import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import ListView from 'common/dynamicPages/views/workflow/list';
import workspaceHelper from 'common/workspaces/api/helper';
import mobileUtil from 'mobile/util/mobileUtil';
import { createActionList, checkAction, formatConfirm } from 'common/util/achActionUtil';
import store from 'system/utilities/cache';
import errHandler from 'system/errHandler';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import services from 'services';
import alertMessage from 'common/api/alertMessage';
import entitlements from 'common/dynamicPages/api/entitlements';
import { postData } from 'common/util/services';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import Tmpl from './achClientListView.hbs';
import AddModal from './controlTotalView';

const AchClientListView = ListView.extend({
    className: 'achClient-listview',
    template: Tmpl,
    events: util.extend(
        {},
        ListView.prototype.events,
        {
            'click [data-hook="print-button"]': 'showPrintOptionsModal',
        },
    ),
    initialize(options) {
        const superOptions = {
            serviceName: '/achControlTotalMaintenance',
            serviceFunc: null,
            businessType: null,
            enableSavedViews: true,
            context: 'ACH_CONTROL_TOTAL_WIDGET',
        };
        const viewId = store.get('ach-viewId');
        if (viewId) {
            superOptions.viewId = viewId;
        }
        this.actionEntitlements = {};
        entitlements.getEntitlements({
            context: {
                serviceName: '/achControlTotalMaintenance',
            },
        }).then((result) => {
            this.actionEntitlements = result.actions;
        }).catch(this.handleError.bind(this));
        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },
    openModal(options = {}) {
        const modal = new AddModal({
            ...options,
            achList: this,
        });
        modal.on('close', () => {
            const actionResponse = store.get('achClientModelActions');
            store.set('achClientModelActions', null);
            if (actionResponse) {
                this.renderMessage(actionResponse.result);
                this.gridView.refreshGridData();
            }
        });
        dialog.open(modal);
    },
    gridRowSelect(options) {
        this.openModal(options);
    },
    gridRowModify(options) {
        this.openModal(options);
    },
    gridRowReject(options) {
        options.model.set('REJECTED_REASON', options.model.get('APPROVER_REJECTION_REASON'));
        ListView.prototype.gridRowReject.call(this, options);
    },
    gridRowDelete(options) {
        this.makeRequest(options, 'delete');
    },
    gridRowApprove(options) {
        this.makeRequest(options, 'approve');
    },
    makeRequest(options, api) {
        const url = services.generateUrl(`achControlTotalMaintenance/${api}`);
        const payload = this.makeReqBody(options);

        postData(url, payload).then((result) => {
            this.renderMessage(result);
            this.gridView.refreshGridData();
        }).catch((error) => {
            this.handleError(error);
        });
    },
    handleError(response) {
        const { message } = response.responseJSON;
        const alertMessages = message[1] || message[0];
        this.alertRegion.show(alert.negative(alertMessages));
    },
    makeReqBody(selectedRow) {
        const options = selectedRow?.model || selectedRow;
        return {
            item: [
                {
                    name: 'PIGID',
                    value: options?.get('PIGID'),
                },
                {
                    name: 'CREDIT_AMOUNT',
                    value: options?.get('CREDIT_AMOUNT'),
                },
                {
                    name: 'DEBIT_AMOUNT',
                    value: options?.get('DEBIT_AMOUNT'),
                },
                {
                    name: 'TNUM',
                    value: options?.get('TNUM'),
                },
                {
                    name: 'REFERNCE_NUMBER',
                    value: options?.get('REFERNCE_NUMBER'),
                },
            ],
        };
    },
    deletePIGID() {
        const ar = this.gridView.grid.getSelectedRows();
        const self = this;
        const deletePromises = [];
        const baseUrl = 'achControlTotalMaintenance/';
        const url = services.generateUrl(`${baseUrl}delete`);
        let txtKey = locale.get('tableMaintenance.dialog.confirm.item.delete');
        const addPromise = function (reqOptions) {
            deletePromises.push(new Promise((resolve, reject) => {
                http.post(url, reqOptions, (result) => {
                    resolve(result);
                }, (err) => {
                    reject(err);
                });
            }));
        };
        if (util.find(ar, ob => checkAction(self.gridView?.wrapper?.rows?.get(ob)?.buttons, 'DELETE'))) {
            if (ar.length) {
                if (ar.length > 1) {
                    const deletedRow = util.find(ar, ob => createActionList(self.gridView?.wrapper?.rows?.get(ob)?.buttons, 'DELETE'));
                    txtKey = deletedRow ? `${locale.get('PAY.multi.delete.warning')} ${locale.get('administration.delete.warning.plural')}` : locale.get('administration.delete.warning.plural');
                }
                dialog.confirm(txtKey, locale.get('tableMaintenance.dialog.confirm.title.delete'), (ok) => {
                    if (ok) {
                        util.each(ar, (item) => {
                            const selectedRow = self.gridView.wrapper.rows.get(item);
                            if (selectedRow.get('STATUS') !== 'Deleted') {
                                const options = self.makeReqBody(selectedRow);
                                addPromise(options);
                            }
                        });
                        Promise.all(deletePromises).then((results) => {
                            this.handleBulkResponse(results);
                        }).then(null, errHandler);
                    }
                });
            }
        }
    },
    export() {
        ListView.prototype.export.call(this, this.getPrintOptions('CSV'));
    },
    getPrintOptions(format = 'PDF') {
        return {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            searchFields: this.getSearchFieldsByKeyList(this.gridView),
            hasSummarySelection: false,
            format,
        };
    },
    handleBulkResponse(results) {
        const updatedConfirmResponse = formatConfirm(results);
        this.gridView.refreshGridData();
        this.renderMessage(updatedConfirmResponse);
    },

    renderMessage(confirmResponse, options) {
        const regionLoc = this.$('div[data-region="alertRegion"]').offset();
        let response = confirmResponse;
        if (confirmResponse) {
            if (confirmResponse === 'reject' && options?.message.length) {
                response = options;
            }
            alertMessage.renderMessage(this, response.result ? 'SUCCESS' : 'DANGER', response);
            if (regionLoc) window.scrollTo(regionLoc.left, regionLoc.top);
        }
    },
    templateHelpers() {
        const self = this;
        return {
            showAdd: self.actionEntitlements?.INSERT,
            showDelete: self.actionEntitlements?.DELETE,
            assignAllTooltip() {
                return locale.get('RISK.Message.CMAINT.LOC_ACCT.INSERT.warning');
            },
        };
    },
});
// eslint-disable-next-line import/no-mutable-exports
let list = AchClientListView;
if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        insertActions: [
            {
                label: 'risk.ach.pigid.addnew',
                entitlement: 'INSERT',
                handlerMethodName: 'openModal',
            },
        ],
        bulkActions: [
            {
                label: 'button.delete',
                entitlement: 'DELETE',
                // TODO this will be changed once Backend is ready
                handlerMethodName: 'deletePIGID',
            },
        ],
    });
    list = list.extend(mobileList);
}
workspaceHelper.publishedWidgets.add({
    id: 'ACH_CONTROL_TOTAL_WIDGET',
    view: list,
    options: {},
    useMobileCard: true,
});
export default list;
