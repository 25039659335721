import util from '@glu/core/src/util';
import ListView from 'common/dynamicPages/views/workflow/list';
import gridApi from 'common/dynamicPages/api/grid';
import entitlements from 'common/dynamicPages/api/entitlements';
import errorHandlers from 'system/error/handlers';
import store from 'system/utilities/cache';
import workspaceHelper from 'common/workspaces/api/helper';
import listViewTmpl from './listView.hbs';

const CorrBankMappingList = ListView.extend({
    template: listViewTmpl,

    events: {
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
        'click [data-hook="refresh-button"]': 'refreshData',
    },

    initialize(options) {
        const superOptions = {
            menuCategory: 'ADMINSTRATION',
            serviceName: 'correspondentBankMapping',
            serviceFunc: null,
            businessType: null,
            enableSavedViews: true,
            context: 'SMAINT_CORBNKMP',
            returnRoute: '/ADMINSTRATION/correspondentBankMapping',
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    loadViewRequirements() {
        const options = this.viewOptionRequirements();
        const entitlementPromise = entitlements.getEntitlements(options);
        this.gridView = gridApi.createServiceGridView(options);
        ListView.prototype.setupGridAvailableListener.call(this);

        Promise.all([entitlementPromise]).then((results) => {
            const entResActions = results[0].actions;
            this.entitlements = entResActions;
            this.setHasLoadedRequiredData(true);
            this.render();
        }, util.bind(errorHandlers.loading, this));
    },

    gridRowSelect(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo('ADMINSTRATION/correspondentBankMappingDetailView');
        return Promise.resolve();
    },

    gridRowModify(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo('ADMINSTRATION/correspondentBankMappingDetailModify');
        return Promise.resolve();
    },

    insert() {
        this.navigateTo('ADMINSTRATION/correspondentBankMappingDetailInsert');
    },

    runProcessMultiActionsAsOne() {
        return false;
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'SMAINT_CORBNKMP',
    view: CorrBankMappingList,
    options: {},
});

export default CorrBankMappingList;
