var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"message-group "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"requiresAdditionalClass") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":38},"end":{"line":3,"column":102}}})) != null ? stack1 : "")
    + "\">\n                <div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IS_REQUEST") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":5,"column":20},"end":{"line":23,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n                <div class=\"row\">\n                    <div class=\"col-12 message\">\n                        <div class=\"message-body\">\n                            <p class=\"additional-info\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"ADDITIONAL_INFO") || (depth0 != null ? lookupProperty(depth0,"ADDITIONAL_INFO") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ADDITIONAL_INFO","hash":{},"data":data,"loc":{"start":{"line":28,"column":55},"end":{"line":28,"column":74}}}) : helper)))
    + "</p>\n                            <p class=\"message-id\">\n                                <span class=\"icon-clock\" />\n                                <span class=\"rtp-userid\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"USERID") || (depth0 != null ? lookupProperty(depth0,"USERID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"USERID","hash":{},"data":data,"loc":{"start":{"line":31,"column":57},"end":{"line":31,"column":67}}}) : helper)))
    + "</span>\n                                <span class=\"rtp-timestamp\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"TIMESTAMP") || (depth0 != null ? lookupProperty(depth0,"TIMESTAMP") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TIMESTAMP","hash":{},"data":data,"loc":{"start":{"line":32,"column":60},"end":{"line":32,"column":73}}}) : helper)))
    + "</span>\n                            </p>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " additional-message-class ";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"col-6\">\n                            <h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rtp.requestForInfo.heading",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":32},"end":{"line":7,"column":71}}}))
    + "</h3>\n                            <h3 class=\"message-type\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rtp.requestForInfo.type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":53},"end":{"line":8,"column":89}}}))
    + "</h3>\n                             <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"REQUESTFORINFO_REASON_TYPE") || (depth0 != null ? lookupProperty(depth0,"REQUESTFORINFO_REASON_TYPE") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"REQUESTFORINFO_REASON_TYPE","hash":{},"data":data,"loc":{"start":{"line":9,"column":35},"end":{"line":9,"column":65}}}) : helper)))
    + "</span>\n                        </div>\n                        <div class=\"col-6\">\n                            <h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rtp.requestForInfo.reason",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":32},"end":{"line":12,"column":70}}}))
    + " </h3>\n                            <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"REQUESTFORINFO_REASON") || (depth0 != null ? lookupProperty(depth0,"REQUESTFORINFO_REASON") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"REQUESTFORINFO_REASON","hash":{},"data":data,"loc":{"start":{"line":13,"column":34},"end":{"line":13,"column":59}}}) : helper)))
    + "</span>\n                        </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IS_RESPONSE") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":23,"column":20}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"col-12\">\n                            <h3>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"rtp.responseToRequestForInfo.heading",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":32},"end":{"line":17,"column":81}}}))
    + "</h3>\n                        </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"col-12\">\n                            <h3>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"rtp.paymentAcknowledgement.heading",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":32},"end":{"line":21,"column":79}}}))
    + "</h3>\n                        </div>\n                    ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"currentTab") || (depth0 != null ? lookupProperty(depth0,"currentTab") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"currentTab","hash":{},"data":data,"loc":{"start":{"line":1,"column":12},"end":{"line":1,"column":26}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"unescapeCharacters") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":38,"column":13}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});