import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import services from 'services';
import http from '@glu/core/src/http';

export default Collection.extend({
    initialize() {
        this.serviceUrl = '/inquiry/getData';
    },

    parse(jsonData) {
        const { rows } = jsonData.inquiryResponse;
        const { rowHeader } = jsonData.inquiryResponse;

        return {
            locationLockboxes: this.convertServerJsonToModelAttributes(rows),
            rowHeader,
        };
    },

    sync(method, model, options) {
        const url = services.generateUrl(this.serviceUrl);
        const postData = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    action: {
                        typeCode: 'LBX_RPT',
                        productCode: 'GIR',
                        functionCode: 'LOCKBOX',
                        actionMode: 'SELECT',
                    },

                    searchType: '5',
                    inquiryId: 22455,
                },
            },
        };

        http.post(url, postData, (result) => {
            options.success(result);
        }, (result) => {
            options.error(result);
        });
    },

    convertServerJsonToModelAttributes(serverJson) {
        let data;
        let columns = {};
        const allRows = [];

        // for each row of all lockboxes
        for (let x = 0; x < serverJson.length; x += 1) {
            data = {};
            ({ columns } = serverJson[x]);

            // for each column of a single row of a lockbox
            for (let y = 0; y < columns.length; y += 1) {
                data[columns[y].fieldName] = columns[y].fieldValue;
            }

            allRows.push(data);
        }

        const locations = util.groupBy(allRows, lockbox => lockbox.LOCATION_CODE);

        const locationLockboxes = [];

        util.each(locations, (item, index) => {
            const lockboxes = util.map(item, value => ({
                id: `lbx${value.LOCKBOX_ID}`,
                name: `${value.LOCKBOX_CODE} (${value.LOCATION_CODE}) ${value.NAME}`,
                parent: value.LOCATION_ID,
            }));

            const uniqueLockboxes = util.uniq(lockboxes, lb => `id:${lb.id}name:${lb.name}`);

            const locationLockbox = {
                id: item[0].LOCATION_ID,
                name: index,
                child: uniqueLockboxes,
            };

            locationLockboxes.push(locationLockbox);
        });

        return locationLockboxes;
    },
});
