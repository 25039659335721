import ModalView from 'no-override!@glu/dialog/src/views/modalView';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import $ from 'jquery';
import template from './modal.hbs';
import buttonTemplate from './modalViewButtons.hbs';
import modalUtil from 'common/util/modal';
import portalInterface from 'system/notifications/interfaces/portal';
import serverConfigParams from 'system/webseries/models/configurationParameters';

export default ModalView.extend({
    template,

    ui: {
        mask: '> .modal-backdrop',
        close: 'button.close',
        dialog: '> div > .modal-dialog',
        body: '> div > div > div > .modal-body',
        footer: '> div > div > div > .modal-footer',
        modal: '> .modal',
        $title: '[data-hook="getTitle"]',
    },

    regions: {
        header: '> div > div > div > .modal-header',
        body: '> div > div > div > .modal-body',
        footer: '> div > div > div > .modal-footer',
    },

    initialize(options) {
        const self = this;
        ModalView.prototype.initialize.call(this, options);
        this.showCloseButton = util.has(options, 'showCloseButton') ? options.showCloseButton : true;
        // add event listener when the page is loaded in an iFrame
        if (window.parent !== window) {
            window.addEventListener('message', this.handleMessage.bind(this));
            this.listenToOnce(this.appBus, ('notification:child:portalWindow-innerHeight'), data => {
                if (data.innerHeight) {
                    self.setMaxHeightModalDeepLinks(data);
                }
            });
            this.listenToOnce(this.appBus, ('notification:child:modal:close'), () => {
                self.close();
            });
        }
    },

    /**
     * @method handleMessage
     * @param {object} evt - event parameter
     *
     * Listener method that routes the different message types sent by the portal
     * to the corresponding method handlers
     *
     */
    handleMessage(evt) {
        switch (evt.data.type) {
            case 'scroll':
                this.positionModal(modalUtil.retrieveTopEdge(evt.data));
                break;
            default:
                // do nothing
                break;
        }
    },

    /**
     * @method setMaxHeightDeepLinks
     * @param {object} data - object containing innerHeight information for parent window
     *
     * Method that is invoked by the listener of the message sent by the portal with the
     * value of the inner height of the portal's window
     */
    setMaxHeightModalDeepLinks(data) {
        let footerHeight;
        let dialogBottomMargin;
        let maxHeight;
        let portalFooterHeight;
        // make sure that body is initialized into an object
        if (typeof this.ui.body === 'object' && this.ui.body.offset() !== undefined && data.innerHeight) {
            footerHeight = this.ui.footer.outerHeight();
            dialogBottomMargin = parseInt(this.ui.dialog.css('margin-bottom'), 10);
            maxHeight = data.innerHeight - (footerHeight + dialogBottomMargin) -
                (data.innerHeight / 3);
            portalFooterHeight = serverConfigParams.get('portalFooterHeight');
            if (portalFooterHeight) {
                maxHeight = maxHeight - portalFooterHeight;
            }
            // if there's a scroll bar, adjust the maxHeight with the distance between
            // the top border of the iFrame and the ViewPort area
            if (window.innerHeight > data.innerHeight && data.child &&
                data.child.top && data.child.top < maxHeight) {
                maxHeight = maxHeight - data.child.top;
            }
            this.ui.body.css('max-height', `${maxHeight}px`);
        }
    },

    /**
     * @method positionModal
     * @param {number} modalTop - number of pixels for the top edge of the modal
     *
     * Place the modal at a given number of pixels (specified by modalTop) below the top
     * edge of the containing positioned element (iFrame)
     */
    positionModal(modalTop) {

        if (modalTop) {
            const $modal = $('.modal-dialog');
            if ($modal.length > 0) {
                $modal.css({
                    top: `${modalTop}px`,
                });
                this.updateFooterPosition();
            }
            this.sizeModal();
        }
    },

    reportListBuilderChange() {
        this.appBus.trigger('listBuilderModalChange');
    },

    /**
     * Logic explicitly for ListBuilder modals
     * ListBuilder was forcibly set to 400px tall,
     * which doesn't work with an adjustable window.
     * @param {jQuery} $modal - modal content element
     * @param {number} maxHeight - Max available height
     * @param {Object} data - sizing data
     */
    sizeModalListBuilder($modal, maxHeight, data) {
        const modalCurrentHeight = $modal.height();
        const usableHeight = modalUtil.calculateUsableHeight(data);

        if (modalCurrentHeight < usableHeight) {
            return;
        }

        const modalHeaderHeight = $modal.find('.modal-header').outerHeight();
        const modalFooterHeight = $modal.find('.modal-footer').outerHeight();
        const usableModalHeight = maxHeight - (2 * (data?.options?.position?.topMargin || 0));
        const $lists = $modal.find('.source-list, .target-list');

        // Get the header and footer height
        const filterHeight = $modal.find('.filter.form-inline').outerHeight() || 0;
        const toggleHeight = $modal.find('.manage-accounts.list-builder>div').first().outerHeight() || 0;
        const moveAccountsHeight = $modal.find('.move-accounts-control, .source-list-controls').outerHeight() || 0
        const nonListHeight = toggleHeight
            + moveAccountsHeight
            + modalHeaderHeight
            + modalFooterHeight
            + filterHeight
            + 10; // Margin in listBuilder we can't easily compute
        const bestListHeight = usableModalHeight - nonListHeight;


        // Figure out if the list can be enlarged to the full 400px
        // 450 provides some buffer for bottom marging, etc.
        // const hasSpaceForFullList = (usableModalHeight - nonListHeight) > 450;
        // const newListHeight = (hasSpaceForFullList || bestListHeight > 400) ? 400 : bestListHeight;
        // Ensure some content is visible if the space is tiny
        const usableListHeight = Math.max(bestListHeight, 100);

        $modal.find('.modal-body').height(usableModalHeight);
        $lists.height(usableListHeight);
        this.throttledListBuilderChange();
    },

    /**
     * update sticky footer position in modals with scrolling content
     * @method updateFooterPosition
     */
    updateFooterPosition() {
        const $modalDialog = this.$('.modal-dialog');
        const $stickyFooter = $modalDialog.find('.sticky-footer');
        const modalFooterHeight = $modalDialog.find('.modal-footer').innerHeight();
        const modalBottomOffset = window.innerHeight -
            ($modalDialog[0].offsetTop + $modalDialog[0].offsetHeight);

        $stickyFooter.css({
            bottom: modalFooterHeight + modalBottomOffset,
        });
    },

    /**
     * @method handleResize
     *
     * Override of the handleResize from glu's modalView
     * For DeepLinks use the window's outerHeight to set the max-height of the modal
     *
     */
    handleResize() {

        // not a deeplink rendered in an iFrame
        if (window === window.parent) {
            ModalView.prototype.handleResize.call(this);
            return;
        }

        // DeepLink in iFrame - send a message to retrieve the inner height of the Portal's window content area.
        portalInterface.send({
            type: 'peekPortalWindow',
            key: 'innerHeight',
        });
    },

    onRender() {
        this.appBus.trigger(
            'glu:modal:open',
            {
                title: this.title,
            },
        );
        if (this.bodyView) {
            this.setBodyViewListeners();
        }
        ModalView.prototype.onRender.call(this);
    },

    templateHelpers() {
        return {
            showCloseButton: this.showCloseButton,
            type: this.type || 'standard',
            message: this.message,
            title: this.title,
            dialogIcon: this.icon,
            modalClass: this.modalClass,
            buttons: this.buttons,
            footerButtons: buttonTemplate,
        };
    },

    setBodyViewListeners() {
        this.listenTo(this.bodyView, 'load:error', this.replaceButtonsForLoadError);
        this.listenTo(this.bodyView, 'dialog:title:change', this.changeTitle);
        this.listenTo(this.bodyView, 'dialog:view:change', this.changeView);
        this.listenTo(this.bodyView, 'dialog:buttons:change', this.changeButtons);
        this.listenTo(this.bodyView, 'dialog:buttons:disableAll', this.disableAllButtons);
        this.listenTo(this.bodyView, 'dialog:buttons:enableAll', this.enableAllButtons);
    },

    close() {
        this.appBus.trigger(
            'glu:modal:close',
            {
                title: this.title,
            },
        );
        portalInterface.send({
            title: 'modal:close',
        });
        ModalView.prototype.close.call(this);
    },

    changeTitle(title) {
        this.ui.$title.text(title);
        this.handleResize();
    },

    changeView(view) {
        this.bodyView = view;
        this.setBodyViewListeners();
        this.body.show(this.bodyView);
    },

    changeButtons(buttons) {
        this.buttons = buttons;
        // Injecting HBS template
        this.ui.footer.html(buttonTemplate(this));
        this.handleResize();
    },

    replaceButtonsForLoadError() {
        this.changeButtons(
            [{
                className: 'btn-primary',
                text: locale.get('common.close'),
            }],
        );
    },

    disableAllButtons() {
        // use jquery since buttons might have changed since rendering
        this.$('.modal-footer button').prop('disabled', true);
    },

    enableAllButtons() {
        // use jquery since buttons might have changed since rendering
        this.$('.modal-footer button').prop('disabled', false);
    },
});
