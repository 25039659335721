import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ExclamationTriangleAshIcon } from '@glu/icons-react';
import useStyles from './CharacterCounter.styles';

const CharacterCounter = ({
    count, isCountdown, hasWarning, label, maxCount, separator, warningLabel,
}) => {
    const classes = useStyles();
    const [displayedCount, setDisplayedCount] = useState(isCountdown ? maxCount : count);

    useEffect(() => {
        if (isCountdown) {
            setDisplayedCount(maxCount - count);
        }
    }, [count, isCountdown, maxCount]);

    return (
        <span className={`${classes.root} ${hasWarning ? classes.warning : ''}`}>
            {hasWarning && (
                <>
                    <ExclamationTriangleAshIcon />
                    {warningLabel}
                </>
            )}
            {!hasWarning && (
                <>
                    <span className={classes.label}>{label}</span>
                    <span>{displayedCount}</span>
                    {!isCountdown && (
                        <>
                            <span>{separator}</span>
                            <span>{maxCount}</span>
                        </>
                    )}
                </>
            )}
        </span>
    );
};

CharacterCounter.propTypes = {
    count: PropTypes.number,
    isCountdown: PropTypes.bool,
    label: PropTypes.string,
    maxCount: PropTypes.number.isRequired,
    separator: PropTypes.string,
    hasWarning: PropTypes.bool,
    warningLabel: PropTypes.string,
};

CharacterCounter.defaultProps = {
    count: 0,
    isCountdown: false,
    label: '',
    separator: '/',
    hasWarning: false,
    warningLabel: '',
};

export default CharacterCounter;
