import store from 'system/utilities/cache';

export default {
    /**
     *
     * make a payment from either payment or template
     *
     *
     */
    makePayment(modeParaml, contextKey) {
        const model = modeParaml;
        if (model.context.serviceName.indexOf('/batchTemplate/') > -1) {
            model.context.payServiceName = model.context.serviceName.replace('/batchTemplate/', '/batch/');
        } else if (model.context.serviceName.indexOf('/template/') > -1) {
            model.context.payServiceName = model.context.serviceName.replace('/template/', '/payment/');
        } else {
            model.context.payServiceName = model.context.serviceName;
        }

        const contextModel = {
            serviceName: model.context.payServiceName,
            tnum: model.get('TNUM'),
            functionCode: model.get('FUNCTION'),
            subType: model.get('SUBTYPE'),
            templateServiceName: model.context.serviceName,
            loadFromPayments: model.loadFromPayments,
            updateCount: model.get('UPDATECOUNT__'),
            enableSaveDraft: true,
        };

        store.set('payment_listView_corp-contextOverride', contextModel);
        store.set(`${contextKey}-actionModel`, model);
    },

    /**
     *
     * make a Template from either payment or template
     *
     * @param {Object} modelParam
     * @param {string} contextKey
     */
    makeTemplate(modelParam, contextKey) {
        const model = modelParam;
        if (model.context.serviceName.indexOf('/batch/') > -1) {
            model.context.payServiceName = model.context.serviceName.replace('/batch/', '/batchTemplate/');
        } else {
            model.context.payServiceName = model.context.serviceName;
        }

        const contextModel = {
            serviceName: model.context.payServiceName,
            tnum: model.get('TNUM'),
            functionCode: model.get('FUNCTION'),
            subType: model.get('SUBTYPE'),
            templateServiceName: model.context.serviceName,
            updateCount: model.get('UPDATECOUNT__'),
            enableSaveDraft: true,
        };

        // Flag is needed to determine where to display confirmation message for RTP Templates
        if (model.fromRTPWorkspace) {
            contextModel.fromRTPWorkspace = model.fromRTPWorkspace;
        }

        store.set('template_listView_corp-contextOverride', contextModel);
        store.set(`${contextKey}-actionModel`, model);
    },

    /**
     * @name makeRequestForPayment
     * @description  called for 'copy as request for payment'
     * this method sets up the store (global model) with the data required for copy as request
     *
     * @param {object} modelParam - src model for request for payment
     * @param {string} contextKey - key representing the rfp data.
     * this key is used to store data
     * in the global cache
     */
    makeRequestForPayment(modelParam, contextKey) {
        const model = modelParam;

        const contextModel = {
            serviceName: model.context.serviceName,
            tnum: model.get('TNUM'),
            functionCode: model.get('FUNCTION'),
            subType: model.get('SUBTYPE'),
            templateServiceName: model.context.serviceName,
            loadFromPayments: model.loadFromPayments,
            updateCount: model.get('UPDATECOUNT__'),
        };
        contextModel.enableSaveDraft = true;
        store.set(`${contextKey}-contextOverride`, contextModel);
        store.set(`${contextKey}-actionModel`, model);
    },

    /**
     * @name makeTemplateFromRequest
     * @description  called for 'copy as template for request for payment'
     * this method sets up the store (global model) with the data required for copy as template
     *
     * @param {object} modelParam - src model for template request for payment
     * @param {string} contextKey - key representing the rfp template data.
     * this key is used to store data
     * in the global cache
     */
    makeTemplateFromRequest(modelParam, contextKey) {
        const model = modelParam;

        const contextModel = {
            serviceName: model.context.serviceName,
            tnum: model.get('TNUM'),
            functionCode: model.get('FUNCTION'),
            subType: model.get('SUBTYPE'),
            templateServiceName: model.context.serviceName,
            loadFromPayments: model.loadFromPayments,
            updateCount: model.get('UPDATECOUNT__'),
        };
        contextModel.enableSaveDraft = true;
        store.set(`${contextKey}-contextOverride`, contextModel);
        store.set(`${contextKey}-actionModel`, model);
    },
};
