var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <option disabled hidden value>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"rdc.Select.Location",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":42},"end":{"line":5,"column":74}}}))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCATIONNAME") || (depth0 != null ? lookupProperty(depth0,"LOCATIONNAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCATIONNAME","hash":{},"data":data,"loc":{"start":{"line":9,"column":27},"end":{"line":9,"column":43}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"hasOneLocation") : depths[1]),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":45},"end":{"line":9,"column":85}}})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCATIONDISPLAYNAME") || (depth0 != null ? lookupProperty(depth0,"LOCATIONDISPLAYNAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCATIONDISPLAYNAME","hash":{},"data":data,"loc":{"start":{"line":9,"column":86},"end":{"line":9,"column":109}}}) : helper)))
    + "</option>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "selected";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"form-group\">\n    <label for=\"LOCATIONNAME\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"rdc.user.location",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":30},"end":{"line":2,"column":60}}}))
    + "</label>\n    <select required name=\"LOCATIONNAME\" data-bind=\"model\" class=\"form-control\" data-hook=\"getLocation\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasOneLocation") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":6,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"locations") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":10,"column":17}}})) != null ? stack1 : "")
    + "    </select>\n    <span class=\"help-block\" data-bind=\"model\" data-validate=\"LOCATIONNAME\"></span>\n</div>\n<div data-region=\"accountInputRegion\"></div>\n";
},"useData":true,"useDepths":true});