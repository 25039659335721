import { Grid as GluGrid } from 'no-override!@glu/grid/src/index';
import FilterBadgeHeaderView from './filter/badges/filterBadgesHeaderLayout';

const Grid = GluGrid.extend({
    buildFilterBadgeHeaderView() {
        this.filterBadgeHeaderView = new FilterBadgeHeaderView({
            columns: this.columns,
            masterCollection: this.masterCollection,
            filters: this.filterProc.filters,
            filterProc: this.filterProc,
            // override: make the grid instance available to filter badges
            grid: this,
        });
    },
    onRender() {
        GluGrid.prototype.onRender.call(this);
        const { filter, enableSavedViews, savedViewsWithoutFilters } = this.options;
        /*
         * NH-178965 It is now possible to have savedViews without showing the filters. They are
         * tightly coupled together in GLU, where filters are required for savedViews both
         * to render and save savedViews. Adding an option, savedViewsWithoutFilters,
         * to visually hide the filters.
         */
        if (filter && enableSavedViews && savedViewsWithoutFilters) {
            this.$el.find('.grid-controls-section .filters').css('visibility', 'hidden');
        }
    },
});

export default Grid;
