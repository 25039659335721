import Model from '@glu/core/src/model';
import { postData } from 'common/util/services';
import services from 'services';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import Collection from '@glu/core/src/collection';
import { maskValue } from 'common/util/maskingUtil';
import ThresholdModel from './thresholdModel';
import ApproverSequenceModel from './approverSequence';
import ApproverModel from './approver';

export default Model.extend({

    defaults: {
        panelProfileCode: '',
        description: '',
        allPaymentMethods: null,
        allAccounts: null,
        allEntryMethods: null,
        paymentMethods: [],
        entryMethods: [],
        accounts: [],
        templates: [],
        thresholds: [],
    },

    initialize() {
        this.set({
            accounts: new Collection(),
            thresholds: new Collection(),
        });

        this.validators = {
            panelProfileCode: {
                description: locale.get('PS.panelApproval.Workflow.Code'),
                exists: true,
            },
            description: {
                description: locale.get('PS.panelApproval.Description'),
                exists: true,
            },
        };
    },

    sync(method, model, options) {
        if (method === 'read') {
            const service = postData(
                services.panelAprovalWorkflowRead,
                this.getFetchData(model),
            );

            if (options.modify) {
                return service.then(this.parse.bind(this));
            }

            return service.then(this.maskAccountNums)
                .then(results => this.set(results));
        }
        if (method === 'create') {
            let endpoint = services.panelAprovalWorkflowAdd;

            if (options.modify) {
                endpoint = services.panelAprovalWorkflowUpdate;
            }

            return postData(endpoint, this.getSaveData(model));
        }

        return Promise.reject(new Error('method event not supported'));
    },

    /**
     * Iterate over the accounts in the response and mask any
     * account numbers
     * @param {Object} data
     * @returns {Object}
     */
    maskAccountNums(data) {
        return {
            ...data,
            account: data.account?.map?.(acc => ({
                ...acc,
                accountNumber: maskValue(acc.accountNumber),
            })),
        };
    },

    parse(result) {
        const paymentMethodAttributes = this.parsePaymentMethods(result.assignPayments);
        const entryMethodAttributes = this.parseEntryMethods(result.entryMethods);
        const accountAttributes = this.parseAccounts(result.account);
        const otherAttributes = {
            panelProfileCode: result.profileCode,
            description: result.description,
            templates: this.parseTemplates(result.assignTemplates),
            thresholds: this.parseThresholds(result.thresholds),
        };

        this.set(util.extend(
            {},
            paymentMethodAttributes,
            entryMethodAttributes,
            accountAttributes,
            otherAttributes,
        ));
    },

    parsePaymentMethods(data) {
        if (util.isNullOrUndefined(data)) {
            return {};
        }
        const paymentMethods = data.map(paymentMethod => paymentMethod.paymentType);
        if (paymentMethods[0] === '*') {
            return {
                allPaymentMethods: paymentMethods[0],
            };
        }
        return {
            paymentMethods,
        };
    },

    parseAccounts(data) {
        if (util.isNullOrUndefined(data)) {
            return {};
        }
        const accounts = data.map(account => util.extend(account, {
            id: account.accountFilter,
            accountNumber: maskValue(account.accountNumber),
        }));
        if (accounts[0].accountFilter === '*') {
            return {
                allAccounts: accounts[0].accountFilter,
            };
        }
        return {
            accounts: new Collection(accounts),
        };
    },

    parseEntryMethods(data) {
        if (util.isNullOrUndefined(data)) {
            return {};
        }
        const entryMethods = data.map(entryMethod => entryMethod.entryMethod);
        if (entryMethods[0] === '*') {
            return {
                allEntryMethods: entryMethods[0],
            };
        }
        return {
            entryMethods,
        };
    },

    parseThresholds(thresholds) {
        const data = thresholds.map(threshold => new ThresholdModel({
            amount: threshold.amount,
            approverSequences: this.parseApproverSequences(threshold.thresholdPanels),
        }));
        return new Collection(data);
    },

    parseApproverSequences(approverSequences) {
        const data = approverSequences.map(approverSequence => new ApproverSequenceModel({
            sequence: approverSequence.sequence,
            approvers: this.parseApprovers(approverSequence.approvers),
        }));
        return new Collection(data);
    },

    parseApprovers(approvers) {
        const data = approvers.map((approver, index) => new ApproverModel(util.extend(
            approver,
            { order: index + 1 },
        )));
        return new Collection(data);
    },

    parseTemplates(templates) {
        return new Collection(templates);
    },

    /**
     * Get the proper request structure for read request
     * @returns {Object}
     */
    getFetchData(model) {
        return {
            item: {
                item: [
                    {
                        name: 'USERGROUP',
                        value: model.get('userGroup'),
                    },
                    {
                        name: 'PANELPROFILECODE',
                        value: model.get('panelProfileCode'),
                    },
                ],
            },
        };
    },

    /**
     * Convert the model into server ready JSON
     * @param {Object} model
     * @returns {Object} serialized version of the object
     */
    getSaveData(model) {
        return {
            userGroup: model.get('userGroup'),
            profileCode: model.get('panelProfileCode'),
            description: model.get('description'),
            assignPayments: this.getPaymentMethods(model),
            entryMethods: this.getEntryMethods(model),
            account: this.getAccounts(model),
            assignTemplates: this.getTemplates(model),
            thresholds: this.getThresholds(model),
        };
    },

    getPaymentMethods(model) {
        let paymentMethods = model.get('paymentMethods');

        if (util.isNullOrUndefined(paymentMethods)) {
            return [];
        }

        if (!Array.isArray(paymentMethods)) {
            paymentMethods = [paymentMethods];
        }

        if (!util.isNullOrUndefined(model.get('allPaymentMethods')) || paymentMethods[0] === '*') {
            return [{
                paymentType: '*',
                productCode: '*',
                templateCode: '*',
            }];
        }

        return paymentMethods.map(paymentType => this.findPaymentMethod(paymentType, model));
    },

    findPaymentMethod(paymentType, model) {
        const paymentMethod = model.get('paymentMethodData').find(method => paymentType === method.paymentType);
        return {
            productCode: paymentMethod.productCode,
            paymentType: paymentMethod.paymentType,
            templateCode: '*',
        };
    },

    getEntryMethods(model) {
        let entryMethods = model.get('entryMethods');

        if (util.isNullOrUndefined(entryMethods)) {
            return [];
        }

        if (!Array.isArray(entryMethods)) {
            entryMethods = [entryMethods];
        }

        if (model.get('entryMethodData').length === entryMethods.length) {
            return [{
                entryMethod: '*',
                entryMethodDescription: '',
            }];
        }
        return entryMethods.map(entry => ({
            entryMethod: entry,
        }));
    },

    getAccounts(model) {
        if (model.get('allAccounts')) {
            return [{
                bankCode: '',
                accountNumber: '',
                accountFilter: '*',
                accountName: '',
            }];
        }
        return model.get('accounts').map(account => ({
            accountFilter: account.get('accountFilter'),
        }));
    },

    getTemplates(model) {
        /*
         * The or in this case will always be the original data from the 'read' request
         * when a template is not viewed or selected from the list
         */
        return model.get('templates').map(template => ({
            productCode: template.get('PRODUCT') || template.get('productCode'),
            paymentType: template.get('TYPE') || template.get('paymentType'),
            templateCode: template.get('CMB_TEMPLATE_CODE') || template.get('templateCode'),
        }));
    },

    getThresholds(model) {
        return model.get('thresholds').map(threshold => ({
            amount: parseFloat(threshold.get('amount')),
            thresholdPanels: threshold.get('approverSequences').map(this.mapApprovalSequences.bind(this)),
        }));
    },

    mapApprovalSequences(approvalSequence) {
        return {
            sequence: approvalSequence.get('sequence'),
            approvers: approvalSequence.get('approvers').filter(this.filterApprovers)
                .map(this.mapApprovers),
        };
    },

    filterApprovers(approver) {
        return !util.isNullOrUndefined(approver.get('id'));
    },

    mapApprovers(approver) {
        return {
            id: approver.get('order'),
            type: approver.get('type'),
            name: approver.get('name'),
        };
    },
});
