import Model from '@glu/core/src/model';
import locale from '@glu/locale';

export default Model.extend({
    defaults() {
        return {
            description: '',
            permission: '',
            permissionLabel: '',
            permitted: false,
        };
    },

    description() {
        return this.get('description');
    },

    permission() {
        return this.get('permission');
    },

    permissionLabel() {
        return this.get('permissionLabel');
    },

    permitted() {
        return this.get('permitted');
    },

    setDescriptionBasedOnPermission() {
        const description = locale.get(this.get('permissionLabel'));

        this.set('description', description);
    },

    serializeModel() {
        return {
            permission: this.get('permission'),
            permissionLabel: this.get('permissionLabel'),
            entitled: this.get('entitled'),
        };
    },

});
