/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import { Modal } from 'pcm/common/components/Modal';

const ConfirmModal = ({
    successHandler,
    cancelHandler: onCancel,
    header,
    body,
}) =>
    (
        <Modal
            title={header}
            preventOkClose
            showCancel
            cancelButtonText={locale.get('no')}
            onClose={() => onCancel()}
            onOK={() => successHandler()}
            okButtonText={locale.get('yes')}
        >
            <div>
                <p>{body}</p>
            </div>
        </Modal>
    );

ConfirmModal.propTypes = {
    successHandler: PropTypes.func.isRequired,
    cancelHandler: PropTypes.func.isRequired,
    header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    body: PropTypes.node.isRequired,
};

ConfirmModal.defaultProps = {
};

export default ConfirmModal;
