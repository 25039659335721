import data from 'common/datePicker/api/futureDatePicker';
import 'bootstrapDateRangePicker';
import $ from 'jquery';
import locale from '@glu/locale';
import userInfo from 'etc/userInfo';
import dateUtil from 'common/util/dateUtil';
import moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';

const originalDRP = $.fn.daterangepickerNH;
const { getDateRanges } = data;

if (userInfo.get('timezone') && moment.tz) {
    // Push us to the users' timezone.
    moment.tz.setDefault(userInfo.get('timezone'));
}

// create a new method to extend the default behavior of this component
// eslint-disable-next-line
function callCommonConfig(opts, cb) {
    const options = opts;
    options.locale = {
        applyLabel: $.trim(locale.get('common.datepicker.apply')),
        cancelLabel: $.trim(locale.get('common.datepicker.cancel')),
        fromLabel: $.trim(locale.get('common.datepicker.from')),
        toLabel: $.trim(locale.get('common.datepicker.to')),
        weekLabel: $.trim(locale.get('common.datepicker.week.label')),
        customRangeLabel: $.trim(locale.get('common.datepicker.custom.range')),
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
        firstDay: moment.localeData().firstDayOfWeek(),
        previousMonth: $.trim(locale.get('common.datepicker.previousMonth')),
        nextMonth: $.trim(locale.get('common.datepicker.nextMonth')),
        openDatepicker: $.trim(locale.get('common.datepicker.openDatepicker')),
    };

    /**
     * Determine if a specific day is invalid based on a number of criteria
     * @param {Object} day - the specific day to be evaluated
     * @return {Boolean} - Whether this day is invalid (TRUE) if invalid
     */
    options.isInvalidDay = function (day) {
        // loop through holidays to see if this day is a holiday
        if (options?.blockedDates?.some?.(blockedDay => moment(day).isSame(blockedDay, 'day'))) {
            return true;
        }

        // mark days before yesterday as invalid
        if (options.pastOnly) {
            let yesterday = moment(new Date()).subtract(1, 'days').endOf('day');
            const today = moment(day).unix() * 1000;
            yesterday = moment(yesterday).unix() * 1000;
            if (today > yesterday) {
                return true;
            }
        }

        // If there is a specific range of dates available, mark dates outside of it as invalid
        if ((options.minDate && options.maxDate) || options.dynamicDateOptions) {
            const tempMinDate = this.minDate || options.minDate;
            const zMinDate = tempMinDate?.startOf('day');

            const tempMaxDate = this.maxDate || options.maxDate;
            const zMaxDate = tempMaxDate?.startOf('day');

            const zDay = day.startOf('day');

            /*
             * mark dates outside the date range as invalid
             * If future dates are enabled then ignore max Date and validate only min date
             * else validate both
             */
            if (options.dynamicDateOptions) {
                if (!zDay.isSameOrAfter(zMinDate, 'day')) {
                    return true;
                }
            } else if (!(zDay.isSameOrAfter(zMinDate, 'day') && zDay.isSameOrBefore(zMaxDate, 'day'))) {
                return true;
            }
        }

        // if the day being evaluated is the current day, check and see if cutoff time has passed
        const rightNow = moment(new Date());
        if (options.cutOffTimes?.length > 0 && day.isSame(rightNow, 'day')) {
            const times = options.cutOffTimes[0].split(':');
            if (times[0] !== '0' && times[1] !== '0') {
                if (rightNow.hour() >= times[0] && rightNow.minute() >= times[1]) {
                    return true;
                }
            }
        }

        // if the day being evaluated is a '0' in the "processingDays" string, mark it invalid
        if (options.processingDays?.toString()[day.day()] === '0') {
            return true;
        }

        /*
         * if the blockedDates array specifically calls out weekends as invalid
         * AND the 'allowWeekends' flag is false
         * AND the day being evaluated is a weekend
         * then return true
         */
        if (options.blockedDates?.includes('weekends') && !options.allowWeekends && (day.day() === 0 || day.day() === 6)) {
            return true;
        }

        return false;
    };

    options.getLastAvailableDate = function (calendar) {
        let lastValidDay = moment();

        for (let row = 0; row < 6; row += 1) {
            for (let col = 0; col < 7; col += 1) {
                const day = calendar[row][col];
                lastValidDay = !this.isInvalidDay(day) ? day : lastValidDay;
            }
        }

        return lastValidDay;
    };

    options.updateCalendars = function (updateOptions) {
        let firstDate;

        if (updateOptions) {
            if (typeof updateOptions.daysForward === 'number' || typeof updateOptions.daysForward === 'object') {
                // NonACH Types - StartDate may not be today, because of NextBusinessDay limits
                if (updateOptions.startDate) {
                    firstDate = moment(updateOptions.startDate, this.format, true);
                } else if (updateOptions.defaultDay && !updateOptions.isACH) {
                    firstDate = moment(updateOptions.defaultDay, this.format, true);
                } else {
                    firstDate = moment(new Date());
                }

                this.maxDate = firstDate.add(updateOptions.daysForward, 'days');
            } else if (typeof updateOptions.daysForward === 'string') {
                this.maxDate = moment(updateOptions.daysForward, this.format, true);
            } else {
                this.maxDate = updateOptions.daysForward;
            }

            /*
             * This value is provided by the date service and
             * represents the earliest date available for selection as
             * the effective date of an ACH payment.
             */
            if (updateOptions.minDaysForward > -1) {
                this.minDate = moment(new Date()).add(updateOptions.minDaysForward, 'days');
            } else if (typeof updateOptions.daysBack === 'number' || typeof updateOptions.daysBack === 'object') {
                this.minDate = moment(new Date()).subtract(updateOptions.daysBack, 'days');
            } else if (typeof updateOptions.daysBack === 'string') {
                this.minDate = moment(updateOptions.daysBack, this.format, true);
            } else {
                this.minDate = updateOptions.minDate;
            }

            /*
             * if earliestDay provided, that overrides the minDate.
             * User can not select any day earlier than the earliestDay
             */
            if (updateOptions.earliestDay && this.minDate.isBefore(updateOptions.earliestDay)) {
                this.minDate = moment(updateOptions.earliestDay, this.format, true);
            }

            // Add support for a default day.
            if (updateOptions.defaultDay) {
                this.defaultDay = moment(updateOptions.defaultDay, this.format, true);
            }

            // holidays
            if (updateOptions.blockedDates) {
                /*
                 * With ONE2ONE transfers we might have multiple date pickers
                 * on the page and this ensures they're scoped properly
                 */
                if (options.useThis) {
                    // multiple
                    this.blockedDates = updateOptions.blockedDates;
                } else {
                    // just one datepicker
                    options.blockedDates = updateOptions.blockedDates;
                }
            }
        }

        let startDate;
        let leftSelected;
        let rightSelected;
        if (this.singleDatePicker) {
            startDate = this.minDate;
            leftSelected = this.defaultDay ? this.defaultDay : this.startDate;
            rightSelected = this.endDate || leftSelected;
        } else {
            if (this.allowPreviousYearInRange) {
                startDate = this.minDate;
            } else {
                const start = this.startDate;
                startDate = start;
            }

            leftSelected = this.startDate;
            rightSelected = this.endDate;
        }

        this.chosenLabelOrig = this.chosenLabel;
        this.leftCalendar.calendar = this.buildCalendar(this.leftCalendar.month.month(), this.leftCalendar.month.year(), this.leftCalendar.month.hour(), this.leftCalendar.month.minute(), this.leftCalendar.month.second(), 'left');
        this.rightCalendar.calendar = this.buildCalendar(this.rightCalendar.month.month(), this.rightCalendar.month.year(), this.rightCalendar.month.hour(), this.rightCalendar.month.minute(), this.rightCalendar.month.second(), 'right');

        // get the last available date from the right calendar and set it like the end date
        const lastAvailableDate = dateUtil.getLastAvailableDate(
            this.rightCalendar.calendar,
            this.isInvalidDay.bind(this),
        );
        if (moment(rightSelected).unix() * 1000 > moment(lastAvailableDate).unix() * 1000) {
            this.endDate = lastAvailableDate;
        }

        this.container.find('.calendar.left').empty().html(this.renderCalendar(this.leftCalendar.calendar, leftSelected, this.minDate, this.maxDate, 'left'));
        this.container.find('.calendar.right').empty().html(this.renderCalendar(this.rightCalendar.calendar, rightSelected, startDate, this.maxDate, 'right'));

        this.container.find('.ranges li').removeClass('active');
        let customRange = true;

        Object.values(this.ranges).forEach((range, i) => {
            if (this.timePicker) {
                if (this.startDate.isSame(range[0])
                    && this.endDate.isSame(range[1])) {
                    customRange = false;
                    this.chosenLabel = this.container.find(`.ranges li:eq(${i})`);
                    this.chosenLabel = this.container.find(`.ranges li:eq(${i})`)
                        .addClass('active').html();
                }
            } else if (this.startDate.format('YYYY-MM-DD') === range[0].format('YYYY-MM-DD')
                && this.endDate.format('YYYY-MM-DD') === range[1].format('YYYY-MM-DD')) {
                // ignore times when comparing dates if time picker is not enabled
                customRange = false;
                this.chosenLabel = this.container.find(`.ranges li:eq(${i})`)
                    .addClass('active').html();
            }
        });
        if (customRange) {
            this.chosenLabel = this.container.find('.ranges li:last').addClass('active').html();
            this.showCalendars();
        }
    };

    if (options.daysBack > -1) {
        options.minDate = moment(new Date()).subtract(options.daysBack, 'days');
    }
    if (options.daysForward > -1) {
        options.maxDate = moment(new Date()).add(options.daysForward, 'days');
    }

    if (options.showCalendarIcon === true) {
        const iconClass = options.iconClass ? options.iconClass : '';
        const calendarIcon = $(`<span class="ui-datepicker-trigger ${iconClass} icon-calendar"></span>`);
        calendarIcon.on({
            click: () => {
                this.click();
            },
        });

        this.after(calendarIcon);
    }

    const $picker = originalDRP.apply(this, [options, cb]);
    const pickerObj = $picker.data('daterangepicker');

    if (options.allowPreviousYearInRange) {
        pickerObj.allowPreviousYearInRange = options.allowPreviousYearInRange;
        pickerObj.updateCalendars();
    }

    options.getActiveRange = function () {
        return this.chosenLabel;
    };

    options.setActiveRange = function (rangeLabel) {
        let rangeElement;
        let customRange = true;
        const li = '.daterangepicker .ranges li';

        $(`${li}.active`).last().removeClass();

        Object.keys(this.ranges).forEach((range, i) => {
            if (range === rangeLabel) {
                customRange = false;
                rangeElement = this.container.find(`.ranges li:eq(${i})`);
                $(`${li}#${rangeElement[0].id}`).last().addClass('active');
            }
        });

        if (customRange) {
            $(li).last().addClass('active');
        }
    };

    pickerObj.getActiveRange = options.getActiveRange;
    pickerObj.setActiveRange = options.setActiveRange;

    if (options.showSingleDateInRangePicker) {
        const $container = pickerObj.container;
        const $singleOption = $(`<span>${locale.get('common.datepicker.singleDate')}</span>`);

        pickerObj.singleDateMode = true;
        $singleOption.insertAfter($container.find('.ranges ul'));

        $singleOption.on('click', () => {
            pickerObj.singleDateMode = true;
            pickerObj.singleDatePicker = true;
            $container.find('.ranges .active').removeClass('active');
            $singleOption.addClass('active');
            pickerObj.showCalendars();
        });

        $container.find('.ranges li').on('click', () => {
            pickerObj.singleDateMode = false;
            pickerObj.singleDatePicker = false;
            $singleOption.removeClass('active');
        });

        $picker.on('apply.daterangepicker', () => {
            if (pickerObj.singleDateMode) {
                pickerObj.setEndDate(pickerObj.startDate.clone());
            }
            pickerObj.cb(pickerObj.startDate.clone(), pickerObj.endDate.clone());
        });

        $picker.on('cancel.daterangepicker', () => {
            if (pickerObj.startDate.diff(pickerObj.endDate, 'days') !== 0) {
                pickerObj.singleDateMode = false;
                $singleOption.removeClass('active');
                $container.removeClass('single-date-in-range');
            }
        });

        $picker.on('showCalendar.daterangepicker', () => {
            if (pickerObj.singleDateMode) {
                $container.addClass('single-date-in-range');
            } else {
                $container.removeClass('single-date-in-range');
            }
        });
    }
    // NH-170309
    // shift the focus to calender option range while down key press
    $picker.on('keydown', (e) => {
        const keycode = e.which || e.keyCode || 0;
        if (keycode === $.ui.keyCode.DOWN) {
            e.preventDefault();
            pickerObj.container.find('.ranges ul li')
                .attr({
                    role: 'button',
                    tabindex: 0,
                }).first().focus();
        }
    });
    // handling keyboard event of calender option range
    $('.ranges ul').on('keydown', 'li', (e) => {
        const keycode = e.which || e.keyCode || 0;
        switch (keycode) {
        case $.ui.keyCode.RIGHT:
        case $.ui.keyCode.DOWN:
            if ($(e.currentTarget).is(':last-child')) {
                $('.ranges ul li').first().focus();
            } else {
                e.currentTarget.nextElementSibling.focus();
            }
            break;
        case $.ui.keyCode.UP:
        case $.ui.keyCode.LEFT:
            if ($(e.currentTarget).is(':first-child')) {
                $('.ranges ul li').last().focus();
            } else {
                e.currentTarget.previousElementSibling.focus();
            }
            break;
        case $.ui.keyCode.ENTER:
        case $.ui.keyCode.SPACE:
            // 'mousedown.daterangepicker' will trigger the click event in daterangepicker
            // when user press enter key or space key
            $(e.currentTarget).trigger('mousedown.daterangepicker');
            $picker.focus();
            break;
        case $.ui.keyCode.ESCAPE:
        case $.ui.keyCode.TAB:
            pickerObj.hide();
            e.preventDefault();
            $picker.focus();
            break;
        default:
            break;
        }
    });
    return $picker;
}

// create a new method to extend the default behavior of this component

$.fn.nhDatePicker = function (opts, cb) {
    // TODO: override behavior for autocomplete.

    const options = opts || {};
    // const this = this;

    options.showSingleDateInRangePicker = options.singleDatePicker
        ? false : (options.showSingleDateInRangePicker || false);

    /*
     * default options
     * use the date format set in the user info object
     * but we need to upper case the string as moment has different
     * formatting than regular javascript and java.
     */
    options.format = options.format || userInfo.getDateFormat().toUpperCase();

    options.blockedDates = options.blockedDates || [];
    options.processingDays = options.processingDays || false;
    options.cutOffTimes = options.cutOffTimes || false;
    options.allowWeekends = options.allowWeekends || false;
    options.daysBack = (Object.prototype.hasOwnProperty.call(options, 'daysBack')) ? options.daysBack : -1;
    options.daysForward = (Object.prototype.hasOwnProperty.call(options, 'daysForward')) ? options.daysForward : -1;
    options.singleDatePicker = (Object.prototype.hasOwnProperty.call(options, 'rangeDatePicker')) ? !options.rangeDatePicker : true;
    options.showSingleDateInRangePicker = (Object.prototype.hasOwnProperty.call(options, 'showSingleDateInRangePicker')) ? options.showSingleDateInRangePicker : false;
    options.allowPreviousYearInRange = (Object.prototype.hasOwnProperty.call(options, 'allowPreviousYearInRange')) || false;
    options.pastOnly = options.pastOnly || false;

    /*
     * 2016-09-12 - samuel.rouse
     * options.timeZone was removed for NH-30827 to solve
     * timezone offset issues but introduced different issues
     * For the time being we have re-added options.timeZone
     * commented out like the utcOffset function to preserve
     * the post-NH-30827 timing while avoiding rangePicker dates near 1970.
     * I suggest we add some unit tests for NH-31022 and NH-30827 before we make
     * more changes.
     */

    /*
     * FIXME: We should be able to use the offset
     * in some places and correct our math for NH-30827
     * options.timeZone = parseInt(userInfo.get('utcOffset'), 10);
     * var now = moment(new Date()).utcOffset(options.timeZone);
     */

    const now = moment(new Date());
    const prevBusinessDate = dateUtil.getPriorDate();
    let endDay = moment(now);
    let startDay = moment(now);

    if (options.pastOnly) {
        const current = moment(now).subtract(1, 'days');
        endDay = moment(current);
        startDay = moment(current);
    }
    if (!options.singleDatePicker) {
        options.ranges = {};

        if (options.dynamicDateOptions) {
            getDateRanges(options.payload).then((results) => {
                this.dateRange = results;
                this.dateRange.forEach((range) => {
                    if (range.operation === 'add') {
                        options.ranges[range.label] = [
                            endDay,
                            moment(startDay)[range.operation](range.duration, range.unit),
                        ];
                    } else {
                        options.ranges[range.label] = [
                            moment(startDay)[range.operation](range.duration, range.unit),
                            endDay,
                        ];
                    }
                });
                const sendThis = callCommonConfig.call(this, options, cb);
                return sendThis;
            });
        } else {
            options.ranges[$.trim(locale.get('common.datepicker.last7Days'))] = [moment(startDay).subtract(6, 'days'), endDay];
            options.ranges[$.trim(locale.get('common.datepicker.last30Days'))] = [moment(startDay).subtract(29, 'days'), endDay];
            options.ranges[$.trim(locale.get('common.datepicker.last60Days'))] = [moment(startDay).subtract(59, 'days'), endDay];
            options.ranges[$.trim(locale.get('common.datepicker.last90Days'))] = [moment(startDay).subtract(89, 'days'), endDay];
            options.ranges[$.trim(locale.get('common.datepicker.monthToDate'))] = [moment(startDay).startOf('month'), endDay];
            options.ranges[$.trim(locale.get('common.datepicker.quarterToDate'))] = [moment(startDay).startOf('quarter'), endDay];
            options.ranges[$.trim(locale.get('common.datepicker.yearToDate'))] = [moment(startDay).startOf('year'), endDay];
            if (!options.showSingleDateInRangePicker && options.pastOnly !== true) {
                options.ranges[$.trim(locale.get('common.datepicker.today'))] = [moment(now).startOf('day'), moment(now).endOf('day')];
            }
            options.ranges[locale.get('common.datepicker.priorDay')] = [prevBusinessDate, prevBusinessDate];
            // first date of previous month to last date of previous month
            options.ranges[locale.get('common.datepicker.lastMonth')] = [moment(startDay).subtract(1, 'months').startOf('month'), moment(startDay).subtract(1, 'months').endOf('month')];
            const sendThis = callCommonConfig.call(this, options, cb);
            return sendThis;
        }
    }
    const sendThis = callCommonConfig.call(this, options, cb);
    return sendThis;
};
export default {};
