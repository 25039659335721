export default function (form) {
    const showCaseId = form.field('SHOWCASEID');
    const showCaseSmallText = form.field('SMALLTEXT');
    const showCaseLargeText = form.field('LARGETEXT');
    const showCaseAddr2 = form.field('ADDRESS2');
    const showCaseAddr3 = form.field('ADDRESS3');
    const showCaseAddMore = form.field('ADDMORE');

    const addRequiredClass = function ($el) {
        $el.parent().addClass('required');
    };

    const removeRequiredClass = function ($el) {
        $el.parent().removeClass('required');
    };

    showCaseId
        .shouldNotMatchField(showCaseSmallText)
        .shouldBeRequired();

    showCaseSmallText
        .shouldBeHidden()
        .shouldBeVisibleWhen(showCaseId.isNotEmpty());

    showCaseLargeText
        .shouldBeHidden()
        .shouldBeVisibleWhen(showCaseId.hasValue('123456'))
        .shouldBeRequiredWhen(showCaseId.hasValue('123456'));

    if (showCaseId.hasValue('123456')) {
        addRequiredClass(showCaseLargeText.$el);
    } else {
        removeRequiredClass(showCaseLargeText.$el);
    }

    // State Mandatory depends on State Mandatory Combo
    form.field('STATE_MANDATORY')
        .shouldBeHidden()
        .shouldBeVisibleWhen(form.field('STATEDESC_MANDATORY_CB').isNotEmpty());

    // Bankcode Mandatory depends on Bankcode Mandatory Combo
    form.field('BANKCODE_MANDATORY')
        .shouldBeHidden()
        .shouldBeVisibleWhen(form.field('ACCOUNTNUM_MANDATORY_CB').isNotEmpty());

    // Currency Code Mandatory depends on Bankcode Mandatory Combo
    form.field('CURRENCYCODE_MANDATORY')
        .shouldBeHidden()
        .shouldBeVisibleWhen(form.field('ACCOUNTNUM_MANDATORY_CB').isNotEmpty());

    // State depends on State Combo
    form.field('STATE')
        .shouldBeHidden()
        .shouldBeVisibleWhen(form.field('STATEDESC_CB').isNotEmpty());

    // Bankcode depends on Account Number Combo
    form.field('BANKCODE')
        .shouldBeHidden()
        .shouldBeVisibleWhen(form.field('ACCOUNTNUM_CB').isNotEmpty());

    // Currency Code depends on Account Number Combo
    form.field('CURRENCYCODE')
        .shouldBeHidden()
        .shouldBeVisibleWhen(form.field('ACCOUNTNUM_CB').isNotEmpty());

    showCaseAddr2
        .shouldBeVisibleWhen(showCaseAddr2.isNotEmpty());

    showCaseAddr3
        .shouldBeVisibleWhen(showCaseAddr3.isNotEmpty());

    // Shows how to implement behavior for a hyperlink, e.g. add more
    showCaseAddMore
        .shouldBeVisibleWhen(showCaseAddr3.isNotVisible())
        .$el.on('click', (e) => {
            e.stopImmediatePropagation();
            if (showCaseAddr2.isVisible()) {
                showCaseAddr3.shouldBeVisible();
                showCaseAddMore.shouldBeHidden();
            } else {
                showCaseAddr2.shouldBeVisible();
            }
        });
}
