export default {
  headerCell: {
    apply: 'Apply',
    clear: 'Clear',
    left: 'Left',
    menu: 'Menu',
    pin: 'Pin',
    pinColumn: 'Pin Column',
    right: 'Right',
    sort: 'Sort',
    unpin: 'Remove Pin'
  }
};
