export default (theme, isPreviewOnly) => `
html,
body {
    box-sizing: border-box;
    min-height: 400px;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 11pt;
}
html {
    ${!isPreviewOnly && `
        background: ${theme.palette.highlightBackground};
        padding: 32px 40px;
    `}
    margin: 0;
}
body {
    background: ${theme.palette.background};
    margin: 0;
    padding: 5px 15px;
    position: relative;
}

.variable {
    display: inline-flex;
    align-items: center;
    background: #cbe4ff;
    border-radius: 15px;
    padding: 5px 5px 5px 10px;
}
.variable span {
    color: ${theme.palette.text.dark};
    margin-right: 5px;
}
.variable svg {
    cursor: pointer;
}
.variable svg path {
    fill: #0070b9;
}

.variable.dark {
    background: #0072b6;
    width: fit-content;
    width: -moz-fit-content;
}
.variable.dark span {
    color: ${theme.palette.text.light};
}
.variable.dark svg path {
    fill: ${theme.palette.text.light};
}

.text-preview {
    background-color: #eee;
    border-radius: 10px 10px 10px 0;
    margin: 20px;
    padding: 10px 10px 15px;
    top: 20px;
    white-space: pre-wrap;
    width: 300px;
}
.text-preview:after {
    background: #eee;
    transform: translateY(60%);
    content: "";
    height: 20px;
    left: 35px;
    position: absolute;
    width: 40px;
    z-index: 1;
    clip-path: ellipse(14px 16px at 0% 20%);
}
`;
