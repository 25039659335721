export default ({ palette }) => ({
  button: {
    fontSize: 16
  },
  header: {
    marginBottom: 8
  },
  icon: {
    '& svg': {
      fill: palette.text.dark,
      height: 18,
      width: 18
    },
    marginRight: 8
  },
  label: {
    color: palette.text.dark,
    fontSize: 14,
    fontWeight: 'normal',
    marginRight: 12
  },
  pinned: {
    fontWeight: 'bold'
  },
  separator: {
    borderLeft: `1px solid ${palette.form.border}`,
    margin: [0, 10]
  }
});
