var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"reports.back.to.previous",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":144},"end":{"line":3,"column":181}}}))
    + "</span></a>\n    </div>\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.EnvelopeDetails",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":63}}}))
    + "</h1>\n</div>\n\n<div class=\"section section-container\">\n    <div class=\"section-header\">\n        <div class=\"glu-container\">\n            <div class=\"row\">\n                <div class=\"col-12 alert-region\" data-region=\"alertRegion\"></div>\n            </div>\n            <div class=\"row\">\n                <div class=\"col-5\">\n                    <h2>"
    + alias2(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":16,"column":24},"end":{"line":16,"column":33}}}) : helper)))
    + "</h3>\n                </div>\n            </div>\n            <div class=\"row\">\n                <button class=\"image-indicator\" data-action=\"showImage\">\n                    <span class=\"icon-image-indicator\"></span>\n                    <span class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.image.view",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":42},"end":{"line":22,"column":72}}}))
    + "</span>\n                </button>\n            </div>\n            <div class=\"row\">\n                <div class=\"col-2\">\n                    <div class=\"form-group\">\n                        <label for=\"lbxEntries\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.Entries",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":48},"end":{"line":28,"column":75}}}))
    + "</label>\n                        <p id=\"lbxEntries\" class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"NUM_TRANSACTIONS") || (depth0 != null ? lookupProperty(depth0,"NUM_TRANSACTIONS") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"NUM_TRANSACTIONS","hash":{},"data":data,"loc":{"start":{"line":29,"column":71},"end":{"line":29,"column":91}}}) : helper)))
    + "</p>\n                    </div>\n                </div>\n                <div class=\"col-2\">\n                    <div class=\"form-group\">\n                        <label for=\"lbxAmount\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.Amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":34,"column":47},"end":{"line":34,"column":70}}}))
    + "</label>\n                        <p id=\"lbxAmount\" class=\"form-control-static\">"
    + alias2(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"TOTAL_AMOUNT") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":35,"column":70},"end":{"line":35,"column":93}}}))
    + "</p>\n                    </div>\n                </div>\n                <div class=\"col-2\">\n                    <div class=\"form-group\">\n                        <label for=\"lbxFilter\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.Lockbox",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":40,"column":47},"end":{"line":40,"column":71}}}))
    + "</label>\n                        <p id=\"lbxFilter\" class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"LOCKBOXFILTER") || (depth0 != null ? lookupProperty(depth0,"LOCKBOXFILTER") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"LOCKBOXFILTER","hash":{},"data":data,"loc":{"start":{"line":41,"column":70},"end":{"line":41,"column":87}}}) : helper)))
    + "</p>\n                    </div>\n                </div>\n                <div class=\"col-2\">\n                    <div class=\"form-group\">\n                        <label for=\"lbxDepositDate\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.DepositDate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":46,"column":52},"end":{"line":46,"column":80}}}))
    + "</label>\n                        <p id=\"lbxDepositDate\" class=\"form-control-static\">"
    + alias2(lookupProperty(helpers,"date").call(alias1,(depth0 != null ? lookupProperty(depth0,"DEPOSIT_DATE") : depth0),{"name":"date","hash":{},"data":data,"loc":{"start":{"line":47,"column":75},"end":{"line":47,"column":96}}}))
    + "</p>\n                    </div>\n                </div>\n                <div class=\"col-2\">\n                    <div class=\"form-group\">\n                        <label for=\"lbxBankBatchNumber\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.bankBatchNumber",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":52,"column":56},"end":{"line":52,"column":88}}}))
    + "</label>\n                        <p id=\"lbxBankBatchNumber\" class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"BANKBATCH") || (depth0 != null ? lookupProperty(depth0,"BANKBATCH") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"BANKBATCH","hash":{},"data":data,"loc":{"start":{"line":53,"column":79},"end":{"line":53,"column":92}}}) : helper)))
    + "</p>\n                    </div>\n                </div>\n                <div class=\"col-2\">\n                    <div class=\"form-group\">\n                        <label for=\"lbxCustomerBatchNumber\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.customerBatchNumber",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":58,"column":60},"end":{"line":58,"column":96}}}))
    + "</label>\n                        <p id=\"lbxCustomerBatchNumber\" class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"CUSTBATCH") || (depth0 != null ? lookupProperty(depth0,"CUSTBATCH") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"CUSTBATCH","hash":{},"data":data,"loc":{"start":{"line":59,"column":83},"end":{"line":59,"column":96}}}) : helper)))
    + "</p>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class=\"section-body\">\n        <div class=\"row\">\n            <div class=\"lockbox-summary-region\" data-region=\"gridRegion\"></div>\n        </div>\n        <div class=\"row\">\n            <div class=\"panel panel-tertiary\">\n                <div class=\"panel-heading\" role=\"tab\" id=\"transactionNotes\">\n                    <h3 class=\"panel-title\">\n                        <a role=\"button\" data-toggle=\"collapse\" href=\"#collapse1\" aria-expanded=\"false\" aria-controls=\"collapse1\" class=\"collapsed\">\n                            <span class=\"indicator-icon\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.transactionNotes",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":74,"column":65},"end":{"line":74,"column":101}}}))
    + " <span data-hook=\"noteCount\">("
    + alias2(((helper = (helper = lookupProperty(helpers,"NOTE_COUNT") || (depth0 != null ? lookupProperty(depth0,"NOTE_COUNT") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"NOTE_COUNT","hash":{},"data":data,"loc":{"start":{"line":74,"column":131},"end":{"line":74,"column":145}}}) : helper)))
    + ")</span>\n                        </a>\n                    </h3>\n                </div>\n                <div id=\"collapse1\" class=\"panel-collapse collapse\" role=\"tabpanel\" aria-labelledby=\"transactionNotes\" aria-expanded=\"false\">\n                    <div class=\"panel-body\">\n                        <div class=\"btn-wrapper\">\n                            <div class=\"btn-group widget-links\">\n                                <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"manageNotes\"><span class=\"icon-add-circle\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.AddNote",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":82,"column":143},"end":{"line":82,"column":167}}}))
    + "</button>\n                            </div>\n                        </div>\n                        <div data-region=\"notesRegion\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});