import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Input, CheckboxRadio } from '@glu/form-components';
import locale from '@glu/locale';
import { withStyles } from '@glu/theming';
import ColumnsDragAndDropper from './ColumnsDragAndDropper';
import { styles } from './Columns.styles';
import FilterActions from '../ApplyFilterActions/FilterActions';
import StyleContext from '../StyleContext/StyleContext';
import { filterColumnsByLockAndPrimary, getColumnsFromOrderChange } from './primaryColumnUtils';
import { setHidden, setPins } from './columnActionsUtils';
import '../../themeDefaults';

const defaultFilteredData = {
  locked: null,
  otherColumns: null,
  primaryColumns: null
};

export function ColumnComponent({
  alwaysShowDragTrigger,
  autoApplyChanges,
  classes,
  data: rawData,
  disablePinning,
  maxPrimary,
  onCancel,
  onChange,
  onShowAll,
  onUnpinAll,
  ...props
}) {
  const [data, setData] = useState(rawData);
  const [filterValue, setFilterValue] = useState('');
  const [filteredData, setFilteredData] = useState(defaultFilteredData);
  const [showAll, setShowAll] = useState(
    data.columns.filter((column) => column.hide).length === 0
  );
  const [showUnpinAll, setShowUnpinAll] = useState(
    data.columns.filter((column) => column.pinned).length > 0
  );

  useEffect(() => {
    setData(rawData);
  }, [rawData]);

  const filteredColumns = filterColumnsByLockAndPrimary(data.columns);
  const {
    locked,
    otherColumns,
    primaryColumns
  } = filteredColumns;

  const applyChanges = useCallback(({ name, value }) => {
    if (autoApplyChanges) {
      onChange({
        errors: {},
        name,
        value
      });
    } else {
      setData((currData) => ({
        ...currData,
        [name]: value
      }));
    }
  }, [autoApplyChanges, onChange]);

  const handleColumnFilter = (event) => {
    const columns = data.columns
      .filter((column) => column
        .headerName
        .toLowerCase()
        .includes(event.target.value.toLowerCase()));
    const currentFilteredData = filterColumnsByLockAndPrimary(columns);
    setFilterValue(event.target.value);
    // sets a temporary copy of data for UI display so that data doesn't get replaced
    setFilteredData(currentFilteredData);
  };

  const showHideAll = () => {
    setShowAll(!showAll);
    const hiddenColumns = setHidden({
      columns: [...primaryColumns, ...otherColumns],
      showAll
    });
    applyChanges({ name: 'columns', value: [...locked, ...hiddenColumns] });

    if (filteredData.locked || filteredData.otherColumns || filteredData.primaryColumns) {
      setFilteredData({
        locked,
        otherColumns: setHidden({ columns: filteredData.otherColumns, showAll }),
        primaryColumns: setHidden({ columns: filteredData.primaryColumns, showAll })
      });
    }
  };

  const handleColumnPinning = ({ field, position }) => {
    const dataPins = setPins({ columns: data.columns, field, position });
    setShowUnpinAll(dataPins.filter((column) => column.pinned).length > 0);
    applyChanges({ name: 'columns', value: dataPins });

    if (filteredData.locked || filteredData.otherColumns || filteredData.primaryColumns) {
      setFilteredData({
        locked,
        otherColumns: setPins({ columns: filteredData.otherColumns, field, position }),
        primaryColumns: setPins({ columns: filteredData.primaryColumns, field, position })
      });
    }
  };

  const handleOrderChange = useCallback((result) => {
    // If no destination. Out of context.
    if (!result.destination) {
      return;
    }
    const newColumns = getColumnsFromOrderChange({
      data, filteredColumns, maxPrimary, result
    });
    applyChanges({ name: 'columns', value: newColumns });
  }, [applyChanges, data, filteredColumns, maxPrimary]);

  const handleHideChange = (field) => {
    const dataHidden = setHidden({ columns: data.columns, field });
    const hiddenColumns = dataHidden.filter((column) => {
      if (column.showHideDisallowed) { return false; }
      return column.hide;
    });
    applyChanges({ name: 'columns', value: dataHidden });

    if ((showAll && hiddenColumns.length > 0) || (!showAll && hiddenColumns.length === 0)) {
      setShowAll(!showAll); // sets UI state of toggle but doesn't update overall state
    }

    if (filteredData.locked || filteredData.otherColumns || filteredData.primaryColumns) {
      setFilteredData({
        locked,
        otherColumns: setHidden({ columns: filteredData.otherColumns, field, showAll }),
        primaryColumns: setHidden({ columns: filteredData.primaryColumns, field, showAll })
      });
    }
  };

  const handleApply = () => {
    onChange({
      errors: {},
      name: 'columns',
      value: data.columns
    });
  };

  const handleCancel = () => {
    onCancel();
    setShowAll(
      rawData.columns.filter((column) => column.hide).length === 0
    );
    onChange({
      errors: {},
      name: 'columns',
      value: rawData.columns
    });
  };

  const unpinAll = () => handleColumnPinning({
    field: null,
    position: null
  });

  return (
    <StyleContext.Provider value={classes}>
      <div className={classes.columnActions}>
        <Input
          type="text"
          className={classes.columnFilter}
          onChange={handleColumnFilter}
          name="findColumnByName"
          labelText={locale.get('dataComponents.column.search')}
          value={filterValue}
          disableInputAPI
        />
        <span>{locale.get('dataComponents.columns')}</span>

        {(!disablePinning && showUnpinAll) && (
          <button
            type="button"
            onClick={onUnpinAll || unpinAll}
          >
            {locale.get('dataComponents.unpinAllColumns')}
          </button>
        )}
      </div>
      <div className={classes.contentScroll}>
        <div className={classes.wrapper}>
          <div className={classes.itemWrapper}>
            <CheckboxRadio
              checked={showAll}
              data-qa="switch-show-all"
              labelText={locale.get('dataComponents.selectAll')}
              name="showAll"
              onChange={onShowAll || showHideAll}
            />
          </div>
          <ColumnsDragAndDropper
            data={data}
            locked={filteredData.locked || locked}
            primaryColumns={filteredData.primaryColumns || primaryColumns}
            otherColumns={filteredData.otherColumns || otherColumns}
            maxPrimary={maxPrimary}
            onOrderChange={handleOrderChange}
            onHideChange={handleHideChange}
            alwaysShowDragTrigger={alwaysShowDragTrigger}
            handleColumnPinning={handleColumnPinning}
            disablePinning={disablePinning}
            disableDrag={filterValue !== ''} /* disable drag/drop while filtering results */
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...props}
          />
        </div>
      </div>
      {!autoApplyChanges && <FilterActions onClick={handleApply} cancel={handleCancel} />}
    </StyleContext.Provider>
  );
}

ColumnComponent.propTypes = {
  alwaysShowDragTrigger: PropTypes.bool,
  autoApplyChanges: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,

  data: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.shape({
      // A unique identifier for each column
      field: PropTypes.string.isRequired,
      // The name that will appear to the user
      headerName: PropTypes.string.isRequired,
      // Determines whether a column is show or hidden
      hide: PropTypes.bool,

      /*
       * Should be unique per set of columns.
       * The column with `lockPosition` and all prior cannot be shifted.
       */
      lockPosition: PropTypes.bool,

      /** Remove column from display in drag and drop */
      menuDisplay: PropTypes.bool,

      /*
       * This field dictates whether a column will show up in the "primary" sections of columns.
       * The primary columns will appear below locked columns and above other columns.
       * As of this implementation, primary columns should be before non-primary in the this array.
       */
      primary: PropTypes.bool,
      /** Prevent show/hide toggle for this column */
      showHideDisallowed: PropTypes.bool
    }))
  }).isRequired,

  disablePinning: PropTypes.bool,

  maxPrimary: PropTypes.number,
  /** Called when `Cancel` is clicked  */
  onCancel: PropTypes.func,
  onChange: PropTypes.func.isRequired,

  /** Custom functionality for "Hide all" button. Defaults to overriding predfined values */
  onShowAll: PropTypes.func,

  /** Custom functionality for "Unpin all" button. Defaults to clearing all pins */
  onUnpinAll: PropTypes.func
};

ColumnComponent.defaultProps = {
  alwaysShowDragTrigger: false,
  autoApplyChanges: false,
  disablePinning: false,
  maxPrimary: 0,
  onCancel: () => { },
  onShowAll: undefined,
  onUnpinAll: undefined
};

export default withStyles(styles)(ColumnComponent);
