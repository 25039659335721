export default {
    TOALOCATION_CONTEXT: 'CMAINT_TOALOC',
    TOALOCATION_RETURNROUTE: 'CMAINT/listTOALocations',
    TOALOCATION_PRODUCTCODE: 'CMAINT',
    TOALOCATION_FUNCTIONCODE: 'MAINT',
    TOALOCATION_TYPECODE: 'TOALOC',
    TOALOCATION_ACCOUNTTYPE: 'ACCT_TM',
    TOALOCATION_SERVICENAME: 'timeOpenAccountLocations',
    TOALOCATION_INSERT_ROUTE: 'CMAINT/toaLocationAdd/',
    TOALOCATION_MODIFY_ROUTE: 'CMAINT/toaLocationModify/',
    TOALOCATION_VIEW_ROUTE: 'CMAINT/toaLocationView/',
    TOALOCATION_DETAIL_SERVICENAME: 'timeOpenAccountLocations',
    TOALOCATION_PREFILL: 'CMAINT-TOALOCATION_CONTEXT-INSERT-PREFILL',
    TOALOCATION_ASSOCACCT_SERVICENAME: 'tableMaint',
    TOALOCATION_ASSOCACCT_INQUIRYID: '25204',
    TOALOCATION_ASSOCACCT_VIEWID: '25204',
};
