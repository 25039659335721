import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const initialState = {
  expandedRows: [],
  filters: []
};

const GridContext = createContext({
  ...initialState,
  toggleExpandOnRow: () => {},
  setFilters: () => {}
});

const actions = {
  TOGGLE_EXPAND_ON_ROW: 'TOGGLE_EXPAND_ON_ROW',
  SET_FILTERS: 'SET_FILTERS'
};

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actions.TOGGLE_EXPAND_ON_ROW: {
      const { expandedRows } = state;
      const { rowId } = action;
      if (expandedRows.includes(rowId)) {
        return {
          ...state,
          expandedRows: expandedRows.filter(r => r !== rowId)
        };
      }
      return {
        ...state,
        expandedRows: expandedRows.concat(rowId)
      };
    }
    case actions.SET_FILTERS: {
      return {
        ...state,
        filters: action.filters
      };
    }
    default:
      return state;
  }
};

const GridProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <GridContext.Provider
      value={{
        expandedRows: state.expandedRows,
        filters: state.filters,
        toggleExpandOnRow: rowId => dispatch({ type: actions.TOGGLE_EXPAND_ON_ROW, rowId }),
        setFilters: filters => dispatch({ type: actions.SET_FILTERS, filters })
      }}
    >
      {children}
    </GridContext.Provider>
  );
};

GridProvider.propTypes = {
  children: PropTypes.node.isRequired
};

const withGridContextHOF = WrappedComponent => function withGridContext(props) {
  return (
    <GridProvider>
      <WrappedComponent {...props} />
    </GridProvider>
  );
};

export { withGridContextHOF as withGridContext };
export { GridProvider };
export default GridContext;
