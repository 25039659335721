import Layout from '@glu/core/src/layout';
import c3 from 'c3';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import $ from 'jquery';
import Formatter from 'system/utilities/format';
import { createTabsToggleButton, toggleTabs, setTabButtonText } from 'common/util/a11y/tabs';
import PlanningConfiguration from './configuration';
import ReadOnlyRowView from './readOnlyRowView';
import DateRowView from './dateRowView';
import DisplayFactorView from './displayFactorView';
import CategoryPlanModel from './categoryPlanModel';
import rowDistributionViewTmpl from './rowDistributionView.hbs';

const fiscalYearStartMonth = PlanningConfiguration.get('fiscalYearStartMonth');
const MASK_OPTIONS = Formatter.getCurrencyMaskOptions();
MASK_OPTIONS.digits = 0;

const RowDistributionView = Layout.extend({
    template: rowDistributionViewTmpl,
    className: 'modal cashflow-planning-row-distribution-editor',

    ui: {
        $navTabs: '[data-hook="getNavTabs"]',
        $navItems: '[data-hook="getNavTabs"] .NavTabs-item',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
    },

    events: {
        'click .modal-footer .btn-primary': 'save',
        'click @ui.$navLinks': 'changeTab',

        /*
         * direct keyup listeners should replace handleKeyup
         * need to ensure that empty fields get filled in with 0 on blur too
         */
        'change #flat': 'applyFlatValues',

        'keyup #flat': 'applyFlatValues',
        'change #percentage': 'applyPercentageValues',
        'keyup #percentage': 'applyPercentageValues',
        'change #trend-line': 'applyLineFitValues',
        'keyup #trend-line': 'applyLineFitValues',
        'focusout input[type="text"]': 'handleFocusout',
        'focusout input[type="number"]': 'handleFocusout',
    },

    updateSelectedLineShape() {
        /*
         * this fixes an issue with bootstrap radio button...buttons
         * the user's input can be lost when tabbing in and out of the buttons
         * this cache's the last selection that was explicitly made
         */
        this.selectedLineShapeButton = this.$('.line-shape-buttons input:checked').parent('.btn');
        if (this.selectedLineShapeButton.length === 0) {
            this.selectedLineShapeButton = this.$('.line-shape-buttons input').eq(0).parent('.btn');
        }
    },

    behaviors: {
        NumberInputAcceleration: {},
    },

    initialize(options) {
        this.flowType = options.flowType;
        this.workingModel = new CategoryPlanModel(util.clone(options.model.attributes));
        this.viewModel = options.viewModel;

        this.listenTo(
            this.workingModel,
            {
                'change:startValue': this.updateStartInputs,
                'change:12': this.updateEndInputs,
                'change:startValue change:12': this.updateLineValues,
            },
            this,
        );

        this.workingModel.set({
            startValue: this.workingModel.get(1),
        });
    },

    regions: {
        displayFactorRegion: '[data-region="display-factor"]',
        thead: 'thead',
        tbody: 'tbody',
    },

    templateHelpers() {
        return {
            categoryName: this.model.get('name'),
            flowType: this.flowType,
            flowTypeClassName: this.flowType.toLowerCase(),
        };
    },

    handleFocusout(e) {
        const $target = this.$(e.currentTarget);
        if ($target.val() === '') {
            $target.val(0);
        }
    },

    updateStartInputs() {
        /*
         * check whether the input already has this value
         * otherwise, we move the cursor to the end of a focused field
         */
        util.each(this.$('input[name="create-distribution-start"]'), function (el) {
            const $el = this.$(el);
            const displayStartValue = this.workingModel.get('startValue');
            if (+$el.val() !== displayStartValue) {
                $el.val(displayStartValue);
            }
        }, this);
    },

    updateEndInputs() {
        util.each(this.$('input[name="create-distribution-end"]'), function (el) {
            const $el = this.$(el);
            const displayEndValue = this.workingModel.get(12);

            if (+$el.val() !== displayEndValue) {
                $el.val(displayEndValue);
            }
        }, this);
    },

    updateStartValue(e) {
        this.workingModel.set({
            startValue: +this.$(e.currentTarget).find('input[name="create-distribution-start"]').val(),
        });
    },

    shouldIgnoreEvent(e) {
        // NumberInputAcceleration will handle these
        return (e.type === 'keyup' && (e.keyCode === $.ui.keyCode.UP || e.keyCode === $.ui.keyCode.DOWN));
    },

    applyFlatValues(e) {
        if (this.shouldIgnoreEvent(e)) {
            return;
        }

        this.updateStartValue(e);

        const values = {};
        const monthlyValue = this.workingModel.get('startValue');

        util.each(util.range(1, 13), (val) => {
            values[val] = monthlyValue;
        });

        this.workingModel.set(values);
    },

    applyPercentageValues(e) {
        if (this.shouldIgnoreEvent(e)) {
            return;
        }

        this.updateStartValue(e);

        const values = {};
        const years = util.range(1, 13);
        const value = this.workingModel.get('startValue');

        // decimal percent
        const percent = (+this.$('[name="distribution-percent"]').val()) / 100;

        // year or month
        const period = this.$('[name="distribution-period"]').val();

        if (period === 'year') {
            const step = (percent * value) / (years.length - 1);
            util.each(years, (i) => {
                values[i] = Math.round(value + (step * (i - 1)));
            });
        } else {
            util.each(years, (i, index) => {
                if (index === 0) {
                    values[i] = value;
                } else {
                    values[i] = Math.round(values[i - 1] * (1 + percent));
                }
            });
        }

        this.workingModel.set(values);
    },

    getLineFitValues(start, end, power, startSteep) {
        const values = {};

        if (startSteep) {
            // \___ shaped lines!
            util.each(util.range(-11, 1), (month) => {
                const y = (((start - end) / ((-11) ** power))
                    * (month ** power)) + end;
                values[month + 12] = Math.round(y);
            });
        } else {
            // ____/ shaped lines - as power increases, it stays flat for longer
            util.each(util.range(1, 13), (month) => {
                const y = (((end - start) / (11 ** power))
                    * ((month - 1) ** power)) + start;
                values[month] = Math.round(y);
            });
        }
        return values;
    },

    onShow() {
        this.updateSelectedLineShape();
        this.updateLineValues();
        this.updateStartInputs();
        this.updateEndInputs();

        this.thead.show(new DateRowView({
            fiscalYearStartMonth,
        }));
        this.tbody.show(new ReadOnlyRowView({
            fiscalYearStartMonth,
            model: this.workingModel,
            viewModel: this.viewModel,
        }));
        this.displayFactorRegion.show(new DisplayFactorView({
            model: this.viewModel,
        }));
    },

    onRender() {
        createTabsToggleButton(this);
        this.currentTabText = locale.get('cashflow.flat');
        setTabButtonText(this, this.currentTabText);
        this.$('input[type="text"]').inputmask('decimal', MASK_OPTIONS);
    },

    changeTab(e) {
        this.ui.$navItems.removeClass('is-active');
        $(e.currentTarget).parent().addClass('is-active');

        this.currentTabText = $(e.currentTarget).text();
        toggleTabs(this);
    },

    applyLineFitValues(e) {
        if (this.shouldIgnoreEvent(e)) {
            return;
        }

        this.updateStartValue(e);

        const end = +this.$('#create-distribution-end').val();
        const start = +this.$('#create-distribution-start-trend-line').val();
        const $button = this.$('input[name="line-shape"]:checked').parent('.btn'); // this.selectedLineShapeButton,
        const power = +$button.data('power');
        const startSteep = $button.is('[data-start-steep]');

        this.workingModel.set(this.getLineFitValues(start, end, power, startSteep));
    },

    updateLineValues: util.debounce(function (model, value, options) {
        if (options && options.source === 'save') {
            return;
        }

        const end = +this.$('#create-distribution-end').val();
        const start = +this.$('#create-distribution-start-trend-line').val();

        util.each(this.$('.line-shape-buttons > label'), function (el) {
            const $button = this.$(el);

            const power = +$button.data('power');
            const startSteep = $button.is('[data-start-steep]');

            const values = this.getLineFitValues(start, end, power, startSteep);

            if (this.$(el).data('chart')) {
                $button.data('chart').load({
                    columns: [
                        ['values'].concat(util.values(values)),
                    ],
                });
            } else {
                const chart = c3.generate({
                    bindto: $button.find('.graph').get(0),

                    interaction: {
                        enabled: false,
                    },

                    data: {
                        type: 'line',

                        columns: [
                            ['values'].concat(util.values(values)),
                        ],
                    },

                    size: {
                        width: 84,
                        height: 84,
                    },

                    legend: {
                        show: false,
                    },

                    axis: {
                        x: {
                            show: false,
                        },

                        y: {
                            show: false,
                        },
                    },
                });

                $button.data('chart', chart);
            }
        }, this);
    }, 250),

    save() {
        this.workingModel.unset(
            'startValue',
            {
                source: 'save',
            },
        );
        this.model.set(util.clone(this.workingModel.attributes));

        this.close();
    },
});

export default RowDistributionView;
