import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import ListView from 'common/dynamicPages/views/workflow/list';
import alert from '@glu/alerts';
import locale from '@glu/locale';
import ImageSearchDetailView from 'app/reports/views/imageSearch/imageSearchDetail';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import template from './listImageSearchResults.hbs';

const ImageSearchList = ListView.extend({
    template,

    initialize(options) {
        const additionalSearchFields = options && options.additionalSearchFields
            ? options.additionalSearchFields : null;
        let service;

        this.accountFilter = options.accountFilter;
        this.imageType = options.imageType;

        switch (options.imageType) {
        case 'DEPTKT':
            service = 'deposit';
            break;
        case 'RDEPITEM':
            service = 'return';
            break;
        case 'CHECK':
            service = 'check';
            break;
        case 'DEPLIST':
            service = 'depositItem';
            break;
        default:
        }

        const superOptions = {
            serviceName: `/balanceAndTransaction/imageSearch/${service}`,
            serviceFunc: null,
            businessType: null,
            enableSavedViews: false,
            selector: 'none',
            additionalSearchFields,
            lvcAlwaysReset: true,
        };
        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));

        /*
         * Overridding contextDef.serviceName here because Listview
         * doesn't accept serviceName from options
         */
        this.contextDef.serviceName = superOptions.serviceName;
        /**
         * Set suppressSavedViews to true to prevent mobile grid from fetching
         * saved views
         */
        this.suppressSavedViews = true;
    },

    onRender() {
        if (this.gridView) {
            this.listenTo(this.gridView, 'gridapi:loaded', () => {
                this.showAlertView();
                this.clearGridRequestParams();
                this.clearGridAddlSearchFields();
                this.notifyParentView();
            });
            this.listenTo(this.appBus, (`gridapi:showErrorMessages${this.gridView.cid}`), this.showGridErrorMessages);
        }
        ListView.prototype.onRender.call(this);
    },

    showAlertView() {
        const message = this.gridView.wrapper.getRespHeader('message');

        if (message && message.length > 1) {
            /*
             * skip the default "Success" message returned by the service and
             * join all others onto newline
             */
            this.alertView = alert.warning(
                message.splice(1).join('\n'),
                {
                    canDismiss: true,
                },
            );
            this.alertRegion.show(this.alertView);
        } else if (this.alertRegion) {
            this.alertRegion.close();
        }
    },

    notifyParentView() {
        this.trigger('imageSearchList:child:isReady');
    },

    showGridErrorMessages(response) {
        ListView.prototype.showGridErrorMessages.call(this, response);
        this.notifyParentView();
    },

    clearGridRequestParams() {
        ListView.prototype.clearGridRequestParams.call(this);
    },

    back() {
        this.navigateTo(this.options.returnRoute);
    },

    templateHelpers() {
        const obj = ListView.prototype.templateHelpers.call(this, undefined);

        return util.extend(
            obj,
            {
                accountName: () => this.accountTitle,

                accountNumber: () => this.accountFilter,

                type: () => this.imageType,
            },
        );
    },

    disableImageRetrievalButtons(disable, modelButtons) {
        const modelButton = util.findWhere(modelButtons, { action: 'IMAGE' });
        if (!modelButton || !modelButton.value) {
            return;
        }

        const imageButtonsNodeList = this.el.querySelectorAll(`button[data-action="${modelButton.value}"]`);
        const imageButtonsArray = Array.from(imageButtonsNodeList);
        imageButtonsArray.forEach((button) => {
            const btn = button;
            btn.disabled = disable;
        });
    },

    gridRowCustomAction(optionsParam) {
        return new Promise((resolve, reject) => {
            const options = optionsParam;
            const { model } = options;

            this.alertRegion.close();

            options.imageType = this.imageType;
            model.set('ACCOUNT_TITLE', this.accountTitle);
            model.set('ACCOUNTFILTER', this.accountFilter);
            model.set('TYPE', this.imageType);

            this.imageDetail = new ImageSearchDetailView(options);

            this.disableImageRetrievalButtons(true, model.buttons);

            // Send getImage service call
            this.imageDetail.getImage()
                .then(() => {
                    if (this.imageDetail.model.get('errorCode') && this.imageDetail.model.get('errorCode') !== 0) {
                        this.showGridErrorMessages({
                            messages: [this.imageDetail.model.get('errorMessage')],
                        });
                    } else {
                        dialog.open(this.imageDetail);
                    }
                    this.disableImageRetrievalButtons(false, model.buttons);
                    resolve();
                })
                .catch((e) => {
                    this.showGridErrorMessages({
                        messages: [locale.get('cm.image.not.found')],
                    });
                    this.disableImageRetrievalButtons(false, model.buttons);
                    reject(e);
                });
        });
    },

    entitlementPromiseSuccess(result) {
        this.entitlements = result.actions;
        if (!mobileUtil.isMobileGridEnabled()) {
            this.setHasLoadedRequiredData(true);
            this.listenTo(this.gridView.getRows(), 'sync', this.updateRefreshTimestamp);
            this.listenForGridErrors();
            this.render();
        } else {
            /** this notifies the parent after the grid has loaded */
            this.showAlertView();
            this.clearGridRequestParams();
            this.clearGridAddlSearchFields();
            this.notifyParentView();
        }
        return result.actions;
    },
});

let list = ImageSearchList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list);
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
