import rtgsCommon from './rtgsCommon';

export default function (form, initialState) {
    const { formView } = form;
    const { model } = formView;
    const isUrgent = form.field('ISURGENT');
    let isUrgentSelected = false;

    if (initialState) {
        rtgsCommon.getAccountBalances(form.formView, 'DEBIT_ACCOUNT_NUMBER', 'RTGS', 'EURO', 'Driver_Field_EURO_Debit_Bank_Code', 'Driver_Field_EURO_Debit_Currency');
    }

    isUrgentSelected = isUrgent.$el.is(':checked');
    if (isUrgentSelected) {
        model.set('SUBTYPE', 'EURO');
    } else {
        model.set('SUBTYPE', 'SEPA');
    }
}
