import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import Grid from '@glu/grid';
import $ from 'jquery';
import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import constants from 'common/dynamicPages/api/constants';
import Transform from 'common/util/transform';
import template from './duplicateDialog.hbs';

export default Layout.extend({
    template,

    initialize(options) {
        this.methodName = options.methodName;
        this.resp = options.resp;
        this.listView = options.grid;
        this.isApprove = false;
        this.modelsArray = options.modelsArray;
        this.isQE = options.isQE;
        this.isTransfer = options.isTransfer;
        this.quickEntryCollection = options.quickEntryCollection;
        this.modelNeedsValidation = false;
        this.isMultiAdd = options.isMultiAdd;
        this.isSimpleRTP = options.isSimpleRTP;
        this.isFromFooter = options.isFromFooter;
        this.isQuickTransfer = options.isQuickTransfer;
        let duplicatesArray;

        // if this is an approval workflow, disregard validators
        if (this.methodName === 'APPROVE') {
            this.isApprove = true;
            duplicatesArray = this.resp;
        } else if (this.isQE || this.isTransfer || this.isMultiAdd || this.isSimpleRTP
            || this.isFromFooter) {
            duplicatesArray = this.resp;
        } else if (this.model) {
            this.modelNeedsValidation = true;
            duplicatesArray = (this.isQuickTransfer) ? this.resp : this.model.error;
            this.model.validators.DUPLICATEREASON = {
                description: locale.get('sbPayments.duplicateReason'),
                exists: true,
            };
        }

        // populate an array of grids to be displayed in this duplicate dialog
        this.gridsArray = duplicatesArray.confirms.confirmResults.map((duplicate) => {
            /*
             * If there is no additional data then the result passed in does not need to
             * be displayed.
             */
            if (!duplicate.additionalData) {
                return undefined;
            }
            /*
             * using the key/value pairs provided within the first result, we create the
             * columns and their field names
             */
            const columns = duplicate.additionalData[0].item.map(item => ({
                label: item.name,
                field: item.name,
            }));

            /*
             * construct the grid data object using
             * the data provided in the additionalData array
             */
            const duplicatesData = new Collection(duplicate.additionalData
                .map(entry => Transform.pairsToHash(entry.item)));

            // initialize the grid
            return new Grid({
                collection: duplicatesData,
                columns,
            });
        });

        // populate an array of "contexts" for the template helpers
        this.templateHelpersArray = duplicatesArray.confirms.confirmResults.map((duplicate) => {
            // Grab fields needed from the server response
            const currentPaymentData = duplicate.confirmData[0].item;
            const valueDate = currentPaymentData.find(item => item.name === 'DUPLICATEVALUEDATE').value;
            const typeCode = currentPaymentData.find(item => item.name === 'DUPLICATETYPECODE').value;
            const beneficiary = currentPaymentData.find(item => item.name === 'DUPLICATEBENEFICIARY').value;
            const amount = currentPaymentData.find(item => item.name === 'DUPLICATEAMOUNT').value;

            const duplicateSummaryText = beneficiary
                ? locale.get('PAY.duplicateSummary', typeCode, beneficiary, amount, valueDate)
                : locale.get('PAY.duplicateSummaryNoToField', typeCode, amount, valueDate);

            return {
                payment: {
                    duplicateSummaryText,
                    duplicateErrorMessage: duplicate.messages ? duplicate.messages[0] : '',
                },
            };
        });
    },

    onRender() {
        /*
         * get the dynamically created grid containers and populate them with the
         * dynamically created grids
         */
        const gridContainers = this.el.getElementsByClassName('duplicates-grid-instance');
        this.gridsArray.forEach((grid, index) => {
            $(gridContainers[index]).append(grid.render().el);
        });
    },

    attributes: {
        role: 'dialog',
        tabindex: '-1',
        'aria-hidden': 'false',
        class: 'modal',
        'data-backdrop': 'static',
    },

    save() {
        if (this.modelNeedsValidation && !this.model.isValid()) {
            return;
        }

        if (this.isQE || this.isMultiAdd || this.isSimpleRTP
            || (this.isTransfer && !this.isApprove)) {
            if (!this.prepareMultiEntryInsertModelForSave()) {
                this.hasError = false;
                return;
            }
        }

        this.close();
        if (this.methodName === 'SAVE') {
            this.model.trigger(
                'modelAction:saveWithWarning',
                this.model,
                {
                    warningName: constants.DUPLICATE_ACCEPTED_INDICATOR,
                    isFromFooter: this.isFromFooter,
                },
            );
        } else if (this.methodName === 'INSERT') {
            if (this.isQE) {
                this.trigger(
                    'saveQE',
                    this.quickEntryCollection,
                    {
                        warningName: constants.DUPLICATE_ACCEPTED_INDICATOR,
                    },
                );
            } else if (this.isTransfer) {
                this.trigger(
                    'saveTransfers',
                    this.modelsArray,
                    false,
                    {
                        warningName: constants.DUPLICATE_ACCEPTED_INDICATOR,
                    },
                );
            } else if (this.isMultiAdd || this.isSimpleRTP) {
                this.trigger(
                    'saveMultiAddDuplicates',
                    this.modelsArray,
                    false,
                    {
                        warningName: constants.DUPLICATE_ACCEPTED_INDICATOR,
                    },
                );
            } else if (this.isQuickTransfer) {
                this.trigger('saveQuickTransferWithWarning', true);
            } else {
                this.model.trigger(
                    'modelAction:saveWithWarning',
                    this.model,
                    {
                        warningName: constants.DUPLICATE_ACCEPTED_INDICATOR,
                    },
                );
            }
        } else if (this.isApprove) {
            if (this.modelsArray) {
                util.forEach(this.modelsArray, (item) => {
                    const model = this.listView.wrapper.rows.get(item);
                    model.set(constants.DUPLICATE_ACCEPTED_INDICATOR, 'true');
                });
                /**
                 *    For multi-approve we may need to handle other warning conditions
                 *    before running the actual approval, hence the DRYRUNONLY.
                 */
                this.trigger(
                    'list:multi:action:action_approveWithWarning',
                    this.modelsArray,
                    {
                        name: 'DRYRUNONLY',
                        value: 'true',
                    },
                );
            } else if (this.listView) {
                this.model.set({
                    duplicateAccepted: 'true',
                });
                this.listView.trigger(
                    'rowAction',
                    {
                        action: 'approve',
                        model: this.model,
                    },
                );
            } else {
                this.model.trigger(
                    'modelAction:approveWithWarning',
                    {
                        warningName: constants.DUPLICATE_ACCEPTED_INDICATOR,
                    },
                );
            }
        } else if (this.methodName === 'DELETE') {
            if (this.modelsArray) {
                this.trigger(
                    'list:multi:action:action_deleteWithWarning',
                    this.modelsArray,
                    {
                        name: '_saveWithWarning',
                        value: 'true',
                    },
                );
            } else {
                this.model.trigger('modelAction:deleteWithWarning', this.model);
            }
        } else if (this.methodName === 'MODIFY') {
            // call for modify action
            this.model.trigger(
                'modelAction:saveWithWarning',
                this.model,
                {
                    warningName: constants.DUPLICATE_ACCEPTED_INDICATOR,
                },
            );
        } else if (this.methodName === 'REPAIR') {
            // call for repair action
            this.model.trigger(
                'modelAction:repairWithWarning',
                this.model,
                {
                    warningName: constants.DUPLICATE_ACCEPTED_INDICATOR,
                },
            );
        }
    },

    /**
     * Retrieve the duplicate reason(s) from the screen and bind it to the model.
     * If there is no reason, show the user an error.
     * @return {boolean} hasError
     */
    prepareMultiEntryInsertModelForSave() {
        const self = this;
        const duplicateReasonInput = document.getElementsByClassName('duplicateReasonInput');
        const duplicateReasonHelpText = document.getElementsByClassName('duplicateReasonHelpText');
        let arrayOfModels = [];

        if (this.isQE) {
            arrayOfModels = this.quickEntryCollection.models;
        } else if (this.isMultiAdd) {
            arrayOfModels = this.modelsArray.models;
        } else if (this.isSimpleRTP) {
            arrayOfModels = this.modelsArray;
        } else {
            arrayOfModels = this.modelsArray.attributes.items.models;
        }

        arrayOfModels.filter(model => model.get('POSSIBLEDUPLICATE')).forEach((model, index) => {
            if (duplicateReasonInput[index]) {
                if (duplicateReasonInput[index].value) {
                    model.set('DUPLICATEREASON', duplicateReasonInput[index].value);
                } else {
                    // TODO: This is fragile and needs to be reworked
                    duplicateReasonInput[index].parentElement.classList.add('has-error');
                    duplicateReasonHelpText[index].textContent = locale.get('PAY.duplicateReasonMandatory');
                    self.hasError = true;
                }
            }
        });
        return !this.hasError;
    },

    cancel() {
        if (this.model) {
            this.model.trigger('modelAction:errorForDetail', this.model);
            if (this.model.validators) {
                this.model.validators.DUPLICATEREASON = null;
            }
            this.model.set('DUPLICATEREASON', '');
        }

        if (this.isTransfer) {
            this.trigger('cancelWarning');
        }

        if (this.isQuickTransfer) {
            this.model.validators.DUPLICATEREASON = null;
        }

        this.close();
    },

    templateHelpers() {
        return {
            contexts: this.templateHelpersArray,
            grids: this.grids,
            isApprove: this.isApprove,
        };
    },
});
