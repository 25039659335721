import locale from '@glu/locale';
import util from '@glu/core/src/util';
import LimitSetView from '../payments/assignedPermissions/limitSet';
import limitSetTmpl from './limitSet.hbs';

export default LimitSetView.extend({
    template: limitSetTmpl,

    templateHelpers() {
        const self = this;
        const { model } = this;

        return util.extend(
            LimitSetView.prototype.templateHelpers.call(this),
            {
                localeLabel() {
                    return locale.get(`type.${self.model.get('typeCode')}`);
                },

                hasModifyAndManage() {
                    return model.isActionApplicable('modify') && model.isActionApplicable('manage');
                },

                modifyLabel() {
                    return locale.get('uce.entitlement.manage');
                },
            },
        );
    },
});
