var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.wirrpt.wiretransactionreport",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":76}}}))
    + "</h1>\n</div>\n<div class=\"page transactionDetails\">\n        <div class=\"section section-container\">\n                <div class=\"section-header\">\n                        <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.wirrpt.wiretransactionreport",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":28},"end":{"line":7,"column":73}}}))
    + "</h2>\n                </div>\n                <div class=\"section-body\">\n                        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n\n                        <div class=\"btn-wrapper\">\n                        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"gridUtilitySection") || (depth0 != null ? lookupProperty(depth0,"gridUtilitySection") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"gridUtilitySection","hash":{},"data":data,"loc":{"start":{"line":13,"column":24},"end":{"line":13,"column":48}}}) : helper))) != null ? stack1 : "")
    + "\n                        </div>\n                        <div data-region=\"wireReportTransactionsGrid\" aria-live=\"polite\"></div>\n                </div>\n        </div>\n</div>\n";
},"useData":true});