import AddressFieldWidget from 'common/uiWidgets/addressFieldWidget/addressFieldWidget';
import AuditHistoryWidget from 'common/uiWidgets/auditHistoryWidget/auditHistoryWidget';
import AuditSectionWidget from 'common/uiWidgets/auditSectionWidget/auditSectionWidget';
import BaseWidget from 'common/uiWidgets/baseWidget/baseWidget';
import ComboboxWidget from 'common/uiWidgets/comboboxWidget/comboboxWidget';
import ConversationWidget from 'common/uiWidgets/conversationWidget/conversationWidget';
import DocumentWidget from 'common/uiWidgets/documentWidget/documentWidget';
import DoubleComboboxWidget from 'common/uiWidgets/doubleComboboxWidget/doubleComboboxWidget';
import FXPaymentWidget from 'common/uiWidgets/fxPaymentWidget/fxPaymentWidget';
import ListViewWidget from 'common/uiWidgets/listViewWidget/listViewWidget';
import LockToggleWidget from 'common/uiWidgets/lockToggleWidget/lockToggleWidget';
import MaskedInputWidget from 'common/uiWidgets/maskedInputWidget/maskedInputWidget';
import MultiSelectComboboxWidget from 'common/uiWidgets/multiSelectComboboxWidget/multiSelectComboboxWidget';
import PmxCheckPrintDetailWidget from 'common/uiWidgets/pmxCheckPrintDetailWidget/pmxCheckPrintDetailWidget';
import PmxRemittanceWidget from 'common/uiWidgets/pmxRemittanceWidget/pmxRemittanceWidget';
import ProtectAmountWidget from 'common/uiWidgets/protectAmountWidget/protectAmountWidget';
import RangeFieldWidget from 'common/uiWidgets/rangeFieldWidget/rangeFieldWidget';
import AchAuthRulesSchedule from 'common/uiWidgets/achAuthRulesSchedule/achAuthRulesSchedule';
import SchedulePaymentWidget from 'common/uiWidgets/schedulePaymentWidget/schedulePaymentWidget';
import SecondAccountNumWidget from 'common/uiWidgets/secondAccountNumWidget/secondAccountNumWidget';
import SimpleTableWidget from 'common/uiWidgets/simpleTableWidget/simpleTableWidget';
import ToggleWidget from 'common/uiWidgets/toggleWidget/toggleWidget';
import RTPToggleWidget from 'common/uiWidgets/toggleWidget/rtpToggleWidget';
import SummarySectionWidget from 'common/uiWidgets/summarySectionWidget/summarySectionWidget';
import ToggleMaskedWidget from 'common/uiWidgets/toggleMaskedWidget/toggleMaskedWidget';
import TransactionAuditSectionWidget from 'common/uiWidgets/transactionAuditSectionWidget/transactionAuditSectionWidget';
import TransactionSummarySectionWidget from 'common/uiWidgets/transactionSummarySectionWidget/transactionSummarySectionWidget';
import TypeToggleWidget from 'common/uiWidgets/typeToggleWidget/typeToggleWidget';
import ValueDataWidget from 'common/uiWidgets/valueDateWidget/valueDateWidget';

const uiWidgets = {
    ADDRESSFIELDWIDGET: AddressFieldWidget,
    AUDITHISTORYWIDGET: AuditHistoryWidget,
    AUDITSECTIONWIDGET: AuditSectionWidget,
    BASEWIDGET: BaseWidget,
    COMBOBOXWIDGET: ComboboxWidget,
    CONVERSATIONWIDGET: ConversationWidget,
    DOCUMENTWIDGET: DocumentWidget,
    DOUBLECOMBOBOXWIDGET: DoubleComboboxWidget,
    FXPAYMENTWIDGET: FXPaymentWidget,
    LISTVIEWWIDGET: ListViewWidget,
    LOCKTOGGLEWIDGET: LockToggleWidget,
    MASKEDINPUTWIDGET: MaskedInputWidget,
    MULTISELECTCOMBOBOXWIDGET: MultiSelectComboboxWidget,
    PMXCHECKPRINTDETAILWIDGET: PmxCheckPrintDetailWidget,
    PMXREMITTANCEWIDGET: PmxRemittanceWidget,
    PROTECTAMOUNTWIDGET: ProtectAmountWidget,
    RANGEFIELDWIDGET: RangeFieldWidget,
    SCHEDULEPAYMENTWIDGET: SchedulePaymentWidget,
    ACHAUTHRULESSCHEDULE: AchAuthRulesSchedule,
    RTPTOGGLEWIDGET: RTPToggleWidget,
    SECONDACCOUNTNUMWIDGET: SecondAccountNumWidget,
    SIMPLETABLEWIDGET: SimpleTableWidget,
    SUMMARYSECTIONWIDGET: SummarySectionWidget,
    TOGGLEWIDGET: ToggleWidget,
    TOGGLEMASKEDWIDGET: ToggleMaskedWidget,
    TRANSACTIONAUDITWIDGET: TransactionAuditSectionWidget,
    TRANSACTIONSUMMARYWIDGET: TransactionSummarySectionWidget,
    TYPETOGGLEWIDGET: TypeToggleWidget,
    VALUEDATEWIDGET: ValueDataWidget,
};

export default {
    getWidgetView(widgetType) {
        let widgetClass = uiWidgets[widgetType];
        if (!widgetClass) {
            widgetClass = uiWidgets.BASEWIDGET;
        }
        return widgetClass;
    },
};
