import React from 'react';
import PropTypes from 'prop-types';
import ContentLoading from './ContentLoading';

const NoDataOrLoader = ({ isLoading, noDataFoundText }) => (
    isLoading ? (
        <ContentLoading />
    ) : (
        <div className="row">{noDataFoundText}</div>
    )
);

NoDataOrLoader.propTypes = {
    isLoading: PropTypes.bool,
    noDataFoundText: PropTypes.string,
};

NoDataOrLoader.defaultValues = {
    isLoading: false,
    noDataFoundText: '',
};

export default NoDataOrLoader;
