import Controller from '@glu/core/src/controller';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import transform from 'common/util/transform';
import CONSTANT from 'common/dynamicPages/api/constants';
import services from 'services';

const FieldNameLookup = {
    both: 'BENEBOTHABALOOKUP',
    ach: 'BENERTGSABALOOKUP',
    wire: 'BENEACHABALOOKUP',
    interboth: 'INTERBOTHABALOOKUP',
};

const BankCodeLookup = Controller.extend({
    initialize() {
        this.request = this.createDefault();
    },

    setSearch(str) {
        this.request.searchFields[0].fieldValue = [str];
        this.request.searchFields[1].fieldValue = [str];
    },

    setDepends(obj) {
        this.request.depends = util.uniq([...this.request.depends, obj], false, nvp => nvp.name);
    },

    clearDepends() {
        this.request.depends = [];
    },

    createDefault() {
        return {
            rowsPerPage: CONSTANT.COMBO_MIN_SIZE,
            startRow: 1,
            fieldName: 'BENEBOTHABALOOKUP',
            depends: [],
            subType: '*',

            searchFields: [{
                fieldName: 'BANKNAME',
                operator: 'CONTAINS',
                fieldValue: [],
                dataType: 'text',
            }, {
                fieldName: 'BANKSORTCODE',
                operator: 'CONTAINS',
                fieldValue: [],
                dataType: 'text',
            }],

            searchOrOperator: true,
        };
    },

    setStartRow(start) {
        this.request.startRow = start;
    },

    setPageSize(num) {
        this.request.rowsPerPage = num;
    },

    getRequest() {
        return this.request;
    },

    setFieldName(field) {
        let localField = field;
        localField = FieldNameLookup[localField] || localField;

        this.request.fieldName = localField;

        return this;
    },

    getFieldName(field) {
        return FieldNameLookup[field];
    },

    send() {
        return http.post(services.accountLookup, this.getRequest())
            .then(this.parseResponse, this.handleError);
    },

    parseResponse(response) {
        const responseData = util.collect(response.rows, (rowParam) => {
            const row = rowParam;
            row.mapDataList = util.map(row.mapDataList, (dataParam) => {
                const data = dataParam;
                data.value = util.unescape(data.value);
                return data;
            });
            const tmp = transform.pairsToHash(row.mapDataList, 'toField', 'value');
            // adds select2 properties
            tmp.text = (tmp.DUPLICATEBANKCODE && tmp.DUPLICATEBANKCODE === 'Y')
                ? `${tmp.RECEIVRBANKCODE} - ${tmp.RECEIVBANKNAME} - ${tmp.COUNTRYCODE}` : `${tmp.RECEIVRBANKCODE} - ${tmp.RECEIVBANKNAME}`;
            tmp.id = `${tmp.RECEIVRBANKCODE} -  ${tmp.SORTCODETYPE}`;

            return tmp;
        });
        return {
            data: responseData,
            totalRows: response.totalRows,
        };
    },

    handleError() {

    },
});

export default BankCodeLookup;
