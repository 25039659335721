import Glu from '@glu/core/src/glu';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import Collection from '@glu/core/src/collection';
import constants from 'common/dynamicPages/api/constants';
import cmConstants from 'app/checkManagement/constants';
import PrintExportUtil from 'common/util/printExportUtil';
import ListView from 'common/dynamicPages/views/workflow/list';
import gridApi from 'common/dynamicPages/api/grid';
import entitlements from 'common/dynamicPages/api/entitlements';
import errorHandlers from 'system/error/handlers';
import store from 'system/utilities/cache';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import systemConfig from 'system/configuration';
import FilterCellView from 'common/dynamicPages/views/filterCellView';
import services from 'services';
import TotalsModel from 'app/checkManagement/models/issueVoidSummary';
import TotalsView from 'common/dataCard/views/dataCardCollectionView';
import filterUtil from 'common/util/filterUtil';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import template from './listIssues.hbs';

const IssuesVoidsList = ListView.extend({
    className: 'issues-management-list',

    id: 'issuesVoidsList',

    attributes: {
        role: 'tabpanel',
        tabindex: '0',
        'aria-labelledby': 'issuesVoidsListTab',
    },

    regions: {
        totalsSection: '[data-hook="template-totals-region"]',
    },

    template,

    ui: {
        $toggleTotals: '.toggleTotals',
        $totalsRegion: '[data-hook="template-totals-region"]',
    },

    events: util.extend(
        {},
        ListView.prototype.events,
        {
            'click [data-hook="print-button"]': 'showPrintOptionsModal',
            'click [data-hook="insert-button"]': 'insert',
            'click [data-hook="fileimport-button"]': 'fileimport',
            'click [data-hook="toggle-totals-button"]': 'toggleTotals',
        },
    ),

    initialize(options) {
        this.allowStopsInIssueVoidFile = applicationConfigParams.getValue('CMCIM', 'ALLOWSTOPSINISSUEVOIDFILE') === '1';
        const superOptions = {
            menuCategory: 'RISK',
            serviceName: 'issueVoids',
            serviceFunc: null,
            businessType: null,
            enableSavedViews: true,
            context: 'CM_IV_LIST',
            returnRoute: Glu.Backbone.history.getFragment(),
            additonalLVCKey: 'CM_IV_LIST',
            cellViews: {
                JOBID: FilterCellView,
            },
            viewId: options.viewId,
        };
        this.contextKey = 'cm_listView_issueVoids';
        store.set('cm_listView-tab', 'issuesVoidsListTab');
        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
        this.shouldShowTotals = serverConfigParams.get('ShowPaymentListViewTotals') === 'true';
        if (options.filters) {
            this.lvc.set('FILTERFIELDS', filterUtil.formatFilters(options.filters), true);
        }
    },

    updateFromRender() {
        // Do not fetch if ShowPaymentListViewTotals config param is false and totalsModel is empty
        if (this.shouldShowTotals && !systemConfig.isAdmin() && this.totalsModel) {
            this.updateSubTotals();
        }
        // update refresh timestamp
        this.updateRefreshTimestamp();
    },

    updateSubTotals() {
        this.totalsModel.set('filters', this.gridView.wrapper.generateFiltersDataBlock());
        this.totalsModel.set('viewId', this.gridView.wrapper.viewId);
        this.totalsModel.fetch();
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.gridRegion.show(this.gridView);
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
            this.renderMessage(store.get(`${this.contextKey}-alertMessage`), store.get(`${this.contextKey}-confirms`));
            store.set(`${this.contextKey}-alertMessage`, null);
            store.set(`${this.contextKey}-confirms`, null);
            this.listenTo(this.gridView.wrapper.rows, 'sync', this.updateFromRender);
            this.trigger('afterRender');
        } else {
            this.loadViewRequirements();
        }
    },

    initializeTotals() {
        this.totalsModel = new TotalsModel({
            filters: this.gridView.wrapper.generateFiltersDataBlock(),
        }, {
            service: services.generateUrl('cm/getIssuesSummary'),
            order: ['ISSUE', 'VOID'],
        });
        /*
         * TODO: 30315 - Once the API is updated to handle in Admin,
         * the check for systemConfig.isAdmin() can be removed.
         */
        if (this.shouldShowTotals && !systemConfig.isAdmin()) {
            this.totalsCollection = new Collection();
            this.totalsView = new TotalsView({
                collection: this.totalsCollection,
                isLoading: true,
            });
            this.totalsSection.show(this.totalsView);
            this.listenTo(this.totalsModel, 'change', () => {
                const paymentTotalsData = this.totalsModel.get('issuesByGroupSummary');
                this.totalsView.isLoading = true;
                if (paymentTotalsData) {
                    this.totalsCollection.reset(paymentTotalsData);
                }
            });
        }
    },

    toggleTotals() {
        const isVisible = this.ui.$totalsRegion.height() > 0;
        if (isVisible) {
            this.ui.$totalsRegion.css({
                height: '0',
                visibility: 'hidden',
            });
            this.ui.$toggleTotals.text(locale.get('common.show.totals'));
        } else {
            // Show totals data only when user clicks on "show totals" button for the first time
            if (!this.totalsModel) {
                this.initializeTotals();
                this.updateFromRender();
            }
            this.ui.$totalsRegion.css({
                height: 'auto',
                visibility: 'visible',
            });
            this.ui.$toggleTotals.text(locale.get('common.hide.totals'));
        }
    },

    templateHelpers() {
        const self = this;
        const obj = ListView.prototype.templateHelpers.call(this, undefined);
        return util.extend(
            obj,
            {
                hasFileImportEntitlement() {
                    return self.hasEntitlement(constants.ACTION_IMPORT);
                },

                hasRejectEntitlement() {
                    return false;
                },

                showTotals() {
                    const shouldShow = self.shouldShowTotals;
                    return (shouldShow && !systemConfig.isAdmin());
                },

                gridUtilityOptions: {
                    hasRefresh: false,
                },
            },
        );
    },

    loadViewRequirements() {
        const options = this.viewOptionRequirements();
        this.gridView = gridApi.createServiceGridView(options);
        const entitlementPromise = entitlements.getEntitlements(options);

        ListView.prototype.setupGridAvailableListener.call(this);

        // for file import
        const optionsFimport = util.clone(options);
        optionsFimport.entryMethod = '0';
        optionsFimport.typeCode = 'CIMIMPRT';
        const entitlementFimportPromise = entitlements.getEntitlements(optionsFimport);

        // merge both promises (check management and the other one for file import)
        return Promise.all([entitlementPromise, entitlementFimportPromise]).then((results) => {
            const entResActions = results[0].actions;
            if (results[1].actions) {
                if (results[1].actions.IMPORT) {
                    entResActions.IMPORT = results[1].actions.IMPORT;
                }
                if (results[1].actions.SELECT) {
                    entResActions.IMPORTRESULTS = true;
                }
            }
            this.entitlements = entResActions;
            if (!mobileUtil.isMobileGridEnabled()) {
                this.listenTo(this.gridView.getRows(), 'sync', this.updateRefreshTimestamp);
                this.listenForGridErrors('listview.savesystemviewinvalid');
                this.setHasLoadedRequiredData(true);
                this.render();
            }
            return entResActions;
        }, util.bind(errorHandlers.loading, this));
    },

    /**
     * Get print options for this list
     * @returns {Object}
     */
    getPrintOptions() {
        return {
            inquiryId: this.allowStopsInIssueVoidFile
                ? cmConstants.CHKISSUE_STOPCANCEL_INQUIRYID_CLIENT
                : cmConstants.CHKISSUE_INQUIRYID,
            summaryInquiryId: this.allowStopsInIssueVoidFile
                ? cmConstants.CHKISSUE_STOPCANCEL_INQUIRYID_CLIENT
                : cmConstants.CHKISSUE_INQUIRYID,
            hasSummarySelection: false,
        };
    },

    print() {
        this.gridView.context.actionMode = 'SELECT';
        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            inquiryId: this.allowStopsInIssueVoidFile
                ? cmConstants.CHKISSUE_STOPCANCEL_INQUIRYID_CLIENT
                : cmConstants.CHKISSUE_INQUIRYID,
            format: 'PDF',
        };

        PrintExportUtil.print(options);
    },

    export() {
        this.gridView.context.actionMode = 'SELECT';
        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            inquiryId: this.allowStopsInIssueVoidFile
                ? cmConstants.CHKISSUE_STOPCANCEL_INQUIRYID_CLIENT
                : cmConstants.CHKISSUE_INQUIRYID,
            format: 'CSV',
        };

        PrintExportUtil.export(options);
    },

    fileimport() {
        const importContext = {
            serviceName: '/checkfimport/import',
            actionMode: 'IMPORT',
            keyOverride: this.allowStopsInIssueVoidFile ? 'cm_listView_importResultsStopCancel' : 'cm_listView_importResults',
            productCode: 'CM',
            typeCode: '',
        };
        const overrideContext = util.extend(this.contextDef, importContext);
        store.set(`${this.contextKey}-contextOverride`, overrideContext);
        store.set('cm_listView-tab', 'importResultsTab');
        this.navigateTo(this.contextModel.getImportUrl());
    },

    insert() {
        this.navigateTo('/RISK/issueVoids/multipleEntry');
        return Promise.resolve();
    },
});

let list = IssuesVoidsList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        insertActions: [
            {
                label: 'cm.button_insert',
                entitlement: 'INSERT',
                handlerMethodName: 'insert',
            },
        ],
        bulkActions: [
            {
                label: 'button.approve',
                entitlement: constants.ACTION_APPROVE,
            },
            {
                label: 'button.unapprove',
                entitlement: constants.ACTION_UNAPPROVE,
            },
            {
                label: 'button.delete',
                entitlement: constants.ACTION_DELETE,
            },
        ],
    });
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
