import serverConfigParams from 'system/webseries/models/configurationParameters';
import { appendScript } from './util';

export default {
    preAuth() {
        const enabled = serverConfigParams.get('RecordAndReplayEnableFeature') === 'true';
        const config = serverConfigParams.get('RecordAndReplayConfigurationUrl');
        const cfrm = serverConfigParams.get('RecordAndReplayUrl');
        if (!enabled || !config || !cfrm) {
            return;
        }
        try {
            // Add config first as cfrm script relies on config
            appendScript(config);
            appendScript(cfrm);
        } catch (error) {
            window.console.error(error);
        }
    },
};
