import Constants from 'app/administration/constants';
import { createCancelRequestPopupView } from 'components/GridActionPopup/CancelRequestPopup/CancelRequestPopup';
import ExtendStopPopup from 'components/GridActionPopup/ExtendStopPopup/ExtendStopPopup';
import { asView } from 'common/util/reactUtil';
import services from 'services';

import GridActionAuthorizeView from './views/gridActionAuthorizeView';
import GridActionRejectView from './views/gridActionRejectView';

/* IMPORTANT the View property must have a capital V */
const gridActionPopupMap = {
    [Constants.ACTIONS.AUTHORIZE]: {
        View: GridActionAuthorizeView,
        successLabel: 'gridAction.popup.success.authorized',
        title: 'gridAction.popup.title.authorize',
    },
    [Constants.ACTIONS.AUTHRIZE]: {
        View: GridActionAuthorizeView,
        successLabel: 'gridAction.popup.success.authorized',
        title: 'gridAction.popup.title.authorize',
    },
    [Constants.ACTIONS.REJECTFORM]: {
        View: GridActionRejectView,
        successLabel: 'gridAction.popup.success.rejected',
        title: 'gridAction.popup.title.reject',
        options: {
            dataUrl: services.generateUrl('/tableMaintenance/getQueryResults'),
        },
    },
    [Constants.ACTIONS.CANCLREQ]: {
        View: createCancelRequestPopupView({}),
        successLabel: 'gridAction.popup.success.canclreq',
        title: 'gridAction.popup.title.canclreq',
        isReact: true,
    },
    [Constants.ACTIONS.EXTENDREQ]: {
        View: asView(ExtendStopPopup),
        successLabel: 'gridAction.popup.success.extendreq',
        title: 'gridAction.popup.title.extendreq',
        isReact: true,
    },
};

export default gridActionPopupMap;
