import ImageIndicator from 'common/dynamicPages/views/imageIndicatorCellView';
import noteTmpl from './imageIndicatorCell.hbs';
import actionTmpl from './gridActionNoteCell.hbs';

export default ImageIndicator.extend({
    className: 'align-center btn-cell',

    events: {
        'click [data-hook="image-button"]': 'addNote',
    },

    addNote() {
        this.model.typeCode = this.options.column.get('typeCode');
        this.appBus.trigger(`grid:row:action:action_comment_${this.model.parentViewId}`, this.model);
    },

    getTemplate() {
        if (this.options.value !== '') {
            return noteTmpl;
        }
        return actionTmpl;
    },
});
