import locale from '@glu/locale';
import util from '@glu/core/src/util';
import orderingPartyUtil from 'common/util/orderingPartyLookupUtil';
import { appBus } from '@glu/core';
import { createMaskedInputView } from 'components/MaskedInput/MaskedInputWrapper';
import PanelLayout from './validationPanel';
import orderingPartyViewTmpl from './orderingPartyView.hbs';

const OrderingPartyView = PanelLayout.extend({
    template: orderingPartyViewTmpl,

    ui: {
        $orderingPartyForm: '.orderingPartyForm',
        $checkboxOrderingParty: '#checkboxOrderingParty',
        $orderingPartyIdType: 'input[name="ORDERINGPARTY_ID_TYPE"]',
        $orderingPartyId: 'input[name="ORDERINGPARTY_ID"]',
        completeIcon: '.complete-icon',
        form: 'form',
    },

    events: {
        'change @ui.$checkboxOrderingParty': 'toggleOrderingPartyRegion',
    },

    initialize() {
        this.collapsed = false;
        PanelLayout.prototype.initialize.call(this, { collapsed: false });
    },

    /**
     * @name showMaskedAccount
     * @description display the maskedInput component for the account number
     */
    showMaskedAccount() {
        const name = 'ORDERINGPARTY_ID';
        const fieldData = this.fieldTypeData.ORDERINGPARTY_ID;
        const { fieldLabel, maxLen } = fieldData;
        const dontMask = this.model.get('ORDERINGPARTY_ID_TYPE') !== 'ACCT';
        const MaskedInputView = createMaskedInputView({
            initialValue: this.model.get(name),
            name,
            dataBind: true,
            classList: 'col-4',
            inputClassList: 'form-control',
            fieldLabel,
            isRequired: true,
            isProtected: false,
            maxLength: maxLen,
            isReadOnly: false, // since this doesn't seem to get displayed in view mode
            dontMask,
        });
        if (this.maskedInputRegion) {
            this.maskedInputRegion.show(new MaskedInputView());
        }
    },

    onRender() {
        const hasConfig = this.fieldTypeData
            && this.fieldTypeData.ALLOWBYORDEROF !== undefined
            && orderingPartyUtil.isEntitled(this.fieldTypeData.ALLOWBYORDEROF.value);
        const hasData = this.hasOrderingPartyData();

        if (!hasConfig) {
            /*
             * No Feature or Application Config, or Feature or Application Config Deactivated
             * Hide ordering party section. Do not change nor validate data
             */
            this.ui.$checkboxOrderingParty.hide();
            this.ui.$orderingPartyForm.hide();
        } else if (!hasData) {
            // Hide ordering party form region when no ordering party data
            this.ui.$checkboxOrderingParty.prop('checked', false);
            this.ui.$orderingPartyForm.hide();
        } else {
            // Show ordering party form region when ordering party data exists
            this.ui.$checkboxOrderingParty.prop('checked', true);
            this.ui.$orderingPartyForm.show();
            this.showMaskedAccount();
        }
    },

    toggleOrderingPartyRegion(e) {
        if (e.target.checked) {
            this.ui.$orderingPartyForm.show();
            this.showMaskedAccount();
            this.model.set('ALLOWORDERINGPARTYLOOKUP', '1');
        } else {
            this.ui.$orderingPartyForm.hide();
            this.model.set({
                ALLOWORDERINGPARTYLOOKUP: '0',
                ORDERINGPARTY_ID_TYPE: '',
                ORDERINGPARTY_ID: '',
            });
        }

        this.toggleMandatoryFields(e.target.checked);
        this.trigger('specialOrderingPartyChanged');
    },

    toggleMandatoryFields(isRequired) {
        this.toggleMandatoryField('ORDERINGPARTY_ID_TYPE', 'bab.orderingParty.idType', isRequired);
        this.toggleMandatoryField('ORDERINGPARTY_ID', 'bab.orderingParty.id', isRequired);
    },

    toggleMandatoryField(fieldName, localeName, isRequired) {
        if (isRequired) {
            this.model.addValidator(
                fieldName,
                {
                    exists: true,
                    description: locale.get(localeName),
                },
            );
        } else {
            this.model.removeValidator(fieldName);
        }
    },

    hasOrderingPartyData() {
        const allow = this.model.get('ALLOWORDERINGPARTYLOOKUP');
        const opid = this.model.get('ORDERINGPARTY_ID');
        const type = this.model.get('ORDERINGPARTY_ID_TYPE');

        return (allow !== undefined && allow === '1')
            || (opid !== undefined && opid !== '')
            || (type !== undefined && type !== '');
    },

    getWhiteList() {
        return ['ALLOWORDERINGPARTYLOOKUP', 'ORDERINGPARTY_ID_TYPE', 'ORDERINGPARTY_ID'];
    },

    templateHelpers() {
        const helpers = PanelLayout.prototype.templateHelpers.call(this);
        return util.extend(
            helpers,
            {
                orderingPartyIdTypes: this.options.orderingPartyIdTypes.toJSON(),
            },
        );
    },

    delegateEvents() {
        PanelLayout.prototype.delegateEvents.call(this);
        this.listenTo(this.options.orderingPartyIdTypes, 'change', this.render);
        this.listenTo(this.model, 'change:ORDERINGPARTY_ID_TYPE', this.orderingPartyIdTypeChange);
    },

    orderingPartyIdTypeChange() {
        appBus.trigger('disableMasking:ORDERINGPARTY_ID', this.model.get('ORDERINGPARTY_ID_TYPE') !== 'ACCT');
    },
});

export default OrderingPartyView;
