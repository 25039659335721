/**
 *
 * MENU ITEM VIEW OVERRIDE
 * =======================
 *
 * All overrides for the Glu menu view
 *
 * Current overrides:
 *       - handleClick method
 *       - handleKeydown method (as of 8.3.8.8)
 *
 */
import MenuView from 'no-override!@glu/core/src/menuView';
import $ from 'jquery';
import mobileUtil from '../../../mobile/util/mobileUtil';

export default MenuView.extend({
    /**
     * Handles the opening and closing of the mobile dropdown menus from the
     * click handler
     * @param {Event} e Native event object
     */
    handleClick(e) {
        if (!mobileUtil.isMobileLarge()) {
            if (!(this.access.isTouch() || this.ui.$navbar.hasClass('locked'))) {
                return;
            }
        }
        const $target = $(e.target);

        //make sure clicks from icons are handled properly
        const $linkTarget = $target.is('[class^="icon"]') ? $target.parent() : $target;

        // if the click target has a dropdown
        if ($linkTarget.hasClass('has-dd')) {
            const $dropdown = $linkTarget.next();

            if ($dropdown.attr('aria-hidden') === 'false') {
                // close the menu
                $linkTarget.removeClass('dd-open').blur();
                $dropdown.attr('aria-hidden', 'true');
            } else {
                // open the menu, and ensure all other dropdowns are closed
                this.ui.$dropdowns.attr('aria-hidden', 'true').prev().removeClass('dd-open');
                $dropdown.attr('aria-hidden', 'false').prev().addClass('dd-open');
            }
        }
    },

    /**
     * Handles the mouseenter event
     * click handler
     * @param {Event} e Native event object
     */
    handleMouseenter(e) {
        if (mobileUtil.isMobileLarge()) {
            return;
        }
        // BEGIN copy and paste from GLU
        if (!this.access.isTouch() && !this.ui.$navbar.hasClass('locked')) {
            /*
             * NH-149330 This line is the override, changing from e.target to
             * e.currentTarget. When the user has clicked an icon, down in the
             * condition where we are checking to see if $this.hadClass('has-dd'),
             * target is always the icon and not the parent 'a' element. Switching
             * over to e.currentTarget allows us to select the 'a' element which
             * is the element that is listening for this event and actually
             * has the 'has-dd' class.
             */
            const $this = this.ui.$all.filter(e.currentTarget);
        
            $this.addClass('menu-hover').focus();
        
            // If there is a dropdown
            if ($this.hasClass('has-dd')) {
                // If we are on a new top level menu item close the previous one
                if (this.ui.$dropdowns.not($this.siblings()).is('[aria-hidden=false]')) {
                this.ui.$dropdowns.attr('aria-hidden', 'true');
                this.ui.$hasDD.removeClass('dd-open');
                }
                // Set the aria-hidden flag to false
                $this.addClass('dd-open');
                $this.next().attr('aria-hidden', 'false');
            }
        } else {
            // if we are a touch device consider this a click since the first tap triggers mouseEnter event
            this.handleClick(e);
        }
      
        return true;
        // END copy and paste from GLU
    },

    /**
     * Gets the link (or button) inside a menu item
     * This allows navigation via keyboard to be consistent
     * @param {jQuery} $item
     * @return {jQuery}
     */
    getActionElement($item) {
        if (!$item || $item.length === 0) {
            return $item;
        }

        if ($item.is(this.ui.$top)) {
            return $item;
        }

        var $action = $item.find('a, button').first();

        return $action.length !== 0 ? $action : $item;
    },

    handleKeydown(e) {
        if (e.altKey || e.ctrlKey) {
            // Modifier key pressed: Do not process
            return true;
        }

        const $item = this.ui.$all.filter(e.target);
        const $itemUL = $item.closest('ul');

        switch (e.keyCode) {
            case this.access.keys.tab: {
                // hide all menu items and update their aria attributes
                this.ui.$hasDD.removeClass('dd-open');
                this.ui.$dropdowns.attr('aria-hidden', 'true');

                // remove focus styling from all menu items
                this.ui.$all.removeClass('menu-focus').removeClass('menu-hover');
                // Set active item to null
                this.access.active = null;
                break;
            }
            case this.access.keys.esc: {
                if (!$itemUL.is(this.ui.$top)) {
                    // move up one level
                    this.access.active =  this.getActionElement($itemUL.prev());
                    this.access.active.removeClass('dd-open');
                    // hide the active menu and update the aria attributes
                    $itemUL.attr('aria-hidden', 'true');
                } else {
                    this.access.active = this.getActionElement($item);
                }

                // Assign active state
                this.access.active.focus();
                e.stopPropagation();
                return false;
            }
            case this.access.keys.enter:
            case this.access.keys.space: {
                if ($itemUL.is(this.ui.$top) && $item.is('.has-dd')) {
                    // If we are on the vertical menu top-level, move down to the next level
                    this.access.active =  this.getActionElement(this.moveDown($item));
                } else {
                    // process the menu choice
                    $(e.target).not('.disabled').click();

                    // remove accessibility hover/focus styling
                    this.ui.$all.removeClass('menu-hover').removeClass('menu-focus');
                    this.ui.$hasDD.removeClass('dd-open');

                    // close the menu
                    this.ui.$dropdowns.attr('aria-hidden', 'true');

                    // Setting focus before navigation completes breaks accessibility.
                    // $document.find('div[role=main]').focus();
                    return true;
                }

                // Assign active state
                this.access.active.focus();
                e.stopPropagation();
                return false;
            }
            case this.access.keys.left: {
                if ($itemUL.is(this.ui.$top) ||
                    (this.verticalMenu() === true && $itemUL.is(this.ui.$dropdowns) && !this.ui.$navbar.hasClass('open'))) {
                    this.access.active = this.getActionElement(this.moveToPrevious($item));
                } else if (!this.verticalMenu() && !$itemUL.is(this.ui.$top)) {
                    /*
                     * Left and right are used to navigate the menuitems with VoiceOver
                     * even when the list of menuitems is vertical
                     */
                    this.access.active = this.getActionElement(this.moveUp($item));
                }

                // Assign active state
                this.access.active.focus();
                e.stopPropagation();
                return false;
            }
            case this.access.keys.right: {
                if ($itemUL.is(this.ui.$top) && (this.verticalMenu() === true && this.ui.$navbar.not('.open'))) {
                    // If we are on the vertical menu top-level, move down to the next level
                    this.access.active =  this.getActionElement(this.moveDown($item));
                } else if (!this.verticalMenu() && !$itemUL.is(this.ui.$top)) {
                    /*
                     * Left and right are used to navigate the menuitems with VoiceOver
                     * even when the list of menuitems is vertical
                     */
                    this.access.active = this.getActionElement(this.moveDown($item));
                } else if ($itemUL.is(this.ui.$top)) {
                    // If we are in the top-level menu, move to the next menu item
                    this.access.active = this.getActionElement(this.moveToNext($item));
                }

                this.access.active.focus();

                e.stopPropagation();
                return false;
            }
            case this.access.keys.up: {
                if (this.verticalMenu() === true && $itemUL.is(this.ui.$top)) {
                    // If this is a vertical menu and the root-level is active, move
                    // to the previous root-level menu
                    this.access.active =  this.getActionElement(this.moveToPrevious($item));
                } else {
                    this.access.active = this.getActionElement(this.moveUp($item));
                }

                // Assign active state
                this.access.active.focus();
                e.stopPropagation();
                return false;
            }
            case this.access.keys.down: {
                if (this.verticalMenu() === true && $itemUL.is(this.ui.$top)) {
                    // If this is a vertical menu and the root-level is active, move
                    // to the next root-level menu
                    this.access.active =  this.getActionElement(this.moveToNext($item));
                } else {
                    this.access.active = this.getActionElement(this.moveDown($item));
                }

                // Assign active state
                this.access.active.focus();
                e.stopPropagation();
                return false;
            }
            default: {
                const chr = String.fromCharCode(e.which);

                this.access.active = this.getActionElement(this.goToChar($item, chr));
                if (!this.access.active !== 'null') {
                    // Assign active state
                    this.access.active.focus();
                }
                e.stopPropagation();
                return false;
            }
        }

        return true;
    },
});
