import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import services from 'services';
import http from '@glu/core/src/http';
import NestedModel from '@glu/core/src/nestedModel';
import transform from 'common/util/transform';

const days = [
    'WEEKLYDAYMON',
    'WEEKLYDAYTUE',
    'WEEKLYDAYWED',
    'WEEKLYDAYTHURS',
    'WEEKLYDAYFRI',
    'WEEKLYDAYSAT',
    'WEEKLYDAYSUN',
];

export default Model.extend({
    initialize() {},

    read(tnum) {
        return http.post(
            services.generateUrl('tableMaintenance/scheduledPayments/read'),
            {
                item: [{
                    name: 'TNUM',
                    value: tnum,
                }],
            },
        );
    },

    parse(response) {
        const obj = transform.pairsToHash(response.item);

        // Recurring template, must populate a schedule model
        if (obj.PATTERNMODE && obj.PATTERNMODE !== 'O') {
            const schedModel = new NestedModel();
            const recurrence = {};

            // This is needed so scheduler doesn't think it is a new record
            schedModel.idAttribute = 'starts';

            schedModel.set('starts', obj.STARTINGEFFECTIVEDATE.indexOf(' ') > -1 ? obj.STARTINGEFFECTIVEDATE.substring(0, obj.STARTINGEFFECTIVEDATE.indexOf(' ')) : obj.STARTINGEFFECTIVEDATE);

            if (obj.ENDMODE === 'noend') {
                schedModel.set('ends', false);
            } else if (obj.ENDMODE === 'numoccur') {
                schedModel.set('ends', parseInt(obj.ENDCYCLES, 10));
            } else {
                schedModel.set('ends', obj.ENDINGEFFECTIVEDATE.indexOf(' ') > -1 ? obj.ENDINGEFFECTIVEDATE.substring(0, obj.ENDINGEFFECTIVEDATE.indexOf(' ')) : obj.ENDINGEFFECTIVEDATE);
            }

            // Monthly
            if (obj.PATTERNMODE === 'M') {
                schedModel.set('type', 'MONTHLY');

                if (obj.MONTHLYMODE === 'specific') {
                    recurrence.intervalType = 'DAY';
                    recurrence.nMonths = obj.MONTHLYMONTH1;
                    recurrence.onN = [Number(obj.MONTHLYDAY1)];
                } else {
                    recurrence.intervalType = obj.MONTHLYDAYTYPE.toUpperCase();
                    recurrence.nMonths = obj.MONTHLYMONTHNUMBER;
                    recurrence.onN = [obj.MONTHLYDAYNUMBER.toUpperCase()];
                }
            } else {
                // Weekly
                schedModel.set('type', 'WEEKLY');

                recurrence.nWeeks = obj.WEEKLYWEEKS;
                recurrence.onN = [];

                util.each(days, (day, idx) => {
                    if (obj[day] === '1') {
                        recurrence.onN.push(idx + 1);
                    }
                });
            }
            schedModel.set('recurrence', recurrence);

            obj.schedModel = schedModel;
        }

        return obj;
    },

    convertModelAttributesToServerJSON(scheduleModel) {
        let pairs = [];

        if (scheduleModel) {
            const schedModel = scheduleModel;

            let schedAttributes = {
                STARTINGEFFECTIVEDATE: schedModel.get('starts'),
            };

            if (schedModel.get('ends') === false) {
                schedAttributes.ENDMODE = 'noend';
                schedAttributes.ENDCYCLES = '';
                schedAttributes.ENDINGEFFECTIVEDATE = '';
            } else if (!Number.isNaN(Number(schedModel.get('ends')))) {
                schedAttributes.ENDMODE = 'numoccur';
                schedAttributes.ENDCYCLES = schedModel.get('ends');
                schedAttributes.ENDINGEFFECTIVEDATE = '';
            } else {
                schedAttributes.ENDMODE = 'effdate';
                schedAttributes.ENDCYCLES = '';
                schedAttributes.ENDINGEFFECTIVEDATE = schedModel.get('ends');
            }

            if (schedModel.get('type') === 'MONTHLY') {
                schedAttributes.PATTERNMODE = 'M';

                if (schedModel.get('recurrence').intervalType === 'DAY') {
                    schedAttributes.MONTHLYMODE = 'specific';
                    schedAttributes.MONTHLYMONTH1 = schedModel.get('recurrence').nMonths;
                    [schedAttributes.MONTHLYDAY1] = schedModel.get('recurrence').onN;
                } else {
                    schedAttributes.MONTHLYMONTHNUMBER = schedModel.get('recurrence').nMonths;
                    schedAttributes.MONTHLYMODE = 'interval';
                    schedAttributes.MONTHLYDAYTYPE = schedModel.get('recurrence').intervalType.toLowerCase();
                    schedAttributes.MONTHLYDAYNUMBER = schedModel.get('recurrence').onN[0].toLowerCase();
                }
            } else {
                schedAttributes.PATTERNMODE = 'W';
                schedAttributes.WEEKLYWEEKS = schedModel.get('recurrence').nWeeks;

                schedAttributes = util.extend(
                    schedAttributes,
                    {
                        WEEKLYDAYMON: '0',
                        WEEKLYDAYTUE: '0',
                        WEEKLYDAYWED: '0',
                        WEEKLYDAYTHURS: '0',
                        WEEKLYDAYFRI: '0',
                        WEEKLYDAYSAT: '0',
                        WEEKLYDAYSUN: '0',
                    },
                );

                util.each(schedModel.get('recurrence').onN, (dayIndex) => {
                    schedAttributes[days[dayIndex - 1]] = '1';
                });
            }

            pairs = pairs.concat(transform.hashToPairs(schedAttributes));
            pairs = pairs.concat(transform.hashToPairs(this.attributes));
        }

        return {
            item: pairs,
        };
    },
});
