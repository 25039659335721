var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"back\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"balanceTrans.back.to.previous",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":142},"end":{"line":4,"column":184}}}))
    + "</span></a>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <div class=\"btn-wrapper\">\n                                                <div class=\"pull-right\">\n                                                        <button type=\"button\" class=\"btn btn-tertiary export\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.export",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":117},"end":{"line":20,"column":143}}}))
    + "\" data-hook=\"export-button\"><span class=\"icon-export\"></span> "
    + alias2((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias3).call(alias1,"button.export",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":20,"column":205},"end":{"line":20,"column":234}}}))
    + "</button>\n                                                        <button type=\"button\" class=\"btn btn-tertiary print\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":116},"end":{"line":21,"column":141}}}))
    + "\" data-hook=\"print-button\"><span class=\"icon-print\"></span> "
    + alias2((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias3).call(alias1,"button.print",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":21,"column":201},"end":{"line":21,"column":229}}}))
    + "</button>\n                                                </div>\n                                        </div>\n\n                                        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n                                        <div class=\"grid-region account-summary-button-grid\" data-region=\"gridRegion\"></div>\n\n\n\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideBackHeader") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":6,"column":15}}})) != null ? stack1 : "")
    + "    <h1 class=\"landing-header\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"getTitle") || (depth0 != null ? lookupProperty(depth0,"getTitle") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"getTitle","hash":{},"data":data,"loc":{"start":{"line":7,"column":31},"end":{"line":7,"column":43}}}) : helper)))
    + "</h1>\n</div>\n\n<div class=\"section section-container\">\n    <div class=\"section-body\">\n                <div class=\"account-summary-button-row\">\n                        <div class=\"BalanceTransactionControls col-md-6\" data-region=\"viewAccountsDropdownRegion\"></div>\n                        "
    + alias3(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"action":"refresh-balances","icon":(depth0 != null ? lookupProperty(depth0,"refresh") : depth0),"textVisible":true,"className":"btn btn-tertiary refresh-balances-button","locale":"gir.loan.account.refreshBalances","name":"refresh"},"data":data,"loc":{"start":{"line":14,"column":24},"end":{"line":14,"column":198}}}))
    + "\n                </div>\n\n"
    + ((stack1 = (lookupProperty(helpers,"panelWithTotals")||(depth0 && lookupProperty(depth0,"panelWithTotals"))||alias2).call(alias1,{"name":"panelWithTotals","hash":{"totalsId":(depth0 != null ? lookupProperty(depth0,"totalsSection") : depth0),"hasTotals":true,"theme":"panel-primary","collapsed":false,"label":(lookupProperty(helpers,"getTitle")||(depth0 && lookupProperty(depth0,"getTitle"))||alias2).call(alias1,{"name":"getTitle","hash":{},"data":data,"loc":{"start":{"line":17,"column":33},"end":{"line":17,"column":43}}})},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":30,"column":28}}})) != null ? stack1 : "")
    + "    </div>\n</div>";
},"useData":true});