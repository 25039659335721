import ItemView from '@glu/core/src/itemView';
import liveMaintenanceUserMessageViewTmpl from './liveMaintenanceUserMessageView.hbs';

export default ItemView.extend({
    template: liveMaintenanceUserMessageViewTmpl,
    tagName: 'section',
    className: 'message-section',

    initialize() {
        this.listenTo(this.model, 'change', this.render);
    },
});
