import ItemView from '@glu/core/src/itemView';
import $ from 'jquery';
import constants from 'app/administration/constants';
import util from '@glu/core/src/util';
import locale from 'system/webseries/api/locale';
import limitSetTmpl from './limitSet.hbs';

export default ItemView.extend({
    template: limitSetTmpl,
    tagName: 'fieldset',

    ui: {
        $limitTypeCheckboxes: '.limit-type-entitlements .checkbox-inline input[type=checkbox]',
        $approvalSignatureSelect: '.approval-signatures',
        $approveOwnCheckbox: '.approve-own-checkbox',
        $autoApproveCheckbox: '.auto-approve-checkbox',
    },

    events: {
        'change @ui.$approvalSignatureSelect': 'changeSignatureHandler',
        'change @ui.$approveOwnCheckbox': 'changeApproveOwnHandler',
        'change @ui.$autoApproveCheckbox': 'changeAutoApproveHandler',
    },

    initialize(options) {
        this.mode = options.mode;
        this.listenTo(this.model, 'change', this.handleActionChanges);
    },

    handleActionChanges(model, o) {
        // not changed by view, ignore
        if (!o.source) {
            return;
        }

        const { name } = o.source[0];
        const action = name.substring(0, name.indexOf('.'));
        const isEntitled = o.source[0].checked;
        const entryMethod = this.model.get('entryMethod');

        // check if any other checkboxes are selected. If so, view cannot be unchecked
        if (action === constants.MODES.VIEW && !isEntitled && this.model.isEntitled()) {
            this.model.set('view.entitled', true);
            return;
        }

        // undo selection of autoapprove/approveown if approve isn't entitled
        if (util.contains(['approveOwn', 'autoApprove'], action) && isEntitled && !this.model.get('approve.entitled')) {
            this.model.set(name, false);
            return;
        }

        // undo selection of debit/credit if manage, modify or repair isn't entitled
        if (entryMethod === 'imported'
            && util.contains(['debit', 'credit', 'manage', 'modify', 'repair'], action)
            && !this.model.get('manage.entitled')
            && !this.model.get('modify.entitled')
            && !this.model.get('repair.entitled')) {
            this.model.set({
                'debit.entitled': false,
                'credit.entitled': false,
            });
            return;
        }

        // undo auto approve if approve own isnt' entitled
        if (action === 'autoApprove' && isEntitled && !this.model.get('approveOwn.entitled')) {
            this.model.set(name, false);
            return;
        }

        /*
         * remove approveown/auto if manage/modify/repair and
         * entryMethods don't match requirements
         */
        if ((this.model.get('approve.entitled')
            && (entryMethod === 'imported'
                && !this.model.get('manage.entitled')
                && !this.model.get('modify.entitled')
                && !this.model.get('repair.entitled')
            ))
            || (entryMethod !== 'imported' && !this.model.get('manage.entitled'))) {
            this.model.set({
                'approveOwn.entitled': false,
                'autoApprove.entitled': false,
            });
        }

        // remove approve own if approve is deselected
        if (action === 'approve' && !isEntitled) {
            this.model.set({
                'approveOwn.entitled': false,
                'autoApprove.entitled': false,
            });
        }

        // remove auto approve if approve own is deselected
        if (action === 'approveOwn' && !isEntitled) {
            this.model.set('autoApprove.entitled', false);
        }

        // auto select view if other checkboxes are selected
        if (this.model.isActionApplicable(constants.MODES.VIEW)
            && action !== constants.MODES.VIEW && isEntitled) {
            this.model.set('view.entitled', true);
        }

        // Update the signature value if approve is changed
        if (this.model.get('signatures') && action === 'approve') {
            this.updateSignatureAssignedValue();
        }
    },

    changeSignatureHandler(e) {
        this.model.setNumberOfRequiredSignatures(parseInt(e.target.value, 10));
    },

    updateSignatureAssignedValue() {
        // set signature value if approve is active and signatures exists
        if (this.model.isActionEntitled('approve')) {
            const numSigSelectedVal = this.model.getNumberOfRequiredSignatures();

            /*
             * Default the number of signatures to 1 if the value is not set yet and
             * signatures is not null
             */
            if (numSigSelectedVal === 0) {
                this.model.setNumberOfRequiredSignatures(1);
            }
        } else {
            // set value back to default
            $(this.ui.$approvalSignatureSelect).find('option:selected').removeAttr('selected');
            this.model.setNumberOfRequiredSignatures(0);
        }
    },

    templateHelpers() {
        const self = this;
        const { model } = this;
        const readOnly = this.mode === constants.MODES.VIEW;

        return {
            readOnly,
            viewApplicable: model.isActionApplicable('view'),
            manageApplicable: model.isActionApplicable('manage'),
            getrateApplicable: model.isActionApplicable('getrate'),
            modifyApplicable: model.isActionApplicable('modify'),
            repairApplicable: model.isActionApplicable('repair'),
            approveApplicable: model.isActionApplicable('approve'),
            reverseApplicable: model.isActionApplicable('reverse'),
            printApplicable: model.isActionApplicable('print'), // PCM
            reprintApplicable: model.isActionApplicable('reprint'), // PCM
            stopCheckApplicable: model.isActionApplicable('stopCheck'), // PCM
            voidCheckApplicable: model.isActionApplicable('voidCheck'), // PCM
            updateCheckApplicable: model.isActionApplicable('updateCheck'), // PCM
            confidentialApplicable: model.isActionApplicable('confidential'),
            approveOwnApplicable: model.isActionApplicable('approveOwn'),
            autoApproveApplicable: model.isActionApplicable('autoApprove'),
            creditApplicable: model.isActionApplicable('credit'),
            debitApplicable: model.isActionApplicable('debit'),
            hasTransactionType: model.isActionApplicable('credit') || model.isActionApplicable('debit'),
            authorizeApplicable: model.isActionApplicable('authorize'),

            hasSignatures() {
                const functionCode = model.get('functionCode');
                const isTemplate = util.contains(['template', 'batchTemplate', 'reqtmpl'], functionCode);
                return !self.options.hasPanelApproval && !isTemplate && model.hasSignatures();
            },

            selectedSig() {
                const val = model.get('signatures.assignedValue');
                // if no sig, show nothing, otherwise properly pluralize the localized string
                return val > 0 ? (`${val} ${locale.get(`administration.approval.signature${val > 1 ? '.plural' : ''}`)}:`) : '';
            },

            localeLabel() {
                return locale.get(`entryMethod.${self.model.get('entryMethod')}`);
            },

            signatures() {
                const signatures = self.model.get('signatures');
                const signaturesArr = [];
                let singPluralSignatures;

                if (signatures) {
                    for (let i = 1; i <= model.maximumNumberOfRequiredSignatures(); i += 1) {
                        if (i === 1) {
                            singPluralSignatures = locale.get('administration.approval.signature');
                        } else {
                            singPluralSignatures = locale.get('administration.approval.signature.plural');
                        }

                        const signatureObj = {
                            label: `${i} ${singPluralSignatures}`,
                            value: i,
                            selected: i === model.getNumberOfRequiredSignatures(),
                        };

                        signaturesArr.push(signatureObj);
                    }

                    return signaturesArr;
                }
                return undefined;
            },
        };
    },
});
