import Collection from '@glu/core/src/collection';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import services from 'services';

export default Collection.extend({
    initialize(models, options) {
        this.data = options.requestData;
        this.combo = options.combo;
        this.index = options.index;
    },

    sync(method, model, options) {
        if (method === 'read') {
            http.post(
                services.currencyBalances,
                {
                    requests: this.data,
                },
                (result) => {
                    options.success(result);
                },
                (result) => {
                    options.error(result);
                },
            );
        }
    },

    parse(response) {
        const { responses } = response;
        let j;
        let availableBalance;

        if (this.index) {
            j = this.index;
            if (responses.length === 1) {
                this.combo[j].accountBalance = responses[0].balance;

                const { label } = this.combo[j];
                this.combo[j].label = label.replace(locale.get('payment.update.pending'), '');

                if (this.combo[j].accountBalance) {
                    availableBalance = (`${this.combo[j].accountBalance} ${this.combo[j].currency} ${locale.get('common.available')}`);

                    if (label.indexOf(availableBalance) === -1) {
                        this.combo[j].label += ` ${availableBalance}`;
                    }
                    this.combo[j].isLoaded = true;
                } else {
                    availableBalance = locale.get('payment.balance.none');
                    if (label.indexOf(availableBalance) === -1) {
                        this.combo[j].label += ` ${availableBalance}`;
                    }
                    this.combo[j].isLoaded = true;
                }
                this.combo[j].isPending = false;
            }
        } else {
            for (j = 0; j < this.combo.length; j += 1) {
                const { label } = this.combo[j];
                if (responses[j]) {
                    this.combo[j].accountBalance = responses[j].balance;
                    if (this.combo[j].accountBalance) {
                        availableBalance = (`${this.combo[j].accountBalance} ${this.combo[j].currency} ${locale.get('common.available')}`);

                        if (label.indexOf(locale.get('payment.update.pending')) > -1) {
                            this.combo[j].label = label.replace(locale.get('payment.update.pending'), '');
                        }

                        // temp patch
                        if (label.indexOf(availableBalance) === -1) {
                            this.combo[j].label += `: ${availableBalance}`;
                        }
                        this.combo[j].isLoaded = true;
                    } else {
                        availableBalance = locale.get('payment.balance.none');
                        if (label.indexOf(availableBalance) === -1) {
                            this.combo[j].label += ` ${availableBalance}`;
                        }
                        this.combo[j].isLoaded = true;
                    }
                } else if (!this.combo[j].isMissingOffsetAcct) {
                    if (label.indexOf(locale.get('common.available')) === -1) {
                        this.combo[j].label += (`: ${locale.get('payment.update.pending')}`);
                        this.combo[j].isPending = true;
                    } else {
                        this.combo[j].isLoaded = true;
                    }
                }
            }
        }
        return this.combo;
    },
});
