var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"PanelWorkflowAssignment\">\n    <div class=\"field-container\">\n        <label>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"PS.panelApproval.Approval.Workflow",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":15},"end":{"line":3,"column":62}}}))
    + "</label>\n        <div data-region=\"getPanelProfileCode\"></div>\n    </div>\n</section>\n";
},"useData":true});