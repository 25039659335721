import Layout from '@glu/core/src/layout';
import alert from '@glu/alerts';
import dialog from '@glu/dialog';
import services from 'services';
import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import GridApi from 'common/dynamicPages/api/grid';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import httpError from 'common/util/httpErrorResult';
import template from './exportData.hbs';

const FileLoadModel = Model.extend({
    sync(method, model, options) {
        if (method === 'update') {
            const payload = {
                actionData: {
                    productCode: 'SMAINT',
                    functionCode: 'EXPORT',
                    typeCode: 'FLOAD_TM',
                    actionMode: 'CREATE',
                },

                exportFileId: model.get('ID'),
            };
            http.post(services.generateUrl('/fileLoadExport/createExport'), payload, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }
    },
});

export default Layout.extend({
    template,

    initialize() {
        this.model = new FileLoadModel();
    },

    onRender() {
        this.renderGridView();
    },

    renderGridView() {
        const options = {
            context: {
                serviceName: '/fileLoadExport',
                productCode: 'SMAINT',
                functionCode: 'EXPORT',
                typeCode: 'FLOAD_TM',
            },

            enabledSavedViews: true,
            selector: 'none',
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,
        };
        this.gridView = GridApi.createServiceGridView(options);
        this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
        this.gridRegion.show(this.gridView);
    },

    gridRowAction() {
        // Do stuff
        return Promise.resolve();
    },

    renderMessage() {
        const confirmObj = {
            confirmResults: [{
                confirmData: [{
                    item: [{
                        name: locale.get('export.eventname'),
                        value: this.model.get('EVENTNAME'),
                    }, {
                        name: locale.get('ACH.FileName'),
                        value: this.model.get('FILENAME'),
                    }],
                }],
            }],
        };
        const confirms = new Confirms({
            confirms: confirmObj,
        });

        this.alertView = alert.success(
            'File Import Successful',
            {
                details: confirms,
                canDismiss: true,
            },
        );

        this.trigger('doExport', this.alertView);
        dialog.close();
    },

    export() {
        const cid = this.$el.find('input[type="radio"]:checked', '.table').attr('data-model-cid');
        const self = this;
        if (cid) {
            const gridModel = this.gridView.grid.collection.get(cid).toJSON();
            this.model.save(
                gridModel,
                {
                    success(data) {
                        self.renderMessage(data);
                    },

                    error() {
                        self.alertRegion.show(alert.negative('An error has occcured'));
                    },
                },
            );
        } else {
            const msg = alert.negative('You Must Select One Item');
            this.alertRegion.show(msg);
        }
    },

    cancel() {
        dialog.close();
    },
});
