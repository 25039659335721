import Marionette from 'backbone.marionette';
import CompositeView from 'no-override!@glu/core/src/compositeView';
import ItemView from '@glu/core/src/itemView';
import Handlebars from 'handlebars';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import filterItemClass from 'system/gridOverride/views/dynamicTypeSearch2';
import Collection from '@glu/core/src/collection';
import tpl from './advancedSearchBase2.hbs';

export default CompositeView.extend({
    template: tpl,

    itemView: ItemView.extend({
        template: Handlebars.compile(''),
    }),

    className: 'advanced-search',
    modalClass: 'modal-lg advanced-search-modal',
    itemViewContainer: '.search-control-list',
    totalFilters: 0,
    dialogTitle: '',
    dialogButtons: [],

    ui: {
        addFilterContainer: '.add-filter-container',
        applyAdvanced: 'input[data-hook="apply-advanced"]',
        addFilter: 'button[data-control="addFilter"]',
        $selectedLine: '[data-hook="selected-line"]',
        $maxCounter: '[data-hook="total"]',
    },

    events: {
        submit: 'handleSubmit',
        'click @ui.applyAdvanced': 'handleSubmit',
        'click @ui.addFilter': 'handleAddFilter',
    },

    constructor(opts) {
        CompositeView.prototype.constructor.call(this, opts);
        this.SearchViewClass = filterItemClass;
    },

    initialize() {
        this.dialogTitle = locale.get('manageFilters');
        this.dialogButtons = [{
            text: locale.get('apply'),
            className: 'btn btn-primary',
            callback: 'handleSubmit',
        }, {
            text: locale.get('advancedSearch.cancel'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }, {
            text: locale.get('advancedSearch.clearall'),
            className: 'btn btn-tertiary',
            callback: 'handleClearAllFilters',
        }];
        // get the total number of filter items by removing the un-'filterable' ones
        const options = this.options.columns || this.columns;
        const filterOptions = util.filter(options.models, model => model.get('notSearchable') !== true);
        this.filtersList = new Collection(filterOptions);
        this.totalFilters = filterOptions.length;
    },

    cancel() {
        dialog.close();
    },

    onRender() {
        this.delegateEvents();
        this.addFilters();
        this.addFirstFilter();
        this.showHideAddFilterButton();
        this.setFilterCounter();
    },

    setFilterCounter() {
        this.ui.$selectedLine.html(locale.get('common.filtersSelectedInfo', this.collection.length, this.totalFilters));
        this.ui.$maxCounter.html(this.totalFilters);
    },

    addFirstFilter() {
        if (this.collection.isEmpty()) {
            // output the first filter selection
            this.addFilter();
        }
    },

    addFilters() {
        const self = this;
        this.filtersList.each((column) => {
            const filterField = column.get('field');
            const model = self.collection.findWhere({
                field: filterField,
            });
            self.addFilter(filterField, model);
        });
    },

    handleAlreadyAddedFilters() {
        this.closeChildren();
        this.collection.filter(model => model.get('field')).each(function (model) {
            this.addFilter(model);
        }, this);
    },

    showHideAddFilterButton() {
        const filtersLength = this.children.length;
        if (filtersLength >= this.totalFilters) {
            return this.ui.addFilter.hide();
        }
        this.ui.addFilter.show();
        return undefined;
    },

    addFilter(fieldId, model) {
        if (!fieldId) {
            return;
        }

        const optionsCollections = this.options.columns || this.columns;
        const grid = this.options.grid || this.grid;

        const view = new this.SearchViewClass({
            filtersCollection: this.collection,
            collection: optionsCollections,
            first: this.children.length === 0,
            grid,
            model,
            filterProc: this.options.filterProc,
            advancedSearch: true,
            id: fieldId,
        });

        view.render();
        this.listenTo(
            view,
            {
                'filter:remove': this.removeFilter,
                filter: this.handleAddFilter,
                'filter:updateCounter': this.handleFilterCounter,
            },
        );

        this.ui.addFilterContainer.append(view.el);

        this.children.add(view);
    },

    handleAddFilter(e) {
        this.addFilter();
        this.trigger('add', this, e);
        this.showHideAddFilterButton();
    },

    handleSubmit(e) {
        e.preventDefault();
        this.collection.reset(this.getFilters());
        this.trigger('submit', this, e);
        dialog.close();
    },

    itemViewOptions() {
        return {
            collection: Marionette.getOption(this, 'columns'),
        };
    },

    getFilters() {
        // remove empty children
        return this.children.filter(child => child.$el.html().length > 0
            && (child.createFilter || (child.searchView && child.searchView.createFilter)))
            .map((child) => {
                if (child.createFilter) {
                    return child.createFilter();
                }
                if (child.searchView && child.searchView.createFilter) {
                    return child.searchView.createFilter();
                }
                return {}; // Empty will be filtered out below
            }).filter(child => child.value !== undefined);
    },

    removeFilter(filterView) {
        if (filterView) {
            this.removeChildView(filterView);
            if (this.children.length === 0) {
                this.addFilter();
            }
            this.showHideAddFilterButton();
        }
    },

    handleClearAllFilters() {
        this.children.each((filterView) => {
            filterView.trigger('filter:clear', filterView);
        }, this);
    },

    handleFilterCounter() {
        const counter = this.getFilters();
        this.ui.$selectedLine.html(locale.get('common.filtersSelectedInfo', counter.length, this.totalFilters));
    },
});
