import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import alert from '@glu/alerts';
import locale from '@glu/locale';
import $ from 'jquery';
import store from 'system/utilities/cache';
import ChallengeModel from 'app/challengeManager/models/challenge';
import MultiStepForm from 'common/multiStepForm/views/multiStepForm';
import Criteria from 'app/challengeManager/views/criteria';
import ChallengeAuditView from 'app/challengeManager/views/challengeAuditView';
import SystemActionsCollection from 'app/challengeManager/collections/actions';
import SegmentsCollection from 'app/challengeManager/collections/segments';
import UsersCollection from 'app/challengeManager/collections/users';
import CompaniesCollection from 'app/challengeManager/collections/companies';
import Population from 'app/challengeManager/views/population';
import scroll from 'common/util/scroll';
import addChallengeTmpl from 'app/challengeManager/views/addChallenge.hbs';
import loadingTmpl from 'common/templates/loadingPage.hbs';

const ENABLED = 'ENABLED';
const DISABLED = 'NOT_ENABLED';

const AddChallenge = Layout.extend({
    template: addChallengeTmpl,
    loadingTemplate: loadingTmpl,

    initialize() {
        this.model = new ChallengeModel();

        if (this.options.action === 'select') {
            this.loadChallengeData();
        }
        store.unset('challenge:population');
    },

    loadChallengeData() {
        const actionModel = store.get('mfa:actionModel');
        const frequency = actionModel.get('FREQUENCY');
        if (frequency.indexOf('always') > -1) {
            this.model.set('frequency', 'ALWAYS');
        } else if (frequency.indexOf('action') > -1) {
            this.model.set('frequency', 'ONCEPERACTIONPERSESSION');
        } else {
            this.model.set('frequency', 'ONCEPERSESSION');
        }
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.multiStepForm = new MultiStepForm({
                multiFormSteps: this.steps,
            });
            if (this.options.action === 'modify' || this.options.action === 'select') {
                this.challengeAuditViewForm = new ChallengeAuditView({
                    model: this.systemActionsCollection.at(0),
                });
                this.auditRegion.show(this.challengeAuditViewForm);
            }
            this.multiStepRegion.show(this.multiStepForm);
            this.listenTo(this.multiStepForm, 'step:invalid', this.handleStepError);
        } else {
            this.loadRequiredData();
        }
    },

    handleStepError(step) {
        const modelErr = step.model.get('error');

        Object.keys(modelErr || {}).forEach((error) => {
            // TODO: We probably only want to empty this region once?
            $('.alert-region').empty();
            if (error === 'action') {
                this.showAlertView(modelErr[error][0]);
            }
        });

        scroll.scrollToFirstError();
    },

    showAlertView(message) {
        this.alertView = alert.danger(
            message,
            {
                canDismiss: true,
                animate: true,
            },
        );
        this.$('.alert-region').html(this.alertView.render().el);
        this.alertView.$el.focus();
    },

    setSteps() {
        const self = this;

        const stepOptions = {
            action: self.options.action,
            systemActionsCollection: this.systemActionsCollection,
        };

        this.steps = [{
            title: locale.get('mfa.ChallengeCriteria'),
            id: 'mfa:criteria',
            view: new Criteria(stepOptions),
        }, {
            title: locale.get('mfa.ChallengePopulation'),
            id: 'mfa:population',
            view: new Population(stepOptions),
        }];
    },

    saveChallenge() {
        const steps = this.multiStepForm.getAllSteps();
        const self = this;
        const populationType = this.systemActionsCollection.at(0).get('populationType');
        const currentPopulation = this.systemActionsCollection.at(0).get('population');

        let challengeObj = {};

        util.each(steps, (step) => {
            const model = step.view.model.toJSON();
            if (model) {
                challengeObj = util.extend(challengeObj, model);
                if (step.id === 'mfa:criteria') {
                    if (util.isBoolean(model.precheckEnabled)
                        && model.precheckEnabled) {
                        challengeObj.precheckEnabled = ENABLED;
                    } else {
                        challengeObj.precheckEnabled = DISABLED;
                    }

                    if (util.isBoolean(model.precheckPaymentIncluded)
                        && model.precheckPaymentIncluded) {
                        challengeObj.precheckPaymentIncluded = ENABLED;
                    } else {
                        challengeObj.precheckPaymentIncluded = DISABLED;
                    }
                }
            }
        });

        if (challengeObj.population) {
            if (challengeObj.population.length === 0) {
                this.showAlertView(locale.get('mfa.populationNotification'));
                $('html, body').animate({
                    scrollTop: 0,
                }, 100);
                return;
            }
            challengeObj.population[0].criteriaName = challengeObj.criteriaName;
        } else if (!currentPopulation || currentPopulation.length === 0) {
            this.showAlertView(locale.get('mfa.incompleteNotification'));
            $('html, body').animate({
                scrollTop: 0,
            }, 100);
            return;
        }

        const countOfSelectedActions = util.where(
            challengeObj.actions.feature,
            {
                enabled: true,
            },
        ).length;

        if (countOfSelectedActions === 0) {
            this.showAlertView(locale.get('mfa.aSystemActionIsRequired'));
            $('html, body').animate({
                scrollTop: 0,
            }, 100);
            return;
        }

        if (this.options.action === 'modify' && !challengeObj.population && currentPopulation) {
            const existingPopulation = [];
            util.each(currentPopulation, (item) => {
                const existing = {};
                switch (populationType) {
                case 'USERS':
                    // move items of the existing record onto the model
                    existing.USERID = item.userId;
                    existing.USERNAME = self.populationCollection.findWhere({
                        USERID: item.userId,
                    }).get('USERNAME');
                    existing.GROUPNAME = item.companyName;
                    existing.USERGROUP = item.companyId;
                    existing.criteriaName = item.criteriaName;
                    break;
                case 'COMPANIES':
                    existing.USERGROUP = item.companyId;
                    existing.GROUPNAME = self.populationCollection.findWhere({
                        USERGROUP: item.companyId,
                    }).get('GROUPNAME');
                    existing.criteriaName = item.criteriaName;
                    break;
                case 'SEGMENTS':
                    existing.NAME = item.segmentId;
                    existing.criteriaName = item.criteriaName;
                    break;
                default:
                }
                existingPopulation.push(existing);
            });

            challengeObj.population = existingPopulation;
            challengeObj.populationType = this.systemActionsCollection.at(0).get('populationType');
            challengeObj.selectionMethod = this.systemActionsCollection.at(0).get('selectionMethod');
        }

        if (challengeObj.finalApproval === true) {
            challengeObj.finalApproval = '1';
        } else if (challengeObj.finalApproval !== '1') {
            challengeObj.finalApproval = '0';
        }

        this.model.set(challengeObj);

        if (this.options.action === 'modify') {
            this.model.set('id', this.model.get('criteriaName'));
        }
        this.model.save(
            {},
            {
                success() {
                    store.set('mfa:alertMessage', locale.get('mfa.challengeSaved'));
                    store.unset('mfa:actionModel');
                    self.navigateTo('SMAINT/MFA/challengeConfig');
                },
            },
        );
    },

    cancel() {
        if (this.actionModel) {
            this.navigateTo(this.actionModel.get('returnUrl'));
        } else {
            window.history.back();
        }
    },

    modifyChallenge() {
        this.navigateTo('MFA/modifyChallenge');
    },

    loadRequiredData() {
        const options = {};

        if (this.options.action === 'select' || this.options.action === 'modify') {
            this.actionModel = store.get('mfa:actionModel');
            options.action = this.options.action;
            options.criteria = this.actionModel.get('CRITERIA_NAME');
        }

        this.systemActionsCollection = new SystemActionsCollection(options);

        const self = this;
        this.systemActionsCollection.fetch({
            success() {
                if (self.options.action === 'modify') {
                    self.getPopulationData();
                } else {
                    self.setSteps();
                    self.setHasLoadedRequiredData(true);
                    self.render();
                }
            },

            error() {
                self.setSteps();
                self.setHasLoadedRequiredData(true);
                self.render();
            },
        });
    },

    getPopulationData() {
        const populationID = this.systemActionsCollection.at(0).get('population');
        const populationType = this.systemActionsCollection.at(0).get('populationType');
        const pArr = [];

        if (populationType === 'SEGMENTS') {
            this.populationCollection = new SegmentsCollection();
        }
        if (populationType === 'COMPANIES') {
            this.populationCollection = new CompaniesCollection();
        }
        if (populationType === 'USERS') {
            this.populationCollection = new UsersCollection();
        }

        util.each(populationID, (item) => {
            let pid;

            if (populationType === 'SEGMENTS') {
                pid = item.segmentId;
            }
            if (populationType === 'COMPANIES') {
                pid = item.companyId;
            }
            if (populationType === 'USERS') {
                pid = item.userId;
            }
            if (pid !== null && pid !== undefined) {
                pArr.push(pid);
            }
        }, this);

        const self = this;

        this.populationCollection.fieldValue = pArr;
        this.populationCollection.fetch({
            success() {
                self.setSteps();
                self.setHasLoadedRequiredData(true);
                self.render();
            },
        });
    },

    templateHelpers() {
        const self = this;
        return {
            viewChallenge: this.options.action === 'select',

            title() {
                let title;
                const { action } = self.options;

                switch (action) {
                case 'select':
                    title = locale.get('mfa.viewChallenge');
                    break;
                case 'modify':
                    title = locale.get('mfa.modifyChallenge');
                    break;
                default:
                    title = locale.get('mfa.addNewChallenge');
                }

                return title;
            },
        };
    },
});

export default AddChallenge;
