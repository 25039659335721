import Layout from '@glu/core/src/layout';
import ListBuilder from 'common/listBuilder/listBuilder';
import constants from 'app/administration/constants';
import appConfigParams from 'system/webseries/models/applicationConfiguration';
import commonConstants from 'common/dynamicPages/api/constants';
import util from '@glu/core/src/util';
import Model from '@glu/core/src/model';
import locale from 'system/webseries/api/locale';
import systemConfig from 'system/configuration'; // PCM RELATED
import ReactDOM from 'react-dom'; // PCM RELATED
import React from 'react'; // PCM RELATED
import $ from 'jquery'; // PCM RELATED
import DocumentRoleDropdown from 'pcm/common/components/DocumentRoleDropdown'; // PCM
import ItemView from '@glu/core/src/itemView';
import FlexDropdown from '@glu/flex-dropdown';
import AdminUtil from 'app/administration/common/util';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import UsersCollection from '../../collection/users';
import RolesCollection from '../../collection/roles';
import UsersAndRolesCollection from '../../collection/usersAndRoles';
import labelTemplateTmpl from './labelTemplate.hbs';
import BankMaintainedNote from './bankMaintainedNoteView';
import assignRolesLayoutTmpl from './assignRolesLayout.hbs';
import ApproverTypesCollection from '../../collection/approverTypes';

const LabelView = ItemView.extend({
    className: 'role-group-item',
    template: labelTemplateTmpl,
});
    /**
     * @config {String} mode
     * @config {Model} model
     */
export default Layout.extend({
    template: assignRolesLayoutTmpl,
    loadingTemplate: loadingPageTmpl,

    ui: {
        $popovers: '[data-toggle="popover"]',
        $copyUserSelect: '.copy-user-select',
        $approverDropdown: '[data-hook="approverDropdown"]',
    },

    regions: {
        bankMaintainedRolesNote: '[data-region="bankMaintainedRolesNote"]',
        approverTypeRegion: '[data-region="approverTypeRegion"]',
    },

    // PCM RELATED
    initialize(opts) {
        this.isAdmin = systemConfig.isAdmin();
        this.userGroup = opts.model.attributes.USERGROUP;
        this.isTxClient = opts.model.attributes.ISTXCLIENT;
    },

    // Only needed for view mode
    loadRequiredData() {
        const self = this;

        if (this.options.mode !== constants.MODES.VIEW) {
            this.bankMaintainedNoteModel = new Model();
            this.bankMaintainedNoteView = new BankMaintainedNote({
                model: this.bankMaintainedNoteModel,
            });
            if (this.options.hasPanelApproval) {
                this.approverTypesCollection = new ApproverTypesCollection();
            }
            this.usersCollection = new UsersCollection();
            this.sourceCollection = new RolesCollection();
            this.targetCollection = new RolesCollection();
            this.usersAndRolesCollection = new UsersAndRolesCollection();

            this.sourceCollection.fetch({
                success: () => {
                    if (this.model.roles) {
                        util.each(this.model.roles, (role) => {
                            this.targetCollection
                                .add(this.sourceCollection.remove(role.ROLEID));
                        });
                    }

                    if (AdminUtil.isBankMaintainedRolesEnabled()) {
                        this.removeBankMaintainedRoles(this.sourceCollection);
                        // remove from target and save for later use
                        this.bankMaintainedRoles = this
                            .removeBankMaintainedRoles(this.targetCollection);
                    }
                    self.setHasLoadedRequiredData(true);
                    self.render();
                    self.afterRender(); // PCM RELATED
                },
            });
        } else {
            const collection = new RolesCollection();
            this.viewModeRecords = [];
            if (this.options.hasPanelApproval) {
                this.approverTypesCollection = new ApproverTypesCollection();
            }
            collection.fetch({
                success: () => {
                    if (this.model.roles) {
                        util.each(this.model.roles, (role) => {
                            this.viewModeRecords.push(collection.get(role.ROLEID).toJSON());
                        });
                    }
                    self.setHasLoadedRequiredData(true);
                    self.render();
                    self.afterRender(); // PCM RELATED
                },
            });
        }
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
            return;
        }

        if (this.options.mode !== constants.MODES.VIEW) {
            this.createCombo();

            this.createListBuilder();
            this.setBankMaintenedRoleNote(this.bankMaintainedRoles);

            /*
             * If copy from user was selected from context menu or
             * view mode, fetch the user info for the seeded data
             * and set the model.  The model change will then trigger
             * the combo change and populate the roles.
             */
            if (this.options.copyFromUser) {
                this.usersAndRolesCollection.fetch({
                    userid: this.options.copyFromUser,

                    success: () => {
                        this.model.set('COPYFROMUSER', this.options.copyFromUser);
                        this.gotRolesFromUsersCollection(this.options.copyFromUser);
                    },
                });
            }
        }

        this.ui.$popovers.popover();

        if (this.options.hasPanelApproval) {
            if (!this.approverDropdown) {
                this.createApproverDropdown();
            } else {
                this.approverTypeRegion.show(this.approverDropdown);
            }
        }
    },

    // PMC RELATED
    getPtxGroupContainer() {
        const container = $('.form-column.PTXGroup_user');
        if (container.length) {
            return container;
        }
        return null;
    },
    afterRender() {
        const ptxContainer = this.getPtxGroupContainer();
        if (ptxContainer) {
            if (this.options.mode === 'modify') {
                const parentEl = ptxContainer.parent().parent();
                parentEl.prev().insertAfter(parentEl);
                this.renderRoleDropdownComponent();
            } else if (this.options.mode === 'view') {
                const roleValue = this.model.get('_CLFLDS_PTXRoleName');
                ptxContainer.get(0).children[0].hidden = true;
                if (roleValue) {
                    $('span[name ="_CLFLDS_PTXRoleId"]').text(roleValue);
                }
            } else {
                this.renderRoleDropdownComponent();
            }
        }
    },

    renderRoleDropdownComponent() {
        const ptxContainer = this.getPtxGroupContainer();
        if (ptxContainer) {
            ptxContainer.get(0).children[0].hidden = true;
            // eslint-disable-next-line react/jsx-filename-extension,react/react-in-jsx-scope
            ReactDOM.render(<DocumentRoleDropdown
                name="PTXROLE"
                isAdmin={this.isAdmin}
                userGroup={this.userGroup}
                label={locale.get('checkManagement.documentProfile')}
                valueFromModel={this.model.get('_CLFLDS_PTXRoleId')}
                onChange={(id, name) => {
                    this.model.set('_CLFLDS_PTXRoleId', id);
                    this.model.set('_CLFLDS_PTXRoleName', name);
                }}
            />, ptxContainer.get(0).children[1]);
        }
    },

    /**
     * Sets up data for the copy from user combo
     */
    createCombo() {
        const combo = this.ui.$copyUserSelect.comboBox({
            allowClear: true,
            query: util.debounce((query) => {
                this.usersCollection.setFilter(query.term);
                this.usersCollection.fetch({
                    success: (results) => {
                        query.callback({
                            results: results.toJSON(),
                        });
                    },
                });
            }, commonConstants.COMBO_DEBOUNCE),

            // Seed the initial selection if copy from user from context menu or view mode
            initSelection: (element, cb) => {
                const val = element.val();
                const text = this.model.get('COPYFROMUSER');
                if (text && !this.isTxClient) {
                    this.ui.$copyUserSelect.show();
                    cb({
                        id: val,
                        text,
                    });
                } else {
                    cb();
                }
            },
        });

        /**
         * on user selection, fire second request to get all roles for full user name.
         * on second request sucess, copy roles from the usersAndRolesCollection
         */
        combo.on('change', (e) => {
            const userid = (e.val || e.currentTarget.value).toUpperCase();
            this.fetchUserAndRoles(userid);
        });
    },

    fetchUserAndRoles(userid) {
        this.usersAndRolesCollection.fetch({
            userid,
            success: () => {
                this.gotRolesFromUsersCollection(userid);
            },
        });
    },

    gotRolesFromUsersCollection(username) {
        const user = this.usersAndRolesCollection.get(username);
        const roles = user ? user.get('roles') : [];
        this.rolesListBuilder.removeAllItems();
        util.each(roles, (role) => {
            this.targetCollection.add(this.sourceCollection.remove(role.RoleId));
        });
        this.rolesListBuilder.sortLists();
    },

    createListBuilder() {
        this.rolesListBuilder = new ListBuilder({
            sourceCollection: this.sourceCollection,
            targetCollection: this.targetCollection,
            labelView: LabelView,
            dataPersist: true,
            showSearch: !this.isTxClient,
        });

        this.rolesListBuilder.text.moveSelectedItems = locale.get('PS.moveSelectedItems');
        this.rolesListBuilder.text.moveAllItems = locale.get('PS.moveAllItems');
        this.rolesListBuilder.text.removeSelectedItems = locale.get('PS.removeSelectedItems');
        this.rolesListBuilder.text.removeAllItems = locale.get('PS.removeAllItems');
        this.rolesListBuilder.text.filter = locale.get('filter');
        this.rolesListBuilder.text.sourceListHeader = 'PS.sourceListHeader';
        this.rolesListBuilder.text.targetListHeader = 'PS.targetListHeader';
        this.rolesListBuilder.text.clearSourceSelection = locale.get('PS.clearSourceSelection');
        this.rolesListBuilder.text.clearTargetSelection = locale.get('PS.clearTargetSelection');
        this.listBuilderRegion.show(this.rolesListBuilder);
    },

    templateHelpers() {
        return {
            readOnly: this.options.mode === constants.MODES.VIEW,
            viewModeRecords: this.viewModeRecords,
            noRoles: this.viewModeRecords?.length === 0,
            bankMaintainedRolesEnabled: AdminUtil.isBankMaintainedRolesEnabled(),
            hasPanelApproval: this.options.hasPanelApproval,
            showAllowFXSelection: appConfigParams.getValue('WIRES', 'ALLOWFXUSDUSER') === '1',
            isTxClient: this.isTxClient,
        };
    },

    removeBankMaintainedRoles(list) {
        const bankMaintainedRoles = this.getBankMaintainedRoles(list);
        util.each(bankMaintainedRoles, (role) => {
            list.remove(role.get('id'));
        });
        return bankMaintainedRoles;
    },

    getBankMaintainedRoles(roles) {
        const list = [];
        roles.each((role) => {
            if (AdminUtil.isBankMaintainedRole(role)) {
                list.push(role);
            }
        });
        return list;
    },

    setBankMaintenedRoleNote(roles) {
        if (roles && roles.length > 0) {
            let content = '';
            util.each(roles, (role) => {
                content += `${role.get('id')}-${role.get('title')} `;
            });
            this.bankMaintainedRolesNote.show(this.bankMaintainedNoteView);
            this.bankMaintainedNoteModel.set('CONTENT', content);
            this.bankMaintainedNoteModel.set('COUNT', roles.length);
        }
    },

    createApproverDropdown() {
        const userModel = this.model;
        this.approverTypesCollection.fetch({
            success: (approverTypes, dropdownData) => {
                this.approverDropdown = new FlexDropdown({
                    data: dropdownData,
                    label: locale.get('uce.approverType'),
                    preSelectedIds: [userModel.get('APPROVERTYPE')],
                    disabled: this.mode === 'view',
                    disableMultiButton: true,
                    clearBtn: true,
                    multiSelect: false,
                });
                this.listenTo(this.approverDropdown, 'selectionChanged', this.handleApproverTypeChange);
            },
            userModel,
        });
    },
    handleApproverTypeChange(selected) {
        this.model.set('APPROVERTYPE', selected[0].id);
    },
});
