import React from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';

const Tabs = ({ tabs, activeTab }) => (
    <ul className="nav nav-tabs page-subtabs">
        {(tabs && tabs.length) ? (
            tabs.map((tab, index) => (tab.visible ? (
                <Tab {...tab} active={activeTab === tab.name} key={index} />
            ) : null))
        ) : null}
    </ul>
);

Tabs.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape(Tab.propTypes)).isRequired,
    activeTab: PropTypes.string.isRequired,
};

export default Tabs;
