var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!--breadcrumbs and title-->\n<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"adminCM.fraud_title",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":31},"end":{"line":3,"column":64}}}))
    + "</h1>\n</div>\n\n<div class=\"section section-container\" role=\"navigation\">\n    <div class=\"section-body\">\n        <ul id=\"fraud-control-tablist\"\n            class=\"NavTabs nav nav-tabs\"\n            data-hook=\"getNavTabs\"\n            role=\"tablist\">\n            <li class=\"NavTabs-item is-active\"\n                role=\"tab\">\n                <a aria-controls=\"individual\"\n                    class=\"NavTabs-link btn btn-tertiary\"\n                    id=\"individual\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.todays-decisions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":36},"end":{"line":16,"column":68}}}))
    + "\n                </a>\n            </li>\n            <li class=\"NavTabs-item\"\n                role=\"tab\">\n                <a aria-controls=\"extract\"\n                    class=\"NavTabs-link btn btn-tertiary\"\n                    id=\"extract\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.FileExtract",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":33},"end":{"line":23,"column":60}}}))
    + "\n                </a>\n            </li>\n            <li class=\"NavTabs-item\"\n                role=\"tab\">\n                <a aria-controls=\"decision-history\"\n                    class=\"NavTabs-link btn btn-tertiary\"\n                    id=\"decision-history\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.decision-history",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":30,"column":42},"end":{"line":30,"column":74}}}))
    + "\n                </a>\n            </li>\n            <li class=\"NavTabs-item\"\n                role=\"tab\">\n                <a aria-controls=\"edecision-history\"\n                    class=\"NavTabs-link btn btn-tertiary\"\n                    id=\"edecision-history\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.edecision-history",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":37,"column":43},"end":{"line":37,"column":76}}}))
    + "\n                </a>\n            </li>\n        </ul>\n        <div class=\"accountGroupToggle right\">\n            <strong>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.viewAllColon",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":42,"column":20},"end":{"line":42,"column":48}}}))
    + "</strong>\n            <button class=\"btn btn-tertiary active\" data-id=\"accs-by-type\" id=\"accs-by-type\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.accountsByType",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":43,"column":93},"end":{"line":43,"column":123}}}))
    + "</button>\n                  | <button class=\"btn btn-tertiary\" data-id=\"posPay-items\" id=\"posPay-items\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.positivePayItems",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":44,"column":94},"end":{"line":44,"column":126}}}))
    + "</button>\n                  | <button class=\"btn btn-tertiary\" data-id=\"revPosPay-items\" id=\"revPosPay-items\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.reversePositivePayItems",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":45,"column":100},"end":{"line":45,"column":139}}}))
    + "</button>\n                  | <button class=\"btn btn-tertiary\" data-id=\"ePay-items\" id=\"ePay-items\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.electronicItems",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":46,"column":90},"end":{"line":46,"column":121}}}))
    + "</button>\n        </div>\n        <div class=\"pageRegion remove-section-styles\"></div>\n    </div>\n</div>\n";
},"useData":true});