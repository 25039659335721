import $ from 'jquery';
import EntryView from 'app/checkManagement/views/viewBasePayDecision';
import constants from 'common/dynamicPages/api/constants';
import store from 'system/utilities/cache';
import alertMessage from 'common/api/alertMessage';
import dialog from '@glu/dialog';
import ReturnReasonDialog from 'app/checkManagement/views/returnReasonDialog';
import locale from '@glu/locale';

export default EntryView.extend({
    initialize(options) {
        this.setModel();
        this.checkViewer = options.checkViewer;
        EntryView.prototype.initialize.call(this, options);
    },

    onRender() {
        EntryView.prototype.onRender.call(this);

        if (store.has(`${this.contextKey}-alertMessageCV`) && this.alertRegion) {
            alertMessage.renderMessage(this, store.get(`${this.contextKey}-alertMessageCV`), store.get(`${this.contextKey}-confirmsCV`), 0);
            store.set(`${this.contextKey}-alertMessageCV`, null);
            store.set(`${this.contextKey}-confirmsCV`, null);
        }
    },

    setModel() {
        this.model = this.model || store.get('cm_listView_reversePositivePayDecision-actionModel');
    },

    setHasLoadedRequiredData(bool) {
        if (bool) {
            const accountNumber = this.model.get('ACCOUNT_NUMBER');
            this.storedModel.set('ACCOUNT_NUMBER', accountNumber);

            const accountName = this.model.get('ACCOUNT_TITLE');
            this.storedModel.set('ACCOUNT_TITLE', accountName);

            const status = $.trim(this.model.get('STATUS'));
            this.storedModel.set('STATUS', status);

            const decision = $.trim(this.model.get('CMDECISION'));
            this.storedModel.set('CMDECISION', decision);

            const payeeName = $.trim(this.model.get('ISSUEDPAYEENAME'));
            this.storedModel.set('ISSUEDPAYEENAME', payeeName.replace(/&amp;/g, '&'));
        }

        EntryView.prototype.setHasLoadedRequiredData.call(this, bool);

        this.trigger('checkDetail:loaded');
    },

    getListUrl() {
        return this.options.returnRoute;
    },

    getFieldDataToSendToServer() {
        return {
            PPCMPPPAIDITEMID: {
                name: 'PPCMPPPAIDITEMID',
                virtualField: false,
            },
            CMDECISION: {
                name: 'CMDECISION',
                virtualField: false,
            },
            CLIENTRETURNREASONCODE: {
                name: 'CLIENTRETURNREASONCODE',
                virtualField: false,
            },
            CLIENTRETURNREASONDESC: {
                name: 'CLIENTRETURNREASONDESC',
                virtualField: false,
            },
            PAYISSUE_FLAG: {
                name: 'PAYISSUE_FLAG',
                virtualField: false,
            },
            // eslint-disable-next-line
            UPDATECOUNT__: {
                name: 'UPDATECOUNT__',
                virtualField: false,
            },
        };
    },

    removeAttributesFromValidationCheck() {
        /*
         * Quick workaround to fix NH-18134.
         * This attribute is never sent to the server so doesn't need validation anyway.
         */
        this.pageView.model.unset('ISSUEDPAYEENAME');
    },

    pay() {
        // only send down fields necessary to make a decision.
        this.pageView.model.fieldData = this.getFieldDataToSendToServer();
        this.removeAttributesFromValidationCheck();

        this.pageView.model.set({
            ACTIONCODE: 'P',
            CMDECISION: 'P',
            CLIENTRETURNREASONCODE: '',
            CLIENTRETURNREASONDESC: '',
            PAYISSUE_FLAG: 'N',
        });

        this.pageView.save();
    },

    cancel() {
        this.navigateTo(this.options.returnRoute);
    },

    return() {
        // only send down fields necessary to make a decision.
        this.pageView.model.fieldData = this.getFieldDataToSendToServer();
        this.removeAttributesFromValidationCheck();
        this.pageView.model.set({
            ACTIONCODE: 'R',
            CMDECISION: 'R',
            PAYISSUE_FLAG: '',
        });

        const returnReasonDialog = new ReturnReasonDialog({
            model: this.pageView.model,
            mode: this.mode,
            contextKey: this.contextKey,
            multiSelect: false,
            pageView: this.pageView,
            type: 'CMPPDEC',
            serviceUrl: constants.URL_RETURN_REASON_CMPPDEC,
            returnRoute: this.options.returnRoute,
            checkViewer: this.checkViewer,
            onNextCheckClick: () => this.getDecisionLocale(),
        });
        dialog.custom(returnReasonDialog);
    },

    getDecisionLocale() {
        if (!this.pageView.model.get('CLIENTRETURNREASONCODE')) {
            const payFlag = this.pageView.model.get('PAYISSUE_FLAG');

            if (payFlag === 'Y') {
                this.model.set('CMDECISION', locale.get('CM.PayIssue'));
            } else {
                this.model.set('CMDECISION', locale.get('CM.Paid'));
            }
        } else {
            this.model.set('CMDECISION', locale.get('CM.Return'));
        }
    },

    actionSuccess(confirmResponse) {
        const { contextKey } = this;
        this.getDecisionLocale();

        if (this.checkViewer === undefined) {
            store.set(`${contextKey}-alertMessage`, this.mode.toUpperCase());
            store.set(`${contextKey}-confirms`, confirmResponse);
            this.navigateTo(this.options.returnRoute);
        } else {
            store.set(`${contextKey}-alertMessageCV`, this.mode.toUpperCase());
            store.set(`${contextKey}-confirmsCV`, confirmResponse);
            this.checkViewer.nextCheck();
        }
    },
});
