var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"resultIsNumber") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":6,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.expires.in",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":14},"end":{"line":3,"column":45}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"RATETRADERESULT") || (depth0 != null ? lookupProperty(depth0,"RATETRADERESULT") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"RATETRADERESULT","hash":{},"data":data,"loc":{"start":{"line":3,"column":46},"end":{"line":3,"column":65}}}) : helper)))
    + " "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.seconds",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":66},"end":{"line":3,"column":94}}}))
    + "</span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"RATETRADERESULT") || (depth0 != null ? lookupProperty(depth0,"RATETRADERESULT") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"RATETRADERESULT","hash":{},"data":data,"loc":{"start":{"line":5,"column":14},"end":{"line":5,"column":33}}}) : helper)))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"RATETRADERESULT") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":7,"column":7}}})) != null ? stack1 : "");
},"useData":true});