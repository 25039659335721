var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div>\n                    <label class=\"main-label\"><input type=\"checkbox\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"NAME") || (depth0 != null ? lookupProperty(depth0,"NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NAME","hash":{},"data":data,"loc":{"start":{"line":12,"column":75},"end":{"line":12,"column":83}}}) : helper)))
    + "\" data-group=\""
    + alias4(container.lambda((depths[1] != null ? lookupProperty(depths[1],"NAME") : depths[1]), depth0))
    + "\" value=\"true\" data-bind=\"model\"/>\n                        <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"NAME") || (depth0 != null ? lookupProperty(depth0,"NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NAME","hash":{},"data":data,"loc":{"start":{"line":13,"column":30},"end":{"line":13,"column":38}}}) : helper)))
    + "</span>\n                    </label>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog modal-lg glu-dialog custom-dialog\">\n    <div class=\"modal-content\">\n        <div class=\"modal-header\">\n            <button aria-hidden=\"false\" data-dismiss=\"modal\" class=\"close\" type=\"button\"><span class=\"icon-close\"></span></button>\n            <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.external.widget.deploy",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":66}}}))
    + "</h2>\n        </div>\n\n        <div class=\"modal-body\" data-hook=\"region-main\">\n            <h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.market.segments",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":59}}}))
    + ": </h3>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"segments") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":16,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n\n        <div class=\"modal-footer\">\n            <button type=\"button\" class=\"btn btn-primary submit save-deployment\" data-action=\"save\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.btn.save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":100},"end":{"line":20,"column":129}}}))
    + "</button>\n            &nbsp;\n            <button type=\"button\" class=\"btn btn-secondary\" data-action=\"cancel\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.btn.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":81},"end":{"line":22,"column":112}}}))
    + "</button>\n        </div>\n\n    </div>\n</div>\n";
},"useData":true,"useDepths":true});