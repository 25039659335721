import { dark } from 'common/util/featureUtil';
import appConfigParams from 'system/webseries/models/applicationConfiguration';

const OrderingPartyLookupUtil = ({

    /**
     * This method tests that the feature configuration and the two required application
     * configurations are each enabled. Furthermore this tests that the user group is
     * entitled to allow by order of information. This is essential for displaying ordering
     * party information and workflows and is centralized here rather than writing the same
     * test for each file it is needed.
     *
     * @param {string} allowByOrderOf - user group entitlement for allowing by order of
     *      payment information. This value is from RTGSUserGroup and is usually populated
     *      on the model server side by a pre-lookup processor.
     * @returns {boolean} if feature is enabled and application configurations are enabled
     *      and if the user group is entitled
     */
    isEntitled(allowByOrderOf) {
        return allowByOrderOf !== undefined
            && allowByOrderOf === '1'
            && this.isConfigured();
    },

    /**
     * This method tests that the feature configuration and the two required application
     * configurations are each enabled. This is essential for displaying ordering party
     * information and workflows and is centralized here rather than writing the same test
     * for each file it is needed.
     *
     * @returns {boolean} if feature is enabled and application configurations are enabled
     */
    isConfigured() {
        return dark.exists('ORDERINGPARTYLU')
            && dark.isLive('ORDERINGPARTYLU')
            && appConfigParams.getValue('WIRES', 'ALLOWBYORDEROF') === '1'
            && appConfigParams.getValue('WIRES', 'ALLOWORDERINGPARTYLOOKUP') === '1';
    },
});

export default OrderingPartyLookupUtil;
