import Collection from '@glu/core/src/collection';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';

export default Collection.extend({

    /**
     *
     * @param {Object} [accumulator]
     * @param {Object} response
     * @return {Object}
     */
    mergeAccumulatedResponse(accumulator, response) {
        if (accumulator === undefined) {
            return response;
        }

        const totalRows = response.queryResponse.QueryData.numRows
            + accumulator.queryResponse.QueryData.numRows;
        const queryRows = accumulator.queryResponse.QueryData
            .queryRows.concat(response.queryResponse.QueryData.queryRows);

        return util.extend({}, accumulator, {
            queryResponse: {
                QueryData: {
                    numRows: totalRows,
                    queryRows,
                },
                respHeader: accumulator.queryResponse.respHeader,
                responseParameters: accumulator.queryResponse.responseParameters,
            },
        });
    },

    /**
     *
     * @param {Number} starting
     * @param {Object} [accumulator]
     * @return {Promise}
     */
    getAccumulatedData(starting, accumulator) {
        const self = this;
        let currentAccumulator = accumulator;

        const data = {
            IncludeMapData: 1,
            queryCriteria: {
                allowDuplicates: this.allowDuplicates,
                inquiryId: this.inquiryId,
                customFilters: [{
                    filterName: 'Depends',
                    filterParam: [this.column, this.currency],
                }],
                action: {
                    typeCode: this.typeCode,
                    entryMethod: 0,
                    productCode: 'RTGS',
                    actionMode: 'INSERT',
                    functionCode: 'INST',
                },
            },
            requestHeader: {
                queryPagesize: 250,
                queryOffset: starting || 0,
                requestId: 0,
            },
        };

        return http.post(services.inquiryQueryResults, data)
            .then((response) => {
                const numOfRows = response.queryResponse.QueryData.numRows;
                // merge data
                currentAccumulator = self.mergeAccumulatedResponse(accumulator, response);
                // if >= 250
                if (numOfRows >= 250) {
                    return self.getAccumulatedData(starting + numOfRows, currentAccumulator);
                }
                return currentAccumulator;
            });
    },

    initialize(options) {
        this.inquiryId = options.inquiryId;
        this.typeCode = options.typeCode;
        this.currency = options.currency;
        this.column = options.column;
        this.allowDuplicates = options.allowDuplicates || undefined;
    },
    sync(method, model, options) {
        this.getAccumulatedData(1).then(options.success, options.error);
    },

    parse(response) {
        let rows = response.queryResponse.QueryData.queryRows;
        rows = util.map(rows, (row) => {
            const mapdata = row.mapDataList;
            const currentRow = row;
            currentRow.mapDataList = util.map(mapdata, (data) => {
                const currentData = data;
                currentData.value = util.unescape(data.value);
                return currentData;
            });
            currentRow.label = util.unescape(currentRow.label);
            return currentRow;
        });
        return rows;
    },
});
