var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li role=\"none\">\n                        <a class=\"item-"
    + alias3(((depths[1] && lookupProperty(depths[1],"sanitizeKey"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"key") : depth0),{"name":"../sanitizeKey","hash":{},"data":data,"loc":{"start":{"line":23,"column":39},"end":{"line":23,"column":61}}}))
    + "\" href=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"appRoot") || (depth0 != null ? lookupProperty(depth0,"appRoot") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"appRoot","hash":{},"data":data,"loc":{"start":{"line":23,"column":69},"end":{"line":23,"column":80}}}) : helper)))
    + "/"
    + alias3(((helper = (helper = lookupProperty(helpers,"requestMappings") || (depth0 != null ? lookupProperty(depth0,"requestMappings") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"requestMappings","hash":{},"data":data,"loc":{"start":{"line":23,"column":81},"end":{"line":23,"column":100}}}) : helper)))
    + "\" role=\"menuitem\" aria-controls=\"main\" tabindex=\"-1\">\n                            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"isLearningLink") : depths[1]),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":28},"end":{"line":24,"column":115}}})) != null ? stack1 : "")
    + "\n                            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"isMyProfileLink") : depths[1]),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":28},"end":{"line":25,"column":94}}})) != null ? stack1 : "")
    + "\n                            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"isLegalLink") : depths[1]),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":28},"end":{"line":26,"column":119}}})) != null ? stack1 : "")
    + "\n                            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"isResourceLink") : depths[1]),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":28},"end":{"line":27,"column":131}}})) != null ? stack1 : "")
    + "\n                            "
    + alias3(((helper = (helper = lookupProperty(helpers,"appResourceName") || (depth0 != null ? lookupProperty(depth0,"appResourceName") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"appResourceName","hash":{},"data":data,"loc":{"start":{"line":28,"column":28},"end":{"line":28,"column":47}}}) : helper)))
    + "\n                        </a>\n                        </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "<span class=\"dropdown-icon icon-graduation-cap\"></span>";
},"4":function(container,depth0,helpers,partials,data) {
    return "<span class=\"icon-person\"></span>";
},"6":function(container,depth0,helpers,partials,data) {
    return "<span class=\"dropdown-icon icon-gavel\" role=\"menuitem\"></span>";
},"8":function(container,depth0,helpers,partials,data) {
    return "<span class=\"dropdown-icon icon-question-solid\" role=\"menuitem\"></span>";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li role=\"presentation\">\n                        <a href=\"#\" class=\"item-changePassword\" tabindex=\"-1\" data-action=\"securityQuestion\">\n                            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasSecurityQuestionMenuIcon") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":28},"end":{"line":36,"column":133}}})) != null ? stack1 : "")
    + "\n                                "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"common.securityQuestion",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":37,"column":32},"end":{"line":37,"column":68}}}))
    + "\n                        </a>\n                        </li>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "<span class=\"dropdown-icon icon-person\" role=\"menuitem\"></span>";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li role=\"none\">\n                        <a href=\"#\" class=\"item-changePassword\" tabindex=\"-1\" data-action=\"changePassword\" role=\"menuitem\">\n                            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasChangePasswordMenuIcon") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":28},"end":{"line":44,"column":117}}})) != null ? stack1 : "")
    + "\n                                "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"function.ChangePwd",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":45,"column":32},"end":{"line":45,"column":63}}}))
    + "\n                        </a>\n                        </li>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "<span class=\"dropdown-icon icon-password\"></span>";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li role=\"none\">\n                    <a href=\"#\" class=\"item-userSettings\" data-action=\"userSettings\" role=\"menuitem\" aria-controls=\"main\">\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasUserSettingsMenuIcon") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":24},"end":{"line":53,"column":95}}})) != null ? stack1 : "")
    + "\n                            "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"function.MySettings",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":54,"column":28},"end":{"line":54,"column":60}}}))
    + "\n                    </a>\n                    </li>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li role=\"none\">\n                    <a href=\"#\" class=\"item-help\" data-action=\"clientHelp\"role=\"menuitem\" aria-controls=\"main\">\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasHelpMenuIcon") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":24},"end":{"line":62,"column":99}}})) != null ? stack1 : "")
    + "\n                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"header.help",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":63,"column":24},"end":{"line":63,"column":48}}}))
    + "\n                    </a>\n                    </li>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "<span class=\"dropdown-icon icon-help\"></span>";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li role=\"none\">\n                    <a href=\"#\" class=\"item-logoff\" data-action=\"logOut\" role=\"menuitem\" aria-controls=\"main\">\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasLogoffMenuIcon") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":24},"end":{"line":71,"column":103}}})) != null ? stack1 : "")
    + "\n                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"header.logoff",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":72,"column":24},"end":{"line":72,"column":50}}}))
    + "\n                    </a>\n                    </li>\n";
},"22":function(container,depth0,helpers,partials,data) {
    return "<span class=\"dropdown-icon icon-logoff\"></span>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<nav class=\"dropdown\" aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"header.user.menu",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":1,"column":34},"end":{"line":1,"column":63}}}))
    + "\">\n    <ul role=\"menubar\" class=\"top\" aria-controls=\"main\">\n        <li class=\"userMenu-item user dropdown-hover parent\" role=\"none\">\n            <a id=\"userMenu-label\" \n                class=\"item-userMenu has-dd\"\n                role=\"menuitem\"\n                aria-haspopup=\"true\"\n                aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"header.user.menu",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":28},"end":{"line":8,"column":57}}}))
    + "\"\n                href onclick=\"return false;\">\n                <span class=\"account-info\"\n                    data-hook=\"getAccountInfo\">\n                        <span class=\"user-name\" data-hook=\"getUserName\"></span>\n                        <span class=\"last-login-wrap\">\n                                <span class=\"last-login-label hidden\" data-hook=\"getLastLoginLabel\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"system.lastLogin",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":100},"end":{"line":14,"column":129}}}))
    + "</span> <span class=\"last-login-value\" data-hook=\"getLastLoginValue\"></span>\n                                        </span>\n                </span>\n                <span class=\"caret\" role=\"presentation\"></span>\n            </span>\n            <div class=\"dropdown-origin-container\">\n                <ul role=\"menu\" aria-hidden=\"true\" aria-labelledby=\"userMenu-label\" class=\"dropdown dropdown-menu\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"dynamicLinks") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":20},"end":{"line":31,"column":29}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasSecurityQuestion") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":20},"end":{"line":40,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasChangePassword") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":20},"end":{"line":48,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasUserSettings") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":16},"end":{"line":57,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasHelp") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":16},"end":{"line":66,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasLogoff") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":16},"end":{"line":75,"column":23}}})) != null ? stack1 : "")
    + "\n            </ul>\n        </li>\n    </ul>\n</nav>";
},"useData":true,"useDepths":true});