import Collection from '@glu/core/src/collection';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import constants from '../constants';

export default Collection.extend({
    initialize(options) {
        this.typeCode = options.isAdmin ? 'CLUSR_TM' : '_USER';
        this.functionCode = options.isAdmin ? 'MAINT' : 'USERS';
        this.productCode = options.isAdmin ? 'CMAINT' : '_ADMIN';
        this.userGroup = options.userGroup;
        this.userId = options.userId;
        this.inquiryId = options.isAdmin
            ? constants.COPY_EXISTING_USER_INQUIRY_ADMIN : constants.COPY_EXISTING_USER_INQUIRY;
    },
    sync(method, model, options) {
        const data = {
            IncludeMapData: 1,
            queryCriteria: {
                inquiryId: this.inquiryId,
                action: {
                    typeCode: this.typeCode,
                    entryMethod: 0,
                    actionMode: 'SELECT',
                    functionCode: this.functionCode,
                    productCode: this.productCode,
                },
                customFilters: [{
                    filterName: 'Depends',
                    filterParam: ['userGroup', this.userGroup],
                },
                {
                    filterName: 'Depends',
                    filterParam: ['USERID', this.userId],
                },
                ],

                queryType: 'SearchQuery',
            },

            requestHeader: {
                queryPagesize: 0,
                queryOffset: 1,
                requestUser: {
                    data: {
                        item: [{
                            name: '',
                            value: '',
                        }],
                    },
                    userId: '',
                },
                requestId: '',
            },
        };

        http.post(services.inquiryQueryResults, data, (result) => {
            options.success(result.queryResponse.QueryData.queryRows);
        }, () => {
            options.error();
        });
    },

    parse(response) {
        return util.map(response, rowItem => ({
            id: rowItem.name,
            label: rowItem.label,
            mapDataList: rowItem.mapDataList,
        }));
    },
});
