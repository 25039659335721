import services from 'services';
import userInfo from 'etc/userInfo';
import locale from '@glu/locale';
import moment from 'moment';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import AccountFilterParent from './accountFilterParent';

const TransSearchAccountFilter = AccountFilterParent.extend({
    initialize() {
        this.listenTo(
            this,
            {
                'change:accountSelectBy': this.updateAccountOptionList,
                'change:accountSelect': this.enforceAccountSelectRules,
                'change:accountType': this.updateAccountOptionList,
                'change:accountGroupFilterOptions change:accountNumberFilterOptions': this.updateAccountOptionList,
            },
        );

        // Expose this in case we need to wait for all the loading.
        this.initPromise = Promise.all([
            this.getAccountNumberFilters(),
            this.getLoanAccountNumberFilters(),
            this.getAccountGroupFilters(),
            this.getLoanAccountGroupFilters(),
            this.getTypeAheadFieldData(),
        ]);
    },

    defaults() {
        return {
            START_DATE: moment(new Date()).subtract(6, 'day').format(userInfo.getDateFormat()),
            END_DATE: moment(new Date()).format(userInfo.getDateFormat()),
            accountNumberFilterOptions: [],
            loanAccountNumberFilterOptions: [],
            accountGroupFilterOptions: [],
            loanAccountGroupFilterOptions: [],
            accountGroupAccountList: [],
            loanAccountGroupAccountList: [],
            accountList: [],
            accountSelectBy: 'number',
            accountTransactionsView: 'single',
            accountSelect: ['all'],
            Accounts: ['all'],
            CUST_REF: '',
            AMOUNT: '',
            BANK_REF: '',
            POSTED_FLAG: '',
            DR_CR: '',
            BAI_CODE: '',
            BAI_GROUP_CODE: '',
            BANK_CODE: '',
            SWIFT_TYPE_CODE: '',
            CURRENCY_CODE: '',
            canDelete: true,
            accountType: 'DEPOSIT',
            functionCode: 'PRO',
            productCode: 'GIR',
            typeCode: 'GIRACCT',
        };
    },

    enforceAccountSelectRules(model, value, options) {
        if (options && options.enforced) {
            return;
        }

        const previous = this.previous('accountSelect');
        const current = this.get('accountSelect');
        const difference = util.difference(current, previous);
        let enforced = [];

        if (util.isArray(previous) && previous.length > 0 && difference.length > 0) {
            if (difference.indexOf('all') > -1) {
                enforced = ['all'];
            } else {
                enforced = util.without(current, 'all');
            }

            this.set(
                'accountSelect',
                enforced,
                {
                    enforced: true,
                },
            );
        }
    },

    /*
     * Parent function for getParentGroupFilter and getParentAccountFilters,
     * returns data that is used in both functions
     */
    parentDataFilter(inquiryNum) {
        const data = {
            IncludeMapData: 1,

            queryCriteria: {
                inquiryId: inquiryNum,

                action: {
                    typeCode: 'GIRACCT',
                    entryMethod: 0,
                    productCode: 'GIR',
                    actionMode: 'SELECT',
                    functionCode: 'INST',
                },
            },

            requestHeader: {
                queryPagesize: 0,
                queryOffset: 0,
            },
        };
        return data;
    },

    /*
     * Parent function for getAccountGroupFilters and getLoanAccountGroupFilters.
     * Includes parameters for filter name and account name, as well as the inquiry number
     * which is passed into the parentDataFilter function
     */
    getParentGroupFilter(inquiryNum, setFilterName, setAccountName) {
        const data = this.parentDataFilter(inquiryNum);

        const accountList = [];
        const self = this;

        return http.post(services.inquiryQueryResults, data, (response) => {
            // this should be a list of accounts
            const filters = util.map(response.queryResponse.QueryData.queryRows, (row) => {
                util.each(row.mapDataList[0].value.split('^'), (account) => {
                    if (accountList.indexOf(account) < 0) {
                        if (accountList[row.name]) {
                            accountList[row.name].push(account);
                        } else {
                            accountList[row.name] = [account];
                        }
                        if (accountList.all) {
                            accountList.all.push(account);
                        } else {
                            accountList.all = [account];
                        }
                    }
                });
                return {
                    id: row.name,
                    text: row.label,
                };
            });

            filters.unshift({
                id: 'all',
                text: 'All Groups',
            });

            self.set(setFilterName, filters);
            self.set(setAccountName, accountList);
        });
    },

    getParentAccountFilters(inquiryNum, setFilterName) {
        const data = this.parentDataFilter(inquiryNum);

        const self = this;

        return http.post(services.inquiryQueryResults, data, (response) => {
            const filters = util.map(response.queryResponse.QueryData.queryRows, row => ({
                id: row.name,
                text: row.label,
            }));

            filters.unshift({
                id: 'all',
                text: locale.get('listviews.allaccounts'),
            });

            self.set(setFilterName, filters);
        });
    },

    getAccountGroupFilters() {
        this.getParentGroupFilter('22006', 'accountGroupFilterOptions', 'accountGroupAccountList');
    },

    getLoanAccountGroupFilters() {
        this.getParentGroupFilter('22030', 'loanAccountGroupFilterOptions', 'loanAccountGroupAccountList');
    },

    getAccountNumberFilters() {
        this.getParentAccountFilters('22015', 'accountNumberFilterOptions');
    },

    getLoanAccountNumberFilters() {
        this.getParentAccountFilters('22021', 'loanAccountNumberFilterOptions');
    },

    getTypeAheadFieldData() {
        this.fieldData = [{
            field: 'BAI_GROUP_CODE_DATA',
            inquiryId: 22012,
        }, {
            field: 'BAI_CODE_DATA',
            inquiryId: 22310,
        }, {
            field: 'SWIFT_TYPE_CODE_DATA',
            inquiryId: 22312,
        }, {
            field: 'BANK_CODE_DATA',
            inquiryId: 22007,
        }, {
            field: 'CURRENCY_CODE_DATA',
            inquiryId: 22009,
        },
        ];
        AccountFilterParent.prototype.getTypeAheadFieldData();
    },

    updateAccountOptionList() {
        let newList = [];
        if (this.get('accountSelectBy') === 'number') {
            if (this.get('accountType') === 'LOAN') {
                newList = util.clone(this.get('loanAccountNumberFilterOptions'));
            } else {
                newList = util.clone(this.get('accountNumberFilterOptions'));
            }
        } else if (this.get('accountType') === 'LOAN') {
            newList = util.clone(this.get('loanAccountGroupFilterOptions'));
        } else {
            newList = util.clone(this.get('accountGroupFilterOptions'));
        }

        this.set({
            accountList: newList,
        });
    },
});

export default TransSearchAccountFilter;
