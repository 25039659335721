import Layout from '@glu/core/src/layout';
import alert from '@glu/alerts';
import util from '@glu/core/src/util';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import Model from '@glu/core/src/model';
import store from 'system/utilities/cache';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import ContextApi from 'common/dynamicPages/api/context';
import ContextModel from 'common/dynamicPages/models/context';
import TemplateTypeCollection from 'app/payments/collections/paymentTypes';
import TemplateSubTypeCollection from 'app/payments/collections/paymentSubTypes';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import patterns from 'system/validatorPatterns';
import loadingModalTmpl from 'common/templates/loadingModal.hbs';
import addTemplateTmpl from './addTemplate.hbs';

const AddTemplateModel = Model.extend({
    initialize() {
        this.validators = {
            type: {
                description: locale.get('payment.template.type'),
                exists: true,
            },
            Template_Code: {
                description: locale.get('PAY.TemplateCode'),
                matches: patterns.TEMPLATECODE_PATTERN,
                overrideError: 'matchesAlphaNumeric',
            },
        };
    },
});

export default Layout.extend({
    template: addTemplateTmpl,
    loadingTemplate: loadingModalTmpl,
    modalClass: 'modal-md add-template-modal',

    ui: {
        $templateTypeSelect: '[data-hook="template-select"]',
        $templateSubTypeSelect: '[data-hook="template-sub-select"]',
        $templateSubTypeContainer: '#templateSubType',
        $templateInfoFields: '#templateInfo',
    },

    initialize() {
        this.model = new AddTemplateModel();
        this.isModal = this.options.isModal;
        this.templateTypes = new TemplateTypeCollection({
            functionCode: 'TMPL',
            inquiryId: 20011,
        }, {
            base: 'templates',
        });
        this.templateSubTypes = new TemplateSubTypeCollection();

        if (this.options.isModal === false) {
            this.contextDef = ContextApi.menuContext.getContext('TEMP_LIST_VIEW');
            this.contextModel = new ContextModel({
                menuCategory: 'PMTS',
                serviceName: 'templates/corp',
                serviceFunc: null,
                businessType: null,
                context: 'TEMP_LIST_VIEW',
                contextDef: this.contextDef,
            });
            this.contextKey = this.contextModel.getContextKey();
            this.localeKey = this.contextModel.getLocaleKey();
            store.set(`${this.contextKey}-listRoute`, 'PAYMENTS/addNewTemplate');
        }

        this.listenTo(this.model, 'change', this.handleModelChangeEvents);

        this.dialogTitle = locale.get('payment.template.add');

        this.dialogButtons = [{
            text: locale.get('payment.continue'),
            className: 'btn btn-primary',
            callback: 'continue',
        }, {
            text: locale.get('payment.cancel'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }];
    },

    onRender() {
        const self = this;

        if (this.hasLoadedRequiredData()) {
            if (this.options.isModal === false) {
                this.renderMessage(store.get(`${this.contextKey}-alertMessage`), store.get(`${this.contextKey}-confirms`));
                store.set(`${this.contextKey}-alertMessage`, null);
                store.set(`${this.contextKey}-confirms`, null);
            }
            this.ui.$templateSubTypeContainer.hide();
            this.ui.$templateInfoFields.hide();
            this.renderTemplateType();
        } else {
            this.templateTypes.fetch({
                success() {
                    self.setHasLoadedRequiredData(true);
                    self.render();
                },
            });
        }
    },

    handleModelChangeEvents(model) {
        let fieldValue = '';
        const attrs = model.changedAttributes();
        if (Object.prototype.hasOwnProperty.call(attrs, 'Template_Code')) {
            fieldValue = this.model.get('Template_Code');
            fieldValue = fieldValue.replace(/ /g, '');
            this.model.set('Template_Code', fieldValue);
        }
    },

    templateHelpers() {
        const self = this;

        return {
            templateTypes() {
                return self.templateTypes.toJSON();
            },

            templateSubTypes() {
                return self.templateSubTypes.toJSON();
            },

            notModal: !this.options.isModal,
            title: this.dialogTitle,
        };
    },

    renderMessage(action, confirmResponse, errorCount) {
        /*
         * create the confirmation view for the alert
         * skip if action is null.  This occurs when first entering the payment workspace
         * because the onRender is called.
         */
        if ((action === null || action === undefined)
            && (confirmResponse === null || confirmResponse === undefined)) {
            // TODO: Refactor to remove empty block
        } else if (confirmResponse === null || confirmResponse === undefined) {
            if (action.type === 'WARNING' && action.message) {
                this.alertView = alert.warning(action.message);
                this.alertRegion.show(this.alertView);
            }
        } else {
            const confirm = typeof confirmResponse === 'object' ? confirmResponse.confirms : null;
            const successCount = confirm !== null ? confirm.totalSuccess : confirmResponse;
            const failCount = confirm !== null ? confirm.totalFail : errorCount;
            const totalCount = failCount + successCount;
            const success = successCount === totalCount;
            const alertFunc = success ? alert.success : alert.danger;
            const chainedMessages = typeof confirmResponse === 'object'
                ? confirmResponse.message : null;
            const message = typeof confirmResponse === 'object'
                && confirmResponse.message.length > 0
                ? confirmResponse.message[chainedMessages.length - 1] : action;

            const confirms = new Confirms({
                confirms: confirmResponse ? confirmResponse.confirms : null,
            });

            // display notification message
            this.alertView = alertFunc(
                message,
                {
                    details: confirmResponse
                    && confirmResponse.confirms.confirmResults[0].confirmData[0].item
                        ? confirms : null,
                    canDismiss: !!confirmResponse,
                    animate: true,
                },
            );

            if (message) {
                this.alertRegion.show(this.alertView);
            }
        }
    },

    renderTemplateType() {
        const self = this;

        this.ui.$templateTypeSelect.comboBox();
        this.ui.$templateTypeSelect.on('change', (e) => {
            if (e.val) {
                const templateModel = self.templateTypes.get(e.val);
                const context = templateModel.get('context');

                if (e.val.indexOf('LOAN') > -1) {
                    self.ui.$templateInfoFields.show();
                } else {
                    self.ui.$templateInfoFields.hide();
                }

                if (context.serviceName.indexOf('/batchTemplate/') === 0) {
                    self.addSubType(templateModel);
                } else {
                    self.removeSubType();
                }
            }
        });
        this.selectIfOnlyOne();
    },

    /**
     * @description if there is only one template to select, the control will be
     * set to that template
     */
    selectIfOnlyOne() {
        const ifOne = (serverConfigParams.get('SelectIfOnlyOne') && serverConfigParams.get('SelectIfOnlyOne').toUpperCase() === 'TRUE');
        const collection = this.templateTypes.models;
        if (ifOne && (collection && collection.length === 1)) {
            this.ui.$templateTypeSelect.comboBox('val', this.ui.$templateTypeSelect.find('option').eq(1).attr('value'), true);
        }
    },

    addSubType(templateModel) {
        const self = this;

        this.ui.$templateSubTypeSelect.html('<option></option>');
        this.ui.$templateSubTypeSelect.comboBox({
            placeholder: locale.get('payment.loading'),
        });
        // self.ui.$templateSubTypeContainer.show();

        this.model.validators.subType = {
            description: locale.get('payment.template.subtype'),
            exists: true,
        };

        const success = () => {
            let html = '<option></option>';

            self.templateSubTypes.each((model) => {
                html += `<option value="${model.get('id')}" id="${model.get('id')}">${model.get('label')}</option>`;
            });

            self.ui.$templateSubTypeSelect.html(html);
            self.ui.$templateSubTypeSelect.comboBox({
                placeholder: locale.get('payment.subtype.select'),
            });

            // if there is only one option in addition to the blank one, select it by default
            if (self.ui.$templateSubTypeSelect.find('option').length === 2) {
                self.ui.$templateSubTypeSelect.comboBox('val', self.ui.$templateSubTypeSelect.find('option').eq(1).attr('id'), true);
                self.ui.$templateSubTypeContainer.hide();
            } else {
                /*
                 * show the subtype dropdown only when more than one option is available,
                 * excluding the empty option
                 */
                self.ui.$templateSubTypeContainer.show();
            }
        };

        if (this.templateSubTypes.type === templateModel.get('typeCode')) {
            success();
        } else {
            this.templateSubTypes.type = templateModel.get('typeCode');
            this.templateSubTypes.fetch({
                success,
            });
        }
    },

    removeSubType() {
        this.ui.$templateSubTypeContainer.hide();
        this.ui.$templateSubTypeSelect.html('<option></option>');
        this.model.validators.subType = null;
    },

    continue() {
        if (this.model.isValid()) {
            const context = this.templateTypes.get(this.model.get('type')).get('context');
            if (context.serviceName.indexOf('/batchTemplate/') === 0) {
                context.serviceNameTypeCode = this.templateSubTypes.type;
                context.subType = this.templateSubTypes.get(this.model.get('subType')).get('id');
            }
            context.enableSaveDraft = true;
            this.model.set('context', context);
            if (this.options.isModal) {
                dialog.close();
                this.trigger('selected', this.model);
            } else if (this.model.get('type').indexOf('LOANPAY') > -1 || this.model.get('type').indexOf('LOANDRAW') > -1) {
                store.set('basic_template_data', this.model.toJSON());
                this.navigateTo('TEMPLATE/addTemplate');
            } else {
                const overrideContext = util.extend(this.contextDef, this.model.get('context'));
                store.set(`${this.contextKey}-contextOverride`, overrideContext);
                this.navigateTo(this.contextModel.getInsertUrl());
            }
        }
    },

    cancel() {
        dialog.close();
    },
});
