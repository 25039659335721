import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import dialog from '@glu/dialog';

const PrintUtil = {
    print(options) {
        const printModal = new PrintViewModal({
            exportModel: this.buildModel(options),
            exportURL: options.dynamicReportURL,
            title: options.title,
            tnum: options.tnum,
            serviceCode: options.serviceCode,
            formMethod: 'post',
            viewId: options.viewId,
            isApprovalDisclosure: options.isApprovalDisclosure,
            approvalDisclosureModel: options.approvalDisclosureModel,
            approvalDisclosureGrid: options.approvalDisclosureGrid,
            approvalDisclosureModelsArray: options.approvalDisclosureModelsArray,
            updateCount: options.updateCount,
            approvalDisclosureResponse: options.approvalDisclosureResponse,
            disclosureActionMode: options.actionMode,
            asyncReport: options.asyncReport,
        });
        dialog.custom(printModal);
    },

    buildModel(options) {
        const dynRptPrintModal = {};
        dynRptPrintModal.outputFormat = options.outputFormat;
        dynRptPrintModal.pageType = options.pageType;
        dynRptPrintModal.tnum = options.tnum;
        dynRptPrintModal.additionalProperties = options.additionalProperties;
        dynRptPrintModal.serviceCode = options.serviceCode;
        dynRptPrintModal.viewId = options.viewId;
        return dynRptPrintModal;
    },
};

export default PrintUtil;
