import Collection from '@glu/core/src/collection';
import alert from '@glu/alerts';
import $ from 'jquery';
import QuestionsView from 'system/login/views/security/questions';
import scroll from 'common/util/scroll';
import workspaceHelper from 'common/workspaces/api/helper';
import questions from 'system/login/views/question/promises';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';

const SecurityQuestionsView = QuestionsView.extend({
    loadingTemplate: loadingPageTmpl,

    initialize() {
        this.collection = new Collection();
        this.loadRequiredData();
    },

    loadRequiredData() {
        const self = this;

        Promise.all([
            questions.questionOne(),
            questions.questionTwo(),
            questions.questionThree(),
            questions.questionFour(),
            questions.questionFive(),
        ]).then((results) => {
            self.collection = results;
            self.setHasLoadedRequiredData(true);
            self.render();
        });
    },

    showAlert(response, status) {
        const message = status ? alert.success(response) : alert.danger(response);
        this.alertRegion.show(message);

        $('html, body').animate({
            scrollTop: 0,
        }, 300);
    },

    save() {
        const self = this;

        if (this.model.isValid()) {
            this.model.save(
                {},
                {
                    success() {
                        self.model.clear();
                        self.showAlert('Success', true);
                    },

                    error() {
                        self.showAlert(self.model.error.message.join(' '), false);
                        scroll.scrollToFirstError();
                    },
                },
            );
        } else {
            scroll.scrollToFirstError();
        }
    },

    cancel() {
        this.model.clear();
        window.history.back();
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'QUESTIONS',
    view: SecurityQuestionsView,
    options: {},
});

export default SecurityQuestionsView;
