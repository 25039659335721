import React, { useState, useEffect } from 'react';
import { act } from 'react-dom/test-utils';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Snackbar } from '@glu/snackbar-react';
import { appBus, log } from '@glu/core';
import helpers from 'components/AsyncReporting/helpers';
import client from 'common/notificationClient/client';
import locale from '@glu/locale';
import transform from 'common/util/transform';
import { getLastPositionData } from 'common/util/deeplinkUtil';
import useStyles from './SnackbarNotification.styles';

const SnackbarNotification = ({ isDeepLink }) => {
    const [messages, setMessages] = useState([]);
    const [containerTopPosition, setContainerTopPosition] = useState(0);
    const classes = useStyles();

    /**
     * ==== START: Handling snackbar notifications in deeplink
     */

    useEffect(() => {
        /**
         * Support positioning the snackbar in an iframe
         * @param {object} data
         */
        const updatePositionFromIframe = (data) => {
            const containerHeight = document.getElementById('snackbar-container').offsetHeight;
            const containerTop = data.contentTop + (data.usableHeight - containerHeight);
            setContainerTopPosition(containerTop);
        };

        if (isDeepLink) {
            appBus.on('iframePosition', (data) => {
                if (data === undefined) {
                    log.error('iframePosition without data');
                    return;
                }
                updatePositionFromIframe(data);
            });
            const startingPosition = getLastPositionData();
            if (startingPosition) {
                updatePositionFromIframe(startingPosition);
            }
            return () => appBus.off('iframePosition', updatePositionFromIframe);
        }
        return () => false;
    }, [isDeepLink]);

    /**
     * ==== END: Handling snackbar notifications in deeplink
     */

    useEffect(() => {
        /**
         * @method formatMessages
         * @description Handles formatting the notification message
         * @param {[{ rowId: number, columns: Object[] }] } rows
         */
        const formatMessages = rows => rows.reduce((acc, {
            rowId,
            columns,
        }) => {
            const data = transform.pairsToHash(columns, 'fieldName', 'fieldValue');
            const message = {
                rowId,
                ...data,
            };
            acc.push(message);
            return acc;
        }, [...messages]);

        let didUnmount = false;
        client.listenTo(client, 'notification:asyncReports', (data) => {
            if (didUnmount) {
                return;
            }
            if (data) {
                const formattedMessages = formatMessages(data);
                act(() => {
                    setMessages(prevMessages => formattedMessages.concat(prevMessages));
                });
            }
        });
        return () => {
            didUnmount = true;
        };
    }, [messages]);

    /**
     * @method onMessageDismiss
     * @description Handles dismissing a notification message
     * @param {number} rowId
     */
    const onMessageDismiss = (rowId) => {
        setMessages(messages.filter(m => m.rowId !== rowId));
    };

    /**
     * @method addRequiredParametersToURL
     * @description Appends the parameter name value pairs that are needed for report retrieval
     * @param {string} URL
     * @param {string} OUTPUTCONTENT
     * @param {string} EXPORTID
     * @returns {string}
     */
    const addRequiredParametersToURL = (URL, OUTPUTCONTENT, EXPORTID) => {
        const [baseURL, params = ''] = URL.split('?');
        let pairs = {};
        if (params?.length > 0) {
            pairs = params.split('&')
                .reduce((acc, pair) => {
                    const [key, value = ''] = pair.split('=');
                    return {
                        ...acc,
                        [key]: value,
                    };
                }, {});
        }

        const keys = {
            type: 'jasper',
            disposition: 'export',
            format: OUTPUTCONTENT,
            exportId: EXPORTID,
            ...pairs,
        };
        const outputParams = Object.entries(keys)
            .map(entry => entry.join('='))
            .join('&');
        return `${baseURL}?${outputParams}`;
    };

    /**
     * @method onMessageClose
     * @description Handles closing the snackbar notification message and
     * downloading the report.
     * @param {number} rowId
     * @param {string} URL
     * @param {string} OUTPUTCONTENT
     * @param {string} EXPORTID
     */
    const onMessageClose = ({
        rowId,
        URL,
        OUTPUTCONTENT,
        EXPORTID,
    }) => {
        if (!URL) return;
        helpers.handleAsyncReport(
            { service: addRequiredParametersToURL(URL, OUTPUTCONTENT, EXPORTID) },
            () => onMessageDismiss(rowId),
            err => console.log(err), // eslint-disable-line no-console
        );
    };

    /**
     * @method getActions
     * @description Handles creating snackbar actions
     * @param {object} m
     * @returns {object}
     */
    const getActions = (m) => {
        const actions = [{
            label: locale.get('async.dismiss'),
            onClick: () => onMessageDismiss(m.rowId),
        }];
        if (m.URL) {
            actions.unshift({
                label: locale.get('async.download'),
                onClick: () => onMessageClose(m),
                shouldNotDismiss: undefined,
            });
        }
        return actions;
    };

    return (
        <TransitionGroup
            id="snackbar-container"
            className={isDeepLink ? `${classes.deepLink}` : classes.root}
            style={isDeepLink ? { top: containerTopPosition } : {}}
        >
            {messages.map(m => (
                <CSSTransition
                    key={m.rowId}
                    timeout={300}
                    classNames={{
                        enter: classes.enter,
                        enterActive: classes.enterActive,
                        exit: classes.exit,
                        exitActive: classes.exitActive,
                    }}
                >
                    <Snackbar
                        id={m.rowId}
                        message={m.MESSAGE || m.MESSAGE_STATUS}
                        actions={getActions(m)}
                    />
                </CSSTransition>
            ))}
        </TransitionGroup>
    );
};

SnackbarNotification.propTypes = {
    isDeepLink: PropTypes.bool,
};

SnackbarNotification.defaultProps = {
    isDeepLink: false,
};

export default SnackbarNotification;
