import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import useLocale from 'hooks/useLocale';
import ContentEditorContext from './ContentEditorContext';
import Pill from './Pill';
import TooltipText from './TooltipText';
import useStyles from './DraggableArea.styles';

const DraggableArea = ({ pillContainers }) => {
    const { getLocaleString } = useLocale();
    const { isPreview, isShown } = useContext(ContentEditorContext);
    const classes = useStyles();
    const getTooltipText = (pill, showSampleHtml) => (
        <TooltipText
            pill={pill}
            showSampleHtml={showSampleHtml}
        />
    );

    return (
        <div className={`${classes.root} ${(isPreview || !isShown) && classes.hide}`}>
            <h5 className={classes.title}>{getLocaleString('CMAINT.Parameters')}</h5>
            <p className={classes.help}>{getLocaleString('CMAINT.Parameters.instructions')}</p>
            <div className={classes.box}>
                {pillContainers.map(container => (
                    <Fragment key={`pillcontainer-${container.name}`}>
                        <p className={classes.containerTitle}>{container.label}</p>
                        <div className={classes.pillContainer}>
                            {container.pills.map(pill => (
                                <Pill
                                    key={`pill-${container.name}-${pill.name}`}
                                    name={pill.name}
                                    label={pill.label}
                                    tooltipText={getTooltipText(pill, container.tooltipSample)}
                                    dark={container.name === 'BLOCKS'}
                                />
                            ))}
                        </div>
                    </Fragment>
                ))}
            </div>
        </div>
    );
};

DraggableArea.propTypes = {
    pillContainers: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        pills: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired).isRequired,
    }).isRequired).isRequired,
};

export default DraggableArea;
