import Layout from '@glu/core/src/layout';
import ContextApi from 'common/dynamicPages/api/context';
import GridApi from 'common/dynamicPages/api/grid';
import Constants from 'app/balanceAndTransaction/constants';
import dialog from '@glu/dialog';
import $ from 'jquery';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import Model from '@glu/core/src/model';
import services from 'services';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import ListView from 'common/dynamicPages/views/workflow/list';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import ExportWireReportListModal from 'app/balanceAndTransaction/views/wireReport/exportWireReportListView';
import workspaceHelper from 'common/workspaces/api/helper';
import filterApi from 'common/dynamicPages/api/filters';
import PrintExportUtil from 'common/util/printExportUtil';
import PrintTransView from 'app/balanceAndTransaction/views/printTransView';
import PrintTransactionAdviceDetails from 'app/balanceAndTransaction/views/depositAccounts/printTransactionAdviceDetails';
import AdditonalInfoCellView from 'app/balanceAndTransaction/views/additionalInfoCellView';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import browser from '@glu/core/src/browser';
import ShortenedCell from 'common/dynamicPages/views/gridCells/shortenedCell';
import userInfo from 'etc/userInfo';
import gridUtil from 'app/utilities/views/gridUtil';
import helpers from 'components/AsyncReporting/helpers';
import systemConfig from 'system/configuration';
import template from './wireReportListView.hbs';

const WireReportView = Layout.extend({
    template,

    initialize() {
        this.setNotificationData({
            title: 'wireReportSummary',
        });

        // set flag for if Credit Advice Report setting is on
        this.creditReportEnabled = serverConfigParams.get('CREDITADVICEREPORTENABLED') === 'true';
        /**
         * on MSIE and old edge, we must do a HTTP POST so that a long list
         * of arguments (selecting 200 items to print) will be passed to the
         * server.  On others, we must to a HTTP GET so that we can save the
         * PFD from the viewer. (new edge uses Edg not Edge in UA string)
         */
        const isMsIeEdge = browser.msie || /Edge/.test(navigator.userAgent);
        this.printHttpMethod = (isMsIeEdge) ? 'post' : 'get';
        this.printUrl = (isMsIeEdge) ? '/export/listViewPost' : this.getPrintUrl();
        this.context = ContextApi.menuContext.getContext(Constants.WIRE_REPORT_GRID_CONTEXT);
        this.context.serviceName = Constants.WIRE_REPORT_TRANSACTIONS_SERVICE;
        this.isAdmin = systemConfig.isAdmin();
        ListView.prototype.setListViewConfig.call(this, this.context);
    },

    onRender() {
        this.loadViewRequirements();
        this.notifyPageLoaded();
    },

    events: {
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
        'click [data-hook="refresh-button"]': 'refreshData',
    },

    /**
     * @method getPrintUrl
     * @description Helper to return the correct service URL required
     * to print report
     * @returns {string} service url
     */
    getPrintUrl() {
        if (this.isAdmin) {
            return '/export/listView';
        }
        return services.runAsyncListViewReport;
    },

    templateHelpers() {
        return {
            getButtonString(messageCode) {
                return locale.get(messageCode);
            },
        };
    },

    loadViewRequirements() {
        const options = {
            context: this.context,
            hideGridActionButtons: true,
            enableSavedViews: true,
            enableRowContextButtonCallbacks: true,

            cellViews: {
                ADDITIONAL_INFO: AdditonalInfoCellView,
                PAYMENT_DETAIL: ShortenedCell,
                PAYMENT_DETAIL_ORIG: ShortenedCell,
            },

            dateFormat: userInfo.getDateFormat(),
            lvc: this.lvc,
            gridOptions: { caption: this?.context?.name },
        };
        this.gridView = GridApi.createServiceGridView(options);
        if (this.wireReportTransactionsGrid) {
            this.wireReportTransactionsGrid.show(this.gridView);
        }

        const nonSortableColumns = Constants.TRANSACTIONS_GRID_NON_SORTABLE_COLUMNS;
        this.listenTo(this.gridView.getRows(), 'sync', ListView.prototype.updateRefreshTimestamp);
        this.listenTo(this.gridView, 'gridapi:loaded', gridUtil.disableColumnSorting.bind(this, nonSortableColumns, this.gridView));
        this.appBus.on(`gridapi:showMessages${this.gridView.cid}`, gridUtil.disableColumnSorting.bind(this, nonSortableColumns, this.gridView));
        ListView.prototype.listenForGridErrors.call(this);
        ListView.prototype.setupGridAvailableListener.call(this);
    },

    refreshData() {
        ListView.prototype.refreshData.call(this); // call listView refreshData
    },

    /**
     * @method setupExportWireReportModal
     * @param {string} mode
     * Instantiate the report modal needed for export or print
     */
    setupExportWireReportModal(mode) {
        const rows = this.gridView.grid.getSelectedRowModels();
        const titleLocale = (mode === 'export') ? 'common.wire.export.transactions' : 'common.wire.print.transactions';

        this.exportWireReportListModal = new ExportWireReportListModal({
            mode,
            enableSel: (rows.length > 0),
            enableAll: (rows.length === 0),
            title: locale.get(titleLocale),
        });

        if (mode === 'export') {
            this.listenTo(this.exportWireReportListModal, 'exportWireTrans', this.exportTrans);
        } else {
            this.listenTo(this.exportWireReportListModal, 'printWireTrans', this.printTrans);
        }

        dialog.open(this.exportWireReportListModal);
    },

    export() {
        this.setupExportWireReportModal('export');
    },

    exportTrans(dialogModel) {
        this.buildExportModel('CSV', dialogModel);
        this.doExport();
    },

    print() {
        this.setupExportWireReportModal('print');
    },

    printTrans(dialogModel) {
        // if credit report setting is enabled
        if (this.creditReportEnabled) {
            const options = {
                view: this,
                gridView: this.gridView,
                exportURL: '/export/listView',
                isCreditAdviceReport: true,
                format: 'PDF',
            };
            if (this.gridView.context.typeCode === 'LOANTRAN') {
                PrintExportUtil.print(options);
            } else {
                this.PrintTransView = new PrintTransView(options);
                this.listenTo(
                    this.PrintTransView, 'printTransaction',
                    () => { this.printTransaction(options, dialogModel); },
                );
                dialog.open(this.PrintTransView);
            }
        } else {
            // Original condition for wire reports
            this.buildExportModel('PDF', dialogModel);
            const printModal = new PrintViewModal({
                exportModel: this.exportModel,
                exportURL: this.printUrl,
                formMethod: this.printHttpMethod,
                viewId: 'wireReport',

            });
            dialog.custom(printModal);
        }
    },

    printTransaction(options, dialogModel) {
        if (this.PrintTransView.model.get('includeDetails')) {
            /*
             * These should match the gridView, and will definitely need to be changed
             * once the service is ready
             */
            const reportModel = new Model({
                productCode: 'GIR',
                reportId: '30006',
                filterData: this.gatherFilters(),
            });
            const selectedRows = this.gridView.grid.getSelectedRows();

            let messageIDs = [];

            if (selectedRows.length > 0) {
                messageIDs = this.getSelectedRowMessageIDs(selectedRows);
            } else {
                messageIDs = this.gridView.grid.collection.pluck('MESSAGE_ID');
            }

            const additionalParameters = [{
                name: 'MESSAGE_ID',
                value: messageIDs.join(),
            }];

            reportModel.set('additionalParameters', additionalParameters);

            dialog.open(new PrintTransactionAdviceDetails({
                model: reportModel,
            }));
        } else {
            this.buildExportModel('PDF', dialogModel);
            const printModal = new PrintViewModal({
                exportModel: this.exportModel,
                exportURL: this.printUrl,
                formMethod: this.printHttpMethod,
                viewId: 'wireReport',
            });
            dialog.custom(printModal);
        }
    },

    gatherFilters() {
        const searchFields = this.gridView.wrapper.generateFiltersDataBlock() || [];

        return searchFields.map(searchField => [
            searchField.fieldName,
            searchField.fieldName,
            filterApi.getFilterType(searchField.dataType),
            searchField.operator,
            this.processValue(searchField.fieldValue, searchField.operator),
        ].join('^'));
    },

    processValue(value, operator) {
        if (Array.isArray(value)) {
            if (operator === 'BETWEEN' && value.length > 1) {
                return value.join('^');
            }
            return value;
        }
        return [value];
    },

    /**
     * @method getSelectedRowMessageIDs
     * @param {array} selectedRows
     * Return an array containing the Message IDS of selected rows passed in
     */
    getSelectedRowMessageIDs(selectedRows) {
        return util.map(selectedRows, row => this.gridView.grid.collection.get(row).get('MESSAGE_ID'));
    },

    fixExportColumns(columns) {
        const newColumns = util.filter(columns, column => (column !== 'ADDITIONAL_INFO'));
        return newColumns;
    },

    buildExportModel(format, dialogModel) {
        this.exportModel = {
            outputFormat: format,
            pageType: 'LETTER',
            numberOfRows: 0,
            viewId: this.gridView.wrapper.viewId,

            actionData: {
                productCode: this.gridView.context.productCode,
                functionCode: this.gridView.context.functionCode,
                typeCode: this.gridView.context.typeCode,
            },
        };

        // if credit report setting is enabled
        if (this.creditReportEnabled) {
            this.exportModel.inquiryId = 22472;
        }
        // Check for Sort by Column
        if (this.gridView.wrapper.sortKey !== null) {
            this.exportModel.sortFields = [{
                fieldName: this.gridView.wrapper.sortKey,
                sortOrder: this.gridView.wrapper.sortOrder,
            }];
        }

        // search fields for query
        const searchFields = [];

        // check for selected rows
        const selectedRows = this.gridView.grid.getSelectedRows();
        if (selectedRows.length > 0 && dialogModel && dialogModel.get('selection') !== 'all') {
            // accumulate selectionID's of selected grid rows
            const selectedIds = this.getSelectedRowMessageIDs(selectedRows);
            searchFields[searchFields.length] = {
                fieldName: 'MESSAGE_ID',
                operator: 'IN',
                fieldValue: selectedIds,
                dataType: 'number',
            };
        }

        // Check for Filtering
        const { filters } = this.gridView.wrapper;
        if (filters && filters.length > 0) {
            filters.each((filter) => {
                const filterType = filter.get('type');
                if (filterType === 'date' || filterType === 'amount' || filterType === 'number') {
                    let value = filter.get('value');
                    value = util.isArray(value) ? value : [value];

                    searchFields[searchFields.length] = {
                        fieldName: filter.get('field'),
                        operator: filter.get('equality'),
                        fieldValue: value,
                        dataType: filterType,
                    };
                } else {
                    searchFields[searchFields.length] = {
                        fieldName: filter.get('field'),
                        operator: 'CONTAINS',
                        fieldValue: [filter.get('value')],
                        dataType: 'text',
                    };
                }
            });
        }

        if (searchFields.length > 0) {
            this.exportModel.searchFields = searchFields;
        }

        // Get Columns and Order
        let columns = [];
        this.gridView.wrapper.columns.each((model) => {
            if (model.get('condition') !== false) {
                columns.push(model.get('field'));
            }
        });
        columns = this.fixExportColumns(columns);
        this.exportModel.columns = columns;
    },

    doExport() {
        if (!this.isAdmin) {
            helpers.handleExportAsyncReport({
                service: services.runAsyncListViewReport,
                postData: this.exportModel,
            }, dialog);
            return;
        }
        if ($('#listViewExportForm').length === 0) {
            let f = '<form id="listViewExportForm" ';
            f += `action="${services.generateUrl(this.printUrl)}" `;
            f += `method="${this.printHttpMethod}" target="_self">`;
            f += '<input id="listViewRequest" type="hidden" name="listViewRequest" ></form>';
            this.$el.append(f);
        }
        $('#listViewExportForm #listViewRequest').val(JSON.stringify(this.exportModel));
        $('#listViewExportForm').submit();
    },
});

let list = WireReportView;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list);
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'WIREREPORT',
    view: list,
    options: {},
});

const exportedList = list;

export default exportedList;
