var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"form-group\">\n                        <h4>"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"payment") : depth0)) != null ? lookupProperty(stack1,"duplicateSummaryText") : stack1), depth0))
    + "</h4>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"isApprove")),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":12,"column":27}}})) != null ? stack1 : "")
    + "                        <p>"
    + alias1(lookupProperty(helpers,"locale").call(alias2,"PAY.SMBDuplicateListTitle",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":27},"end":{"line":13,"column":65}}}))
    + "</p>\n                        <div class=\"noManage duplicates-grid-instance\"></div>\n                    <br>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias2,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"isApprove")),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":24,"column":31}}})) != null ? stack1 : "")
    + "                </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <h5>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"payment") : depth0)) != null ? lookupProperty(stack1,"duplicateErrorMessage") : stack1), depth0))
    + "</h5>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"col-6\">\n                            <div class=\"form-group required\">\n                                    <label for=\"DUPLICATEREASON\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"PAY.DUPLICATEREASON",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":65},"end":{"line":19,"column":97}}}))
    + "</label>\n                                    <input data-bind=\"model\" id=\"DUPLICATEREASON\" name=\"DUPLICATEREASON\" type=\"text\" class=\"form-control duplicateReasonInput\" maxlength=\"255\">\n                                <span class=\"help-block duplicateReasonHelpText\" data-bind=\"model\" data-validate=\"DUPLICATEREASON\"></span>\n                            </div>\n                        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog glu-dialog dialog-warning\">\n    <div class=\"modal-content\">\n        <div class=\"modal-header\">\n            <h2 class=\"modal-title\"><span class=\"icon-info-solid\"></span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.PossibleDuplicatePayment",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":73},"end":{"line":4,"column":114}}}))
    + "</h2>\n        </div>\n        <div class=\"modal-body\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"contexts") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":26,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n            <div class=\"modal-footer\">\n                <button type=\"submit\" class=\"btn btn-primary\" data-action=\"save\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.continue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":81},"end":{"line":29,"column":109}}}))
    + "</button>\n                <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"cancel\" data-dismiss=\"modal\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":30,"column":105},"end":{"line":30,"column":131}}}))
    + "</button>\n            </div>\n    </div>\n</div>\n";
},"useData":true});