var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "     <div>\n         <div>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.template",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":14},"end":{"line":4,"column":43}}}))
    + ": <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"templateName") || (depth0 != null ? lookupProperty(depth0,"templateName") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"templateName","hash":{},"data":data,"loc":{"start":{"line":4,"column":51},"end":{"line":4,"column":67}}}) : helper)))
    + "</span></div>\n         <div class=\"field-container-xs\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":41},"end":{"line":5,"column":66}}}))
    + ": <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"valueDate") || (depth0 != null ? lookupProperty(depth0,"valueDate") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"valueDate","hash":{},"data":data,"loc":{"start":{"line":5,"column":74},"end":{"line":5,"column":87}}}) : helper)))
    + "</span></div>\n         <div class=\"field-container-xs\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":41},"end":{"line":6,"column":68}}}))
    + ": <span>"
    + alias2(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"amount") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":6,"column":76},"end":{"line":6,"column":93}}}))
    + "</span></div>\n         <div>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.error",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":14},"end":{"line":7,"column":40}}}))
    + ": <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"errors") || (depth0 != null ? lookupProperty(depth0,"errors") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"errors","hash":{},"data":data,"loc":{"start":{"line":7,"column":48},"end":{"line":7,"column":58}}}) : helper)))
    + "</span></div>\n     </div>\n\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"cmb-alert-details form-container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"errors") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":10,"column":13}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});