import util from '@glu/core/src/util';
import alert from '@glu/alerts';
import locale from '@glu/locale';
import $ from 'jquery';
import Layout from '@glu/core/src/layout';

const FilterParent = Layout.extend({
    updateAccountSummary(unit, selected) {
        let count = 'All';
        let labelText = '';

        if (util.isArray(selected) && selected.indexOf('all') === -1) {
            count = selected.length;
            if (count > 1) {
                labelText = `${count} ${locale.get(`common.${unit}s`)}`;
            } else {
                labelText = `${count} ${locale.get(`common.${unit}`)}`;
            }
        } else {
            labelText = locale.get(`common.${count}${unit}s`);
        }
        return labelText;
    },

    getTypeAheadFields(modelField, modelList) {
        const self = this;
        const typeAheadFields = [];

        if (self.model.get(modelField)) {
            util.each(self.model.get(modelField), (value) => {
                const pair = util.findWhere(
                    self.model.get(modelList),
                    {
                        id: value.trim(),
                    },
                );
                if (pair) {
                    if (modelField === 'BANK_CODE' || modelField === 'CURRENCY_CODE') {
                        typeAheadFields.push({
                            id: pair.id,
                            text: `${pair.id} - ${pair.text}`,
                        });
                    } else {
                        typeAheadFields.push({
                            id: pair.id,
                            text: pair.text,
                        });
                    }
                } else {
                    /*
                     * TODO: Temporary placeholder code that will display database interpreted
                     * values on multi select combos in BAI custom reporting filter for saved
                     * profiles. Need to figure out how to trigger typeahead display
                     * for saved views for multiselect combos
                     */
                    typeAheadFields.push({
                        id: value.trim(),
                        text: value.trim(),
                    });
                }
            });
        }
        return typeAheadFields;
    },

    postSubmitOperations(model) {
        this.updateAccountSummary();

        if (model.get('saved')) {
            model.unset('saved');
        }
        if (model.get('selectionChange')) {
            model.unset('selectionChange');
        }
    },

    checkDateRange() {
        if (util.isEmpty(this.ui.$datepicker.val())) {
            this.alertRegion.show(alert.negative(
                locale.get('common.filter.date.error'),
                {
                    canDismiss: true,
                },
            ));

            const regionLoc = this.$('.alert-region').offset();
            window.scrollTo(regionLoc.left, regionLoc.top);
            return false;
        }
        return true;
    },

    updateFilters() {
        if (this.alertRegion) {
            this.alertRegion.close();
        }
    },

    getTypeAheadData(view, reportTypeField) {
        const selector = '.type-ahead';
        const typeAheadFields = {};
        let comboData;

        // collect the data on each typeAhead field
        view.$el.find(selector).each(function () {
            let attrName = $(this).attr('name');
            comboData = $(this).comboBox('data');

            util.each(comboData, (valueParam) => {
                const value = valueParam;
                // TODO: Can we return a different object, or is this mutation needed?
                delete value.mapDataList;
            });

            // convert SWIFT_CODE to SWIFT_TYPE_CODE for the modal
            attrName = (attrName === 'SWIFT_CODE') ? 'SWIFT_TYPE_CODE' : attrName;
            typeAheadFields[attrName] = comboData;
        });

        comboData = this.$('#accountSelect').comboBox('data');

        if (comboData[0].id !== 'all') {
            typeAheadFields[reportTypeField] = comboData;
        }

        return typeAheadFields;
    },
});

export default FilterParent;
