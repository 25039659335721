import Layout from '@glu/core/src/layout';
import $ from 'jquery';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import userInfo from 'etc/userInfo';
import GridApi from 'common/dynamicPages/api/grid';
import Constants from 'app/administration/constants';
import dialog from '@glu/dialog';
import entitlements from 'common/dynamicPages/api/entitlements';
import PrintExportUtil from 'common/util/printExportUtil';
import InquiryRows from 'app/loans/views/bankSummary/inquiryRows';
import Formatter from 'system/utilities/format';
import ImportView from 'app/administration/views/fileImportHistory/importView/importView';
import ListView from 'common/dynamicPages/views/workflow/list';
import template from './successfulImportsListView.hbs';

export default Layout.extend({
    template,

    events: {
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
    },

    ui: {
        $totalIssues: '[data-hook="totalIssues"]',
        $totalVoids: '[data-hook="totalVoids"]',
        $totalStops: '[data-hook="totalStops"]',
        $totalCancels: '[data-hook="totalCancels"]',
        $totalItems: '[data-hook="totalItems"]',
        $amountIssues: '[data-hook="amountIssues"]',
        $amountVoids: '[data-hook="amountVoids"]',
        $amountStops: '[data-hook="amountStops"]',
        $amountCancels: '[data-hook="amountCancels"]',
        $amountTotal: '[data-hook="amountTotal"]',
        $companyId: '[data-hook="companyId"]',
        $companyName: '[data-hook="companyName"]',
        $fileName: '[data-hook="fileName"]',
        $importDate: '[data-hook="importDate"]',
        $importMapName: '[data-hook="importMapName"]',
    },

    initialize() {
        const productCode = store.get('productCode');
        const functionCode = store.get('functionCode');
        const typeCode = store.get('typeCode');
        /*
         * NH-31307 NH-160125 - Check Management FIH, RTGS FIH, USACH FIH,
         * RTP FIH, and Bene Address Book FIH not working on print or export from admin side;
         * must override with ADMPAY/HISTORY/IMPORT;
         * see failedRecordsListView.js
         */
        const switchCtxtCm = (productCode === 'CM' && functionCode === 'INST' && (typeCode === 'CIMIMPRT' || typeCode === 'STCAIMPT'));
        const switchCtxtRtgsUsach = ((productCode === 'RTGS' || productCode === 'USACH') && (functionCode !== 'MAINT' && functionCode !== 'PRO' && functionCode !== 'RPT'));
        const switchCtxtRtp = (productCode === 'RTP' && (functionCode !== 'MAINT' && functionCode !== 'RPT'));
        const switchBeneAddressBook = (productCode === '_ADMIN' && functionCode === 'MAINT' && typeCode === 'BENEADBK');

        let context;

        if (switchCtxtCm || switchCtxtRtgsUsach || switchCtxtRtp || switchBeneAddressBook) {
            context = {
                productCode: 'ADMPAY',
                functionCode: 'HISTORY',
                typeCode: 'IMPORT',
            };
        } else {
            context = {
                productCode,
                functionCode,
                typeCode,
            };
        }

        this.importFunctionCode = functionCode;
        this.productCode = context.productCode;
        this.functionCode = context.functionCode;
        this.typeCode = context.typeCode;
        this.importCode = store.get('importCode');
        ListView.prototype.setListViewConfig.call(this, context);
    },

    onRender() {
        const self = this;

        if (!util.isEmpty(this.productCode)
            && !util.isEmpty(this.functionCode)
            && !util.isEmpty(this.typeCode)) {
            const options = {
                context: [{
                    name: 'productCode',
                    value: this.productCode,
                }, {
                    name: 'functionCode',
                    value: this.functionCode,
                }, {
                    name: 'typeCode',
                    value: this.typeCode,
                }],

                entryMethod: this.getEntryMethod(),

                overrideContext: {
                    serviceName: '/adminFileImportHistory/listView/importHistory',
                },
            };

            const entitlementPromise = entitlements.getEntitlementsForContext(options);

            entitlementPromise.then((result) => {
                if (!(result.actions.SELECT === true)) {
                    const message = Constants.SPACE + locale.get('fih.NoPermissions') + Constants.SPACE + locale.get(`Confirm.Multi.${self.getAppResKey()}`);
                    $('.alert-warning').html(message);
                    $('.alert-warning').removeClass('hide').addClass('icon-warning').addClass('show');
                }
            });
        }
        if (this.importCode === 'CM') {
            this.loadAddlFields();
        }
        this.loadGrid();
    },

    gridLoadComplete() {
        $('#successCount').html(this.gridView.wrapper.rows.totalCount);
        if (this.gridView.wrapper.rows.totalCount !== 0) {
            // Hidden initially to avoid flickers
            $('#successfulImportsPanel').show();
        }
        store.set('successfulImportsGridLoaded', true);
        this.options.parent.trigger('bothGridsLoaded');
    },

    templateHelpers() {
        return {
            successfulListViewItemized: (this.importCode === 'CM' && this.typeCode !== 'STCAIMPT'),
        };
    },

    getAppResKey() {
        if (this.typeCode === 'BENEADBK') {
            return this.typeCode;
        }
        return this.functionCode;
    },

    getEntryMethod() {
        if (this.typeCode === 'BENEADBK') {
            return '0';
        }
        return '3';
    },

    getInquiryId() {
        const { typeCode } = this;
        const { importCode } = this;
        const { importFunctionCode } = this;
        let inquiryId;
        if (importFunctionCode === 'INST' || importFunctionCode === 'BATCH') {
            inquiryId = 17261;
        } else if (importFunctionCode === 'TMPL' || importFunctionCode === 'BHTMPL') {
            inquiryId = 17262;
        } else if (typeCode === 'BENEADBK' || importCode === 'BAB') {
            inquiryId = 17263;
        } else if (typeCode === 'CIMBTCHC' || importCode === 'CM') {
            inquiryId = 17264;
        } else {
            // this else block is temporary and will be removed later
            inquiryId = 17261;
        }

        return inquiryId;
    },

    loadGrid() {
        this.inquiryId = this.getInquiryId();

        const data = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    action: {
                        typeCode: this.typeCode,
                        productCode: this.productCode,
                        functionCode: this.functionCode,
                        actionMode: 'SELECT',
                    },

                    searchType: '5',
                    inquiryId: this.inquiryId,
                },
            },
        };

        this.customFilters = [{
            filterName: 'Depends',
            filterParam: ['IMPORTID', store.get('fileImportId')],
        }];

        data.inquiryRequest.searchCriteria.customFilters = this.customFilters;

        const contextLocal = {
            actionMode: 'SELECT',
            displayOrder: 1,
            functionCode: this.functionCode,
            inquiryId: this.inquiryId,
            gridId: 0,
            nonDashboardDisplay: 0,
            productCode: this.productCode,
            requestParams: `?!_init=true&_productCode=ADMPAY&_functionCode=HISTORY&_typeCode=IMPORT&_mode=SELECT&_gridId=${this.inquiryId}`,
            typeCode: this.typeCode,
        };

        const options = {
            context: contextLocal,
            filter: false,
            selector: 'none',
            hideButtons: true,

            // default grid action buttons are hidden
            hideGridActionButtons: true,

            enableRowContextButtonCallbacks: true,
            inquirySearchCriteria: data.inquiryRequest.searchCriteria,
            lvc: this.lvc,
        };

        this.gridView = GridApi.createInquiryGridView(options);

        this.listenTo(this.gridView, 'gridapi:loaded', this.gridLoadComplete);
        this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
        ListView.prototype.setupGridAvailableListener.call(this);

        if (this.tableContent) {
            this.tableContent.show(this.gridView);
        }
    },

    loadAddlFields() {
        const self = this;
        const data = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    action: {
                        typeCode: this.typeCode,
                        productCode: this.productCode,
                        functionCode: this.functionCode,
                        actionMode: 'SELECT',
                    },

                    searchType: '5',
                    inquiryId: '17265',
                },
            },
        };

        this.customFilters = [{
            filterName: 'Depends',
            filterParam: ['IMPORTID', store.get('fileImportId')],
        }];

        data.inquiryRequest.searchCriteria.customFilters = this.customFilters;
        const rowsData = this.getRows(data);
        return rowsData.then((results) => {
            self.createCIMImportSummary(results);
        });
    },

    createCIMImportSummary(rows) {
        /*
         * row count can be either 0 or 1. So initialization of values are needed.
         * But inquiry would return only one model from the server side.
         */
        const totals = {
            totalIssues: 0,
            totalVoids: 0,
            totalStops: 0,
            totalCancels: 0,
            totalItems: rows.length,
            amountIssues: 0,
            amountVoids: 0,
            amountStops: 0,
            amountCancels: 0,
            amountTotal: 0,
        };
        util.each(rows.models, (model) => {
            totals.totalIssues = model.get('TotalIssues');
            totals.totalVoids = model.get('TotalVoids');
            totals.totalStops = model.get('TOTALSTOPS');
            totals.totalCancels = model.get('TOTALCANCELS');
            totals.totalItems = model.get('TotalRecordCount');
            totals.amountIssues = model.get('TotalIssueAmount');
            totals.amountVoids = model.get('TOTALVOIDAMOUNT');
            totals.amountStops = model.get('TOTALSTOPAMOUNT');
            totals.amountCancels = model.get('TOTALCANCELAMOUNT');
            totals.amountTotal = model.get('TOTALITEMAMOUNT');
            totals.companyId = model.get('USERGROUP');
            totals.companyName = model.get('USERGROUPNAME');
            totals.fileName = model.get('FILENAME');
            totals.importDate = model.get('ENTERED_TIMESTAMP');
            totals.importMapName = model.get('IMPORTMAPNAME');
        });
        this.ui.$totalIssues.text(totals.totalIssues);
        this.ui.$totalVoids.text(totals.totalVoids);
        this.ui.$totalStops.text(totals.totalStops);
        this.ui.$totalCancels.text(totals.totalCancels);
        this.ui.$totalItems.text(totals.totalItems);
        this.ui.$amountIssues.text(Formatter.formatNumber(totals.amountIssues));
        this.ui.$amountVoids.text(Formatter.formatNumber(totals.amountVoids));
        this.ui.$amountStops.text(Formatter.formatNumber(totals.amountStops));
        this.ui.$amountCancels.text(Formatter.formatNumber(totals.amountCancels));
        this.ui.$amountTotal.text(Formatter.formatNumber(totals.amountTotal));
        this.ui.$companyId.text(totals.companyId);
        this.ui.$companyName.text(totals.companyName);
        this.ui.$fileName.text(totals.fileName);
        this.ui.$importDate.text(
            Formatter.formatDateTime(totals.importDate),
            userInfo.getDateTimeFormat(),
        );
        this.ui.$importMapName.text(totals.importMapName);
    },

    getRows(data) {
        return new Promise((resolve, reject) => {
            const rowsData = new InquiryRows(data);
            rowsData.fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    gridRowAction(options) {
        if (options.action.toUpperCase() === 'SELECT') {
            this.importView = new ImportView(options);
            dialog.open(this.importView);
        } else if (options.action.toUpperCase() === 'VIEWSUMT') {
            // TBD
        }
        return Promise.resolve();
    },

    print() {
        let title = 'fih.PaymentImportsGrid';

        if (this.typeCode === 'BENEADBK') {
            title = 'fih.BABImportsGrid';
        } else if (this.typeCode === 'CIMBTCHC') {
            title = 'fih.CIMImportsGrid';
        }

        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            inquiryId: this.inquiryId,
            title,
            format: 'PDF',
        };

        PrintExportUtil.print(options);
    },

    export() {
        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            inquiryId: this.inquiryId,
            format: 'CSV',
        };

        PrintExportUtil.export(options);
    },
});
