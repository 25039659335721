import { createUseStyles } from '@glu/theming';

const styles = ({ typography }) => ({
    body: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:not(:last-child)': {
            marginRight: 40,
        },
        '& > div': {
            marginRight: 15,
            whiteSpace: 'nowrap',
        },
    },
    noAmount: {
        maxWidth: 200,
    },
    label: {
        fontWeight: typography.fontWeightLight,
        fontSize: 13,
        marginBottom: 2,
    },
    value: {
        fontWeight: typography.fontWeightMedium,
        fontSize: 16,
        marginBottom: 5,
    },
});

export default createUseStyles(styles);
