import Collection from '@glu/core/src/collection';
import $ from 'jquery';
import util from '@glu/core/src/util';
import { appBus } from '@glu/core';
import locale from '@glu/locale';
import Formatter from 'system/utilities/format';
import Dialog from '@glu/dialog';
import InquiryQueryResults from 'app/smbPayments/collections/loanInquiryQuery';
import InquiryApi from 'system/webseries/api/inquiry';
import DataAPI from 'common/dynamicPages/api/data';
import paymentOptionTmpl from 'app/loans/views/paymentOption.hbs';
import loans from 'app/loans/api/common';
import services from 'services';
import requestServices from 'common/util/services';
import TransferLayout from 'app/smbPayments/views/accounts/balances/transfer/transferLayout';
import constants from 'app/smbPayments/constants';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import duplicatePaymentUtil from 'app/smbPayments/util/duplicatePaymentUtil';
import scroll from 'common/util/scroll';
import validatorPatterns from 'system/validatorPatterns';
import { modalClose } from 'common/modals/mobileGridModalUtil';
import template from './loanPaymentLayout.hbs';

const validators = {
    AMOUNT_0: {
        description: locale.get('SMBPAY.Amount'),
        maxLength: 16,
        matches: validatorPatterns.PAMOUNT_PATTERN,
        exists: true,
    },

    AMOUNT_1: {
        description: locale.get('SMBPAY.Amount'),
        maxLength: 16,
        matches: validatorPatterns.PAMOUNT_PATTERN,
        exists: true,
    },

    AMOUNT_2: {
        description: locale.get('SMBPAY.Amount'),
        maxLength: 16,
        matches: validatorPatterns.PAMOUNT_PATTERN,
        exists: true,
    },

    AMOUNT_3: {
        description: locale.get('SMBPAY.Amount'),
        maxLength: 16,
        matches: validatorPatterns.PAMOUNT_PATTERN,
        exists: true,
    },

    AMOUNT_4: {
        description: locale.get('SMBPAY.Amount'),
        maxLength: 16,
        matches: validatorPatterns.PAMOUNT_PATTERN,
        exists: true,
    },
};

export default TransferLayout.extend({
    template,
    className: 'transfer-model',

    ui: util.extend(
        {},
        TransferLayout.prototype.ui,
        {
            $paymentOptions: '.payment-options',
            $addOption: '#addOption',
        },
    ),

    events: {
        'click #remove-option': 'removeOption',
    },
    currentBeneAccountValue: null,

    initialize(options) {
        this.options = options;

        this.context = {
            serviceName: this.getServiceName(),

            typeInfo: {
                productCode: 'RTGS',
                functionCode: 'INST',
                typeCode: this.getTypeCode(),
            },

            // NH-33054 ; SMB only uses '0' which is Freeform entry
            entryMethodName: constants.ENTRYMETHODNAME,
        };

        this.preferences = options.preferences || {};
        this.paymentOptions = loans.getAvailablePaymentOptions(options.preferences);
        this.enabledOptions = new Collection(this.paymentOptions);
        this.amountOfPaymentOptions = this.enabledOptions.length;
        this.paymentTotal = 0;

        // 'apply to' payments type dropdown
        this.payOptionsLength = 0;
        this.serverDisplayBalancesParam = serverConfigParams.get('DisplayAccountBalanceOnLoanScreens') === 'true';
        this.shouldDisplayBalancesResult = null;
    },

    getHeader() {
        return locale.get('smbPayments.loan.pay.header');
    },

    getTypeCode() {
        return 'LOANPAY';
    },

    getServiceName() {
        return '/payment/loanPayment';
    },

    convertComboData(comboData) {
        return comboData.queryResponse.QueryData.queryRows;
    },

    beneAccountNumberChange(model, newValue) {
        this.setAccountMapDataList(newValue, this.beneAcctList, 'BENE_ACCOUNT');
        this.updateSelectedAccountBalance('BENE_ACCOUNT', newValue);
        const localNewValue = model.get('BENE_ACCOUNT');

        this.renderOptions();
        this.$el.find('.payment-options').removeClass('hide');

        const excludeCutoff = ['select', 'view'];
        if (localNewValue && !excludeCutoff.includes(this.options.action)) {
            TransferLayout.prototype.updateCutoff.apply(this);
        }
        if (newValue === '' || (this.currentBeneAccountValue !== newValue
            && newValue?.split('-')[1] !== this.currentBeneAccountValue)) {
            this.currentBeneAccountValue = model.get('BENE_ACCOUNT');
            loans.getPaymentPreferencesData(model, true)
                .catch(() => Promise.reject(locale.get('loans.loading.error')))
                .then((res) => {
                    this.preferences = { ...this.preferences, ...res };
                    this.ui.$addOption.toggleClass('hide', this.preferences?.singleOnlyPaymentOptionSupported);
                });
        }
    },

    // Payment Options
    renderOptions() {
        const self = this;

        if (!this.enabledOptions) {
            this.enabledOptions = new Collection(this.paymentOptions);
        }

        this.amountOfPaymentOptions = this.enabledOptions.length;

        const options = paymentOptionTmpl(this.enabledOptions.toJSON());
        let amountPresent = false;

        for (let x = 0; x < this.amountOfPaymentOptions; x += 1) {
            this.$el.find(`[name="SELECTION_${x}"]`).html(options).comboBox();

            if (this.paymentOptions[x].amount) {
                amountPresent = true;
                this.$el.find(`.multifield-container-${x}.payment-option`).removeClass('hide');
                this.$el.find(`#amount_${x}`).val(this.paymentOptions[x].amount).show();
                this.$el.find(`[name="SELECTION_${x}"]`).select2('val', this.paymentOptions[x].key);

                // change our model if the user changes payment type combo box
                $(this.$el.find(`[name="SELECTION_${x}"]`)).on('change', $.proxy(self.modifyPaymentType, self));
            }
        }

        /*
         * If there are no amounts to show, show the 'standard' amount
         * to allow the user to input an amount
         */
        if (!amountPresent) {
            this.$el.find('.multifield-container-0.payment-option').removeClass('hide');
            this.$el.find('#amount_0').val('').show();
        }

        if (this.$el.find('#amount_0').length) {
            // add an amount validator if the field exists
            validators.AMOUNT_0.description = locale.get('SMBPAY.Amount');
            this.model.addValidator('AMOUNT_0', validators.AMOUNT_0);
        }

        // add input mask for amount value localization
        this.ui.$amountFields.inputmask('number');

        // hide add button if only one enabled by admin
        if (this.paymentOptions.length === 1) {
            this.$el.find('#addOption').addClass('hide');
        }
        if (this.preferences.singleOnlyPaymentOptionSupported) {
            loans.removeOptionIcon(this);
        }

        // formats the displayed amount to be paid
        loans.getTotal(this.ui.$amount);
    },

    modifyPaymentType(sel) {
        if (sel && sel.removed) {
            const oldPaymentType = sel.removed.id;

            // set new payment type to oldpayment val, then remove oldpaymenttype
            this.model.set(sel.val, this.model.get(oldPaymentType));
            // our api wont let us juset unset the model, thats too easy!
            this.model.set(oldPaymentType, '');
        }
    },

    addOption() {
        if (this.payOptionsLength < this.amountOfPaymentOptions) {
            for (let x = 0; x < this.amountOfPaymentOptions; x += 1) {
                if (this.$el.find(`.multifield-container-${x}`).hasClass('hide')) {
                    this.$el.find(`.multifield-container-${x}`).removeClass('hide');
                    const value = $(`#selection_${x}`).val();
                    // add the validator on this field
                    validators[`AMOUNT_${x}`].description = locale.get('SMBPAY.Amount');
                    this.model.addValidator(`AMOUNT_${x}`, validators[`AMOUNT_${x}`]);
                    // set default value when option is visible
                    this.model.set(`SELECTION_${x}`, value);
                    this.payOptionsLength += 1;
                    break;
                }
            }
        }
    },

    removeOption(e) {
        this.payOptionsLength = $('.payment-option:visible').length;

        if (this.payOptionsLength > 1) {
            // find our amount and the related payment type (drop down)
            const field = this.$el.find(e.currentTarget).attr('data-hook');

            this.$el.find(`.multifield-container-${field}`).toggleClass('hide');
            this.$el.find(`#amount_${field}`).val('');
            // remove the validator on this hidden field
            this.model.removeValidator(`AMOUNT_${field}`);
            // unset when option is hidden
            this.model.unset(`SELECTION_${field}`);
            this.unsetPaymentOptionParameter(`SELECTION_${field}`);
            this.payOptionsLength -= 1;
        }
    },

    // remove value of payment option from the model, not unset
    unsetPaymentOptionParameter(selector) {
        this.model.set(this.$el.find(`select[name="${selector}"]`).val(), '');
    },

    /**
     * Sets Credit_Amount to the sum if all payment options
     * If no payment options exist, Credit_Amount becomes 0. Use only for LOANPAY.
     */
    updatePaymentTotal() {
        let total = 0;
        const self = this;

        util.each(this.ui.$paymentOptions.find('.payment-option'), (option) => {
            if (!self.$el.find(option).hasClass('hide')) {
                const amount = self.$el.find(option).find('input[data-hook="amount"]').val();
                total += Number(amount);
            }
        });

        this.model.set('CREDIT_AMOUNT', Formatter.formatCurrency(total));
        this.model.set('DEBIT_AMOUNT', Formatter.formatCurrency(total));
    },

    scheduleBtnHandler() {
        const self = this;
        // Flag to differentiate between Account and Transfer Widget.
        const modalOpeningFromAccountWidget = !!(this.options?.draggedModel);
        if (this.options && this.options.action === 'copyinst' && util.isUndefined(this.model.get('duplicateAccepted'))) {
            // When used from list page this must be called for Copy as Payment.
            this.prepModelFromCopy();
        }
        this.updatePaymentTotal();

        this.beforeSubmit();
        // validate the form
        if (!this.model.isValid()) {
            this.model.trigger('invalid');
            return;
        }

        this.trigger('dialog:buttons:disableAll');
        if (!this.actionInProgress) {
            this.actionInProgress = true;
            if (this.model.isValid()) {
                /*
                 * for existing transactions, the TRAN_DATE may be earlier that today.  This
                 * hack allows the update
                 */
                /*
                 * FIXME
                 * Option success was not being called in meta.js (presumably) but
                 * the returned jquery
                 * promise resolves appropriately
                 */
                this.model.save({
                    TRAN_DATE: this.model.get('VALUE_DATE'),
                }, {
                    success(resp) {
                        DataAPI.model.generate({
                            context: self.context,
                            state: 'insert',
                        }, false).then(() => {
                            Dialog.close();
                            self.updateConfirmData(resp, self.model);
                            if (modalOpeningFromAccountWidget) {
                                appBus.trigger('smbPayments:newTransfer:gridAlertForAccount', self.model);
                            } else {
                                appBus.trigger('smbPayments:newTransfer:gridAlert', self.model);
                            }
                            modalClose(self.options.onClose, self);
                        });
                    },
                    error(model, optionsParam) {
                        const options = optionsParam.error;
                        self.actionInProgress = false;

                        const { resultType } = options;
                        if (resultType === 'WARNING' && (options.errorCode && options.errorCode === 540)) {
                            duplicatePaymentUtil.showWarningConfirmation(options, self);
                            duplicatePaymentUtil.renderPaymentAlertContent(options, self);
                        } else {
                            self.ui.$duplicatePaymentSection.hide();
                            self.ui.$formElement.show();
                            self.renderMessage(self.model.error);
                            scroll.scrollToFirstError();
                        }
                        self.trigger('dialog:buttons:enableAll');
                        self.actionInProgress = false;
                    },
                });
            } else {
                // a client-side error occurred.  Re-enable buttons
                this.trigger('dialog:buttons:enableAll');
                this.actionInProgress = false;
            }
        }
    },

    beforeSubmit() {
        const obj = {
            description: 'Payment Option',
            isUnique: true,
        };

        const valsToSet = {};
        let value;
        let option;
        let amount;
        let selection;

        for (let x = 0; x < this.amountOfPaymentOptions; x += 1) {
            value = $(`#amount_${x}`).val();
            option = $(`#selection_${x}`).val();
            amount = `AMOUNT_${x}`;
            selection = `SELECTION_${x}`;

            if (!$(`.multifield-container-${x}`).hasClass('hide')) {
                valsToSet[amount] = value;
                valsToSet[selection] = option;
                valsToSet[option] = value;

                // add validators
                this.model.validators[selection] = obj;
            } else {
                this.model.removeValidator(amount);
            }
            if (this.amountOfPaymentOptions === 1) {
                this.model.removeValidator(amount);
            }
        }
        // update our new transaction amount
        valsToSet.TRANSACTION_AMOUNT = this.model.get('CREDIT_AMOUNT');
        valsToSet.CREDIT_AMOUNT = this.adjustCreditAmount();
        const debitModelValue = this.model.get('DEBIT_ACCOUNT_NUMBER');

        /*
         * When modifying payments/templates, we use this attribute to know which payment
         * options to display on page load. Once these options are displayed we need to clear
         * this attribute so the model considers the SELECT_X values unique
         */
        valsToSet.AMOUNTTYPES_SELECTED = '';
        valsToSet.DEBIT_ACCOUNT_NUMBER = debitModelValue?.split('-')?.[1] || debitModelValue;

        this.model.set(valsToSet);
    },

    /**
     * - check if this is the ONLY payment option type
     * - return the value from the model
     * @method adjustCreditAmount
     * @returns {string} value to set
     */
    adjustCreditAmount() {
        let amountKey;
        if (this.enabledOptions.length === 1) {
            amountKey = this.enabledOptions.at(0).get('key');
            return this.model.get(amountKey);
        }
        return this.model.get('CREDIT_AMOUNT');
    },

    getDebitInquiryId() {
        return 19533;
    },

    getCreditInquiryId() {
        return 19534;
    },

    getDebitAccountList(modelPromise) {
        this.debitAcctList = new InquiryQueryResults({
            includeMapData: InquiryApi.INCLUDE_MAP_DATA,

            customFilters: [{
                filterName: 'Depends',

                filterParam: [
                    'Credit_Currency',
                    'NONE',
                ],
            }],

            allowDuplicates: true,
            inquiryId: this.getDebitInquiryId(),
            typeCode: this.getTypeCode(),
            productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
            functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,
            queryField: this.getDebitQueryField(),
        });
        const accountPromise = new Promise((resolve, reject) => {
            this.debitAcctList.fetch({
                success: resolve,
                error: reject,
            });
        });
        /*
         * Need to wait for the model promise, so it is possible to evaluate if
         * account balances should be displayed
         */
        return Promise.all([
            modelPromise,
            accountPromise,
        ]).then(() => {
            if (this.shouldDisplayBalances()) {
                return this.updateAccountDropdownBalances('DEBIT_ACCOUNT_NUMBER')
                    .then(() => Promise.resolve(this.debitAcctList));
            }
            return Promise.resolve(this.debitAcctList);
        });
    },

    getCreditAccountList() {
        // get credit loan payment accounts
        return new Promise((resolve, reject) => {
            this.beneAcctList = new InquiryQueryResults({
                includeMapData: InquiryApi.INCLUDE_MAP_DATA,
                customFilters: [{
                    filterName: 'Depends',

                    filterParam: [
                        'Debit_Currency',
                        'NONE',
                    ],
                }],
                allowDuplicates: true,
                inquiryId: this.getCreditInquiryId(),
                typeCode: this.getTypeCode(),
                productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,
                queryField: this.getCreditQueryField(),
            });
            this.beneAcctList.fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    /**
     * Return the query field used to get the label for
     * the credit accounts
     * @returns {string}
     */
    getCreditQueryField() {
        return constants.ACCOUNTNAME_DISPLAY;
    },

    /**
     * Return the query field used to get the label for
     * the debit accounts
     * @returns {string}
     */
    getDebitQueryField() {
        return constants.ACCOUNTNAME_NUM_BANK;
    },

    /**
     * Based on the parameters, return the promise used to fetch the accounts
     * @param {Object} data
     * @param {string} fieldName
     * @param {boolean} isAccountArray
     * @returns {Promise}
     */
    getAccountBalancePromise(postData, fieldName, isAccountArray) {
        if (fieldName === 'DEBIT_ACCOUNT_NUMBER') {
            return requestServices.postData(
                isAccountArray ? services.currencyBalances : services.balanceAndTransaction,
                postData,
            );
        }
        return this.getLoanAccountBalancePromise(
            isAccountArray ? services.loanBalancesReporting : services.loanReporting,
            postData,
        );
    },

    /**
     * Iterates over the the requests property of the postData and
     * makes a request for each and returns a single promise wrapping
     * each request
     * @param {string} serviceUrl
     * @param {Object} postData
     * @param {Array} postData.requests
     * @returns {Promise}
     */
    getLoanAccountBalancePromise(serviceUrl, postData) {
        return requestServices.postData(serviceUrl, postData);
    },

    templateHelpers(...args) {
        const self = this;

        const base = TransferLayout.prototype.templateHelpers.apply(this, args);

        return util.extend(
            {},
            base,
            {
                creditAccountLabel: locale.get('smbPayments.loan.account'),

                paymentOptions() {
                    return self.enabledOptions.toJSON();
                },

                hasMultipleOptions: true,

                showMemo() {
                    return self.options.action !== 'select';
                },

                singleOptionOnly() {
                    return self.preferences.singleOnlyPaymentOptionSupported;
                },
                displayInterestRate: false,
            },
        );
    },
});
