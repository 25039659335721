var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\n                <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"start") || (depth0 != null ? lookupProperty(depth0,"start") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"start","hash":{},"data":data,"loc":{"start":{"line":3,"column":22},"end":{"line":3,"column":31}}}) : helper)))
    + "</span>\n                <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"START_LEDGER_FORGROUP") || (depth0 != null ? lookupProperty(depth0,"START_LEDGER_FORGROUP") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"START_LEDGER_FORGROUP","hash":{},"data":data,"loc":{"start":{"line":4,"column":22},"end":{"line":4,"column":47}}}) : helper)))
    + "</span>\n        </li>\n\n        <li>\n                <span>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"balanceTrans.total.credits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":22},"end":{"line":8,"column":61}}}))
    + " <span>("
    + alias4(((helper = (helper = lookupProperty(helpers,"TOTAL_NUMBER_CREDITS_FORGROUP") || (depth0 != null ? lookupProperty(depth0,"TOTAL_NUMBER_CREDITS_FORGROUP") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOTAL_NUMBER_CREDITS_FORGROUP","hash":{},"data":data,"loc":{"start":{"line":8,"column":69},"end":{"line":8,"column":102}}}) : helper)))
    + ")</span></span>\n                <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"TOTAL_CREDIT_AMT_FORGROUP") || (depth0 != null ? lookupProperty(depth0,"TOTAL_CREDIT_AMT_FORGROUP") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOTAL_CREDIT_AMT_FORGROUP","hash":{},"data":data,"loc":{"start":{"line":9,"column":22},"end":{"line":9,"column":51}}}) : helper)))
    + "</span>\n        </li>\n\n        <li>\n                <span>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"balanceTrans.total.debits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":22},"end":{"line":13,"column":60}}}))
    + " <span>("
    + alias4(((helper = (helper = lookupProperty(helpers,"TOTAL_NUMBER_DEBITS_FORGROUP") || (depth0 != null ? lookupProperty(depth0,"TOTAL_NUMBER_DEBITS_FORGROUP") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOTAL_NUMBER_DEBITS_FORGROUP","hash":{},"data":data,"loc":{"start":{"line":13,"column":68},"end":{"line":13,"column":100}}}) : helper)))
    + ")</span></span>\n                <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"TOTAL_DEBIT_AMT_FORGROUP") || (depth0 != null ? lookupProperty(depth0,"TOTAL_DEBIT_AMT_FORGROUP") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOTAL_DEBIT_AMT_FORGROUP","hash":{},"data":data,"loc":{"start":{"line":14,"column":22},"end":{"line":14,"column":50}}}) : helper)))
    + "</span>\n        </li>\n\n        <li>\n                <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"end") || (depth0 != null ? lookupProperty(depth0,"end") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"end","hash":{},"data":data,"loc":{"start":{"line":18,"column":22},"end":{"line":18,"column":29}}}) : helper)))
    + "</span>\n                <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"END_LEDGER_SUM_FORGROUP") || (depth0 != null ? lookupProperty(depth0,"END_LEDGER_SUM_FORGROUP") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"END_LEDGER_SUM_FORGROUP","hash":{},"data":data,"loc":{"start":{"line":19,"column":22},"end":{"line":19,"column":49}}}) : helper)))
    + "</span>\n        </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\n                <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"start") || (depth0 != null ? lookupProperty(depth0,"start") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"start","hash":{},"data":data,"loc":{"start":{"line":23,"column":22},"end":{"line":23,"column":31}}}) : helper)))
    + "</span>\n                <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"START_LEDGER") || (depth0 != null ? lookupProperty(depth0,"START_LEDGER") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"START_LEDGER","hash":{},"data":data,"loc":{"start":{"line":24,"column":22},"end":{"line":24,"column":38}}}) : helper)))
    + "</span>\n        </li>\n\n        <li>\n                <span>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"balanceTrans.total.credits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":22},"end":{"line":28,"column":61}}}))
    + " <span>("
    + alias4(((helper = (helper = lookupProperty(helpers,"TOTAL_NUMBER_CREDITS") || (depth0 != null ? lookupProperty(depth0,"TOTAL_NUMBER_CREDITS") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOTAL_NUMBER_CREDITS","hash":{},"data":data,"loc":{"start":{"line":28,"column":69},"end":{"line":28,"column":93}}}) : helper)))
    + ")</span></span>\n                <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"TOTAL_CREDIT_AMT") || (depth0 != null ? lookupProperty(depth0,"TOTAL_CREDIT_AMT") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOTAL_CREDIT_AMT","hash":{},"data":data,"loc":{"start":{"line":29,"column":22},"end":{"line":29,"column":42}}}) : helper)))
    + "</span>\n        </li>\n\n        <li>\n                <span>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"balanceTrans.total.debits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":22},"end":{"line":33,"column":60}}}))
    + " <span>("
    + alias4(((helper = (helper = lookupProperty(helpers,"TOTAL_NUMBER_DEBITS") || (depth0 != null ? lookupProperty(depth0,"TOTAL_NUMBER_DEBITS") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOTAL_NUMBER_DEBITS","hash":{},"data":data,"loc":{"start":{"line":33,"column":68},"end":{"line":33,"column":91}}}) : helper)))
    + ")</span></span>\n                <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"TOTAL_DEBIT_AMT") || (depth0 != null ? lookupProperty(depth0,"TOTAL_DEBIT_AMT") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOTAL_DEBIT_AMT","hash":{},"data":data,"loc":{"start":{"line":34,"column":22},"end":{"line":34,"column":41}}}) : helper)))
    + "</span>\n        </li>\n\n        <li>\n                <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"end") || (depth0 != null ? lookupProperty(depth0,"end") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"end","hash":{},"data":data,"loc":{"start":{"line":38,"column":22},"end":{"line":38,"column":29}}}) : helper)))
    + "</span>\n                <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"END_LEDGER") || (depth0 != null ? lookupProperty(depth0,"END_LEDGER") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"END_LEDGER","hash":{},"data":data,"loc":{"start":{"line":39,"column":22},"end":{"line":39,"column":36}}}) : helper)))
    + "</span>\n        </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"useGroupKeys") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":41,"column":7}}})) != null ? stack1 : "");
},"useData":true});