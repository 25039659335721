var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <div></div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"detailMessage") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":10,"column":23}}})) != null ? stack1 : "")
    + "        <div class=\"form-group\">\n            <label>"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":12,"column":19},"end":{"line":12,"column":25}}}) : helper)))
    + "</label> <span class=\"form-control-static\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"ID") || (depth0 != null ? lookupProperty(depth0,"ID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ID","hash":{},"data":data,"loc":{"start":{"line":12,"column":68},"end":{"line":12,"column":74}}}) : helper)))
    + "</span>\n        </div>\n       \n        <div class=\"form-group\">\n            <label>"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":16,"column":19},"end":{"line":16,"column":27}}}) : helper)))
    + "</label> <span class=\"form-control-static\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"BENE_NAME") || (depth0 != null ? lookupProperty(depth0,"BENE_NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BENE_NAME","hash":{},"data":data,"loc":{"start":{"line":16,"column":70},"end":{"line":16,"column":83}}}) : helper)))
    + "</span>\n        </div>\n        <div class=\"form-group\">\n            <label>"
    + alias4(((helper = (helper = lookupProperty(helpers,"contactAccount") || (depth0 != null ? lookupProperty(depth0,"contactAccount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contactAccount","hash":{},"data":data,"loc":{"start":{"line":19,"column":19},"end":{"line":19,"column":37}}}) : helper)))
    + "</label> <span class=\"form-control-static\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"BENE_ACCOUNT_DESCRIPTION") || (depth0 != null ? lookupProperty(depth0,"BENE_ACCOUNT_DESCRIPTION") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BENE_ACCOUNT_DESCRIPTION","hash":{},"data":data,"loc":{"start":{"line":19,"column":80},"end":{"line":19,"column":108}}}) : helper)))
    + "</span>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPayment") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":25,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"form-group\">\n            <label>"
    + alias4(((helper = (helper = lookupProperty(helpers,"userAccount") || (depth0 != null ? lookupProperty(depth0,"userAccount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userAccount","hash":{},"data":data,"loc":{"start":{"line":27,"column":19},"end":{"line":27,"column":34}}}) : helper)))
    + "</label> <span class=\"form-control-static\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"DEBIT_ACCOUNT_NUMBER") || (depth0 != null ? lookupProperty(depth0,"DEBIT_ACCOUNT_NUMBER") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DEBIT_ACCOUNT_NUMBER","hash":{},"data":data,"loc":{"start":{"line":27,"column":77},"end":{"line":27,"column":101}}}) : helper)))
    + "</span>\n        </div>\n        <div class=\"form-group\">\n            <label>"
    + alias4(((helper = (helper = lookupProperty(helpers,"transactionDate") || (depth0 != null ? lookupProperty(depth0,"transactionDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"transactionDate","hash":{},"data":data,"loc":{"start":{"line":30,"column":19},"end":{"line":30,"column":38}}}) : helper)))
    + "</label> <span class=\"form-control-static\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"VALUE_DATE") || (depth0 != null ? lookupProperty(depth0,"VALUE_DATE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"VALUE_DATE","hash":{},"data":data,"loc":{"start":{"line":30,"column":81},"end":{"line":30,"column":95}}}) : helper)))
    + "</span>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"SPECIAL_INSTRUCTIONS") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":8},"end":{"line":36,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"form-group\">\n            <label>"
    + alias4(((helper = (helper = lookupProperty(helpers,"total") || (depth0 != null ? lookupProperty(depth0,"total") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"total","hash":{},"data":data,"loc":{"start":{"line":38,"column":19},"end":{"line":38,"column":28}}}) : helper)))
    + "</label> <span class=\"form-control-static\">"
    + alias4(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"CREDIT_AMOUNT") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":38,"column":71},"end":{"line":38,"column":95}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"CREDIT_CURRENCY") || (depth0 != null ? lookupProperty(depth0,"CREDIT_CURRENCY") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CREDIT_CURRENCY","hash":{},"data":data,"loc":{"start":{"line":38,"column":96},"end":{"line":38,"column":115}}}) : helper)))
    + "</span>\n        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"form-group\">\n                        <span class=\"form-control-static\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"detailMessage") || (depth0 != null ? lookupProperty(depth0,"detailMessage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"detailMessage","hash":{},"data":data,"loc":{"start":{"line":8,"column":58},"end":{"line":8,"column":75}}}) : helper)))
    + "</span>\n                    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"form-group\">\n                <label>"
    + alias4(((helper = (helper = lookupProperty(helpers,"paymentMethod") || (depth0 != null ? lookupProperty(depth0,"paymentMethod") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"paymentMethod","hash":{},"data":data,"loc":{"start":{"line":23,"column":23},"end":{"line":23,"column":40}}}) : helper)))
    + "</label> <span class=\"form-control-static\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"TYPE_DESCRIPTION_UPDATE") || (depth0 != null ? lookupProperty(depth0,"TYPE_DESCRIPTION_UPDATE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TYPE_DESCRIPTION_UPDATE","hash":{},"data":data,"loc":{"start":{"line":23,"column":83},"end":{"line":23,"column":110}}}) : helper)))
    + "</span>\n            </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"form-group\">\n                <label>"
    + alias4(((helper = (helper = lookupProperty(helpers,"comments") || (depth0 != null ? lookupProperty(depth0,"comments") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"comments","hash":{},"data":data,"loc":{"start":{"line":34,"column":23},"end":{"line":34,"column":35}}}) : helper)))
    + "</label> <span class=\"form-control-static\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"SPECIAL_INSTRUCTIONS") || (depth0 != null ? lookupProperty(depth0,"SPECIAL_INSTRUCTIONS") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SPECIAL_INSTRUCTIONS","hash":{},"data":data,"loc":{"start":{"line":34,"column":78},"end":{"line":34,"column":102}}}) : helper)))
    + "</span>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"cmb-alert-details\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isError") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":40,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});