/* eslint-disable */
/*
 * For BioCatch to work properly, there are a few server config params that need to be setup
 * for each client.
 * 1. BioCatchSnippetUrl - a URL for the snippet
 * 2. BioCatchMode - the mode to run in. The default is 'primary', where session and 
 * context changes are sent to bioCatch. 'secondary' only includes a snippet from BioCatch
 * to perform other operations 
 * 3. BioCatchScope - the name of the object exposed by the snippet. Only used in 'primary' mode
 * 4. BioCatchSessionCookieName - the name of the cookie where user session is stored. Only
 * used in 'primary' mode
 * 
 * Route Context - branding override
 * Depending on the implementation, different a route context may need to be overridden by branding.
 * The import below BioCatchRouteContext  contains an object which can be completely replaced to
 * accomodate different contexts or additional routes.
 */

import util from 'underscore';
import * as serverConfigParamsImport from 'system/webseries/models/configurationParameters';
import { BioCatchRouteContext } from './bioCatchRouteContext';
import getSession from './util';
const { default: serverConfigParams } = serverConfigParamsImport;

const bioCatch = {
    postAuth() {
        // Begin BioCatch snippet
        const bioCatchSnippetUrl = serverConfigParams.get('BioCatchSnippetUrl');
        const bioCatchMode = serverConfigParams.get('BioCatchMode');

        if (util.isEmpty(bioCatchSnippetUrl)) {
            return;
        }

        var f = document,
            e = window,
            i = e.location.protocol,
            b = [
                ["src", [i == "https:" ? "https:/" : "http:/", bioCatchSnippetUrl].join("/")],
                ["type", "text/javascript"],
                ["async", true]
            ],
            c = f.createElement("script"),
            h = f.getElementsByTagName("head")[0];
            if (bioCatchMode === 'primary') {
                c.addEventListener('load', () => {
                    bioCatch.setCustomerSession();
                    // Establish the context when the snippet loads for the first time
                    bioCatch.routeChange(window.location.pathname);
                }, false);
            }
        setTimeout(function() {
            for (var d = 0, l = b.length; d < l; d++) {
                c.setAttribute(b[d][0], b[d][1])
            }
            h.appendChild(c)
        }, 0);
        // End BioCatch snippet
    },

    /**
     * Set setCustomerSessionId with BioCatch if the bioCatch scope and function exists
     */
    setCustomerSession() {
        const session = getSession(serverConfigParams.get('BioCatchSessionCookieName'));
        const bioCatchScope = serverConfigParams.get('BioCatchScope');
        if (window[bioCatchScope] && typeof window[bioCatchScope].setCustomerSessionId === 'function') {
            window[bioCatchScope].setCustomerSessionId(session);
        }
    },

    /**
     * Early return when bioCatchMode is secondary, as routeChanges are not relevant.
     * Notify BioCatch of a context change, if the bioCatch scope and function exists
     * @param {string} route 
     */
    routeChange(route) {
        if(serverConfigParams.get('BioCatchMode') === 'secondary') {
            return;
        }
        const bioCatchScope = serverConfigParams.get('BioCatchScope');
        if (window[bioCatchScope] && typeof window[bioCatchScope].changeContext === 'function') {
            window[bioCatchScope].changeContext(BioCatchRouteContext[route] || 'OTHER');
        }
    },
}

export default bioCatch;

