import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import dialog from '@glu/dialog';
import alert from '@glu/alerts';
import ListView from 'common/dynamicPages/views/workflow/list';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
/*
 * this consolidates bulk grid row action handling into a single class
 * this cannot be instantiated as-is - extend it!
 */

export default Layout.extend({
    events: {
        'change input[type="checkbox"]': 'updateButtons',
    },

    ui: {
        $buttonList: '.button-list',
    },

    /**
     * @abstract
     * A stand-in for the correct template, as it is the only change between the
     * two implementations.
     */
    buttonTemplate() {
        throw new ReferenceError('Button Template not implemented.');
    },

    getSelectedModel() {
        return this.gridView.wrapper.rows.get(this.gridView.grid.getSelectedRows()[0]);
    },

    select() {
        this.gridView.trigger(
            'rowAction',
            {
                action: 'SELECT',
                model: this.getSelectedModel(),
            },
        );
    },

    modify() {
        this.gridView.trigger(
            'rowAction',
            {
                action: 'MODIFY',
                model: this.getSelectedModel(),
            },
        );
    },

    onShow() {
        if (!this.gridView) {
            return;
        }

        this.listenToOnce(
            this.gridView,
            {
                'item:rendered': this.setupGridEvents,
            },
            this,
        );

        this.listenTo(
            this.gridView,
            {
                'gridapi:loaded': this.updateButtons,
            },
        );
    },

    setupGridEvents() {
        this.listenTo(
            this.gridView.grid.collection,
            {
                sync: this.refreshData,
            },
            this,
        );

        this.gridView.refreshGridData();
        this.removeActions();
    },

    refreshData(obj, response, options) {
        if (options && options.source === 'itemService') {
            ListView.prototype.refreshData.call(this);
        }

        this.removeActions();
    },

    updateButtons() {
        if (!this.gridView || !this.gridView.grid) {
            return;
        }

        const selectedRowIds = this.gridView.grid.getSelectedRows();

        const buttons = {
            buttonList: [],
            multipleSelection: selectedRowIds.length > 1,
        };

        if (selectedRowIds.length === 0) {
            this.removeButtons();
            return;
        }

        const nonBatchActions = ['modify', 'select'];

        // action: title enforces uniqueness
        let actions = {};

        const actionsToIntersect = [];

        if (this.gridView.grid) {
            // collect ALL actions and keep track of each row's actions for later
            util.each(selectedRowIds, function (id, index) {
                const model = this.gridView.wrapper.rows.get(id);
                util.each(model.buttons, (button) => {
                    const action = button.action.toLowerCase();
                    actions[action] = button.label;
                    if (actionsToIntersect[index]) {
                        actionsToIntersect[index].push(action);
                    } else {
                        actionsToIntersect[index] = [action];
                    }
                }, this);
            }, this);

            /*
             * get rid of non-batch actions if selected rows > 1
             * get rid of actions that are not present in ALL selected rows
             */
            if (selectedRowIds.length > 1) {
                const commonActions = util.intersection.apply(this, actionsToIntersect);
                actions = util.chain(actions).omit(nonBatchActions).pick(commonActions).value();
            }

            // convert to a format the template can use
            buttons.buttonList = util.map(actions, (
                title,
                action,
            ) => ({
                action,
                title,
            }));
        }

        this.ui.$buttonList.html(this.buttonList(buttons));
    },

    removeButtons() {
        this.ui.$buttonList.empty();
    },

    // bill pay handlers
    setBillToPay(model) {
        this.billToPay = model;
    },

    clearBillToPay() {
        this.billToPay = null;
    },

    paymentIssued(billId) {
        if (this.billToPay.get('BILL_ID') !== billId) {
            return;
        }

        this.billToPay.paymentIssued({
            success: () => {
                this.clearBillToPay();
                this.gridView.refreshGridData();
            },

            error: () => {
                this.clearBillToPay();
                this.gridView.refreshGridData();
                dialog.alert(locale.get('smbBillMgmt.payment.issue.status.error'), locale.get('smbBillMgmt.payment.issue.failed.error'));
            },
        });
    },

    // bulk handling
    renderMessage(action, confirmResponse, errorCount) {
        /*
         * create the confirmation view for the alert
         * skip if action is null.  This occurs when first entering the payment workspace
         * because the onRender is called.
         */
        if ((action === null || action === undefined)
            && (confirmResponse === null || confirmResponse === undefined)) {
            return;
        }

        if (confirmResponse === null
            || confirmResponse === undefined
            || confirmResponse.confirms === null
            || confirmResponse.confirms === undefined) {
            if (action.type === 'WARNING' && action.message) {
                this.alertView = alert.warning(action.message);
                this.alertRegion.show(this.alertView);
            }
        } else {
            const confirm = typeof confirmResponse === 'object' ? confirmResponse.confirms : null;
            const successCount = confirm !== null ? confirm.totalSuccess : confirmResponse;
            const failCount = confirm !== null ? confirm.totalFail : errorCount;
            const totalCount = failCount + successCount;
            const success = successCount === totalCount;
            const alertFunc = success ? alert.success : alert.danger;
            const chainedMessages = typeof confirmResponse === 'object' ? confirmResponse.message : null;
            const message = typeof confirmResponse === 'object' && confirmResponse.message.length > 0 ? confirmResponse.message[chainedMessages.length - 1] : action;

            const confirms = new Confirms({
                confirms: confirmResponse ? confirmResponse.confirms : null,
            });

            // display notification message
            this.alertView = alertFunc(
                message,
                {
                    details: confirmResponse
                    && confirmResponse.confirms.confirmResults[0].confirmData[0].item
                        ? confirms : null,
                    canDismiss: !!confirmResponse,
                    animate: false,
                },
            );

            if (message) {
                this.alertRegion.show(this.alertView);
            }
        }
    },

    /**
     * @abstract
     * Base removeActions function for override
     */
    removeActions() {
        throw new ReferenceError('removeActions not implemented.');
    },

    /**
     * FIXME: copied from common/dynamicPages/views/workflow/list.js and desperately
     * in need of a rewrite
     * @param {string} method
     */
    processBulkAction(method) {
        const self = this;
        const selectedArray = this.gridView.grid.getSelectedRows();
        let confirmResponse = null;
        let successMessage = null;
        let totalSuccess = 0;
        let totalFail = 0;
        let failMessage = null;
        let message = null;

        const formatConfirm = function () {
            let idx = -1;

            if (successMessage) {
                idx = successMessage.indexOf(' ');
                successMessage = idx > -1
                    ? totalSuccess + successMessage.slice(idx) : successMessage;

                // add a separator for success and failure messages
                if (failMessage) {
                    successMessage += ', ';
                }
                message = successMessage;
            }
            if (failMessage) {
                idx = failMessage.indexOf(' ');
                failMessage = idx > -1 ? totalFail + failMessage.slice(idx) : failMessage;
                if (successMessage) {
                    message += failMessage;
                } else {
                    message = failMessage;
                }
            }
            confirmResponse.message[0] = message;
            confirmResponse.confirms.totalSuccess = totalSuccess;
            confirmResponse.confirms.totalFail = totalFail;
        };

        const finishMethod = util.after(selectedArray.length, () => {
            self.gridView.refreshGridData();
            formatConfirm();
            self.renderMessage(method, confirmResponse);
            confirmResponse = null;
            successMessage = null;
            failMessage = null;
            message = null;
        });

        const optionsBlock = {
            success(model, resp) {
                if (confirmResponse === null) {
                    confirmResponse = resp;
                } else {
                    confirmResponse.confirms
                        .confirmResults.push(resp.confirms.confirmResults[0]);
                }
                totalSuccess += resp.confirms.totalSuccess;
                totalFail += resp.confirms.totalFail;
                if (resp.confirms.totalSuccess) {
                    [successMessage] = resp.message;
                } else {
                    [failMessage] = resp.message;
                }
                finishMethod();
            },

            error() {
                confirmResponse.confirms.totalFail += 1;
                finishMethod();
            },
        };

        util.each(selectedArray, function (id) {
            const model = this.gridView.wrapper.rows.get(id);
            model[method](optionsBlock);
        }, this);
    },

    approve() {
        this.processBulkAction('approve');
    },

    /**
     * This is *NOT* misspelled, server-side uses this stupid eight-character
     * limit name. :(
     */
    unapprov() {
        this.processBulkAction('unapprove');
    },

    validate() {
        this.processBulkAction('validateAction');
    },

    /**
     * This is *NOT* misspelled, server-side uses this stupid eight-character
     * limit name. :(
     */
    unvalida() {
        this.processBulkAction('unvalidate');
    },

    reject() {
        this.processBulkAction('reject');
    },

    cancel() {
        if (this.gridView) {
            this.gridView.$('.grid-all-rows-selector, .column-row-selector').prop('checked', false).trigger('change');
        }
    },
});
