import Collection from '@glu/core/src/collection';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';

const Account = Collection.extend({
    initialize() {
        this.listenTo(
            this,
            {
                'change:minimumBalance change:cashFlowEnabled': this.saveCashFlowPreference,
                sync: this.modelUpdated,
            },
            this,
        );
        this.loadAccounts();
    },

    modelUpdated(model) {
        // make sure this is a model, not collection sync event
        if (model && typeof model.hasChanged === 'function' && model.hasChanged('minimumBalance')) {
            this.loadAccounts();
        }
    },

    saveCashFlowPreference(model) {
        const data = {
            accountId: model.get('accountId'),
            aba: model.get('aba'),
            accountNumber: model.get('accountNum'),
            cashFlowEnabled: model.get('cashFlowEnabled'),
            minimumBalance: model.get('minimumBalance'),
        };

        http.post(services.generateUrl('/bosAccount/update'), data, (result) => {
            model.trigger('sync', model, result);
        }, () => {
            model.trigger('error', model);
        });
    },

    loadAccounts(options) {
        const self = this;
        http.post(
            services.generateUrl('/bosAccount/list'),
            {
                accountId: 0,
            },
            (data) => {
                self.trigger('sync', self, data, options);
                self.reset(data);
            },
            () => {
                self.trigger('error', self);
            },
        );
    },

    getActiveThresholds() {
        return this.chain().filter(account => account.get('cashFlowEnabled') && !util.isNull(account.get('minimumBalanceDate'))).map(account => ({
            value: account.get('minimumBalance'),
            text: account.get('accountName'),
        })).value();
    },
});

export default Account;
