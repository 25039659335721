import Layout from '@glu/core/src/layout';
import services from 'services';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import constants from 'app/administration/constants';
import Grid from '@glu/grid';
import checkGridHeader from 'common/checkGrid/checkGridHeader';
import collectionProxyGen from 'common/collectionProxy/collectionProxyGenerator';
import tableLayout from '@glu/grid/src/tableLayout';
import adoptRegion from '@glu/grid/src/adoptRegion';
import configParams from 'system/webseries/models/configurationParameters';
import proxyTabSetup from './proxyTabSetup';
import gridDataAccess from './gridDataActions';
import template from './checkGrid.hbs';

export default Layout.extend({
    template,
    singleType: false,

    initialize(options) {
        // One-time call for services
        const live = this.options.copyingUser || this.options.copyingRole;
        this.serviceURL = services.generateUrl(`/users/userCentric/v2/getDataEntitlements${live ? 'Live' : ''}`);

        // Access from the tab
        this.proxyData = options.proxyData;

        // The typecode/product map so product code can be applied when by type.
        this.typeProductMap = options.typeProductMap;

        // Add proxy functions to this object.
        proxyTabSetup(this, true);

        // The grid loads async.
        this.hasLoaded = false;
        this.cfLoaded = false;

        // Don't mess with the existing init
        Layout.prototype.initialize.call(this, options);

        this.mode = options.mode;
        this.permissionsChangedToByGroup = options.permissionsChangedToByGroup;
    },

    ui: {
        $grid: '.grid-region',
        $allCheck: '.selectAll',
        $cfCheck: '.cfCheck',
        $groupCheck: '.groupCheck',
    },

    events: {
        'change @ui.$allCheck': 'checkAll',
        'change @ui.$cfCheck': 'checkAllCurrentFutureFromDOM',
        'change @ui.$groupCheck': 'checkTypeGroup',
        'click tbody td input[type="checkbox"]': 'checkEachFromDOM',
    },

    /**
     * Determine if there are any entitlements for this tab.
     */
    shouldShow() {
        return gridDataAccess.getAllEnabledEntitlements(this.getRecords()).length !== 0;
    },

    onRender() {
        /*
         * TODO: Use this when available to avoid extra calls to getAllRecords
         * When it's run here it doesn't get all the entitlement data.
         */
        this.permissionRecords = this.getRecords();

        this.loadCurrentFutureData()
            .then(this.loadFirstGrid.bind(this))
            .then(() => {
                this.hasLoaded = true;
            });
        return this;
    },

    /**
     * Used for specific use case for a CGBO user where not all accounts on grid are available
     * to be selected. This method can be removed once the server implementation is changed to
     * remove unavailable accounts via the sql rather than programmatically via the list
     * view call.
     */
    disableSelectAllForUnavailableAccounts() {
        this.grid.collection.forEach((model) => {
            const isAvailableAccont = util.some(model.values(), value => ['0', '1'].indexOf(value) !== -1);
            if (!isAvailableAccont) {
                model.set(
                    'selectAll',
                    '',
                    {
                        silent: true,
                    },
                );
            }
        });
    },

    /** ********************** CHECK EVENTS */

    /**
     * @abstract
     * @return {string}
     */
    getEntityName() {
        throw new Error('getEntityName not implemented');
    },

    /**
     * @abstract
     * @return {number}
     */
    getEntityID() {
        throw new Error('getEntityID not implemented');
    },

    /**
     * @abstract
     * @return {number}
     */
    getCFEntityID() {
        throw new Error('getCFEntityID not implemented');
    },

    /**
     * @abstract
     * @return {Object}
     */
    getEntitlementsSource() {
        throw new Error('getEntitlementsSource not implemented');
    },

    /** ********************** CHECK EVENTS */

    checkEachFromDOM(event) {
        // Only when unchecking an item and not on the selectAll column.
        if (event.currentTarget.checked || event.currentTarget.dataset.column === 'selectAll') {
            return;
        }

        const $row = this.$(event.currentTarget).closest('tr');
        const $rowChecks = $row.find('input[type="checkbox"]');
        const modelId = $row.data('model-cid');
        const gridModel = this.grid.collection.get(modelId);
        const $selAllCheck = $rowChecks.eq(0);
        const $otherChecks = $rowChecks.not($selAllCheck);
        const $otherActive = $otherChecks.not(':disabled');
        const allPossibleChecked = $otherActive.filter(':checked').length === $otherActive.length;
        const allCheckedValue = allPossibleChecked ? '1' : '0';

        /**
         * Skip it if we are on the selectAll checkbox or if everything is checked...
         * We don't check this, just uncheck it.
         * And don't change anything if the value is already correct.
         */
        if (allPossibleChecked || gridModel.get('selectAll') === allCheckedValue) {
            return;
        }

        // Update the model silently, or we might mess up the DOM
        gridModel.set(
            'selectAll',
            allCheckedValue,
            {
                silent: true,
            },
        );
        $selAllCheck.prop('checked', allPossibleChecked);
    },

    /**
     * Translates DOM event into the DOM-free event.
     * @param {Event} event
     * @return {*}
     */
    checkAllCurrentFutureFromDOM(event) {
        const { checked } = event.currentTarget;
        const colName = event.currentTarget.dataset.value;
        const $cfCheck = this.$(event.currentTarget);
        const $table = $cfCheck.closest('table');
        const eStatementHeaderMatches = gridDataAccess.getEStatementHeaders(this.typeProductMap);
        let $allCheck;

        // Handle the purely DOM-related (view) interactions here.
        if ($cfCheck.length) {
            // Disable and mark checkAll header if CF is checked
            $allCheck = $table.find(`tr.entitlements input[data-value="${colName}"]`);
            $allCheck.prop('disabled', checked);
            $allCheck.parents('th').toggleClass('mark', checked);
        }

        if (colName === 'selectAll') {
            // Check all the CF boxes
            $table.find('input.cfCheck:enabled').prop('checked', checked);

            // disable all the select all (column)
            $allCheck = $table.find('tr.entitlements input[data-value]');
            $allCheck.prop('disabled', checked);
            $allCheck.parents('th').toggleClass('mark', checked);

            /*
             * TODO: Check all other cfCheck inputs? Disable?
             * Uncheck selectAll if another cfCheck unchecked?
             */

            $allCheck.each((index, el) => {
                const allCheckColName = el.dataset.value;
                const column = this.getProxyColumn(allCheckColName);
                if (column.currentFuture !== '') {
                    this.checkAllCurrentFuture(allCheckColName, checked);
                }
            });
        } else if (eStatementHeaderMatches[colName]) {
            // Check or uncheck all ESTATEMENT headers together
            util.each(eStatementHeaderMatches, function (val, key) {
                this.checkAllCurrentFuture(key, checked);
            }, this);
        } else {
            this.checkAllCurrentFuture(colName, checked);
        }

        // Cause the rows to refresh
        this.pokeTheGrid(this.grid);

        /*
         * Refresh the header directly
         * TODO: This doesn't restore focus. We need to resolve this in the future.
         */
        this.grid.tableHeader.render();
    },

    /**
     * Pokes the grid to cause each row to re-render without re-rendering the
     * whole thing.
     * More efficient than every event
     * @param {Grid} grid
     * @param {String} [eventName]
     * @param {boolean} [justCollection]
     */
    pokeTheGrid(grid, eventName, justCollection) {
        const callEvent = eventName || 'change';

        if (justCollection) {
            grid.collection.trigger(callEvent);
        } else {
            grid.collection.each((row) => {
                row.trigger(callEvent);
            });
        }
    },

    /**
     * Get the column and the
     * @param {String} colName
     * @param {boolean} checked
     * @param {boolean} [initial] Provided if happening from the first load
     */
    checkAllCurrentFuture(colName, checked, initial) {
        const column = this.getProxyColumn(colName);

        column.currentFuture = checked;

        // Columns loaded as currentFuture cannot be mixed.
        if (initial && checked) {
            column.initialCurrentFuture = true;
            column.mixedChangeSet = false;
        } else if (!checked && this.grid) {
            /*
             * Wipe columns when currentFuture is unchecked
             * The grid check is because this can run during initial proxy load.
             */
            column.allSet = true;
            column.exclusiveSet = false;
            column.mixedChangeSet = false;
            column.members = [];
            /*
             * Fake an Event for checkAll.
             * TODO - Split checkAll so it can be called without an Event.
             */
            this.checkAll({
                target: {
                    checked,
                    dataset: {
                        value: colName,
                    },
                },
            });
        }
    },
    /**
     * Header/data action for Check All.
     * Separated to allow the Group Check All to set data without the DOM event
     * penalties.
     * @param {String} colName
     * @param {boolean} checked
     * @return {Object}
     */
    checkAllHeaderActions(colName, checked) {
        const data = this.getProxyColumn(colName);

        // Can't interact if current/future is set
        if (!data.currentFuture) {
            data.allSet = true;
            // Exclusive is based on the check setting.
            data.exclusiveSet = checked;

            // Have to remove mixed when we interact here.
            data.mixedChangeSet = false;
            data.members = [];
        }

        return data;
    },

    /**
     * Action for a specific Check All column
     * The
     * @param event
     */
    checkAll(event) {
        const { checked } = event.target;
        const colName = event.target.dataset.value;
        const data = this.checkAllHeaderActions(colName, checked);
        const $globalSelectAll = this.$(event.target).closest('tr').find('input[type="checkbox"]').first();
        const globalSelectAllChecked = $globalSelectAll.prop('checked');

        // Can't interact if current/future is set
        if (data.currentFuture) {
            return;
        }

        /**
         * Special behavior for the selectAll column, which should hit the columns,
         * not the rows.
         */
        if (colName === 'selectAll') {
            const $me = this.$(event.target);
            const $checkedHeaders = $me.closest('tr')
                .find('input[type="checkbox"]')
                .not($me).not(':disabled');

            $checkedHeaders
                .prop('checked', checked)
                .trigger('change');
        }

        // Push updates to the groups
        this.updateGroupHeaderState(colName);

        const updatedModels = this.grid.collection.models.reduce((acc, cur) => {
            const { attributes } = cur;
            if (attributes[colName] === '') {
                return [
                    ...acc,
                    attributes,
                ];
            }
            return [
                ...acc,
                {
                    ...attributes,
                    ...(attributes.selectAll === '1' && !checked ? { selectAll: '0' } : {}),
                    [colName]: checked ? '1' : '0',
                },
            ];
        }, []);
        this.grid.collection.set(updatedModels);

        // Update the selectAll header when we uncheck any column
        if (globalSelectAllChecked && !checked) {
            $globalSelectAll.prop('checked', false);
        }
    },

    /**
     * Listens for select all for the row
     * @param {Object} row the row model
     * @param {String} allValue the value of the select all
     * @return {*}
     */
    checkAllRow(row, allValue) {
        // Get the row id for use with restricted templates.
        const rowId = row.get('ACCOUNTFILTER');

        // Set any checkboxes we can
        const settable = util.reduce(row.omit('ACCOUNTFILTER', 'ACCOUNTDISPLAY'), (acc, val, key) => {
            // Only applicable items that are set by Current/Future
            const col = this.getProxyColumn(key, false, true, rowId);
            if (val !== '' && (col.currentFuture === false || col.currentFuture === '')) {
                acc[key] = allValue;
            }
            return acc;
        }, {});

        row.set(settable);
    },

    /**
     * We get the colName from the selectAll checkbox, then have to locate the
     * parent group checkbox
     * @param {String} colName
     * @return {*}
     */
    getGroupCheckboxFromColName(colName) {
        let $groupInput = this.$(`[data-cols*="${colName}"]`);

        if (!$groupInput.length) {
            return undefined;
        }

        // Test for multiples, in case one column name is part of another.
        if ($groupInput.length > 1) {
            // filter out the grouped input with the actual colName in its data list
            $groupInput = $groupInput
                .filter((i, el) => util.contains(el.dataset.cols.split(','), colName))
                .first();
        }

        return $groupInput;
    },

    /**
     * Called when we check a single "selectAll" checkbox; we try to update the parent group
     * @param {String} colName
     */
    updateGroupHeaderState(colName) {
        const self = this;
        const $groupCheck = this.getGroupCheckboxFromColName(colName);

        if (!$groupCheck) {
            return;
        }

        // Convert to string in case a column has a numeric name
        const cols = String($groupCheck.data('cols')).split(',');

        // if any are false, then allChecked is false;
        const allChecked = !util.some(cols, thisCol =>
        /*
         * data-value is used for both .cfCheck and .selectAll,
         * so the selector has to be very specific
         */
            self.$(`.selectAll[data-value="${thisCol}"]`).prop('checked') === false);

        $groupCheck.prop('checked', allChecked);
    },

    /**
     *
     * @param {Event} e
     */
    checkTypeGroup(e) {
        // Get the array of individual columns to check or uncheck
        const self = this;

        // Convert to string in case a column has a numeric name
        const cols = String(this.$(e.target).data('cols')).split(',');

        const { checked } = e.target;
        const checkedVal = checked ? '1' : '0';
        const $selAll = this.$('.selectAll');

        // Start with the headers
        util.forEach(cols, (colName) => {
            self.checkAllHeaderActions(colName, checked);
        });

        /*
         * More efficient to cycle the rows and look at the affected values than to
         * cycle the columns.
         */
        this.grid.collection.each((row) => {
            // For each row, set only the affected and applicable columns
            const setKeys = util.reduce(cols, (acc, col) => {
                // Only if the value is enabled on this account
                if (row.get(col) !== '') {
                    acc[col] = checkedVal;
                }
                return acc;
            }, {});
            row.set(setKeys);
        });

        // Get the affected header rows and set their checked state as appropriate.
        $selAll.filter(function () {
            return util.contains(cols, self.$(this).data('value'));
        }).not(':disabled').prop('checked', checked);
    },

    /** ********************** END CHECK EVENTS */

    /** ********************** BEGIN CHECK GRID METHODS */

    /**
     * Runs the "save" proxy pre-processor and returns the result
     */
    extractData() {
        // Won't exist if this view was never rendered
        if (this.grid && this.grid.collection) {
            return this.grid.collection.extractData();
        }
        return undefined;
    },

    getCollectionProxyObject() {
        const self = this;

        return {
            incomingData: {
                load(data) {
                    const affectedColumns = util.filter(
                        self.gridHeaders,
                        gridDataAccess.otherColumnsPredicate.bind(gridDataAccess),
                    );

                    return data.map((rowParam) => {
                        const row = rowParam;
                        const currentMember = row.ACCOUNTFILTER;

                        util.each(affectedColumns, (colHeader) => {
                            const colName = colHeader.fieldName || colHeader.field;
                            const enabled = !util.isNullOrUndefined(row[colName]) && row[colName] !== '';

                            // Avoid the expensive operations if not enabled
                            if (!enabled) {
                                return;
                            }

                            const col = self.getProxyColumn(colName, true) || {};
                            const memberRecord = util.find(
                                col.members,
                                member => member.id === currentMember,
                            );

                            if (col.mixedChangeSet && memberRecord) {
                                // Only if there is an entry do we change it
                                row[colName] = memberRecord.checked ? '1' : '0';
                            } else if (col.exclusiveSet) {
                                // Exclusive members are unchecked
                                row[colName] = memberRecord !== undefined ? '0' : '1';
                            } else if (col.allSet) {
                                // Inclusive members are checked
                                row[colName] = memberRecord !== undefined ? '1' : '0';
                            } else if (memberRecord) {
                                row[colName] = memberRecord.checked ? '1' : '0';
                            }
                        });

                        return row;
                    });
                },
            },

            removeCurrentFuture: {
                save(data) {
                    const orig = this.originalData;
                    const affectedColumns = util.filter(
                        self.gridHeaders,
                        gridDataAccess.otherColumnsPredicate.bind(gridDataAccess),
                    );

                    // Nothing to do if originalData doesn't exist.
                    if (!orig) {
                        return data;
                    }

                    // Cycle over the incoming data
                    return data.map((row, idx) => {
                        const origRow = orig[idx] || {};

                        // Cycle over each of the
                        util.each(affectedColumns, (col) => {
                            // TODO: Swap out for complex selector
                            const colName = col.fieldName || col.field;

                            // colData = self.getProxyColumn(colName, true) || {},
                            const cellData = row[colName];

                            const memberId = row.ACCOUNTFILTER;

                            self.updateColDataRow(
                                colName,
                                memberId,
                                cellData,
                                origRow[colName],
                            );
                        });

                        return row;
                    });
                },
            },
        };
    },

    /**
     *
     * @param {Object} data
     */
    buildGrid(data) {
        /*
         * TODO: This grid logic should be moved to another file for readability and
         * maintenance improvements
         */
        const self = this;

        const tableRegions = util.clone(tableLayout.prototype.regions);
        const Collection = collectionProxyGen();

        const MyGrid = Grid.extend({
            tableHeaderClass: checkGridHeader.extend({
                entityType: self.getEntityType(),
                singleType: self.singleType,
            }),

            tableViewClass: tableLayout.extend({
                regions: tableRegions,
            }),

            calcTotal() {
                if (self.gridData) {
                    this.pagerView.total = self.gridData.totalPages;
                } else {
                    this.total = 0;
                }
            },

            onAfterBuild() {
                self.grid.tableHeader.getProxyColumn = function (colName) {
                    return self.getProxyColumn(colName, true) || {};
                };
            },

            mode: this.mode,
        });

        this.gridHeaders = gridDataAccess.generateColumns.bind(gridDataAccess)(data, this);

        this.gridCollection = new Collection(
            gridDataAccess.getRowData(data, this.permissionsChangedToByGroup),
            {
                proxies: self.getCollectionProxyObject(),
            },
        );
        this.gridCollection.totalCount = data.totalRows;

        this.listenTo(this.gridCollection, 'change:selectAll', this.checkAllRow);

        tableRegions.header = {
            selector: 'thead',
            regionType: adoptRegion,
        };

        this.grid = new MyGrid({
            collection: this.gridCollection,
            el: this.ui.$grid,
            columns: this.gridHeaders,
            paginate: true,
            pageSize: this.getCheckGridPageSize(),
            stickyFooter: false,
            filterable: {
                method: () => {
                    gridDataAccess.sharedFetchAction(self);
                },
            },

            disableDynamicColumnWidths: true,

            pageable: {
                pageSize: this.getCheckGridPageSize(),
                method: util.partial(gridDataAccess.sharedFetchAction, self),
            },
        });

        this.disableSelectAllForUnavailableAccounts();
        this.grid.render();

        // HACK -- adds the grid class to this container to get the filter CSS.
        this.$('[data-region="header"]').addClass('grid');
    },

    /**
     *
     * @return {*}
     */
    loadCurrentFutureData() {
        const self = this;

        if (this.cfLoaded) {
            return Promise.resolve();
        }

        return this.getCheckCFData().then((data) => {
            if (!data || !data.rows || !data.rows.length) {
                return undefined;
            }

            util.chain(data.rows[0].columns)
                .each((col) => {
                    // Build the proxyData object for each enable column.
                    self.checkAllCurrentFuture(col.fieldName, col.fieldValue === '' ? '' : col.fieldValue === '1', true);
                });

            self.cfLoaded = true;
            return self.cfLoaded;
        });
    },

    /**
     *
     * @return {*}
     */
    loadFirstGrid() {
        return this.getCheckData().then((data) => {
            this.gridData = data;
            this.buildGrid(data);
        }, (err) => {
            // TODO: Error handling
            window.console.log(err);
        });
    },

    getUserGroup() {
        const model = this.model.roleModel || this.model.userModel;
        return model.get('USERGROUP');
    },

    getUserId() {
        /*
         * use either the current user ID --OR-- the copy user ID
         * if trying to replicate another user's assignments
         */
        if (this.model.userModel) {
            return this.model.userModel.get('COPYEXISTINGUSER') || this.model.userModel.get('USERID');
        }
        return '';
    },

    getRoleId() {
        if (this.model.roleModel) {
            return this.model.roleModel.get('ROLEID');
        }
        return '';
    },

    getMode() {
        /*
         * will return modify mode if COPY USER scenario is defined
         *   while inserting, so we can get the copied user's assignments
         *   use this.options.mode instead of this.mode, which is set up in initialize
         *   because for TOA Locations this is called before initialize in accountTabs (line #59)
         */
        return (this.model?.userModel?.get('COPYEXISTINGUSER')) ? constants.MODES.VIEW : this.options.mode;
    },

    processFilters(filterCollection) {
        return filterCollection.chain()
            .map((model) => {
                const type = model.get('type');
                const validType = ['string', 'date', 'amount', 'number'];

                if (!util.contains(validType, type)) {
                    return undefined;
                }

                return {
                    fieldName: model.get('field'),
                    fieldValue: [model.get('value')],
                    dataType: type === 'string' ? 'text' : type,
                    operator: type === 'string' ? 'CONTAINS' : model.get('equality'),
                };
            })
        // util.compact isn't on the collection chain, so use reject
            .reject(util.isUndefined)
            .value();
    },

    getSearchFields(grid) {
        let searchFields = [{
            fieldName: 'Usergroup',

            fieldValue: [
                this.getUserGroup(),
            ],

            dataType: 'text',
            operator: '=',
        }, {
            fieldName: 'actionMode',

            fieldValue: [
                this.getMode(),
            ],

            dataType: 'text',
            operator: '=',
        }];

        if (this.model.roleModel) {
            searchFields.push({
                fieldName: 'RoleId',

                fieldValue: [
                    this.options.copyingRole || this.getRoleId(),
                ],

                dataType: 'text',
                operator: '=',
            });
        } else {
            searchFields.push({
                fieldName: 'UserID',

                fieldValue: [
                    this.getUserId(),
                ],

                dataType: 'text',
                operator: '=',
            });
        }

        // If we were passed filters, include them.
        if (grid && grid.filterView && grid.filterView.filtersCollection) {
            const { filtersCollection } = grid.filterView;
            searchFields = searchFields.concat(this.processFilters(filtersCollection));
        }

        return searchFields;
    },

    getCheckRequestData(entityId, grid, startPage, rows) {
        const rowsPerPage = rows || this.getCheckGridPageSize();
        const startRow = startPage ? ((startPage - 1) * rows) + 1 : 0;
        const entitlements = gridDataAccess.getAllEnabledEntitlements(this.getRecords());
        const entitlementData = gridDataAccess.prepEntitlementsForRequest(
            entitlements,
            true,
        );
        const itemData = gridDataAccess.prepInquiryForRequest(entityId
                || this.getEntityID()).concat(entitlementData);

        return {
            startRow,
            rowsPerPage,
            dataOnly: 0,
            searchFields: this.getSearchFields(grid),

            requestParameters: {
                item: itemData,
            },
        };
    },

    getCheckCFData(grid, start, rows) {
        return http.post(
            this.serviceURL,
            this.getCheckRequestData(this.getCFEntityID(), grid, start, rows),
        );
    },

    getCheckData(grid, start, rows) {
        return http.post(
            this.serviceURL,
            this.getCheckRequestData(this.getEntityID(), grid, start, rows),
        );
    },

    getCheckGridPageSize() {
        /**
         * First validate from config Params
         * else take it from constants
         */
        return configParams.get('DEFAULT_UCE2_GRID_SIZE')
            || configParams.get('DEFAULT_GRID_SIZE')
            || constants.DEFAULT_UCE2_GRID_SIZE;
    },

    templateHelpers() {
        return {
            cid: this.cid,
            entityId: this.getEntityID(),
        };
    },

    /** ********************** END CHECK GRID METHODS */

    // HACK: FIXME: TODO: This is  a test
    enableViewBinding: util.noop,

    monitorVisibility: util.noop,
    renderAndRestoreFocus: util.noop,
    restoreFocus: util.noop,
});
