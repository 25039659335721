import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import TextArea from '../RtpCharCountTextArea/RtpCharCountTextArea';
import MessageButtons from '../RtpAddToConversationMessageButtons/RtpAddToConversationMessageButtons';
import AlertRegion from '../RtpAlertRegion/RtpAlertRegion';
import styles from '../RtpAddToConversationMessage/RtpAddToConversationMessage.styles';

const propTypes = {
    children: PropTypes.node,
    title: PropTypes.string.isRequired,
    placeHolderText: PropTypes.string,
    handleSend: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    setMessage: PropTypes.func.isRequired,
    maxLength: PropTypes.number.isRequired,
    rows: PropTypes.number.isRequired,
    isLoading: PropTypes.bool,
    errorMessage: PropTypes.string,
    warningMessage: PropTypes.string,
    invalidErrorMessage: PropTypes.string,
    setInvalidErrorMessage: PropTypes.func,
    // Internal classes hash - do not pass
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const defaultProps = {
    placeHolderText: '',
    isLoading: false,
    errorMessage: '',
    warningMessage: '',
    invalidErrorMessage: '',
    setInvalidErrorMessage: null,
    children: null,
};

const AddResponseMessage = ({
    children, title, placeHolderText, handleSend, handleCancel, message, setMessage,
    maxLength, rows, isLoading, errorMessage, warningMessage, invalidErrorMessage,
    setInvalidErrorMessage, classes,
}) => {
    const newMessageRef = useRef();

    useEffect(() => {
        if (newMessageRef.current && newMessageRef.current.scrollIntoView) {
            newMessageRef.current.scrollIntoView();
        }
    }, [newMessageRef]);

    const newMessageClassName = `newMessage ${classes.addToConversationMessage}`;

    return (
        <div ref={newMessageRef} className={newMessageClassName}>
            <p className={classes.centeredHeading}><span>{title}</span></p>
            {children}
            <TextArea
                name="RESPONSE"
                placeHolderText={placeHolderText}
                rows={rows}
                maxLength={maxLength}
                message={message}
                setMessage={setMessage}
                invalidErrorMessage={invalidErrorMessage}
                setInvalidErrorMessage={setInvalidErrorMessage}
            />
            <MessageButtons
                isLoading={isLoading}
                handleSend={handleSend}
                handleCancel={handleCancel}
            />
            <AlertRegion warningMessage={warningMessage} errorMessage={errorMessage} />
        </div>
    );
};

AddResponseMessage.propTypes = propTypes;
AddResponseMessage.defaultProps = defaultProps;

export default withStyles(styles)(AddResponseMessage);
