import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/table';
import React, {
    useCallback, useContext, useState, useRef, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@glu/theming';
import { EyeAshIcon, PencilAshIcon } from '@glu/icons-react';
import locale from '@glu/locale';
import { Editor } from '@tinymce/tinymce-react';
import ContentEditorContext from './ContentEditorContext';
import { replaceParameterTokens } from './util';
import getContentStyles from './getContentStyles';
import getVariablePlugin from './getVariablePlugin';
import getHorizontalRulePlugin from './getHorizontalRulePlugin';
import CharacterCounter from '../CharacterCounter/CharacterCounter';
import useStyles from './ContentEditor.styles';

const formatTextContent = textContent => textContent.replace(/\n/g, '<br>');

/**
 * Wrap the content with the display class for text preview
 * @param {string} content
 * @returns {string}
 */
const wrapTextForPreview = content => `<div class="text-preview">${content}</div>`;

const sanitizeBeforeChange = (content, contentType) => {
    // Remove any script tags
    let sanitized = content.replaceAll(/&lt;[\s\S]*?&gt;/g, '');
    /*
     * If this is text (SMS) content, we need to preserve the variable token before calling
     * onChange on the form so we don't lose the token when preserving plain text of content
     */
    if (contentType === 'text') {
        // Convert line break element before removing html
        sanitized = sanitized.replace(/<br( \/)?>/g, '\n');
        // TinyMCE wrap elements in paragraph block, so convert closing tag to line break
        sanitized = sanitized.replace(/<\/p>/g, '\n');
        // replace any additional HTML in the string
        sanitized = sanitized.replace(/(<([^>]+)>)/gi, '');
    }
    return sanitized;
};

const getToolBar = (contentType, isPreviewOnly) => {
    if (isPreviewOnly) {
        return '';
    }
    const htmlToolbar = 'fontselect fontsizeselect forecolor backcolor | bold italic underline | alignleft aligncenter alignright alignjustify | table hr | bullist numlist outdent indent | link unlink | undo redo';
    const textToolbar = 'undo redo';
    return contentType === 'html' ? htmlToolbar : textToolbar;
};

const ContentEditor = ({
    content,
    contentType,
    onChange,
    pillMap,
    hidePreview,
    maxCharacterCount,
}) => {
    const theme = useTheme();
    const classes = useStyles();
    const {
        editor,
        isDisabled,
        isPreview,
        isPreviewOnly,
        isShown,
        setEditor,
        setIsPreview,
    } = useContext(ContentEditorContext);
    const storedValue = useRef('');
    const [characterCount, setCharacterCount] = useState(0);
    const [characterCounterHasWarning, setCharacterCounterHasWarning] = useState(false);
    const characterCounterLabel = useRef(locale.get('CMAINT.alert.progressText'));
    const characterCounterWarningText = useRef(locale.get('CMAINT.alert.progressTextWarning'));

    const handlePaste = useCallback((plugin, args) => {
        // eslint-disable-next-line
        args.content = args.target.sanitizeContent(args.content);

        if (maxCharacterCount) {
            const currentContentLength = args.target.getSanitizedContent({ format: 'text' }).length;
            const pastedContentLength = args.content.length;
            if (currentContentLength + pastedContentLength > maxCharacterCount) {
                const availableCharacters = maxCharacterCount - currentContentLength;
                const argsCopy = args;

                /*
                 * 1. If the pasted string contains only plain text, paste up to the allowed amount
                 * 2. If the pasted string is a token OR contains HTML, do not paste anything
                 *    and set warning
                 */
                const isToken = /^{{/.test(argsCopy.content) && /}}$/.test(argsCopy.content);
                const containsHtml = /<\/?[a-z][\s\S]*>/i.test(argsCopy.content);
                if (isToken || containsHtml) {
                    argsCopy.content = '';
                    setCharacterCounterHasWarning(true);
                } else {
                    argsCopy.content = argsCopy.content.substr(0, availableCharacters - 1);
                }
            } else {
                setCharacterCounterHasWarning(false);
            }
        }
    }, [maxCharacterCount]);

    const handleSetup = (editorInstance) => {
        // you have to use the window instance for some reason
        window.tinymce.PluginManager.add('variable', getVariablePlugin({ mapper: pillMap }));
        window.tinymce.PluginManager.add('horizontalRule', getHorizontalRulePlugin());

        editorInstance.on('init', () => {
            // make sure character count is leveraging existing content length
            setCharacterCount(editorInstance.getSanitizedContent({ format: 'text' }).length);
        });

        if (maxCharacterCount) {
            editorInstance.on('keydown', (e) => {
                const allowedKeys = [8, 13, 16, 17, 18, 20, 33, 34, 35, 36, 37, 38, 39, 40, 46];
                const isTooLarge = editorInstance.getSanitizedContent({ format: 'text' }).length > (maxCharacterCount - 1);
                if (!allowedKeys.includes(e.which) && isTooLarge) {
                    e.preventDefault();
                    e.stopPropagation();
                } else {
                    setCharacterCounterHasWarning(false);
                }
            });
        }

        editorInstance.on('SwitchMode', (e) => {
            const { mode } = e;
            if (mode === 'readonly') {
                const currentContent = editorInstance.getSanitizedContent();
                // Store current content before replacing it
                storedValue.current = currentContent;
                let replacedContent = replaceParameterTokens(currentContent, pillMap);
                if (contentType === 'text') {
                    replacedContent = wrapTextForPreview(replacedContent);
                }
                editorInstance.setContent(replacedContent);
            } else {
                editorInstance.setContent(storedValue.current);
            }
        });

        editorInstance.on('beforeExecCommand', (e) => {
            // The commands we want to permit formatting noneditable items for
            const textFormatCommands = [
                'FontSize',
                'FontName',
                'mceToggleFormat',
                'mceApplyTextcolor',
                'mceRemoveTextcolor',
            ];
            if (textFormatCommands.includes(e.command)) {
                // Find all elements that have contenteditalbe attribute set to false
                const params = editorInstance.dom.$('span.variable[contenteditable="false"]:not(.dark)');
                // Set contenteditable attribute to null so styles can be applied
                editorInstance.dom.setAttrib(params, 'contenteditable', 'null');
            }
        });

        editorInstance.on('execCommand', () => {
            // Find all elements that have contenteditalbe attribute previously set to null
            const params = editorInstance.dom.$('span.variable[contenteditable="null"]:not(.dark)');
            /*
             * Set contenteditable attribute to false so styles can not be applied.
             * This attribute must be false if the variables are to be draggable
             */
            editorInstance.dom.setAttrib(params, 'contenteditable', 'false');
        });

        // When dropping the pill, need to take into account maxCharacterCount if around
        editorInstance.on('beforeSetContent', (e) => {
            // if this is a parameter, make sure we're taking into account maxCharacterCount
            if (/^{{/.test(e.content) && /}}$/.test(e.content)) {
                handlePaste(undefined, e);
            }
        });

        setEditor(editorInstance);
    };

    const setPreview = useCallback((e) => {
        e.preventDefault();
        if (!isPreview) {
            editor.setMode('readonly');
            setIsPreview(true);
        } else {
            editor.setMode('design');
            setIsPreview(false);
        }
    }, [isPreview, setIsPreview, editor]);

    const handleChange = useCallback(() => {
        if (editor.mode.get() === 'readonly') {
            return;
        }

        let newContent = editor.getSanitizedContent();

        const textContent = editor.getSanitizedContent({ format: 'text' });
        setCharacterCount(textContent.length);

        newContent = sanitizeBeforeChange(newContent, contentType);
        onChange(newContent);
    }, [contentType, editor, onChange]);

    const toolbarToUse = useMemo(
        () => getToolBar(contentType, isPreviewOnly),
        [contentType, isPreviewOnly],
    );

    const formattedContent = contentType === 'html'
        ? content : formatTextContent(content);
    const showPreviewIcon = !hidePreview && !isPreviewOnly;
    return (
        <div className={`${classes.root} ${!isShown && classes.hidden}`}>
            {showPreviewIcon && isPreview && (
                <PencilAshIcon
                    actionable
                    onClick={setPreview}
                    className={classes.previewButton}
                />
            )}
            {showPreviewIcon && !isPreview && (
                <EyeAshIcon
                    actionable
                    onClick={setPreview}
                    className={classes.previewButton}
                />
            )}
            <Editor
                onEditorChange={handleChange}
                initialValue={formattedContent}
                init={{
                    height: 500,
                    menubar: false,
                    toolbar: false,
                    statusbar: false,
                    content_style: getContentStyles(theme, isPreviewOnly),
                    skin_url: `${window.Bottomline.assetRoot}/dbiqe-skin`,
                    setup: handleSetup,
                    paste_preprocess: handlePaste,
                    fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt',
                    table_default_styles: {
                        'margin-block-start': '1em',
                        'margin-block-end': '1em',
                    },
                    editorReadOnly: isPreview,
                    extended_valid_elements: 'span[*],svg[*],g[*],path[*]',
                }}
                plugins={[
                    'variable',
                    'lists',
                    'link',
                    'horizontalRule',
                    'table',
                    'paste',
                ]}
                disabled={isDisabled}
                toolbar={toolbarToUse}
            />
            {!isPreviewOnly && !isPreview && (
                <CharacterCounter
                    isCountdown
                    count={characterCount}
                    label={characterCounterLabel.current}
                    maxCount={maxCharacterCount}
                    hasWarning={characterCounterHasWarning}
                    warningLabel={characterCounterWarningText.current}
                />
            )}
        </div>
    );
};

ContentEditor.propTypes = {
    /** Initial value for content editor text area */
    content: PropTypes.string.isRequired,
    /** Content Type is HTML or Text */
    contentType: PropTypes.oneOf(['html', 'text']),
    /** Maximum character count */
    maxCharacterCount: PropTypes.number,
    /** Function to execute when changes are made in the editor */
    onChange: PropTypes.func.isRequired,
    /** Map of available parameter pills */
    pillMap: PropTypes.objectOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        pillGroup: PropTypes.string.isRequired,
    }).isRequired).isRequired,
    hidePreview: PropTypes.bool,
};

ContentEditor.defaultProps = {
    contentType: 'html',
    hidePreview: false,
    maxCharacterCount: undefined,
};

export default ContentEditor;
