var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"modal-header\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showCloseButton") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":9,"column":27}}})) != null ? stack1 : "")
    + "                    <h2 class=\"modal-title\" id=\"modal-title\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"dialogIcon") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":61},"end":{"line":10,"column":129}}})) != null ? stack1 : "")
    + "<span data-hook=\"getTitle\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":10,"column":156},"end":{"line":10,"column":167}}}) : helper)))
    + "</span></h2>\n                </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <button type=\"button\" class=\"close\" data-action=\"close\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.close",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":92},"end":{"line":8,"column":117}}}))
    + "\"><span class=\"icon-close\"></span></button>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"icon-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"dialogIcon") || (depth0 != null ? lookupProperty(depth0,"dialogIcon") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"dialogIcon","hash":{},"data":data,"loc":{"start":{"line":10,"column":97},"end":{"line":10,"column":113}}}) : helper)))
    + "\"></span>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-backdrop in\"></div>\n<div class=\"modal\">\n    <div class=\"modal-dialog glu-dialog dialog-"
    + alias4(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":3,"column":47},"end":{"line":3,"column":57}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"modalClass") || (depth0 != null ? lookupProperty(depth0,"modalClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modalClass","hash":{},"data":data,"loc":{"start":{"line":3,"column":58},"end":{"line":3,"column":72}}}) : helper)))
    + "\">\n        <div class=\"modal-content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":12,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"modal-body\" id=\"modal-body\">\n                <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"message") || (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message","hash":{},"data":data,"loc":{"start":{"line":14,"column":19},"end":{"line":14,"column":34}}}) : helper))) != null ? stack1 : "")
    + "</p>\n            </div>\n            <div class=\"modal-footer\">\n                "
    + ((stack1 = (lookupProperty(helpers,"footerButtons")||(depth0 && lookupProperty(depth0,"footerButtons"))||alias2).call(alias1,depth0,{"name":"footerButtons","hash":{},"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":17,"column":40}}})) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});