var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "first-panel";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel panel-primary\" role=\"tablist\">\n    <div class=\"panel-heading parent-panel-heading "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFirst") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":51},"end":{"line":2,"column":84}}})) != null ? stack1 : "")
    + "\" role=\"tab\" id=\"panel-heading-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":2,"column":115},"end":{"line":2,"column":122}}}) : helper)))
    + "\">\n		<h3 class=\"panel-title\">\n            <span class=\"account-number\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.noDataBasedOnSearch",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":41},"end":{"line":4,"column":77}}}))
    + "</span>\n		</h3>\n    </div>\n</div>\n";
},"useData":true});