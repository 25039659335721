var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"panel-heading\" role=\"tab\" id=\"heading"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":2,"column":53},"end":{"line":2,"column":60}}}) : helper)))
    + "\">\n        <h3 class=\"panel-title\">\n            <a role=\"button\" data-toggle=\"collapse\" data-parent=\"#accordion\" href=\"#collapse"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":4,"column":92},"end":{"line":4,"column":99}}}) : helper)))
    + "\" aria-expanded=\"false\" aria-controls=\"collapse"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":4,"column":146},"end":{"line":4,"column":153}}}) : helper)))
    + "\" class=\"collapsed\">\n                <span class=\"indicator-icon\"></span>Addenda "
    + alias4(((helper = (helper = lookupProperty(helpers,"ui_sequenceNumber") || (depth0 != null ? lookupProperty(depth0,"ui_sequenceNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ui_sequenceNumber","hash":{},"data":data,"loc":{"start":{"line":5,"column":60},"end":{"line":5,"column":81}}}) : helper)))
    + " - "
    + alias4((lookupProperty(helpers,"valueFor")||(depth0 && lookupProperty(depth0,"valueFor"))||alias2).call(alias1,"RMT_REFNUMBERQUALIFIER",{"name":"valueFor","hash":{},"data":data,"loc":{"start":{"line":5,"column":84},"end":{"line":5,"column":121}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"RMT_REFNUMBER") || (depth0 != null ? lookupProperty(depth0,"RMT_REFNUMBER") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RMT_REFNUMBER","hash":{},"data":data,"loc":{"start":{"line":5,"column":122},"end":{"line":5,"column":139}}}) : helper)))
    + "\n            </a>\n        </h3>\n    </div>\n    <div id=\"collapse"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":9,"column":21},"end":{"line":9,"column":28}}}) : helper)))
    + "\" class=\"panel-collapse collapse\" role=\"tabpanel\" aria-labelledby=\"heading"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":9,"column":102},"end":{"line":9,"column":109}}}) : helper)))
    + "\">\n        <div class=\"panel-body\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":28,"column":39},"end":{"line":28,"column":47}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":28,"column":49},"end":{"line":28,"column":58}}}) : helper)))
    + "</option>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row\">\n           <div class=\"form-column col-md-6\">\n               <button type=\"button\" class=\"btn btn-tertiary\" name=\"ADDADDENDA\" id=\"ADDADDENDA\">\n                   "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"common.addenda.add.to.bene",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":168,"column":19},"end":{"line":168,"column":58}}}))
    + "\n               </button>\n           </div>\n        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row\">\n            <div class=\"form-column col-md-6 button-container\">\n                <button type=\"button\" data-action=\"saveChanges\" class=\"btn btn-secondary btn-sm\"><span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.save.changes",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":177,"column":115},"end":{"line":177,"column":147}}}))
    + "</span></button>\n                <button type=\"button\" data-action=\"reset\" class=\"btn btn-secondary btn-sm\"><span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.reset",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":178,"column":109},"end":{"line":178,"column":134}}}))
    + "</span></button>\n                <button type=\"button\" data-action=\"delete\" class=\"btn btn-secondary btn-sm\"><span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.delete",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":179,"column":110},"end":{"line":179,"column":136}}}))
    + "</span></button>\n            </div>\n        </div>\n    </div>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSavedAddenda") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":11,"column":7}}})) != null ? stack1 : "")
    + "\n    <h3 class=\"form-group-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.addenda.remittance.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":33},"end":{"line":13,"column":76}}}))
    + "</h3>\n\n    <div class=\"row\">\n\n        <div class=\"form-column inline-fields\">\n\n            <div class=\"col-md-"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"RMT_REFNUMBERQUALIFIER") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                <label for=\"RMT_REFNUMBERQUALIFIER\">"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"RMT_REFNUMBERQUALIFIER") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + "</label>\n                <select class=\"form-control\"\n                    data-bind=\"model\"\n                    data-hook=\"getRMT_REFNUMBERQUALIFIER\"\n                    id=\"RMT_REFNUMBERQUALIFIER\"\n                    name=\"RMT_REFNUMBERQUALIFIER\">\n                    <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"RMT_REFNUMBERQUALIFIER") : stack1)) != null ? lookupProperty(stack1,"choices") : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":20},"end":{"line":29,"column":29}}})) != null ? stack1 : "")
    + "                </select>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"RMT_REFNUMBERQUALIFIER\"></span>\n            </div>\n\n            <div class=\"col-md-"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"RMT_REFNUMBER") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                <label for=\"RMT_REFNUMBER\">"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"RMT_REFNUMBER") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + "</label>\n                <input type=\"text\" class=\"form-control\" data-bind=\"model\" id=\"RMT_REFNUMBER\" name=\"RMT_REFNUMBER\" maxlength=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"RMT_REFNUMBER") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"RMT_REFNUMBER\"></span>\n            </div>\n\n            <div class=\"col-md-"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"PAIDINVOICEAMOUNT") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                <label for=\"PAIDINVOICEAMOUNT\">"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"PAIDINVOICEAMOUNT") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + "</label>\n                <input type=\"text\" class=\"form-control\" data-bind=\"model\" id=\"PAIDINVOICEAMOUNT\" name=\"PAIDINVOICEAMOUNT\" maxlength=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"PAIDINVOICEAMOUNT") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"PAIDINVOICEAMOUNT\"></span>\n            </div>\n\n            <div class=\"col-md-"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"TOTALINVOICEAMOUNT") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                <label for=\"TOTALINVOICEAMOUNT\">"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"TOTALINVOICEAMOUNT") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + "</label>\n                <input type=\"text\" class=\"form-control\" data-bind=\"model\" id=\"TOTALINVOICEAMOUNT\" name=\"TOTALINVOICEAMOUNT\" maxlength=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"TOTALINVOICEAMOUNT") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"TOTALINVOICEAMOUNT\"></span>\n            </div>\n\n            <div class=\"col-md-"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"TERMSDISCOUNT") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                <label for=\"TERMSDISCOUNT\">"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"TERMSDISCOUNT") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + "</label>\n                <input type=\"text\" class=\"form-control\" data-bind=\"model\" id=\"TERMSDISCOUNT\" name=\"TERMSDISCOUNT\" maxlength=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"TERMSDISCOUNT") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"TERMSDISCOUNT\"></span>\n            </div>\n\n        </div>\n\n    </div>\n\n    <div class=\"row\">\n        <h3 class=\"form-group-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.addenda.reference.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":63,"column":37},"end":{"line":63,"column":79}}}))
    + "</h3>\n        <div class=\"form-column inline-fields\">\n\n            <div class=\"col-md-"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"REF_REFNUMBERQUALIFIER") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                <label for=\"REF_REFNUMBERQUALIFIER\">"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"REF_REFNUMBERQUALIFIER") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + "</label>\n                <select class=\"form-control\"\n                    data-bind=\"model\"\n                    data-hook=\"getREF_REFNUMBERQUALIFIER\"\n                    id=\"REF_REFNUMBERQUALIFIER\"\n                    name=\"REF_REFNUMBERQUALIFIER\">\n                    <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"REF_REFNUMBERQUALIFIER") : stack1)) != null ? lookupProperty(stack1,"choices") : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":20},"end":{"line":76,"column":29}}})) != null ? stack1 : "")
    + "                </select>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"REF_REFNUMBERQUALIFIER\"></span>\n            </div>\n\n            <div class=\"col-md-"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"REF_REFNUMBER") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                <label for=\"REF_REFNUMBER\">"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"REF_REFNUMBER") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + "</label>\n                <input type=\"text\" class=\"form-control\" data-bind=\"model\" id=\"REF_REFNUMBER\" name=\"REF_REFNUMBER\" maxlength=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"REF_REFNUMBER") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"REF_REFNUMBER\"></span>\n            </div>\n\n            <div class=\"col-md-"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"PAYMENTDESCRIPTION") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                <label for=\"PAYMENTDESCRIPTION\">"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"PAYMENTDESCRIPTION") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + "</label>\n                <input type=\"text\" class=\"form-control\" data-bind=\"model\" id=\"PAYMENTDESCRIPTION\" name=\"PAYMENTDESCRIPTION\" maxlength=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"PAYMENTDESCRIPTION") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"PAYMENTDESCRIPTION\"></span>\n            </div>\n\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <h3 class=\"form-group-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.addenda.date.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":97,"column":37},"end":{"line":97,"column":74}}}))
    + "</h3>\n        <div class=\"form-column inline-fields\">\n\n            <div class=\"col-md-"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"DAT_REFNUMBERQUALIFIER") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                <label for=\"DAT_REFNUMBERQUALIFIER\">"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"DAT_REFNUMBERQUALIFIER") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + "</label>\n                <select class=\"form-control\"\n                    data-bind=\"model\"\n                    data-hook=\"getDAT_REFNUMBERQUALIFIER\"\n                    id=\"DAT_REFNUMBERQUALIFIER\"\n                    name=\"DAT_REFNUMBERQUALIFIER\">\n                    <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"DAT_REFNUMBERQUALIFIER") : stack1)) != null ? lookupProperty(stack1,"choices") : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":108,"column":20},"end":{"line":110,"column":29}}})) != null ? stack1 : "")
    + "                </select>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"DAT_REFNUMBERQUALIFIER\"></span>\n            </div>\n\n            <div class=\"col-md-"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"DAT_REFNUMBER") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                <label for=\"DAT_REFNUMBER\">"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"DAT_REFNUMBER") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + "</label>\n                <input class=\"input-date\"\n                    data-bind=\"model\"\n                    data-hook=\"getDAT_REFNUMBER\"\n                    id=\"DAT_REFNUMBER\"\n                    name=\"DAT_REFNUMBER\"\n                    maxlength=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"DAT_REFNUMBER") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\"\n                    type=\"text\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"DAT_REFNUMBER\"></span>\n            </div>\n\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <h3 class=\"form-group-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.addenda.adjustment.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":131,"column":37},"end":{"line":131,"column":80}}}))
    + "</h3>\n        <div class=\"form-column inline-fields\">\n\n            <div class=\"col-md-"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"AMOUNTTOADJ") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                <label for=\"AMOUNTTOADJ\">"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"AMOUNTTOADJ") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + "</label>\n                <input type=\"text\" class=\"form-control\" data-bind=\"model\" id=\"AMOUNTTOADJ\" name=\"AMOUNTTOADJ\" data-hook=\"getAmountToAdj\" maxlength=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"AMOUNTTOADJ") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"AMOUNTTOADJ\"></span>\n            </div>\n\n            <div class=\"col-md-"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"ADJREASONCODE") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                <label for=\"ADJREASONCODE\">"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"ADJREASONCODE") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + "</label>\n                <select class=\"form-control\"\n                    data-bind=\"model\"\n                    data-hook=\"getADJREASONCODE\"\n                    id=\"ADJREASONCODE\"\n                    name=\"ADJREASONCODE\">\n                    <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"ADJREASONCODE") : stack1)) != null ? lookupProperty(stack1,"choices") : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":20},"end":{"line":150,"column":29}}})) != null ? stack1 : "")
    + "                </select>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"ADJREASONCODE\"></span>\n            </div>\n\n            <div class=\"col-md-"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"ADJDESCRIPTION") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                <label for=\"ADJDESCRIPTION\">"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"ADJDESCRIPTION") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + "</label>\n                <input type=\"text\" class=\"form-control\" data-bind=\"model\" id=\"ADJDESCRIPTION\" name=\"ADJDESCRIPTION\" maxlength=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"ADJDESCRIPTION") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"ADJDESCRIPTION\"></span>\n            </div>\n\n        </div>\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFresh") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":164,"column":0},"end":{"line":172,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSavedAddenda") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":174,"column":0},"end":{"line":184,"column":7}}})) != null ? stack1 : "");
},"useData":true});