export default ({
    'REPORTING/btrExport': 'rptDownloadWorkspace',
    'REPORTING/report': 'viewReport',
    'REPORTING/viewReport': 'viewReport',
    'REPORTING/viewLegacyReport': 'viewLegacyReport',
    'REPORTING/audit': 'auditInfoWorkspace',
    'REPORTING/legacyReports': 'rptLegacyReportsWorkspace',
    'REPORTING/balanceAndTransactionReporting': 'rptBTRWorkspace',
    'REPORTING/reportsManagement': 'rptReportManagementWorkspace',
    'REPORTING/lockbox/reports': 'rptLockboxSummaryReportWorkspace',
    'REPORTING/lockbox/reports/details(/)(:fromBack)': 'rptLockboxTransactionDetails',
    'REPORTING/lockbox/reports/envelopeDetails(/)(:fromBack)': 'rptLockboxEnvelopeDetails',
    'REPORTING/lockbox/reports/checkImage': 'rptLockboxCheckImage',
    'REPORTING/imageSearch': 'imageSearchWorkspace',
    'REPORTING/lockbox/remitters': 'rptListRemitters',
    'REPORTING/lockbox/fimport': 'rptRemitterFimport',
    'REPORTING/gireStatementSearch': 'rptEStatementSearchWorkspace',
    'REPORTING/viewExportDetail': 'viewExportDetail',
    'REPORTING/ctrlDisbursementReport': 'rptControlDisbursementReport',
    'REPORTING/ctrlDisbursementReport/viewImage': 'rptShowControlDisbursementImage',
    'REPORTING/lockboxAvailability': 'rptLockboxAvailabilityWorkspace',
    'REPORTING/lockboxAvailability/accountDetails': 'rptLockboxAvailabilityDetails',
    'REPORTING/pmxReports': 'rptPmxReportsWorkspace',
    'REPORTING/cff/forecast': 'cffWidgetForecast',
    'REPORTING/cff/transactions': 'cffWidgetTrans',
    'REPORTING/cff/settings': 'cffWidgetSettings',
    'REPORTING/cff/planning': 'cffWidgetSettings',

    // DEEPLINKS
    'REPORTING/pmxReportsWidget': 'pmxReportsWidget',
    'REPORTING/pmxVendorNetworkWidget': 'pmxVendorNetworkWidget',
    'REPORTING/showImageSearch': 'imageSearch',
    'REPORTING/controlledDisbursementReport': 'rptCtrlDisbursementReport',
    'REPORTING/reportsListViewInquiry': 'listReports',
    'REPORTING/lockbox': 'rptLockboxSummaryReport',
    'REPORTING/legacyReportsList': 'rptLegacyReportsList',
    'REPORTING/download': 'rptDownload',
    'REPORTING/eStatement': 'rptEStatementSearch',
    'REPORTING/notentitled': 'rptNotEntitled',
    'REPORTING/lockbox/availability': 'rptLockboxAvailability',
});
