import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import scroll from 'common/util/scroll';
import Alert from '@glu/alerts';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import store from 'system/utilities/cache';
import CollectionView from '@glu/core/src/collectionView';
import loadingWidgetTmpl from 'common/templates/loadingWidget.hbs';
import PaymentLimitsModel from 'app/administration/models/segmentation/paymentLimitsModel';
import paymentLimitsItemView from './paymentLimitsItemView';
import paymentLimitsTmpl from './paymentLimits.hbs';

export default Layout.extend({
    className: 'segmentation segmentation-add',
    template: paymentLimitsTmpl,
    loadingTemplate: loadingWidgetTmpl,

    regions: {
        alertRegion: '.alert-region',
    },

    ui: {
        $submitBtn: '[data-hook="getSubmitBtn"]',
        $navTabs: '[data-hook="getNavTabs"]',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
    },

    events: {
        'click @ui.$submitBtn': 'save',
    },

    appEvents: {
        'paymentLimits.toggleValidationErrors': 'toggleValidationErrors',
    },

    initialize(options) {
        this.paymentLimitsCollection = options.paymentLimitsCollection;

        this.paymentLimitsCollectionView = new CollectionView({
            collection: this.paymentLimitsCollection,
            itemView: paymentLimitsItemView,
        });

        this.isEditMode = store.get('segmentModify');

        this.loadViewRequirements();
    },

    modelEvents: {
        invalid: 'scrollToError',
    },

    onRender() {
        this.setupListeners();
        this.displayLeavePageWarning();
    },

    /**
     * Set up custom event listeners
     */
    setupListeners() {
        this.listenTo(this.paymentLimitsCollection, 'change', () => {
            this.changed = true;
        });
    },

    /**
     * retrieve payment limits data and set initial vars
     */
    loadViewRequirements() {
        if (this.isLoading) {
            return undefined;
        }
        this.segmentName = store.get('segmentName') || locale.get('segments.paymentLimits.defaultValue');
        this.editMode = store.get('segmentModify') || false;
        this.isLoading = true;

        return this.getPaymentLimitsCollection().then((results) => {
            // create a new model for results.section -> paymentTypes -> limits
            const models = this.createModels(results);

            this.paymentLimitsCollection.add(models);
            this.setSectionLabels();
            this.setHasLoadedRequiredData(true);
            this.isLoading = false;
            this.render();

            this.paymentLimitsItemsRegion.show(this.paymentLimitsCollectionView);
        }).catch((err) => {
            this.alertRegion.show(Alert.negative(err));
        });
    },

    /**
     * Create model from paymentType data
     * @param  {Object} [results={}] Payment limit server data
     * @return {Object} array of models from server data
     */
    createModels(results = {}) {
        return util.flatten(util.chain(results.sections)
            .map((section) => {
                const attributes = util.pick(
                    section,
                    'sectionLabel',
                    'groupLimit',
                );

                return section.paymentTypes
                    .map(paymentType => util.extend({}, attributes, paymentType));
            })
            .flatten(true)
            .map(paymentType => this.makeModelFromLimit(paymentType))
            .value());
    },

    /**
     * ©
     * Create model from paymentType data
     * @param  {object} paymentType paymentType data filtered from server data
     * @return {array} Array with newly instantiated model(s)
     */
    makeModelFromLimit(paymentType) {
        const modelArray = [];

        // if groupLimit exists then create a model and add it to the array to return
        if (!util.isEmpty(paymentType.groupLimit)
                 && paymentType.groupLimit.restrictionSetId !== store.get('restrictionSetId')) {
            const groupLimitRow = util.pick(
                paymentType,
                'sectionLabel',
                'paymentTypeLabel',
                'productCode',
                'functionCode',
                'typeCode',
                'subTypeCode',
                'actionMode',
                'groupLimit',
            );

            // place object in an array
            groupLimitRow.groupLimit = [groupLimitRow.groupLimit];

            modelArray.push(new PaymentLimitsModel(groupLimitRow));
            store.set('restrictionSetId', paymentType.groupLimit.restrictionSetId);
        }

        const limitRow = util.pick(
            paymentType,
            'sectionLabel',
            'paymentTypeLabel',
            'productCode',
            'functionCode',
            'typeCode',
            'subTypeCode',
            'actionMode',
            'limits',
        );

        limitRow.limits = util.sortBy(limitRow.limits, limit => limit.displayOrder);

        modelArray.push(new PaymentLimitsModel(limitRow));

        return modelArray;
    },

    /**
     * loop through all models in collection in order and determine if
     * the current model's section label matches the previous
     */
    setSectionLabels() {
        let previousModelSectionLabel = '';

        /*
         * loop through all models in collection in order and determine if
         * the current model's section label matches the previous
         */
        util.map(this.paymentLimitsCollection.models, (model) => {
            if (model.get('sectionLabel') === previousModelSectionLabel) {
                model.set('sectionLabel', null);
            } else {
                previousModelSectionLabel = model.get('sectionLabel');
            }
        });
    },

    /**
     * Hit service to retrieve payment limits data
     * @return {Promise} Payment limits data retrieved from server
     */
    getPaymentLimitsCollection() {
        return this.paymentLimitsCollection.sync('READ');
    },

    /**
     * Warns user that entered data will be lost if they leave page without saving
     */
    displayLeavePageWarning() {
        this.ui.$navLinks.on('click', (e) => {
            e.preventDefault();
            if (this.$(e.target).parent('li').hasClass('is-active')) {
                return;
            }
            const path = this.$(e.target).attr('href');

            if (window.innerWidth < 768) {
                this.ui.$navTabs.toggleClass('is-open');
            }

            if (!util.isEmpty(path) && path !== '#' && this.changed) {
                dialog.confirm(
                    locale.get('segment.confirm.leavepage.warning'), locale.get('segment.confirm.edit.heading'),
                    (ok) => {
                        if (ok) {
                            this.navigateTo(path);
                        }
                    },
                );
            } else {
                // no changes so just go there.
                this.navigateTo(path);
            }
        });
    },

    /**
     * Save current Payment Limits
     * @return {[type]} [description]
     */
    save() {
        if (!this.toggleValidationErrors()) {
            return;
        }
        this.paymentLimitsCollection.sync('SAVE').then(() => {
            const localeKey = this.editMode ? 'administration.segmentation.update.success' : 'administration.segmentation.add.success';
            const message = locale.get(localeKey, this.segmentName);

            // on successful save
            store.set('admin:segmentation:add', message);

            this.cancel();
        }).catch((err) => {
            this.alertRegion.show(Alert.negative(err.responseJSON.message));
        });
    },

    /**
     * When user cancels out of screen reset corresponding store attributes
     * and return to market segmentation list view
     * @return {[type]} [description]
     */
    cancel() {
        store.unset(
            'segmentName',
            {
                silent: true,
            },
        );
        store.unset(
            'segmentModify',
            {
                silent: true,
            },
        );
        store.unset(
            'restrictionSetId',
            {
                silent: true,
            },
        );
        this.navigateTo('/SEGMENTATION/viewSegments');
    },

    back() {
        this.navigateTo('/SEGMENTATION/addProfile');
    },

    /**
     * Scroll to error when triggered
     */
    scrollToError() {
        util.defer(() => {
            scroll.scrollToFirstError();
        });
    },

    /**
     * Check if there are any error messages which would indicate whether
     * the form is valid or not
     * @param  {array} messages array of validation messages
     * @return {boolean} Form validity
     */
    checkValid(messages) {
        const hasErrors = messages.length > 0;
        return !hasErrors;
    },

    /**
     * Show errors in alert view
     */
    toggleValidationErrors() {
        const messages = this.createValidationMessages();

        // if there are no messages then close alert view
        if (this.checkValid(messages)) {
            this.alertRegion.close();
            return true;
        }

        this.alertRegion.show(Alert.negative(
            null,
            {
                title: locale.get('segments.paymentLimits.validationErrorsHeader'),
                bullets: messages,
            },
        ));

        return false;
    },

    /**
     * Form error message array
     * @return {array} Array of error messages
     */
    createValidationMessages() {
        const validationMessages = [{
            type: 'TRANSACTION',
            message: locale.get('segment.paymentLimits.transactionValidationErrorMessage'),
        }, {
            type: 'BATCH',
            message: locale.get('segment.paymentLimits.batchValidationErrorMessage'),
        }, {
            type: 'DAILY',
            message: locale.get('segment.paymentLimits.dailyValidationErrorMessage'),
        }, {
            type: 'GROUPDAILY',
            message: locale.get('segment.paymentLimits.groupDailyValidationErrorMessage'),
        }];

        const validations = this.paymentLimitsCollection.pluck('validations');

        /*
         * loop through all model validation attributes and see if there are any
         * false ones
         * determine if there are any false validations for any of the validation types
         */
        const messagesArray = util.map(validationMessages, (validationMessage) => {
            if (util.some(
                validations,
                validation => validation[validationMessage.type] === false,
            )) {
                return validationMessage.message;
            }
            return undefined;
        });

        return util.compact(messagesArray);
    },

    templateHelpers() {
        return {
            isEditMode: this.isEditMode,
        };
    },

});
