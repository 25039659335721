import '../themeDefaults';

export default ({ typography }) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dt: {
    flexBasis: '30%',
    fontWeight: typography.fontWeightHeavy,
    marginBottom: '0.5rem'
  },
  dd: {
    flexBasis: '55%',
    flex: 1,
    margin: '0 0 0.5rem 0.5rem'
  }
});
