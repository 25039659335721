export default ({
    'PAYMENTS/enterPayment': 'sbListPaymentTypes',
    'payments/newTransferGrid': 'sbTransferPaymentGrid',
    'payments/newPayIndGrid/:id': 'sbPayIndividualGrid',
    'payments/newPayEmpGrid/:id': 'sbPayEmployeesGrid',
    'payments/newPayBusinessGrid/:id': 'sbPayBusinessGrid',
    'payments/reqLoanAdvanceGrid': 'sbReqLoanAdvanceGrid',
    'payments/collectFromIndividual': 'sbCollectFromIndividual',
    'payments/collectFromBusiness': 'sbCollectFromBusiness',
});
