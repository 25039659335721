import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import services from 'services';
import configuration from 'system/configuration';
import constants from 'common/dynamicPages/api/constants';
import DataAPI from 'common/dynamicPages/api/data';
import unescapeData from 'common/util/unescapeData';
import DateRange from 'common/util/dateRange';

const getBlockedDates = function (dates) {
    const blockedDates = dates.holidays;
    if (dates.businessDays === '0111110') {
        blockedDates.push('weekends');
    }
    return blockedDates;
};

const getCutoffTimes = function (hoursAndMinutes) {
    const cutoffTimes = {};
    const cutoffTime = hoursAndMinutes + constants.DATEPICKER_MAXCUTOFFTIME;
    let i;
    for (i = 1; i <= 7; i += 1) {
        cutoffTimes[i] = cutoffTime;
    }
    return cutoffTimes;
};

const getDates = function (dates) {
    const { businessDays } = dates;
    return {
        blockedDates: getBlockedDates(dates),
        minDate: dates.maxBackwardDays,
        maxDate: dates.maxForwardDays,
        cutoffTimes: getCutoffTimes(dates.cutoff),
        processingDays: util.isArray(businessDays) ? businessDays : [businessDays],
        defaultDay: dates.defaultDay,
        earliestDay: dates.earliestDay,
        cutoffDateTimeTz: dates.cutoffDateTimeTz,
    };
};

export default {
    getModel(options) {
        if (options.model) {
            // assume retrieved from fetch
            return new Promise((resolve) => {
                resolve(options.model);
            });
        }
        return DataAPI.model.generate(options, false);
    },

    reloadModel(options) {
        return DataAPI.model.reload(options);
    },

    /**
     * Updates this helpers model and pageView references to be current now that data for the
     * page has been retrieved, as well as kicks off the construction of the DOM
     * @param {Model} model
     * @param {View} pageViewParam
     */
    updateModels(model, pageViewParam) {
        this.viewPromise = import('common/dynamicPages/api/view').then(({ default: viewapi }) => {
            const pageView = pageViewParam;

            const fieldColumnSpanInUse = util.some(model.jsonData.fieldInfoList, item => item.columnSpan && item.columnSpan !== '0');

            // we only care about visible fields and grids apparently
            const allVisibleFields = model.jsonData.fieldInfoList.filter(field => (field.visible || field.fieldUIType === 'GRID'));

            // structure/format the blocks(containers/groups) that will be on the form
            const blocks = viewapi.page.createViewRowGroups(model, allVisibleFields);

            // get an array of all the fields with their appropriate classes
            const fieldsArray = viewapi.page.assignClassesToFields(
                allVisibleFields,
                fieldColumnSpanInUse,
            );

            pageView.model = model;
            // This is needed so we can add our listener events back onto the model in entry.js
            pageView.trigger('modelAction:newModel', pageView.model);
            pageView.jsonModel = fieldsArray;
            pageView.blocks = blocks;
        });
    },

    getComboPromises(options, metaModel, combosList) {
        const promiseHash = {};
        const self = this;
        let i;
        let j;

        if (combosList === undefined) {
            for (i = 0; i < metaModel.comboList.length; i += 1) {
                if (metaModel.comboList[i].inquiryId) {
                    promiseHash[`combo-${i}`] = self.setupCombo({
                        context: {
                            serviceName: 'inquiry',
                        },

                        parentModel: options.parentModel,
                        metaModel,
                        inquiryId: metaModel.comboList[i].inquiryId,
                        state: options.state,
                        name: metaModel.comboList[i].name,
                        comboService: options.comboService,
                    });
                } else {
                    promiseHash[`combo-${i}`] = self.setupCombo({
                        context: options.context,
                        parentModel: options.parentModel,
                        metaModel,
                        state: options.state,
                        name: metaModel.comboList[i],
                        comboService: options.comboService,
                    });
                }
            }
        } else {
            // loop through the passed combosList
            for (j = 0; j < combosList.length; j += 1) {
                /*
                 * preferred typeahead lists may have dependents in meta-data, but handle their own
                 * list retrievals and should not be controlled by the MDF
                 */
                if (metaModel.fieldData[combosList[j]].fieldUIType !== 'TYPEAHEAD_PREFERRED') {
                    // TODO: Why build the hash by combo-# when we have a fieldname?!
                    promiseHash[`combo-${j}`] = self.setupCombo({
                        context: options.context,
                        parentModel: options.parentModel,
                        metaModel,
                        name: combosList[j],
                        comboService: options.comboService,
                    });
                }
            }
        }
        return promiseHash;
    },

    getDateRangePromise(options) {
        /*
         * for now only call the date range promise for a limit set to prevent errors
         * on the admin system
         * in the future we may want a different way to scope or limit this test or
         * place the meta data on the admin
         */
        if (options.context.typeCode === 'SEARCH' || options.context.typeCode === 'CMINQ') {
            return new Promise((resolve, reject) => {
                const dateRange = new DateRange(options);
                dateRange.fetch({
                    success: resolve,
                    error: reject,
                });
            });
        }
        return new Promise((resolve) => {
            resolve(null);
        });
    },

    getDatePromises(options, metaModel) {
        const fields = metaModel.fieldData;
        const datePromise = new Promise((resolve) => {
            resolve(null);
        });
        const { typeCode } = metaModel.jsonData.typeInfo;
        const subType = metaModel.jsonData.subtype;
        const debitBank = metaModel.get('BANKCODE');
        const creditCurrency = metaModel.get('DESTCURRENCYCODE');
        const debitCurrency = metaModel.get('ORIGCURRENCYCODE');
        const debitBankCountry = metaModel.get('ORIGCOUNTRY');
        const creditBankCountry = metaModel.get('DESTCOUNTRYCODE');
        const origCompId = metaModel.get('ORIGCOMPID');
        const origCompName = metaModel.get('ORIGCOMPNAME');
        const entryClass = metaModel.get('ENTRYCLASS');
        if (typeCode !== '*') {
            let paymentType = typeCode;
            if (typeCode === 'CIMINSTT' || typeCode === 'ACH_SCHE') {
                paymentType = 'transfer';
            }
            const calfield = Object.keys(fields || {})
                .find(field => fields[field].fieldUIType === 'CALENDAR');
            if (calfield) {
                // TODO - is it ok to assume that the service is the end of it?
                return this.setupDates(
                    paymentType,
                    typeCode,
                    subType,
                    debitBank,
                    creditCurrency,
                    debitCurrency,
                    debitBankCountry,
                    creditBankCountry,
                    origCompId,
                    origCompName,
                    entryClass,
                );
            }
        }
        return datePromise;
    },

    /**
     * @description this function will return a Promise that should resolve to
     * the context sensitive help page for the current view
     *
     * @param {object} options - options hash, containing the mode
     * @param {object} metaModel - view backbone model
     * @returns {Promise} helpPage promise
     */
    getHelpPagePromise(options, metaModel) {
        const helpService = services.generateUrl(constants.URL_GETHELPPAGE);
        const postData = {};
        let typeInfo = null;

        // sanity check on metaModel
        if (metaModel && metaModel.jsonData && metaModel.jsonData.typeInfo) {
            ({ typeInfo } = metaModel.jsonData);
        }

        if (typeInfo) {
            // special context for import
            if (options.mode === 'import') {
                postData.productCode = (typeInfo.productCode === 'CM') ? 'CM' : '*';
                postData.functionCode = 'INST';
                postData.typeCode = '*';
            } else {
                postData.productCode = typeInfo.productCode;
                postData.functionCode = typeInfo.functionCode;
                postData.typeCode = typeInfo.typeCode || '*';
            }
            postData.actionMode = options.mode.toUpperCase();
            return new Promise((resolve, reject) => {
                http.post(helpService, postData, (data) => {
                    resolve(data);
                }, () => {
                    reject(locale.get('common.helppage.data.error', options.name));
                });
            });
        }
        const defaultPage = (configuration.isClient()) ? 'DB_Client_Help.htm' : 'DB_Administrator_Help_CSH.htm';

        return Promise.resolve({
            helpPage: defaultPage,
        });
    },

    setupCombo(options) {
        const { metaModel } = options;
        const { parentModel } = options;
        const fields = metaModel.fieldData;
        const { name } = options;
        const inquiryId = options.inquiryId || fields[name].popupId;
        const dependsOnList = fields[name].dependsOn;
        let subTypeStr = '*';
        let entryClass = '';
        let actionMode = 'SELECT';
        if (options.context && options.context.actionMode) {
            actionMode = options.context.actionMode.toUpperCase();
        }
        if (options.context && options.context.subType && options.context.subType !== null && options.context.subType !== '') {
            subTypeStr = options.context.subType;
        } else if (options.context && options.context.actionContext && options.context.actionContext.subType !== null && options.context.actionContext.subType !== '') {
            subTypeStr = options.context.actionContext.subType;
        }
        if (options.context && options.context.actionData) {
            ({ entryClass } = options.context.actionData);
        }
        let postdata;
        if (inquiryId) {
            postdata = {
                IncludeMapData: 1,

                queryCriteria: {
                    action: {
                        typeCode: options.metaModel.context.typeCode,
                        entryMethod: '0',
                        productCode: options.metaModel.context.productCode,
                        actionMode,
                        functionCode: options.metaModel.context.functionCode,
                    },

                    inquiryId,
                },

                requestHeader: {
                    queryPagesize: 250,
                },
            };
        } else {
            postdata = {
                queryCriteria: {
                    action: {
                        productCode: metaModel.jsonData.typeInfo.productCode,
                        functionCode: metaModel.jsonData.typeInfo.functionCode,
                        typeCode: metaModel.jsonData.typeInfo.typeCode,
                        actionMode,
                    },

                    subTypeCode: subTypeStr,
                    fieldName: name,
                    entryClass,
                },
            };
        }

        if (options.metaModel.context.queryType) {
            postdata.queryCriteria.queryType = options.metaModel.context.queryType;
        }

        if (metaModel.get('TEMPLATETNUM')) {
            postdata.queryCriteria.templateTnum = metaModel.get('TEMPLATETNUM');
        } else if (!metaModel.get('TEMPLATETNUM') && options.context.copySource === 'template'
            && options.context.actionMode === 'INSERT' && options.context.createdFrom === '1' && options.context.functionCode === 'TMPL') {
            postdata.queryCriteria.templateTnum = options.context.tnum;
        }

        if (metaModel.get('TNUM')) {
            postdata.queryCriteria.tnum = metaModel.get('TNUM');
        }

        if (dependsOnList !== null) {
            // add the depends fields as customFilters
            postdata.queryCriteria.customFilters = this.createDependsOnFilters(
                fields,
                dependsOnList,
                metaModel,
                parentModel,
            );
        }

        postdata.queryCriteria.allowDuplicates = fields[name].comboAllowDuplicates;

        if (metaModel.staticDependsOn && metaModel.staticDependsOn[name]) {
            postdata.queryCriteria.customFilters = metaModel.staticDependsOn[name];
        }

        let comboService = options.comboService ? services.generateUrl(options.comboService)
            : services.generateUrl(constants.URL_GETQUERYRESULTS_ACTION);

        /*
         * For Admin Payments the service urls are different
         * so changing the urls to process for admin payments and templates.
         * TODO remove these checks and pass comboService as an option
         * to the view
         */
        const isAdminPay = options.context.serviceName.indexOf('adminPayment/listView') > -1;
        const isAdminTemp = options.context.serviceName.indexOf('adminTemplate/listView') > -1;
        const isAdminCm = options.context.serviceName.indexOf('adminCM/cminst') > -1;
        const isAdminStopDetail = options.context.serviceName.indexOf('adminCM/cm') > -1;
        if (isAdminPay) {
            comboService = services.generateUrl('adminPayment/listView/payments/getQueryResults');
        } else if (isAdminTemp) {
            comboService = services.generateUrl('adminTemplate/listView/templates/getQueryResults');
        } else if (isAdminCm) {
            comboService = services.generateUrl('adminCM/cm/issueVoids/getQueryResults');
        } else if (isAdminStopDetail) {
            comboService = services.generateUrl('adminCM/cm/stopCancels/getQueryResults');
        }

        if ((isAdminPay || isAdminTemp || isAdminCm) && (metaModel.isChild || options.state === 'view')) {
            // TODO: Should this return a Promise?
            return undefined;
        }

        return new Promise((resolve, reject) => {
            http.post(comboService, postdata, (data) => {
                unescapeData.unescapeQueryResponseData(data);
                resolve(data);
            }, () => {
                reject(locale.get('common.combo.data.error', options.name));
            });
        });
    },

    createDependsOnFilters(fields, dependsOnList, metaModel, parentModel) {
        return dependsOnList.map((dependsOn) => {
            const fieldMeta = fields[dependsOn];
            let tempVal = metaModel.get(dependsOn);
            if ((util.isEmpty(tempVal) || util.isNull(tempVal))
                && metaModel.isChild && !util.isUndefined(parentModel)) {
                tempVal = parentModel.get(dependsOn);
            }
            if (fieldMeta && fieldMeta.fieldUIType === 'CHECKBOX') {
                // for checkboxes the model has a false instead of Off Value.
                if (tempVal !== fieldMeta.checkboxOnValue) {
                    // if the value does not match the on value, then use the off value.
                    tempVal = fieldMeta.checkboxOffValue;
                }
            }

            const customFilter = {};
            if (util.isArray(tempVal)) {
                customFilter.filterName = 'DependsIN';
                customFilter.filterParam = tempVal;
            } else {
                customFilter.filterName = 'Depends';
                customFilter.filterParam = [tempVal];
            }
            // Add the depends on field name as the first value in the array
            customFilter.filterParam = [dependsOn].concat(customFilter.filterParam);
            return customFilter;
        });
    },

    getOptionsForCombo(comboData) {
        let newOptionsStr = '<option value=""></option>';
        if (!comboData) {
            return newOptionsStr;
        }
        for (let j = 0; j < comboData.length; j += 1) {
            newOptionsStr += `<option value="${util.escape(comboData[j].name)}" data-item="">${util.escape(comboData[j].label)}</option>`;
        }
        return newOptionsStr;
    },

    getPromisesForStateToggle(model) {
        const field = util.findWhere(
            model.fieldData,
            {
                fieldUIType: 'STATETOGGLE',
            },
        );
        if (!field) {
            return Promise.resolve();
        }
        const { name } = field;
        const subTypeStr = '*';
        const entryClass = '';
        const actionMode = 'INSERT';
        const service = services.generateUrl(constants.URL_GETQUERYRESULTS_ACTION);

        const postdata = {
            queryCriteria: {
                action: {
                    productCode: model.jsonData.typeInfo.productCode,
                    functionCode: model.jsonData.typeInfo.functionCode,
                    typeCode: model.jsonData.typeInfo.typeCode,
                    actionMode,
                },

                subTypeCode: subTypeStr,
                fieldName: name,
                entryClass,
            },
        };

        // TODO: Get proper resource for reject
        return new Promise((resolve, reject) => {
            http.post(service, postdata, (data) => {
                resolve(data);
            }, () => {
                reject(locale.get('common.combo.data.error', name));
            });
        });
    },

    setupDates(
        paymentType,
        typeCode,
        subType,
        debitBank,
        creditCurrency,
        debitCurrency,
        debitBankCountry,
        creditBankCountry,
        origCompId,
        origCompName,
        entryClass,
    ) {
        const dateService = services.generateUrl(constants.URL_GETDATESLIST);
        return new Promise((resolve, reject) => {
            const postData = {
                paymentType,
                debitBank,
                creditCurrency,
                debitCurrency,
                debitBankCountry,
                creditBankCountry,
                typeCode,
                subType,
                origCompId,
                origCompName,
                entryClass,
            };
            http.post(dateService, postData, (data) => {
                resolve(getDates(data));
            }, () => {
                reject(locale.get('common.datepicker.data.error'));
            });
        });
    },

    getChildNumberPromise(options) {
        return new Promise((resolve, reject) => {
            const service = services.generateUrl(options.context.serviceName)
                + constants.URL_GETCHILDREN_ACTION;
            const postData = {
                startRow: 1,
                action: 'INSERT',
                entryMethod: 0,
                subType: options.context.subType,
                batchSeqNumber: options.parentModel.get('BATCHSEQNUM'),
            };
            http.post(service, postData, (data) => {
                resolve(data.nextSeqNum);
            }, () => {
                // TODO - implement error handling
                reject(1);
            });
        });
    },

    /**
     * gets the next batch sequence number
     * @returns - Promise to get the next sequence number.
     */
    getSequenceNumberPromise() {
        return new Promise(((resolve, reject) => {
            const service = services.generateUrl(constants.URL_GET_BATCH_SEQUENCE);
            http.post(service, {}, (data) => {
                resolve(data);
            }, () => {
                reject(0);
            });
        }));
    },

    resetChildrenPromise(options) {
        return new Promise((resolve, reject) => {
            const service = services.generateUrl(options.context.serviceName)
                + constants.URL_RESETCHILDREN_ACTION;
            const postData = {
                entryMethod: '0',
                action: 'INSERT',
                subType: options.context.subType,
            };
            http.post(service, postData, () => {
                resolve();
            }, () => {
                reject();
            });
        });
    },

    /**
     * A helper function to set proper column counts on containers
     * @param containerParam {Object} - the container to note columns(groups) on
     * @param groups {Array} - the groups within this container (or group if it has subgroups)
     */
    recursiveColumnCountPerRow(containerParam, groups) {
        const container = containerParam;
        const self = this;

        // iterate through all groups within this container and get their amount/desired sizes
        groups.forEach((group) => {
            if (group.subgroups) {
                self.recursiveColumnCountPerRow(group, group.subgroups);
            }
            const rowNum = group.rowNumber;
            const colCount = group.columnSpan;

            // if rowNum doesn't exist within the rowColumnCount, set it to 0 so we can do math
            container.rowColumnCount[rowNum] = container.rowColumnCount[rowNum] || 0;

            container.rowColumnCount[rowNum] += colCount;
        });
    },

    /**
     * kick off the calculation/setting of column counts on a container
     * @param containerParam {Object} - the specific container
     */
    columnCountPerRow(containerParam) {
        const container = containerParam;
        container.rowColumnCount = [];
        this.recursiveColumnCountPerRow(container, container.groups);
    },

    /**
     * Create a map object for an array of groups to allow for quick access by the name of the
     * group alone. Prevents having to loop through the array and search for individual groups
     * whenever you want to get one by name
     *
     * @param groupArray {array} - an array of groups
     * @return {Object} - a map object of all the passed in groups
     */
    createMapOfGroups(groupArray) {
        return groupArray.reduce((acc, groupObject) => {
            acc[groupObject.fieldGroupName] = groupObject;
            return acc;
        }, {});
    },

    /**
     * Detect any subgroups and establish their proper relationship with their parent groups
     * @param containerParam {object} - an individual field container
     */
    properlyNestSubgroups(containerParam) {
        const groupsThatAreSubgroups = containerParam.groups.filter(group => group.subgroupof);

        // if there are no subgroups present in this container, exit this function
        if (!groupsThatAreSubgroups.length) {
            return;
        }

        const container = containerParam;
        const mapOfGroups = this.createMapOfGroups(container.groups);
        let parentGroup;

        // iterate through all subgroups to assign them to their parents
        groupsThatAreSubgroups.forEach((group) => {
            // grab the parent to this subgroup
            parentGroup = mapOfGroups[group.subgroupof];

            if (parentGroup) {
                if (!parentGroup.subgroups) {
                    parentGroup.subgroups = [];
                    parentGroup.rowColumnCount = [];
                }
                parentGroup.subgroups.push(group);
            }
        });

        // update the containers groups array to only be non-sub groups
        container.groups = container.groups
            .filter(group => !groupsThatAreSubgroups.includes(group));
    },

    /**
     * In rare situations, a group with subgroups may decide to have those subgroups display in
     * an inline fashion. This can allow for layouts where groups with vertically aligned fields
     * can sit alongside one another. A result of this is that the group itself must be sized
     * rather than the individual fields within it
     *
     * @param group {Object} - The group having a size applied to it
     * @param fieldColumnSpanInUse {Boolean} - indicates whether or not to observe bootstrap
     * column size rules
     * @return {String} - the sizing class to apply to the group
     */
    applySizeToGroup(group, fieldColumnSpanInUse) {
        let largestSize = 0;
        let largestColSpan = 0;
        let containsAFilter = false;

        group.fields.forEach((fieldParam) => {
            const field = fieldParam;

            // indicate that the fields within this group should not recieve individual sizes
            field.shouldNotBeSized = true;

            // get the largest field sizes/column spans
            largestSize = largestSize < Number(field.size) ? Number(field.size) : largestSize;
            largestColSpan = largestColSpan < field.columnSpan
                ? field.columnSpan
                : largestColSpan;

            if (field.fieldUIType && field.fieldUIType.indexOf('FILTER')) {
                containsAFilter = true;
            }
        });

        // determine what size to return
        if (fieldColumnSpanInUse && largestColSpan !== 0) {
            /*
             * HACK:
             * adds a midsize view break (768 - 1024)
             */
            if (largestColSpan === 2) {
                return `col-md-2 col-mdlg-${largestColSpan + 2}`;
            }
            return `col-md-${largestColSpan}`;
        }
        if (containsAFilter) {
            return 'field-container-lg';
        }
        if (largestSize < constants.SMALLTEXT) { // check by size if not a filter type
            return 'field-container-xs';
        }
        if (largestSize < constants.MEDIUMTEXT) {
            return 'field-container-sm';
        }
        if (largestSize < constants.LARGETEXT) {
            return 'field-container-md';
        }

        return 'field-container-lg';
    },

    /**
     * give groups(columns) within a container their appropriate classes to establish their
     * size on the DOM
     *
     * @param container {Object} - the specific container whos groups we're affecting
     * @param groups {Array} - all groups within a specific container
     * @param columnSuffix {String} - the bootstrap colspan for this container
     * @param isSubgroup {Boolean} - indicates if this function is being called recursively for
     * a group within a group
     * @param isInlineSubgroup {Boolean} - indicates if this group lives within another group
     * and has an inline layout (important for determining size of the group/inner fields)
     * @param fieldColumnSpanInUse {Boolean} - indicates whether or not to observe bootstrap
     * column size rules
     */
    recursiveAssignColumnClassesToGroups(
        container,
        groups,
        columnSuffix,
        isSubgroup,
        isInlineSubgroup,
        fieldColumnSpanInUse,
    ) {
        let position = 1;
        let oldRow = -1;
        let lastGroup = null;

        groups.forEach((groupParam) => {
            const group = groupParam;
            group.labelClasses = '';
            let colClass = '';
            let inlineLayout = false;

            /*
             * determine if the elements (fields or groups) within this group will act as
             * blocks(default) or inline elements
             */
            if (group.fieldOrientation === 'H') {
                inlineLayout = true;
                colClass += groupParam.subgroups ? ' inline-groups inline-fields' : ' inline-fields';
            }

            // Check for subgroups and run them through this function
            if (groupParam.subgroups) {
                // indicate that this group contains other groups
                colClass += ' group-container';
                this.recursiveAssignColumnClassesToGroups(
                    groupParam,
                    groupParam.subgroups,
                    columnSuffix,
                    true,
                    inlineLayout,
                    fieldColumnSpanInUse,
                );
            }

            if (columnSuffix && !fieldColumnSpanInUse) {
                /*
                 * To support older MDF screens that still use
                 * the non-bootstrap styles (col-md)
                 */
                colClass += ` col-md-${columnSuffix}`;
            }

            if (oldRow !== group.rowNumber) {
                position = 1;
                if (lastGroup !== null) {
                    lastGroup.endRow = true;
                }
                oldRow = group.rowNumber;
                group.startRow = true;
                group.rowClass = 'row';

                if (isSubgroup) {
                    group.rowClass += ' subgroup-row';
                }
            } else {
                position += 1;
            }

            if (position !== group.columnNumber) {
                if (fieldColumnSpanInUse) {
                    colClass += ` col-md-offset-${(parseInt(group.columnNumber, 10) - 1) * 2}`;
                } else {
                    colClass += ` col-md-offset-${columnSuffix}`;
                }
            }

            // when determining the size of a vertical group
            if (group.fieldOrientation === 'V') {
                if (isInlineSubgroup) {
                    /*
                     * if the group is a subgroup within a horizontally oriented group, the size
                     * is determined by the fields within it
                     */
                    colClass += ` mdf-vertical-group ${this.applySizeToGroup(group, fieldColumnSpanInUse)}`;
                } else {
                    /*
                     * if the group is not a subgroup, then the size of the group is determined
                     * by the columnspan of the group related to the number of columns in the
                     * container
                     */
                    colClass += ` col-md-${columnSuffix}`;
                }
            }

            group.columnClass = colClass;
            lastGroup = group;
        });
        if (lastGroup !== null) {
            lastGroup.endRow = true;
        }
    },

    /*
     * kick off the assigning of appropriate classes to groups(columns) within a container
     * before their DOM elements are constructed
     *
     * @param container {Object} - the specific container being acted upon
     * @param columnSuffix {String} - the bootstrap colspan for this container
     * @param fieldColumnSpanInUse {Boolean} - indicates whether or not we're using the
     * bootstrap grid
     */
    assignColumnClasses(container, columnSuffix, fieldColumnSpanInUse) {
        // assign classes to groups
        this.recursiveAssignColumnClassesToGroups(
            container,
            container.groups,
            columnSuffix,
            false,
            false,
            fieldColumnSpanInUse,
        );
    },
};
