import Loan from 'app/loans/views/template/viewTemplate/view';

export default Loan.extend({
    initialize(options) {
        Loan.prototype.initialize.call(this, options);

        this.model.set(options.model);

        // set attributes on the model for the handlebars template
        this.model.set('DEBIT_ACCOUNT_TITLE', this.model.get('CMB_DEBIT_ACCOUNT_NAME'));
        this.model.set('VALUE_DATE', this.model.get('CMB_VALUE_DATE'));
        this.model.set('BENE_ACCOUNT', this.model.get('CMB_BENE_ACCOUNT'));
        this.model.set('BENE_NAME', this.model.get('CMB_BENE_NAME'));
    },

    cancel() {
        window.history.back();
    },
});
