import CollectionView from '@glu/core/src/collectionView';
import Column from 'no-override!@glu/grid/src/column/column';
import util from '@glu/core/src/util';

export default Column.extend({
    initialize(...args) {
        Column.prototype.initialize.apply(this, args);

        this.debouncedHandleChange = util.debounce(util.bind(this.handleChange, this), 5);
        this.debouncedColumnsChange = util.debounce(util.bind(this.columnsChanged, this), 5);
    },

    delegateEvents(...args) {
        CollectionView.prototype.delegateEvents.apply(this, args);
        this.stopListening(this.collection, 'reset sort sync'); // prevent default CollectionView render on reset
        this.stopListening(this.columns);

        this.listenTo(this.columns, 'change:condition add remove sort sync reorder', this.debouncedHandleChange);

        /*
         * if we don't have groupBy or rowSubView feature enabled we should
         * use built in Marionette CollectionView handlers for 'add' and 'remove' events
         */
        if (!this.options.groupBy && !this.options.rowSubView) {
            this.listenTo(this.collection, 'reset sort sync', this.debouncedHandleChange);
        } else {
            this.listenTo(this.collection, 'reset add remove sort sync', this.debouncedHandleChange);
        }
        this.listenTo(this.columns, 'reorder change:condition', this.debouncedColumnsChange);
    },

    columnsChanged(target = this) {
        if (!target) {
            return;
        }
        const columns = target.columns ? target.columns.models : target.models;
        if (!columns) {
            return;
        }

        if (this.grid.options.wrapper && this.grid.options.wrapper.lvc) {
            this.grid.options.wrapper.lvc.setColumns(columns);
        }
    },

    addPreItemView(item, index, columnCount) {
        this.addRowGroupHeaderView(item, index, columnCount);
    },

    // TODO leave this method for backwards compatibility - remove in Glu 1.9
    setCollection(collection) {
        this.collection = collection;

        /*
         * if we don't have groupBy or rowSubView feature enabled we should
         * use built in Marionette CollectionView collection events handling.
         */
        if (!this.options.groupBy || !this.options.rowSubView) {
            if (this.collection) {
                this.stopListening(this.collection, 'reset');
            }
            /*
             * We debounce this handler, because otherwise it is called as many times as
             * there are models in the collection.
             */
            this.listenTo(this.collection, 'reset sort sync', this.debouncedHandleChange);
            return;
        }

        if (this.collection) {
            this.stopListening(this.collection);
        }

        /*
         * We debounce this handler, because otherwise it is called as many times as
         * there are models in the collection.
         */
        this.listenTo(this.collection, 'reset add remove sort sync', this.debouncedHandleChange);
    },
    /*
     * NH-170316
     * overriding  showCollection method
     */
    showCollection() {
        const columnCount = this.getColumnCount();

        // Necessary as cannot use :nth-child or :nth-of-type with sub row views
        let isEvenRow = false;

        this.collection.each((item, index) => {
            const RowItemView = this.getItemView(item);

            this.addPreItemView(item, index, columnCount, isEvenRow);

            const view = this.addItemView(item, RowItemView, index);

            /*
             * added aria-label attribute for rowselector
             * field in tablefor accessbility
             */
            const rowSelector = view.$el?.find('.column-row-selector');
            if (rowSelector[0]?.nodeName === 'INPUT') {
                const label = Object.keys(view.el?.cells)?.reduce((a, b) => {
                    if (view.el.cells[b]?.dataset?.title) {
                        return `  ${a}  ${view.el.cells[b].dataset.title}`;
                    }
                    return a;
                }, '');
                rowSelector?.attr('aria-label', label);
            }
            // Necessary when using sub-rows, as :nth-child/:nth-of-type isn't "class aware"
            view.el.className += isEvenRow ? ' grid-row grid-row-even' : ' grid-row grid-row-odd';

            this.addPostItemView(item, index, columnCount, view, isEvenRow);

            isEvenRow = !isEvenRow;
        });

        // Reset value ready for next page of results.
        delete this.currentGroupByValue;
    },
});
