var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"checkbox assign-all-checkbox\">\n            <input id=\"assignAllChkTemplates\" type=\"checkbox\" data-hook=\"assign-all-checkbox\">\n            <label for=\"assignAllChkTemplates\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.assign-all-templates","Assign all current/future templates",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":47},"end":{"line":8,"column":122}}}))
    + "</label>\n        <a data-toggle=\"popover\" data-trigger=\"hover focus\" title=\"\" role=\"button\" data-placement=\"auto\" data-content=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"assignAllTooltip") || (depth0 != null ? lookupProperty(depth0,"assignAllTooltip") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"assignAllTooltip","hash":{},"data":data,"loc":{"start":{"line":9,"column":119},"end":{"line":9,"column":139}}}) : helper)))
    + "\">\n            <span class=\"icon-info\"></span>\n        </a>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button type=\"button\" class=\"btn btn-tertiary edit-items-button right\"><span class=\"icon-add-circle\"></span> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.addTemplates",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":117},"end":{"line":21,"column":146}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.restrictedTemplates",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":40}}}))
    + "</h2>\n\n<p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.add.specific.templates",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":3},"end":{"line":4,"column":53}}}))
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + "<div class=\"assign-all-msg\">\n    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.assign-all-msg-templates","All current/future templates have been assigned.",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":15,"column":96}}}))
    + "\n</div>\n\n<div data-hook=\"grid-section\">\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":22,"column":15}}})) != null ? stack1 : "")
    + "\n    <div class=\"grid\"></div>\n\n</div>\n";
},"useData":true});