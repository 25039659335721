import util from '@glu/core/src/util';
import transform from 'common/util/transform';
import { postData } from 'common/util/services';
import services from 'services';
import interfaceUtil from './util';

const requiredProps = [
    'ABA_NUMBER',
    'ACCOUNT_TITLE',
    'ACCOUNT_NUMBER',
    'ACCOUNTFILTER',
    'BANKCODE',
    'SERIALNUMBER',
];

const accountMapAttributes = {
    ABA_NUMBER: 'abaNumber',
    ACCOUNT_NUMBER_UNMASKED: 'accountNumber',
    ACCOUNTFILTER: 'accountFilter',
    ACCOUNT_TITLE: 'accountName',
    BANKCODE: 'bankCode',
};

const accountColumnAttributes = {
    ACCOUNTNAME: 'accountName',
};

export default {
    // productCode~functionCode~typeCode
    entitlementKey: 'CM~INST~STOP',

    /**
     * Get the modelInfo for the add stop
     */
    getAddStopModelInfo() {
        const url = services.generateUrl('cminst/placeStop/getModelInfo');
        return postData(url, {
            action: 'INSERT',
            entryMethod: 0,
            functionCode: 'INST',
        });
    },

    /**
     * Get the mandatory fields for creating a stop payment
     */
    getOptionalMandatoryFormFields() {
        return this.getAddStopModelInfo()
            .then(this.extractMandatoryFields)
            .then(response => JSON.stringify(response));
    },

    /**
     * Extract the manditory prop for payee and amount fields from
     * the stand modelInfo response
     * @param response
     * @returns {Object}
     */
    extractMandatoryFields(response) {
        const payee = util.findWhere(response.fieldInfoList, { name: 'PAYEE' });
        const amount = util.findWhere(response.fieldInfoList, { name: 'AMOUNT' });

        return {
            payee: payee.mandatory,
            amount: amount.mandatory,
        };
    },

    /**
     * Evaluate options object for correct params, submit a request to create
     * a new stop payment, convert the response to a string
     * @param {Object} options
     * @param {string} options.SERIALNUMBER - check serial number
     * @param {string} options.BANKCODE - bank code
     * @param {string} options.ACCOUNT_TITLE - account name
     * @param {string} options.ACCOUNT_NUMBER - account number
     * @param {string} options.ACCOUNT_FILTER - bank code and account number
     * concatenated, ie: BONY-14432343
     * @param {string} options.ABA_NUMBER - aka routing number
     * @param {string} [options.PAYEE] - payee on the check, configured by the bank
     * in the metadata for the create stop payment form
     * @param {string} [options.AMOUNT] - amount, configured by the bank
     * in the metadata for the create stop payment form
     * @returns {Promise}
     */
    stopPayment(options = {}) {
        return interfaceUtil.dataHasProps(requiredProps, options)
            .then(data => this.postStopPayment(data, 'add'))
            .then(response => JSON.stringify(response))
            .catch(error => Promise.reject(JSON.stringify(error)));
    },

    /**
     * Sumbit data to the the endpoint based on the action param
     * and return a promise
     * @param {Object} options - additional data values
     * @param {string} action
     * @returns {Promise}
     */
    postStopPayment(options, action) {
        const data = util.extend({}, options, {
            STOP_DURATION: '120',
            STOPDURATIONDESC: '120 Days',
            STOPTYPE: 'CHECK',
            STOPTYPE_DESCRIPTION: 'Check',
        });
        return postData(
            `${services.stopPaymentAction}/${action}`,
            {
                item: transform.hashToPairs(data, 'name', 'value'),
            },
        );
    },

    /**
     * Get accounts that are entitled to stop payments
     * @returns {Promise}
     */
    getAccounts() {
        return interfaceUtil.getAccounts({
            typeCode: 'STOP',
            productCode: 'CM',
            functionCode: 'INST',
            fieldName: 'ACCOUNT_NUMBER',
        }).then(response => ({
            accounts: this.formatResponse(response),
            entitlement: this.entitlementKey,
        }));
    },

    /**
     * Convert the standard response object to data structure that native expects
     * @param {Object} response
     * @returns {Object}
     */
    formatResponse(response) {
        return response.rows.map((row) => {
            const mapDataAttributes = interfaceUtil.extractData(
                row.mapDataList,
                accountMapAttributes,
                'toField',
                'value',
            );
            const columnAttributes = interfaceUtil.extractData(
                row.columns,
                accountColumnAttributes,
                'fieldName',
                'fieldValue',
            );
            return util.extend({}, mapDataAttributes, columnAttributes);
        });
    },
};
