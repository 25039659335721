import Collection from '@glu/core/src/collection';
import Formatter from 'system/utilities/format';
import Model from 'app/payments/multiTransfer/model/transfer';

export default Collection.extend({
    model: Model,

    initialize(models, options) {
        this.options = options || {};
    },

    totalAmount() {
        let total = 0;
        for (let i = 0; i < this.length; i += 1) {
            total += Formatter.stringToNumber(this.at(i).getAmount());
        }
        return total;
    },

    getDate() {
        return this.pluck('VALUE_DATE')[0];
    },

    getCurrency() {
        const transfer = this.at(0);
        if (transfer) {
            return transfer.getCurrency();
        }
        return '';
    },
});
