export default ({ palette }) => ({
  btnApply: {
    fontSize: 14
  },
  btnClear: {
    fontSize: 16,
    marginLeft: 18
  },
  card: {
    backgroundColor: palette.background,
    border: `1px solid ${palette.form.border}`,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 220,
    padding: [13, 14]
  },
  root: {
    '& +div': {
      fontSize: 0,
      fontWight: 400
    },
    '& +div label p': {
      fontWight: 400,
      margin: 0,
      padding: 0
    },
    '& div[class*="inputWrapper"]': {
      marginBottom: 0
    },
    '& section div button span': {
      color: '#f00'
    }
  }
});
