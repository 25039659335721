import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import services from 'services';
import locale from '@glu/locale';
import transform from 'common/util/transform';
import constants from 'app/administration/constants';
import dynamicPagesConstants from 'common/dynamicPages/api/constants';
import http from '@glu/core/src/http';
import DataEntitlement from '../../models/user2/dataEntitlementLimit';

export default Collection.extend({
    model: DataEntitlement,

    /**
     * @param {object} searchOptions
     * @return {object} search field obj
     * Takes in a value and name pairs to create a search field parameter used in limits
     * service call
     */
    createSearchField(searchOptions) {
        return {
            fieldValue: [
                searchOptions.value,
            ],
            dataType: searchOptions.type || 'text',
            fieldName: searchOptions.name,
            operator: searchOptions.operator || 'EQ',
        };
    },

    /**
     * Creates service query parameter for data interim limits call
     * @param {object} options
     * @return {object} service query data
     */
    createServiceParam(options) {
        const postData = {
            listViewRequest: {
                searchFields: [
                    this.createSearchField({
                        value: options.userGroup,
                        name: 'USERGROUP',
                    }),
                ],
                rowsPerPage: options.rowsPerPage || 5,
                startRow: options.currentPage
                    ? ((options.currentPage - 1) * options.rowsPerPage) + 1 : 0,
                viewId: -1,
            },
            dataEntitlements: [options.dataEntitlements],
        };

        // if not on insert mode, send user id
        if (options.mode !== constants.MODES.INSERT) {
            postData.listViewRequest.searchFields.push(this.createSearchField({
                value: options.userId,
                name: 'USERID',
            }));
        }

        // if a copyuser id is passed in
        if (options.copyUserId) {
            postData.listViewRequest.searchFields.push(this.createSearchField({
                value: options.copyUserId,
                name: 'COPYEXISTINGUSER',
            }));
        }

        return postData;
    },

    sync(method, model, options) {
        const accountLimitsService = services.generateUrl('/users/userCentric/v2/getInterimDataEntitlementLimits');
        const postData = this.createServiceParam(options);

        /*
         * dataEntitlements are not empty
         * -- OR --
         * INSERT with a copyuserid passed
         */
        if ((!util.isEmpty(options.dataEntitlements))
                || (options.mode === constants.MODES.INSERT && options.copyUserId)) {
            http.post(accountLimitsService, postData, (result) => {
                options.success(result);
            }, () => {
                options.error();
            });
        } else {
            /**
             * Avoid server request if purely insert mode and/or if no data entitlements have
             * been selected.
             */
            options.success({
                result: {
                    rows: null,
                },
            });
        }
    },

    /**
     * Checks the column object type and display order to see if it's hidden.
     * @param {object} column
     * @returns {boolean} true if the column is hidden, false otherwise
     */
    isColumnHidden(column) {
        return util.isNullOrUndefined(column) || !util.isObject(column)
            || util.isNullOrUndefined(column.type)
            || column.type === dynamicPagesConstants.COLUMN_TYPE_HIDDEN
            || util.isNullOrUndefined(column.displayOrder) || column.displayOrder < 0;
    },

    parse(result, options) {
        const optionsParam = options;
        const returnArray = [];
        const currentFutureMessage = locale.get(optionsParam.isACH ? 'uce.currentFuture.ach' : 'uce.currentFuture.accounts');

        const offsetAccountField = util.where(result.rowHeader, {
            fieldName: 'offsetAccountNum',
        });
        this.showOffsetAccountNumber = !this.isColumnHidden(util.first(offsetAccountField));

        util.each(result.rows, (row) => {
            const data = transform.pairsToHash(row.columns, 'fieldName', 'fieldValue');
            data.typeCode = optionsParam.dataEntitlements.typeCode;
            data.productCode = optionsParam.dataEntitlements.productCode;
            data.id = `${data.bankCode || data.clearingSystem}-${data.typeCode}-${data.accountNumber}`;

            /*
             * Override if current/future since it just needs a localized resource that spans
             * 2 columns. On modify we need to check the result of the account name and number
             * to indicate current/future.
             */
            if (optionsParam.dataEntitlements.currentFuture || (data.accountName === '_ALL_' && data.accountNumber === '_ALL_')) {
                // Set in case _ALL_
                optionsParam.dataEntitlements.currentFuture = true;
                data.accountName = currentFutureMessage;
                data.accountNumber = '';
            }

            // Change to boolean since listviewrequest has it as a string.
            util.each(['manageAction', 'repairAction', 'approveAction', 'modifyAction'], (action) => {
                const value = data[action];
                data[action] = value === 'true' || value === 'false' ? value === 'true' : value;
            });

            returnArray.push(data);
        });
        return returnArray;
    },

    // TODO merge back the save to sync, at least part of the check of the method.
    save(options) {
        const limits = [];
        const accountLimitsService = services.generateUrl('/users/userCentric/v2/updateInterimDataEntitlementLimits');

        this.each((model) => {
            /*
             * Grab isACH from step 2 if that is the caller
             * Step 2 sends a collection so we need to look at
             * each model to determine if isACH.
             */
            const isACH = model.get('isACH');

            // server does not want id but needs typecode.
            const jsonData = util.omit(model.toJSON(), ['id', 'ACCOUNTNUMBER_DISP', 'isACH']);

            // Unescape account number and name for server
            jsonData.accountName = options.currentFuture ? '_ALL_' : util.unescape(jsonData.accountName);
            jsonData.accountNumber = util.unescape(jsonData.accountNumber);
            jsonData.dataEntFilterAttribute = options.isACH || isACH ? 'ACHCompany' : 'BankAccount';
            limits.push(jsonData);
        });

        http.post(accountLimitsService, {
            limits,
            group: options.byGroup,
            actionsOnly: options.actionsOnly || false,
        }, (result) => {
            options.success(result);
        }, () => {
            options.error();
        });
    },
});
