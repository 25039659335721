var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<div class=\"nacha-input-error-container\">\n<span class=\"icon-exclamation-circle\"></span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "has-error";
},"5":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"7":function(container,depth0,helpers,partials,data) {
    return "</div>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasError") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":4,"column":7}}})) != null ? stack1 : "")
    + "<input tabindex=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"tabindex") || (depth0 != null ? lookupProperty(depth0,"tabindex") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tabindex","hash":{},"data":data,"loc":{"start":{"line":5,"column":17},"end":{"line":5,"column":29}}}) : helper)))
    + "\"\n    class=\"nacha-amount-input form-control "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasError") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":43},"end":{"line":6,"column":75}}})) != null ? stack1 : "")
    + "\"\n    data-hook=\"getNachaAmountInput\"\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":8,"column":46}}})) != null ? stack1 : "")
    + "    \n    id=\"amount-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":9,"column":15},"end":{"line":9,"column":21}}}) : helper)))
    + "\"\n    name=\"AMOUNT\"\n    tabindex=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"tabindex") || (depth0 != null ? lookupProperty(depth0,"tabindex") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tabindex","hash":{},"data":data,"loc":{"start":{"line":11,"column":14},"end":{"line":11,"column":26}}}) : helper)))
    + "\"\n    type=\"text\"\n    value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"AMOUNT") || (depth0 != null ? lookupProperty(depth0,"AMOUNT") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"AMOUNT","hash":{},"data":data,"loc":{"start":{"line":13,"column":11},"end":{"line":13,"column":21}}}) : helper)))
    + "\"\n    maxlength=\"17\">\n<span class=\"nacha-display-amount\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"AMOUNT") || (depth0 != null ? lookupProperty(depth0,"AMOUNT") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"AMOUNT","hash":{},"data":data,"loc":{"start":{"line":15,"column":35},"end":{"line":15,"column":45}}}) : helper)))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasError") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":0},"end":{"line":16,"column":29}}})) != null ? stack1 : "");
},"useData":true});