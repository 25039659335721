import { createUseStyles } from '@glu/theming';

const styles = (theme) => {
    const {
        palette,
    } = theme;
    return {
        spaceBelow: {
            marginBottom: 10,
        },

        helpBlock: {
            color: palette.form.error,
            fontSize: 12,
        },

        extendReasons: {
            display: 'inline-block',
            width: '60%',
            marginRight: 10,
            '& label': {
                width: '100%',
                '&::before': {
                    content: '"*"',
                    color: palette.form.error,
                },
                '& p:first-of-type': {
                    margin: [-18, 0, 5, 8],
                    paddingBottom: 0,
                },
            },

        },

        required: {
            '&::before': {
                content: '"* "',
                color: palette.form.error,
            },
            marginBottom: 0,
        },

        extendRequestButton: {
            marginRight: 10,
        },
        extendLabel: {
            fontWeight: 'bold',
            display: 'inline-block',
            lineHeight: 5,
            position: 'absolute',
        },
    };
};

export default createUseStyles(styles);
