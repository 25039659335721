var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"TEMPLATE_CODE") || (depth0 != null ? lookupProperty(depth0,"TEMPLATE_CODE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TEMPLATE_CODE","hash":{},"data":data,"loc":{"start":{"line":1,"column":19},"end":{"line":1,"column":36}}}) : helper)))
    + "</span>\n<span class=\"description\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"TEMPLATE_DESCRIPTION") || (depth0 != null ? lookupProperty(depth0,"TEMPLATE_DESCRIPTION") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TEMPLATE_DESCRIPTION","hash":{},"data":data,"loc":{"start":{"line":2,"column":26},"end":{"line":2,"column":50}}}) : helper)))
    + "</span>\n<span class=\"beneficiary\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"BENE_NAME") || (depth0 != null ? lookupProperty(depth0,"BENE_NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BENE_NAME","hash":{},"data":data,"loc":{"start":{"line":3,"column":26},"end":{"line":3,"column":39}}}) : helper)))
    + "</span>\n<span class=\"type\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"TYPE_DESCRIPTION") || (depth0 != null ? lookupProperty(depth0,"TYPE_DESCRIPTION") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TYPE_DESCRIPTION","hash":{},"data":data,"loc":{"start":{"line":4,"column":19},"end":{"line":4,"column":39}}}) : helper)))
    + "</span>\n\n";
},"useData":true});