import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import SimpleEntitlement from '../../models/user/simpleEntitlement';

export default Collection.extend({
    model: SimpleEntitlement,

    hasAnyEntitledAction() {
        let hasView = false;

        this.each((item) => {
            if (item.get('view.entitled')) {
                hasView = true;
            }
        });

        return hasView || this.getEntitledActions().length > 0;
    },

    getEntitledActions() {
        const actions = [];
        let available = ['manage', 'approve', 'modify', 'repair'];

        util.each(this.models, (model) => {
            if (available.length > 0) {
                util.each(available, (action) => {
                    if (model.get(action) && model.get(action).entitled) {
                        actions.push(action);
                        available = util.without(available, action);
                    }
                });
            } else {
                return actions;
            }
            return undefined;
        });

        return actions;
    },

    selectAll(flag) {
        this.each((model) => {
            const modelParam = model;
            util.each(model.attributes, (obj, attr) => {
                const objParam = obj;
                if (attr !== 'signatures' && attr !== 'autoApprove' && objParam && Object.prototype.hasOwnProperty.call(objParam, 'applicable') && objParam.applicable) {
                    objParam.entitled = flag;
                }
            });

            // handle signatures
            if (modelParam.get('signatures') && modelParam.get('signatures').applicable) {
                modelParam.get('signatures').assignedValue = flag ? 1 : 0;
            }

            // have to do view/autoApprove at the end if deselecting, since others depend on it
            modelParam.get('view').entitled = flag;
            if (modelParam.get('autoApprove')) {
                modelParam.get('autoApprove').entitled = flag;
            }
        });
    },
});
