import Model from '@glu/core/src/model';
import locale from '@glu/locale';
import validatorPatterns from 'system/validatorPatterns';
import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import ApproverSequenceModel from './approverSequence';

export default Model.extend({
    defaults() {
        return {
            amount: null,
            approverSequences: new Collection([
                {},
            ], { model: ApproverSequenceModel }),
        };
    },

    initialize() {
        this.validators = {
            amount: {
                description: locale.get('PS.ThresholdAmount'),
                matches: validatorPatterns.AMOUNT_PATTERN,
                exists: true,
            },
        };
    },

    validate(attributes, options) {
        const attributeErrors = Model.prototype.validate.call(this, attributes, options);

        const errors = attributes.approverSequences
            .map(approverSequence => approverSequence.isValid());

        if (util.contains(errors, false)) {
            return 'Invalid Approver Sequence(s)';
        }
        return attributeErrors;
    },
});
