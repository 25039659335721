import Layout from '@glu/core/src/layout';
import GridApi from 'common/dynamicPages/api/grid';
import ELearningContentModel from 'app/administration/models/elearningContentModel';
import ListView from 'common/dynamicPages/views/workflow/list';
import viewContentTabTmpl from './viewContentTab.hbs';

export default Layout.extend({
    template: viewContentTabTmpl,

    events: {
        'click [data-hook="insert-content-button"]': 'addElearningContent',
    },

    initialize() {
        const context = {
            serviceName: '/cxp/elearningContent',
            actionMode: 'SELECT',
            productCode: 'CXP',
            functionCode: 'MAINT',
            typeCode: 'ELEARN',
            gridId: 30002,
        };
        ListView.prototype.setListViewConfig.call(this, context);
        const gridOptions = {
            context,
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,
            enableSavedViews: true,
            selector: 'none',
            lvc: this.lvc,
        };

        this.gridView = GridApi.createServiceGridView(gridOptions);

        this.listenTo(
            this.gridView,
            {
                rowAction: this.handleRowAction,
            },
            this,
        );
        ListView.prototype.setupGridAvailableListener.call(this);
    },

    onShow() {
        this.gridRegion.show(this.gridView);
    },

    addElearningContent() {
        this.navigateTo('/CXP/addElearningContent');
    },

    handleRowAction(data) {
        const self = this;

        const contentId = data.model.get('CONTENTID');

        if (data.action === 'select') {
            this.navigateTo(`/CXP/viewElearningContent/${contentId}`);
        } else if (data.action === 'modify') {
            this.navigateTo(`/CXP/updateElearningContent/${contentId}`);
        } else if (data.action === 'delete') {
            const tempModel = new ELearningContentModel();
            tempModel.set('contentId', contentId);
            tempModel.destroy({
                success() {
                    self.gridView.refreshGridData();
                },

                error() {
                    self.gridView.refreshGridData();
                },
            });
        }
    },
});
