import store from 'pcm/common/store';
import React from 'react';
import locale from '@glu/locale';
import ContentLoading from 'pcm/common/components/ContentLoading';
import EntitlementProvider from 'pcm/common/entitlementContext/EntitlementProvider';
import { eventConfigEntitlements } from './EventConfigUtils';

const EventConfig = React.lazy(() => import('./EventConfigList'));

const getActionToTitleOptions = () => ({
    title: {
        view: locale.get('outboundScheduleConfig.viewEventConfigList'),
    },
    backLink: '/ui-admin-portal/ADMINSTRATION/eventConfig',
});

export default {

    eventConfig() {
        this.showPage(
            locale.get('eventConfig.list'),
            <React.Suspense fallback={<ContentLoading />}>
                <EntitlementProvider entitlements={eventConfigEntitlements}>
                    <EventConfig />
                </EntitlementProvider>
            </React.Suspense>,
            {
                headlineText: locale.get('eventConfig.list'),
            },
        );
    },

    eventConfigActions(action) {
        const components = {
        };

        store.action = action;
        store.alerts = {};

        const actionToTitleOptions = getActionToTitleOptions();
        const ComponentToShow = components[action];

        this.showPage(
            actionToTitleOptions.title[action],
            <React.Suspense fallback={<ContentLoading />}>
                <EntitlementProvider entitlements={eventConfigEntitlements}>
                    <ComponentToShow action={action} />
                </EntitlementProvider>
            </React.Suspense>,
            {
                headlineText: actionToTitleOptions.title[action],
                backLink: actionToTitleOptions.backLink,
            },
        );
    },
};
