import Controller from '@glu/core/src/controller';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import constants from 'common/dynamicPages/api/constants';
import transform from 'common/util/transform';
import services from 'services';

export default Controller.extend({
    initialize(options) {
        this.buildAction(options);
        this.inquiryId = (options && options.isAdmin) ? '25086' : '25090';
        this.request = this.createDefault();
        this.url = services.generateUrl('/tableMaintenance/getLookupResults');
    },

    setStartRow(start) {
        this.request.startRow = start;
    },

    setSearch(str) {
        this.request.searchFields[0].fieldValue = [str];
    },
    setSearchOperator(str) {
        this.request.searchFields[0].operator = str;
    },

    setPageSize(rowsPerPage) {
        this.request.rowsPerPage = (rowsPerPage > constants.MAX_SERVER_ROWSPERPAGE)
            ? constants.MAX_SERVER_ROWSPERPAGE : rowsPerPage;
    },

    buildAction(options) {
        this.action = {};

        if (options && options.isAdmin) {
            this.action = {
                typeCode: 'CLUSR_TM',
                entryMethod: 0,
                actionMode: 'SELECT',
                functionCode: 'MAINT',
                productCode: 'CMAINT',
            };
        } else {
            this.action = {
                typeCode: '_USER',
                entryMethod: 0,
                actionMode: 'SELECT',
                functionCode: 'USERS',
                productCode: '_ADMIN',
            };
        }
    },

    createDefault() {
        const self = this;
        const requestParameters = {
            INQUIRYID: this.inquiryId,
        };
        return {
            rowsPerPage: 50,
            startRow: 1,
            fieldName: 'USERGROUP',
            depends: [],
            subType: '*',

            searchFields: [{
                fieldName: 'USERGROUP',
                operator: 'CONTAINS',
                fieldValue: [],
                dataType: 'text',
            }],

            typeCode: self.action.typeCode,
            entryMethod: 0,
            action: self.action.actionMode,
            functionCode: self.action.functionCode,
            productCode: self.action.productCode,

            requestParameters: {
                item: transform.hashToPairs(requestParameters),
            },
        };
    },

    getRequest() {
        return this.request;
    },

    send() {
        return http.post(this.url, this.getRequest())
            .then(this.parseResponse, this.handleError);
    },

    parseResponse(response) {
        const data = util.map(response.rows, (rowItem) => {
            const mapTmpItem = transform.pairsToHash(rowItem.mapDataList, 'toField', 'value');
            const isEqualOne = str => str === '1';
            return {
                id: rowItem.columns[0].fieldValue,
                label: rowItem.columns[0].fieldValue,
                text: rowItem.columns[0].fieldValue,
                parentUserGroup: mapTmpItem.ParentUserGroup,
                isMobile: mapTmpItem.UGMobile === '1',
                isMobileHybrid: mapTmpItem.UGMobileHybrid === '1',
                timezone: mapTmpItem.TimeZoneID,
                locale: mapTmpItem.Locale,
                isSMB: mapTmpItem.MARKETSEGMENT === 'SMB',
                allowSelfAdmin: mapTmpItem.ALLOWSELFADMINISTRATION === '1',
                allowAccountUnmasking: mapTmpItem.ALLOWACCOUNTUNMASKING === '1',
                usachApproveOwnImport: mapTmpItem.ACHALLOWSNGLADMINPAYIMPORT === '1',
                usachApproveOwnPayment: mapTmpItem.ACHALLOWSNGLADMINPAYFROMTMPL === '1',
                usachApproveOwnTemplate: mapTmpItem.ACHALLOWSNGLADMINTMPL === '1',
                rtgsApproveOwnImport: mapTmpItem.RTGSALLOWSNGLADMINPAYIMPORT === '1',
                rtgsApproveOwnPayment: mapTmpItem.RTGSALLOWSNGLADMINPAYFROMTMPL === '1',
                rtgsApproveOwnTemplate: mapTmpItem.RTGSALLOWSNGLADMINTMPL === '1',
                applicationSuperUserInd: mapTmpItem.UGApplicationSuperUserInd,
                superUserInd: mapTmpItem.UGSuperUserInd,
                allowConfidentialPayments: mapTmpItem.UGAllowConfidentialPayments === '1',
                optUISecurityEnabled: mapTmpItem.OPTUISECURITYENABLED === '1',
                optUISecurityCheck: mapTmpItem.OPTUISECURITYCHECK === '1',
                optUISecurityReason: mapTmpItem.OPTUISECURITYREASON,
                hasPanelApproval: mapTmpItem.PaymentApprovalType === '1',
                mapDataList: rowItem.mapDataList,
                isCompanyRequireBeneFromAdressBookForPayments:
                        isEqualOne(mapTmpItem.CompanyRequireBeneFromAdressBookForPayments),
                isCompanyRequireBeneFromAdressBookForTemplates:
                        isEqualOne(mapTmpItem.CompanyRequireBeneFromAdressBookForTemplates),
                enablePushNotifications: mapTmpItem.ENABLEPUSHNOTIFICATIONS === '1',
                isUce: mapTmpItem.USERPERMISSIONSETTINGS === '3',
            };
        });

        return {
            data,
            totalRows: response.totalRows,
        };
    },

    handleError() {},
});
