import Layout from '@glu/core/src/layout';
import CheckReview from 'common/dynamicPages/views/checkReview';
import cdImageTmpl from 'app/reports/views/cd/cdImage.hbs';

export default Layout.extend({
    template: cdImageTmpl,

    initialize(options) {
        this.model = options.model;
        this.checkView = new CheckReview({
            model: this.model,
            source: 'CD',
        });
    },

    cancel() {
        this.navigateTo('REPORTING/ctrlDisbursementReport');
    },

    onRender() {
        this.imageViewer.show(this.checkView);
    },
});
