import ManageFiltersLink from 'no-override!@glu/grid/src/filter/manageFiltersLink';
import AdvancedSearch2 from './advancedSearch2';

export default ManageFiltersLink.extend({
    initialize() {
        this.grid = this.options.grid;
        this.advancedFilterView = new AdvancedSearch2({
            collection: this.collection,
            filterViewClass: this.options.filterViewClass,
            columns: this.options.columns,
            grid: this.grid,
            filterProc: this.options.filterProc,
        });
        this.listenTo(this.advancedFilterView, 'submit', this.handleAdvancedFilterSubmit);
    },
});
