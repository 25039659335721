import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import exportSearchTemplate from 'app/reports/views/exportBTRData/exportBTRData.hbs';
import locale from '@glu/locale';
import constants from 'app/balanceAndTransaction/constants';
import dialog from '@glu/dialog';
import services from 'services';
import http from '@glu/core/src/http';
import filterApi from 'common/dynamicPages/api/filters';
import alert from '@glu/alerts';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import moment from 'moment';
import $ from 'jquery';
import dateUtil from 'common/util/dateUtil';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import LookupCollection from 'common/dynamicPages/collections/lookup';
import ExportTypeCollection from 'app/reports/collections/exportTypes';
import ExportTypeCSVView from 'app/reports/views/exportBTRData/cvs/exportBTRCSVDetail';
import ExportTypeBAIView from 'app/reports/views/exportBTRData/bai/exportBAIDetail';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import ExportTypeCAMTView from 'app/reports/views/exportBTRData/camt/exportCAMTDetail';
import helpers from 'components/AsyncReporting/helpers';
import mobileUtil from 'mobile/util/mobileUtil';
import configuration from 'system/configuration';

const csvExportTypes = ['CSVEXP', 'CSBEXP', 'TSVEXP'];

export default Layout.extend({
    template: exportSearchTemplate,

    attributes: {
        class: 'BTRExportModal',
    },
    ui: {
        $submitButton: 'div.modal-footer button[name="submit"]',
        $exportButtons: '.postSubmission',
        $baiTypes: '.baiTypes',
        $exportType: '[name="EXPTYPECODE"]',
        $baiType: '[name="BAITYPE"]',
        $date: '[name="DATE"]',
        $conditionalField: '[data-hook="getExportConditionalField"]',
        $panel: '.panel-tertiary',
        $exportFileName: '[data-hook="getExportFileName"]',
        $BAIOptions: '[data-hook="getBAIOptions"]',
        $exportTypeDropdown: '[data-hook="getExportTypeCode"]',
    },

    initialize(options) {
        this.dateOptions = {
            showCalendarIcon: true,
            rangeDatePicker: true,
            pastOnly: true,
            allowWeekends: true,

            locale: {
                format: userInfo.get('dateFormat'),
            },
            drops: 'down',
        };

        this.model = new Model();
        this.model.validators = this.setValidators();

        this.dateRangeSelected = options.dateRangeSelected;
        this.defaultDates = options.defaultDates || {
            startDate: null,
            endDate: null,
        };

        this.accountArray = [];
        this.accountGroupArray = [];

        // Create the model bound to export filter
        this.filterModel = this.createExportFilterModel(
            options.filterModel,
            options.initialFilterValues,
        );

        if (options.typeAheadData) {
            this.typeAheadData = options.typeAheadData;
        }

        /*
         * preset date values in filterModel using datepicker values
         * currently bound to the filterView model
         */
        if (options.dateRangeValue) {
            this.filterModel.set('DATE', options.dateRangeValue);
            this.model.set('DATE', options.dateRangeValue);
        }

        this.currentTab = options.currentTab;

        // Get account type either from options from bal or tran listview.
        if (options.accountType) {
            this.accountType = options.accountType;
        } else if (options.gridExportOptions) {
            this.accountType = options.gridExportOptions.accountType;
        }

        /*
         * set specific permission props if using the export tool from specific BTR pages
         * will dictate what export options the user will have
         */
        this.permissionProps = (options.currentTab && options.entitlements)
            ? this.getPermissionProps(options.currentTab, options.entitlements) : {};

        this.exportTypeCollection = new ExportTypeCollection(
            [],
            {
                inquiryId: this.options.exportTypeInquiryId,
                typeRestrictions: this.permissionProps.typeRestrictions,
            },
        );

        this.conditionalFieldsModel = new Model({
            DATE: false,
        });

        this.listenTo(this.conditionalFieldsModel, 'change', this.showConditionalField);

        this.listenTo(this.model, 'change:DATE', this.setFilterModelDate);
        this.dialogTitle = locale.get('gir.export.data.title');
        this.dialogButtons = [{
            text: locale.get('button.createExport'),
            className: 'btn btn-primary postSubmission',
            callback: 'submit',
        }, {
            text: locale.get('button.cancel'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }];
    },

    /**
     * @param {Model} initialFilterModel
     * @param {object} initialFilterValues
     * @return {Model} exportFilterModel
     * Takes in the passed in model and model options, and returns a model
     * that will be tied tot he export filters.
     */
    createExportFilterModel(initialFilterModel, initialFilterValues) {
        const filterModel = initialFilterModel || new Model();

        /*
         * These are the additional filters take from the BTR page.
         * We are merging them into this.
         */
        if (initialFilterValues) {
            const filterOptions = util.reduce(initialFilterValues, (optParam, filter) => {
                const opt = optParam;
                opt[filter.fieldName] = filter.fieldValue;

                // Handling Special AMOUNT filter case
                if (filter.fieldName === 'AMOUNT') {
                    // parse out amount equality if possible (single unique case)
                    opt['AMOUNT-equality'] = filter.operator;

                    // if amount filter is a range
                    if (filter.operator === 'BETWEEN') {
                        [opt.AMOUNT, opt.AMOUNT2] = filter.fieldValue;
                    }
                }

                return opt;
            }, {});

            filterModel.set(filterOptions);
        }

        return filterModel;
    },

    /**
     * @method showConditionalField
     * When the filterApi matches a fieldName with the model attributes defined
     * in the conditionalFieldsModel, it
     * sets it to true. There is only one attribute, so on a change initialize
     * the date picker.
     */
    showConditionalField() {
        const childFilterView = this.typeBasedFilterRegion.currentView;
        const currentFilterView = childFilterView || this.filterRegion.currentView;
        // 'I' for 'pending', 'P' for 'cleared' status type in Optional fields
        if (this.currentTab === constants.ACCOUNT_SUMMARY.TABS.PRIOR) {
            this.filterRegion.currentView.model.set('POSTED_FLAG', 'P');
        } else if (this.currentTab === constants.ACCOUNT_SUMMARY.TABS.CURRENT) {
            this.filterRegion.currentView.model.set('POSTED_FLAG', 'I');
        }
        const $date = (childFilterView) ? childFilterView.ui.$date : this.ui.$date;
        const $conditionalField = (childFilterView)
            ? childFilterView.ui.$conditionalField : this.ui.$conditionalField;
        const activeRange = this.dateRangeSelected;
        const isCurrentDay = this.currentTab === constants.ACCOUNT_SUMMARY.TABS.CURRENT
            || this.currentTab === constants.ACCOUNT_SUMMARY.TABS.CASH_POSITION;

        /*
         * Hide the date filter field for BAI Current Day and SWIFT 942 export types
         * show for other cases
         */
        if (this.model.get('EXPTYPECODE') === 'SWMT942' || isCurrentDay) {
            // by default set the date to today in this case
            const today = moment(new Date()).format(userInfo.getDateFormat());
            currentFilterView.model.set('DATE', today);
            this.model.set('DATE', today);
        } else {
            // Will apply to the child typeBased FilterView if one exists
            $date.nhDatePicker((childFilterView && childFilterView.dateOptions)
                ? childFilterView.dateOptions
                : this.dateOptions, this.setSelectedDatePickerRangeValues.bind(this));

            if (activeRange) {
                $date.data('daterangepicker').setActiveRange(activeRange);
            }

            // show conditional field
            $conditionalField.removeClass('hide');
        }
    },

    /**
     * @method setSelectedDatePickerRangeValues
     * @param {string|moment} start
     * @param {string|moment} end
     * On select of a date range option, set the start & end dates
     */
    setSelectedDatePickerRangeValues(start, end) {
        const filterView = this.filterRegion.currentView;
        if (!filterView) {
            return;
        }
        // eslint-disable-next-line
        filterView.model._datePickerValues.DATE = [start, end];
    },

    /**
     * @method setFilterModelDate
     * Set the date field from the view onto the filterApi model
     */
    setFilterModelDate() {
        const filterView = this.filterRegion.currentView;
        if (filterView) {
            filterView.model.set({
                DATE: this.model.get('DATE'),
                START_DATE: this.model.get('START_DATE'),
                END_DATE: this.model.get('END_DATE'),
            });
        }
    },

    setValidators() {
        return {
            EXPTYPECODE: {
                description: locale.get('export.type'),
                exists: true,
            },

            EXPTFILENAME: {
                description: locale.get('ACH.FileName'),
                exists: true,
            },
        };
    },

    /**
     * @param {string} currentTab
     * @param {Model} entitlementsModel
     * @return {object} permission props
     * Forms the permission props that dictate the export options the user has
     * based on their
     * current tab and btr entitlements
     */
    getPermissionProps(currentTab, entitlementsModel) {
        const props = {
            // string
            baiRestriction: null,

            // boolean
            noTransactions: false,

            // array
            typeRestrictions: null,
        };
        switch (currentTab) {
        // PREVIOUS tabs
        case constants.ACCOUNT_SUMMARY.TABS.PRIOR:
        case constants.ACCOUNT_SUMMARY.TABS.CUSTOM_REPORT_PRIOR:
            props.noTransactions = !entitlementsModel
                .isEntitled(constants.BTR_TYPECODES.PREVIOUS.TRANSACTIONS);
            props.baiRestriction = 'prior';
            props.typeRestrictions = util.union(
                constants.EXPORT_TYPE_RESTRICTIONS.DEFAULT,
                (!props.noTransactions) ? constants.EXPORT_TYPE_RESTRICTIONS.TRANS.PRIOR : [],
            );
            break;
            // CURRENT tabs
        case constants.ACCOUNT_SUMMARY.TABS.CURRENT:
        case constants.ACCOUNT_SUMMARY.TABS.CASH_POSITION:
            props.noTransactions = !entitlementsModel
                .isEntitled(constants.BTR_TYPECODES.CURRENT.TRANSACTIONS);
            props.baiRestriction = 'current';
            props.typeRestrictions = util.union(
                constants.EXPORT_TYPE_RESTRICTIONS.DEFAULT,
                (!props.noTransactions) ? constants.EXPORT_TYPE_RESTRICTIONS.TRANS.CURRENT : [],
            );
            break;
        default:
        }

        return props;
    },

    onRender() {
        this.ui.$panel.hide();
        this.setupExportTypeDropdown();
    },

    /**
     * @method setupExportTypeDropdown
     * @param {object} [options] - optional param to add specific dropdown options
     * instantiates and renders export types dropdown, takes in options for dropdown
     * can be extended by other classes to pass in certain options
     */
    setupExportTypeDropdown(options = {}) {
        // hide submit button if preSelectFirstItem is not set to true
        if (!options.preSelectFirstItem) {
            $('.postSubmission').hide();
        }
        // fetch export options data if none exists
        this.loadExportTypeOptions()
            .then((exportTypes) => {
                // merge default props with passed in props
                const data = util.union(options.data || [], exportTypes);
                const dropdownOptions = {
                    ...options,
                    data,
                    initSelection: (element, callback) => {
                        if (options.preSelectFirstItem) {
                            callback(data[0]);
                        }
                    },
                };
                this.exportTypeDropdown = this.ui.$exportTypeDropdown.comboBox(dropdownOptions);
                this.exportTypeDropdown.on('change', (e) => {
                    this.exportTypeChange(e.val);
                });
            });
    },

    /**
     * @param {object} [typeOptions] - optional param to only allow certain export
     * type options
     * @return {Promise}
     * Promise to fetch data for export type options dropdown
     */
    loadExportTypeOptions(typeOptions) {
        return new Promise((resolve, reject) => {
            if (typeOptions) {
                resolve(typeOptions);
            } else {
                this.exportTypeCollection.fetch({
                    success: (expTypeCollection) => {
                        resolve(this.formatExportTypesData(expTypeCollection));
                    },

                    error: err => reject(err),
                });
            }
        });
    },

    /**
     * @param {array} typeModels
     * @return {array}
     * formatted export type options for dropdown use
     */
    formatExportTypesData(typeModels) {
        return typeModels.map(type => ({
            id: type.get('TYPECODE'),
            name: type.get('DESCRIPTION'),
            text: type.get('DESCRIPTION'),
        }));
    },

    /**
     * @method exportTypeChange
     * @param {string} exportType
     * Callback method after an export type is chosen by the user.
     * Will commence loading related filters for export
     */
    exportTypeChange(exportType) {
        const dateFormat = userInfo.getDateFormat();
        this.model.set('EXPTFILENAME', `${exportType}_${moment(new Date()).format(`${dateFormat.replace(/\//g, '')}_hhmmss`)}`);
        this.model.set('EXPTYPECODE', exportType);

        this.ui.$submitButton.attr('aria-busy', true);
        this.showFilterRegionsByType(exportType);
        this.handleContinue();
    },

    /**
     * @method showFilterRegionsByType
     * @param {string} exportType
     * Shows certain specific filter fields/views based on the export type chosen
     */
    showFilterRegionsByType(exportType) {
        const typeBasedFilterView = this.getFilterViewByType(exportType);

        // clear any regions
        this.typeBasedFilterRegion.reset();
        this.ui.$conditionalField.addClass('hide');

        /*
         * not all export types will have their own specific filterview,
         * might only have a general conditional field initiated elsewhere from filterAPI call
         */
        if (typeBasedFilterView) {
            this.typeBasedFilterRegion.show(typeBasedFilterView);
        }
    },

    /**
     * @param {string} exportType
     * Returns specific filter fields/views based on the export type chosen
     */
    getFilterViewByType(exportType) {
        let typeBasedFilterView;
        this.dateOptions.pastOnly = exportType !== 'CSBEXP'; // Reset the value based on exportType
        switch (exportType) {
        case 'BAIEXP':
            typeBasedFilterView = new ExportTypeBAIView({
                model: this.model,
                baiRestriction: this.permissionProps.baiRestriction,
                dateOptions: this.dateOptions,
            });
            break;
        case 'CAMTEXP':
            typeBasedFilterView = new ExportTypeCAMTView({
                model: this.model,
                dateOptions: this.dateOptions,
            });
            break;
        case 'CSVEXP':
        case 'TSVEXP':
            typeBasedFilterView = new ExportTypeCSVView({
                model: this.model,
                noTransactions: this.permissionProps.noTransactions,
                dateOptions: this.dateOptions,
            });
            break;
        default:
            break;
        }
        if (typeBasedFilterView) {
            typeBasedFilterView.listenTo(this.model, 'change:OUTPUTCONTENT', () => {
                this.handleContinue();
            });
        }
        return typeBasedFilterView;
    },

    /**
     * @method displayError errorCode
     * @param {string} message
     * @param {object} [options]
     * Show error as an alert
     */
    displayError(message, options) {
        if (this.$('.fetch-transactions-error').length > 0) {
            this.$('.fetch-transactions-error').remove();
        }
        const errorMessage = alert.danger(message, options || {});
        errorMessage.$el.addClass('fetch-transactions-error');
        this.alertRegion.show(errorMessage);
    },

    getDateError(exportType, today, startDate, endDate, baiType) {
        /*
         * BAI Type is P:Prior, I:Current (Intraday?)
         * Determine correct startDate error message based on the existence of an endDate
         */
        const INVALID_START = endDate ? 'GIR.error.invalidStartDate' : 'GIR.error.invalidDate';

        const INVALID_END = 'GIR.error.invalidEndDate';
        const isBAICurrent = exportType === 'BAIEXP' && baiType === 'I';
        const isBAIPrior = exportType === 'BAIEXP' && baiType === 'P';
        const isSWMT942 = exportType === 'SWMT942';
        const isSWMT940 = exportType === 'SWMT940';
        const isCSVEXP = util.contains(csvExportTypes, exportType);

        if (isSWMT942) {
            if (startDate.diff(today, 'days') !== 0) {
                return INVALID_START;
            }

            if (endDate && endDate.diff(today, 'days') !== 0) {
                return INVALID_END;
            }
        } else if (isBAICurrent) {
            // BAI Current allows today
            if (startDate.isAfter(today, 'day')) {
                return INVALID_START;
            }

            // If there is an endDate
            if (endDate && endDate.isAfter(today, 'day')) {
                return INVALID_END;
            }
        } else {
            // BAI Prior and MT940 don't allow today
            if (((isBAIPrior || isSWMT940) && today.isSame(startDate, 'day')) || today.isBefore(startDate, 'day')) {
                return INVALID_START;
            }

            // If there is an endDate
            if (isCSVEXP) {
                // CSV types support current-day ending
                if (endDate && (today.isBefore(endDate, 'day'))) {
                    return INVALID_END;
                }
            } else if (endDate && (today.isBefore(endDate, 'day') || today.isSame(endDate, 'day'))) {
                return INVALID_END;
            }
        }

        return false;
    },

    validateDateFields(additionalSearchFields) {
        const today = moment(new Date());
        const exportType = this.model.get('EXPTYPECODE');
        const baiType = (exportType === 'BAIEXP' ? this.model.get('BAITYPE') : undefined);

        const dateField = util.find(additionalSearchFields, field => field.fieldName === 'DATE');

        let dateValues;
        let startDate;
        let endDate;
        let validationResponse;

        if (dateField) {
            dateValues = dateField.fieldValue;
            startDate = moment(dateValues[0].trim());

            if (dateValues.length === 2) {
                endDate = moment(dateValues[1].trim());
            }

            validationResponse = this.getDateError(
                exportType,
                today,
                startDate,
                endDate,
                baiType,
            );

            if (validationResponse) {
                this.displayError(locale.get(validationResponse));
                return true;
            }
        }
        return undefined;
    },

    buildExportModel(additionalSearchFields) {
        const exportType = this.model.get('EXPTYPECODE');
        const outputContent = this.model.get('OUTPUTCONTENT');

        this.exportModel = {
            actionData: {
                productCode: 'GIR',
                functionCode: 'EXPORT',
                typeCode: exportType,
                actionMode: 'CREATE',
            },

            fileName: this.model.get('EXPTFILENAME'),
            accountFilter: '',
            outputContent: '',
            searchFields: [],
            filterID: 0,
            accountType: this.accountType,
        };
        /*
         * There is a config param that allows the user to break transaction
         * details into separate columns. Only when that config is on, should
         * the splitTransactions property be added to the request data
         */
        if (serverConfigParams.get('SeparateTransDetailsMultiColumns')) {
            this.exportModel = {
                ...this.exportModel,
                split88Record: this.model.get('split88Record'),
            };
        }
        for (let i = 0; i < additionalSearchFields.length; i += 1) {
            if (additionalSearchFields[i].fieldName === 'DATE') {
                const dateValues = additionalSearchFields[i].fieldValue;
                if (dateValues.length === 2) {
                    this.exportModel.startDate = dateValues[0].trim();
                    this.exportModel.endDate = dateValues[1].trim();
                } else {
                    this.exportModel.startDate = dateValues[0].trim();
                    this.exportModel.endDate = dateValues[0].trim();
                }
                this.exportModel.searchFields.push(additionalSearchFields[i]);
            } else if (['ACCOUNTFILTER', 'ACCOUNTFILTER2'].includes(additionalSearchFields[i].fieldName)) {
                const value = additionalSearchFields[i].fieldValue;
                /*
                 * NH-161585 ACCOUNTFILTER and ACCOUNTFILTER2 can be arrays of values.
                 * When empty, do not push the empty array to the searchField and into the request.
                 */
                if (value.length) {
                    this.exportModel.accountFilter = value.join(',');
                    this.exportModel.searchFields.push(additionalSearchFields[i]);
                }
            } else if (util.isArray(additionalSearchFields[i].fieldValue)
                && additionalSearchFields[i].fieldValue.length === 0) {
                /*
                 * "No value" will be represented by a zero length array
                 * when a user changes his mind and, say, selects a
                 * BAI_GROUP_CODE and then deletes it, or if he deletes the value copied over
                 * from the preceding page.  If no
                 * selection is made then filterApi.gatherFilterData() will not give it to
                 * us at all.
                 */
            } else {
                this.exportModel.searchFields.push(additionalSearchFields[i]);
            }
        }

        if (this.model.get('BAITYPE') === 'P') {
            this.exportModel.postedFlag = 'P';
        } else {
            this.exportModel.postedFlag = 'I';
        }
        const typesRequiringOutputContenct = [
            constants.EXPORT_TYPE.BAIEXP,
            constants.EXPORT_TYPE.CSVEXP,
            constants.EXPORT_TYPE.TSVEXP,
            constants.EXPORT_TYPE.CAMTEXP,
        ];
        if (typesRequiringOutputContenct.includes(exportType)) {
            this.exportModel.outputContent = outputContent;
            this.exportModel.excludeHeader = this.model.get('EXCLUDEHEADER') || '';
        }

        this.exportModel.filterID = this.getFilterIdByExportType(exportType, outputContent) || -1;
    },

    cancel() {
        dialog.close();
    },

    doExport() {
        const self = this;
        const expService = services.generateUrl('/girexport/createGIRExport');

        http.post(expService, this.exportModel).then((dataParam) => {
            const data = dataParam;
            let confirms = null;
            if (data.confirms) {
                confirms = new Confirms({
                    confirms: data.confirms,
                });
            }
            if (data.errorCode && data.errorCode > 0) {
                // Replace empty/missing messages with an generic one.
                if (!data.messages || !data.messages.length) {
                    data.messages = locale.get('listviewexport.queue.fail.title');
                }

                self.displayError(
                    data.messages,
                    {
                        details: confirms,
                    },
                );

                // Reset action button when showing errors.
                self.ui.$submitButton.attr('aria-busy', false);
            } else {
                dialog.close();
                self.trigger('doExport');
                this.triggerAsyncReportPoller();
            }
        }, () => {
            dialog.close();
            self.trigger('doExportFailed');
        });
    },

    /**
     * @method triggerAsyncReportPoller
     * @description will trigger async report poller when user export from girexport/createGIRExport
     */
    triggerAsyncReportPoller() {
        const isClient = configuration.isClient();
        const isMobile = mobileUtil.isNativeApp() || mobileUtil.isMobileScreen();
        if (isClient && !isMobile) {
            helpers.setupAsyncReportPoller();
        }
    },

    getCacheName() {
        return `model-${this.filterContextDef.serviceName}-GIR-EXPORT`;
    },

    /**
     * @param {string} exportType
     * @param {string} [outputContent]
     * @return {number} filterID
     * Returns correct filterID for export type passed in (special case with
     * output content)
     */
    getFilterIdByExportType(exportType, outputContent) {
        // current special case for GIR_BAL output content and CSV export Type
        if ((util.contains(csvExportTypes, exportType) || exportType === 'BAIEXP') && outputContent === 'GIRBAL') {
            return constants.EXPORT_TYPE_FILTER_ID.CSVEXP_GIRBAL;
        }
        return constants.EXPORT_TYPE_FILTER_ID[exportType];
    },

    // Using one Account filter disables the other
    disableAccountGroup() {
        const readonly = this.filterRegion.currentView.model.get('ACCOUNTFILTER').length > 0;
        const accountType = this.filterRegion.currentView.model.get('ACCOUNTTYPE');
        this.$('#ACCOUNTFILTER2').prop('readonly', readonly).toggleClass('read-only-field', readonly);
        if (!util.isNullOrUndefined(accountType)) {
            // Account type is an array that has one entry, CC.
            if (accountType === constants.ACCOUNT_TYPE.CREDIT_CARD[0]) {
                this.accountType = constants.ACCOUNT_TYPE_EXPORT.CREDIT_CARD;
            }
        }
    },

    disableAccount() {
        const readonly = this.filterRegion.currentView.model.get('ACCOUNTFILTER2').length > 0;
        this.$('#ACCOUNTFILTER').prop('readonly', readonly).toggleClass('read-only-field', readonly);
    },

    /**
     * @param {View|Object} filterView
     * Takes in loaded in filterView and merges the current filterModel into the view's model
     */
    onLoadFilterSection(filterView) {
        const filterModel = filterView.model;
        const baiType = this.model.get('BAITYPE');
        const exportType = this.model.get('EXPTYPECODE');

        this.listenTo(filterModel, 'change:ACCOUNTFILTER', this.disableAccountGroup);
        this.listenTo(filterModel, 'change:ACCOUNTFILTER2', this.disableAccount);

        // Make sure filterModel is set with all required fields
        filterModel.set(Object.assign(
            this.filterModel.toJSON(),
            {
                DATE: this.model.get('DATE'),
            },
        ));

        if (this.accountArray.length > 0) {
            const isMTExport = (exportType === 'SWMT942' || exportType === 'SWMT940');
            /*
             * if is MT 940,942 AND has only 1 account pre-filtered
             * OR if not MT export type at all
             * pass in preselected account filter data to filter form
             */
            if (!isMTExport || (isMTExport && this.accountArray.length === 1)) {
                filterModel.set('ACCOUNTFILTER', (this.accountArray));
            } else {
                filterModel.set('ACCOUNTFILTER', []);
            }
        }

        if (this.accountGroupArray.length > 0) {
            filterModel.set('ACCOUNTFILTER2', this.accountGroupArray);
        }

        if (this.typeAheadData && this.typeAheadData.ACCOUNTFILTER) {
            this.$('#ACCOUNTFILTER2').prop('readonly', true).addClass('read-only-field');
        }

        if (this.typeAheadData && this.typeAheadData.ACCOUNTFILTER2) {
            this.$('#ACCOUNTFILTER').prop('readonly', true).addClass('read-only-field');
        }

        if (baiType !== constants.BAI_TYPE.CURRENT) {
            /*
             * Delete the filter validator and bring it up to
             * the exposed date field in the main section.
             */
            if (util.has(filterModel.validators, 'DATE')) {
                delete filterModel.validators.DATE;
                util.extend(
                    this.model.validators,
                    {
                        DATE: {
                            // This is the localized field referenced in the filter.hbs.
                            description: locale.get('ACH.Date'),
                            exists: true,
                        },
                    },
                );
            }
        }

        if (this.defaultDates && (this.defaultDates.startDate && this.defaultDates.endDate)) {
            this.setSelectedDatePickerRangeValues(
                this.defaultDates.startDate,
                this.defaultDates.endDate,
            );
        } else if (baiType === constants.BAI_TYPE.CURRENT
            && this.ui.$conditionalField.filter(':hidden').length > 0) {
            /*
             * Because on current day export the date field is hidden,
             * derive the range to build the export model correctly
             * and to ensure the validator for the DATE field on the filter model
             * validates correctly.  Without the code the date validator fails with
             * no warning, since the date is not on the filter page.
             */
            const endDate = this.model.get('DATE');
            const dateRange = [endDate, endDate];
            filterModel.set('DATE', dateRange.join(' - '));
        }

        // Hide the date filter field for BAI Current Day and SWIFT 942 export types
        if ((exportType === 'BAIEXP' && this.model.get('BAITYPE') === 'I') || exportType === 'SWMT942') {
            const today = moment(new Date()).format(userInfo.getDateFormat());
            filterModel.set('DATE', today);

            this.$('#DATE').parents('.field-container').hide();
        } else {
            const activeRangeLabel = this.dateRangeSelected;

            if (this.dateRangePicker && activeRangeLabel && activeRangeLabel.length > 0) {
                this.dateRangePicker.setActiveRange(activeRangeLabel);
            }
        }

        if ((exportType === 'BAIEXP' && this.model.get('BAITYPE') === 'P')
            || exportType === 'QUICKBKS' || exportType === 'QUICKEN') {
            const yesterday = dateUtil.getPriorDate().format(userInfo.getDateFormat());

            filterModel.set('DATE', yesterday);
        }

        this.ui.$submitButton.attr('aria-busy', false);
    },

    /**
     * @method handleContinue
     * Triggers successive service calls to generate the additional optional and
     * conditional filters based on
     * the export type selected and the current filters applied
     */
    handleContinue() {
        const context = {
            productCode: constants.GIR,
            functionCode: constants.BTR_FUNCTIONS.EXPORT,
            typeCode: constants.BTR_TYPECODES.EXPORT.BAI_EXPORT,
        };

        this.filterContextDef = util.extend(
            {},
            context,
            {
                serviceName: 'advanceFilter',
            },
        );

        const exportType = this.model.get('EXPTYPECODE');
        const outputContent = this.model.get('OUTPUTCONTENT');
        const vfilterId = this.getFilterIdByExportType(exportType, outputContent) || -1;

        const requestData = {
            filterId: vfilterId,

            typeInfo: {
                productCode: 'GIR',
                functionCode: 'EXPORT',
                typeCode: exportType,
            },
        };

        if (this.typeAheadData && (this.typeAheadData.ACCOUNTFILTER
            || this.typeAheadData.ACCOUNTFILTER2)) {
            const typeAheadOptions = {
                field: (this.typeAheadData.ACCOUNTFILTER) ? 'ACCOUNTFILTER' : 'ACCOUNTFILTER2',
                inquiryId: (this.typeAheadData.ACCOUNTFILTER)
                    ? constants.TYPEAHEAD_INQUIRY.ACCOUNTFILTER
                    : constants.TYPEAHEAD_INQUIRY.ACCOUNTFILTER2,
                filterData: (this.typeAheadData.ACCOUNTFILTER)
                    ? this.typeAheadData.ACCOUNTFILTER : this.typeAheadData.ACCOUNTFILTER2,
                requestData,
                context,
                onSuccess: this.loadFilterRegion.bind(this),
            };

            this.loadTypeAheadFilterData(typeAheadOptions);
        } else {
            this.loadFilterRegion(requestData);
        }
    },

    /**
     * @method loadTypeAheadFilterData
     * @param {object} options - options use to load typeahead related filter data
     * @param {string} options.field - type of typeahead
     * @param {number} options.inquiryId - query item
     * @param {object} options.filterData - actual filters
     * @param {object} options.requestData - query data
     * @param {object} options.context - query data
     * @param {function} options.onSuccess - callback after operation has finished
     * Take in options to load and produce typeahead related filter data needed
     * for the dynamic filter region
     * Will call callback afterwards (most cases, will be to load the actual
     * filter region)
     */
    loadTypeAheadFilterData(options) {
        if (options.field === 'ACCOUNTFILTER') {
            // ACCOUNT Filter
            this.accountArray = options.filterData;
            options.onSuccess(options.requestData);
        } else {
            // ACCOUNT filter 2 or Account Groups
            const groupPromises = util.map(
                options.filterData,
                (item, i) => this.createAccountGroupPromises({
                    requestData: options.requestData,
                    context: options.context,
                    inquiry: options.inquiryId,
                    field: options.field,
                    index: i,
                    groupId: item,
                }),
            );

            Promise.all(groupPromises).then((results) => {
                this.accountGroupArray = util.map(results, group => group.models[0]?.id);
                options.onSuccess(options.requestData);
            });
        }
    },

    /**
     * @param {object} params - options needed for service call by lookup collections
     * @return {Promise} Promise to lookup collection
     * Creates a promise to search for account group type
     */
    createAccountGroupPromises(params) {
        const lookupCollection = new LookupCollection(
            null,
            {
                fieldName: `${params.field}LOOKUP`,
                fieldKey: params.field,
                context: params.context,
                typeInfo: params.requestData.typeInfo,
                dependentTypeAheadFields: false,

                typeAheadFields: [{
                    field: 'ACCOUNTGROUPNAME',
                    type: 'STRING',
                    operator: 'EQ',
                }],
            },
        );

        lookupCollection.queryTerm = params.groupId;
        lookupCollection.requestParameters = {
            item: [{
                name: 'PRODUCTCODE',
                value: params.requestData.typeInfo.productCode,
            }, {
                name: 'FUNCTIONCODE',
                value: params.requestData.typeInfo.functionCode,
            }, {
                name: 'TYPECODE',
                value: params.requestData.typeInfo.typeCode,
            }, {
                name: 'INQUIRYID',
                value: params.inquiry,
            }],
        };

        return new Promise((resolve, reject) => {
            lookupCollection.fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    loadFilterRegion(requestData) {
        if ((this.model.get('EXPTYPECODE') === 'BAIEXP' && this.model.get('BAITYPE') === 'P')
            || this.model.get('EXPTYPECODE') === 'QUICKBKS' || this.model.get('EXPTYPECODE') === 'QUICKEN'
            || this.model.get('EXPTYPECODE') === 'SWMT940') {
            this.datePickerOptions = {
                pastOnly: true,
                allowWeekends: true,
            };
        }

        // don't show while getting new view
        this.ui.$panel.hide();

        filterApi.getFilters(requestData, this).then(() => {
            $('.postSubmission').show();
            this.ui.$panel.show();
            // preset date values
            this.listenToOnce(this.filterRegion.currentView, 'item:rendered', () => {
                this.onLoadFilterSection(this.filterRegion.currentView);
            });
        });
    },

    submit() {
        const filterModel = this.filterRegion.currentView.model;
        const exportType = this.model.get('EXPTYPECODE');

        /**
         * BAI and CAMT don't use the common export filterModel.
         * To get around this, we push dates into the export model
         * before validation or submission.
         */
        const searchDate = this.model.get('DATE');
        const customTypes = [
            constants.EXPORT_TYPE.BAIEXP,
            constants.EXPORT_TYPE.CAMTEXP,
        ];
        if (customTypes.includes(this.model.get('EXPTYPECODE')) && !util.isEmpty(searchDate)) {
            const dates = searchDate.split(' - ');
            const start = dates[0];
            const end = dates[1] || start;

            // Push BAI date values before we collect filter data.
            filterModel.set({
                DATE: this.model.get('DATE'),
                // Always default to the date picker in the modal, so it can override the view
                START_DATE: start || this.model.get('START_DATE'),
                END_DATE: end || this.model.get('END_DATE'),
            });
            this.setSelectedDatePickerRangeValues(
                moment(filterModel.get('START_DATE')),
                moment(filterModel.get('END_DATE')),
            );
        }

        const additionalSearchFields = filterApi.gatherFilterData(filterModel);

        if (!this.model.isValid() || !filterModel.isValid()) {
            filterModel.trigger('invalid');
            return;
        }

        if (this.validateDateFields(additionalSearchFields)) {
            return;
        }
        this.$('.fetch-transactions-error').remove();

        if (exportType !== 'SWMT940' || exportType !== 'SWMT942') {
            this.$('.fetch-transactions-error').remove();
        }

        this.ui.$submitButton.attr('aria-busy', true);
        this.buildExportModel(additionalSearchFields);
        this.doExport();
    },

    templateHelpers() {
        return {
            cid: this.model.cid,
        };
    },
});
