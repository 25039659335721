import { createUseStyles } from '@glu/theming';

const styles = ({ palette }) => ({
    label: {
        color: palette.form.error,
    },
    validationMessage: {
        color: palette.form.error,
    },
});

export default createUseStyles(styles);
