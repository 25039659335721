import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import moment from 'moment';
import outputContentOptions from 'app/reports/views/exportBTRData/common/outputContentOptions';

const OUTPUTCONTENT = 'OUTPUTCONTENT';
const constants = {
    TYPE_KEY: 'BAITYPE',
    INTRA_DAY: 'I',
    PRIOR_DAY: 'P',
    RANGE_SEPARATOR: ' - ',
};

const ExportDataBase = Layout.extend({
    ui: {
        $date: '[data-hook="getDateInput"]',
        $conditionalField: '[data-hook="getExportConditionalField"]',
        $currentPriorDropdown: '[data-hook="getCurrentPriorDropdown"]',
        $outputContentDropdown: '[data-hook="getOutputContentDropdown"]',
    },
    initialize() {
        this.setupBAIDates();
    },
    setupBAIDates() {
        const today = moment(new Date()).format(userInfo.getDateFormat());

        const date = this.model.get('DATE');
        const dateRange = (date || '').split(constants.RANGE_SEPARATOR);
        const startDate = dateRange[0];
        let endDate = dateRange[1] || startDate;

        if (startDate === today && endDate === today) {
            // Today is Intra-day
            this.model.set(constants.TYPE_KEY, constants.INTRA_DAY);
        } else if (endDate === today) {
            // Cannot include today in Prior Day reports.
            endDate = moment(endDate).subtract(1, 'days');
            this.setDateRange(moment(startDate), endDate);
        }
    },
    /**
     *
     * @param {moment} [start]
     * @param {moment} [end]
     */
    setDateRange(start, end) {
        // If there are no dates passed, we clear them out.
        const setData = {
            DATE: '',
            START_DATE: '',
            END_DATE: '',
        };

        // Allow for clearing the date range
        if (start !== undefined && end !== undefined) {
            setData.START_DATE = start.format(userInfo.getDateFormat());
            setData.END_DATE = end.format(userInfo.getDateFormat());

            // Always show a range even if the same day.
            setData.DATE = [setData.START_DATE, setData.END_DATE]
                .join(constants.RANGE_SEPARATOR);
        }
        this.model.set(setData);
    },
    /**
     * @param {string} restriction - option restriction based on where the
     * user is trying to export from
     * @return {array}
     */
    getCurrentPriorOptions(restriction) {
        const options = {
            prior: {
                id: constants.PRIOR_DAY,
                text: locale.get('filter.datecode.priorday'),
            },
            current: {
                id: constants.INTRA_DAY,
                text: locale.get('filter.datecode.currentday'),
            },
        };

        return (restriction) ? [options[restriction]]
            : [options.prior, options.current];
    },
    /**
     * @method setBAITypeDropdown
     * Creates and shows a flexdropdown with BAI options.
     * The BAI value passed in will be the defaulted dropdown value
     */
    setupCurrentPriorDropdown() {
        const data = this.getCurrentPriorOptions(this.baiRestriction);
        const [preselected] = data;
        const currentPriorOptions = {
            data,
            initSelection: (element, callback) => {
                callback(preselected);
            },
        };
        this.currentPriorDropdown = this.ui.$currentPriorDropdown.comboBox(currentPriorOptions);
        this.currentPriorDropdown.on('change', (e) => {
            this.onCurrentPriorChange(e.val);
        });
        this.onCurrentPriorChange(preselected.id);
    },
    /**
     * @method onCurrentPriorChange
     * @param {string} type
     * Sets the model with type selected and toggle conditional date field
     * based on selection
     */
    onCurrentPriorChange(type) {
        this.toggleConditionalFields(type);
        this.model.set(constants.TYPE_KEY, type);
    },
    /**
     * @method renderOutputContentDropdown
     * Renders OUTPUTCONTENT dropdown
     * field is specific to CVS exporting
     */
    renderOutputContentDropdown() {
        const options = outputContentOptions();
        let preselected = util.findWhere(options, { id: 'GIRBALANDTRANS' });
        if (!preselected) {
            [preselected] = options;
        }
        const opts = {
            data: options,
            initSelection: (element, callback) => {
                if (preselected) {
                    callback(preselected);
                }
            },
        };
        this.outputContentDropdown = this.ui.$outputContentDropdown.comboBox(opts);
        this.outputContentDropdown.on('change', (e) => {
            this.model.set(OUTPUTCONTENT, e.val);
        });
        // Preset model with preselected value
        this.model.set(
            OUTPUTCONTENT,
            preselected.id,
            {
                silent: true,
            },
        );
    },
    /**
     * @method toggleConditionalFields
     * @param {string} type
     * toggle conditional date field based on BAIType selected
     * and the previous type
     */
    toggleConditionalFields(type) {
        if (this.ui.$conditionalField) {
            this.ui.$conditionalField.toggle(type === constants.PRIOR_DAY);
        }
        const previousType = this.model.get(constants.TYPE_KEY);
        // hide date field if current Day indicator is chosen
        if (type === constants.INTRA_DAY) {
            const today = moment(new Date());
            this.setDateRange(today.startOf('day'), today.endOf('day'));
        } else if (type === constants.PRIOR_DAY && previousType === constants.INTRA_DAY) {
            this.setDateRange();
        }
    },
});

export default ExportDataBase;
