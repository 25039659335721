var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <button type=\"button\" class=\"btn btn-tertiary add-contact\"><span class=\"icon-add-circle\"></span>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"bab.contact.add",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":120},"end":{"line":10,"column":148}}}))
    + "</button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <button class=\"btn btn-secondary\"\n                            data-hook=\"getApproveContacts\"\n                            type=\"button\">"
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"button.approve",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":44,"column":42},"end":{"line":44,"column":78}}}))
    + "\n                        </button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <button class=\"btn btn-secondary\"\n                            data-hook=\"getDeleteContacts\"\n                            type=\"button\">"
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"button.delete",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":50,"column":42},"end":{"line":50,"column":77}}}))
    + "\n                        </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"section-container\">\n    <div class=\"section-body\">\n        <div class=\"widget-controls\">\n\n            <div class=\"page-alerts\"></div>\n\n            <div class=\"btn-wrapper\">\n                <div class=\"btn-group widget-links\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasInsertEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":11,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n                <div class=\"btn-group widget-info-links\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"gridUtilitySection")||(depth0 && lookupProperty(depth0,"gridUtilitySection"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"gridUtilityOptions") : depth0),{"name":"gridUtilitySection","hash":{},"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":14,"column":63}}})) != null ? stack1 : "")
    + "\n                </div>\n            </div>\n\n            <div class=\"col-md-8 hidden\">\n                <div class=\"alphabet-links\">\n                    <a>A</a><a>B</a><a>C</a><a>D</a><a>E</a><a>F</a><a>G</a><a>H</a><a>I</a><a>J</a><a>K</a><a>L</a><a>M</a><a>N</a><a>O</a><a>P</a><a>Q</a><a>R</a><a>S</a><a>T</a><a>U</a><a>V</a><a>W</a><a>X</a><a>Y</a><a>Z</a>\n                </div>\n            </div>\n\n            <div class=\"viewControls col-md-4 pull-right hidden\">\n                <div class=\"btn-group view-switcher\">\n                    <button type=\"button\" class=\"btn btn-tile-view\"><span class=\"icon-grid\"></span></button>\n                    <button type=\"button\" class=\"btn btn-list-view\"><span class=\"icon-list\"></span></button>\n                    <button type=\"button\" class=\"btn active btn-list-view\"><span class=\"icon-rows\"></span></button>\n                </div>\n\n            </div>\n\n        </div>\n\n        <div id=\"container\" class=\"bab-grid-region\" data-region=\"babGrid\">\n            <div>\n                <div class=\"page-content\">\n                    <div id=\"babGrid\"></div>\n                </div>\n                <div id=\"complete\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasApproveEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":20},"end":{"line":46,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasDeleteEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":20},"end":{"line":52,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});