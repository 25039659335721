var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"field-container lookup-container double-combobox-widget-secondary-combobox "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isReadOnly") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":95},"end":{"line":4,"column":147}}})) != null ? stack1 : "")
    + "\" data-hook=\"secondaryComboboxContainer\">\n            <label for=\"SECONDARY_"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"secondaryCombobox") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"secondaryCombobox") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isReadOnly") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":20,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "readonly-secondary-combobox";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p class=\"form-control-static\">\n                    <span>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"secondaryCombobox") : depth0)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "</span>\n                </p>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <select id=\"SECONDARY_"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"secondaryCombobox") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\" name=\"SECONDARY_"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"secondaryCombobox") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\" class=\"ui-widget form-control combobox-widget\" data-hook=\"secondaryCombobox\" data-bind=\"model\" data-ui-widget=\"true\">\n                </select>\n\n                <div class=\"mdf-input-icon-container\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"lockIcon"),depth0,{"name":"lockIcon","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"SECONDARY_"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"secondaryCombobox") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\"></span>\n                <div class=\"lookup-helper-text\" data-hook=\"secondaryComboboxAdditionalInfo\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-hook=\"doubleComboboxWidgetContainer\">\n    <div class=\"primary-combobox-widget-container\" data-region=\"primaryComboboxWidgetContainer\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showSecondaryCombobox") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":22,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true});