import React, {
  useCallback, useContext, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import { CaretUpNextIcon } from '@glu/icons-react';
import { withStyles } from '@glu/theming';

import { MobileGridContext } from '../MobileGridWrapper/MobileGridWrapper';
import styles from './ScrollToTop.styles';

const ScrollToTop = ({
  acceleration, classes, defaultContainer, linesPerScrollBy, maxLinesPerScrollBy
}) => {
  const { topScrollPoint, containerEl } = useContext(MobileGridContext);
  const [hasScrolled, setHasScrolled] = useState(false);

  const lastKnownY = useRef(topScrollPoint);
  const linesToScroll = useRef(linesPerScrollBy);
  const topPoint = useRef(topScrollPoint);
  const containerRef = useRef(null);

  useEffect(() => {
    topPoint.current = topScrollPoint;
  }, [topScrollPoint]);

  const updateScroll = useCallback(() => {
    const currentY = lastKnownY.current || 0;
    const distanceToTop = currentY - topPoint.current || 0;
    if (distanceToTop > 0 && distanceToTop < linesToScroll.current
      && (currentY !== topPoint.current)) {
      containerRef.current.scrollBy(0, -distanceToTop);
    } else if (distanceToTop < 0 || currentY === 0 || currentY <= topPoint.current) {
      // reset linesToScroll to default so we can accelerate the same each time
      linesToScroll.current = 1;
      setHasScrolled(false);
      return;
    } else {
      containerRef.current.scrollBy(0, -linesToScroll.current);
      const linesToLimitDiff = maxLinesPerScrollBy - linesToScroll.current;
      const acceleratedLinesPerScrollBy = linesToScroll.current * acceleration;
      if (linesToLimitDiff - linesToScroll.current > acceleratedLinesPerScrollBy) {
        linesToScroll.current *= acceleration;
      } else {
        linesToScroll.current = maxLinesPerScrollBy;
      }
    }
    requestAnimationFrame(updateScroll);
  }, [acceleration, maxLinesPerScrollBy]);

  const requestAnimationUpdate = useCallback(() => {
    requestAnimationFrame(updateScroll);
  }, [updateScroll]);

  const watchForScroll = useCallback(function scrollWatch() {
    const closestContainer = defaultContainer && containerEl?.closest(defaultContainer);
    if (closestContainer) {
      containerRef.current = closestContainer;
      lastKnownY.current = containerRef.current.scrollTop;
    } else if (this === window || !containerEl) {
      containerRef.current = window;
      lastKnownY.current = containerRef.current.scrollY;
    } else {
      containerRef.current = containerEl;
      lastKnownY.current = containerRef.current.scrollTop;
    }
    if (lastKnownY.current != null && topScrollPoint != null) {
      setHasScrolled(lastKnownY.current > topScrollPoint);
    }
  }, [containerEl, topScrollPoint, defaultContainer]);

  useEffect(() => {
    if (containerEl) {
      containerEl.addEventListener('scroll', watchForScroll, true);
    }
    window.addEventListener('scroll', watchForScroll, true);
    return () => {
      if (containerEl) {
        containerEl.removeEventListener('scroll', watchForScroll, true);
      }
      window.removeEventListener('scroll', watchForScroll, true);
    };
  }, [containerEl, watchForScroll]);

  return hasScrolled ? (
    <div className={classes.root}>
      <CaretUpNextIcon
        actionable
        className={classes.button}
        onClick={requestAnimationUpdate}
        title={locale.get('scrollToTop')}
      />
    </div>
  ) : null;
};

ScrollToTop.propTypes = {
  /** Amount by which to accelerate animation */
  acceleration: PropTypes.number,
  /** JSS classes meant for styling */
  classes: PropTypes.objectOf(PropTypes.string),
  /** Number of lines to use per scrollBy call */
  linesPerScrollBy: PropTypes.number,
  /** Maxiumum number of lines to use in a scrollBy call */
  maxLinesPerScrollBy: PropTypes.number,
  /** CSS Selector for scroll container */
  defaultContainer: PropTypes.string
};

ScrollToTop.defaultProps = {
  acceleration: 1.1,
  classes: {},
  linesPerScrollBy: 1,
  maxLinesPerScrollBy: 100,
  defaultContainer: undefined
};

export const BareScrollToTop = ScrollToTop;
export default withStyles(styles)(BareScrollToTop);
