import { postData } from 'common/util/services';
import services from 'services';
import {
    MODE,
    ALERTS_CONTEXT,
    FOOTERS_CONTEXT,
    CONTENT_TYPE_ID,
} from 'components/AlertContentManagement/constants';
import transform from 'common/util/transform';
import { normalizeParametersResponse } from './util';

const hasAlertAccess = (type = 'alerts') => {
    const usedContext = type === 'alerts' ? ALERTS_CONTEXT : FOOTERS_CONTEXT;
    return postData(
        services.generateUrl('accessService/hasEntitlements'),
        {
            actions: [
                {
                    ...usedContext,
                    actionMode: MODE.INSERT,
                },
                {
                    ...usedContext,
                    actionMode: MODE.MODIFY,
                },
                {
                    ...usedContext,
                    actionMode: MODE.APPROVE,
                },
                {
                    ...usedContext,
                    actionMode: MODE.DELETE,
                },
                {
                    ...usedContext,
                    actionMode: MODE.SELECT,
                },
            ],
        },
    );
};

const getQueryResults = (fieldName, customFilters = [], action) => {
    const data = {
        queryCriteria: {
            action: action || {
                actionMode: 'INSERT',
                functionCode: 'ALERT',
                productCode: '*',
                typeCode: '*',
            },
            allowDuplicates: false,
            entryClass: '',
            fieldName,
            subTypeCode: '*',
            customFilters,
        },
    };
    return postData(
        services.generateUrl('tableMaintenance/getQueryResults'),
        data,
    ).then((response) => {
        const rows = response.queryResponse?.QueryData?.queryRows || [];
        return rows.map(row => ({
            id: row.name,
            name: row.label,
        }));
    });
};

const getInquiryQueryResults = url => (inquiryId, customFilters = [], options = {}) => {
    const data = {
        IncludeMapData: options.IncludeMapData || 0,
        queryCriteria: {
            inquiryId,
            customFilters,
        },
    };

    if (options.allowDuplicates) {
        data.queryCriteria = {
            ...data.queryCriteria,
            allowDuplicates: true,
        };
    }
    return postData(
        services.generateUrl(url),
        data,
    );
};
const getAlertInquiryQuery = getInquiryQueryResults('alertContentManagement/alerts/getQueryResults');
const getFooterInquiryQuery = getInquiryQueryResults('alertContentManagement/footers/getQueryResults');

const queryRowsToDropdownFormat = (response) => {
    const rows = response.queryResponse?.QueryData?.queryRows || [];
    return rows.map(row => ({
        id: row.name,
        name: row.label,
    }));
};

const getAlertGroups = () => getQueryResults('ALERTGROUP')
    .catch(() => []);

const getAlertTypes = alertGroup => getQueryResults(
    'ALERTTYPE',
    [
        { filterName: 'Depends', filterParam: ['ALERTGROUP', alertGroup] },
    ],
).catch(() => []);
const getLocales = () => getQueryResults(
    'LOCALE',
    [],
    {
        actionMode: '*',
        functionCode: 'MAINT',
        productCode: '*',
        typeCode: '*',
    },
).catch(() => []);

/**
 * Extract marketsegments from a list of grids
 * @param {array} grids
 * @returns {array}
 */
const extractMarketSegements = (grids = []) => {
    const marketSegments = grids[0]?.items || [];
    return marketSegments.map((segment) => {
        const attrs = transform.pairsToHash(
            segment.item,
            'name',
            'value',
        );
        return attrs.MARKETSEGMENT;
    });
};

const getBankDefinedContent = (url, item) => postData(
    services.generateUrl(url),
    {
        item: {
            item,
        },
    },
).then((response) => {
    const data = transform.pairsToHash(
        response.item?.item || [],
        'name',
        'value',
    );
    const marketSegements = extractMarketSegements(response.grids);
    return {
        ...data,
        MARKETSEGMENTS: marketSegements,
    };
});

const getBankDefinedAlert = data => getBankDefinedContent(
    'alertContentManagement/alerts/READCHILDREN',
    [
        {
            name: 'ALERTCONTENTID',
            value: data.ALERTCONTENTID,
        },
    ],
);
const getBankDefinedFooter = data => getBankDefinedContent(
    'alertContentManagement/footers/READCHILDREN',
    [
        {
            name: 'ALERTFOOTERID',
            value: data.ALERTFOOTERID,
        },
    ],
);

const getSystemDefinedAlert = options => getAlertInquiryQuery(
    24457,
    [
        {
            filterName: 'Depends',
            filterParam: ['ALERTTYPE', options.ALERTTYPEID],
        },
        {
            filterName: 'Depends',
            filterParam: ['LOCALE', options.LOCALEID],
        },
    ],
    {
        IncludeMapData: 1,
    },
).then((response) => {
    const alertData = response?.queryResponse?.QueryData?.queryRows?.[0] || {};
    return transform.pairsToHash(
        alertData.mapDataList,
        'toField',
        'value',
    );
});

/**
 * Fetch a system defined footer by LOCALEID
 * @param {Object} options
 * @param {string} options.LOCALEID
 * @returns {Promise}
 */
const getSystemDefinedFooter = options => getFooterInquiryQuery(
    24459,
    [
        {
            filterName: 'Depends',
            filterParam: ['LOCALE', options.LOCALEID],
        },
    ],
    {
        IncludeMapData: 1,
    },
).then((response) => {
    const footerData = response?.queryResponse?.QueryData?.queryRows?.[0] || {};
    return transform.pairsToHash(
        footerData.mapDataList,
        'toField',
        'value',
    );
});

/**
 * When contentTypeId is Bank defined, then fetch the alert with the getBankDefinedAlert function.
 * Otherwise, use getSystemDefinedAlert.
 * @param {Object} modelData
 */
const getAlert = (options = {}) => {
    const getAlertData = options.CONTENTTYPEID === CONTENT_TYPE_ID.BANK_DEFINED
        ? getBankDefinedAlert : getSystemDefinedAlert;

    return getAlertData(options);
};

const getFooter = (options = {}) => {
    if (options.CONTENTTYPEID === CONTENT_TYPE_ID.BANK_DEFINED) {
        return getBankDefinedFooter(options);
    }
    return getSystemDefinedFooter(options);
};

const getEntitlements = context => () => postData(services.generateUrl(`alertContentManagement/${context}/getEntitledActions`))
    .then(response => response.actionModes);

const getAlertEntitlements = getEntitlements('alerts');
const getFooterEntitlements = getEntitlements('footers');

const getMarketSegments = ({
    ALERTTYPEID,
    ALERTCONTENTID,
    LOCALEID,
    mode = '*',
}) => {
    let inquiryId = 24456;
    let filters = [
        {
            filterName: 'Depends',
            filterParam: ['ALERTTYPE', ALERTTYPEID],
        },
        {
            filterName: 'Depends',
            filterParam: ['LOCALE', LOCALEID],
        },
        {
            filterName: 'Depends',
            filterParam: ['ACTIONMODE', MODE.INSERT],
        },
    ];
    if (mode === MODE.MODIFY) {
        inquiryId = 24460;
        filters = [
            {
                filterName: 'Depends',
                filterParam: ['ALERTCONTENTID', ALERTCONTENTID],
            },
            {
                filterName: 'Depends',
                filterParam: ['ALERTTYPE', ALERTTYPEID],
            },
            {
                filterName: 'Depends',
                filterParam: ['LOCALE', LOCALEID],
            },
        ];
    }
    return getAlertInquiryQuery(inquiryId, filters).then(queryRowsToDropdownFormat);
};

const getFooterMarketSegments = ({
    LOCALEID,
    ALERTFOOTERID,
    mode = '*',
}) => {
    let inquiryId = 24461;
    let filters = [
        {
            filterName: 'Depends',
            filterParam: ['LOCALE', LOCALEID],
        },
        {
            filterName: 'Depends',
            filterParam: ['ACTIONMODE', MODE.INSERT],
        },
    ];
    if (mode === MODE.MODIFY) {
        inquiryId = 24462;
        filters = [
            {
                filterName: 'Depends',
                filterParam: ['ALERTFOOTERID', ALERTFOOTERID],
            },
            {
                filterName: 'Depends',
                filterParam: ['LOCALE', LOCALEID],
            },
        ];
    }
    return getFooterInquiryQuery(inquiryId, filters).then(queryRowsToDropdownFormat);
};

const getParameters = options => getAlertInquiryQuery(
    24458,
    [
        {
            filterName: 'Depends',
            filterParam: ['ALERTTYPE', options.ALERTTYPEID],
        },
        {
            filterName: 'Depends',
            filterParam: ['LOCALE', options.LOCALEID],
        },
    ],
    {
        IncludeMapData: 1,
        allowDuplicates: 'true',
    },
).then(normalizeParametersResponse);

const postAlert = action => data => postData(
    services.generateUrl(`alertContentManagement/alerts/${action}`),
    {
        item: {
            item: [
                {
                    ...(action === 'UPDATE' ? {
                        name: 'readChildren',
                        value: 'false',
                    } : { name: '', value: '' }),
                },
                {
                    name: 'ALERTCONTENTID',
                    value: data.ALERTCONTENTID,
                },
                {
                    name: 'ALERTTYPE',
                    value: data.ALERTTYPE?.[0]?.id || data.ALERTTYPE,
                },
                {
                    name: 'ALERTGROUP',
                    value: data.ALERTGROUP?.[0]?.id || data.ALERTGROUP,
                },
                {
                    name: 'LOCALE',
                    value: data.LOCALE?.[0]?.id || data.LOCALE,
                },
                {
                    name: 'ALERTSUBJECT',
                    value: data.ALERTSUBJECT,
                },
                {
                    name: 'TEXTCONTENT',
                    value: data.TEXTCONTENT,
                },
                {
                    name: 'EMAILCONTENT',
                    value: data.EMAILCONTENT,
                },
                {
                    name: 'UPDATECOUNT__',
                    value: data['UPDATECOUNT__'], // eslint-disable-line
                },
            ],
        },
        grids: [
            {
                name: 'MARKETSEGMENTS',
                items: data.MARKETSEGMENTS
                    .map(segment => ({
                        item: [
                            {
                                name: 'MARKETSEGMENT',
                                value: segment,
                            },
                        ],
                    })),
            },
        ],
    },
);
const insertAlert = postAlert('ADD');
const updateAlert = postAlert('UPDATE');

/**
 * Submit the alert data to the proper endpoint
 * @param {string} action
 * @param {object} data
 * @returns {Promise}
 */
const alertAction = (action, data) => postData(
    services.generateUrl(`alertContentManagement/alerts/${action}`),
    {
        item: {
            item: [
                {
                    name: 'ALERTCONTENTID',
                    value: data.ALERTCONTENTID,
                },
                {
                    name: 'UPDATECOUNT__',
                    value: data['UPDATECOUNT__'], // eslint-disable-line
                },
            ],
        },
    },
);

const postFooter = action => data => postData(
    services.generateUrl(`alertContentManagement/footers/${action}`),
    {
        item: {
            item: [
                {
                    ...(action === 'UPDATE' ? {
                        name: 'readChildren',
                        value: 'false',
                    } : { name: '', value: '' }),
                },
                {
                    name: 'ALERTFOOTERID',
                    value: data.ALERTFOOTERID,
                },
                {
                    name: 'LOCALE',
                    value: data.LOCALE?.[0]?.id || data.LOCALE,
                },
                {
                    name: 'FOOTERCONTENT',
                    value: data.FOOTERCONTENT,
                },
                {
                    name: 'UPDATECOUNT__',
                    value: data['UPDATECOUNT__'], // eslint-disable-line
                },
            ],
        },
        grids: [
            {
                name: 'MARKETSEGMENTS',
                items: data.MARKETSEGMENTS
                    .map(segment => ({
                        item: [
                            {
                                name: 'MARKETSEGMENT',
                                value: segment,
                            },
                        ],
                    })),
            },
        ],
    },
);
const insertFooter = postFooter('ADD');
const updateFooter = postFooter('UPDATE');

/**
 * Submit the footer data to the proper endpoint
 * @param {string} action
 * @param {object} data
 * @returns {Promise}
 */
const footerAction = (action, data) => postData(
    services.generateUrl(`alertContentManagement/footers/${action}`),
    {
        item: {
            item: [
                {
                    name: 'ALERTFOOTERID',
                    value: data.ALERTFOOTERID,
                },
                {
                    name: 'UPDATECOUNT__',
                    value: data['UPDATECOUNT__'], // eslint-disable-line
                },
            ],
        },
    },
);

export {
    hasAlertAccess,
    getAlertGroups,
    getAlertTypes,
    getLocales,
    getAlert,
    getFooter,
    getMarketSegments,
    getFooterMarketSegments,
    getParameters,
    insertAlert,
    insertFooter,
    updateAlert,
    updateFooter,
    alertAction,
    footerAction,
    getAlertEntitlements,
    getFooterEntitlements,
};
