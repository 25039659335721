export default function (form) {
    /*
     * We only want to enter this block if we are creating a payment from
     * a template.
     */
    if (form.formView.context && form.formView.context.actionContext) {
        if (form.formView.context.actionContext.productCode === 'PAY'
                && form.formView.context.actionContext.functionCode === 'INST'
                && form.formView.context.functionCode === 'TMPL') {
            const previous = form.formView.model.previousAttributes();

            /*
             * At this point the dataMapList has already been applied and any originator
             * information contained in the
             * template will now be in _previousAttributes, the current values being
             * those of the debit account.
             * If the template contains and originator name we will use whatever is contained in
             * the template.
             */
            if (previous.ORIGINATOR_NAME !== '' && previous.ORIGINATOR_NAME !== form.formView.model.get('ORIGINATOR_NAME')) {
                form.formView.model.set('ORIGINATOR_NAME', previous.ORIGINATOR_NAME);
                form.formView.model.set('ORIGINATOR_ADDRESS_1', previous.ORIGINATOR_ADDRESS_1);
                form.formView.model.set('ORIGINATOR_ADDRESS_2', previous.ORIGINATOR_ADDRESS_2);
                form.formView.model.set('ORIGINATOR_CITY', previous.ORIGINATOR_CITY);
                form.formView.model.set('ORIGINATOR_COUNTRY', previous.ORIGINATOR_COUNTRY);
            }
        }
    }
}
