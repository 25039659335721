import Controller from '@glu/core/src/controller';
import services from 'services';
import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';

const Scenario = new ((Controller.extend({
    initialize() {},

    getModelJSON(model) {
        // if we need to do any mapping, do it here
        return model.toJSON();
    },

    delete(model) {
        this.sendRequest('/cashflowScenario/delete', model);
    },

    create(model) {
        this.sendRequest('/cashflowScenario/save', model);
    },

    update(model) {
        this.sendRequest('/cashflowScenario/update', model);
    },

    sendRequest(endPoint, model) {
        const self = this;
        http.post(
            services.generateUrl(endPoint),
            this.getModelJSON(model),
            (response) => {
                model.trigger(
                    'sync',
                    model,
                    response,
                    {
                        source: 'cashFlowScenarioService',
                    },
                );
                self.trigger('sync', new Model(response));
            },
            () => {
                model.trigger('error', model);
            },
        );
    },
})))();

export default Scenario;
