var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div>\n        <form>\n            <div>\n                <div class=\"validation-group hide\" role=\"alert\">\n                    <p class=\"help-block center\" data-validation=\"generic\"></p>\n                </div>\n                <div class=\"row center\">\n                    <p class=\"col-12\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.secretQuestions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":38},"end":{"line":9,"column":73}}}))
    + "</p>\n                </div>\n                <div class=\"form-group\">\n                    <label for=\"question0\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.secretQuestion1",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":43},"end":{"line":12,"column":78}}}))
    + "</label>\n                    <strong>"
    + ((stack1 = (lookupProperty(helpers,"getQuestion")||(depth0 && lookupProperty(depth0,"getQuestion"))||alias3).call(alias1,0,{"name":"getQuestion","hash":{},"data":data,"loc":{"start":{"line":13,"column":28},"end":{"line":13,"column":47}}})) != null ? stack1 : "")
    + "</strong>\n                    <input name=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,0,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":14,"column":33},"end":{"line":14,"column":51}}})) != null ? stack1 : "")
    + "\" id=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,0,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":14,"column":57},"end":{"line":14,"column":75}}})) != null ? stack1 : "")
    + "\" maxlength=\"35\" data-bind=\"model\" type=\"text\" class=\"form-control securityAnswer\" aria-required=\"true\"/>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,0,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":15,"column":78},"end":{"line":15,"column":96}}})) != null ? stack1 : "")
    + "\"></span>\n                </div>\n\n                <div class=\"form-group\">\n                    <label for=\"question1\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.secretQuestion2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":43},"end":{"line":19,"column":78}}}))
    + "</label><br/>\n                    <strong>"
    + ((stack1 = (lookupProperty(helpers,"getQuestion")||(depth0 && lookupProperty(depth0,"getQuestion"))||alias3).call(alias1,1,{"name":"getQuestion","hash":{},"data":data,"loc":{"start":{"line":20,"column":28},"end":{"line":20,"column":47}}})) != null ? stack1 : "")
    + "</strong>\n                    <input name=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,1,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":21,"column":33},"end":{"line":21,"column":51}}})) != null ? stack1 : "")
    + "\" id=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,1,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":21,"column":57},"end":{"line":21,"column":75}}})) != null ? stack1 : "")
    + "\" maxlength=\"35\" data-bind=\"model\" type=\"text\" class=\"form-control securityAnswer\" aria-required=\"true\"/>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,1,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":22,"column":78},"end":{"line":22,"column":96}}})) != null ? stack1 : "")
    + "\"></span>\n                </div>\n\n                <div class=\"form-group\">\n                    <label for=\"question2\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.secretQuestion3",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":43},"end":{"line":26,"column":78}}}))
    + "</label><br/>\n                    <strong>"
    + ((stack1 = (lookupProperty(helpers,"getQuestion")||(depth0 && lookupProperty(depth0,"getQuestion"))||alias3).call(alias1,2,{"name":"getQuestion","hash":{},"data":data,"loc":{"start":{"line":27,"column":28},"end":{"line":27,"column":47}}})) != null ? stack1 : "")
    + "</strong>\n                    <input name=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,2,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":28,"column":33},"end":{"line":28,"column":51}}})) != null ? stack1 : "")
    + "\" id=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,2,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":28,"column":57},"end":{"line":28,"column":75}}})) != null ? stack1 : "")
    + "\" maxlength=\"35\" data-bind=\"model\" type=\"text\" class=\"form-control securityAnswer\" aria-required=\"true\"/>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,2,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":29,"column":78},"end":{"line":29,"column":96}}})) != null ? stack1 : "")
    + "\"></span>\n                </div>\n            </div>\n            <div>\n                <div class=\"form-group center\">\n                    <button type=\"submit\" id=\"security-challenge-action-submit\" class=\"btn btn-primary\" data-action=\"save\" data-disable-on-submit>\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.submit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":35,"column":24},"end":{"line":35,"column":50}}}))
    + "\n                    </button>\n                    <button type=\"button\" id=\"security-challenge-action-cancel\" class=\"btn btn-link\" data-action=\"cancel\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":37,"column":122},"end":{"line":37,"column":148}}}))
    + "</button>\n                </div>\n            </div>\n        </form>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"notModal") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":16},"end":{"line":43,"column":70}}})) != null ? stack1 : "")
    + "\">\n        <form class=\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"notModal") : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":21},"end":{"line":44,"column":65}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"notModal") : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":12},"end":{"line":50,"column":23}}})) != null ? stack1 : "")
    + "            <div class=\"modal-body\">\n                <div class=\"validation-group hide\" role=\"alert\">\n                    <p class=\"help-block center\" data-validation=\"generic\"></p>\n                </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notModal") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":16},"end":{"line":59,"column":23}}})) != null ? stack1 : "")
    + "                <div class=\"form-group\">\n                    <label for=\"question0\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.secretQuestion1",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":61,"column":43},"end":{"line":61,"column":78}}}))
    + "</label><br/>\n                    <strong>"
    + ((stack1 = (lookupProperty(helpers,"getQuestion")||(depth0 && lookupProperty(depth0,"getQuestion"))||alias3).call(alias1,0,{"name":"getQuestion","hash":{},"data":data,"loc":{"start":{"line":62,"column":28},"end":{"line":62,"column":47}}})) != null ? stack1 : "")
    + "</strong>\n                </div>\n                <div class=\"form-group\">\n                    <label for=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,0,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":65,"column":32},"end":{"line":65,"column":50}}})) != null ? stack1 : "")
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.answer",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":65,"column":52},"end":{"line":65,"column":78}}}))
    + "</label>\n                    <input name=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,0,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":66,"column":33},"end":{"line":66,"column":51}}})) != null ? stack1 : "")
    + "\" id=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,0,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":66,"column":57},"end":{"line":66,"column":75}}})) != null ? stack1 : "")
    + "\" maxlength=\"35\" data-bind=\"model\" type=\"text\" class=\"form-control securityAnswer\" aria-required=\"true\"/>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,0,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":67,"column":78},"end":{"line":67,"column":96}}})) != null ? stack1 : "")
    + "\"></span>\n                </div>\n\n                <div class=\"form-group\">\n                    <label for=\"question1\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.secretQuestion2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":71,"column":43},"end":{"line":71,"column":78}}}))
    + "</label><br/>\n                    <strong>"
    + ((stack1 = (lookupProperty(helpers,"getQuestion")||(depth0 && lookupProperty(depth0,"getQuestion"))||alias3).call(alias1,1,{"name":"getQuestion","hash":{},"data":data,"loc":{"start":{"line":72,"column":28},"end":{"line":72,"column":47}}})) != null ? stack1 : "")
    + "</strong>\n                </div>\n                <div class=\"form-group\">\n                    <label for=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,1,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":75,"column":32},"end":{"line":75,"column":50}}})) != null ? stack1 : "")
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.answer",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":75,"column":52},"end":{"line":75,"column":78}}}))
    + "</label>\n                    <input name=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,1,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":76,"column":33},"end":{"line":76,"column":51}}})) != null ? stack1 : "")
    + "\" id=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,1,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":76,"column":57},"end":{"line":76,"column":75}}})) != null ? stack1 : "")
    + "\" maxlength=\"35\" data-bind=\"model\" type=\"text\" class=\"form-control securityAnswer\" aria-required=\"true\"/>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,1,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":77,"column":78},"end":{"line":77,"column":96}}})) != null ? stack1 : "")
    + "\"></span>\n                </div>\n\n                <div class=\"form-group\">\n                    <label for=\"question2\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.secretQuestion3",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":81,"column":43},"end":{"line":81,"column":78}}}))
    + "</label><br/>\n                    <strong>"
    + ((stack1 = (lookupProperty(helpers,"getQuestion")||(depth0 && lookupProperty(depth0,"getQuestion"))||alias3).call(alias1,2,{"name":"getQuestion","hash":{},"data":data,"loc":{"start":{"line":82,"column":28},"end":{"line":82,"column":47}}})) != null ? stack1 : "")
    + "</strong>\n                </div>\n                <div class=\"form-group\">\n                    <label for=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,2,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":85,"column":32},"end":{"line":85,"column":50}}})) != null ? stack1 : "")
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.answer",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":85,"column":52},"end":{"line":85,"column":78}}}))
    + "</label>\n                    <input name=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,2,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":86,"column":33},"end":{"line":86,"column":51}}})) != null ? stack1 : "")
    + "\" id=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,2,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":86,"column":57},"end":{"line":86,"column":75}}})) != null ? stack1 : "")
    + "\" maxlength=\"35\" data-bind=\"model\" type=\"text\" class=\"form-control securityAnswer\" aria-required=\"true\"/>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,2,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":87,"column":78},"end":{"line":87,"column":96}}})) != null ? stack1 : "")
    + "\"></span>\n                </div>\n            </div>\n            <div class=\"modal-footer\">\n                <div class=\"form-group\">\n                    <button type=\"submit\" id=\"security-challenge-action-submit\" class=\"btn btn-primary\" data-action=\"save\" data-disable-on-submit>\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.submit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":93,"column":24},"end":{"line":93,"column":50}}}))
    + "\n                    </button>\n                    <button type=\"button\" id=\"security-challenge-action-cancel\" class=\"btn btn-link\" data-action=\"cancel\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":95,"column":122},"end":{"line":95,"column":148}}}))
    + "</button>\n                </div>\n            </div>\n        </form>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "modal-dialog glu-dialog";
},"6":function(container,depth0,helpers,partials,data) {
    return "modal-content";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"modal-header\">\n                    <button type=\"button\" class=\"close\" data-action=\"cancel\" aria-hidden=\"true\">×</button>\n                    <h2 class=\"modal-title\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"common.securityQuestionChallenge",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":48,"column":44},"end":{"line":48,"column":89}}}))
    + "</h2>\n                </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"row center\">\n                        <p class=\"col-12\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"common.secretQuestions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":57,"column":42},"end":{"line":57,"column":77}}}))
    + "</p>\n                    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"notModal") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":100,"column":7}}})) != null ? stack1 : "");
},"useData":true});