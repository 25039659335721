var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"view-model\">\n        <div class=\"row\">\n            <div class=\"form-column col-md-6\">\n                <div class=\"field-container-md field-container\">\n                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"risk.pigid.RefernceNumber",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":27},"end":{"line":8,"column":65}}}))
    + "</label>\n                    <div>"
    + alias2(((helper = (helper = lookupProperty(helpers,"REFERNCE_NUMBER") || (depth0 != null ? lookupProperty(depth0,"REFERNCE_NUMBER") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"REFERNCE_NUMBER","hash":{},"data":data,"loc":{"start":{"line":9,"column":25},"end":{"line":9,"column":44}}}) : helper)))
    + "</div>\n                </div>\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"form-column col-md-6\">\n                <div class=\"field-container-md field-container\">\n                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RISK.Status",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":27},"end":{"line":16,"column":51}}}))
    + "</label>\n                    <div>"
    + alias2(((helper = (helper = lookupProperty(helpers,"STATUS") || (depth0 != null ? lookupProperty(depth0,"STATUS") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"STATUS","hash":{},"data":data,"loc":{"start":{"line":17,"column":25},"end":{"line":17,"column":35}}}) : helper)))
    + "</div>\n                </div>\n            </div>\n            <div class=\"form-column col-md-6\">\n                <div class=\" field-container-md field-container\">\n                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"risk.pigid.confirmationNumber",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":27},"end":{"line":22,"column":69}}}))
    + "</label>\n                    <div>"
    + alias2(((helper = (helper = lookupProperty(helpers,"CONFIRMATION_NUMBER") || (depth0 != null ? lookupProperty(depth0,"CONFIRMATION_NUMBER") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"CONFIRMATION_NUMBER","hash":{},"data":data,"loc":{"start":{"line":23,"column":25},"end":{"line":23,"column":48}}}) : helper)))
    + "</div>\n                </div>\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"form-column col-md-6\">\n                <div class=\"field-container-md field-container\">\n                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"risk.pigid.id",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":30,"column":27},"end":{"line":30,"column":53}}}))
    + "</label>\n                    <div>"
    + alias2(((helper = (helper = lookupProperty(helpers,"PIGID") || (depth0 != null ? lookupProperty(depth0,"PIGID") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"PIGID","hash":{},"data":data,"loc":{"start":{"line":31,"column":25},"end":{"line":31,"column":34}}}) : helper)))
    + "</div>\n                </div>\n            </div>\n            <div class=\"form-column col-md-6\">\n                <div class=\" field-container-md field-container\">\n                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.UserGroup",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":27},"end":{"line":36,"column":53}}}))
    + "</label>\n                    <div>"
    + alias2(((helper = (helper = lookupProperty(helpers,"USERGROUP") || (depth0 != null ? lookupProperty(depth0,"USERGROUP") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"USERGROUP","hash":{},"data":data,"loc":{"start":{"line":37,"column":25},"end":{"line":37,"column":38}}}) : helper)))
    + "</div>\n                </div>\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"form-column col-md-6\">\n                <div class=\"field-container-md field-container\">\n                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.CreditAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":44,"column":27},"end":{"line":44,"column":56}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isView") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":45,"column":20},"end":{"line":56,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n            <div class=\"form-column col-md-6\">\n                <div class=\" field-container-md field-container\">\n                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.DebitAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":61,"column":27},"end":{"line":61,"column":55}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isView") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":62,"column":20},"end":{"line":73,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div>"
    + alias4(((helper = (helper = lookupProperty(helpers,"CREDIT_AMOUNT") || (depth0 != null ? lookupProperty(depth0,"CREDIT_AMOUNT") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CREDIT_AMOUNT","hash":{},"data":data,"loc":{"start":{"line":46,"column":25},"end":{"line":46,"column":42}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"CURRENCY") || (depth0 != null ? lookupProperty(depth0,"CURRENCY") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CURRENCY","hash":{},"data":data,"loc":{"start":{"line":46,"column":43},"end":{"line":46,"column":55}}}) : helper)))
    + "</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"input-group input-group-combobox\">\n                        <input class=\"form-control\" data-amount-type=\"CREDIT\" data-bind=\"model\" data-maxlength=\"20\"\n                            data-type=\"amount\" maxlength=\"20\" name=\"CREDIT_AMOUNT\" id=\"CREDIT_AMOUNT\" type=\"text\">\n                        <div class=\"input-group-addon\">\n                            <span>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"PAY.USD",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":52,"column":34},"end":{"line":52,"column":54}}}))
    + "</span>\n                        </div>\n                    </div>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"CREDIT_AMOUNT\"></span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div>"
    + alias4(((helper = (helper = lookupProperty(helpers,"DEBIT_AMOUNT") || (depth0 != null ? lookupProperty(depth0,"DEBIT_AMOUNT") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DEBIT_AMOUNT","hash":{},"data":data,"loc":{"start":{"line":63,"column":25},"end":{"line":63,"column":41}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"CURRENCY") || (depth0 != null ? lookupProperty(depth0,"CURRENCY") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CURRENCY","hash":{},"data":data,"loc":{"start":{"line":63,"column":42},"end":{"line":63,"column":54}}}) : helper)))
    + "</div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"input-group input-group-combobox\">\n                        <input class=\"form-control\" data-amount-type=\"CREDIT\" data-bind=\"model\" data-maxlength=\"20\"\n                            data-type=\"amount\" maxlength=\"20\" name=\"DEBIT_AMOUNT\" id=\"DEBIT_AMOUNT\" type=\"text\">\n                        <div class=\"input-group-addon\">\n                            <span>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"PAY.USD",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":69,"column":34},"end":{"line":69,"column":54}}}))
    + "</span>\n                        </div>\n                    </div>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"DEBIT_AMOUNT\"></span>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <form autocomplete=\"off\">\n        <div class=\"create-id\">\n            <div class=\"row\">\n                <div class=\"form-column col-md-6\">\n                    <div class=\"required field-container-lg field-container\">\n                        <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"risk.pigid.id",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":84,"column":31},"end":{"line":84,"column":57}}}))
    + "</label>\n                        <input type=\"hidden\" id=\"payment_group_id\" class=\"form-control payment-group-id-field\"\n                            data-placeholder=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.select",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":86,"column":46},"end":{"line":86,"column":72}}}))
    + "\" data-bind=\"model\" data-hook=\"payment_group_id\"\n                            name=\"payment_group_id\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":87,"column":59},"end":{"line":87,"column":65}}}) : helper)))
    + "\">\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"payment_group_id\"></span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row\">\n                <div class=\"form-column col-md-6\">\n                    <div class=\"field-container-lg field-container\">\n                        <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"risk.pigid.nickname",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":95,"column":31},"end":{"line":95,"column":63}}}))
    + "</label>\n                        <input type=\"text\" data-bind=\"model\" name=\"nickname\" id=\"nickname\" maxlength=\"50\" class=\"form-control\" disabled />\n                    </div>\n                </div>\n            </div>\n            <div class=\"row\">\n                <div class=\"form-column col-md-3\">\n                    <div class=\"required field-container-lg field-container\">\n                        <label for=\"CREDIT_AMOUNT\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.CreditAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":103,"column":51},"end":{"line":103,"column":80}}}))
    + "</label>\n                        <div class=\"input-group input-group-combobox\">\n                            <input class=\"form-control\" data-amount-type=\"CREDIT\" data-bind=\"model\" data-maxlength=\"20\"\n                                data-type=\"amount\" maxlength=\"20\" name=\"CREDIT_AMOUNT\" id=\"CREDIT_AMOUNT\" type=\"text\">\n                            <div class=\"input-group-addon\">\n                                <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.USD",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":108,"column":38},"end":{"line":108,"column":58}}}))
    + "</span>\n                            </div>\n                        </div>\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"CREDIT_AMOUNT\"></span>\n                    </div>\n                </div>\n                <div class=\"form-column col-md-3\">\n                    <div class=\"required field-container-lg field-container\">\n                        <label for=\"DEBIT_AMOUNT\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.DebitAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":116,"column":50},"end":{"line":116,"column":78}}}))
    + "</label>\n                        <div class=\"input-group input-group-combobox\">\n                            <input class=\"form-control\" data-amount-type=\"CREDIT\" data-bind=\"model\" data-maxlength=\"20\"\n                                data-type=\"amount\" maxlength=\"20\" name=\"DEBIT_AMOUNT\" id=\"DEBIT_AMOUNT\" type=\"text\">\n                            <div class=\"input-group-addon\">\n                                <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.USD",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":121,"column":38},"end":{"line":121,"column":58}}}))
    + "</span>\n                            </div>\n                        </div>\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"DEBIT_AMOUNT\"></span>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </form>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "        <div data-region=\"getMobileFooter\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ach-client-model\">\n    <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"modifyView") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":130,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isMobile") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":131,"column":4},"end":{"line":133,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});