import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import Formatter from 'system/utilities/format';
import store from 'system/utilities/cache';
import GridApi from 'common/dynamicPages/api/grid';
import template from './envelopeListView.hbs';

export default Layout.extend({
    template,
    modalClass: 'modal-xlg',

    initialize(options) {
        this.model = options.model;
        this.dialogTitle = locale.get('reports.envelope');
        this.dialogButtons = [{
            text: locale.get('reports.close.btn'),
            className: 'btn btn-primary',
            callback: 'cancel',
        }];
        this.endPoint = options.endPoint;
    },

    onRender() {
        this.loadGrid(true);
    },

    onClose() {
        if (this.gridView) {
            this.gridView.close();
        }
    },

    gridRowAction(options) {
        const action = options.action.toUpperCase();

        if (action === 'IMAGE') {
            this.showImage(options);
        }
        return Promise.resolve();
    },

    showImage(options) {
        const req = {
            TRANS_ITEM_ID: options.model.get('TRANS_ITEM_ID'),
            FIELD_TYPE_CODE: options.model.fieldName,
        };
        store.set('lbxImageRequest', req);
        this.navigateTo('REPORTING/lockbox/reports/checkImage');
        dialog.close();
    },

    createGridView() {
        const reportOptions = {
            context: {
                serviceName: `/lockbox/${this.endPoint}` || 'checktransactions',
                productCode: 'GIR',
                functionCode: 'LOCKBOX',
                typeCode: 'LBX_RPT',
            },

            selector: 'none',
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,

            additionalSearchFields: [{
                dataType: 'number',
                fieldName: 'ENVELOPE_ID',
                operator: 'IN',
                fieldValue: [this.model.get('ENVELOPE_ID')],
            }],

            columnsToBeHidden: ['ACTIONLIST', 'NOTE_TEXT'],

            parseRow() {
                const rowHeaders = this.jsonData.rowHeader;
                let header;
                // for each row
                for (let x = 0; x < this.jsonData.rows.length; x += 1) {
                    const row = this.jsonData.rows[x];

                    // and each column in a row
                    for (let y = 0; y < row.columns.length; y += 1) {
                        const column = row.columns[y];
                        let { fieldValue } = column;

                        /*
                         * check for a flag, set it onto the object, and remove it from the
                         * field value
                         */
                        if (fieldValue.indexOf('^Y') > -1) {
                            column.hasCheckImage = true;
                            fieldValue = fieldValue.replace(/\^Y/gi, '');
                        } else {
                            fieldValue = fieldValue.replace(/\^N/gi, '');
                        }
                        column.fieldValue = fieldValue;

                        // get the column header for the current cell
                        for (let z = 0; z < rowHeaders.length; z += 1) {
                            if (rowHeaders[z].fieldName === column.fieldName) {
                                header = rowHeaders[z];
                                break;
                            }
                        }

                        const type = header.fieldType;
                        if (type.toUpperCase() === 'AMOUNT') {
                            column.fieldValue = Formatter.formatNumber(column.fieldValue);
                        }
                    }
                }
            },
        };

        return GridApi.createServiceGridView(reportOptions);
    },

    loadGrid(shouldRenderGridView) {
        if (shouldRenderGridView) {
            this.gridView = this.createGridView();
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
            this.gridRegion.show(this.gridView);
        }
    },

    cancel() {
        dialog.close();
    },
});
