var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"column-account\"><div class=\"account\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"getAccountNumber") || (depth0 != null ? lookupProperty(depth0,"getAccountNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getAccountNumber","hash":{},"data":data,"loc":{"start":{"line":1,"column":48},"end":{"line":1,"column":68}}}) : helper)))
    + "</div></td>\n<td class=\"column-comment\"><div class=\"comment icon-chat-bubble\" title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"SPECIAL_INSTRUCTIONS") || (depth0 != null ? lookupProperty(depth0,"SPECIAL_INSTRUCTIONS") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SPECIAL_INSTRUCTIONS","hash":{},"data":data,"loc":{"start":{"line":2,"column":72},"end":{"line":2,"column":96}}}) : helper)))
    + "\"></div></td>\n<td class=\"column-amount\">\n        <div class=\"amount\">\n                <input type=\"text\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"getAmount") || (depth0 != null ? lookupProperty(depth0,"getAmount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getAmount","hash":{},"data":data,"loc":{"start":{"line":5,"column":42},"end":{"line":5,"column":55}}}) : helper)))
    + "\" data-field=\"transferAmount\" />\n        </div>\n</td>\n<td class=\"column-currency\">\n        <div class=\"currency\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"getCurrency") || (depth0 != null ? lookupProperty(depth0,"getCurrency") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getCurrency","hash":{},"data":data,"loc":{"start":{"line":9,"column":30},"end":{"line":9,"column":45}}}) : helper)))
    + "</div>\n</td>\n<td  class=\"column-actions\">\n        <div class=\"icons\">\n                <button type=\"button\" class=\"btn btn-tertiary\" data-field=\"modifyTransfer\">\n            "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"action.edit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":14,"column":36}}}))
    + "\n                </button>\n\n                <button type=\"button\" class=\"btn btn-tertiary\" data-field=\"removeTransfer\">\n            <span class=\"remove icon-close\"></span><span class=\"sr-only\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"button.delete",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":73},"end":{"line":18,"column":99}}}))
    + "</span>\n                </button>\n        </div>\n</td>\n";
},"useData":true});