import useRequest from '../../hooks/useRequest';
import services from '../../system/servicesList';

const { post } = useRequest();

/**
 * Get users in the dropdown consumable format
 * @param {string} searchValue - value to search in the userId
 * @param {string} userGroup - current user group
 * @param {string|number} inquiryId
 * @returns {Promise}
 */
const getUsers = (userGroup, searchValue, inquiryId = 9071) => {
    let searchFields = [
        {
            fieldName: 'USERGROUP',
            operator: 'CONTAINS',
            fieldValue: [
                userGroup,
            ],
            dataType: 'text',
        },
    ];

    if (searchValue) {
        searchFields = [
            {
                fieldName: 'USERID',
                operator: 'CONTAINS',
                fieldValue: [
                    searchValue,
                ],
                dataType: 'text',
            },
            ...searchFields,
        ];
    }
    const data = {
        rowsPerPage: 20,
        startRow: 1,
        fieldName: 'UserId',
        depends: [
            {
                name: 'USERGROUP',
                value: userGroup,
            },
        ],
        requestParameters: {
            item: [
                {
                    name: 'INQUIRYID',
                    value: inquiryId,
                },
            ],
        },
        searchFields,
    };
    return post(services.tableMaintLookup, data).then((response) => {
        const remapped = response.rows.map((row) => {
            const [userId] = row.columns;
            return {
                id: userId.fieldValue,
                name: userId.fieldValue,
            };
        });
        return remapped;
    });
};

export default getUsers;
