import EntryView from 'common/dynamicPages/views/workflow/entry';

export default EntryView.extend({
    initialize() {
        const options = {
            menuCategory: 'PMTS',
            serviceName: 'payments/corp',
            serviceFunc: null,
            businessType: null,
            context: 'PAY_LIST_VIEW',
            mode: 'reverse',
        };

        EntryView.prototype.initialize.call(this, options);
    },

    getReverseButtons() {
        this.buttonList.push({
            action: 'reverseSubmit',
        });
    },

    reversesubmit() {
        this.pageView.model.overloadedMethodName = 'reverse';
        this.pageView.save();
    },
});
