import React from 'react';
import PropTypes from 'prop-types';
import actionsHelper from './actions_helper';
import useStyles from './Menu.styles';

export default function Menu(props) {
  const {
    gridId,
    restActions,
    rowIndex,
    setOpen
  } = props;
  const classes = useStyles(props);

  const renderActions = (action) => {
    const Action = actionsHelper.getComponent(action);
    const { href, onClick, variant } = actionsHelper.getProps(action, props);

    const onActionClicked = () => {
      onClick();
      setOpen(false);
    };
    return (
      <Action
        className={classes.menuItem}
        key={action.label}
        data-qa={`${gridId}-${action.label}-${rowIndex}`}
        href={href}
        variant={variant}
        onClick={onActionClicked}
      >
        {action.label}
      </Action>
    );
  };
  return (
    <div className={classes.root}>
      {restActions.map(renderActions)}
    </div>
  );
}

Menu.propTypes = {
  /** String to determine qa attributes */
  gridId: PropTypes.string.isRequired,

  /** List of available for menu actions after condition check */
  restActions: PropTypes.arrayOf(
    PropTypes.shape({
      condition: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
      handler: PropTypes.func,
      href: PropTypes.string,
      label: PropTypes.string.isRequired
    })
  ).isRequired,

  /** Number generated by Ag Grid */
  rowIndex: PropTypes.number.isRequired,

  /** Callback to show/hide popover */
  setOpen: PropTypes.func.isRequired
};
