import scheduleTransferView from 'app/payments/views/schedulePayment';

export default scheduleTransferView.extend({
    initialize() {
        this.options = {
            menuCategory: 'PMTS',
            serviceName: 'template/transfer',
            serviceFunc: null,
            businessType: null,
            context: 'TRANSFERS_TEMPLATE_LIST',
            mode: 'view',
        };

        scheduleTransferView.prototype.init.call(this);
    },
});
