import http from '@glu/core/src/http';
import services from 'services';

const useRequest = () => {
    function requestData(method, url, payload = {}) {
        let endpoint = url;
        if (
            endpoint.indexOf(services.bases.client) === -1
            && endpoint.indexOf(services.bases.admin) === -1
        ) {
            endpoint = services.generateUrl(endpoint);
        }

        return new Promise((resolve, reject) => {
            http[method](endpoint, payload).then((response) => {
                resolve(response);
            }, (error) => {
                reject(error);
            });
        });
    }

    const post = (url, payload) => requestData('post', url, payload);
    const get = (url, payload) => requestData('get', url, payload);

    return {
        post,
        get,
    };
};

export default useRequest;
