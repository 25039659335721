/* istanbul ignore file */
export default ({ palette, typography }) => ({
    container: {
        marginBottom: 20,
    },
    label: {
        ...typography.label,
        color: palette.text.dark,
        paddingRight: 5,
    },
    checkboxLabel: {
        ...typography.text,
        fontSize: 14,
        marginLeft: 6,
        color: palette.text.dark,
        display: 'inline',
    },
    input: {
        backgroundColor: 'inherit',
        backgroundImage: 'none',
        border: `1px solid ${palette.form.border}`,
        borderRadius: 4,
        '-webkit-box-shadow': 'inset 0 1px 1px rgba(0, 0, 0, 0.075)',
        boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.075)',
        color: palette.text.dark,
        fontSize: 14,
        height: 34,
        lineHeight: 1.42857143,
        padding: [6, 12],
        '-webkit-transition': 'border-color ease-in-out .15s, box-shadow ease-in-out .15s',
        '-o-transition': 'border-color ease-in-out .15s, box-shadow ease-in-out .15s',
        transition: 'border-color ease-in-out .15s, box-shadow ease-in-out .15s',
        textareaHeight: 60,
        darkFocusColor: palette.focus.dark,
        focusColor: palette.focus.normal,

        dark: {
            backgroundColor: 'inherit',
        },
    },
    numberInput: {
        borderRadius: [4, 0, 0, 4],
    },
    numberFirstButton: {
        borderRadius: [0, 4, 0, 0],
    },
    numberLastButton: {
        borderRadius: [0, 0, 4, 0],
    },
    dark: {
        color: palette.text.light,
        error: {
            color: palette.form.error,
            borderColor: palette.form.error,
        },
        warning: {
            color: palette.error,
            borderColor: palette.error,
        },
    },
    checkbox: {
        checkColor: palette.text.light,
        checkBackground: palette.grey.G800,
        outlineColor: palette.outline,
        shadowColor: palette.grey.G400,
        disabledColor: palette.text.midGray,
        darkFocusColor: palette.focus.dark,
        focusColor: palette.focus.normal,

        dark: {
            checkBackground: 'inherit',
            checkBorder: palette.background,
        },
    },
    radio: {
        radioBackground: 'inherit',
        fill: palette.grey.G800,

        dark: {
            radioBackground: 'inherit',
            fill: palette.text.light,
        },
    },
    // SelectBase
    select: {
        caretRight: 10,
        caretTop: 9,
        darkIconFill: palette.text.light,
        darkOptionColor: palette.text.light,
        darkOptionBackground: palette.primary.darkBackground,
        darkFocusColor: palette.focus.dark,
        focusColor: palette.focus.normal,
        optionBackground: palette.background,
        iconFill: palette.text.dark,
        optionColor: palette.text.dark,
        padding: [9, 30, 9, 10],
        caretIcon: 'none',
        caret: {
            position: 'absolute',
            right: 10,
            top: 16,
            width: 0,
            height: 0,
            borderRight: '4px solid transparent',
            borderLeft: '4px solid transparent',
            borderTop: '4px dashed',
            pointerEvents: 'none',
        },
    },
    // CheckboxRadio
    hiddenCheckbox: {},
    checkboxRadioSvgDisplay: 'none',
    disabled: {
        cursor: 'no-drop',
    },
});
