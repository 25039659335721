import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from '@glu/theming';
import { Loading } from '@glu/indicators-react';

const useStyles = createUseStyles({
  gridWrapper: {
    position: 'relative'
  },

  loader: {
    background: 'rgba(255,255,255, .6)',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%'
  }
});

const GridLoading = ({ isLoading, children, ...props }) => {
  const classes = useStyles(props);

  return (
    <div className={classes.gridWrapper}>
      {children}
      <Loading className={classes.loader} isLoading={isLoading}><></></Loading>
    </div>
  );
};

GridLoading.propTypes = {
  /** Usually Grid.jsx, but occasionally other things in addition to just the grid */
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.element]).isRequired,

  /**
   * True if the loading spinner show show as an overlay over the grid, false if it should not show
   */
  isLoading: PropTypes.bool
};

GridLoading.defaultProps = {
  isLoading: false
};

export default GridLoading;
