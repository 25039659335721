var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "checked ";
},"3":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-group col-md-3\">\n            <div class=\"radio\">\n                <input type=\"radio\" name=\"INCREMENTALEXPORT\" id=\"incremental\" value=\"true\" data-bind=\"model\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"INCREMENTALEXPORT") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":109},"end":{"line":29,"column":149}}})) != null ? stack1 : "")
    + ">\n                <label for=\"incremental\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Incremental",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":30,"column":41},"end":{"line":30,"column":69}}}))
    + "</label>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"INCREMENTALEXPORT\"></span>\n            </div>\n            <div class=\"radio\">\n                <input type=\"radio\" name=\"INCREMENTALEXPORT\" id=\"full_replacement\" value=\"false\" data-bind=\"model\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"INCREMENTALEXPORT") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":115},"end":{"line":34,"column":163}}})) != null ? stack1 : "")
    + ">\n                <label for=\"full_replacement\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Full.Replacement",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":35,"column":46},"end":{"line":35,"column":79}}}))
    + "</label>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"INCREMENTALEXPORT\"></span>\n            </div>\n            <span\n                class=\"icon icon-info\"\n                data-trigger=\"hover\"\n                data-container=\"body\"\n                data-toggle=\"popover\"\n                data-hook=\"getTooltip\"\n                data-placement=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"tooltipPlacement") || (depth0 != null ? lookupProperty(depth0,"tooltipPlacement") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"tooltipPlacement","hash":{},"data":data,"loc":{"start":{"line":44,"column":32},"end":{"line":44,"column":52}}}) : helper)))
    + "\">\n            </span>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"form-group col-md-3\">\n        <div class=\"radio\">\n            <input type=\"radio\"\n                name=\"CURRENTDAY\"\n                id=\"currentDay\"\n                value=\"true\"\n                data-bind=\"model\"\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"CURRENTDAY") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":49}}})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disableCurrentDay") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":10,"column":56}}})) != null ? stack1 : "")
    + ">\n            <label for=\"currentDay\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Current.Day",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":36},"end":{"line":11,"column":64}}}))
    + "</label>\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"CURRENTDAY\"></span>\n        </div>\n        <div class=\"radio\">\n            <input type=\"radio\"\n                name=\"CURRENTDAY\"\n                id=\"prior_day\"\n                value=\"false\"\n                data-bind=\"model\"\n                "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"CURRENTDAY") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":20,"column":57}}})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disableCurrentDay") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":21,"column":56}}})) != null ? stack1 : "")
    + ">\n            <label for=\"prior_day\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Prior.Day",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":35},"end":{"line":22,"column":61}}}))
    + "</label>\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"CURRENTDAY\"></span>\n        </div>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showIncrementalExport") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":4},"end":{"line":47,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});