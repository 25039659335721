var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                </br>\n                <div class=\"glu-alert-layer\">\n                    <div class=\"glu-alert payments-glu-alert-warning\">\n                        <div class=\"glu-alert-content\">\n                            <p class=\"glu-alert-message\">\n                                <span class=\"icon-warning\"></span>\n                                <span class=\"glu-alert-message-content\">\n                                    <b> "
    + alias2(((helper = (helper = lookupProperty(helpers,"getPaymentNumber") || (depth0 != null ? lookupProperty(depth0,"getPaymentNumber") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"getPaymentNumber","hash":{},"data":data,"loc":{"start":{"line":15,"column":40},"end":{"line":15,"column":60}}}) : helper)))
    + " "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.payment.action.required",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":61},"end":{"line":15,"column":104}}}))
    + "</b>\n                                </span>\n                            </p>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"form-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"confirms") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":48,"column":27}}})) != null ? stack1 : "")
    + "                    <div class=\"alert-result\">\n                        <div><b>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ps.common.proceed.payments.warning.confirm",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":50,"column":32},"end":{"line":50,"column":87}}}))
    + "</b></div>\n                    </div>\n                </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"confirmResults") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":24},"end":{"line":47,"column":33}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"alert-result\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"messages") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":32},"end":{"line":39,"column":39}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"confirmData") : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":32},"end":{"line":44,"column":41}}})) != null ? stack1 : "")
    + "                            </div>\n                            </br>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"alert-result\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"messages") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":40},"end":{"line":37,"column":49}}})) != null ? stack1 : "")
    + "                                    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <div class=\"Message Message--"
    + alias4(((helper = (helper = lookupProperty(helpers,"messageType") || (depth0 != null ? lookupProperty(depth0,"messageType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"messageType","hash":{},"data":data,"loc":{"start":{"line":28,"column":73},"end":{"line":28,"column":88}}}) : helper)))
    + "\">\n                                                <span class=\"icon-"
    + alias4(((helper = (helper = lookupProperty(helpers,"messageType") || (depth0 != null ? lookupProperty(depth0,"messageType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"messageType","hash":{},"data":data,"loc":{"start":{"line":29,"column":66},"end":{"line":29,"column":81}}}) : helper)))
    + "\"></span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"messageTitle") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":48},"end":{"line":32,"column":55}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"messageContentArray") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":48},"end":{"line":35,"column":57}}})) != null ? stack1 : "")
    + "                                            </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                        <h5 class=\"Message-title\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"messageTitle") || (depth0 != null ? lookupProperty(depth0,"messageTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"messageTitle","hash":{},"data":data,"loc":{"start":{"line":31,"column":82},"end":{"line":31,"column":98}}}) : helper)))
    + "</h5>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "                                                        <p class=\"Message-content\">"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</p>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"item") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":36},"end":{"line":43,"column":45}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <div>"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":42,"column":45},"end":{"line":42,"column":53}}}) : helper)))
    + "<span> <b> "
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":42,"column":64},"end":{"line":42,"column":73}}}) : helper)))
    + "</b></span></div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"form-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"confirms") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":20},"end":{"line":75,"column":27}}})) != null ? stack1 : "")
    + "                    <div class=\"alert-result\">\n                        <div>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"ps.common.payment.action.required",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":77,"column":29},"end":{"line":77,"column":75}}}))
    + "</div>\n                    </div>\n                </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"confirmResults") : depth0),{"name":"each","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":24},"end":{"line":74,"column":33}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"alert-result\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"messages") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":32},"end":{"line":72,"column":39}}})) != null ? stack1 : "")
    + "                            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog glu-dialog dialog-warning\">\n    <div class=\"modal-content\">\n        <div class=\"modal-header\">\n            <h2 class=\"modal-title\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"title.warning",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":36},"end":{"line":4,"column":62}}}))
    + "</h2>\n        </div>\n        <div class=\"modal-body\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasMultipleApproval1") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":80,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"modal-footer\">\n            <button type=\"submit\" class=\"btn btn-primary\" data-action=\"save\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.ok",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":83,"column":77},"end":{"line":83,"column":99}}}))
    + "</button>\n            <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"cancel\" data-dismiss=\"modal\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":84,"column":101},"end":{"line":84,"column":127}}}))
    + "</button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});