export const showDivider = ({
  hideColumns,
  hideExport,
  hideFilters,
  hidePrint,
  hideDrawerMenu
}) => (
  !hideDrawerMenu && !(hideFilters && hideColumns) && !(hidePrint && hideExport)
);

export const showFilters = ({ hideFilters, hideDrawerMenu }) => (
  !hideFilters && !hideDrawerMenu
);

export const showColumns = ({ hideColumns, hideDrawerMenu }) => (
  !hideColumns && !hideDrawerMenu
);
