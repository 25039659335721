import ItemView from '@glu/core/src/itemView';
import services from 'services';
import serviceUtil from 'common/util/services';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import constants from '../constants';
import template from './contentView.hbs';

const AsyncContentView = ItemView.extend({
    template,
    className: 'ContentView',

    events: {
        'change [data-hook="getExportFormat"]': 'export',
    },

    ui: {
        $exportFormatDropDown: '[data-hook="getExportFormat"]',
    },

    initialize(options) {
        this.exportId = options.exportId;
        this.disposition = options.disposition;
        this.exportFormat = options.exportFormat;
        this.hasDetailReport = options.hasDetailReport;
        this.formId = `getReport${options.disposition}${options.reportId}${options.exportFormat}`;
    },

    onRender() {
        if (this.hasDetailReport === false) {
            this.ui.$exportFormatDropDown.hide();
        }
    },

    onClose() {
        this.$('iframe[name="pdfContent"]').attr('src', 'about:blank');
        this.$('iframe[name="pdfContent"]').remove();
    },

    templateHelpers() {
        return {
            exportFormats: this.getExportFormats(),
            type: 'application/pdf',
            url: this.generateDownloadUrl(),
        };
    },

    /**
     * Get a list of valid export formats
     * @returns {Array} - list of export formats
     */
    getExportFormats() {
        const formats = [{
            value: constants.EXPORT_FORMAT_PDF,
            label: locale.get('reports.export.as.pdf'),
        }, {
            value: constants.EXPORT_FORMAT_CSV,
            label: locale.get('reports.export.as.csv'),
        }, {
            value: constants.EXPORT_FORMAT_TXT,
            label: locale.get('reports.export.as.txt'),
        }];
        return formats.filter(this.isDisabledFormat.bind(this));
    },

    /**
     * Check is this format is in the list of disabled formats
     * @param {Object} format
     * @returns {boolean}
     */
    isDisabledFormat(format) {
        return !util.contains(this.options.disabledFormats, format.value);
    },

    /**
     * Generate the downloadUrl based on the params or use the defaults
     * @returns {string} - download url with params
     */
    generateDownloadUrl(data) {
        return `${services.downloadAsyncReport}?${serviceUtil.generateUrlParams(data || this.getData())}`;
    },

    getData(format, disposition) {
        return {
            disposition: disposition || 'view',
            exportId: this.exportId,
            format: format || 'PDF',
            type: 'jasper',
        };
    },

    /**
     * When target has a value, open the downloadUrl in the target iframe
     * @param {Event} e - change event object
     */
    export(e) {
        if (util.isEmpty(e.target.value)) {
            return;
        }
        window.open(this.generateDownloadUrl(this.getData(e.target.value, 'export')), 'pdfContent');
    },
});

export default AsyncContentView;
