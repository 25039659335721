import $ from 'jquery';
import services from 'services';

export default {
    /**
     * Save data using the table maint service.
     */
    saveData(data) {
        const serialized = JSON.stringify(data);

        return $.ajax({
            contentType: 'application/json',
            url: services.tableMaintSaveData,
            type: 'POST',
            dataType: 'json',
            data: serialized,
        });
    },

    /**
     * Add a new User Defined Record.
     */
    add(data) {
        const serialized = JSON.stringify(data);

        return $.ajax({
            contentType: 'application/json',
            url: services.tableMaintAdd,
            type: 'POST',
            dataType: 'json',
            data: serialized,
        });
    },

    /**
     * Remove data using the table maint service.
     */
    removeData(data) {
        const serialized = JSON.stringify(data);

        return $.ajax({
            contentType: 'application/json',
            url: services.tableMaintRemoveData,
            type: 'POST',
            dataType: 'json',
            data: serialized,
        });
    },

    /**
     * Bulk remove data using the table maint service.
     */
    bulkRemoveData(data) {
        const serialized = JSON.stringify(data);

        return $.ajax({
            contentType: 'application/json',
            url: services.tableMaintBulkRemoveData,
            type: 'POST',
            dataType: 'json',
            data: serialized,
        });
    },

    /**
     * Get data using the table maint service.
     */
    getData(tnum) {
        const data = {
            item: [{
                name: 'TNUM',
                value: tnum,
            }],
        };
        const serialized = JSON.stringify(data);

        return $.ajax({
            contentType: 'application/json',
            url: services.beneAddressBook,
            type: 'POST',
            dataType: 'json',
            data: serialized,
        });
    },
};
