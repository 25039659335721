import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import Model from '@glu/core/src/model';
import validationPatterns from 'system/validatorPatterns';
import AccountList from 'app/payments/multiTransfer/collections/accountList';
import scroll from 'common/util/scroll';
import loadingModalTmpl from 'common/templates/loadingModal.hbs';
import modifyTransferDialogTmpl from './modifyTransferDialog.hbs';

const TransferModel = Model.extend({
    validators: {
        ACCOUNTNUMBER: {
            description: locale.get('payment.AccountNumber'),
            exists: true,
        },

        AMOUNT: {
            description: locale.get('payment.Amount'),
            exists: true,
            matches: validationPatterns.AMOUNT_PATTERN,
        },
    },
});

export default ItemView.extend({
    template: modifyTransferDialogTmpl,
    loadingTemplate: loadingModalTmpl,
    modalClass: 'modal-lg',

    ui: {
        $accountListSelect: '#ACCOUNTNUMBER',
        $alertText: '[data-validation="generic"]',
        $alertContainer: '.alert-message',
    },

    initialize(options) {
        this.transfer = options.transfer;
        this.model = new TransferModel();
        this.loadRequiredData();

        this.dialogTitle = locale.get('payment.account.edit');

        this.dialogButtons = [{
            text: locale.get('payment.save.changes'),
            className: 'btn btn-primary',
            callback: 'save',
        }, {
            text: locale.get('payment.close'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }];
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.populateDialogModel();
            this.ui.$accountListSelect.comboBox({
                placeholder: locale.get('payment.SelectAnAccount'),
                allowClear: true,
            });
            this.ui.$alertContainer.hide();
        }
    },

    populateDialogModel() {
        if (this.transfer.isManyToOne()) {
            this.model.set('ACCOUNTNUMBER', this.transfer.get('DEBIT_ACCOUNT_NUMBER'));
            this.model.set('AMOUNT', this.transfer.get('DEBIT_AMOUNT'));
            this.model.set('CURRENCY', this.transfer.get('DEBIT_CURRENCY'));
        } else {
            this.model.set('ACCOUNTNUMBER', this.transfer.get('BENE_ACCOUNT'));
            this.model.set('AMOUNT', this.transfer.get('CREDIT_AMOUNT'));
            this.model.set('CURRENCY', this.transfer.get('CREDIT_CURRENCY'));
        }
        this.model.set('COMMENTS', this.transfer.get('SPECIAL_INSTRUCTIONS'));
    },

    loadRequiredData() {
        const self = this;
        this.createAccountListPromise().then((result) => {
            self.accountList = result;
            self.setHasLoadedRequiredData(true);
            self.render();
        });
    },

    createAccountListPromise() {
        const self = this;
        return new Promise((resolve, reject) => {
            const accountList = new AccountList({
                transfer: self.transfer,
            });
            accountList.fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    templateHelpers() {
        const self = this;

        return {
            getString(type) {
                return locale.get(type);
            },

            accountList() {
                return self.accountList.toJSON();
            },

            getAmountLabel() {
                return self.transfer.isManyToOne() ? locale.get('payment.DebitAmount') : locale.get('payment.CreditAmount');
            },
        };
    },

    isSameAccount() {
        const account = this.model.get('ACCOUNTNUMBER');
        const otherAccount = this.transfer.isManyToOne() ? this.transfer.get('BENE_ACCOUNT') : this.transfer.get('DEBIT_ACCOUNT_NUMBER');
        return account === otherAccount;
    },

    save() {
        if (this.isSameAccount()) {
            this.ui.$alertText.html(locale.get('payment.FromAndToCannotBeSame'));
            this.ui.$alertContainer.show();
            return;
        }

        if (this.model.isValid()) {
            this.updateTransferModel();
            dialog.close();
            this.trigger('save', this.model);
        } else {
            scroll.scrollToFirstError();
        }
    },

    updateTransferModel() {
        if (this.transfer.isManyToOne()) {
            this.transfer.set('DEBIT_ACCOUNT_NUMBER', this.model.get('ACCOUNTNUMBER'));
            this.transfer.set('DEBIT_AMOUNT', this.model.get('AMOUNT'));
            this.transfer.set('DEBIT_CURRENCY', this.model.get('CURRENCY'));
        } else {
            this.transfer.set('BENE_ACCOUNT', this.model.get('ACCOUNTNUMBER'));
            this.transfer.set('CREDIT_AMOUNT', this.model.get('AMOUNT'));
            this.transfer.set('CREDIT_CURRENCY', this.model.get('CURRENCY'));
        }
        this.transfer.set('SPECIAL_INSTRUCTIONS', this.model.get('COMMENTS'));

        // map popup map data
        const accountModel = this.accountList.get(this.model.get('ACCOUNTNUMBER'));
        const mapDataList = accountModel.get('mapDataList');
        if (mapDataList) {
            for (let i = 0; i < mapDataList.length; i += 1) {
                this.transfer.set(mapDataList[i].toField.toUpperCase(), mapDataList[i].value);
            }
        }
    },

    cancel() {
        dialog.close();
    },
});
