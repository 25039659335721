import CompositeView from '@glu/core/src/compositeView';
import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import paymentHistoryTmpl from './paymentHistory.hbs';
import paymentHistoryRowTmpl from './paymentHistoryRow.hbs';

const RowView = ItemView.extend({
    template: paymentHistoryRowTmpl,
    tagName: 'tr',

    templateHelpers() {
        return {
            currencySign: userInfo.getCurrencySymbol(),
        };
    },
});

export default CompositeView.extend({
    template: paymentHistoryTmpl,
    className: 'panel payment-history',
    itemViewContainer: '.panel-body tbody',
    itemView: RowView,

    ui: {
        $panelHeaderIcon: '.panel-title .icon-wrapper span:first-child',
    },

    events: {
        'hide.bs.collapse': 'toggleIcon',
        'show.bs.collapse': 'toggleIcon',
    },

    toggleIcon() {
        const expanded = (this.$('.panel-heading a').attr('aria-expanded') === 'true');
        this.ui.$panelHeaderIcon.toggleClass('icon-caret-up', !expanded);
        this.ui.$panelHeaderIcon.toggleClass('icon-caret-down', expanded);
    },

    templateHelpers() {
        const totals = this.collection.getTotals();
        util.extend(
            totals,
            {
                id: this.cid,
                currencySign: userInfo.getCurrencySymbol(),
            },
        );

        return totals;
    },
});
