import Layout from '@glu/core/src/layout';
import expandRowChildRowViewTmpl from './expandRowChildRowView.hbs';
/**
 * Serves as a container for a view to be injected into a row.
 * This view will render a very simple row with a single cell
 * that spans the entire length of the table.  This allows a blank
 * container be injected above or below an existing row
 *
 * <tr><td colspan="{{max}}"></td></tr>
 *
 */
export default Layout.extend({
    className: 'expanded-row',
    tagName: 'tr',
    template: expandRowChildRowViewTmpl,

    regions: {
        cell: '> td',
    },

    templateHelpers() {
        return {
            colspan: this.options.colspan || 1,
        };
    },
});
