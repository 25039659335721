import Collection from '@glu/core/src/collection';
import http from '@glu/core/src/http';
import services from 'services';
import CashFlowPlanSummary from '../planning/planSummaryModel';

const Plan = Collection.extend({
    model: CashFlowPlanSummary,

    initialize(attributes, options) {
        this.categoryService = options.categoryService;
        this.listenToOnce(this.categoryService, 'sync', this.fetch);

        this.listenTo(
            this,
            {
                sync: this.handleSync,
                'change remove': this.handleChange,
            },
        );
    },

    handleSync(collection, response, options) {
        if (collection.attributes) {
            // this is the sync event of a child model
            return;
        }

        if (options && options.selectedPlan) {
            this.selectByIdAndVersion(options.selectedPlan.get('cashFlowPlanId'), options.selectedPlan.get('version'));
        } else {
            this.select(0);
        }
    },

    handleChange(data) {
        if (!data || !data.get('selected')) {
            return;
        }

        this.each((m) => {
            if (m.cid !== data.cid) {
                m.set('selected', false);
            }
        });
    },

    selectedPlan() {
        return this.findWhere({
            selected: true,
        });
    },

    getByIdAndVersion(id, version) {
        return this.findWhere({
            cashFlowPlanId: +id,
            version: +version,
        });
    },

    selectByIdAndVersion(id, version) {
        const m = this.getByIdAndVersion(id, version);
        m.set('selected', true);
    },

    select(idx) {
        // helper method that is used sparingly.
        const m = this.at(idx);
        if (m) {
            m.set('selected', true);
        }
    },

    sync(method, model, options) {
        // results won't make sense if categories haven't loaded yet
        if (method === 'read' && this.categoryService.hasLoaded()) {
            // in the future, this will happen at the collection level
            const xhr = http.post(
                services.generateUrl('/cashflowPlan/summary'),
                (response) => {
                    options.success(response);
                },
                (response) => {
                    options.error(response);
                },
            );

            this.trigger('request', this, xhr, options);
            return xhr;
        }
        return undefined;
    },
});

export default Plan;
