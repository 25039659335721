import { appBus } from '@glu/core';
import { connect } from '@autobooks/session-extension';
import http from '@glu/core/src/http';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import services from 'services';

export default {

    initAutoBooksKeepAliveConnect() {
        let iframeElement;
        let cleanUp;
        const autobooksUrl = serverConfigParams.get('autobooksUrl');
        const autobooksKeepAliveMethod = serverConfigParams.get('autobooksKeepAliveMethod');
        const autobooksPollInterval = serverConfigParams.get('autobooksPollInterval') ? serverConfigParams.get('autobooksPollInterval') : 30000;
        const autobooksConnectTimeout = serverConfigParams.get('autobooksConnectTimeout') ? serverConfigParams.get('autobooksConnectTimeout') : 10000;
        const loadAutoBooksKeepAliveConnect = async function () {
            try {
                const { startKeepAlive, disconnect } = await connect({
                    iframeElement,
                    targetOrigin: autobooksUrl,
                    connectTimeout: autobooksConnectTimeout,
                });
                const { stopKeepAlive } = startKeepAlive(autobooksPollInterval, () => {
                    if (autobooksKeepAliveMethod === 'message') {
                        appBus.trigger(
                            'keepSessionAlive',
                            {
                                data: {
                                    type: 'keepSessionAlive',
                                },
                            },
                        );
                    } else {
                        http.get(services.keepAlive);
                    }
                });

                cleanUp = function () {
                    disconnect();
                    stopKeepAlive();
                };
            } catch (error) {
                window.console.error(error);
            }
        };

        const clearAutoBooksKeepAliveConnect = async function () {
            cleanUp();
        };

        iframeElement = document.getElementById('ssoFrame');
        if (iframeElement) {
            iframeElement.addEventListener('load', () => loadAutoBooksKeepAliveConnect());
            iframeElement.addEventListener('beforeunload', () => clearAutoBooksKeepAliveConnect());
        }
    },

};
