import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import LineManagerView from './lineManager';
import ScenarioSelectView from './scenarioSelect';
import managerLayoutTmpl from './managerLayout.hbs';

const ManagerLayout = Layout.extend({
    template: managerLayoutTmpl,
    className: 'scenario-settings-drawer container',

    initialize(options) {
        this.lineCollection = options.lineCollection;
        this.scenarioCollection = options.scenarioCollection;
        this.transactionCollection = options.transactionCollection;

        this.filterCollection = options.filterCollection;
        this.activeEditView = null;
    },

    events: {
        'click button[data-hook="done"]': 'remove',
        'click button[data-hook="editLines"]': 'showLineEditMode',
        'click button[data-hook="editScenarios"]': 'showScenarioEditMode',
        'click button[data-hook="saveEdits"]': 'saveEdits',
        'click button[data-hook="cancelEdits"]': 'cancelEdits',
    },

    regions: {
        primary: '.primary',
        secondary: '.secondary',
    },

    onRender() {
        this.primary.show(new LineManagerView({
            collection: this.lineCollection,
            scenarios: this.scenarioCollection,
        }));

        this.secondary.show(new ScenarioSelectView({
            collection: this.scenarioCollection,
            transactions: this.transactionCollection,
            lines: this.lineCollection,
            filters: this.filterCollection,
        }));
    },

    showScenarioEditMode() {
        this.showFooterEditControls(true, '.scenario');
        this.activeEditView = this.secondary.currentView;
        this.triggerEditMode();
    },

    showLineEditMode() {
        this.showFooterEditControls(true, '.line');
        this.activeEditView = this.primary.currentView;
        this.triggerEditMode();
    },

    triggerEditMode() {
        this.activeEditView.$el.parent().siblings().addClass('masked');
        this.activeEditView.$el.parent().siblings().css('overflow-y', 'hidden');
        this.activeEditView.$el.parent().siblings().scrollTop(0);
        this.activeEditView.trigger('showEditMode');
    },

    showFooterEditControls(show, controlsSelector) {
        if (show && controlsSelector) {
            this.$('.actions.edit-mode').filter(controlsSelector).toggleClass('hidden', false);
        } else {
            this.$('.actions.edit-mode').toggleClass('hidden', true);
        }
        this.$('.actions.view-mode').toggleClass('hidden', show);
    },

    saveEdits() {
        let validModels = true;
        if (this.activeEditView.manageLines !== undefined) {
            // test if the scenarios are valid
            util.each(this.activeEditView.collection.models, (line) => {
                if (!line.isValid()) {
                    validModels = false;
                }
            });
        }

        if (validModels === true) {
            this.showFooterEditControls(false);
            this.activeEditView.trigger('saveEditModeChanges');
            this.activeEditView.$el.parent().siblings().css('overflow-y', '');
            this.activeEditView.$el.parent().siblings().removeClass('masked');
            this.activeEditView = null;
        }
    },
});

export default ManagerLayout;
