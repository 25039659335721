var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "first-panel";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel panel-primary\" role=\"tablist\">\n        <div class=\"panel-heading parent-panel-heading "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFirst") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":55},"end":{"line":2,"column":88}}})) != null ? stack1 : "")
    + "\" role=\"tab\" id=\"panel-heading-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":2,"column":119},"end":{"line":2,"column":126}}}) : helper)))
    + "\">\n                <h3 class=\"panel-title\">\n                                <a role=\"button\" data-toggle=\"collapse\" href=\"#combined-account-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":4,"column":96},"end":{"line":4,"column":103}}}) : helper)))
    + "\" aria-expanded=\"false\" aria-controls=\"combined-account-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":4,"column":159},"end":{"line":4,"column":166}}}) : helper)))
    + "\" class=\"collapsed\">\n                                <span class=\"indicator-icon\"></span><span class=\"account-number\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"gir.summaryTotals",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":97},"end":{"line":5,"column":127}}}))
    + " <span data-hook=\"account-count\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"count") || (depth0 != null ? lookupProperty(depth0,"count") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"count","hash":{},"data":data,"loc":{"start":{"line":5,"column":160},"end":{"line":5,"column":169}}}) : helper)))
    + " "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"gir.accounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":170},"end":{"line":5,"column":195}}}))
    + "</span></span>\n                                <span class=\"account-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"BANKCODE") || (depth0 != null ? lookupProperty(depth0,"BANKCODE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BANKCODE","hash":{},"data":data,"loc":{"start":{"line":6,"column":59},"end":{"line":6,"column":71}}}) : helper)))
    + "</span>\n                        </a>\n                </h3>\n\n                <div class=\"date-range\">\n                        "
    + alias4(((helper = (helper = lookupProperty(helpers,"AsOfDate") || (depth0 != null ? lookupProperty(depth0,"AsOfDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"AsOfDate","hash":{},"data":data,"loc":{"start":{"line":11,"column":24},"end":{"line":11,"column":36}}}) : helper)))
    + "\n                </div>\n\n                <div data-hook=\"account-summary-region\">\n                        <ul class=\"account-summary\">\n                                <li>\n                                        <span>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"gir.totalTransactions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":46},"end":{"line":17,"column":80}}}))
    + "</span>\n                                        <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"TOTALITEMSREPORTED") || (depth0 != null ? lookupProperty(depth0,"TOTALITEMSREPORTED") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOTALITEMSREPORTED","hash":{},"data":data,"loc":{"start":{"line":18,"column":46},"end":{"line":18,"column":68}}}) : helper)))
    + "</span>\n                                </li>\n\n                                <li>\n                                        <span>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"ACH.TotalAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":46},"end":{"line":22,"column":74}}}))
    + "</span>\n                                        <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"TOTALAMOUNTREPORTED") || (depth0 != null ? lookupProperty(depth0,"TOTALAMOUNTREPORTED") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOTALAMOUNTREPORTED","hash":{},"data":data,"loc":{"start":{"line":23,"column":46},"end":{"line":23,"column":69}}}) : helper)))
    + "</span>\n                                </li>\n\n                                <li>\n                                        <span>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"gir.presentment1",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":46},"end":{"line":27,"column":75}}}))
    + "</span>\n                                        <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"first") || (depth0 != null ? lookupProperty(depth0,"first") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"first","hash":{},"data":data,"loc":{"start":{"line":28,"column":46},"end":{"line":28,"column":55}}}) : helper)))
    + "</span>\n                                </li>\n\n                                <li>\n                                        <span>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"gir.presentment2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":46},"end":{"line":32,"column":75}}}))
    + "</span>\n                                        <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"second") || (depth0 != null ? lookupProperty(depth0,"second") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"second","hash":{},"data":data,"loc":{"start":{"line":33,"column":46},"end":{"line":33,"column":56}}}) : helper)))
    + "</span>\n                                </li>\n                        </ul>\n                </div>\n        </div>\n        <div aria-expanded=\"false\" id=\"combined-account-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":38,"column":56},"end":{"line":38,"column":63}}}) : helper)))
    + "\" class=\"panel-collapse collapse\" role=\"tabpanel\" aria-labelledby=\"panel-heading-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":38,"column":144},"end":{"line":38,"column":151}}}) : helper)))
    + "\">\n                <div class=\"panel-body\">\n                        <div class=\"accountReport\" data-region=\"cdAccountRegion\"></div>\n                </div>\n        </div>\n</div>\n";
},"useData":true});