import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import locale from '@glu/locale';

export default Model.extend({

    /*
     * @param {Object} attributes - Incoming data
     * @param {Object} options
     * @param {String} options.service - The URL to fetch data from
     * @param {Array} [options.order] - Optional display order, based on PayGroup
     * Identifier
     * @param {Object} [options.payGroupConfig] - Optional config options,
     *    keyed by PayGroup Identifier. 'ALL' applies to all PayGroups
     * @param {boolean} [options.payGroupConfig.disableDebitTotals] -
     *    Hide Debit totals for this PayGroup
     * @param {boolean} [options.payGroupConfig.disableCreditTotals] -
     *    Hide Credit totals for this PayGroup
     * @param {boolean} [options.payGroupConfig.showZeroValueAccounts] -
     *    Render zero-value account
     */
    initialize(attributes, options = {}) {
        this.service = options.service;
        this.order = options.order || false;
        this.payGroupConfig = options.payGroupConfig || {};
    },

    defaults: {
        filters: [],
    },

    sync(method, model, options) {
        if (method === 'read') {
            const data = {
                rowsPerPage: 0,
                startRow: 0,
                searchFields: this.get('filters'),
                viewId: this.get('viewId'),
            };

            http.post(this.service, data, (response) => {
                options.success(response);
            }, () => {
                options.error();
            });
        }
    },

    parse(response) {
        /*
         *  1. Loop over paymentGroups
         *      1.1 Split paymentGroup to creditCurrency and debitCurrency, match
         * currencyPair
         *      1.2 Change title of Wires paymentGroup to its Currency
         *  2. Loop over currencyPairs
         *      2.1 Change title to read debitCurrency "to" creditCurrency (eg: USD to CAD)
         *  3. Join both, and loop over full list
         *      3.1 Flag if amount based on indicativeRate
         *      3.2 Create 'Totals' array from Debit and Credit values
         *      3.3 Remove any Tiles with nothing to display
         *  4. Filter full list and remove any payment groups with no tiles
         *  5. Sort the paymentGroups if a sort order is defined
         */

        let paymentGroups = response.paymentTotalsByPaymentGroupList;
        util.each(paymentGroups, (paymentGroup) => {
            /*
             * Create a PaymentGroup config object based on configuration objects...
             * 1. options.payGroupConfig[IDENTIFIER]
             * 2. options.payGroupConfig.ALL
             * 3. payGroupDefaults
             */
            const paramPaymentGroup = paymentGroup;
            const groupIdentifier = paymentGroup.identifier;
            const tileConfigID = this.payGroupConfig[groupIdentifier] || {};
            const allTileConfig = this.payGroupConfig.ALL || {};
            const payGroupDefaults = {
                showZeroValueAccounts: false,
                disableDebitTotals: false,
                disableCreditTotals: false,
            };
            const tileConfig = util.extend(payGroupDefaults, allTileConfig, tileConfigID);

            // Parse the Subgroups
            util.each(paymentGroup.paymentTotalsByPaymentSubGroup, (tile) => {
                const paramTile = tile;
                paramTile.creditCurrency = paramTile.currency;
                paramTile.debitCurrency = paramTile.currency;
                this.parseTitles(paramTile);
            }, this);

            // Parse the Currency pairs
            util.each(paymentGroup.currencyPairs, (tile) => {
                const paramTile = tile;
                paramTile.subGroupName = `${paramTile.debitCurrency} ${locale.get('payment.to')} ${paramTile.creditCurrency}`;
            });

            // Parse the joined data
            const paymentSubgroups = paymentGroup.paymentTotalsByPaymentSubGroup;
            const joined = paymentSubgroups.concat(paymentGroup.currencyPairs);
            util.each(joined, (tile) => {
                const tileParam = tile;
                // Check for Indicitive amount
                tileParam.isIndicative = tileParam.containsIndicativeRateTransactions;
                // Create array of "Totals"
                tileParam.totals = [];
                /*
                 * Optionally create tile for accounts with zero value
                 * for both debitTotal and creditTotal
                 */
                if (tileConfig.showZeroValueAccounts
                    && tileParam.debitTotal === 0
                    && tileParam.creditTotal === 0) {
                    tileParam.totals.push(this.createTotalsObj(tileParam.debitTotal, tileParam.debitCurrency, '', groupIdentifier));
                } else {
                    if (tileParam.debitTotal && !tileConfig.disableDebitTotals) {
                        tileParam.totals.push(this.createTotalsObj(tileParam.debitTotal, tileParam.debitCurrency, 'Debit', groupIdentifier));
                    }
                    if (tileParam.creditTotal && !tileConfig.disableCreditTotals) {
                        tileParam.totals.push(this.createTotalsObj(tileParam.creditTotal, tileParam.creditCurrency, 'Credit', groupIdentifier));
                    }
                }
            }, this);

            // Remove Tiles with nothing to display
            const final = util.filter(joined, tile => tile.totals.length > 0);

            // Set parsed data as new attribute for CollectionView to use as its Collection
            paramPaymentGroup.totalTiles = final;
        }, this);

        // Remove empty paymentGroups
        paymentGroups = util.filter(paymentGroups, group => group.totalTiles.length > 0);

        // Sort if order is specified
        const sortByOrder = obj => util.indexOf(this.order, obj.identifier);
        if (this.order) {
            paymentGroups = util.sortBy(paymentGroups, sortByOrder, this);
        }

        // Set the parsed array back into the response
        response.paymentTotalsByPaymentGroupList = paymentGroups;
        return response;
    },
    createTotalsObj(amount, currency, label, groupIdentifier) {
        const total = {
            amount,
            currency,
        };
        /*
         * Only Show SubTotal Currancy labels for these. This logic
         * may need to flip based on requirements of future categories
         */
        if (groupIdentifier === 'ACH' || groupIdentifier === 'WIRES' || groupIdentifier === 'RTP') {
            total.type = label;
        }

        return total;
    },
    parseTitles(tile) {
        const paramTile = tile;
        // If this is a single-currency WIRE or RTP payment, set the title to the currency
        if (tile.identifier === 'WIRES' || tile.identifier === 'RTP') {
            paramTile.subGroupName = tile.currency;
        }
    },
});
