import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import constants from 'app/administration/views/paymentAutomationManager/constants';
import ListView from 'common/dynamicPages/views/workflow/list';
import listViewConfig from 'common/util/listViewConfig/listViewConfig';
import systemConfig from 'system/configuration';
import workspaceHelper from 'common/workspaces/api/helper';
import detailsTmpl from './details.hbs';

const isDeeplink = () => window.parent !== window;

export default ListView.extend({
    className: 'PaymentAutomation-fileManagerDetail',

    template: detailsTmpl,

    initialize(options) {
        const model = store.get(constants.PAY_AUT_HIST);

        this.uniqueFileId = model && model.get('UNIQUEFILEID');
        this.fileName = model && model.get('FILENAME');
        this.returnRoute = model && model.get('returnRoute');

        const superOptions = {
            menuCategory: 'REPORTING',
            serviceName: constants.DETAIL_SERV,
            serviceFunc: null,
            businessType: null,
            context: constants.PAY_AUT_HIST,
            additionalSearchFields: this.getInitialFilters(),
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    /**
     * creates the listViewConfig and sets the member lvc to it
     * @param {object} [context]
     * @return {object} returns the listViewConfig
     */
    setListViewConfig(context) {
        let options;
        if (systemConfig.isAdmin()) {
            options = {
                productCode: 'ADMPAY',
                functionCode: 'AUTOMTN',
                typeCode: 'PAYAUTLC',
                subType: 'DETAILS',
                actionMode: 'SELECT',
            };
        } else {
            options = {
                productCode: 'PA',
                functionCode: 'AUTOMTN',
                typeCode: 'PAYAUTLC',
                subType: 'DETAILS',
                actionMode: 'SELECT',
            };
        }
        this.lvc = listViewConfig.getInstance(this, util.extend({}, context, options));
        return this.lvc;
    },

    /**
     * Return filters for listView initiating
     * @returns {{
     *      fieldValue: string[],
     *      fieldName: string,
     *      dataType: string,
     *      operator: string
     * }[]}
     */
    getInitialFilters() {
        return [{
            fieldValue: [this.uniqueFileId],
            fieldName: 'UNIQUEFILEID',
            dataType: 'text',
            operator: 'EQ',
        }];
    },

    templateHelpers() {
        return util.extend(ListView.prototype.templateHelpers.call(this), {
            fileNameLabel: () => this.fileName,
        });
    },

    cancel() {
        if (isDeeplink() && this.returnRoute) {
            this.navigateTo(this.returnRoute);
        } else {
            workspaceHelper.returnToCurrentWorkspace(this);
        }
    },
});
