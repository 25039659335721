import $ from 'jquery';
import util from '@glu/core/src/util';
import cache from 'system/utilities/cache';

export default {
    setupAdvancedFilter(view, $alt) {
        // Support an alternate container element
        const $el = $alt || view.$el;

        const filterData = cache.get('advanceFilterCache');

        if (typeof filterData !== 'undefined') {
            util.each(filterData, (value) => {
                if (value) {
                    $el.find(`input[id*=${value.fieldName}]`).each(() => {
                        view.model.set(value.fieldName, value.fieldValue[0]);
                        $(`#${value.fieldName}`).trigger('change');
                    });
                    $el.find(`input[name*=${value.fieldName}]`).each(() => {
                        view.model.set(value.fieldName, value.fieldValue[0]);
                        $(`#${value.fieldName}`).trigger('change');
                    });
                    $el.find(`select[id*=${value.fieldName}-equality]`).each((index, element) => {
                        $(element).val(value.operator);
                    });
                }
            });
            cache.unset('advanceFilterCache');
        }
    },
};
