import Model from '@glu/core/src/model';
import services from 'services';
import helper from 'system/mfa/helper';
import { postData } from 'common/util/services';

export default Model.extend({
    sync(method, model, options) {
        let service = services.generateUrl(`mfaService/${helper.translateUrlForLogin('validate')}`);
        let data = this.convertModelAttributesToServerJSON();
        if (method === 'read') {
            data = {};
            service = services.generateUrl('mfaService/rsaAdaptiveMarkup');
        }
        return postData(service, data, options.success, options.error);
    },

    /**
     * @method convertModelAttributesToServerJSON
     * @returns {{challengedAction: *, oneTimePassword: string, tokenString:
     * string, tokenPassCode: *, tokenPin: string, customParameters: {item: *[]}}}
     */
    convertModelAttributesToServerJSON() {
        const challengeAction = new Model(this.get('challengedAction'));
        return {
            challengedAction: challengeAction.toJSON(),
            oneTimePassword: '',
            tokenString: '',
            tokenPassCode: this.get('callbackToken'),
            tokenPin: '',

            customParameters: {
                item: [{
                    name: '',
                    value: '',
                }],
            },
        };
    },
});
