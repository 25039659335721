var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"icon").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{"className":"btn btn-tertiary","action":"handleAddNewSegment","textVisible":true,"locale":"segment.newSegment","name":"add-circle"},"data":data,"loc":{"start":{"line":8,"column":24},"end":{"line":8,"column":154}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n        <h1 class=\"landing-header\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"segment.header",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":35},"end":{"line":2,"column":62}}}))
    + "</h1>\n</div>\n<section class=\"section section-container\">\n        <div class=\"section-body\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasInsertEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":9,"column":23}}})) != null ? stack1 : "")
    + "\n                <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n                <div data-region=\"segmentationGrid\"></div>\n        </div>\n</section>\n";
},"useData":true});