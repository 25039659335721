var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <div class=\"page-header-wrapper\">\n        <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.freeCheck.enrollment.header",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":83}}}))
    + "</h1>\n\n        <div class=\"page-header\" data-region=\"header\"></div>\n    </div>\n  \n\n    <section class=\"section section-container\">\n              <h2> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.freeCheck.info1",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":19},"end":{"line":10,"column":55}}}))
    + " </h2>\n        <div class=\"section-body\">         \n                        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n            <div class=\"pageRegion remove-section-styles\" region=\"pageRegion\"></div>\n        </div> \n        <div class=\"form-group\">\n                    <button class=\"btn btn-primary\" data-hook=\"getSubmitBtn\" type=\"button\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":91},"end":{"line":16,"column":115}}}))
    + "</button>\n                    <button class=\"btn btn-tertiary\" data-hook=\"getCancelBtn\" type=\"button\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":92},"end":{"line":17,"column":118}}}))
    + "</button>\n                    <button class=\"btn btn-tertiary hidden\" data-hook=\"getResetBtn\" type=\"button\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.reset",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":98},"end":{"line":18,"column":124}}}))
    + "</button>\n                </div>\n    </section>\n</div>\n";
},"useData":true});