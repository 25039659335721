import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import LocationAccountView from 'mobile/common/views/locationAccountView';
import AccountView from 'mobile/common/views/accountView';
import MobileAmountInput from 'mobile/common/views/inputs/mobileAmountInput';
import ConfirmationDialog from 'mobile/remoteDepositCapture/views/confirmationDialog';
import services from 'services';
import { postData } from 'common/util/services';
import loadingTmpl from 'common/templates/loadingPage.hbs';

import RemoteDepositBaseLayout from './remoteDepositBase';
import RemoteDeposit from '../models/remoteDeposit';
import MiSnapImageView from './miSnapImage';
import addRemoteDepositTmpl from './addRemoteDeposit.hbs';

const RDC_ANALYZE_CHECK_IMAGES = 'imaging/remoteDepositCapture/analyzeCheckImages';
const DEPOSIT_STATUS = {
    NEW: 'NEW',
    MODIFY: 'MODIFY',
    CONFIRM: 'CONFIRM',
};
const IS_CONFIRMABLE = 'isConfirmable';

export default RemoteDepositBaseLayout.extend({
    template: addRemoteDepositTmpl,
    loadingTemplate: loadingTmpl,
    className: 'remote-deposit-capture',

    ui: {
        $depositButton: '[data-hook="getDepositButton"]',
        $retakeImagesButton: '[data-hook="getRetakeImagesButton"]',
    },

    appEvents: {
        'RDC:transfer:image': 'receiveImage',
    },

    events: {
        'click @ui.$depositButton': 'submitDeposit',
        'click @ui.$retakeImagesButton': 'resetImages',
        'blur [data-hook="getAmount"], [data-hook="getAccountNum"], [data-hook="getLocation"]': 'onInputBlur',
    },

    initialize(options) {
        RemoteDepositBaseLayout.prototype.initialize.call(this, options);
        this.depositStatus = DEPOSIT_STATUS.NEW;
        this.model = new RemoteDeposit();

        this.mobileAmountInput = new MobileAmountInput({
            model: this.model,
            attribute: 'AMOUNT',
            label: locale.get('rdc.Amount'),
        });
        this.listenTo(
            this.model, 'change:ACCOUNTNUM change:FRONT_IMAGE change:BACK_IMAGE',
            this.analyzeCheckImages,
        );
    },

    templateHelpers() {
        return {
            showMemo: serverConfigParams.get('MobileRDCShowMemoField') === 'true',
            amountValidationWorkflow: this.amountValidationWorkflow,
            imgBase: `${window.Bottomline.assetRoot}/images/${window.Bottomline.buildId}/`,
        };
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.frontImageView = this.renderMiSnapImage(this.frontImageRegion, locale.get('rdc.Front'), 'FRONT_IMAGE');
            this.renderMiSnapImage(this.backImageRegion, locale.get('rdc.Back'), 'BACK_IMAGE');
            this.renderlocationAccountWorkflow();
            if (!this.amountValidationWorkflow) {
                this.amountInputRegion.show(this.mobileAmountInput);
            }
        } else {
            this.loadRequiredData();
        }
    },

    renderlocationAccountWorkflow() {
        this.model.addLocationValidation(this.useLocationWorkflow);

        if (this.useLocationWorkflow) {
            this.locationAccountWorkflow = new LocationAccountView({
                accounts: this.accounts,
                locations: this.locations,
                model: this.model,
            });
        } else {
            this.locationAccountWorkflow = new AccountView({
                model: this.model,
                accounts: this.accounts,
            });
        }
        this.locationAccountRegion.show(this.locationAccountWorkflow);
    },

    renderMiSnapImage(region, label, modelAttribute) {
        const imageView = new MiSnapImageView({
            model: this.model,
            label,
            checkSide: modelAttribute,
            amountValidationWorkflow: this.amountValidationWorkflow,
        });
        region.show(imageView);
        return imageView;
    },

    /**
     * Set the appropriate attribute of the mobel with the base64 encoded image string
     * @param data - {checkSide: attribute on the model (FRONT_IMAGE or BACK_IMAGE),
     * image: base64 encoded string}
     */
    receiveImage(data) {
        this.model.set(data.checkSide, data.image);
        this.validateAttribute(data.checkSide);
    },

    /**
     * Determine if the current state of the application
     * requires checkAnalysis
     * @returns {boolean}
     */
    shouldAnalyzeCheckImages() {
        return this.amountValidationWorkflow
            && this.model.hasRequiredAnalysisData(this.locationAccountWorkflow)
            && !this.model.hasAmount();
    },

    /**
     * Show the spinner in the amount region and fetch check analysis
     * @return {Promise}
     */
    analyzeCheckImages() {
        if (!this.shouldAnalyzeCheckImages()) {
            return;
        }
        this.analyzedAmountRegion.show(new Layout({
            template: loadingTmpl,
        }));
        this.getCheckAnalysis();
    },
    /**
     * Submit check image data to the server for analysis
     * @return {Promise}
     */
    getCheckAnalysis() {
        this.setBankCode(this.accounts, this.model);
        return postData(
            services.generateUrl(RDC_ANALYZE_CHECK_IMAGES),
            this.model.getRequestData(this.model),
        ).then(this.receiveCheckAnalysis.bind(this))
            .catch(this.onAnalyzeError.bind(this));
    },

    /**
     * Show errors and toggle retake images button
     * @param {Object} response - standard DGB response
     */
    onAnalyzeError(response) {
        this.toggleHideRetakeImagesButton(false);
        this.analyzedAmountRegion.reset();
        this.actionResponseError(response);
    },

    /**
     * Update the model with check analysis data
     * @param {Object} data - server response
     */
    receiveCheckAnalysis(data) {
        this.model.set('AMOUNT', data.analyzeDepositImage.formattedCheckAmount);
        this.validateAttribute('AMOUNT');
        this.analyzedAmountRegion.show(this.mobileAmountInput);
        this.toggleHideRetakeImagesButton(false);
    },

    /**
     * Toggle the retake images button
     * @param {boolean} hide - hide or not hide
     */
    toggleHideRetakeImagesButton(hide) {
        this.ui.$retakeImagesButton.toggleClass('hide', hide);
    },

    /**
     * Reset model image data and hide UI components that were shown when
     * image data was set
     */
    resetImages() {
        this.toggleHideRetakeImagesButton(true);
        this.model.set({
            FRONT_IMAGE: null,
            BACK_IMAGE: null,
        });
        this.model.set('AMOUNT', this.model.defaults.AMOUNT);
        this.enableDisableAction();
        this.frontImageView.initiateMiSnap();
        this.analyzedAmountRegion.reset();
    },

    /**
     * Validate the model, set the bank sortCode and save
     */
    submitDeposit() {
        const saveFnc = this.depositStatus === DEPOSIT_STATUS.NEW
            ? this.saveDeposit : this.updateDeposit;
        if (this.model.isValid()) {
            this.setButtonBusy(true);
            this.setBankCode(this.accounts, this.model);
            saveFnc.call(this)
                .then(this.onDepositSuccess.bind(this))
                .catch(this.onDepositError.bind(this));
        }
    },

    /**
     * Save the model
     */
    saveDeposit() {
        return this.model.save();
    },

    /**
     * Update the model
     */
    updateDeposit() {
        return this.model.update();
    },

    /**
     * Make a call to confirm the deposit an handle response and errors
     * @return {Promise}
     */
    onConfirm() {
        this.depositStatus = DEPOSIT_STATUS.CONFIRM;
        return this.model.confirm()
            .then(this.onDepositSuccess.bind(this))
            .catch(this.onDepositError.bind(this));
    },

    /**
     * Check the response to see if submitting a deposit
     * requires confirmation
     * @param {Object} response
     */
    onDepositError(response) {
        const { actionResponse, RdcSessionData } = response.responseJSON;
        if (actionResponse.resultType === IS_CONFIRMABLE) {
            this.model.set('rdcSessionData', RdcSessionData);
            const confirmDialog = dialog.open(new ConfirmationDialog({
                response: actionResponse,
                onConfirm: this.onConfirm.bind(this),
                onModify: this.onModify.bind(this),
            }));
            confirmDialog.once('close', this.onConfirmationDialogClose.bind(this));
        } else {
            RemoteDepositBaseLayout.prototype.actionResponseError.call(this, response);
        }
    },

    /**
     * When the status is CONFIRM, it means that the dialog
     * was closed from the confirm button. Otherwise, it was close
     * with the 'x' or the modify the button. In that case, treat
     * the closure as if having clicked the modify button.
     */
    onConfirmationDialogClose() {
        if (this.depositStatus === DEPOSIT_STATUS.CONFIRM) {
            return;
        }
        this.onModify();
    },

    /**
     * Set the model with rdcSessionData and enable the button
     */
    onModify() {
        this.locationAccountWorkflow.toggleDisabled(true);
        this.depositStatus = DEPOSIT_STATUS.MODIFY;
        this.setButtonBusy(false);
    },

    /**
     * Show alert with success message and reset the model for another submission
     *
     * @param {Object } response - response object
     */
    onDepositSuccess(response) {
        const { actionResponse } = response;
        this.setButtonBusy(false);
        dialog.alert(actionResponse.message.join(' '), this.resetForm.bind(this));
    },

    /**
     * Reset the model and reset the form to the initial state
     */
    resetForm() {
        this.depositStatus = DEPOSIT_STATUS.NEW;
        if (this.amountValidationWorkflow) {
            this.toggleHideRetakeImagesButton(true);
            this.analyzedAmountRegion.reset();
        } else {
            this.mobileAmountInput.trigger('resetAmount');
        }
        this.locationAccountWorkflow.toggleDisabled(false);
        this.resetModel();
        this.enableDisableAction();
    },

    /**
     * Reset the model to the defaults
     */
    resetModel() {
        this.model.set(this.model.defaults);
        this.locationAccountWorkflow.checkForSingleItem();
    },

    /**
     * Set aria-busy attribute of the button to the value provided
     * @param {boolean} value
     */
    setButtonBusy(value) {
        this.ui.$depositButton.attr('aria-busy', value);
    },

    /**
     * Toggle the submit button based on the model being ready for submission
     */
    enableDisableAction() {
        this.ui.$depositButton.prop('disabled', !this.model.isReadyForSubmission());
    },
});
