var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"section section-container\">\n    <div class=\"alert-message\">\n        <div data-region=\"alertRegion\" role=\"alert\"></div>\n    </div>\n</section>\n<form class=\"ScheduledExports-add\">\n    <fieldset class=\"section section-container\">\n        <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Export.Detail",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":46}}}))
    + "</legend>\n        <div class=\"row\">\n            <div class=\"col-md-3 required\">\n                <div data-region=\"fileFormatRegion\"></div>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"FILE_FORMAT\"></span>\n            </div>\n        </div>\n        <div class=\"MultiField-region\" data-region=\"multifieldRegion\"></div>\n    </fieldset>\n    <fieldset class=\"section section-container\">\n        <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Report.Criteria",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":18,"column":48}}}))
    + "</legend>\n        <div class=\"row\">\n            <div class=\"col-md-3 required\">\n                <div data-region=\"reportIdRegion\"></div>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"REPORT_ID\"></span>\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"col-md-3 required\">\n                <div data-region=\"profileNumberRegion\"></div>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"PROFILE_NUMBER\" data-hook=\"getProfileNumValidationMessages\"></span>\n            </div>\n        </div>\n    </fieldset>\n</form>\n\n";
},"useData":true});