import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@glu/dropdown-react';

const MultiSelectFilter = ({
  MultiselectFilterProps,
  createFilterValue,
  fieldId,
  filterId,
  onChange,
  options: rawOptions,
  parseFilterValue,
  records,
  value: rawValue,
  ...rest
}) => {
  const selection = parseFilterValue ? parseFilterValue(rawValue) : rawValue;
  const options = (typeof rawOptions === 'function') ? rawOptions({ records }) : rawOptions;

  const handleChange = (name, value) => {
    onChange(fieldId, createFilterValue(filterId, fieldId, value));
  };

  const addRequiredKeys = (filterOptions) => filterOptions.map((option) => {
    const optionToUse = typeof option === 'object' ? option : (options.find((o) => o.id === option || o.value === option) || option);
    const requiredKeys = {
      id: optionToUse.id
        || optionToUse.value
        || optionToUse.name
        || (optionToUse.filterData?.filter)
        || option,
      name: `${optionToUse.name || (optionToUse.filterData?.filter) || option}`
    };

    return { ...optionToUse, ...requiredKeys };
  });

  return (
    <Dropdown
      filterEnabled={false}
      multiselect
      onChange={handleChange}
      options={addRequiredKeys(options)}
      showSelected={1}
      showTooltip
      disableInputAPI
      value={addRequiredKeys(selection)}
      {...MultiselectFilterProps}
      {...rest}
    />
  );
};

export default MultiSelectFilter;

MultiSelectFilter.propTypes = {

  /** additional props for the dropdown */
  MultiselectFilterProps: PropTypes.shape({}),

  /** Function to convert data from the input into something usable by callback */
  createFilterValue: PropTypes.func.isRequired,

  /** Field id to use for generating filter metadata */
  fieldId: PropTypes.string.isRequired,

  /** Filter id to use for generating filter metadata */
  filterId: PropTypes.string,

  onChange: PropTypes.func.isRequired,

  options: PropTypes.arrayOf(PropTypes.shape({
    filters: PropTypes.arrayOf(PropTypes.shape({}))
  })).isRequired,

  /** Function to convert stored data into a form usable by the component */
  parseFilterValue: PropTypes.func,

  records: PropTypes.arrayOf(PropTypes.shape({})),

  value: PropTypes.arrayOf(PropTypes.shape({}))
};

MultiSelectFilter.defaultProps = {
  MultiselectFilterProps: undefined,
  filterId: 'undefined',
  parseFilterValue: undefined,
  records: [],
  value: []
};
