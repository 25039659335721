import Layout from '@glu/core/src/layout';
import alert from '@glu/alerts';
import http from '@glu/core/src/http';
import hash from 'common/util/promise-hash';
import locale from '@glu/locale';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import userInfo from 'etc/userInfo';
import moment from 'moment';
import DataAPI from 'common/dynamicPages/api/data';
import patterns from 'system/validatorPatterns';
import amountFormatHelper from 'common/dynamicPages/views/mdf/componentHelpers/amountFormat';
import comboboxHelper from 'common/dynamicPages/views/mdf/componentHelpers/combobox';
import viewHelper from 'common/dynamicPages/api/viewHelper';
import typeaheadHelper from 'common/dynamicPages/views/mdf/componentHelpers/typeahead';
import singleLookupHelper from 'common/dynamicPages/views/mdf/componentHelpers/singleLookup';
import gridApi from 'common/dynamicPages/api/grid';
import util from '@glu/core/src/util';
import dialog from '@glu/dialog';
import { log } from '@glu/core';
import domUtil from 'common/util/domUtil';
import RemittanceDialog from 'app/payments/views/realTimePayments/rtpComponents/rtpRemittanceDialog';
import transferService from 'app/payments/services/quickTransferWidget';
import WarningDialog from 'common/dynamicPages/views/warningDialog';
import DuplicateDialog from 'common/dynamicPages/views/duplicateDialog';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';
import transform from 'common/util/transform';
import rtpSimpleModel from 'app/payments/models/realTimePayments/rtpSimpleEntryModel';
import loadingWidgetTmpl from 'common/templates/loadingWidget.hbs';
import rtpWidgetViewTmpl from './rtpWidgetView.hbs';

const MAX_BENE_ADDRESS = 70;
const DEBIT_ACCOUNT_NUMBER = 'DEBIT_ACCOUNT_NUMBER';
const ACCOUNTFILTER = 'ACCOUNTFILTER';
const DEBIT_BANK_CODE = 'DEBIT_BANK_CODE';
const DEBIT_CURRENCY = 'DEBIT_CURRENCY';
const VALUE_DATE = 'VALUE_DATE';
const BENE_NAME = 'BENE_NAME';

const rtpWidgetView = Layout.extend({
    template: rtpWidgetViewTmpl,
    loadingTemplate: loadingWidgetTmpl,

    ui: {
        $submitButton: '[data-hook="getSubmitButton"]',
        $addRemittance: '[data-hook="getAddRemittanceBtn"]',
        $remittanceEntered: '[data-hook="getRemittanceCheckMark"]',
    },

    events: {
        'click @ui.$submitButton': 'submit',
        'click @ui.$addRemittance': 'addRemittance',
    },

    behaviors() {
        return {
            LookupHelperText: {
                getTemplateInput: this.getTemplateInput.bind(this),
            },
        };
    },

    initialize() {
        this.context = {
            serviceName: '/payment/crtran',
            typeInfo: {
                productCode: 'RTP',
                functionCode: 'INST',
                typeCode: 'CRTRAN',
            },
        };

        this.setNotificationData({
            title: 'rtpWidget',
        });

        this.initializeOptions = {
            gridApi,
        };
        this.lookupHelperText = {};
        const displayBalance = serverConfigParams.get('DisplayAccountBalanceOnPaymentsScreens') || '';
        // converting the displayBalance value to boolean from string
        this.displayAvailableBalances = displayBalance === 'true';
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.model.on('change:ACCOUNTFILTER', (model, value) => {
                this.getAccountBalance(value);
            });
            this.setupFields();
            this.listenTo(this.model, 'modelAction:saveWithWarning', this.saveWithWarning.bind(this, false));
            this.listenTo(this, 'rtp:remittance:entered', () => {
                this.ui.$remittanceEntered.toggleClass('hide', util.isEmpty(this.model.get('REMITTANCE_INFO')));
            });
        } else {
            this.loadRequiredData();
        }
    },

    /**
     * sets up the fields for formatting and combos leveraging the MDF functions
     */
    setupFields() {
        amountFormatHelper.setupAmountFormats(this);
        comboboxHelper.setupComboboxes(this, '#ACCOUNTFILTER');
        /*
         * setup for the typeahead beneficiary field
         * this is needed b/c on the regular RTP detail page
         * the beneficiary field is a text field not a typeahead
         */
        this.model.fieldData.BENE_NAME.fieldUIType = 'TYPEAHEAD';
        this.model.fieldData.BENE_NAME.typeAhead = [{ field: BENE_NAME, type: 'STRING' }];
        this.addLookupToName = true;
        typeaheadHelper.setupTypeaheadBoxes(this);
        singleLookupHelper.setupSingleLookups(this);
    },

    loadRequiredData() {
        const options = {
            context: this.context,
            state: 'insert',
        };

        rtpSimpleModel.getEntitlements({
            options,
        }).then((response) => {
            if (response.model.isEntitled('insert')) {
                DataAPI.model.generate(options, false).then((results) => {
                    this.model = results;
                    this.initializeModel();
                    return results;
                }).then(() => {
                    const promiseHash = viewHelper.getComboPromises(options, this.model, ['ACCOUNTFILTER']);
                    promiseHash.valueDatePromise = this.getValueDatePromise();
                    hash(promiseHash).then((results) => {
                        this.comboCollections = this.createComboCollections(results);
                        this.setupValueDate(results);
                        this.setHasLoadedRequiredData(true);
                        this.render();
                    });
                });
            } else {
                this.displayNoRTPEntitlements();
            }
        })
            .catch((err) => {
                log.error(err);
            });
    },

    /**
     * issue a request to the server to validate the value date
     * @returns {promise}
     */
    getValueDatePromise() {
        const validationService = services.generateUrl(constants.URL_DO_FIELD_VALIDATION);

        this.model.set({
            VALUE_DATE: moment(new Date()).format(userInfo.getDateFormat()),
        });

        const inputData = [{
            name: 'REQUESTTYPE',
            value: 'fieldValidation',
        }, {
            name: 'FIELDNAME',
            value: VALUE_DATE,
        }, {
            name: 'VALUE_DATE',
            value: this.model.get('VALUE_DATE'),
        }, {
            name: 'ACTION',
            value: 'INSERT',
        }, {
            name: 'PRODUCT',
            value: 'RTP',
        }, {
            name: 'FUNCTION',
            value: 'INST',
        }, {
            name: 'TYPE',
            value: 'CRTRAN',
        }, {
            name: 'DEBIT_CURRENCY',
            value: this.model.get('DEBIT_CURRENCY'),
        },
        ];
        return http.post(validationService, {
            item: inputData,
        });
    },

    /**
     * with the resolved promise, set up the value date
     * @param results
     */
    setupValueDate(results) {
        const valueDateItem = results.valueDatePromise.item.find(res => res.name === 'VALUE_DATE');
        const newValueDate = moment(valueDateItem.value).format(userInfo.getDateFormat());
        this.model.set({
            VALUE_DATE: newValueDate,
        });
    },

    /**
     * initialize the model setting the VALUE_DATE and DEBIT_CURRENCY
     * also set up validators
     */
    initializeModel() {
        this.model.set({
            DEBIT_CURRENCY: 'USD',
        });
        this.setupValidators();
    },

    /**
     * setup model validators for account, amount and beneficiary fields
     */
    setupValidators() {
        this.model.validators = {
            ACCOUNTFILTER: {
                description: locale.get('RTP.INST.CRTRAN.*.DEBIT_ACCOUNT_NUMBER.label'),
                exists: true,
            },
            CREDIT_AMOUNT: {
                description: locale.get('RTP.INST.CRTRAN.*.CREDIT_AMOUNT.label'),
                exists: true,
                matches: patterns.PAMOUNT_PATTERN,
            },
            BENE_NAME: {
                description: locale.get('RTP.INST.CRTRAN.*.BENE_NAME.label'),
                exists: true,
            },
        };
    },

    /**
     * display no entitlement message
     */
    displayNoRTPEntitlements() {
        this.$el.text(locale.get('payment.rtp.permission.error'));
    },

    /**
     * Generate combobox collections from view setup data.
     * @param {Object} results
     * @returns {Object} Hash of combobox data.
     */
    createComboCollections(results) {
        return util.reduce(this.model.comboList, (acc, combo, index) => {
            const result = results[`combo-${index}`];
            const name = combo.inquiryId ? combo.name : combo;

            if (!result || result.queryResponse.QueryData.queryRows === null) {
                acc[name] = [];
            } else {
                acc[name] = (this.comboBoxData && this.comboBoxData[name])
                    ? this.comboBoxData[name] : result.queryResponse.QueryData.queryRows;
            }
            return acc;
        }, {});
    },

    /**
     * @name convertModelAttributesToJSON
     * @description transform the model attributes to an array of name values pairs
     * @param model
     * @returns {{item: array of NameValuePairs}}
     */
    convertModelAttributesToServerJSON(model) {
        const modelAttributes = model.toJSON();
        modelAttributes.PRODUCT = 'RTP';
        modelAttributes.FUNCTION = 'INST';
        modelAttributes.TYPE = 'CRTRAN';

        let item = transform.hashToPairs(modelAttributes, 'name', 'value', true);
        item = item.filter(i => !util.isEmpty(i.value));
        return {
            item,
        };
    },

    /**
     * @returns {object} request object for the save
     */
    collectData() {
        const saveWithWarning = this.model.get('_saveWithWarning') || false;
        return {
            items: [this.convertModelAttributesToServerJSON(this.model)],
            saveAsTemplate: '',
            restrict: false,
            saveWithWarning,
        };
    },

    /**
     * submits the real-time payment added from the simple entry
     */
    submit() {
        if (this.model.isValid()) {
            this.disableButtons(true);

            // combine address 1 and address 2 into bene_address
            this.combineAddress();
            const data = this.collectData();
            http.post(
                services.generateUrl(`${constants.URL_RTP_MULTISAVE}/add`),
                data,
                (response) => {
                    this.submitSuccess(response);
                },
                (response) => {
                    this.submitError(response);
                },
            );
        }
    },

    /**
     * Callback method handling ok on warning
     */
    saveWithWarning() {
        this.model.set({
            _saveWithWarning: true,
        });
        this.submit();
    },

    /**
     * callback for handling ok on duplicate warning
     */
    saveWithWarningForDuplicates() {
        this.model.set({
            duplicateAccepted: true,
        });
        this.model.validators.DUPLICATEREASON = null;
        this.submit();
    },

    /**
     * error handler for submit
     * @param {object} response
     */
    submitError(response) {
        this.disableButtons(false);
        if (response.responseJSON.errorCode === constants.DUPLICATE_ERROR_CODE) {
            this.displayDuplicateWarning(response);
        } else if (response.responseJSON.resultType === 'WARNING') {
            this.displayWarning(response);
        } else {
            this.displayError(response);
        }
    },

    /**
     * displays the duplicate transaction dialog
     * @param {object} response
     */
    displayDuplicateWarning(response) {
        this.model.set('POSSIBLEDUPLICATE', true);
        const duplicateDialog = new DuplicateDialog({
            resp: response.responseJSON,
            methodName: 'INSERT',
            isSimpleRTP: true,
            modelsArray: [this.model],
        });
        duplicateDialog.once('saveMultiAddDuplicates', this.saveWithWarningForDuplicates, this);
        dialog.custom(duplicateDialog);
    },

    /**
     * displays the warning dialog
     * @param {object} response
     */
    displayWarning(response) {
        const warningDialog = new WarningDialog({
            model: this.model,
            methodName: 'SAVE',
            confirms: response.responseJSON.confirms,
        });
        dialog.custom(warningDialog);
    },

    /**
     * displays error in the alert region
     * @param response
     */
    displayError(response) {
        const errors = response.responseJSON.confirms
        && response.responseJSON.confirms.confirmResults.length > 0
            ? response.responseJSON.confirms.confirmResults[0].messages
            : response.responseJSON.message;
        this.alertRegion.show(alert.danger(
            errors.join(' '),
            {
                canDismiss: true,
                title: response.responseJSON.message.join(' '),
            },
        ));
    },

    /**
     * handler for the successful save
     * clears the model, enables the buttons, clears the alert region and the helper text
     * triggers an event to display the success message.
     */
    submitSuccess(response) {
        const TRANSACTION_ID = response.confirms
            .confirmResults[0].confirmData[0].item[0].value;
        this.model.set({
            TRANSACTION_ID,
        });

        this.alertRegion.close();
        this.disableButtons(false);
        this.trigger('lookupHelperText:clear', ACCOUNTFILTER);
        this.ui.$remittanceEntered.addClass('hide');
        this.appBus.trigger('rtp:quickPaymentSuccess', this.model, response);
        this.setHasLoadedRequiredData(false);
        this.render();
    },

    /**
     * combine BENE_ADDRESS_1 and BENE_ADDRESS_2 to BENE_ADDRESS
     */
    combineAddress() {
        const beneAddress1 = this.model.get('BENE_ADDRESS_1') || '';
        const beneAddress2 = this.model.get('BENE_ADDRESS_2') || '';
        let combinedAddress = beneAddress1;
        if (combinedAddress.length + beneAddress2.length < MAX_BENE_ADDRESS) {
            combinedAddress += ' ';
        }
        combinedAddress += beneAddress2;
        this.model.set('BENE_ADDRESS', combinedAddress);
    },

    /**
     * disable or enable the submit button
     * @param {boolean} status if true, disable button, otherwise enable button
     */
    disableButtons(status) {
        domUtil.setDisabled(this.ui.$submitButton, status);
    },

    /**
     * addRemittance link handler
     * displays the add remittance popup for the user to enter the optional 140 characters
     * (or less) remittance
     */
    addRemittance() {
        // get placement
        const rect = this.$el[0].getBoundingClientRect();
        const remittanceDlg = new RemittanceDialog({
            model: this.model,
            top: rect.top,
            left: rect.left,
            parentView: this,
        });
        this.remittanceRegion.show(remittanceDlg);
        this.ui.$addRemittance.blur();
    },

    /**
     * method to retrieve the account balance from the server for the selected
     * (debit) account number
     * @param accountFilter
     */
    getAccountBalance(accountFilter) {
        let balanceRequest;

        if (!accountFilter) {
            return;
        }

        const options = {
            bankCodeKey: DEBIT_BANK_CODE,
            currencyKey: DEBIT_CURRENCY,
            accountNumberKey: DEBIT_ACCOUNT_NUMBER,
        };

        const currency = this.model.get(options.currencyKey);
        const comboAcctFltr = this.comboCollections.ACCOUNTFILTER;
        let acct;
        let bnkCode;
        let accountNumber;
        if (comboAcctFltr) {
            acct = comboAcctFltr.find(account => accountFilter === account.name);
            if (acct) {
                const acctMap = acct.mapDataList;
                bnkCode = acctMap.filter(data => data.toField === options.bankCodeKey)[0].value;
                accountNumber = acctMap
                    .filter(data => data.toField === options.accountNumberKey)[0].value;
            }
        }

        // fetch the balance of the selected account if it is not already available
        if (this.displayAvailableBalances) {
            balanceRequest = {
                productCode: 'RTP',
                functionCode: 'INST',
                typeCode: 'CRTRAN',
                bankCode: this.model.get(options.bankCodeKey) || bnkCode,
                accountNumber,
                currency,
            };
            this.currentAccountFilter = accountFilter;

            transferService.getBalanceForAccount(balanceRequest).then((balanceData) => {
                this.setupLookupHelperText(accountFilter, balanceData, currency);
            }, (balanceData) => {
                this.setupLookupHelperText(accountFilter, balanceData, currency);
            });
        }
    },

    /**
     * extracts the relevant data from the response to getAvailableBalance for
     * display in helper text for the debit account number
     * @param {string} accountNumber - selected account number
     * @param {object} balanceData - data returned from the getAvailableBalance service
     * @param {string} currency
     */
    setupLookupHelperText(accountNumber, balanceData, currency) {
        const status = balanceData.status === 'success';
        const balance = status ? balanceData.balance : balanceData.status;
        this.lookupHelperText[accountNumber] = {
            balance,
            hasBalance: status,
            currency,
            account: accountNumber,
        };
        this.trigger('lookupHelperText:update', ACCOUNTFILTER);
    },

    /**
     * override for lookupHelperText
     */
    getTemplateInput() {
        return this.lookupHelperText[this.currentAccountFilter];
    },

    templateHelpers() {
        const vDate = this.model ? this.model.get(VALUE_DATE) : new Date();
        let currency = 'USD';
        if (this.model && !util.isEmpty(this.model.get(DEBIT_CURRENCY))) {
            currency = this.model.get(DEBIT_CURRENCY);
        }
        const options = this.comboCollections ? this.comboCollections.ACCOUNTFILTER : [];
        return {
            valueDate: moment(vDate, userInfo.getDateFormat()).format(userInfo.getDateFormat()),
            creditCurrency: currency,
            debitAccountOptions: options,
        };
    },
});

export default rtpWidgetView;
