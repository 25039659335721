import GluModel from '@glu/core/src/model';
import Formatter from 'system/utilities/format';

let Model = GluModel;

export default function () {
    Model = Model.extend({
        defaults: {
            menuCategory: '',
            menuCode: '',
            menuDescription: '',
            optionCost: '',
            menuOptionDesc: '',
        },

        validators: {

        },

        displayAmount(optionCost) {
            return Formatter.formatCurrency(optionCost);
        },
    });
    return new Model();
}
