import ItemView from '@glu/core/src/itemView';
import moment from 'moment';
import userInfo from 'etc/userInfo';
import challengeAuditViewTmpl from './challengeAuditView.hbs';

const ChallengeAuditView = ItemView.extend({
    template: challengeAuditViewTmpl,

    /**
     * Returns a date string formatted to the user's timezone
     * @param date
     */
    toTimeZone(date) {
        const zone = userInfo.get('timezone');
        const formatString = `${userInfo.getDateFormat()} ${userInfo.getTimeFormat()}`;
        return moment(date).tz(zone).format(formatString);
    },

    templateHelpers() {
        return {
            enteredByTime: this.toTimeZone(this.model.get('enteredByTime')),
            enteredByUserGroup: this.model.get('enteredByUserGroup'),
            enteredBy: this.model.get('enteredBy'),
            enteredByName: this.model.get('enteredByName'),
            modifiedByTime: this.toTimeZone(this.model.get('modifiedByTime')),
            modifiedByUserGroup: this.model.get('modifiedByUserGroup'),
            modifiedBy: this.model.get('modifiedBy'),
            modifiedByName: this.model.get('modifiedByName'),
        };
    },
});

export default ChallengeAuditView;
