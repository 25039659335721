import React, {
  Fragment, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import { CaretDownNextIcon } from '@glu/icons-react';
import classNames from 'classnames';

import Popover from '@glu/popover-react';
import SaveOrUpdateView from './SaveOrUpdateView';
import ViewRow from './ViewRow';
import { groupByKey, insertIntoHeaders } from './utils';
import { styles } from './SavedViews.styles';

export function SavedViewsDisplayComponent(props) {
  const {
    PopoverProps,
    classes,
    diverged,
    headers,
    save,
    select,
    selected,
    shouldAllowCreation,
    suggestedName,
    update,
    views
  } = props;
  const activeView = useMemo(
    () => views.find((view) => view.viewData.id === selected), [selected, views]
  );
  const {
    arrow,
    divergentHide,
    divergentIndicator,
    divergentPlaceholder,
    dropdownHeader,
    dropdownHeaderEmpty,
    dropdownList,
    dropdownListHeader,
    dropdownTitle,
    dropdownWrapper,
    parentPopover,
    popover
  } = classes;

  const [viewsVisibility, setViewsVisibility] = useState({});

  const sortedViews = useMemo(() => (
    views.sort((a, b) => {
      const stringA = a.viewData.name.toLowerCase();
      const stringB = b.viewData.name.toLowerCase();

      return stringA > stringB ? 1 : (stringA === stringB ? 0 : -1);
    })
  ), [views]);
  sortedViews.sort((a, b) => (a.viewData.isDefault ? -1 : b.viewData.isDefault ? 1 : 0));
  const groupedViews = insertIntoHeaders(groupByKey(sortedViews, 'viewData', 'headerId'), headers);
  return (
    <>
      <div className={dropdownWrapper}>
        <div className={dropdownHeader}>
          <div className={classNames(divergentPlaceholder, {
            [divergentHide]: !diverged,
            [divergentIndicator]: diverged
          })}
          />
          {activeView && (
          <Popover
            className={classNames(popover, parentPopover)}
            placement="bottom-start"
            trigger="click"
            noArrow
            enablePopoverClick
            disableHideOnOutsideClick={Object.keys(viewsVisibility)
              .some((view) => viewsVisibility[view])}
            content={/* istanbul ignore next */ ({ closePopover, visible }) => (
              <ul className={dropdownList}>
                {groupedViews.map((group) => (
                  <Fragment key={group.name}>
                    {group.name && <li className={dropdownListHeader}>{group.name}</li>}
                    {group.entries.map((view) => (
                      view.viewData ? (
                        <ViewRow
                          key={`${view.viewData.id}-${view.viewData.name}`}
                          classes={classes}
                          {...props}
                          view={view}
                          PopoverProps={PopoverProps}
                          select={select(view)}
                          viewsVisibility={viewsVisibility}
                          setViewsVisibility={setViewsVisibility}
                          popoverVisible={visible}
                          handleCloseMainPopover={closePopover}
                          suggestedName={suggestedName}
                        />

                      ) : (
                        <span key={view.empty} className={dropdownHeaderEmpty}>
                          {view.empty}
                        </span>
                      )
                    ))}
                  </Fragment>
                ))}
              </ul>
            )}
            {...PopoverProps}
          >
            <button
              data-qa="title"
              className={dropdownTitle}
              type="button"
            >
              {activeView.viewData.name}
              <CaretDownNextIcon className={arrow} />
            </button>
          </Popover>
          )}
          {diverged && (
          <SaveOrUpdateView
            {...props}
            isStatic={activeView && activeView.viewData.isStatic}
            hasActive={!!activeView}
            classes={classes}
            selected={selected}
            save={save}
            update={update}
            shouldAllowCreation={shouldAllowCreation}
            suggestedName={suggestedName}
          />
          )}
        </div>
      </div>
    </>
  );
}

SavedViewsDisplayComponent.propTypes = {
  /** Options for @popperjs/core see docs for details */
  PopoverProps: PropTypes.shape({}),

  classes: PropTypes.objectOf(PropTypes.string).isRequired,

  diverged: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.objectOf({
    id: PropTypes.string,
    name: PropTypes.string
  })),
  save: PropTypes.func.isRequired,
  select: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  shouldAllowCreation: PropTypes.bool.isRequired,
  suggestedName: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired,
  views: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string
    })
  )
};

SavedViewsDisplayComponent.defaultProps = {
  PopoverProps: undefined,
  diverged: false,
  headers: [],
  selected: null,
  views: []
};

export default withStyles(styles)(SavedViewsDisplayComponent);
