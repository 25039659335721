import util from '@glu/core/src/util';
import balanceAndTransaction from 'mobile/common/interfaces/balanceAndTransaction';
import checkInquiry from 'mobile/common/interfaces/checkInquiry';
import stopPayments from 'mobile/common/interfaces/stopPayments';
import quickTransfer from 'mobile/common/interfaces/quickTransfer';

export default {
    /**
     * Get all accounts that native expects for voice interactions
     * @returns {Promise}
     */
    getAllAccounts() {
        return Promise.all([
            balanceAndTransaction.getAccounts(),
            checkInquiry.getAccounts(),
            stopPayments.getAccounts(),
            quickTransfer.getAccounts(),
        ])
            .then(this.mergeAccounts.bind(this))
            .then(accounts => ({
                accounts,
                entitlements: {
                    balanceAndTransaction: balanceAndTransaction.entitlementKey,
                    checkInquiry: checkInquiry.entitlementKey,
                    stopPayments: stopPayments.entitlementKey,
                    quickTransfer: quickTransfer.entitlementKey,
                },
            }))
            .then(accountEntitlements => JSON.stringify(accountEntitlements))
            .catch(error => Promise.reject(JSON.stringify(error)));
    },

    /**
     *
     * @param {Array} responseArray - array of all account responses
     * @returns {array}
     */
    mergeAccounts(responseArray) {
        return responseArray.reduce((accum, response) => {
            const { entitlement } = response;
            return [
                ...accum,
                ...this.remapAccounts(accum, response.accounts, entitlement),
            ];
        }, []);
    },

    /**
     * Check each account in accounts param to see if it is already in
     * the allAccounts param array. When it is not, add it to the allAccounts array
     * with entitlements. When it is in the allAccounts array, update any attributes
     * that might be undefined
     * @param {Array}allAccounts
     * @param {Array} accounts
     * @param {string} entitlement
     */
    remapAccounts(allAccounts, accounts, entitlement) {
        return accounts.reduce((accum, account) => {
            // Find account by number and bankCode
            let existingAccount = util.find(
                allAccounts,
                this.matchesAccount.bind(this, account),
            );
            // When no account was found add it, with entitlements
            if (existingAccount === undefined) {
                return [
                    ...accum,
                    util.extend({}, account, {
                        entitlements: [entitlement],
                    }),
                ];
            }

            // Update any attributes that might be undefined
            existingAccount = this.updateUndefinedAttributes(existingAccount, account);

            // Merge entitlements from existing
            existingAccount.entitlements = [...existingAccount.entitlements, entitlement];

            return accum;
        }, []);
    },

    /**
     * Evauate the array of attributes and if the existingAccount attribute is
     * undefined, set it from the account attribute
     * @param {Object} existingAccount
     * @param {Object} account
     */
    updateUndefinedAttributes(existingAccount, account) {
        const newAccount = existingAccount;
        const optionalAttributes = [
            'accountFilter',
            'abaNumber',
            'accountType',
            'currencyCode',
            'toAccount',
            'fromAccount',
        ];
        // Update attributes if they are undefined
        optionalAttributes.forEach((attr) => {
            if (newAccount[attr] === undefined) {
                newAccount[attr] = account[attr];
            }
        });
        return newAccount;
    },

    /**
     * Check accountNumber and bankCode or accountFilter to see if
     * the accounts match
     * @param {Object} firstAccount
     * @param {Object} secondAccount
     */
    matchesAccount(firstAccount, secondAccount) {
        return (secondAccount.accountNumber === firstAccount.accountNumber
            && secondAccount.bankCode === firstAccount.bankCode);
    },
};
