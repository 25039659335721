import Model from '@glu/core/src/model';

const Account = Model.extend({
    defaults() {
        return {
            sectionId: 'DEPOSITACCTS',
            groupCount: 0,
            searchFilter: '',
            AccountNum: '',
            AccountNum_DISP: '',
            ClientAccountName: '',
        };
    },

    idAttribute: 'AccountFilter',
    accountFilter() {
        return this.get('AccountFilter');
    },
    accountNumber() {
        return this.get('AccountNum');
    },
    accountNumberDisplay() {
        return this.get('AccountNum_DISP');
    },
    name() {
        return this.get('ClientAccountName');
    },
    bankCode() {
        return (this.accountFilter() || '').split('-')[0];
    },
    sectionId() {
        return this.get('sectionId');
    },
    incrementGroupCount() {
        this.set('groupCount', this.groupCount() + 1);
    },
    decrementGroupCount() {
        this.set('groupCount', this.groupCount() - 1);
    },
    groupCount() {
        return this.get('groupCount');
    },
    applySearchFilter(filter) {
        this.set('searchFilter', filter.toLowerCase());
    },
    matchesFilter() {
        const filter = this.get('searchFilter');
        return this.accountNumber().toLowerCase().indexOf(filter) !== -1
                || this.accountNumberDisplay().toLowerCase().indexOf(filter) !== -1
                || this.name().toLowerCase().indexOf(filter) !== -1;
    },

    url: 'n/a',
});

export default Account;
