import EntryView from 'common/dynamicPages/views/workflow/entry';
import store from 'system/utilities/cache';
import errorHandlers from 'system/error/handlers';
import util from '@glu/core/src/util';
import MetaModel from 'common/dynamicPages/models/meta';
import DataApi from 'common/dynamicPages/api/data';

export default EntryView.extend({
    initialize() {
        const options = {
            menuCategory: 'ADMINISTRATION',
            serviceName: 'tableMaintenance/emailRecipients',
            serviceFunc: null,
            businessType: null,
            context: 'EMAIL_RECP_MAINT',
            mode: 'modify',
        };

        EntryView.prototype.initialize.call(this, options);
    },

    loadRequiredData() {
        const self = this;

        this.storedPreObj = store.remove(
            'recipientDetails',
            {
                context: {
                    productCode: 'ADMINISTRATION',
                    functionCode: 'EMAIL_RECP_MAINT',
                },
            },
        );

        const metaModel = new MetaModel(
            {},
            {
                context: {
                    serviceName: 'tableMaintenance/emailRecipients',

                    actionContext: {
                        actionMode: 'SELECT',
                        entryMethod: 0,
                        functionCode: 'MAINT',
                        productCode: '_ADMIN',
                        subType: '*',
                        typeCode: 'CEMAD_TM',
                    },
                },
            },
        );
        metaModel.key = {
            NAME: 'true',
            USERGROUP: 'true',
        };
        metaModel.set('id', this.storedPreObj.name + this.storedPreObj.userGroup);
        metaModel.set({
            NAME: this.storedPreObj.name,
            USERGROUP: this.storedPreObj.userGroup,
        });

        DataApi.model.generateModelFromModel(metaModel).then((newModel) => {
            newModel.fetch({
                success() {
                    self.storedModel = newModel;
                    self.setHasLoadedRequiredData(true);
                    self.render();
                },

                error: util.bind(errorHandlers.loading, self),
            });
        });
    },
});
