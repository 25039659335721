/**
 * Collection for from/to accounts for transfers
 */
import Collection from '@glu/core/src/collection';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';
import util from '@glu/core/src/util';
import transform from 'common/util/transform';
import http from '@glu/core/src/http';

const TRANSFER_PRODUCT = 'RTGS';
const TRANSFER_TYPE = 'TRANSFER';
const TRANSFER_FUNCTION = 'INST';
const TRANSFER_FUNCTION_TMPL = 'TMPL';

/**
 * @config {String} type
 * @config {Boolean} isToAccounts - default: false
 */
export default Collection.extend({
    filterVal: '',
    rowsPerPage: 10,
    startRow: 1,
    hasMorePages: false,

    initialize(opts) {
        if (opts) {
            this.isToAccounts = opts.isToAccounts;
            this.type = opts.type;
            this.isTOA = opts.isTOA;
            this.templateMode = opts.templateMode;
        }
    },

    setFilter(page, term, accountFilter) {
        let localTerm = term;
        localTerm = localTerm || '';
        if (localTerm !== this.filterVal || page === 1) {
            this.startRow = 1;
        }
        this.filterVal = localTerm;
        this.rowsPerPage = localTerm.length > 2 ? 50 : 10;
        if (accountFilter) {
            this.accountFilter = accountFilter;
        }
    },

    /**
     * @name setData
     * @description returns the data payload to retrieve the account collection
     * @param {object} localOptions
     */
    setData(localOptions) {
        const toAccountFieldName = this.isTOA ? 'BENE_ACCOUNTENTITLEMENT_TOA' : 'BENE_ACCOUNTENTITLEMENT';
        const functionCode = this.templateMode ? TRANSFER_FUNCTION_TMPL : TRANSFER_FUNCTION;
        const data = {
            rowsPerPage: (localOptions.data && localOptions.data.rowsPerPage)
                ? localOptions.data.rowsPerPage : this.rowsPerPage,
            startRow: (localOptions.data && localOptions.data.startRow)
                ? localOptions.data.startRow : this.startRow,
            fieldName: this.isToAccounts ? toAccountFieldName : 'ACCOUNTFILTER',
            typeCode: TRANSFER_TYPE,
            productCode: TRANSFER_PRODUCT,
            functionCode,
            depends: (localOptions.data && localOptions.data.depends)
                ? localOptions.data.depends : [],
            subType: this.type,
        };
        return data;
    },

    sync(method, model, options) {
        let localOptions = options;
        localOptions = localOptions || {};

        const data = this.setData(localOptions);

        if (this.filterVal) {
            data.searchFields = [{
                fieldName: 'ACCOUNTFILTER',
                operator: 'CONTAINS',
                fieldValue: [this.filterVal],
                dataType: 'text',
            }, {
                fieldName: 'CLIENTACCOUNTNAME',
                operator: 'CONTAINS',
                fieldValue: [this.filterVal],
                dataType: 'text',
            }, {
                fieldName: 'CURRENCYCODE',
                operator: 'CONTAINS',
                fieldValue: [this.filterVal],
                dataType: 'text',
            }];
            data.searchOrOperator = true;
        }

        if (this.accountFilter) {
            data.depends.push({
                name: this.isToAccounts ? 'ACCOUNTFILTER' : 'BENE_ACCOUNTENTITLEMENT',
                value: this.accountFilter,
            });
        }

        http.post(
            services.generateUrl(constants.URL_GETLOOKUPRESULTS_ACTION),
            data,
            (result) => {
                /*
                 * NH-105108- because the start is 1 subtracting 1 to get the correct count. if
                 *   the total rows is 1 more than the rowsPerPage, the second page is not fetched.
                 */
                this.hasMorePages = ((this.startRow + result.rows.length) - 1)
                    <= result.totalRows;
                this.startRow += this.rowsPerPage;
                localOptions.success(result.rows);
            }, () => {
                localOptions.error();
            },
        );
    },

    parse(response) {
        return util.map(response, (rowItem) => {
            const obj = transform.pairsToHashUnescape(rowItem.columns, 'fieldName', 'fieldValue');
            obj.mapDataList = transform.pairsToHashUnescape(rowItem.mapDataList, 'toField', 'value');
            obj.id = obj.ACCOUNTFILTER;

            obj.text = (!util.isEmpty(obj.ACCOUNTNAME_NUM_BANK))
                ? obj.ACCOUNTNAME_NUM_BANK
                : `${obj.CLIENTACCOUNTNAME} - ${obj.ACCOUNTNUM} - ${obj.BANKCODE} - ${obj.NAME}`;

            return obj;
        });
    },
});
