import $ from 'jquery';
import util from '@glu/core/src/util';
import Dialog from '@glu/dialog';
import { appBus } from '@glu/core';
import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import sbConstants from 'app/smb/constants';
import InquiryAPI from 'system/webseries/api/inquiry';
import API from 'app/smb/api';
import nhUtil from 'system/utilities/accessors';
import FromAccountList from 'app/smbPayments/collections/fromAccountList';
import errHandler from 'system/errHandler';
import Formatter from 'system/utilities/format';
import PaymentModel from 'app/smbPayments/models/payment';
import Collection from '@glu/core/src/collection';
import CollectionComboBoxView from 'common/collectionComboBox/views/base';
import 'jui/droppable';
import DroppedCollectionView from './droppedTileCollectionView';
import droppableViewTmpl from './droppableView.hbs';

export default Layout.extend({
    initialize(options) {
        this.options = options;
        this.droppedCollection = new Collection();
        this.fromAccountList = this.loadPromisedAccounts();
        this.initializeDroppableTarget();
        this.loadRequiredVendorData();
    },

    template: droppableViewTmpl,

    ui: {
        droppableList: '.droppable-target-list',
        currentAmountWarning: '.icon-warning',
        emptyQueueMsg: '.empty-queue-msg',
        schedulePaymentsBtn: '.schedule-payments',
        okBtn: '.ok-btn',
        dropZoneTotal: '.amount-value-text',
    },

    regions: {
        droppableTargetListRegion: '.droppable-target-list',
        fromAccountRegion: '[data-region="fromAccountRegion"]',
    },

    events: {
        'click @ui.schedulePaymentsBtn': 'schedulePayments',
        'click @ui.okBtn': 'schedulePaymentsFast',
    },

    appEvents: {
        'update:billsAmount': 'updateBillsAmount',
        'update:billsStatus': 'updateBillsStatus',
    },

    templateHelpers() {
        return {
            formattedBillsAmount: this.getFormattedAmount(this.dzTotal),
        };
    },

    initializeDroppableTarget() {
        const self = this;
        this.initializeDroppedCollectionView();

        // no more items so close our drop zone
        if ($(this.el).length > 0 || this.droppedCollection.length > 0) {
            $('.billsGridWrapper').show();
        } else {
            $('.billsGridWrapper').hide();
        }

        $(this.el).droppable({
            // accept: '#trash li',
            activeClass: 'custom-state-active',

            drop(ev, ui) {
                // get reference to dropped view's model
                const draggableModel = $(ui.draggable).data('backbone-view').model;
                self.selected = self.accountListComboBoxView.selected;
                /*
                 * add model to dropped view if we aren't
                 * just moving around a model in the dropzone
                 */
                if (self.droppedCollectionView.collection !== draggableModel.collection) {
                    if (self.selected) {
                        // remove the model from the draggable collection
                        const totalBillsCollection = draggableModel.collection;
                        totalBillsCollection.remove(draggableModel);

                        /*
                         * set up so we have the correct reference to the collection which
                         * contains the model
                         */
                        draggableModel.collection = self.droppedCollectionView.collection;

                        // add model to dropped view
                        self.droppedCollectionView.collection.add(draggableModel);

                        /*
                         * We should not be adding attributes to a model, BUT there is NO
                         * way to correlate
                         * bill/vendor models and statuses to the selected account or bill
                         * Fixing this requires DDL changes to BILL, Payment
                         * and/or Accounts* tables
                         */
                        draggableModel.set('status', 'droppedUnscheduled');

                        // remove this item back from the tiles view collection
                        self.trigger('itemInDropzone', draggableModel);
                        $(ui.draggable).remove();

                        $(self.ui.emptyQueueMsg).addClass('hidden');

                        // trigger update total bills due
                        self.appBus.trigger('update:billsAmount', self.droppedCollectionView.total());
                    } else {
                        self.trigger('itemOut', draggableModel);
                        // remove item from droppable collection
                        self.droppedCollectionView.collection.remove(draggableModel);
                        // add model to dropped view
                        draggableModel.collection.add(draggableModel);
                    }
                }
            },

            out(event, ui) {
                const draggableModel = $(ui.draggable).data('backbone-view').model;

                /*
                 * update total amount indicator if we are moving
                 * a current tile out of the dropzone
                 */
                if (self.droppedCollectionView.collection === draggableModel.collection) {
                    // add this item back to the tiles view collection
                    self.trigger('itemOut', draggableModel);

                    // remove item from droppable collection
                    self.droppedCollectionView.collection.remove(draggableModel);

                    // add model to dropped view
                    draggableModel.collection.add(draggableModel);

                    // trigger update total bills due
                    self.appBus.trigger('update:billsAmount', self.droppedCollectionView.total());
                }

                if (self.droppedCollectionView.collection.length === 0) {
                    self.resetTotalAmountTemplate();
                }

                /*
                 * always set this so we can drag items that come out of the dropzone whether
                 * they are dropped or not.
                 */
                draggableModel.set('status', 'unscheduled');
            },
        });
    },

    convertCurrency(value) {
        return Number(value.replace(/[^0-9.]+/g, ''));
    },

    updateBillsAmount(total) {
        const formattedAmount = this.getFormattedAmount(total);
        this.dzTotal = formattedAmount;

        if (total === 0) {
            $(this.ui.dropZoneTotal).html(`<span class="hidden icon-warning"></span>${formattedAmount}`);
        } else {
            const accountBalance = this.selected.get('accountBalance');
            if (parseFloat(total) < parseFloat(accountBalance
                ? this.convertCurrency(accountBalance) : 0)) {
                $(this.ui.dropZoneTotal).html(`<span class="hidden icon-warning"></span>${formattedAmount}`);
            } else {
                $(this.ui.dropZoneTotal).html(`<span class="icon-warning"></span>${formattedAmount}`);
            }
        }
    },

    updateBillsStatus(model, paymentModel) {
        const self = this;

        const loadFinished = function () {
            self.setHasLoadedRequiredData(true);
            self.render();
        };

        if (model) {
            const billModel = paymentModel.convertInquiryModelAttributesToServerJSON(model.get('columns'));

            const bill = API.updateSmbBill.update(billModel);

            Promise.all([bill])
                .then((results) => {
                    [self.paymentCollection] = results;

                    loadFinished();
                })
                .then(null, errHandler);
        }
    },

    initializeDroppedCollectionView() {
        this.droppedCollectionView = new DroppedCollectionView({
            collection: this.droppedCollection,
        });

        this.listenTo(this.droppedCollectionView.collection, 'change', function () {
            this.dzTotal = this.droppedCollectionView.total();
            this.showHideSchedulePaymentsBtn();
        });

        this.listenTo(this.droppedCollectionView.collection, 'remove', function () {
            this.dzTotal = this.droppedCollectionView.total();
            this.showHideSchedulePaymentsBtn();
        });
    },

    getAccountPromise() {
        return new Promise((resolve, reject) => {
            new FromAccountList({
                inquiryId: InquiryAPI.DOMESTIC_WIRE_FROM_ACCOUNT_INQUIRY_ID,
                typeCode: 'FEDWIRE',
                productCode: 'RTGS',
                functionCode: 'INST',

                labelFormat(data) {
                    const accountName = util.findWhere(
                        data.mapDataList,
                        {
                            toField: 'DEBIT_ACCOUNT_TITLE',
                        },
                    ).value;
                    return `${accountName} - ${data.label}${data.accountBalance ? ` - ${data.accountBalance} Available` : ''}`;
                },
            }).fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    loadPromisedAccounts() {
        const self = this;

        const loadFinished = () => {
            self.setHasLoadedRequiredData(true);
            self.render();
        };

        const dates = API.dates.get('business');

        Promise.all([dates, this.getAccountPromise()])
            .then((results) => {
                [self.dates, self.fromAccountList] = results;
                self.blockedDates = self.dates.blockedDates;
                self.cutoffTimes = self.dates.cutoffTimes;
                self.maxDate = self.dates.maxDate;
                self.earliestDay = self.dates.earliestDay;

                loadFinished();
            })
            .then(null, errHandler);
    },

    resetTotalAmountTemplate() {
        this.selected = this.accountListComboBoxView.selected;
        this.accountListComboBoxView.clearSelection();
    },

    renderAccountList() {
        const self = this;
        if (this.fromAccountList) {
            this.accountListComboBoxView = new CollectionComboBoxView({
                collection: this.fromAccountList,
                displayStache: '{{{text}}}',
                selectedStache: '{{{text}}}',
                emptyText: locale.get('smbPayments.no.accounts'),
                selected: this.fromAccountList.models[0],
                canAdd: false,
                canEdit: false,
                canDelete: false,
            });

            this.accountListComboBoxView.on('select', (selected) => {
                if (selected) {
                    self.selected = selected;
                    // trigger update total bills due
                    self.appBus.trigger('update:billsAmount', self.droppedCollectionView.total());
                } else {
                    // remove item from droppable collection
                    self.droppedCollectionView.collection.reset();
                    self.resetTotalAmountTemplate();
                    self.showHideSchedulePaymentsBtn();
                    appBus.trigger('update:billsAmount', 0);
                }
            });

            this.fromAccountRegion.show(this.accountListComboBoxView);
        }
    },

    getFormattedAmount(value) {
        /*
         * use the localized formatter in the util object which allows
         * localization formatting by user and other preferences
         */
        return Formatter.formatCurrency(value);
    },

    schedulePayments() {
        const self = this;

        this.ui.schedulePaymentsBtn.addClass('hidden');

        $('.billsGridWrapper .droppable-target').addClass('send-items-state');

        this.droppedCollectionView.collection.each((model) => {
            const paymentModel = new PaymentModel();

            /*
             * self.vendors is set in the vendor promise
             * should be: SOURCEID is === BENE_ACCOUNTNUMBER
             */
            const vendor = self.findInCollection(self.vendors, model, 'BENE_NAME');
            // self.selected is selected account on drop zone dd
            if (vendor) {
                paymentModel.convertBillToPayment(model, vendor, self.selected);

                nhUtil.setFieldValue(model, 'STATUS_DESCRIPTION', 'Scheduled');
                nhUtil.setFieldValue(model, 'STATUS', 'SC');

                /*
                 * remove bill from table , remove ok button
                 *
                 * trigger update total bills due
                 */
                appBus.trigger('update:billsStatus', model, paymentModel);
                appBus.trigger('update:billsAmount', 0);
            } else {
                self.resetTotalAmountTemplate();
                appBus.trigger('update:billsAmount', 0);
                // add this item back to the tiles view collection
                self.trigger('itemOut', model);

                // remove item from droppable collection
                self.droppedCollectionView.collection.remove(model);

                // add model to dropped view
                model.collection.add(model);

                // trigger update total bills due
                self.appBus.trigger('update:billsAmount', self.droppedCollectionView.total());
                // No vendor/beneficiary exists, add grid alert here
                Dialog.alert(locale.get('smbPayments.no.vendor.error'));
            }
        });

        this.scheduledItemCount = self.$('.droppable-target-list ul.dropped-tiles li.ui-draggable').length;

        // remove html tiles in drop zone
        $('.dropped-tiles > li').remove();
        // remove all models from collection
        this.droppedCollectionView.collection.reset();
    },

    loadRequiredVendorData() {
        const self = this;

        const loadFinished = function () {
            self.setHasLoadedRequiredData(true);
            self.render();
        };

        const vendors = API.beneficiaryList.get(sbConstants.BUSINESS_OPTION_2);

        Promise.all([vendors])
            .then((results) => {
                [self.vendors] = results;

                loadFinished();
            })
            .then(null, errHandler);
    },

    findInCollection(vendorCollection, billModel, fieldName) {
        const foundItem = util.filter(vendorCollection.models, (model) => {
            const value = model.get(fieldName);
            const source = util.findWhere(
                billModel.get('columns'),
                {
                    fieldName: 'VENDORNAME',
                },
            ).fieldValue;

            return value === source;
        });

        // always send 1st in array
        return foundItem[0];
    },

    processBillPayment(model) {
        const self = this;
        const isValid = model.isValid();
        if (isValid) {
            model.save(
                {},
                {
                    success() {
                        appBus.trigger('smbPayments:paymentAdded');
                        /*
                         * appBus.trigger(
                         *     'payment:newPayment:gridAlert',
                         *     model,
                         *     locale.get('sbPayments.successfulSubmitMsg'),
                         * );
                         */
                        self.resetTotalAmountTemplate();
                        self.ui.emptyQueueMsg.html(`<br/>${self.scheduledItemCount}${self.scheduledItemCount > 1 ? locale.get('smbPayments.bill.scheduled.plural') : locale.get('smbPayments.bill.scheduled')} &nbsp;  <button type="button" class="btn btn-secondary ok-btn">${locale.get('smbPayments.ok')}</button>`);

                        self.ui.emptyQueueMsg.removeClass('hidden');
                    },

                    error(m, options) {
                        // TODO - implement error handling
                        self.ui.schedulePaymentsBtn.removeClass('hidden');
                        /*
                         * options will have server side validation messages
                         * model will contain the client validation - validationError
                         */
                        appBus.trigger('payment:newPayment:gridAlert', options);
                    },
                },
            );
        }

        return isValid;
    },

    /*
     *  If the collection is empty, hide the schedule payments button
     */
    showHideSchedulePaymentsBtn() {
        if (this.droppedCollectionView.collection.length > 0) {
            this.ui.schedulePaymentsBtn.removeClass('hidden');
        } else {
            this.ui.schedulePaymentsBtn.addClass('hidden');
        }
    },

    schedulePaymentsFast() {
        this.resetDropZone();
    },

    resetDropZone() {
        /*
         * if we have more droppable items,
         * reset the dropzone to handle them, otherwise close it
         */
        const remainingDraggableItems = $('.draggable-tiles li').length;

        this.render();
        this.resetTotalAmountTemplate();
        if (remainingDraggableItems === 0) {
            // no more items so close our drop zone
            $('.billsGridWrapper').slideUp();
            $('#tile-filter').parent().hide();
        }
    },

    onRender() {
        this.delegateEvents();

        if (this.droppedCollectionView) {
            this.droppableTargetListRegion.show(this.droppedCollectionView);
        }

        this.initializeDroppableTarget();
        this.renderAccountList();
    },
});
