import store from 'system/utilities/cache';

export default {
    /**
     *
     * make a Transfer from either another Transfer or Transfer Template
     *
     *
     */
    makePayment(model, contextKey) {
        const contextModel = {
            serviceName: model.context.serviceName,
            tnum: model.get('TNUM'),
            functionCode: model.get('FUNCTION'),
            subType: model.get('SUBTYPE'),
            templateServiceName: model.context.serviceName,
            loadFromPayments: model.loadFromPayments,
            updateCount: model.get('UPDATECOUNT__'),
        };
        contextModel.enableSaveDraft = true;
        store.set(`${contextKey}-contextOverride`, contextModel);
        store.set(`${contextKey}-actionModel`, model);
    },

    /**
     * make a Transfer Template from a Transfer or Transfer Template
     * @param {Model} model
     * @param {string} contextKey
     */
    makeTemplate(model, contextKey) {
        const contextModel = {
            serviceName: model.context.serviceName,
            tnum: model.get('TNUM'),
            functionCode: model.get('FUNCTION'),
            subType: model.get('SUBTYPE'),
            templateServiceName: model.context.serviceName,
            updateCount: model.get('UPDATECOUNT__'),
        };
        contextModel.enableSaveDraft = true;
        store.set(`${contextKey}-contextOverride`, contextModel);
        store.set(`${contextKey}-actionModel`, model);
    },
};
