import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import locale from '@glu/locale';
import services from 'services';
import { postData } from 'common/util/services';
import Formatter from 'system/utilities/format';
import applicationConfiguration from 'system/webseries/models/applicationConfiguration';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import constants from 'common/dynamicPages/api/constants';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import validatorPatterns from 'system/validatorPatterns';
import template from './contractIdLookup.hbs';

export default Layout.extend({
    template,

    ui: {
        $exchangeRateField: '[data-hook="getExchangeRate"]',
        $getExchangeRateButton: '[data-hook="getExchangeRateButton"]',
        $errorMessage: '[data-hook="errorMessage"]',
        $loadingSpinnerContainer: '[data-hook="loadingSpinnerContainer"]',
        $exchangeRateContainer: '[data-hook="exchangeRateContainer"]',
        $errorMessageContainer: '[data-hook="errorMessageContainer"]',
    },

    initialize(options) {
        this.formModel = options.formModel;
        this.onExchangeRateUpdate = options.onExchangeRateUpdate || (() => {});
        this.onClearExchangeRate = options.onClearExchangeRate || (() => {});
        this.model = options.model || new Model();

        if (options.prefill && this.hasContractId(this.formModel)) {
            this.model.set({
                exchangeRate: this.formModel.get('EXCHANGE_RATE'),
                contractId: this.formModel.get('EXCHANGE_RATE_CONTRACTID'),
            });
        }

        this.model.validators = {
            contractId: {
                exists: true,
                description: locale.get('RTGS.screentext.Contract ID'),
                matches: validatorPatterns.ALPHANUMERIC_PATTERN,
            },

            exchangeRate: {
                exists: true,
                description: locale.get('RTGS.Exchange_Rate'),
            },
        };
        this.formModel.on('validate', () => {
            if (!this.model.isValid()) {
                this.ui.$errorMessageContainer.show();
            }
        });

        this.getExchangeRateFromFXSystem = applicationConfiguration
            .getValue('WIRES', 'ALLOWCONTRACTIDRATELOOKUP') === '1';

        this.model.on('change:contractId', this.clearExchangeRate.bind(this));
        if (!this.getExchangeRateFromFXSystem) {
            this.model.on('change:exchangeRate', (model) => {
                this.onExchangeRateUpdate(model);
            });
        }
    },

    /**
     * Evaluate the model to determine if it has a contract Id
     * @param {Object} model
     * @returns {boolean}
     */
    hasContractId(model) {
        return !util.isEmpty(model.get('EXCHANGE_RATE_CONTRACTID'));
    },

    onRender() {
        if (!this.getExchangeRateFromFXSystem) {
            this.ui.$exchangeRateField.inputmask('decimal', util.extend(
                Formatter.getCurrencyMaskOptions(true, true),
                {
                    digits: +serverConfigParams.get('ExchangeRateScale'),
                    digitsOptional: false,
                },
            ));
        } else {
            this.ui.$errorMessageContainer.hide();
            this.ui.$exchangeRateContainer.hide();
            this.ui.$loadingSpinnerContainer.hide();
        }
        if (this.hasContractId(this.formModel)) {
            this.ui.$exchangeRateContainer.show();
        }
    },

    /**
     * Unset the exchangeRate from the model and then
     * hide the exchangeRateContainer
     */
    clearExchangeRate() {
        this.model.unset('exchangeRate');
        this.ui.$exchangeRateContainer.hide();
        this.ui.$errorMessageContainer.hide();
        this.onClearExchangeRate(this.model);
    },

    /**
     * Attempt to get a valid exchange rate with the current contract ID
     */
    retrieveExchangeRate() {
        // clear the currently present exchangeRate
        this.clearExchangeRate();

        // clear out the exchange rate area
        this.ui.$errorMessageContainer.hide();

        if (!this.model.get('contractId')) {
            // show error under contractId field
            this.model.validate();
            this.model.trigger('invalid');
        } else {
            this.showLoadingSpinner(true);
            this.getExchangeRate(this.formModel, this.model).then(
                this.onExchangeRateRetrieved.bind(this),
                this.onExchangeRateRetrievedError.bind(this),
            );
        }
    },

    getExchangeRate(formModel, model) {
        const url = services.generateUrl(constants.URL_GETEXCHANGERATE);
        const isCredit = formModel.get('ENTERED_AMOUNT_FLAG') === 'C';

        let debitAccount = formModel.get('DEBIT_ACCOUNT_NUMBER');
        let creditAccount = formModel.get('CREDIT_ACCOUNT_NUMBER') || formModel.get('BENE_ACCOUNT');

        if (formModel.get('TYPE') === 'TRANSFER') {
            if (formModel.fromAccount
                && formModel.fromAccount.get('mapDataList')
                && formModel.fromAccount.get('mapDataList').DEBIT_ACCOUNT_NUMBER) {
                debitAccount = formModel.fromAccount.get('mapDataList').DEBIT_ACCOUNT_NUMBER;
            }
            if (formModel.toAccount
                && formModel.toAccount.get('mapDataList')
                && formModel.toAccount.get('mapDataList').BENE_ACCOUNT) {
                creditAccount = formModel.toAccount.get('mapDataList').BENE_ACCOUNT;
            }
        }

        const data = {
            enteredAmountFlag: formModel.get('ENTERED_AMOUNT_FLAG'),
            creditCurrency: formModel.get('CREDIT_CURRENCY'),
            debitCurrency: formModel.get('DEBIT_CURRENCY'),
            creditAmount: (isCredit ? formModel.get('CREDIT_AMOUNT') : '0') || '0',
            debitAmount: (isCredit ? '0' : formModel.get('DEBIT_AMOUNT')) || '0',
            debitAcctNumber: debitAccount,
            creditAcctNumber: creditAccount,
            debitBankCode: formModel.get('DEBIT_BANK_CODE'),
            contractId: model.get('contractId'),
            valueDate: formModel.get('VALUE_DATE'),
            tranDate: formModel.get('TRAN_DATE'),
            typeCode: formModel.get('TYPE') || formModel.get('PAYMENTTYPE'),
            actionMode: formModel.context?.actionMode || 'INSERT',
        };
        return postData(url, data);
    },

    /**
     * The rate has been retrieved, show it to the user
     * @param {Object} response - the retrieved rate
     */
    onExchangeRateRetrieved(response) {
        this.showLoadingSpinner(false);
        if (response.status && response.fxRate) {
            this.ui.$exchangeRateContainer.show();
            this.model.set('exchangeRate', response.fxRate);
            // Callback to respond to receiving the exchange rate
            this.onExchangeRateUpdate(this.model);
        } else {
            this.ui.$errorMessageContainer.show();
            this.ui.$errorMessage.text(response.responseHeader.message[0]);
        }
    },

    /**
     * There was an issue retrieving the rate, show the error to the user
     * @param {Object} response - the response with the error
     */
    onExchangeRateRetrievedError(response) {
        this.showLoadingSpinner(false);
        this.ui.$errorMessageContainer.show();
        this.ui.$errorMessage.text(response.message);
    },

    /**
     * Show or hide the loading spinner appropriately
     * @param {Boolean} showLoadingSpinner - a flag to indicate the desired state of spinner
     */
    showLoadingSpinner(showLoadingSpinner) {
        this.ui.$loadingSpinnerContainer.toggle(showLoadingSpinner);
    },

    removeValidators() {
        this.model.removeValidator('contractId');
        this.model.removeValidator('exchangeRate');
    },

    templateHelpers() {
        return {
            getExchangeRateFromFXSystem: this.getExchangeRateFromFXSystem,
            showRequiredIndicator: !userInfo.isSmbUser(),
        };
    },
});
