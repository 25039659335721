var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"section section-container NoteItem\">\n    <div class=\"section-body\">\n        <div class=\"row\">\n            <div class=\"col-9\">\n                <p class=\"NoteItem-header\">\n                    <span class=\"NoteItem-user\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"ENTERED_BY_NAME") || (depth0 != null ? lookupProperty(depth0,"ENTERED_BY_NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ENTERED_BY_NAME","hash":{},"data":data,"loc":{"start":{"line":6,"column":48},"end":{"line":6,"column":67}}}) : helper)))
    + "</span>\n                    <span>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"serviceRequest.Added.Note.On",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":26},"end":{"line":7,"column":67}}}))
    + " -</span>\n                    <span class=\"NoteItem-timestamp\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"ENTERED_TIMESTAMP") || (depth0 != null ? lookupProperty(depth0,"ENTERED_TIMESTAMP") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ENTERED_TIMESTAMP","hash":{},"data":data,"loc":{"start":{"line":8,"column":53},"end":{"line":8,"column":74}}}) : helper)))
    + "</span>\n                </p>\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"col-9\">\n                <p class=\"NoteItem-message\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"NOTE") || (depth0 != null ? lookupProperty(depth0,"NOTE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NOTE","hash":{},"data":data,"loc":{"start":{"line":14,"column":44},"end":{"line":14,"column":52}}}) : helper)))
    + "</p>\n            </div>\n        </div>\n    </div>\n</section>\n";
},"useData":true});