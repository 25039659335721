import SchedulePaymentWidget from 'common/uiWidgets/schedulePaymentWidget/schedulePaymentWidget';
import moment from 'moment';
import { date } from '@glu/core';
import Formatter from 'system/utilities/format';
import achAuthRulesScheduleTmpl from './achAuthRulesSchedule.hbs';

export default SchedulePaymentWidget.extend({
    template: achAuthRulesScheduleTmpl,

    handleStartDate() {
        const { schedModel } = this.model;
        if (this.options.state !== 'view') {
            // In ACH auth rules, start & min date must be past today’s date
            const tomorrow = Formatter.formatDate(date().add(1, 'days'), 'YYYY-MM-DD');
            /**
             * In insert mode, set start date to tomorrow
             * In modify mode, only set start date to tomorrow if
             * current date is before tomorrow
             */
            const currentStartDate = schedModel.get('starts');
            if (this.mode === 'insert'
                || (this.model.context.actionMode === 'modify'
                && moment(currentStartDate).isBefore(tomorrow))) {
                schedModel.set('starts', tomorrow);
            }
        }
        this.handleStartEndDateRequired();
    },

    setMinDate() {
        const tomorrow = Formatter.formatDate(date().add(1, 'days'), 'YYYY-MM-DD');
        const { schedModel } = this.model;
        if (this.model.context.actionMode !== 'view') {
            schedModel.set('minDate', Formatter.formatDate(tomorrow, 'YYYY-MM-DD'));
        }
    },

    handleStartEndDateRequired() {
        const { schedModel } = this.model;
        const startDate = this.model.fieldData.STARTINGEFFECTIVEDATE;
        if (startDate) {
            /**
             * TODO: Pull startDateRequired and endDateRequired values from the db with
             * `{startDate|endDate}.mandatory`
             */
            schedModel.set('startDateRequired', true);
            // TODO: Do we need to dynamically set end date region to be mandatory?
            schedModel.set('endDateRequired', true);
        }
    },
});
