import dialog from '@glu/dialog';
import FIDetail from 'app/administration/views/fileImport/fiDetail';
import { getLastPositionData, isDeepLinked } from 'common/util/deeplinkUtil';
import modalUtil from 'common/util/modal';
import fiDetailModalTmpl from './fiDetailModal.hbs';

const FileImportDetails = FIDetail.extend({
    template: fiDetailModalTmpl,

    regions: {
        failedRecords: '#failedRecords',
        successfulImports: '#successfulImports',
    },

    attributes: {
        role: 'dialog',
        tabindex: '-1',
        'aria-hidden': 'false',
        class: 'modal',
    },

    onRender() {
        FIDetail.prototype.onRender.call(this);
        // If in iframe, configure with the current position
        if (isDeepLinked()) {
            modalUtil.updateModal(getLastPositionData().data, this);
        }
        this.listenTo(this.appBus, 'iframePosition', data => modalUtil.updateModal(data.data, this));
    },

    closeModal() {
        /*
         * this modal can spawn other modals on top of it for printing purpose,
         * so we need to make sure glu/dialog keeps track of this modal after other modals in
         * the queue before it get closed
         */
        dialog.activeModal = this;
        dialog.close();
    },
});

export default FileImportDetails;
