import Model from '@glu/core/src/model';
import NestedModel from '@glu/core/src/nestedModel';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import services from 'services';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import moment from 'moment';
import systemConfig from 'system/configuration';
import Formatter from 'system/utilities/format';
import transform from 'common/util/transform';
import store from 'system/utilities/cache';

const days = [
    'WEEKLYDAYMON',
    'WEEKLYDAYTUE',
    'WEEKLYDAYWED',
    'WEEKLYDAYTHURS',
    'WEEKLYDAYFRI',
    'WEEKLYDAYSAT',
    'WEEKLYDAYSUN',
];

export default Model.extend({
    defaults: {
        ACCOUNTFILTER: '',
        BENE_ACCOUNT: '',
        TRAN_DATE: '',
        ENTERED_AMOUNT: '',
        TYPE: 'TRANSFER',
    },

    addRecurringValidations() {
        this.validators.TEMPLATE_CODE = {
            exists: true,
            description: locale.get('PAY.TemplateCode'),
        };
        this.validators.TEMPLATE_DESCRIPTION = {
            exists: true,
            description: locale.get('PAY.TemplateDescription'),
        };
    },

    setValidators(reqFrom, reqTo, reqDate, reqAmt) {
        const validators = {};

        if (reqFrom) {
            validators.ACCOUNTFILTER = {
                exists: true,
                description: locale.get('RTGS.TRANSFER.DEBIT_ACCOUNT'),
            };
        }
        if (reqTo) {
            validators.BENE_ACCOUNTENTITLEMENT = {
                exists: true,
                description: locale.get('RTGS.TRANSFER.BENE_ACCOUNT'),
            };
        }
        if (reqDate) {
            validators.TRAN_DATE = {
                exists: true,
                description: locale.get('RTGS.TRANSFER.TRANSFER_DATE'),
            };
        }
        if (reqAmt) {
            validators.ENTERED_AMOUNT = {
                exists: true,
                description: locale.get('RTGS.TRANSFER.AMOUNT'),
                minValue: 0.01,
            };
        }

        this.validators = validators;
    },

    sync(method, model, opts) {
        switch (method) {
        case 'create':

            return this.saveRecurringTransfer(model, opts);

        case 'read':

            if (opts.recurId) {
                return this.readRecurringTransfer(model, opts);
            }
            return this.readSingleTransfer(model, opts);
        default:
        }
        // TODO: Should this error?
        return undefined;
    },

    readRecurringTransfer(model, opts) {
        const postData = [{
            name: 'TNUM',
            value: opts.recurId,
        }];

        if (opts.copyPaymentFromTemplate || opts.copyTemplateFromTemplate) {
            postData.push({
                name: 'FROMTEMPLATE',
                value: '1',
            }, {
                name: 'FUNCTIONCODE',
                value: 'TMPL',
            }, {
                name: 'TARGETFUNCTION',
                value: opts.copyPaymentFromTemplate ? 'INST' : 'TMPL',
            }, {
                name: 'TEMPLATEENTRYMETHOD',
                value: store.get('templateEntryMethod') ?? '1',
            });
            store.unset('templateEntryMethod');
        }

        return http.post(
            services.generateUrl((systemConfig.isAdmin()) ? 'adminTemplate/transfer/recurring/read' : 'template/transfer/recurring/read'),
            {
                item: postData,
            },
            (result) => {
                opts.success(result);
            },
            (result) => {
                opts.error({
                    errorCode: result.status,
                    errorMessage: result.statusText,
                    message: result.responseText,
                });
            },
        );
    },

    saveRecurringTransfer(model, opts) {
        // saving recurring transfer
        const url = services.generateUrl(`template/transfer/recurring/${(opts.isModify) ? 'update' : 'add'}`);

        return http.post(url, model.toJSON(), (result) => {
            opts.success(result);
        }, (result) => {
            opts.error({
                errorCode: result.status,
                errorMessage: result.statusText,
                message: result.responseText,
            });
        });
    },

    readSingleTransfer(model, opts) {
        const data = {
            item: transform.hashToPairs({
                TNUM: model.get('TNUM'),
                ENTRYMETHOD: model.get('ENTRYMETHOD'),
                FUNCTIONCODE: 'INST',
                UPDATECOUNT__: model.get('UPDATECOUNT__'),
            }),
        };

        // Base model came from grid, must remove these unneeded attributes
        model.unset('fieldData');
        model.unset('driverFields');

        return http.post(services.paymentTransferRead, data, (result) => {
            opts.success(result);
        }, () => {
            opts.error();
        });
    },

    parse(response) {
        const obj = transform.pairsToHash(response.item);

        // FIX: server needs to populate proper name for this
        obj.DEBIT_ACCOUNT_NAME = obj.DEBIT_ACCOUNT_BANK_NAME;
        obj.DEBIT_AMOUNT = Formatter.formatCurrency(obj.DEBIT_AMOUNT);
        obj.CREDIT_AMOUNT = Formatter.formatCurrency(obj.CREDIT_AMOUNT);

        if (obj.TRAN_DATE) {
            obj.TRAN_DATE = moment(obj.TRAN_DATE).format(userInfo.getDateFormat());
        }

        if (obj.VALUE_DATE) {
            obj.VALUE_DATE = moment(obj.VALUE_DATE).format(userInfo.getDateFormat());
        }

        if (obj.FUNCTION === 'TMPL' || obj.ENTRYMETHOD === '1') {
            if (util.isEmpty(obj.SETNAME) && !util.isEmpty(obj.TEMPLATE_CODE)) {
                obj.SETNAME = obj.TEMPLATE_CODE;
            }
            if (util.isEmpty(obj.SETDESCRIPTION) && !util.isEmpty(obj.TEMPLATE_DESCRIPTION)) {
                obj.SETDESCRIPTION = obj.TEMPLATE_DESCRIPTION;
            }
        }

        // Recurring template, must populate a schedule model if pattern mode is not Off
        if (obj.PATTERNMODE && obj.PATTERNMODE !== 'O') {
            const schedModel = new NestedModel();
            const recurrence = {};
            let ends;

            // This is needed so scheduler doesn't think it is a new record
            schedModel.idAttribute = 'starts';

            schedModel.set('starts', obj.STARTINGEFFECTIVEDATE.indexOf(' ') > -1 ? obj.STARTINGEFFECTIVEDATE.substring(0, obj.STARTINGEFFECTIVEDATE.indexOf(' ')) : obj.STARTINGEFFECTIVEDATE);
            schedModel.set('ends', obj.ENDINGEFFECTIVEDATE.indexOf(' ') > -1 ? obj.ENDINGEFFECTIVEDATE.substring(0, obj.ENDINGEFFECTIVEDATE.indexOf(' ')) : obj.ENDINGEFFECTIVEDATE);
            schedModel.set('showWeekends', true);
            // Monthly
            if (obj.PATTERNMODE === 'M') {
                schedModel.set('type', 'MONTHLY');

                if (obj.MONTHLYMODE === 'specific') {
                    recurrence.intervalType = 'DAY';
                    recurrence.nMonths = obj.MONTHLYMONTH1;
                    recurrence.onN = [Number(obj.MONTHLYDAY1)];
                } else {
                    recurrence.intervalType = obj.MONTHLYDAYTYPE.toUpperCase();
                    recurrence.nMonths = obj.MONTHLYMONTHNUMBER;
                    recurrence.onN = [obj.MONTHLYDAYNUMBER.toUpperCase()];
                }
            } else {
                // Weekly

                schedModel.set('type', 'WEEKLY');

                recurrence.nWeeks = Number(obj.WEEKLYWEEKS);
                recurrence.onN = [];

                util.each(days, (day, idx) => {
                    if (obj[day] === '1') {
                        recurrence.onN.push(idx + 1);
                    }
                });
            }

            // Ends
            if (obj.ENDMODE === 'noend') {
                ends = false;
            } else if (obj.ENDMODE === 'numoccur') {
                ends = Number(obj.ENDCYCLES);
            } else if (obj.ENDMODE === 'effdate') {
                ends = obj.ENDINGEFFECTIVEDATE;
            }

            schedModel.set('recurrence', recurrence);
            schedModel.set('ends', ends);

            obj.schedModel = schedModel;
        }

        return obj;
    },

    getCurrency() {
        return this.get('DEBIT_CURRENCY') || (this.fromAccount ? this.fromAccount.get('CURRENCYCODE') : '');
    },

    toJSON() {
        /*
         * These values are not mapped to UI fields but are required server side.
         * These fields are also used by the UI to handle the duplicate warning message
         * and populate the duplicate reason override on the correct models when
         * duplicate payments are flagged from a multi-add context.
         */
        const isCredit = (this.get('DBCR_FLAG') === 'C');
        this.set({
            TRANSACTION_AMOUNT: this.get(isCredit ? 'CREDIT_AMOUNT' : 'DEBIT_AMOUNT'),
            TRANSACTION_CURRENCY: this.get(isCredit ? 'CREDIT_CURRENCY' : 'DEBIT_CURRENCY'),
        });

        let pairs = transform.hashToPairs(this.attributes);

        if (this.fromAccount) {
            pairs = pairs.concat(transform.hashToPairs(this.fromAccount.get('mapDataList')));
        }

        if (this.toAccount) {
            pairs = pairs.concat(transform.hashToPairs(this.toAccount.get('mapDataList')));
        }

        return {
            item: pairs,
        };
    },
});
