var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button class=\"btn "
    + alias4(((helper = (helper = lookupProperty(helpers,"btnClass") || (depth0 != null ? lookupProperty(depth0,"btnClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnClass","hash":{},"data":data,"loc":{"start":{"line":56,"column":35},"end":{"line":56,"column":47}}}) : helper)))
    + "\"\n                    data-action=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"btnAction") || (depth0 != null ? lookupProperty(depth0,"btnAction") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnAction","hash":{},"data":data,"loc":{"start":{"line":57,"column":33},"end":{"line":57,"column":46}}}) : helper)))
    + "\"\n                    name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"btnAction") || (depth0 != null ? lookupProperty(depth0,"btnAction") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnAction","hash":{},"data":data,"loc":{"start":{"line":58,"column":26},"end":{"line":58,"column":39}}}) : helper)))
    + "\"\n                    type=\"button\">\n                    <span class=\"txt\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"btnText") || (depth0 != null ? lookupProperty(depth0,"btnText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnText","hash":{},"data":data,"loc":{"start":{"line":60,"column":38},"end":{"line":60,"column":49}}}) : helper)))
    + "</span>\n                </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\">\n            <span class=\"icon-arrow-left\"></span>\n            <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.backToPrevious",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":42},"end":{"line":5,"column":84}}}))
    + "</span>\n        </a>\n    </div>\n    <h1 class=\"landing-header\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":8,"column":31},"end":{"line":8,"column":40}}}) : helper)))
    + "</h1>\n\n    <div class=\"amount-wrapper\">\n        <p class=\"amount-label-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.paymentTotal",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":37},"end":{"line":11,"column":69}}}))
    + "</p>\n        <div class=\"amount-value-text payment-total\"><span data-hook=\"getSummaryAmountTotal\">"
    + alias2(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"CREDIT_AMOUNT") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":12,"column":93},"end":{"line":12,"column":117}}}))
    + "</span> <span data-hook=\"getCurrency\"></span></div>\n    </div>\n</div>\n \n<section class=\"section section-container\">\n    <div class=\"alert-message\">\n        <div data-region=\"alertRegion\" role=\"alert\"></div>\n    </div>\n</section>\n \n<section class=\"section section-container\">\n    <div class=\"section-body\">\n        <div class=\"collection-entry rtpMultiAdd\" data-region=\"collectionRegion\" aria-live=\"polite\"></div>\n        <div class=\"add-container\">\n            <div class=\"item-add-container\">\n                <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.Add",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":22},"end":{"line":29,"column":42}}}))
    + "</span>\n                <input aria-labelledby=\"add-label add-type\" type=\"text\" class=\"form-control input-sm\"  data-hook=\"getAddCount\" value=\"1\">\n                <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"getItemsLabel") || (depth0 != null ? lookupProperty(depth0,"getItemsLabel") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"getItemsLabel","hash":{},"data":data,"loc":{"start":{"line":31,"column":22},"end":{"line":31,"column":39}}}) : helper)))
    + "</span>\n                <button aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"title.addIt",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":36},"end":{"line":32,"column":60}}}))
    + "\" class=\"btn btn-secondary btn-xs\" data-hook=\"getAddItemButton\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.add",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":124},"end":{"line":32,"column":147}}}))
    + "</button>\n            </div>\n            <div class=\"item-info-container\">\n                <span data-hook=\"getAddInfo\" class=\"info-line\"></span>\n            </div>\n        </div>\n     </div>\n</section>\n \n<div class=\"section section-summary\">\n    <div data-section=\"payment-summary\" class=\"section-header\">\n        <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTP.totalOf",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":44,"column":14},"end":{"line":44,"column":38}}}))
    + "</span>\n        <span class=\"summary-large-text\" data-hook=\"getTotalItems\"></span>\n        <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTP.paymentsFor",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":46,"column":14},"end":{"line":46,"column":42}}}))
    + "</span>\n        <span class=\"summary-large-text\" data-hook=\"paymentSummaryTotalAmount\"></span>\n        <span data-hook=\"paymentSummaryCurrency\"></span>\n    </div>\n \n    <div data-hook=\"saveAsTemplate\" data-section=\"save-as-template\"></div>\n\n    <div class=\"section-body\">\n        <div class=\"widget-action-btn-group\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"buttons") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":12},"end":{"line":62,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>";
},"useData":true});