var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"field-container-sm\">\n            <div class=\"form-group\">\n                <div>\n                    <p>\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.printOptions.numberOfItems",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":24},"end":{"line":8,"column":70}}}))
    + "\n                        <span data-hook=\"getSelectedItems\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"selectedItemsCount") || (depth0 != null ? lookupProperty(depth0,"selectedItemsCount") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"selectedItemsCount","hash":{},"data":data,"loc":{"start":{"line":9,"column":59},"end":{"line":9,"column":81}}}) : helper)))
    + "</span>\n                    </p>\n                </div>\n                <div class=\"row\">\n                    <div class=\"col-6\">\n                        <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.printOptions.include",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":31},"end":{"line":14,"column":71}}}))
    + "</label>\n                        <div class=\"form-group\">\n                            <div class=\"radio\">\n                                <label class=\"radio-inline\" for=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":17,"column":65},"end":{"line":17,"column":72}}}) : helper)))
    + "_select-all\">\n                                    <input type=\"radio\"\n                                        id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":19,"column":44},"end":{"line":19,"column":51}}}) : helper)))
    + "_select-all\"\n                                        value=\"all\"\n                                        name=\"selection\"\n                                        data-bind=\"model\"\n                                        data-hook=\"getSelection\"\n                                        "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasSelectedRows") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":40},"end":{"line":24,"column":86}}})) != null ? stack1 : "")
    + ">\n                                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.printOptions.allItems",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":36},"end":{"line":25,"column":77}}}))
    + "\n                                </label>\n                            </div>\n                            <div class=\"radio\">\n                                <label class=\"radio-inline\" for=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":29,"column":65},"end":{"line":29,"column":72}}}) : helper)))
    + "_select-sel\">\n                                    <input type=\"radio\"\n                                        id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":31,"column":44},"end":{"line":31,"column":51}}}) : helper)))
    + "_select-sel\"\n                                        value=\"sel\"\n                                        name=\"selection\"\n                                        data-bind=\"model\"\n                                        data-hook=\"getSelection\"\n                                        "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasSelectedRows") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":40},"end":{"line":36,"column":86}}})) != null ? stack1 : "")
    + ">\n                                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.printOptions.selectedItems",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":37,"column":36},"end":{"line":37,"column":82}}}))
    + "\n                                </label>\n                            </div>\n                        </div>\n                    </div>\n\n                </div>\n            </div>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"field-container-sm\">\n        <label for=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":50,"column":20},"end":{"line":50,"column":27}}}) : helper)))
    + "_include-details\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"menuitem.actInfoRp.dataExpt",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":50,"column":45},"end":{"line":50,"column":85}}}))
    + "</label>\n        <div class=\"form-group\">\n            <div class=\"row\">\n                <label class=\"checkbox-inline\">\n                <input type=\"checkbox\"\n                    id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":55,"column":24},"end":{"line":55,"column":31}}}) : helper)))
    + "_include-details\"\n                    name=\"includeDetails\" \n                    data-bind=\"model\" />\n                    "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"export.includeTransactionDetails",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":58,"column":20},"end":{"line":58,"column":65}}}))
    + "\n                </label>\n            </div>\n        </div>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form autocomplete=\"off\" data-submit=\"save\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasRowSelector") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":46,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"includeDetails") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":4},"end":{"line":63,"column":11}}})) != null ? stack1 : "")
    + "</form>\n";
},"useData":true});