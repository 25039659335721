import ItemView from '@glu/core/src/itemView';
import $ from 'jquery';
import util from '@glu/core/src/util';
import ruleTmpl from 'common/passwordRules/rule.hbs';

export default ItemView.extend({
    template: ruleTmpl,

    onRender() {
        this.rules = this.activeRules();
        const self = this;

        $('input[name="newPassword"]').keyup((e) => {
            const userPasswordString = e.currentTarget.value;
            self.testPassword(userPasswordString);
        });
    },

    testPassword(string) {
        let rule;
        let result;
        const self = this;

        util.each(this.rules, (activeRule) => {
            switch (activeRule) {
            case 'MINIMUMLENGTH':
                result = string.length >= self.model.get(activeRule);
                self.toggleStatus(activeRule, result);
                break;
            case 'ALPHACOUNT':
                rule = /[a-zA-Z]/gi;
                result = string.match(rule)
                    ? (string.match(rule)).length >= self.model.get(activeRule) : false;
                self.toggleStatus(activeRule, result);
                break;
            case 'ALPHASTART':
                rule = /[a-zA-Z]/gi;
                result = rule.test(string[0]);
                self.toggleStatus(activeRule, result);
                break;
            case 'DIGITCOUNT':
                rule = /[0-9]/g;
                result = string.match(rule)
                    ? (string.match(rule)).length >= self.model.get(activeRule) : false;
                self.toggleStatus(activeRule, result);
                break;
            case 'SPECIALCOUNT':
                result = self.specialCount(string) >= self.model.get(activeRule);
                self.toggleStatus(activeRule, result);
                break;
            case 'UPPERCASECOUNT':
                rule = /[A-Z]/g;
                result = string.match(rule)
                    ? (string.match(rule)).length >= self.model.get(activeRule) : false;
                self.toggleStatus(activeRule, result);
                break;
            case 'LOWERCASECOUNT':
                rule = /[a-z]/g;
                result = string.match(rule)
                    ? (string.match(rule)).length >= self.model.get(activeRule) : false;
                self.toggleStatus(activeRule, result);
                break;
            default:
            }
        });
    },

    toggleStatus(element, status) {
        this.$(`#${element}`).toggleClass('active', status);
    },

    activeRules() {
        const activeRules = [];
        const rules = this.model.get('RULES');
        for (let x = 0; x < rules.length; x += 1) {
            if (rules[x].value) {
                activeRules.push(rules[x].name);
            }
        }
        return activeRules;
    },

    specialCount(userString) {
        const specialChars = this.model.get('SPECIALCHARS');
        let count = 0;
        for (let x = 0; x < userString.length; x += 1) {
            if (specialChars.indexOf(userString.charAt(x)) > -1) {
                count += 1;
            }
        }
        return count;
    },
});
