import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import alert from '@glu/alerts';
import FlexDropdown from '@glu/flex-dropdown';
import Formatter from 'system/utilities/format';
import util from '@glu/core/src/util';
import transform from 'common/util/transform';
import alertMessage from 'common/api/alertMessage';
import entitlementUtil from 'common/util/entitledActions';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import services from './services';
import PreferenceModel from './preferences';
import template from './preferencesView.hbs';

export default Layout.extend({
    template,
    loadingTemplate,
    className: 'AdminRTPPreferences',

    ui: {
        $allowableCurrencies: '[data-hook="getOperatingCurrencyCodes"]',
        $maxTransactionAmount: '[data-hook="getMaxTransactionAmount"]',
        $maxOnUsTransactionAmount: '[data-hook="getMaxOnUsTransactionAmount"]',
        $saveButton: '[data-hook="getSave"]',
    },

    events: {
        'click @ui.$saveButton': 'save',
        'click [data-hook="getReset"]': 'reset',
    },

    initialize() {
        this.model = new PreferenceModel();
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
        } else if (this.hasModify) {
            this.renderAllowableCurrencies();
            let options = Formatter.getCurrencyMaskOptions(undefined, true);
            options = util.extend(options, {
                rightAlign: true,
                digits: 2,
                integerDigits: 11,
                allowMinus: false,
            });
            this.ui.$maxTransactionAmount.inputmask('number', options);
            this.ui.$maxOnUsTransactionAmount.inputmask('number', options);
        }
    },

    loadRequiredData() {
        Promise.all([
            services.getAllowableCurrencies(),
            services.getEntitledActions(),
            this.model.fetch(),
        ]).then((responses) => {
            const [allowableCurrencies, entitlements] = responses;
            this.allowableCurrencies = this.generateFlexDropdownData(allowableCurrencies
                .queryResponse.QueryData.queryRows);

            this.hasModify = entitlementUtil.hasEntitledAction(
                entitlementUtil.ACTIONS.modify,
                entitlements.actionModes,
            );
            this.setHasLoadedRequiredData(true);
            this.backupModel();
            this.render();
        });
    },

    templateHelpers() {
        return {
            headingText: locale.get('tableMaintenance.rtpPreferences_title-modify'),
            hasModify: this.hasModify,
        };
    },

    /**
     * Transform an array of objects into the expected flex drop down
     * datasource sctructure
     * @param {Array} array
     * @returns {Array} Array of objects with properties id and name
     */
    generateFlexDropdownData(array) {
        return array.map((row) => {
            const item = transform.pairsToHash(row.mapDataList, 'toField', 'value');
            return {
                id: item.CURRENCY_CODE,
                name: `${item.CURRENCY_CODE} - ${item.CURRENCY_DESCRIPTION}`,
            };
        });
    },

    /**
     * Setup the dropdown and add change listener
     */
    renderAllowableCurrencies() {
        const dropDown = new FlexDropdown({
            data: this.allowableCurrencies,
            multiSelect: true,
            preSelectedIds: this.model.get('OPERATING_CURRENCYCODES').split(','),
            selectAllBtn: true,
            clearBtn: true,
            filter: true,
        });
        this.listenTo(dropDown, 'selectionChanged:id', this.updateCurrencies.bind(this));
        this.dropDownRegion.show(dropDown);
    },

    /**
     * Set the model with a list of selected IDs
     * @param {Array} ids - list of selected IDs
     */
    updateCurrencies(ids) {
        this.model.set({
            OPERATING_CURRENCYCODES: ids.join(','),
        });
    },

    /**
     * Create a clone of the model
     */
    backupModel() {
        this.originalModel = this.model.clone();
    },

    /**
     * When the model is valid, save it to the server
     */
    save() {
        if (this.model.isValid()) {
            this.toggleButtonsBusy(true);
            this.model.save().then(this.handleSaveSuccess.bind(this))
                .catch(this.handleSaveError.bind(this));
        }
    },

    /**
     * Clone the newly saved model and show a success message alert
     * @param {Object} response - stand DGB response object
     */
    handleSaveSuccess(response) {
        this.backupModel();
        this.toggleButtonsBusy(false);
        const alertView = alert.success(response.message.join(' '));
        this.alertRegion.show(alertView);
    },

    /**
     * Show the error message alert
     * @param {Object} response - standard DGB Error response
     */
    handleSaveError(response) {
        this.toggleButtonsBusy(false);
        alertMessage.renderMessage(this, null, response.responseJSON);
    },

    /**
     * Use the attributes of the cloned model to set the view model,
     * then render the view
     */
    reset() {
        this.model.set(this.originalModel.toJSON());
        this.render();
    },

    /**
     * Set save buttons to busy parameter
     * @param {boolean} busy
     */
    toggleButtonsBusy(busy) {
        this.ui.$saveButton.attr('aria-busy', busy);
    },
});
