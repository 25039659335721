import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import addTmpl from './add.hbs';

export default Layout.extend({
    template: addTmpl,

    initialize(options) {
        this.model = options.model || new Model();
    },

    segmentationList() {
        this.navigateTo('/SEGMENTATION/viewSegments');
    },

    back() {
        this.navigateTo('/SEGMENTATION/addProfile');
    },

    templateHelpers() {
        return {
            imgBase: `${window.Bottomline.assetRoot}/images/${window.Bottomline.buildId}/prototype/screenshots/themes`,
        };
    },
});
