import { getOptions, modifyDataToPost } from 'pcm/common/serviceGridUtils';

/**
 *
 * @param typeCode
 * @param serviceName
 * @returns {{context: *[], entryMethod: number, overrideContext: {serviceName: string}}}
 */
function getCheckEntitlementsOptions(typeCode, serviceName) {
    return {
        context: [{
            name: 'productCode',
            value: '_ADMIN',
        }, {
            name: 'functionCode',
            value: 'MAINT',
        }, {
            name: 'typeCode',
            value: typeCode,
        }],
        entryMethod: 0,
        overrideContext: {
            serviceName: `/tableMaintenance/${serviceName}`,
        },
    };
}

export const settingsEntitlementsOptions = getCheckEntitlementsOptions('ACHESEND', 'achEsendManagement');
export const profileEntitlementsOptions = getCheckEntitlementsOptions('ACHESEND', 'achEsendManagement');
export const applicationEntitlementsOptions = getCheckEntitlementsOptions('ACHESEND', 'achEsendManagement');
export const divisionEntitlementsOptions = getCheckEntitlementsOptions('ACHESEND', 'achEsendManagement'); // Yes, the same as for CheckProfileList (typeCode is NOT COMPDIV, and it's OK here)

export const checkEntitlements =
    [profileEntitlementsOptions, applicationEntitlementsOptions, divisionEntitlementsOptions];

/**
 *
 * @param inquiryId - depends on feature, it will be always different.
 * @param typeCode - depends on feature, it will be always different.
 * @returns {*}
 */
export const checkDataToPost = (inquiryId, typeCode) => modifyDataToPost([{
    name: 'inquiryId',
    value: inquiryId,
}, {
    name: 'typeCode',
    value: typeCode,
}]);

export const getCurrentLinkToBack = () => {
    if (window.location) {
        return window.location.pathname.split('/ui')[1] + window.location.search;
    }
    return '';
};

/**
 *
 * @param serviceName - used for ServiceGrid
 * @param context - used for ServiceGrid
 * @param inquiryId - passed from main feature.
 * @param typeCode - passed from main feature. Depends on feature, it will be always different.
 * @param additionalSearchFields
 * @param filter - if true, then filter dropdown will be shown above the MDF Grid.
 * @param enableSavedViews - if true, then MDF Grid will fetch endpoint to get data from server
 * @param selector
 * @param loadedCallback - executed just after grid event 'grid:available' + 'after:build'
 * @param gridOptions - if provided, then object will extend the Grid instance options
 * @returns {*}
 */
export const prepareGridOptions = ({
    serviceName,
    context,
    inquiryId,
    typeCode,
    additionalSearchFields,
    filter = true,
    enableSavedViews = true,
    selector,
    loadedCallback,
    gridOptions,
}) =>
    getOptions({
        context,
        serviceName,
    }, {
        ...checkDataToPost(inquiryId, typeCode),
        additionalSearchFields,
        filter,
        enableSavedViews,
        selector: selector || 'rowSelector',
        loadedCallback,
        gridOptions,
    });

/**
 *
 * @param details {Object}
 * @returns {Boolean}
 */
export const hasCheckNumberRangeFromDetails = details => details && !!details.APNYN;

/**
 *
 * @param id {string}
 * If provided, then "example_1554740260321"
 * If not provided, then "_1554740260322"
 * And that key is expected to use for ReactJS list/repetitive-like components.
 *
 * @returns {string}
 * @todo - LOOK TO import { getUniqueId } from 'common/util/uniqueId';
 */
export const generateKey = (id = '') => {
    const latestTime = (new Date()).getTime();
    return `${id}_${latestTime}_${Math.floor(Math.random() * 100)}`;
};

/**
 * Helper function to get View Entity ID (any identifier possible) from location.search (URL).
 * So that we can use it inside View-like React component.
 * @param key {string}
 * @returns {string}
 *
 * @todo TODO - verify the best way to get companyCode after BE is implemented.
 * Possible alternatives:
 * 1) Use location.search.companyCode somehow;
 * 2) Somehow receive from this.props.details.companyCode;
 */
export const getDataFromUrl = (key = 'id') => {
    const escapedCodeValue = window.location.search.split(`?${key}=`)[1]; // Example: "hello%20world"
    return decodeURI(escapedCodeValue); // Example: "hello world"
};

/**
 * Helper function to get all values from confirmData response in PCM endpoints
 * @param data {string}
 * @returns {string}
 */

const getParamsFromConfirmData = (data) => {
    const params = {};
    `&${data}`.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
        params[key] = value;
    });
    return params;
};

/**
 * Helper function to get the ID from response in PCM endpoints
 * @param data {string}
 * @returns {string}
 */
export const getIdFromAddResponse = (response) => {
    const confirmDataItem = response.confirms.confirmResults[0].confirmData[0].item;
    if (confirmDataItem) {
        const confirmData = confirmDataItem.find(item => item.name === 'ID');
        return getParamsFromConfirmData(confirmData.value);
    }
    return '';
};

/**
 * Helper function to get the status from a record  detail and some useful methods
 * @param data {object}
 * @returns {object}
 */
export const getStatusByDetails = (details) => {
    const { STATUS: status } = details || { STATUS: '' };
    return {
        status,
        isDeleted: () => status === 'DL',
        isApproved: () => status === 'AP',
        isSuspended: () => status === 'SP',
    };
};

/**
 * Helper function to return a list of additionalField objects to be used in checkprofile pages
 * @param detail {object}
 * @returns {Array}
 */
export const getArrayAdditionalInformation = (details) => {
    if (!details) {
        return [];
    }
    const additionalInformations =
        (({
            CHECKPROFILEEXTRA1,
            CHECKPROFILEEXTRA2,
            CHECKPROFILEEXTRA3,
            CHECKPROFILEEXTRA4,
            CHECKPROFILEEXTRA5,
            CHECKPROFILEEXTRA6,
            CHECKPROFILEEXTRA7,
            CHECKPROFILEEXTRA8,
            CHECKPROFILEEXTRA9,
            CHECKPROFILEEXTRA10,
            CHECKPROFILEEXTRA11,
            CHECKPROFILEEXTRA12,
            CHECKPROFILEEXTRA13,
            CHECKPROFILEEXTRA14,
            CHECKPROFILEEXTRA15,
            CHECKPROFILEEXTRA16,
            CHECKPROFILEEXTRA17,
            CHECKPROFILEEXTRA18,
            CHECKPROFILEEXTRA19,
            CHECKPROFILEEXTRA20,
            CHECKPROFILEEXTRA21,
            CHECKPROFILEEXTRA22,
            CHECKPROFILEEXTRA23,
            CHECKPROFILEEXTRA24,
            CHECKPROFILEEXTRA25,
            CHECKPROFILEEXTRA26,
            CHECKPROFILEEXTRA27,
            CHECKPROFILEEXTRA28,
            CHECKPROFILEEXTRA29,
            CHECKPROFILEEXTRA30,
        }) =>
            ({
                CHECKPROFILEEXTRA1,
                CHECKPROFILEEXTRA2,
                CHECKPROFILEEXTRA3,
                CHECKPROFILEEXTRA4,
                CHECKPROFILEEXTRA5,
                CHECKPROFILEEXTRA6,
                CHECKPROFILEEXTRA7,
                CHECKPROFILEEXTRA8,
                CHECKPROFILEEXTRA9,
                CHECKPROFILEEXTRA10,
                CHECKPROFILEEXTRA11,
                CHECKPROFILEEXTRA12,
                CHECKPROFILEEXTRA13,
                CHECKPROFILEEXTRA14,
                CHECKPROFILEEXTRA15,
                CHECKPROFILEEXTRA16,
                CHECKPROFILEEXTRA17,
                CHECKPROFILEEXTRA18,
                CHECKPROFILEEXTRA19,
                CHECKPROFILEEXTRA20,
                CHECKPROFILEEXTRA21,
                CHECKPROFILEEXTRA22,
                CHECKPROFILEEXTRA23,
                CHECKPROFILEEXTRA24,
                CHECKPROFILEEXTRA25,
                CHECKPROFILEEXTRA26,
                CHECKPROFILEEXTRA27,
                CHECKPROFILEEXTRA28,
                CHECKPROFILEEXTRA29,
                CHECKPROFILEEXTRA30,
            }))(details);

    const additionalInformationsArray = Object.keys(additionalInformations)
        .map(checkProfileExtra => (
            { checkProfileExtra: additionalInformations[checkProfileExtra], key: checkProfileExtra.split('CHECKPROFILEEXTRA')[1] }));

    return additionalInformationsArray;
};
