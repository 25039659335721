import Collection from '@glu/core/src/collection';
import http from '@glu/core/src/http';
import services from 'services';
import { appBus } from '@glu/core';
import transform from 'common/util/transform';
import util from '@glu/core/src/util';
import LiveMaintenanceMessageModel from '../models/liveMaintenanceMessageModel';

export default Collection.extend({
    model: LiveMaintenanceMessageModel,
    url: services.generateUrl('tableMaintenance/getLookupResults'),
    settings: {
        allLanguages: '',
    },

    initialize() {
        // populate collection with data from server
        this.sync('get');
    },

    /**
     * Function to save or get data from server
     * @param  {string} method Action to perform
     */
    sync(method) {
        this.triageRequest(method);
    },

    /**
     * Handles request for getting or saving to collection and runs based on
     * method passed
     * @param  {string} method Action for collection
     */
    triageRequest(method) {
        if (method === 'get') {
            this.getModels();
        } else if (method === 'save') {
            this.saveModels();
        }
    },

    /**
     * Retrieve all languages/messages from server
     */
    getModels() {
        const url = services.generateUrl('tableMaintenance/getLookupResults');
        const method = 'post';

        const data = {
            requestParameters: {
                item: [{
                    name: 'INQUIRYID',
                    value: '9049',
                }],
            },
            startRow: 0,
            rowsPerPage: 0,
        };

        this.interactWithApi(url, method, data)
            .then(this.addToCollection.bind(this))
            .catch(this.handleError.bind(this));
    },

    /**
     * Hit api and get or set data
     * @param  {string} url    URL of api
     * @param  {string} method Method used to hit api
     * @param  {object} data   Object literal containing data API requires for
     * interaction
     */
    interactWithApi(url, method, data) {
        return new Promise((resolve, reject) => {
            http[method](url, data).then(resolve, reject);
        });
    },

    /**
     * Add formatted array of models to collection
     * @param {array} response - Formatted array of models
     */
    addToCollection(response) {
        // add model array to collection
        this.add(this.formatResponse(response));
    },

    /**
     * Format response before adding to collection
     * @param  {array} response Array of models returned from service
     * @return {array} Formatted array of models
     */
    formatResponse(response) {
        // after success loop through each message in result
        return util.map(response.rows, messageArray => transform.pairsToHash(messageArray.columns, 'fieldName', 'fieldValue'));
    },

    // trigger appBus in event of service error
    handleError() {
        appBus.trigger('liveMaintenance.getDataError');
    },

    /**
     * Save all languages/messages to server
     */
    saveModels() {
        const url = services.generateUrl('liveMaintenance/save');
        const method = 'post';
        const data = this.convertCollectionForSave();

        this.interactWithApi(url, method, data)
            .then(this.handleSaveSuccess)
            .catch(this.handleSaveError);
    },

    /**
     * Handles data from api on successful interaction
     * @param  {array} response data from api
     */
    handleSaveSuccess(response) {
        if (response.message.indexOf('failed') >= 0) {
            appBus.trigger('liveMaintenance.saveError');
        } else {
            appBus.trigger('liveMaintenance.modelsSaved');
        }
    },

    /**
     * Handles error unsuccessful interaction with api
     */
    handleSaveError() {
        appBus.trigger('liveMaintenance.saveError');
    },

    /**
     * Take data from all models and format them in a format compatible to
     * REST service
     * @return {object} JSON formatted object of values to save to server
     */
    convertCollectionForSave() {
        const items = this.map(model => ({
            item: [{
                name: 'LANGUAGE',
                value: model.get('LANGUAGE'),
            }, {
                name: 'MESSAGEHEADER',
                value: model.get('MESSAGEHEADER'),
            }, {
                name: 'DETAILS',
                value: model.get('DETAILS'),
            }],
        }));

        return {
            items,
            liveMaintenanceHeaderMessage: 'Message for header',
        };
    },

});
