import workspaceHelper from 'common/workspaces/api/helper';
import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import $ from 'jquery';
import Formatter from 'system/utilities/format';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import userInfo from 'etc/userInfo';
import ReportListView from 'app/reports/views/lockbox/lockboxReportListType';
import ReportGroupView from 'app/reports/views/lockbox/lockboxReportGroupType';
import LockboxReportApi from 'app/reports/api/lockboxReports';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import moment from 'moment';
import lockboxReportWrapperTmpl from './lockboxReportWrapper.hbs';

const LockboxReportWrapper = Layout.extend({
    template: lockboxReportWrapperTmpl,

    ui: {
        $panel: '.panel',
        $heading: '.panel-heading',
        $viewType: '[data-hook="viewType"]',
        $switch: '.view-switcher .btn',
        $btnwrapper: '.btn-wrapper',
    },

    events: {
        'click @ui.$heading': 'togglePanel',
        'click @ui.$switch': 'toggleReportView',
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
    },

    initialize(options) {
        this.reportSettings = options.reportSettings;
        this.defaultView = options.defaultView ? options.defaultView : 'group';
    },

    onRender() {
        this.showReport(this.defaultView);
    },

    showReport(defaultView) {
        const dateRange = this.reportSettings.get('VALUE_DATE').split(' - ');

        const options = {
            defaultView,

            dates: {
                value: this.reportSettings.get('VALUE_DATE'),
                min: Formatter.formatDate(moment(dateRange[0], userInfo.getDateFormat()), 'YYYYMMDD'),
                max: Formatter.formatDate(moment(dateRange[1], userInfo.getDateFormat()), 'YYYYMMDD'),
                minUnFormatted: dateRange[0],
                maxUnFormatted: dateRange[1],
            },

            selectedLockboxIds: this.reportSettings.get('VALUE_LOCKBOXLIST'),
            selectedTypeNames: this.reportSettings.get('VALUE_TRANSACTION_TYPES'),
            flexModel: this.options.flexModel,
        };

        this.reportView = defaultView === 'group' ? new ReportGroupView(options) : new ReportListView(options);
        this.contentRegion.show(this.reportView);
    },

    togglePanel(event) {
        const $target = $(event.currentTarget);

        $target.find('span[class^=icon-]').first().toggleClass('icon-caret-down icon-caret-up');
    },

    toggleReportView(event) {
        const $target = $(event.currentTarget);
        let text = locale.get('LBX.GroupedView');

        if ($target.hasClass('btn-list-view')) {
            text = locale.get('LBX.ListView');
            this.defaultView = 'list';
            this.ui.$btnwrapper.hide();
        } else {
            this.defaultView = 'group';
            this.ui.$btnwrapper.show();
        }

        this.ui.$switch.removeClass('active');
        $target.addClass('active');

        this.ui.$viewType.text(text);
        this.showReport(this.defaultView);
    },

    export() {
        if (this.exceedsExportRecordLimit()) {
            return;
        }

        const exportData = this.buildExportData('CSV');
        LockboxReportApi.doExport(this, exportData);
    },

    print() {
        if (this.exceedsExportRecordLimit()) {
            return;
        }

        const exportData = this.buildExportData('PDF');
        const printModal = new PrintViewModal({
            exportModel: exportData,
        });
        dialog.custom(printModal);
    },

    exceedsExportRecordLimit() {
        const exportLimit = parseInt(this.getMaxListViewExportRecords(), 10);
        let totalCount = 0;
        if (this.reportView.isDefaultView === 'group') {
            util.each(this.reportView.lockboxLocationsData, (location) => {
                totalCount += parseInt(location.NUM_PAYMENTS, 10);
            });
        } else {
            totalCount = this.reportView.numPayments;
        }

        if (totalCount > exportLimit) {
            dialog.error(locale.get('export.lockboxexceed.error', exportLimit), locale.get('export.lockboxexceed.title'));
            return true;
        }

        return false;
    },

    getMaxListViewExportRecords() {
        return serverConfigParams.get('LockBoxExportReportMaxRows') || 5000;
    },

    buildExportData(format) {
        let gridView;
        let inquiryId = 22599;
        if (this.reportView.isDefaultView === 'group') {
            // build Mock gridView for exporting/printing grouped Lockboxes
            let totalRows = 0;
            util.each(this.reportView.lockboxLocationsData, (location) => {
                totalRows += parseInt(location.NUM_PAYMENTS, 10);
            });
            gridView = {};
            gridView.wrapper = {};
            gridView.wrapper.rows = {};
            gridView.wrapper.rows.totalCount = totalRows;
            gridView.context = {};
            gridView.context.productCode = 'GIR';
            gridView.context.functionCode = 'LOCKBOX';
            gridView.context.typeCode = 'LBX_RPT';
            inquiryId = 22610;
        } else {
            ({ gridView } = this.reportView);
        }

        const exportData = LockboxReportApi.buildExportData(format, inquiryId, gridView);

        if (!exportData.searchFields) {
            exportData.searchFields = [];
        }

        let searchField = {};
        const types = util.pluck(this.reportSettings.get('VALUE_TRANSACTION_TYPES'), 'value');
        searchField.operator = 'IN';
        searchField.fieldValue = this.reportSettings.get('VALUE_LOCKBOXLIST');
        searchField.dataType = 'numeric';
        searchField.fieldName = 'LOCKBOX_ID';
        exportData.searchFields.push(searchField);

        searchField = {};
        searchField.operator = 'IN';
        searchField.fieldValue = types;
        searchField.dataType = 'TEXT';
        searchField.fieldName = 'ITEM_TYPE_CODE';
        exportData.searchFields.push(searchField);

        exportData.customFilters = [{
            filterName: 'Depends',

            filterParam: [
                'minDepositDate',
                this.reportView.depositDates.min,
            ],
        }, {
            filterName: 'Depends',

            filterParam: [
                'maxDepositDate',
                this.reportView.depositDates.max,
            ],
        }];

        return exportData;
    },

    templateHelpers() {
        const self = this;
        return {
            reportDate() {
                const dateRange = self.reportSettings.get('VALUE_DATE').replace(/ /g, '').split('-');
                const [min] = dateRange;
                const max = dateRange[1];

                if (min === max) {
                    return min;
                }
                return `(${min} - ${max})`;
            },

            isGroupView() {
                return self.defaultView === 'group';
            },

            isListView() {
                return self.defaultView === 'list';
            },

            viewTypeTitle() {
                return self.defaultView === 'group' ? locale.get('reports.group.view') : locale.get('reports.list.view');
            },

            gridUtilityOptions: {
                hasRefresh: false,
            },
        };
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'LOCKBOXLIST',
    view: LockboxReportWrapper,
    options: {},
});

export default LockboxReportWrapper;
