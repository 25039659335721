import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import alert from '@glu/alerts';
import { log } from '@glu/core';
import services from 'services';
import store from 'system/utilities/cache';
import errorHandlers from 'system/error/handlers';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import ContextApi from 'common/dynamicPages/api/context';
import ContextModel from 'common/dynamicPages/models/context';
import entitlements from 'common/dynamicPages/api/entitlements';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import constants from 'common/dynamicPages/api/constants';
import RejectDialog from 'common/dynamicPages/views/rejectDialog';
import WarningDialog from 'common/dynamicPages/views/warningDialog';
import DynamicReportUtil from 'app/reports/views/dynamicReports';
import ReportConstants from 'app/reports/constants';
import Formatter from 'system/utilities/format';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import userInfo from 'etc/userInfo';
import scroll from 'common/util/scroll';
import DuplicateDialog from 'common/dynamicPages/views/duplicateDialog';
import BeneUpdatedWarning from 'common/dynamicPages/views/beneUpdatedApprovalWarning';
import printExportUtil from 'common/util/printExportUtil';
import listViewConfig from 'common/util/listViewConfig/listViewConfig';
import filterUtil from 'common/util/filterUtil';
import lvcConstants from 'common/util/listViewConfig/listViewConfigConstants';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import loadingTemplate from 'common/templates/loadingPage.hbs';
// import gridActionPopupManager from 'app/utilities/gridActionPopup/gridActionPopupManager';
import gridApiRequired from 'common/dynamicPages/api/grid';
import PrintChecksModalView from 'pcmMdf/app/payments/views/printChecks'; // eslint-disable-line
import template from './list.hbs';

// The "double extend" lets us pull in the printExportUtil functions, as well
const desktopList = Layout.extend(util.extend({}, printExportUtil, {
    template,
    loadingTemplate,
    className: 'full-page',

    events: {
        'click [data-hook="insert-button"]': 'insert',
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
        'click [data-hook="refresh-button"]': 'refreshData',
    },

    constructor: function ListView(...args) {
        Layout.prototype.constructor.apply(this, args);
    },

    initialize(options) {
        this.options = options;
        this.entitlements = {};
        this.init();
    },

    init() {
        this.gridApi = gridApiRequired;
        this.contextDef = ContextApi.menuContext.getContext(this.options.context);
        if (this.options.additonalLVCKey) {
            this.contextDef.additonalLVCKey = this.options.additonalLVCKey;
        }

        if (!this.contextDef.serviceName && this.options.serviceName) {
            this.contextDef.serviceName = this.options.serviceName;
        }
        this.disableFilters = this.options.disableFilters;
        this.disableSort = this.options.disableSort;
        this.enableSavedViews = this.options.enableSavedViews;
        this.enableDateTimeSupport = this.options.enableDateTimeSupport;
        if (typeof this.enableSavedViews === 'undefined' || this.enableSavedViews === null) {
            // keeping it to the original true value if nothing is in the options
            this.enableSavedViews = true;
        }

        this.contextModel = new ContextModel({
            menuCategory: this.options.menuCategory,
            serviceName: this.options.serviceName,
            serviceFunc: this.options.serviceFunc,
            businessType: this.options.businessType,
            context: this.options.context,
            contextDef: this.contextDef,
        });

        this.contextKey = this.contextModel.getContextKey();
        this.localeKey = this.contextModel.getLocaleKey();
        this.gridLoaded = false;

        if (this.options.returnRoute) {
            store.set(`${this.contextKey}-listRoute`, this.options.returnRoute);
            // MDF uses workspace helper to get return route from this nvp.
            if (!mobileUtil.isMobileGridEnabled()) {
                store.set('current-workspace-route', this.options.returnRoute);
            }
        }

        this.setNotificationData({
            title: this.contextDef.serviceName,
        });

        this.setListViewConfig();
        if (this.options.viewId) {
            this.lvc.reset(true);
            this.lvc.set(lvcConstants.VIEWID, this.options.viewId, true);
        } else if (this.options.lvcAlwaysReset) {
            this.lvc.searchRefresh();
        }
        /* If there are additional search fields for server side filters,
         * then store in listView config (grid state)
         */
        if (this.options.additionalSearchFields) {
            this.lvc.set(lvcConstants.ADDITIONALSEARCH, this.options.additionalSearchFields);
        } else {
            this.lvc.remove(lvcConstants.ADDITIONALSEARCH);
        }
    },

    /**
     * creates the listViewConfig and sets the member lvc to it
     * @param {object} optional context
     * @return {object} returns the listViewConfig
     */
    setListViewConfig(context) {
        this.lvc = listViewConfig.getInstance(this, context);
        return this.lvc;
    },

    setWidget(widget) {
        this.listenTo(widget.model, 'change:size', this.handleWidgetSizeChange);
    },

    handleWidgetSizeChange() {
        if (this.hasLoadedRequiredData()) {
            util.defer(() => {
                this.gridView.grid.tableView.setWidth();
            });
        }
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            if (mobileUtil.isMobileGridEnabled()) {
                let MobileList = this.mobileGridView || desktopList;
                const mobileList = configureMobileInterface(
                    MobileList,
                    this.mobileListViewOptions,
                );
                MobileList = MobileList.extend(mobileList);
                this.gridRegion.show(new MobileList({
                    ...this.options,
                    ...this.gridViewOptions,
                    renderMobileGridUsingTemplate: false,
                    prebuiltOptions: true,
                    gridView: this.gridView,
                }));
                this.$('#complete').hide();
            } else {
                this.gridRegion.show(this.gridView);
            }
            this.gridView.wrapper.entitlements = this.entitlements;
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
            this.renderMessage(store.get(`${this.contextKey}-alertMessage`), store.get(`${this.contextKey}-confirms`));
            store.set(`${this.contextKey}-alertMessage`, null);
            store.set(`${this.contextKey}-confirms`, null);
            this.updateRefreshTimestamp();
        } else {
            this.loadViewRequirements();
        }
    },

    updateRefreshTimestamp(inView) {
        // update refresh timestamp
        let timestamp = '';

        const $refreshEl = this.$('[data-hook="refresh-time"]');

        if (this.gridView && this.gridView.getRows()) {
            timestamp = this.gridView.getRows().lastRefreshTimestamp;
        } else if (inView && inView.getRows()) {
            timestamp = inView.getRows().lastRefreshTimestamp;
        }
        if (!util.isNullOrUndefined(timestamp)) {
            if ($refreshEl && $refreshEl.length > 0) {
                $refreshEl.text(`${Formatter.formatDate(timestamp)} ${Formatter.formatTime(timestamp)}`);
            }
        }
    },


    handleInvalidSavedView() {
        this.alertRegion.show(alert.negative(locale.get('common.invalid.savedView'), {
            canDismiss: true,
        }));
    },

    hasBulkActionEntitlements() {
        // return true if the user is entitled to perform bulk actions on grid rows
        return this.hasEntitlement(constants.ACTION_APPROVE) ||
            this.hasEntitlement(constants.ACTION_UNAPPROVE) ||
            this.hasEntitlement(constants.ACTION_REJECT) ||
            this.hasEntitlement(constants.ACTION_DELETE) ||
            this.hasEntitlement(constants.ACTION_RESTORE);
    },

    secondaryGridActionOptions: {
        hasRefresh: true,
    },

    templateHelpers() {
        const self = this;
        return {
            getString(type) {
                return locale.get(self.localeKey + type);
            },
            getButtonString(type) {
                const key = `${self.localeKey}.button_${type}`;
                let retStr = locale.get(key);
                if (retStr === (`??${key}??`)) {
                    retStr = locale.get(`${self.localeKey.split('.')[0]}.button_${type}`);
                }
                return retStr;
            },
            context() {
                return self.contextKey;
            },
            hasApproveEntitlement: self.hasEntitlement(constants.ACTION_APPROVE),
            hasAssignEntitlement: self.hasEntitlement(constants.ACTION_ASSIGN),
            hasDeleteEntitlement: self.hasEntitlement(constants.ACTION_DELETE),
            hasInsertEntitlement: self.hasEntitlement(constants.ACTION_INSERT),
            hasModifyEntitlement: self.hasEntitlement(constants.ACTION_MODIFY),
            hasRejectEntitlement: self.hasEntitlement(constants.ACTION_REJECT),
            hasRestoreEntitlement: self.hasEntitlement(constants.ACTION_RESTORE),
            hasUnapproveEntitlement: self.hasEntitlement(constants.ACTION_UNAPPROVE),
            hasBulkActionEntitlements: self.hasBulkActionEntitlements(),
        };
    },

    loadCharts() {
        // nothing to see here, move along
    },

    entitlementPromiseSuccess(result) {
        this.entitlements = result.actions;
        if (mobileUtil.isMobileGridEnabled() && !this.renderMobileGridUsingTemplate) {
            return result.actions;
        }
        this.setHasLoadedRequiredData(true);
        // Wait for the grid to be loaded so that the view is present for refresh timestamp
        this.listenTo(this.gridView, 'gridapi:loaded', this.updateRefreshTimestamp);
        this.listenForGridErrors();
        this.render();
        return result.actions;
    },

    setupGridAvailableListener() {
        this.listenTo(this.gridView, 'grid:available', () => {
            filterUtil.displayFilterBadges(this);
            this.listenTo(this.gridView.grid, 'grid:columnResizeComplete', () => {
                const columns = this.gridView.grid.columns.models;
                this.lvc.setColumns(columns);
            });
        });
    },

    loadViewRequirements() {
        /**
         * we need to requiry gridApi here rather than at the top b/c it causes a
         * circular reference with the introduction of an mdf widget that contains a listview
         */
        const gridApi = gridApiRequired;
        const viewOptions = (this.options.prebuiltOptions) ? this.options :
            this.viewOptionRequirements();
        this.gridViewOptions = viewOptions;
        this.gridView = gridApi.createServiceGridView(viewOptions);

        if (this.options.skipEntitlements) {
            this.entitlementPromiseSuccess({ actions: 'skipped' });
            return '';
        }

        const entitlementPromise = entitlements.getEntitlements(viewOptions);
        this.setupGridAvailableListener();

        return entitlementPromise.then(
            result => this.entitlementPromiseSuccess(result),
            util.bind(errorHandlers.loading, this),
        );
    },

    viewOptionRequirements() {
        // const self = this;
        const options = {
            context: this.contextDef,
            // default grid action buttons are hidden
            hideButtons: true,
            // instead of executing context actions, grid handles callback
            enableRowContextButtonCallbacks: true,
            enableSavedViews: this.enableSavedViews,
            enableDateTimeSupport: this.enableDateTimeSupport || false,
            additionalSearchFields: this.options.additionalSearchFields,
            requestParameters: this.options.requestParameters,
            selector: this.options.selector || 'rowSelector',
            filter: (typeof this.options.filter === 'undefined') ? true : this.options.filter,
            loadedCallback: () => {
                this.gridLoaded = true;

                // removed per Colin on 9/22
                // self.loadCharts();

                // removed per Matt on 10/9 NH-46613
                // focus on alert message (if applicable)
                // scroll.scrollToFirstError();
            },
            viewId: this.options.viewId,
            dateFormat: userInfo.getDateFormat(),
            suppressServicesUrlSuffix: this.options.suppressServicesUrlSuffix,
            isFileLevelWorkflow: this.options.isFileLevelWorkflow,
            disableDrillDown: this.options.disableDrillDown || false,
            lvc: this.lvc,
            customFormatters: this.options.customFormatters,
            /*
             * stickyFooter by default is `true` but this lets it flow through to the
             * grid options if it is specifically defined as `false`
             */
            stickyFooter: this.options.stickyFooter !== false,
        };

        if (this.options.sortKey && this.options.sortOrder) {
            options.sortKey = this.options.sortKey;
            options.sortOrder = this.options.sortOrder;
        }

        if (this.options.cellViews) {
            options.cellViews = this.options.cellViews;
        }

        return options;
    },

    /**
     *  @method: scrollToAlert
     *  Scrolls to the alert section in the view, and sets focus on it
     *  Used in callback after view finishes loading content
     */
    scrollToAlert() {
        // check if alert message has content there to scroll to
        if (this.alertRegion && this.alertRegion.$el) {
            const alertRegionEl = this.alertRegion.$el[0];
            setTimeout(() => {
                scroll.scrollToRegion(alertRegionEl);
                // focus on alert region
                this.$(alertRegionEl).attr('tabindex', -1).focus();
            }, 0);
        }
    },


    gridRowSelect(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo(this.contextModel.getViewUrl());
        return Promise.resolve();
    },

    gridRowModify(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo(this.contextModel.getModifyUrl());
        return Promise.resolve();
    },

    gridRowRepair(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo(this.contextModel.getRepairUrl());
        return Promise.resolve();
    },

    gridRowDelete(options) {
        const self = this;
        // adding options.wait = true, will wait for server response before removal
        return new Promise((resolve) => {
            options.model.destroy({
                wait: 'true',
                success(model, resp) {
                    if (resp.resultType === 'WARNING') {
                        dialog.custom(new WarningDialog({
                            model,
                            methodName: constants.ACTION_DELETE,
                            grid: self.gridView,
                            confirms: resp.confirms,
                        }));
                    } else {
                        self.gridView.refreshGridData();
                        if (self.contextKey.indexOf('payments_smb') !== -1) {
                            const paymentType = util.findWhere(
                                resp.confirms.confirmResults[0].confirmData[0].item,
                                {
                                    name: 'Payment Type:',
                                },
                            );
                            paymentType.name = `${locale.get('SMBPAY.PaymentMethod')}:`;
                        }
                        if (self.contextKey.indexOf('panelfilter') !== -1) {
                            const panelProfileCode = util.findWhere(
                                resp.confirms.confirmResults[0].confirmData[0].item,
                                {
                                    name: 'Panel Profile Code',
                                },
                            );
                            if (panelProfileCode) {
                                panelProfileCode.name = `${locale.get('panelfilter.panelworkflowcode')}:`;
                            }
                            const panelProfileDesc = util.findWhere(
                                resp.confirms.confirmResults[0].confirmData[0].item,
                                {
                                    name: 'Description',
                                },
                            );
                            if (panelProfileDesc) {
                                panelProfileDesc.name = `${locale.get('panelfilter.panelworkflowdescription')}:`;
                            }
                        }
                        if (self.contextKey.indexOf('panelassignment') !== -1) {
                            const panelProfCode = util.findWhere(
                                resp.confirms.confirmResults[0].confirmData[0].item,
                                {
                                    name: 'profile Code',
                                },
                            );
                            if (panelProfCode) {
                                panelProfCode.name = `${locale.get('panelfilter.panelworkflowcode')}:`;
                            }
                        }
                        self.renderMessage(options.action, resp);
                        resolve();
                    }
                },
                error: this.showResponseError.bind(self, options.action),
            });
        });
    },

    generateKeyValuePairs(model, typeCode, ptxId) {
        return {
            tnum: model.get('TNUM'),
            status: model.get('STATUS'),
            printStatus: model.get('PRINTSTATUS'),
            PTXId: ptxId,
            updateCount: model.get('UPDATECOUNT__'),
            entryMethod: model.get('ENTRYMETHOD'),
            typeCode,
        };
    },

    performCheckBatchPrintOrReprintAction(
        keyValuesPairArray,
        isReprintBatchAction,
        isReprintCheckAction,
        entryMethod,
        singleCheckForReprint,
    ) {
        const self = this;
        this.printView = new PrintChecksModalView({
            isModal: true,
            isReprintBatch: isReprintBatchAction,
            isReprintCheck: isReprintCheckAction,
            paymentListModel: self,
            isActionTriggeredFromPaymentsList: true,
            checkBatchesData: keyValuesPairArray,
            checkModel: singleCheckForReprint,
            entryMethod,
        });
        dialog.open(self.printView);
    },

    gridRowPrintChecks(options) {
        const printKeyValuesPairArray = [];
        const { model } = options;
        const { typeCode } = model.context.actionData;
        const { entryMethod } = model.context.actionData;
        const ptxId = model.get('PTX_BATCH_ID');
        const keyValuePairs = this.generateKeyValuePairs(
            model,
            typeCode,
            ptxId,
        );
        const isReprintBatch = false;
        const isReprintCheck = false;
        printKeyValuesPairArray.push(keyValuePairs);
        this.performCheckBatchPrintOrReprintAction(
            printKeyValuesPairArray,
            isReprintBatch,
            isReprintCheck,
            entryMethod,
        );
    },

    gridRowReprintChecks(options) {
        const reprintKeyValuesPairArray = [];
        const { model } = options;
        const { typeCode } = model.context.actionData;
        const ptxId = model.get('PTX_BATCH_ID');
        const { entryMethod } = model.context.actionData;
        const keyValuePairs = this.generateKeyValuePairs(
            model,
            typeCode,
            ptxId,
        );
        const isReprintBatch = true;
        const isReprintCheck = false;
        reprintKeyValuesPairArray.push(keyValuePairs);
        this.performCheckBatchPrintOrReprintAction(
            reprintKeyValuesPairArray,
            isReprintBatch,
            isReprintCheck,
            entryMethod,
        );
    },

    gridRowApprove(options) {
        const self = this;
        return new Promise((resolve, reject) => {
            options.model.approve({
                success(model, resp) {
                    if (resp.resultType === 'WARNING') {
                        if (resp.errorCode === 514) {
                            self.viewDisclosure(model);
                        } else if (resp.errorCode === 540) {
                            dialog.custom(new DuplicateDialog({
                                model,
                                resp,
                                grid: self.gridView,
                                methodName: 'APPROVE',
                            }));
                        } else if (resp.errorCode === constants.BENE_UPDATED_ERROR_CODE) {
                            dialog.custom(new BeneUpdatedWarning({
                                model,
                                isMultiApprove: false,
                                grid: self.gridView,
                                resp,
                            }));
                        } else {
                            dialog.custom(new WarningDialog({
                                model,
                                methodName: 'APPROVE',
                                grid: self.gridView,
                                confirms: resp.confirms,
                            }));
                        }
                    } else {
                        self.gridView.refreshGridData();
                        if (options.model.get('ENTRYDESC') === 'Expense Reimbursement') {
                            const paymentType = util.findWhere(
                                resp.confirms.confirmResults[0].confirmData[0].item,
                                {
                                    name: 'Payment Type:',
                                },
                            );
                            paymentType.value = options.model.get('ENTRYDESC');
                        }
                        if (self.contextKey.indexOf('panelfilter') !== -1) {
                            const panelProfCode = util.findWhere(
                                resp.confirms.confirmResults[0].confirmData[0].item,
                                {
                                    name: 'Panel Profile Code',
                                },
                            );
                            if (panelProfCode) {
                                panelProfCode.name = `${locale.get('panelfilter.panelworkflowcode')}:`;
                            }
                        }
                        self.renderMessage(options.action, resp);
                        resolve({ model, result: resp });
                    }
                },
                error(resp) {
                    self.gridView.refreshGridData();

                    /*
                     * Pass resp now and check if the object has message.  If it does the
                     * JSON objectneeds to be parsed to access the correct confirmation object.
                     * Otherwise pass undefined which is basically what occurred previously.
                     */
                    self.renderMessage(options.action, resp.message ?
                        JSON.parse(resp.message) : undefined);

                    reject(resp);
                },
            });
        });
    },

    viewDisclosure(model) {
        const printOptions = {
            tnum: model.get('TNUM'),
            outputFormat: 'PDF',
            pageType: 'LETTER',
            viewId: 'WIREINTLDISCLOSURE',
            serviceCode: 'WIREINTLDISCLOSURE',
            dynamicReportURL: `${ReportConstants.WIRE_DISCLOSURE_PREFIX}reportView`,
            isApprovalDisclosure: true,
            approvalDisclosureModel: model,
            approvalDisclosureGrid: this.gridView,
        };
        DynamicReportUtil.print(printOptions);
    },

    viewDisclosureForMulti(modelsArray) {
        const model = this.gridView.wrapper.rows.get(modelsArray[0]);
        const options = {
            tnum: model.get('TNUM'),
            outputFormat: 'PDF',
            pageType: 'LETTER',
            viewId: 'WIREINTLDISCLOSURE',
            serviceCode: 'WIREINTLDISCLOSURE',
            dynamicReportURL: `${ReportConstants.WIRE_DISCLOSURE_PREFIX}reportView`,
            isApprovalDisclosure: true,
            approvalDisclosureModelsArray: modelsArray,
            approvalDisclosureGrid: this.gridView,
        };
        const printModal = new PrintViewModal({
            exportModel: this.buildDisclosurePrintModel(options),
            exportURL: options.dynamicReportURL,
            title: options.title,
            tnum: options.tnum,
            serviceCode: options.serviceCode,
            formMethod: 'post',
            viewId: options.viewId,
            isApprovalDisclosure: options.isApprovalDisclosure,
            approvalDisclosureModel: options.approvalDisclosureModel,
            approvalDisclosureGrid: options.approvalDisclosureGrid,
            approvalDisclosureModelsArray: options.approvalDisclosureModelsArray,
        });
        printModal.once('list:multi:action:action_acceptDisclosure', this.actionApproveWithWarning, this);

        dialog.custom(printModal);
    },

    buildDisclosurePrintModel(options) {
        const dynRptPrintModal = {};
        dynRptPrintModal.outputFormat = options.outputFormat;
        dynRptPrintModal.pageType = options.pageType;
        dynRptPrintModal.tnum = options.tnum;
        dynRptPrintModal.serviceCode = options.serviceCode;
        dynRptPrintModal.viewId = options.viewId;
        return dynRptPrintModal;
    },

    gridRowDisable(options) {
        const self = this;
        options.model.disable({
            success(model, resp) {
                if (resp.resultType === 'WARNING') {
                    dialog.custom(new WarningDialog({
                        model,
                        methodName: 'DISABLE',
                        grid: self.gridView,
                        confirms: resp.confirms,
                    }));
                } else {
                    self.gridView.refreshGridData();
                    self.renderMessage(options.action, resp);
                }
            },
            error: this.showResponseError.bind(self, options.action),
        });
    },

    gridRowRestore(options) {
        const self = this;
        return new Promise((resolve) => {
            options.model.restore({
                success(model, resp) {
                    if (resp.resultType === 'WARNING') {
                        dialog.custom(new WarningDialog({
                            model,
                            methodName: 'RESTORE',
                            grid: self.gridView,
                            confirms: resp.confirms,
                        }));
                    } else {
                        self.gridView.refreshGridData();
                        self.renderMessage(options.action, resp);
                    }
                    resolve();
                },
                error: this.showResponseError.bind(self, options.action),
            });
        });
    },

    gridRowReverse(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo(this.contextModel.getReverseUrl());
        return Promise.resolve();
    },

    gridRowGetRate(options) {
        const self = this;
        const viewId = this.getGetRateViewId();
        return new Promise((resolve) => {
            if (self.gridView.wrapper && self.gridView.wrapper.viewId !== viewId) {
                dialog.confirm(locale.get('common.getRateWarning'), locale.get('title.warning'), (ok) => {
                    if (ok) {
                        self.gridView.viewSelectionHandlerById(viewId);
                    }
                    resolve();
                });
            } else {
                options.model.getrate({
                    success(model, resp) {
                        self.handleGetRateResponse(model, resp);
                        resolve();
                    },
                    error: this.showResponseError.bind(self, options.action),
                });
            }
        });
    },

    getGridRequestParams() {
        const { wrapper } = this.gridView;

        return wrapper ? wrapper.getRequestParams() : false;
    },

    /**
     *
     * @param requestParams array of name/value pair objects
     */
    setGridRequestParams(requestParams) {
        const { wrapper } = this.gridView;
        if (wrapper) {
            wrapper.setRequestParams(requestParams);
        }
    },

    clearGridRequestParams() {
        const { wrapper } = this.gridView;
        if (wrapper) {
            wrapper.clearRequestParams();
        }
    },

    clearGridAddlSearchFields() {
        const { wrapper } = this.gridView;
        if (wrapper) {
            wrapper.clearAddlSearchFields();
            this.lvc.remove(lvcConstants.ADDITIONALSEARCH);
        }
    },

    handleGetRateResponse(model, resp, l) {
        const self = this;
        let lParam = l;
        if (!lParam) {
            lParam = 0;
        }
        util.each(resp.items[lParam].data, (nvp) => {
            if (nvp.name.toUpperCase() === 'STATUSCODE') {
                if (nvp.value === '0') {
                    self.handleGetRateSuccessResponse(model, resp, lParam);
                } else {
                    self.handleGetRateFailureResponse(model, resp, lParam);
                }
            }
        });
    },

    handleGetRateFailureResponse(model, resp, l) {
        let lParam = l;
        if (!lParam) {
            lParam = 0;
        }
        util.each(resp.items[lParam].data, (nvp) => {
            if (nvp.name.toUpperCase() === 'STATUSMESSAGE') {
                model.set('RATETRADERESULT', nvp.value);
            } else if (nvp.name.toUpperCase() === 'UPDATECOUNT__') {
                model.set('UPDATECOUNT__', nvp.value);
            }
        });
    },

    handleGetRateSuccessResponse(model, resp, l) {
        let lParam = l;
        util.each(model.buttons, (button) => {
            const buttonParam = button;
            if (buttonParam.action === 'GETRATE') {
                buttonParam.action = 'TRADE';
                buttonParam.label = locale.get('button.trade');
                buttonParam.value = button.value.replace('getrate', 'trade');
            }
        });
        if (!lParam) {
            lParam = 0;
        }

        log.info(resp);
        const rateFields = ['RATETRADERESULT', 'EXCHANGE_RATE', 'QUOTEID', 'COUNTERVALUE'];
        let quoteExpTime = 0;
        let counterVal = 0;
        let entAmtFlag = 'C';
        util.each(resp.items[lParam].data, (nvp) => {
            if (nvp.name.toUpperCase() === 'QUOTEEXPIRATIONTIME') {
                model.set(rateFields[0], nvp.value);
                quoteExpTime = nvp.value;
            } else if (nvp.name.toUpperCase() === 'EXCHANGERATE') {
                model.set(rateFields[1], nvp.value);
            } else if (nvp.name.toUpperCase() === 'COUNTERVALUE') {
                counterVal = nvp.value;
            } else if (nvp.name.toUpperCase() === 'ENTERED_AMOUNT_FLAG') {
                entAmtFlag = nvp.value;
            } else if (rateFields.indexOf(nvp.name.toUpperCase()) > -1) {
                model.set(nvp.name.toUpperCase(), nvp.value);
            } else if (nvp.name.toUpperCase() === 'UPDATECOUNT__') {
                model.set('UPDATECOUNT__', nvp.value);
            }
        });
        if (entAmtFlag.toUpperCase() === 'C') {
            model.set('CMB_DEBIT_AMOUNT', counterVal);
        } else {
            model.set('CMB_CREDIT_AMOUNT', counterVal);
        }
        model.set('RATETRADERESULT', quoteExpTime);
    },

    handleTradeResponse(model, resp) {
        model.set('RATETRADERESULT', '');
        const self = this;
        if (resp.items === null) {
            return;
        }
        util.each(resp.items[0].data, (nvp) => {
            if (nvp.name.toUpperCase() === 'STATUSCODE') {
                if (nvp.value === '0') {
                    self.handleTradeSuccessResponse(model, resp);
                } else {
                    self.handleTradeFailureResponse(model, resp);
                }
            }
        });
    },

    handleTradeFailureResponse(model, resp) {
        model.set('RATETRADERESULT', '');
        util.each(model.buttons, (button) => {
            const buttonParam = button;
            if (buttonParam.action === 'TRADE') {
                buttonParam.action = 'GETRATE';
                buttonParam.label = 'Get Rate';
                buttonParam.value = button.value.replace('trade', 'getrate');
            }
        });
        util.each(resp.items[0].data, (nvp) => {
            if (nvp.name.toUpperCase() === 'STATUSMESSAGE') {
                model.set('RATETRADERESULT', nvp.value);
            } else if (nvp.name.toUpperCase() === 'UPDATECOUNT__') {
                model.set('UPDATECOUNT__', nvp.value);
            }
        });
    },

    handleTradeSuccessResponse(model, resp) {
        model.set('RATETRADERESULT', '');
        const rateFields = ['RATETRADERESULT', 'EXCHANGE_RATE', 'CONTRACTID', 'COUNTERVALUE'];
        let quoteId = 0;
        let counterVal = 0;
        let entAmtFlag = 'C';
        let tradeBtnIdx;
        let statusMsg;
        util.each(resp.items[0].data, (nvp) => {
            if (nvp.name.toUpperCase() === 'CONTRACTID') {
                model.set(rateFields[0], nvp.value);
                model.set('EXCHANGE_RATE_CONTRACTID', nvp.value);
                quoteId = nvp.value;
            } else if (nvp.name.toUpperCase() === 'EXCHANGERATE') {
                model.set(rateFields[1], nvp.value);
            } else if (nvp.name.toUpperCase() === 'COUNTERVALUE') {
                counterVal = nvp.value;
            } else if (nvp.name.toUpperCase() === 'ENTERED_AMOUNT_FLAG') {
                entAmtFlag = nvp.value;
            } else if (nvp.name.toUpperCase() === 'STATUSMESSAGE') {
                statusMsg = nvp.value;
            } else if (rateFields.indexOf(nvp.name.toUpperCase()) > -1) {
                model.set(nvp.name.toUpperCase(), nvp.value);
            }
        });
        if (entAmtFlag.toUpperCase() === 'C') {
            model.set('CMB_DEBIT_AMOUNT', counterVal);
        } else {
            model.set('CMB_CREDIT_AMOUNT', counterVal);
        }
        model.set('RATETRADERESULT', `${locale.get(statusMsg)} ${quoteId}`);
        util.each(model.buttons, (button) => {
            if (button.action === 'TRADE') {
                tradeBtnIdx = model.buttons.indexOf(button);
            }
        });
        model.buttons.splice(tradeBtnIdx, 1);
    },

    /**
     * @description sets listener on gridView for any grid or grid componenet errors
     *
     */
    listenForGridErrors() {
        this.listenTo(this.gridView, 'gridError', (errorMessage) => {
            if (this.alertRegion) {
                this.alertRegion.show(alert.negative(errorMessage, {
                    canDismiss: true,
                }));
            }
        });
    },

    gridRowTrade(options) {
        const self = this;
        return new Promise((resolve) => {
            options.model.trade({
                success(model, resp) {
                    self.handleTradeResponse(model, resp);
                    resolve();
                },
                error: this.showResponseError.bind(self, options.action),
            });
        });
    },

    gridRowUnapprove(options) {
        return new Promise((res) => {
            const self = this;
            options.model.unapprove({
                success(model, resp) {
                    if (resp.resultType === 'WARNING') {
                        dialog.custom(new WarningDialog({
                            model,
                            methodName: 'UNAPPROVE',
                            grid: self.gridView,
                            confirms: resp.confirms,
                        }));
                    } else {
                        self.gridView.refreshGridData();
                        if (options.model.get('ENTRYDESC') === 'Expense Reimbursement') {
                            const paymentType = util.findWhere(
                                resp.confirms.confirmResults[0].confirmData[0].item,
                                {
                                    name: 'Payment Type:',
                                },
                            );
                            paymentType.value = options.model.get('ENTRYDESC');
                        }
                        self.renderMessage(options.action, resp);
                    }
                    res({ model, response: resp });
                },
                error: this.showResponseError.bind(self, options.action),
            });
        });
    },


    gridRowReject(options) {
        const self = this;
        return new Promise((resolve, reject) => {
            options.model.reject({
                success(model, resp) {
                    if (resp.resultType === 'WARNING') {
                        dialog.custom(new WarningDialog({
                            model,
                            methodName: constants.ACTION_REJECT,
                            grid: self.gridView,
                            confirms: resp.confirms,
                        }));
                    } else {
                        self.gridView.refreshGridData();
                        if (self.contextKey.indexOf('payments_smb') !== -1) {
                            const paymentType = util.findWhere(
                                resp.confirms.confirmResults[0].confirmData[0].item,
                                {
                                    name: 'Payment Type:',
                                },
                            );
                            paymentType.name = `${locale.get('SMBPAY.PaymentMethod')}:`;
                        }
                        self.renderMessage(options.action, resp);
                        resolve();
                    }
                    if (options.promise) {
                        options.promise.resolve();
                    }
                },
                error() {
                    this.showResponseError.bind(self, options.action);
                    reject();
                    if (options.promise) {
                        options.promise.reject();
                    }
                },
            });
        });
    },

    /**
     * Called by gridRowAction when none of the known actions are recognized
     * @abstract
     * @param {object} options - the option object
     * @param {string} options.action - the action string
     * @param {Model} options.model - the model for the row
     */
    gridRowCustomAction() /* options */ {
        // Override gridRowCustomAction to do something
        return Promise.resolve();
    },

    gridRowAction(options) {
        const action = options.action.toUpperCase();

        const functionMap = {
            APPROVE: this.gridRowApprove,
            ARCHIVE: this.gridRowArchive,
            ASSIGN: this.gridRowAssign,
            COPYINST: this.gridPaymentFromTemplate,
            COPYTMPL: this.gridTemplateFromPayment,
            DELETE: this.gridRowDelete,
            DISABLE: this.gridRowDisable,
            DOWNLOAD: this.gridDownload,
            ENABLE: this.gridRowEnable,
            EXPORT: this.gridRowExport,
            FOUND: this.gridRowFound,
            GETRATE: this.gridRowGetRate,
            LOST: this.gridRowLost,
            MAKEINST: this.gridPaymentFromTemplate,
            MAKETMPL: this.gridTemplateFromPayment,
            MISPLACE: this.gridRowMisplace,
            EMAIL: this.gridRowEmail,
            MODIFY: this.gridRowModify,
            MODIFYVFIMPORT: this.gridRowModifyvFImport,
            NTEMPLATE: this.gridNotificationFromTemplate,
            PAY: this.gridRowPay,
            QENTRY: this.gridRowQEntry,
            REJECT: this.gridRowReject,
            REPAIR: this.gridRowRepair,
            REPLACE: this.gridRowReplace,
            RESETPASSWORD: this.gridRowResetPassword,
            RESTORE: this.gridRowRestore,
            REVERSE: this.gridRowReverse,
            SELECT: this.gridRowSelect,
            SPAYMT: this.gridRowSchedule,
            TNOTIFICATION: this.gridTemplateFromNotification,
            PRINTCHECKS: this.gridRowPrintChecks, // PCM
            REPRINTCHECKS: this.gridRowReprintChecks, // PCM
            VOIDCHECK: this.gridRowVoidCheck, // PCM
            STOPCHECK: this.gridRowStopCheck, // PCM
            REPRINTCHECK: this.gridRowReprintCheckFromTransactionList,
            TRADE: this.gridRowTrade,
            UNAPPROV: this.gridRowUnapprove,
            UNAPPROVE: this.gridRowUnapprove,
            UNASSIGN: this.gridRowUnassign,
            UNLOCK: this.gridRowUnlock,
            UPDCHKST: this.gridRowModify, // PCM-6125
        };

        const useFunction = functionMap[action];

        if (this.alertRegion) {
            this.alertRegion.close();
        }

        if (useFunction) {
            return useFunction.call(this, options);
        }
        return this.gridRowCustomAction(options);
    },

    translateActionText(action) {
        let translatedAction = locale.get(`action.${action}`);
        if (translatedAction.indexOf('??') > -1) {
            translatedAction = action;
        }
        return translatedAction;
    },

    handleBulkAction(methodName, args) {
        let argsParam = args;
        if (this.alertRegion) {
            this.alertRegion.close();
        }
        if (this.gridLoaded) {
            const self = this;
            const ar = this.gridView.grid.getSelectedRows();
            let eligibleRows = ar;

            if (ar.length > 0) {
                let message = '';
                let conintlmsg = '';
                let initPrefundingMessage = '';
                let initPrefundingCount = 0;
                if (methodName === constants.ACTION_APPROVE) {
                    for (let i = 0; i < ar.length; i += 1) {
                        const model = this.gridView.wrapper.rows.get(ar[i]);
                        let approvalFlag = false;
                        for (let j = 0; j < model.buttons.length; j += 1) {
                            if (model.buttons[j].action === 'APPROVE') {
                                approvalFlag = true;
                                if (model.get('INITIATEPREFUND') === '1') {
                                    initPrefundingCount += 1;
                                }
                            }
                        }
                        if (!approvalFlag) {
                            eligibleRows = util.without(eligibleRows, ar[i]);
                            message = locale.get('common.approval.eligible.error');
                        }
                        // consumer international payment
                        if (model.get('CONINTLWIREFLAG') === '1' && ar.length > 1) {
                            eligibleRows = util.without(eligibleRows, ar[i]);
                            conintlmsg = locale.get('common.approval.conintl.error');
                        }
                    }
                }

                if (methodName === constants.ACTION_DELETE
                    || methodName === constants.ACTION_RESTORE) {
                    for (let q = 0; q < ar.length; q += 1) {
                        const deleteModel = this.gridView.wrapper.rows.get(ar[q]);
                        let deleteFlag = false;
                        for (let r = 0; r < deleteModel.buttons.length; r += 1) {
                            if (deleteModel.buttons[r].action === methodName) {
                                deleteFlag = true;
                            }
                        }
                        if (!deleteFlag) {
                            eligibleRows = util.without(eligibleRows, ar[q]);
                            message = (methodName === constants.ACTION_DELETE)
                                ? locale.get('PAY.multi.delete.warning')
                                : message = locale.get('PAY.multi.restore.warning');
                        }
                    }
                }

                if (methodName === constants.ACTION_REJECT) {
                    for (let k = 0; k < ar.length; k += 1) {
                        const rejectModel = this.gridView.wrapper.rows.get(ar[k]);
                        let rejectFlag = false;
                        for (let l = 0; l < rejectModel.buttons.length; l += 1) {
                            if (rejectModel.buttons[l].action === 'REJECT') {
                                rejectFlag = true;
                            }
                        }
                        if (!rejectFlag) {
                            eligibleRows = util.without(eligibleRows, ar[k]);
                            message = locale.get('PAY.multi.reject.warning');
                        }
                    }
                }

                if (methodName === constants.ACTION_UNAPPROVE) {
                    for (let x = 0; x < ar.length; x += 1) {
                        const unApproveModel = this.gridView.wrapper.rows.get(ar[x]);
                        let unApprovalFlag = false;
                        for (let y = 0; y < unApproveModel.buttons.length; y += 1) {
                            if (unApproveModel.buttons[y].action === 'UNAPPROV') {
                                unApprovalFlag = true;
                            }
                        }
                        if (!unApprovalFlag) {
                            eligibleRows = util.without(eligibleRows, ar[x]);
                        }
                    }
                }

                if (methodName === constants.ACTION_RETURN ||
                    methodName === constants.ACTION_PAY ||
                    methodName === constants.ACTION_PAYISSUE) {
                    for (let m = 0; m < ar.length; m += 1) {
                        const decisionModel = this.gridView.wrapper.rows.get(ar[m]);
                        let decisionFlag = false;
                        for (let n = 0; n < decisionModel.buttons.length; n += 1) {
                            if (decisionModel.buttons[n].action === methodName) {
                                decisionFlag = true;
                            }
                        }
                        if (!decisionFlag) {
                            eligibleRows = util.without(eligibleRows, ar[m]);
                            this.eligibleRows = eligibleRows;
                            message = locale.get('alerts.multi.decisioning.warning');
                        }
                    }
                }

                if (methodName !== constants.ACTION_TRADE &&
                    methodName !== constants.ACTION_GETRATE) {
                    const translatedAction = this.translateActionText(methodName);
                    if (conintlmsg) {
                        dialog.error(conintlmsg, 'Error');
                        return;
                    }
                    dialog.confirm(`${message + locale.get('title.ConfirmActionPrefix')} <span class="method-name">${translatedAction}</span> ${locale.get('title.ConfirmActionSuffix')}`, `${locale.get('title.confirm')} ${translatedAction}`, (ok) => {
                        if (ok) {
                            self.updateSelectedModels(methodName, argsParam);
                            /**
                             * fix for (MFA) NH-23171, NH-23646, NH-20477. Look
                             * for those products where the processMultiActionsAsOne applies
                             * Eventually all actions from the Grid
                             * (whether MFA is enabled or not) should use the
                             * method processMultiActionsAsOne.
                             */
                            if (
                                self.runProcessMultiActionsAsOne(
                                    self.contextDef.productCode
                                       || self.contextDef.actionContext.productCode,
                                    self.contextDef.typeCode
                                       || self.contextDef.actionContext.typeCode,
                                ) || (
                                    self.context &&
                                    self.runProcessMultiActionsAsOne(
                                        self.context.productCode
                                            || self.contextDef.actionContext.productCode,
                                        self.context.typeCode
                                            || self.contextDef.actionContext.typeCode,
                                    )
                                )
                            ) {
                                if (methodName === constants.ACTION_APPROVE) {
                                    if (!argsParam) {
                                        argsParam = {
                                            name: 'DRYRUNONLY',
                                            value: 'true',
                                        };
                                    }
                                }
                                if (initPrefundingCount > 0) {
                                    if (initPrefundingCount === 1) {
                                        initPrefundingMessage = locale.get('common.prefund.initiate');
                                    } else {
                                        initPrefundingMessage = locale.get('common.prefund.grid.initiate');
                                    }
                                    dialog.confirm(initPrefundingMessage, `${locale.get('title.confirm')} ${translatedAction}`, (preFundOk) => {
                                        if (preFundOk) {
                                            self.processMultiActionsAsOne(
                                                methodName,
                                                eligibleRows,
                                                argsParam,
                                            );
                                        }
                                    });
                                } else {
                                    self.processMultiActionsAsOne(
                                        methodName,
                                        eligibleRows,
                                        argsParam,
                                    );
                                }
                            } else {
                                self.bulkAction(methodName, eligibleRows);
                            }
                        }
                    });
                } else if (methodName === constants.ACTION_GETRATE) {
                    const getRateViewId = argsParam || constants.NEEDSRATEVIEWID;
                    if (self.gridView.wrapper &&
                        self.gridView.wrapper.viewId !== getRateViewId) {
                        dialog.confirm(locale.get('common.getRateWarning'), locale.get('title.warning'), (ok) => {
                            if (ok) {
                                self.gridView.viewSelectionHandlerById(getRateViewId, 'action.getrate');
                            }
                        });
                    } else {
                        const maxRequests = serverConfigParams.get('MaxGetRateRecords');
                        if (ar.length > maxRequests) {
                            dialog.alert(locale.get('RTGS.GETRATE.maxRecordsExceeded', maxRequests));
                        } else {
                            const maxRequestsPerReq = serverConfigParams.get('MaxNoOfTransactionsPerGetRateRequest');
                            const countryCurrMap = {};
                            for (let idx = 0; idx < ar.length; idx += 1) {
                                const rateModel = self.gridView.wrapper.rows.get(ar[idx]);
                                let rateEligible = false;
                                for (let p = 0; p < rateModel.buttons.length; p += 1) {
                                    if (rateModel.buttons[p].action === 'GETRATE') {
                                        rateEligible = true;
                                    }
                                }
                                if (rateEligible) {
                                    const currKey = rateModel.get('CMB_DEBIT_CURRENCY') + rateModel.get('CMB_CREDIT_CURRENCY');
                                    let paymentList = countryCurrMap[currKey];
                                    if (!paymentList) {
                                        paymentList = [];
                                    }
                                    const map = {};
                                    map.TNUM = rateModel.get('TNUM');
                                    map.TYPE = rateModel.get('TYPE');
                                    // eslint-disable-next-line
                                    map.UPDATECOUNT__ = rateModel.get('UPDATECOUNT__');
                                    paymentList.push(map);
                                    countryCurrMap[currKey] = paymentList;
                                }
                            }
                            util.map(countryCurrMap, (value) => {
                                let jsonData = {
                                    items: [],
                                };
                                let service = services.generateUrl('');
                                service += constants.URL_GETRATE_ACTION;
                                let itemCount = 0;
                                util.map(value, (thisobj) => {
                                    itemCount += 1;
                                    const jsonDataInner = {
                                        data: [],
                                    };
                                    jsonDataInner.data.push({
                                        name: 'TNUM',
                                        value: thisobj.TNUM,
                                    });
                                    jsonDataInner.data.push({
                                        name: 'TYPECODE',
                                        value: thisobj.TYPE,
                                    });
                                    jsonDataInner.data.push({
                                        name: 'UPDATECOUNT__',
                                        // eslint-disable-next-line
                                        value: thisobj.UPDATECOUNT__,
                                    });
                                    jsonData.items.push(jsonDataInner);
                                    if (itemCount >= maxRequestsPerReq) {
                                        http.post(service, jsonData, (result) => {
                                            const itemTnums = [];
                                            util.each(result.items, (item) => {
                                                util.each(item.data, (eachData) => {
                                                    if (eachData.name === 'TNUM') {
                                                        itemTnums.push(eachData.value);
                                                    }
                                                });
                                            });
                                            util.each(
                                                self.gridView.wrapper.rows.models,
                                                (row) => {
                                                    if (util.indexOf(itemTnums, row.get('TNUM')) >= 0) {
                                                        self.handleGetRateResponse(row, result, util.indexOf(itemTnums, row.get('TNUM')));
                                                    }
                                                },
                                            );
                                        }, (result) => {
                                            log.info(result);
                                        });
                                        jsonData = {
                                            items: [],
                                        };
                                        itemCount = 0;
                                    }
                                });
                                if (jsonData.items.length > 0) {
                                    http.post(service, jsonData, (result) => {
                                        const itemTnums = [];
                                        util.each(result.items, (item) => {
                                            util.each(item.data, (eachData) => {
                                                if (eachData.name === 'TNUM') {
                                                    itemTnums.push(eachData.value);
                                                }
                                            });
                                        });
                                        util.each(self.gridView.wrapper.rows.models, (row) => {
                                            if (util.indexOf(itemTnums, row.get('TNUM')) >= 0) {
                                                self.handleGetRateResponse(row, result, util.indexOf(itemTnums, row.get('TNUM')));
                                            }
                                        });
                                        log.info(result);
                                    }, (result) => {
                                        log.info(result);
                                    });
                                }
                            });
                        }
                    }
                } else if (methodName === constants.ACTION_TRADE) {
                    let service = services.generateUrl('');
                    service += constants.URL_TRADE_ACTION;
                    util.each(ar, (rowItem) => {
                        const tradeModel = self.gridView.wrapper.rows.get(rowItem);
                        // clears timeouts
                        tradeModel.set('RATETRADERESULT', '');
                        let tradeEligible = false;
                        for (let buttonIndex = 0;
                            buttonIndex < tradeModel.buttons.length;
                            buttonIndex += 1) {
                            if (tradeModel.buttons[buttonIndex].action === 'TRADE') {
                                tradeEligible = true;
                            }
                        }
                        if (!tradeEligible) {
                            return;
                        }
                        const jsonData = {
                            items: [],
                        };


                        const jsonDataInner = {
                            data: [],
                        };
                        jsonDataInner.data.push({
                            name: 'TNUM',
                            value: tradeModel.get('TNUM'),
                        });
                        jsonDataInner.data.push({
                            name: 'TYPECODE',
                            value: tradeModel.get('TYPE'),
                        });
                        jsonDataInner.data.push({
                            name: 'UPDATECOUNT__',
                            value: tradeModel.get('UPDATECOUNT__'),
                        });
                        jsonData.items.push(jsonDataInner);
                        http.post(service, jsonData, (result) => {
                            tradeModel.set('RATETRADERESULT', '');
                            self.handleTradeResponse(tradeModel, result);
                        }, (result) => {
                            log.info(result);
                        });
                    });
                }
            }
        }
    },

    convertModelAttributesToJSONForCM(model, obj) {
        const jsonData = [];
        Object.entries(obj).forEach((entry) => {
            if (Object.prototype.hasOwnProperty.call(obj, entry[0])) {
                jsonData.push({
                    name: entry[0],
                    value: model.get(entry[0]),
                });
            }
        });
        return {
            item: jsonData,
        };
    },

    /**
     * Abstract function to be overridden by classes extending this list.
     * Provides a hook to perform modification on the selected models before save.
     *
     * @param {string} method - the action/mode string
     * @param {object} [args] - optional object with key value pairs
     */
    updateSelectedModels() {

    },

    /**
     * Function to determine if the multi action on list is processed as single or bulk request
     *
     * @param {string} productCode - the productCode of the model
     * @param {string} typeCode - the typeCode of the model
     */
    runProcessMultiActionsAsOne(productCode, typeCode) {
        let res = 'notfound';
        switch (productCode) {
        case 'PAY':
        case 'USACH':
        case 'CM':
        case 'RISK':
        case 'GIR':
        case 'RTP':
            res = 'found';
            break;
        case 'RTGS':
            if (typeCode !== 'BENINTMP') {
                res = 'found';
            }
            break;
        default:
            break;
        }
        return res === 'found';
    },

    adjustMenuCtxtMultiActionsAsOne(inMenuCtxt) {
        // for SMB
        const self = this;

        let menuCtxt = inMenuCtxt;
        if (!menuCtxt && self.context) {
            menuCtxt = self.context.menuContext;
        }
        /**
         * if processing multi action as one for employees (ACH payments),
         * adjust rest service path
         */
        if (['payment/listView/smbEmployee',
            'payment/listView/smb',
            'payment/transfer',
            'payment/listView/smbTransfer',
            'payment/rtp',
        ].indexOf(menuCtxt) > -1) {
            menuCtxt = 'payment/listView/corp';
        } else if (['template/transfer',
            'template/transfer/recurring',
            'template/listView/smbEmployee',
        ].indexOf(menuCtxt) > -1) {
            menuCtxt = 'template/listView/corp';
        } else if (menuCtxt === 'payment/rtp') {
            menuCtxt = 'payment/listView/corp';
        }

        return menuCtxt;
    },

    populateCustomKeys(item) {
        return item;
    },

    /**
     * Function to handle approve method when warnings occur
     *
     * @param {string} methodName
     * @param {array} selectedArray
     * @param {Object} confirmResponse
     */
    handleMultipleApproveWarning(methodName, selectedArray, confirmResponse) {
        return this.multipleApproveWarning(methodName, confirmResponse, selectedArray);
    },

    /**
     * Function to handle delete method when warnings occur
     *
     * @param {string} methodName
     * @param {array} selectedArray
     * @param {Object} confirmResponse
     */
    handleMultipleDeleteWarning(methodName, selectedArray, confirmResponse) {
        this.multipleDeleteWarning(methodName, confirmResponse, selectedArray);
    },

    /**
     * Function to handle general multi items response
     *
     * @param {string} methodName
     * @param {array} selectedArray
     * @param {Object} confirmResponse
     */
    handleMultiItemsResponse(methodName, confirmResponse) {
        this.renderMessage(methodName, confirmResponse);
    },

    /**
     * Generate service name for multi-items POST request
     *
     * @param {string} methodName
     * @returns {string}
     */
    generateMultiServiceName(methodName) {
        let menuCtxt = this.contextDef.menuContext || this.options.menuContext;
        menuCtxt = this.adjustMenuCtxtMultiActionsAsOne(menuCtxt);
        let service = services.generateUrl(menuCtxt);
        if (methodName === constants.ACTION_APPROVE) {
            service += constants.URL_APPROVE_ACTION;
        } else if (methodName === constants.ACTION_UNAPPROVE) {
            service += constants.URL_UNAPPROVE_ACTION;
        } else if (methodName === constants.ACTION_DELETE) {
            service += constants.URL_DELETE_ACTION;
        } else if (methodName === constants.ACTION_REJECT) {
            service += constants.URL_REJECT_ACTION;
        } else if (methodName === constants.ACTION_PAYISSUE ||
            constants.ACTION_RETURN ||
            constants.ACTION_PAY) {
            service += `${constants.URL_MULTIUPDATE_ACTION}/${methodName}`;
        }
        return service;
    },

    /**
     * Generate JSON data for multi-items POST request
     *
     * @param {string} methodName
     * @param {array} selectedArray
     * @param {Object} additionalParams
     * @returns {Object}
     */
    generateMultiItemsJsonData(methodName, selectedArray, additionalParams) {
        return {
            items: selectedArray.map((item) => {
                let itemCopy = item;
                const model = this.gridView.wrapper.rows.get(itemCopy) || itemCopy;
                if (methodName === constants.ACTION_DELETE) {
                    itemCopy = model.convertModelAttributesToKeyWithOptContextJSON(model, true);
                    itemCopy = this.populateCustomKeys(itemCopy, model);
                    if (!util.isEmpty(additionalParams)) {
                        itemCopy.item.push(additionalParams);
                    }
                }
                if (methodName === constants.ACTION_APPROVE) {
                    itemCopy = model.convertModelAttributesToKeyWithOptContextJSON(model, true);
                    itemCopy = this.populateCustomKeys(itemCopy, model);
                    if (!util.isEmpty(additionalParams)) {
                        itemCopy.item.push(additionalParams);
                    }
                }
                if (methodName === constants.ACTION_UNAPPROVE) {
                    itemCopy = model.convertModelAttributesToKeyWithOptContextJSON(model, true);
                    itemCopy = this.populateCustomKeys(itemCopy, model);
                }
                if (methodName === constants.ACTION_REJECT) {
                    itemCopy = model.convertModelAttributesToKeyWithOptContextJSON(model, true);
                    itemCopy.item.push(additionalParams);
                }
                if (methodName === constants.ACTION_PAYISSUE ||
                    methodName === constants.ACTION_PAYRULE ||
                    methodName === constants.ACTION_RETURN ||
                    methodName === constants.ACTION_PAY) {
                    itemCopy = this.getCustomItem(model, methodName);
                }
                return itemCopy;
            }),
        };
    },

    getSelectedRowModels() {
        return this.gridView.grid.getSelectedRowModels();
    },

    /**
     * Handle Success response for multi-item response
     *
     * @param {string} methodName
     * @param {array} selectedArray
     * @param {Object} resp
     */
    handleMultiItemsSuccess(methodName, selectedArray, resp) {
        if (methodName === constants.ACTION_APPROVE && (resp.resultType === 'WARNING' || resp.resultType === 'DRYRUNSUCCESS')) {
            this.handleMultipleApproveWarning(methodName, selectedArray, resp);
        } else if (methodName === constants.ACTION_DELETE && resp.resultType === 'WARNING') {
            this.handleMultipleDeleteWarning(methodName, selectedArray, resp);
        } else {
            this.gridView.refreshGridData();
            // Start NH-27619 - REIMBURSE Override
            const selectedModels = this.getSelectedRowModels();

            util.each(selectedModels, (selectedModel) => {
                if (selectedModel.get('ENTRYDESC') === 'Expense Reimbursement') {
                    const paymentType = util.findWhere(
                        resp.confirms.confirmResults[0].confirmData[0].item,
                        {
                            name: 'Payment Type:',
                        },
                    );
                    if (paymentType) {
                        paymentType.value = selectedModel.get('ENTRYDESC');
                    }
                }
                if (methodName === constants.ACTION_DELETE ||
                    methodName === constants.ACTION_REJECT) {
                    const payType = util.findWhere(
                        resp.confirms.confirmResults[0].confirmData[0].item,
                        {
                            name: 'Payment Type:',
                        },
                    );
                    if (payType) {
                        payType.name = locale.get('RTGS.Payment Method');
                        if (payType.name[payType.name.length - 1] !== ':') {
                            payType.name += ':';
                        }
                    }
                }
            });
            // End of NH-27619

            this.handleMultiItemsResponse(methodName, resp);
        }
    },

    /**
     * Handle Error response for multi-item response
     *
     * @param {string} methodName
     * @param {Object} resp
     */
    handleMultiItemsError(methodName, resp) {
        this.gridView.refreshGridData();
        this.handleMultiItemsResponse(methodName, resp);
    },

    /**
     * Handle bulk actions as one from the grid
     *
     * @param {string} methodName
     * @param {array} selectedArray
     * @param {Object} additionalParams
     * @returns {Promise}
     */
    processMultiActionsAsOne(methodName, selectedArray, additionalParams) {
        if (selectedArray.length <= 0) {
            return Promise.resolve();
        }

        const service = this.generateMultiServiceName(methodName);
        const jsonData = this.generateMultiItemsJsonData(
            methodName,
            selectedArray,
            additionalParams,
        );
        return new Promise((resolve, reject) => {
            /** this is not a promise so wrap it */
            http.post(service, jsonData, (result) => {
                this.handleMultiItemsSuccess(methodName, selectedArray, result);
                resolve();
            }, (result) => {
                this.handleMultiItemsError(methodName, result);
                reject();
            });
        });
    },

    bulkAction(methodName, selectedArray) {
        const self = this;
        let confirmResponse = null;
        let successMessage = null;
        let totalSuccess = 0;
        let totalFail = 0;
        let failMessage = null;
        let message = null;
        let model;

        const formatConfirm = () => {
            let idx = -1;

            if (successMessage) {
                idx = successMessage.indexOf(' ');

                successMessage = idx > -1 ?
                    totalSuccess + successMessage.slice(idx) : successMessage;

                if (idx > -1 && totalSuccess > 1) {
                    const splittedMessage = successMessage.split(' ');
                    splittedMessage[1] += 's';
                    successMessage = splittedMessage.join(' ');
                }

                // add a separator for success and failure messages
                if (failMessage) {
                    successMessage += ', ';
                }
                message = successMessage;
            }
            if (failMessage) {
                idx = failMessage.indexOf(' ');
                failMessage = idx > -1 ? totalFail + failMessage.slice(idx) : failMessage;
                if (successMessage) {
                    message += failMessage;
                } else {
                    message = failMessage;
                }
            }
            confirmResponse.message[0] = message;
            confirmResponse.confirms.totalSuccess = totalSuccess;
            confirmResponse.confirms.totalFail = totalFail;
        };

        const finishMethod = util.after(selectedArray.length, () => {
            self.gridView.refreshGridData();
            formatConfirm();
            self.renderMessage(methodName, confirmResponse);
            confirmResponse = null;
            successMessage = null;
            failMessage = null;
            message = null;
        });
        const onSuccess = (resolve, successModel, resp) => {
            if (confirmResponse === null) {
                confirmResponse = resp;
            } else {
                const [toPush] = resp.confirms.confirmResults;
                confirmResponse.confirms.confirmResults.push(toPush);
            }
            totalSuccess += resp.confirms.totalSuccess;
            totalFail += resp.confirms.totalFail;
            if (resp.confirms.totalSuccess) {
                [successMessage] = resp.message;
            } else {
                [failMessage] = resp.message;
            }
            finishMethod();
            resolve();
        };

        const onError = (resolve) => {
            confirmResponse.confirms.totalFail += 1;
            finishMethod();
            resolve();
        };
        const actionPromises = [];
        for (let i = 0; i < selectedArray.length; i += 1) {
            // Mobile grid requires promises for bulk actions
            // eslint-disable-next-line
            const modelPromise = new Promise((resolve) => {
                const optionsBlock = {
                    success: onSuccess.bind(this, resolve),
                    error: onError.bind(this, resolve),
                };
                model = this.getModelForAction(selectedArray[i]);
                if (methodName === constants.ACTION_DELETE) {
                    model.destroy(optionsBlock);
                }
                if (methodName === constants.ACTION_APPROVE) {
                    model.approve(optionsBlock);
                }
                if (methodName === constants.ACTION_UNAPPROVE) {
                    model.unapprove(optionsBlock);
                }
                if (methodName === constants.ACTION_REJECT) {
                    model.reject(optionsBlock);
                }
                if (methodName === constants.ACTION_RESTORE) {
                    model.restore(optionsBlock);
                }
                if (methodName === constants.ACTION_PAY ||
                    methodName === constants.ACTION_PAYISSUE ||
                    methodName === constants.ACTION_RETURN ||
                    methodName === constants.ACTION_PAYRULE) {
                    model.update(optionsBlock);
                }
            });
            actionPromises.push(modelPromise);
        }
        return Promise.all(actionPromises);
    },

    /**
     * @method getModelForAction
     * @description returns the model for the selected item
     *
     * @param {object} selectedItem - selected row from the grid on which
     * to perform the bulk action
     */
    getModelForAction(selectedItem) {
        return this.gridView.wrapper.rows.get(selectedItem);
    },

    approve() {
        this.handleBulkAction(constants.ACTION_APPROVE);
    },

    getGetRateViewId() {
        let viewId = constants.NEEDSRATEVIEWID;
        // needsrateviewid value is 20.  This is the default value for payment list
        this.gridView.savedViews.models.find((model) => {
            if (constants.NEEDSRATEID === model.id) {
                viewId = model.get('viewId');
                return true;
            }
            return false;
        });
        return viewId;
    },

    getrate() {
        const self = this;
        const viewId = this.getGetRateViewId();
        self.handleBulkAction(constants.ACTION_GETRATE, viewId);
    },

    trade() {
        this.handleBulkAction(constants.ACTION_TRADE);
    },

    unapprove() {
        this.handleBulkAction(constants.ACTION_UNAPPROVE);
    },

    reject() {
        const ar = this.gridView.grid.getSelectedRows();

        if (ar.length > 0) {
            const rejectDialog = new RejectDialog({
                multiSelectReject: true,
            });
            rejectDialog.once('list:multi:action:action_reject', this.handleBulkRejectAction, this);

            dialog.custom(rejectDialog);
        }
    },

    handleBulkRejectAction(rejectReason) {
        this.handleBulkAction(constants.ACTION_REJECT, rejectReason);
    },

    delete() {
        this.handleBulkAction(constants.ACTION_DELETE);
    },

    printchecks() {
        if (this.hasEntitlement(constants.ACTION_PRINTCHECK)) {
            const selectedChecks = this.gridView.grid.getSelectedRows();
            const printKeyValuesArray = [];
            let illegibleCheckBatchesCount = 0;
            const reprintBatchAction = false;
            const reprintCheckAction = false;
            if (selectedChecks.length > 0) {
                const firstSelectedCheck = this.gridView.wrapper.rows.get(selectedChecks[0]);
                // we have only one entryMethod within collection
                const { entryMethod } = firstSelectedCheck.context.actionData;
                for (let index = 0; index < selectedChecks.length; index += 1) {
                    const selectedCheckBatchModel =
                        this.gridView.wrapper.rows.get(selectedChecks[index]);
                    const ptxProcessedStatus = 'PR';
                    const printSource = selectedCheckBatchModel.get('CHKPRINTSOURCE');
                    if (selectedCheckBatchModel.get('STATUS') === ptxProcessedStatus && printSource !== 'O') {
                        const { typeCode } =
                            selectedCheckBatchModel.context.actionData;
                        const ptxId = selectedCheckBatchModel.get('PTX_BATCH_ID');
                        const keyValuePair =
                            this.generateKeyValuePairs(
                                selectedCheckBatchModel,
                                typeCode,
                                ptxId,
                            );
                        printKeyValuesArray.push(keyValuePair);
                    } else {
                        illegibleCheckBatchesCount += 1;
                    }
                }


                if (illegibleCheckBatchesCount > 0) {
                    const message = locale.get('clm.printchecks.eligible.error');
                    const translatedAction = locale.get('clm.printCheckBatches');
                    dialog.confirm(
                        `${message}`,
                        `${locale.get('title.confirm')} ${translatedAction}`,
                        (ok) => {
                            if (ok && illegibleCheckBatchesCount < selectedChecks.length) {
                                this.performCheckBatchPrintOrReprintAction(
                                    printKeyValuesArray,
                                    reprintBatchAction,
                                    reprintCheckAction,
                                    entryMethod,
                                );
                            }
                        },
                    );
                } else {
                    this.performCheckBatchPrintOrReprintAction(
                        printKeyValuesArray,
                        reprintBatchAction,
                        reprintCheckAction,
                        entryMethod,
                    );
                }
            }
        }
    },

    reprintchecks() {
        if (this.hasEntitlement(constants.ACTION_REPRINTCHECK)) {
            const selectedChecks = this.gridView.grid.getSelectedRows();
            const ptxIncompleteStatus = 'PX';
            const donePtxPrintStatus = 'DN';
            const errorPtxPrintStatus = 'ER';
            const releasedToPrinterStatus = 'RP';
            const reprintKeyValuesArray = [];
            let illegibleCheckBatchesCount = 0;
            const reprintBatchAction = true;
            const reprintCheckAction = false;
            if (selectedChecks.length > 0) {
                const firstSelectedCheck = this.gridView.wrapper.rows.get(selectedChecks[0]);
                // we have only one entryMethod within collection
                const { entryMethod } = firstSelectedCheck.context.actionData;
                for (let index = 0; index < selectedChecks.length; index += 1) {
                    const selectedCheckBatchModel =
                        this.gridView.wrapper.rows.get(selectedChecks[index]);
                    const selectedCheckBatchStatus = selectedCheckBatchModel.get('STATUS');
                    const selectedCheckBatchPrintStatus = selectedCheckBatchModel.get('PRINTSTATUS');
                    const printSource = selectedCheckBatchModel.get('CHKPRINTSOURCE');
                    const printedStatusVerification =
                        selectedCheckBatchPrintStatus === donePtxPrintStatus ||
                        selectedCheckBatchPrintStatus === errorPtxPrintStatus;
                    const releasedCheckBatchStatusVerification =
                        selectedCheckBatchStatus === releasedToPrinterStatus
                        && printedStatusVerification;
                    if (printSource !== 'O' && (selectedCheckBatchStatus === ptxIncompleteStatus
                        || releasedCheckBatchStatusVerification)) {
                        const { typeCode } =
                            selectedCheckBatchModel.context.actionData;
                        const ptxId = selectedCheckBatchModel.get('PTX_BATCH_ID');
                        const keyValuePair =
                            this.generateKeyValuePairs(
                                selectedCheckBatchModel,
                                typeCode,
                                ptxId,
                            );
                        reprintKeyValuesArray.push(keyValuePair);
                    } else {
                        illegibleCheckBatchesCount += 1;
                    }
                }

                if (illegibleCheckBatchesCount > 0) {
                    const message = locale.get('clm.reprintchecks.eligible.error');
                    const translatedAction = locale.get('clm.reprintCheckBatches');
                    dialog.confirm(
                        `${message}`,
                        `${locale.get('title.confirm')} ${translatedAction}`,
                        (ok) => {
                            if (ok && illegibleCheckBatchesCount < selectedChecks.length) {
                                this.performCheckBatchPrintOrReprintAction(
                                    reprintKeyValuesArray,
                                    reprintBatchAction,
                                    reprintCheckAction,
                                    entryMethod,
                                );
                            }
                        },
                    );
                } else {
                    this.performCheckBatchPrintOrReprintAction(
                        reprintKeyValuesArray,
                        reprintBatchAction,
                        reprintCheckAction,
                        entryMethod,
                    );
                }
            }
        }
    },

    restore() {
        this.handleBulkAction(constants.ACTION_RESTORE);
    },

    insert() {
        this.navigateTo(this.contextModel.getInsertUrl());
        return Promise.resolve();
    },

    insertOverride(context) {
        store.set(`${this.contextKey}-contextOverride`, context);
        this.navigateTo(this.contextModel.getInsertUrl());
    },

    hasEntitlement(type) {
        return this.entitlements[type] && this.entitlements[type] === true;
    },

    multipleApproveWarning(action, confirmResponse, selectedModelArray) {
        const confirmResponseParam = confirmResponse;
        if (confirmResponseParam.resultType === 'WARNING') {
            if (confirmResponseParam.errorCode === 514) {
                this.viewDisclosureForMulti(selectedModelArray);
            } else if (confirmResponseParam.errorCode === 540) {
                confirmResponseParam.confirms.confirmResults = confirmResponseParam.confirms.confirmResults.filter(result => result.resultType === 'DUPLICATEWARNING');
                const duplicateDialog = new DuplicateDialog({
                    resp: confirmResponseParam,
                    modelsArray: selectedModelArray,
                    grid: this.gridView,
                    methodName: 'APPROVE',
                });
                duplicateDialog.once('list:multi:action:action_approveWithWarning', this.actionApproveWithWarning, this);
                duplicateDialog.once('list:multi:action:action_continueWithResponse', this.actionContinueWithResponse, this);
                dialog.custom(duplicateDialog);
            } else if (confirmResponseParam.errorCode === constants.BENE_UPDATED_ERROR_CODE) {
                const beneUpdatedWarning = new BeneUpdatedWarning({
                    resp: confirmResponseParam,
                    modelsArray: selectedModelArray,
                    isMultiApprove: true,
                    grid: this.gridView,
                });
                beneUpdatedWarning.once('list:multi:action:action_approveWithWarning', this.actionApproveWithWarning, this);
                dialog.custom(beneUpdatedWarning);
            } else {
                const warningDialog = new WarningDialog({
                    methodName: action,
                    multipleApproval: true,
                    modelsArray: selectedModelArray,
                    confirms: confirmResponseParam,
                });
                warningDialog.once('list:multi:action:action_approveWithWarning', this.actionApproveWithWarning, this);
                warningDialog.once('list:multi:action:action_continueWithResponse', this.actionContinueWithResponse, this);
                dialog.custom(warningDialog);
            }
            return Promise.resolve();
        }
        return this.processMultiActionsAsOne(constants.ACTION_APPROVE, selectedModelArray);
    },

    actionApproveWithWarning(modelsArray, args) {
        this.processMultiActionsAsOne(constants.ACTION_APPROVE, modelsArray, args);
    },

    /**
     * Callback method handling warning for delete of multiple items
     * @param {string} action -  action name here its Delete
     * @param {object} confirmResponse -  confirmResponse object returned drom server
     * @param {array} selectedModelArray - array of selected items in the list
     */
    multipleDeleteWarning(action, confirmResponse, selectedModelArray) {
        if (confirmResponse.resultType === 'WARNING') {
            const warningDialog = new WarningDialog({
                methodName: action,
                multipleApproval: true,
                modelsArray: selectedModelArray,
                confirms: confirmResponse,
            });
            warningDialog.once('list:multi:action:action_deleteWithWarning', this.actionDeleteWithWarning, this);
            warningDialog.once('list:multi:action:action_continueWithResponse', this.actionContinueWithResponse, this);
            dialog.custom(warningDialog);
        } else {
            this.processMultiActionsAsOne(constants.ACTION_DELETE, selectedModelArray);
        }
    },

    /**
     * Callback method called from warning dialog for delete of multiple items
     * @param {array} modelsArray -  array of selected items in the list
     * @param {object} args -  additional parameters to be set in to the model
     */
    actionDeleteWithWarning(modelsArray, args) {
        this.processMultiActionsAsOne(constants.ACTION_DELETE, modelsArray, args);
    },

    actionContinueWithResponse(modelsArray, confirms, methodName) {
        const selModelsArray = [];
        for (let i = 0; i < modelsArray.length; i += 1) {
            const model = this.gridView.wrapper.rows.get(modelsArray[i]);
            for (let j = 0; j < confirms.confirmResults.length; j += 1) {
                if (confirms.confirmResults[j].confirmData.length > 0) {
                    const Id = confirms.confirmResults[j].confirmData[0].item[0].value;
                    if (Id === model.get('USERGROUPSEQUENCENUMBER')) {
                        if (confirms.confirmResults[j].resultType !== 'WARNING') {
                            selModelsArray.push(modelsArray[i]);
                        }
                    }
                }
            }
        }
        this.processMultiActionsAsOne(methodName, selModelsArray);
    },

    /**
     * Place Holder in case we need to default any Data coming from the Confirm response
     * @abstract
     */
    defaultConfirmData() {
        // Place Holder in case we need to default any Data coming from the Confirm response
    },

    // Grips Response Alert
    /**
     * @method startGridRespAlertListeners
     * @param {string} gridViewCid - gridview cid
     * Adds listeners to the view that displays alert messages based on Grid Request
     * responses on load/action (The data recieved from)
     * Currently these listeners are used in listviews that are dynamically
     * inserted into parent views ie. Check Inquiry, BTR Transactions List, eStatement Results
     */
    startGridRespAlertListeners(gridViewCid) {
        this.listenTo(this.appBus, (`gridapi:showMessages${gridViewCid}`), this.showGridSuccessMessages);
        this.listenTo(this.appBus, (`gridapi:showWarningMessage${gridViewCid}`), this.showGridWarningMessage);
        this.listenTo(this.appBus, (`gridapi:showErrorMessages${gridViewCid}`), this.showGridErrorMessages);
    },

    /**
     * @method showGridWarningMessages
     * @param {string} message - Localized warning message string
     * Displays a persistent warning message when a warningMessage is found
     */
    showGridWarningMessage(message) {
        this.warningView = alert.warning(message, {
            canDismiss: false,
        });

        if (this.warningRegion) {
            this.warningRegion.show(this.warningView);
        }
    },

    /**
     * @method showGridSuccessMessages
     * @param {object} response -- grid request respHeader
     * On grid request success, parses the message and shows a success or warning alert message.
     */
    showGridSuccessMessages(response) {
        const message = this.gridView.wrapper.getRespHeader('message');

        if (message && message.length > 1) {
            const { errorCode } = response;
            const status = (errorCode === 0) ? 'success' : 'warning';

            /**
             * skip the default "Success" message returned by the service
             * and join all others onto newline
             */
            const gridMsg = message.splice(1).join('\n');

            this.showAlertMessage(gridMsg, status);
        }
    },

    /**
     * @method showGridErrorMessages
     * @param {object} response -- grid request respHeader
     * On grid request fail, parses the message and shows a danger/negative alert message.
     */
    showGridErrorMessages(response) {
        this.trigger('checkInquiryGrid:error');

        if (response.messages && response.messages.length) {
            const gridMsg = response.messages.join('\n');
            this.showAlertMessage(gridMsg, 'danger');
        }
    },

    /**
     * @method showAlertMessage
     * @param {string} message
     * @param {string} type - glu alert types associated to an alert creation method
     * (success, warning, danger, etc)
     * @param {object} props - object containing extra properties for glu alert construction
     * Shows an alert message in the ListView. The status passed in is associated to the type of
     * alert that is shown.
     */
    showAlertMessage(message, type, props) {
        const typeParam = alert[type] ? type : 'success';
        const propsParam = props || {
            canDismiss: true,
        };

        // create alert message using associated alert status type
        this.alertView = alert[typeParam](message, propsParam);

        this.appBus.trigger('listview:alert:show', {
            type: typeParam,
            parentModel: this.parentModel,
        });

        this.alertRegion.show(this.alertView);
    },


    /**
     * @method renderMessage
     * @param {object} action - grid/page action data
     * @param {object} confirmResponse - confirmation message content
     * @param {number} errorCount
     * Takes in action data and a action confirmation response to produce an alert message
     * Usually used after a grid action is made and the data is stored in the
     * Store to be used in this method.
     */
    renderMessage(action, confirmResponse, errorCount) {
        // create the confirmation view for the alert
        // skip if action is null.  This occurs when first entering the payment workspace
        // because the onRender is called.
        if (!((action === null || action === undefined) &&
            (confirmResponse === null || confirmResponse === undefined))) {
            if (confirmResponse === null || confirmResponse === undefined) {
                if ((action.type === 'WARNING' || action.type === 'DELEGATERESULT') && action.message) {
                    this.showAlertMessage(action.message, 'warning');
                }
            } else {
                const confirm = typeof confirmResponse === 'object' ? confirmResponse.confirms : null;
                const successCount = confirm ? confirm.totalSuccess : 0;
                const failCount = confirm ? confirm.totalFail : errorCount;
                const totalCount = failCount + successCount;
                const success = successCount === totalCount;
                const alertType = success ? 'success' : 'danger';
                const chainedMessages = typeof confirmResponse === 'object' ? confirmResponse.message : null;
                let message = action;

                if (typeof confirmResponse === 'object' && confirmResponse.message && confirmResponse.message.length > 0) {
                    message = '';
                    for (let i = 0; i < chainedMessages.length; i += 1) {
                        message += util.unescape(confirmResponse.message[i]);
                    }
                }

                this.defaultConfirmData(confirmResponse);

                const confirms = new Confirms({
                    confirms: confirmResponse ? confirmResponse.confirms : null,
                });

                // display notification message
                this.showAlertMessage(message, alertType, {
                    details: confirmResponse && confirmResponse.confirms ? confirms : null,
                    canDismiss: !!confirmResponse,
                    animate: true,
                    confirmResponse: confirmResponse &&
                        confirmResponse.message ? confirmResponse : false,
                });

                if (message) {
                    this.alertRegion.show(this.alertView);
                    scroll.scrollToFirstError(this.$el);
                }
            }
        }
    },

    /**
     * @method getConfirmData
     * @param {object} confirmResponse
     * @param {string} name
     * From the confirmData item array,
     * find the object where the name matches the passed string value. If confirmResults
     * is undefined/null return false
     */
    getConfirmData(confirmResponse, name) {
        return confirmResponse.confirms.confirmResults ?
            util.findWhere(
                confirmResponse.confirms.confirmResults[0].confirmData[0].item,
                {
                    name,
                },
            ) : false;
    },

    /**
     * @description refreshes the grid data in response to the refresh icon
     * pass in true to send rowsperpage
     */
    refreshData() {
        this.gridView.wrapper.refreshGridData(false, null, true);
        // trigger an event that signifies that the grid was refreshed
        this.appBus.trigger('grid:refreshed', this);
    },

    print() {
        const printModal = new PrintViewModal({
            exportModel: this.buildExportModel('PDF'),
            title: `${this.localeKey}title`,
        });
        dialog.custom(printModal);
    },

    /**
     * Refresh the grid data and render and error message with the
     * data from the response
     * @param {Object} action - grid/page action data
     * @param {Model|Object} model - model | action error response
     * @param {Object} resp - action error response
     */
    showResponseError(action, model, resp) {
        this.gridView.refreshGridData();
        this.renderMessage(
            action,
            model.responseJSON || (resp && resp.responseJSON) || model.errorMessage,
        );
    },

}));

export default desktopList;
