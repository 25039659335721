var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row\">\n            <div class=\"form-group col-4\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.User.Group",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":23},"end":{"line":7,"column":50}}}))
    + "</label>\n                <p data-bind=\"model\" data-text=\"USERGROUP\"></p>\n            </div>\n            <div class=\"form-group col-4\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.User",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":23},"end":{"line":11,"column":44}}}))
    + "</label>\n                <p data-bind=\"model\" data-text=\"USERID\"></p>\n            </div>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"form-group col-4\">\n                <label>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"GIR.Transmission.Profile",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":23},"end":{"line":23,"column":60}}}))
    + "</label>\n                <p data-bind=\"model\" data-text=\"TRANSMISSIONPROFILE\"></p>\n            </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button class=\"btn btn-secondary\" type=\"button\" data-hook=\"getModifyBtn\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.modify",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":43,"column":89},"end":{"line":43,"column":115}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"workflowPageHeader"),depth0,{"name":"workflowPageHeader","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<fieldset class=\"section section-container\">\n    <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Summary",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":36}}}))
    + "</legend>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdmin") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":15,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"row\">\n        <div class=\"form-group col-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Schedule.Name",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":19},"end":{"line":18,"column":49}}}))
    + "</label>\n            <p data-bind=\"model\" data-text=\"NAME\"></p>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"TRANSMISSIONPROFILE") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":26,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"row\">\n        <div class=\"form-group col-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Report.Type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":30,"column":19},"end":{"line":30,"column":47}}}))
    + "</label>\n            <p>"
    + alias2(((helper = (helper = lookupProperty(helpers,"reportTypeDesc") || (depth0 != null ? lookupProperty(depth0,"reportTypeDesc") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"reportTypeDesc","hash":{},"data":data,"loc":{"start":{"line":31,"column":15},"end":{"line":31,"column":33}}}) : helper)))
    + "</p>\n        </div>\n    </div>\n</fieldset>\n\n<div data-region=\"detailRegion\"></div>\n\n<fieldset class=\"section section-container\">\n    <div class=\"row\">\n        <div class=\"col-6\">\n            <button class=\"btn btn-secondary\" type=\"button\" data-hook=\"getPrintBtn\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":41,"column":84},"end":{"line":41,"column":109}}}))
    + "</button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasModifyEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":12},"end":{"line":44,"column":19}}})) != null ? stack1 : "")
    + "            <button class=\"btn btn-secondary\" type=\"button\" data-hook=\"getCancelBtn\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.back",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":45,"column":85},"end":{"line":45,"column":109}}}))
    + "</button>\n        </div>\n    </div>\n</fieldset>\n";
},"usePartial":true,"useData":true});