import Collection from '@glu/core/src/collection';

const Population = Collection.extend({
    /**
     * TODO: This base collection should be globally available for the listBuilder.
     * Extend the collection passed by the view.
     * Move the fetch call into the listBuilder. Implement the Non-Blocking pattern.
     */
    initialize() {
        this.isReady = true;
        this.pageSize = 250;
        this.setRequestObj();
    },

    setRequestObj() {
        this.data = {
            rowsPerPage: this.pageSize,
            startRow: 1,
        };
    },

    setPageSize(pageSize) {
        this.pageSize = pageSize;
        this.setRequestObj();
    },

    updatePagination() {
        this.data.startRow += this.data.startRow;
    },

    canContinue() {
        return this.isReady;
    },

    isNotEmpty() {
        return !this.isEmpty();
    },

    setSearchCriteria(searchString) {
        this.searchValue = [searchString];
    },

    getSearchCriteria() {
        return this.searchValue;
    },

    clearSearchCriteria() {
        this.searchValue = [];
        this.setRequestObj();
        this.fetch({
            reset: true,
        });
    },

    next() {
        const self = this;
        this.updatePagination();
        this.isReady = false;
        return new Promise((resolve, reject) => {
            self.fetch({
                success(model, response) {
                    self.totalRows = response.totalRows;
                    self.isReady = (response.rows.length === self.data.rowsPerPage);
                    resolve(response);
                },

                error(model, response) {
                    reject(response);
                },

                silent: true,
                remove: false,
            });
        });
    },

    liveSearch() {
        const self = this;
        // reset for paginating searching results
        this.resetForLiveSearch();

        // TODO: Do we similarly need to expose this promise?
        this.fetch({
            success(model, resp) {
                self.isReady = (resp.rows.length === self.data.rowsPerPage);
                if (resp.rows.length === 0) {
                    self.trigger('search:noResultsFound');
                } else {
                    self.trigger('search:resultsFound');
                }
            },
        });
    },

    resetForLiveSearch() {
        this.isReady = true;
        this.setRequestObj();
    },
});

export default Population;
