var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"locationInfo row\">\n        <div class=\"form-column col-md-6\">\n            <div class=\"field-container-md field-container\">\n                "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"LBX.NoLocationInfoLine",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":51}}}))
    + "\n            </div>\n        </div>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"locationInfo row\">\n        <div class=\"form-column col-md-6\">\n            <div class=\"field-container-md field-container\">\n                <strong>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"LBX.AddLockboxLocation",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":24},"end":{"line":14,"column":59}}}))
    + "</strong>\n            </div>\n        </div>\n    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "    <div data-region=\"alertRegion\"></div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"row\">\n        <div class=\"form-column col-md-12\">\n            <div class=\" field-container-xs field-container\">\n                <button type=\"button\" data-action=\"submitLocation\" class=\"btn btn-primary\"><span class=\"txt\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"LBX.AddLocation",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":42,"column":109},"end":{"line":42,"column":137}}}))
    + "</span></button>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFirstLocation") : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":16},"end":{"line":45,"column":27}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" data-action=\"cancelLocation\" class=\"btn btn-secondary\"><span class=\"txt\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":44,"column":115},"end":{"line":44,"column":141}}}))
    + "</span></button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFirstLocation") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":9,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":0},"end":{"line":18,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":0},"end":{"line":21,"column":7}}})) != null ? stack1 : "")
    + "<div class=\"row\">\n    <div class=\"form-column col-md-6\">\n        <div class=\"field-container-md field-container\">\n            <label for=\"LOCATION_CODE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.LocationCode",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":39},"end":{"line":25,"column":68}}}))
    + "</label>\n            <input class=\"form-control \" type=\"text\" maxlength=\"20\" data-bind=\"model\" id=\"\" name=\"LOCATION_CODE\" placeholder=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.LocationCodeInfoLine",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":126},"end":{"line":26,"column":163}}}))
    + "\">\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"LOCATION_CODE\"></span>\n        </div>\n    </div>\n    <div class=\"form-column col-md-6\">\n        <div class=\" field-container-md field-container\">\n            <label for=\"LOCATION_DESC\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.LocationDescription",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":39},"end":{"line":32,"column":75}}}))
    + "</label>\n            <input class=\"form-control \" type=\"text\" maxlength=\"75\" data-bind=\"model\" id=\"\" name=\"LOCATION_DESC\" value=\"\">\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"LOCATION_DESC\"></span>\n        </div>\n    </div>\n</div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":0},"end":{"line":49,"column":11}}})) != null ? stack1 : "");
},"useData":true});