import alert from '@glu/alerts';
import dialog from '@glu/dialog';
import ListView from 'common/dynamicPages/views/workflow/list';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import workspaceHelper from 'common/workspaces/api/helper';
import AddHolidayDialog from './AddHolidayDialog';
import template from './holidays.hbs';

const HolidaysView = ListView.extend({
    template,
    initialize(options) {
        const superOptions = {
            menuCategory: 'ADMINISTRATION',
            serviceName: '/bankHoliday',
            serviceFunc: null,
            businessType: null,
            context: 'HOLIDAYS_LIST',
            enableSavedViews: false,
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    insert() {
        dialog.custom(new AddHolidayDialog({
            success: () => this.success(),
        }));
    },

    success() {
        this.alertRegion.show(alert.success(locale.get('holy.Add.Success')));
        this.refreshData();
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'HOLIDAYS_LIST',
    view: HolidaysView,
    options: {},
});

export default HolidaysView;
