import { useEffect } from 'react';
import PropTypes from 'prop-types';
import dialog from '@glu/dialog';
/*
 * TODO When converting to ASH theme, this will no longer be required, use
 * @glu/modal-react instead. There is a major conflict with the CSS used to render
 * the @glu/modal-react. This wrapper of sorts never actually renders markup.
 * It does show a backbone modal and requires managing the show prop in
 * the parent component.
 */
const ConfirmModal = ({
    title,
    message,
    show,
    onButtonAction,
}) => {
    useEffect(() => {
        if (show) {
            dialog.confirm(
                message,
                title,
                onButtonAction,
            );
        }
    }, [title, message, onButtonAction, show]);
    return null;
};

ConfirmModal.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    onButtonAction: PropTypes.func.isRequired,
};
ConfirmModal.defaultProps = {
    title: '',
};

export default ConfirmModal;
