/* istanbul ignore file */
export const styles = ({ dataComponents, palette }) => ({
  dropdown: {
    '& > label p': {
      margin: 0
    },
    margin: [0, 0, 8, 0],
    minWidth: 161,
    width: 'calc(50% - 4px)'
  },
  input: {
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '&:disabled': {
      opacity: 0.5
    },
    '-moz-appearance': 'textfield',
    background: 'transparent',
    border: `1px solid ${palette.form.border}`,
    borderRadius: dataComponents.selection.borderRadius,
    boxSizing: 'border-box',
    color: ({ dark }) => (dark ? palette.text.light : palette.text.dark),
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: 16,
    fontWeight: 400,
    height: 34,
    lineHeight: 1,
    padding: [0, 5],
    width: 'calc(50% - 35px)'
  },
  rangeInputWrapper: {
    '& input': {
      width: '100%'
    },
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    textTransform: 'capitalize',
    width: 'calc(50% - 4px)'
  },
  rangeWrapper: {
    display: 'flex',
    flexBasis: '100%',
    justifyContent: 'space-between'
  },
  wrapper: {
    color: ({ dark }) => (dark ? palette.text.light : palette.text.dark),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  }
});

export default styles;
