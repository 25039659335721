/**
 * routing configuration for user maintenance
 */
export default {
    'ADMINISTRATION/addUser': 'adminAddUser',
    'ADMINISTRATION/addUserClient': 'addUserDeeplinkClient',
    'ADMINISTRATION/modifyUser': 'adminModifyUser',
    'ADMINISTRATION/viewUser': 'adminViewUser',
    'ADMINISTRATION/viewPendingChanges': 'adminViewPendingChanges',
    'ADMINISTRATION/addRoleUser': 'addRoleUser',
    'ADMINISTRATION/updateUserSetting': 'updateUserSetting',
    'ADMINISTRATION/modifyRoleUser': 'modifyRoleUser',
    'ADMINISTRATION/viewRoleUser': 'viewRoleUser',
    'ADMINISTRATION/addRole': 'addRole',
    'ADMINISTRATION/cloneRole': 'cloneRole',
    'ADMINISTRATION/modifyRole/(:roleName)/(:userGroup)': 'modifyRole',
    'ADMINISTRATION/viewRole/(:roleName)/(:userGroup)': 'viewRole',
    'ADMINSTRATION/viewUsers(/)(:userGroup)': 'adminViewUsersWorkspace',
    'ADMINSTRATION/listUsers(/)(:viewId)': 'adminListUsers',
    'ADMINSTRATION/viewRoles(/)(:userGroup)': 'adminUserRoleMaintenance',
    'ADMINSTRATION/modifyUserClient': 'modifyUserDeeplinkClientMain',
    'ADMINSTRATION/modifyUserClientSecondary': 'modifyUserDeeplinkClientSecondary',
    'ADMINSTRATION/modifyUserAdmin': 'modifyUserDeeplinkAdminMain',
    'ADMINSTRATION/modifyUserAdminSecondary': 'modifyUserDeeplinkAdminSecondary',
    'ADMINSTRATION/viewUserClient': 'viewUserDeeplinkClient',
    'ADMINSTRATION/viewUserAdmin': 'viewUserDeeplinkAdmin',
    'CMAINT/viewUsers(/)(:userGroup)': 'adminViewUsersWorkspace',
    'CMAINT/listUsers(/)(:viewId)': 'adminListAllUsers',
};
