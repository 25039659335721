import Layout from '@glu/core/src/layout';
import tableMaintApi from 'system/webseries/api/tableMaintenance';
import Beneficiary from 'app/setup/models/beneficiary';
import Collection from '@glu/core/src/collection';
import CollectionComboBoxView from 'common/collectionComboBox/views/base';
import createBeneficiaryTmpl from './createBeneficiary.hbs';

export default Layout.extend({
    initialize(opts) {
        this.title = opts.title || '';
        this.stateCodesCollection = opts.stateCodesCollection || new Collection();
        this.countryCodesCollection = opts.countryCodesCollection || new Collection();
        this.bankCodesCollection = opts.bankCodesCollection || new Collection();
        this.currencyCodesCollection = opts.currencyCodesCollection || new Collection();
        this.model.clear();
    },

    template: createBeneficiaryTmpl,
    model: new Beneficiary(),

    onRender() {
        // Setup dropdowns.
        this.renderStateContent();
        this.renderCountryContent();
        this.renderBankContent();
        this.renderCurrencyContent();
    },

    /**
     * Render the state region.
     */
    renderStateContent() {
        const self = this;

        const stateCodeComboBoxView = new CollectionComboBoxView({
            collection: self.stateCodesCollection,
        });

        this.stateRegion.show(stateCodeComboBoxView);
    },

    /**
     * Render the country region.
     */
    renderCountryContent() {
        const self = this;

        const countryCodeComboBoxView = new CollectionComboBoxView({
            collection: self.countryCodesCollection,
        });

        this.countryRegion.show(countryCodeComboBoxView);
    },

    /**
     * Render the bank region.
     */
    renderBankContent() {
        const self = this;

        const bankCodeComboBoxView = new CollectionComboBoxView({
            collection: self.bankCodesCollection,
        });

        this.bankRegion.show(bankCodeComboBoxView);
    },

    /**
     * Render the currency region.
     */
    renderCurrencyContent() {
        const self = this;

        const currencyCodeComboBoxView = new CollectionComboBoxView({
            collection: self.currencyCodesCollection,
        });

        this.currencyRegion.show(currencyCodeComboBoxView);
    },

    backToListView() {
        this.navigateTo('SETUP/beneBookInquiry');
    },

    cancel() {
        this.backToListView();
    },

    submit(e) {
        e.preventDefault();
        const data = this.model.toJSON();
        tableMaintApi.saveData(data);
        this.backToListView();
    },
});
