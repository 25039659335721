/* eslint backbone/no-native-jquery:0 */
// Ignoring eslint issues as this file will be deleted in 2.0
import Poll from '@glu/polling';
import Glu from '../../glu';
import $ from '../../$';
import util from '../../util';
import locale from '../../locale';
import http from '../../http';
import Layout from '../../layout';
import appBus from '../../appBus';
import log from '../../log';

export default Layout.extend({
  template: () => '<div id="nav" class="glu-base-nav" role="navigation"></div><div id="main" role="main"></div><div id="footer" class="glu-footer" role="contentinfo"></div><div id="modal-content"></div>',

  el: '.glu-content',

  initialize(options) {
    this.options = options;
    this.LoginView = options.loginView;
    this.NavView = options.navView;
    this.FooterView = options.footerView;
    this.documentTitle = options.title ? options.title : 'Bottomline Technologies';
    this.notAuthorised = options.notAuthorised || [];

    this.initialEvents();
  },

  onRender() {
    const isAuthenticatedRoute = !util.some(this.notAuthorised, (regex) =>
    // TODO: replace window.location.href with function which does the same as history.fragment.
      window.location.href.match(regex));
    if (this.options.user.isAuthenticated && isAuthenticatedRoute) {
      return this.showNavigationAndFooter();
    }

    // If not authorized, then show login page immediately
    this.onUnauthorized();

    return undefined;
  },

  appEvents: {
    'router:reload': 'showNavigationAndFooter',
    'show:page': 'showPage',
    'http:401': 'onUnauthorized',
    'http:request': 'monitorSessionExpiry'
  },

  regions: {
    main: '#main',
    nav: '#nav',
    footer: '#footer'
  },

  showNavigationAndFooter() {
    if (this.NavView) {
      // need to add back options to the constructor so it is part of new object
      this.nav.show(new this.NavView(this.NavView.options || undefined));
    }

    if (this.FooterView) {
      this.footer.show(new this.FooterView());
    }
  },

  showPage(view, title) {
    document.title = title ? util.escape(`${title} - ${this.documentTitle}`) : this.documentTitle;
    this.main.show(view);
  },

  initialEvents() {
    this.handleUnexpectedErrors();

    // Override default action on all hyperlinks beginning `/`
    // Use the `data-enable-href` attribute to disable this handling
    $(document).on('click', 'a[href^="/"]', (e) => {
      const $el = $(e.currentTarget);
      const href = $el.attr('href');
      const passThrough = $el.data('enable-href');
      const urlRootRegEx = new RegExp(`^${Glu.history.root}`);

      // Allow shift+click for new tabs, etc.
      if (!passThrough && (href !== '#') && !e.altKey && !e.ctrlKey && !e.metaKey && !e.shiftKey) {
        e.preventDefault();

        // Remove root from URL
        const url = href.replace(urlRootRegEx, '');
        // Instruct Backbone to trigger routing events
        appBus.trigger('router:navigate', url, true);

        // Trigger dropdown close event
        $(document).trigger('click.bs.dropdown');

        return false;
      }

      return undefined;
    });
  },

  handleUnexpectedErrors() {
    // TODO use Glu.locale for localisation
    const message = locale.get('anErrorOccurredMsg');
    const title = locale.get('anErrorOccurredTitle');

    const clientErrorHandler = this.options.clientErrorHandler || (err => {
      window.console.log(message, title);
      log.debug(err.stack ? err.stack : err);
    });

    const serverErrorHandler = this.options.serverErrorHandler || (() => {
      window.console.log(message, title);
    });

    window.onerror = clientErrorHandler;
    this.listenTo(appBus, 'http:5XX', serverErrorHandler);
  },

  onUnauthorized() {
    // check if the url belongs to the 'safe list', before stopping the page load
    const url = window.location.href;

    if (util.indexOf(this.notAuthorised, url) > -1) {
      return;
    }
    // This stops further 401s/HTTP activity being handled superfluously
    Glu.history.stop();

    this.nav.close();

    this.appBus.trigger('session.clear-expiry-alert');

    // This could also be a redirect to a login page outside of the app
    this.appBus.trigger('show:page', new this.LoginView(this.options));
  },

  monitorSessionExpiry(url) {
    // Only activate this functionality if a ping URL is provided
    if (!this.options.sessionExpiryPingUrl) {
      return;
    }

    // If the request URL is the ping URL don't queue up another poll, otherwise
    // we'll end up in a recursive mess
    if (url === this.options.sessionExpiryPingUrl) {
      return;
    }

    // Reset the timer, unless this was invoked by ping URL itself
    if (this.inactiveHttpTimer && (url !== this.options.sessionExpiryPingUrl)) {
      window.clearTimeout(this.inactiveHttpTimer);
      this.appBus.trigger('session.clear-expiry-alert');
    }

    if (this.sessionExpiryPing) {
      this.sessionExpiryPing.dispose();
    }

    // Only ping if there is an active session
    if (!Glu.History.started) {
      return;
    }

    const self = this;

    // After 5 minutes without HTTP activity, begin pinging the server for a warning about impending session expiry
    this.inactiveHttpTimer = window.setTimeout(() => {
      // Only ping if there is an active session
      if (!Glu.History.started) {
        return;
      }

      self.sessionExpiryPing = new Poll({
        iterator(poll) {
          // TODO Fix this up, its PT-X specific
          http.get(self.options.sessionExpiryPingUrl, (result) => {
            if (result === 'NEARING_EXPIRY') {
              self.appBus.trigger('session.near-expiry');
            }
            poll(60000); // Poll again in 1 minute
          }, null, {
            silent: true
          });
        },
        immediateStart: true
      });
    }, 300000); // Poll in 5 minutes
  }
});

