import ItemView from '@glu/core/src/itemView';
import cardItemTmpl from 'app/administration/common/card/cardItem.hbs';

export default ItemView.extend({
    template: cardItemTmpl,
    className: 'fx-card',
    initialize() {
        this.$el.addClass(`fx-card-${this.cid}`);
    },
});
