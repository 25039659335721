import Model from '@glu/core/src/model';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import constants from 'app/administration/constants';
import validatorPatterns from 'system/validatorPatterns';
import httpError from 'common/util/httpErrorResult';

export default Model.extend({
    service: 'ssoVendors',
    idAttribute: 'TPV_ID',

    defaults: {
        addRequestFieldCount: 1,
    },

    initialize() {
        this.validators = {
            VENDCODE: {
                description: locale.get('sso.vendorCode'),
                exists: true,
            },
            MENUDESCRIPTION: {
                description: locale.get('sso.menuDisplayName'),
                exists: true,
            },
            TYPECODE: {
                description: locale.get('sso.typeCode'),
                exists: true,
            },
            TYPEDESCRIPTION: {
                description: locale.get('sso.typeDescription'),
                exists: true,
            },
            AUTHENTICATE_URL: {
                description: locale.get('sso.loginRequestUrl'),
                exists: true,
                matches: validatorPatterns.URL_PATTERN,
            },
            INITIAL_REDIRECT_URL: {
                description: locale.get('sso.successfulLoginUrl'),
                matches: validatorPatterns.URL_PATTERN,
            },
            LOGOUT_URL: {
                description: locale.get('sso.logoutRequestUrl'),
                matches: validatorPatterns.URL_PATTERN,
            },
            TPV_TYPE: {
                description: locale.get('sso.mandatory.tpv_type'),
                exists: true,
            },
            BILLING_CODE: {
                description: locale.get('sso.billingCode'),
                exists: true,
            },
            LOGOUT_TYPE: {
                description: locale.get('sso.mandatory.logout_type'),
                exists: true,
            },
            OPTIONAL_URL: {
                description: locale.get('sso.optionalUrl'),
                matches: validatorPatterns.URL_PATTERN,
            },
        };
    },

    /**
     * - send data to the api, depending on current mode
     * @method sync
     * @param {string} method - the current mode
     * @param {object} model - the current model
     * @param {object} options - an options object
     */
    sync(method, model, options) {
        const self = this;
        let url;
        let data;

        if (method === 'create') {
            url = services.generateUrl(`tableMaintenance/${this.service}/add`);
            data = this.convertModelAttributesToServerJSON(model);

            http.post(url, data, options.success, httpError.getResult(options, model));
        }

        if (method === 'read') {
            this.method = method;
            data = this.convertModelAttributesToServerJSON(model);
            url = services.generateUrl(`tableMaintenance/${this.service}/readchildren`);

            http.post(url, data, (result) => {
                let vendorData = {};
                util.each(result.item.item, (detail) => {
                    vendorData[detail.name] = detail.value;
                });
                self.REQUESTFIELDS = result.grids[0].items;
                vendorData = self.prepDataForViewAndEdit(vendorData);
                options.success(vendorData);
            }, httpError.getResult(options, model));
        }

        if (method === 'update') {
            url = services.generateUrl(`tableMaintenance/${this.service}/update`);
            data = this.convertModelAttributesToServerJSON(model);

            http.post(url, data, options.success, httpError.getResult(options, model));
        }

        if (method === 'delete') {
            this.method = method;
            data = this.convertModelAttributesToServerJSON(model);
            url = services.generateUrl(`tableMaintenance/${this.service}/delete`);

            http.post(url, data, options.success, httpError.getResult(options, model));
        }

        if (method === 'combobox') {
            data = {
                requestHeader: {},
                inquiryRequest: {
                    searchCriteria: {
                        inquiryId: options.inquiryId,
                        searchType: '5',
                    },
                },
            };

            if (options.menuCategory) {
                data.inquiryRequest.searchCriteria.action = {
                    typeCode: 'SSOFIELD',
                    productCode: 'SSOOUT',
                    functionCode: 'MAINT',
                    actionMode: 'SELECT',
                };
                data.inquiryRequest.searchCriteria.customFilters = [{
                    filterName: 'Depends',
                    filterParam: [
                        'menuCategory',
                        options.menuCategory,
                    ],
                }];
                data.inquiryRequest.startRow = 1;
                data.inquiryRequest.rowsPerPage = 100;
            }

            http.post(services.generateUrl('/inquiry/getData'), data, (result) => {
                const dropdownValues = util.map(
                    result.inquiryResponse.rows,
                    self.parseColumn,
                    self,
                );

                const dropdownData = {
                    data: dropdownValues,
                };

                options.success(dropdownData);
            }, httpError.getResult(options, model));
        }
    },

    /**
     * - data transformations of API data to prep for view and edit modes
     * @method prepDataForViewAndEdit
     * @param {Object} data - the data to be used as the view's model
     * @returns {Object} data - the data to be used as the view's model
     */
    prepDataForViewAndEdit(data) {
        const dataParam = data;
        dataParam.BEFOREAFTER = dataParam.BEFOREAFTER === '0' ? locale.get('sso.before') : locale.get('sso.after');
        this.getPostPermissionsFromBitMask(dataParam.USE_POST_METHOD, dataParam);
        this.getCertPermissionsFromBitMask(dataParam.USE_CLIENT_CERTIFICATES, dataParam);

        return dataParam;
    },

    /**
     * @method getPostPermissionsFromBitMask
     * @param {Number} mask - Value bit mask stored on the server
     * @param {Object} data - Data for the model
     * @returns {Object} data - Data for the model
     */
    getPostPermissionsFromBitMask(mask, data) {
        // eslint-disable-next-line
        data.POST_METHOD_CHECKBOX_LOGIN = !!(constants.SSO_ADMIN.LOGIN_MASK & mask);
        // eslint-disable-next-line
        data.POST_METHOD_CHECKBOX_LOGOUT = !!(constants.SSO_ADMIN.LOGOUT_MASK & mask);
        // eslint-disable-next-line
        data.POST_METHOD_CHECKBOX_REDIRECT = !!(constants.SSO_ADMIN.REDIRECT_MASK & mask);

        return data;
    },

    /**
     * @method getCertPermissionsFromBitMask
     * @param {Number} mask - Value bit mask stored on the server
     * @param {Object} data - Data for the model
     * @returns {Object} data - Data for the model
     */
    getCertPermissionsFromBitMask(mask, data) {
        // eslint-disable-next-line
        data.CLIENT_CERTIFICATES_CHECKBOX_LOGIN = !!(constants.SSO_ADMIN.LOGIN_MASK & mask);
        // eslint-disable-next-line
        data.CLIENT_CERTIFICATES_CHECKBOX_LOGOUT = !!(constants.SSO_ADMIN.LOGOUT_MASK & mask);
        // eslint-disable-next-line
        data.CLIENT_CERTIFICATES_CHECKBOX_REDIRECT = !!(constants.SSO_ADMIN.REDIRECT_MASK & mask);

        return data;
    },

    /**
     * - call the sync method
     * @method fetchComboData
     * @param {object} options - an options object
     */
    fetchComboData(options) {
        this.sync('combobox', this, options);
    },

    /**
     * - use only the column we need
     * @method parseColumn
     * @param {object} row - the row to parse
     * @param {number} i - the current index
     */
    parseColumn(row, i) {
        const rowParam = row;
        const lastRow = rowParam.columns.length - 1;
        const firstRow = 0;

        if (rowParam.columns[lastRow].fieldValue.indexOf('menugroup') === 0
            || rowParam.columns[lastRow].fieldValue.indexOf('menuitem') === 0) {
            rowParam.columns[lastRow].name = locale.get(rowParam.columns[lastRow].fieldValue);
        } else {
            rowParam.columns[lastRow].name = rowParam.columns[lastRow].fieldValue;
        }

        /**
         * @TODO NH-52870 - Tech Debt: We need to update Inquiry Calls to Return
         * Correct Field
         * Names
         */

        if (rowParam.columns[lastRow].fieldName === 'METHODCODE' || rowParam.columns[lastRow].fieldName === 'METHOD') {
            rowParam.columns[lastRow].fieldName = 'LOGOUT_TYPE';
        }

        if (rowParam.columns[lastRow].fieldName === 'DESCRIPTION') {
            rowParam.columns[lastRow].fieldName = rowParam.columns[firstRow].fieldName;
            rowParam.columns[lastRow].fieldValue = rowParam.columns[firstRow].fieldValue;
        }

        if (rowParam.columns[lastRow].fieldName === 'MENUDESCRIPTION') {
            rowParam.columns[lastRow].fieldName = rowParam.columns[firstRow].fieldName;
            rowParam.columns[lastRow].fieldValue = rowParam.columns[firstRow].fieldValue;
        }

        if (rowParam.columns[lastRow].fieldName === 'TPVTYPE') {
            rowParam.columns[lastRow].fieldName = 'TPV_TYPE';
        }

        if (rowParam.columns[lastRow].fieldName === 'USAGETYPE') {
            rowParam.columns[lastRow].fieldName = 'USAGE';
        }

        if (rowParam.columns[lastRow].fieldName === 'CONTENTTYPE') {
            rowParam.columns[lastRow].fieldName = 'CONTENT_TYPE';
        }
        rowParam.columns[lastRow].id = i;

        return rowParam.columns[lastRow];
    },

    /**
     * - convert the attributes of the model into a name/value pair format
     * @method convertModelAttributesToServerJSON
     * @param {object} model - the model to work on
     * @returns {object}
     */
    convertModelAttributesToServerJSON(model) {
        const jsonData = [];

        util.each(model.toJSON(), (value, key) => {
            if (key !== 'grids') {
                jsonData.push({
                    name: key,
                    value,
                });
            }
        });

        const serverJson = {
            item: {
                item: jsonData,
            },
        };
        if (this.method !== 'read' || this.method !== 'delete') {
            serverJson.grids = model.get('grids');
        }

        return serverJson;
    },
});
