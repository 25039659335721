import React from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import { withStyles } from '@glu/theming';

import styles from './Loading.styles';

const LoadingIndicator = ({ classes, primaryColumnLength }) => (
  <tr className={classes.root}>
    <td colSpan={primaryColumnLength + 1}>
      {locale.get('loading')}
      &hellip;
    </td>
  </tr>
);

LoadingIndicator.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  primaryColumnLength: PropTypes.number.isRequired
};

export default withStyles(styles)(LoadingIndicator);
