import React, {
  useRef, useState, useEffect, useCallback
} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import { DatePicker } from '@glu/datepicker-react';
import '../../themeDefaults';

const styles = ({ dataComponents, palette }) => ({
  pickerWrapper: {
    position: 'relative',
    width: '100%'
  },

  root: {
    '& > button': {
      '& .icon-calendar-next': {
        fill: ({ dark }) => (dark ? palette.text.light : palette.text.dark)
      }
    },

    '& input': {
      background: 'none',
      borderRadius: dataComponents.selection.borderRadius,
      color: ({ dark }) => (dark ? palette.text.light : palette.text.dark),
      lineHeight: 'inherit',
      width: '100%'
    }
  }
});

/**
 * Default date format that will be used to format the dates in the datepicker and for the
 * filter's label.
 * @type {string}
 */
export const DISPLAY_DATE_FORMAT = 'YYYY-MM-DD';

export const DATE_RANGE_FILTER_ID = 'dateRangeFilter';

const DateRangeFilter = ({
  className,
  classes,
  createFilterValue,
  datePickerProps,
  displayFormat,
  fieldId,
  filterId,
  headerName,
  htmlId,
  onChange,
  parseFilterValue,
  value,
  wrapperClassName
}) => {
  const wrapperRef = useRef(null);
  const [haveRef, setHaveRef] = useState(false);

  useEffect(() => {
    setHaveRef(!!wrapperRef.current);
  }, []);

  const onDatePicked = useCallback(
    (name, datePicked) => {
      const newFilterValue = createFilterValue(
        filterId,
        fieldId,
        displayFormat,
        datePicked
      );

      onChange(fieldId, newFilterValue);
    },
    [createFilterValue, filterId, fieldId, displayFormat, onChange]
  );

  const formatedValue = parseFilterValue(value);

  return (
    <div className={classes.pickerWrapper} ref={wrapperRef}>
      {haveRef ? (
        <DatePicker
          className={`${classes.root} ${className}`}
          disableInputAPI
          error=""
          forcePositionY
          format={displayFormat}
          htmlId={htmlId}
          labelText={headerName}
          name={fieldId}
          onApply={onDatePicked}
          screenReadeLabel
          value={formatedValue}
          wrapperClassName={wrapperClassName}
          {...datePickerProps}
        />
      ) : null}
    </div>
  );
};

DateRangeFilter.propTypes = {

  /** ClassName to use for this filter component */
  className: PropTypes.string,

  /** Classes for JSS styling */
  classes: PropTypes.objectOf(PropTypes.string).isRequired,

  /**
   * Creates the final filter value that is returned via the filter's onChange function whenever
   * the filter is changed.
   *
   * @param {string} filterId - The id of the filter
   * @param {string} fieldId - The id of the field being filtered
   * @param {string} displayFormat - The format string used to display the date in the date
   *    filter picker.
   * @param {array} dateRange - Array of moment's where the first entry is the startDate and the
   *    second, if present, is the endDate.
   * @return {*} - filter value matching the passed in arguments
   */
  createFilterValue: PropTypes.func.isRequired,

  /** For customizing the datepicker */
  datePickerProps: PropTypes.shape({
    config: PropTypes.shape({})
  }),

  /** Format string to use to format the date in the date picker */
  displayFormat: PropTypes.string,

  /** Unique identifier for the field of data you are filtering on */
  fieldId: PropTypes.string.isRequired,

  /** The id to use for the value returned from onChange */
  filterId: PropTypes.string,

  /** Name of filter field that this datepicker is associated with */
  headerName: PropTypes.string,

  /** Html id to use for the html control of this filter */
  htmlId: PropTypes.string.isRequired,

  /**
   * Function to call whenever the filter has been changed.
   *
   * @param {string} fieldId - The fieldId of this filter
   * @param {Object} value - The new value of the filter
   */
  onChange: PropTypes.func.isRequired,

  /**
   * Parses the criteria used by the filter out of its value which can include other data.
   * Date Range Filter criteria consist of an object with a startDate and endDate property,
   * both of which must be MomentJs date objects.
   *
   * @param {Object} filterValue - The value for the filter, which may contain data other than
   *    the criteria needed for the Date Range Filter.
   * @return {{
   *    endDate: moment.Moment, startDate: moment.Moment
   * }}
   */
  parseFilterValue: PropTypes.func.isRequired,

  /** The current value for this filter. */
  value: PropTypes.shape({}),

  /** Classname to use on the wrapper placed around the datepicker */
  wrapperClassName: PropTypes.string
};

DateRangeFilter.defaultProps = {
  className: '',
  datePickerProps: {},
  displayFormat: DISPLAY_DATE_FORMAT,
  filterId: DATE_RANGE_FILTER_ID,
  headerName: '',
  value: undefined,
  wrapperClassName: ''
};

export default withStyles(styles)(DateRangeFilter);
export const BareDateRangeFilter = DateRangeFilter;
