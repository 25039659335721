import React from 'react';
import PropTypes from 'prop-types';
import Layout from '@glu/layout-react';
import useLocale from 'hooks/useLocale';
import useStyles from './AuditHeader.styles';

const AuditHeader = ({
    columnHeaders,
    col,
}) => {
    const { getLocaleString } = useLocale();
    const classes = useStyles();
    return (
        <Layout container>
            <Layout col={col}>
                <p className={classes.header}>
                    {getLocaleString('audit.Action')}
                </p>
            </Layout>
            {columnHeaders.map(column => (
                <Layout col={col}>
                    <p
                        className={classes.header}
                        key={column.fieldName}
                    >
                        {column.label}
                    </p>
                </Layout>
            ))}
        </Layout>
    );
};
AuditHeader.propTypes = {
    columnHeaders: PropTypes.arrayOf(PropTypes.shape({
        fieldName: PropTypes.string,
        label: PropTypes.string,
    })).isRequired,
    col: PropTypes.number,
};
AuditHeader.defaultProps = {
    col: 2,
};
export default AuditHeader;
