import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import store from 'system/utilities/cache';
import constants from 'app/bab/constants';
import util from '@glu/core/src/util';
import alert from '@glu/alerts';
import Collection from '@glu/core/src/collection';
import { moveToTopCheck } from 'common/util/deeplinkUtil';
import transform from 'common/util/transform';
import alertMessage from 'common/api/alertMessage';
import WarningDialog from 'common/dynamicPages/views/warningDialog';
import helpPageUtil from 'common/util/helpPage';
import ItemsEffectedWarning from 'common/dynamicPages/views/itemsEffectedWarning';
import apiConstants from 'common/dynamicPages/api/constants';
import entitlements from 'common/dynamicPages/api/entitlements';
import orderingPartyUtil from 'common/util/orderingPartyLookupUtil';
import { getMaskingProperties, maskValue } from 'common/util/maskingUtil';
import AddressBook from '../service/addressBook';
import ContactModel from '../model/contactModel';
import viewContactTmpl from './viewContact.hbs';
import relatedDataGrid from './relatedDataGrid';
import InquiryService from '../service/inquiry';
import contactUtil from '../util/contactUtil';

const APPROVED = 'AP';

const ViewContact = Layout.extend({
    template: viewContactTmpl,

    ui: {
        alertRegion: '.alert-region',
        $relatedPaymentsContainer: '[data-hook="getRelatedPaymentsContainer"]',
        $relatedTemplatesContainer: '[data-hook="getRelatedTemplatesContainer"]',
    },

    regions: {
        alertRegion: '.alert-region',
    },

    unescapeData(dataObjParam) {
        const dataObj = dataObjParam;
        Object.keys(dataObj || {}).forEach((prop) => {
            dataObj[prop] = util.unescape(dataObj[prop]);
        });

        return dataObj;
    },

    initialize(options) {
        this.options = util.extend({}, options);
        store.set('current-workspace-route', options.lastFragment);
        this.contactModel = this.options.data
            ? new ContactModel(this.unescapeData(this.options.data)) : new ContactModel();
        this.mode = this.options.mode || 'create';
        this.isModal = (options.state === 'modal');
        this.addrAPI = new AddressBook();
        this.model = new Model({});
        this.accountsCollection = new Collection([]);
        this.dialogTitle = locale.get('bab.contact.view');
        this.corpOrSmb = options.corpOrSmb;
        this.secondaryAccountAllocationType = '';
        this.isOrderingParty = orderingPartyUtil.isEntitled(this.contactModel.get('ALLOWBYORDEROF'));

        this.dialogButtons = [{
            text: locale.get('bab.cancel.btn'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }];
        if (this.isOrderingParty) {
            this.getOrderingPartyTypes();
        }
        this.getCountries();
        // get the masking properties once
        this.maskingProps = getMaskingProperties();
    },

    /**
     * Polls inquiry service to get country names
     * @return {[type]} [description]
     */
    getCountries() {
        const countryService = new InquiryService();

        this.listenTo(countryService, 'success', this.setFullCountryName);

        countryService.setActionMode('VIEW');
        countryService.setFunctionCode('MAINT');
        countryService.setProductCode('_ADMIN');
        countryService.setInquiryId(17076);
        countryService.setTypeCode('BENEADBK');

        countryService.send();
    },

    /**
     * Polls inquiry service to get ordering party id type names
     * @return {[type]} [description]
     */
    getOrderingPartyTypes() {
        const orderingPartyTypeService = new InquiryService();

        this.listenTo(orderingPartyTypeService, 'success', this.setOrderingPartyIdType);

        orderingPartyTypeService.setActionMode('VIEW');
        orderingPartyTypeService.setFunctionCode('MAINT');
        orderingPartyTypeService.setProductCode('_ADMIN');
        orderingPartyTypeService.setInquiryId(19247);
        orderingPartyTypeService.setTypeCode('BENEADBK');

        orderingPartyTypeService.send();
    },

    /**
     * Uses data returned from inquiry service to find full country name Based
     * on contactModal BENE_COUNTRY abbreviated value
     * @param  {object} response Countries object returned from inquiry service
     */
    setFullCountryName(response) {
        const countryName = util.findWhere(response, { name: this.contactModel.get('BENE_COUNTRY') });
        // If we find a countryName object then set the BENE_COUNTRY and re-render the view
        if (countryName !== undefined) {
            this.contactModel.set('BENE_COUNTRY', countryName.label);
            this.render();
        }
    },

    /**
     * Uses data returned from inquiry service to find ordering party id type
     * description based on contactModal ORDERINGPARTY_ID_TYPE abbreviated value
     * @param  {object} response Typea object returned from inquiry service
     */
    setOrderingPartyIdType(response) {
        const typeName = util.findWhere(response, { name: this.contactModel.get('ORDERINGPARTY_ID_TYPE') });
        if (typeName !== undefined) {
            this.contactModel.set('ORDERINGPARTY_ID_TYPE', locale.get(typeName.label));
            this.render();
        }
    },

    delegateEvents() {
        Layout.prototype.delegateEvents.call(this);
        this.listenTo(this.accountsCollection, 'change reset set add', this.render);
    },

    /*
     * forces all properties of an object to an array.
     * TODO: Could this return a new object rather than mutating?
     */
    forceArray(objParam) {
        const obj = objParam;
        Object.keys(obj || {}).forEach((key) => {
            obj[key] = [obj[key]];
        });
        return obj;
    },

    // Merges two structures of arrays
    mergeStructure(struct, obj) {
        const structParam = struct;
        Object.keys(struct || {}).forEach((key) => {
            if (Object.prototype.hasOwnProperty.call(obj, key)
                && struct[key].indexOf(obj[key][0]) === -1) {
                structParam[key] = struct[key].concat(obj[key]);
            }
        });

        return struct;
    },

    loadViewRequirements() {
        return this.addrAPI.read(this.contactModel.get('TNUM')).then((result) => {
            let records = util.map(
                result.detailRecords,
                record => this.forceArray(transform.pairsToHash(record)),
            );
            /*
             PCM-5694 - Same fix on the personView.js
             allowBeneEmails made true always to have the Add email link available
             on the Contact ceter screen instead of entitling
             through ALLOWBENENOTIFICTIONSWIRES flag
             this.contactModel.set('allowBeneEmails', result.allowBeneEmails);
            */
            this.contactModel.set('allowBeneEmails', true);

            records = util.reduce(records, (memoParam, record) => {
                const memo = memoParam;
                // skip a draft record and avoid from listing as part of the account.
                if (record.PAYMENTTYPE[0].search(/draft/gi) > -1) {
                    this.loadDraftInfo(record);
                    return memo;
                }

                if (record.PAYMENTTYPE[0].search(/bpay/gi) > -1) {
                    this.loadBillPayInfo(record);
                    return memo;
                }

                const accountKey = `${record.BENE_ACCOUNTNUMBER}`;
                const currMemo = memo[accountKey];
                memo[accountKey] = currMemo ? this.mergeStructure(currMemo, record) : record;

                return memo;
            }, {});
            return records;
        }).then((records) => {
            const helpPagePromise = helpPageUtil.getHelpPagePromise({
                productCode: '_ADMIN',
                functionCode: 'MAINT',
                typeCode: 'BENEADBK',
                mode: '*',
            });

            const companyEntitlementPromise = entitlements.getCompanyEntitlements({
                context: {
                    serviceName: '/beneAddressBook',
                },
                data: {
                    productCode: '_ADMIN',
                    functionCode: 'MAINT',
                    typeCode: 'BENEADBK',
                    action: 'SELECT',
                },
            });

            Promise.all([
                helpPagePromise,
                companyEntitlementPromise,
            ]).then((results) => {
                const helperPageResults = results[0];
                const entitlementResults = results[1];

                this.isCompanyEntitledToTemplates = contactUtil
                    .companyIsEntitledToTemplates(entitlementResults);
                this.isCompanyEntitledToPayments = contactUtil
                    .companyIsEntitledToPayments(entitlementResults);
                this.isCompanyEntitledToRFPTemplates = contactUtil
                    .companyIsEntitledToRFPTemplates(entitlementResults);
                this.isCompanyEntitledToRFP = contactUtil
                    .companyIsEntitledToRFP(entitlementResults);
                this.relatedItemTypes = contactUtil.getRelatedItemTypes(this);
                this.relatedItemlabels = contactUtil.getRelatedItemLabels()[this.relatedItemTypes];
                this.relatedSectionLabel = locale.get(this.relatedItemlabels.sectionLabel);
                this.relatedPaymentsHeader = locale.get(this.relatedItemlabels
                    .paymentSectionHeader);

                store.set('helpPage', helperPageResults);
                this.setHasLoadedRequiredData(true);

                const tmp = Object.values(records || {});

                this.accountsCollection.reset(tmp);
            });
        });
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            moveToTopCheck(this.model);
            this.loadViewRequirements();
        }
        const hasOrderingPartyLookupConfig = orderingPartyUtil.isEntitled(this.contactModel.get('ALLOWBYORDEROF'));

        relatedDataGrid.setRelatedGridDataListener({
            $container: this.ui.$relatedPaymentsContainer,
            gridRegion: this.relatedPaymentsGridRegion,
            inquiryId: apiConstants.babItemsAffected.INQUIRYID_PAYMENTS_AFFECTED,
            tnum: this.contactModel.get('TNUM'),
            allowOrderingParty: hasOrderingPartyLookupConfig ? '1' : '0',
            relatedItemTypes: this.relatedItemTypes,
            templateGridOptions: {
                $container: this.ui.$relatedTemplatesContainer,
                gridRegion: this.relatedTemplatesGridRegion,
                inquiryId: apiConstants.babItemsAffected.INQUIRYID_TEMPLATES_AFFECTED,
                tnum: this.contactModel.get('TNUM'),
                allowOrderingParty: hasOrderingPartyLookupConfig ? '1' : '0',
                relatedItemTypes: this.relatedItemTypes,
            },
        });
    },

    onSubmit() {
        return false;
    },

    cancel() {
        if (this.isModal) {
            dialog.close();
        } else {
            window.history.back();
            /*
             * corp and smb have two different urls for the listing grid.
             * this.navigateTo('SETUP/manageBeneAddressBook');
             */
        }
    },

    approve() {
        const model = store.get(constants.STORE_BAB_VIEWMODEL);
        model.context = store.get(constants.STORE_BAB_CONTEXT);
        model.context.serviceName = 'beneAddressBook';

        model.approve({
            success: (mod, resp) => {
                if (resp.errorCode === apiConstants.ITEMS_EFFECTED_WARNING_CODE) {
                    const itemsEffectedWarning = new ItemsEffectedWarning({
                        resp,
                        actionMode: 'APPROVE',
                        model: mod,
                    });
                    itemsEffectedWarning.once('approveContact', this.approve, this);
                    dialog.custom(itemsEffectedWarning);
                } else if (!resp.result) {
                    alertMessage.renderMessage(this, null, resp, 0, true);
                } else {
                    store.set('addContactSuccessMessage', locale.get('bab.contact.approved.success'));
                    this.cancel();
                }
            },

            error: () => {
                this.alertRegion.show(alert.danger(locale.get('bab.contact.approved.error')));
            },
        });
    },

    delete() {
        const self = this;
        const model = store.get(constants.STORE_BAB_VIEWMODEL);

        dialog.confirm(locale.get('tableMaintenance.dialog.confirm.item.delete'), locale.get('tableMaintenance.dialog.confirm.title.delete'), (ok) => {
            if (ok) {
                model.context = store.get(constants.STORE_BAB_CONTEXT);
                model.context.serviceName = 'beneAddressBook';
                model.destroy({
                    success(successModel, resp) {
                        let isWarning = false;
                        if (resp && resp.confirms && resp.confirms.confirmResults[0] && resp.confirms.confirmResults[0].resultType === 'WARNING') {
                            isWarning = true;
                        }

                        if (resp.errorCode === apiConstants.ITEMS_EFFECTED_WARNING_CODE) {
                            const itemsEffectedWarning = new ItemsEffectedWarning({
                                resp,
                                model: successModel,
                                actionMode: 'DELETE',
                            });
                            itemsEffectedWarning.once('deleteContact', self.delete, self);
                            dialog.custom(itemsEffectedWarning);
                        } else if (isWarning) {
                            self.listenTo(successModel, 'modelAction:deleteWithWarning', self.deleteWithWarning);
                            dialog.custom(new WarningDialog({
                                model: successModel,
                                methodName: 'DELETE',
                                grid: self.gridView,
                                confirms: resp.confirms,
                            }));
                        } else {
                            store.set('addContactSuccessMessage', locale.get('bab.contact.deleted.success'));
                            // return to listing screen
                            self.cancel();
                        }
                    },

                    error() {
                        self.alertRegion.show(alert.danger(locale.get('bab.contact.deleted.error')));
                    },
                });
            }
        });
    },

    deleteWithWarning(model) {
        model.set('_saveWithWarning', 'true');
        this.delete();
    },

    modify() {
        this.navigateTo(`SETUP/modifyContact/${this.corpOrSmb}`);
    },

    loadDraftInfo(rec) {
        this.draftAddr = util.compact([
            rec.BENE_ADDRESS_1_CHILD[0],
            rec.BENE_ADDRESS_2_CHILD[0],
            rec.BENE_COUNTRY_CHILD[0],
        ]).join(', ');

        this.draftCurrency = rec.BENE_ACCOUNT_CURRENCY ? rec.BENE_ACCOUNT_CURRENCY[0] : '';
        this.hasDraft = true;
    },

    loadBillPayInfo(rec) {
        [this.billPayCurrency] = rec.BENE_ACCOUNT_CURRENCY;
        this.billPayBiller = `${rec.BENE_BANK_NAME[0]} - ${rec.BENE_BANK_ID[0]}`;
        [this.billPayAccount] = rec.BENE_ACCOUNTNUMBER;

        this.hasBillPay = true;
    },

    isBankAcctNumPresent() {
        return this.BENE_BANK_ACCOUNT_NUMBER && this.BENE_BANK_ACCOUNT_NUMBER[0];
    },

    isFreeForm() {
        return this.BENEBANKIDENTRYMETHOD[0] === 'FREEFORM';
    },

    isIntermediaryFreeForm() {
        return this.INTERBANKIDENTRYMETHOD[0] === 'FREEFORM';
    },

    intermediary2HasBankAccountNumber() {
        return this.INTER_BANK_ACCOUNT_NUMBER && this.INTER_BANK_ACCOUNT_NUMBER[0];
    },

    hasIntermediary1() {
        return this.INTERMEDIARY_ID && this.INTERMEDIARY_ID[0];
    },

    hasIntermediary2() {
        if (this.INTERBANKIDENTRYMETHOD && this.INTERBANKIDENTRYMETHOD[0] === 'FREEFORM') {
            return this.INTERMEDIARY2_NAME && this.INTERMEDIARY2_NAME[0];
        }
        return this.INTERMEDIARY2_ID && this.INTERMEDIARY2_ID[0];
    },

    hasSecondaryAccount() {
        return this.SECOND_BENE_BANK_ID && this.SECOND_BENE_BANK_ID[0];
    },

    /**
     * @method onClose
     * @description - method that is invoked when the view is closed.
     * If we are not a batch child view, then unset the helpPage that is used for
     * the global help.
     *
     */
    onClose() {
        store.unset('helpPage'); // remove view helppage from cache
    },

    /**
     * Checks the allocation type for various handlebars logical flows
     * @param {string} valueToReturn1 - The string to return if the allocation type is '1'
     * @param {string} valueToReturn2 - The string to return if the allocation type is '2'
     */
    checkAllocationType(valueToReturn1, valueToReturn2) {
        if (this.secondaryAccountAllocationType === '1') {
            return valueToReturn1;
        }
        if (this.secondaryAccountAllocationType === '2') {
            return valueToReturn2;
        }
        return undefined;
    },

    templateHelpers() {
        const self = this;

        return {
            contact: this.contactModel.toJSON(),
            accounts: this.accountsCollection.toJSON(),
            allowBeneEmails: this.contactModel.get('allowBeneEmails'),
            templatesEntitled: self.isCompanyEntitledToTemplates
                 || self.isCompanyEntitledToRFPTemplates,
            servicesEntitled: self.isCompanyEntitledToPayments || self.isCompanyEntitledToRFP,
            contactType() {
                const localeHash = {
                    1: locale.get('bab.business'),
                    2: locale.get('bab.individual'),
                    3: locale.get('bab.employee'),
                    4: `${locale.get('bab.individual')}, ${locale.get('bab.employee')}`,
                };

                return localeHash[self.contactModel.get('CONTACTTYPE')] || locale.get('bab.na');
            },

            contactAddr() {
                let addr = '';

                // broken into 3 operations to allow for different delimiters within 'join'
                addr = util.compact([
                    self.contactModel.get('BENE_ADDRESS_1'),
                    self.contactModel.get('BENE_ADDRESS_2'),
                    self.contactModel.get('BENE_CITY'),
                ]).join('<br/>');

                addr = util.compact([
                    addr,
                    self.contactModel.get('BENE_STATE'),
                    self.contactModel.get('BENE_PROVINCE'),
                    self.contactModel.get('BENE_POSTALCODE'),
                ]).join(', ');

                addr = util.compact([
                    addr,
                    self.contactModel.get('BENE_COUNTRY'),
                ]).join('<br/>');

                return addr;
            },

            pageTitle() {
                return self.contactModel.get('BENE_NAME');
            },

            hasContactPersonInfo() {
                return (self.contactModel.get('BENE_CONTACT_NAME') || self.contactModel.get('BENE_EMAIL1') || self.contactModel.get('BENE_PHONENUMBER') || self.contactModel.get('BENE_PHONE_MOBILE') || self.contactModel.get('BENE_FAXNUMBER'));
            },

            hasDeleteEntitlement() {
                return store.get('setup:contact:delete:entitlement');
            },

            hasModifyEntitlement() {
                return store.get('setup:contact:modify:entitlement');
            },
            hasLegalEntityInfo() {
                return (self.contactModel.get('LEGAL_ENTITY_ID'));
            },

            hasApproveEntitlement() {
                return store.get('setup:contact:approve:entitlement');
            },

            shouldSeeApproveButton() {
                return store.get('setup:contact:approve:entitlement') && store.get('beneficiary address book view model').get('STATUS') !== APPROVED;
            },

            hasDraftInfo() {
                return self.hasDraft;
            },

            hasDraftCurrency() {
                return self.draftCurrency;
            },

            hasDraftAddr() {
                return self.draftAddr;
            },

            getDraftAddr() {
                return self.draftAddr;
            },

            getDraftCurrency() {
                return self.draftCurrency;
            },

            isOrderingParty() {
                return self.contactModel.get('ALLOWORDERINGPARTYLOOKUP') === '1'
                    && orderingPartyUtil.isEntitled(self.contactModel.get('ALLOWBYORDEROF'));
            },

            hasBillPayInfo: self.hasBillPay,
            billPayBiller: this.billPayBiller,
            billPayCurrency: this.billPayCurrency,
            billPayAccount: this.billPayAccount,
            isFreeForm: this.isFreeForm,
            isIntermediaryFreeForm: this.isIntermediaryFreeForm,
            intermediary2HasBankAccountNumber: this.intermediary2HasBankAccountNumber,

            formatAllocation() {
                return self.checkAllocationType(`${this}%`, `${this}`);
            },

            isFixed() {
                return self.secondaryAccountAllocationType === '2';
            },

            getAllocationType() {
                self.secondaryAccountAllocationType = String(this);
                return self.checkAllocationType(locale.get('bab.percent'), locale.get('bab.fixedamount'));
            },

            getAllocationLabel() {
                return self.checkAllocationType(locale.get('bab.percent'), locale.get('bab.allocation.amount'));
            },

            hasIntermediary1: this.hasIntermediary1,
            hasIntermediary2: this.hasIntermediary2,
            hasSecondaryAccount: this.hasSecondaryAccount,
            isBankAcctNumPresent: this.isBankAcctNumPresent,
            isModal: this.isModal,
            isSMB: this.corpOrSmb === 'smb',
            relatedSectionLabel: self.relatedSectionLabel,
            relatedPaymentsHeader: self.relatedPaymentsHeader,

            shouldShowRelatedGrids() {
                return this.accounts.length
                    || this.hasBillPayInfo
                    || this.hasDraftInfo()
                    || this.isOrderingParty();
            },
            maskIt: value => maskValue(value, this.maskingProps),
        };
    },
});

export default ViewContact;
