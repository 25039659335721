import MultiAddLayout from './views/multiAddLayoutView';

export default {
    /**
     * @name multiAdd
     * @description displays the generic (non-mdf) multi add page
     * @param type
     */
    multiAdd(type) {
        this.showPage('', new MultiAddLayout({
            type,
            localeKey: 'ps.multiItem_',
        }));
    },
};
