function getEntitlementsOptions(typeCode) {
    return {
        typeCode,
        productCode: 'SMAINT',
        functionCode: 'MAINT',
        entryMethod: 0,
        overrideContext: {
            serviceName: '/userMaintenance/user',
        },
    };
}

export const outboundScheduleConfigEntitlementsOptions = getEntitlementsOptions('OBSCH_TM');

export const outboundScheduleEntitlements =
    [outboundScheduleConfigEntitlementsOptions];

