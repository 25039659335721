import Layout from '@glu/core/src/layout';
import store from 'system/utilities/cache';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import alert from '@glu/alerts';
import locale from '@glu/locale';
import services from 'services';
import gridApi from 'common/dynamicPages/api/grid';
import entitlements from 'common/dynamicPages/api/entitlements';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import constants from 'app/bab/constants';
import contextApi from 'common/dynamicPages/api/context';
import apiconstants from 'common/dynamicPages/api/constants';
import loadingTemplate from 'common/templates/loadingWidget.hbs';
import ListView from 'common/dynamicPages/views/workflow/list';
import transform from 'common/util/transform';
import WarningDialog from 'common/dynamicPages/views/warningDialog';
import workspaceHelper from 'common/workspaces/api/helper';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import printExportUtil from 'common/util/printExportUtil';
import ItemsEffectedWarning from 'common/dynamicPages/views/itemsEffectedWarning';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import tpl from './addressBookGridLayout.hbs';

const AddressBookGridLayout = Layout.extend(util.extend(
    {},
    printExportUtil,
    {
        template: tpl,
        loadingTemplate,

        regions: {
            gridRegion: '#babGrid',
            alertRegion: '.page-alerts',
        },

        events: {
            'click .add-contact': 'addContact',
            'click [data-hook="print-button"]': 'showPrintOptionsModal',
            'click [data-hook="export-button"]': 'showExportOptionsModal',
            'click [data-hook="refresh-button"]': 'refreshData',
            'click [data-hook="getDeleteContacts"]': 'delete',
            'click [data-hook="getApproveContacts"]': 'approve',
        },

        initialize() {
            this.gridLoaded = false;
            this.contextDef = contextApi.menuContext.getContext('SMB_CONTACT');
            ListView.prototype.setListViewConfig.call(this, this.contextDef);
            this.loadViewRequirements();
        },

        onRender() {
            if (this.hasLoadedRequiredData()) {
                this.gridRegion.show(this.gridView);
                this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
                if (store.has('addContactSuccessMessage')) {
                    this.alertRegion.show(alert.success(store.get('addContactSuccessMessage')));
                    store.unset('addContactSuccessMessage');
                }
            }
        },

        templateHelpers() {
            const self = this;

            return {
                hasInsertEntitlement() {
                    return self.hasEntitlement('INSERT');
                },

                getButtonString(messageCode) {
                    return locale.get(messageCode);
                },

                hasApproveEntitlement() {
                    return self.hasEntitlement('APPROVE');
                },

                hasDeleteEntitlement() {
                    return self.hasEntitlement('DELETE');
                },

                gridUtilityOptions: {
                    hasRefresh: true,
                },
            };
        },

        loadViewRequirements() {
            /*
             * TODO: the context is hardcoded to pull in the corp service because
             *  contextApi.menuContext.getContext(constants.GRID_CONTEXT) doesn't work
             * for smb
             */
            const options = {
                /*
                 * contextApi.menuContext.getContext(constants.GRID_CONTEXT),
                 * default grid action buttons are hidden
                 */
                context: {
                    actionMode: 'SELECT',
                    displayOrder: 11,
                    filterID: 17056,
                    functionCode: 'MAINT',
                    gridId: 17056,
                    inquiryId: 0,
                    menuCatergory: 'setup',
                    menuContext: 'beneAddressBook/listView/corp',
                    menuDescription: 'Address Book',
                    name: 'Address Book',
                    nonDashboardDisplay: 0,
                    productCode: '_ADMIN',
                    requestMappings: 'manageBeneAddressBook',
                    requestParams: '?!_init=true&_productCode=_ADMIN&_functionCode=MAINT&_typeCode=BENEADBK&_mode=SELECT&_gridId=17056&_filterID=17056',
                    serviceName: 'beneAddressBook/listView/corp',
                    typeCode: 'BENEADBK',
                },

                // instead of executing context actions, grid handles callback
                hideGridActionButtons: true,

                enableRowContextButtonCallbacks: true,
                enableSavedViews: true,
                lvc: this.lvc,

                loadedCallback: () => {
                    this.gridLoaded = true;
                },
            };
            this.gridView = gridApi.createServiceGridView(options);
            const entitlementPromise = entitlements.getEntitlements(options);
            ListView.prototype.setupGridAvailableListener.call(this);

            return entitlementPromise.then((result) => {
                this.entitlements = result;
                if (!mobileUtil.isMobileGridEnabled()) {
                    this.setHasLoadedRequiredData(true);
                    this.listenTo(this.gridView.getRows(), 'sync', ListView.prototype.updateRefreshTimestamp);
                    ListView.prototype.listenForGridErrors.call(this);
                    this.render();
                }
                return result;
            });
        },

        hasEntitlement(action) {
            return this.entitlements.actions[action];
        },

        gridRowDelete(options) {
            return new Promise((resolve, reject) => {
                const self = this;
                options.model.destroy({
                    success(model, resp) {
                        let isWarning = false;
                        if (resp && resp.confirms && resp.confirms.confirmResults[0] && resp.confirms.confirmResults[0].resultType === 'WARNING') {
                            isWarning = true;
                        }
                        if (resp.errorCode === apiconstants.ITEMS_EFFECTED_WARNING_CODE) {
                            const itemsEffectedWarning = new ItemsEffectedWarning({
                                resp,
                                actionMode: 'DELETE',
                                model,
                            });
                            itemsEffectedWarning.once('deleteContact', self.gridRowDelete, self);
                            itemsEffectedWarning.once('cancelDeleteContact', self.gridRowCancelDeleteWithWarning, self);
                            dialog.custom(itemsEffectedWarning);
                        } else if (isWarning) {
                            self.listenTo(model, 'modelAction:deleteWithWarning', self.gridRowDeleteWithWarning);
                            self.listenTo(model, 'modelAction:errorForDetail', self.gridRowCancelDeleteWithWarning);
                            dialog.custom(new WarningDialog({
                                model,
                                methodName: 'DELETE',
                                grid: self.gridView,
                                confirms: resp.confirms,
                            }));
                        } else {
                            self.gridView.refreshGridData();
                            self.renderMessage('DELETE', resp);
                        }
                        resolve({ model, result: resp });
                    },

                    error(e) {
                        self.gridView.refreshGridData();
                        self.renderMessage('DELETE');
                        reject(e);
                    },
                });
            });
        },

        gridRowApprove(options) {
            return new Promise((resolve, reject) => {
                const self = this;
                options.model.approve({
                    success(model, resp) {
                        if (resp.errorCode === apiconstants.ITEMS_EFFECTED_WARNING_CODE) {
                            const itemsEffectedWarning = new ItemsEffectedWarning({
                                resp,
                                actionMode: 'APPROVE',
                                model,
                            });
                            itemsEffectedWarning.once('approveContact', self.gridRowApprove, self);
                            dialog.custom(itemsEffectedWarning);
                        } else if (resp.resultType === 'WARNING') {
                            dialog.custom(new WarningDialog({
                                model,
                                methodName: 'APPROVE',
                                grid: self.gridView,
                                confirms: resp.confirms,
                            }));
                        } else {
                            self.gridView.refreshGridData();
                            self.renderMessage(options.action, resp);
                            resolve({ model, result: resp });
                        }
                    },

                    error(e) {
                        self.gridView.refreshGridData();
                        self.renderMessage(options.action);
                        reject(e);
                    },
                });
            });
        },

        handleEntitlements() {
            if (this.hasEntitlement(constants.ACTION_MODIFY)) {
                store.set('setup:contact:modify:entitlement', true);
            } else {
                store.unset(
                    'setup:contact:modify:entitlement',
                    {
                        silent: true,
                    },
                );
            }

            if (this.hasEntitlement(constants.ACTION_APPROVE)) {
                store.set('setup:contact:approve:entitlement', true);
            } else {
                store.unset(
                    'setup:contact:approve:entitlement',
                    {
                        silent: true,
                    },
                );
            }

            if (this.hasEntitlement(constants.ACTION_DELETE)) {
                store.set('setup:contact:delete:entitlement', true);
            } else {
                store.unset(
                    'setup:contact:delete:entitlement',
                    {
                        silent: true,
                    },
                );
            }
        },

        gridRowAction(optionsParam) {
            const options = optionsParam;
            if (options.action.toUpperCase() === 'SELECT') {
                store.set(constants.STORE_BAB_CONTEXT, options.model.context);
                store.set(constants.STORE_BAB_VIEWMODEL, options.model);
                this.handleEntitlements();
                this.navigateTo('SETUP/viewContact/smb');
            }

            if (options.action.toUpperCase() === 'DELETE') {
                options.model.context.serviceName = 'beneAddressBook';
                return this.gridRowDelete(options);
            }

            if (options.action.toUpperCase() === 'APPROVE') {
                options.model.context.serviceName = 'beneAddressBook';
                return this.gridRowApprove(options);
            }
            if (options.action.toUpperCase() === 'MODIFY') {
                return this.gridRowModify(options);
            }
            return Promise.resolve();
        },

        gridRowModify(options) {
            store.set(constants.STORE_BAB_CONTEXT, options.model.context);
            store.set(constants.STORE_BAB_VIEWMODEL, options.model);
            this.navigateTo('SETUP/modifyContact/smb');
            return Promise.resolve();
        },

        addContact() {
            this.navigateTo('SETUP/addContact/smb');
            return Promise.resolve();
        },

        addContactSuccess() {
            if (store.has('addContactSuccessMessage')) {
                this.alertRegion.show(alert.success(store.get('addContactSuccessMessage')));
                this.gridView.refreshGridData();
                store.unset('addContactSuccessMessage');
            }
        },

        addTemplateSelected(model) {
            store.set(constants.STORE_BAB_CONTEXT, model.get('context'));
            this.navigateTo('payments/addTemplate');
        },

        approve() {
            this.handleBulkAction(constants.ACTION_APPROVE);
        },

        delete() {
            this.handleBulkAction(constants.ACTION_DELETE);
        },

        handleBulkAction(methodName, args) {
            if (this.alertRegion) {
                this.alertRegion.close();
            }

            if (!this.gridLoaded) {
                return;
            }

            const self = this;
            let message = '';
            const ar = this.gridView.grid.getSelectedRows();
            let eligibleRows = ar;
            let model;
            let approvalFlag;

            if (ar.length === 0) {
                return;
            }

            /*
             * TODO: Refactor this - Needs comments and could be implemented without
             * nested for loops
             */
            if (methodName === apiconstants.ACTION_APPROVE) {
                for (let i = 0; i < ar.length; i += 1) {
                    model = this.gridView.wrapper.rows.get(ar[i]);
                    approvalFlag = false;
                    for (let j = 0; j < model.buttons.length; j += 1) {
                        if (model.buttons[j].action === 'APPROVE') {
                            approvalFlag = true;
                        }
                    }
                    if (!approvalFlag) {
                        eligibleRows = util.without(eligibleRows, ar[i]);
                        message = locale.get('bab.not.eligible');
                    }
                }
            }

            const translatedAction = this.translateActionText(methodName);

            dialog.confirm(`${message + locale.get('title.ConfirmActionPrefix')} <span class="method-name">${translatedAction}</span> ${locale.get('title.ConfirmActionSuffix')}`, `${locale.get('title.confirm')} ${translatedAction}`, (ok) => {
                if (ok) {
                    self.processMultiActionsAsOne(methodName, eligibleRows, args);
                }
            });
        },

        translateActionText(action) {
            let translatedAction = locale.get(`action.${action}`);
            if (translatedAction.indexOf('??') > -1) {
                translatedAction = action;
            }
            return translatedAction;
        },

        processMultiActionsAsOne(methodName, selectedArray, additionalParams) {
            const self = this;
            let service;
            let item;
            let model;

            const jsonData = {
                items: [],
            };

            if (selectedArray.length <= 0) {
                return;
            }
            service = services.generateUrl(self.contextDef.menuContext);
            if (methodName === apiconstants.ACTION_APPROVE || methodName === 'MULTICHANGEWARNING-APPROVE') {
                service += apiconstants.URL_APPROVE_ACTION;
            } else if (methodName === constants.ACTION_DELETE || methodName === 'MULTICHANGEWARNING-DELETE') {
                service += apiconstants.URL_DELETE_ACTION;
            }

            for (let i = 0; i < selectedArray.length; i += 1) {
                model = this.gridView.wrapper.rows.get(selectedArray[i]);
                if (methodName === apiconstants.ACTION_DELETE) {
                    item = model.convertModelAttributesToKeyWithOptContextJSON(model, true);
                    if (!util.isEmpty(additionalParams)) {
                        item.item.push(additionalParams);
                    }
                }
                if (methodName === apiconstants.ACTION_APPROVE) {
                    item = model.convertModelAttributesToKeyWithOptContextJSON(model, true);
                }

                if (methodName === 'MULTICHANGEWARNING-APPROVE' || methodName === 'MULTICHANGEWARNING-DELETE') {
                    model.set(apiconstants.CONTACT_CHANGES_ACCEPTED_INDICATOR, true);
                    item = model.convertModelAttributesToKeyWithOptContextJSON(model, true);
                }

                jsonData.items.push(item);
            }

            const handleMultipleDeleteWarning = function (resp) {
                const warningModelsArray = [];
                let confirmMap;
                let selModel;

                if (resp && resp.confirms) {
                    util.each(resp.confirms.confirmResults, (confirmResult) => {
                        if (confirmResult.resultType === 'WARNING' && confirmResult.confirmData[0] && confirmResult.confirmData[0].item) {
                            confirmMap = transform.pairsToHash(confirmResult.confirmData[0].item);
                            util.each(selectedArray, (selectedItem) => {
                                selModel = self.gridView.wrapper.rows.get(selectedItem);
                                if (selModel.get('TNUM') === confirmMap.ID) {
                                    warningModelsArray.push(selModel);
                                }
                            });
                        }
                    });
                }

                self.multipleDeleteWarning(methodName, resp, warningModelsArray);
            };
            const handleMultiItemsResponse = function (confirmResponse) {
                self.renderMessage(methodName, confirmResponse);
            };

            const options = {
                action: methodName,
            };
            options.success = function (resp) {
                let isWarning = false;

                if (resp && resp.confirms && methodName === apiconstants.ACTION_DELETE) {
                    util.each(resp.confirms.confirmResults, (confirmResult) => {
                        if (confirmResult.resultType === 'WARNING') {
                            isWarning = true;
                        }
                    });
                }
                if (resp.errorCode === apiconstants.ITEMS_EFFECTED_WARNING_CODE) {
                    self.multipleItemsEffectedWarning(resp, selectedArray, methodName);
                } else if (isWarning) {
                    handleMultipleDeleteWarning(resp);
                } else {
                    self.gridView.refreshGridData();
                    handleMultiItemsResponse(resp);
                }
            };
            options.error = function (resp) {
                self.gridView.refreshGridData();
                handleMultiItemsResponse(resp);
            };

            http.post(service, jsonData, (result) => {
                options.success(result);
            }, (result) => {
                options.error(result.responseJSON);
            });
        },

        multipleItemsEffectedWarning(resp, selectedArray, methodName) {
            const itemsEffectedWarning = new ItemsEffectedWarning({
                resp,
                selectedArray,
                actionMode: `MULTICHANGEWARNING-${methodName}`,
            });
            itemsEffectedWarning.once('multiWarning', this.processMultiActionsAsOne, this);
            itemsEffectedWarning.once('cancelDeleteContact', this.actionDeleteWithResponse, this);
            dialog.custom(itemsEffectedWarning);
        },

        multipleDeleteWarning(action, confirmResponse, selectedModelArray) {
            const warningDialog = new WarningDialog({
                methodName: action,
                //  multipleApproval: true,
                modelsArray: selectedModelArray,
                confirms: confirmResponse,
            });
            warningDialog.once('list:multi:action:action_deleteWithWarning', this.actionDeleteWithWarning, this);
            warningDialog.once('list:multi:action:action_continueWithResponse', this.actionDeleteWithResponse, this);
            dialog.custom(warningDialog);
        },

        actionDeleteWithItemsEffectedWarning(action, modelsArray, args) {
            this.processMultiActionsAsOne(constants.ACTION_DELETE, modelsArray, args);
        },

        actionDeleteWithWarning(modelsArray, args) {
            this.processMultiActionsAsOne(constants.ACTION_DELETE, modelsArray, args);
        },

        actionDeleteWithResponse() {
            this.gridView.refreshGridData();
        },

        renderMessage(action, confirmResponse, errorCount) {
            /*
             * create the confirmation view for the alert
             * skip if action is null.  This occurs when first entering the payment workspace
             * because the onRender is called.
             */
            if ((action === null || action === undefined)
                && (confirmResponse === null || confirmResponse === undefined)) {
                // TODO: Refactor out empty block
            } else if (confirmResponse === null || confirmResponse === undefined) {
                if (action.type === 'WARNING' && action.message) {
                    this.alertView = alert.warning(action.message);
                    this.alertRegion.show(this.alertView);
                }
            } else {
                const confirm = typeof confirmResponse === 'object' ? confirmResponse.confirms : null;
                const successCount = confirm !== null ? confirm.totalSuccess : confirmResponse;
                const failCount = confirm !== null ? confirm.totalFail : errorCount;
                const totalCount = failCount + successCount;
                const success = successCount === totalCount;
                const alertFunc = success ? alert.success : alert.danger;
                const chainedMessages = typeof confirmResponse === 'object' ? confirmResponse.message : null;
                const message = typeof confirmResponse === 'object' && confirmResponse.message.length > 0 ? confirmResponse.message[chainedMessages.length - 1] : action;
                let isWarning = false;
                if (confirmResponse && confirmResponse.confirms && confirmResponse.confirms.confirmResults[0] && confirmResponse.confirms.confirmResults[0].resultType === 'WARNING') {
                    isWarning = true;
                }
                const confirms = new Confirms({
                    confirms: confirmResponse ? confirmResponse.confirms : null,
                });

                if (isWarning) {
                    this.alertView = alert.warning(message);
                } else {
                    // display notification message
                    this.alertView = alertFunc(
                        message,
                        {
                            details: confirmResponse
                            && confirmResponse.confirms.confirmResults[0].confirmData[0].item
                                ? confirms : null,
                            canDismiss: !!confirmResponse,
                            animate: true,
                        },
                    );
                }

                if (message) {
                    this.alertRegion.show(this.alertView);
                }
            }
        },

        print() {
            this.buildExportModel('PDF');
            const printModal = new PrintViewModal({
                exportModel: this.exportModel,
            });
            dialog.custom(printModal);
        },

        refreshData() {
            ListView.prototype.refreshData.call(this);
        },

        /**
         * Get print options for this list
         * @returns {Object}
         */
        getPrintOptions() {
            return {
                // TODO update INQUIRY_ID when working on NH-89819
                inquiryId: apiconstants.INQUIRY_ID_20018,
            };
        },

        /**
         * Get export options for the list
         * @returns {Object}
         */
        getExportOptions() {
            return {
                inquiryId: apiconstants.exportInquiry.INQUIRY_ID_72206,
            };
        },
    },
));

let list = AddressBookGridLayout;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        insertActions: [
            {
                label: 'bab.contact.add',
                entitlement: 'INSERT',
                handlerMethodName: 'addContact',
            },
        ],
        bulkActions: [
            {
                label: 'button.approve',
                entitlement: 'APPROVE',
            },
            {
                label: 'button.delete',
                entitlement: 'DELETE',
            },
        ],
    });
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'SMBCONTACTLIST',
    view: list,
    options: {},
});

const exportedList = list;

export default exportedList;
