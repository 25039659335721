import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import moment from 'moment';
import Formatter from 'system/utilities/format';
import domUtil from 'common/util/domUtil';
import userInfo from 'etc/userInfo';
import template from './ctxAddendaChild_InsertModify.hbs';
import viewTemplate from './ctxAddendaChild_View.hbs';

export default ItemView.extend({
    template,
    viewTemplate,
    tagName: 'div',
    className: 'panel panel-secondary ctxaddenda-panel',

    ui: {
        $RMT_REFNUMBERQUALIFIER: '[data-hook="getRMT_REFNUMBERQUALIFIER"]',
        $REF_REFNUMBERQUALIFIER: '[data-hook="getREF_REFNUMBERQUALIFIER"]',
        $DAT_REFNUMBERQUALIFIER: '[data-hook="getDAT_REFNUMBERQUALIFIER"]',
        $ADJREASONCODE: '[data-hook="getADJREASONCODE"]',
        $DAT_REFNUMBER: '[data-hook="getDAT_REFNUMBER"]',
        $AMOUNTTOADJ: '[data-hook="getAmountToAdj"]',
    },

    events: {
        'hide.bs.collapse': 'handlePanelHide',
        'click #ADDADDENDA': 'saveNewGrandchild',
    },

    getTemplate() {
        if (this.options.parentState === 'view') {
            return this.viewTemplate;
        }
        return this.template;
    },

    onRender() {
        const today = moment(new Date()).format(userInfo.getDateFormat());
        const selectedDate = Formatter.formatDateFromUserFormat(this.model.get('DAT_REFNUMBER'), userInfo.getDateFormat());
        const dateOptions = {
            showCalendarIcon: true,
            showDropdowns: true,
            daysBack: 0,
            minDate: today,
            allowWeekends: true,
            startDate: selectedDate || today,
        };

        if (this.options.parentState !== 'view') {
            this.ui.$RMT_REFNUMBERQUALIFIER.comboBox({
                allowClear: true,
            });
            this.ui.$REF_REFNUMBERQUALIFIER.comboBox({
                allowClear: true,
            });
            this.ui.$DAT_REFNUMBERQUALIFIER.comboBox({
                allowClear: true,
            });
            this.ui.$ADJREASONCODE.comboBox({
                allowClear: true,
            });
            this.ui.$DAT_REFNUMBER.nhDatePicker(dateOptions);
            this.ui.$AMOUNTTOADJ.inputmask('decimal', {
                prefix: '-',
                digits: '2',
                placeholder: '0.00',
                digitsOptional: false,
            });
        }
    },

    templateHelpers() {
        const self = this;
        return {
            isSavedAddenda: !(Object.prototype.hasOwnProperty.call(this.model, 'isFresh')),
            isFresh: (Object.prototype.hasOwnProperty.call(this.model, 'isFresh')),
            options: this.options.meta,
            cid: this.cid,
            valueFor(field) {
                let returnValue = '';
                const value = self.model.get(field);
                if (!util.isEmpty(value)) {
                    util.each(self.options.meta[field].choices, (choice) => {
                        if (choice.name === value) {
                            returnValue = choice.value;
                        }
                    });
                }
                return returnValue;
            },
            hasAnyReferenceInformation() {
                return (!util.isEmpty(self.model.get('REF_REFNUMBERQUALIFIER')) || !util.isEmpty(self.model.get('REF_REFNUMBER') || !util.isEmpty(self.model.get('PAYMENTDESCRIPTION'))));
            },
            hasAnyDateInformation() {
                return (!util.isEmpty(self.model.get('DAT_REFNUMBERQUALIFIER')) || !util.isEmpty(self.model.get('DAT_REFNUMBER')));
            },
            hasAnyAdjustmentInformation() {
                return (!util.isEmpty(self.model.get('AMOUNTTOADJ')) || !util.isEmpty(self.model.get('ADJREASONCODE') || !util.isEmpty(self.model.get('ADJDESCRIPTION'))));
            },
        };
    },

    handlePanelHide() {
        this.reset();
    },

    disableButtons() {
        // TODO: can we put this in the ui hash
        domUtil.setDisabled(this.$('.btn-secondary'), true);
    },

    enableButtons() {
        // TODO: can we put this in the ui hash
        domUtil.setDisabled(this.$('.btn-secondary'), false);
    },

    saveNewGrandchild() {
        this.appBus.trigger('meta:ctx:grandchild:new', this.model);
    },

    saveChanges() {
        this.disableButtons();

        if (this.options.parentModelAttrs) {
            this.model.set({
                REFERENCEID: this.options.parentModelAttrs.REFERENCEID,
                IDCODE: this.options.parentModelAttrs.IDCODE,
            }, { silent: true });
        }
        this.appBus.trigger('meta:ctx:grandchild:update', {
            model: this.model,
            errorCallback: util.bind(this.enableButtons, this),
        });
    },

    reset() {
        this.model.set(this.model.originalAttributes);
    },

    delete() {
        this.disableButtons();
        this.appBus.trigger('meta:ctx:grandchild:delete', this.model);
    },

});
