import Controller from '@glu/core/src/controller';
import services from 'services';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';

const Category = Controller.extend({
    initialize() {
        this.response = [];
        this.refreshInProgress = false;
        this.fetched = false;
        this.listenTo(
            this,
            {
                'sync error': this.refreshComplete,
            },
            this,
        );
        this.refresh();
    },

    hasLoaded() {
        // return the value, not reference
        return !!this.fetched;
    },

    getByType(type) {
        let localType = type;
        localType = localType.toUpperCase();
        return util.where(
            this.response,
            {
                categoryType: localType,
            },
        );
    },

    refresh() {
        if (this.refreshInProgress) {
            return;
        }
        const self = this;
        this.refreshInProgress = true;
        http.post(
            services.generateUrl('/cashflowCategory/list'),
            (data) => {
                self.response = data;
                self.fetched = true;
                self.trigger('sync', self, data);
            },
            () => {
                self.trigger('error', self);
            },
        );
    },

    refreshComplete() {
        this.refreshInProgress = false;
    },
});

export default Category;
