var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1 role=\"menubar\" aria-label=\"logo\">\n    <a href=\"#\" role=\"menuitem\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"BTClient.code",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":39},"end":{"line":2,"column":65}}}))
    + "\" data-action=\"home\" class=\"logo\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"BTClient.code",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":99},"end":{"line":2,"column":125}}}))
    + "</a>\n</h1>\n";
},"useData":true});