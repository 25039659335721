import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import quickEntryAlertDetailsTmpl from './quickEntryAlertDetails.hbs';

export default Layout.extend({
    template: quickEntryAlertDetailsTmpl,

    initialize(options) {
        this.model = options.model || new Model();
        this.model.errors = options.errors || null;
    },

    templateHelpers() {
        const self = this;
        return {
            errors() {
                return self.model.errors;
            },
        };
    },
});
