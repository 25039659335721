import Model from '@glu/core/src/model';

export default Model.extend({
    idAttribute: 'name',
    defaults() {
        return {
            name: '',
            email: [],
            phone: [],
            sms: [],
            fax: [],
        };
    },

});
