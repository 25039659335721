import Collection from '@glu/core/src/collection';

const Category = Collection.extend({
    initialize(models, options) {
        this.type = options.type;
        this.categoryService = options.categoryService;
        this.listenTo(
            this.categoryService,
            {
                sync: this.updateModels,
            },
            this,
        );

        this.updateModels();
    },

    updateModels() {
        if (this.categoryService.hasLoaded()) {
            const json = this.categoryService.getByType(this.type);
            this.reset(json);
        }
    },
});

export default Category;
