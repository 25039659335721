import AddChallenge from 'app/challengeManager/views/addChallenge';
import ChallengeConfig from 'app/challengeManager/views/challengeConfigListView';

export default {
    mfaAddChallenge() {
        this.showPage('Add New MFA Challenge', new AddChallenge());
    },

    mfaViewChallenge() {
        this.showPage('View MFA Challenge', new AddChallenge({
            action: 'select',
        }));
    },

    mfaModifyChallenge() {
        this.showPage('Modify MFA Challenge', new AddChallenge({
            action: 'modify',
        }));
    },

    mfaChallengeConfiguration() {
        this.showPage('MFA Challenge Manager', new ChallengeConfig());
    },
};
