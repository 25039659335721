/* istanbul ignore file */

export default {
  dataComponents: {
    actions: 'Actions',
    all: 'All',
    apply: 'Apply',
    back: 'Back',
    between: 'Between',
    betweenCriteria: 'Between {0} and {1}',
    cancel: 'Cancel',
    changed: 'Changed',
    clear: 'Clear',
    column: { none: 'No items found', search: 'Search' },
    columns: 'Columns',
    contains: 'Contains',
    default: 'Default',
    delete: 'Delete',
    deleteThisView: 'Delete this view?',
    display: 'Display',
    emptyHeader: 'No views available',
    enterMax: 'Enter Maximum',
    enterMin: 'Enter Minimum',
    equals: 'Equals',
    filters: 'Filters',
    findColumnByName: 'Find Column By Name',
    from: 'from',
    greaterThan: 'Greater than',
    hidden: 'Hidden',
    hideAll: 'Hide All',
    hideValues: 'Hide Values',
    isGreaterThan: 'Is Greater Than',
    isLessThan: 'Is Less Than',
    left: 'Left',
    lessThan: 'Less than',
    maxPrimary: 'Max of',
    next: 'Next',
    no: 'No',
    noResultsFound: 'No results found',
    numberFilterType: 'Number Filter Type',
    numberInput: 'Number Input',
    pin: 'Pin',
    remove: 'Remove',
    rename: 'Rename',
    right: 'Right',
    save: 'Save',
    saveAs: 'Save As',
    select: 'Select',
    selectAll: 'Select All',
    setDefault: 'Set Default',
    showValues: 'Show Values',
    to: 'to',
    unpin: 'Unpin',
    unpinAllColumns: 'Unpin all columns',
    viewName: 'View Name',
    viewOf: 'View {0} of {1}',
    xHidden: '{0} Hidden',
    xVisible: '{0} Visible',
    yes: 'Yes'
  }
};
