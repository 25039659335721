import { CompositeView, util } from '@glu/core';
import FlexDropdownItemView from './flexDropdownItemView';

import template from './flexDropdownItemCompositeView.hbs';

export default CompositeView.extend({
  template,
  itemView: FlexDropdownItemView,

  getItemView() {
    if (!util.isEmpty(this.options.dropDownItemView)) {
      return this.options.dropDownItemView.viewClass || this.itemView;
    }
    return this.itemView;
  },

  itemViewContainer: '.item-list',

  noDefaultBehaviors: true,
  noGluEvents: true,

  itemEvents: {
    select: 'clickItem',
    'multi:select': 'clickParentMultiSelectItem'
  },

  ui: {
    $itemList: '.item-list',
    $spinner: '.spinner',
    $dropdown: '.table-view-dropdown'
  },

  events: {
    'click ul.item-breadcrumb li': 'clickBreadcrumb',
    'click .done': 'clickDone',
    'click .clear': 'clickClear',
    'click .select-all': 'clickSelectAll'
  },

  collectionEvents: {
    reset: 'render'
  },

  modelEvents: {
    change: 'render'
  },

  itemViewOptions() {
    const options = {
      multiSelect: this.options.multiSelect,
      tableView: this.options.tableView,
      showGroups: this.options.showGroups
    };

    if (util.isEmpty(this.options.dropDownItemView)) {
      return options;
    }

    return util.extend(options, this.options.dropDownItemView.viewOptions || {});
  },

  templateHelpers() {
    const hasBreadcrumb = this.model.get('breadcrumb');
    const hasChildList = this.model.data && this.model.data.length === 1 && this.model.data[0].child instanceof Array;

    return {
      hasBreadcrumb,
      hasChildList,
      enableMultiButton: hasBreadcrumb && !this.options.disableMultiButton,
      selectAllBtn: this.model.selectAllBtn,
      clearBtn: this.model.clearBtn,
      tableView: this.model.tableView,
      columnTitles: this.model.columnTitles,
      multiSelect: this.model.multiSelect,
      showGroups: this.options.showGroups
    };
  },

  onRender() {
    this.ui.$spinner.removeClass('loader');
    this.ui.$dropdown.show();
    this.setWidth();
  },

  onCompositeCollectionRendered() {
    if (this.filterText) {
      if (this.collection.length === 1) {
        this.children.findByModel(this.collection.at(0)).$el.addClass('selected');
      }
    }
  },
  /**
   * Sets the width of the dropdown to match the width of the longest item when viewing all items
   *
   * When viewing filtered data it allows the dropdown to auto size to the data
   *
   */
  setWidth() {
    this.filterText = this.filterText || '';
    if (this.model.minLength !== null && this.filterText === '') {
      this.ui.$itemList.css('min-width', this.model.minLength);
      return;
    }

    this.ui.$itemList.css('min-width', 'auto');
  },

  /**
   * Handles clicks on the done button for multi-select.
   *
   */
  clickDone() {
    if (this.options.multiSelect) {
      this.$el.parents().find('.flex-dropdown.open').focus();
      // Need to trigger global close function.
      this.trigger('closeOnSelection');
    } else {
      const breadcrumb = this.model.get('breadcrumb');
      const breadcrumbLength = breadcrumb.length;
      const id = !breadcrumbLength ? undefined : breadcrumb[breadcrumbLength - 1].id;

      this.model.selectOne(id);
      this.$el.parents().find('.flex-dropdown.open').focus();
      this.trigger('closeOnSelection');
      this.collection.trigger('change');
      this.$el.parent().focus();
    }
  },

  /**
   * Handles clicks (or enter press) on breadcrumb links.
   *
   * @param {event} e - DOM event

   */
  clickBreadcrumb(e) {
    e.preventDefault();
    e.stopPropagation();

    const $target = this.$(e.currentTarget);
    const selectedIndex = $target.index();
    const id = $target.data('parent-id');

    if (selectedIndex === 0) {
      this.trigger('reset:hierarchy');
    } else {
      const breadcrumb = this.model.get('breadcrumb').slice(0, selectedIndex);
      const currentAttr = this.model.get('breadcrumb');

      this.model.set('breadcrumb', breadcrumb);

      if (currentAttr === breadcrumb) {
        this.model.trigger('change');
      }
      this.collection.reset(this.model.getById(id).child);
    }
  },

  clickParentMultiSelectItem(eventName, child, id) {
    const { model } = child;
    const currentAttr = model.get('selected');
    const newAttr = this.model.toggleSelected(id);

    model.set('selected', newAttr);

    // need to trigger change as it will set selected to the same model. Because it can set `true` to `true` in this case it wouldn't trigger change..
    if (currentAttr === newAttr) {
      model.trigger('change');
    }
  },

  clickItem(eventName, child, options) {
    const selectedIndex = this.collection.indexOf(child.model);

    this.selectItem(selectedIndex, options.id, options.label, options.isParent);
    this.model.trigger('item:selected');
  },

  /**
   * Handles the selection of a single item in the list as provided by a click on the item or the explicit passing
   * of that item's information obtained by other means such as filtering the list and selecting the sole item using the enter key.
   *
   * @param selectedIndex - 0 based index of the item to select, basically its index in the collection.
   * @param id - id of the item, matches up to the id attribute on the item's model.
   * @param label - label of the item, matches up to the name attribute on the item's model.
   * @param isParent - Is this item a parent with child items beneath it? True if it is, false otherwise.
   */
  selectItem(selectedIndex, id, label, isParent) {
    if (isParent) {
      // If parent item for sub-list
      const breadcrumb = this.model.get('breadcrumb');
      this.removeVisitedBreadcrumbItems(breadcrumb, id);
      breadcrumb.push({
        id,
        label
      });

      this.model.set('breadcrumb', breadcrumb).trigger('change');
      this.collection.reset(this.model.getById(id).child);
      this.$el.parents().find('.flex-dropdown.open').focus();
    } else if (this.options.multiSelect) {
      // If multi select list item
      const model = this.collection.at(selectedIndex);
      const currentAttr = model.get('selected');
      const newAttr = this.model.toggleSelected(id);

      model.set('selected', newAttr);

      if (currentAttr === newAttr) {
        model.trigger('change');
      }

      this.$el.parents().find('.flex-dropdown.open').focus();
    } else {
      // If single select item
      this.model.selectOne(id);

      this.collection.each((item) => {
        if (item.get('id') === id || item.get('selected')) {
          item.set('selected', item.get('id') === id);
        }
      });

      this.$el.parents().find('.flex-dropdown.open').focus();
      // Need to trigger global close function.
      this.trigger('closeOnSelection');
    }
  },

  removeVisitedBreadcrumbItems(breadcrumb, id) {
    const currentLevel = breadcrumb.length;
    const newItemLevel = this.model.getById(id).name.split('\\').length;
    if (newItemLevel <= currentLevel) {
      while (breadcrumb.length >= newItemLevel) {
        breadcrumb.pop();
        breadcrumb = this.model.get('breadcrumb');
      }
    }
  },

  addSpinner() {
    this.ui.$dropdown.hide();
    this.ui.$spinner.addClass('loader');
  },

  /**
   * Resets model to current state of data
   *
   */
  reloadCollection() {
    const $breadcrumbs = this.$('ul.item-breadcrumb li');
    if ($breadcrumbs.length < 2) {
      this.model.getActive();
      return;
    }

    const id = $breadcrumbs.last().data('parent-id');

    if (util.isUndefined(id)) {
      this.model.getActive();
      return;
    }

    this.collection.reset(this.model.getById(id).child);
  },
  /**
   * Selects all items on item collection.
   */
  selectAll() {
    this.model.selectAll();
    this.reloadCollection();
  },

  goLevelUp() {
    const $breadcrumbs = this.$('ul.item-breadcrumb li');
    if ($breadcrumbs.length < 2) {
      this.model.getActive();
      return;
    }

    const id = $breadcrumbs.eq($breadcrumbs.length - 2).data('parent-id');
    const breadcrumb = this.model.get('breadcrumb').slice(0, this.model.get('breadcrumb').length - 1);

    if (this.model.get('breadcrumb') !== breadcrumb) {
      this.model.set('breadcrumb', breadcrumb);
    } else {
      this.model.trigger('change');
    }

    if (util.isUndefined(id)) {
      this.model.getActive();
      return;
    }

    this.collection.reset(this.model.getById(id).child);
  },

  /**
   * Resets all items on item to unselected after clicking Clear button
   */
  clickClear(e) {
    e.preventDefault();

    const id = this.model.get('selection').models[0].get('id');
    const breadcrumb = this.model.get('breadcrumb');

    if (!this.options.multiSelect && id === null && breadcrumb.length > 0) {
      this.trigger('closeOnSelection');
    }

    this.trigger('clear:selected', true);
    this.$el.parents('.flex-dropdown').focus();
  },

  /**
   * Selects all items on item collection after clicking Select All button
   */
  clickSelectAll(e) {
    e.preventDefault();
    this.selectAll();
    this.$el.parents('.flex-dropdown').focus();
  }
});
