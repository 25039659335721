import Field from './fieldModel';

export default Field.extend({
    idAttribute: 'TPV_FIELD_ID',

    defaults() {
        return {
            FIELDLABEL: '',
            FIELDNAME: '',
            FIELDTYPE: '',
            VENDNAME: '',
            TPV_ID: '',
            TPV_TYPE: '',
            VENDCODE: '',
            TPV_FIELD_ID: '',
            TYPECODE: 'CFRXP',
            PRODUCTCODE: 'SSOOUT',
            FUNCTIONCODE: 'MAINT',
            ENCRYPTED: 0,
        };
    },
});
