import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import constants from 'app/administration/constants';
import errorHandlers from 'system/error/handlers';
import ContactTypes from 'app/administration/collection/user2/templates';
import DataEntitlementLimitsCollection from 'app/administration/collection/user2/dataEntitlementLimits';
import DataEntitlementLimitModel from 'app/administration/models/user2/dataEntitlementLimit';
import { appBus } from '@glu/core';
import systemConfig from 'system/configuration';
import setupAccessibleTabs, { createTabsToggleButton, toggleTabs, setTabButtonText } from 'common/util/a11y/tabs';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import AdminLayout from './admin/admin';
import PaymentLayout from './payments/paymentLayout';
import ReportsLayout from './reporting/reporting';
import RiskLayout from './riskManagement/riskManagement';
import AncillaryLayout from './ancillaryBusinessServices/permissionsView';
import AlertLayout from './alerts/alerts';
import DynamicTablLayout from './dynamicTab/dynamicTab';
import permissionTabsTmpl from './permissionTabs.hbs';


export default Layout.extend({
    template: permissionTabsTmpl,
    isInitialRender: true,
    ui: {
        $navTabs: '[data-hook="getNavTabs"]',
        $navItems: '[data-hook="getNavTabs"] .NavTabs-item',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
    },

    events: {
        'click @ui.$navLinks': 'handleToggleButtonClick',

    },

    regions: {
        formRegion: '[data-hook="formRegion"]',
    },

    initialize(opts) {
        const self = this;
        let userId = '';
        let roleId = '';
        const profileModel = (this.model.get('roleInfo') || this.model.get('userInfo'));
        let userGroup = profileModel && profileModel.USERGROUP;

        this.mode = opts.mode;
        this.readOnly = this.mode === constants.MODES.VIEW;
        this.isUce = opts.isUce;
        this.copyFromUser = opts.copyFromUser;

        /*
         * Grab the user id or role is.
         * Depending whether insert or another mode
         * the id is retrieved from different models.
         * On insert there is no id so just make it empty.
         * For modify try to retrieve it from the userModel.  Since
         * it might not be set at this time, fall back to the userInfo.
         * The userInfo model is just the response from the server prior to
         * it being added to a backbone model.
         * If being copied from a user, grab that users existing contact types.
         */
        if (opts.copyFromUser) {
            userId = opts.copyFromUser;
        } else if (opts.mode === constants.MODES.INSERT) {
            userId = '';
        } else if (this.model.get('userModel')) {
            userId = this.model.get('userModel').get('USERID');
        } else if (this.model.get('roleInfo')) {
            roleId = util.findWhere(
                this.model.get('roleInfo').item,
                {
                    name: 'ROLEID',
                },
            ).value;
            } else if (this.model.get('userInfo')) {  // eslint-disable-line
            userId = util.findWhere(
                this.model.get('userInfo').item,
                {
                    name: 'USERID',
                },
            ).value;
        }

        if (!userGroup) {
            userGroup = this.model.userGroupModel && this.model.userGroupModel.get('parentUserGroup');
        }

        // Need the request to be in modify to bring up the copied user's contact types.
        this.model.contactTypes = new ContactTypes(
            [],
            {
                inquiryId: '29087',
                userGroup,
                userId,
                roleId,
                mode: opts.copyFromUser ? constants.MODES.MODIFY : opts.mode,
            },
        );

        // Need to grab them now in case we never render step 2.
        this.contactTypesPromise = new Promise((resolve, reject) => {
            self.model.contactTypes.fetch({
                copyingUser: !!opts.copyFromUser,

                success() {
                    resolve();
                },

                error() {
                    util.bind(errorHandlers.loading, self);
                    reject();
                },
            });
        });

        this.switchedToByGroup = false;
        this.sectionModels = {};
        this.hidePaymentsSectionForView = true;
        this.model.get('sections').each((section) => {
            this.sectionModels[section.get('id')] = section;
        });

        // TODO Refactor to make all tabs dynamically generated.
        const tabsMap = {
            PAYMENTS: {
                view: PaymentLayout,
                order: 1,

                options: {
                    mainModel: this.model,
                },
            },

            REPORTS: {
                view: ReportsLayout,
                order: 2,
            },

            RISK: {
                view: RiskLayout,
                order: 3,
            },

            ADMIN: {
                view: AdminLayout,
                order: 4,

                options: {
                    mainModel: this.model,
                    contactTypesPromise: this.contactTypesPromise,
                },
            },

            ALERT: {
                view: AlertLayout,
                order: 5,
            },
            ABS: {
                view: AncillaryLayout,
                order: 6,
                options: {
                    mainModel: this.model,
                },
            },
        };

        this.sections = {};
        util.each(tabsMap, this.tabGenerator.bind(this));
        this.dynamicTabs = this.getDynamicTabs();
        this.isPaymentsTabEnabled = this.sectionModels.PAYMENTS && this.sectionModels.PAYMENTS.get('groups').length > 0 && !this.hidePaymentsSectionForView;
        this.paymentsModels = this.isPaymentsTabEnabled ? this.sectionModels.PAYMENTS.get('groups') : null;
        this.existingActionEntitlements = null;
        this.accountLevelActions = ['approve', 'modify', 'repair', 'manage'];
    },

    getRenderModel() {
        return (this.model.roleModel || this.model.userModel);
    },

    onRender() {
        createTabsToggleButton(this);
        this.saveOrCancel = false;
        // use either the roleModel or the userModel
        this.infoModel = this.getRenderModel();
        this.byGroup = this.infoModel && this.infoModel.get('GROUPED_PERMISSIONS_BY') === 'PAYMENT_GROUP';

        if (this.mode === constants.MODES.MODIFY || this.mode === constants.MODES.INSERT) {
            this.entitledTypes = this.getEntitledTypes();

            if (this.infoModel) {
                // Only do this on modify.
                this.listenTo(this.infoModel, 'change:GROUPED_PERMISSIONS_BY', () => {
                    /*
                     * When switching permissions grouping,
                     * reset existing entitlements and entitled types.
                     */
                    this.byGroup = this.infoModel.get('GROUPED_PERMISSIONS_BY') === 'PAYMENT_GROUP';
                    this.refreshExistingEntitlements();
                    this.entitledTypes = this.getEntitledTypes();
                    this.switchedToByGroup = true;
                }, this);
            }
        }

        /*
         * Set saveorcancel to true so it allows Marionette to clear resources
         * but does not try to update the limits via service.  The limits are
         * updated in bulk below.
         */
        if (this.infoModel) {
            this.listenTo(this.infoModel, 'user:cancel user:save role:cancel role:save', () => {
                this.saveOrCancel = true;
            }, this);
        }

        // Always refresh existing entitlements when re-entering the tab.
        this.refreshExistingEntitlements();

        if (util.size(this.sections) > 0) {
            this.ui.$navLinks.first().addClass('is-active');
            this.currentTabText = this.ui.$navLinks.first().text();
            setTabButtonText(this, this.currentTabText);
            this.formRegion.show(util.first(util.sortBy(this.sections, 'order')));
        }
        setupAccessibleTabs(this, this.handleToggleButtonClick);
        this.isInitialRender = false;
    },

    /*
     * Determines if the sectionModel is a dynamic tab based
     * on the static sections defined.
     * @return {array}  array of dynamic tabs
     */
    getDynamicTabs() {
        return util.chain(this.sectionModels)
            .compact()
            .reduce((map, model) => {
                const key = model.id;
                if (this.readOnly) {
                    if (this.isDynamicTab(this.sections, model)
                            && this.sectionModels[key].hasSelectedGroup()) {
                        map.push(model);
                    }
                } else if (this.isDynamicTab(this.sections, model)) {
                    map.push(model);
                }
                return map;
            }, [])
            .compact()
            .filter(this.shouldShowDynamicTab.bind(this))
            .each(this.dynamicTabGenerator.bind(this))
            .value();
    },

    isDynamicTab(sections, model) {
        return util.isEmpty(sections) || !util.has(sections, model.id);
    },

    /**
     * shouldShowDynamicTab
     * @param {Model} tab
     * Add certain cases to show or hide certain dynamic tabs,
     * defaults to true if no cases to check
     */
    shouldShowDynamicTab(tab) {
        if (tab.id === 'RDC') {
            /**
             * ONLY SHOW RDC TAB for these conditions:
             * - If "Deluxe" flavor, can view/modify on ADMIN, only view on CLIENT
             * - Can view/modify on all other flavors for both ADMIN and CLIENT
             */
            const isDeluxe = serverConfigParams.get('RDCProviderName') === constants.RDC_SETTING.DELUXE;
            return !isDeluxe
                    || (isDeluxe && (systemConfig.isAdmin() || this.mode === constants.MODES.VIEW));
        }
        return true;
    },

    /*
     * Add dynamic tabs to the sectionModel
     * @param {Model} model
     */
    dynamicTabGenerator(model) {
        const key = model.id;

        const options = {
            model: this.sectionModels[key],
            mode: this.mode,
        };

        if (!this.readOnly || this.sectionModels[key].hasSelectedGroup()) {
            this.sections[key] = new DynamicTablLayout(options);
        } else {
            // if view mode and nothing entitled
            this.sectionModels[key] = null;
        }
    },

    /**
     * Get all types entitled by a group to determine if any were added.
     * Any added types to a group must have a data entitlement object
     * sent to the server so the type as the correct data entitlements.
     * @return {Array}
     */
    getEntitledTypes() {
        /*
         * Check if payments tab is enabled just in case the company
         * permissions were changed and no payments are entitled.
         */
        if (!this.byGroup || !this.isPaymentsTabEnabled) {
            return null;
        }

        return this.sectionModels.PAYMENTS.get('groups').chain()
            .filter(group => group.isEntitled())
            .map(group => ({
                groupId: group.id,
                types: this.extractTypesfromGroup(group),
            }))
            .indexBy('groupId')
            .value();
    },

    /**
     * Get all types entitled by a group and return array of types.
     * @param group
     * @return {Array}
     */
    extractTypesfromGroup(group) {
        return group.getEntitledTypes().map(type => type.id);
    },

    /**
     * Stub function to reload the entitlements cache to used determine if any
     * actions have changed
     */
    refreshExistingEntitlements() {
        if (this.isPaymentsTabEnabled && (this.mode === 'modify'
        || (this.mode === constants.MODES.INSERT && this.copyFromUser))) {
            this.existingActionEntitlements = this.byGroup
                ? this.getGroupEntitlements() : this.getTypeEntitlements();
        }
    },

    /**
     * Load the group entitlements cache when permissions are by group.  The
     * cache is used to compare to any changed to entitlements.  Return a map
     * of each groups entitlements.
     * @return {Array}
     */
    getGroupEntitlements() {
        return this.sectionModels.PAYMENTS?.get('groups').chain()
            .filter(group => group.isEntitled())
            .map(group => ({
                id: group.id,

                actions: util.map(this.accountLevelActions, action => ({
                    action,
                    entitled: group.get('aggregateModels').some(this.checkActionEntitlements.bind(this, action)),
                })),
            }))
            .indexBy('id')
            .value();
    },

    /**
     * Returns a map objects of types with their entitlements
     * actions have changed
     * @return {Array}
     */
    formatTypeEntitlements(group) {
        return group.getEntitledTypes().chain()
            .map(type => ({
                type: type.id,

                actions: util.map(this.accountLevelActions, action => ({
                    action,
                    entitled: type.get('entitlements').some(this.checkActionEntitlements.bind(this, action)),
                })),
            }))
            .indexBy('type')
            .value();
    },

    /**
     * Returns a map objects of groups with their associated
     * types and entitlements
     * @return {Array}
     */
    getTypeEntitlements() {
        return this.sectionModels.PAYMENTS.get('groups').chain()
            .filter(group => group.isEntitled())
            .map(group => ({
                id: group.id,
                types: this.formatTypeEntitlements(group),
            }))
            .indexBy('id')
            .value();
    },

    /**
     * Determines if an entitlement has changed from its original value
     * @param {string} action
     * @param {Array} entitlements
     * @return {boolean}
     */
    checkActionEntitlements(action, entitlements) {
        const entitlement = entitlements.get('actions');
        return util.has(entitlement, action) && entitlement[action].entitled === true;
    },

    tabGenerator(tab, key) {
        if (!this.sectionModels[key]) {
            return;
        }

        const options = {
            model: this.sectionModels[key],
            mode: this.mode,
            isUce: this.isUce,
        };

        const PermissionView = tab.view;
        util.extend(options, tab.options || {});

        /*
         * Show all the time if not view mode.  But if view mode make sure something
         * is entitled
         */
        if (!this.readOnly || this.sectionModels[key].hasSelectedGroup()) {
            if (key === 'PAYMENTS') {
                this.hidePaymentsSectionForView = false;
            }
            this.sections[key] = new PermissionView(options);
        } else {
            // if view mode and nothing entitled
            this.sectionModels[key] = null;
        }
    },

    toStep(step) {
        this.formRegion.show(this.sections[step]);
    },

    pushEntitlementChanges() {
        if ((this.mode === constants.MODES.MODIFY || this.mode === constants.MODES.INSERT)
            && !util.isNull(this.existingActionEntitlements)) {
            return this.applyAllEntitlementChanges();
        }
        return Promise.resolve();
    },

    beforeSave() {
        const promises = [];

        util.each(this.sections, (section) => {
            if (!util.isNullOrUndefined(section) && util.isFunction(section.beforeSave)) {
                promises.push(section.beforeSave());
            }
        });

        return Promise.all(promises);
    },

    handleToggleButtonClick(e) {
        const elem = e.currentTarget;
        const $target = this.$(e.currentTarget);
        const step = elem.getAttribute('data-step');

        this.currentTabText = $target.text();
        toggleTabs(this);
        this.ui.$navItems.removeClass('is-active');
        $target.parent().addClass('is-active');
        if (this.ui.$navItems.hasClass('is-active')) {
            $target.attr('aria-expanded', 'true');
        }
        if (step) {
            this.toStep(step);
        }
    },
    close(...args) {
        if (this.mode === 'modify' && !this.saveOrCancel) {
            this.applyAllEntitlementChanges().then(() => {
                if (Layout.prototype.close) {
                    Layout.prototype.close.apply(this, args);
                }
            });
        } else if (this.mode === constants.MODES.INSERT && !this.saveOrCancel) {
            this.entitledTypes = this.getEntitledTypes();
            this.existingActionEntitlements = this.byGroup
                ? this.getGroupEntitlements() : this.getTypeEntitlements();
        }
        // Continue with Marionette close
        if (Layout.prototype.close) {
            Layout.prototype.close.apply(this, args);
        }
    },

    /**
     * Generate the data entitlement objects for those types added
     * when permissions are by group.  Used by server to sync up
     * accounts for the new types within a group, by group only.
     * @return {Array} dataEntitlements
     */
    loadDataEntitlements() {
        return util.chain(this.getEntitledTypes())
            .map((group) => {
                /*
                 * If a new product was added the original types don't exist.
                 * Seed with empty array for diff.
                 */
                const originalTypes = this.entitledTypes[group.groupId]
                    ? this.entitledTypes[group.groupId].types : [];

                const changedTypes = util.difference(group.types, originalTypes);
                const dataEntAttr = group.groupId === 'ACH' ? 'ACHCompany' : 'BankAccount';
                if (util.isEmpty(changedTypes)) {
                    return null;
                }
                return {
                    productCode: group.groupId,
                    members: [],
                    exclusiveSet: false,
                    currentFuture: false,
                    mixedChangeSet: true,
                    group: true,
                    typeCode: '*',
                    dataEntAttr,
                    newTypesInGroupOnModify: changedTypes,
                };
            })
            .compact()
            .value();
    },

    /**
     * Syncs up action level permissions changes in modify mode.
     */
    applyAllEntitlementChanges() {
        const paymentsModels = this.isPaymentsTabEnabled ? this.sectionModels.PAYMENTS.get('groups') : null;
        let dataEntitlementLimitsCollection;

        if (!paymentsModels) {
            return Promise.resolve();
        }

        const limits = paymentsModels.chain()
            .filter(group => group.isEntitled())
            .map((group) => {
                if (this.byGroup) {
                    return group;
                }
                // Need to identify the group name for each type
                group.getEntitledTypes().each((typeParam) => {
                    const type = typeParam;
                    type.groupId = group.id;
                });
                return group.getEntitledTypes().models;
            })
            .flatten(true)
            .map(this.limitsFn.bind(this))
            .compact()
            .value();

        // Only push a dataentitlement object for type changes by group.
        if (this.byGroup) {
            util.each(this.loadDataEntitlements(), (dataEntitlement) => {
                this.model.get('dataEntitlements').push(dataEntitlement);
            });
        }

        /*
         * Don't send to the server if nothing has changed.  Send actionsOnly to
         * only wipe out the actions and leave the limits in place.  But if
         * we are changing from by type to by group, the limits must be initialized.
         */
        if (limits && !util.isEmpty(limits)) {
            dataEntitlementLimitsCollection = new DataEntitlementLimitsCollection(limits);
            return new Promise((resolve, reject) => {
                dataEntitlementLimitsCollection.save({
                    byGroup: this.byGroup,
                    actionsOnly: !this.switchedToByGroup,
                    limits,

                    success() {
                    // Tell tab 4 that permissions were changed
                        this.limits.forEach((limit) => {
                            const typeCode = this.byGroup ? limit.get('productCode') : limit.get('typeCode');
                            appBus.trigger(`change:permissions:${typeCode}`);
                        });
                        resolve();
                    },

                    error() {
                        reject();
                    },
                });
            });
        }
        return Promise.resolve();
    },

    /**
     * Determines if an entitlement has changed from its original value
     * @param {model} model or type model
     * @return {model} dataEntitlementLimit model
     */
    limitsFn(model) {
        // Normalize some of the variables based on the by type and by group models.

        const groupId = this.byGroup ? model.id : model.groupId;

        // typecode for by type only.  It will not be referenced for by group.
        const typeCode = model.id;

        const existingEntitlements = this.getExistingEntitlements(groupId, typeCode);
        const newEntitlements = this.byGroup ? model.get('aggregateModels') : model.get('entitlements');

        const changedActions = util.chain(this.accountLevelActions)
            .map((action) => {
                const oldEntitlement = this.getOldEntitlement(existingEntitlements, action);

                if (this.hasActionChanged(newEntitlements, action, oldEntitlement)) {
                    return {
                        action,
                        entitled: !oldEntitlement.entitled,
                    };
                }
                return undefined;
            })
            .compact()
            .indexBy('action')
            .value();

        if (util.isEmpty(changedActions)) {
            return null;
        }

        /*
         * If anything changed create the model with those values.  Use the original
         * values for those actions that have not changed.
         */
        return this.formatLimitModel(changedActions, groupId, typeCode);
    },

    /**
     * Returns the existing/original entitlements for a type or group
     * @param {string} groupId name
     * @param {string} typeCode code
     * @return {object} type or group entitlements
     */
    getExistingEntitlements(groupId, typeCode) {
        const types = {};

        const actions = {
            type: typeCode,

            actions: util.map(this.accountLevelActions, action => ({
                action,
                entitled: false,
            })),
        };

        /*
         * If a new group was added on modify, the existing entitlements need to be
         * refreshed.
         */
        if (util.isNullOrUndefined(this.existingActionEntitlements[groupId])) {
            if (this.byGroup) {
                this.existingActionEntitlements[groupId] = actions;
            } else {
                types[typeCode] = actions;
                this.existingActionEntitlements[groupId] = {
                    types,
                };
            }
        } else if (!this.byGroup
                && util.isNullOrUndefined(this.existingActionEntitlements[groupId]
                    .types[typeCode])) {
            this.existingActionEntitlements[groupId].types[typeCode] = actions;
        }

        return this.byGroup ? this.existingActionEntitlements[groupId].actions
            : this.existingActionEntitlements[groupId].types[typeCode].actions;
    },

    /**
     * Returns the existing/original entitlements for a type or group
     * @param {Array} entitlements
     * @param {string} action
     * @return {object} original action entitlement
     */
    getOldEntitlement(entitlements, action) {
        return util.find(entitlements, entitlement => entitlement.action === action);
    },

    /**
     * Determines if a specific action for a type or group has changed.
     * If the old entitlement is false, then only one action across all
     * entry methods must be true.  If the old entitlement is true,
     * then every new entitlement must be false to identify as changed.
     * @param {Array} newEntitlements
     * @param {string} action
     * @param {object} oldEntitlement
     * @return {boolean} did the entitlement change
     */
    hasActionChanged(newEntitlements, action, oldEntitlement) {
        /*
         * If oldentitlement does not exist it means the type was removed.
         * If action entitled, then all changed to false means it changed.
         * If action not entitled, any change to true means it changed.
         */
        if (util.isNullOrUndefined(oldEntitlement)) {
            return false;
        }
        if (oldEntitlement.entitled) {
            return this.allEntitlementsForActionChanged(newEntitlements, action);
        }
        return this.singleEntitlementforActionChanged(newEntitlements, action);
    },

    /**
     * If the old entitlement is true, then every new entitlement must
     *  be false to identify as changed.
     * @param {Array} entitlements
     * @param {string} action
     * @return {object} did the entitlement change
     */
    allEntitlementsForActionChanged(entitlements, action) {
        return entitlements.chain()
            .filter((entitlement) => {
                const actions = entitlement.get('actions');
                return util.has(actions, action);
            })
            .every((entitlement) => {
                const actions = entitlement.get('actions');
                return !actions[action].entitled;
            })
            .value();
    },

    /**
     * If the old entitlement is false, then only one new entitlement must
     *  be true to identify as changed.
     * @param {Array} entitlements
     * @param {string} action
     * @return {object} did the entitlement change
     */
    singleEntitlementforActionChanged(entitlements, action) {
        return entitlements.chain()
            .filter((entitlement) => {
                const actions = entitlement.get('actions');
                return util.has(actions, action);
            })
            .some((entitlement) => {
                const actions = entitlement.get('actions');
                return actions[action].entitled;
            })
            .value();
    },

    /**
     * Create a dataEntitlementModel based on changed actions.  For those actions
     * that have not changed, null indicates the server will not change its value.
     *  be true to identify as changed.
     * @param {Array} changedActions
     * @param {string} group
     * @param {string} type
     * @return {object} did the entitlement change
     */
    formatLimitModel(changedActions, group, type) {
        const hasChangedActions = !util.isNullOrUndefined(changedActions)
                && !util.isEmpty(changedActions);
        let limitObject = {};

        const data = {
            productCode: group,
            typeCode: this.byGroup ? '*' : type,
            isACH: group === 'ACH' || group === 'EFT',
        };

        /*
         * Build the limit object with the actions.  We seed the product and type
         * with data object.
         */
        limitObject = util.chain(this.accountLevelActions)
            .reduce((objParam, action) => {
                const obj = objParam;
                obj[`${action}Action`] = hasChangedActions && changedActions[action] ? changedActions[action].entitled : '';
                return obj;
            }, data)
            .value();

        return new DataEntitlementLimitModel(limitObject);
    },

    templateHelpers() {
        /*
         * Show tab only when there is content. If section's groups does not have eny entries,
         * then don't show it.  Note that in view mode, if nothing was assigned, but entitled
         * in the tab, groups will be empty.  In modify the items are sent.
         */
        return {
            isPaymentsTabEnabled: this.isPaymentsTabEnabled,
            isReportingTabEnabled: this.sectionModels.REPORTS && this.sectionModels.REPORTS.get('groups').length > 0,
            isRiskTabEnabled: this.sectionModels.RISK && this.sectionModels.RISK.get('groups').length > 0,
            isAdminTabEnabled: this.sectionModels.ADMIN && this.sectionModels.ADMIN.get('groups').length > 0,
            isAlertsTabEnabled: this.sectionModels.ALERT && this.sectionModels.ALERT.get('groups').length > 0,
            dynamicTabs: this.dynamicTabs,
            isAncillaryTabEnabled: this.sectionModels.ABS && this.sectionModels.ABS.get('groups').length > 0,

            label(label) {
                return `administration.${label}`;
            },
        };
    },
});
