import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import userInfo from 'etc/userInfo';
import actionFunction from 'app/payments/api/common';
import constants from 'common/dynamicPages/api/constants';
import ListView from 'common/dynamicPages/views/workflow/list';
import payPrintExportUtil from 'common/util/payPrintExportUtil';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import ConversationView from './rtpConversationView';
import template from './rfpGridView.hbs';

const RtpGridView = ListView.extend(util.extend({}, payPrintExportUtil, {
    className: 'requestForPaymentsGrid',

    template,

    events: util.extend(
        {},
        ListView.prototype.events,
        {
            // custom events for RFP grid view go here
        },
    ),

    initialize(options) {
        // Dynamic import to resolve circular references in MDF
        this.gridImportPromise = import('common/dynamicPages/api/grid').then(({ default: gridApi }) => {
            this.gridApi = gridApi;
        });

        this.options = options;
        this.isSmbUser = userInfo.isSmbUser();
        this.isRFPReceivedGrid = this.options.configContext.typeCode === 'REQIN';
        this.isSubmittedGrid = this.options.context === 'RFP';

        const gridOptions = {
            startRow: 1,
            disableDrillDown: false,
            dataOnly: 0,
            searchFields: [],
        };

        ListView.prototype.initialize.call(this, util.extend({}, this.options, gridOptions));

        this.setupListeners();

        this.appBus.trigger('dgb:drawer:update', {
            showTab: false,
            showTabOnClose: false,
            shouldBeOpen: false,
            viewOptions: {
                allowDetail: true,
            },
        });
    },

    setupListeners() {
        this.listenTo(this.appBus, 'rtp:startConversation', this.createConversationView);
        this.listenTo(this.appBus, 'rtp:continueConversation', this.createConversationView);
        this.listenTo(this.appBus, 'rfp:renderMessage', this.renderAlertMessage);
        /*
         * comment out for now since the rfp drawer is scheduled for the next release
         * However, once we need to listen to this event we either need to change
         * Drawer.jsx to trigger
         * a different event specific to rfp, or we need to distinguish which
         * event we need to listen to
         * since both rtp & rfp grid views appear on the same workspace
         */
        // this.listenTo(this.appBus, 'rtp:viewPayment', this.gridRowSelect);
        this.listenTo(this.appBus, 'rtp:conversationUpdated', this.updateGrid);
    },

    /**
     * Override listview onRender
     */
    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.gridRegion.show(this.gridView);
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
            this.listenTo(this.gridView.getRows(), 'sync', this.updateRefreshTimestamp);
            if (this.isSubmittedGrid) {
                this.showConfirmMessage();
            }
        } else {
            this.loadViewRequirements();
        }
    },

    /**
     * Render alerts messages and then remove them from the store
     */
    showConfirmMessage() {
        this.renderMessage(
            store.get(`${this.contextKey}-alertMessage`),
            store.get(`${this.contextKey}-confirms`),
        );
        store.set(`${this.contextKey}-alertMessage`, null);
        store.set(`${this.contextKey}-confirms`, null);
    },

    /**
     * handles select action from the RFP Workspace
     * @param {Object} optionsParam - Holds options for the payment being selected
     */
    gridRowSelect(optionsParam) {
        const options = optionsParam;
        // check if the message_state to see if the drawer should show on the detail page
        if (options.model.get('MESSAGE_STATE') > 0) {
            const rfpConversationData = {
                shouldBeOpen: false,
                showTab: true,
                showTabOnClose: true,
                viewOptions: {
                    allowDetail: false,
                },
            };
            /*
             * if (!options.fromReact) {
             *  rfpConversationData = {
             *      ...rtpConversationData,
             *      view: ConversationView,
             *      viewOptions: {
             *          model: options.model,
             *          conversationStarted: options.model.get('MESSAGE_STATE') > 1,
             *          defaultOpen: false,
             *          allowDetail: false,
             *          currentTab: store.get('payment_rfp-currentTab'),
             *      },
             *  };
             * }
             */
            this.appBus.trigger('dgb:drawer:update', rfpConversationData);
        }

        options.model.context = this.contextDef;
        store.set(`${this.contextKey}-actionModel`, options.model);
        if (options.model.get('TYPE') === 'REQOUT') {
            if (options.model.get('FUNCTION') === 'REQTMPL') {
                this.navigateTo(constants.ROUTE_RTP_VIEW_REQUEST_FOR_PAYMENT_TEMPLATE_OUT);
            } else {
                this.navigateTo(constants.ROUTE_RTP_VIEW_REQUEST_FOR_PAYMENT_OUT);
            }
        } else {
            this.navigateTo(constants.ROUTE_RTP_VIEW_REQUEST_FOR_PAYMENT_IN);
        }
        return Promise.resolve();
    },

    /**
     * handles the PAY action from the RFP list view. Same functionality as SELECT but once the
     * page is loaded, it navigates to the footer and displays the PAY meta driven footer
     * @param {Object} optionsParam - Holds options for the payment being selected
     */
    gridRowPay(optionsParam) {
        const options = optionsParam;
        options.model.context = this.contextDef;
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo('PAYMENTS/payRequestForPayment');
        return Promise.resolve();
    },

    /**
     * handles modify action from the RFP Workspace
     */
    gridRowModify(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        if (options.model.get('FUNCTION') === 'REQTMPL') {
            this.navigateTo(constants.ROUTE_RTP_MODIFY_REQUEST_FOR_PAYMENT_TEMPLATE_OUT);
        } else if (options.model.get('FUNCTION') === 'REQUEST') {
            this.navigateTo(constants.ROUTE_RTP_MODIFY_REQUEST_FOR_PAYMENT_OUT);
        }
        return Promise.resolve();
    },

    /**
     * @method gridRowRestore
     * @param {object} optionsParam -  data from grid row
     * - function to navigate to restore template screen on select of Restore
     * action from context menu
     */
    gridRowRestore(optionsParam) {
        const options = optionsParam;
        const serviceName = options.model.context.serviceName.toLowerCase();

        if (serviceName.indexOf('loandraw') > -1 || serviceName.indexOf('loanpayment') > -1) {
            options.model.overloadedMethodName = 'restore';
            store.set('custom_modify_template_model', options.model);
            this.navigateTo('TEMPLATE/restoreLoanTemplate');
        } else {
            store.set(`${this.contextKey}-actionModel`, options.model);
            this.navigateTo('TEMPLATES/requestForPaymentRestoreTemplate');
        }
        return Promise.resolve();
    },

    /**
     * handles Copy as Request action from the RFP Workspace
     * @param {Object} optionsParam - Holds options for the payment being copied
     */
    gridPaymentFromTemplate(optionsParam) {
        const options = optionsParam;
        options.model.loadFromPayments = true;
        actionFunction.makeRequestForPayment(options.model, this.contextKey);
        this.navigateTo(`PAYMENTS/addRequestFromTemplate/${options.model.get('FUNCTION')}`);
        return Promise.resolve();
    },

    /**
     * handles Copy as Template action from the RTP Workspace
     * @param {Object} optionsParam - Holds options for the payment being copied
     */
    gridTemplateFromPayment(optionsParam) {
        const options = optionsParam;
        actionFunction.makeTemplateFromRequest(options.model, this.contextKey);
        this.navigateTo(`TEMPLATES/addTemplateFromRequest/${options.model.get('FUNCTION')}`);
        return Promise.resolve();
    },

    createConversationView(options) {
        const conversationView = new ConversationView(options);
        dialog.custom(conversationView);
    },

    startConversation(options) {
        const conversationView = new ConversationView(options);
        dialog.custom(conversationView);
    },

    /**
     * Renders a message above the grid within the RTP workspace.
     * @param {String} action - the action being performed (insert, modify etc..)
     * @param {Object} confirmResponse - response from the server containing action results
     */
    renderAlertMessage(action, confirmResponse) {
        this.gridView.refreshGridData();
        this.renderMessage(action, confirmResponse);
    },

    /*
     * TODO This function will likely need to be addressed as it currently only handles
     * action buttons for a normal payment workflow. Though, that is likely fine for
     * outbound requests and request templates, these actions are not sufficient for
     * inbound requests.
     */
    getButtons(parentHelpers) {
        return Object.entries({
            approve: 'hasApproveEntitlement',
            unapprove: 'hasUnapproveEntitlement',
            reject: 'hasRejectEntitlement',
            delete: 'hasDeleteEntitlement',
            restore: 'hasRestoreEntitlement',
        }).filter(([, value]) => parentHelpers[value])
            .map(([key]) => ({
                class: key,
                localeString: parentHelpers.getButtonString(key),
            }));
    },

    /**
     * @name updateGrid
     * @description refresh the grid so that it reflects any new message data
     */
    updateGrid() {
        if (this.gridView) {
            this.refreshData();
        }
    },

    export() {
        this.exportPayListView();
    },

    print() {
        this.showPrintOptionsModal();
    },

    getPrintOptions() {
        const { inquiryId, summaryInquiryId } = this.options;
        return {
            inquiryId,
            summaryInquiryId,
        };
    },

    templateHelpers() {
        const parentHelpers = ListView.prototype.templateHelpers.call(this);
        return {
            ...parentHelpers,
            entitlements: this.getButtons(parentHelpers),
            hasBulkActionEntitlements: this.hasBulkActionEntitlements()
                && !this.isRFPReceivedGrid,
        };
    },
}));

let list = RtpGridView;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list);
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
