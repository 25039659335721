import ItemView from '@glu/core/src/itemView';
import { appBus } from '@glu/core';
import liveMaintenanceUserMessageNavViewTmpl from './liveMaintenanceUserMessageNavView.hbs';

export default ItemView.extend({
    template: liveMaintenanceUserMessageNavViewTmpl,
    tagName: 'li',
    className: 'message-nav-section',

    events: {
        'click [data-hook="getCurrentLanguageBtn"]': 'setCurrentLanguage',
    },

    appEvents: {
        'liveMaintenance.setActiveMode': 'setActiveMode',
    },

    initialize() {
        this.listenTo(this.model, 'change', this.render);
    },

    /**
     * Set the current language and broadcast to all models
     */
    setCurrentLanguage() {
        // broadcast event for all models to hear
        appBus.trigger('liveMaintenance.toggleMessageView', this.model.cid);
    },

    /**
     * Call to function in model to set active mode to true or false
     * @param  {string} cid
     */
    setActiveMode(cid) {
        this.model.setActiveMode(cid);
    },
});
