import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';

export default Model.extend({

    defaults() {
        return {

        };
    },

    // merge grid data from full records
    mergeData(fullAttributes) {
        this.set(util.extend(this.attributes, fullAttributes));
    },

    name() {
        return this.get('TEMPLATECODEENTITLEMENT');
    },
    description() {
        return this.get('CMB_TEMPLATE_DESCRIPTION');
    },
    paymentType() {
        return this.get('CMB_TYPE_DESCRIPTION');
    },

    bankCode() {
        return this.get('BANKCODE');
    },

    eCode() {
        return this.get('ECODE');
    },
    accountNum() {
        return this.get('ACCOUNTNUM');
    },

    toJSON() {
        const keyVal = this.get(this.collection.keyCol);
        const { keyCol } = this.collection;
        if (!keyVal) {
            return this.attributes;
        }

        // Account numbers may contain multiple hyphens. Split only the first
        const separator = keyVal.toUpperCase() === 'ACCOUNTFILTER' ? /-(.+)/ : '-';
        let parts = this.collection.configuredDataType === 'LEGACYSYSTEMID' ? [keyVal] : keyVal.split(separator);

        if (keyVal.toUpperCase() === 'ACCOUNTFILTER') {
            // the split creates an unwanted empty string at the end of the array.
            parts.pop();
        }

        if (this.collection.configuredDataType === 'ESTATEMENTID') {
            parts = [];
            parts[0] = this.eCode();
            parts[1] = this.accountNum();
            parts[2] = this.bankCode();
        }

        if (this.collection.keyCol.toUpperCase() === 'PRODUCTTEMPLATECODEENTITLEMENT') {
            parts = [this.name(), this.get('PRODUCT'), this.get('TYPE')];
        }

        if (keyCol.toUpperCase() === 'ACCOUNTFILTER' && parts.length > 2) {
            for (let i = 2; i < parts.length; i += 1) {
                parts[1] = `${parts[1]}-${parts[i]}`;
            }
            parts[2] = null;
            parts.pop();
        }

        return {
            dataEntitlementAttPart1: parts[0],
            dataEntitlementAttPart2: parts.length > 1 ? parts[1] : null,
            dataEntitlementAttPart3: parts.length > 2 ? parts[2] : null,
            dataEntitlementType: this.collection.configuredDataType,
        };
    },
});
