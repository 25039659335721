import Layout from '@glu/core/src/layout';
import alert from '@glu/alerts';
import TableModel from 'common/dynamicPages/models/dataTableModel';
import template from 'common/dynamicPages/views/dataTable/dataTable.hbs';
import loadingTemplate from 'common/templates/loadingGrid.hbs';

export default Layout.extend({
    template,
    loadingTemplate,

    ui: {
        $tableRowButton: '[data-hook="getTableRowButton"]',
    },

    events: {
        'click @ui.$tableRowButton': 'onTableRowSelect',
    },

    /**
     * @param {object} options
     * @param {object} options.context
     * @param {string} options.serviceName
     * @param {array}  [options.filters]
     * - optional array of filter fields to apply to table fetch
     * @param {object} [options.actionCells]
     * - optional override to handle which column will have actionable cells
     */
    initialize(options) {
        this.context = options.context;
        this.model = new TableModel(null, {
            serviceName: options.serviceName,
            searchFields: options.filters,
            actionCellsOverride: options.actionCells,
            suppressListAction: options.suppressListAction,
            suppressActionCells: options.suppressActionCells,
            viewId: options.viewId,
        });
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadViewRequirements();
        } else {
            this.trigger('table:loaded');
        }
    },

    /**
     * @return {Promise}
     * Fetches the data for the table using passed in service name option
     * and will re-initiate the render after finished fetching
     */
    loadViewRequirements() {
        // fetch for data to be used to populate our row collections
        return this.model.fetch().then(() => {
            this.onLoadedRequirements();
        }).catch((err, resp) => {
            this.onLoadedRequirements();
            this.showTableError(err, resp);
        });
    },

    onLoadedRequirements() {
        this.setHasLoadedRequiredData(true);
        this.render();
    },

    /**
     * @param {Event} e
     * Table row click handler that will trigger the select action and return the model
     * of the row selected.
     */
    onTableRowSelect(e) {
        const rowId = e.currentTarget.value;
        const rowSelected = this.model.findRowById(rowId);
        this.trigger('table:row:action:select', rowSelected);
    },

    /**
     * @param {object} [filters] - additional filters search param object
     * @param {string} viewId - saved view id
     * Method to refetch the data for the table and rerender it
     */
    refreshTableData(filters, viewId) {
        if (viewId) {
            this.model.viewId = viewId;
        }
        this.trigger('table:refresh');
        this.model.setSearchFields(filters);
        this.setHasLoadedRequiredData(false);
        this.render();
    },

    /**
     * @param {object} err - error object
     * @param {object} resp object
     * Show alert upon catching error with loading view requirements
     */
    showTableError(err, resp) {
        const alertMsg = (resp) ? resp.message : `${err.status}: ${err.statusText}`;
        const alertView = alert.danger(alertMsg);
        this.alertRegion.show(alertView);
    },

    formatTableData(model) {
        if (this.options.formatTableData) {
            return this.options.formatTableData(model);
        }
        return model;
    },

    templateHelpers() {
        return {
            getHeaderColumns: this.model.get('headerRow').toJSON(),
            getFooterColumns: this.formatTableData(this.model.get('footerRow')).toJSON(),
            getBodyRows: this.formatTableData(this.model.get('bodyRows')).toJSON(),
            getTotalColumns: this.model.get('headerRow').toJSON().length,
        };
    },
});
