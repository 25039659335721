import React from 'react';
import { withValidation } from '@glu/validation-react';

export default function withNewValidation(WrappedComponent) {
  const Validated = props => {
    const passThroughProps = Object.assign({}, props, {
      onBlur: (name, value) => {
        props.onChange(name, value, true);

        if (props.onBlur) {
          props.onBlur(name, value);
        }
      },
      value: props.value || '',
      error: props.errors && props.errors[props.name]
    });

    return <WrappedComponent {...passThroughProps} />;
  };

  Validated.displayName = `Validated(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return withValidation(Validated);
}
