/* eslint no-underscore-dangle:0 */
import Backbone from 'backbone';
import Marionette from 'backbone.marionette';

// jQuery plugins
import 'bootstrap/js/tooltip';
import 'bootstrap/js/affix';
import 'bootstrap/js/alert'; // maybe can be moved to glu-alerts
import 'bootstrap/js/button';
import 'bootstrap/js/collapse';
import 'bootstrap/js/transition';
import 'bootstrap/js/dropdown'; // needed for Glu Buttons
import 'bootstrap/js/popover';
import 'bootstrap/js/scrollspy';
import 'bootstrap/js/modal'; // not sure if needed, because tried with glu-dialog it still work if no require. TODO
import 'bootstrap/js/tab';

// We load this file, so that all helpers available for LOB codebases, with no need to inject dependency manually.
// import './templateHelpers';
// TODO, doesn't work here. Throws an error: "Uncaught Error: Module name "glu/templateHelpers" has not been loaded yet for context: _"
// TODO maybe better way to do this, if we assume, all will inject glu/glu.js file. Not sure.

/**
 * This module pulls in baseline dependencies, such as jQuery plugins to support UI fundamentals, like dropdowns
 */
// https://github.com/marionettejs/marionette.inspector#caveats
if (window.__agent) {
  window.__agent.start(Backbone, Marionette);
}

export default {
  VERSION: '<<VERSION>>',
  Backbone,
  Marionette,
  History: Backbone.History,
  history: Backbone.history
};
