import React from 'react';

const RtpContext = React.createContext({
    conversationItems: null,
    incorrectReasons: null,
    missingReasons: null,
    incorrectCount: 0,
    missingCount: 0,
    responses: null,
});

export default RtpContext;
