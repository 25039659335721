var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":13,"column":31},"end":{"line":13,"column":39}}}) : helper)))
    + "\" data-item=\"\" "
    + alias4(((helper = (helper = lookupProperty(helpers,"selected") || (depth0 != null ? lookupProperty(depth0,"selected") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"selected","hash":{},"data":data,"loc":{"start":{"line":13,"column":54},"end":{"line":13,"column":66}}}) : helper)))
    + ">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":13,"column":67},"end":{"line":13,"column":76}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"form-container mfa-criteria\">\n    <div>\n        <div class=\"field-container-sm\">\n            <label for=\"criteriaName\" class=\"required\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.ChallengeName",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":55},"end":{"line":4,"column":85}}}))
    + "</label>\n            <input class=\"form-control\" type=\"text\" name=\"criteriaName\" data-bind=\"model\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":90},"end":{"line":5,"column":123}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"noEdit") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":123},"end":{"line":5,"column":152}}})) != null ? stack1 : "")
    + ">\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"criteriaName\"></span>\n        </div>\n\n        <div class=\"field-container-sm\">\n            <label for=\"challengeType\" class=\"required\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.challengeType",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":56},"end":{"line":10,"column":86}}}))
    + "</label>\n            <select class=\"form-control\" data-bind=\"model\" name=\"challengeType\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":80},"end":{"line":11,"column":113}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"challengeMethods") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":14,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n        </div>\n    </div>\n    <div>\n        <div class=\"field-container-lg frequency-controls\">\n            <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.FrequencyOfChallenge",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":20,"column":53}}}))
    + "</h4>\n            <div>\n                <label for=\"frquency-always\"><input type=\"radio\" id=\"frquency-always\" value=\"ONCEPERSESSION\" name=\"frequency\" data-bind=\"model\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":144},"end":{"line":22,"column":177}}})) != null ? stack1 : "")
    + ">\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.OncePerSession",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":23,"column":47}}}))
    + "</label>\n            </div>\n            <div>\n                <label for=\"frequency-session\"><input type=\"radio\" id=\"frequency-session\" value=\"ALWAYS\" name=\"frequency\" data-bind=\"model\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":140},"end":{"line":26,"column":173}}})) != null ? stack1 : "")
    + ">\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.Always",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":16},"end":{"line":27,"column":39}}}))
    + "</label>\n            </div>\n            <div>\n                <label for=\"frequency-action-session\"><input type=\"radio\" id=\"frequency-action-session\" value=\"ONCEPERACTIONPERSESSION\" name=\"frequency\" data-bind=\"model\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":171},"end":{"line":30,"column":204}}})) != null ? stack1 : "")
    + ">\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.OncePerActionSession",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":16},"end":{"line":31,"column":53}}}))
    + "</label>\n            </div>\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"frequency\"></span>\n            <hr/>\n            <div>\n                <label for=\"finalApproval\">\n                    <input type=\"checkbox\" id=\"final-approve\" name=\"finalApproval\" data-bind=\"model\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":101},"end":{"line":37,"column":134}}})) != null ? stack1 : "")
    + "> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.finalApproval",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":37,"column":136},"end":{"line":37,"column":166}}}))
    + "\n                </label>\n            </div>\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"finalApproval\"></span>\n        </div>\n    </div>\n\n    <div class=\"precheck-control\">\n        <div class=\"checkbox\">\n            <input type=\"checkbox\" name=\"precheckEnabled\" data-bind=\"model\" id=\"precheck\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":90},"end":{"line":46,"column":123}}})) != null ? stack1 : "")
    + ">\n            <label for=\"precheck\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"challengeMgr.precheck",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":47,"column":34},"end":{"line":47,"column":68}}}))
    + "</label>\n        </div>\n    </div>\n\n    <div class=\"precheckpmt-control\">\n        <div class=\"checkbox\">\n            <input type=\"checkbox\" name=\"precheckPaymentIncluded\" data-bind=\"model\" id=\"precheckpmt\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":101},"end":{"line":53,"column":134}}})) != null ? stack1 : "")
    + ">\n            <label for=\"precheckpmt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"challengeMgr.precheckpmt",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":54,"column":37},"end":{"line":54,"column":74}}}))
    + "</label>\n        </div>\n    </div>\n\n\n    <hr>\n\n    <div>\n        <div class=\"field-container-md\">\n            <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.SystemActions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":63,"column":16},"end":{"line":63,"column":46}}}))
    + "</h4>\n        </div>\n    </div>\n    <div>\n        <div class=\" mfa-system-actions\">\n\n        </div>\n    </div>\n</div>\n";
},"useData":true});