import store from '../system/utilities/cache';

const useCache = (key) => {
    function set(value) {
        return store.set(key, value);
    }

    function get() {
        return store.get(key);
    }

    function unset() {
        return store.unset(key);
    }

    return {
        set,
        get,
        unset,
    };
};
export default useCache;
