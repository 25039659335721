import $ from 'jquery';
import moment from 'moment';
import numeral from 'numeral';
import services from 'services';

import '@glu/flex-dropdown/src/inputPlugins';
import dialog from '@glu/dialog';
import http from '@glu/core/src/http';
import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import Model from '@glu/core/src/model';
import NestedModel from '@glu/core/src/nestedModel';
import Scheduler from '@glu/scheduler';
import util from '@glu/core/src/util';
import { log } from '@glu/core';

import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import constants from 'common/dynamicPages/api/constants';
import dateUtil from 'common/util/dateUtil';
import Decimals from 'common/dynamicPages/api/decimals';
import entitlements from 'common/dynamicPages/api/entitlements';
import Formatter from 'system/utilities/format';
import PaymentUtil from 'common/util/paymentUtil';
import RtgsHelper from 'app/smbPayments/util/rtgs';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import serviceUtil from 'common/util/services';
import showAccountBalanceUtil from 'common/util/showAccountBalanceUtil';
import systemConfig from 'system/configuration';
import transform from 'common/util/transform';
import userInfo from 'etc/userInfo';

import Accounts from '../collections/accounts';
import FormDialog from './transferRateDialog';
import fxTransfer from './fxTransfer';
import template from './transferView.hbs';
import TransferModel from '../models/transfer';

let dateFormat;
let dateFormats;
const days = [
    'WEEKLYDAYMON',
    'WEEKLYDAYTUE',
    'WEEKLYDAYWED',
    'WEEKLYDAYTHURS',
    'WEEKLYDAYFRI',
    'WEEKLYDAYSAT',
    'WEEKLYDAYSUN',
];

// constants
const dateService = services.generateUrl(constants.URL_GETDATESLIST);

const DEBITACCOUNTNUMBER = 'ACCOUNTFILTER';

const BENEACCOUNT = 'BENE_ACCOUNTENTITLEMENT';

const TOASUBTYPE = 'TO';

const requestOptions = {
    ACCOUNTFILTER: {
        bankCodeKey: 'DEBIT_BANK_CODE',
        currencyKey: 'DEBIT_CURRENCY',
        accountNumberKey: 'DEBIT_ACCOUNT_NUMBER',
    },

    BENE_ACCOUNTENTITLEMENT: {
        bankCodeKey: 'BENE_BANK_CODE',
        currencyKey: 'CREDIT_CURRENCY',
        accountNumberKey: 'BENE_ACCOUNT',
    },
};

export default Layout.extend({
    template,
    className: 'xfer-view',

    ui: {
        fromCombo: '[data-hook="getFromCombo"]',
        toCombo: '[data-hook="getToCombo"]',
        dateField: '[name="VALUE_DATE"]',
        amtField: '[name="ENTERED_AMOUNT"]',
        optRow: '.xfer-opt-fields',
        optRowStatic: '.xfer-opt-fields-static',
        rateLabel: '.indicative-rate',
        occInput: '[name="ENDCYCLES"]',
        selectCurrency: '[name="currencyType"]',
        currencyText: '.currency-text',
        exchangeRateAmount: '[data-hook="exchangeRateAmount"]',
        addContractID: '[data-hook="contractIDpopUp"]',
        contractID: '[data-hook="contractID"]',
        removeContractID: '[data-hook="removeContractID"]',
        businessDayMode: '.businessDay-Mode',
        scheduleWarningDisplay: '.schedWarning',
        singleRecur: '[data-hook="single-recur-select"]',
        $indicateAmount: '[data-hook="getIndicativeAmount"]',
        $indicateRate: '[data-hook="getIndicativeRate"]',
        transDateMessage: '[data-hook="getDateMessage"]',
        timeField: '[name="TRANSFER_TIME"]',
        $clearBtn: '[data-hook="clear-fields"]',
        $xBtn: '[data-action="removeItem"]',
    },

    events: {
        'change @ui.toCombo': 'updateToCombo',
        'change @ui.fromCombo': 'fromChanged',
        'click @ui.addContractID': 'openRateDialog',
        'change @ui.amtField': 'resetExchangeRate',
        'click @ui.removeContractID': 'removeContractID',
        'change @ui.dateField': 'updateDates',
        'click [data-hook="single-recur-select"]': 'singleRecurringSelect',
        'click [data-hook="clear-fields"]': 'removeItem',
    },

    behaviors() {
        return {
            LookupHelperText: {
                getTemplateInput: this.getHelperTextTemplateInput.bind(this),
            },
        };
    },

    regions: {
        schedRegion: '[data-hook="schedulerRegion"]',
        singleSchedRegion: '[data-hook="singleSchedulerRegion"]',
    },
    removeItem() {
        this.model.destroy();
    },

    initialize(opts) {
        let viewCurrency = '';

        dateFormat = userInfo.getDateFormat();
        dateFormats = {
            dateFormats: {
                server: 'YYYY-MM-DD',
                ui: dateFormat,
            },
        };

        this.isFlexDDSetup = false;
        this.model = this.model || new TransferModel();
        this.templateMode = opts.templateMode;
        this.state = opts.mode;
        this.currencyType = this.getCurrencyType();
        this.exchangePrecision = +serverConfigParams.get('ExchangeRateScale');
        this.accountHelperText = [];
        this.displayAvailableBalances = (serverConfigParams.get('DisplayAccountBalanceOnPaymentsScreens') === 'true');
        this.buildScheduleModel();

        /*
         * In the event that this configuration is enabled, the available CREDIT_CURRENCIES will be
         * dependent on the ACCOUNTFILTER and DEBIT_CURRENCY
         */
        this.restrictedCreditCurrenciesEnabled = applicationConfigParams.getValue('WIRES', 'SUPPORTDEFINEDCURRENCYPAIRS') === '1';

        const isCreditAmount = this.model.get('ENTERED_AMOUNT_FLAG') === 'C';

        /*
         * If creating from template or modifying/viewing a template/transfer
         * make sure the correct amount is displayed based on the entered currency flag
         * NOTE: if this is an 'add new transfer template'
         * we won't have the values defined in the model
         */
        if (this.options.copyFromTemplate || this.templateMode || this.options.mode === 'modify' || this.options.isModify || opts.readOnly) {
            this.toCurrency = this.model.get('CREDIT_CURRENCY');
            this.fromCurrency = this.model.get('DEBIT_CURRENCY');
        }

        this.model.set({
            ENTERED_AMOUNT:
            this.model.get(isCreditAmount ? 'CREDIT_AMOUNT' : 'DEBIT_AMOUNT'),
            CURRENCYCODE:
            this.model.get(isCreditAmount ? 'CREDIT_CURRENCY' : 'DEBIT_CURRENCY'),
        });

        if (opts.readOnly || opts.copyFromTemplate || opts.modifyPaymentFromTemplate || this.options.mode === 'repair') {
            viewCurrency = this.model.get(isCreditAmount ? 'CREDIT_CURRENCY' : 'DEBIT_CURRENCY');
            // calculate debit or credit amount
            if (this.model.get('EXCHANGE_RATE')) {
                this.calculateExchangedAmount(viewCurrency);
            } else {
                this.calculateIndicativeAmount();
            }

            this.model.fromAccount = new Model({
                ACCOUNTFILTER: this.model.get('ACCOUNTFILTER'),
                CURRENCYCODE: this.model.get('DEBIT_CURRENCY'),
                ACCOUNTNUM: this.model.get('DEBIT_ACCOUNT_NUMBER'),
                BANKCODE: this.model.get('DEBIT_BANK_CODE'),
                mapDataList: {
                    DEBIT_ACCOUNT_NUMBER: this.model.get('DEBIT_ACCOUNT_NUMBER'),
                    DEBIT_CURRENCY: this.model.get('DEBIT_CURRENCY'),
                    DEBIT_BANK_CODE: this.model.get('DEBIT_BANK_CODE'),
                },
            });

            this.model.toAccount = new Model({
                ACCOUNTFILTER: this.model.get('BENE_ACCOUNTENTITLEMENT'),
                CURRENCYCODE: this.model.get('CREDIT_CURRENCY'),
                ACCOUNTNUM: this.model.get('CREDIT_ACCOUNT'),
                BANKCODE: this.model.get('BENE_BANK_CODE'),
                mapDataList: {
                    BENE_ACCOUNT: this.model.get('BENE_ACCOUNT'),
                    CREDIT_CURRENCY: this.model.get('CREDIT_CURRENCY'),
                    BENE_BANK_CODE: this.model.get('BENE_BANK_CODE'),
                },
            });

            if (this.model.get('TEMPLATE_MAX_AMOUNT')) {
                this.templateMaxAmountStr = `${Formatter.formatCurrency(this.model.get('TEMPLATE_MAX_AMOUNT'))} ${this.model.get('TEMPLATE_MAX_CURRENCY')}`;
            }
        }

        if (this.model.collection) {
            this.listenTo(
                this.model.collection,
                {
                    'toggle-opt-fields': this.toggleOpt,
                    'account-selected': this.enableFields,
                    'lock-fields': this.lockFields,
                    'toggle-clear-remove': this.toggleClearRemove,
                },
            );
        }

        this.fromAccounts = new Accounts({
            type: opts.type,
            templateMode: opts.templateMode,
        });

        this.toAccounts = new Accounts({
            isToAccounts: true,
            type: opts.type,
            templateMode: opts.templateMode,
        });

        this.processingDates = {
            blockedDates: [],
            processingDays: [],
            daysForward: [],
            cutOffTimes: [],
            daysBack: 0,
            showCalendarIcon: true,
            showDropdowns: true,
            holidayDates: [],
        };

        const notOneToOne = (opts.type !== 'ONE2ONE' && opts.type !== 'SINGLE');
        this.hideSingleRecur = (opts.type !== 'SINGLE') || (opts.hasTemplateInsertEntitlement !== true);
        this.parentLayout = opts.parentLayout;
        this.hideAmount = notOneToOne && opts.isSingle;
        this.hideDate = !util.isUndefined(opts.hideDate)
            || (notOneToOne && !opts.isSingle)
            || !util.isUndefined(opts.templateMode);
        this.hideTime = applicationConfigParams.getValue('TRANSFER', 'ENABLETRANSFERTIME') !== '1';
        this.hideClose = !util.isUndefined(opts.hideClose) || (notOneToOne && opts.isSingle) || opts.modifyPaymentFromTemplate || opts.type === 'SINGLE';
        this.hideFromAccount = (opts.type === 'ONE2MANY' && !opts.isSingle)
            || (opts.type === 'MANY2ONE' && opts.isSingle);
        this.hideToAccount = (opts.type === 'ONE2MANY' && opts.isSingle)
            || (opts.type === 'MANY2ONE' && !opts.isSingle);
        this.hasAutoApproveChecked = false;
        this.hasAutoApprove = false;
        this.isTemplate = this.parentLayout.model?.get('functionCode') === 'TMPL';

        this.model.setValidators(!this.hideFromAccount, !this.hideToAccount, !this.hideDate, !this.hideAmount && (!this.templateMode || this.model.get('schedModel')), this.options.fxRateType);

        /**
         * Get the allow contract override value and wait for our promise
         * Show the 'add contract id' if currencies are different
         * and ALLOWCONTRACTRATEOVERRIDE=1
         */
        this.allowContractOverridePromise = this.getCompanyRTGSPreferences().then((data) => {
            let isOverride = true;

            if (data.inquiryResponse.rows[0].columns[1].fieldName === 'ALLOWCONTRACTRATEOVERRIDE'
                && data.inquiryResponse.rows[0].columns[1].fieldValue === '1') {
                isOverride = false;
            }

            return isOverride;
        });
        this.appBus.on('scheduler:modifyschedulecheckbox', ({ readOnly }) => {
            this.disableBusinessOnlyInputs(readOnly);
        });

        /*
         * When restricted currencies config is enabled, and this is a single transfer, ensure the
         * appropriate CREDIT_CURRENCY validator is applied
         */
        if (!this.parentLayout.templateMode && this.restrictedCreditCurrenciesEnabled && (this.options.type === 'SINGLE' || this.options.type === 'ONE2ONE')) {
            // listen for changes to ACCOUNTFILTER and DEBIT_CURRENCY to get credit currencies
            this.listenTo(this.model, 'change:DEBIT_CURRENCY change:ACCOUNTFILTER', this.updateAllowedCreditCurrenciesFromDependent);

            /*
             * if there is already a credit currency present, then retrieve the allowed credit
             * currencies and add the appropriate validator
             */
            if (this.model.get('CREDIT_CURRENCY')) {
                this.updateAllowedCreditCurrenciesFromDependent();
            }
        }
    },

    disableBusinessOnlyInputs(isDisabled = false) {
        this.$el.find('.businessDay-Mode input[type="radio"]').prop('disabled', isDisabled);
    },

    toggleClearRemove(isSingleEntry) {
        this.ui.$clearBtn.toggleClass('hide', !isSingleEntry);
        this.ui.$xBtn.toggleClass('hide', isSingleEntry);
    },

    checkForDefaultDate() {
        return (this.options.type === 'ONE2ONE'
            && !this.options.readOnly
            && !this.hideDate
            && this.options.mode !== 'modify'
            && this.parentLayout.model.get('functionCode') !== 'TMPL')
            || this.parentLayout.hasDefaultDate;
    },

    onRender() {
        const self = this;
        this.hasDefaultDate = this.checkForDefaultDate();

        if (this.hasDefaultDate && this.model.get('TRAN_DATE')) {
            this.model.set('VALUE_DATE', this.model.get('TRAN_DATE'));
        }

        if (!this.hideAmount) {
            this.ui.amtField.inputmask('decimal', util.extend(
                Formatter.getCurrencyMaskOptions(true, true),
                {
                    placeholder: '0.00',
                    digitsOptional: false,
                },
            ));
        }

        // Ensure new transfers match the provided toggle setting.
        this.toggleOpt(this.options.showOptionalFields);

        if (!this.hideDate && !this.options.readOnly) {
            const dateOptions = {
                blockedDates: this.processingDates.blockedDates,
                processingDays: this.processingDates.processingDays,
                cutOffTimes: this.processingDates.cutOffTimes,
                showCalendarIcon: true,
                showDropdowns: true,
                daysForward: this.processingDates.daysForward[0],
                daysBack: new Date(),
                autoUpdateInput: true,

                // needed for one2one due to multiple datepickers on the same page
                useThis: this.options.type === 'ONE2ONE',
            };

            this.ui.dateField.nhDatePicker(dateOptions);

            /*
             * we wont set value date or tran date, based on this call, if it is modify
             * mode.  this is checked in the handling of response
             */
            this.setValidDate();
        }

        /*
         * This will validate that the accounts exist and set the account comboboxes to
         * the correct values.
         */
        if (systemConfig.isAdmin()) {
            this.model.set({
                DEBIT_ACCOUNT_FORMATTED: `${this.model.get('DEBIT_ACCOUNT_TITLE')} - ${this.model.get('DEBIT_ACCOUNT_NUMBER')}`,
                BENE_ACCOUNT_FORMATTED: `${this.model.get('BENE_NAME')} - ${this.model.get('BENE_ACCOUNT')}`,
            });
        } else if (this.options.mode !== 'add') {
            this.validateAccounts(this.fromAccounts, this.model.get('ACCOUNTFILTER'));
            this.validateAccounts(this.toAccounts, this.model.get('BENE_ACCOUNTENTITLEMENT'));
        }

        if (!this.hideTime && !this.options.readOnly) {
            const timeMask = {
                alias: 'datetime',
                mask: 'h:s t\\m',
                insertMode: false,
                placeholder: '00:00 am',
                hourFormat: '12',
                autoUnmask: false,
            };

            this.ui.timeField.inputmask(timeMask);
        }

        this.ui.fromCombo.comboBox({
            query: util.debounce((query) => {
                let filterValue;

                /*
                 * If this is MANY2ONE we won't have a BENE_ACCOUNTENTITLEMENT value so
                 * we need to try and get the value from the toAccount in the model
                 * If it is not MANY2ONE we can just use the BENE_ACCOUNTENTITLEMENT
                 * in the model.
                 */
                if (self.options.type === 'MANY2ONE') {
                    if (self.model.collection && self.model.collection.at(0)
                        && self.model.collection.at(0).toAccount) {
                        filterValue = self.model.collection.at(0).toAccount.get('ACCOUNTFILTER');
                    } else {
                        filterValue = self.filterVal;
                    }
                } else {
                    filterValue = self.model.get('BENE_ACCOUNTENTITLEMENT');
                }

                self.fromAccounts.setFilter(query.page, query.term, filterValue);
                self.fromAccounts.fetch({
                    remove: query.page === 1,

                    success: (collection, response) => {
                        const result = util.filter(collection.toJSON(), collItem => util.find(
                            response,
                            newItem => transform.pairsToHash(newItem.mapDataList, 'toField', 'value').ACCOUNTFILTER === collItem.mapDataList.ACCOUNTFILTER,
                        ));
                        if (this.templateMode) {
                            query.callback({
                                results: result,
                                more: self.fromAccounts.hasMorePages,
                            });
                        } else {
                            // Go get the Balances for the fromAccounts
                            this.fetchBalancesForTheseAccounts(result, 'ACCOUNTFILTER')
                                .then((balances) => {
                                    // setup helper text
                                    this.processBalances(balances, result, 'ACCOUNTFILTER');
                                    // merge the balances onto the lookup results
                                    return this.mergeBalancesOnAccounts(result, balances);
                                })
                                .then((theResult) => {
                                    query.callback({
                                        results: theResult,
                                        more: this.fromAccounts.hasMorePages,
                                    });
                                }).catch((err) => {
                                    log.error(err);
                                }, () => {
                                    log.error(locale.get('common.combo.data.error', DEBITACCOUNTNUMBER));
                                });
                        }
                    },
                });
            }, constants.COMBO_DEBOUNCE),
        });

        this.ui.toCombo.comboBox({
            query: util.debounce((query) => {
                let filterValue;

                /*
                 * If this is ONE2MANY we won't have a DEBIT_ACCOUNT_NUMBER value in the model
                 * If it is not ONE2MANY we can just use the DEBIT_ACCOUNT_NUMBER in the model.
                 */
                if (self.options.type === 'ONE2MANY') {
                    filterValue = self.filterVal;
                    this.updateToAccount();
                } else {
                    filterValue = self.model.get('ACCOUNTFILTER');
                }

                self.toAccounts.setFilter(query.page, query.term, filterValue);
                self.toAccounts.fetch({
                    remove: query.page === 1,

                    success: (collection, response) => {
                        const result = util.filter(collection.toJSON(), collItem => util.find(
                            response,
                            newItem => transform.pairsToHash(newItem.mapDataList, 'toField', 'value').BENE_ACCOUNTENTITLEMENT === collItem.mapDataList.BENE_ACCOUNTENTITLEMENT,
                        ));
                        if (this.templateMode) {
                            query.callback({
                                results: result,
                                more: this.toAccounts.hasMorePages,
                            });
                        } else {
                            // Go get the Balances for the toAccounts
                            this.fetchBalancesForTheseAccounts(result, 'BENE_ACCOUNTENTITLEMENT')
                                .then((balances) => {
                                    // setup helper text
                                    this.processBalances(balances, result, 'BENE_ACCOUNTENTITLEMENT');

                                    return this.mergeBalancesOnAccounts(result, balances);
                                })
                                .then((theResult) => {
                                    query.callback({
                                        results: theResult,
                                        more: this.toAccounts.hasMorePages,
                                    });
                                }).catch((err) => {
                                    log.error(err);
                                }, () => {
                                    log.error(locale.get('common.combo.data.error', BENEACCOUNT));
                                });
                        }
                    },
                });
            }, constants.COMBO_DEBOUNCE),
        });

        let schedModel;

        // TODO refactor so this method isn't so big
        switch (self.options.type) {
        case 'ONE2ONE':

            this.listenTo(this.model, 'change:ACCOUNTFILTER', (modelParam, val) => {
                const model = modelParam;
                model.fromAccount = this.fromAccounts.get(val);
                self.parentLayout.handleAccountSelection(val, model.fromAccount.get('CURRENCYCODE'));
                // When we switch between single and multi, we need to re-check this.
                this.hasDefaultDate = this.checkForDefaultDate();
                this.setValidDate();
            });

            this.listenTo(this.model, 'change:BENE_ACCOUNTENTITLEMENT', (modelParam, val) => {
                const model = modelParam;
                model.toAccount = this.toAccounts.get(val);
                self.parentLayout.handleAccountSelection(val, model.toAccount.get('CURRENCYCODE'));

                // When we switch between single and multi, we need to re-check this.
                this.hasDefaultDate = this.checkForDefaultDate();
                this.setValidDate();
            });

            if (this.hasDefaultDate) {
                this.ui.transDateMessage.hide();
            }

            break;

        case 'SINGLE':
            schedModel = this.model.get('schedModel');

            this.listenTo(this.model, 'change:BENE_ACCOUNTENTITLEMENT', (modelParam, val) => {
                const model = modelParam;
                model.toAccount = this.toAccounts.get(val);
                self.parentLayout.handleAccountSelection(val, model.toAccount.get('CURRENCYCODE'));
                self.setValidDate();
            });

            if (this.model.get('schedModel')) {
                const patternMode = this.model.get('PATTERNMODE');
                this.recurCheckUnchecked = !(patternMode && patternMode !== 'O');

                const showModifyScheduleCheckbox = !!(
                    this.options.mode === 'modify'
                        && !this.recurCheckUnchecked
                );
                this.scheduler = new Scheduler({
                    model: schedModel,
                    startDate: dateFormats,
                    simpleEndDate: false,
                    startValue: schedModel.get('starts') || Formatter.formatDate(new Date(), 'YYYY-MM-DD'),
                    endValue: schedModel.get('ends'),
                    weekly: true,
                    monthly: true,
                    daily: false,
                    ends: dateFormats,
                    /**
                     * We cannot rely on this.options.readOnly here because in modify
                     * mode the schedule is ineditable by default.
                     */
                    readOnly: this.state === 'view' || showModifyScheduleCheckbox,
                    showModifyScheduleCheckbox,
                });

                this.singleSchedRegion.show(this.scheduler);
                this.ui.businessDayMode.show();
                this.model.singleScheduleModel = this.scheduler;
            }

            break;

        case 'ONE2MANY':

            if (this.options.isSingle) {
                this.listenTo(this.model, 'change:ACCOUNTFILTER', (modelParam, val) => {
                    const model = modelParam;
                    model.fromAccount = this.fromAccounts.get(val);
                    self.parentLayout.handleAccountSelection(val, model.fromAccount.get('CURRENCYCODE'));
                    self.setValidDate();
                    self.validateAndUpdateDates();
                });
            } else {
                this.ui.toCombo.comboBox('disable').on('change', (e) => {
                    self.model.toAccount = self.toAccounts.get(e.val);
                });
            }

            break;

        case 'MANY2ONE':

            if (this.options.isSingle) {
                this.listenTo(this.model, 'change:BENE_ACCOUNTENTITLEMENT', (modelParam, val) => {
                    const model = modelParam;
                    model.toAccount = this.toAccounts.get(val);
                    self.parentLayout.handleAccountSelection(val, model.toAccount.get('CURRENCYCODE'));
                    self.setValidDate();
                    self.validateAndUpdateDates();
                });
            } else {
                this.ui.fromCombo.comboBox('disable').on('change', (e) => {
                    self.model.fromAccount = self.fromAccounts.get(e.val);
                    self.validateAndUpdateDates();
                });
            }

            this.listenTo(this.model, 'change:ACCOUNTFILTER', (modelParam, val) => {
                const model = modelParam;
                model.fromAccount = this.fromAccounts.get(val);
                self.validateAndUpdateDates();
            });

            break;

        case 'RECUR':

            this.model.addRecurringValidations();

            this.listenTo(
                this.model,
                {
                    'change:BENE_ACCOUNTENTITLEMENT': (modelParam, val) => {
                        const model = modelParam;
                        model.toAccount = this.toAccounts.get(val);
                        self.retrieveEarliestValueDate();
                    },

                    'change:ACCOUNTFILTER': (modelParam, val) => {
                        const model = modelParam;
                        model.fromAccount = this.fromAccounts.get(val);
                        self.retrieveEarliestValueDate();
                    },
                },
            );

            schedModel = this.model.get('schedModel');

            if (!schedModel) {
                // This is a strange bug with scheduler that needs to be addressed in glu
                schedModel = this.initializeSchedModel();

                this.model.set({
                    NONBUSINESSDAYMODE: 'prev',
                    ENDMODE: 'effdate',
                });
            }

            this.scheduler = new Scheduler({
                model: schedModel,
                startDate: dateFormats,
                simpleEndDate: dateFormats,
                startValue: schedModel.get('starts') || Formatter.formatDate(new Date(), 'YYYY-MM-DD'),
                weekly: true,
                monthly: true,
                daily: false,
                ends: dateFormats,
            });

            this.schedRegion.show(this.scheduler);

            this.model.unset('schedModel');

            this.ui.occInput.inputmask(
                'integer',
                {
                    allowMinus: false,
                    max: 999,
                },
            );

            break;
        default:
        }

        if (!this.model.get('schedModel')) {
            this.ui.businessDayMode.hide();
        } else {
            this.setsingleRecurCheck(this.model.get('PATTERNMODE'));
        }
        this.ui.scheduleWarningDisplay.hide();
        this.ui.amtField.prop('disabled', true);
        this.toggleOpt(false);

        if (this.model.get('ACCOUNTFILTER')) {
            this.enableFields(this.model.get('ACCOUNTFILTER'), this.model.get('DEBIT_CURRENCY'));

            this.ui.toCombo.comboBox('enable');

            // for modify & view ONLY
            if (this.options.mode === 'modify' || (!this.options.mode && this.options.readOnly)) {
                this.state = (this.options.mode === 'modify') ? 'modify' : 'view'; // this is needed for the helper text behavior
            }
        }

        if (this.options.readOnly) {
            this.toggleOpt(false);

            if (this.scheduler) {
                this.scheduler.$('input').prop('disabled', true);
                this.scheduler.$('select').comboBox('disable', true);
            }
        }

        if (this.options.mode === 'modify' || this.options.mode === 'add'
            || (!this.options.readOnly
            && (this.options.modifyPaymentFromTemplate || this.options.copyFromTemplate))) {
            const currencyCode = this.model.get('CURRENCYCODE') || this.getCreditCurrency();

            /*
             * We don't want to do setupCurrencyDropdown() and setupExchangeRate()
             * if the current workflow is adding a new transfer while modifying a template.
             */
            if (this.model.get('DEBIT_CURRENCY')) {
                this.setupCurrencyDropdown();
                this.setupExchangeRate(undefined, 'onRender');
            }
            if (currencyCode) {
                this.setAmountMaskingFromCurrency('ENTERED_AMOUNT', currencyCode);
            }
        }

        this.resetExchangeRate();
        this.setExchangeRateVisibility();
        if ((!util.isNullOrUndefined(this.options.mode) && this.options.mode === 'modify')
            || this.model.get('CUSTOMER_REFERENCE') || this.model.get('SPECIAL_INSTRUCTIONS') || this.model.get('TRANSFER_TIME')) {
            // check whether it's set?
            this.parentLayout.ui.optFieldSwitch.prop('checked', true);
            this.toggleOpt(true);
        }
    },

    /**
     * @name updateToAccount
     * @description updates the toAccounts combo isTOA prop based on the
     * parent   this is necessary for the ONE2MANY transfer where the first
     * item is the from account and the 2nd thru n items are the to accounts
     */
    updateToAccount() {
        this.toAccounts.isTOA = this.parentLayout.model.get('isTOA');
    },

    initializeSchedModel(attrs = {}) {
        const schedModel = new NestedModel(attrs);
        /*
         * HACK: NH-110556 due to nestedModel failure to access necessary attributes
         * we needed to manually connect nested model attribute to non nested attribute
         */
        this.listenTo(schedModel, 'change', () => {
            const recAttrs = util.omit(schedModel.attributes, 'recurrence');

            schedModel.set('recurrence', { ...schedModel.attributes.recurrence, ...recAttrs }, { silent: true });
        });

        return schedModel;
    },

    /* Get currency type for template */
    getCurrencyType() {
        let c;
        const currencyType = this.model.get('DBCR_FLAG') || this.model.get('ENTERED_AMOUNT_FLAG');

        if (this.model.get('DBCR_FLAG')) {
            this.model.set('ENTERED_AMOUNT_FLAG', this.model.get('DBCR_FLAG'));
        }

        if (currencyType) {
            if (currencyType === 'C') {
                c = this.model.get('CREDIT_CURRENCY');
            } else {
                c = this.model.get('DEBIT_CURRENCY');
            }

            this.model.set('CURRENCYCODE', c);
            return c;
        }
        return undefined;
    },

    // Validate that to/from accounts are still valid. Should run on render in modify mode.
    validateAccounts(accountList, accountNumber) {
        const type = this.model.get('ENTRYMODE');
        let otherAccount;

        /*
         * When a user adds an additional account to a transfer set accountNumber will
         * not be set so there will be nothing to validate and we don't want to set
         * any values in the relevant comboboxes.
         */
        if (!accountNumber) {
            return;
        }

        /*
         * For ONE2MANY and MANY2ONE the inquiry uses the selected 'to account'(ONE2MANY)
         * or 'from account'(MANY2ONE) to determine what accounts are valid.
         * This is because the to/from accounts needs to be of the same currency.
         * This restriction does not apply for other transfer types.
         */
        if (type === 'ONE2MANY' && accountList.isToAccounts) {
            otherAccount = this.model.get('ACCOUNTFILTER');
        } else if (type === 'MANY2ONE' && !accountList.isToAccounts) {
            otherAccount = this.model.get('BENE_ACCOUNTENTITLEMENT');
        }
        accountList.setFilter(1, accountNumber, otherAccount);
        accountList.fetch({
            success: (results) => {
                if (results.models.length === 0) {
                    if (accountList.isToAccounts) {
                        this.ui.toCombo.comboBox('val', '');
                    } else {
                        this.ui.fromCombo.comboBox('val', '');
                    }
                } else if (accountList.isToAccounts) {
                    this.ui.toCombo.comboBox(
                        'data',
                        {
                            id: results.models[0].get('ACCOUNTFILTER'),
                            text: results.models[0].get('text'),
                        },
                    );
                    this.model.set('BENE_ACCOUNT_FORMATTED', `${this.model.get('BENE_NAME')} - ${results.models[0].get('ACCOUNTNUM')}`);
                    /*
                     * This ensures we do not display a balance before setting the formatted
                     * account number. If the balance is displayed before the account number,
                     * it will be erased. This affects only view and modify mode; insert
                     * and modify after page is rendered are driven by the combobox and
                     * follow a different path.
                     */
                    this.getAvailableBalance(BENEACCOUNT);
                } else {
                    this.ui.fromCombo.comboBox(
                        'data',
                        {
                            id: results.models[0].get('ACCOUNTFILTER'),
                            text: results.models[0].get('text'),
                        },
                    );
                    this.model.set('DEBIT_ACCOUNT_FORMATTED', `${this.model.get('DEBIT_ACCOUNT_TITLE')} - ${results.models[0].get('ACCOUNTNUM')}`);
                    // As above, this ensures we display the account number before its balance.
                    this.getAvailableBalance(DEBITACCOUNTNUMBER);
                    const isTOA = results.models[0].get('mapDataList')?.ACCOUNTSUBTYPE === TOASUBTYPE;
                    this.toAccounts.isTOA = isTOA;
                    /*
                     * for one 2 many transfer templates,
                     * we need to ensure that if the from account is a TOA
                     * then the to accounts and any new to accounts that get added
                     * will be the operating account
                     */
                    if (type === 'ONE2MANY' && this.state === 'modify') {
                        this.parentLayout.model.set('isTOA', isTOA);
                    }
                }
            },
        });
    },

    updateDates() {
        const self = this;
        const setDate = this.ui.dateField.val();
        const momentDate = moment(setDate, userInfo.getDateFormat());
        const currentTransferTime = this.model.get('TRANSFER_TIME');
        const globalTime = this.parentLayout.model.get('START_TIME');
        const setTime = currentTransferTime || globalTime;

        this.model.set('VALUE_DATE', setDate);
        this.model.set('TRAN_DATE', setDate);
        this.model.set('TRANSFER_TIME', setTime);

        if (momentDate.isValid()) {
            fxTransfer.doFieldValidation(this.model, 'VALUE_DATE', this.hasDefaultDate).then(() => {
                // use indicitive rate if date is future
                if (this.isCrossCurrency()) {
                    if (!self.model.get('EXCHANGE_RATE_TABLE')) {
                        self.getIndicativeRate();
                    } else {
                        self.useExchangeRateTable();
                    }
                }

                PaymentUtil.updateCutoff(
                    'RTGS',
                    self.parentLayout.model.get('functionCode'),
                    'TRANSFER',
                    '*',
                    self.state, self.model, self.$('.ui-datepicker-trigger'),
                );
            });
        } else {
            this.setValidDate();
        }
    },

    getCompanyRTGSPreferences() {
        const url = services.generateUrl('/inquiry/getData');
        const postData = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    searchType: '5',
                    inquiryId: 40011,
                },
            },
        };
        return http.post(url, postData);
    },

    // Show Add Contract ID / Exchange Rate Dialog
    openRateDialog() {
        const contractIDModal = new FormDialog({
            formModel: this.model,
            handler: util.bind(this.useContractAndRate, this),
        });

        dialog.open(contractIDModal);
    },

    /**
     *  Remove our (user entered) contract id and exchange rate
     *  Reset to our original exchange rate from the database if we have one,
     * otherwise check for indicative rate
     */
    resetExchangeRate() {
        if (this.state === 'view') {
            return;
        }

        const creditCurr = this.getCreditCurrency();
        const debitCurr = this.getDebitCurrency();

        if (this.isCrossCurrency()) {
            this.model.set(
                this.model.get('ENTERED_AMOUNT_FLAG') === 'C' ? 'CREDIT_AMOUNT' : 'DEBIT_AMOUNT',
                this.model.get('ENTERED_AMOUNT'),
            );
        } else {
            this.model.set({
                CREDIT_AMOUNT: this.model.get('ENTERED_AMOUNT'),
                DEBIT_AMOUNT: this.model.get('ENTERED_AMOUNT'),
            });
        }

        if ((creditCurr && creditCurr === debitCurr) || (!creditCurr && this.model.get('EXCHANGE_RATE_TABLE') === '1')) {
            this.noExchangeRate(null, true);
        } else if (this.model.get('EXCHANGE_RATE') && this.model.get('EXCHANGE_RATE_CONTRACTID')) {
            this.useContractAndRate(this.model.get('EXCHANGE_RATE'), this.model.get('EXCHANGE_RATE_CONTRACTID'));
        } else if ((this.model.get('EXCHANGE_RATE_TABLE')) || (this.model.get('EXCHANGE_RATE'))) {
            this.useExchangeRateTable();
        } else if (creditCurr && debitCurr && creditCurr !== debitCurr) {
            this.getIndicativeRate();
        } else {
            this.noExchangeRate(null, true);
        }
    },

    setsingleRecurCheck(patternMode) {
        if (patternMode && patternMode !== 'O') {
            this.ui.singleRecur.prop('checked', true);
        }
    },

    useContractAndRate(rate, contractId) {
        const toCurrency = this.getCreditCurrency();
        const fromCurrency = this.getDebitCurrency();
        const currencyLabel = this.model.get('CURRENCYCODE') === toCurrency ? fromCurrency : toCurrency;
        const parsedRate = parseFloat(rate.replace(',', '.'));

        this.model.set({
            EXCHANGE_RATE: rate,
            EXCHANGE_RATE_CONTRACTID: contractId,
        });

        this.ui.rateLabel.text(`${locale.get('RTGS.Exchange_Rate')} ${parsedRate.toFixed(this.exchangePrecision)}`).removeClass('hide');

        this.calculateExchangedAmount(currencyLabel);
        this.ui.addContractID.addClass('hide');
        this.ui.contractID.text(`${locale.get('RTGS.screentext.Contract ID')} ${contractId}`);
        this.ui.removeContractID.removeClass('hide');
        this.setExchangeRateVisibility();
    },

    removeContractID() {
        const self = this;
        this.model.set('EXCHANGE_RATE', '');
        this.model.set('EXCHANGE_RATE_CONTRACTID', '');

        fxTransfer.doFieldValidation(this.model, 'CREDIT_CURRENCY', this.hasDefaultDate).then(() => {
            self.resetExchangeRate();
        });
    },

    noExchangeRate(e, hideContract) {
        const tempMode = this.options.parentLayout.options.templateMode;
        // clear out exchange rate, contract id and any calculated amounts
        this.model.set('EXCHANGE_RATE_CONTRACTID', '');
        this.model.set('EXCHANGE_RATE', '');
        this.model.set('EXCHANGE_RATE_TABLE', '');

        this.ui.rateLabel.addClass('hide');
        this.ui.exchangeRateAmount.text('');
        this.ui.contractID.text('');
        if (hideContract) {
            this.ui.addContractID.addClass('hide');
            this.ui.removeContractID.addClass('hide');
        } else if (!tempMode) {
            this.ui.addContractID.removeClass('hide');
            this.ui.removeContractID.addClass('hide');
            this.setExchangeRateVisibility();
        }
        this.setCurrencyText(e);
    },

    setCurrencyText(currencyComboEl) {
        const toCurrency = this.getCreditCurrency() || false;
        const fromCurrency = this.getDebitCurrency() || false;
        const currencyCode = this.model.get('CURRENCYCODE') || fromCurrency || toCurrency || '';
        const { type } = this.options;
        /*
         * FIX: for some reason the standard this.ui.selectCurrency method of referencing
         * this does not always work. At times it will indeed show the dropdown is there
         * but using the ui hash you won't be able to remove the class
         */
        if (type === 'ONE2MANY' || type === 'MANY2ONE') { // no cross currency
            this.$('.currency-type').addClass('hide');
            this.ui.currencyText.removeClass('hide').text(currencyCode);
        } else if (toCurrency && fromCurrency && toCurrency === fromCurrency) {
            this.$('.currency-type').addClass('hide');
            this.ui.currencyText.removeClass('hide').text(toCurrency || '');
        }

        // new transfer template
        if (this.options.templateMode) {
            // if our currencies are't empty and are different
            if (!this.options.readOnly && this.isCrossCurrency()) {
                if (currencyComboEl) {
                    this.setupCurrencyDropdown(currencyComboEl);
                }
            } else {
                this.ui.currencyText.removeClass('hide').text(currencyCode);
            }
        }
    },

    useExchangeRateTable() {
        const self = this;
        const toCurrency = this.getCreditCurrency();
        const fromCurrency = this.getDebitCurrency();
        const currencyLabel = this.model.get('CURRENCYCODE') === toCurrency ? fromCurrency : toCurrency;

        /*
         * Market Convention is for direct/indirect rate types = I = divide = D = multiple
         * need to retrieve it
         */
        if (this.options.copyFromTemplate && !this.model.get('MARKETCONVENTION')) {
            fxTransfer.doFieldValidation(this.model, 'CREDIT_CURRENCY', this.hasDefaultDate).then(() => {
                self.resetExchangeRate();
            });
        }

        if (this.model.get('EXCHANGE_RATE') && !this.model.get('EXCHANGE_RATE_CONTRACTID')) {
            this.model.set('EXCHANGE_RATE_TABLE', this.model.get('EXCHANGE_RATE'));
        }

        const rate = this.model.get('EXCHANGE_RATE') || this.model.get('EXCHANGE_RATE_TABLE') || 0;

        if (!this.templateMode && !this.model.get('EXCHANGE_RATE_CONTRACTID')) {
            this.ui.addContractID.removeClass('hide');
        }

        if (!this.model.get('EXCHANGE_RATE_CONTRACTID')) {
            this.ui.removeContractID.addClass('hide');
            this.ui.contractID.text('');
        }

        this.ui.rateLabel.text(`${locale.get('RTGS.Exchange_Rate')} ${parseFloat(rate).toFixed(this.exchangePrecision)}`).removeClass('hide');
        this.calculateExchangedAmount(currencyLabel);
    },

    /**
     * Use/show indicative rate from lookup (data) otherwise use indicative rate
     * on the model, hide contract ID
     * @param {Object} [data] - indicative rate lookup from getIndicativeRate method
     */
    useIndicativeRate(data) {
        const self = this;
        const toCurrency = this.getCreditCurrency();
        const fromCurrency = this.getDebitCurrency();
        const currencyLabel = this.model.get('CURRENCYCODE') === toCurrency ? fromCurrency : toCurrency;

        // need to retrieve it
        if (this.options.copyFromTemplate && !this.model.get('MARKETCONVENTION')) {
            fxTransfer.doFieldValidation(this.model, 'CREDIT_CURRENCY', this.hasDefaultDate).then(() => {
                self.resetExchangeRate();
            });
        }

        this.ui.removeContractID.addClass('hide');
        this.ui.contractID.text('');
        this.ui.addContractID.removeClass('hide');

        const rate = (data && data.indicativeRate) || this.model.get('INDICATIVERATE');

        if (rate && rate !== '') {
            this.ui.rateLabel.text(`${locale.get('RTGS.*.INDICATIVE_RATE')} ${parseFloat(rate).toFixed(this.exchangePrecision)}`).removeClass('hide');
        }
        const calcAmt = this.calculateIndicativeAmount();
        const calcAmtView = Formatter.formatNumberByCurrency(calcAmt, currencyLabel);
        this.ui.exchangeRateAmount.text(`${calcAmtView} ${currencyLabel}`).removeClass('hide');
        this.setExchangeRateVisibility();
    },

    /**
     * calculate and format indicative amount based on credit or debit selection
     * @returns {number} - exchange rate with fixed point notation(2) or 0
     */
    calculateIndicativeAmount() {
        let calculatedAmount = 0;
        const { model } = this;
        const isCreditAmount = (model.get('ENTERED_AMOUNT_FLAG') === 'C' || model.get('DBCR_FLAG') === 'C');
        const enteredAmount = model.get('ENTERED_AMOUNT') || 0;

        if (numeral().unformat(enteredAmount) > 0) {
            calculatedAmount = model.get('INDICATIVEAMOUNT') || 0;
            calculatedAmount = parseFloat(numeral().unformat(calculatedAmount));
            if (isCreditAmount) {
                calculatedAmount = Formatter.formatNumberByCurrency(calculatedAmount, model.get('DEBIT_CURRENCY'));
                model.set('DEBIT_AMOUNT', calculatedAmount);
            } else {
                calculatedAmount = Formatter.formatNumberByCurrency(calculatedAmount, model.get('CREDIT_CURRENCY'));
                model.set('CREDIT_AMOUNT', calculatedAmount);
            }
        }
        return calculatedAmount;
    },

    /**
     * Calculate and format exchange rate amount based on credit or debit selection
     * @returns {number} - exchange rate with fixed point notation(2) or 0
     */
    calculateExchangedAmount(currencyLabel) {
        let calculatedAmount = 0;
        const { model } = this;
        const self = this;
        let exchangeCurrency = currencyLabel;
        const isCreditAmount = (model.get('ENTERED_AMOUNT_FLAG') === 'C' || model.get('DBCR_FLAG') === 'C');
        const enteredAmount = model.get('ENTERED_AMOUNT') || 0;

        if (numeral().unformat(enteredAmount) > 0) {
            exchangeCurrency = this.model.get(isCreditAmount ? 'DEBIT_CURRENCY' : 'CREDIT_CURRENCY');

            fxTransfer.doFieldValidation(model, (isCreditAmount ? 'CREDIT_AMOUNT' : 'DEBIT_AMOUNT'), this.hasDefaultDate).then(() => {
                /*
                 * If exchange rate is TBD or does not exist the CALCULATED values
                 * will not be correct and we don't want to use them.
                 */
                if ((model.get('EXCHANGERATETBD_FLAG') !== 'Y' && model.get('EXCHANGE_RATE_TABLE')) || model.get('EXCHANGE_RATE')) {
                    calculatedAmount = model.get(isCreditAmount ? 'DEBIT_AMOUNT' : 'CREDIT_AMOUNT');
                    calculatedAmount = parseFloat(numeral().unformat(calculatedAmount));
                }
                const calculatedAmountView = Formatter
                    .formatNumberByCurrency(calculatedAmount, exchangeCurrency);
                self.ui.exchangeRateAmount
                    .text(`${calculatedAmountView} ${exchangeCurrency}`).removeClass('hide');
                self.setExchangeRateVisibility();
            });
        } else {
            const calculatedAmountView = Formatter
                .formatNumberByCurrency(calculatedAmount, exchangeCurrency);
            self.ui.exchangeRateAmount
                .text(`${calculatedAmountView} ${exchangeCurrency}`).removeClass('hide');
            self.setExchangeRateVisibility();
        }
    },

    deleteModelsByCurrency(models, fieldName, currency) {
        models.filter((model) => {
            const modelCurrency = model.get(fieldName);

            return (!!modelCurrency && modelCurrency !== currency);
        }).forEach((model) => {
            model.destroy();
        });
    },

    fromChanged(e) {
        this.filterVal = e.val;
        this.ui.toCombo.comboBox('enable');

        // Needed in flex dropdown method
        this.prevFromCurrencyCode = this.model.fromAccount ? this.model.fromAccount.get('CURRENCYCODE') : false;

        // update our model
        this.model.fromAccount = this.fromAccounts.get(this.filterVal);
        this.model.set('DEBIT_CURRENCY', this.model.fromAccount.get('mapDataList').DEBIT_CURRENCY);
        this.model.set('DEBIT_COUNTRY', this.model.fromAccount.get('mapDataList').DEBIT_COUNTRY);

        // check if from account is a time open account
        const isTOA = this.model.fromAccount.get('mapDataList')?.ACCOUNTSUBTYPE === TOASUBTYPE;
        // set the toAccounts combo isTOA property so the fetch will get the correct to Account
        this.toAccounts.isTOA = isTOA;
        // for ONE2MANY transfers, set the isTOA property on the parent model
        if (this.options.type === 'ONE2MANY') {
            this.parentLayout.model.set('isTOA', isTOA);
        }

        if (!this.options.copyFromTemplate
                && !this.options.templateMode && !this.options.mode && !this.options.readOnly) {
            this.fromCurrency = this.model.fromAccount.get('CURRENCYCODE');
        } else {
            this.fromCurrency = this.model.get('DEBIT_CURRENCY');
        }

        /*
         * The amount is displayed in the credit currency or the manually selected currency.
         * In the event that the credit currency has not been set, we are setting the amount
         * masking to the debit currency temporarily so that the amount displayed on screen
         * matches the only available currency option.
         */
        if (!this.model.get('CREDIT_CURRENCY') && !this.toCurrency) {
            this.setAmountMaskingFromCurrency('ENTERED_AMOUNT', this.model.get('DEBIT_CURRENCY'));
        }

        /*
         * Remove the credit accounts if the currency does not match
         * with debit account currency
         */
        if (this.options.type === 'ONE2MANY') {
            this.deleteModelsByCurrency(this.parentLayout.collection.models, 'CREDIT_CURRENCY', this.fromCurrency);
        }

        // Whenever we change to or from accounts we might need to update currency
        if (this.options.type === 'ONE2ONE' || this.options.type === 'SINGLE') {
            this.setupExchangeRate(e);
        } else if (this.options.type === 'ONE2MANY') {
            this.parentLayout.accountFilter = e.val;
        } else {
            this.setCurrencyText();
        }

        /*
         * if helper text is available, then display, if not fetch the balance and
         * then display
         */
        this.processHelperText('ACCOUNTFILTER', this.model.fromAccount);
        // when we change the to account, we should update the calendar
        this.setValidDate();
    },

    /**
     * @description processes the account helper balances so that the balance can
     * be displayed
     * @param fieldName Debit account number or bene account number
     * @param account Debit account model or bene account model
     *
     */
    processHelperText(fieldName, account) {
        if (this.displayAvailableBalances) {
            if (
                util.findWhere(
                    this.accountHelperText[fieldName],
                    {
                        account: account.get('ACCOUNTFILTER'),
                    },
                )
            ) {
                this.trigger('lookupHelperText:update', fieldName);
            } else {
                this.getAvailableBalance(fieldName, account);
            }
        }
    },

    updateToCombo(e) {
        if (e) {
            this.ui.amtField.prop('disabled', false);

            // Needed in flex dropdown method
            this.prevToCurrencyCode = this.model.toAccount ? this.model.toAccount.get('CURRENCYCODE') : false;

            // Update our model
            this.model.toAccount = this.toAccounts.get(e.val);
            this.model.set('CREDIT_CURRENCY', this.model.toAccount.get('mapDataList').CREDIT_CURRENCY);
            this.model.set('BENE_BANK_COUNTRY', this.model.toAccount.get('mapDataList').BENE_BANK_COUNTRY);
            if (!this.options.copyFromTemplate && !this.options.templateMode
                && !this.options.mode && !this.options.readOnly) {
                this.toCurrency = this.model.toAccount.get('CURRENCYCODE');
            } else {
                this.toCurrency = this.model.get('CREDIT_CURRENCY');
            }

            // The 'ToAccount' always becomes the entered currency when it's selected
            this.model.set('ENTERED_AMOUNT_FLAG', 'C');
            this.model.set('DBCR_FLAG', 'C');

            /*
             * Update the amount format/precision based on
             * what was just selected  as the 'To Account'
             */
            this.setAmountMaskingFromCurrency('ENTERED_AMOUNT', this.model.get('CREDIT_CURRENCY'));

            /*
             * Remove the debit accounts if the currency does not match
             * with credit account currency
             */
            if (this.options.type === 'MANY2ONE') {
                this.deleteModelsByCurrency(this.parentLayout.collection.models, 'DEBIT_CURRENCY', this.toCurrency);
            }

            // Whenever we change to or from accounts we might need to update currency
            if (this.options.type === 'ONE2ONE' || this.options.type === 'SINGLE') {
                this.setupExchangeRate(e);
            } else if (this.options.type === 'MANY2ONE') {
                this.parentLayout.accountFilter = e.val;
            } else {
                this.setCurrencyText();
            }

            // if helper text is available, the display
            this.processHelperText('BENE_ACCOUNTENTITLEMENT', this.model.toAccount);
        }
    },

    /**
     * Update CURRENCYCODE to whatever is selected in the currencyType dropdown
     * so it is diplayed correctly in the footer.
     */
    updateEnteredCurrency(e) {
        const creditCurrency = this.getCreditCurrency();

        if (e && e[0]) {
            this.model.set('CURRENCYCODE', e[0]);

            // Update the amount format/precision based on the selected currency
            this.setAmountMaskingFromCurrency('ENTERED_AMOUNT', this.model.get('CURRENCYCODE'));

            if (this.model.get('CURRENCYCODE') === creditCurrency) {
                this.model.set('ENTERED_AMOUNT_FLAG', 'C');
                this.model.set('DBCR_FLAG', 'C');
            } else {
                this.model.set('ENTERED_AMOUNT_FLAG', 'D');
                this.model.set('DBCR_FLAG', 'D');
            }
            if (!this.templateMode) {
                fxTransfer.doFieldValidation(this.model, 'CREDIT_CURRENCY', this.hasDefaultDate);
            }
        }
    },

    /**
     * @method setExchangeRateVisibility
     * Based on the configuration, add a class to the inputGroup which will keep
     * the exchange rate hidden
     */
    setExchangeRateVisibility() {
        const hideIndicativeRate = serverConfigParams.get('HideTransferIndicativeAmount') === 'true';
        const showRateFields = this.state !== 'view'
            && this.parentLayout.model.get('functionCode') !== 'TMPL';
        const hasExchangeRate = showRateFields && (!!this.model.get('EXCHANGE_RATE') || !!this.model.get('EXCHANGE_RATE_TABLE'));
        const hasEnteredAmount = !!this.model.get('ENTERED_AMOUNT') && (this.model.get('ENTERED_AMOUNT') !== '0.00' && this.model.get('ENTERED_AMOUNT') !== '0');
        const hasIndicativeRate = showRateFields && !hasExchangeRate && !hideIndicativeRate
            && !!this.model.get('INDICATIVERATE');

        if (hideIndicativeRate && !hasExchangeRate) {
            this.ui.$indicateAmount.addClass('hide');
            this.ui.$indicateRate.addClass('hide');
        }

        /*
         * We want to show the exchange amount for a cross currency transaction even when the
         * rate lookup fails since this is the only on screen indication of multiple currencies
         */
        this.ui.exchangeRateAmount.addClass('hide');
        if (showRateFields && this.isCrossCurrency()) {
            this.ui.exchangeRateAmount.removeClass('hide');
        }

        /*
         * We want to show the exchange rate for a cross currency transaction only when the
         * rate lookup was successful to prevent from displaying non numeric or error content
         */
        this.ui.rateLabel.addClass('hide');
        if ((hasEnteredAmount || !!this.model.get('EXCHANGE_RATE_CONTRACTID')) && (hasExchangeRate || hasIndicativeRate)) {
            this.ui.rateLabel.removeClass('hide');
        }
    },

    /**
     * This should only be run to setup the exchange rate, not reset it
     * Run on 1st enter, and when to/from account update
     */
    setupExchangeRate(e, state) {
        const self = this;

        // Do we have both accounts, and are the currencies different
        if ((this.model.get('DEBIT_CURRENCY') && this.model.get('CREDIT_CURRENCY')) || (this.model.toAccount && this.model.fromAccount) || this.options.copyFromTemplate) {
            /*
             * Set values differently depending on if this is a new transfer, or one
             * being created from template or Modify/View
             */
            if (!this.options.copyFromTemplate && !this.options.templateMode
                && !this.options.mode && !this.options.readOnly) {
                self.fromCurrency = this.model.fromAccount.get('CURRENCYCODE');
                self.toCurrency = this.model.toAccount.get('CURRENCYCODE');
                this.model.set('CURRENCYCODE', self.toCurrency);
                this.model.set('ENTERED_AMOUNT_FLAG', 'C');
            } else {
                self.fromCurrency = this.model.get('DEBIT_CURRENCY');
                self.toCurrency = this.model.get('CREDIT_CURRENCY');
                if (this.model.get('DBCR_FLAG')) {
                    this.model.set('ENTERED_AMOUNT_FLAG', this.model.get('DBCR_FLAG'));
                }

                // If we have no entered_amount_flag default it to C
                if (!this.model.get('DBCR_FLAG') && !this.model.get('ENTERED_AMOUNT_FLAG')) {
                    this.model.set('DBCR_FLAG', 'C');
                    this.model.set('ENTERED_AMOUNT_FLAG', 'C');
                }

                if (this.model.get('ENTERED_AMOUNT_FLAG') === 'C') {
                    this.model.set('CURRENCYCODE', self.toCurrency);
                } else {
                    this.model.set('CURRENCYCODE', self.fromCurrency);
                }
            }

            if (this.templateMode) {
                this.noExchangeRate(e);
            } else {
                fxTransfer.doFieldValidation(this.model, 'CREDIT_CURRENCY', this.hasDefaultDate, state).then((data) => {
                    // Only setup a rate if the currencies are different
                    if (self.fromCurrency !== self.toCurrency) {
                        // Get override flag to show/hide add contract
                        if (!self.templateMode && !self.model.get('EXCHANGE_RATE_CONTRACTID')) {
                            self.allowContractOverridePromise.then((contractOverride) => {
                                self.ui.addContractID.toggleClass('hide', contractOverride);
                            });
                        }

                        // Setup flex
                        self.setupCurrencyDropdown(e);

                        if (self.options.mode === 'modify') {
                            /*
                             * Do we have a default exchange rate ?  otherwise get
                             * indicative rate
                             */
                            if (self.model.get('INDICATIVERATE')) {
                                /*
                                 * if we have indicative rate.. this would mean we need
                                 * to get it on render
                                 */
                                self.getIndicativeRate();
                            } else if ((self.model.get('EXCHANGE_RATE') || self.model.get('EXCHANGE_RATE_TABLE')) && !self.model.get('EXCHANGE_RATE_CONTRACTID')) {
                                // if we dont, do we have exchange rate.. if so, use it
                                self.useExchangeRateTable();
                            } else {
                                // all else fails.. get indicative rate from server
                                self.getIndicativeRate();
                            }
                        } else {
                            /*
                             * This returns our default exchange_rate_table from db if it
                             * exists we'll need it below. Only setup a rate if the
                             * currencies are different
                             */
                            const exchangeRateTable = data.item[2];

                            /*
                             * Do we have a default exchange rate ?  otherwise get
                             * indicative rate
                             */
                            if (exchangeRateTable && exchangeRateTable.value && !self.model.get('EXCHANGE_RATE_CONTRACTID')) {
                                self.useExchangeRateTable();
                            } else {
                                self.getIndicativeRate();
                            }
                        }
                    } else {
                        // Currencies are the same, hide all exchange rate stuff
                        self.noExchangeRate(null, true);
                    }
                });
            }
            this.setExchangeRateVisibility();
        }
    },

    setupCurrencyDropdown(e) {
        const evt = e ? e.currentTarget.name : false;
        const isFromDDChanged = evt === 'ACCOUNTFILTER';
        const isToDDChanged = evt === 'BENE_ACCOUNTENTITLEMENT';
        const creditCurrency = this.getCreditCurrency();
        const debitCurrency = this.getDebitCurrency();
        let toSelected = false;
        let fromSelected = false;

        /*
         * If there is an event 'evt' it means we've hit this from a to/from account change,
         * and we always use the TO account. Otherwise, we're hitting this from a load where
         * we have a template (and no evt) and we'll have to figure out which currency
         * is selected
         */
        if (!evt && this.currencyType && this.currencyType === this.fromCurrency) {
            fromSelected = true;
        } else {
            toSelected = true;
        }

        const options = {
            disableMultiButton: true,

            data: [{
                id: this.fromCurrency,
                name: this.fromCurrency,
                selected: fromSelected,
            }, {
                id: this.toCurrency,
                name: this.toCurrency,
                selected: toSelected,
            }],
        };

        // No dropdown when its view/read-only
        const isAmountLocked = this.model.get('LOCKED_FIELDS')
                && this.model.get('LOCKED_FIELDS').indexOf('ENTERED_AMOUNT') !== -1;
        const isFromTemplate = this.options.copyFromTemplate
                || this.model.get('ENTRYMETHOD') === '1';

        if (!(this.options.readOnly || (isAmountLocked && isFromTemplate))) {
            // Setup dropdown for the 1st time
            if (!this.isFlexDDSetup) {
                this.ui.selectCurrency.flexDropdown(options);

                // listen to updates on the flex drop down
                if (!util.isNullOrUndefined(this.$(this.ui.selectCurrency).data('flexDropdown'))) {
                    this.listenTo(this.$(this.ui.selectCurrency).data('flexDropdown'), 'selectionChanged:name', this.currencyDDUpdated);
                }

                this.isFlexDDSetup = true;
            } else if ((this.prevFromCurrencyCode !== debitCurrency && isFromDDChanged)
                || (this.prevToCurrencyCode !== creditCurrency && isToDDChanged)) {
                // Dropdown is already setup, but did currencies change?  If so, reset list

                this.$(this.ui.selectCurrency).data('flexDropdown').setData(options.data);
            }

            // Show dropdown, hide static currency label
            this.ui.currencyText.addClass('hide');
            /*
             * FIX: for some reason the standard this.ui.selectCurrency
             * method of referencing this does not always work
             * At times it will indeed show the dropdown is there but using
             * the ui hash you won't be able to remove the class
             */
            this.$('.currency-type').removeClass('hide');
        }
    },

    // Currency type was updated in DD
    currencyDDUpdated(e) {
        this.updateEnteredCurrency(e);
        this.resetExchangeRate(e);
    },

    /**
     * If we have different to/from currencies and no set exchange rate (user
     * entered or database table)
     * Then get the indictive rate
     */
    getIndicativeRate() {
        // don't retrieve indicative rates when creating a template
        if (this.templatemode || this.options.templateMode) {
            return;
        }

        const self = this;
        const amt = self.model.get('ENTERED_AMOUNT');
        self.model.set('TYPE', 'TRANSFER');
        self.model.set('FETCHING_INDICATIVE_RATE', true);

        RtgsHelper.getIndicativeRate(new Model({
            CREDIT_CURRENCY: this.getCreditCurrency(),
            DEBIT_CURRENCY: this.getDebitCurrency(),

            // we always send credit/debit amount (same value).
            CREDIT_AMOUNT: amt || 1,
            DEBIT_AMOUNT: amt || 1,
            VALUE_DATE: this.model.get('VALUE_DATE'),
            STATUS: self.model.get('STATUS'),
            PAYMENTTYPE: self.model.get('TYPE') || 'TRANSFER',
            DBCR_FLAG: self.model.get('DBCR_FLAG') || self.model.get('ENTERED_AMOUNT_FLAG'),
        })).then((data) => {
            if (data) {
                self.model.set({
                    INDICATIVERATE: data.indicativeRate || '',
                    INDICATIVEAMOUNT: data.indicativeAmount || '',
                    INDICATIVE_AMOUNT: Formatter.formatCurrency(data.indicativeAmount) || '',
                });

                // Only show indicative rate if there is no exchange rate set
                if (!self.model.get('EXCHANGE_RATE')) {
                    self.useIndicativeRate(data);
                }
            } else {
                // Hide the rate if we don't have indicative or exchange rate
                self.noExchangeRate(null, false);
            }

            // fetching is complete even if no rate returned from service
            self.model.set('FETCHING_INDICATIVE_RATE', false);
        });
    },

    /**
     * used for enabling opposite account combo and amount field when prereq
     * field is set
     */
    enableFields(filter, currency) {
        this.filterVal = filter;

        if (this.options.type === 'ONE2MANY') {
            this.ui.toCombo.comboBox('enable');
        } else {
            this.ui.fromCombo.comboBox('enable');
        }

        this.ui.amtField.prop('disabled', false);
        this.ui.currencyText.text(this.model.getCurrency() || currency);
    },

    toggleOpt(flag) {
        const self = this;

        this.ui.optRow.toggle(flag);
        this.ui.optRowStatic.toggle(!flag);

        // show only static fields with values
        this.ui.optRowStatic.find('p').each((idx, el) => {
            const $dd = self.$(el);
            $dd.parent().toggle($dd.text().length > 0);
        });
    },

    removeTransfer() {
        this.model.destroy();
    },

    onClose() {
        this.ui.selectCurrency.flexDropdown('destroy');
        this.ui.fromCombo.comboBox('destroy');
        this.ui.toCombo.comboBox('destroy');
    },

    retrieveEarliestValueDate() {
        let fromAccountSet = false;
        let toAccountSet = false;

        const data = {
            type: 'TRANSFER',
            subType: '*',
        };

        if (this.model.fromAccount) {
            data.debitBankCode = this.model.fromAccount.get('BANKCODE');
            data.debitCurrency = this.model.fromAccount.get('CURRENCYCODE');
            data.debitCountry = this.model.fromAccount.get('COUNTRYCODE');
            fromAccountSet = true;
        }
        if (this.model.toAccount) {
            data.beneBankId = this.model.toAccount.get('BANKCODE');
            data.creditCurrency = this.model.toAccount.get('CURRENCYCODE');
            data.beneCountry = this.model.toAccount.get('COUNTRYCODE');
            toAccountSet = true;
        }
        if (!fromAccountSet && this.isFromAccountInfoDefined()) {
            data.debitBankCode = this.model.get('DEBIT_BANK_CODE');
            data.debitCurrency = this.model.get('DEBIT_CURRENCY');
            data.debitCountry = this.model.get('DEBIT_COUNTRY');
            fromAccountSet = true;
        }
        if (!toAccountSet && this.isToAccountInfoDefined()) {
            data.beneBankId = this.model.get('BENE_BANK_CODE');
            data.creditCurrency = this.model.get('CMB_CREDIT_CURRENCY');
            data.beneCountry = this.model.get('BENE_BANK_COUNTRY');
            toAccountSet = true;
        }
        if (fromAccountSet && toAccountSet) {
            http.post(
                services.generateUrl(constants.URL_GET_NEXT_EARLIEST_AVAILABLE_VALUE_DATE),
                data,
                util.bind(this.handleEarliestValueDate, this),
                (err) => {
                    log.error(err);
                },
            );
        }
    },

    isFromAccountInfoDefined() {
        return (this.model.get('DEBIT_BANK_CODE') && this.model.get('DEBIT_CURRENCY') && this.model.get('DEBIT_COUNTRY'));
    },

    isToAccountInfoDefined() {
        return (this.model.get('BENE_BANK_CODE') && this.model.get('CMB_CREDIT_CURRENCY') && this.model.get('BENE_BANK_COUNTRY'));
    },

    handleEarliestValueDate(response) {
        if (response.valueDate) {
            const $earliestValDiv = this.$('#earliestValueDateDiv');
            $earliestValDiv.empty();
            $earliestValDiv.append(`<h3>${locale.get('RTGS.Transfer.Schedulet.NextEarliestValueDate')} ${response.valueDate}</h3>`);
        }
    },

    /**
     * Provide a working isValidDate method for ONE2MANY and MANY2ONE transfers
     */
    validateAndUpdateDates() {
        const self = this;
        let i;
        let tempModel;
        /*
         * if this is one2many or many2one, and collection size is greater than 1, then
         * we already got the date and we should not retrieve again.
         * or we dont have a model, cant continue
         */
        if (!this.model || ((this.options.type === 'ONE2MANY' || this.options.type === 'MANY2ONE')
                && this.model.collection && this.model.collection.length > 1)) {
            return;
        }

        if (this.options.type === 'ONE2MANY' && this.parentLayout.singleView.fromAccounts && !this.model.fromAccount) {
            for (i = 0; i < this.parentLayout.singleView.fromAccounts.models.length; i += 1) {
                tempModel = this.parentLayout.singleView.fromAccounts.models[i];
                if (this.parentLayout.singleView.filterVal && tempModel.get('id') === this.parentLayout.singleView.filterVal) {
                    this.model.fromAccount = tempModel;
                    break;
                }
            }
        }
        if (this.options.type === 'MANY2ONE' && this.parentLayout.singleView.toAccounts && !this.model.toAccount) {
            for (i = 0; i < this.parentLayout.singleView.toAccounts.models.length; i += 1) {
                tempModel = this.parentLayout.singleView.toAccounts.models[i];
                if (this.filterVal && tempModel.get('id') === this.filterVal) {
                    this.model.toAccount = tempModel;
                    break;
                }
            }
        }

        this.model.set('VALUE_DATE', this.parentLayout.singleView.model.get('VALUE_DATE'));
        this.model.set('TRAN_DATE', this.parentLayout.singleView.model.get('TRAN_DATE'));
        fxTransfer.doFieldValidation(self.model, 'CREDIT_CURRENCY', this.hasDefaultDate).then(() => {
            self.parentLayout.singleView.model.set('VALUE_DATE', self.model.get('VALUE_DATE'));
            self.parentLayout.singleView.model.set('TRAN_DATE', self.model.get('TRAN_DATE'));
        });
    },

    setValidDate() {
        // hideDate flag is used for templates
        if (this.hideDate) {
            return;
        }

        const self = this;
        const { model } = this;


        const postData = {
            creditBankCountry: '',
            creditCurrency: '',
            debitBank: '',
            debitBankCountry: '',
            debitCurrency: '',
            paymentType: 'TRANSFER',
            typeCode: 'TRANSFER',
            subType: util.isUndefined(self.type) ? '' : self.type,
        };

        if (model) {
            if (model.toAccount) {
                if (!model.toAccount.get('mapDataList')) {
                    model.toAccount = self.toAccounts.get(self.ui.toCombo.val());
                }

                util.extend(
                    postData,
                    {
                        creditBankCountry: model.toAccount.get('mapDataList').BENE_BANK_COUNTRY,
                        creditCurrency: model.toAccount.get('mapDataList').CREDIT_CURRENCY,
                        debitBank: model.toAccount.get('mapDataList').BENE_BANK_CODE,
                    },
                );
            }
            if (model.fromAccount) {
                util.extend(
                    postData,
                    {
                        debitBank: model.fromAccount.get('mapDataList').DEBIT_BANK_CODE,
                        debitBankCountry: model.fromAccount.get('mapDataList').DEBIT_COUNTRY,
                        debitCurrency: model.fromAccount.get('mapDataList').DEBIT_CURRENCY,
                    },
                );
            }

            // Default is to require debit and credit account for date list service call.
            let updateDates = model.toAccount && model.fromAccount;

            if (self.options.type === 'ONE2MANY') {
                // One to Many transfers only require the debit account information
                updateDates = model.fromAccount;
            } else if (self.options.type === 'MANY2ONE') {
                // Many to One transfers only require the credit account information
                updateDates = model.toAccount;
            } else if (self.options.type === 'ONE2ONE' && !updateDates) {
                /*
                 * For One to One (Multi Entry) the date service can be called if the default
                 * transaction date is set instead. This typically occurs when clicking the "add"
                 * button. For payment from template (or when the default date is not set) we
                 * don't want to prevent a valid service call so this check only occurs if the
                 * service call would not have been executed otherwise.
                 */
                updateDates = model.get('TRAN_DATE');
            }

            if (updateDates) {
                http.post(
                    dateService,
                    postData,
                    util.bind(this.handleBusinessDaysResponse, this),
                );
            }
        }
    },

    handleBusinessDaysResponse(result) {
        const date = moment(result.earliestDay);
        const { maxAdvancedDays } = result;
        const { processingDates } = this;
        const earliestDate = moment(result.earliestDay).utc().format(userInfo.getDateFormat());
        const tranDate = moment(result.tranDate).utc().format(userInfo.getDateFormat());
        const { model } = this;
        const mode = this.mode || this.options.mode;
        let defaultDate = this.parentLayout.model.get('transDate');
        const existingDate = this.model.get('VALUE_DATE');
        let currentSelectedDate;
        let isValidDate;

        processingDates.daysForward.shift();
        processingDates.daysForward.push(result.maxForwardDays);

        processingDates.processingDays.shift();
        processingDates.processingDays.push(result.businessDays);

        processingDates.cutOffTimes.shift();
        processingDates.cutOffTimes.push(result.cutoff);

        processingDates.holidayDates.shift();
        processingDates.holidayDates.push(result.holidays);

        // show cutoff if it exists and is configured to do so.
        if (result.cutoffDateTimeTz && !model.get('CUTOFF_INFO')) {
            PaymentUtil.showCutoff(result.cutoffDateTimeTz, this.$('.ui-datepicker-trigger'), 'TRANSFER');
        }

        processingDates.blockedDates.splice(0, processingDates.blockedDates.length);
        if (result.holidays.length > 0) {
            processingDates.blockedDates.push(...result.holidays);
        }

        /*
         * We dont want to set the value date or tran date if already set.. ie..
         * modify mode
         */
        if (!mode || mode.toUpperCase() !== 'MODIFY') {
            this.ui.dateField.val(defaultDate || earliestDate);
            model.set({
                VALUE_DATE: defaultDate || earliestDate,
                TRAN_DATE: defaultDate || tranDate,
            });
        }

        // check if individual transfer date is set
        const momentExistingDate = moment(existingDate, userInfo.getDateFormat());
        if (existingDate && momentExistingDate.isValid() && this.hasDefaultDate) {
            defaultDate = existingDate;
        }

        /*
         * If we're using ONE2ONE we may have multiple datepickers on the page and
         * to scope it properly we need to use this.$() however in other scenarios
         * we'll have the wrong scope if we don't use $() - without the 'this.$' prepended
         */
        const dateRangePicker = (this.options.type === 'ONE2ONE') ? this.$('.input-date').data('daterangepicker') : $('.input-date').data('daterangepicker');

        dateRangePicker.updateCalendars({
            blockedDates: this.processingDates.blockedDates,
            processingDays: this.processingDates.processingDays,
            cutOffTimes: this.processingDates.cutOffTimes,
            showCalendarIcon: true,
            showDropdowns: true,
            daysForward: this.processingDates.daysForward[0],
            minDate: date,
        });

        /*
         * HACK: NH-63063 - directly update our datepicker inputfield
         * after we get holidays (ui hash isn't avaliable)
         */
        if (!this.hasDefaultDate) {
            const $dateInputField = $('[name="VALUE_DATE"]');

            if (dateUtil.isDayOff(
                this.processingDates.processingDays[0],
                this.processingDates.blockedDates,
                moment($dateInputField.val()),
            )) {
                $dateInputField.val(moment(earliestDate).format(userInfo.getDateFormat()));
            }
        }

        currentSelectedDate = model.get('VALUE_DATE');

        if (this.isCrossCurrency()) {
            if (!this.model.get('EXCHANGE_RATE_TABLE')) {
                this.getIndicativeRate();
            } else {
                this.useExchangeRateTable();
            }
        }

        // set the correct dates if payment is from a template and cross currency
        if (this.options.copyFromTemplate && this.isCrossCurrency()) {
            fxTransfer.doFieldValidation(this.model, 'VALUE_DATE', this.hasDefaultDate);
        }

        // Establish exchange rates if the transfer is create from a template
        if (this.options.copyFromTemplate) {
            this.setupExchangeRate(undefined, 'onRender');
        }

        if (currentSelectedDate) {
            currentSelectedDate = moment(currentSelectedDate, userInfo.getDateFormat());
            isValidDate = !dateRangePicker.isInvalidDay(currentSelectedDate);
            dateRangePicker.isInvalidDay(currentSelectedDate);

            if (this.hasDefaultDate) {
                this.checkValidDate(model, currentSelectedDate);
            }

            if (isValidDate) {
                return;
            }
        }

        if (model.get('functionCode') === 'INTL') {
            model.set('maxAdvancedDays', maxAdvancedDays);
        }
    },

    setAmountMaskingFromCurrency(fieldname, currencyCode) {
        this.model.set(
            fieldname,
            Formatter.formatNumberByCurrency(this.model.get(fieldname), currencyCode),
        );

        /*
         * In a many-to-one or one-to-many scenario, the first instance of amtField will
         * reference the entered amount field from the layout view which is not an input
         * field. In the event that the amtField reference does not have an input we will
         * reset the reference to the instance of entered amount that does.
         */
        if (!this.ui.amtField || !this.ui.amtField[0]) {
            this.ui.amtField = $('[name="ENTERED_AMOUNT"]');
        }

        if (this.ui.amtField) {
            const precision = Decimals.getNumberOfDecimalPlaces(currencyCode);
            let maxLength = this.ui.amtField.attr('data-maxlength');
            if (!maxLength && this.parentLayout && this.parentLayout.fieldData
                && this.parentLayout.fieldData.DEBIT_AMOUNT) {
                maxLength = this.parentLayout.fieldData.DEBIT_AMOUNT.maxLen;
            }
            this.ui.amtField.inputmask(
                'number',
                {
                    integerDigits: maxLength - precision,
                    digits: precision,
                },
            );
        }
    },

    /**
     * @method checkValidDate
     * @description checks for invalid date and sets day to next availabile business date
     * @param {object} model
     * @param {string} val - date to check
     */
    checkValidDate(model, val) {
        const dateRangePicker = (this.options.type === 'ONE2ONE') ? this.$('.input-date') : $('.input-date');
        const datePickerData = dateRangePicker.data('daterangepicker');
        const pastMaxDate = moment(val, userInfo.getDateFormat()) > datePickerData.maxDate;
        const isDayOff = targetDate => dateUtil.isDayOff(
            this.processingDates.processingDays[0],
            this.processingDates.blockedDates,
            moment(targetDate),
        );

        let currentSelectedDate = val;

        if (currentSelectedDate) {
            currentSelectedDate = moment(val, userInfo.getDateFormat());
            const isInvalidDate = datePickerData.isInvalidDay(currentSelectedDate);

            if (pastMaxDate || isDayOff(val) || isInvalidDate) {
                const nextDay = dateUtil.getFirstBusinessDay(
                    this.processingDates.processingDays[0],
                    this.processingDates.holidayDates,
                    val,
                    false,
                );

                datePickerData.setStartDate(nextDay);
                dateRangePicker.val(nextDay.format(userInfo.getDateFormat()));
            }

            /*
             * When the transaction date is changed at the layout level and applied to the
             * individual transfer transactions, the above validation checks if the new date
             * is valid. When that date is not valid, the above validation changes the date
             * to be the next valid business day. In that case, the currentSelectedDate will
             * become out of sync with the actual model value. For that reason I am getting
             * the date value directly from the model to determine if the warning message
             * should be shown.
             */
            const currentModelDate = this.model.get('VALUE_DATE')
                || this.parentLayout.model.get('transDate')
                || currentSelectedDate;

            if (isDayOff(currentModelDate)) {
                this.ui.transDateMessage.show();
            } else {
                this.ui.transDateMessage.hide();
            }
        }
    },

    /**
     * methods for account balances
     */

    /**
     * @description retrieves the account balances for all the accounts
     * @param accounts
     * @param fieldName
     */
    fetchBalancesForTheseAccounts(accounts, fieldName) {
        // post to the service and return array of balances
        let hideAcctBalanceStatuses;

        let status;
        const options = requestOptions[fieldName];
        let dispAcctBalance = this.displayAvailableBalances;
        const serviceUrl = services.currencyBalances;

        // Do not show the balances for these statuses
        if (dispAcctBalance) {
            hideAcctBalanceStatuses = serverConfigParams.get('HideAccountBalanceStatuses');
            status = this.model.get('STATUS');
            if (!util.isEmpty(status) && !util.isEmpty(hideAcctBalanceStatuses)
                && hideAcctBalanceStatuses.indexOf(status) !== -1) {
                dispAcctBalance = false;
            }
        }

        /*
         * if we are not configured to show account balances then just return empty
         * entries for each combo entry so that nothing is shown.
         */
        if (!dispAcctBalance) {
            const emptyHelpText = accounts.map(() => '');

            return Promise.resolve(emptyHelpText);
        }

        /**
         * Each item in the request must contain:
         * productCode, functionCode, typeCode, bankCode, accountNumber, currency
         *
         */
        const balanceRequest = accounts.map(item => ({
            productCode: 'RTGS',
            functionCode: 'INST',
            typeCode: 'TRANSFER',
            bankCode: item.mapDataList[options.bankCodeKey],
            accountNumber: item.mapDataList[options.accountNumberKey],
            currency: item.mapDataList[options.currencyKey],
        }));
        const postData = {
            requests: balanceRequest,
        };

        return new Promise((resolve, reject) => {
            http.post(serviceUrl, postData, (data) => {
                if (data && data.responses) {
                    resolve(data.responses);
                } else {
                    resolve(accounts);
                }
            }, () => {
                reject(locale.get('common.combo.data.error', fieldName));
            });
        });
    },

    /**
     * @description retrieves the balance for the current account (in modify and view)
     * @param {string}fieldName
     * @param {model} accountModel
     */
    fetchBalanceForAccount(fieldName, accountModel) {
        // post to the service and return array of balances
        let hideAcctBalanceStatuses;

        let status;
        const options = requestOptions[fieldName];
        let dispAcctBalance = this.displayAvailableBalances;
        const serviceUrl = services.balanceAndTransaction;

        // Do not show the balances for these statuses
        if (dispAcctBalance) {
            hideAcctBalanceStatuses = serverConfigParams.get('HideAccountBalanceStatuses');
            status = this.model.get('STATUS');
            if (!util.isEmpty(status)
                && !util.isEmpty(hideAcctBalanceStatuses)
                && hideAcctBalanceStatuses.indexOf(status) !== -1) {
                dispAcctBalance = false;
            }
        }

        /*
         * if we are not configured to show account balances then just return empty
         * entry so that nothing is shown.
         */
        if (!dispAcctBalance) {
            return Promise.resolve('');
        }

        /**
         * Request must contain:
         * productCode, functionCode, typeCode, bankCode, accountNumber, currency
         *
         */
        const balanceRequest = {
            productCode: 'RTGS',
            functionCode: 'INST',
            typeCode: 'TRANSFER',
            bankCode: accountModel ? accountModel.get('BANKCODE') : this.model.get(options.bankCodeKey),
            accountNumber: accountModel
                ? accountModel.get('mapDataList')[options.accountNumberKey]
                : this.model.get(options.accountNumberKey),
            currency: this.model.get(options.currencyKey),
        };

        return new Promise((resolve, reject) => {
            http.post(serviceUrl, balanceRequest, (data) => {
                if (data) {
                    resolve(data);
                } else {
                    resolve('');
                }
            }, () => {
                reject(locale.get('common.combo.data.error', fieldName));
            });
        });
    },

    /**
     * @description this method sets up the helper text for either all the items
     * in the account combo or for the current account (in view & modify)
     * @param balances
     * @param accounts
     */
    setupHelperText(balances, accounts) {
        let helperTextTemp;

        if (Array.isArray(balances)) {
            helperTextTemp = balances.map((
                balance,
                index,
            ) => ({
                balance: balance.balance,
                currency: accounts[index].CURRENCYCODE,
                hasBalance: balance.status === 'success',
                account: accounts[index].ACCOUNTNUM,
            }));
        } else {
            helperTextTemp = {
                balance: balances.balance,
                hasBalance: balances.status === 'success',
                currency: accounts.currency,
                account: accounts.accountNum,
            };
        }

        return helperTextTemp;
    },

    /**
     * @description override of lookupHelperText method
     *              creates a helperTextModel from the accountHelperText set up
     * after the available balances were fetched.
     * @param id
     * @returns a helperTextModel which contains the values to display for the
     * helper text
     */
    getHelperTextTemplateInput(id) {
        let helperText = this.accountHelperText[id];
        const helperTextModel = {};
        const accountModel = (id === DEBITACCOUNTNUMBER)
            ? this.model.fromAccount : this.model.toAccount;
        let accountNum = accountModel.get('ACCOUNTFILTER');
        if (!accountNum) {
            accountNum = this.model.get(id);
        }

        if (!util.isEmpty(helperText)) {
            if (Array.isArray(helperText)) {
                helperText = util.findWhere(
                    helperText,
                    {
                        account: accountNum,
                    },
                );
            }
            if (helperText) {
                util.each(helperText, (value, prop) => {
                    helperTextModel[prop] = value;
                });
            }
        }
        return helperTextModel;
    },

    /**
     * @description merges the account balances into the label property for each account
     * @param accounts
     * @param balances
     */
    mergeBalancesOnAccounts(accounts, balances) {
        return accounts.map((itemParam, index) => {
            const item = itemParam;
            let label = item.text;

            if (balances && balances[index]) {
                if (balances[index].status === 'success') {
                    label += `: ${balances[index].balance} ${item.CURRENCYCODE} ${locale.get('common.available')}`;
                }
            }
            item.text = label;
            return item;
        });
    },

    /**
     * @description processes the account balances and stores in the array eliminating
     * the duplicates
     * @param balances current balances
     * @param result  new balances
     * @param fieldName Debit account number or bene account number
     */
    processBalances(balances, result, fieldName) {
        const tempHelperText = this.setupHelperText(balances, result);
        if (!util.isEmpty(tempHelperText)) {
            if (util.isNullOrUndefined(this.accountHelperText[fieldName])) {
                this.accountHelperText[fieldName] = [];
            }

            if (Array.isArray(tempHelperText)) {
                this.accountHelperText[fieldName] = util.union(
                    this.accountHelperText[fieldName],
                    tempHelperText,
                );
            } else {
                this.accountHelperText[fieldName].push(tempHelperText);
            }
            this.accountHelperText[fieldName] = util.uniq(
                this.accountHelperText[fieldName],
                false,
                p => p.account,
            );
        }
    },

    /**
     * @description gets the available balance for one account
     * @param accountField
     * @param accountModel
     */
    getAvailableBalance(accountField, accountModel) {
        // get the available balance for either the current fromAccount or the current toAccount
        if (!this.templateMode) {
            this.fetchBalanceForAccount(accountField, accountModel)
                .then((accountBalance) => {
                    // setup helper text

                    this.processBalances(
                        accountBalance,
                        {
                            currency:
                                this.model.get(accountField === 'ACCOUNTFILTER' ? 'DEBIT_CURRENCY' : 'CREDIT_CURRENCY'),
                            accountNum: this.model.get(accountField),
                        },
                        accountField,
                    );

                    // if this is a valid situation to show an account balance
                    if (showAccountBalanceUtil.shouldShowAccountBalance(
                        this.displayAvailableBalances,
                        this.isTemplate,
                        this.state === 'view',
                        this.model.get('STATUS'),
                    )) {
                        // set fromAccount or toAccount which is used for the helper text
                        if (accountField === DEBITACCOUNTNUMBER) {
                            if (util.isNullOrUndefined(this.model.fromAccount)) {
                                [this.model.fromAccount] = this.fromAccounts.models;
                            }
                        } else if (util.isNullOrUndefined(this.model.toAccount)) {
                            [this.model.toAccount] = this.toAccounts.models;
                        }
                        this.trigger('lookupHelperText:update', accountField);
                    }
                    return accountBalance;
                }).catch((err) => {
                    log.error(err);
                });
        }
    },

    /**
     * end methods for account balances
     */

    displayCutoff() {
        const hidecutoff = serverConfigParams.get('hideCutoff');
        // if cutoff info does not exist, dont show
        if (!this.model.get('CUTOFF_INFO')) {
            return false;
        }
        /*
         * if status is entered, incomplete approval, needs rate, or 2nd approval needed,
         * reversal awaiting approval, partial reversal awaiting approval, reversal
         * (incomplete
         * approval), partial reversal (incomplete approval)
         * or hidecutoff is defined and includes the type, do NOT show cutoff verbiage
         */
        return !((hidecutoff && hidecutoff.indexOf(this.model.get('TYPE')) >= 0)
            || (['EN', 'IA', 'RT', 'HV', 'RA', 'RI', 'IV', 'PI'].indexOf(this.model.get('STATUS')) < 0));
    },

    templateHelpers() {
        const self = this;
        let nextEarliestValueDate = this.model.get('NEXTVALUEDATE');
        const isCreditAmount = this.model.get('ENTERED_AMOUNT_FLAG') === 'C';
        const isCrossCurrency = this.isCrossCurrency();

        if (nextEarliestValueDate) {
            nextEarliestValueDate = nextEarliestValueDate.split(' ');
            [nextEarliestValueDate] = nextEarliestValueDate;
        }

        if (!this.hideTime && this.parentLayout.formState === 'view'
                && (util.isNull(this.model.attributes.TRANSFER_TIME)
                 || util.isEmpty(this.model.attributes.TRANSFER_TIME))) {
            this.hideTime = true;
        }

        return {
            cid: this.model.cid,
            hasExchangeRate: isCrossCurrency && !this.isTemplate,
            debitAmountView: this.model.get('DEBIT_AMOUNT'),
            debitCurrencyView: this.model.get('DEBIT_CURRENCY'),
            debitAmountFormat: Decimals.getCurrencyFormat(this.model.get('DEBIT_CURRENCY')),
            creditAmountView: this.model.get('CREDIT_AMOUNT'),
            creditCurrencyView: this.model.get('CREDIT_CURRENCY'),
            creditAmountFormat: Decimals.getCurrencyFormat(this.model.get('CREDIT_CURRENCY')),
            enteredAmountView: this.model.get(isCreditAmount ? 'CREDIT_AMOUNT' : 'DEBIT_AMOUNT'),
            enteredCurrencyView: this.model.get(isCreditAmount ? 'CREDIT_CURRENCY' : 'DEBIT_CURRENCY'),
            enteredAmountFormat: Decimals.getCurrencyFormat(this.model.get(isCreditAmount ? 'CREDIT_CURRENCY' : 'DEBIT_CURRENCY')),
            indicativeAmountView: this.model.get(isCreditAmount ? 'DEBIT_AMOUNT' : 'CREDIT_AMOUNT'),
            indicativeCurrencyView: this.model.get(isCreditAmount ? 'DEBIT_CURRENCY' : 'CREDIT_CURRENCY'),
            indicativeAmountFormat: Decimals.getCurrencyFormat(this.model.get(isCreditAmount ? 'DEBIT_CURRENCY' : 'CREDIT_CURRENCY')),
            hasIndicativeRate: this.model.get('INDICATIVERATE') && !this.model.get('EXCHANGE_RATE') && !this.isTemplate,
            hasContractId: this.model.get('EXCHANGE_RATE_CONTRACTID'),
            hasCalculatedCredit: isCrossCurrency && !isCreditAmount,
            hasCalculatedDebit: isCrossCurrency && isCreditAmount,
            isNeedsRates: this.model.get('STATUS') === constants.NEEDSRATEID,
            isOne2One: this.options.type === 'ONE2ONE' || this.options.type === 'SINGLE',
            noReferenceAndView: !this.model.get('CUSTOMER_REFERENCE') && this.options.readOnly,
            noCommentsAndView: !this.model.get('SPECIAL_INSTRUCTIONS') && this.options.readOnly,
            isRecur: this.options.type === 'RECUR',
            isNotRecur: this.options.type !== 'RECUR',
            isSingle: this.options.isSingle,
            isSingleTransfer: this.options.type === 'SINGLE',
            isSingleTransferOrModify: (this.options.type === 'SINGLE' || this.options.mode === 'modify' || this.options.isModify) && this.options.readOnly !== true,
            isShowTime: !this.hideTime,
            hideFrom: this.hideFromAccount,
            hideTo: this.hideToAccount,
            alwaysHideOptField: (this.hideFromAccount && this.options.type === 'MANY2ONE') || (this.hideToAccount && this.options.type === 'ONE2MANY'),
            hideDate: this.hideDate,
            hideAmount: this.hideAmount,
            hideSingleRecur: this.hideSingleRecur,
            reqAmount: (!this.templateMode && !this.options.readOnly) || this.model.get('schedModel'),
            hideClose: this.hideClose || this.options.readOnly || this.options.modifyPaymentFromTemplate || this.options.type === 'SINGLE' || this.options.isModify || this.options.transferModel,
            readOnly: this.options.readOnly,
            currencyType: this.currencyType,
            isAdmin: systemConfig.isAdmin(),
            isTransfer: this.model.get('TYPE') === 'TRANSFER' && this.model.get('FUNCTION') === 'INST',
            templateCodeReadOnly: this.options.readOnly || this.options.isModify,
            nextEarliestValueDate,
            templateMode: this.options.templateMode || this.options.copyFromTemplate,
            readOnlyOrCopyFromTemplate: this.options.readOnly || this.options.copyFromTemplate || this.options.modifyPaymentFromTemplate || this.options.mode === 'repair',
            displayCutoff: this.displayCutoff(),
            isPotentialDuplicate: this.model.get('POSSIBLEDUPLICATEFLAG') === '1',
            userTimezone: this.parentLayout.userTimezone,
            isOne2OneNotModify: (this.options.type === 'ONE2ONE' && this.options.mode !== 'modify' && this.model.get('ACCOUNTFILTER') !== ''),
            referenceLength: this.parentLayout.customerReferenceLength,
            isError: this.model.get('ERRORS'),
            templateMaxAmountFormatted: this.templateMaxAmountStr,
            lockPaymentAmount() {
                let lockAmount = false;
                const isAmountLocked = self.model.get('LOCKED_FIELDS')
                     && self.model.get('LOCKED_FIELDS').indexOf('ENTERED_AMOUNT') !== -1;
                const isFromTemplate = self.options.copyFromTemplate
                     || self.model.get('ENTRYMETHOD') === '1';
                if (self.options.readOnly || (isAmountLocked && isFromTemplate)) {
                    lockAmount = true;
                }
                return lockAmount;
            },
            isAmountLockable: this.options.isAmountLockable,
            amountLockIconClass() {
                let iconClass = 'icon-unlock';
                if (self.options.isAmountLockedByDefault || self.options.isAmountLocked || (self.model.get('LOCKED_FIELDS') && self.model.get('LOCKED_FIELDS').indexOf('ENTERED_AMOUNT') !== -1)) {
                    iconClass = 'icon-lock';
                }
                return iconClass;
            },
        };
    },
    /**
     * Correctly updates the lock icon state upon when the user clicks it
     * @param  {Event} e  Jquery click handler event
     */
    toggleLock(e) {
        if (this.state === 'view') {
            return;
        }
        PaymentUtil.toggleLock(e, this.model);
        const isLocked = e.currentTarget.classList.contains('icon-lock');
        if (this.options.type === 'SINGLE') {
            this.model.setValidators(
                !this.hideFromAccount, !this.hideToAccount,
                !this.hideDate, isLocked, this.options.fxRateType,
            );
        }
        if (this.model.collection && this.model.collection.length > 1) {
            this.model.collection.each((m) => {
                m.set('LOCKED_FIELDS', this.model.get('LOCKED_FIELDS'));
                m.setValidators(
                    !this.hideFromAccount, !this.hideToAccount,
                    !this.hideDate, isLocked, this.options.fxRateType,
                );
            });
        }
        $('[name="ENTERED_AMOUNT"]').closest('.form-group').toggleClass('required', isLocked);
    },

    /**
     * Correctly updates the lock icon state upon when the user adds new transfers
     */

    lockFields() {
        $('.icon-unlock').toggleClass('icon-lock', true).toggleClass('icon-unlock', false);
        if (this.model.collection && this.model.collection.length > 1) {
            this.model.collection.each((m) => {
                m.set('LOCKED_FIELDS', this.model.get('LOCKED_FIELDS'));
                m.setValidators(
                    !this.hideFromAccount, !this.hideToAccount,
                    !this.hideDate, true, this.options.fxRateType,
                );
            });
            $('[name="ENTERED_AMOUNT"]').closest('.form-group').toggleClass('required', true);
        }
    },

    buildScheduleModel() {
        if (this.model.get('PATTERNMODE') && this.model.get('PATTERNMODE') !== 'O') {
            const schedModel = this.initializeSchedModel();

            const recurrence = {};

            // This is needed so scheduler doesn't think it is a new record
            schedModel.idAttribute = 'starts';
            const startingEffectiveDate = this.model.get('STARTINGEFFECTIVEDATE');
            schedModel.set('starts', startingEffectiveDate.indexOf(' ') > -1 ? startingEffectiveDate.substring(0, startingEffectiveDate.indexOf(' ')) : startingEffectiveDate);

            if (this.model.get('ENDMODE') === 'noend') {
                schedModel.set('ends', false);
            } else if (this.model.get('ENDMODE') === 'numoccur') {
                schedModel.set('ends', parseInt(this.model.get('ENDCYCLES'), 10));
            } else {
                const endingEffectiveDate = this.model.get('ENDINGEFFECTIVEDATE');
                schedModel.set('ends', endingEffectiveDate.indexOf(' ') > -1 ? endingEffectiveDate.substring(0, endingEffectiveDate.indexOf(' ')) : endingEffectiveDate);
            }

            // Monthly
            if (this.model.get('PATTERNMODE') === 'M') {
                schedModel.set('type', 'MONTHLY');

                if (this.model.get('MONTHLYMODE') === 'specific') {
                    recurrence.intervalType = 'DAY';
                    recurrence.nMonths = this.model.get('MONTHLYMONTH1');
                    recurrence.onN = [Number(this.model.get('MONTHLYDAY1'))];
                } else {
                    recurrence.intervalType = this.model.get('MONTHLYDAYTYPE').toUpperCase();
                    recurrence.nMonths = this.model.get('MONTHLYMONTHNUMBER');
                    recurrence.onN = [this.model.get('MONTHLYDAYNUMBER').toUpperCase()];
                }
            } else {
                // Weekly
                schedModel.set('type', 'WEEKLY');

                recurrence.nWeeks = this.model.get('WEEKLYWEEKS');
                recurrence.onN = [];

                util.each(days, (day, idx) => {
                    if (this.model.get(day) === '1') {
                        recurrence.onN.push(idx + 1);
                    }
                });
            }

            schedModel.set(recurrence);

            schedModel.set('readOnly', this.options.mode !== 'modify');
            schedModel.set('minDate', Formatter.formatDate(new Date(), 'YYYY-MM-DD'));
            this.model.set('schedModel', schedModel);
        }
    },

    singleRecurringSelect(e) {
        if (this.$(e.target).is(':checked')) {
            const self = this;
            const schedModel = this.initializeSchedModel();

            this.recurCheckUnchecked = false;

            this.model.set({
                NONBUSINESSDAYMODE: 'prev',
                ENDMODE: 'noend',
            });

            this.scheduler = new Scheduler({
                model: schedModel,
                startDate: this.templateMode ? dateFormats : false,
                simpleEndDate: false,
                startValue: schedModel.get('starts') || Formatter.formatDate(new Date(), 'YYYY-MM-DD'),
                weekly: true,
                monthly: true,
                yearly: false,
                daily: false,
                ends: dateFormats,
                readOnly: this.state === 'view',
            });

            this.scheduler.model.set('showWeekends', true);
            this.singleSchedRegion.show(this.scheduler);
            this.ui.businessDayMode.show();
            this.model.singleScheduleModel = this.scheduler;
            if (this.templateMode) {
                this.ui.amtField.closest('.form-group').addClass('required');
                this.model.setValidators(
                    !this.hideFromAccount,
                    !this.hideToAccount,
                    !this.hideDate,
                    true,
                    this.options.fxRateType,
                );
            }
            if (this.hasAutoApproveChecked !== true) {
                const ctx = {
                    serviceName: 'template/transfer',
                };
                const opt = {
                    context: ctx,
                    actionMode: 'INSERT',
                    restrictions: ['AUTOAPPROVE'],
                };
                const transferRestrictionPromise = entitlements.getActionRestrictions(opt);
                transferRestrictionPromise.then((result) => {
                    if (result.restrictions[0].value1 === 'true') {
                        self.hasAutoApprove = true;
                        self.ui.scheduleWarningDisplay.hide();
                    } else {
                        self.ui.scheduleWarningDisplay.show();
                    }
                    self.hasAutoApproveChecked = true;
                });
            } else if (this.hasAutoApprove !== true) {
                this.ui.scheduleWarningDisplay.show();
            }
        } else {
            this.recurCheckUnchecked = true;
            this.singleSchedRegion.reset();
            this.ui.businessDayMode.hide();
            if (this.templateMode) {
                this.ui.amtField.closest('.form-group').removeClass('required');
                this.model.setValidators(
                    !this.hideFromAccount,
                    !this.hideToAccount,
                    !this.hideDate,
                    false,
                    this.options.fxRateType,
                );
            }
            this.ui.scheduleWarningDisplay.hide();
            delete this.model.singleScheduleModel;
        }
    },

    getDebitCurrency() {
        return this.model.get('DEBIT_CURRENCY')
            || this.fromCurrency
            || (this.model.fromAccount ? this.model.fromAccount.get('CURRENCYCODE') : undefined)
            || (this.parentLayout ? this.parentLayout.currency : undefined);
    },

    getCreditCurrency() {
        return this.model.get('CREDIT_CURRENCY')
            || this.toCurrency
            || (this.model.toAccount ? this.model.toAccount.get('CURRENCYCODE') : undefined)
            || (this.parentLayout ? this.parentLayout.currency : undefined);
    },

    isCrossCurrency() {
        const crCurr = this.getCreditCurrency();
        const dbCurr = this.getDebitCurrency();

        return (!!crCurr && !!dbCurr && crCurr !== dbCurr);
    },

    /**
     * Adds a specific validator for the CREDIT_CURRENCY field that will validate using the allowed
     * currencies based on the debit bank code and debit currency
     * @param {Array} allowedCreditCurrencies - the allowed credit currencies
     */
    updateCreditCurrencyValidators(allowedCreditCurrencies) {
        // remove any existing credit currency validator
        this.model.removeValidator('CREDIT_CURRENCY');

        this.model.addValidator(
            'CREDIT_CURRENCY',
            {
                description: locale.get('RTGS.TMPL.TRANSFER.*.CREDIT_CURRENCY.description'),
                exists: true,
                customOneOf: allowedCreditCurrencies.map(currency =>
                    currency.name),
                customString: locale.get('RTGS.FxCurrencyPair.PairNotSupported.short', this.model.get('DEBIT_CURRENCY'), locale.get('RTGS.TMPL.TRANSFER.*.CREDIT_CURRENCY.description')),
            },
        );
    },

    /**
     * builds the request object to retrieve allowed credit currencies
     */
    constructAllowedCreditCurrenciesRequestPayload() {
        return {
            queryCriteria: {
                action: {
                    productCode: 'RTGS',
                    functionCode: 'INST',
                    typeCode: 'TRANSFER',
                    actionMode: 'INSERT',
                },
                customFilters: [
                    {
                        filterName: 'Depends',
                        filterParam: [
                            'DEBIT_BANK_CODE',
                            this.model.get('ACCOUNTFILTER').split('-')[0],
                        ],
                    },
                    {
                        filterName: 'Depends',
                        filterParam: [
                            'DEBIT_CURRENCY',
                            this.model.get('DEBIT_CURRENCY'),
                        ],
                    },
                ],
                subTypeCode: '',
                fieldName: 'CREDIT_CURRENCY',
                entryClass: '',
                allowDuplicates: false,
            },
        };
    },

    /**
     * @returns {Promise} The promise to get allowed credit currencies
     */
    createPromiseToRetrieveAllowedCreditCurrencies() {
        const queryURL = services.generateUrl(constants.URL_GETQUERYRESULTS_ACTION);
        const queryData = this.constructAllowedCreditCurrenciesRequestPayload();

        return serviceUtil.postData(queryURL, queryData);
    },

    /**
     * Retrieve the allowed credit currencies based off the ACCOUNTFILTER and DEBIT_CURRENCY
     * this function should run when either of those attributes are changed
     */
    updateAllowedCreditCurrenciesFromDependent() {
        // safegaurd to prevent multiple calls from the multiple dependent attributes
        if (this.allowedCurrencyUpdateInProgress) {
            return;
        }

        this.allowedCurrencyUpdateInProgress = true;

        // either the DEBIT_CURRENCY or the DACCOUNTFILTER is empty
        if (!this.model.get('DEBIT_CURRENCY') || !this.model.get('ACCOUNTFILTER')) {
            this.model.allowedCreditCurrencies = [];

            this.updateCreditCurrencyValidators(this.model.allowedCreditCurrencies);
            this.allowedCurrencyUpdateInProgress = false;
            return;
        }

        this.createPromiseToRetrieveAllowedCreditCurrencies().then((result) => {
            this.model.allowedCreditCurrencies = result?.queryResponse?.QueryData?.queryRows?.map(({
                label,
                name,
            }) => ({ label, name }));
            this.updateCreditCurrencyValidators(this.model.allowedCreditCurrencies);

            this.allowedCurrencyUpdateInProgress = false;
        }, log.error).then(() => {
            this.allowedCurrencyUpdateInProgress = false;
        });
    },
});
