import services from 'services';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import locale from '@glu/locale';
import Model from '@glu/core/src/model';

export default Model.extend({
    defaults: {
        list: [],
    },

    initialize() {
        this.validators = {
            name: {
                description: locale.get('payment.templategroups.groupName'),
                exists: true,
            },

            description: {
                description: locale.get('payment.templategroups.groupDescription'),
                exists: true,
            },
        };
    },

    sync(method, model, options) {
        let url;
        if (method === 'create') {
            url = services.generateUrl('/tableMaintenance/PaymentTemplateGroups/add');
        } else if (method === 'update') {
            url = services.generateUrl('/tableMaintenance/PaymentTemplateGroups/update');
        } else if (method === 'delete') {
            url = services.generateUrl('/tableMaintenance/PaymentTemplateGroups/delete');
        }

        if (url) {
            return http.post(url, this.toServerJSON(method), options.success, options.error);
        }
        return undefined;
    },

    toServerJSON(method) {
        const listJSON = util.map(this.get('list'), item => ({
            item: [{
                name: 'TNUM',
                value: item.TNUM,
            }, {
                name: 'TEMPLATE_CODE',
                value: item.TEMPLATE_CODE,
            }],
        }));

        const json = {
            item: {
                item: [{
                    name: 'NAME',
                    value: this.get('name'),
                }, {
                    name: 'DESCRIPTION',
                    value: this.get('description'),
                }],
            },

            grids: [{
                name: 'PAYTEMPLATESETITEMS',
                items: listJSON,
            }],
        };

        if (method === 'update' || method === 'delete') {
            json.item.item.push({
                name: 'USERGROUP',
                value: userInfo.get('group'),
            });
        }

        return json;
    },
});
