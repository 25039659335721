import React, {
  useMemo, useCallback
} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import { usePromise } from '@glu/utilities-react';
import Select from '../Select/Select';

const styles = {
  select: {
    '& select': {
      width: '100%'
    },
    marginBottom: 20
  }
};

export const ENUM_FILTER_ID = 'enumFilter';

function enumDataValueToKey(value) {
  return Array.isArray(value) ? value.sort().join() : value;
}

/**
 * Takes enumData and returns a new array with each element having a new "key" property that
 * can uniquely identify that element generated from that element's value property.
 *
 * @param {array} enumData - Array of name value pairs for an enum filter
 * @return {*}
 */
export function keyEnumData(enumData) {
  return enumData.map((item) => ({
    ...item,
    key: enumDataValueToKey(item.value)
  }));
}

const EnumFilter = ({
  className, classes, createFilterValue, dark, enumData,
  fieldId, filterId, htmlId,
  onChange, parseFilterValue, value: filterValue
}) => {
  const getEnumData = useCallback(() => {
    const evaluated = typeof enumData === 'function' ? enumData() : enumData;
    return evaluated instanceof Promise ? evaluated : Promise.resolve(evaluated);
  }, [enumData]);

  const { resolves } = usePromise({ init: getEnumData });

  const keyedEnumData = useMemo(() => (resolves ? keyEnumData(resolves) : []), [resolves]);

  const onSelected = useCallback((event) => {
    // selected enumData entry, name and value (minus key added by keyEnumData)
    const { name, value } = keyedEnumData.find((item) => item.key === event.target.value) || {};

    const newFilterValue = createFilterValue(
      filterId, fieldId, { name, value }
    );

    onChange(fieldId, newFilterValue);
  }, [keyedEnumData, createFilterValue, filterId, fieldId, onChange]);

  const selectedEnumKey = enumDataValueToKey(parseFilterValue(filterValue));

  return (
    <Select
      htmlId={htmlId}
      className={`${classes.select} ${className}`}
      name={fieldId}
      onChange={onSelected}
      value={selectedEnumKey}
      dark={dark}
    >
      {keyedEnumData.map((data) => (
        <option key={data.key} value={data.key}>{data.name}</option>
      ))}
    </Select>
  );
};

EnumFilter.propTypes = {

  /** ClassName to use for this filter component */
  className: PropTypes.string,

  /** Classes for JSS styling */
  classes: PropTypes.objectOf(PropTypes.string).isRequired,

  /**
   * Creates the final filter value that is returned via the filter's onChange function whenever
   * the filter is changed.
   *
   * @param {string} filterId - The id of the filter
   * @param {string} fieldId - The id of the field being filtered
   * @param {{
   *    name: string,
   *    value: string|number|boolean|*[]
   * }} enumDataEntry - The entry from enumData selected by the user to use for filtering.
   * @return {*}
   */
  createFilterValue: PropTypes.func.isRequired,

  /** True if the filter should be in dark mode which styles it for dark colored forms */
  dark: PropTypes.bool,

  /**
   * List of choices available for the filter and the values that each represents
   * Can accept as a value a simple value or an array of simple values.
   * This allows an enum filter to have a single label for an entry but the filter to filter on
   * multiple possible values in that group
   *
   * Sample enumData:
   *    [{
   *      name: 'Choice 1'
   *      value: 'NAME1'
   *    }, {
   *      name: 'Choice 2'
   *      value: ['VALUE2', 'VAL2', 'CODE_TWO']
   *    }, {
   *      name: 'Choice 3'
   *      value: 3
   *    }]
   *
   * In the above example for an enum filter the user could pick 'Choice 1' from the list and we
   * would filter the data for rows whose fieldId field matched 'NAME1'. However, if they chose
   * 'Choice 2' we would filter for data rows whose fieldId field matched 'VALUE2', 'VAL2', or
   * 'CODE_TWO'
   */
  enumData: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
        ),
        PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
      ])
    })),
    PropTypes.func,
    PropTypes.instanceOf(Promise)
  ]).isRequired,

  /** Unique identifier for the field of data you are filtering on */
  fieldId: PropTypes.string.isRequired,

  /** The id to use for the value returned from onChange */
  filterId: PropTypes.string,

  /** Html id to use for the html control of this filter */
  htmlId: PropTypes.string.isRequired,

  /**
   * Function to call whenever the filter has been changed.
   *
   * @param {string} fieldId - The fieldId of this filter
   * @param {Object} value - The new value of the filter
   */
  onChange: PropTypes.func.isRequired,

  /**
   * Parses the criteria used by the filter out of its value which can include other data.
   * Enum filter criteria must be the value portion from the entry selected for filtering out of
   * the enumData array that defines all the filter choices.
   *
   * @param {Object} filterValue - The value for the filter, which may contain data other than
   *    the criteria needed for the Enum Filter.
   * @return {array|string|number|boolean} - Chosen enumData entry's value for filtering
   */
  parseFilterValue: PropTypes.func.isRequired,

  /** The current value for this filter. */
  value: PropTypes.shape({})
};

EnumFilter.defaultProps = {
  className: '',
  dark: true,
  filterId: ENUM_FILTER_ID,
  value: undefined
};

export default withStyles(styles)(EnumFilter);
export const BareEnumFilter = EnumFilter;
