import React from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import IEUtil from 'common/util/IEUtil';

import NoDataOrLoading from '../../../pcm/common/components/NoDataOrLoading';
import ErrorMsgPtx from '../../../pcm/common/components/ErrorMsgPtx';
import { getESendPreviewImage, parseESendDocumentPreview, getCheckPreviewDownloadPdf } from '../../app/payments/dataUtils';

class ESendDocumentPreview extends React.Component {
    state = {
        error: false,
        errorEsend: false,
        isLoading: true,
        documentData: [],
        documentId: null,
    };

    componentDidMount() {
        const { eSendDocumentId, eSendStatus } = this.props;

        if (!eSendDocumentId) {
            this.setState({
                errorEsend: locale.get('esend.documentPreview.error.ID'),
                isLoading: false,
            });
		  }else{
            getESendPreviewImage(eSendDocumentId).then((data) => {
                const documentData = parseESendDocumentPreview(data);
                this.setState({
                    isLoading: false,
                    documentData,
                    documentId: data.id,
                });
            }, (err) => {
                const msg = (err instanceof Error) ? err.message : err.statusText;
                this.setState({
                    error: msg,
                    isLoading: false,
                });
            });
        }
    }

    showEsendError = (status) => {
        this.setState({
            errorEsend: locale.get(`esend.documentPreview.error.${status}`),
            isLoading: false,
        });
    }


    download = () => {
        getCheckPreviewDownloadPdf(this.state.documentId);
    }

    render() {
        const {
            error, errorEsend, isLoading, documentData,
        } = this.state;

        return (
            <div>
                {errorEsend && <ErrorMsgPtx title={locale.get('ptx.error.prefixESendDocumentPreview')} />}
                {error && <ErrorMsgPtx title={locale.get('ptx.error.prefixESendDocumentPreview')} />}
                {
                    documentData.length > 0 ? (
                        <React.Fragment>
                            {
                                documentData.map((imageUrl, index) => (
                                    <div>
                                        <div>
                                            <button type="button" className="btn btn btn-primary popbox-btn1" id="downloadButton" onClick={() => this.download(imageUrl)}>{locale.get('download')}</button>
                                        </div>
                                        <img key={`esend-preview-${index}`} alt="" src={imageUrl} />
                                    </div>
                                ))
                            }

                        </React.Fragment>
                    ) : (
                        <div className="text-center">
                            <NoDataOrLoading
                                isLoading={isLoading}
                            />
                        </div>
                    )
                }
            </div>
        );
    }
}

ESendDocumentPreview.propTypes = {
    eSendDocumentId: PropTypes.string.isRequired,
    eSendStatus: PropTypes.string.isRequired,
};

export default ESendDocumentPreview;
