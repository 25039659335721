import util from '@glu/core/src/util';
import $ from 'jquery';
import Formatter from 'system/utilities/format';
import dateUtil from 'common/util/dateUtil';
import rtgsCommon from './rtgsCommon';

export default function (form, initialState) {
    let collections;
    const { model } = form.formView;
    const { functionCode } = model.jsonData.typeInfo;
    const summaryDate = $('[data-field="summary-date"]');
    const valueDate = form.field('VALUE_DATE');

    if (initialState) {
        collections = form.formView.comboCollections;

        rtgsCommon.getAccountBalances(form.formView, 'DEBIT_ACCOUNT_NUMBER', 'RTGS', 'TRANSFER', 'DEBIT_BANK_CODE', 'Debit_Currency');

        form.formView.listenTo(form.formView.appBus, 'combo-collections-updated', (keys) => {
            util.each(keys, (key) => {
                if (collections[key].length) {
                    // get balance information
                    rtgsCommon.getAccountBalances(form.formView, key, 'RTGS', 'TRANSFER', 'BENE_BANK_CODE', 'CREDIT_CURRENCY');
                }
            });
        });

        summaryDate.text(Formatter.formatDateFromUserFormat(model.get('VALUE_DATE'), dateUtil.PAYMENT_SUMMARY_DATE_FORMAT));
        valueDate.$el.on('change', () => {
            model.set('VALUE_DATE', $('#VALUE_DATE').val());
            summaryDate.text(Formatter.formatDateFromUserFormat(model.get('VALUE_DATE'), dateUtil.PAYMENT_SUMMARY_DATE_FORMAT));
        });

        if (functionCode.indexOf('TMPL') > -1) {
            $('[data-field="summary-date"]').hide();
            $('.summary-date-text').hide();
        }
    }
}
