export default {
    TIMESTAMP: 'TIMESTAMP',
    FILTERFIELDS: 'FILTERFIELDS', // array of filterfields
    SORTFIELDS: 'SORTFIELDS', // array of sortFields
    ROWSPERPAGE: 'ROWSPERPAGE', // number
    STARTROW: 'STARTROW', // number
    VIEWID: 'VIEWID', // string
    COLUMNS: 'COLUMNS', // array of columninfo includes fieldname and width.  the order in this array is the order that the fields appeas
    CURRENTPAGE: 'CURRENTPAGE',
    ADDITIONALSEARCH: 'ADDITIONALSEARCH', // array of server side filters; these filters are not displayed as badges
};
