var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"btn-popover toolTip-btn-popover\" data-toggle=\"popover\" role=\"button\" aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"tooltip.shortened",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":107},"end":{"line":3,"column":137}}}))
    + "\" data-content=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"tooltipColVal") || (depth0 != null ? lookupProperty(depth0,"tooltipColVal") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"tooltipColVal","hash":{},"data":data,"loc":{"start":{"line":3,"column":153},"end":{"line":3,"column":170}}}) : helper)))
    + "\">\n                        <span class=\"toolTipCell-icon "
    + alias2(((helper = (helper = lookupProperty(helpers,"iconClass") || (depth0 != null ? lookupProperty(depth0,"iconClass") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"iconClass","hash":{},"data":data,"loc":{"start":{"line":4,"column":54},"end":{"line":4,"column":67}}}) : helper)))
    + "\"></span>\n                </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":1,"column":6},"end":{"line":1,"column":15}}}) : helper)))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasToolTip") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":6,"column":15}}})) != null ? stack1 : "")
    + "</span>\n";
},"useData":true});