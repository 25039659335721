import rCount from 'common/dynamicPages/views/mdf/componentHelpers/rcount';
import GridActionFieldView from '../gridActionFieldView';
import template from '../templates/gridActionAuthorize.hbs';

export default GridActionFieldView.extend({
    template,

    onRender() {
        rCount.setupRCountBoxes(this);
    },

    // return the specific fields within this popup
    getDataForSubmission() {
        return {
            COMMENT: this.model.get('COMMENT'),
        };
    },
});
