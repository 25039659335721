import Collection from '@glu/core/src/collection';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';
/**
 * TableModel
 * ==========
 * Used as the main data model for the dataTable component
 * contains Collections that represent the different sections of the table
 * - headerRow
 * - bodyRows
 * - footerRows
 *
 * Each row will contain models for its child columns
 */
import transform from 'common/util/transform';
import { postData } from 'common/util/services';

export default Model.extend({

    defaults: {
        headerRow: new Collection(),
        bodyRows: new Collection(),
        footerRow: new Collection(),
    },

    initialize(attr, options) {
        this.options = options;
        this.searchFields = options.searchFields;
        this.actionCellsOverride = options.actionCellsOverride || {};
        this.suppressListAction = options.suppressListAction;
        this.suppressActionCells = options.suppressActionCells;
        this.viewId = options.viewId;
    },

    sync(method, model, options) {
        const listAction = this.suppressListAction ? '' : constants.URL_GETLISTVIEW_ACTION;
        const serviceUrl = services.generateUrl(this.options.serviceName + listAction);
        const dataBlock = {
            dataOnly: 0,
            searchFields: this.searchFields || [],
            startRow: 1,
        };
        if (this.viewId) {
            dataBlock.viewId = this.viewId;
        }
        return postData(serviceUrl, dataBlock, options.success);
    },

    parse(data) {
        const columnProps = data.rowHeader;
        const newBodyCollection = new Collection(this.processRowData(data.rows, columnProps));
        const headerRow = new Collection(this.setColumnProps(data.rowHeader, columnProps));
        const bodyRows = this.setRowsWithId(newBodyCollection);
        const footerData = data.footerRows || (data.footerRow ? [data.footerRow] : []);
        const footerRow =
            this.setRowsWithId(new Collection(this.processRowData(footerData, columnProps)));

        return {
            headerRow,
            bodyRows,
            footerRow,
        };
    },

    /**
     * @param {Object[]} rows
     * @param {Object[]} [columnProps]
     * - (optional) array of object with column field/display properties to set
     * on columns
     * @return {array}
     *
     * Loop through collection of row models to set
     * onto it the data properties that each column in
     * its columns array is meant to show.
     *
     * Each item in the columns array is received as
     * { fieldName: '', fieldValue: '' } with no other column specific properties
     */
    processRowData(rows, columnProps) {
        return rows.map((row) => {
            // Create a hash out of all the items in the columns array to set on row model
            const rowProps = transform.pairsToHash(row.columns, 'fieldName', 'fieldValue');

            if (columnProps) {
                // set field and display properties for each column in the row
                rowProps.columns = this.setColumnProps(row.columns, columnProps);
            }
            return util.extend({}, row, rowProps);
        });
    },

    /**
     * @param {array} columns (columns of an individual row)
     * @param {array} columnProps (array of object with column field/display data)
     * @return {array}
     * Loops through columns of a row and sets column specific properties needed
     * for display
     * Will filter out any columns that need to be hidden.
     * Since most body and footer columns are returned with only 'fieldValue' and fieldName'
     * while the corresponding header columns store their specific display properties
     */
    setColumnProps(columns, columnProps) {
        return util.chain(columns)
            .map((col, i) => util.extend({}, col, columnProps[i]))
            .filter(col => (col.type !== constants.COLUMN_TYPE_HIDDEN) && (col.displayOrder > 0))
            .map(col => util.extend(
                col,
                {
                    actionCell: this.getActionCell(col),
                },
            ))
            .sortBy('displayOrder')
            .value();
    },

    /**
     * @param {Collection} rows
     * @return {Collection}
     * Returns a Collection of rows models set with an id (attribute)
     * (used to handle setting this id for each Collection's row model on templates)
     */
    setRowsWithId(rows) {
        rows.models.forEach((row) => {
            row.set('id', row.cid);
        });
        return rows;
    },

    /**
     * @param {object} col (individual row)
     * @return {boolean}
     * Sets column specific property `actionCell`
     */
    getActionCell(col) {
        if (this.suppressActionCells) {
            return false;
        }
        return col.fieldName === 'TRANTYPE' && !util.isEmpty(this.actionCellsOverride)
            ? this.actionCellsOverride.TRANTYPE
            : (col.type === constants.COLUMN_TYPE_ACTION_TABLE_ITEM);
    },

    /**
     * @param {string} id
     * @return {Model} row
     * Searches for a row model by its cid property
     */
    findRowById(id) {
        const allRows = util.flatten([this.get('bodyRows').models, this.get('footerRow').models]);
        return util.find(allRows, row => row.cid === id);
    },

    setSearchFields(filters) {
        this.searchFields = filters;
    },
});
