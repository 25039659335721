import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import Select from '../Select/Select';
import { updateOptions } from './utils';

export const baseOptions = [5, 25, 50, 100, 250];
export default function PageDisplayCount({
  classes,
  htmlIdRoot,
  onChange,
  pageSize,
  parsePageSizeOptions,
  totalItems
}) {
  // Preserve the original custom pageSize
  const originalPageSize = useRef(pageSize);
  const { countWrapper } = classes;
  const options = useMemo(() => parsePageSizeOptions(updateOptions({
    options: baseOptions,
    originalPageSize: originalPageSize.current,
    totalItems
  })), [parsePageSizeOptions, totalItems]);

  const value = pageSize > totalItems ? totalItems : pageSize;

  const onChangeCallback = useCallback((e) => {
    e.preventDefault();
    onChange(e.target.value);
  }, [onChange]);
  return (
    <div className={countWrapper}>
      <label htmlFor={`${htmlIdRoot}-pageDisplayCount`}>{locale.get('dataComponents.display')}</label>
      <Select
        htmlId={`${htmlIdRoot}-pageDisplayCount`}
        firstOption={null}
        onChange={onChangeCallback}
        value={value.toString()}
      >
        {options.map((option) => (
          <option key={option.name} value={option.value}>{option.name}</option>
        ))}
      </Select>
    </div>
  );
}

PageDisplayCount.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  htmlIdRoot: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  parsePageSizeOptions: PropTypes.func,
  totalItems: PropTypes.number.isRequired
};

PageDisplayCount.defaultProps = {
  parsePageSizeOptions: /* istanbul ignore next */ (options) => options
};
