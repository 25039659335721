import Layout from '@glu/core/src/layout';
import $ from 'jquery';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import services from 'services';
import GridApi from 'common/dynamicPages/api/grid';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import LockboxReportApi from 'app/reports/api/lockboxReports';
import entitlements from 'common/dynamicPages/api/entitlements';
import ManageNotesView from 'app/reports/views/lockbox/modals/notes/manageNotes';
import ModifyRemitterView from 'app/reports/views/lockbox/modals/remitter/modifyRemitter';
import EnvelopeView from 'app/reports/views/lockbox/modals/envelope/envelopeListView';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import constants from 'app/reports/constants';
import ListView from 'common/dynamicPages/views/workflow/list';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import template from 'app/reports/views/lockbox/lockboxReportDetails.hbs';

const LockboxReportDetails = Layout.extend({
    template,

    ui: {
        $transactionType: '[name="type"]',
        $switch: '.view-switcher .btn',
        $groupView: '#lbxGroupView',
        $listView: '#lbxListView',
        $lbxTransactionTypes: '.lbxTransactionTypes a',
        $lbxTransactionType: '.lbxTransactionTypes',
    },

    events: {
        'click @ui.$switch': 'toggleReportView',
        'click @ui.$lbxTransactionTypes': 'switchTransactionTypes',
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
    },

    initialize(options) {
        this.lockbox = options.lockbox;
        this.reportData = store.get('lbxReportData');
        this.defaultType = this.mapDefaultTypeNameToService();
        this.isGroupedView = false;
        this.listViewType = 'getListView';
        this.returnRoute = options.returnRoute;
    },

    /**
     * @method switchTransactionTypes
     * @param {Event} e - Selected transaction type event
     */
    switchTransactionTypes(e) {
        const $element = this.$(e.currentTarget);
        const endPoint = this.getEndPoint($element.attr('data-attr'));
        this.endPoint = endPoint;
        this.lvc.reset();
        this.showGridView(endPoint);
        this.ui.$lbxTransactionType.find('a').removeClass('active');
        $element.addClass('active');
    },

    /**
     * @method setInquiryId
     * @param endPoint
     * - The inquiryId is later used by buildExportData() for print / export
     */
    setInquiryId(endPoint) {
        if (endPoint === constants.LOCKBOX_CREDIT_ENDPOINT) {
            if (this.isGroupedView) {
                this.inquiryId = constants.LOCKBOX_CREDIT_GROUPED_EXPORT_INQUIRY;
            } else {
                this.inquiryId = constants.LOCKBOX_CREDIT_EXPORT_INQUIRY;
            }
        } else if (endPoint === constants.LOCKBOX_CHECK_ENDPOINT) {
            if (this.isGroupedView) {
                this.inquiryId = constants.LOCKBOX_CHECK_GROUPED_EXPORT_INQUIRY;
            } else {
                this.inquiryId = constants.LOCKBOX_CHECK_EXPORT_INQUIRY;
            }
        } else if (endPoint === constants.LOCKBOX_DOCONLY_ENDPOINT) {
            if (this.isGroupedView) {
                this.inquiryId = constants.LOCKBOX_DOCONLY_GROUPED_EXPORT_INQUIRY;
            } else {
                this.inquiryId = constants.LOCKBOX_DOCONLY_EXPORT_INQUIRY;
            }
        }
    },

    onRender() {
        const self = this;

        if (this.defaultType) {
            this.ui.$transactionType.comboBox('val', this.defaultTypeName);
        }

        this.showGridView(this.defaultType);

        if (this.isGroupedView) {
            this.ui.$groupView.addClass('active');
        } else {
            this.ui.$listView.addClass('active');
        }

        const options = {
            context: {
                actionMode: 'SELECT',
                functionCode: 'LOCKBOX',
                productCode: 'GIR',
                serviceName: '/lockbox/manageRemitters/getListView',
                typeCode: 'LBX_MRN',
            },
        };

        const entitlementPromise = entitlements.getEntitlements(options);

        entitlementPromise.then((result) => {
            if (result && result.actions && result.actions.SELECT) {
                self.$el.find('button[data-action="manageRemitters"]').show();
            } else {
                self.$el.find('button[data-action="manageRemitters"]').hide();
            }
        });
    },

    /**
     * @method showGridView
     * - Process the endPoint, set the inquiryId for later use in print/export,
     * then render the grid.
     */
    showGridView(endPoint) {
        let localEndPoint = endPoint;
        const defaultType = this.reportData.types[0].value;
        localEndPoint = localEndPoint || this.getEndPoint(defaultType);

        this.endPoint = localEndPoint;
        this.typeCode = 'LBX_CHK';

        if (localEndPoint === constants.LOCKBOX_CREDIT_ENDPOINT) {
            if (this.isGroupedView) {
                this.typeCode = 'LBX_CRGR';
            } else {
                this.typeCode = 'LBX_CRED';
            }
        } else if (localEndPoint === constants.LOCKBOX_CHECK_ENDPOINT) {
            if (this.isGroupedView) {
                this.typeCode = 'LBX_CKGR';
            } else {
                this.typeCode = 'LBX_CHK';
            }
        } else if (localEndPoint === constants.LOCKBOX_DOCONLY_ENDPOINT) {
            if (this.isGroupedView) {
                this.typeCode = 'LBX_DCGR';
            } else {
                this.typeCode = 'LBX_DOC';
            }
        }
        this.setInquiryId(localEndPoint);

        store.set('lockboxTransaction:endpoint', localEndPoint);

        const context = {
            serviceName: `/lockbox/${localEndPoint}/${this.listViewType}`,
            productCode: 'GIR',
            functionCode: 'LOCKBOX',
            typeCode: this.typeCode,
        };

        ListView.prototype.setListViewConfig.call(this, context);

        const reportOptions = {
            context,
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,
            enableColumnRenameControls: true,
            suppressServicesUrlSuffix: true,
            enableSavedViews: true,
            lvc: this.lvc,
            additionalSearchFields: [{
                dataType: 'number',
                fieldName: 'BATCH_ID',
                operator: 'IN',
                fieldValue: this.reportData.batch,
            },
            {
                dataType: 'date',
                fieldName: 'DEPOSIT_DATE',
                operator: 'BETWEEN',
                fieldValue: [
                    this.reportData.minDate,
                    this.reportData.maxDate,
                ],
            }],

            parseRow() {
                // for each row
                for (let x = 0; x < this.jsonData.rows.length; x += 1) {
                    const row = this.jsonData.rows[x];

                    // and each column in a row
                    for (let y = 0; y < row.columns.length; y += 1) {
                        const column = row.columns[y];
                        let { fieldValue } = column;

                        /*
                         * check for a flag, set it onto the object, and remove it from the
                         * field value
                         */
                        if (fieldValue.indexOf('^Y') > -1) {
                            column.hasCheckImage = true;
                            fieldValue = fieldValue.replace(/\^Y/gi, '');
                        } else {
                            fieldValue = fieldValue.replace(/\^N/gi, '');
                        }
                        column.fieldValue = fieldValue;
                    }
                }
            },
        };

        reportOptions.selector = 'none';

        this.gridView = GridApi.createServiceGridView(reportOptions);
        this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
        if (mobileUtil.isMobileGridEnabled()) {
            let MobileList = LockboxReportDetails;
            const mobileList = configureMobileInterface(MobileList, {
                useGridViewFromOptions: true,
            });
            MobileList = MobileList.extend(mobileList);
            this.gridRegion.show(new MobileList({
                ...this.options,
                ...reportOptions,
                gridView: this.gridView,
            }));
        } else {
            this.gridRegion.show(this.gridView);
        }
    },

    gridRowAction(optionsParam) {
        const options = optionsParam;
        const action = options.action.toUpperCase();
        if (action === 'COMMENT') {
            this.manageNotes(options);
        }
        if (action === 'IMAGE') {
            this.showImage(options);
        }
        if (action === 'MANAGERMT') {
            options.account = {
                accountNumber: options.model.get('CHKACCT'),
                aba: options.model.get('CHKROUT'),
            };
            const remitterName = options.model.get('REMITTER');
            if (remitterName.replace(/\s/g, '').length > 0) {
                options.remitterName = remitterName;
            }
            options.allowExistingRemitters = true;
            this.modifyRemitter(options);
        }

        if (action === 'SELECT') {
            this.viewEnvelopeDetails(options.model);
        }
    },

    reloadGrid() {
        this.showGridView(this.endPoint);
    },

    /**
     * @method showImage
     * When the grid row action IMAGE is triggered, create the base request for
     * Check Image and store it
     * @parem {object} - Data about the action chosen for the selected row and its' model
     */
    showImage(options) {
        const { fieldName } = options.model;
        const req = {
            TRANS_ITEM_ID: options.model.get('TRANS_ITEM_ID'),
            FIELD_TYPE_CODE: fieldName,
        };
        store.set('lbxImageRequest', req);
        this.navigateTo('REPORTING/lockbox/reports/checkImage');
    },

    /**
     * @method viewEnvelopeDetails
     * - Store the data required for the envelopeDetails screen. Navigate to the
     * next screen.
     */
    viewEnvelopeDetails(model) {
        const defaultType = this.ui.$lbxTransactionType.find('a.active').attr('data-attr')
                || model.get('ITEM_TYPE_CODE');
        const endPoint = this.getEndPoint(defaultType);

        let envDetailsTypeCode = 'LBX_CKDT';
        if (endPoint === constants.LOCKBOX_CREDIT_ENDPOINT) {
            envDetailsTypeCode = 'LBX_CRDT';
        } else if (endPoint === constants.LOCKBOX_CHECK_ENDPOINT) {
            envDetailsTypeCode = 'LBX_CKDT';
        } else if (endPoint === constants.LOCKBOX_DOCONLY_ENDPOINT) {
            envDetailsTypeCode = 'LBX_DCDT';
        }

        store.set(
            'envelopeDetails',
            {
                eid: model.get('ENVELOPE_ID'),
                endpoint: this.endPoint || endPoint,
                typeCode: envDetailsTypeCode,
                filter: this.reportData.filter,
            },
        );
        this.navigateTo('REPORTING/lockbox/reports/envelopeDetails');
    },

    modifyRemitter(optionsParam) {
        const options = optionsParam;
        const self = this;
        options.modalType = 'basic';
        const remitterModal = new ModifyRemitterView(options);

        remitterModal.on('remitter:close', (alertView) => {
            if (alertView) {
                self.alertRegion.show(alertView);
                self.reloadGrid();
            }
            dialog.close();
        });

        dialog.open(remitterModal);
    },

    manageNotes(options) {
        const self = this;
        const manageNotes = new ManageNotesView({
            batch: options.model.get('BATCH_ID'),
            lockbox: this.reportData.filter,
            ENVELOPE_ID: options.model.get('ENVELOPE_ID'),
        });
        manageNotes.on('grid:refresh', (alert) => {
            dialog.close();
            if (alert) {
                self.alertRegion.show(alert);
                self.reloadGrid();
            }
        });
        dialog.open(manageNotes);
    },

    viewEnvelope(model) {
        const envelope = new EnvelopeView({
            endPoint: this.endPoint,
            model,
        });
        dialog.open(envelope);
    },

    manageRemitters() {
        this.navigateTo('REPORTING/lockbox/remitters');
    },

    /**
     * @method getEndPoint
     * @param {string} type -  The Item Type Code of the transaction type. (Check,
     * Credit, or Doc Only Transaction)
     */
    getEndPoint(type) {
        const types = {
            P: 'checktransactions',
            C: 'creditcardtransactions',
            S: 'doconlytransactions',
        };
        return types[type];
    },

    /**
     * @method cancel
     */
    cancel() {
        if (this.stack) {
            this.stack.pop();
            this.navigateTo(this.returnRoute);
        } else {
            window.history.back();
        }
    },

    /**
     * @method toggleReportView
     * @param {Event} e
     */
    toggleReportView(e) {
        const $target = $(e.currentTarget);

        this.isGroupedView = $target.attr('id') !== 'lbxListView';
        this.listViewType = this.isGroupedView ? 'getGroupedListView' : 'getListView';
        this.ui.$switch.removeClass('active');
        $target.addClass('active');
        this.lvc.reset();
        this.showGridView(this.endPoint);
    },

    export() {
        if (this.exceedsMaxExportLimit()) {
            return;
        }
        const exportData = this.buildExportData('CSV');
        LockboxReportApi.doExport(
            this, exportData,
            services.generateUrl(constants.ASYNC_LOCKBOX_EXPORT_PRINT_URL),
        );
    },

    print() {
        if (this.exceedsMaxExportLimit()) {
            return;
        }
        const exportData = this.buildExportData('PDF');
        const printModal = new PrintViewModal({
            exportModel: exportData,
            exportURL: services.generateUrl(constants.ASYNC_LOCKBOX_EXPORT_PRINT_URL),
        });
        dialog.custom(printModal);
    },

    exceedsMaxExportLimit() {
        const maxListViewExportRecords = parseInt(this.getMaxListViewExportRecords(), 10);
        const currentTotalRows = this.gridView.grid.collection.jsonData.totalRows;
        if (currentTotalRows > maxListViewExportRecords) {
            dialog.error(locale.get('export.lockboxexceed.error', maxListViewExportRecords), locale.get('export.lockboxexceed.title'));
            return true;
        }

        return false;
    },

    getMaxListViewExportRecords() {
        return serverConfigParams.get('LockBoxExportReportMaxRows') || 5000;
    },

    buildExportData(format) {
        const exportData = LockboxReportApi.buildExportData(format, this.inquiryId, this.gridView);

        if (!exportData.searchFields) {
            exportData.searchFields = [];
        }

        const searchField = {};
        const searchField1 = {};
        searchField.operator = 'IN';
        searchField.fieldValue = this.reportData.batch;
        searchField.dataType = 'numeric';
        searchField.fieldName = 'BATCH_ID';
        exportData.searchFields.push(searchField);

        searchField1.operator = 'BETWEEN';
        searchField1.fieldValue = [this.reportData.minDate, this.reportData.maxDate];
        searchField1.dataType = 'date';
        searchField1.fieldName = 'DEPOSIT_DATE';
        exportData.searchFields.push(searchField1);

        if (exportData.inquiryId === constants.LOCKBOX_CHECK_GROUPED_EXPORT_INQUIRY) {
            exportData.inquiryId = constants.LOCKBOX_CHECK_GROUPVIEW_EXPORT_INQUIRY;
        } else if (exportData.inquiryId === constants.LOCKBOX_CREDIT_GROUPED_EXPORT_INQUIRY) {
            exportData.inquiryId = constants.LOCKBOX_CREDIT_GROUPVIEW_EXPORT_INQUIRY;
        } else if (exportData.inquiryId === constants.LOCKBOX_DOCONLY_GROUPED_EXPORT_INQUIRY) {
            exportData.inquiryId = constants.LOCKBOX_DOCONLY_GROUPVIEW_EXPORT_INQUIRY;
        }

        if (exportData.inquiryId === constants.LOCKBOX_CHECK_GROUPVIEW_EXPORT_INQUIRY
                || exportData.inquiryId === constants.LOCKBOX_CREDIT_GROUPVIEW_EXPORT_INQUIRY
                || exportData.inquiryId === constants.LOCKBOX_DOCONLY_GROUPVIEW_EXPORT_INQUIRY) {
            exportData.columns = [];
            delete exportData.numberOfRows;
            delete exportData.viewId;
            exportData.searchFields = util.filter(exportData.searchFields, field => field.fieldName !== 'NUM_TRANSACTIONS');
        }

        return exportData;
    },

    mapDefaultTypeNameToService() {
        const type = this.reportData.defaultType;
        let service;

        if (type) {
            this.defaultTypeName = type;
            if (type.toUpperCase().indexOf('CREDIT') > -1) {
                service = 'creditcardtransactions';
            }
            if (type.toUpperCase().indexOf('CHECK') > -1) {
                service = 'checktransactions';
            }
            if (type.toUpperCase().indexOf('DOC') > -1) {
                service = 'doconlytransactions';
            }

            return service;
        }
        return false;
    },

    templateHelpers() {
        const self = this;
        return {
            getReportDate() {
                return self.reportData.range;
            },

            getLockboxCode() {
                return (!util.isNullOrUndefined(self.reportData.name)) ? `${self.reportData.filter} ${self.reportData.name}` : self.reportData.filter;
            },

            transactionTypes() {
                return util.uniq(self.reportData.types, item => item.value);
            },

            viewLabel() {
                return self.isGroupedView ? locale.get('LBX.GroupedView') : locale.get('LBX.ListView');
            },

            gridUtilityOptions: {
                customIcons: [{
                    dataAction: 'manageRemitters',
                    icon: 'icon-network',
                    ariaLabel: 'LBX.ManageRemitters',
                    title: 'LBX.ManageRemitters',
                }],
                hasRefresh: false,
            },
        };
    },
});

export default LockboxReportDetails;
