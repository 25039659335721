import Day from 'no-override!@glu/scheduler/src/recurrence/monthly/monthlyChildViews/basicSelectors/day';
import tmpl from './day.hbs';
import locale from '@glu/locale';
import util from 'underscore';

export default Day.extend({
    template: tmpl,

    templateHelpers() {
        const daysInMonth = util.range(1, 32);

        const ending = function(i) {
            if (i === 0) {
                return 'last';
            } else if (i === 1 || i === 21 || i === 31) {
                return 'first';
            } else if (i === 2 || i === 22) {
                return 'second';
            } else if (i === 3 || i === 23) {
                return 'third';
            } else {
                return 'fourth';
            }
        };

        return {
            daysInMonth: util.map(daysInMonth, i => {
                const endingValue = ending(i);
                const label = `scheduler.${i}${endingValue.substring(endingValue.length - 2)}`;

                return {
                    value: i,
                    label: locale.get(label),
                };
            }),

            cid: this.model.cid,
        };
    },

    setDropdown() {
        if (!this.model.has('onN') || util.isString(this.model.get('recurrence.onN')[0])) {
            this.model.set({
                'recurrence.onN': '1',
                'onN': '1',
            });
        }
        // A temp fix for NH-152296 where views don't update
        this.ui.$select.val(this.model.get('recurrence.onN'));
        this.ui.$select.flexDropdown();
    }
});
