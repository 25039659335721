import Model from '@glu/core/src/model';
import services from 'services';
import http from '@glu/core/src/http';

export default Model.extend({
    initialize(options) {
        this.data = this.getRequest(
            options.keyValues,
            options.parentUserGroup,
            options.childUserGroup,
        );
        this.action = options.action;
    },

    getRequest(keyValues, parentUserGroup, childUserGroup) {
        return {
            item: [{
                name: 'keyValues',
                value: keyValues,
            }, {
                name: 'ParentUserGroup',
                value: parentUserGroup,
            }, {
                name: 'UserGroup',
                value: childUserGroup,
            }],
        };
    },

    sync(method, model, options) {
        if (this.action === 'assign') {
            this.service = services.bankAcctFiltersAssignAccounts;
        }
        if (this.action === 'unassign') {
            this.service = services.bankAcctFiltersUnassignAccounts;
        }

        http.post(this.service, this.data, (response) => {
            options.success(response);
        }, (response) => {
            options.error(model, response);
        });
    },
});
