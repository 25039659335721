var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":11,"column":27},"end":{"line":11,"column":37}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":11,"column":39},"end":{"line":11,"column":48}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.personal.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":1,"column":8},"end":{"line":1,"column":38}}}))
    + " <span class=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"iconClassName") || (depth0 != null ? lookupProperty(depth0,"iconClassName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"iconClassName","hash":{},"data":data,"loc":{"start":{"line":1,"column":52},"end":{"line":1,"column":69}}}) : helper)))
    + "\">\n    </span>\n</legend>\n\n<div>\n    <div class=\"form-group\">\n        <label for=\"BENE_BIRTH_COUNTRY\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTP.INST.CRTRAN.*.BENE_BIRTH_COUNTRY.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":40},"end":{"line":7,"column":95}}}))
    + "</label>\n        <select aria-label=\"BENE_BIRTH_COUNTRY\" class=\"form-control\" name=\"BENE_BIRTH_COUNTRY\" data-bind=\"model\">\n            <option value=\"\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.select.country",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":29},"end":{"line":9,"column":60}}}))
    + "</option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"countries") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":12,"column":21}}})) != null ? stack1 : "")
    + "        </select>\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_BIRTH_COUNTRY\"></span>\n    </div>\n</div>\n\n<div>\n    <div class=\"form-group\">\n        <label for=\"BENE_BIRTH_CITY\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTP.INST.CRTRAN.*.BENE_BIRTH_CITY.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":37},"end":{"line":20,"column":89}}}))
    + "</label>\n        <input class=\"form-control\" id=\"BENE_BIRTH_CITY\" type=\"text\" name=\"BENE_BIRTH_CITY\" maxlength=\""
    + alias2(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"BENE_BIRTH_CITY") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\" data-bind=\"model\">\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_BIRTH_CITY\"></span>\n    </div>\n</div>\n\n<div class=\"form-group\">\n    <div>\n        <label for=\"BENE_BIRTH_DATE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTP.INST.CRTRAN.*.BENE_BIRTH_DATE.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":37},"end":{"line":28,"column":89}}}))
    + "</label>\n        <input class=\"form-control\" id=\"BENE_BIRTH_DATE\" type=\"text\" name=\"BENE_BIRTH_DATE\"  maxlength=\"23\" data-bind=\"model\">\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_BIRTH_DATE\"></span>\n    </div>\n</div>\n";
},"useData":true});