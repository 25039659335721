var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <div class=\"page-header-wrapper\">\n        <h1 class=\"landing-header\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"smbPayments.account.center",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":74}}}))
    + "</h1>\n        <div class=\"page-header\" data-region=\"header\"></div>\n    </div>\n    <section>\n        <div class=\"workbench-container\" data-region=\"workbench\"></div>\n    </section>\n</div>\n";
},"useData":true});