var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"checkbox\">\n            <input id=\"week-saturday-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":27,"column":37},"end":{"line":27,"column":44}}}) : helper)))
    + "\" type=\"checkbox\" value=\"6\">\n            <label for=\"week-saturday-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":28,"column":38},"end":{"line":28,"column":45}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"scheduler.saturday",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":47},"end":{"line":28,"column":78}}}))
    + "</label>\n        </div>\n        <div class=\"checkbox\">\n            <input id=\"week-sunday-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":31,"column":35},"end":{"line":31,"column":42}}}) : helper)))
    + "\" type=\"checkbox\" value=\"7\">\n            <label for=\"week-sunday-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":32,"column":36},"end":{"line":32,"column":43}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"scheduler.sunday",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":45},"end":{"line":32,"column":74}}}))
    + "</label>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div>\n            <label for=\"schedule-time-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":38,"column":38},"end":{"line":38,"column":45}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"scheduler.atTime",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":47},"end":{"line":38,"column":76}}}))
    + "</label>\n            <input type=\"text\" id=\"schedule-time-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":39,"column":49},"end":{"line":39,"column":56}}}) : helper)))
    + "\" class=\"form-control\" data-bind=\"model\"\n                   name=\"atTime\" placeholder=\"HH:MM\"/>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"form-group clearfix\">\n    <p class=\"static-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"scheduler.on",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":28},"end":{"line":2,"column":53}}}))
    + "</p>\n\n    <div class=\"checkbox\">\n        <input id=\"week-monday-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":38}}}) : helper)))
    + "\" type=\"checkbox\" value=\"1\">\n        <label for=\"week-monday-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":6,"column":32},"end":{"line":6,"column":39}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"scheduler.monday",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":41},"end":{"line":6,"column":70}}}))
    + "</label>\n    </div>\n    <div class=\"checkbox\">\n        <input id=\"week-tuesday-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":9,"column":32},"end":{"line":9,"column":39}}}) : helper)))
    + "\" type=\"checkbox\" value=\"2\">\n        <label for=\"week-tuesday-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":10,"column":33},"end":{"line":10,"column":40}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"scheduler.tuesday",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":42},"end":{"line":10,"column":72}}}))
    + "</label>\n    </div>\n    <div class=\"checkbox\">\n        <input id=\"week-wednesday-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":13,"column":34},"end":{"line":13,"column":41}}}) : helper)))
    + "\" type=\"checkbox\" value=\"3\">\n        <label for=\"week-wednesday-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":14,"column":35},"end":{"line":14,"column":42}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"scheduler.wednesday",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":44},"end":{"line":14,"column":76}}}))
    + "</label>\n    </div>\n    <div class=\"checkbox\">\n        <input id=\"week-thursday-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":17,"column":33},"end":{"line":17,"column":40}}}) : helper)))
    + "\" type=\"checkbox\" value=\"4\">\n        <label for=\"week-thursday-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":18,"column":34},"end":{"line":18,"column":41}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"scheduler.thursday",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":43},"end":{"line":18,"column":74}}}))
    + "</label>\n    </div>\n    <div class=\"checkbox\">\n        <input id=\"week-friday-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":21,"column":31},"end":{"line":21,"column":38}}}) : helper)))
    + "\" type=\"checkbox\" value=\"5\">\n        <label for=\"week-friday-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":22,"column":32},"end":{"line":22,"column":39}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"scheduler.friday",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":41},"end":{"line":22,"column":70}}}))
    + "</label>\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"weekendsAllowed") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":4},"end":{"line":34,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"canSetTime") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":4},"end":{"line":42,"column":11}}})) != null ? stack1 : "")
    + "\n    <span class=\"help-block\" data-bind=\"model\" data-validate=\"onN\"></span>\n</div>\n";
},"useData":true});