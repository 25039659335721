var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button class=\"btn "
    + alias4(((helper = (helper = lookupProperty(helpers,"btnClass") || (depth0 != null ? lookupProperty(depth0,"btnClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnClass","hash":{},"data":data,"loc":{"start":{"line":21,"column":35},"end":{"line":21,"column":47}}}) : helper)))
    + "\"\n                    data-action=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"btnAction") || (depth0 != null ? lookupProperty(depth0,"btnAction") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnAction","hash":{},"data":data,"loc":{"start":{"line":22,"column":33},"end":{"line":22,"column":46}}}) : helper)))
    + "\"\n                    name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"btnAction") || (depth0 != null ? lookupProperty(depth0,"btnAction") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnAction","hash":{},"data":data,"loc":{"start":{"line":23,"column":26},"end":{"line":23,"column":39}}}) : helper)))
    + "\"\n                    type=\"button\">\n                    <span class=\"txt\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"btnText") || (depth0 != null ? lookupProperty(depth0,"btnText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnText","hash":{},"data":data,"loc":{"start":{"line":25,"column":38},"end":{"line":25,"column":49}}}) : helper)))
    + "</span>\n                </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"setup.back.to.previous",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":144},"end":{"line":4,"column":179}}}))
    + "</span></a>\n    </div>\n\n    <h1 class=\"landing-header\">"
    + alias2((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||container.hooks.helperMissing).call(alias1,"title",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":7,"column":31},"end":{"line":7,"column":52}}}))
    + "</h1>\n\n    </div>\n<div class=\"alert-message\">\n    <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n</div>\n\n<div data-region=\"pageContent\"></div>\n<div data-region=\"gridContent\"></div>\n\n<div class=\"section section-summary\">\n    <div class=\"section-body\">\n        <div class=\"widget-action-btn-group\">       \n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"buttons") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":27,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"useData":true});