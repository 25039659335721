import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import services from 'services';
import transform from 'common/util/transform';
import http from '@glu/core/src/http';

export default Collection.extend({
    sync(method, model, options) {
        if (method === 'read') {
            return http.post(
                services.generateUrl('/inquiry/getData'),
                {
                    requestHeader: {},

                    inquiryRequest: {
                        searchCriteria: {
                            inquiryId: 20690,
                            searchType: '5',
                        },
                    },
                },
                (result) => {
                    options.success(result.inquiryResponse.rows);
                },
                (result) => {
                    options.error(result);
                },
            );
        }
        return undefined;
    },

    parse(rows) {
        const methods = [];
        util.each(rows, (row) => {
            const hash = transform.pairsToHash(row.columns, 'fieldName', 'fieldValue');

            methods.push({
                name: hash.DELIVERYMETHOD,
                label: hash.DELIVERYMETHODLABEL,
            });
        });

        return methods;
    },
});
