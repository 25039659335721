import locale from '@glu/locale';
import Layout from '@glu/core/src/layout';
import FlexDropdown from '@glu/flex-dropdown';
import template from './editHoldView.hbs';

export default Layout.extend({
    template,

    initialize(options) {
        // passed in options that will interact with the related Editable Grid
        this.editableGridView = options.editableGridView;
        /*
         * handler to toggle on manual edits for hold and showing checkboxes (may
         * need to move this)
         */
        this.manualEditToggle = options.manualEditToggle;
    },

    onRender() {
        const options = {
            data: [{
                id: 1,
                name: locale.get('action.edit'),
            }, {
                id: 2,
                name: locale.get('common.hold.all'),
            }, {
                id: 3,
                name: locale.get('ACH.unhold.all'),
            }],

            disableMultiButton: true,
            showTooltip: false,
            label: locale.get('ACH.edit.hold'),
        };

        this.holdOptions = new FlexDropdown(options);

        this.listenTo(this.holdOptions, 'selectionChanged:id', this.onOptionSelect);

        this.optionsDropdownRegion.show(this.holdOptions);

        this.holdOptions.ui.dropdown[0].setAttribute('role', 'button');
        this.holdOptions.ui.dropdown[0].setAttribute('aria-label', locale.get('ACH.edit.hold'));
    },

    /**
     *  @method onOptionSelect
     *  @param {array} selected - on select return thats an array of id's
     *      Will trigger a callback edit method based on the option id selected
     * in dropdown
     */
    onOptionSelect(selected) {
        const [id] = selected;
        switch (id) {
        case 1:
            // triggers manual edit mode toggle for grid (passed in options)
            return this.manualEditToggle(true);
        case 2:
            // triggers hold All
            return this.triggerBulkHold(true);
        case 3:
            // triggers unhold all
            return this.triggerBulkHold(false);
        default:
        }
        return undefined;
    },

    /**
     *  @method triggerBulkHold
     *  @param {boolean} holdAll
     *  Triggers a bulk update to either apply Hold or remove Hold from all rows
     *   based on holdAll flag passed in
     */
    triggerBulkHold(holdAll) {
        const bulkHoldRequest = {
            action: 'bulkUpdate',
            actionType: 'HOLD',
            value: (holdAll) ? 1 : 0,
        };

        this.trigger('bulkUpdateRequest', bulkHoldRequest);
    },
});
