var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "\n<div class=\"row\">\n\n    <div class=\"col-4\">\n        <fieldset>\n            <legend class=\"sr-only\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"uce.entitledReports",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":36},"end":{"line":15,"column":68}}}))
    + "</legend>\n            <div data-region=\"entitlementsRegion\"></div>\n        </fieldset>\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasGrid") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":46,"column":11}}})) != null ? stack1 : "")
    + "\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"checkbox\">\n        <input type=\"checkbox\" id=\"selectAllReports-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":6,"column":52},"end":{"line":6,"column":59}}}) : helper)))
    + "\" class=\"select-all-reports\">\n        <label for=\"selectAllReports-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":7,"column":37},"end":{"line":7,"column":44}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"administration.all.reports",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":46},"end":{"line":7,"column":85}}}))
    + "</label>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"col-8\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":24},"end":{"line":30,"column":35}}})) != null ? stack1 : "")
    + "            <div class=\"assign-all-msg\">\n                "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"assignAllMsg") || (depth0 != null ? lookupProperty(depth0,"assignAllMsg") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"assignAllMsg","hash":{},"data":data,"loc":{"start":{"line":32,"column":16},"end":{"line":32,"column":32}}}) : helper)))
    + "\n            </div>\n\n            <div data-hook=\"grid-section\">\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":16},"end":{"line":41,"column":27}}})) != null ? stack1 : "")
    + "                <div data-region=\"gridRegion\"></div>\n\n            </div>\n        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"checkbox assign-all-checkbox\">\n                        <input id=\"assignAllChk-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":24,"column":48},"end":{"line":24,"column":55}}}) : helper)))
    + "\" type=\"checkbox\" data-hook=\"assign-all-checkbox\">\n                        <label for=\"assignAllChk-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":25,"column":49},"end":{"line":25,"column":56}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"assignAllCheckbox") || (depth0 != null ? lookupProperty(depth0,"assignAllCheckbox") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"assignAllCheckbox","hash":{},"data":data,"loc":{"start":{"line":25,"column":58},"end":{"line":25,"column":79}}}) : helper)))
    + "</label>\n                            <a data-toggle=\"popover\" data-trigger=\"hover focus\" title=\"\" role=\"button\" data-placement=\"auto\" data-content=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"assignAllTooltip") || (depth0 != null ? lookupProperty(depth0,"assignAllTooltip") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"assignAllTooltip","hash":{},"data":data,"loc":{"start":{"line":26,"column":139},"end":{"line":26,"column":159}}}) : helper)))
    + "\">\n                                <span class=\"icon-info\"></span>\n                            </a>\n                    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-tertiary right\" data-action=\"openEditItemsDialog\">\n                        <span class=\"icon-pencil\"></span> "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"lookupText") || (depth0 != null ? lookupProperty(depth0,"lookupText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"lookupText","hash":{},"data":data,"loc":{"start":{"line":39,"column":58},"end":{"line":39,"column":72}}}) : helper)))
    + "\n                    </button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "   \n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":4},"end":{"line":58,"column":15}}})) != null ? stack1 : "")
    + "\n    <div data-region=\"permissionsRegion\"></div>\n\n</div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"checkbox\">\n            <input type=\"checkbox\" id=\"selectAllPermissions-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":55,"column":60},"end":{"line":55,"column":67}}}) : helper)))
    + "\" class=\"select-all-perms\">\n            <label for=\"selectAllPermissions-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":56,"column":45},"end":{"line":56,"column":52}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"uce.assignAll",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":56,"column":54},"end":{"line":56,"column":80}}}))
    + "</label>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h3 class=\"report-group-heading\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"reportGroup") || (depth0 != null ? lookupProperty(depth0,"reportGroup") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"reportGroup","hash":{},"data":data,"loc":{"start":{"line":1,"column":33},"end":{"line":1,"column":48}}}) : helper)))
    + "</h3>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPA") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":49,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPA") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":0},"end":{"line":63,"column":7}}})) != null ? stack1 : "");
},"useData":true});