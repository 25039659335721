import constants from 'common/dynamicPages/api/constants';
import FormattedCell from 'common/dynamicPages/views/gridCells/formattedCell';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import util from '@glu/core/src/util';
import $ from 'jquery';
import mobileUtil from 'mobile/util/mobileUtil';
import template from './shortenedCell.hbs';

export default FormattedCell.extend({
    template,

    ui: {
        $popovers: '[data-toggle="popover"]',
    },

    events: {
        'click [data-hook="expand-button"]': 'handleClick',
    },

    initialize() {
        this.fullValue = this.model.get(this.options.column.get('fieldName'));
        const maxLengthConfigParam = parseInt(serverConfigParams.get('TransactionDetailMaxColumnWidth'), 10);
        this.maxLength = util.isNaN(maxLengthConfigParam)
            ? constants.COLUMN_MAX_CHARACTERS
            : maxLengthConfigParam;
        if (this.fullValue.length > this.maxLength) {
            this.hasPopup = true;
            // Only add event lister if there are truncated values
            this.onColumnWidthChange();
        }
        this.setInitialCellWidth();
    },

    setInitialCellWidth() {
        if (!mobileUtil.isMobileGridEnabled()) {
            this.listenTo(this.options.grid, 'grid:contentRendered', () => {
                this.initialCellWidth = this.$el.width();
                this.shouldTruncate();
            });
        }
    },

    /**
     * Get content width
     */
    getFullContentWidth() {
        const $text = this.$el.find('span').html(this.fullValue);
        $text.css('overflow', 'visible');
        const fullValueLength = $text.width();
        $text.css('overflow', 'hidden');
        return fullValueLength;
    },

    /**
     * Helper method to override truncated
     * content after grid rendering completed
     */
    shouldTruncate() {
        const fullValueLength = this.getFullContentWidth();
        this.truncate(fullValueLength > this.initialCellWidth);
        this.$('.shortened-cell span:first').html(this.getFormattedValue());
    },

    /**
     * Listen to changes in column width
     */
    onColumnWidthChange() {
        if (!mobileUtil.isMobileGridEnabled()) {
            this.listenTo(this.options.grid, 'grid:columnResizeComplete', () => {
                const contentWidth = this.getFullContentWidth();
                if (contentWidth > this.initialCellWidth && this.hasPopup) {
                    this.truncate(true);
                } else if (contentWidth < this.initialCellWidth && !this.hasPopup) {
                    this.truncate(false);
                }
            });
        }
    },

    /**
     * Helper method to truncate/remove truncate
     * @param {boolean} hasPopup - A flag on values with
     * more than max chars
     */
    truncate(hasPopup) {
        if (this.canBeTruncated()) {
            this.hasPopup = hasPopup;
            this.render();
        }
    },

    /**
     * Used to re-render only values with `hasPopup` flag
     * for performance
     * @returns {boolean}
     */
    canBeTruncated() {
        return this.hasPopup !== undefined;
    },

    onRender() {
        this.ui.$popovers.popover().click((e) => {
            $('body').on(
                'click',
                {
                    closeToolTip: this.closeToolTip,
                },
                this.closeToolTip,
            );

            $('.toolTip-btn-popover').each(function () {
                // hide any other open popovers
                if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                    $(this).popover('hide');
                }
            });
            if ($('.popover').has(e.target).length === 0) {
                this.ui.$popovers.popover('show');
            }

            e.preventDefault();
        });

        this.$el.attr('data-title', this.options.value);
    },

    closeToolTip(event) {
        // Close the popover if click out side of the trigger button or popover
        if (!$('.toolTipCell-icon').is(event.target) && $('.popover').has(event.target).length === 0) {
            $('[data-toggle="popover"]').popover('hide');
            $('body').off('click', event.data.closeToolTip);
            $('.popover').remove();
        }
    },

    getFormattedValue() {
        if (this.hasPopup) {
            return `${this.fullValue.slice(0, this.maxLength)}...`;
        }
        return this.fullValue;
    },

    templateHelpers() {
        const self = this;
        return {
            formattedValue: self.getFormattedValue(),
            value: self.fullValue,
            hasToolTip: self.hasPopup,
        };
    },
});
