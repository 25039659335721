import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import Model from '@glu/core/src/model';
import dialog from '@glu/dialog';
import exportWireReportListViewTmpl from './exportWireReportListView.hbs';

const ExportWireTransModel = Model.extend({

});

const ExportWireReportListView = ItemView.extend({
    template: exportWireReportListViewTmpl,
    modalClass: 'modal-lg',

    initialize(options) {
        this.model = new ExportWireTransModel();

        this.model.set({
            selection: 'all',
            enableSel: options.enableSel,
            enableAll: options.enableAll,
        });

        this.dialogTitle = options.title;

        this.dialogButtons = [{
            text: options.mode === 'export' ? locale.get('payment.export') : locale.get('payment.print'),
            className: 'btn btn-primary',
            callback: options.mode,
        }, {
            text: locale.get('payment.cancel'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }];
    },

    templateHelpers() {
        return {
            toggleSelectionRadio(model, enableFlag) {
                if (enableFlag) {
                    return '';
                }
                return 'disabled';
            },

            getMode: (this.options.mode === 'export') ? locale.get('payment.export') : locale.get('payment.print'),
            cid: this.cid,
        };
    },

    export() {
        dialog.close();
        this.trigger('exportWireTrans', this.model);
    },

    print() {
        dialog.close();
        this.trigger('printWireTrans', this.model);
    },

    cancel() {
        dialog.close();
    },
});

export default ExportWireReportListView;
