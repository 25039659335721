var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n\n<p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.you.have",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":3},"end":{"line":3,"column":33}}}))
    + " <strong data-hook=\"scenarioCount\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"scenarioCount") || (depth0 != null ? lookupProperty(depth0,"scenarioCount") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"scenarioCount","hash":{},"data":data,"loc":{"start":{"line":3,"column":68},"end":{"line":3,"column":85}}}) : helper)))
    + "</strong> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.activities",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":95},"end":{"line":3,"column":127}}}))
    + ".</p>\n        <p class=\"hint\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.activities.drag",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":24},"end":{"line":4,"column":61}}}))
    + "</p>\n\n        <div class=\"scenario-list\"></div>\n\n        <button class=\"btn btn-link btn-show-add-new-scenario edit-mode hidden\">\n                <span class=\"icon-add-circle\"></span>\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.activities.add",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":10,"column":52}}}))
    + "\n        </button>\n";
},"useData":true});