import util from '@glu/core/src/util';
import ConfirmPayment from 'app/smbPayments/views/modals/confirmPayment';

export default ConfirmPayment.extend({
    templateHelpers(...args) {
        const base = ConfirmPayment.prototype.templateHelpers.apply(this, args);

        return util.extend(
            {},
            base,
            {
                isPayment: false,
                showExchangeRate: false,
                contractIdIsChecked: false,
            },
        );
    },
});
