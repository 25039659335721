import { createUseStyles } from '@glu/theming';

const styles = ({ palette, typography }) => ({
    root: {
        marginBottom: 35,
        display: 'flex',
        flexDirection: 'column',
        '& > h4': {
            ...typography.h4,
            margin: [10, 20],
        },
        '& svg': {
            fill: '#0074bd',
        },
    },

    document: {
        flexGrow: 1,
        overflow: 'auto',
    },

    hideDocument: {
        display: 'none',
    },

    downloadButton: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    carousel: {
        display: 'flex',
        justifyContent: 'center',
        '& button': {
            fontSize: 0,
            margin: [0, 4],
        },
    },

    inactive: {
        '& svg': {
            fill: palette.accent,
        },
    },

    circle: {
        '& svg': {
            width: 10,
        },
    },

    arrow: {
        '& svg': {
            width: 10,
        },
    },
    tooltip: {
        fontSize: 12,
        padding: [0, 15],
    },
});

export default createUseStyles(styles);
