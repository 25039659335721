var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = (lookupProperty(helpers,"panel")||(depth0 && lookupProperty(depth0,"panel"))||alias2).call(alias1,{"name":"panel","hash":{"theme":"panel-primary","collapsed":true,"locale":"administration.payment.types"},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":11,"column":14}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"panel")||(depth0 && lookupProperty(depth0,"panel"))||alias2).call(alias1,{"name":"panel","hash":{"theme":"panel-primary","collapsed":true,"locale":"administration.limit.approval"},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":23,"column":14}}})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.payment.types.select.group",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":11},"end":{"line":5,"column":65}}}))
    + "</p>\n\n        "
    + alias2(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"textVisible":true,"className":"btn btn-tertiary","action":"editPaymentTypes","locale":"administration.payment.types.edit","name":"pencil"},"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":146}}}))
    + "\n\n        <div data-region=\"paymentTypesRegion\" class=\"payment-Types-Region\"></div>\n\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":18,"column":19}}})) != null ? stack1 : "")
    + "\n        "
    + container.escapeExpression(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"textVisible":true,"className":"btn btn-tertiary","action":"editPaymentLimits","locale":"administration.limits.edit","name":"pencil"},"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":20,"column":140}}}))
    + "\n\n        <div data-region=\"approvalLimitsRegion\"></div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.limit.overall.approval",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":16,"column":66}}}))
    + "</h4>\n            <p> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.limit.overall.payment.add",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":17,"column":69}}}))
    + "</p>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n    <h3>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.payment.types.approval.limits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":8},"end":{"line":27,"column":65}}}))
    + "</h3>\n    <div data-region=\"approvalLimitsRegion\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":29,"column":11}}})) != null ? stack1 : "")
    + "\n<div data-region=\"assignedPermissionsRegion\" class=\"assigned-permissions-region\"></div>\n\n<div data-region=\"restrictedTemplatesRegion\" class=\"restricted-templates-region\"></div>\n";
},"useData":true});