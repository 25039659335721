import locale from '@glu/locale';
// import ptxRouteManager from '@glu/core/src/routeManager'; // glu/core 3.2.1
// glu/core 2.1.1 version
import { isDeepLinked } from 'common/util/deeplinkUtil';
import ptxRouteManager from 'ptx-connect/node_modules/@glu/core/src/routeManager';
import ptxReactRouteManager from '@glu/core/src/routeManager';
import configuration from 'system/configuration';
import 'ptx/system/templateHelpers'; // runApplication.js not used, so we need to import it. TODO - not sure
import integrationUtils from 'ptx/system/integrationUtils'; // must be BEFORE routes - TODO fix it better
import integrationUtilsPtxReact from 'ptxReact/system/integrationUtils'; // must be BEFORE routes - TODO fix it better
import routes from 'ptx/system/routes';
import customizeLocales from './customizeLocales';


// After PCM-1769 we got rid of "Secure Check Printing" (which used PAYMENTS/documents)
// After PCM-1484 required to change to ADMINSTRATION/documents
const pcmBaseRoute = configuration.isClient() ? `/${isDeepLinked() ? 'ui-portal' : 'ui'}/ADMINSTRATION/documents/` : '/ui-admin-portal/ADMINSTRATION/documents/';

// This function is written correct, but doesn't affect later on the routes object.
export function initPtxConnectLocale(/* options */) {
    // TODO - seems not affecting
    // integrationUtils.appendRouteUrlProcessor(url => `${options.ptxConnectUrl}/${url}`);

    // doesn't work - doesn't affect the routes object (because it's different singleton).
    integrationUtils.setRoutesRoot(pcmBaseRoute);
    integrationUtilsPtxReact.setRoutesRoot(pcmBaseRoute);

    // For hbs/templates url from helper {{route}}
    // routeManager.setRoot(routes.root).registerRoutes(routes.routes); // doesn't work anyhow.
    ptxRouteManager.setRoot(pcmBaseRoute).registerRoutes(routes.routes);
    ptxReactRouteManager.setRoot(pcmBaseRoute).registerRoutes(routes.routes);

    // still doesn't affect deep PT-X Connect boolean flag
    integrationUtils.setDisableAnalytics(true);

    locale.initBundles({
        i18n(bundleAcceptingCallback) {
            bundleAcceptingCallback({
                strings: customizeLocales(), // it matters.
            });
        },
    });

    locale.fetch('i18n');
}

export default {
    initPtxConnectLocale,
};
