/**
 * Inspired by jquery.param() it basically encodes parameters for
 * inclusion in a query string without requiring jquery.
 *
 * @param parameters {object} key/value pair parameter hash.
 */
export const encodeParameters = (parameters = {}) => (
    Object.keys(parameters)
        .filter(key => parameters[key] !== null && parameters[key] !== undefined)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(parameters[key])}`).join('&')
);

const defaultProcessBSLUrl = encodedUrl => `/nextx/bsl?url=${encodedUrl}`;

let processBslUrlFunction = defaultProcessBSLUrl;

export function setProcessBslUrlFunction(urlProcessFunction) {
    if (typeof urlProcessFunction === 'function') {
        processBslUrlFunction = urlProcessFunction;
    }
}

export function createBSLUrl(url, parameters) {
    const encodedUrl = encodeURIComponent(url + (parameters ? (`?${encodeParameters(parameters)}`) : ''));
    return processBslUrlFunction(encodedUrl);
}

export function urlParamsToJson() {
    const query = window.location.search.substr(1);

    return query.split('&').reduce((result, part) => {
        const item = part.split('=');
		result[item[0]] = decodeURIComponent(item[1]); // eslint-disable-line
        return result;
    }, {});
}
