import Layout from '@glu/core/src/layout';
import mobileDrawerTmpl from './mobileDrawer.hbs';

export default Layout.extend({
    template: mobileDrawerTmpl,
    className: 'MobileDrawer',

    events: {
        'click [data-hook="getReturnButton"]': 'closeDrawer',
    },

    initialize(options) {
        this.contentView = new options.ContentView({
            model: this.model,
        });

        this.listenTo(this.contentView, 'close:drawer', this.closeDrawer.bind(this));
    },

    templateHelpers() {
        return {
            returnLabel: this.options.returnLabel,
        };
    },

    onRender() {
        this.contentRegion.show(this.contentView);
    },

    /**
     * Close this view
     */
    closeDrawer() {
        this.close();
    },
});
