import store from 'system/utilities/cache';

let viewSelected;
let manageSelected;

// Potential place for the actual privilege checks for PMX=>DGB "Contact Options" feature.
const contactOptionsPrivilegesMap = {
    view: () => {
        if (viewSelected) {
            return viewSelected;
        }
        const currentPrivileges = store.get('PMX_PRIV') || { entitlements: [] };
        const hasPrivilege = currentPrivileges.entitlements.some(privilege => privilege.typeCode === 'PMXPYCT'
            && privilege.actionMode === 'SELECT'
            && privilege.entitled === true);
        viewSelected = hasPrivilege;
        return hasPrivilege;
    },

    manage: () => {
        if (manageSelected) {
            return manageSelected;
        }
        const currentPrivileges = store.get('PMX_PRIV') || { entitlements: [] };
        const hasPrivilege = currentPrivileges.entitlements.some(privilege => privilege.typeCode === 'PMXPYCT'
            && privilege.actionMode === 'MODIFY'
            && privilege.entitled === true);
        manageSelected = hasPrivilege;
        return hasPrivilege;
    },
};

export default contactOptionsPrivilegesMap;
