import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import alert from '@glu/alerts';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import ListView from 'common/dynamicPages/views/workflow/list';
import store from 'system/utilities/cache';
import constants from 'app/administration/constants';
import NotificationModel from 'app/administration/models/notification';
import services from 'services';
import gridApi from 'common/dynamicPages/api/grid';
import template from 'app/administration/views/notificationManagement/notificationList.hbs';
import loadingTemplate from 'common/templates/loadingPage.hbs';

export default ListView.extend({
    template,
    loadingTemplate,

    ui: {
        $addNotification: '[data-hook="addNotification"]',
        $export: '[data-hook="export-button"]',
        $print: '[data-hook="print-button"]',
    },

    regions: {
        notificationRegion: 'div[data-region="notificationRegion"]',
    },

    events: util.extend(
        {},
        ListView.prototype.events,
        {
            'click @ui.$addNotification': 'addNotification',
        },
    ),

    initialize(options) {
        const superOptions = {
            menuCategory: 'CMAINT',
            serviceName: this.options.serviceName,
            serviceFunc: null,
            businessType: null,
            context: 'CMAINT_CGRPB_TM',
            returnRoute: options.returnRoute,
        };

        this.inquiryId = '29067';

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.notificationRegion.show(this.gridView);
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
            if (store.get('notification:success')) {
                this.renderMessage(store.get('notification:success'));
                store.unset('notification:success');
            }
        } else {
            this.isInsertEntitled();
        }
    },

    gridRowAction(optionsParam) {
        const options = optionsParam;
        const self = this;
        const action = options.action.toUpperCase();
        let notification;

        options.returnRoute = this.options.returnRoute;

        return new Promise((resolve, reject) => {
            if (action === constants.DELETE) {
                notification = new NotificationModel({
                    TNUM: options.model.get('TNUM'),
                    serviceName: this.options.serviceName,
                });

                dialog.confirm(locale.get('tableMaintenance.dialog.confirm.item.delete'), locale.get('tableMaintenance.dialog.confirm.title.delete'), (ok) => {
                    if (ok) {
                        notification.destroy({
                            success(result) {
                                self.gridView.refreshGridData();
                                self.renderMessage(result.message
                                    ? result.message[0] : notification.message[0]);
                                resolve({ result });
                            },

                            error(e) {
                                self.gridView.refreshGridData();
                                reject(e);
                            },
                        });
                    }
                });
            } else if (action === constants.CREATENOTIFICATION
                || action === constants.CREATETEMPLATE) {
                options.serviceName = this.options.serviceName;
                /*
                 * copy from message read service when not template workflow.
                 * copy from template read service otherwise
                 */
                options.isCopyFromMessage = !this.isTemplateWorkflow;
                store.set('bannerNotifications:read', options);
                this.navigateTo(action === 'NTEMPLATE' ? 'CMAINT/manageNotifications' : 'CMAINT/manageNotificationTemplates');
                resolve();
            } else if (action === 'ARCHIVE') {
                notification = new NotificationModel({
                    TNUM: options.model.get('TNUM'),
                    serviceName: this.options.serviceName,
                });

                dialog.confirm(locale.get('tableMaintenance.dialog.confirm.item.archive'), locale.get('tableMaintenance.dialog.confirm.title.archive'), (ok) => {
                    if (ok) {
                        notification.archive({
                            success(result) {
                                self.gridView.refreshGridData();
                                self.renderMessage(result.message
                                    ? result.message[0] : notification.message[0]);
                                resolve({ result });
                            },

                            error(e) {
                                self.gridView.refreshGridData();
                                reject(e);
                            },
                        });
                    }
                });
            } else {
                store.set('bannerNotifications:read', options);
                this.navigateTo(this.options.navigationUrl);
                resolve();
            }
        });
    },

    renderMessage(message) {
        this.alertView = alert.success(
            message,
            {
                canDismiss: true,
                animate: true,
            },
        );
        this.alertRegion.show(this.alertView);
    },

    isInsertEntitled() {
        const self = this;
        const url = services.generateUrl('accessService/hasAccess');

        const request = {
            functionCode: 'MAINT',
            actionMode: 'INSERT',
            productCode: 'CMAINT',
            typeCode: this.options.typeCode,
        };

        http.post(url, request, (response) => {
            self.isEntitled = response;
            self.loadViewRequirements();
        });
    },

    /**
     * @method addNotification
     * Unset stale model info data and navigate to notification entry screen
     */
    addNotification() {
        store.unset('notification:success');
        store.unset('bannerNotifications:read');
        store.set('bannerNotifications:returnRoute', this.options.returnRoute);
        this.navigateTo(this.options.navigationUrl);
    },

    loadViewRequirements() {
        const options = this.viewOptionRequirements();
        options.selector = 'none';
        this.gridView = gridApi.createServiceGridView(options);
        ListView.prototype.setupGridAvailableListener.call(this);
        this.setHasLoadedRequiredData(true);
        this.render();
    },

    buildExportModel(options) {
        return util.extend(
            ListView.prototype.buildExportModel.call(this, options),
            {
                inquiryId: this.inquiryId,
            },
        );
    },

    templateHelpers() {
        return {
            isAddEntitled: this.isEntitled,
            getLocaleString: 'common.addNotification',
        };
    },
});
