function getEntitlementsOptions(typeCode) {
    return {
        typeCode,
        productCode: 'SMAINT',
        functionCode: 'MAINT',
        entryMethod: 0,
        overrideContext: {
            serviceName: '/userMaintenance/user',
        },
    };
}

export const paymentExtractConfigEntitlementsOptions = getEntitlementsOptions('PEXT_CFG');

export const paymentExtractConfigEntitlements =
    [paymentExtractConfigEntitlementsOptions];

