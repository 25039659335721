import React from 'react';
import PropTypes from 'prop-types';
import Accordion, { AccordionPanel, AccordionPanelContent } from '@glu/accordion-react';
import { withStyles } from '@glu/theming';
import FilterAccordionHeader from './FilterAccordionHeader';
import Label from '../Label/Label';

const styles = {
  accordionContent: {
    lineHeight: '19px',
    overflow: 'inherit',
    paddingLeft: 26
  },

  accordionPanel: {
    width: '100%'
  },

  root: {
    display: 'flex',
    flex: 1,
    width: '100%'
  }
};

const FilterAccordionWrapper = ({
  FilterComponent, className, classes, dark, fieldId, hasFilter,
  hiddenField, htmlId, isOpen, label, onClearFilter,
  ...rest // everything not used directly is passed on to the filter component
}) => (
  <Accordion className={`${classes.root} ${className}`}>
    <AccordionPanel panelId={htmlId} isOpen={isOpen} className={classes.accordionPanel}>
      <FilterAccordionHeader
        qaRoot={htmlId}
        fieldId={fieldId}
        label={label}
        hasFilter={hasFilter}
        onClearFilter={onClearFilter}
        hiddenField={hiddenField}
        dark={dark}
      />
      <AccordionPanelContent className={classes.accordionContent}>
        <Label htmlFor={htmlId} hidden>{label}</Label>
        <FilterComponent
          htmlId={htmlId}
          fieldId={fieldId}
          dark={dark}
          {...rest}
        />
      </AccordionPanelContent>
    </AccordionPanel>
  </Accordion>
);

FilterAccordionWrapper.propTypes = {

  /** The actual filter component */
  FilterComponent: PropTypes.func.isRequired,

  /** ClassName to use for customization */
  className: PropTypes.string,

  /** Classes for JSS styling */
  classes: PropTypes.objectOf(PropTypes.string).isRequired,

  /** True if the filter should be in dark mode which styles it for dark colored forms */
  dark: PropTypes.bool,

  /** Unique identifier for the field of data you are filtering on */
  fieldId: PropTypes.string.isRequired,

  /** Indicates there is currently a filter present for the field */
  hasFilter: PropTypes.bool,

  /** Indicates if the hidden field indicator should show for this filter */
  hiddenField: PropTypes.bool,

  /** String to use as htmlId for this filter */
  htmlId: PropTypes.string.isRequired,

  /** True if the accordion should be opened by default */
  isOpen: PropTypes.bool,

  /** Label to display for the filter */
  label: PropTypes.string.isRequired,

  /** Function to call whenever the filter has been cleared. */
  onClearFilter: PropTypes.func.isRequired
};

FilterAccordionWrapper.defaultProps = {
  className: '',
  dark: true,
  hasFilter: false,
  hiddenField: false,
  isOpen: false
};

export default React.memo(withStyles(styles)(FilterAccordionWrapper));
