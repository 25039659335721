/**
 * Function to set the hide key of a specific column.
 * Does not adjust the hide key's value if showHideDisabled is true.
 *
 * @param {Object} options
 * @param {Object} options.columns - all the columns
 * @param {string} options.field - unique identifier for a column to be hidden
 * @param {bool} options.showAll - state of the show/hide all ui
 */
export function setHidden({ columns, field, showAll }) {
  return columns.map((column) => {
    let hide = field && field === column.field ? !column.hide : column.hide;
    hide = !field ? showAll : hide;
    hide = column.showHideDisallowed ? column.hide : hide;
    return {
      ...column,
      hide
    };
  });
}

export function setPins({ columns, field, position }) {
  return columns.map((column) => {
    let pinned = field && field === column.field ? position : column.pinned;
    pinned = !field ? null : pinned;
    return {
      ...column,
      pinned
    };
  });
}

export default setHidden;
