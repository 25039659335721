import ItemView from '@glu/core/src/itemView';
import listBuilderAccountViewTmpl from './listBuilderAccountView.hbs';

export default ItemView.extend({
    template: listBuilderAccountViewTmpl,

    initialize(options) {
        this.ops = options;
    },
});
