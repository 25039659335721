var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"accountGroupToggle\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"sections") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":32},"end":{"line":21,"column":41}}})) != null ? stack1 : "")
    + "                            </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":36},"end":{"line":20,"column":124}}})) != null ? stack1 : "")
    + "<button class=\"btn btn-tertiary "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":156},"end":{"line":20,"column":184}}})) != null ? stack1 : "")
    + "\" data-step=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":20,"column":197},"end":{"line":20,"column":203}}}) : helper)))
    + "\" data-action=\"showAccountGroup\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":20,"column":236},"end":{"line":20,"column":251}}}) : helper)))
    + "</button>"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":260},"end":{"line":20,"column":291}}})) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<strong>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"gir.manageAccountGroups.accountView",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":58},"end":{"line":20,"column":106}}}))
    + ": </strong>";
},"5":function(container,depth0,helpers,partials,data) {
    return " active";
},"7":function(container,depth0,helpers,partials,data) {
    return " | ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page bankAccountSummary\">\n    <div class=\"section section-container\">\n        <div class=\"alerts\"></div>\n                <div class=\"reporting-nav-btn-container\">\n            <div class=\"row\">\n                <div class=\"col-6\">\n                    <div class=\"account-summary-button-row\">\n                        <h3 class=\"accountGroupsTitle\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"accountGroupsTitle") || (depth0 != null ? lookupProperty(depth0,"accountGroupsTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"accountGroupsTitle","hash":{},"data":data,"loc":{"start":{"line":9,"column":55},"end":{"line":9,"column":77}}}) : helper)))
    + "</h3>\n                                        <button type=\"button\" class=\"btn btn-tertiary manage-accounts-button\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.editOption",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":110},"end":{"line":10,"column":144}}}))
    + "</button>\n                                </div>\n                </div>\n                <div class=\"col-6 text-right\">\n                    <div id=\"accountView\">\n                        "
    + alias2(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"action":"refresh-balances","icon":(depth0 != null ? lookupProperty(depth0,"refresh") : depth0),"textVisible":true,"className":"btn btn-tertiary refresh-balances-button"},"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":15,"column":141}}}))
    + "\n                        <span class=\"refresh-time\" data-hook=\"refresh-time\"></span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sections") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":23,"column":31}}})) != null ? stack1 : "")
    + "                                </div>\n                </div>\n            </div>\n        </div>\n        <div class=\"section-body\" data-hook=\"account-groups\">\n        </div>\n    </div>\n</div>\n";
},"useData":true});