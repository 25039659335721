var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"panel panel-primary\" role=\"tablist\">\n                    <div data-hook=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":13,"column":36},"end":{"line":13,"column":42}}}) : helper)))
    + "\" class=\"panel-heading\" role=\"tab\" id=\"collapseHeading"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":13,"column":96},"end":{"line":13,"column":102}}}) : helper)))
    + "\">\n                        <h2 class=\"panel-title\">\n                            <a role=\"button\" data-toggle=\"collapse\" href=\"#collapseArea"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":15,"column":87},"end":{"line":15,"column":93}}}) : helper)))
    + "\"\n                                aria-expanded=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":16,"column":47},"end":{"line":16,"column":85}}})) != null ? stack1 : "")
    + "\"\n                                aria-controls=\"collapseArea"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":17,"column":59},"end":{"line":17,"column":65}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(data && lookupProperty(data,"first")),{"name":"unless","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":67},"end":{"line":17,"column":114}}})) != null ? stack1 : "")
    + "\n                                data-parent=\"#accordion-"
    + alias4(container.lambda((depths[1] != null ? lookupProperty(depths[1],"id") : depths[1]), depth0))
    + "\">\n                                <span class=\"indicator-icon\"></span> "
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":19,"column":69},"end":{"line":19,"column":75}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":19,"column":78},"end":{"line":19,"column":86}}}) : helper)))
    + "\n                            </a>\n                        </h2>\n                    </div>\n                    <div id=\"collapseArea"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":23,"column":41},"end":{"line":23,"column":47}}}) : helper)))
    + "\" data-hook=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":23,"column":60},"end":{"line":23,"column":66}}}) : helper)))
    + "\" data-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":23,"column":79},"end":{"line":23,"column":87}}}) : helper)))
    + "\"\n                        aria-expanded=\""
    + ((stack1 = (lookupProperty(helpers,"ifAnd")||(depth0 && lookupProperty(depth0,"ifAnd"))||alias2).call(alias1,(data && lookupProperty(data,"first")),((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"isDesktop")),{"name":"ifAnd","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":24,"column":39},"end":{"line":24,"column":99}}})) != null ? stack1 : "")
    + "\"\n                        class=\"panel-collapse collapse "
    + ((stack1 = (lookupProperty(helpers,"ifAnd")||(depth0 && lookupProperty(depth0,"ifAnd"))||alias2).call(alias1,(data && lookupProperty(data,"first")),((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"isDesktop")),{"name":"ifAnd","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":55},"end":{"line":25,"column":100}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"isDesktop")),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":24},"end":{"line":46,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "true";
},"4":function(container,depth0,helpers,partials,data) {
    return "false";
},"6":function(container,depth0,helpers,partials,data) {
    return "class=\"collapsed\" ";
},"8":function(container,depth0,helpers,partials,data) {
    return "in";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"panel-body\">\n                                <div class=\"btn-wrapper\">\n                                    <div class=\"btn-group widget-info-links\">\n                                        <button type=\"button\" class=\"btn btn-tertiary refresh\"\n                                            data-hook=\"refresh-button\"><span class=\"icon-refresh\"></span><span\n                                                class=\"refresh-time\" data-hook=\"refresh-time\"></span></button>\n                                    </div>\n                                </div>\n                                <div class=\"page-content\" data-region=\"grid_"
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":35,"column":76},"end":{"line":35,"column":82}}}) : helper)))
    + "\"></div>\n                                <div>\n                                    <button type=\"button\" class=\"btn btn-tertiary remove-accounts\"\n                                        data-action=\"removeAccounts\">\n                                        <span class=\"icon-minus-circle\"></span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.removeAccts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":39,"column":79},"end":{"line":39,"column":110}}}))
    + "\n                                    </button>\n                                    <button type=\"button\" class=\"btn btn-tertiary add-accounts\" data-action=\"addAccounts\">\n                                        <span class=\"icon-add-circle\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.addAccts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":42,"column":78},"end":{"line":42,"column":106}}}))
    + "\n                                    </button>\n                                </div>\n                            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"setup.user.group.account.assignment",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":79}}}))
    + "</h1>\n</div>\n<div class=\"page bankAccountSummary\">\n    <div class=\"section section-container bank-account-filters-container\">\n        <div class=\"section-header\">\n            <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"setup.user.group.plural",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":52}}}))
    + "</h2>\n        </div>\n        <div class=\"section-body\">\n            <div id=\"accordion-"
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":10,"column":31},"end":{"line":10,"column":37}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"clientUserGrps") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":49,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</div>\n<div class=\"mobileDrawerPanel\"></div>\n";
},"useData":true,"useDepths":true});