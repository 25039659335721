import services from 'services';
import { postData } from 'common/util/services';

const VALIDATE_ANSWERS = 'securityChallengeQuestions/validateQAChallenge';
const RANDOM_QUESTIONS = 'securityChallengeQuestions/getRandomChallengeQuestions';

export default {
    /**
     * Validate security question answers
     * @param {Object} data
     * @returns {Promise}
     */
    validateAnswers(data) {
        return postData(services.generateUrl(VALIDATE_ANSWERS), data);
    },
    /**
     * Get random challenge questions
     * @param {Object} data
     * @returns {Promise}
     */
    getChallengeQuestions(data) {
        return postData(services.generateUrl(RANDOM_QUESTIONS), data);
    },
};
