import Layout from '@glu/core/src/layout';
import locale from '@glu/core/src/locale';
import { createTabsToggleButton, toggleTabs, setTabButtonText } from 'common/util/a11y/tabs';
import store from 'system/utilities/cache';
import workspaceHelper from 'common/workspaces/api/helper';
import PaymodeReports from './reports';
import tpl from './pmxReportsWidget.hbs';

const PaymodeReportsWidget = Layout.extend({
    template: tpl,

    className: 'ui-widget field-container pmx-field-container',

    ui: {
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
    },

    events: {
        'click @ui.$navLinks': 'handleClick',
        'keypress @ui.$navLinks': 'handleKeyPress',
    },

    regions: {},

    isInitialRender: true,

    initialize(options = {}) {
        this.options = options;

        const currentPrivileges = store.get('PMX_PRIV') || { entitlements: [] };
        const hasAccess = currentPrivileges.entitlements.some(privilege => (
            privilege.typeCode === 'PMXRPT'
            && privilege.actionMode === 'SELECT'
            && privilege.entitled === true
        ));

        if (hasAccess) {
            this.tabViews = [
                PaymodeReports.PaymodePayerDividendsView,
                PaymodeReports.BankReconciliationView,
            ];

            this.tabs = [
                {
                    title: PaymodeReports.PaymodePayerDividendsView.prototype.tabTitle,
                    view: PaymodeReports.PaymodePayerDividendsView,
                },
                {
                    title: PaymodeReports.BankReconciliationView.prototype.tabTitle,
                    view: PaymodeReports.BankReconciliationView,
                },
            ];

            this.defaultTab = options.defaultTab || 'tabItem-0';
            this.currentTab = null;

            // setup our regions
            this.tabs.forEach((tab, idx) => {
                this.addRegion(`tabPanelRegion-${idx}`, `[data-region="tabPanelRegion-${idx}"]`);
            });

            this.shouldRender = true;
        } else {
            this.shouldRender = false;
        }
    },

    onRender() {
        if (this.shouldRender) {
            createTabsToggleButton(this);
            this.switchTab(this.defaultTab);
            this.isInitialRender = false;
        }
    },

    /**
     * Switch tabs on mouse click
     * @param {object} e - the mouse event
     */
    handleClick(e) {
        const el = e.currentTarget;
        const tabId = el.getAttribute('id');
        this.switchTab(tabId);
    },

    /**
     * Switch tabs on keyboard navigation
     * @param {object} e - the keypress event
     */
    handleKeyPress(e) {
        // enter key
        if (e.which === 13) {
            const el = e.currentTarget;
            const tabId = el.getAttribute('id');
            this.switchTab(tabId);
        }
    },

    /**
     * Switch tabs
     * @param {string} tabId - the tab id
     */
    switchTab(tabId = '') {
        const tabIndex = +tabId.substr(tabId.lastIndexOf());
        const tabPanelPrefix = 'tabPanelRegion-';
        const tabPanelId = `${tabPanelPrefix}${tabIndex}`;
        this.currentTab = tabId;
        this.ui.$navLinks.parent().removeClass('is-active');
        this.ui.$navLinks.filter(`[id=${tabId}]`).parent().addClass('is-active');

        this.currentTabText = this.ui.$navLinks.filter(`[id=${tabId}]`).text();

        this.tabs.forEach((tab, idx) => {
            const thisTabPanel = this[tabPanelId];
            if (idx === tabIndex) {
                // if the tab has already been rendered, just unhide it
                if (this[tabPanelId].$el) {
                    this[tabPanelId].$el.show();
                } else {
                    thisTabPanel.show(new this.tabViews[tabIndex]());
                }
            } else if (this[`${tabPanelPrefix}${idx}`].$el) {
                this[`${tabPanelPrefix}${idx}`].$el.hide();
            }
        });

        if (this.isInitialRender) {
            setTabButtonText(this, this.currentTabText);
        } else {
            toggleTabs(this);
        }
    },

    templateHelpers() {
        return {
            /**
             * Gets the title of the page
             * @return {string} the page title.
             */
            getPageTitle() {
                return locale.get('widget.pmx-reports.description');
            },

            /**
             * Gets the tabs
             * @return {array} the tab set
             */
            tabs: this.tabs,
        };
    },
});

export default PaymodeReportsWidget;

workspaceHelper.publishedWidgets.add({
    id: 'PMX_REPORTS_WIDGET',
    view: PaymodeReportsWidget,
    options: {},
});
