import { createUseStyles } from '@glu/theming';

const styles = () => ({
    // Override because there CSS in product to add bottom margin to all p elements
    root: {
        '& p': {
            margin: '0 0 0 3px',
        },
    },
});

export default createUseStyles(styles);
