import http from '@glu/core/src/http';
import services from 'services';
import locale from '@glu/locale';
import constants from 'common/dynamicPages/api/constants';
import util from '@glu/core/src/util';

export default function (form, initialState, resolveIBAN) {
    const { model } = form.formView;
    const $beneAccount = form.formView.$('[name="BENE_ACCOUNT"]');
    const beneAccount = form.field('BENE_ACCOUNT');
    const $beneAccountHelper = form.formView.$('[data-validate="BENE_ACCOUNT"]');
    const beneBankType = form.field('BENE_BANK_TYPE');
    const beneBankIdEntryMethod = form.field('BENEBANKIDENTRYMETHOD');
    const beneBankIdLookup = form.field('BENE_BANK_ID');
    const $beneBankIdLookup = form.formView.$('[name="BENE_BANK_ID_LOOKUP"]');

    const cleanIBANValue = function (useModel = false) {
        const account = useModel ? (model.get('BENE_ACCOUNT') || '') : beneAccount.getValue();
        return account.replace(/ /g, '');
    };

    // construct the request payload for this lookup
    const buildDataObject = function (useBranchCode) {
        if ((form.field('BENE_ACCOUNT_TYPE').getValue() === 'IBAN' || model.get('BENE_ACCOUNT_TYPE') === 'IBAN') && form.field('BENE_ACCOUNT').isNotEmpty()) {
            const bicPlusIbanForm = cleanIBANValue();
            const bicPlusIban = cleanIBANValue(true);
            let countryCode = '';
            let bankBranchCode = '';
            let branchCodeEnd = 0;
            let bankCodeEnd = 0;
            let bankCodeStart = 4;

            // Remove Space Characters
            form.field('BENE_ACCOUNT').setValue(bicPlusIbanForm);

            countryCode = bicPlusIban.substring(0, 2);

            /*
             * THE BELOW IS NOT NEEDED
             * I did a console.log test here and model.get('BENE_ACCOUNT') is the same
             * value as form.field('BENE_ACCOUNT').getValue();
             * WITH this code, the BENE_ACCOUNT field is no longer updated when choosing
             * from the contact modal thee second time. It doesn't seem to hit the first pass.
             */

            /*
             * TODO: REMOVE BELOW IN 20.05 after 20.04 regression
             * Set Model with Updated Input Value
             */

            if (util.contains(constants.IBAN_BANK_CODE_START['05'], countryCode)) {
                bankCodeStart = 5;
            } else if (util.contains(constants.IBAN_BANK_CODE_START['06'], countryCode)) {
                bankCodeStart = 6;
            } else if (util.contains(constants.IBAN_BANK_CODE_START['07'], countryCode)) {
                bankCodeStart = 7;
            }

            if (util.contains(constants.IBAN_BRANCH_CODE_END['08'], countryCode)) {
                branchCodeEnd = 8;
            } else if (util.contains(constants.IBAN_BRANCH_CODE_END['09'], countryCode)) {
                branchCodeEnd = 9;
            } else if (util.contains(constants.IBAN_BRANCH_CODE_END['10'], countryCode)) {
                branchCodeEnd = 10;
            } else if (util.contains(constants.IBAN_BRANCH_CODE_END['11'], countryCode)) {
                branchCodeEnd = 11;
            } else if (util.contains(constants.IBAN_BRANCH_CODE_END['12'], countryCode)) {
                branchCodeEnd = 12;
            } else if (util.contains(constants.IBAN_BRANCH_CODE_END['13'], countryCode)) {
                branchCodeEnd = 13;
            } else if (util.contains(constants.IBAN_BRANCH_CODE_END['14'], countryCode)) {
                branchCodeEnd = 14;
            } else if (util.contains(constants.IBAN_BRANCH_CODE_END['15'], countryCode)) {
                branchCodeEnd = 15;
            } else if (util.contains(constants.IBAN_BRANCH_CODE_END['16'], countryCode)) {
                branchCodeEnd = 16;
            } else if (util.contains(constants.IBAN_BRANCH_CODE_END['17'], countryCode)) {
                branchCodeEnd = 17;
            }

            if (useBranchCode && branchCodeEnd > 0 && branchCodeEnd > bankCodeStart) {
                bankBranchCode = bicPlusIban.substring(bankCodeStart, branchCodeEnd);
            }

            if (util.contains(constants.IBAN_BANK_CODE_END['06'], countryCode)) {
                bankCodeEnd = 6;
            } else if (util.contains(constants.IBAN_BANK_CODE_END['07'], countryCode)) {
                bankCodeEnd = 7;
            } else if (util.contains(constants.IBAN_BANK_CODE_END['08'], countryCode)) {
                bankCodeEnd = 8;
            } else if (util.contains(constants.IBAN_BANK_CODE_END['09'], countryCode)) {
                bankCodeEnd = 9;
            } else if (util.contains(constants.IBAN_BANK_CODE_END['10'], countryCode)) {
                bankCodeEnd = 10;
            } else if (util.contains(constants.IBAN_BANK_CODE_END['11'], countryCode)) {
                bankCodeEnd = 11;
            } else if (util.contains(constants.IBAN_BANK_CODE_END['12'], countryCode)) {
                bankCodeEnd = 12;
            }

            if (!useBranchCode && bankCodeEnd > 0 && bankCodeEnd > bankCodeStart) {
                bankBranchCode = bicPlusIban.substring(bankCodeStart, bankCodeEnd);
            }
            return {
                queryCriteria: {
                    action: {
                        typeCode: model.jsonData.typeInfo.typeCode,
                        entryMethod: model.get('ENTRYMETHOD'),
                        actionMode: form.formView.state.toUpperCase(),
                        productCode: model.jsonData.typeInfo.productCode,
                        functionCode: model.jsonData.typeInfo.functionCode,
                    },

                    customFilters: [{
                        filterName: 'Depends',
                        filterParam: ['COUNTRYCODE', countryCode],
                    }, {
                        filterName: 'Depends',
                        filterParam: ['BRANCHCODE', bankBranchCode],
                    }],

                    fieldName: 'BENE_ACCOUNT_LOOKUP',
                },
            };
        }
        return false;
    };

    const lockBeneBankDetails = function (bool) {
        beneBankType.shouldBeReadOnly(bool);
        if (beneBankIdEntryMethod) {
            beneBankType.shouldBeReadOnly(bool);
            beneBankIdEntryMethod.shouldBeReadOnly(bool);
        }
        beneBankIdLookup.shouldBeReadOnly(bool);

        if (bool) {
            $beneBankIdLookup.addClass('hide');
        } else {
            $beneBankIdLookup.removeClass('hide');
        }
    };

    const parseIBANTableValue = function (response) {
        if (response && response.inquiryResponse
            && response.inquiryResponse.rows[0]
            && response.inquiryResponse.rows[0].columns[0]) {
            return response.inquiryResponse.rows[0].columns[0].fieldValue;
        }
        return '0';
    };

    const getUseIBANTableValue = function () {
        const url = services.generateUrl('/inquiry/getData');

        const postData = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    searchType: '5',
                    inquiryId: 40015,
                },
            },
        };

        return http.post(url, postData);
    };

    const getRequireIBANTableValue = function () {
        const url = services.generateUrl('/inquiry/getData');

        const postData = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    searchType: '5',
                    inquiryId: 40016,
                },
            },
        };

        return http.post(url, postData);
    };

    /**
     * @method getBeneBankFromIban
     * @param {boolean} useBranchCode
     * Retrieve all Beneficiary's Bank related info to place onto the form.
     * Lookup is based on bank national ID parsed from provided IBAN. National ID
     * is either bank code or combined bank and branch code. Boolean parameter
     * determines if branch code is included as part of national ID lookup.
     */
    const getBeneBankFromIban = function (useBranchCode) {
        const data = buildDataObject(useBranchCode);
        if (!data) { // don't make an http post unless there's a proper request payload
            return;
        }

        // Determine if lookup to IBAN table is enabled
        getUseIBANTableValue().then((useResp) => {
            const useIBANTable = parseIBANTableValue(useResp);

            if (useIBANTable === '1') {
                // If IBAN lookup is enabled then perform service call
                http.post(
                    services.generateUrl(constants.URL_GETQUERYRESULTS_ACTION),
                    data,
                ).then((result) => {
                    if (result.queryResponse.QueryData.numRows > 0) {
                        /*
                         * Data returned from IBAN lookup table. Map bene bank details and
                         * lock entry fields
                         */
                        const setVals = util.chain(result.queryResponse.QueryData.queryRows)
                            // Extract the mapDataList
                            .map(row => row.mapDataList)
                            // make a single array out of all the mapDataList entries
                            .flatten(true)
                            // Accumulate all elements of the mapDataLists in a single object
                            .reduce((acc, list) => {
                                acc[list.toField] = list.value;
                                return acc;
                            }, {})
                            // Must call .value() at the end of a .chain()
                            .value();
                        // One set to avoid multiple model change events.
                        model.set(setVals);
                        // Remove previous errors and warnings, lock bene bank fields
                        $beneAccount.parent().removeClass('has-error').removeClass('has-warning');
                        $beneAccountHelper.text('');
                        lockBeneBankDetails(true);
                    } else if (useBranchCode) {
                        /*
                         * If lookup fails with bank and branch code combined,
                         * try lookup again with less restrictive bank code only.
                         */
                        getBeneBankFromIban(!useBranchCode);
                    } else {
                        /*
                         * No data returned from IBAN lookup table. Determine if the lookup
                         * is required for payment entry
                         */
                        getRequireIBANTableValue().then((reqResp) => {
                            const requireIBANTable = parseIBANTableValue(reqResp);

                            $beneAccountHelper.text(locale.get('RTGS.validate.beneIbanLookupFail'));

                            if (requireIBANTable === '1') {
                                /*
                                 * IBAN lookup is required. Set error message, lock bene
                                 * bank fields and clear bene bank ID
                                 */
                                $beneAccount.parent().addClass('has-error').removeClass('has-warning');
                                model.set('BENE_BANK_ID', '');
                                lockBeneBankDetails(true);
                            } else {
                                /*
                                 * IBAN lookup is not required. Set warning message,
                                 * unlock bene bank fields.
                                 */
                                $beneAccount.parent().removeClass('has-error').addClass('has-warning');
                                lockBeneBankDetails(false);
                            }
                        });
                    }
                });
            } else {
                lockBeneBankDetails(false);
            }
        });
    };

    const triggerBeneBankLookUp = function () {
        /*
         * defer this call so that we can gather the bene bank values after triggered
         * bank related change handlers on the form finish firing
         */
        util.defer(() => {
            getBeneBankFromIban(true);
        });
    };

    const setBeneAccount = function () {
        /*
         * On blur set Bene account value based on model instead of form
         * since form account value can be masked
         */
        model.set({
            BENE_ACCOUNT: cleanIBANValue(true),
        }, {
            silent: true,
        });
    };

    if (initialState) {
        model.off('change:BENE_ACCOUNT_TYPE', triggerBeneBankLookUp);
        model.on('change:BENE_ACCOUNT_TYPE', triggerBeneBankLookUp);
        $beneAccount.on('blur', setBeneAccount);
        $beneAccount.on('blur', triggerBeneBankLookUp);
    }

    if (resolveIBAN) {
        getBeneBankFromIban(true);
    }
}
