const routes = {
    'BANK_ACC_INFO/girAccountSummaryInquiry': 'girShowAccountSummary',
    'BANK_ACC_INFO/girPreviousDaySummaryInquiry': 'girShowPreviousDaySummary',
    'BANK_ACC_INFO/girCashPositionSummaryInquiry': 'girShowCashPositionSummary',
    'BANK_ACC_INFO/girAccountGroupSummaryInquiry': 'girShowAccountGroupSummary',
    'BANK_ACC_INFO/depositAccountsTransactionsSearch': 'showDepositTransactionsFromSearch',
    'BANK_ACC_INFO/depositAccountsTransactions': 'showDepositAccountTransactions',
    'BANK_ACC_INFO/creditCardAccountsTransactions': 'showCreditCardAccountTransactions',
    'BANK_ACC_INFO/depositPreviousDayAccountsTransactions': 'showPreviousDayDepositAccountTransactions',
    'BANK_ACC_INFO/creditCardPreviousDayAccountsTransactions': 'showPreviousDayCreditCardAccountTransactions',
    'BANK_ACC_INFO/depositCurrentDayAccountsTransactions': 'showCurrentDayDepositAccountTransactions',
    'BANK_ACC_INFO/creditCardCurrentDayAccountsTransactions': 'showCurrentDayCreditCardAccountTransactions',
    'BANK_ACC_INFO/depositCashPositionAccountsTransactions': 'showCashPositionDepositAccountTransactions',
    'BANK_ACC_INFO/loanAccountsTransactions': 'showLoanAccountTransactions',
    'BANK_ACC_INFO/loanPreviousDayAccountsTransactions': 'showPreviousDayLoanAccountTransactions',
    'BANK_ACC_INFO/loanCurrentDayAccountsTransactions': 'showCurrentDayLoanAccountTransactions',
    'BANK_ACC_INFO/tieredLoanAccountDetail': 'showTieredLoanAccountDetail',
    'BANK_ACC_INFO/tieredLoanPreviousDayAccountDetail': 'showPreviousDayTieredLoanAccountDetail',
    'BANK_ACC_INFO/tieredLoanCurrentDayAccountDetail': 'showCurrentDayTieredLoanAccountDetail',
    'BANK_ACC_INFO/wireReportSummary': 'showWireReportSummaryWorksapce',
    'REPORTING/wireReportSummary': 'showWireReportSummaryWorksapce',
    'REPORTING/wireReportSummaryWidget': 'showWireReportSummary',
    'BANK_ACC_INFO/depositAccountImage': 'showDepositSlipImage',
    'BANK_ACC_INFO/reporting': 'showReportingView',
    'BANK_ACC_INFO/previousDayReporting': 'showPreviousDayReportingView',
    'REPORTING/transactionSearch': 'showTransactionSearch',
    'BANK_ACC_INFO/depositAccountsTransactionsDeepLink': 'showDepositAccountTransactionsDeepLink',
    'BANK_ACC_INFO/currDayDepositAccountTransactionsDeepLink': 'showCurrentDayDepositAccountTransactionsDeepLink',
    'BANK_ACC_INFO/prevDayDepositAccountTransactionsDeepLink': 'showPreviousDayDepositAccountTransactionsDeepLink',
    'BANK_ACC_INFO/loanAccountTransactionsDeepLink': 'showLoanAccountTransactionsDeepLink',
    'BANK_ACC_INFO/loanPreviousDayAccountTransactionsDeepLink': 'showPreviousDayLoanAccountTransactionsDeepLink',
    'BANK_ACC_INFO/loanCurrentDayAccountTransactionsDeepLink': 'showCurrentDayLoanAccountTransactionsDeepLink',
    'BANK_ACC_INFO/tieredLoanAccountDetailDeepLink': 'showTieredLoanAccountDetailDeepLink',
    'BANK_ACC_INFO/tieredLoanPreviousDayAccountDetailDeepLink': 'showPreviousDayTieredLoanAccountDetailDeepLink',
    'BANK_ACC_INFO/tieredLoanCurrentDayAccountDetailDeepLink': 'showCurrentDayTieredLoanAccountDetailDeepLink',
};

export default routes;
