import CompositeView from '@glu/core/src/compositeView';
import util from '@glu/core/src/util';

export default CompositeView.extend({
    initialize(options) {
        this.listenTo(
            this.collection,
            {
                'change:NET_AMOUNT reset remove': this.updateTotal,
            },
        );

        this.readOnly = false;
        if (options) {
            this.readOnly = options.readOnly;
        }
    },

    className: 'editable-table',
    itemViewContainer: 'tbody',

    itemViewOptions() {
        return {
            readOnly: this.readOnly,
        };
    },

    templateHelpers() {
        return {
            readOnly: this.readOnly,
        };
    },

    events: {
        'click button[data-hook="add-line-item"]': 'addLineItem',
        sortstart: 'setWidths',
        sortstop: 'unsetWidths',
        sortupdate: 'handleSort',
    },

    addLineItem() {
        if (this.collection.length < 25) {
            this.collection.add({});
        }
    },

    setWidths(e, ui) {
        const $siblingCells = this.$(ui.item).siblings().not('.ui-sortable-placeholder').eq(0)
            .find('td');
        if ($siblingCells.length) {
            util.each(this.$(ui.item).find('td'), function (el, i) {
                this.$(el).width($siblingCells.eq(i).width());
            }, this);
        }
    },

    unsetWidths(e, ui) {
        this.$(ui.item).find('td').css({
            width: 'auto',
        });
    },

    updateTotal() {
        /*
         * TO DISPLAY TOTAL
         *         console.log(this.collection.getTotal());
         */

        /*
         *         TO SAVE
         * console.log(this.collection.toServerJSON());
         */
    },

    onRender() {
        this.$('tbody').sortable({
            axis: 'y',
            forcePlaceholderSize: true,
            containment: this.$el,
        });

        this.handleSort();
    },

    handleSort() {
        util.each(this.$('tr[data-model]'), function (el, i) {
            const $el = this.$(el);
            this.collection.get($el.data('model')).set({
                order: i,
            });
        }, this);
    },
});
