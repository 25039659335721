var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button type=\"button\" class=\"btn btn-action dropdown-toggle\" data-action=\"toggleMenu\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"common.action.dropdown",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":106},"end":{"line":4,"column":141}}}))
    + "\">\n            <span class=\"caret\" role=\"presentation\"></span>\n        </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"btn-group\" aria-labelledby=\"label-th-"
    + alias2(((helper = (helper = lookupProperty(helpers,"columnId") || (depth0 != null ? lookupProperty(depth0,"columnId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"columnId","hash":{},"data":data,"loc":{"start":{"line":1,"column":49},"end":{"line":1,"column":61}}}) : helper)))
    + "\">\n    <button type=\"button\" data-action=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"primary") : depth0)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\" class=\"btn btn-action\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"primary") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"moreThanOne") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});