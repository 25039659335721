import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import {
  Button, PRIMARY, SMALL, TERTIARY
} from '@glu/buttons-react';
import locale from '@glu/locale';
import styles from './FilterActions.styles';

const ApplyButtonComponent = ({ cancel, classes, ...props }) => (
  <div className={classes.applyButtonWrapper}>
    <Button
      className={classes.applyButton}
      variant={PRIMARY}
      size={SMALL}
      text={locale.get('dataComponents.apply')}
      {...props}
    />
    <Button
      className={classes.cancelButton}
      onClick={cancel}
      variant={TERTIARY}
      size={SMALL}
      text={locale.get('dataComponents.cancel')}
    />
  </div>
);

ApplyButtonComponent.propTypes = {
  cancel: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired
};

export default withStyles(styles)(ApplyButtonComponent);
