import util from '@glu/core/src/util';
import locale from '@glu/locale';
import domUtil from 'common/util/domUtil';
import actionFunction from 'app/payments/api/common';
import constants from 'common/dynamicPages/api/constants';
import EntryView from 'common/dynamicPages/views/workflow/entry';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import store from 'system/utilities/cache';
import Entitlements from 'common/dynamicPages/api/entitlements';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import alert from '@glu/alerts';
import Model from '@glu/core/src/model';
import { createTransformLocaleView } from 'components/TransformLocale/TransformLocaleWrapper';
import systemConfig from 'system/configuration';
import { getCurrentRoute } from 'system/utilities/routeHelper';
import rfpEntryTmpl from './rfpEntry.hbs';

export default EntryView.extend({
    template: rfpEntryTmpl,

    templatesEntitled: false,

    ui: util.extend(EntryView.prototype.ui, {
        $saveAndAddAnotherButton: '.widget-action-btn-group button[name="saveandaddanother"]',
        $pageContent: '[data-region="pageContent"]',
    }),

    initialize(options) {
        EntryView.prototype.initialize.call(this, {
            ...options,
            returnRoute: getCurrentRoute(),
        });
    },

    /**
     * @name setupListeners
     * @description override entry's method
     * adds event handler for 'rfp:useTemplate
     */
    setupListeners() {
        EntryView.prototype.setupListeners.call(this);
        this.listenTo(this.pageView, 'rfp:useTemplate', () => {
            this.navigateTo('/PAYMENTS/useRequestForPaymentTemplate');
        });
        this.listenTo(this.pageView, 'rfp:viewPayment', (instructionId, isSMBUser) => {
            const isAdmin = systemConfig.isAdmin();

            // Use the context key expected for rtpreceive.
            const navModel = new Model({
                INSTRUCTION_ID: instructionId,
            });

            const currentWorkspaceRoute = store.get('current-workspace-route');
            if (currentWorkspaceRoute) {
                store.set(`${this.pageView.model.get('id')}-original-workspace-route`, currentWorkspaceRoute);
            }
            store.set('current-workspace-route', this.returnRoute);

            if (isAdmin) {
                navModel.context = {
                    serviceName: 'adminPayment/rtp/incoming/read',
                };
                navModel.context.actionData = {
                    subType: '*',
                    productCode: 'ADMPAY',
                    functionCode: 'MSGS',
                    typeCode: 'RTPPAY',
                    entryMethod: 0,
                };
                store.set('adminPayment_rtp_incoming-actionModel', navModel);
                this.navigateTo('/REPORTING/viewIncomingDetails');
            } else {
                navModel.context = {
                    serviceName: 'payment/rtp/receivedPmt',
                };
                store.set(isSMBUser ? 'payment_listView_smb-actionModel' : 'payment_listView_corp-actionModel', navModel);
                this.navigateTo(isSMBUser ? '/payments/viewPayment' : '/PAYMENTS/viewPayment');
            }
        });
    },

    /**
     * @name getInsertPageOptions
     * @description override entry's method
     * need to set templatesEntitled option so that the policy file can react to this flag
     */
    getInsertPageOptions() {
        const insertOptions = EntryView.prototype.getInsertPageOptions.call(this);
        insertOptions.useTemplatesEntitled = this.useTemplatesEntitled;
        insertOptions.hasTemplatesEntitled = this.hasTemplatesEntitled;
        return insertOptions;
    },

    /**
     * @name loadRequiredDataInsertImport
     * @decription override entry's method
     * for insert of rfp, we need to check if the user in entitled to RFP templates
     */
    loadRequiredDataInsertImport() {
        if (this.mode === 'import') {
            EntryView.prototype.loadRequiredDataInsertImport.call(this);
        } else {
            // check if the user has entitlements to 'manage' rfp templates
            this.isTemplateEntryEntitled();
        }
    },

    /**
     * @override
     * @name displaySummaryTotal
     * @description initial display of summary total for RFP
     */
    displaySummaryTotal() {
        const localeKey = 'rfp.summary.total';
        let currency = 'USD';
        if (this.storedModel) {
            currency = this.storedModel.get('REQUESTED_AMOUNT_CCY');
        }

        this.pageView.summaryLocaleKey = localeKey;
        const params = [{ value: '0.00', className: 'summary-large-text' }, { value: currency }];
        const SummaryTotalView = createTransformLocaleView({
            localeKey,
            params,
            tagName: 'p',
            tagClass: 'summaryTotal',
        });
        if (this.summaryTotalRegion) {
            this.summaryTotalRegion.show(new SummaryTotalView());
        }
    },

    /**
     * @name isTemplateEntryEntitled
     * @description checks if the user is entitled to manage RFP templates
     */
    isTemplateEntryEntitled() {
        const useTemplateEntitlements = Entitlements
            .getEntitlements(constants.RTP_REQUEST_TEMPLATE_ENTRY_OPTIONS);
        const hasTemplateEntitlements = Entitlements
            .getEntitlements(constants.RFP_REQUEST_TEMPLATE_ENTRY_OPTIONS);
        Promise.all([
            useTemplateEntitlements,
            hasTemplateEntitlements,
        ]).then((response) => {
            this.useTemplatesEntitled = (Object.keys(response[0].actions).indexOf('INSERT') >= 0);
            this.hasTemplatesEntitled = (Object.keys(response[1].actions).indexOf('INSERT') >= 0);
            EntryView.prototype.loadRequiredDataInsertImport.call(this);
        });
    },

    templateHelpers() {
        const createdFrom = this.contextDef.loadFromPayments
            && this.contextDef.createdFrom === '1'
            && util.contains(['template', 'request'], this.contextDef.copySource);

        /*
         * Use the override locale to render the correct app resources when useTemplate
         * or 'copy as' from context menu.
         */
        if (createdFrom && this.contextDef.localeKeyOverride) {
            this.localeKey = this.contextDef.localeKeyOverride;
        }

        return {
            ...EntryView.prototype.templateHelpers.call(this),
            buttons: () => util.chain(this.buttonList)
                .map((button, index) => {
                    const btnActionLowercase = button.action.toLowerCase();
                    const btnIsDeleteOrPrint = btnActionLowercase === 'delete' || btnActionLowercase === 'print';

                    return {
                        btnText: locale.get(`${this.localeKey.split('.')[0]}.button_${btnActionLowercase}`),
                        btnClass: (index === 0 && !btnIsDeleteOrPrint ? 'btn-primary' : 'btn-secondary'),
                        btnAction: btnActionLowercase,
                    };
                })
                .indexBy('btnAction')
                .value(),

            getString: (type) => {
                const useTNum = serverConfigParams.get('ShowTNumOnPaymentDetails') === 'true';
                let localeKey = this.localeKey + type;
                let id;
                let title;
                if (this.mode) {
                    localeKey = `${localeKey}-${this.mode}`;
                }
                title = locale.get(localeKey);
                if (this.mode !== 'insert' && this.mode !== 'import') {
                    id = this.storedModel.get('USERGROUPSEQUENCENUMBER');
                    if (id) {
                        if (useTNum) {
                            id = this.storedModel.get('TNUM');
                        }
                        title = `${title} (${locale.get('PAY.ID')} ${id})`;
                    }
                }
                return title;
            },
        };
    },

    /**
     * @override
     */
    onRender() {
        if (this.mode === 'restore') {
            this.ui.$pageContent.addClass('rfp-template-entry');
        }
        if (this.hasLoadedRequiredData() && store.has(`${this.contextKey}-alertMessage`) && store.has(`${this.contextKey}-confirms`)) {
            this.renderMessage(store.get(`${this.contextKey}-confirms`));
        }
        this.contextDef.productCode = this.options.productCode;
        this.contextDef.functionCode = this.options.functionCode;
        EntryView.prototype.onRender.call(this);
    },

    renderMessage(confirmResponse) {
        if (!store.has(`${this.contextKey}-confirms`)) {
            EntryView.prototype.renderMessage.call(this, confirmResponse);
        } else {
            store.unset(`${this.contextKey}-alertMessage`);
            store.unset(`${this.contextKey}-confirms`);
            const confirms = new Confirms({
                confirms: confirmResponse.confirms,
            });

            // display notification message
            this.alertView = alert.success(
                confirmResponse.message[0],
                {
                    details: confirms,
                    canDismiss: true,
                },
            );
            this.alertRegion.show(this.alertView);
        }
    },

    /**
     * @override
     */
    actionSuccess(confirmResponse) {
        const fromTemplate = this.model && this.model.get('FROMTEMPLATE') === '1';
        if (this.addAnother) {
            this.setConfirmAndWarningMessage(confirmResponse);
            this.addAnother = false;
            if (fromTemplate) {
                this.navigateTo('PAYMENTS/requestForPayment');
            } else {
                this.refresh();
            }
        } else {
            EntryView.prototype.actionSuccess.call(this, confirmResponse);
        }
    },

    /**
     * @override
     */
    actionError(model) {
        EntryView.prototype.actionError.call(this, model);
        this.addAnother = false;
    },

    /**
     * @override
     */
    actionErrorForDetail(model) {
        EntryView.prototype.actionErrorForDetail.call(this, model);
        this.addAnother = false;
    },

    /**
     * @override
     */
    enableButtons() {
        EntryView.prototype.enableButtons.call(this);
        this.addAnother = false;
        domUtil.setDisabled([this.ui.$saveAndAddAnotherButton], false);
    },

    saveandaddanother() {
        this.addAnother = true;
        this.save();
    },

    modify() {
        store.set(`${this.contextKey}-actionModel`, this.getTheModel());
        this.navigateTo(constants.ROUTE_RTP_MODIFY_REQUEST_FOR_PAYMENT_OUT);
    },

    copyinst() {
        const model = this.getTheModel();
        model.loadFromPayments = true;
        actionFunction.makeRequestForPayment(model, this.contextKey);
        this.navigateTo(`PAYMENTS/addRequestFromTemplate/${model.get('FUNCTION')}`);
    },

    copytmpl() {
        const model = this.getTheModel();
        actionFunction.makeTemplateFromRequest(model, this.contextKey);
        this.navigateTo(`TEMPLATES/addTemplateFromRequest/${model.get('FUNCTION')}`);
    },

    getModifyButtons() {
        this.buttonList.push({
            action: 'SAVE',
        }, {
            action: 'SAVEDRAFT',
        });
    },

    getInsertButtons() {
        this.buttonList.push({
            action: 'SAVE',
        }, {
            action: 'SAVEANDADDANOTHER',
        }, {
            action: 'SAVEDRAFT',
        });
    },
});
