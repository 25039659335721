import Model from '@glu/core/src/model';
import transform from 'common/util/transform';

export default Model.extend({
    defaults() {
        return {
            fieldName: '',
            fieldValue: '',
        };
    },

    /**
     * Returns an array of "pair" objects to represent each attribute in the model.
     * If the structure had { 'foo': true, 'bar': 'derp'} The result from toPair would be
     * [{name: 'foo', value: true},{name: 'bar', value: 'derp'}]
     * @return {Array}
     */
    toPair() {
        return transform.hashToPairs(this.toJSON());
    },
});
