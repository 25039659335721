import util from '@glu/core/src/util';
// PCM RELATED - Use file from 3.18
import constants from 'common/dynamicPages/api/constants';
import BatchChildGridView from 'common/dynamicPages/views/batchChildGrid';
import systemConfig from 'system/configuration';
import dataApi from 'common/dynamicPages/api/data';
import mobileUtil from 'mobile/util/mobileUtil';

export default {
    makeChildView(viewParam, MetaDrivenForm, state) {
        const view = viewParam;
        /*
         * NH-45817, 45818
         * The reason that payment/template from copy as doesn't work is because
         * child model wasn't created in that case. Child model was only created
         * for modify. Because payment/template from copy is similar to modify,
         * createFromOther is a flag in the context which would be set to 1 when
         * the payment/template is from copy as.  Adding the check here and
         * use it to decide to create the child model later in the code.
         * This is what we did 3.2.9 release, but lost in the 3.3.3.3.
         */

        const createFromOther = view.options && view.options.context && view.options.context.createdFrom === '1';

        const newOptions = util.extend(
            {},
            view.initializeOptions,
            {
                isChild: true,
                isModal: false,
                parentModel: view.model,
                parentView: view,
                beneWidget: view.beneWidget,
            },
        );

        /*
         * NH-45817
         * Overrides the function code to BHTMPL when copy as template from payment list view
         * in which case the original function code in the context is BATCH, overrides the
         * function code to BATCH when copy as payment from template list view in which case
         * the origianal function code in the context is BHTML, the overriden function code
         * will be used to get the model info. Similarly, the same is for when copy as payment
         * from the template list view.
         */
        if (view.initializeOptions.overrideChildFunctionCode) {
            newOptions.context.functionCode = view.initializeOptions.overrideChildFunctionCode;
        }

        // Generate model for Modify
        /*
         * NH-45817,45818
         * Also create the child model if from copy as, similar to what we did in
         * earlier release.
         */
        if (view.state === 'modify' || view.state === 'restore' || createFromOther) {
            newOptions.model = dataApi.model.generateFromPreload(newOptions);
        }

        if (state) {
            newOptions.state = state;
        }

        view.childView = new MetaDrivenForm(newOptions);

        // FIXME - This shouldn't be needed, because the logic in MDF should do this.
        if (view.beneWidget && view.useBeneWidget) {
            view.childView.useBeneWidget = true;
        }

        view.listenTo(view.childView, 'childSaveSuccess', () => {
            view.handleChildAdd();
        });
        /*
         * When a grandChild save fails, this listern has already been added,
         * so before adding the listener again, remove it, to make sure that we
         * don't have multiple callbacks firing
         */
        view.stopListening(view.childView, 'grandChildSaveSuccess');
        view.listenTo(view.childView, 'grandChildSaveSuccess', view.handleGrandChildAdd);
        view.listenTo(view.childView, 'grandChildDeleteSuccess', view.handleGrandChildDelete);

        if (!state) {
            if (view.childViewRegion) {
                view.childViewRegion.show(view.childView);
            }
        } else {
            view.childView.render();
        }
    },
    // PCM RELATED
    isNotCheckBatchInPrintedStatus(view) {
        const checkBatchModel = view.model;
        const { productCode } = view.context.actionData;
        const printedStatus = 'RP';
        const incompleteStatus = 'PX';
        const checkBatchModelStatus = checkBatchModel.get('STATUS');
        return !(constants.CHECK_PRODUCT_CODE === productCode &&
            (printedStatus === checkBatchModelStatus ||
                incompleteStatus === checkBatchModelStatus));
    },

    setupChildRegions(viewParam, MetaDrivenForm) {
        const view = viewParam;
        const createFromOther = view.options && view.options.context && view.options.context.createdFrom === '1';

        if (!view.model.isBatch) {
            return;
        }

        if (view.state === 'insert' && !createFromOther) {
            this.makeChildView(view, MetaDrivenForm);
        } else {
            // If the parent is using BeneWidget, so do we
            if (view.useBeneWidget) {
                /*
                 * NH-48509 - Admin App cannot handle insert action for the payments,
                 * so not passing the parameter so that it uses the Select action.
                 */
                if (systemConfig.isAdmin()) {
                    this.makeChildView(view, MetaDrivenForm);
                } else {
                    /*
                     * FIXME - This insert action shouldn't be passed
                     * even for client when the action is select.
                     * because of this getChildren call is being made twice once with
                     * the insert action and next with the select action.
                     */
                    this.makeChildView(view, MetaDrivenForm, 'insert');
                }
            }

            view.$el.find('[data-region="childViewRegion"]').hide();
            view.$el.find('[data-region="childGridRegion"]').show();

            util.extend(
                view.initializeOptions,
                {
                    parentKey: view.model.getKeyData(),
                },
            );
            const batchGridOptions = util.clone(view.initializeOptions);
            // PCM RELATED
            if (view.state === 'view' && this.isNotCheckBatchInPrintedStatus(view)) {
                util.extend(
                    batchGridOptions,
                    {
                        selector: 'none',
                    },
                );
            }
            // set up Parent entry method, for use in the getmodel cache
            if (!util.isUndefined(view.model.get('ENTRYMETHOD')) && !util.isNull(view.model.get('ENTRYMETHOD'))) {
                batchGridOptions.context.PARENTENTRYMETHOD = view.model.get('ENTRYMETHOD');
            }
            if (util.isEmpty(batchGridOptions.context.typeCode)) {
                batchGridOptions.context.typeCode = view.model.get('TYPE');
            }

            if (mobileUtil.isMobileGridEnabled()) {
                batchGridOptions.hideAlertRegion = true;
            }
            batchGridOptions.batchSeqNum = view.model.get('BATCHSEQNUM');
            view.batchChildGridView = new BatchChildGridView(batchGridOptions);
            view.listenTo(view.batchChildGridView, 'addChild', view.handleChildAddFromGrid);
            view.listenTo(view.batchChildGridView, 'modifyChild', view.handleChildModifyFromGrid);
            view.listenTo(view.batchChildGridView, 'viewChild', view.handleChildViewFromGrid);
            // NH-47905 This next call only applies to ACH payments
            // that don't use the new Bene Widget
            if (!view.useBeneWidget && !view.useAltGrid) {
                view.childGridRegion.show(view.batchChildGridView);
            }
        }
    },
};
