import { mapperDataToRequestContract } from 'pcm/common/util/requestJSONParser';
import requestContract from 'pcm/app/checkManagement/checkApplications/requestContract';
import { nameToValue } from 'pcm/common/util/responseJSONParser';
import { getSobSummaryReport } from 'pcm/app/payments/dataUtils';
import $ from '@glu/core/src/$'; // TODO - try simple
import util from '@glu/core/src/util'; // must be so, because of DGB/PCM override.
import http from '@glu/core/src/http'; // must be so, because of DGB/PCM override.
import locale from '@glu/locale'; // but @glu/locale exports default. Why it works here? DGB? Why?
import dialog from '@glu/dialog'; // .default?
import Formatter from 'system/utilities/format';
import userInfo from 'etc/userInfo';
import BeneUpdatedWarning from 'common/dynamicPages/views/beneUpdatedWarning';
import constants from 'common/dynamicPages/api/constants';
import moment from 'moment';
import services from 'services';


/*
     * In some sections of this policy, you will find that we use jQuery selectors to store fields
     * rather than the F1 form.field() method. This is due to the fields being in a child region
     * and inaccessible to F1. Most functionalities were copied from achBatch.
     */
export default function (form, initialState) {
    const totalAmountDebitFieldName = 'TOTALAMTDEBIT';
    const totalNumDebitFieldName = 'TOTALNUMDEBIT';
    const transactionAmt = 'TRANSACTION_AMOUNT';
    const totalFieldName = 'TOTALAMT';
    const batchName = $('#BATCHNAME');
    const { formView } = form;
    const formState = formView.context.actionMode || formView.context.actionData.actionMode;
    const numberBeneFieldName = formState.toUpperCase() === 'VIEW' ? 'TOTALNUMITEMS' : 'TOTALNUM';
    const $blockTitle = $('.section-summary div[data-section="payment-summary"] .total-heading');
    const $debitOnlyBlocks = $('.debit-only');
    const totalAmountCreditFieldName = 'TOTALAMTCREDIT';
    const totalNumCreditFieldName = 'TOTALNUMCREDIT';
    const viewModel = formView.model;
    const { childViewRegion } = formView;
    const aFieldInAuditSection = form.field('VIEWHISTORY');
    const saveAsTemplate = form.field('SAVEASTEMPLATE');
    const $saveAsTemplate = $('[data-section="save-as-template"]');
    const addChild = form.field('addChild');
    const $creditOnlyBlocks = $('.credit-only');
    const entryMethod = viewModel.get('ENTRYMETHOD');
    const currentDate = Formatter.formatDate(new Date());
    const summaryDate = $('[data-field="summary-date"]');
    // fields for Check Batch Payment Summary Header and Footer
    const $paymentSummaryContainer = $('[data-section="payment-summary"]');
    const $currencyFields = $('[data-field="summary-currency"]');
    const $beneficiariesFields = $paymentSummaryContainer.find('[data-field="summary-beneficiaries"]');
    const payeeSummary = function () {
        const beneficiariesCount = (viewModel.get(numberBeneFieldName) || 0);
        const onePayee = formState.toUpperCase() === 'INSERT' ? 1 : '1';
        const textValue = (beneficiariesCount === onePayee)
            ? `${beneficiariesCount} ${locale.get('clm.payee')}`
            : `${beneficiariesCount} ${locale.get('clm.payees')}`;
        $beneficiariesFields.text(textValue);
        summaryDate.text(Formatter.formatDate(viewModel.get('RELEASE_DATE')));
    };
    const chunkedBatchSeq = $('[data-text="PARTNUM"]');

    const downloadJobSummary = () => {
        const batchId = viewModel.get('PTX_BATCH_ID');

        if (!batchId) {
            dialog.warning(locale.get('ptx.error.generic', locale.get('ptx.error.jobsummary')));
            return;
        }

        getSobSummaryReport(batchId).then(
            () => {
            },
            () => {
                dialog.warning(locale.get('ptx.error.generic', locale.get('ptx.error.jobsummary')));
            },
        );
    };

    const updateTotalsDisplay = function () {
        // Format the payment total and set beneficiary count
        const $amountFields = $('[data-field="summary-amount"]');
        const $creditAmountFields = $paymentSummaryContainer.find('[data-field="summary-credit-amount"]');
        const $debitAmountFields = $paymentSummaryContainer.find('[data-field="summary-debit-amount"]');
        const $numberDebit = $paymentSummaryContainer.find('[data-bind="TOTALNUMDEBIT"]');
        const $numberCredit = $paymentSummaryContainer.find('[data-bind="TOTALNUMCREDIT"]');
            const paymentTotalFormatted = Formatter.formatCurrency(viewModel.get(totalFieldName) || 0); // eslint-disable-line
            const creditTotalFormatted = Formatter.formatCurrency(viewModel.get(totalAmountCreditFieldName) || 0); // eslint-disable-line
            const debitTotalFormatted = Formatter.formatCurrency(viewModel.get(totalAmountDebitFieldName) || 0); // eslint-disable-line

        // Update the values of the date and beneficiary count summaries.
        payeeSummary();

        // Update the values of the amount summaries.
        // Some of these may be inactive based on the payment type.
        $amountFields.text(paymentTotalFormatted);
        $creditAmountFields.text(creditTotalFormatted);
        $debitAmountFields.text(debitTotalFormatted);

        // Update the display of the credit/debit totals
        // based respectively on their total numbers.
        $numberCredit.text(viewModel.get(totalNumCreditFieldName) || 0);
        $numberDebit.text(viewModel.get(totalNumDebitFieldName) || 0);
    };

    const updateBatchTotals = function () {
        const vmEffectiveDate = viewModel.get('EFFECTIVEDATE');
        const { actionData } = viewModel.context;
        const { productCode, typeCode, functionCode } = viewModel.jsonData.typeInfo;

        const effectiveDate = moment(vmEffectiveDate
                || form.field('EFFECTIVEDATE').getValue(), [userInfo.getDateFormat(), 'YYYY-MM-DD HH:mm:ss.S']).format('MM/DD/YYYY');

        const entryClass = (actionData === undefined || actionData.entryClass === undefined)
            ? viewModel.get('ENTRYCLASS')
            : actionData.entryClass;

        const postData = {
            productCode,
            typeCode,
            functionCode,
            entryClass,
            effectiveDate,
            user: viewModel.get('ENTERED_BY'),
            userGroup: viewModel.get('USERGROUP'),
            batchSeqNumber: viewModel.get('BATCHSEQNUM'),
        };

        const totalService = services.generateUrl(constants.URL_GETTOTALS);

        http.post(totalService, postData, (result) => {
            let displayTotalAmount = Formatter.unformatNumber(result.totalAmount);

            // Account for amounts that may not be saved on back end
            // when updating the totalAmount
            if (formView.childView && formView.childView.model && formView.childView.model.get('AMOUNT')) {
                displayTotalAmount += Formatter.unformatNumber(formView.childView.model.get('AMOUNT'));
            }
            viewModel.set(totalFieldName, Formatter.formatNumber(displayTotalAmount));

            // Set the number of beneficiaries.
            viewModel.set(numberBeneFieldName, result.totalTransactions);
            viewModel.set(transactionAmt, result.totalAmount);

            // Update the credit and debit amounts on the model.
            viewModel.set(totalAmountCreditFieldName, result.creditAmount);
            viewModel.set(totalAmountDebitFieldName, result.debitAmount);

            // Update the number of credits and debits respectively on the model.
            viewModel.set(totalNumCreditFieldName, result.creditTransactions);
            viewModel.set(totalNumDebitFieldName, result.debitTransactions);

            updateTotalsDisplay();
        });
    };
    const setMinimumPrintDate = function () {
        $('#RELEASE_DATE').daterangepicker({
            format: 'MM/DD/YYYY',
            minDate: currentDate,
            singleDatePicker: true,
        });
    };

    batchName.attr('autocomplete', 'off');
    form.field('CHECKDESIGN').shouldBeReadOnly(true);

    if (formState.toUpperCase() !== 'VIEW') {
        form.field('CHECKDESIGN').shouldBeHidden();
    }

    if (formState.toUpperCase() === 'MODIFY') {
        form.field('CHECKAPP_DESC').shouldBeReadOnly(true);
        document.getElementsByName('CHECKAPP_DESC_LOOKUP')[0].parentNode.hidden = true;
    }

    if (initialState && (formState.toUpperCase() === 'VIEW')) {
        const $printDate = $('#RELEASE_DATE');
        if ($printDate && $printDate[0]) {
            const printDateContent = $printDate[0].lastElementChild;
            printDateContent.innerText = Formatter.formatDate(viewModel.get('RELEASE_DATE'));
        }
    }

    if (initialState && (formState.toUpperCase() === 'INSERT' || formState.toUpperCase() === 'MODIFY')) {
        if (formState.toUpperCase() === 'INSERT') {
            // Remove the audit section from the DOM so styling is not impacted.
            aFieldInAuditSection.$el.closest('.section').remove();
            // Hide the summary section
            $('[data-widget-id="summarysection"]').closest('fieldset').hide();
            if (!viewModel.get('RELEASE_DATE')) {
                viewModel.set('RELEASE_DATE', currentDate);
            }
            setMinimumPrintDate();
        }
    }

    if (formView.batchChildGridView &&
            formView.batchChildGridView.hasLoaded === true) {
        // Get all page totals from the server on load
        formView.listenTo(formView.batchChildGridView.gridView, 'gridapi:loaded', () => {
            updateBatchTotals();
        });
        // Get totals when we update amounts and save
        formView.listenTo(formView.appBus, 'nachaChild:update:success', updateBatchTotals);
        // Get totals when we update the hold and save
        formView.listenTo(formView.appBus, 'nachaChild:hold:update:success', updateBatchTotals);
        updateBatchTotals();
    }

    if (initialState) {
        payeeSummary();
        if (formState.toUpperCase() === 'REPAIR') {
            viewModel.context.actionData.action = 'REPAIR';
        }
        // PARTNUM field text is replaced with text value "PARTNUM of PARTTOTAL"
        chunkedBatchSeq.text(`${viewModel.attributes.PARTNUM} ${locale.get('of')} ${viewModel.attributes.PARTTOTAL}`);

        $creditOnlyBlocks.toggle(true);
        $debitOnlyBlocks.toggle(true);
        $blockTitle.toggle(true);
        $blockTitle.hide();
        $debitOnlyBlocks.hide();
        $creditOnlyBlocks.hide();
        // Set the new view total fields default values.
        viewModel.set(totalFieldName, 0);
        if (formState.toUpperCase() !== 'VIEW') {
            viewModel.set(numberBeneFieldName, 0);
        }

        // Bind to the childViewRegion show event so we can attach events to its fields.
        if (childViewRegion) {
            childViewRegion.on('show', () => {
                childViewRegion.currentView.$el.on('change', 'input, select, textarea', () => {
                    viewModel.set(totalNumDebitFieldName, 0);
                    viewModel.set(totalAmountDebitFieldName, form.field('AMOUNT').getValue());
                    // Update the beneficiary total count and the total field name manually
                    // since these values aren't returning from a service until
                    // we have multiple beneficiaries.
                    viewModel.set(numberBeneFieldName, 1);
                    viewModel.set(totalFieldName, form.field('TOTALAMOUNT').getValue());
                    form.applyPolicies(false);
                });
            });
        }

        if (viewModel.get('ENTRYMETHOD') === '1') {
            if (addChild) {
                addChild.$el.hide();
            }
        }

        if (viewModel.jsonData.typeInfo.functionCode === 'BATCH') {
            const $closestSection = saveAsTemplate.$el.closest('.section');
            if (userInfo.isSmbUser()) {
                $closestSection.addClass('hidden');
            } else {
                // The originator ID should be editable on modify unless the payment was created
                // from copy-from-template or imported.  Does not apply to imported templates.
                if (formState.toUpperCase() === 'VIEW' || ((entryMethod === '1' || entryMethod === '3') && formState.toUpperCase() === 'MODIFY')) {
                    $closestSection.addClass('hidden');
                }
                if (entryMethod === '3' || entryMethod === '1' || formState.toUpperCase() === 'VIEW') {
                    saveAsTemplate.$el.closest('.section').addClass('hidden');
                } else {
                    $saveAsTemplate.empty();
                    $closestSection.appendTo($saveAsTemplate);
                    $closestSection.removeClass('section section-container');
                }
            }
        }

        if (formView.state.toUpperCase() === 'REPAIR') {
                for (const daField in Object.keys(form.fields)) { // eslint-disable-line
                if (daField === 'AMOUNT' ||
                        daField === 'edit-amounts-amount' ||
                        daField === 'bulk-update-affected') {
                        continue; // eslint-disable-line
                }
                if (util.has(form.fields, daField)) {
                    form.fields[daField].shouldBeReadOnly(true);
                }
            }
            const $batchComment = $('textarea[name="BATCHCOMMENT"]');
            if ($batchComment) {
                $batchComment.prop('readonly', true);
            }
        }

        /*
            * Need to show the user a warning if the contact used in any of the beneficiaries
            * has been modified or deleted.
            * This flag is set on contact approval/deletion
            */
        const contactHasBeenUpdatedValue = viewModel.get('CONTACTHASBEENUPDATED');
        const isApplicableActionMode = form.formView.state.toUpperCase() === 'MODIFY' || (form.formView.state.toUpperCase() === 'INSERT' && viewModel.context.createdFrom === '1');
        if (isApplicableActionMode && (contactHasBeenUpdatedValue === 'YES' || contactHasBeenUpdatedValue === 'INVALID')) {
            const beneUpdatedWarning = new BeneUpdatedWarning({
                model: viewModel,
                contactHasBeenUpdatedValue,
                inavlidBeneficiaries: viewModel.get('INVALIDBENELIST'),
            });
                // if the user accepts the changes the beneficiary
                // grid will have to be refreshed to show them.
            form.formView.listenTo(beneUpdatedWarning, 'refreshGrid', () => {
                form.formView.batchChildGridView.gridView.refreshGridData(false, false, true);
            });
            dialog.custom(beneUpdatedWarning);
        }
    }

    // Update the summary sections visibility.
    $paymentSummaryContainer.removeClass('hidden');

    // If available, append the currency code to the formatted payment Total.
    if (!util.isEmpty(viewModel.get('CURRENCY'))) {
        $currencyFields.text(viewModel.get('CURRENCY'));
    }
    viewModel.on('change', payeeSummary);


    // Format the payment total and set beneficiary count
    if (!initialState) {
        updateBatchTotals();
    }

    const enableJobSummaryLink = () => {
        const ID = viewModel.get('CHECKAPP_ID');
        const dataRequest = {
            ID,
        };
        if (ID) {
            const readApplicationEndpoint
                    = '/banking-services/api/tableMaintenance/checkApplication/read';
            const dataToPost
                    = mapperDataToRequestContract(
                        dataRequest,
                        requestContract.readCheckApplication,
                    );

            http.post(readApplicationEndpoint, dataToPost).then((response) => {
                const details = nameToValue(response.item);
                if (parseInt(details.JOBSUMMARYRPT, 10) === 1) {
                    if (!$('#jobsummary-link').length) {
                        $("div[data-widget-id='summarysection']").children().children().eq(0)
                            .children()
                            .append(`<div id="jobsummary-link" class="col-md-2 undefined  summary-section-column">
                                    <button data-hook="getToggleViewFilters" class="btn-tertiary" aria-label="JobSummaryReports">
                                        ${locale.get('ptx.jobsummary.download')}
                                    </button>
                                </div>`);

                        $('#jobsummary-link').on('click', downloadJobSummary);
                    }
                }
            }, (reason) => {
                this.props.showMessage('error', reason);
            });
        }
    };

    enableJobSummaryLink();
}
