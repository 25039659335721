import services from 'services';
import { postData } from 'common/util/services';
import interfaceUtil from './util';

const depositAccountTypes = ['DD', 'SV', 'GL'];
const balanceAttributes = {
    CURRENTDAYAVAILABLE: 'currentDayAvailable',
    CURRENCYCODE: 'currencyCode',
    OUTSTANDINGBALANCE: 'outstandingBalance',
};
const accountAttributes = {
    ACCOUNTNUM: 'accountNumber',
    ACCOUNTFILTER: 'accountFilter',
    CLIENTACCOUNTNAME: 'accountName',
    CURRENCYCODE: 'currencyCode',
    ACCOUNTTYPE: 'accountType',
    BANKCODE: 'bankCode',
};

export default {
    // productCode~functionCode~typeCode
    entitlementKey: 'GIR~INST~GIRACCT',
    /**
     * Use the listview to get a filtered list of accounts by ACCOUNTNUM
     * data in the post body
     * @param {string} accountFilter - Bank code and account number
     * concatenated, ie: BONY-14432343
     * @param {string} accountType
     * @returns {Promise}
     */
    getBalance(accountFilter, accountType) {
        // For loan account types default to the following sectionId, viewId and endpoint
        let sectionId = 'LOANACCTS';
        let viewId = 29054;
        let url = services.balanceInquiryLoanListView;

        // For deposit account types, use the following sectionId, viewId and endpoint
        if (depositAccountTypes.includes(accountType)) {
            sectionId = 'DEPOSITACCTS';
            viewId = 64;
            url = services.balanceInquiryDepositAccountListView;
        }
        const data = {
            startRow: 1,
            rowsPerPage: 500,
            viewId,
            disableDrillDown: false,
            dataOnly: 1,
            searchFields: [{
                fieldName: 'ACCOUNTFILTER',
                dataType: 'text',
                operator: 'EQ',
                fieldValue: [
                    accountFilter,
                ],
            }],
            requestParameters: {
                item: [
                    {
                        name: 'tabId',
                        value: 'ALLACCOUNTS',
                    },
                    {
                        name: 'sectionId',
                        value: sectionId,
                    },
                ],
            },
        };

        return postData(url, data);
    },

    /**
     * Get balance of an account based on account number
     * @param {string} accountFilter - Bank code and account number
     * concatenated, ie: BONY-14432343
     * @param {string} accountType
     * @returns {Promise}
     */
    getAccountBalance(accountFilter, accountType) {
        return this.getBalance(accountFilter, accountType)
            .then(this.formatBalanceResponse.bind(this))
            .then(data => JSON.stringify(data))
            .catch(error => Promise.reject(JSON.stringify(error)));
    },

    /**
     * When response contains rows, extract data from those rows, otherwise
     * return a rejected promise with an error.
     * @param {Object} response
     * @returns {Promise|Array}
     */
    formatBalanceResponse(response) {
        if (response.rows.length) {
            const [rows] = response.rows;
            return interfaceUtil.extractData(rows.columns, balanceAttributes);
        }
        return Promise.reject(new Error('No Records found'));
    },

    /**
     * When response contains queryRows, extract data from those rows, otherwise
     * return a rejected promise with an error.
     * @param {Object} response
     * @returns {Promise|Array}
     */
    formatAccountResponse(response) {
        if (response.queryResponse.QueryData.queryRows.length) {
            return response.queryResponse.QueryData.queryRows
                .map(row => interfaceUtil.extractData(
                    row.mapDataList,
                    accountAttributes,
                    'toField',
                    'value',
                ));
        }
        return Promise.reject(new Error('No Records found'));
    },

    /**
     * Get all accounts that are assigned to a user
     * @returns {Promise}
     */
    getAccounts() {
        const data = {
            IncludeMapData: 1,
            queryCriteria: {
                inquiryId: '22207',
                action: {
                    typeCode: 'GIRACCT',
                    entryMethod: 0,
                    productCode: 'GIR',
                    actionMode: 'SELECT',
                    functionCode: 'INST',
                },
            },
            requestHeader: {
                queryPagesize: 0,
                queryOffset: 0,
            },
        };
        return postData(services.inquiryQueryResults, data)
            .then(response => ({
                accounts: this.formatAccountResponse(response),
                entitlement: this.entitlementKey,
            }));
    },
};
