import moment from 'moment';
import userInfo from 'etc/userInfo';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import { Layout } from '@glu/core';
import DueOnTheDay from './dueOnThe/dueOnTheDay';
import ExpiresOnTheDay from './expiresOnThe/expiresOnTheDay';
import template from './dueOnExpiresOn.hbs';

export default Layout.extend({

    template,

    regions: {
        dueDate: '[data-hook="schedulerDueDateRegion"]',
        expirationDate: '[data-hook="schedulerExpirationDateRegion"]',
    },

    initialize(options) {
        this.model = options.model;
        const timeZone = userInfo.get('timezone') || serverConfigParams.get('SystemTimezone');
        const userTimeZone = moment(new Date()).tz(timeZone).format('z');
        this.model.set('userTimeZone', userTimeZone, { silent: true });
    },

    onRender() {
        this.dueDate.show(new DueOnTheDay({
            model: this.model,
        }));
        this.expirationDate.show(new ExpiresOnTheDay({
            model: this.model,
        }));
    },

});
