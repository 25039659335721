import React from 'react';
import PageHeader from 'components/PageHeader/PageHeader';
import ViewWrapper from 'react-wrappers/ViewWrapper';
import useLocale from 'hooks/useLocale';
import AchTotalListView from 'app/administration/views/achTotal/achTotalListView.js';

const AdminListView = (prop) => {
    const { getLocaleString } = useLocale();
    return (
        <>
            <PageHeader headingText={getLocaleString('risk.ach.pigid.homeTitle')} />
            <ViewWrapper view={new AchTotalListView(prop)} />
        </>
    );
};
export default AdminListView;
