var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <label for=\"usergroup\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"rolebased.company","Company",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":43},"end":{"line":9,"column":83}}}))
    + "</label>\n                    <p class=\"form-static-control\" data-text=\"userGroup\" data-bind=\"model\"></p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <label for=\"usergroup\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"rolebased.select-company","Select a Company",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":43},"end":{"line":12,"column":99}}}))
    + "</label>\n                    <input type=\"hidden\" id=\"usergroup\" class=\"form-control usergroup-select\" name=\"userGroup\" data-bind=\"model\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                    <p class=\"form-static-control\" data-text=\"roleName\" data-bind=\"model\"></p>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                    <input id=\"roleName\" type=\"text\" name=\"roleName\" data-bind=\"model\" class=\"form-control\">\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row\">\n                <div class=\"form-group col-4\">\n                    <div class=\"checkbox\">               \n                        <input id=\"bankMaintained\" type=\"checkbox\" name=\"bankMaintained\" value=\"1\" class=\"checkbox\" data-bind=\"model\">\n                        <label for=\"bankMaintained\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"PS.BankMaintained",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":42,"column":52},"end":{"line":42,"column":82}}}))
    + "</label>\n                    </div>\n                </div>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form> \n    <fieldset>\n        <legend class=\"sr-only\">Add Role Fields</legend>\n\n        <div class=\"row\">\n            <div class=\"form-group required col-4\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fromForm") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":14,"column":23}}})) != null ? stack1 : "")
    + "                <span class=\"help-block\" data-bind=\"model\" data-validate=\"userGroup\"></span>\n            </div>\n        </div>\n\n        <div class=\"row\">\n            <div class=\"form-group required col-6\">\n                <label for=\"roleName\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rolebased.rolename","Role Name",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":38},"end":{"line":21,"column":81}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"modifyMode") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":22,"column":16},"end":{"line":26,"column":23}}})) != null ? stack1 : "")
    + "                <span class=\"help-block\" data-bind=\"model\" data-validate=\"roleName\"></span>\n            </div>\n\n            <div class=\"form-group required col-6\">\n                <label for=\"roleDesc\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rolebased.roledesc","Role Description",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":38},"end":{"line":31,"column":88}}}))
    + "</label>\n                <input id=\"roleDesc\" type=\"text\" name=\"roleDesc\" data-bind=\"model\" class=\"form-control\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"roleDesc\"></span>\n            </div>\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"bankMaintainedRolesEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":8},"end":{"line":46,"column":15}}})) != null ? stack1 : "")
    + "        \n\n    </fieldset>\n</form>";
},"useData":true});