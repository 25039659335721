import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './RequiredLabelText.styles';

const propTypes = {
    label: PropTypes.string.isRequired,
};
const RequiredLabelText = ({
    label,
}) => {
    const classes = useStyles();
    return (
        <span
            className={classes.required}
        >
            {label}
        </span>
    );
};

RequiredLabelText.propTypes = propTypes;
export default RequiredLabelText;
