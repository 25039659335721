import CorpPassThroughDetail from 'app/payments/views/passthroughDetail';
import store from 'system/utilities/cache';
import util from '@glu/core/src/util';
import inquiryService from 'app/payments/views/passThrough/inquiry';
import BatchInfoGrid from 'app/administration/views/paymentManagement/passThrough/passThroughListView';
import AuditInfo from 'app/payments/views/passThrough/auditInfo';
import Model from '@glu/core/src/model';
import formatUtil from 'system/utilities/format';

const PassThroughDetails = CorpPassThroughDetail.extend({
    initialize(options) {
        this.isModal = options.isModal;
        this.model = options.model || new Model();
        this.paymentModel = store.get('passthru_view_payment_model');
        this.contextKey = 'payment_listView_corp';
        this.isAdminContext = true;
        this.fileSummary = new Model();
        inquiryService.send(this.paymentModel.get('TNUM'))
            .then(this.handleFileSummarySuccess.bind(this));
        this.buttonList = [];
        if (this.paymentModel && this.paymentModel.buttons) {
            for (let i = 0; i < this.paymentModel.buttons.length; i += 1) {
                const button = this.paymentModel.buttons[i];
                if (button.action !== 'SELECT') {
                    this.buttonList.push({
                        action: button.action,
                    });
                }
            }
        }
    },

    ui: {
        $batchCount: '[data-hook="ptBatchCount"]',
        $totalAmtDebit: '[data-hook="ptTotalAmtDebit"]',
        $totalNumDebit: '[data-hook="ptTotalNumDebit"]',
        $totalAmtCredit: '[data-hook="ptTotalAmtCredit"]',
        $totalNumCredit: '[data-hook="ptTotalNumCredit"]',
    },

    onRender() {
        this.listenTo(this, 'bothGridsLoaded', this.allGridsLoadComplete);
        const options = {
            parent: this,
        };
        this.batchInfo.show(new BatchInfoGrid(options));
        this.auditInfo.show(new AuditInfo({
            model: this.paymentModel,
        }));
    },

    setDebitsCredits(models) {
        let totalAmtDebits = 0;
        let totalDebits = 0;
        let totalAmtCredits = 0;
        let totalCredits = 0;

        util.each(models, (model) => {
            totalAmtDebits += formatUtil.unformatNumber(model.get('TOTALAMTDEBIT'));
            totalDebits += Number(model.get('TOTALNUMALLDEBITS'));
            totalAmtCredits += formatUtil.unformatNumber(model.get('TOTALAMTCREDIT'));
            totalCredits += Number(model.get('TOTALNUMALLCREDITS'));
        });
        this.ui.$batchCount.html(models.length);
        this.ui.$totalAmtDebit.html(formatUtil.formatCurrency(totalAmtDebits));
        this.ui.$totalNumDebit.html(totalDebits);
        this.ui.$totalAmtCredit.html(formatUtil.formatCurrency(totalAmtCredits));
        this.ui.$totalNumCredit.html(totalCredits);
    },

    allGridsLoadComplete() {
        const { models } = this.batchInfo.currentView.gridView.wrapper.rows;
        CorpPassThroughDetail.prototype.allGridsLoadComplete.call(this);
        this.setDebitsCredits(models);
    },

    handleBack() {
        window.history.back();
    },

    cancel() {
        window.history.back();
    },
});

export default PassThroughDetails;
