var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"form-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"tokenPin") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":28,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.PIN.Verification",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":28},"end":{"line":8,"column":61}}}))
    + "</h2>\n                        <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.PIN.Verification.message",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":27},"end":{"line":9,"column":68}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"contactDescription") || (depth0 != null ? lookupProperty(depth0,"contactDescription") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"contactDescription","hash":{},"data":data,"loc":{"start":{"line":9,"column":69},"end":{"line":9,"column":91}}}) : helper)))
    + "</p>\n                        <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.PIN.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":27},"end":{"line":10,"column":53}}}))
    + ": "
    + alias2(((helper = (helper = lookupProperty(helpers,"tokenPin") || (depth0 != null ? lookupProperty(depth0,"tokenPin") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"tokenPin","hash":{},"data":data,"loc":{"start":{"line":10,"column":55},"end":{"line":10,"column":67}}}) : helper)))
    + "</p>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.Phone.Verification",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":28},"end":{"line":12,"column":63}}}))
    + "</h2>\n                        <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.Phone.Verification.message",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":27},"end":{"line":13,"column":70}}}))
    + "</p>\n                        <div class=\"VoiceCallOTP-contact-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"contacts") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":28},"end":{"line":26,"column":37}}})) != null ? stack1 : "")
    + "                        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"radio\">\n                                    <input type=\"radio\"\n                                        id=\"contact-"
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":18,"column":52},"end":{"line":18,"column":61}}}) : helper)))
    + "\"\n                                        name=\"contact\"\n                                        value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":20,"column":47},"end":{"line":20,"column":56}}}) : helper)))
    + "\"\n                                        data-bind=\"model\"\n                                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"default") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":40},"end":{"line":22,"column":69}}})) != null ? stack1 : "")
    + "\n                                    />\n                                    <label for=\"contact-"
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":24,"column":56},"end":{"line":24,"column":65}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":24,"column":67},"end":{"line":24,"column":82}}}) : helper)))
    + "</label>\n                                </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "checked";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"tokenPin") : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":20},"end":{"line":37,"column":31}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <button data-action=\"initiate\"\n                            data-hook=\"getCallButton\"\n                            class=\"btn btn-primary\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"mfa.btn.call",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":52},"end":{"line":36,"column":77}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <form autocomplete=\"off\">\n        <div data-region=\"alertRegion\"></div>\n        <div class=\"form-container\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideContactMethodAndBlockConfirm") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":30,"column":23}}})) != null ? stack1 : "")
    + "            <div class=\"form-group\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideContactMethodAndBlockConfirm") : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":16},"end":{"line":38,"column":27}}})) != null ? stack1 : "")
    + "                <button data-action=\"cancel\" class=\"btn btn-secondary\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"common.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":39,"column":71},"end":{"line":39,"column":97}}}))
    + "</button>\n            </div>\n        </div>\n    </form>\n</div>\n";
},"useData":true});