var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " readonly ";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span>\n            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTP.mustPayThisAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":12},"end":{"line":36,"column":46}}}))
    + "\n        </span>\n        <a href=\"\" tabindex=\"0\" class=\"btn btn-popover toolTip-btn-popover\" data-toggle=\"popover\" role=\"button\" data-placement=\"auto right\" data-content=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTP.noAmountChangeMessage",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":154},"end":{"line":38,"column":192}}}))
    + "\">\n            <span class=\"textBoxError-icon icon-info-circle\"></span>\n        </a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"form-group\">\n        <label for=\"fullAmount\" class=\"main-label\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"fullAmountText") || (depth0 != null ? lookupProperty(depth0,"fullAmountText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fullAmountText","hash":{},"data":data,"loc":{"start":{"line":46,"column":51},"end":{"line":46,"column":69}}}) : helper)))
    + "</label>\n        <div class=\"input-group\">\n            <input id=\"fullAmount\" type=\"text\" class=\"form-control\" data-bind=\"model\" maxLength=\"13\" name=\"FULL_AMOUNT\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"amountModificationNotAllowed") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":120},"end":{"line":48,"column":173}}})) != null ? stack1 : "")
    + ">\n            <div class=\"input-group-addon\">\n                <input id=\"creditCurrency\" type=\"text\" class=\"currency-field\" data-bind=\"model\" name=\"CREDIT_CURRENCY\" tabindex=\"-1\" readonly>\n            </div>\n        </div>\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"FULL_AMOUNT\"></span>\n    </div>\n    <div class=\"form-group\">\n        <label for=\"discountAmount\" class=\"main-label\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"discountAmountText") || (depth0 != null ? lookupProperty(depth0,"discountAmountText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"discountAmountText","hash":{},"data":data,"loc":{"start":{"line":56,"column":55},"end":{"line":56,"column":77}}}) : helper)))
    + "</label>\n        <div class=\"input-group\">\n            <input id=\"discountAmount\" type=\"text\" class=\"form-control\" data-bind=\"model\" maxLength=\"13\" name=\"DISCOUNT_AMOUNT\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"amountModificationNotAllowed") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":128},"end":{"line":58,"column":181}}})) != null ? stack1 : "")
    + ">\n            <div class=\"input-group-addon\">\n                <input id=\"creditCurrency\" type=\"text\" class=\"currency-field\" data-bind=\"model\" name=\"CREDIT_CURRENCY\" tabindex=\"-1\" readonly>\n            </div>\n        </div>\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"DISCOUNT_AMOUNT\"></span>\n    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"form-group\">\n        <div class=\"AmountExchangeRate-wrapper\">\n            <div class=\"AmountExchangeRate-item\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"DEBIT_AMOUNT") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":16},"end":{"line":72,"column":23}}})) != null ? stack1 : "")
    + "                <strong data-bind=\"model\" data-text=\"DEBIT_CURRENCY\"></strong>\n            </div>\n            <div class=\"AmountExchangeRate-item\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"contractIdIsChecked") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":16},"end":{"line":95,"column":27}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n        <div>\n            <input type=\"checkbox\" id=\"contractIdCheckbox\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"contractIdIsChecked") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":59},"end":{"line":99,"column":100}}})) != null ? stack1 : "")
    + ">\n            <label for=\"contractIdCheckbox\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"smbPayments.contractIdIndicator",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":100,"column":44},"end":{"line":100,"column":88}}}))
    + "</label>\n        </div>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"contractIdIsChecked") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":103,"column":4},"end":{"line":105,"column":11}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "                    <span data-bind=\"model\" data-text=\"DEBIT_AMOUNT\"></span>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFxRateType") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":20},"end":{"line":88,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFxRateType") : depth0),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":89,"column":20},"end":{"line":94,"column":31}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"INDICATIVERATE") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":24},"end":{"line":81,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"INDICATIVERATE") : depth0),{"name":"unless","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":24},"end":{"line":87,"column":35}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <label for=\"indicativeRate\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"RTGS.INST.INTL.*.INDICATIVE_RATE.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":79,"column":56},"end":{"line":79,"column":107}}}))
    + "</label>\n                            <span data-bind=\"model\" data-text=\"EXCHANGE_RATE\"></span>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"EXCHANGE_RATE") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":28},"end":{"line":86,"column":35}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <label for=\"exchangeRate\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"gir.exchRate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":84,"column":58},"end":{"line":84,"column":83}}}))
    + "</label>\n                                <span data-bind=\"model\" data-text=\"EXCHANGE_RATE\"></span>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"EXCHANGE_RATE") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":24},"end":{"line":93,"column":31}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <label for=\"exchangeRate\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"gir.exchRate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":91,"column":54},"end":{"line":91,"column":79}}}))
    + "</label>\n                            <span data-bind=\"model\" data-text=\"EXCHANGE_RATE\"></span>\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "checked";
},"22":function(container,depth0,helpers,partials,data) {
    return "        <div data-region=\"contractIdLookupRegion\"></div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"form-group additional-comments-group\">\n        <label for=\"commentsText\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"SMBPAY.Comments",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":110,"column":53},"end":{"line":110,"column":81}}}))
    + "</label>\n        <textarea rows=\"2\" name=\"SPECIAL_INSTRUCTIONS\" data-bind=\"model\" id=\"commentsText\" class=\"form-control\" maxlength=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"maxLength") || (depth0 != null ? lookupProperty(depth0,"maxLength") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"maxLength","hash":{},"data":data,"loc":{"start":{"line":111,"column":123},"end":{"line":111,"column":136}}}) : helper)))
    + "\"></textarea>\n        <div class=\"char-counter pull-right\" id=\"char-counter\"></div>\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"SPECIAL_INSTRUCTIONS\"></span>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"validation-group hide\" role=\"alert\">\n    <p class=\"help-block center\" data-validation=\"generic\">\n    </p>\n</div>\n\n<div class=\"form-group account-group\">\n    <label for=\"account-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":7,"column":24},"end":{"line":7,"column":31}}}) : helper)))
    + "\" class=\"main-label\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"accountText") || (depth0 != null ? lookupProperty(depth0,"accountText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountText","hash":{},"data":data,"loc":{"start":{"line":7,"column":52},"end":{"line":7,"column":67}}}) : helper)))
    + "</label>\n    <input id=\"account-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":8,"column":23},"end":{"line":8,"column":30}}}) : helper)))
    + "\" type=\"hidden\" class=\"form-control\" data-hook=\"account-select\" data-bind=\"model\" name=\"ACCOUNTFILTER\" />\n    <span class=\"help-block\" data-bind=\"model\" data-validate=\"ACCOUNTFILTER\"></span>\n\n</div>\n\n<div class=\"form-group payment-date-group\">\n    <label for=\"paymentDate\" class=\"main-label\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"dateText") || (depth0 != null ? lookupProperty(depth0,"dateText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dateText","hash":{},"data":data,"loc":{"start":{"line":14,"column":48},"end":{"line":14,"column":60}}}) : helper)))
    + "</label>\n    <input id=\"paymentDate\" type=\"text\" class=\"input-date\" data-bind=\"model\" name=\"VALUE_DATE\">\n    <span class=\"help-block\" data-bind=\"model\" data-validate=\"VALUE_DATE\"></span>\n</div>\n\n<div class=\"form-group payment-amount-group\">\n    <label for=\"paymentAmount\" class=\"main-label\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"amountText") || (depth0 != null ? lookupProperty(depth0,"amountText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"amountText","hash":{},"data":data,"loc":{"start":{"line":20,"column":50},"end":{"line":20,"column":64}}}) : helper)))
    + "</label>\n    <div class=\"input-group\">\n        <input id=\"paymentAmount\"\n            type=\"text\"\n            class=\"form-control\"\n            data-bind=\"model\"\n            name=\"CREDIT_AMOUNT\"\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"amountModificationNotAllowed") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":27,"column":65}}})) != null ? stack1 : "")
    + "\n        >\n        <div class=\"input-group-addon\">\n            <input id=\"creditCurrency\" type=\"text\" class=\"currency-field\" data-bind=\"model\" name=\"CREDIT_CURRENCY\" tabindex=\"-1\" readonly>\n        </div>\n    </div>\n    <span class=\"help-block\" data-bind=\"model\" data-validate=\"CREDIT_AMOUNT\"></span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"amountModificationNotAllowed") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":4},"end":{"line":41,"column":11}}})) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRTPPayment") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":0},"end":{"line":65,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showExchangeRate") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":0},"end":{"line":106,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRTPPayment") : depth0),{"name":"unless","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":108,"column":0},"end":{"line":115,"column":11}}})) != null ? stack1 : "");
},"useData":true});