import locale from '@glu/locale';
import { getFlexDropdownFormat } from 'common/util/inquiryQueryResults';
import ViewDetailBase from './viewDetailBase';
import template from './viewLegacyReport.hbs';
import scheduleUtil from '../util';

export default ViewDetailBase.extend({
    template,
    entitledReportDescriptions: [],

    initialize() {
        const getReportIds = this.getInquiryData(17269).then(getFlexDropdownFormat);
        const getReportDescriptions = this.getInquiryData(17270).then(getFlexDropdownFormat);

        Promise.all([
            getReportIds,
            getReportDescriptions,
        ]).then(this.completeInitialize.bind(this));
        this.fileTypes = {
            'legacy.report.type': locale.get('GIR.Report'),
            'legacy.file.type': locale.get('GIR.Data.File'),
        };
    },

    /**
     * Finish setting up properties of the view and render it again
     * @param {Array} array of responses
     */
    completeInitialize([reportIds, reportDescriptions]) {
        const reportId = this.model.get('REPORT_ID');
        this.reportId = this.getReportId(reportId, reportIds);
        this.entitledReportDescriptions = reportDescriptions;
        this.setHasLoadedRequiredData(true);
        this.render();
    },

    /**
     * Use the inquiry service to get data with inquiry id and customfilters
     * @param {number} inquiryId - inquiry id to use for the request
     * @returns {Promise}
     */
    getInquiryData(inquiryId) {
        return scheduleUtil.getInquiryData({
            customFilters: this.getCustomFilters(this.model),
            inquiryId,
        });
    },

    /**
     * Return customer filters with user and usergroup
     * @param {Object} model - model with user and usergroup attributes
     * @returns {Array}
     */
    getCustomFilters(model) {
        return [
            {
                filterName: 'DEPENDS',
                filterParam: [
                    'USERGROUP',
                    model.get('USERGROUP'),
                ],
            },
            {
                filterName: 'DEPENDS',
                filterParam: [
                    'USER',
                    model.get('USERID'),
                ],
            },
        ];
    },

    /**
     * Get the report id from a list of report ids
     * @param {string} reportId - report id to search for
     * @param {Array} reportIds - array of report id objects
     * @returns {Object}
     */
    getReportId(reportId, reportIds) {
        return reportIds.find(report => report.id === reportId);
    },

    /**
     * Get report descriptions and when no longer entitled, concatenate
     * with the proper message
     * @param {Object} model
     * @returns {Array} Array of report descriptions
     */
    getReportDescriptions(model) {
        let reportDesc = model.get('REPORTDESC');
        if (!Array.isArray(reportDesc)) {
            reportDesc = [reportDesc];
        }
        return reportDesc.map((desc) => {
            const description = this.entitledReportDescriptions
                .find(entitledDesc => entitledDesc.id === desc);
            return this.getDescription(description, desc);
        });
    },

    templateHelpers() {
        return {
            ...ViewDetailBase.prototype.templateHelpers.call(this),
            reportId: this.getDescription(this.reportId, this.model.get('REPORT_ID')),
            reportDesc: this.getReportDescriptions(this.model),
            fileType: this.fileTypes[this.model.get('REPORTORDOWNLOAD')],
        };
    },
});
