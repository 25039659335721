import ItemView from '@glu/core/src/itemView';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import cashFlowScenarioPickerViewTmpl from './cashFlowScenarioPickerView.hbs';
import ScenarioService from '../service/scenario';

const CashFlowScenarioPickerView = ItemView.extend({
    template: cashFlowScenarioPickerViewTmpl,

    ui: {
        $viewMode: '.view-mode',
        $editMode: '.edit-mode',
        $btnShowEditMode: '.view-mode button',
        $btnCancelEdit: '.edit-mode .btn-link',
        $inputScenarioName: '.edit-mode input',
        $btnCreateScenario: '.edit-mode .btn-primary',
        $selectControl: '.select-control',
    },

    events: {
        'keypress @ui.$inputScenarioName': 'handleKeyPress',
        'click @ui.$btnShowEditMode': 'showEditMode',
        'click @ui.$btnCancelEdit': 'hideEditMode',
        'click @ui.$btnCreateScenario': 'addNewScenario',
    },

    showEditMode() {
        this.ui.$inputScenarioName.val('');
        this.ui.$viewMode.addClass('hidden');
        this.ui.$selectControl.addClass('hidden');
        this.ui.$editMode.removeClass('hidden');
        this.ui.$inputScenarioName.focus();
        this.disableParentButtons(true);
    },

    hideEditMode() {
        this.ui.$editMode.addClass('hidden');
        this.ui.$selectControl.removeClass('hidden');
        this.ui.$viewMode.removeClass('hidden');
        this.ui.$selectControl.focus();
        this.disableParentButtons(false);
    },

    disableParentButtons(disable) {
        this.$el.parents('.modal').find('.modal-footer button').prop('disabled', disable);
    },

    handleKeyPress(e) {
        // enter key
        if (e.which === 13) {
            e.stopPropagation();
            e.preventDefault();
            this.addNewScenario();
        }
    },

    addNewScenario() {
        const name = this.ui.$inputScenarioName.val();
        const scenario = new Model();
        scenario.set('name', name);
        ScenarioService.create(scenario);
    },

    initialize(options) {
        this.action = options.action;

        this.scenarios = options.scenarios;
        this.transactions = options.transactions;
        this.gridTransactions = options.gridTransactions;
        this.createdScenarioId = null;
        this.listenTo(
            this.model,
            {
                updateFields: this.save,
            },
            this,
        );

        this.listenTo(
            ScenarioService,
            {
                sync: this.updateScenarioCollection,
            },
            this,
        );
    },

    updateScenarioCollection(model) {
        this.scenarios.add(model);
        this.scenarios.sort();
        this.createdScenarioId = model.get('cashFlowScenarioId');
        this.render();
    },

    templateHelpers() {
        const scenarioId = this.createdScenarioId || this.model.get('CASHFLOWSCENARIOID') || null;
        return {
            confirmOnly: !util.isNull(this.model.get('CASHFLOWSCENARIOID')),
            scenarios: this.scenarios.getTemplateData(scenarioId),
        };
    },

    save() {
        const scenarioId = this.$('select').val();
        const scenario = this.scenarios.find(item => item.get('cashFlowScenarioId') === parseInt(scenarioId, 10));
        this.model.set({
            CASHFLOWSCENARIOID: util.isUndefined(scenario) ? null : scenarioId,
        });
    },

    getButtons() {
        let buttons = [];

        if (util.contains(['create', 'update'], this.action)) {
            buttons = [{
                text: 'Save',
                className: 'btn btn-primary btn-cashflow-save',
            }];
        } else {
            buttons = [{
                text: 'Delete',
                className: 'btn btn-primary btn-cashflow-save',
            }];
        }

        return buttons;
    },

    onRender() {
        this.$(':focusable').eq(0).focus();
        this.disableParentButtons(false);
    },
});

export default CashFlowScenarioPickerView;
