import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import simpleTmpl from './simple.hbs';

export default ItemView.extend({
    template: simpleTmpl,
    binding: true,
    tagName: 'td',
    className: 'sb-simple-cell',

    initialize(options) {
        this.model = options.model;
        this.column = options.column;
        this.field = this.column.get('field');
        this.desc = this.column.get('description');
    },

    getDisplayField() {
        let cellVal;
        if (!util.isEmpty(this.desc)) {
            cellVal = `${this.model.get(this.field)}-${this.model.get(this.desc)}`;
        } else {
            cellVal = this.model.get(this.field);
        }
        return cellVal;
    },

    templateHelpers() {
        return {
            cellHeader: this.column.get('title'),
            cellHeaderExists: !util.isEmpty(this.column.get('title')),
            cellValue: this.getDisplayField(),
        };
    },
});
