import React from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';

const ErrorMsgPtx = props => (
    <div className="row">
        <span className="sda-warning icon-exclamation-solid left" />
        <span className="col-10">{locale.get('ptx.error.generic', props.title)}</span>
    </div>);

ErrorMsgPtx.propTypes = {
    title: PropTypes.string.isRequired,
};

export default ErrorMsgPtx;
