import Marionette from 'backbone.marionette';
import ValidationSupplement from './validationSupplement';
import NumberInputAcceleration from './numberInputAcceleration';
import LookupHelperText from './lookupHelperText';
import ClearBeneficiary from './clearBeneficiary';

const Behaviors = {
    ValidationSupplement,
    NumberInputAcceleration,
    LookupHelperText,
    ClearBeneficiary,
};

Marionette.Behaviors.behaviorsLookup = function () {
    return Behaviors;
};

export default Behaviors;
