var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-region=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":1,"column":18},"end":{"line":1,"column":26}}}) : helper)))
    + "WidgetRegion\"\n    data-widget-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":2,"column":20},"end":{"line":2,"column":28}}}) : helper)))
    + "\"\n    data-type=\"ui-widget\"\n    data-widget-type=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"widgetType") || (depth0 != null ? lookupProperty(depth0,"widgetType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"widgetType","hash":{},"data":data,"loc":{"start":{"line":4,"column":22},"end":{"line":4,"column":36}}}) : helper)))
    + "\"\n    data-widget-field=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldName") || (depth0 != null ? lookupProperty(depth0,"fieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldName","hash":{},"data":data,"loc":{"start":{"line":5,"column":23},"end":{"line":5,"column":36}}}) : helper)))
    + "\"\n    data-widget-should-be-protected=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"shouldBeProtected") || (depth0 != null ? lookupProperty(depth0,"shouldBeProtected") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shouldBeProtected","hash":{},"data":data,"loc":{"start":{"line":6,"column":37},"end":{"line":6,"column":58}}}) : helper)))
    + "\"\n    class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cssWidgetClass") || (depth0 != null ? lookupProperty(depth0,"cssWidgetClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cssWidgetClass","hash":{},"data":data,"loc":{"start":{"line":7,"column":11},"end":{"line":7,"column":29}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"blockClass") || (depth0 != null ? lookupProperty(depth0,"blockClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"blockClass","hash":{},"data":data,"loc":{"start":{"line":7,"column":30},"end":{"line":7,"column":44}}}) : helper)))
    + "\">\n</div>\n";
},"useData":true});