/* eslint-disable react/no-array-index-key */
import './themeDefaults';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withInputAPI } from '@glu/validation-react';
import Dropzone from './Dropzone';
import Message from './Message';

const FileUpload = ({
  alertClassName,
  className,
  dataQa,
  errors,
  hideDropzone,
  messages,
  onChange,
  onClearMessage,
  clearMessageList,
  ...rest
}) => {
  const [internalMsgs, setInternalMsgs] = useState([]);
  return (
    <div className={className} data-qa={`file-upload${dataQa ? `-${dataQa}` : ''}`}>
      <div className={alertClassName}>
        {internalMsgs.map((message, index) => (
          <Message
            onHide={() => {
              onClearMessage(index);
              if (clearMessageList) {
                setInternalMsgs([]);
              }
            }}
            key={index}
            content={message}
          />
        ))}
        {errors.map((message, index) => (
          <Message
            key={index}
            onHide={() => { onClearMessage(index); }}
            content={message}
          />
        ))}
        {messages.map((message, index) => (
          <Message
            onHide={() => { onClearMessage(index); }}
            key={index}
            content={message}
          />
        ))}
      </div>
      {!hideDropzone && (
        <Dropzone
          onChange={onChange}
          dataQa={dataQa}
          clearMessageList={clearMessageList}
          setMessages={setInternalMsgs}
          {...rest}
        />
      )}
    </div>
  );
};


FileUpload.propTypes = {
  /** Class put on wrapper of alert message */
  alertClassName: PropTypes.string,

  /** Class passed to parent element for custom styling */
  className: PropTypes.string,

  /** Clear the internal message list after file(s) upload */
  clearMessageList: PropTypes.bool,

  /** String given to parent elements data-qa html attribute for easy targeting */
  dataQa: PropTypes.string,

  /** Toggle if the dropzone/file upload input can be used */
  disabled: PropTypes.bool,

  /** Array of strings with validation errors from the form context. */
  errors: PropTypes.arrayOf(PropTypes.string.isRequired),

  /** Callback to run after the user has dropped or selected file(s) */
  onChange: PropTypes.func,

  /** Callback after a message has been cleared.
   * receives index of message as a param */
  onClearMessage: PropTypes.func,

  /** Object with messages from server after upload. Used to populate alert messages. */
  messages: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    type: PropTypes.string.isRequired
  })),

  /** Toggle to display the Dropzone or not. Useful for hiding it when there are messages */
  hideDropzone: PropTypes.bool,

  /** Message for user's stating limitation of the file upload */
  uploadRules: PropTypes.string
};

FileUpload.defaultProps = {
  alertClassName: '',
  className: '',
  dataQa: undefined,
  disabled: false,
  messages: [],
  errors: [],
  hideDropzone: false,
  clearMessageList: false,
  onChange: /* istanbul ignore next */ () => {},
  onClearMessage: /* istanbul ignore next */ () => {},
  uploadRules: ''
};

const InputApiFileUpload = withInputAPI(FileUpload);
InputApiFileUpload.displayName = 'FileUpload';

export default InputApiFileUpload;
