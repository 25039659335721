import LayerView from '@glu/core/src/core/internal/layerView';
import util from '@glu/core/src/util';
import $ from 'jquery';
import userInfo from 'etc/userInfo';
import template from './actionMenu.hbs';

export default LayerView.extend({
    template,

    initialize(optionsParam) {
        const options = optionsParam;
        // hack. remove through meta code when possible
        if (this.isSMBStopPaymentsGrid(options.model)) {
            this.buttons = util.without(options.buttons, util.findWhere(
                options.buttons,
                {
                    action: 'INQUIRY',
                },
            ));
            options.model.buttons = this.buttons;
        } else {
            this.buttons = options.buttons;
        }

        this.model = options.model;
    },

    isSMBStopPaymentsGrid(model) {
        let isCorrectGrid = false;

        if (model && model.context && model.context.actionData) {
            const { actionData } = model.context;
            if (actionData.functionCode && actionData.productCode) {
                if (actionData && actionData.functionCode === 'INST' && actionData.productCode === 'CM' && userInfo.get('marketSegment') === 'SMB') {
                    isCorrectGrid = true;
                }
            }
        }

        return isCorrectGrid;
    },

    disableInitialViewBinding: true,

    ui: {
        $dropdownMenu: '.dropdown-menu',
    },

    events: {
        'click [data-action]': 'handleClick',
    },

    handleFocus() {
        if (document.activeElement.localName !== 'body' && this.$(document.activeElement).length === 0) {
            this.close();
        }
    },

    onClose() {
        $(document).off(`.${this.cid}`);

        if (this.$launchedFrom && this.$launchedFrom.focus) {
            if (this.$(':focus').length > 0) {
                this.$launchedFrom.focus();
            }
            delete this.$launchedFrom;
        }
    },

    showMenu($button) {
        this.$launchedFrom = $button;
        // This is to close the pop-up on click of the action menu
        this.appBus.trigger('gridActionPopup:close');
        // setup event listeners
        $(document).one(`click.${this.cid}`, util.bind(this.close, this));

        /*
         * Handle the focus event so we can close the menus
         * if the user focuses outside of the menu.
         */
        $(document).on(`focusin.${this.cid}`, util.bind(this.handleFocus, this));

        // position the menu
        this.ui.$dropdownMenu.css('top', `${$button.offset().top + $button.outerHeight()}px`);
        this.ui.$dropdownMenu.css('left', `${$button.offset().left}px`);

        this.ui.$dropdownMenu.show();
        this.ui.$dropdownMenu.find('a:first').focus();
    },

    /**
     * Bubble the edit event up the stack.
     */
    handleEdit(e) {
        this.trigger('edit', this, e);
    },

    /**
     * Bubble the delete event up the stack.
     */
    handleDelete(e) {
        this.trigger('delete', this, e);
    },

    handleClick(e) {
        this.options.parentCellView.handleClick(e);
    },

    findPrimary() {
        const { buttons } = this;
        let primary = util(buttons).find(model => model.primary);

        if (!primary) {
            if (buttons) {
                [primary] = buttons;
            }
        }

        return primary;
    },

    hasPrimary() {
        return !!util.find(this.buttons, model => model.primary);
    },

    templateHelpers() {
        return {
            hasPrimary: this.hasPrimary(),
            primary: this.findPrimary(),
            buttons: this.buttons,
        };
    },
});
