var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"login-container page\">\n    <div class=\"row\">\n        <div class=\"col-12\">\n            <div class=\"login-logo\">\n                <img class=\"header-image\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"imgRoot") || (depth0 != null ? lookupProperty(depth0,"imgRoot") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imgRoot","hash":{},"data":data,"loc":{"start":{"line":5,"column":47},"end":{"line":5,"column":58}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"buildId") || (depth0 != null ? lookupProperty(depth0,"buildId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"buildId","hash":{},"data":data,"loc":{"start":{"line":5,"column":59},"end":{"line":5,"column":70}}}) : helper)))
    + "/logo.png\" alt=\""
    + alias4(lookupProperty(helpers,"locale").call(alias1,"logoAltTxt",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":86},"end":{"line":5,"column":109}}}))
    + "\"/>\n            </div>\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"validation-group hide\" role=\"alert\">\n            <p class=\"help-block center\" data-validation=\"generic\"></p>\n        </div>\n        <div data-region=\"alertRegion\"></div>\n    </div>\n    <div class=\"row regions\">\n      <div class=\"col-sm-4 challenge-questions\" data-region=\"challengeQuestionsRegion\"></div>\n      <div class=\"col-sm-4 password-reset\" data-region=\"passwordResetRegion\"></div>\n      <div class=\"col-sm-4 success-message\">\n          <p>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.passwordResetSuccess",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":13},"end":{"line":19,"column":53}}}))
    + "</p>\n          <p>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.passwordResetLoginPage",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":13},"end":{"line":20,"column":55}}}))
    + "</p>\n          <button type=\"button\" id=\"go-to-login\" class=\"btn btn-primary\" data-hook=\"getGoToLoginBtn\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"button.continue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":101},"end":{"line":21,"column":129}}}))
    + "</button>\n      </div>\n    </div>\n    <div class=\"login-footer\">\n        "
    + ((stack1 = (lookupProperty(helpers,"processFooter")||(depth0 && lookupProperty(depth0,"processFooter"))||alias2).call(alias1,{"name":"processFooter","hash":{"locale":"logon.footer"},"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":25,"column":49}}})) != null ? stack1 : "")
    + "\n    </div>\n</div>\n";
},"useData":true});