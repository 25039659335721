import ItemView from '@glu/core/src/itemView';
import $ from 'jquery';
import store from 'system/utilities/cache';
import util from '@glu/core/src/util';
import systemActionsTmpl from './systemActions.hbs';

const SystemActionsView = ItemView.extend({
    template: systemActionsTmpl,

    events: {
        'click @ui.$action': 'updateModel',
        'click @ui.$category': 'toggleCategory',
        'click @ui.$selectAll': 'toggleAllCategories',
    },

    ui: {
        $action: 'li.child-item input',
        $category: 'li.parent-item input',
        $selectAll: '#select-all',
    },

    className: 'mfa-category-container',

    initialize() {
        this.actionModel = store.get('mfa:actionModel');
        if (this.actionModel) {
            this.setChallenges();
        }
    },

    setChallenges() {
        const self = this;
        const sysModel = this.options.sysCollection.at(0);
        const sysActions = sysModel.get('systemActions');

        util.each(sysActions, (sysAction) => {
            util.each(sysAction.actions, (action) => {
                if (action.enabled) {
                    self.model.set(action.feature, action.enabled);
                }
            });
        });
    },

    onRender() {
        this.selectSystemActions();
        this.selectSystemCategories();
    },

    selectSystemActions() {
        util.each(this.ui.$action, function (e) {
            const { name } = e;
            if (this.model.get(name)) {
                $(e).prop('checked', true);
            }
        }, this);
    },

    selectSystemCategories() {
        const self = this;
        /*
         * For all categories only, loop through the list of actions. If all checked,
         * select category box
         */
        util.each(this.ui.$category, (e) => {
            let allSelected = true;
            const name = $(e).attr('name');
            const children = self.$el.find(`.${name}`);

            for (let x = 0; x < children.length; x += 1) {
                if (!self.model.get(children[x].name)) {
                    allSelected = children[x].checked;
                    break;
                }
            }
            $(e).prop('checked', allSelected);
        });
    },

    toggleCategory(e, flag, selectAllstatus) {
        const actions = !flag ? $(e.currentTarget).val() : e.value;
        const checked = !flag ? $(e.currentTarget).prop('checked') : selectAllstatus;

        this.toggleChildren(actions.split(','), checked);
        if (!flag) {
            this.notifyParentOfModelUpdate();
        }
    },

    toggleAllCategories(ev) {
        const checked = $(ev.currentTarget).prop('checked');
        const self = this;

        util.each(this.ui.$category, (e) => {
            // pass true to avoid executing notifyParentOfModelUpdate() at the end of each loop
            self.toggleCategory(e, true, checked);
            $(e).prop('checked', checked);
        }, this);
        this.notifyParentOfModelUpdate();
    },

    toggleChildren(actions, checked) {
        util.each(actions, function (action) {
            $(`input[name="${action}"]`).prop('checked', checked);
            this.model.set(action, checked);
        }, this);
    },

    updateModel(e) {
        const $target = $(e.currentTarget || e);
        const action = $target.attr('name');
        const parentCategory = $target.attr('class');
        const parent = $(`[name="${parentCategory}"]`);
        const checked = $target.prop('checked');

        this.model.set(action, checked);

        /*
         * For the current category only, loop through the list of actions. If all
         * checked, select category box
         */
        const children = this.$(`.${parentCategory}`);
        const checkedArr = [];
        util.each(children, (child) => {
            if (child.checked) {
                checkedArr.push(child.checked);
            }
        });

        parent.prop('checked', (checkedArr.length === children.length));

        this.notifyParentOfModelUpdate();
    },

    notifyParentOfModelUpdate() {
        this.appBus.trigger('systemActions:updateParentModel', this.model.toJSON());
    },

    templateHelpers() {
        return {
            systemActions: this.model.get('systemActions'),
            isDisabled: this.options.action === 'select',
        };
    },
});

export default SystemActionsView;
