function getSimplifiedAgent(browser) {
  let { userAgent } = browser;

  if (userAgent.match(/Opera|Navigator|Minefield|KHTML|Chrome/)) {
    userAgent = userAgent.replace(/(Firefox|MSIE|KHTML,\slike\sGecko|Konqueror)/, '')
      .replace('Chrome Safari', 'Chrome')
      .replace('Minefield', 'Firefox')
      .replace('Navigator', 'Netscape')
      .replace('KHTML', 'Konqueror');
  }

  return userAgent.toLowerCase();
}

function getName(userAgent) {
  const name = (/(camino|chrome|crios|firefox|netscape|konqueror|lynx|msie|trident|opera|safari|phantomjs|jsdom)/).exec(userAgent);
  if (!name || !name.length || name.length < 2) {
    return 'other';
  }

  return name[1].replace('trident', 'msie');
}

function getVersion(userAgent, browser) {
  if (browser.opera) {
    return window.opera.version();
  }

  try {
    // TODO fix this but I ain't touching it during a mass conversion
    // eslint-disable-next-line no-useless-escape
    return (/(camino|chrome|crios|firefox|netscape|netscape6|opera|version|konqueror|lynx|msie|rv|safari|phantomjs|jsdom)(:|\/|\s)([a-z0-9\.\+]*?)(\;|dev|rel|\s|$)/).exec(userAgent)[3];
  } catch (e) {
    return null;
  }
}

const browser = {
  userAgent: window.navigator.userAgent
};

const agent = getSimplifiedAgent(browser);

// Set browser name flag (e.g. firefox: true)
browser[getName(agent)] = true;

// Set browser version (fullVersion is the original string, version is an numeric representation)
browser.fullVersion = getVersion(agent, browser);
browser.version = parseFloat(browser.fullVersion);

browser.isIECompatibilityMode = function isIECompatibilityMode() {
  if (!this.msie) {
    return false;
  }

  const agentLowerCase = this.userAgent.toLowerCase();

  // Check if Trident version mismatches the actual browser version
  if (this.version === 8 && agentLowerCase.indexOf('trident/4.0') === -1 ||
    this.version === 9 && agentLowerCase.indexOf('trident/5.0') === -1 ||
    this.version === 10 && agentLowerCase.indexOf('trident/6.0') === -1 ||
    this.version === 11 && agentLowerCase.indexOf('trident/7.0') === -1) {
    return true;
  }

  return false;
};

export default browser;

