import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import partialSummaryBodyTmpl from './partialSummaryBody.hbs';

const PartialSummaryBody = ItemView.extend({
    template: partialSummaryBodyTmpl,

    omitFields: [
        'START_DATE',
        'END_DATE',
        'START_LABEL',
        'END_LABEL',
    ],

    initialize() {
        this.parseFields();
    },

    parseFields() {
        const { model } = this;
        this.startFields = [];
        this.endFields = [];
        const columns = model.collection.headers;
        const startLabel = model.get('START_LABEL');
        const endLabel = model.get('END_LABEL');
        if (columns) {
            const sortedColumns = util.sortBy(columns, 'displayOrder');
            for (let i = 0; i < sortedColumns.length; i += 1) {
                const field = sortedColumns[i].fieldName;
                const value = model.get(field);
                if (!(util.indexOf(this.omitFields, field) >= 0 || !value || value === '')) {
                    const obj = {};
                    obj.fielName = field;
                    obj.fieldLabel = sortedColumns[i].displayName;
                    obj.fieldValue = value;
                    if (field.indexOf('START_') === 0) {
                        if (field.indexOf('START_AVAILABLE') === 0 || field.indexOf('START_LEDGER') === 0) {
                            obj.fieldLabel = locale.get(`gir.${field.replace('START', startLabel)}`);
                        }
                        this.startFields.push(obj);
                    } else if (field.indexOf('END_') === 0) {
                        if (field.indexOf('END_AVAILABLE') === 0 || field.indexOf('END_LEDGER') === 0) {
                            obj.fieldLabel = locale.get(`gir.${field.replace('END', endLabel)}`);
                        }
                        this.endFields.push(obj);
                    }
                }
            }
        }
    },

    templateHelpers() {
        return {
            startFields: this.startFields,
            endFields: this.endFields,
        };
    },
});

export default PartialSummaryBody;
