import util from '@glu/core/src/util';
import locale from '@glu/locale';
import genGridCheck from 'common/checkGrid/checkGridGenerator';
import dynPagesConstants from 'common/dynamicPages/api/constants';
import CONSTANTS from './constants';
import checkGridCellTmpl from './checkGridCell.hbs';

const gridDataAccess = {
    CONSTANTS,

    /**
     * Get applicable, but this is only valid for the type applicable, not the children.
     * @param type
     * @return {boolean}
     */
    getApplicableType(type) {
        // This applicable only works for the inner types.
        return type.get('dataEntitlementsApplicable') || true;
    },

    /**
     * Get records for the payment section only
     * @param {Model} model
     * @param {string[]} exclusionList
     */
    paymentSectionRecords(model, exclusionList) {
        const self = this;
        const isGrouped = this.isGrouped(model);

        return util.chain(self.getSectionData(model, 'PAYMENTS'))
            .map(group => (isGrouped ? self.getHeaderAsGroups(group)
                : self.getHeaderAsTypes(group)))
            .flatten(true)
            .reject(group => util.contains(exclusionList, group.id)
                    || util.contains(exclusionList, group.subGroupId))
            .map((groupParam) => {
                const group = groupParam;
                group.isGroup = isGrouped;
                return group;
            })
            .value();
    },

    /**
     * For certain type codes such as the ones found under the reporting tab, multiple
     * types are deeply nested under the first type code in the array instead of each type
     * code being a member of the array. This requires a deeper look up in order to check if
     * entitlements are true.
     *
     * @param groupId
     * @param section
     * @return {undefined|{id: string, label: string, isGroup: boolean, sectionLabel,
     * groupLabel, groupId, types, groups, entitled: boolean, applicable: boolean}}
     */
    getDeepNestedRecord(groupId, section) {
        const records = util.findWhere(
            section.data,
            {
                id: groupId,
            },
        );

        let anyApplicable = false;
        let anyEntitled = false;
        let entitledType = '';

        // Return undefined if there is no BTR
        if (typeof records === 'undefined') {
            return undefined;
        }

        const entitlements = records.types[0].get('entitlements');

        /*
         * Entitled and Applicable are quite buried.
         * Doing both of these lookups kind of sucks. We could use a for loop.
         */
        const entitledItem = entitlements.find(entry => entry.get('actions').view.entitled);
        if (undefined !== entitledItem) {
            anyEntitled = true;
            entitledType = entitledItem.get('typeCode');
        }

        if (undefined !== entitlements.find(entry => entry.get('actions').view.applicable)) {
            anyApplicable = true;
        }

        return {
            id: groupId,
            label: groupId,
            isGroup: false,
            subGroupLabel: records.label,
            subGroupId: records.id,
            groupLabel: section.label,
            groupId: section.id,
            types: records.types,
            groups: records.groups,
            entitled: anyEntitled,
            applicable: anyApplicable,
            entitledType,
        };
    },

    coreTypeMap(section, group) {
        return group.types
            .filter(type => type.get('dataEntitlementsApplicable'))
            .map((type) => {
                // Uncomfortably deep nesting on the entitled & applicable values
                const { view } = type.get('entitlements').at(0).get('actions');

                return util.extend(
                    {},
                    {
                        id: type.id,
                        label: type.label,
                        types: group.types,
                        groups: group.groups,
                        subGroupLabel: group.label,
                        subGroupId: group.id,
                        groupLabel: section.label,
                        groupId: section.id,
                        entitled: view?.entitled,
                        applicable: view?.applicable,
                    },
                );
            });
    },

    /**
     * Mapping for lockbox reporting
     * @param {Object} group - lockbox group from reports section
     * @return {Array}
     */
    lockboxTypeMap(group) {
        return group.types
        // Must be marked as allowing data entitlements from server
            .filter(type => type.get('dataEntitlementsApplicable'))
            .map((type) => {
                // Grab the lockbox type used for data entitlements
                const { view } = type.get('entitlements').findWhere({
                    typeCode: CONSTANTS.LOCKBOX.TYPE,
                }).get('actions');

                return util.extend(
                    {},
                    {
                        id: type.id,
                        label: type.label,
                        types: group.types,
                        groups: group.groups,
                        subGroupLabel: group.label,
                        subGroupId: group.id,
                        groupLabel: group.label,
                        groupId: group.id,
                        entitled: view.entitled,
                        applicable: view.applicable,
                    },
                );
            });
    },

    reportingSectionRecords(model) {
        const [section] = this.getSectionData(model, 'REPORTS');
        const justBTR = section ? this.getDeepNestedRecord('BTR', section) : undefined;
        const coreMapFn = this.coreTypeMap.bind(this, section);

        // Make sure we have something entitled before we process
        if (!section || !section.data.length) {
            return [];
        }
        return util.chain(section.data)
            .flatten(true)
            .reject(group => util.contains(CONSTANTS.REPORTING_ENTITLEMENT_EXLUSIONS, group.id))
            .map(coreMapFn)
            .push(justBTR)
            .flatten(true)
            .compact() // in case BTR is undefined
            .value();
    },

    /**
     * Get records for the lockbox from reporting section only
     * @param {Model} model
     * @return {Array}
     */
    lockboxRecords(model) {
        const [section] = this.getSectionData(model, 'REPORTS');

        // Make sure we have something entitled before we process
        if (!section || !section.data.length) {
            return [];
        }
        return util.chain(section.data)
            .flatten(true)
            .filter(group => group.id === CONSTANTS.LOCKBOX.GROUP_ID)
            .map(this.lockboxTypeMap)
            .flatten(true)
            .value();
    },

    /**
     * Get records for the legacy entitlements from reporting section only
     * @param {Model} model
     * @return {Array}
     */
    legacyRecords(model) {
        const [section] = this.getSectionData(model, 'REPORTS');
        const coreMapFn = this.coreTypeMap.bind(this, section);

        // Make sure we have something entitled before we process
        if (!section || !section.data.length) {
            return [];
        }
        return util.chain(section.data)
            .flatten(true)
            .filter(group => group.id === CONSTANTS.LEGACY.GROUP)
            .map(coreMapFn)
            .flatten(true)
            .value();
    },
    paymentInitiatorGroupRecords(model) {
        const [section] = this.getSectionData(model, 'RISK');
        const justACH = section ? this.getDeepNestedRecord('ACHControlTotals', section) : undefined;

        // Make sure we have something entitled before we process
        if (!(justACH && justACH?.entitled)) {
            return [];
        }
        return util.chain(section.data)
            .filter(group => group.id === CONSTANTS.PAYMENY_INITIATOR_GROUP_IDS.ID)
            .map(data => ({
                ...data,
                id: CONSTANTS.ENTITY_TYPE.PAYMENY_INITIATOR_GROUP_IDS,
                isGroup: false,
                label: section.label,
                groupLabel: section.label,
                groupId: section.id,
                entitled: true,
                applicable: true,
            }))
            .flatten(true)
            .value();
    },
    locationRecords(model) {
        const [section] = this.getSectionData(model, 'RDC');
        const coreMapFn = this.coreTypeMap.bind(this, section);
        const isAccountEntitlement = this.hasEntitlementAttrForSection(model, 'RDC', 'BankAccount');

        /*
         * Make sure we have something entitled before we process
         * as well as check if this section should be shown or not based on user's
         * RDC entitlements.
         *
         * There is a case where the items of this section should show under the
         * direct Accounts tabs
         * if entitled that way
         */
        if (!section || !section.data.length || isAccountEntitlement) {
            return [];
        }

        return util.chain(section.data)
            .flatten(true)
            .map(coreMapFn)
            .flatten(true)
            .value();
    },

    /**
     * @method TOALocationRecords
     * @param {Model} model
     * @return {Array} - TOA locations
     */
    toaLocationRecords(model) {
        const [section] = this.getSectionData(model, 'REPORTS');
        const justBTR = section ? this.getDeepNestedRecord('BTR', section) : undefined;
        if (!(justBTR && justBTR.entitled)) {
            return [];
        }
        return util.chain(section.data)
            .filter(group => group.id === 'BTR')
            .map((data) => {
                const d = data;
                d.id = CONSTANTS.ENTITY_TYPE.TOA_LOCATIONS;
                d.isGroup = false;
                d.label = section.label;
                d.groupLabel = section.label;
                d.groupId = section.id;
                d.entitled = true;
                d.applicable = true;
                return d;
            })
            .flatten(true)
            .value();
    },

    /**
     * Get records for the bank widget entitlements from admin section only
     * @param {Model} model
     * @return {Array}
     */
    bankWidgetRecords(model) {
        const [section] = this.getSectionData(model, 'ADMIN');
        const coreMapFn = this.coreTypeMap.bind(this, section);

        // Make sure we have something entitled before we process
        if (!section || !section.data.length) {
            return [];
        }
        return util.chain(section.data)
            .flatten(true)
            .filter(group => group.id === CONSTANTS.BANK_WIDGET.GROUP
                    && group.types.some(type => type.id === CONSTANTS.BANK_WIDGET.TYPE))
            .map(coreMapFn)
            .flatten(true)
            .value();
    },

    /**
     * Note that the Risk Tab contains products for both RISK and CM
     * @param {Model} model
     * @return {Array}
     */
    riskSectionRecords(model) {
        let records = this.getSectionDataEntitlementRecords(model, 'RISK');
        /*
         * Filter to remove Risk types (positive pay and ACH rules)
         * columns because they will share a single column and we need to
         * manually build that column.
         * Here We are also removing Check Inquiry
         * As we are sharing a common column for Checks Paid Inquiry and Checks Stopped Inquiry
         */
        records = records.filter(record => !this.isRiskType(record.id));

        const riskRecord = this.getRISKProductRecord(model);
        const checkInquiry = this.getCheckInquiry(model);
        if (records && riskRecord) {
            records.unshift(riskRecord);
        }
        if (records && checkInquiry) {
            records.unshift(checkInquiry);
        }
        return records;
    },

    getRISKProductRecord(model) {
        const [section] = this.getSectionData(model, 'RISK');

        // Make sure we have something entitled before we process
        if (!section || !section.data.length) {
            return undefined;
        }

        const records = util.find(
            section.data[0].types,
            type => this.isRiskType(type.id) && this.isTypeEntitled(type),
        );

        // Return undefined if there are no records
        if (typeof records === 'undefined') {
            return undefined;
        }

        return {
            id: CONSTANTS.RISK_POSITIVE_PAY,
            label: 'RISK.*',
            isGroup: false,
            subGroupLabel: records.label,
            subGroupId: records.id,
            groupLabel: section.label,
            groupId: section.id,
            types: records.types,
            groups: records.groups,
            entitled: true,
            applicable: true,
        };
    },
    getCheckInquiry(model) {
        const [section] = this.getSectionData(model, 'RISK');
        if (section === undefined) return undefined;

        const record = util.findWhere(
            section.data,
            {
                id: 'CheckInquiry',
            },
        );
        if (record === undefined) return undefined;

        const type = util.find(record.types, item => item.id === CONSTANTS.CHECK_INQUIRY);

        // if record do not contain Check-Inquiry Entitlment with id CHECKINQ
        if (type === undefined) return undefined;

        const entitlement = type.get('entitlements');
        const { actions } = entitlement.models[0].attributes;
        const entitled = actions.CHCKPAID?.entitled
                || actions.CHCKSTOP?.entitled;

        return {
            id: type.id,
            label: type.label,
            types: record.types,
            groups: record.groups,
            subGroupLabel: record.label,
            subGroupId: record.id,
            groupLabel: section.label,
            groupId: section.id,
            entitled,
            applicable: true,
        };
    },

    isRiskType(typeCode) {
        return [
            CONSTANTS.RISK_POSITIVE_PAY,
            CONSTANTS.RISK_ACH_AUTH_RULE,
            CONSTANTS.CHECK_INQUIRY,
            CONSTANTS.ACH_CONTROLS_TOTAL,
        ].indexOf(typeCode) > -1;
    },

    absSectionRecords(model) {
        const [section] = this.getSectionData(model, 'ABS');
        // Make sure we have something entitled before we process
        if (!section || !section.data.length) {
            return [];
        }

        const msgRecord = this.getABSSectionByFunction(section, CONSTANTS.ABS_MESSAGE);
        const reqRecord = this.getABSSectionByFunction(section, CONSTANTS.ABS_REQUEST);
        return util.compact([msgRecord, reqRecord]);
    },

    getABSSectionByFunction(section, functionCode) {
        const records = util.find(
            section.data[0].types,
            type => type.get('functionCode') === functionCode && this.isTypeEntitled(type),
        );

        // Return undefined if there are no records
        if (typeof records === 'undefined') {
            return undefined;
        }

        return {
            id: `ABS${functionCode}`,
            label: `ABS${functionCode}`,
            isGroup: false,
            subGroupLabel: records.label,
            subGroupId: records.id,
            groupLabel: section.label,
            groupId: section.id,
            types: records.types,
            groups: records.groups,
            entitled: true,
            applicable: true,
        };
    },

    isTypeEntitled(type) {
        return type.get('entitlements').find(entry => entry.get('actions').view?.entitled) !== undefined;
    },

    rdcBankAccountRecords(model) {
        return this.getSectionDataEntitlementRecords(model, 'RDC');
    },

    getSectionDataEntitlementRecords(model, sectionName, filter) {
        const [section] = this.getSectionData(model, sectionName);
        const coreMapFn = this.coreTypeMap.bind(this, section);

        // Make sure we have something entitled before we process
        if (!section || !section.data.length) {
            return [];
        }

        return util.chain(section.data)
            .flatten(true)
            .filter(group => (!filter ? true : filter(group)))
            .map(coreMapFn)
            .flatten(true)
            .value();
    },

    /**
     * Accumulate the grid
     * @param {Array} gridContent
     * @param {object} eStatementHeaderMatches
     * @return {Array}
     */
    getGridRowHeaders(gridContent, eStatementHeaderMatches) {
        const headers = util.extend({}, eStatementHeaderMatches);
        util.extend(headers, this.getTransferHeaders(gridContent));
        util.extend(headers, { TEMPLATE: 'uce.templates' });

        return util.chain(gridContent)
            .filter(entry => entry.applicable && entry.entitled)
            .reduce((acc, f) => {
                acc[f.id.toUpperCase()] = f.groupLabel;
                return acc;
            }, headers)
            .value();
    },

    /**
     * Produce the Header matching hash object for transfers since debit and
     * credit are broken out
     * @param {Object} gridContent - Map of products.
     * @return {Object}
     */
    getTransferHeaders(gridContent) {
        const transferHeader = util.find(gridContent, (types) => {
            /*
             * TODO The models are not consistent with payments vs other groups.
             * Therefore check to see which object exists before extracting
             * id.  Most likely the collection is created incorrectly
             * for payment groups.
             */
            // const id = types.type ? types.type.get('id') : types.get('id');
            let id;
            if (types.type) {
                id = types.type.get('id');
            } else if (types.get) {
                id = types.get('id');
            } else {
                ({ id } = types);
            }
            return id && id.toUpperCase() === 'TRANSFER';
        });

        let transferTypes = {};
        if (!util.isNullOrUndefined(transferHeader)) {
            // Split out types for debit/credit.
            transferTypes = {
                TRANSFERBANKACCOUNT: transferHeader.groupLabel,
                TRANSFERBANKACCOUNTCR: transferHeader.groupLabel,
            };
        }

        return transferTypes;
    },

    /**
     * Produce the Header matching hash object for eStatements
     * @param {Object} typeProductMap - The source of all product/type combinations.
     * @return {Object}
     */
    getEStatementHeaders(typeProductMap) {
        return util.chain(typeProductMap)
            .pairs()
            .filter(pair => pair[0].indexOf('ESTMENT') > -1)
            .map((ecodeParam) => {
                const ecode = ecodeParam;
                ecode[1] = 'type.jrpt.report';
                return ecode;
            })
            .object()
            .value();
    },

    /**
     * Produce the manipulated records for eStatements
     * @param {Object} eStatementRecord - The source for producing the three
     * output records.
     * @param eStatementHeaderMatches
     * @return {Array}
     */
    getEstatementManipulatedRecords(eStatementRecord, eStatementHeaderMatches) {
        return util.map(eStatementHeaderMatches, (val, newKey) => util.extend(
            {},
            eStatementRecord,
            {
                id: newKey,
            },
        ));
    },

    /**
     * Accumulate records from the various soures.
     * Because we have different process for collecting data from different permissions,
     *  we created distinct functions for the groups to allow better code management.
     *  Here we can call each of them and return a single array.
     * @param {Model} model
     * @param {boolean} withHardcodedRecords
     * @param eStatementHeaderMatches
     * @return {Array}
     */
    getAllRecords(model, withHardcodedRecords, eStatementHeaderMatches) {
        const records = [
            this.paymentSectionRecords(model, CONSTANTS.PAYMENT_ENTITLEMENT_EXCLUSIONS),
            this.reportingSectionRecords(model),
            this.riskSectionRecords(model),
            this.absSectionRecords(model),
        ];

        let estatementRecord;

        /*
         * CASE: Add RDC (Remote Deposit Capture) records to all accounts if entitled
         * to for this section
         */
        if (this.hasEntitlementAttrForSection(model, 'RDC', 'BankAccount')) {
            records.push(this.rdcBankAccountRecords(model));
        }

        if (withHardcodedRecords) {
            /**
             * Special logic because ESTMENT becomes three columns.
             * Only check the records[1] because ESTMENT is in reportingSectionRecords
             */
            estatementRecord = util.find(records[1], rec => rec.id === 'ESTMENT');

            if (undefined !== estatementRecord) {
                records.push(this.getEstatementManipulatedRecords(
                    estatementRecord,
                    eStatementHeaderMatches,
                ));
            }
        }

        return util.flatten(records, true);
    },

    /**
     * Obtain all records across types and groups and return only the entitled ones
     * @param {Array} [records] - Allows us to pass in records for different types
     * @return {Array}
     */
    getAllEnabledEntitlements(records) {
        return util.filter(records, entry => entry.applicable && entry.entitled);
    },

    /* ******************************************************************************** */

    /**
     * @param {Model} model
     * @param {string} sectionId
     * @param {string} entitlement
     * Looks for a certain section and checks if it has the specified entitlement attribute
     *
     * These dataEntitlementAttributes are specific to the columns of a group
     * that depict the
     * entitlements involved in showing the group
     *
     * The only downside, is that these attributes are nested on a deeper level section >
     * group > types
     * (for service reasons)
     */
    hasEntitlementAttrForSection(model, sectionId, entitlement) {
        const [section] = this.getSectionData(model, sectionId);

        if (!section || !section.data.length) {
            return false;
        }

        const entitledAttributes = section.data[0].types[0].get('dataEntitlementsAttributes');
        return util.indexOf(entitledAttributes, entitlement) !== -1;
    },

    /**
     * Figure out if we have grouped the Payment entitlements
     * @param {Model} model
     * @return {boolean}
     */
    isGrouped(model) {
        const infoModel = model.roleModel || model.userModel;
        const groupBy = infoModel && infoModel.get('GROUPED_PERMISSIONS_BY');

        // NOTE: Defaults to empty string currently, which is the same as grouped.
        return groupBy === 'PAYMENT_GROUP' || groupBy === '';
    },

    /**
     *
     * @param {Object} context
     * @return {Array}
     */
    getRowHeaders(context) {
        const eStatementHeaderMatches = this.getEStatementHeaders(context.typeProductMap);
        delete eStatementHeaderMatches.ESTMENT;

        // Supports the context preload
        return this.getGridRowHeaders(context.permissionRecords
                || this.getAllRecords(
                    context.model,
                    true,
                    eStatementHeaderMatches,
                ), eStatementHeaderMatches);
    },

    /**
     * Map section groups to headers. Used by Payments section.
     * @param {Object} group
     * @return {Array}
     */
    getHeaderAsGroups(group) {
        const self = this;

        return util.map(group.data, (entry) => {
            // See if any type is entitled.
            const anyEntitled = undefined !== util.find(entry.types, type => type.get('entitled'));

            const anyApplicable = undefined !== util.find(entry.types, self.getApplicableType);
            const mergeEntry = entry.attributes || entry;

            return util.extend(
                {},
                mergeEntry,
                {
                    groupId: group.id,
                    groupLabel: group.label,
                    entitled: anyEntitled,
                    applicable: anyApplicable,
                },
            );
        });
    },

    /**
     * Map section types to headers. Used by Payments section.
     * @param {Object} section
     * @return {Array}
     */
    getHeaderAsTypes(section) {
        const self = this;

        return util.chain(section.data)
            .map(b => b.types.map(type => util.extend(
                {},
                type,
                {
                    subGroupLabel: b.label,
                    subGroupId: b.id,
                    groupLabel: section.label,
                    groupId: section.id,
                    entitled: type.get('entitled'),
                    applicable: self.getApplicableType(type),
                },
            )))
            .flatten()
            .filter(c => // Only get headers for entitled types
                c.applicable && c.entitled)
            .value();
    },

    /**
     * Build the column object
     * @param context
     * @param {Object} colGroups
     * @param {Object} col
     * @return {{field: string, label, localeKey: string, group: *, cellView:
     * marionette.ItemView, sortable: boolean, draggable: boolean, resizable: boolean,
     * filterable: boolean}}
     */
    generateCheckViewColumn(context, colGroups, col) {
        return {
            field: col.fieldName,
            label: col.displayName,
            localeKey: col.displayKey,
            group: colGroups[col.fieldName],
            cellView: this.generateCheckColumnCellView(col.fieldName, context),
            sortable: false,
            draggable: false,
            resizable: false,
            filterable: false,
        };
    },

    /**
     * Heavy lifting for the grid columns.
     * @param {Object} data
     * @param {Object} context
     * @return {Array}
     */
    generateColumns(data, context) {
        const colGroups = this.getRowHeaders(context);

        // TODO: Remove fallback account column
        const accountCol = util.find(data.rowHeader, this.accountDisplayPredicate)
                || util.find(data.rowHeader, this.accountPredicate);

        const otherColumns = [this.generateSelectAllColumnData()]
            .concat(util.filter(data.rowHeader, this.otherColumnsPredicate.bind(this)));

        /**
         * The mapper pulls in this for access to the other functions, the context for data,
         *  and the colGroups object and returns a curried function.
         * When it is passed to the .map() function, it takes each column name and
         * generates a
         *  column object with the fields needed by the Grid, including a custom
         * CellView for
         * the checkbox.
         */

        const checkViewMapper = this.generateCheckViewColumn.bind(this, context, colGroups);

        const mappedColumns = util.map(otherColumns, checkViewMapper);

        return [this.generateAccountHeaderColumn(context, accountCol)].concat(mappedColumns);
    },

    /**
     * Filter for Account Filter
     * @param {Object} row
     * @return {boolean}
     */
    accountPredicate(row) {
        return (row.fieldName || row.field) === 'ACCOUNTFILTER';
    },

    /**
     * Filter for the Account display
     * @param {Object} row
     * @return {boolean}
     */
    accountDisplayPredicate(row) {
        return (row.fieldName || row.field) === 'ACCOUNTDISPLAY';
    },

    /**
     * Filter out hidden columns
     * @param {Object} row
     * @return {boolean}
     */
    hiddenColumnsPredicate(row) {
        return util.contains(['USERID', 'USERGROUP'], (row.fieldName || row.field)) || row.type === dynPagesConstants.COLUMN_TYPE_HIDDEN;
    },

    /**
     * Filter for either of the Account columns
     * @param {Object} row
     * @return {boolean}
     */
    otherColumnsPredicate(row) {
        return !this.accountPredicate(row)
                && !this.accountDisplayPredicate(row)
                && !this.hiddenColumnsPredicate(row);
    },

    /**
     * Returns the custom view used for the check grid
     * @param {string} keyName
     * @param {Object} context
     * @return {*}
     */
    generateCheckColumnCellView(keyName, context) {
        return genGridCheck({
            template: checkGridCellTmpl,

            attributes() {
                const attrs = {};
                const header = context.getProxyColumn(keyName, true) || {};

                attrs.class = header.currentFuture ? 'mark' : 'nomark';

                return attrs;
            },

            // TODO: This wasn't accessible from the template, but keyName below was? WHY???
            key: keyName,

            useBind: true,
            readOnly: context.mode === 'view',

            isChecked() {
                const header = context.getProxyColumn(keyName, true) || {};
                const val = this.model.get(keyName);
                const cf = header.currentFuture;

                return (cf && val !== '') || val === '1';
            },

            isDisabled() {
                const header = context.getProxyColumn(keyName, true) || {};
                const val = this.model.get(keyName);
                const cf = header.currentFuture;

                return cf || val === '';
            },

            keyName() {
                return keyName;
            },
        });
    },

    generateAccountHeaderColumn(context, accountCol) {
        return {
            title: accountCol.displayName,
            field: accountCol.fieldName,
            localKey: accountCol.displayKey,
            type: 'string',
            sortable: false,
            draggable: false,
            filterable: util.partial(this.sharedFetchAction, context),
        };
    },

    generateSelectAllColumnData() {
        return {
            fieldName: 'selectAll',
            displayName: locale.get('uce.checkgridall'),
            displayKey: '',
        };
    },

    /**
     * Get the the data from the getDataEntitlements response
     * and return the row data as a map.
     * @param {Object} data [original data from server]
     * @param {boolean} permissionsChangedToByGroup [Indicates if payment permissions
     * are now grouped
     * @return {Array}
     */
    getRowData(data, permissionsChangedToByGroup) {
        return util.map(data.rows, row => row.columns.reduce((acc, col) => {
            const accumulator = acc;
            /*
             * If the payment permissions were changed from by type to group
             * all data from the server must be treated as if the payments
             * data entitlements are completely new.  Therefore we must wipe
             * out any original value that was obtained by the server from
             * the data entitlements originally there by type.
             */
            if (permissionsChangedToByGroup
                    && util.contains(CONSTANTS.PAYMENT_TYPE_PRODUCTS, col.fieldName)) {
                accumulator[col.fieldName] = '0';
            } else {
                accumulator[col.fieldName] = col.fieldValue;
            }
            return accumulator;
        }, {}));
    },

    /**
     * Simplify the permission section data for inclusion.
     */
    getSectionData(model, onlySection) {
        return model.get('sections')
            .filter(section => onlySection === undefined || section.get('id') === onlySection)
            .map(section => ({
                id: section.get('id'),
                label: section.get('label'),

                data: section.get('groups').map(groupModel => ({
                    id: groupModel.get('id'),
                    label: groupModel.get('label'),
                    groups: groupModel.get('aggregateModels').models,
                    types: groupModel.get('types').models,
                })),
            }));
    },

    /**
     *
     * @param {string} inquiryId
     * @return {{name: string, value: string}}
     */
    prepInquiryForRequest(inquiryId) {
        return [{
            name: 'inquiryID',
            value: inquiryId,
        }];
    },

    /**
     * Convert entitlements object into itemData for the server calls.
     * @param {Array} entitlements
     * @param {boolean} enableGroupType
     * @return {Array}
     */
    prepEntitlementsForRequest(entitlements, enableGroupType) {
        if (!entitlements || !entitlements.length) {
            return [];
        }

        return util.chain(entitlements)
            .map((entitlement) => {
                let itemData = {
                    name: entitlement.isGroup ? 'group' : 'type',
                    value: entitlement.id,
                };

                // Add type records for groups to allow proper account filtering.
                if (entitlement.isGroup && enableGroupType) {
                    itemData = [itemData].concat(entitlement.types.map(type => ({
                        name: 'groupType',
                        value: `${entitlement.id}-${type.id}`,
                    })));
                }

                return itemData;
            })
            .flatten(true) // Handle nested arrays for groupType
            .value();
    },

    sharedFetchAction(viewContext, page, records) {
        const viewContextParam = viewContext;
        const $spinner = viewContext.$el.find('[data-hook="spinner"]');

        // Extract data from current page
        viewContext.extractData();

        $spinner.removeClass('hide');
        viewContext.getCheckData(viewContext.grid, page, records)
            .then((responseData) => {
                const newData = gridDataAccess.getRowData(
                    responseData,
                    viewContext.permissionsChangedToByGroup,
                );

                /* eslint-disable no-param-reassign */
                viewContext.grid.collection.recordsPerPage = responseData.rowsPerPage;
                viewContext.grid.collection.totalCount = responseData.totalRows;
                /* eslint-enable no-param-reassign */
                viewContext.grid.collection.reset(newData);
                viewContextParam.gridData = responseData;
                $spinner.addClass('hide');
            }, (err) => {
                // TODO: Error handling
                window.console.log(err);
            });
    },
};

export default gridDataAccess;
