import React, {
  useMemo, useCallback, useReducer
} from 'react';
import Loading from '../Loading/Loading';
import NoContent from '../NoContent/NoContent';
import DataRow from '../DataRow/DataRow';
import DefinitionList from '../DefinitionList/DefinitionList';
import ActionGroup from '../ActionsGroup/ActionsGroup';

const defaultReducer = (state = {}, newState) => ({ ...state, ...newState });

const getDefaultState = ({
  initialData = [],
  initialColumns = [],
  initialSort = {},
  initialFilters = {},
  initialBulkActionDefs = [],
  initialRowActionDefs = {},
  initialTotalRows = 0,
  initialPageSize = 50
} = {}) => ({
  isPaginating: false,
  loading: true,
  dataChanging: false,
  drawerOpen: false,
  drawer: null,
  data: initialData,
  columnState: initialColumns,
  sortState: initialSort,
  filterState: initialFilters,
  savedViewState: {},
  bulkActionDefs: initialBulkActionDefs,
  rowActionDefs: initialRowActionDefs,
  bulkActionState: null,
  selectedRows: [],
  pageSize: initialPageSize,
  page: 1,
  totalRows: initialTotalRows,
  enableSavedViews: false,
  defaultSavedView: undefined
});

const useMobileGrid = ({
  initialData = [],
  initialColumns = [],
  initialSort = {},
  initialFilters = {},
  initialBulkActionDefs = [],
  initialRowActionDefs = {},
  initialTotalRows = 0,
  initialPageSize = 50,
  gridReducer,
  initialState,
  forceOpenRows = []
} = {}) => {
  const [gridState, setGridState] = useReducer(
    gridReducer || defaultReducer,
    initialState || getDefaultState({
      initialData,
      initialColumns,
      initialSort,
      initialFilters,
      initialBulkActionDefs,
      initialRowActionDefs,
      initialTotalRows,
      initialPageSize
    })
  );
  const {
    loading,
    dataChanging,
    data,
    columnState,
    rowActionDefs,
    bulkActionState,
    selectedRows
  } = gridState;

  // Column definitions
  const columnsWithoutActions = useMemo(
    () => columnState.filter(c => c.field !== 'actions'), [columnState]
  );
  const primaryColumns = useMemo(
    () => columnsWithoutActions.filter(c => c.primary && !c.hide), [columnsWithoutActions]
  );
  const sortColumns = useMemo(
    () => columnsWithoutActions.filter(c => c.sortable), [columnsWithoutActions]
  );
  const visibleColumns = useMemo(
    () => columnsWithoutActions.filter(c => !c.primary && !c.hide), [columnsWithoutActions]
  );
  const filterColumns = useMemo(
    () => columnsWithoutActions.filter(c => c.filterable), [columnsWithoutActions]
  );

  // Drawer Actions
  const openDrawer = useCallback((newDrawer) => {
    setGridState({ drawerOpen: true, drawer: newDrawer });
  }, []);
  const closeDrawer = useCallback(() => setGridState({ drawerOpen: false }), []);

  // Grid Actions
  const onBulkActionSelect = useCallback(({ rowId }) => {
    const newState = selectedRows.includes(rowId)
      ? selectedRows.filter(id => id !== rowId)
      : selectedRows.concat(rowId);
    setGridState({ selectedRows: newState });
  }, [selectedRows]);
  const onBulkActionSelectAll = useCallback(
    (checked) => setGridState({ selectedRows: checked ? data.map(d => d.id) : [] }), [data]
  );
  const isSelectAllChecked = useMemo(
    () => data.every(d => selectedRows.includes(d.id)), [selectedRows, data]
  );

  const gridContent = useMemo(() => {
    if (loading || dataChanging) {
      return <Loading primaryColumnLength={primaryColumns.length} />;
    }
    if (!data.length) {
      return <NoContent primaryColumnLength={primaryColumns.length} />;
    }
    return data.map((rowData) => (
      <DataRow
        key={`data-row-${rowData.id}`}
        rowId={rowData.id}
        primaryColumns={primaryColumns}
        rowData={rowData}
        bulkActionsEnabled={!!bulkActionState}
        isSelected={selectedRows.includes(rowData.id)}
        onBulkActionSelect={onBulkActionSelect}
        forceOpen={forceOpenRows.includes(rowData.id)}
      >
        <DefinitionList
          columns={visibleColumns}
          rowData={rowData}
          rowId={rowData.id}
        />
        <ActionGroup
          rowData={rowData}
          actionDefs={rowActionDefs}
        />
      </DataRow>
    ));
  }, [
    data,
    loading,
    primaryColumns,
    visibleColumns,
    rowActionDefs,
    bulkActionState,
    selectedRows,
    onBulkActionSelect,
    dataChanging,
    forceOpenRows
  ]);

  return useMemo(() => ({
    ...gridState,
    // grid content
    gridContent,
    // drawers
    closeDrawer,
    openDrawer,
    // bulk actions
    onBulkActionSelect,
    onBulkActionSelectAll,
    isSelectAllChecked,
    // columns
    columnsWithoutActions,
    primaryColumns,
    visibleColumns,
    sortColumns,
    filterColumns,
    setGridState
  }), [
    gridState,
    gridContent,
    isSelectAllChecked,
    closeDrawer,
    openDrawer,
    onBulkActionSelect,
    onBulkActionSelectAll,
    columnsWithoutActions,
    primaryColumns,
    visibleColumns,
    sortColumns,
    filterColumns,
    setGridState
  ]);
};

export default useMobileGrid;
