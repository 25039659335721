/* istanbul ignore file */

export const styles = ({ palette }) => ({
  input: {
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '-moz-appearance': 'textfield',
    background: ({ dark }) => (dark ? 'inherit' : palette.background),
    border: `1px solid ${palette.form.border}`,
    borderRadius: 2,
    boxSizing: 'border-box',
    color: ({ dark }) => (dark ? palette.text.light : palette.text.dark),
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1,
    padding: [7.5, 10],
    width: '100%'
  },
  rangeWrapper: {
    '&:nth-child(2)': {
      marginLeft: 10
    },
    display: 'inline-block',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    textTransform: 'capitalize'
  },
  wrapper: {
    color: ({ dark }) => (dark ? palette.text.light : palette.text.dark),
    display: 'flex',
    justifyContent: 'space-between'
  }
});

export default styles;
