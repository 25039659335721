import Layout from '@glu/core/src/layout';
import $ from 'jquery';
import CollectionView from '@glu/core/src/collectionView';
import Collection from '@glu/core/src/collection';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import FlexDropdown from '@glu/flex-dropdown';
import { createTabsToggleButton, toggleTabs, setTabButtonText } from 'common/util/a11y/tabs';
import errHandler from 'system/errHandler';
import store from 'system/utilities/cache';
import services from 'services';
import VendorFields from 'app/administration/views/sso/vendorFields';
import VendorFieldsModel from 'app/administration/models/ssoVendorFields';
import VendorTabModel from 'app/administration/models/ssoVendor';
import transform from 'common/util/transform';
import scroll from 'common/util/scroll';
import alertMessage from 'common/api/alertMessage';
import adminConstants from 'app/administration/constants';
import template from 'app/administration/views/sso/tabWrapper.hbs';
import loadingTemplate from 'common/templates/loadingPage.hbs';

const constants = {
    LOGIN_PROTOCOL: 44001,
    LOGOUT_METHOD: 44002,
    FIELD_TYPE: 44003,
    FIELD_CONTENT_TYPE: 44004,
    MENU_GROUP: 44005,
    MENU_ITEM: 44006,
    USAGE: 44007,
    MENU_GROUP_REGION: 'menuGroupDropdownRegion',
    MENU_ITEM_REGION: 'menuItemDropdownRegion',
    VENDOR_PROTOCOL_REGION: 'vendorPrototolDropdownRegion',
    LOGOUT_TYPE_REGION: 'logoutTypeDropdownRegion',
    SHOW_VENDOR_REGION: 'showVendorDropdownRegion',
    ACTION_DELETE: 'DELETE',
    ACTION_MODIFY: 'MODIFY',
    ACTION_EXPORT: 'MODIFYVFIMPORT',
};

export default Layout.extend({
    template,
    loadingTemplate,

    ui: {
        $vendorDetailRegion: '[data-hook="getVendorDetailRegion"]',
        $vendorFieldsRegion: '[data-hook="getVendorFieldsRegion"]',
        $navTabs: '[data-hook="getNavTabs"]',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
        $detailListItem: '[data-hook="getDetailItem"]',
        $logoutMethodIcon: '[data-hook="getLogoutMethodIcon"]',
        $popovers: '[data-hook="getLogoutMethodIcon"]',
        $fieldsListItem: '[data-hook="getFieldsItem"]',
        $submit: '[data-hook="getSubmit"]',
        $modify: '[data-hook="getModify"]',
        $delete: '[data-hook="getDelete"]',
        $export: '[data-hook="getExport"]',
        $cancel: '[data-hook="getCancel"]',
        $addRequestField: '[data-hook="getAddRequestField"]',
        $addRequestFieldCount: '[data-hook="getAddRequestFieldCount"]',
    },

    events: {
        'click @ui.$navLinks': 'switchTabs',
        'click @ui.$submit': 'submit',
        'click @ui.$modify': 'modify',
        'click @ui.$delete': 'delete',
        'click @ui.$export': 'export',
        'click @ui.$cancel': 'cancel',
        'click @ui.$addRequestField': 'addRequestField',
    },

    initialize() {
        this.model = new VendorTabModel();
        this.mode = this.options.mode;
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            const self = this;
            createTabsToggleButton(this);
            this.currentTabText = locale.get('sso.detail');
            setTabButtonText(this, this.currentTabText);
            this.fieldsCollectionView = new CollectionView({
                itemView: VendorFields,

                itemViewOptions: {
                    comboData: {
                        fieldTypeData: this.fieldTypeData,
                        fieldContentTypeData: this.fieldContentTypeData,
                        usageData: this.usageData,
                    },

                    mode: this.mode,
                    getRecordCount: this.getCount.bind(this),
                },

                collection: this.getCollection(),
            });

            this.on('add:fields', this.fieldsCollectionView.render, this.fieldsCollectionView);

            this.fieldsCollectionView.on('itemview:remove:field', (childView, model) => {
                self.fieldsCollectionView.collection.remove(model);
                self.fieldsCollectionView.render();
            });

            this.vendorFieldsRegion.show(this.fieldsCollectionView);
            this.createDropdowns(this.mode !== 'SELECT');

            this.ui.$popovers.popover({
                trigger: 'focus hover',
                html: true,
            });
        } else {
            this.loadRequiredData();
        }
    },

    /**
     * - returns a collection based on the mode of the view
     * @method getCollection
     * @return {collection}
     */
    getCollection() {
        if (this.mode === 'INSERT') {
            return new Collection(
                {},
                {
                    model: VendorFieldsModel,
                },
            );
        }
        const collection = new Collection(
            null,
            {
                model: VendorFieldsModel,
            },
        );

        const fields = this.model.REQUESTFIELDS;
        util.each(fields, (field) => {
            const fieldItemHash = transform.pairsToHash(field.item, 'name', 'value');
            collection.add(new VendorFieldsModel(fieldItemHash));
        });
        return collection;
    },

    /**
     * - Get the current collection length
     * @method getCount
     * @returns {Number} - the length
     */
    getCount() {
        return this.fieldsCollectionView.collection.length;
    },

    /**
     * - create multiple flex dropdowns
     * @method createDropdowns
     * @param {boolean} isReadOnlyMode - true/false if dropdowns should be created
     */
    createDropdowns(isReadOnlyMode) {
        if (!isReadOnlyMode) {
            return;
        }
        this.menuGroupDropdown = this.createDropdown('menuGroupDropdownRegion', this.menuGroupData);
        this.menuItemDropdown = this.createDropdown('menuItemDropdownRegion', this.menuItemData);
        this.vendorProtocolDropdown = this.createDropdown('vendorPrototolDropdownRegion', this.loginProtocolData);
        this.logoutTypeDropdown = this.createDropdown('logoutTypeDropdownRegion', this.logoutMethodData);
        this.showVendorDropdown = this.createDropdown(
            'showVendorDropdownRegion',
            {
                data: [{
                    name: locale.get('sso.before'),
                    id: 0,
                    fieldName: 'BEFOREAFTER',
                    fieldValue: locale.get('sso.before'),
                }, {
                    name: locale.get('sso.after'),
                    id: 1,
                    fieldName: 'BEFOREAFTER',
                    fieldValue: locale.get('sso.after'),
                }],
            },
        );

        this.listenTo(this.menuGroupDropdown, 'selectionChanged', this.processMenuGroupChange);
        this.listenTo(this.menuItemDropdown, 'selectionChanged', this.setSelectedValue);
        this.listenTo(this.vendorProtocolDropdown, 'selectionChanged', this.setSelectedValue);
        this.listenTo(this.logoutTypeDropdown, 'selectionChanged', this.setSelectedValue);
        this.listenTo(this.showVendorDropdown, 'selectionChanged', this.setSelectedValue);
    },

    /**
     * - create a flex dropdown
     * @method createDropdown
     * @param {string} region - the region the dropdown should be added to
     * @param {object} optionsParam - the data options object required by all Flex Dropdowns
     * @returns {object} - the dropdown
     */
    createDropdown(region, optionsParam) {
        const options = optionsParam;
        options.multiSelect = false;
        options.disableMultiButton = true;
        options.showTooltip = false;
        if (this.mode !== 'INSERT') {
            this.processPreselectedIds(region, options);
        }
        if (region === constants.MENU_ITEM_REGION && util.isEmpty(options.data)) {
            options.defaultSelectMessage = locale.get('sso.select.menuGroup.message');
        }
        const dropdown = new FlexDropdown(options);

        this[region].show(dropdown);

        return dropdown;
    },

    /**
     * - function called when a dropdown is created
     * @method processPreselectedIds
     * @param {string} region - the string name of the region
     * @param {object} optionsParam - the FlexDropdown options with data
     */
    processPreselectedIds(region, optionsParam) {
        const options = optionsParam;
        switch (region) {
        case constants.MENU_GROUP_REGION:
            options.preSelected = util.where(
                options.data,
                {
                    fieldValue: this.model.get('MENUCATEGORY'),
                },
            );
            break;
        case constants.MENU_ITEM_REGION:
            options.preSelected = util.where(
                options.data,
                {
                    fieldValue: this.model.get('MENUCODE'),
                },
            );
            break;
        case constants.VENDOR_PROTOCOL_REGION:
            options.preSelected = util.where(
                options.data,
                {
                    fieldValue: this.model.get('TPV_TYPE'),
                },
            );
            break;
        case constants.SHOW_VENDOR_REGION:
            options.preSelected = util.where(
                options.data,
                {
                    fieldValue: this.model.get('BEFOREAFTER'),
                },
            );
            break;
        case constants.LOGOUT_TYPE_REGION:
            options.preSelected = util.where(
                options.data,
                {
                    fieldValue: this.model.get('LOGOUT_TYPE'),
                },
            );
            break;
        default:
        }
    },

    /**
     * - function called when user selectes a tab
     * - displays proper tab
     * @method switchTabs
     * @param {object} e - the event triggered by clicking a tab
     */
    switchTabs(e) {
        this.currentTabText = $(e.target).text();
        if ($(e.target).data('step') === 'fields') {
            this.showFieldsTab();
        } else {
            this.showDetailTab();
        }
        toggleTabs(this);
    },

    /**
     * - display proper content for fields tab
     * @method showFieldsTab
     */
    showFieldsTab() {
        this.ui.$vendorDetailRegion.addClass('hide');
        this.ui.$vendorFieldsRegion.removeClass('hide');
        this.ui.$fieldsListItem.addClass('is-active');
        this.ui.$detailListItem.removeClass('is-active');
    },

    /**
     * - display proper content for detail tab
     * @method showDetailTab
     */
    showDetailTab() {
        this.ui.$vendorDetailRegion.removeClass('hide');
        this.ui.$vendorFieldsRegion.addClass('hide');
        this.ui.$fieldsListItem.removeClass('is-active');
        this.ui.$detailListItem.addClass('is-active');
    },

    /**
     * - ensure data for dropdowns has been loaded
     * - if so, call the render method
     * - on modify/view workflow fetch the menu items after menu group data loaded
     * onto the model
     * @method loadRequiredData
     */
    loadRequiredData() {
        const self = this;
        const successCallback = (model) => {
            self.menuItemData = model;
            if (self.mode === 'SELECT') {
                const selectedMenuCode = util.where(
                    self.menuItemData.data,
                    {
                        fieldValue: self.model.get('MENUCODE'),
                    },
                );
                const selectedMenuGroup = util.where(
                    self.menuGroupData.data,
                    {
                        fieldValue: self.model.get('MENUCATEGORY'),
                    },
                );
                if (selectedMenuCode.length > 0) {
                    self.model.set('MENUCODE', selectedMenuCode[0].name);
                }
                if (selectedMenuGroup.length > 0) {
                    self.model.set('MENUCATEGORY', selectedMenuGroup[0].name);
                }
            }

            self.setHasLoadedRequiredData(true);
            self.render();
        };
        this.getRequiredPromises()
            .then((results) => {
                [
                    self.loginProtocolData,
                    self.logoutMethodData,
                    self.fieldTypeData,
                    self.fieldContentTypeData,
                    self.menuGroupData,
                    self.menuItemData,
                    self.usageData,
                ] = results;

                if (self.mode !== 'INSERT') {
                    self.fetchMenuItems(successCallback, self.model.get('MENUCATEGORY'));
                } else {
                    self.setHasLoadedRequiredData(true);
                    self.render();
                }
            })
            .then(null, errHandler);
    },

    /**
     * - build an array of promises that use the model to get data for dropdowns
     * @method getRequiredPromises
     * @returns {object} - the promise indicating all promises have been resolved
     */
    getRequiredPromises() {
        const self = this;
        const requiredPromises = [];
        const addPromise = function (inquiryId) {
            requiredPromises.push(new Promise((resolve, reject) => {
                if (inquiryId) {
                    self.model.fetchComboData({
                        success: resolve,
                        error: reject,
                        inquiryId,
                    });
                } else {
                    self.model.fetch({
                        success: resolve,
                        error: reject,
                    });
                }
            }));
        };

        addPromise(constants.LOGIN_PROTOCOL);
        addPromise(constants.LOGOUT_METHOD);
        addPromise(constants.FIELD_TYPE);
        addPromise(constants.FIELD_CONTENT_TYPE);
        addPromise(constants.MENU_GROUP);
        addPromise(constants.MENU_ITEM);
        addPromise(constants.USAGE);
        if (this.mode !== 'INSERT') {
            this.gridModel = store.get('SSOADMIN:actionModel');
            this.model.set('TPV_ID', this.gridModel.get('TPV_ID'));
            addPromise();
        }

        return Promise.all(requiredPromises);
    },

    /**
     * - sets the selected dropdown value on the model
     * @method setSelectedValue
     * @param {array} selected - array of selected objects (see Glu docs for details)
     */
    setSelectedValue(selected) {
        if (selected[0].fieldName) {
            this.model.set(selected[0].fieldName, selected[0].fieldValue);
        }
    },

    /**
     * - add request fields to model
     * @method setRequestFields
     */
    setRequestFields() {
        this.model.set('grids', this.getRequestFields());
    },

    /**
     * - builds an array of request field items
     * @method getRequestFields
     * @returns {array} - the request field items
     */
    getRequestFields() {
        const self = this;

        const reqFieldItems = {
            name: 'SSOVENDORSFIELDSGRID',
            items: [],
        };

        this.fieldsCollectionView.isValid = true;
        this.fieldsCollectionView.children.each((view) => {
            reqFieldItems.items.push(view.model.convertModelAttributesToServerJSON());
            if (!view.model.isValid()) {
                view.model.trigger('invalid');
                self.fieldsCollectionView.isValid = false;
            }
        });
        return [reqFieldItems];
    },

    /**
     * - used when the menu group is changed.
     * @method processMenuGroupChange
     * @param {object} data - flexdropdown object containing data about the selection
     */
    processMenuGroupChange(data) {
        const self = this;
        const successCallback = function (model) {
            self.menuItemDropdown = self.createDropdown('menuItemDropdownRegion', model);
            self.listenTo(self.menuItemDropdown, 'selectionChanged', self.setSelectedValue);
        };
        this.fetchMenuItems(successCallback, data);
        this.setSelectedValue(data);
    },

    /**
     * - used when the menu group is changed. fetches the menu items based on
     * selected menucategory
     * @method fetchMenuItems
     * @param {function} callback - a callback for the success handler
     * @param {Object[]} menuCategory - An array or string containing the menu
     * category of the menu items
     */
    fetchMenuItems(callback, menuCategory) {
        this.model.fetchComboData({
            success: callback,
            inquiryId: constants.MENU_ITEM,
            menuCategory: util.isArray(menuCategory)
                ? menuCategory[0].fieldValue : menuCategory,
        });
    },

    /**
     * - use the alert message api to render a response alert
     * @method renderMessage
     * @param {object} confirmResponse - a response message object
     */
    renderMessage(confirmResponse) {
        if (typeof confirmResponse === 'object' && confirmResponse.message.length > 0) {
            alertMessage.renderMessage(this, 'DANGER', confirmResponse);
        }
    },

    /**
     * - confirm the model and fields collection are valid
     * - save the model, navigate or display message depending on result
     * @method submit
     */
    submit() {
        const self = this;
        this.setRequestFields();

        if (!this.model.isValid()) {
            this.showDetailTab();
            this.model.trigger('invalid');
            scroll.scrollToFirstError();
            return;
        }
        if (!this.fieldsCollectionView.isValid) {
            this.showFieldsTab();
            scroll.scrollToFirstError();
            return;
        }

        this.prepDataForAPI();

        this.model.save(
            {},
            {
                success(model, confirmResponse) {
                    store.set('sso:action:success', confirmResponse);
                    self.navigateTo('SSOADMIN/ssoAdministration');
                },

                error(result) {
                    self.renderMessage(result.error);
                },
            },
        );
    },

    /**
     * - adds an empty model to the collectionView when the "Add" button is clicked
     * @method addRequestField
     */
    addRequestField() {
        const count = this.ui.$addRequestFieldCount.val();
        for (let x = 0; x < count; x += 1) {
            this.fieldsCollectionView.collection.add(new VendorFieldsModel());
        }
        this.trigger('add:fields');
    },

    /**
     * - navigate to modify route
     * @method modify
     */
    modify() {
        this.navigateTo('SSOADMIN/modifyVendor');
    },

    /**
     * - manipulate DOM, submit form
     * @method export
     */
    export() {
        if (this.$('#SSOExportForm').length === 0) {
            const form = '<form id="SSOExportForm"  method="get" target="_self"><input id="TPV_ID" type="hidden" name="TPV_ID" ></form>';
            this.$el.append(form);
        }
        this.$('#SSOExportForm').attr('action', services.generateUrl(`/tableMaintenance/ssoVendors/export/${this.model.get('TPV_ID')}`));
        this.$('#SSOExportForm #TPV_ID').val(JSON.stringify(this.model.get('TPV_ID')));
        this.$('#SSOExportForm').submit();
    },

    /**
     * delete a grid row, refresh the grid and display a message
     */
    delete() {
        return new Promise((resolve, reject) => {
            const self = this;
            this.model.destroy({
                success(model, confirmResponse) {
                    store.set('sso:action:success', confirmResponse);
                    self.navigateTo('SSOADMIN/ssoAdministration');
                    resolve({ model, result: confirmResponse });
                },

                error(result) {
                    self.renderMessage(result.error);
                    reject(result.error);
                },
            });
        });
    },

    /**
     * - navigate back
     * @method cancel
     */
    cancel() {
        window.history.back();
    },

    /**
     * - convert boolean values into text usable in view templates
     * @method convertBoolean
     * @param {Boolean} val - value to convert
     * @returns {String} - localized text for "Yes" or "No"
     */
    convertBoolean(val) {
        const num = +val; // convert string to number

        return num === 1 ? locale.get('common.yes') : locale.get('common.no');
    },

    /**
     * - wrapper for boolean conversion method
     * @method getKeyAsBooleanText
     * @param {String} key - the object key to retreive
     * @returns {String} - localized text for "Yes" or "No"
     */
    getKeyAsBooleanText(key) {
        return this.convertBoolean(this.model.get(key));
    },

    /**
     * - convert values into text usable in view templates
     * @method convertOption
     * @param {String} type - the type
     * @param {String} option - value to convert
     * @returns {String} - the text to display in the view
     */
    convertOption(type, option) {
        const num = +option;
        let text = '';

        switch (num) {
        case 0:
            text = type === 'checkbox' ? locale.get('sso.login') : locale.get('sso.none');
            break;
        case 1:
            text = type === 'checkbox' ? locale.get('sso.logout') : locale.get('sso.single');
            break;
        case 2:
            text = type === 'checkbox' ? locale.get('sso.redirect') : locale.get('sso.multi');
            break;
        case 3:
            text = locale.get('common.multiple');
            break;
        default:
        }

        return text;
    },

    /**
     * Convert checkbox values into a single value usable by the backend
     * @param {Boolean} login - Login checkbox value
     * @param {Boolean} logout - Logout checkbox value
     * @param {Boolean} redirect - Redirect checkbox value
     * @returns {Number} - the value to send to the API
     */
    prepCheckboxesForAPI(login, logout, redirect) {
        return (login ? adminConstants.SSO_ADMIN.LOGIN_MASK : 0)
            + (logout ? adminConstants.SSO_ADMIN.LOGOUT_MASK : 0)
            + (redirect ? adminConstants.SSO_ADMIN.REDIRECT_MASK : 0);
    },

    /**
     * - convert checkbox values into text usable by the UI
     * @method prepCheckboxesForView
     * @param {Number} mask - the number value stored in the database
     * @returns {String} - the text to display
     */
    prepCheckboxesForView(mask) {
        return util.reject([
            // eslint-disable-next-line no-bitwise
            (mask & adminConstants.SSO_ADMIN.LOGIN_MASK) ? locale.get('sso.login') : '',
            // eslint-disable-next-line no-bitwise
            (mask & adminConstants.SSO_ADMIN.LOGOUT_MASK) ? locale.get('sso.logout') : '',
            // eslint-disable-next-line no-bitwise
            (mask & adminConstants.SSO_ADMIN.REDIRECT_MASK) ? locale.get('sso.redirect') : '',
        ], util.isEmpty).join(', ');
    },

    /**
     * - transform data so its consumable by backend
     * @method prepDataForAPI
     */
    prepDataForAPI() {
        this.model.set('BEFOREAFTER', this.model.get('BEFOREAFTER ') === 'Before' ? 0 : 1);

        this.model.set('USE_POST_METHOD', this.prepCheckboxesForAPI(
            this.model.get('POST_METHOD_CHECKBOX_LOGIN'),
            this.model.get('POST_METHOD_CHECKBOX_LOGOUT'),
            this.model.get('POST_METHOD_CHECKBOX_REDIRECT'),
        ));

        this.model.set('USE_CLIENT_CERTIFICATES', this.prepCheckboxesForAPI(
            this.model.get('CLIENT_CERTIFICATES_CHECKBOX_LOGIN'),
            this.model.get('CLIENT_CERTIFICATES_CHECKBOX_LOGOUT'),
            this.model.get('CLIENT_CERTIFICATES_CHECKBOX_REDIRECT'),
        ));
    },

    hasEntitlement(type) {
        return this.gridModel.entitlements[type] && this.gridModel.entitlements[type] === true;
    },

    templateHelpers() {
        const self = this;
        return {
            insert: this.options.mode === 'INSERT',
            modify: this.options.mode === 'MODIFY',
            readOnly: this.options.mode === 'SELECT',
            isExistingVendor: this.options.mode !== 'INSERT',
            logoutCloseSessionText: this.getKeyAsBooleanText('LOGOUT_CLOSE_SESSION'),
            newWindowText: this.getKeyAsBooleanText('NEW_WINDOW'),
            allowMultSessionText: this.getKeyAsBooleanText('ALLOW_MULT_SESSION'),
            usePostMethodText: this.prepCheckboxesForView(this.model.get('USE_POST_METHOD')),
            useClientCertificatesText: this.prepCheckboxesForView(this.model.get('USE_CLIENT_CERTIFICATES')),
            accountSelectionText: this.convertOption('radio', this.model.get('ACCOUNT_SELECTION')),

            hasModifyEntitlement() {
                return self.hasEntitlement(constants.ACTION_MODIFY);
            },

            hasDeleteEntitlement() {
                return self.hasEntitlement(constants.ACTION_DELETE);
            },

            hasExportEntitlement() {
                return self.hasEntitlement(constants.ACTION_EXPORT);
            },
        };
    },
});
