var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"form-container\">\n    <div class=\"population-controls population-methods\">\n        <div class=\"field-container-md\">\n            <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.PopulationSelectionMethod",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":58}}}))
    + "</h4>\n            <div>\n                <label for=\"include-method\"><input type=\"radio\" id=\"include-method\" value=\"INCLUSIVE\"\n                        name=\"selectionMethod\" data-bind=\"model\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disableControls") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":65},"end":{"line":7,"column":103}}})) != null ? stack1 : "")
    + ">\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.batch.include",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":53}}}))
    + "</label>\n            </div>\n            <div>\n                <label for=\"exclude-method\"><input type=\"radio\" id=\"exclude-method\" value=\"EXCLUSIVE\"\n                        name=\"selectionMethod\" data-bind=\"model\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disableControls") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":65},"end":{"line":12,"column":103}}})) != null ? stack1 : "")
    + ">\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.batch.exclude",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":13,"column":53}}}))
    + "</label>\n            </div>\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"method\"></span>\n        </div>\n    </div>\n    <div class=\"population-controls population-challenge\">\n        <div class=\"field-container-md\">\n            <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.PopulationForChallenge",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":20,"column":55}}}))
    + "</h4>\n            <div class=\"source-section-header\">\n                <div class=\"population-challenge-options\">\n    \n                    <div>\n                        <label for=\"mfa-segments\"> <input type=\"radio\" id=\"mfa-segments\" value=\"SEGMENTS\"\n                                name=\"populationType\" data-bind=\"model\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disableControls") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":72},"end":{"line":26,"column":110}}})) != null ? stack1 : "")
    + ">\n                            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segments",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":28},"end":{"line":27,"column":49}}}))
    + "</label>\n                    </div>\n                    <div>\n                        <label for=\"mfa-companies\"><input type=\"radio\" id=\"mfa-companies\" value=\"COMPANIES\"\n                                name=\"populationType\" data-bind=\"model\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disableControls") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":72},"end":{"line":31,"column":110}}})) != null ? stack1 : "")
    + ">\n                            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"Confirm.Multi.BDIFCHLD",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":28},"end":{"line":32,"column":63}}}))
    + "</label>\n                    </div>\n                    <div>\n                        <label for=\"mfa-users\"><input type=\"radio\" id=\"mfa-users\" value=\"USERS\" name=\"populationType\"\n                                data-bind=\"model\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disableControls") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":50},"end":{"line":36,"column":88}}})) != null ? stack1 : "")
    + ">\n                            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"Confirm.Multi.CLUSR_TM",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":37,"column":28},"end":{"line":37,"column":63}}}))
    + "</label>\n                    </div>\n                </div>\n            </div>\n    \n        </div>\n    </div>\n    <div class=\"population-heading\">\n        <h1>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.accounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":45,"column":12},"end":{"line":45,"column":48}}}))
    + "</h1>\n    </div>\n</div>\n<div>\n    <div data-region=\"controlPanelRegion\"></div>\n</div>";
},"useData":true});