var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <select class=\"perm-group-by\">\n            <option value=\"byGroup\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.byGroup",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":36},"end":{"line":20,"column":60}}}))
    + "</option>\n            <option value=\"byType\" selected>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.byType",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":44},"end":{"line":21,"column":67}}}))
    + "</option>\n        </select>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <h4>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.viewBy",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":26,"column":35}}}))
    + "</h4>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.paymentSettings",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":40}}}))
    + "</h2>\n    <div class=\"checkbox\">\n\n        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.selectBene",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":35}}}))
    + "\n\n        <div class=\"checkbox-inline\">\n            <input id=\"paymentsChk\" type=\"checkbox\" data-bind=\"model\" name=\"globalSettings.BENEPAY\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":100},"end":{"line":8,"column":142}}})) != null ? stack1 : "")
    + ">\n            <label for=\"paymentsChk\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.payments",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":37},"end":{"line":9,"column":73}}}))
    + "</label>\n        </div>\n        <div class=\"checkbox-inline\">\n            <input id=\"templatesChk\" type=\"checkbox\" data-bind=\"model\" name=\"globalSettings.BENETEMP\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":102},"end":{"line":12,"column":144}}})) != null ? stack1 : "")
    + ">\n            <label for=\"templatesChk\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.templates",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":38},"end":{"line":13,"column":75}}}))
    + "</label>\n        </div>\n    </div>\n\n    <h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.assignedPermissions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":17,"column":44}}}))
    + "</h3>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":23,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":4},"end":{"line":27,"column":15}}})) != null ? stack1 : "")
    + "</div>\n\n<!-- Swap out content below based on dropdown select-->\n<div data-region=\"permissionsRegion\"></div>";
},"useData":true});