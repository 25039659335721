var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog glu-dialog dialog-warning\">\n    <form class=\"modal-content\" data-submit=\"save\">\n        <div class=\"modal-header\">\n            "
    + alias2(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"close","action":"close","locale":"button.close","name":"close"},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":89}}}))
    + "\n            <h2 class=\"modal-title\">\n                <span class=\"icon-exclamation-solid\"></span>\n                <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Missing.Approvers.In.Sequence",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":22},"end":{"line":7,"column":81}}}))
    + "</span>\n            </h2>\n        </div>\n        <div class=\"modal-body\">\n            <div class=\"form-group\">\n                <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Missing.Approvers.Message.1",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":19},"end":{"line":12,"column":76}}}))
    + "</p>\n                <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Missing.Approvers.Message.2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":19},"end":{"line":13,"column":76}}}))
    + "</p>\n                <div data-region=\"listRegion\"></div>\n            </div>\n        </div>\n        <div class=\"modal-footer\">\n            <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Do.You.Wish.To.Continue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":15},"end":{"line":18,"column":68}}}))
    + "</p>\n            <button type=\"button\"\n                    data-hook=\"getYes\"\n                    class=\"btn btn-primary\">\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"yes",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":16},"end":{"line":22,"column":32}}}))
    + "\n            </button>\n            <button type=\"button\"\n                    class=\"btn btn-secondary\"\n                    data-hook=\"getNo\"\n                    data-dismiss=\"modal\">\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"no",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":16},"end":{"line":28,"column":31}}}))
    + "\n            </button>\n        </div>\n    </form>\n</div>\n";
},"useData":true});