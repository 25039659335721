import util from '@glu/core/src/util';
import AssetCollection from '../assets';
import LimitDataEntitlement from '../../models/user/limitDataEntitlement';

export default AssetCollection.extend({
    model: LimitDataEntitlement,

    initialize(models, options) {
        if (options) {
            this.inquiryId = options.inquiryId;
            this.keyCol = options.keyCol ? options.keyCol.toUpperCase() : null;
            this.configuredDataType = options.configuredDataType;
            this.buildCustomFilters(options);
        }
    },

    // TODO The function below can be removed once the code is correctd on the server.
    /**
     *
     * A temporary method to ensure that the selected account is actually still entitled.
     * Because of a server side bug, when an account is removed from a company,
     * the deletion
     * is not propagated to the data entitlements that already were selected.
     * Because of this,
     * accounts appear to still be entitled/selected even though the company
     * does not have
     * entitlements to the account.
     */

    accountsNotEntitled(availableAccounts) {
        const self = this;
        return this.filter((selected) => {
            const id = selected.get(self.keyCol);
            return availableAccounts.every(available => available.id !== id);
        });
    },

    /**
     * this is necessary to match up the uce read format of accounts, and the
     * inquiry format
     */
    matchRecord(rec, keyCol) {
        // Account numbers may contain multiple hyphens. Split only the first
        const separator = (keyCol.toUpperCase().indexOf('ACCOUNTFILTER') > -1 || keyCol.toUpperCase().indexOf('BENE_ACCOUNTENTITLEMENT') > -1) ? /-(.+)/ : '-';

        const parts = rec.get(keyCol.toUpperCase()).split(separator);

        if (keyCol === 'CLIENTCOMP_GROUPNUM') {
            return this.findWhere({
                dataEntitlementAttPart1: rec.get('COMPNAME'),
                dataEntitlementAttPart2: rec.get('COMPANYID'),
                dataEntitlementAttPart3: rec.get('CLEARINGSYSTEM'),
            });
        }

        if (keyCol.toUpperCase() === 'ACCOUNTFILTER' || keyCol.toUpperCase() === 'BENE_ACCOUNTENTITLEMENT') {
            // the split creates an unwanted empty string at the end of the array.
            parts.pop();
        }

        return this.findWhere({
            dataEntitlementAttPart1: parts[0],
            dataEntitlementAttPart2: parts.length > 1 ? parts[1] : null,
            dataEntitlementAttPart3: parts.length > 2 ? parts[2] : null,
        });
    },

    requestBody() {
        return {
            requestHeader: {},
            inquiryRequest: {
                searchCriteria: {
                    action: {
                        typeCode: this.inquiryId,
                        entryMethod: 0,
                        actionMode: 'SELECT',
                        functionCode: 'INQ',
                        productCode: '_POPUP',
                    },
                    customFilters: this.customFilters,
                    inquiryId: this.inquiryId,
                    searchType: '5',
                },
            },
        };
    },

    buildCustomFilters(options) {
        this.customFilters = [];

        if (!options) {
            return;
        }

        if (options.paymentType) {
            this.customFilters.push({
                filterName: 'Depends',
                filterParam: ['paymentType', options.paymentType],
            });
        }

        if (options.entitledTypes) {
            this.customFilters.push({
                filterName: 'DependsIN',
                filterParam: ['paymentTypes'].concat(options.entitledTypes.join()),
            });
        }

        if (options.userGroup) {
            this.customFilters.push({
                filterName: 'Depends',
                filterParam: ['UserGroup', options.userGroup],
            });
        }
    },

    updateActionsByPermissions(applicableActionsArr) {
        const hadApproval = this.hasApprove();
        let currentApproval;
        let lostApproval;
        let gainedApproval;
        const isUnselectedCollection = this.unselected || false;

        this.each((account) => {
            account.enableActionsFromPermissions(applicableActionsArr);
        });

        /*
         * Only trigger the hide/show of quick limits button if the collection lost
         * or gained its right to approval.  Otherwise it is not necessary to validate
         * the button.  Also only update for the selected collection.  It is
         * unnecessary to update the unselected collection since those items
         * are not rendered in the grid.
         */
        if (!isUnselectedCollection && this.length > 0) {
            currentApproval = this.hasApprove();
            lostApproval = hadApproval && !currentApproval;
            gainedApproval = !hadApproval && currentApproval;
            if (lostApproval || gainedApproval) {
                /*
                 * Indicate if the collection lost approval rights since
                 * some items must be hidden or blanked out.
                 */
                this.trigger('approvalRightsChanged:overall', lostApproval);
            }
        }
    },

    clearLimits() {
        this.each((account) => {
            account.clearLimits();
        });
    },

    applyQuickLimits(limitsModel) {
        // Grab only the models that have approval on
        util.each(this.filter(model => model.get('approve.entitled')), (model) => {
            model.updateLimits(limitsModel);
        });
    },

    hasApprove() {
        return this.some(model => model.get('approve.entitled'));
    },
});
