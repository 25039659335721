import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import http from '@glu/core/src/http';
import services from 'services';
import LineModel from '../model/line';

const Line = Collection.extend({
    model: LineModel,

    initialize(models, options) {
        this.nameIncrement = 0;

        this.givenColors = this.getLineColors();
        this.colors = this.givenColors.slice();
        [this.extraColor] = this.colors;

        this.listenTo(
            this,
            {
                add: this.assignRandomColor,
                remove: this.reclaimColor,
            },
            this,
        );

        /*
         * fetch once the scenario collection is populated
         * need to be able to associated scenarioIds to models
         */
        this.scenarioCollection = options.scenarioCollection;
        this.listenToOnce(
            this.scenarioCollection,
            {
                sync: this.fetch,
            },
            this,
        );

        this.listenToOnce(
            this,
            {
                sync: this.initialSyncCompleted,
            },
            this,
        );
    },

    initialSyncCompleted() {
        this.syncedOnce = true;
    },

    isReady() {
        return (this.syncedOnce === true);
    },

    parse(resp) {
        util.each(resp, function (lineParam) {
            const line = lineParam;
            if (line.colorValue >= 0) {
                const match = util.findWhere(
                    this.colors,
                    {
                        value: line.colorValue,
                    },
                );
                if (match !== undefined && match.value > -1) {
                    const index = this.colors.indexOf(match);
                    if (index > -1) {
                        line.randomColor = false;
                        this.colors.splice(index, 1);
                        line.color = match.color;
                    }
                } else {
                    // Can't find color, so assign random one.
                    line.colorValue = -1;
                    line.randomColor = true;
                }
            }
            const scenarios = util.map(line.cashFlowScenarios, function (obj) {
                return this.scenarioCollection.get(obj.cashFlowScenarioId);
            }, this);
            line.scenarios = new Collection(scenarios);
        }, this);

        util.each(resp, function (lineParam) {
            const line = lineParam;
            if (line.colorValue < 0) {
                let nextColorPair = this.colors.shift();
                if (nextColorPair === undefined) {
                    nextColorPair = this.extraColor;
                }
                // assign a color
                line.color = nextColorPair.color;
                line.colorValue = nextColorPair.value;
                line.randomColor = true;
            }
        }, this);

        return resp;
    },

    sync(method, collection, options, ...rest) {
        if (method === 'read') {
            const self = this;
            return http.post(services.generateUrl('/cashflowLine/list'), (data) => {
                self.trigger('sync', self, data);

                // TODO make sure scenarios are correctly associated
                if (options && util.isFunction(options.success)) {
                    options.success(data);
                }
            }, () => {
                self.trigger('error', self);
            });
        }
        return Collection.prototype.sync.apply(this, [method, collection, options, ...rest]);
    },

    assignRandomColor(model) {
        model.set('randomColor', true);
        let newColorPair = this.colors.shift();
        if (newColorPair === undefined) {
            newColorPair = this.extraColor;
        }
        model.set({
            color: newColorPair.color,
            colorValue: newColorPair.value,
        });
    },

    assignSelectedColor(model, color, colorValue) {
        const match = util.findWhere(
            this.colors,
            {
                value: colorValue,
            },
        );
        if (match !== undefined && match.value > -1) {
            const index = this.colors.indexOf(match);
            if (index > -1) {
                model.set('randomColor', false);
                this.colors.splice(index, 1);
                model.set({
                    color: match.color,
                    colorValue,
                });
            } else {
                this.assignRandomColor(model);
            }
        }
    },

    reclaimColor(model) {
        const thisColorValue = model.get('colorValue');
        const thisColor = model.get('color');
        if (!util.isUndefined(thisColorValue) && !util.isNull(thisColorValue)) {
            const canReclaim = (util.findWhere(
                this.colors,
                {
                    value: thisColorValue,
                },
            ) === undefined);
            if (canReclaim) {
                const index = util.sortedIndex(
                    this.colors,
                    {
                        value: thisColorValue,
                    },
                    'value',
                );
                const thisColorPair = {
                    value: thisColorValue,
                    color: thisColor,
                };
                this.colors.splice(index, 0, thisColorPair);
            }
        }
    },

    changeColor(model, newValue, newColor) {
        this.reclaimColor(model);
        this.assignSelectedColor(model, newColor, newValue);
    },

    isColorValueAvailable(selValue) {
        if (selValue === undefined) {
            return false;
        }
        const valueInt = parseInt(selValue, 10);
        return util.findWhere(
            this.colors,
            {
                value: valueInt,
            },
        );
    },

    getDefaultName() {
        const currentNames = this.pluck('name');
        let index = 0;
        let name;

        do {
            name = `${locale.get('cashflow.scenario')} ${index}`;
            index += 1;
        } while (currentNames.indexOf(name) > -1);

        return name;
    },

    getActiveLineIds() {
        return util.chain(this.where({
            visible: true,
        })).map(model => model.get('cashFlowLineId')).value();
    },

    getActiveLineClasses() {
        return util.chain(this.where({
            visible: true,
        })).reduce((memo, model) => {
            const obj = {};
            // This returns the custom css class for the graph line/circle colors.
            obj[model.get('cashFlowLineId')] = `graph-color-${model.get('colorValue')}`;
            return util.extend(memo, obj);
        }, {}).value();
    },

    getActiveLineGroups() {
        return util.chain(this.where({
            visible: true,
        })).map((model) => {
            const scenarioIds = model.getActiveScenarioIds();
            return {
                lineId: model.get('cashFlowLineId'),
                scenarioIds,
            };
        }).value();
    },

    getScenarioUses(scenario) {
        const uses = [];
        this.each((line) => {
            if (line.get('scenarios').get(scenario)) {
                uses.unshift({
                    lineName: line.get('name'),
                    lineColor: line.get('color'),
                });
            }
        });

        return uses;
    },

    getLineColors() {
        return this.getDefaultColors();
    },

    getDefaultColors() {
        /*
         * The colors in this array are just place holders.  They will be overridden
         * by css classes when rendered.
         */
        const defaultColors = [{
            value: 1,
            color: '#000001',
        }, {
            value: 2,
            color: '#000002',
        }, {
            value: 3,
            color: '#000003',
        }, {
            value: 4,
            color: '#000004',
        }, {
            value: 5,
            color: '#000005',
        }, {
            value: 6,
            color: '#000006',
        }, {
            value: 7,
            color: '#000007',
        }, {
            value: 8,
            color: '#000008',
        }, {
            value: 9,
            color: '#000009',
        }, {
            value: 10,
            color: '#000010',
        }, {
            value: 11,
            color: '#000011',
        }, {
            value: 12,
            color: '#000012',
        }, {
            value: 13,
            color: '#000013',
        }, {
            value: 14,
            color: '#000014',
        }, {
            value: 15,
            color: '#000015',
        }, {
            value: 16,
            color: '#000016',
        }, {
            value: 17,
            color: '#000017',
        }, {
            value: 18,
            color: '#000018',
        }, {
            value: 19,
            color: '#000019',
        }, {
            value: 20,
            color: '#000020',
        }];
        return defaultColors;
    },
});

export default Line;
