var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"AccountDropdown\">\n    <div class=\"form-group\">\n        <label for=\"ACCOUNTFILTER\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"GIR.ViewAnotherAccount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":70}}}))
    + "</label>\n        <input type=\"text\" class=\"form-control\" name=\"ACCOUNTFILTER\"  maxlength=\"35\" data-bind=\"model\" data-hook=\"account-select\"/>\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"ACCOUNTFILTER\"></span>\n    </div>\n</div>\n";
},"useData":true});