import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@glu/dropdown-react';
import { useValidation } from '@glu/validation-react';
import {
    Button, PRIMARY, SECONDARY, SMALL,
} from '@glu/buttons-react';
import { appBus } from '@glu/core';
import Loader from 'components/Loader/Loader';
import LoadingPage from 'components/LoadingPage/LoadingPage';
import useRequest from 'hooks/useRequest';
import locale from '@glu/locale';
import Constants from './constants';
import useStyles from './ExtendRequestPopup.styles';

const propTypes = {
    submitText: PropTypes.string.isRequired,
    cancelText: PropTypes.string.isRequired,
    model: PropTypes.shape({
        get: PropTypes.func,
        set: PropTypes.func,
        unset: PropTypes.func,
    }).isRequired,
};

const payload = {
    queryCriteria: {
        action: {
            productCode: Constants.productCode,
            functionCode: Constants.functionCode,
            typeCode: Constants.typeCode,
            actionMode: Constants.actionMode,
        },
        subTypeCode: Constants.subTypeCode,
        fieldName: Constants.fieldName,
        entryClass: '',
        allowDuplicates: false,
    },
};
const ExtendStopPopup = ({
    submitText, cancelText, model,
}) => {
    const { post } = useRequest();
    const classes = useStyles();

    const [extendData, setReason] = useState('');
    // const [duration, setValue] = useState('');
    const [options, setOptions] = useState([]);
    const [queryErrorMsg, setQueryErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [Provider, value, { validate }] = useValidation();

    useEffect(() => {
        post(Constants.queryService, payload).then((response) => {
            if (response?.queryResponse?.QueryData?.queryRows) {
                const reasons = response.queryResponse.QueryData.queryRows.map(item => ({
                    id: item.name,
                    name: item.label,
                    duration: item.mapDataList[0].value,
                }));
                setOptions(reasons);
                setIsLoading(false);
            }
        }).catch((e) => {
            let errMsg = e.statusText;
            if (e?.responseJSON?.queryResponse?.respHeader) {
                errMsg = e.responseJSON.queryResponse.respHeader.message.join('');
            }
            setQueryErrorMsg(errMsg);
            setIsLoading(false);
        });
    }, []); // eslint-disable-line

    const onChange = (name, selected) => {
        setReason(selected[0]);
    };

    const submitExtendRequest = () => {
        // get the payload
        model.set('STOPDURATIONDESC', extendData?.id);
        model.set('STOP_DURATION', extendData?.duration);
        model.set('TYPE', 'STOP');
        model.set('FUNCTION', 'INST');
        model.set('STOPTYPE', 'CHECK');
        model.set('CHECK_STOP_TYPE', '1');
        model.set('CURRENT_EXPIRATION_DATE', model.get('STOP_EXPIRATION_DATE') || null);

        model.unset('driverFields');
        model.unset('fieldData');
        appBus.trigger('grid:row:action:action_extendSubmit', model);
        setIsLoading(true);
        appBus.trigger('gridActionPopup:close');
    };

    const handleClose = () => {
        appBus.trigger('gridActionPopup:close');
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        validate().then(({ isValid }) => {
            if (isValid) {
                submitExtendRequest.call(this);
            }
        });
    };

    return (
        <Loader isLoading={isLoading} LoadingComponent={LoadingPage}>
            <Provider value={value}>
                <form>
                    <div className={classes.spaceBelow}>{locale.get('gridAction.label.ExtendStopWarning')}</div>
                    <div>
                        <Dropdown
                            htmlId="extendReason"
                            name="extendReason"
                            labelText={locale.get('gridAction.label.ExtendStopDuration')}
                            options={options}
                            className={classes.extendReasons}
                            filterEnabled={false}
                            onChange={onChange}
                            validators={{
                                name: locale.get('gridAction.label.ExtendStopDuration'),
                                required: true,
                            }}
                        />
                        <span className={classes.extendLabel}>{locale.get('gridAction.label.ExtendStopFromToday')}</span>
                        {queryErrorMsg
                        && <span className={classes.helpBlock}>{queryErrorMsg}</span>}
                    </div>
                    <div>
                        <Button type="button" variant={PRIMARY} size={SMALL} onClick={handleSubmit} text={submitText} className={classes.extendRequestButton} />
                        <Button type="button" variant={SECONDARY} size={SMALL} onClick={handleClose} text={cancelText} className={classes.extendRequestButton} />
                    </div>
                </form>
            </Provider>
        </Loader>
    );
};

ExtendStopPopup.propTypes = propTypes;

/**
 * Provide a utility function for leveraging this in a Backbone context
 * @param {Object} options - Options/props to be sent along to wrapped component
 */
export default ExtendStopPopup;
