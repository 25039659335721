import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import dateRangeUtil from 'app/balanceAndTransaction/api/dateRangeUtil';
import Constants from 'app/balanceAndTransaction/constants';
import moment from 'moment';
import userInfo from 'etc/userInfo';
import Formatter from 'system/utilities/format';
import dialog from '@glu/dialog';
import store from 'system/utilities/cache';

// The TransactionList jasper report expects the date in this format.
const FILTER_DATE_FORMAT = 'MM/DD/YYYY';

const PrintBTRAccountsUtil = {
    print(options) {
        const printModal = new PrintViewModal({
            exportModel: this.buildModel(options),
            exportURL: options.accountsURL,
            title: 'export.printSummaryReport',
            viewId: 'printBTRModal',
            formMethod: 'get',
        });
        dialog.custom(printModal);
    },

    buildModel(options) {
        const btrPrintModel = {};
        btrPrintModel.outputFormat = 'PDF';
        btrPrintModel.pageType = 'LETTER';
        btrPrintModel.numberOfRows = Constants.BTR_REPORT_NUMROWS;
        btrPrintModel.inquiryId = options.inquiryId;
        btrPrintModel.totalsInquiryId = options.totalsInquiryId;
        btrPrintModel.filterValues = this.getFilterValues(options);
        btrPrintModel.searchFields = options.filterView.getTransactionFilters();
        btrPrintModel.accountSearchFields = options.filterModel.getSearchFields();
        if (options.includeSummaryInfo !== undefined) {
            btrPrintModel.includeSummaryInfo = options.includeSummaryInfo;
        }
        return btrPrintModel;
    },

    getFilterValues(options) {
        let startDate = options.filterModel.get('START_DATE');
        let endDate = options.filterModel.get('END_DATE');
        if (endDate.length < 1) {
            startDate = dateRangeUtil.convertCodesToDates(startDate);
            endDate = moment(new Date()).format(userInfo.getDateFormat());
        }
        return {
            startDate: Formatter.formatDateFromUserFormat(startDate, FILTER_DATE_FORMAT),
            endDate: Formatter.formatDateFromUserFormat(endDate, FILTER_DATE_FORMAT),
            transactionsView: options.filterModel.get('accountTransactionsView'),
        };
    },

    /**
     * Get the reportId based on permissions
     * @returns {String}
     */
    getPrintReportId() {
        const permissionsData = store.get('btr:entitlements');
        if (permissionsData) {
            const entitledTypes = permissionsData.get('entitledTypes') || '';
            if (entitledTypes.indexOf(Constants.GIRTRA_P) !== -1) {
                return '22198';
            }
            if (entitledTypes.indexOf(Constants.GIRTRA_I) !== -1) {
                return '22199';
            }
        }
        return '22195';
    },
};

export default PrintBTRAccountsUtil;
