import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import { withStyles } from '@glu/theming';
import {
  Button, PRIMARY, SECONDARY, ButtonToggleGroup, ButtonToggle
} from '@glu/buttons-react';
import { CheckboxRadio } from '@glu/form-components';

import PanelWrapper from '../PanelWrapper/PanelWrapper';
import styles from './SortPanel.styles';

const SortPanel = ({
  classes, columns, closeDrawer, sortFieldId, sortDirection, onSave
}) => {
  const [sortDirectionState, setSortDirectionState] = useState(sortDirection);
  const [sortFieldIdState, setSortFieldIdState] = useState(sortFieldId);

  const handleSave = useCallback(() => {
    onSave({ direction: sortDirectionState, field: sortFieldIdState });
  }, [sortDirectionState, sortFieldIdState, onSave]);

  const handleClose = useCallback(() => {
    setSortDirectionState(sortDirection);
    setSortFieldIdState(sortFieldId);
    closeDrawer();
  }, [sortDirection, sortFieldId, closeDrawer]);

  useEffect(() => {
    setSortDirectionState(sortDirection);
    setSortFieldIdState(sortFieldId);
  }, [sortDirection, sortFieldId]);

  return (
    <PanelWrapper
      title={locale.get('sort')}
      additionalTitleContent={(
        <ButtonToggleGroup
          selectedButtonId={sortDirectionState}
          onButtonSelected={id => setSortDirectionState(id)}
        >
          <ButtonToggle id="asc">
            {locale.get('ascending')}
          </ButtonToggle>
          <ButtonToggle id="desc">
            {locale.get('descending')}
          </ButtonToggle>
        </ButtonToggleGroup>
    )}
      footer={(
        <>
          <Button
            variant={PRIMARY}
            type="button"
            text={locale.get('apply')}
            onClick={handleSave}
          />
          <Button
            variant={SECONDARY}
            type="button"
            text={locale.get('cancel')}
            onClick={handleClose}
          />
        </>
    )}
    >
      {columns.map(({ field, headerName }) => (
        <CheckboxRadio
          key={field}
          htmlId={field}
          type="radio"
          name="sort"
          labelText={headerName}
          checked={field === sortFieldIdState}
          value={field}
          className={classes.radio}
          onChange={() => setSortFieldIdState(field)}
        />
      ))}
    </PanelWrapper>
  );
};

SortPanel.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeDrawer: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    headerName: PropTypes.string
  })).isRequired,
  sortDirection: PropTypes.string,
  sortFieldId: PropTypes.string,
  onSave: PropTypes.func.isRequired
};

SortPanel.defaultProps = {
  sortDirection: 'asc',
  sortFieldId: undefined
};

export default withStyles(styles)(SortPanel);
