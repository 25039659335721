import Model from '@glu/core/src/model';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';

const Scenario = Model.extend({
    idAttribute: 'cashFlowScenarioId',

    syncSuccess() {
        // model, resp, options
        this.trigger('sync', this);
    },

    syncError() {
        // model, resp, options
        this.trigger('error', this);
    },

    parse(resp) {
        return resp;
    },

    sync(method, model, options) {
        /*
         * TODO this is copy/pasted from model/line - common model to extend for both?
         * TODO handle options & callbacks - use bind to attach options.success/options.error
         * to callbacks
         */
        let xhr;

        const success = util.bind(this.syncSuccess, this);
        const error = util.bind(this.syncError, this);

        switch (method) {
        case 'create':
            xhr = http.post(
                services.generateUrl('/cashflowScenario/save'),
                {
                    name: model.get('name'),
                },
                (result) => {
                    model.set('cashFlowScenarioId', result.cashFlowScenarioId);
                },
                error,
            );
            break;
        case 'update':
            xhr = http.post(
                services.generateUrl('/cashflowScenario/update'),
                {
                    cashFlowScenarioId: model.get('cashFlowScenarioId'),
                    name: model.get('name'),
                },
                success,
                error,
            );

            break;
        case 'delete':
            xhr = http.post(
                services.generateUrl('/cashflowScenario/delete'),
                {
                    cashFlowScenarioId: model.get('cashFlowScenarioId'),
                },
                success,
                error,
            );

            break;
        default:
                /*
                 * TODO delegate to backbone.sync here?
                 * console.warn('unsupported method passed to sync');
                 */
        }

        this.trigger('request', model, xhr, options);
        return xhr;
    },
});

export default Scenario;
