import ListView from 'common/dynamicPages/views/workflow/list';
import util from '@glu/core/src/util';
import dialog from '@glu/dialog';
import userInfo from 'etc/userInfo';
import contextApi from 'common/dynamicPages/api/context';
import constants from 'app/administration/constants';
import TokenModal from 'app/administration/views/tokenManagement/updateTokensModal';
import TokenModel from 'app/administration/models/token';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import template from './clientTokenListView.hbs';

const ClientTokenListView = ListView.extend({
    template,

    regions: {
        gridRegion: 'div[data-region="gridRegion"]',
        alertRegion: 'div[data-region="alertRegion"]',
    },

    ui: {
        $assignTokens: '[data-hook="assign-token"]',
        $exportBtn: '[data-hook="export-button"]',
        $printBtn: '[data-hook="print-button"]',
        $refreshBtn: '[data-hook="refresh-button"]',
    },

    events: {
        'click @ui.$assignTokens': 'assignMultipleTokens',
        'click @ui.$exportBtn': 'export',
        'click @ui.$printBtn': 'print',
        'click @ui.$refreshBtn': 'refreshData',
    },

    initialize() {
        this.model = new TokenModel();
        const superOptions = {
            menuCategory: 'ADMINISTRATION',
            serviceName: '/tokenManagement',
            serviceFunc: null,
            businessType: null,
            context: contextApi.menuContext.getContext('TKNMGMT'),
            returnRoute: '/',
            selector: 'none',
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions));
    },

    /**
     * @method setTokenType
     * - Evaluation if Token type is hard or soft token
     * @return {object}
     */
    setTokenType(gridRowModel) {
        let tokenType = gridRowModel.get('TOKENTYPE');
        tokenType = tokenType.includes('Soft') ? 'SOFT' : 'HARD';

        return tokenType;
    },

    /**
     * @method gridRowAssign
     * @param {object} options - an object containing the row model and selected action
     */
    gridRowAssign(options) {
        const gridRowModel = options.model;
        const assignTokenModal = new TokenModal({
            mode: constants.TOKEN_ACTIONS.ASSIGN,
            data: this.model.toJSON(),
            TOKENSERIALNUMBER: gridRowModel.get('TOKENSERIALNUMBER'),
            TOKENTYPE: this.setTokenType(gridRowModel),
        });
        this.listenTo(assignTokenModal, 'token:action:success', this.updateGridFromCore);
        this.listenTo(assignTokenModal, 'token:action:error', this.tokenErrorHandler);
        dialog.open(assignTokenModal);
        return Promise.resolve();
    },

    /**
     * @method gridRowUnassign
     * @param {object} options - an object containing the row model and selected action
     */
    gridRowUnassign(options) {
        return new Promise((resolve, reject) => {
            const self = this;
            const gridRowModel = options.model;

            this.model.set(
                'assignmentList',
                [{
                    assignedTokenType: this.setTokenType(gridRowModel),
                    assignedToken: gridRowModel.get('TOKENSERIALNUMBER'),
                    assignedUserId: gridRowModel.get('USERID'),
                }],
            );

            this.model.syncData(
                constants.TOKEN_ACTIONS.UNASSIGN,
                {
                    success(result) {
                        self.updateGridFromCore(result);
                        resolve({ result });
                    },

                    error(model) {
                        self.tokenErrorHandler(model.error);
                        reject(model.error);
                    },
                },
            );
        });
    },

    /**
     * @method gridRowEnable
     * @param {object} options - an object containing the row model and selected action
     */
    gridRowEnable(options) {
        return new Promise((resolve, reject) => {
            const self = this;
            const gridRowModel = options.model;

            this.setTokenInfo(gridRowModel);
            this.model.syncData(
                constants.TOKEN_ACTIONS.ENABLE,
                {
                    success(result) {
                        self.updateGridFromCore(result);
                        resolve({ result });
                    },

                    error(model) {
                        self.tokenErrorHandler(model.error);
                        reject(model.error);
                    },
                },
            );
        });
    },

    /**
     * @method gridRowDisable
     * @param {object} options - an object containing the row model and selected action
     */
    gridRowDisable(options) {
        return new Promise((resolve, reject) => {
            const self = this;
            const gridRowModel = options.model;

            this.setTokenInfo(gridRowModel);
            this.model.syncData(
                constants.TOKEN_ACTIONS.DISABLE,
                {
                    success(result) {
                        self.updateGridFromCore(result);
                        resolve({ result });
                    },

                    error(model) {
                        self.tokenErrorHandler(model.error);
                        reject(model.error);
                    },
                },
            );
        });
    },

    /**
     * @method gridRowLost
     * @param {object} options - an object containing the row model and selected action
     */
    gridRowLost(options) {
        const gridRowModel = options.model;
        const lostTokenModal = new TokenModal({
            mode: constants.TOKEN_ACTIONS.LOST,
            model: this.model,
            TOKENTYPE: this.setTokenType(gridRowModel),
            TOKENSERIALNUMBER: gridRowModel.get('TOKENSERIALNUMBER'),
            USERID: gridRowModel.get('USERID'),
        });
        this.listenTo(lostTokenModal, 'token:action:success', this.updateGridFromCore);
        this.listenTo(lostTokenModal, 'token:action:error', this.tokenErrorHandler);
        dialog.open(lostTokenModal);
        return Promise.resolve();
    },

    /**
     * @method gridRowMisplace
     * @param {object} options - an object containing the row model and selected action
     */
    gridRowMisplace(options) {
        return new Promise((resolve, reject) => {
            const self = this;
            const gridRowModel = options.model;

            this.setTokenInfo(gridRowModel);
            this.model.syncData(
                constants.TOKEN_ACTIONS.MISPLACED,
                {
                    success(result) {
                        self.updateGridFromCore(result);
                        resolve({ result });
                    },

                    error(model) {
                        self.tokenErrorHandler(model.error);
                        reject(model.error);
                    },
                },
            );
        });
    },

    /**
     * @method gridRowEmail
     * @param {object} options - an object containing the row model and selected action
     */
    gridRowEmail(options) {
        const gridRowModel = options.model;
        const emailTokenModal = new TokenModal({
            mode: constants.TOKEN_ACTIONS.EMAIL,
            model: this.model,
            TOKENTYPE: this.setTokenType(gridRowModel),
            TOKENSERIALNUMBER: gridRowModel.get('TOKENSERIALNUMBER'),
            USERID: gridRowModel.get('USERID'),
        });
        this.listenTo(emailTokenModal, 'token:action:success', this.updateGridFromCore);
        this.listenTo(emailTokenModal, 'token:action:error', this.tokenErrorHandler);
        dialog.open(emailTokenModal);
        return Promise.resolve();
    },

    /**
     * @method gridRowFound
     * @param {object} options - an object containing the row model and selected action
     */
    gridRowFound(options) {
        return new Promise((resolve, reject) => {
            const self = this;
            const gridRowModel = options.model;

            this.setTokenInfo(gridRowModel);
            this.model.syncData(
                constants.TOKEN_ACTIONS.FOUND,
                {
                    success(result) {
                        self.updateGridFromCore(result);
                        resolve({ result });
                    },

                    error(model) {
                        self.tokenErrorHandler(model.error);
                        reject(model.error);
                    },
                },
            );
        });
    },

    /**
     * @method gridRowReplace
     * @param {object} options - an object containing the row model and selected action
     */
    gridRowReplace(options) {
        const gridRowModel = options.model;
        const replaceTokenModal = new TokenModal({
            mode: constants.TOKEN_ACTIONS.REPLACE,
            model: this.model,
            TOKENTYPE: this.setTokenType(gridRowModel),
            TOKENSERIALNUMBER: gridRowModel.get('TOKENSERIALNUMBER'),
            USERID: gridRowModel.get('USERID'),
        });
        this.listenTo(replaceTokenModal, 'token:action:success', this.updateGridFromCore);
        this.listenTo(replaceTokenModal, 'token:action:error', this.tokenErrorHandler);
        dialog.open(replaceTokenModal);
        return Promise.resolve();
    },

    /**
     * @method gridRowUnlock
     * @param {object} options - an object containing the row model and selected action
     */
    gridRowUnlock(options) {
        return new Promise((resolve, reject) => {
            const self = this;
            const gridRowModel = options.model;

            this.setTokenInfo(gridRowModel);
            this.model.syncData(
                constants.TOKEN_ACTIONS.UNLOCK,
                {
                    success(result) {
                        self.updateGridFromCore(result);
                        resolve({ result });
                    },

                    error(model) {
                        self.tokenErrorHandler(model.error);
                        reject(model.error);
                    },
                },
            );
        });
    },

    /**
     * @method assignMultipleTokens
     * Open a dialog modal to allow the user to assign multiple tokens to a user
     * or multiple users at once
     */
    assignMultipleTokens() {
        const assignTokenModal = new TokenModal({
            mode: constants.TOKEN_ACTIONS.ASSIGN,
            data: this.model.toJSON(),
        });

        this.listenTo(assignTokenModal, 'token:action:success', this.updateGridFromCore);
        this.listenTo(assignTokenModal, 'token:action:error', this.tokenErrorHandler);
        dialog.open(assignTokenModal);
    },

    /**
     * @method updateGridFromCore - refresh the grid to get the latest data and
     * display Msg if present
     * @param {object} result - standard confirms object returned fromt the REST service
     */
    updateGridFromCore(result) {
        if (result) {
            this.renderMessage('SUCCESS', result);
        }
        this.fetchTokensFromCore();
    },

    /**
     * @method fetchTokensFromCore
     * Fetch the tokens from RSA and save it in DGB
     */
    fetchTokensFromCore() {
        const self = this;
        this.model.set('COMPANYID', userInfo.get('group'));

        this.model.syncData(
            'tokens',
            {
                success() {
                    self.gridView.refreshGridData();
                },

                error(result) {
                    self.tokenErrorHandler(result);
                },
            },
        );
    },

    /**
     * @method tokenErrorHandler
     * Common error handler for token management actions
     */
    tokenErrorHandler(result) {
        this.renderMessage('DANGER', result);
    },

    setTokenInfo(gridRowModel) {
        this.model.set(
            'tokenInfo',
            {
                tokenSerialNumber: gridRowModel.get('TOKENSERIALNUMBER'),
                tokenType: this.setTokenType(gridRowModel),
                userId: gridRowModel.get('USERID'),
            },
        );
    },

    /**
     * @method templateHelpers
     * Extended to include assign option
     */
    templateHelpers() {
        return {
            ...ListView.prototype.templateHelpers.call(this),
            gridUtilityOptions: {
                customIcons: [{
                    dataHook: 'assign-token',
                    icon: 'icon-add-circle',
                    ariaLabel: 'common.assign',
                    title: 'common.assign',
                }],
            },
        };
    },
});

let list = ClientTokenListView;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list);
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
