import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import PaymentTypeView from './assignedPermissions/paymentType';
import permissionsByPaymentGroupTmpl from './permissionsByPaymentGroup.hbs';

export default Layout.extend({
    template: permissionsByPaymentGroupTmpl,

    ui: {
        $paymentGroupBtn: '.btn-link-group .btn-link',
    },

    initialize(options) {
        this.mode = options.mode;
    },

    onRender() {
        if (this.ui.$paymentGroupBtn.length > 0) {
            this.ui.$paymentGroupBtn.first().addClass('active');
            this.loadPermissionsByPaymentGroup(this.ui.$paymentGroupBtn.data().value);
        }
    },

    showPermissionsByPaymentGroup(e) {
        const $target = this.$(e.currentTarget);
        const selectedGroupId = e.target.dataset.value;

        this.ui.$paymentGroupBtn.removeClass('active');
        $target.addClass('active');

        this.loadPermissionsByPaymentGroup(selectedGroupId);
    },

    loadPermissionsByPaymentGroup(groupId) {
        const group = this.model.get('complexTypes').findWhere({
            typeGroupId: groupId,
        });

        this.permissionsView = new PaymentTypeView({
            aggregateTypes: group.get('aggregateTypes'),
            entitledTypes: group.getEntitledTypes().map(type => type.get('typeCode')),
            userGroup: this.model.userGroupModel.get('id'),
            paymentTypeGroup: groupId,
            mode: this.mode,
            hasPanelApproval: this.model.userGroupModel.get('hasPanelApproval'),
            isRole: Boolean(this.model.roleInfo),
        });

        this.permissionsRegion.show(this.permissionsView);
    },

    templateHelpers() {
        const self = this;

        return {
            paymentGroupIds() {
                const paymentGroupTypes = [];
                self.model.getSelectedComplexTypes().each((type) => {
                    paymentGroupTypes.push({
                        id: type.get('typeGroupId'),
                        label: locale.get(`administration.${type.get('typeGroupLabel')}`),
                    });
                });
                return paymentGroupTypes;
            },
        };
    },
});
