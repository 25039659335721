import locale from '@glu/locale';
import util from '@glu/core/src/util';
import Payment from 'app/smbPayments/views/modals/payment';

export default Payment.extend({
    templateHelpers(...args) {
        const base = Payment.prototype.templateHelpers.apply(this, args);

        return util.extend(
            {},
            base,
            {
                amountText: locale.get('SMBPAY.CollectionAmount'),
                dateText: locale.get('SMBPAY.CollectionDate'),
                accountText: locale.get('SMBPAY.ToAccount'),
                showExchangeRate: false,
                contractIdIsChecked: false,
            },
        );
    },
});
