import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BoxesColumnsNextIcon, LockNextIcon } from '@glu/icons-react';
import { CheckboxRadio } from '@glu/form-components';
import locale from '@glu/locale';
import StyleContext from '../StyleContext/StyleContext';

export default function ColumnItems({
  alwaysShowDragTrigger,
  column,
  disablePinning,
  handleColumnPinning,
  onHideChange
}) {
  const classes = useContext(StyleContext);
  const isMenuHidden = column.menuDisplay === false;

  return (
    !column.showHideDisallowed && (
      <div
        className={classNames({
          [classes.suppressDisplay]: isMenuHidden,
          [classes.itemWrapper]: true,
          [classes.locked]: column.lockPosition,
          [classes.showDragTrigger]: alwaysShowDragTrigger
        })}
        data-qa={`columnItem-${column.headerName.split(' ').join('')}${column.menuDisplay ? '' : '-hidden'}`}
        hidden={isMenuHidden}
      >
        <div className={`${classes.itemToggle} ${column.showHideDisallowed ? classes.itemNoHide : ''}`}>
          { column.lockPosition
            ? (
              <>
                <LockNextIcon className={classes.lockIcon} />
                <span className={classes.itemText}>{column.headerName}</span>
              </>
            )
            : !column.showHideDisallowed && (
            <CheckboxRadio
              disableInputAPI
              checked={!column.hide}
              name={column.headerName}
              labelTagProps={{ title: column.headerName }}
              className={classes.checkbox}
              dark={false}
              labelText={<span className={classes.itemText}>{column.headerName}</span>}
              onChange={() => onHideChange(column.field)}
            />
            )}
        </div>
        {!column.lockPosition && !column.showHideDisallowed && (
          <div className={classes.itemActions}>
            {!disablePinning && !column.lockPinned && (
              <div className={classes.itemPin}>
                <span>{locale.get('dataComponents.pin')}</span>
                {(!column.pinned || (column.pinned && column.pinned === 'right')) && (
                  <button
                    type="button"
                    onClick={() => handleColumnPinning({
                      field: column.field,
                      position: 'left'
                    })}
                  >
                    {locale.get('dataComponents.left')}
                  </button>
                )}
                {(!column.pinned || (column.pinned && column.pinned === 'left')) && (
                  <button
                    type="button"
                    onClick={() => handleColumnPinning({
                      field: column.field,
                      position: 'right'
                    })}
                  >
                    {locale.get('dataComponents.right')}
                  </button>
                )}
                {column.pinned && (
                  <button
                    type="button"
                    onClick={() => handleColumnPinning({
                      field: column.field,
                      position: null
                    })}
                  >
                    {locale.get('dataComponents.unpin')}
                  </button>
                )}
              </div>
            )}
            {
              <BoxesColumnsNextIcon className={classes.dragTrigger} />
            }
          </div>
        )}
      </div>
    )
  );
}

ColumnItems.propTypes = {
  alwaysShowDragTrigger: PropTypes.bool,
  column: PropTypes.shape({
    field: PropTypes.string.isRequired,
    headerName: PropTypes.string.isRequired,
    hide: PropTypes.bool,
    lockPinned: PropTypes.bool,
    lockPosition: PropTypes.bool,
    /** Remove column from display in drag and drop */
    menuDisplay: PropTypes.bool,
    pinned: PropTypes.string,
    /** Prevent show/hide toggle for this column item */
    showHideDisallowed: PropTypes.bool
  }).isRequired,
  disablePinning: PropTypes.bool,
  handleColumnPinning: PropTypes.func,
  onHideChange: PropTypes.func.isRequired
};

ColumnItems.defaultProps = {
  alwaysShowDragTrigger: false,
  disablePinning: false,
  handleColumnPinning: () => {}
};
