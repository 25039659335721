import Glu from '@glu/core/src/glu';
import $ from 'jquery';
import cookie from 'system/cookie';
import LoginModel from 'system/login/models/login';
import Layout from '@glu/core/src/layout';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import alert from '@glu/alerts';
import store from '@glu/store';
import services from 'services';
import FeatureList from 'system/webseries/models/featureList';
import userInfo from 'etc/userInfo';
import configuration from 'system/configuration';
import ForgottenPasswordApi from 'system/webseries/api/forgottenPassword';
import branding from 'etc/branding';
import mfaManager from 'system/mfa/manager';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import mobileUtil from 'mobile/util/mobileUtil';
import randomstring from 'randomstring';
import { encode as base64encode } from 'base64-arraybuffer';
import configurationParameters from 'system/webseries/models/configurationParameters';


import {
    getTypeLocaleString,
    getErrorLocaleString,
    hasBiometricCapability,
} from 'mobile/util/biometricUtil';
import {
    getBiometricSecureData,
    getStatusInformation,
    setBiometricSecureData,
    deleteBiometricSecureData,
} from '@mob/mobile';
import StatefulCheckbox from 'components/StatefulCheckbox/StatefulCheckbox';
import { asView } from 'common/util/reactUtil';
import dialog from '@glu/dialog';
import LaunchBiometricButton from 'components/LaunchBiometricButton/LaunchBiometricButton';
import tmpl from './base_pcm.hbs';
import Challenge from './challenge';
import ChangePassword from './passwordReset';
import staticRedirect from './staticRedirect.hbs';
import { getLoginResources } from '../../../locale/loader';

// const state = randomstring.generate();
// const codeVerifier = randomstring.generate(128);
let ssoErrorMessage = '';
export function saveStateAndVerifier(state, codeVerifier) {
    /*
  Don't overwrite our saved state if location has the state parameter.
  This means we got authorization from the AS, and we need to compare them later.
 */
    if (window.location.search.includes('state')) return;
    const storage = window.sessionStorage;
    storage.clear();
    storage.setItem('state', state);
    storage.setItem('code_verifier', codeVerifier);
}

export async function generateCodeChallenge(codeVerifier) {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest('SHA-256', data);
    const base64Digest = base64encode(digest);
    // you can extract this replacing code to a function
    return base64Digest
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
}

// saveStateAndVerifier();

export default Layout.extend({
    loadingTemplate,

    initialize(options) {
        this.brandMode = options && options.brandMode;
        this.model = new LoginModel();
        this.validators = this.model.validators;
        this.model.validators = {};
        this.model.imgLink = 'true';
        this.checkUserResetPassword(options.userResetPassword);
        if (mobileUtil.isNativeApp()) {
            this.setupBiometric();
        } else {
            this.setHasLoadedRequiredData(true);
        }
        this.appBus.on('mfa:challenge:showError', this.showMfaError.bind(this));
        this.checkAuthCodeUrl();
    },

    checkAuthCodeUrl() {
        const path = window.location.search?.split('&code=');
        if (path.length > 1) {
            $('form').hide();
            $('.sso-login-message').show();
            const code = path[1].split('&')[0];
            if (code) {
                const self = this;
                self.hasLoadedRequiredData(false);
                this.retrieveSSOToken(code).then(tokens => self.login({
                    preventDefault: () => {}, tokens,
                }), (errorMessage) => {
                    console.log(errorMessage);
                    self.alertRegion.show(alert.danger(ssoErrorMessage));
                });
            }
        }
    },

    showMfaError(error) {
        this.alertRegion.show(error);
    },

    template() {
        const path = window.location.pathname.split('/');
        if (path.indexOf('SSOLandingPage') > -1) {
            return staticRedirect({
                key: `logon.${path[path.indexOf('SSOLandingPage') + 1]}`,
            });
        }
        return tmpl;
    },

    className: 'login floaty-box',
    binding: true,

    ui: {
        realm: 'input[name="realm"]',
        username: 'input[name="username"]',
        password: 'input[name="password"]',
        submit: '#login-action-submit',
        biometricLoginIconWrapper: '[data-hook="getBiometricLoginIconWrapper"]',
    },

    events: {
        submit: 'login',
        'focus @ui.realm': 'clearRealm',
        'focus @ui.username': 'clearUsername',
        'focus @ui.password': 'clearPassword',
        'focus @ui.submit': 'validateAll',
    },

    appEvents: {
        'forgottenPassword:reset:success': 'passwordResetSuccess',
    },

    /**
     * THIS IS A LIE
     * templateHelpers are not called for the loginView.
     * If you need to access a value from base.hbs,
     * it must be added to globalHelpers
     */
    templateHelpers: {
        sessionExpired() {
            const result = userInfo.isLoggedIn();
            userInfo.logout();
            return result;
        },
    },

    /**
     * Determine if user is resetting password based on passed parameters
     */
    checkUserResetPassword(userResetPassword) {
        if (userResetPassword) {
            this.userResetPassword = true;
            this.model.set({
                realm: userResetPassword.companyId,
                username: userResetPassword.userId,
            });
        }
    },

    clearRealm() {
        this.model.validators.realm = {};
        this.validateModel();
    },

    clearUsername() {
        this.model.validators.username = {};
        this.validateRealm();
        this.validateModel();
    },

    clearPassword() {
        this.model.validators.password = {};
        this.validateUsername();
        this.validateModel();
    },

    validateRealm() {
        this.model.validators.realm = this.validators.realm;
    },

    validateUsername() {
        this.model.validators.username = this.validators.username;
        this.validateRealm();
    },

    validatePassword() {
        this.model.validators.password = this.validators.password;
        this.validateUsername();
    },

    validateAll() {
        this.validatePassword();
        this.validateModel();
    },

    validateModel() {
        if (this.model.validate()) {
            this.model.trigger(this.invalid);
        }
    },

    onRender() {
        const storage = window.sessionStorage;
        const codeVerifier2 = storage.getItem('code_verifier');
        if (codeVerifier2) {
            $('form').hide();
            $('.sso-login-message').show();
        }
        if (!this.hasLoadedRequiredData()) {
            return;
        }
        if (FeatureList.get('isForgottenPasswordFeatureON')) {
            $(this.ui.submit).after(`<button type="button" id="login-action-forgot" data-action="forgot" class="btn btn-link">${locale.get('common.forgotPassword')}</button>`);
        }

        /**
         * If the userResetPassword object exist then trigger Forgot Password functionality
         */
        if (this.userResetPassword) {
            this.forgot();
        }
        mfaManager.listen();

        if (mobileUtil.isNativeApp()) {
            this.showBiometricToggle();
            this.showBiometricLoginIcon();
        }
    },

    /**
     * Get information about the device and turn on
     * Biometric if appropriate
     * @returns {Promise}
     */
    setupBiometric() {
        return getStatusInformation().then(({ data }) => {
            this.biometricType = data.biometricCapability;
            this.hasBiometricData = data.sdkContainsBiometricSecureData;
            this.biometricEnabled = this.hasBiometricData;
            // Check the query string param for fromLogout
            if (this.hasBiometricData && !this.fromLogin()) {
                this.loginWithBiometric();
            }
            this.setHasLoadedRequiredData(true);
            this.render();
        }).catch(() => {
            this.hasBiometricData = false;
            this.setHasLoadedRequiredData(true);
            this.render();
        });
    },

    /**
     * Create a new view from the LaunchBiometricButton component
     * and render in the region. When there is Biometric data, add a
     * class to show the view
     */
    showBiometricLoginIcon() {
        const LaunchBiometricButtonView = asView(LaunchBiometricButton);
        this.biometricLoginIconRegion.show(new LaunchBiometricButtonView({
            biometricType: this.biometricType,
            onClick: this.loginWithBiometric.bind(this),
        }));
        if (this.hasBiometricData) {
            this.ui.biometricLoginIconWrapper.addClass('show');
        }
    },

    /**
     * Remove show class to hide the button
     */
    hideBiometricLoginIcon() {
        this.ui.biometricLoginIconWrapper.removeClass('show');
    },

    /**
     * Determine if the login page has been reached because of a logout.
     * Only relevant for biometric on the native app
     * @returns {boolean}
     */
    fromLogin() {
        return window.history.state && window.history.state.fromLogout;
    },

    /**
     * Show a checkbox in the biometricToggleRegion
     */
    showBiometricToggle() {
        if (this.biometricToggleRegion && hasBiometricCapability(this.biometricType)) {
            const SwitchView = asView(StatefulCheckbox);
            this.biometricToggleRegion.show(new SwitchView({
                onChange: this.onBiometricToggle.bind(this),
                name: 'biometricEnabled',
                checked: this.biometricEnabled,
                labelText: getTypeLocaleString(this.biometricType),
            }));
        }
    },

    /**
     * When toggle is true, show the confirmation modal otherwise, delete the biometric data
     * @param {string} fieldName
     * @param {boolean} value
     */
    onBiometricToggle(fieldName, value) {
        if (value) {
            dialog.confirm(
                locale.get('logon.biometric.enable.confirmation', getTypeLocaleString(this.biometricType)),
                (okay) => {
                    if (okay) {
                        this.biometricEnabled = value;
                    } else {
                        this.showBiometricToggle();
                    }
                },
            );
        } else {
            this.hideBiometricLoginIcon();
            this.deleteBiometricData();
        }
    },

    /**
     * Delete biometric data from the device, set biometricEndabled to false
     * @returns {Promise}
     */
    deleteBiometricData() {
        this.hasBiometricData = false;
        this.biometricEnabled = false;
        deleteBiometricSecureData()
            .catch(({ error }) => {
                dialog.error(getErrorLocaleString(error.code));
            });
    },

    forgot(e) {
        this.alertRegion.close();
        if (e) {
            e.preventDefault();
        }
        const self = this;
        if (!this.model.get('realm') || !this.model.get('username')) {
            self.alertRegion.show(alert.warning(locale.get('logon.forgotten.system.warning')));
        } else {
            const userId = this.model.get('username').toUpperCase();
            const userGroup = this.model.get('realm').toUpperCase();
            ForgottenPasswordApi.getChallengeQuestions({
                userId,
                userGroup,
            }).then((res) => {
                // Skip over locale update if not needed
                if (!res.enrollmentStatus
                    || !res.locale
                    || res.locale === userInfo.getLocale()) {
                    return res;
                }
                return getLoginResources(res.locale).then(() => res);
            }).then((res) => {
                if (res.enrollmentStatus) {
                    const challenge = new Challenge({
                        questions: res.data.items,
                        title: locale.get('common.security.title'),

                        user: {
                            realm: self.model.get('realm'),
                            name: self.model.get('username'),
                        },
                    });

                    const changePassword = new ChangePassword({
                        realm: self.model.get('realm'),
                        name: self.model.get('username'),
                        isForgottenPassword: true,
                        fromLogin: true,
                    });

                    dialog.custom(challenge);
                    challenge.on('save', (val) => {
                        dialog.close();
                        changePassword.model.set('token', val.resetPwdToken);
                        dialog.custom(changePassword);
                    });
                    changePassword.on('cancel', () => {
                        dialog.close();
                    });
                } else {
                    self.alertRegion.show(alert.warning(locale.get('logon.forgotten.system.error')));
                }
            }).catch(() => {
                self.alertRegion.show(alert.warning(locale.get('logon.forgotten.system.error')));
            });
        }
    },

    /**
     * Get the secure biometric data, update the model, and then call the login function
     * @returns {Promise}
     */
    loginWithBiometric() {
        // Native is handling any errors for this function
        return getBiometricSecureData().then(({ data }) => {
            this.model.set({
                realm: data.userGroup,
                username: data.userId,
                password: data.password,
            }, { silent: true });
            this.login({
                preventDefault: () => {},
            });
        });
    },

    async getSSOServiceAuthURL(e) {
        e.preventDefault();
        const state = randomstring.generate();
        const codeVerifier = randomstring.generate(128);
        const serverConfigParams = configurationParameters;
        const authURL = serverConfigParams.get('BTIQSSOLoginAuthorizationEndpoint');
        const redirectURI = serverConfigParams.get('BTIQSSOLoginRedirectURI');
        const storage = window.sessionStorage;
        const clientId = serverConfigParams.get('BTIQSSOLoginClientId');
        storage.clear();
        storage.setItem('state', state);
        storage.setItem('code_verifier', codeVerifier);
        const linkValue = new URL(authURL);
        const challengeOauth = await generateCodeChallenge(codeVerifier);

        const queryParams = {
            client_id: clientId,
            response_type: 'code',
            scope: 'openid',
            state,
            code_challenge: challengeOauth,
            code_challenge_method: 'S256',
            redirect_uri: redirectURI,
        };
        $('form').hide();
        $('.sso-login-message').show();

        /*eslint-disable */
        for (const param in queryParams) {
            // eslint-disable-next-line no-use-before-define
            linkValue.searchParams.append(param, queryParams[param]);
        }
		/* eslint-enable */
        window.location = linkValue;
        this.navigateTo(linkValue);
        // link.setAttribute('href', linkValue);
    },


    retrieveSSOToken(code) {
        return new Promise((resolve, reject) => {
            const storage = window.sessionStorage;
            const codeVerifier2 = storage.getItem('code_verifier');
            const serverConfigParams = configurationParameters;
            const tokenURL = serverConfigParams.get('BTIQSSOLoginAuthTokenEndpoint');
            const clientId = serverConfigParams.get('BTIQSSOLoginClientId');
            const redirectURI = serverConfigParams.get('BTIQSSOLoginRedirectURI');
            const encodedauth = `Basic ${btoa(`${clientId}:`)}`;
            const data = `grant_type=authorization_code&code=${code}&redirect_uri=${redirectURI}&code_verifier=${codeVerifier2}`;
            const xhr = new XMLHttpRequest();
            xhr.withCredentials = true;
            xhr.onload = function () {
                if (xhr.status >= 200 && xhr.status < 300) {
                    $('form').hide();
                    $('.sso-login-message').show();
                    const tokens = {
                        accessToken: '',
                        idToken: '',
                    };
                    // resolve(xhr.response);
                    JSON.parse(this.responseText, (key, value) => {
                        if (key === 'access_token') {
                            tokens.accessToken = value;
                        } else if (key === 'id_token') {
                            tokens.idToken = value;
                        }
                    });
                    resolve(tokens);
                } else {
                    reject({
                        status: xhr.status,
                        statusText: xhr.statusText,
                    });
                }
            };
            xhr.onerror = function () {
                reject({
                    status: xhr.status,
                    statusText: xhr.statusText,
                });
            };

            xhr.open('POST', tokenURL);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.setRequestHeader('Authorization', encodedauth);
            xhr.setRequestHeader('Accept', 'application/x-www-form-urlencoded, application/json');

            // $('form > fieldset').hide();
            // $('.sso-login-message').show();
            xhr.send(data);
        });
    },

    login(e) {
        // const fakeAccessToken = window.sessionStorage.getItem('sso_token') || e.accessToken;
        // const { tokens } = e.tokens;
        this.alertRegion.close();
        e.preventDefault();
        // reset validation
        // Always clear the entered password:
        // No need to hold this in memory longer than we need to

        // When there isn't biometric data validate the form
        if (!this.hasBiometricData) {
            this.validateAll();
        }

        const self = this;
        const userId = this.model.get('username');
        const userGroup = this.model.get('realm');
        const password = this.model.get('password');

        const data = {
            userId,
            userGroup,
            password,
        };

        // if running inside the hybrid mobile app, notify the server on login
        if (userInfo.has('mobileAppPlatform')) {
            util.extend(data, userInfo.pick('mobileAppPlatform', 'mobileAppPlatformVersion'));
        }
        userInfo.set('isSSOLogin', 'false');

        // Add as a request header the parameter name
        // 'bypassLiveMaintKey' that is in the window.url - if it exists
        http.addRequestHeaderFromUrlParamName('bypassLiveMaintKey');

        new Promise((resolve, reject) => {
            if (e.tokens) {
                const headerData = {
                    JWT_TOKEN: e.tokens.accessToken,
                };
                http.setRequestHeaders(headerData);
                this.setHasLoadedRequiredData(true);
                userInfo.set('isSSOLogin', 'true');
                $('form').hide();
                $('.sso-login-message').show();
                const serverConfigParams = configurationParameters;
                const redirectURI = serverConfigParams.get('BTIQSSOLoginRedirectURI');
                userInfo.set('redirectURI', redirectURI);
                userInfo.set('idToken', e.tokens.idToken);
                ssoErrorMessage = '';
            }
            http.post(services.login, data, resolve, (response) => {
                reject(locale.get('logon.failed.contact.admin'));
                self.appBus.trigger('login:failure', response.responseJSON);
            });
            // Check the login call and handle success or error
        }).then((authInfo) => {
            // When biometric is enable, send the secure data to native
            if (this.biometricEnabled) {
                setBiometricSecureData(data)
                    .catch(({ error }) => {
                        dialog.error(getErrorLocaleString(error.code));
                    });
            }
            self.ensureHistory();

            self.appBus.trigger(
                'login:success',
                {
                    userId,
                    userGroup,
                    __token__: authInfo.token,
                },
            );

            if (store.get('challengedAction')) {
                store.set('challengedAction', null);
            }

            // setting the csrf token into a local session cookie
            cookie.set('x-csrf', authInfo.token);

            if (window.machineSecret && authInfo.machineNonce && authInfo.sequenceNonce) {
                window.machineSecret.storeMachineNonce(authInfo.machineNonce);
                window.machineSecret.storeSequenceNonce(authInfo.sequenceNonce);
            }
            // setting in the sessionStorage local to the tab, is used to redirect the
            // user to log in page when a new tab is opened
            store.set('alreadyLoggedInThisTab', 'true');

            // this flag is to indicate that the dynamic links need to be started as the
            // user did a regular login
            // Prior to log in the routes were started and the only routes left to start
            // are the ones for the dynamic links
            // as they are dependent on the returned data from the menu service call
            store.set('loadDynamicLinks', 'true');

            const afterAuthPromiseList = [];
            util.each(self.options.afterAuthPromises, (promise) => {
                afterAuthPromiseList.push(promise());
            });
            Promise.all(afterAuthPromiseList).then(() => {
                // Redirect to change password pass if required
                if (authInfo === 'PASSWORD_EXPIRED' && Glu.history.fragment !== 'password/change') {
                    self.navigateTo('password/change');
                    return;
                }

                // We store the fact that the user has logged in, so that we can show
                // a "Session expired" message on the login page if there session expires
                // (as opposed to them logging out manually)
                userInfo.login();
                branding.apply();
                self.refresh();
            });
        }, (errorMessage) => {
            if (!userGroup) { ssoErrorMessage = errorMessage; }
            self.alertRegion.show(alert.danger(errorMessage));
        });
        this.model.unset('password');
    },

    passwordResetSuccess() {
        dialog.close();
        this.alertRegion.show(alert.success(
            locale.get('common.reset.successful'),
            {
                canDismiss: true,
                animate: true,
            },
        ));
    },

    // If we were not authenticated on first page load, then Backbone.History will
    // not have been started. This method ensures that post-login, this has been started.
    ensureHistory() {
        if (Glu.History.started) {
            return;
        }

        Glu.history.start({
            pushState: true,
            root: configuration.appRoot,
            silent: true,
        });
    },
});
