import Layout from '@glu/core/src/layout';
import template from './informationalMessage.hbs';

export default Layout.extend({
    template,

    attributes: {
        role: 'informationalMessage',
    },

    className: 'informational-message',

    initialize(opts) {
        this.message = opts.message || '';
    },

    regions: {
        informationalMessageDetailsRegion: '.glu-informational-message-details-region',
    },

    templateHelpers() {
        return {
            informationalMessage: this.message,
        };
    },

});
