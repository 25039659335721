import position from '@dgb/positioning';
import { appBus } from '@glu/core';
import util from 'underscore';
import ErrorView from 'system/error/views/errorMessage';
import store from 'system/utilities/cache';
import portalInterface from 'system/notifications/interfaces/portal';

export const isDeepLinked = () => window.parent !== window;

export const isEntitled = (url) => {
    if (store.get('entitledDeepLinks') === null || url === '') {
        return false;
    }
    return store.get('entitledDeepLinks')
        .get('deeplinks')
        .indexOf(url) !== -1;
};

export const getErrorView = key => new ErrorView({
    key: key || 'notAuthorized',
    noContainer: true,
});

/**
 * @method moveToTopCheck
 *
 * @param {object} [model] - an optional model parameter
 *
 * sends the message 'parentScrollTop' on the appBus if page is displayed
 * in an iFrame. If model is passed as a parameter a check is made on whether
 * model.isChild is defined
 *
 */
export const moveToTopCheck = (model) => {
    if (model && model.isChild) {
        return;
    }
    if (isDeepLinked()) {
        appBus.trigger('parentScrollTop');
    }
};

/**
 * Get the last fragment from the options passed
 * @param {Object} options
 * @param {Model} options.model
 * @param {Object} options.controller
 * @returns {String}
 */
export const getLastFragment = ({ model, controller = {} }) => (
    model?.get?.('lastFragment') || controller.lastFragment
);

/** ****** iframe position data logic ******* */
let positionData;

/**
 * Process PFC data to calculate display area
 * @param {Object} data
 * @returns {{contentTop: number, usableHeight: number, data: object}}
 */
export const calculatePosition = data => ({
    data,
    contentTop: position.calculateTopEdge(data),
    usableHeight: position.calculateUsableHeight(data),
});

/**
 * Return the most recent [current] position data
 * without needing to call postMessage.
 * Useful for setting position initially.
 * @returns {undefined|{contentTop: number, usableHeight: number, data: object}}
 */
export const getLastPositionData = () => positionData;

export const handleScrollMessage = (evt) => {
    if (evt?.data?.type !== 'scroll') {
        return;
    }
    // Save off the current position each time
    positionData = calculatePosition(evt.data.message);
    if (!positionData) {
        return;
    }
    appBus.trigger('iframePosition', positionData);
};

/**
 * Ensure the setup listener cannot be run multiple times.
 */
export const setupScrollListener = util.once(() => {
    if (!isDeepLinked()) {
        return;
    }
    window.addEventListener('message', handleScrollMessage);
});

export const requestPosition = () => {
    /**
     * DeepLink in iFrame - send a message to retrieve the inner height
     * of the Portal's window content area.
     */
    if (isDeepLinked()) {
        portalInterface.send({
            type: 'getPositionData',
        });
    }
};

export const postMessagetoTX = (message, isSuccess) => {
    const parentIframeUrl = window.location.ancestorOrigins !== undefined ? window.location.ancestorOrigins[0] : '*';
    const responseToTx = {
        message,
        isSuccess,
    };
    window.parent.postMessage(responseToTx, parentIframeUrl);
};

export default {
    isDeepLinked,
    isEntitled,
    getErrorView,
    moveToTopCheck,
    getLastFragment,
    calculatePosition,
    getLastPositionData,
    handleScrollMessage,
    setupScrollListener,
    requestPosition,
    postMessagetoTX,
};
