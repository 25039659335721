import util from '@glu/core/src/util';
import ListView from 'common/dynamicPages/views/workflow/list';
import store from 'system/utilities/cache';
import workspaceHelper from 'common/workspaces/api/helper';
import Glu from '@glu/core/src/glu';
import constants from 'common/dynamicPages/api/constants';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import template from './serviceRequestListView.hbs';

const ServiceRequestListView = ListView.extend({
    template,
    events: util.extend({}, ListView.prototype.events, {
        'click [data-hook="getAddButton"]': 'addServiceRequest',
        'click [data-hook="print-button"]': 'showPrintOptionsModal',
    }),

    initialize(options) {
        const superOptions = {
            menuCategory: 'SERVREQ',
            serviceName: '/serviceRequest/freeform',
            serviceFunc: null,
            businessType: null,
            enableSavedViews: true,
            context: 'SERVREQ_LIST',
            returnRoute: '/SERVICEREQUEST/serviceRequest',
        };

        store.set('serviceRequestReturnRoute', Glu.Backbone.history.getFragment());

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    /**
     * Set the actionModel in the store and navigate to the detail route
     * @param {Object} options - contains a model
     */
    gridRowSelect(options) {
        store.set('serviceRequest-actionModel', options.model);
        this.navigateTo('SERVICEREQUEST/serviceRequestDetailView');
        return Promise.resolve();
    },

    /**
     * Set the actionModel in the store and navigate to the modify route
     * @param {Object} options - contains a model
     */
    gridRowModify(options) {
        store.set('serviceRequest-actionModel', options.model);
        this.navigateTo('SERVICEREQUEST/serviceRequestDetailModify');
        return Promise.resolve();
    },

    /**
     * Whether to process multi actions as one
     * @returns {boolean}
     */
    runProcessMultiActionsAsOne() {
        return false;
    },

    /**
     * Navigate to the route for adding a new service request
     */
    addServiceRequest() {
        this.navigateTo('SERVICEREQUEST/serviceRequestDetailCreate');
        return Promise.resolve();
    },

    /**
     * Get print options for this list
     * @returns {Object}
     */
    getPrintOptions() {
        return {
            inquiryId: constants.INQUIRY_ID_49070,
            hasSummarySelection: false,
        };
    },
});

let list = ServiceRequestListView;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        insertActions: [
            {
                label: 'serviceRequest.Add.Service.Request',
                entitlement: 'INSERT',
                handlerMethodName: 'addServiceRequest',
            },
        ],
    });
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'SERVREQ_LIST',
    view: list,
    options: {},
});

const exportedList = list;

export default exportedList;
