import serverConfigParams from 'system/webseries/models/configurationParameters';

export default {
    /**
     * Determines, based on a number of criteria, whether or not to show the account balance
     * beneath the dropdown of the selected account. Currently used in loan payments
     * and transfers for advanced marked segment
     *
     * @param {Boolean} relevantConfiguration - The relevant configuration based on what part of the
     * application the logic is being called from
     * @param {Boolean} isTemplate - Whether or not this form is a template
     * @param {Boolean} isViewWorkflow - This form is in a 'view' workflow
     * @param {String} formStatus - The status of the form (accepted, released, etc)
     *
     * @return {Boolean} - Whether or not to show the account balance
     */
    shouldShowAccountBalance(relevantConfiguration, isTemplate, isViewWorkflow, formStatus) {
        // never show account balances if the configuration for the relevant screen is off
        if (!relevantConfiguration) {
            return false;
        }

        // never show account balances for templates
        if (isTemplate) {
            return false;
        }

        /*
         * If the form is a 'view' workflow, only show the account balance of a selected account
         * when the form's status is not included in the array of "hide balance" statuses dictated
         * in the configuration
         */
        if (isViewWorkflow && serverConfigParams.get('HideAccountBalanceStatuses')?.includes(formStatus)) {
            return false;
        }

        return true;
    },
};
