import { Model, util } from '@glu/core';

function Url(url) {
  this.value = url;
}

Url.prototype.params = function params(options) {
  if (!options) {
    return this;
  }

  // based on GLU-1278
  return new Url(this.value.replace(/\(?\/:\w+\)?/g, (match) => {
    const optionsKey = match.charAt(0) === '(' ? match.slice(3, match.length - 1) : match.slice(2);
    const option = options[optionsKey];
    return option !== undefined ? `/${option}` : '';
  }));
};

Url.prototype.query = function query(options) {
  if (!options) {
    return this;
  }

  let url = this.value;
  let separator = '?';

  util.each(options, (value, key) => {
    url += `${separator + key}=${encodeURIComponent(value)}`;
    separator = '&';
  });

  return new Url(url);
};

Url.prototype.toString = function toString() {
  return this.value;
};

Url.prototype.replace = function replace(...args) {
  return this.toString().replace.apply(this, args);
};

export default Model.extend({
  urls: {},

  initialize() {
    this.initializeUrls();
  },

  initializeUrls() {
    util.each(this.urls, (value, key) => {
      this[key] = new Url(value);
    });
  }
});
