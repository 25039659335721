import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import modalUtil from 'common/util/modal';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import template from './lookupGrid.hbs';

const LookupGrid = Layout.extend({
    template,
    loadingTemplate,
    modalClass: 'modal-xlg lookup-grid-modal',

    initialize(options) {
        this.options = options;
        this.gridApi = options.gridApi;
        this.gridOptions = options.gridOptions;
        this.isModal = options.isModal;
        this.typeInfo = options.typeInfo;
        if (this.isModal === true) {
            this.className = 'modal';
        }
        this.isSingle = options.isSingle;
        if (this.isSingle) {
            this.gridOptions.selector = 'singleRowSelector';
        }
        this.dialogButtons = [];
        this.dialogButtons.push({
            text: locale.get('common.cancel'),
            className: 'btn btn-link',
            callback: 'cancel',
        });
        // add event listener when the page is loaded in an iFrame
        if (window.parent !== window) {
            window.addEventListener('message', this.handleMessage.bind(this));
        }
        this.gridView = this.gridApi.createLookupGridView(this.gridOptions);
    },

    ui: {
        $continueBtn: '[data-hook="getContinueButton"]',
    },

    /**
     * @method handleMessage
     * @param {object} evt - event parameter
     *
     * This method is used by deeplinks where the pages are displayed in an iFrame.
     * It handles the positioning of a modal (if present) as the user scrolls
     * vertically the window browser
     */
    handleMessage(evt) {
        // handle only scroll messages for now
        if (evt.data.type !== 'scroll') {
            return;
        }
        // TODO - Prevent this call if there are no open modals
        this.trigger('lookup:mainWinScroll', modalUtil.retrieveTopEdge(evt.data));
    },

    onRender() {
        const self = this;
        if (this.hasLoadedRequiredData()) {
            this.listenToOnce(this.gridView, 'gridapi:loaded', () => {
                if (self.isModal) {
                    self.trigger('dialog:buttons:change', self.buttonList());
                } else {
                    self.ui.$continueBtn.removeClass('hidden');
                }
            });
            this.gridRegion.show(this.gridView);
        } else {
            this.loadRequiredData();
        }
    },

    /**
     * When this is a single lookup, return undefined, otherwise return
     * and empty object
     * @returns
     */
    handleSelectorCellChange() {
        if (!this.isSingle) {
            return {};
        }
        return undefined;
    },

    onMobileGridLoad() {
        if (this.isModal) {
            this.trigger('dialog:buttons:change', this.buttonList());
        } else {
            this.ui.$continueBtn.removeClass('hidden');
        }
    },

    templateHelpers() {
        const self = this;
        return {
            isModal() {
                return self.isModal;
            },
        };
    },

    loadRequiredData() {
        this.setHasLoadedRequiredData(true);
        this.render();
    },

    buttonList() {
        return [{
            text: locale.get('button.continue'),
            className: 'btn btn-primary',
            callback: util.bind(this.continue, this),
        }, {
            text: locale.get('button.cancel'),
            className: 'btn btn-secondary',
            callback: util.bind(this.cancel, this),
        }];
    },

    // we need this until we are sending down unescaped values from the server.
    unescapeAttributes(modelParam) {
        const model = modelParam;
        util.each(model.attributes, (attr, attrName) => {
            model.set(attrName, util.unescape(attr));
        });

        // unescape mapDataList
        const mapdata = model.mapDataList;
        model.mapDataList = util.map(mapdata, (dataParam) => {
            const data = dataParam;
            data.value = util.unescape(data.value);
            return data;
        });

        return model;
    },

    continue() {
        const self = this;
        try {
            const ar = this.gridView.grid.getSelectedRows();
            // Lookup single
            if (self.isSingle) {
                if (ar.length > 0) {
                    const model = this.unescapeAttributes(this.gridView.wrapper.rows.get(ar[0]));
                    this.trigger('lookup:selected', model);
                }
            } else {
                const models = [];
                if (ar.length) {
                    util.each(ar, function (selected) {
                        const selectedRows = self.gridView.wrapper.rows.get(selected);
                        const data = this.unescapeAttributes(selectedRows);
                        models.push(data);
                    });
                    this.trigger('lookup:selected', models);
                }
            }
        } catch (err) {
            this.cancel();
        }
    },

    cancel() {
        this.trigger('lookup:cancel');
    },
});

let list = LookupGrid;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, { callbackAfterLoad: 'onMobileGridLoad' });
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
