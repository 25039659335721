import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import $ from 'jquery';
import unEscape from 'common/util/unescapeData';
import toolTipTmpl from './toolTipCell.hbs';

export default ItemView.extend({
    template: toolTipTmpl,
    disableInitialViewBinding: true,

    initialize(options) {
        // ItemView.prototype.initialize.call(this, options);
        this.column = options.column;
        this.model = options.model;

        // check for tooltip column's content value and escape it if it exists
        const tooltipCol = this.column.get('tooltipCol');

        const tooltipColVal = (tooltipCol) ? this.model.get(tooltipCol.toUpperCase()) : null;
        if (tooltipColVal) {
            /**
             * Remove all the special Characters and double qoutes
             */
            const removeAllSpecialChar = util.escape(tooltipColVal);
            this.tooltipColVal = unEscape.unescapeString(removeAllSpecialChar);
        }
    },

    onRender() {
        const self = this;
        const mobileGrid = $('[data-qa="mobilegrid"]');
        const desktopGrid = '.grid';
        this.$el.attr('data-title', this.tooltipColVal);
        if (this.hasToolTip()) {
            const toolTipBtnEl = this.$el.find('.toolTip-btn-popover');
            toolTipBtnEl.popover({
                placement: mobileGrid.length > 0 ? 'auto top' : 'auto right',
                container: mobileGrid.length > 0 ? mobileGrid : desktopGrid,
                html: true,
                title: '&nbsp;<a href="#" class="closePopover close icon-circle-close"></a>',
                trigger: 'manual',
            }).click((e) => {
                $('body').on(
                    'click',
                    {
                        closeToolTip: self.closeToolTip,
                    },
                    self.closeToolTip,
                );

                $('.toolTip-btn-popover').each(function () {
                    // hide any other open popovers
                    if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                        $(this).popover('hide');
                    }
                });
                if ($('.popover').has(e.target).length === 0) {
                    toolTipBtnEl.popover('show');
                }

                $('.closePopover').click((ev) => {
                    toolTipBtnEl.popover('hide');
                    $('.popover').remove();
                    ev.stopPropagation();
                    ev.preventDefault();
                });
                e.preventDefault();
            });
        }
    },

    closeToolTip(event) {
        // Close the popover if click out side of the trigger button or popover
        if (!$('.toolTipCell-icon').is(event.target) && $('.popover').has(event.target).length === 0) {
            $('[data-toggle="popover"]').popover('hide');
            $('body').off('click', event.data.closeToolTip);
            $('.popover').remove();
        }
    },

    hasToolTip() {
        return !util.isEmpty(this.tooltipColVal);
    },

    isErrorIcon() {
        return (this.column.get('tooltipCol') && this.column.get('tooltipStyle')
            && this.column.get('tooltipStyle') === 'ERROR');
    },

    getIconClass() {
        return (this.isErrorIcon() ? 'icon-exclamation-circle' : 'icon-info');
    },

    /**
     * @method parseToolTipContent
     * @return {string} content
     * Replace certain escaped special characters in a tooltip content string
     * with actual values
     */
    parseToolTipContent(content) {
        return content.replace(/(\n)/g, '<br />').replace(/(&gt;)/g, '>');
    },

    templateHelpers() {
        const self = this;
        return {
            value: self.options.value,
            tooltipColVal: self.tooltipColVal ? self.parseToolTipContent(self.tooltipColVal) : '',

            iconClass: self.getIconClass(),
            hasToolTip() {
                return self.hasToolTip();
            },
        };
    },
});
