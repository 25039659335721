import http from '@glu/core/src/http';
import Collection from '@glu/core/src/collection';
import services from 'services';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import RecipientCollection from 'app/smb/collections/recipients';
import PaymentUtil from 'common/util/paymentUtil';
import patterns from 'system/validatorPatterns';
import PayeeCollection from 'app/smbPayments/collections/vendors';

const checkingAndSavingsTypes = ['checking', 'savings'];
const lineOfCreditTypes = ['lineOfCredit'];
const lineOfCreditAndLoanTypes = ['lineOfCredit', 'loan'];
const CHECKING_AND_SAVINGS = 'checkingAndSavings';
const LINE_OF_CREDIT = 'lineOfCredit';
const LINE_OF_CREDIT_AND_LOAN = 'lineOfCreditAndLoan';
const accountTypeMap = {};

accountTypeMap[CHECKING_AND_SAVINGS] = checkingAndSavingsTypes;
accountTypeMap[LINE_OF_CREDIT] = lineOfCreditTypes;
accountTypeMap[LINE_OF_CREDIT_AND_LOAN] = lineOfCreditAndLoanTypes;

const getBlockedDates = function (dates) {
    const blockedDates = dates.holidays;
    if (dates.businessDays === '0111110') {
        blockedDates.push('weekends');
    }
    return blockedDates;
};

const getCutoffTimes = function (hoursAndMinutes) {
    const cutoffTimes = {};
    const cutoffTime = `${hoursAndMinutes}-05:00`;
    let i;
    for (i = 1; i <= 7; i += 1) {
        cutoffTimes[i] = cutoffTime;
    }
    return cutoffTimes;
};

const getDates = function (dates) {
    return {
        blockedDates: getBlockedDates(dates),
        maxDate: dates.maxForwardDays,
        cutoffTimes: getCutoffTimes(dates.cutoff),
        businessDays: dates.businessDays,
        cutoff: dates.cutoff,
        defaultDay: dates.defaultDay,
        earliestDay: dates.earliestDay,
        holidays: dates.holidays,
        maxBackwardDays: dates.maxBackwardDays,
        maxForwardDays: dates.maxForwardDays,
        tranDate: dates.tranDate,
        cutoff_info: dates.cutoffDateTimeTz,
    };
};

const getEntitledActions = function (serverJson) {
    const actions = {};
    const ent = {
        actions,
        productCode: serverJson.productCode,
        functionCode: serverJson.functionCode,
        typeCode: serverJson.typeCode,
    };
    util.each(serverJson.actionModes, (action) => {
        let localAction = action;
        localAction = localAction.toUpperCase();
        switch (localAction) {
        case 'INSERT':
            actions.INSERT = true;
            break;
        case 'MODIFY':
            actions.MODIFY = true;
            break;
        case 'DELETE':
            actions.DELETE = true;
            break;
        case 'APPROVE':
            actions.APPROVE = true;
            actions.REJECT = true;
            break;
        case 'UNAPPROV':
            actions.UNAPPROVE = true;
            break;
        case 'SELECT':
            actions.SELECT = true;
            break;
        default:
        }
    });
    return ent;
};

export default {
    AMOUNT_PATTERN: '^(([0,]*\\.([1-9][0-9]?|[0-9][1-9]))|(,*[1-9][,\\d]*(\\.\\d{0,3})?))$',
    DATE_PATTERN: patterns.DATE_PATTERN,

    // ASCII 10 to 126 excluding ; < >
    _NOBADCHARS_PATTERN: patterns.NOBADCHARS_PATTERN,

    SWIFT_PATTERN: patterns.SWIFT_PATTERN,
    FEDWIRE_PATTERN: patterns.FEDWIRE_PATTERN,

    paymentTypes: {
        get() {
            return new Promise((resolve, reject) => {
                http.get(services.sbPaymentTypeList, (data) => {
                    resolve(new Collection(data));
                }, () => {
                    reject(locale.get('smb.payment.type.load.error'));
                });
            });
        },
    },

    createTransfer: {
        get(data) {
            return new Promise((resolve, reject) => {
                http.post(services.paymentTransferAdd, data, resolve, (response) => {
                    resolve(response);
                }, () => {
                    reject(locale.get('smb.webseries.load.error'));
                });
            });
        },
    },

    getSMBPaymentListView: {
        get(data) {
            return new Promise((resolve, reject) => {
                http.post(services.paymentSMBListView, data, resolve, (response) => {
                    resolve(response);
                }, () => {
                    reject(locale.get('smb.payment.load.error'));
                });
            });
        },
    },

    getSmbBillListView: {
        get(data) {
            return new Promise((resolve, reject) => {
                http.post(services.smbBillListView, data, resolve, (response) => {
                    resolve(response);
                }, () => {
                    reject(locale.get('smb.bill.load.error'));
                });
            });
        },
    },

    getSmbBillEntitledActions: {
        get(data) {
            return new Promise((resolve, reject) => {
                http.post(services.smbBillEntitledActions, data, (response) => {
                    resolve(getEntitledActions(response));
                }, (e) => {
                    reject(e);
                });
            });
        },
    },

    updateSmbBill: {
        update(data) {
            return new Promise((resolve, reject) => {
                http.post(services.smbBillUpdate, data, resolve, (response) => {
                    resolve(response);
                }, () => {
                    reject(locale.get('smb.bill.update.error'));
                });
            });
        },
    },

    webseriesAccounts: {
        get(data) {
            return new Promise((resolve, reject) => {
                http.post(services.inquiryQueryResults, data, resolve, (response) => {
                    resolve(response);
                }, () => {
                    reject(locale.get('smb.webseries.load.error'));
                });
            });
        },
    },

    CHECKING_AND_SAVINGS,
    LINE_OF_CREDIT,
    LINE_OF_CREDIT_AND_LOAN,

    accounts: {
        get(accountTypes) {
            const accountTypeList = accountTypeMap[accountTypes];

            return new Promise((resolve, reject) => {
                http.get(services.sbTransferAccountList, (data) => {
                    resolve(new Collection(util.filter(
                        data,
                        acct => util.contains(accountTypeList, acct.type),
                    )));
                }, () => {
                    reject(locale.get('smb.account.load.error'));
                });
            });
        },
    },

    payeeList: {
        get(isCollection, fromRTPWorkspace) {
            const recipientCollection = new PayeeCollection(
                null,
                {
                    isCollection,
                    fromRTPWorkspace,
                },
            );

            return new Promise((resolve, reject) => {
                recipientCollection.fetch({
                    success: resolve,
                    error: reject,
                });
            });
        },
    },

    beneficiaryList: {
        get(id) {
            const recipientCollection = new RecipientCollection({
                optionId: id,
            });

            return new Promise((resolve, reject) => {
                recipientCollection.fetch({
                    success: resolve,
                    error: reject,
                });
            });
        },
    },

    recipientList: {
        get() {
            return new Promise((resolve, reject) => {
                http.get(services.sbRecipientList, (data) => {
                    resolve(new Collection(data));
                }, () => {
                    reject(locale.get('smb.recipient.load.error'));
                });
            });
        },
    },

    businessRecipientList: {
        get() {
            return new Promise((resolve, reject) => {
                http.get(services.sbBusinessRecipientList, (data) => {
                    resolve(new Collection(data));
                }, () => {
                    reject(locale.get('smb.business.recipient.load.error'));
                });
            });
        },
    },

    countryList: {
        get() {
            return new Promise((resolve, reject) => {
                http.get(services.sbCountryList, (data) => {
                    resolve(new Collection(data));
                }, () => {
                    reject(locale.get('smb.country.load.error'));
                });
            });
        },
    },

    accountTypeList: {
        get() {
            return new Promise((resolve, reject) => {
                http.get(services.sbAccountTypeList, (data) => {
                    resolve(new Collection(data));
                }, () => {
                    reject(locale.get('smb.account.type.load.error'));
                });
            });
        },
    },

    adjustmentReasonList: {
        get() {
            return new Promise((resolve, reject) => {
                http.get(services.sbAdjustmentReasonList, (data) => {
                    resolve(new Collection(data));
                }, () => {
                    reject(locale.get('smb.adjustments.load.error'));
                });
            });
        },
    },

    referenceNumberTypeList: {
        get() {
            return new Promise((resolve, reject) => {
                http.get(services.sbReferenceNumberTypeList, (data) => {
                    resolve(new Collection(data));
                }, () => {
                    reject(locale.get('smb.reference.number.load.error'));
                });
            });
        },
    },

    dates: {
        get(options) {
            return new Promise((resolve, reject) => {
                /*
                 * for now, 'transfer' paymentType is the only valid one.
                 * When others become valid from the server,
                 * replace 'transfer' below with paymentType
                 */
                http.post(
                    services.sbDates,
                    {
                        paymentType: options.paymentType || 'transfer',
                        subType: options.subType || '',
                        debitBank: options.debitBank || '',
                        creditCurrency: options.creditCurrency || 'USD',
                        debitCurrency: options.debitCurrency || 'USD',
                        debitBankCountry: options.debitBankCountry || 'US',
                        creditBankCountry: options.creditBankCountry || 'US',
                    },
                    (data) => {
                        resolve(getDates(data));
                    },
                    () => {
                        reject(locale.get('smb.payment.type.load.error'));
                    },
                );
            });
        },
    },

    /*
     * updateCutoff
     * main function call is get, which will process call back to server to get
     * updated dates, including cutoff
     * @param model
     * @param datePicker (reference to the ui date picker)
     * @param type (payment type, ex.  INTL)
     * @param dates - this is the reference to the dates defined in this file
     * @param overrides - ability to pass in overrides to replace the dateOptions
     * pulled in the get, from the model
     * @returns getDates block defined above
     */
    updateCutoff: {
        get(model, datePicker, type, dates, overrides) {
            // sets standard request data needed to pull back dates accurately
            let dateOptions = {
                paymentType: type,
                subType: model.get('SUBTYPE'),
                debitBank: model.get('DEBIT_BANK_CODE'),
                creditCurrency: model.get('CREDIT_CURRENCY'),
                debitCurrency: model.get('DEBIT_CURRENCY'),
                debitBankCountry: model.get('DEBIT_COUNTRY'),
                creditBankCountry: model.get('BENE_BANK_COUNTRY'),
            };
            // if overrides are passed in, we use those instead
            if (overrides) {
                dateOptions = overrides;
            }
            // calls back to the server to get dates (dates/list call)
            return dates.get(dateOptions).then((data) => {
                // show cutoff line if configured to do so
                PaymentUtil.showCutoff(data.cutoff_info, datePicker, type);
                return data;
            });
        },
    },

    cutoffTimes: {
        get(paymentType) {
            return new Promise((resolve, reject) => {
                http.get(services.sbCutoffTimes, (data) => {
                    resolve(util.filter(
                        data,
                        cutoffTimes => util.contains(cutoffTimes, paymentType),
                    )[0].times);
                }, () => {
                    reject(locale.get('smb.cutoff.times.load.error'));
                });
            });
        },
    },
};
