import React from 'react';
import locale from '@glu/locale';
import ContentLoading from 'pcm/common/components/ContentLoading';

const WorkflowLog = React.lazy(() => import('./WorkflowLogList'));

export default {

    pcmWorkflowLog() {
        this.showPage(
            locale.get('workflow.log.list'),
            <React.Suspense fallback={<ContentLoading />}>
                <WorkflowLog />
            </React.Suspense>,
            {
                headlineText: locale.get('workflow.log.list'),
            },
        );
    },
};
