import $ from 'jquery';
import Layout from '@glu/core/src/layout';
import userInfo from 'etc/userInfo';
import Formatter from 'system/utilities/format';
import Bill from 'app/smbPayments/views/payments/models/bill';
import nhUtil from 'system/utilities/accessors';
import Collection from '@glu/core/src/collection';
import locale from '@glu/locale';
import Dialog from '@glu/dialog';
import moment from 'moment';
import viewBillTmpl from './viewBill.hbs';

const ViewModifyBill = Layout.extend({
    template: viewBillTmpl,

    ui: {
        $vendorSelect: '[data-hook="select-vendor"]',
        $invoiceNumber: '#invoiceNumber',
        $vendorFormGroup: '.vendor-group',
        $billAmount: '#billAmount',
        $amountFormGroup: '.billing-amount-group',
        $billingDateContainer: '.billing-date-container',
        $description: 'textarea[name="description"]',
        $descriptionFormGroup: '.bill-description-group',
        $billingDate: '.billing-date-container .input-date',
        $billingDateFormGroup: '.billing-date-group',
        $billTotal: '.bill-total',
        $approver: '[data-hook="select-approver"]',
        $terms: '[data-hook="billing-terms"]',
        $billEditOnly: '.bill-edit-only',
        $billViewOnly: '.bill-view-only',
    },

    events: {
        'change @ui.$vendorSelect': 'handleVendorChange',
        'blur @ui.$invoiceNumber': 'handleVendorChange',
        'blur @ui.$description': 'handleDescriptionChange',
        'blur @ui.$billAmount': 'handleAmountChange',
        'change @ui.$billingDate': 'handleBillingDateChange',
        'change @ui.$approver': 'handleApproverChange',
        'change @ui.$terms': 'handleTermsChange',
    },

    getDynamicHeading() {
        let headingTitle = '';
        const invoiceNum = nhUtil.getFieldValue(this.model, 'INVOICENUMBER');
        const status = nhUtil.getFieldValue(this.model, 'STATUS_DESCRIPTION');

        headingTitle = `${nhUtil.getFieldValue(this.model, 'VENDORNAME')} - ${Formatter.formatCurrency(nhUtil.getFieldValue(this.model, 'TOTALAMOUNT'))
        } ( ${status === 'Entered' ? 'Pending Approval' : status} ) `;

        if (invoiceNum !== '') {
            headingTitle = `Bill #${nhUtil.getFieldValue(this.model, 'INVOICENUMBER')} - ${headingTitle}`;
        }

        return headingTitle;
    },

    initialize(options) {
        // whether or not the modal allows editing
        this.editable = options.editable;
        // Indicator for displaying approval options
        this.approvable = options.approvable || false;
        this.title = options.title || '';
        this.model = options.model || new Bill();

        this.vendorsCollection = options.vendorsCollection || new Collection([]);
        this.approversCollection = options.approversCollection || new Collection([]);
        this.defaultDate = 0;
        // this.modalClass = 'modal-lg';

        this.dialogTitle = this.getDynamicHeading();

        this.modalClass = 'modal-lg';

        // setup for editable view
        if (this.editable) {
            this.dialogButtons = [{
                text: locale.get('smbPayments.save.changes'),
                className: 'btn btn-primary',
                callback: 'submit',
            }, {
                text: locale.get('smbPayments.cancel'),
                className: 'btn btn-secondary',
                callback: 'cancel',
            }];
        } else if (this.approvable) {
            this.dialogButtons = [{
                text: locale.get('smbPayments.approve'),
                className: 'btn btn-primary',
                callback: 'approveBill',
            }, {
                text: locale.get('smbPayments.reject'),
                className: 'btn btn-secondary',
                callback: 'rejectBill',
            }, {
                text: locale.get('smbPayments.modify'),
                className: 'btn btn-secondary',
                callback: 'modifyBill',
            }, {
                text: locale.get('smbPayments.cancel'),
                className: 'btn btn-secondary',
                callback: 'cancel',
            }];
        } else {
            this.dialogButtons = [{
                text: locale.get('smbPayments.payment.schedule'),
                className: 'btn btn-primary',
                callback: 'payNow',
            }, {
                text: locale.get('smbPayments.modify'),
                className: 'btn btn-secondary',
                callback: 'modifyBill',
            }, {
                text: locale.get('smbPayments.cancel'),
                className: 'btn btn-secondary',
                callback: 'cancel',
            }];
        }
    },

    onRender() {
        this.renderDatePickerContent();

        if (this.editable) {
            this.editMode();
        } else {
            this.viewMode();
        }
    },

    editMode() {
        this.ui.$billEditOnly.show();
        this.ui.$billViewOnly.hide();
    },

    viewMode() {
        this.ui.$billEditOnly.hide();
        this.ui.$billViewOnly.show();
    },

    templateHelpers() {
        return {
            editable: this.editable,
            vendor: nhUtil.getFieldValue(this.model, 'VENDORNAME'),
            vendorInvoceNumber: nhUtil.getFieldValue(this.model, 'INVOICENUMBER'),
            totalAmount: nhUtil.getFieldValue(this.model, 'TOTALAMOUNT'),
            billStatus: nhUtil.getFieldValue(this.model, 'STATUS_DESCRIPTION'),
            dueDate: nhUtil.getFieldValue(this.model, 'DUEDATE'),
            description: nhUtil.getFieldValue(this.model, 'DESCRIPTION'),
            dueTerm: nhUtil.getFieldValue(this.model, 'DUETERM'),
            vendors: this.vendorsCollection.models,
            approvers: this.approversCollection.models,
            preferredCurrenyFormat: userInfo.getCurrencyFormat(),
            dateFormat: userInfo.getDateFormat(),
            imgBase: `${window.Bottomline.assetRoot}/images/${window.Bottomline.buildId}/nh`,
        };
    },

    submit() {
        Dialog.close();
    },

    approveBill() {
        Dialog.close();
    },

    rejectBill() {
        Dialog.close();
    },

    modifyBill() {
        const modifyBill = new ViewModifyBill({
            model: this.model,
            editable: true,
            approvable: false,
        });
        Dialog.close();

        // open new modal with editable view
        Dialog.open(modifyBill);
    },

    payNow() {
        Dialog.close();
    },

    cancel() {
        Dialog.close();
    },

    handleVendorChange() {

    },

    handleAmountChange(e) {
        const amount = $(e.target).val();
        if (amount) {
            this.ui.$billTotal.text(Formatter.formatCurrency(amount));
        }
    },

    handleDescriptionChange() {
    },

    updateBillingDate() {
    },

    handleBillingDateChange() {
    },

    handleApproverChange() {
    },

    handleTermsChange() {
    },

    renderDatePickerContent() {
        const datePicker = this.$el.find('#dateRange');

        datePicker.nhDatePicker({
            singleDatePicker: true,
            showCalendarIcon: true,
            startDate: moment(new Date()).add(30, 'days').calendar(),
        });
    },
});

export default ViewModifyBill;
