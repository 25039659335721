/* istanbul ignore file */
export default (palette, typography) => ({
  background: palette.background,
  bodyFont: typography.fontFamily,
  textColor: palette.text.dark,
  border: palette.form.border,
  buttonColor: palette.primary.normal,
  disabledColor: palette.text.midGray,
  highlightBackground: palette.highlightBackground,
  iconColor: palette.text.midGray,
  tableHeaderColor: palette.text.midGray,
  titleFont: typography.h3.fontFamily,
  complete: palette.variants.success.normal,
  focusColor: palette.focus.normal
});
