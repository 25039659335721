/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import isEqual from 'lodash/isEqual';
import { combineColumnData } from '../../utils/columns';

export const sizes = Object.freeze({ medium: 35, small: 25 });

export const mapRowHeight = (rHeight) => ({ className: rHeight, height: sizes[rHeight] });

export const withProps = (gluProps) => (WrappedComponent) => (agGridProps) => (
  <WrappedComponent
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...gluProps}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...agGridProps}
  />
);

export const handleGridChange = ({ api, columnApi, type }, {
  columnDefs, data, onChange, selections, serverSidePagination, setSelections
}, gridApi, gridReady) => {
  // Ignore most of the bootstrapping
  if (!gridReady) {
    return;
  }
  if (/sort/.test(type)) {
    const sort = api.getSortModel();
    if (isEqual(sort, data.sort)) {
      return;
    }
    onChange({
      errors: {},
      name: 'sort',
      value: sort
    });
    return;
  }
  if (/pagination/.test(type) && !serverSidePagination) {
    const page = api.paginationGetCurrentPage();
    const pageSize = api.paginationGetPageSize();
    if (isEqual(page, data.page) && isEqual(pageSize, data.pageSize)) {
      return;
    }
    onChange({
      errors: {},
      name: 'page',
      value: page
    });

    onChange({
      errors: {},
      name: 'pageSize',
      value: pageSize
    });
  }
  if (!isEqual(selections, gridApi.getSelectedRows())) {
    setSelections(gridApi.getSelectedRows());
  }

  if (/column/.test(type)) {
    const columns = columnApi.getColumnState();
    const combined = combineColumnData(columnDefs, columns);
    if (isEqual(combined, data.columns)) {
      return;
    }
    onChange({
      errors: {},
      name: 'columns',
      value: combineColumnData(columnDefs, columns)
    });
  }
};
