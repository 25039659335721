import TypeaheadSearch from 'system/gridOverride/views/typeaheadSearch';
import DateRangeSearch from 'system/gridOverride/views/dateRangeSearch';
import DateRangeSearch2 from 'system/gridOverride/views/dateRangeSearch2';
import DynamicTypeSearch from './dynamicTypeSearchBase2';

export default DynamicTypeSearch.extend({
    initialize(...args) {
        DynamicTypeSearch.prototype.initialize.apply(this, args);
    },

    getSearchViewByType(type, model, ...rest) {
        const args = [type, model, ...rest];
        let view;

        if (type === 'typeahead') {
            view = new TypeaheadSearch({
                model,
            });
            view.setField(model.get('field'));
        } else if (type.indexOf('date') > -1) {
            if (this.options.grid.options.enableDateTimeSupport) {
                view = new DateRangeSearch2({
                    model,
                });
            } else {
                view = new DateRangeSearch({
                    model,
                });
            }

            view.setField(model.get('field'));
        }

        if (view) {
            this.listenTo(
                view,
                {
                    filter: this.hideSearchView,
                    'filter:clear': this.clearFilter,
                    'filter:remove': this.handleRemoveFilter,
                },
            );

            return view;
        }

        return DynamicTypeSearch.prototype.getSearchViewByType.apply(this, args);
    },
});
