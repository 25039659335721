import locale from '@glu/locale';
import client from 'common/notificationClient/client';
import basePoller from 'common/notificationClient/poller';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import { postData } from 'common/util/services';

const POLLER_TIMEOUT = 300000; // 5 minutes
const POLLER_INTERVAL = 5000;

const poller = basePoller.extend({

    keys: [
        'voiceCallOTP:validate',
    ],
    initialize(options) {
        basePoller.prototype.initialize.call(this, {
            interval: POLLER_INTERVAL,
            ...options,
        });
        // The poller should only be allowed to poll for a certain duration
        setTimeout(
            this.disablePoller.bind(this),
            serverConfigParams.get('voiceCallOTPPollerTimeout') || POLLER_TIMEOUT,
        );
    },

    /**
     * Disable the poller and trigger a mock response with messaging
     */
    disablePoller() {
        this.disable();
        client.trigger('voiceCallOTP:validate', {
            respHeader: {
                result: false,
                message: [locale.get('mfa.authentify.unableToConnect')],
                errorCode: 124,
            },
        });
    },

    /**
     * Override this function, calling the prototype and stop listening to
     * the client
     * @param {String} key
     */
    handleUnsubscribe(key) {
        if (basePoller.prototype.handleUnsubscribe.call(this, key)) {
            this.stopListening(client, 'service:subscribe');
            this.stopListening(client, 'service:unsubscribe');
        }
    },
    /**
     * Override this to specify whether a subscribe event
     *  should cause this poller to act.
     * @param {String} key
     */
    isRelevantSubscription(key) {
        return this.keys.includes(key);
    },

    /**
     * Fetch data based on the service url
     * @return {Promise}
     */
    requestData() {
        return postData(this.serviceUrl, this.get('postData'));
    },

    /**
     * Trigger the event on the client
     * @param {Object} data
     */
    processResponses(data) {
        client.trigger('voiceCallOTP:validate', data);
    },
});
export default poller;
