import { appBus } from '@glu/core';
import util from '@glu/core/src/util';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import keepAliveUtil from 'system/keepAlive/util';

/*
 * The notification message controller sends messages to portal
 * These messages are configurable and can be associated to corresponding REST calls
 * (the message is sent if a corresponding REST service call was made)
 *
 * The notification message controller also supports the 'portalKeepAlive' type
 * where customers can configure a specific message that DGB can send according to
 * the customer's portal implemented listeners. The 'portalKeepAlive' also takes into
 * account the black list of URLs defined in the /system/keepAlive/constants.js
 */
let throttleWait = 3000;
let listening = false;

const sendMessage = function (url, type, message, matchUrl) {
    let bSend = false;
    if (type === 'postMessage' && url.indexOf(matchUrl) > -1) {
        bSend = true;
    }
    // if the message is a portal 'Keep Alive' check if the URL is blacklisted, if yes then skip
    if (type === 'portalKeepAlive' && !keepAliveUtil.isIgnoreList(url)) {
        bSend = true;
    }

    if (bSend && !util.isEmpty(message)) {
        appBus.trigger('bottomline.portal.message', message);
    }
};

export default {
    start() {
        if (listening) {
            return;
        }
        const configuredThrottledWaitParam = serverConfigParams.get('notificationMessageThrottleWaitParam');
        if (!util.isEmpty(configuredThrottledWaitParam)) {
            throttleWait = configuredThrottledWaitParam;
        }
        appBus.on('http:complete:callback:invocation', util.throttle(
            (url) => {
                let data = serverConfigParams.get('notificationMessageDataParams');
                if (util.isEmpty(data) || util.isEmpty(url)) {
                    return;
                }
                data = JSON.parse(util.unescape(data));
                if (Array.isArray(data)) {
                    for (let i = 0; i < data.length; i += 1) {
                        sendMessage(url, data[i].type, data[i].message, data[i].matchUrl);
                    }
                } else {
                    sendMessage(url, data.type, data.message, data.matchUrl);
                }
            },
            throttleWait,
        ));
        listening = true;
    },

};
