import RefreshBalancesButton from 'app/balanceAndTransaction/views/refreshBalancesButton';
import locale from '@glu/locale';

const RefreshBalancesButtonView = RefreshBalancesButton.extend({
    label() {
        return locale.get('gir.refreshAccounts');
    },

    onClick() {
        if (this.isReady()) {
            this.parentViewAction();
        }
    },

    refresh(state) {
        this.$el.prop('disabled', state === 'disabled');
        this.currentState = state;
    },

    initialize(options) {
        this.parentViewAction = options.action;
        this.currentState = 'ready';
    },

    isReady() {
        return this.currentState === 'ready';
    },
});

export default RefreshBalancesButtonView;
