import Layout from '@glu/core/src/layout';
import alert from '@glu/alerts';
import dialog from '@glu/dialog';
import $ from 'jquery';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import ssoApi from 'app/utilities/sso/ssoApi';
import userInfo from 'etc/userInfo';
import workspaceHelper from 'common/workspaces/api/helper';
import ItemSelectionView from 'app/utilities/sso/itemSelectionView';
import CheckFreeView from 'app/payments/checkFree/view/checkFreeSSOView';
import InquirySearchService from 'app/payments/checkFree/service/inquirySearch';
import dynamicLinkController from 'system/dynamiclinks/appRouterController';
import RegisterForm from 'app/payments/checkFree/view/registerForm';

import template from './smbOutboundSSOView.hbs';

const deferred = $.Deferred;

const SMBOutboundSSOView = Layout.extend({
    template,

    regions: {
        alertRegion: '#alertRegion',
    },

    initialize(options) {
        this.options = options;
        this.checkFreeUserInquiry = new InquirySearchService();
        this.checkFreeUserInquiry.addSearch('USER_ID', userInfo.get('id'));
        this.checkFreeUserInquiry.addSearch('USERGROUP', userInfo.get('group'));
        this.checkFreeUserInquiry.setTypeCode('SSOTPV');
        this.checkFreeUserInquiry.setProductCode('SSOOUT');
        this.checkFreeUserInquiry.setInquiryId(29550);
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        const self = this;
        const promiseMe = ssoApi.getEntitledSSOs();
        promiseMe.then((data) => {
            self.setHasLoadedRequiredData(true);
            self.entitledSSOList = data;
            self.render();
        });
    },

    ssoLogin(args) {
        this.typecode = args.currentTarget.attributes.getNamedItem('typecode');
        const productcode = args.currentTarget.attributes.getNamedItem('productcode');
        const menucode = args.currentTarget.attributes.getNamedItem('menucode');
        let requestType = args.currentTarget.attributes.getNamedItem('requestType');
        if (requestType) {
            requestType = requestType.nodeValue;
        }
        if (productcode && productcode.nodeValue === 'DYNLINK' && menucode) {
            if (requestType === '0') {
                dynamicLinkController.externalLink(menucode.nodeValue);
            } else if (requestType === '1') {
                dynamicLinkController.internalLink(menucode.nodeValue);
            }
        } else {
            this.doLogin(null);
        }
    },

    showCheckFreeModal(typeCode) {
        const def = deferred();
        const view = new CheckFreeView({ vendorCode: typeCode });

        view.on('success', () => {
            def.resolve();
            dialog.close();
        });

        view.on('register', () => {
            dialog.close();
            const registerView = new RegisterForm({ options: { vendorCode: typeCode } });
            this.appBus.trigger('show:page', registerView, '', {});
        });

        view.on('close', () => {
            def.reject();
        });

        view.on('cancel', () => {
            dialog.close();
        });

        dialog.open(
            view,
            {
                title: locale.get('smb.check.free.settings'),
                modalClass: 'modal-md',
            },
        );

        return def.promise();
    },

    getDeferredLogin(typecode, params) {
        return (deferred(def => ssoApi.loginsmb('SSOOUT', 'MAINT', typecode, params).then(def.resolve, def.reject))).promise();
    },

    doLogin(params) {
        const self = this;
        const typecode = this.typecode.nodeValue.toString();
        let promiseMe;

        if (typecode === 'CFRXP' || typecode === 'CFSMB') {
            this.checkFreeUserInquiry.addSearch('VENDCODE', typecode);
            promiseMe = this.checkFreeUserInquiry.send().then((response) => {
                if (response.inquiryResponse.rows.length > 0) {
                    return self.getDeferredLogin(typecode, params);
                }
                return self.showCheckFreeModal(typecode)
                    .then(() => self.getDeferredLogin(typecode, params));
            });
        } else {
            promiseMe = ssoApi.loginsmb('SSOOUT', 'MAINT', typecode, params);
        }

        promiseMe.then(util.bind(this.handleLogin, this), util.bind(this.handleClose, this));
    },

    handleClose() {

    },

    handleLogin(data) {
        this.loginResponse = data;
        if (data.dialog) {
            this.openDialog(data);
        } else if (!data.success) {
            const msg = alert.danger(data.errorMsg);
            this.alertRegion.show(msg);
        } else if (data.inNewWindow) {
            ssoApi.openSSOWindow(data);
        } else {
            this.render();
            this.submitForm(data);
        }
    },

    openDialog(data) {
        this.itemSelectionDialog = new ItemSelectionView({
            isModal: true,
            loginResponse: data,
            menuctx: this.options.menuctx,
        });
        this.listenTo(this.itemSelectionDialog, 'itemSelected', this.itemSelected);
        dialog.open(this.itemSelectionDialog);
    },

    itemSelected(data) {
        const params = data;
        params.push({
            name: 'mode',
            value: this.loginResponse.dialog,
        });

        this.doLogin(params);
    },

    submitForm() {
        $('#ssoForm').submit();
    },

    templateHelpers() {
        const self = this;
        return {
            htmlContent() {
                if (!self.loginResponse) {
                    return null;
                }
                return self.loginResponse.htmlContent;
            },

            postRedirect() {
                if (!self.loginResponse) {
                    return null;
                }
                return self.loginResponse.postRedirect;
            },

            iframeSrc() {
                if (!self.loginResponse) {
                    return null;
                }
                if (self.loginResponse.postRedirect) {
                    return null;
                }
                return self.loginResponse.redirectURL;
            },

            myForm() {
                let f = `<form id="ssoForm" action="${self.loginResponse.redirectURL}" method="post" target="ssoFrame">`;
                if (self.loginResponse.parameters) {
                    self.loginResponse.parameters.forEach((param) => {
                        f += `<input type="hidden" name="${param.first}" value="${param.second}">`;
                    });
                }
                f += '</form>';
                return f;
            },

            sso() {
                return self.entitledSSOList;
            },
        };
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'OUTBOUND_SSO',
    view: SMBOutboundSSOView,
    options: {},
});

export default SMBOutboundSSOView;
