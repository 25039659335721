var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-group col-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"_ADMIN.USERS._USER.*.ENTEREDBY.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":19},"end":{"line":4,"column":68}}}))
    + ":</label>\n            <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"ENTEREDBY") || (depth0 != null ? lookupProperty(depth0,"ENTEREDBY") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"ENTEREDBY","hash":{},"data":data,"loc":{"start":{"line":5,"column":43},"end":{"line":5,"column":56}}}) : helper)))
    + "</p>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-group col-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"_ADMIN.USERS._USER.*.ENTEREDBYTIMESTAMP.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":19},"end":{"line":10,"column":77}}}))
    + ":</label>\n            <p class=\"form-control-static\">"
    + alias2((lookupProperty(helpers,"formatTimeStamp")||(depth0 && lookupProperty(depth0,"formatTimeStamp"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"ENTEREDBYGROUPTIMESTAMP") : depth0),{"name":"formatTimeStamp","hash":{},"data":data,"loc":{"start":{"line":11,"column":43},"end":{"line":11,"column":86}}}))
    + "</p>\n        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-group col-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"_ADMIN.USERS._USER.*.ENTEREDBYGROUP.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":19},"end":{"line":16,"column":73}}}))
    + ":</label>\n            <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"ENTEREDBYGROUP") || (depth0 != null ? lookupProperty(depth0,"ENTEREDBYGROUP") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"ENTEREDBYGROUP","hash":{},"data":data,"loc":{"start":{"line":17,"column":43},"end":{"line":17,"column":61}}}) : helper)))
    + "</p>\n        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-group col-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"_ADMIN.USERS._USER.*.MODIFIEDBY.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":19},"end":{"line":24,"column":69}}}))
    + ":</label>\n            <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"MODIFIEDBY") || (depth0 != null ? lookupProperty(depth0,"MODIFIEDBY") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"MODIFIEDBY","hash":{},"data":data,"loc":{"start":{"line":25,"column":43},"end":{"line":25,"column":57}}}) : helper)))
    + "</p>\n        </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-group col-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"_ADMIN.USERS._USER.*.MODIFIEDBYTIMESTAMP.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":30,"column":19},"end":{"line":30,"column":78}}}))
    + ":</label>\n            <p class=\"form-control-static\">"
    + alias2((lookupProperty(helpers,"formatTimeStamp")||(depth0 && lookupProperty(depth0,"formatTimeStamp"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"MODIFIEDBYGROUPTIMESTAMP") : depth0),{"name":"formatTimeStamp","hash":{},"data":data,"loc":{"start":{"line":31,"column":43},"end":{"line":31,"column":87}}}))
    + "</p>\n        </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-group col-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"_ADMIN.USERS._USER.*.MODIFIEDBYGROUP.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":19},"end":{"line":36,"column":74}}}))
    + ":</label>\n            <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"MODIFIEDBYGROUP") || (depth0 != null ? lookupProperty(depth0,"MODIFIEDBYGROUP") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"MODIFIEDBYGROUP","hash":{},"data":data,"loc":{"start":{"line":37,"column":43},"end":{"line":37,"column":62}}}) : helper)))
    + "</p>\n        </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-group col-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"_ADMIN.USERS._USER.*.APPROVEDBY.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":44,"column":19},"end":{"line":44,"column":69}}}))
    + ":</label>\n            <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"APPROVEDBY") || (depth0 != null ? lookupProperty(depth0,"APPROVEDBY") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"APPROVEDBY","hash":{},"data":data,"loc":{"start":{"line":45,"column":43},"end":{"line":45,"column":57}}}) : helper)))
    + "</p>\n        </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-group col-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"_ADMIN.USERS._USER.*.APPROVEDBYTIMESTAMP.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":50,"column":19},"end":{"line":50,"column":78}}}))
    + ":</label>\n            <p class=\"form-control-static\">"
    + alias2((lookupProperty(helpers,"formatTimeStamp")||(depth0 && lookupProperty(depth0,"formatTimeStamp"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"APPROVEDBYGROUPTIMESTAMP") : depth0),{"name":"formatTimeStamp","hash":{},"data":data,"loc":{"start":{"line":51,"column":43},"end":{"line":51,"column":87}}}))
    + "</p>\n        </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-group col-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"_ADMIN.USERS._USER.*.APPROVEDBYGROUP.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":56,"column":19},"end":{"line":56,"column":74}}}))
    + ":</label>\n            <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"APPROVEDBYGROUP") || (depth0 != null ? lookupProperty(depth0,"APPROVEDBYGROUP") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"APPROVEDBYGROUP","hash":{},"data":data,"loc":{"start":{"line":57,"column":43},"end":{"line":57,"column":62}}}) : helper)))
    + "</p>\n        </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-group col-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"_ADMIN.USERS._USER.*.DELETEDBY.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":64,"column":19},"end":{"line":64,"column":68}}}))
    + ":</label>\n            <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"DELETEDBY") || (depth0 != null ? lookupProperty(depth0,"DELETEDBY") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"DELETEDBY","hash":{},"data":data,"loc":{"start":{"line":65,"column":43},"end":{"line":65,"column":56}}}) : helper)))
    + "</p>\n        </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-group col-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"_ADMIN.USERS._USER.*.DELETEDBYTIMESTAMP.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":70,"column":19},"end":{"line":70,"column":77}}}))
    + ":</label>\n            <p class=\"form-control-static\">"
    + alias2((lookupProperty(helpers,"formatTimeStamp")||(depth0 && lookupProperty(depth0,"formatTimeStamp"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"DELETEDBYTIMESTAMP") : depth0),{"name":"formatTimeStamp","hash":{},"data":data,"loc":{"start":{"line":71,"column":43},"end":{"line":71,"column":81}}}))
    + "</p>\n        </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-group col-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"_ADMIN.USERS._USER.*.DELETEDBYGROUP.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":76,"column":19},"end":{"line":76,"column":73}}}))
    + ":</label>\n            <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"DELETEDBYGROUP") || (depth0 != null ? lookupProperty(depth0,"DELETEDBYGROUP") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"DELETEDBYGROUP","hash":{},"data":data,"loc":{"start":{"line":77,"column":43},"end":{"line":77,"column":61}}}) : helper)))
    + "</p>\n        </div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-group col-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"_ADMIN.USERS._USER.*.RESTOREDBY.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":84,"column":19},"end":{"line":84,"column":69}}}))
    + ":</label>\n            <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"RESTOREDBY") || (depth0 != null ? lookupProperty(depth0,"RESTOREDBY") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"RESTOREDBY","hash":{},"data":data,"loc":{"start":{"line":85,"column":43},"end":{"line":85,"column":57}}}) : helper)))
    + "</p>\n        </div>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-group col-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"_ADMIN.USERS._USER.*.RESTOREDBYTIMESTAMP.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":90,"column":19},"end":{"line":90,"column":78}}}))
    + ":</label>\n            <p class=\"form-control-static\">"
    + alias2((lookupProperty(helpers,"formatTimeStamp")||(depth0 && lookupProperty(depth0,"formatTimeStamp"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"RESTOREDBYTIMESTAMP") : depth0),{"name":"formatTimeStamp","hash":{},"data":data,"loc":{"start":{"line":91,"column":43},"end":{"line":91,"column":82}}}))
    + "</p>\n        </div>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-group col-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"_ADMIN.USERS._USER.*.RESTOREDBYGROUP.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":96,"column":19},"end":{"line":96,"column":74}}}))
    + ":</label>\n            <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"RESTOREDBYUSERGROUP") || (depth0 != null ? lookupProperty(depth0,"RESTOREDBYUSERGROUP") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"RESTOREDBYUSERGROUP","hash":{},"data":data,"loc":{"start":{"line":97,"column":43},"end":{"line":97,"column":66}}}) : helper)))
    + "</p>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ENTEREDBY") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ENTEREDBYGROUPTIMESTAMP") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ENTEREDBYGROUP") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":4},"end":{"line":19,"column":11}}})) != null ? stack1 : "")
    + "</div>\n<div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"MODIFIEDBY") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":27,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"MODIFIEDBYGROUPTIMESTAMP") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":4},"end":{"line":33,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"MODIFIEDBYGROUP") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":4},"end":{"line":39,"column":11}}})) != null ? stack1 : "")
    + "</div>\n<div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"APPROVEDBY") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":4},"end":{"line":47,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"APPROVEDBYGROUPTIMESTAMP") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":4},"end":{"line":53,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"APPROVEDBYGROUP") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":4},"end":{"line":59,"column":11}}})) != null ? stack1 : "")
    + "</div>\n<div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"DELETEDBY") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":4},"end":{"line":67,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"DELETEDBYTIMESTAMP") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":4},"end":{"line":73,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"DELETEDBYGROUP") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":4},"end":{"line":79,"column":11}}})) != null ? stack1 : "")
    + "</div>\n<div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"RESTOREDBY") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":4},"end":{"line":87,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"RESTOREDBYTIMESTAMP") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":4},"end":{"line":93,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"RESTOREDBYUSERGROUP") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":4},"end":{"line":99,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});