import Collection from '@glu/core/src/collection';
import Permission from '../models/smbPermission.userMaintenance';

export default Collection.extend({
    model: Permission,
    comparator: 'description',
    serializeCollection() {
        return this.map(model => model.serializeModel());
    },
});
