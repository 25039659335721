var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"back\"><span class=\"icon-arrow-left\"></span>\n        &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.backToPrevious",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":45},"end":{"line":4,"column":87}}}))
    + "</span></a>\n    </div>\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.FileExtractDetails",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":31},"end":{"line":6,"column":65}}}))
    + "</h1>\n</div>\n<div class=\"section section-container\">\n    <div class=\"section-header\">\n        <h2>"
    + alias2(((helper = (helper = lookupProperty(helpers,"getSectionHeader") || (depth0 != null ? lookupProperty(depth0,"getSectionHeader") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"getSectionHeader","hash":{},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":32}}}) : helper)))
    + "</h2>\n    </div>\n    <div class=\"section-body\">\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>    \n         <div class=\"default-heading\">\n            <h4 class=\"pull-left totals-title\">\n                <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.TransactionTotals",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":22},"end":{"line":16,"column":55}}}))
    + "</span>\n            </h4>\n            <span class=\"pull-right\" data-region=\"totalsSection\" aria-live=\"polite\"></span>\n        </div>       \n        <div class=\"btn-wrapper\">\n            <div class=\"btn-group widget-links\">\n                <button type=\"button\" class=\"btn btn-tertiary export\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.export",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":77},"end":{"line":22,"column":103}}}))
    + "\" data-action=\"export\"><span class=\"icon-export\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.export",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":160},"end":{"line":22,"column":186}}}))
    + "</button>\n                <button type=\"button\" class=\"btn btn-tertiary print\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":76},"end":{"line":23,"column":101}}}))
    + "\" data-action=\"print\"><span class=\"icon-print\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":156},"end":{"line":23,"column":181}}}))
    + "</button>\n            </div>\n        </div>\n        <div class=\"filter-region\" data-region=\"filterRegion\"></div>\n                <div class=\"grid-region\" data-region=\"gridRegion\"></div>\n    </div>\n</div>\n";
},"useData":true});