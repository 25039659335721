export default function (
    form,
    initialState,
) {
    if (initialState === null) {
        form.field('NEEDS_MORE_INFO_REASON').shouldBeHidden();
    }
    if (form.formView.state === 'insert' || form.formView.state === 'modify') {
        form.field('NEEDS_MORE_INFO_REASON').shouldBeHidden();
        form.field('APPROVER_REJECTION_REASON').shouldBeHidden();
        form.field('AUDIT_HISTORY').shouldBeHidden();
        form.field('STATUS').shouldBeHidden();
    }
}
