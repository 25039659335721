import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';

const CategoryPlanModel = Model.extend({
    defaults: {
        // from either the inflow OR outflow category collection
        categoryId: null,

        name: '',
        sum: 0,

        // monthly plan amount
        1: 0,

        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
    },

    initialize() {
        this.listenTo(
            this,
            {
                change: this.updateSum,
            },
            this,
        );

        this.updateSum();
        this.isDirty = false;
    },

    updateSum() {
        if (!this.hasChanged('sum')) {
            const newSum = util.reduce(util.range(1, 13), function (memo, value) {
                return memo + this.get(value);
            }, 0, this);

            this.set({
                sum: newSum,
            });
            this.isDirty = true;
        }
    },
});

export default CategoryPlanModel;
