import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import store from '@glu/store';
import { createTabsToggleButton, toggleTabs, setTabButtonText } from 'common/util/a11y/tabs';
import WarningDialog from 'common/dynamicPages/views/warningDialog';
import alert from '@glu/alerts';
import AlertDetails from 'app/smbPayments/alerts/paymentAlertDetails';
import EnterBillGridView from 'app/smbBillManagement/views/enterBillGrid';
import ValidateBillGridView from 'app/smbBillManagement/views/validateBillGrid';
import ApproveBillGridView from 'app/smbBillManagement/views/approveBillGrid';
import PayBillGridView from 'app/smbBillManagement/views/payBillGrid';
import HistoryBillGridView from 'app/smbBillManagement/views/historyBillGrid';
import MasterGridView from 'app/smbBillManagement/views/masterGrid';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import workspaceHelper from 'common/workspaces/api/helper';
import entitlements from 'common/dynamicPages/api/entitlements';
import ContextApi from 'common/dynamicPages/api/context';
import constants from 'common/dynamicPages/api/constants';
import VisualFilterCollection from 'app/smbBillManagement/collections/visualFilter';
import VisualFilterView from 'app/smbBillManagement/views/visualFilter';
import template from './listBills.hbs';

const BillManagementList = Layout.extend({
    className: 'smb-bill-management',
    template,

    regions: {
        pageRegion: '.pageRegion',
        alertRegion: '.alert-region',
        visualFilterRegion: 'div[data-region="visual-filter"]',
    },

    ui: {
        $navTabs: '[data-hook="getNavTabs"]',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
    },

    events: {
        'click [data-hook="insert-button"]': 'insert',
        'click @ui.$navLinks': 'updateView',
    },

    appEvents: {
        'payment:newPayment:gridAlert': 'renderPaymentSuccess',
    },

    isInitialRender: true,

    initialize() {
        this.contextKey = 'bill_listview';

        this.visualFilterCollection = new VisualFilterCollection();
        this.visualFilterCollection.fetch();
    },

    insert(options) {
        if (util.isUndefined(options.BILL_ID)) {
            this.navigateTo('BILLMGMT_BASIC/bill/create');
        } else if (options.readOnly) {
            this.navigateTo(`BILLMGMT_BASIC/bill/view/${options.BILL_ID}/`);
        } else {
            this.navigateTo(`BILLMGMT_BASIC/bill/modify/${options.BILL_ID}/`);
        }
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            createTabsToggleButton(this);
            this.showFirstEntitledView();

            this.visualFilterRegion.show(new VisualFilterView({
                collection: this.visualFilterCollection,
            }));

            if (store.get('bosBillMessage')) {
                this.renderMessage('INSERT', store.get('bosBillMessage'));
                store.unset('bosBillMessage');
            }
            this.isInitialRender = false;
        } else {
            this.loadViewRequirements();
        }
    },

    updateView(e) {
        const href = this.$(e.currentTarget).attr('href');
        e.preventDefault();
        this.openView(href);
    },

    openView(href) {
        if (this.alertRegion) {
            this.alertRegion.close();
        }
        this.changeTab(href);

        if (this.gridView !== undefined && this.gridView !== null) {
            this.stopListening(this.gridView.gridView, 'rowAction', this.gridRowAction);
        }

        switch (href) {
        case '#enter':
            this.gridView = new EnterBillGridView();
            break;
        case '#validate':
            this.gridView = new ValidateBillGridView({
                entitlements: this.entitlements,
                alertRegion: this.alertRegion,
            });
            break;
        case '#approve':
            this.gridView = new ApproveBillGridView({
                entitlements: this.entitlements,
                alertRegion: this.alertRegion,
            });
            break;
        case '#pay':
            this.gridView = new PayBillGridView();
            break;
        case '#history':
            this.gridView = new HistoryBillGridView();
            break;
        case '#master':
            this.gridView = new MasterGridView({
                entitlements: this.entitlements,
                alertRegion: this.alertRegion,
            });
            break;
        default:
            this.showFirstEntitledView();
        }

        this.updateVisualFilters(this.gridView);

        this.pageRegion.show(this.gridView);
        this.listenTo(this.gridView.gridView, 'rowAction', this.gridRowAction);
    },

    updateVisualFilters(gridView) {
        this.visualFilterCollection.setGridView(gridView);
    },

    changeTab(e) {
        this.$(`a[href$=${e}]`).parent('li').addClass('is-active').siblings()
            .removeClass('is-active');

        this.currentTabText = this.$(`a[href$=${e}]`).text();
        if (this.isInitialRender) {
            setTabButtonText(this, this.currentTabText);
        } else {
            toggleTabs(this);
        }
    },

    renderMessage(action, confirmResponse, errorCount) {
        /*
         * create the confirmation view for the alert
         * skip if action is null.  This occurs when first entering the payment workspace
         * because the onRender is called.
         */
        if ((action === null || action === undefined)
            && (confirmResponse === null || confirmResponse === undefined)) {
            // TODO: refactor empty block
        } else if (confirmResponse === null
            || confirmResponse === undefined
            || confirmResponse.confirms === null
            || confirmResponse.confirms === undefined) {
            if (action.type === 'WARNING' && action.message) {
                this.alertView = alert.warning(action.message);
                this.alertRegion.show(this.alertView);
            }
        } else {
            const confirm = typeof confirmResponse === 'object' ? confirmResponse.confirms : null;
            const successCount = confirm !== null ? confirm.totalSuccess : confirmResponse;
            const failCount = confirm !== null ? confirm.totalFail : errorCount;
            const totalCount = failCount + successCount;
            const success = successCount === totalCount;
            const alertFunc = success ? alert.success : alert.danger;
            const chainedMessages = typeof confirmResponse === 'object' ? confirmResponse.message : null;
            const message = typeof confirmResponse === 'object' && confirmResponse.message.length > 0 ? confirmResponse.message[chainedMessages.length - 1] : action;

            const confirms = new Confirms({
                confirms: confirmResponse ? confirmResponse.confirms : null,
            });

            // display notification message
            this.alertView = alertFunc(
                message,
                {
                    details: confirmResponse
                        && confirmResponse.confirms.confirmResults[0].confirmData[0].item
                        ? confirms : null,
                    canDismiss: !!confirmResponse,
                    animate: false,
                },
            );

            if (message) {
                this.alertRegion.show(this.alertView);
            }
        }
    },

    templateHelpers() {
        const self = this;

        return {
            hasInsertEntitlement() {
                return self.hasEntitlement(constants.ACTION_INSERT);
            },

            hasValidateOrInvalidateEntitlement() {
                return self.hasEntitlement(constants.ACTION_VALIDATE)
                    || self.hasEntitlement(constants.ACTION_INVALIDATE);
            },

            hasApproveOrUnapproveEntitlement() {
                return self.hasEntitlement(constants.ACTION_APPROVE)
                    || self.hasEntitlement(constants.ACTION_UNAPPROVE);
            },

            hasPayEntitlement() {
                return self.hasEntitlement(constants.ACTION_PAY);
            },
        };
    },

    loadViewRequirements() {
        const self = this;

        const options = {
            context: ContextApi.menuContext.getContext('BOS_BILLMGMT'),
        };

        const entitlementPromise = entitlements.getEntitlements(options);

        Promise.all([entitlementPromise]).then((results) => {
            const [entResActions] = results;
            self.entitlements = entResActions;
            self.setHasLoadedRequiredData(true);
            self.render();
        });
    },

    hasEntitlement(action) {
        return this.entitlements
            && this.entitlements.actions
            && this.entitlements.actions[action] === true;
    },

    showFirstEntitledView() {
        if (this.hasEntitlement(constants.ACTION_INSERT)) {
            this.openView('#enter');
        } else if (this.hasEntitlement(constants.ACTION_VALIDATE)) {
            this.openView('#validate');
        } else if (this.hasEntitlement(constants.ACTION_APPROVE)) {
            this.openView('#approve');
        } else if (this.hasEntitlement(constants.ACTION_PAY)) {
            this.openView('#pay');
        } else {
            this.openView('#history');
        }
    },

    gridRowAction(options) {
        if (this.alertRegion) {
            this.alertRegion.close();
        }
        if (options.action.toUpperCase() === 'SELECT') {
            return this.gridRowSelect(options);
        }
        if (options.action.toUpperCase() === 'MODIFY') {
            return this.gridRowModify(options);
        }
        if (options.action.toUpperCase() === 'DELETE') {
            return this.gridRowDelete(options);
        }
        if (options.action.toUpperCase() === 'APPROVE') {
            return this.gridRowApprove(options);
        }
        if (options.action.toUpperCase() === 'VALIDATE') {
            return this.gridRowValidate(options);
        }
        if (options.action.toUpperCase() === 'UNAPPROVE') {
            return this.gridRowUnapprove(options);
        }
        if (options.action.toUpperCase() === 'UNVALIDATE') {
            return this.gridRowUnvalidate(options);
        }
        if (options.action.toUpperCase() === 'REJECT') {
            return this.gridRowReject(options);
        }
        return Promise.resolve();
    },

    gridRowSelect(options) {
        this.insert({
            BILL_ID: options.model.get('BILL_ID'),
            readOnly: true,
        });
        return Promise.resolve();
    },

    gridRowModify(options) {
        this.insert({
            BILL_ID: options.model.get('BILL_ID'),
        });
        return Promise.resolve();
    },

    gridRowDelete(options) {
        return new Promise((resolve, reject) => {
            const self = this;
            options.model.destroy({
                success(model, resp) {
                    self.gridView.gridView.refreshGridData();
                    self.renderMessage(options.action, resp);
                    resolve({ model, result: resp });
                },

                error(e) {
                    self.gridView.gridView.refreshGridData();
                    self.renderMessage(options.action);
                    reject(e);
                },
            });
        });
    },

    gridRowApprove(options) {
        return new Promise((resolve, reject) => {
            const self = this;
            options.model.approve({
                success(model, resp) {
                    if (resp.resultType === 'WARNING') {
                        dialog.custom(new WarningDialog({
                            model,
                            methodName: 'APPROVE',
                            grid: self.gridView.gridView,
                            confirms: resp.confirms,
                        }));
                    } else {
                        self.gridView.gridView.refreshGridData();
                        self.renderMessage(options.action, resp);
                        resolve({ model, result: resp });
                    }
                },

                error(e) {
                    self.gridView.gridView.refreshGridData();
                    self.renderMessage(options.action);
                    reject(e);
                },
            });
        });
    },

    gridRowValidate(options) {
        return new Promise((resolve, reject) => {
            const self = this;
            options.model.validateAction({
                success(model, resp) {
                    if (resp.resultType === 'WARNING') {
                        dialog.custom(new WarningDialog({
                            model,
                            methodName: 'VALIDATE',
                            grid: self.gridView.gridView,
                            confirms: resp.confirms,
                        }));
                    } else {
                        self.gridView.gridView.refreshGridData();
                        self.renderMessage(options.action, resp);
                    }
                    resolve({ model, result: resp });
                },

                error(e) {
                    self.gridView.gridView.refreshGridData();
                    self.renderMessage(options.action);
                    reject(e);
                },
            });
        });
    },

    gridRowUnapprove(options) {
        return new Promise((resolve, reject) => {
            const self = this;
            options.model.unapprove({
                success(model, resp) {
                    self.gridView.gridView.refreshGridData();
                    self.renderMessage(options.action, resp);
                    resolve({ model, result: resp });
                },

                error(e) {
                    self.gridView.gridView.refreshGridData();
                    self.renderMessage(`${options.action} error`);
                    reject(e);
                },
            });
        });
    },

    gridRowUnvalidate(options) {
        return new Promise((resolve, reject) => {
            const self = this;
            options.model.unvalidate({
                success(model, resp) {
                    self.gridView.gridView.refreshGridData();
                    self.renderMessage(options.action, resp);
                    resolve({ model, result: resp });
                },

                error(e) {
                    self.gridView.gridView.refreshGridData();
                    self.renderMessage(`${options.action} error`);
                    reject(e);
                },
            });
        });
    },

    gridRowReject(options) {
        return new Promise((resolve, reject) => {
            const self = this;
            options.model.reject({
                success(model, resp) {
                    self.gridView.gridView.refreshGridData();
                    self.renderMessage(options.action, resp);
                    resolve({ model, result: resp });
                },

                error(e) {
                    self.gridView.gridView.refreshGridData();
                    self.renderMessage(`${options.action} error`);
                    reject(e);
                },
            });
        });
    },

    renderPaymentSuccess(model, message) {
        // update bill status
        if (model !== undefined && model !== null && this.gridView.billToPay !== null) {
            this.gridView.paymentIssued(model.get('BILL_ID'));
        }

        this.gridView.gridView.refreshGridData();

        // render the alert
        this.renderPaymentAlertContent(model, message);
    },

    renderPaymentAlertContent(model, message) {
        // create the details view for the alert
        const paymentDetails = new AlertDetails({
            model,
        });

        // display notification message
        this.alertView = alert.success(
            message,
            {
                details: paymentDetails,
            },
        );

        this.alertRegion.show(this.alertView);
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'SMBBILLS',
    view: BillManagementList,
    options: {},
});

export default BillManagementList;
