import PrintModal from 'common/modals/printOptions';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import combinedAccountPrintModalTmpl from './combinedAccountPrintModal.hbs';

const CombinedAccountPrintModal = PrintModal.extend({
    template: combinedAccountPrintModalTmpl,

    ui: {
        $includeSummaryInfoCheckbox: '[data-hook="getIncludeSummaryInfoCheckbox"]',
    },

    initialize() {
        // showIncludeSummaryInfo is set to 0 and unchecked by default
        this.model.set({ includeSummaryInfo: 0 });

        this.dialogTitle = locale.get('common.printOptions.title');

        this.dialogButtons = [{
            text: locale.get('common.printOptions.button.print'),
            className: 'btn btn-primary',
            callback: 'print',
        }, {
            text: locale.get('common.printOptions.button.cancel'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }];
    },

    /**
     * Close this dialog and call printTransactionDetails
     */
    print() {
        dialog.close();
        this.options.onPrint();
    },
});

export default CombinedAccountPrintModal;
