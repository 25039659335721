import util from '@glu/core/src/util';
import ListView from 'app/payments/views/listTemplates';
import store from 'system/utilities/cache';
import constants from 'common/dynamicPages/api/constants';
import workspaceHelper from 'common/workspaces/api/helper';
import template from './listView.hbs';

const AdminTemplatesList = ListView.extend({
    template,

    initialize(options) {
        const superOptions = {
            menuCategory: 'REPORTING',
            serviceName: '/adminTemplate/listView/templates',
            serviceFunc: null,
            businessType: null,
            enableSavedViews: true,
            mode: 'view',
            context: 'TEMP_LIST_VIEW',
            returnRoute: 'REPORTING/viewTemplates',
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));

        this.exportListViewPath = '/export/listView';
    },

    prepareExportScreenInfo(exportModelParam) {
        const exportModel = exportModelParam;
        ListView.prototype.prepareExportScreenInfo.call(this, exportModel);
        // disable export type selections for admin
        exportModel.showDataFormatForSel = false;
        exportModel.showDataFormatForAll = false;
    },

    buildExportModel(format) {
        ListView.prototype.buildExportModel.call(this, format);
        this.exportModel.inquiryId = this.exportModel.expData === 'transaction' ? '20082' : '20008';
    },

    /**
     * Get print options for this list
     * @returns {Object}
     */
    getPrintOptions() {
        return {
            inquiryId: constants.INQUIRY_ID_20082,
            summaryInquiryId: constants.INQUIRY_ID_20008,
        };
    },

    isLoan(model) {
        const type = model.get('TYPE');
        return type === 'LOANPAY' || type === 'LOANDRAW';
    },

    gridRowSelect(options) {
        if (this.isLoan(options.model)) {
            store.set('loanTemplateModel', options.model);
            this.navigateTo('REPORTING/viewLoanTemplateDetails');
        } else {
            store.set(`${this.contextKey}-actionModel`, options.model);
            this.navigateTo('REPORTING/viewTemplatesDetails');
        }
        return Promise.resolve();
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'TEMP_LIST_VIEW',
    view: AdminTemplatesList,
    options: {},
});

export default AdminTemplatesList;
