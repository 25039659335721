import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import Collection from '@glu/core/src/collection';
import Grid from '@glu/grid';
import transform from 'common/util/transform';
import localeKeysTmpl from './localeKeys.hbs';

export default Layout.extend({
    template: localeKeysTmpl,

    onRender() {
        const localeCollection = new Collection(
            transform.hashToPairs(locale.pairs),
            {
                comparator: 'name',
            },
        );

        new Grid({
            el: this.$('.grid'),
            collection: localeCollection,

            columns: [{
                field: 'name',
                title: 'Key',
            }, {
                field: 'value',
                title: 'Localized string',
            }],

            pageable: {
                pageSize: 10,
                pageSizeValues: [10, 25, 50, 100, 250, 1000, 5000, 25000],
            },

            filterable: true,
        }).render();
    },
});
