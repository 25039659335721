export default function (
    form,
    initialState,
) {
    const formState = form.formView.state;
    const paymentTypeDesc = form.field('PAYMENTTYPEDESC');
    const achCompanyLookup = form.field('ACHCOMPANYLOOKUP');

    if (initialState && formState === 'insert') {
        paymentTypeDesc.shouldBeReadOnly(true);
    }

    if (initialState && formState === 'modify') {
        achCompanyLookup.addClassToEl('hidden');
    }
}
