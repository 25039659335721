import 'jui/droppable';
import 'jui/draggable';
import Glu from '@glu/core/src/glu';
import locale from '@glu/locale';
import $ from 'jquery';
import TileView from 'app/smbPayments/views/accounts/balances/tileView';
import Dialog from '@glu/dialog';
import store from 'system/utilities/cache';
import loans from 'app/loans/api/common';
import accountUtil from 'app/smbPayments/util/accountUtil';
import TransferLayout from 'app/smbPayments/views/accounts/balances/transfer/transferLayout';
import LoanPaymentLayout from 'app/smbPayments/views/accounts/balances/transfer/loanPaymentLayout';
import LoanDrawLayout from 'app/smbPayments/views/accounts/balances/transfer/loanDrawLayout';
import Account from 'app/balanceAndTransaction/models/account';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import constants from 'app/balanceAndTransaction/constants';

export default TileView.extend({
    tagName: 'li',

    initialize(options) {
        const tranType = this.model.get('tranType');
        /*
         * DR is debit, CR is credit, BO is both.  An account tile is draggable if
         * the account has debit
         * capabilities.  An account tile is droppable if the account has credit
         * capabilities.
         */
        let isDraggable = tranType === 'DR' || tranType === 'BO';
        let isDroppable = tranType === 'CR' || tranType === 'BO';

        /*
         * NH-150580 - Disable for certain customers, configured in IDT
         * --- Otherwise ---
         * NH-152866 According to the PO, only disable dragging and dropping
         * on credit card tiles, when not permissioned. This will not affect
         * the behavior of any other tiles.
         */
        if (serverConfigParams.get('DisableDragAndDropTiles') !== 'true') {
            const canTransfer = this.model.get('canTransfer');
            if (accountUtil.isCreditCardAccount(this.model.get('accountType')) && !canTransfer) {
                isDraggable = false;
                isDroppable = false;
            }
            if (isDraggable) {
                this.initializeDraggableItem();
            }
            if (isDroppable) {
                this.initializeDroppableItem();
            }
        }

        $(this.el).data('backbone-view', this);

        this.loanPrefs = options.loanPrefs;
    },

    initializeDraggableItem() {
        $(this.el).draggable({
            // clicking an icon or button won't initiate dragging
            cancel: 'a.ui-icon, .btn-group',

            // when not dropped, the item will revert back to its initial position
            revert: 'invalid',

            revertDuration: 200,

            /*
             * containment: 'document',
             * helper: 'clone',
             */
            cursor: 'move',

            start(event, ui) {
                ui.helper.addClass('tilt');
            },

            stop(event, ui) {
                ui.helper.removeClass('tilt');
            },
        });
    },

    initializeDroppableItem() {
        const self = this;

        $(this.el).droppable({
            hoverClass: 'account-hover',

            drop(event, ui) {
                const droppedModel = $(this).data('backbone-view').model;
                const draggedModel = $(ui.draggable).data('backbone-view').model;

                ui.draggable.draggable('option', 'revert', true);

                self.viewTransfer(draggedModel, droppedModel);
            },
        });
    },

    ui: {
        viewDetailsLink: '.view-details',
        addTransferBtn: '.btn-transfer',
    },

    events: {
        'click @ui.viewDetailsLink': 'viewTransactions',
        'click @ui.addTransferBtn': 'addTransfer',
    },

    showDialogWarningModal(draggedModel, droppedModel, entitleType) {
        const draggedModelCantTransfer = draggedModel.get(entitleType);
        const droppedModelCantTransfer = droppedModel.get(entitleType);
        let toFromMessage = '';

        if (draggedModelCantTransfer) {
            // locale - 'Money cannot be moved to this account.'
            toFromMessage = locale.get('SMBPAY.DragDropErr2');
        } else if (droppedModelCantTransfer) {
            // locale - Money cannot be moved from this account.
            toFromMessage = locale.get('SMBPAY.DragDropErr1');
        } else {
            // locale - 'Money cannot be moved to or from this account.'
            toFromMessage = locale.get('SMBPAY.DragDropErr3');
        }
        Dialog.warning(toFromMessage, locale.get('SMBPAY.Caution'));
    },

    viewTransfer(draggedModel, droppedModel) {
        const dragType = draggedModel.get('accountType');
        const dropType = droppedModel.get('accountType');
        const canLoanDraw = draggedModel.get('canLoanDraw') && droppedModel.get('canLoanDraw');
        const canLoanPay = draggedModel.get('canLoanPay') && droppedModel.get('canLoanPay');
        const canTransfer = draggedModel.get('canTransfer') && droppedModel.get('canTransfer');
        const isTransferLinkEntitled = draggedModel.get('isTransferLinkEntitled') && droppedModel.get('isTransferLinkEntitled');

        if (!isTransferLinkEntitled) {
            // can't transfer
            this.showDialogWarningModal(draggedModel, droppedModel, 'isTransferLinkEntitled');
        } else if (accountUtil.isLoan(dragType) && accountUtil.isDeposit(dropType)) {
            // loan draw
            if (canLoanDraw) {
                Dialog.open(new LoanDrawLayout({
                    draggedModel,
                    droppedModel,
                    preferences: this.loansPrefs,
                }));
            } else {
                this.showDialogWarningModal(draggedModel, droppedModel, 'canLoanDraw');
            }
        } else if (accountUtil.isDeposit(dragType) && accountUtil.isLoan(dropType)) {
            // loan payment
            if (canLoanPay) {
                Dialog.open(new LoanPaymentLayout({
                    draggedModel,
                    droppedModel,
                    preferences: this.loanPrefs,
                }));
            } else {
                this.showDialogWarningModal(draggedModel, droppedModel, 'canLoanPay');
            }
        } else if (canTransfer) {
            // transfer
            Dialog.open(new TransferLayout({
                draggedModel,
                droppedModel,
            }));
        } else {
            this.showDialogWarningModal(draggedModel, droppedModel, 'canTransfer');
        }
    },

    viewTransactions() {
        const subAccountType = this.model.get('subAccountType');
        const account = new Account({
            accountFilter: this.model.get('accountFilter'),
            accountName: this.model.get('accountName'),
            accountNumber: this.model.get('accountNumber'),
            accountType: this.model.get('accountType'),
            isRealTime: this.model.get('isRealTimeAccount'),
            returnRoute: Glu.Backbone.history.getFragment(),
            clientAccountName: this.model.get('clientAccountName'),
            currencyCode: this.model.get('currency'),
            tabId: constants.ACCOUNT_SUMMARY.TABS.ALL,
            sectionId: this.model.get('sectionId'),
            ...(subAccountType && { subAccountType }),
        });

        store.set('balanceAndTransactionAccount', account);
        const route = accountUtil.getViewDetailRoute(
            this.model.get('accountType'),
            this.isTieredLoan(),
        );
        this.navigateTo(route);
    },

    /**
     * Helper method check if an account is a tiered loan.
     * @returns {boolean}
     */
    isTieredLoan() {
        /*
         * When updateMasterOnSubAccountEnabled is enabled, we will
         * treat all loans as tiered loans
         * when is disabled, the UI will need to re-route to the correct loan page
         */
        const updateMasterOnSubAccountEnabled = applicationConfigParams
            .getValue(
                'TIERLOAN',
                'UpdateMasterOnSubAccountEnabled',
            ) === '1';
        return updateMasterOnSubAccountEnabled || this.model.get('isTieredLoan');
    },

    addTransfer() {
        const accountType = this.model.get('accountType');
        if (accountUtil.isLoanPayment(accountType)) {
            if (this.model.get('canLoanPay')) {
                Dialog.open(new LoanPaymentLayout({
                    draggedModel: this.model,
                    preferences: this.loanPrefs,
                }));
            }
        } else if (accountUtil.isLoanDraw(accountType)) {
            if (this.model.get('canLoanDraw')) {
                Dialog.open(new LoanDrawLayout({
                    draggedModel: this.model,
                    preferences: this.loansPrefs,
                }));
            }
        } else if (this.model.get('canTransfer')) {
            Dialog.open(new TransferLayout({
                draggedModel: this.model,
            }));
        }
    },

    onRender() {
        this.delegateEvents();
    },

    loadRequiredData() {
        loans.getPaymentPreferences().then((loansPrefs) => {
            this.loansPrefs = loansPrefs;
            this.setHasLoadedRequiredData(true);
        });
    },
    templateHelpers() {
        return {
            ...TileView.prototype.templateHelpers.call(this),
            showTransfer: accountUtil.showTransfer(this.model),
        };
    },
});
