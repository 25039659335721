import http from '@glu/core/src/http';
import Poll from '@glu/polling';
import alert from '@glu/alerts';
import $ from 'jquery';
import locale from '@glu/locale';
import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import ContextApi from 'common/dynamicPages/api/context';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import services from 'services';
import constants from 'app/reports/constants';
import ReportStatus from 'app/reports/models/reportStatus';
import ContentView from 'app/reports/views/contentView';
import filterApi from 'common/dynamicPages/api/filters';
import workspaceHelper from 'common/workspaces/api/helper';
import AddProfileModalView from 'app/reports/views/addProfile';
import profileApi from 'app/reports/api/profile';
import Messages from 'app/reports/views/messageView';
import store from 'system/utilities/cache';
import moment from 'moment';
import mobileUtil from 'mobile/util/mobileUtil';
import reportViewTmpl from 'app/reports/views/reportView.hbs';
import { asView } from 'common/util/reactUtil';
import AsyncPrintViewModal from 'components/AsyncReporting/AsyncPrintViewModal';
import helpers from 'components/AsyncReporting/helpers';
import AsyncReportContentView from 'app/reports/views/asyncReportContentView';
import systemConfig from 'system/configuration';
import exportTemplate from './exportView.hbs';
import viewTemplate from './contentView.hbs';

const ReportView = Layout.extend({
    template: reportViewTmpl,

    regions: {
        alertRegion: '[data-hook="alertRegion"]',
        infoRegion: '[data-hook="infoRegion"]',
    },

    ui: {
        $heading: '.panel-heading',
        $saveReport: 'input[type=checkbox]',
        $reportCancel: '#widget-action-btn-group button[data-action="cancel"]',
    },

    events: {
        'click @ui.$heading': 'togglePanel',
        'change @ui.$saveReport': 'toggleSaveReportConfig',
    },

    initialize(options) {
        this.model = options.model;
        this.lastFragment = options.lastFragment;
        this.isAdmin = systemConfig.isAdmin();
        this.isMobile = mobileUtil.isNativeApp() || mobileUtil.isMobileScreen();
        this.createPoller();

        this.listenTo(this.appBus, constants.REPORT_READY_EVENT, this.renderReport);
        this.listenTo(this.appBus, constants.REPORT_RUNNING_EVENT, this.renderStatus);
        this.listenTo(this.appBus, constants.REPORT_ERROR_EVENT, this.showError);
        this.listenTo(this.appBus, constants.REPORT_TIMEOUT_EVENT, this.showError);

        this.contextDef = this.options.contextDef
            || ContextApi.menuContext.getContext(this.options.context);
        this.mode = 'insert';

        const profileNumber = this.model.get('PROFILENUM');
        const filterId = this.model.get(constants.FILTERID_ATTR);

        const filterContextDef = this.contextDef;
        filterContextDef.serviceName = 'advanceFilter';
        // need 'context' for getQueryResult filters
        filterContextDef.productCode = '*';
        filterContextDef.functionCode = 'RPT';
        filterContextDef.typeCode = '*';
        // NH-126726 setting the filter id to be used in the policy file.
        filterContextDef.filterId = filterId;
        this.filterContextDef = filterContextDef;

        this.loadReportOnRender = (profileNumber && profileNumber > 0 && this.model.action !== 'MODIFY');
        this.noProfilesOrFilters = (!profileNumber || profileNumber <= 0)
            && (!filterId || filterId <= 0);
        this.isSavedReport = this.model.get('PROFILENUM') && this.model.get('PROFILENUM') > 0;
    },

    onClose() {
        this.poller.dispose();
    },

    onRender() {
        const filterId = this.model.get(constants.FILTERID_ATTR);
        // hide save & export when no report rendered.
        if (!this.filledReport) {
            this.ui.$reportCancel.removeClass('btn-tertiary');
            this.ui.$reportCancel.addClass('btn-primary');
        }
        if (filterId && filterId > 0) {
            this.setAdditionalSearchParameters();
            const promiseMe = this.retrieveFilter();
            if (promiseMe !== undefined) {
                promiseMe.then(() => {
                    const p = profileApi.getProfile(this.model.get(constants.PROFILENUM_ATTR));
                    return p;
                }).then((data) => {
                    const p = new Promise((resolve) => {
                        // Add a pause to ensure the screen is ready
                        util.delay(() => {
                            // Initialize the filter only if the profileAPI found a profile.
                            if (data && data.responseHeader.result) {
                                this.filterProfile = data;
                                this.initializeFilter(data);
                            }
                            this.setUpDefault();
                            resolve(data);
                        }, 250);
                    });
                    return p;
                }).then(() => {
                    if (this.loadReportOnRender) {
                        const filterModel = this.filterRegion.currentView.model;
                        filterModel.set('LOAD_REPORT_ON_RENDER', true);
                        if (this.isAdmin) {
                            this.run();
                        }
                    }
                });
            }
        } else if (this.loadReportOnRender || this.noProfilesOrFilters) {
            this.run();
        }
    },

    templateHelpers() {
        const self = this;

        return {
            hasFilters() {
                const filterId = self.model.get(constants.FILTERID_ATTR);
                return (!!(filterId && filterId > 0));
            },

            filterTitle() {
                return self.getFilterPanelTitle();
            },
            showSave: !mobileUtil.isNativeApp(),
            isModifyMode: this.model.action === 'MODIFY',
        };
    },

    getFilterPanelTitle() {
        const profileNum = this.model.get('PROFILENUM');
        return (profileNum && profileNum > 0 ? `${locale.get('RPT.Filter')} - ${this.model.get('PROFILEDESCRIPTION')}` : locale.get('RPT.Filter'));
    },

    togglePanel(event) {
        const $target = $(event.currentTarget);

        $target.find('span[class^=icon-]').first().toggleClass('icon-caret-down icon-arrow-right');
    },

    retrieveFilter() {
        const { model } = this;
        let promiseMe;

        this.filterContextDef = this.contextDef;
        this.filterContextDef.serviceName = 'advanceFilter';

        if (this.model.get('ID') === '31030' || this.model.get('ID') === '31050') {
            this.pastOnly = serverConfigParams.get('DatepickerEnablePastOnlyImport') === 'true';
        } else if (this.model.get('ID') === '31060') {
            this.currentAndPastOnly = true;
        }

        const requestData = {
            filterId: model.get(constants.FILTERID_ATTR),

            typeInfo: {
                productCode: model.get('PRODUCTCODE'),
                functionCode: 'RPT',
                typeCode: model.get('ID'),
            },
        };

        if (model.isValid()) {
            store.set(this.getCacheName(), this.model);
            promiseMe = filterApi.getFilters(requestData, this);
        }

        return promiseMe;
    },

    setAdditionalSearchParameters() {
        this.dependentTypeAheadFields = [{
            field: 'ROLEID',
            dependsOn: 'USERGROUP',
            operator: 'IN',
            dataType: 'text',
        }, {
            field: 'USERID',
            dependsOn: 'USERGROUP',
            operator: 'CONTAINS',
            dataType: 'text',
        }];
    },

    createPoller() {
        const self = this;
        let failedOnce = false;
        const count = serverConfigParams.get('reportPollLimit') !== undefined ? serverConfigParams.get('reportPollLimit') : 12;
        const intvl = 1000 * (serverConfigParams.get('reportPollIntervalSeconds') || 5);

        this.reportStatus = new ReportStatus({
            targetId: -1,
        });

        const options = {
            iterator(poll) {
                this.reportStatus.fetch({
                    success(model) {
                        failedOnce = false;

                        if (model.get('STATUS') === 'DN') {
                            self.appBus.trigger(constants.REPORT_READY_EVENT, model);
                        } else if (model.get('STATUS') === 'RJ') {
                            self.appBus.trigger(
                                constants.REPORT_ERROR_EVENT,
                                constants.ERROR_MESSAGE,
                            );
                        } else {
                            self.appBus.trigger(constants.REPORT_RUNNING_EVENT, model);
                            poll();
                        }
                    },

                    error() {
                        // Add the ability to "skip a beat" in the poller heartbeat.
                        if (failedOnce) {
                            self.appBus.trigger(
                                constants.REPORT_ERROR_EVENT,
                                constants.STATUS_RETRIEVAL_ERROR_MESSAGE,
                            );
                        } else {
                            failedOnce = true;
                            poll();
                        }
                    },
                });
            },

            limit: count,

            onLimit() {
                self.appBus.trigger(constants.REPORT_TIMEOUT_EVENT, constants.TIMEOUT_MESSAGE);
            },

            interval: intvl,
        };

        this.poller = new Poll(options, this);
    },

    run() {
        const filterId = this.model.get(constants.FILTERID_ATTR);
        let filterModel;

        /*
         * Add a pause to ensure that the screen is ready to process the profile
         * filter data if it is present.
         */
        util.defer(() => {
            if (filterId && filterId > 0) {
                filterModel = this.filterRegion.currentView.model;
                if (filterModel.validate()) {
                    filterModel.trigger('invalid');
                    return;
                }
                const datesAttr = filterModel.get('CALENDAR_DATE');
                if (datesAttr) {
                    if (!util.isArray(datesAttr)) {
                        const [startDate, endDate] = this.getStartAndEndDate(datesAttr);
                        // eslint-disable-next-line
                        filterModel._datePickerValues.CALENDAR_DATE = [startDate, endDate];
                    } else if (datesAttr.length === 1) {
                        const dateString = datesAttr[0];
                        let dates = [];
                        const isDateRange = dateString.includes('-');
                        if (!isDateRange) {
                            dates = [moment(dateString), moment(dateString)];
                        } else {
                            const [startDate, endDate] = this.getStartAndEndDate(dateString);
                            dates = [startDate, endDate];
                        }
                        // eslint-disable-next-line
                        filterModel._datePickerValues.CALENDAR_DATE = dates;
                    }
                }
                this.filters = filterApi.gatherFilters(this);
            } else {
                this.filters = null;
            }

            $(this.alertRegion.$el).fadeIn(100);
            this.content.close();

            this.handleSaveProfile();

            if (this.isAdmin) {
                this.runTheReport();
            }
        });
    },

    /**
     * @method runTheReport
     * @description handles run non-async reports. This will run in admin UI and mobile
     */
    runTheReport() {
        this.runReport((result) => {
            if (result.errorCode === 0) {
                this.reportStatus.targetId = result.targetId;
                this.poller.start();
            } else {
                const messages = new Messages({
                    messages: result.messages,
                });
                const msg = alert.danger(
                    locale.get(constants.RUN_REQUEST_ERROR_MESSAGE),
                    {
                        details: messages,
                        canDismiss: true,
                    },
                );
                this.alertRegion.show(msg);
            }
        }, () => {
            this.appBus.trigger(
                constants.REPORT_ERROR_EVENT,
                constants.RUN_REQUEST_ERROR_MESSAGE,
            );
        });
    },

    /**
     * @method runAsyncReport
     * @description handles running async reports
     */
    runAsyncReport() {
        this.handleAsyncJasperReport({
            service: services.runAsyncJasperReport,
            postData: this.getPayload(),
        });
    },

    /**
     * @method handleSaveProfile
     * @description handles save profile. On client UI, if save report config is checked, async
     * reports will be called once we receive a success response from saving the configuration.
     */
    handleSaveProfile() {
        if (this.isAdmin && this.ui.$saveReport.is(':checked')) {
            this.saveProfile();
        } else if (!this.isAdmin) {
            if (this.addProfileConfigIsChecked) {
                this.saveProfile(() => {
                    /**
                     * Only run async report on save profile success if save report config check
                     * box is checked
                     */
                    this.handleRunningReport();
                });
            } else {
                $(this.alertRegion.$el).fadeOut(100);
                this.handleRunningReport();
            }
        }
    },

    /**
     * @method handleRunningReport
     * @description handles running the report. Will run async report with async modal
     * when not in mobile.
     */
    handleRunningReport() {
        if (this.isMobile) {
            this.runTheReport();
        } else {
            this.runAsyncReport();
        }
    },

    /**
     * Handles showing async Jasper reports
     * @param {object} options
     */
    handleAsyncJasperReport(options) {
        const AsyncPrintView = asView(AsyncPrintViewModal);
        const AsyncPrintViewModalView = new AsyncPrintView({
            onCancel: () => {
                dialog.close();
                // Navigate back to reports screen when modal footer btn clicked
                this.navigateTo(this.lastFragment);
            },
            onReportComplete: result => this.renderAsyncReport(result),
            onReportIncomplete: () => {
                // Navigate back to reports screen when modal close btn clicked
                this.listenTo(this.appBus, 'glu:modal:close', () => {
                    this.navigateTo(this.lastFragment);
                });
                helpers.setupAsyncReportPoller();
            },
            ...options,
        });
        AsyncPrintViewModalView.dialogTitle = locale.get('async.report');
        dialog.open(AsyncPrintViewModalView);
    },

    /**
     * Helper method to extract start and end date from string
     * @param {string} dateString
     * @returns {moment[]}
     */

    getStartAndEndDate(dateString) {
        const startEndDate = dateString.split(' - ');
        return [moment(startEndDate[0]), moment(startEndDate[1])];
    },

    /**
     * Handles generating reports payload
     * @return {object}
     */
    getPayload() {
        return {
            productCode: this.model.get('PRODUCTCODE'),
            reportId: this.model.get('ID'),
            profileNumber: this.model.get('PROFILENUM'),
            filterData: this.filters,
        };
    },

    runReport(success, failure) {
        const url = services.runReport;
        const postData = this.getPayload();
        // if payment or template detail report, add default sort
        if (constants.PAYMENT_DETAIL_REPORT === postData.reportId) {
            postData.sortFields = [{
                fieldName: 'USERGROUPSEQUENCENUMBER',
                sortOrder: 'DESC',
            }];
        } else if (constants.TEMPLATE_DETAIL_REPORT === postData.reportId) {
            postData.sortFields = [{
                fieldName: 'CMB_TEMPLATE_CODE',
                sortOrder: 'ASC',
            }];
        }
        http.post(url, postData, success, failure);
        const message = alert.info(locale.get(constants.STATUS_MESSAGE));
        this.alertRegion.show(message);
    },

    renderReport(result) {
        this.filledReport = result;
        this.content.show(new ContentView({
            model: result,
            template: viewTemplate,
            reportId: result.get('SEQNO'),
            target: 'pdfContent',
            disposition: 'view',
            exportFormat: 'PDF',
            type: 'jasper',
            formId: 'viewPDF',
        }));
        $(this.alertRegion.$el).fadeOut(3000);
        this.ui.$reportCancel.removeClass('btn-primary');
        this.ui.$reportCancel.addClass('btn-tertiary');
    },

    /**
     * Renders async report pdf file when report generation finishes in time.
     * @param {object} result
     */
    renderAsyncReport(result) {
        dialog.close();
        this.content.show(new AsyncReportContentView({
            hasDetailReport: this.hasDetailReport,
            ...result,
        }));
    },

    exportReport() {
        this.export.show(new ContentView({
            model: this.filledReport,
            template: exportTemplate,
            reportId: this.filledReport.get('SEQNO'),
            target: 'exportReport',
            disposition: 'export',
            exportFormat: 'CSV',
            type: 'jasper',
        }));
    },

    saveReport() {
        this.export.show(new ContentView({
            model: this.filledReport,
            template: exportTemplate,
            reportId: this.filledReport.get('SEQNO'),
            target: 'exportReport',
            disposition: 'export',
            exportFormat: 'PDF',
            type: 'jasper',
        }));
    },

    saveProfile(onSuccess) {
        this.searchFields = filterApi
            .gatherFilterData(this.filterRegion.currentView.model, true);
        if (this.isSavedReport) {
            this.updateProfile(onSuccess);
        } else if (this.isAdmin) {
            this.getProfileId();
        } else {
            this.addProfile(onSuccess);
        }
    },

    getProfileId() {
        this.addProfileView = new AddProfileModalView({
            model: this.model,
        });
        this.listenTo(this.addProfileView, 'report:profile:save', this.addProfile);
        dialog.open(this.addProfileView);
    },

    resetProfile() {
        this.initializeFilter(this.filterProfile);
    },

    addProfile(onSuccess) {
        const self = this;
        const promiseMe = profileApi.addProfile(this.searchFields, this.model);
        promiseMe.then((data) => {
            const { errorCode } = data.responseHeader;
            let message;
            if (errorCode === 0) {
                self.model.set('PROFILENUM', data.profileNumber);
                self.retrieveProfile();
                message = alert.success(locale.get('RPT.profile.add.success'));
                self.alertRegion.show(message);
                onSuccess();
            } else {
                const errorStrings = data.responseHeader.message;
                let resultString = '';
                util.each(errorStrings, (value) => {
                    if (resultString.length > 0) {
                        resultString += ' ';
                    }
                    resultString += value;
                });
                message = alert[errorCode === 308 ? 'info' : 'danger'](resultString);
                self.alertRegion.show(message);
            }
        });
    },

    retrieveProfile() {
        const promiseMe = profileApi.getProfile(this.model.get(constants.PROFILENUM_ATTR));
        const self = this;
        promiseMe.then((data) => {
            self.filterProfile = data;
            self.initializeFilter(data);
        });
    },

    updateProfile(onSuccess) {
        const self = this;

        const promiseMe = profileApi.updateProfile(this.searchFields, this.model);
        promiseMe.then((data) => {
            const { errorCode } = data.responseHeader;
            let message;
            if (errorCode === 0) {
                message = alert.success(locale.get('RPT.profile.update.success'));
                self.alertRegion.show(message);
                onSuccess();
            } else {
                const errorStrings = data.responseHeader.message;
                let resultString = '';
                util.each(errorStrings, (value) => {
                    if (resultString.length > 0) {
                        resultString += ' ';
                    }
                    resultString += value;
                });
                message = alert.danger(resultString);
                self.alertRegion.show(message);
            }
        });
    },

    initializeFilter(data) {
        if (data) {
            util.each(data.profileData.searchCriteria.searchFields, function (value) {
                // Place the profile value in the filter model.
                let fieldValue;
                const filterModel = this.filterRegion.currentView.model;
                if (value.dataType === 'date' || value.dataType === 'gmtdate') {
                    // eslint-disable-next-line
                    if (!filterModel._datePickerValues) {
                        // eslint-disable-next-line
                        filterModel._datePickerValues = [];
                    }
                    let end;
                    const start = moment(value.fieldValue[0]);
                    if (value.operator === 'BETWEEN' && value.fieldValue.length === 2) {
                        fieldValue = [`${value.fieldValue[0]} - ${value.fieldValue[1]}`];
                        end = moment(value.fieldValue[1]);
                    } else {
                        fieldValue = [value.fieldValue[0]];
                        end = moment(value.fieldValue[0]);
                    }
                    // eslint-disable-next-line
                    filterModel._datePickerValues[value.fieldName] = [start, end];
                } else {
                    ({ fieldValue } = value);
                }
                filterModel.set(value.fieldName, fieldValue);

                /**
                 * For numeric fields and when the operator is BETWEEN
                 * there are two fields for the values
                 */
                if (value.dataType === 'numeric' && value.operator === 'BETWEEN' && fieldValue.length === 2) {
                    filterModel.set(value.fieldName, fieldValue[0]);
                    filterModel.set(`${value.fieldName}2`, fieldValue[1]);
                }

                // Place the profile value(s) in the input field(s).
                const { currentView } = this.filterRegion;
                if (currentView.$el) {
                    const selector = `[name="${value.fieldName}"]`;
                    const f = currentView.$el.find(selector);
                    f.val(fieldValue);
                    if (value.dataType === 'numeric' && value.operator === 'BETWEEN' && fieldValue.length === 2) {
                        f.val(fieldValue[0]);
                        // for the second input field for the BETWEEN
                        const selector2 = `[name="${value.fieldName}2"]`;
                        const f2 = currentView.$el.find(selector2);
                        f2.val(fieldValue[1]);
                        // make sure the second field for the BETWEEN is displayed
                        this.$(`[name=${value.fieldName}2]`).removeClass('hidden');
                    }
                    // set the operator field
                    const selectOperator = `[name="${value.fieldName}-equality"]`;
                    const foperator = currentView.$el.find(selectOperator);
                    foperator.val(value.operator);
                }
            }, this);
        } else {
            this.retrieveFilter();
        }
    },

    setUpDefault() {
        const { popupId } = this?.filterRegion?.currentView?.model?.fieldData?.INCLUDESUMMARYFILTER
        || this?.filterRegion?.currentView?.model?.fieldData?.INCLUDEDETAILINFO || {};
        if ([15047, 9067].includes(popupId)) {
            this.filterRegion.currentView.model.set('INCLUDEDETAILINFO', 'Y');
        } else if (popupId === 15048) {
            this.filterRegion.currentView.model.set('INCLUDESUMMARYFILTER', 'BOTH');
        }
    },

    showError(result) {
        const message = alert.danger(locale.get(result));
        this.alertRegion.show(message);
    },

    cancel() {
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    getCacheName() {
        return 'model-advanceFilter-*-RPT';
    },

    renderStatus() {
        const txt = $(this.alertRegion.$el).text();
        const message = alert.info(`${txt}.`);
        this.alertRegion.show(message);
    },

    toggleSaveReportConfig({ currentTarget: { checked } }) {
        this.addProfileConfigIsChecked = checked;
        if (this.isSavedReport || this.isAdmin) return;
        if (!this.addProfileView) {
            this.addProfileView = new AddProfileModalView({
                model: this.model,
                isAdmin: this.isAdmin,
            });
            this.listenTo(this.addProfileView, 'report:profile:save', this.addProfile);
            this.saveProfileConfigRegion.show(this.addProfileView);
        }
        this.saveProfileConfigRegion.$el[checked ? 'show' : 'hide']();
    },
});

export default ReportView;
