var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog modal-2xlg glu-dialog custom-dialog\">\n    <div class=\"modal-content\">\n        <div class=\"modal-header\">\n            <button aria-hidden=\"false\" data-dismiss=\"modal\" class=\"close\" type=\"button\"><span class=\"icon-close\"></span></button>\n                        <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.match",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":28},"end":{"line":5,"column":55}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"DESCRIPTION") || (depth0 != null ? lookupProperty(depth0,"DESCRIPTION") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"DESCRIPTION","hash":{},"data":data,"loc":{"start":{"line":5,"column":56},"end":{"line":5,"column":71}}}) : helper)))
    + "</h2>\n        </div>\n\n        <div class=\"modal-body\">\n                        <div class=\"section\">\n                                <h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.matched.entries",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":36},"end":{"line":10,"column":73}}}))
    + "</h3>\n                                <div data-hook=\"matched-grid-region\"></div>\n                                <button class=\"btn btn-primary\" data-hook=\"unmatch\" disabled>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.match.remove",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":93},"end":{"line":12,"column":127}}}))
    + "</button>\n                        </div>\n\n                        <div class=\"section\">\n                                <h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.match.additional.entries",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":36},"end":{"line":16,"column":82}}}))
    + "</h3>\n                                <div data-hook=\"new-match-grid-region\"></div>\n                                <button class=\"btn btn-primary\" data-hook=\"match\" disabled>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.match",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":91},"end":{"line":18,"column":118}}}))
    + "</button>\n                        </div>\n        </div>\n                <div class=\"modal-footer\">\n            <button data-dismiss=\"modal\" class=\"btn btn-primary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.done",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":65},"end":{"line":22,"column":91}}}))
    + "</button>\n                </div>\n    </div>\n</div>\n";
},"useData":true});