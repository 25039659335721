import './themeDefaults';
import { AccessibilityText } from '@glu/utilities-react';
import { ContainerArrowUpNextIcon } from '@glu/icons-react';
import classNames from 'classnames';
import locale from '@glu/locale';
import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import useStyles from './Dropzone.styles.js';
import validateSelectedFiles from './utils/validateSelectedFiles';
import {
  defaultInternalErrorFormat,
  defaultOnChange,
  defaultSetMessages
} from './defaultPropFunctions';

let uniqueId = 0;

const Dropzone = (props) => {
  const {
    denylist,
    clearMessageList,
    dataQa,
    disabled,
    internalErrorFormat,
    htmlId,
    maxFileSelect,
    maxFileSize,
    minFileSelect,
    name,
    onChange,
    setMessages,
    uploadRules,
    allowlist
  } = props;

  const classes = useStyles(props);
  const [highlight, setHighlight] = useState();
  const fileInputRef = React.createRef();

  // eslint-disable-next-line no-plusplus
  const id = useMemo(() => htmlId || `dropzone-${uniqueId++}`, [htmlId]);

  const fileListToArray = (list) => {
    const array = [];
    for (let i = 0; i < list.length; i += 1) {
      array.push(list[i]);
    }
    return array;
  };

  const handleFileSelect = (files) => {
    const [validFiles, messages] = validateSelectedFiles(
      denylist,
      files,
      internalErrorFormat,
      maxFileSelect,
      maxFileSize,
      minFileSelect,
      allowlist,
    );
    setMessages(prev => (maxFileSelect === 1 && validFiles.length === 1
      ? [] : clearMessageList ? messages : prev.concat(messages)));
    if (validFiles.length > 0) onChange(name, validFiles);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleOnDragOver = (event) => {
    if (!disabled) {
      event.preventDefault();
      setHighlight(true);
    }
  };

  const handleOnDragLeave = () => {
    setHighlight(false);
  };

  const handleOnDrop = (event) => {
    if (!disabled) {
      event.preventDefault();
      handleFileSelect(fileListToArray(event.dataTransfer.files));
    }
    setHighlight(false);
    fileInputRef.current.value = '';
  };

  const handleFileInputChange = (event) => {
    if (event.target.files.length) {
      handleFileSelect(fileListToArray(event.target.files));
    }
    fileInputRef.current.value = '';
  };

  const uploadPromptPart1 = maxFileSelect === 1
    ? locale.get('uploadPromptPart1Singular')
    : locale.get('uploadPromptPart1Plural');

  const uploadPromptButton = maxFileSelect === 1
    ? locale.get('uploadPromptButtonSingular')
    : locale.get('uploadPromptButtonPlural');

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.disabled]: disabled,
        [classes.highlight]: highlight
      })}
      data-qa={`dropzone${dataQa ? `-${dataQa}` : ''}`}
      onDragLeave={handleOnDragLeave}
      onDragOver={handleOnDragOver}
      onDrop={handleOnDrop}
    >
      <h3 className={classes.uploadMessage}>
        <ContainerArrowUpNextIcon className={classes.icon} />
        <span>{uploadPromptPart1}</span>
        <button
          type="button"
          onClick={handleButtonClick}
          disabled={disabled}
          className={`${classes.uploadButton} ${disabled ? classes.disabled : ''}`}
        >
          {uploadPromptButton}
        </button>
        <span>{locale.get('uploadPromptPart2')}</span>
      </h3>
      <form>
        <label htmlFor={id}>
          <AccessibilityText>{locale.get('fileSelectLabel')}</AccessibilityText>
          <input
            name={name}
            id={id}
            className={classes.fileInput}
            multiple
            onChange={handleFileInputChange}
            ref={fileInputRef}
            type="file"
            data-qa={`file-input${dataQa ? `-${dataQa}` : ''}`}
          />
        </label>
      </form>
      {uploadRules && <p className={classes.uploadRules}>{uploadRules}</p>}
    </div>
  );
};


Dropzone.propTypes = {
  /** Disallowed file types */
  denylist: PropTypes.arrayOf(PropTypes.string),

  /** Clear the internal message list after file(s) upload */
  clearMessageList: PropTypes.bool,

  /** String assigned to parent element data-qa attribute for easy targeting */
  dataQa: PropTypes.string,

  /** Toggle if the dropzone/file upload input can be used */
  disabled: PropTypes.bool,

  /** Function to override default error formatting when file does not pass validation
   * @param {FilesList} files - files which have failed a validation condition
   * @returns {Array} - array of error objects */
  internalErrorFormat: PropTypes.func,

  /** Value passed to the file select input */
  htmlId: PropTypes.string,

  /** The maximum filesize allowed for individual files being uploaded in Mb */
  maxFileSize: PropTypes.number,

  /** The maximum number of files that can be selected before triggering a validation error */
  maxFileSelect: PropTypes.number,

  /** The minimum number of files that can be selected before triggering a validation error */
  minFileSelect: PropTypes.number,

  /** The field in the form data in which the file select's value is stored */
  name: PropTypes.string,

  /** Callback to run after the user has dropped or selected file(s) */
  onChange: PropTypes.func,

  /** Callback to set messages in HOC */
  setMessages: PropTypes.func,

  /** Message for user's stating limitation of the file upload */
  uploadRules: PropTypes.string,

  /** Allowed file types */
  allowlist: PropTypes.arrayOf(PropTypes.string)
};

Dropzone.defaultProps = {
  denylist: undefined,
  clearMessageList: false,
  dataQa: undefined,
  disabled: false,
  internalErrorFormat: defaultInternalErrorFormat,
  htmlId: undefined,
  maxFileSelect: 5,
  maxFileSize: 20,
  minFileSelect: 1,
  name: undefined,
  onChange: defaultOnChange,
  setMessages: defaultSetMessages,
  uploadRules: '',
  allowlist: undefined
};

export default Dropzone;
