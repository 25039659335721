var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"MultiFieldElements-region\" data-hook=\"getMultifieldRegion\"></div>\n<div class=\"col-md-12 MultiField-message-wrapper\" data-hook=\"getMultiFieldMessageRegion\"></div>\n<div class=\"col-md-12\">\n    <button class=\"btn btn-tertiary MultiFieldElements-add-button\" data-hook=\"getAddBtn\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"addBtnText") || (depth0 != null ? lookupProperty(depth0,"addBtnText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"addBtnText","hash":{},"data":data,"loc":{"start":{"line":4,"column":89},"end":{"line":4,"column":103}}}) : helper)))
    + "</button>\n</div>\n";
},"useData":true});