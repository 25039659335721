var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"field-container-lg field-container\">\n    <div class=\"radio-inline\">\n        <input id=\"REPORTDATE\" name=\"LegacyReportDateType\" type=\"radio\" value=\"REPORTDATE\">\n        <label for=\"REPORTDATE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.ReportDate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":32},"end":{"line":4,"column":58}}}))
    + "</label>\n    </div>\n    <div class=\"radio-inline\">\n        <input id=\"CREATIONDATETIME\" name=\"LegacyReportDateType\" type=\"radio\" value=\"CREATIONDATETIME\" checked=\"checked\">\n        <label for=\"CREATIONDATETIME\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.LoadDate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":38},"end":{"line":8,"column":62}}}))
    + "</label>\n    </div>\n</div>";
},"useData":true});