import React, {
  useEffect, useCallback, useState, useRef, useMemo
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { createPopper } from '@popperjs/core';
import { withStyles } from '@glu/theming';
import styles from './Popover.styles';

let uniqueId = 0;

const Popover = ({
  children,
  noArrow,
  classes,
  placement,
  content,
  title,
  openOnHover,
  className,
  defaultVisible,
  disableHideOnOutsideClick,
  popperOptions,
  enablePopoverClick,
  openedId,
  onMouseOverFunction,
  open
}) => {
  const popperRef = useRef();
  const childRef = useRef();
  const [visible, setVisible] = useState(open || defaultVisible);
  const externalControl = open !== undefined;

  let closeWithTimeout;
  const id = useMemo(() => `popover-${uniqueId++}`, []); // eslint-disable-line no-plusplus

  const clickHandler = () => {
    setVisible(!visible);
  };

  const events = externalControl
    ? null
    : {
      onClick: () => (!openOnHover ? setVisible(!visible) : clickHandler()),
      onMouseOver: () => {
        clearTimeout(closeWithTimeout);
        if (openOnHover) setVisible(true);
        onMouseOverFunction(id);
      },
      onMouseLeave: () => {
        closeWithTimeout = openOnHover ? setTimeout(() => setVisible(false), 200) : null;
      }
    };

  const closePopover = useCallback(() => {
    setVisible(false);
  });

  useEffect(() => {
    let popper = null;
    if (childRef.current && popperRef.current) {
      popper = createPopper(childRef.current, popperRef.current, {
        placement,
        ...popperOptions,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 10]
            }
          },
          {
            name: 'arrow',
            enabled: !noArrow
          },
          ...(popperOptions.modifiers || [])
        ]
      });
    }

    return () => {
      if (popper) {
        popper.destroy();
      }
    };
  }, [visible, placement, noArrow, popperOptions]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (childRef.current && childRef.current.contains(event.target)) {
        return;
      }
      if (enablePopoverClick && popperRef.current && popperRef.current.contains(event.target)) {
        return;
      }
      setVisible(false);
    };

    if (!disableHideOnOutsideClick && !externalControl) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [enablePopoverClick, disableHideOnOutsideClick, externalControl]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    id !== openedId ? setVisible(false) : null;
  }, [openedId]);

  useEffect(() => {
    setVisible(open);
  }, [open]);

  const renderPopover = () => (
    <div
      ref={popperRef}
      className={`${classes.root} ${visible ? classes.show : classes.hide} ${className}`}
      onMouseEnter={() => clearTimeout(closeWithTimeout)}
      onMouseLeave={() => {
        closeWithTimeout = openOnHover ? setTimeout(() => closePopover(), 200) : null;
      }}
    >
      {title ? (
        <div className={`${classes.title}`}>{typeof title === 'function' ? title() : title}</div>
      ) : null}
      {content && typeof content === 'function' ? content({ closePopover, visible }) : content}
      {/* data-popper-arrow is used by the popper library */}
      {!noArrow && <div data-popper-arrow="" className={classes.arrow} />}
    </div>
  );

  return (
    <>
      {
        children && typeof children === 'function'
          ? (<span ref={childRef} {...events}>{children()}</span>)
          : (React.cloneElement(
            React.Children.only(children), { ...events, ref: childRef }
          ))
      }
      {ReactDOM.createPortal(renderPopover(), document.getElementsByTagName('body')[0])}
    </>
  );
};

Popover.propTypes = {
  /** Any value accepted by popper to tell it where to position to popover */
  placement: PropTypes.oneOf([
    'auto', 'left', 'right', 'top', 'bottom',
    'left-start', 'right-start', 'top-start', 'bottom-start',
    'left-end', 'right-end', 'top-end', 'bottom-end'
  ]),

  /** Custom options to pass directly to popperJs.
   *  Using these could break your code if Popover ever switches to something else */
  popperOptions: PropTypes.objectOf(PropTypes.string),

  /** True if you wish to disable the arrow connecting popover to its reference element */
  noArrow: PropTypes.bool,

  /** True if the popover is open by default and thus should be showing, false if it should not */
  defaultVisible: PropTypes.bool,

  /** If true the the out side clic is disabled in order to hide the popover */
  disableHideOnOutsideClick: PropTypes.bool,

  /** Children to be wrappeed and respond to events in order to show the popover */
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node
  ]),

  /** CSS ClassName to use in addition to built in classes */
  className: PropTypes.string,

  /** Classes for JSS styling */
  classes: PropTypes.objectOf(PropTypes.string).isRequired,

  /** Contents of the popover */
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node
  ]),

  /** Allow clicks inside the popover with closing */
  enablePopoverClick: PropTypes.bool,

  /** Title of the popover */
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node
  ]),

  /** If true the popover will open on hover in addition to opening on click */
  openOnHover: PropTypes.bool,

  /** Unique id to indicate which popover instance need to be closed on MouseOver */
  openedId: PropTypes.string,

  /** Callback function for onMouseOver, used to set currently opened popover id */
  onMouseOverFunction: PropTypes.func,

  /** Manually set zIndex for popover to use * */
  zIndex: PropTypes.number,

  open: PropTypes.bool
};

Popover.defaultProps = {
  enablePopoverClick: false,
  defaultVisible: false,
  noArrow: false,
  disableHideOnOutsideClick: false,
  placement: 'bottom',
  openOnHover: false,
  className: '',
  children: undefined,
  content: undefined,
  title: undefined,
  popperOptions: {},
  openedId: '',
  onMouseOverFunction: () => {},
  zIndex: 1000,
  open: undefined
};

export const PopoverBase = Popover;
export const StyledPopover = withStyles(styles)(Popover);
StyledPopover.displayName = 'Popover';
export default StyledPopover;
