import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@glu/accordion-react';
import HistoryHeader from './HistoryHeader';
import HistoryRows from './HistoryRows';

const historyTableContainerStyle = { overflowY: 'auto' };

const HistoryDetails = ({
    sectionTitle,
    tableData, // { rows, rowHeader }
}) => (
    <Accordion>
        <Accordion.Panel panelId={sectionTitle.toLowerCase().replace(/\s/gi, '_')}>
            <Accordion.Header>
                {sectionTitle}
            </Accordion.Header>

            <Accordion.Content>
                <div className="row">
                    <div className="form-column" style={historyTableContainerStyle}>
                        <table className="table secondary">
                            <thead>
                                <HistoryHeader {...tableData} />
                            </thead>
                            <tbody>
                                <HistoryRows {...tableData} />
                            </tbody>
                        </table>
                    </div>
                </div>
            </Accordion.Content>
        </Accordion.Panel>
    </Accordion>
);

HistoryDetails.propTypes = {
    sectionTitle: PropTypes.string.isRequired,
    tableData: PropTypes.shape(HistoryRows.propTypes).isRequired,
};

export default HistoryDetails;
