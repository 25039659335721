import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import services from 'services';
import http from '@glu/core/src/http';

export default Collection.extend({
    parse(jsonData) {
        const returnArray = [];
        let obj = {};

        util.each(jsonData.inquiryResponse.rows, (rowItem) => {
            const row = rowItem.columns;

            obj = {
                LOCATION_CODE: util.unescape(row[0].fieldValue),
                LOCATION_ID: row[1].fieldValue,
                LOCATION_DESC: util.unescape(row[2].fieldValue),
            };

            returnArray.push(obj);
        });

        return util.sortBy(returnArray, location => location.LOCATION_DESC);
    },

    sync(method, model, options) {
        const url = services.generateUrl('/inquiry/getRowsData');
        const data = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    action: {
                        typeCode: 'LBXLC_TM',
                        productCode: 'GIR',
                        functionCode: 'MAINT',
                        actionMode: 'SELECT',
                    },

                    searchType: '5',
                    inquiryId: 22500,
                },
            },
        };
        http.post(url, data, (result) => {
            options.success(result);
        }, (result) => {
            options.error(result);
        });
    },
});
