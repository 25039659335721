var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.delete.1",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":4,"column":30}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"DESCRIPTION") || (depth0 != null ? lookupProperty(depth0,"DESCRIPTION") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"DESCRIPTION","hash":{},"data":data,"loc":{"start":{"line":4,"column":31},"end":{"line":4,"column":46}}}) : helper)))
    + " "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.delete.2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":47},"end":{"line":4,"column":77}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.delete.confirm",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":6,"column":36}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"DESCRIPTION") || (depth0 != null ? lookupProperty(depth0,"DESCRIPTION") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"DESCRIPTION","hash":{},"data":data,"loc":{"start":{"line":6,"column":37},"end":{"line":6,"column":52}}}) : helper)))
    + " "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"from",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":53},"end":{"line":6,"column":70}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"scenarioName") || (depth0 != null ? lookupProperty(depth0,"scenarioName") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"scenarioName","hash":{},"data":data,"loc":{"start":{"line":6,"column":71},"end":{"line":6,"column":87}}}) : helper)))
    + "?\n</p>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"checkbox\">\n<input type=\"checkbox\" data-hook=\"recurring-checkbox\" id=\"delete-recurring-checkbox\">\n<label for=\"delete-recurring-checkbox\"> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"cashflow.delete.all.recurring",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":40},"end":{"line":13,"column":82}}}))
    + "</label>\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<p class=\"text-left\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"inBase") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":8,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRecurring") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":0},"end":{"line":15,"column":7}}})) != null ? stack1 : "");
},"useData":true});