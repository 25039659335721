import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import services from 'services';
import serverConfigParams from 'system/webseries/models/configurationParameters';

const helpTextLoaderOptions = {
    ACCOUNTFILTER: {
        bankCodeKey: 'DEBIT_BANK_CODE',
        currencyKey: 'CREDIT_CURRENCY',
        accountNumberKey: 'ORIGINATOR_ID',
    },

    COMPIDNAME: {
        // NH-89886 - Need to send the Offset bank code and not the originator bank code.
        bankCodeKey: 'OFFSETBANKCODE',

        currencyKey: 'ORIGCURRENCYCODE',
        accountNumberKey: 'OFFSETACCOUNTNUM',
    },
};

function loadHelpTextData(opts) {
    const comboDataIndexToResponseIndexMap = {};
    const functionCd = this.parentModel.jsonData.typeInfo.functionCode;
    const options = opts || helpTextLoaderOptions[this.fieldName];
    let dispAcctBalance = (
        (serverConfigParams.get('DisplayAccountBalanceOnPaymentsScreens') === '1'
        || serverConfigParams.get('DisplayAccountBalanceOnPaymentsScreens') === 'true')
    && functionCd !== 'TMPL');
    let hideAcctBalanceStatuses;
    let itemSelected = false;
    let requestComboData = [];
    let status;

    if (this.options.parentInitializeOptions.context.productCode === 'USACH'
        || this.parentModel.jsonData.typeInfo.productCode === 'USACH') {
        dispAcctBalance = (
            (serverConfigParams.get('DisplayAccountBalanceOnACHScreens') === '1'
        || serverConfigParams.get('DisplayAccountBalanceOnACHScreens') === 'true')
    && functionCd !== 'TMPL');
    }

    // if this field is an account filter, assign the correct currencyKey
    if (this.fieldName === 'ACCOUNTFILTER') {
        const typeCode = this.model.get('TYPE') || this.parentModel.jsonData.typeInfo.typeCode;
        options.currencyKey = (typeCode === 'INTL') ? 'DEBIT_CURRENCY' : 'CREDIT_CURRENCY';
    }

    /*
     * Account for statuses that we don't want to show balances for,
     * but if this is a 'create' workflow then ALWAYS show balances
     */
    if (dispAcctBalance && this.parentView.context.actionMode !== 'INSERT') {
        hideAcctBalanceStatuses = serverConfigParams.get('HideAccountBalanceStatuses');
        status = this.model.get('STATUS');
        if (!util.isEmpty(status)
            && !util.isEmpty(hideAcctBalanceStatuses)
            && hideAcctBalanceStatuses.indexOf(status) !== -1) {
            dispAcctBalance = false;
        }
    }

    /*
     * if we are not configured to show account balances then just return empty entries for
     * each combo entry so that nothing is shown.
     */
    if (!dispAcctBalance) {
        const emptyHelpText = this.comboData.map(() => undefined);

        return Promise.resolve(emptyHelpText);
    }

    if (this.currentSelection !== undefined) {
        requestComboData = [
            this.currentSelection,
        ];
        itemSelected = true;
    } else {
        requestComboData = this.comboData;
    }

    const helpTextRequests = requestComboData.reduce((memo, item, index) => {
        if (!item.mapDataList) {
            return memo;
        }

        const requestEntry = item.mapDataList.reduce(
            (mapDataMemoParam, mapDataItem) => {
                const mapDataMemo = mapDataMemoParam;
                if (mapDataItem.toField === options.bankCodeKey) {
                    mapDataMemo.bankCode = mapDataItem.value;
                } else if (mapDataItem.toField === options.currencyKey) {
                    mapDataMemo.currency = mapDataItem.value;
                } else if (mapDataItem.toField === options.accountNumberKey) {
                    mapDataMemo.accountNumber = mapDataItem.value;
                }
                return mapDataMemo;
            },
            {
                productCode: this.parentModel.jsonData.typeInfo.productCode,
                functionCode: this.parentModel.jsonData.typeInfo.functionCode,
                typeCode: this.parentModel.jsonData.typeInfo.typeCode,
            },
        );

        if (requestEntry.accountNumber) {
            memo.push(requestEntry);
            comboDataIndexToResponseIndexMap[index] = memo.length - 1;
        }

        return memo;
    }, []);

    const serviceUrl = services.currencyBalances;
    const postdata = {
        requests: helpTextRequests,
    };

    return new Promise((resolve, reject) => {
        http.post(serviceUrl, postdata, (data) => {
            if (data && data.responses) {
                let comboDataModified = false;
                let responseIndex = 0;
                let parsedItem;

                if (itemSelected) {
                    [parsedItem] = data.responses;
                    if (parsedItem && parsedItem.status === 'success') {
                        responseIndex = this.currentSelectionIndex;
                        parsedItem = util.pick(parsedItem, 'balance');

                        parsedItem.currency = helpTextRequests[0].currency;
                        parsedItem.hasBalance = true;

                        if (!this.isReadOnly() && !this.currentSelection.hasBalance) {
                            this.currentSelection.label = this.currentSelection.label.replace(locale.get('payment.update.pending'), '');
                            this.currentSelection.label += (`: ${parsedItem.balance} ${parsedItem.currency} ${locale.get('common.available')}`);
                            this.comboData[responseIndex].label = this.currentSelection.label;

                            this.currentSelection.hasBalance = true;
                            this.comboData[responseIndex].hasBalance = (
                                this.currentSelection.hasBalance);

                            comboDataModified = true;
                        }
                    } else {
                        parsedItem = {
                            hasBalance: false,
                        };
                    }

                    // Creates new helperTextData for modify screen
                    if (this.helperTextData === undefined) {
                        this.helperTextData = [];
                    }
                    this.helperTextData[responseIndex] = parsedItem;

                    resolve({
                        helperTextData: this.helperTextData,
                        comboDataModified,
                    });
                } else {
                    const mappedResponse = this.comboData.map((itemParam, comboDataIndex) => {
                        const item = itemParam;
                        responseIndex = comboDataIndexToResponseIndexMap[comboDataIndex];
                        parsedItem = (responseIndex || responseIndex === 0)
                            ? data.responses[responseIndex] : null;

                        if (parsedItem && parsedItem.status === 'success') {
                            parsedItem = util.pick(parsedItem, 'balance');

                            parsedItem.currency = helpTextRequests[responseIndex].currency;
                            parsedItem.hasBalance = true;

                            if (!this.isReadOnly() && !item.hasBalance) {
                                item.label = item.label.replace(locale.get('payment.update.pending'), '');
                                item.label += (`: ${parsedItem.balance} ${parsedItem.currency} ${locale.get('common.available')}`);

                                item.hasBalance = true;
                                comboDataModified = true;
                            }
                        } else {
                            parsedItem = {
                                hasBalance: false,
                            };
                        }

                        return parsedItem;
                    });

                    resolve({
                        helperTextData: mappedResponse,
                        comboDataModified,
                    });
                }
            } else {
                resolve();
            }
        }, () => {
            reject(locale.get('common.combo.data.error', this.fieldName));
        });
    });
}
export default loadHelpTextData;
