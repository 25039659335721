import Collection from '@glu/core/src/collection';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import constants from 'common/dynamicPages/api/constants';

const memoData = {};

export default Collection.extend({
    initialize(model, options) {
        this.service = options.serviceUrl;
        this.inquiryId = options.inquiryId;
        this.context = options.context;
        this.rowsPerPage = null;
        this.queryTerm = null;
        this.fieldName = options.fieldName;
        this.startRow = 1;
        this.customFilters = options.customFilters;
    },
    setStartRow(start) {
        this.startRow = start;
    },
    setPageSize(rowsPerPage) {
        this.rowsPerPage = (rowsPerPage > constants.MAX_SERVER_ROWSPERPAGE)
            ? constants.MAX_SERVER_ROWSPERPAGE : rowsPerPage;
    },
    setSearch(term) {
        this.queryTerm = term;
    },
    sync(method, model, options) {
        if (method === 'read') {
            const queryData = {
                inquiryRequest: {
                    startRow: this.startRow,
                    searchCriteria: {
                        searchType: '5',
                        inquiryId: this.inquiryId,
                        customFilters: this.customFilters,
                    },
                },
                requestHeader: {
                    requestId: '100',
                },
            };
            if (this.context && this.context.productCode) {
                queryData.inquiryRequest.searchCriteria.callingAction = {
                    productCode: this.context.productCode,
                    functionCode: this.context.functionCode,
                    typeCode: this.context.typeCode,
                    actionMode: this.context.actionMode || 'SELECT',
                };
            }
            if (this.rowsPerPage) {
                queryData.inquiryRequest.rowsPerPage = this.rowsPerPage;
            }
            if (this.queryTerm && this.fieldName) {
                queryData.inquiryRequest.searchCriteria.searchFields = [{
                    fieldName: this.fieldName,
                    operator: 'CONTAINS',
                    fieldValue: [this.queryTerm],
                    dataType: 'TEXT',
                }];
            }
            const memoKey = JSON.stringify(queryData);
            // Avoid duplicate requests
            if (memoData[memoKey]) {
                return Promise.resolve(memoData[memoKey]).then((response) => {
                    // Do we need to call this.parse?
                    options.success(response);
                    return response;
                });
            }
            return http.post(this.service, queryData, (response) => {
                memoData[memoKey] = response;
                options.success(response);
                return response;
            }, () => {});
        }
        return undefined;
    },
    parse(response) {
        const { rows } = response.inquiryResponse;
        const selections = [];
        let fieldValue0;
        let labeltext;
        rows.forEach((row) => {
            fieldValue0 = util.unescape(row.columns[0].fieldValue);
            labeltext = (row.columns.length > 1)
                ? util.unescape(row.columns[1].fieldValue) : fieldValue0;
            selections.push({
                value: fieldValue0,
                label: labeltext,
                // select2 query results need id & text
                id: fieldValue0,
                text: labeltext,
            });
        });
        return selections;
    },
});
