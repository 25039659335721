import util from './util';

// Based upon https://github.com/mhs/world-currencies and http://fx.sauder.ubc.ca/currency_table.html
const currencies = [{
  isoCode: 'AED',
  symbol: '\u062f.\u0625;',
  name: 'UAE dirham'
}, {
  isoCode: 'AFN',
  symbol: 'Afs',
  name: 'Afghan afghani'
}, {
  isoCode: 'ALL',
  symbol: 'L',
  name: 'Albanian lek'
}, {
  isoCode: 'AMD',
  symbol: 'AMD',
  name: 'Armenian dram'
}, {
  isoCode: 'ANG',
  symbol: 'NA\u0192',
  name: 'Netherlands Antillean gulden'
}, {
  isoCode: 'AOA',
  symbol: 'Kz',
  name: 'Angolan kwanza'
}, {
  isoCode: 'ARS',
  symbol: '$',
  name: 'Argentine peso'
}, {
  isoCode: 'AUD',
  symbol: '$',
  name: 'Australian dollar'
}, {
  isoCode: 'AWG',
  symbol: '\u0192',
  name: 'Aruban florin'
}, {
  isoCode: 'AZN',
  symbol: 'AZN',
  name: 'Azerbaijani manat'
}, {
  isoCode: 'BAM',
  symbol: 'KM',
  name: 'Bosnia and Herzegovina konvertibilna marka'
}, {
  isoCode: 'BBD',
  symbol: 'Bds$',
  name: 'Barbadian dollar'
}, {
  isoCode: 'BDT',
  symbol: '\u09f3',
  name: 'Bangladeshi taka'
}, {
  isoCode: 'BGN',
  symbol: 'BGN',
  name: 'Bulgarian lev'
}, {
  isoCode: 'BHD',
  symbol: '.\u062f.\u0628',
  name: 'Bahraini dinar'
}, {
  isoCode: 'BIF',
  symbol: 'FBu',
  name: 'Burundi franc'
}, {
  isoCode: 'BMD',
  symbol: 'BD$',
  name: 'Bermudian dollar'
}, {
  isoCode: 'BND',
  symbol: 'B$',
  name: 'Brunei dollar'
}, {
  isoCode: 'BOB',
  symbol: 'Bs.',
  name: 'Bolivian boliviano'
}, {
  isoCode: 'BOV',
  symbol: 'Bs.',
  name: 'Bolivian boliviano'
}, {
  isoCode: 'BRL',
  symbol: 'R$',
  name: 'Brazilian real'
}, {
  isoCode: 'BSD',
  symbol: 'B$',
  name: 'Bahamian dollar'
}, {
  isoCode: 'BTN',
  symbol: 'Nu.',
  name: 'Bhutanese ngultrum'
}, {
  isoCode: 'BWP',
  symbol: 'P',
  name: 'Botswana pula'
}, {
  isoCode: 'BYR',
  symbol: 'Br',
  name: 'Belarusian ruble'
}, {
  isoCode: 'BYN',
  symbol: 'Br',
  name: 'Belarusian ruble'
}, {
  isoCode: 'BZD',
  symbol: 'BZ$',
  name: 'Belize dollar'
}, {
  isoCode: 'CAD',
  symbol: '$',
  name: 'Canadian dollar'
}, {
  isoCode: 'CDF',
  symbol: 'F',
  name: 'Congolese franc'
}, {
  isoCode: 'CHE',
  symbol: 'CHE',
  name: 'Swiss euro'
}, {
  isoCode: 'CHF',
  symbol: 'CHF',
  name: 'Swiss franc'
}, {
  isoCode: 'CHW',
  symbol: 'CHW',
  name: 'Swiss franc'
}, {
  isoCode: 'CLF',
  symbol: '$',
  name: 'Chilean peso'
}, {
  isoCode: 'CLP',
  symbol: '$',
  name: 'Chilean peso'
}, {
  isoCode: 'CNY',
  symbol: '\u00a5',
  name: 'Chinese/Yuan renminbi'
}, {
  isoCode: 'COP',
  symbol: 'Col$',
  name: 'Colombian peso'
}, {
  isoCode: 'COU',
  symbol: 'Col$',
  name: 'Colombian peso'
}, {
  isoCode: 'CRC',
  symbol: '\u20a1',
  name: 'Costa Rican colon'
}, {
  isoCode: 'CUC',
  symbol: '$',
  name: 'Cuban peso'
}, {
  isoCode: 'CUP',
  symbol: '$',
  name: 'Cuban peso'
}, {
  isoCode: 'CVE',
  symbol: 'Esc',
  name: 'Cape Verdean escudo'
}, {
  isoCode: 'CZK',
  symbol: 'K\u010d',
  name: 'Czech koruna'
}, {
  isoCode: 'DJF',
  symbol: 'Fdj',
  name: 'Djiboutian franc'
}, {
  isoCode: 'DKK',
  symbol: 'Kr',
  name: 'Danish krone'
}, {
  isoCode: 'DOP',
  symbol: 'RD$',
  name: 'Dominican peso'
}, {
  isoCode: 'DZD',
  symbol: '\u062f.\u062c',
  name: 'Algerian dinar'
}, {
  isoCode: 'EEK',
  symbol: 'KR',
  name: 'Estonian kroon'
}, {
  isoCode: 'EGP',
  symbol: '\u00a3',
  name: 'Egyptian pound'
}, {
  isoCode: 'ERN',
  symbol: 'Nfa',
  name: 'Eritrean nakfa'
}, {
  isoCode: 'ETB',
  symbol: 'Br',
  name: 'Ethiopian birr'
}, {
  isoCode: 'EUR',
  symbol: '\u20ac',
  name: 'European Euro'
}, {
  isoCode: 'FJD',
  symbol: 'FJ$',
  name: 'Fijian dollar'
}, {
  isoCode: 'FKP',
  symbol: '\u00a3',
  name: 'Falkland Islands pound'
}, {
  isoCode: 'GBP',
  symbol: '\u00a3',
  name: 'British pound'
}, {
  isoCode: 'GEL',
  symbol: 'GEL',
  name: 'Georgian lari'
}, {
  isoCode: 'GHS',
  symbol: 'GH\u20b5',
  name: 'Ghanaian cedi'
}, {
  isoCode: 'GIP',
  symbol: '\u00a3',
  name: 'Gibraltar pound'
}, {
  isoCode: 'GMD',
  symbol: 'D',
  name: 'Gambian dalasi'
}, {
  isoCode: 'GNF',
  symbol: 'FG',
  name: 'Guinean franc'
}, {
  isoCode: 'GQE',
  symbol: 'CFA',
  name: 'Central African CFA franc'
}, {
  isoCode: 'GTQ',
  symbol: 'Q',
  name: 'Guatemalan quetzal'
}, {
  isoCode: 'GYD',
  symbol: 'GY$',
  name: 'Guyanese dollar'
}, {
  isoCode: 'HKD',
  symbol: 'HK$',
  name: 'Hong Kong dollar'
}, {
  isoCode: 'HNL',
  symbol: 'L',
  name: 'Honduran lempira'
}, {
  isoCode: 'HRK',
  symbol: 'kn',
  name: 'Croatian kuna'
}, {
  isoCode: 'HTG',
  symbol: 'G',
  name: 'Haitian gourde'
}, {
  isoCode: 'HUF',
  symbol: 'Ft',
  name: 'Hungarian forint'
}, {
  isoCode: 'IDR',
  symbol: 'Rp',
  name: 'Indonesian rupiah'
}, {
  isoCode: 'ILS',
  symbol: '\u20aa',
  name: 'Israeli new sheqel'
}, {
  isoCode: 'INR',
  symbol: '\u20B9',
  name: 'Indian rupee'
}, {
  isoCode: 'IQD',
  symbol: '\u062f.\u0639',
  name: 'Iraqi dinar'
}, {
  isoCode: 'IRR',
  symbol: 'IRR',
  name: 'Iranian rial'
}, {
  isoCode: 'ISK',
  symbol: 'kr',
  name: 'Icelandic kr\u00f3na'
}, {
  isoCode: 'JMD',
  symbol: 'J$',
  name: 'Jamaican dollar'
}, {
  isoCode: 'JOD',
  symbol: 'JOD',
  name: 'Jordanian dinar'
}, {
  isoCode: 'JPY',
  symbol: '\u00a5',
  name: 'Japanese yen'
}, {
  isoCode: 'KES',
  symbol: 'KSh',
  name: 'Kenyan shilling'
}, {
  isoCode: 'KGS',
  symbol: '\u0441\u043e\u043c',
  name: 'Kyrgyzstani som'
}, {
  isoCode: 'KHR',
  symbol: '\u17db',
  name: 'Cambodian riel'
}, {
  isoCode: 'KMF',
  symbol: 'KMF',
  name: 'Comorian franc'
}, {
  isoCode: 'KPW',
  symbol: 'W',
  name: 'North Korean won'
}, {
  isoCode: 'KRW',
  symbol: 'W',
  name: 'South Korean won'
}, {
  isoCode: 'KWD',
  symbol: 'KWD',
  name: 'Kuwaiti dinar'
}, {
  isoCode: 'KYD',
  symbol: 'KY$',
  name: 'Cayman Islands dollar'
}, {
  isoCode: 'KZT',
  symbol: 'T',
  name: 'Kazakhstani tenge'
}, {
  isoCode: 'LAK',
  symbol: 'KN',
  name: 'Lao kip'
}, {
  isoCode: 'LBP',
  symbol: '\u00a3',
  name: 'Lebanese lira'
}, {
  isoCode: 'LKR',
  symbol: 'Rs',
  name: 'Sri Lankan rupee'
}, {
  isoCode: 'LRD',
  symbol: 'L$',
  name: 'Liberian dollar'
}, {
  isoCode: 'LSL',
  symbol: 'M',
  name: 'Lesotho loti'
}, {
  isoCode: 'LTL',
  symbol: 'Lt',
  name: 'Lithuanian litas'
}, {
  isoCode: 'LVL',
  symbol: 'Ls',
  name: 'Latvian lats'
}, {
  isoCode: 'LYD',
  symbol: 'LD',
  name: 'Libyan dinar'
}, {
  isoCode: 'MAD',
  symbol: 'MAD',
  name: 'Moroccan dirham'
}, {
  isoCode: 'MDL',
  symbol: 'MDL',
  name: 'Moldovan leu'
}, {
  isoCode: 'MGA',
  symbol: 'FMG',
  name: 'Malagasy ariary'
}, {
  isoCode: 'MKD',
  symbol: 'MKD',
  name: 'Macedonian denar'
}, {
  isoCode: 'MMK',
  symbol: 'K',
  name: 'Myanma kyat'
}, {
  isoCode: 'MNT',
  symbol: '\u20ae',
  name: 'Mongolian tugrik'
}, {
  isoCode: 'MOP',
  symbol: 'P',
  name: 'Macanese pataca'
}, {
  isoCode: 'MRO',
  symbol: 'UM',
  name: 'Mauritanian ouguiya'
}, {
  isoCode: 'MUR',
  symbol: 'Rs',
  name: 'Mauritian rupee'
}, {
  isoCode: 'MVR',
  symbol: 'Rf',
  name: 'Maldivian rufiyaa'
}, {
  isoCode: 'MWK',
  symbol: 'MK',
  name: 'Malawian kwacha'
}, {
  isoCode: 'MXN',
  symbol: '$',
  name: 'Mexican peso'
}, {
  isoCode: 'MXV',
  symbol: '$',
  name: 'Mexican peso'
}, {
  isoCode: 'MYR',
  symbol: 'RM',
  name: 'Malaysian ringgit'
}, {
  isoCode: 'MZM',
  symbol: 'MTn',
  name: 'Mozambican metical'
}, {
  isoCode: 'MZN',
  symbol: 'MTn',
  name: 'Mozambican metical'
}, {
  isoCode: 'NAD',
  symbol: 'N$',
  name: 'Namibian dollar'
}, {
  isoCode: 'NGN',
  symbol: '\u20a6',
  name: 'Nigerian naira'
}, {
  isoCode: 'NIO',
  symbol: 'C$',
  name: 'Nicaraguan c\u00f3rdoba'
}, {
  isoCode: 'NOK',
  symbol: 'kr',
  name: 'Norwegian krone'
}, {
  isoCode: 'NPR',
  symbol: 'NRs',
  name: 'Nepalese rupee'
}, {
  isoCode: 'NZD',
  symbol: 'NZ$',
  name: 'New Zealand dollar'
}, {
  isoCode: 'OMR',
  symbol: 'OMR',
  name: 'Omani rial'
}, {
  isoCode: 'PAB',
  symbol: 'B./',
  name: 'Panamanian balboa'
}, {
  isoCode: 'PEN',
  symbol: 'S/.',
  name: 'Peruvian nuevo sol'
}, {
  isoCode: 'PGK',
  symbol: 'K',
  name: 'Papua New Guinean kina'
}, {
  isoCode: 'PHP',
  symbol: '\u20b1',
  name: 'Philippine peso'
}, {
  isoCode: 'PKR',
  symbol: 'Rs.',
  name: 'Pakistani rupee'
}, {
  isoCode: 'PLN',
  symbol: 'z\u0142',
  name: 'Polish zloty'
}, {
  isoCode: 'PYG',
  symbol: '\u20b2',
  name: 'Paraguayan guarani'
}, {
  isoCode: 'QAR',
  symbol: 'QR',
  name: 'Qatari riyal'
}, {
  isoCode: 'RON',
  symbol: 'L',
  name: 'Romanian leu'
}, {
  isoCode: 'RSD',
  symbol: 'din.',
  name: 'Serbian dinar'
}, {
  isoCode: 'RUB',
  symbol: 'R',
  name: 'Russian ruble'
}, {
  isoCode: 'RWF',
  symbol: 'RWF',
  name: 'Rwanda Franc'
}, {
  isoCode: 'SAR',
  symbol: 'SR',
  name: 'Saudi riyal'
}, {
  isoCode: 'SBD',
  symbol: 'SI$',
  name: 'Solomon Islands dollar'
}, {
  isoCode: 'SCR',
  symbol: 'SR',
  name: 'Seychellois rupee'
}, {
  isoCode: 'SDG',
  symbol: 'SDG',
  name: 'Sudanese pound'
}, {
  isoCode: 'SEK',
  symbol: 'kr',
  name: 'Swedish krona'
}, {
  isoCode: 'SGD',
  symbol: 'S$',
  name: 'Singapore dollar'
}, {
  isoCode: 'SHP',
  symbol: '\u00a3',
  name: 'Saint Helena pound'
}, {
  isoCode: 'SLL',
  symbol: 'Le',
  name: 'Sierra Leonean leone'
}, {
  isoCode: 'SOS',
  symbol: 'Sh.',
  name: 'Somali shilling'
}, {
  isoCode: 'SRD',
  symbol: '$',
  name: 'Surinamese dollar'
}, {
  isoCode: 'SSP',
  symbol: 'SSP',
  name: 'South Sudanese Pound'
}, {
  isoCode: 'STD',
  symbol: 'STD',
  name: 'Dobra'
}, {
  isoCode: 'SVC',
  symbol: '$',
  name: 'El Salvador Colon US Dollar'
}, {
  isoCode: 'SYP',
  symbol: 'LS',
  name: 'Syrian pound'
}, {
  isoCode: 'SZL',
  symbol: 'E',
  name: 'Swazi lilangeni'
}, {
  isoCode: 'THB',
  symbol: '\u0e3f',
  name: 'Thai baht'
}, {
  isoCode: 'TJS',
  symbol: 'TJS',
  name: 'Tajikistani somoni'
}, {
  isoCode: 'TMT',
  symbol: 'm',
  name: 'Turkmen manat'
}, {
  isoCode: 'TND',
  symbol: 'DT',
  name: 'Tunisian dinar'
}, {
  isoCode: 'TOP',
  symbol: 'TOP',
  name: 'Pa\'anga'
}, {
  isoCode: 'TRY',
  symbol: 'TRY',
  name: 'Turkish new lira'
}, {
  isoCode: 'TTD',
  symbol: 'TT$',
  name: 'Trinidad and Tobago dollar'
}, {
  isoCode: 'TWD',
  symbol: 'NT$',
  name: 'New Taiwan dollar'
}, {
  isoCode: 'TZS',
  symbol: 'TZS',
  name: 'Tanzanian shilling'
}, {
  isoCode: 'UAH',
  symbol: 'UAH',
  name: 'Ukrainian hryvnia'
}, {
  isoCode: 'UGX',
  symbol: 'USh',
  name: 'Ugandan shilling'
}, {
  isoCode: 'USD',
  symbol: '$',
  name: 'United States dollar'
}, {
  isoCode: 'USN',
  symbol: '$',
  name: 'United States dollar (Next day)'
}, {
  isoCode: 'UYI',
  symbol: '$U',
  name: 'Uruguayan peso'
}, {
  isoCode: 'UYU',
  symbol: '$U',
  name: 'Uruguayan peso'
}, {
  isoCode: 'UZS',
  symbol: 'UZS',
  name: 'Uzbekistani som'
}, {
  isoCode: 'VEB',
  symbol: 'Bs',
  name: 'Venezuelan bolivar'
}, {
  isoCode: 'VEF',
  symbol: 'Bs',
  name: 'Bolivar fuerte'
}, {
  isoCode: 'VND',
  symbol: '\u20ab',
  name: 'Vietnamese dong'
}, {
  isoCode: 'VUV',
  symbol: 'VT',
  name: 'Vanuatu vatu'
}, {
  isoCode: 'WST',
  symbol: 'WS$',
  name: 'Samoan tala'
}, {
  isoCode: 'XAF',
  symbol: 'CFA',
  name: 'Central African CFA franc'
}, {
  isoCode: 'XAG',
  symbol: 'XAG',
  name: 'Silver'
}, {
  isoCode: 'XAU',
  symbol: 'XAU',
  name: 'Gold'
}, {
  isoCode: 'XBA',
  symbol: 'XBA',
  name: 'Bond Markets Units European Composite Unit (EURCO)'
}, {
  isoCode: 'XBB',
  symbol: 'XBB',
  name: 'European Monetary Unit (E.M.U.-6)'
}, {
  isoCode: 'XBC',
  symbol: 'XBC',
  name: 'European Unit of Account 9(E.U.A.-9)'
}, {
  isoCode: 'XBD',
  symbol: 'XBD',
  name: 'European Unit of Account 17(E.U.A.-17)'
}, {
  isoCode: 'XCD',
  symbol: 'EC$',
  name: 'East Caribbean dollar'
}, {
  isoCode: 'XDR',
  symbol: 'SDR',
  name: 'Special Drawing Rights'
}, {
  isoCode: 'XOF',
  symbol: 'CFA',
  name: 'West African CFA franc'
}, {
  isoCode: 'XPD',
  symbol: 'XPD',
  name: 'Palladium'
}, {
  isoCode: 'XPF',
  symbol: 'F',
  name: 'CFP franc'
}, {
  isoCode: 'XPT',
  symbol: 'XPT',
  name: 'Platinum'
}, {
  isoCode: 'XSU',
  symbol: 'F',
  name: 'CFP franc'
}, {
  isoCode: 'XTS',
  symbol: 'XTS',
  name: 'Codes specifically reserved for testing purposes'
}, {
  isoCode: 'XUA',
  symbol: 'XUA',
  name: 'ADB Unit of Account'
}, {
  isoCode: 'XXX',
  symbol: 'XXX',
  name: 'The codes assigned for transactions where no currency is involved'
}, {
  isoCode: 'YER',
  symbol: 'YER',
  name: 'Yemeni rial'
}, {
  isoCode: 'ZAR',
  symbol: 'R',
  name: 'South African rand'
}, {
  isoCode: 'ZMK',
  symbol: 'ZK',
  name: 'Zambian kwacha'
}, {
  isoCode: 'ZMW',
  symbol: 'ZK',
  name: 'Zambian kwacha'
}, {
  isoCode: 'ZWL',
  symbol: 'Z$',
  name: 'Zimbabwean dollar'
}, {
  isoCode: 'ZWR',
  symbol: 'Z$',
  name: 'Zimbabwean dollar'
}];

export default {
  get(isoCode) {
    return util.findWhere(currencies, {
      isoCode
    });
  },

  symbol(isoCode) {
    return this.get(isoCode) ? this.get(isoCode).symbol : isoCode;
  },

  list(commonCurrencies) {
    commonCurrencies = commonCurrencies || [];

    const common = [];

    // Build the list of 'common' currencies, in order
    util.each(commonCurrencies, (isoCode) => {
      const currency = util.findWhere(currencies, {
        isoCode
      });
      common.push(currency);
    });

    // Get all remaining currencies (i.e. excluding any 'common' currencies above)
    const rest = util.filter(currencies, (currency) => {
      if (util.contains(commonCurrencies, currency.isoCode)) {
        return undefined;
      }

      return currency;
    });

    return util.union(common, rest);
  },

  listOnly(includeCurrencies, commonCurrencies) {
    const list = this.list(commonCurrencies);

    // Only include currencies that are within the 'include' array
    return util.filter(list, (currency) => util.contains(includeCurrencies, currency.isoCode));
  },

  listWithout(excludeCurrencies, commonCurrencies) {
    const list = this.list(commonCurrencies);

    // Exclude currencies that are within the 'exclude' array
    return util.reject(list, (currency) => util.contains(excludeCurrencies, currency.isoCode));
  }
};

