import Layout from '@glu/core/src/layout';
import TenantKey from 'app/setup/models/tenantKey';
import quickbooksTenantTmpl from './quickbooksTenant.hbs';

export default Layout.extend({
    initialize() {},
    template: quickbooksTenantTmpl,
    binding: true,
    model: new TenantKey(),

    save() {
        this.model.save();
    },
});
