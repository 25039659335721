var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ACCOUNT_FILTER") || (depth0 != null ? lookupProperty(depth0,"ACCOUNT_FILTER") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ACCOUNT_FILTER","hash":{},"data":data,"loc":{"start":{"line":35,"column":43},"end":{"line":35,"column":61}}}) : helper)))
    + "\" data-accountnumber=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ACCOUNT_NUM") || (depth0 != null ? lookupProperty(depth0,"ACCOUNT_NUM") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ACCOUNT_NUM","hash":{},"data":data,"loc":{"start":{"line":35,"column":83},"end":{"line":35,"column":98}}}) : helper)))
    + "\" data-subaccountnumber=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"SUBACCOUNT_NUM") || (depth0 != null ? lookupProperty(depth0,"SUBACCOUNT_NUM") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SUBACCOUNT_NUM","hash":{},"data":data,"loc":{"start":{"line":35,"column":123},"end":{"line":35,"column":141}}}) : helper)))
    + "\" data-accountfilter=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ACCOUNT_FILTER") || (depth0 != null ? lookupProperty(depth0,"ACCOUNT_FILTER") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ACCOUNT_FILTER","hash":{},"data":data,"loc":{"start":{"line":35,"column":163},"end":{"line":35,"column":181}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"displayValue") || (depth0 != null ? lookupProperty(depth0,"displayValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"displayValue","hash":{},"data":data,"loc":{"start":{"line":35,"column":183},"end":{"line":35,"column":199}}}) : helper)))
    + "</option>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"text-group field-container field-container-lg\">\n                        <label for=\"DEBIT_ACCOUNT_NUMBER\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"originLabel") || (depth0 != null ? lookupProperty(depth0,"originLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"originLabel","hash":{},"data":data,"loc":{"start":{"line":44,"column":58},"end":{"line":44,"column":73}}}) : helper)))
    + ": </label>\n                        <p class=\"form-control-static\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"DEBIT_ACCOUNT_NUMBER") || (depth0 != null ? lookupProperty(depth0,"DEBIT_ACCOUNT_NUMBER") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DEBIT_ACCOUNT_NUMBER","hash":{},"data":data,"loc":{"start":{"line":45,"column":55},"end":{"line":45,"column":79}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"getDebitSubaccount") || (depth0 != null ? lookupProperty(depth0,"getDebitSubaccount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getDebitSubaccount","hash":{},"data":data,"loc":{"start":{"line":45,"column":80},"end":{"line":45,"column":102}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"DEBIT_ACCOUNT_TITLE") || (depth0 != null ? lookupProperty(depth0,"DEBIT_ACCOUNT_TITLE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DEBIT_ACCOUNT_TITLE","hash":{},"data":data,"loc":{"start":{"line":45,"column":105},"end":{"line":45,"column":128}}}) : helper)))
    + "</p>\n                    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"text-group field-container field-container-lg\">\n                        <label for=\"BENE_ACCOUNT\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"destinationLabel") || (depth0 != null ? lookupProperty(depth0,"destinationLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"destinationLabel","hash":{},"data":data,"loc":{"start":{"line":68,"column":50},"end":{"line":68,"column":70}}}) : helper)))
    + ": </label>\n                        <p class=\"form-control-static\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"BENE_ACCOUNT") || (depth0 != null ? lookupProperty(depth0,"BENE_ACCOUNT") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BENE_ACCOUNT","hash":{},"data":data,"loc":{"start":{"line":69,"column":55},"end":{"line":69,"column":71}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"getBeneSubaccount") || (depth0 != null ? lookupProperty(depth0,"getBeneSubaccount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getBeneSubaccount","hash":{},"data":data,"loc":{"start":{"line":69,"column":72},"end":{"line":69,"column":93}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"BENE_NAME") || (depth0 != null ? lookupProperty(depth0,"BENE_NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BENE_NAME","hash":{},"data":data,"loc":{"start":{"line":69,"column":96},"end":{"line":69,"column":109}}}) : helper)))
    + "</p>\n                    </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"row\" data-hook=\"getInvoices\" data-region=\"pmtInvoicesRegion\"></div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row\" data-hook=\"getInvoiceAmounts\" data-region=\"pmtInvoiceAmountsRegion\"></div>\n            <div class=\"payment-options hide\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"paymentOptions") : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":107,"column":16},"end":{"line":150,"column":25}}})) != null ? stack1 : "")
    + "                <div class=\"row\">\n                    <div class=\"form-column col-md-6\">\n                        <div class=\"field-container field-container-lg\">\n                            <div class=\"addAnotherPaymentOptionLinkRegion\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"row\">\n                        <div class=\"form-column col-md-6\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"onlyOption") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":110,"column":28},"end":{"line":147,"column":35}}})) != null ? stack1 : "")
    + "                         </div>\n                    </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"field-container field-container-lg\">\n                                    <label for=\"credit-amount\" class=\"required\">"
    + alias1(container.lambda(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"getAmountText")), depth0))
    + " <span data-hook=\"getAmountDue\"></span></label>\n                                    <div class=\"form-group amount\">\n                                        <div class=\"input-group\">\n                                            <input id=\"credit-amount\" type=\"text\" class=\"form-control credit-amount\" data-type=\"amount\" tabindex=\"-1\" maxLength=\"16\" data-bind=\"model\" data-hook=\"getAmount\" name=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"key") || (depth0 != null ? lookupProperty(depth0,"key") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":115,"column":211},"end":{"line":115,"column":218}}}) : helper)))
    + "\">\n                                            <div class=\"input-group-addon\">\n                                                <input id=\"credit-currency\" readonly class=\"currency-field\" data-bind=\"model\" data-hook=\"getCurrency\" tabindex=\"-1\" maxLength=\"16\" name=\"CREDIT_CURRENCY\">\n                                            </div>\n                                        </div>\n                                    </div>\n                                    <span class=\"help-block\" data-bind=\"model\" data-validate=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"key") || (depth0 != null ? lookupProperty(depth0,"key") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":121,"column":94},"end":{"line":121,"column":101}}}) : helper)))
    + "\"></span>\n                                </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"multifield-container-"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":124,"column":65},"end":{"line":124,"column":73}}}) : helper)))
    + " payment-option "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"firstOption") : depth0),{"name":"unless","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":124,"column":89},"end":{"line":124,"column":127}}})) != null ? stack1 : "")
    + "\">\n                                    <div class=\"form-column column-md-6 payment-amounts\">\n                                        <div class=\"form-group amount\">\n                                            <label for=\"amount_"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":127,"column":63},"end":{"line":127,"column":71}}}) : helper)))
    + "\" class=\"required\">"
    + alias4(container.lambda(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"getAmountText")), depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"firstOption") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":127,"column":114},"end":{"line":127,"column":178}}})) != null ? stack1 : "")
    + "</label>\n                                            <div class=\"input-group\">\n                                                <input id=\"amount_"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":129,"column":66},"end":{"line":129,"column":74}}}) : helper)))
    + "\" type=\"text\" class=\"form-control amount_"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":129,"column":115},"end":{"line":129,"column":123}}}) : helper)))
    + "\" data-type=\"amount\" data-bind=\"model\" maxLength=\"16\" data-hook=\"getAmount\" value=\"\" name=\"AMOUNT_"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":129,"column":221},"end":{"line":129,"column":229}}}) : helper)))
    + "\">\n                                                <div class=\"input-group-addon\">\n                                                    <input id=\"credit-currency\" readonly class=\"currency-field\" data-bind=\"model\" data-hook=\"getCurrency\" tabindex=\"-1\" maxLength=\"16\" name=\"CREDIT_CURRENCY\">\n                                                </div>\n                                            </div>\n                                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"AMOUNT_"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":134,"column":109},"end":{"line":134,"column":117}}}) : helper)))
    + "\"></span>\n                                        </div>\n                                    </div>\n                                    <div class=\"form-column column-md-6 payment-types\">\n                                        <div class=\"form-group type\">\n                                            <label for=\"selection_"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":139,"column":66},"end":{"line":139,"column":74}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"loans.applies.to",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":139,"column":76},"end":{"line":139,"column":105}}}))
    + "</label>\n                                            <select data-bind=\"model\" id=\"selection_"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":140,"column":84},"end":{"line":140,"column":92}}}) : helper)))
    + "\" class=\"form-control applies-to\" data-hook=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":140,"column":137},"end":{"line":140,"column":145}}}) : helper)))
    + "\" name=\"SELECTION_"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":140,"column":163},"end":{"line":140,"column":171}}}) : helper)))
    + "\">\n                                            </select>\n                                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"SELECTION_"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":142,"column":112},"end":{"line":142,"column":120}}}) : helper)))
    + "\"></span>\n                                        </div>\n                                    </div>\n                                    <span class=\"icon-close remove\" id=\"remove-option\" data-hook=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":145,"column":98},"end":{"line":145,"column":106}}}) : helper)))
    + "\"></span>\n                                </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "hide";
},"18":function(container,depth0,helpers,partials,data) {
    return "<span data-hook=\"getAmountDue\"></span>";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row\">\n                <div class=\"form-column col-md-6\">\n                    <div class=\"field-container field-container-lg payment-options payment-options-single\">\n                        <div class=\"form-group amount\">\n                            <label for=\"credit-amount\">"
    + container.escapeExpression(container.lambda(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"getAmountText")), depth0))
    + "</label>\n                            <div class=\"input-group\">\n                                <input id=\"credit-amount\" type=\"text\" class=\"form-control credit-amount\" data-bind=\"model\" maxLength=\"16\" data-type=\"amount\" data-hook=\"getAmount\" name=\"CREDIT_AMOUNT\">\n                                <div class=\"input-group-addon\">\n                                    <input id=\"credit-currency\" readonly class=\"currency-field\" data-bind=\"model\" data-hook=\"getCurrency\" maxLength=\"16\" name=\"CREDIT_CURRENCY\">\n                                </div>\n                            </div>\n                        </div>\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"CREDIT_AMOUNT\"></span>\n                    </div>\n                </div>\n            </div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"detailAuditHistory"),depth0,{"name":"detailAuditHistory","hash":{"payment":"true","containerTitle":"PAYJS.PaymentHistory"},"data":data,"indent":"           ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div data-section=\"save-as-template\">\n                <div class=\"row\">\n                    <div class=\"form-column inline-fields\">\n                        <div id=\"SAVEASTEMPLATE\" class=\"align-checkbox field-container-lg field-container\">\n                            <input type=\"checkbox\" data-bind=\"model\" name=\"SAVEASTEMPLATE\" id=\"template-save\">\n                            <label class=\"checkbox-inline\" for=\"SAVEASTEMPLATE\"> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"loans.save.as.template",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":214,"column":81},"end":{"line":214,"column":116}}}))
    + "</label>\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"SAVEASTEMPLATE\"></span>\n                        </div>\n                        <div class=\"col-md-2 col-mdlg-4 field-container template-code-form required hide\">\n                            <label for=\"Template_Code\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.TemplateCode",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":218,"column":55},"end":{"line":218,"column":84}}}))
    + "</label>\n                            <input class=\"form-control\" type=\"text\" maxlength=\"35\" data-bind=\"model\" id=\"\" name=\"TEMPLATE_CODE\" required>\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"TEMPLATE_CODE\"></span>\n                        </div>\n                        <div class=\"col-md-2 col-mdlg-4  field-container template-descr-form required hide\">\n                            <label for=\"Template_Description\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.TemplateDescription",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":223,"column":62},"end":{"line":223,"column":98}}}))
    + "</label>\n                            <input class=\"form-control \" type=\"text\" maxlength=\"35\" data-bind=\"model\" id=\"\" name=\"TEMPLATE_DESCRIPTION\" required>\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"TEMPLATE_DESCRIPTION\"></span>\n                        </div>\n                    </div>\n                    <div class=\"form-column inline-fields\">\n                        <div class=\"align-checkbox field-container-lg field-container restrict-check-form hide\">\n                            <input type=\"checkbox\" data-bind=\"model\" name=\"RESTRICTTEMPLATE_FLAG\" id=\"RESTRICTTEMPLATE_FLAG\">\n                            <label class=\"checkbox-inline\" for=\"RESTRICTTEMPLATE_FLAG\"> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.screentext.Restrict",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":231,"column":88},"end":{"line":231,"column":124}}}))
    + "</label>\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"RESTRICTTEMPLATE_FLAG\"></span>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"loans-loading-overlay hidden\">\n    <div class=\"loading-indicator-view\"><span></span></div>\n</div>\n\n<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"loans.back.to.previous",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":144},"end":{"line":7,"column":179}}}))
    + "</span></a>\n    </div>\n    <h1 class=\"landing-header\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"getTitle") || (depth0 != null ? lookupProperty(depth0,"getTitle") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"getTitle","hash":{},"data":data,"loc":{"start":{"line":9,"column":31},"end":{"line":9,"column":43}}}) : helper)))
    + "</h1>\n\n    <div class=\"amount-wrapper\">\n        <p class=\"amount-label-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.paymentTotal",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":37},"end":{"line":12,"column":69}}}))
    + "</p>\n        <div class=\"amount-value-text payment-total\"><span data-hook=\"getSummaryAmountTotal\">0.00</span> <span data-hook=\"getCurrency\"></span></div>\n    </div>\n</div>\n<section class=\"section-container\">\n  <div class=\"alert-message\">\n      <div id=\"alertRegion\" class=\"validation-group\" role=\"alert\"></div>\n  </div>\n  <div data-region=\"pmtMessageRegion\"></div>\n</section>\n\n<form class=\"corp-loans-page\">\n    <fieldset class=\"section section-container\">\n        <div class=\"section-header\">\n            <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.advice.transaction.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":20},"end":{"line":26,"column":60}}}))
    + "</legend>\n        </div>\n\n        <div class=\"row\">\n            <div class=\"form-column col-md-6 from-account\">\n                <div class=\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isActive") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":28},"end":{"line":31,"column":65}}})) != null ? stack1 : "")
    + " field-container field-container-lg lookup-container\">\n                    <label for=\"DEBIT_ACCOUNT_NUMBER\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"originLabel") || (depth0 != null ? lookupProperty(depth0,"originLabel") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"originLabel","hash":{},"data":data,"loc":{"start":{"line":32,"column":54},"end":{"line":32,"column":69}}}) : helper)))
    + "</label>\n                     <select id=\"DEBIT_ACCOUNT_NUMBER\" data-hook=\"getDebitAccountNumber\" class=\"form-control form-select\" name=\"DEBIT_ACCOUNT_NUMBER\" data-bind=\"model\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"debitAccounts") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":24},"end":{"line":36,"column":33}}})) != null ? stack1 : "")
    + "                    </select>\n                    <span class=\"icon-search lookup btn-tertiary btn\" data-hook=\"lookup-single\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.search",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":103},"end":{"line":38,"column":129}}}))
    + "\" data-field=\"DEBIT_ACCOUNT_NUMBER\" name=\"DEBIT_ACCOUNT_NUMBER\"></span>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"DEBIT_ACCOUNT_NUMBER\"></span>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"DEBIT_CURRENCY\"></span>\n                </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isActive") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":16},"end":{"line":47,"column":27}}})) != null ? stack1 : "")
    + "                <div class=\"text-group field-container field-container-lg field-info\">\n                    </div>\n            </div>\n        </div>\n\n        <div class=\"row\">\n            <div class=\"form-column col-md-6 to-account\">\n                <div class=\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isActive") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":28},"end":{"line":55,"column":65}}})) != null ? stack1 : "")
    + " field-container field-container-lg lookup-container\">\n                    <label for=\"BENE_ACCOUNT\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"destinationLabel") || (depth0 != null ? lookupProperty(depth0,"destinationLabel") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"destinationLabel","hash":{},"data":data,"loc":{"start":{"line":56,"column":46},"end":{"line":56,"column":66}}}) : helper)))
    + "</label>\n                     <select id=\"BENE_ACCOUNT\" class=\"form-control form-select\" data-bind=\"model\" name=\"BENE_ACCOUNT\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"creditAccounts") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":24},"end":{"line":60,"column":33}}})) != null ? stack1 : "")
    + "                    </select>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_ACCOUNT\"></span>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"CREDIT_CURRENCY\"></span>\n                    <span class=\"icon-search lookup btn-tertiary btn\" data-hook=\"lookup-single\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.search",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":64,"column":103},"end":{"line":64,"column":129}}}))
    + "\" data-field=\"BENE_ACCOUNT\" name=\"BENE_ACCOUNT\"></span>\n                </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isActive") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":16},"end":{"line":71,"column":27}}})) != null ? stack1 : "")
    + "                <div class=\"text-group field-container field-container-lg field-info\">\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row\">\n            <div class=\"form-column col-md-10\">\n                <div class=\"field-container field-container-lg amounts-due\"></div>\n            </div>\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasMultipleOptions") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":8},"end":{"line":85,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"row\">\n            <div class=\"form-column col-md-6\">\n                <div data-hook=\"getDatePicker\" class=\"date-picker field-container field-container-sm payment-date\">\n                    <label for=\"VALUE_DATE\" class=\"required\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.valueDate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":90,"column":61},"end":{"line":90,"column":90}}}))
    + " <div data-hook=\"getDueDate\" class=\"loan-due-date\"></div></label>\n                    <input name=\"VALUE_DATE\" type=\"text\" size=\"20\" class=\"input-date form-control\" data-bind=\"model\" autocomplete=\"off\" id=\"VALUE_DATE\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"VALUE_DATE\"></span>\n                    <span class=\"field-info\"></span>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row hide\" data-hook=\"getNoInvoice\">\n            <div class=\"form-column col-md-6\">\n                <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"loans.invoice.not.available",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":100,"column":19},"end":{"line":100,"column":59}}}))
    + "</p>\n            </div>\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasMultipleOptions") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":104,"column":8},"end":{"line":176,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"row\">\n            <div class=\"form-column col-md-6\">\n                <div class=\"field-container field-container-lg\">\n                    <label for=\"customer-reference\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"loans.customerref",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":180,"column":52},"end":{"line":180,"column":82}}}))
    + "</label>\n                    <input id=\"customer-reference\" type=\"text\" name=\"CUSTOMER_REFERENCE\" data-bind=\"model\" class=\"form-control\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"CUSTOMER_REFERENCE\"></span>\n                </div>\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"form-column col-md-6\">\n                <div class=\"field-container field-container-lg payment-comments\">\n                    <label for=\"special-instructions\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.Comments",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":189,"column":54},"end":{"line":189,"column":79}}}))
    + "</label>\n                    <textarea id=\"special-instructions\" type=\"text\" class=\"form-control textarea-field\" rows=3 data-bind=\"model\" name=\"SPECIAL_INSTRUCTIONS\"/>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"SPECIAL_INSTRUCTIONS\"></span>\n                </div>\n                <div class=\"field-container field-container-lg\">\n                    <span class=\"textline-field\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.InternalMemoMessage",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":194,"column":49},"end":{"line":194,"column":85}}}))
    + "</span>\n                </div>\n            </div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNotCopy") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":198,"column":8},"end":{"line":200,"column":15}}})) != null ? stack1 : "")
    + "    </fieldset>\n\n    <div class=\"section section-summary total\">\n        <div class=\"section-header\" data-section=\"payment-summary\">\n            <h3 class=\"total-heading\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"loans.payment.summary",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":205,"column":38},"end":{"line":205,"column":72}}}))
    + "</h3>\n            <div data-hook=\"payment-summary\" data-region=\"summaryTotalRegion\" />\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasInsertTemplateEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":208,"column":8},"end":{"line":237,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"section-body\">\n            <div class=\"widget-action-btn-group\">\n                <button type=\"button\" class=\"btn btn-success submit\" data-action=\"submit\" aria-label=\"Submit\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.submit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":240,"column":110},"end":{"line":240,"column":136}}}))
    + "</button>\n                <button type=\"button\" class=\"btn btn-secondary\" data-action=\"saveDraft\" aria-label=\"Save Draft\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"buttons.saveForLater",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":241,"column":112},"end":{"line":241,"column":145}}}))
    + "</button>\n                <button type=\"button\" class=\"btn btn-secondary\" data-action=\"cancel\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":242,"column":85},"end":{"line":242,"column":111}}}))
    + "</button>\n            </div>\n        </div>\n    </div>\n</form>";
},"usePartial":true,"useData":true});