import http from '@glu/core/src/http';
import locale from '@glu/locale';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';

import Constants from 'system/mfa/constants';
import services from 'services';
import transform from 'common/util/transform';

export default Model.extend({
    fetchRegistrationCode(options) {
        this.sync('register', this, options);
    },

    sync(method, model, options) {
        let data;
        let service;
        let items;
        let uri;

        if (method === 'create') {
            data = this.convertModelAttributesToServerJSON(method);
            uri = model.get('challengeType') === Constants.ONESPAN_CHALLENGE_TYPE
                ? 'mfaService/loginSelfRegisterToken' : 'mfaService/loginActivateToken';
            service = services.generateUrl(uri);

            data.activationCode = this.get('activationCode');
            return http.post(service, data, (response) => {
                options.success(response);
            }, (response) => {
                // Passing default App Resource incase response is null
                options.error(response, 'mfa.error.message');
            });
        }

        if (method === 'register') {
            data = this.convertModelAttributesToServerJSON(method);
            service = services.generateUrl('mfaService/loginGetTokenRegistrationCode');

            return http.post(service, data, (response) => {
                let localResponse = response;
                if (localResponse.customParameters) {
                    items = localResponse.customParameters.item;

                    if (items.length > 0) {
                        items = transform.pairsToHash(localResponse.customParameters.item);
                        localResponse = util.extend(localResponse, items);
                    }
                }
                options.success(localResponse);
            }, (response) => {
                // Passing default App Resource incase response is null
                options.error('mfa.error.message', response);
            });
        }
        // TODO: Should return rejected Promise for bad method?
        return undefined;
    },

    parse(response) {
        let localResponse = response;
        let items;

        if (localResponse.customParameters) {
            items = localResponse.customParameters.item;

            if (items.length > 0) {
                items = transform.pairsToHash(localResponse.customParameters.item);
                localResponse = util.extend(localResponse, items);
            }
        }

        if (localResponse.additionalData && util.isArray(localResponse.additionalData.item)) {
            items = localResponse.additionalData.item;

            if (items.length > 0) {
                items = transform.pairsToHash(localResponse.additionalData.item);
                localResponse = util.extend(localResponse, items);
            }
        }

        return localResponse;
    },

    convertModelAttributesToServerJSON() {
        const challengeAction = util.clone(this.attributes.challengeAction);
        const challengeType = util.clone(this.attributes.challengeType);
        return {
            challengedAction: challengeAction,
            tokenType: challengeType,

            customParameters: {
                item: [{
                    name: 'tokenSerialNumber',
                    value: this.get('TokenSerialNumber'),
                }],
            },
        };
    },

    /**
     * @method addSelfRegistrationValidator
     * Helper method to add validator for token code
     * and serial number in model
     */
    addSelfRegistrationValidator() {
        this.addValidator('TokenSerialNumber', {
            exists: true,
            description: locale.get('mfa.rsa.selfregister.token.error'),
        });
        this.addValidator('activationCode', {
            exists: true,
            description: locale.get('mfa.rsa.selfregister.code.error'),
        });
    },

});
