import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './FormField.styles';

const propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

const defaultProps = {
    className: '',
};
/*
 * TODO As GLU matures, this component may become obsolete.
 * It currently exists to make allow form fields to
 * render properly with legacy css in the application.
 */
const FormField = ({
    className,
    children,
}) => {
    const classes = useStyles();
    return (
        <div className={`${classes.fieldWrapper} ${className}`}>
            {children}
        </div>
    );
};
FormField.propTypes = propTypes;
FormField.defaultProps = defaultProps;

export default FormField;
