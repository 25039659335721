import React from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import { withStyles } from '@glu/theming';

import styles from './LoadMore.styles';

const LoadMore = ({
  classes,
  onLoadMore,
  page,
  pageSize,
  totalRows,
  visibleRowsSize
}) => {
  const lastPage = totalRows / pageSize;
  const isNotLastPage = page < lastPage;

  if (!visibleRowsSize) {
    return null;
  }
  return (
    <div className={classes.loadMoreContainer}>
      {isNotLastPage ? <button type="button" onClick={onLoadMore}>{locale.get('loadMore')}</button> : null}
      <span>
        {locale.get('viewingRangeTotal', 1, visibleRowsSize, totalRows)}
      </span>
    </div>
  );
};

LoadMore.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onLoadMore: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalRows: PropTypes.number.isRequired,
  visibleRowsSize: PropTypes.number.isRequired
};

export default withStyles(styles)(LoadMore);
