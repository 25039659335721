import EntryView from 'common/dynamicPages/views/workflow/entry';

export default EntryView.extend({
    initialize() {
        const options = {
            menuCategory: 'PMTS',
            serviceName: 'templates/corp',
            serviceFunc: null,
            businessType: null,
            context: 'TEMP_LIST_VIEW',
            mode: 'modify',
        };

        EntryView.prototype.initialize.call(this, options);
    },

    getModifyButtons() {
        this.buttonList.push({
            action: 'SAVE',
        });
        this.buttonList.push({
            action: 'SAVEDRAFT',
        });
    },

    savedraft() {
        this.disableButtons();

        this.disableValidators(this.pageView);
        if (this.pageView.childView) {
            this.disableValidators(this.pageView.childView);
        }

        this.pageView.model.set({
            _saveIncomplete: 'true',
        });
        this.pageView.save();
    },
});
