import CollectionView from '@glu/core/src/collectionView';
import TotalsItem from './totalsItem';
import TotalsCollection from './collection/totalsCollection';

export default CollectionView.extend({
    tagName: 'div',
    className: 'totals-collection',
    itemView: TotalsItem,
    collectionEvents: {
        sync: 'render',
    },

    initialize(options) {
        this.collection = new TotalsCollection([], options);
    },

    /**
     * Refreshes the data of this view
     * @param {array} [searchFields] - an optional array of objects containing
     * search fields
     * criteria
     * @param {number} [viewId] - an optional view id
     */
    refresh(searchFields, viewId) {
        this.collection.setSearchFields(searchFields);
        this.collection.setViewId(viewId);
        this.collection.fetch();
    },

    /**
     * Adds a new total item to this view.
     * Note this does not re-render the view.  Render should be called explicitly.
     *
     * @param {object} totalsItem - A object representing a total item label
     * and value to
     * display
     * @param {string} [totalsItem.fieldName] - an optional field name. Typically the raw
     * untranslated column name
     * @param {string} totalsItem.fieldValue - the value of the field
     * @param {string} totalsItem.displayName - the user friendly display name
     *
     */
    add(totalsItem) {
        if (totalsItem) {
            this.collection.add(totalsItem);
        }
    },

});
