import workspaceHelper from 'common/workspaces/api/helper';
import GridView from 'app/smbPayments/views/paymentGrid';
import store from 'system/utilities/cache';
import {
    PAYMENT_SAVED_VIEW_IDS,
    TEMPLATE_SAVED_VIEW_IDS,
} from 'app/smbPayments/constants';
import PaymentAndEmployeeMainView from './paymentAndEmployeeMainView';
import tmpl from './paymentAndTemplateView.hbs';

const paymentAndTemplateView = PaymentAndEmployeeMainView.extend({
    template: tmpl,

    gridData: {
        paymentOptions: {
            returnRoute: 'PAYMENTS/smbManagePayments',
            context: 'SMB_PAY_LIST',
            serviceName: 'payment/listView/smb',
            isPaymentView: true,
            gridView: GridView,
        },
        templateOptions: {
            returnRoute: 'PAYMENTS/smbManagePayments',
            context: 'SMB_TEMP_LIST',
            serviceName: 'template/listView/smb',
            isPaymentView: false,
            gridView: GridView,
        },
    },

    initialize(options) {
        const opts = {
            paymentTypeList: ['BDACHTP'],
            storeName: 'payment_smb-currentTab',
            paymentBase: 'payments',
            templateBase: 'paymentTemplates',
            gridView: GridView,
        };
        const { viewId } = options;
        if (PAYMENT_SAVED_VIEW_IDS.includes(viewId)) {
            this.gridData.paymentOptions.viewId = options.viewId;
            store.set(opts.storeName, 'paymentTab');
        } else if (TEMPLATE_SAVED_VIEW_IDS.includes(viewId)) {
            this.gridData.templateOptions.viewId = options.viewId;
            store.set(opts.storeName, 'templateTab');
        }
        PaymentAndEmployeeMainView.prototype.initialize.call(this, opts);
    },

});

workspaceHelper.publishedWidgets.add({
    id: 'SMBPAYLIST',
    view: paymentAndTemplateView,
    options: {},
});

export default paymentAndTemplateView;
