import Marionette from 'backbone.marionette';

export default Marionette.Behavior.extend({
    initialize() {

        /*
         * TODO - might reimplement a global error catcher in a different behavior
         * var self = this;
         * this.view.listenTo(this.view.appBus, 'http:400', function(response) {
         *    self.showGenericError(response.responseHeader.message);
         * });
         */
    },

    events: {
        'blur input': 'blurHandler',
        'blur textarea': 'blurHandler',
    },

    modelEvents: {
        validate: 'hideGenericError',
    },

    blurHandler(event) {
        /*
         * removed checks from LSM code about whether or not a view is in a region
         * might want to add those back at some point
         */
        this.view.model.validateField(event.target.name);
    },

    hideGenericError() {
        const { view } = this;
        const $el = view.$el.find('[data-hook="generic-error"]');

        // Set error highlighting on wrapper element
        const $wrapper = $el.closest('.form-group, .validation-group').removeClass('has-error');
        $wrapper.addClass('hide');
    },

    showGenericError(messages) {
        const { view } = this;
        const str = messages.join('<br>');

        const $el = view.$el.find('[data-hook="generic-error"]')
            .text(str);

        // Set error highlighting on wrapper element
        const $wrapper = $el.closest('.form-group, .validation-group')
            .addClass('has-error');

        // If the wrapper has a 'hide' class, remove it, and make a note
        if ($wrapper.hasClass('hide')) {
            $wrapper.data('hide', 'true').removeClass('hide');
        }
    },
});
