import locale from '@glu/locale';
import ScheduledExportModel from './scheduledExportModel';

export default ScheduledExportModel.extend({

    defaults() {
        return {
            ...ScheduledExportModel.prototype.defaults.call(this),
            EXPORTTYPECODE: 'EXTRPT',
        };
    },

    initialize(options) {
        ScheduledExportModel.prototype.initialize.call(this, options);

        this.validators = {
            ...this.validators,
            REPORT_ID: {
                description: locale.get('GIR.Report.ID'),
                exists: true,
            },
            REPORTDESC: {
                description: locale.get('GIR.Description'),
                exists: true,
            },
            REPORTORDOWNLOAD: {
                description: locale.get('GIR.File.Type'),
                exists: true,
            },
        };
    },
});
