import EntryView from 'common/dynamicPages/views/workflow/entry';
import ContextApi from 'common/dynamicPages/api/context';

export default EntryView.extend({
    initialize() {
        const context = {
            actionMode: 'SELECT',
            displayOrder: 1,
            filterId: 20104,
            functionCode: 'INST',
            inquiryId: 20104,
            gridId: 20104,
            menuCategory: 'payments',
            menuContext: 'payment/listView/smb',
            menuDescription: 'Bills & Payments',
            nonDashboardDisplay: 0,
            productCode: 'PAY',
            requestMappings: 'smbManagePayments',
            requestParams: '?!_init=true&_productCode=PAY&_functionCode=INST&_typeCode=*&_mode=SELECT&inquiryID=20104&_gridId=20104&_filterID=20104',
            typeCode: '*',
        };

        const paymentsContextDef = ContextApi.menuContext.getContext(context);
        const options = {
            menuCategory: 'PMTS',
            serviceName: 'payment/listView/smb',
            serviceFunc: null,
            businessType: null,
            context: paymentsContextDef,
            mode: 'modify',
        };

        EntryView.prototype.initialize.call(this, options);
    },

    getModifyButtons() {
        this.buttonList.push({
            action: 'SAVE',
        });
        this.buttonList.push({
            action: 'SAVEDRAFT',
        });
    },

    savedraft() {
        this.disableButtons();
        this.pageView.model.set({
            _saveIncomplete: 'true',
        });
        this.pageView.save();
    },
});
