var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"mdf-item-row\">\n    <div data-region=\"itemContent\" class=\"mdf-item-content\"></div>\n    <div class=\"remove-button-container\">\n        <div >\n            <button data-action=\"removeItem\" class=\"icon-wrapper btn btn-tertiary item-remove\" aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.removeAnItem",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":107},"end":{"line":5,"column":139}}}))
    + "\">\n                <span data-hook=\"getClearButton\" class=\"item-remove-first\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.clear",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":75},"end":{"line":6,"column":100}}}))
    + "</span>\n                <span data-hook=\"getRemoveButton\" class=\"item-remove-multi icon-close hide\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"action.remove",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":99},"end":{"line":7,"column":125}}}))
    + "\"></span>\n            </button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});