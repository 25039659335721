import ErrorView from 'system/error/views/errorMessage';

export default {
    loading(noContainer) {
        this.$el.html((new ErrorView({
            key: 'loadingPage',
            noContainer,
        })).render().$el);
    },

    loadingModal() {
        this.$el.html(new ErrorView({
            noContainer: true,
            key: 'loadingModal',
        }).render().$el);
        this.trigger('load:error');
    },

    intraPage() {
        const errorView = new ErrorView({
            noContainer: true,
            key: 'intraPage',
        });

        if (this.alertRegion) {
            this.alertRegion.show(errorView);
        } else if (this.$el.find('.section-container').first().length > 0) {
            this.$el.find('.section-container').first().prepend(errorView.render().$el);
        } else {
            this.$el.prepend(errorView.render().$el);
        }
    },

    modelSubmission() {
        const errorView = new ErrorView({
            noContainer: true,
            key: 'modelSubmission',
        });

        if (this.alertRegion) {
            this.alertRegion.show(errorView);
        } else {
            this.$el.find('.section-container').first().prepend(errorView.render().$el);
        }
    },

    /**
     * @description infers the status object and displays the corresponding message
     * to the user.
     * @param {boolean} noContainer specifies whether or not a container is needed.
     * @param {Object} status - XHR status object
     * status xhr status object.
     */
    handleError(noContainer, status) {
        let errorKey = 'loadingPage';
        if (status.status === 408) {
            errorKey = 'timeout';
        } else if (status.status === 502) {
            errorKey = 'proxyerror';
        }

        this.$el.append((new ErrorView({
            key: errorKey,
            noContainer,
        })).render().$el);
    },

    // for testing purposes
    errorPromise() {
        return new Promise((resolve, reject) => {
            reject();
        });
    },
};
