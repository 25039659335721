import Collection from '@glu/core/src/collection';
import userInfo from 'etc/userInfo';
import util from '@glu/core/src/util';
import transform from 'common/util/transform';
import services from 'services';
import http from '@glu/core/src/http';

export default Collection.extend({
    url: services.generateUrl('/tableMaintenance/getLookupResults'),

    initialize(model, options) {
        this.templateName = '';
        if (options) {
            this.templateName = options.templateName;
            if (options.mode === 'target') {
                this.mode = 'target';
                this.inquiryId = '20490';
            } else {
                this.mode = 'source';
                this.inquiryId = '20491';
            }
        }
    },

    getEntitledModels() {
        return this.where({
            ENTITLED: '1',
        });
    },

    getNonEntitledModels() {
        return this.where({
            ENTITLED: '0',
        });
    },

    getRequestJSON() {
        const request = {
            typeCode: 'TMPLSET',
            productCode: 'PAY',
            functionCode: 'TMPLSET',

            depends: [{
                name: 'NAME',
                value: this.templateName,
            }, {
                name: 'USERID',
                value: userInfo.get('id'),
            }, {
                name: 'USERGROUP',
                value: userInfo.get('group'),
            }],

            requestParameters: {
                item: [{
                    name: 'INQUIRYID',
                    value: this.inquiryId,
                }],
            },

            startRow: 0,
            rowsPerPage: 250,
        };

        return request;
    },

    sync(method, collection, options) {
        if (method === 'read') {
            return http.post(
                this.url, this.getRequestJSON(),
                options.success, options.error,
            );
        }
        return undefined;
    },

    parse(res) {
        return util.map(res.rows, (row) => {
            const hash = transform.pairsToHash(row.columns, 'fieldName', 'fieldValue');
            hash.id = hash.TNUM;
            return hash;
        }, this);
    },
});
