import Grid from '@glu/grid';
import { appBus } from '@glu/core';

export default function (options) {
    let localOptions = options;
    localOptions = localOptions || {};

    const Model = localOptions.collection.model;

    const view = new localOptions.AddEditRowView({
        model: new Model(),
        editOptions: localOptions.editOptions,
    });

    const CustomGrid = Grid.extend();
    CustomGrid.prototype.tableBodyClass.prototype.editItemView = localOptions.AddEditRowView;

    view.on('save', (model, callback) => {
        const payment = model.clone();
        localOptions.collection.add(payment);
        appBus.trigger('sbPayments:paymentListUpdated');
        model.reset();
        callback(true);
    }, this);

    view.on('clear', (model, callback) => {
        appBus.trigger('sbPayments:paymentListUpdated');
        model.reset();
        callback(true);
    }, this);

    const grid = new CustomGrid({
        emptyViewText: ' ',
        columns: localOptions.columns,
        collection: localOptions.collection,
        header: false,
        disableAutoWidth: true,
        editOptions: localOptions.editOptions,
    });

    grid.tableView.setHeader(false);
    grid.tableBody.setFirstView(view.render());

    return grid;
}
