import Layout from '@glu/core/src/layout';
import FlexDropdown from '@glu/flex-dropdown';
import locale from '@glu/locale';
import constants from 'app/loans/constants';
import template from './invoiceWidget.hbs';

export default Layout.extend({
    template,

    ui: {
        $invoiceFields: '[data-hook="getPmtInvoiceContainer"]',
        $paymentType: '[data-hook="getPmtTypeDropdown"]',
    },

    initialize() {
        this.flexData = this.collection.toFlexDropdataFormat();
        this.invoiceAmountFields = [];
    },

    onRender() {
        this.ui.$paymentType.hide();
        this.fieldsShouldBeHidden(true);
        this.renderInvoiceDropdown();
        this.renderPaymentTypeDropdown();
    },

    /**
     * @method renderInvoiceDropdown
     */
    renderInvoiceDropdown() {
        this.pmtInvoiceDropdown = new FlexDropdown(this.flexData);
        this.listenTo(this.pmtInvoiceDropdown, 'selectionChanged:id', this.handleSelect.bind(this));
        this.pmtInvoiceRegion.show(this.pmtInvoiceDropdown);
    },

    /**
     * @method renderPaymentTypeDropdown
     */
    renderPaymentTypeDropdown() {
        if (this.options.loanAccountType !== constants.TYPE.BOTH) {
            this.fieldsShouldBeHidden(false);
            return;
        }
        this.pmtPayTypeDropdown = new FlexDropdown({
            data: [{
                id: constants.TYPE.ANY,
                name: locale.get('common.otherAmount'),
            },
            {
                id: constants.TYPE.INVOICE_ONLY,
                name: locale.get('common.invoiceAmount'),
            }],

            preSelectedIds: [
                this.options.parentModel.get('INVOICENUM') ? constants.TYPE.INVOICE_ONLY : constants.TYPE.ANY,
            ],
        });
        this.listenTo(this.pmtPayTypeDropdown, 'selectionChanged', this.handleTypeSelect.bind(this));
        this.pmtPayTypeRegion.show(this.pmtPayTypeDropdown);
        this.ui.$paymentType.show();
        this.fieldsShouldBeHidden(false);
    },

    /**
     * @method handleSelect
     * @param invoice
     * @description Accepts the flex object from the invoice dropdown, sets the
     * attributes of the selection on the model, triggers an event.
     * Setting the attributes of the selected collection model onto the current
     * view model forever overwrites the identical model in the collection.
     * Instead of using set, replace the current view model entirely.
     */
    handleSelect(invoice) {
        this.model = this.collection.get(invoice[0]);
        this.trigger('invoice:selection:changed', this.model.toJSON());
    },

    /**
     * @method handleTypeSelect
     * @param type
     * @description Controls the visibility of the fields, triggers an event
     */
    handleTypeSelect(type) {
        this.trigger('invoice:paymentType:changed', type[0].id);
    },

    /**
     * @method setPreSelectedInvoice
     * @param hasExistingInvoiceNumber
     * @description A helper function to return the first model of the collection,
     * unless an existing invoice number
     * exists in the model already or the model is found in the collection.
     */
    setPreSelectedInvoice(hasExistingInvoiceNumber) {
        const hasFoundModelInCollection = this.model
            ? this.collection.get(this.model.id) : this.collection.at(0);
        if (!hasExistingInvoiceNumber || !hasFoundModelInCollection) {
            const attrs = this.collection.at(0).toJSON();
            this.model.set(attrs);
        }
        this.trigger('invoice:selection:changed', this.model.toJSON());
    },

    /**
     * @method fieldsShouldBeHidden
     * @param shouldHide
     * @description A helper function to control visibility of all invoice related fields
     */
    fieldsShouldBeHidden(shouldHide) {
        if (shouldHide) {
            this.ui.$invoiceFields.hide();
        } else {
            this.ui.$invoiceFields.show();
        }
    },
});
