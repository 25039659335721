var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page page-2col\">\n    <div class=\"page-content beneficiaryPage\">\n        <div class=\"title-strip\">\n            <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"addressBook",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":40}}}))
    + "</h2>\n        </div>\n        <form class=\"beneficiaryForm\">\n            <fieldset>\n                <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"createBeneficiary",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":24},"end":{"line":8,"column":54}}}))
    + "</legend>\n                <div class=\"col\">\n                    <div class=\"form-group\">\n                        <label for=\"name\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"name",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":42},"end":{"line":11,"column":59}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <input class=\"form-control\" type=\"text\" data-bind=\"model\" name=\"name\" value=\"\" id=\"name\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"name\"></span>\n                        </div>\n                    </div>\n                    <div class=\"form-group\">\n                        <label for=\"reference\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"referenceId",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":47},"end":{"line":18,"column":71}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <input class=\"form-control\" type=\"text\" name=\"reference\" data-bind=\"model\" value=\"\" id=\"reference\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"reference\"></span>\n                        </div>\n                    </div>\n                    <div class=\"form-group addressLines\">\n                        <label for=\"Address Lines\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"addressLine",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":51},"end":{"line":25,"column":75}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <input class=\"form-control\" type=\"text\" name=\"addressLine1\" value=\"\" data-bind=\"model\" id=\"address-line1\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"address-line1\"></span>\n                            <input class=\"form-control\" type=\"text\" name=\"addressLine2\" data-bind=\"model\" value=\"\" id=\"address-line2\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"address-line2\"></span>\n                            <input class=\"form-control\" type=\"text\" name=\"addressLine3\" data-bind=\"model\" value=\"\" id=\"address-line3\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"address-line3\"></span>\n                        </div>\n                    </div>\n                    <div class=\"form-group\">\n                        <label for=\"country\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"country",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":45},"end":{"line":36,"column":65}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <div data-region=\"countryRegion\"></div>\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"country\"></span>\n                        </div>\n                    </div>\n                    <div class=\"form-group\">\n                        <label for=\"province\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"BeneAddBook.Province",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":43,"column":46},"end":{"line":43,"column":79}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <input class=\"form-control\" type=\"text\" data-bind=\"model\" name=\"province\" value=\"\" id=\"province\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"province\"></span>\n                        </div>\n                    </div>\n                    <div class=\"form-group\" id=\"state\">\n                        <label for=\"state\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"state",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":50,"column":43},"end":{"line":50,"column":61}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <div data-region=\"stateRegion\"></div>\n                        </div>\n                    </div>\n                    <div class=\"form-group\">\n                        <label for=\"postal\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"postal",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":56,"column":44},"end":{"line":56,"column":63}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <input class=\"form-control\" type=\"text\" data-bind=\"model\" name=\"postal\" value=\"\" id=\"postal\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"postal\"></span>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"col\">\n                    <div class=\"form-group\">\n                        <label for=\"phone\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"BeneAddBook.Phone",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":65,"column":43},"end":{"line":65,"column":73}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <input class=\"form-control\" type=\"text\" data-bind=\"model\" name=\"phone\" value=\"\" id=\"phone\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"phone\"></span>\n                        </div>\n                    </div>\n                    <div class=\"form-group\">\n                        <label for=\"fax\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"BeneAddBook.Fax",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":72,"column":41},"end":{"line":72,"column":69}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <input class=\"form-control\" type=\"text\" data-bind=\"model\" name=\"fax\" value=\"\" id=\"fax\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"fax\"></span>\n                        </div>\n                    </div>\n                </div>\n            </fieldset>\n            <fieldset>\n                <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"paymentInfo",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":81,"column":24},"end":{"line":81,"column":48}}}))
    + "</legend>\n                <div class=\"col\">\n                    <div class=\"form-group\">\n                        <label for=\"paymentName\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"paymentName",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":84,"column":49},"end":{"line":84,"column":73}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <input class=\"form-control\" type=\"text\" data-bind=\"model\" name=\"paymentName\" value=\"\" id=\"paymentName\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"paymentName\"></span>\n                        </div>\n                    </div>\n                    <div class=\"form-group\">\n                        <label for=\"paymentRid\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"referenceId",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":91,"column":48},"end":{"line":91,"column":72}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <input class=\"form-control\" type=\"text\" data-bind=\"model\" name=\"paymentRid\" value=\"\" id=\"paymentRid\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"paymentRid\"></span>\n                        </div>\n                    </div>\n                    <div class=\"form-group\">\n                        <label for=\"accountType\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"accountType",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":98,"column":49},"end":{"line":98,"column":73}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <input class=\"form-control\" type=\"text\" data-bind=\"model\" name=\"accountType\" value=\"\" id=\"accountType\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"accountType\"></span>\n                        </div>\n                    </div>\n                    <div class=\"form-group\">\n                        <label for=\"accountNumber\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"BeneAddBook.AccountNumber",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":105,"column":51},"end":{"line":105,"column":89}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <input class=\"form-control\" type=\"text\" data-bind=\"model\" name=\"accountNumber\" value=\"\" id=\"accountNumber\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"accountNumber\"></span>\n                        </div>\n                    </div>\n                    <div class=\"form-group\" id=\"bank\">\n                        <label for=\"bank\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bank",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":112,"column":42},"end":{"line":112,"column":59}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <div data-region=\"bankRegion\"></div>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"col\">\n                    <div class=\"form-group\" id=\"currency\">\n                        <label for=\"currency\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"currency",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":120,"column":46},"end":{"line":120,"column":67}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <div data-region=\"currencyRegion\"></div>\n                        </div>\n                    </div>\n                </div>\n            </fieldset>\n            <div class=\"beneficiaryButtons\">\n                <button type=\"submit\" class=\"btn btn-primary\" data-action=\"submit\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.add",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":128,"column":83},"end":{"line":128,"column":106}}}))
    + "</button>\n                <button type=\"submit\" class=\"btn btn-primary\" data-action=\"cancel\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":129,"column":83},"end":{"line":129,"column":109}}}))
    + "</button>\n            </div>\n        </form>\n    </div>\n</div>\n";
},"useData":true});