export default {
    // one time passcode challenge type
    OTP_CHALLENGE_TYPE: 0,

    // rsa secure id challenge type
    RSA_CHALLENGE_TYPE: 1,

    // external challenge type
    EXT_CHALLENGE_TYPE: 2,

    // mobile one time passcode challenge type
    MOBILE_OTP_CHALLENGE_TYPE: 3,

    // when an admin challenges a user's device
    ADMIN_CHALLENGE_TYPE: 4,

    // when an admin attempts to register an user's mobile device
    USER_MOBILE_REGISTRATION: 5,

    // vasco challenge type
    VASCO_CHALLENGE_TYPE: 6,

    // entrust challenge type
    ENTRUST_CHALLENGE_TYPE: 7,

    // symantec vip challenge type
    VIP_CHALLENGE_TYPE: 8,

    // RSA Adaptive Authentication
    RSA_AA_CHALLENGE_TYPE: 9,

    VOICE_CALL_CHALLENGE_TYPE: 10,

    // Onespan token challenge type
    ONESPAN_CHALLENGE_TYPE: 11,

    // opt passcode validation failed
    ERROR_PASSCODE_INCORRECT: 124,

    ERROR_NEW_PASSCODE_REQUIRED: 125,

    // maximum retries exceeded, user logged out and disabled.
    ERROR_MAX_RETRIES: 126,
};
