import http from '@glu/core/src/http';
import basePoller from 'common/notificationClient/poller';
import client from '../client';

const NotificationPoller = basePoller.extend({

    initialize(options) {
        this.notificationKey = options.notificationKey;
        this.eventName = options.eventName;
        this.keys = [this.notificationKey];
        basePoller.prototype.initialize.call(this, options);
        client.subscribeTo(this.notificationKey, true);
    },

    /**
     *  Remove listeners for subscribe, unsubscribe
     */
    removeListeners() {
        this.stopListening(client, 'service:subscribe', this.handleSubscribe);
        this.stopListening(client, 'service:unsubscribe', this.handleUnsubscribe);
    },

    /**
     * Override this to specify whether a subscribe event
     *  should cause this poller to act.
     * @param {String} key
     */
    isRelevantSubscription(key) {
        return this.keys.includes(key);
    },

    /**
     * @override
     * @return {{list of subscribe topics}}
     */
    buildRequestObject() {
        return {
            subscribeTypeList: [...this.keys],
        };
    },

    /**
     * @override
     * @return {*}
     */
    requestData() {
        const data = this.buildRequestObject();
        return new Promise((res, rej) => http.post(this.serviceUrl, data).then(res, rej));
    },

    /**
     * Must be overridden by implementation
     * Should trigger events on the client for views to receive.
     */
    processResponses({ notificationMessages }) {
        if (notificationMessages?.[0]?.data.count) {
            client.trigger(this.eventName, notificationMessages[0].data.rows);
        }
        if (!notificationMessages?.[0]?.inProcessItemsPending) {
            this.removeListeners();
            this.disable();
        }
    },
});

export default NotificationPoller;
