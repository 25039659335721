var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button class=\"btn "
    + alias4(((helper = (helper = lookupProperty(helpers,"btnClass") || (depth0 != null ? lookupProperty(depth0,"btnClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnClass","hash":{},"data":data,"loc":{"start":{"line":33,"column":31},"end":{"line":33,"column":43}}}) : helper)))
    + "\"\n                data-action=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"btnAction") || (depth0 != null ? lookupProperty(depth0,"btnAction") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnAction","hash":{},"data":data,"loc":{"start":{"line":34,"column":29},"end":{"line":34,"column":42}}}) : helper)))
    + "\"\n                name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"btnAction") || (depth0 != null ? lookupProperty(depth0,"btnAction") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnAction","hash":{},"data":data,"loc":{"start":{"line":35,"column":22},"end":{"line":35,"column":35}}}) : helper)))
    + "\"\n                type=\"button\">\n                <span class=\"txt\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"btnText") || (depth0 != null ? lookupProperty(depth0,"btnText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnText","hash":{},"data":data,"loc":{"start":{"line":37,"column":34},"end":{"line":37,"column":45}}}) : helper)))
    + "</span>\n            </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.backToPrevious",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":137},"end":{"line":3,"column":179}}}))
    + "</span></a>\n    </div>\n        <h1 class=\"landing-header\">"
    + alias2((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||container.hooks.helperMissing).call(alias1,"title",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":5,"column":35},"end":{"line":5,"column":56}}}))
    + "</h1>\n</div>\n\n<div class=\"alert-message\">\n    <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n</div>\n\n<div data-region=\"pageContent\"></div>\n\n\n<div data-region=\"recipientRegion\">\n</div>\n\n<div data-region=\"filterRegion\"></div>\n\n<div class=\"section-container\">\n    <div class=\"form-group\">\n        <div class=\"row\">\n            <div class=\"col-9\">\n                <div data-region=\"listBuilderRegion\"></div>\n            </div>\n        </div>\n    </div>\n</div>\n\n<div class=\"section-container\">\n        <div class=\"section section-footer\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"buttons") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":16},"end":{"line":39,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});