var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-container\">\n                <div>\n                        <div>\n                                <label class=\"main-label\">Entered On:</label>\n                                <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"enteredByTime") || (depth0 != null ? lookupProperty(depth0,"enteredByTime") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"enteredByTime","hash":{},"data":data,"loc":{"start":{"line":6,"column":40},"end":{"line":6,"column":57}}}) : helper)))
    + "</strong>\n                        </div>\n                        <div>\n                                <label class=\"main-label\">User ID:</label>\n                                <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"enteredBy") || (depth0 != null ? lookupProperty(depth0,"enteredBy") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"enteredBy","hash":{},"data":data,"loc":{"start":{"line":10,"column":40},"end":{"line":10,"column":53}}}) : helper)))
    + "</strong>\n                        </div>\n                        <div>\n                                <label class=\"main-label\">User Name:</label>\n                                <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"enteredByName") || (depth0 != null ? lookupProperty(depth0,"enteredByName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"enteredByName","hash":{},"data":data,"loc":{"start":{"line":14,"column":40},"end":{"line":14,"column":57}}}) : helper)))
    + "</strong>\n                        </div>\n                </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"modifiedBy") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":33,"column":23}}})) != null ? stack1 : "")
    + "        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div>\n                                <div>\n                                        <label class=\"main-label\">Modified On:</label>\n                                        <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"modifiedByTime") || (depth0 != null ? lookupProperty(depth0,"modifiedByTime") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modifiedByTime","hash":{},"data":data,"loc":{"start":{"line":22,"column":48},"end":{"line":22,"column":66}}}) : helper)))
    + "</strong>\n                                </div>\n                                <div>\n                                        <label class=\"main-label\">User ID:</label>\n                                        <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"modifiedBy") || (depth0 != null ? lookupProperty(depth0,"modifiedBy") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modifiedBy","hash":{},"data":data,"loc":{"start":{"line":26,"column":48},"end":{"line":26,"column":62}}}) : helper)))
    + "</strong>\n                                </div>\n                                <div>\n                                        <label class=\"main-label\">User Name:</label>\n                                        <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"modifiedByName") || (depth0 != null ? lookupProperty(depth0,"modifiedByName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modifiedByName","hash":{},"data":data,"loc":{"start":{"line":30,"column":48},"end":{"line":30,"column":66}}}) : helper)))
    + "</strong>\n                                </div>\n                        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"panel")||(depth0 && lookupProperty(depth0,"panel"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"panel","hash":{"theme":"panel-tertiary","collapsed":false,"label":"Audit"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":35,"column":10}}})) != null ? stack1 : "");
},"useData":true});