import notificationManager from 'system/notifications/manager';
import cache from 'system/utilities/cache';
import { appBus } from '@glu/core';

export default {
    deelHandleDestinationRequest(key) {
        this.listenToOnce(appBus, (`notification:child:${key}`), (data) => {
            let newKey = '';
            Object.keys(data || {}).find((index) => {
                if (index !== 'route' && index !== 'context') {
                    newKey = index;
                    return true;
                }
                return false;
            });
            cache.set(cache.makeKeyFromContext(newKey, data.context), data[newKey]);
            appBus.trigger('router:navigate', data.route, true);
        });
        notificationManager.notify(
            'bottomline',
            {
                key: 'bottomline.deeplink.ready',

                message: {
                    child: key,
                },
            },
        );
    },
};
