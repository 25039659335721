import CONSTANT from 'common/dynamicPages/api/constants';
import Collection from '@glu/core/src/collection';
import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import transform from 'common/util/transform';
import util from '@glu/core/src/util';
import validatorPatterns from 'system/validatorPatterns';
import { createMaskedInputView } from 'components/MaskedInput/MaskedInputWrapper';
import AccountLookup from '../service/bankCodeLookup';
import AccountPaymentDetailAPI from '../service/accountPaymentDetail';
import contactUtil from '../util/contactUtil';
import secondaryAccountFormTmpl from './secondaryAccountForm.hbs';

const secondAccountAllocationFixedMaxLength = 17;
const secondAccountAllocationPercentMaxLength = 4;

const SecondaryAccountForm = Layout.extend({
    template: secondaryAccountFormTmpl,

    ui: {
        $formGroups: '[class~="form-group"]',
        $allocationType: '[data-hook="allocation-type"]',
        $allocationAmount: '[data-hook="allocation-amount"]',
        $allocationAmountContainer: '[data-hook="allocation-amount-container"]',
        $allocationAmountCurrencyContainer: '[data-hook="allocation-amount-currency-container"]',
        $allocationLabel: '[data-hook="allocation-label"]',
        $beneBankCode: '[data-hook="secondary-bene-bank-code"]',
        $secondaryAccountPanel: '[data-name="secondary-account-panel"]',
    },

    events: {
        'change @ui.$allocationType': 'changeAllocationType',
        'change @ui.$beneBankCode': 'changeBeneBankCode',
    },

    initialize(options) {
        this.secondaryAccountFormValidators = {
            SECOND_BENE_ACCOUNTTYPE: {
                description: locale.get('bab.secondAccountType'),
                exists: true,
            },

            SECOND_BENE_ACCOUNTNUMBER: {
                description: locale.get('bab.secondAccountNumber'),
                exists: true,
                matches: validatorPatterns.NUMERIC_PATTERN,
            },

            SECOND_BENE_BANK_ID: {
                description: locale.get('bab.secondBank'),
                exists: true,
                matches: validatorPatterns.NUMERIC_PATTERN,
            },

            SECOND_ALLOCATION_TYPE: {
                description: locale.get('bab.allocationType'),
                exists: true,
            },

            SECOND_ACCOUNT_ALLOCATION: {
                description: locale.get('bab.secondAccountAllocation'),
                exists: true,
            },
        };
        this.mode = options.mode;
        this.model = options.model;
        this.currencyCollection = new Collection();
        this.paymentDetailsService = new AccountPaymentDetailAPI();
        this.secondaryAccountCodeLookup = new AccountLookup();
        this.secondaryAccountCodeLookup.setFieldName('wire');
        this.secondaryAccountExists = !!this.model.get('SECOND_BENE_BANK_NAME');
        this.collapsed = !this.secondaryAccountExists;

        /**
         * listener for isEmployee attribute changing to update display of secondary account
         * panel appropriately
         */
        this.model.on('change:isEmployee', this.employeeCheckboxToggled.bind(this));

        // initialize the validators to be an empty array
        this.model.validators = [];

        /*
         * flag to signify the first render cycle (prevents infinite loop if second
         * bene bank code already exists)
         */
        this.firstRender = true;
    },

    /**
     * @name showMaskedSecondaryAccount
     * @description display the maskedInput component for the secondary account number
     */
    showMaskedSecondaryAccount() {
        const fieldLabel = 'bab.account.number';
        const MaskedInputView = createMaskedInputView({
            initialValue: this.model.get('SECOND_BENE_ACCOUNTNUMBER') || '',
            cid: this.model.cid,
            name: 'SECOND_BENE_ACCOUNTNUMBER',
            maxLength: CONSTANT.ACCOUNT_MASKING.PROPERTIES.ACCOUNT_NUMBER_MAXLEN,
            fieldLabel: locale.get(fieldLabel),
            inputClassList: 'form-control',
            dataBind: true,
        });
        if (this.maskedInputRegion) {
            this.maskedInputRegion.show(new MaskedInputView());
        }
    },

    /**
     * resets the form allowing for a clean slate when adding more than one account
     * with a sub account
     */
    resetForm() {
        this.collapsed = true;
        this.secondaryAccountExists = false;
    },

    /**
     * Updates the model with the correct data from the selected bank routing code
     * @param {object} response - The response object for the 'GETPAYMENTDETAILINFOBANKCODE'
     * call made upon selecting a bank routing code
     */
    handleBeneBankCodeSuccess(response) {
        // Populate currency collection
        const currencyList = transform.hashToPairs(response.validCurrencyDescMap, 'name', 'label');

        this.currencyCollection.reset(currencyList);

        let configurationObject = {};

        /*
         * Set default currency only if its in the response.
         * Default currency will only be set if we change a bank code.
         * If we change the currency drop down itself, it wont be setting default
         * currency in the response.
         */
        if (response.defaultCurrency && !this.secondaryAccountExists) {
            configurationObject.SECOND_BENE_ACCOUNT_CURRENCY = response.defaultCurrency;
        }
        if (response.beneCountryCode) {
            configurationObject.SECOND_BENE_BANK_COUNTRY = response.beneCountryCode;
        }
        configurationObject = Object.assign(
            configurationObject,
            {
                SECOND_ACH_ONLY: response.achOnlyFlag,
                SECOND_WIRE_ONLY: response.rtgsOnlyFlag,
                SECOND_SECONDARY_CODE: response.secondCodeFieldFlag,
                SECOND_BENE_BANK_IDTYPE: response.sortCodeType,
            },
        );

        this.model.set(configurationObject);

        configurationObject = {};

        // Set bank information
        if (this.model.get('SECOND_BENEBANKIDENTRYMETHOD') !== 'FREEFORM') {
            if (response.bankSortCode && response.bankSortCode !== '') {
                configurationObject.SECOND_BENE_BANK_ID = response.bankSortCode;
            }
        } else {
            const parentTnum = this.model.get('PARENTNUM');
            if (parentTnum) {
                this.paymentDetailsService.setParentTnum(parentTnum);
            }
        }

        configurationObject.SECOND_BENE_BANK_NAME = response.bankName;
        configurationObject.SECOND_BENE_BANK_ADDRESS_1 = response.bankAddress1;
        configurationObject.SECOND_BENE_BANK_ADDRESS_2 = response.bankAddress2;
        configurationObject.SECOND_BENE_BANK_CITY = response.bankCity;
        configurationObject.SECOND_BENE_BANK_STATE = response.bankState;
        configurationObject.SECOND_BENE_BANK_COUNTRY = response.beneCountryCode;

        // remove any empty items within the configuration object
        Object.keys(configurationObject).forEach((item) => {
            if (!configurationObject[item]) {
                delete configurationObject[item];
            }
        });

        this.model.set(configurationObject);

        this.render();
    },

    /**
     * Listener function for selecting a bank routing code,
     * builds the paymentDetailsService call to gather more data
     * @param {Event} e - the triggered change event
     */
    changeBeneBankCode(e) {
        const bankCodesortcodetype = e.target.value;
        let bankCode = bankCodesortcodetype.substring(0, bankCodesortcodetype.indexOf(' - '));
        let sortCodeType = bankCodesortcodetype.substring(bankCodesortcodetype.indexOf(' - ') + 4);

        if (!bankCode) {
            bankCode = this.model.get('SECOND_BENE_BANK_ID');
        } else {
            this.model.set('SECOND_BANK_ACCOUNT_TEXT', this.ui.$beneBankCode.comboBox('data').text);
        }

        if (!sortCodeType) {
            sortCodeType = this.model.get('SECOND_BENE_BANK_IDTYPE');
        }

        /*
         * TODO: Replace paymentDetailsService below to use the lookup service call
         *  for accounttype Other (service call is still being developed)
         * Temporarily use the same service call for accounttype OTHER as IBAN - just
         * so we can get past the ui validation
         * this.model.set('BENE_ACCOUNTNUMBER', bankCode);
         */

        this.model.set('SECOND_BENE_BANK_IDTYPE', sortCodeType);
        this.paymentDetailsService.setBeneBankIDType(sortCodeType);
        this.paymentDetailsService.setAccountType(this.model.get('MODE'), this.model.get('ISIBANNOTDERIVED'));
        this.paymentDetailsService.setAccountCode(bankCode);
        this.paymentDetailsService.setProduct(null);
        this.paymentDetailsService.setContactType(contactUtil.getContactType(this.model));
        if (this.model.get('ISIBANNOTDERIVED')) {
            this.paymentDetailsService.setIBANNotDerived('1');
        }
        this.paymentDetailsService.send();
    },

    /**
     * Listener function for changing allocation type, updates the allocation
     * amount field appropriately
     * @param {Event} e - the triggered change event
     * @param {bool} reRender - boolean flag to indicate if this is being called
     * upon a re-render or an allocation
     *                          type change
     */
    changeAllocationType(e, reRender) {
        if (!e.target) {
            return;
        }

        this.ui.$allocationAmountContainer.show();
        this.model.removeValidator('SECOND_ACCOUNT_ALLOCATION');

        /*
         * if the form is being re-rendered (like when the bank routing
         * code is selected) don't zero the field
         */
        if (!reRender) {
            this.ui.$allocationAmount.val(0);
        }

        if (e.target.value === '1') {
            this.ui.$allocationAmount.prop('maxLength', secondAccountAllocationPercentMaxLength);
            this.ui.$allocationAmount.inputmask({ mask: '999%', placeholder: '' });
            this.ui.$allocationAmountCurrencyContainer.hide();
            this.ui.$allocationLabel.text(locale.get('bab.allocation.percent'));
            this.model.addValidator({
                SECOND_ACCOUNT_ALLOCATION: {
                    description: locale.get('bab.secondAccountAllocation'),
                    exists: true,
                    maxValue: 100,
                    minValue: 0,
                },
            });
        } else if (e.target.value === '2') {
            this.ui.$allocationAmount.prop('maxLength', secondAccountAllocationFixedMaxLength);
            this.ui.$allocationAmount.inputmask('number', { allowMinus: false });
            this.ui.$allocationAmountCurrencyContainer.show();
            this.ui.$allocationLabel.text(locale.get('bab.allocation.amount'));
            this.model.addValidator({
                SECOND_ACCOUNT_ALLOCATION: {
                    description: locale.get('bab.secondAccountAllocation'),
                    exists: true,
                    matches: validatorPatterns.AMOUNT_PATTERN,
                },
            });
        } else {
            this.ui.$allocationAmountContainer.hide();
        }
    },

    hasAddress() {
        if (this.model.get('SECOND_BENE_BANK_ADDRESS_1') || this.model.get('SECOND_BENE_BANK_ADDRESS_2')
            || this.model.get('SECOND_BENE_BANK_CITY') || this.model.get('SECOND_BENE_BANK_STATE')
            || this.model.get('SECOND_BENE_BANK_COUNTRY')) {
            return true;
        }
        return false;
    },

    /*
     * @param addClass {boolean} - dictates whether to ADD or REMOVE the
     * 'required' class from the secondary account form elements, also toggles validators
     */
    toggleRequired(addClass) {
        if (addClass) {
            this.model.addValidator(this.secondaryAccountFormValidators);
        } else {
            this.model.removeValidator(Object.keys(this.secondaryAccountFormValidators));
        }

        if (this.ui.$formGroups.selector) {
            this.ui.$formGroups.each((index, item) => {
                this.$(item).toggleClass('required', addClass);
            });
        }
    },

    /*
     * displays/hides various on screen elements based on form state and information available
     */
    showAndHideStuffOnLoad() {
        if (this.firstRender) {
            this.ui.$beneBankCode.trigger('change', { simulated: true });
        }

        // check for a pre-existing allocation type to hide/show the allocation input
        this.changeAllocationType({ target: this.ui.$allocationType[0] }, true);

        // create the masked input widget for secondary account number
        this.showMaskedSecondaryAccount();

        this.firstRender = false;
    },

    /*
     * if the user is not an employee, remove the secondary account panel and
     * clear any entered info there
     */
    employeeCheckboxToggled() {
        if (!this.model.get('isEmployee')) {
            this.collapsed = true;
            this.toggleRequired(false);
            this.model.set({
                SECOND_ACCOUNT_ALLOCATION: '',
                SECOND_ALLOCATION_TYPE: '',
                SECOND_BANK_ACCOUNT_TEXT: '',
                SECOND_BENE_ACCOUNTNUMBER: '',
                SECOND_BENE_ACCOUNTTYPE: '',
                SECOND_BENE_ACCOUNT_CURRENCY: '',
                SECOND_BENE_BANK_ADDRESS_1: '',
                SECOND_BENE_BANK_CITY: '',
                SECOND_BENE_BANK_COUNTRY: '',
                SECOND_BENE_BANK_ID: '',
                SECOND_BENE_BANK_IDTYPE: '',
                SECOND_BENE_BANK_NAME: '',
                SECOND_BENE_BANK_SORTCODETYPE: '',
                SECOND_BENE_BANK_STATE: '',
                SECOND_ACH_ONLY: '',
                SECOND_WIRE_ONLY: '',
                SECOND_SECONDARY_CODE: '',
            }, { silent: true });
        }
    },

    onRender() {
        const lookup = this.secondaryAccountCodeLookup;
        const self = this;
        this.ui.$beneBankCode.comboBox({
            dropdownAutoWidth: true,
            triggerChange: true,

            query: util.debounce((query) => {
                const rowsPerPage = (query.term.length < CONSTANT.COMBO_MIN_CHARS)
                    ? CONSTANT.COMBO_MIN_SIZE : CONSTANT.MAX_SERVER_ROWSPERPAGE;

                lookup.setStartRow(((query.page - 1) * rowsPerPage) + 1);
                lookup.setPageSize(rowsPerPage);
                lookup.setSearch(query.term);

                lookup.setSearch(query.term);
                lookup.send().then((result) => {
                    query.callback({
                        results: result.data,
                        more: ((query.page * rowsPerPage) < result.totalRows),
                    });
                });
            }, CONSTANT.COMBO_DEBOUNCE),

            initSelection(element, cb) {
                const secondBeneBankId = self.model.get('SECOND_BENE_BANK_ID');
                const secondBeneBankName = self.model.get('SECOND_BENE_BANK_NAME');
                if (secondBeneBankId && secondBeneBankName) {
                    cb({
                        id: element.val(),
                        text: `${secondBeneBankId} - ${secondBeneBankName}`,
                    });
                } else {
                    cb();
                }
            },
        });

        // listeners for showing/hiding the secondary account panel
        this.ui.$secondaryAccountPanel.bind('show.bs.collapse', () => {
            this.collapsed = false;
            this.toggleRequired(true);
        });
        this.ui.$secondaryAccountPanel.bind('hide.bs.collapse', () => {
            this.collapsed = true;
            this.toggleRequired(false);
        });

        // listener for selecting an account
        this.listenTo(this.paymentDetailsService, 'success', this.handleBeneBankCodeSuccess);

        // if the panel is open, apply validators appropriately
        if (!this.collapsed) {
            this.toggleRequired(true);
        }

        // display/hide various on screen elements based on form state and information available
        this.showAndHideStuffOnLoad();
    },

    templateHelpers() {
        const self = this;
        return {
            collapsed() {
                /*
                 * must be a function since it'll be evaluated as a
                 * sub-expression in the template
                 */
                return self.collapsed;
            },

            currencies: this.currencyCollection ? this.currencyCollection.toJSON() : {},
            hasAddress: this.hasAddress(),
            isEmployee: self.model.get('isEmployee'),
        };
    },
});

export default SecondaryAccountForm;
