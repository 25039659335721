var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-primary submit\"  data-action=\"edit\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"segment.btn.edit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":48,"column":93},"end":{"line":48,"column":122}}}))
    + "</button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-primary submit\"  data-action=\"approve\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"segment.btn.approve",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":51,"column":96},"end":{"line":51,"column":128}}}))
    + "</button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-primary submit\"  data-action=\"delete\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"segment.btn.delete",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":54,"column":95},"end":{"line":54,"column":126}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <div class=\"page-header-wrapper\">\n        <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.header",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":62}}}))
    + "</h1>\n\n        <div class=\"page-header\" data-region=\"header\"></div>\n    </div>\n\n    <section class=\"section section-container\" role=\"navigation\">\n        <div class=\"section-body\">\n            <ul class=\"NavTabs nav nav-tabs is-open\" data-hook=\"getNavTabs\" role=\"tablist\">\n                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link\"\n                        href=\"/SEGMENTATION/viewServices\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.menu.services",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":58},"end":{"line":14,"column":92}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"NavTabs-item is-active\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link\"\n                        href=\"/SEGMENTATION/viewProfile\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.menu.defaults",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":57},"end":{"line":20,"column":91}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link\"\n                        href=\"/SEGMENTATION/addPaymentLimits\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.menu.limits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":62},"end":{"line":26,"column":94}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link\"\n                        href=\"/SEGMENTATION/externalWidgets\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.menu.bankwidgets",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":61},"end":{"line":32,"column":98}}}))
    + "\n                    </a>\n                </li>\n                                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link\"\n                        href=\"/SEGMENTATION/elearning\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.menu.elearning",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":55},"end":{"line":38,"column":90}}}))
    + "\n                    </a>\n                </li>\n            </ul>\n                        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n            <div class=\"pageRegion remove-section-styles\" region=\"pageRegion\"></div>\n         </div>\n        <div>\n            <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasModifyEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":16},"end":{"line":49,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasApproveEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":16},"end":{"line":52,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasDeleteEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":16},"end":{"line":55,"column":23}}})) != null ? stack1 : "")
    + "                <button type=\"button\" class=\"btn btn-link\" data-action=\"back\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.btn.back",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":56,"column":78},"end":{"line":56,"column":107}}}))
    + "</button>\n            </div>\n        </div>\n    </section>\n</div>\n";
},"useData":true});