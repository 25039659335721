import { postData } from 'common/util/services';
import services from 'services';

const createPayload = data => ({
    item: [
        {
            name: 'USERGROUP',
            value: data.companyList[0]?.id,
        },
        {
            name: 'PIGID',
            value: data.pigId,
        },
        {
            name: 'PIGIDNICKNAME',
            value: data.nickname,
        },
    ],
});
export const createPaymentGroupId = data => postData(services.generateUrl('achControlTotalMaintenance/validateAndSave'), createPayload(data));
export const modifyPaymentGroupId = data => postData(services.generateUrl('achControlTotalMaintenance/modifyRecord'), createPayload(data));
