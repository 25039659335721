import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import locale from '@glu/locale';
import alert from '@glu/alerts';
import scroll from 'common/util/scroll';
import services from 'services';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import moment from 'moment';
import store from 'system/utilities/cache';
import CheckInquiryList from 'app/payments/views/checkInquiry/checkInquiryList';
import constants from 'app/payments/constants';
import filterApi from 'common/dynamicPages/api/filters';
import viewHelper from 'common/dynamicPages/api/viewHelper';
import CheckInquiryTypeCollection from 'app/payments/collections/checkInquiry/checkInquiryTypes';
import MultipleEntryFieldsView from 'app/payments/views/checkInquiry/multipleEntry/multipleEntryFieldsView';
import appConfigParams from 'system/webseries/models/applicationConfiguration';
import loadingModalTmpl from 'common/templates/loadingModal.hbs';
import userInfo from 'etc/userInfo';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import { getState, resetFormState } from 'common/util/reduxUtil';
import checkInquiryTmpl from './checkInquiry.hbs';

const CMINQPRODUCTCODE = 'CM';
const CMINQFUNCTIONCODE = 'INST';

const CheckInquiry = Layout.extend({
    loadingTemplate: loadingModalTmpl,
    template: checkInquiryTmpl,
    className: 'check-inquiry',

    initialize(options) {
        this.model = options.model || new Model();
        this.checkInquiryType = null;

        this.options = {
            menuCategory: 'PAYMENTS',
            serviceName: 'dummy',

            contextDef: {
                serviceName: 'dummy',
            },

            mode: 'insert',
            tabId: options.tabId || constants.CHECK_INQUIRY.TABS.REAL_TIME,
        };

        this.history = this.options.tabId === constants.CHECK_INQUIRY.TABS.HISTORY;
        this.filterData = store.get('cm_inq_searchFields');
        this.storedfilterId = store.get('cm_inq_filterId');
        this.filterModel = store.get('cm_inq_filterModel');
        this.options.hasBulkCheckDownload = false;

        store.unset('cm_inq_searchFields');
        store.unset('cm_inq_filterId');
        store.unset('cm_inq_filterModel');

        this.setNotificationData({
            title: constants.CHECK_INQUIRY.URL.PATH,
        });
    },

    ui: {
        typeSelect: '[data-hook="type-select"]',
        searchButton: '#checkSearch',
        $datepicker: '.date-input',
        $message: '.checkInquiryMessage',
        $checkInquiryMsg: '[data-hook="checkInquiryMsg"]',
    },

    events: {
        'click @ui.searchButton': 'search',
    },
    modelEvents: {
        'change:type': 'typeChange',
    },

    regions: {
        checkInquiryList: '#checkInquiryList',
    },

    enableSearchButton() {
        this.ui.searchButton.prop('disabled', false);
        this.ui.searchButton.attr('aria-busy', false);
    },

    getFilterFieldValues(filterData, fieldName) {
        let value = [];
        if (filterData && fieldName) {
            const filterField = util.find(filterData, item => item.fieldName === fieldName);
            if (filterField) {
                value = filterField.fieldValue;
            }
        }
        return value;
    },

    loadRequiredData() {
        // get the check inquiry types, if any.
        const checkInquiryTypesPromise = this.checkInquiryTypesPromise();

        const bulkCheckDownloadPromise = this.bulkCheckDownloadPromise();

        const options = {
            context: {
                productCode: 'CM',
                functionCode: 'INST',
                typeCode: 'CMINQ',
            },
        };

        const dateRangePromise = viewHelper.getDateRangePromise(options);

        Promise.all([
            checkInquiryTypesPromise,
            dateRangePromise,
            bulkCheckDownloadPromise,
        ]).then((results) => {
            const [checkInquiryTypes, dateModel, hasBulkCheckDownload] = results;
            this.checkInquiryTypes = checkInquiryTypes;
            store.set('checkInquiryTypes', this.checkInquiryTypes);
            this.minDate = dateModel.get('minDate');
            this.maxDate = dateModel.get('maxDate');
            this.startDate = dateModel.get('startDate');
            this.endDate = dateModel.get('endDate');
            this.maxDaysPerSearch = dateModel.get('maxDaysPerSearch');

            /**
             * Determine if user can download images via bulk check download.
             * It will be used in check inquiry list to show a modal or go
             * directly to the export listview feature.
             */
            this.options.hasBulkCheckDownload = hasBulkCheckDownload;

            this.setHasLoadedRequiredData(true);
            this.render();
        });
    },

    getCacheName() {
        return `model-${this.filterContextDef.serviceName}-${CMINQPRODUCTCODE}-${CMINQFUNCTIONCODE}`;
    },

    onRender() {
        let model;
        let filterId;
        let checkInquiryType;
        if (this.hasLoadedRequiredData()) {
            if (this.history) {
                this.showFilters(constants.CHECK_INQUIRY.HISTORY_FILTER);
            } else if (this.checkInquiryTypes.length === 0) {
                this.showFilters(null, this.filterData);
            } else {
                this.ui.typeSelect.comboBox({
                    // hide the search box
                    minimumResultsForSearch: -1,
                });
                // check if there is only one check inquiry type, if so select it
                if (this.checkInquiryTypes.length === 1) {
                    [model] = this.checkInquiryTypes.models;
                    filterId = model.get('filterId');
                    this.checkInquiryType = model.get('id');
                    this.checkInquiryListRegion.reset();
                    this.filterRegion.reset();
                    this.ui.searchButton.hide();
                    /*
                     * need to update/change combo box  - val
                     * (will trigger change event to show correct filters)
                     */
                    this.ui.typeSelect.val(model.get('id')).trigger({
                        type: 'change',
                        val: model.get('id'),
                    });
                }
                if (this.filterData) {
                    checkInquiryType = this.getFilterFieldValues(this.filterData, 'CHECKSTATUSCODE');
                    model = this.checkInquiryTypes.get(checkInquiryType[0]);
                    if (model) {
                        filterId = model.get('filterId');
                        if (checkInquiryType && checkInquiryType.length > 0) {
                            this.model.set('type', checkInquiryType[0]);
                            [this.checkInquiryType] = checkInquiryType;
                        }
                        this.showFilters(filterId, this.filterData);
                    }
                } else {
                    this.ui.searchButton.hide();
                }
            }
            this.notifyPageLoaded();
            const { pageContext } = getState();
            if (pageContext.previousRoute === constants.PLACESTOP_PAGE) {
                this.refreshAfterStopPaymentDetail();
            }
        } else {
            this.loadRequiredData();
        }
    },

    /**
     * refresh the check inquiry screen after coming from the stop payments (mdf/react) detail page
     */
    refreshAfterStopPaymentDetail() {
        resetFormState();
        this.preventDefaultingDateRange = true;
        this.onFiltersLoaded(this.filterData);
        this.search();
    },

    checkInquiryTypesPromise() {
        return new Promise((resolve, reject) => {
            const checkInquiryTypes = new CheckInquiryTypeCollection();
            checkInquiryTypes.fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    bulkCheckDownloadPromise() {
        const request = {
            functionCode: 'INST',
            actionMode: 'BULKIMG',
            productCode: 'CM',
            typeCode: 'DOWNLOAD',
        };
        return new Promise((resolve, reject) => {
            const url = services.generateUrl('accessService/hasAccess');
            http.post(url, request, (response) => {
                resolve(response);
            }, (e) => {
                reject(e);
            });
        });
    },

    typeChange() {
        let filterId;
        const type = this.model.get('type');
        if (type) {
            this.checkInquiryType = type;
            const model = this.checkInquiryTypes.get(this.checkInquiryType);
            filterId = model.get('filterId');
            store.set('typeCode', model.get('entitleTypeCodeData'));
            store.set('functionCode', model.get('functionCodeData'));
        }

        this.checkInquiryListRegion.reset();
        this.filterRegion.reset();
        this.multipleEntryRegion.reset();
        this.ui.searchButton.hide();
        this.showFilters(filterId);
        this.alertRegionForSearch.close();
    },

    showFilters(filterId, filterData) {
        const filterContextDef = {};
        filterContextDef.serviceName = '/advanceFilter';
        filterContextDef.productCode = CMINQPRODUCTCODE;
        filterContextDef.functionCode = CMINQFUNCTIONCODE;
        filterContextDef.typeCode = constants.CHECK_INQUIRY.TYPECODE.CHECKINQUIRYOLD;
        this.filterContextDef = filterContextDef;
        this.filterId = filterId || this.storedfilterId;
        this.ui.$checkInquiryMsg.removeClass('hidden');
        const requestData = {
            filterId: this.filterId || 20503,

            typeInfo: {
                productCode: CMINQPRODUCTCODE,
                functionCode: this.history ? constants.CHECK_INQUIRY.FUNCTIONCODE : store.get('functionCode'),
                typeCode: this.history ? constants.CHECK_INQUIRY.TYPECODE.CHECKINQHISTORY : store.get('typeCode'),
            },
        };

        store.unset(this.getCacheName());
        store.set(this.getCacheName(), this.model);

        return filterApi.getFilters(requestData, this)
            .then(() => this.onFiltersLoaded(filterData));
    },

    /**
     * Ensure the signle select radio button comes first
     * @param {Array} filterFields
     */
    radioChoicesSortComparator(filterFields) {
        const serialNumberRadio = util.findWhere(filterFields, { name: 'SINGLEMULTISERIALNUMRADIO' });

        if (serialNumberRadio) {
            if (serialNumberRadio.choices[0].value !== 'S') {
                // Rearrange the  radio buttons if multi select came first
                const saveChoice = serialNumberRadio.choices.shift();
                serialNumberRadio.choices.push(saveChoice);
            }
        }
    },

    /**
     * Callback to setup mdf Filters section after loaded
     */
    onFiltersLoaded(filterData) {
        if (this.filterRegion.currentView) {
            this.listenToOnce(this.filterRegion.currentView, 'item:rendered', () => {
                this.$el.find('div[data-region="filterRegion"] fieldset.section.section-container').removeClass('section-container');
                // move the title into the filterRegion
                this.ui.$message.detach().removeClass('hidden').prependTo('div[data-region="filterRegion"] div.form-column.orphanFields.col-md-12');

                if (this.filterModel) {
                    this.filterRegion.currentView.model.set(this.filterModel);

                    // run functionality to change dependent dropdown
                    this.triggerSelectChange();
                }

                // default value for certain fields
                this.filterRegion.currentView.model.set('SINGLEMULTISERIALNUMRADIO', 'S');

                this.setFilterValidators(this.filterRegion.currentView.model);

                this.listenTo(
                    this.filterRegion.currentView.model,
                    'change:SINGLEMULTISERIALNUMRADIO',
                    this.setupSerialNumEntryByMode,
                );

                /*
             * listener to edit validators based on SERIALNUMBER
             * and AMOUNT input settings chosen
             */
                this.listenTo(
                    this.filterRegion.currentView.model,
                    'change:SERIALNUMBER-equality change:AMOUNT-equality change:SINGLEMULTISERIALNUMRADIO',
                    this.setFilterValidators,
                );

                this.filterRegion.currentView.$('#ACCOUNTFILTER').change(() => this.enableSearchButton());

                this.ui.searchButton.show();

                if (this.checkInquiryType !== 'STOPPED' && !this.history && !this.preventDefaultingDateRange) {
                    this.defaultDateRange();
                }

                if (filterData) {
                    this.search();
                }
            });
        }
    },

    search() {
        const filterModel = this.filterRegion.currentView
            ? this.filterRegion.currentView.model : null;
        const isMultipleEntry = filterModel.get('SINGLEMULTISERIALNUMRADIO') === 'M';
        const multipleEntryView = this.multipleEntryRegion.currentView;

        if (filterModel.get('AMOUNT') === '0.00' && filterModel.get('AMOUNT-equality') !== 'BETWEEN') {
            filterModel.set('AMOUNT', '');
        }
        // Validate all the date inputs present in Check Inquiry if filled in
        if (filterModel.get('VALUE_DATE') && !this.validateDateInput(filterModel, 'VALUE_DATE')) {
            return;
        }
        if (filterModel.get('TRAN_DATE') && !this.validateDateInput(filterModel, 'TRAN_DATE')) {
            return;
        }
        if (filterModel.get('STOP_EFFECTIVE_DATE') && !this.validateDateInput(filterModel, 'STOP_EFFECTIVE_DATE')) {
            return;
        }
        if (filterModel.get('ENTERED_TIMESTAMP') && !this.validateDateInput(filterModel, 'ENTERED_TIMESTAMP')) {
            return;
        }
        if (filterModel.get('STOP_EXPIRATION_DATE') && !this.validateDateInput(filterModel, 'STOP_EXPIRATION_DATE')) {
            return;
        }

        const additionalSearchFields = filterModel
            ? filterApi.gatherFilterData(filterModel) : (this.filterData || []);
        let viewId;
        let checkInqType;
        let model;
        let serviceName = 'cm/listView/checkInquiry';
        let isHistory = false;

        if (this.checkInquiryType) {
            checkInqType = this.checkInquiryType === 'PADINQ' ? ['PADSTOP'] : [this.checkInquiryType];
            model = this.checkInquiryTypes.get(this.checkInquiryType);
            viewId = model.get('viewId');
        } else if (this.history) {
            checkInqType = [constants.CHECK_INQUIRY.TABS.HISTORY];
            viewId = constants.CHECK_INQUIRY.CHECK_INQUIRY_HISTORY;
            serviceName = 'cm/listView/checkInquiryHistory';
            isHistory = true;
        }

        if (checkInqType) {
            additionalSearchFields.push({
                operator: 'EQ',
                dataType: 'text',
                fieldName: 'CHECKSTATUSCODE',
                fieldValue: checkInqType,
            });
        }

        const options = {
            requestParameters: {
                item: [{
                    name: 'initialRequest',
                    value: 'true',
                }],
            },

            additionalSearchFields,
            viewId: viewId || 0,
            filterId: this.filterId || false,
            filterModel,
            serviceName,
            isHistory,
            hasBulkCheckDownload: this.options.hasBulkCheckDownload,
        };

        if (util.pluck(additionalSearchFields, 'fieldName').indexOf('ACCOUNTFILTER') === -1) {
            this.showAlertOnSearch(locale.get('cm.inquiry.AccountNumberMandatory'));
            return;
        }

        // NH-26044: make sure all mandatory fields are filled in.
        const missingFields = filterApi.validateFilterList(
            additionalSearchFields,
            filterModel.jsonData.fieldInfoList,
        );
        if (missingFields.length > 0) {
            this.showAlertOnSearch(locale.get('filter.validation.CheckInquiry.missingMandatoryFields', missingFields.join(', ')));
            return;
        }

        if (!this.validateSearchFieldCount(additionalSearchFields)) {
            this.showAlertOnSearch(locale.get('filter.validation.CheckInquiry'));
            return;
        }

        filterModel.validators = this.updateOperandValidators(filterModel);

        if (!filterModel.isValid()
            || (isMultipleEntry && !multipleEntryView.validateInputs())) {
            this.showAlertOnSearch(locale.get('PS.fixErrors'));
            return;
        }
        options.dynamicDatePayload = [{
            fieldName: 'STOP_EXPIRATION_DATE',
            type: 'FUTURE',
            field: 'FUTURE',
        }];
        const checkInquiryList = new CheckInquiryList(options);

        this.ui.searchButton.prop('disabled', true);
        this.ui.searchButton.attr('aria-busy', true);

        this.listenTo(checkInquiryList, 'checkInquiryGrid:loaded', this.enableSearchButton);

        this.listenTo(checkInquiryList, 'checkInquiryGrid:error', this.enableSearchButton);

        this.alertRegionForSearch.close();
        this.checkInquiryListRegion.show(checkInquiryList);
    },

    /**
     * @param {array} searchFields
     * @return {Boolean}
     * Will validate the query searchfields, and check if at least 2 valid fields
     * exist for the search
     */
    validateSearchFieldCount(searchFields) {
        const fieldCount = util.filter(searchFields, field => field.fieldName !== 'CHECKSTATUSCODE' && field.fieldName !== 'SINGLEMULTISERIALNUMRADIO').length;
        const interim = parseInt(serverConfigParams.get('checkInquiryRequiredSearchFields'), 10);
        const sanitizedTest = Number.isNaN(interim) ? 2 : interim;

        return fieldCount >= sanitizedTest;
    },

    templateHelpers() {
        const self = this;
        return {
            inquiryFilter() {
                return self.checkInquiryTypes.toJSON();
            },

            isHistory: this.history,

            isInquiryFilterAvailable() {
                if (self.options.tabId === constants.CHECK_INQUIRY.TABS.REAL_TIME) {
                    return self.checkInquiryTypes.length > 0;
                }
                return false;
            },
        };
    },

    // default the starting range date to 30 days
    defaultDateRange() {
        const dates = this.filterRegion.currentView.$('.input-date');
        let dp;

        const filterModel = this.filterRegion.currentView
            ? this.filterRegion.currentView.model : null;
        for (let i = 0; i < dates.length; i += 1) {
            const key = this.$(dates[i]).attr('name');

            dp = this.$(dates[i]).data('daterangepicker');
            dp.setStartDate(this.startDate);
            dp.setEndDate(this.endDate);
            if (filterModel !== null) {
                // TODO: We should not directly set _datePickerValues
                // eslint-disable-next-line
                filterModel._datePickerValues[key] = [dp.startDate, dp.endDate];
            }
        }
    },

    /**
     * @method validateDateInput
     * @param {Model|null} filterModel
     * @param {string} dateName -- name of dateinput to validateDateInput
     * @return {boolean} if valid
     * Validates a date input with the name value passed in
     */
    validateDateInput(filterModel, dateName) {
        const selDate = filterModel.get(dateName);
        if (filterModel.fieldData[dateName] && filterModel.fieldData[dateName].fieldUIType === 'DATEFILTER' && selDate.indexOf('-') === -1) {
            this.showAlertOnSearch(locale.get('system.invalidDateRange'));
            return undefined;
        }
        const date = selDate.split(' - ');
        const dateInput = this.filterRegion.currentView.$(`.input-date[name=${dateName}]`);
        const dp = this.$(dateInput).data('daterangepicker');
        if (dp) {
            dp.setStartDate(moment(date[0], userInfo.getDateFormat()));
            dp.setEndDate(moment(date[1], userInfo.getDateFormat()));
            if (filterModel !== null) {
                // TODO: We should not directly set _datePickerValues
                // eslint-disable-next-line
                filterModel._datePickerValues[dateName] = [dp.startDate, dp.endDate];
            }
            // validate startDate & endDate exist
            if (dp.startDate && dp.endDate) {
                if (dp.isInvalidDay(dp.startDate) || dp.isInvalidDay(dp.endDate)) {
                    this.showAlertOnSearch(locale.get('system.invalidDateRange'));
                    return false;
                }
                if (dp.startDate > dp.endDate) {
                    this.showAlertOnSearch(locale.get('system.invalidDateRange'));
                    return false;
                }

                // validate the day range for search not exceeding allowed number of days
                const startDate = moment(dp.startDate);
                const endDate = moment(dp.endDate);

                if (endDate.diff(startDate, 'days') > this.maxDaysPerSearch) {
                    this.showAlertOnSearch(locale.get('PS.Search.DateRange.Exceeded').replace('{0}', this.maxDaysPerSearch));
                    return undefined;
                }
            }
        }

        return true;
    },

    /**
     * @param {Model} filterModel
     * @return {Object} - updated validator object
     * Returns a new validator object with validation rules for fields that have
     * operand cases.
     * If operand is "Between" and at least one compared value is input,
     * will set to validate if both compared values exists
     * but if no compared values are inputted, will not place or remove exists
     * validators
     */
    updateOperandValidators(filterModel) {
        const validators = util.extend({}, filterModel.validators);
        const fields = ['AMOUNT', 'SERIALNUMBER'];
        fields
            .filter(fieldName => validators[fieldName] !== undefined)
            .forEach((fieldName) => {
                const hasValues = (filterModel.get(fieldName) || filterModel.get(`${fieldName}2`));

                /*
                 * set exists validator if "BETWEEN" operand and
                 * at least one of the compared inputs has a value
                 */
                if (filterModel.get(`${fieldName}-equality`) === 'BETWEEN' && hasValues) {
                    validators[fieldName].exists = true;
                    validators[`${fieldName}2`].exists = true;
                } else if (validators[fieldName].exists) {
                    /*
                     * no longer needs check for both compared values to exist if no
                     * values are inputted to begin with
                     */
                    delete validators[fieldName].exists;
                    delete validators[`${fieldName}2`].exists;
                }
            });
        return validators;
    },

    /**
     * @param {Model} filterModelParam
     * Sets the Amount and Serial Number validators on the filter section based on what
     * amount and serial inputs exist and have been chosen
     */
    setFilterValidators(filterModelParam) {
        const filterModel = filterModelParam;
        const { fieldData } = filterModel;
        const validateList = ['AMOUNT', 'VALUE_DATE', 'TRAN_DATE', 'STOP_EFFECTIVE_DATE'];
        const fields = filterModel.get('SINGLEMULTISERIALNUMRADIO') !== 'M'
            || !util.isEmpty(filterModel.get('SERIALNUMBER'))
            ? [...validateList, 'SERIALNUMBER']
            : validateList;

        filterModel.validators = fields
            .filter(fieldName => filterModel.get(fieldName) !== undefined
                                && fieldData[fieldName] !== undefined)
            .reduce((valObjParam, fieldName) => {
                const valObj = valObjParam;
                if (fieldData[fieldName].fieldUIType === 'DATEFILTER') {
                    valObj[fieldName] = {
                        description: fieldData[fieldName].fieldLabel,
                        matchesDatePattern: userInfo.get('dateFormat'),
                    };
                } else {
                    if (fieldName === 'SERIALNUMBER') {
                        filterModel.serialNumberLimit = fieldData[fieldName].maxLen;
                    }
                    valObj[fieldName] = {
                        description: fieldData[fieldName].fieldLabel,
                        minValue: 0,
                        ...((fieldName === 'SERIALNUMBER') ? { isNumeric: true, serialNumberLimit: true, limitValue: fieldData[fieldName].maxLen } : {}),
                    };
                }

                /**
                 * if there is an in between case add the second
                 * new value to model to avoid any validation error
                 *
                 */
                if (filterModel.get(`${fieldName}-equality`) === 'BETWEEN') {
                    valObj[`${fieldName}2`] = { ...valObj[fieldName] };
                    if (!filterModel.get(`${fieldName}2`)) {
                        filterModel.set(`${fieldName}2`, '');
                    }
                }

                return valObj;
            }, {});

        // refresh validation state to rid of old validation messages
        filterModel.isValid();
    },

    /**
     * @method triggerSelectChange description
     * Cause change event on select input fields to trigger functionality
     * related to dependent MDF fields
     */
    triggerSelectChange() {
        const filterSelects = this.$('[name="SERIALNUMBER-equality"], [name="AMOUNT-equality"]');

        filterSelects.trigger('change');
    },

    /**
     * @method showAlertOnSearch
     * @param {string} message -- alert message content
     * Shows an alert message related to the search action, with passed in message
     */
    showAlertOnSearch(message) {
        this.alertView = alert.danger(
            message,
            {
                canDismiss: false,
            },
        );
        this.alertRegionForSearch.show(this.alertView);
        scroll.scrollToFirstError();
    },

    /**
     * @method setupSerialNumEntrybyMode
     * @param {Model} filterModel
     * Will handle hiding and showing correct fields in form when user toggles
     * the serial number entry options
     */
    setupSerialNumEntryByMode(filterModel) {
        const isMultipleEntry = (filterModel.get('SINGLEMULTISERIALNUMRADIO') === 'M');
        const mdfFieldsRef = this.filterRegion.currentView.pageForm.fields;
        const requiredFields = ['ACCOUNTFILTER', 'SINGLEMULTISERIALNUMRADIO'];
        const otherFields = util.difference(Object.keys(mdfFieldsRef), requiredFields);

        // set necessary values
        this.filterRegion.currentView.$el.toggleClass('multipleEntrySerial', isMultipleEntry);
        filterModel.set({
            SERIALNUMBER: '',
            'SERIALNUMBER-equality': (isMultipleEntry) ? 'IN' : 'EQ',
            VALUE_DATE: '',
            PAYEE: '',
            AMOUNT: '',
        });

        /*
         * toggle fields to show based on the entry method chosen
         * if multiple then hide all other fields from sight and show Multiple Entry Region
         */
        otherFields.forEach(field => mdfFieldsRef[field].$el.closest('.field-container').toggle(!isMultipleEntry));
        this.toggleMultipleEntryField(isMultipleEntry, filterModel);

        if (!isMultipleEntry) {
            util.defer(() => this.defaultDateRange());
        }
    },

    /**
     * @method toggleMultipleEntryField
     * @param {boolean} isMultipleEntry
     * @param {Model} filterModel
     */
    toggleMultipleEntryField(isMultipleEntry, filterModel) {
        if (isMultipleEntry) {
            this.multipleEntryRegion.show(new MultipleEntryFieldsView({
                parentView: this,
                parentModel: filterModel,
                fieldName: 'SERIALNUMBER',
                fieldLabel: locale.get('CM.SerialNumbers'),

                modelValidator: {
                    SERIALNUMBER: {
                        description: filterModel.fieldData.SERIALNUMBER.fieldLabel,
                        isNumeric: true,
                        minValue: 0,
                        limitValue: filterModel?.serialNumberLimit,
                        serialNumberLimit: true,
                    },
                },

                maxEntries: appConfigParams.getValue('CMINQ', 'CIMaxSerialNumbers'),
            }));
        } else {
            this.multipleEntryRegion.reset();
        }
    },
});

export default CheckInquiry;
