export default {
  advanced: 'Advanced',
  anErrorOccurredMsg: 'An error occurred while processing your request. Please contact your local administrator.',
  anErrorOccurredTitle: 'An error occurred',
  apply: 'Apply',
  cancel: 'Cancel',
  clear: 'Clear',
  clearAll: 'Clear All',
  configure: 'Configure',
  day: 'Day',
  default: 'Default',
  details: 'Details',
  display: 'Display',
  done: 'Done',
  filter: 'Filter',
  loading: 'Loading...',
  loadMore: 'Load More', // mobile grid. GLU-852
  loadingUiComponent: 'Loading UI Component',
  no: 'No',
  // number: 'number', // looks like not used anywhere.
  ok: 'OK',
  // remove: 'Remove', // looks like not used anywhere
  save: 'Save',
  selected: 'Selected', // used in glu-grid and glu-flex-dropdown
  selectRow: 'Select Row', // mobile grid. GLU-852
  'validator.beginsWith': '<%= description %> must start with <%= beginsWith %>',
  'validator.contains': '<%= description %> must contain <%= contains %>',
  'validator.emailAddress': '<%= description %> must be a valid email address',
  'validator.endsWith': '<%= description %> must end with <%= endsWith %>',
  'validator.equalTo': '<%= description %> must be equal to <%= equalTo %>',
  'validator.exists': '<%= description %> is required',
  'validator.isEmailAddress': '<%= description %> must be a valid email address',
  'validator.isGreaterThan': '<%= description %> must be greater than <%= isGreaterThan %>',
  'validator.isLessThan': '<%= description %> must be less than <%= isLessThan %>',
  'validator.isNumeric': '<%= description %> must be a number',
  'validator.isUnique': '<%= description %> must have a unique value',
  'validator.matches': '<%= description %> must match our requirements',
  'validator.matchesDatePattern': '<%= description %> must be a valid date',
  'validator.maxLength': '<%= description %> must be less than <%= maxLength %> characters long',
  'validator.maxValue': '<%= description %> must be no more than <%= maxValue  %>',
  'validator.minLength': '<%= description %> must be at least <%= minLength %> characters',
  'validator.minValue': '<%= description %> must be at least <%= minValue  %>',
  'validator.notSameValue': '<%= description %> should not match <%= notSameValue %>',
  'validator.oneOf': '<%= description %> must be one of <%= oneOf %>',
  'validator.range': '<%= description %> must be between <%= range[0] %> and <%= range[1] %>',
  'validator.rangeLength': '<%= description %> must be between <%= rangeLength[0] %> and <%= rangeLength[1] %> characters long',
  'validator.sameValue': '<%= description %> does not match <%= sameValue %>',
  'validator.us10DigitPhone': '<%= description %> must be a valid 10 digit US phone number',
  'validator.usZipCode': '<%= description %> must be a valid zip or zip+4 code',
  yes: 'Yes',
  update: 'Update'
};

