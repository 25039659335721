import React, { Fragment } from 'react';
import Popover from '@glu/popover-react';
import PropTypes from 'prop-types';
import { Button, TERTIARY } from '@glu/buttons-react';
import { AccessibilityText } from '@glu/utilities-react';
import locale from '@glu/locale';
import {
    CaretLeftIcon, CaretRightIcon, CircleNextIcon, CircleSolidIcon,
} from '@glu/icons-react';

const Carousel = ({
    classes, pages, activePageIndex, onChange,
}) => {
    const withTooltip = (icon, currencyCode) =>
        (
            <Popover
                className={classes.tooltip}
                content={currencyCode}
                openOnHover
            >
                <span>{icon}</span>
            </Popover>
        );

    return (
        <div className={classes.carousel}>
            <Button
                className={classes.arrow}
                variant={TERTIARY}
                onClick={() => (
                    onChange(activePageIndex > 0 ? activePageIndex - 1 : pages.length - 1)
                )}
            >
                <AccessibilityText>{locale.get('previousPage')}</AccessibilityText>
                <CaretLeftIcon noIconWrapper />
            </Button>
            {pages.map(({ id, currencyCode }, index) => (
                <Fragment key={id || index}>
                    <Button
                        key={id || index}
                        variant={TERTIARY}
                        onClick={() => onChange(index)}
                        className={classes.circle}
                    >
                        <AccessibilityText>{`${currencyCode} ${index}`}</AccessibilityText>
                        {activePageIndex === index
                            ? withTooltip(<CircleSolidIcon noIconWrapper />, currencyCode)
                            : withTooltip(
                                <CircleNextIcon className={classes.inactive} />,
                                currencyCode,
                            )
                        }
                    </Button>
                </Fragment>
            ))}
            <Button
                className={classes.arrow}
                variant={TERTIARY}
                onClick={() => (
                    onChange(activePageIndex < pages.length - 1 ? activePageIndex + 1 : 0)
                )}
            >
                <AccessibilityText>{locale.get('nextPage')}</AccessibilityText>
                <CaretRightIcon noIconWrapper />
            </Button>
        </div>
    );
};

Carousel.propTypes = {
    /** Styles */
    classes: PropTypes.shape().isRequired,

    /** Document pages */
    pages: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
    })).isRequired,

    /** Index of the active page */
    activePageIndex: PropTypes.number.isRequired,

    /** A callback function to set the active page index */
    onChange: PropTypes.func.isRequired,
};

export default Carousel;
