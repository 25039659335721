/*
* TableLayout.js Override
* Created by Chris Ward / Oct 10, 2017 *
* This is to allow original column auto-resize functionality from Glu 1.7
*
* Amended by Danny Nguyen / Oct 19, 2017 (with special guest Samuel Rouse)
* - Merged tableLayout.js (#1) - appEvents, triggerSetWidth, Footer Resize
* (NH-42259)
* -
*/

import TableLayout from 'no-override!@glu/grid/src/tableLayout';
import $ from 'jquery';
import util from 'underscore';

export default TableLayout.extend({
    appEvents: {
        resize: 'debouncedResize',
    },

    initialize(...args) {
        TableLayout.prototype.initialize.apply(this, args);

        this.grid = this.options.grid;
        this.masterCollection = this.options.masterCollection;
        this.loading = false;

        // Determine whether to load with an immediate loading state based on
        // collection's `requestingAttributes` flag
        if (this.options.serverSidePagination) {
            this.loading = this.masterCollection.requestingAttributes;
        }

        // TODO `desync` event is deprecated, all logic
        //  now hangs off of the Backbone event `request`
        this.listenTo(
            this.masterCollection,
            {
                'desync request': this.showLoadingView,
                'sync remove': this.hideInfoView,
            },
        );

        this.listenTo(
            this.grid,
            {
                'grid:contentRendered': this.setWidth,
            },
        );

        // New Listener for all browsers
        this.listenTo(this.grid.columns, 'change:condition add remove', this.setWidth);

        // set debounced resize method to be used as resize handler
        this.debouncedResize = util.debounce(this.triggerSetWidth.bind(this), 100);
    },

    onClose() {
        $(window).off(`resize.grid.${this.options.grid.cid}`);
    },

    onRender() {
        TableLayout.prototype.onRender.apply(this);

        if (!this.options.disableDynamicColumnWidths) {
            this.setWidth();
        }
    },

    triggerSetWidth() {
        /**
         *
         * Only fire table's width resize handler if the table view is visible
         *
         * In cases where a StackView is implemented and table view is not on top
         * of stack, it
         * will be hidden
         * no need for change if view is not visible
         *
         * "setWidth()" is the innate method to resize table in glu component
         *
         * */
        if (this.$el.is(':visible')) {
            this.setWidth();
        }
    },

    fitColumnToContent($th, columnIndex, $rows) {
        const columnModel = this.options.grid.columns.at(columnIndex);
        const $cell = $rows.find(`th:eq("${columnIndex}")`);
        const cellPadding = $cell.outerWidth() - $cell.width();
        let width = 0;

        // If column has initial width do not change it during sorting/filtering actions
        if (columnModel && columnModel.get('width') !== 'auto') {
            width = columnModel.get('width');
        } else {
            $rows.each(function () {
                const contentWidth = $(this).find(`th:eq("${columnIndex}") > *`).outerWidth();
                if (contentWidth > width) {
                    width = contentWidth;
                }
            });
            width += cellPadding;
        }

        $th.css({
            width: width || $th.width(),
            minWidth: width || $th.css('min-width'),
        });
    },

    setWidth() {
        /*
         * Grids with disableDynamicColumnWidths enabled do not use this function for
         * table column widths, they use CSS only to manage widths
         */

        if (this.options.disableDynamicColumnWidths) {
            return;
        }

        if (this.isClosed) {
            return;
        }

        // Resizing a column sets a fixed width on the table, which breaks autosizing
        this.$('table').css({
            width: 'auto',
            'table-layout': 'auto',
        });


        const self = this;
        const MAXCOLUMNWIDTH = 280;

        util.defer(() => {
            const $table = self.$('table');
            const $canvas = self.$('.js-canvas');
            const $footer = self.grid.$('.footer');
            let viewportWidth = self.$el.width();
            const tableWidth = $table.width();
            const newSetWidth = (viewportWidth > tableWidth) ? viewportWidth : tableWidth;

            // Adjust width including visible scrollbar
            if (self.$el[0].scrollHeight > self.$el.height()) {
                viewportWidth = self.$(self.info.el).width();
            }

            // Reset sizing container to parent container
            $table.width('');
            $canvas.width(newSetWidth);
            $footer.width(viewportWidth);

            // Iterate through the header list and find columns that do not have defined widths
            $table.find('thead th').each(function (columnIndex) {
                const $th = $(this);
                const headerModel = self.header.currentView.collection.get($th.data('cid'));
                const $rows = $table.find('thead tr');

                if (!headerModel) {
                    return undefined;
                }

                if (headerModel.has('rowButtons')) {
                    return self.fitColumnToContent($th, columnIndex, $rows);
                }

                if (headerModel.get('type') === 'rowSelector' ||
                    headerModel.get('type') === 'singleRowSelector') {
                    return self.fitColumnToContent($th, columnIndex, $rows);
                }

                /*
                 * (if columns have no set width) we want to resize the columns to fill the gaps
                 * if the table is smaller than the viewport width.
                 */
                if (!headerModel.has('width') || (viewportWidth > tableWidth)) {
                    // clear any preset width constraints to get natural content width
                    $th.css({
                        width: '',
                        'min-width': '',
                        'max-width': '',
                    });

                    // Set a max-width for grid column
                    let maxSize = $(this).outerWidth();

                    // if its not 'set' already by user
                    if ($(this).attr('data-grid-column-width') === 'default') {
                        maxSize = maxSize > MAXCOLUMNWIDTH ? MAXCOLUMNWIDTH : maxSize;
                    }

                    $(this).css({
                        width: `${maxSize}px`,
                        minWidth: $(this).outerWidth(),
                        maxWidth: `${maxSize}px`,
                    });
                }
                return undefined;
            });

            // PCM-4346
            let sumWidthSize = newSetWidth;
            if (self.$el.is(':visible')) {
                /*
                 * After the if/else above, loop through
                 * and set the widths of columns that didn't come with it preset
                 */
                sumWidthSize = 0;
                $table.find('thead th').each(function () {
                    const modelCid = $(this).attr('data-cid');
                    const headerModel = self.header.currentView.collection.get(modelCid);
                    if (headerModel && !headerModel.has('width')) {
                        headerModel.set({
                            width: ($(this).outerWidth()),
                        }, {
                            silent: true,
                        });
                        // PCM-4346
                        sumWidthSize += parseInt(($(this).outerWidth()), 10);
                    } else {
                        // PCM-4346
                        sumWidthSize += parseInt(headerModel.get('width'), 10);
                    }
                });
            }

            // reset layout property of table
            $table.css('table-layout', '');
            $table.width(sumWidthSize);
        });

        // Override copied from Glu (NH-42259; GLU-1096) for footer width adjustments
        if (this.$el.closest('.dashboard-region').length) {
            this.grid.footer.$el.css('width', 'auto');
        }
    },
});
