/**
 * one off impl of listbuilder for mfa challenge
 */
import ListBuilder from 'common/listBuilder/listBuilder';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import listBuilderTmpl from 'app/challengeManager/views/listBuilder.hbs';
import store from 'system/utilities/cache';

const ListBuilderView = ListBuilder.extend({
    template: listBuilderTmpl,

    initialize(options, ...rest) {
        // Assume there are more items until proven otherwise.
        this.allItemsLoaded = false;

        this.disableDragAndDrop = options.disableDragAndDrop;
        ListBuilder.prototype.initialize.apply(this, [options, ...rest]);
        // Need to perform an action when search ends, no matter which way.
        this.listenTo(this.sourceCollection, 'search:resultsFound', this.searchFinished);
        this.listenTo(this.sourceCollection, 'search:noResultsFound', this.searchFinished);
        this.listenTo(this.sourceCollection, 'reset', this.setupLoadContent);
        this.listenTo(this.sourceCollection, 'sync', (...args) => {
            this.updateFromSourceSync(...args);
            /*
             * NH-122850. The data-item-id attribute being set on each sortList item was
             * getting out of sync when the collection changed as that attribute is tied to
             * Backbone's cid property generated for each model in the collection and those
             * change when new data is loaded.
             */
            if (!this.suppressRender) {
                this.sourceList.render();
            }
        });

        /*
         * After the lists render, there are new elements, so we need
         * to re-init Drag and Drop to properly catch them.
         */
        this.listenTo(this.sourceList, 'render', () => {
            this.initializeDragAndDrop();
        });
        this.listenTo(this.targetList, 'render', () => {
            this.initializeDragAndDrop();
        });
    },

    /**
     * Search is triggered by `sortLists` which is called when drag-n-drop ends
     * With live search, the collection is reset, but doesn't render or remove
     * duplicates, so we have to do both, ourselves.
     */

    searchFinished() {
        this.removeDuplicates();
        this.sourceList.render();
        this.resetReadyStatus();
    },

    hideSpinner() {
        this.$('.mfa-wait-overlay').addClass('hidden');
    },

    showSpinner() {
        this.$('.mfa-wait-overlay').removeClass('hidden');
    },

    /**
     * - A wrapper for locale to ensure we have the proper properties on objects
     * before attempting to use them
     * @method getLocale
     * @param {string} property - the property to look for
     * @param {Object} collection - the collection required to display total
     * length in UI
     * @returns {string}
     */
    getLocale(property, collection) {
        let localProperty = property;
        localProperty = (this.options.text && this.options.text[localProperty])
            ? this.options.text[localProperty] : localProperty;
        localProperty = (this.options.localeKeys && this.options.localeKeys[localProperty]
            ? this.options.localeKeys[localProperty] : localProperty);

        return locale.get(localProperty, collection.length);
    },

    /**
     * - Build an array of text to use for the source header
     * - Adjust the form's checkbox
     * - Append the source header text to the DOM
     * @method updateSourceHeader
     */
    updateSourceHeader() {
        const sourceTextArray = [
            this.getLocale('sourceListHeader', this.sourceCollection),
            ' (',
            this.sourceCollection.length,
        ];

        if (this.sourceCollection.totalRows) {
            sourceTextArray.push(' of ');
            sourceTextArray.push(this.sourceCollection.totalRows);
        }

        sourceTextArray.push(')');

        this.$('.source-section-header .population-controls').find(`input[value="${this.options.population}"]`).prop('checked', true);
        this.$('.source-section-header-text').text(sourceTextArray.join(''));
    },

    /**
     * - Append the target header to the DOM
     * - Trigger an appBus event
     * @method updateTargetHeader
     */
    updateTargetHeader() {
        this.$('.target-section-header-text').text(`${this.getLocale('targetListHeader', this.targetCollection)} (${this.targetCollection.length})`);
        this.appBus.trigger('population:change', this.targetCollection);
    },

    /**
     * Updates called when the source collection syncs
     * - Update the collection's total rows
     * - Remove duplicates
     * - Update the source header
     * @method updateFromSourceSync
     * @param {Collection} collectionParam
     * @param {object} response
     */
    updateFromSourceSync(collectionParam, response) {
        const collection = collectionParam;
        if (response.totalRows) {
            collection.totalRows = response.totalRows;
        }

        this.removeDuplicates();
        this.updateSourceHeader();
    },

    onRender() {
        this.removeDuplicates();
        this.sortLists();
        this.updateSourceHeader();
        this.updateTargetHeader();
    },

    resetReadyStatus() {
        /*
         * after each successful live search, ready status must be set to true and
         * startRow to 1
         */
        this.sourceCollection.resetForLiveSearch();
    },

    /**
     * Calls parent initializeDragAndDrop if drag and drop is not disabled
     * @method initializeDragAndDrop
     * @param  {...any} args
     */
    initializeDragAndDrop(...args) {
        if (!this.disableDragAndDrop) {
            ListBuilder.prototype.initializeDragAndDrop.apply(this, args);
        }
    },

    /**
     * After a move event trigger various functionality
     * - Remove duplicates between source and target collections
     * - Trigger list sorting on source and target collections
     * - Update the source header
     * - Update the target header
     * - Toggle the controls available in the listBuilder
     * - Initialize drag and drop
     * - Hide the loading spinner
     * @method afterMove
     */
    afterMove() {
        this.removeDuplicates();
        this.sortLists();

        this.updateSourceHeader();
        this.updateTargetHeader();

        this.toggleControls();
        this.initializeDragAndDrop();

        this.hideSpinner();
    },

    /**
     * Move all items from the source to the target collection
     * @method moveAllItemsAction
     * @param {Collection} fromCollection - collection from which items will be removed
     * @param {Collection} toCollection - collection where items will go
     */
    moveAllItemsAction(fromCollection, toCollection) {
        const self = this;
        const items = fromCollection.slice(0);

        this.showSpinner();

        // Defer gives the spinner time to paint before the browser is unresponsive.
        util.defer(() => {
            toCollection.add(
                items,
                {
                    silent: true,
                },
            );

            // Reset is cheaper than deleting the items, since the items are reused.
            fromCollection.reset();

            self.sourceListRegion.show(self.sourceList);
            self.targetListRegion.show(self.targetList);

            self.afterMove();
        });
    },

    /**
     * Load all the data and then move all items to be added to the MFA Challenge
     * @method moveAllItems
     */
    moveAllItems() {
        const self = this;

        // Load all of the available elements, then...
        this.loadAllCycle()
            .then(() => {
                // Add a pause to ensure the fetch buried in the region-view-collection finishes
                util.defer(() => {
                    // Perform the move.
                    self.moveAllItemsAction(self.sourceCollection, self.targetCollection);
                });
            })
            .catch(function (err) {
                // TODO: What do we do when the load fails
                this.loadFail = err;
            });
    },

    /**
     * Remove all items from those selected to be added to the MFA Challenge
     * @method removeAllItems
     */
    removeAllItems() {
        return this.moveAllItemsAction(this.targetCollection, this.sourceCollection);
    },

    /**
     * Moves all selected items either too or from being added to the MFA Challenge
     * @method moveSelectedItems
     */
    moveSelectedItems() {
        const items = this.sourceCollection.where({
            'listBuilder:isSelected': true,
        });

        this.targetCollection.add(
            items,
            {
                silent: true,
            },
        );
        this.sourceCollection.remove(
            items,
            {
                silent: true,
            },
        );

        this.sourceListRegion.show(this.sourceList);
        this.targetListRegion.show(this.targetList);

        this.afterMove();
    },

    /**
     * @method shouldLoadContent
     * @returns {boolean} - should data be loaded
     */
    shouldLoadContent() {
        return !!(this.sourceCollection.next
            && this.sourceCollection.canContinue()
            && this.sourceCollection.isNotEmpty());
    },

    /**
     * @method loadContent
     * @returns {Promise} - to continue functionality after load
     */
    loadContent() {
        const self = this;

        if (this.shouldLoadContent()) {
            // Add a spinner for each cycle because this is used for the lazy load.
            this.showSpinner();
            this.suppressRender = true;
            return this.sourceCollection.next().then((data) => {
                self.hideSpinner();
                return data;
            });
        }
        this.suppressRender = false;
        // If nothing to load, return false so loadAllCycle has a return path
        return Promise.resolve(false);
    },

    /**
     * Load all required data
     * @method loadAllCycle
     * @param {boolean} data - determines how to resolve this promise
     * @returns {Promise}
     */
    loadAllCycle(data) {
        // If we already did this, don't bother.
        if (this.allItemsLoaded) {
            return Promise.resolve(true);
        }

        // Have we reached the end?
        if (data === false) {
            // When the cycle is complete set this so we don't try again.
            this.allItemsLoaded = true;
            return Promise.resolve(true);
        }
        // If a successful response, do it again for the next result set.
        return this.loadContent().then(this.loadAllCycle.bind(this));
    },

    /**
     * Setups up a scroll listener to load more data when a users scrolls to the bottom
     * @method setupLoadContent
     */
    setupLoadContent() {
        const self = this;

        this.$('.source-list').on('scroll', function () {
            const $el = self.$(this);

            if ($el.scrollTop() + $el.innerHeight() >= $el.get(0).scrollHeight) {
                /*
                 * collection should include a function to control increment rowsPerPage and
                 * fetch on this event
                 */
                self.loadContent().then(() => {
                    self.sourceListRegion.show(self.sourceList);
                });
            }
        });
    },

    /**
     * Filters the source collection by search criteria and re-initializes drag and drop
     * @method applySearchFilter
     */
    applySearchFilter() {
        const $filter = this.ui.sourceListSearchFilter;
        const filterText = $filter.val().trim();

        if (filterText.length >= 3) {
            this.sourceCollection.setSearchCriteria(filterText);
            this.sourceCollection.liveSearch();
        }

        if (filterText === '') {
            this.sourceCollection.clearSearchCriteria();
        }

        this.initializeDragAndDrop();
    },

    /**
     * Removes duplicates of target collection models from the source collection
     * @method removeDuplicates
     */
    removeDuplicates() {
        // Fix missing target type if we can.
        if (this.targetCollection.type === undefined) {
            this.targetCollection.type =
                this.$('input[name="populationType"]:checked').val() || store.get('challenge:population');
        }

        switch (this.targetCollection.type) {
        case 'USERS':
            this.targetCollection.each(function (model) {
                const duplicate = this.sourceCollection.findWhere({
                    USERGROUP: model.get('USERGROUP'),
                    USERID: model.get('USERID'),
                    USERNAME: model.get('USERNAME'),
                });
                this.sourceCollection.remove(duplicate);
            }, this);
            break;
        case 'COMPANIES':
            this.targetCollection.each(function (model) {
                const duplicate = this.sourceCollection.findWhere({
                    USERGROUP: model.get('USERGROUP'),
                    GROUPNAME: model.get('GROUPNAME'),
                });
                this.sourceCollection.remove(duplicate);
            }, this);

            break;
        case 'SEGMENTS':
        default:
            this.targetCollection.each(function (model) {
                const duplicate = this.sourceCollection.findWhere({
                    NAME: model.get('NAME'),
                });
                this.sourceCollection.remove(duplicate);
            }, this);
        }
    },
});

export default ListBuilderView;
