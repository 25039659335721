import util from '@glu/core/src/util';
import $ from 'jquery';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import fxFieldValidation from 'common/uiWidgets/util/fxFieldValidation';
import PaymentUtil from 'common/util/paymentUtil';
import rtgsCommon from './rtgsCommon';

export default function (form, initialState) {
    const { model } = form.formView;
    const formState = form.formView.state;
    const receivingAccount = ['DEBIT_ACCOUNT_BANK_NAME', 'DEBIT_ACCOUNT_TITLE'];
    const bankAndAccountInformation = ['CONTAINER2GROUP2_ADDMORE', ['BENE_BANK_ADDRESS_2', 'BENE_BANK_CITY']];
    const readOnlybankAndAccountInformation = ['BENE_BANK_NAME', 'BENE_BANK_ADDRESS_1', 'BENE_BANK_ADDRESS_2', 'BENE_BANK_CITY', 'BENE_BANK_STATE'];
    const addIntBank = form.field('ADDINTBANK');
    const creditAmount = form.field('CREDIT_AMOUNT');
    const beneCountry = form.field('BENE_COUNTRY');
    const hidecutoff = serverConfigParams.get('hideCutoff');
    const { functionCode } = model.jsonData.typeInfo;
    const corrBankArr = ['CORRESPONDENT_NAME', 'CORRESPONDENT_ADDRESS_1', 'CORRESPONDENT_ADDRESS_2', 'CORRESPONDENT_CITY', 'CORRESPONDENT_STATE'];
    const corrBankAddr = ['CORRESPONDENT_ADDRESS_1', 'CORRESPONDENT_ADDRESS_2', 'CORRESPONDENT_CITY'];
    const intBankArr = ['INTERMEDIARY_NAME', 'INTERMEDIARY_ADDRESS_1', 'INTERMEDIARY_ADDRESS_2', 'INTERMEDIARY_CITY', 'INTERMEDIARY_STATE'];
    const intBankAddr = ['INTERMEDIARY_ADDRESS_1', 'INTERMEDIARY_ADDRESS_2', 'INTERMEDIARY_CITY'];
    const changeableFields = [
        'VALUE_DATE',
        'CREDIT_AMOUNT',
        'CUSTOMER_REFERENCE',
        'SPECIAL_INSTRUCTIONS',
        'OBI_TEXT_1',
        'OBI_TEXT_2',
        'OBI_TEXT_3',
        'OBI_TEXT_4',
        'INSTRUCTIONS_TO_BENE_BANK_1',
        'INSTRUCTIONS_TO_BENE_BANK_2',
        'INSTRUCTIONS_TO_BENE_BANK_3',
        'INSTRUCTIONS_TO_BENE_BANK_4',
        'INSTRUCTIONS_TO_BENE_BANK_5',
        'INSTRUCTIONS_TO_BENE_BANK_6',
        'BENE_ACCOUNT_MASK_TOGGLE',
    ];
    const allowMaxTmplAmount = model.get('ALLOWMAXAMTPAYMENTFROMTMPL') || (model.fieldData.ALLOWMAXAMTPAYMENTFROMTMPL) ? model.fieldData.ALLOWMAXAMTPAYMENTFROMTMPL.value : 0;

    const showHideReadOnly = (array, controlField, isManual) => {
        util.each(array, (fieldName) => {
            form.field(fieldName).shouldBeReadOnly(!isManual);
        });
    };

    const makeReadOnly = (array, controlField) => {
        util.each(array, (fieldName) => {
            form.field(fieldName).shouldBeReadOnlyWhen(form.field(controlField).isNotEmpty());
        });
    };

    const clearFields = (array) => {
        util.each(array, (value) => {
            model.set(value, '');
        });
    };

    const intermediaryBank = ['CORRESPONDENT_CITY_LINK', ['CORRESPONDENT_ADDRESS_2', 'CORRESPONDENT_CITY']];
    const secondIntermediaryBank = ['INTERMEDIARY_CITY_LINK', ['INTERMEDIARY_ADDRESS_2', 'INTERMEDIARY_CITY']];

    const showHideAddMore = (array) => {
        const [addLink] = array;
        const additionalFields = array[1];
        const fieldsReversed = additionalFields.slice().reverse();
        let valueFound = false;
        let visible = false;

        form.field(addLink).shouldBeHidden();

        util.each(fieldsReversed, (newField) => {
            visible = form.field(newField).isNotEmpty() || valueFound;
            form.field(newField).shouldBeVisibleWhen(visible);
            valueFound = visible;
            form.field(addLink)
                .shouldBeVisibleWhen(!valueFound || form.field(addLink).isVisible());
        });

        form.field(addLink).$el.parent('.field-container').addClass('add-more-link');
        form.field(addLink).$el.click(additionalFields, (e) => {
            let fieldNeeded = true;
            e.stopImmediatePropagation();

            util.each(e.data, (newField) => {
                if (form.field(newField).isNotVisible() && fieldNeeded) {
                    form.field(newField).shouldBeVisible();
                    fieldNeeded = false;
                }

                form.field(e.target.id)
                    .shouldBeVisibleWhen(form.field(newField).isNotVisible());
            });
        });
    };

    const isArrayEmpty = (array) => {
        let empty = true;
        util.each(array, (fieldName) => {
            if (form.field(fieldName).isNotEmpty()) {
                empty = false;
            }
        });
        return empty;
    };

    const hideAddressFields = (array) => {
        util.each(array, (value) => {
            form.field(value).shouldBeVisibleWhen(form.field(value).isNotEmpty());
        });
    };

    const protectFields = () => {
        Object.keys(form.fields || {}).forEach((fieldName) => {
            if (changeableFields.indexOf(fieldName) === -1) {
                form.field(fieldName).shouldBeReadOnly(true);
            }
        });
        $('.lookup').addClass('hidden');
    };

    if (initialState) {
        model.on('change:VALUE_DATE', () => {
            if (form.formView.state.toUpperCase() !== 'VIEW') {
                fxFieldValidation.doFieldValidation(this, model, 'VALUE_DATE', form.formView.state.toUpperCase()).then(() => {
                });
            }
        });

        const amtBlock = form.formView.$('[data-validate="CREDIT_AMOUNT"]');
        PaymentUtil.shouldShowTemplateMaxAmt(
            allowMaxTmplAmount,
            form.formView,
            model,
            amtBlock,
            functionCode,
        );

        if (form.formView.state.toUpperCase() === 'INSERT') {
            // default bene_country to US
            if (model.context.createdFrom !== '1') {
                model.set('BENE_COUNTRY', 'US');
            }
        }

        if (form.formView.state.toUpperCase() !== 'VIEW') {
            // handle lock icon for amount if template and not view
            if (functionCode !== 'BATCH') {
                if (creditAmount.$el && creditAmount.$el.length > 0) {
                    PaymentUtil.runChangeAmount(creditAmount.$el[0], model);
                }
            }
            // unset defaulted amount if not template entry
            if (!parseInt(model.get('CREDIT_AMOUNT'), 10) && model.get('FUNCTION') !== 'TMPL') {
                model.unset('CREDIT_AMOUNT');
            }
            /**
             * NH-139876
             * event will be triggered when the value of Amount(CREDIT_AMOUNT) is changed
             * when the amount is 0 we are updating the model
             */
            model.on('change:CREDIT_AMOUNT', () => {
                if (parseInt(model.get('CREDIT_AMOUNT'), 10) === 0) {
                    model.unset('CREDIT_AMOUNT', { silent: true });
                }
            });
        }
    }
    /**
     * Show the button 'addIntBank' and hide the Intermediary details when the data
     * is empty and mode is insert
     */
    if (isArrayEmpty(intBankArr) && form.formView.state === 'insert') {
        addIntBank.shouldBeVisible();
        form.field('INTERMEDIARY_ID').$el.closest('.row').hide();
    } else if (!isArrayEmpty(intBankArr) && form.formView.state !== 'insert') {
        /**
         * Show the Intermediary Details when the data is not empty and mode is anything other
         * then Insert
         */
        form.field('INTERMEDIARY_ID').$el.closest('.row').show();
    } else {
        /**
         * hide the button 'addIntBank' and show Intermediary details when the button is clicked
         */
        form.field('INTERMEDIARY_ID').$el.closest('.row').show();
        addIntBank.shouldBeHidden();
    }

    showHideAddMore(intermediaryBank);
    showHideAddMore(secondIntermediaryBank);

    if (form.field('CORRESPONDENT_ID').isNotEmpty()) {
        showHideReadOnly(corrBankArr, 'CORRESPONDENT_ID', false);
    }
    if (form.field('INTERMEDIARY_ID').isNotEmpty()) {
        showHideReadOnly(intBankArr, 'INTERMEDIARY_ID', false);
    }
    if (form.field('CORRESPONDENT_ID').isNotEmpty()) {
        hideAddressFields(corrBankAddr);
    }
    if (form.field('INTERMEDIARY_ID').isNotEmpty()) {
        hideAddressFields(intBankAddr);
    }
    if (form.field('INTERMEDIARY_ID').$el.closest('.row').css('display') !== 'none') {
        showHideAddMore(secondIntermediaryBank);
    }

    form.field('BENE_ACCOUNT_TYPE').$el.on('change', () => {
        clearFields(['BENE_BANK_ID', 'BENE_BANK_NAME', 'BENE_BANK_ADDRESS_1', 'BENE_BANK_ADDRESS_2', 'BENE_BANK_CITY', 'BENE_BANK_STATE']);
        $('#s2id_BENE_BANK_ID').select2('data', null);
    });

    showHideReadOnly(receivingAccount, 'DEBIT_ACCOUNT_NUMBER');
    makeReadOnly(readOnlybankAndAccountInformation, 'BENE_BANK_ID');
    showHideAddMore(bankAndAccountInformation);
    addIntBank
        .$el.on('click', (e) => {
            e.stopImmediatePropagation();
            addIntBank.shouldBeHidden();
            form.field('INTERMEDIARY_ID').$el.closest('.row').show();
            showHideAddMore(secondIntermediaryBank);
        });

    if (model.get('ENTRYMETHOD') && (model.get('ENTRYMETHOD') === '1' || model.get('ENTRYMETHOD') === '2')) {
        protectFields();
    }

    rtgsCommon.toggleStateProvince(
        model, beneCountry.getValue(),
        $('input[name="BENE_PROVINCE"]').closest('.field-container'),
        $('select[name="BENE_STATE"]').closest('.field-container'),
    );

    /*
     * hide cutoff if conditions are true  (EN-Entered, IA-Incomplete Approval, RT-Needs
     * Rate, HV-2nd Approval Required are status defined in database)
     */
    rtgsCommon.hideCutoff(formState, functionCode, hidecutoff, 'DRAWDOWN', model.get('STATUS'));
}
