import dialog from '@glu/dialog';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import { log } from '@glu/core';
import $ from 'jquery';
import locale from '@glu/locale';
import moment from 'moment';
import services from 'services';
import applicationConfiguration from 'system/webseries/models/applicationConfiguration';
import BeneUpdatedWarning from 'common/dynamicPages/views/beneUpdatedWarning';
import configuration from 'system/configuration';
import constants from 'common/dynamicPages/api/constants';
import Decimals from 'common/dynamicPages/api/decimals';
import Formatter from 'system/utilities/format';
import fxPaymentUtil from 'common/util/fxPaymentUtil';
import IntermediaryBankMappingCollection from 'app/payments/collections/intermediaryBankMapping';
import PaymentUtil from 'common/util/paymentUtil';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import userInfo from 'etc/userInfo';
import orderingPartyUtil from 'common/util/orderingPartyLookupUtil';
import { getMaskingConfiguration } from 'common/util/maskingUtil';
import OnUsWireDatePromptView from 'common/modals/onUsWireDatePromptView';
import viewBinding from 'system/gluOverride/core/internal/viewBinding';
import rtgsCommon from './rtgsCommon';

/**
 * Determines eligibility for the onus date prompt modal to be shown
 * @param {Object} businessDaysResponse - the response from the date list upon bene_bank_id change
 * @param {Model} model - the form model
 * @return {Boolean} - true for eligible, false for ineligible
 */
const eligibleForOnusModalPrompt = (businessDaysResponse, model) => (businessDaysResponse.onUs && model.get('VALUE_DATE') !== businessDaysResponse.earliestDay);

export default function (form, initialState) {
    const { model } = form.formView;
    const { formView } = form;

    const $bankToBankField = $('#INSTRUCTIONS_TO_BENE_BANK_1');
    const $companyName = $('#COMPANYNAME');
    const $contractIdField = $('#EXCHANGE_RATE_CONTRACTID');
    const $correspondentIdField = $('#CORRESPONDENT_ID');
    const $exchangeRateField = $('#EXCHANGE_RATE');
    const $indicativeAmountField = $('#INDICATIVEAMOUNT');
    const $indicativeRateField = $('#INDICATIVERATE');
    const $intermediaryIdField = $('#INTERMEDIARY_ID');
    const $originatorId = $('#ORIGINATOR_ID');
    const $paymentDetailField = $('#OBI_TEXT_1');
    const $saveAsDraftButton = $('[data-action="savedraft"]');
    const $saveAsTemplate = $('[data-section="save-as-template"]');
    const $saveButton = $('[data-action="save"]');
    const $summarySection = $('[data-widget-id="summarysection"]').closest('fieldset');
    const addIntAddress = form.field('INTERMEDIARY_CITY_LINK');
    const bankCodeType = form.field('BENE_BANK_TYPE');
    const bankEntryMethod = form.field('BENEBANKIDENTRYMETHOD');
    const bankTypeClearingFieldsSuffix = [
        'ID',
        'NAME',
        'ADDRESS_1',
        'ADDRESS_2',
        'STATE',
        'CITY',
        'COUNTRY',
        'ACCOUNT_NUMBER_VIRTUALFIELD',
        'ADDRESS_1_VIRTUALFIELD',
        'ADDRESS_2_VIRTUALFIELD',
        'CITY_VIRTUALFIELD',
    ];
    const beneAddress1 = form.field('BENE_ADDRESS_1');
    const beneAddress2 = form.field('BENE_ADDRESS_2');
    const companyName = form.field('COMPANYNAME');
    const contractId = form.field('EXCHANGE_RATE_CONTRACTID');
    const contractRateConvention = serverConfigParams.get('ContractRateConvention');
    const creditAmount = form.field('CREDIT_AMOUNT');
    const creditCurrency = form.field('CREDIT_CURRENCY');
    const creditDebitBtn = form.field('CREDIT_DEBIT_RADIO_BTN');
    const creditDebitBtnExists = !util.isUndefined(model.fieldData.CREDIT_DEBIT_RADIO_BTN);
    const creditDebitEntered = form.field('CREDIT_DEBIT_ENTERED');
    const creditDebitEnteredExists = !util.isUndefined(model.fieldData.CREDIT_DEBIT_ENTERED);
    const debitAmount = form.field('DEBIT_AMOUNT');
    const debitCurrency = form.field('DEBIT_CURRENCY');
    const exchangeRate = form.field('EXCHANGE_RATE');
    const indicativeAmount = form.field('INDICATIVEAMOUNT');
    const indicativeRate = form.field('INDICATIVERATE');
    const originatorAddr1 = form.field('ORIGINATOR_ADDRESS_1');
    const originatorAddr2 = form.field('ORIGINATOR_ADDRESS_2');
    const originatorCity = form.field('ORIGINATOR_CITY');
    const originatorCountry = form.field('ORIGINATOR_COUNTRY');
    const originatorId = form.field('ORIGINATOR_ID');
    const originatorIdType = form.field('ORIGINATOR_ID_TYPE');
    const originatorLink = form.field('ORIGINATOR_ADDRESS_1_LINK');
    const originatorName = form.field('ORIGINATOR_NAME');
    const orderOfAccount = form.field('ORDEROF_ACCOUNT');
    const productDesc = $('#PRODUCTDESC').find('span');
    const restrict = form.field('RESTRICTTEMPLATE_FLAG');
    const saveAsTemplate = form.field('SAVEASTEMPLATE');
    const templateCode = form.field('TEMPLATE_CODE');
    const templateDescription = form.field('TEMPLATE_DESCRIPTION');
    const { functionCode } = model.jsonData.typeInfo;
    const maskConfig = getMaskingConfiguration();
    let freeFormBeneBankCountry;
    let openRequests = 0;
    let showPostDate;
    let templateNameNode;

    /*
     * HACK: NH-38324
     * A mechanism was required to disable form submission while HTTP requests
     * (e.g. validation requests)
     * were in-flight, in order to prevent forms being submitted after a request was made,
     * but prior to the response being returned, which can lead to unexpected results.
     * It was decided to simply keep a count of the in-flight requests. It is
     * still very much
     * possible to get race conditions with this 'solution', but a much more extensive
     * overhaul than we have time for would be required to fix all of the issues here.
     */
    const updateSubmitButton = function () {
        if (openRequests === 0) {
            $saveButton.prop('disabled', false);
            $saveAsDraftButton.prop('disabled', false);
        } else {
            $saveButton.prop('disabled', true);
            $saveAsDraftButton.prop('disabled', true);
        }
    };

    const incrementOpenRequestCount = function () {
        openRequests += 1;
        updateSubmitButton();
    };

    const decrementOpenRequestCount = function () {
        openRequests -= 1;
        updateSubmitButton();
    };

    const isDebitBtnSelected = function () {
        return (creditDebitBtnExists && creditDebitBtn.$el.is(':checked')) || (creditDebitEnteredExists && (creditDebitEntered.getValue() === 'Debit' || creditDebitBtn.getValue() === 'Debit'));
    };

    const isCreditDebitSelectionExists = function () {
        return ((creditDebitBtnExists && creditDebitBtn.isVisible()) || (creditDebitEnteredExists && !util.isUndefined($('#CREDIT_DEBIT_ENTERED').parent().css('display')) && $('#CREDIT_DEBIT_ENTERED').parent().css('display') !== 'none'));
    };

    const getFormFlags = function () {
        let showExchangeRate = false;
        let showContractId = false;
        let showIndicativeRate = false;
        let showIndicativeAmt = false;
        let crossCurr = false;
        let allowContractIdOverride = false;
        let fxRateType = '0';
        let exchangeRateReadOnly = true;
        if (model.fieldData.ALLOWCONTRACTRATEOVERRIDE && model.fieldData.ALLOWCONTRACTRATEOVERRIDE.value === '1') {
            allowContractIdOverride = true;
        }
        if (model.fieldData.FXRATETYPE) {
            fxRateType = model.fieldData.FXRATETYPE.value;
        }
        if (util.isEmpty(model.get('DEBIT_ACCOUNT_NUMBER')) || (!util.isEmpty(model.get('DEBIT_ACCOUNT_NUMBER')) && model.get('DEBIT_CURRENCY') === model.get('CREDIT_CURRENCY'))) {
            // hide all
        } else if (model.get('DEBIT_CURRENCY') !== model.get('CREDIT_CURRENCY')) {
            crossCurr = true;
        }

        if (crossCurr && (!util.isEmpty(model.get('CREDIT_AMOUNT')) || !util.isEmpty(model.get('DEBIT_AMOUNT'))) && util.isEmpty(model.get('EXCHANGE_RATE'))) {
            showIndicativeRate = true;
            showIndicativeAmt = true;
        } else if (crossCurr && !util.isEmpty(model.get('EXCHANGE_RATE'))) {
            showExchangeRate = true;
            showContractId = true;
            if (creditDebitBtnExists) {
                creditDebitBtn.shouldBeReadOnly(false);
            } else {
                creditDebitEntered.shouldBeReadOnly(false);
            }
        }
        // exchange rate table
        if (crossCurr && fxRateType === '0') {
            showContractId = true;
            allowContractIdOverride = true;
        } else if (crossCurr && fxRateType === '1' && allowContractIdOverride) {
            showContractId = true;
        }

        /*
         * Display the exchange rate if the contract ID is displayed and it is not
         * empty or this is a multi-bank payment.
         */
        if (showContractId && (!util.isEmpty(model.get('EXCHANGE_RATE_CONTRACTID')) || model.get('TYPE') === 'MULTIBK')) {
            showExchangeRate = true;
            exchangeRateReadOnly = false;
            showIndicativeRate = false;
            showIndicativeAmt = false;
        }
        return {
            showExchangeRate,
            exchangeRateReadOnly,
            showIndicativeRate,
            showIndicativeAmt,
            showContractId,
            allowContractIdOverride,
            crossCurr,
            fxRateType,
        };
    };

    const getIndicativeRate = function () {
        const url = services.generateUrl(constants.URL_GETINDICATIVERATE_ACTION);
        const isDebitSelected = isDebitBtnSelected();
        const type = model.get('TYPE');
        let enteredAmountFlag;
        if (form.formView.state === 'view') {
            enteredAmountFlag = model.get('ENTERED_AMOUNT_FLAG');
        } else {
            enteredAmountFlag = isDebitSelected ? 'D' : 'C';
        }

        if (!util.contains(['TRANSFER', 'INTL', 'DRAFT'], type)) {
            return;
        }

        const data = {
            enteredAmountFlag,
            creditCurrency: model.get('CREDIT_CURRENCY'),
            debitCurrency: model.get('DEBIT_CURRENCY'),
            debitAmount: model.get('DEBIT_AMOUNT'),
            creditAmount: model.get('CREDIT_AMOUNT'),
            valueDate: model.get('VALUE_DATE'),
            status: model.get('STATUS') || 'EN',
            typeCode: type,
            actionMode: form.formView.state.toUpperCase(),
        };

        incrementOpenRequestCount();
        http.post(url, data, (result) => {
            model.set('INDICATIVERATE', result.indicativeRate);
            model.set('INDICATIVEAMOUNT', result.indicativeAmount);
            if (form.formView.state === 'view') {
                $indicativeAmountField.find('span').text(result.indicativeAmount);
            }

            const flags = getFormFlags();

            if (flags.showIndicativeRate && flags.showIndicativeAmt) {
                if (isDebitSelected) {
                    model.set('CREDIT_AMOUNT', ' ');
                } else {
                    model.set('DEBIT_AMOUNT', ' ');
                }
            }
            decrementOpenRequestCount();
        }, (err) => {
            log.error(err);
            decrementOpenRequestCount();
        });
    };

    const toggleElementVisibility = function (el, shouldShow) {
        if (shouldShow) {
            el.show();
        } else {
            el.hide();
        }
    };

    const showOrHideRateFields = function () {
        /*
         * Intl Wires use the FXPAYMENTWIDGET.  The Multi-Bank payment templates do
         * not show the debit amount or rate information.
         */
        if (model.jsonData.typeInfo.typeCode === 'INTL' || (model.get('TYPE') === 'MULTIBK' && functionCode === 'TMPL')) {
            return;
        }

        const flags = getFormFlags();
        if (model.context.actionMode !== 'view' && flags.showIndicativeRate && flags.showIndicativeAmt) {
            getIndicativeRate();
        }

        if (form.formView.state === 'view') {
            toggleElementVisibility($indicativeAmountField, flags.showIndicativeAmt);
            toggleElementVisibility($indicativeRateField, flags.showIndicativeRate);
            toggleElementVisibility($contractIdField, flags.showContractId);
            toggleElementVisibility($exchangeRateField, flags.showExchangeRate);
        } else {
            exchangeRate
                .shouldBeVisibleWhen(flags.showExchangeRate)
                .shouldBeReadOnly(flags.exchangeRateReadOnly);
            contractId
                .shouldBeVisibleWhen(flags.showContractId)
                .shouldBeReadOnly(!flags.allowContractIdOverride);
            indicativeRate
                .shouldBeVisibleWhen(flags.showIndicativeRate)
                .shouldBeReadOnly(true);
            indicativeAmount
                .shouldBeVisibleWhen(flags.showIndicativeAmt)
                .shouldBeReadOnly(true);
        }
    };

    const isInsertFromTemplate = function () {
        return form.formView.state === 'insert'
            && (model.context.createdFrom === '1' || model.context.functionCode === 'INST');
    };

    const openOptionalSections = function () {
        if (form.formView.state === 'modify' || form.formView.state === 'restore' || isInsertFromTemplate()) {
            if (form.field('CORRESPONDENT_ID').isNotEmpty()) {
                form.field('CORRESPONDENT_ID').$el.closest('.panel-collapse').collapse('show');
            }
            if (form.field('OBI_TEXT_1').isNotEmpty()) {
                form.field('OBI_TEXT_1').$el.closest('.panel-collapse').collapse('show');
            }
            if (form.field('INSTRUCTIONS_TO_BENE_BANK_1').isNotEmpty()) {
                form.field('INSTRUCTIONS_TO_BENE_BANK_1').$el.closest('.panel-collapse').collapse('show');
            }
            if (originatorName.isNotEmpty()) {
                originatorName.$el.closest('.panel-collapse').collapse('show');
            }
        }
        if (form.formView.state === 'view') {
            if ($correspondentIdField.val() === '') {
                $correspondentIdField.closest('.panel-collapse').collapse('show');
            }
            if ($paymentDetailField.val() === '') {
                $paymentDetailField.closest('.panel-collapse').collapse('show');
            }
            if ($bankToBankField.val() === '') {
                $bankToBankField.closest('.panel-collapse').collapse('show');
            }
        }
    };

    const handlePostDateDisplay = function () {
        // If using VALUEDATEWIDGET, skip this section, .
        if (util.contains(['FEDWIRE', 'INTL', 'MULTIBK', 'CRTRAN', 'PAYMODE', 'DRAWDOWN', 'DRAFT'], model.get('TYPE'))) {
            return;
        }
        const postDate = model.get('POST_DATE');
        const ffPostDate = form.field('POST_DATE');
        if (!showPostDate || showPostDate === 'false') {
            ffPostDate.shouldBeVisibleWhen(false);
            return;
        }
        if (postDate) {
            // show post date
            ffPostDate.shouldBeVisibleWhen(true).shouldBeReadOnly(true);
        } else {
            // make sure post date is not shown
            ffPostDate.shouldBeVisibleWhen(false);
        }
    };

    const setAmountMaskingFromCurrency = function (fieldname, currencyCode) {
        const precision = Decimals.getNumberOfDecimalPlaces(currencyCode);
        let fieldValue = form.formView.model.get(fieldname);
        if (precision === 0) {
            fieldValue = typeof fieldValue === 'string'
                ? fieldValue.trim().replace(/,/g, '') : fieldValue;
            const initialValue = fieldValue !== '' ? fieldValue : 0;
            let roundedValue = Math.round(parseFloat(initialValue));
            roundedValue = Number.isNaN(roundedValue) ? 0 : roundedValue;
            form.field(fieldname).setValue(roundedValue);
            // countries with 0 decimal fails with trim() function, set value back as string
            model.set(fieldname, `${roundedValue}`);
        }
    };

    const updateDebitOrCreditAmount = function () {
        const exchRate = model.get('EXCHANGE_RATE');
        let creditAmt = model.get('CREDIT_AMOUNT');
        let debitAmt = model.get('DEBIT_AMOUNT');
        const marketConvention = model.get('MARKETCONVENTION');
        let precision = 2;
        let amount;
        const isDebitSelected = isDebitBtnSelected();
        const amountField = isDebitSelected ? 'CREDIT_AMOUNT' : 'DEBIT_AMOUNT';

        if (model.jsonData.typeInfo.typeCode === 'INTL') {
            return; // FXPAYMENTWIDGET
        }
        // strip off thousand separators from amounts
        const thousandsSeparator = userInfo.get('thousandsSeparator');
        if (debitAmt) {
            debitAmt = debitAmt.split(thousandsSeparator).join('');
        }
        if (creditAmt) {
            creditAmt = creditAmt.split(thousandsSeparator).join('');
        }
        const currencyChoice = isDebitSelected ? 'CREDIT_CURRENCY' : 'DEBIT_CURRECNY';
        precision = Decimals.getNumberOfDecimalPlaces(model.get(currencyChoice));
        if (model.get('TYPE') !== 'MULTIBK') {
            if (exchRate) {
                if (Number.isNaN(Number(debitAmt))) {
                    model.set(amountField, '');
                }
                if (debitAmt === creditAmt) {
                    model.set('MARKETCONVENTION', 'D');
                }
                if (contractRateConvention === 'BUY') {
                    amount = isDebitSelected
                        ? parseFloat(debitAmt) * parseFloat(exchRate)
                        : parseFloat(creditAmt) * parseFloat(exchRate);
                } else if (marketConvention === 'D') {
                    amount = isDebitSelected ? parseFloat(debitAmt) / parseFloat(exchRate)
                        : parseFloat(creditAmt) * parseFloat(exchRate);
                } else {
                    amount = isDebitSelected ? parseFloat(debitAmt) * parseFloat(exchRate)
                        : parseFloat(creditAmt) / parseFloat(exchRate);
                }
                // Guaranteed to be a Number, here
                if (Number.isNaN(amount)) {
                    amount = 0;
                }

                form.field(amountField).setValue(amount.toFixed(precision));
                model.set(amountField, amount.toFixed(precision));
            }
        }

        // populate the other field that was not selected/editable
        if (isDebitSelected) {
            setAmountMaskingFromCurrency('CREDIT_AMOUNT', model.get('CREDIT_CURRENCY'));
        } else {
            setAmountMaskingFromCurrency('DEBIT_AMOUNT', model.get('DEBIT_CURRENCY'));
        }
    };

    const doFieldValidation = function (fieldname, func) {
        let tempValue;
        let tempKey;
        const amountFields = {};
        const { fieldData } = model;
        const isWireOrMultibk = util.contains(['FEDWIRE', 'INTL', 'MULTIBK'], model.get('TYPE'));

        if (model.jsonData.typeInfo.typeCode === 'INTL') {
            return; // FXPAYMENTWIDGET
        }

        const validationService = services.generateUrl(constants.URL_DO_FIELD_VALIDATION);

        const addlNVPlist = [{
            name: 'REQUESTTYPE',
            value: 'fieldValidation',
        }, {
            name: 'FIELDNAME',
            value: fieldname,
        }, {
            name: 'ACTION',
            value: form.formView.state.toUpperCase(),
        }];

        const data = {
            item: model.getNameValuePairAttributes(),
        };

        const inputData = util.union(addlNVPlist, data.item);
        incrementOpenRequestCount();
        http.post(
            validationService,
            {
                item: inputData,
            },
            (result) => {
                // extract the debit amounts and currency
                util.each(result.item, (entry) => {
                    if (fieldData[entry.name]) {
                        if (fieldData[entry.name].visible) {
                            if (fieldData[entry.name].fieldType === 'AMOUNT' || fieldData[entry.name].fieldType === 'PAMOUNT' || fieldData[entry.name].fieldType === 'CURRENCY') {
                                const key = (entry.name === 'CREDIT_AMOUNT') ? 'CREDIT_CURRENCY' : 'DEBIT_CURRENCY';

                                amountFields[entry.name] = {
                                    value: entry.value,
                                    currencyKey: key,
                                };
                            }
                        }
                    }
                });
                const state = form.formView.state.toUpperCase();
                const payType = model.jsonData.typeInfo.typeCode;

                util.each(result.item, (nvp) => {
                    tempKey = nvp.name;
                    tempValue = nvp.value;
                    // preprocess return data
                    if (fieldData[tempKey] && fieldData[tempKey].fieldUIType === 'CHECKBOX') {
                        if (tempValue === fieldData[tempKey].checkboxOffValue) {
                            tempValue = '';
                        }
                    }
                    if (tempKey === 'TRAN_DATE' || tempKey === 'VALUE_DATE' || tempKey === 'POST_DATE' || tempKey === 'CUTOFF_INFO') {
                        // NH-116228 drawdown wire not showing cutoff time (only date)
                        if (tempKey === 'CUTOFF_INFO') {
                            // prevent displaying errors in UI when tempValue is non-date
                            tempValue = moment(tempValue).isValid() ? tempValue : '';
                        } else {
                            tempValue = moment(tempValue).format(userInfo.getDateFormat());
                        }

                        /*
                         * For these types we do not want to set a default value date or tran
                         * date on payment creation. Payments from templates and copy as payment
                         * follow a different path.
                         */
                        if (!util.contains(['FEDWIRE', 'MULTIBK', 'DRAFT', 'FEDTAX', 'INTL', 'DRAWDOWN'], payType) || state !== 'INSERT') {
                        /*
                         * FIXME: An incorrect/empty formatted fate gives "Invalid date"
                         * Which is still truthy, so the tempValue condition will never fail?
                         * HACK The statement below should be hit after the initial load of
                         * the page.  But because initialState is true within the
                         * closure of the caller, it executes the else.  Apparently this
                         * HACK was implemented so the tran date would move
                         * correctly for the specified type.  The correct fix is to ensure
                         * initialState is accurate and always use the value
                         * of Tran date.  I believe that the behavior changed when the
                         * initialState was removed from the form object.
                         */
                            if ((tempValue && (payType !== 'INTL' && (state === 'MODIFY' || state === 'REPAIR') && !initialState)) || state === 'INSERT') {
                                model.set(tempKey, tempValue);
                                if (tempKey === 'TRAN_DATE' && state !== 'INSERT') {
                                    $('#TRAN_DATE').find('strong').text(tempValue);
                                }
                            } else if (tempValue && fieldname === 'VALUE_DATE' && (isWireOrMultibk || util.contains(['NRECEIVE', 'ITRNSFER', 'DRAFT', 'FEDTAX'], payType))) {
                                model.set(tempKey, tempValue);
                                if (tempKey === 'TRAN_DATE') {
                                    $('#TRAN_DATE').find('strong').text(tempValue);
                                }
                            } else if (model.formReloadedFromDriverField && payType === 'INTL') {
                                model.set(tempKey, tempValue);
                                if (tempKey === 'TRAN_DATE') {
                                    $('#TRAN_DATE').find('strong').text(tempValue);
                                }
                            } else if (tempValue && (payType === 'INTL' || payType === 'FEDWIRE') && fieldname === 'DEBIT_BANK_CODE'
                            && tempKey === 'POST_DATE') {
                            // this is special condition just for post date
                                model.set(tempKey, tempValue);
                            }
                        }
                    } else if (isCreditDebitSelectionExists()) {
                        if (!isDebitBtnSelected()) {
                            if (tempKey !== 'DEBIT_AMOUNT') {
                                model.set(tempKey, tempValue);
                            }
                        } else if (tempKey !== 'CREDIT_AMOUNT') {
                            model.set(tempKey, tempValue);
                        }
                    } else if (tempKey === 'DEBIT_AMOUNT' || tempKey === 'CREDIT_AMOUNT') {
                        /*
                         * TODO: do we need?
                         * if tempKey IS debit or credit amount, we need to make sure it is
                         * formatted correctly
                         */
                        if (parseInt(tempValue, 10)) {
                            model.set(tempKey, Formatter.formatCurrency(tempValue || 0));
                        }
                    } else {
                        model.set(tempKey, util.unescape(tempValue));
                    }
                });
                func();
                showOrHideRateFields();
                updateDebitOrCreditAmount();
                decrementOpenRequestCount();
                handlePostDateDisplay();
            },
            (err) => {
                log.error(err);
                decrementOpenRequestCount();
            },
        );
    };

    const setDefaultCharges = function () {
        const hideCharges = model.fieldData.HideCharges
            ? model.fieldData.HideCharges.value : false;
        const defaultCharges = model.fieldData.DefaultCharge
            ? model.fieldData.DefaultCharge.value : false;
        const pulledFromTemplate = model.get('DETAILS_OF_CHARGES')
            && model.context.createdFrom === '1' && model.context.copySource === 'template';
        const chargesCombo = form.field('DETAILS_OF_CHARGES');
        if (hideCharges && chargesCombo) {
            if (form.formView.state.toUpperCase() === 'INSERT'
                && util.isEmpty(chargesCombo.value) && !pulledFromTemplate && util.isEmpty(model.get('DETAILS_OF_CHARGES'))) {
                model.set('DETAILS_OF_CHARGES', defaultCharges);
            }

            if (hideCharges === '1') {
                chargesCombo.shouldBeHidden();
                // Hide the charges field when in view mode also.
                form.formView.$('#DETAILS_OF_CHARGES').hide();
            } else if (hideCharges === '0') {
                chargesCombo.shouldBeVisible();
            }
        }
    };

    const setCreditDebitFormValue = function (isCredit) {
        if (creditDebitBtnExists) {
            if (isCredit) {
                formView.$('#CREDIT_DEBIT_RADIO_BTN-Credit').prop('checked', true);
            } else {
                formView.$('#CREDIT_DEBIT_RADIO_BTN-Debit').prop('checked', true);
            }
        } else if (isCredit) {
            model.set('CREDIT_DEBIT_ENTERED', 'Credit');
        } else {
            model.set('CREDIT_DEBIT_ENTERED', 'Debit');
        }
    };

    const updateDebitOrCreditCurrency = function () {
        /*
         * Intl Wires use the FXPAYMENTWIDGET.  The Multi-Bank payment templates do
         * not show the debit amount or rate information.
         */
        const isMultiBank = model.get('TYPE') === 'MULTIBK';
        if (model.jsonData.typeInfo.typeCode === 'INTL' || (isMultiBank && functionCode === 'TMPL')) {
            return;
        }

        const creditCurrencyValue = model.get('CREDIT_CURRENCY');
        const debitCurrencyValue = model.get('DEBIT_CURRENCY');
        if (creditCurrencyValue === '' || debitCurrencyValue === '' || creditCurrencyValue === debitCurrencyValue) {
            debitAmount.shouldBeHidden();
            if (creditDebitBtnExists) {
                formView.$('#CREDIT_DEBIT_RADIO_BTN-Credit').parent().hide();
                formView.$('#CREDIT_DEBIT_RADIO_BTN-Debit').parent().hide();
            } else {
                creditDebitEntered.shouldBeHidden();
            }
            model.set('ENTERED_AMOUNT_FLAG', 'C');
        } else {
            debitAmount.shouldBeVisible();
            if (creditDebitBtnExists) {
                creditDebitBtn.shouldBeVisible();
                formView.$('#CREDIT_DEBIT_RADIO_BTN-Credit').parent().show();
                formView.$('#CREDIT_DEBIT_RADIO_BTN-Debit').parent().show();
            } else {
                creditDebitEntered.shouldBeVisible();
            }

            /*
             * Multi-Bank payments require the user to enter Credit and Debit amounts
             * on modify.
             */
            if (!isMultiBank) {
                if (util.isEmpty(model.get('ENTERED_AMOUNT_FLAG')) || model.get('ENTERED_AMOUNT_FLAG') === 'C') {
                    debitAmount.shouldBeReadOnly(true);
                    creditAmount.shouldBeReadOnly(false);
                    setCreditDebitFormValue(true);
                } else if (model.get('ENTERED_AMOUNT_FLAG') === 'D') {
                    debitAmount.shouldBeReadOnly(false);
                    creditAmount.shouldBeReadOnly(true);
                    setCreditDebitFormValue(false);
                }

                creditAmount.shouldBeRequired();
                creditAmount.$el.parent().addClass('required');
                debitAmount.shouldBeOptional();
                debitAmount.$el.parent().removeClass('required');
                debitAmount.removeValidator('exists').$el.parent().removeClass('required');
            }

            if (model.get('TYPE') === 'DRAFT') {
                model.set('Debit_Currency', model.get('DEBIT_CURRENCY'));
            }
        }
    };

    const setDefaultAmount = function (amountField) {
        const amount = model.get(amountField);
        const defaultAmount = 0;
        const currencyField = (amountField === 'DEBIT_AMOUNT') ? 'DEBIT_CURRENCY' : 'CREDIT_CURRENCY';
        const precision = Decimals.getNumberOfDecimalPlaces(model.get(currencyField));

        if (form.field(amountField).isEmpty() || util.isNaN(amount)) {
            model.set(amountField, defaultAmount.toFixed(precision));
        }
    };

    /*
     * Walk through each section that contains data tagged as confidential information
     * and remove it except for the
     * credit amount and the detail charges.
     */
    const hideConfidentialSections = function () {
        const confidentialGroups = $(`div:contains("${model.get('ConfidentialMessage')}")`).parents('.section-container');
        let item;

        const ignoreFieldName = ['ACCOUNTFILTER', 'COMPANYNAME', 'CREDIT_AMOUNT', 'DEBIT_AMOUNT', 'DETAILS_OF_CHARGES', 'FXPAYMENTWIDGET', 'PREADVISEWIRES'];
        const ignoreFieldIncl = ['CORRESPONDENT', 'DOC_', 'INSTRUCTIONS', 'INTERMEDIARY', 'OBI', 'ORDEROF', 'ORIGINATOR', 'RMT_'];

        confidentialGroups.each(function () {
            $(this).find('div.text-group, [data-region="maskedInputRegion"]').each(function () {
                item = $(this)[0].id || $(this)[0].closest('div [data-widget-field]').dataset.widgetField;
                if (item === 'BENE_NAME') {
                    $(this).empty().append(`<strong>${model.get('ConfidentialMessage')}</strong>`);
                } else if (!ignoreFieldName.some(name => item === name)
                    && !ignoreFieldIncl.some(incl => item.includes(incl))) {
                    $(this).remove();
                }
            });
        });
    };

    const protectRequiredOptionalSections = function () {
        $('.panel-collapse').parents('.panel-tertiary')
            .filter(function () {
                return $(this).find('.required').length > 0;
            }).find('.indicator-icon')
            .toggleClass('indicator-icon');
    };

    const handleBusinessDaysResponse = function (businessDaysResponse) {
        let date = businessDaysResponse.defaultDay;
        let {
            tranDate,
            maxForwardDays,
        } = businessDaysResponse;
        const {
            maxAdvancedDays,
            defaultForwardDays,
            userSetDateInvalid,
            userSetValueDate,
            onUs,
        } = businessDaysResponse;
        const state = form.formView.state.toUpperCase();
        const paymentType = model.get('TYPE');
        const $dateInput = form.formView.$el.find('#VALUE_DATE');

        if (model.get('ENTRYMETHOD') === '1' && model.get('CREATEDFROM') === '2') {
            // Scheduled payments should push the maxForwards days out just over a year
            maxForwardDays = 370;
        }

        date = moment(date).local().format(userInfo.getDateFormat());
        tranDate = moment(tranDate).local().format(userInfo.getDateFormat());

        form.formView.processingDates.daysForward.shift();
        form.formView.processingDates.daysForward.push(maxForwardDays);

        form.formView.processingDates.processingDays.shift();
        form.formView.processingDates.processingDays.push(businessDaysResponse.businessDays);

        form.formView.processingDates.cutOffTimes.shift();
        form.formView.processingDates.cutOffTimes.push(businessDaysResponse.cutoff);

        // display cutoff if returned and config allows
        if (businessDaysResponse.cutoffDateTimeTz && state !== 'VIEW') {
            model.set('CUTOFF_INFO', businessDaysResponse.cutoffDateTimeTz);
            if (paymentType === 'FEDTAX' || paymentType === 'BPAY') {
                PaymentUtil.showCutoff(businessDaysResponse.cutoffDateTimeTz, $('.ui-datepicker-trigger'), paymentType, state, model.get('STATUS'));
            }
        }

        // remove previous blocked dates
        form.formView.processingDates.blockedDates
            .splice(0, form.formView.processingDates.blockedDates.length);
        if (businessDaysResponse.holidays.length > 0) {
            form.formView.processingDates.blockedDates.push(...businessDaysResponse.holidays);
        }

        if ($dateInput.length > 0) {
            if ($dateInput.data('daterangepicker')) {
                $dateInput.data('daterangepicker').updateCalendars({
                    blockedDates: form.formView.processingDates.blockedDates,
                    daysBack: date,
                    daysForward: form.formView.processingDates.daysForward[0],
                });
            }

            PaymentUtil.invalidDateUpdatedWarning($dateInput, userSetDateInvalid);

            if (userSetValueDate && !userSetDateInvalid) {
                date = moment(userSetValueDate).local().format(userInfo.getDateFormat());
            }
            model.set({
                VALUE_DATE: date,
                TRAN_DATE: tranDate,
            });
        }

        if (model.get('TYPE') === 'INTL') {
            model.set({
                defaultForwardDays,
                maxAdvancedDays,
            });
        }

        model.set('ONUS_INDICATOR', (onUs ? 'Y' : 'N'));

        /*
         * When the selected beneficiary bank details are eligible for OnUs processing and the OnUs
         * payment date is earlier than the currently selected date, we will display a warning
         * message with the potential new payment date. In some cases (such as when updating the
         * debit account) multiple fields can cause this validation to occur simultaneously, so we
         * perform a simple sanity check to assure that this warning is not already displayed.
         */
        if (!dialog.activeModal && eligibleForOnusModalPrompt(businessDaysResponse, model)) {
            dialog.open(new OnUsWireDatePromptView({
                businessDaysResponse,
                model,
                $dateInput,
            }));
        }
    };

    const setValueDate = function () {
        if (model.jsonData.typeInfo.typeCode === 'INTL') {
            return; // FXPAYMENTWIDGET
        }

        if (!PaymentUtil.isReadyValueDateRetrieval(model)) {
            return;
        }

        if (model.jsonData.typeInfo.functionCode === 'INST') {
            const postData = {
                paymentType: model.jsonData.typeInfo.typeCode,
                debitBank: model.get('DEBIT_BANK_CODE'),
                debitCurrency: model.get('DEBIT_CURRENCY'),
                debitBankCountry: model.get('DEBIT_COUNTRY'),
                subType: model.jsonData.subtype === undefined ? '*' : model.jsonData.subtype,
                creditCurrency: model.get('CREDIT_CURRENCY'),
                creditBankCountry: model.get('BENE_BANK_COUNTRY'),
                beneBankId: model.get('BENE_BANK_ID'),
                beneBankType: model.get('BENE_BANK_TYPE'),
                valueDate: model.get('VALUE_DATE'),
                tranDate: model.get('TRAN_DATE'),
            };

            const dateService = services.generateUrl(constants.URL_GETDATESLIST);

            incrementOpenRequestCount();
            http.post(dateService, postData, (result) => {
                handleBusinessDaysResponse(result);
                decrementOpenRequestCount();
            }, (err) => {
                log.error(err);
                decrementOpenRequestCount();
            });
        }
    };

    const clearBankTypeFields = function (prefix) {
        if (prefix === 'BENE_BANK_' && !util.isEmpty(model.get('BENE_BANK_TYPE_MAP'))) {
            model.set('BENE_BANK_TYPE_MAP', '');
            return;
        }
        const modelSetList = {};

        /*
         * don't clear preferred-list fields. Those fields handle themselves with an
         * appropriately named template helper and clearing them like this will kick off
         * unneccesary change listeners
         */
        bankTypeClearingFieldsSuffix.forEach((field) => {
            const fieldName = prefix + field;

            if (!form.field(fieldName) || form.field(fieldName).$el.hasClass('preferred-list')) {
                return;
            }
            modelSetList[fieldName] = '';
            modelSetList[`${fieldName}_MAP`] = '';
            form.field(fieldName).shouldBeReadOnly(false);
            /*
             * Clean up previously selected bene bank and country codes
             */
            if (fieldName === 'BENE_BANK_ID' && model.get('TYPE') === 'INTL') {
                // This field doesn't match the prefix/suffix pattern
                modelSetList.BENE_COUNTRY_CODE = '';

                $('#s2id_BENE_BANK_ID .select2-chosen').empty();
                $('[data-hook="bene-country"]').text('');
            }
        });

        model.set(modelSetList);
    };

    const onCreditDebitChange = function () {
        if (model.jsonData.typeInfo.typeCode === 'INTL') {
            return; // FXPAYMENTWIDGET
        }
        if (model.get('TYPE') === 'INTL' && model.get('PRODUCT') === 'RTGS' && model.get('FUNCTION') === 'INST') {
            form.field('CREDIT_AMOUNT').setValue(model.get('CREDIT_AMOUNT'));
            form.field('DEBIT_AMOUNT').setValue(model.get('DEBIT_AMOUNT'));
        } else {
            model.set({
                CREDIT_AMOUNT: ' ',
                DEBIT_AMOUNT: ' ',
            });
            form.field('CREDIT_AMOUNT').setValue(' ');
            form.field('DEBIT_AMOUNT').setValue(' ');
        }
        showOrHideRateFields();
    };

    const mapIntermediaryBank = function (intermediaryBankMappingCollection) {
        const lookupModel = intermediaryBankMappingCollection.at(0);
        const type = model.jsonData.typeInfo.typeCode;
        if (lookupModel !== undefined) {
            const keys = lookupModel.keys();
            util.every(keys, function (columnName) {
                if (model.has(columnName)) {
                    form.field(columnName).shouldBeReadOnly(true);
                    model.set(columnName, this.get(columnName));
                    form.field(columnName).setValue(this.get(columnName));
                    if (form.field(columnName).isNotEmpty()) {
                        form.field(columnName).shouldBeVisibleWhen(true);
                    }
                }
                return true;
            }, lookupModel);
            if (type === 'INTL') {
                model.set('INTERMEDIARY_MAPPED', 'Y');
            }
        } else if (form.field('CORRESPONDENT_ID').isReadOnly() && model.get('ENTRYMETHOD') !== '1') {
            if (type === 'INTL') {
                model.set('INTERMEDIARY_MAPPED', 'N');
            }
            clearBankTypeFields('CORRESPONDENT_');
        }
        if (type === 'INTL') {
            if (!util.isEmpty(model.get('BENE_BANK_COUNTRY_MAP'))) {
                model.set('BENE_BANK_COUNTRY', model.get('BENE_BANK_COUNTRY_MAP'));
                $('[data-hook="bene-country"]').text(model.get('BENE_BANK_COUNTRY_MAP'));
            }
        }
    };

    const getIntermediaryBank = function () {
        const searchFields = [];

        if (model.get('BENE_BANK_ID') === '') {
            return;
        }

        if (util.contains(['FEDWIRE', 'MULTIBK'], model.get('TYPE'))) {
            return;
        }

        /*
         * NH-161208 Because this functionality is mutually exclusive with the preferred
         * intermediary bank lookup we should not execute this lookup when the other is enabled.
         * This will help to assure that only one intermediary lookup is performed and
         * prevent situations where the slowest response is the wrong one. Additionally
         * we can be certain that only one of the two processes will be actively deferring
         * or executing the deferred driver field screen refresh.
         */
        if (applicationConfiguration.getValue('WIRES', 'RTGSRESTRICTLOOKUPINTERMEDIARY') === '1') {
            return;
        }

        // NH-161208 learn more about this deferral in metaDrivenForm handleModelChangeEvents()
        if (!model.activeFieldRequests) {
            model.activeFieldRequests = [];
        }
        if (!model.activeFieldRequests.includes('INTERMEDIARY_ID')) {
            model.activeFieldRequests.push('INTERMEDIARY_ID');
        }

        searchFields.push({
            fieldName: 'CORRESPONDENT_TYPE',
            fieldValue: [model.get('BENE_BANK_TYPE')],
            dataType: 'text',
            operator: '=',
        });
        searchFields.push({
            fieldName: 'BENE_BANK_ID',
            fieldValue: [model.get('BENE_BANK_ID')],
            dataType: 'text',
            operator: '=',
        });
        const intermediaryBankMappingCollection = new IntermediaryBankMappingCollection(
            searchFields,
            19349,
        );
        intermediaryBankMappingCollection.fetch().then(() => {
            mapIntermediaryBank(intermediaryBankMappingCollection);

            model.activeFieldRequests = model.activeFieldRequests.filter(item => item !== 'INTERMEDIARY_ID');

            if (!model.activeFieldRequests?.length) {
                model.trigger('executeDeferredComboDriverField');
            }
        });
    };

    const disableEnableCreditCurrency = function () {
        if (fxPaymentUtil.USD_ONLY) {
            $('#CREDIT_CURRENCY').prop('disabled', true);
        } else {
            $('#CREDIT_CURRENCY').prop('disabled', false);
        }
    };

    /**
     * @method setupAmountBlur
     * @param {string} fieldName -  either 'DEBIT_AMOUNT' or 'CREDIT_AMOUNT'
     * @param {string} currencyField - either 'DEBIT_CURRENCY' or 'CREDIT_CURRENCY'
     * @param {string} amountFlag - 'C' or 'D'
     */
    const setupAmountBlur = function (fieldName, currencyField, amountFlag) {
        const isTemplate = model.get('FUNCTION') === 'TMPL';
        let isDebitSelected;
        let cond;
        form.field(fieldName)
            .$el.on('blur', () => {
                if (creditDebitBtn.isVisible()) {
                    isDebitSelected = creditDebitBtn.$el.prop('checked');
                    cond = isDebitSelected ? fieldName === 'DEBIT_AMOUNT' : fieldName === 'CREDIT_AMOUNT';
                    if (cond && model.get(currencyField) !== '') {
                        if (isTemplate) {
                            setDefaultAmount(fieldName);
                        }
                        /*
                         * fetches values from service that correspond with user inputed
                         * amount changes
                         */
                        doFieldValidation(fieldName, () => {
                            model.validateField(fieldName);
                        });
                    }
                } else if (model.get('TYPE') === 'DRAWDOWN' || model.jsonData.typeInfo.typeCode === 'DRAWDOWN') {
                    /*
                     * When DRAWDOWN payment is saved, 'ENTERED_AMOUNT_FLAG' is
                     * always set to 'D' at backend (NH-30217) regardless what value
                     * is received in the add payment request.
                     * Hence, we need to reset it to the proper value.
                     */
                    model.set('ENTERED_AMOUNT_FLAG', amountFlag);
                    if (isTemplate) {
                        setDefaultAmount(fieldName);
                    }
                    doFieldValidation(fieldName, () => {
                        model.validateField(fieldName);
                    });
                }
            });
    };

    const showOrderingParty = function (editable) {
        if (editable && (!util.isEmpty(model.get('ORIGINATOR_ID_TYPE')) || originatorIdType.isNotEmpty())) {
            originatorId.$el.parent().addClass('required');
            originatorId.shouldBeRequired();
        } else {
            originatorId.$el.parent().removeClass('required');
            originatorId.shouldBeOptional();
        }
    };

    /**
     * @method validateTemplateInfo
     * @param {boolean} saveTemplateChecked: true if the SAVEASTEMPLATE field is checked
     *
     * When the Save as Template checkbox is selected, display the Template Code,
     * Template Description, and Restricted checkbox.  Otherwise hide the fields.
     */
    const validateTemplateInfo = function (saveTemplateChecked) {
        if (model.jsonData.typeInfo.functionCode === 'INST') {
            const entryMethod = model.get('ENTRYMETHOD');
            // Clear out Template Code and Template Description when not visible
            if (!saveTemplateChecked && (!entryMethod || entryMethod === '0')) {
                model.set({
                    TEMPLATE_CODE: '',
                    TEMPLATE_DESCRIPTION: '',
                });
                templateCode.setValue('');
                templateDescription.setValue('');
            }
            templateCode.shouldBeVisibleWhen(saveTemplateChecked);
            templateDescription.shouldBeVisibleWhen(saveTemplateChecked);
            templateCode.setValidator(templateCode.$el, 'overrideError', 'isTemplateCode');
            restrict.shouldBeVisibleWhen(saveTemplateChecked);
            PaymentUtil.validateTemplateInfo({
                saveTemplateChecked,
                templateCode,
                templateDescription,
                tempCodeFieldName: 'TEMPLATE_CODE',
                tempDesFieldName: 'TEMPLATE_DESCRIPTION',
            }, model);
        }
    };

    const isValidDebitAccount = (payment) => {
        if (form.formView.comboCollections.ACCOUNTFILTER
            && payment.get('FUNCTION') === 'INST'
            && payment.get('ENTRYMETHOD') === '1'
            && (payment.get('TYPE') === 'FEDWIRE' || model.get('TYPE') === 'INTL')) {
            const selectedAcct = form.formView.comboCollections
                .ACCOUNTFILTER.find((acct) => {
                    const acctnum = acct.mapDataList.find(data => data.toField === 'ORIGINATOR_ID');
                    const bankcode = acct.mapDataList.find(data => data.toField === 'DEBIT_BANK_CODE');
                    return (acctnum.value === payment.get('DEBIT_ACCOUNT_NUMBER')
                        && bankcode.value === payment.get('DEBIT_BANK_CODE'));
                });

            return !!selectedAcct;
        }

        return true;
    };

    if (initialState) {
        fxPaymentUtil.evaluateUSDOnly(model, form.formView.comboCollections);

        disableEnableCreditCurrency();

        /* this is temporary until the fx payment widget is complete */
        model.listenTo(model, 'widget:update:value-date', () => {
            showOrHideRateFields();
            updateDebitOrCreditAmount();
            handlePostDateDisplay();
        });
        /* end temporary */

        model.set({
            PRODUCT: model.jsonData.typeInfo.productCode,
            FUNCTION: model.jsonData.typeInfo.functionCode,
            TYPE: model.jsonData.typeInfo.typeCode,
        });

        const state = form.formView.state.toUpperCase();
        showPostDate = serverConfigParams.get('showPostDate');
        // toggle info field for bene address 1 and/or bene address 2
        rtgsCommon.toggleAddressInfo(model, beneAddress1, beneAddress2);

        if (state === 'VIEW' && model.get('ERRORS')) {
            form.formView.$('[data-text="ERRORS"]').text(locale.getDefault(model.get('ERRORS'), model.get('ERRORS')));
        }

        if ((state === 'INSERT' || state === 'MODIFY' || state === 'RESTORE' || state === 'REPAIR') && model.get('FUNCTION') === 'INST') {
            if (model.get('ENTRYMETHOD') !== '1' || model.get('TYPE') !== 'INTL') {
                doFieldValidation('CREDIT_CURRENCY', () => {});
            } else if (model.get('TRANSACTION_CURRENCY') && model.get('CREDIT_CURRENCY') === ''
                && model.get('DEFAULTCREDITCURRENCYFLAG') === '1'
                && applicationConfiguration.getValue('WIRES', 'SUPPORTDEFINEDCURRENCYPAIRS') !== '1') {
                /*
                 * to eliminate the infinite page reload described in NH-21237 and make sure
                 * CREDIT_CURRENCY is populated
                 */
                model.set('CREDIT_CURRENCY', model.get('TRANSACTION_CURRENCY'));
            } else if (model.get('TYPE') === 'INTL' && model.get('ENTRYMETHOD') === '1' && (!model.get('EXCHANGE_RATE') || model.get('EXCHANGE_RATE') === '')) {
                doFieldValidation('CREDIT_CURRENCY', () => {});
            }

            setValueDate();
        }
        /*
         * Reload is called while changing the value,
         * onRender will reset the masking, re-apply masking.
         */
        if (model.get('CREDIT_CURRENCY') !== '') {
            setAmountMaskingFromCurrency('CREDIT_AMOUNT', model.get('CREDIT_CURRENCY'));
        }
        if (model.get('DEBIT_CURRENCY') !== '') {
            setAmountMaskingFromCurrency('DEBIT_AMOUNT', model.get('DEBIT_CURRENCY'));
        }

        if (model.get('ENTRYMETHOD') === '1' && model.get('FUNCTION') === 'INST' && (model.get('TYPE') === 'FEDWIRE' || model.get('TYPE') === 'DRAWDOWN')) {
            if (form.field('CORRESPONDENT_ID').isNotEmpty()) {
                form.field('CORRESPONDENT_ID').shouldBeReadOnly(true);
            }
            if (form.field('INTERMEDIARY_ID').isNotEmpty()) {
                form.field('INTERMEDIARY_ID').shouldBeReadOnly(true);
            }
        }

        // NH-46084 - HACK Allow intermediary combobox selection to be removed.
        $correspondentIdField.siblings('div').toggleClass('select2-allowclear');
        $intermediaryIdField.siblings('div').toggleClass('select2-allowclear');

        if (model.get('NoConfidentialAccess') === 'true') {
            hideConfidentialSections();
        }
        model.on('change:BENE_BANK_ID', () => {
            /*
             * must reset value date, transaction date and calendar,
             * depending on Bene settings
             */
            setValueDate();
            getIntermediaryBank();
        });

        model.on('change:ACCOUNTFILTER', () => {
            /*
             * must reset value date, transaction date and calendar,
             * depending on Bene settings
             */
            setValueDate();
            if (util.isEmpty(model.get('ACCOUNTFILTER')) && model.get('ENTRYMETHOD') === '1' && model.get('FUNCTION') === 'INST') {
                form.field('ACCOUNTFILTER_LOOKUP').$el.parent().addClass('hidden');
            }
        });

        if (model.get('TYPE') === 'DRAFT' && model.get('FUNCTION') === 'INST') {
            creditAmount.$el.on('change', () => {
                doFieldValidation('CREDIT_CURRENCY', () => {});
            });
        }

        if (model.get('ENTRYMETHOD') === '1') {
            getIntermediaryBank();
        }

        if (model.get('ENTRYMETHOD') === '1' && form.formView.state !== 'insert') {
            $('#TEMPLATE_CODE').closest('.section').addClass('hidden');

            templateNameNode = $('#ENTRYMETHOD_DESCRIPTION').closest('.row').clone();
            templateNameNode.find('.field-container').attr('id', 'TEMPLATENAME');
            if (form.formView.state === 'view') {
                templateNameNode.find('label').text(`${locale.get('PAY.Template')} : `);
            } else {
                templateNameNode.find('label').text(locale.get('PAY.Template'));
            }
            templateNameNode.find('p').text(model.get('TEMPLATE_CODE'));
            templateNameNode.find('p').attr('bind', 'TEMPLATENAME');
            $('#ENTRYMETHOD_DESCRIPTION').closest('.row').parent().append(templateNameNode);
        }

        if (!model.get('ERRORS')) {
            $('[class*=_AUDIT_ERROR]').hide();
        } else {
            if (form.fields.ERRORS) {
                form.fields.ERRORS.shouldBeReadOnly(true);
            }
            $('.ERRORS').addClass('read-only-field');
        }

        if (form.formView.state !== 'insert' && model.get('FUNCTION') === 'TMPL') {
            templateNameNode = $('#ENTRYMETHOD_DESCRIPTION').closest('.row').clone();
            templateNameNode.find('.field-container').attr('id', 'STATUS_DESCRIPTION');
            if (form.formView.state === 'view') {
                templateNameNode.find('label').text(`${locale.get('PAY.Status')} : `);
            } else {
                templateNameNode.find('label').text(locale.get('PAY.Status'));
            }
            templateNameNode.find('p').text(model.get('STATUS_DESCRIPTION'));
            templateNameNode.find('p').attr('bind', 'STATUS_DESCRIPTION');
            templateNameNode.insertBefore($('#ENTRYMETHOD_DESCRIPTION').closest('.row'));
        }

        if (model.jsonData.typeInfo.typeCode !== 'INTL') {
            if (util.isEmpty(model.get('DEBIT_ACCOUNT_NUMBER')) || (!util.isEmpty(model.get('DEBIT_ACCOUNT_NUMBER')) && model.get('DEBIT_CURRENCY') === model.get('CREDIT_CURRENCY'))) {
                debitAmount.shouldBeHidden();
                $('#DEBIT_AMOUNT').hide();
                if (creditDebitBtnExists) {
                    formView.$('#CREDIT_DEBIT_RADIO_BTN-Credit').parent().hide();
                    formView.$('#CREDIT_DEBIT_RADIO_BTN-Debit').parent().hide();
                } else {
                    creditDebitEntered.shouldBeHidden();
                }
            } else if (debitCurrency.getValue() !== creditCurrency.getValue()) {
                setCreditDebitFormValue(model.get('ENTERED_AMOUNT_FLAG') === 'C');
            }
        }

        model.on('change:CREDIT_CURRENCY', () => {
            if (model.get('CREDIT_CURRENCY') === '') {
                return;
            }
            disableEnableCreditCurrency();

            if (model.jsonData.typeInfo.typeCode !== 'INTL') {
                if (fxPaymentUtil.USD_ONLY) {
                    updateDebitOrCreditCurrency();
                    return;
                }
                doFieldValidation('CREDIT_CURRENCY', updateDebitOrCreditCurrency);
            } else {
                model.set('FUNCTION', model.jsonData.typeInfo.functionCode);
                updateDebitOrCreditCurrency();
                setAmountMaskingFromCurrency('CREDIT_AMOUNT', model.get('CREDIT_CURRENCY'));
            }
        });
        exchangeRate.$el.on('blur', () => {
            if (util.contains(['C', 'D'], model.get('ENTERED_AMOUNT_FLAG'))) {
                updateDebitOrCreditAmount();
            }
        });

        model.on('change:CREDIT_DEBIT_RADIO_BTN', () => {
            onCreditDebitChange();
        });

        model.on('change:CREDIT_DEBIT_ENTERED', () => {
            onCreditDebitChange();
        });

        model.on('change:EXCHANGE_RATE_CONTRACTID', () => {
            if (model.get('EXCHANGE_RATE_CONTRACTID') === '') {
                if (isDebitBtnSelected()) {
                    debitAmount.$el.trigger('blur');
                } else {
                    creditAmount.$el.trigger('blur');
                }
            } else if (contractId.isNotEmpty()) {
                showOrHideRateFields();
            }
        });

        bankCodeType.$el.on('change', (e) => {
            if (e.val) {
                clearBankTypeFields('BENE_BANK_');
            }
        });

        bankEntryMethod.$el.on('change', (e) => {
            if (e.val) {
                clearBankTypeFields('BENE_BANK_');
                form.formView.trigger('lookupHelperText:clear', 'BENE_BANK_ID');
            }
        });

        model.on('change:INTERMEDIARY_TYPE', () => {
            /*
             * If INTERMEDIARY_ID has also already been changed then we do not want to clear
             * out the new value. An example of this is when the user selects a new
             * beneficiary. Doing this sets a new INTERMEDIARY_TYPE and INTERMEDIARY_ID value
             * at the same time. Clearing out INTERMEDIARY_ID is only necessary when the user
             * manually changes the INTERMEDIARY_TYPE, making the INTERMEDIARY_ID no longer
             * valid.
             */
            const isInterBankFreeform = model.get('INTERBANKIDENTRYMETHOD') === 'FREEFORM';
            if (model.changed.INTERMEDIARY_ID || isInterBankFreeform) {
                return;
            }
            clearBankTypeFields('INTERMEDIARY_');
        });

        model.on('change:CORRESPONDENT_TYPE', () => {
            /*
             * If CORRESPONDENT_ID has also already been changed then we do not want to clear
             * out the new value. An example of this is when the user selects a new
             * beneficiary. Doing this sets a new CORRESPONDENT_TYPE and CORRESPONDENT_ID value
             * at the same time. Clearing out CORRESPONDENT_ID is only necessary when the user
             * manually changes the CORRESPONDENT_TYPE, making the CORRESPONDENT_ID no longer
             * valid.
             */
            if (model.changed.CORRESPONDENT_ID) {
                return;
            }

            clearBankTypeFields('CORRESPONDENT_');
            form.formView.trigger('lookupHelperText:update', 'CORRESPONDENT_ID');
            if (model.get('TYPE') === 'FEDWIRE') {
                if (model.get('CORRESPONDENT_TYPE') === '') {
                    model.set('CORRESPONDENT_TYPE', 'ABA');
                }
            }
        });

        model.on('change:CORRESPONDENT_ID', () => {
            if (util.isEmpty(model.get('CORRESPONDENT_ID'))) {
                form.formView.trigger('lookupHelperText:clear', 'CORRESPONDENT_ID');
                clearBankTypeFields('CORRESPONDENT_');
            }
        });

        model.on('change:INTERMEDIARY_ID', () => {
            const isInterBankFreeform = model.get('INTERBANKIDENTRYMETHOD') === 'FREEFORM';
            if (util.isEmpty(model.get('INTERMEDIARY_ID'))) {
                form.formView.trigger('lookupHelperText:clear', 'INTERMEDIARY_ID');
                if (isInterBankFreeform) {
                    return;
                }
                clearBankTypeFields('INTERMEDIARY_');
                addIntAddress.shouldBeVisible();
            }
        });

        setupAmountBlur('CREDIT_AMOUNT', 'CREDIT_CURRENCY', 'C');
        setupAmountBlur('DEBIT_AMOUNT', 'DEBIT_CURRENCY', 'D');

        if (form.formView.state.toUpperCase() === 'MODIFY' || form.formView.state.toUpperCase() === 'RESTORE') {
            if (functionCode.indexOf('TMPL') > -1) {
                templateCode.$el.parent().addClass('read-only-text').removeClass('required');
            }
        }

        if (functionCode.indexOf('TMPL') > -1) {
            $('[data-field="summary-date"]').hide();
            $('.summary-date-text').hide();
        }

        // Not applicable in the admin UI.
        if (configuration.isClient()) {
            setDefaultCharges();
        }

        model.on('change:DEBIT_CURRENCY', (changedModel, changedValue) => {
            if (model.get('DEBIT_CURRENCY') === '') {
                return;
            }
            setValueDate();
            if (model.jsonData.typeInfo.typeCode !== 'INTL') {
                if (fxPaymentUtil.USD_ONLY && changedValue !== 'USD') {
                    model.set({
                        CREDIT_CURRENCY: 'USD',
                    }, { silent: true });
                    return;
                }
            }
            updateDebitOrCreditCurrency();
            setAmountMaskingFromCurrency('DEBIT_AMOUNT', model.get('DEBIT_CURRENCY'));
        });

        model.on('change:DEBIT_BANK_CODE', () => {
            doFieldValidation('DEBIT_BANK_CODE', () => {});
        });

        model.on('change:DEBIT_COUNTRY', () => {
            setValueDate();
        });

        model.on('change:BENE_BANK_COUNTRY', () => {
            setValueDate();
        });

        model.on('change:BENEBANKIDENTRYMETHOD', () => {
            if (model.get('BENEBANKIDENTRYMETHOD') === 'FREEFORM') {
                setValueDate();
            }
        });

        const entryMethod = model.get('ENTRYMETHOD');
        if (model.jsonData.typeInfo.functionCode === 'INST') {
            if (entryMethod === '1' || entryMethod === '3') {
                saveAsTemplate.$el.closest('.section').addClass('hidden');
            } else {
                saveAsTemplate.$el.closest('.section').appendTo($saveAsTemplate);
                // Implement redirected view binding
                viewBinding.bindAdditional(formView, $saveAsTemplate);

                saveAsTemplate.$el.closest('.section').removeClass('section section-container');
                templateCode.setValue('');
                templateDescription.setValue('');

                // HACK (looks like a metadata issue)
                if ($saveAsTemplate.children('fieldset').length > 1) {
                    $saveAsTemplate[0].removeChild($saveAsTemplate[0].childNodes[1]);
                }
            }
        }

        if (form.formView.state === 'view' && (model.get('ORDEROF_ACCOUNT') === undefined || model.get('ORDEROF_ACCOUNT') === '')) {
            orderOfAccount.$el.parent().hide();
        }

        // getIndicativeRate();
        showOrHideRateFields();
        // This will hid it in INSERT mode
        companyName.shouldBeVisibleWhen(configuration.isAdmin());
        // This will hide it in VIEW mode
        toggleElementVisibility($companyName, configuration.isAdmin());

        // This will open the optional sections that are populated in modify mode
        openOptionalSections();

        // This will keep the optional sections open if they have a required field
        protectRequiredOptionalSections();

        // handle post date
        handlePostDateDisplay();

        if (form.formView.state.toUpperCase() === 'REPAIR') {
            Object.keys(form.fields || {}).forEach((daField) => {
                if (daField === 'CREDIT_AMOUNT' || daField === 'VALUE_DATE') {
                    return;
                }
                if (Object.prototype.hasOwnProperty.call(form.fields, daField)) {
                    form.fields[daField].shouldBeReadOnly(true);
                }
            });
            $('.lookup').addClass('hidden');
        }
        restrict.$el.on('click, change', () => {
            if (restrict.$el.is(':checked')) {
                model.set('RESTRICTTEMPLATE_FLAG', '1');
                form.formView.model.set('RESTRICTTEMPLATE_FLAG', '1');
            } else {
                model.set('RESTRICTTEMPLATE_FLAG', '');
                form.formView.model.set('RESTRICTTEMPLATE_FLAG', '');
            }
        });

        /*
         * The configParam ProtectOriginator controls if the originator info
         * is protected or not. If protected, the ORIGINATOR_ID_TYPE field is sent
         * in the MDF model as a protected TEXTBOX
         */
        if (form.formView.state !== 'view' && util.contains(['FEDWIRE', 'INTL', 'MULTIBK'], model.get('TYPE'))) {
            // If there is no data, show the field as a protected textbox.
            if (model.fieldData.ORIGINATOR_ID_TYPE.fieldUIType === 'TEXTBOX') {
                /*
                 * Set the initial display state to '--' for insert since the
                 * initial values are empty until the debit account is selected
                 */
                if (originatorIdType) {
                    originatorIdType.shouldBeReadOnly(true).$el.parent().addClass('read-only-text');
                }
                if (originatorId) {
                    originatorId.shouldBeReadOnly(true).$el.parent().addClass('read-only-text');
                }
                if (originatorName) {
                    originatorName.shouldBeReadOnly(true).$el.parent().addClass('read-only-text');
                }
                if (originatorAddr1) {
                    originatorAddr1.shouldBeReadOnly(true).$el.parent().parent().addClass('read-only-text');
                }
                if (originatorAddr2) {
                    originatorAddr2.shouldBeReadOnly(true).$el.parent().parent().addClass('read-only-text');
                }
                if (originatorCity) {
                    originatorCity.shouldBeReadOnly(true).$el.parent().parent().addClass('read-only-text');
                }
                if (originatorCountry) {
                    originatorCountry.shouldBeReadOnly(true).$el.parent().addClass('read-only-text');
                }
                if (originatorLink) {
                    originatorLink.$el.parent().addClass('hide');
                }
                showOrderingParty(false);
            } else {
                model.on('change:ORIGINATOR_ID_TYPE', () => {
                    showOrderingParty();
                });
                showOrderingParty(true);
            }
        }

        if (!maskConfig.disableMask && model.get('TYPE') !== 'MULTIBK') {
            originatorId.$el.parent().addClass('hide');
            $originatorId.find('label').text('');
        }

        if (form.formView.state.toUpperCase() === 'INSERT' && (model.fieldData.MAX_SIGNATURES_OVERRIDE) && model.get('MAX_SIGNATURES_OVERRIDE') === '' && model.fieldData.MAX_SIGNATURES_OVERRIDE_DEFAULT) {
            model.set('MAX_SIGNATURES_OVERRIDE', model.fieldData.MAX_SIGNATURES_OVERRIDE_DEFAULT.value);
        }

        /*
         * Need to show the user a warning if the contact used in this payment has
         * been modified or deleted.
         * This flag is set on contact approval/deletion
         */
        const contactHasBeenUpdatedValue = model.get('CONTACTHASBEENUPDATED');
        const orderingPartyUpdatedValue = orderingPartyUtil.isEntitled(model.get('ALLOWBYORDEROF'))
            ? model.get('ORDERINGPARTYCONTACTUPDATED') : '';
        const isApplicableActionMode = form.formView.state.toUpperCase() === 'MODIFY' || (form.formView.state.toUpperCase() === 'INSERT' && model.context.createdFrom === '1');
        if (isApplicableActionMode
            && (contactHasBeenUpdatedValue === 'YES' || contactHasBeenUpdatedValue === 'INVALID'
            || orderingPartyUpdatedValue === 'YES' || orderingPartyUpdatedValue === 'INVALID')
            && !model.get('beneWarningShown')) {
            model.set('beneWarningShown', true);
            const beneUpdatedWarning = new BeneUpdatedWarning({
                model,
                contactHasBeenUpdatedValue,
                orderingPartyUpdatedValue,
                formView,
            });
            dialog.custom(beneUpdatedWarning);
        } else if (model.get('beneWarningShown') && model.get('TYPE') === 'INTL'
                    && formView.state === 'modify' && util.isEmpty(model.get('BENE_BANK_ID'))) {
            /*
             * Need to clear out the beneficiary country setting on screen for international wire
             * if the user selected to update the payment with modified contact info.
             */
            $('[data-hook="bene-country"]').text('');
        }

        // protect lookup icon if necessary, for bene_bank_id lookup
        PaymentUtil.hideLookupIfProtected('BENE_BANK_ID_LOOKUP', 'BENE_BANK_IDLOOKUP', model, form.formView);

        validateTemplateInfo(saveAsTemplate.$el.is(':checked'));
        saveAsTemplate.$el.on('change', e => validateTemplateInfo(e.target.checked));

        // if this is template and recurring, make sure amount is required
        if (functionCode === 'TMPL'
                && (model.get('SCHEDULED')
                || (model.schedModel && model.schedModel.get('onN'))
                || (this.parentModel && this.parentModel.get('recur')))) {
            if (model.get('TYPE') !== 'FEDTAX') {
                const isDebitAmt = model.get('ENTERED_AMT_FLAG') === 'D'
                    || model.get('CREDIT_DEBIT_ENTERED') === 'Debit';
                const isFxAmount = model.fieldData.FXPAYMENTWIDGET
                    && model.fieldData.FXPAYMENTWIDGET.visible;
                const $creditField = $(`[name="${'CREDIT_AMOUNT'}"]`);
                const $debitField = $(`[name="${'DEBIT_AMOUNT'}"]`);
                PaymentUtil.toggleAmountRequired($creditField, 'CREDIT_AMOUNT', model, !isDebitAmt, isFxAmount);
                PaymentUtil.toggleAmountRequired($debitField, 'DEBIT_AMOUNT', model, isDebitAmt, isFxAmount);
            } else {
                form.field('AMOUNTTYPE1').shouldBeRequired(true);
                form.field('AMOUNTTYPE1').$el.parent().addClass('required');
                const $amountField = $(`[name="${'AMOUNT1'}"]`);
                PaymentUtil.toggleAmountRequired($amountField, 'AMOUNT1', model, true);
            }
        }

        if (!isValidDebitAccount(model) && form.formView.state.toUpperCase() !== 'VIEW') {
            const warningTitle = locale.get('RTGS.INST.invalidAccount.warning.title', model.get('DEBIT_ACCOUNT_NUMBER'));
            const warningMsg = locale.get('RTGS.validate.invalidTemplateAccount', model.get('DEBIT_ACCOUNT_NUMBER'));
            dialog.alert(warningMsg, warningTitle);
        }
    }

    // Cross-Currency Support
    if (isCreditDebitSelectionExists()) {
        const isTemplate = model.get('FUNCTION') === 'TMPL';
        const isScheduled = model.schedModel || model.get('SCHEDULED') || this.parentModel.get('recur');

        if (isDebitBtnSelected()) {
            debitAmount.shouldBeReadOnly(false);
            if (!isTemplate || isScheduled) {
                debitAmount.shouldBeRequired(true);
            }

            creditAmount.shouldBeReadOnly(true);
            creditAmount.shouldBeOptional(true);

            model.set({
                ENTERED_AMOUNT_FLAG: 'D',
                CREDIT_AMOUNT: 0,
            });
        } else {
            creditAmount.shouldBeReadOnly(false);
            if (!isTemplate || isScheduled) {
                creditAmount.shouldBeRequired(true);
            }

            debitAmount.shouldBeReadOnly(true);
            debitAmount.shouldBeOptional(true);

            model.set({
                ENTERED_AMOUNT_FLAG: 'C',
                DEBIT_AMOUNT: 0,
            });
        }
    }

    if (form.formView.state === 'insert') {
        // Hide the audit section
        const aFieldInAuditSection = form.field('VIEWHISTORY');
        aFieldInAuditSection.$el.closest('.section').hide();

        // If this is not a create payment from template then hide the summary section
        if (!model.get('CMB_TEMPLATE_CODE') || model.context.createdFrom !== '1' || model.context.functionCode !== 'INST') {
            $summarySection.hide();
        }
        if (model.get('TYPE') === 'INTL' && util.isEmpty(model.get('BENE_BANK_ID')) && model.get('BENEBANKIDENTRYMETHOD') === 'LOOKUP') {
            $('[data-hook="bene-country"]').text('');
        }
    } else if (form.formView.state === 'modify' && model.get('BENEBANKIDENTRYMETHOD') === 'FREEFORM'
            && util.isEmpty(model.get('BENE_BANK_NAME')) && model.get('TYPE') === 'INTL') {
        model.set('BENE_BANK_COUNTRY', '');
    }

    // hide the view disclosure if they are not set up for it or its in Incomplete status
    if (model.get('FUNCTION') === 'TMPL' || form.formView.state !== 'view' || model.get('CONINTLWIREFLAG') !== '1'
        || model.get('STATUS') === 'NR' || model.get('STATUS') === 'IC' || form.formView.options.importView) {
        const viewDisclosureLink = form.field('VIEWDISCLOSURE');
        viewDisclosureLink.$el.closest('.section').hide();
    }

    /**
     * NH-44250
     * For FREEFORM, hide the bene bank id, because its value BLANK is used just
     * to leverage the same helptext widget code
     * to display the bene info, also set the value of BENE_BANK_COUNTRY
     * to description to display in the helptext
     */
    if (form.formView.state === 'view' && (model.get('TYPE') === 'INTL' || model.get('TYPE') === 'FEDWIRE')) {
        $('#BENE_BANK_ID').find('label').text(locale.get('ACH.Bank'));
        if (model.get('BENEBANKIDENTRYMETHOD') === 'FREEFORM') {
            $('#BENE_BANK_ID .form-control-static ').addClass('hide');
            $('#BENE_BANK_ID .lookup-helper-text ').removeClass('lookup-helper-text-view').addClass('lookup-helper-text-view-freeform');
            freeFormBeneBankCountry = util.findWhere(
                form.formView.comboCollections.BENE_COUNTRY,
                {
                    name: model.get('BENE_BANK_COUNTRY'),
                },
            );
            if (freeFormBeneBankCountry) {
                $('[data-hook="bene-country"]').text(freeFormBeneBankCountry.label);
            }
        }
    }

    /*
     * Set originator account to read only if usergroup is not allowed for
     * locked fields on payment from template
     */
    if (model.get('RTGSALLOWLOCKEDFIELDSFROMTMPL') === '0' && model.get('TYPE') === 'INTL'
        && ['insert', 'modify'].includes(form.formView.state)) {
        form.field('ACCOUNTFILTER').shouldBeReadOnly(true);
    }

    // Resolve the Paymode Method
    if (form.formView.state === 'view' && model.get('TYPE') === 'PAYMODE' && productDesc.length > 0) {
        productDesc.text(locale.get(productDesc[0].innerText));
    }
}

export {
    eligibleForOnusModalPrompt,
};
