var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"required "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"blockClass") || (depth0 != null ? lookupProperty(depth0,"blockClass") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"blockClass","hash":{},"data":data,"loc":{"start":{"line":2,"column":25},"end":{"line":2,"column":39}}}) : helper)))
    + "\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"blockClass") || (depth0 != null ? lookupProperty(depth0,"blockClass") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"blockClass","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":30}}}) : helper)))
    + "\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"icon-search\" tabindex=\"0\" advanced-combo></span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"mandatory") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":5,"column":7}}})) != null ? stack1 : "")
    + "    <label for=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldName") || (depth0 != null ? lookupProperty(depth0,"fieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldName","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":29}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldLabel") || (depth0 != null ? lookupProperty(depth0,"fieldLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldLabel","hash":{},"data":data,"loc":{"start":{"line":6,"column":31},"end":{"line":6,"column":45}}}) : helper)))
    + "</label>\n    <input type=\"hidden\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldName") || (depth0 != null ? lookupProperty(depth0,"fieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldName","hash":{},"data":data,"loc":{"start":{"line":7,"column":29},"end":{"line":7,"column":42}}}) : helper)))
    + "\" data-filter-type=\"multi-select\" class=\"type-ahead "
    + alias4(((helper = (helper = lookupProperty(helpers,"cssClass") || (depth0 != null ? lookupProperty(depth0,"cssClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cssClass","hash":{},"data":data,"loc":{"start":{"line":7,"column":94},"end":{"line":7,"column":106}}}) : helper)))
    + "\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldName") || (depth0 != null ? lookupProperty(depth0,"fieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldName","hash":{},"data":data,"loc":{"start":{"line":7,"column":114},"end":{"line":7,"column":127}}}) : helper)))
    + "\" data-bind=\"model\">\n    <span class=\"help-block\" data-bind=\"model\" data-validate=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldName") || (depth0 != null ? lookupProperty(depth0,"fieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldName","hash":{},"data":data,"loc":{"start":{"line":8,"column":62},"end":{"line":8,"column":75}}}) : helper)))
    + "\"></span>\n    <div class=\"mdf-input-icon-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"advanced") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":12,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"lockIcon"),depth0,{"name":"lockIcon","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"usePartial":true,"useData":true});