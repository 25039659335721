import EntryView from 'common/dynamicPages/views/workflow/entry';
import ImportModel from 'common/dynamicPages/models/import';
import f1 from 'system/utilities/f1';
import $ from 'jquery';
import util from '@glu/core/src/util';
import scroll from 'common/util/scroll';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import alert from '@glu/alerts';
import userInfo from 'etc/userInfo';
import GridApi from 'common/dynamicPages/api/grid';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import domUtil from 'common/util/domUtil';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import importRemitterNameTmpl from './importRemitterName.hbs';

let form = null;

export default EntryView.extend({
    template: importRemitterNameTmpl,
    loadingTemplate: loadingPageTmpl,
    className: 'full-page',

    ui: {
        alertRegion: '.alert-region',
        $cancelButton: 'div.widget-action-btn-group button[name="cancel"]',
        $fileImport: '#FILESELECTION',
        $progressBar: '.progress-bar',
    },

    initialize(options) {
        form = f1.getForm(this);

        EntryView.prototype.initialize.call(this, options);

        this.buttonList = [];
        this.buttonList.push({
            action: 'FILEIMPORT',
        });
        this.buttonList.push({
            action: 'FILEIMPORTREFRESH',
        });
        this.buttonList.push({
            action: 'CANCEL',
        });

        this.import = new ImportModel();
        this.listenTo(this.import, 'change:percentComplete', this.showProgressBar);
        this.listenTo(this.appBus, 'create:success', this.handleImportSuccess);
        this.listenTo(this.appBus, 'create:error', this.handleImportError);
    },

    onRender() {
        const self = this;
        if (!this.hasLoadedRequiredData()) {
            self.setHasLoadedRequiredData(true);
            self.render();
        } else {
            this.createMapFormatGrid();
        }
    },

    checkFormatType(cid) {
        if (cid === undefined || cid === '') {
            const message = alert.danger(
                'You must select a format type',
                {
                    title: 'Error',
                },
            );
            this.ui.alertRegion.append(message.render().el);
            return false;
        }
        return true;
    },

    fileimport() {
        const cid = this.$('input[type="radio"]:checked', '.table').attr('data-model-cid');
        const self = this;
        const { files } = this.ui.$fileImport[0];
        const [file] = files;

        if (!this.checkFileName()) {
            return false;
        }

        if (files && file) {
            if (!this.checkFormatType(cid)) {
                return false;
            }

            const gridModel = this.mapFormatView.grid.collection.get(cid);
            const gmc = gridModel.get('GENERICMAPPERCODE');

            const reader = new FileReader();

            reader.onload = function (readerEvt) {
                // show our progress bar
                self.ui.$progressBar.parent().removeClass('hide');
                const binaryString = readerEvt.target.result;
                const payValues = [];

                payValues.push({
                    name: 'GENMAPPERCODE',
                    value: gmc,
                });
                self.import.set('fileName', file.name);
                self.import.set('importType', 'LBXRNIMPQ');
                self.import.set('eventName', 'LBX Remitter Name Import');
                self.import.set('importJCode', 'LBXRNIMP');
                // self.import.set('GENERICMAPPERCODE', gmc);
                try {
                    /*
                     * NH-54415 Remove the use of window.btoa() to support import file
                     * with French characters
                     */
                    self.import.set('fileContent', binaryString);
                } catch (e) {
                    self.actionErrorForDetail({
                        responseJSON: {
                            message: [locale.get('common.fileimport.invalidchar')],

                            confirms: {
                                confirmResults: [{
                                    confirmData: null,
                                    messages: [locale.get('common.fileimport.invalidchar')],
                                }],
                            },
                        },
                    });
                    return;
                }
                self.import.set('importParameters', payValues);
                self.import.set('paymentData', []);
                self.import.save(
                    {},
                    {
                        success(model, confirmResponse) {
                            self.appBus.trigger('create:success', confirmResponse);
                        },

                        error(model) {
                            self.appBus.trigger('create:error', model.error);
                        },
                    },
                );
            };
            /*
             * NH-56820 To support French characters in the import, we enable user to
             * specify the
             * encoding of the file through
             * configure the importCharset in the ConfigParams table.  Use it during
             * import so that
             * the content can be properly
             * imported, if not set, use the default encoding.
             */
            const encoding = serverConfigParams.get('importCharset');

            if (!util.isNullOrUndefined(encoding)) {
                reader.readAsText(file, encoding);
            } else {
                reader.readAsText(file);
            }
        } else {
            $('[data-validate="FILESELECTION"]').text('You must select a file to upload');
            let { className } = $('#FILESELECTION')[0].parentElement;
            className += ' has-error';
            $('#FILESELECTION')[0].parentElement.className = className;
            scroll.scrollToFirstError();
        }
        return undefined;
    },

    handleImportError(response) {
        this.clearErrors();
        const errors = response.confirms
            && response.confirms.confirmResults.length > 0
            ? response.confirms.confirmResults[0].messages : response.messages;
        this.showErrors(errors);
    },

    handleImportSuccess(response) {
        this.clearErrors();
        store.set('importSuccessMessage', response.confirms.confirmResults[0].messages);
        this.navigateTo('REPORTING/lockbox/remitters');
    },

    cancel() {
        window.history.back();
    },

    showErrors(messages) {
        util.each(messages, this.showError, this);
    },

    showError(message) {
        const alertView = alert.danger(message);
        this.ui.alertRegion.append(alertView.render().el);
    },

    clearErrors() {
        this.ui.alertRegion.empty();
    },

    checkFileName() {
        const fileNameWithPath = form.field('FILESELECTION').getValue();
        let fileNameWithoutPath = '';
        let lastIndex = -1;
        if (fileNameWithPath.indexOf('\\') !== -1) {
            lastIndex = fileNameWithPath.lastIndexOf('\\');
            fileNameWithoutPath = fileNameWithPath
                .substring(lastIndex + 1, fileNameWithPath.length);
        }
        if (fileNameWithoutPath !== '' && fileNameWithoutPath.length > 255) {
            $('[data-validate="FILESELECTION"]').text(locale.get('ACH.UploadFileNameLength.Error'));
            let { className } = $('#FILESELECTION')[0].parentElement;
            className += ' has-error';
            $('#FILESELECTION')[0].parentElement.className = className;
            return false;
        }
        return true;
    },

    createMapFormatGrid() {
        const context = {};
        util.extend(context, this.contextDef);

        context.serviceName = '';

        const inquiryGridOptions = {
            inquirySearchCriteria: {
                inquiryId: 22487,
                searchType: 5,
            },

            additionalSearchFields: [{
                fieldName: 'USERGROUP',
                operator: '=',
                dataType: 'text',
                fieldValue: [userInfo.get('group')],
            }],

            enableSavedViews: false,
            filter: false,
            selector: 'none',
            enableRowContextButtonCallbacks: true,
            context,
            hideButtons: true,
        };

        this.mapFormatView = GridApi.createInquiryGridView(inquiryGridOptions);

        if (this.mapFormatRegion) {
            this.mapFormatRegion.show(this.mapFormatView);
        }
    },

    fileimportrefresh() {
        form.field('FILESELECTION').setValue('');
        this.$('input[type="radio"]:checked', '.table').prop('checked', false);
    },

    disableButtons() {
        domUtil.setDisabled(this.ui.$cancelButton, true);
    },

    showProgressBar() {
        const percent = `${this.import.get('percentComplete')}%`;

        this.ui.$progressBar.attr('aria-valuenow', this.import.get('percentComplete'))
            .css('width', percent).html(percent);
    },
});
