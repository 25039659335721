var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n        <div class=\"col-md-6\">\n                <div class=\"field-container baiTypes\">\n                        <label class=\"required\" for=\"BAITYPE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"type.export.camtexp",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":62},"end":{"line":4,"column":94}}}))
    + "</label>\n                        <input type=\"hidden\" class=\"form-control\" data-hook=\"getCurrentPriorDropdown\" name=\"BAITYPE\"> \n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"BAITYPE\"></span>\n                </div>\n        </div>\n        <div class=\"col-md-6\">\n                <div class=\"field-container required\" data-hook=\"getExportConditionalField\">\n                        <label for=\"exportCVSDate\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":51},"end":{"line":11,"column":75}}}))
    + "</label>\n                        <div class=\"search-input-wrapper\">\n                                <label for=\"exportCSVDate\" class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.searchValue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":75},"end":{"line":13,"column":106}}}))
    + "</label>\n                                <input class=\"form-control input-date\"\n                                        type=\"text\"\n                                        data-hook=\"getDateInput\"\n                                        data-bind=\"model\"\n                                        name=\"DATE\"\n                                        value=\"\"\n                                        id=\"exportCSVDate\"\n                                        aria-required=\"true\"\n                                        required />\n                                <span class=\"help-block\" data-bind=\"model\" data-validate=\"DATE\"></span>\n                        </div>\n                </div>\n        </div>\n</div>\n<div class=\"row\">\n        <div class=\"col-md-6\">\n                <div class=\"field-container outputContents\">\n                        <label class=\"required\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.ExportType.OutputContent",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":48},"end":{"line":31,"column":89}}}))
    + "</label>\n                        <input type=\"hidden\" class=\"form-control\" data-hook=\"getOutputContentDropdown\" name=\"OUTPUTCONTENT\">\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"OUTPUTCONTENT\"></span>\n                </div>\n        </div>\n</div>";
},"useData":true});