var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"checkbox\">\n        <div class=\"checkbox-inline\">\n            <input id=\"historyChk\" type=\"checkbox\" data-bind=\"model\" data-hook=\"historyChk\" name=\"globalSettings.HISTORY.value\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":128},"end":{"line":4,"column":159}}})) != null ? stack1 : "")
    + ">\n            <label for=\"historyChk\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"paymentHistory.download",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":36},"end":{"line":5,"column":72}}}))
    + "</label>\n        </div>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"checkbox\">\n        <input type=\"checkbox\" id=\"selectAllAdmin\" class=\"select-all-admin\">\n        <label for=\"selectAllAdmin\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.selectall",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":36},"end":{"line":13,"column":65}}}))
    + "</label>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showImportHistoryDownloadCheckbox") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":8,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":0},"end":{"line":15,"column":11}}})) != null ? stack1 : "")
    + "\n<div data-region=\"groupRegion\" class=\"row group-region\"></div>";
},"useData":true});