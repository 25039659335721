import util from '@glu/core/src/util';
import ListView from 'common/dynamicPages/views/workflow/list';
import workspaceHelper from 'common/workspaces/api/helper';
import store from 'system/utilities/cache';
import template from './rxpAccountListView.hbs';

const checkfreeRxpAccountList = ListView.extend({

    template,

    initialize(options) {
        const superOptions = {
            menuCategory: 'CHECKFREE',
            serviceName: '/checkFreeRXPAccounts',
            serviceFunc: null,
            businessType: null,
            enableSavedViews: true,
            enableDateTimeSupport: true,
            context: 'CHECKFREE_RXP',
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    gridRowSelect(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo('ADMINSTRATION/viewCheckfreeRxpAccountDetails');
        return Promise.resolve();
    },

});

workspaceHelper.publishedWidgets.add({
    id: 'CFRXPACCOUNTS',
    view: checkfreeRxpAccountList,
    options: {},
});

export default checkfreeRxpAccountList;
