var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tile-container col-md-4\">\n    <div class=\"tile\">\n            <div class=\"tile-content\">\n\n                    <div>\n                            <a role=\"button\" class=\"title btn-link view-bill\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"beneficiary") || (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"beneficiary","hash":{},"data":data,"loc":{"start":{"line":6,"column":78},"end":{"line":6,"column":93}}}) : helper)))
    + "</a>\n                    </div>\n\n                    <div>\n                        <div class=\"form-group\">\n                                <input class=\"form-control\" type=\"text\" name=\"\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"paymentAmount") || (depth0 != null ? lookupProperty(depth0,"paymentAmount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"paymentAmount","hash":{},"data":data,"loc":{"start":{"line":11,"column":87},"end":{"line":11,"column":104}}}) : helper)))
    + "\" id=\"\" />\n                        </div>\n                        <div class=\"form-group\">\n                                        <div class=\"due-date-container\"></div>\n                        </div>\n                                <br/><br/>\n                                <!--\n                        <div class=\"form-group\">\n                                <button type=\"button\" class=\"btn btn-primary\">Pay Today</button>\n                        </div>\n                                -->\n                    </div>\n\n            </div>\n    </div>\n</div>\n\n";
},"useData":true});