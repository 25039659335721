import locale from '@glu/locale';
import WorkspaceView from 'common/workspaces/views/workspace';
import HolidaysListView from 'app/administration/views/holidays/holidaysView';

export default (dgbAdminActions = {}) => Object.assign(dgbAdminActions, {// eslint-disable-line
    holidaysView() { // PCM-5 and other PCM-related admin actions
        if (this.processDeeplink('ADMINSTRATION/holidaysView')) {
            this.showPage('', new HolidaysListView());
        }
    },

    holidaysWorkspace() { // PCM-5 and other PCM-related admin actions
        this.showPage(locale.get('menuitem.holidays.management'), new WorkspaceView({
            id: 'HOLIDAYS',
            returnRoute: 'ADMINSTRATION/holidays',
        }));
    },
});
