/* eslint-disable */
/*
 * Route Context - branding override
 * Routes can be added or removed here. Additionally, the context can be changed
 * depending on the client requirements.
 */
export const BioCatchRouteContext = {
    '/ui': 'HOME_PAGE',
    '/ui/workspace': 'HOME_PAGE',
    '/ui/PAYMENTS/managePayments': 'PAYMENT_LIST',
    '/ui/PMTS/insert/payments/corp/PAY_LIST_VIEW': 'MAKE_PAYMENT',
    '/ui/PAYMENTS/addPaymentFromTemplate': 'MAKE_PAYMENT_TEMPLATE',
    '/ui/PAYMENTS/quickEntry': 'MAKE_PAYMENT_QUICK',
    '/ui/PMTS/import/payments/corp/PAY_LIST_VIEW': 'MAKE_PAYMENT_IMPORT',
    '/ui/PMTS/insert/templates/corp/TEMP_LIST_VIEW': 'MAKE_TEMPLATE',
    '/ui/PAYMENTS/manageTemplateGroups': 'MANAGE_TEMPLATE_GROUPS',
    '/ui/PMTS/import/templates/corp/TEMP_LIST_VIEW': 'MAKE_TEMPLATE_IMPORT',
    '/ui/PAYMENTS/manageTransfers': 'TRANSFER_LIST',
    '/ui/PAYMENTS/addTransfer/SINGLE': 'MAKE_TRANSFER_SINGLE',
    '/ui/PAYMENTS/addTransfer/ONE2ONE': 'MAKE_TRANSFER_MULTI',
    '/ui/PAYMENTS/addTransfer/ONE2MANY': 'MAKE_TRANSFER_O2M',
    '/ui/PAYMENTS/addTransfer/MANY2ONE': 'MAKE_TRANSFER_M2O',
    '/ui/PAYMENTS/addTransferByTemplate/CathTransferSet': 'MAKE_TRANSFER_TEMPLATE',
    '/ui/PAYMENTS/manageStops': 'STOPS_LIST',
    '/ui/CM/multiAdd/STOP': 'MAKE_STOP',
    '/ui/undefined/insert/null/PAY_CM_IV_LIST': 'MAKE_CANCEL_STOP',
    '/ui/undefined/import/null/PAY_CM_IV_LIST': 'MAKE_STOP_IMPORT',
};
