import locale from '@glu/locale';
import Model from '@glu/core/src/model';
import services from 'services';
import { postData } from 'common/util/services';

const BASE_URL = 'export/transmissionProfile/';

export default Model.extend({
    validators: {
        name: {
            exists: true,
            description: locale.get('GIR.Profile.Name'),
        },
    },

    /**
     * Save the model data to the server
     * @returns {Promise}
     */
    save() {
        return postData(services.generateUrl(`${BASE_URL}add`), this.toJSON());
    },

    /**
     * Get the data for the transmission profile based on id
     * @param {string} id - unique id for this transmission profile
     * @returns {Promise}
     */
    fetch(id) {
        const data = {
            identifier: id,
        };
        return postData(services.generateUrl(`${BASE_URL}read`), data)
            .then(this.set.bind(this));
    },
});
