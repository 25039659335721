import Router from '@glu/core/src/router';
import Glu from '@glu/core/src/glu';
import { appBus } from '@glu/core';
import $ from 'jquery';
import util from '@glu/core/src/util';
import configuration from 'system/configuration';
import store from '@glu/store';
import ErrorView from 'system/error/views/base';
import dynamiclinks from 'system/dynamiclinks/configuration';
import userInfo from 'etc/userInfo';

function listenToKeyboard() {
    const bindings = {};
    const self = this;

    util.each(this.keyBindings, (value, key) => {
        let localKey = key;
        localKey = localKey.toUpperCase();
        bindings[localKey] = value;
    });

    $('html').on('keydown', (e) => {
        if (!Glu.History.started) {
            return;
        }

        // Ignore keydown on input areas
        const tag = e.target.tagName;
        if (tag === 'INPUT' || tag === 'SELECT' || tag === 'TEXTAREA' || tag === 'BUTTON' || tag.isContentEditable) {
            return;
        }

        const key = String.fromCharCode(e.keyCode);
        const action = bindings[key];

        if (!action) {
            return;
        }

        if (typeof action === 'function') {
            action.call(self);
        } else {
            self.navigate(action, true);
        }
    });
}

const originLoadUrl = Glu.History.prototype.loadUrl;
const originNavigate = Glu.History.prototype.navigate;
util.extend(
    Glu.History.prototype,
    {
        loadUrl(fragment, ...rest) {
            let localFragment = fragment;
            this.fragment = this.getFragment(localFragment);
            localFragment = this.fragment;
            const isAdmin = userInfo.get('administrator') === 'true' ? true : userInfo.get('administrator');
            if (isAdmin === 'true' && localFragment.indexOf('admin') === 0) {
                window.location.replace(configuration.appRoot);
            } else {
                originLoadUrl.apply(this, [fragment, ...rest]);
            }
        },
        navigate(fragment, options) {
            const escapedRoot = this.root.replace(/\//g, '\\/');
            const cleanedFragment = fragment.replace(new RegExp(escapedRoot, 'g'), '');
            originNavigate.apply(this, [cleanedFragment, options]);
        },
    },
);

export default Router.extend({
    constructor(...args) {
        Router.prototype.constructor.apply(this, Array.prototype.slice.call(args));
        // Remove the previous listening so we can override it here
        Glu.history.stopListening(this.appBus, 'router:navigate');
        Glu.history.listenTo(this.appBus, 'router:navigate', this.handleRouterNavigation.bind(this));
    },

    allowRefresh: [],

    initialize() {
        listenToKeyboard.apply(this);
        this.appRoutes.logout = 'logout';
        this.appRoutes.deleteSession = 'deleteSession';
        dynamiclinks.setupDynamicLinks();
    },

    /**
     * Evaluate the route and determine if it is the same as the current route.
     * When it is, then check the list of function in allowRefresh and determine
     * if the route should trigger the route again
     * @param {string} fragment - desired route
     * @param {Object} options - Options object coming from appBUs event
     */
    handleRouterNavigation(fragment, options) {
        /*
         * Backbone doesn't allow the route action to trigger if the route hasn't
         * changed. If the route is the same, check to see if the route action
         * should be executed
         */
        if (Glu.history.fragment === fragment) {
            // Check if any of the functions in the allowRefresh array resolve to true
            if (this.allowRefresh.some(allowRefreshFunc => allowRefreshFunc(fragment))) {
                /*
                 * Trick backbone by navigating to an empty route and not executing
                 * the route action
                 */
                this.navigate('', {
                    trigger: false,
                });
            }
        } else if (options && options.newStack) {
        /*
         * Consumed by baseController to determine if navigated to a new stack
         * Don't set the new-stack variable when we are routing to the same route multiple
         * times. backbone won't complete the route and the functions which remove the
         * variable (showPage,popPage) won't be called.
         */
            store.set('new-stack', true);
        }
        this.navigate(fragment, options);
    },

    routes: {
        // Empty function for 'router:reload'
        redirecting() {},

        '*404': () => {
            // leaving this appBus trigger for other handlers, e.g. brand.js sets active nav
            appBus.trigger('show:page', new ErrorView(), 'Error');
        },
    },
});
