var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-tertiary print\" title=\""
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":80},"end":{"line":11,"column":105}}}))
    + "\" data-action=\"print\"><span class=\"icon-print\"></span></button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"display-print-transactions-text\">\n                    <span>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"gir.custReport.tranTotals.message",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":26},"end":{"line":16,"column":72}}}))
    + "</span>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page balance-and-transaction-reporting\">\n    <div class=\"section section-container\">\n                <div data-region=\"filter\" class=\"section-body\"></div>\n        </div>\n\n        <div class=\"section section-container\" data-hook=\"criteriaResults\">\n       <div class=\"btn-wrapper\">\n            <div class=\"btn-group pull-right\">\n                <button type=\"button\" class=\"btn btn-tertiary export\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.export",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":77},"end":{"line":9,"column":103}}}))
    + "\" data-action=\"export\"><span class=\"icon-export\"></span></button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAccountPrint") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":12,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"displayPrintTransactionsText") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":18,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"section-body\">\n            <div class=\"alert-region\" data-region=\"accountAlertRegion\"></div>\n            <div data-region=\"combined-accounts\">\n            </div>\n            <div class=\"loader BTRLoader\" data-hook=\"accounts-loader\"><span class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.loading",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":92},"end":{"line":24,"column":119}}}))
    + "</span></div>\n        </div>\n        </div>\n</div>\n";
},"useData":true});