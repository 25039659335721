import Model from '@glu/core/src/model';
import services from 'services';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import validatorPatterns from 'system/validatorPatterns';

export default Model.extend({
    idAttribute: 'widgetId',

    defaults: {
        widgetSize: 'S',
        widgetType: 'R',
        height: 400,
        dataWidgetId: '',
        widgetName: '',
        widgetDescription: '',
    },

    initialize() {
        this.validators = {
            dataWidgetId: {
                description: locale.get('CXP.bankWidget.id'),
                exists: true,
            },

            widgetName: {
                description: locale.get('CXP.bankWidget.name'),
                exists: true,
            },

            widgetDescription: {
                description: locale.get('CXP.bankWidget.description'),
                exists: true,
            },
            height: {
                description: locale.get('CXP.bankWidget.height'),
                matches: validatorPatterns.TEMPLATECODE_PATTERN,
                overrideError: 'isTemplateCode',
            },
        };
    },

    sync(method, model, options) {
        switch (method) {
        case 'read':
            http.post(services.generateUrl('/cxp/externalWidget/READ'), {
                widgetId: parseInt(this.get('widgetId'), 10),
            }, options.success, options.error);
            break;

        case 'create':
            this.prepareData();
            http.post(services.generateUrl('/cxp/externalWidget/add'), model.toJSON(), (result) => {
                if (result !== undefined) {
                    model.set('widgetId', result.widgetId);
                }
                options.success(result);
            }, options.error);
            break;

        case 'update':
            this.prepareData();
            http.post(
                services.generateUrl('/cxp/externalWidget/update'), model.toJSON(), (result) => {
                    options.success(result);
                },
                (result) => {
                    if (result.status === 200) {
                        options.success(model.toJSON());
                    } else {
                        options.error(result);
                    }
                },
            );
            break;

        case 'delete':
            http.post(
                services.generateUrl('/cxp/externalWidget/delete'), model.toJSON(),
                (result) => {
                    options.success(result);
                },
                (result) => {
                    if (result.status === 200) {
                        options.success(result);
                    } else {
                        options.error(result);
                    }
                },
            );
            break;

        default:
        }
    },

    parse(data) {
        const dataParam = data;
        if (Object.prototype.hasOwnProperty.call(dataParam, 'remote')) {
            dataParam.widgetType = (dataParam.remote === true) ? 'R' : 'L';
        }

        if (Object.prototype.hasOwnProperty.call(dataParam, 'trusted')) {
            dataParam.trusted = `${dataParam.trusted}`;
        }

        if (Object.prototype.hasOwnProperty.call(dataParam, 'dataEntitle')) {
            dataParam.dataEntitle = `${dataParam.dataEntitle}`;
        }

        if (Object.prototype.hasOwnProperty.call(dataParam, 'fileName')) {
            dataParam.fileName = null;
        }

        return dataParam;
    },

    prepareData() {
        this.set('orientation', 'H');

        if (this.get('widgetType') === 'R') {
            this.set({
                remote: true,
                local: false,
            });
        } else {
            this.set({
                remote: false,
                local: true,
            });
        }
    },

});
