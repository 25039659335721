export default ({
    'SETUP/tableMaintInquiry': 'listBankHolidays',
    'SETUP/beneBookInquiry': 'listBeneficiary',
    'SETUP/beneBookInquiry/create': 'createBeneficiary',
    'SETUP/beneBookInquiry/modify/:id': 'modifyBeneficiary',
    'SETUP/beneBookInquiry/view/:id': 'viewBeneficiary',
    'ADMINSTRATION/advancedFilter': 'viewBankAcctFilter',
    'SETUP/quickbooks': 'viewQuickbooksConnect',
    'SMAINT/quickbooksTenant': 'viewQuickbooksTenant',
    'SETUP/fileImportMapList': 'fileImportMapList',
    'SETUP/fileImportMap': 'fileImportMapListWidget',
    'ADMINSTRATION/fileImportMap': 'fileImportMapListWidget',
    'SETUP/fileImportMap/:action': 'fileImportMap',
    'SETUP/modifyAlertRecipient': 'setupModifyAlertRecipient',
    'SETUP/listAlertRecipients': 'setupAlertRecipientList',
    'SETUP/listAlerts': 'setupAlertsList',
});
