const UNUSED_FALSE = false;
const ALWAYS_TRUE = true;

export const accountManagement = {
    paymodeAccounts: {
        showAccountInfoSectionLinks: UNUSED_FALSE,
        showPrimaryContacts: UNUSED_FALSE,
    },

    simplifiedAccounts: {
        assignUsers: UNUSED_FALSE,
        showPrivileges: UNUSED_FALSE,
        showLimits: UNUSED_FALSE,
        showUsers: UNUSED_FALSE,
    },

    preventLoadNotifications: ALWAYS_TRUE,

    // remove after privileges are split out the from the rest of user-info
    insideDGB: ALWAYS_TRUE,
    isDGB: ALWAYS_TRUE,
    setPMXHeader: ALWAYS_TRUE,
};

export const payerDividendsReport = {
    // for now, until printing functionality is implemented
    allowPrinting: UNUSED_FALSE,

    pdfExport: UNUSED_FALSE,
    multiSelectPMXAccounts: ALWAYS_TRUE,
    exportUnderSearch: ALWAYS_TRUE,
    isDGB: ALWAYS_TRUE,
};

export const bankAccountReconciliationReport = {
    allowPrinting: UNUSED_FALSE,
    pdfExport: UNUSED_FALSE,
    multiSelectBankAccounts: ALWAYS_TRUE,
    exportUnderSearch: UNUSED_FALSE,
    isDGB: ALWAYS_TRUE,
};

export const contactOptions = {
    usePMXRollbackFlag: UNUSED_FALSE,
};

export const serviceUser = {
    isDGB: true,
};

export default {
    accountManagement,
    payerDividendsReport,
    bankAccountReconciliationReport,
    serviceUser,
    contactOptions,
};
