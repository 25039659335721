import './themeDefaults';
import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '@glu/form-components';
import locale from '@glu/locale';
import useStyles from './FileNameInput.styles.js';

const FileNameInput = ({ extension, ...rest }) => {
  const classes = useStyles({ rest });
  return (
    <div className={classes.root}>
      <Input
        className={classes.input}
        labelText={locale.get('labelFilenameInput')}
        name="filename-input"
        screenReaderLabel
        type="text"
        {...rest}
      />
      <span className={classes.extension}>{extension}</span>
    </div>
  );
};

FileNameInput.propTypes = {
  /** File being uploaded */
  extension: PropTypes.string
};

FileNameInput.defaultProps = {
  extension: ''
};

export default FileNameInput;
