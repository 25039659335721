import LayoutView from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import GridApi from 'common/dynamicPages/api/grid';
import dialog from '@glu/dialog';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import printExportUtil from 'common/util/printExportUtil';
import ListView from 'common/dynamicPages/views/workflow/list';
import adminConstants from 'app/administration/constants';
import constants from './constants';
import associatedAccountsListTmpl from './associatedAccountsList.hbs';

const associatedAccountsList = LayoutView.extend(util.extend({}, printExportUtil, {
    template: associatedAccountsListTmpl,

    events: {
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
    },

    initialize() {
        this.localeKey = 'toa.associatedAccounts_';
    },

    /**
     * @name getDataContext
     * @description - returns the 'data' object for the payload
     */
    getDataContext() {
        const data = {
            actionData: {
                subType: '*',
                productCode: constants.TOALOCATION_PRODUCTCODE,
                functionCode: constants.TOALOCATION_FUNCTIONCODE,
                typeCode: constants.TOALOCATION_ACCOUNTTYPE,
            },

            childActionData: null,
            serviceName: constants.TOALOCATION_ASSOCACCT_SERVICENAME,

            actionMode: adminConstants.MODES.VIEW,
            subType: '*',
        };

        return data;
    },

    /**
     * @name generateGridData
     * @description returns the options required for the associated accounts grid
     */
    generateGridData() {
        return {
            enableRowContextButtonCallbacks: true,
            enableSavedViews: false,
            canSave: false,
            canSetDefault: false,
            hideButtons: true,
            isInquiry: false,
            isChild: false,
            editableChildGrid: false,
            context: this.getDataContext(),
            requestParameters: {
                item: [
                    {
                        name: 'productCode',
                        value: constants.TOALOCATION_PRODUCTCODE,
                    },
                    {
                        name: 'functionCode',
                        value: constants.TOALOCATION_FUNCTIONCODE,
                    }, {
                        name: 'typeCode',
                        value: constants.TOALOCATION_ACCOUNTTYPE,
                    },
                    {
                        name: 'inquiryId',
                        value: constants.TOALOCATION_ASSOCACCT_INQUIRYID,
                    },
                ],
            },
            additionalSearchFields: [{
                fieldName: 'LOCATIONNUMBER',
                fieldValue: [this.options.model.get('LOCATIONNUMBER')],
                dataType: 'text',
                operator: '=',
            }, {
                fieldName: 'USERGROUP',
                fieldValue: [this.options.model.get('USERGROUP')],
                dataType: 'text',
                operator: '=',
            }],
            selector: 'none',
            selectAndProtectAll: true,
            viewId: constants.TOALOCATION_ASSOCACCT_VIEWID,
        };
    },

    onRender() {
        this.gridView = GridApi.createServiceGridView(this.generateGridData());
        if (this.gridContent) {
            this.gridContent.show(this.gridView);
        }
    },

    /**
     * @name export
     * @description export associated accounts list to CSV
     */
    export() {
        this.buildExportModel('CSV');
        this.doExport();
    },

    /**
     * @name print
     * @description print associated accounts list as a PDF
     */
    print() {
        this.buildExportModel('PDF');
        const printModal = new PrintViewModal({
            exportModel: this.exportModel,
            title: `${this.localeKey}title`,
        });
        dialog.custom(printModal);
    },

    /**
     * @name buildExportModel
     * @description builds the exportModel (member of printExportUtil) in
     * preparation for printing or exporting
     * @param {string} format output format, e.g. PDF or CSV
     */
    buildExportModel(format) {
        ListView.prototype.buildExportModel.call(this, format);
        this.exportModel.actionData = {
            ...this.exportModel.actionData,
            productCode: constants.TOALOCATION_PRODUCTCODE,
            typeCode: constants.TOALOCATION_ACCOUNTTYPE,
        };

        this.exportModel.inquiryId = constants.TOALOCATION_ASSOCACCT_INQUIRYID;
        this.exportModel.viewId = constants.TOALOCATION_ASSOCACCT_VIEWID;
    },

    templateHelpers() {
        return {
            gridUtilityOptions: {
                hasRefresh: false,
            },
        };
    },
}));

export default associatedAccountsList;
