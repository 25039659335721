import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import numeral from 'numeral';
import locale from '@glu/locale';
import format from 'system/utilities/format';
import template from './nachaAmount.hbs';

export default ItemView.extend({
    template,

    events: {
        'blur @ui.$amount': 'saveAmount',
        'focus @ui.$amount': 'applyMask',
    },

    ui: {
        $amount: '[data-hook="getNachaAmountInput"]',
    },

    applyMask() {
        if (!this.ui.$amount.get(0).inputmask) {
            this.ui.$amount.inputmask({
                alias: 'number',
                showMaskOnHover: false,
            });
            this.ui.$amount.select();
        }
    },

    onRender() {
        // Render wipes out the amount mask.  Reapply it
        this.applyMask();
    },

    belowZero() {
        const amt = format.removeNumberFormat(this.model.get('AMOUNT')) || 0;

        return amt < 0;
    },

    templateHelpers() {
        const self = this;
        return {
            id: this.model.cid,
            tabindex: 512 + (this.model.collection
                ? this.model.collection.indexOf(this.model) : 0),

            disabled() {
                let isTaxPayment;
                if (self.model.context && self.model.context.actionContext) {
                    const { actionContext } = self.model.context;
                    isTaxPayment = (actionContext.functionCode === 'BATCH' || actionContext.functionCode === 'BHTMPL') && actionContext.productCode === 'USACH' && actionContext.typeCode === 'BDACHTP';
                }
                return isTaxPayment || self.model.get('PRENOTEFLAG') === '1' || self.model.get('ZERODOLLARLIVE') === '1';
            },
            hasError: this.model.get('hasError') || this.belowZero(),
        };
    },

    saveAmount() {
        this.appBus.trigger('unbound:input');

        const amt = numeral(this.ui.$amount.val()).value();
        // Dont save if nothing changed!
        if (numeral(this.model.get('AMOUNT')).value() === amt) {
            return;
        }

        // set the models amount value here so it persists in the grid
        this.model.set({
            AMOUNT: amt, // convert "" to 0
        }, {
            silent: true,
        });

        // validate the amount to display proper inline warnings if invalid
        this.validateAmount(amt);
        this.validateZeroDollarLive(amt);

        this.model.set('ENTRYMETHOD', this.model.context.PARENTENTRYMETHOD);
        this.model.save({}, {
            success: () => {
                /*
                 * status and status description must be cleared out when
                 * you edit a bene in the grid
                 */
                this.model.set({
                    STATUS: '',
                    STATUS_DESCRIPTION: '',
                });
                this.appBus.trigger('nachaChild:update:success');
            },
            error(response) {
                this.appBus.trigger('nachaChild.update.error', response);
            },
            silent: true,
        });
    },

    validateZeroDollarLive(amt) {
        const message = locale.get('ACH.ErrorCode.1405');
        let error;
        if (amt !== 0 && this.model.get('ZERODOLLARLIVE') === '1') {
            error = {
                AMOUNT: message,
            };
            this.model.set('error', error);
            return false;
        }

        if (this.model.get('error') && this.model.get('error').AMOUNT) {
            if (this.model.get('error').AMOUNT === message) {
                error = util.clone(this.model.get('error'));
                delete error.AMOUNT;
                this.model.set('error', error);
            }
        }
        return true;
    },

    /**
     * @method validateAmount
     * @param {number} amt
     * @return {boolean} amount is Valid flag
     * Takes in an amount inputed by user and validates it based on conditions:
     * - must be greater than 0
     * - must be less than 99999999.99
     */
    validateAmount(amt) {
        /*
         * System minimum and maximum amounts allowed. Custom limits are handled by
         * the server.
         */
        if (amt < 0 || amt > 99999999.99) {
            // set error
            this.model.set({
                hasError: true,
            });

            return false;
        }
        // reset error settings
        this.model.set({
            hasError: false,
        });

        return true;
    },
});
