import BaseWidget from 'common/uiWidgets/baseWidget/baseWidget';
import moment from 'moment';
import util from '@glu/core/src/util';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import Formatter from 'system/utilities/format';
import userInfo from 'etc/userInfo';
import fxFieldValidation from '../util/fxFieldValidation';
import template from './valueDateWidget.hbs';

export default BaseWidget.extend({
    template,
    className: 'ui-widget',
    tranDateName: 'TRAN_DATE',

    ui: {
        $valueDate: '[data-hook="value-date"]',
        $optionalRadio: '[name="optionalRadio"]',
        $tranDateSection: '[data-section="tran-date-section"]',
    },

    events: {
        'blur @ui.$valueDate': 'blurValueDate',
        'apply.daterangepicker @ui.$valueDate': 'blurValueDateFromCalendar',
        'change @ui.$optionalRadio': 'valueDateFieldValidation',
    },

    initialize(options) {
        // Call base to init model, parentModel, readyState, fieldName, etc.
        BaseWidget.prototype.initialize.call(this, options);

        const { parentModel } = this;
        this.fieldData = this.parentModel.fieldData[options.fieldName];
        if (parentModel.get(this.fieldName) && !parentModel.get(this.tranDateName)) {
            parentModel.set(this.tranDateName, parentModel.get(this.fieldName));
        }

        /*
         * this is needed to initialize the value date, but we only want
         * the onchange to fire at this time not when the user changes the
         * value date by typing, which will be handled by the onblur,
         * or by selecting a date, which is handled by the apply.daterangepicker handler
         */
        this.listenToOnce(this.parentModel, `change:${options.fieldName}`, (model) => {
            if (Formatter.formatDateFromUserFormat(model.previousAttributes().VALUE_DATE) !== Formatter.formatDateFromUserFormat(model.get('VALUE_DATE'))) {
                this.valueDateFieldValidation();
            }
        });
        this.listenTo(this.parentModel, 'change:POST_DATE', this.displayPostDate);
        this.listenTo(this.parentModel, 'change:TRAN_DATE', this.displayTranDate);
    },

    blurValueDateFromCalendar() {
        const model = this.parentModel;
        if (util.isEmpty(model.previousAttributes()) || (Formatter.formatDateFromUserFormat(model.previousAttributes().VALUE_DATE) !== Formatter.formatDateFromUserFormat(model.get('VALUE_DATE')))) {
            this.valueDateFieldValidation();
        }
    },

    blurValueDate() {
        this.valueDateFieldValidation();
    },

    shouldDisplayTranDate() {
        const valueDate = this.formatDate('VALUE_DATE');
        const tranDate = this.formatDate('TRAN_DATE');
        return (valueDate !== tranDate);
    },

    displayTranDate() {
        if (typeof this.ui.$tranDateSection?.toggle === 'function') {
            this.ui.$tranDateSection.toggle(this.shouldDisplayTranDate());
        }
    },

    shouldDisplayPostDate() {
        return !util.isEmpty(this.parentModel.get('POST_DATE'));
    },

    displayPostDate() {
        this.$('div[data-section="post-date-section"]').toggle(this.shouldDisplayPostDate());
    },

    shouldDisplayCutoffDate() {
        const hidecutoff = serverConfigParams.get('hideCutoff');
        const type = this.model.jsonData.typeInfo.typeCode;
        const { state } = this;
        if (state === 'VIEW' || (hidecutoff && hidecutoff.indexOf(type) >= 0)) {
            return false;
        }
        return true;
    },

    displayCutoffDate() {
        this.$('dev[data-section="cutoff-date-section"]').toggle(this.shouldDisplayCutoffDate());
    },

    valueDateFieldValidation() {
        const { fieldName } = this;
        const { state } = this;
        const model = this.parentModel;
        const { typeInfo } = model.jsonData;

        model.set({
            PRODUCT: typeInfo.productCode,
            FUNCTION: typeInfo.functionCode,
            TYPE: typeInfo.typeCode,
        });

        this.readyState = new Promise((resolve) => {
            fxFieldValidation.doFieldValidation(this, model, fieldName, state).then(() => {
                this.displayCutoffDate();
                this.displayTranDate();
                this.displayPostDate();
                model.trigger('widget:update:value-date', model);
                resolve();
            });
        });
    },

    formatDate(dateField) {
        return moment(this.parentModel.get(dateField)).format(userInfo.getDateFormat());
    },

    templateHelpers() {
        const { fieldData } = this;
        const { parentModel } = this;
        const modelFieldData = parentModel.fieldData;
        return {
            blockClass: fieldData.blockClass,
            cssWidgetClass: this.className,
            getFieldLabel: fieldData.fieldLabel,
            getFieldName: fieldData.name,
            getFieldValue: this.formatDate('VALUE_DATE'),
            getTranDateToolTip: modelFieldData.TRAN_DATE.fieldUiToolTip,
            isMandatory: (this.state === 'VIEW') ? false : fieldData.mandatory,
            isReadOnly: this.state === 'VIEW',
            optionalButtons: this.fieldData.choices,
            postDateFieldLabel: (modelFieldData.POST_DATE) ? modelFieldData.POST_DATE.fieldLabel : '',
            shouldDisplayCutoffDate: this.shouldDisplayCutoffDate(),
            shouldDisplayPostDate: this.shouldDisplayPostDate(),
            shouldDisplayTranDate: this.shouldDisplayTranDate(),
            tranDateFieldLabel: modelFieldData.TRAN_DATE.fieldLabel,
        };
    },
});
