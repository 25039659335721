import ItemView from '@glu/core/src/itemView';
import ReasonRowView from './reasonRowView';
import reasonCellViewTmpl from './reasonCellView.hbs';

export default ItemView.extend({
    events: {
        'click a': 'handleClick',
    },

    template: reasonCellViewTmpl,
    subrow: false,

    handleClick() {
        if (this.subrow) {
            this.removeSubRow();
        } else {
            this.addSubRow();
        }
    },

    removeSubRow() {
        this.subrow.close();
        this.$el.parent('tr').removeClass('row-expanded');
        this.subrow = false;
        this.render();
    },

    addSubRow() {
        const parentRow = this.$el.parent('tr');

        const rowView = new ReasonRowView({
            colspan: this.options.column.collection.length,
            model: this.model,
            className: `${parentRow.get(0).className} grid-subrow`,
        });

        rowView.render();
        if (parentRow.next('tr').length > 0) {
            parentRow.after(rowView.el);
        } else {
            parentRow.get(0).parentNode.appendChild(rowView.el);
        }
        rowView.$el.addClass('row-expanded');
        parentRow.addClass('row-expanded');

        this.subrow = rowView;
        this.render();
    },

    onClose() {
        if (this.subrow) {
            this.subrow.close();
        }
    },

    templateHelpers() {
        return {
            showView: !this.subrow,
        };
    },
});
