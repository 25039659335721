var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"droppable-target clearfix\">\n    <div class=\"row\">\n        <div class=\"col-md-6 pull-left\">\n                <div class=\"field-container accounts-combobox-view\">\n                    <div data-region=\"fromAccountRegion\" clear class=\"fromAccount\"></div>\n                </div>\n        </div>  \n        <div class=\"col-md-6 amount-wrapper\">\n            <div class=\"total-amount-area\">\n                 <h4 class=\"amount-label-text\">Total Amount</h4>\n                <span class=\"amount-value-text\"><span class=\"hidden icon-warning\"></span> "
    + alias2(((helper = (helper = lookupProperty(helpers,"formattedBillsAmount") || (depth0 != null ? lookupProperty(depth0,"formattedBillsAmount") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"formattedBillsAmount","hash":{},"data":data,"loc":{"start":{"line":11,"column":90},"end":{"line":11,"column":114}}}) : helper)))
    + "</span>\n            </div>            \n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div>\n            <p class=\"text-center empty-queue-msg\">\n                <span class=\"icon-add-doc\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.drag.bill.to.schedule",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":51},"end":{"line":19,"column":97}}}))
    + "\n            </p>\n        </div>\n    </div>\n\n    <div class=\"droppable-target-list row\" data-region=\"droppableTargetList\"></div>\n\n    <br/>\n        <div class=\"row\">\n            <div>\n                <button type=\"button\" class=\"btn btn-primary schedule-payments hidden\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.click.to.schedule",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":87},"end":{"line":29,"column":129}}}))
    + "</button>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div>\n            <a role=\"button\" class=\"btn btn-link pull-right\"><span class=\"icon-add-circle\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.add.payment.account",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":99},"end":{"line":36,"column":143}}}))
    + "</a>\n        </div>\n    </div>\n";
},"useData":true});