import constants from 'app/administration/constants';
import store from 'system/utilities/cache';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import AdminUtil from 'app/administration/common/util';
import RoleModel from 'app/administration/models/roles/role';
import systemConfig from 'system/configuration';
import helpPageUtil from 'common/util/helpPage';
import BaseLayout from '../userMaintenance/userLayout';
import RoleDialog from './addRoleDialog';

export default BaseLayout.extend({
    initialize(opts) {
        this.isRole = true;
        this.userGroup = opts.userGroup;
        this.roleName = opts.roleName;
        this.roleDesc = opts.roleDesc;
        this.mode = opts.mode;
        this.cloneId = opts.cloneId;
        this.bankMaintained = opts.bankMaintained;
        this.returnRoute = opts.returnRoute;
        this.skipUser = true;
        this.isAdmin = systemConfig.isAdmin();
        this.buttons = opts.buttons || [];
        if (this.buttons.length > 0 && this.mode === constants.MODES.VIEW) {
            this.roleGridModel = new RoleModel(opts.gridModel);
            this.buttons = util.filter(this.buttons, (buttonParam) => {
                const button = buttonParam;
                button.action = button.action.toLowerCase();
                return button.action !== constants.ACTIONS.SELECT;
            });
        }
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.initTabs();
            if (this.tabs.PAYMENTS && !(this.mode === constants.MODES.VIEW
                && !this.tabs.PAYMENTS.hasSelectedComplexType())) {
                this.toStep(this.steps.PAYMENTS);
            } else if (this.tabs.REPORTS) {
                this.toStep(this.steps.REPORTING);
            } else if (this.tabs.RISK) {
                this.toStep(this.steps.RISK);
            } else if (this.tabs.ADMIN) {
                this.toStep(this.steps.ADMIN);
            } else if (this.tabs.ALERTS) {
                this.toStep(this.steps.ALERTS);
            } else if (this.tabs.ABS) {
                this.toStep(this.steps.ABS);
            }
        } else {
            // clear out helpPage from cache
            store.unset('helpPage');
            this.load();
        }
    },

    load() {
        let roleId = '';

        const helpPagePromise = helpPageUtil.getHelpPagePromise({
            productCode: '_ADMIN',
            functionCode: 'ROLES',
            typeCode: '*',
            mode: this.mode === constants.MODES.INSERT ? 'INSERT' : '*',
        });

        if (this.cloneId) {
            roleId = this.cloneId;
        } else if (this.mode !== constants.MODES.INSERT) {
            roleId = this.roleName;
        }

        new RoleModel().fetch({
            userGroup: this.userGroup,
            roleId,

            success: (roleModel) => {
                this.model = roleModel;
                this.userGroup = this.userGroup || roleModel.get('roleInfo').USERGROUP;
                if (roleModel.get('roleInfo').BANKMAINTAINED === '1') {
                    this.bankMaintained = roleModel.get('roleInfo').BANKMAINTAINED;
                }
                // Must populate roleInfo for inserts
                if (this.mode === constants.MODES.INSERT && !this.cloneId) {
                    roleModel.set(
                        'roleInfo',
                        {
                            USERGROUP: this.userGroup,
                            ROLEID: this.roleName,
                            ROLENAME: this.roleName,
                            DESCRIPTION: this.roleDesc,
                            GROUPED_PERMISSIONS_BY: 'PAYMENT_GROUP',
                            BANKMAINTAINED: this.bankMaintained,
                        },
                    );
                } else if (this.mode === constants.MODES.MODIFY) {
                    this.roleDesc = roleModel.get('roleInfo').DESCRIPTION;
                } else {
                    roleModel.set(
                        'roleInfo',
                        {
                            USERGROUP: this.userGroup,
                            ROLEID: this.roleName,
                            ROLENAME: this.roleName,
                            DESCRIPTION: this.roleDesc,
                            GROUPED_PERMISSIONS_BY: roleModel.get('roleInfo').GROUPED_PERMISSIONS_BY,
                            BANKMAINTAINED: this.bankMaintained,
                        },
                    );
                }

                // Add tab models to this view
                this.tabs = {};

                const roleInfo = roleModel.get('roleInfo');

                roleModel.get('masterTabs').each((masterTabParam) => {
                    const masterTab = masterTabParam;
                    masterTab.roleInfo = roleInfo;
                    this.tabs[masterTab.get('masterTabCode')] = masterTab;
                });

                helpPagePromise.then((results) => {
                    store.set('helpPage', results.helpPage);
                    this.setHasLoadedRequiredData(true);
                    this.loadUsergroup();
                });
            },
        });
    },

    modify() {
        if (this.mode === constants.MODES.VIEW) {
            this.navigateTo(`ADMINISTRATION/modifyRole/${this.roleGridModel.get('ROLEID')}/${this.roleGridModel.get('USERGROUP')}`);
        }
    },

    clone() {
        dialog.open(new RoleDialog({
            isAdmin: this.isAdmin,
            userGroup: this.roleGridModel.get('USERGROUP'),
            cloneId: this.roleGridModel.get('ROLEID'),
        }));
    },

    doAction(action) {
        const self = this;
        this.roleGridModel[action]({
            success(model) {
                store.set(
                    'addRoleSuccessMessage',
                    {
                        action,
                        response: model,
                    },
                );
                self.navigateTo(self.returnRoute);
            },

            error(model, response) {
                AdminUtil.errorAlert(self.alertRegion, response.responseJSON);
            },

            action,
        });
    },

    save() {
        const self = this;

        this.ui.saveUser.attr('aria-busy', true);
        this.model.save(
            null,
            {
                isModify: this.mode === constants.MODES.MODIFY,

                success(userModel, response) {
                    store.set(
                        'addRoleSuccessMessage',
                        {
                            action: 'save',
                            response,
                        },
                    );
                    self.navigateTo(self.returnRoute);
                },

                error(userModel, response) {
                    AdminUtil.errorAlert(self.alertRegion, response.responseJSON);
                    self.ui.saveUser.attr('aria-busy', false);
                },
            },
        );
    },

    cancel() {
        this.navigateTo(this.returnRoute);
    },

    editRoleInfo() {
        const self = this;

        dialog.open(new RoleDialog({
            roleName: self.roleName,
            roleDesc: self.roleDesc,
            userGroup: self.userGroup,
            isAdmin: self.isAdmin,
            bankMaintained: self.bankMaintained,
            fromForm: true,
            modifyMode: this.mode === constants.MODES.MODIFY,

            onSave(dialogModel) {
                const roleName = dialogModel.get('roleName');
                const roleDesc = dialogModel.get('roleDesc');
                const bankMaintained = dialogModel.get('bankMaintained');
                self.model.set('roleInfo', util.extend(
                    self.model.get('roleInfo'),
                    {
                        DESCRIPTION: roleDesc,
                        ROLEID: roleName,
                        ROLENAME: roleName,
                        BANKMAINTAINED: bankMaintained || '0',
                    },
                ));

                self.roleName = roleName;
                self.roleDesc = roleDesc;
                self.bankMaintained = bankMaintained;
                self.ui.roleName.text(roleName);
                self.ui.roleDesc.text(roleDesc);
                if (self.ui.bankMaintained) {
                    self.ui.bankMaintained.text(locale.get(bankMaintained || '0'));
                }
                self.showBankMaintainedSections(bankMaintained === '1');
            },
        }));
    },

    templateHelpers(...args) {
        const parentHelpers = BaseLayout.prototype.templateHelpers.call(this, args);

        return util.extend(
            parentHelpers,
            {
                getPageTitle: locale.get('menuitem.SETUP.RoleMaint'),
                roleName: this.roleName,
                roleDesc: this.roleDesc,
                userGroup: this.userGroup,
                bankMaintained: this.bankMaintained,
            },
        );
    },

    showBankMaintainedSections(show) {
        // Don't bother doing anything if tenant flag is off.
        if (!AdminUtil.isBankMaintainedRolesEnabled()) {
            return;
        }

        const isAdminOrClientView = systemConfig.isAdmin() || (!systemConfig.isAdmin() && this.mode === 'VIEW');
        if (!isAdminOrClientView) {
            return;
        }

        const sections = this.$('.UserAdministration, .RoleAdministration');
        if (show) {
            sections.removeClass('hidden');
        } else {
            sections.addClass('hidden');
            this.$(':checkbox', sections).prop('checked', false);

            if (this.tabs.ADMIN) {
                util.chain(this.tabs.ADMIN.get('simpleTypes').models)
                    .map(adminTabModel => adminTabModel.get('entitlementsByType').models)
                    .flatten(true)
                    .filter((model) => {
                        const funcCode = model.get('functionCode');
                        return funcCode === 'roles' || funcCode === 'users';
                    })
                    .each((modelRolesUsers) => {
                        modelRolesUsers.set({
                            'approve.entitled': false,
                            'manage.entitled': false,
                            'view.entitled': false,
                        });
                    });
            }
        }
    },

    /**
     * @method onClose
     * @description - method that is invoked when the view is closed.
     * If we are not a batch child view, then unset the helpPage that is used for
     * the global help.
     */
    onClose() {
        store.unset('helpPage'); // remove view helppage from cache
    },
});
