var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.back.to.previous",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":144},"end":{"line":4,"column":181}}}))
    + "</span></a>\n    </div>\n\n    <h1 class=\"landing-header\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":7,"column":31},"end":{"line":7,"column":40}}}) : helper)))
    + "</h1>\n\n    <div data-section=\"payment-summary\" class=\"amount-wrapper\">\n        <p class=\"amount-label-text\">"
    + alias2((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias3).call(alias1,"PaymentTotal",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":10,"column":37},"end":{"line":10,"column":66}}}))
    + "</p>\n        <span data-field=\"summary-amount\" class=\"amount-value-text payment-total\">0.00</span> <span data-field=\"summary-currency\" class=\"amount-value-text payment-total\"></span>\n    </div>\n\n</div>\n\n<div class=\"section section-body\">\n    <div class=\"alert-message\">\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n    </div>\n    <div data-region=\"pmtMessageRegion\"></div>\n<div>\n\n<div data-region=\"pageContent\"></div>\n\n<div class=\"section section-container section-transfers\">\n    <div class=\"alert-message\">\n        <div class=\"alert-region\" data-region=\"alertRegionBottom\"></div>\n    </div>\n\n    <div class=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"getTransferType") || (depth0 != null ? lookupProperty(depth0,"getTransferType") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"getTransferType","hash":{},"data":data,"loc":{"start":{"line":30,"column":16},"end":{"line":30,"column":37}}}) : helper)))
    + "\">\n        <div class=\"from-account\" data-region=\"transfersFromAccountRegion\"></div>\n\n        <div class=\"bracket\">\n            <div class=\"piece-top\"></div>\n            <div class=\"piece-middle\"></div>\n            <div class=\"piece-bottom\"></div>\n        </div>\n\n        <div class=\"to-account\" data-region=\"transfersToAccountRegion\"></div>\n    </div>\n</div>\n\n<div class=\"section section-summary\">\n    <div data-section=\"payment-summary\" class=\"section-header\">\n        <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.transfer.summary",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":45,"column":12},"end":{"line":45,"column":49}}}))
    + ":</h2>\n        <h3><span data-field=\"summary-amount\" class=\"summary-large-text\">0.00</span>\n            <span data-field=\"summary-currency\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.from",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":47,"column":56},"end":{"line":47,"column":81}}}))
    + "\n            <span data-field=\"summary-beneficiaries\">0</span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.on",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":48,"column":62},"end":{"line":48,"column":85}}}))
    + "\n            <span data-field=\"summary-date\"></span></h3>\n    </div>\n    <div class=\"section-body\">\n        <div class=\"widget-action-btn-group\">\n                <button type=\"button\" class=\"btn btn-primary \" data-action=\"save\">"
    + alias2((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias3).call(alias1,"Submit",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":53,"column":82},"end":{"line":53,"column":105}}}))
    + "</button>\n                <button type=\"button\" class=\"btn btn-secondary\" data-action=\"cancel\">"
    + alias2((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias3).call(alias1,"Cancel",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":54,"column":85},"end":{"line":54,"column":108}}}))
    + "</button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});