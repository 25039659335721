import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@glu/datepicker-react';
import { createUseStyles } from '@glu/theming';
import useEditAwareCellRenderer from './useEditAwareCellRenderer';

const useStyles = createUseStyles({
  root: {
    lineHeight: 1,
    padding: [6, 0],
    whiteSpace: 'normal'
  },

  datepickerCell: {
    overflow: 'visible'
  }
});

function addFocusListener(element, handler) {
  /* istanbul ignore else */
  if (element) {
    element.addEventListener('focus', handler);
  }
}

function removeFocusListener(element, handler) {
  /* istanbul ignore else */
  if (element) {
    element.removeEventListener('focus', handler);
  }
}

const DatePickerCellRenderer = ({
  value: cellValue, node, data, colDef, api, eGridCell,
  convertDatePickerValue, parseDateStart, parseDateEnd, datepickerProps, ...rest
}) => {
  const classes = useStyles({});
  const {
    rowId, field, labelText, editedValue, setEditedValue, errors
  } = useEditAwareCellRenderer({
    cellValue, node, data, colDef, api, eGridCell, ...rest
  });
  const name = `${rowId}-${field}-input`;

  const handleChange = useCallback((_, [start, end], validate = false) => {
    if ((start === undefined && end === undefined) || (start.isValid() && end.isValid())) {
      setEditedValue(convertDatePickerValue([start, end]), validate);
    }
  }, [convertDatePickerValue, setEditedValue]);

  // Possible datepicker paths for getting values, this can help in deciding events to handle later:
  // dropdown used and apply pressed - onChangeCallback w/validate, plus onApplyCallback right after
  // dropdown used and cancel pressed - no onChangeCallback, onBlurCallback called
  // input changed blanked out - onChangeCallback w/o validate
  // input changed and left - onChangeCallback w/validate, dates may or may not be valid
  // input received enter key press - onChangeCallback w/validate, plus onApplyCallback right after
  //    Note this uses whatever the currently selected dates are which the calendar is updated to
  //    as the input is typed into.  However, if you select a date and then go back to the input
  //    and hit enter it will use the selected dates BUT the input is not updated to match!
  // leave datepicker entirely, tab out or click outside - onBlurCallback

  useEffect(() => {
    function onFocusSetGridFocusCell(event) {
      api.setFocusedCell(node.rowIndex, colDef.field);
      window.setTimeout(() => { // in FF focus cannot be set back on the target without timeout
        event.target.removeEventListener('focus', onFocusSetGridFocusCell);
        removeFocusListener(event.target, onFocusSetGridFocusCell);
        event.target.focus();
        addFocusListener(event.target, onFocusSetGridFocusCell);
      });
    }

    window.setTimeout(() => {
      eGridCell.classList.add(classes.datepickerCell);
      addFocusListener(eGridCell.querySelector(`input[name*="${name}"]`), onFocusSetGridFocusCell);
      addFocusListener(eGridCell.querySelector(`button[id*="${name}"]`), onFocusSetGridFocusCell);
    }); // need to ensure ag grid has actually attached our datepicker before adding events

    return () => {
      eGridCell.classList.remove(classes.datepickerCell);
      removeFocusListener(
        eGridCell.querySelector(`input[name*="${name}"]`), onFocusSetGridFocusCell
      );
      removeFocusListener(
        eGridCell.querySelector(`button[id*="${name}"]`), onFocusSetGridFocusCell
      );
    };
  }, [classes.datepickerCell, eGridCell, api, node.rowIndex, colDef.field, name]);

  return (
    <div className={classes.root}>
      <DatePicker
        name={name}
        htmlId={name}
        labelText={labelText}
        value={[parseDateStart(editedValue), parseDateEnd(editedValue)]}
        error={errors && errors.length ? errors[0] : undefined}
        screenReaderLabel
        forcePositionY
        onChange={handleChange}
        {...datepickerProps}
      />
    </div>
  );
};


DatePickerCellRenderer.propTypes = {
  /** Ag grid node, basically the current row */
  node: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    rowIndex: PropTypes.number.isRequired
  }).isRequired,

  /** Ag grid data hash for the current row */
  data: PropTypes.shape({}).isRequired,

  /** Ag grid colDef for the current column */
  colDef: PropTypes.shape({
    field: PropTypes.string.isRequired
  }).isRequired,

  /** Ag grid's value for the this row/column */
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types

  /** Ag grid grid api */
  api: PropTypes.shape({
    /** Ag grid setFocusedCell grid api function */
    setFocusedCell: PropTypes.func.isRequired
  }).isRequired,

  /** Ag grid html cell container that this component renders into */
  eGridCell: PropTypes.instanceOf(Element).isRequired,

  /**
   * Function that will receive the output of the glu datepicker as an array ([start,end])
   * and which is expected to return that input converted into the form to be stored as the
   * edited field value for this row.
   */
  convertDatePickerValue: PropTypes.func.isRequired,

  /** Function to be used to parse the start date out of the data for this field/row */
  parseDateStart: PropTypes.func,

  /** Function to be used to parse the end date out of the data for this field/row */
  parseDateEnd: PropTypes.func,

  /** Props to be spread onto the internal date picker component */
  datepickerProps: PropTypes.shape({})
};

DatePickerCellRenderer.defaultProps = {
  value: undefined,
  parseDateStart: dateValue => dateValue,
  parseDateEnd: dateValue => dateValue,
  datepickerProps: undefined
};

export default DatePickerCellRenderer;
