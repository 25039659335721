import React, {
  useEffect, useRef, useState, createContext
} from 'react';
import { withStyles } from '@glu/theming';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './MobileGridWrapper.styles';

export const MobileGridContext = createContext({});

const MobileGridWrapper = ({ children, classes, className }) => {
  const containerEl = useRef(null);
  const [topScrollPoint, setTopScrollPoint] = useState(null);

  useEffect(
    () => setTopScrollPoint(containerEl.current.getBoundingClientRect().top),
    []
  );

  return (
    <MobileGridContext.Provider value={{
      topScrollPoint,
      containerEl: containerEl.current
    }}
    >
      <div className={classnames(classes.root, className)} ref={containerEl}>
        {children}
      </div>
    </MobileGridContext.Provider>
  );
};

MobileGridWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired
};

MobileGridWrapper.defaultProps = {
  className: undefined
};

export const BareMobileGrid = MobileGridWrapper;
export default withStyles(styles)(MobileGridWrapper);
