import React from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import Grid from '../Grid/Grid';
import ComposedView from '../ComposedView/ComposedView';

export default function ComposedGrid({
  bulkActions,
  disableSessionStorage,
  onChange,
  defaultState,
  onGridReady,
  records,
  serverSideFiltering,
  sessionId,
  bulkActionsEnabled,
  columnHeaderFilters,
  ...props
}) {
  return (
    <ComposedView
      bulkActions={bulkActions}
      disableSessionStorage={disableSessionStorage}
      onChange={onChange}
      defaultState={defaultState}
      numberFilterTypes={[
        { value: 'equals', text: locale.get('equals') },
        { value: 'lessThan', text: locale.get('isLessThan') },
        { value: 'greaterThan', text: locale.get('isGreaterThan') }
      ]}
      onGridReady={onGridReady}
      records={records}
      serverSideFiltering={serverSideFiltering}
      sessionId={sessionId}
      bulkActionsEnabled={bulkActionsEnabled}

      {...props}
    >
      {({
        onGridReady: composedOnGridReady,
        onChange: composedOnChange,
        data,
        onSelectionChanged,
        pagination,
        setVisibleItems,
        setSelections,
        ...other
      }) => (
        <Grid
          onGridReady={composedOnGridReady}
          onChange={composedOnChange}
          headerHeight="40"
          data={data}
          target="screen"
          setSelections={setSelections}
          pagination
          setVisibleItems={setVisibleItems}
          records={records}
          columnHeaderFilters={columnHeaderFilters}
          {...other}
        />
      )}
    </ComposedView>
  );
}

ComposedGrid.propTypes = {
  /** Bulk Actions. Will create a button for each one */
  bulkActions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    handler: PropTypes.func.isRequired,
    condition: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
  })),
  /** Callback function, or boolean, to determine whether or not to enable bulk actions */
  bulkActionsEnabled: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  /** Description of the columns. Include filtering information here */
  columnDefs: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      headerName: PropTypes.string
    })
  ).isRequired,
  /** Adds a filter menu to each column header with filters */
  columnHeaderFilters: PropTypes.bool,
  /** Default State object. This will mirror the shape of the data object.
     it will be overriden by the default Saved View or session storage if
     enabled. */
  defaultState: PropTypes.shape({
    savedViews: PropTypes.shape({
      active: PropTypes.number,
      views: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        colId: PropTypes.string
      })
    ),
    filters: PropTypes.arrayOf(PropTypes.shape({
      field: PropTypes.string.isRequired,
      criteriaDisplay: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      nameDisplay: PropTypes.string.isRequired,
      operator: PropTypes.oneOf(['AND', 'OR']),
      filterData: PropTypes.shape({}).isRequired
    })),
    grid: PropTypes.shape({}),
    sort: PropTypes.arrayOf(
      PropTypes.shape({
        colId: PropTypes.string,
        sort: PropTypes.oneOf(['asc', 'desc'])
      })
    ),
    pageSize: PropTypes.number,
    page: PropTypes.number
  }),
  /** Prop to disable save and application of any changes to the data object */
  disableSessionStorage: PropTypes.bool,
  /** If true, do not export selected items on other pages * */
  exportActivePageOnly: PropTypes.bool,
  /** Conversion function before export. Useful if you need to remove columns */
  exportColumnConversion: PropTypes.func,
  /** Conversion function before export. Useful if you need to adjust data */
  exportDataConversion: PropTypes.func,
  /** Ag grid height is a little touchy. Adding a default height of 500 */
  gridHeight: PropTypes.number,
  /** Id for the grid used for storage and export title */
  gridId: PropTypes.string.isRequired,
  /** Proptype to hide columns in drawer and control bar */
  hideColumns: PropTypes.bool,
  /** Change event called anytime a component is called. Passes data in the shape of:
    {name: '', value: '', errors: {} }
  */
  onChange: PropTypes.func,
  /** Property to call after initial view is loaded. */
  onGridReady: PropTypes.func,
  /** Options for @popperjs/core see docs for details */
  PopoverProps: PropTypes.shape({}),
  /** data should match column defs, but doesn't have any particular shape */
  records: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  /** Property to disable saved views */
  showSavedViews: PropTypes.bool,
  /** Custom session id. Useful if you want to namespace a grid id.
    Session will use grid Id if this is not defined
  */
  sessionId: PropTypes.string,
  /** Suppress local pagination */
  serverSidePagination: PropTypes.bool,
  /** Can explicitly set pagination count. Will fallback to records length */
  paginationTotalItems: PropTypes.number,
  /** Server side filtering information. */
  serverSideFiltering: PropTypes.shape({
    filters: PropTypes.array
  }),
  /** Grid is used with left glu navigation bar. True by default */
  withSideNav: PropTypes.bool
};

ComposedGrid.defaultProps = {
  bulkActions: [],
  bulkActionsEnabled: true,
  columnHeaderFilters: true,
  defaultState: {},
  disableSessionStorage: false,
  exportActivePageOnly: false,
  exportColumnConversion: null,
  exportDataConversion: null,
  gridHeight: 500,
  hideColumns: false,
  onChange() {},
  onGridReady() {},
  serverSidePagination: false,
  sessionId: null,
  showSavedViews: false,
  paginationTotalItems: null,
  PopoverProps: undefined,
  serverSideFiltering: null,
  withSideNav: false
};
