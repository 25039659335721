import { ItemView, util } from '@glu/core';

import template from './flexDropdownTooltipItemView.hbs';

export default ItemView.extend({
  template,
  tagName: 'ul',

  templateHelpers() {
    return {
      isDefaultSelected: this.isDefaultSelected()
    };
  },

  isDefaultSelected() {
    const modelId = this.model.id;
    // can't use !this.model.id , because this.model.id can be equal 0;
    return util.isNull(modelId) || util.isUndefined(modelId) || modelId === '';
  }
});
