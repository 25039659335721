var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"NavTabs-item "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":36},"end":{"line":10,"column":66}}})) != null ? stack1 : "")
    + "\"\n                role=\"presentation\">\n                <a aria-controls=\"fYearRef\"\n                    class=\"NavTabs-link\"\n                    data-toggle=\"tab\"\n                    data-year=\""
    + alias3(alias2(depth0, depth0))
    + "\"\n                    href=\"#fYearRef\"\n                    role=\"tab\">"
    + alias3(lookupProperty(helpers,"locale").call(alias1,"cashflow.fy",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":31},"end":{"line":17,"column":55}}}))
    + " "
    + alias3(alias2(depth0, depth0))
    + "\n                </a>\n            </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "is-active";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n    <div data-hook=\"plan-properties-region\"></div>\n    <div data-hook=\"scale-legend-region\"></div>\n\n<div>\n    <ul class=\"NavTabs nav nav-tabs is-open\"\n        data-hook=\"getNavTabs\"\n        role=\"tablist\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"fiscalYearBtnNames") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":20,"column":17}}})) != null ? stack1 : "")
    + "    </ul>\n</div>\n\n<!-- Tab panes -->\n<div class=\"tab-content\">\n    <div role=\"tabpanel\" class=\"tab-pane active\" id=\"fYearRef\">\n\n        <table>\n            <caption>\n                <!-- TODO summarize this table -->\n            </caption>\n\n\n    <thead class=\"sr-only\">\n    </thead>\n\n    <tbody class=\"category-title inflow\">\n        <tr>\n        <th scope=\"col\"><button class=\"btn btn-link\" data-toggle=\"collapseByAria\" aria-controls=\"inflow-date-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":39,"column":109},"end":{"line":39,"column":115}}}) : helper)))
    + " inflow-plans-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":39,"column":129},"end":{"line":39,"column":135}}}) : helper)))
    + " inflow-subtotal-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":39,"column":152},"end":{"line":39,"column":158}}}) : helper)))
    + "\"><span class=\"indicator\"></span>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"cashflow.inflow.categories",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":39,"column":191},"end":{"line":39,"column":230}}}))
    + "</button></th>\n        <td colspan=\"13\" data-region=\"inflow-total\"></td>\n        </tr>\n    </tbody>\n\n    <tbody class=\"date-row inflow\" id=\"inflow-date-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":44,"column":51},"end":{"line":44,"column":57}}}) : helper)))
    + "\"></tbody>\n\n    <tbody class=\"category-plans inflow\" id=\"inflow-plans-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":46,"column":58},"end":{"line":46,"column":64}}}) : helper)))
    + "\" aria-expanded=\"true\">\n    </tbody>\n\n    <tbody class=\"category-subtotal inflow\" id=\"inflow-subtotal-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":49,"column":64},"end":{"line":49,"column":70}}}) : helper)))
    + "\">\n    </tbody>\n\n    <tbody class=\"category-title outflow\">\n        <tr>\n        <th scope=\"col\"><button class=\"btn btn-link\" data-toggle=\"collapseByAria\" aria-controls=\"outflow-date-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":54,"column":110},"end":{"line":54,"column":116}}}) : helper)))
    + " outflow-plans-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":54,"column":131},"end":{"line":54,"column":137}}}) : helper)))
    + " outflow-subtotal-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":54,"column":155},"end":{"line":54,"column":161}}}) : helper)))
    + "\"><span class=\"indicator\"></span>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"cashflow.outflow.categories",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":54,"column":194},"end":{"line":54,"column":234}}}))
    + "</button></th>\n        <td colspan=\"13\" data-region=\"outflow-total\"></td>\n        </tr>\n    </tbody>\n\n    <tbody class=\"date-row outflow\" id=\"outflow-date-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":59,"column":53},"end":{"line":59,"column":59}}}) : helper)))
    + "\"></tbody>\n\n    <tbody class=\"category-plans outflow\" id=\"outflow-plans-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":61,"column":60},"end":{"line":61,"column":66}}}) : helper)))
    + "\" aria-expanded=\"true\">\n    </tbody>\n\n    <tbody class=\"category-subtotal outflow\" id=\"outflow-subtotal-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":64,"column":66},"end":{"line":64,"column":72}}}) : helper)))
    + "\">\n    </tbody>\n\n    <tbody class=\"column-totals\">\n    </tbody>\n\n</table>\n    </div>\n</div>\n\n<div class=\"widget-action-button-group\">\n    <button class=\"btn btn-primary\" data-hook=\"save\" aria-busy=\"false\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"cashflow.save.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":75,"column":71},"end":{"line":75,"column":101}}}))
    + "</button>\n    <button class=\"btn btn-secondary\" data-hook=\"save-as\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"cashflow.saveas.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":76,"column":58},"end":{"line":76,"column":90}}}))
    + "</button>\n    <button class=\"btn btn-link\" data-hook=\"return\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"cashflow.back.to.forecasting",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":77,"column":52},"end":{"line":77,"column":93}}}))
    + "</button>\n</div>\n";
},"useData":true});