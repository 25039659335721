import util from '@glu/core/src/util';
import constants from 'common/dynamicPages/api/constants';
import store from 'system/utilities/cache';
import ListView from 'common/dynamicPages/views/workflow/list';
import dialog from '@glu/dialog';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import workspaceHelper from 'common/workspaces/api/helper';
import template from './listRecurringTransfers.hbs';

const RecurringTransfersList = ListView.extend({
    template,

    initialize(options) {
        const superOptions = {
            menuCategory: 'PMTS',
            serviceName: 'template/transfer',
            serviceFunc: null,
            businessType: null,
            context: 'TRANSFERS_RECURRING',
            returnRoute: 'PAYMENTS/listRecurringTransfers',
            viewId: options.viewId,
        };
        this.isAdmin = window.Bottomline.appRoot === 'ui-admin' || window.Bottomline.appRoot === 'ui-admin-portal';
        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    insert() {
        this.navigateTo('PAYMENTS/addTransfer/RECUR');
    },

    onRender(...args) {
        let alertResponse;

        ListView.prototype.onRender.call(this, args);

        if (this.hasLoadedRequiredData()) {
            if (store.has('listRecurringMessage')) {
                alertResponse = store.get('listRecurringMessage');
                this.renderMessage(alertResponse.action, alertResponse.response);
                store.unset('listRecurringMessage');
            } else if (store.has(`${this.contextKey}-alertMessage`) && store.has(`${this.contextKey}-confirms`)) {
                // for rejects coming from reject dialog
                this.renderMessage(store.get(`${this.contextKey}-alertMessage`), store.get(`${this.contextKey}-confirms`));
                store.unset(`${this.contextKey}-alertMessage`);
                store.unset(`${this.contextKey}-confirms`);
            }
            this.listenTo(this.gridView.wrapper.rows, 'sync', ListView.prototype.updateRefreshTimestamp);
        }
    },

    viewOptionRequirements(...args) {
        const options = ListView.prototype.viewOptionRequirements.call(this, args);
        options.context.overrideTypeCode = (this.isAdmin) ? 'RTRANSFR' : 'RTRANSFER';
        return options;
    },

    gridRowModify(opts) {
        this.navigateTo(`PAYMENTS/modifyRecurring/${opts.model.get('TNUM')}`);
        return Promise.resolve();
    },

    gridRowSelect(optsParam) {
        const opts = optsParam;
        /*
         * When rejected from view screen, the context key is needed for reject dialog
         * to properly return the reject message to the list view.
         */
        opts.model.contextKey = this.contextKey;
        store.set(`${this.contextKey}-actionModel`, opts.model);
        this.navigateTo(`PAYMENTS/viewRecurring/${opts.model.get('TNUM')}`);
        return Promise.resolve();
    },

    templateHelpers() {
        const obj = ListView.prototype.templateHelpers.call(this, undefined);
        const self = this;

        return util.extend(
            obj,
            {
                hasScheduleEntitlement() {
                    return self.hasEntitlement(constants.ACTION_SCHEDULE);
                },
            },
        );
    },

    export() {
        this.buildExportModel('CSV');
        this.exportModel.actionData.actionMode = 'SPAYMT';
        this.doExport();
    },

    print() {
        this.buildExportModel('PDF');
        this.exportModel.actionData.actionMode = 'SPAYMT';
        const printModal = new PrintViewModal({
            exportModel: this.exportModel,
            title: `${this.localeKey}title`,
        });
        dialog.custom(printModal);
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'TRANSFERS_RECURRING',
    view: RecurringTransfersList,
    options: {},
});

export default RecurringTransfersList;
