var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"advanced-search-counter\">\n    <span data-hook=\"selected\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.of",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":39},"end":{"line":2,"column":58}}}))
    + " <span data-hook=\"total\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.filtersSelected",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":91},"end":{"line":2,"column":126}}}))
    + "\n</div>\n<div data-control=\"filteritemview\">\n    <div class=\"search-control-list\"></div>\n    <div class=\"add-filter-container\"></div>\n</div>";
},"useData":true});