import $ from 'jquery';
import Layout from '@glu/core/src/layout';
import userInfo from 'etc/userInfo';
import Formatter from 'system/utilities/format';
import moment from 'moment';
import locale from '@glu/locale';
import Collection from '@glu/core/src/collection';
import alert from '@glu/alerts';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import InquiryAPI from 'system/webseries/api/inquiry';
import API from 'app/smb/api';
import FromAccountList from 'app/smbPayments/collections/fromAccountList';
import errHandler from 'system/errHandler';
import nhUtil from 'system/utilities/accessors';
import 'datePicker';
import 'select2';
import loadingModalTmpl from 'common/templates/loadingModal.hbs';
import payNowLayoutTmpl from './payNowLayout.hbs';

export default Layout.extend({
    initialize(options) {
        this.loadRequiredData();

        this.title = options.title || '';
        this.model = options.model.clone() || new Model();
        this.account = new Model();
        this.accountsCollection = new Collection([]);
        this.vendors = new Collection([]);
        this.viewFactory = options.viewFactory;
        this.defaultDate = 3;

        this.initializeModel();

        this.initializeDialog();
    },

    loadRequiredData() {
        this.loadPromisedAccounts();
        this.loadRequiredVendorData();
    },

    loadRequiredVendorData() {
        const self = this;

        const loadFinished = function () {
            self.setHasLoadedRequiredData(true);
            self.render();
        };

        const vendors = API.payeeList.get();

        Promise.all([vendors])
            .then((results) => {
                [self.vendors] = results;

                loadFinished();
            })
            .then(null, errHandler);
    },

    getAccountPromise() {
        return new Promise((resolve, reject) => {
            new FromAccountList({
                inquiryId: InquiryAPI.DOMESTIC_WIRE_FROM_ACCOUNT_INQUIRY_ID,
                typeCode: 'FEDWIRE',
                productCode: 'RTGS',
                functionCode: 'INST',

                labelFormat(data) {
                    const accountName = util.findWhere(
                        data.mapDataList,
                        {
                            toField: 'DEBIT_ACCOUNT_TITLE',
                        },
                    ).value;
                    return `${accountName} - ${data.label}${data.accountBalance ? ` - ${data.accountBalance} ${locale.get('smbPayments.available')}` : ''}`;
                },
            }).fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    loadPromisedAccounts() {
        const self = this;

        const loadFinished = function () {
            self.setHasLoadedRequiredData(true);
            self.render();
        };

        const dates = API.dates.get('business');

        Promise.all([dates, this.getAccountPromise()])
            .then((results) => {
                [
                    self.dates,
                    self.accountsCollection,
                ] = results;
                self.blockedDates = self.dates.blockedDates;
                self.cutoffTimes = self.dates.cutoffTimes;
                self.maxDate = self.dates.maxDate;
                self.earliestDay = self.dates.earliestDay;

                loadFinished();
            })
            .then(null, errHandler);
    },

    initializeDialog() {
        this.dialogTitle = this.getDynamicHeading();
        this.dialogButtons = [{
            text: locale.get('smbPayments.submit.review.btn'),
            className: 'btn btn-primary',
            callback: 'submit',
        }, {
            text: locale.get('smbPayments.cancel'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }];
    },

    getDynamicHeading() {
        let headingTitle = '';
        const invoiceNum = nhUtil.getFieldValue(this.model, 'INVOICENUMBER');

        headingTitle = `${nhUtil.getFieldValue(this.model, 'VENDORNAME')} - ${Formatter.formatCurrency(nhUtil.getFieldValue(this.model, 'TOTALAMOUNT'))
        } ( ${nhUtil.getFieldValue(this.model, 'STATUS_DESCRIPTION')} ) `;

        if (invoiceNum !== '') {
            headingTitle = `Bill #${nhUtil.getFieldValue(this.model, 'INVOICENUMBER')} - ${headingTitle}`;
        }

        return headingTitle;
    },

    initializeModel() {

    },

    template: payNowLayoutTmpl,
    loadingTemplate: loadingModalTmpl,

    ui: {
        $paymentDateFormGroup: '.payment-date-group',
        $paymentDateContainer: '.payment-date-container',
        $dateRange: '.dateRange',
        $paymentDateAlert: '.payment-date-alert',
        $accountSelect: '[data-hook="select-account"]',
        $accountFormGroup: '.account-group',
    },

    events: {
        'change @ui.$dateRange': 'handlePaymentDateChange',
        'change @ui.$accountSelect': 'handleAccountChange',
    },

    submit() {
        this.trigger('submit', this.model, this.account, this.vendors);
    },

    cancel() {
        this.trigger('cancel', this.model);
    },

    updatePaymentDate(selectedValue) {
        const selectedDate = moment(selectedValue);
        const defaultDate = moment(new Date()).add(this.defaultDate, 'd');

        if (selectedDate < defaultDate) {
            this.renderAlertContent();
        } else {
            this.alertRegion.reset();
        }

        this.model.set('PAYDATE', selectedValue);
    },

    handlePaymentDateChange(e) {
        this.updatePaymentDate($(e.target).val());
    },

    handleAccountChange(e) {
        const accountModelId = $(e.target).val();
        const account = this.accountsCollection.get(accountModelId);

        this.account = account;
        this.model.set('ACCOUNT', account.toJSON());
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            // Setup form fields.
            this.ui.$accountSelect.comboBox();

            this.renderDatePickerContent();
        }
    },

    renderAlertContent() {
        // display notification message
        const message = locale.get('smbPayments.send.expedited.warning');
        this.alertRegion.show(alert.warning(
            message,
            {
                canDismiss: false,
            },
        ));
    },

    renderDatePickerContent() {
        const self = this;

        const datePicker = this.$el.find('#dateRange');

        datePicker.nhDatePicker({
            singleDatePicker: true,
            showCalendarIcon: true,
            daysBack: 0,
        });
        const startDate = moment(new Date()).format(userInfo.getDateFormat());

        datePicker.data('daterangepicker').setStartDate(startDate);
        if (this.model.get('PAYDATE') === '') {
            this.model.set('PAYDATE', startDate);
        }
        if (nhUtil.getFieldValue(self.model, 'DUEDATE') === '') {
            nhUtil.setFieldValue(self.model, 'DUEDATE', startDate);
        }

        datePicker.on('apply.daterangepicker', (e) => {
            self.model.set('PAYDATE', e.target.value);
            nhUtil.setFieldValue(self.model, 'DUEDATE', e.target.value);
        });
    },

    templateHelpers() {
        return {
            accounts: this.accountsCollection.toJSON(),
            bill: this.model.toJSON(),
        };
    },
});
