import { createUseStyles } from '@glu/theming';

const styles = {
    wrapper: {
        display: 'inline-flex',
    },
    popoverWrapper: {
        marginLeft: '5px',
    },
};

export default createUseStyles(styles);
