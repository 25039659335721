import React, { Suspense } from 'react';
import { withStyles } from '@glu/theming';
import locale from '@glu/locale';
import configuration from 'system/configuration';

const CFF = React.lazy(() => import('@cff/forecast-ui'));

const loadingText = locale.get('loading');

/* istanbul ignore next */
// keeping for now as we will most likely be adding styles, will externalize when we do.
const styles = () => ({
});

let CFFComponent = () => (
    <Suspense fallback={(
        <div>
            {loadingText}
            ...
        </div>
    )}
    >
        <CFF apiRoot="/servlet-services/api/cffProxy" basename="ui/REPORTING/cff" />
    </Suspense>
);
if (configuration.isPortal()) {
    CFFComponent = () => (
        <Suspense fallback={(
            <div>
                {loadingText}
                ...
            </div>
        )}
        >
            <CFF apiRoot="/servlet-services/api/cffProxy" basename="ui-portal/REPORTING/cff" />
        </Suspense>
    );
}

export default withStyles(styles)(CFFComponent);
