var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"simpleEntryWidget.transfer.successMessage",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":66}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"rtp.realTimePayment.submissionMessage",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":62}}}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <h2 id=\"paymentProcessingMessage\"></h2>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "col-lg-2 ";
},"9":function(container,depth0,helpers,partials,data) {
    return " col-lg-3 ";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <h4>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"simpleEntryWidget.transfer.toAccount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":25,"column":65}}}))
    + "</h4>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <h4>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"payment.simple_entry_widget.beneficiary",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":16},"end":{"line":27,"column":68}}}))
    + "</h4>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"TRAN_DATE") || (depth0 != null ? lookupProperty(depth0,"TRAN_DATE") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"TRAN_DATE","hash":{},"data":data,"loc":{"start":{"line":34,"column":15},"end":{"line":34,"column":28}}}) : helper)))
    + "</p>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"VALUE_DATE") || (depth0 != null ? lookupProperty(depth0,"VALUE_DATE") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"VALUE_DATE","hash":{},"data":data,"loc":{"start":{"line":36,"column":15},"end":{"line":36,"column":29}}}) : helper)))
    + "</p>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"confirmPollingKeys") : depth0),{"name":"each","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":8},"end":{"line":53,"column":17}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"item") : depth0),{"name":"each","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":12},"end":{"line":52,"column":21}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"spinnerElement col-lg-3 col-md-6\">\n                    <div data-pollId=\"confirm.poll."
    + alias2(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":47,"column":51},"end":{"line":47,"column":60}}}) : helper)))
    + "\">\n                        <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.confirm.processing.msg",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":48,"column":28},"end":{"line":48,"column":71}}}))
    + "</h4>\n                        <span class=\"icon icon-refresh animate-spin\"></span>\n                    </div>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div><span class=\"updateIcon icon-check-circle\"></span></div>\n    <h2 id=\"submissionMessage\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isTransfer") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "    </h2>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"confirmPollingKeys") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":12,"column":11}}})) != null ? stack1 : "")
    + "</div>\n<div class=\"row success-data\">\n    <div class=\"col-lg-1 col-md-6\">\n        <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"simpleEntryWidget.transfer.transaction_id",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":16,"column":66}}}))
    + "</h4>\n        <p>"
    + alias2(((helper = (helper = lookupProperty(helpers,"TRANSACTION_ID") || (depth0 != null ? lookupProperty(depth0,"TRANSACTION_ID") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"TRANSACTION_ID","hash":{},"data":data,"loc":{"start":{"line":17,"column":11},"end":{"line":17,"column":29}}}) : helper)))
    + "</p>\n    </div>\n    <div class=\"columnSize "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"confirmPollingKeys") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":19,"column":27},"end":{"line":19,"column":87}}})) != null ? stack1 : "")
    + " col-md-6\">\n        <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"simpleEntryWidget.transfer.fromAccount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":20,"column":63}}}))
    + "</h4>\n        <p>"
    + alias2((lookupProperty(helpers,"maskIt")||(depth0 && lookupProperty(depth0,"maskIt"))||alias3).call(alias1,(depth0 != null ? lookupProperty(depth0,"DEBIT_ACCOUNT_NUMBER") : depth0),{"name":"maskIt","hash":{},"data":data,"loc":{"start":{"line":21,"column":11},"end":{"line":21,"column":42}}}))
    + "</p>\n    </div>\n    <div class=\"columnSize "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"confirmPollingKeys") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":23,"column":27},"end":{"line":23,"column":87}}})) != null ? stack1 : "")
    + "  col-md-6\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isTransfer") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":28,"column":15}}})) != null ? stack1 : "")
    + "        <p>"
    + alias2(((helper = (helper = lookupProperty(helpers,"BENE_NAME") || (depth0 != null ? lookupProperty(depth0,"BENE_NAME") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"BENE_NAME","hash":{},"data":data,"loc":{"start":{"line":29,"column":11},"end":{"line":29,"column":24}}}) : helper)))
    + "</p>\n    </div>\n    <div class=\"col-lg-2 col-md-6\">\n        <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"simpleEntryWidget.transfer.date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":32,"column":56}}}))
    + "</h4>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isTransfer") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":37,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"col-lg-2 col-md-6\">\n        <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"simpleEntryWidget.transfer.amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":40,"column":12},"end":{"line":40,"column":58}}}))
    + "</h4>\n        <p>"
    + alias2(((helper = (helper = lookupProperty(helpers,"formattedAmount") || (depth0 != null ? lookupProperty(depth0,"formattedAmount") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"formattedAmount","hash":{},"data":data,"loc":{"start":{"line":41,"column":11},"end":{"line":41,"column":30}}}) : helper)))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"CREDIT_CURRENCY") || (depth0 != null ? lookupProperty(depth0,"CREDIT_CURRENCY") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"CREDIT_CURRENCY","hash":{},"data":data,"loc":{"start":{"line":41,"column":31},"end":{"line":41,"column":50}}}) : helper)))
    + "</p>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"confirmPollingKeys") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":4},"end":{"line":54,"column":11}}})) != null ? stack1 : "")
    + "</div>\n<div class=\"row center\">\n    <button type=\"button\" name=\"button\" class=\"btn btn-secondary\" data-hook=\"getDoneBtn\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"simpleEntryWidget.transfer.doneBtn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":57,"column":89},"end":{"line":57,"column":136}}}))
    + "</button>\n</div>\n";
},"useData":true});