import Model from '@glu/core/src/model';
import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import services from 'services';
import http from '@glu/core/src/http';

const Line = Model.extend({
    idAttribute: 'cashFlowLineId',

    defaults: {
        name: 'Line',
        visible: true,
        changedName: 'Line',
    },

    initialize(attrs) {
        this.set({
            hiddenScenarios: [],
            scenarios: (attrs && attrs.scenarios) ? attrs.scenarios : new Collection(),
        });

        this.listenTo(
            this.get('scenarios'),
            {
                'add remove': this.handleScenarioChange,
            },
            this,
        );
    },

    changeColor(newColor) {
        this.color = newColor;
    },

    getActiveScenarioIds() {
        // always include base case
        const activeIds = [];

        this.get('scenarios').each(function (scenario) {
            if (!util.contains(this.get('hiddenScenarios'), scenario.get('cashFlowScenarioId'))) {
                activeIds.push(scenario.get('cashFlowScenarioId'));
            }
        }, this);

        return activeIds;
    },

    handleScenarioChange() {
        this.trigger('scenarioChange');
        this.save();
    },

    toJSON() {
        const json = Model.prototype.toJSON.call(this);
        json.scenarios = this.get('scenarios').toJSON();
        return json;
    },

    syncSuccess() {
        // model, resp, options
        this.trigger('sync', this);
    },

    syncError() {
        // model, resp, options
        this.trigger('error', this);
    },

    getItemId(...args) {
        const self = this;
        http.post(services.generateUrl('/cashflowLine/list'), (data) => {
            /*
             * TODO enforce name uniqueness - or get the ID back from the create request
             * will the server name ever not match the local name? whitespace? escaping?
             * make sure the ID isn't already used in self.collection?
             */
            const match = util.find(data, obj => obj.name === self.get('name'));
            self.set('cashFlowLineId', match.cashFlowLineId);
        });

        this.syncSuccess(...args);
    },

    sync(method, model, options) {
        /*
         * TODO handle options & callbacks - use bind to attach options.success/options.error
         * to callbacks
         */

        const success = util.bind(this.syncSuccess, this);
        const error = util.bind(this.syncError, this);
        // createSuccess = util.bind(this.getItemId, this);

        // Only persist assigned line colors, and not randomly assigned colors.
        const colorValue = (model.get('randomColor') === true) ? -1 : model.get('colorValue');

        let xhr;
        let scenarios;

        switch (method) {
        case 'create':
            xhr = http.post(
                services.generateUrl('/cashflowLine/save'),
                {
                    name: model.get('name'),
                    visible: model.get('visible'),
                    colorValue,
                },
                (result) => {
                    // oddly, the save service returns a list with 1 element.
                    model.set('cashFlowLineId', result[0].cashFlowLineId);
                },
                error,
            );

            break;
        case 'update':
            scenarios = model.get('scenarios').map(scenario => ({
                cashFlowScenarioId: scenario.get('cashFlowScenarioId'),
            }));

            xhr = http.post(
                services.generateUrl('/cashflowLine/update'),
                {
                    cashFlowLineId: model.get('cashFlowLineId'),
                    name: model.get('name'),
                    visible: model.get('visible'),
                    colorValue,
                    cashFlowScenarios: scenarios,
                },
                success,
                error,
            );

            break;
        case 'delete':
            xhr = http.post(
                services.generateUrl('/cashflowLine/delete'),
                {
                    cashFlowLineId: model.get('cashFlowLineId'),
                },
                success,
                error,
            );

            break;
        default:
                /*
                 * TODO delegate to backbone.sync here?
                 * console.warn('unsupported method passed to sync');
                 */
        }

        this.trigger('request', model, xhr, options);
        return xhr;
    },
});

export default Line;
