import Layout from '@glu/core/src/layout';
import entitlements from 'common/dynamicPages/api/entitlements';
import { postData } from 'common/util/services';
import services from 'system/servicesList';
import setupAccessibleTabs, { createTabsToggleButton, toggleTabs, setTabButtonText } from 'common/util/a11y/tabs';
import AccountSettingsWidgetTmpl from './AccountSettingsWidget.hbs';
import AccountSettingsList from './AccountSettingsList';
import PaperlessSettingsList from '../paperlessSettings/PaperlessSettingsList';

const AccountSettingsWidget = Layout.extend({
    template: AccountSettingsWidgetTmpl,
    isInitialRender: true,

    ui: {
        $navTabs: '[data-hook="getNavTabs"]',
        $accountSettingsTab: '[data-hook="getAccountSettingsTab"]',
        $paperlessSettingsTab: '[data-hook="getPaperlessSettingsTab"]',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
        $navItems: '[data-hook="getNavTabs"] .NavTabs-item',
    },

    initialize(options) {
        this.options = options;
        const getAccountSettingsEntitlement = entitlements.getEntitlements({
            context: {
                serviceName: 'tableMaintenance/renameAccount',
            },
        });
        const getPaperlessSettingsEntitlement = entitlements.getEntitlements({
            context: {
                serviceName: 'paperlessStatements',
            },
        });
        const getMultipleStatementTypesEntitlement = postData(services.generateUrl('paperlessStatements/multipleStatementTypesEntitlement'));
        Promise.all([
            getAccountSettingsEntitlement,
            getPaperlessSettingsEntitlement,
            getMultipleStatementTypesEntitlement,
        ]).then(this.parseEntitlements.bind(this))
            .then(() => {
                this.setHasLoadedRequiredData(true);
                this.render();
            });
    },

    /**
     * Extra a list of actions from each of the entitlement promises
     * @param {Array} response - destructured to schedule and
     * transmission profile entitlements
     */
    parseEntitlements([
        accountSettingsEntitlements,
        paperlessSettingsEntitlements,
        multipleStatementTypesEntitled,
    ]) {
        this.accountSettingsEntitlements = accountSettingsEntitlements.actions;
        this.paperlessSettingsEntitlements = paperlessSettingsEntitlements.actions;
        this.multipleStatementTypesEntitled = multipleStatementTypesEntitled;
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            return;
        }
        createTabsToggleButton(this);
        this.renderInitialTab();
        this.isInitialRender = false;
    },

    /**
     * Get active tab from the store, get default view and show it
     */
    renderInitialTab() {
        if (this.isEntitled(this.accountSettingsEntitlements)) {
            this.showAccountSettings();
        } else {
            this.showPaperlessSettings();
        }

        if (this.isInitialRender) {
            this.listenToOnce(this.activeView, 'afterRender', () => {
                setupAccessibleTabs(this, this.activateTab);
            });
        }
    },

    /**
     * Show the account settings list and set the active tab
     */
    showAccountSettings() {
        this.activeView = new AccountSettingsList({
            ...this.options,
            lastFragment: this.options?.returnRoute,
        });
        this.showTabContent(
            this.activeView,
            this.ui.$accountSettingsTab,
        );
    },

    /**
     * Show the paperless settings list and set the active tab
     */
    showPaperlessSettings() {
        this.activeView = new PaperlessSettingsList({
            multipleStatementTypesEntitled: this.multipleStatementTypesEntitled,
        });
        this.showTabContent(
            this.activeView,
            this.ui.$paperlessSettingsTab,
        );
    },
    /**
     * Select the view to be displayed based on tab selected via keyboard
     */
    activateTab(e) {
        const currElementId = e.target.id;
        if (currElementId === 'getPaperlessSettingsTab') {
            this.showPaperlessSettings();
        } else {
            this.showAccountSettings();
        }
    },

    /**
     * Remove 'is-active' class and add only to the desired active tab
     * @param {Object} $tab - jquery object for the tab that is to be selected
     */
    setActiveTab($tab) {
        this.ui.$navItems.removeClass('is-active');
        this.currentTabText = $tab.text();
        $tab.addClass('is-active');
        if (this.isInitialRender) {
            setTabButtonText(this, this.currentTabText);
        } else {
            toggleTabs(this);
        }
    },

    /**
     * When the tab is not yet active, update the content in the
     * region, save the active tab
     * @param {Object} view - view to render in the tab region
     * @param {Object} tabSelector - tab to set as active
     * @returns {function}
     */
    showTabContent(view, tabSelector) {
        if (tabSelector.hasClass('is-active')) {
            toggleTabs(this);
            return;
        }
        this.tabContentRegion.show(view);
        this.setActiveTab(tabSelector);
    },


    /**
     * Look at the select property to determine if the user is entitled
     * @param {object} entitledAction
     * @returns {boolean}
     */
    isEntitled(entitledAction) {
        if (!entitledAction) {
            return false;
        }
        return entitledAction.SELECT;
    },

    templateHelpers() {
        return {
            accountSettingsEntitled: this.isEntitled(this.accountSettingsEntitlements),
            paperlessSettingsEntitled: this.isEntitled(this.paperlessSettingsEntitlements),
        };
    },
});

export default AccountSettingsWidget;
