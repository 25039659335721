export default function (
    form,
    initialState,
) {
    const { model } = form.formView;

    const onChangeFeeType = function () {
        let isFlat = false;
        if (model.get('FEETYPE') === 'FLAT') {
            isFlat = true;
        }
        form.field('FLATFEE').shouldBeVisibleWhen(isFlat);
        form.field('PERCENTFEE').shouldBeVisibleWhen(!isFlat);
        form.field('MINIMUMFEE').shouldBeVisibleWhen(!isFlat);
        form.field('MAXIMUMFEE').shouldBeVisibleWhen(!isFlat);
        if (isFlat) {
            model.set({
                PERCENTFEE: '',
                MINIMUMFEE: '',
                MAXIMUMFEE: '',
            });
        } else {
            model.set('FLATFEE', '');
        }
    };

    if (initialState) {
        model.on('change:FEETYPE', () => {
            onChangeFeeType();
        });
        onChangeFeeType();
        form.field('PAYMENTTYPE').shouldBeReadOnly(true);
        form.field('PERCENTFEE').$el.inputmask(
            'number',
            {
                digits: 2,
            },
        );
    }
}
