import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import styles from './ColumnTitle.style';

const ColumnTitle = ({
  displayName,
  classes
}) => (
  <div className={classes.columnTitle}>
    <span className={classes.headerName}>{displayName}</span>
  </div>
);

ColumnTitle.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  displayName: PropTypes.string.isRequired
};

export default withStyles(styles)(ColumnTitle);
