import CollectionView from '@glu/core/src/collectionView';
import DraggableTileItem from 'app/smbPayments/views/accounts/balances/draggableTileItem';

/**
 * Represents a list of view types on the tile grid
 *
 * @class TileGridView
 */
const TileGridView = CollectionView.extend({
    tagName: 'ul',
    className: 'draggable-tiles',
    itemView: DraggableTileItem,
    itemViewOptions() {
        return {
            loanPrefs: this.options.loanPrefs,
        };
    },
});

export default TileGridView;
