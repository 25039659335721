var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"rapid-add-additional-container col-12\">\n    <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.add",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":10},"end":{"line":2,"column":33}}}))
    + "</span>\n    <input class=\"rapid-add-additional-amount add-amount-input form-control\" data-hook=\"getAddEntryFieldCount\" type=\"text\" value=\"1\">\n    <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":10},"end":{"line":4,"column":26}}}))
    + "</span>\n    <button class=\"rapid-add-additional-confirm btn btn-secondary btn-sm\" data-hook=\"getAddEntryField\" type=\"button\">\n        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.add",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":31}}}))
    + "\n    </button>\n</div>";
},"useData":true});