import React from 'react';
import PropTypes from 'prop-types';
import getIconData from 'common/util/iconData';

function insertOrReplaceTitle(svgData, title) {
    if (!title) {
        return svgData;
    }

    let updatedSvg;

    if (svgData.indexOf('<title>') !== -1) {
        updatedSvg = svgData.replace(/<title>.*<\/title>/, `<title>${title}</title>`);
    } else {
        const insertIndex = svgData.indexOf('><') + 1;
        updatedSvg = `${svgData.slice(0, insertIndex)}<title>${title}</title>${svgData.slice(insertIndex)}`;
    }

    return updatedSvg;
}

// A note about the use of dangerouslySetInnerHTML = DON'T USE IT.
// Here, the innerHtml is defined in our code, just not in a React context.
const Icon = ({
    className, color, dataToggle, dataPlacement, dataTrigger, dataContent,
    name, title, textVisible, text, noIconWrapper, iconData = getIconData(name),
}) => {
    if (!iconData) {
        return null;
    }

    // Allowing dangerouslySetInnerHTML as this is a safe case
    /* eslint-disable react/no-danger */
    const BareIcon = (
        <span
            className={`icon-${name}`}
            data-trigger={dataTrigger}
            data-placement={dataPlacement}
            data-content={dataContent}
            data-toggle={dataToggle}
            style={{ fill: color }}
            title={title}
            dangerouslySetInnerHTML={{ __html: insertOrReplaceTitle(iconData, title) }}
        />
    );
    /* eslint-enable react/no-danger */

    return noIconWrapper ? BareIcon : (
        <div className={`icon-wrapper ${className || ''}`}>
            {BareIcon}
            {text ? <span className={textVisible ? '' : 'sr-only'}>{text}</span> : ''}
        </div>
    );
};

Icon.propTypes = {
    name: PropTypes.string.isRequired,

    iconData: PropTypes.string,
    className: PropTypes.string,
    color: PropTypes.string,
    dataToggle: PropTypes.string,
    dataTrigger: PropTypes.string,
    dataPlacement: PropTypes.string,
    dataContent: PropTypes.string,
    title: PropTypes.string,
    textVisible: PropTypes.bool,
    text: PropTypes.string,
    noIconWrapper: PropTypes.bool,
};

export default Icon;
