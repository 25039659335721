import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import services from 'services';
import Constants from 'system/mfa/constants';
import helper from 'system/mfa/helper';
import OneTimePasswordChallengeView from 'system/mfa/views/oneTimePassword/selectContactMethod';
import VoicecCallOneTimePasswordChallenge from 'system/mfa/views/voiceCallOneTimePassword/voiceCallOTP';
import MobileOneTimePasswordChallengeView from 'system/mfa/views/mobileOneTimePassword/mobileOTP';
import AdminOneTimePasswordChallengeView from 'system/mfa/views/oneTimePassword/adminSelectContactMethod';
import ExtChallengeView from 'system/mfa/views/external/extChallenge';
import RSAChallengeView from 'system/mfa/views/rsa/enterRSAToken';
import RSAChallengeViewST from 'system/mfa/views/rsa/enterRSATokenSoftToken';
import RsaAuthChallengeView from 'system/mfa/views/rsa/adaptiveAuth/authentication';

export default Model.extend({
    sync(method, model, options) {
        if (method === 'read') {
            const data = this.toJSON();
            const service = services.generateUrl(`mfaService/${helper.translateUrlForLogin('getSettings')}`);

            http.post(service, data, (response) => {
                options.success(response);
            }, (response) => {
                // Passing default App Resource incase response is null
                options.error('mfa.error.message', response);
            });
        }
    },

    /**
     * @method parse
     * @param {object} response - challenge type info from the rest service.
     * - returns object based on provided challenge type.
     */
    parse(response) {
        const returnObject = {
            challengeType: +response.challengeType,
        };

        // setup new challenge type workflows
        switch (+response.challengeType) {
        case Constants.OTP_CHALLENGE_TYPE:
            returnObject.viewClass = OneTimePasswordChallengeView;
            returnObject.contacts = response.contacts;
            break;

        case Constants.MOBILE_OTP_CHALLENGE_TYPE:
            returnObject.viewClass = MobileOneTimePasswordChallengeView;
            returnObject.contacts = response.contacts;
            break;

        case Constants.RSA_CHALLENGE_TYPE:
            returnObject.viewClass = RSAChallengeViewST;
            returnObject.challengeType = +response.challengeType;
            break;

        case Constants.VASCO_CHALLENGE_TYPE:
        case Constants.ENTRUST_CHALLENGE_TYPE:
        case Constants.VIP_CHALLENGE_TYPE:
        case Constants.ONESPAN_CHALLENGE_TYPE:
            returnObject.viewClass = RSAChallengeView;
            returnObject.challengeType = +response.challengeType;
            break;

        case Constants.EXT_CHALLENGE_TYPE:
            returnObject.viewClass = ExtChallengeView;
            break;

        case Constants.RSA_AA_CHALLENGE_TYPE:
            returnObject.viewClass = RsaAuthChallengeView;
            break;

        case Constants.ADMIN_CHALLENGE_TYPE:
            returnObject.viewClass = AdminOneTimePasswordChallengeView;
            returnObject.contacts = response.contacts;
            break;

        case Constants.USER_MOBILE_REGISTRATION:
            returnObject.viewClass = OneTimePasswordChallengeView;
            returnObject.skipContactCheck = true;
            returnObject.userRequestingOnBehalfOf = this.get('userRequestingOnBehalfOf');
            break;
        case Constants.VOICE_CALL_CHALLENGE_TYPE:
            returnObject.viewClass = VoicecCallOneTimePasswordChallenge;
            returnObject.contacts = response.contacts;
            break;
        default:
        }

        return returnObject;
    },

});
