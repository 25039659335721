import Layout from '@glu/core/src/layout';
import CheckReview from 'common/dynamicPages/views/checkReview';
import Constants from 'app/balanceAndTransaction/constants';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import transform from 'common/util/transform';
import locale from '@glu/locale';
import Dialog from '@glu/dialog';
import Formatter from 'system/utilities/format';
import imageViewerTmpl from 'common/dynamicPages/views/mdf/componentTemplates/imageViewer.hbs';

export default Layout.extend({
    template: imageViewerTmpl,

    initialize(options) {
        this.model = options.model;
        this.model.unset('imgRetrievalErrorMessage');
        this.model.unset('respMessages');

        this.imageType = options.imageType;
        this.dialogButtons = [{
            text: locale.get('button.close'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }];
        this.modalClass = 'modal-xlg';
    },

    onRender() {
        const self = this;
        this.model.unset('selectedImage');
        self.checkView = new CheckReview({
            model: self.model,
            source: 'IMAGESEARCH',
        });
        self.imageViewer.show(self.checkView);
    },

    cancel() {
        Dialog.close();
    },

    getImage() {
        const jsonData = [];
        let service;
        const self = this;

        const addFn = function (name, val) {
            jsonData.push({
                name,
                value: val,
            });
        };

        addFn('ACCOUNT_TITLE', this.accountTitle);
        addFn('ACCOUNT_NUMBER', this.model.get('ACCOUNT_NUMBER'));
        addFn('ACCOUNTFILTER', this.model.get('ACCOUNTFILTER'));
        addFn('BANKCODE', this.model.get('BANKCODE'));
        addFn('BANK_NAME', this.model.get('BANK_NAME'));
        addFn('BANK_ID', this.model.get('BANK_ID'));
        addFn('AMOUNT', this.model.get('AMOUNT'));
        addFn('IMAGEID', this.model.get('IMAGEID'));
        addFn('IMAGETYPE', this.model.get('TYPE'));
        addFn('SERIALNUMBER', this.model.get('SERIALNUMBER'));
        addFn('POST_DATE', this.model.get('ENTERED_DATE'));
        addFn('_productCode', '_UTIL');
        addFn('_functionCode', 'IMGSRCH');

        switch (this.imageType) {
        case 'DEPTKT':
            addFn('CUST_REF', this.model.get('SERIALNUMBER'));
            service = services.generateUrl(Constants.DEPOSIT_ACCTS_IMAGE_SERVICE_SEARCH);
            break;
        case 'CHECK':
            service = services.generateUrl(Constants.CHECK_PAID_IMAGE_SERVICE_SEARCH);
            break;
        case 'RDEPITEM':
            service = services.generateUrl(Constants.RETURNED_ITEM_IMAGE_SERVICE_SEARCH);
            break;
        default:
        }

        if (this.model.has('imgRetrievalErrorMessage')) {
            this.model.set('imgRetrievalErrorMessage', '');
        }

        const imagePromise = new Promise((resolve, reject) => {
            http.post(
                service,
                {
                    item: jsonData,
                },
                (resp) => {
                    const { model } = self;
                    const headerData = transform.pairsToHash(resp.headerData.item);
                    const headerLabels = transform.pairsToHash(resp.headerLabels.item);
                    const topHeaderLabels = transform.pairsToHash(resp.topHeaderLabels.item);
                    const images = [];
                    const depositSlipItems = [];

                    util.each(resp.images, (image) => {
                        images.push(transform.pairsToHash(image.item));
                    });

                    util.each(resp.depositSlipItems, (depositItem) => {
                        depositSlipItems.push(transform.pairsToHash(depositItem.item));
                    });

                    if (headerData.AMOUNT) {
                        headerData.AMOUNT = Formatter.formatNumber(headerData.AMOUNT);
                    }
                    model.set({
                        images,
                        headerData,
                        topHeaderLabels,
                        headerLabels,
                        depositSlipItems,
                        image_data: !util.isEmpty(resp.frontImage) ? resp.frontImage.value : '',
                        image_type: !util.isEmpty(resp.frontImage) ? resp.frontImage.contentType : '',
                        image_data_reverse: !util.isEmpty(resp.backImage) ? resp.backImage.value : '',
                        image_type_reverse: !util.isEmpty(resp.backImage) ? resp.backImage.contentType : '',
                    });

                    if (!resp.result && resp.messages && resp.messages.length
                        && resp.messages.length > 0) {
                        model.set('imgRetrievalErrorMessage', resp.messages[0]);
                    }

                    resolve();
                },
                (result) => {
                    reject({
                        errorCode: result.status,
                        errorMessage: result.statusText,
                    });
                },
            );
        });

        return imagePromise;
    },
});
