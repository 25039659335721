import services from 'services';
import serviceUtil from 'common/util/services';
import constants from 'common/dynamicPages/api/constants';

export default {

    /**
     * @param {Object} payloadConfigInfo - The information needed to configure the request payload
     * @returns {Promise} The promise to get allowed credit currencies
     */
    createPromiseToRetrieveInterestRate(payloadConfigInfo) {
        const queryURL = services.generateUrl(constants.URL_GETINTERESTRATE_ACTION);
        const queryData = this.constructInterestRateRequestPayload(payloadConfigInfo);

        return serviceUtil.postData(queryURL, queryData);
    },

    /**
     * @param {Object} payloadConfigInfo - The information needed to configure the request payload
     * builds the request object to retrieve an interest rate
     */
    constructInterestRateRequestPayload(payloadConfigInfo) {
        return {
            productCode: payloadConfigInfo.productCode,
            functionCode: payloadConfigInfo.functionCode,
            typeCode: payloadConfigInfo.typeCode,
            bankCode: payloadConfigInfo.bankCode,
            accountNumber: payloadConfigInfo.accountNumber,
            currency: payloadConfigInfo.currency,
            subAccountNum: payloadConfigInfo.subAccountNum,
            accountFilter: payloadConfigInfo.accountFilter,
        };
    },
};
