/* eslint-disable react/no-array-index-key */
import './themeDefaults';
import Alert from '@glu/alerts-react';
import PropTypes from 'prop-types';
import React from 'react';
import useStyles from './Message.styles.js';

const Message = ({
  content,
  onHide,
  ...rest
}) => {
  const classes = useStyles(rest);

  return (
    <Alert onHide={onHide} variant={content.type} data-qa="file-upload-message">
      {content.title && <h3 className={classes.alertTitle}>{content.title}</h3>}
      { content.message && (typeof content.message === 'string'
        ? <p className={classes.alertMessage}>{content.message}</p>
        : (
          <div className={classes.alertMessage}>
            {content.message.map((line, index) => <p key={index}>{line}</p>)}
          </div>
        ))}
      {content.customContent || null}
    </Alert>
  );
};

Message.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    message: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    customContent: PropTypes.string
  }).isRequired,
  onHide: PropTypes.func
};

Message.defaultProps = {
  onHide: undefined
};

export default Message;
