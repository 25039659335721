import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import EntryView from 'common/dynamicPages/views/workflow/entry';

export default EntryView.extend({
    initialize(options) {
        this.model = store.get('appConfig-actionModel');
        const newOptions = {
            menuCategory: 'SMAINT',
            serviceName: 'appConfig/listView',
            serviceFunc: null,
            businessType: null,
            context: 'APPCONF_MAINT',
            mode: this.model.get('ACTION') === 'modify' ? 'modify' : 'view',
        };
        EntryView.prototype.initialize.call(this, util.extend(options, newOptions));
    },

    onRender() {
        EntryView.prototype.onRender.call(this);
        if (this.hasLoadedRequiredData()) {
            this.listenTo(this.pageView, 'ui-loaded', this.setPageLegendText);
        }
    },

    setPageLegendText() {
        const className = '.mdf-MAINT-SMAINT-APPCONF .section .section-header ';
        const legendHeader = locale.get(`SMAINT.MAINT.APPCONF.TAB.${this.model.get('SELECTEDTAB')}`);
        if (this.$(`${className}legend`).length) {
            this.$(`${className}legend`).text(legendHeader);
        } else {
            this.$(className).text(legendHeader);
        }
    },

    modify() {
        this.model.set('ACTION', 'modify');
        store.set(`${this.contextKey}-actionModel`, this.model);
        this.navigateTo('SMAINT/appConfigEntryModify');
    },

    /**
     * @description returns the value of the app config parameter
     * @returns {string}
     */
    getNewConfigValue() {
        return this.$(`#${this.model.get('KEY')}`).val();
    },

    /**
     * @description when enableNextDayPosPay or enableMultiPosPay config changes
     * and it changed from enabled to disabled, then issue a warning to the user
     * only save if user allows
     */
    enablePosPayChanged(key) {
        const oldValue = this.model.get('VALUE')?.toUpperCase() === 'ENABLED' ? '1' : '0';
        const newValue = this.getNewConfigValue();
        const modifiedBy = this.model.get('MODIFIED_BY');
        if (oldValue !== newValue && modifiedBy && newValue === '0') {
            // warn user, and only proceed if they say ok
            const msgKey = (key === 'ENABLENEXTDAYPOSPAY')
                ? 'appConfig.positivepaytype.change'
                : 'appConfig.multipositivepaytype.change';
            dialog.confirm(locale.get(msgKey), 'Warning', (ok) => {
                if (ok) {
                    this.saveConfig();
                }
            });
        } else {
            this.saveConfig();
        }
    },

    /**
     * @name saveConfig
     * @description calls base save method
     */
    saveConfig() {
        EntryView.prototype.save.call(this);
    },

    /**
     * @name save
     * @description for ENABLENEXTDAYPOSPAY, check if a warning needs to be issued before the save
     * otherwise, just pass thru to the base save method
     */
    save() {
        const key = this.model.get('KEY');
        if (key === 'ENABLENEXTDAYPOSPAY' || key === 'ENABLEMULTIPOSPAY') {
            this.enablePosPayChanged(key);
        } else {
            this.saveConfig();
        }
    },

    getListUrl() {
        return `${EntryView.prototype.getListUrl.call(this)}/${this.model.get('SELECTEDTAB')}`;
    },
});
