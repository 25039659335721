import Model from '@glu/core/src/model';
import services from 'services';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import httpError from 'common/util/httpErrorResult';

export default Model.extend({
    initialize() {
        this.validators = {
            fieldValue: {
                description: locale.get('RDC.Please.Select.Location'),
                exists: true,
            },
        };
    },

    submit() {
        this.sync('create', this);
    },

    sync(method, model, options) {
        if (method === 'create') {
            http.post(services.generateUrl('/ssoFieldValues/process'), this.convertModelAttributesToServerJSON(), options.success, httpError.getResult(options, model));
        }
    },

    convertModelAttributesToServerJSON() {
        return {
            fieldValue: this.get('fieldValue'),
            fieldName: 'LOCATION_ID',
            vendorCode: 'RDCTYPC',
        };
    },
});
