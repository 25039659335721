import PositivePayDecisionList from 'app/checkManagement/views/listPositivePayDecision';
import ReversePositivePayDecisionList from 'app/checkManagement/views/listReversePositivePayDecision';
import EPositivePayDecisionList from 'app/checkManagement/views/listEPositivePayDecision';
import store from 'system/utilities/cache';
import util from '@glu/core/src/util';
import setupAccessibleTabs, { createTabsToggleButton, setTabButtonText } from 'common/util/a11y/tabs';
import entitlements from 'common/dynamicPages/api/entitlements';
import ContextApi from 'common/dynamicPages/api/context';
import workspaceHelper from 'common/workspaces/api/helper';
import FraudParent from 'app/FraudChangeParent';
import Constants from 'app/checkManagement/constants';
import FraudControlListView from './listPaymentFraudControl';
import DecisionHistoryListView from './listPositivePayDecisionHistory';
import EDecisionHistoryListView from './listEPositivePayDecisionHistory';
import template from './fraudControlClientView.hbs';

const FraudControl = FraudParent.extend({
    template,

    currentTab: 'view',

    isInitialRender: true,

    /*
     *  @method: setWidget
     *  @param: {View} Widget
     *  Method to instantiate listeners to connect grid to widget changes
     *  Called by (higher level) SurrogateWidget when view instantiates
     */
    setWidget(widget) {
        this.listenTo(widget.model, 'change:size', this.handleWidgetSizeChange);
    },

    /*
     *  @method: handleWidgetSizeChange
     *  Listener to resize the grid when the widget is resized
     */
    handleWidgetSizeChange() {
        util.defer(() => {
            this.activeView.gridView.grid.tableView.setWidth();
        });
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            createTabsToggleButton(this);
            if (store.get('cm_listView_payDecision-viewAll')) {
                this.currentTab = store.get('cm_listView_comingToList');
            }

            // Override cuttentTab if viewId is set
            const viewId = store.get('risk-viewId');
            if (store.has('risk-viewId')) {
                if (viewId === Constants.POS_PAY_NEEDS_DECISION_VIEWID ||
                    viewId === Constants.NEXTDAY_POS_PAY_NEEDS_DECISION_VIEWID ||
                    viewId === Constants.STANDARD_POS_PAY_NEEDS_DECISION_VIEWID) {
                    this.currentTab = 'posPay-items';
                } else if (viewId === Constants.ELECTRONIC_POS_PAY_NEEDS_DECISION_VIEWID) {
                    this.currentTab = 'ePay-items';
                } else if (viewId === Constants.REVERSE_POS_PAY_NEEDS_DECISION_VIEWID) {
                    this.currentTab = 'revPosPay-items';
                }
            }
            this.openView(this.currentTab, viewId);
            this.isInitialRender = false;
        } else {
            this.loadViewRequirements();
        }
    },

    updateView(e) {
        const href = this.$(e.currentTarget).data('id');
        e.preventDefault();

        this.openView(href);
        this.changeTab(e.currentTarget);
    },

    openView(href, viewId) {
        switch (href) {
        case 'view':
            this.changeLink('accs-by-type');
            this.activeView = new FraudControlListView({
                returnRoute: this.options.returnRoute,
            });
            this.displayField(true, this.ui.$divLinks);
            break;
        case 'decision-history':
            this.activeView = new DecisionHistoryListView({
                serviceName: 'cm/listView/positivePayDecisionHistory',
                hideFields: true,
            });
            this.displayField(false, this.ui.$divLinks);
            break;
        case 'edecision-history':
            this.activeView = new EDecisionHistoryListView({
                serviceName: 'risk/listView/ePositivePayDecisionHistory',
                hideFields: true,
            });
            this.displayField(false, this.ui.$divLinks);
            break;
        case 'accs-by-type':
            this.changeLink(href);
            this.activeView = new FraudControlListView({
                returnRoute: this.options.returnRoute,
            });
            break;
        case 'posPay-items':
            this.changeLink(href);
            this.activeView = new PositivePayDecisionList({
                serviceName: 'cm/listView/positivePayDecision',
                hideFields: true,
                returnRoute: this.options.returnRoute,
                viewAll: true,
                viewId,
            });
            break;
        case 'revPosPay-items':
            this.changeLink(href);
            this.activeView = new ReversePositivePayDecisionList({
                serviceName: 'cm/listView/reversePositivePayDecision',
                hideFields: true,
                returnRoute: this.options.returnRoute,
                viewAll: true,
                viewId,
            });
            break;
        case 'ePay-items':
            this.changeLink(href);
            this.activeView = new EPositivePayDecisionList({
                serviceName: 'risk/listView/ePositivePayDecision',
                hideFields: true,
                returnRoute: this.options.returnRoute,
                viewAll: true,
                viewId,
            });
            break;

        default:
            this.activeView = new FraudControlListView();
            this.displayField(true, this.ui.$divLinks);
        }

        this.pageRegion.show(this.activeView);
        if (this.isInitialRender) {
            this.currentTabText = this.ui.$navItems.filter('.is-active').find('a').text();
            setTabButtonText(this, this.currentTabText);
            this.listenToOnce(this.activeView, 'afterRender', () => {
                setupAccessibleTabs(this, this.updateView);
            });
        }
    },

    loadViewRequirements() {
        const self = this;

        const options = {
            context: ContextApi.menuContext.getContext('CM_FRAUD_CTRL'),
        };

        const entitlementPromise = entitlements.getEntitlements(options);

        function getEntitlementsData(productCode, typeCode) {
            const optionsPP = util.extend(
                options,
                {
                    typeCode,
                    productCode,
                },
            );
            return entitlements.getEntitlements(optionsPP);
        }

        const entitlementEBMPPDECPromise = getEntitlementsData('RISK', 'EBMPPDEC');
        const entitlementBMPPDECPromise = getEntitlementsData('CM', 'BMPPDEC');
        const entitlementCMPPDECPromise = getEntitlementsData('CM', 'CMPPDEC');

        Promise.all([
            entitlementPromise,
            entitlementEBMPPDECPromise,
            entitlementBMPPDECPromise,
            entitlementCMPPDECPromise,
        ]).then((results) => {
            const [,, PositivePay, ReversePositivePay] = results;
            store.set('PositivePay', PositivePay);
            store.set('ReversePositivePay', ReversePositivePay);
            if (results[1].actions && results[1].actions.SELECT) {
                self.isEBMPPDECEntitled = true;
            }
            if (results[2].actions && results[2].actions.SELECT) {
                self.isDHEntitled = true;
                self.isBMPPDECEntitled = true;
            }
            if (results[3].actions && results[3].actions.SELECT) {
                self.isDHEntitled = true;
                self.isCMPPDECEntitled = true;
            }
            self.setHasLoadedRequiredData(true);
            self.render();
        });
    },

    hasEntitlement(action) {
        return (this.entitlements && this.entitlements[action] === true);
    },

    templateHelpers() {
        const self = this;
        return {
            hasEBMPPDECEntitlement() {
                return self.isEBMPPDECEntitled;
            },

            hasDecisionHistoryEntitlement() {
                return self.isDHEntitled;
            },

            hasBMPPDECEntitlement() {
                return self.isBMPPDECEntitled;
            },

            hasCMPPDECEntitlement() {
                return self.isCMPPDECEntitled;
            },
        };
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'CM_FRAUD_CTRL_CLIENT',
    view: FraudControl,
    options: {},
    useMobileCard: true,
});

export default FraudControl;
