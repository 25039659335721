import Layout from '@glu/core/src/layout';
import Collection from '@glu/core/src/collection';
import $ from 'jquery';
import gridApi from 'common/dynamicPages/api/grid';
import number from 'numeral';
import CollectionView from '@glu/core/src/collectionView';
import util from '@glu/core/src/util';
import dialog from '@glu/dialog';
import Model from '@glu/core/src/model';
import alertMessage from 'common/api/alertMessage';
import WarningDialog from 'common/dynamicPages/views/warningDialog';
import store from 'system/utilities/cache';
import helpPageUtil from 'common/util/helpPage';
import locale from '@glu/locale';
import Formatter from 'system/utilities/format';
import alert from '@glu/alerts';
import scroll from 'common/util/scroll';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import RejectDialog from 'common/dynamicPages/views/rejectDialog';
import systemConfig from 'system/configuration';
import workspaceHelper from 'common/workspaces/api/helper';
import entitlements from 'common/dynamicPages/api/entitlements';
import PaymentUtil from 'common/util/paymentUtil';
import DataAPI from 'common/dynamicPages/api/data';
import validatorUtil from 'common/util/validatorUtil';
import services from 'services';
import http from '@glu/core/src/http';
import AuditHistorySetup from 'app/auditHistory/util/auditHistorySetup';
import DuplicateDialog from 'common/dynamicPages/views/duplicateDialog';
import constants from 'common/dynamicPages/api/constants';
import transform from 'common/util/transform';
import moment from 'moment';
import userInfo from 'etc/userInfo';
import PanelWorkflowAssignment from 'common/template/panelWorkflow/panelWorkflowAssignment';
import PanelWorkflowCodesCollection from 'common/template/panelWorkflow/panelWorkflowCodes';
import panelWorkflowAssignmentUtil from 'common/template/panelWorkflow/util';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import PaymentsTransitionsView from 'app/administration/views/paymentManagement/paymentsTransitionsView';
import { appBus } from '@glu/core';
import TransferSetModel from '../models/transferSet';
import TransferModel from '../models/transfer';
import TransferView from './transferView';
import Signatures from '../collections/signatures';
import template from './transferLayout.hbs';

const DEFAULT_CUSTOMER_REFERENCE_LENGTH = 20;

export default Layout.extend({
    template,
    className: 'transfer-layout',

    ui: {
        addAmtInput: '[data-hook="getAddEntryFieldCount"]',
        templateForm: '.template-form',
        transferAmount: '[data-hook="transferAmount"]',
        transferDate: '[data-hook="transferDate"]',
        currencyDisplay: '[data-hook="currencyDisplay"]',
        totalDisplay: '[data-hook="totalDisplay"]',
        headline: '.landing-header',
        headerSummaryAmount: '[data-hook="summary-amount"]',
        headerSummaryCurrency: '[data-hook="summary-currency"]',
        saveAsTemplate: '[data-hook="template-save"]',
        singleRecur: '[data-hook="single-recur-select"]',
        optFieldSwitch: '[data-hook="optFieldSwitch"]',
        schedulePayWarning: '[data-hook="schedulePayWarning"]',
        signaturesDropdown: '[data-hook="getMaxSignaturesOverrideDropdown"]',
        $popovers: '[data-toggle="popover"]',
        restrictTemplate: '[data-hook="restrict-template-select"]',
        transferReference: '[data-hook="getTransferRef"]',
        datepicker: '[data-hook="getTransferDate"]',
        dateField: '[name="VALUE_DATE"]',
        startDate: '[name="START_DATE"]',
        startTime: '[name="START_TIME"]',
        timepicker: '[data-hook="getTransferTime"]',
        timeField: '[name="TRANSFER_TIME"]',
        maxAmtField: '[data-hook="getTemplateMaxAmount"]',
    },

    events: {
        'click [data-hook="getAddEntryField"]': 'addTransfers',
        'click [data-hook="submit-button"]': 'saveTransfers',
        'click [data-hook="save-later-button"]': 'saveTransfersForLater',
        'click [data-hook="cancel-button"]': 'cancel',
        'click [data-hook="transition-button"]': 'initiateTransition',
        'click [data-hook="modify-button"]': 'modify',
        'click [data-hook="approve-button"]': 'approve',
        'click [data-hook="unapprove-button"]': 'unapprove',
        'click [data-hook="delete-button"]': 'delete',
        'click [data-hook="restore-button"]': 'restore',
        'click [data-hook="reject-button"]': 'reject',
        'click [data-hook="print-button"]': 'print',
        'click [data-hook="approve-transition-button"]': 'approveTransition',
        'click [data-hook="reject-transition-button"]': 'rejectTransition',
        'change [data-hook="optFieldSwitch"]': 'toggleOptFields',
        'click [data-hook="template-save"]': 'toggleTemplateForm',
        'click [data-hook="single-recur-select"]': 'snglRecurringSelect',
        'change [data-hook="getTransferRef"]': 'updateTransferRef',
        'click [data-hook="getClearButton"]': 'clearAllDialog',
        'change [data-hook="getTransferDate"]': 'updateTransferDate',
        'blur [data-hook="getTransferTime"]': 'updateTransferTime',
        'change [data-hook="getTemplateMaxAmount"]': 'maxAmountChanged',
    },

    regions: {
        panelWorkflowAssignmentRegion: 'div[data-region="panelWorkflowAssignmentRegion"]',
        singleRegion: '.single-region',
        entryRegion: '.entry-region',
        recurRegion: '.recur-region',
        auditRegion: '[data-hook="auditRegion"]',
    },

    initialize(opts) {
        let savedModel;

        if (opts.transferModel) {
            savedModel = opts.transferModel;
        } else if (opts.templateMode) {
            if (systemConfig.isAdmin()) {
                savedModel = store.get('adminTemplate_transfer-actionModel');
                store.unset('adminTemplate_transfer-actionModel');
            } else {
                savedModel = store.get('template_transfer-actionModel');
                store.unset('template_transfer-actionModel');
            }
        } else if (opts.type === 'RECUR') {
            savedModel = store.get('template_transfer_recurring-actionModel');
            store.unset('template_transfer_recurring-actionModel');
        }
        this.savedModel = savedModel;
        if (opts.mode) {
            this.formState = opts.mode;
        } else if (opts.readOnly) {
            this.formState = 'view';
        }

        const { buttons, buttonsToHide } = savedModel || {};
        this.buttons = util.pluck(buttons, 'action');
        this.buttonsToHide = buttonsToHide;
        this.type = opts.type;
        this.contextKey = savedModel ? savedModel.contextKey : null;
        this.isWidget = savedModel ? savedModel.isWidget : false;
        this.templateMode = opts.templateMode;
        this.mode = opts.mode;
        this.fxRateType = false;
        this.hasDefaultDate = false;

        this.hasTemplateInsertEntitlement = false;
        this.singleRecurringSeries = false;
        this.returnRoute = 'PAYMENTS/manageTransfers';
        /*
         * Identify if the payment is being copied from a template or the
         * payment was created from a template.  If true, You want to make the
         * to/from accounts read only and hide delete/add row buttons.
         */
        this.modifyPaymentFromTemplate = savedModel && (savedModel.get('ENTRYMETHOD') === '1' || savedModel.get('ENTRYMETHOD') === '2');
        this.copyFromTemplate = (opts.templateId && !opts.templateMode);
        this.copyTemplateFromTemplate = opts.copyTemplateFromTemplate;
        this.overloadedMethodName = opts.overloadedMethodName;
        this.model = new TransferSetModel(
            {},
            {
                templateMode: this.templateMode,
            },
        );
        this.hideTime = applicationConfigParams.getValue('TRANSFER', 'ENABLETRANSFERTIME') !== '1';

        const timeZone = userInfo.get('timezone') || serverConfigParams.get('SystemTimezone');
        this.userTimezone = moment(new Date()).tz(timeZone).format('z');
        this.customerReferenceLength = DEFAULT_CUSTOMER_REFERENCE_LENGTH;

        // This is how backend knows we are saving directly as a transfer set
        this.model.set('functionCode', opts.templateMode ? 'TMPL' : 'INST');

        this.processingDates = {
            blockedDates: [],
            processingDays: [],
            daysForward: [],
            cutOffTimes: [],
            daysBack: 0,
            showCalendarIcon: true,
            showDropdowns: true,
        };

        if (savedModel && opts.readOnly) {
            this.model.set({
                ENTRYMETHOD: savedModel.get('ENTRYMETHOD'),
                FUNCTION: savedModel.get('FUNCTION'),
                UPDATECOUNT__: savedModel.get('UPDATECOUNT__'),
                TNUM: savedModel.get('TNUM'),
            });
        }
        this.tempModel = this.model;
    },

    /**
     * @method rejectTransition
     * @description handles initiating transition rejection
     */
    rejectTransition() {
        this.submitTransition('reject');
    },

    /**
     * @method approveTransition
     * @description handles initiating transition approval
     */
    approveTransition() {
        this.submitTransition('approve');
    },

    /**
     * @method getJSONData
     * @description converts model data to json format
     */
    getJSONData() {
        return {
            item: {
                item: transform.hashToPairs({
                    TNUM: this.savedModel.get('TNUM'),
                    PAYMENT_PRODUCT: this.savedModel.get('PRODUCT'),
                    PAYMENT_FUNCTION: this.savedModel.get('FUNCTION'),
                    PAYMENT_TYPE: this.savedModel.get('TYPE'),
                }),
            },
        };
    },

    /**
     * @method initiateTransition
     * @description handles triggering the transition calls
     */
    initiateTransition() {
        const self = this;
        const paymentsTransitionsView = new PaymentsTransitionsView({
            transitions: self.transitions,
            status: self.savedModel.get('STATUS_DESCRIPTION'),
            model: new Model(util.pick(self.savedModel.attributes, 'TNUM', 'PRODUCT', 'FUNCTION', 'TYPE')),
        });

        this.listenTo(paymentsTransitionsView, 'resolve', (data) => {
            self.actionSuccess(data);
        });

        dialog.open(paymentsTransitionsView);
    },

    /**
     * @method submitTransition
     * @description handles transition action like approve/reject
     * @param {string} action
     */
    submitTransition(action) {
        const self = this;
        const jsonData = this.getJSONData();

        http.post(services.generateUrl(`transitionsMaint/${action}`), jsonData, (data) => {
            self.actionSuccess(data);
        }, (data) => {
            self.actionError(data);
        });
    },

    /**
     * @method actionSuccess
     * @description handles success response from server
     * @param {object} confirmResponse
     */
    actionSuccess(confirmResponse) {
        let { contextKey } = this;
        let successMessage;

        if (this.contextDef && this.contextDef.keyOverride) {
            contextKey = this.contextDef.keyOverride;
        }

        if (confirmResponse.resultType === 'WARNING') {
            successMessage = {
                message: confirmResponse.confirms.confirmResults[0].messages[0],
                type: confirmResponse.resultType,
            };
            store.set('template_listView_corp-alertMessage', successMessage);
        } else if (confirmResponse.resultType === 'DELEGATERESULT') {
            successMessage = {
                message: confirmResponse.confirms.confirmResults[0].messages,
                type: confirmResponse.resultType,
            };
            store.set(`${this.contextKey}-alertMessage`, successMessage);
        } else {
            store.set(`${contextKey}-alertMessage`, this.formState.toUpperCase());
            store.set(`${contextKey}-confirms`, confirmResponse);
        }

        // if we're in a stackview, render our notification instead of navigating away
        if (this.stack && this.stack.stack && this.cid === this.stack.stack[1].view.cid) {
            this.notify(this.mode.toUpperCase(), confirmResponse);
        } else {
            workspaceHelper.returnToCurrentWorkspace(this);
        }
    },

    /**
     * @method actionError
     * @description handles displaying errors in alerts section
     * @param {Model} model
     */
    actionError(model) {
        this.renderMessage(model.error);
        this.alertRegion.$el.hide();
        $('#modal-body').animate({
            scrollTop: 0,
        }, 300);
        this.alertRegion.$el.fadeIn(600, () => this.alertView.$el.focus());
    },

    /**
     * This is a promise function that will call InquiryService to get the FXRateType
     * for the company .
     * The FXRAteType is used to determine if debit/credit amount should be left
     * blank during create/modify
     * and filled when Get Rate is called
     */
    getCompanyRTGSPreferences() {
        const url = services.generateUrl('/inquiry/getData');
        const self = this;

        const postData = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    searchType: '5',
                    inquiryId: 40011,
                },
            },
        };

        //
        return new Promise((resolve) => {
            http.post(url, postData, (data) => {
                let fxRateType = false;

                if (data.inquiryResponse.rows[0].columns[0].fieldName === 'FXRATETYPE'
                    && data.inquiryResponse.rows[0].columns[0].fieldValue === '1') {
                    fxRateType = true;
                }
                resolve({
                    fxRateType,
                });
            }, () => {
                // handle errors
                alertMessage.renderMessage(self, locale.get('common.fxRateType.data.error'), locale.get('common.fxRateType.data.error'));
            });
        });
    },

    /**
     * function setValidator: set validator for model
     * @param {string} name: name of validator
     * @param {string} type: fieldtype of the filed
     * @param {string} label: fieldLabel of the filed
     * @param {Model} model: the model whose validator need to be set
     */
    setValidator(name, type, label, model) {
        const field = {
            name,
            fieldType: type,
            fieldLabel: label,
        };
        validatorUtil.setValidator(field, model);
    },

    /**
     * @description removes the validator for the 'name' attribute in the model
     * @param {string} name
     * @param {Model} model
     */
    removeValidator(name, model) {
        model.removeValidator(name);
    },

    loadRequiredData() {
        const panelApprovalCheck = panelWorkflowAssignmentUtil
            .getPanelApprovalConfig(this.mode);
        this.panelWorkflowCodes = new PanelWorkflowCodesCollection();

        if (systemConfig.isAdmin()) {
            this.setupTransitionTrackingGrid();
        }

        let service = 'template/transfer';
        if (systemConfig.isAdmin()) {
            service = 'adminTemplate/transfer';
        }
        const request = this.getHelpPageRequest(this.options);
        let model;

        const ctx = {
            serviceName: service,
        };

        const opt = {
            context: ctx,
        };
        const functionCode = this.model.get('functionCode');
        let getModelOptions = {
            context: {
                serviceName: '/payment/transfer',
                functionCode,
                typeInfo: {
                    functionCode,
                    productCode: 'RTGS',
                    typeCode: 'TRANSFER',
                },
            },
        };
        if (systemConfig.isAdmin()) {
            getModelOptions = {
                context: {
                    serviceName: '/adminPayment/listView/payments',
                    functionCode,
                    subType: '*',
                    actionData: {
                        productCode: 'RTGS',
                        typeCode: 'TRANSFER',
                    },
                },
            };
        }

        const transferEntitlementsPromise = entitlements.getEntitlements(opt);
        const helpPagePromise = helpPageUtil.getHelpPagePromise(request);

        /*
         * promise to get the FXRate type field. setting it on initialize variable to
         * be available throughout
         */
        const fxRateTypePromise = this.getCompanyRTGSPreferences();
        const dataModelPromise = DataAPI.model.generate(getModelOptions, false);

        const promiseArray = [
            helpPagePromise,
            fxRateTypePromise,
            panelApprovalCheck,
            this.panelWorkflowCodes.fetch(),
            dataModelPromise,
        ];
        if (this.templateMode) {
            const signaturePromise = this.fetchSignatures(Signatures);
            const transferCompanySettingsPromise = this.getTransferCompanyPreferences();
            promiseArray.push(signaturePromise);
            promiseArray.push(transferCompanySettingsPromise);
        }

        Promise.all(promiseArray).then((results) => {
            store.set('helpPage', results[0].helpPage);
            this.fxRateType = results[1].fxRateType;
            this.fieldData = results[4].fieldData;
            if (this.model.get('functionCode') === 'TMPL') {
                this.setValidator('setName', this.fieldData.TEMPLATE_CODE.fieldType, this.fieldData.TEMPLATE_CODE.fieldLabel, this.model);
                this.setValidator('setDescription', this.fieldData.TEMPLATE_DESCRIPTION.fieldType, this.fieldData.TEMPLATE_DESCRIPTION.fieldLabel, this.model);
            }
            this.customerReferenceLength = this.fieldData.CUSTOMER_REFERENCE.maxLen;

            if (this.templateMode) {
                this.signaturesData = this.mapDropdownData(results[5]);
                const pref = this.mapTemplateCompanyPreferences(results[6]);
                this.defaultSignature = pref.defaultSignature;
                this.showSignatureDropDown = pref.showSignatureDropDown && applicationConfigParams.getValue('PAYMENTS', 'ALLOWTEMPLATESIGNATUREOVERRIDE') === '1';
                this.showMaxAmount = pref.allowMaxAmtPmtFromTmpl && applicationConfigParams.getValue('PAYMENTS', 'ALLOWMAXAMTPAYMENTFROMTMPL') === '1';
                this.baseCurrency = pref.baseCurrency;
            }
            if (this.options.templateId) {
                this.loadTemplateTransfer(model);
            } else if (this.options.type !== 'SINGLE') {
                this.loadNonSingleTypeTransfer(model, transferEntitlementsPromise);
            } else if (this.options.type === 'SINGLE') {
                this.loadSingleTypeTransfer(model, transferEntitlementsPromise);
            }
        });
    },

    /**
     * @method loadTemplateTransfer
     * @description loads required data for a transfer from a template
     * @param model
     */
    loadTemplateTransfer(model) {
        let localModel = model;
        if (this.options.type !== 'SINGLE') {
            this.model.fetch({
                setName: this.options.templateId,
                inquiryId: this.options.inquiryId,
                userGroup: this.options.userGroup,

                success: () => {
                    this.type = this.model.get('setEntryMode');
                    this.setHasLoadedRequiredData(true);
                    this.render();
                },
            });
        } else {
            localModel = new TransferModel(this.options.transferModel
                && this.options.transferModel.attributes);
            localModel.fetch({
                recurId: this.options.templateId,
                copyPaymentFromTemplate: this.copyFromTemplate,
                copyTemplateFromTemplate: this.copyTemplateFromTemplate,

                success: () => {
                    this.collection = new Collection([localModel]);
                    if (this.copyTemplateFromTemplate) {
                        this.hasTemplateInsertEntitlement = true;
                    } else {
                        this.hasTemplateInsertEntitlement = false;
                    }
                    this.setHasLoadedRequiredData(true);
                    this.render();
                },
                error: () => {
                    if (systemConfig.isPortal()) {
                        appBus.trigger('router:navigate', 'PAYMENTS/readAccessDenied', true);
                    }
                },
            });
        }
    },

    /**
     * @method loadNonSingleTypeTransfer
     * @description loads required data for a multi, one-to-many, many-to-one transfer
     * @param {object} model
     * @param {Promise} transferEntitlementsPromise
     */
    loadNonSingleTypeTransfer(model, transferEntitlementsPromise) {
        let localModel = model;
        if (this.options.transferModel) {
            localModel = new TransferModel(this.options.transferModel
                && this.options.transferModel.attributes);
            localModel.fetch({
                recurId: this.options.recurId,

                success: () => {
                    this.collection = new Collection([localModel]);
                    this.setHasLoadedRequiredData(true);
                    this.render();
                },
            });
        } else {
            // New transfer, no data to load but need to satisfy the formalities.
            transferEntitlementsPromise.then((result) => {
                this.hasTemplateInsertEntitlement = result.actions.INSERT;
                this.setHasLoadedRequiredData(true);
                this.render();
            });
        }
    },

    /**
     * @method singleTypeTransfer
     * @description loads required data for a single type transfer
     * @param {object} model
     * @param {Promise} transferEntitlementsPromise
     */
    loadSingleTypeTransfer(model, transferEntitlementsPromise) {
        let localModel = model;
        const ctx = {
            serviceName: 'template/transfer',
        };

        let opt = {
            context: ctx,
        };

        if (this.options.recurId && systemConfig.isAdmin()) {
            localModel = new TransferModel(this.options.transferModel
                && this.options.transferModel.attributes);
            localModel.fetch({
                recurId: this.options.recurId,

                success: () => {
                    this.collection = new Collection([localModel]);
                    this.hasTemplateInsertEntitlement = true;
                    this.setHasLoadedRequiredData(true);
                    this.render();
                },
            });
        } else if (this.options.recurId) {
            localModel = new TransferModel(this.options.transferModel
                && this.options.transferModel.attributes);
            localModel.fetch({
                recurId: this.options.recurId,

                success: () => {
                    this.collection = new Collection([localModel]);
                    transferEntitlementsPromise.then((result) => {
                        this.hasTemplateInsertEntitlement = result.actions.INSERT;
                        this.setHasLoadedRequiredData(true);
                        if (localModel.get('STATUS') === 'AP') {
                            this.unapprovingSchedule = true;
                            opt = {
                                context: ctx,
                                actionMode: 'INSERT',
                                restrictions: ['AUTOAPPROVE'],
                            };
                            const transferRestrictionPromise = entitlements
                                .getActionRestrictions(opt);
                            transferRestrictionPromise.then((restrictResult) => {
                                if (restrictResult.restrictions[0].value1 === 'true') {
                                    this.unapprovingSchedule = false;
                                }
                                this.render();
                            });
                        } else {
                            this.render();
                        }
                    });
                },
            });
        } else {
            // New transfer, no data to load but need to satisfy the formalities.
            transferEntitlementsPromise.then((result) => {
                this.hasTemplateInsertEntitlement = result.actions.INSERT;
                this.setHasLoadedRequiredData(true);
                this.render();
            });
        }
    },

    /**
     * @method getHelpPageRequest
     * @description creates the request object for the getHelpPage service request.
     * The request needs to include:
     * productCode, functionCode, typeCode and mode.
     *
     * @returns {{productCode: string, functionCode: string, typeCode: string,
     * mode: string}}
     */
    getHelpPageRequest(options) {
        const request = {
            productCode: 'RTGS',
        };

        if (options.copyFromTemplate) {
            request.functionCode = 'INST';
            request.typeCode = '*';
            request.mode = 'INSERT';
        } else if (!options.templateMode) {
            request.functionCode = 'INST';
            request.mode = '*';
            if (options.type === 'RECUR') {
                request.typeCode = 'RECUR';
                if (!options.readOnly && util.isEmpty(options.isModify)) {
                    request.mode = 'INSERT';
                }
            } else if (options.readOnly || options.mode === 'modify') {
                // view mode
                request.typeCode = 'TRANSFER';
            } else {
                request.typeCode = options.type;
            }
        } else {
            request.functionCode = 'TMPL';
            request.typeCode = 'TRANSFER';
            if (options.readOnly || options.mode === 'modify') {
                // view mode
                request.mode = '*';
            } else {
                request.mode = 'INSERT';
            }
        }
        return request;
    },

    onRender() {
        let singleModel;
        if (this.hasLoadedRequiredData()) {
            this.listenTo(this.tempModel, 'modelAction:approveWithWarning', this.approveWithWarning);
            this.listenTo(this.tempModel, 'modelAction:modifyWithWarning', this.modifyWithWarning);
            this.listenTo(this.tempModel, 'modelAction:deleteWithWarning', this.deleteWithWarning);
            this.listenTo(this.tempModel, 'modelAction:errorForDetail', this.cancelWarning);
            // setup for audit history
            this.showAuditSection();
            if (this.transitionTrackingAuditGrid && systemConfig.isAdmin()) {
                this.transitionAuditRegion.show(this.transitionTrackingAuditGrid);
            }

            // setup global date picker
            const transferType = this.options.type ? this.options.type : this.model.get('setEntryMode');
            if (transferType === 'ONE2ONE'
                && !this.options.readOnly
                && !this.hideDate
                && this.model.get('functionCode') !== 'TMPL') {
                this.showDatePicker();
            }
        }

        if (this.showMaxAmount) {
            this.ui.maxAmtField.inputmask('decimal', util.extend(
                Formatter.getCurrencyMaskOptions(true, true),
                {
                    placeholder: '0.00',
                    digitsOptional: false,
                },
            ));
        }
        if (this.options.templateId) {
            if (this.options.type !== 'SINGLE') {
                if (!this.hasLoadedRequiredData()) {
                    this.loadRequiredData();
                    return;
                }

                if (this.model.has('singleTransfer')) {
                    singleModel = this.model.get('singleTransfer');
                    this.model.unset('singleTransfer');
                }

                this.collection = new Collection(this.model.get('templates'));
                this.model.unset('templates');
                this.initForLoad();
            } else if (!this.hasLoadedRequiredData()) {
                this.loadRequiredData();
                return;
            }
        } else if (this.options.transferModel) {
            if (!this.hasLoadedRequiredData()) {
                this.loadRequiredData();
                return;
            }

            this.initForLoad();
        } else if (this.options.recurId) {
            if (!this.hasLoadedRequiredData()) {
                this.unapprovingSchedule = false;
                this.loadRequiredData();
                return;
            } if (this.unapprovingSchedule === true && this.options.isRecurring === '1') {
                this.ui.schedulePayWarning.text(locale.get('ACH.SchedulePayChangeWarning'));
            }
        } else if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
            return;
        }

        this.model.set('setEntryMode', this.type);

        this.handleTypes(singleModel);

        if (this.recurView) {
            if (this.options.recurId) {
                this.initForLoad();
                this.showAuditSection();
            }
            this.recurRegion.show(this.recurView);
            this.ui.transferDate.text(Formatter.formatDate(new Date()));
            this.listenTo(this.recurView.model, 'change', util.bind(this.updateFooter, this));
        } else {
            if (this.singleView) {
                this.singleRegion.show(this.singleView);
            }

            this.collection = this.collection || new Collection([new TransferModel()]);
            this.initForLoad();
            this.entryView = new CollectionView({
                collection: this.collection,
                itemView: TransferView,

                itemViewOptions: {
                    hideDate: this.templateMode,
                    templateMode: this.templateMode,
                    parentLayout: this,
                    isSingle: false,
                    type: this.type,
                    showOptionalFields: this.optShown,
                    copyFromTemplate: this.copyFromTemplate,
                    modifyPaymentFromTemplate: this.modifyPaymentFromTemplate,
                    copyTemplateFromTemplate: this.copyTemplateFromTemplate,
                    readOnly: this.options.readOnly,
                    mode: this.options.mode,
                    fxRateType: this.fxRateType,
                    hasTemplateInsertEntitlement: this.hasTemplateInsertEntitlement,
                    isAmountLockable: (this.fieldData.ENTERED_AMOUNT.lockable
                        && this.templateMode) || false,
                    isAmountLockedByDefault: (this.fieldData.ENTERED_AMOUNT.lockedByDefault
                        && this.templateMode) || false,
                    isAmountLocked: (this.fieldData.ENTERED_AMOUNT.locked
                        && this.templateMode) || false,
                    transferModel: this.options.transferModel,
                },
            });

            this.listenTo(this.collection, 'change add remove', util.bind(this.updateFooter, this));
            this.listenTo(this.collection, 'change add remove', util.bind(this.updateHeader, this));

            this.entryRegion.show(this.entryView);
        }
        this.stopListening(this.collection, 'change add remove', this.handleChange);
        this.listenTo(this.collection, 'change add remove', this.handleChange);

        this.ui.addAmtInput.inputmask(
            'integer',
            {
                allowMinus: false,
                max: 20,
            },
        );

        if (!this.hideTime && !this.options.readOnly) {
            const timeMask = {
                alias: 'datetime',
                mask: 'h:s t\\m',
                insertMode: false,
                placeholder: '00:00 am',
                hourFormat: '12',
            };

            this.ui.startTime.inputmask(timeMask);
        }

        this.ui.templateForm.hide();

        if (this.options.templateId) {
            const rec = this.collection.at(0);
            const acctField = (this.type === 'MANY2ONE') ? rec.get('BENE_ACCOUNTENTITLEMENT') : rec.get('ACCOUNTFILTER');
            this.handleAccountSelection(acctField, (util.isUndefined(rec.get('CURRENCYCODE')) ? this.currency : rec.get('CURRENCYCODE')));
        }
        // show cutoff if it exists and is configured to do so
        PaymentUtil.showCutoff(this.collection.models[0].get('CUTOFF_INFO'), this.$('.ui-datepicker-trigger'), 'TRANSFER');

        if (this.templateMode && this.formState !== 'view' && this.showMaxAmount) {
            if (!this.formState) {
                this.collection.at(0).set({
                    TEMPLATE_MAX_AMOUNT: this.model.get('TEMPLATE_MAX_AMOUNT'),
                    TEMPLATE_MAX_CURRENCY: this.model.get('TEMPLATE_MAX_CURRENCY') || this.baseCurrency,
                });
            }
        }
        if (this.templateMode && this.formState !== 'view' && this.showSignatureDropDown) {
            const preSelected = [];
            const selectedVal = (this.formState || this.copyTemplateFromTemplate) ? this.model.get('MAX_SIGNATURES_OVERRIDE') : this.defaultSignature;

            preSelected.push({
                id: selectedVal,
                name: selectedVal,
            });

            if (!this.formState) {
                this.collection.at(0).set('MAX_SIGNATURES_OVERRIDE', selectedVal);
            }

            // create the signatures dropdown and apply a listener
            this.ui.signaturesDropdown.flexDropdown({
                data: this.signaturesData,
                disableMultiButton: true,
                showTooltip: false,
                preSelected,
            });

            this.listenTo(this.ui.signaturesDropdown.data('flexDropdown'), 'selectionChanged', this.signatureChanged);

            this.ui.$popovers.popover({
                trigger: 'focus hover',
                html: true,
            });
        }
        // Only show the Panel Workflow Assignment view when panel approval has been enabled
        if (panelWorkflowAssignmentUtil.panelApprovalEnabled && this.formState !== 'view') {
            this.panelWorkflowAssignmentRegion.show(new PanelWorkflowAssignment({
                model: this.model,
                collection: this.panelWorkflowCodes,
            }));
        }
    },

    /**
     * @method updateTransferDate
     * function to update transfer date fields and trigger dialog
     * if date changes a second time
     */
    updateTransferDate() {
        const prevDate = this.model.previous('transDate');
        const selectedDate = this.ui.datepicker.val();

        if (!util.isUndefined(prevDate) && prevDate !== selectedDate) {
            dialog.confirm(
                locale.get('common.transDate.update.dialog.body'),
                locale.get('common.transDate.update.dialog.header'), (ok) => {
                    if (ok) {
                        this.model.set('transDate', selectedDate);
                        this.updateDateFields();
                    } else {
                        // reverts to previous date in temp attribute and datepicker
                        this.model.set('transDate', prevDate);
                        this.ui.datepicker.val(moment(prevDate, userInfo.getDateFormat()));
                    }
                },
            );
        } else {
            this.model.set('transDate', selectedDate);
            this.hasDefaultDate = !!(selectedDate);
            this.updateDateFields();
        }
    },

    handleChange() {
        if (this.collection.length < 1) {
            this.addTransfers();
        }
        this.updateFooter();
        this.toggleClearRemove();
    },

    /**
     * @method updateDateFields
     * function to update all transfer date fields.
     */
    updateDateFields() {
        const refValue = this.model.get('transDate');

        this.collection.each((item) => {
            const datePicker = this.$(`input[id="transferDate-${item.cid}"]`);
            datePicker.val(refValue);
            datePicker.data('daterangepicker').setStartDate(refValue);
            item.set('VALUE_DATE', refValue);
            item.set('TRAN_DATE', refValue);
        });
    },

    /**
     * @method showDatePicker
     * function to render the global transfer datepicker.
     */
    showDatePicker() {
        if (this.ui.datepicker.length > 0) {
            this.ui.datepicker.nhDatePicker({
                blockedDates: this.processingDates.blockedDates,
                processingDays: this.processingDates.processingDays,
                cutOffTimes: this.processingDates.cutOffTimes,
                showCalendarIcon: true,
                showDropdowns: true,
                daysForward: this.processingDates.daysForward[0],
                daysBack: new Date(),

                // needed for one2one due to multiple datepickers on the same page
                useThis: this.options.type === 'ONE2ONE',
            });
        }
    },

    /*
     * @method selectedTransitionChange
     * @param selected - the selected flex dropdown item
     */
    signatureChanged(selected) {
        const model = this.collection.at(0);
        model.set('MAX_SIGNATURES_OVERRIDE', selected[0].id);
    },

    maxAmountChanged() {
        const newMaxAmtValue = this.ui.maxAmtField.val();
        this.collection.models.forEach((model) => {
            model.set('TEMPLATE_MAX_AMOUNT', newMaxAmtValue);
        });
    },
    /**
     * @param {Collection} CollectionType
     * @param {object}  options
     * @return {Promise}
     * Takes in options and returns a promise to fetch signatures
     */
    fetchSignatures(CollectionType, options) {
        return new Promise((resolve, reject) => {
            new CollectionType(options).fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    /**
     * @param {Collection} data
     * @return {array}
     * Map collection models into a format that can be used to populate
     * flex dropdowns
     */
    mapDropdownData(data) {
        return util.map(data.models, rowItem => ({
            id: rowItem.get('CODE'),
            name: rowItem.get('DESCRIPTION'),
        }));
    },

    /**
     * @param {Array} columns
     * @return Object obj
     * Map Template company settings to model values
     */
    mapTemplateCompanyPreferences(columns) {
        const obj = {};
        util.each(columns, (column) => {
            switch (column.fieldName) {
            case 'TRANSFERALLOWTMPLSIGNOVERRIDE':
                obj.showSignatureDropDown = column.fieldValue !== '1' && column.fieldValue !== '';
                break;
            case 'TRANMAXAPPROVALSIG':
                obj.defaultSignature = column.fieldValue;
                break;
            case 'ALLOWMAXAMTPAYMENTFROMTMPL':
                obj.allowMaxAmtPmtFromTmpl = column.fieldValue === '1';
                break;
            case 'BASECURRENCY':
                obj.baseCurrency = column.fieldValue;
                break;
            default:
            }
        });
        return obj;
    },

    /**
     * @return {Promise}
     * Takes in options and returns a promise to fetch signatures
     */

    getTransferCompanyPreferences() {
        const url = services.generateUrl('/inquiry/getData');
        const postData = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    searchType: '5',
                    inquiryId: 23001,
                },
            },
        };

        return new Promise((resolve, reject) => {
            http.post(
                url, postData, (response) => {
                    resolve(response.inquiryResponse.rows[0].columns);
                },
                (e) => {
                    reject(e);
                },
            );
        });
    },

    showAuditSection() {
        let viewModel;
        let collectionModel;

        if (this.options.readOnly || this.options.mode === 'modify') {
            viewModel = util.clone(this.savedModel) || util.clone(this.model);

            if (this.collection && viewModel) {
                collectionModel = util.omit(
                    util.clone(this.collection.at(0)).attributes,
                    value => typeof value === 'string' && value.trim().length === 0,
                );
                viewModel.set(collectionModel);
            }

            // setup for audit history
            AuditHistorySetup.setup(this, viewModel);
        }
    },

    handleBusinessDaysResponse(result) {
        const date = moment(result.earliestDay);
        const { maxAdvancedDays } = result;
        const { model, processingDates } = this;
        const earliestDate = moment(result.earliestDay).format(userInfo.getDateFormat());

        processingDates.daysForward.shift();
        processingDates.daysForward.push(result.maxForwardDays);

        processingDates.processingDays.shift();
        processingDates.processingDays.push(result.businessDays);

        processingDates.cutOffTimes.shift();
        processingDates.cutOffTimes.push(result.cutoff);

        processingDates.blockedDates.splice(0, processingDates.blockedDates.length);
        if (result.holidays.length > 0) {
            processingDates.blockedDates.push(...result.holidays);
        }

        this.ui.datepicker.val(earliestDate);

        const dateRangePicker = this.ui.datepicker.data('daterangepicker');

        if (dateRangePicker) {
            dateRangePicker.updateCalendars({
                blockedDates: this.processingDates.blockedDates,
                processingDays: this.processingDates.processingDays,
                cutOffTimes: this.processingDates.cutOffTimes,
                showCalendarIcon: true,
                showDropdowns: true,
                daysForward: this.processingDates.daysForward[0],
                minDate: date,
            });
        }

        if (model.get('functionCode') === 'INTL') {
            model.set('maxAdvancedDays', maxAdvancedDays);
        }
    },

    /**
     * @method updateTransferTime
     * function to update transfer time fields and trigger dialog
     * if time changes a second time
     */
    updateTransferTime() {
        const prevTime = this.model.previous('START_TIME');
        const getDefaultTime = this.model.get('START_TIME');

        if (prevTime !== getDefaultTime && !util.isUndefined(getDefaultTime)) {
            dialog.confirm(
                locale.get('common.transTime.update.dialog.body'),
                locale.get('common.transTime.update.dialog.header'), (ok) => {
                    if (ok) {
                        this.updateTimeFields();
                    } else {
                        this.model.set('START_TIME', prevTime);
                    }
                },
            );
        }
    },

    /**
     * @method updateTimeFields
     * function to update all transfer time fields.
     */
    updateTimeFields() {
        const refValue = this.ui.timepicker.val();

        this.collection.each((item) => {
            item.set('TRANSFER_TIME', refValue);
        });
    },

    /**
     * @method updateTransferRef
     * function to update transfer reference text fields and trigger dialog
     * if existing values with be overwritten.
     */
    updateTransferRef() {
        if (this.collection.at(0).has('CUSTOMER_REFERENCE')) {
            dialog.confirm(
                locale.get('common.transfers.update.dialog.body'),
                locale.get('common.transfers.update.dialog.header'), (ok) => {
                    if (ok) {
                        this.updateTransferFields();
                    } else {
                        this.ui.transferReference.val('');
                    }
                },
            );
        } else {
            this.updateTransferFields();
        }
    },

    /**
     * @method updateTransferFields
     * function to update transfer reference text fields.
     */
    updateTransferFields() {
        const refValue = this.ui.transferReference.val();

        this.collection.each((item) => {
            item.set('CUSTOMER_REFERENCE', refValue);
        });
        // In case any entry is not showing optional fields.
        this.collection.trigger('toggle-opt-fields', true);

        // Update the optional field toggle to ensure references are visible.
        this.ui.optFieldSwitch.prop('checked', true);
        this.optShown = true;
    },

    /**
     * @method clearAllTransfers
     * function to clear all transfer reference text fields.
     */
    clearAllTransfers() {
        this.ui.transferReference.val('');
        this.collection.each((item) => {
            item.unset('CUSTOMER_REFERENCE');
        });
    },

    /**
     * @method clearAllTransfers
     * function to trigger dialog to confirm action
     */
    clearAllDialog() {
        dialog.confirm(
            locale.get('common.transfers.clear.dialog.body'),
            locale.get('common.transfers.clear.dialog.header'), (ok) => {
                if (ok) {
                    this.clearAllTransfers();
                }
            },
        );
    },

    initForLoad() {
        const model = this.collection.at(0);

        this.setCurrency(model.get('DEBIT_CURRENCY'));
        this.setSetDescription(model.get('SETDESCRIPTION'));
        this.setSetName(model.get('SETNAME'));
        this.setTransferSetUpdateCount(model.get('PARENTUPDATECOUNT__'));
        this.setsingleRecurCheck(model.get('PATTERNMODE'));
        if (this.templateMode) {
            this.model.set({
                MAX_SIGNATURES_OVERRIDE: model.get('MAX_SIGNATURES_OVERRIDE'),
                TEMPLATE_MAX_CURRENCY: model.get('TEMPLATE_MAX_CURRENCY') || this.baseCurrency,
                TEMPLATE_MAX_AMOUNT: model.get('TEMPLATE_MAX_AMOUNT'),
                TEMPLATE_MAX_AMOUNT_FORMATTED: `${Formatter.formatCurrency(model.get('TEMPLATE_MAX_AMOUNT'))} ${model.get('TEMPLATE_MAX_CURRENCY')}`,
            });
        }
        if (systemConfig.isAdmin()) {
            this.model.set({
                USERGROUP: model.get('USERGROUP'),
                COMPANYNAME: model.get('COMPANYNAME'),
            });
        }
        this.setRestrictTemplateCheck(model.get('RESTRICTTEMPLATE_FLAG'));
        this.updateFooter();
        this.updateHeader();
    },

    /**
     * configure the view according to the transfer type
     */
    handleTypes(singleModel) {
        let headline = '';

        switch (this.type) {
        case 'ONE2ONE':

            headline = locale.getDefault('RTGS.TRANSFER.ONE2ONE.TRANSFER', 'Multi Transfer');
            this.singleLabel = '';
            this.multiLabel = '';

            break;

        case 'SINGLE':

            headline = locale.getDefault('RTGS.TRANSFER.SINGLE.TRANSFER', 'Single Transfer');
            this.singleLabel = '';
            this.multiLabel = '';

            /*
             *    if(this.options.recurId)
             *      {
             *        this.recurView = new TransferView({
             *          model: this.collection && this.collection.at(0),
             *          readOnly: this.options.readOnly,
             *          type: this.type,
             *          isModify: this.options.recurId,
             *          hideDate: true,
             *          hideClose: true,
             *          parentLayout: this
             *      });
             *      }
             */

            break;

        case 'ONE2MANY':

            headline = locale.getDefault('RTGS.TRANSFER.ONE2MANY.TRANSFER', 'One-to-Many Transfer');
            this.singleLabel = locale.getDefault('RTGS.TRANSFER.DEBIT_ACCOUNT', 'From Account');
            this.multiLabel = locale.getDefault('RTGS.TRANSFER.BENE_ACCOUNTS', 'To Accounts');

            this.singleView = new TransferView({
                model: singleModel,
                hideDate: this.templateMode,
                parentLayout: this,
                isSingle: true,
                type: this.type,
                copyFromTemplate: this.copyFromTemplate,
                modifyPaymentFromTemplate: this.modifyPaymentFromTemplate,
                readOnly: this.options.readOnly,
                fxRateType: this.fxRateType,
                templateMode: this.templateMode,
                mode: this.mode,
            });

            break;

        case 'MANY2ONE':

            headline = locale.getDefault('RTGS.TRANSFER.MANY2ONE.TRANSFER', 'Many-to-One Transfer');
            this.singleLabel = locale.getDefault('RTGS.TRANSFER.BENE_ACCOUNT', 'To Account');
            this.multiLabel = locale.getDefault('RTGS.TRANSFER.DEBIT_ACCOUNTS', 'From Accounts');

            this.singleView = new TransferView({
                model: singleModel,
                hideDate: this.templateMode,
                parentLayout: this,
                isSingle: true,
                type: this.type,
                copyFromTemplate: this.copyFromTemplate,
                modifyPaymentFromTemplate: this.modifyPaymentFromTemplate,
                readOnly: this.options.readOnly,
                fxRateType: this.fxRateType,
                templateMode: this.templateMode,
                mode: this.mode,
            });

            break;

        case 'RECUR':

            headline = locale.getDefault('RTGS.TRANSFER.RECUR.TRANSFER', 'Recurring Transfer');
            this.recurView = new TransferView({
                model: this.collection && this.collection.at(0),
                readOnly: this.options.readOnly,
                type: this.type,
                isModify: this.options.recurId,
                hideDate: true,
                hideClose: true,
                parentLayout: this,
                fxRateType: this.fxRateType,
            });

            break;
        default:
        }

        if (this.templateMode) {
            headline = `${headline} ${locale.getDefault('PAYMENTS.TEMPLATE', 'Template')}`;
        } else if (this.options.templateId) {
            headline = `${headline} ${locale.getDefault('PAYMENTS.FROM_TEMPLATE', 'From Template')}`;
        }

        this.ui.headline.text(headline);
    },

    addTransfers() {
        const max = parseInt(this.ui.addAmtInput.val() || 1, 10);
        this.createTransferModels(max);

        /*
         * Must retrigger the enable after adding new records, if account filter/currency are
         * already set.
         */
        if (this.accountFilter) {
            this.collection.trigger('account-selected', this.accountFilter, this.currency);
        }

        // Must also retrigger opt fields shown, if they are being shown currently
        if (this.optShown) {
            this.collection.trigger('toggle-opt-fields', true);
        }

        if (this.collection.at(0).get('LOCKED_FIELDS')
             && this.collection.at(0).get('LOCKED_FIELDS').indexOf('ENTERED_AMOUNT') !== -1) {
            this.collection.trigger('lock-fields');
        }
        this.toggleClearRemove();
    },

    toggleClearRemove() {
        this.collection.trigger('toggle-clear-remove', this.collection.length === 1);
    },

    createTransferModels(max) {
        for (let i = 0; i < max; i += 1) {
            const transModel = new TransferModel({
                CUSTOMER_REFERENCE: this.ui.transferReference.val(),
            });
            if (this.model.has('START_TIME')) {
                transModel.set('TRANSFER_TIME', this.model.get('START_TIME'));
            }
            if (this.collection.at(0) && this.collection.at(0).has('LOCKED_FIELDS')) {
                transModel.set('LOCKED_FIELDS', this.collection.at(0).get('LOCKED_FIELDS'));
            }

            const selectedDate = this.ui.datepicker.val();
            if (selectedDate) {
                transModel.set('TRAN_DATE', selectedDate);
            }

            this.collection.add(transModel);
        }
    },

    toggleTemplateForm(e) {
        if (this.$(e.target).is(':checked')) {
            this.ui.templateForm.show();
            this.setValidator('setName', this.fieldData.TEMPLATE_CODE.fieldType, this.fieldData.TEMPLATE_CODE.fieldLabel, this.model);
            this.setValidator('setDescription', this.fieldData.TEMPLATE_DESCRIPTION.fieldType, this.fieldData.TEMPLATE_DESCRIPTION.fieldLabel, this.model);
        } else {
            this.ui.templateForm.hide();
            this.removeValidator('setName', this.model);
            this.removeValidator('setDescription', this.model);
        }
    },

    updateHeader() {
        if (this.type === 'SINGLE' && this.collection.models.length === 1 && this.currency) {
            const [item] = this.collection.models;
            const isCreditAmount = item.get('ENTERED_AMOUNT_FLAG') === 'C';

            const footerAmount = item.get('ENTERED_AMOUNT')
                || item.get(isCreditAmount ? 'CREDIT_AMOUNT' : 'DEBIT_AMOUNT');
            const footerCurrency = item.get('CURRENCYCODE')
                || item.get(isCreditAmount ? 'CREDIT_CURRENCY' : 'DEBIT_CURRENCY')
                || this.currency;

            this.ui.headerSummaryCurrency.text(footerCurrency);

            if (!util.isEmpty(footerAmount) || !util.isEmpty(footerCurrency)) {
                this.ui.headerSummaryAmount.text(Formatter
                    .formatNumberByCurrency(number().unformat(footerAmount), footerCurrency));
            }
        }
    },

    updateFooter() {
        const self = this;
        let html;
        const paymentOnText = this.singleRecurringSeries ? locale.get('PAY.STARTINGON') : locale.get('PAY.on');

        if (this.type !== 'RECUR' && this.collection.models.length) {
            /*
             * If no currency is set and a toAccount has been selected, set the currency
             * to the currency code of the selected toAccount
             */
            const myAccount = this.collection
                .models[this.collection.models.length - 1].toAccount;
            if (!this.currency && myAccount) {
                this.setCurrency(myAccount.get('CURRENCYCODE'));
            }
        }

        if (this.currency) {
            if (this.type === 'RECUR') {
                const transferAmountView = Formatter.formatNumberByCurrency(this.recurView.model.get('DEBIT_AMOUNT'), this.currency);
                this.ui.transferAmount.text(`${transferAmountView} ${this.currency}`);
                this.ui.transferDate.text(Formatter
                    .formatDate(this.recurStartDate || new Date()));
                const recurringAmt = this.model.get('DEBIT_AMOUNT');
                if (recurringAmt) {
                    this.ui.transferAmount.text(Formatter
                        .formatNumberByCurrency(0, this.currency));
                    this.ui.currencyDisplay.text(this.currency);
                }
            } else {
                // totals is a map of <currencycode, { amount, count }>
                const totals = {};

                html = '';

                this.collection.each((item) => {
                    const isCreditAmount = (item.get('ENTERED_AMOUNT_FLAG') === 'C');
                    const footerCurrency = item.get(isCreditAmount ? 'CREDIT_CURRENCY' : 'DEBIT_CURRENCY');

                    const curr = item.get('CURRENCYCODE') || footerCurrency || self.currency;
                    let singleTranferDate = item.get('VALUE_DATE') || item.get('STARTINGEFFECTIVEDATE');
                    if (singleTranferDate instanceof moment) {
                        singleTranferDate = Formatter.formatDate(singleTranferDate);
                    }

                    if (totals[curr]) {
                        totals[curr].count += 1;
                        totals[curr].amount += number().unformat(item.get(isCreditAmount ? 'CREDIT_AMOUNT' : 'DEBIT_AMOUNT'));
                        totals[curr].transferDate = singleTranferDate;
                    } else {
                        totals[curr] = {
                            count: 1,
                            amount: number().unformat(item.get(isCreditAmount ? 'CREDIT_AMOUNT' : 'DEBIT_AMOUNT')),
                            transferDate: singleTranferDate,
                        };
                    }
                });

                util.each(totals, (totalItem, curr) => {
                    const summaryAmountView = Formatter
                        .formatNumberByCurrency(totalItem.amount, curr);
                    if (self.type === 'SINGLE' || this.isModifyAndSingleItem()) {
                        html += `<div>${locale.get('PAY.Transfer')} <span class="summary-large-text">${summaryAmountView}</span> ${curr}`;

                        if (totalItem.transferDate) {
                            html += ` ${paymentOnText} ${totalItem.transferDate}`;
                        }

                        html += '</div>';
                    } else {
                        html += `<div>(${totalItem.count}) ${locale.get('PAY.Transfers')} <span class="summary-large-text">${summaryAmountView}</span> ${curr}</div>`;
                    }
                });

                this.ui.totalDisplay.html(html);
            }
        } else if (this.type === 'SINGLE') {
            html = `<div>${locale.get('PAY.Transfer')} <span class="summary-large-text">0.00</span>  ${paymentOnText} </div>`;
            this.ui.totalDisplay.html(html);
        } else if (this.type !== 'RECUR') {
            this.ui.totalDisplay.text(`(${this.collection.length}) ${locale.get('PAY.Transfers')}`);
        }
    },

    toggleOptFields(e) {
        const flag = this.$(e.target).is(':checked');

        this.optShown = flag;

        if (this.collection) {
            this.collection.trigger('toggle-opt-fields', flag);
        } else {
            this.recurRegion.currentView.toggleOpt(flag);
        }
    },

    handleAccountSelection(accountFilter, currency) {
        this.collection.trigger('account-selected', accountFilter, currency);
        this.accountFilter = accountFilter;
        this.setCurrency(currency);
    },

    setCurrency(currency) {
        this.currency = currency;
    },

    saveTransfersForLater(evt) {
        this.saveTransfers(evt, true);
    },

    toggleButtonEnabled($button, enabled) {
        $button.prop('disabled', !enabled);
    },

    saveTransfers(evt, saveLater, options) {
        const self = this;
        let isFetchingIndicativeRate = false;

        // Make sure we have something to save!
        if ((this.collection && this.collection.length) || this.type === 'RECUR') {
            const $button = this.$('[data-hook="submit-button"]');
            let valid = true;

            this.model.unset('previousReference');
            this.model.unset('transDate');
            this.model.unset('START_TIME');
            this.model.unset('isTOA');

            // NH-37584 - disable button during action to prevent multiple clicks.
            this.toggleButtonEnabled($button, false);

            if (this.type === 'RECUR') {
                this.recurView.model.set('CREDIT_AMOUNT', this.recurView.model.get('ENTERED_AMOUNT'));

                const recurModel = this.recurView.model;

                recurModel.scheduleModel = this.recurView.scheduler.model;

                if (recurModel.isValid()) {
                    valid = recurModel.save(
                        null,
                        {
                            isModify: this.options.recurId,
                            isRestore: this.options.overloadedMethodName === 'restore',
                            type: this.type,

                            success(model, response) {
                                store.set(
                                    'listRecurringMessage',
                                    {
                                        action: 'save',
                                        response,
                                    },
                                );

                                workspaceHelper.returnToCurrentWorkspace(self);
                            },

                            error(model, response) {
                                const message = JSON.parse(response.message);
                                alertMessage.renderMessage(self, 'save', message, null, message.confirms);
                                // NH-37584 - enable button if there's an error.
                                self.toggleButtonEnabled($button, true);
                                scroll.scrollToFirstError();
                            },
                        },
                    );
                } else {
                    this.toggleButtonEnabled($button, true);
                    scroll.scrollToFirstError();
                }
            } else {
                /*
                 * Make sure none of the models are in the process
                 * of fetching an indicative rate
                 */
                this.collection.each((item) => {
                    if (item.get('FETCHING_INDICATIVE_RATE')) {
                        setTimeout(self.saveTransfers.bind(self, evt, saveLater, options), 50);
                        isFetchingIndicativeRate = true;
                    }
                });
                if (isFetchingIndicativeRate) {
                    return false;
                }

                if (this.singleView) {
                    this.model.set('singleItem', this.singleView.model);
                    valid = this.singleView.model.isValid();
                } else {
                    const schedule = this.collection.at(0).singleScheduleModel;
                    if (this.type === 'SINGLE' && schedule) {
                        const submit = this.translateSchedule(
                            this.collection.at(0),
                            schedule.model,
                        );
                        if (!submit) {
                            this.toggleButtonEnabled($button, true);
                            return false;
                        }
                        if (this.copyFromTemplate && this.model.get('functionCode') === 'INST') {
                            /*
                             * if this is copy from template and INST, then it is a
                             * payment and we dont want to save as template
                             * template already exists..
                             */
                            this.model.set({
                                saveAsTemplate: '0',
                                copyFromTemplate: this.copyFromTemplate,
                            });
                        } else if (!this.templateMode) {
                            this.model.set('saveAsTemplate', '1');
                            /*
                             * NH-104845 :When the user sets up a recurring transfer through
                             * the Single Transfer view, set the Start Date on the recurrence
                             * This will allow the system to continue to work as it does today
                             * pattern to Transfer Date+1. but will never create a transfer
                             * from the recurrence pattern on the same day as that first
                             * Freeform transfer.
                             */
                            const startDate = Formatter.formatDate(moment(this.collection.at(0).get('VALUE_DATE'), userInfo.getDateFormat())
                                .add(1, 'days'));
                            this.collection.at(0).set('STARTINGEFFECTIVEDATE', startDate);
                        }
                    } else if (this.type === 'SINGLE' && !schedule) {
                        this.collection.at(0).set('PATTERNMODE', 'O');
                        this.collection.at(0).set('ENDMODE', '');
                        this.collection.at(0).set('ENDCYCLES', '');
                        this.collection.at(0).set('ENDINGEFFECTIVEDATE', '');
                    }
                }

                this.model.set('items', this.collection);

                let maxSignatures = '';
                let maxAmount = '';
                let maxAmtCurr = '';

                this.collection.each((item) => {
                    const currencyDifference = item.has('DEBIT_CURRENCY') && item.has('CREDIT_CURRENCY') && item.get('CREDIT_CURRENCY') !== item.get('DEBIT_CURRENCY');
                    const accountCurrencyDifference = item.toAccount && item.fromAccount && (item.toAccount.get('CURRENCYCODE') !== item.fromAccount.get('CURRENCYCODE'));

                    if (maxSignatures === '') {
                        maxSignatures = item.get('MAX_SIGNATURES_OVERRIDE');
                    }

                    if (maxAmount === '') {
                        maxAmount = item.get('TEMPLATE_MAX_AMOUNT');
                    }
                    if (maxAmtCurr === '') {
                        maxAmtCurr = item.get('TEMPLATE_MAX_CURRENCY');
                    }

                    item.set({
                        MAX_SIGNATURES_OVERRIDE: maxSignatures,
                        TEMPLATE_MAX_AMOUNT: maxAmount,
                        TEMPLATE_MAX_CURRENCY: maxAmtCurr,
                    });

                    // If this is cross currency ...
                    if (currencyDifference || accountCurrencyDifference) {
                        /*
                         * if company setup for realtime rates, for cross currency payments
                         * the alternate amount should not be set until set by get rate
                         * with contra amount
                         */
                        if (!this.fxRateType || this.hasContractIdAndExchangeRate(item)) {
                            /*
                             * Save user entered exchange rate if it exists, and save
                             * an exchange rate gotten from the server if it exists
                             * as long as this is not an Indicative Rate entry
                             */
                            if (!item.get('EXCHANGE_RATE') && item.get('EXCHANGE_RATE_TABLE')) {
                                item.set(
                                    'EXCHANGE_RATE',
                                    item.get('EXCHANGE_RATE_TABLE'),
                                    {
                                        silent: true,
                                    },
                                );
                            }
                        } else if (item.get('ENTERED_AMOUNT_FLAG') === 'D') {
                            /*
                             * if company setup for realtime rates, for cross currency payments
                             * the alternate amount should not be set until set by get rate
                             * with contra amount
                             */
                            item.set('CREDIT_AMOUNT', ' ');
                        } else {
                            item.set('DEBIT_AMOUNT', ' ');
                        }
                    }

                    if (self.type !== 'SINGLE' && (!this.fxRateType || this.hasContractIdAndExchangeRate(item))) {
                        item.set({
                            DEBIT_AMOUNT: number().unformat(item.get('DEBIT_AMOUNT')),
                            CREDIT_AMOUNT: number().unformat(item.get('CREDIT_AMOUNT')),
                        });
                    }
                });

                this.collection.each((rec) => {
                    if (!rec.isValid()) {
                        valid = false;
                    }
                });

                this.model.unset('MAX_SIGNATURES_OVERRIDE');

                if (!this.model.isValid()) {
                    valid = false;
                }

                if (valid) {
                    this.model.unset('TEMPLATE_MAX_AMOUNT');
                    this.model.unset('TEMPLATE_MAX_CURRENCY');
                    this.model.unset('TEMPLATE_MAX_AMOUNT_FORMATTED');
                }

                if (this.validateFromToAccounts() && valid) {
                    this.collection.each((item) => {
                        item.set('_saveIncomplete', Boolean(saveLater));
                        item.set('_saveWithWarning', self.model.get('saveWithWarning'));
                        item.set('RESTRICTTEMPLATE_FLAG', self.ui.restrictTemplate.prop('checked') ? '1' : '0');

                        // this should only be done for template... not when creating payment
                        if (self.templateMode && (self.type === 'ONE2ONE' || self.type === 'SINGLE')) {
                            item.unset('EXCHANGE_RATE');
                            item.unset('EXCHANGE_RATE_CONTRACTID');
                        }
                    });

                    if (options && options.warningName
                        && options.warningName === constants.DUPLICATE_ACCEPTED_INDICATOR) {
                        this.model.attributes.items.models.forEach((model) => {
                            model.set(constants.DUPLICATE_ACCEPTED_INDICATOR, true);
                        });
                    }

                    this.model.save(
                        null,
                        {
                            isModify: (this.templateMode
                                && (this.options.templateId || this.options.recurId)
                                && this.copyTemplateFromTemplate !== true)
                                || this.options.transferModel,
                            isRestore: this.options.overloadedMethodName === 'restore',
                            isRepair: this.mode === 'repair',
                            type: this.type,

                            success(model, response) {
                                store.set(
                                    self.templateMode ? 'listTransferTemplateMessage' : 'listTransferMessage',
                                    {
                                        action: 'save',
                                        response,
                                    },
                                );
                                workspaceHelper.returnToCurrentWorkspace(self);
                            },

                            error(model, response) {
                                // handle warnings for modify action
                                const res = JSON.parse(response.message);

                                model.set('TEMPLATE_MAX_AMOUNT', model.get('items').models[0].get('TEMPLATE_MAX_AMOUNT'));
                                if (res.errorCode === 540) {
                                    self.updateCollectionFromFailedResults(res);

                                    /*
                                     * Get all responses that are duplicates so they can be
                                     * sent to the duplicate warning dialog
                                     */
                                    res.confirms.confirmResults = res.confirms.confirmResults.filter(confirmResults => confirmResults.resultType === 'DUPLICATEWARNING');

                                    const duplicateDialog = new DuplicateDialog({
                                        resp: res,
                                        modelsArray: self.model,
                                        isTransfer: true,
                                        methodName: 'INSERT',
                                    });
                                    duplicateDialog.once('saveTransfers', self.saveTransfers, self);
                                    duplicateDialog.once('cancelWarning', self.cancelWarning, self);
                                    dialog.custom(duplicateDialog);
                                } else if (res.resultType === 'WARNING') {
                                    dialog.custom(new WarningDialog({
                                        model: self.model,
                                        methodName: 'MODIFY',
                                        store,
                                        detailPage: self,
                                        confirms: res.confirms,
                                    }));
                                } else {
                                    alertMessage.renderMessage(self, 'save', JSON.parse(response.message), 0, true);
                                    // NH-37584 - enable button if there's an error.
                                    self.toggleButtonEnabled($button, true);
                                    scroll.scrollToFirstError();
                                }
                            },
                        },
                    );
                } else {
                    /*
                     * If invalid, we need to enable the button, because something
                     * else came up.
                     */
                    this.toggleButtonEnabled($button, true);
                    scroll.scrollToFirstError();
                }
            }
        }
        return undefined;
    },

    /**
     * @method updateCollectionFromFailedResults -  Update values in the collection
     * based on a failed server response
     * @param {object} response - response from the server containing confirmResults
     */
    updateCollectionFromFailedResults(response) {
        util.each(response.confirms.confirmResults, function (confirmResult) {
            this.model.attributes.items.models.forEach((model) => {
                const confirmData = transform.pairsToHash(confirmResult.confirmData[0].item);
                const sameFrom = `${model.get('DEBIT_ACCOUNT_TITLE') || model.fromAccount.attributes.mapDataList.DEBIT_ACCOUNT_TITLE} ${model.get('DEBIT_ACCOUNT_NUMBER') || model.fromAccount.attributes.mapDataList.DEBIT_ACCOUNT_NUMBER}` === `${confirmData.DEBIT_ACCOUNT_TITLE} ${confirmData.DEBIT_ACCOUNT_NUMBER}`;
                const sameTo = `${model.get('BENE_NAME') || model.toAccount.attributes.mapDataList.BENE_NAME} ${model.get('BENE_ACCOUNT') || model.toAccount.attributes.mapDataList.BENE_ACCOUNT}` === `${confirmData.BENE_NAME} ${confirmData.BENE_ACCOUNT}`;
                const sameAmount = this.evaluateDuplicateAmount(confirmData, model);
                const sameDate = moment(model.get('VALUE_DATE'), userInfo.getDateFormat()).isSame(confirmData.VALUE_DATE);

                /*
                 * Check if all the values from the server response match a record in the
                 * collection
                 */
                if (sameFrom && sameTo && sameAmount && sameDate) {
                    /*
                     * If we are dealing with a duplicate result then update the collection to
                     * indicate a possible duplicate. Needed for the duplicate warning modal.
                     */
                    if (confirmResult.resultType === 'DUPLICATEWARNING') {
                        model.set('POSSIBLEDUPLICATE', true);
                    }
                }
            });
        }, this);
    },

    /**
     * @method evaluateDuplicateAmount -  Evaluate if confirm data
     * and model data have duplicate values for amount and currency
     * @param {object} confirmData - response from the server containing confirmData
     * @param {object} model - transfer model
     */
    evaluateDuplicateAmount(confirmData, model) {
        let keyword;

        if (confirmData.TRANSACTION_AMOUNT) {
            keyword = 'TRANSACTION';
        } else if (confirmData.CREDIT_AMOUNT) {
            keyword = 'CREDIT';
        } else if (confirmData.DEBIT_AMOUNT) {
            keyword = 'DEBIT';
        } else {
            return false;
        }

        const sameCurrency = confirmData[`${keyword}_CURRENCY`] === model.get(`${keyword}_CURRENCY`);
        return (sameCurrency && Formatter.unformatNumber(model.get(`${keyword}_AMOUNT`)) === Formatter.unformatNumber(confirmData[`${keyword}_AMOUNT`]));
    },

    modify() {
        if (this.isSingleTransferTemplate(this.options)) {
            store.set('template_transfer-actionModel', this.savedModel);
            this.navigateTo(`TEMPLATES/modifySingleTransferTemplate/${this.options.recurId}&${this.options.isRecurring}`);
        } else if (this.templateMode) {
            store.set('template_transfer-actionModel', this.savedModel);
            this.navigateTo(`PAYMENTS/modifyTransferSet/${this.options.templateId}`);
        } else if (this.type === 'RECUR') {
            this.navigateTo(`PAYMENTS/modifyRecurring/${this.options.recurId}`);
        } else {
            store.set('modifyTransferModel', this.options.transferModel);
            this.navigateTo('PAYMENTS/modifyTransfer');
        }
    },

    /**
     * @method restore
     * function to navigate to restore template screen on click of Restore button
     */
    restore() {
        if (this.isSingleTransferTemplate(this.options)) {
            store.set('template_transfer-actionModel', this.savedModel);
            this.navigateTo(`TEMPLATES/restoreSingleTransferTemplate/${this.options.recurId}`);
        } else if (this.templateMode) {
            store.set('template_transfer-actionModel', this.savedModel);
            this.navigateTo(`PAYMENTS/restoreTransferSet/${this.options.templateId}`);
        } else if (this.type === 'RECUR') {
            this.navigateTo(`PAYMENTS/restoreRecurring/${this.options.recurId}`);
        } else {
            store.set('modifyTransferModel', this.options.transferModel);
            this.navigateTo('PAYMENTS/restoreTransfer');
        }
    },

    isSingleTransferTemplate(opts) {
        return opts.templateMode && opts.recurId;
    },

    approveWithWarning(options) {
        if (!this.tempModel && this.model) {
            this.tempModel = this.model;
        }
        this.tempModel.set({
            _saveWithWarning: 'true',
        });
        if (options && options.warningName === constants.DUPLICATE_ACCEPTED_INDICATOR) {
            this.tempModel.set({
                duplicateAccepted: 'true',
            });
        }
        this.approve();
    },

    /**
     * Callback method handling warning for modify
     */
    modifyWithWarning() {
        if (!this.tempModel && this.model) {
            this.tempModel = this.model;
        }
        this.tempModel.set({
            saveWithWarning: 'true',
        });
        this.model.set({
            saveWithWarning: 'true',
        });
        this.saveTransfers();
    },

    deleteWithWarning() {
        if (!this.tempModel && this.model) {
            this.tempModel = this.model;
        }
        this.tempModel.set({
            _saveWithWarning: 'true',
        });

        this.delete();
    },

    // Callback method handling cancel on warning
    cancelWarning() {
        const $button = this.$('[data-hook="submit-button"]');
        this.toggleButtonEnabled($button, true);
    },

    processViewFunctions(method) {
        let storeSetId = 'listTransferMessage';
        if (this.templateMode) {
            storeSetId = 'listTransferTemplateMessage';
        } else if (this.model.get('FUNCTION') === 'TMPL') {
            storeSetId = 'listRecurringMessage';
        }
        this.model.sync(
            method,
            this.model,
            {
                templateMode: this.templateMode,

                success: (model, response) => {
                    if (response.errorCode === 540) {
                        const duplicateDialog = new DuplicateDialog({
                            resp: response,
                            model,
                            isTransfer: true,
                            methodName: method.toUpperCase(),
                        });
                        duplicateDialog.once('approve', this.approve, this);
                        duplicateDialog.once('cancelWarning', this.cancelWarning, this);
                        dialog.custom(duplicateDialog);
                    } else if (response.resultType === 'WARNING') {
                        dialog.custom(new WarningDialog({
                            model: this.model,
                            methodName: method.toUpperCase(),
                            store,
                            detailPage: this,
                            confirms: response.confirms,
                        }));
                    } else {
                        if (this.isWidget) {
                            store.set(`${this.contextKey}-alertMessage`, method);
                            store.set(`${this.contextKey}-confirms`, response);
                        } else {
                            store.set(
                                storeSetId,
                                {
                                    action: method,
                                    response,
                                },
                            );
                        }
                        workspaceHelper.returnToCurrentWorkspace(this);
                    }
                },

                error: (response) => {
                    const message = `${response.errorCode} ${response.errorMessage}`;
                    this.alertView = alert.danger(
                        message,
                        {
                            canDismiss: true,
                        },
                    );
                    this.alertRegion.show(this.alertView);
                    scroll.scrollToFirstError();
                },
            },
        );
    },

    onClose() {
        store.unset('helpPage'); // remove view helppage from cache
    },

    delete() {
        this.processViewFunctions('delete');
    },

    approve() {
        this.processViewFunctions('approve');
    },

    unapprove() {
        this.processViewFunctions('unapprove');
    },

    reject() {
        const self = this;
        dialog.custom(new RejectDialog({
            model: this.tempModel,
            store,
            detailPage: self,
        }));
    },

    print() {
        const printModal = new PrintViewModal({
            exportModel: {
                outputFormat: 'PDF',
                pageType: 'LETTER',
                expData: 'transaction',
                detailReportId: 40007,

                actionData: {
                    productCode: 'RTGS',
                    functionCode: 'INST',
                    typeCode: 'TRANSFER',
                },

                searchFields: [{
                    fieldName: 'TNUM',
                    operator: 'IN',
                    fieldValue: [this.model.get('TNUM')],
                    dataType: 'number',
                }],
            },
        });
        dialog.custom(printModal);
    },

    cancel() {
        /*
         * Cannot use window.history.back() because we might have gone from view -> modify
         * and must return back to listview, not the view screen.
         */
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    /**
     * @method hasTransition -  determine if this transfer can be transitioned
     * based on model values and if it is in admin
     * @returns {boolean}
     */
    hasTransition() {
        if (systemConfig.isAdmin() && this.collection && this.collection.length > 0) {
            const newModel = this.collection.models[0];
            const actions = newModel.get('ACTIONLIST');
            if (actions && actions.indexOf('INSERT') >= 0 && newModel.get('STATUS') !== 'TP'
                && newModel.get('TRANSITIONS') && newModel.get('TRANSITIONS').length > 0) {
                this.transitions = JSON.parse(newModel.get('TRANSITIONS').replace(/&quot;/g, '"'));
                return true;
            }
        }
        return false;
    },

    /**
     * @method hasActionableTransition -  determine if transfer can transition based
     * on action and on model values and if it is in admin
     * @param {String} action
     * @returns {boolean}
     */
    hasActionableTransition(action) {
        if (systemConfig.isAdmin() && this.collection && this.collection.length > 0) {
            const newModel = this.collection.models[0];
            const actions = newModel.get('ACTIONLIST');
            if (actions && actions.indexOf(action) >= 0 && newModel.get('STATUS') === 'TP') {
                return true;
            }
        }
        return false;
    },

    /**
     * @method doShowAudit
     * @returns {Boolean} show or hide the audit option
     */
    doShowAudit() {
        return this.options.readOnly || this.options.mode === 'modify';
    },

    /**
     * @method isModifyAndSingleItem
     * @returns {Boolean} if view is showing a ONE2ONE transfer and is in modify mode
     */
    isModifyAndSingleItem() {
        return this.type === 'ONE2ONE' && this.options.mode === 'modify';
    },

    /**
     * @method doShowRefForTransfer
     * @returns {Boolean} whether the view should show or hide ref for transfer
     */
    doShowRefForTransfer() {
        return !this.options.readOnly && this.type !== 'RECUR' && this.type !== 'SINGLE';
    },

    /**
     * @method isSingleTransferOrModify
     * @returns {Boolean} whether the fiew is showing a single transfer or is in modify mode
     */
    isSingleTransferOrModify() {
        return (this.options.type === 'SINGLE'
            || this.options.mode === 'modify'
            || this.options.isModify) && this.options.readOnly !== true;
    },

    templateHelpers() {
        const showAudit = this.doShowAudit();
        const maxAmountView = Formatter.formatNumberByCurrency(this.model.get('TEMPLATE_MAX_AMOUNT'), this.model.get('TEMPMLATE_MAX_CURRENCY'));
        const formattedAmt = `${maxAmountView} ${this.model.get('TEMPLATE_MAX_CURRENCY')}`;
        const isModifyAndSingleItem = this.isModifyAndSingleItem();
        const showRefForTransfer = this.doShowRefForTransfer();
        const isSingleTransferOrModify = this.isSingleTransferOrModify();

        if (this.formState === 'view') {
            const panelCode = this.savedModel?.get('PANELCODE');
            const panelCodeDesc = this.savedModel?.get('PANELCODEDESC');

            /*
             * if the panel approval workflow is enabled AND both a panel profile code AND a panel
             * profile code desc exist on the model, set the panel profile details as a
             * combination of them
             */
            if (panelWorkflowAssignmentUtil.panelApprovalEnabled && panelCode && panelCodeDesc) {
                this.model.set({
                    panelProfileDetails: `${panelCode} - ${panelCodeDesc}`,
                    PANELPROFILECODE: panelCode,
                });
            } else { // in all other cases don't bother showing the field
                panelWorkflowAssignmentUtil.panelApprovalEnabled = false;
            }
        }

        return {
            hideSaveTemplate: this.options.readOnly || this.templateMode || this.options.templateId || this.type !== 'SINGLE' || (this.type === 'SINGLE' && !this.hasTemplateInsertEntitlement),
            hideAdd: this.options.readOnly || this.options.transferModel || this.copyFromTemplate || this.type === 'SINGLE',
            showRefForTemplate: !this.options.readOnly && !this.options.transferModel && this.type !== 'SINGLE',
            showRefForTransfer,
            templateMode: this.templateMode || this.options.templateId,
            fromTemplate: (this.options.templateId || this.options.recurId)
                && this.options.copyTemplateFromTemplate !== true,
            templateEdit: this.templateMode
                && (util.isUndefined(this.options.readOnly) || !this.options.readOnly),
            isRecur: this.type === 'RECUR',
            isSingleTransfer: this.type === 'SINGLE',
            isSingleTransferOrModify,
            isCopyFromTemplate: this.copyFromTemplate,
            isOne2OneShowDate: this.type === 'ONE2ONE' && !this.hideDate,
            isShowTime: !this.hideTime,
            isModifyAndSingleItem,
            hideSaveLater: this.type === 'SINGLE' && this.model.get('functionCode') === 'INST',
            singleLabel: this.singleLabel,
            multiLabel: this.multiLabel,
            readOnly: this.options.readOnly,
            fromTemplateNotModify: (this.options.templateId || this.options.recurId)
                && this.options.mode !== 'modify' && this.options.copyTemplateFromTemplate !== true,
            isAdmin: systemConfig.isAdmin(),
            hasModify: util.contains(this.buttons, 'MODIFY'),
            hasUnapprove: util.contains(this.buttons, 'UNAPPROV') && this.options.readOnly,
            hasApprove: util.contains(this.buttons, 'APPROVE') && this.options.readOnly,
            hasDelete: util.contains(this.buttons, 'DELETE') && this.options.readOnly,
            hasReject: util.contains(this.buttons, 'REJECT') && this.options.readOnly,
            hasRestore: util.contains(this.buttons, 'RESTORE') && this.options.readOnly,
            hasTransition: this.hasTransition(),
            hasApproveTransition: this.hasActionableTransition('APPROVE'),
            hasRejectTransition: this.hasActionableTransition('REJECT'),
            showOverrideSign: this.showSignatureDropDown,
            showPaymentAudit: this.model.get('functionCode') === 'INST' && showAudit,
            showTransitionAudit: (this.options.readOnly || this.options.mode === 'modify') && systemConfig.isAdmin(),
            showTemplateAudit: this.model.get('functionCode') === 'TMPL' && showAudit,
            showOptionalFieldRef: (showRefForTransfer && !isModifyAndSingleItem)
                || (!isSingleTransferOrModify && !this.options.readOnly),
            panelApprovalEnabled: panelWorkflowAssignmentUtil.panelApprovalEnabled,
            userTimezone: this.userTimezone,
            referenceLength: this.customerReferenceLength,
            showMaxAmount: this.showMaxAmount,
            baseCurrency: this.baseCurrency,
            templateMaxAmountFormatted: formattedAmt,
            showTemplateMaxAmoutMessage: this.copyFromTemplate && this.type !== 'SINGLE' && !util.isUndefined(this.model.get('TEMPLATE_MAX_AMOUNT')) && this.model.get('TEMPLATE_MAX_AMOUNT') !== '',
            hideCancelButton: () => (this.buttonsToHide ? util.contains(this.buttonsToHide, 'CANCEL') : false),
            hideReturnButton: () => (this.buttonsToHide ? util.contains(this.buttonsToHide, 'CANCEL') : false),
        };
    },

    setSetDescription(description) {
        this.model.set('setDescription', description);
    },

    setSetName(name) {
        this.model.set('setName', name);
    },

    setTransferSetUpdateCount(transferSetUpdateCount) {
        this.model.set('updateCount', transferSetUpdateCount);
    },

    setsingleRecurCheck(patternMode) {
        if (patternMode && patternMode !== 'O') {
            this.ui.singleRecur.prop('checked', true);
        }
    },

    /**
     * @method setRestrictTemplateCheck
     * @param {object} restrictTemplate -  restrict template model value
     * - function to check restric template ui element.
     */
    setRestrictTemplateCheck(restrictTemplate) {
        if (this.type === 'SINGLE' && restrictTemplate === '1') {
            this.ui.restrictTemplate.prop('checked', true);
        }
    },

    validateFromToAccounts() {
        let ret = true;
        let singleItemFieldName;
        let rowFieldName;
        const self = this;

        if ((this.type === 'MANY2ONE' || this.type === 'ONE2MANY') && this.model.get('singleItem')) {
            if (this.type === 'MANY2ONE') {
                singleItemFieldName = 'BENE_ACCOUNT';
                rowFieldName = 'DEBIT_ACCOUNT_NUMBER';
                if (this.mode === 'modify' && this.model.get('functionCode') === 'TMPL') {
                    singleItemFieldName = 'BENE_ACCOUNTENTITLEMENT';
                    rowFieldName = 'ACCOUNTFILTER';
                }
            } else if (this.type === 'ONE2MANY') {
                singleItemFieldName = 'DEBIT_ACCOUNT_NUMBER';
                rowFieldName = 'BENE_ACCOUNT';
            }
            this.collection.each((rec) => {
                if (self.model.get('singleItem').get(singleItemFieldName) === rec.get(rowFieldName)) {
                    if (self.alertRegion) {
                        self.alertView = alert.danger(
                            locale.get('RTGS.TRANSFER.BENE.DEBIT.CANNOT.BE.SAME'),
                            {
                                canDismiss: true,
                            },
                        );
                        self.alertRegion.show(self.alertView);
                    }
                    ret = false;
                }
            });
        }
        return ret;
    },

    snglRecurringSelect(e) {
        if (this.$(e.target).is(':checked') && this.ui.saveAsTemplate[0]) {
            this.singleRecurringSeries = true;
            this.ui.saveAsTemplate[0].disabled = true;
            if (this.ui.saveAsTemplate[0].checked !== true) {
                this.ui.saveAsTemplate[0].checked = true;
                this.ui.templateForm.show();
            }
            this.model.addValidator('setName', {
                exists: true,
                description: locale.get('PAY.TemplateCode'),
                matches: '^[A-Za-z0-9]+$',
                overrideError: 'matchesAlphaNumeric',
            });
            this.model.addValidator('setDescription', {
                description: locale.get('PAY.TemplateDescription'),
                exists: true,
            });
        } else if (this.ui.saveAsTemplate[0]) {
            this.singleRecurringSeries = false;
            this.ui.saveAsTemplate[0].checked = false;
            this.ui.saveAsTemplate[0].disabled = false;
            this.ui.templateForm.hide();
            this.removeValidator('setName', this.model);
            this.removeValidator('setDescription', this.model);
        }
    },

    translateSchedule(model, schedModel) {
        if (schedModel.get('error')) {
            const isScheModelError = Object.keys(schedModel.get('error') || {}).find(prop => util.isArray(schedModel.get('error')[prop]));
            if (isScheModelError) {
                return false;
            }
        }
        model.set('STARTINGEFFECTIVEDATE', schedModel.get('starts'));

        if (schedModel.get('type') === 'MONTHLY') {
            model.set('PATTERNMODE', 'M');

            if (schedModel.get('recurrence').intervalType === 'DAY') {
                model.set({
                    MONTHLYMODE: 'specific',
                    MONTHLYMONTH1: schedModel.get('recurrence').nMonths,
                    MONTHLYDAY1: Array.isArray(schedModel.get('recurrence').onN) ? schedModel.get('recurrence').onN[0].toString() : schedModel.get('recurrence').onN.toString(),
                });
                if (schedModel.get('recurrence').onN[0].toString() === 'LAST') {
                    dialog.error(locale.get('common.schedule.lastday.error'), 'Error');
                    return false;
                }
            } else {
                model.set({
                    MONTHLYMONTHNUMBER: schedModel.get('recurrence').nMonths,
                    MONTHLYMODE: 'interval',
                    MONTHLYDAYTYPE: schedModel.get('recurrence').intervalType.toLowerCase(),
                    MONTHLYDAYNUMBER: Array.isArray(schedModel.get('recurrence').onN) ? schedModel.get('recurrence').onN[0].toLowerCase() : schedModel.get('recurrence').onN.toLowerCase(),
                });
            }
        } else if (schedModel.get('type') === 'WEEKLY') {
            model.set({
                PATTERNMODE: 'W',
                WEEKLYWEEKS: schedModel.get('recurrence').nWeeks,
                WEEKLYDAYMON: '0',
                WEEKLYDAYTUE: '0',
                WEEKLYDAYWED: '0',
                WEEKLYDAYTHURS: '0',
                WEEKLYDAYFRI: '0',
                WEEKLYDAYSAT: '0',
                WEEKLYDAYSUN: '0',
            });

            util.each(schedModel.get('recurrence').onN, (dayIndex) => {
                if (dayIndex === 1) {
                    model.set('WEEKLYDAYMON', '1');
                } else if (dayIndex === 2) {
                    model.set('WEEKLYDAYTUE', '1');
                } else if (dayIndex === 3) {
                    model.set('WEEKLYDAYWED', '1');
                } else if (dayIndex === 4) {
                    model.set('WEEKLYDAYTHURS', '1');
                } else if (dayIndex === 5) {
                    model.set('WEEKLYDAYFRI', '1');
                } else if (dayIndex === 6) {
                    model.set('WEEKLYDAYSAT', '1');
                } else if (dayIndex === 7) {
                    model.set('WEEKLYDAYSUN', '1');
                }
            });
        } else if (schedModel.get('type') === 'YEARLY') {
            model.set({
                PATTERNMODE: 'Y',
                YEARLYMODE: 'specific',
                YEARLYYEARS: schedModel.get('recurrence').nYears,
            });
            util.each(schedModel.get('recurrence').onMonths, (monthIndex) => {
                if (monthIndex === 1) {
                    model.set('YEARLYMONTH1', '1');
                } else if (monthIndex === 2) {
                    model.set('YEARLYMONTH2', '1');
                } else if (monthIndex === 3) {
                    model.set('YEARLYMONTH3', '1');
                } else if (monthIndex === 4) {
                    model.set('YEARLYMONTH4', '1');
                } else if (monthIndex === 5) {
                    model.set('YEARLYMONTH5', '1');
                } else if (monthIndex === 6) {
                    model.set('YEARLYMONTH6', '1');
                } else if (monthIndex === 7) {
                    model.set('YEARLYMONTH7', '1');
                } else if (monthIndex === 8) {
                    model.set('YEARLYMONTH8', '1');
                } else if (monthIndex === 9) {
                    model.set('YEARLYMONTH9', '1');
                } else if (monthIndex === 10) {
                    model.set('YEARLYMONTH10', '1');
                } else if (monthIndex === 11) {
                    model.set('YEARLYMONTH11', '1');
                } else if (monthIndex === 12) {
                    model.set('YEARLYMONTH12', '1');
                }
            });
            let count = 0;
            util.each(schedModel.get('recurrence').onN, (onDay) => {
                count += 1;
                model.set(`YEARLYDAY${count}`, onDay);
            });
        }

        // Get End of Schedule
        const ends = schedModel.get('ends');
        if (util.isBoolean(ends)) {
            model.set('ENDMODE', 'noend');
        } else if (util.isNumber(ends)) {
            model.set({
                ENDMODE: 'numoccur',
                ENDCYCLES: ends.toString(),
            });
        } else {
            model.set({
                ENDMODE: 'effdate',
                ENDINGEFFECTIVEDATE: ends ? ends.toString() : '',
            });
        }
        // Get reset pattern
        const resetPattern = schedModel.get('resetPattern');
        if (resetPattern) {
            model.set('RESETPATTERN', resetPattern);
        }
        return true;
    },

    hasContractIdAndExchangeRate(item) {
        if (item.has('EXCHANGE_RATE_CONTRACTID') && item.has('EXCHANGE_RATE') && !util.isEmpty(item.get('EXCHANGE_RATE_CONTRACTID')) && !util.isEmpty(item.get('EXCHANGE_RATE'))) {
            return true;
        }
        return false;
    },

    /**
     * @method gridLoadComplete
     * shows or hides the grid for transition audit
     */
    gridLoadComplete() {
        const transitionAuditRegionDiv = $('#transitionAuditRegionDiv');
        if (this.transitionTrackingAuditGrid
            && this.transitionTrackingAuditGrid.wrapper.rows.totalCount === 0) {
            transitionAuditRegionDiv.empty();
        } else {
            const id = util.uniqueId();
            transitionAuditRegionDiv.addClass('section section-container');
            transitionAuditRegionDiv.prepend(`<div class="panel panel-tertiary"><div class="panel-heading" role="tab" id="collapseHeading${id}"><h4 class="panel-title"><a role="button" data-toggle="collapse" href="#collapseArea${id}" aria-expanded="true" aria-controls="collapseArea${id}"><span class="indicator-icon"></span> ${locale.get('transition.auditgrid.title')} </a></h4></div><div id="collapseArea${id}" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="collapseHeading${id}"><div class="panel-body"></div></div></div>`);
            transitionAuditRegionDiv.find('> div:last-child').appendTo(transitionAuditRegionDiv.find('> .panel .panel-body'));
        }
    },

    /**
     * @method setupTransitionTrackingGrid
     * does the query for data and populates a grid
     */
    setupTransitionTrackingGrid() {
        const data = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    action: {
                        typeCode: 'TRANSFER',
                        productCode: 'ADMPAY',
                        functionCode: 'INST',
                        actionMode: 'SELECT',
                    },

                    searchType: '5',
                    inquiryId: 31000,
                },
            },
        };

        this.customFilters = [{
            filterName: 'Depends',
            filterParam: ['TNUM', this.savedModel.get('TNUM')],
        }];

        data.inquiryRequest.searchCriteria.customFilters = this.customFilters;

        const contextLocal = {
            actionMode: 'SELECT',
            displayOrder: 1,
            functionCode: 'INST',
            inquiryId: 31000,
            gridId: 0,
            nonDashboardDisplay: 0,
            productCode: 'ADMPAY',
            typeCode: 'TRANSFER',
        };

        const options = {
            context: contextLocal,
            filter: false,
            selector: 'none',
            hideButtons: true,

            // default grid action buttons are hidden
            hideGridActionButtons: true,

            enableRowContextButtonCallbacks: true,
            inquirySearchCriteria: data.inquiryRequest.searchCriteria,
            lvc: this.lvc,
        };

        this.transitionTrackingAuditGrid = gridApi.createInquiryGridView(options);
        this.listenTo(this.transitionTrackingAuditGrid, 'gridapi:loaded', this.gridLoadComplete);
    },
});
