var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"ColumnRename-form\">\n    <input\n        class=\"ColumnRename-formInput form-control\"\n        data-hook=\"column-rename-input\"\n        type=\"text\"\n        maxlength=\"64\"\n        name=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":7,"column":14},"end":{"line":7,"column":23}}}) : helper)))
    + "\"\n        value=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"inputNameValue") : depth0), depth0))
    + "\"\n    />\n    <span class=\"ColumnRename-formErrorMsg help-block\" data-bind=\"model\" data-validate=\"inputedRename\"></span>\n    <div class=\"ColumnRename-formControls\">\n        <button class=\"ColumnRename-formBtn btn btn-sm btn-secondary\" data-action=\"submitRename\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"update",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":97},"end":{"line":12,"column":116}}}))
    + "</button>\n        <button class=\"ColumnRename-formBtn btn btn-sm btn-tertiary\" data-action=\"close\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":89},"end":{"line":13,"column":115}}}))
    + "</button>\n    </div>\n</form>\n";
},"useData":true});