import dialog from '@glu/dialog';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import Collection from '@glu/core/src/collection';
import { PaymentNextIcon } from '@glu/icons-react';
import constants from 'common/dynamicPages/api/constants';
import store from 'system/utilities/cache';
import errorHandlers from 'system/error/handlers';
import ListView from 'common/dynamicPages/views/workflow/list';
import entitlements from 'common/dynamicPages/api/entitlements';
import PaymentsChartView from 'app/payments/views/paymentsChart';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import printExportUtil from 'common/util/printExportUtil';
import AddPaymentModalView from 'app/payments/views/addPayment';
import actionFunction from 'app/payments/api/common';
import workspaceHelper from 'common/workspaces/api/helper';
import services from 'services';
import TotalsModel from 'common/dataCard/models/paymentSummary';
import TotalsView from 'common/dataCard/views/dataCardCollectionView';
import infoTooltipHelper from 'common/dynamicPages/views/mdf/componentHelpers/infoTooltip';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import systemConfig from 'system/configuration';
import ConversationView from 'app/payments/views/realTimePayments/rtpConversationView';
import payPrintExportUtil from 'common/util/payPrintExportUtil';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import MaskToggleUtil from 'common/util/maskToggleUtil';
import gridApi from 'common/dynamicPages/api/grid';
import template from './listPayments.hbs';
import { isDeepLinked } from '../../../common/util/deeplinkUtil';

const PaymentManagementList = ListView.extend(util.extend({}, payPrintExportUtil, {
    className: 'payment-management-list',

    regions: {
        totalsSection: '[data-hook="payment-totals-region"]',
        debitPaymentTotal: '[data-hook="debit-payment-total"]',
        creditPaymentTotal: '[data-hook="credit-payment-total"]',
    },

    template,

    ui: {
        $popovers: '[data-toggle="popover"]',
        $message: '.maxDaysMessage',
        $icon: '.icon-info',
        $toggleTotals: '.toggleTotals',
        $totalsRegion: '[data-hook="payment-totals-region"]',
    },

    events: util.extend(
        {},
        ListView.prototype.events,
        {
            'click [data-hook="print-button"]': 'showPrintOptionsModal',
            'click [data-hook="fileimport-button"]': 'fileimport',
            'click [data-hook="quickentry-button"]': 'quickEntry',
            'click [data-hook="toggle-totals-button"]': 'toggleTotals',
        },
    ),

    initialize(options) {
        this.gridApi = gridApi; // require here to avoid circular reference

        const superOptions = {
            menuCategory: 'PMTS',
            serviceName: 'payments/corp',
            serviceFunc: null,
            businessType: null,
            context: 'PAY_LIST_VIEW',
            returnRoute: 'PAYMENTS/listPayments',
            viewId: options.viewId,
        };

        if (!options.isFileLevelWorkflow) {
            store.set('file-level-fragment-override', null);
        }

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
        this.nachaExportListViewPath = '/paymentExport/listView/corp/exportListView';
        this.exportListViewPath = systemConfig.isAdmin()
            ? '/paymentExport/listView/corp/exportListView' : services.runAsyncListViewReport;
        this.shouldShowTotals = serverConfigParams.get('ShowPaymentListViewTotals') === 'true';
        ({ shouldShowMaskToggle: this.shouldShowMaskToggle, maskToggle: this.maskToggle }
            = MaskToggleUtil.initMaskToggle());

        // listen for rtp conversation updates
        this.listenTo(this.appBus, 'rtp:conversationUpdated', this.refreshData);
    },

    updateFromRender() {
        // Do not fetch if ShowPaymentListViewTotals config param is false and totalsModel is empty
        if (this.shouldShowTotals && !systemConfig.isAdmin() && this.totalsModel) {
            this.updateSubTotals();
        }
        // update refresh timestamp
        this.updateRefreshTimestamp();
    },

    updateSubTotals() {
        this.totalsModel.set('filters', this.gridView.wrapper.generateFiltersDataBlock());
        this.totalsModel.set('viewId', this.gridView.wrapper.viewId);
        this.totalsModel.fetch();
    },

    /**
     * @name displayAccountMaskToggle
     * @description if applicable, displays the account masking toggle
     */
    displayAccountMaskToggle() {
        if (this.shouldShowMaskToggle) {
            MaskToggleUtil.showMaskToggle(this, {
                name: 'maskTogglePayments',
                htmlId: 'maskTogglePayments',
                labelText: locale.get('common.maskBeneAcctNum'),
                onChange: this.toggleMaskColumns.bind(this),
                region: this.toggleSwitchOuterRegion,
            });
        }
    },

    onRender() {
        infoTooltipHelper.setupInfoTooltips(this);
        if (this.hasLoadedRequiredData()) {
            this.displayAccountMaskToggle();
            this.gridRegion.show(this.gridView);
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
            this.listenTo(this.appBus, 'rtp:viewPayment', this.gridRowSelect);
            this.renderMessage(store.get(`${this.contextKey}-alertMessage`), store.get(`${this.contextKey}-confirms`));
            store.set(`${this.contextKey}-alertMessage`, null);
            store.set(`${this.contextKey}-confirms`, null);
        } else {
            this.loadViewRequirements();
        }
        if (isDeepLinked) {
            this.$el.find('.page-header-wrapper').first().hide();
            this.$el.find('.section-summary').removeClass('section-summary');
            this.$el.find('.widget-action-btn-group').removeClass('widget-action-btn-group');
        }
    },

    initializeTotals() {
        if (this.totalsModel && this.totalsView) {
            this.totalsModel.set(
                'filters',
                this.gridView.wrapper.generateFiltersDataBlock(),
            );
            this.totalsSection.show(this.totalsView);
            this.listenTo(this.totalsModel, 'change', function () {
                this.totalsView.isLoading = true;
                this.totalsCollection.reset(this.totalsModel.get('paymentTotalsByPaymentGroupList'));
                this.totalsView.render();
            });
            return;
        }
        if (!this.totalsModel) {
            this.totalsModel = new TotalsModel({
                filters: this.gridView.wrapper.generateFiltersDataBlock(),
            }, {
                service: services.generateUrl('payment/getPaymentsSummary'),
                order: ['ACH', 'LOANS', 'WIRES', 'BILLPAY', 'RTP'],
            });
            /*
             * TODO: 30315 - Once the API is updated to handle in Admin,
             * the check for systemConfig.isAdmin() can be removed.
             */
            if (this.shouldShowTotals && !systemConfig.isAdmin()) {
                this.totalsCollection = new Collection();
                this.totalsView = new TotalsView({
                    collection: this.totalsCollection,
                    isLoading: true,
                });
                this.totalsSection.show(this.totalsView);
            }
            this.listenTo(this.totalsModel, 'change', function () {
                const paymentTotalsData = this.totalsModel.get('paymentTotalsByPaymentGroupList');
                this.totalsView.isLoading = true;
                if (paymentTotalsData) {
                    this.totalsCollection.reset(paymentTotalsData);
                }
            });
        }
    },

    createGridView(options) {
        return this.gridApi.createServiceGridView(options);
    },

    loadCharts() {
        const originalCollection = this.gridView.wrapper.rows;
        const dataCollection = new Collection();
        for (let i = 0; i < originalCollection.length; i += 1) {
            const amount = originalCollection.models[i].get('CMB_TRANSACTION_AMOUNT');
            const date = originalCollection.models[i].get('CMB_VALUE_DATE');
            if (!util.isEmpty(amount) && !util.isEmpty(date)) {
                dataCollection.add({
                    amount: parseFloat(amount),
                    due: date,
                });
            }
        }
        this.chartView = new PaymentsChartView({
            collection: dataCollection,
        });
        this.paymentChartRegion.show(this.chartView);
        this.listenTo(this.gridView.getRows(), 'sync', this.refreshChart);
    },

    refreshChart() {
        const originalCollection = this.gridView.wrapper.rows;
        const dataCollection = new Collection();
        for (let i = 0; i < originalCollection.length; i += 1) {
            const amount = originalCollection.models[i].get('CMB_TRANSACTION_AMOUNT');
            const date = originalCollection.models[i].get('CMB_VALUE_DATE');
            if (!util.isEmpty(amount) && !util.isEmpty(date)) {
                dataCollection.add({
                    amount: parseFloat(amount),
                    due: date,
                });
            }
        }
        //            this.chartView.updateChart(dataCollection);
    },

    toggleTotals() {
        const isVisible = this.ui.$totalsRegion.height() > 0;
        if (isVisible) {
            this.ui.$totalsRegion.css({
                height: '0',
                visibility: 'hidden',
            });
            this.ui.$toggleTotals.text(locale.get('common.show.totals'));
        } else {
            // Show totals data only when user clicks on "show totals" button for the first time
            if (!this.totalsModel) {
                this.initializeTotals();
                this.updateFromRender();
            }
            this.ui.$totalsRegion.css({
                height: 'auto',
                visibility: 'visible',
            });
            this.ui.$toggleTotals.text(locale.get('common.hide.totals'));
        }
    },

    insert() {
        this.addPaymentView = new AddPaymentModalView({
            isModal: true,
        });
        this.listenTo(this.addPaymentView, 'selected', this.addPaymentSelected);
        dialog.open(this.addPaymentView);
        return Promise.resolve();
    },

    addPaymentSelected(modelParam) {
        const model = modelParam;
        let overrideContext;

        if (model.get('selection') === 'newPayment') {
            if (model.get('type').indexOf('LOANPAY') > -1 || model.get('type').indexOf('LOANDRAW') > -1) {
                store.set('basic_payment_data', model);
                this.navigateTo('PAYMENTS/addPayment');
            } else {
                overrideContext = util.extend(this.contextDef, model.get('context'));
                overrideContext.enableSaveDraft = true;
                overrideContext.productCode = model.get('productCode');

                store.set('listView-contextKey', this.contextKey);
                store.set('multiAdd-contextKey', `${this.contextKey}-contextOverride`);
                store.set(`${this.contextKey}-contextOverride`, overrideContext);

                const isTransfer = overrideContext.serviceName.indexOf('/transfer') !== -1;
                const isManyToOne = overrideContext.transferType === 'many-to-one';
                const isOneToMany = overrideContext.transferType === 'one-to-many';
                if (overrideContext.isRapidWireEntry) {
                    this.navigateTo('PAYMENTS/rapidWireEntry');
                } else if (overrideContext.isRtpMultiAdd) {
                    this.navigateTo('PAYMENTS/rtpMultiAdd');
                } else if (isTransfer && isManyToOne) {
                    overrideContext.subType = 'MANY2ONE';
                    store.set(`${this.contextKey}-contextOverride`, overrideContext);
                    this.navigateTo('PAYMENTS/addTransferManyToOne');
                } else if (isTransfer && isOneToMany) {
                    overrideContext.subType = 'ONE2MANY';
                    store.set(`${this.contextKey}-contextOverride`, overrideContext);
                    this.navigateTo('PAYMENTS/addTransferOneToMany');
                } else {
                    this.insertOverride(overrideContext);
                }
            }
        }
        if (model.get('selection') === 'template') {
            if (model.get('context').type.indexOf('LOANPAY') > -1 || model.get('context').type.indexOf('LOANDRAW') > -1) {
                model.set('FROMTEMPLATE', '1');
                model.set('TARGETFUNCTION', this.contextDef.functionCode);
                store.set('basic_payment_data', model);
                this.navigateTo('PAYMENTS/addPayment');
            } else {
                if (model.get('context').functionCode === 'BHTMPL') {
                    model.get('context').serviceName = model.get('context').serviceName.replace('/payment/', '/batch/');
                    model.get('context').templateServiceName = model.get('context').templateServiceName.replace('/template/', '/batchTemplate/');
                }
                overrideContext = util.extend(
                    this.contextDef,
                    {
                        serviceName: model.get('context').serviceName,
                        templateServiceName: model.get('context').templateServiceName,
                        tnum: model.get('context').tnum,
                        functionCode: model.get('context').functionCode,
                        subType: model.get('context').subType,
                        updateCount: model.get('context').updateCount,
                        templateEntryMethod: model.get('context').templateEntryMethod,
                    },
                );
                overrideContext.enableSaveDraft = true;
                store.set(`${this.contextKey}-contextOverride`, overrideContext);
                this.navigateTo('PAYMENTS/addPaymentFromTemplate');
            }
        }
    },

    gridPaymentFromTemplate(optionsParam) {
        const options = optionsParam;
        const { typeCode } = options.model.context.actionData;

        if (typeCode.indexOf('LOANPAY') > -1 || typeCode.indexOf('LOANDRAW') > -1) {
            options.model.action = options.action;
            options.model.loadFromPayments = true;
            options.model.set('FROMTEMPLATE', '1');
            options.model.set('TARGETFUNCTION', this.contextDef.functionCode);
            store.set('basic_payment_data', options.model);
            this.navigateTo('PAYMENTS/addPayment');
        } else {
            options.model.loadFromPayments = true;
            actionFunction.makePayment(options.model, this.contextKey);
            this.navigateTo('PAYMENTS/addPaymentFromTemplate');
        }
        return Promise.resolve();
    },

    gridTemplateFromPayment(optionsParam) {
        const options = optionsParam;
        const { typeCode } = options.model.context.actionData;

        if (typeCode.indexOf('LOANPAY') > -1 || typeCode.indexOf('LOANDRAW') > -1) {
            options.model.action = options.action;
            options.model.loadFromPayments = true;
            options.model.context.actionData.functionCode = 'TMPL';
            options.model.set('FROMTEMPLATE', '1');
            options.model.set('TARGETFUNCTION', this.contextDef.functionCode);
            store.set('basic_template_data', options.model);
            this.navigateTo('TEMPLATE/addTemplate');
        } else {
            actionFunction.makeTemplate(options.model, this.contextKey);
            this.navigateTo('TEMPLATES/addTemplateFromPayment');
        }
        return Promise.resolve();
    },

    gridRowSelect(options) {
        const serviceName = options.model.context.serviceName.toLowerCase();
        if (serviceName.indexOf('loandraw') > -1 || serviceName.indexOf('loanpay') > -1) {
            store.set('custom_view_payment_model', options.model);
            this.navigateTo('PAYMENTS/viewLoanPayment');
        } else if (options.model.get('TYPE') === 'PASSTHRU' || options.model.get('TYPE') === 'BEFTPASS') {
            store.set('passthru_view_payment_model', options.model);
            store.set('SURROGATETNUM', options.model.get('TNUM'));
            this.navigateTo('PAYMENTS/passthroughDetail');
        } else {
            if (options.model.get('PRODUCT') === 'RTP') {
                const conversationStarted = options.model.get('MESSAGE_STATE') > 1;
                if (conversationStarted) {
                // create the view passing in the model
                    const rtpConversationData = {
                        view: ConversationView,
                        shouldBeOpen: false,
                        showTab: true,
                        viewOptions: {
                            model: options.model,
                            conversationStarted,
                            defaultOpen: false,
                            allowDetail: false,
                            currentTab: 'submitted',
                            keepAlive: true,
                        },
                    };
                    this.appBus.trigger('dgb:drawer:update', rtpConversationData);
                }
            }
            // PCM RELATAED
            if (options.model.get('PRODUCT') === 'CLM') {
                const entryMethod = options.model.get('ENTRYMETHOD');
                if (entryMethod === '0') {
                    options.model.set('entitlements', this.entitlements);
                } else {
                    options.model.set('entitlements', this.importEntitlements);
                }
            }
            store.set(`${this.contextKey}-actionModel`, options.model);
            this.navigateTo('PAYMENTS/viewPayment');
        }
        return Promise.resolve();
    },

    gridRowModify(options) {
        const serviceName = options.model.context.serviceName.toLowerCase();

        if (serviceName.indexOf('loandraw') > -1 || serviceName.indexOf('loanpay') > -1) {
            store.set('custom_modify_payment_model', options.model);
            this.navigateTo('PAYMENTS/modifyLoanPayment');
        } else {
            store.set(`${this.contextKey}-actionModel`, options.model);
            this.navigateTo('PAYMENTS/modifyPayment');
        }
        return Promise.resolve();
    },

    /**
     * @method gridRowReverse
     * @param {object} options -  data from grid row
     * - function to navigate to reverse payment screen on select of Reverse
     * action from context menu
     */
    gridRowReverse(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo('PAYMENTS/reversePayment');
        return Promise.resolve();
    },

    gridRowRepair(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo('PAYMENTS/repairPayment');
        return Promise.resolve();
    },

    templateHelpers() {
        const self = this;

        return {
            getString(type) {
                return locale.get(self.localeKey + type);
            },

            getButtonString(type) {
                return locale.get(`${self.localeKey.split('.')[0]}.button_${type}`);
            },

            context() {
                return self.contextKey;
            },

            hasInsertEntitlement() {
                return self.hasEntitlement(constants.ACTION_INSERT)
                    || self.hasEntitlement(constants.ACTION_COPYPAYFROMTMPL);
            },

            hasApproveEntitlement() {
                return self.hasEntitlement(constants.ACTION_APPROVE);
            },

            hasUnapproveEntitlement() {
                return self.hasEntitlement(constants.ACTION_UNAPPROVE);
            },

            hasFileImportEntitlement() {
                return self.hasEntitlement(constants.ACTION_IMPORT);
            },

            hasRejectEntitlement() {
                return self.hasEntitlement(constants.ACTION_REJECT);
            },

            hasDeleteEntitlement() {
                return self.hasEntitlement(constants.ACTION_DELETE);
            },

            hasGetRateEntitlement() {
                return self.hasEntitlement(constants.ACTION_GETRATE);
            },

            hasTradeEntitlement() {
                return self.hasEntitlement(constants.ACTION_GETRATE);
            },

            hasTemplateSelectEntitlement() {
                return self.hasEntitlement(constants.ACTION_COPYPAYFROMTMPL);
            },

            hasBulkActionEntitlements() {
                // return true if the user is entitled to perform bulk actions on grid rows
                return self.hasEntitlement(constants.ACTION_APPROVE)
                    || self.hasEntitlement(constants.ACTION_GETRATE)
                    || self.hasEntitlement(constants.ACTION_UNAPPROVE)
                    || self.hasEntitlement(constants.ACTION_REJECT)
                    || self.hasEntitlement(constants.ACTION_DELETE);
            },

            showTotals() {
                const shouldShow = self.shouldShowTotals;
                return (shouldShow && !systemConfig.isAdmin());
            },

            // RELATED PCM
            hasPrintCheckEntitlement() {
                return self.hasEntitlement(constants.ACTION_PRINTCHECK);
            },

            hasReprintCheckEntitlement() {
                return self.hasEntitlement(constants.ACTION_REPRINTCHECK);
            },

            hasVoidCheckEntitlement() {
                return false; // change code when entitlements are implemented
            },
        };
    },

    updateMaxDaysMsg() {
        const maxDaysMsg = this.gridView.getResponseParameter('PAYMAXDAYS');
        if (maxDaysMsg) {
            this.ui.$message.text(maxDaysMsg);
            this.ui.$icon.removeClass('hidden');
        } else {
            this.ui.$message.hide();
            this.ui.$icon.hide();
        }
    },

    loadViewRequirements() {
        const options = this.viewOptionRequirements();
        const gridView = this.gridApi.createServiceGridView({
            ...options,
            // TODO: remove this once we're using the mobile grid ubiquitously
            useMobileGrid: true,
        });
        const entitlementPromise = entitlements.getEntitlements(options);

        this.gridView = gridView;

        const optionsFimport = util.clone(options);
        const optionsCopyFromTemplate = util.clone(options);
        const optionsCopyFromRepetitive = util.clone(options);
        const optionsFreeForm = util.clone(options); // PCM
        const templateOptions = {
            context: {},
        };

        util.extend(templateOptions.context, options.context);
        templateOptions.context.serviceName = 'template/listView/corp';
        // PCM const templateEntitlementPromise = entitlements.getEntitlements(templateOptions);

        // for file import
        optionsFimport.entryMethod = 3;

        // for copy payment from template entrymethod 1
        optionsCopyFromTemplate.entryMethod = 1;

        // for copy payment from Repetitive entrymethod 2
        optionsCopyFromRepetitive.entryMethod = 2;

        // for check free form
        optionsFreeForm.entryMethod = 0;// PCM

        const entitlementFimportPromise = entitlements.getEntitlements(optionsFimport);
        const entitlementCopyFromTemplatePromise =
            entitlements.getEntitlements(optionsCopyFromTemplate);
        const entitlementCopyFromRepetitivePromise =
            entitlements.getEntitlements(optionsCopyFromRepetitive);
        const entitlementCheckFreeFrom =
            entitlements.getEntitlements(optionsFreeForm); // PCM

        ListView.prototype.setupGridAvailableListener.call(this);

        // merge both promises (regular payments and the other one for file import)
        return Promise.all([
            entitlementPromise,
            entitlementFimportPromise,
            // PCM templateEntitlementPromise,
            entitlementCopyFromTemplatePromise,
            entitlementCopyFromRepetitivePromise,
            entitlementCheckFreeFrom, // PCM
            this.gridView.loadPromise,
        ]).then(([
            entResults,
            entFimportResults,
            copyFromTmplResults,
            copyFromRepResults,
            checkEntitlementsResults,
        ]) => {
            const entResActions = entResults.actions;
            const entFimportActions = entFimportResults.actions || {};
            const entCpFromTmpl = copyFromTmplResults.actions || {};
            const checkEntitlements = checkEntitlementsResults.actions || {}; // PCM
            if ((copyFromTmplResults.actions && copyFromTmplResults.actions.INSERT) ||
            (copyFromRepResults.actions && copyFromRepResults.actions.INSERT)) {
            // has insert entitement for payments from template or repetitive
                entResActions.COPYPAYFROMTMPL = true;
            }
            /*
             * if the actions are true for entitlementFimportPromise, then set it true
             * for entitlementPromise so that the buttons show up correctly in the grid.
             * ideally, we should be able to call just one service and that one
             * should return all the entitlements irrespective of the entrymethod.
             */
            entResActions.APPROVE = entFimportActions.APPROVE || entCpFromTmpl.APPROVE;
            entResActions.DELETE = entFimportActions.DELETE || entCpFromTmpl.DELETE;
            entResActions.GETRATE = entCpFromTmpl.GETRATE;
            entResActions.IMPORT = entFimportActions.INSERT;
            entResActions.MODIFY = entFimportActions.MODIFY;
            entResActions.REJECT = entFimportActions.REJECT || entCpFromTmpl.REJECT;
            entResActions.SELECT = entFimportActions.SELECT;
            entResActions.TRADE = entCpFromTmpl.TRADE;
            entResActions.UNAPPROVE = entFimportActions.UNAPPROVE || entCpFromTmpl.UNAPPROVE;
            this.importEntitlements = {
                ...entFimportActions,
            }; // PCM
            entResActions.PRINT = checkEntitlements.PRINT; // PCM
            entResActions.REPRINT = checkEntitlements.REPRINT;// PCM

            this.entitlements = entResActions;
            this.setHasLoadedRequiredData(true);
            if (!mobileUtil.isMobileGridEnabled()) {
                this.listenTo(this.gridView.getRows(), 'sync', this.updateFromRender);
                this.render();
                this.updateFromRender();
                this.updateMaxDaysMsg();
                this.listenForGridErrors();
            }
            return entResActions;
        }, util.bind(errorHandlers.loading, this));
    },

    quickEntry() {
        this.navigateTo('/PAYMENTS/quickEntry');
    },

    fileimport() {
        const importContext = {
            serviceName: '/fimport/import',
            actionMode: 'IMPORT',
        };
        const overrideContext = util.extend(this.contextDef, importContext);
        store.set(`${this.contextKey}-contextOverride`, overrideContext);
        this.navigateTo(this.contextModel.getImportUrl());
    },

    export() {
        this.exportPayListView();
    },

    /**
     * Get print options for this list
     * @returns {Object}
     */
    getPrintOptions() {
        return {
            inquiryId: constants.INQUIRY_ID_20017,
        };
    },

    printPay(model) {
        this.exportModel = printExportUtil.buildPrintModel(model, this.gridView, '20017');
        const printModal = new PrintViewModal({
            exportModel: this.exportModel,
        });
        dialog.custom(printModal);
    },

}));

let list = PaymentManagementList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        insertActions: [
            {
                label: 'payment.add',
                entitlement: 'INSERT',
                handlerMethodName: 'insert',
            },
            {
                label: 'button.quickentry',
                entitlement: constants.ACTION_COPYPAYFROMTMPL,
                handlerMethodName: 'quickEntry',
            },
        ],
        bulkActions: [
            {
                label: 'payment.button_approve',
                entitlement: 'APPROVE',
            },
            {
                label: 'payment.button_unapprove',
                entitlement: 'UNAPPROVE',
            },
            {
                label: 'payment.button_reject',
                entitlement: 'REJECT',
            },
            {
                label: 'payment.button_delete',
                entitlement: 'DELETE',
            },
            {
                label: 'payment.button_getrate',
                entitlement: 'GETRATE',
            },
            {
                label: 'payment.button_trade',
                entitlement: 'GETRATE',
            },
        ],
        additionalActions: [
            MaskToggleUtil.getMobileGridActions(),
        ],
    });
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'PAYLIST',
    view: list,
    options: {},
    useMobileCard: true,
    mobileCardProps: {
        icon: PaymentNextIcon,
    },
});

const exportedList = list;

export default exportedList;
