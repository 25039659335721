var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!--breadcrumbs and title-->\n<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"getPageTitle") || (depth0 != null ? lookupProperty(depth0,"getPageTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"getPageTitle","hash":{},"data":data,"loc":{"start":{"line":3,"column":31},"end":{"line":3,"column":47}}}) : helper)))
    + "</h1>\n</div>\n\n<!--navigation and alert section-->\n<section class=\"section section-container\" role=\"navigation\">\n\n    <!--navigation tabs-->\n    <div class=\"section-body\">\n        <ul id=\"check-management-tablist\" class=\"NavTabs nav nav-tabs\" role=\"tablist\"\n            data-hook=\"getNavTabs\">\n            <li class=\"NavTabs-item is-active\"\n               role=\"tab\">\n                <a class=\"NavTabs-link btn btn-tertiary\"\n                    id=\"individualItems\"\n                    tabindex=\"0\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.IndividualItems",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":33},"end":{"line":17,"column":64}}}))
    + "\n                </a>\n            </li>\n            <li class=\"NavTabs-item is-active\"\n               role=\"tab\">\n                <a class=\"NavTabs-link btn btn-tertiary\"\n                    id=\"fileExtract\"\n                    tabindex=\"0\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.FileExtract",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":33},"end":{"line":24,"column":60}}}))
    + "\n                </a>\n            </li>\n        </ul>\n\n        <div class=\"alert-message\">\n            <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        </div>\n\n        <!--main section for tab content-->\n        <div class=\"individualItemsRegion\" region=\"individualItemsRegion\"></div>\n        <div class=\"fileExtractRegion\" region=\"fileExtractRegion\"></div>\n\n    </div>\n</section>\n";
},"useData":true});