import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import itemViewTmpl from './itemView.hbs';

export default ItemView.extend({
    tagName: 'div',
    className: 'row',
    template: itemViewTmpl,

    initialize(options) {
        const self = this;

        this.modifyMode = options.modifyMode || false;
        this.mode = options.mode;
        this.originalAttributes = util.clone(this.model.attributes);

        // reset values if cancelled
        this.listenTo(this.appBus, 'usercentric:paymentLimits:cancel:reset', () => {
            self.model.attributes = self.originalAttributes;
        });
    },

    onRender() {
        // limits are numeric
        this.$('input').inputmask({
            mask: '9[9999999999999]',
            placeholder: '',
        });
    },

    templateHelpers() {
        const self = this;

        return {
            showDaily() {
                return self.model.get('overallDailyLimitForType').applicable;
            },

            showBatch() {
                return self.model.get('overallBatchLimitForType').applicable;
            },

            showTransaction() {
                return self.model.get('overallTransactionLimitForType').applicable;
            },

            isModifyMode() {
                return self.modifyMode;
            },
        };
    },
});
