import ViewItem from 'app/smbPayments/views/viewItem';
import dialog from '@glu/dialog';
import CombinedPaymentModal from 'app/smbPayments/views/modals/combinedPayment';
import store from 'system/utilities/cache';

export default ViewItem.extend({
    initialize(options) {
        // The existence of this cache is enough to conclude that we are viewing a payment
        const isPayment = !!store.get('payment_listView_smb-actionModel');

        this.context = {
            payContext: 'SMB_PAY_LIST',
            tempContext: 'SMB_TEMP_LIST',
            returnTabStoreName: 'payment_smb-currentTab',
            paymentContextStoreName: 'payment_listView_smb-contextOverride',
            templateContextStoreName: 'template_listView_smb-contextOverride',
            modifyURL: isPayment ? 'payments/modifyPayment' : 'templates/modifyTemplate',
            copyAsPaymentURL: isPayment ? 'payments/copyTax' : 'templates/copyAsPayment',
            copyPaymentAsPaymentURL: isPayment ? 'payments/copyTax' : 'templates/copyAsPayment',
            copyPaymentAsTemplateURL: 'templates/copyPaymentAsTemplate',
        };

        const superOptions = {
            ...options,
            menuCategory: 'PMTS',
            serviceName: isPayment ? 'payment/listView/smb' : 'template/listView/smb',
            serviceFunc: null,
            businessType: null,
            context: {},
            mode: 'view',
        };

        ViewItem.prototype.initialize.call(this, superOptions);
    },

    /**
     * Opens the SMB payment modal
     * @param {String} action - action being performed (ex. MODIFY)
     */
    openCombinedPaymentModal(action) {
        const options = {
            action,
            model: this.tempModel,
            contextKey: this.contextKey,
            fromDetailView: true,
            returnLinkAfterAction: this.tempModel ? this.tempModel.returnLinkAfterAction : '',
            isFromDetailScreen: this.tempModel ? this.tempModel.isFromDetailScreen : false,
            isCopyPayment: action === 'copyinst',
        };
        dialog.open(new CombinedPaymentModal(options));
    },
});
