import Layout from '@glu/core/src/layout';
import ServiceModel from 'app/administration/models/service';
import $ from 'jquery';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import locale from 'system/webseries/api/locale';
import dialog from '@glu/dialog';
import AdminUtil from 'app/administration/common/util';
import ServicesCollection from 'app/administration/collection/services';
import loadingWidgetTmpl from 'common/templates/loadingWidget.hbs';
import viewTmpl from './view.hbs';

export default Layout.extend({
    className: 'segmentation segmentation-view',
    template: viewTmpl,
    loadingTemplate: loadingWidgetTmpl,

    regions: {
        alertRegion: '.alert-region',
    },

    ui: {
        $panelTitle: '.panel-title',
        $navTabs: '[data-hook="getNavTabs"]',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
    },

    events: {},

    initialize(options) {
        this.model = options.model || new ServiceModel();
        // show basic(smb) as default
        this.state = 0;
        this.segmentName = store.get('segmentName');

        if (this.segmentName && $.trim(this.segmentName) !== '') {
            // set our segment name in the model for display
            this.model.set('NAME', this.segmentName);
        }
    },

    loadViewRequirements() {
        const self = this;
        self.collection = new ServicesCollection({
            segmentName: self.segmentName,
        });
        self.collection.fetch({
            success() {
                self.setHasLoadedRequiredData(true);
                self.render();
            },
        });
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.displaySelected();
            this.ui.$navLinks.on('click', (e) => {
                e.preventDefault();
                const path = this.$(e.target).attr('href');
                this.navigateTo(path);
            });
        } else {
            this.loadViewRequirements();
        }
    },

    servicesPromise() {
        return new Promise((resolve, reject) => {
            const servicesList = new ServicesCollection();
            servicesList.fetch({
                success() {
                    resolve(servicesList);
                },

                error: reject,
            });
        });
    },

    templateHelpers() {
        const self = this;
        return {
            businessType() {
                return store.get('segment:sophistication');
            },

            sectionGroups() {
                return self.collection.models;
            },

            hasModifyEntitlement() {
                return store.get('admin:segmentation:modify:entitlement');
            },

            hasApproveEntitlement() {
                return store.get('admin:segmentation:approve:entitlement');
            },

            hasDeleteEntitlement() {
                return store.get('admin:segmentation:delete:entitlement');
            },
        };
    },

    displaySelected() {
        const self = this;
        this.$('.panel').each((i, el) => {
            self.setDisplayTotals(self.$(el));
        });
    },

    setDisplayTotals($panel) {
        const count = $panel.find('li').length;
        let total = 0;
        const self = this;
        $panel.find('ul[data-total-field]').each((i, el) => {
            const groupTotal = +(self.$(el).data('total-field'));
            if (!util.isNaN(groupTotal)) {
                total += groupTotal;
            }
        });

        $panel.find('.service-count .count').text(count);
        $panel.find('.service-count .total').text(total);
    },

    edit() {
        store.set('segmentModify', true);
        this.navigateTo('/SEGMENTATION/addServices');
    },

    approve() {
        const self = this;

        self.model.approve({
            success() {
                store.set('admin:segmentation:approve', `${self.model.get('NAME')} ${locale.get('segment.approve.success')}`);
                self.cancel();
            },

            error(response) {
                AdminUtil.errorAlert(self.alertRegion, response);
            },
        });
    },

    delete() {
        const self = this;

        dialog.confirm(
            locale.get('segment.confirm.delete.warning'), locale.get('segment.confirm.delete.heading'),
            (ok) => {
                if (ok) {
                    self.model.delete({
                        success() {
                            store.set('admin:segmentation:delete', `${self.model.get('NAME')} ${locale.get('segment.delete.success')}`);
                            self.cancel();
                        },

                        error(response) {
                            AdminUtil.errorAlert(self.alertRegion, response);
                        },
                    });
                }
            },
        );
    },

    cancel() {
        store.unset(
            'segmentName',
            {
                silent: true,
            },
        );
        store.unset(
            'segmentModify',
            {
                silent: true,
            },
        );
        this.navigateTo('/SEGMENTATION/viewSegments');
    },
});
