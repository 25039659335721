import constants from 'common/dynamicPages/api/constants';
import store from 'system/utilities/cache';
import EntryView from 'app/payments/views/requestForPayments/rfpEntry';
import rfpTemplateEntryTmpl from './rfpTemplateEntry.hbs';

export default EntryView.extend({
    template: rfpTemplateEntryTmpl,
    initialize(options) {
        EntryView.prototype.initialize.call(this, options);
    },

    modify() {
        store.set(`${this.contextKey}-actionModel`, this.getTheModel());
        this.navigateTo(constants.ROUTE_RTP_MODIFY_REQUEST_FOR_PAYMENT_TEMPLATE_OUT);
    },

    getInsertButtons() {
        this.buttonList.push({
            action: 'SAVE',
        }, {
            action: 'SAVEDRAFT',
        });
    },

    /**
     * @method restore
     * - callback function to navigate to restore template screen from template
     * details screen
     */

    restore() {
        store.set(`${this.contextKey}-actionModel`, this.getTheModel());
        this.navigateTo(constants.URL_RFP_RESTORE_TEMPLATE);
    },
});
