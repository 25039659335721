import PropTypes from 'prop-types';
import { convertGlutoAgGrid } from '../Filters/conversion';

export const propTypes = {
  captureApis: PropTypes.func,
  /** This gets passed down to AgGrid, but is required. */
  columnDefs: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      headerName: PropTypes.string
    })
  ).isRequired,
  /**
   * An individual column can have collapsible content. If that's the case
   * then this boolean can be true. You should use GridContext in the cellRenderer
   * of that column in this case.
   */
  columnHasSubRowContent: PropTypes.bool,
  /** Adds a filter menu to each column header with filters */
  columnHeaderFilters: PropTypes.bool,
  /**
   * Function to use to convert the data.filters prop to the format used by ag grid
   * Will be passed a single argument that is the data.filters prop of this component and
   * is expected to return the filters converted to the ag grid format suitable for passing
   * to the ag grid grid api setFilterModel() function.
   */
  convertFilters: PropTypes.func,
  data: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        colId: PropTypes.string
      })
    ),
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.string.isRequired,
        criteriaDisplay: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        nameDisplay: PropTypes.string.isRequired,
        operator: PropTypes.oneOf(['AND', 'OR']),
        filterData: PropTypes.shape({}).isRequired
      })
    ),
    grid: PropTypes.shape({}),
    sort: PropTypes.arrayOf(
      PropTypes.shape({
        colId: PropTypes.string,
        sort: PropTypes.oneOf(['asc', 'desc'])
      })
    ),
    pageSize: PropTypes.number,
    page: PropTypes.number,
    savedViews: PropTypes.shape({})
  }).isRequired,

  /** Composed of column definitions from ag grid */
  defaultColDef: PropTypes.shape({}),
  /** Most of these will be produced by Ag Grid */
  defaultState: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        colId: PropTypes.string
      })
    ),
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.string.isRequired,
        criteriaDisplay: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        nameDisplay: PropTypes.string.isRequired,
        operator: PropTypes.oneOf(['AND', 'OR']),
        filterData: PropTypes.shape({}).isRequired
      })
    ),
    grid: PropTypes.shape({}),
    sort: PropTypes.arrayOf(
      PropTypes.shape({
        colId: PropTypes.string,
        sort: PropTypes.oneOf(['asc', 'dsc'])
      })
    ),
    pageSize: PropTypes.number,
    page: PropTypes.number
  }),
  /** An object of React components that will be used in cell renderer */
  frameworkComponents: PropTypes.objectOf(PropTypes.func),
  gridId: PropTypes.string.isRequired,
  gridHeight: (props, propName, componentName, ...otherProps) => {
    const hasAutoHeight = props.domLayout && props.domLayout === 'autoHeight';
    if (hasAutoHeight) {
      return null;
    }
    if (!props[propName]) {
      return new Error(
        `Invalid ${propName} value: ${componentName} requires a ${propName} of number when domLayout is not set to autoHeight`
      );
    }
    return PropTypes.number(props, propName, componentName, ...otherProps);
  },
  /** Change event called anytime a component is called. Passes data in the shape of:
   {name: '', value: '', errors: {} }
   */
  onChange: PropTypes.func,
  /** Property to call after initial view is loaded. */
  onGridReady: PropTypes.func.isRequired,
  /** records data should match column defs, but doesn't have any particular shape */
  records: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSelections: PropTypes.func,
  setVisibleItems: PropTypes.func,
  /** Optional boolean that will use api.sizeColumnsToFit to fit the column to the container
   * when true
   */
  sizeColumnsToFit: PropTypes.bool,
  suppressPaginationPanel: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  serverSidePagination: PropTypes.bool,
  /**
   * Optional component to be used as a subrow for the grid. The component will receive
   * the same props as the SubRowControl cellRender.
   */
  SubRow: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /** Row height */
  gridRowHeight: PropTypes.oneOf(['small', 'medium'])
};

export const defaultProps = {
  captureApis: null,
  columnHasSubRowContent: false,
  columnHeaderFilters: true,
  convertFilters: convertGlutoAgGrid,
  defaultColDef: {},
  defaultState: {},
  frameworkComponents: {},
  gridHeight: undefined,
  onChange() {},
  serverSidePagination: false,
  setSelections() {},
  setVisibleItems() {},
  sizeColumnsToFit: false,
  suppressPaginationPanel: true,
  SubRow: null,
  gridRowHeight: null
};
