import locale from '@glu/locale';
import fdicLogoImagePath from './fdicLogo';
import externalLinkLogoImagePath from './externalLinkLogo';

const footerTokens = {
    FDICLOGOPATH: fdicLogoImagePath,
    EXTERNALLINKPATH: externalLinkLogoImagePath,
};

const processTokens = (tokenList, input = '') => {
    let output = input;
    Object.entries(tokenList).forEach(([key, value]) => {
        output = output.replace(new RegExp(`\\{\\{${key}\\}\\}`, 'g'), value);
    });
    return output;
};

const processFooter = (...args) => {
    const options = args[args.length - 1];
    const hash = {
        fallback: ' ',
        ...(options.hash || {}),
    };

    if (hash.locale) {
        hash.label = locale.getDefault(hash.locale, hash.fallback);
    }

    return processTokens(footerTokens, hash.label);
};

export {
    footerTokens,
    processFooter,
    processTokens,
};
