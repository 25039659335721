const conversationReducer = (state, action) => {
    const {
        incorrectReasons, missingReasons, conversationItems, responses, response,
    } = action;
    const incorrectCount = (conversationItems)
        ? conversationItems.reduce((acc, item) => (item.REQUESTFORINFO_REASON_FLAG === 'I' ? acc + 1 : acc), 0)
        : 0;
    const missingCount = (conversationItems)
        ? conversationItems.reduce((acc, item) => (item.REQUESTFORINFO_REASON_FLAG === 'M' ? acc + 1 : acc), 0)
        : 0;
    const updateResponse = (resp) => {
        // if response for this seq no exists, overwrite it; otherwise add it
        const newResponses = state.responses;
        const exists = newResponses.find(r => r.seqno === resp.seqno);
        if (exists) {
            if (resp.message !== undefined) {
                exists.message = resp.message;
            }
            if (resp.internalMessage !== undefined) {
                exists.internalMessage = resp.internalMessage;
            }
            exists.noresponse = resp.noresponse;
        }
        return newResponses;
    };
    const clearResponses = () => state.responses.map(r => ({
        ...r, message: '', internalMessage: '', noresponse: false,
    }));
    switch (action.type) {
    case 'all':
        return {
            ...state,
            incorrectReasons,
            missingReasons,
            conversationItems,
            incorrectCount,
            missingCount,
            responses,
        };
    case 'newConversation':
        return {
            ...state,
            conversationItems,
            incorrectCount,
            missingCount,
            responses,
        };
    case 'firstConversation':
        return {
            ...state,
            incorrectReasons,
            missingReasons,
            conversationItems: [],
            incorrectCount: 0,
            missingCount: 0,
        };
    case 'resetConversation':
        return {
            ...state,
            conversationItems: [],
            incorrectCount: 0,
            missingCount: 0,
            responses: [],
        };
    case 'updateResponse':
        return {
            ...state,
            responses: updateResponse(response),
        };
    case 'resetResponses':
        return {
            ...state,
            responses: clearResponses(),
        };
    default:
        return state;
    }
};

export default conversationReducer;
