import Collection from '@glu/core/src/collection';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import services from 'services';
import locale from '@glu/locale';
import ScenarioModel from '../model/scenario';

const Scenario = Collection.extend({
    model: ScenarioModel,

    initialize() {
        this.nameIncrement = 0;
    },

    getDefaultName() {
        const currentNames = this.pluck('name');
        let index = 0;
        let name;

        do {
            name = `${locale.get('cashflow.activity.new')} ${index}`;
            index += 1;
        } while (currentNames.indexOf(name) > -1);

        return name;
    },

    getEnumHash() {
        const map = {};
        this.each((scenario) => {
            map[scenario.get('name')] = scenario.cid;
        });

        return map;
    },

    toJSON() {
        const json = Collection.prototype.toJSON.call(this);
        json.cid = this.cid;
        return json;
    },

    getTemplateData(id) {
        let selectedFound = false;
        const json = this.map((scenario) => {
            const data = scenario.toJSON();
            data.selected = false;
            if (data.cashFlowScenarioId === id) {
                data.selected = true;
                selectedFound = true;
            }

            return data;
        });

        json.push({
            id: null,
            name: 'Base',
            selected: !selectedFound,
        });

        return json;
    },

    parse(resp) {
        return resp;
    },

    sync(method, collection, options, ...rest) {
        if (method === 'read') {
            const self = this;
            return http.post(services.generateUrl('/cashflowScenario/list'), (data) => {
                if (options && util.isFunction(options.success)) {
                    options.success(data);
                }
            }, () => {
                self.trigger('error', self);
            });
        }
        return Collection.prototype.sync.apply(this, [method, collection, options, ...rest]);
    },

    comparator(model) {
        return model.get('name');
    },
});

export default Scenario;
