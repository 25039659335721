import ListView from 'common/dynamicPages/views/workflow/list';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import mobileGridDefaultBulkActions from 'common/dynamicPages/api/mobileGridDefaultBulkActions';
import store from 'system/utilities/cache';

const AccountSettingsList = ListView.extend({
    id: 'showAccountSettings',

    attributes: {
        role: 'tabpanel',
        tabindex: '0',
        'aria-labelledby': 'showAccountSettingsTab',
    },
    initialize(options) {
        store.set('current-workspace-route', options?.lastFragment);
        const superOptions = {
            menuCategory: 'BANK_ACC_INFO',
            serviceName: 'tableMaintenance/renameAccount',
            serviceFunc: null,
            businessType: null,
            context: 'CLIENT_ACCT_NAME',
            configContext: {
                productCode: '_ADMIN',
                functionCode: 'MAINT',
                typeCode: 'CACCT_TM',
            },
            ...options,
        };

        ListView.prototype.initialize.call(this, superOptions);
    },

    onRender() {
        ListView.prototype.onRender.call(this);
        if (this.hasLoadedRequiredData()) {
            this.$el.find('.section-header').first().hide();
            this.$el.find('.page-header-wrapper').first().hide();
        }
    },
});

// eslint-disable-next-line import/no-mutable-exports
let list = AccountSettingsList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        bulkActions: mobileGridDefaultBulkActions,
    });
    list = list.extend(mobileList);
}

export default list;
