import Collection from '@glu/core/src/collection';
import services from 'services';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import transform from 'common/util/transform';
import http from '@glu/core/src/http';

export default Collection.extend({

    initialize() {
        const userInfo = store.get('uce-load');
        const depends = [{
            name: 'UserGroup',
            value: userInfo.userGroup,
        },

        ];
        this.data = {
            rowsPerPage: 10,
            startRow: 1,
            fieldName: 'USERWITHROLELOOKUP',
            typeCode: '_USER',
            productCode: '_ADMIN',
            functionCode: 'USERS',
            depends,
            searchFields: [{
                fieldName: 'USERID',
                operator: 'CONTAINS',
                fieldValue: [
                    '',
                ],
                dataType: 'text',
            }],
            searchOrOperator: true,
        };
    },

    sync(method, model, options) {
        // Only used when copy From User from context menu or view mode.
        if (options.copyFromUser) {
            this.data.searchFields[0].fieldValue[0] = options.copyFromUser;
        }

        http.post(services.tableMaintLookup, this.data, (result) => {
            options.success(result.rows);
        }, () => {
            options.error();
        });
    },

    setFilter(filterText) {
        this.data.searchFields[0].fieldValue = [filterText];
    },

    parse(response) {
        const data = {};

        util.each(response, (rowItem) => {
            const obj = transform.pairsToHash(rowItem.columns, 'fieldName', 'fieldValue');
            const key = `${obj.UserGroup}:${obj.UserId}`;

            if (!data[key]) {
                data[key] = {
                    id: obj.UserId,
                    text: obj.UserId,
                };
            }
        });
        return util.values(data);
    },
});
