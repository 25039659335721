import { Controller } from 'backbone.marionette';

export default Controller.extend({
    initialize(options) {
        this.parentDimension = {
            left: 0,
            parentScrollLeft: 0,
            parentScrollTop: 0,
            top: 0,
            windowHeight: 0,
            windowWidth: 0,
        };
        this.frameController = options.frameController;
        this.listenTo(this.frameController, 'scroll', this.handleScroll);
    },

    handleScroll(evt) {
        this.parentDimension = evt.data.message;
    },

    findAlignment(element) {
        const offset = element.offset();
        const height = element.outerHeight();
        const width = element.outerWidth();
        const above = this.getAbove(offset.top);
        const below = this.getBelow(offset.top, height);
        const right = this.getRight(offset.left);
        const alignment = {};
        if (above > below) {
            alignment.bottom = window.innerHeight - offset.top;
        } else {
            alignment.top = offset.top + height;
        }

        if (right > offset.left) {
            alignment.left = offset.left;
        } else {
            alignment.right = window.innerWidth - (offset.left + width);
        }

        return alignment;
    },

    getAbove(offset) {
        return (this.parentDimension.top + offset) - this.parentDimension.scrollTop;
    },

    getBelow(offset, height) {
        return (this.parentDimension.windowHeight + this.parentDimension.scrollTop)
            - (offset + this.parentDimension.top + height);
    },

    getRight(offset) {
        return window.innerWidth - offset;
    },
});
