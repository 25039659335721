import services from 'services';
import { postData } from 'common/util/services';
import entitlementUtil from 'common/util/entitledActions';

export default {
    /**
     * Get allowable currencies
     * @returns {Promise}
     */
    getAllowableCurrencies() {
        return postData(
            services.inquiryQueryResults,
            {
                IncludeMapData: 1,
                queryCriteria: {
                    inquiryId: 43021,
                    action: {
                        typeCode: '*',
                        entryMethod: 0,
                        actionMode: 'SELECT',
                        productCode: '*',
                        functionCode: '*',
                    },
                    allowDuplicates: true,
                },
            },
        );
    },

    /**
     * Get entitled actions for realtime payments
     * @returns {Promise}
     */
    getEntitledActions() {
        return entitlementUtil.getEntitledActions(services.realTimePaymentsPreferences, {});
    },
};
