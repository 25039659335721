import React from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import Icon from './Icon';

const BackLink = ({ backLink }) => (backLink ? (
    <div className="page-back pull-left">
        {typeof backLink === 'function' ? (
            <button onClick={backLink} className="back-icon" title="Back">
                <Icon name="arrow-left" />
                <span className="sr-only">{locale.get('back')}</span>
            </button>
        ) : (
            <a href={backLink} className="back-icon" title="Back">
                <Icon name="arrow-left" />
                <span className="sr-only">{locale.get('back')}</span>
            </a>
        )}
    </div>
) : null);

BackLink.propTypes = {
    backLink: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

BackLink.defaultProps = {
    backLink: '',
};

export default BackLink;
