var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"combined-account-panel\">\n    <div class=\"panel panel-secondary\" role=\"tablist\">\n        <div aria-expanded=\"false\" class=\"panel-heading collapsed\" role=\"tab\" id=\"panel-heading-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":3,"column":96},"end":{"line":3,"column":103}}}) : helper)))
    + "\" data-toggle=\"collapse\" data-target=\"#combined-account-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":3,"column":159},"end":{"line":3,"column":166}}}) : helper)))
    + "\">\n            <h3 class=\"panel-title\">\n                <span class=\"indicator-icon\"></span>\n                <span class=\"account-number\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"allReports.Account",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":45},"end":{"line":6,"column":76}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"maskAccount") || (depth0 != null ? lookupProperty(depth0,"maskAccount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"maskAccount","hash":{},"data":data,"loc":{"start":{"line":6,"column":77},"end":{"line":6,"column":92}}}) : helper)))
    + "</span>\n                <span class=\"account-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":7,"column":43},"end":{"line":7,"column":51}}}) : helper)))
    + "</span>\n            </h3>\n            <span class=\"caret-indicator\" role=\"presentation\"></span>\n\n            <div>\n                <div>\n                    <ul class=\"account-summary\">\n                        <li class=\"account-presentment\">\n                            <div>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"gir.presentment1",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":33},"end":{"line":15,"column":62}}}))
    + "</div>\n                            <div class=\"account-presentment-info\">\n                                <div>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"ACH.Status",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":37},"end":{"line":17,"column":60}}}))
    + " <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"fpStatus") || (depth0 != null ? lookupProperty(depth0,"fpStatus") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fpStatus","hash":{},"data":data,"loc":{"start":{"line":17,"column":69},"end":{"line":17,"column":81}}}) : helper)))
    + "</strong></div>\n                                <div>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"allReports.Transactions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":37},"end":{"line":18,"column":73}}}))
    + " <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"fpTransactions") || (depth0 != null ? lookupProperty(depth0,"fpTransactions") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fpTransactions","hash":{},"data":data,"loc":{"start":{"line":18,"column":82},"end":{"line":18,"column":100}}}) : helper)))
    + "</strong></div>\n                                <div>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"ACH.Amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":37},"end":{"line":19,"column":60}}}))
    + " <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"fpAmount") || (depth0 != null ? lookupProperty(depth0,"fpAmount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fpAmount","hash":{},"data":data,"loc":{"start":{"line":19,"column":69},"end":{"line":19,"column":81}}}) : helper)))
    + "</strong></div>\n                            </div>\n                        </li>\n                        <li class=\"account-presentment\">\n                            <div>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"gir.presentment2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":33},"end":{"line":23,"column":62}}}))
    + "</div>\n                            <div class=\"account-presentment-info\">\n                                <div>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"ACH.Status",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":37},"end":{"line":25,"column":60}}}))
    + " <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"spStatus") || (depth0 != null ? lookupProperty(depth0,"spStatus") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"spStatus","hash":{},"data":data,"loc":{"start":{"line":25,"column":69},"end":{"line":25,"column":81}}}) : helper)))
    + "</strong></div>\n                                <div>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"allReports.Transactions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":37},"end":{"line":26,"column":73}}}))
    + " <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"spTransactions") || (depth0 != null ? lookupProperty(depth0,"spTransactions") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"spTransactions","hash":{},"data":data,"loc":{"start":{"line":26,"column":82},"end":{"line":26,"column":100}}}) : helper)))
    + "</strong></div>\n                                <div>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"ACH.Amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":37},"end":{"line":27,"column":60}}}))
    + " <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"spAmount") || (depth0 != null ? lookupProperty(depth0,"spAmount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"spAmount","hash":{},"data":data,"loc":{"start":{"line":27,"column":69},"end":{"line":27,"column":81}}}) : helper)))
    + "</strong></div>\n                            </div>\n                        </li>\n                    </ul>\n                </div>\n            </div>\n        </div>\n        <div aria-expanded=\"false\" id=\"combined-account-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":34,"column":56},"end":{"line":34,"column":63}}}) : helper)))
    + "\" class=\"panel-collapse collapse\" role=\"tab\" aria-labelledby=\"panel-heading-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":34,"column":139},"end":{"line":34,"column":146}}}) : helper)))
    + "\">\n            <div class=\"accountReportDetails\" data-region=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"account") || (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"account","hash":{},"data":data,"loc":{"start":{"line":35,"column":59},"end":{"line":35,"column":70}}}) : helper)))
    + "\"></div>\n        </div>\n    </div>\n</div>\n<div class=\"mobileDrawerPanel\"></div>\n";
},"useData":true});