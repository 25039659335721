import BalanceListView from 'app/balanceAndTransaction/views/balanceListView';
import cashPositionListViewTmpl from './cashPositionListView.hbs';

const CashPositionListView = BalanceListView.extend({
    // balanceListView without totals section
    template: cashPositionListViewTmpl,

    ui: {
        $printBtn: '[data-hook="print-button"]',
        $exportBtn: '[data-hook="export-button"]',
    },

    events: {
        'click [data-hook="export-button"]': 'customExport',
        'click [data-hook="print-button"]': 'customPrint',
    },

    onRender() {
        BalanceListView.prototype.onRender.call(this);

        if (this.context.inquiryId === 22252) {
            this.gridView.filter = false;
            this.ui.$printBtn.hide();
            this.ui.$exportBtn.hide();
        }
    },

    /**
     * @return {object} options
     * Generate the standard options object used for standard Print and Export
     * operations
     */
    getPrintOptions() {
        this.buildPrintAndExportModel();
        return {
            view: this,
            gridView: this.gridView,
            inquiryId: this.context.inquiryId,
            hasSummarySelection: false,
            exportURL: this.context.exportPrintURL,
            columns: this.exportModel.columns,
            contextData: {
                tabId: this.tabId,
                sectionId: this.sectionId,
            },
        };
    },

    customPrint() {
        this.print(this.getPrintOptions());
    },

    customExport() {
        BalanceListView.prototype.customExport.call(this);
    },

    buildPrintAndExportModel() {
        const columns = [];
        const self = this;

        this.exportModel = {};

        this.gridView.wrapper.columns.each((m) => {
            if (m.get('condition') !== false) {
                if (m.get('field') === 'ACTIONLIST') {
                    columns.push(self.gridView.wrapper.columns.fields.ACCOUNTNUMBER.fieldName);
                } else {
                    columns.push(m.attributes.field);
                }
            }
        });

        this.exportModel.columns = columns;
    },
});

export default CashPositionListView;
