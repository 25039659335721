import Collection from '@glu/core/src/collection';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';

export default Collection.extend({
    model: Model,

    initialize(models, options) {
        this.parse(options);
    },

    parse(jsonData) {
        const returnArray = [];
        const self = this;
        util.each(jsonData, (accountItem) => {
            self.add(accountItem);
            returnArray.push(accountItem);
        });

        return returnArray;
    },
});
