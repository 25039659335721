import checkFreeModelListenerUtil from 'common/util/checkFreeModelListenerUtil';

export default function (form, initialState) {
    const { model } = form.formView;
    const formState = form.formView.state;

    if (initialState) {
        // hide the fields not needed(not defined in visiblefields but they still showing)
        if (formState === 'insert') {
            form.field('BILLPAYENABLED').shouldBeVisibleWhen(false)
                .shouldBeRequiredWhen(false);
            form.field('BANKINGENABLED').shouldBeVisibleWhen(false)
                .shouldBeRequiredWhen(false);
            form.field('ELECTRONICCAPABLE').shouldBeVisibleWhen(false)
                .shouldBeRequiredWhen(false);
            form.field('ACCOUNTSTATUS').shouldBeVisibleWhen(false)
                .shouldBeRequiredWhen(false);
            form.field('POSTALCODE').shouldBeVisibleWhen(false)
                .shouldBeRequiredWhen(false);
            form.field('PROVINCENAME').shouldBeVisibleWhen(false)
                .shouldBeRequiredWhen(false);
        }

        checkFreeModelListenerUtil.setModelListeners(model, form, 'CITY', 'STATE', 'ZIPCODE');
    }
}
