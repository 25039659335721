import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import util from '@glu/core/src/util';
import { useValidation } from '@glu/validation-react';
import Layout from '@glu/layout-react';
import Alert from '@glu/alerts-react';
import useLocale from 'hooks/useLocale';
import store from 'system/utilities/cache';
import { Button, PRIMARY, SECONDARY, NORMAL } from '@glu/buttons-react';
import { ValidatedInput } from '@glu/form-components';
import ActionButtonContainer from 'components/ActionButtonContainer/ActionButtonContainer';
import useNavigation from 'hooks/useNavigation';
import FormField from 'components/FormField/FormField';
import RequiredLabelText from 'components/RequiredLabelText/RequiredLabelText';
import PageHeader from 'components/PageHeader/PageHeader';
import UserGroupSelector from 'components/UserGroupSelector/UserGroupSelector';
import validatorPatterns from 'system/validatorPatterns';
import { createPaymentGroupId, modifyPaymentGroupId } from './services';

const MAX_LENGTH = 30;
const initialData = {
    companyList: [],
    pigId: '',
    nickname: '',
};
const AddNewPaymentGroupId = ({
    contextKey,
    userGroup,
    pigId,
    status,
    nickname,
    isSelectedUserGroup,
}) => {
    const defaultFormValues = useMemo(() => {
        if (pigId) {
            return {
                companyList: [{ id: userGroup, name: userGroup }],
                pigId,
                nickname,
            };
        }
        if (userGroup) {
            return {
                ...initialData,
                companyList: [{ id: userGroup, name: userGroup }],
            };
        }
        return initialData;
    }, [pigId, nickname, userGroup]);
    const [showAlert, setShowAlert] = useState(false);

    const [ValidationProvider, validationValue, { data, validate, resetData }] = useValidation({
        data: defaultFormValues,
    });
    const { getLocaleString } = useLocale();
    const [isLoading, setIsLoading] = useState(false);
    const returnRouteUrl = useMemo(() => {
        if (userGroup && isSelectedUserGroup) {
            return `/CMAINT/totalListViewAch/${userGroup}`;
        }
        return '/CMAINT/totalListViewAch';
    }, [userGroup, isSelectedUserGroup]);
    const { cancel } = useNavigation({
        returnRoute: returnRouteUrl,
    });
    const [alertOptions, setAlertOptions] = useState({
        title: '',
        message: '',
    });
    const onContinue = () => {
        const apiCall = pigId ? modifyPaymentGroupId : createPaymentGroupId;
        validate().then(({ isValid }) => {
            if (isValid) {
                setIsLoading(true);
                apiCall(data, status).then((res) => {
                    setIsLoading(false);
                    if (!res.result) {
                        let errorMessages = '';
                        if (res.confirms) {
                            const innerErrorMessages = util.chain(res.confirms.confirmResults)
                                .map(confirmResult => confirmResult.messages)
                                .value();
                            errorMessages = innerErrorMessages[1] || innerErrorMessages[0];
                        }
                        setAlertOptions({
                            title: res.title,
                            message: errorMessages || res.message,
                        });
                        setShowAlert(true);
                        return;
                    }
                    store.set(`${contextKey}-alertMessage`, res);
                    cancel();
                }).catch(() => {
                    setIsLoading(false);
                });
            }
        });
    };
    const onReset = () => {
        resetData();
    };
    return (
        <>
            <PageHeader headingText={getLocaleString(pigId ? 'RISK.ach.pigid.modify' : 'risk.ach.pigid.maintenance')} />
            {showAlert && (
                <section className="section section-container">
                    <Alert
                        variant="error"
                        title={alertOptions.title}
                        message={alertOptions.message}
                        onHide={() => setShowAlert(false)}
                    />
                </section>
            )}
            <ValidationProvider value={validationValue}>
                <form>
                    <Layout container>
                        <Layout col={4}>
                            <FormField>
                                <UserGroupSelector
                                    labelText={(
                                        <RequiredLabelText
                                            label={getLocaleString('ACH.Company ID')}
                                        />
                                    )}
                                    name="companyList"
                                    validators={{
                                        name: getLocaleString('ACH.Company ID'),
                                        required: true,
                                    }}
                                    htmlId="companyList"
                                    disabled={!!userGroup || !!pigId}
                                    data-qa="companyList"
                                />
                            </FormField>
                        </Layout>
                    </Layout>
                    <Layout container>
                        <Layout col={4}>
                            <FormField>
                                <ValidatedInput
                                    labelText={(
                                        <RequiredLabelText
                                            label={getLocaleString('risk.pigid.id')}
                                        />
                                    )}
                                    name="pigId"
                                    validators={{
                                        name: getLocaleString('risk.pigid.id'),
                                        required: true,
                                        regexMatch: {
                                            pattern: validatorPatterns.ALPHANUMERIC_PATTERN,
                                            message: getLocaleString('validator.no.bad.characters'),
                                        },
                                    }}
                                    disabled={!!pigId}
                                    htmlId="pigId"
                                />
                            </FormField>
                        </Layout>
                    </Layout>
                    <Layout container>
                        <Layout col={4}>
                            <FormField>
                                <ValidatedInput
                                    labelText={getLocaleString('risk.pigid.nickname')}
                                    name="nickname"
                                    htmlId="nickname"
                                    maxLength={MAX_LENGTH}
                                />
                            </FormField>
                        </Layout>
                    </Layout>
                    <Layout container>
                        <Layout col={6} className="pi-group-button-group">
                            <ActionButtonContainer>
                                <div className="col-md-2 col-xs-3">
                                    <Button
                                        size={NORMAL}
                                        className="btn btn-primary"
                                        variant={PRIMARY}
                                        text={getLocaleString('ACH.save')}
                                        onClick={onContinue}
                                        aria-busy={isLoading}
                                        data-qa="saveButton"
                                    />
                                </div>
                                <div className="col-md-2 col-xs-3">
                                    <Button
                                        variant={SECONDARY}
                                        size={NORMAL}
                                        text={getLocaleString('button.cancel')}
                                        onClick={cancel}
                                        data-qa="cancelButton"
                                    />
                                </div>
                                <div className="col-md-2 col-xs-3">

                                    <Button
                                        variant={SECONDARY}
                                        size={NORMAL}
                                        text={getLocaleString('common.reset')}
                                        onClick={onReset}
                                        data-qa="resetButton"
                                    />
                                </div>
                            </ActionButtonContainer>
                        </Layout>
                    </Layout>
                </form>
            </ValidationProvider>
        </>
    );
};
AddNewPaymentGroupId.propTypes = {
    contextKey: PropTypes.string,
    userGroup: PropTypes.string,
    pigId: PropTypes.string,
    status: PropTypes.string,
    nickname: PropTypes.string,
    isSelectedUserGroup: PropTypes.bool,
};
AddNewPaymentGroupId.defaultProps = {
    contextKey: 'achControlTotalMaintenance',
    userGroup: undefined,
    pigId: undefined,
    status: undefined,
    nickname: undefined,
    isSelectedUserGroup: false,
};

export default AddNewPaymentGroupId;
