import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import services from 'services';
import helper from 'system/mfa/helper';

export default Model.extend({
    initialize() {
        this.validators = {
            oneTimePassword: {
                description: locale.get('mfa.otp_singleUseCode'),
                exists: true,
            },
        };
    },

    sendNotice(options) {
        this.sync('send', this, options);
    },

    validateMfa(options) {
        this.sync('create', this, options);
    },

    sync(method, model, options) {
        const self = this;

        let data;
        let service;

        if (method === 'send') {
            data = this.convertModelAttributesForSendMFA();
            service = services.generateUrl(`mfaService/${helper.translateUrlForLogin('initiateOutOfBand')}`);

            data.customParameters = {
                item: [{
                    name: '',
                    value: '',
                }],
            };

            http.post(service, data, (response) => {
                options.success(response);
            }, (response) => {
                try {
                    self.errorMessage = response.responseJSON.respHeader.message.join(' ');
                } catch (err) {
                    // do nothing
                }
                // Passing default App Resource incase response is null
                options.error('mfa.error.message', response);
            });
        } else if (method === 'create') {
            data = this.convertModelAttributesToServerJSON();
            service = services.generateUrl(`mfaService/${helper.translateUrlForLogin('validate')}`);

            http.post(service, data, (response) => {
                options.success(response);
            }, (response) => {
                // Passing default App Resource incase response is null
                options.error('mfa.error.message', response);
            });
        }
    },

    convertModelAttributesForSendMFA() {
        const challengeAction = new Model(this.get('challengedAction'));
        const jsonData = {
            challengedAction: challengeAction.toJSON(),
            selection: this.get('selection') ? this.get('selection').split('*')[0] : null,
            selectionType: this.get('selectionType'),

            customParameters: {
                item: [{
                    name: '',
                    value: '',
                }],
            },
        };

        if (this.has('userRequestingOnBehalfOf')) {
            jsonData.userRequestingOnBehalfOf = this.get('userRequestingOnBehalfOf');
        }

        return jsonData;
    },

    convertModelAttributesToServerJSON() {
        const challengeAction = new Model(this.get('challengedAction'));
        const jsonData = {
            challengedAction: challengeAction.toJSON(),
            oneTimePassword: this.get('oneTimePassword'),
            tokenString: '',
            tokenPassCode: '',

            customParameters: {
                item: [{
                    name: '',
                    value: '',
                }],
            },
        };
        if (this.has('userRequestingOnBehalfOf')) {
            jsonData.userRequestingOnBehalfOf = this.get('userRequestingOnBehalfOf');
        }
        return jsonData;
    },
});
