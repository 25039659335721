import locale from '@glu/locale';
import dialog from '@glu/dialog';
import Collection from '@glu/core/src/collection';
import ListView from 'common/dynamicPages/views/workflow/list';
import SwitchCell from 'components/CustomCells/SwitchCell/SwitchCell';
import { getServerValue } from 'components/CustomCells/SwitchCell/constants';
import { postData } from 'common/util/services';
import services from 'system/servicesList';
import EStatementRecipientCell from 'components/CustomCells/EStatementRecipientCell/EStatementRecipientCell';
import constants from 'common/dynamicPages/api/constants';
import BulkActionGridControl from 'components/BulkActionGridControl/BulkActionGridControl';
import ToggleAllControl from 'components/BulkActionGridControl/ToggleAllControl';
import ApplyAllEmailControl from 'components/BulkActionGridControl/ApplyAllEmailControl';
import bulkActionConstants from 'components/BulkActionGridControl/constants';
import template from './PaperlessSettingsList.hbs';
import { asView } from '../../common/util/reactUtil';
import ConsentModal from './consentModal';

const PaperlessSettingsList = ListView.extend({
    template,
    id: 'showPaperlessSettings',

    attributes: {
        role: 'tabpanel',
        tabindex: '0',
        'aria-labelledby': 'getPaperlessSettingsTab',
    },

    ui: {
        $saveButton: '[data-hook="getSaveButton"]',
        $revertButton: '[data-hook="getRevertButton"]',
    },

    initialize(options) {
        // set options for listView
        this.mobileListViewOptions = {
            insertActions: [
                {
                    label: '_ADMIN.PPRLESS.Save.Changes',
                    entitlement: 'INSERT',
                    handlerMethodName: 'save',
                },
                {
                    label: '_ADMIN.PPRLESS.Revert',
                    entitlement: 'INSERT',
                    handlerMethodName: 'revert',
                },
            ],
            useGridViewFromOptions: true,
        };
        this.skipMobileGridAlertRegion = true;
        this.mobileGridView = PaperlessSettingsList;
        this.renderMobileGridUsingTemplate = options.renderMobileGridUsingTemplate !== undefined
            ? options.renderMobileGridUsingTemplate
            : true;

        // call the parent
        const superOptions = {
            ...options,
            serviceName: 'paperlessStatements',
            menuContext: 'paperlessStatements',
            selector: 'none',
            cellViews: {
                PAPERLESS_ONOFF_UPDATED: asView(SwitchCell, {
                    readOnly: this.isReadyOnly.bind(this),
                    onChange: this.onToggleChange.bind(this),
                    negativeLabel: locale.get('_ADMIN.PPRLESS.Off'),
                    positiveLabel: locale.get('_ADMIN.PPRLESS.On'),
                }),
                ESTATEMENTRECIPIENT_UPDATED: asView(EStatementRecipientCell, {
                    readOnly: this.isReadyOnly.bind(this),
                    onBlur: this.onRowDataChange.bind(this),
                }),
            },
            rowsPerPage: constants.MAX_SERVER_ROWSPERPAGE,
            enableSavedViews: false,
            configContext: {
                productCode: '_ADMIN',
                functionCode: 'MAINT',
                typeCode: 'PPRLESS',
            },
            onGridRefreshComplete: () => {
                this.updatedRows = [];
                this.toggleRevertButton(false);
            },
        };
        ListView.prototype.initialize.call(this, superOptions);
        this.localeKey = '_ADMIN.paperlessStatements.';
        this.updatedRows = [];
    },

    /**
     * Return true when the use does not have modify entitlements
     * @returns {boolean}
     */
    isReadyOnly() {
        return !this.hasEntitlement(constants.ACTION_MODIFY);
    },

    /**
     * Update rows that currently have paperless toggled on, with the provided email
     * @param {string} email
     */
    updateVisibleEmails(email) {
        const possibleValues = ['1', true];
        this.gridView.wrapper.rows.forEach((row) => {
            const paperlessValue = row.get('PAPERLESS_ONOFF_UPDATED');
            if (possibleValues.includes(paperlessValue)) {
                /**
                 * NH-143077 Updating a large number of rows is causing performance issues.
                 * It has been isolated to the model set call, which
                 * triggers all sorts of events on the model that the grid responds too.
                 * So, set the model silently and then trigger a custom model event. The React
                 * component listens to the custom event and updates the row accordingly.
                 */
                row.set('ESTATEMENTRECIPIENT_UPDATED', email, { silent: true });
                row.trigger('email:updated', email);
            }
        });
    },

    /**
     * Updated all rows with the paperless value
     * @param {boolean} value
     */
    updateVisibleToggles(value) {
        this.gridView.wrapper.rows.forEach((row) => {
            this.trackLocalChange(value, row);
            row.set('PAPERLESS_ONOFF_UPDATED', getServerValue(value), {
                silent: true,
            });
            row.trigger('switch:toggled', value);
        });
    },

    /**
     * Submit all the rows with the new data
     * @param {boolean} value
     */
    onToggleAll(value) {
        this.updateVisibleToggles(value);
        this.toggleRevertButton(true);
        this.updateItems(this.gridView.wrapper.rows);
    },

    /**
     * Submit the array of items as bulk action update
     * @param {Array} items
     * @returns {Promise}
     */
    updateItems(items) {
        const data = this.generateMultiItemsJsonData(constants.ACTION_UPDATE, items);
        return postData(services.generateUrl('paperlessStatements/update'), data);
    },

    /**
     *
     * @param {string} fieldName
     * @param {boolean} value
     * @param {Object} model
     */
    onToggleChange(fieldName, value, model) {
        this.trackLocalChange(value, model);
        this.onRowDataChange(fieldName, getServerValue(value), model);
        /*
         * ESTATEMENTRECIPIENT cell needs to know that the switch has been
         * toggled without re-rendering the grid, so trigger a custom action
         */
        model.trigger('switch:toggled', value);
    },

    /**
     * When the value is truthy, set updatedRows with a new array, adding the item
     * Whent the value is falsy, set updatedRows with a new array, removing the item
     * @param {boolean} value
     * @param {Object} model
     */
    trackLocalChange(value, model) {
        const item = {
            ...model.toJSON(),
            id: model.cid,
        };
        if (value) {
            this.updatedRows = this.addLocalChange(this.updatedRows, item);
        } else {
            this.updatedRows = this.removeLocalChange(this.updatedRows, item);
        }
    },

    /**
     * Search through the array and find and replace the item if found,
     * otherwise, add it to the array
     * @param {Array} updatedRows
     * @param {Object} item
     */
    addLocalChange(updatedRows, item) {
        const rows = updatedRows;
        const foundIndex = rows.findIndex(row => row.id === item.id);
        if (foundIndex < 0) {
            return [
                ...rows,
                item,
            ];
        }
        rows[foundIndex] = item;
        return rows;
    },

    /**
     * Remove the item from the array when found in the array
     * @param {Array} updatedRows
     * @param {Object} item
     */
    removeLocalChange(updatedRows, item) {
        return updatedRows.filter(row => row.id !== item.id);
    },

    /**
     * When row changes, silently update the model
     * @param {string} fieldName
     * @param {string|boolean|Object} value
     * @param {Object} model
     */
    onRowDataChange(fieldName, value, model) {
        model.set(fieldName, value, {
            silent: true,
        });
        this.toggleRevertButton(true);
        this.updateItems(new Collection(model));
    },

    /**
     * Apply an email to all rows
     * @param {string} email - email to use for all
     */
    applyAll(email) {
        this.updateVisibleEmails(email);
        this.toggleRevertButton(true);
        this.updateItems(this.gridView.wrapper.rows);
    },

    onRender() {
        ListView.prototype.onRender.call(this);
        this.toggleRevertButton(false);
        if (this.hasLoadedRequiredData() && !this.isReadyOnly()) {
            const ToggleAllControlView = asView(BulkActionGridControl);
            this.toggleAllRegion.show(new ToggleAllControlView({
                controlLabel: locale.get('_ADMIN.PPRLESS.Edit.Paperless'),
                positiveLabel: locale.get('_ADMIN.PPRLESS.Paperless.On'),
                negativeLabel: locale.get('_ADMIN.PPRLESS.Paperless.Off'),
                helperText: locale.get('_ADMIN.PPRLESS.Toggle.All.Control.helper.text'),
                onToggleAll: this.onToggleAll.bind(this),
                GridControl: ToggleAllControl,
            }));
            const ApplyAllControlView = asView(BulkActionGridControl);
            this.applyAllRegion.show(new ApplyAllControlView({
                controlLabel: locale.get('_ADMIN.PPRLESS.Edit.Email'),
                onApplyAll: this.applyAll.bind(this),
                GridControl: ApplyAllEmailControl,
                childPlacement: bulkActionConstants.PLACEMENT.RIGHT,
            }));
        }
    },

    /**
     * Show the action buttons in the region
     * @param {boolean} show - is reset visible
     */
    toggleRevertButton(show) {
        if (!this.isReadyOnly()) {
            this.ui.$revertButton.toggle(show);
        }
    },

    /**
     * Show consent modal if required and call save
     */
    save() {
        if (this.updatedRows.length) {
            dialog.open(new ConsentModal({
                onAgree: this.onAgree.bind(this),
                onDecline: this.onDecline.bind(this),
            }));
        } else {
            this.submitSave();
        }
    },

    /**
     * Submit a request to the server indicating that the user
     * accepted the consent, then called submitSave
     * @returns {Promise}
     */
    onAgree() {
        return postData(services.generateUrl('paperlessStatements/consentAgreement'), {
            consentAccepted: true,
        }).then(this.submitSave.bind(this));
    },

    /**
     * Submit a request to the server indicating that the user
     * declined the consent form
     * @returns {Promise}
     */
    onDecline() {
        return postData(services.generateUrl('paperlessStatements/consentAgreement'), {
            consentAccepted: false,
        });
    },

    /**
     * Submit payload to save all the data
     * @returns {Promise}
     */
    submitSave() {
        this.toggleSaveInProgress(true);
        return postData(services.generateUrl('paperlessStatements/save'))
            .then((response) => {
                this.renderMessage('save', response);
                this.updatedRows = [];
                this.toggleSaveInProgress(false);
                this.toggleRevertButton(false);
                this.gridView.refreshGridData();
            }).catch((response) => {
                this.toggleSaveInProgress(false);
                this.handleMultiItemsError('save', response);
            });
    },

    /**
     * Set the save button as busy and disable the revert button
     * @param {boolean} busy
     */
    toggleSaveInProgress(busy) {
        this.ui.$saveButton.attr('aria-busy', busy);
        this.ui.$revertButton.prop('disabled', busy);
    },

    /**
     * Set the revert button as buy and disable the save button
     * @param {boolean} busy
     */
    toggleRevertInProgress(busy) {
        this.ui.$saveButton.prop('disabled', busy);
        this.ui.$revertButton.attr('aria-busy', busy);
    },

    /**
     * Reset the data in updatedRows collection and refresh the grid data
     * @returns {Promise}
     */
    revert() {
        this.toggleRevertInProgress(true);
        return postData(services.generateUrl('paperlessStatements/revert')).then(() => {
            this.updatedRows = [];
            this.toggleRevertInProgress(false);
            this.toggleRevertButton(false);
            this.gridView.refreshGridData();
        }).catch((response) => {
            this.toggleRevertInProgress(false);
            this.handleMultiItemsError('revert', response);
        });
    },

    templateHelpers() {
        return {
            ...ListView.prototype.templateHelpers.call(this),
            multipleStatementTypesEntitled: this.options.multipleStatementTypesEntitled,
        };
    },

    /**
     *
     * @param {string} methodName
     * @param {Array} selectedArray
     * @return {Object}
     */
    generateMultiItemsJsonData(methodName, selectedArray) {
        const requiredAttributes = [
            'ACCOUNTNUM',
            'STATEMENTTYPE',
            'PAPERLESS_ONOFF_UPDATED',
            'ESTATEMENTRECIPIENT_UPDATED',
        ];
        return {
            items: selectedArray.models.map((model) => {
                const item = requiredAttributes.map(attribute => ({
                    name: attribute,
                    value: model.get(attribute),
                }));
                return {
                    item,
                };
            }),
        };
    },

    /**
     * Call the prototype export function passing in an inquiryId
     */
    export() {
        ListView.prototype.export.call(this, {
            inquiryId: constants.INQUIRY_ID_20701,
        });
    },

    /**
     * Build the model
     * @param {string} format
     * @returns {Object}
     */
    buildExportModel(format) {
        return {
            ...ListView.prototype.buildExportModel.call(this, format),
            inquiryId: constants.INQUIRY_ID_20701,
        };
    },
});
export default PaperlessSettingsList;
