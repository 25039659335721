import util from '@glu/core/src/util';
import locale from '@glu/locale';
import EntitlementView from '../payments/assignedPermissions/entitlementView';
import entitlementViewTmpl from './entitlementView.hbs';

export default EntitlementView.extend({
    template: entitlementViewTmpl,

    /**
     * Implemented so when rolling up the checkboxes by type a fieldset tag
     * is not wrapping the type, which is the default of the base EntitlementView.
     * Instead use a div tag.
     * @return {String} html tag
     */
    tagName() {
        return this.model.get('useActions') ? 'fieldSet' : 'div';
    },

    initialize(opts) {
        this.label = this.model.label;
        this.mode = opts.mode;

        EntitlementView.prototype.initialize.call(this, opts);
    },

    templateHelpers() {
        return util.extend(
            EntitlementView.prototype.templateHelpers.call(this),
            {
                label: locale.get(this.label),
            },
        );
    },
});
