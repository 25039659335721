import React from 'react';
import locale from '@glu/locale';
import store from 'pcm/common/store';
import configuration from 'system/configuration';
import StepUserWrapper from 'pcm/common/components/StepUserWrapper';
import EntitlementProvider from 'pcm/common/entitlementContext/EntitlementProvider';
import ContentLoading from 'pcm/common/components/ContentLoading';
import { isDeepLinked } from 'common/util/deeplinkUtil';
import { checkEntitlements } from './checkManagementUtils';

const CheckManagement = React.lazy(() => import('./CheckManagement'));
const AddCheckProfile = React.lazy(() => import('./checkProfile/AddCheckProfile'));
const ViewCheckProfile = React.lazy(() => import('./checkProfile/ViewCheckProfile'));
const EditCheckProfile = React.lazy(() => import('./checkProfile/EditCheckProfile'));
const AddCheckApplication = React.lazy(() => import('./checkApplications/AddCheckApplication'));
const ApplicationSteps = React.lazy(() => import('./checkApplications/ApplicationSteps'));
const ViewCheckApplication = React.lazy(() => import('./checkApplications/ViewCheckApplication'));
const AddCheckDivision = React.lazy(() => import('./checkDivisions/AddCheckDivision'));
const ViewCheckDivision = React.lazy(() => import('./checkDivisions/ViewCheckDivision'));
const EditCheckDivision = React.lazy(() => import('./checkDivisions/EditCheckDivision'));
const CheckApplicationsList = React.lazy(() => import('./checkApplications/CheckApplicationsList'));
const CheckProfileList = React.lazy(() => import('./checkProfile/CheckProfileList'));
const CheckDivisionsList = React.lazy(() => import('./checkDivisions/CheckDivisionsList'));

const rootLink = configuration.isAdminCheckMgtApp() ? '/ui-admin-portal/ADMINSTRATION/check-management/' : `/${isDeepLinked() ? 'ui-portal' : 'ui'}/ADMINSTRATION/check-management/`;

const getActionToTitleOptions = () => ({
    profile: {
        title: {
            add: locale.get('checkManagement.newCheckProfile'),
            edit: locale.get('checkManagement.modifyCheckProfile'),
            view: locale.get('checkManagement.viewCheckProfile'),
        },
        backLink: `${rootLink}profiles`,
    },
    division: {
        title: {
            add: locale.get('checkManagement.newCheckDivision'),
            edit: locale.get('checkManagement.modifyCheckDivision'),
            view: locale.get('checkManagement.viewCheckDivision'),
        },
        backLink: `${rootLink}divisions`,
    },
    application: {
        title: {
            add: locale.get('checkManagement.newCheckApplication'),
            edit: locale.get('checkManagement.modifyCheckApplication'),
            view: locale.get('checkManagement.viewCheckApplication'),
            selectCheckProfiles: locale.get('checkManagement.selectCheckProfiles.pageTitle'),
        },
        backLink: `${rootLink}applications`,
    },
});

let userGroupSelected = null;

export default {
    pcmCheckManagementWithUser(activeTab) {
        const tabsComponents = {
            applications: CheckApplicationsList,
            profiles: CheckProfileList,
            divisions: CheckDivisionsList,
        };

        this.showPage(
            locale.get('menuitem.cm.checkMgmt'),
            <React.Suspense fallback={<ContentLoading />}>
                <EntitlementProvider entitlements={checkEntitlements}>
                    <CheckManagement tabsComponents={tabsComponents} activeTab={activeTab} />
                </EntitlementProvider>
            </React.Suspense>,
            {
                headlineText: locale.get('menuitem.cm.checkMgmt'),
            },
        );
    },

    pcmCheckManagement(activeTab) {
        const tabsComponents = {
            applications: CheckApplicationsList,
            profiles: CheckProfileList,
            divisions: CheckDivisionsList,
        };

        if (configuration.isAdminCheckMgtApp()) {
            if (userGroupSelected) {
                this.pcmCheckManagementWithUser(activeTab);
                return;
            }
            this.showPage(
                locale.get('menuitem.cm.checkMgmt'),
                <StepUserWrapper callback={(usergroup) => {
                    userGroupSelected = usergroup;
                    this.pcmCheckManagementWithUser(activeTab);
                }}
                />, {
                    headlineText: locale.get('menuitem.cm.checkMgmt'),
                },
            );
        } else {
            this.showPage(
                locale.get('menuitem.cm.checkMgmt'),
                <React.Suspense fallback={<ContentLoading />}>
                    <EntitlementProvider entitlements={checkEntitlements}>
                        <CheckManagement tabsComponents={tabsComponents} activeTab={activeTab} />
                    </EntitlementProvider>
                </React.Suspense>,
                {
                    headlineText: locale.get('menuitem.cm.checkMgmt'),
                },
            );
        }
    },

    pcmCheckManagementActions(activeTab, action) {
        const components = {
            profile: {
                add: AddCheckProfile,
                view: ViewCheckProfile,
                edit: EditCheckProfile,
            },
            application: {
                add: AddCheckApplication,
                edit: ApplicationSteps,
                view: ViewCheckApplication,
                selectCheckProfiles: ApplicationSteps,
            },
            division: {
                add: AddCheckDivision,
                view: ViewCheckDivision,
                edit: EditCheckDivision,
            },
        };

        store.activeTab = activeTab;
        store.action = action;
        store.alerts = {};

        const actionToTitleOptions = getActionToTitleOptions();

        const ComponentToShow = components[activeTab][action];

        this.showPage(
            actionToTitleOptions[activeTab].title[action],
            <React.Suspense fallback={<ContentLoading />}>
                <EntitlementProvider entitlements={checkEntitlements}>
                    <ComponentToShow action={action} tab={activeTab} />
                </EntitlementProvider>
            </React.Suspense>,
            {
                headlineText: actionToTitleOptions[activeTab].title[action],
                backLink: actionToTitleOptions[activeTab].backLink,
            },
        );
    },
};
