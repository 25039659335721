import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import CategoryPlanModel from './categoryPlanModel';

const PlanCollection = Collection.extend({
    model: CategoryPlanModel,

    initialize(models, options) {
        if (options && options.type) {
            // 'inflow' or 'outflow'
            this.type = options.type;
        }
    },

    initializeFromCategories(categories, planData) {
        const models = util.map(categories, (category) => {
            const id = category.cashFlowCategoryId;
            const planValues = planData[id];

            planValues.name = category.categoryDescription;
            planValues.categoryId = id;

            return new CategoryPlanModel(planValues);
        });

        this.reset(models);
    },
});

export default PlanCollection;
