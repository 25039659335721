import services from 'services';
import http from '@glu/core/src/http';
import PlanCollection from './plan';

const GroupPlan = PlanCollection.extend({
    initialize() {
        this.listenTo(
            this,
            {
                sync: this.handleSync,
                'change remove': this.handleChange,
            },
        );
    },

    sync(method, model, options) {
        // results won't make sense if categories haven't loaded yet
        if (method === 'read') {
            const xhr = http.post(
                services.generateUrl('cashflowPlan/summaryGroupPlan'),
                (response) => {
                    options.success(response);
                },
                (response) => {
                    options.error(response);
                },
            );

            this.trigger('request', this, xhr, options);
            return xhr;
        }
        return undefined;
    },
});

export default GroupPlan;
