import serverConfigParams from 'system/webseries/models/configurationParameters';
import { getData } from 'common/util/services';
import userInfo from 'etc/userInfo';
import services from 'services';
import cookie from 'system/cookie';

export const getToken = () => {
    const url = services.generateUrl('/security/livePersonUser');
    return getData(url);
};

const isSimulatedSession = !!cookie.get('SIMULATIONSESSIONTOKEN');

const setup = () => {
    const account = serverConfigParams.get('LivePersonAccountNumber');
    if (serverConfigParams.get('EnableLivePerson') !== 'true'
        || account === '0') {
        return;
    }
    /* eslint-disable */
    // BEGIN LivePerson Monitor
    window.lpTag = window.lpTag || {}, typeof window.lpTag._tagCount == 'undefined' ? (window.lpTag = {
        site: account || '',
        section: lpTag.section || '',
        tagletSection: lpTag.tagletSection || null,
        autoStart: lpTag.autoStart !== !1,
        ovr: lpTag.ovr || {},
        _v: '1.8.0',
        _tagCount: 1,
        protocol: 'https:',

        events: {
            bind(t, e, i) {
                lpTag.defer(() => {
                    lpTag.events.bind(t, e, i);
                }, 0);
            },

            trigger(t, e, i) {
                lpTag.defer(() => {
                    lpTag.events.trigger(t, e, i);
                }, 1);
            },
        },

        defer(t, e) {
            e == 0 ? (this._defB = this._defB || [], this._defB.push(t)) : e == 1 ? (this._defT = this._defT || [], this._defT.push(t)) : (this._defL = this._defL || [], this._defL.push(t));
        },

        load(t, e, i) {
            const n = this;
            setTimeout(() => {
                n._load(t, e, i);
            }, 0);
        },

        _load(t, e, i) {
            let n = t;
            t || (n = `${this.protocol}//${this.ovr && this.ovr.domain ? this.ovr.domain : 'lptag.liveperson.net'}/tag/tag.js?site=${this.site}`);
            const a = document.createElement('script');
            a.setAttribute('charset', e || 'UTF-8'), i && a.setAttribute('id', i), a.setAttribute('src', n), document.getElementsByTagName('head').item(0).appendChild(a);
        },

        init() {
            this._timing = this._timing || {}, this._timing.start = (new Date).getTime();
            const t = this;
            window.attachEvent ? window.attachEvent('onload', () => {
                t._domReady('domReady');
            }) : (window.addEventListener('DOMContentLoaded', () => {
                t._domReady('contReady');
            }, !1), window.addEventListener('load', () => {
                t._domReady('domReady');
            }, !1)), typeof window._lptStop == 'undefined' && this.load();
        },

        start() {
            this.autoStart = !0;
        },

        _domReady(t) {
            this.isDom || (this.isDom = !0, this.events.trigger(
                'LPT',
                'DOM_READY',
                {
                    t,
                },
            )), this._timing[t] = (new Date).getTime();
        },

        vars: lpTag.vars || [],
        dbs: lpTag.dbs || [],
        ctn: lpTag.ctn || [],
        sdes: lpTag.sdes || [],
        hooks: lpTag.hooks || [],
        ev: lpTag.ev || [],
    }, lpTag.init()) : window.lpTag._tagCount += 1;
    // END LivePerson Monitor
    /* eslint-enable */
    /**
     * NH-43924: Live Person requires a method accessible from the
     * window. This method will
     * submit an empty payload to the REST service which will return an
     * encrypted token string
     * based on the current authenticated user. Finally, that string is
     * passed into the lpCallbackMethod
     */
    window.Bottomline.extMethods.getLPAuthToken = function (lpCallbackMethod) {
        if (lpCallbackMethod) {
            getToken().then(result => lpCallbackMethod(result.jwtString))
                .catch(() => lpCallbackMethod('', 'error communicating with token generator'));
        }
    };
};

// Authentication
export const pushIdentity = (sub) => {
    if (window.lpTag) {
        window.lpTag.identities = window.lpTag.identities || [];
        window.lpTag.identities.push((callback) => {
            callback({
                iss: 'Bottomline',
                acr: 'loa1',
                sub,
            });
        });
    }
};

export default {
    postAuth() {
        if (isSimulatedSession) {
            return;
        }
        setup();
        if (serverConfigParams.get('LivePersonAuthEngage') === 'true') {
            pushIdentity(`${userInfo.get('group')}-${userInfo.get('id')}`);
        }
    },
    /**
     * When the route changes, notify live person that this is a new page
     */
    routeChange() {
        if (window.lpTag && window.lpTag.loaded) {
            window.lpTag.newPage(document.URL);
        }
    },
    preAuth() {
        if (isSimulatedSession) {
            return;
        }
        if (serverConfigParams.get('ShowLivePersonWidgetAtLogin') !== 'true') {
            return;
        }
        setup();
    },
};
