import Collection from '@glu/core/src/collection';
import services from 'services';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import SystemActionsModel from 'app/challengeManager/models/actions';

const Actions = Collection.extend({
    comparator: 'NAME',
    model: SystemActionsModel,

    initialize(options) {
        this.action = options.action;
        this.criteria = options.criteria;
    },

    parse(response) {
        response.systemActions = [];

        const setActionOnModel = (sysAction) => {
            const actionName = sysAction.feature;
            response[actionName] = sysAction.enabled;
        };

        Object.keys(response || {}).forEach((actions) => {
            if (util.isArray(response[actions]) && response[actions].length > 0 && util.has(response[actions][0], 'feature')) {
                // set each action onto the model
                util.each(response[actions], setActionOnModel);

                // hide unselected actions when viewing the record
                response[actions] = this.hideUnselectedActions(response[actions]);

                // hide unselected actions when viewing the record
                response[actions] = this.addKeyToActions(response[actions], actions);

                // do not push a category if there are no actions
                if (response[actions].length > 0) {
                    const obj = {
                        key: actions,
                        actions: response[actions],
                        allActions: util.pluck(response[actions], 'feature').join(),

                        parseKey(key) {
                            return key.replace(/[^a-z0-9]/gi, '');
                        },
                    };

                    if (this.action === 'select') {
                        obj.disableCategory = true;
                    }

                    // push each action info the array for easy rendering in the template
                    response.systemActions.push(obj);
                }
            }
        });
        return response;
    },

    getAttr(attr) {
        return this.at(0).get(attr);
    },

    addKeyToActions(sysActionsParam, parentKey) {
        const sysActions = sysActionsParam;
        for (let s = 0; s < sysActions.length; s += 1) {
            // add these here to be available in the .hbs template
            sysActions[s].parentKey = parentKey;
            sysActions[s].parseKey = key => key.replace(/[^a-z0-9]/gi, '');
        }

        return sysActions;
    },

    hideUnselectedActions(systemActionsParam) {
        const systemActions = systemActionsParam;
        if (this.action === 'select') {
            for (let x = systemActions.length - 1; x >= 0; x -= 1) {
                if (systemActions[x].enabled) {
                    systemActions[x].disableAction = true;
                } else {
                    systemActions.splice(x, 1);
                }
            }
        }
        return systemActions;
    },

    sync(method, model, options) {
        const url = services.generateUrl('/challengeManager/read');
        const data = {
            name: 'Criteria',
            value: this.criteria || '',
        };
        http.post(url, data, (result) => {
            options.success(result);
        }, (result) => {
            options.error(result);
        });
    },
});

export default Actions;
