import $ from 'jquery';
import services from 'services';

export default {
    // Inquiry Ids
    DEFAULT_INQUIRY_ID: 0,

    REPORTS_INQUIRY_ID: 17050,
    ALERTS_INQUIRY_ID: 17051,
    CHECK_MANAGEMENT_ALERT_LIST_INQUIRY_ID: 20550,

    // need to finalize value for payments alerts list.
    PAYMENTS_ALERT_LIST_INQUIRY_ID: 20560,

    CHECK_PRODUCTION_ALERT_LIST_INQUIRY_ID: 21090,
    INFORMATION_REPORTING_ALERT_LIST_INQUIRY_ID: 22300,
    ADMIN_ALERT_LIST_INQUIRY_ID: 9028,
    ALERT_GROUPS_LIST_INQUIRY_ID: 17047,
    ALERT_TYPES_LIST_INQUIRY_ID: 17046,
    STATE_CODES_LIST_INQUIRY_ID: 17097,
    COUNTRY_CODES_LIST_INQUIRY_ID: 17076,
    BANK_CODES_LIST_INQUIRY_ID: 17089,
    CURRENCY_CODES_LIST_INQUIRY_ID: 17084,
    TRANSFER_FROM_ACCOUNT_INQUIRY_ID: 19497,
    TRANSFER_TO_ACCOUNT_INQUIRY_ID: 19498,
    TRANSFER_PRODUCT_CODE: 'RTGS',
    TRANSFER_FUNCTION_CODE: 'INST',
    TRANSFER_TYPE_CODE: 'TRANSFER',
    DOMESTIC_WIRE_FROM_ACCOUNT_INQUIRY_ID: 19339,

    // Search Types
    DEFAULT_SEARCH_TYPE: 2,

    INQUIRY_CONFIGURED_SEARCH_TYPE: 2,
    ACTION_CONFIGURED_SEARCH_TYPE: 5,

    // Type Codes
    DEFAULT_TYPE_CODE: '',

    ALL_TYPE_CODE: '*',
    ADDRESS_BOOK_TYPE_CODE: 'BENEADBK',
    BANK_HOLIDAY_TYPE_CODE: 'CBANK_TM',
    SECURITY_QUESTIONS_TYPE_CODE: 'USECQ_TM',

    // View Ids
    DEFAULT_VIEW_ID: 0,

    // Column Types
    COLUMN_TYPE_HIDDEN: 6,

    COLUMN_TYPE_STRING: 0,
    COLUMN_TYPE_ACTION_LIST: 32,

    // apparently webseries has two types of action list columns.
    COLUMN_TYPE_ENTITLEMENT_ACTION_LIST: 38,

    COLUMN_TYPE_ACCESSIBILITY_ACTION_LIST: 39,
    COLUMN_TYPE_CHECKBOX: 5,

    // Query Results
    DEFAULT_QUERY_PAGE_SIZE: 0,

    DEFAULT_QUERY_OFFSET: 0,

    // Action Modes
    INSERT_ACTION_MODE: 'INSERT',

    // Function Codes
    ALERT_FUNCTION_CODE: 'ALERT',

    MAINT_FUNCTION_CODE: 'MAINT',

    // Product Codes
    DEFAULT_PRODUCT_CODE: '*',

    ADMIN_PRODUCT_CODE: '_ADMIN',
    SECURITY_PRODUCT_CODE: 'SMAINT',

    // Entry Methods
    DEFAULT_ENTRY_METHOD: 0,

    // Map Data
    INCLUDE_MAP_DATA: 1,

    EXCLUDE_MAP_DATA: 0,

    /**
     * Retrieves inquiry data based on custom filter and search parameters.
     * Ignore hardcoded user information below.  Webseries is in the process of
     * requiring a separate login
     * request before inquiry service requests can be submitted.  The requestUser
     * block will eventually go away.
     *
     * @param options
     */
    getQueryResults(options) {
        const data = {
            IncludeMapData: options.includeMapData,

            queryCriteria: {
                customFilters: options.customFilters,
                inquiryId: options.inquiryId,

                action: {
                    typeCode: options.typeCode,
                    entryMethod: options.entryMethod,
                    productCode: options.productCode,
                    actionMode: options.actionMode,
                    functionCode: options.functionCode,
                },
            },

            requestHeader: {
                requestId: options.viewId,

                requestUser: {
                    data: {
                        item: [{
                            name: 'usergroup',
                            value: options.userGroup,
                        }, {
                            name: 'password',
                            value: options.password,
                        }],
                    },

                    userId: options.userId,
                },
            },
        };

        const serialized = JSON.stringify(data);

        return $.ajax({
            contentType: 'application/json',
            url: services.inquiryQueryResults,
            type: 'POST',
            dataType: 'json',
            data: serialized,
        });
    },

    /**
     * Retrieves inquiry data including both the headers and rows based on requested
     * search type and search parameters.
     * Ignore hardcoded user information below.  Webseries is in the process of
     * requiring a separate login
     * request before inquiry service requests can be submitted.  The requestUser
     * block will eventually go away.
     *
     */
    getData(options) {
        const data = {
            inquiryRequest: {
                startRow: 1,
                rowsPerPage: 10,

                searchCriteria: {
                    searchType: options.searchType,
                    inquiryId: options.inquiryId,

                    action: {
                        typeCode: options.typeCode,
                        entryMethod: 1,
                        productCode: '_ADMIN',
                        functionCode: 'MAINT',
                        actionMode: 'SELECT',
                    },
                },
            },

            requestHeader: {},
        };

        const serialized = JSON.stringify(data);

        return $.ajax({
            contentType: 'application/json',
            url: services.inquiryData,
            type: 'POST',
            dataType: 'json',
            data: serialized,
        });
    },

    /**
     * Retrieves inquiry headers data based on requested search type and search
     * parameters
     * Ignore hardcoded user information below.  Webseries is in the process of
     * requiring a separate login
     * request before inquiry service requests can be submitted.  The requestUser
     * block will eventually go away.
     *
     */
    getHeaderData(request) {
        const serialized = JSON.stringify(request);

        return $.ajax({
            contentType: 'application/json',
            url: services.inquiryHeaderData,
            type: 'POST',
            dataType: 'json',
            data: serialized,
        });
    },

    /**
     * Retrieves inquiry rows data based on requested search type and search
     * parameters
     * Ignore hardcoded user information below.  Webseries is in the process of
     * requiring a separate login
     * request before inquiry service requests can be submitted.  The requestUser
     * block will eventually go away.
     *
     */
    getRowsData(request) {
        const serialized = JSON.stringify(request);

        return $.ajax({
            contentType: 'application/json',
            url: services.inquiryRowsData,
            type: 'POST',
            dataType: 'json',
            data: serialized,
        });
    },
};
