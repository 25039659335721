import alert from '@glu/alerts';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import util from '@glu/core/src/util';

import BaseMFAView from 'system/mfa/views/baseMFA';
import Constants from 'system/mfa/constants';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import SoftTokenRegisterModel from 'system/mfa/models/softTokenRegisterModel';

import softTokenRegisterTemplate from './softTokenRegisterTemplate.hbs';

export default BaseMFAView.extend({
    template: softTokenRegisterTemplate,
    modalClass: 'modal-xlg dialog-warning soft-token-register-modal mfa-challenge-modal',
    dialogTitle: ' ',

    initialize() {
        this.model = new SoftTokenRegisterModel();
        this.challengeType = this.options.challengeType;
        this.challengeAction = this.options.challengeAction;
        // initialize the step to start at 1
        this.step = 1;
    },

    /**
     * Programatically returns the buttons for the modal so they're in the proper place on the DOM
     * in the modals footer and can be affected by existing listeners
     */
    buttons() {
        return [{
            text: this.step === 1 ? locale.get('button.next') : locale.get('button.submit'),
            className: 'btn btn-primary btn-sm',
            callback: this.step === 1 ? util.bind(this.submitFirstStep, this)
                : util.bind(this.submitCode, this),
        }, {
            text: locale.get('button.cancel'),
            className: 'btn btn-secondary btn-sm',
            callback: util.bind(this.returnToChallenge, this),
        }];
    },

    /**
     * baseMFA.js allows for the specification of different modal buttons in the event of
     * an error, but in this case we'd like to stick to our current ones
     */
    errorStateButtons() {
        return this.buttons();
    },

    /* display the warning icon in the header of the modal */
    getHeaderIcon() {
        return 'warning';
    },

    /**
     * create the array of needed promises that will occur on load of this modal
     * @return Promise - The singular promise for all asynch dependencies on modal load
     */
    getModalPromises() {
        const self = this;
        return Promise.all([
            !(this.challengeType === Constants.ONESPAN_CHALLENGE_TYPE)
                ? new Promise((resolve, reject) => {
                    self.model.fetchRegistrationCode({
                        success: resolve,
                        error: reject,
                    });
                }) : '',
            new Promise((resolve, reject) => {
                serverConfigParams.fetch({
                    success: resolve,
                    error: reject,
                    isPreLoginMfaFetch: true,
                });
            }),
        ]);
    },

    onRender() {
        // update the title and buttons of the modal
        this.trigger('dialog:title:change', locale.get('mfa.rsa.authorization.required'));
        this.trigger('dialog:buttons:change', this.buttons());

        if (!this.hasLoadedRequiredData()) {
            // retrieve the initial scannable image
            this.loadRequiredData();
        } else if (this.step === 1) {
            // only add the validator on the first step so we don't add it more than once
            this.model.addValidator('activationCode', {
                exists: true,
                description: locale.get('mfa.rsa.onespan.deviceCode.label'),
            });
        }
    },

    /**
     * Upon completion of the first step of the mobile token registration process, submit the
     * entered data on the model and move onto the next step
     */
    submitFirstStep() {
        // clear any currently existing error messages
        this.alertRegion.close();

        // disable buttons during submission
        this.trigger('dialog:buttons:disableAll');

        const self = this;
        this.model.save(
            {},
            {
                success() {
                    self.step = 2;

                    // clear out the old activation code to allow for the new one in step 2
                    self.model.set('activationCode', '', { silent: true });

                    // update the validator to correctly give errors when the user omits data
                    self.model.validators.activationCode.description = locale.get('mfa.rsa.onespan.signature.label');

                    // re-render with new step and model attributes
                    self.render();
                },
                error: util.bind(this.handleMFAError, this),
            },
        );
    },

    /**
     * On the first render of the modal, retrieve initial model information necessary
     */
    loadRequiredData() {
        this.getModalPromises().then(([modelDataResp, configParamResp]) => {
            if (!(this.challengeType === Constants.ONESPAN_CHALLENGE_TYPE)) {
                this.model.set(modelDataResp);
                /*
                 * this model save retrieves the initial scannable image and cannot occur
                 * asynchronously with the initial promises since it depends on configuration
                 * date retrieved during that step
                 * TODO: Add a loading template to display until this call completes
                 */
                this.model.save({}, {
                    success: () => {
                        this.setHasLoadedRequiredData(true);
                        this.render();
                    },
                    error: util.bind(this.handleMFAError, this),
                });
                return;
            }

            // exit early if there are no successfully loaded details
            if (!configParamResp) {
                return;
            }

            const configParameters = configParamResp.toJSON();

            this.model.set(configParameters);
            this.model.set('challengeType', this.challengeType);
            this.model.set('challengeAction', this.challengeAction);

            /*
             * this model save retrieves the initial scannable image and cannot occur
             * asynchronously with the initial promises since it depends on configuration
             * date retrieved during that step
             * TODO: Add a loading template to display until this call completes
             */
            this.model.save({}, {
                success: () => {
                    this.setHasLoadedRequiredData(true);
                    this.render();
                },
                error: util.bind(this.handleMFAError, this),
            });
        });
    },

    serializeData() {
        // dependant upon the current step, use different text/values for the template
        if (this.step === 1) {
            return {
                scannableImageSrc: this.model.get('crontoImage'),
                stepInstructions: locale.get('mfa.rsa.onespan.cronto.1'),
                stepLabel: locale.get('mfa.rsa.onespan.token.soft.step.1'),
                inputLabel: locale.get('mfa.rsa.onespan.deviceCode'),
                inputMaxLength: 26,
            };
        }
        return {
            scannableImageSrc: this.model.get('crontoImage'),
            stepInstructions: locale.get('mfa.rsa.onespan.cronto.2'),
            stepLabel: locale.get('mfa.rsa.onespan.token.soft.step.2'),
            inputLabel: locale.get('mfa.rsa.onespan.signature'),
            inputMaxLength: 16,
        };
    },

    /**
     * Upon completion of the second step of the soft token registration modal, submit the
     * model, close the modal, and display whatever response the back end returns
     */
    submitCode() {
        // clear any currently existing error messages
        this.alertRegion.close();

        // disable buttons during submission
        this.trigger('dialog:buttons:disableAll');

        this.model.save(
            {},
            {
                success: (result) => {
                    if (result.get('respHeader').result === false) {
                        const resp = result.get('respHeader');
                        // handle error types
                        const message = resp.message.join(' ');

                        this.alertView = alert.danger(
                            message,
                            {
                                canDismiss: true,
                            },
                        );
                        this.alertRegion.show(this.alertView);
                    } else {
                        this.trigger('activationSuccess');
                        dialog.close();
                    }
                },

                error: util.bind(this.handleMFAError, this),
            },
        );
    },
});
