import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@glu/theming';

import styles from './SnackbarContainer.styles';

const SnackbarContainer = ({ classes, children, className }) => (
  <div data-qa="snackbar-container" className={classnames(className, classes.root)}>
    {children}
  </div>
);

SnackbarContainer.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string
};

SnackbarContainer.defaultProps = {
  className: ''
};

export default withStyles(styles)(SnackbarContainer);
