import Model from '@glu/core/src/model';
import transform from 'common/util/transform';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import http from '@glu/core/src/http';
import services from 'services';
import Constants from 'common/dynamicPages/api/constants';

export default Model.extend({

    idAttribute: 'UserGroup',

    defaults() {
        return {
            USERGROUP: '',
            STATUS: '',
            STATUSDESC: '',
        };
    },

    url: '/confidentialSettingsMaint',

    parse(data) {
        const attributes = transform.pairsToHash(data.item.item);
        attributes.canModify = (attributes.ACTIONLIST.indexOf('MODIFY') >= 0);
        attributes.canApprove = (attributes.ACTIONLIST.indexOf('APPROVE') >= 0);
        if (data.grids && data.grids[0] && data.grids[0].items) {
            const typeItems = data.grids[0].items.map((item) => {
                const entry = transform.pairsToHash(item.item);
                entry.description = locale.get(entry.TYPEDESC);
                return entry;
            });
            // Sort by resolved description
            attributes.types = util.sortBy(typeItems, 'description');
        }
        return attributes;
    },

    sync(method, model, options) {
        if (method === 'read') {
            const readData = {}; // Constants.URL_READ_ACTION;
            const readService = `${services.generateUrl(this.url)}/READCHILDREN`;

            if (model.get('USERGROUP')) {
                readData.item = {};
                readData.item.item = [];
                readData.item.item.push({
                    name: 'USERGROUP',
                    value: model.get('USERGROUP'),
                });
            }

            return http.post(readService, readData, (data) => {
                options.success(data);
            }, () => {});
        }
        return undefined;
    },

    toServerJSON(typeItems) {
        const attributes = util.clone(this.attributes);
        const data = {
            item: {
                item: transform.hashToPairs(util.pick(attributes, 'USERGROUP', 'STATUS', 'STATUSDESC')),
            },
        };
        if (!typeItems) {
            return data;
        }
        data.grids = [{
            name: 'confidentialsettings',
            items: typeItems.map(typeSetting => ({
                item: transform.hashToPairs({
                    TYPECODE: typeSetting.get('TYPECODE'),
                    TYPEDESC: typeSetting.get('TYPEDESC'),
                    CONFIDENTIALSETTING: typeSetting.get('CONFIDENTIALSETTING'),
                    USERGROUP: this.get('USERGROUP'),
                }),
            })),
        }];
        return data;
    },

    save(method, typeItems) {
        let service = this.url;
        if (method === 'approve') {
            service += Constants.URL_APPROVE_ACTION;
        } else {
            service += Constants.URL_UPDATE_ACTION;
        }
        const data = this.toServerJSON(typeItems);
        return http.post(services.generateUrl(service), data);
    },
});
