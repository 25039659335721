import constants from 'app/balanceAndTransaction/constants';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';

/**
 * @method isPrevDayTab
 * @param {string} tabId
 * Check if current tab is Previous day tab or not
 */
const isPrevDayTab = tabId => tabId === constants.ACCOUNT_SUMMARY.TABS.PRIOR;

/**
 * @method isCurrentDayTab
 * @param {string} tabId
 * Check if current tab is Current day tab or not
 */
const isCurrentDayTab = tabId => tabId === constants.ACCOUNT_SUMMARY.TABS.CURRENT;

/**
 * @method handleDepositAccounts
 * @param {string} tabId
 * Redirect the user to the correct Transaction Details for deposit account
 */
const handleDepositAccounts = (tabId) => {
    if (isPrevDayTab(tabId)) {
        return constants.TRANSACTION_PREVDAY_DEPOSIT_DETAIL;
    }
    if (isCurrentDayTab(tabId)) {
        return constants.TRANSACTION_CURRDAY_DEPOSIT_DETAIL;
    }
    return constants.TRANSACTION_DEPOSIT_DETAIL;
};

/**
 * @method handleCreditCardAccounts
 * @param {string} tabId
 * Redirect the user to the correct Transaction Details for credit card account
 */
const handleCreditCardAccounts = (tabId) => {
    if (isPrevDayTab(tabId)) {
        return constants.TRANSACTION_PREVDAY_CREDIT_CARD_DETAIL;
    }
    if (isCurrentDayTab(tabId)) {
        return constants.TRANSACTION_CURRDAY_CREDIT_CARD_DETAIL;
    }
    return constants.TRANSACTION_CREDIT_CARD_DETAIL;
};

/**
 * @method handleLoanAccounts
 * @param {boolean} isTieredLoan
 * @param {string} tabId
 * Redirect the user to the correct Transaction Details for loan/tiered loan
 */
const handleLoanAccounts = (isTieredLoan, tabId) => {
    if (isTieredLoan) {
        return constants.TRANSACTION_TIERED_LOAN_DETAIL;
    }
    if (isPrevDayTab(tabId)) {
        return constants.TRANSACTION_PREVDAY_LOAN_DETAIL;
    }
    if (isCurrentDayTab(tabId)) {
        return constants.TRANSACTION_CURRDAY_LOAN_DETAIL;
    }
    return constants.TRANSACTION_LOAN_DETAIL;
};

/**
 * @method redirectToAssociatedAccountType
 * @param {string} acctSelected
 * @param {string} tabId
 * Redirect the user to the correct Transaction Details page for the account
 * they have selected
 */
export default function redirectToAssociatedAccountType(acctSelected, tabId) {
    const updateMasterOnSubAccountEnabled = applicationConfigParams.getValue('TIERLOAN', 'UpdateMasterOnSubAccountEnabled') === '1';
    const accountType = acctSelected.ACCOUNTTYPE;
    /**
     * When updateMasterOnSubAccountEnabled is enabled, we will
     * treat all loans as tiered loans
     */
    const isTieredLoan = updateMasterOnSubAccountEnabled || acctSelected.ISTIEREDLOAN;

    // if DEPOSIT
    if (constants.ACCOUNT_TYPE.DEPOSIT.includes(accountType)) {
        this.navigateTo(handleDepositAccounts(tabId));
    } else if (constants.ACCOUNT_TYPE.CREDIT_CARD.includes(accountType)) {
        // if credit card
        this.navigateTo(handleCreditCardAccounts(tabId));
    } else {
        /*
         * if not then is a LOAN
         * direct to tiered loans page if needed
         */
        this.navigateTo(handleLoanAccounts(isTieredLoan, tabId));
    }
}
