import FileSaver from 'file-saver';
import { Parser } from 'json2csv';

function exportToCSV({
  columns, records, fileName, excelStrings = true
}) {
  const fields = columns.map(({
    headerName, title, field, valueFormatter
  }) => ({
    label: title || headerName,
    value: /* istanbul ignore next */ valueFormatter
      ? row => valueFormatter({ value: row[field] })
      : field
  }));
  const JSONParser = new Parser({ fields, excelStrings });
  const csv = JSONParser.parse(records);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
  FileSaver.saveAs(blob, `${fileName}.csv`);
}

function getVisiblySelected({ gridApi, exportActivePageOnly }) {
  if (gridApi) {
    if (exportActivePageOnly) {
      const visibleNodes = gridApi.getRenderedNodes();
      const selected = visibleNodes.filter(node => node.selected).map(node => node.data);
      return selected.length ? selected : null;
    }
    const selected = gridApi.getSelectedNodes()
      .map(node => node.data);
    return selected.length ? selected : null;
  }
  return null;
}

export default function exportGrid({
  columnDefs,
  exportColumnConversion,
  exportDataConversion,
  exportActivePageOnly,
  exportValuesAsStrings,
  gridApi,
  gridId,
  actionAfterExport,
  exportFileName
}) {
  const hasCheckboxes = columnDefs.some(column => column.checkboxSelection);

  // only export visible rows (takes into account filters and not just columns)
  let visibleRows = gridApi.rowModel.rowsToDisplay.map((row) => row.data);

  if (hasCheckboxes) {
    visibleRows = getVisiblySelected({ exportActivePageOnly, gridApi }) || visibleRows;
  }

  const convertedColumns = exportColumnConversion ? exportColumnConversion(columnDefs) : columnDefs;
  const convertedData = exportDataConversion ? exportDataConversion(visibleRows) : visibleRows;

  exportToCSV({
    columns: convertedColumns,
    excelStrings: exportValuesAsStrings,
    fileName: exportFileName || gridId,
    records: convertedData
  });
  if (actionAfterExport) {
    actionAfterExport();
  }
}
