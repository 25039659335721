var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"accountId") || (depth0 != null ? lookupProperty(depth0,"accountId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountId","hash":{},"data":data,"loc":{"start":{"line":7,"column":35},"end":{"line":7,"column":48}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"accountName") || (depth0 != null ? lookupProperty(depth0,"accountName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountName","hash":{},"data":data,"loc":{"start":{"line":7,"column":50},"end":{"line":7,"column":65}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\"> \n    <div class=\"col-md-6\">\n        <div class=\"widget-form-group required\">\n            <label for=\"cashflow-account\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.account",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":42},"end":{"line":4,"column":71}}}))
    + "</label>\n            <select class=\"form-control\" name=\"ACCOUNTID\" id=\"cashflow-account\" data-bind=\"model\"  aria-required=\"true\" required>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"accounts") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":8,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"ACCOUNTID\"></span>\n\n        </div>\n    </div>\n\n    <div class=\"col-md-6\">\n            <div class=\"widget-form-group required\">\n                <label for=\"cashflow-account\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.import.file",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":46},"end":{"line":17,"column":79}}}))
    + "</label>\n                <div>\n                    <button type=\"button\" class=\"btn btn-link export\"><span class=\"icon-add-circle\"></span> <span class=\"txt-label\" id=\"cash-flow-filename\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"cashFlowFilename") || (depth0 != null ? lookupProperty(depth0,"cashFlowFilename") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"cashFlowFilename","hash":{},"data":data,"loc":{"start":{"line":19,"column":156},"end":{"line":19,"column":176}}}) : helper)))
    + "</span></button>\n                    <input id=\"cash-flow-import\" class=\"btn btn-link  pull-right cash-flow-import\" type=\"file\" style=\"position: absolute; top: 0; left: 0; width: 200%; height: 200%; line-height: 4; opacity: 0;\">\n                </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});