import Glu from '@glu/core/src/glu';
import util from '@glu/core/src/util';
import alert from '@glu/alerts';
import locale from '@glu/locale';
import http from '@glu/core/src/http';
import store from 'system/utilities/cache';
import services from 'services';
import ChallengeModel from 'app/challengeManager/models/challenge';
import gridApi from 'common/dynamicPages/api/grid';
import ListView from 'common/dynamicPages/views/workflow/list';
import template from './challengeConfigListView.hbs';

const ChallengeConfigListView = ListView.extend({
    template,

    events: {
        'click [data-hook="refresh-button"]': 'refreshData',
    },

    getInsertEntitlement() {
        const self = this;
        const entitlementRequest = {
            functionCode: 'MAINT',
            actionMode: 'INSERT',
            productCode: 'SMAINT',
            typeCode: 'CHLMG_TM',
        };
        const url = services.generateUrl('accessService/hasAccess');
        return http.post(url, entitlementRequest, (response) => {
            self.insertEntitlement = response;
            self.hasLoadedEntitlements = true;
            self.render();
        }, () => {
            self.insertEntitlement = false;
            self.hasLoadedEntitlements = true;
            self.render();
        });
    },

    initialize(options) {
        const superOptions = {
            menuCategory: 'REPORTING',
            serviceName: '/challengeManager',
            serviceFunc: null,
            businessType: null,
            context: 'getListView',
        };

        this.returnUrl = Glu.Backbone.history.getFragment();
        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.gridRegion.show(this.gridView);
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
            const alertMessage = store.get('mfa:alertMessage');
            if (alertMessage) {
                this.renderMessage(store.get('mfa:alertMessage'));
            }
            store.set('mfa:alertMessage', false);
        } else {
            this.loadViewRequirements();
        }

        if (!this.hasLoadedEntitlements) {
            this.getInsertEntitlement();
        }
    },

    renderMessage(message) {
        this.alertView = alert.success(
            message,
            {
                canDismiss: true,
                animate: true,
            },
        );
        this.alertRegion.show(this.alertView);
    },

    gridRowSelect(options) {
        options.model.set('returnUrl', this.returnUrl);
        store.set('mfa:actionModel', options.model);
        this.navigateTo('MFA/viewChallenge');
        return Promise.resolve();
    },

    gridRowModify(options) {
        options.model.set('returnUrl', this.returnUrl);
        store.set('mfa:actionModel', options.model);
        this.navigateTo('MFA/modifyChallenge');
        return Promise.resolve();
    },

    gridRowDelete(options) {
        return new Promise((resolve, reject) => {
            const self = this;

            const name = options.model.get('CRITERIA_NAME');
            const challenge = new ChallengeModel({
                Criteria: name,
                id: name,
            });

            challenge.destroy({
                success() {
                    self.gridView.refreshGridData();
                    self.renderMessage(locale.get('mfa.challengeDeleted'));
                    resolve();
                },

                error(e) {
                    self.gridView.refreshGridData();
                    reject(e);
                },
            });
        });
    },

    addChallenge() {
        store.unset('mfa:actionModel');
        this.navigateTo('MFA/addChallenge');
    },

    loadViewRequirements() {
        const self = this;
        const options = this.viewOptionRequirements();

        options.selector = 'none';

        self.gridView = gridApi.createServiceGridView({
            ...options,
            enableSavedViews: false,
        });

        self.setHasLoadedRequiredData(true);
        self.listenTo(self.gridView.getRows(), 'sync', self.updateRefreshTimestamp);
        self.render();
    },

    templateHelpers() {
        return {
            hasInsertEntitlements: this.insertEntitlement,
        };
    },
});

export default ChallengeConfigListView;
