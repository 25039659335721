import util from '@glu/core/src/util';
import dialog from '@glu/dialog';
import store from 'system/utilities/cache';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import transform from 'common/util/transform';
import ListView from 'common/dynamicPages/views/workflow/list';
import template from './summaryList.hbs';

const SummaryList = ListView.extend({
    template,

    initialize(options) {
        this.menuCategory = options.menuCategory;
        this.viewUrl = options.viewUrl;
        this.returnRoute = options.returnRoute;
        this.reportSubTypeCode = options.reportSubTypeCode;
        this.summaryListRegion = options.summaryListRegion;
        this.contextDef = this.options.contextDef;
        this.additionalSearchFields = options.additionalSearchFields;
        this.mode = 'select';

        const superOptions = {
            menuCategory: this.menuCategory,
            serviceName: 'adminReporting/listView',
            serviceFunc: null,
            businessType: null,
            hideGridActionButtons: true,
            context: 'ADMIN_REPORT',
            enableSavedViews: true,
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    gridRowSelect(options) {
        const keyData = options.model.getKeyData();

        let searchFields = util.filter(this.additionalSearchFields, fld => fld.fieldName !== 'FEATURECODE' && fld.fieldName !== 'TYPECODE' && fld.fieldName !== 'MARKETSEGMENTFLAG');

        if (keyData && util.isArray(keyData.item)) {
            searchFields = searchFields.concat(util.map(keyData.item, key => ({
                fieldName: key.name,
                fieldValue: [key.value],
                dataType: 'text',
                operator: '=',
            })));
        }

        const requestParameters = {
            reportSubTypeCode: this.reportSubTypeCode,
            subTypeCode: this.subTypeCode,
            detailView: true,
        };

        const detailOptions = {
            enableSavedViews: false,
            filter: false,
            selector: 'none',
            serviceName: this.options.serviceName,
            reportSubTypeCode: this.reportSubTypeCode,
            context: this.options.context,
            additionalSearchFields: searchFields,
            requestParameters: {
                item: transform.hashToPairs(requestParameters),
            },
        };

        store.set(`${this.contextKey}-detailOptions`, detailOptions);
        this.navigateTo('REPORTING/adminReportingDetails');
        return Promise.resolve();
    },

    print() {
        const printModal = new PrintViewModal({
            exportModel: this.buildExportModel('PDF'),
            title: `${this.localeKey}title`,
            exportURL: '/adminReporting/export',
        });
        printModal.exportModel.expData = this.reportSubTypeCode;
        dialog.custom(printModal);
    },
    export(options) {
        const optionsParam = options;
        optionsParam.gridView = this.gridView;
        optionsParam.format = optionsParam.format || 'CSV';
        optionsParam.exportURL = '/adminReporting/export';
        optionsParam.exportModel = this.buildExportModel(optionsParam);
        optionsParam.exportModel.expData = this.reportSubTypeCode;
        this.doExport(optionsParam);
    },

});

export default SummaryList;
