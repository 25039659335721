import userInfo from 'etc/userInfo';
import moment from 'moment';
import locale from '@glu/locale';
import Lookup from 'app/cashflow/service/typeaheadLookup';
import EditableTableRow from 'app/bos_common/editableTable/view/editableTableRow';
import subPlanTableRowTmpl from './subPlanTableRow.hbs';

const SubPlanTableRow = EditableTableRow.extend({
    initialize(options, ...rest) {
        EditableTableRow.prototype.initialize.apply(this, [options, ...rest]);
        this.userData = options.userData;
    },

    template: subPlanTableRowTmpl,

    templateHelpers(...args) {
        const helper = EditableTableRow.prototype.templateHelpers.apply(this, args);
        helper.savedOn = this.convertFYDateToLocalDate(this.model.get('savedOn'));
        helper.status = this.model.get('status');
        if (!helper.status) {
            helper.status = locale.get('cashflow.groupplan.status.notsubmitted');
        }

        return helper;
    },

    onRender() {
        const self = this;
        const spr = this.$('.comboBox');
        spr.each((i, el) => {
            const $el = self.$(el);
            $el.comboBox({
                placeholder: $el.is('[data-filter-immediately]') ? locale.get('cashflow.select.option') : '',
                dropdownAutoWidth: true,
                minimumInputLength: $el.is('[data-filter-immediately]') ? 0 : 3,
                maximumSelectionSize: 1,
                triggerChange: true,

                initSelection(element, callback) {
                    const lookup = new Lookup();
                    const name = element.attr('name');
                    if (lookup.setTextSearchFromValue(name, element.val())) {
                        lookup.send(name).then((result) => {
                            if (result.length > 0) {
                                callback(result[0]);
                            }
                        });
                    }
                },

                query(query) {
                    const lookup = new Lookup();
                    const name = query.element.attr('name');
                    if (lookup.setSearch(name, query.term)) {
                        lookup.send(name).then((result) => {
                            query.callback({
                                results: result,
                            });
                        });
                    } else {
                        query.callback({
                            results: [],
                        });
                    }
                },
            });
        });
    },

    convertFYDateToLocalDate(dateString) {
        if (!dateString) {
            return '-';
        }
        return moment(dateString, 'DD-MMM-YY').format(userInfo.getDateFormat());
    },
});

export default SubPlanTableRow;
