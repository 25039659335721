import locale from '@glu/locale';
import errHandler from 'system/errHandler';
import API from 'app/smb/api';
import InquiryAPI from 'system/webseries/api/inquiry';
import Constants from 'app/smb/constants';
import PaymentDecisionView from 'app/smb/views/paymentDecision';
import TransferPaymentGridBaseView from 'app/smb/views/transfers/base';
import IndividualPaymentGridBaseView from 'app/smb/views/individual/base';
import EmployeesPaymentGridBaseView from 'app/smb/views/payEmployees/base';
import BusinessPaymentGridBaseView from 'app/smb/views/business/base';
import LoanAdvanceGridBaseView from 'app/smb/views/loanAdvance/base';
import CollectFromIndividualGridBaseView from 'app/smb/views/collectFromIndividual/base';
import CollectFromBusinessPaymentGridBaseView from 'app/smb/views/collectFromBusiness/base';

export default {
    sbListPaymentTypes() {
        const self = this;
        const paymentTypes = API.paymentTypes.get();

        Promise.all([paymentTypes])
            .then((results) => {
                self.showPage(locale.get('sbPayments.payments'), new PaymentDecisionView({
                    collection: results[0],
                }));
            })
            .then(null, errHandler);
    },

    sbTransferPaymentGrid() {
        const self = this;

        const data = {
            queryCriteria: {
                inquiryId: InquiryAPI.TRANSFER_FROM_ACCOUNT_INQUIRY_ID,

                action: {
                    typeCode: InquiryAPI.TRANSFER_TYPE_CODE,
                    productCode: InquiryAPI.TRANSFER_PRODUCT_CODE,
                    actionMode: InquiryAPI.INSERT_ACTION_MODE,
                    functionCode: InquiryAPI.TRANSFER_FUNCTION_CODE,
                },
            },

            requestHeader: {
                requestId: InquiryAPI.DEFAULT_VIEW_ID,
            },
        };

        const paymentTypes = API.paymentTypes.get();
        const fromAccountList = API.webseriesAccounts.get(data);
        const toAccountList = API.webseriesAccounts.get(data);
        const dates = API.dates.get('transfer');

        Promise.all([paymentTypes, fromAccountList, toAccountList, dates])
            .then((results) => {
                self.showPage(locale.get('sbPayments.newTransfersTitle'), new TransferPaymentGridBaseView({
                    paymentTypeCollection: results[0],
                    fromAccountList: results[1],
                    toAccountList: results[2],
                    dates: results[3],
                }));
            })
            .then(null, errHandler);
    },

    sbPayIndividualGrid(id) {
        const self = this;
        const paymentTypes = API.paymentTypes.get();
        const fromAccountList = API.accounts.get(API.CHECKING_AND_SAVINGS);
        const recipientList = API.beneficiaryList.get(id);
        const countryList = API.countryList.get();
        const accountTypeList = API.accountTypeList.get();
        const dates = API.dates.get('individual');
        const cutoffTimes = API.cutoffTimes.get('individual');

        Promise.all([
            paymentTypes,
            fromAccountList,
            recipientList,
            countryList,
            accountTypeList,
            dates,
            cutoffTimes,
        ]).then((results) => {
            self.showPage(locale.get('sbPayments.newPayIndividualTitle'), new IndividualPaymentGridBaseView({
                paymentTypeCollection: results[0],
                optionId: id,
                fromAccountList: results[1],
                recipientList: results[2],
                countryList: results[3],
                accountTypeList: results[4],
                dates: results[5],
                cutoffTimes: results[6],
            }));
        }).then(null, errHandler);
    },

    sbPayEmployeesGrid(id) {
        const self = this;
        const paymentTypes = API.paymentTypes.get();
        const fromAccountList = API.accounts.get(API.CHECKING_AND_SAVINGS);
        const recipientList = API.beneficiaryList.get(id);
        const countryList = API.countryList.get();
        const accountTypeList = API.accountTypeList.get();
        const dates = API.dates.get('payEmployees');
        const cutoffTimes = API.cutoffTimes.get('payEmployees');

        Promise.all([
            paymentTypes,
            fromAccountList,
            recipientList,
            countryList,
            accountTypeList,
            dates,
            cutoffTimes,
        ]).then((results) => {
            self.showPage(locale.get('sbPayments.newPayEmployeesTitle'), new EmployeesPaymentGridBaseView({
                paymentTypeCollection: results[0],
                optionId: id,
                fromAccountList: results[1],
                recipientList: results[2],
                countryList: results[3],
                accountTypeList: results[4],
                dates: results[5],
                cutoffTimes: results[6],
            }));
        }).then(null, errHandler);
    },

    sbPayBusinessGrid(id) {
        const self = this;
        const paymentTypes = API.paymentTypes.get();
        const fromAccountList = API.accounts.get(API.CHECKING_AND_SAVINGS);
        const recipientList = API.beneficiaryList.get(id);
        const countryList = API.countryList.get();
        const accountTypeList = API.accountTypeList.get();
        const adjustmentReasonList = API.adjustmentReasonList.get();
        const referenceNumberTypeList = API.referenceNumberTypeList.get();
        const dates = API.dates.get('business');
        const cutoffTimes = API.cutoffTimes.get('business');

        Promise.all([
            paymentTypes,
            fromAccountList,
            recipientList,
            countryList,
            accountTypeList,
            adjustmentReasonList,
            referenceNumberTypeList,
            dates,
            cutoffTimes,
        ]).then((results) => {
            self.showPage(locale.get('sbPayments.newPayBusinessTitle'), new BusinessPaymentGridBaseView({
                paymentTypeCollection: results[0],
                optionId: id,
                fromAccountList: results[1],
                recipientList: results[2],
                countryList: results[3],
                accountTypeList: results[4],
                adjustmentReasonList: results[5],
                referenceNumberTypeList: results[6],
                dates: results[7],
                cutoffTimes: results[8],
            }));
        }).then(null, errHandler);
    },

    sbReqLoanAdvanceGrid() {
        const self = this;
        const paymentTypes = API.paymentTypes.get();
        const fromAccountList = API.accounts.get(API.LINE_OF_CREDIT);
        const toAccountList = API.accounts.get(API.CHECKING_AND_SAVINGS);
        const dates = API.dates.get('loanAdvance');
        const cutoffTimes = API.cutoffTimes.get('loanAdvance');

        Promise.all([
            paymentTypes,
            fromAccountList,
            toAccountList,
            dates,
            cutoffTimes,
        ]).then((results) => {
            self.showPage(locale.get('sbPayments.reqLoanAdvanceTitle'), new LoanAdvanceGridBaseView({
                paymentTypeCollection: results[0],
                fromLoanList: results[1],
                toAccountList: results[2],
                dates: results[3],
                cutoffTimes: results[4],
            }));
        }).then(null, errHandler);
    },

    sbCollectFromIndividual() {
        const self = this;
        const paymentTypes = API.paymentTypes.get();
        const fromAccountList = API.accounts.get(API.CHECKING_AND_SAVINGS);
        const recipientList = API.beneficiaryList.get(Constants.COLL_FUNDS_INDIVIDUAL);
        const countryList = API.countryList.get();
        const accountTypeList = API.accountTypeList.get();
        const dates = API.dates.get('collectFromIndividual');
        const cutoffTimes = API.cutoffTimes.get('collectFromIndividual');

        Promise.all([
            paymentTypes,
            fromAccountList,
            recipientList,
            countryList,
            accountTypeList,
            dates,
            cutoffTimes,
        ]).then((results) => {
            self.showPage(locale.get('sbPayments.collectFromIndividualTitle'), new CollectFromIndividualGridBaseView({
                paymentTypeCollection: results[0],
                fromAccountList: results[1],
                recipientList: results[2],
                countryList: results[3],
                accountTypeList: results[4],
                dates: results[5],
                cutoffTimes: results[6],
            }));
        }).then(null, errHandler);
    },

    sbCollectFromBusiness() {
        const self = this;
        const paymentTypes = API.paymentTypes.get();
        const fromAccountList = API.accounts.get(API.CHECKING_AND_SAVINGS);
        const recipientList = API.beneficiaryList.get(Constants.COLL_FUNDS_BUSINESS);
        const countryList = API.countryList.get();
        const accountTypeList = API.accountTypeList.get();
        const dates = API.dates.get('collectFromBusiness');
        const cutoffTimes = API.cutoffTimes.get('collectFromBusiness');

        Promise.all([
            paymentTypes,
            fromAccountList,
            recipientList,
            countryList,
            accountTypeList,
            dates,
            cutoffTimes,
        ]).then((results) => {
            self.showPage(locale.get('sbPayments.collectFromBusinessTitle'), new CollectFromBusinessPaymentGridBaseView({
                paymentTypeCollection: results[0],
                fromAccountList: results[1],
                recipientList: results[2],
                countryList: results[3],
                accountTypeList: results[4],
                dates: results[5],
                cutoffTimes: results[6],
            }));
        }).then(null, errHandler);
    },
};
