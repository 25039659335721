const buttonReset = {
  background: 'none',
  color: 'inherit',
  border: 'none',
  padding: 0,
  font: 'inherit',
  cursor: 'pointer'
};

export default ({
  grid = { actions: {} }, typography
}) => ({
  wrapper: {
    height: ({ gridRowHeight }) => (gridRowHeight === 'small' ? 25 : gridRowHeight === 'medium' ? 32 : 48),
    display: 'flex',
    alignItems: 'center'
  },

  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 25,
    '&:hover $dot': {
      backgroundColor: grid.actions.dotHoverBackground,
      '& svg': {
        fill: grid.actions.dotHoverFill
      }
    }
  },

  dot: {
    display: 'inherit',
    borderRadius: '50%',
    '& svg': {
      height: 25,
      width: 25,
      fill: grid.actions.dotFill
    }
  },

  dotActive: {
    backgroundColor: grid.actions.dotHoverBackground,
    '& svg': {
      fill: grid.actions.dotHoverFill
    }
  },

  button: {
    background: 'transparent',
    margin: [0, 5],
    marginTop: ({ gridRowHeight }) => (gridRowHeight === 'small' ? 0 : gridRowHeight === 'medium' ? 4 : 10),
    '& svg': {
      height: '18px',
      width: '18px',
      fill: grid.actions.caretDownFill
    },
    ...buttonReset,
    display: 'flex'
  },

  popover: {
    padding: 0
  },
  dropDown: {
    display: 'flex',
    flexDirection: 'column',
    padding: [10, 0],
    top: -5,
    background: grid.whiteBackgroundColor, // do not revert, it should be white!
    zIndex: 100,
    fontFamily: typography.fontFamily,
    '& $dropDownButton': {
      ...buttonReset,
      ...grid.actions.typography,
      padding: [0, 14, 0],
      color: grid.actions.buttonColor,
      textDecoration: `none solid ${grid.actions.buttonTextDecorationColor}`,
      textAlign: 'left',
      fontSize: grid.actions.dropdownFontSize,
      '&:hover': {
        background: grid.actions.buttonHoverBackground
      }
    }
  },

  dropDownButton: {},

  actionButton: {
    ...buttonReset,
    fontSize: 14,
    lineHeight: '14px',
    color: grid.actions.buttonColor
  }
});
