import React, {
  useCallback, useEffect, useState, useMemo, useReducer, useImperativeHandle
} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import FilterActions from '../ApplyFilterActions/FilterActions';
import { styles } from './Filters.styles';
import Filterable from './Filterable';
import { combineFilters } from './utils';

export function FiltersComponent({
  classes,
  data,
  filterables,
  filtersRef,
  htmlId,
  modifyFilters,
  onCancel,
  onChange,
  ...props
}) {
  const { filters: dataFilters } = data;
  const [filters, updateFilters] = useReducer(combineFilters, dataFilters || []);
  const [appliedFilters, setAppliedFilters] = useState(dataFilters);

  useEffect(() => {
    updateFilters({ reset: dataFilters });
    setAppliedFilters(dataFilters);
  }, [dataFilters]);

  useImperativeHandle(filtersRef, () => ({
    resetFilters() {
      updateFilters({ reset: appliedFilters });
    }
  }));

  const handleChange = useCallback((name, filter) => {
    updateFilters({ filter, name });
  }, []);

  const handleClear = useCallback((name) => {
    updateFilters({ name });
  }, []);

  const handleCancel = () => {
    updateFilters({ reset: appliedFilters });
    onCancel();
  };

  const apply = () => {
    onChange({
      errors: {},
      name: 'filters',
      value: filters
    });
    setAppliedFilters(filters);
  };

  const modifiedFilterables = useMemo(() => modifyFilters(filterables),
    [modifyFilters, filterables]);

  return (
    <form
      className={classes.filtersWrapper}
      onSubmit={(e) => {
        e.preventDefault();
        apply();
      }}
    >
      <div className={classes.filterableWrapper}>
        {modifiedFilterables.map((filterable) => (
          // Move this separate component.
          // https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
          <Filterable
            classes={classes}
            filterable={filterable}
            filters={filters}
            handleClear={handleClear}
            handleChange={handleChange}
            htmlId={htmlId}
            key={filterable.field}
            {...props}
          />
        ))}
      </div>

      <FilterActions type="submit" cancel={handleCancel} />
    </form>
  );
}

FiltersComponent.propTypes = {
  /** Css classes provided by theme */
  classes: PropTypes.objectOf(PropTypes.string).isRequired,

  /** Data object describing filters */
  data: PropTypes.shape({
    filters: PropTypes.arrayOf(PropTypes.shape({
      criteriaDisplay: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      filterData: PropTypes.shape({}).isRequired,
      id: PropTypes.string.isRequired,
      nameDisplay: PropTypes.string.isRequired,
      operator: PropTypes.oneOf(['AND', 'OR'])
    }))
  }).isRequired,

  /** Array of filter types. Determines what filter is shown.
   * Types include:
   * data
   * enum
   * multiselect
   * string (default)
  */
  filterables: PropTypes.arrayOf(PropTypes.shape({
    dateFormat: PropTypes.string,
    field: PropTypes.string.isRequired,
    headerName: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['date', 'enum', 'multiselect', 'number', 'string', 'typeahead', 'range'])
  })),

  filtersRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),

  /** id used for qa attributes and label ids */
  htmlId: PropTypes.string.isRequired,

  /** Sorting function to display filters. */
  modifyFilters: PropTypes.func,

  /** Called when `Cancel` is clicked  */
  onCancel: PropTypes.func,

  /** Change handler called when `Apply` is selected */
  onChange: PropTypes.func.isRequired
};

FiltersComponent.defaultProps = {
  filterables: [],
  filtersRef: () => {},
  modifyFilters: (data) => data,
  onCancel: () => {}
};

export default withStyles(styles)(FiltersComponent);
