import InquiryApi from 'system/webseries/api/inquiry';
import ParentAlert from './alertParent';

export default ParentAlert.extend({
    initialize(opts) {
        ParentAlert.prototype.initialize(opts);
        this.inquiryId = opts.inquiryId
            || InquiryApi.INFORMATION_REPORTING_ALERT_LIST_INQUIRY_ID;
    },
});
