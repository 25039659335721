import { Layout, locale, util } from '@glu/core';
import EndDate from '../date/endDate';
import template from './endBy.hbs';

export default Layout.extend({
    template,

    ui: {
        $endBySelectedDate: '.js-end-by-selected-date',
        $occurrencesInput: '.js-occurrences-input',
        $noEndRadio: '[title="period-no-end"]',
        $occurrencesRadio: '[title="period-and-after-occurrences"]',
        $dateRadio: '[title="period-end-by-date"]',
    },

    regions: {
        endByDatepicker: '.js-region-scheduler-ends-end-by-date-picker',
    },

    modelEvents: {
        'change:ends': 'handleFrequencyChange',
    },

    events: {
        'change @ui.$noEndRadio': 'setEndsValue',
        'change @ui.$occurrencesRadio': 'setEndsValue',
        'change @ui.$dateRadio': 'setEndsValue',
        'keyup @ui.$occurrencesInput': 'setOccurrencesInput',
    },

    initialize() {
        if (util.isUndefined(this.model.get('ends'))) {
            this.model.set('ends', false);
        }
    },

    templateHelpers() {
        return {
            cid: this.model.cid,
            endDateRequired: this.model.get('endDateRequired'),
        };
    },

    onShow() {
        this.selectRadioButton();
        this.showDatePicker();
    },

    selectRadioButton() {
        // manually select the occurrences or date radio buttons, as they don't hold any value
        const value = this.model.get('ends');

        if (util.isBoolean(value)) {
            this.ui.$occurrencesInput.prop('disabled', true);
            this.$('.js-end-by-datepicker').prop('disabled', true);
            return this.ui.$noEndRadio.prop('checked', true);
        }

        if (util.isNumber(+value) && !Number.isNaN(+value)) {
            this.ui.$occurrencesInput.prop('disabled', false);
            this.ui.$occurrencesInput.val(+value);
            return this.ui.$occurrencesRadio.prop('checked', true);
        }

        if (util.isString(value)) {
            return this.ui.$dateRadio.prop('checked', true);
        }
    },

    showDatePicker() {
        this.endByDatepicker.show(new EndDate({
            readOnly: this.options.readOnly,
            model: this.model,
            endDate: this.options.endDate,
            autoUpdateInput: this.options.autoUpdateInput,
            minDate: this.options.minDate,
        }));
    },

    handleFrequencyChange() {
        const value = this.model.get('ends');

        if (util.isBoolean(value) || value === 'false') {
            this.ui.$occurrencesInput.prop('disabled', true);
            this.$('.js-end-by-datepicker').prop('disabled', true);
        } else if (util.isNumber(value)) {
            this.ui.$occurrencesInput.prop('disabled', false);
            this.$('.js-end-by-datepicker').prop('disabled', true);
            this.setOccurrencesInput();
        } else {
            this.$('.js-end-by-datepicker').prop('disabled', false);
            this.ui.$occurrencesInput.prop('disabled', true);
        }
    },

    setEndsValue(e) {
        const value = this.$(e.currentTarget).val();

        if (value === 'noEnd') {
            this.removeEndsNumberError();
            return this.model.set('ends', false);
        }

        if (value === 'occurrences') {
            this.model.set('ends', +this.ui.$occurrencesInput.val());
            return this.ui.$occurrencesInput.prop('disabled', false);
        }

        if (value === 'date') {
            this.removeEndsNumberError();
            this.model.trigger('set:endDateValue');
        }
    },

    setOccurrencesInput() {
        const value = +this.ui.$occurrencesInput.val();

        if (!util.isNumber(value) || Number.isNaN(value) || value < 0) {
            this.model.set('error', {
                endsNumber: [locale.get('ACH.bulkupdate.invalidNumber')],
            });

            // unset the value as if user types in 32-4 then 32 get's saved,
            // do silently so the occurence input fields won't be disabled
            this.model.unset('ends', {
                silent: true,
            });

            return this.model.trigger('invalid');
        }

        this.model.set('ends', value);

        this.removeEndsNumberError();
    },

    removeEndsNumberError() {
        // necessary (and nasty) workaround so the error message disappears when user enters the value
        this.model.set('endsNumber', ' ', {
            changeFromBinding: true,
        });

        this.model.unset('endsNumber');
    },
});
