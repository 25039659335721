import React from 'react';
import PropTypes from 'prop-types';
import { Button, TERTIARY, EXTRA_SMALL } from '@glu/buttons-react';
import { ValidatedInput } from '@glu/form-components';
import { useValidation } from '@glu/validation-react';
import { withStyles } from '@glu/theming';
import styles from './ApplyAllEmailControl.styles';
import useLocale from '../../hooks/useLocale';

const propTypes = {
    onApplyAll: PropTypes.func.isRequired,
    toggleControl: PropTypes.func.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};
const MAX_LENGTH = 254;

const ApplyAllEmailControl = ({
    onApplyAll,
    toggleControl,
    classes,
}) => {
    const { getLocaleString } = useLocale();
    const [ValidationProvider, validationValue, { data, validate }] = useValidation();
    const onBlur = () => {
        validate();
    };
    const applyAll = () => {
        validate().then(({ isValid }) => {
            if (isValid) {
                toggleControl(false);
                onApplyAll(data.email);
            }
        });
    };
    return (
        <ValidationProvider value={validationValue}>
            <p className={classes.helpText}>{getLocaleString('_ADMIN.PPRLESS.Apply.All.Email.helper.text')}</p>
            <div className={classes.wrapper}>
                <ValidatedInput
                    className={classes.input}
                    name="email"
                    onBlur={onBlur}
                    maxLength={MAX_LENGTH}
                    validators={{
                        emailAddress: true,
                        name: getLocaleString('_ADMIN.PPRLESS.Email.Address'),
                        required: true,
                        maxLength: MAX_LENGTH,
                    }}
                    value={data.email}
                    placeholder={getLocaleString('_ADMIN.PPRLESS.Email.Address.placeholder')}
                />
                <Button
                    className={classes.button}
                    variant={TERTIARY}
                    size={EXTRA_SMALL}
                    text={getLocaleString('_ADMIN.PPRLESS.Apply')}
                    onClick={applyAll}
                />
            </div>
        </ValidationProvider>
    );
};
ApplyAllEmailControl.propTypes = propTypes;
export default withStyles(styles)(ApplyAllEmailControl);
