import Backbone from 'backbone';
import Marionette from 'backbone.marionette';
import ItemView from './itemView';
import appBus from './appBus';

export default Marionette.Controller.extend({
  constructor() {
    Marionette.Controller.prototype.constructor.apply(this, Array.prototype.slice.call(arguments));
  },

  appBus,

  getLoadingView() {
    if (this.loadingView) {
      return this.loadingView;
    }

    this.loadingView = ItemView.extend({
      className: 'glu-content-loading',
      template() {
        return '<div class="loader"></div>';
      }
    });

    return this.loadingView;
  },

  showPage(title, view, options) {
    // TODO: Title is currently optional. This will be removed soon.
    if (typeof title !== 'string') {
      options = view;
      view = title;
      title = null;
    }

    options = options || {};

    const { fragment } = Backbone.history;

    // in case of reloading the page after session expiration
    // to prevent double binding of view events
    if (this.lastFragment === fragment && this.pendingPage) {
      this.pendingPage.close();
    }

    this.lastFragment = fragment;

    // Stop listening for any previous page to finish
    if (this.pendingPage) {
      this.stopListening(this, `showPage:${this.pendingPage.cid}`);
      this.pendingPage = null;
    }

    // If this page is to be shown immediately (the default action), then do so
    if (typeof options.immediate === 'undefined' || options.immediate) {
      return this.appBus.trigger('show:page', view, title, options);
    }

    // Show loading
    const Loading = this.getLoadingView();
    this.appBus.trigger('show:page', new Loading(), 'Loading', options);

    this.pendingPage = view;

    this.listenTo(this, `showPage:${this.pendingPage.cid}`, () => {
      this.appBus.trigger('show:page', this.pendingPage, title, options);
    });

    return () => {
      this.trigger(`showPage:${this.pendingPage.cid}`);
    };
  }
});

