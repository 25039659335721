import util from '@glu/core/src/util';
import constants from 'common/dynamicPages/api/constants';
import PrintExportUtil from 'common/util/printExportUtil';
import store from 'system/utilities/cache';
import ListView from 'common/dynamicPages/views/workflow/list';
import gridApi from 'common/dynamicPages/api/grid';
import entitlements from 'common/dynamicPages/api/entitlements';
import helpPageUtil from 'common/util/helpPage';
import errorHandlers from 'system/error/handlers';
import template from './listDecision.hbs';

export default ListView.extend({
    template,

    events: util.extend(
        {},
        ListView.prototype.events,
        {
            'click [data-hook="print-button"]': 'showPrintOptionsModal',
        },
    ),

    initialize(options) {
        const superOptions = this.getInitializeOptions(options);

        if (options.viewAll) {
            this.viewAll = true;
            store.set('cm_listView_payDecision-viewAll', true);
        } else {
            const rowModel = this.getRowModel(options);
            this.ACCOUNT_NUMBER = rowModel.get('ACCOUNT_NUMBER');
            this.ACCOUNT_TITLE = rowModel.get('ACCOUNT_TITLE');
            this.TYPEDESC = rowModel.get('TYPEDESC');
            this.columnsToBeHidden = ['USERGROUP', 'USERGROUPNAME', 'ACCOUNT_NUMBER', 'ACCOUNT_TITLE'];
        }

        // make sure we set the return route if available
        if (superOptions.returnRoute) {
            store.set('current-workspace-route', superOptions.returnRoute);
        }

        superOptions.configContext = {
            productCode: this.getProductCode(),
            functionCode: this.getFunctionCode(),
            typeCode: this.getTypeCode(),
        };
        superOptions.lvcAlwaysReset = true;

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));

        this.contextDef.productCode = this.getProductCode();
        this.contextDef.functionCode = this.getFunctionCode();
        this.contextDef.typeCode = this.getTypeCode();
        this.contextDef.menuContext = this.getMenuContext();
        this.enableViewChecks = (superOptions.enableViewChecks !== undefined)
            ? superOptions.enableViewChecks : true;
    },

    /**
     * Gets the product code
     * @abstract
     * @return {string} the product code
     */
    getProductCode() {},

    /**
     * Gets the function code
     * @abstract
     * @return {string} the function code
     */
    getFunctionCode() {},

    /**
     * Gets the type code
     * @abstract
     * @return {string} the type code
     */
    getTypeCode() {},

    /**
     * Gets the menu context
     * @abstract
     * @return {string} the menu context string
     */
    getMenuContext() {},

    /**
     * Creates a options object for the parent
     * @abstract
     * @param {object} [options] - an options object
     */
    getInitializeOptions() {},

    getReturnPath() {},

    viewChecks() {
        const recs = this.gridView.grid.getSelectedRowModels();

        if (recs.length > 0) {
            util.each(recs, (model) => {
                model.set('_mode', 'SELECT');
            });
            store.set(`${this.contextKey}-actionModels`, recs);
            this.getReturnPath();
        }
    },

    /**
     * Gets the row model that was clicked on from previous page
     * @abstract
     * @param {object} [options] - an options object
     * @return {Model} the row model
     */
    getRowModel() {},

    /**
     * @abstract
     * @return an array of fields to send to the server
     */
    getFieldDataToSendToServer() {

    },

    /**
     * Returns the inquiry id used by this grid
     * Used by print and export
     * @abstract
     * @return {number} the inquiry id
     */
    getInquiryId() {},

    /**
     * Returns to the previous page
     */
    back() {
        this.navigateTo(this.options.returnRoute);
    },

    /**
     * @method loadViewRequiremnts
     * @description gets the entitlment & helpPage
     */
    loadViewRequirements() {
        const options = this.viewOptionRequirements();
        this.gridViewOptions = options;
        this.gridView = gridApi.createServiceGridView(options);
        const entitlementPromise = entitlements.getEntitlements(options);
        const helpPagePromise = helpPageUtil.getHelpPagePromise({
            productCode: 'CM',
            functionCode: 'BATCH',
            typeCode: '*',
            mode: 'SELECT',
        });

        ListView.prototype.setupGridAvailableListener.call(this);

        return Promise.all([helpPagePromise, entitlementPromise]).then((results) => {
            if (!util.isEmpty(results[0])) {
                store.set('helpPage', results[0].helpPage);
            }
            return this.entitlementPromiseSuccess(results[1]);
        }, util.bind(errorHandlers.loading, this));
    },

    shouldEnableViewChecks() {
        return !!this.enableViewChecks;
    },

    templateHelpers() {
        const self = this;
        const obj = ListView.prototype.templateHelpers.call(this);
        return util.extend(
            obj,
            {
                hideFields: self.options.hideFields,

                hasReturnEntitlement() {
                    return self.hasEntitlement(constants.ACTION_RETURN);
                },

                hasPayEntitlement() {
                    return self.hasEntitlement(constants.ACTION_PAY);
                },

                hasPayIssueEntitlement() {
                    return self.hasEntitlement(constants.ACTION_PAYISSUE);
                },

                enableViewChecks() {
                    return self.shouldEnableViewChecks();
                },

                accountName() {
                    return self.ACCOUNT_TITLE.replace('&amp;', '&');
                },

                accountNumber() {
                    return self.ACCOUNT_NUMBER;
                },

                isHistory() {
                    return !!self.viewAll;
                },

                type() {
                    return self.TYPEDESC;
                },

                gridUtilityOptions: {
                    hasRefresh: false,
                },
            },
        );
    },

    saveSingleRecord(options) {
        const self = this;

        options.model.update({
            success(m, resp) {
                self.gridView.refreshGridData();
                self.renderMessage(options.action, resp);
            },

            error(m) {
                self.gridView.refreshGridData();
                self.renderMessage(options.action, m.error);
            },
        });
    },

    /**
     * Updates the models of selected rows with the provided object key/values
     * @param {object} values - a object containing key/value pairs to set on
     * the models
     *
     */
    updateModelOfSelectedRows(values) {
        const fieldData = this.getFieldDataToSendToServer();
        const rows = this.gridView.grid.getSelectedRows();

        for (let i = 0; i < rows.length; i += 1) {
            const model = this.gridView.wrapper.rows.get(rows[i]);
            model.fieldData = fieldData;
            model.set(values);
        }
    },

    /**
     * Get print options for this list
     * @returns {Object}
     */
    getPrintOptions() {
        return {
            inquiryId: this.getInquiryId(),
            summaryInquiryId: this.getInquiryId(),
            hasSummarySelection: false,
        };
    },

    export() {
        this.handleExportPrint(false);
    },

    handleExportPrint(isPrint) {
        this.gridView.context.productCode = this.getProductCode();
        this.gridView.context.functionCode = this.getFunctionCode();
        this.gridView.context.typeCode = this.getTypeCode();
        this.gridView.context.actionMode = 'SELECT';
        const contextDataValues = {};
        if (!util.isUndefined(this.ACCOUNT_NUMBER)) {
            contextDataValues.AccountNumber = this.ACCOUNT_NUMBER;
        }
        if (!util.isUndefined(this.ACCOUNT_TITLE)) {
            contextDataValues.AccountName = encodeURIComponent(this.ACCOUNT_TITLE);
        }

        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            inquiryId: this.getInquiryId(),
            format: isPrint ? 'PDF' : 'CSV',
            contextData: contextDataValues,
        };

        if (isPrint) {
            PrintExportUtil.print(options);
        } else {
            PrintExportUtil.export(options);
        }
    },

    viewOptionRequirements(...args) {
        const listOptions = ListView.prototype.viewOptionRequirements.call(this, args);
        listOptions.columnsToBeHidden = this.columnsToBeHidden;
        return listOptions;
    },

    /**
     * @method onClose
     * @description - method that is invoked when the view is closed.
     * If we are not a batch child view, then unset the helpPage that is used for
     * the global help.
     *
     */
    onClose() {
        store.unset('helpPage'); // remove view helppage from cache
    },

    /**
     * @method isRowSelected
     * Helper method to check if any grid rows were selected
     * @returns {boolean}
     */
    isRowSelected() {
        return !!this.gridView.grid.getSelectedRows().length;
    },
});
