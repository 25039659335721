import Layout from '@glu/core/src/layout';
import CollectionView from '@glu/core/src/collectionView';
import util from '@glu/core/src/util';
import $ from 'jquery';
import store from 'system/utilities/cache';
import SearchFieldsUtil from 'common/util/searchFieldsUtil';
import LockboxReportCollection from 'app/reports/collections/lockboxReport';
import LockboxPanel from 'app/reports/views/lockbox/lockboxPanel';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import lockboxReportGroupTypeTmpl from 'app/reports/views/lockbox/lockboxReportGroupType.hbs';

export default Layout.extend({
    template: lockboxReportGroupTypeTmpl,
    loadingTemplate: loadingPageTmpl,
    className: 'lockbox-report-group-type',

    ui: {
        $primaryPanel: '.panel-primary > .panel-heading',
        $secondaryPanel: '.panel-secondary',
        $heading: '.panel-heading',
        $switch: '.view-switcher .btn',
        $date: '[name="VALUE_DATE"]',
    },

    events: {
        'click @ui.$switch': 'toggleReportView',
        'click @ui.$primaryPanel': 'toggleLocation',
        'click @ui.$secondaryPanel': 'toggleLockbox',
    },

    initialize(options) {
        this.depositDates = options.dates;
        this.isDefaultView = options.defaultView;
        this.selectedLockboxIds = options.selectedLockboxIds;
        this.selectedTypeNames = options.selectedTypeNames;
        this.types = util.chain(this.selectedTypeNames)
            .pluck('value')
            .uniq()
            .value();
        this.batchIDs = {};
        this.reportFilters = {};
        this.grids = {};
        this.singleTransactionType = {};

        this.setNotificationData({
            title: 'lockbox Reporting',
        });
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.openReportSelections();
        } else {
            this.loadRequiredData();
        }
    },

    /**
     * @method getLockboxReportRequest
     * @param locationIds -  An array of lockbox location IDs used when selecting
     * a lockbox location
     * @param inquiryId - An int value used to retrieve the list of lockboxes
     */
    getLockboxReportRequest(locationIds, inquiryId) {
        const dateRangeField = SearchFieldsUtil.createDateRangeSearchField('DEPOSIT_DATE', [this.depositDates.minUnFormatted, this.depositDates.maxUnFormatted]);
        const typesField = SearchFieldsUtil.createTextInSearchField('ITEM_TYPE_CODE', this.types);
        const lockboxesField = SearchFieldsUtil.createNumericInSearchField('LOCKBOX_ID', this.selectedLockboxIds);
        const locationField = locationIds ? SearchFieldsUtil.createTextInSearchField('LOCATION_ID', locationIds) : {};

        const request = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    action: {
                        typeCode: 'LBX_RPT',
                        productCode: 'GIR',
                        functionCode: 'LOCKBOX',
                        actionMode: 'SELECT',
                    },

                    searchFields: [
                        dateRangeField,
                        typesField,
                        lockboxesField,
                    ],

                    searchType: '5',
                    inquiryId: inquiryId || 22600,
                },

                startRow: 1,
                rowsPerPage: 1000,
            },
        };

        if (locationIds) {
            request.inquiryRequest.searchCriteria.searchFields.push(locationField);
        }

        return request;
    },

    loadRequiredData() {
        const self = this;

        this.lockboxReport = new LockboxReportCollection(this.getLockboxReportRequest());
        this.lockboxReport.fetch({
            success(collection) {
                self.lockboxLocationsData = collection.toJSON();
                self.setHasLoadedRequiredData(true);
                self.inquiryId = 22600;
                self.render();
                self.notifyPageLoaded();
            },
        });
    },

    /**
     * @method toggleLocation
     * @param e
     * - With the Location id retrieve the complete list of lockbox IDs selected
     * by the user from the flexModel
     */
    toggleLocation(e) {
        const self = this;
        const selectedLocationCode = e.currentTarget.id;
        const allLocations = this.options.flexModel.data;

        const locationId = util.chain(allLocations)
            .filter(location => location.name.replace(/ /g, '') === selectedLocationCode)
            .map(location => location.id).value();

        const collectionData = this.getLockboxReportRequest(locationId, 22605);
        this.lockboxReport = new LockboxReportCollection(collectionData);
        this.lockboxReport.fetch({
            success() {
                self.renderLockboxPanels(selectedLocationCode);
            },
        });
    },

    /**
     * @method renderLockboxPanels
     * @param location - location ID
     * - Render the lockbox panel once the rest service is corrected
     */
    renderLockboxPanels(location) {
        this.lockboxCollectionView = new CollectionView({
            collection: this.lockboxReport,
            itemView: LockboxPanel,

            itemViewOptions: {
                types: this.selectedTypeNames,
                dates: this.depositDates,
            },
        });
        this[`lockboxRegion-${location}`].show(this.lockboxCollectionView);
    },

    recordSelections() {
        const panels = $('.panel-group > .panel > .panel-heading');

        this.selections = [];

        util.each(panels, function (panel) {
            const isExpanded = $(panel).attr('aria-expanded');

            if (isExpanded) {
                const obj = {
                    location: $(panel).attr('id'),
                    lockboxes: [],
                };

                const lbxPanels = $(panel).siblings().find('.panel-heading');
                util.each(lbxPanels, (lbxPanel) => {
                    const lbxIsExpanded = $(lbxPanel).attr('aria-expanded');
                    if (lbxIsExpanded) {
                        obj.lockboxes.push($(lbxPanel).attr('id'));
                    }
                });
                this.selections.push(obj);
            }
        }, this);
    },

    openReportSelections() {
        this.selections = this.selections || store.get('lbxSelections');

        if (this.selections) {
            util.each(this.selections, (panel) => {
                $(`#${panel.location}`).trigger('click');
                util.each(panel.lockboxes, (lockbox) => {
                    $(`#${lockbox}`).trigger('click');
                });
            });
        }
        store.unset('lbxSelections');
    },

    templateHelpers() {
        return {
            lockboxLocations: this.lockboxLocationsData,
        };
    },
});
