import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@glu/tabs-react';
import ViewWrapper from 'react-wrappers/ViewWrapper';
import useLocale from 'hooks/useLocale';
import { CheckmarkCircleIcon } from '@glu/icons-react';
import { withStyles } from '@glu/theming';
import styles from './PanelApprovalFirstStep.styles';

const PAYMENT_METHODS = 'paymentMethods';
const TEMPLATES = 'templates';

const propTypes = {
    paymentMethodsView: PropTypes.shape({
        model: PropTypes.object.isRequired,
    }).isRequired,
    templatesView: PropTypes.shape({
        model: PropTypes.object.isRequired,
    }).isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const PanelApprovalFirstStep = ({
    paymentMethodsView,
    templatesView,
    classes,
}) => {
    const { getLocaleString } = useLocale();
    const [viewedPaymentMethods, setViewedPaymentMethods] = useState(false);
    const [viewedTemplates, setViewedTemplates] = useState(false);
    const [tab, setTab] = useState(PAYMENT_METHODS);
    const paymentMethodTabChange = (e, value) => {
        setViewedTemplates(true);
        setTab(value);
    };
    const templatesTabChange = (e, value) => {
        setViewedPaymentMethods(true);
        setTab(value);
    };
    return (
        <>
            <Tabs className={classes.tabs} value={tab}>
                <Tab
                    key={`${PAYMENT_METHODS}-tab`}
                    value={PAYMENT_METHODS}
                    onChange={paymentMethodTabChange}
                >
                    <div className={classes.tabTitleWrapper}>
                        {viewedPaymentMethods
                            && <CheckmarkCircleIcon className={classes.tabCheckbox} /> }
                        {getLocaleString('PS.panelApproval.Payment.Methods')}
                    </div>
                </Tab>
                <Tab
                    key={`${TEMPLATES}-tab`}
                    value={TEMPLATES}
                    onChange={templatesTabChange}
                >
                    <div className={classes.tabTitleWrapper}>
                        {viewedTemplates
                            && <CheckmarkCircleIcon className={classes.tabCheckbox} />}
                        {getLocaleString('PS.panelApproval.Templates')}
                    </div>
                </Tab>
            </Tabs>
            <ViewWrapper view={tab === PAYMENT_METHODS ? paymentMethodsView : templatesView} />
        </>
    );
};
PanelApprovalFirstStep.propTypes = propTypes;

export default withStyles(styles)(PanelApprovalFirstStep);
