/* istanbul ignore file */
export const styles = (theme) => {
  const {
    dataComponents: {
      scrollbarBackground,
      scrollbarColor,
      textLight
    }
  } = theme;

  const customScrollBar = {
    '&::-webkit-scrollbar': {
      width: 10
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: scrollbarColor
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: scrollbarBackground,
      marginTop: 20
    }
  };

  return ({
    filterWrapper: {

      '& article': {
        padding: [1, 0]
      },

      '& article > input': {
        boxSizing: 'border-box',
        marginBottom: 20
      },
      // label
      '& section > div': {
        alignItems: 'baseline'
      },
      '& section > div > button > span': {
        color: textLight,
        fontSize: 16
      },
      '& section > div > div': {
        '& > button': {
          color: textLight,
          fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
          fontSize: 16,
          padding: [7, 0]
        }
      }
    },
    filterableWrapper: {
      flex: '1',
      overflowY: 'auto',
      padding: [0, 25, 0, 0],
      ...customScrollBar,
      '& ul': {
        maxHeight: 300,
        overflowY: 'auto',
        ...customScrollBar
      }
    },
    filtersWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      minHeight: '100%'
    }
  });
};

export default styles;
