var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.report.management",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":68}}}))
    + "</h1>\n</div>\n\n<div class=\"section section-container\">\n    <div class=\"section-body\">\n        <div class=\"btn-wrapper\">\n           <div class=\"btn-group widget-info-links\">\n                <button type=\"button\" class=\"btn btn-tertiary refresh\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.refresh",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":78},"end":{"line":9,"column":105}}}))
    + "\" data-hook=\"refresh-button\"><span class=\"icon-refresh\"></span><span class=\"refresh-time\" data-hook=\"refresh-time\"></span></button>\n           </div>\n        </div>\n                <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n                <div data-region=\"content\" aria-live=\"polite\"></div>\n    </div>\n</div>\n";
},"useData":true});