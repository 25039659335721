import Layout from '@glu/core/src/layout';
import PageApi from 'common/dynamicPages/api/view';
import constants from 'app/administration/constants';
import store from 'system/utilities/cache';
import ContextModel from 'common/dynamicPages/models/context';
import systemConfig from 'system/configuration'; // PCM RELATED
import loadingWidgetTmpl from 'common/templates/loadingWidget.hbs';
import userProfileTmpl from './userProfile.hbs';

export default Layout.extend({
    template: userProfileTmpl,
    loadingTemplate: loadingWidgetTmpl,

    initialize(options) {
        this.options = options;
        this.mode = options.mode || constants.MODES.VIEW;
        this.isAdmin = systemConfig.isAdmin(); // PCM

        this.contextDef = this.options.contextDef;

        this.contextModel = new ContextModel({
            menuCategory: this.options.menuCategory,
            serviceName: this.options.serviceName,
            serviceFunc: this.options.serviceFunc,
            businessType: this.options.businessType,
            context: this.options.context,
            contextDef: this.contextDef,
        });

        this.contextKey = this.contextModel.getContextKey();
        this.localeKey = this.contextModel.getLocaleKey();

        this.mode = this.options.mode || constants.MODES.VIEW;
        this.viewType = this.options.viewType || 'default';
    },

    onRender() {
        const { mode } = this.options;
        if (this.hasLoadedRequiredData()) {
            this.listenTo(this.pageView, 'loaded', this.setPageViewListeners);
            this.pageContent.show(this.pageView);
        } else {
            if (mode === 'insert') {
                this.loadInsertUserData();
            } else {
                this.loadModifyUserData(mode);
            }

            this.pageView.userCompany = store.get('userCompany');
        }
    },

    loadInsertUserData() {
        const self = this;

        const options = {
            context: self.contextDef,
            hideButtons: true,
        };

        this.pageView = PageApi.page.get(options);

        this.pageView.userGroupModel = this.model.userGroupModel;

        this.model = this.pageView.model;
        this.storedModel = this.model;

        this.setHasLoadedRequiredData(true);
        this.render();
    },

    loadModifyUserData(mode) {
        this.mode = mode;

        const options = {
            context: this.contextDef,
            model: this.model,
            state: mode,
            hideButtons: true,
        };

        this.pageView = PageApi.page.get(options);

        this.pageView.userGroupModel = this.model.userGroupModel;

        this.model = this.pageView.model;
        this.storedModel = this.model;
        this.setHasLoadedRequiredData(true);
        this.render();
    },

    templateHelpers() { // PCM RELATED
        const self = this;

        return {
            readOnly: self.mode === constants.MODES.VIEW,
            isAdmin: this.isAdmin,
        };
    },
});
