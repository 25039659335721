import util from '@glu/core/src/util';
import Account from 'app/balanceAndTransaction/models/account';
import constants from 'app/balanceAndTransaction/constants';
import store from 'system/utilities/cache';

const BTRDeepLinkUtil = {

    /**
     * Retrieves the object with the values that were provided when the contextual
     * deep link is invoked
     *
     * @param {String} contextDetailsName - The name of object representing the context data
     */
    retrieveContextData(contextDetailsName) {
        const storedPreObj = store.remove(
            contextDetailsName,
            {
                context: {
                    productCode: constants.GIR,
                    functionCode: constants.INST,
                },
            },
        );
        return storedPreObj;
    },

    /**
     * Creates an instance of a BalanceAndTransaction model that is placed into the
     * store prior to navigating to the Transaction Detail page
     * @param {Object} contextData - object with the name value pairs for instantiating
     *                               the account object
     */
    createAccountModel(contextData) {
        const keyValuePairs = {};
        Object.keys(contextData).forEach((key) => {
            if (!util.isEmpty(contextData[key])) {
                keyValuePairs[key] = contextData[key];
            }
        });

        // Set account filter if not set
        if (util.isEmpty(keyValuePairs.accountFilter)) {
            keyValuePairs.accountFilter = `${keyValuePairs.bankCode}-${keyValuePairs.accountNumber}`;
        }

        return new Account(keyValuePairs);
    },

};
export default BTRDeepLinkUtil;
