import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import util from '@glu/core/src/util';
import { withStyles } from '@glu/theming';
import useLocale from '../../hooks/useLocale'; // path may change
import RtpUtils from '../../app/payments/views/realTimePayments/utils/conversationUtils';

import styles from '../RtpConversationHeader/RtpConversationHeader.styles';

const propTypes = {
    data: PropTypes.shape({
        TOTAL_INTERBANK_SETTLE_AMT: PropTypes.string,
        CMB_TRANSACTION_AMOUNT: PropTypes.string,
        TOTAL_INTERBANK_SETTLE_CCY: PropTypes.string,
        CMB_TRANSACTION_CURRENCY: PropTypes.string,
        CREDITOR_NAME: PropTypes.string,
        CMB_BENE_NAME: PropTypes.string,
        INTERBANK_SETTLE_DATE: PropTypes.string,
        CMB_VALUE_DATE: PropTypes.string,
        REMITTANCE_INFO: PropTypes.string,
        PAYMENT_DETAILS: PropTypes.string,
    }).isRequired,
    currentTab: PropTypes.string,
    // Internal classes hash - do not pass
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const defaultProps = {
    currentTab: null,
};

/**
 * @name getRemittance
 *
 * @param {object} data
 * @param {string} currentTab - either submitted or received
 * @param {function} getLocaleString - passed locale.get function
 * @returns {string} the remittance info for the incoming/outgoing payment
 */
function getRemittance(data, currentTab, getLocaleString) {
    const submitted = currentTab === 'submitted';
    let remittance;
    if (submitted) {
        remittance = util.isEmpty(data.PAYMENT_DETAILS) ? getLocaleString('rtp.noRemittanceInfoSent') : data.PAYMENT_DETAILS;
    } else {
        remittance = util.isEmpty(data.TRANSACTION_DETAIL) ? getLocaleString('rtp.noRemittanceInfoReceived') : data.TRANSACTION_DETAIL;
    }
    return util.unescape(remittance);
}

const ConversationPaymentDetail = ({ data, currentTab, classes }) => {
    const { getLocaleString } = useLocale();
    const {
        label1, value1, date, amount, currency,
    } = RtpUtils.getDetailInfo(data, currentTab);
    const remittance = getRemittance(data, currentTab, getLocaleString);
    return (
        <>
            <div className={`row ${classes.conversationHeaderRow}`}>
                <span className={`col-3 ${classes.conversationHeaderColumnLabel}`}>
                    {getLocaleString(label1)}
                </span>
                <p className={`col-9 ${classes.conversationHeaderColumnValue}`}>{value1}</p>
            </div>
            <div className={`row ${classes.conversationHeaderRow}`}>
                <span className={`col-3 ${classes.conversationHeaderColumnLabel}`}>
                    {getLocaleString('RTP.INST.CRTRAN.*.VALUE_DATE.label')}
                </span>
                <p className={`col-9 ${classes.conversationHeaderColumnValue}`}>
                    {date}
                </p>
            </div>
            <div className={`row ${classes.conversationHeaderRow}`}>
                <span className={`col-3 ${classes.conversationHeaderColumnLabel}`}>
                    {getLocaleString('RTP.INST.CRTRAN.*.CREDIT_AMOUNT.label')}
                </span>
                <p className={`col-9 ${classes.conversationHeaderColumnValue}`}>
                    {amount}
                    <span className={classes.conversationHeaderCurrency}>
                        {currency}
                    </span>
                </p>
            </div>
            <div className={`row ${classes.conversationHeaderRow}`}>
                <span className={`col-3 ${classes.conversationHeaderColumnLabel}`}>
                    {getLocaleString('RTP.INST.CRTRAN.*.REMITTANCE_INFO.label')}
                </span>
                <p className={`col-9 ${classes.conversationHeaderColumnValue}`}>{remittance}</p>
            </div>
        </>
    );
};

ConversationPaymentDetail.propTypes = propTypes;
ConversationPaymentDetail.defaultProps = defaultProps;

export default withStyles(styles)(ConversationPaymentDetail);
