import Controller from '@glu/core/src/controller';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import services from 'services';
import SearchField from '../model/searchFieldModel';

export default Controller.extend({
    initialize() {
        this.activeRequest = this.getDefaultRequest();
        this.successHandle = util.bind(this.handleSuccess, this);
        this.failureHandle = util.bind(this.handleFailure, this);
    },

    /**
     * Returns generic structure that will contain the information
     * necessary to make a READ request.
     * @return {Object} Default structure for inquiry search request data
     */
    getDefaultRequest() {
        return {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    searchType: 5,
                    searchFields: [],
                    inquiryId: 0,

                    action: {
                        typeCode: '',
                        productCode: '',
                        entryMethod: 0,
                        functionCode: 'MAINT',
                        actionMode: 'SELECT',
                    },
                },
            },
        };
    },

    /**
     * Convienent function to add a search field to the inquiry object.
     * @param {String} name - Field name to search
     * @param {*} value - Simple type search value.
     */
    addSearch(name, value) {
        const f = new SearchField();
        f.set({
            fieldName: name,
            fieldValue: [value],
        });

        this.addSearchField(f.toJSON());
    },

    /**
     * Function to add SearchField object.
     * @param {SearchField} field - SearchField object that will be added to the
     * list of seach fields.
     */
    addSearchField(field) {
        this.activeRequest.inquiryRequest.searchCriteria.searchFields.push(field);
    },

    /**
     * Sets the Type code for the inquiry.
     * @param {String} type
     */
    setTypeCode(type) {
        this.activeRequest.inquiryRequest.searchCriteria.action.typeCode = type;
    },

    /**
     * Sets the product code for the inquiry.
     * @param {String} code
     */
    setProductCode(code) {
        this.activeRequest.inquiryRequest.searchCriteria.action.productCode = code;
    },

    /**
     * Sets the inquiry ID for the inquiry.
     * @param {Number} id
     */
    setInquiryId(id) {
        this.activeRequest.inquiryRequest.searchCriteria.inquiryId = id;
    },

    /**
     * Sends a request to the service to fetch data.
     * @return {Promise}
     */
    send() {
        return http.post(this.getURL(), this.getData(), this.successHandle, this.failureHandle);
    },

    /**
     * Returns the URL for this inquiry.
     * @return {String}
     */
    getURL() {
        return services.inquiryRowsData;
    },

    /**
     * Returns the active request that is prepared to be sent to the server.
     * @return {type}
     */
    getData() {
        return this.activeRequest;
    },

    /**
     * Event handler to broadcast a success event.
     * @param  {Object} response
     * @return {type}
     */
    handleSuccess(response) {
        this.trigger('success', response);
    },

    /**
     * Event handler to broadcast an error event.
     * @param  {Object} response
     * @return {type}
     */
    handleFailure(response) {
        this.trigger('error', response);
    },
});
