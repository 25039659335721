
import RadioButton from './radioButton';

/**
 * RadioGroup constructor
 * @param {Element} domNode - The radio group node that the user is working on
 * @param {function} activationHandler - method bound to view obj executed when a btn is activated
 * @param {View} view - View object that contains the radio buttons.
 * @param {string} [startId] - Optional element key(?) which is the initial selection
 */
function RadioGroup(domNode, activationHandler, view, startId) {
    this.domNode = domNode;
    this.activationHandler = activationHandler;
    this.view = view;
    this.startId = startId;
    this.init();
}

/**
 * Locate the currently selected radio button in the list.
 * If none is selected, look for the provided 'startOn',
 * or default to the first button if all else fails.
 */
RadioGroup.prototype.getSelectedButton = function () {
    return this.radioButtons.find(input => input.checked ?? input.ariaChecked === 'true')
        ?? this.radioButtons.find(input => input.id === this.startId) ?? this.radioButtons[0];
};

RadioGroup.prototype.init = function () {
    // initialize the radio group
    if (!this.domNode.getAttribute('role')) {
        this.domNode.setAttribute('role', 'radiogroup');
    }
    this.radioButtons = [...this.domNode.querySelectorAll('[role=radio]')]
        .map(input => new RadioButton(input, this));
    this.activeButton = this.getSelectedButton();
    this.setChecked(this.activeButton);
};

RadioGroup.prototype.isActive = function (currentItem) {
    return this.activeButton === currentItem;
};

RadioGroup.prototype.setActiveButton = function (activeButton) {
    this.activeButton = activeButton;
};

RadioGroup.prototype.setChecked = function (currentItem, event) {
    if (this.isActive(currentItem)) {
        return;
    }
    this.radioButtons.forEach((radio) => {
        radio.setChecked(radio === currentItem, event);
    });
    this.activationHandler.call(this.view, currentItem);
    this.activeButton = currentItem;
};
RadioGroup.prototype.setCheckedToPreviousItem = function (currentItem) {
    let index;
    if (currentItem === this.firstRadioButton) {
        this.setChecked(this.lastRadioButton);
    } else {
        index = this.radioButtons.indexOf(currentItem);
        let targetIndex = index - 1;
        if (targetIndex < 0) {
            targetIndex = this.radioButtons.length - 1;
        }
        this.setChecked(this.radioButtons[targetIndex]);
    }
};
RadioGroup.prototype.setCheckedToNextItem = function (currentItem) {
    let index;
    if (currentItem === this.lastRadioButton) {
        this.setChecked(this.firstRadioButton);
    } else {
        index = this.radioButtons.indexOf(currentItem);
        let targetIndex = index + 1;
        if (targetIndex >= this.radioButtons.length) {
            targetIndex = 0;
        }
        this.setChecked(this.radioButtons[targetIndex]);
    }
};

export default RadioGroup;
