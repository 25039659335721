import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import EmployeeGrid from './employeeGrid';

let list = EmployeeGrid;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        insertActions: [],
    });
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
