import GluDialog from '@glu/dialog';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import ModalView from '@glu/dialog/src/views/modalView';
import ComplexModalView from './modalView';

export default util.extend(
    GluDialog,
    {
        // Deprecated.
        ModalView: ComplexModalView || ModalView,

        complexAlert(message, title, callback) {
            let localTitle = title;
            let localCallback = callback;
            if (typeof localTitle === 'function') {
                localCallback = localTitle;
                localTitle = null;
            }

            const popup = new ComplexModalView({
                type: 'alert',
                message,
                title: localTitle,

                buttons: [{
                    text: locale.get('smb.ok.btn'),
                    className: 'btn-primary',

                    callback() {
                        this.close();

                        if (localCallback) {
                            localCallback();
                        }
                    },
                }],
            });

            popup.$el.attr('role', 'alertdialog');
            this.showPopup(popup);
        },

        alert(message, title, callback) {
            let localTitle = title;
            let localCallback = callback;
            if (typeof localTitle === 'function') {
                localCallback = localTitle;
                localTitle = null;
            }

            const popup = new ModalView({
                type: 'alert',
                message,
                title: localTitle,

                buttons: [{
                    text: locale.get('smb.ok.btn'),
                    className: 'btn-primary',

                    callback() {
                        this.close();

                        if (localCallback) {
                            localCallback();
                        }
                    },
                }],
            });

            popup.$el.attr('role', 'alertdialog');
            this.showPopup(popup);
        },
    },
);
