import Collection from '@glu/core/src/collection';
import CombinedAccount from 'app/balanceAndTransaction/models/combinedAccount';
import util from '@glu/core/src/util';
import transform from 'common/util/transform';
import http from '@glu/core/src/http';
import BalancePoller from 'app/balanceAndTransaction/models/balancePoller';
import services from 'services';
import constants from 'app/balanceAndTransaction/constants';

const CombinedAccountAccounts = Collection.extend({
    model: CombinedAccount,

    initialize(models, options) {
        const realtimeServiceURL = services.generateUrl(constants
            .REAL_TIME_TRANSACTION_POLLER_SERVICES
            .CUSTOMREPORT);
        this.service = options.service;
        this.filterModel = options.filterModel;
        this.groupName = options.groupName;
        this.groupIndex = options.groupIndex;
        this.filterAccounts = options.filterAccounts;
        this.filterView = options.filterView;
        this.parentCollection = options.parentCollection;
        this.options = options;

        // Instantiate Poller for real time balances
        this.realTimePoller = new BalancePoller({
            serviceUrl: realtimeServiceURL,
        });

        this.on('sync', this.onFetchedAccounts.bind(this));
    },

    setGroupName(name) {
        this.groupName = name;
    },

    getRequestObject(options) {
        let searchFields = this.filterModel.getSearchFields();

        // default to rowsPerPage size set on the serverside
        const req = {
            rowsPerPage: null,
            searchFields: [],
            startRow: 1,
            ...options,
        };

        if (this.filterAccounts) {
            searchFields = util.reject(searchFields, fld => fld.fieldName === 'account_group_name');
            searchFields.push({
                fieldName: 'account_group_name',

                fieldValue: [
                    this.groupName,
                ],

                dataType: 'text',
                operator: '=',
            });
        }

        req.searchFields = searchFields.concat(this.filterView.getTransactionFilters());
        return req;
    },

    sync(method, model, options) {
        if (method === 'read') {
            const self = this;
            const data = this.getRequestObject(options.requestOptions);

            // trigger account loading event
            this.parentCollection.trigger('accountGroups:loading');

            return http.post(this.service, data, (response) => {
                self.rowsPerPage = response.rowsPerPage;
                self.totalRows = response.totalRows;
                self.startRow = data.startRow - 1;
                self.headers = util.filter(response.rowHeader, column => column.fieldName.indexOf('START_') === 0 || column.fieldName.indexOf('END_') === 0);
                options.success(response);

                /*
                 * initiate another batch fetch call if
                 * there are more rows in the search to load
                 */
                if (self.length < response.totalRows) {
                    util.defer(() => {
                        self.fetch({
                            remove: false,
                            requestOptions: {
                                startRow: data.startRow + response.rowsPerPage,
                                rowsPerPage: response.rowsPerPage,
                            },
                        });
                    });
                }
            }, () => {
                options.error();
            });
        }
        // TODO: Maybe return rejected promise for bad method?
        return undefined;
    },

    /**
     * @method onFetchAccounts
     * callback to check if
     */
    onFetchedAccounts() {
        util.defer(() => {
            // trigger event to let parent collection know group has finished loading
            if (this.length >= this.totalRows) {
                this.fetchedAll = true;
                this.trigger('accounts:loaded');
            }

            // start process to get Real Time Balance and Transactions on recently fetched
            if (this.options.shouldLoadRealTime && !this.realTimePoller.isWorking()) {
                this.setupRealTimePolling((this.startRow) ? this.startRow : 0);
            }
        });
    },

    /**
     * @method getRealTimeAccounts
     * @param {array} accounts - array of account models in collection
     * @return {array} array of account Models for realtime accounts
     */
    getRealTimeAccounts(accounts) {
        return accounts
            .filter(acct => acct.get('ISREALTIMEACCOUNT') === '1');
    },

    /**
     * @method setupRealTimePolling
     * @param {number} startRow
     * Method used to setup process of recurring polling calls for real time data
     * for realtime accounts
     */
    setupRealTimePolling(startRow = 0) {
        const realTimeAccts = this.getRealTimeAccounts(this.models);
        if (realTimeAccts.length) {
            this.triggerRealTimePoller(startRow);
        } else {
            this.parentCollection.at(this.groupIndex).trigger('accountsPolling:complete');
        }
    },

    /**
     * @method triggerRealTimePoller
     * @param {number} startRow
     * @return {xhr} real time polling request
     * Method for sneding out recurring realtime poll requests
     */
    triggerRealTimePoller(startRow = 0) {
        const realTimeAccts = this.getRealTimeAccounts(this.models);
        const endRow = startRow + this.rowsPerPage;
        const targetAccts = realTimeAccts.slice(startRow, endRow);
        const acctFilters = targetAccts.map(acct => acct.get('ACCOUNTFILTER'));

        this.toggleAccountLoadingEvent(targetAccts);
        this.parentCollection.at(this.groupIndex).trigger('realTimeInformation:loading', true);
        this.realTimePoller.set('accountFilters', acctFilters);

        // start polling request
        const realTimePoll = this.realTimePoller.start({
            disableDrillDown: true,
            requestParameters: {
                item: [{
                    name: 'initialRequest',
                    value: 'true',
                }],
            },
            dataOnly: 0,
            rowsPerPage: 25,
            startRow: 0,
            searchFields: [
                {
                    fieldName: 'start_date',
                    fieldValue: [this.filterModel.get('START_DATE')],
                    dataType: 'date',
                    operator: '>=',
                },
                {
                    fieldName: 'end_date',
                    fieldValue: [this.filterModel.get('END_DATE')],
                    dataType: 'date',
                    operator: '<=',
                },
            ],
        });

        // listeners for completion and abort
        this.listenToOnce(this.realTimePoller, 'pollComplete', this.onRealTimePollingComplete.bind(this, endRow, targetAccts));
        this.listenToOnce(this.parentCollection, 'reset', this.abortRealTimePolling.bind(this, realTimePoll));

        return realTimePoll;
    },

    /**
     *
     * @param {xhr} request
     * Callback to abort ongoing realtime calls when parent totals collection is reset
     */
    abortRealTimePolling(request) {
        this.stopListening(this.realTimePoller);
        if (!util.isUndefined(request)) {
            request.abort();
        }
    },

    /**
     * @method onRealTimePollingComplete
     * @param {number} currentRow
     * @param {array} currentAccts - accounts that are currently targeted for realtime polling
     * callback on polling call completion
     */
    onRealTimePollingComplete(currentRow, currentAccts) {
        // get updated list of all realtime accounts currently available
        const realTimeAccts = this.getRealTimeAccounts(this.models);
        const data = this.realTimePoller.get('foundNewBalances');

        this.toggleAccountLoadingEvent(currentAccts, false);

        // update real time accounts after finish
        if (data) {
            this.updateExistingAccounts(data);
        }

        // decide to continue polling or not
        if (currentRow < realTimeAccts.length) {
            this.triggerRealTimePoller(currentRow);
        } else {
            this.parentCollection.at(this.groupIndex).trigger('accountsPolling:complete');
        }
    },

    /**
     * @param {array} targetAccts - current realtime account models that are set to poll
     * @param {boolean} isPolling
     * Trigger the polling event from a set of realtime account models
     */
    toggleAccountLoadingEvent(targetAccts, isPolling = true) {
        targetAccts.forEach((acct) => {
            acct.trigger('realTimeLoading', isPolling);
        });
    },

    /**
     * @method updateExistingAccounts
     * @param {object} resp - response data
     * Will update existing realtime accounts with the data returned from poll
     */
    updateExistingAccounts(resp) {
        const parsedData = this.parse(resp);
        parsedData.forEach((acctData) => {
            // Remove any blank/empty string values that come back (only update whats needed)
            const filteredData = Object
                .keys(acctData)
                .reduce((arr, prop) => ((acctData[prop]) ? Object.assign(arr, {
                    [prop]: acctData[prop],
                }) : arr), {});
            const account = this.filter(act => act.get('ACCOUNTFILTER') === acctData.ACCOUNTFILTER);
            account[0].set(filteredData);
        });
    },

    parse(res) {
        return util.map(res.rows, (row) => {
            const ret = transform.pairsToHash(row.columns, 'fieldName', 'fieldValue');
            if (ret.ACCOUNT_NAME) {
                ret.ACCOUNT_NAME = ret.ACCOUNT_NAME.replace(/&amp;/g, '&');
            }
            ret.headers = res.rowHeader;
            return ret;
        }, this);
    },
});

export default CombinedAccountAccounts;
