import React, {
    useState,
    useContext,
    useCallback,
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
import useLocale from 'hooks/useLocale';
import {
    Input,
} from '@glu/form-components';
import {
    useValidation,
} from '@glu/validation-react';
import Alert from '@glu/alerts-react';
import Layout from '@glu/layout-react';
import FormFieldStatic from 'components/FormFieldStatic/FormFieldStatic';
import RequiredLabelText from 'components/RequiredLabelText/RequiredLabelText';
import { Button, PRIMARY, TERTIARY } from '@glu/buttons-react';
import FormField from 'components/FormField/FormField';
import FormFieldStaticContainer from 'components/FormFieldStaticContainer/FormFieldStaticContainer';
import ActionButtonContainer from 'components/ActionButtonContainer/ActionButtonContainer';
import useNavigation from 'hooks/useNavigation';
import LabelTextPopover from 'components/LabelTextPopover/LabelTextPopover';
import ValidatedCheckboxRadioGroup from 'components/ValidatedCheckboxRadioGroup/ValidatedCheckboxRadioGroup';
import serverConfig from 'system/webseries/models/configurationParameters';
import validatorPatterns from 'system/validatorPatterns';
import useStyles from './StepTwo.styles';
import AlertContext from '../AlertWrapper/AlertContext';
import DeliveryTypeTabs from '../DeliveryTypeTabs/DeliveryTypeTabs';
import ValidatedContentEditorContainer from '../../ContentEditor/ValidatedContentEditorContainer';
import { ContentEditorProvider } from '../../ContentEditor/ContentEditorContext';

const propTypes = {
    returnRoute: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
};
const StepTwo = ({
    returnRoute,
    onSave,
}) => {
    const classes = useStyles();
    const { cancel } = useNavigation({
        returnRoute,
    });
    const [enabledSMS] = useState(serverConfig.get('EnableSMSalertContent') === 'true');
    const { getLocaleString } = useLocale();
    const {
        alertData,
        alertDeliveryType,
        alertParameters,
        marketSegmentOptions,
    } = useContext(AlertContext);
    const [ValidationProvider, validationValue,
        { validate, data, errors }] = useValidation({ data: alertData });

    const [showContentError, setShowContentError] = useState(false);
    // Update setShowContentError state when error attributes changes
    useEffect(() => {
        setShowContentError(!!errors.EMAILCONTENT?.length || !!errors.TEXTCONTENT?.length);
    }, [errors.EMAILCONTENT, errors.TEXTCONTENT]);

    // Combine the errors into an array, when the error attributes change
    const combineContentErrors = useCallback(() => [
        ...errors.EMAILCONTENT,
        ...errors.TEXTCONTENT,
    ], [errors.EMAILCONTENT, errors.TEXTCONTENT]);

    const [isSaving] = useState(false);
    const save = () => {
        validate().then((result) => {
            const { isValid } = result;
            if (isValid) {
                onSave(data);
            }
        });
    };

    return (
        <>
            <FormFieldStaticContainer>
                <Layout container>
                    <FormFieldStatic
                        col={3}
                        label={getLocaleString('CMAINT.Alert.Group')}
                        name="ALERTGROUP_DESC"
                        value={alertData?.ALERTGROUP_DESC}
                    />
                    <FormFieldStatic
                        col={3}
                        label={getLocaleString('CMAINT.Alert.Type')}
                        name="ALERTTYPE_DESC"
                        value={alertData?.ALERTTYPE_DESC}
                    />
                    <FormFieldStatic
                        col={3}
                        label={getLocaleString('CMAINT.Locale')}
                        name="LOCALE_DESC"
                        value={alertData?.LOCALE_DESC}
                    />
                </Layout>
            </FormFieldStaticContainer>
            <div className="section section-container">
                <ValidationProvider value={validationValue}>
                    <form>
                        <FormField>
                            <Input
                                className={classes.subject}
                                labelText={(
                                    <RequiredLabelText
                                        label={getLocaleString('CMAINT.Subject')}
                                    />
                                )}
                                name="ALERTSUBJECT"
                                validators={{
                                    name: getLocaleString('CMAINT.Subject'),
                                    required: true,
                                    regexMatch: {
                                        pattern: validatorPatterns.NOBADCHARS_PATTERN,
                                        message: getLocaleString('validator.no.bad.characters'),
                                    },
                                }}
                                onDrop={e => e.preventDefault()}
                                maxLength="78"
                            />
                        </FormField>
                        {showContentError && (
                            <section className="section section-container">
                                <Alert
                                    variant="error"
                                    title={getLocaleString('PS.fixErrors')}
                                    onHide={() => setShowContentError(false)}
                                >
                                    {combineContentErrors().map(error => (<p>{error}</p>))}
                                </Alert>
                            </section>
                        )}
                        <DeliveryTypeTabs />
                        <section className={classes.tabContainer}>
                            <ContentEditorProvider>
                                <ValidatedContentEditorContainer
                                    alertParameters={alertParameters?.EMAIL}
                                    content={alertData.EMAILCONTENT}
                                    contentType="html"
                                    isShown={alertDeliveryType === 'EMAIL'}
                                    maxCharacterCount={2000}
                                    name="EMAILCONTENT"
                                    validators={{
                                        name: getLocaleString('CMAINT.Email.content'),
                                        required: true,
                                    }}
                                />
                            </ContentEditorProvider>
                            {enabledSMS && (
                                <ContentEditorProvider>
                                    <ValidatedContentEditorContainer
                                        alertParameters={alertParameters?.TEXT}
                                        content={alertData.TEXTCONTENT}
                                        contentType="text"
                                        isShown={alertDeliveryType === 'SMS'}
                                        maxCharacterCount={2000}
                                        name="TEXTCONTENT"
                                        validators={{
                                            name: getLocaleString('CMAINT.Text.Message.content'),
                                            required: true,
                                        }}
                                    />
                                </ContentEditorProvider>
                            )}
                        </section>
                        <section>
                            <ValidatedCheckboxRadioGroup
                                name="MARKETSEGMENTS"
                                autoSelectSingleOption
                                itemList={marketSegmentOptions.map(option => ({
                                    labelText: option.name,
                                    htmlValue: option.id,
                                }))}
                                labelText={(
                                    <LabelTextPopover
                                        labelText={(
                                            <RequiredLabelText
                                                label={getLocaleString('CMAINT.Market.Segments')}
                                            />
                                        )}
                                        popoverContent={getLocaleString('CMAINT.Market.Segments.popover.content')}
                                    />
                                )}
                                validators={{
                                    name: getLocaleString('CMAINT.Market.Segments'),
                                    required: true,
                                }}
                            />
                        </section>
                        <ActionButtonContainer>
                            <Button
                                className="btn btn-primary"
                                variant={PRIMARY}
                                text={getLocaleString('button.save')}
                                aria-busy={isSaving}
                                onClick={save}
                            />
                            <Button
                                variant={TERTIARY}
                                text={getLocaleString('button.cancel')}
                                onClick={cancel}
                            />
                        </ActionButtonContainer>
                    </form>
                </ValidationProvider>
            </div>
        </>
    );
};

StepTwo.propTypes = propTypes;
export default StepTwo;
