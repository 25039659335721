import ToggleWidget from 'common/uiWidgets/toggleWidget/toggleWidget';


export default ToggleWidget.extend({
    shouldShow() {
        const { model } = this;
        const fromRFP = model.get('RFP_MSG_ID');
        const show = ToggleWidget.prototype.shouldShow.call(this);
        return show && !fromRFP;
    },
});
