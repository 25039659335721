import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import exportDetailTmpl from './exportDetail.hbs';

export default ItemView.extend({
    template: exportDetailTmpl,

    navigateToDownloads() {
        this.appBus.trigger('router:navigate', 'REPORTING/btrExport', { trigger: true, newStack: true });
    },

    templateHelpers() {
        return {
            downloadMessage: locale.get('payment.export.success.message', this.options.exportId || null),
        };
    },
});
