import { createUseStyles } from '@glu/theming';

const styles = ({ fileUploadReact }) => ({
  alertTitle: {
    margin: 0,
    fontSize: 16,
    lineHeight: 1.2,
    textTransform: 'uppercase',
    fontFamily: fileUploadReact.titleFont
  },
  alertMessage: {
    lineHeight: 1.2,
    fontSize: 16,
    margin: [9, 0, 15, 0],
    '& p': {
      margin: 0
    }
  }
});

export default createUseStyles(styles);
