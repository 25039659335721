import services from 'services';
import errHandler from 'system/errHandler';
import util from '@glu/core/src/util';
import transform from 'common/util/transform';
import http from '@glu/core/src/http';
import systemConfig from 'system/configuration';

const flattenResponse = (data) => {
    const response = data.queryResponse.QueryData.queryRows;

    for (let x = 0; x < response.length; x += 1) {
        if (response[x].mapDataList) {
            const mapDataList = util.findWhere(
                response[x],
                {
                    toField: 'ProductCode',
                },
            );
            if (mapDataList) {
                response[x].ProductCode = mapDataList.value;
            }
        }
    }

    return response;
};

export default {
    getEntitlementsRequest(userGroup) {
        const url = services.generateUrl('panelassignment/getEntitledActions');
        const requestData = {
            data: [{
                name: 'USERGROUP',
                value: userGroup,
            }],

            typeCode: 'PNLPA_TM',
            productCode: '_ADMIN',
            functionCode: 'MAINT',
        };
        return http.post(url, requestData)
            .then(data => util.values(data.actionModes), errHandler);
    },

    getEntryOptionsRequest() {
        // payment entry methods
        const url = services.generateUrl('inquiry/getQueryResults');
        const requestData = {
            queryCriteria: {
                inquiryId: '17011',
                customFilters: [],

                action: {
                    productCode: systemConfig.isAdmin() ? 'CMAINT' : '_ADMIN',
                    functionCode: 'MAINT',
                    actionMode: 'INSERT',
                    entryMethod: 0,
                    typeCode: 'PNLPA_TM',
                },
            },
        };

        return http.post(url, requestData).then(flattenResponse, errHandler);
    },

    getAssignedAccountsRequest(entryMethod, paymentType, userGroup, panelName) {
        const url = services.generateUrl('inquiry/getQueryResults');
        const request = {
            IncludeMapData: 1,

            queryCriteria: {
                inquiryId: '15223',
                allowDuplicates: 'true',

                customFilters: [{
                    filterName: 'Depends',

                    filterParam: [
                        'PanelEntryMethod',
                        entryMethod,
                    ],
                }, {
                    filterName: 'Depends',

                    filterParam: [
                        'SubCode',
                        paymentType,
                    ],
                }, {
                    filterName: 'Depends',

                    filterParam: [
                        'UserGroup',
                        userGroup,
                    ],
                }, {
                    filterName: 'Depends',

                    filterParam: [
                        'PanelName',
                        panelName,
                    ],
                }],

                action: {
                    productCode: '_ADMIN',
                    functionCode: 'MAINT',
                    actionMode: 'INSERT',
                    typeCode: 'PNLPA_TM',
                },
            },
        };
        return http.post(url, request).then(flattenResponse).then(data => util.map(data, row => transform.pairsToHash(row.mapDataList, 'toField', 'value')), errHandler);
    },

    getAccountListRequest(userGroup) {
        const url = services.generateUrl('/inquiry/getQueryResults');

        return http.post(
            url,
            {
                IncludeMapData: 1,

                queryCriteria: {
                    inquiryId: '40012',

                    customFilters: [{
                        filterName: 'Depends',

                        filterParam: [
                            'UserGroup',
                            userGroup,
                        ],
                    }],

                    allowDuplicates: 'true',

                    action: {
                        productCode: systemConfig.isAdmin() ? 'CMAINT' : '_ADMIN',
                        functionCode: 'MAINT',
                        actionMode: 'INSERT',
                        typeCode: 'PNLPA_TM',
                    },
                },
            },
        ).then(flattenResponse).then(data => util.map(data, (row) => {
            const hash = transform.pairsToHash(row.mapDataList, 'toField', 'value');
            return {
                id: row.name,
                accountFilter: row.name,
                accountNumber: row.label,
                accountName: util.unescape(hash.ACCOUNTNAME),
                bankCode: row.name,
            };
        }), errHandler);
    },

    getPaymentMethodsRequest(userGroup) {
        return http.post(
            services.generateUrl('inquiry/getQueryResults'),
            {
                IncludeMapData: 1,

                queryCriteria: {
                    inquiryId: '17010',

                    customFilters: [{
                        filterName: 'Depends',
                        filterParam: ['USERGROUP', userGroup],
                    }],

                    action: {
                        productCode: systemConfig.isAdmin() ? 'CMAINT' : '_ADMIN',
                        functionCode: 'MAINT',
                        actionMode: 'INSERT',
                        typeCode: 'PNLPA_TM',
                    },
                },
            },
        ).then((data) => {
            const rows = data.queryResponse.QueryData.queryRows;
            return rows.map((row) => {
                const hash = transform.pairsToHash(row.mapDataList, 'toField', 'value');
                return {
                    productCode: hash.ProductCode,
                    paymentType: hash.PaymentType,
                    label: row.label,
                };
            });
        }, errHandler);
    },

    getWorkflowsRequest() {
        return http.post(
            services.generateUrl('inquiry/getQueryResults'),
            {
                IncludeMapData: 1,

                queryCriteria: {
                    inquiryId: '17066',

                    action: {
                        typeCode: 'PNLPA_TM',
                        productCode: '_ADMIN',
                        actionMode: 'SELECT',
                        functionCode: 'MAINT',
                    },
                },
            },
        ).then(flattenResponse, errHandler);
    },
};
