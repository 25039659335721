// PCM related
import withPCM from 'pcmMdf/app/payments/pcmRoutes'; //eslint-disable-line

export default withPCM({
    'BATCH/largeBatch(/)(:viewId)': 'pmtLargeBatch',
    'IMPORT/modifyvFImport': 'impModifyvFImport',
    'PAYMENTS/addNewPayment': 'pmtAddPayment',
    'PAYMENTS/addNewTemplate': 'pmtAddTemplate',
    'PAYMENTS/addPaymentFromTemplate': 'addPaymentFromTemplate',
    'PAYMENTS/addRequestFromTemplate/:function': 'addRequestFromTemplate',
    'PAYMENTS/addTransferManyToOne': 'pmtAddTransferManyToOne',
    'PAYMENTS/addTransferOneToMany': 'pmtAddTransferOneToMany',
    'PAYMENTS/checkInquiry': 'pmtCheckInquiryWorkspace',
    'PAYMENTS/checkInquiryView': 'pmtCheckInquiry',
    'PAYMENTS/checkManagement': 'pmtCheckManagementWorkspace',
    'PAYMENTS/exception/report': 'pmtExceptionReport',
    'PAYMENTS/imageSearch': 'pmtImageSearchWorkspace',
    'PAYMENTS/insertStopCancels': 'pmtInsertStopCancels',
    'PAYMENTS/listPayments(/)(:viewId)': 'pmtListPayments',
    'PAYMENTS/listStops(/)(:viewId)': 'pmtListStops',
    'PAYMENTS/listTemplates(/)(:viewId)': 'pmtListTemplates',
    'PAYMENTS/managePayments': 'pmtWorkspace',
    'PAYMENTS/manageRealTimePayment': 'pmtRealTimePaymentsWorkspace',
    'PAYMENTS/manageStops': 'pmtListStopsWorkspace',
    'PAYMENTS/manageTemplateGroups': 'pmtManageTemplateGroups',
    'PAYMENTS/manageTransfers': 'pmtTransfersWorkspace',
    'PAYMENTS/modifyPayment(/)(:isSubPayment)': 'pmtModifyPayment',
    'PAYMENTS/modifyRequestForPaymentOut': 'pmtModifySubmittedRequestForPayment',
    'PAYMENTS/notEntitled': 'pmtNotEntitled',
    'PAYMENTS/passthroughDetail': 'listPassThroughDetails',
    'PAYMENTS/payRequestForPayment': 'pmtPayRequestForPay',
    'PAYMENTS/quickEntry': 'pmtQuickEntry',
    'PAYMENTS/quickTransferWidget': 'pmtQuickTransferWidget',
    'PAYMENTS/rapidWireDomEntry': 'pmtRapidWireDom',
    'PAYMENTS/rapidWireEntry': 'pmtRapidWire',
    'PAYMENTS/realTimePaymentsWidget(/)(:viewId)': 'pmtRealTimePayments',
    'PAYMENTS/receivedRFPNotificationList(/)(:viewId)': 'pmtReceivedRFPNotificationList',
    'PAYMENTS/repairPayment(/)(:isSubPayment)': 'pmtRepairPayment',
    'PAYMENTS/requestForPayment': 'pmtRequestForPayment',
    'PAYMENTS/requestForPaymentWidget(/)(:viewId)': 'pmtRequestForPaymentWidget',
    'PAYMENTS/requestPhotoCopy': 'pmtReqPhotoCopy',
    'PAYMENTS/reversePayment': 'pmtReversePayment',
    'PAYMENTS/rtpMultiAdd': 'pmtRtpMultiAdd',
    'PAYMENTS/simpleEntryWidget': 'pmtSimpleEntryWidget',
    'PAYMENTS/ssoout(/)(:context)': {
        action: 'pmtSSOout',
        allowRefresh: fragment => fragment.startsWith('PAYMENTS/ssoout'),
    },
    'PAYMENTS/ssooutRetry(/)(:context)': 'pmtSSOoutRetry',
    'PAYMENTS/useRequestForPaymentTemplate': 'rfpChoseTemplate',
    'PAYMENTS/viewImage': 'pmtViewImage',
    'PAYMENTS/viewPayment(/)(:isSubPayment)': 'pmtViewPayment',
    'PAYMENTS/viewRequestForPayment': 'pmtReceivedRequestForPay',
    'PAYMENTS/viewRequestForPaymentOut': 'pmtViewSubmittedRequestForPayment',
    'TEMPLATES/addTemplateFromPayment': 'addTemplateFromPayment',
    'TEMPLATES/modifyRequestForPaymentOut': 'pmtModifySubmittedRequestForPaymentTemplate',
    'TEMPLATES/modifyTemplate': 'pmtModifyTemplate',
    'TEMPLATES/repairTemplate': 'pmtRepairTemplate',
    'TEMPLATES/requestForPaymentTemplate': 'requestForPaymentTemplate',
    'TEMPLATES/requestForPaymentRestoreTemplate': 'requestForPaymentRestoreTemplate',
    'TEMPLATES/restoreTemplate': 'pmtRestoreTemplate',
    'TEMPLATES/schedulePayment': 'pmtSchedulePayment',
    'TEMPLATES/viewRequestForPaymentOut': 'pmtViewSubmittedRequestForPaymentTemplate',
    'TEMPLATES/viewTemplate': 'pmtViewTemplate',
    // 'PAYMENTS/quickRTPWidget': 'pmtQuickRTPWidget',
    'TEMPLATES/addTemplateFromRequest/:function': 'addTemplateFromRequest',
    'PAYMENTS/checkFree/register': 'pmtCheckFreeRegister',
    'PAYMENTS/addPaymentFromTemplateDeepLink': 'deeplinkAddPaymentFromTemplate',
    'PAYMENTS/readAccessDenied': 'displayErrorPageReadAccessDenied',
    // 'PAYMENTS/deepLinkViewPayment': 'deepLinkViewPaymentPAY',
});
