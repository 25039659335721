import Collection from '@glu/core/src/collection';
import BusinessPayment from 'app/smb/models/business';

export default Collection.extend({
    model: BusinessPayment,

    comparator(model) {
        return -model.cid.replace('c', '');
    },

    total() {
        let retVal = 0;
        this.each((model) => {
            retVal += Number(model.get('amount'));
        });
        return retVal;
    },
});
