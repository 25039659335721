import CollectionView from '@glu/core/src/collectionView';
import MultiItemView from './multiItemView';

export default CollectionView.extend({
    itemView: MultiItemView,

    /**
     * @name itemViewOptions
     * @description returns the options for the multi-add item view
     * @param model
     * @returns {{model: *, itemIndex}}
     */
    itemViewOptions(model) {
        return {
            model,
            itemIndex: this.collection.models.length,
        };
    },

    itemEvents: {
        /**
         * @name destroy
         * @description event handler for the remove item.  If there is only one
         * model it gets
         * cleared; otherwise,
         * the model is removed from the collection
         * @param event
         * @param itemView
         */
        destroy(event, itemView) {
            // if first item, then just clear model; otherwise, remove model
            if (this.collection.models.length === 1) {
                this.collection.models[0].clear();
            } else {
                this.collection.remove(itemView.model);
            }
        },
    },

    initialize(options) {
        this.parentView = options.parentView;
        /**
         * if we want to perform any action when the collection is updated we can add
         * a listener & handler
         * ex: this.listenTo(this.collection, 'add remove', this.updateCollection.bind(this));
         */
    },

    /**
     * @name onAfterItemAdded
     * @description (marionette) event handler called after an item (model) has
     * been added to the collection
     * We update the totals to reflect the new value of items in the collection
     */
    onAfterItemAdded() {
        this.updateItemTotals();
    },

    /**
     * @name onItemRemoved
     * @description (marionette) event handler called after an item (model) has
     * been removed from the collection
     * We update the totals to reflect the new value of items in the collection
     */
    onItemRemoved() {
        this.updateItemTotals();
    },

    /**
     * @name updateItemTotals
     * @description updates the total items UI element on the page
     */
    updateItemTotals() {
        this.parentView.$('[data-hook="getTotalItems"]').text(this.collection.models.length);
    },
});
