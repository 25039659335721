import $ from 'jquery';
import number from 'numeral';
import Decimals from 'common/dynamicPages/api/decimals';

export default {
    setTotal(model, totalFieldName, currencyFieldName) {
        const amountFields = $('[data-field="summary-amount"]');
        const $currencyFields = $('[data-field="summary-currency"]');
        const paymentTotalFormatted = number(model.get(totalFieldName) || 0)
            .format(Decimals.getCurrencyFormat(model.get(currencyFieldName)));
        amountFields.text(paymentTotalFormatted);
        $currencyFields.text(model.get(currencyFieldName));
    },
};
