export default {
    dgbdrawer: {
        background: '#37435c',
        borderLeft: '2px solid #0074bd',
        color: '#FFFFFF',
        padding: '14px 0',
        '&:before': {
            background: '#37435c',
        },
        transition: 'all 0.5s ease-in-out',
        right: '0',
        opacity: 1,
        // get over grid sticky-footer
        'z-index': 355,

        'body.portal &': {

        },

        '@media (min-width:1400px)': {
            right: 'calc(50% - 700px)',
        },
    },
    drawerMessageToggle: {
        background: '#0074bd',
        border: 0,
        padding: '10px 16px 7px 20px',
        position: 'fixed',
        top: '150px',
        right: '2px',
        'border-radius': '15px 0 0 15px',
        color: 'white',
        // collapse duration
        transition: 'all 0.5s ease-in-out',
        '&.icon-chat-bubble': {
            fontSize: '28px',
        },
        '@media (min-width:1400px)': {
            right: 'calc(50% - 700px)',
        },
    },
    drawerMessageToggleOpen: {
        right: '550px',
        transition: 'all 0.5s ease-in-out',
        '@media (min-width:1400px)': {
            right: 'calc(50% - 700px + 550px)',
        },
    },
    close: {
        color: '#fff',
        fontSize: '18px',
        opacity: 1,
        position: 'fixed',
        right: '31px',
        top: '18px',
        zIndex: 110,
        '&:hover': {
            color: '#fff',
        },
        '@media (min-width:1400px)': {
            right: 'calc(50% - 700px + 31px)',
        },
    },
};
