export const createActionList = (buttons, entitlement) =>
    buttons.map(({ action }) => action)?.includes(entitlement) === false;
export const checkAction = (buttons, entitlement) =>
    buttons.find(button => button.action === entitlement);

export const formatConfirm = (results) => {
    let confirmResponse = null;
    let successMessage = null;
    let totalSuccess = 0;
    let totalFail = 0;
    let failMessage = null;
    let message = null;

    results.forEach((responseItem) => {
        if (responseItem.result) {
            if (confirmResponse === null) {
                confirmResponse = responseItem;
            } else {
                confirmResponse.confirms.confirmResults
                    .push(responseItem.confirms.confirmResults[0]);
            }
            totalSuccess += responseItem.confirms.totalSuccess;
            totalFail += responseItem.confirms.totalFail;
            if (responseItem.confirms.totalSuccess) {
                [successMessage] = responseItem.message;
            } else {
                [failMessage] = responseItem.message;
            }
        } else {
            confirmResponse.confirms.totalFail += 1;
        }
    });

    let idx = -1;

    if (successMessage) {
        idx = successMessage.indexOf(' ');
        successMessage = idx > -1
            ? totalSuccess + successMessage.slice(idx) : successMessage;

        // add a separator for success and failure messages
        if (failMessage) {
            successMessage += ', ';
        }
        message = successMessage;
    }
    if (failMessage) {
        idx = failMessage.indexOf(' ');
        failMessage = idx > -1 ? totalFail + failMessage.slice(idx) : failMessage;
        if (successMessage) {
            message += failMessage;
        } else {
            message = failMessage;
        }
    }

    confirmResponse.message[0] = message;
    confirmResponse.confirms.totalSuccess = totalSuccess;
    confirmResponse.confirms.totalFail = totalFail;
    return confirmResponse;
};

