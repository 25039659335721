var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"blockClass") || (depth0 != null ? lookupProperty(depth0,"blockClass") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"blockClass","hash":{},"data":data,"loc":{"start":{"line":2,"column":12},"end":{"line":2,"column":26}}}) : helper)))
    + " required\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"blockClass") || (depth0 != null ? lookupProperty(depth0,"blockClass") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"blockClass","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":26}}}) : helper)))
    + "\">\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <option "
    + alias3(((depths[1] && lookupProperty(depths[1],"isOpSelected"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"operator") : depth0),{"name":"../isOpSelected","hash":{},"data":data,"loc":{"start":{"line":14,"column":28},"end":{"line":14,"column":56}}}))
    + " value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"operator") || (depth0 != null ? lookupProperty(depth0,"operator") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"operator","hash":{},"data":data,"loc":{"start":{"line":14,"column":64},"end":{"line":14,"column":78}}}) : helper)))
    + "\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":14,"column":80},"end":{"line":14,"column":91}}}) : helper)))
    + "</option>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return " readonly ";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " placeholder=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"placeHolder") || (depth0 != null ? lookupProperty(depth0,"placeHolder") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"placeHolder","hash":{},"data":data,"loc":{"start":{"line":23,"column":214},"end":{"line":23,"column":229}}}) : helper)))
    + "\" ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"mandatory") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":5,"column":7}}})) != null ? stack1 : "")
    + "\n    <!-- Label -->\n    <label for=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldName") || (depth0 != null ? lookupProperty(depth0,"fieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldName","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":29}}}) : helper)))
    + "-equality\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldLabel") || (depth0 != null ? lookupProperty(depth0,"fieldLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldLabel","hash":{},"data":data,"loc":{"start":{"line":8,"column":40},"end":{"line":8,"column":54}}}) : helper)))
    + "</label>\n    <div class=\"filter-outer-wrapper\">\n        <div class=\"filter-search-operator\">\n            <!-- Operand -->\n            <select id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldName") || (depth0 != null ? lookupProperty(depth0,"fieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldName","hash":{},"data":data,"loc":{"start":{"line":12,"column":24},"end":{"line":12,"column":37}}}) : helper)))
    + "-equality\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldName") || (depth0 != null ? lookupProperty(depth0,"fieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldName","hash":{},"data":data,"loc":{"start":{"line":12,"column":54},"end":{"line":12,"column":67}}}) : helper)))
    + "-equality\" class=\"form-control field-operator\" data-bind=\"model\" maxlength=\"255\">\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"operators") || (depth0 != null ? lookupProperty(depth0,"operators") : depth0)) != null ? helper : alias2),(options={"name":"operators","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":15,"column":30}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"operators")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </select>\n        </div>\n        \n        <div class=\"filter-search-wrapper\">\n            <div class=\"filter-search-operand\">\n                <!-- Field 1 -->\n                <label for=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldName") || (depth0 != null ? lookupProperty(depth0,"fieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldName","hash":{},"data":data,"loc":{"start":{"line":22,"column":28},"end":{"line":22,"column":41}}}) : helper)))
    + "\" class=\"sr-only\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.search.value",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":59},"end":{"line":22,"column":91}}}))
    + "</label>\n                <input class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cssClass") || (depth0 != null ? lookupProperty(depth0,"cssClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cssClass","hash":{},"data":data,"loc":{"start":{"line":23,"column":30},"end":{"line":23,"column":42}}}) : helper)))
    + "\" type=\"text\" maxlength=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"maxLen") || (depth0 != null ? lookupProperty(depth0,"maxLen") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"maxLen","hash":{},"data":data,"loc":{"start":{"line":23,"column":67},"end":{"line":23,"column":77}}}) : helper)))
    + "\" data-bind=\"model\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldName") || (depth0 != null ? lookupProperty(depth0,"fieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldName","hash":{},"data":data,"loc":{"start":{"line":23,"column":103},"end":{"line":23,"column":116}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"protected") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":118},"end":{"line":23,"column":152}}})) != null ? stack1 : "")
    + " value=\"\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldName") || (depth0 != null ? lookupProperty(depth0,"fieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldName","hash":{},"data":data,"loc":{"start":{"line":23,"column":166},"end":{"line":23,"column":179}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"placeHolder") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":181},"end":{"line":23,"column":238}}})) != null ? stack1 : "")
    + ">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldName") || (depth0 != null ? lookupProperty(depth0,"fieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldName","hash":{},"data":data,"loc":{"start":{"line":24,"column":74},"end":{"line":24,"column":87}}}) : helper)))
    + "\"></span>\n            </div>\n            <div class=\"filter-search-operand\">\n                <!-- Field 2 (Optional) -->\n                 <label for=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldName") || (depth0 != null ? lookupProperty(depth0,"fieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldName","hash":{},"data":data,"loc":{"start":{"line":28,"column":29},"end":{"line":28,"column":42}}}) : helper)))
    + "2\" class=\"sr-only\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.search.value",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":61},"end":{"line":28,"column":93}}}))
    + "</label>\n                <input class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cssClass") || (depth0 != null ? lookupProperty(depth0,"cssClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cssClass","hash":{},"data":data,"loc":{"start":{"line":29,"column":30},"end":{"line":29,"column":42}}}) : helper)))
    + " hidden\" type=\"text\" maxlength=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"maxLen") || (depth0 != null ? lookupProperty(depth0,"maxLen") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"maxLen","hash":{},"data":data,"loc":{"start":{"line":29,"column":74},"end":{"line":29,"column":84}}}) : helper)))
    + "\" data-bind=\"model\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldName") || (depth0 != null ? lookupProperty(depth0,"fieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldName","hash":{},"data":data,"loc":{"start":{"line":29,"column":110},"end":{"line":29,"column":123}}}) : helper)))
    + "2\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"protected") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":126},"end":{"line":29,"column":160}}})) != null ? stack1 : "")
    + " value=\"\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldName") || (depth0 != null ? lookupProperty(depth0,"fieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldName","hash":{},"data":data,"loc":{"start":{"line":29,"column":174},"end":{"line":29,"column":187}}}) : helper)))
    + "2\" placeholder=\""
    + alias4(lookupProperty(helpers,"locale").call(alias1,"title.to",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":203},"end":{"line":29,"column":224}}}))
    + "\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldName") || (depth0 != null ? lookupProperty(depth0,"fieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldName","hash":{},"data":data,"loc":{"start":{"line":30,"column":74},"end":{"line":30,"column":87}}}) : helper)))
    + "2\"></span>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true,"useDepths":true});