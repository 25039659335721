import Model from '@glu/core/src/model';
import services from 'services';
import http from '@glu/core/src/http';

const TypeEntitled = Model.extend({
    initialize() {
        let inquiryId = null;
        const typeCode = this.get('typeCode');

        this.data = {
            requestHeader: {
                queryOffset: 0,
                queryPagesize: 0,
                requestId: '',
            },

            IncludeMapData: 1,

            queryCriteria: {
                action: {
                    typeCode: 'BENEADBK',
                    entryMethod: 0,
                    actionMode: false,
                    productCode: '_ADMIN',
                    functionCode: 'INST',
                },

                fieldName: false,
                inquiryId: false,
                queryType: 'Query',
            },

            formatterType: 'Query',
        };

        if (typeCode === 'DRAFT') {
            inquiryId = '40006';
        } else if (typeCode === 'BPAY') {
            inquiryId = '19446';
        } else if (typeCode === 'RTP') {
            inquiryId = '40017';
        }
        this.data.queryCriteria.inquiryId = inquiryId;
    },

    sync(method, model, options) {
        http.post(services.inquiryQueryResults, this.data, (result) => {
            options.success(result);
        }, () => {
            options.error();
        });
    },

    parse(result) {
        return {
            isEntitled: result.queryResponse.QueryData.queryRows.length > 0,
        };
    },
});

export default TypeEntitled;
