import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import services from 'services';
import http from '@glu/core/src/http';

export default Collection.extend({
    /**
     * Parse jsonData rows
     * @param  {Object} jsonData - server response
     * @return {Array}
     */
    parse(jsonData) {
        const contextObj = {
            serviceName: 'bankAcctFilters',
        };

        let group;

        // Return userInfo Group data if no results
        if (util.isEmpty(jsonData.rows)) {
            group = userInfo.get('group');
            return [{
                id: group,
                name: group,
                label: group,
                context: contextObj,
            }];
        }

        return util.map(jsonData.rows, (rowItem) => {
            const row = rowItem.columns;
            return {
                id: row[0].fieldValue,
                name: row[1].fieldValue,
                label: row[3].fieldValue,
                context: contextObj,
            };
        });
    },

    sync(method, model, options) {
        const url = services.generateUrl('/bankAcctFilters/listView/getListView');
        const postData = {};
        http.post(url, postData, (result) => {
            options.success(result);
        }, (result) => {
            options.error(result);
        });
    },
});
