var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button>\n        <span class=\"title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"LABEL") || (depth0 != null ? lookupProperty(depth0,"LABEL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LABEL","hash":{},"data":data,"loc":{"start":{"line":2,"column":28},"end":{"line":2,"column":37}}}) : helper)))
    + "</span>\n        <span class=\"count\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"TOTAL") || (depth0 != null ? lookupProperty(depth0,"TOTAL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOTAL","hash":{},"data":data,"loc":{"start":{"line":3,"column":28},"end":{"line":3,"column":37}}}) : helper)))
    + "</span>\n        <span class=\"value\">"
    + alias4(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"AMOUNT") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":4,"column":28},"end":{"line":4,"column":45}}}))
    + "</span>\n</button>\n";
},"useData":true});