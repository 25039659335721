import $ from 'jquery';
import locale from '@glu/locale';

export const toggleTabs = (view) => {
    const $navItems = $('[data-hook="getNavTabs"] .NavTabs-item', view.$el);
    const $navTabs = $('[data-hook="getNavTabs"]', view.$el);
    const $navTabsToggle = $('[data-hook="getNavTabsToggle"]', view.$el);
    $navTabs.toggleClass('is-open');
    if ($navTabs.hasClass('is-open')) {
        $navTabsToggle.attr('aria-expanded', true);
        $navTabsToggle.text(locale.get('common.tabs.chooseTab'));
        $navTabsToggle.addClass('is-open');
        $navItems.filter('.is-active').find('a').focus();
    } else {
        $navTabsToggle.attr('aria-expanded', false);
        $navTabsToggle.text(view.currentTabText);
        $navTabsToggle.removeClass('is-open');
        $navTabsToggle.focus();
    }
};

export const createTabsToggleButton = (view) => {
    const $navTabs = $('[data-hook="getNavTabs"]', view.$el);
    const triggerButton = document.createElement('button');
    triggerButton.setAttribute('type', 'button');
    triggerButton.setAttribute('aria-controls', $navTabs.attr('id'));
    triggerButton.classList.add('NavTabs-toggle');
    triggerButton.dataset.hook = 'getNavTabsToggle';
    triggerButton.onclick = toggleTabs.bind(view);
    triggerButton.innerText = locale.get('common.tabs.chooseTab');
    $(triggerButton).insertBefore($navTabs);
};

export const setTabButtonText = (view, text) => {
    $('[data-hook="getNavTabsToggle"]', view.$el).text(text);
};

/**
 * @method setupAccessibleTabs
 * @param view - View object that contains the tabs.
 * @param activationHandler - method bound to view object that executes when a tab is activated.
 */
const setupAccessibleTabs = (view, activationHandler) => {
    const $tablist = $('[role="tablist"]', view.$el)[0];
    const $tabs = $('[role="tab"]', view.$el);
    const keys = {
        end: 35,
        home: 36,
        left: 37,
        up: 38,
        right: 39,
        down: 40,
        delete: 46,
        enter: 13,
        space: 32,
    };
    const direction = {
        37: -1,
        38: -1,
        39: 1,
        40: 1,
    };

    const focusFirstTab = () => {
        $tabs[0].focus();
    };

    const focusLastTab = () => {
        $tabs[$tabs.length - 1].focus();
    };

    // Activates any given tab
    const activateTab = (event, setFocus = true) => {
        const { target } = event;

        // Deactivate all other tabs
        $tabs.each((_, tab) => {
            tab.setAttribute('tabindex', '-1');
            tab.setAttribute('aria-selected', 'false');
        });

        // set tabindex attribute as 0
        target.setAttribute('tabindex', '0');

        // Set the tab as selected
        target.setAttribute('aria-selected', 'true');

        activationHandler.call(view, event);

        // Set focus when required
        target.focus();
        if (!setFocus) {
            target.blur();
        }
    };

    /**
     * Either focus the next, previous, first, or last tab
     * depending on key pressed
     */
    const switchTabOnArrowPress = (event) => {
        const { keyCode, target } = event;
        const index = $tabs.index(target);

        if (direction[keyCode] && index !== undefined) {
            const tabToFocus = $tabs[index + direction[keyCode]];
            if (tabToFocus) {
                tabToFocus.focus();
            } else if ([keys.left, keys.up].includes(keyCode)) {
                focusLastTab();
            } else if ([keys.right, keys.down].includes(keyCode)) {
                focusFirstTab();
            }
        }
    };

    /**
     * When a tablist's aria-orientation is set to vertical,
     * only up and down arrow should function.
     * In all other cases only left and right arrow function.
     */
    const determineOrientation = (event) => {
        const { keyCode } = event;
        const vertical = $tablist?.getAttribute('aria-orientation') === 'vertical';
        let proceed = false;

        if (vertical && [keys.up, keys.down].includes(keyCode)) {
            event.preventDefault();
            proceed = true;
        } else if ([keys.left, keys.right].includes(keyCode)) {
            proceed = true;
        }

        if (proceed) {
            switchTabOnArrowPress(event);
        }
    };

    // When a tab is clicked, activateTab is fired to activate it
    const clickEventListener = (event) => {
        event.preventDefault();
        activateTab(event, false);
    };

    // Handle keydown on tabs
    const keydownEventListener = (event) => {
        const { keyCode } = event;

        switch (keyCode) {
        case keys.end:
            event.preventDefault();
            // Activate last tab
            focusLastTab();
            break;
        case keys.home:
            event.preventDefault();
            // Activate first tab
            focusFirstTab();
            break;

            /*
             * Up and down are in keydown
             * because we need to prevent page scroll >:)
             */
        case keys.up:
        case keys.down:
            determineOrientation(event);
            break;
        default:
            break;
        }
    };

    // Handle keyup on tabs
    const keyupEventListener = (event) => {
        const { keyCode } = event;

        switch (keyCode) {
        case keys.left:
        case keys.right:
            determineOrientation(event);
            break;
        case keys.enter:
        case keys.space:
            event.preventDefault();
            activateTab(event);
            break;
        default:
            break;
        }
    };

    // Bind listeners
    $tabs.on('mousedown', clickEventListener)
        .on('keydown', keydownEventListener)
        .on('keyup', keyupEventListener);
};

export default setupAccessibleTabs;
