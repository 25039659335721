var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">Back to Previous Page</span></a>\n    </div>\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.import.remitter.name.import",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":75}}}))
    + "</h1>\n</div>\n<div class=\"alert-region\"></div>\n\n<section class=\"section section-container\">\n\n    <div class=\"row\">\n        <div class=\"form-column col-md-12\">\n            <h4 class=\"form-group-label \" role=\"presentation\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.import.remitter.name.format.name",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":62},"end":{"line":13,"column":111}}}))
    + "</h4>\n            <div data-region=\"mapFormatRegion\"></div>\n        </div>\n    </div>\n\n</section>\n\n<form name=\"importform\" id=\"importform\" enctype=\"multipart/form-data\" method=\"POST\" target=\"_blank\">\n\n    <section class=\"section section-container\">\n\n        <div class=\"row\">\n            <div class=\"form-column col-md-12\"><h4 class=\"form-group-label \" role=\"presentation\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.fimport.filesection",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":97},"end":{"line":25,"column":133}}}))
    + "</h4>\n                <div class=\"required field-container-lg field-container\">\n                    <label for=\"FILESELECTION\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.fimport.selectimportfile",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":47},"end":{"line":27,"column":88}}}))
    + "</label>\n                    <input class=\"form-control \" type=\"file\" maxlength=\"255\" data-bind=\"model\" id=\"FILESELECTION\" name=\"FILESELECTION\" value=\"\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"FILESELECTION\"></span>\n                    <div class=\"progress hide\">\n                        <div class=\"progress-bar progress-bar-striped active\" role=\"progressbar\" aria-valuenow=\"0\" aria-valuemin=\"0\" aria-valuemax=\"100\" style=\"width: 0%\">\n                            0%\n                        </div>\n                    </div>\n                </div></div></div>\n\n    </section>\n</form>\n\n<div class=\"section section-summary\">\n    <div class=\"section-body\">\n        <div class=\"widget-action-btn-group\">\n            <button type=\"button\" data-action=\"fileimport\" class=\"btn btn-primary\" name=\"fileimport\"><span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.fimport.fileimportbutton",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":43,"column":119},"end":{"line":43,"column":160}}}))
    + "</span></button>\n            <button type=\"button\" data-action=\"fileimportrefresh\" class=\"btn btn-secondary\" name=\"fileimportrefresh\"><span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.fimport.refreshbutton",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":44,"column":135},"end":{"line":44,"column":173}}}))
    + "</span></button>\n            <button type=\"button\" data-action=\"cancel\" class=\"btn btn-secondary\" name=\"cancel\"><span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.fimport.cancelbutton",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":45,"column":113},"end":{"line":45,"column":150}}}))
    + "</span></button>\n        </div>\n    </div>\n</div>\n\n";
},"useData":true});