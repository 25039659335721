import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import services from 'services';
import http from '@glu/core/src/http';
import AccountsModel from 'app/reports/models/accounts';

export default Collection.extend({
    model: AccountsModel,

    parse(response) {
        const { rows } = response;
        const accounts = [];

        util.each(rows, (account) => {
            let localAccount = account;
            localAccount = this.convertServerJsonToModelAttributes(localAccount.columns);

            localAccount.id = localAccount.REMITTER_ACCT_ID;

            accounts.push(localAccount);
        });

        return accounts;
    },

    sync(method, model, options) {
        const url = services.generateUrl('/tableMaintenance/lockboxRemitterView/getListView');
        const postData = (this.rid ? {
            startRow: 1,

            searchFields: [{
                dataType: 'number',
                fieldName: 'REMITTER_ID',
                operator: 'IN',
                fieldValue: [this.rid],
            }],
        } : {});

        http.post(url, postData, (result) => {
            options.success(result);
        }, (result) => {
            options.error(result);
        });
    },

    convertServerJsonToModelAttributes(columns) {
        const data = {};

        for (let x = 0; x < columns.length; x += 1) {
            data[columns[x].fieldName] = columns[x].fieldValue;
        }

        return data;
    },
});
