/* eslint-disable */
/*
 * Route Context - branding override
 * Routes can be added or removed here. Additionally, the context can be changed
 * depending on the client requirements.
 */
export const TrusteerPinpointRouteContext = {
    '/ui': 'main_account',
    '/ui/workspace': 'main_account',
    '/ui/PAYMENTS/managePayments': 'transaction',
    '/ui/PAYMENTS/manageTransfers': 'add_payee',
    '/ui/ADMINSTRATION/viewUsers': 'change_details',
};
