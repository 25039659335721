import Model from '@glu/core/src/nestedModel';

export default Model.extend({

    defaults() {
        return {
            functionCode: '',
            functionGroup: null,
            productCode: '',
            typeCode: '',
            typeGroup: '',
            typeGroupLabel: null,
        };
    },

    isEntitled() {
        return Object.keys(this.attributes).some((key) => {
            if (this.get(key) && this.get(key).entitled) {
                return true;
            }
            return false;
        }, this);
    },

    isActionApplicable(action) {
        return !!(this.get(action) || {}).applicable;
    },

    isActionEntitled(action) {
        return !!(this.get(action) || {}).entitled;
    },

    hasSignatures() {
        return !!(this.get('signatures') || {}).applicable;
    },

    maximumNumberOfRequiredSignatures() {
        return (this.get('signatures') || {}).maxValue;
    },
    getNumberOfRequiredSignatures() {
        return (this.get('signatures') || {}).assignedValue;
    },
    setNumberOfRequiredSignatures(n) {
        this.get('signatures').assignedValue = n;
    },
});
