import util from '@glu/core/src/util';
import CheckGrid from './checkGrid';
import CONSTANTS from './constants';
import gridDataAccess from './gridDataActions';

export default CheckGrid.extend({
    getEntityName() {
        return 'ACHCompany';
    },

    getEntityID() {
        return CONSTANTS.REQUEST_ENTITY.ACH;
    },

    getCFEntityID() {
        return CONSTANTS.REQUEST_ENTITY.ACH_CF;
    },

    /**
     * Return the ACH type used for localized resources
     * @return {string}
     */
    getEntityType() {
        return CONSTANTS.ENTITY_TYPE.ORIGINATOR;
    },

    getRecords() {
        const isGrouped = gridDataAccess.isGrouped(this.model);

        return util.chain(gridDataAccess.getSectionData(this.model, 'PAYMENTS'))
            .map(group => (isGrouped ? gridDataAccess.getHeaderAsGroups(group)
                : gridDataAccess.getHeaderAsTypes(group)))
            .flatten(true)
            .filter(group => this.isGroupPermitted(group.id)
                        || this.isGroupPermitted(group.subGroupId))
            .map((groupParam) => {
                const group = groupParam;
                group.isGroup = isGrouped;
                return group;
            })
            .value();
    },

    isGroupPermitted(groupId) {
        return ['ACH', 'EFT'].includes(groupId);
    },

});
