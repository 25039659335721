export default ({ mobilegrid: { drawerPanel } }) => ({
  root: {
    background: drawerPanel.background,
    height: '100%',
    fontFamily: drawerPanel.fontFamily,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
  },
  title: {
    margin: 0,
    padding: drawerPanel.titlePadding
  },
  titleBar: {
    background: drawerPanel.titleBackground,
    boxShadow: drawerPanel.titleBoxShadow,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    padding: '1rem',
    height: drawerPanel.titleHeight,
    boxSizing: drawerPanel.titleBoxSizing,
    alignItems: drawerPanel.titleAlignItems
  },
  content: {
    padding: '0 0 0 1rem',
    height: '100%',
    overflow: 'auto'
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: drawerPanel.footerHeight,
    marginBottom: drawerPanel.marginBottom,

    '& button': {
      borderRadius: 0,
      flexBasis: '50%'
    }
  }
});
