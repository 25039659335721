import util from '@glu/core/src/util';
import Collection from '@glu/core/src/collection';
import ActionCell from 'common/dynamicPages/views/gridActionCell';
import InquiryApi from 'system/webseries/api/inquiry';

export default Collection.extend({
    initialize(opts) {
        this.inquiryId = opts.inquiryId;
        this.searchType = opts.searchType || InquiryApi.DEFAULT_SEARCH_TYPE;
        this.typeCode = opts.typeCode || InquiryApi.DEFAULT_TYPE_CODE;
        this.viewId = opts.viewId || InquiryApi.DEFAULT_VIEW_ID;
    },

    sync(method, model, options) {
        const self = this;

        /*
         * Use the inquiry service to retrieve the header information.  Transform into
         * dataset that grid is happy consuming.
         */
        InquiryApi.getHeaderData({
            inquiryId: self.inquiryId,
            searchType: self.searchType,
            typeCode: self.typeCode,
            viewId: self.viewId,
        }).done((data) => {
            options.success(data);
        });
    },

    /**
     * Parse newly formatted header data from the backend into a format
     * that can be easily consumed by a grid.
     *
     * @param result
     */
    parse(result) {
        try {
            // Map properties from result set into grid consumable values.
            const columnMap = util.map(
                result.inquiryResponse.rowHeader,
                column => util.extend(column, this.parseColumn(column)),
            );

            /*
             * Filter out hidden columns
             * Note: The intent for columns of type "hidden" from webseries
             * is that they are never visible to the user.
             * They are working on an additional property to express columns that are
             * initially not displayed
             * but available for filtering and via the add/remove menu.
             */
            const visibleColumnMap = util.reject(columnMap, column => column.type === 'hidden' || column.displayOrder < 0, this);

            /*
             * Sort columns by displayOrder.
             * Note: Some inquiries return negative values for displayOrder.  We need to
             * figure out what this means
             * in terms of UI presentation.
             */
            return util.sortBy(visibleColumnMap, column => column.displayOrder, this);
        } catch (err) {
            return [];
        }
    },

    /**
     * Map incoming column data to grid equivalents.
     *
     * @param column
     * @returns {{}}
     */
    parseColumn(column) {
        const extColumn = {};

        extColumn.field = column.fieldName;
        extColumn.title = column.displayName;

        switch (column.type) {
        case InquiryApi.COLUMN_TYPE_STRING:
            extColumn.type = 'string';
            break;
        case InquiryApi.COLUMN_TYPE_CHECKBOX:
            extColumn.type = 'rowSelector';
            extColumn.title = 'All';
            extColumn.disableRemove = true;
            extColumn.filterable = false;
            extColumn.width = '75px';
            break;
        case InquiryApi.COLUMN_TYPE_ACTION_LIST:
        case InquiryApi.COLUMN_TYPE_ENTITLEMENT_ACTION_LIST:
        case InquiryApi.COLUMN_TYPE_ACCESSIBILITY_ACTION_LIST:
            extColumn.type = ActionCell;
            extColumn.display = 'dropdown';
            extColumn.filterable = false;
            extColumn.disableRemove = true;
            break;
        case InquiryApi.COLUMN_TYPE_HIDDEN:
            extColumn.type = 'hidden';
            break;
        default:
            extColumn.type = 'string';
        }

        return extColumn;
    },
});
