import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import services from 'services';
import userInfo from 'etc/userInfo';
import Sections from '../../collection/user2/sections';

export default Model.extend({

    defaults() {
        return {
            sections: new Sections(),
            userInfo: {},
        };
    },

    sync(method, model, options) {
        const requestData = {};
        let currentUserGroup;
        switch (method) {
        case 'read':
            currentUserGroup = userInfo.get('group');

            if (options && options.userGroup) {
                requestData.userGroup = options.userGroup;
                currentUserGroup = options.userGroup;
            }

            // Should the live read be done only for copy user or for both modify and copy user
            if (options && (options.copyUserId || options.userId)) {
                /**
                 * read live service if copying user data and not just pulling it
                 * regularly on
                 * the UI side
                 */
                const readServiceURL = (options.copyUserId) ? '/users/userCentric/v2/readLive' : '/users/userCentric/v2/read';
                requestData.updateCount = '0';
                requestData.userId = options.copyUserId || options.userId;
                http.post(
                    services.generateUrl(readServiceURL),
                    requestData,
                    options.success,
                    options.error,
                );
            } else {
                http.post(services.generateUrl(`/users/userCentric/v2/getUserEntitlementModel/${currentUserGroup}`), requestData, options.success, options.error);
            }

            break;

        case 'create':
        case 'update':
            http.post(`${services.userCentric}v2/${options.isModify ? 'update' : 'add'}`, model.toJSON(), options.success, options.error);
            break;
        case 'delete':
            break;
        default:
            break;
        }
    },

    parse(response) {
        response.sections = new Sections(response.sections);
        return response;
    },
});
