import Model from '@glu/core/src/model';
import SimpleDataEntitlement from './simpleDataEntitlement';
import SimpleEntitlements from '../../collection/user/simpleEntitlements';

export default Model.extend({

    initialize(obj) {
        this.set(this.parse(obj));
    },

    defaults() {
        return {
            simpleDataEntitlements: new SimpleDataEntitlement({}),
            displayOrder: 0,
            entitlementsByType: new SimpleEntitlements({}),
            typeGroupId: '',
            typeGroupLabel: '',
        };
    },

    parse(response) {
        const { entitlementsByType, simpleDataEntitlements } = response;
        response.simpleDataEntitlements = new SimpleDataEntitlement(simpleDataEntitlements);
        response.entitlementsByType = new SimpleEntitlements(entitlementsByType);
        return response;
    },

    isEntitled() {
        return this.get('entitlementsByType').some(permission => permission.isEntitled());
    },

    isSimpleEntitled() {
        return this.get('entitlementsByType').some(permission => permission.get('entitled'));
    },
});
