// PCM CHANGES ON HOLE FILE - BE AWARE ON DGB MERGE
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import constants from 'app/administration/constants';
import commonConstants from 'common/dynamicPages/api/constants';
import Model from '@glu/core/src/model';
import ItemView from '@glu/core/src/itemView';
import UserGroupLookup from 'app/administration/views/userMaintenance/userGroupLookup';
import AdminUtil from 'app/administration/common/util';
import patterns from 'system/validatorPatterns';
import addRoleDialogTmpl from './addRoleDialog.hbs';

/**
 * @config {Boolean} fromForm - true if this is an edit and the origin is
 * the add form
 * @config {Boolean} modifyMode - true if the action (mode) is MODIFY
 * @config {String} cloneId
 * @config {Boolean} isAdmin
 * @config {String} userGroup
 * @config {String} bankMaintained the value of the bank maintained flag
 */
export default ItemView.extend({
    template: addRoleDialogTmpl,

    ui: {
        userGroupCombo: '.usergroup-select',
    },

    initialize(opts) {
        const mergeData = this.getMergeData(opts);
        // set values of mergeData to this object as variables
        util.extend(this, mergeData);
        // create a new model using the values in mergeData
        this.model = new Model(mergeData);
        this.setupDialog();
        this.userGroupLookup = new UserGroupLookup({
            isAdmin: this.isAdmin,
        });
        this.createValidators();
    },

    getMergeData(opts) {
        let mergeData = {};
        if (opts) {
            mergeData = util.pick(
                opts,
                'bankMaintained',
                'cloneId',
                'fromForm',
                'isAdmin',
                'modifyMode',
                'roleDesc',
                'roleName',
                'userGroup',
            );
        }
        return mergeData;
    },

    setupDialog() {
        this.dialogTitle = locale.getDefault(this.cloneId ? 'rolebased.clone-role' : 'rolebased.add-new-role', 'Add New Role');

        const saveButton = (this.fromForm ? {
            text: locale.get('administration.save.btn'),
            className: 'btn btn-primary',
            callback: 'onSave',
        } : {
            text: locale.get('administration.continue.btn'),
            className: 'btn btn-primary',
            callback: 'continueAddRole',
        });

        this.dialogButtons = [
            saveButton,
            {
                text: locale.get('administration.cancel.btn'),
                className: 'btn btn-tertiary',
                callback: 'cancel',
            },
        ];
    },

    createValidators() {
        this.model.validators = {
            userGroup: {
                description: locale.get('rolebased.select-company'),
                exists: true,
            },

            roleName: {
                description: locale.get('rolebased.rolename'),
                matches: patterns.ALPHANUMERIC_PATTERN,
                minLength: 4,
                exists: true,
            },

            roleDesc: {
                description: locale.get('rolebased.roledesc'),
                matches: patterns.ALPHANUMERICSPACE_PATTERN,
                exists: true,
            },
        };
    },

    onRender() {
        if (!this.fromForm) {
            const lookup = this.userGroupLookup;
            let comboData;

            if (this.userGroup) {
                comboData = {
                    data: [{
                        id: this.userGroup,
                        text: this.userGroup,
                    }],
                };

                this.model.set('userGroup', this.userGroup);

                // this.ui.userGroupCombo.prop('disabled', true); PCM-6698
            } else {
                comboData = {
                    query: util.debounce((query) => {
                        lookup.setSearch(query.term);
                        lookup.request.rowsPerPage = query.term.length < 3 ? 10 : 50;
                        lookup.send().then((result) => {
                            query.callback({
                                results: result.data,
                            });
                        });
                    }, commonConstants.COMBO_DEBOUNCE),
                };
            }
            this.userGroupCombo = this.ui.userGroupCombo.comboBox(comboData);
        }
    },

    onSave() {
        this.options.onSave(this.model);
        dialog.close();
    },

    continueAddRole() {
        if (this.model.isValid()) {
            store.set(
                'rolebased-load',
                {
                    userGroup: this.model.get('userGroup'),
                    roleName: this.model.get('roleName'),
                    roleDesc: this.model.get('roleDesc'),
                    cloneId: this.cloneId,
                    bankMaintained: this.model.get('bankMaintained'),
                },
            );

            this.navigateTo(this.cloneId
                ? constants.ROUTES.CLONE_ROLE
                : constants.ROUTES.ADD_ROLE);
            dialog.close();
        }
    },

    cancel() {
        dialog.close();
    },

    templateHelpers() {
        return {
            fromForm: this.fromForm,
            modifyMode: this.modifyMode,
            bankMaintainedRolesEnabled:
                AdminUtil.isBankMaintainedRolesEnabled() && this.isAdmin,
        };
    },
});
