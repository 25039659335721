var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"glu-container\">\n    <div class=\"row\">\n        <div class=\"col-3\">\n            <div class=\"form-group\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.EnteredBy",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":23},"end":{"line":5,"column":49}}}))
    + "</label>\n                <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"ENTERED_BY") || (depth0 != null ? lookupProperty(depth0,"ENTERED_BY") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"ENTERED_BY","hash":{},"data":data,"loc":{"start":{"line":6,"column":47},"end":{"line":6,"column":61}}}) : helper)))
    + "</p>\n            </div>\n        </div>\n        <div class=\"col-3\">\n            <div class=\"form-group\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.EnteredOn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":23},"end":{"line":11,"column":49}}}))
    + "</label>\n                <p class=\"form-control-static\">"
    + alias2(lookupProperty(helpers,"date").call(alias1,(depth0 != null ? lookupProperty(depth0,"ENTERED_TIMESTAMP") : depth0),{"name":"date","hash":{},"data":data,"loc":{"start":{"line":12,"column":47},"end":{"line":12,"column":73}}}))
    + "</p>\n            </div>\n        </div>\n        <div class=\"col-3\">\n            <div class=\"form-group\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.Note",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":23},"end":{"line":17,"column":47}}}))
    + "</label>\n                <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"NOTE_TEXT") || (depth0 != null ? lookupProperty(depth0,"NOTE_TEXT") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"NOTE_TEXT","hash":{},"data":data,"loc":{"start":{"line":18,"column":47},"end":{"line":18,"column":60}}}) : helper)))
    + "</p>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});