import Controller from '@glu/core/src/controller';
import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import Constants from 'app/smbPayments/constants';
import transform from 'common/util/transform';

const defaultValues = [{
    name: '_productCode',
    value: '_ADMIN',
}, {
    name: '_typeCode',
    value: 'BENEADBK',
}, {
    name: 'type',
    value: 'BENEADBK',
}, {
    name: '_functionCode',
    value: 'MAINT',
}, {
    name: 'functionCode',
    value: 'MAINT',
}, {
    name: 'FUNCTION',
    value: 'MAINT',
}, {
    name: '_subTypeCode',
    value: '*',
}, {
    name: 'SUBTYPE',
    value: '*',
}, {
    name: 'PRODUCT',
    value: '_ADMIN',
}];

const DataConverter = Controller.extend({
    getDefaultsCheckType(accountType, beneBankIdType) {
        if (accountType === 'Other' && (beneBankIdType === null || beneBankIdType === undefined)) {
            return this.generateOtherAccountTypeDefaults().concat(defaultValues);
        }
        return defaultValues;
    },

    getDefaults(accountType) {
        if (accountType === 'Other') {
            return this.generateOtherAccountTypeDefaults().concat(defaultValues);
        }
        return defaultValues;
    },

    generateDefaults() {
        return [{
            name: 'BENE_NAME_CHILD',
            value: `Acme Inc Random ${(new Date()).getTime()}`,
        }];
    },

    generateOtherAccountTypeDefaults() {
        return [{
            name: 'BENE_BANK_IDTYPE',
            value: 'ABA',
        }];
    },

    generateIBANAccountTypeDefaults() {
        return [{
            name: 'BENE_BANK_IDTYPE',
            value: 'SWIFT',
        }];
    },

    filterPairs(pairs, list) {
        return util.filter(pairs, pair => list.indexOf(pair.name) > -1);
    },

    createRequest(hash, headerOnly) {
        const pairs = transform.hashToPairs(hash);

        if (headerOnly) {
            return Object.assign({}, this.createHeader(pairs));
        }
        return Object.assign({}, this.createHeader(pairs), this.createDetail(hash.accounts));
    },

    createDetail(accountList) {
        // Handle multiple accounts
        const detailGridItems = [];
        const self = this;

        // Create at least 1 account per item in the accountList
        util.each(accountList, (accountParam, acctGrpNum) => {
            const account = accountParam;
            let paymentTypes = account.PAYMENTTYPE;
            let accountType = account.BENE_ACCOUNTTYPE;
            let paymentTypeCollection = account.PAYMENTTYPE_COLLECTION || false;

            if (paymentTypes && !util.isArray(paymentTypes)) {
                paymentTypes = [paymentTypes];
            }

            paymentTypes = util.uniq(paymentTypes);

            // Create one grid item for every payment type
            if (paymentTypeCollection) {
                const currentBankRecNums = account.CURRENTBANKRECNUMS;
                const originalTnums = account.ORIGINALTNUMS;

                // Call the smb convert functions which will do nothing to corp payment types
                paymentTypeCollection = self.smbConvertToServerPaymentTypes(
                    paymentTypeCollection,
                    currentBankRecNums,
                    originalTnums,
                );

                paymentTypes = self.smbConvertToServerPaymentTypeCodes(paymentTypes);

                util.each(paymentTypes, (paymentType) => {
                    const tmpHash = util.extend({}, account);
                    // TODO: self.paymentTypeCollection.get(paymentType),
                    const paymentModel = paymentTypeCollection.get(paymentType);
                    let secondaryHash;
                    let secondaryPairs;

                    if (paymentModel) {
                        const payTypeBankCode = paymentModel.get('bankCode');
                        let isFreeForm = false;

                        if (paymentModel.get('BENEBANKIDENTRYMETHOD')) {
                            tmpHash.BENEBANKIDENTRYMETHOD = paymentModel.get('BENEBANKIDENTRYMETHOD');
                            isFreeForm = (tmpHash.BENEBANKIDENTRYMETHOD === 'FREEFORM');
                        }

                        tmpHash.PAYMENTTYPE = paymentType;
                        tmpHash.BENE_NAME_CHILD = tmpHash.BENE_NAME;
                        tmpHash.CLEARINGSYSTEM = paymentModel.get('clearingSystem');
                        tmpHash.PAYMENTPRODUCT = paymentModel.get('product');
                        tmpHash.BENE_BANK_STATE = paymentModel.get('payTypeLevelBankStateCode');

                        if (isFreeForm && paymentModel.get('name') === 'FEDWIRE') {
                            tmpHash.BENE_BANK_IDTYPE = 'ABA';
                        } else if (paymentModel.get('payTypeLevelSortCodeType')) {
                            tmpHash.BENE_BANK_IDTYPE = paymentModel.get('payTypeLevelSortCodeType');
                        }

                        tmpHash.BENE_BANK_COUNTRY = paymentModel.get('payTypeLevelBankCountryCode') || tmpHash.BENE_BANK_COUNTRY;

                        if (paymentModel.get('payTypeLevelBankAddress1')) {
                            tmpHash.BENE_BANK_ADDRESS_1 = paymentModel.get('payTypeLevelBankAddress1');
                        }

                        if (paymentModel.get('payTypeLevelBankAddress2')) {
                            tmpHash.BENE_BANK_ADDRESS_2 = paymentModel.get('payTypeLevelBankAddress2');
                        }

                        if (paymentModel.get('payTypeLevelBankAddress3')) {
                            tmpHash.BENE_BANK_ADDRESS_3 = paymentModel.get('payTypeLevelBankAddress3');
                        }

                        if (!account.PRIMARY_ACCOUNT || account.PRIMARY_ACCOUNT === 'N') {
                            tmpHash.PRIMARY_ACCOUNT = 'N';
                        } else {
                            tmpHash.PRIMARY_ACCOUNT = 'Y';
                        }

                        tmpHash.ACCTGROUPNUM = acctGrpNum;
                        tmpHash.SECONDARY_IND = '0';

                        // reset tnum. Should come from payment model
                        tmpHash.TNUM = null;
                        tmpHash.RECNUM = paymentModel.get('recnum');
                        tmpHash.TNUM = paymentModel.get('tnum');

                        if (tmpHash.BENE_ACCOUNTTYPE &&
                            tmpHash.BENE_ACCOUNTTYPE.search(/other/gi) > -1) {
                            tmpHash.BENE_ACCOUNTTYPE = 'Other';
                        }

                        accountType = tmpHash.BENE_ACCOUNTTYPE;

                        if (tmpHash.BENE_BANK_ID_SECONDARY) {
                            secondaryHash = util.extend({}, tmpHash);
                            if (payTypeBankCode === tmpHash.BENE_BANK_ID_SECONDARY) {
                                secondaryHash.SECONDARY_IND = '1';
                                secondaryHash.BENE_BANK_ID =
                                    secondaryHash.BENE_BANK_ID_SECONDARY;
                                secondaryHash.BENE_BANK_NAME =
                                    secondaryHash.BENE_BANK_NAME_SECONDARY;
                                secondaryHash.BENE_BANK_ADDRESS_1 =
                                    paymentModel.get('payTypeLevelBankAddress1');
                                secondaryHash.BENE_BANK_ADDRESS_2 =
                                    paymentModel.get('payTypeLevelBankAddress2');
                                secondaryHash.BENE_BANK_ADDRESS_3 =
                                    paymentModel.get('payTypeLevelBankAddress3');
                                secondaryPairs = transform.hashToPairs(secondaryHash);
                                secondaryPairs =
                                    secondaryPairs.concat(self.getDefaultsCheckType(
                                        accountType,
                                        secondaryHash.BENE_BANK_IDTYPE,
                                    ));
                                secondaryPairs = self.filterPairs(
                                    secondaryPairs,
                                    Constants.DETAILLIST,
                                );
                                detailGridItems.push({
                                    item: secondaryPairs,
                                });
                            }
                        }

                        if ((payTypeBankCode === tmpHash.BENE_BANK_ID) || isFreeForm) {
                            let tmpItem = transform.hashToPairs(tmpHash)
                                .concat(self.getDefaultsCheckType(
                                    accountType,
                                    tmpHash.BENE_BANK_IDTYPE,
                                ));
                            tmpItem = self.filterPairs(tmpItem, Constants.DETAILLIST);
                            detailGridItems.push({
                                item: tmpItem,
                            });
                        }

                        /*
                         * FIXME:
                         * We really need a UI validation solution for the case where no entry to
                         * the detailGridItems array occurs.
                         *
                         * Ex: if there's bad metadata (in this case the RECEIVRBANKCODE has an
                         * eroneous space before the numbers for this account),
                         * the 'if ((payTypeBankCode === tmpHash.BENE_BANK_ID) || isFreeForm) {'
                         * conditional fails and the entire account silently isn't saved
                         */
                    }
                });
            } else {
                account.ACCTGROUPNUM = acctGrpNum;
                detailGridItems.push({
                    item: transform.hashToPairs(account),
                });
            }
        });

        return {
            grids: [{
                name: 'BENEADDRESSBOOK_Grid',
                items: detailGridItems,
            }],
        };
    },

    createHeader(pairs) {
        let arr = pairs.concat(this.getDefaults());
        arr = this.filterPairs(arr, Constants.HEADERLIST);
        return {
            item: {
                item: arr,
            },
        };
    },

    reduce(result) {
        const data = transform.pairsToHash(result.item);
        let accountListData = util.map(result.detailRecords, (record) => {
            let hash = transform.pairsToHash(record);
            const tmp = util.extend({}, data);

            hash = util.extend(tmp, hash);

            if (!hash.BENE_ACCOUNTNUMBER && !hash.BENE_BANK_ID) {
                hash.BENE_ACCOUNTNUMBER = 'DRAFT';
                hash.BENE_BANK_ID = 'DRAFT';
                hash.DRAFT_PAYMENT_ENABLED = 'DRAFT';
            }

            if (hash.PAYMENTTYPE === 'BPAY') {
                hash.BPAY_ACCOUNTNUMBER = hash.BENE_ACCOUNTNUMBER;
                hash.BENE_BANK_CODE_PRIMARY = hash.BENE_BANK_ID;
                hash.BENE_ACCOUNTNUMBER = 'BPAY';
                hash.BENE_BANK_ID = 'BPAY';
            }

            if (hash.BENE_ACCOUNTTYPE === 'IBAN') {
                hash.BENE_BANK_CODE = hash.BENE_ACCOUNTNUMBER;
            }

            hash.PAYMENTTYPE = [hash.PAYMENTTYPE];
            hash.PAYMENTTYPE_COLLECTION = [{
                accountType: hash.BENE_ACCOUNTTYPE,
                bankCode: hash.BENE_BANK_ID,
                clearingSystem: hash.CLEARINGSYSTEM,
                id: hash.PAYMENTTYPE,
                label: hash.TYPE_DESCRIPTION,
                name: hash.PAYMENTTYPE,
                payTypeLevelBankCountryCode: hash.BENE_BANK_COUNTRY,
                payTypeLevelBankStateCode: hash.BENE_BANK_STATE,
                payTypeLevelSortCodeType: hash.BENE_BANK_IDTYPE,
                product: hash.PAYMENTPRODUCT,
                tnum: hash.TNUM,
                recnum: hash.RECNUM,
                value: hash.PAYMENTTYPE,
            }];
            return hash;
        });

        accountListData = util.values(util.reduce(accountListData, (memoParam, record) => {
            const memo = memoParam;
            const accountKey = `${record.BENE_BANK_ID}~${record.BENE_ACCOUNTNUMBER}`;
            const accountKeySecondary = `${record.BENE_BANK_CODE_PRIMARY}~${record.BENE_ACCOUNTNUMBER}`;
            const bankKey = record.BENE_BANK_ID;
            const bankKeySecondary = record.BENE_BANK_CODE_PRIMARY;

            if (memo[accountKey]) {
                const currMemo = memo[accountKey][bankKey];

                if (currMemo) {
                    if (util.isString(record.BENE_ACCOUNTTYPE) && record.BENE_ACCOUNTTYPE.toUpperCase() !== 'OTHER' && record.BENE_ACCOUNTTYPE.toUpperCase() !== 'IBAN') {
                        currMemo.BENE_ACCOUNTTYPE = record.BENE_ACCOUNTTYPE;
                    }
                    currMemo.PAYMENTTYPE =
                        currMemo.PAYMENTTYPE.concat(record.PAYMENTTYPE);
                    currMemo.PAYMENTTYPE_COLLECTION =
                        currMemo.PAYMENTTYPE_COLLECTION.concat(record.PAYMENTTYPE_COLLECTION);
                } else {
                    memo[accountKey][bankKey] = record;
                }
            } else if (memo[accountKeySecondary]) {
                const currMemo = memo[accountKeySecondary][bankKeySecondary];
                if (currMemo) {
                    currMemo.SECONDARY_CODE = true;
                    currMemo.BENE_BANK_ID_SECONDARY = bankKey;
                    currMemo.BENE_BANK_NAME_SECONDARY = record.BENE_BANK_NAME;
                    currMemo.BANK_ACCOUNT_TEXT_SECONDARY = `${record.BENE_BANK_ID} - ${record.BENE_BANK_NAME}`;
                    currMemo.BENE_BANK_IDTYPE_SECONDARY = record.BENE_BANK_IDTYPE;
                    currMemo.PAYMENTTYPE = currMemo.PAYMENTTYPE.concat(record.PAYMENTTYPE);
                    currMemo.PAYMENTTYPE_COLLECTION =
                        currMemo.PAYMENTTYPE_COLLECTION.concat(record.PAYMENTTYPE_COLLECTION);
                } else {
                    memo[accountKeySecondary][bankKeySecondary] = record;
                }
            } else {
                memo[accountKey] = {};
                memo[accountKey][bankKey] = record;
            }

            return memo;
        }, {}));

        return {
            data,
            accountList: accountListData,
        };
    },

    smbSetPaymentTypeLabels(paymentTypeResponse) {
        /*
         * Change labels to the SMB-specific ones.  This is a modify-in-place
         */
        util.each(paymentTypeResponse.paymentTypes, (paymentTypeParam) => {
            const paymentType = paymentTypeParam;
            const label = util.find(
                paymentTypeResponse.smbCheckBoxMap,
                mapElement => mapElement.payTypeCode.indexOf(paymentType.id) > -1,
            );

            if (label) {
                // only change the label if one is defined
                paymentType.label = label.name;
            }
        });
    },

    smbConvertFromServerPaymentTypes(paymentTypeResponseParam) {
        const paymentTypeResponse = paymentTypeResponseParam;
        /*
         * Payroll and another ACH payment type might be present, but need to be
         * represented by one payment type. Replace the payment type with one
         * representing both, and remove the duplicate.  This is a modify-in-place
         */
        const payrollPT = util.findWhere(
            paymentTypeResponse.paymentTypes,
            {
                id: Constants.PAYROLL,
            },
        );
        const consumerPaymentPT = util.findWhere(
            paymentTypeResponse.paymentTypes,
            {
                id: Constants.CONSUMERPAYMENT,
            },
        );
        const corporateVendorPaymentPT = util.findWhere(
            paymentTypeResponse.paymentTypes,
            {
                id: Constants.CORPORATEPAYMENT,
            },
        );
        const consumerCollectionPaymentPT = util.findWhere(
            paymentTypeResponse.paymentTypes,
            {
                id: Constants.CONSUMERCOLLECTION,
            },
        );
        if (payrollPT && (consumerPaymentPT || corporateVendorPaymentPT ||
            consumerCollectionPaymentPT)) {
            payrollPT.id = consumerPaymentPT ? Constants.PAYROLL_AND_CP_TYPE :
                Constants.PAYROLL_AND_CVP_TYPE;
            paymentTypeResponse.paymentTypes = util.reject(
                paymentTypeResponse.paymentTypes,
                paymentType => paymentType.id === Constants.CONSUMERPAYMENT ||
                    paymentType.id === Constants.CORPORATEPAYMENT,
            );
        }
    },

    smbConvertToServerPaymentTypes(paymentTypeCollection, currentBankRecNums, originalTnums) {
        /*
         * This is mostly an inverse of smbConvertFromServerPaymentTypes, except that this
         * function does not alter
         * the input parameters, and works on a Collection
         */
        const updatedPaymentTypeCollection = new Collection();

        paymentTypeCollection.each((paymentType) => {
            if (paymentType.id === Constants.PAYROLL_AND_CP_TYPE ||
                paymentType.id === Constants.PAYROLL_AND_CVP_TYPE) {
                /*
                 * We have payroll and either consumer payments or corporate vendor
                 * payments.  Convert back to
                 * payroll and the other ACH payment type
                 */
                updatedPaymentTypeCollection.add(this.getNewPaymentTypes(
                    paymentType,
                    currentBankRecNums,
                    originalTnums,
                ));
            } else {
                paymentType.set('tnum', originalTnums ?
                    originalTnums.get(paymentType.id) : undefined);
                updatedPaymentTypeCollection.add(paymentType);
            }
        });

        return updatedPaymentTypeCollection;
    },

    /**
     * Convert from the base back to payroll and the other ACH payment type
     * @param  {Model} basePaymentType The incoming payment type
     * @param  {Model} recNums a map of server side recnum values for current bank's
     * supported payment types. Key is payment type code.
     * @param  {Model} tnums a map of TNUMS for payment types currently selected
     * for this beneficiary. Key is payment type code.
     * @return {Array}
     */
    getNewPaymentTypes(basePaymentType, recNums, tnums) {
        const payrollType = basePaymentType.clone();
        payrollType.id = Constants.PAYROLL;

        /*
         * We need to restore the RECNUM associated with the bank in order to avoid
         * losing one of the ACH payment types. And the TNUM as well if the type is
         * entitled in order to avoid duplicate tnums in BENEADDRESSBOOK and
         * false payment type deletion conditions.
         */
        payrollType.set({
            id: Constants.PAYROLL,
            name: Constants.PAYROLL,
            value: Constants.PAYROLL,
            recnum: recNums ? recNums.get(payrollType.id) : undefined,
            tnum: tnums ? tnums.get(payrollType.id) : undefined,
        });

        const paymentType = basePaymentType.clone();
        const label = basePaymentType.id === Constants.PAYROLL_AND_CP_TYPE ?
            Constants.CONSUMERPAYMENT : Constants.CORPORATEPAYMENT;
        paymentType.id = label;
        paymentType.set({
            id: label,
            name: label,
            value: label,
            recnum: recNums ? recNums.get(paymentType.id) : undefined,
            tnum: tnums ? tnums.get(paymentType.id) : undefined,
        });

        return [payrollType, paymentType];
    },

    smbConvertFromServerPaymentTypeCodes(payTypeCodes) {
        /*
         * Payroll and another ACH payment type code might be present, but need to be
         * represented by one payment type code. Replace the payment type code with one
         * representing both, and remove the duplicate
         */
        let updatedPayTypeCodes = util.clone(payTypeCodes);
        if (util.contains(updatedPayTypeCodes, Constants.PAYROLL)) {
            const achPaymentType = util.find(
                updatedPayTypeCodes,
                payType => payType === Constants.CORPORATEPAYMENT ||
                payType === Constants.CONSUMERPAYMENT,
            );
            if (achPaymentType) {
                /*
                 * remove the ACH payment type, and replace payroll
                 * with a type representing both
                 */
                updatedPayTypeCodes = util.without(updatedPayTypeCodes, achPaymentType);
                const newPayrollType = achPaymentType === Constants.CORPORATEPAYMENT ?
                    Constants.PAYROLL_AND_CVP_TYPE : Constants.PAYROLL_AND_CP_TYPE;
                updatedPayTypeCodes[util.indexOf(updatedPayTypeCodes, Constants.PAYROLL)] =
                    newPayrollType;
            }
        }
        return updatedPayTypeCodes;
    },

    smbConvertToServerPaymentTypeCodes(payTypeCodes) {
        /*
         * This is an inverse of smbConvertFromServerPaymentTypeCodes
         */
        const updatedPayTypeCodes = [];

        util.each(payTypeCodes, (paymentType) => {
            if (paymentType === Constants.PAYROLL_AND_CVP_TYPE) {
                updatedPayTypeCodes.push(Constants.PAYROLL);
                updatedPayTypeCodes.push(Constants.CORPORATEPAYMENT);
            } else if (paymentType === Constants.PAYROLL_AND_CP_TYPE) {
                updatedPayTypeCodes.push(Constants.PAYROLL);
                updatedPayTypeCodes.push(Constants.CONSUMERPAYMENT);
            } else {
                updatedPayTypeCodes.push(paymentType);
            }
        });

        return updatedPayTypeCodes;
    },
});

export default DataConverter;
