const ALERT_DELIVERY_TYPE = {
    EMAIL: 'EMAIL',
    SMS: 'SMS',
};

const ALERTS_CONTEXT = {
    productCode: 'CMAINT',
    functionCode: 'MAINT',
    typeCode: 'ADMALERT',
};
const FOOTERS_CONTEXT = {
    productCode: 'CMAINT',
    functionCode: 'MAINT',
    typeCode: 'TMPALERT',
};
const MODE = {
    MODIFY: 'MODIFY',
    INSERT: 'INSERT',
    VIEW: 'VIEW',
    DELETE: 'DELETE',
    APPROVE: 'APPROVE',
    SELECT: 'SELECT',
    COPY: 'COPY',
};
const CONTENT_TYPE_ID = {
    BANK_DEFINED: 'BD',
    SYSTEM_DEFINED: 'SD',
};

const ACTION = {
    APPROVE: 'approve',
    DELETE: 'delete',
};

export {
    ALERT_DELIVERY_TYPE,
    ALERTS_CONTEXT,
    FOOTERS_CONTEXT,
    MODE,
    CONTENT_TYPE_ID,
    ACTION,
};
