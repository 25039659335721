import jquery from 'jquery';
import http from '@glu/core/src/http';
import services from 'services';
// Dancing around eslint hating $.Deferred()
const jqDeferred = jquery.Deferred;

export default {
    /**
     * Change the password. Formatting wrapper around updateUser.
     * @param {string} userGroup
     * @param {string} userId
     * @param {string|number} updateCount
     * @param {string} newPassword
     * @returns {Promise}
     */
    changePassword(userGroup, userId, updateCount, newPassword) {
        const req = {
            item: {
                item: [{
                    name: 'USERGROUP',
                    value: userGroup,
                }, {
                    name: 'USERID',
                    value: userId,
                }, {
                    name: 'UPDATECOUNT__',
                    value: updateCount,
                }, {
                    name: newPassword ? 'PASSWORD' : 'EMAILRESETPASSWORD',
                    value: newPassword || 'resetPassword',
                }],
            },
        };

        return this.updateUser(req).then((data) => {
            // If it's a "200 OK" error, make it an error.
            if (data.result === false) {
                // response format is a little tedious, but allows us to reuse the error handler
                const errorData = {
                    responseText: JSON.stringify({
                        responseHeader: {
                            result: false,
                            message: data.confirms.confirmResults[0].messages,
                        },
                    }),
                };

                // Pass a rejected promise to trigger the error handling
                return jqDeferred().reject(errorData);
            }
            return data;
        });
    },

    /**
     * Generic updateUser command
     * @param {Object} data - the formatted request
     * @returns {Promise}
     */
    updateUser(data) {
        return http.post(services.resetPassword, data);
    },
};
