import Layout from '@glu/core/src/layout';
import CollectionView from '@glu/core/src/collectionView';
import constants from 'app/administration/constants';
import GroupView from './groupView';
import alertsTmpl from './alerts.hbs';

export default Layout.extend({
    template: alertsTmpl,

    ui: {
        groupsRoot: '.alerts-groups-root',
    },

    initialize(opts) {
        const groupCollection = this.model.get('simpleTypes');

        if (opts.mode === constants.MODES.VIEW) {
            groupCollection.reset(groupCollection.filter(group => group.isSimpleEntitled()));
        }

        this.groupsCollectionView = new CollectionView({
            collection: groupCollection,
            itemView: GroupView,

            itemViewOptions: {
                mode: opts.mode,
            },
        });
    },

    onRender() {
        this.groupRegion.show(this.groupsCollectionView);
    },
});
