import React from 'react';
import { FunnelAshIcon } from '@glu/icons-react';
import locale from '@glu/locale';
import PropTypes from 'prop-types';

const FilterIcon = ({ onClick }) => (
  <FunnelAshIcon actionable title={locale.get('filters')} onClick={onClick} />
);

FilterIcon.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default FilterIcon;
