import ItemView from '@glu/core/src/itemView';
import accountTotalsViewTmpl from './accountTotalsView.hbs';

const AccountTotalsView = ItemView.extend({
    template: accountTotalsViewTmpl,

    initialize(options) {
        this.isTieredLoans = options.isTieredLoans;
        this.model = options.model;
        this.listenTo(options.model, 'sync', this.render);
    },
    templateHelpers() {
        if (!this.isTieredLoans) {
            return {};
        }
        const totals = this.model.get('currencyTotals') &&
this.model.get('currencyTotals')[0];
        return {
            field1Label: totals?.field1Label,
            field1Amount: totals?.field1Amount,
            field2Label: totals?.field2Label,
            field2Amount: totals?.field2Amount,
        };
    },
});

export default AccountTotalsView;
