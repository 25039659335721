import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import store from '@glu/store';
import alert from '@glu/alerts';
import locale from '@glu/locale';
import numeral from 'numeral';
import moment from 'moment';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import scroll from 'common/util/scroll';
import userInfo from 'etc/userInfo';
import Formatter from 'system/utilities/format';
import Lookup from 'app/smbInvoice/services/typeaheadLookup';
import EditableTableView from 'app/smbInvoice/views/editableTable';
import InvoiceModel from 'app/smbInvoice/models/invoice';
import AddressBookEntry from 'app/smbInvoice/models/addressBookEntry';
import AddNewAddressEntryView from 'app/smbInvoice/views/addNewAddressEntry';
import invoiceFormTmpl from './invoiceForm.hbs';

export default Layout.extend({
    template: invoiceFormTmpl,
    className: 'bill-entry-form full-page',

    regions: {
        alertRegion: 'div[data-region="alert-region"]',
        lineItemRegion: 'div[data-region="line-items"]',
    },

    ui: {
        $totalAmountField: 'input[name="TOTAL_AMOUNT"]',
        $lineItemTotal: '.line-item-total',
        $lineItemTotalValue: '.line-item-total .value',
        $lineItemContainer: '.line-item-container',
        $addVendorBtn: 'button[data-hook="add-new-vendor"]',
        $vendorTypeaheadContainer: '.field-container.vendor-id',
        $addCustomerBtn: 'button[data-hook="add-new-customer"]',
        $customerTypeaheadContainer: '.field-container.customer-id',
        $saveDraftButton: 'button[data-action="savedraft"]',
    },

    events: {
        'click @ui.$addVendorBtn': 'showAddVendor',
        'click @ui.$addCustomerBtn': 'showAddCustomer',
    },

    initialize(options) {
        this.model = new InvoiceModel();

        this.listenTo(
            this.model,
            {
                sync: this.handleSync,
                error: this.handleError,
                'change:TOTAL_AMOUNT': this.updateTotal,
                invalid: this.scrollToError,
            },
            this,
        );

        this.listenTo(
            this.model.get('LINE_ITEMS'),
            {
                'add remove reset change:NET_AMOUNT': this.updateTotal,
            },
        );

        if (options.BILL_ID) {
            this.model.fetch({
                id: options.BILL_ID,
            });
        }

        if (options && options.readOnly) {
            this.readOnly = options.readOnly;
        }
    },

    scrollToError() {
        util.defer(() => {
            scroll.scrollToFirstError();
        });
    },

    edit() {
        this.setReadOnly(false);
        // update URL without actually navigating/reloading the model
        this.appBus.trigger('router:navigate', `INVOICE_BASIC/invoice/modify/${this.model.get('INVOICE_ID')}/`, false);
    },

    setReadOnly(readOnly) {
        this.readOnly = !!readOnly;
        this.render();
    },

    updateTotal() {
        const collectionTotal = this.model.get('LINE_ITEMS').getTotal();

        /*
         * hide the total if the line items total is 0
         * once it !== 0, color code
         */

        const modelTotal = +this.model.get('TOTAL_AMOUNT');
        this.ui.$lineItemTotal.toggleClass('amounts-do-not-match', collectionTotal !== modelTotal).toggle(collectionTotal !== 0);
        this.ui.$lineItemTotalValue.text(numeral(collectionTotal).format(userInfo.get('currencySymbol') + userInfo.getCurrencyFormat()));
    },

    save() {
        if (!this.model.isValid()) {
            return;
        }
        this.model.unset('_saveIncomplete');
        this.model.save();
    },

    savedraft() {
        if (!this.model.isValid()) {
            return;
        }

        this.model.set({
            _saveIncomplete: 'true',
        });

        this.model.save();
    },

    cancel() {
        window.history.back();
    },

    onRender() {
        const self = this;
        this.$('.form-container .input-date').each((i, el) => {
            /*
             * calling datepicker on the collection causes icon clicks to trigger ALL
             * pickers in the collection
             */
            self.$(el).nhDatePicker({
                showCalendarIcon: true,
            });
        });

        this.$('.form-container .comboBox').each((i, el) => {
            const $el = self.$(el);
            $el.comboBox({
                placeholder: $el.is('[data-filter-immediately]') ? locale.get('smbBillMgmt.select.option') : '',
                dropdownAutoWidth: true,
                minimumInputLength: $el.is('[data-filter-immediately]') ? 0 : 3,
                maximumSelectionSize: 1,
                triggerChange: true,

                initSelection(element, callback) {
                    const lookup = new Lookup();
                    const name = element.attr('name');
                    if (lookup.setTextSearchFromValue(name, element.val())) {
                        lookup.send(name).then((result) => {
                            if (result.length > 0) {
                                callback(result[0]);
                            }
                        });
                    }
                },

                query(query) {
                    const lookup = new Lookup();
                    const name = query.element.attr('name');
                    if (lookup.setSearch(name, query.term)) {
                        lookup.send(name).then((result) => {
                            query.callback({
                                results: result,
                            });
                        });
                    } else {
                        query.callback({
                            results: [],
                        });
                    }
                },
            });
        });

        this.ui.$totalAmountField.inputmask('decimal', Formatter.getCurrencyMaskOptions());

        if (this.readOnly) {
            this.$('input, textarea').prop('readonly', true);
        }

        this.updateLineItems();
    },

    updateLineItems() {
        if (this.readOnly && this.model.get('LINE_ITEMS').isEmpty()) {
            this.ui.$lineItemContainer.hide();
        } else {
            this.ui.$lineItemContainer.show();

            this.lineItemRegion.show(new EditableTableView({
                collection: this.model.get('LINE_ITEMS'),
                readOnly: this.readOnly,
            }));

            this.updateTotal();
        }
    },

    showAddVendor() {
        const model = new AddressBookEntry(
            {},
            {
                description: locale.get('smbBillMgmt.new.vendor.name'),
            },
        );

        this.ui.$vendorTypeaheadContainer.after(new AddNewAddressEntryView({
            fieldLabel: locale.get('smbBillMgmt.new.vendor.name'),
            model,
        }).render().el);

        this.listenTo(
            model,
            {
                'change:ID': this.updateVendorIdSelection,
                destroy: this.showVendorTypeAhead,
            },
        );

        this.ui.$vendorTypeaheadContainer.hide();
    },

    showVendorTypeAhead(model) {
        this.stopListening(model);
        this.ui.$vendorTypeaheadContainer.show();
    },

    updateVendorIdSelection(model, value) {
        this.model.set({
            VENDOR_ID: value,
        });
        this.$('[name="VENDOR_ID"]').comboBox(
            'data',
            {
                id: value,
                text: model.get('Name'),
            },
        );
        model.destroy();
    },

    showAddCustomer() {
        const model = new AddressBookEntry(
            {},
            {
                description: locale.get('smbBillMgmt.new.customer.name'),
            },
        );

        this.ui.$customerTypeaheadContainer.after(new AddNewAddressEntryView({
            fieldLabel: locale.get('smbBillMgmt.new.customer.name'),
            model,
        }).render().el);

        this.listenTo(
            model,
            {
                'change:ID': this.updateCustomerIdSelection,
                destroy: this.showCustomerTypeAhead,
            },
        );

        this.ui.$customerTypeaheadContainer.hide();
    },

    showCustomerTypeAhead(model) {
        this.stopListening(model);
        this.ui.$customerTypeaheadContainer.show();
    },

    updateCustomerIdSelection(model, value) {
        this.model.set({
            CUSTOMER_ID: value,
        });
        this.$('[name="CUSTOMER_ID"]').comboBox(
            'data',
            {
                id: value,
                text: model.get('Name'),
            },
        );
        model.destroy();
    },

    updateDatePickers() {
        const fields = ['DUE_DATE', 'ISSUE_DATE'];
        util.each(fields, function (field) {
            const date = moment(this.model.get(field));
            const datePicker = this.$(`input[name="${field}"]`).data('daterangepicker');

            datePicker.setStartDate(date);
            datePicker.setEndDate(date);
        }, this);
    },

    updateButtons() {
        const canModify = this.model.canBeModified();
        this.$('button[data-action="edit"]').toggle(canModify).attr('disabled', !canModify);
        this.ui.$saveDraftButton.toggle(this.model.get('STATUS') === 'IC');
    },

    handleSync(model, resp, options) {
        // read will also fire sync - ignore
        if (options.read) {
            this.updateDatePickers();
            this.updateButtons();
            this.updateLineItems();

            return;
        }

        if (resp.result === false) {
            this.handleError(resp);
            return;
        }

        if (this.model.get('ENTERANOTHER')) {
            // set up a new model + listeners
            this.initialize({});
            this.render();
            this.renderMessage('INSERT', resp);
        } else {
            store.set('bosBillMessage', resp);
            window.history.back();
        }
    },

    scrollToAlertRegion() {
        const regionLoc = this.$('div[data-region="alert-region"]').offset();
        window.scrollTo(regionLoc.left, regionLoc.top);
    },

    handleError(resp) {
        this.renderMessage('INSERT', resp);
    },

    // TODO find a way to refactor/reuse this or clean it up - duped everywhere!
    templateHelpers() {
        return {
            // this is how we should toggle between read-only and view
            id: this.cid,

            readOnly: this.readOnly,
        };
    },

    renderMessage(action, confirmResponse, errorCount) {
        /*
         * create the confirmation view for the alert
         * skip if action is null.  This occurs when first entering the payment workspace
         * because the onRender is called.
         */
        if ((action === null || action === undefined)
            && (confirmResponse === null || confirmResponse === undefined)) {
            // TODO: refactor out empty block
        } else if (confirmResponse === null
            || confirmResponse === undefined
            || confirmResponse.confirms === null
            || confirmResponse.confirms === undefined) {
            if (action.type === 'WARNING' && action.message) {
                this.alertView = alert.warning(action.message);
                this.alertRegion.show(this.alertView);
            }
        } else {
            const confirm = typeof confirmResponse === 'object'
                ? confirmResponse.confirms : null;
            const successCount = confirm !== null
                ? confirm.totalSuccess : confirmResponse;
            const failCount = confirm !== null
                ? confirm.totalFail : errorCount;
            const totalCount = failCount + successCount;
            const success = successCount === totalCount;
            const alertFunc = success ? alert.success : alert.danger;
            const chainedMessages = typeof confirmResponse === 'object'
                ? confirmResponse.message : null;
            const message = typeof confirmResponse === 'object'
                && confirmResponse.message.length > 0
                ? confirmResponse.message[chainedMessages.length - 1] : action;

            const confirms = new Confirms({
                confirms: confirmResponse ? confirmResponse.confirms : null,
            });

            // display notification message
            this.alertView = alertFunc(
                message,
                {
                    details: confirmResponse
                    && confirmResponse.confirms.confirmResults[0].confirmData[0].item
                        ? confirms : null,
                    canDismiss: !!confirmResponse,
                    animate: false,
                },
            );

            if (message) {
                this.alertRegion.show(this.alertView);
                this.scrollToAlertRegion();
            }
        }
    },
});
