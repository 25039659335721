import Model from '@glu/core/src/nestedModel';
import Collection from '@glu/core/src/collection';
import ComplexDataEntitlement from './complexDataEntitlement';
import SimpleEntitlements from '../../collection/user/simpleEntitlements';

const ComplexDataEntitlementsCollection = Collection.extend({
    model: ComplexDataEntitlement,
});

export default Model.extend({

    initialize(obj, options) {
        this.set(this.parse(obj));
        if (options) {
            this.isAggregate = options.isAggregate;
        }
    },

    defaults() {
        return {
            typeDataEntitlements: new ComplexDataEntitlementsCollection([{}]),
            displayOrder: 0,
            detailedTypeEntitlements: new SimpleEntitlements(),
            typeCode: '',
            typeCodeLabel: null,
            entitled: false,
            overallBatchLimitForType: {},
            overallTransactionLimitForType: {},
            overallDailyLimitForType: {},
        };
    },

    parse(response = {}) {
        response.typeDataEntitlements = new ComplexDataEntitlementsCollection((response
            && response.typeDataEntitlements) || [{}]);
        response.detailedTypeEntitlements = new SimpleEntitlements((response
            && response.detailedTypeEntitlements) || {});
        return response;
    },

    toJSON(...args) {
        if (this.isAggregate) {
            return {
                typeDataEntitlements: this.get('typeDataEntitlements').toJSON(),
                detailedTypeEntitlements: this.get('detailedTypeEntitlements').toJSON(),
            };
        }

        return Model.prototype.toJSON.call(this, args);
    },

    isEntitled() {
        return this.get('entitled');
    },
});
