import d3 from 'd3';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import moment from 'moment';
import ChartLayout from 'common/chart/views/chartLayout';

export default ChartLayout.extend({
    emptyChartMessage: locale.get('payment.no.data.available'),

    updateChartConfig() {
        const self = this;

        this.chartConfig.data.keys = {
            x: 'date',
            value: ['amount'],
        };

        this.chartConfig.data.type = 'bar';

        this.chartConfig.data.labels = {
            format: {
                amount(v, id, i) {
                    // Add a label for the number of payments on that day
                    if (self.chartData.models[i] !== undefined
                        && self.chartData.models[i].attributes.paymentsQty > 0) {
                        return self.chartData.models[i].attributes.paymentsQty;
                    }
                    return '';
                },
            },
        };

        this.chartConfig.data.colors = {
            amount: '#216bb0',
        };

        this.chartConfig.data.color = function (color, d) {
            // d will be 'id' when called for legends
            if (d.id && d.id === 'predicted') {
                if (d.value < 0) {
                    return 'red';
                }
            }
            return d.id && d.id === 'remaining' ? d3.rgb(color).darker(d.value / 150) : color;
        };
    },

    updateChart(collection) {
        const todayDate = moment(new Date());
        let futureStart = 0;

        // Calculate futureStart if collection is not empty
        if (collection.length > 0) {
            const firstDate = moment(collection.models[0].get('date'), this.dateFormatStr);
            futureStart = todayDate.diff(firstDate, 'days');
        }

        if (!this.chart || !this.chart.c3) {
            return;
        }

        this.chart.c3.unload({
            ids: 'amount',
        });

        /*
         * TODO: Find out why all the data doesn't load when we use
         * this.chart.load instead of rerendering the entire chart
         * this.showChart();
         */

        this.chart.c3.regions.remove();

        this.chart.c3.load({
            json: collection.toJSON(),

            keys: {
                // it's possible to specify 'x' when category axis
                x: 'date',

                value: ['amount'],
            },
        });

        this.chart.c3.regions.add([{
            axis: 'x',
            start: futureStart,
            class: 'future',
        }]);
    },

    /*
     * Update the data used for the grid for the chart by combining all the payments
     * per day into one data point
     */
    mapDataForChart(collectionParam) {
        const collection = collectionParam;
        let reduced = {};
        const self = this;

        if (collection.length > 0) {
            // sort collection by date
            collection.comparator = function (model) {
                return Date.parse(model.get('due'));
            };
            collection.sort();

            const entries = [];
            const entryCount = collection.models.length;

            // note attribute is due
            const firstDate = moment(
                collection.models[0].attributes.due,
                self.dateFormatStr,
            ).format(self.dateFormatStr);

            // note attribute is due
            const lastDate = moment(
                collection.models[entryCount - 1].attributes.due,
                self.dateFormatStr,
            ).format(self.dateFormatStr);

            const diff = moment(firstDate).diff(moment(lastDate), 'days');
            let checkDate = firstDate;

            // Account for all dates that exist between our starting and final dates
            for (let i = diff; i < 0; i += 1) {
                const newEntry = {
                    attributes: {
                        date: moment(checkDate, self.dateFormatStr).format(self.dateFormatStr),
                        amount: 0,
                    },
                };
                entries.push(newEntry);
                checkDate = moment(checkDate, self.dateFormatStr).add('days', 1);
            }

            // Assemble the data we do have into the array of date entries
            for (let j = 0; j < collection.models.length; j += 1) {
                // note attribute is due
                const dateValue = moment(
                    collection.models[j].attributes.due,
                    self.dateFormatStr,
                ).format(self.dateFormatStr);
                const amountValue = collection.models[j].attributes.amount;

                const originalEntry = {
                    attributes: {
                        date: dateValue,
                        amount: amountValue,
                    },
                };
                entries.push(originalEntry);
            }

            // massage transaction data so there is only one entry per date
            reduced = util.flatten(util.reduce(entries, (mergedParam, object) => {
                const merged = mergedParam;
                merged[object.attributes.date] = merged[object.attributes.date] || {};
                merged[object.attributes.date] = {
                    date: moment(
                        object.attributes.date,
                        self.dateFormatStr,
                    ).format(self.dateFormatStr),

                    // sum of credit transactions for the day,
                    amount: (merged[object.attributes.date].amount || 0)
                        + object.attributes.amount,

                    // number of payments for the day
                    paymentsQty: (merged[object.attributes.date].paymentsQty || 0)
                        + (object.attributes.amount ? 1 : 0),
                };
                return merged;
            }, {}));
        }
        this.chartData.reset(reduced);
    },
});
