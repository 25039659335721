var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"br-stopPaymentNote\">\n                <span class=\"stopPaymentNote-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cm.payment.note",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":52},"end":{"line":38,"column":80}}}))
    + "</span>: "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cm.stopFeeNote",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":89},"end":{"line":38,"column":116}}}))
    + "\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button class=\"btn "
    + alias4(((helper = (helper = lookupProperty(helpers,"btnClass") || (depth0 != null ? lookupProperty(depth0,"btnClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnClass","hash":{},"data":data,"loc":{"start":{"line":53,"column":35},"end":{"line":53,"column":47}}}) : helper)))
    + "\"\n                    data-action=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"btnAction") || (depth0 != null ? lookupProperty(depth0,"btnAction") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnAction","hash":{},"data":data,"loc":{"start":{"line":54,"column":33},"end":{"line":54,"column":46}}}) : helper)))
    + "\"\n                    name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"btnAction") || (depth0 != null ? lookupProperty(depth0,"btnAction") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnAction","hash":{},"data":data,"loc":{"start":{"line":55,"column":26},"end":{"line":55,"column":39}}}) : helper)))
    + "\"\n                    type=\"button\">\n                    <span class=\"txt\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"btnText") || (depth0 != null ? lookupProperty(depth0,"btnText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnText","hash":{},"data":data,"loc":{"start":{"line":57,"column":38},"end":{"line":57,"column":49}}}) : helper)))
    + "</span>\n                </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span>\n            <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.backToPrevious",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":42},"end":{"line":4,"column":84}}}))
    + "</span></a>\n    </div>\n    <h1 class=\"landing-header\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":6,"column":31},"end":{"line":6,"column":40}}}) : helper)))
    + "</h1>\n\n    <div data-section=\"payment-summary\" class=\"amount-wrapper\">\n        <p class=\"amount-label-text\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"getTotalLabel") || (depth0 != null ? lookupProperty(depth0,"getTotalLabel") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"getTotalLabel","hash":{},"data":data,"loc":{"start":{"line":9,"column":37},"end":{"line":9,"column":54}}}) : helper)))
    + "</p>\n        <span data-hook=\"getTotalItems\" class=\"amount-value-text payment-total\">0</span>\n    </div>\n</div>\n\n<section class=\"section section-container\">\n  <div class=\"alert-message\">\n      <div data-region=\"alertRegion\" role=\"alert\"></div>\n  </div>\n</section>\n\n<section class=\"section section-container\">\n    <div class=\"section-body\">\n        <div class=\"type-toggle-region\"></div>\n        <div class=\"collection-entry\" data-region=\"collectionRegion\" aria-live=\"polite\"></div>   \n        <div class=\"add-container\">\n            <div class=\"item-add-container\">                \n               <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.Add",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":21},"end":{"line":26,"column":41}}}))
    + "</span>\n               <input aria-labelledby=\"add-label add-type\" type=\"text\" class=\"form-control input-sm\"  data-hook=\"getAddCount\" value=\"1\">\n               <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"getItemsLabel") || (depth0 != null ? lookupProperty(depth0,"getItemsLabel") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"getItemsLabel","hash":{},"data":data,"loc":{"start":{"line":28,"column":21},"end":{"line":28,"column":38}}}) : helper)))
    + "</span>\n               <button aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"title.addIt",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":35},"end":{"line":29,"column":59}}}))
    + "\" class=\"btn btn-secondary btn-xs\" data-hook=\"getAddItemButton\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.add",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":123},"end":{"line":29,"column":146}}}))
    + "</button>\n           </div>\n           <div class=\"item-info-container\">\n                <span data-hook=\"getAddInfo\" class=\"info-line\"></span>\n           </div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"enableStopPayNote") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":9},"end":{"line":40,"column":17}}})) != null ? stack1 : "")
    + "     </div>\n</section>\n\n<div class=\"section section-summary\">\n    <div data-section=\"payment-summary\" class=\"section-header\">\n         <p><span data-hook=\"getTotalItems\" class=\"summary-large-text\">0</span> <span> "
    + alias2(((helper = (helper = lookupProperty(helpers,"getTotalLabel") || (depth0 != null ? lookupProperty(depth0,"getTotalLabel") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"getTotalLabel","hash":{},"data":data,"loc":{"start":{"line":47,"column":87},"end":{"line":47,"column":104}}}) : helper)))
    + " </span></p>\n    </div>\n\n    <div class=\"section-body\">\n        <div class=\"widget-action-btn-group\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"buttons") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":12},"end":{"line":59,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n\n";
},"useData":true});