var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"glu PrintChecks-container\">\n        <div class=\"glu-content PrintChecks\" style=\"display: block;\">\n            <div>\n                <section class=\"PrintChecks-info\">\n                    <div class=\"row\">\n                        <div class=\"col-4 login-logo\">\n                            <img src=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"rootUrl") : depths[1]), depth0))
    + alias2(((helper = (helper = lookupProperty(helpers,"logoImagePath") || (depth0 != null ? lookupProperty(depth0,"logoImagePath") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"logoImagePath","hash":{},"data":data,"loc":{"start":{"line":8,"column":52},"end":{"line":8,"column":69}}}) : helper)))
    + "\">\n                        </div>\n                        <div class=\"col-8 printInfo\">\n                            <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias3,"allReports.Date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":31},"end":{"line":11,"column":59}}}))
    + ": "
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"today") : depths[1]), depth0))
    + "</p>\n                            <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias3,"allReports.Page",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":31},"end":{"line":12,"column":59}}}))
    + ": "
    + alias2(((helper = (helper = lookupProperty(helpers,"page") || (depth0 != null ? lookupProperty(depth0,"page") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"page","hash":{},"data":data,"loc":{"start":{"line":12,"column":61},"end":{"line":12,"column":69}}}) : helper)))
    + " of "
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"checks") : depths[1])) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "</p>\n                        </div>\n                    </div>\n                </section>\n                <section class=\"PrintChecks-details\">\n                    <div class=\"PrintChecks-detailFields\">\n                        <ul class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"fields") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":28},"end":{"line":24,"column":37}}})) != null ? stack1 : "")
    + "                        </ul>\n                    </div>\n                    <div class=\"PrintChecks-images\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"frontSrc") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":28,"column":24},"end":{"line":43,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                </section>\n            </div>\n        </div>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <li class=\"col-3\">\n                                <label class=\"field\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":21,"column":53},"end":{"line":21,"column":62}}}) : helper)))
    + "</label>\n                                <p class=\"value\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldValue") || (depth0 != null ? lookupProperty(depth0,"fieldValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldValue","hash":{},"data":data,"loc":{"start":{"line":22,"column":49},"end":{"line":22,"column":63}}}) : helper)))
    + "</p>\n                            </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"row\">\n                            <div class=\"col-12\">\n                                <img class=\"PrintChecks-img\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"frontSrc") || (depth0 != null ? lookupProperty(depth0,"frontSrc") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"frontSrc","hash":{},"data":data,"loc":{"start":{"line":31,"column":66},"end":{"line":31,"column":78}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"frontAlt") || (depth0 != null ? lookupProperty(depth0,"frontAlt") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"frontAlt","hash":{},"data":data,"loc":{"start":{"line":31,"column":85},"end":{"line":31,"column":97}}}) : helper)))
    + "\">\n                            </div>\n                        </div>\n                        <div class=\"row\">\n                            <div class=\"col-12\">\n                                <img class=\"PrintChecks-img\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"backSrc") || (depth0 != null ? lookupProperty(depth0,"backSrc") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"backSrc","hash":{},"data":data,"loc":{"start":{"line":36,"column":66},"end":{"line":36,"column":77}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"backAlt") || (depth0 != null ? lookupProperty(depth0,"backAlt") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"backAlt","hash":{},"data":data,"loc":{"start":{"line":36,"column":84},"end":{"line":36,"column":95}}}) : helper)))
    + "\">\n                            </div>\n                        </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"row\">\n                            <p class=\"col-12\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"imageView.noImageFound",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":41,"column":46},"end":{"line":41,"column":81}}}))
    + "</p>\n                        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"checks") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":49,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});