var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.backToPreviousPage",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":144},"end":{"line":3,"column":191}}}))
    + "</span></a>\n    </div>\n    <h1 class=\"landing-header\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"stateDescription") || (depth0 != null ? lookupProperty(depth0,"stateDescription") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"stateDescription","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":51}}}) : helper)))
    + " "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.bankWidgets",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":52},"end":{"line":5,"column":92}}}))
    + "</h1>\n</div>\n\n<fieldset class=\"section section-container\">\n    <div class=\"section-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.identifiers",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":32},"end":{"line":9,"column":72}}}))
    + "</div>\n    <div class=\"row\">\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"required field-container-lg field-container\">\n                <label for=\"dataWidgetId\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.id",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":42},"end":{"line":13,"column":73}}}))
    + "</label>\n                <br><strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"dataWidgetId") || (depth0 != null ? lookupProperty(depth0,"dataWidgetId") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"dataWidgetId","hash":{},"data":data,"loc":{"start":{"line":14,"column":28},"end":{"line":14,"column":44}}}) : helper)))
    + "</strong>\n            </div>\n        </div>\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"required field-container-lg field-container\">\n                <label for=\"widgetName\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.name",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":40},"end":{"line":19,"column":73}}}))
    + "</label>\n                <br><strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"widgetName") || (depth0 != null ? lookupProperty(depth0,"widgetName") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"widgetName","hash":{},"data":data,"loc":{"start":{"line":20,"column":28},"end":{"line":20,"column":42}}}) : helper)))
    + "</strong>\n            </div>\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"field-container-lg field-container\">\n                <label for=\"widgetDescription\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.description",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":47},"end":{"line":27,"column":87}}}))
    + "</label>\n                <br><strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"widgetDescription") || (depth0 != null ? lookupProperty(depth0,"widgetDescription") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"widgetDescription","hash":{},"data":data,"loc":{"start":{"line":28,"column":28},"end":{"line":28,"column":49}}}) : helper)))
    + "</strong>\n            </div>\n        </div>\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"field-container-lg field-container\">\n                <label for=\"dataVersion\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.version",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":41},"end":{"line":33,"column":77}}}))
    + "</label>\n                <br><strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"dataVersion") || (depth0 != null ? lookupProperty(depth0,"dataVersion") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"dataVersion","hash":{},"data":data,"loc":{"start":{"line":34,"column":28},"end":{"line":34,"column":43}}}) : helper)))
    + "</strong>\n            </div>\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"field-container-lg field-container\">\n                <label for=\"dataEntitle\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.dataEntitle",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":41,"column":41},"end":{"line":41,"column":81}}}))
    + "</label>\n                <br><strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"dataEntitleDescription") || (depth0 != null ? lookupProperty(depth0,"dataEntitleDescription") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"dataEntitleDescription","hash":{},"data":data,"loc":{"start":{"line":42,"column":28},"end":{"line":42,"column":54}}}) : helper)))
    + "</strong>\n            </div>\n        </div>\n    </div>\n</fieldset>\n\n<fieldset class=\"section section-container\">\n    <div class=\"section section-container\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.specification",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":49,"column":43},"end":{"line":49,"column":85}}}))
    + "</div>\n    <div class=\"row\">\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"field-container-lg field-container\">\n                <label for=\"widgetType\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.location",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":53,"column":40},"end":{"line":53,"column":77}}}))
    + "</label>\n                <br><strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeDescription") || (depth0 != null ? lookupProperty(depth0,"typeDescription") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"typeDescription","hash":{},"data":data,"loc":{"start":{"line":54,"column":28},"end":{"line":54,"column":47}}}) : helper)))
    + "</strong>\n            </div>\n        </div>\n    </div>\n    <div class=\"row\" data-hook=\"getRemote\">\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"field-container-lg field-container\">\n                <label for=\"dataUrl\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.url",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":61,"column":37},"end":{"line":61,"column":69}}}))
    + "</label>\n                <br><strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"dataUrl") || (depth0 != null ? lookupProperty(depth0,"dataUrl") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"dataUrl","hash":{},"data":data,"loc":{"start":{"line":62,"column":28},"end":{"line":62,"column":39}}}) : helper)))
    + "</strong>\n            </div>\n        </div>\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"field-container-lg field-container\">\n                <label for=\"trusted\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.trusted",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":67,"column":37},"end":{"line":67,"column":73}}}))
    + "</label>\n                <br><strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"trustedDescription") || (depth0 != null ? lookupProperty(depth0,"trustedDescription") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"trustedDescription","hash":{},"data":data,"loc":{"start":{"line":68,"column":28},"end":{"line":68,"column":50}}}) : helper)))
    + "</strong>\n            </div>\n        </div>\n    </div>\n    <div class=\"row\" data-hook=\"getLocal\">\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"field-container-lg field-container\">\n                <label for=\"dataProxify\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.proxyUrl",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":75,"column":41},"end":{"line":75,"column":78}}}))
    + "</label>\n                <br><strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"dataProxify") || (depth0 != null ? lookupProperty(depth0,"dataProxify") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"dataProxify","hash":{},"data":data,"loc":{"start":{"line":76,"column":28},"end":{"line":76,"column":43}}}) : helper)))
    + "</strong>\n            </div>\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"field-container-lg field-container\">\n                <label for=\"externalWidgetType\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":83,"column":48},"end":{"line":83,"column":81}}}))
    + "</label>\n                <br><strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"externalWidgetType") || (depth0 != null ? lookupProperty(depth0,"externalWidgetType") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"externalWidgetType","hash":{},"data":data,"loc":{"start":{"line":84,"column":28},"end":{"line":84,"column":50}}}) : helper)))
    + "</strong>\n            </div>\n        </div>\n        <div class=\"form-column col-md-6 horizontal mfa-url\">\n            <div class=\"field-container-lg field-container\">\n                <label for=\"mfaUrl\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.mfaEndpoint",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":89,"column":36},"end":{"line":89,"column":76}}}))
    + "</label>\n                <br><strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"mfaUrl") || (depth0 != null ? lookupProperty(depth0,"mfaUrl") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"mfaUrl","hash":{},"data":data,"loc":{"start":{"line":90,"column":28},"end":{"line":90,"column":38}}}) : helper)))
    + "</strong>\n            </div>\n        </div>\n    </div>\n</fieldset>\n\n<fieldset class=\"section section-container\">\n    <div class=\"section-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.format",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":97,"column":32},"end":{"line":97,"column":67}}}))
    + "</div>\n    <div class=\"row\">\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"field-container-lg field-container\">\n                <label for=\"widgetSize\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.width",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":101,"column":40},"end":{"line":101,"column":74}}}))
    + "</label>\n                <br><strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"widgetSizeDescription") || (depth0 != null ? lookupProperty(depth0,"widgetSizeDescription") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"widgetSizeDescription","hash":{},"data":data,"loc":{"start":{"line":102,"column":28},"end":{"line":102,"column":53}}}) : helper)))
    + "</strong>\n            </div>\n        </div>\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"field-container-lg field-container\">\n                <label for=\"widgetDescription\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.height",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":107,"column":47},"end":{"line":107,"column":82}}}))
    + "</label>\n                <br><strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"height") || (depth0 != null ? lookupProperty(depth0,"height") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"height","hash":{},"data":data,"loc":{"start":{"line":108,"column":28},"end":{"line":108,"column":38}}}) : helper)))
    + "</strong>\n            </div>\n        </div>\n    </div>\n</fieldset>\n\n<div class=\"section section-container\">\n    <div class=\"section-body\">\n        <div class=\"widget-action-btn-group\">\n            <button type=\"button\" data-action=\"modify\" class=\"btn btn-primary\" name=\"modify\"><span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":117,"column":111},"end":{"line":117,"column":144}}}))
    + "</span></button>\n            <button type=\"button\" data-action=\"cancel\" class=\"btn btn-secondary\" name=\"cancel\"><span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":118,"column":113},"end":{"line":118,"column":148}}}))
    + "</span></button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});