var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <h1 class=\"landing-header\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"BOS.EnterBillInfo",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":43},"end":{"line":3,"column":73}}}))
    + "</h1>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <h1 class=\"landing-header\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"BOS.ViewBillHeader",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":43},"end":{"line":5,"column":74}}}))
    + " <span data-text=\"BILL_NUMBER\" data-bind=\"model\"></span></h1>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "not-";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "     <div class=\"field-container-lg field-container\">\n       <button type=\"button\" class=\"btn btn-link image\"><span class=\"icon-add-circle\"></span> <span class=\"txt-label add-image-filename\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"addImageFilename") || (depth0 != null ? lookupProperty(depth0,"addImageFilename") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"addImageFilename","hash":{},"data":data,"loc":{"start":{"line":18,"column":137},"end":{"line":18,"column":157}}}) : helper)))
    + "</span>\n       </button>\n          <input class=\"btn btn-link  pull-right add-image-upload\" type=\"file\">         \n      </div> \n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button class=\"btn btn-tertiary\" data-hook=\"add-new-vendor\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"smbBillMgmt.vendor.add.new",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":76},"end":{"line":28,"column":115}}}))
    + "</button>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <button class=\"btn btn-tertiary\" data-hook=\"add-new-customer\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"smbBillMgmt.customer.add.new",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":67,"column":86},"end":{"line":67,"column":127}}}))
    + "</button>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <textarea class=\"form-control  textarea-field\" rows=\"3\" data-bind=\"model\" id=\"BILL_DESCRIPTION_"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data,"loc":{"start":{"line":75,"column":111},"end":{"line":75,"column":117}}}) : helper)))
    + "\" name=\"BILL_DESCRIPTION\" maxlength=\"255\"></textarea>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "                        <p data-text=\"BILL_DESCRIPTION\" data-bind=\"model\"></p>\n\n";
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-container\">\n                <div class=\" field-container-lg field-container\">\n                        <label class=\"checkbox-inline\">\n                                <input type=\"checkbox\" data-bind=\"model\" name=\"ENTERANOTHER\">\n                                "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"BOS.EnterAnotherBill",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":149,"column":32},"end":{"line":149,"column":65}}}))
    + "\n                        </label>\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"ENTERANOTHER\"></span>\n                </div>\n        </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <button data-action=\"edit\" class=\"btn btn-secondary\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"payment.button_modify",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":159,"column":77},"end":{"line":159,"column":111}}}))
    + "</button>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <button data-action=\"save\" class=\"btn btn-primary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.button_save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":161,"column":83},"end":{"line":161,"column":115}}}))
    + "</button>\n                                <button data-action=\"savedraft\" class=\"btn btn-secondary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.button_savedraft",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":162,"column":90},"end":{"line":162,"column":127}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":6,"column":19}}})) != null ? stack1 : "")
    + "</div>\n\n<div class=\"section section-container "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":38},"end":{"line":9,"column":73}}})) != null ? stack1 : "")
    + "read-only\">\n\n<div data-region=\"alert-region\"></div>\n\n<div class=\"form-container\">\n        <div> <!-- break out of .form-container child styles -->\n    \n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":5},"end":{"line":22,"column":16}}})) != null ? stack1 : "")
    + "\n        <div class=\"required field-container-lg field-container vendor-id\">\n                <label for=\"VENDOR_ID_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":25,"column":38},"end":{"line":25,"column":44}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.vendor",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":46},"end":{"line":25,"column":77}}}))
    + "</label>\n                <input type=\"hidden\" class=\"form-control comboBox\" data-bind=\"model\" name=\"VENDOR_ID\" id=\"VENDOR_ID_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":26,"column":116},"end":{"line":26,"column":122}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":16},"end":{"line":29,"column":27}}})) != null ? stack1 : "")
    + "                <span class=\"help-block\" data-bind=\"model\" data-validate=\"VENDOR_ID\"></span>\n        </div>\n\n        <div class=\"field-container-lg field-container required\">\n                <label for=\"BILL_NUMBER_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":34,"column":40},"end":{"line":34,"column":46}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.bill.number",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":34,"column":48},"end":{"line":34,"column":84}}}))
    + "</label>\n                <input class=\"form-control\" type=\"text\" maxlength=\"20\" data-bind=\"model\" id=\"BILL_NUMBER_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":35,"column":105},"end":{"line":35,"column":111}}}) : helper)))
    + "\" name=\"BILL_NUMBER\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"BILL_NUMBER\"></span>\n        </div>\n\n        <div class=\"required field-container-sm field-container\">\n                <label for=\"TOTAL_AMOUNT_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":40,"column":41},"end":{"line":40,"column":47}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":40,"column":49},"end":{"line":40,"column":80}}}))
    + "</label>\n                <input class=\"form-control \" type=\"text\" maxlength=\"19\" data-bind=\"model\" id=\"TOTAL_AMOUNT_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":41,"column":107},"end":{"line":41,"column":113}}}) : helper)))
    + "\" name=\"TOTAL_AMOUNT\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"TOTAL_AMOUNT\"></span>\n        </div>\n\n        <div class=\"date-picker required field-container-sm field-container\">\n                <label for=\"ISSUE_DATE_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":46,"column":39},"end":{"line":46,"column":45}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.issue.date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":46,"column":47},"end":{"line":46,"column":82}}}))
    + "</label>\n                <input class=\"input-date\" type=\"text\" data-bind=\"model\" name=\"ISSUE_DATE\" id=\"ISSUE_DATE_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":47,"column":105},"end":{"line":47,"column":111}}}) : helper)))
    + "\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"ISSUE_DATE\"></span>\n        </div>\n\n        <div class=\"date-picker required field-container-sm field-container\">\n                <label for=\"DUE_DATE_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":52,"column":37},"end":{"line":52,"column":43}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.due.date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":52,"column":45},"end":{"line":52,"column":78}}}))
    + "</label>\n                <input class=\"input-date\" type=\"text\" data-bind=\"model\" name=\"DUE_DATE\" id=\"DUE_DATE_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":53,"column":101},"end":{"line":53,"column":107}}}) : helper)))
    + "\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"DUE_DATE\"></span>\n        </div>\n\n        <div class=\"required field-container-lg field-container\">\n                <label for=\"TERM_CONDITION_ID_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":58,"column":46},"end":{"line":58,"column":52}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.terms.conditions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":58,"column":54},"end":{"line":58,"column":95}}}))
    + "</label>\n                <input type=\"hidden\" class=\"form-control comboBox\" data-bind=\"model\" name=\"TERM_CONDITION_ID\" id=\"TERM_CONDITION_ID_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":59,"column":132},"end":{"line":59,"column":138}}}) : helper)))
    + "\" data-filter-immediately>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"TERM_CONDITION_ID\"></span>\n        </div>\n\n        <div class=\"field-container-lg field-container customer-id\">\n                <label for=\"CUSTOMER_ID_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":64,"column":40},"end":{"line":64,"column":46}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.billable.customer",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":64,"column":48},"end":{"line":64,"column":90}}}))
    + "</label>\n                <input type=\"hidden\" class=\"form-control comboBox\" data-bind=\"model\" name=\"CUSTOMER_ID\" id=\"CUSTOMER_ID_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":65,"column":120},"end":{"line":65,"column":126}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":16},"end":{"line":68,"column":27}}})) != null ? stack1 : "")
    + "                <span class=\"help-block\" data-bind=\"model\" data-validate=\"CUSTOMER_ID\"></span>\n        </div>\n\n        <div class=\"field-container-lg field-container\">\n                <label for=\"BILL_DESCRIPTION_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":73,"column":45},"end":{"line":73,"column":51}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.description",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":73,"column":53},"end":{"line":73,"column":89}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":74,"column":16},"end":{"line":79,"column":27}}})) != null ? stack1 : "")
    + "                <span class=\"help-block\" data-bind=\"model\" data-validate=\"BILL_DESCRIPTION\"></span>\n        </div>\n\n        <div class=\"field-container-lg field-container\">\n                <label for=\"IMPORTANCE_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":84,"column":39},"end":{"line":84,"column":45}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.importance",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":84,"column":47},"end":{"line":84,"column":82}}}))
    + "</label>\n        <form class=\"form-inline\">\n            <input type=\"hidden\" class=\"form-control comboBox\" data-bind=\"model\" name=\"IMPORTANCE\" id=\"IMPORTANCE_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":86,"column":114},"end":{"line":86,"column":120}}}) : helper)))
    + "\" data-filter-immediately>\n            <label class=\"checkbox-inline\">\n                <input type=\"checkbox\" data-bind=\"model\" name=\"PINNED\" id=\"PINNED_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":88,"column":82},"end":{"line":88,"column":88}}}) : helper)))
    + "\" data-validate=\"PINNED\">\n                "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"BOS.BillPin",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":89,"column":16},"end":{"line":89,"column":40}}}))
    + "\n            </label>\n            <a class=\"btn-popover toolTip-btn-popover\" data-content=\""
    + alias4(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.pinnedBillText",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":91,"column":69},"end":{"line":91,"column":108}}}))
    + "\" data-trigger=\"hover\" data-placement=\"top\" role=\"button\" data-toggle=\"popover\" aria-describedby=\"Pin\">\n                <span class=\"icon-info\"/></span>\n            </a>\n        </form>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"IMPORTANCE\"></span>\n        </div>\n\n        <div class=\"field-container-lg field-container\">\n                <label for=\"GL_ACCOUNT_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":99,"column":39},"end":{"line":99,"column":45}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.general.ledger",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":99,"column":47},"end":{"line":99,"column":86}}}))
    + "</label>\n                <input class=\"form-control \" type=\"text\" maxlength=\"20\" data-bind=\"model\" id=\"GL_ACCOUNT_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":100,"column":105},"end":{"line":100,"column":111}}}) : helper)))
    + "\" name=\"GL_ACCOUNT\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"GL_ACCOUNT\"></span>\n        </div>\n\n        <div class=\"field-container-lg field-container\">\n                <label for=\"LOCATION_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":105,"column":37},"end":{"line":105,"column":43}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.location",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":105,"column":45},"end":{"line":105,"column":78}}}))
    + "</label>\n                <input type=\"hidden\" class=\"form-control comboBox\" data-bind=\"model\" name=\"LOCATION\" id=\"LOCATION_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":106,"column":114},"end":{"line":106,"column":120}}}) : helper)))
    + "\" data-filter-immediately>\n                <span class=\"help-block\" data-bind=\"model\"></span>\n        </div>\n\n        <div class=\"field-container-lg field-container\">\n                <label for=\"DEPARTMENT_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":111,"column":39},"end":{"line":111,"column":45}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.department",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":111,"column":47},"end":{"line":111,"column":82}}}))
    + "</label>\n                <input type=\"hidden\" class=\"form-control comboBox\" data-bind=\"model\" name=\"DEPARTMENT\" id=\"DEPARTMENT_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":112,"column":118},"end":{"line":112,"column":124}}}) : helper)))
    + "\" data-filter-immediately>\n                <span class=\"help-block\" data-bind=\"model\"></span>\n        </div>\n\n        <div class=\"required field-container-lg field-container\">\n                <label for=\"VALIDATOR_REQUIRED_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":117,"column":47},"end":{"line":117,"column":53}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.validator",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":117,"column":55},"end":{"line":117,"column":89}}}))
    + "</label>\n                <input type=\"hidden\" class=\"form-control comboBox\" data-bind=\"model\" name=\"VALIDATOR_REQUIRED\" id=\"VALIDATOR_REQUIRED_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":118,"column":134},"end":{"line":118,"column":140}}}) : helper)))
    + "\" data-filter-immediately>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"VALIDATOR_REQUIRED\"></span>\n        </div>\n\n        <div class=\"required field-container-lg field-container\">\n                <label for=\"APPROVER_REQUIRED\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.smbBillMgmt.approver",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":123,"column":47},"end":{"line":123,"column":92}}}))
    + "</label>\n                <input type=\"hidden\" class=\"form-control comboBox\" data-bind=\"model\" name=\"APPROVER_REQUIRED\" id=\"APPROVER_REQUIRED_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":124,"column":132},"end":{"line":124,"column":138}}}) : helper)))
    + "\" data-filter-immediately>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"APPROVER_REQUIRED\"></span>\n        </div>\n        </div>\n\n</div>\n\n\n        <fieldset class=\"line-item-container\">\n                <legend>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.line.items",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":133,"column":24},"end":{"line":133,"column":59}}}))
    + "</legend>\n                <div data-region=\"line-items\"></div>\n                <div class=\"line-item-total\">\n                        <span>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.total",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":136,"column":30},"end":{"line":136,"column":60}}}))
    + "</span>\n                        <span class=\"value\"></span>\n                </div>\n        </fieldset>\n\n        <div data-region=\"payment-history\"></div>\n\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":144,"column":8},"end":{"line":154,"column":19}}})) != null ? stack1 : "")
    + "\n    <div class=\"section-body\"> \n        <div class=\"widget-action-btn-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":158,"column":24},"end":{"line":163,"column":31}}})) != null ? stack1 : "")
    + "\n                        <button data-action=\"cancel\" class=\"btn btn-tertiary\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"payment.button_cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":165,"column":78},"end":{"line":165,"column":112}}}))
    + "</button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});