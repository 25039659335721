import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import alert from '@glu/alerts';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import services from 'services';
import IEUtil from 'common/util/IEUtil';
import appConfig from 'system/webseries/models/applicationConfiguration';
import ListView from 'common/dynamicPages/views/workflow/list';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import template from './eStatementResultsList.hbs';

const EstatementResultsList = ListView.extend({
    template,

    ui: {
        $eStatementLink: '#estatement-pdf',
    },

    initialize(options) {
        this.model = new Model();
        this.accountFilters = options.accountFilters;
        this.statementType = options.statementType;
        this.startDate = options.startDate;
        this.endDate = options.endDate;
        this.serviceName = '/balanceAndTransaction/eStatement';

        const superOptions = this.getSuperOptions();

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    getSuperOptions() {
        let superOption = {
            serviceName: this.serviceName,
            serviceFunc: 'DLOAD',
            businessType: null,
            enableSavedViews: false,
            context: 'SEARCH_ESTATE',
            lvcAlwaysReset: true,
            additionalSearchFields: this.getSearchFields(),
        };

        if (appConfig.getValue('ESTMT', 'ALLOWESTATEMENTRETRIEVAL') !== '1') {
            superOption = { ...superOption, selector: 'none' };
        }
        return superOption;
    },

    templateHelpers() {
        const self = this;
        const obj = ListView.prototype.templateHelpers.call(this, undefined);
        return {
            bulkDownloadEnabled: appConfig.getValue('ESTMT', 'ALLOWESTATEMENTRETRIEVAL') === '1',
            statementTypeList() {
                util.extend(
                    obj,
                    {
                        statementType() {
                            return self.statementType === 'QUARTER' ? locale.get('QUARTERLY') : self.statementType;
                        },
                    },
                );
            },
        };
    },

    onRender() {
        if (this.gridView) {
            this.listenTo(this.gridView, 'gridapi:loaded', () => {
                this.notifyParentView();
                this.clearGridRequestParams();
            });
            this.listenTo(this.gridView, 'gridapi:loaded:failed', () => {
                this.notifyParentView();
                this.clearGridRequestParams();
            });
            this.startGridRespAlertListeners(this.gridView.cid);
        }
        ListView.prototype.onRender.call(this);
    },

    notifyParentView() {
        this.trigger('eStatementList:child:isReady');
    },

    renderMessage(action) {
        if (action === null || action === undefined || !this.alertRegion) {
            // TODO: refactor empty block
        } else {
            const alertFunc = action.type === 'WARNING' ? alert.warning : alert.danger;
            if (action.message) {
                this.alertView = alertFunc(action.message);
                this.alertRegion.show(this.alertView);
            }
        }
    },

    clearGridRequestParams() {
        ListView.prototype.clearGridRequestParams.call(this);
        this.gridView.wrapper.additionalSearchFields = [];
        this.clearGridAddlSearchFields();
    },

    getSearchFields() {
        const searchFields = [];

        const addFilter = (name, val, type, operator) => {
            searchFields.push({
                fieldName: name,
                fieldValue: val,
                dataType: type,
                operator,
            });
        };

        addFilter('ACCOUNTFILTER', this.accountFilters, 'text', 'IN');
        addFilter('TODATE', [this.endDate], 'date', '=');
        addFilter('FROMDATE', [this.startDate], 'date', '=');
        addFilter('STATTYPE', [this.statementType], 'text', '=');

        return searchFields;
    },

    showError(message) {
        this.alertView = alert.danger(
            message,
            {
                canDismiss: true,
            },
        );
        this.alertRegion.show(this.alertView);
    },

    gridDownload(options) {
        const STATEMENT_IMAGE_ACTION = 'getImage';
        const postData = this.getDownloadCriteria(options);

        return this.makeDownloadRequest(services.generateUrl(`${this.serviceName}/${STATEMENT_IMAGE_ACTION}`), postData);
    },

    /**
     * @method makeDownloadRequest
     * @param {String} url
     * @param {Object} postData
     * Initiates the http call to the given endpoint with the proper data. Handles the response
     */
    makeDownloadRequest(url, postData) {
        return new Promise((resolve, reject) => {
            const $loading = this.$('.loading-indicator-grid');

            // NH-31936 - Enable loading indicator as download feedback
            $loading.show();

            http.post(url, postData, (result) => {
                if ((appConfig.getValue('ESTMT', 'ALLOWESTATEMENTRETRIEVAL') === '1') && url.indexOf('downloadMultipleImages') >= 0) {
                    const successMsg = locale.get('payment.export.success.message', result.exportId || null);
                    $loading.hide();
                    this.alertRegion.show(alert.success(successMsg, {
                        canDismiss: true,
                        animate: true,
                    }));
                } else {
                    this.processDownload(result);
                    $loading.hide();
                }
                resolve({ result });
            }, (result) => {
                const messages = result?.responseJSON?.messages ? result.responseJSON.messages.join(' ') : `${result.statusText} - ${result.status}`;
                this.showError(messages);
                $loading.hide();
                reject(result);
            });
        });
    },

    processDownload(result) {
        const file = result.statementImageData;
        const respHeader = result.responseHeader;
        let msgs = false;
        const { fileName } = result;
        let link;
        let blob;
        let blobUrl;
        let cleanupCallback = () => null;

        if (respHeader.message && respHeader.message.length) {
            msgs = respHeader.message.join('\n');
        }

        if (!file) {
            this.showError(msgs);
            return;
        }

        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(IEUtil.getBlob(file.value), fileName);
        } else {
            blob = this.base64toBlob(file.value, file.contentType);
            blobUrl = window.URL.createObjectURL(blob);
            link = this.ui.$eStatementLink.get(0);
            if (!link) {
                link = document.createElement('a');
                cleanupCallback = () => document.body.removeChild(link);
            }
            link.setAttribute('href', blobUrl);
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.setAttribute('href', '');
            cleanupCallback();
        }
    },

    getDownloadCriteria(options) {
        const jsonData = [];
        const { model } = options;

        util.each(model.attributes, (value, key) => {
            jsonData.push({
                name: key,
                value: this.convertData(model.get(key)),
            });
        }, this);
        return {
            item: jsonData,
        };
    },

    convertData(data) {
        if (util.isString(data)) {
            return data;
        }
        return JSON.stringify(data);
    },

    base64toBlob(b64Data, contentType) {
        const sliceSize = 512;
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const byteNumbers = byteCharacters.slice(offset, offset + sliceSize).split('').map(c => c.charCodeAt(0));

            byteArrays.push(new Uint8Array(byteNumbers));
        }

        return new Blob(
            byteArrays,
            {
                type: contentType || '',
            },
        );
    },

    entitlementPromiseSuccess(result) {
        this.entitlements = result.actions;
        if (!mobileUtil.isMobileGridEnabled()) {
            this.setHasLoadedRequiredData(true);
            this.listenTo(this.gridView.getRows(), 'sync', this.updateRefreshTimestamp);
            this.listenForGridErrors();
            this.render();
        } else {
            /** this notifies the parent after the grid has loaded */
            this.notifyParentView();
            this.clearGridRequestParams();
        }
        return result.actions;
    },
    /**
     * @method bulkDownload
     * Finds all selected row data and calls makeDownloadRequest with downloadMultipleImages
     * url and data
     */
    bulkDownload() {
        const rows = this.getSelectedRowModels();
        if (!rows.length) {
            const emptyRowsError = locale.get('gir.stmt.download.error.1300');
            this.showError(emptyRowsError);
            return;
        }

        const items = this.convertRowsToMultipleDownloadData(rows);
        const data = this.convertItemsToServerFormat(items);
        const url = services.generateUrl(`${this.serviceName}/downloadMultipleImages`);

        this.makeDownloadRequest(url, data);
    },

    /**
     * @method convertRowsToMultipleDownloadData
     * @param {Array} rows
     */
    convertRowsToMultipleDownloadData(rows) {
        return rows.map((row) => {
            const { item } = this.getDownloadCriteria({ model: row });
            item.push({
                name: 'FILETYPE',
                value: 'PDF',
            });
            return { item };
        });
    },

    /**
     * @method convertItemsToServerFormat
     * @param {string} items - the multiple estatements converted from rows
     */
    convertItemsToServerFormat(items) {
        return {
            items,
        };
    },

    /**
     * @method getSelectedRowModels
     * @returns {Array} - array of models selected in the grid
     */
    getSelectedRowModels() {
        return this.gridView.grid.getSelectedRowModels();
    },
});

let list = EstatementResultsList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list);
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
