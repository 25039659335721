import Layout from '@glu/core/src/layout';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import locale from '@glu/locale';
import alert from '@glu/alerts';
import entitlementUtil from 'common/util/entitledActions';
import alertMessage from 'common/api/alertMessage';
import services from 'services';
import AnalyticsServerSettingsModel from './analyticsServerSettings';
import analyticsServerSettingsViewTmpl from './analyticsServerSettingsView.hbs';

export default Layout.extend({
    template: analyticsServerSettingsViewTmpl,
    loadingTemplate: loadingPageTmpl,
    className: 'AnalyticsServerSettings',

    ui: {
        $saveButton: '[data-hook="getSave"]',
        $dataCollectionEnabledCB: '[data-hook="getDataCollectionEnabled"]',
    },

    events: {
        'click @ui.$saveButton': 'save',
        'click [data-hook="getReset"]': 'reset',
        'click @ui.$dataCollectionEnabledCB': 'toggleDataCollectionEnabled',
    },

    initialize() {
        this.model = new AnalyticsServerSettingsModel();
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
        }
    },

    toggleDataCollectionEnabled(e) {
        this.model.set('DATACOLLECTIONENABLED', this.ui.$dataCollectionEnabledCB.prop('checked') === true ? '1' : '0');
        e.stopPropagation();
    },

    loadRequiredData() {
        Promise.all([
            entitlementUtil.getEntitledActions(services.analyticsServerSettings, {}),
            this.model.fetch(),
        ]).then(([entitlements]) => {
            this.hasModify = entitlementUtil.hasEntitledAction(
                entitlementUtil.ACTIONS.modify,
                entitlements.actionModes,
            );
            this.readOnly = !this.hasModify && entitlementUtil.hasEntitledAction(
                entitlementUtil.ACTIONS.select,
                entitlements.actionModes,
            );
            this.setHasLoadedRequiredData(true);
            this.backupModel();
            this.render();
        });
    },

    templateHelpers() {
        return {
            headingText: this.hasModify
                ? locale.get('tableMaintenance.analyticsServerSettings_title-modify')
                : locale.get('tableMaintenance.analyticsServerSettings_title-view'),
            hasModify: this.hasModify,
            readOnly: this.readOnly,
            dataCollectionEnabled: this.model.get('DATACOLLECTIONENABLED') === '1',
        };
    },

    backupModel() {
        this.originalModel = this.model.clone();
    },

    save() {
        if (this.model.isValid()) {
            this.toggleButtonsBusy(true);
            this.model.save().then(this.handleSaveSuccess.bind(this))
                .catch(this.handleSaveError.bind(this));
        }
    },

    /**
     * Clone the newly saved model and show a success message alert
     * @param {Object} response - response object
     */
    handleSaveSuccess(response) {
        this.backupModel();
        this.toggleButtonsBusy(false);
        const alertView = alert.success(response.message.join(' '));
        this.alertRegion.show(alertView);
    },

    /**
     * Show the error message alert
     * @param {Object} response - standard Error response
     */
    handleSaveError(response) {
        this.toggleButtonsBusy(false);
        alertMessage.renderMessage(this, null, response.responseJSON);
    },

    /**
     * Use the attributes of the cloned model to set the view model,
     * then render the view
     */
    reset() {
        this.model.set(this.originalModel.toJSON());
        this.render();
    },

    /**
     * Set save buttons to busy parameter
     * @param {boolean} busy
     */
    toggleButtonsBusy(busy) {
        if (this.ui.$saveButton.attr) {
            this.ui.$saveButton.attr('aria-busy', busy);
        }
    },
});
