/* istanbul ignore file */
export default {
  equals: 'Equals',
  greaterThan: 'Greater than',
  isLessThan: 'Is Less Than',
  isGreaterThan: 'Is Greater Than',
  lessThan: 'Less than',
  numberFilterType: 'Number Filter Type',
  numberInput: 'Number Input',
  select: 'Select',
  between: 'Between',
  enterMin: 'Enter Minimum',
  enterMax: 'Enter Maximum'
};
