import ViewReversePositivePay from 'app/checkManagement/views/viewReversePositivePayDecision';
import store from 'system/utilities/cache';
import cmconstants from 'app/checkManagement/constants';

export default ViewReversePositivePay.extend({
    /** @override */
    setModel() {
        this.model = store.get(`adminCM_fraud_listView_reversePositivePayDecision${cmconstants.ACTIONMODEL}`);
    },

    loadRequiredData() {
        this.loadRequiredFCData(cmconstants.CM, cmconstants.REVERSE_POS_PAY_TYPE);
    },

    cancel() {
        this.disableButtons();
        this.navigateTo(this.getListUrl());
    },
});
