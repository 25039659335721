import ItemView from '@glu/core/src/itemView';
import numeral from 'numeral';
import Formatter from 'system/utilities/format';
import userInfo from 'etc/userInfo';

export default ItemView.extend({
    tagName: 'tr',

    initialize(options) {
        this.readOnly = options.readOnly;
        this.listenTo(
            this.model,
            {
                'change:NET_AMOUNT': this.updateNetAmount,
            },
        );
    },

    attributes() {
        return {
            'data-model': this.model.cid,
        };
    },

    events: {
        'click button[data-action="delete"]': 'deleteLineItem',
    },

    deleteLineItem() {
        this.model.destroy();
    },

    updateNetAmount() {
        this.$('input[name="NET_AMOUNT"]').val(numeral(this.model.get('NET_AMOUNT')).format(userInfo.get('currencySymbol') + userInfo.getCurrencyFormat()));
    },

    onRender() {
        const MASK_OPTIONS = Formatter.getCurrencyMaskOptions();
        MASK_OPTIONS.rightAlign = true;
        this.$('input[name="EACH_AMOUNT"]').inputmask('decimal', MASK_OPTIONS);
        this.$('input[name="QUANTITY"]').inputmask('decimal');
        if (this.readOnly) {
            this.$('input').prop('readonly', true);
        }
        this.updateNetAmount();
    },

    templateHelpers() {
        return {
            id: this.cid,
            readOnly: this.readOnly,
        };
    },
});
