import React, {
    useContext, useState, useLayoutEffect, Fragment,
} from 'react';
import PropTypes from 'prop-types';
import { appBus } from '@glu/core';
import { withStyles } from '@glu/theming';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';
import useLocale from '../../hooks/useLocale';
import useRequest from '../../hooks/useRequest';

import styles from './RtpOnGoingConversation.styles';
import RtpContext from '../RtpDrawerContainer/RtpContext';
import MessageItem from '../RtpMessageItem/RtpMessageItem';
import PairedResponseMessage from '../RtpPairedResponseMessage/RtpPairedResponseMessage';
import AddToConversationButtons from '../RtpAddToConversationButtons/RtpAddToConversationButtons';
import AlertRegion from '../RtpAlertRegion/RtpAlertRegion';
import RtpUtils from '../../app/payments/views/realTimePayments/utils/conversationUtils';

const propTypes = {
    currentTab: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    updateResponse: PropTypes.func,
    displayPaired: PropTypes.bool,
    resetResponses: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool,
    // Internal classes hash - do not pass
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const defaultProps = {
    updateResponse: null,
    displayPaired: false,
    isAdmin: false,
};

const OnGoingConversation = ({
    currentTab, id, updateResponse, displayPaired, resetResponses, isAdmin, classes,
}) => {
    const { getLocaleString } = useLocale();
    const { post } = useRequest();

    const rtpContext = useContext(RtpContext); // get state from RtpContext
    const [paymentId, setPaymentId] = useState(id);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSending, setIsSending] = useState(false);

    /**
     *  since the state is set on the first render and it is not reset, need to manually update
     *  this is similar to getDerivedStateFromPropss
     */
    if (id !== paymentId) {
        setPaymentId(id);
    }

    const handleError = (error) => {
        setIsSending(false);
        // adjust for different error responses
        const actionResponse = error.responseJSON
            ? error.responseJSON.actionResponse
            : error.actionResponse;

        setErrorMessage(actionResponse.confirms.confirmResults[0].messages.join(' '));
    };

    const sendTheResponses = () => {
        const url = services.generateUrl(`${constants.URL_RTP_RESPONSE_TO_REQUEST_FOR_INFO}/add`);
        const payload = RtpUtils.getResponseItems(rtpContext.responses);

        setIsSending(true);
        post(url, payload).then((response) => {
            // unmark send button - not busy
            setIsSending(false);
            // check for errors - if any call handleError
            if (response.actionResponse.confirms.totalFail > 0) {
                handleError(response);
            } else {
                // trigger event for drawer to update messages
                appBus.trigger('rtp:newMessageSuccess');
            }
        }).catch((error) => {
            handleError(error); // display error message
        });
    };

    const handleSendResponse = (evt) => {
        evt.preventDefault();
        // for each placholder, make sure that either a message was added or no response was checked
        const needResponses = rtpContext.responses;
        const notAllEntered = needResponses.some(resp => !resp.internalMessage && !resp.noresponse);
        if (notAllEntered) {
            setErrorMessage(getLocaleString('rtp.responsesNeeded'));
        } else {
            setErrorMessage('');
            // submit request
            sendTheResponses();
        }
    };

    const handleCancel = (evt) => {
        evt.preventDefault();
        // clear all messages
        resetResponses();
    };

    useLayoutEffect(() => {
        if (rtpContext.conversationItems.length > 0) {
            // scroll to the last message in the conversationItems
            const lastMessage = document.querySelector('.conversationItem:last-child');
            if (lastMessage && lastMessage.scrollIntoView) {
                lastMessage.scrollIntoView();
            }

            rtpContext.conversationItems.forEach((item) => {
                if (item.ITEM_TYPE === 'RESPONSE' && item.NOT_DELIVERED) {
                    setErrorMessage(getLocaleString('rtp.responseNotSent'));
                }
            });
        }
    }, [rtpContext.conversationItems]); //eslint-disable-line

    const renderMessageItem = (item) => {
        let key;
        if (item.ITEM_TYPE === 'PLACEHOLDER' && displayPaired) {
            // the seq number should really never be 0, but just incase...
            if (item.SEQUENCE_NUM === '0') {
                key = `${item.CONVITEM_ID}.${item.ITEM_TYPE}`;
            } else {
                key = `${item.CONVITEM_ID}.${item.SEQUENCE_NUM}`;
            }
            if (isAdmin) {
                return (
                    <></>
                );
            }
            return (
                <PairedResponseMessage
                    data={item}
                    updateResponse={updateResponse}
                    key={key}
                    errorIndicator={errorMessage.length > 0}
                />
            );
        }

        return (
            <MessageItem
                key={item.CONVITEM_ID}
                itemDetail={item}
                currentTab={currentTab}
                updateResponse={updateResponse}
            />
        );
    };

    return (
        <>
            <div className={classes.messageItems}>
                {rtpContext
                    && rtpContext.conversationItems.map(item => (
                        renderMessageItem(item)
                    ))}
            </div>
            {displayPaired && !isAdmin
            && (
                <>
                    <AddToConversationButtons
                        currentTab={currentTab}
                        handleSend={handleSendResponse}
                        handleCancel={handleCancel}
                        isSending={isSending}
                    />
                    <AlertRegion errorMessage={errorMessage} />
                </>
            )}
        </>
    );
};
OnGoingConversation.propTypes = propTypes;
OnGoingConversation.defaultProps = defaultProps;

export default withStyles(styles)(OnGoingConversation);
