import Model from '@glu/core/src/model';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import httpError from 'common/util/httpErrorResult';

export default Model.extend({
    defaults: {
        rowsPerPage: 0,
        startRow: 0,
    },

    initialize() {
        const summary = store.get('lockboxAvailability:account');
        if (this.get('isAccountDetails')) {
            this.accountFilter = [{
                fieldName: 'ACCOUNTFILTER',
                operator: 'CONTAINS',
                fieldValue: [summary.model.get('ACCOUNTFILTER')],
                dataType: 'text',
            }];
            this.set('searchFields', util.union(this.accountFilter, this.get('searchFields')));
        }
    },

    sync(method, model, options) {
        let path;
        if (method === 'read') {
            if (this.get('isAccountDetails')) {
                path = 'getDetailTotals';
            } else {
                path = 'getSummaryTotals';
            }
            this.unset('isAccountDetails');
            http.post(services.generateUrl(`lockboxAvailability/${path}`), this.toJSON(), (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }
    },

    parse(results) {
        return util.map(results.rows[0]?.columns, (totalParam) => {
            const total = totalParam;
            switch (total.fieldName) {
            case 'TOTAL_ITEMS':
                total.fieldLabel = locale.get('LBX.TOTAL_ITEMS');
                break;
            case 'DEPOSIT_TOTAL':
                total.fieldLabel = locale.get('LBX.DEPOSIT_TOTAL');
                break;
            case 'IMMEDIATE_TOTAL':
                total.fieldLabel = locale.get('LBX.IMMEDIATE_TOTAL');
                break;
            case 'ONE_DAY_TOTAL':
                total.fieldLabel = locale.get('LBX.ONE_DAY_TOTAL');
                break;
            case 'TWO_DAY_TOTAL':
                total.fieldLabel = locale.get('LBX.TWO_DAY_TOTAL');
                break;
            case 'ADJUSTMENT':
                total.fieldLabel = locale.get('LBX.ADJUSTMENT_TOTAL');
                break;
            default:
            }
            return total;
        });
    },
});
