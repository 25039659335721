var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"_ADMIN.PPRLESS.Multi.Statement.Type.Info.Message",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":15},"end":{"line":5,"column":76}}}))
    + "</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"BulkActionGridControls\">\n                <div class=\"BulkActionGridControls-region-wrapper\">\n                    <div data-region=\"toggleAllRegion\"></div>\n                    <div data-region=\"applyAllRegion\"></div>\n                </div>\n            </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"paperless-btn-wrap\">\n                <button type=\"button\"\n                        data-hook=\"getSaveButton\"\n                        class=\"btn btn-primary\"\n                        data-action=\"save\">\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"_ADMIN.PPRLESS.Save.Changes",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":24},"end":{"line":26,"column":64}}}))
    + "\n                </button>\n                <button type=\"button\"\n                        data-hook=\"getRevertButton\"\n                        class=\"btn btn-secondary\"\n                        data-action=\"revert\">\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"_ADMIN.PPRLESS.Revert",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":24},"end":{"line":32,"column":58}}}))
    + "\n                </button>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"section section-container\" role=\"tabpanel\">\n    <div class=\"section-body\">\n        <div data-region=\"alertRegion\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multipleStatementTypesEntitled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":6,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"btn-wrapper\">\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"gridUtilitySection") || (depth0 != null ? lookupProperty(depth0,"gridUtilitySection") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"gridUtilitySection","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":36}}}) : helper))) != null ? stack1 : "")
    + "\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasModifyEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":17,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"filter-region\" data-region=\"filterRegion\"></div>\n        <div class=\"grid-region PaperlessSettings\" data-region=\"gridRegion\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasModifyEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":35,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});