import Constants from 'app/balanceAndTransaction/constants';
import DepositAccTransListView from 'app/balanceAndTransaction/views/depositAccounts/depositTransactionListView';
import locale from '@glu/locale';

export default DepositAccTransListView.extend({
    accountType: 'DEPOSIT',

    getContext() {
        return {
            functionCode: 'INST',
            menuCatergory: 'reporting',
            menuDescription: locale.get('smbPayments.balance.trans.reporting'),
            name: locale.get('smbPayments.balance.trans.reporting'),
            productCode: 'GIR',
            requestMappings: 'balanceAndTransactionReporting',
            serviceName: `${Constants.DEPOSIT_ACCTS_SERVICE_PREFIX}getTransactions`,
            typeCode: 'GIRTRANS',
            exportPrintURL: '/depositAccounts/transactions/export/listView',
            inquiryId: 22210,
        };
    },
});
