import $ from 'jquery';
import util from '@glu/core/src/util';
import moment from 'moment';
import userInfo from 'etc/userInfo';

function enableOrDisableDateField($el, disable) {
    /*
     * A hack to add the disable icon to the date field.
     * Unfortunately, this will add the style attribute to the element.
     */
    if (disable) {
        $el.css('cursor', 'not-allowed');
    } else {
        $el.css('cursor', 'auto');
    }
}

export default function (form, initialState) {
    const formState = form.formView.state;
    const fromAccount = form.field('ACCOUNT_NUMBER');
    const fromAccountName = form.field('ACCOUNT_TITLE');
    const serialNumber = form.field('SERIALNUMBER');
    const toSerialNumber = form.field('SERIALNUM_TO');
    const amount = form.field('AMOUNT');
    const tranDate = form.field('TRAN_DATE');
    const memo = form.field('MEMO');
    const expirationDate = form.field('CURRENT_EXPIRATION_DATE');
    const extendExpirationDate = form.field('EXTENDED_EXPIRATION_DATE');
    const abaNumber = form.field('ABA_NUMBER');
    const payee = form.field('PAYEE');
    const stopReason = form.field('STOPPLACEMENT_REASON');
    const stopReasonChecks = form.field('STOPPLACEMENT_REASON_CHECK');
    const stopReasonPreAuthDebits = form.field('STOPPLACEMENT_REASON_PAD');
    const readonlyFields = [amount, tranDate, payee];
    const { model } = form.formView;
    const modelReadonlyFields = ['AMOUNT', 'TRAN_DATE', 'PAYEE'];
    const isInsertOrModify = (formState === 'insert' || formState === 'modify');

    const setReadOnlyFields = function () {
        readonlyFields.forEach((el, idx) => {
            if (toSerialNumber.isNotEmpty()) {
                el.setValue('');
                model.set(modelReadonlyFields[idx], '');
            }
            el.$el.prop('readonly', toSerialNumber.isNotEmpty());
        });
    };

    const validatorShouldExist = function (field) {
        if (util.isObject(model.validators[field])) {
            serialNumber.setValidator(serialNumber.$el, 'exists', true);
            serialNumber.setValidator(serialNumber.$el, 'isNumeric', true);
            serialNumber.setValidator(serialNumber.$el, 'matches', '[0-9]*$');
        }
    };

    const validatorShouldNotExist = function (field) {
        if (util.isObject(model.validators[field])) {
            serialNumber.removeValidator('exists');
            serialNumber.removeValidator('isNumeric');
            serialNumber.removeValidator('matches');
        }
    };

    if (initialState) {
        /*
         * Save the initial mandatory state of the amount field and the
         * payee field for later use
         */
        amount.$el.data('initial-required-state', amount.$el.parent().hasClass('required'));
        payee.$el.data('initial-required-state', payee.$el.parent().hasClass('required'));
        $('#ABA_NUMBER').hide();
        abaNumber.$el.parent().hide();
        /*
         * The STOPPLACEMENT_REASON_CHECK and STOPPLACEMENT_REASON_PAD are virtual fields to
         * provide the options
         * for user to select in the insert and modify, need to get the stop reason
         * user selected to set these
         * so that the user selected reason will be displayed in modify.
         */
        model.set({
            STOPPLACEMENT_REASON_CHECK: model.get('STOPPLACEMENT_REASON'),
            STOPPLACEMENT_REASON_PAD: model.get('STOPPLACEMENT_REASON'),
        });

        if (formState === 'modify') {
            // make from account readonly & hide the lookup icon
            fromAccount.shouldBeReadOnly(true);
            form.formView.$el.find('[name="ACCOUNT_NUMBER_LOOKUP"]').hide();
        }
    }

    if (isInsertOrModify) {
        $('.CM_INST_STOP_PAYMENT_RANGES div').first().addClass('required');
    }

    stopReason.shouldBeHidden();
    stopReasonChecks.shouldBeHidden();
    stopReasonPreAuthDebits.shouldBeHidden();
    stopReason.shouldBeRequiredWhen(model.fieldData.STOPPLACEMENT_REASON.mandatory);
    stopReasonChecks.shouldBeRequiredWhen(model.fieldData.STOPPLACEMENT_REASON.mandatory);
    stopReasonPreAuthDebits
        .shouldBeRequiredWhen(model.fieldData.STOPPLACEMENT_REASON.mandatory);
    if (model.fieldData.STOPPLACEMENT_REASON.mandatory) {
        stopReasonChecks.$el.parent().addClass('required');
        stopReasonPreAuthDebits.$el.parent().addClass('required');
    }

    if (formState === 'insert') {
        expirationDate.shouldBeHidden();
        // Hide the audit section
        $('[data-region="auditsectionWidgetRegion"]').closest('fieldset').hide();
    }

    if (formState === 'modify'
        && model.get('viewAction') === 'INQUIRY') {
        $('#ENTERED_TIMESTAMP').closest('.section').hide();
    }

    if (formState === 'modify') {
        // adjust type toggle
        form.formView.appBus.trigger(
            'event:updateStateToggle',
            {
                newType: model.get('STOPTYPE'),
                stopPropagation: true,
            },
        );
    }
    if (formState !== 'view') {
        fromAccountName.shouldBeHidden();
        extendExpirationDate.shouldBeHidden();
    }

    enableOrDisableDateField(tranDate.$el, toSerialNumber.isNotEmpty());

    setReadOnlyFields();
    // Handle disabling fields when serial number range is filled
    if (!toSerialNumber.isEmpty()) {
        /*
         * when there is a "to" serial number (e.g. range entered) the amount field
         * and payee field  is disabled and can never be mandatory even if set in the
         * server meta data
         */
        amount.shouldBeOptional(true);
        amount.setValue('');
        amount.$el.parent().find('.help-block').empty();
        payee.shouldBeOptional(true);
        payee.$el.parent().find('.help-block').empty();
        tranDate.shouldBeOptional(true);
        tranDate.$el.parent().find('.help-block').empty();
    }

    // reset the amount field and payee field to it's initial mandatory state when
    if (toSerialNumber.isEmpty()) {
        amount.shouldBeRequiredWhen(amount.$el.data('initial-required-state'), true);
        payee.shouldBeRequiredWhen(payee.$el.data('initial-required-state'), true);
    }

    /*
     * User can go as far back as the beginning of time (12/31/1969) and
     * as far forward as they like.
     */
    if (formState !== 'view') {
        if (form.formView.$el.find('#TRAN_DATE').data('daterangepicker') !== undefined) {
            form.formView.$el.find('#TRAN_DATE').data('daterangepicker').updateCalendars({
                daysBack: moment(new Date(0)).format(userInfo.getDateFormat()),
            });
        }
    } else if (model.get('STOPTYPE') === 'PREAUTHDEBIT') {
        // hide the serial number for PAD in view mode
        $('#SERIALNUMBERRANGE').hide();
    }

    if (model.get('STOPTYPE') === 'CHECK') {
        form.formView.$('[data-region="serialnumberrangeWidgetRegion"]').show(); // show range widget
        validatorShouldExist('SERIALNUMBER');
        model.set('STOPPLACEMENT_REASON', model.get('STOPPLACEMENT_REASON_CHECK'));
        stopReasonPreAuthDebits.shouldBeOptional(true);
        stopReasonChecks.shouldBeVisible();
        stopReasonPreAuthDebits.shouldBeHidden();
        $('.CM_INST_STOP_PAYMENT_PADNOTE').hide();
    } else if (model.get('STOPTYPE') === 'PREAUTHDEBIT') {
        model.set({
            SERIALNUMBER: '',
            SERIALNUM_TO: '',
            STOPPLACEMENT_REASON: model.get('STOPPLACEMENT_REASON_PAD'),
        });
        setReadOnlyFields();
        form.formView.$('[data-region="serialnumberrangeWidgetRegion"]').hide(); // hide range widget
        validatorShouldNotExist('SERIALNUMBER');
        amount.setValidator(amount.$el, 'exists', true);
        payee.setValidator(payee.$el, 'exists', true);
        if (formState === 'insert' || formState === 'modify') {
            if (payee.$el.val() !== undefined && payee.$el.val().trim() === '' && amount.$el.val() !== '') {
                payee.removeValidator('exists');
            }
            if (amount.$el.val() === '' && payee.$el.val() !== undefined && payee.$el.val().trim() !== '') {
                amount.removeValidator('exists');
            }
        }
        model.set('STOPPLACEMENT_REASON', model.get('STOPPLACEMENT_REASON_PAD'));
        stopReasonChecks.shouldBeOptional(true);
        stopReasonPreAuthDebits.shouldBeVisible();
        stopReasonChecks.shouldBeHidden();
        $('.CM_INST_STOP_PAYMENT_PADNOTE').show();
    }
    // Should be readonly when it is for modify and Extend Stop
    if (formState === 'modify' && model.get('CHECK_STOP_TYPE') === '1') {
        payee.shouldBeReadOnly(true);
        memo.shouldBeReadOnly(true);
        tranDate.shouldBeReadOnly(true);
        amount.shouldBeReadOnly(true);
        stopReasonChecks.shouldBeReadOnly(true);
        serialNumber.shouldBeReadOnly(true);
        expirationDate.shouldBeReadOnly(true);
        extendExpirationDate.shouldBeReadOnly(true);
        toSerialNumber.shouldBeReadOnly(true);
    }
    if (model.get('CHECK_STOP_TYPE') !== '1') {
        expirationDate.shouldBeHidden(true);
    }
}
