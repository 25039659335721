var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"col-4\">\n        <div class=\"form-group required\">\n                        <label for=\"TEMPLATE_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":3,"column":45},"end":{"line":3,"column":51}}}) : helper)))
    + "\"> "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"payment.template.name",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":54},"end":{"line":3,"column":88}}}))
    + "</label>\n                        <input type=\"hidden\" id=\"TEMPLATE_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":4,"column":58},"end":{"line":4,"column":64}}}) : helper)))
    + "\" class=\"form-control template-field\" name=\"TEMPLATE\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"templateName") || (depth0 != null ? lookupProperty(depth0,"templateName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"templateName","hash":{},"data":data,"loc":{"start":{"line":4,"column":125},"end":{"line":4,"column":141}}}) : helper)))
    + "\">\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"TEMPLATE\"></span>\n                        <small class=\"readonly-data hide\"></small>\n        </div>\n        </div>\n        <div class=\"col-4\">\n        <div class=\"form-group\">\n                        <label for=\"ENTRY_DATE_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":11,"column":47},"end":{"line":11,"column":53}}}) : helper)))
    + "\" class=\"main-label\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"payment.value.date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":74},"end":{"line":11,"column":105}}}))
    + "</label>\n                        <input class=\"form-control input-date active\" type=\"text\" size=\"20\" data-bind=\"model\" name=\"ENTRY_DATE\" value=\"\" id=\"ENTRY_DATE_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":12,"column":152},"end":{"line":12,"column":158}}}) : helper)))
    + "\">\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"ENTRY_DATE\"></span>\n        </div>\n        </div>\n        <div class=\"col-3\">\n        <div class=\"form-group\">\n                        <label for=\"AMOUNT_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":18,"column":43},"end":{"line":18,"column":49}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"payment.amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":51},"end":{"line":18,"column":78}}}))
    + "</label>\n            <div class=\"input-group\">\n                                <input class=\"form-control quick-entry-amount\" type=\"text\" name=\"AMOUNT\" data-bind=\"model\" value=\"\" id=\"AMOUNT_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":20,"column":143},"end":{"line":20,"column":149}}}) : helper)))
    + "\" maxlength=\"19\">\n            <div class=\"input-group-addon\">\n              <label for=\""
    + alias4(lookupProperty(helpers,"locale").call(alias1,"payment.currency",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":26},"end":{"line":22,"column":55}}}))
    + "_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":22,"column":56},"end":{"line":22,"column":62}}}) : helper)))
    + "\" class=\"sr-only\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"payment.currency",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":80},"end":{"line":22,"column":109}}}))
    + "</label>\n              <input class=\"currency-field\" id=\""
    + alias4(lookupProperty(helpers,"locale").call(alias1,"payment.currency",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":48},"end":{"line":23,"column":77}}}))
    + "_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":23,"column":78},"end":{"line":23,"column":84}}}) : helper)))
    + "\" readonly=\"\" data-bind=\"model\" tabindex=\"-1\">\n            </div>\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"AMOUNT\"></span>\n          </div>\n          <span id=\"QUICKENTRY_TMPL_MAX_AMT_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":27,"column":44},"end":{"line":27,"column":50}}}) : helper)))
    + "\" class=\"textline-field\"></span>\n        </div>\n        </div>\n        <div class=\"col-1\">\n            "
    + alias4(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"btn btn-tertiary btn-lg","textVisible":false,"action":"removeBtn","locale":"payment.quickentry.delete","name":"close"},"data":data,"loc":{"start":{"line":31,"column":12},"end":{"line":31,"column":142}}}))
    + "\n        </div>\n";
},"useData":true});