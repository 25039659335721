var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "active";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"section section-container\">\n    <div class=\"section-header\">\n        <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"reports.lockbox.report",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":47}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"reportDate") || (depth0 != null ? lookupProperty(depth0,"reportDate") : depth0)) != null ? helper : alias3),(typeof helper === "function" ? helper.call(alias1,{"name":"reportDate","hash":{},"data":data,"loc":{"start":{"line":3,"column":48},"end":{"line":3,"column":62}}}) : helper)))
    + "</h2>\n\n        <div class=\"viewControls\">\n\n            <div class=\"lbxViewType\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.view",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":23},"end":{"line":8,"column":44}}}))
    + "</label>\n                <div class=\"btn-group view-switcher\">\n                    <button  type=\"button\" class=\"btn btn-secondary "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isListView") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":68},"end":{"line":10,"column":99}}})) != null ? stack1 : "")
    + " btn-list-view\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.List",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":115},"end":{"line":10,"column":139}}}))
    + "</button>\n                    <button  type=\"button\" class=\"btn btn-secondary "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isGroupView") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":68},"end":{"line":11,"column":100}}})) != null ? stack1 : "")
    + " btn-tile-view\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.Group",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":116},"end":{"line":11,"column":141}}}))
    + "</button>\n                </div>\n            </div>\n\n\n        </div>\n\n        <div class=\"btn-wrapper\">\n            "
    + ((stack1 = (lookupProperty(helpers,"gridUtilitySection")||(depth0 && lookupProperty(depth0,"gridUtilitySection"))||alias3).call(alias1,(depth0 != null ? lookupProperty(depth0,"gridUtilityOptions") : depth0),{"name":"gridUtilitySection","hash":{},"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":19,"column":55}}})) != null ? stack1 : "")
    + "\n        </div>\n    </div>\n    <div class=\"section-body\">\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        <div data-region=\"contentRegion\"></div>\n    </div>\n</div>\n";
},"useData":true});