import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@glu/theming';
import { Pagination } from '@glu/data-components';
import styles from './Footer.styles';
import { getTotalItems } from './Footer.util';
import '../../themeDefaults';

const Footer = (props) => {
  const {
    classes,
    className,
    data,
    onChange,
    records,
    gridId,
    paginationTotalItems,
    visibleItems,
    ...rest
  } = props;

  const getTotalItemsCallback = useCallback(() => (
    getTotalItems(paginationTotalItems, visibleItems, records)
  ), [paginationTotalItems, records, visibleItems]);

  return (
    <div className={`${className || ''} ${classes.root}`}>
      <Pagination
        data={data}
        onChange={onChange}
        totalItems={getTotalItemsCallback()}
        htmlIdRoot={gridId}
        {...rest}
      />
    </div>
  );
};

Footer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  data: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        colId: PropTypes.string
      })
    )
  }).isRequired,
  /** Id for the grid used for storage and export title */
  gridId: PropTypes.string.isRequired,
  /** Can explicitly set pagination count. Will fallback to records length */
  paginationTotalItems: PropTypes.number,
  /** records data should match column defs, but doesn't have any particular shape */
  records: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  visibleItems: PropTypes.arrayOf(PropTypes.shape({})),
  /** Change event called anytime a component is called. Passes data in the shape of:
   {name: '', value: '', errors: {} }
   */
  onChange: PropTypes.func
};

Footer.defaultProps = {
  paginationTotalItems: null,
  visibleItems: null,
  className: '',
  onChange: /* istanbul ignore next */ () => {}
};

export default withTheme(withStyles(styles)(Footer));
