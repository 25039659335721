import Controller from '@glu/core/src/controller';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import transform from 'common/util/transform';

const AccountPaymentDetail = Controller.extend({
    constructor(...args) {
        const [request] = args;
        Controller.prototype.constructor.apply(this, args);
        // this.request = request || this.createDefaultRequest();
        this.hash = request ? transform.pairsToHash(request.item) : this.createDefaultHash();
        this.handleSuccess = util.bind(this.handleSuccess, this);
        this.url = services.beneAddressBookPaymentDetail;
        this.handleError = util.bind(this.handleError, this);
        this.handleIBANSuccessResponse = util.bind(this.handleIBANSuccessResponse, this);
    },

    createDefaultHash() {
        return {
            // IBAN
            BENE_ACCOUNT_TYPE: '',
            BENE_ACCOUNT: '',
            PRODUCT: 'RTGS',
            CONTACTTYPE: '',
        };
    },

    createDefaultRequest() {
        return {
            item: [{
                name: 'BENE_ACCOUNT_TYPE',
                value: '',
            }, {
                name: 'BENE_ACCOUNT',
                value: '',
            }, {
                name: 'PRODUCT',
                value: 'RTGS',
            }, {
                name: 'CONTACTTYPE',
                value: '',
            }],
        };
    },

    setIntermediaryId(intermediaryId) {
        this.hash.INTERMEDIARY_ID = intermediaryId;
        this.hash.ONLYBANKDETAILS = 'true';
        return this;
    },

    setIntermediaryIdType(intermediaryIdType) {
        this.hash.INTERMEDIARY_IDTYPE = intermediaryIdType;
        return this;
    },

    setContactType(contactType) {
        this.hash.CONTACTTYPE = contactType;
        return this;
    },

    setParentTnum(parentTnum) {
        this.hash.PARENTTNUM = parentTnum;
        return this;
    },

    setBeneBankName(beneBankName) {
        this.hash.BENE_BANK_NAME = beneBankName;
        return this;
    },

    setBeneAccountNumber(beneAccountNumber) {
        this.hash.BENE_ACCOUNTNUMBER = beneAccountNumber;
        return this;
    },

    setBeneBankIDType(beneBankIDType) {
        this.hash.BENE_BANK_IDTYPE = beneBankIDType;
        return this;
    },

    setBeneAccountCurrency(beneAccountCurrency) {
        this.hash.BENE_ACCOUNT_CURRENCY = beneAccountCurrency;
        return this;
    },

    setChangeSimulation(changeSimulation) {
        this.hash.CHANGE_SIMULATION = changeSimulation;
        return this;
    },

    setAccountCode(accountCode) {
        this.hash.BENE_BANK_ID = accountCode;
        this.hash.BENE_ACCOUNT = accountCode;
        return this;
    },

    setAccountType(accountType, isIBANNotDerived) {
        if (accountType === 'IBAN' && !isIBANNotDerived) {
            this.url = services.beneAddressBookPaymentDetail;
        } else {
            this.url = services.beneAddressBankInfo;
        }
        this.hash.BENE_ACCOUNT_TYPE = accountType;
        return this;
    },

    setIBANNotDerived(isIBANNotDerived) {
        this.hash.IBANNOTDERIVED = isIBANNotDerived;
        return this;
    },

    setProduct(product) {
        if (product) {
            this.hash.PRODUCT = product;
        } else {
            delete this.hash.PRODUCT;
        }
    },

    setBeneBankIdEntryMethod(entryMethod) {
        if (entryMethod === 'FREEFORM') {
            this.url = services.beneAddressBookFreeFormInfo;
        } else {
            this.url = services.beneAddressBookPaymentDetail;
        }
        this.hash.BENEBANKIDENTRYMETHOD = entryMethod;
        return this;
    },

    setBeneBankCountry(country) {
        this.hash.BENE_BANK_COUNTRY = country;
        return this;
    },

    setFreeFormTypeList(freeFormList) {
        this.hash.FREEFORM_TYPES_LIST = freeFormList;
        return this;
    },

    getConfigInfo() {
        this.url = services.beneAddressBookConfigInfo;
        this.send();
        // return it back to what it should be
        this.url = services.beneAddressBookPaymentDetail;
        return this;
    },

    getBankDetail() {
        this.url = services.beneAddressBankInfo;
        this.send();
        return this;
    },

    send() {
        this.request = {
            item: {
                item: transform.hashToPairs(this.hash),
            },
        };

        return http.post(this.url, this.request, this.handleSuccess, this.handleError);
    },

    sendIBANRequest() {
        this.request = {
            item: {
                item: transform.hashToPairs(this.hash),
            },
        };

        return http.post(
            this.url,
            this.request,
            this.handleIBANSuccessResponse,
            this.handleError,
        );
    },

    handleSuccess(response) {
        this.resetKeyValues();
        this.trigger('success', response);
    },

    handleIBANSuccessResponse(response) {
        this.resetKeyValues();
        this.trigger('successIBANResponse', response);
    },

    resetKeyValues() {
        /*
         * clear out some key values after query is done, so they dont get sent on
         * next query by accident
         * This really should be handled by the caller, but cleaning up after self is
         * a good practice.
         */
        this.setBeneBankCountry(null);
        this.setBeneAccountCurrency(null);
    },

    clearHash() {
        this.hash = this.createDefaultHash();
    },

    handleError() {},
});

export default AccountPaymentDetail;
