import React, {
    useState,
    useReducer,
    useEffect,
    useCallback,
    useContext,
} from 'react';
import PropTypes from 'prop-types';
import useLocale from 'hooks/useLocale';
import Layout from '@glu/layout-react';
import { Loading } from '@glu/indicators-react';
import FormFieldStatic from 'components/FormFieldStatic/FormFieldStatic';
import AuditSectionSimple from 'components/AuditSectionSimple/AuditSectionSimple';
import { Button, PRIMARY, SECONDARY } from '@glu/buttons-react';
import FormFieldStaticContainer from 'components/FormFieldStaticContainer/FormFieldStaticContainer';
import ActionButtonContainer from 'components/ActionButtonContainer/ActionButtonContainer';
import PrintModal from 'components/PrintModal/PrintModal';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import useNavigation from 'hooks/useNavigation';
import useCache from 'hooks/useCache';
import entitledActions from 'common/util/entitledActions';
import { ContentEditorProvider } from 'components/ContentEditor/ContentEditorContext';
import ContentEditorContainer from 'components/ContentEditor/ContentEditorContainer';
import {
    getAlert,
    getAlertEntitlements,
    getParameters,
    alertAction,
} from '../services';
import {
    MODE,
    ALERT_DELIVERY_TYPE,
    CONTENT_TYPE_ID,
    ACTION,
} from '../constants';
import AlertContext from '../AlertWrapper/AlertContext';
import DeliveryTypeTabs from '../DeliveryTypeTabs/DeliveryTypeTabs';

const isBankDefined = contentType => contentType === CONTENT_TYPE_ID.BANK_DEFINED;
const modelHasAction = (action, buttons) => buttons.some(button => button.action === action);

const AlertReadOnly = ({
    returnRoute,
}) => {
    const { cancel, navigateTo } = useNavigation({
        returnRoute,
    });
    const {
        alertData,
        alertDeliveryType,
        alertParameters,
        setAlertData,
        setAlertParameters,
        setEntitlements,
        modelData,
        modelActions,
    } = useContext(AlertContext);
    useEffect(() => {
        // When there isn't an ALERTCONTENTID, return to the list view
        if (!modelData.ALERTCONTENTID) {
            cancel();
        }
    }, [modelData, cancel]);

    const { getLocaleString } = useLocale();

    const onModify = useCallback(() => {
        navigateTo(`/CMAINT/alertContentManagement/alert/${MODE.MODIFY}`);
    }, [navigateTo]);
    const onCopy = useCallback(() => {
        navigateTo(`/CMAINT/alertContentManagement/alert/${MODE.COPY}`);
    }, [navigateTo]);

    const [isLoading, setIsLoading] = useState(true);
    const [printOptions] = useState({
        exportModel: {
            expData: 'transaction',
            actionData: {
                productCode: 'CMAINT',
            },
            searchFields: [
                {
                    fieldName: 'ALERTCONTENTID',
                    operator: 'IN',
                    fieldValue: [
                        modelData.ALERTCONTENTID,
                    ],
                    dataType: 'number',
                },
            ],
            detailReportId: 60016,
        },
    });
    const [showPrint, toggleShowPrint] = useReducer(state => !state, false);
    const [showConfirm, toggleShowConfirm] = useReducer(state => !state, false);

    const { set: setAlertmessage } = useCache('alertContentManagement_alerts-alertMessage');
    const { set: setConfirmResponse } = useCache('alertContentManagement_alerts-confirms');
    const onButtonAction = action => () => {
        alertAction(action, modelData).then((resp) => {
            setAlertmessage(action);
            setConfirmResponse(resp);
            navigateTo(returnRoute);
        });
    };
    const onApprove = onButtonAction(ACTION.APPROVE);
    const onDelete = onButtonAction(ACTION.DELETE);
    const onConfirm = (okay) => {
        if (okay) {
            onDelete();
        }
        toggleShowConfirm();
    };

    useEffect(() => {
        Promise.all([
            getAlert(modelData),
            getAlertEntitlements(),
            getParameters({
                ALERTTYPEID: modelData.ALERTTYPEID,
                LOCALEID: modelData.LOCALEID,
            }),
        ]).then(([alertResp, entitledActionResp, parametersResp]) => {
            setAlertData({
                ...alertResp,
                CONTENTTYPE: modelData.CONTENTTYPE,
                ALERTGROUP: modelData.ALERTGROUPDESCRIPTION,
                ALERTTYPE: modelData.ALERTTYPE,
                LOCALE: modelData.LOCALE,
                STATUS: modelData.STATUS,
            });
            setEntitlements(entitledActionResp);
            setAlertParameters(parametersResp);
            setIsLoading(false);
        });
    }, [modelData, setAlertData, setAlertParameters, setEntitlements]);

    return (
        <Loading isLoading={isLoading}>
            <PrintModal
                show={showPrint}
                onHide={toggleShowPrint}
                options={printOptions}
            />
            <ConfirmModal
                title={getLocaleString('tableMaintenance.dialog.confirm.title.delete')}
                message={getLocaleString('CMAINT.alerts.confirmation.message.delete')}
                show={showConfirm}
                onButtonAction={onConfirm}
            />
            <FormFieldStaticContainer>
                <Layout container>
                    <FormFieldStatic
                        col={3}
                        label={getLocaleString('CMAINT.Alert.Group')}
                        name="ALERTGROUP"
                        value={alertData?.ALERTGROUP}
                    />
                    <FormFieldStatic
                        col={3}
                        label={getLocaleString('CMAINT.Alert.Type')}
                        name="ALERTTYPE"
                        value={alertData?.ALERTTYPE}
                    />
                    <FormFieldStatic
                        col={3}
                        label={getLocaleString('CMAINT.Locale')}
                        name="LOCALE"
                        value={alertData?.LOCALE}
                    />
                </Layout>
                <Layout container>
                    <FormFieldStatic
                        col={3}
                        label={getLocaleString('CMAINT.Content')}
                        name="contentType"
                        value={alertData?.CONTENTTYPE}
                    />
                    { isBankDefined(modelData.CONTENTTYPEID) && (
                        <>
                            <FormFieldStatic
                                col={3}
                                label={getLocaleString('CMAINT.Market.Segments')}
                                name="marketSegments"
                                value={alertData?.MARKETSEGMENTS?.join(', ')}
                            />
                            <FormFieldStatic
                                col={3}
                                label={getLocaleString('CMAINT.Status')}
                                name="status"
                                value={alertData?.STATUS}
                            />
                        </>
                    )}
                </Layout>
            </FormFieldStaticContainer>
            <div className="section section-container">
                <Layout container>
                    <FormFieldStatic
                        col={6}
                        label={getLocaleString('CMAINT.Subject')}
                        name="ALERTSUBJECT"
                        value={alertData?.ALERTSUBJECT}
                    />
                </Layout>
                <DeliveryTypeTabs />
                <ContentEditorProvider
                    previewOnly
                >
                    <ContentEditorContainer
                        alertParameters={alertParameters?.EMAIL}
                        content={alertData.EMAILCONTENT}
                        contentType="html"
                        isShown={alertDeliveryType === ALERT_DELIVERY_TYPE.EMAIL}
                    />
                </ContentEditorProvider>
                <ContentEditorProvider
                    previewOnly
                >
                    <ContentEditorContainer
                        alertParameters={alertParameters?.TEXT}
                        content={alertData.TEXTCONTENT}
                        contentType="text"
                        isShown={alertDeliveryType === ALERT_DELIVERY_TYPE.SMS}
                    />
                </ContentEditorProvider>
                {isBankDefined(modelData.CONTENTTYPEID) && (
                    <section>
                        <AuditSectionSimple
                            data={alertData}
                        />
                    </section>
                )}
                <ActionButtonContainer>
                    {isBankDefined(modelData.CONTENTTYPEID) && (
                        <>
                            { modelHasAction(entitledActions.ACTIONS.modify, modelActions)
                                && (
                                    <Button
                                        className="btn btn-primary"
                                        variant={PRIMARY}
                                        text={getLocaleString('button.modify')}
                                        onClick={onModify}
                                    />
                                )}
                            { modelHasAction(entitledActions.ACTIONS.approve, modelActions)
                                && (
                                    <Button
                                        className="btn btn-secondary"
                                        variant={SECONDARY}
                                        text={getLocaleString('button.approve')}
                                        onClick={onApprove}
                                    />
                                )}
                            { modelHasAction(entitledActions.ACTIONS.delete, modelActions)
                                && (
                                    <Button
                                        className="btn btn-secondary"
                                        variant={SECONDARY}
                                        text={getLocaleString('button.delete')}
                                        onClick={toggleShowConfirm}
                                    />
                                )}
                            { modelHasAction(entitledActions.ACTIONS.copyalert, modelActions)
                                && (
                                    <Button
                                        className="btn btn-secondary"
                                        variant={SECONDARY}
                                        text={getLocaleString('CMAINT.CopyAlert')}
                                        onClick={onCopy}
                                    />
                                )}
                        </>
                    )}
                    <Button
                        className="btn btn-secondary"
                        variant={SECONDARY}
                        text={getLocaleString('button.print')}
                        onClick={toggleShowPrint}
                    />
                    <Button
                        className="btn btn-secondary"
                        variant={SECONDARY}
                        text={getLocaleString('button.cancel')}
                        onClick={cancel}
                    />
                </ActionButtonContainer>
            </div>
        </Loading>
    );
};

AlertReadOnly.propTypes = {
    returnRoute: PropTypes.string.isRequired,
};

export default AlertReadOnly;
