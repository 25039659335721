import ExportBTRDataView from 'app/reports/views/exportBTRData/exportBTRData';
import GridFilterView from 'app/balanceAndTransaction/views/exportBTRData/gridFilterView';
import PrintExportUtil from 'common/util/printExportUtil';
import Moment from 'moment';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import systemConfig from 'system/configuration';

const GridExportBTRDataView = ExportBTRDataView.extend({
    /**
     * @param {object} options
     * @param {object} options.gridExportOptions - set standard grid export options
     * (will differ based on which list view is using this)
     * @param {function} options.gridExportCallback - specified standard grid
     * export callback (will be used if defined)
     * @param {array} options.initialFilterValues - set of default filter values
     * from the list view (need to be converted)
     * @param {boolean} options.hasSelectedRows
     * @param {boolean} options.standardExportOnly
     * Uses passed in options to pre-setup the export view before passing the
     * options into
     * the extended ExportBTRDataView to finish instantiation.
     */
    initialize(options) {
        // modify list view filters to match names used for usual export filters
        const exportDataViewOptions = Object.assign(options, this.generateDataViewOptions(options));
        this.gridView = options.gridView;

        // Call original initialize
        ExportBTRDataView.prototype.initialize.call(this, exportDataViewOptions);
    },

    /**
     * @param {object} options
     * @return {object} modified export data options
     * Takes in initial options object and returns a new options object
     * that is formatted to be used by the ExportBTR Data view
     */
    generateDataViewOptions(options) {
        const exportDataOpts = {};
        const filterValues = this.getModifiedExportFilters(options.initialFilterValues);
        const dateFilter = this.getFilterValues(filterValues, 'DATE');
        const accountFilter = this.getFilterValues(filterValues, 'ACCOUNTFILTER');
        const accountGroupFilter = this.getFilterValues(filterValues, 'ACCOUNTFILTER2');
        const baiTransCodeFilter = this.getFilterValues(filterValues, 'BAI_CODE');
        const typeAheadFilters = util.compact([
            accountFilter,
            accountGroupFilter,
            baiTransCodeFilter,
        ]);

        // generate date value options
        if (dateFilter) {
            exportDataOpts.defaultDates = this.generateDefaultDateOptions(dateFilter);
            exportDataOpts.dateRangeValue = this.generateDateRangeValue(dateFilter);
        }

        // parse account filters to mimic type-ahead account filter format
        if (typeAheadFilters.length) {
            exportDataOpts.typeAheadData = this.generateTypeAheadAccountFilters(typeAheadFilters);
        }

        exportDataOpts.initialFilterValues = filterValues;

        return exportDataOpts;
    },

    /**
     * @param {array} filterValues
     * @return {array} modified filter values
     * Searches through the filter array for account/bank/group filters and change
     * the fieldname to their export equivalents and returns the new array
     */
    getModifiedExportFilters(filterValues) {
        return util.map(filterValues, (filter) => {
            let fieldName = filter.fieldName.toUpperCase();

            switch (fieldName) {
            case 'ACCOUNTGROUP':
                fieldName = 'ACCOUNTFILTER2';
                break;
            case 'DISPLAYAMOUNT':
                fieldName = 'AMOUNT';
                break;
            case 'BAI_GROUP_CODE_DISPLAY':
                fieldName = 'BAI_GROUP_CODE';
                break;
            case 'ASOFTIME':
            case 'POST_DATE':
                fieldName = 'DATE';
                break;
            default:
                break;
            }

            return util.extend(
                {},
                filter,
                {
                    fieldName,
                },
            );
        });
    },

    /**
     * @param {array} filterValues
     * @param {string} filterName
     * @return {object} filter Item
     * Finds the filter object within the filters array passed by its fieldName
     * and returns it
     */
    getFilterValues(filterValues, filterName) {
        return util.findWhere(
            filterValues,
            {
                fieldName: filterName,
            },
        );
    },

    /**
     * @param {object} dateFilter
     * @return {string} dateRange string
     * Take in the date filter object and return a date range string
     * used to preset the filter's model
     */
    generateDateRangeValue(dateFilter) {
        // may not have endDate if only filtering on one day
        const [startDate] = dateFilter.fieldValue;

        const endDate = dateFilter.fieldValue[1];

        if (endDate) {
            return `${startDate} - ${endDate}`;
        }

        return startDate;
    },

    /**
     * @param {object} dateFilter
     * @return {object} default date values
     * Take in the grid filters and will parses for date filters to
     * return a defaultDates options object
     */
    generateDefaultDateOptions(dateFilter) {
        // may not have endDate if only filtering on one day
        const [startDate] = dateFilter.fieldValue;

        const endDate = dateFilter.fieldValue[1] || startDate;

        return {
            startDate: new Moment(new Date(startDate)),
            endDate: new Moment(new Date(endDate)),
        };
    },

    /**
     * @param {object} filters
     * @return {object} default date values
     * Take in the grid account/account group related filters and returns them in
     * an object to be
     * used in the account typeahead lookup done in extended export view\
     */
    generateTypeAheadAccountFilters(filters) {
        return util.reduce(filters, (objParam, filter) => {
            const obj = objParam;
            obj[filter.fieldName] = filter.fieldValue;
            return obj;
        }, {});
    },

    /**
     * Extended
     * @method setupExportOptions
     * Override method for export type dropdown instantiation to send in own
     * data options
     */
    setupExportTypeDropdown() {
        // pass in specified export type options if passed
        const overrideOptions = {
            data: [{
                id: 'GRID',
                text: locale.get('type.export.standardgrid'),
                name: locale.get('type.export.standardgrid'),
            }],
            preSelectFirstItem: true,
        };
        ExportBTRDataView.prototype.setupExportTypeDropdown.call(this, overrideOptions);
        // preset standard grid export as default
        this.exportTypeChange('GRID');
    },

    /**
     * Extended
     * @return {Promise}
     * Loads the main export type options for the export dropdown and passes
     * in specific
     * restricition on types allowed to show based on user's current tab
     */
    loadExportTypeOptions(typeOptions) {
        return ExportBTRDataView.prototype.loadExportTypeOptions
            .call(this, (this.options.standardExportOnly) ? [] : typeOptions);
    },

    /**
     * @method showStandardGridOption
     * Renders the filter options for a standard grid export
     */
    showStandardGridOption() {
        const { gridView } = this;
        const rows = gridView?.grid.getSelectedRows();
        const gridFilterView = new GridFilterView({
            selectedRows: rows?.length,
            model: this.model,
            hasSelectedRows: rows?.length || this.options.hasSelectedRows,
            includeDetails: this.options.includeDetails,
            totalCount: gridView?.grid.masterCollection.totalCount,
            hasRowSelector: gridView?.hasRowSelector,
        });
        this.typeBasedFilterRegion.show(gridFilterView);

        // toggle some existing ui fields on exportBTRdata view
        this.ui.$panel.hide();
        this.ui.$exportFileName.hide();
        this.ui.$exportButtons.show();
        this.ui.$conditionalField.addClass('hide');
    },

    /**
     * @param {string} exportType
     * Overrides the original export type callback to include case for a standard
     * grid export selection
     * will perform differently than other export types
     */
    exportTypeChange(exportType) {
        if (exportType === 'GRID') {
            this.showStandardGridOption();
            this.model.set('EXPTYPECODE', 'GRID');
        } else {
            this.ui.$exportFileName.show();
            ExportBTRDataView.prototype.exportTypeChange.call(this, exportType);
        }
    },

    /**
     * @param {object} defaultOptions
     * @return {object} export options
     * Generate a grid export options object with the filter selections made by
     * the user using the filter view
     */
    prepareGridExportOptions(defaultOptions) {
        const includeDetails = this.model.get('includeDetails');
        const selection = this.model.get('selection');
        const hasSelectedRows = (selection && selection === ' selected');

        return util.extend(
            defaultOptions,
            {
                includeDetails,
                hasSelectedRows,
                selection,
            },
        );
    },

    /**
     * Extended
     * @method submit
     * A submit on a standard grid export will perform a different export operation
     * than other download related options
     */
    submit() {
        // prepare the options
        const gridExportOptions = this.prepareGridExportOptions(this.options.gridExportOptions);

        if (this.model.get('EXPTYPECODE') === 'GRID') {
            // will call gridExportCallback if function is defined
            if (this.options.gridExportCallback) {
                this.options.gridExportCallback(gridExportOptions);
            } else {
                PrintExportUtil.export(gridExportOptions);
            }

            if (systemConfig.isAdmin()) {
                dialog.close();
            }
        } else {
            ExportBTRDataView.prototype.submit.call(this);
        }
    },
});

export default GridExportBTRDataView;
