/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */
import { defaultStrings, defaultStrings_en_US } from '@glu/data-components';
import defaultGridStrings from './locale/defaultStrings_en_US';

const combinedStrings = {
  ...defaultGridStrings,
  ...defaultStrings
};

const combinedEnglishStrings = {
  ...defaultGridStrings,
  ...defaultStrings_en_US
};

export {
  combinedStrings as defaultStrings,
  combinedEnglishStrings as defaultStrings_en_US
};

export { default as Actions } from './components/Actions/Actions';
export { default as ComposedView } from './components/ComposedView/ComposedView';
export { default as ComposedGrid } from './components/ComposedGrid/ComposedGrid';
export { default as Grid } from './components/Grid/Grid';
export { sizes as gridRowHeight } from './components/Grid/Grid';
export { default as GridLoading } from './components/Grid/GridLoading';
export { default as GridContext } from './components/Grid/GridContext';
export { default as MultiSelectFilter } from './components/Filters/MultiFilter';
export { default as SubRowControl } from './components/SubRowControl/SubRowControl';
export { default as Footer } from './components/Footer/Footer';
export * from './components/Grid/subRowControlColumn';
export { default as useEditAwareCellRenderer } from './editableCellRenderers/useEditAwareCellRenderer';
export { default as DatePickerCellRenderer } from './editableCellRenderers/DatePickerCellRenderer';
export { default as NumberInputCellRenderer } from './editableCellRenderers/NumberInputCellRenderer';
export { default as TextInputCellRenderer } from './editableCellRenderers/TextInputCellRenderer';
