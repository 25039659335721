import Layout from '@glu/core/src/layout';
import $ from 'jquery';
import locale from '@glu/locale';
import multiStepFormTmpl from 'common/multiStepForm/views/multiStepForm.hbs';

export default Layout.extend({
    template: multiStepFormTmpl,

    ui: {
        $formSteps: '.form-step-container button',
        $container: '.form-step-container',
    },

    events: {
        'click @ui.$formSteps': 'goToStep',
    },

    regions: {
        stepRegion: '.step-region',
    },

    initialize(options) {
        this.currentStep = options.currentStep || 0;
        this.multiFormSteps = options.multiFormSteps || false;
    },

    goToStep(e) {
        const nextStepNumber = $(e.currentTarget).attr('data-step');
        const thisStepView = this.multiFormSteps[this.currentStep].view;

        if (!thisStepView.model) {
            this.stepRegion.show(this.multiFormSteps[nextStepNumber].view);
        } else if (thisStepView.model.validate()) {
            thisStepView.model.trigger('invalid');
            this.ui.$container.find(`button[data-step="${this.currentStep}"]`).removeClass('complete');
            this.trigger('step:invalid', thisStepView);
        } else {
            this.stepRegion.show(this.multiFormSteps[nextStepNumber].view);
            this.ui.$container.find('button').removeClass('active');
            this.ui.$container.find(`button[data-step="${this.currentStep}"]`).addClass('complete');
            $(e.currentTarget).addClass('active');
            $('.alert-region').empty();
        }
    },

    onRender() {
        if (this.multiFormSteps) {
            this.stepRegion.show(this.multiFormSteps[this.currentStep].view);
            this.$el.find(`[data-step="${this.currentStep}"]`).addClass('active');
        } else {
            throw new Error(locale.get('common.no.steps'));
        }
    },

    getAllSteps() {
        return this.multiFormSteps;
    },

    templateHelpers() {
        const self = this;
        return {
            pageTitle() {
                return locale.get('common.mfa.add.challenge');
            },

            formSteps() {
                return self.multiFormSteps;
            },
        };
    },
});
