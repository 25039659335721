var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"form-group\">\n                        <p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"paymentsText") || (depth0 != null ? lookupProperty(depth0,"paymentsText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"paymentsText","hash":{},"data":data,"loc":{"start":{"line":9,"column":27},"end":{"line":9,"column":43}}}) : helper)))
    + "</p>\n                    <div class=\"noManage\" data-hook=\"payments-effected-grid\"></div>\n                    <br>\n                </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"form-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isApprove") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":20,"column":27}}})) != null ? stack1 : "")
    + "                    <div class=\"noManage\" data-hook=\"templates-effected-grid\"></div>\n                    <br>\n                </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"PS.effectedTemplatesApprove",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":27},"end":{"line":17,"column":67}}}))
    + "</p>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                       <p>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"PS.effectedTemplates",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":26},"end":{"line":19,"column":59}}}))
    + "</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog glu-dialog dialog-warning\">\n    <div class=\"modal-content\">\n        <div class=\"modal-header\">\n            <h2 class=\"modal-title\"><span class=\"icon-info-solid\"></span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"warningTitle") || (depth0 != null ? lookupProperty(depth0,"warningTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"warningTitle","hash":{},"data":data,"loc":{"start":{"line":4,"column":73},"end":{"line":4,"column":89}}}) : helper)))
    + "</h2>\n        </div>\n        <div class=\"modal-body\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPaymentGrid") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":13,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasTemplateGrid") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":24,"column":19}}})) != null ? stack1 : "")
    + "            <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.effectedPaymentsWarningContinueText",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":15},"end":{"line":25,"column":66}}}))
    + "</p>\n        </div>\n        <div class=\"modal-footer\">\n            <button type=\"submit\" class=\"btn btn-primary\" data-action=\"save\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.continue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":77},"end":{"line":28,"column":105}}}))
    + "</button>\n            <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"cancel\" data-dismiss=\"modal\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":101},"end":{"line":29,"column":127}}}))
    + "</button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});