import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@glu/dropdown-react';
import useStyles from './DropdownRemoteFilter.styles';

const propTypes = {
    onFilter: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
    })),
    fetchImmediately: PropTypes.bool,
};

const defaultProps = {
    options: [],
    fetchImmediately: true,
};

const dedupOptions = options => options.filter((option, index, array) => (
    option === array.find(t => (t.id === option.id))
));

const DropdownRemoteFilter = ({
    onFilter,
    options,
    fetchImmediately,
    ...props
}) => {
    const classes = useStyles();
    const [remoteOptions, setRemoteOptions] = useState(options);
    const [loadingData, setLoadingData] = useState(false);

    const onFilterFunc = useCallback((search = '') => {
        let subscribed = true;
        onFilter(search).then((data) => {
            if (subscribed) {
                setRemoteOptions(previous => dedupOptions([
                    ...previous,
                    ...data,
                ]));
                setLoadingData(false);
            }
        });
        return () => {
            subscribed = false;
        };
    }, [onFilter]);

    useEffect(() => {
        if (fetchImmediately) {
            onFilterFunc();
        }
        setRemoteOptions(options);
    }, [fetchImmediately, onFilterFunc, options]);

    return (
        <Dropdown
            className={classes.root}
            isLoading={loadingData}
            options={remoteOptions}
            onFilter={onFilterFunc}
            {...props}
        />
    );
};

DropdownRemoteFilter.propTypes = propTypes;
DropdownRemoteFilter.defaultProps = defaultProps;

export default DropdownRemoteFilter;
