import Model from '@glu/core/src/model';
import services from 'services';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import sbPaymentsApi from 'app/smb/api';

export default Model.extend({
    defaults: {
        BILLID: '',
        DUEDATE: '',
        TOTALAMOUNT: '',
        INVOICENUMBER: '',
        VENDORID: '',
        VENDORNAME: '',
        DUETERM: '',
        DESCRIPTION: '',
        APPROVAL_ID_REQUESTED: '',
        APPROVAL_NAME_REQUESTED: '',
        STATUS: '',
        STATUS_DESCRIPTION: '',
    },

    initialize() {
        this.validators = {
            VENDORNAME: {
                description: locale.get('smbPayments.vendor.name'),
                exists: true,
            },

            TOTALAMOUNT: {
                description: locale.get('smbPayments.amount'),
                exists: true,
                matches: sbPaymentsApi.AMOUNT_PATTERN,
            },

            DUEDATE: {
                description: locale.get('smbPayments.due.date'),
                exists: true,
                matches: sbPaymentsApi.DATE_PATTERN,
            },
        };
    },

    sync(method, model, options) {
        if (method === 'create') {
            const addData = this.convertModelAttributesToServerJSON(model.attributes);
            const route = model.get('validationOnly') ? services.billCreateValidation : services.billCreate;
            model.unset('validationOnly');
            /*
             * We don't do anything with the result.
             * If we do in the future, ignore it for validation-only submissions
             */
            http.post(route, addData, () => {
                options.success({});
            }, (result) => {
                options.error({
                    errorCode: result.status,
                    errorMessage: result.statusText,
                    message: result.responseText,
                });
            });
        }
    },

    convertModelAttributesToServerJSON(attribute) {
        const jsonData = Object.keys(attribute || {})
            .map(data => ({
                name: data,
                value: attribute[data],
            }));

        return {
            item: jsonData,
        };
    },
});
