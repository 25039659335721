import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import moment from 'moment';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';

export default {
    doFieldValidation(model, fieldname, dateOverride, state) {
        const validationService = services.generateUrl(constants.URL_DO_FIELD_VALIDATION);
        const dbcrFlag = model.get('DBCR_FLAG') || model.get('ENTERED_AMOUNT_FLAG') || model.get('Entered_Amount_Flag');
        const creditAmount = (model.get('CREDIT_AMOUNT') !== '0' && model.get('CREDIT_AMOUNT') !== '0.00') ? model.get('CREDIT_AMOUNT') || '1' : '1';
        const debitAmount = (model.get('DEBIT_AMOUNT') !== '0' && model.get('DEBIT_AMOUNT') !== '0.00') ? model.get('DEBIT_AMOUNT') || '1' : '1';

        const inputData = [{
            name: 'REQUESTTYPE',
            value: 'fieldValidation',
        }, {
            name: 'FIELDNAME',
            value: fieldname,
        }, {
            name: 'VALUE_DATE',
            value: model.get('VALUE_DATE'),
        }, {
            name: 'ACTION',
            value: 'INSERT',
        }, {
            name: 'PRODUCT',
            value: 'RTGS',
        }, {
            name: 'FUNCTION',
            value: 'INST',
        }, {
            name: 'TYPE',
            value: 'TRANSFER',
        }, {
            name: 'DEBIT_BANK_CODE',
            value: (model.fromAccount && (model.fromAccount.get('mapDataList') && model.fromAccount.get('mapDataList').DEBIT_BANK_CODE)) || model.get('DEBIT_BANK_CODE'),
        }, {
            name: 'DEBIT_COUNTRY',
            value: (model.fromAccount && (model.fromAccount.get('mapDataList') && model.fromAccount.get('mapDataList').DEBIT_COUNTRY)) || model.get('DEBIT_COUNTRY'),
        }, {
            name: 'DEBIT_CURRENCY',
            value: (model.fromAccount && (model.fromAccount.get('mapDataList') && model.fromAccount.get('mapDataList').DEBIT_CURRENCY)) || model.get('DEBIT_CURRENCY'),
        }, {
            name: 'BENE_BANK_CODE',
            value: (model.toAccount && (model.toAccount.get('mapDataList') && model.toAccount.get('mapDataList').BENE_BANK_CODE)) || model.get('BENE_BANK_CODE'),
        }, {
            name: 'BENE_BANK_COUNTRY',
            value: (model.toAccount && (model.toAccount.get('mapDataList') && model.toAccount.get('mapDataList').BENE_BANK_COUNTRY)) || model.get('BENE_BANK_COUNTRY'),
        }, {
            name: 'ENTERED_AMOUNT_FLAG',
            value: model.get('ENTERED_AMOUNT_FLAG') || model.get('DBCR_FLAG'),
        }, {
            name: 'Credit_Amount',
            value: creditAmount,
        }, {
            name: 'Debit_Amount',
            value: debitAmount,
        }, {
            name: 'CREDIT_CURRENCY',
            value: (model.toAccount && (model.toAccount.get('mapDataList') && model.toAccount.get('mapDataList').CREDIT_CURRENCY)) || model.get('CREDIT_CURRENCY'),
        }, {
            name: 'EXCHANGE_RATE',
            value: model.get('EXCHANGE_RATE'),
        }, {
            name: 'EXCHANGE_RATE_CONTRACTID',
            value: model.get('EXCHANGE_RATE_CONTRACTID'),
        }];

        return http.post(
            validationService,
            {
                item: inputData,
            },
        ).then((result) => {
            const retrievedValues = util.reduce(result.item, (acc, nvp) => {
                const accumulator = acc;
                if (state !== 'onRender' && (nvp.name === 'VALUE_DATE' || nvp.name === 'TRAN_DATE')) {
                    /*
                     * if default transfer date is enabled and our date is
                     * greater than response date, don't update date
                     */
                    const checkDate = moment(model.get('VALUE_DATE')).isBefore(moment(nvp.value));
                    const updateDate = dateOverride ? checkDate : true;

                    if (updateDate) {
                        accumulator[nvp.name] = moment(nvp.value, 'YYYY-MM-DD').format(userInfo.getDateFormat());
                    }
                } else if (nvp.name === 'MARKETCONVENTION') {
                    accumulator[nvp.name] = nvp.value;
                } else if (nvp.name === 'EXCHANGE_RATE' && !model.get('EXCHANGE_RATE_CONTRACTID')) {
                    /*
                     * The ui needs this for its own purposes to distinguish the table
                     * rate from that entered by a user.
                     */
                    accumulator.EXCHANGE_RATE_TABLE = nvp.value;
                } else if (nvp.name === 'DEBIT_AMOUNT') {
                    if (dbcrFlag === 'C' && fieldname === 'CREDIT_AMOUNT') {
                        // credit amount entered, debit amount calculated
                        accumulator.DEBIT_AMOUNT = nvp.value;
                    }
                } else if (nvp.name === 'CREDIT_AMOUNT') {
                    if (dbcrFlag === 'D' && fieldname === 'DEBIT_AMOUNT') {
                        // debit amount entered, credit amount calculated
                        accumulator.CREDIT_AMOUNT = nvp.value;
                    }
                } else if (nvp.name === 'EXCHANGERATETBD_FLAG') {
                    accumulator.EXCHANGERATETBD_FLAG = nvp.value;
                }
                return accumulator;
            }, {});
            model.set(retrievedValues);
            return result;
        });
    },
};
