/**
 * Locale Loader. Extracted from main.js
 */
import gluLocale from '@glu/locale';
import localeApi from 'system/webseries/api/locale';
import { getData } from 'common/util/services';

/**
 * Create a request for locale bundle for moment & numeral library details.
 * If the request fails, resolve anyway or the app will not start.
 * @param {string} bundleName
 * @param {function} resolve
 */
const importLocale = (bundleName, resolve) => {
    const bundlePath = `${window.Bottomline.assetRoot}/locale/${window.Bottomline.buildId}/bundles/${bundleName}.js`;

    getData(bundlePath)
        .then((bundle) => {
            // booo... I know.
            resolve(eval(bundle)); // eslint-disable-line
        })
        .catch(resolve);
};

// TODO - Can we simplify this passtrhough?
const bundles = {
    'de-ch': function (cb) {
        importLocale('de-ch', cb);
    },

    'de-de': function (cb) {
        importLocale('de-de', cb);
    },

    'en-ca': function (cb) {
        importLocale('en-ca', cb);
    },

    'en-es': function (cb) {
        importLocale('en-es', cb);
    },

    'en-gb': function (cb) {
        importLocale('en-gb', cb);
    },

    'en-us': function (cb) {
        importLocale('en-us', cb);
    },

    'es-es': function (cb) {
        importLocale('es-es', cb);
    },

    'es-us': function (cb) {
        importLocale('es-us', cb);
    },

    es(cb) {
        importLocale('es', cb);
    },

    'fr-ca': function (cb) {
        importLocale('fr-ca', cb);
    },

    'ja-jp': function (cb) {
        importLocale('jp', cb);
    },
};
/**
 * Initiate request for moment & numeral localization data.
 * @param {string} localeCode
 * @returns {Promise}
 */
const loadLocaleBundle = (localeCode) => {
    const localeName = localeCode.replace('_', '-').toLowerCase();
    if (window.Bottomline.amd) {
        // en-us.js
        const bundlePath = `${window.Bottomline.assetRoot}/locale/bundles/${localeName}.js`;
        return new Promise(resolve => gluLocale.fetch(bundlePath, resolve));
    }

    // Pass bundles into gluLocale
    gluLocale.initBundles(bundles);
    return new Promise(resolve => gluLocale.fetch(localeName, () => {
        resolve();
    }));
};

/**
 * Request resources for a specific locale and get a promise back.
 * @param {string} locale
 * @returns {Promise}
 */
const getAllResources = locale => new Promise(resolve => localeApi.getAllResources({
    locale,
    success: (data) => {
        gluLocale.clear();
        gluLocale.set(data.attributes);
        resolve(data);
    },
}));

/**
 * Request login resources for a specific locale and get a promise back.
 * Used initially for correcting language of Forgot Password Security Questions.
 * @param {string} locale
 * @returns {Promise}
 */
const getLoginResources = locale => new Promise(resolve => localeApi.getLoginResources({
    locale,
    success: (data) => {
        gluLocale.clear();
        gluLocale.set(data.attributes);
        resolve(data);
    },
}));

export {
    getAllResources,
    getLoginResources,
    loadLocaleBundle,
};
