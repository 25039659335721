import React from 'react';
import locale from '@glu/locale';
import SecurityQuestions from 'app/utilities/views/base';
import StaticView from 'app/utilities/views/staticRedirect';
import MobileViewContainer from 'components/MobileViewContainer/MobileViewContainer';
import store from 'system/utilities/cache';

export default {
    /**
     * Return the manage security question view.
     */
    manageSecurityQuestions() {
        const view = new SecurityQuestions({
            title: locale.get('securityTitle'),
            isEnrollmentPage: false,
        });
        this.showPage('', view);
    },

    ssoStaticRedirect() {
        this.showPage('', new StaticView());
    },

    showMobileView() {
        const view = store.get('mobileView');
        const returnRoute = store.get('current-workspace-route');
        // eslint-disable-next-line
        this.showPage('', <MobileViewContainer
            view={view}
            returnRoute={returnRoute}
            navigateTo={this.navigateTo}
        />);
    },
};
