import util from '@glu/core/src/util';
import buttonTmpl from 'common/dynamicPages/views/button.hbs';

export default {
    getButtonList(entitlements, exceptionList = []) {
        const buttonList = Object.keys(entitlements.actions || {})
            .filter(key => entitlements.actions[key] === true)
            .map(key => key.toLowerCase())
            .filter(key => !util.contains(exceptionList, key))
            .map(key => ({
                action: key,
                title: key,
            }));

        return {
            buttonList,
        };
    },

    getButtonHTML(entitlements, exceptionList) {
        const buttonList = this.getButtonList(entitlements, exceptionList);
        return buttonTmpl(buttonList);
    },
};
