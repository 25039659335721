/* istanbul ignore file */
export default {
  actions: 'Actions',
  cancel: 'Cancel',
  changed: 'Changed',
  default: 'Default',
  delete: 'Delete',
  deleteThisView: 'Delete this view?',
  no: 'No',
  rename: 'Rename',
  save: 'Save',
  saveAs: 'Save As',
  setDefault: 'Set Default',
  viewName: 'View Name',
  yes: 'Yes'
};
