import NestedModel from '@glu/core/src/nestedModel';
import http from '@glu/core/src/http';
import services from 'services';
import Groups from '../../collection/user2/groups';
import Types from '../../collection/user2/types';

export default NestedModel.extend({

    initialize(obj) {
        this.set(this.parse(obj));
    },

    defaults() {
        return {
            id: '',
            label: '',
            groups: new Groups(),
        };
    },

    groupToTypes(success) {
        http.post(
            services.generateUrl('/users/userCentric/v2/groupToTypes'),
            this.toJSON(),
            (response) => {
                this.set(this.parse(response));
                success();
            },
        );
    },

    getEntitledTypesForSpecificGroup(groupId) {
        return new Types(this.get('groups').findWhere({
            id: groupId,
        }).get('types').where({
            entitled: true,
        }));
    },

    hasSelectedGroup() {
        return this.get('groups').some(group => group.isEntitled());
    },

    getSelectedGroups() {
        return new Groups(this.get('groups').filter(group => group.isEntitled()));
    },

    parse(response) {
        response.groups = new Groups(response.groups);
        return response;
    },
});
