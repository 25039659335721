import RFPEntryView from 'app/payments/views/requestForPayments/rfpEntry';
import DataAPI from 'common/dynamicPages/api/data';
import PageApi from 'common/dynamicPages/api/view';
import { log } from '@glu/core';
import constants from 'common/dynamicPages/api/constants';
import Entitlements from 'common/dynamicPages/api/entitlements';
import gridApi from 'common/dynamicPages/api/grid';
import util from '@glu/core/src/util';
import Formatter from 'system/utilities/format';

const FREEFORMENTRY = '0';
const TEMPLATEENTRY = '1';
const ENTRYMETHOD2 = '2';

const CopyRFP = RFPEntryView.extend({
    ui: util.extend(
        {},
        RFPEntryView.prototype.ui,
        {
            $setLandingText: '[data-hook="setLandingText"]',
        },
    ),

    initialize(options, ...rest) {
        RFPEntryView.prototype.initialize.apply(this, [options, ...rest]);
        this.contextDef.createdFrom = '1';
        // Despite the file name, this is used whenever we are making a template.
        if (options.functionCode === 'REQTMPL') {
            this.contextDef.copySource = 'template';
            this.contextDef.keyOverride = 'requestForPayment_outgoingPaymentRequests';

            // Pass the locale key for payments to render correct app resources
            this.contextDef.localeKeyOverride = 'requestForPayment.outgoingPaymentRequests_';
        } else {
            this.contextDef.copySource = 'request';
        }
        this.contextDef.returnAccordionId = 'rfpSubmitted';
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.contextDef.serviceName = this.options.serviceName;
            this.contextDef.subType = this.options.subType;
            this.contextDef.createdFrom = '1';
            this.pageView = PageApi.page.get({
                context: this.contextDef,
                model: this.paymentModel,
                hideButtons: true,
                gridApi,
                overrideChildFunctionCode: this.targetFunction,
                hasTemplatesEntitled: this.hasTemplatesEntitled,
            });
            this.listenTo(this.pageView, 'loaded', this.setPageViewListeners);
            this.listenTo(this.pageView, 'loaded', this.getPaymentMessage);

            this.pageContent.show(this.pageView);
            this.displaySummaryTotal();
        } else {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        const keyListGlobal = ['TNUM', 'UPDATECOUNT__', 'FROMTEMPLATE', 'TARGETFUNCTION'];

        // Set templateServiceName if it doesn't exist.
        if (!this.contextDef.templateServiceName) {
            this.contextDef.templateServiceName = this.contextDef.serviceName;
        }

        const model = DataAPI.model.generateFromKeyList({
            context: {
                serviceName: this.contextDef.serviceName,
            },

            keyList: keyListGlobal,
        });
        this.targetFunction = 'REQUEST';

        model.set('TNUM', this.contextDef.tnum);
        model.set('UPDATECOUNT__', this.contextDef.updateCount);
        model.set('FROMTEMPLATE', '1');
        model.set('TARGETFUNCTION', this.targetFunction);
        model.set('FUNCTION', this.contextDef.functionCode);
        // read the model
        model.fetch({
            success: (m) => {
                this.modelFetched(m);
            },
            error: (m) => {
                log.error(m);
            },
        });
    },

    /**
     * @name modelFetched
     * @description success handler when a model is fetched (read)
     * for copy as request.  after the source model is read, we need to
     * get the model that is cached for this context (or call getModelInfo if required)
     *
     * @param {object} m - model that was read.
     */
    modelFetched(m) {
        this.model = m;
        let entrymethod = this.contextDef.functionCode === 'REQTMPL' ? TEMPLATEENTRY : FREEFORMENTRY;
        if (m.get('TEMPLATEENTRYMETHOD') && m.get('TEMPLATEENTRYMETHOD') === ENTRYMETHOD2) {
            this.model.set('ENTRYMETHOD', ENTRYMETHOD2);
            m.set('ENTRYMETHOD', ENTRYMETHOD2);
            entrymethod = ENTRYMETHOD2;
        }

        // get rfp model need for screen generation
        const modelPromise = DataAPI.model.generate({
            context: {
                functionCode: this.targetFunction,
                serviceName: this.contextDef.serviceName,
                entrymethod,
                subType: this.contextDef.subType,
                createdFrom: this.contextDef.createdFrom,
                copySource: this.contextDef.copySource,
            },
        }, false);

        modelPromise.then((paymentModelParam) => {
            this.modelPromiseCallback(paymentModelParam);
            // check if rfp templates are entitled
            const useTemplateEntitlements = Entitlements
                .getEntitlements(constants.RTP_REQUEST_TEMPLATE_ENTRY_OPTIONS);
            const hasTemplateEntitlements = Entitlements
                .getEntitlements(constants.RFP_REQUEST_TEMPLATE_ENTRY_OPTIONS);
            Promise.all([
                useTemplateEntitlements,
                hasTemplateEntitlements,
            ]).then((response) => {
                this.useTemplatesEntitled = (Object.keys(response[0].actions).indexOf('INSERT') >= 0);
                this.hasTemplatesEntitled = (Object.keys(response[1].actions).indexOf('INSERT') >= 0);

                this.setHasLoadedRequiredData(true);
                this.render();
            });
        }, (err) => {
            // handle errors
            log.error(err.statusText);
        });
    },

    /**
     * populates the model returned from the promise (getModelInfo) with
     * values from the model being copied
     * @param {object} newModel
     */
    modelPromiseCallback(newModel) {
        const requestModel = newModel;
        util.each(this.model.attributes, (value, key) => {
            if (!util.isEmpty(this.model.get(key))) {
                if (util.contains(['REQUESTED_AMOUNT', 'FULL_AMOUNT', 'DISCOUNT_AMOUNT'], key)) {
                    requestModel.set(
                        key,
                        Formatter.formatCurrency(this.model.get(key) || 0),
                    );
                } else {
                    requestModel.set(key, this.model.get(key));
                }
            }
        });
        this.paymentModel = requestModel;
    },
});
export default CopyRFP;
