const Constants = {
    GRID_CUSTOM_VIEW: 'SMB Payments Grid',
    GRID_LIST_VIEW: 'SMB Payments Grid List View',
    GRID_META_VIEW: 'Payments Grid Meta View',
    GRID_CONTEXT: 'SMBPAY_LIST_VIEW',
    TRNSFR_GRID_CONTEXT: 'SMBTRN_LIST_VIEW',
    PAYMENT_VIEW_ADD: 'payment add',
    PAYMENT_VIEW_VIEW: 'payment view',
    PAYMENT_VIEW_MODIFY: 'payment modify',
    STORE_PAYMENTS_CONTEXT: 'payments context',
    STORE_PAYMENTS_VIEWMODEL: 'payments view model',
    STORE_TRANSFERS_CONTEXT: 'transfers context',
    STORE_TRANSFERS_VIEWMODEL: 'transfers view model',
    TAX: 'BDACHTP',
    PAYROLL_AND_CVP_TYPE: 'PCVP',
    PAYROLL_AND_CP_TYPE: 'PCP',
    PAYROLL: 'BDACHP',
    REIMBURSE: 'REIMBURSE',
    CHILDSUPPORT: 'BDACHCSP',
    FEDWIRE: 'FEDWIRE',
    CRTRAN: 'CRTRAN',
    INTL: 'INTL',
    CORPORATEPAYMENT: 'BDACHCVP',
    CONSUMERPAYMENT: 'BDACHCP',
    CORPORATECOLLECTION: 'BDACHCEC',
    CONSUMERCOLLECTION: 'BDACHCRC',
    LOANDRAW: 'LOANDRAW',
    LOANPAY: 'LOANPAY',
    TRANSFER: 'TRANSFER',
    ENTRYMETHODNAME: 'Freeform',
    WIRE_DISCLOSURE_PREFIX: 'dynamicreports/',
    CURRENCY_USD: 'USD',
    REAL_TIME_REMITTANCE_MAX_LENGTH: 140,
    ACCOUNTNAME_DISPLAY: 'ACCOUNTNAME_DISPLAY',
    ACCOUNTNAME_NUM_BANK: 'ACCOUNTNAME_NUM_BANK',

    isWire(type) {
        return type === this.FEDWIRE || type === this.INTL;
    },

    isRTP(type) {
        return type === this.CRTRAN;
    },

    isACHPayment(type) {
        return type === this.CORPORATEPAYMENT || type === this.CONSUMERPAYMENT;
    },

    isACHCollection(type) {
        return type === this.CORPORATECOLLECTION || type === this.CONSUMERCOLLECTION;
    },

    isACH(type) {
        return this.isACHPayment(type) || this.isACHCollection(type);
    },

    DETAILLIST: [
        'BENE_ACCOUNTNUMBER',
        'BENE_ACCOUNTTYPE',
        'BENE_BANK_COUNTRY',
        'BENE_BANK_ADDRESS_1',
        'BENE_BANK_ADDRESS_2',
        'BENE_BANK_ADDRESS_3',
        'BENE_BANK_STATE',
        'BENE_ACCOUNT_CURRENCY',
        'BENE_BANK_IDTYPE',
        'BENE_BANK_ID',
        'BENE_BANK_NAME',
        'BENE_BANK_ACCOUNT_NUMBER',
        'BENE_NAME_CHILD',
        'CLEARINGSYSTEM',
        'STATUS',
        'STATUS_DESCRIPTION',
        'PAYMENTPRODUCT',
        'PRODUCT',
        'PAYMENTTYPE',
        'RECNUM',
        'TNUM',
        'PRIMARY_ACCOUNT',
        'INTERMEDIARY_NAME',
        'INTERMEDIARY_IDTYPE',
        'INTERMEDIARY_ID',
        'INTERMEDIARY_ADDRESS_1',
        'INTERMEDIARY_ADDRESS_2',
        'INTERMEDIARY_ADDRESS_3',
        'INTERMEDIARY_COUNTRY',
        'INTERMEDIARY_STATE',
        'INTERMEDIARY2_NAME',
        'INTERMEDIARY2_IDTYPE',
        'INTERMEDIARY2_ID',
        'INTERMEDIARY2_ADDRESS_1',
        'INTERMEDIARY2_ADDRESS_2',
        'INTERMEDIARY2_ADDRESS_3',
        'INTERMEDIARY2_COUNTRY',
        'INTERMEDIARY2_STATE',
        'BENEBANKIDENTRYMETHOD',
        'ACCTGROUPNUM',
        'INTERBANKIDENTRYMETHOD',
        'INTER_BANK_ACCOUNT_NUMBER',
        'SECOND_BENE_ACCOUNTTYPE',
        'SECOND_BENE_ACCOUNTNUMBER',
        'SECOND_BANK_ACCOUNT_TEXT',
        'SECOND_BENE_BANK_IDTYPE',
        'SECOND_BENE_BANK_SORTCODETYPE',
        'SECOND_BENE_ACCOUNT_CURRENCY',
        'SECOND_BENE_BANK_COUNTRY',
        'SECOND_ACH_ONLY',
        'SECOND_WIRE_ONLY',
        'SECOND_SECONDARY_CODE',
        'SECOND_BENE_BANK_ID',
        'SECOND_BENE_BANK_NAME',
        'SECOND_BENE_BANK_CITY',
        'SECOND_BENE_BANK_STATE',
        'SECOND_ALLOCATION_TYPE',
        'SECOND_ACCOUNT_ALLOCATION',
        'SECONDARY_IND',
    ],

    HEADERLIST: [
        'ALLOWORDERINGPARTYLOOKUP',
        'BENE_REFERENCE',
        'BENE_ADDRESS_1',
        'BENE_ADDRESS_2',
        'BENE_CITY',
        'BENE_POSTALCODE',
        'BENE_COUNTRY',
        'BENE_BANK_NAME',
        'BENE_NAME',
        'BENE_PROVINCE',
        'BENE_STATE',
        'BENE_CONTACT_NAME',
        'BENE_EMAIL1',
        'BENE_EMAIL2',
        'BENE_EMAIL3',
        'BENE_EMAIL4',
        'BENE_EMAIL5',
        'BENE_PHONENUMBER',
        'BENE_PHONE_MOBILE',
        'BENE_FAXNUMBER',
        'BENE_BIRTH_COUNTRY',
        'BENE_BIRTH_CITY',
        'BENE_BIRTH_DATE',
        'LEGAL_ENTITY_ID',
        'ORDERINGPARTY_ID',
        'ORDERINGPARTY_ID_TYPE',
        '_productCode',
        '_typeCode',
        'type',
        'functionCode',
        '_functionCode',
        'FUNCTION',
        '_mode',
        '_actionMode',
        'CONTACTTYPE',
        'CONTACTTYPE_DESC',
        'TNUM',
        'UPDATECOUNT__',
        'contactChangesAccepted',
        '_saveWithWarning',
    ],
};

export default Constants;

export const PAYMENT_SAVED_VIEW_IDS = [
    '24521',
    '24530',
    '24531',
];

export const TEMPLATE_SAVED_VIEW_IDS = [
    '33',
    '34',
];
