/*
 *  NH-111271 Adding log out to the ignoreList as these
 *  requests are pinging the keepAlive URL.
 */
const constants = {
    ignoreList: [
        'localizationService',
        'security/user',
        '/menu',
        'outboundSSO/logout',
        'security/logout',
        'configurationParameter/getInitialAppData',
        'workspaces/getAllDeeplinks',
        'workspaces/getWorkspaces',
    ],
};
export default constants;
