import Collection from '@glu/core/src/collection';
import transform from 'common/util/transform';
import http from '@glu/core/src/http';
import moment from 'moment';
import userInfo from 'etc/userInfo';
import util from '@glu/core/src/util';
import AccountCollection from './combinedAccountAccounts';

export default Collection.extend({
    initialize(models, options) {
        this.service = options.service;
        this.accountsService = options.accountsService;
        this.filterModel = options.filterModel;
        this.filterChildAccounts = !!options.filterChildAccounts;
        this.filterView = options.filterView;
        this.disableRealTime = options.disableRealTime;
    },

    /**
     * @param {object} options
     * @return {object}
     * Creates request object for account group totals call
     */
    getRequestObject(options) {
        const req = {
            rowsPerPage: 1000,
            searchFields: this.filterModel.getSearchFields(),
            startRow: 0,
        };

        req.searchFields = req.searchFields.concat(this.filterView.getTransactionFilters());

        if (options.groupName) {
            req.searchFields.push({
                fieldName: 'account_group_name',
                fieldValue: [options.groupName],
                operator: 'IN',
                dataType: 'text',
            });
        }

        return req;
    },

    sync(method, model, options) {
        const self = this;

        if (method === 'read') {
            const data = this.getRequestObject(options);

            return http.post(this.service, data, (response) => {
                if (options.updateTotal) {
                    self.updateExistingTotals(response, options.groupName);
                } else {
                    options.success(self.customParse(response, options.useGrouped));
                    self.initiateGroupAcctFetch();
                }
            }, () => {
                options.error();
            });
        }
        // TODO: Maybe return rejected promise for bad method?
        return undefined;
    },

    customParse(res, useGrouped) {
        const self = this;
        let accountGroups =
            res.rows
                .map(row => this.parseColumnsData(row.columns))
                .filter(data => data.BASECURRACCOUNT.length);
        const accountTotals = util.clone(accountGroups);
        /**
         * The server is sending rows by currency. accountTotals will be
         * used to populate currency totals and accountGroups will be used to
         * display the accounts.
         * [before FX totals NH-97988]: The server was sending only one row. Each row
         * will render an account group.
         */
        if (useGrouped) {
            accountGroups = util.uniq(accountGroups, 'ACCOUNT_GROUP_NAME');
        } else {
            accountGroups = accountGroups.slice(0, 1);
        }
        return accountGroups.map((row, i) => {
            const updateRow = row;
            const accountCollection = new AccountCollection(
                [],
                {
                    service: self.accountsService,
                    filterModel: self.filterModel,
                    groupName: updateRow.ACCOUNT_GROUP_NAME,
                    filterAccounts: self.filterChildAccounts,
                    filterView: self.filterView,
                    parentCollection: self,
                    groupIndex: i,
                    shouldLoadRealTime: self.shouldLoadRealTime(),
                },
            );
            updateRow.accountCollection = accountCollection;
            updateRow.accountTotals = accountTotals;
            this.listenToOnce(accountCollection, 'accounts:loaded', this.onAcctGroupLoaded.bind(this));
            return updateRow;
        }, this);
    },

    parseColumnsData(columns) {
        const data = transform.pairsToHash(columns, 'fieldName', 'fieldValue');

        // remove illegal chars
        return Object.keys(data).reduce((acc, key) => {
            const newKey = key.replace(/^MAX\(|^MIN\(|^SUM\(/, '').replace(/\)$/, '');
            acc[newKey] = data[key];
            return acc;
        }, {});
    },

    initiateGroupAcctFetch() {
        return Promise.all(this.map(acctGroup => acctGroup.get('accountCollection').fetch()));
    },

    /**
     * @method onAcctGroupLoaded
     * Callback to keep track of when all group of accounts have finished loading
     * and trigger event
     */
    onAcctGroupLoaded() {
        const finishedGroups = this.filter(group => group.get('accountCollection').fetchedAll);

        if (finishedGroups.length === this.length) {
            this.trigger('accountGroups:loading', false);
        }
    },

    /**
     * @method updateExistingTotals
     * @param {object} resp - response data
     * @param {string} groupName
     * Will only update the existing totals for each group after update request
     */
    updateExistingTotals(resp, groupName) {
        // only update group if one is specified
        if (groupName) {
            const group = this.find(grp => grp.get('ACCOUNT_GROUP_NAME') === groupName);
            group.set(this.parseColumnsData(resp.rows[0].columns));
            group.trigger('realTimeInformation:loading', false);
        } else {
            resp.rows.forEach((row, i) => {
                const data = this.parseColumnsData(row.columns);
                this.at(i)?.set(data);
                this.at(i)?.trigger('realTimeInformation:loading', false);
            });
        }
    },

    shouldLoadRealTime() {
        const today = moment(new Date()).format(userInfo.getDateFormat());
        return (!this.disableRealTime) && !moment(this.filterModel.get('END_DATE')).isBefore(today);
    },
});
