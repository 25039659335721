import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import styles from './RtpAlertRegion.styles';

const propTypes = {
    errorMessage: PropTypes.string,
    warningMessage: PropTypes.string,
    // Internal classes hash - do not pass
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const defaultProps = {
    errorMessage: '',
    warningMessage: '',
};

const RtpAlertRegion = ({ warningMessage, errorMessage, classes }) => {
    const errRef = useRef();
    const warnRef = useRef();
    useEffect(() => {
        if (errorMessage) {
            if (errRef.current.scrollIntoView) {
                errRef.current.scrollIntoView();
            }
        }
    }, [errorMessage]);

    useEffect(() => {
        if (warningMessage) {
            if (warnRef.current.scrollIntoView) {
                warnRef.current.scrollIntoView();
            }
        }
    }, [warningMessage]);

    return (
        <>
            {warningMessage
            && (
                <div className={classes.messageAlert} data-qa="warning-message">
                    <p ref={warnRef}>{warningMessage}</p>
                </div>
            )}
            {errorMessage
            && (
                <div className={classes.messageAlert} data-qa="error-message">
                    <p ref={errRef}>{errorMessage}</p>
                </div>
            )}
        </>
    );
};

RtpAlertRegion.propTypes = propTypes;
RtpAlertRegion.defaultProps = defaultProps;

export default withStyles(styles)(RtpAlertRegion);
