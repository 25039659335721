import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import Model from '@glu/core/src/model';

import PageApi from 'common/dynamicPages/api/view';
import workspaceHelper from 'common/workspaces/api/helper';
import loadingWidgetTmpl from 'common/templates/loadingWidget.hbs';
import DataApi from 'common/dynamicPages/api/data';
import errorHandlers from 'system/error/handlers';
import userInfo from 'etc/userInfo';

import subscriberFormTmpl from './rxpSubscriber.hbs';

const checkfreeRxpSubscriberView = Layout.extend({
    template: subscriberFormTmpl,
    loadingTemplate: loadingWidgetTmpl,

    regions: {
        pageRegion: '.pageRegion',
    },

    initialize(options) {
        this.model = options.model || new Model();
        this.context = {
            functionCode: 'MAINT',
            productCode: 'SSOOUT',
            typeCode: 'CFRXPSUB',
            entrymethod: '0',
            serviceName: '/checkFreeRXPSubscriber',
            actionData: {
                productCode: 'SSOOUT',
                functionCode: 'MAINT',
                typeCode: 'CFRXPSUB',
            },

        };
        this.model.set('retrieveLevel3UserInfo', userInfo.getCheckfreeAuthLevel() === 'Level3');
        this.model.context = this.context;
    },

    /*
     * loads the required data for the form, this can included configuration flags for widgets,
     * data to populate fields, etc.
     */
    loadRequiredData() {
        DataApi.model.generateModelFromModel(this.model).then((newModel) => {
            const modifiedModel = newModel;
            modifiedModel.key = { retrieveLevel3UserInfo: '' };
            modifiedModel.fetch({
                success: () => {
                    this.model = modifiedModel;
                    this.setHasLoadedRequiredData(true);
                    this.render();
                },
                error: util.bind(errorHandlers.loading, this),
            });
        });
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            const options = {
                context: this.context,
                hideButtons: true,
                state: 'view',
                functionCode: 'MAINT',
                productCode: 'SSOOUT',
                typeCode: 'CFRXPSUB',
                viewType: 'default',
                mode: 'view',
                model: this.model,
            };
            this.pageView = PageApi.page.get(options);
            this.pageRegion.show(this.pageView);
        } else {
            this.loadRequiredData();
        }
    },

});


workspaceHelper.publishedWidgets.add({
    id: 'CFRXPSUBSCRIBER',
    view: checkfreeRxpSubscriberView,
    options: {},
});

export default checkfreeRxpSubscriberView;
