import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import services from 'services';
import http from '@glu/core/src/http';

export default Collection.extend({
    parse(jsonData) {
        const returnArray = [];
        util.each(jsonData, (rowItem) => {
            const obj = {
                id: rowItem.name,
                name: rowItem.label,
                label: rowItem.label,
            };

            returnArray.push(obj);
        });
        return returnArray;
    },

    sync(method, model, options) {
        const url = services.generateUrl('/inquiry/getQueryResults');
        const postData = {
            IncludeMapData: 1,

            queryCriteria: {
                action: {
                    typeCode: '*',
                    entryMethod: 0,
                    productCode: 'PAY',
                    actionMode: 'INSERT',
                    functionCode: 'INST',
                },

                inquiryId: 24750,

                customFilters: [{
                    filterName: 'Depends',

                    filterParam: [
                        'paymentType',
                        model.type,
                    ],
                }],
            },

            requestHeader: {
                queryOffset: 0,
                queryPagesize: 0,
            },
        };

        http.post(url, postData, (result) => {
            options.success(result.queryResponse.QueryData.queryRows);
        }, (result) => {
            options.error(result);
        });
    },
});
