import '../themeDefaults';

export default ({ mobilegrid: { loadMore } }) => ({
  loadMoreContainer: {
    borderTop: loadMore.borderTop,
    paddingTop: '1rem',
    '& button': {
      background: 'none',
      border: 0,
      cursor: 'pointer',
      display: 'block',
      fontSize: loadMore.buttonTextSize,
      marginBottom: '0.5rem',
      color: loadMore.buttonTextColor,
      width: '100%'
    },
    '& span': {
      display: 'block',
      color: loadMore.spanTextColor,
      fontSize: loadMore.spanTextSize,
      textAlign: 'center'
    }
  }
});
