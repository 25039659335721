import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import services from 'services';
import Formatter from 'system/utilities/format';
import http from '@glu/core/src/http';
import transform from 'common/util/transform';

export default Collection.extend({
    initialize(options) {
        this.request = {
            item: transform.hashToPairs(options.request),
        };
        this.request.item.push({
            name: '_productCode',
            value: 'GIR',
        }, {
            name: '_functionCode',
            value: 'LOCKBOX',
        }, {
            name: '_typeCode',
            value: 'LBX_IMG',
        });
        if (options.request.ACCEPT) {
            this.request.item.push({
                name: 'ACCEPT',
                value: options.request.ACCEPT,
            });
        }
    },

    sync(method, model, options) {
        const url = services.generateUrl('/lockbox/getImage');

        http.post(url, this.request, (result) => {
            options.success(result);
        }, (result) => {
            options.error(result);
        });
    },

    parse(jsonData) {
        const images = [];
        const headerData = transform.pairsToHash(jsonData.headerData.item);
        const headerLabels = transform.pairsToHash(jsonData.headerLabels.item);

        util.each(jsonData.images, (image) => {
            images.push(transform.pairsToHash(image.item));
        });

        headerData.DEPOSIT_DATE = Formatter.formatDate(headerData.DEPOSIT_DATE);

        const selectedImage = headerData.IMAGE_PAGE - headerData.START_PAGE;
        return {
            images,
            headerData,
            headerLabels,
            selectedImage,
        };
    },

    convertServerJsonToModelAttributes(serverJson) {
        let data;
        let columns = {};
        const allRows = [];
        const lockboxTypes = [];

        // for each row of all lockboxes
        for (let x = 0; x < serverJson.length; x += 1) {
            data = {};
            ({ columns } = serverJson[x]);

            // for each column of a single row of a lockbox
            for (let y = 0; y < columns.length; y += 1) {
                data[columns[y].fieldName] = columns[y].fieldValue;
            }

            allRows.push(data);
        }

        util.each(allRows, (item) => {
            // Only add visible items
            if (item.HIDDEN === 'N') {
                const type = {
                    id: item.ITEM_TYPE_ID,
                    name: item.DISPLAY_NAME,
                };

                lockboxTypes.push(type);
            }
        });

        return lockboxTypes;
    },
});
