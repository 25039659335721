import { createUseStyles } from '@glu/theming';

const styles = () => ({
    wrapper: {
        paddingLeft: 25,
        paddingTop: 25,
    },
});

export default createUseStyles(styles);
