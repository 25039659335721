import Layout from '@glu/core/src/layout';

export default Layout.extend({
    template() {
        const path = window.location.pathname.split('/');
        return ({
            key: `logon.${path[path.indexOf('SSOLandingPage') + 1]}`,
        });
    },
});
