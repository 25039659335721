export default ({ grid, palette }) => ({
  root: {
    height: ({ gridHeight, domLayout }) => {
      if (domLayout === 'autoHeight') {
        return 'auto';
      }
      return gridHeight;
    },
    '& .ag-root-wrapper': {
      ...grid.gridWrapper
    },
    '& .ag-cell-wrapper': {
      height: 'auto'
    },
    '& .ag-cell-focus': {
      borderColor: `${palette.focus.normal}!important`
    },
    '&.ag-theme-material': {
      fontSize: 14,
      '& .ag-row': {
        borderBottom: `1px solid ${palette.form.border}`
      }
    },
    '& .ag-selection-checkbox .ag-icon-checkbox-checked, & .ag-selection-checkbox .ag-icon-checkbox-unchecked': {
      top: 0
    },
    '& .ag-header, .ag-pinned-left-header, .ag-pinned-right-header': {
      ...grid.header,
      color: palette.text.dark,
      border: 'none',
      overflow: 'visible',
      zIndex: 2,
      boxShadow: 'inset 2px -2px 0px 2px #cdcdcd',
      '& .ag-header-cell': {
        height: 37
      },
      '& .ag-header-cell-menu-button': {
        display: 'none'
      },
      '& .ag-header-icon': {
        height: 'auto',
        '& .ag-icon-desc, & .ag-icon-asc': {
          opacity: 1,
          width: 11,
          height: 13,
          backgroundSize: 'auto',
          backgroundImage: grid.sortArrow,
          '&:before': {
            content: 'none'
          }
        },
        '& .ag-icon-asc': {
          transform: 'rotate(180deg)'
        },
        '&.ag-filter-icon': {
          width: 24,
          height: 24,
          margin: '5px 0 0 4px',
          borderRadius: '50%',
          backgroundColor: '#36425c',
          opacity: 1,

          '& .ag-icon-filter': {
            width: 14,
            backgroundSize: 'auto',
            display: 'block',
            margin: '4px auto',
            backgroundImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAQCAYAAAAmlE46AAAAyElEQVQ4T92TXRGDQAyEdx1UQh0UCUhAQusACUhAAhIqAQlIoA7qYDt7k2OgvZbhtXnJw+XLzyZHSScAFxyzBx0v6Q7ACcYd3jFXAFUGM9SQnL/BknoAM8k+gVG1AjAAqEk+32FJNYCWZOO3BQy49bwkb2swdJhSi5F0AwbsqiNJ+9yNNRhI2icrgZ7XAW5rkuQuziTtF/sAV/NaCCvoyhZtM3cRDNir6UIs+439EZhOkeThGb2a4iX9Esfr6ErnVzyAnd+xPL8APbVhS7icxAUAAAAASUVORK5CYII=)',
            '&:before': {
              content: 'none'
            }
          }
        },
        '&.ag-header-cell-menu-button': {
          width: 30,
          height: 30,
          borderRadius: '50%',
          position: 'absolute',
          right: 5,
          top: 3,

          '&:hover': {
            backgroundColor: '#0074b9',
            '& .ag-icon-menu': {
              backgroundImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAT0lEQVQ4T83RsREAIAgDwGQCdVNH0U11gnjYWSIN1JC7D0RwGLxHkgBJC0BxcjbJmofQPwiL5LwESRZQnR1YwMjTwc8bjdCSEJztP+thwgG9xxQR6F35JgAAAABJRU5ErkJggg==)'
            }
          }
        },

        '& .ag-icon-menu': {
          display: 'block',
          height: 18,
          margin: '6px auto',
          opacity: 1,
          backgroundImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAWUlEQVQ4T2NkoBAwUqifYbAYULDzAwMDAz+J3vnIMMFdYNB4YUcBAwMjaV5g/P+Bod9jIsQLBWADBEgLg/8fGCZ4TBg0YUBWNH5gmOAuOFi8QFrwo6im2AsAUPASEWgRkjIAAAAASUVORK5CYII=)'
        }
      },

      '& .ag-pinned-right-header': {
        background: palette.background,
        position: 'relative',
        zIndex: 1
      },

      '& .ag-pinned-left-header': {
        background: 'white',
        position: 'relative',
        zIndex: 1
      },

      '& .ag-header-viewport': {
        overflow: 'visible',
        boxShadow: 'inset 2px -2px 0px 2px #cdcdcd'
      },

      '& .ag-icon-asc': {
        opacity: '0.5',
        height: '100%'
      },

      '& .ag-icon-desc': {
        opacity: '0.5',
        height: '100%'
      },

      '& .ag-header-cell-text': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        fontWeight: 'normal',
        lineHeight: 'initial',
        whiteSpace: 'normal',
        ...grid.cellHeaderText
      }
    },
    '& .ag-icon-checkbox-checked': {
      color: 'unset'
    },
    '& .ag-row': {
      zIndex: 0,
      '& .ag-icon-checkbox-checked:empty': {
        width: 18,
        height: 18,
        margin: [0, 3],
        backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE2IDBIMmEyIDIgMCAwIDAtMiAydjE0YTIgMiAwIDAgMCAyIDJoMTRhMiAyIDAgMCAwIDItMlYyYTIgMiAwIDAgMC0yLTJ6TTcgMTRMMiA5bDEuNDEtMS40MUw3IDExLjE3bDcuNTktNy41OUwxNiA1bC05IDl6IiBmaWxsPSIjMzMzIi8+PC9zdmc+)'
      },
      '& .ag-icon-checkbox-checked:before': {
        content: 'none'
      },
      '&.ag-row-focus': {
        zIndex: 1
      },
      ...grid.row
    },

    '& .action': {
      overflow: 'visible'
    }
  },

  row: {
    border: 'none'
  },

  shadedRow: {
    background: grid.row.shaded
  },

  small: {
    '& .ag-cell': {
      lineHeight: '24px',
      '& .icon-caret-left': {
        marginTop: -4
      }
    }
  },
  medium: {
    '& .ag-cell': {
      lineHeight: '34px'
    }
  },

  subRowControl: {
    /** We need to use important here because ag-grid sets width on the element
     * important use is documented in the ag-grid docs
     */
    width: '100% !important',
    whiteSpace: 'normal',
    height: 'auto',
    backgroundColor: palette.white
  }
});
