var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset class=\"section section-container\">\n    <div class=\"section-header \">\n        <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Threshold.Amount.Information",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":16},"end":{"line":3,"column":74}}}))
    + "</legend>\n    </div>\n    <div class=\"row\">\n        <div class=\"col-12\">\n\n        </div>\n    </div>\n    <div data-region=\"primaryThresholdRegion\"></div>\n\n    <div class=\"row\">\n        <div class=\"col-12 form-group\">\n            <button type=\"button\"\n                    class=\"btn btn-secondary btn-sm\"\n                    data-hook=\"getAddThresholdButton\">\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Add.Another.Threshold.Amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":17,"column":74}}}))
    + "\n            </button>\n        </div>\n    </div>\n\n    <div class=\"Panel-region\" data-region=\"thresholdPanelsRegion\"></div>\n</fieldset>\n\n\n\n\n";
},"useData":true});