import { Controller } from 'backbone.marionette';

export default Controller.extend({
    findAlignment(element) {
        const offset = element.offset();
        const height = element.outerHeight();
        const width = element.outerWidth();
        const above = this.getAbove(offset.top);
        const below = this.getBelow(offset.top, height);
        const right = this.getRight(offset.left);
        const alignment = {};

        if (above > below) {
            alignment.bottom = window.innerHeight - offset.top;
        } else {
            alignment.top = offset.top + height;
        }

        if (right > offset.left) {
            alignment.left = offset.left;
        } else {
            alignment.right = window.innerWidth - (offset.left + width);
        }

        return alignment;
    },

    getAbove(offset) {
        return offset - document.body.scrollTop;
    },

    getBelow(offset, height) {
        return (window.innerHeight + document.body.scrollTop) - (offset + height);
    },

    getRight(offset) {
        return window.innerWidth - offset;
    },
});
