import constants from 'app/balanceAndTransaction/constants';
import TransactionDetailView from 'app/balanceAndTransaction/views/depositAccounts/transactionDetails';
import store from 'system/utilities/cache';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import services from 'services';
import transform from 'common/util/transform';

const BTRImageUtil = {
    getImage(optionsParam, view) {
        const options = optionsParam;
        const { model } = options;
        const type = model.get('BAI_GROUP_CODE');
        const tranType = model.get('DR_CR');
        const postedFlag = model.get('POSTED_FLAG_DESC');
        const jsonData = [];
        const self = this;
        let postedFlagValue = '';
        let imageType = '';
        let service = '';

        if (type === 'DEPOSITED ITEMS RETURNED') {
            service = services.generateUrl(constants.RETURNED_ITEM_IMAGE_SERVICE);
            imageType = constants.RETURNED_ITEM_IMAGETYPE;
        } else if (tranType === 'CR') {
            service = services.generateUrl(constants.DEPOSIT_ACCTS_IMAGE_SERVICE);
            imageType = constants.DEPOSIT_ACCTS_IMAGETYPE;
        } else {
            service = services.generateUrl(constants.CHECK_PAID_IMAGE_SERVICE);
            imageType = constants.CHECKPAID_IMAGETYPE;
        }

        if (postedFlag === 'Pending') {
            postedFlagValue = 'I';
        } else {
            postedFlagValue = 'P';
        }

        jsonData.push({
            name: 'ACCOUNT_TITLE',
            value: model.get('ACCOUNT_NAME'),
        }, {
            name: 'ACCOUNT_NUMBER',
            value: model.get('ACCOUNT_NUM'),
        }, {
            name: 'ACCOUNTFILTER',
            value: model.get('ACCOUNTFILTER'),
        }, {
            name: 'BANKCODE',
            value: model.get('BANK_CODE'),
        }, {
            name: 'BANK_NAME',
            value: model.get('BANK_NAME'),
        }, {
            name: 'BANK_ID',
            value: model.get('BANK_ID'),
        }, {
            name: 'POST_DATE',
            value: model.get('POST_DATE'),
        }, {
            name: 'AMOUNT',
            value: model.get('AMOUNT'),
        }, {
            name: 'BANK_REF',
            value: model.get('BANK_REF'),
        }, {
            name: 'CURRENCY_CODE',
            value: model.get('CURRENCY_CODE'),
        }, {
            name: 'CUST_REF',
            value: model.get('CUST_REF'),
        }, {
            name: 'BAI_CODE',
            value: model.get('BAI_CODE'),
        }, {
            name: 'DR_CR',
            value: tranType,
        }, {
            name: 'MESSAGE_ID',
            value: model.get('MESSAGE_ID'),
        }, {
            name: 'POSTED_FLAG',
            value: postedFlagValue,
        }, {
            name: 'IMAGETYPE',
            value: imageType,
        }, {
            name: 'PIC_SEQUENCE_NUMBER',
            value: model.get('BANK_REF'),
        }, {
            name: '_productCode',
            value: model.get('PRODUCT'),
        }, {
            name: '_functionCode',
            value: model.get('FUNCTION'),
        });

        options.success = (resp) => {
            self.showImage(resp, options, view);
        };
        options.error = (resp) => {
            self.showError(resp, options, view);
        };

        http.post(
            service,
            {
                item: jsonData,
            },
            (resp) => {
                options.success(resp, options, view);
            },
            (resp) => {
                options.error(resp, options, view);
            },
        );
    },

    showError(resp, options, view) {
        if (resp.message) {
            view.renderMessage({
                type: 'DANGER',
                message: util.filter(resp.message, msg => msg.toUpperCase() !== locale.get('cm.image.not.found').toUpperCase()).join('\n'),
            });
        }
    },

    showImage(resp, options, view) {
        const { model } = options;
        const imageResponse = resp;
        const images = [];
        const headerData = transform.pairsToHash(imageResponse.headerData.item);
        const headerLabels = transform.pairsToHash(imageResponse.headerLabels.item);
        const { backImage } = imageResponse;
        const { frontImage } = imageResponse;
        const frontImageType = !util.isEmpty(frontImage) ? frontImage.contentType : '';
        const backImageType = !util.isEmpty(backImage) ? backImage.contentType : '';
        const depositSlipItems = [];

        util.each(imageResponse.images, (image) => {
            images.push(transform.pairsToHash(image.item));
        });

        util.each(resp.depositSlipItems, (depositItem) => {
            depositSlipItems.push(transform.pairsToHash(depositItem.item));
        });

        model.set({
            images,
            headerData,
            headerLabels,
            depositSlipItems,
            image_data: !util.isEmpty(frontImage) ? frontImage.value : '',
            image_type: frontImageType,
            image_data_reverse: !util.isEmpty(backImage) ? backImage.value : '',
            image_type_reverse: backImageType,
            respMessages: util.filter(imageResponse.messages, msgs => msgs.toUpperCase() !== locale.get('cm.image.not.found').toUpperCase()),
        });

        if (view.stack) {
            view.stack.push(new TransactionDetailView({
                pageTitle: locale.get('gir.transactions'),
                accountFilter: model.get('ACCOUNTFILTER'),
                model,
                result: resp.result,
            }));
        } else {
            store.set('deposit_data', model);
            store.set('check_result_bool', resp.result);
            view.navigateTo('BANK_ACC_INFO/depositAccountImage');
        }
    },
};

export default BTRImageUtil;
