import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';
import transform from 'common/util/transform';

const StopPayments = Collection.extend({
    /**
     * @name convertModelAttributesToJSON
     * @description transform the model attributes to an array of name values pairs
     * @param model
     * @returns {{item: array of NameValuePairs}}
     */
    convertModelAttributesToServerJSON(model) {
        // copy account_number_unmasked to account_number
        if (model.get('ACCOUNT_NUMBER_UNMASKED')) {
            model.set('ACCOUNT_NUMBER', model.get('ACCOUNT_NUMBER_UNMASKED'), { silent: true });
        }
        const item = transform.hashToPairs(model.attributes)
            .map(field => Object.assign(
                field,
                { value: field.value.replace('&amp;', '&') },
            ));

        // TYPE and ENTRYMETHOD are required
        item.push({
            name: 'TYPE',
            value: 'STOP',
        });
        item.push({
            name: 'ENTRYMETHOD',
            value: 0,
        });
        return {
            item,
        };
    },

    /**
     * @name collectData
     * @description creates the request for the multi-save service
     * @returns {Object {"items": [{"item": [{"name": "","value": ""}...]}]} }
     */
    collectData() {
        return {
            items: util.map(this.models, this.convertModelAttributesToServerJSON),
        };
    },

    /**
     * @name save
     * @description performs the collection save (create) operation
     * @param options
     * @returns {promise}
     */
    save(options) {
        return this.sync('save', this, options);
    },

    handleSyncSuccess(options, result) {
        options.success(result);
    },

    handleSyncError(options, result) {
        options.error(result);
    },

    /**
     * @name sync
     * @description collection sync method.  Assembles the post data and then
     * makes a request to the multi-save service
     * @param method
     * @param collection
     * @param options
     */
    sync(method, collection, options) {
        if (method === 'save') {
            // post to the multi-save service
            return http.post(
                services.generateUrl(constants.URL_STOP_MULTISAVE),
                this.collectData(),
                this.handleSyncSuccess.bind(this, options),
                this.handleSyncError.bind(this, options),
            );
        }
        return undefined;
    },
});

export default StopPayments;
