import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';

export default Model.extend({
    defaults() {
        return {
            entryMethod: 0,
            action: 'INSERT',
            subType: 'NACHA',
        };
    },

    initialize(attributes, options) {
        this.routeBase = options.routeBase ? options.routeBase : this.defaultRouteBase;
    },

    sync(method, model, options) {
        const route = services.generateUrl(constants.URL_GET_BATCH_SEQUENCE);
        http.post(route, {}, (result) => {
            options.success(result);
        }, (result) => {
            options.error({
                errorCode: result.status,
                errorMessage: result.statusText,
                message: result.responseText,
            });
        });
    },

    defaultRouteBase: '/batch/CorporateVendorPayments',
});
