import Model from '@glu/core/src/model';
import locale from '@glu/locale';
import userInfo from 'etc/userInfo';
import validatorPatterns from 'system/validatorPatterns';
import transform from 'common/util/transform';

const IssueVoid = Model.extend({
    defaults: {
        ACCOUNT_NUMBER: '',
        SERIALNUMBER: '',
        ISSUEVOIDDATE: '',
        AMOUNT: '',
        PAYEE: '',
        MEMO: '',
        ACCOUNT_TITLE: '',
        CURRENCYCODE: '',
        ACCOUNTNUM_NAME: '',
    },

    /**
     * Override default backbone model initialize method
     * @param {object} attributes - by default when a Backbone Model is instantiated,
     * these attributes passed will be set in the model
     * @param {object} options
     */
    initialize(attributes, options) {
        this.setValidators(options.ISSUEVOIDFLAG);
        this.set('ISSUEVOIDFLAG', options.ISSUEVOIDFLAG);

        this.fromAccount = options.FROMACCOUNT;
        this.fieldInfo = options.FIELDINFO;
    },

    setValidators(type) {
        this.validators = {
            ACCOUNT_NUMBER: {
                exists: true,
                description: locale.get('CM.FromAccount'),
            },

            SERIALNUMBER: {
                exists: true,
                description: locale.get('CM.SerialNumber'),
                isNumeric: true,
            },

            MEMO: {
                description: locale.get('CM.Memo'),
                matches: validatorPatterns.NOBADCHARSWITHSEMICOLON_PATTERN,
            },

            PAYEE: {
                description: locale.get('CM.PayeeName'),
                matches: validatorPatterns.NOBADCHARSWITHSEMICOLON_PATTERN,
            },
        };

        if (type === 'ISSUE') {
            this.validators.ISSUEVOIDDATE = {
                exists: true,
                description: locale.get('CM.Date'),
                matchesDatePattern: userInfo.getDateFormat(),
            };
            this.validators.AMOUNT = {
                exists: true,
                description: locale.get('CM.Amount'),
            };
        } else {
            this.validators.ISSUEVOIDDATE = {
                description: locale.get('CM.Date'),
                matchesDatePattern: userInfo.getDateFormat(),
            };
        }
    },

    toJSON() {
        let pairs = transform.hashToPairs(this.attributes);

        if (this.fromAccount) {
            pairs = pairs.concat(transform.hashToPairs(this.fromAccount.get('mapDataList')));
        }

        return {
            item: pairs,
        };
    },
});

export default IssueVoid;
