import Marionette from 'backbone.marionette';
import viewMixins from '@glu/core/src/core/internal/viewMixins';
import { appBus } from '@glu/core';

const Mixin = viewMixins(Marionette.Layout);

export default {
    /**
     * @method logout
     * logout user if they try to evade RSA check
     */
    logout() {
        this.navigateTo('logout');
    },

    navigateTo(route) {
        appBus.trigger('router:navigate:before');
        Mixin.navigateTo(route);
    },

    /**
     * @method cancelRSASoftToken
     * Notifies whoever cares to listen that the soft token modal has been closed.
     */
    cancelRSASoftToken() {
        appBus.trigger('mfa:rsasofttoken:cancel');
    },
};
