var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.business.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":1,"column":8},"end":{"line":1,"column":38}}}))
    + " <span class=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"iconClassName") || (depth0 != null ? lookupProperty(depth0,"iconClassName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"iconClassName","hash":{},"data":data,"loc":{"start":{"line":1,"column":52},"end":{"line":1,"column":69}}}) : helper)))
    + "\">\n    </span>\n</legend>\n\n<div>\n    <div class=\"form-group\">\n        <label for=\"LEGAL_ENTITY_ID\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTP.INST.CRTRAN.*.LEGAL_ENTITY_ID.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":37},"end":{"line":7,"column":89}}}))
    + "</label>\n        <input class=\"form-control\" type=\"text\" id=\"LEGAL_ENTITY_ID\" name=\"LEGAL_ENTITY_ID\"  maxlength=\""
    + alias2(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"LEGAL_ENTITY_ID") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\" data-bind=\"model\">\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"LEGAL_ENTITY_ID\"></span>\n    </div>\n</div>\n";
},"useData":true});