import BaseWidget from 'common/uiWidgets/baseWidget/baseWidget';
import Format from '@glu/core/src/templateHelpers';
import util from 'underscore';
import protectAmountWidgetTmpl from './protectAmountWidget.hbs';

export default BaseWidget.extend({
    template: protectAmountWidgetTmpl,
    className: 'protect-amount-widget',

    /**
     * Sets presets like currencyLabel and formattedValue based on the form type
     * @param {Boolean} viewMode - whether or not the widget is in view mode
     */
    determinePresets(viewMode) {
        if (viewMode) {
            this.currencyLabel = this.model.get(this.fieldData.relatedProperty);
            this.formattedValue = Format.amount(this.model.get(this.fieldData.name));
        } else {
            const GROUPING_COUNT = 3;
            const relatedPropField = util.findWhere(
                this.parentView.jsonModel,
                {
                    name: this.fieldData.relatedProperty,
                },
            );

            this.currencyLabel = relatedPropField ? relatedPropField.fieldLabel : '';

            /*
             * If maxlength is specified compute an actual maxlength which includes
             * comma separators
             */
            if (this.fieldData.maxLen) {
                this.adjustedMaxLen = this.fieldData.maxLen
                + Math.floor(this.fieldData.maxLen / GROUPING_COUNT);
            }
        }
    },

    initialize() {
        this.determinePresets(this.state === 'VIEW');
    },

    templateHelpers() {
        return {
            cid: this.parentView.cid,
            cssClass: 'form-control amount-field',
            currencyLabel: this.currencyLabel,
            fieldLabel: this.fieldData.fieldLabel,
            fieldName: this.fieldName,
            formattedValue: this.formattedValue,
            maxLen: this.adjustedMaxLen,
            name: this.fieldData.name,
            originalMaxLen: this.fieldData.maxLen,
            protected: (this.model.get('AMOUNT_MODIFICATION_ALLOWED') === '0'),
            relatedProperty: this.fieldData.relatedProperty,
            viewMode: this.state === 'VIEW',
        };
    },
});
