import $ from 'jquery';
import util from '@glu/core/src/util';
import { amountEqualityChange, amountFieldBlur } from '../../util/advancedFilterUtil';

export default function (form, initialState) {
    const { model } = form.formView;
    const $amount = form.formView.$('[name="AMOUNT"]');
    const $amount2 = form.formView.$('[name="AMOUNT2"]');
    if (initialState) {
        const $report = $('.report');

        /**
         * Hide the report from the screen until it is requested
         * unless the value of LOAD_REPORT_ON_RENDER is true
         */
        if (!model.get('LOAD_REPORT_ON_RENDER')) {
            $report.hide();
        }

        // Bind to the Run Report button to display the report and hide the fields.
        $('button[data-action="run"]').on('click', () => {
            // Show the report.
            $report.show();
        });

        // Bind to the collapsible headers to hide the report when they are clicked.
        $('a[data-toggle="collapse"]').on('click', () => {
            // Hide the report.
            $report.hide();
        });

        // For pages without collapsible headers, hide the report when an input is focused.
        $('input').on('focus', () => {
            // Hide the report.
            $report.hide();
        });

        // For Datepicker fields that need to be hidden when clicked.
        $('span.ui-datepicker-trigger').on('focus', () => {
            // Hide the report.
            $report.hide();
        });

        if (form.formView.context.filterId === '24704') {
            const viewModel = form.formView.model;
            const companyID = form.field('COMPANYID');

            /*
             * NH-126726 if more than one  usergroup is selected,
             * the company id should be disabled.
             */

            viewModel.on('change:USERGROUP', () => {
                const userGroupVal = viewModel.get('USERGROUP');
                // if more than one usergroup is selected
                if (util.isArray(userGroupVal) && userGroupVal.length > 1) {
                    viewModel.set('COMPANYID', '');
                    companyID.shouldBeReadOnly(true);
                } else {
                    companyID.shouldBeReadOnly(false);
                }
            });
        }
        /*
         * Remove listener with the specified callback that is added on the next line to prevent
         * duplicate listeners from being added
         */
        model.off('change:AMOUNT-equality', amountEqualityChange);
        model.on('change:AMOUNT-equality', amountEqualityChange);
        // Namespacing the blur event here, to prevent accidental removal of other listeners
        $amount.off('blur.filter').on('blur.filter', amountFieldBlur.bind(this, model, 'AMOUNT2'));
        $amount2.off('blur.filter').on('blur.filter', amountFieldBlur.bind(this, model, 'AMOUNT'));
        // When in modify state, need to conditionally add validators
        if (form.formView.state === 'modify') {
            amountEqualityChange(model, model.get('AMOUNT-equality'));
        }
    }
}
