import util from '@glu/core/src/util';
import Formatter from 'system/utilities/format';

export default {
    wholeNumberWithSeparatorFormat() {
        /*
         * Get user's localized options but suppress decimal position and sign.
         * Use this for view as well.  The available glu template helpers does help
         * format it like inputmask.
         */
        return util.extend(
            Formatter.getCurrencyMaskOptions(true, true),
            {
                digits: 0,
                placeholder: '',
                allowMinus: false,
                allowPlus: false,
            },
        );
    },
};
