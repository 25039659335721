import pipe from 'lodash/fp/pipe';
import curry from 'lodash/fp/curry';
import unionWith from 'lodash/fp/unionWith';
import equals from 'lodash/fp/equals';
import ldFilter from 'lodash/fp/filter';
import locale from '@glu/locale';

export function convertFilterToDataComponents(filter) {
  const { criteria, fieldId, filterId } = filter;

  const type = filterId === 'fuzzyFilter' ? 'contains' : filterId;
  const filterType = filterId === 'fuzzyFilter' ? 'text' : filterId;
  return {
    criteriaDisplay: `${type} ${criteria}`,
    field: fieldId,
    filterData: {
      filter: criteria,
      filterType,
      type
    },
    id: `${fieldId}-${type}-${type} ${criteria}`,
    nameDisplay: 'Account Name'
  };
}

export function convertFilterToDataLens(filter) {
  const { field, filterData = {} } = filter;
  const filterId = filterData.filterType === 'text' ? 'fuzzyFilter' : filterData.filterType;
  return {
    criteria: filterData.filter,
    fieldId: field,
    filterId,
    instanceId: `${field}-${filterId}-${filterData.filter}`,
    label: filterData.filter
  };
}

function convertSortToDataLens(sort) {
  return {
    column: {
      field: sort.colId
    },
    direction: sort.sort
  };
}

function convertSortToDataComponents(sort) {
  return {
    colId: sort.column.field,
    sort: sort.direction
  };
}

function convertColumnToDataLens(column, columnsHasPrimary, index) {
  return {
    ...column,
    fieldId: column.colId,
    label: column.headerName,
    primary: columnsHasPrimary ? !!column.primary : index < 3,
    title: column.headerName,
    type: column.type || 'string'
  };
}

function convertColumnToDataComponents(column) {
  return {
    ...column,
    colId: column.fieldId,
    headerName: column.label,
    type: column.type
  };
}

export function convertFromDataLens(view) {
  const {
    columns = [],
    filters = [],
    sort = []
  } = view;
  return {
    ...view,
    columns: columns.map((column) => convertColumnToDataComponents(column)),
    filters: filters.map((filter) => convertFilterToDataComponents(filter)),
    sort: sort.map((s) => convertSortToDataComponents(s))
  };
}

export function convertToDataLens(view) {
  const {
    columns = [],
    filters = [],
    sort = []
  } = view;
  const columnsHasPrimary = columns.some((column) => column.primary);
  return {
    ...view,
    columns: columns
      .map((column, index) => convertColumnToDataLens(column, columnsHasPrimary, index)),
    filters: filters.map((filter) => convertFilterToDataLens(filter)),
    sort: sort.map((s) => convertSortToDataLens(s))
  };
}

/**
 * Reports whether two objects have the same value, in [`R.equals`](#equals)
 * terms, for the specified property. Useful as a curried predicate.
 *
 * @func
 * @sig k -> {k: v} -> {k: v} -> Boolean
 * @param {String} prop The name of the property to compare
 * @param {Object} obj1
 * @param {Object} obj2
 * @return {Boolean}
 *
 * @example
 *
 *      const o1 = { a: 1, b: 2, c: 3, d: 4 };
 *      const o2 = { a: 10, b: 20, c: 3, d: 40 };
 *      R.eqProps('a', o1, o2); //=> false
 *      R.eqProps('c', o1, o2); //=> true
 */
const eqProps = curry((prop, obj1, obj2) => equals(obj1[prop], obj2[prop]));

export const eqByFieldProp = eqProps('field');

export const unionByField = unionWith(eqByFieldProp);

export function includesWith(pred, x, list) {
  let idx = 0;
  const len = list.length;

  while (idx < len) {
    if (pred(x, list[idx])) {
      return true;
    }
    idx += 1;
  }
  return false;
}

/**
 * Takes a predicate `pred`, a list `xs`, and a list `ys`, and returns a list
 * `xs'` comprising each of the elements of `xs` which is equal to one or more
 * elements of `ys` according to `pred`.
 *
 * `pred` must be a binary function expecting an element from each list.
 *
 * `xs`, `ys`, and `xs'` are treated as sets, semantically, so ordering should
 * not be significant, but since `xs'` is ordered the implementation guarantees
 * that its values are in the same order as they appear in `xs`. Duplicates are
 * not removed, so `xs'` may contain duplicates if `xs` contains duplicates.
 *
 * @func
 * @sig ((a, b) -> Boolean) -> [a] -> [b] -> [a]
 * @param {Function} pred
 * @param {Array} xs
 * @param {Array} ys
 * @return {Array}
 */
export const innerJoin = curry((pred, xs, ys) => ldFilter((x) => includesWith(pred, x, ys), xs));

export const columnScrub = curry((stored, definitions) => pipe(
  (views) => unionByField(views, definitions),
  (views) => innerJoin(eqByFieldProp, views, definitions)
)(stored));

export const groupByKey = (array, object, key) => {
  const groupedObject = array
    .reduce((hash, obj) => {
      if (obj[object][key] === undefined) return hash;
      return Object.assign(hash,
        { [obj[object][key]]: (hash[obj[object][key]] || []).concat(obj) });
    }, {});
  return Object.keys(groupedObject).length === 0
    ? [{ entries: array }] // Return single ungrouped array of entries if no key found
    : Object.keys(groupedObject).map((newKey) => ({
      entries: groupedObject[newKey],
      [key]: newKey
    }));
};

export const getEntriesForGroup = (groups, header) => {
  const groupInHeader = groups.find((group) => group.headerId === header.id);
  return groupInHeader ? groupInHeader.entries : [{ empty: header.empty || locale.get('dataComponents.emptyHeader') }];
};

export const insertIntoHeaders = (groups, headers) => (
  headers && headers.length > 0
    ? headers.map((header) => ({
      ...header,
      entries: getEntriesForGroup(groups, header)
    }))
    : groups // Return existing structure if no headings provided
);
