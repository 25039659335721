import TransactionSearchFilterModel from 'app/balanceAndTransaction/models/transSearchAccountFilter';
import TransactionSearch from 'app/balanceAndTransaction/views/tranSearch/transactionSearch';
import constants from 'app/balanceAndTransaction/constants';
import systemConfig from 'system/configuration';
import TransactionSearchFilterView from './transactionSearchFilter';

const PreviousDaySearch = TransactionSearch.extend({

    id: 'showNewTransactionSearchTab',

    attributes: {
        role: 'tabpanel',
        tabindex: '0',
        'aria-labelledby': 'showNewTransactionSearchLink',
    },
    /**
     * Overrides the base initialize to create a TransactionSearchFilterView wih
     * hidePosted
     * flag set to true.
     */
    initialize() {
        this.filterModel = new TransactionSearchFilterModel();
        this.filterView = new TransactionSearchFilterView({
            model: this.filterModel,
            hidePostedFlag: true,
        });
        this.isAdmin = systemConfig.isAdmin();
        this.listenTo(this.filterModel, 'update', this.filterUpdate);
    },

    /**
     * Overrides the base definition to define a context for the new previous
     * day deposit
     */
    getDepositContext() {
        return {
            functionCode: constants.INST,
            productCode: constants.GIR,
            serviceName: `${constants.DEPOSIT_ACCTS_SERVICE_PREFIX}previousDay/getSearchTransactions`,
            typeCode: constants.GIRTRSR2,
            exportPrintURL: this.isAdmin
                ? constants.TRANS_EXPORT_PRINT_URL : constants.ASYNC_TRANS_EXPORT_PRINT_URL,
            inquiryId: 29570,
        };
    },

    /**
     * Overrides the base definition to define a context for the new previous day loans
     */
    getLoanContext() {
        return {
            functionCode: constants.INST,
            productCode: constants.GIR,
            serviceName: `${constants.LOAN_ACCTS_SERVICE_PREFIX}getSearchTransactions`,
            typeCode: constants.LNTRNSR2,
            exportPrintURL: this.isAdmin
                ? constants.LOAN_TRANS_EXPORT_PRINT_URL
                : constants.ASYNC_LOAN_TRANS_EXPORT_PRINT_URL,
            inquiryId: 29561,
        };
    },
});

export default PreviousDaySearch;
