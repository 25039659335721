import '../../themeDefaults';

export default ({ mobilegrid: { inputRadio } }) => ({
  radio: {
    margin: '1.5rem 0',
    cursor: 'pointer',
    '& input[type="radio"]': {
      '& + div svg': {
        background: inputRadio.checkmarkBackground,
        fill: inputRadio.checkmarkBorder,
        height: 16,
        width: 16
      },
      '&:checked + div': {
        outline: 'none',
        '& svg circle': {
          fill: inputRadio.checkmarkBackgroundChecked
        }
      }
    },
    '& p': {
      fontFamily: inputRadio.fontFamily,
      fontWeight: inputRadio.fontWeight
    }
  }
});
