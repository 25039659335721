import React, {
  useCallback, useMemo
} from 'react';
import PropTypes from 'prop-types';
import FilterAccordionWrapper from '../AccordionWrappers/FilterAccordionWrapper';

import {
  createFilterValueFunction,
  createParseFilterFunction,
  getFilterComponent
} from './utils';

export default function Filterable({
  classes,
  filterable,
  filters,
  handleChange,
  handleClear,
  htmlId,
  ...props
}) {
  const filterCurrentValue = useMemo(
    () => filters.filter((filter) => filter.field === filterable.field),
    [filters, filterable.field]
  );
  const createFilterValue = useCallback(createFilterValueFunction(filterable), [filterable]);
  const parseFilterValue = useCallback(createParseFilterFunction(filterable), [filterable]);
  const Component = useMemo(() => getFilterComponent(filterable.type), [filterable]);
  const hasFilter = useMemo(
    () => !!filters.find((filter) => filter.field === filterable.field),
    [filters, filterable.field]
  );
  return (
    <FilterAccordionWrapper
      className={classes.filterWrapper}
      key={filterable.field}
      value={{
        filters: filterCurrentValue
      }}
      hasFilter={hasFilter}
      htmlId={`${htmlId}-${filterable.field}`}
      label={filterable.headerName}
      fieldId={filterable.field}
      FilterComponent={Component}
      onClearFilter={handleClear}
      onChange={handleChange}
      createFilterValue={createFilterValue}
      parseFilterValue={parseFilterValue}
      enumData={filterable.enumData}
      options={filterable.options}
      displayFormat={filterable.dateFormat}
      {...props}
    />
  );
}

Filterable.propTypes = {
  /** Css classes provided by theme */
  classes: PropTypes.objectOf(PropTypes.string).isRequired,

  /** Array of filter types. Determines what filter is shown.
   * Types include:
   * data
   * enum
   * multiselect
   * string (default)
   * typeahead
  */
  filterable: PropTypes.shape({
    dateFormat: PropTypes.string,
    enumData: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      })),
      PropTypes.instanceOf(Promise)
    ]),
    field: PropTypes.string.isRequired,
    headerName: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })),
    type: PropTypes.oneOf(['date', 'enum', 'multiselect', 'number', 'string', 'typeahead', 'range'])
  }).isRequired,

  /* Actively applied filters */
  filters: PropTypes.arrayOf(PropTypes.shape({
    criteriaDisplay: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    filterData: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    nameDisplay: PropTypes.string.isRequired,
    operator: PropTypes.oneOf(['AND', 'OR'])
  })).isRequired,

  /* Change callback function */
  handleChange: PropTypes.func.isRequired,

  /* Clear callback function */
  handleClear: PropTypes.func.isRequired,

  /** id used for qa attributes and label ids */
  htmlId: PropTypes.string.isRequired
};
