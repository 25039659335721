import locale from 'system/webseries/api/locale';

export default () => function (editor) {
    const openDialog = function () {
        return editor.windowManager.open({
            title: locale.get('common.tinymce.Horizontal.Rule'),
            body: {
                type: 'panel',
                items: [
                    {
                        type: 'colorinput',
                        name: 'color',
                        label: locale.get('common.tinymce.Color'),
                        value: '#ccc',
                    },
                    {
                        type: 'input',
                        name: 'width',
                        label: locale.get('common.tinymce.Width'),
                        maxLength: 5,
                        placeholder: '100%',
                    },
                    {
                        type: 'input',
                        name: 'height',
                        label: locale.get('common.tinymce.Height'),
                        maxLength: 5,
                        placeholder: '2px',
                    },
                ],
            },
            buttons: [
                {
                    type: 'cancel',
                    text: locale.get('button.cancel'),
                },
                {
                    type: 'submit',
                    text: locale.get('button.insertinto'),
                    primary: true,
                },
            ],
            onSubmit(api) {
                const data = api.getData();
                const hr = document.createElement('hr');

                const unitRegex = /^\d+(px|%)?$/;
                // set width
                hr.style.width = unitRegex.test(data.width) ? data.width : '100%';

                // set border
                hr.style.border = `0px solid ${data.color || '#909090'}`;

                // set height (thickness)
                hr.style.borderTopWidth = unitRegex.test(data.height) ? data.height : '2px';
                hr.style.height = '0px';

                // insert content when the window form is submitted
                editor.insertContent(hr.outerHTML);
                api.close();
            },
        });
    };
    editor.ui.registry.addButton('hr', {
        icon: 'horizontal-rule',
        onAction() {
            openDialog();
        },
    });
};
