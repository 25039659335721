import util from '@glu/core/src/util';
import constants from 'app/administration/constants';
import systemConfig from 'system/configuration';
import store from 'system/utilities/cache';
import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import ContextApi from 'common/dynamicPages/api/context';
import DataApi from 'common/dynamicPages/api/data';
import errorHandlers from 'system/error/handlers';
import ContextModel from 'common/dynamicPages/models/context';
import UserModel from 'app/administration/models/user2/user';
import UserPendingChanges from 'app/administration/views/userMaintenance2/pendingChanges/pendingChanges';
import workspaceHelper from 'common/workspaces/api/helper';
import entitlements from 'common/dynamicPages/api/entitlements';
import userPendingChangesLayoutTmpl from './userPendingChangesLayout.hbs';

export default Layout.extend({
    template: userPendingChangesLayoutTmpl,
    className: 'uce',

    initialize(opts) {
        this.model = new UserModel();
        this.mode = constants.MODES.VIEW;
        this.userGroup = opts.userGroup;
        this.userId = opts.userId;
        this.isAdmin = systemConfig.isAdmin();
        this.isDeepLink = this.options.deeplinked || false;
        this.returnURL = opts.returnRoute;
        if (this.returnURL) {
            store.set('current-workspace-route', this.returnURL);
        }
        this.initContext();

        // dynamically set all applicable button actions from store for use in the view
        this.buttons = [];
        const buttons = store.has('uce-load') ? store.get('uce-load').buttons : null;
        if (buttons) {
            this.buttons = util.filter(buttons, (buttonParam) => {
                const button = buttonParam;
                button.action = button.action.toLowerCase();
                return button.action === constants.ACTIONS.APPROVE;
            });
        }
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.initPendingChangesSection();
        } else {
            this.load();
        }
    },

    initContext() {
        this.contextDef = ContextApi.menuContext.getContext(constants.USER_MAINT);

        if (this.isAdmin) {
            this.contextDef.actionContext = {
                actionMode: 'SELECT',
                entryMethod: 0,
                functionCode: 'USERS',
                productCode: '_ADMIN',
                subType: '*',
                typeCode: '_USER',
            };
        } else {
            this.contextDef.actionContext = {
                actionMode: 'SELECT',
                entryMethod: 0,
                functionCode: 'MAINT',
                productCode: 'CMAINT',
                subType: '*',
                typeCode: 'CLUSR_TM',
            };
        }


        this.contextDef.serviceName = '/userMaintenance/user';

        this.contextModel = new ContextModel({
            menuCategory: this.options.menuCategory,
            serviceName: this.contextDef.serviceName,
            serviceFunc: this.options.serviceFunc,
            businessType: this.options.businessType,
            context: this.options.context,
            contextDef: this.contextDef,
        });

        this.contextKey = this.contextModel.getContextKey();
        this.localeKey = this.contextModel.getLocaleKey();
    },

    /**
     * @method initPendingChangesSection
     * Creates the panel section where user can preview the pending changes
     * of a user with 'modified' status. If the user has approval and rejection
     * permissions, they will also be given that option in the view
     */
    initPendingChangesSection() {
        const userActions = util.reduce(this.buttons, (actionsParam, btn) => {
            if (btn.action.toLowerCase() === constants.ACTIONS.APPROVE) {
                return {
                    ...actionsParam,
                    approve: {
                        ...btn,
                        callback: this.approve.bind(this),
                    },
                };
            }
            return actionsParam;
        }, {});

        // Create View
        this.pendingChangesView = new UserPendingChanges({
            userProfile: this.userProfileModel,
            actions: userActions,
            noCollapse: true,
            noButtons: true,
        });

        this.pendingChangesRegion.show(this.pendingChangesView);
    },

    load() {
        const metadrivenModel = new Model();

        metadrivenModel.id = this.userGroup + this.userId;
        metadrivenModel.set('USERGROUP', this.userGroup);
        metadrivenModel.set('USERID', this.userId);
        metadrivenModel.set('id', metadrivenModel.id);

        metadrivenModel.context = this.contextDef;

        this.loadUserProfile(metadrivenModel, (loadedModel) => {
            this.setUserProfile(loadedModel);
            const entitlementsPromise = entitlements.getEntitlements({
                context: {
                    serviceName: '/userMaintenance/user',
                },
            });

            entitlementsPromise.then((response) => {
                this.entitlements = response.actions;
                this.setHasLoadedRequiredData(true);
                this.render();
            });
        });
    },

    /**
     * @param {model} metadrivenModel
     * @param {function} _successCallback
     * @return {promise}
     * Returns a promise that will fetch a user's model information
     * and perform the passed in success callback
     */
    loadUserProfile(metadrivenModel, _successCallback) {
        const self = this;
        return DataApi.model.generateModelFromModel(metadrivenModel).then((loadedModel) => {
            loadedModel.fetch({
                success: _successCallback,
                error: util.bind(errorHandlers.loading, self),
            });
        });
    },

    /**
     * @param {model} newUserModel
     * Merges the user's current working userModel and merges it with the passed in userModel
     * Used for cases where the user wants to copy another user's data
     */
    setUserProfile(newUserModel) {
        /*
         * merge our current profile model attributes with the fetched userprofile
         * model (IF WE HAVE ONE)
         */
        if (this.model.userModel) {
            newUserModel.set(this.model.userModel.toJSON());
        }

        this.userProfileModel = newUserModel;
    },

    cancel() {
        if (util.has(this.model, 'userModel')) {
            this.model.userModel.trigger('user:cancel');
        }

        workspaceHelper.returnToCurrentWorkspace(this);
    },

    approve() {
        this.doAction(constants.ACTIONS.APPROVE);
    },

    doAction(action) {
        const self = this;
        this.userProfileModel[action]({
            success(model, response) {
                store.set(
                    'addUserCentricSuccessMessage',
                    {
                        action: 'action',
                        response,
                    },
                );
                self.navigateTo(self.options.returnRoute);
            },

            error(model, response) {
                store.set(
                    'addUserCentricSuccessMessage',
                    {
                        action,

                        /*
                         * Approve requires the JSON to be parsed to access the correct
                         * confirmation response.  If model.message does not exist, just
                         * pass in response, which was the original behavior.
                         */
                        response: model.message ? JSON.parse(model.message) : response,
                    },
                );
                self.navigateTo(self.options.returnRoute);
            },
        });
    },

    templateHelpers() {
        return {
            pageTitle: () => {
                const model = this.userProfileModel;
                const pageTitle = (model)
                    ? `${model.get('USERGROUP')} - ${model.get('USERID')} - ${model.get('USERNAME')}`
                    : '';
                return pageTitle;
            },
            isDeepLinked: this.isDeepLink,
            buttons: this.buttons,
        };
    },
});
