import locale from '@glu/locale';
import errHandler from 'system/errHandler';
import InquiryApi from 'system/webseries/api/inquiry';
import InquiryQueryResults from 'system/webseries/collections/inquiryQueryResults';
import BaseListView from 'common/listDeprecated/views/baseListView';
import CreateAlertView from 'app/setup/views/alerts/create/create';
import AlertsListControls from 'app/setup/views/alerts/list/controls';
import BeneficiaryListControls from 'app/setup/views/beneficiary/list/controls';
import BeneficiaryFormView from 'app/setup/views/beneficiary/create/createBeneficiary';
import ModifyBeneficiaryFormView from 'app/setup/views/beneficiary/modify/modifyBeneficiary';
import ViewBeneficiaryFormView from 'app/setup/views/beneficiary/view/viewBeneficiary';
import BankAcctFilterView from 'app/setup/views/bankAcctFilter/list';
import QuickbooksView from 'app/setup/views/quickbooks/connect';
import QuickbooksTenantView from 'app/setup/views/quickbooksTenant/quickbooksTenant';
import FileImportMapListView from 'app/setup/views/fileImportMap/list';
import FileImportMapEntryView from 'app/setup/views/fileImportMap/entry';
import AlertsListView from 'app/administration/views/listAlerts';
import ModfiyAlertRecipientView from 'app/setup/views/modifyAlertRecipient';
import AlertRecipientsListView from 'app/setup/views/listAlertRecipients';
import { processDeeplink } from 'common/dynamicPages/views/mdf/mdfUtil';
import StackView from 'common/stack/views/stackView';
import WorkspaceView from 'common/workspaces/views/workspace';

export default {
    /**
     * Return the bank holidays view.
     */
    listBankHolidays() {
        const listView = new BaseListView({
            inquiryId: InquiryApi.DEFAULT_INQUIRY_ID,
            searchType: InquiryApi.INQUIRY_CONFIGURED_SEARCH_TYPE,
            typeCode: InquiryApi.BANK_HOLIDAY_TYPE_CODE,
            title: locale.get('menuitem.SETUP.VwBankHolidays'),
            enableExport: true,
        });
        this.showPage(listView.title, listView);
    },

    /**
     * Return the beneficiary book list view.
     */
    listBeneficiary() {
        const listView = new BaseListView({
            inquiryId: InquiryApi.DEFAULT_INQUIRY_ID,
            typeCode: InquiryApi.ADDRESS_BOOK_TYPE_CODE,
            viewId: InquiryApi.DEFAULT_VIEW_ID,
            title: locale.get('menuitem.SETUP.ADBKMaint'),
            enableExport: true,

            gridOptions: {
                filter: true,
            },

            controls: new BeneficiaryListControls(),
        });
        this.showPage(listView.title, listView);
    },

    /**
     * Return the beneficiary book add form.
     */
    createBeneficiary() {
        const self = this;

        // get state codes
        const stateCodesPromise = new Promise((resolve, reject) => {
            new InquiryQueryResults({
                inquiryId: InquiryApi.STATE_CODES_LIST_INQUIRY_ID,
                typeCode: InquiryApi.ADDRESS_BOOK_TYPE_CODE,
                productCode: InquiryApi.ADMIN_PRODUCT_CODE,
                functionCode: InquiryApi.FUNCTION_CODE_MAINT,

                customFilters: [{
                    filterName: 'Depends',

                    filterParam: [
                        'label',
                        'CODE',
                    ],
                }],
            }).fetch({
                success: resolve,
                error: reject,
            });
        });

        // get country codes
        const countryCodesPromise = new Promise((resolve, reject) => {
            new InquiryQueryResults({
                inquiryId: InquiryApi.COUNTRY_CODES_LIST_INQUIRY_ID,
                typeCode: InquiryApi.ADDRESS_BOOK_TYPE_CODE,
                productCode: InquiryApi.ADMIN_PRODUCT_CODE,
                functionCode: InquiryApi.FUNCTION_CODE_MAINT,

                customFilters: [{
                    filterName: 'Depends',

                    filterParam: [
                        'label',
                        'Description',
                    ],
                }],
            }).fetch({
                success: resolve,
                error: reject,
            });
        });

        // get bank codes
        const bankCodesPromise = new Promise((resolve, reject) => {
            new InquiryQueryResults({
                inquiryId: InquiryApi.BANK_CODES_LIST_INQUIRY_ID,
                typeCode: InquiryApi.ADDRESS_BOOK_TYPE_CODE,
                productCode: InquiryApi.ADMIN_PRODUCT_CODE,
                functionCode: InquiryApi.FUNCTION_CODE_MAINT,

                labelFormat(data) {
                    return `${data.mapDataList[4].value} - ${data.mapDataList[6].value}`;
                },

                customFilters: [{
                    filterName: 'Depends',

                    filterParam: [
                        'paymentType',
                        'BDACHP',
                    ],
                }, {
                    filterName: 'Depends',

                    filterParam: [
                        'clearingSystem',
                        'CAEFT',
                    ],
                }, {
                    filterName: 'Depends',

                    filterParam: [
                        'Bene_Bank_IdType',
                        'CA',
                    ],
                }],
            }).fetch({
                success: resolve,
                error: reject,
            });
        });

        // get currency codes
        const currencyCodesPromise = new Promise((resolve, reject) => {
            new InquiryQueryResults({
                inquiryId: InquiryApi.CURRENCY_CODES_LIST_INQUIRY_ID,
                typeCode: InquiryApi.ADDRESS_BOOK_TYPE_CODE,
                productCode: InquiryApi.ADMIN_PRODUCT_CODE,
                functionCode: InquiryApi.FUNCTION_CODE_MAINT,

                customFilters: [{
                    filterName: 'Depends',

                    filterParam: [
                        'paymentType',
                        'BDACHCC',
                    ],
                }, {
                    filterName: 'Depends',

                    filterParam: [
                        'clearingSystem',
                        'NACHA',
                    ],
                }, {
                    filterName: 'Depends',

                    filterParam: [
                        'value',
                        'Code',
                    ],
                }],
            }).fetch({
                success: resolve,
                error: reject,
            });
        });

        Promise.all([
            stateCodesPromise,
            countryCodesPromise,
            bankCodesPromise,
            currencyCodesPromise,
        ]).then((results) => {
            const createBeneficiaryForm = new BeneficiaryFormView({
                title: locale.get('addressBook'),
                stateCodesCollection: results[0],
                countryCodesCollection: results[1],
                bankCodesCollection: results[2],
                currencyCodesCollection: results[3],
            });
            self.showPage(createBeneficiaryForm.title, createBeneficiaryForm);
        }).then(null, errHandler);
    },

    /**
     * Return beneficiary edit form
     *
     * @param beneId
     */
    modifyBeneficiary(beneId) {
        const self = this;

        const beneficiaryForm = new ModifyBeneficiaryFormView({
            title: locale.get('addressBook'),
            id: beneId,
        });
        self.showPage(beneficiaryForm.title, beneficiaryForm);
    },

    /**
     * Return beneficiary view form
     *
     * @param beneId
     */
    viewBeneficiary(beneId) {
        const self = this;
        const beneficiaryForm = new ViewBeneficiaryFormView({
            title: locale.get('addressBook'),
            id: beneId,
        });
        self.showPage(beneficiaryForm.title, beneficiaryForm);
    },

    /**
     * Return the alerts list view.
     */
    listAlerts() {
        const listView = new BaseListView({
            inquiryId: InquiryApi.ALERTS_INQUIRY_ID,
            searchType: InquiryApi.ACTION_CONFIGURED_SEARCH_TYPE,
            title: locale.get('menuitem.ACTRPT.AlertListView'),
            enableExport: true,

            gridOptions: {
                filter: true,
            },

            controls: new AlertsListControls(),
        });
        this.showPage(listView.title, listView);
    },

    /**
     * Return the create alert view.
     */
    createAlert() {
        const self = this;

        // get alert groups
        const alertGroupsPromise = new Promise((resolve, reject) => {
            new InquiryQueryResults({
                inquiryId: InquiryApi.ALERT_GROUPS_LIST_INQUIRY_ID,
            }).fetch({
                success: resolve,
                error: reject,
            });
        });

        // get recipients (when available)

        // Using .all() here as we will eventually be passing the recipients promise.
        Promise.all([alertGroupsPromise]).then((results) => {
            const createView = new CreateAlertView({
                title: locale.get('menuitem.ACTRPT.AlertListView'),
                alertGroupsCollection: results[0],
            });

            self.showPage(createView.title, createView);
        }).then(null, errHandler);
    },

    fileImportMapList() {
        if (processDeeplink('SETUP/fileImportMapList', this)) {
            this.showPage('File Import Map', new FileImportMapListView({
                returnRoute: 'SETUP/fileImportMapList',
            }));
        }
    },

    fileImportMapListWidget() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'IMPORT_MAP',
                    returnRoute: 'ADMINSTRATION/fileImportMap',
                }),
            }));
        }
    },

    fileImportMap(action) {
        this.showPage('File Import Map', new FileImportMapEntryView({
            mode: action,
        }));
    },

    viewBankAcctFilter() {
        this.showPage('Bank Account Filters', new BankAcctFilterView());
    },

    viewQuickbooksConnect() {
        this.showPage('Connect to Quickbooks', new QuickbooksView());
    },

    viewQuickbooksTenant() {
        const title = locale.get('menuitem.qbo.tenant');
        // 'Configure Quickbook Keys'
        this.showPage(title, new QuickbooksTenantView());
    },

    setupModifyAlertRecipient() {
        if (processDeeplink('SETUP/modifyAlertRecipient', this)) {
            this.showPage('', new ModfiyAlertRecipientView());
        }
    },

    setupAlertRecipientList() {
        this.showPage('', new AlertRecipientsListView());
    },

    setupAlertsList() {
        this.showPage('', new AlertsListView());
    },
};
