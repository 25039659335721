import Model from '@glu/core/src/model';

export const PortalInterfaceModel = Model.extend({
    send(dataParam) {
        const data = dataParam;
        try {
            /*
             * copied from webseries documentation
             * https://confluence.us-bottomline.root.bottomline.com/display/WS/JavaScript+API+for+Portals
             * var targetOrigin = window.location.protocol + '/' + '/' + window.location.host;
             */

            // removing leading forward slash that some contexts are adding
            if (data.title && data.title.charAt(0) === '/') {
                data.title = data.title.slice(1);
            }

            window.parent.postMessage(data, '*');
        } catch (err) {
            // don't do anything right now
        }
    },
});

export default new PortalInterfaceModel();
