import Controller from '@glu/core/src/controller';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';

const BankInquiryUtil = Controller.extend({
    constructor(...args) {
        const [request] = args;
        Controller.prototype.constructor.apply(this, args);
        this.request = request || this.createDefaultRequest();
        this.handleSuccess = util.bind(this.handleSuccess, this);
        this.handleError = util.bind(this.handleError, this);
    },

    createDefaultRequest() {
        return {
            requestHeader: {
                queryOffset: 0,
                queryPagesize: 0,
                requestId: '',
            },

            IncludeMapData: 1,

            queryCriteria: {
                action: {
                    typeCode: '',
                    entryMethod: 0,
                    actionMode: false,
                    productCode: false,
                    functionCode: false,
                },

                fieldName: false,
                inquiryId: false,
                queryType: 'Query',
            },

            formatterType: 'Query',
        };
    },

    setTypeCode(code) {
        this.request.queryCriteria.action.typeCode = code;
        return this;
    },

    setActionMode(mode) {
        this.request.queryCriteria.action.actionMode = mode;
        return this;
    },

    setProductCode(code) {
        this.request.queryCriteria.action.productCode = code;
        return this;
    },

    setFunctionCode(code) {
        this.request.queryCriteria.action.functionCode = code;
        return this;
    },

    setFieldName(name) {
        this.request.queryCriteria.fieldName = name;
        return this;
    },

    setInquiryId(id) {
        this.request.queryCriteria.inquiryId = id;
        return this;
    },

    getRequest() {
        this.validateRequest();
        return this.request;
    },

    setCustomFilters(customFilters) {
        this.request.queryCriteria.customFilters = customFilters;
        return this;
    },

    validateRequest() {
        const required = [
            this.request.queryCriteria.action.typeCode,
            this.request.queryCriteria.action.actionMode,
        ];

        for (let i = 0, len = required.length; i < len; i += 1) {
            if (!required[i]) {
                throw new Error(this.err);
            }
        }
    },

    send() {
        this.validateRequest();
        return http.post(
            services.inquiryQueryResults,
            this.request,
            this.handleSuccess,
            this.handleError,
        );
    },

    handleSuccess(response) {
        this.trigger('success', response.queryResponse.QueryData.queryRows);
    },

    handleError() {

    },
});

export default BankInquiryUtil;
