var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n        <h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.transactionDetailsForNote",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":12},"end":{"line":2,"column":54}}}))
    + " <span class=\"note\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"getNoteNumber") || (depth0 != null ? lookupProperty(depth0,"getNoteNumber") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"getNoteNumber","hash":{},"data":data,"loc":{"start":{"line":2,"column":74},"end":{"line":2,"column":91}}}) : helper)))
    + "</span></h3>\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        <div class=\"grid-region tiered-loan-subaccount-transactionGrid\" data-region=\"transactionsGrid\" aria-live=\"polite\"></div>\n</div>\n";
},"useData":true});