import locale from '@glu/locale';
import template from './panel.hbs';

let panelIdAssign = 0;

export default {
    panel(...args) {
        const options = args[args.length - 1];
        const hash = options.hash || {};

        if (hash.locale) {
            hash.label = locale.get(hash.locale);
        }

        if (hash.collapsed === 'false') {
            hash.collapsed = false;
        }

        if (!hash.theme) {
            hash.theme = 'panel-primary';
        }

        if (!hash.name) {
            hash.name = 'panel-name';
        }

        const panelId = panelIdAssign;
        panelIdAssign += 1;

        return template({
            panelId,
            name: hash.name,
            label: hash.label,
            isExpanded: !hash.collapsed,
            themeClass: hash.theme,
            body: options.fn(this),
        });
    },
};
