import util from '@glu/core/src/util';

export default function (form, initialState) {
    const paymentFromTemplateProtectedFields = ['ID', 'COMPDESCDATE', 'BATCHCOMMENT', 'DESTCOUNTRYCODE', 'DESTCURRENCYCODE'];

    const protectFields = function (array) {
        util.each(array, (fieldName) => {
            form.field(fieldName).shouldBeReadOnly(true);
        });
    };

    if (initialState) {
        if (form.formView.model.context.entryMethod
            && form.formView.model.context.entryMethod === 1
            && (form.formView.model.jsonData.typeInfo.functionCode !== 'TMPL'
                || form.formView.model.jsonData.typeInfo.typeCode === 'BDACHIAT')) {
            protectFields(paymentFromTemplateProtectedFields);
            form.field('ORIGABA_LOOKUP').$el.prop('disabled', true);
        }
    }
}
