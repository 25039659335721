import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import alert from '@glu/alerts';
import errorContainerTmpl from './errorContainer.hbs';
import errorNoContainerTmpl from './errorNoContainer.hbs';

export default Layout.extend({
    template: errorContainerTmpl,
    noContainerTemplate: errorNoContainerTmpl,

    initialize() {
        this.setNotificationData({
            title: 'errorMessage',
        });
    },

    defaultMessages: {
        loadingApp: `The ${window.Bottomline.appTitle || ''} application did not load successfully. Please try again in a few minutes. If you continue to receive this message please contact your financial institution.`,
        loadingPage: 'The system was unable to load this page successfully. Please try again. If you continue to receive this error, please contact your system administrator.',
        loadingModal: 'The system was unable to load the information successfully. Please close the window and try again. If you continue to receive this error, please contact your system administrator.',
        intraPage: 'Specific information on this page was not able to update successfully. Please refresh the page. If you continue to receive this error, please contact your system administrator.',
        notAuthorized: 'The system was unable to load this page successfully because of insufficient entitlements. Please contact your system administrator.',
        dataAccessNotAuthorized: 'The system was unable to load this page successfully because you are not authorized to access the requested data. Please try again. If you continue to receive this error, please contact your system administrator.',
        noDataFoundForOperation: 'The system was unable to process the request as the required data for the operation was not found. Please try again. If you continue to receive this error, please contact your administrator.',
        timeout: 'The system was unable to retrieve the information requested. Please narrow your search and try again. If you continue to receive this error, please contact your administrator.',
        proxyerror: 'The system was unable to retrieve the information requested. Please narrow your search and try again. If you continue to receive this error, please contact your administrator.',
    },

    messages: {
        loadingApp: 'system.unable.load.app',
        loadingPage: 'system.unable.load.page',
        loadingModal: 'system.unable.load.modal',
        intraPage: 'system.unable.load.intraPage',
        notAuthorized: 'system.not.authorized',
        dataAccessNotAuthorized: 'system.dataAccess.not.authorized',
        noDataFoundForOperation: 'system.noDataFound.forOperation',
        timeout: 'system.timeout',
        proxyerror: 'system.proxyerror',
    },

    regions: {
        alertRegion: '[data-region="alertRegion"]',
    },

    getTemplate() {
        if (this.options.noContainer) {
            return this.noContainerTemplate;
        }
        return this.template;
    },

    onRender() {
        let message = locale.get(this.messages[this.options.key], window.Bottomline.appTitle);
        if (message.indexOf(this.messages[this.options.key]) >= 0) {
            message = this.defaultMessages[this.options.key];
        }
        this.alertView = alert.danger(message);
        this.alertRegion.show(this.alertView);
        this.notifyPageLoaded();
    },
});
