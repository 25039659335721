var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"workflowPageHeader"),depth0,{"name":"workflowPageHeader","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<section class=\"section section-container\">\n        <div class=\"section-body\">\n        <div data-region=\"alertRegion\"></div>\n                <div class=\"panel--steps\" data-region=\"stepLayoutRegion\"></div>\n                <button type=\"button\"\n                class=\"btn btn-primary\"\n                data-hook=\"getNext\">\n            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.continue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":9,"column":37}}}))
    + "\n        </button>\n        <button type=\"button\"\n                class=\"btn btn-primary hide\"\n                data-hook=\"getSave\">\n            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":14,"column":36}}}))
    + "\n        </button>\n                <button type=\"button\"\n                class=\"btn btn-secondary hide\"\n                data-hook=\"getPrev\">\n            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.back",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":19,"column":33}}}))
    + "\n        </button>\n                <button type=\"button\"\n                class=\"btn btn-secondary\"\n                data-hook=\"getCancel\">\n            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":24,"column":38}}}))
    + "\n        </button>\n        </div>\n</section>\n";
},"usePartial":true,"useData":true});