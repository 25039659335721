export default ({ palette }) => ({
  content: {
    lineHeight: '22px',
    fontSize: 12,
    fontWeight: 'bold',
    backgroundColor: {
      defaultBackgroundColor: palette.background,
      positiveBackgroundColor: palette.colors ? palette.colors.tundora : palette.grey.G800,
      negativeBackgroundColor: palette.grey.G200
    },
    height: {
      toggleHeight: 22,
      pointerHeight: 20
    },
    color: {
      positiveColor: palette.text.light,
      negativeColor: palette.text.dark
    }
  },
  switchWrap: {
    minWidth: 63,
    borderRadius: 30,
    border: `0 solid ${palette.grey.A100}`
  },
  value: {
    textTransform: 'uppercase'
  },
  positive: {
    paddingRight: 23,
    paddingLeft: 5
  },
  negative: {
    paddingRight: 5,
    paddingLeft: 23
  },
  pointer: {
    borderRadius: 20,
    height: 20,
    width: 20
  },
  pointerShift: 'calc(100% - 21px)',
  focusColor: palette.focus.normal
});
