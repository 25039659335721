var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <a href=\"#\" class=\"btn-select-all\"><span class=\"icon-add-circle\"></span> <span class=\"select-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.select.all",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":103},"end":{"line":2,"column":138}}}))
    + "</span></a>\n\n    <div class=\"listing-region\" data-region=\"listing\">\n    </div>\n\n</div>\n\n<button type=\"button\" class=\"btn btn-primary col-xs-12 col-md-3 \">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.payment.schedule.plural",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":66},"end":{"line":10,"column":114}}}))
    + "</button>\n";
},"useData":true});