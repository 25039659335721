var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-secondary download\" data-action=\"bulkDownload\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.download",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":100},"end":{"line":10,"column":128}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"section section-container\">\n    <div class=\"section-header\">\n        <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.eStatement.result",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":46}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"statementType") || (depth0 != null ? lookupProperty(depth0,"statementType") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"statementType","hash":{},"data":data,"loc":{"start":{"line":3,"column":47},"end":{"line":3,"column":66}}}) : helper)))
    + "</h2>\n    </div>\n    <div class=\"section-body\">\n        <div class=\"btn-wrapper\"></div>\n                <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n                <div class=\"grid-region\" data-region=\"gridRegion\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"bulkDownloadEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":11,"column":23}}})) != null ? stack1 : "")
    + "    </div>\n    <a id=\"estatement-pdf\" class=\"hide\"></a>\n</div>\n";
},"useData":true});