import React from 'react';
import locale from '@glu/locale';
import StepUserWrapper from 'pcm/common/components/StepUserWrapper';
import ContentLoading from 'pcm/common/components/ContentLoading';

const CheckPrintAdministration = React.lazy(() => import('./check-print-administration/CheckPrintAdministration'));

export default {
    checkPrintAdm() {
        this.showPage(
            locale.get('types.CFDCLS'),
            <StepUserWrapper callback={(userGroup) => {
                this.checkPrintAdmWithUser(userGroup);
            }}
            />, {
                headlineText: locale.get('types.CFDCLS'),
            },
        );
    },

    checkPrintAdmWithUser() {
        this.showPage(
            locale.get('types.CFDCLS'),
            <React.Suspense fallback={<ContentLoading />}>
                <CheckPrintAdministration />
            </React.Suspense>,
            {
                headlineText: locale.get('types.CFDCLS'),
            },
        );
    },
};
