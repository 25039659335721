var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = ((helper = (helper = lookupProperty(helpers,"spinner") || (depth0 != null ? lookupProperty(depth0,"spinner") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"spinner","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":13}}}) : helper))) != null ? stack1 : "")
    + "\n<div class=\"grid-content\" data-hook=\"gridContent\"></div>\n<div class=\"page-content\">\n        <div>\n                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"buttonList") || (depth0 != null ? lookupProperty(depth0,"buttonList") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"buttonList","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":32}}}) : helper))) != null ? stack1 : "")
    + "\n        </div>\n</div>\n\n";
},"useData":true});