var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"section section-container\">\n    <div class=\"alert-message\">\n        <div data-region=\"alertRegion\" role=\"alert\"></div>\n    </div>\n</section>\n<form class=\"ScheduledExports-add\">\n    <fieldset class=\"section section-container\">\n        <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Export.Detail",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":46}}}))
    + "</legend>\n        <div class=\"row\">\n            <div class=\"col-md-3 required\">\n                <div class=\"field-container\">\n                    <div data-region=\"exportTypeRegion\"></div>\n                    <span class=\"help-block\"\n                          data-bind=\"model\"\n                          data-validate=\"EXPORTTYPECODE\"></span>\n                </div>\n            </div>\n            <div class=\"col-md-3 required\">\n                <div class=\"field-container\">\n                    <label for=\"FILENAME\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.File.Name",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":42},"end":{"line":20,"column":68}}}))
    + "</label>\n                    <input class=\"form-control\" type=\"text\" name=\"FILENAME\" id=\"FILENAME\" data-bind=\"model\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"FILENAME\"></span>\n                </div>\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"col-md-3 hide\" data-hook=\"getOutputContent\">\n                <div class=\"field-container outputContents\">\n                    <label class=\"required\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.ExportType.OutputContent",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":44},"end":{"line":29,"column":85}}}))
    + "</label>\n                    <div data-region=\"outputContentOptionsRegion\"></div>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"OUTPUTCONTENT\"></span>\n                </div>\n            </div>\n\n            <div class=\"col-md-3 hide\" data-hook=\"getExcludeHeader\">\n                <div class=\"field-container excludeHeaders\"> \n                    <div class=\"checkbox\">\n                        <label for=\"EXCLUDEHEADER\">\n                            <input type=\"checkbox\" id=\"EXCLUDEHEADER\" name=\"EXCLUDEHEADER\" data-bind=\"model\">\n                            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Export.ExcludeHeader",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":40,"column":28},"end":{"line":40,"column":65}}}))
    + "\n                        </label>\n                        <span class=\"help-block\" data-validate=\"EXCLUDEHEADER\"></span>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div data-region=\"currentPriorDay\"></div>\n        <div class=\"row\">\n            <div class=\"col-md-3 hide\" data-hook=\"getContinuationAccountSummaryRecord\">\n                <div class=\"field-container\"> \n                    <div class=\"checkbox\">\n                        <label for=\"SPLIT88RECORD\">\n                            <input type=\"checkbox\" id=\"SPLIT88RECORD\" name=\"SPLIT88RECORD\" data-bind=\"model\">\n                            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Export.Separate.Transaction.Details",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":54,"column":28},"end":{"line":54,"column":80}}}))
    + "\n                        </label>\n                        <span class=\"help-block\" data-validate=\"SPLIT88RECORD\"></span>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class=\"MultiField-region row\" data-region=\"multifieldRegion\"></div>\n    </fieldset>\n    <fieldset class=\"section section-container\">\n        <legend >"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Report.Criteria",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":64,"column":17},"end":{"line":64,"column":49}}}))
    + "</legend>\n        <div class=\"ScheduledExports-bai-filters\" data-region=\"advancedFilterRegion\"></div>\n    </fieldset>\n</form>\n";
},"useData":true});