import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import constants from 'app/administration/constants';
import CollectionView from '@glu/core/src/collectionView';
import util from '@glu/core/src/util';
import AssignedPermissions from './assignedPermissions';
import GroupListView from './paymentType/paymentGroup';
import paymentLayoutTmpl from './paymentLayout.hbs';
import { onChildrenChange } from '../helpers';

export default Layout.extend({
    template: paymentLayoutTmpl,

    ui: {
        $paymentPanel: '.panel-payment',
        $paymentPanelTitle: '.panel-payment .panel-title',
        $permsPanel: '.perms-panel .panel-collapse',
        $templatesPanel: '.templates-panel',
        $selectAllPaymentTypes: '[data-hook="type-selectAllPaymentTypes"]',
    },

    events: {
        'click @ui.$selectAllPaymentTypes': 'selectAllPaymentTypes',
    },

    initialize(options) {
        this.mode = options.mode || 'insert';
        this.model = options.model;
        this.mainModel = options.mainModel;
        this.readOnly = this.mode === constants.MODES.VIEW;
        this.boundUpdateSelectAllState = this.updateSelectAllState.bind(this);
    },

    onRender() {
        const self = this;

        this.buildPaymentTypesRegion();
        this.ui.$templatesPanel.hide();
        if (!this.readOnly) {
            this.listenTo(this.typesCollection, 'types-changed', function () {
                this.updatePaymentTypesTotal(this.countPaymentTypes());

                if (!this.typesChanged) {
                    this.typesChanged = true;

                    this.ui.$permsPanel.collapse('hide');

                    this.ui.$permsPanel.one('show.bs.collapse', () => {
                        self.assignedPermissionsView.render();
                        self.typesChanged = false;
                    });
                }
            });
        }

        this.buildAssignedPermissionsRegion();
        this.updateSelectAllState();
        this.onChildrenChange();
    },

    updateSelectAllState() {
        const allChecked = this.paymentTypesRegion?.$el.find('.paymentType-block input[type="checkbox"]').not(':checked').length === 0;
        this.ui.$selectAllPaymentTypes.prop('checked', allChecked);
    },

    onChildrenChange() {
        const checkboxList = this.paymentTypesRegion?.$el.find('input[type="checkbox"]');
        onChildrenChange(this, checkboxList);
    },

    buildReadOnlyCollection(groups) {
        return groups.chain()
            .filter(group => group.isEntitled())
            .reduce((obj, group) => {
                const models = util.isEmpty(obj) ? [] : obj;
                const types = group.get('types');

                const unEntitled = types.reject(type => type.get('entitled'));

                types.remove(unEntitled);
                models.push(group);
                return models;
            }, {})
            .value();
    },

    buildPaymentTypesRegion() {
        const groups = this.model.get('groups');
        if (this.readOnly) {
            groups.reset(this.buildReadOnlyCollection(groups));
        }

        this.typesCollection = groups;

        this.addPaymentTotalContainer();

        this.paymentTypesView = new CollectionView({
            collection: this.typesCollection,
            itemView: GroupListView,

            itemViewOptions: {
                readOnly: this.readOnly,
            },
        });

        this.paymentTypesRegion.show(this.paymentTypesView);
        this.updatePaymentTypesTotal(this.countPaymentTypes());
    },

    buildAssignedPermissionsRegion() {
        const self = this;

        this.assignedPermissionsView = new AssignedPermissions({
            model: this.model,
            mode: this.mode,
            readOnly: this.readOnly,
        });

        this.listenTo(this.assignedPermissionsView, 'payments:rerender', () => {
            self.render();
        });

        this.assignedPermissionsRegion.show(self.assignedPermissionsView);
    },

    /**
     * Appends the container to the DOM for displaying total amount of selected
     * payment types
     * @return nothing
     */
    addPaymentTotalContainer() {
        this.ui.$paymentPanelTitle.addClass('panel-title--has-right-content');
        this.ui.$paymentPanelTitle.append(`<span class="panel-title-right"><span class="payment-types-total" data-hook="payment-types-total"></span> ${locale.get('uce.payment.types.selected')}</span>`);
    },

    /**
     * Counts the total selected payment types
     * @return {total: number}
     */
    countPaymentTypes() {
        let checkboxCount = {};
        const self = this;

        if (this.readOnly) {
            return this.$('label[for^="typeChk"]').length;
        }

        checkboxCount = util.countBy(this.ui.$paymentPanel.find('[data-hook="type-checkbox"]'), checkbox => self.$(checkbox).prop('checked') === true);

        return !checkboxCount.true ? 0 : checkboxCount.true;
    },

    /**
     * Updates the displayed total amount of payment types
     * @param  {Number} amount [total selected payment types]
     * @return nothing
     */
    updatePaymentTypesTotal(amount) {
        const $paymentTotal = this.ui.$paymentPanel.find('[data-hook="payment-types-total"]');

        $paymentTotal.empty().append(amount);
    },

    /**
     * Toggles selection of all payment types
     * @param  {Event} evt [jQuery mouse event]
     * @return nothing
     */

    selectAllPaymentTypes(evt) {
        const self = this;
        const { checked } = evt.currentTarget;
        const $selectAllCheckboxes = self.ui.$paymentPanel.find('[data-hook="type-selectAll"]');
        const $individualTypeCheckboxes = self.ui.$paymentPanel.find('[data-hook="type-checkbox"]');

        $selectAllCheckboxes.prop('checked', checked);
        $individualTypeCheckboxes.prop('checked', checked);

        this.model.get('groups').each((group) => {
            group.get('types').each((type) => {
                type.set('entitled', checked);
            });
        });

        this.updatePaymentTypesTotal(this.countPaymentTypes());
        this.typesCollection.trigger('types-changed');
        this.onChildrenChange();
    },

    templateHelpers() {
        const self = this;
        return {
            isInsertMode: this.mode === 'insert',

            hasSelection() {
                return self.model.hasSelectedGroup();
            },

            noSelection() {
                return !self.model.hasSelectedGroup();
            },

            readOnly: this.readOnly,
        };
    },
});
