/* istanbul ignore file */
export default (typography, palette) => ({
  backgroundColor: palette.background,
  backgroundColorHover: palette.grey.G200,
  border: `${palette.accent} 1px solid`,
  btnTextColor: palette.primary.normal,
  buttonReset: {
    background: 'none',
    border: 'none',
    color: 'inherit',
    cursor: 'pointer',
    font: 'inherit',
    padding: 0
  },
  countLabelColor: palette.grey.G600,
  errorIconColor: palette.error,
  fontWeightHeavy: typography.fontWeightHeavy,
  scrollbarBackground: palette.primary.darkBackground,
  scrollbarColor: '#222a3a',
  textDark: palette.text.dark,
  textGrey: palette.grey.G500,
  textLight: palette.text.light,
  textMid: palette.text.midGray,
  textStyles: typography.text,
  whiteBackgroundColor: '#fff'
});
