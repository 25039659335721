import Layout from '@glu/core/src/layout';
import services from 'services';
import http from '@glu/core/src/http';
import transform from 'common/util/transform';
import systemConfig from 'system/configuration';
import constants from 'app/administration/views/userMaintenance2/accounts/constants';
import adminConstants from 'app/administration/constants';
import proxyTabSetup from 'app/administration/views/userMaintenance2/accounts/proxyTabSetup';
import gridDataAccess from 'app/administration/views/userMaintenance2/accounts/gridDataActions';
import loadingWidgetTmpl from 'common/templates/loadingWidget.hbs';
import LocationLoginView from './locationLoginView';
import locationLoginTabTmpl from './locationLoginTab.hbs';

export default Layout.extend({
    loadingTemplate: loadingWidgetTmpl,
    template: locationLoginTabTmpl,

    ui: {
        $addBtn: '[data-hook="getAddItemBtn"]',
    },

    initialize(options) {
        this.model = options.model;
        this.mode = options.mode;
        this.serviceURL = services.generateUrl(`/users/userCentric/v2/getDataEntitlements${(this.options.copyingUser ? 'Live' : '')}`);

        // Access from the tab
        this.proxyData = options.proxyData;

        // The typecode/product map so product code can be applied when by type.
        this.typeProductMap = options.typeProductMap;

        // Add proxy functions to this object.
        proxyTabSetup(this, true);
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadViewRequirements()
                .then((data) => {
                    const locationData = this.parseEntitlementData(data);
                    this.locationCollectionView = new LocationLoginView({
                        data: locationData,
                        mode: this.mode,
                        collection: this.cachedCollection,
                        columnTypeData: this.columnTypeData,
                        proxySave: this.updateColDataRow,
                    });
                    this.setHasLoadedRequiredData(true);
                    this.render();
                });
        } else {
            this.locationLoginCollectionRegion.show(this.locationCollectionView);
        }
    },

    onClose() {
        // refetch data when we revisit tab
        this.setHasLoadedRequiredData(false);
    },

    /**
     * Determine if there are any entitlements for this tab.
     */
    shouldShow() {
        return (gridDataAccess.getAllEnabledEntitlements(this.getRecords()).length !== 0
            && (systemConfig.isAdmin() || this.mode === adminConstants.MODES.VIEW)
        );
    },

    getRecords() {
        return gridDataAccess.locationRecords(this.model);
    },

    /**
     * Return the location data entitlement attribute
     * @return {string}
     */
    getEntityName() {
        return constants.ENTITY_NAME.LOCATION;
    },

    loadViewRequirements() {
        const locationRecords = this.getRecords();
        const entitlements = gridDataAccess.getAllEnabledEntitlements(locationRecords);
        const entitlementData = gridDataAccess.prepEntitlementsForRequest(entitlements, true);
        const itemData = gridDataAccess
            .prepInquiryForRequest(constants.REQUEST_ENTITY.LOCATION)
            .concat(entitlementData);

        const requestData = {
            startRow: 0,
            rowsPerPage: constants.DEFAULT_UCE2_GRID_SIZE,
            dataOnly: 0,
            searchFields: [{
                fieldName: 'Usergroup',
                fieldValue: [
                    this.model.userModel.get('USERGROUP'),
                ],
                dataType: 'text',
                operator: '=',
            }, {
                fieldName: 'UserID',
                fieldValue: [
                    this.model.userModel.get('COPYEXISTINGUSER') || this.model.userModel.get('USERID'),
                ],
                dataType: 'text',
                operator: '=',
            }, {
                fieldName: 'actionMode',
                fieldValue: [
                    (this.model.userModel.get('COPYEXISTINGUSER')) ? adminConstants.MODES.VIEW : this.mode,
                ],
                dataType: 'text',
                operator: '=',
            }],
            requestParameters: {
                item: itemData,
            },
        };

        // set the column type data for proxy uses
        this.columnTypeData = locationRecords;

        return http.post(this.serviceURL, requestData);
    },

    parseEntitlementData(data) {
        return data.rows.map(row => transform.pairsToHashUnescape(row.columns, 'fieldName', 'fieldValue'));
    },

    /**
     * @return {array} data for all changes in tab
     * Callback called when moving out of step 3 assingment views,
     * will save all the changes made as proxy data to track user entitlements
     */
    extractData() {
        if (this.locationCollectionView) {
            this.locationCollectionView.extractData();
            this.cachedCollection = this.locationCollectionView.collection;
        }
    },
});
