import { appBus, util } from '@glu/core';
import PropTypes from 'prop-types';
import React, { useState, useLayoutEffect } from 'react';
import ViewWrapper from 'react-wrappers/ViewWrapper';
import configuration from 'system/configuration';
import PtxPageTitle from 'ptx/system/views/layoutManager/pageTitle';
import { isDeepLinked } from '../../common/util/deeplinkUtil';

const isPtxPage = () => configuration.isPtxConnectRoute();

const Page = (props) => {
    const [page, setPage] = useState(null);

    useLayoutEffect(() => {
        /**
         * Main showPage method for showing a component or view
         * @param {Object} viewOrComponent
         * @param {string} title
         */
        function showPage(viewOrComponent, title, options) {
            if (!viewOrComponent) {
                return;
            }
            if (viewOrComponent === 'error') {
                setPage(null);
                return;
            }
            const ptxPage = isPtxPage();

            document.title = props.formatDocumentTitle(title);

            let InnerPage;

            if (typeof viewOrComponent === 'function') {
                InnerPage = viewOrComponent();
            } else {
                InnerPage = viewOrComponent;
            }

            if (viewOrComponent.cid) {
                InnerPage = <ViewWrapper view={viewOrComponent} />;
            }

            if (ptxPage) {
                const { pageTitle: pageTitleView } = options || {};

                let ptxPageTitleView;
                if (options && !isDeepLinked()) {
                    ptxPageTitleView = new PtxPageTitle({
                        model: viewOrComponent.model,
                        config: options,
                        title,
                    });
                }


                InnerPage = (
                    <div className="connect-container layout-manager page-content ptx-page">
                        {pageTitleView
                            ? <ViewWrapper view={pageTitleView} className="nested-ptx-page" />
                            : ptxPageTitleView && <ViewWrapper view={ptxPageTitleView} className="main-ptx-page" />}
                        {InnerPage}
                    </div>
                );
            }

            setPage(InnerPage);
        }

        appBus.on('show:page', showPage);
        return () => {
            appBus.off('show:page', showPage);
        };
    }, [props]);

    return !page ? null : page;
};

Page.propTypes = {
    formatDocumentTitle: PropTypes.func,
};
Page.defaultProps = {
    formatDocumentTitle: title => (title ? util.escape(title) : ''),
};

export default Page;
