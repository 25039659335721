import { Layout } from '@glu/core';
import tmpl from './printerDetails.hbs';
import OffsetInput from './offsetInput';

export default Layout.extend({
    template: tmpl,
    className: 'row',

    modelEvents: {
        sync: 'render',
    },

    regions: {
        pageOffsetX: '#region-page-offset-x',
        pageOffsetY: '#region-page-offset-y',
    },

    onRender() {
        this.showPageOffsetX();
        this.showPageOffsetY();
    },

    showPageOffsetX() {
        this.pageOffsetX.show(new OffsetInput({
            propertyName: 'pageOffsetX',
            model: this.model,
        }));
    },

    showPageOffsetY() {
        this.pageOffsetY.show(new OffsetInput({
            propertyName: 'pageOffsetY',
            model: this.model,
        }));
    },
});
