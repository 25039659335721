import PageView from 'app/administration/common/dynamicPages/views/mdf/metaDrivenForm';
import constants from 'common/dynamicPages/api/constants';
import util from '@glu/core/src/util';
import viewHelper from 'common/dynamicPages/api/viewHelper';

const compareFieldUIOrder = (a, b) => {
    if (a.fieldUIOrder < b.fieldUIOrder) {
        return -1;
    }
    if (a.fieldUIOrder > b.fieldUIOrder) {
        return 1;
    }
    return 0;
};

export default {
    page: {
        createViewRules(model) {
            const fieldList = util.clone(model.jsonData.fieldInfoList);
            const ruleData = util.filter(fieldList, (item) => {
                const itemParam = item;
                // reset the blockClasses so we don't double them up on "multi-add workflows"
                itemParam.blockClass = '';

                if (itemParam.fieldUIType === 'GRID') {
                    itemParam.visible = true;
                }

                if (itemParam.visible) {
                    if (itemParam.info && !itemParam.mandatory) {
                        itemParam.infoClass = 'textline-field';
                    }

                    // set cssClass property for item
                    itemParam.cssClass = this.getVisibleItemCSSClass(itemParam);

                    // set blockClass property for item
                    itemParam.blockClass = this.getVisibleItemBlockClass(itemParam);

                    return itemParam;
                }
                return undefined;
            });
            ruleData.sort(compareFieldUIOrder);
            return ruleData;
        },

        /**
         * Conditionally return a blockClass property for a visible item in the fieldlist
         * based on its field properties
         * @param {object} item
         * @return {string} blockClass
         */
        getVisibleItemBlockClass(item) {
            const size = Number(item.size);

            if (item.fieldUIType && (util.contains(['TEXTLINE', 'RADIO', 'CHECKBOX'], item.fieldUIType)
                    || item.fieldUIType.indexOf('FILTER'))) {
                return 'field-container-lg';
            }

            // if not of a fieldUIType above, check by size
            if (size < constants.SMALLTEXT) {
                return 'field-container-xs';
            }
            if (size < constants.MEDIUMTEXT) {
                return 'field-container-sm';
            }
            if (size < constants.LARGETEXT) {
                return 'field-container-md';
            }

            return 'field-container-lg';
        },

        /**
         * Conditionally return the cssClass property for a visible item in the fieldlist based
         * on its field properties
         * @param {object} item
         * @return {string} cssClass
         */
        getVisibleItemCSSClass(item) {
            let cssClass = ['form-control'];

            switch (item.fieldUIType) {
            // is Combo
            case 'COMBOBOX':
            case 'COMBOSELECT':
            case 'COMBOFILTER':
                cssClass.push('form-select');
                break;
            case 'TEXTLINE':
                cssClass = ['textline-field'];
                break;
            case 'TEXTAREA':
                cssClass.push('textarea-field');
                break;
            default:
                break;
            }

            if (item.fieldType === 'AMOUNT' && item.relatedProperty) {
                cssClass.push('amount-field');
            }

            return cssClass.join(' ');
        },

        createViewRowGroups(model, fields) {
            let containers = null;
            let allGroups;
            const maxColumns = 12;
            // const viewHelper = viewHelper;

            // deep clone
            if (model.jsonData.fieldContainerList
                && !util.isEmpty(model.jsonData.fieldContainerList)) {
                containers = JSON.parse(JSON.stringify(model.jsonData.fieldContainerList));
            } else {
                // nothing to do
                return null;
            }

            // get all the groups
            allGroups = util.map(containers, container => container.groups);

            allGroups = util.flatten(allGroups);

            // assign fields to groups
            util.each(fields, (field) => {
                let group;
                const addToGroup = () => {
                    if (!group.fields) {
                        group.fields = [];
                    }
                    group.fields.push(field);
                };
                if (field.fieldGroup) {
                    // get group
                    group = util.findWhere(allGroups, {
                        fieldGroupName: field.fieldGroup,
                    });
                    addToGroup();
                }
            });

            // assign classes for each
            util.each(containers, (container) => {
                const containerParam = container;
                // Sometimes, numColumns is set to 0 which results in NaN.
                containerParam.numColumns = containerParam.numColumns > 0
                    ? containerParam.numColumns : 1;

                // Column suffix is the Bootstrap "colspan".
                const columnSuffix = parseInt(maxColumns / containerParam.numColumns, 10);
                containerParam.classes = 'section';
                containerParam.labelClasses = '';

                viewHelper.properlyNestSubgroups(containerParam);
                viewHelper.columnCountPerRow(containerParam);
                viewHelper.assignColumnClasses(containerParam, columnSuffix);
            });

            return containers;
        },

        get(options) {
            return new PageView(options);
        },
    },

    convertPhoneNumbersToNumber(phoneNumber) {
        let ret = phoneNumber;
        if (ret) {
            ret = ret.replace(/\(/g, '');
            ret = ret.replace(/\)/g, '');
            ret = ret.replace(/-/g, '');
            ret = ret.replace(/\s/g, '');
        }
        return ret;
    },
};
