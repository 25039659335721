import locale from '@glu/locale';
import Layout from '@glu/core/src/layout';
import template from './noteForm.hbs';
import NoteModel from '../models/noteModel';
import CharsRemainingInput from './charactersRemainingInput';
import constants from '../constants';

const NoteFormView = Layout.extend({
    template,

    ui: {
        $addNote: '[data-hook="getAddNote"]',
        $buttonContainer: '[data-hook="getButtonContainer"]',
        $formContainer: '[data-hook="getFormContainer"]',
    },

    events: {
        'click [data-hook="getAddNew"]': 'toggleForm',
        'click @ui.$addNote': 'addNote',
        'click [data-hook="getCancelNote"]': 'toggleForm',
    },

    initialize() {
        this.model = new NoteModel();
    },

    onRender() {
        this.noteInputRegion.show(new CharsRemainingInput({
            model: this.model,
            label: locale.get('serviceRequest.Note'),
            inputType: constants.type.textArea,
            name: 'note',
            maxLength: 300,
        }));
    },

    /**
     * When model is valid, save the model to the server
     */
    addNote() {
        if (this.model.isValid()) {
            this.ui.$addNote.attr('aria-busy', true);
            this.model.save({}, {
                tnum: this.options.tnum,
            }).then(this.handleAddSuccess.bind(this))
                .catch(this.handleAddFailure.bind(this));
        }
    },

    /**
     * Clear the note attribute of the model, toggle the form, and trigger the
     * note:added event
     */
    handleAddSuccess() {
        this.model.set('note', '');
        this.ui.$addNote.attr('aria-busy', false);
        this.toggleForm();
        this.trigger('note:added');
    },

    /**
     * Trigger the note:failure event
     * @param response
     */
    handleAddFailure(response) {
        this.ui.$addNote.attr('aria-busy', false);
        this.trigger('note:failure', response);
    },

    /**
     * Toggle the form and the button
     */
    toggleForm() {
        this.ui.$buttonContainer.toggleClass('hidden');
        this.ui.$formContainer.toggleClass('hidden');
    },

    /**
     * When view options has a collection, add the model at the top and return
     * the collection,
     * otherwise return the model
     * @param {Object} model
     * @returns {Model|Collection}
     */
    addToCollection(model) {
        if (this.options.collection) {
            this.options.collection.add(model, { at: 0 });
            return this.options.collection;
        }
        return model;
    },
});

export default NoteFormView;
