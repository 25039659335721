/* istanbul ignore file */
export default ({ palette, typography }) => ({
  actionColor: '#00c0f0',
  backgroundColor: '#231f20',
  borderColor: palette.info,
  borderColorError: palette.error,
  font: typography.fontFamily,
  messageColor: palette.background,
  placeholderBackground: '#454343'
});
