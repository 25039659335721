import util from '@glu/core/src/util';
import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import mobileUtil from 'mobile/util/mobileUtil';
import $ from 'jquery';
import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import alert from '@glu/alerts';
import store from 'system/utilities/cache';
import services from 'services';
import validatorPatterns from 'system/validatorPatterns';
import constants from 'common/dynamicPages/api/constants';
import { ActionsGroupView } from 'components/ActionsGroup/ActionsGroup';
import getPaymentIntiatorGroupList from './paymentIntiatorGroupList';
import achModalViewTmpl from './controlTotalView.hbs';

export default Layout.extend({
    template: achModalViewTmpl,
    modalClass: 'modal-xlg',
    ui: {
        $pigId: '[data-hook="payment_group_id"]',
        $save: '[data-hook="save"]',
        $amountInput: '[data-type="amount"]',
    },
    events: {
        'click @ui.$save': 'createPaymentInitiatorGroup',
    },
    initialize(options) {
        this.isMobile = mobileUtil.isMobileScreen();
        this.modelButtons = options.model?.buttons.filter(button => button.action !== 'SELECT');
        this.model = options.model || new Model();
        switch (options.action) {
        case 'modify':
            this.creditAmount = this.model.get('CREDIT_AMOUNT');
            this.debitAmount = this.model.get('DEBIT_AMOUNT');
            this.model.set('CREDIT_AMOUNT', this.model.get('CREDIT_AMOUNT').replace(/\s/g, ''));
            this.model.set('DEBIT_AMOUNT', this.model.get('DEBIT_AMOUNT').replace(/\s/g, ''));
            this.model.addValidator(
                'CREDIT_AMOUNT',
                {
                    description: locale.get('ACH.CreditAmount'),
                    exists: true,
                    matches: validatorPatterns.PAMOUNT_PATTERN,
                },
            );
            this.model.addValidator(
                'DEBIT_AMOUNT',
                {
                    description: locale.get('ACH.DebitAmount'),
                    exists: true,
                    matches: validatorPatterns.PAMOUNT_PATTERN,
                },
            );
            this.dialogTitle = locale.get('RISK.modifycontrol');
            break;
        case 'select':
            this.dialogTitle = locale.get('RISK.viewcontrol');
            break;
        case 'INSERT':
        default:
            this.model.addValidator(
                'payment_group_id',
                {
                    description: locale.get('risk.pigid.id'),
                    exists: true,
                },
            );
            this.model.addValidator(
                'CREDIT_AMOUNT',
                {
                    description: locale.get('ACH.CreditAmount'),
                    exists: true,
                    matches: validatorPatterns.PAMOUNT_PATTERN,
                },
            );
            this.model.addValidator(
                'DEBIT_AMOUNT',
                {
                    description: locale.get('ACH.DebitAmount'),
                    exists: true,
                    matches: validatorPatterns.PAMOUNT_PATTERN,
                },
            );
            this.dialogTitle = locale.get('risk.ach.pigid.addnew');
            break;
        }
        this.viewState = options.state || null;
        this.buildButtonArray();
    },

    buildReactButtonData() {
        return {
            rowData: {
                actions: [
                    ...(this.options.action === 'select' ? this.modelButtons.map(button => button.action) : []),
                    'CANCEL',
                ],
            },
            actionDefs: {
                ...(this.options.action === 'select' ? this.modelButtons.reduce((acc, cur) => ({
                    ...acc,
                    [cur.action]: {
                        label: cur.label,
                        onClick: (...args) => {
                            const fn = this.getButtonAction(cur.action);
                            this[fn](...args);
                        },
                    },
                }), {}) : {}),
                CANCEL: {
                    label: locale.get('button.cancel'),
                    onClick: () => dialog.close(),
                },
            },
            classes: {
                popover: 'controlTotalPopover',
            },
        };
    },

    buildBackboneButtonData() {
        if (this.options.action === 'select') {
            this.dialogButtons = this.modelButtons
                .map((button, index) => ({
                    text: button.label,
                    className: index === 0 ? 'btn btn-primary' : 'btn btn-secondary',
                    callback: this.getButtonAction(button.action),
                }));
        } else {
            this.dialogButtons = [{
                text: locale.get('ACH.save'),
                className: 'btn btn-primary',
                callback: this.options.action === 'modify'
                    ? 'modifyPaymentInitiatorGroup' : 'createPaymentInitiatorGroup',
            }];
        }
        // Always add cancel
        this.dialogButtons.push({
            text: locale.get('button.cancel'),
            className: 'btn btn-tertiary',
            callback: 'cancel',
        });
    },

    buildButtonArray() {
        /**
         * if mobile -> build array for react
         * else use dialog buttons below
         */
        if (this.isMobile && this.options.action === 'select') {
            this.reactActionsData = this.buildReactButtonData();
            return;
        }
        this.buildBackboneButtonData();
    },

    getButtonAction(action) {
        const actionMap = {
            REJECT: 'rejectAction',
            APPROVE: 'approveAction',
            DELETE: 'deleteAction',
            MODIFY: 'setModelToModify',
        };
        return actionMap[action] ?? '';
    },


    setModelToModify() {
        dialog.close();
        setTimeout(() => {
            // this.options.achList.gridView.gridModifyAction(this.options.model);
            this.options.action = 'modify';
            this.options.achList.gridRowModify(this.options);
        }, 0);
    },
    approveAction() {
        dialog.close();
        this.appBus.trigger(`grid:row:action:action_approve_${this.options.achList.gridView.cid}`, this.options.model);
    },
    rejectAction() {
        dialog.close();
        this.appBus.trigger(`grid:row:action:action_reject_${this.options.achList.gridView.cid}`, this.options.model);
    },
    deleteAction() {
        dialog.close();
        this.appBus.trigger(`grid:row:action:action_delete_${this.options.achList.gridView.cid}`, this.options.model);
    },
    cancel(result) {
        if (this.creditAmount || this.debitAmount) {
            this.model.set('CREDIT_AMOUNT', this.creditAmount);
            this.model.set('DEBIT_AMOUNT', this.debitAmount);
        }
        store.set('achClientModelActions', result.type ? '' : result);
        dialog.close();
    },
    initSelect2Combobox() {
        this.ui.$pigId.comboBox({
            dropdownAutoWidth: false,
            triggerChange: true,
            placeholder: 'select',
            initSelection($element, callback) {
                callback({
                    id: $element.val(),
                    text: $element.val(),
                });
            },
            query: util.debounce((query) => {
                getPaymentIntiatorGroupList(query.term).then((res) => {
                    query.callback({
                        results: res,
                    });
                });
            }, constants.COMBO_DEBOUNCE),
        }).on('change', $.proxy(this.changeNickname, this));
    },
    changeNickname(e) {
        this.model.set('nickname', e.added.nickname);
    },
    createPaymentInitiatorGroup() {
        if (!this.model.isValid()) {
            return;
        }
        const baseUrl = 'achControlTotalMaintenance/';
        const url = services.generateUrl(`${baseUrl}add`);
        const payload = this.createPayload(true);
        http.post(url, payload, (result) => {
            const response = {
                result,
                action: 'create',
            };
            this.cancel(response);
        }, (err) => {
            this.handleError(err);
        });
    },
    handleError(response) {
        const errors = response.responseJSON?.confirms
        && response.responseJSON?.confirms?.confirmResults.length > 0
            ? response.responseJSON?.confirms?.confirmResults[0]?.messages
            : response.responseJSON.message;
        this.alertRegion.show(alert.danger(
            errors.join(' '),
            {
                canDismiss: true,
                title: response.responseJSON.message.join(' '),
            },
        ));
    },
    modifyPaymentInitiatorGroup() {
        if (!this.model.isValid()) {
            return;
        }
        const baseUrl = 'achControlTotalMaintenance/';
        const url = services.generateUrl(`${baseUrl}update`);
        const payload = this.createPayload(false);
        http.post(url, payload, (result) => {
            const response = {
                result,
                action: 'update',
            };
            this.cancel(response);
        }, (err) => {
            this.handleError(err);
        });
    },
    createPayload(isCreate) {
        const payload = {
            item: [
                {
                    name: 'PIGID',
                    value: isCreate ? this.model.get('payment_group_id') : this.options.model.get('PIGID'),
                },
                {
                    name: 'PIGIDNICKNAME',
                    value: isCreate ? this.model.get('nickname') : this.options.model.get('PIGIDNICKNAME'),
                },
                {
                    name: 'CREDIT_AMOUNT',
                    value: this.model.get('CREDIT_AMOUNT'),
                },
                {
                    name: 'DEBIT_AMOUNT',
                    value: this.model.get('DEBIT_AMOUNT'),
                },
            ],
        };
        if (!isCreate) {
            payload.item = [
                ...payload.item,
                {
                    name: 'TNUM',
                    value: this.options.model.get('TNUM'),
                },
                {
                    name: 'REFERNCE_NUMBER',
                    value: this.options?.model.get('REFERNCE_NUMBER'),
                },
                {
                    name: 'REJECTED_REASON',
                    value: '',
                },
            ];
        }
        return payload;
    },


    onRender() {
        this.ui.$amountInput.inputmask('number');
        if (this.hasLoadedRequiredData()) {
            this.initSelect2Combobox();
            if (this.isMobile && this.options.action === 'select') {
                this.getMobileFooter.show(new ActionsGroupView({
                    ...this.reactActionsData,
                }));
            }
        } else {
            this.loadRequiredData();
        }
    },
    loadRequiredData() {
        this.setHasLoadedRequiredData(true);
        this.render();
    },
    templateHelpers() {
        if (this.options.action === 'select' || this.options.action === 'modify') {
            return {
                isMobile: this.isMobile,
                modifyView: this.options.action === 'select' || this.options.action === 'modify',
                isView: this.options.action === 'select',
            };
        }
        return {};
    },
});
