import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, registerDefaultsForModule } from '@glu/theming';
import { CaretDownIcon } from '@glu/icons-react';
import '../../themeDefaults';

export const themeDefaults = ({ dataComponents, palette }) => ({
  background: palette.background,
  border: `1px solid ${palette.accent}`,
  borderRadius: 2,
  caretRight: 10,
  caretTop: 9,
  color: palette.grey.G600,
  darkBackground: palette.primary.darkBackground,
  darkColor: palette.text.light,
  darkIconFill: palette.text.light,
  darkOptionColor: palette.text.light,
  display: dataComponents.svgIconDisplay,
  dropdownChevron: dataComponents.dropdownChevron,
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  fontSize: 14,
  fontWeight: 400,
  iconFill: palette.text.dark,
  optionColor: palette.text.dark,
  selection: dataComponents.selection
});

registerDefaultsForModule('styledSelect', themeDefaults);

const styles = ({ styledSelect }) => ({
  root: {
    border: styledSelect.border,
    position: 'relative',
    ...styledSelect.dropdownChevron,
    '& svg': {
      display: styledSelect.display,
      fill: ({ dark }) => (dark ? styledSelect.darkIconFill : styledSelect.iconFill),
      pointerEvents: 'none',
      position: 'absolute',
      right: styledSelect.caretRight,
      top: styledSelect.caretTop
    }
  },

  select: {
    '&::-ms-expand': { // necessary evil for IE 11, appearance should work in edge
      display: 'none'
    },
    appearance: 'none',

    background: ({ dark }) => (dark ? styledSelect.darkBackground : styledSelect.background),
    borderRadius: styledSelect.borderRadius,
    borderWidth: 0,
    color: ({ dark }) => (dark ? styledSelect.darkColor : styledSelect.color),
    fontFamily: styledSelect.fontFamily,
    fontSize: styledSelect.fontSize,
    fontWeight: styledSelect.fontWeight,
    lineHeight: 1,
    padding: styledSelect.padding,
    width: '100%',
    ...styledSelect.selection,

    '& option': {
      color: ({ dark }) => (dark ? styledSelect.darkOptionColor : styledSelect.optionColor)
    }
  }
});

const Select = ({
  children, className, classes, dark, firstOption, htmlId, name, onChange, theme, value, ...rest
}) => (
  <div className={`${classes.root} ${className}`}>
    <select
      id={htmlId}
      className={`${classes.select}`}
      name={name}
      onChange={onChange}
      value={value !== undefined && value !== null ? value : ''}
      {...rest}
    >
      {firstOption}
      {children}
    </select>
    <CaretDownIcon noIconWrapper focusable={false} />
  </div>
);

Select.propTypes = {

  children: PropTypes.node.isRequired,

  /** ClassName to use for the root element of this component, a wrapper div */
  className: PropTypes.string,

  /** Classes for JSS styling */
  classes: PropTypes.objectOf(PropTypes.string).isRequired,

  /** True if the select should be in dark mode which styles it for dark colored forms */
  dark: PropTypes.bool,

  /** Content for the first option, including the <option> tag set null/false to not use */
  firstOption: PropTypes.node,

  /** Html id to use for the select */
  htmlId: PropTypes.string.isRequired,

  /** Name to use for the select */
  name: PropTypes.string,

  /** called whenever onChange happens on the select */
  onChange: PropTypes.func.isRequired,

  /** JSS theme */
  theme: PropTypes.shape({}).isRequired,

  /** Value of currently selected option */
  value: PropTypes.string
};

Select.defaultProps = {
  className: '',

  dark: false,

  // eslint-disable-next-line jsx-a11y/control-has-associated-label
  firstOption: <option value="" />,
  name: undefined,
  value: undefined
};

export default withStyles(styles)(Select);
export const BareSelect = Select;
