var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row\">\n                <div class=\"form-column col-md-12\">\n                    <div class=\"field-container-xl field-container\">\n                        <div>\n                            <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"handleAddLocation\">\n                                                                <span class=\"icon-add-circle\"></span> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"LBX.AddLocation",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":102},"end":{"line":16,"column":130}}}))
    + "\n                            </button>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"lbxAddLocation\" data-region=\"addLocationRegion\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"lockboxLocations") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":52,"column":21}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n                <div class=\"panel panel-primary \">\n                                        <div data-hook=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCATION_ID") || (depth0 != null ? lookupProperty(depth0,"LOCATION_ID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCATION_ID","hash":{},"data":data,"loc":{"start":{"line":26,"column":56},"end":{"line":26,"column":71}}}) : helper)))
    + "\" class=\"panel-heading\" role=\"tab\" id=\"heading-"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":26,"column":118},"end":{"line":26,"column":126}}}) : helper)))
    + "\">\n                        <h3 class=\"panel-title\">\n                                                        <a role=\"button\" data-toggle=\"collapse\" href=\"#panel-"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":28,"column":109},"end":{"line":28,"column":117}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(data && lookupProperty(data,"first")),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":119},"end":{"line":28,"column":187}}})) != null ? stack1 : "")
    + " aria-controls=\"panel-"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":28,"column":209},"end":{"line":28,"column":217}}}) : helper)))
    + "\">\n                                                                <span class=\"indicator-icon\"></span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCATION_DESC") || (depth0 != null ? lookupProperty(depth0,"LOCATION_DESC") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCATION_DESC","hash":{},"data":data,"loc":{"start":{"line":29,"column":100},"end":{"line":29,"column":117}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCATION_CODE") || (depth0 != null ? lookupProperty(depth0,"LOCATION_CODE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCATION_CODE","hash":{},"data":data,"loc":{"start":{"line":29,"column":120},"end":{"line":29,"column":137}}}) : helper)))
    + "\n                                                        </a>\n                        </h3>\n                        <div class=\"pull-right\">\n                                                        <button class=\"btn btn-tertiary\" type=\"button\" data-action=\"editLocation\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"LBX.EditLockboxLocation",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":130},"end":{"line":33,"column":166}}}))
    + "</button>\n                                                        "
    + alias4(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"btn btn-tertiary hide","action":"deleteLocation","text":"Delete lockbox","name":"close"},"data":data,"loc":{"start":{"line":34,"column":56},"end":{"line":34,"column":158}}}))
    + "\n                        </div>\n                    </div>\n                                        <div id=\"panel-"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":37,"column":55},"end":{"line":37,"column":63}}}) : helper)))
    + "\" role=\"tabpanel\" data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCATION_ID") || (depth0 != null ? lookupProperty(depth0,"LOCATION_ID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCATION_ID","hash":{},"data":data,"loc":{"start":{"line":37,"column":90},"end":{"line":37,"column":105}}}) : helper)))
    + "\" data-hook=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCATION_CODE") || (depth0 != null ? lookupProperty(depth0,"LOCATION_CODE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCATION_CODE","hash":{},"data":data,"loc":{"start":{"line":37,"column":118},"end":{"line":37,"column":135}}}) : helper)))
    + "\" data-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCATION_DESC") || (depth0 != null ? lookupProperty(depth0,"LOCATION_DESC") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCATION_DESC","hash":{},"data":data,"loc":{"start":{"line":37,"column":148},"end":{"line":37,"column":165}}}) : helper)))
    + "\" class=\"panel-collapse collapse "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":198},"end":{"line":37,"column":221}}})) != null ? stack1 : "")
    + "\" aria-labelledby=\"heading-"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":37,"column":248},"end":{"line":37,"column":256}}}) : helper)))
    + "\">\n                                                <div class=\"panel-body\">\n                                                        <div data-region=\"lockboxRegion-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCATION_ID") || (depth0 != null ? lookupProperty(depth0,"LOCATION_ID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCATION_ID","hash":{},"data":data,"loc":{"start":{"line":39,"column":88},"end":{"line":39,"column":103}}}) : helper)))
    + "\"></div>\n                                                        <div class=\"widget-links\">\n                                                                <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"removeLockbox\">\n                                                                        <span class=\"icon-minus-circle\"></span> "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"LBX.RemoveLockboxes",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":42,"column":112},"end":{"line":42,"column":144}}}))
    + "</button>\n                                                                <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"addLockbox\">\n                                                                        <span class=\"icon-add-circle\"></span> "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"LBX.AddAnotherLockbox",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":44,"column":110},"end":{"line":44,"column":144}}}))
    + "</button>\n                                                        </div>\n                                                </div>\n                    </div>\n                </div>\n\n\n\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "class=\"collapsed\" aria-collapsed=\"true\"";
},"5":function(container,depth0,helpers,partials,data) {
    return "in";
},"7":function(container,depth0,helpers,partials,data) {
    return "            <div data-region=\"firstLocationRegion\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.Title",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":53}}}))
    + "</h1>\n</div>\n<div class=\"page-lockbox\">\n    <div class=\"section section-container\">\n        <div class=\"section-header\">\n            <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.LocationTitle",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":46}}}))
    + "</h2>\n        </div>\n        <div data-region=\"alertRegion\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"lockboxLocations") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":55,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});