import BaseWidget from 'common/uiWidgets/baseWidget/baseWidget';
import formFieldUtil from 'common/uiWidgets/util/formFieldUtil';
import { appBus } from '@glu/core';
import util from '@glu/core/src/util';
import constants from 'app/administration/constants';
import lockToggleWidgetTmpl from './lockToggleWidget.hbs';

const PROP_EXISTS = 'exists';
const FIELD_DATA_MANDATORY = 'mandatory';

export default BaseWidget.extend({
    template: lockToggleWidgetTmpl,
    className: 'ui-widget LockToggleWidget field-container',

    initialize(options) {
        BaseWidget.prototype.initialize.call(this, options);
        this.fieldData = options.fieldData;
        this.relatedFields = this.fieldData.relatedProperty.split(',');
        this.hideAllFields = !this.relatedFieldsHaveData(
            this.relatedFields,
            this.parentModel,
        ) && options.state === constants.MODES.VIEW;

        this.requiredFields = this.getRelatedFieldsByProp(
            this.relatedFields,
            this.parentModel,
            FIELD_DATA_MANDATORY,
        );
        // Make sure this value has a default
        if (this.parentModel.get(this.fieldData.name) !== '1') {
            this.parentModel.set(this.fieldData.name, '0', { silent: true });
        }
        // Wait until policies are applies
        this.listenTo(appBus, 'policies:applied', this.applyFieldBehaviors.bind(this));
        // Update the requiredFields based on model validator change events
        this.listenTo(this.parentModel, 'validator:added', this.addRequiredField.bind(this));
        this.listenTo(this.parentModel, 'validator:removed', this.removeRequiredField.bind(this));
        this.listenTo(this.parentModel, 'validator:prop:removed', this.removeRequiredField.bind(this));
    },

    /**
     * When a validator is added to the model, if the validator prop
     * is 'exists' and it's true, add that field to the requiredFields list
     * @param {string} field - name of model attribute
     * @param {Object} validators - current validators on the model
     */
    addRequiredField(field, validators) {
        if (this.requiredFields.indexOf(field) === -1 && validators.exists) {
            this.requiredFields = [...this.requiredFields, field];
        }
        this.updateFieldState();
    },

    /**
     * When a validator is removed from the model, remove it from the
     * list of requiredFields as long as the prop passed is undefined
     * or 'exists' in both cases, the field is no longer required
     * @param {string} removedField - name of model attribute
     * @param {string} prop - name of validator prop that was removed
     */
    removeRequiredField(removedField, prop) {
        this.requiredFields = this.requiredFields.filter(field => removedField !== field
                || (prop !== PROP_EXISTS && prop !== undefined));

        this.updateFieldState();
    },

    /**
     * Check each related field and determine if any of the fields have
     * any values set.
     * @returns {boolean} - if any of related fields have data values
     */
    relatedFieldsHaveData(relatedFields, model) {
        return relatedFields.some((fieldName) => {
            const value = model.get(fieldName);
            return !util.isNullOrUndefined(value) && value !== '';
        });
    },

    /**
     * When all fields should be hidden, hide the container for each
     * of the related fields
     */
    showHideFields() {
        if (this.hideAllFields) {
            this.relatedFields.forEach((fieldName) => {
                const $field = formFieldUtil.getFieldElement(this.parentView, fieldName);
                formFieldUtil.getContainer($field).hide();
            });
        }
    },

    /**
     * Depending on whether this is payment or template, disable/enable or
     * make required/optional the related fields
     */
    updateFieldState() {
        const locked = this.parentModel.get(this.fieldData.name) === '0';
        // Lockable will only ever be false for payments, so apply payment specific logic here
        if (!this.fieldData.lockable) {
            if (!locked) {
                this.toggleReadOnlyFields(locked);
            }
        } else {
            this.toggleRequiredFields(locked);
        }
    },

    /**
     * Apply specific behaviors based on state
     */
    applyFieldBehaviors() {
        if (this.options.state === constants.MODES.VIEW) {
            this.showHideFields();
        }

        this.updateFieldState();
    },

    /**
     * Use the functionCode to evalute if this is a template
     * @return {boolean} - if this functionCode is for templates
     */
    isTemplate(parentView, parentModel) {
        return parentView.functionCode === 'TMPL' && parentModel.get('TEMPLATETNUM') === '';
    },

    /**
     * Correctly updates the lock icon state upon when the user clicks it
     * @param  {Event} e  Jquery click handler event
     */
    toggleLock(e) {
        // Never toggle the lock in view mode
        if (this.options.state !== constants.MODES.VIEW) {
            e.currentTarget.classList.toggle('icon-unlock');
            e.currentTarget.classList.toggle('icon-lock');
            const allowUnlock = e.currentTarget.classList.contains('icon-unlock');
            this.updateModel(allowUnlock);
        }
    },

    /**
     * Update the model with proper value based on the param and
     * then toggle the fields as required or not
     * @param {boolean} unlocked - status of the lock
     */
    updateModel(unlocked) {
        this.parentModel.set(this.fieldData.name, unlocked ? '1' : '0');
        this.updateFieldState();
    },

    /**
     * Set each related field to required or not based on the
     * lock param
     * @param {boolean} locked
     */
    toggleRequiredFields(locked) {
        this.requiredFields.forEach((field) => {
            formFieldUtil.toggleRequired(this.parentModel, this.parentView, field, locked);
            if (!locked) {
                this.parentModel.validateField(field);
            }
        });
    },

    /**
     * Return an array of field names that have the property flag
     * set to true in the fieldData
     * @param {Array} relatedFields - array of related fields names
     * @param {Model} model - model with validators
     * @param {string} property - propert to search for
     * @return {Array} string array of field names
     */
    getRelatedFieldsByProp(relatedFields, model, property) {
        return relatedFields.reduce((accum, field) => {
            if (model.fieldData[field] && model.fieldData[field][property]) {
                return [...accum, field];
            }
            return accum;
        }, []);
    },

    /**
     * Set each related field to be read only or not based on the
     * lock param
     * @param {boolean} locked
     */
    toggleReadOnlyFields(locked) {
        this.relatedFields.forEach((field) => {
            formFieldUtil.toggleReadOnly(this.parentView, field, locked);
        });
    },

    templateHelpers() {
        return {
            isVisible: this.fieldData.lockable,
            locked: this.parentModel.get(this.fieldData.name) !== '1',
            name: this.fieldData.name,
            label: this.fieldData.fieldLabel,
            hideAllFields: this.hideAllFields,
        };
    },
});
