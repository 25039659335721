import constants from 'app/smbBillManagement/constants';
import GridApi from 'common/dynamicPages/api/grid';
import ContextApi from 'common/dynamicPages/api/context';
import GridLayout from './gridLayout';
import validateBillGridTmpl from './validateBillGrid.hbs';

export default GridLayout.extend({
    name: 'Validate',
    template: validateBillGridTmpl,

    initialize(options) {
        const gridOptions = {
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,
            columnsToBeHidden: constants.COMMON_COLUMNSTOHIDE,
            context: ContextApi.menuContext.getContext('BOS_BILLMGMT'),
        };

        this.gridView = GridApi.createServiceGridView(gridOptions);
        this.entitlements = options.entitlements;
        this.alertRegion = options.alertRegion;
    },

    onShow(...args) {
        this.validateBillGrid.show(this.gridView);
        GridLayout.prototype.onShow.apply(this, args);
    },

    removeActions() {
        this.gridView.grid.collection.each((rowParam) => {
            const row = rowParam;
            let VIEW = null;
            let MODIFY = null;
            let VALIDATE = null;
            let UNVALIDA = null;
            let REJECT = null;
            const { STATUS } = row.attributes;
            for (let i = 0; i < row.buttons.length; i += 1) {
                if (row.buttons[i].action === 'SELECT') {
                    VIEW = row.buttons[i];
                } else if (row.buttons[i].action === 'MODIFY') {
                    MODIFY = row.buttons[i];
                } else if (row.buttons[i].action === 'VALIDATE') {
                    VALIDATE = row.buttons[i];
                } else if (row.buttons[i].action === 'UNVALIDA') {
                    UNVALIDA = row.buttons[i];
                } else if (row.buttons[i].action === 'REJECT') {
                    REJECT = row.buttons[i];
                }
            }
            if (STATUS === 'AV') {
                row.buttons = [];
                if (VALIDATE) {
                    row.buttons.push(VALIDATE);
                }
                row.buttons.push(VIEW);
                if (MODIFY) {
                    row.buttons.push(MODIFY);
                }
                if (REJECT) {
                    row.buttons.push(REJECT);
                }
            } else if (STATUS === 'EN') {
                row.buttons = [];
                if (UNVALIDA) {
                    row.buttons.push(UNVALIDA);
                }
                row.buttons.push(VIEW);
            }
        });
    },
});
