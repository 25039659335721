import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import services from 'services';
import httpError from 'common/util/httpErrorResult';

export default Model.extend({
    defaults: {
        questioncode1: '',
        questionanswer1: '',
        questioncode2: '',
        questionanswer2: '',
        questioncode3: '',
        questionanswer3: '',
        questioncode4: '',
        questionanswer4: '',
        questioncode5: '',
        questionanswer5: '',
    },

    validators: {
        questioncode1: {
            description: 'A question',
            exists: true,
        },

        questionanswer1: {
            description: 'This answer',
            exists: true,
            isUnique: true,
            minLength: 3,
            maxLength: 35,
        },

        questioncode2: {
            description: 'A question',
            exists: true,
        },

        questionanswer2: {
            description: 'This answer',
            exists: true,
            isUnique: true,
            minLength: 3,
            maxLength: 35,
        },

        questioncode3: {
            description: 'A question',
            exists: true,
        },

        questionanswer3: {
            description: 'This answer',
            exists: true,
            isUnique: true,
            minLength: 3,
            maxLength: 35,
        },

        questioncode4: {
            description: 'A question',
            exists: true,
        },

        questionanswer4: {
            description: 'This answer',
            exists: true,
            isUnique: true,
            minLength: 3,
            maxLength: 35,
        },

        questioncode5: {
            description: 'A question',
            exists: true,
        },

        questionanswer5: {
            description: 'This answer',
            exists: true,
            isUnique: true,
            minLength: 3,
            maxLength: 35,
        },
    },

    parseString(string) {
        if (typeof string !== 'string') {
            return '';
        }

        let localString = string;
        localString = localString.replace(/<|>|'|/g, '');
        return localString;
    },

    sync(method, model, options) {
        const myArray = [];
        const tableMaintService = services.generateUrl('/tableMaint/add');
        const savedData = model.toJSON();

        Object.keys(savedData || {}).forEach((key) => {
            myArray.push({
                name: key.toUpperCase(),
                value: this.parseString(savedData[key]),
            });
        });

        const tableMaintData = {
            typeCode: 'USECQ_TM',

            data: {
                item: myArray,
            },

            functionCode: 'MAINT',
            productCode: '_UTIL',
        };

        http.post(tableMaintService, tableMaintData, (result) => {
            options.success(result);
        }, httpError.getResult(options, model));
    },
});
