import BeneUpdatedWarning from 'common/dynamicPages/views/beneUpdatedWarning';
import dialog from '@glu/dialog';
import $ from 'jquery';
import util from 'underscore';
import number from 'numeral';
import moment from 'moment';
import locale from '@glu/locale';
import userInfo from 'etc/userInfo';

// "alias" imports separated from "global" imports, above
import PaymentUtil from 'common/util/paymentUtil';
import dateUtil from 'common/util/dateUtil';
import Decimals from 'common/dynamicPages/api/decimals';
import appConfigParams from 'system/webseries/models/applicationConfiguration';
import validatorPatterns from 'system/validatorPatterns';

const DUEONDATE = 'REQUESTED_DUE_DATE';
const EXPDATE = 'REQUESTED_EXPIRATION_DATE';
const REQUEST = 'request';

/**
 * @name makeRequired
 * @description - if state is true, makes the field required; otherwise makes it optional
 * when a field is required it will have a red asterisk
 * @param {object} field - f1 field
 * @param {boolean} state - true makes field required; false makes it optional
 * @param {Object} form
 */
const makeRequired = (field, state, form) => {
    field.shouldBeRequiredWhen(state);
    if (field.$el) {
        const container = field.$el.closest('.field-container');
        if (container.length) {
            container.toggleClass('required', state && form.formView.state.toUpperCase() !== 'VIEW');
        }
    }
};

/**
 * @name showField
 * @description - Hide or show a specific field based on a flag
 * @param {object} form - the form
 * @param {model} model - the model
 * @param {jquery} field - a specific reference to a field
 * @param {boolean} flag - "true" to show a field, "false" to hide it
 * @param {boolean} readOnlyComboBoxFlag - indicates if this field is a readOnlyCombobox
 */
const showField = (form, model, field, flag, readOnlyComboBoxFlag) => {
    /*
     * Due to complications surrounding how we render empty value read-only comboboxes and
     * hide/show fields in f1 functions, this specific read-only combobox for ORIGINATOR_STATE
     * must be manually shown and hidden
     */
    if (readOnlyComboBoxFlag) {
        if (flag) {
            field.removeClass('hidden');
        } else {
            field.addClass('hidden');
        }
    } else if (flag) {
        field.shouldBeVisible();
    } else {
        field.shouldBeHidden();
    }
};

const addressFields = [
    'ORIGINATOR_ADDRESS_1',
    'ORIGINATOR_ADDRESS_2',
    'ORIGINATOR_CITY',
    'ORIGINATOR_COUNTRY',
    'ORIGINATOR_POSTALCODE',
    'ORIGINATOR_PROVINCE',
    'ORIGINATOR_STATE',
];

const allAddressFieldsEmpty = (model) => {
    const empty = addressFields.every(fld => util.isEmpty(model.get(fld)));
    return empty;
};

const adjustStateProvince = (form, model) => {
    let stateField = form.field('ORIGINATOR_STATE');
    const provinceField = form.field('ORIGINATOR_PROVINCE');
    const isUSA = model.get('ORIGINATOR_COUNTRY') === 'US';

    showField(form, model, provinceField, !isUSA, false);

    if (form.formView.state.toUpperCase() === 'MODIFY') {
        const readOnlyComboBoxFlag = stateField.isDisabled();
        if (readOnlyComboBoxFlag) {
            stateField = form.field('ORIGINATOR_STATE').$el.parent('.hidden').siblings('#ORIGINATOR_STATE');
        }
        showField(form, model, stateField, isUSA, readOnlyComboBoxFlag);
    } else {
        showField(form, model, stateField, isUSA, false);
    }
};

const adjustAddressFields = (form, model, requiredState) => {
    const addressLine1 = form.field('ORIGINATOR_ADDRESS_1');
    const countryField = form.field('ORIGINATOR_COUNTRY');
    const postalCodeField = form.field('ORIGINATOR_POSTALCODE');
    const stateField = form.field('ORIGINATOR_STATE');
    const provinceField = form.field('ORIGINATOR_PROVINCE');
    const cityField = form.field('ORIGINATOR_CITY');
    const isUSA = model.get('ORIGINATOR_COUNTRY') === 'US';

    adjustStateProvince(form, model);

    makeRequired(stateField, (requiredState && isUSA), form);
    makeRequired(provinceField, (requiredState && !isUSA), form);
    makeRequired(addressLine1, requiredState, form);
    makeRequired(cityField, requiredState, form);
    makeRequired(countryField, requiredState, form);
    makeRequired(postalCodeField, requiredState, form);

    if (model.get('ORIGINATOR_ADDRESS_2')) {
        form.formView.$('#ORIGINATOR_ADDRESS_1_LINK').parent().addClass('hide');
        if (form.fields.ORIGINATOR_ADDRESS_2) {
            form.field('ORIGINATOR_ADDRESS_2').$el.parent().removeClass('hide');
        } else {
            form.formView.$('[name="ORIGINATOR_ADDRESS_2"]').parents('.field-container').removeClass('hide');
        }
    }
};

const setupAddressFields = (form, model, addressRequired) => {
    const addressLine1 = form.field('ORIGINATOR_ADDRESS_1');
    if (addressRequired === '1') {
        makeRequired(addressLine1, true, form);
        adjustAddressFields(form, model, true);
    } else {
        adjustStateProvince(form, model);
    }
};

/*
 * Need to show the user a warning if the contact used in this payment has
 * been modified or deleted.
 * This flag is set on contact approval/deletion
 * @param {object} model
 * @param {object} formView
 */
const setupWarningDialog = (model, formView) => {
    const contactHasBeenUpdatedValue = model.get('CONTACTHASBEENUPDATED');
    const isApplicableActionMode = formView.state.toUpperCase() === 'MODIFY' || (formView.state.toUpperCase() === 'INSERT' && model.context.createdFrom === '1');
    if (isApplicableActionMode && (contactHasBeenUpdatedValue === 'YES' || contactHasBeenUpdatedValue === 'INVALID') && !model.get('beneWarningShown')) {
        model.set('beneWarningShown', true);
        const beneUpdatedWarning = new BeneUpdatedWarning({
            model,
            contactHasBeenUpdatedValue,
            formView,
        });
        dialog.custom(beneUpdatedWarning);
    }
};

/**
 * @description adds the error class and inline validation message to a field
 * @param {object} form
 * @param {string} fieldName
 * @param {string} errorMsg
 */
const addError = (form, fieldName, errorMsg) => {
    const fld = form.field(fieldName);
    const parent = fld.$el.parent();
    if (errorMsg) {
        parent.addClass('has-error');
        parent.find('.help-block').text(locale.get(errorMsg));
    } else {
        parent.removeClass('has-error');
        parent.find('.help-block').text(''); // clear out error text
    }
};

/**
 * @description determines whether any error indicator is to be cleared
 * @param {object} form
 * @param {string} fieldName
 */
const hasError = (form, fieldName) => {
    if (form.field(fieldName) && form.field(fieldName).$el.parent().hasClass('has-error')) {
        return true;
    }
    return false;
};

/**
 * @description clears the inline validation error on a field
 * @param {object} form
 * @param {string} fieldName
 */
const clearError = (form, fieldName) => {
    // should only add error if has-error
    if (hasError(form, fieldName)) {
        addError(form, fieldName, '');
    }
};

/**
 * @description determines whether any error message indicator on UI field is to be cleared
 * @param {object} e
 * @param {object} form
 * @param {string} fieldName
 */
const isClearErrorMessage = (e, form, fieldName) => {
    const { model } = form.formView;
    const isMandatory = model.fieldData[fieldName].mandatory;
    if (isMandatory && util.isEmpty(e.target.value)) {
        return false;
    }
    if (hasError(form, fieldName) && !util.isEmpty(e.target.value)) {
        return false;
    }
    return true;
};

/**
 * @description checks if the input date is less than today.  if so returns true;
 * otherwise, returns false
 * @param {string} dateValue
 */
const isDateBeforeToday = (dateValue) => {
    const dateFormat = userInfo.getDateFormat();
    const today = moment(new Date(), dateFormat).startOf('day');
    return moment(dateValue, dateFormat).isBefore(moment(today, dateFormat));
};

/**
 * @description checks if the expires on date is greater than or equal to the requested date
 * and that the expires on date is greater than today.
 * @param {object} model
 * @param {string} dateValue
 * @returns {boolean} true if expires on date is valid
 */
const validExpirationDate = (model, dateValue) => {
    const dateFormat = userInfo.getDateFormat();
    // make sure the expiration date is greater than or equal to the requested date
    const dueDate = model.get(DUEONDATE);
    if (moment(dateValue, dateFormat).isBefore(dueDate, dateFormat)) {
        return false;
    }
    // make sure that the expiration date is not before today
    return !isDateBeforeToday(dateValue);
};

/**
 * @name updateExpirationDate
 * @description change handler for REQUESTED_DUE_DATE
 * @param {object} form
 * @param {object} model
 */
const updateExpirationDate = (form, model, clear = true) => {
    const expiration = parseInt(model.get('EXPIRATION'), 10);
    if (!Number.isNaN(expiration) && expiration > 0) {
        // clear out REQUESTED_EXPIRATION_DATE
        if (clear) {
            model.set(EXPDATE, '');
        }
        const dateFormat = userInfo.getDateFormat();

        // adjust the expiration date calendar to reflect the due date & expiration setting
        const dueDate = model.get(DUEONDATE) || new Date();
        const minDate = moment(dueDate, dateFormat);
        if (!minDate.isValid()) {
            return; // don't reset expires on calendar if date is invalid
        }
        const endDate = moment(dueDate, dateFormat).add(expiration, 'days');
        const $expDatePicker = form.formView.$('[name="REQUESTED_EXPIRATION_DATE"]');

        /*
         * we need to reset the date picker and ensure that it displays
         * the correct month based on the due date
         */
        $expDatePicker.nhDatePicker({
            startDate: minDate,
            minDate,
            endDate,
            maxDate: endDate,
            showCalendarIcon: true,
            rangeDatePicker: false,
            processingDays: ['1111111'],
        });
    }
};

const resetDueOnDate = (form) => {
    const minDate = moment(new Date(), userInfo.getDateFormat());
    const $dueDatePicker = form.formView.$('[name="REQUESTED_DUE_DATE"]');
    /*
     * we need to reset the date picker and ensure that it displays
     * the correct month based on the due date
     */
    $dueDatePicker.nhDatePicker({
        startDate: minDate,
        minDate,
        showCalendarIcon: true,
        rangeDatePicker: false,
        processingDays: ['1111111'],
    });
};

const showDebtorBirthFields = (form, model, showPersonalInfo) => {
    // by default hide the consumer personal information
    form.field('DEBTOR_COUNTRY_OF_BIRTH').shouldBeHidden();
    form.field('DEBTOR_CITY_OF_BIRTH').shouldBeHidden();
    form.field('DEBTOR_BIRTH_DATE').shouldBeHidden();
    if (model.get('PAYER_TYPE') === 'CONSUMER') {
        // if flag indicates as such show the consumer personal information
        if (showPersonalInfo) {
            form.field('DEBTOR_COUNTRY_OF_BIRTH').shouldBeVisible();
            form.field('DEBTOR_CITY_OF_BIRTH').shouldBeVisible();
            form.field('DEBTOR_BIRTH_DATE').shouldBeVisible();
            dateUtil.maskDate(form.formView, 'DEBTOR_BIRTH_DATE');
            if (model.get('DEBTOR_BIRTH_DATE')) {
                model.set('DEBTOR_BIRTH_DATE', moment(model.get('DEBTOR_BIRTH_DATE')).format(userInfo.getDateFormat()));
            }
        }
        if (form.fields.DEBTOR_LEI_ID) {
            form.field('DEBTOR_LEI_ID').shouldBeHidden();
        } else {
            form.formView.$('[name="DEBTOR_LEI_ID"]').parents('.field-container').addClass('hidden');
        }
        form.field('DISCOUNT_AMOUNT').shouldBeHidden();
        model.set('DISCOUNT_AMOUNT', '');
    } else if (model.get('PAYER_TYPE') === 'BUSINESS') {
        if (form.fields.DEBTOR_LEI_ID) {
            form.field('DEBTOR_LEI_ID').shouldBeVisible();
        } else {
            form.formView.$('[name="DEBTOR_LEI_ID"]').parents('.field-container').removeClass('hidden');
        }
        form.field('DISCOUNT_AMOUNT').shouldBeVisible();
    }
};

const discountAmountChange = (form, model) => {
    let enableRequiredProps;
    if (model.get('DISCOUNT_AMOUNT') && form.formView.state.toUpperCase() !== 'VIEW') {
        form.field('FULL_AMOUNT').$el.parent().parent().parent().addClass('required');
        enableRequiredProps = true;
    } else {
        form.field('FULL_AMOUNT').$el.parent().parent().parent().removeClass('required');
        enableRequiredProps = false;
    }
    form.field('FULL_AMOUNT').shouldBeRequiredWhen(enableRequiredProps);
};

const getPaymentReceivedSection = (form) => {
    const receivedAmount = form.field('RECEIVED_AMOUNT');
    const paymentStatus = form.field('PAYMENT_STATUS_DESCRIPTION');
    const acceptedAmount = form.field('ACCEPTED_AMOUNT');
    let section = null;
    if (receivedAmount.$el.length) {
        section = receivedAmount.$el.closest('.section');
    } else if (acceptedAmount.$el.length) {
        section = acceptedAmount.$el.closest('.section');
    } else if (paymentStatus.$el.length) {
        section = paymentStatus.$el.closest('.section');
    } else if (form.field('PAYMENTDETAIL')) {
        section = form.field('PAYMENTDETAIL').$el.closest('.section');
    }
    return section?.length > 0 ? section : null;
};

const getCancelSummarySection = (form) => {
    const cancellationTS = form.field('CANCELLATION_TIMESTAMP');
    let section;
    if (cancellationTS.$el.length) {
        section = cancellationTS.$el.closest('.section');
    }
    return section;
};
/**
 * NH-169735
 * added email validation for REMITTANCE_ADDRESS field
 * whenever REMITTANCE_METHOD is email
 */
const addValidatorToEmail = (model) => {
    model.addValidator('REMITTANCE_ADDRESS', {
        description: model.fieldData.REMITTANCE_ADDRESS.fieldLabel,
        exists: true,
        matches: validatorPatterns.EMAIL_PATTERN,
    });
};
export default function (form, initialState) {
    const { model } = form.formView;
    const { formView } = form;
    const businessRadioBtn = form.formView.$('#PAYER_TYPE_RADIO_BTN-BUSINESS');
    const consumerRadioBtn = form.formView.$('#PAYER_TYPE_RADIO_BTN-CONSUMER');
    const amtModAllowed = form.formView.$('#AMOUNT_MODIFICATION_ALLOWED');
    const remittanceMethod = form.field('REMITTANCE_METHOD');
    const remittanceAddress = form.field('REMITTANCE_ADDRESS');
    const saveAsTemplate = form.field('SAVEASTEMPLATE');
    const templateCode = form.field('TEMPLATE_CODE');
    const templateDescription = form.field('TEMPLATE_DESCRIPTION');
    const addressRequired = appConfigParams.get('RTP_REQUIREBENEADDRESS');
    const useTemplate = form.field('USETEMPLATE');
    const showPersonalInfo = (appConfigParams.getValue('RTP', 'REQUIREPERSONALID') === '1');
    const $paymentReceivedSection = getPaymentReceivedSection(form);
    const $cancelSummarySection = getCancelSummarySection(form);
    const paymentDetail = form.field('PAYMENTDETAIL');

    const $summarySection = $('[data-widget-id="summarysection"]').closest('fieldset');

    const setupPaymentSummarySection = () => {
        const $paymentSummaryContainer = $('[data-section="payment-summary"]');
        const $amountFields = $paymentSummaryContainer.find('[data-field="summary-amount"]');
        const $currencyField = $paymentSummaryContainer.find('[data-field="summary-currency"]');
        const reqAmount = model.get('REQUESTED_AMOUNT');
        const reqAmountCcy = model.get('REQUESTED_AMOUNT_CCY');

        // Update the summary sections visibility
        $paymentSummaryContainer.removeClass('hidden');

        // Format and set the amount
        const retAmountFormatted = number(reqAmount || 0)
            .format(Decimals.getCurrencyFormat(reqAmountCcy));
        $amountFields.text(retAmountFormatted);
        if ($currencyField.text() !== reqAmountCcy) {
            $currencyField.text(reqAmountCcy);
        }
    };

    const payerTypeChange = () => {
        if (model.get('PAYER_TYPE_RADIO_BTN') === 'BUSINESS') {
            model.set('PAYER_TYPE', 'BUSINESS');
        } else {
            model.set('PAYER_TYPE', 'CONSUMER');
            model.set('DEBTOR_LEI_ID', '');
        }
        showDebtorBirthFields(form, model, showPersonalInfo);
        discountAmountChange(form, model);
    };

    const validateTemplateInfo = (saveTemplateChecked) => {
        const entryMethod = model.get('ENTRYMETHOD');
        // Clear out Template Code and Template Description when not visible
        if (!saveTemplateChecked && (!entryMethod || entryMethod === '0')) {
            model.set({
                TEMPLATE_CODE: '',
                TEMPLATE_DESCRIPTION: '',
            });
            templateCode.setValue('');
            templateDescription.setValue('');
        }
        templateCode.shouldBeVisibleWhen(saveTemplateChecked);
        templateDescription.shouldBeVisibleWhen(saveTemplateChecked);
        PaymentUtil.validateTemplateInfo({
            saveTemplateChecked,
            templateCode,
            templateDescription,
            tempCodeFieldName: 'TEMPLATE_CODE',
            tempDesFieldName: 'TEMPLATE_DESCRIPTION',
        }, model);
    };

    const isTemplate = model.context.functionCode === 'REQTMPL'
          || model.get('FUNCTION') === 'REQTMPL';
    const fromTemplate = model.get('FROMTEMPLATE') === '1';
    const templateSource = model.context.copySource;
    const requestedDueDate = form.formView.$('[name="REQUESTED_DUE_DATE"]');
    const PAYMENT_REJECTED = 'RJCT';
    const PAYMENT_PENDING = 'ACTC';
    const PAYMENT_POSTED = 'PSTD';

    if (initialState) {
        if ($paymentReceivedSection) {
            $paymentReceivedSection.hide();
        }

        if ($cancelSummarySection) {
            if (!(model.get('STATUS') === 'XS' || model.get('STATUS') === 'XD')) {
                $cancelSummarySection.hide();
            } else if (model.get('CANCEL_REASON_CODE') === 'AUTO') {
                form.field('CANCEL_REASON_DESC').shouldBeHidden();
            }
        }

        // requests from templates, hide lookups
        if (model.get('ENTRYMETHOD') === '1') {
            // hide lookup buttons
            $('[name="ORIGINATOR_NAME_LOOKUP"]').addClass('hidden');
        }

        // set checkbox correctly based on model's value
        const amountModAllowed = model.get('AMOUNT_MODIFICATION_ALLOWED');
        amtModAllowed.prop('checked', amountModAllowed === '1');

        if (form.formView.state === 'insert') {
            /*
             * save as template should appear when creating a request from scratch or
             * 'copy as a request' from a request
             */
            const opts = form.formView.options;
            if ((fromTemplate && templateSource !== REQUEST)
                || (templateSource === REQUEST && !opts.hasTemplatesEntitled)) {
                saveAsTemplate.$el.closest('.section').addClass('hidden');
                model.set('PAYER_TYPE_RADIO_BTN', model.get('PAYER_TYPE'));
            } else if (!fromTemplate && !opts.hasTemplatesEntitled) {
                saveAsTemplate.$el.closest('.section').addClass('hidden');
            } else if (templateSource === REQUEST && model.get('ENTRYMETHOD') === '0') {
                businessRadioBtn.prop('checked', model.get('PAYER_TYPE') === 'BUSINESS');
                consumerRadioBtn.prop('checked', model.get('PAYER_TYPE') === 'CONSUMER');
                model.set('PAYER_TYPE_RADIO_BTN', model.get('PAYER_TYPE'));
            } else {
                businessRadioBtn.prop('checked', true);
                model.set('PAYER_TYPE', 'BUSINESS');
                model.set('PAYER_TYPE_RADIO_BTN', 'BUSINESS');
            }

            // hide 'use a template' link if not entitled
            if (!isTemplate && !opts.useTemplatesEntitled) {
                useTemplate.shouldBeHidden();
            }

            // hide the template code and description
            $summarySection.hide();

            if (fromTemplate && templateSource === 'template' && !isTemplate) {
                // Hide the payer type section
                form.field('PAYER_TYPE_RADIO_BTN').shouldBeHidden();
                // show the template code and description
                $summarySection.show();
            }
        } else {
            if (model.get('ENTRYMETHOD') === '1') {
                // Hide the payer type section
                form.field('PAYER_TYPE_RADIO_BTN').shouldBeHidden();
                // hide save as template section
                saveAsTemplate.$el.closest('.section').addClass('hidden');
            } else if (model.get('PAYER_TYPE') === 'BUSINESS') {
                businessRadioBtn.prop('checked', true);
            } else if (model.get('PAYER_TYPE') === 'CONSUMER') {
                consumerRadioBtn.prop('checked', true);
            }

            model.set('PAYER_TYPE_RADIO_BTN', model.get('PAYER_TYPE'));
            useTemplate.shouldBeHidden();
        }

        if (form.formView.state === 'view') {
            form.formView.$("[name='REQUESTED_DUE_DATE_TIME']").text(model.get('REQUESTED_DUE_DATE_TIME'));
            form.formView.$("[name='REQUESTED_EXPIRATION_DATE_TIME']").text(model.get('REQUESTED_EXPIRATION_DATE_TIME'));
            if (util.isEmpty(model.get('REQUESTED_DUE_DATE_TIME'))) {
                form.formView.$("[id='REQUESTED_DUE_DATE_TIME']").addClass('hide');
            }
            if (util.isEmpty(model.get('REQUESTED_EXPIRATION_DATE_TIME'))) {
                form.formView.$("[id='REQUESTED_EXPIRATION_DATE_TIME']").addClass('hide');
            }
            // add view only class to address widget
            form.formView.$('[data-hook="address-line"]').addClass('address-widget-readonly');

            const status = model.get('PAYMENT_STATUS');
            if (util.contains([PAYMENT_PENDING, PAYMENT_POSTED, PAYMENT_REJECTED], status)
                && $paymentReceivedSection) {
                $paymentReceivedSection.show();
                form.field('PAYMENT_STATUS_DESCRIPTION').shouldBeVisibleWhen(status === PAYMENT_REJECTED);
                if (status === PAYMENT_POSTED) {
                    form.field('ACCEPTED_AMOUNT').shouldBeVisibleWhen(false);
                    form.field('REQUESTED_EXECUTION_DATE').shouldBeVisibleWhen(false);
                }
                paymentDetail.shouldBeVisibleWhen(status === PAYMENT_POSTED);
                if (paymentDetail.isVisible()) {
                    paymentDetail.$el.on('click', (e) => {
                        const isSMBUser = userInfo.isSmbUser();
                        e.stopImmediatePropagation();
                        // Pass id to match back to instruction_id in rtpreceived.
                        form.formView.trigger('rfp:viewPayment', model.get('RECEIVED_INSTRUCTION_ID'), isSMBUser);
                    });
                }
            }
        }

        if (form.formView.state.toUpperCase() === 'RESTORE') {
            if (isTemplate) {
                templateCode.$el.parent().addClass('read-only-text').removeClass('required');
            }
        }

        showDebtorBirthFields(form, model, showPersonalInfo);
        discountAmountChange(form, model);
        PaymentUtil.hideLookupIfProtected('DEBIT_BANK_CODE_LOOKUP', 'DEBIT_BANK_CODE', model, form.formView);
        if (model.fieldData.PAYER_TYPE && model.fieldData.PAYER_TYPE.protected) {
            form.formView.$('[name="PAYER_TYPE_RADIO_BTN"]').prop('disabled', true);
        }

        // requested due date should start today
        if (requestedDueDate && requestedDueDate.data('daterangepicker')) {
            requestedDueDate.data('daterangepicker').updateCalendars({
                minDate: moment(new Date()),
            });
        }

        model.on('change:PAYER_TYPE_RADIO_BTN', () => {
            payerTypeChange();
        });

        model.on('change:REQUESTED_AMOUNT', () => {
            setupPaymentSummarySection();
        });

        model.on('change:DISCOUNT_AMOUNT', () => {
            discountAmountChange(form, model);
        });

        // CONTACTTYPE is only available via bene lookup, so its change indicates looking up a bene
        model.on('change:CONTACTTYPE', () => {
            model.set('PAYER_TYPE_RADIO_BTN', model.get('CONTACTTYPE') === '1' ? 'BUSINESS' : 'CONSUMER');
        });

        model.on('change:ORIGINATOR_STATE change:ORIGINATOR_ADDRESS_1 change:ORIGINATOR_ADDRESS_2 change:ORIGINATOR_CITY change:ORIGINATOR_POSTALCODE change:ORIGINATOR_PROVINCE change:ORIGINATOR_COUNTRY', (m) => {
            /**
             * we need the defer here b/c the state can get
             * cleared out from a mapdate when the country changes
             */
            util.defer(() => {
                const flag = (addressRequired === '1' || !allAddressFieldsEmpty(m));
                adjustAddressFields(form, m, flag);
            });
        });

        // add validator to email when modify without changing REMITTANCE_METHOD
        if (model.get('REMITTANCE_METHOD') === 'EMAIL') {
            addValidatorToEmail(model);
        }
        model.on('change:REMITTANCE_METHOD', (m) => {
            const method = m.get('REMITTANCE_METHOD');
            const isNotEmpty = !util.isEmpty(method);
            makeRequired(remittanceAddress, isNotEmpty, form);
            // add or remove validator to email based on the value of REMITTANCE_METHOD
            if (method !== 'EMAIL') {
                clearError(form, 'REMITTANCE_ADDRESS');
                model.removeValidator('REMITTANCE_ADDRESS');
            } else if (method === 'EMAIL') {
                addValidatorToEmail(model);
            }
        });

        model.on('change:REMITTANCE_ADDRESS', (m) => {
            const address = m.get('REMITTANCE_ADDRESS');
            const isNotEmpty = !util.isEmpty(address);
            makeRequired(remittanceMethod, isNotEmpty, form);
        });

        // when the date changes from the calendar
        model.on('change:REQUESTED_DUE_DATE', (e) => {
            clearError(form, DUEONDATE);
            // only update the expires on date if the due on date is validate
            if (!isDateBeforeToday(e.get(DUEONDATE))) {
                updateExpirationDate(form, e);
            }
        });

        model.on('change:REQUESTED_EXPIRATION_DATE', () => {
            clearError(form, EXPDATE);
        });

        // when date changes from user input
        form.field(DUEONDATE).$el.on('blur', (e) => {
            // first validate the field
            if (isDateBeforeToday(e.target.value)) {
                addError(form, DUEONDATE, 'RTP.error.greaterThanToday');
                /*
                 * this is needed to reset the due on date calendar; otherwise, the calendar does
                 *  not display correctly when the user reenters this field
                 */
                resetDueOnDate(form);
            } else if (isClearErrorMessage(e, form, DUEONDATE)) {
                clearError(form, DUEONDATE);
            }
        });

        // clear any data errors on focus
        form.field(DUEONDATE).$el.on('focus', (e) => {
            if (isClearErrorMessage(e, form, DUEONDATE)) {
                clearError(form, DUEONDATE);
            }
        });

        // when date changes from user input
        form.field(EXPDATE).$el.on('blur', (e) => {
            // first validate the field
            if (!validExpirationDate(model, e.target.value)) {
                addError(form, EXPDATE, 'RTP.error.greaterThanDueOn');
                /*
                 * this is needed to reset the due on date calendar; otherwise, the calendar does
                 * not display correctly when the user reenters this field
                 */
                updateExpirationDate(form, model, false);
            } else if (isClearErrorMessage(e, form, EXPDATE)) {
                clearError(form, EXPDATE);
            }
        });

        // clear any data errors on focus
        form.field(EXPDATE).$el.on('focus', (e) => {
            if (isClearErrorMessage(e, form, EXPDATE)) {
                clearError(form, EXPDATE);
            }
        });

        useTemplate.$el.on('click', (e) => {
            e.stopImmediatePropagation();
            // trigger event so the rfpEntry can respond
            form.formView.trigger('rfp:useTemplate');
        });

        model.addValidator(
            'DEBTOR_LEI_ID',
            {
                description: model.fieldData.DEBTOR_LEI_ID.fieldLabel,
                exactLength: 20,
            },
        );

        if (!isTemplate) {
            /*
             * hide or show the templateCode and templateDescription
             * based on the state of the checkbox
             */
            saveAsTemplate.$el.on('change', (e) => {
                validateTemplateInfo(e.target.checked);
            });

            validateTemplateInfo(saveAsTemplate.$el.is(':checked'));
        } else {
            const requestedAmount = form.field('REQUESTED_AMOUNT');
            // template  --- when recurring template, make requested amount required
            form.formView.$('#make-recur').on('click', (e) => {
                makeRequired(requestedAmount, e.target.checked, form);
            });
        }

        setupAddressFields(form, model, addressRequired);

        setupPaymentSummarySection();

        setupWarningDialog(model, formView);

        if ((form.formView.state.toUpperCase() === 'MODIFY' || form.formView.state.toUpperCase() === 'VIEW') && model.get('EXPIRES_ON_DUE_DATE') === '1') {
            /**
             * Deferring sending the message as the scheduler section for a recurring Request
             * for Payment may not be fully opened after the policy file is finished processing
             */
            util.defer(() => {
                form.formView.appBus.trigger(
                    'expiresOnDueDate:check',
                    true,
                );
            });
        }
    }
}

export {
    setupAddressFields, allAddressFieldsEmpty, isDateBeforeToday, showField, adjustAddressFields,
    isClearErrorMessage, hasError, showDebtorBirthFields, discountAmountChange, setupWarningDialog,
};
