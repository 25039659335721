import BaseWidget from 'common/uiWidgets/baseWidget/baseWidget';
import locale from '@glu/locale';
import { createMaskedInputView } from 'components/MaskedInput/MaskedInputWrapper';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import secondAccountNumWidgetTmpl from './secondAccountNumWidget.hbs';

export default BaseWidget.extend({
    template: secondAccountNumWidgetTmpl,
    className: 'second-account-num-widget',

    initialize() {
        this.secondAccountValidators = {
            SECOND_ACCOUNTNUMBER: {
                description: locale.get('ACH.payroll.SecondAccountNumber'),
                exists: true,
            },

            ALLOCATION_TYPE: {
                description: locale.get('ACH.payroll.AllocationType'),
                exists: true,
            },

            SECOND_BANKCODE: {
                description: locale.get('ACH.payroll.SecondBankCode'),
                exists: true,
            },

            SECOND_ACCOUNTTYPE: {
                description: locale.get('ACH.payroll.SecondAccountType'),
                exists: true,
            },

            SECOND_ACCOUNT_ALLOCATION: {
                description: locale.get('ACH.payroll.SecondAccountAllocation'),
                exists: true,
            },
        };
        if (this.parentView.parentView) {
            this.listenTo(this.parentView.parentView, 'clearBeneficiaryForm', this.removeSecondaryAccount);
        }
    },

    /**
     * Clears/hides a number of fields related to the secondary account
     */
    removeSecondaryAccount() {
        this.$secondaryAccountFields.hide();
        if (!this.shouldBeProtected) {
            this.$addSecondAccountButton.show();
        }
        this.$accountAllocationFieldContainer.hide();
        this.handleSecondaryRequirementChange(true);
        this.$amount.closest('.input-group').after('<span class="textline-field"></span>')
            .siblings('span.textline-field').remove();

        // remove any stored values from the already filled out fields
        this.clearSecondaryAccountFields();

        this.arrayOfSecondaryAccountFields.forEach((field) => {
            if (field.prop('name') === 'SECOND_ACCOUNTTYPE'
                || field.prop('name') === 'ALLOCATION_TYPE'
                || field.prop('name') === 'SECOND_BANKCODE') {
                /*
                 * set null and trigger a change in accordance with select2
                 * documentation to clear
                 */
                field.val(null).trigger('change');
            } else {
                field.val('');
            }
        });

        this.$secondBankCode.siblings('.lookup-helper-text').remove();
        this.$accountAllocation.val('');
        this.showSecondAccountNumber();
    },

    /**
     * @name showAccountNumber
     * @description displays thhe maskedInput component for the ACCOUNTNUMBER
     */
    showAccountNumber() {
        const { fieldInfo } = this;

        const MaskedInputView = createMaskedInputView({
            initialValue: this.model.get('ACCOUNTNUMBER'),
            cid: this.model.cid,
            name: 'ACCOUNTNUMBER',
            maxLength: fieldInfo.maxLen,
            fieldLabel: fieldInfo.fieldLabel,
            placeholder: fieldInfo.placeholder,
            inputClassList: fieldInfo.cssClass,
            isProtected: fieldInfo.protected,
            isRequired: fieldInfo.mandatory,
            dataBind: true,
        });
        if (this.maskedInputRegion) {
            this.maskedInputRegion.show(new MaskedInputView());
        }
    },

    /**
     * @name showSecondAccountNumber
     * @description displays thhe maskedInput component for the SECOND_ACCOUNTNUMBER
     */
    showSecondAccountNumber() {
        const fieldInfo = this.parentView.model.fieldData.SECOND_ACCOUNTNUMBER;

        const MaskedInputView = createMaskedInputView({
            initialValue: this.model.get('SECOND_ACCOUNTNUMBER'),
            cid: this.model.cid,
            name: 'SECOND_ACCOUNTNUMBER',
            maxLength: fieldInfo.maxLen,
            fieldLabel: fieldInfo.fieldLabel,
            placeholder: fieldInfo.placeholder,
            inputClassList: fieldInfo.cssClass,
            isProtected: fieldInfo.protected,
            isRequired: true,
            dataBind: true,
        });
        if (this.parentView.beneWidget.secondAccountnumberWidgetRegion) {
            this.parentView.beneWidget.secondAccountnumberWidgetRegion
                .show(new MaskedInputView());
        }
    },

    onRender() {
        if (this.state !== 'VIEW') {
            this.showAccountNumber();

            this.$accountAllocation = this.parentView.$('[name="ACCOUNT_ALLOCATION"]');
            this.$accountAllocationFieldContainer = this.parentView.$('[name="ACCOUNT_ALLOCATION"]').closest('.field-container');
            this.$allocationType = this.parentView.$('[name="ALLOCATION_TYPE"]');
            this.$amount = this.parentView.$('[name="AMOUNT"]');
            this.$removeSecondaryAccount = this.parentView.$('[name="REMOVE_SECONDARY_LINK"]');
            this.$secondAccountAllocationFieldContainer = this.parentView.$('[name="SECOND_ACCOUNT_ALLOCATION"]').closest('.field-container');
            this.$secondaryAccountFields = this.parentView.$('.Beneficiary-secondaryAccountFields');
            this.$secondAccountAllocation = this.parentView.$('[name="SECOND_ACCOUNT_ALLOCATION"]');
            this.$secondBankCode = this.parentView.$('[name="SECOND_BANKCODE"]');
            this.$secondAccountType = this.parentView.$('[name="SECOND_ACCOUNTTYPE"]');
            this.$addSecondAccountButton = this.$('[data-hook="addSecondAccount"]');

            this.arrayOfSecondaryAccountFields = [
                this.$secondBankCode,
                this.$allocationType,
                this.$secondAccountType,
                this.$secondAccountAllocation,
            ];

            // account for this widget being used outside the beneficiaryWidget
            if (this.$secondaryAccountFields.get(0) === undefined) {
                this.$secondaryAccountFields = this.$secondBankCode.closest('.row');
            }

            /*
             * if the account related fields are protected, don't allow the user to remove
             * a secondary account
             */
            if (this.shouldBeProtected) {
                this.$removeSecondaryAccount.hide();
            } else {
                this.$removeSecondaryAccount.click(this.removeSecondaryAccount.bind(this));
                this.$addSecondAccountButton.click(this.showExtraFields.bind(this));
            }

            const secondAccountPopulated = this.model.get('SECOND_BANKCODE') !== '';
            if (secondAccountPopulated) {
                this.showExtraFields();
            } else {
                this.removeSecondaryAccount();
            }

            if (this.parentView.parentModel.fieldData.ALLOWSECONDPRACC.value === '1') {
                this.model.on('change:SECOND_BANKCODE', (model, value) => {
                    if (value !== '') {
                        if (!this.parentView.context.reimburse) {
                            this.$secondAccountAllocation.val(this.model.get('SECOND_ACCOUNT_ALLOCATION'));
                            if (!this.$secondaryAccountFields.is(':visible')) {
                                this.showExtraFields();
                            }
                        } else { // for reimburse, remove secondary account fields
                            this.clearSecondaryAccountFields();
                        }
                    } else {
                        this.removeSecondaryAccount();
                    }
                });
            }
        }
    },

    clearSecondaryAccountFields() {
        this.model.set({
            ALLOCATION_TYPE: '',
            ACCOUNT_ALLOCATION: '',
            SECOND_ACCOUNTTYPE: '',
            SECOND_ACCOUNTNUMBER: '',
            SECOND_ACCOUNT_ALLOCATION: '',
            SECOND_BANKADDRLINE1: '',
            SECOND_BANKADDRLINE2: '',
            SECOND_BANKCITY: '',
            SECOND_BANKCODE: '',
            SECOND_BANKNAME: '',
            SECOND_BANKSTATE: '',
        }, { silent: true });
    },

    /**
     * shows needed fields when adding a second account
     */
    showExtraFields() {
        const allocation = this.model.get('ACCOUNT_ALLOCATION') || this.$amount.val();
        this.$secondaryAccountFields.show();
        this.$addSecondAccountButton.hide();
        this.$accountAllocationFieldContainer.show();
        this.handleSecondaryRequirementChange(false);
        this.$accountAllocation.prop('disabled', true);
        this.$amount.closest('.input-group').after('<span class="textline-field"></span>')
            .siblings('span.textline-field').text(locale.get('PAY.totalBeneAmount'));
        this.$accountAllocation.val(allocation);
    },

    /**
     * Adds/removes validators and changes the 'required' status of some fields
     * @param {boolean} removeValidators - Indicates whether or not to add validators
     * or remove them
     */
    handleSecondaryRequirementChange(removeValidators) {
        /*
         * The second account number (masked input widget) may not be visible when the
         * beneficiary widget renders. For that reason we will wait to create the reusable
         * reference until the second account number exists. Since these fields are hidden
         * by default, the use cannot make them visible until the screen is fully loaded.
         */
        if (!this.secondAccountNumWidget && !!this.parentView.beneWidget.$('[name="SECOND_ACCOUNTNUMBER"]')[0]) {
            this.secondAccountNumWidget = this.parentView.beneWidget.$('[name="SECOND_ACCOUNTNUMBER"]');
            this.arrayOfSecondaryAccountFields.push(this.secondAccountNumWidget);
        }

        // add/remove validators based on form state
        if (removeValidators) {
            this.model.removeValidator(Object.keys(this.secondAccountValidators));
        } else {
            this.model.addValidator(this.secondAccountValidators);
        }

        // mark fields as required/not required based on form state
        this.arrayOfSecondaryAccountFields.forEach((field) => {
            field.closest('.field-container').toggleClass('required', !removeValidators);
        });
    },

    templateHelpers() {
        return {
            secondAccount: !this.parentView.context.reimburse
                && Number(applicationConfigParams.getValue('ACH', 'ALLOWSECONDPRACC')),
            shouldBeProtected: this.shouldBeProtected,
            viewMode: this.state === 'VIEW',
        };
    },
});
