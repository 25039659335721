import Layout from '@glu/core/src/layout';
import Dialog from '@glu/dialog';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import ComplexTypes from 'app/administration/collection/user/complexTypes';
import PaymentGroupsView from './paymentType/paymentGroupCollectionView';
import assignPaymentTypesTmpl from './assignPaymentTypes.hbs';

export default Layout.extend({
    template: assignPaymentTypesTmpl,

    regions: {
        paymentTypesRegion: '.payment-types-region',
    },

    initialize(options) {
        const self = this;

        this.modifyMode = options.modifyMode || false;

        if (!options.modifyMode) {
            this.collection = new ComplexTypes(this.collection.filter(child => self.modifyMode
                    || child.hasEntitledType()));
        }

        this.mode = options.mode;
        this.onSave = options.onSave;

        this.dialogTitle = locale.get('administration.assign.payment.types');
        this.modalClass = '';

        this.dialogButtons = [{
            text: locale.get('administration.continue.btn'),
            className: 'btn btn-primary',
            callback: 'submit',
        }, {
            text: locale.get('administration.cancel.btn'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }];
    },

    onRender() {
        this.paymentTypesRegion.show(new PaymentGroupsView({
            collection: this.collection,
            mode: this.mode,
            modifyMode: this.modifyMode,
        }).render());
    },

    cancel() {
        this.appBus.trigger('usercentric:paymentTypes:cancel:reset');
        Dialog.close();
    },

    submit() {
        const self = this;

        if (util.isFunction(self.onSave)) {
            self.onSave();
        }

        Dialog.close();
    },

    /**
     * Check each attribute of a given permission and
     * modify the entitled flag to false if it exists
     *
     * @param permission
     */
    modifyPermission(permission) {
        util.each(permission.attributes, (attributeParam, key) => {
            const attribute = attributeParam;
            if (attribute && attribute.entitled) {
                attribute.entitled = false;
                permission.set(key, attribute);
            }
        });
    },
});
