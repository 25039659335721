var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row\">\n                <div class=\"form-column col-md-12\">\n                    <div class=\"field-container-md field-container\"><strong>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"LBX.AddToLocationSubTitle",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":76},"end":{"line":7,"column":114}}}))
    + "</strong></div>\n                </div>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"allowContinue") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":12},"end":{"line":81,"column":19}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <hr/>\n                <div class=\"row\">\n                    <div class=\"form-column col-md-6\">\n                        <div id=\"lockbox-continue\" class=\"field-container-lg field-container\">\n                            <label class=\"checkbox-inline\">\n                                <input type=\"checkbox\" data-bind=\"model\" name=\"continue\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"LBX.Continue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":76,"column":89},"end":{"line":76,"column":114}}}))
    + "\n                            </label>\n                        </div>\n                    </div>\n                </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <hr/>\n            <div class=\"row\">\n                <div class=\"form-column col-md-6\">\n                    <div id=\"lockbox-continue\" class=\"field-container-lg field-container\">\n                        <label class=\"checkbox-inline\">\n                            <input type=\"checkbox\" data-bind=\"model\" name=\"continue\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.Continue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":88,"column":85},"end":{"line":88,"column":110}}}))
    + "\n                        </label>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row\">\n                <div class=\"form-column col-md-12\">\n                    <div class=\" field-container-xs field-container\">\n                        <button type=\"button\" class=\"btn btn-primary\" data-bind=\"model\" data-action=\"submitLockbox\">\n                            <span class=\"text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.AddToLocation",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":97,"column":47},"end":{"line":97,"column":77}}}))
    + "</span>\n                        </button>\n                    </div>\n                </div>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"add-lockbox\">\n    <form autocomplete=\"off\" data-submit=\"save\">\n        <div class=\"alert-region\" data-region=\"modalAlertRegion\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":10,"column":19}}})) != null ? stack1 : "")
    + "        <div class=\"row\">\n            <div class=\"form-column col-md-6\">\n                <div class=\"field-container-md field-container\">\n                    <label for=\"LOCKBOX_CODE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.LockboxCode",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":46},"end":{"line":14,"column":74}}}))
    + "</label>\n                    <input class=\"form-control \" type=\"text\" maxlength=\"12\" data-bind=\"model\" name=\"LOCKBOX_CODE\" value=\"\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"LOCKBOX_CODE\"></span>\n                </div>\n                <div class=\"field-container-lg field-container\" id=\"LOCKBOX_CODE_INFOLINE\">\n                    <span class=\"textline-field\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.UpTo12",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":49},"end":{"line":19,"column":72}}}))
    + "</span>\n                </div>\n            </div>\n            <div class=\"form-column col-md-6\">\n                <div class=\" field-container-md field-container\">\n                    <label for=\"NAME\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.LockboxName",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":38},"end":{"line":24,"column":66}}}))
    + "</label>\n                    <input class=\"form-control \" type=\"text\" maxlength=\"25\" data-bind=\"model\" name=\"NAME\" value=\"\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"NAME\"></span>\n                </div>\n                <div class=\"field-container-lg field-container\" id=\"NAME_INFOLINE\">\n                    <span class=\"textline-field\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.UpTo25",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":49},"end":{"line":29,"column":72}}}))
    + "</span>\n                </div>\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"form-column col-md-6\">\n                <div id=\"IMAGE_VIEWING\" class=\"field-container-lg field-container\">\n                    <label class=\"checkbox-inline\">\n                        <input type=\"checkbox\" data-bind=\"model\" name=\"IMAGE_VIEWING\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.ImageViewing",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":37,"column":86},"end":{"line":37,"column":115}}}))
    + "\n                    </label>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"IMAGE_VIEWING\"></span>\n                </div>\n                <div id=\"DETAIL_REPORTS\" class=\"field-container-lg field-container\">\n                    <label class=\"checkbox-inline\">\n                        <input type=\"checkbox\" data-bind=\"model\" name=\"DETAIL_REPORTS\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.DetailReports",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":43,"column":87},"end":{"line":43,"column":117}}}))
    + "\n                    </label>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"DETAIL_REPORTS\"></span>\n                </div>\n                <div id=\"REPORT_FLOAT\" class=\"field-container-lg field-container\">\n                    <label class=\"checkbox-inline\">\n                        <input type=\"checkbox\" data-bind=\"model\" name=\"REPORT_FLOAT\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.FloatAvailability",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":49,"column":85},"end":{"line":49,"column":119}}}))
    + "\n                    </label>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"REPORT_FLOAT\"></span>\n                </div>\n            </div>\n            <div class=\"form-column col-md-6\">\n                <div class=\"field-container-lg field-container\">\n                    <label for=\"REMITTERMANAGEBYBANK\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.RemitterNameData",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":56,"column":54},"end":{"line":56,"column":87}}}))
    + "</label>\n                </div>\n                <div id=\"REMITTERMANAGEBYBANK\" class=\"field-container field-container-lg field-container\">\n                    <label class=\"radio-inline\">\n                        <input type=\"radio\" data-bind=\"model\" value=\"Y\" name=\"REMITTERMANAGEBYBANK\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.ByBank",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":60,"column":100},"end":{"line":60,"column":123}}}))
    + "\n                    </label>\n                    <label class=\"radio-inline\">\n                        <input type=\"radio\" data-bind=\"model\" value=\"N\" name=\"REMITTERMANAGEBYBANK\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.ByUser",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":63,"column":100},"end":{"line":63,"column":123}}}))
    + "\n                    </label>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"REMITTERMANAGEBYBANK\"></span>\n                </div>\n            </div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":69,"column":8},"end":{"line":102,"column":15}}})) != null ? stack1 : "")
    + "    </form>\n</div>\n";
},"useData":true});