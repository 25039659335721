import Layout from '@glu/core/src/layout';
import template from './freeFormDetail.hbs';

const FreeFormDetailView = Layout.extend({
    template,

    templateHelpers() {
        return {
            detailText: this.model.get('DESCRIPTION').split('\n'),
        };
    },
});

export default FreeFormDetailView;
