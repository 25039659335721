import locale from '@glu/locale';
import transform from 'common/util/transform';
import store from 'system/utilities/cache';
import GridApi from 'common/dynamicPages/api/grid';
import PrintExportUtil from 'common/util/printExportUtil';
import appConfigParams from 'system/webseries/models/applicationConfiguration';
import ReconciliationReportParent from './reconciliationReportParent';
import detailTmpl from './detail.hbs';

export default ReconciliationReportParent.extend({
    template: detailTmpl,

    initialize() {
        this.inquiryId = (appConfigParams.getValue('CMCIM', 'ALLOWSTOPSINISSUEVOIDFILE') === '1') ? 20631 : 20621;
        this.totalsInquiryId = (appConfigParams.getValue('CMCIM', 'ALLOWSTOPSINISSUEVOIDFILE') === '1') ? 20633 : 20623;
        this.fileExtractModel = store.get('cm-issueVoidReconciliation-fileExtract-model');
    },

    /**
     * Creates an object literal to be used by the template
     * @return {object} the object for the template
     */
    templateHelpers() {
        const self = this;
        return {
            /**
             * Gets the page header
             * @return {string} the page header.
             */
            getPageHeader() {
                return locale.get('CM.FileExtractDetails');
            },

            /**
             * Gets the section header
             * @return {string} the section header.
             */
            getSectionHeader() {
                return `${locale.get('CM.FileID')} ${self.fileExtractModel.get('ID')}`;
            },
        };
    },

    onRender() {
        const requestParameters = {
            inquiryId: this.inquiryId,
            productCode: 'CM',
            functionCode: 'INST',
            typeCode: 'CIMINSTT',
            viewType: `CM-INST-CIMINSTT-${this.inquiryId}`,
        };

        const options = {
            context: {
                serviceName: '/tableMaint',
                actionMode: 'SELECT',
                functionCode: 'INST',
                productCode: 'CM',
                typeCode: `CIMINSTT-${this.inquiryId}`,
            },
            filter: true,
            selector: 'none',
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,
            enableSavedViews: true,
            requestParameters: {
                item: transform.hashToPairs(requestParameters),
            },
            additionalSearchFields: [{
                fieldName: 'CIFILE_EXT_ID',
                operator: '=',
                dataType: 'number',
                fieldValue: [
                    this.fileExtractModel.get('ID'),
                ],
            }],
        };

        this.gridView = GridApi.createServiceGridView(options);
        this.gridRegion.show(this.gridView);
        this.listenTo(this.gridView, 'gridapi:loaded', this.loadTotals);
        this.listenTo(this.gridView.getRows(), 'sync', this.refreshTotals);
    },

    /**
     * returns the user to the summary page
     */
    back() {
        window.history.back();
    },

    print() {
        const options = {
            view: this,
            gridView: this.gridView,
            inquiryId: this.inquiryId,
            title: '',
            format: 'PDF',
        };
        PrintExportUtil.print(options);
    },

    export() {
        const options = {
            view: this,
            gridView: this.gridView,
            inquiryId: this.inquiryId,
            format: 'CSV',
        };
        PrintExportUtil.export(options);
    },
});
