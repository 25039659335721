import Layout from '@glu/core/src/layout';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import CollectionView from '@glu/core/src/collectionView';
import actionsUtil from 'common/util/entitledActions';
import services from 'services';
import dialog from '@glu/dialog';
import scroll from 'common/util/scroll';
import RejectDialog from 'common/dynamicPages/views/rejectDialog';
import alert from '@glu/alerts';
import template from './detailView.hbs';
import NoteForm from './noteForm';
import FreeFormModel from '../models/freeFormModel';
import NoteItemView from './noteItemView';
import NoteCollection from '../noteCollection';
import freeFormDetailTemplate from './freeFormDetail.hbs';
import NoteModal from './noteModal';
import FreeFormDetailView from './freeFormDetail';

const DetailView = Layout.extend({
    template,
    loadingTemplate,

    ui: {
        $submitButton: '[data-hook="getSubmit"]',
    },

    events: {
        'click [data-hook="getCancel"]': 'cancel',
    },

    initialize() {
        this.tnum = store.remove('serviceRequest-actionModel').get('TNUM');
        this.model = new FreeFormModel();
        this.notes = new NoteCollection();
        this.noteForm = new NoteForm({
            collection: this.notes,
            tnum: this.tnum,
        });

        this.listenTo(this.noteForm, 'note:added', this.fetchNotes.bind(this));
        this.listenTo(this.noteForm, 'note:failure', this.requestFailure.bind(this));
    },

    templateHelpers() {
        return {
            headingText: locale.get('serviceRequest.View.Service.Request'),
            hasNotes: this.notes.length > 0,
            buttons: this.getButtons(),
            getStatus: this.model.get('STATUS') ? locale.get(this.model.get('STATUS')) : '',
        };
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.detailsRegion.show(new FreeFormDetailView({
                template: freeFormDetailTemplate,
                model: this.model,
            }));

            if (this.model.get('STATUS') === 'NI') {
                this.renderNoteForm();
            }

            if (this.notes.length > 0) {
                this.renderNoteList();
            }
        } else {
            this.loadRequiredData();
        }
    },

    /**
     * Fetch service reqest model data, entitled actions, and notes associated to this
     * service request from the server
     */
    loadRequiredData() {
        const modelData = this.model.fetch({ tnum: this.tnum });
        const entitledActions = actionsUtil.getEntitledActions(services.serviceRequestFreeForm);
        const notes = this.notes.fetch({ tnum: this.tnum });
        Promise.all([notes, entitledActions, modelData])
            .then(this.receiveData.bind(this))
            .catch(this.requestFailure.bind(this));
    },

    /**
     * Set the notes collection, entitledActions and then render the view again
     * @param {Object} response - server response
     */
    receiveData(response) {
        const [notes, entitledActions] = response;
        this.notes.set(notes);
        this.entitledActions = entitledActions.actionModes;
        this.setHasLoadedRequiredData(true);
        this.render();
    },

    /**
     * Get a collection of notes from the server
     */
    fetchNotes() {
        return this.notes.fetch({ tnum: this.tnum })
            .then(this.handleNotesSuccess.bind(this));
    },

    /**
     * Set the notes collection and when the collection has only one item, render the view
     * @param {Array} notes - An array of note models or attributes
     */
    handleNotesSuccess(notes) {
        this.notes.set(notes);
        /*
         * If this is the first note in the collection, render the whole view again as the
         * panel, along with the collection view, wasn't rendered in the DOM during the previous
         * render cycle
         */
        if (this.notes.length === 1) {
            this.render();
        }
    },

    /**
     * Show the noteForm view in the noteFormRegion
     */
    renderNoteForm() {
        this.noteFormRegion.show(this.noteForm);
    },

    /**
     * Show a new collection in the noteListRegion, using the NoteItemView
     */
    renderNoteList() {
        this.noteListRegion.show(new CollectionView({
            itemView: NoteItemView,
            collection: this.notes,
        }));
    },

    /**
     * Show the error response in the alertRegion and scroll to the region
     * @param {Object} response - server response
     */
    requestFailure(response) {
        const { message } = response.responseJSON;
        this.alertRegion.show(alert.negative(message.join(' ')));
        scroll.scrollToRegion(this.alertRegion);
    },

    /**
     * Get a list of buttons available in the template
     * @returns {array}
     */
    getButtons() {
        const buttons = [
            {
                hidden: !actionsUtil.hasEntitledAction(
                    actionsUtil.ACTIONS.approve,
                    this.entitledActions,
                ),
                dataAction: 'approve',
                classes: 'btn-primary',
                label: locale.get('action.approve'),
            },
            {
                hidden: !actionsUtil.hasEntitledAction(
                    actionsUtil.ACTIONS.unapprov,
                    this.entitledActions,
                ),
                dataAction: 'unapprove',
                classes: 'btn-primary',
                label: locale.get('action.UNAPPROVE'),
            },
            {
                hidden: !actionsUtil.hasEntitledAction(
                    actionsUtil.ACTIONS.reject,
                    this.entitledActions,
                ),
                dataAction: 'reject',
                classes: 'btn-secondary',
                label: locale.get('action.reject'),
            },
            {
                hidden: !actionsUtil.hasEntitledAction(
                    actionsUtil.ACTIONS.modify,
                    this.entitledActions,
                ),
                dataAction: 'modify',
                classes: 'btn-secondary',
                label: locale.get('action.modify'),
            },
            {
                hidden: !actionsUtil.hasEntitledAction(
                    actionsUtil.ACTIONS.needInfo,
                    this.entitledActions,
                ),
                dataAction: 'needInfo',
                classes: 'btn-secondary',
                label: locale.get('action.needInfo'),
            },
            {
                hidden: !actionsUtil.hasEntitledAction(
                    actionsUtil.ACTIONS.complete,
                    this.entitledActions,
                ),
                dataAction: 'complete',
                classes: 'btn-secondary',
                label: locale.get('action.complete'),
            },
            {
                dataAction: 'cancel',
                classes: 'btn-secondary',
                label: locale.get('common.cancel'),
            },
        ];

        return buttons.filter(button => !button.hidden)
            .filter(button => this.filterByState(button, this.model.get('STATUS')));
    },

    /**
     * Base on the button action and the state of the service request, return
     * true for buttons
     * that are available and false for buttons that are not
     * @param {Object} button - button object
     * @param {string} currentState - state of the service request
     * @returns {boolean}
     */
    filterByState(button, currentState) {
        switch (button.dataAction) {
        case 'approve':
        case 'reject':
            return ['EN', 'NI'].indexOf(currentState) > -1;
        case 'unapprove':
        case 'complete':
            return currentState === 'AP';
        case 'modify':
        case 'needInfo':
            return currentState === 'EN';
        default:
            return true;
        }
    },

    /**
     * Exeute the approve action
     */
    approve() {
        this.executeActionThenReturn(actionsUtil.ACTIONS.approve);
    },

    /**
     * Execute the unapprove action
     */
    unapprove() {
        this.executeActionThenReturn('UNAPPROVE');
    },

    /**
     * Display the reject modal and then listen for the reject:dialog:save event
     */
    reject() {
        const rejectModal = new RejectDialog({
            triggerEventOnly: true,
        });
        this.listenToOnce(
            rejectModal,
            'reject:dialog:save',
            this.handleRejectSave.bind(this, actionsUtil.ACTIONS.reject),
        );
        dialog.custom(rejectModal);
    },

    /**
     * Execute the reject action
     * @param {string} eventName
     * @param {Object} rejectObject - Reject object from the reject modal
     */
    handleRejectSave(eventName, rejectObject) {
        this.executeActionThenReturn(actionsUtil.ACTIONS.reject, [rejectObject]);
    },

    /**
     * Set the actionmodel and the return route in the store and then
     * navigate to the modify route
     */
    modify() {
        store.set('serviceRequestReturnRoute', this.options.returnRoute);
        store.set('serviceRequest-actionModel', this.model);
        this.navigateTo('SERVICEREQUEST/serviceRequestDetailModify');
    },

    /**
     * Show the note modal and then listen for the note:added event before
     * submitting the needInfo action to the server
     */
    needInfo() {
        const noteModal = new NoteModal({
            tnum: this.tnum,
        });
        this.listenToOnce(
            noteModal,
            'note:added',
            this.executeActionThenReturn.bind(this, actionsUtil.ACTIONS.needInfo),
        );
        dialog.custom(noteModal);
    },

    /**
     * Execute the complate action
     */
    complete() {
        this.executeActionThenReturn(actionsUtil.ACTIONS.complete);
    },

    /**
     * Navigate to the returnRoute specified in the options during initialization
     */
    cancel() {
        this.navigateTo(this.options.returnRoute);
    },

    /**
     * Send a request to the server to execute the specified action
     * @param {string} action - action name
     * @param {Array} optionalData  - additional data to add to the request
     */
    executeAction(action, optionalData) {
        return actionsUtil.executeEntitledAction(
            services.serviceRequestFreeForm,
            action,
            this.getActionRequestData(optionalData),
        ).then(actionsUtil.handleSuccess.bind(this, this.options.contextKey, action))
            .catch(actionsUtil.handleError.bind(this, this.options.contextKey, action));
    },

    /**
     * Send a request to the server to execute the specified action and then navigate
     * to the return route
     * @param {string} action - action name
     * @param {Array} optionalData - additional data to add to the request
     */
    executeActionThenReturn(action, optionalData) {
        return this.executeAction(action, optionalData)
            .then(this.cancel.bind(this));
    },

    /**
     * Build request data based on the tnum as well as optional data
     * @param {Array} optionalDataArray - additional data to add to the request
     * @returns {Array}
     */
    getActionRequestData(optionalDataArray = []) {
        const item = [
            ...optionalDataArray,
            {
                name: 'TNUM',
                value: this.tnum,
            },
        ];
        return {
            item,
        };
    },
});

export default DetailView;
