import util from '@glu/core/src/util';
import Dialog from '@glu/dialog';
import locale from '@glu/locale';
import alert from '@glu/alerts';
import BaseWidget from 'common/uiWidgets/baseWidget/baseWidget';
import Model from '@glu/core/src/model';
import config from 'system/configuration';
import service from './service';
import template from './documentWidget.hbs';

const DOCUMENT_COUNT = 'DOCUMENT_COUNT';

export default BaseWidget.extend({
    template,
    className: 'ui-widget',

    ui: {
        $addDocument: '[data-hook="getAddDocument"]',
        $childRegion: '[data-region="childGridRegion"]',
        $noDocMessage: '[data-hook="getAddDocumentInfo"]',
    },

    events: {
        'click @ui.$addDocument': 'openDocumentModalFromDOM',
    },

    initialize(options) {
        // Call base to init model, parentModel, readyState, fieldName, etc.
        BaseWidget.prototype.initialize.call(this, options);

        // set up context for getting the child model
        this.context = util.extend({}, this.context, this.parentModel.context);

        if (this.context.typeCode) {
            this.context.typeCode = `R${this.context.typeCode}`;
        }

        // Dynamic import to resolve circular references in MDF
        this.gridImportPromise = import('common/dynamicPages/api/grid').then(({ default: gridApi }) => {
            this.gridApi = gridApi;
        });

        const childJSON = service.getChildObject(this.context);
        this.docTypes = util.findWhere(childJSON.fieldInfoList, { name: 'DOC_DOCTYPE1' })
            .choices.reduce((acc, { name, value }) => {
                acc[name] = value;
                return acc;
            }, {});

        this.addParentModelEvents();
    },

    addParentModelEvents() {
        // Listens for a call from a policy file, so we keep the data access in the widget
        this.listenTo(this.parentModel, 'deleteDocs', this.deleteRemitDocs);
    },

    onRender() {
        this.dealWithGrid();
        return this;
    },

    deleteRemitDocs() {
        // Call the deleteAll endpoint
        service.deleteAllDocuments().then(() => {
            this.ui.$childRegion.hide();
        });

        // Remove the grid and references.
        this.gridView = undefined;
        this.childGridRegion.reset();

        // Reset the widget state
        this.ui.$noDocMessage.show();
    },

    /**
     * gets the context object for the Document Modal
     * @param {Backbone.Model} [model] Optional model for View/Edit mode
     * @param {string} [state]
     * @returns {{context: context, hideButtons: boolean, gridApi: *,
     * viewType: string, isChild: boolean, isNonBatchChild: boolean}}
     */
    getModalContext(model, state = 'view') {
        const { context } = this;
        return {
            context,
            model,
            state,
            hideButtons: true,
            gridApi: this.gridApi,
            viewType: 'modal',
            isChild: true,
        };
    },

    /**
     * returns the Modal view
     * @param {Backbone.Model} [model]
     * @param {string} [action]
     */
    getModalForm(model, action = 'view') {
        if (this.activeModalView) {
            return this.activeModalView;
        }

        // Wait on gridImport for the getModalContext call, which returns gridApi
        return Promise.all([
            import('common/dynamicPages/api/view'),
            this.gridImportPromise,
        ]).then(([{ default: pageApi }]) => {
            const modalContext = this.getModalContext(model, action.toLowerCase());
            return pageApi.page.get(modalContext);
        });
    },

    /**
     * opens the glu dialog with the appropriate title and buttons
     */
    openModal() {
        Dialog.activeModal = false;
        this.activeModalView.dialogButtons = this.dialogButtons;
        this.activeModalView.dialogTitle = locale.get('RTGS.INST.RFEDWIRE.REMIT.MODAL.TITLE');
        Dialog.open(this.activeModalView);
    },

    getParentDocCount() {
        return parseFloat(this.parentModel.get(DOCUMENT_COUNT) || 0);
    },

    /**
     * Update the document count on the model.
     * @param {Number} amount
     */
    updateParentDocCount(amount = 0) {
        const newVal = this.getParentDocCount() + amount;
        this.parentModel.set(DOCUMENT_COUNT, newVal.toString());
    },

    openDocumentModalFromDOM() {
        this.openDocumentModal('INSERT');
    },

    /**
     * opens up a modal containing the document page
     * @param {string} action
     * @param {Object} modelParam
     */
    openDocumentModal(action, modelParam) {
        let model = modelParam;

        if (model) {
            // Build a new model so we don't impact the grid.
            model = new Model(modelParam.attributes);
            // Copy over anything the MDF might need.
            Object.keys(modelParam).forEach((key) => {
                if (model[key] === undefined) {
                    model[key] = modelParam[key];
                }
            });
        }

        if (this.activeModalView) {
            return;
        }

        const useAction = action === 'INSERT' ? 'SELECT' : action;

        this.dialogButtons = [];
        // set up buttons for modal
        if (useAction.toLowerCase() !== 'view') {
            this.dialogButtons.push({
                text: locale.get('common.save'),
                className: 'btn btn-primary',
                callback: util.bind(this.saveDocumentModal, this),
            });
        }
        this.dialogButtons.push({
            text: locale.get('common.cancel'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        });

        this.getModalForm(model, useAction).then((activeModalView) => {
            this.activeModalView = activeModalView;
            this.openModal();

            // Add listener for cancel
            this.listenTo(this.activeModalView, 'cancel', this.cancelDocumentModal);

            // Add listener for save complete
            this.listenTo(this.activeModalView, 'save', this.saveDocumentModal);
            // TODO: Add failure listener/handler
        });
    },

    /**
     * closes the modal, if there is one
     */
    closeDocumentModal() {
        if (!this.activeModalView) {
            return;
        }

        /*
         * Nothing happening so set it back to a "resolved" value
         * this.latestModalPromise = true;
         */

        this.stopListening(this.activeModalView);
        this.activeModalView = undefined;
        Dialog.close();
    },

    cancelDocumentModal() {
        this.closeDocumentModal();
    },

    onBeforeClose() {
        // Don't allow the modal to persist if we leave the page.
        this.closeDocumentModal();
    },

    /**
     * @return {Number}
     */
    getNextSequence() {
        // If there is a gridView, the wrapper now has the nextSeqNum
        if (this.gridView && this.gridView.wrapper) {
            return this.gridView.wrapper.nextSeqNum || 1;
        }

        return 1;
    },

    /**
     * saves the document and closes the modal
     */
    saveDocumentModal() {
        if (!this.activeModalView.model.isValid()) {
            return;
        }

        const isEdit = !util.isEmpty(this.activeModalView.model.get('WORKSEQNUMBER'));

        this.activeModalView.model.set('BATCHSEQNUM', this.parentModel.get('BATCHSEQNUM'));

        service.saveDocument(this.activeModalView.model, this.getNextSequence())
            .then(() => {
                if (!isEdit) {
                    this.updateParentDocCount(1);
                }
                this.dealWithGrid();
                // TODO, any "successful" errors to handle?
                this.closeDocumentModal();
            }, () => {
                // TODO - Add region to modal to Show error alert.
            });
    },

    addModalAlertRegion(modal) {
        // TODO - Error handling in the modal
        return modal;
    },

    showModalAlert(alertView) {
        // TODO - Error handling in the modal
        return alertView;
    },

    dealWithGrid() {
        // Always toggle the views
        const hasDocs = this.getParentDocCount() !== 0;
        this.ui.$noDocMessage.toggle(!hasDocs);
        this.ui.$childRegion.toggle(hasDocs);

        if (!this.gridView) {
            // No Grid, create one.
            this.addGrid(this);
        } else {
            // Or refresh
            this.gridView.refreshGridData();
        }
    },

    /**
     * Admin uses a common payments URL, so we need to get the correct one.
     * @return {string}
     */
    getServiceName() {
        return config.isAdmin() ? 'adminPayment/listView/payments' : '/payment/fedwire';
    },

    /**
     * Get different context keys for admin or user
     * @param {string} regularKey
     * @param {string} adminKey
     * @return {string}
     */
    getModelKey(regularKey, adminKey) {
        const useKey = config.isAdmin() ? adminKey : regularKey;
        return this.parentModel.get(useKey);
    },

    /**
     * Context only for grid data, not for MDF.
     * @return {{actionData: {subType: string, productCode: (*|string),
     * functionCode: (*|string), typeCode: (*|string), viewId: number},
     * childActionData: null, serviceName: (*|String), keyList: string[],
     * actionMode: string, subType: string, entryMethod: *, entryClass: *,
     * PARENTENTRYMETHOD: string, viewId: number}}
     */
    getDataContext() {
        const model = this.parentModel;
        const data = {
            // Add parent context to solve getCacheName issues
            containerContext: this.context,

            actionData: {
                subType: '*',
                productCode: this.getModelKey('PRODUCTCODE', 'PRODUCTCODE'),
                functionCode: this.getModelKey('FUNCTIONCODE', 'FUNCTIONCODE'),
                typeCode: this.getModelKey('TYPECODE', 'TYPECODE'),
                viewId: 19101,
            },

            childActionData: null,
            serviceName: this.getServiceName(),

            keyList: [
                'TNUM',
            ],

            actionMode: 'view',
            subType: '*',
            entryClass: model.get('ENTRYCLASS'),
            PARENTENTRYMETHOD: model.get('ENTRYMETHOD'),
            viewId: 19101,
        };

        if (config.isAdmin()) {
            util.extend(
                data,
                {
                    keyList: [
                        'TNUM',
                        'FUNCTIONCODE',
                        'PRODUCTCODE',
                        'TYPECODE',
                        'SUBTYPE',
                    ],
                },
            );
        }

        return data;
    },

    /**
     * @return {{item: {name: string, value: *}[]}}
     */
    getDataParentKeys() {
        const fields = ['TNUM'];
        if (config.isAdmin()) {
            fields.push(
                'FUNCTIONCODE',
                'PRODUCTCODE',
                'TYPECODE',
            );
        }

        const data = {
            item: fields.map(field => ({
                name: field,
                value: this.parentModel.get(field),
            })),
        };

        if (config.isAdmin()) {
            data.item.push({
                name: 'SUBTYPE',
                value: '*',
            });
        }

        return data;
    },

    /**
     * The Document grid requires context that is not the same as the parent, so
     * we have to do some work.
     * @return {{enableRowContextButtonCallbacks: boolean, enableSavedViews: boolean,
     * canSave: boolean, canSetDefault: boolean, hideButtons: boolean, isInquiry: boolean,
     * isChild: boolean, editableChildGrid: boolean, draggable: boolean,
     * context: (*|{actionData: {subType: string, productCode: (*|string),
     * functionCode: (*|string), typeCode: (*|string), viewId: number},
     * childActionData: null, serviceName: (*|String), keyList: string[],
     * actionMode: string, subType: string, entryMethod: *, entryClass: *,
     * PARENTENTRYMETHOD: string, viewId: number}), parentState: string,
     * parentKey: (*|{item: {name: string, value: *}[]}),
     * customFormatters: {fieldName: string, format: (function(*): *)}[],
     * selector: string, selectAndProtectAll: boolean, lockedFields: string[]}}
     */
    generateGridData() {
        // TODO: There should be a better way than manipulating the parentState.
        const newParentState = this.parentView.state.toLowerCase() === 'view'
            ? 'SELECT' : this.parentView.state;

        return {
            enableRowContextButtonCallbacks: true,
            enableSavedViews: false,
            canSave: false,
            canSetDefault: false,
            hideButtons: true,
            isInquiry: false,
            isChild: true,
            editableChildGrid: false,
            draggable: false,
            context: this.getDataContext(),
            parentState: newParentState,
            parentKey: this.getDataParentKeys(),
            batchSeqNum: this.parentModel.get('BATCHSEQNUM'),
            customFormatters: [{
                fieldName: 'DOC_DOCTYPE1',
                format: value => this.docTypes[value],
            }],

            selector: 'none',
            selectAndProtectAll: true,

            lockedFields: [
                '',
            ],
        };
    },

    addGrid() {
        // Need to wait on the promise before we can access gridApi
        this.gridImportPromise.then(() => {
            this.gridView = this.gridApi.createServiceGridView(this.generateGridData());
            this.childGridRegion.show(this.gridView);

            // Add grid row action handlers
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
            /*
             * listen to grid:available for filter and column width changes
             * ListView.prototype.setupGridAvailableListener.call(this);
             */
        });
    },

    /**
     * Handle actions cell operations for the document grid
     * @param {Object} options
     */
    gridRowAction(options) {
        // TODO - use constants here?
        const action = options.action.toUpperCase();

        switch (action) {
        case 'DELETE':
            this.destroyAndRefresh([options.model]);
            break;
        case 'SELECT':
            this.openDocumentModal('VIEW', options.model);
            break;
        default:
            /*
             * SELECT and MODIFY
             * Defaults to view.
             */
            this.openDocumentModal(action, options.model);
            break;
        }
        return Promise.resolve();
    },

    /**
     * Delete selected document(s) in the grid or provide error messaging.
     * @param {Array} childArray
     */
    destroyAndRefresh(childArray) {
        const [firstChild] = childArray;
        const items = childArray.map(child => ({
            item: [{
                name: child.childSequenceKey.toUpperCase(),
                value: child.childNumber,
            },
            {
                name: 'BATCHSEQNUM',
                value: this.parentModel.get('BATCHSEQNUM'),
            },
            ],
        }));
        const data = {
            grids: [{
                name: firstChild.childGridKey,
                items,
            }],
        };

        service.deleteDocument(data).then(() => {
            const docType = this.docTypes[childArray[0].get('DOC_DOCTYPE1')];
            const docIdNum = childArray[0].get('DOC_DOCIDNUM1');
            this.renderMessage(locale.get('RTGS.INST.RFEDWIRE.success.deleteMessage', docType, docIdNum));

            this.updateParentDocCount(-1);
            if (this.getParentDocCount() < 1) {
                this.ui.$noDocMessage.show();
                this.ui.$childRegion.hide();
            }
            this.gridView.refreshGridData(false, true);
        }, () => {
            this.renderMessage(locale.get('common.delete.failed'), 'error');
            this.gridView.refreshGridData(false, true);
        });
    },

    /**
     * @method renderMessage
     * @param {string} message
     * @param {string} type
     * renders alerts to the parent MDF after updates to the document grid
     */
    renderMessage(message, type) {
        const alertType = type || 'info';

        if (message) {
            this.widgetAlertRegion.show(
                alert[alertType](message),
                {
                    canDismiss: true,
                },
            );
        } else {
            this.widgetAlertRegion.reset();
        }
    },

    /**
     * @return {Object}
     */
    templateHelpers() {
        return {
            isNotView: this.parentView.state !== 'view',
            hasDocs: this.getParentDocCount() > 0,
        };
    },
});
