/* istanbul ignore file */
import { buttonReset } from '../../helpers.styles';

export const styles = (theme) => {
  const {
    palette: {
      primary,
      text
    },
    typography
  } = theme;
  return ({
    chipsWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: -24
    },
    hideToggle: {
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    hideToggleWrapper: {
      margin: [0, 24]
    },
    label: {
      fontSize: 12,
      fontWeight: typography.fontWeightHeavy,
      textTransform: 'uppercase'
    },
    wrapper: {
      ...typography,
      '& $hideToggle': {
        fontFamily: '"Roboto Condensed", "Helvetica", "Arial", "sans-serif"'
      },
      '& button': {
        ...buttonReset,
        color: primary.normal
      },
      color: text.midGray || '#6E6E6E',
      display: 'flex'

    }
  });
};

export default styles;
