var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"form-inline\">\n    <input type=\"hidden\" name=\"field-name\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":2,"column":50},"end":{"line":2,"column":58}}}) : helper)))
    + "\"/>\n    <div class=\"search-input-wrapper\">\n        <label for=\"field-value\" class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"searchValue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":49},"end":{"line":4,"column":73}}}))
    + "</label>\n                <input type=\"hidden\" id=\"field-value\" name=\"field-value\" class=\"form-control\">\n        <button type=\"button\" class=\"close\"  data-control=\"removeFilter\"><span class=\"icon-close\"></span></button>\n    </div>\n    <button type=\"submit\" name=\"submit\" class=\"btn btn-default\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"apply",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":64},"end":{"line":8,"column":82}}}))
    + "</button>\n</form>\n";
},"useData":true});