import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import PayeeAccountCollection from 'app/smbBillManagement/collections/payeeAccounts';
import payeeAccountTmpl from './payeeAccount.hbs';

export default ItemView.extend({
    initialize() {
        this.collection = new PayeeAccountCollection(
            [],
            {
                VENDORID: +this.model.get('VENDOR_ID'),
            },
        );

        this.listenTo(
            this.paymentAccount,
            {
                'change:FROMACCTTYPE': this.fetchAccountList,
            },
        );

        this.listenTo(
            this.collection,
            {
                sync: this.updateSelect,
            },
        );
    },

    className: 'payee-account-cell form-group has-validator',
    template: payeeAccountTmpl,

    templateHelpers() {
        return {
            id: this.model.cid,
        };
    },

    events: {
        'change input': 'updateCachedModel',
    },

    updateCachedModel() {
        const model = this.collection.get(this.$('input').select2('val'));
        this.$('input').data('accountModel', model);
        // run validator
        this.isValid();

        // trigger event to let the parent know change happened
        this.$el.trigger('update', this.$('input').select2('val') !== '');
    },

    fetchAccountList() {
        this.collection.fetch({
            FROMACCTTYPE: this.paymentAccount.get('FROMACCTTYPE'),
        });
    },

    getPlaceholder() {
        let placeholder = '';
        const type = this.paymentAccount.getAccountType();

        if (this.collection.length > 0) {
            placeholder = `Select ${type} Account`;
        } else {
            placeholder = `${type} Unavailable`;
        }

        return placeholder;
    },

    updateSelect() {
        // make sure to clear out the old one, otherwise validation breaks
        this.$('input').comboBox('destroy');
        this.$('input').val('');
        this.$('input').comboBox({
            placeholder: this.getPlaceholder(),
            data: this.collection.getComboBoxArray(),
        });

        const primaryAccount = this.collection.getPrimaryAccount();
        if (primaryAccount) {
            this.$('input').select2('val', primaryAccount.get('KEY_FIELD'));
        }

        /*
         * if the collection is empty, the whole row should be disabled
         * if the collection is not empty BUT there is no selection - the row should
         * be disabled except for this control
         */
        this.$('input').select2('enable', this.collection.length > 0);

        // without defer, the parent doesn't get this event on initial render
        util.defer((context) => {
            context.$el.trigger('update', context.$('input').select2('val') !== '');
        }, this);
    },

    onRender() {
        this.updateSelect();
        this.$el.data('view', this);
    },

    isValid() {
        const value = this.$('input').select2('val');
        let isValid = true;

        if (!value) {
            isValid = false;
        }

        this.$el.toggleClass('has-error', !isValid);

        return isValid;
    },
});
