var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"loans.back.to.previous",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":144},"end":{"line":4,"column":179}}}))
    + "</span></a>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <div class=\"text-group field-container-lg field-container\">\n                                                <label for=\"POSSIBLEDUPLICATEFLAG_DESCRIPTION\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.POSSIBLEDUPLICATEFLAG.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":95},"end":{"line":31,"column":139}}}))
    + "</label>\n                                                <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"POSSIBLEDUPLICATEFLAG_DESCRIPTION") || (depth0 != null ? lookupProperty(depth0,"POSSIBLEDUPLICATEFLAG_DESCRIPTION") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"POSSIBLEDUPLICATEFLAG_DESCRIPTION","hash":{},"data":data,"loc":{"start":{"line":32,"column":79},"end":{"line":32,"column":116}}}) : helper)))
    + "</p>\n                                        </div>\n                                        <div class=\"text-group field-container-lg field-container\">\n                                                <label for=\"DUPLICATEREASON\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.DUPLICATEREASON.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":35,"column":77},"end":{"line":35,"column":115}}}))
    + "</label>\n                                                <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"DUPLICATEREASON") || (depth0 != null ? lookupProperty(depth0,"DUPLICATEREASON") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"DUPLICATEREASON","hash":{},"data":data,"loc":{"start":{"line":36,"column":79},"end":{"line":36,"column":98}}}) : helper)))
    + "</p>\n                                        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <div class=\"text-group field-container-lg field-container\">\n                                                <label for=\"credit-value-date\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.PaymentMustBeApprovedBy",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":46,"column":79},"end":{"line":46,"column":119}}}))
    + ": </label>\n                                                <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"CUTOFF_INFO") || (depth0 != null ? lookupProperty(depth0,"CUTOFF_INFO") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"CUTOFF_INFO","hash":{},"data":data,"loc":{"start":{"line":47,"column":79},"end":{"line":47,"column":94}}}) : helper)))
    + "</p>\n                                        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasInvoiceNumber") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":20},"end":{"line":66,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasStandardAmount") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":20},"end":{"line":72,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPrincipalAmount") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":20},"end":{"line":78,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasInterestAmount") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":20},"end":{"line":84,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasEscrowAmount") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":20},"end":{"line":90,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasOtherAmount") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":20},"end":{"line":96,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"savedOptions") : depth0),{"name":"each","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":40},"end":{"line":102,"column":49}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"text-group field-container-lg field-container\">\n                            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.InvoiceNumber",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":63,"column":35},"end":{"line":63,"column":65}}}))
    + ": </label>\n                            <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"INVOICENUM") || (depth0 != null ? lookupProperty(depth0,"INVOICENUM") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"INVOICENUM","hash":{},"data":data,"loc":{"start":{"line":64,"column":59},"end":{"line":64,"column":73}}}) : helper)))
    + "</p>\n                        </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"text-group field-container-lg field-container\">\n                            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.LOANS.OPTION.STANDARD",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":69,"column":35},"end":{"line":69,"column":74}}}))
    + ": </label>\n                            <p class=\"form-control-static\">"
    + alias2(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"STANDARD_AMOUNT") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":70,"column":59},"end":{"line":70,"column":85}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"DEBIT_CURRENCY") || (depth0 != null ? lookupProperty(depth0,"DEBIT_CURRENCY") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"DEBIT_CURRENCY","hash":{},"data":data,"loc":{"start":{"line":70,"column":86},"end":{"line":70,"column":104}}}) : helper)))
    + "</p>\n                        </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"text-group field-container-lg field-container\">\n                            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.LOANS.OPTION.PRINCIPAL",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":75,"column":35},"end":{"line":75,"column":75}}}))
    + ": </label>\n                            <p class=\"form-control-static\">"
    + alias2(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"PRINCIPAL_AMOUNT") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":76,"column":59},"end":{"line":76,"column":86}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"DEBIT_CURRENCY") || (depth0 != null ? lookupProperty(depth0,"DEBIT_CURRENCY") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"DEBIT_CURRENCY","hash":{},"data":data,"loc":{"start":{"line":76,"column":87},"end":{"line":76,"column":105}}}) : helper)))
    + "</p>\n                        </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"text-group field-container-lg field-container\">\n                            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.LOANS.OPTION.INTEREST",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":81,"column":35},"end":{"line":81,"column":74}}}))
    + ": </label>\n                            <p class=\"form-control-static\">"
    + alias2(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"INTEREST_AMOUNT") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":82,"column":59},"end":{"line":82,"column":85}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"DEBIT_CURRENCY") || (depth0 != null ? lookupProperty(depth0,"DEBIT_CURRENCY") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"DEBIT_CURRENCY","hash":{},"data":data,"loc":{"start":{"line":82,"column":86},"end":{"line":82,"column":104}}}) : helper)))
    + "</p>\n                        </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"text-group field-container-lg field-container\">\n                            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.LOANS.OPTION.ESCROW",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":87,"column":35},"end":{"line":87,"column":72}}}))
    + ": </label>\n                            <p class=\"form-control-static\">"
    + alias2(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"ESCROW_AMOUNT") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":88,"column":59},"end":{"line":88,"column":83}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"DEBIT_CURRENCY") || (depth0 != null ? lookupProperty(depth0,"DEBIT_CURRENCY") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"DEBIT_CURRENCY","hash":{},"data":data,"loc":{"start":{"line":88,"column":84},"end":{"line":88,"column":102}}}) : helper)))
    + "</p>\n                        </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"text-group field-container-lg field-container\">\n                            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.LOANS.OPTION.OTHER",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":93,"column":35},"end":{"line":93,"column":71}}}))
    + ": </label>\n                            <p class=\"form-control-static\">"
    + alias2(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"OTHER_AMOUNT") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":94,"column":59},"end":{"line":94,"column":82}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"DEBIT_CURRENCY") || (depth0 != null ? lookupProperty(depth0,"DEBIT_CURRENCY") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"DEBIT_CURRENCY","hash":{},"data":data,"loc":{"start":{"line":94,"column":83},"end":{"line":94,"column":101}}}) : helper)))
    + "</p>\n                        </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <div class=\"text-group field-container-lg field-container\">\n                                                        <label id=\"amount-label\" for=\"credit-amount\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":99,"column":101},"end":{"line":99,"column":110}}}) : helper)))
    + ": </label>\n                                                        <p class=\"form-control-static\">"
    + alias4(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"optionAmount") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":100,"column":87},"end":{"line":100,"column":110}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"DEBIT_CURRENCY") || (depth0 != null ? lookupProperty(depth0,"DEBIT_CURRENCY") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DEBIT_CURRENCY","hash":{},"data":data,"loc":{"start":{"line":100,"column":111},"end":{"line":100,"column":129}}}) : helper)))
    + "</p>\n                                                </div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <div class=\"text-group field-container-lg field-container\">\n                                            <label for=\"credit-amount\">"
    + alias1(container.lambda(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"getAmountText")), depth0))
    + ": </label>\n                                            <p class=\"form-control-static\">"
    + alias1(lookupProperty(helpers,"amount").call(alias2,(depth0 != null ? lookupProperty(depth0,"DEBIT_AMOUNT") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":106,"column":75},"end":{"line":106,"column":98}}}))
    + " "
    + alias1(((helper = (helper = lookupProperty(helpers,"DEBIT_CURRENCY") || (depth0 != null ? lookupProperty(depth0,"DEBIT_CURRENCY") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias2,{"name":"DEBIT_CURRENCY","hash":{},"data":data,"loc":{"start":{"line":106,"column":99},"end":{"line":106,"column":117}}}) : helper)))
    + "</p>\n                                        </div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button class=\"btn "
    + alias4(((helper = (helper = lookupProperty(helpers,"btnClass") || (depth0 != null ? lookupProperty(depth0,"btnClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnClass","hash":{},"data":data,"loc":{"start":{"line":144,"column":39},"end":{"line":144,"column":51}}}) : helper)))
    + "\"\n                        data-action=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"btnAction") || (depth0 != null ? lookupProperty(depth0,"btnAction") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnAction","hash":{},"data":data,"loc":{"start":{"line":145,"column":37},"end":{"line":145,"column":50}}}) : helper)))
    + "\"\n                        name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"btnAction") || (depth0 != null ? lookupProperty(depth0,"btnAction") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnAction","hash":{},"data":data,"loc":{"start":{"line":146,"column":30},"end":{"line":146,"column":43}}}) : helper)))
    + "\"\n                        type=\"button\">\n                        <span class=\"txt\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"btnText") || (depth0 != null ? lookupProperty(depth0,"btnText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnText","hash":{},"data":data,"loc":{"start":{"line":148,"column":42},"end":{"line":148,"column":53}}}) : helper)))
    + "</span>\n                    </button>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-secondary\" data-action=\"cancel\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":153,"column":85},"end":{"line":153,"column":111}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideReturnButton") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":6,"column":15}}})) != null ? stack1 : "")
    + "    <h1 class=\"landing-header\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"getTitle") || (depth0 != null ? lookupProperty(depth0,"getTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getTitle","hash":{},"data":data,"loc":{"start":{"line":7,"column":31},"end":{"line":7,"column":43}}}) : helper)))
    + "</h1>\n\n    <div class=\"amount-wrapper\">\n        <p class=\"amount-label-text\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.paymentTotal",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":37},"end":{"line":10,"column":69}}}))
    + "</p>\n        <span class=\"amount-value-text payment-total\" data-hook=\"getSummaryAmountTotal\">"
    + alias4(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"DEBIT_AMOUNT") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":11,"column":88},"end":{"line":11,"column":111}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"DEBIT_CURRENCY") || (depth0 != null ? lookupProperty(depth0,"DEBIT_CURRENCY") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DEBIT_CURRENCY","hash":{},"data":data,"loc":{"start":{"line":11,"column":112},"end":{"line":11,"column":130}}}) : helper)))
    + "</span>\n    </div>\n</div>\n<section class=\"section section-container\">\n<div class=\"alert-message\">\n    <div id=\"alertRegion\"  class=\"validation-group\" role=\"alert\"></div>\n</div>\n<div data-region=\"pmtMessageRegion\"></div>\n</section>\n\n<form class=\"corp-loans-page\">\n    <fieldset class=\"section section-container\">\n        <div class=\"section-header\">\n            <legend>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"gir.advice.transaction.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":20},"end":{"line":24,"column":60}}}))
    + "</legend>\n        </div>\n\n        <div class=\"row\">\n                        <div class=\"form-column col-md-12\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPossibleDuplicate") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":32},"end":{"line":38,"column":39}}})) != null ? stack1 : "")
    + "                                <div class=\"text-group field-container-lg field-container from-account\">\n                                        <label for=\"debit-account\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"originLabel") || (depth0 != null ? lookupProperty(depth0,"originLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"originLabel","hash":{},"data":data,"loc":{"start":{"line":40,"column":67},"end":{"line":40,"column":82}}}) : helper)))
    + ": </label>\n                                        <p class=\"form-control-static\">"
    + alias4((lookupProperty(helpers,"maskIt")||(depth0 && lookupProperty(depth0,"maskIt"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"DEBIT_ACCOUNT_NUMBER") : depth0),{"name":"maskIt","hash":{},"data":data,"loc":{"start":{"line":41,"column":71},"end":{"line":41,"column":102}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"getDebitSubaccount") || (depth0 != null ? lookupProperty(depth0,"getDebitSubaccount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getDebitSubaccount","hash":{},"data":data,"loc":{"start":{"line":41,"column":103},"end":{"line":41,"column":125}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"DEBIT_ACCOUNT_TITLE") || (depth0 != null ? lookupProperty(depth0,"DEBIT_ACCOUNT_TITLE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DEBIT_ACCOUNT_TITLE","hash":{},"data":data,"loc":{"start":{"line":41,"column":128},"end":{"line":41,"column":151}}}) : helper)))
    + "</p>\n                                        <div class=\"text-group field-container field-container-lg balanceTotal\"></div>\n                                </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"displayCutoff") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":32},"end":{"line":49,"column":39}}})) != null ? stack1 : "")
    + "\n                                <div class=\"text-group field-container-lg field-container to-account\">\n                                        <label for=\"credit-account\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"destinationLabel") || (depth0 != null ? lookupProperty(depth0,"destinationLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"destinationLabel","hash":{},"data":data,"loc":{"start":{"line":52,"column":68},"end":{"line":52,"column":88}}}) : helper)))
    + ": </label>\n                                        <p class=\"form-control-static\">"
    + alias4((lookupProperty(helpers,"maskIt")||(depth0 && lookupProperty(depth0,"maskIt"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"BENE_ACCOUNT") : depth0),{"name":"maskIt","hash":{},"data":data,"loc":{"start":{"line":53,"column":71},"end":{"line":53,"column":94}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"getBeneSubaccount") || (depth0 != null ? lookupProperty(depth0,"getBeneSubaccount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getBeneSubaccount","hash":{},"data":data,"loc":{"start":{"line":53,"column":95},"end":{"line":53,"column":116}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"BENE_NAME") || (depth0 != null ? lookupProperty(depth0,"BENE_NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BENE_NAME","hash":{},"data":data,"loc":{"start":{"line":53,"column":119},"end":{"line":53,"column":132}}}) : helper)))
    + "</p>\n                                        <div class=\"text-group field-container field-container-lg balanceTotal\"></div>\n                                </div>\n                                <div class=\"text-group field-container-lg field-container\">\n                                    <label for=\"credit-value-date\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.valueDate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":57,"column":67},"end":{"line":57,"column":96}}}))
    + ": </label>\n                                    <p class=\"form-control-static\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"VALUE_DATE") || (depth0 != null ? lookupProperty(depth0,"VALUE_DATE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"VALUE_DATE","hash":{},"data":data,"loc":{"start":{"line":58,"column":67},"end":{"line":58,"column":81}}}) : helper)))
    + "</p>\n                                </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasMultipleOptions") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":60,"column":32},"end":{"line":108,"column":39}}})) != null ? stack1 : "")
    + "                                <div class=\"text-group field-container-lg field-container\">\n                                        <label for=\"customer-reference\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"loans.customerref",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":110,"column":72},"end":{"line":110,"column":102}}}))
    + ": </label>\n                                        <p class=\"form-control-static\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"CUSTOMER_REFERENCE") || (depth0 != null ? lookupProperty(depth0,"CUSTOMER_REFERENCE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CUSTOMER_REFERENCE","hash":{},"data":data,"loc":{"start":{"line":111,"column":71},"end":{"line":111,"column":93}}}) : helper)))
    + "</p>\n                                </div>\n                                <div class=\"payment-comments\">\n                                        <div class=\"text-group field-container-lg field-container\">\n                                                <label for=\"special-instructions\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"ACH.Comments",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":115,"column":82},"end":{"line":115,"column":107}}}))
    + ": </label>\n                                                <p class=\"form-control-static\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"SPECIAL_INSTRUCTIONS") || (depth0 != null ? lookupProperty(depth0,"SPECIAL_INSTRUCTIONS") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SPECIAL_INSTRUCTIONS","hash":{},"data":data,"loc":{"start":{"line":116,"column":79},"end":{"line":116,"column":103}}}) : helper)))
    + "</p>\n                                        </div>\n                                </div>\n                        </div>\n                </div>\n\n                <div class=\"row\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"detailAuditHistory"),depth0,{"name":"detailAuditHistory","hash":{"payment":"true","containerTitle":"PAYJS.PaymentHistory"},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n    </fieldset>\n\n    <div id=\"customRegionDiv\" data-region=\"customRegion\"></div>\n\n    <div class=\"section section-summary total\">\n        <div class=\"section-header\">\n            <h3 class=\"total-heading\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"loans.payment.summary",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":131,"column":38},"end":{"line":131,"column":72}}}))
    + "</h3>\n            <h4>\n                    <span data-hook=\"getSummaryAmountTotal\" class=\"summary-large-text\">"
    + alias4(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"CREDIT_AMOUNT") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":133,"column":87},"end":{"line":133,"column":111}}}))
    + "</span>\n                    <span data-field=\"summary-currency\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"CREDIT_CURRENCY") || (depth0 != null ? lookupProperty(depth0,"CREDIT_CURRENCY") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CREDIT_CURRENCY","hash":{},"data":data,"loc":{"start":{"line":134,"column":56},"end":{"line":134,"column":75}}}) : helper)))
    + "</span>\n                <span> "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"payment.summary.to",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":135,"column":23},"end":{"line":135,"column":54}}}))
    + " </span>\n                    <span data-field=\"summary-beneficiaries\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"BENE_NAME") || (depth0 != null ? lookupProperty(depth0,"BENE_NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BENE_NAME","hash":{},"data":data,"loc":{"start":{"line":136,"column":61},"end":{"line":136,"column":74}}}) : helper)))
    + "</span>\n                    <span> "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"payment.on",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":137,"column":27},"end":{"line":137,"column":50}}}))
    + " </span>\n                    <span data-field=\"summary-date\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"VALUE_DATE") || (depth0 != null ? lookupProperty(depth0,"VALUE_DATE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"VALUE_DATE","hash":{},"data":data,"loc":{"start":{"line":138,"column":52},"end":{"line":138,"column":66}}}) : helper)))
    + "</span>\n            </h4>\n        </div>\n        <div class=\"section-body\">\n            <div class=\"widget-action-btn-group\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"buttons") : depth0),{"name":"each","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":143,"column":16},"end":{"line":150,"column":25}}})) != null ? stack1 : "")
    + "                <button type=\"button\" class=\"btn btn-secondary\" data-action=\"print\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"button.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":151,"column":84},"end":{"line":151,"column":109}}}))
    + "</button>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideCancelButton") : depth0),{"name":"unless","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":152,"column":16},"end":{"line":154,"column":27}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</form>\n";
},"usePartial":true,"useData":true});