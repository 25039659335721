import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import FlexDropdown from '@glu/flex-dropdown';
import constants from 'app/administration/constants';
import MultiFieldView from 'common/util/multiField/multiFieldView';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import userInfo from 'etc/userInfo';
import moment from 'moment';
import systemConfig from 'system/configuration';
import validatorPatterns from 'system/validatorPatterns';
import mobileUtil from 'mobile/util/mobileUtil';
import scheduleUtil from '../util';
import EmailRecipients from './emailRecipients';

export default Layout.extend({
    initialize() {
        this.getDataPromise().then(() => {
            this.setHasLoadedRequiredData(true);
            this.render();
        });
    },

    /**
     * Depending on the mode, return the promises required for rendering
     * the view
     * @retuns {Promise}
     */
    getDataPromise() {
        return this.isModify() ? this.getModifyData() : this.getCreateData();
    },

    /**
     * @abstract
     * Override with the promises you need to fetch data for modify.
     * @returns {Promise}
     */
    getModifyData() {
        return Promise.resolve();
    },

    /**
     * @abstract
     * Override with the promises you need to fetch data for create.
     * @returns {Promise}
     */
    getCreateData() {
        return Promise.resolve();
    },

    /**
     * Get userGroup and userId data that is available only in Admin
     * @returns {object}
     */
    getEmailRecipientAdminData() {
        if (systemConfig.isAdmin()) {
            const userGroup = this.model.get('userGroup');
            const userId = this.model.get('userId');
            return {
                userGroup: this.getSelectedId(userGroup),
                userId: this.getSelectedId(userId),
            };
        }
        return {};
    },

    /**
     * Get id's from the array
     * @param {Array} array - array
     */
    getSelectedId(array) {
        if (Array.isArray(array) && array.length > 0) {
            const [selection] = array;
            return selection.id;
        }
        return undefined;
    },

    /**
     * When the formatted value is truthy and has changed after the formatting,
     * update the model and trigger validation
     * @param {Model} blurModel
     * @param {Object} blurOptions
     */
    onRunTimeBlur(blurModel, blurOptions) {
        const runTime = blurModel.get('RUN_TIME');
        const validRunTime = this.attemptTimeFormat(runTime);
        if (runTime !== validRunTime && validRunTime) {
            blurModel.set('RUN_TIME', validRunTime);
            blurModel.validateField(blurOptions.fieldOptions.fieldName);
        }
    },

    /**
     * Attempt to format the string value with the expected server
     * format, 00:00 am. Return false when the value can not be formatted
     * @param {string} value
     * @returns {string|boolean}
     */
    attemptTimeFormat(value) {
        const time = /^(0?\d|1[0-2]):?([0-5]\d) *([ap]m)$/i;
        const attempt = value.match(time);
        if (attempt === null) {
            return false;
        }
        const [, hour, minute, ampm] = attempt;
        return `${hour.padStart(2, '0')}:${minute} ${ampm.toLowerCase()}`;
    },

    /**
     * Create a new multiFieldView specific to run times
     * @param {Model} model - backbone model
     * @param {Object} options - options for the multiField view
     * @returns {Object}
     */
    createRunTimeFields(model, options = {
        multiFieldLimit: serverConfigParams.get('ScheduledExportMaxRunTimes') || 50,
    }) {
        // loop through array of run times and create objects for collection
        const models = util.map(model.get('TIMEFREQUENCIES'), runTime => ({
            fieldValue: runTime,
            RUN_TIME: runTime.toLowerCase(),
        }));

        const timeZoneData = systemConfig.isAdmin() && this.isInsert() ? model.attributes.userId[0].timeZoneID : this.model.get('TIMEZONEID');
        const timeZone = timeZoneData || userInfo.get('timezone');
        let formatedTimeZone;

        if (timeZone) {
            formatedTimeZone = moment(new Date()).tz(timeZone).format('z');
        }

        const multiFieldView = new MultiFieldView({
            models,
            modelValidators: {
                RUN_TIME: {
                    description: locale.get('GIR.Run.Time'),
                    exists: true,
                    uniqueInCollection: true,
                    matches: validatorPatterns.TIME_PATTERN,
                },
            },
            addBtnText: locale.get('GIR.Add.another.run.time'),
            limit: options.multiFieldLimit,
            limitMessage: locale.get('GIR.Exceeded.Run.Times.Message', options.multiFieldLimit),
            fieldOptions: {
                fieldClass: 'form-control input-time',
                fieldLabel: locale.get('GIR.Run.Time'),
                fieldName: 'RUN_TIME',
                fieldPlaceholder: mobileUtil.isMobileScreen() ? '00:00 AM' : '',
                fieldType: 'text',
                inputMask: {
                    mask: 'h:s t\\m',
                    placeholder: '00:00 am',
                    alias: 'datetime',
                    hourFormat: '12',
                },
                required: true,
                timeZone: formatedTimeZone,
                onBlur: this.onRunTimeBlur.bind(this),
            },
            ...options,
        });
        return multiFieldView;
    },

    /**
     * Return array of run times from multi element fields
     * @return {array|null} array of run times
     */
    getRunTimes(multiFieldView) {
        const runTimes = multiFieldView.collection.map((model) => {
            const runTime = model.get('RUN_TIME');
            if (model.isValid() && runTime) {
                return runTime.toUpperCase();
            }
            return null;
        });

        // if any values in array are null then return null
        if (!runTimes.length || util.some(runTimes, util.isNull)) {
            return null;
        }
        return runTimes;
    },

    /**
     * Identifies if this current mode is 'modify'
     * @return {Boolean}
     */
    isModify() {
        return this.options.mode && this.options.mode.toLowerCase() === constants.MODES.MODIFY;
    },

    /**
     * Identifies if this current mode is 'insert'
     * @return {Boolean}
     */
    isInsert() {
        return this.options.mode && this.options.mode.toLowerCase() === constants.MODES.INSERT;
    },

    /**
     * Create a Flexdropdown instace with the specified options and
     * add an event listern
     * @param {Object} options
     * @param {Function} changeHandler
     * @returns {Object} new instance of Flexdropdown view
     */
    createDropdown(options, changeHandler) {
        const dropdown = new FlexDropdown({
            showTooltip: true,
            disableMultiButton: true,
            defaultSelectMessage: locale.get('common.select'),
            ...options,
        });
        this.listenTo(dropdown, 'selectionChanged', changeHandler);
        return dropdown;
    },

    /**
     * Update the specified model attribute with the id of the selected
     * value
     * @param {string} attribute - name of the model attribute to update
     * @param {Array} selected - array of selected values as provided by flexdropdown
     * @returns {string} id of the selected item
     */
    updateModelWithSelection(attribute, selected) {
        const [value] = selected;
        this.model.set(attribute, value.id);
        return value.id;
    },

    /**
     * Show email recipient view in the region
     */
    showEmailRecipients(disabled) {
        this.emailRecipientsRegion.show(new EmailRecipients({
            model: this.model,
            onFilter: this.getEntitledEmailRecipients.bind(this),
            disabled,
        }));
    },

    templateHelpers() {
        return {
            shouldShowEmailRecipients: scheduleUtil.shouldShowEmailRecipients(),
        };
    },
});
