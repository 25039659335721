import CollectionView from '@glu/core/src/collectionView';
import ItemListView from './item';

/**
 * Represents a list of view types on the list layout
 *
 * @class ItemListView
 */
const ListItemsView = CollectionView.extend({
    tagName: 'div',
    className: 'emailMessage-list',
    itemView: ItemListView,
});

export default ListItemsView;
