var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"glu-container\">\n    <form>\n        <fieldset>\n            <legend class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"printer",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":36},"end":{"line":4,"column":56}}}))
    + "</legend>\n            <div id=\"region-alert\" class=\"col-12\"></div>\n            <div id=\"region-printer-details\"></div>\n            <div class=\"col-10\">\n                <label for=\"printer-description\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"trayConfiguration",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":49},"end":{"line":8,"column":79}}}))
    + "</label>\n                <div id=\"region-tray-configuration-grid\"></div>\n            </div>\n        </fieldset>\n        <div class=\"col-12\">\n            <div class=\"page-actions\" data-behavior=\"sticky-bar\">\n                <button class=\"btn btn-primary\" type=\"submit\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":62},"end":{"line":14,"column":79}}}))
    + "</button>\n                <a href=\""
    + alias2(lookupProperty(helpers,"route").call(alias1,"printers",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":15,"column":25},"end":{"line":15,"column":45}}}))
    + "\" class=\"btn btn-tertiary js-btn-cancel\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":86},"end":{"line":15,"column":105}}}))
    + "</a>\n            </div>\n        </div>\n    </form>\n</div>\n";
},"useData":true});