var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " checked=\"checked\" ";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n                        <li data-scenario-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":17,"column":46},"end":{"line":17,"column":53}}}) : helper)))
    + "\">\n                                <input type=\"checkbox\" id=\"toggle-scenario-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":18,"column":75},"end":{"line":18,"column":82}}}) : helper)))
    + "-"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"id") : depths[1]), depth0))
    + "\" class=\"toggle-scenario\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hidden") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":118},"end":{"line":18,"column":164}}})) != null ? stack1 : "")
    + ">\n                                <label  for=\"toggle-scenario-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":19,"column":61},"end":{"line":19,"column":68}}}) : helper)))
    + "-"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"id") : depths[1]), depth0))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":19,"column":80},"end":{"line":19,"column":88}}}) : helper)))
    + "</label>\n                        </li>\n\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row\">\n                <h3 class=\"pull-left\">\n                        <button class=\"btn btn-link\" data-toggle=\"collapse\" data-target=\"#collapse-scenarios-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":3,"column":109},"end":{"line":3,"column":115}}}) : helper)))
    + "\" aria-expanded=\"false\" aria-controls=\"#collapse-scenarios-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":3,"column":174},"end":{"line":3,"column":180}}}) : helper)))
    + "\">\n                                "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":4,"column":32},"end":{"line":4,"column":40}}}) : helper)))
    + "\n                                <span class=\"picker-color-class-"
    + alias4(((helper = (helper = lookupProperty(helpers,"lineValue") || (depth0 != null ? lookupProperty(depth0,"lineValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lineValue","hash":{},"data":data,"loc":{"start":{"line":5,"column":64},"end":{"line":5,"column":77}}}) : helper)))
    + "\" style=\"width: 14px; height: 14px; display: inline-block;\"></span>\n                        </button>\n                </h3>\n\n                <label class=\"sr-only\" for=\"toggle-line-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":9,"column":56},"end":{"line":9,"column":62}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"cashflow.toggle.line.visible",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":64},"end":{"line":9,"column":105}}}))
    + "</label>\n                <input type=\"checkbox\" id=\"toggle-line-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":10,"column":55},"end":{"line":10,"column":61}}}) : helper)))
    + "\" class=\"toggle-line pull-right\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"visible") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":94},"end":{"line":10,"column":135}}})) != null ? stack1 : "")
    + ">\n        </div>\n\n\n        <ul class=\"collapse\" id=\"collapse-scenarios-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":14,"column":52},"end":{"line":14,"column":58}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"scenarios") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":22,"column":25}}})) != null ? stack1 : "")
    + "        </ul>\n";
},"useData":true,"useDepths":true});