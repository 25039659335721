var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"btn-wrapper\">\n                <div class=\"btn-group widget-links\">\n                    <button type=\"button\" class=\"btn btn-link export\" data-action=\"toggleForm\"><span class=\"icon-add-circle\"></span> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"LBX.AddAnotherNote",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":133},"end":{"line":6,"column":164}}}))
    + "</button>\n                </div>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div>\n                    <button type=\"button\" class=\"btn btn btn-secondary\" data-action=\"addNote\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"LBX.AddNote",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":94},"end":{"line":18,"column":118}}}))
    + "</button>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lockbox-note-add\">\n    <div class=\"field-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showButtons") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":9,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"lockbox-note-form\">\n            <div class=\"form-group lockbox-addNote\">\n                <label for=\"NOTE_TEXT\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"LBX.TransactionNote",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":39},"end":{"line":12,"column":71}}}))
    + "</label>\n                <textarea id=\"NOTE_TEXT\" class=\"form-control\" maxlength=\"400\" name=\"NOTE_TEXT\" data-bind=\"model\"></textarea>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"NOTE_TEXT\"></span>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showButtons") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":20,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"useData":true});