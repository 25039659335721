import util from '@glu/core/src/util';
import Model from 'common/dynamicPages/models/meta';
import validators from 'system/gluOverride/core/internal/validators';

export default Model.extend({

    validateField(attribute) {
        Model.prototype.validateField.call(this, attribute);
        let results = this.getValidationResultsByFieldName(attribute);
        if (util.isEmpty(results)) {
            if (this.schedModel) {
                results = this.getValidationResultsByFieldName(attribute, this.schedModel);
                if (results.errors) {
                    const errors = this.schedModel.get('error') || {};
                    if (results.errors) {
                        errors[attribute] = results.errors;
                    }
                    this.schedModel.set('error', errors);
                    this.schedModel.trigger('invalid', {
                        fieldValidation: true,
                    });
                    return;
                }
            }
            this.trigger('valid:attribute', attribute);
        }
    },

    /**
     * @param {String} name - attribute on the model
     * @param {Object} incModel - alternative Model other than self
     */
    getValidationResultsByFieldName(name, incModel) {
        const validationResults = {};

        const incValidators = incModel ? incModel.validators : this.validators;
        if (util.contains(util.keys(incValidators), name)) {
            const validator = incValidators[name];

            /*
             * mandatory set from table, or set when should be require(true), adds the
             * exists validator: true
             */
            const isMandatory = !!(validator && validator.exists
            && validator.exists === true);

            const tests = util.omit(validator, 'description', 'warning', 'overrideError', 'otherDescription');

            util.each(tests, (param, testName) => {
                /*
                 * validators should be run if the field is mandatory
                 * validators should be run if the field is not mandatory and there
                 * is data to validate
                 * validators should not be run if the field is not mandatory and
                 * there is no data to validate
                 * this checks to determine if the fielddata exists for the validator
                 * being named, if the field is not mandatory
                 * and if the fielddata actually has a value.  If no value and not
                 * mandatory, then it wont execute the validator.
                 */
                let refModel = this;
                if (incModel) {
                    refModel = incModel;
                }
                if (refModel.fieldData && refModel.fieldData[name]
                                       && !isMandatory && !refModel.get(name)) {
                    return;
                }

                /*
                 * avoid: method = validators[testName]; method();
                 * runs validator in global scope ('this' undefined)
                 * OverrideError applies to 'matches' test, and should only be used
                 * when test is for 'matches' and an override error exists
                 */
                const valid = (param === true) ? validators[testName](name, refModel)
                    : validators[testName](name, param, this);

                const validationMessage = this.getValidationMessage(validator.overrideError && testName === 'matches' ? validator.overrideError : testName);
                const message = util.template(validationMessage)(validator);

                if (!valid) {
                    // TODO: this format does not work for all messages.
                    if (validator.warning) {
                        validationResults.warnings = validationResults.warnings || [];
                        validationResults.warnings.push(message);
                    } else {
                        validationResults.errors = validationResults.errors || [];
                        validationResults.errors.push(message);
                    }
                }
            }, incModel || this);
        }

        return validationResults;
    },

    /**
     * Overriding the validate method from Glu
     * New code is indicated below where the existing
     * validators in the schedModel are applied
     */
    validate() {
        if (!this.validators) {
            return undefined;
        }

        this.trigger('validate');

        const warnings = {};
        const errors = {};
        let invalid = false;

        util.each(this.validators, (validator, key) => {
            const results = this.getValidationResultsByFieldName(key);

            if (results.warnings) {
                warnings[key] = results.warnings;
            }

            if (results.errors) {
                errors[key] = results.errors;
            }
        });

        if (util.isEmpty(warnings)) {
            this.unset('warning');
        } else {
            this.set('warning', warnings);
        }

        if (util.isEmpty(errors)) {
            this.unset('error');
        } else {
            invalid = true;
            this.set('error', errors);
        }

        /**
         * beginning of new code
         */
        if (this.schedModel && util.isEmpty(warnings) && util.isEmpty(errors)) {
            util.each(this.schedModel.validators, (validator, key) => {
                const results = this.getValidationResultsByFieldName(key, this.schedModel);
                if (results.warnings) {
                    warnings[key] = results.warnings;
                }
                if (results.errors) {
                    errors[key] = results.errors;
                }
            });
            if (util.isEmpty(warnings)) {
                this.unset('warning');
            } else {
                this.set('warning', warnings);
            }
            if (util.isEmpty(errors)) {
                this.unset('error');
            } else {
                invalid = true;
                this.set('error', errors);
            }
        }
        /**
         * end of new code
         */

        return invalid ? 'invalid' : null;
    },

});
