import defaultTemplate from 'common/dynamicPages/views/mdf/componentTemplates/helperTextTemplates/defaultTemplate.hbs';
import beneBankIdTemplate from 'common/dynamicPages/views/mdf/componentTemplates/helperTextTemplates/beneBankIdTemplate.hbs';
import correspondentIdTemplate from 'common/dynamicPages/views/mdf/componentTemplates/helperTextTemplates/correspondentIdTemplate.hbs';
import receivABATemplate from 'common/dynamicPages/views/mdf/componentTemplates/helperTextTemplates/receivABATemplate.hbs';
import accountBalance from 'common/dynamicPages/views/mdf/componentTemplates/helperTextTemplates/accountBalance.hbs';
import intermediaryIdTemplate from 'common/dynamicPages/views/mdf/componentTemplates/helperTextTemplates/intermediaryIdTemplate.hbs';

const helperTextTemplates = {
    defaultTemplate,
    BENE_BANK_ID: beneBankIdTemplate,
    CORRESPONDENT_ID: correspondentIdTemplate,
    RECEIVABA: receivABATemplate,
    DEBIT_ACCOUNT_NUMBER: accountBalance,
    ACCOUNTFILTER: accountBalance,
    COMPIDNAME: accountBalance,
    INTERMEDIARY_ID: intermediaryIdTemplate,
    BENE_ACCOUNTENTITLEMENT: accountBalance,
};

export default {
    getTemplate(id) {
        return (helperTextTemplates[id])
            ? helperTextTemplates[id] : helperTextTemplates.defaultTemplate;
    },
};
