import Layout from '@glu/core/src/layout';
import { toggleTabs, setTabButtonText } from 'common/util/a11y/tabs';

export default Layout.extend({

    ui: {
        $divLinks: 'div.accountGroupToggle',
        $btnAccsByType: '#accs-by-type',
        $btnPosPay: '#posPay-items',
        $btnRevPosPay: '#revPosPay-items',
        $btnEPay: '#ePay-items',
        $navTabs: '[data-hook="getNavTabs"]',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
        $navItems: '[data-hook="getNavTabs"] .NavTabs-item',
    },
    isInitialRender: true,

    regions: {
        pageRegion: '.pageRegion',
    },

    events: {
        'click @ui.$navLinks': 'updateView',
        'click .accountGroupToggle button': 'updateView',
    },

    changeTab(currentTarget) {
        this.$(currentTarget).parent('li').addClass('is-active').siblings()
            .removeClass('is-active');
        this.currentTabText = this.$(currentTarget).text();
        if (this.isInitialRender) {
            setTabButtonText(this, this.currentTabText);
        } else if (this.$(currentTarget).is('a')) {
            toggleTabs(this);
        }
    },

    changeLink(e) {
        this.$(`button[data-id=${e}]`).addClass('active').siblings().removeClass('active');
    },

    displayField(isVisible, field) {
        if (isVisible) {
            field.show();
        } else {
            field.hide();
        }
    },
});
