import Layout from '@glu/core/src/layout';
import RenderFlag from 'components/CurrencyBadges/RenderFlag';
import { asView } from 'common/util/reactUtil';
import currencyFlagTmpl from './currencyFlagTmpl.hbs';

const CurrencyFlag = Layout.extend({
    template: currencyFlagTmpl,
    onRender() {
        const CurrencyFlagView = asView(RenderFlag);
        const CurrencyFlagViewModalView = new CurrencyFlagView({
            name: this.options.name,
        });
        this.currencyFlagRegion.show(CurrencyFlagViewModalView);
    },
});

export default CurrencyFlag;
