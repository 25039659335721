import { createUseStyles } from '@glu/theming';

const styles = ({ fileUploadReact }) => ({
  root: {
    fontFamily: fileUploadReact.bodyFont,
    fontWeight: 'normal',
    fontSize: 14,
    margin: [20, 0],
    width: '100%',
    borderBottom: `1px solid ${fileUploadReact.border}`,
    '& thead': {
      textTransform: 'uppercase',
      color: fileUploadReact.textColor,
      fontSize: 12,
      lineHeight: 1,
      '& td': {
        border: 0,
        borderBottom: `2px solid ${fileUploadReact.border}`
      }
    },
    '& td': {
      padding: [10, 8]
    }
  }
});

export default createUseStyles(styles);
