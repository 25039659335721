import ItemView from '@glu/core/src/itemView';
import companyViewTmpl from './companyView.hbs';

export default ItemView.extend({
    template: companyViewTmpl,

    events: {
        'click input': 'toggleSelected',
    },

    templateHelpers() {
        return {
            hx: 'h4',
            name: this.model.get('COMPNAME'),
            companyId: this.model.get('COMPANYID'),
        };
    },
});
