import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import ConversationView from 'app/payments/views/realTimePayments/rtpConversationView';
import template from './imageConversationCell.hbs';

const NOT_STARTED = '1';
const UNREAD = '2';
const READ = '3';
const ERROR = '4';

export default Layout.extend({
    conversationIconType: '',
    conversationIconDesc: '',
    events: {
        'click [data-hook="image-button"]': 'handleConversation',
    },

    initialize() {
        // get the entitlements from the parent grid
        this.entitlements = (this.options.grid)
            ? this.options.grid.options.wrapper.entitlements
            : {
                RTPACK: 1,
                RTPREQ: 1,
            };
        this.determineConversationType();
    },

    /**
     * @description - sets up conversationIconType, conversationIconDesc and conversationState
     * based on the column value
     */
    determineConversationType() {
        switch (this.options.value) {
        case NOT_STARTED:
            // conversation not started
            this.conversationIconType = this.options.column.get('iconName');
            this.conversationIconDesc = this.options.column.get('iconDesc');
            this.conversationState = NOT_STARTED;
            break;
        case UNREAD:
            // conversation not read
            this.conversationIconType = `${this.options.column.get('iconName')} unread`;
            this.conversationIconDesc = 'rtp.conversation.image.unread';
            this.conversationState = UNREAD;
            break;
        case READ:
            this.conversationIconType = `${this.options.column.get('iconName')} read`;
            this.conversationIconDesc = 'rtp.conversation.image.read';
            this.conversationState = READ;
            break;
        case ERROR:
            this.conversationIconType = `${this.options.column.get('iconName')} error`;
            this.conversationIconDesc = 'rtp.conversation.image.error'; // TODO - add to app resources
            this.conversationState = ERROR;
            break;
        default:
            break;
        }
    },

    getTemplate() {
        return (this.hasImage())
            ? template : function () {
                return '';
            };
    },

    hasImage() {
        return this.options.value > 0;
    },

    handleConversation(e) {
        // set currentTab in the store for BTR transactions
        if (this.model.get('TYPE') === 'GIRTRANS' || this.model.get('TYPE') === 'GIRTRNSR' || this.model.get('TYPE') === 'GIRTRSR2') {
            if (this.model.get('BAI_CODE') === '458') {
                store.set('payment_rtp-currentTab', 'submitted');
            } else if (this.model.get('BAI_CODE') === '158') {
                store.set('payment_rtp-currentTab', 'received');
            }
        } else {
            store.set('payment_rtp-currentTab', this.model.context.serviceName.indexOf('crtran') !== -1 ? 'submitted' : 'received');
        }
        // what type of conversation icon did the user click on
        switch (this.conversationState) {
        case NOT_STARTED:
            this.displayStartConversation(e.currentTarget);
            break;
        case UNREAD:
            this.displayContinueConversation(e.currentTarget);
            break;
        case READ:
            this.displayContinueConversation(e.currentTarget);
            break;
        default:
            console.log('function not supported yet');  //eslint-disable-line
        }
    },

    /**
     * @description - displays the start conversation dialog
     */
    displayStartConversation(currentTarget) {
        const rtpStartConversationData = {
            view: ConversationView,
            showTab: false,
            shouldBeOpen: true,
            viewOptions: {
                model: this.model,
                conversationStarted: false,
                currentTab: store.get('payment_rtp-currentTab'),
                keepAlive: false,
                msgIconElement: currentTarget,
            },
        };

        this.appBus.trigger('dgb:drawer:update', rtpStartConversationData);
    },

    displayContinueConversation(currentTarget) {
        const rtpStartConversationData = {
            view: ConversationView,
            showTab: false,
            shouldBeOpen: true,
            viewOptions: {
                model: this.model,
                conversationStarted: true,
                allowDetail: true,
                currentTab: store.get('payment_rtp-currentTab'),
                keepAlive: false,
                msgIconElement: currentTarget,
            },
        };

        this.appBus.trigger('dgb:drawer:update', rtpStartConversationData);
        this.updateMessageDisplay();
    },

    /**
     * when an unread message is viewed, update the message state icon to read
     * and update (decrement) the unread message counter
     */
    updateMessageDisplay() {
        // if message state is unread, change it to read
        if (this.model.get('MESSAGE_STATE') === UNREAD) {
            this.model.set('MESSAGE_STATE', READ);

            const currentTab = store.get('payment_rtp-currentTab');
            const currentCounter = (currentTab === 'submitted')
                ? document.querySelector('[data-hook="getSubmittedCount"]')
                : document.querySelector('[data-hook="getReceivedCount"]');

            if (!currentCounter) {
                return;
            }
            const currentValue = parseInt(currentCounter.innerText, 10);
            /*
             * sanity check, we never want the count to get below 0
             * the notification service should send down the right value, but
             * just in case, bullet proof the code
             */
            if (currentValue > 0) {
                currentCounter.innerText = (currentValue - 1);
            }
        }
    },

    templateHelpers() {
        return {
            showError: this.conversationState === ERROR,
            iconName: this.conversationIconType,
            iconDescription: locale.get(this.conversationIconDesc),
        };
    },
});
