/**
 * Majority of code in this file came from times when Glu was between release/1.8.x and release/1.9.x - GLU-1040
 * This file used to overwrite Glu 1.8 glyph icons by SVG icons using template helper {{icon}}.
 * If application uses Glu 1.8 but code migrated to Glu Core 2.0.0, then this helper is picked automatically by glu-core/templateHelpers.js
 * If LOB codebase has specific SVG icons and want to use it, this helper will also look to custom icons, if `Glu.settings.customIconsData` provided.
 * So that, iconSvgCode could looked up from custom function (iconsData.js).
 */

import Handlebars from 'handlebars';
import {
  util,
  locale
} from '@glu/core';
import iconTemplate from '../templates/iconTemplate.hbs';

export const ICONS_DATA = {
  "address-book": "<svg viewBox=\"0 0 15.7 15.8\"><path d=\"M12 0H.9v3.9H0v3h.9v2H0v3h.9v3.9h14.8V0H12zM8.8 4.7c.9 0 1.7.7 1.7 1.7S9.7 8 8.8 8c-.9 0-1.7-.7-1.7-1.6 0-.9.8-1.7 1.7-1.7zm-6.9 6.1h-1v-1h1v1zm0-4.9h-1v-1h1v1zm9.9 5H5.7c0-.5 0-1 .1-1.4.7-.4 1.4-.8 2.2-1 .5.3 1.1.3 1.6 0 .8.3 1.5.6 2.2 1 0 .4.1.9 0 1.4z\"/></svg>",
  "alarm-clock": "<svg viewBox=\"0 0 14.5 14.5\"><path d=\"M14.1 5.1C13.4 2.8 11.4 1 9 .7c1.5-1.1 3.6-.9 4.8.6.8 1.2.9 2.6.3 3.8zM5.5.7C3.1 1 1.1 2.7.4 5-.5 3.3.1 1.3 1.8.4 3-.2 4.5-.1 5.5.7zm2.3 7.2l2.9 2.9-.7.7-3.2-3.1V5.2h1v2.7zm-.5 6.6C3.7 14.5.8 11.6.8 8s2.9-6.5 6.5-6.5 6.5 2.9 6.5 6.5-2.9 6.5-6.5 6.5zm0-12C4.3 2.5 1.8 5 1.8 8s2.5 5.5 5.5 5.5S12.8 11 12.8 8c0-3.1-2.5-5.5-5.5-5.5zm-5 9.6l.7.7-1.3 1.3-.7-.6 1.3-1.4zm10 0l-.7.7 1.3 1.3.7-.7-1.3-1.3z\"/></svg>",
  "arrow-circle-left": "<svg viewBox=\"0 0 15 12.4\"><path d=\"M13.2 1.8c2.4 2.4 2.4 6.4-.1 8.8-1.6 1.6-3.9 2.2-6 1.6l.5-1.7c2.4.7 4.8-.7 5.5-3.1s-.8-4.8-3.1-5.5-4.8.8-5.5 3.1c-.1.4-.1.8-.1 1.2h3.2L3.7 9.9 0 6.2h2.6C2.6 2.7 5.4 0 8.8 0c1.7 0 3.2.7 4.4 1.8z\"/></svg>",
  "arrow-curved-left": "<svg viewBox=\"0 0 15.9 12\"><path d=\"M0 6.1L5 0v4.1C16 3.4 15.9 11 15.9 12 14.1 6.5 6 7.9 5 7.9V12L0 6.1z\"/></svg>",
  "arrow-curved-right": "<svg viewBox=\"0 0 15.9 12\"><path d=\"M10.9 12V7.9C9.9 7.9 1.8 6.5 0 12c0-1-.1-8.6 10.9-7.9V0l5 6.1-5 5.9z\"/></svg>",
  "arrow-dashes": "<svg viewBox=\"0 0 13.4 11\"><path d=\"M13.4 5.5L7.8 11V7.5H4.4v-4h3.4V0l5.6 5.5zm-9.9-2H2.1v4h1.4v-4zm-3.1 0H0v4h.4v-4z\"/></svg>",
  "arrow-down": "<svg viewBox=\"0 0 15.6 15.9\"><path d=\"M0 8.1l1.4-1.4 5.4 5.4V0h2v12l5.3-5.3 1.4 1.4-7.8 7.8L0 8.1z\"/></svg>",
  "arrow-left": "<svg viewBox=\"0 0 15.9 15.6\"><path d=\"M0 7.8L7.8 0l1.4 1.4-5.3 5.3h12v2H3.7l5.4 5.4-1.4 1.4L0 7.8z\"/></svg>",
  "arrow-right": "<svg viewBox=\"0 0 15.9 15.6\"><path d=\"M8.1 15.6l-1.4-1.4 5.4-5.4H0v-2h12L6.7 1.4 8.1 0l7.8 7.8-7.8 7.8z\"/></svg>",
  "arrow-up": "<svg viewBox=\"0 0 15.6 15.9\"><path d=\"M15.6 7.7l-1.4 1.4-5.5-5.4v12.1h-2v-12L1.4 9.2 0 7.8 7.8 0l7.8 7.7z\"/></svg>",
  "arrows-circles": "<svg viewBox=\"0 0 15.5 12.4\"><path d=\"M10.9 3.1c-1.7-1.7-4.5-1.8-6.3 0-.8.8-1.2 1.9-1.2 3.1h1.7L2.7 9.9 0 6.2h1.6C1.6 2.7 4.4 0 7.8 0c1.4 0 2.7.5 3.8 1.4.1.1.2.2.3.2m-8.3 9.2l.3.3c2.7 2.1 6.6 1.6 8.7-1 .9-1.1 1.3-2.4 1.3-3.8h1.5l-2.7-3.8-2.3 3.8h1.7c0 2.5-2 4.4-4.5 4.4-1.2 0-2.3-.5-3.1-1.3\"/></svg>",
  "arrows-corners-in": "<svg viewBox=\"0 0 16 16\"><path d=\"M3.6 11.7L0 15.3l.7.7 3.7-3.7L6 14v-4H2l1.6 1.7zM14 10h-4v4l1.6-1.7 3.7 3.7.7-.7-3.6-3.6L14 10zM2 6h4V2L4.4 3.7.7 0 0 .7l3.6 3.6L2 6zm10.4-1.7L16 .7l-.7-.7-3.7 3.7L10 2v4h4l-1.6-1.7z\"/></svg>",
  "arrows-corners-out": "<svg viewBox=\"0 0 14 14\"><path d=\"M4 0H0v4l1.6-1.7L5.3 6l.7-.7-3.6-3.6L4 0zm7.6 1.7L8 5.3l.7.7 3.7-3.7L14 4V0h-4l1.6 1.7zM10 14h4v-4l-1.6 1.6L8.7 8l-.7.7 3.6 3.6L10 14zm-7.6-1.7L6 8.7 5.3 8l-3.7 3.6L0 10v4h4l-1.6-1.7z\"/></svg>",
  "badge-checkmark": "<svg viewBox=\"0 0 15.5 15.6\"><path d=\"M15.1 10.2L13.8 9v-.2-.2l1.7-.8-1.7-.7c0-.2 0-.3-.1-.5L15 5.4l-1.8-.2s0-.1-.1-.1c0-.1-.1-.2-.1-.3l1-1.6-1.9.4-.1-.1s0-.1-.1-.2l.4-1.9-1.6 1c-.1-.1-.2-.1-.4-.2L10.1.4 9 1.8h-.2-.2L7.7 0 7 1.8c-.1 0-.2 0-.4.1L5.4.4l-.2 1.9s-.1 0-.2.1c0 0-.1 0-.2.1l-1.7-1 .5 1.8-.1.1-.2.2-1.9-.4 1 1.6c-.1.1-.1.3-.2.5l-1.8.1 1.3 1.2V7L0 7.8l1.7.7c0 .2 0 .4.1.5L.5 10.2l1.8.2s0 .1.1.1c0 .1.1.2.1.3l-1.1 1.7 2-.5.1.1c.1.1.1.2.2.2l-.5 1.8 1.7-1c.1.1.2.1.4.2l.2 1.8 1.2-1.4h.4l.7 1.7.7-1.7c.1 0 .2 0 .4-.1l1.3 1.4.1-1.8c.1 0 .1-.1.2-.1s.2-.1.3-.1l1.6.9-.4-1.8.1-.1.2-.2 1.8.4-1-1.6c.1-.2.2-.3.2-.5l1.8.1zm-7.3 2.7c-2.8 0-5-2.2-5-4.9s2.2-5 5-5c2.7 0 4.9 2.2 4.9 4.9 0 2.8-2.2 5-4.9 5zm-1.2-1.6l4.6-4.6-.7-.7-3.9 3.9-2.1-2.1-.8.7 2.9 2.8z\"/></svg>",
  "blocks": "<svg viewBox=\"0 0 16 16\"><path d=\"M16 8v8H9V8m7-2V0H0v6m0 1.9v8h7v-8\"/></svg>",
  "book": "<svg viewBox=\"0 0 12.9 15.7\"><path d=\"M10.9 0v6.6l-1.5-1-1.5 1V0H0v15.7h12.9V0h-2z\"/></svg>",
  "bookmark": "<svg viewBox=\"0 0 7.6 15.7\"><path d=\"M0 0v15.7l3.8-5.2 3.8 5.2V0H0zm1.9 10.5l-1 1V1h1v9.5zm4.8.9l-1-1V1h1v10.4z\"/></svg>",
  "box-arrow-out": "<svg viewBox=\"0 0 16 16\"><path d=\"M12 16H0V4h9v1H1v10h10V8h1v8zm4-9h-1V1.7l-8.1 8-.7-.6L14.3 1H9V0h7v7z\"/></svg>",
  "box-arrow-right": "<svg viewBox=\"0 0 16 16\"><path d=\"M10 11H9v4H1V1h8v4h1V0H0v16h10v-5zm2.1 1.1l-.7-.7 2.8-2.9L6 8.4v-.9h8.2l-2.9-2.8.7-.7 4 4-3.9 4.1z\"/></svg>",
  "boxes-columns": "<svg viewBox=\"0 0 10 16\"><path d=\"M4 4H0V0h4v4zm0 2H0v4h4V6zm0 6H0v4h4v-4zm6-12H6v4h4V0zm0 6H6v4h4V6zm0 6H6v4h4v-4z\"/></svg>",
  "briefcase-paperclip": "<svg viewBox=\"0 0 16 16.1\"><path d=\"M15.5 11.8l-1.9 2.8c-.3.5-.9.7-1.5.4-.5-.3-.7-.9-.4-1.5l.1-.1 1.5-2.3.4.3-1.5 2.3c-.5.8.5 1.4 1 .6l1.9-2.8c1-1.5-.8-2.7-1.8-1.2l-1.8 2.8c-.5.7-.5 1.6.2 2.2s1.6.5 2.2-.2c.1-.1.1-.2.2-.3l1.4-2.2.4.3-1.4 2.1c-.5 1-1.8 1.4-2.8.8-1-.5-1.4-1.8-.8-2.8.1-.1.1-.2.2-.3l1.8-2.8c.4-.8 1.3-1.1 2.1-.7s1.1 1.3.7 2.1c0 .2-.1.3-.2.5zm-6.1-.1l1.8-2.8c.7-1.2 2-1.9 3.4-1.8.5 0 1 .2 1.4.4V3h-5V0H5v3H0v13h9.2c-.2-.4-.4-.8-.5-1.3-.1-1.1.1-2.1.7-3zM6 .9h4v2H6v-2z\"/></svg>",
  "briefcase-plus": "<svg viewBox=\"0 0 16 16\"><path d=\"M16 13H9v-1h7v1zm-4 3V9h1v7h-1zm-5-1v-5h3V7h5v3h1V3h-5V0H5v3H0v13h10v-1H7zM6 .9h4v2H6v-2z\"/></svg>",
  "briefcase": "<svg viewBox=\"0 0 16 16\"><path d=\"M11 0H5v3H0v13h16V3h-5V0zM6 .9h4v2H6v-2z\"/></svg>",
  "building-checkmark": "<svg viewBox=\"0 0 18.2 16\"><path d=\"M2.5 7h-1v7h1V7zm8 3.3h1V7h-1v3.3zm4-3.3h-1v3.2h1V7zm-9 0h-1v7h1V7zm3 4V7h-1v4h1zM8 0L2 4h12L8 0zM5.5 14H0v2h8.5v-2h-3zM16 5H0v1h16V5zm-7 7.4l.8-.7 2.6 2.8 5-5.1.7.8-5.7 5.7L9 12.4z\"/></svg>",
  "building-plus": "<svg viewBox=\"0 0 16 16\"><path d=\"M16 13H9v-1h7v1zm-4 3V9h1v7h-1zM2.5 7h-1v7h1V7zm8 1h1V7h-1v1zm4-1h-1v1h1V7zm-9 0h-1v7h1V7zm3 4V7h-1v4h1zM8 0L2 4h12L8 0zM5.5 14H0v2h8.5v-2h-3zM16 5H0v1h16V5z\"/></svg>",
  "building": "<svg viewBox=\"0 0 16 16\"><path d=\"M16 16H0v-2h16v2zm0-11H0v1h16V5zm-2-1L8 0 2 4m.5 3h-1v7h1V7zm3 0h-1v7h1V7zm6 0h-1v7h1V7zm3 0h-1v7h1V7zm-6 0h-1v7h1V7z\"/></svg>",
  "buildings": "<svg viewBox=\"0 0 16 16\"><path d=\"M10 5v11h6V5h-6zm2.5 9h-1v-1h1v1zm0-2h-1v-1h1v1zm0-2h-1V9h1v1zm0-2h-1V7h1v1zm2 6h-1v-1h1v1zm0-2h-1v-1h1v1zm0-2h-1V9h1v1zm0-2h-1V7h1v1zM8 7H7V4L6 2H5V0H4v2H3L2 4v3H1L0 8v8h9V8L8 7zM5 4h1v1H5V4zM3 4h1v1H3V4zm0 10H2v-1h1v1zm0-2H2v-1h1v1zm0-2H2V9h1v1zm0-4h1v1H3V6zm2 8H4v-1h1v1zm0-2H4v-1h1v1zm0-2H4V9h1v1zm0-4h1v1H5V6zm2 8H6v-1h1v1zm0-2H6v-1h1v1zm0-2H6V9h1v1z\"/></svg>",
  "calendar": "<svg viewBox=\"0 0 15.7 15.7\"><path d=\"M12.9 7.9h-2v-2h2v2zm0 4.9h-2v-2h2v2zm-4-4.9h-2v-2h2v2zm-4 3h-2v2h2v-2zm4 0h-2v2h2v-2zm5-9.1V0h-3v1.8h-6V0h-3v1.8H0v13.9h15.7V1.8h-1.8zm-2-1h1v2h-1v-2zm-9 .1h1v2h-1v-2zm12 14H.9v-11h14v11z\"/></svg>",
  "caret-down": "<svg viewBox=\"0 0 15.6 9.2\"><path d=\"M7.8 6.4L14.1 0l1.4 1.4-7.7 7.8L0 1.4 1.4 0l6.4 6.4z\"/></svg>",
  "caret-left": "<svg viewBox=\"0 0 9.2 15.6\"><path d=\"M2.8 7.8l6.4 6.4-1.4 1.4L0 7.8 7.8 0l1.4 1.4-6.4 6.4z\"/></svg>",
  "caret-line-left": "<svg viewBox=\"0 0 11.2 16\"><path d=\"M2 16H0V0h2v16zm2.8-8l6.4 6.4-1.4 1.4L2 8 9.8.2l1.4 1.4L4.8 8z\"/></svg>",
  "caret-line-right": "<svg viewBox=\"0 0 11.2 16\"><path d=\"M9.2 0h2v16h-2V0zM0 1.6L1.4.2 9.2 8l-7.8 7.8L0 14.4 6.4 8 0 1.6z\"/></svg>",
  "caret-right": "<svg viewBox=\"0 0 9.2 15.6\"><path d=\"M6.4 7.8L0 1.4 1.4 0l7.8 7.8-7.8 7.8L0 14.1l6.4-6.3z\"/></svg>",
  "caret-up": "<svg viewBox=\"0 0 15.6 9.2\"><path d=\"M7.8 2.8L1.4 9.2 0 7.8 7.8 0l7.8 7.8-1.4 1.4-6.4-6.4z\"/></svg>",
  "cash-in-hand": "<svg viewBox=\"0 0 15.4 14.9\"><path d=\"M0 14.9L3.5 9 5 8h4v1H6v2h5l3.5-2 .8.6-3.8 2.4H5.1l-1.6 2.9H0zM6.3 3.5l-.5-.9 2.7-1.4.5.9-2.7 1.4zm7.3 2.7l.3-.9-2.8-.9-.3.9 2.8.9zM15 1V0h-3v1h3z\"/></svg>",
  "chart-bar": "<svg viewBox=\"0 0 16 16\"><path d=\"M16 16H0V0h.9v15H16v1zM2.9 7.1v6.6h2V7.1h-2zm5 6.6h2V4.6h-2v9.1zm7-13.6h-2v13.6h2V.1z\"/></svg>",
  "chart-circles": "<svg viewBox=\"0 0 16 16\"><path d=\"M16 16H0V0h1v15h15v1zM4.7 8.7c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2 2.2-1 2.2-2.2c.1-1.2-.9-2.2-2-2.2h-.2zm7.4.6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-1.9-2-2zM10 1.8c-1.7 0-3.1 1.4-3.1 3.1 0 1.7 1.3 3.1 3 3.1h.1c1.7 0 3.1-1.4 3.1-3.1 0-1.7-1.3-3.1-3.1-3.1z\"/></svg>",
  "chart-donut": "<svg viewBox=\"0 0 16 16\"><path d=\"M7.3 3.6c-2.8 0-5 2.2-5 5s2.2 5 5 5c1.4 0 2.7-.6 3.6-1.5l1.7 1.6c-2.8 2.9-7.5 3-10.4.2S-.8 6.5 2 3.6c1.4-1.4 3.3-2.3 5.3-2.3v2.3zm4.6 7.4c.9-1 1.2-2.5.8-3.8-.2-1.1-.8-2.2-1.7-3l1.9-2.5c1.5 1.4 2.6 3.2 3 5.2.3 2.1-.2 4.3-1.3 6.1l-2.7-2zm-1.6-7.2c-.5-.4-1.1-.6-1.8-.6L8.7 0c.6.1 1.1.2 1.7.4.5.2 1 .4 1.4.7l-1.5 2.7z\"/></svg>",
  "chart-line": "<svg viewBox=\"0 0 16 16\"><path d=\"M16 16H0V0h1v15h15v1zM13.9 5.5L11 6l1 1-3.7 3.7-2.2-2.3-3.5 3.5.7.7 2.8-2.8 2.1 2.1 4.3-4.3.9.9.5-3z\"/></svg>",
  "chart-pie": "<svg viewBox=\"0 0 16 16\"><path d=\"M13.1 14.1C11.7 15.3 9.9 16 8 16c-4.4 0-8-3.6-8-8C0 3.9 3.2.4 7.3 0v8.3l5.8 5.8zM13.3 2C12 .8 10.4.1 8.7 0v6.6L13.3 2zm.9.9L9.1 8.1l5.1 5C15.4 11.7 16 9.9 16 8c0-1.9-.6-3.6-1.8-5.1z\"/></svg>",
  "chat": "<svg viewBox=\"0 0 16 16\"><path d=\"M15 11H8.7L5 14v-3H1V1h14m1-1H0v12h4v4l5-4h7V0z\"/></svg>",
  "check-payment": "<svg viewBox=\"0 0 15.9 12.8\"><path d=\"M12.7.6l.4-.4c.2-.2.6-.3.9 0 .2.2.3.6 0 .9L6.9 8.2h-.1c-.1.1-.3.2-.4.2l-.8.2.2-.8c0-.1 0-.3.1-.4v-.1L10.3 3c-.1-.2 0-.5.1-.6L12 .8c.3-.2.5-.3.7-.2zm1.6 2l-1 1H15v8.2H1V3.6h6.9l1-1H0v10.2h15.9V2.6h-1.6zM9.1 9.7H2.2v1h6.9v-1z\"/></svg>",
  "checkboxes": "<svg viewBox=\"0 0 16 7\"><path d=\"M16 7H9V0h7v7zm-6-1h5V1h-5v5zM7 2.3L6 3.4V6H1V1h4.1l1-1H0v7h7V2.3zM3.5 5L7.7.7 7 0 3.5 3.6 2.2 2.3l-.8.7 2.1 2z\"/></svg>",
  "checkmark-box": "<svg viewBox=\"0 0 16 14.2\"><path d=\"M12 14.2H0v-12h10l-.8 1H1v10h10v-5l1-1v7zM1.7 4.8l.7-.7 4.3 4.3L15.2 0l.8.7-9.2 9.2-5.1-5.1z\"/></svg>",
  "checkmark-circle-solid": "<svg viewBox=\"0 0 15.7 15.7\"><path d=\"M7.9 0C3.5 0 0 3.5 0 7.9s3.5 7.9 7.9 7.9 7.9-3.5 7.9-7.9C15.7 3.5 12.2 0 7.9 0zm0 14.7c-3.8 0-6.9-3-6.9-6.8S4.1 1 7.9 1s6.9 3.1 6.9 6.9c-.1 3.8-3.1 6.8-6.9 6.8zM7.9 1C4.1 1 1 4.1 1 7.9s3.1 6.9 6.9 6.9 6.9-3.1 6.9-6.9C14.7 4.1 11.7 1 7.9 1zM6.6 12.9L1.9 8.2l1.7-1.7 2.9 2.9 5.2-5.2L13.4 6l-6.8 6.9z\"/></svg>",
  "checkmark-circle": "<svg viewBox=\"0 0 15.7 15.7\"><path d=\"M2.9 7.8l.7-.7L6.5 10l5.2-5.2.7.7-5.9 5.9-3.6-3.6zm5 7.9C3.5 15.7 0 12.2 0 7.9S3.5 0 7.9 0s7.9 3.5 7.9 7.9c-.1 4.3-3.6 7.8-7.9 7.8zM7.9 1C4.1 1 1 4.1 1 7.9s3.1 6.9 6.9 6.9 6.9-3.1 6.9-6.9C14.7 4.1 11.7 1 7.9 1z\"/></svg>",
  "checkmark": "<svg viewBox=\"0 0 14.8 9.9\"><path d=\"M0 4.2l.7-.7 4.9 4.9L14.1 0l.7.7-9.2 9.2L0 4.2z\"/></svg>",
  "circle-minus-arrow": "<svg viewBox=\"0 0 16 15.8\"><path d=\"M12.9 15.8l-3.1-3.5h2.1V9.7H14v2.6h2l-3.1 3.5zm2.6-10C14.3 1.6 10-.9 5.8.3S-.9 5.8.3 9.9c.9 3.4 4.1 5.8 7.6 5.8v-1c-3.8 0-6.9-3-6.9-6.8S4.1 1 7.9 1c3 0 5.7 2 6.6 4.8h1zm-4.6 2.6h-7v-1h7v1z\"/></svg>",
  "circle-solid": "<svg viewBox=\"0 0 15.7 15.7\"><path d=\"M7.9 15.7C3.5 15.7 0 12.2 0 7.9S3.5 0 7.9 0s7.9 3.5 7.9 7.9c-.1 4.3-3.6 7.8-7.9 7.8z\"/></svg>",
  "circle": "<svg viewBox=\"0 0 15.7 15.7\"><path d=\"M7.9 15.7C3.5 15.7 0 12.2 0 7.9S3.5 0 7.9 0s7.9 3.5 7.9 7.9c-.1 4.3-3.6 7.8-7.9 7.8zM7.9 1C4.1 1 1 4.1 1 7.9s3.1 6.9 6.9 6.9 6.9-3.1 6.9-6.9C14.7 4.1 11.7 1 7.9 1z\"/></svg>",
  "clipboard-checkmark": "<svg viewBox=\"0 0 13.1 16\"><path d=\"M3.6 15H1V3h11l.1 5.9h1L13 2h-3V0H3v2H0v14h3.6v-1zM4 1h5v1H4V1zm4 15l5-4.9-.7-.7L8 14.5l-2.4-2.4-.7.7L8 16z\"/></svg>",
  "clipboard-document": "<svg viewBox=\"0 0 13 16\"><path d=\"M4 1h5v1H4V1zm9 6.1V2h-3V0H3v2H0v14h4.5v-1H1V3h11v4l1 .1zm-5.5 6.4h4v.7h-4v-.7zm0-1h3v-.7h-3v.7zm0-1.7h2V10h-2v.8zM11.3 8H5.5v8H13V9.5L11.3 8zm.7 7H6.5V9h4.3v1.2H12V15z\"/></svg>",
  "clipboard": "<svg viewBox=\"0 0 13 16\"><path d=\"M10 2V0H3v2H0v14h13V2h-3zM4 1h5v1H4V1zm8 12l-2 2H1V3h11v10z\"/></svg>",
  "clock-arrow-left": "<svg viewBox=\"0 0 16 13.7\"><path d=\"M11.9 10.6L8.6 7.3V3.9h1.1v2.9l3 3.1-.8.7zM9.2 0C5.5 0 2.6 2.8 2.3 6.4h1.1C3.7 3.2 6.5.8 9.7 1.1s5.6 3.1 5.3 6.3c-.3 3-2.8 5.3-5.8 5.3v1.1c3.8 0 6.9-3.1 6.9-6.9S12.9 0 9.2 0zM0 6.4l3.1 3.1 3.1-3.1H0z\"/></svg>",
  "clock-checkmark": "<svg viewBox=\"0 0 15.8 15.7\"><path d=\"M8.3 7.4l3.2 3.1-.7.7-3.5-3.3v-4h1v3.5zm6.1-.6c-.6-3.6-4-6.1-7.6-5.5s-6.1 4-5.5 7.6c.4 2.4 2.1 4.5 4.5 5.2v1.3C1.6 14.3-.8 10 .3 5.8S5.7-.9 9.9.3c3 .8 5.3 3.4 5.7 6.5h-1.2zm-4.5 8.9l5.9-5.9-.8-.7-5.2 5.2-2.9-2.9-.7.6 3.7 3.7z\"/></svg>",
  "clock-money": "<svg viewBox=\"0 0 15.7 15.7\"><path d=\"M8.6 7.1l3.4 3.4-.7.7S7.6 7.6 7.6 7.5V3.7h1v3.4zm-.5 8c4.2 0 7.5-3.4 7.6-7.5 0-4.2-3.4-7.5-7.5-7.6-2.8 0-5.3 1.5-6.6 3.8h1.3c2-2.9 6-3.6 8.9-1.6s3.6 6 1.6 8.9a6.31 6.31 0 0 1-5.2 2.7l-.1 1.3zm-4.5-2.5c0-.2-.1-.5-.2-.7-.2-.2-.5-.4-.8-.5-.3-.1-.7-.3-1-.4-.3-.1-.5-.3-.7-.5-.2-.2-.3-.4-.5-.6C.3 9.6.3 9.3.3 9c0-.5.2-1.1.6-1.4.3-.4.9-.6 1.4-.7V5.8h.8V7c.6 0 1.1.3 1.5.7.4.5.5 1.1.5 1.7H3.6c0-.3-.1-.7-.3-.9-.1-.3-.4-.4-.6-.4-.3 0-.5.1-.7.3-.2.2-.2.4-.2.6 0 .2.1.5.2.6.2.2.5.4.8.5.3.1.7.3 1 .5.2.1.5.3.7.5.2.2.3.4.4.6.1.3.2.5.1.8 0 .5-.2 1-.5 1.4-.4.4-.9.6-1.5.6v1h-.8v-1c-.6 0-1.2-.3-1.6-.8-.4-.3-.6-.9-.6-1.5h1.5c0 .3.1.7.3.9.2.2.5.3.8.3.3 0 .5-.1.7-.2.2-.1.3-.4.3-.6z\"/></svg>",
  "clock": "<svg viewBox=\"0 0 15.7 15.7\"><path d=\"M8.3 7.4l3.6 3.6-.7.7-3.9-3.8v-4h1v3.5zm-.5 8.3C3.5 15.7 0 12.2 0 7.8S3.5 0 7.8 0s7.8 3.5 7.8 7.8c.1 4.4-3.4 7.9-7.8 7.9zm0-14.5c-3.7 0-6.6 3-6.6 6.6s3 6.6 6.6 6.6 6.6-3 6.6-6.6c.1-3.6-2.9-6.6-6.6-6.6z\"/></svg>",
  "columns-x": "<svg viewBox=\"0 0 14 16\"><path d=\"M0 0v16h2V0H0zm5 0v16H3V0h2zm3 0v8H7v8H6V0h2zm3 0v8H9V0h2zm3 0v8h-2V0h2zm-3.5 13l-2.1-2.1.7-.7 2.1 2.1 2.1-2.1.7.7-2.1 2.1 2.1 2.1-.7.7-2.1-2.1L9 15.8l-.7-.7 2.2-2.1z\"/></svg>",
  "columns": "<svg viewBox=\"0 0 16 16\"><path d=\"M0 0v16h16V0H0zm1 4.6h6.5V15H1V4.6zM15 15H8.5V4.6H15V15z\"/></svg>",
  "contact-card": "<svg viewBox=\"0 0 15.7 11\"><path d=\"M11.8 3h-4v1h4V3zm2 2h-6v1h6V5zm-1 2h-5v1h5V7zm2.9-7v11H0V0h15.7zm-.9 10V1H.8v9h14zM5.7 4.3c0 .7-.6 1.3-1.3 1.3-.8.1-1.4-.5-1.4-1.3C3 3.6 3.6 3 4.3 3c.8 0 1.4.6 1.4 1.3m1 2.5C6.2 6.5 5.6 6.2 5 6c-.4.3-.9.3-1.3 0-.6.2-1.2.5-1.8.8-.1.4-.1.8-.1 1.2h5c.1-.4 0-.8-.1-1.2z\"/></svg>",
  "container-arrow-down": "<svg viewBox=\"0 0 16 15.9\"><path d=\"M16 10v5.9H0V10h1v4.9h14V10h1zm-7.9 0l4-4-.7-.7-2.9 2.9V0h-.9v8.1L4.7 5.4l-.7.7L8.1 10z\"/></svg>",
  "container-arrow-up": "<svg viewBox=\"0 0 16 15.9\"><path d=\"M16 10v5.9H0V10h1v4.9h14V10h1zM8.1 0l-4 4 .7.7 2.8-2.9V10h.9V1.9l2.9 2.8.7-.7-4-4z\"/></svg>",
  "credit-card": "<svg viewBox=\"0 0 16 12\"><path d=\"M0 0v12h16V0H0zm15 1v1.8H1V1h14zM1 11V5.8h14V11H1zm13-1.8H6.8v-1H14v1z\"/></svg>",
  "cylinder-stack": "<svg viewBox=\"0 0 14 16\"><path d=\"M14 2c0 1.1-3.1 2-7 2s-7-.9-7-2 3.1-2 7-2 7 .9 7 2zM7 6c-.6 0-5-.1-7-1.4V6c0 1.1 3.1 2 7 2s7-.9 7-2V4.6C12 5.9 7.6 6 7 6zm0 4c-.6 0-5-.1-7-1.4V10c0 1.1 3.1 2 7 2s7-.9 7-2V8.6C12 9.9 7.6 10 7 10zm0 4c-.6 0-5-.1-7-1.4V14c0 1.1 3.1 2 7 2s7-.9 7-2v-1.4C12 13.9 7.6 14 7 14z\"/></svg>",
  "document-arrow-down": "<svg viewBox=\"0 0 13.3 16\"><path d=\"M6.4 15H1V1h8v3h3v4h1V3l-3-3H0v16h6.5l-.1-1zm4 1l-2.9-3.5.6-.5 1.9 2.3V9h.7v5.3l1.9-2.3.6.4-2.8 3.6z\"/></svg>",
  "document-arrow": "<svg viewBox=\"0 0 13 16\"><path d=\"M4.4 15H1V1h8v3h3v4h1V3l-3-3H0v16h4.4v-1zm8.6-2.1L9.3 16l-.5-.6 2.4-2.1H5.9v-.7h5.3l-2.5-2.1.4-.6 3.9 3z\"/></svg>",
  "document-check": "<svg viewBox=\"0 0 13 16\"><path d=\"M3.5 15H1V1h8v3h3v5h1V3l-3-3H0v16h3.5v-1zM8 16l5-4.9-.7-.7L8 14.6l-2.4-2.4-.7.7L8 16z\"/></svg>",
  "document-clock": "<svg viewBox=\"0 0 13 16\"><path d=\"M5 15H1V1h8v3h3v4c.4.3.7.6 1 1V3l-3-3H0v16h6c-.3-.3-.7-.6-1-1zm4 1c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.7 4-4 4zm0-7c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zm.2 2.8l1.4 1.4-.5.5S8.5 12.1 8.5 12v-1.9h.8l-.1 1.7z\"/></svg>",
  "document-csv": "<svg viewBox=\"0 0 13 16\"><path d=\"M10 0H0v16h13V3l-3-3zm2 15H1V1h8v3h3v11zm-7.8-3.6l.7.3c-.1.4-.3.8-.5 1.1-.3.2-.6.3-.9.3-.4 0-.8-.2-1.1-.5-.3-.5-.5-1.1-.5-1.7 0-.6.1-1.2.5-1.7.2-.4.6-.6 1-.6s.7.1 1 .4c.2.2.4.5.4.9l-.7.2c0-.2-.1-.4-.3-.5-.1-.2-.2-.2-.4-.2-.3 0-.5.1-.6.3-.2.3-.3.7-.2 1.1 0 .4.1.8.2 1.2.1.2.4.3.6.3.2 0 .4-.1.5-.2.1-.2.2-.4.3-.7zm1.1.2l.7-.1c.1.5.3.8.8.8.2 0 .4 0 .5-.2.1-.1.2-.3.2-.4 0-.1 0-.2-.1-.2 0-.1-.1-.1-.2-.2-.2-.1-.4-.2-.6-.2-.2-.1-.5-.2-.7-.3-.1-.1-.3-.3-.3-.4-.2-.2-.2-.4-.2-.6 0-.2.1-.4.2-.6.1-.2.2-.3.4-.4.2-.1.5-.2.7-.1.4 0 .7.1 1 .3.2.2.3.6.3.9h-.7c0-.2-.1-.4-.2-.5-.1 0-.3-.1-.4-.1-.2 0-.3 0-.4.1-.2.1-.2.2-.2.4 0 .1 0 .2.1.3l.6.3c.3.1.5.2.8.3.2.1.3.3.4.4.1.2.1.4.1.7 0 .3-.1.7-.3.9-.3.3-.7.4-1.1.4-.8 0-1.3-.5-1.4-1.5zM9.6 13L8.4 8.7h.8l.9 3.2.9-3.2h.8L10.4 13h-.8z\"/></svg>",
  "document-dollar-sign": "<svg viewBox=\"0 0 13 16.1\"><path d=\"M6.5 15H1V1h8v3h3v4h1V3l-3-3H0v16h6.5v-1zm5.1-1.1c0-.2-.1-.4-.2-.5-.2-.2-.4-.3-.6-.3-.2-.1-.5-.2-.7-.3-.2-.1-.4-.2-.5-.3-.1-.1-.3-.3-.3-.5-.1-.2-.1-.4-.1-.6 0-.4.1-.8.4-1 .3-.3.7-.4 1.1-.5V9h.6v.8c.4 0 .8.2 1 .6.3.3.4.8.4 1.2h-1.1c0-.2 0-.5-.2-.7-.1-.1-.3-.2-.5-.2s-.3.1-.5.2c-.1.1-.2.3-.2.5s.1.3.2.5c.2.2.4.3.6.4.3.1.5.2.7.3.2.1.3.2.5.4.1.1.2.3.3.5.1.2.1.4.1.6 0 .4-.1.8-.4 1-.3.3-.7.4-1.1.5v.7h-.6v-.7c-.4 0-.9-.2-1.2-.5-.1-.6-.3-1.1-.3-1.5h1.1c0 .2.1.5.2.7.2.2.4.3.6.2.2 0 .4-.1.5-.2.2-.1.3-.3.2-.4z\"/></svg>",
  "document-lines": "<svg viewBox=\"0 0 13 16\"><path d=\"M2.2 12h6v1h-6v-1zm0-2h8.4V9H2.2v1zm0-3h7V6h-7v1zm0-3h3V3h-3v1zM10 0H0v16h13V3l-3-3zm2 15H1V1h8v3h3v11z\"/></svg>",
  "document-magnify": "<svg viewBox=\"0 0 14.1 15.9\"><path d=\"M4.7 14H1V1h8v3h3v3.2c.4.3.7.6 1 1V3l-3-3H0v15h5.7c-.4-.3-.7-.6-1-1zm3.8.7c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.7 4-4 4zm0-7c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zm5.6 7.2l-1 1-2.2-2.2 1-1 2.2 2.2z\"/></svg>",
  "document-minus": "<svg viewBox=\"0 0 13 16\"><path d=\"M13 13H6v-1h7v1zm-7.5 2H1V1h8v3h3v4h1V3l-3-3H0v16h5.5v-1z\"/></svg>",
  "document-payment": "<svg viewBox=\"0 0 13 16\"><path d=\"M2.5 15H1V1h8v3h3v4h1V3l-3-3H0v16h2.5v-1zm9.9-5.4v5.8H4.2V9.6h8.2M13 9H3.7v7H13V9zm-7.8 1.5v4.1h6.3v-4.1H5.2zm1.4 2.3h-.9V12h.9v.8zm1.7.9c-.6 0-1.2-.5-1.2-1.2 0-.6.5-1.2 1.2-1.2s1.2.5 1.2 1.2c0 .6-.5 1.2-1.2 1.2zm2.6-.9H10V12h.9v.8z\"/></svg>",
  "document-pdf": "<svg viewBox=\"0 0 13 16\"><path d=\"M10 0H0v16h13V3l-3-3zm2 15H1V1h8v3h3v11zM1.9 13V8.7H3c.3 0 .6 0 .8.1.3 0 .5.2.6.4.1.2.2.5.2.8 0 .3-.1.5-.2.8-.1.2-.2.3-.4.4-.3.1-.6.1-.9.1h-.5V13h-.7zm.7-3.6v1.2H3c.2 0 .4 0 .5-.1.1 0 .2-.1.2-.2.1-.2.1-.5 0-.7 0 0-.1-.1-.2-.1-.2 0-.4-.1-.6-.1h-.3zm2.6-.7h1.3c.3 0 .5 0 .7.1.2.1.3.2.5.4.1.2.2.4.3.7.1.3.1.7.1 1 0 .3 0 .7-.1 1-.1.2-.2.5-.3.7-.1.2-.3.3-.5.4h-2V8.7zm.7.7v2.8h.5c.2 0 .3 0 .5-.1.1 0 .2-.1.3-.2.1-.1.1-.3.2-.4 0-.2.1-.5.1-.7 0-.3 0-.5-.1-.8 0-.2-.1-.3-.2-.4-.2 0-.3-.1-.4-.1h-.6l-.3-.1zM8.7 13V8.7h2.4v.7H9.4v1h1.5v.7H9.4V13h-.7z\"/></svg>",
  "document-pencil": "<svg viewBox=\"0 0 13 16\"><path d=\"M4.5 15H1V1h8v3h3v4h1V3l-3-3H0v16h4.5v-1zm1.6 1l.1-.9.1-.9.8.8.9.7-1 .2-.9.1zm5.2-4.1l-3 3-.3-.3 3-3-.5-.5-3 3-.3-.3 3-3-.3-.3-3.3 3.3 1.6 1.6 3.3-3.3-.2-.2zm0-2.7l-.8.8 1.6 1.6.8-.8-1.6-1.6z\"/></svg>",
  "document-plus": "<svg viewBox=\"0 0 13 16\"><path d=\"M13 13H6v-1h7v1zm-4 3V9h1v7H9zm-3.5-1H1V1h8v3h3v4h1V3l-3-3H0v16h5.5v-1z\"/></svg>",
  "document-question": "<svg viewBox=\"0 0 13.2 16\"><path d=\"M7.6 16H0V0h10l3 3v5h-1V4H9V1H1v14h6.6v1zm4.2-2.7c.1-.2.3-.4.5-.6.3-.3.5-.6.7-.9.1-.2.2-.5.2-.8.1-1.1-.8-2-1.8-2h-.2c-.6 0-1.2.2-1.6.6-.4.5-.6 1.1-.6 1.7l1.5.2c0-.3.1-.6.2-.8.1-.1.3-.2.4-.2.1 0 .3 0 .4.1.1.1.1.3.1.4 0 .2-.1.4-.3.6-.3.3-.5.5-.7.8-.1.2-.2.3-.2.5-.1.2-.1.5-.1.8v.2h1.4c-.1-.2 0-.4.1-.6zM10.3 16h1.4v-1.4h-1.4V16z\"/></svg>",
  "document-slash": "<svg viewBox=\"0 0 13 16\"><path d=\"M5 15H1V1h8v3h3v4c.4.3.7.6 1 1V3l-3-3H0v16h6c-.3-.3-.7-.6-1-1zm4 1c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.7 4-4 4zm0-7c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zm-2.7 5.1l4.9-4.9.8.7-5 5-.7-.8z\"/></svg>",
  "document-xls": "<svg viewBox=\"0 0 13 16\"><path d=\"M10 0H0v16h13V3l-3-3zm2 15H1V1h8v3h3v11zM1.7 13L3 10.8 1.9 8.7h.8l.7 1.3.7-1.3h.8l-1.1 2.1L5 13h-.8l-.8-1.5-.8 1.5h-.9zm6.8-1.4l.7-.1c.1.5.3.8.8.8.2 0 .4 0 .5-.2.1-.1.2-.3.2-.4 0-.1 0-.2-.1-.2 0-.1-.1-.1-.2-.2-.2-.1-.4-.2-.6-.2-.2-.1-.5-.2-.7-.3-.1-.1-.3-.3-.3-.4-.1-.2-.1-.4-.1-.6 0-.2.1-.4.2-.6 0-.2.2-.3.4-.4.2-.1.4-.2.7-.2.7-.1 1.3.4 1.3 1.1v.2h-.7c0-.2-.1-.4-.2-.5-.1-.1-.3-.1-.4-.1-.2 0-.3 0-.4.1-.2.1-.3.2-.3.4 0 .1 0 .2.1.3l.6.3c.3.1.5.2.8.3.2.1.3.3.4.4.1.2.1.4.1.7 0 .3-.1.7-.3.9-.3.3-.7.4-1.1.4-.8 0-1.3-.5-1.4-1.5zM5.7 13V8.7h.7v3.5h1.8v.8H5.7z\"/></svg>",
  "document-xml": "<svg viewBox=\"0 0 13 16\"><path d=\"M10 0H0v16h13V3l-3-3zm2 15H1V1h8v3h3v11zM1.5 13l1.2-2.2-1.1-2.1h.8l.8 1.3.7-1.3h.8l-1.1 2.1L4.8 13h-.9l-.8-1.5-.7 1.5h-.9zm3.6 0V8.7h1.1l.6 2.9.6-2.9h1.1V13h-.6V9.6L7.2 13h-.7l-.7-3.4V13h-.7zm4.2 0V8.7h.7v3.5h1.8v.8H9.3z\"/></svg>",
  "document": "<svg viewBox=\"0 0 13 16\"><path d=\"M10 0H0v16h13V3l-3-3zm2 15H1V1h8v3h3v11z\"/></svg>",
  "documents": "<svg viewBox=\"0 0 14 16\"><path d=\"M11 0H3v3H0v13h11v-3h3V3l-3-3zm-1 15H1V4h2v9h7v2zm3-3H4V1h6v3h3v8z\"/></svg>",
  "door-arrow-in": "<svg viewBox=\"0 0 15 16\"><path d=\"M.5 1.1v11H11v1H0V0h11.1v2h-1V1H.5zM5.4 16L.3 12.8V.3l5.1 3.2V16zm2-12.6h1v5.3l6-6 .6.6-6 6.1h5.3v1h-7l.1-7z\"/></svg>",
  "door-arrow-out": "<svg viewBox=\"0 0 15 16\"><path d=\"M.5 1.1v11H10v-2h1v3H0V0h11.1v1.1H.5zM5.4 16L.3 12.8V.3l5.1 3.2V16zM15 9.7h-1V4.4l-6 6-.6-.6 6-6.1H8v-1h7v7z\"/></svg>",
  "drawer-arrow-down": "<svg viewBox=\"0 0 16 16\"><path d=\"M13.9 5H12v1h1.2l1.3 3H11c0 1.7-1.3 3-3 3s-3-1.3-3-3H1.5l1.3-3H4V5H2.1L0 10v6h16v-6l-2.1-5zM4.4 6.5l.7-.7 2.4 2.3.1-8.1h.9v8.2l2.4-2.4.7.7L8.1 10 4.4 6.5z\"/></svg>",
  "drawer-arrow-up": "<svg viewBox=\"0 0 16 16\"><path d=\"M13.9 5H11v1h2.2l1.3 3H11c0 1.7-1.3 3-3 3s-3-1.3-3-3H1.5l1.3-3H5V5H2.1L0 10v6h16v-6l-2.1-5zm-2.3-1.5l-.7.7-2.4-2.3V10h-1V1.8L5.2 4.2l-.7-.7L8 0l3.6 3.5z\"/></svg>",
  "drawer": "<svg viewBox=\"0 0 16 11\"><path d=\"M13.9 0H2.1L0 5v6h16V5l-2.1-5zM11 4c0 1.7-1.3 3-3 3S5 5.7 5 4H1.5l1.3-3h10.4l1.3 3H11z\"/></svg>",
  "envelope-document": "<svg viewBox=\"0 0 15.8 14.9\"><path d=\"M15.8 14.8L10.5 11l5.3-3.8v7.6zM0 7.1v7.7L5.3 11 0 7.1zm6.2 4.4l-4.6 3.3h12.7l-4.6-3.3H6.2zm7.2-2V4h-3V1h-8v7.8l-1-.7V0h10l3 3v6.5h-1z\"/></svg>",
  "envelope-open": "<svg viewBox=\"0 0 15.8 13.4\"><path d=\"M15.8 5.6v7.7l-5.3-3.8 4.4-3.2m-8.7 3.8l-4.6 3.3h12.7l-4.6-3.3H6.2zM0 5.6L7.9 0l7.9 5.6-.6.8-.3-.2-7-5-7 5H1l4.4 3.1-5.4 4\"/></svg>",
  "envelope": "<svg viewBox=\"0 0 15.8 7.9\"><path d=\"M15.8 7.8L10.5 4 15.8.2v7.6zM0 .1v7.7L5.3 4 0 .1zm6.2 4.4L1.5 7.9h12.7L9.6 4.5 7.9 5.8 6.2 4.5zm8-4.5H1.6l6.3 4.6L14.2 0z\"/></svg>",
  "exclamation-circle-solid": "<svg viewBox=\"0 0 15.7 15.7\"><path d=\"M13.4 2.3C10.3-.8 5.3-.8 2.3 2.3s-3.1 8.1 0 11.1c3.1 3.1 8.1 3.1 11.1 0 3.1-3 3.1-8 0-11.1zM8.8 12.9h-2v-2h2v2zm0-3.1h-2v-7h2v7z\"/></svg>",
  "exclamation-circle": "<svg viewBox=\"0 0 15.7 15.7\"><path d=\"M2.3 13.4c-3.1-3.1-3.1-8.1 0-11.1s8.1-3.1 11.1 0 3.1 8.1 0 11.1c-3 3.1-8 3.1-11.1 0zM12.7 3C10 .3 5.7.3 3 3s-2.7 7 0 9.7 7 2.7 9.7 0c2.7-2.7 2.7-7 0-9.7zM6.8 9.8v-7h2v7h-2zm0 3.1v-2h2v2h-2z\"/></svg>",
  "exclamation-triangle-solid": "<svg viewBox=\"0 0 15.8 14.8\"><path d=\"M8 0L0 14.8h15.8L8 0zm1 12.2H7v-2h2v2zm-.1-3h-2v-5h2v5z\"/></svg>",
  "exclamation-triangle": "<svg viewBox=\"0 0 16 14.7\"><path d=\"M16 14.7H0L8.1 0 16 14.7zm-14.3-1h12.6L8.1 2 1.7 13.7zM8.5 12H7.4v-1.1h1.1V12zm.1-2.1H7.5V5.8h1.1v4.1z\"/></svg>",
  "eye-line": "<svg viewBox=\"0 0 14 12.7\"><path d=\"M1.3 12.7L.6 12l12-12 .7.7-12 12zm10.6-9.2L10 5.4c.1.3.1.6.1.9 0 1.8-1.4 3.3-3.2 3.3-.3 0-.7-.1-1-.2l-1.4 1.4c3.1 1.2 6.7 0 9.3-4.2v-.5c-.4-1-1.1-1.8-1.9-2.6zM3.8 6.3C3.8 4.5 5.2 3.1 7 3c.3 0 .7.1 1 .2l1.3-1.3C6.2.7 2.6 1.8 0 6.1v.5c.6 1 1.2 1.8 2 2.6l1.9-1.9c-.1-.3-.1-.6-.1-1z\"/></svg>",
  "eye": "<svg viewBox=\"0 0 14 9.8\"><path d=\"M0 4.6v.5c3.4 5.8 9.8 6.6 14 0v-.5C10.5-1.1 4.1-2 0 4.6zm7 3.6c-1.8 0-3.2-1.5-3.2-3.3 0-1.8 1.4-3.3 3.2-3.3 1.8 0 3.3 1.4 3.3 3.2v.1C10.2 6.7 8.8 8.2 7 8.2zm1.6-3.3c0 .9-.7 1.6-1.6 1.6-.9 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.7.9.1 1.6.8 1.6 1.7z\"/></svg>",
  "filter-stacked": "<svg viewBox=\"0 0 16 14\"><path d=\"M5.5 2V0H16v2H5.5zM0 4v2h4.5V4H0zm16 2V4H5.5v2H16zm-3.3 4V8H5.5v2h7.2zM.5 8v2h3.9V8H.5zm.6 4v2h3.4v-2H1.1zm10.5 2v-2H5.5v2h6.1z\"/></svg>",
  "filter": "<svg viewBox=\"0 0 14 16\"><path d=\"M14 0H0l6.2 8.1V16l2-2V8.1L14 0z\"/></svg>",
  "flag": "<svg viewBox=\"0 0 15.7 14.6\"><path d=\"M11.8 0c-1.7.8-3.6.9-5.3.4C3.6-.6.8 1.7.8 1.7l-.8.6 7.9 12.4.9-.6L5 8.2C6.2 6.7 7.8 6.1 10.7 7c1.7.5 3.5.4 5-.4L11.8 0z\"/></svg>",
  "floppy-disk": "<svg viewBox=\"0 0 16 16\"><path d=\"M8 14H6v-2h2v2zm8-14v14l-2 2H0V0h16zm-5 11H5v4h6v-4zm2-10H3v7h10V1z\"/></svg>",
  "folder-lock": "<svg viewBox=\"0 0 15.7 12\"><path d=\"M15 7.9v-.8C15 6 14.1 5 12.9 5c-1.2 0-2.1.9-2.1 2.1v.7h-.7V12h5.6V7.9H15zm-.9 0h-2.4v-.8c0-.6.5-1.2 1.2-1.2.6 0 1.2.5 1.2 1.2v.8zm-6-2V12H0V0h4.1l2.8 2h8.8v2.1c-1.7-1.6-4.3-1.5-5.8.2-.4.5-.7 1-.9 1.6h-.9z\"/></svg>",
  "folder-minus": "<svg viewBox=\"0 0 15.7 12\"><path d=\"M15.7 9H8.9V8h6.8v1zM6.9 6h8.8V2H6.9L4.1 0H0v12h15.7v-1H6.9V6z\"/></svg>",
  "folder-open": "<svg viewBox=\"0 0 16 13\"><path d=\"M14 13H2L0 5h16l-2 8zM1 0h4l3 2h7v1H1V0z\"/></svg>",
  "folder-plus": "<svg viewBox=\"0 0 15.7 12\"><path d=\"M15.7 9H8.9V8h6.8v1zm-3.8 3V5h1v7h-1zm-5-1V6h3V3h5v3h.9V2H6.9L4.1 0H0v12h9.9v-1h-3z\"/></svg>",
  "folder-zip": "<svg viewBox=\"0 0 14 16\"><path d=\"M6.9 0v1H13v10l-8.1.1v.9H14V0H6.9zm3 14v-2h-1v2l-2 1v1h5v-1l-2-1zM0 1h3.4l1.8 1.4h5.9v7.1H0V1z\"/></svg>",
  "folder": "<svg viewBox=\"0 0 15.7 12\"><path d=\"M0 12h15.7V2H7.4L4.8 0H0z\"/></svg>",
  "gauge": "<svg viewBox=\"0 0 15.7 13.1\"><path d=\"M9.7 1.2C9.1 1 8.5.9 7.9 1 4.1 1 1 4 1 7.9c0 1.5.5 3 1.4 4.2l.9 1H2l-.1-.2C-1 9.5-.5 4.6 2.9 1.8 4.9.1 7.7-.4 10.2.3l-.5.9zm3.4.8c3.2 2.8 3.5 7.6.8 10.9l-.1.2h-1.6c.2 0 1.1-1 1.2-1 .9-1.2 1.4-2.7 1.4-4.2 0-1.9-.7-3.7-2.1-4.9l.4-1zM6.9 9.4c-.9-.5-1.2-1.7-.7-2.6.5-.9 6.1-6.7 6.1-6.7S9.9 8.2 9.6 8.7c-.6.9-1.8 1.2-2.7.7z\"/></svg>",
  "gavel": "<svg viewBox=\"0 0 14.8 14.8\"><path d=\"M3.5 10.6l-.7.7L0 8.5l.7-.7 2.8 2.8zm3.6-9.2L1.4 7.1l2.8 2.8 5.7-5.7-2.8-2.8zM8.5 0l-.7.7 2.8 2.8.7-.7L8.5 0zm0 7.1L7.1 8.5l6.3 6.3 1.4-1.4-6.3-6.3z\"/></svg>",
  "gear": "<svg viewBox=\"0 0 16 16\"><path d=\"M12.6 9V7h.3c-.1-.6-.4-1.2-.7-1.8l-.2.3-1.4-1.4.2-.2c-.6-.4-1.2-.7-1.8-.8v.3H7v-.3c-.6.1-1.2.4-1.7.7l.2.2-1.4 1.5-.3-.2c-.3.5-.5 1.1-.7 1.7h.3v2h-.3c.1.6.4 1.2.7 1.7l.2-.2L5.5 12l-.2.2c.5.4 1.1.6 1.7.7v-.3h2v.3c.6-.1 1.2-.4 1.7-.7l-.2-.2 1.4-1.4.2.2c.4-.5.6-1.1.7-1.8h-.2zM8 10.9c-1.6 0-2.9-1.3-2.9-2.9 0-1.6 1.3-2.9 2.9-2.9 1.6 0 2.9 1.3 2.9 2.9S9.6 10.9 8 10.9zM7 3.4V0h2v3.4H7zm2 9.2V16H7v-3.4h2zM12.6 7H16v2h-3.4V7zM3.4 9H0V7h3.4v2zm7.2-4.9l.2-.2L13 1.6 14.4 3l-2.2 2.2-.2.3-1.4-1.4zM5.5 12l-.2.2-2.2 2.2L1.6 13l2.2-2.2.2-.2L5.5 12zm6.5-1.5l.2.2 2.2 2.2-1.4 1.5-2.2-2.2-.3-.2 1.5-1.5zm-7.9-5l-.3-.2-2.2-2.2L3 1.7l2.2 2.2.2.2-1.3 1.4z\"/></svg>",
  "gears": "<svg viewBox=\"0 0 16 16\"><path d=\"M8.3 11.4V10h.2c-.1-.4-.2-.8-.5-1.1l-.1.1-1-.9.2-.2c-.4-.2-.8-.3-1.2-.4v.2H4.6v-.2c-.4 0-.8.2-1.1.4l.1.1-.9 1-.2-.1c-.2.3-.4.7-.4 1.1h.2v1.3h-.2c.1.4.2.8.5 1.1l.1-.1.9.9-.1.1c.3.2.7.4 1.1.5v-.2h1.3v.2c.4-.1.8-.2 1.1-.5l-.1-.1.9-.9.2.2c.2-.3.4-.7.5-1.2h-.2zm-3 1.2c-1.1 0-1.9-.9-1.9-1.9s.9-1.9 1.9-1.9 1.9.9 1.9 1.9c0 1-.9 1.9-1.9 1.9zm-.7-4.9V5.4h1.3v2.3H4.6zm1.3 6V16H4.6v-2.3h1.3zM8.3 10h2.3v1.3H8.3V10zm-6 1.4H0V10h2.3v1.4zm4.6-3.3l.2-.2 1.4-1.4.9.9L8 8.9l-.1.1-1-.9zm-3.3 5.2l-.1.1L2 14.9l-.9-.9 1.5-1.5.1-.1.9.9zm4.3-.9l.1.1L9.5 14l-.9.9-1.5-1.5-.1-.1.9-.9zM2.7 9l-.2-.1-1.4-1.5.9-.9 1.5 1.4.1.1-.9 1zm11.8-5.1V3h.1c-.1-.3-.2-.5-.3-.8l-.1.1-.6-.6.1-.1c-.2-.2-.5-.3-.8-.3v.1H12v-.1c-.3.1-.5.2-.8.3l.1.1-.6.6-.1-.1c-.2.2-.3.5-.3.8h.1v.9h-.1c.1.3.2.5.3.8l.1-.1.6.6-.1.1c.2.2.5.3.8.3v-.1h.9v.1c.3-.1.5-.2.8-.3l-.1-.1.6-.6.1.1c.2-.2.3-.5.3-.8h-.1zm-2 .8c-.7 0-1.3-.6-1.3-1.3 0-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3-.1.8-.6 1.3-1.3 1.3zm-.4-3.2V0h.9v1.5h-.9zm.8 4V7H12V5.5h.9zM14.5 3H16v.9h-1.5V3zm-4 .9H9V3h1.5v.9zm3.1-2.1l.1-.1 1-1 .6.6-1 1-.1.1-.6-.6zm-2.2 3.4l-1.1 1.1-.6-.7 1-1 .1-.1.6.7zm2.8-.6l1.1 1-.6.6-1-1-.1-.1.6-.5zm-3.4-2.2L9.7 1.3l.6-.6 1 1 .1.1-.6.6z\"/></svg>",
  "globe-gears": "<svg viewBox=\"0 0 14.7 15.5\"><path d=\"M8.2 6H5.9c0-.7.1-1.5.3-2.2h2V6zm0-2.6H6.3c.3-1 1-1.9 1.8-2.5v2.5zm5.2.4c.3.7.5 1.4.5 2.2h-2.1c0-.7-.1-1.5-.2-2.2h1.8c-.1 0-.1 0 0 0zm-1.1-1.5c.3.3.6.7.9 1.1h-1.7c-.3-.9-.7-1.7-1.3-2.4.8.3 1.5.7 2.1 1.3zM8.9 1c.8.6 1.4 1.5 1.7 2.4H8.9V1zm0 2.8h1.8c.3.7.4 1.5.4 2.2H8.9V3.8zm0 2.6H11c0 .7-.1 1.5-.4 2.2H8.9V6.4zm0 3.5V9h1.6c-.1.4-.3.7-.5 1l.6.5c.3-.5.6-1 .8-1.6h1.7c-.5.8-1.2 1.5-2.1 1.9l.6.6c1.9-1.1 3.1-3.1 3.1-5.3 0-1.6-.7-3.2-1.8-4.3C11.7.6 10.1 0 8.5 0h-.2-.1c-.1 0-3.1 1.3-3.1 6v.9H6v-.5h2.2v2.2h-.6V9h.6v1l.7-.1zm4.4-1.3c-.1 0-.1 0 0 0h-1.8c.2-.7.3-1.5.3-2.2h2.1c-.1.8-.3 1.5-.6 2.2zM2.5 6.9c0-.3-.1-.5-.1-.8 0-2.6 1.7-5 4.2-5.8C5.1 1.8 4.3 4 4.3 6.2V7l-1.8-.1zm3 4.7v-.9h.1c-.1-.3-.2-.5-.3-.8l-.1.1-.6-.5.1-.1c-.3-.2-.5-.3-.8-.3v.1H3v-.1c-.2 0-.5.1-.7.3l.1.1-.6.6-.1-.1c-.2.2-.3.5-.3.8h.1v.9h-.1c.1.3.2.5.3.8l.1-.1.6.6h-.1c.2.2.5.3.8.3v-.1H4v.1c.3-.1.5-.2.8-.3l-.1-.1.6-.6.1.1c.2-.2.3-.5.3-.8h-.2zm-2 .8c-.7 0-1.3-.6-1.3-1.3s.6-1.3 1.3-1.3 1.3.6 1.3 1.3c-.1.8-.6 1.3-1.3 1.3zM3 9.2V7.7h.9v1.5H3zm.9 4v1.5H3v-1.5h.9zm1.6-2.5H7v.9H5.5v-.9zm-4 .9H0v-.9h1.5v.9zm3.1-2.1l.1-.1 1-1 .5.6-1 1-.1.1-.5-.6zm-2.2 3.4L1.3 14l-.6-.6 1-1 .1-.1.6.6zm2.8-.6l1 1.1-.6.6-1-1-.1-.1.7-.6zm-3.4-2.2L.7 9l.6-.6 1 1 .1.1-.6.6zm8.5 3.4v-.6h.1c0-.2-.1-.3-.2-.5l-.1.1-.4-.4.1-.1c-.2-.1-.3-.2-.5-.2v.1h-.6v-.1c-.2 0-.3.1-.5.2l.1.1-.4.4-.1-.1c-.1.2-.2.3-.2.5h.1v.6h-.1c0 .2.1.3.2.5l.1-.1.4.4-.1.1c.2.1.3.2.5.2v-.1h.6v.1c.2 0 .3-.1.5-.2l-.1-.1.4-.4.1.1c.1-.2.2-.3.1-.5zM9 14c-.5 0-.8-.4-.8-.8s.4-.8.8-.8.8.4.8.8c0 .5-.3.8-.8.8zm-.3-2.1v-1h.6v1h-.6zm.6 2.6v1h-.6v-1h.6zm1-1.6h1v.6h-1v-.6zm-2.6.6h-1v-.6h1v.6zm2-1.4l.7-.7.4.4-.6.6-.1.1-.4-.4zm-1.4 2.2l-.7.7-.4-.4.6-.6.1-.1.4.4zm1.8-.4l.7.7-.4.4-.6-.6-.1-.1.4-.4zm-2.2-1.4l-.7-.7.4-.4.6.6.1.1-.4.4z\"/></svg>",
  "globe": "<svg viewBox=\"0 0 15.7 15.7\"><path d=\"M7.9 0h.2C8.3.1 12 1.6 12 7.8c0 3.5-1.3 5.6-2.3 6.7 3-.8 5-3.5 5-6.6 0-.3.2-.5.5-.5s.5.2.5.5c0 4.3-3.5 7.9-7.9 7.9C3.5 15.7 0 12.2 0 7.9 0 3.5 3.5 0 7.9 0M1.8 11.1H4.1c-.3-.9-.4-1.9-.4-2.8H1c.1.9.3 1.9.8 2.8m5.6 3.2v-2.8H5.3c.4 1.2 1.1 2.1 2.1 2.8m0-3.3V8.2H4.7c0 1 .2 1.9.5 2.8h2.2zm3.3-6.1H8.4v2.8h2.7c0-.9-.2-1.9-.4-2.8m-5.5-.5h2.2V1.3c-1.1.8-1.9 1.9-2.2 3.1m-.2.5c-.2.9-.4 1.9-.3 2.8h2.7V4.9H5zm6 3.3H8.4V11h2.2c.3-.9.4-1.8.4-2.8m-7.3-.5c0-.9.1-1.9.3-2.8H1.7h-.1c-.4.9-.6 1.8-.6 2.8h2.7zM1.9 4.4h2.2c.3-1.1.9-2.2 1.8-3.1-1.7.5-3.1 1.6-4 3.1m6.5-3.1v3.1h2.2c-.4-1.2-1.2-2.3-2.2-3.1m2 10.2h-2v2.8c.9-.7 1.6-1.7 2-2.8m-4.4 3c-.8-.8-1.4-1.8-1.8-2.9H2.1C3 13 4.4 14 6 14.5m9.7-8.4c0-.3-.3-.6-.6-.6s-.6.3-.6.6.3.6.6.6.6-.3.6-.6m-.8-1.7c0-.3-.2-.5-.5-.5s-.5.2-.5.5.2.5.5.5.5-.2.5-.5m-1.1-1.5c0-.2-.2-.4-.4-.4s-.4.2-.4.4.2.4.4.4.4-.2.4-.4\"/></svg>",
  "graduation-cap": "<svg viewBox=\"0 0 15.8 10.2\"><path d=\"M9.6 9.3c-1.9-.8-3.4-1-4.4-.4L5 9v-.1c-.7-.9-.9-2-.7-3.1l4.7.5 4.2-2.4c.5 1 .7 2.1.6 3.2 0 .2-.2.2-.6.3-1.3.1-2.7.8-3.6 1.9zm-.7-3.9L0 4.5 7.8 0l8.1 1-7 4.4zM1.7 8.7h-.5V5.5l.5.1v3.1zm.3 1c0 .3-.2.5-.5.5S1 10 1 9.7s.2-.5.5-.5.5.2.5.5z\"/></svg>",
  "handout": "<svg viewBox=\"0 0 16 16\"><path d=\"M12 8.2H4C4 9 5 9.3 5 9.3h1.5V8.1h.6v1.8c-1 1.2-1.3 2.9-.8 4.4V16h4.2v-1.8c.6-1.3 1.1-2.5 1.6-3.9 0-.7 0-1.4-.1-2.1zM9.6 9.6c-.3 0-.3-.1-.4-.2V8.1h.6c0 .1 0 .7.1 1.1.1.1 0 .4-.3.4zm-.4-1.5V5.9s-.1-1.1-1.1-1.1c-.5.1-1 .6-1 1.2v2.2l2.1-.1zm-3 2c-.3.4-.6.9-.7 1.4H5s-1 0-1-.8 1-.8 1-.8h1.2v.2zM16 7h-5.3V5.9c0-1.4-1.1-2.5-2.5-2.5-1.5 0-2.6 1.2-2.6 2.6v1H0V0h16v7z\"/></svg>",
  "home": "<svg viewBox=\"0 0 16 16\"><path d=\"M8 0L0 8h3v8h10V8h3L8 0zm2 16H6v-6h4v6z\"/></svg>",
  "image": "<svg viewBox=\"0 0 16 13\"><path d=\"M15 12H1V1h14v11zm1-12H0v13h16V0zM4.7 2.2c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.7-1.5-1.5-1.5zM2 11h12V6.4l-2.6-2.9-2.1 4.6L8 6.8c-.9.4-1.7.9-2.3 1.7-.4-.3-1-.3-1.4 0L2 11z\"/></svg>",
  "info-circle-solid": "<svg viewBox=\"0 0 15.7 15.7\"><path d=\"M13.4 2.3C10.3-.8 5.3-.8 2.3 2.3s-3.1 8.1 0 11.1 8.1 3.1 11.1 0c3.1-3 3.1-8 0-11.1zm-6.5.5h2v2h-2v-2zm3 10h-4v-2h1v-3h-1v-2h3v5h1v2z\"/></svg>",
  "info-circle": "<svg viewBox=\"0 0 15.9 15.9\"><path d=\"M8 15.9c-4.4 0-8-3.6-8-8 0-2.1.8-4.1 2.3-5.6 3.1-3.1 8.2-3.1 11.3 0 3.1 3.1 3.1 8.2 0 11.3-1.5 1.5-3.5 2.3-5.6 2.3zM8 1C4.1 1 1 4.1 1 8c0 1.8.7 3.6 2 4.9 2.7 2.7 7.1 2.7 9.9 0s2.7-7.1 0-9.9C11.6 1.7 9.8 1 8 1zM7 2.9h2v2H7v-2zm3 10H6v-2h1v-3H6v-2h3v5h1v2z\"/></svg>",
  "key": "<svg viewBox=\"0 0 15.9 16\"><path d=\"M14.6 1.3c-1.7-1.7-4.5-1.7-6.2 0-1.2 1.2-1.6 3-1 4.6L0 13.2V16h2.2l2-1.8 1 1 1.1-1-.9-1.2L7 11.5l.9.9 1.8-1.8-.9-.8L10 8.6c2.3.8 4.8-.3 5.6-2.6.6-1.7.2-3.5-1-4.7zm-1.7 2.8c-.3.3-.8.3-1.1 0s-.3-.8 0-1.1c.3-.3.8-.3 1.1 0 .3.3.3.8 0 1.1z\"/></svg>",
  "layout-browser": "<svg viewBox=\"0 0 16 16\"><path d=\"M15 0H0v16h16V0h-1zM6 1h1v1H6V1zM4 1h1v1H4V1zM2 1h1v1H2V1zm13 14H1V3h14v12zm-2-8H6V5h7v2zm0 6H6V8h7v5zm-8 0H3V5h2v8z\"/></svg>",
  "letters": "<svg viewBox=\"0 0 16 15.8\"><path d=\"M4 4.7H2l-.2.9H0L2.1 0H4l2.1 5.6H4.3L4 4.7zm-.3-1.2l-.7-2-.6 2h1.3zm1.7 2.4h3.2c.5 0 .9.1 1.2.4.3.3.4.6.4 1 0 .3-.1.6-.3.9-.1.1-.3.3-.5.3.3.1.7.2.9.5.2.3.3.6.3.9 0 .3-.1.5-.2.8-.1.2-.3.4-.6.6-.2.1-.4.2-.7.2-.3 0-.5.1-.8.1h-3l.1-5.7zm1.7 2.2h.8c.2 0 .4 0 .6-.1.1-.1.2-.3.2-.4 0-.1-.1-.3-.2-.4-.2-.1-.4-.2-.6-.2h-.8v1.1zm0 2.2H8c.2 0 .4 0 .6-.2.2-.1.3-.2.2-.4 0-.2-.1-.3-.2-.4-.1-.1-.3-.1-.6-.1h-.9v1.1zm7.4 3.1l1.5.5c-.1.4-.2.8-.5 1.1-.2.3-.5.5-.8.6-.4.2-.8.2-1.2.2-.5 0-1-.1-1.5-.3-.4-.2-.8-.5-1-.9-.3-.5-.4-1.1-.4-1.7-.1-.8.2-1.6.7-2.1.6-.5 1.3-.8 2.1-.7.6 0 1.2.1 1.7.4.4.3.8.8.9 1.3l-1.5.3c0-.1-.1-.3-.2-.4-.1-.1-.2-.2-.4-.3-.5-.2-1.1-.1-1.4.4-.2.3-.3.7-.2 1.1 0 .4.1.9.3 1.3.4.4 1.1.5 1.5.1.2-.3.3-.6.4-.9z\"/></svg>",
  "lightbulb": "<svg viewBox=\"0 0 10 16\"><path d=\"M8.5 1.5c-2-2-5.1-2-7.1 0C-.1 3-.4 5.3.5 7.3c.4.6.9 1.2 1.5 1.7v6h1v1h4v-1h1V9c2.2-1.7 2.7-4.8 1-7-.1-.2-.3-.4-.5-.5zM7 14H3v-1h4v1zm0-2H3v-1h4v1zm1.6-5.2c-.3.5-.7 1-1.2 1.4l-.4.3V10H3c0-1.5-.1-1.6-.4-1.8-.5-.4-.9-.8-1.2-1.4-.8-1.5-.5-3.4.8-4.6C3.8.6 6.3.6 7.9 2.2 8.6 2.9 9 3.9 9 5c0 .6-.1 1.3-.4 1.8z\"/></svg>",
  "lines-arrow-down": "<svg viewBox=\"0 0 16 13.6\"><path d=\"M14 2.5H2v1h12v-1zM11 5H2v1h9V5zM9 7.4H2v1h7v-1zm7 .1V0H0v11h8.3l-.1-1H1V1h14v6.5h1zm-3.4 6.1l-3.1-3.5h2.1V7.5h2.1v2.6h2.1l-3.2 3.5z\"/></svg>",
  "lines": "<svg viewBox=\"0 0 16 16\"><path d=\"M16 2H0V0h16v2zm0 2.7H0v2h16v-2zm0 9.3H0v2h16v-2zm0-4.7H0v2h16v-2z\"/></svg>",
  "list-browser": "<svg viewBox=\"0 0 16 16\"><path d=\"M15 0H0v16h16V0h-1zM6 1h1v1H6V1zM4 1h1v1H4V1zM2 1h1v1H2V1zm13 14H1V3h14v12zm-2-8H6V6h7v1zM4 7H3V6h1v1zm9 2.9H6v-1h7v1zm-9 0H3v-1h1v1zm9 3.1H6v-1h7v1zm-9 0H3v-1h1v1z\"/></svg>",
  "list-number": "<svg viewBox=\"0 0 15 15\"><path d=\"M2.2 3.6h-.8V1.5v-.2-.2-.2-.2.2l-.1.1-.1.1-.4.3L.4.9 1.6 0h.6v3.6zm.2 5.6H0v-.5l.8-.9c.2-.1.3-.2.4-.3.1-.1.2-.2.2-.3 0-.1.1-.2.1-.3 0-.1.1-.2.1-.3 0-.1 0-.2-.1-.3-.1-.1-.2-.1-.3-.1-.1 0-.3 0-.4.1-.1.1-.3.2-.4.3L0 6.1c.1-.1.2-.1.2-.2.1-.1.2-.1.3-.2.1 0 .2-.1.3-.1h.4c.2 0 .3 0 .5.1.1 0 .2.1.3.2.1 0 .2.1.2.3.1.1.1.3.1.4 0 .1 0 .3-.1.4 0 .1-.1.2-.2.4 0 .1-.1.2-.2.3-.2.1-.3.3-.4.4l-.4.4h1.5v.7zm-.1 3c0 .1 0 .2-.1.3 0 .1-.1.2-.2.3 0 0-.1.1-.1.2-.1 0-.2 0-.3.1.2 0 .5.1.7.3.2.1.2.4.2.6 0 .1 0 .3-.1.4-.1.1-.1.3-.3.3-.1.1-.3.2-.4.2-.3.1-.5.1-.7.1H.5c-.2 0-.3-.1-.5-.1v-.6c.1 0 .2.1.2.1.1 0 .2.1.2.1h.7c.1 0 .2-.1.2-.1.1 0 .1-.1.1-.2V14v-.2c0-.1-.1-.1-.1-.1-.1 0-.2-.1-.3-.1H.6v-.5H1.2c.1 0 .2-.1.2-.1.1 0 .1-.1.1-.2v-.2c0-.1 0-.2-.1-.3-.1-.1-.2-.1-.4-.1H.8c-.1 0-.1 0-.2.1-.1 0-.1 0-.2.1l-.1.1-.3-.8c.1-.1.1-.1.2-.1s.2-.1.3-.1c.1 0 .2-.1.3-.1h.3c.2 0 .3 0 .5.1.1 0 .3.1.4.2.1.1.2.2.2.3.1-.1.1.1.1.2zM15 2.5H4v-1h11v1zM15 7H4v1h11V7zm0 5.5H4v1h11v-1z\"/></svg>",
  "list-outline": "<svg viewBox=\"0 0 16 16\"><path d=\"M2 2H0V0h2v2zm0 2.3H0v3h2v-3zm-1.5.6h1v1.6h-1V4.9zM2 14H0v2h2v-2zM16 0H4v2h12V0zm0 4.3H4v3h12v-3zm-11.5.6h11v1.8h-11V4.9zM16 14H4v2h12v-2zM2 9.3H0v2h2v-2zm14 0H4v2h12v-2z\"/></svg>",
  "list": "<svg viewBox=\"0 0 16 16\"><path d=\"M2 2H0V0h2v2zm0 2.8H0v2h2v-2zM2 14H0v2h2v-2zM16 0H4v2h12V0zm0 4.8H4v2h12v-2zm0 9.2H4v2h12v-2zM2 9.3H0v2h2v-2zm14 0H4v2h12v-2z\"/></svg>",
  "locator": "<svg viewBox=\"0 0 10.5 15.8\"><path d=\"M5.2 0C2.5-.1.1 2 0 4.8v.5c0 3.1 4.2 8.5 5.2 10.5 1.1-2 5.2-7.3 5.2-10.5.1-2.8-2-5.1-4.8-5.2 0-.1-.2-.1-.4-.1zm2.6 4.9c0 1.4-1.1 2.6-2.6 2.6S2.7 6.3 2.7 4.9c0-1.4 1.1-2.6 2.6-2.6 1.4 0 2.5 1.2 2.5 2.6z\"/></svg>",
  "lock": "<svg viewBox=\"0 0 12 14.8\"><path d=\"M10.5 6V4.5C10.5 2 8.5 0 6 0S1.5 2 1.5 4.5V6H0v8.8h12V6h-1.5zm-2 0h-5V4.5C3.5 3.1 4.6 2 6 2s2.5 1.1 2.5 2.5V6z\"/></svg>",
  "magnify-minus": "<svg viewBox=\"0 0 15.6 15.6\"><path d=\"M6.8 13.6C3 13.6 0 10.5 0 6.8S3 0 6.8 0s6.8 3 6.8 6.8-3 6.8-6.8 6.8zM6.8 1C3.6 1 1 3.6 1 6.8s2.6 5.8 5.8 5.8 5.8-2.6 5.8-5.8C12.6 3.6 10 1 6.8 1zm8.8 13.2l-1.4 1.4-3.4-3.4 1.4-1.4 3.4 3.4zm-5.3-6.9h-7v-1h7v1z\"/></svg>",
  "magnify-plus": "<svg viewBox=\"0 0 15.6 15.6\"><path d=\"M6.8 13.6C3 13.6 0 10.5 0 6.8S3 0 6.8 0s6.8 3 6.8 6.8-3 6.8-6.8 6.8zM6.8 1C3.6 1 1 3.6 1 6.8s2.6 5.8 5.8 5.8 5.8-2.6 5.8-5.8C12.6 3.6 10 1 6.8 1zm8.8 13.2l-1.4 1.4-3.4-3.4 1.4-1.4 3.4 3.4zm-5.3-6.9h-7v-1h7v1zm-4.1 3v-7h1v7h-1z\"/></svg>",
  "magnify": "<svg viewBox=\"0 0 15.6 15.6\"><path d=\"M6.8 13.6C3 13.6 0 10.5 0 6.8S3 0 6.8 0s6.8 3 6.8 6.8-3 6.8-6.8 6.8zM6.8 1C3.6 1 1 3.6 1 6.8s2.6 5.8 5.8 5.8 5.8-2.6 5.8-5.8C12.6 3.6 10 1 6.8 1zm8.8 13.2l-1.4 1.4-3.4-3.4 1.4-1.4 3.4 3.4z\"/></svg>",
  "microphone-slash": "<svg viewBox=\"0 0 15.3 16\"><path d=\"M4.8 3.1v-.4C4.8 1.2 6.1 0 7.5.1c1.4 0 2.6 1.2 2.6 2.6V8.5L4.8 3.1zm7.3 7.3c.3-.6.5-1.2.6-1.9h-1c0 .4-.1.8-.3 1.1l.7.8zm-.5.8l-.7-.7-1.1-1-5-5L.7.4l-.7.7 4.8 4.8V8c0 1.5 1.2 2.7 2.7 2.7.6 0 1.1-.2 1.6-.5l1.1 1.1c-.7.6-1.7 1-2.7 1-2.1 0-3.9-1.6-4.1-3.7h-1C2.6 11 4.5 13 7 13.2V15H5.4v1h4.2v-1H8v-1.8c1.1-.1 2-.5 2.8-1.2l3.8 3.8.7-.7-3.7-3.9z\"/></svg>",
  "microphone": "<svg viewBox=\"0 0 10.3 16\"><path d=\"M5.1 10.8c-1.5 0-2.7-1.2-2.7-2.7V2.7C2.5 1.2 3.7 0 5.1 0c1.5 0 2.7 1.2 2.7 2.7v5.4c0 1.4-1.2 2.6-2.7 2.7zm5.2-2.3h-1c-.2 2.3-2.3 4-4.6 3.7C2.8 12 1.2 10.5 1 8.5H0C.2 11 2.2 13 4.6 13.2V15H3.1v1h4.2v-1H5.6v-1.8c2.5-.2 4.5-2.2 4.7-4.7z\"/></svg>",
  "minus-circle-solid": "<svg viewBox=\"0 0 15.7 15.7\"><path d=\"M13.4 2.3C10.3-.8 5.3-.8 2.3 2.3s-3.1 8.1 0 11.1 8.1 3.1 11.1 0c3.1-3 3.1-8 0-11.1zm-.6 6.6h-10v-2h10v2z\"/></svg>",
  "minus-circle": "<svg viewBox=\"0 0 15.7 15.7\"><path d=\"M2.3 13.4c-3.1-3.1-3.1-8.1 0-11.1s8.1-3.1 11.1 0 3.1 8.1 0 11.1c-3 3.1-8 3.1-11.1 0zM12.7 3C10 .3 5.7.3 3 3s-2.7 7 0 9.7 7 2.7 9.7 0c2.7-2.7 2.7-7 0-9.7zm-.8 5.4h-8v-1h8v1z\"/></svg>",
  "minus": "<svg viewBox=\"0 -5.5 12 12\"><path d=\"M0 1V0h12v1H0z\"/></svg>",
  "monitor": "<svg viewBox=\"0 0 16 16\"><path d=\"M16 12V0H0v12h7v3H4v1h8v-1H9v-3h7zM1 11V1h14v10H1z\"/></svg>",
  "network-branch": "<svg viewBox=\"0 0 16 15\"><path d=\"M16 12.8H9.9V15H6v-2.2H0v-2h16v2zM9 10H7V8.8H3V0h9v8.8H9V10zM5 6.8H4v1h1v-1zm2 0H6v1h1v-1zm3.9-1V1H4v4.8h6.9z\"/></svg>",
  "network": "<svg viewBox=\"0 0 16 16\"><path d=\"M13 10V7H9V6h3V0H5v6h3v1H3v3H0v6h7v-6H4V8h8v2H9v6h7v-6h-3zm-7 1v1H1v-1h5zm0-9V1h5v1H6zm9 10h-5v-1h5v1z\"/></svg>",
  "paper-clip": "<svg viewBox=\"0 0 10.7 14.2\"><path d=\"M9.9 5.4l-3.8 5.8c-.6 1.1-1.9 1.5-3 .9-1.1-.7-1.5-2-.9-3.1.1-.1.1-.2.2-.3l3-4.6.8.6-3 4.6c-1 1.6 1 2.9 2 1.3L9 4.8c2-3-1.7-5.4-3.7-2.4L1.7 8c-1.1 1.4-.9 3.4.5 4.5.1 0 .1.1.2.1 1.6 1.1 3.5.4 4.5-1.2L9.8 7l.8.6L7.7 12C6.6 14 4 14.8 2 13.6c-.1 0-.1-.1-.2-.1C-.1 12.2-.6 9.6.7 7.6c0-.1.1-.1.1-.2l3.7-5.6c2.7-4.1 8.1-.6 5.4 3.6z\"/></svg>",
  "payment-left-arrow": "<svg viewBox=\"0 0 16 13.7\"><path d=\"M15.5 5.1v5.4H7.8V5.1h7.7m.5-.5H7.3v6.5H16V4.6zm-7.6 1V10h6.5V5.6H8.4zM10 8.3H8.9v-1H10v1zm1.7.6c-.6 0-1.1-.5-1.1-1.1s.5-1.1 1.1-1.1c.6 0 1.1.5 1.1 1.1-.1.6-.5 1.1-1.1 1.1zm2.7-.6h-1.1v-1h1.1v1zM9.2 0C5.5 0 2.6 2.8 2.3 6.4h1.1C3.7 3.2 6.5.8 9.7 1.1c1.5.1 2.9.8 3.9 2h1.3C13.6 1.1 11.4 0 9.2 0zM0 6.4l2.9 2.9 2.8-2.9H0zm9.2 6.3v1.1c1.4 0 2.7-.4 3.8-1.2h-2.8c-.4.1-.7.1-1 .1z\"/></svg>",
  "payment-quick": "<svg viewBox=\"0 0 15.6 8.1\"><path d=\"M14.8.7l-1.1 6.7H4.3L5.4.7h9.4m.8-.7H4.8l-.1.3-1 6.8-.2 1h10.8l.2-1L15.6.4V0zM5.9 1.3l-.8 5.4h8.1l.8-5.4H5.9zm1.5 3.3H6.1l.2-1.2h1.3l-.2 1.2zm1.9.8c-.6 0-1.1-.5-1.2-1.1V4c.2-.7.9-1.3 1.6-1.3.6 0 1.1.5 1.2 1.1v.3c-.2.7-.8 1.3-1.6 1.3zm3.5-.8h-1.3l.2-1.2H13l-.2 1.2zM.6 2.1h3.2l.1-.8H.7l-.1.8zM.3 3.9h3.1l.1-.8H.4l-.1.8zM0 5.7h3.1l.1-.8H.1l-.1.8z\"/></svg>",
  "payment": "<svg viewBox=\"0 0 16 12\"><path d=\"M15 1v10H1V1h14m1-1H0v12h16V0zM2 2v8h12V2H2zm3 4.9H3V5.1h2v1.8zM8 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm5-1.1h-2V5.1h2v1.8z\"/></svg>",
  "pencil": "<svg viewBox=\"0 0 16 16\"><path d=\"M0 16l.3-2.2.3-2.2 1.9 1.9 1.9 1.9-2.2.3L0 16zm12.3-9.8l-7.1 7.1-.6-.6 7.1-7.1-1.3-1.3-7.1 7.1-.6-.6 7.1-7.1-.7-.7-7.9 7.9L5 14.7l7.9-7.9-.6-.6zm0-6.2l-2 2 3.8 3.8 1.9-2L12.3 0z\"/></svg>",
  "people": "<svg viewBox=\"0 0 14.7 11.8\"><path d=\"M9 3.1c0 1.7-1.4 3.1-3.1 3.2-1.7 0-3.1-1.4-3.2-3.1C2.7 1.4 4.1 0 5.9 0 7.6 0 9 1.4 9 3.1m2.5 6c-.4-.5-4.2-1.9-4.2-1.9-.9.6-2.1.6-3 0 0 0-3.8 1.4-4.2 1.9-.1.8-.2 1.8-.1 2.7h11.7c.1-.9 0-1.9-.2-2.7zm3.2.9h-2c0-.5-.1-1.1-.4-1.5-.1-.2-.5-.7-3.9-2l.3-.3c.7.4 1.6.3 2.3-.1 0 0 3.2 1.2 3.5 1.6.2.8.3 1.5.2 2.3zM10 3.1c0 .8-.2 1.5-.6 2.2 1.5.2 2.8-.8 3-2.3.2-1.5-.8-2.8-2.3-3h-.4c-.3 0-.7.1-1 .2C9.6 1 10 2 10 3.1z\"/></svg>",
  "person-minus": "<svg viewBox=\"0 0 15.5 15.5\"><path d=\"M15.3 12.4H8.5v-1h6.8v1zM7.7 8.3c2.3 0 4.2-1.9 4.2-4.1S10 0 7.8 0 3.6 1.9 3.6 4.1c0 2.3 1.9 4.2 4.1 4.2zM7 9.9c-.4-.2-.8-.3-1.2-.5 0 0-5 1.9-5.5 2.5-.4.7-.3 3.6-.3 3.6h15.4v-1.6H7v-4z\"/></svg>",
  "person-network": "<svg viewBox=\"0 0 16 15.7\"><path d=\"M3.7 12.6v.6H.6v-.6h3.1zm5.9.6H6.5v-.6h3.1v.6zm-1.3-3.1V5.6h-.6v4.5H1.9V12H0v3.7h4.3V12H2.5v-1.2h5.2V12H5.8v3.7h4.3V12H8.3v-1.2h5.2V12h-1.9v3.7H16V12h-1.9v-1.9H8.3zm7.1 3.1h-3.1v-.6h3.1v.6zM8.9 1.6c0 .9-.7 1.6-1.6 1.6-.9 0-1.6-.7-1.6-1.6S6.3 0 7.2 0s1.7.7 1.7 1.6m1.3 3.1c-.7-.4-1.4-.7-2.2-1-.5.3-1.1.3-1.6 0-.7.3-1.4.6-2.1 1-.1.5-.2 1-.2 1.5h6.1c.1-.5.1-1 0-1.5zm1.7.5h-1.1c0-.3-.1-.6-.2-.8-.1-.1-.3-.4-2.1-1.1.1 0 .1-.1.2-.1.4.2.8.2 1.2-.1.6.2 1.3.5 1.9.9.1.4.1.8.1 1.2zM9.4 1.6c0 .4-.1.8-.3 1.1.8.1 1.5-.5 1.6-1.2.1-.8-.5-1.5-1.2-1.6-.2.1-.2.1-.3.1-.1 0-.3 0-.5.1.5.4.7 1 .7 1.5z\"/></svg>",
  "person-plus": "<svg viewBox=\"0 0 15.4 15.5\"><path d=\"M15.4 12.4H8.5v-1h6.8l.1 1zm-3.9 3.1v-7h1v7h-1zM10.8 7c1.5-1.7 1.4-4.3-.3-5.8s-4.3-1.5-5.8.2-1.4 4.3.3 5.8c1.4 1.3 3.4 1.4 5 .4V7h.8zM7 13.9v-4c-.4-.2-.8-.3-1.2-.5 0 0-5 1.9-5.5 2.5-.4.7-.3 3.6-.3 3.6h10v-1.6H7zM10.8 7H10v.7c.3-.3.6-.5.8-.7z\"/></svg>",
  "person-x": "<svg viewBox=\"0 0 15.6 15.6\"><path d=\"M12.1 12.7L10 10.6l.7-.7 2.1 2.1 2.1-2.1.7.7-2.1 2.1 2 2-.7.7-2-2-2.1 2.1-.7-.7 2.1-2.1zm-2.2 0l-2.1-2.1.8-.8c-1 .2-2 0-2.8-.5 0 0-5 1.9-5.5 2.5-.4.8-.3 3.7-.3 3.7h8.4l-.6-.6 2.1-2.2zM7.7 8.3c2.3 0 4.2-1.9 4.2-4.1S10 0 7.8 0 3.6 1.9 3.6 4.1c0 2.3 1.9 4.2 4.1 4.2z\"/></svg>",
  "person": "<svg viewBox=\"0 0 15.5 15.5\"><path d=\"M11.9 4.2c0 2.3-1.9 4.2-4.1 4.2s-4.2-2-4.2-4.2S5.5 0 7.7 0c2.3 0 4.2 1.8 4.2 4.2 0-.1 0-.1 0 0m3.3 7.7c-.5-.7-5.5-2.5-5.5-2.5-1.2.8-2.7.8-3.9 0 0 0-5 1.9-5.5 2.5-.4.7-.3 3.6-.3 3.6h15.4c.1 0 .2-2.9-.2-3.6z\"/></svg>",
  "phone-mobile": "<svg viewBox=\"0 0 9 16\"><path d=\"M2 2V0H1v2H0v14h9V2H2zm0 12H1v-1h1v1zm0-2H1v-1h1v1zm0-2H1V9h1v1zm2 4H3v-1h1v1zm0-2H3v-1h1v1zm0-2H3V9h1v1zm2 4H5v-1h1v1zm0-2H5v-1h1v1zm0-2H5V9h1v1zm2 4H7v-1h1v1zm0-2H7v-1h1v1zm0-2H7V9h1v1zm0-2H1V3h7v5z\"/></svg>",
  "phone-touch-screen": "<svg viewBox=\"0 0 10 16\"><path d=\"M0 0v16h10V0H0zm3 1h4v1H3V1zm2 14.3c-.4 0-.8-.3-.8-.8 0-.4.3-.8.8-.8s.8.3.8.8-.4.8-.8.8zM9 13H1V3h8v10z\"/></svg>",
  "phone": "<svg viewBox=\"0 0 14.3 15.5\"><path d=\"M2.3 0c-.2.1-.3.1-.4.2C1.2.6.6 1.2.2 1.9c-.3.5-.4 5.4 3.2 9.5 2.1 2.6 5.3 4.1 8.7 4.1h.1c.8-.3 1.4-.9 1.9-1.5.4-.6.3-1.3-.1-1.8 0-.1-.1-.1-.1-.2-.2-.6-3.2-1.4-3.4-1.4h-.2c-.4.1-.8.5-1.8 1.3-1.2-.7-2.3-1.5-3.2-2.6-.8-1-1.5-2.1-2-3.3 1.1-1 1.5-1.4 1.6-1.9 0-.2-.5-3.3-1-3.6 0 0-.1-.1-.2-.1C3.3.1 2.8 0 2.3 0z\"/></svg>",
  "plus-circle-solid": "<svg viewBox=\"0 0 15.7 15.7\"><path d=\"M13.4 2.3C10.3-.8 5.3-.8 2.3 2.3s-3.1 8.1 0 11.1 8.1 3.1 11.1 0c3.1-3 3.1-8 0-11.1zm-.5 6.6h-4v4h-2v-4h-4v-2h4v-4h2v4h4v2z\"/></svg>",
  "plus-circle": "<svg viewBox=\"0 0 15.7 15.7\"><path d=\"M2.3 13.4c-3.1-3.1-3.1-8.1 0-11.1s8.1-3.1 11.1 0 3.1 8.1 0 11.1c-3 3.1-8 3.1-11.1 0zM12.7 3C10 .3 5.7.3 3 3s-2.7 7 0 9.7 7 2.7 9.7 0c2.7-2.7 2.7-7 0-9.7zm-.8 5.4h-8v-1h8v1zm-4.5 3.5v-8h1v8h-1z\"/></svg>",
  "plus": "<svg viewBox=\"0 0 12 11.9\"><path d=\"M5.4 11.9V0h1v11.9h-1zM0 5.5h12v1H0v-1z\"/></svg>",
  "power": "<svg viewBox=\"0 0 15 16\"><path d=\"M8 0H7v10h1V0zm1.5 2.3c3.4 1.1 5.3 4.7 4.2 8.2S9 15.8 5.5 14.7.2 9.9 1.3 6.5c.6-2 2.2-3.5 4.2-4.2v-1C1.5 2.4-.8 6.5.3 10.5s5.2 6.3 9.2 5.2 6.3-5.2 5.2-9.2C14 4 12 2 9.5 1.3v1z\"/></svg>",
  "printer": "<svg viewBox=\"0 0 15.8 15.6\"><path d=\"M15.8 4h-2.9V1.8l-2-1.8h-8v4H0v6.8h2.9v4.8h10v-4.8h2.9V4zM1.9 6h-1V5h1v1zm10 3v5.6h-8V8.8h8V9zm0-5h-8V1h6v1.8h2V4z\"/></svg>",
  "puck": "<svg viewBox=\"0 0 14.6 11.4\"><path d=\"M14.6 8.2c0 1.8-3.3 3.2-7.3 3.2S0 9.9 0 8.2V4.1c.3.8 1 1.5 1.9 1.8C3.6 6.6 5.5 7 7.3 7c1.1 0 2.2-.1 3.2-.3h.2c2-.5 3.5-1.3 3.8-2.3v3.9c.1-.2.1-.1.1-.1zm-.1-5c0 1.8-3.2 3.2-7.2 3.2S.1 4.9.1 3.2 3.3 0 7.3 0s7.2 1.4 7.2 3.2zm-9 .3l2.2-.9-.4-1.3-2.3.9-.3-.7c-.1-.2-.2-.2-.2-.1l-.5.9c-.1.1-.2.3-.3.4l-.4.8c-.1.1 0 .2.1.3h.1l.9.3.5.1.9.3c.1 0 .2-.1.1-.2l-.4-.8zm5.7-.6l-.9-.3-.5-.1-.9-.3c-.1 0-.2.1-.1.2l.3.7-2.3.8.5 1.3 2.4-.8.3.7c.1.2.2.2.2.1l.5-.9c.1-.1.2-.3.2-.4l.5-.9c0 .1 0-.1-.2-.1.1 0 .1 0 0 0z\"/></svg>",
  "question-circle-solid": "<svg viewBox=\"0 0 15.7 15.7\"><path d=\"M13.4 2.3C10.3-.8 5.3-.8 2.3 2.3s-3.1 8.1 0 11.1 8.1 3.1 11.1 0c3.1-3 3.1-8 0-11.1zM8.9 12.8h-2v-2h2v2zm1.9-6c-.3.5-.6.9-1 1.3-.4.3-.6.6-.8.9-.1.3-.1.6-.1.9H6.8v-.3c0-.4 0-.7.1-1.1.1-.2.2-.5.4-.7.3-.4.7-.8 1.1-1.2.2-.2.4-.5.4-.8 0-.2 0-.4-.2-.6-.1-.2-.3-.2-.5-.2-.3 0-.5.1-.7.3-.2.3-.3.7-.3 1.1L5 6.1c0-.9.3-1.7.8-2.4.6-.6 1.4-1 2.2-.9.8 0 1.5.2 2 .7.7.6 1 1.4 1 2.2 0 .4-.1.8-.2 1.1z\"/></svg>",
  "question-circle": "<svg viewBox=\"0 0 15.7 15.7\"><path d=\"M2.3 13.4c-3.1-3.1-3.1-8.1 0-11.1s8.1-3.1 11.1 0 3.1 8.1 0 11.1c-3 3.1-8 3.1-11.1 0zM12.7 3C10 .3 5.7.3 3 3s-2.7 7 0 9.7 7 2.7 9.7 0c2.7-2.7 2.7-7 0-9.7zM8.9 9.9H6.8v-.3c0-.4 0-.7.1-1.1.1-.2.2-.5.4-.7.3-.4.7-.8 1.1-1.2.2-.2.4-.5.4-.8 0-.2 0-.4-.2-.6-.1-.2-.3-.2-.5-.2-.3 0-.5.1-.6.3-.3.3-.4.7-.4 1.1L5 6.1c0-.9.3-1.7.8-2.4.6-.6 1.4-1 2.2-.9.8 0 1.5.2 2 .7.7.6 1 1.4 1 2.2 0 .4-.1.8-.3 1.1-.3.5-.6.9-1 1.3-.3.3-.5.6-.7.9-.1.3-.1.6-.1.9zm-2 1h2v2h-2v-2z\"/></svg>",
  "ribbon-award": "<svg viewBox=\"0 0 11.4 16\"><path d=\"M8.2 6c0 1.4-1.1 2.5-2.5 2.5S3.2 7.4 3.2 6s1.1-2.5 2.5-2.5S8.2 4.6 8.2 6zm2.4 1l.8.9-1.2.2c-.2.6-.6 1.1-1.1 1.6l-.2.1.8 6.2-4-4-4 4 .8-6.2-.2-.1c-.5-.4-.9-1-1.1-1.6L0 7.9.8 7C.7 6.4.7 5.7.8 5L0 4.1l1.2-.2c.3-.6.7-1.1 1.1-1.6l-.1-1.2 1.1.5c.6-.3 1.2-.5 1.8-.6l.6-1 .6 1.1c.6.1 1.3.3 1.8.6l1.1-.5-.1 1.1c.5.4.9 1 1.1 1.6l1.2.2-.8.9c.1.7.1 1.3 0 2zM9.2 6c0-1.9-1.6-3.5-3.5-3.5S2.2 4.1 2.2 6s1.6 3.5 3.5 3.5S9.2 7.9 9.2 6z\"/></svg>",
  "scales": "<svg viewBox=\"0 0 14.8 16\"><path d=\"M4.1 3h2.7v12H5.3v1h4v-1H7.8V3h3.7l-3.2 8.1s1 2.4 3.3 2.4 3.3-2.4 3.3-2.4L11.5 3h1.3V2h-5V0h-1v2h-5v1M3 3l-3 8.1s.8 2.4 3.1 2.4S6.4 11 6.4 11L3 3m2.5 7.8H1l2.1-6 2.4 6zm3.6.2l2.4-5.9 2.4 5.9H9.1z\"/></svg>",
  "servers": "<svg viewBox=\"0 0 16 11\"><path d=\"M0 11h5V0H0v11zm3.4-1.1H1.6c-.1 0-.1-.1-.1-.2s.1-.1.1-.1h1.8c.1 0 .1.1.1.2 0 0 0 .1-.1.1m0-1.2H1.6c-.1 0-.1-.1-.1-.1 0-.1.1-.1.1-.1h1.8c.1 0 .1.1.1.1.1 0 0 .1-.1.1M2 6.8c0-.3.2-.6.5-.6.3.1.5.4.5.7 0 .3-.2.5-.5.5S2 7.1 2 6.8M4 2H1V1h3v1zm4.8 3.2V4c-.3 0-2.5-.4-3 1.2 0-.3 0-2.4 3.1-2.2V1.8l1.4 1.7-1.5 1.7zm-1.6-.1v1.2c.3 0 2.6.4 3.1-1.2 0 .3 0 2.4-3.1 2.2v1.2L5.8 6.8l1.4-1.7zM11 11h5V0h-5v11zm3.4-1.1h-1.8c-.1 0-.1-.1-.1-.2s.1-.1.1-.1h1.8c.1 0 .1.1.1.2 0 0 0 .1-.1.1m0-1.2h-1.8c-.1 0-.1-.1-.1-.1 0-.1.1-.1.1-.1h1.8c.1 0 .1.1.1.1.1 0 0 .1-.1.1M13 6.8c0-.3.3-.5.6-.5s.5.3.5.6-.2.5-.5.5c-.4 0-.6-.3-.6-.6M15 2h-3V1h3v1z\"/></svg>",
  "settings": "<svg viewBox=\"0 0 16 16\"><path d=\"M0 16h16l-.1-16H0v16zm15-1H1V1h14v14zM2 4h1V3h1v1h1v1H4v8H3V5H2V4zm4.5 4h1v5h1V8h1V7h-1V3h-1v4h-1v1zM11 6h1v7h1V6h1V5h-1V3h-1v2h-1v1z\"/></svg>",
  "shield-checkmark": "<svg viewBox=\"0 0 14 15\"><path d=\"M7 0L0 1v5c0 3 2 7 7 9 5-2 7-6 7-9V1L7 0zm6 6c-.1 3.5-2.4 6-6 8-3.4-1.7-5.7-4.1-6-8V1.7L7 1l6 .7V6zm-6.9 4.4L12 4.5l-.7-.7L6.1 9 3.2 6.1l-.7.7 3.6 3.6z\"/></svg>",
  "shield-dollar": "<svg viewBox=\"0 0 14 15\"><path d=\"M7 0L0 1v5c0 3 2 7 7 9 5-2 7-6 7-9V1L7 0zm6 6c-.1 3.5-2.4 6-6 8-3.4-1.7-5.7-4.1-6-8V1.7L7 1l6 .7V6zM7.8 8.9c0-.2-.1-.4-.2-.6-.2-.2-.5-.3-.7-.4-.3-.1-.6-.3-.9-.4-.2-.1-.4-.3-.6-.4-.2-.2-.3-.4-.4-.6-.1-.2-.1-.5-.1-.8 0-.5.2-.9.5-1.3.4-.3.8-.5 1.3-.6v-1h.7v1c.5.2.9.4 1.3.8.3.4.4.9.4 1.4H7.8c0-.3-.1-.6-.2-.8-.2-.2-.4-.3-.6-.2-.2-.1-.5 0-.6.2-.2.1-.2.3-.2.6 0 .2.1.4.2.6.2.2.5.3.7.4.3.1.6.2.9.4.2.1.4.3.6.4.2.1.3.3.4.5.1.2.1.5.1.7 0 .5-.2.9-.5 1.3-.4.3-.8.5-1.3.6v.9h-.7v-.9c-.5 0-1.1-.3-1.4-.7-.4-.4-.5-1-.5-1.5H6c0 .3.1.6.3.8.2.2.5.3.7.3.2 0 .5-.1.6-.2.1-.1.2-.3.2-.5z\"/></svg>",
  "shield": "<svg viewBox=\"0 0 14 15\"><path d=\"M7 0L0 1v5c0 3 2 7 7 9 5-2 7-6 7-9V1L7 0zm6 6c-.1 3.5-2.4 6-6 8-3.4-1.7-5.7-4.1-6-8V1.7L7 1l6 .7V6zm-1-3.5V6c0 3.1-1.8 5.4-5 6.9V2l5 .5z\"/></svg>",
  "slash-circle": "<svg viewBox=\"0 0 15.7 15.7\"><path d=\"M7.9 15.7C3.5 15.7 0 12.2 0 7.9S3.5 0 7.9 0s7.9 3.5 7.9 7.9c-.1 4.3-3.6 7.8-7.9 7.8zM7.9 1C4.1 1 1 4.1 1 7.9s3.1 6.9 6.9 6.9 6.9-3.1 6.9-6.9C14.7 4.1 11.7 1 7.9 1zM2.7 13.7L2 13 13 2l.7.7-11 11z\"/></svg>",
  "snowflake": "<svg viewBox=\"0 0 13.8 15\"><path d=\"M1.3 5.9C.6 5.7 0 5 0 4.3c0-1 .7-1.8 1.7-1.8.9 0 1.7.7 1.7 1.7.4 1 1.5 1.6 2.5 1.3.7-.2.7-.9.7-1.3-.2-.9-1.2-1-1.4-2.2C5 1.2 5.4.3 6.3.1c.2-.1.4-.1.6-.1.9-.1 1.6.6 1.7 1.4 0 .2 0 .4-.1.6-.1 1.2-1.1 1.3-1.3 2.2 0 .4.1 1.1.7 1.3 1 .3 2.2-.2 2.5-1.3 0-.9.7-1.7 1.7-1.7.9.1 1.7.9 1.7 1.8 0 .8-.5 1.5-1.2 1.7-.5 0-3.4-.9-3.4 1.4S12 8.8 12.5 9c.7.2 1.2.9 1.2 1.7 0 .9-.7 1.7-1.7 1.8-.9 0-1.7-.7-1.7-1.7 0-.8-1-1.2-2-1.2-.9 0-1.3.4-1.2 1.3s1.1 1.1 1.2 2.2c.2.8-.2 1.7-1 1.9h-.5c-1.2 0-1.8-.7-1.6-1.9.2-1.1 1.2-1.3 1.4-2.2 0-.5 0-1.1-.7-1.3-1-.4-2.2.2-2.5 1.2 0 .9-.7 1.7-1.7 1.7-1-.1-1.7-.8-1.7-1.8C0 9.9.6 9.2 1.3 9c.4-.1 3.2.9 3.2-1.5S1.7 6.1 1.3 5.9zm4.4 1.6c-.1.6.4 1.2 1.1 1.3h.1c.7 0 1.3-.6 1.2-1.3 0-.7-.6-1.2-1.3-1.2-.6 0-1.1.5-1.1 1.2z\"/></svg>",
  "squares-four": "<svg viewBox=\"0 0 16 16\"><path d=\"M7 7H0V0h7v7zm0 2H0v7h7V9zm9 0H9v7h7V9zm0-9H9v7h7V0z\"/></svg>",
  "squares-nine": "<svg viewBox=\"0 0 16 16\"><path d=\"M4 4H0V0h4v4zm0 2H0v4h4V6zm0 6H0v4h4v-4zm6-12H6v4h4V0zm0 6H6v4h4V6zm0 6H6v4h4v-4zm6-12h-4v4h4V0zm0 6h-4v4h4V6zm0 6h-4v4h4v-4z\"/></svg>",
  "squares-stacked": "<svg viewBox=\"0 0 16 16\"><path d=\"M8 0l8 4-8 4-8-4 8-4zm0 9.5L3.1 7 0 8l8 4 8-4-2.8-1.1L8 9.5zm0 4L3.1 11 0 12l8 4 8-4-2.8-1.1L8 13.5z\"/></svg>",
  "star": "<svg viewBox=\"0 0 16 15.2\"><path d=\"M8 0l2 5.7 6 .1-4.8 3.6 1.7 5.8L8 11.8l-4.9 3.4 1.7-5.8L0 5.8l6-.1L8 0z\"/></svg>",
  "table-pivot": "<svg viewBox=\"0 0 16 16\"><path d=\"M0 0v16h16V0H0zm11 4.4v1.5H7.5V4.4H11zM7.5 15v-1.4H11V15H7.5zm0-1.7v-1.4H11v1.4H7.5zm0-1.8V9.9H11v1.5l-3.5.1zm0-1.9V8.2H11v1.4H7.5zm0-1.8V6.3H11v1.5H7.5zM7 4.4v1.5H3.7V4.4H7zm0 1.9v1.5H3.7V6.3H7zm0 1.8v1.5H3.7V8.1H7zm0 1.8v1.5H3.7V9.9H7zm0 1.9v1.5H3.7v-1.5H7zm0 1.8V15H3.7v-1.4H7zm4.4 1.4v-1.4H15V15h-3.6zm0-1.7v-1.4H15v1.4h-3.6zm0-1.8V9.9H15v1.5l-3.6.1zm0-1.9V8.2H15v1.4h-3.6zm0-1.8V6.3H15v1.5h-3.6zm0-1.9V4.4H15v1.5h-3.6z\"/></svg>",
  "table": "<svg viewBox=\"0 0 16 16\"><path d=\"M0 0v16h16V0H0zm11.5 4.4v1.5H8.3V4.4h3.2zM4 15v-1.4h3.8V15H4zm3.8-6.9v1.5H4V8.1h3.8zM4 7.8V6.3h3.8v1.5H4zm3.8 2.1v1.5H4V9.9h3.8zm0 1.9v1.5H4.1v-1.5h3.7zm.5 3.2v-1.4h3.2V15H8.3zm0-1.7v-1.5h3.2v1.5H8.3zm0-1.8V9.9h3.2v1.5l-3.2.1zm0-1.9V8.1h3.2v1.5H8.3zm0-1.8V6.3h3.2v1.5H8.3zm-.5-3.4v1.5H4V4.4h3.8zm-4.2 0v1.5H1V4.4h2.6zm0 1.9v1.5H1V6.3h2.6zm0 1.8v1.5H1V8.1h2.6zm0 1.8v1.5H1V9.9h2.6zm0 1.9v1.5H1v-1.5h2.6zm0 1.8V15H1v-1.4h2.6zM12 15v-1.4h3V15h-3zm0-1.7v-1.5h3v1.5h-3zm0-1.8V9.9h3v1.5l-3 .1zm0-1.9V8.1h3v1.5h-3zm0-1.8V6.3h3v1.5h-3zm0-1.9V4.4h3v1.5h-3z\"/></svg>",
  "tools": "<svg viewBox=\"0 0 16 16.1\"><path d=\"M15.9 2.2c.4 1.5-.6 3.1-2.1 3.4-1.5.4-3.1-.6-3.4-2.1C10 2 11 .4 12.5.1c.4-.1.9-.1 1.3 0l-2 2 .3 1.8 1.8.4 2-2.1zM7.3 8l-2.8 2.8.7.7L8 8.7l2.6 2.6-.7.7.9.9.5-.4 3.6 3.5 1.2-1.2-3.6-3.5.4-.5-.9-.8-.7.7L8.7 8l2.8-2.8-.4-.3-.3-.4L8 7.3 3.6 3 3 1.8 2.1.9.4 0 0 .4l.9 1.7.9.9 1.1.7L7.3 8zm-2.4 3.1C3.8 10 2 10 .9 11.1c-.7.7-1 1.7-.8 2.7l2-2 1.8.4.3 1.8-2.1 2.1c1.5.4 3.1-.6 3.4-2.1.4-1.2.1-2.2-.6-2.9z\"/></svg>",
  "trash-can": "<svg viewBox=\"0 0 14 16\"><path d=\"M11 3V0H3v3H0v2h2v11h10V5h2V3h-3zM4 1h6v1H4V1zm.5 12.2c0 .3-.2.5-.5.5s-.5-.2-.5-.5V5.8c0-.3.2-.5.5-.5s.5.2.5.5v7.4zm3 0c0 .3-.2.5-.5.5s-.5-.2-.5-.5V5.8c0-.3.2-.5.5-.5s.5.2.5.5v7.4zm3 0c0 .3-.2.5-.5.5s-.5-.2-.5-.5V5.8c0-.3.2-.5.5-.5s.5.2.5.5v7.4z\"/></svg>",
  "triangle-down": "<svg viewBox=\"0 0 11 5.5\"><path d=\"M5.5 5.5L0 0h11L5.5 5.5z\"/></svg>",
  "triangle-left": "<svg viewBox=\"0 0 5.5 11\"><path d=\"M0 5.5L5.5 0v11L0 5.5z\"/></svg>",
  "triangle-right": "<svg viewBox=\"0 0 5.5 11\"><path d=\"M5.5 5.5L0 11V0l5.5 5.5z\"/></svg>",
  "triangle-up": "<svg viewBox=\"0 0 11 5.5\"><path d=\"M5.5 0L11 5.5H0L5.5 0z\"/></svg>",
  "umbrella": "<svg viewBox=\"0 0 15.5 15.9\"><path d=\"M15.5 8.1c-.8-.2-1.6-.6-2.3-1.1l-2.8 1.6-2-1.4v5.7c0 2.9-4.2 4.7-4.9.2h1c.5 3.1 2.9 1.4 2.8-.4V7.1L5 8.4 2.8 6.9S1.1 8.2 0 8.1c-.3 0 2.7-6.5 7.3-7 0-.4.1-.7 0-1.1h1v1.1c5 .6 7.5 7 7.2 7z\"/></svg>",
  "unlock": "<svg viewBox=\"0 0 15.5 14.8\"><path d=\"M11 0C8.5 0 6.5 2 6.5 4.5V6H0v8.8h10.8V6H8.5V4.5C8.5 3.1 9.6 2 11 2s2.5 1.1 2.5 2.5V6h2V4.5C15.5 2 13.5 0 11 0z\"/></svg>",
  "video-camera": "<svg viewBox=\"0 0 16 11\"><path d=\"M11.7 3.8c-.4.2-.7 0-.7-.6V0H0v11h11V7.8c0-.6.3-.8.7-.6S16 9.7 16 9.7V1.2l-4.3 2.6z\"/></svg>",
  "wifi": "<svg viewBox=\"0 0 15.5 12.3\"><path d=\"M7.8 12.3s-2.3-3-2.6-3.4c2.9-2.9 5.3 0 5.3 0l-2.7 3.4zm5-6.4C10 3.1 5.5 3.1 2.7 5.8l1.4 1.4c2.1-2 5.3-2 7.4 0-.1 0 1.3-1.3 1.3-1.3zm2.7-2.7C11.2-1.1 4.3-1.1 0 3.2l1.4 1.4c3.6-3.5 9.2-3.5 12.8 0l1.3-1.4z\"/></svg>",
  "wrench": "<svg viewBox=\"0 0 16 16\"><path d=\"M15 5.6c-1.3 1.3-3.4 1.3-4.7 0S9 2.2 10.3.9c.8-.8 2-1.1 3.1-.9L11 2.4l.4 2 2.1.4 2.4-2.4c.3 1.3-.1 2.4-.9 3.2zm-4.3.4l-.7-.7-10 10 .7.7 10-10z\"/></svg>",
  "x-circle-solid": "<svg viewBox=\"0 0 15.7 15.7\"><path d=\"M15.7 7.9c0-4.3-3.5-7.9-7.9-7.9S0 3.5 0 7.9c0 4.3 3.5 7.9 7.9 7.9 4.3-.1 7.8-3.6 7.8-7.9zm-5 4.2L7.9 9.3 5 12.1l-1.4-1.4 2.8-2.8-2.8-2.8L5 3.6l2.8 2.8 2.8-2.8L12 5 9.3 7.9l2.8 2.8-1.4 1.4z\"/></svg>",
  "x-circle": "<svg viewBox=\"0 0 15.7 15.7\"><path d=\"M0 7.9C0 3.5 3.5 0 7.9 0s7.9 3.5 7.9 7.9-3.5 7.9-7.9 7.9C3.5 15.7 0 12.2 0 7.9zm14.7 0c0-3.8-3.1-6.9-6.9-6.9C4.1 1 1 4.1 1 7.9c0 3.8 3.1 6.9 6.9 6.9 3.8-.1 6.8-3.1 6.8-6.9zm-4.4 3.2L4.7 5.5l.7-.7 5.6 5.6-.7.7zm-5.6-.7l5.7-5.7.6.7-5.7 5.7-.6-.7z\"/></svg>",
  "x": "<svg viewBox=\"0 0 12.1 12.2\"><path d=\"M0 11.3L11.4 0l.7.7L.7 12l-.7-.7zM.7 0l11.4 11.4-.7.7L0 .7.7 0z\"/></svg>"
};
const customIconsAttributes = {};

/**
 * GluIcons - main function/class to create Glu SVG-based Icons.
 *
 * @param mainOptions - object with possible optional fields:
 *
 * @param mainOptions.iconsType
 * value 'font' - use font-based icons. It's former, old approach. v < 1.8.40
 * value 'svg'; - use SVG icons. It's new approach, related to IE fonts files issues. v > 1.9 (2.0.0)
 * Default behaviour/value is 'svg' (if nothing provided from JavaScript file of requireJS config object)

 * # .customIconsData - object, to extend gluIcons.iconsData
 * #
 * Alternative to `customIconsDataCallback`. By default , following Singleton pattern, if no iconData, it will use initial constant value.
 * customIconsData object key/value pair example:
 * "chat": "<svg viewBox=\"0 0 16 16\"><path d=\"M15 11H8.667L5 14v-3H1V1h14m1-1H0v12h4v4l5-4h7V0z\" data-name=\"Layer 2\"/></svg>"
 * If LOB code provide customIconsData object, glu-icons will use it for extension. Yes, by extending you can also override icon.
 *
 * #
 * # .customIconsDataCallback - function
 * #
 * Alternative to `customIconsData`. Expected as function callback to be executed if iconName is not found in Glu Icons set.
 * Providing customIconDataCallback also mean, that code will return custom icon code instead of Glu icons code, but it will not override existed Glu iconsData.
 *
 * #
 * # .createJsonFile - boolean (not fully verified approach yet.)
 * #
 * Alternative for LOB codebase if they don't use AMD/define approach of DI, and instead would like to generate JSON file and use it for customIconsdata later.
 *
 * #
 * # .customLegacyAliases - object (not fully verified approach yet.)
 * #
 * Useful for LOB to extend/override aliases.
 *
 * @returns {iconsData: iconsData, getIcon: function}
 * @constructor
 */
export default function GluIcons(mainOptions) {
  if (!this.iconsData) {
    this.iconsData = ICONS_DATA;
  }

  if (mainOptions && mainOptions.customIconsData) {
    if (mainOptions.customIconsData instanceof Object) {
      util.extend(this.iconsData, mainOptions.customIconsData);
    } else {
      throw new Error('Please provide valid Object, where key is real lowerCase iconName and value is and svgCode (SVG tag only).');
    }
  }

  if (mainOptions && mainOptions.customIconsAttributes) {
    util.extend(customIconsAttributes, mainOptions.customIconsAttributes);
  }

  this.registerIconHelper = () => {
    // important to use bind(this) otherwise iconHelper loosing context, and throws an error.
    Handlebars.registerHelper('icon', this.iconHelper.bind(this));
    return this.iconHelper;
  };

  /**
   * Returns WCAG-compliant icon markup.
   * This function now is combined, and contains font-based and svg-based icons approaches.
   * Depends on mainOptions.iconsType value we define which iconTemplate to use.
   * And suggested to create instance of GluIcons by providing mainOptions.iconType value = 'font' or 'svg'. If ommited, then 'svg' will be used.
   */
  this.iconHelper = (name, text, context) => {
    if (!context) {
      context = {};
    }
    if (context && !context.hash) {
      context.hash = {};
    }
    if (util.isObject(name)) {
      context = util.extend(context, name);
    } else if (util.isObject(text)) {
      context = util.extend(context, text);
      context.hash.name = name;
    } else {
      context.hash.name = name;
      context.hash.text = text;
    }

    if (context.hash.locale) {
      // TODO locale.pairs doesn't have value set from tests.
      context.hash.text = locale.get(context.hash.locale);
    }

    // by default, if no text provided, we better show at least something (for sr-only and for aria-label).
    // we also need to avoid name value, in case if it's about getting localized value.
    const defaultTextValue = (context.hash.name && util.isEmpty(context.hash.locale)) ? context.hash.name : '';

    const options = util.extend({
      text: defaultTextValue
    }, context.hash);

    options.title = customIconsAttributes.noTitle && options.title === undefined ? '' : Handlebars.Utils.escapeExpression(options.title || options.text);

    // Accessibility options (.srOnly, .ariaLabel, .role), if provided, will be applied to SPAN.icon-wrapper
    options.srOnly = options.srOnly || options.title;
    // if I omit this line test failed.
    // TODO rework this after GLU-966, I'm not sure why test says it should be sr-only class named element.

    // there was discussion to NOT use div, so instead use span
    options.primaryTagName = options.action ? 'button' : 'span';

    // LOB specific icon options. Optional.
    options.spanElementOptions = {
      title: options.title,
      customDataAttributes: options.customDataAttributes,
      className: options.className || false,
      style: options.style || false
    };

    // default value "svg"
    // In future, if all LOB will migrate and use ONLY SVG-based approach, below code can be removed.
    // And then using "svg-based" css class can be also removed.
    options.iconsType = options.iconsType || 'svg';
    if (options.iconsType === 'svg') {
      // this condition needed to avoid auto-casting from undefined to "undefined", in case if no classname at all.
      if (options.className) {
        options.className += ' svg-based';
      } else {
        options.className = 'svg-based';
      }
      options.iconHTML = this.getIcon(options.name, options.spanElementOptions);
    }
    // and if "font", then above condition will be skipped

    return new Handlebars.SafeString(iconTemplate(options));
  };

  /**
   * Helper function, to get icon code
   * No need to inject it manually.
   *
   * @param iconName - string value of icon name
   * @param spanElementOptions - optional. object of options to apply for icon span. Mostly needed by LOB specific.
   * @return
   */
  this.getIcon = (iconName, spanElementOptions) => { // 95% the same function as in gluIconsWindow.js
    if (!iconName) {
      console.error('Provide an icon name in helper {{icon}}');
      return '';
    }
    spanElementOptions = spanElementOptions || {};

    // 1) By the load flow of dependencies, LOB can provide mainOptions.customIconsData and GluIcons will extend its iconsData object.
    // LOB codebase will have helpful JS/JSON files created by glu-icons npm module.

    // 2) by name of icon, getIcons tries to access to glu-icons data set and return svgCode.
    let svgCode = this.iconsData ? this.iconsData[iconName] : '';

    // 3) If no glu-icons built-in icon with a name, need to consider aliases, which might not exists btw.
    if (!svgCode) {
      svgCode = this.getIconByAlias(iconName);
    }

    // 4) If no iconCode found, but maybe there is LOB custom callback function, after execution of which new iconsData can be extended.
    if (!svgCode && mainOptions.customIconsDataCallback && typeof mainOptions.customIconsDataCallback === 'function') {
      svgCode = mainOptions.customIconsDataCallback(iconName);
    }

    // 5) After all possible ways to get baseline iconsData, we compose icon wrapper - SPAN element.
    // Below lines are borrowed from LSM/PartnerSelect/LegalX, and can be considered as helpful for other LOBs also.
    const title = spanElementOptions.title ? ` title="${spanElementOptions.title}"` : '';
    let dataAttributes = '';
    if (spanElementOptions.customDataAttributes && spanElementOptions.customDataAttributes instanceof Array) {
      for (let i = 0; i < spanElementOptions.customDataAttributes.length; i++) {
        dataAttributes += ` data-${spanElementOptions.customDataAttributes[i].key}="${spanElementOptions.customDataAttributes[i].value}"`;
      }
    }
    const style = spanElementOptions.style ? ` style="${spanElementOptions.style}"` : '';

    // 6) Considering, that GluIcons will be used in Glu code, it's expected to have Handlebars helper, which will wrap below code by one more SPAN.
    return `<span class="icon-${iconName}" ${title}${dataAttributes}${style} >${svgCode}</span>`;
  };

  /* eslint no-unused-vars : "off" */
  /* eslint consistent-return : "off" */
  this.getIconByAlias = (iconName) => { // iconName will be used inside of SWITCH
    let iconDataKey;

    switch (iconName) {
			case 'address-book':
				iconDataKey = 'address-book';
				break;
			case 'time':
			case 'alarm-clock':
				iconDataKey = 'alarm-clock';
				break;
			case 'circle-arrow':
			case 'arrow-circle-left':
				iconDataKey = 'arrow-circle-left';
				break;
			case 'undo':
			case 'arrow-curved-left':
				iconDataKey = 'arrow-curved-left';
				break;
			case 'redo':
			case 'arrow-curved-right':
				iconDataKey = 'arrow-curved-right';
				break;
			case 'motion-arrow':
			case 'arrow-dashes':
				iconDataKey = 'arrow-dashes';
				break;
			case 'arrow-thin-down':
			case 'arrow-down':
				iconDataKey = 'arrow-down';
				break;
			case 'arrow-thin-left':
			case 'arrow-left':
				iconDataKey = 'arrow-left';
				break;
			case 'arrow-thin-right':
			case 'arrow-right':
				iconDataKey = 'arrow-right';
				break;
			case 'arrow-thin-up':
			case 'arrow-up':
				iconDataKey = 'arrow-up';
				break;
			case 'refresh':
			case 'arrows-circles':
				iconDataKey = 'arrows-circles';
				break;
			case 'shrink':
			case 'arrows-corners-in':
				iconDataKey = 'arrows-corners-in';
				break;
			case 'enlarge':
			case 'arrows-corners-out':
				iconDataKey = 'arrows-corners-out';
				break;
			case 'check-badge':
			case 'badge-checkmark':
				iconDataKey = 'badge-checkmark';
				break;
			case 'workspace':
			case 'blocks':
				iconDataKey = 'blocks';
				break;
			case 'book-with-mark':
			case 'book':
				iconDataKey = 'book';
				break;
			case 'bookmark':
				iconDataKey = 'bookmark';
				break;
			case 'new-tab':
			case 'box-arrow-out':
				iconDataKey = 'box-arrow-out';
				break;
			case 'logout':
			case 'box-arrow-right':
				iconDataKey = 'box-arrow-right';
				break;
			case 'drag':
			case 'boxes-columns':
				iconDataKey = 'boxes-columns';
				break;
			case 'briefcase-paperclip':
				iconDataKey = 'briefcase-paperclip';
				break;
			case 'briefcase-add':
			case 'briefcase-plus':
				iconDataKey = 'briefcase-plus';
				break;
			case 'briefcase':
				iconDataKey = 'briefcase';
				break;
			case 'courthouse-checkmark':
			case 'building-checkmark':
				iconDataKey = 'building-checkmark';
				break;
			case 'courthouse-plus':
			case 'batch-case':
			case 'batch_case':
			case 'building-plus':
				iconDataKey = 'building-plus';
				break;
			case 'courthouse':
			case 'cases':
			case 'legal-building':
			case 'building':
				iconDataKey = 'building';
				break;
			case 'claim-offices':
			case 'buildings':
				iconDataKey = 'buildings';
				break;
			case 'calendar':
				iconDataKey = 'calendar';
				break;
			case 'down-caret':
			case 'caret-down':
				iconDataKey = 'caret-down';
				break;
			case 'left-caret':
			case 'caret-left':
				iconDataKey = 'caret-left';
				break;
			case 'first':
			case 'caret-line-left':
				iconDataKey = 'caret-line-left';
				break;
			case 'last':
			case 'caret-line-right':
				iconDataKey = 'caret-line-right';
				break;
			case 'right-caret':
			case 'caret-right':
				iconDataKey = 'caret-right';
				break;
			case 'up-caret':
			case 'caret-up':
				iconDataKey = 'caret-up';
				break;
			case 'collect':
			case 'discount-categories':
			case 'money':
			case 'cash-in-hand':
				iconDataKey = 'cash-in-hand';
				break;
			case 'bar-graph':
			case 'bar-graph-border':
			case 'analytics':
			case 'chart-bar':
				iconDataKey = 'chart-bar';
				break;
			case 'scatter-graph':
			case 'chart-circles':
				iconDataKey = 'chart-circles';
				break;
			case 'donut-graph':
			case 'chart-donut':
				iconDataKey = 'chart-donut';
				break;
			case 'line':
			case 'line-graph':
			case 'line-graph-border':
			case 'chart-line':
				iconDataKey = 'chart-line';
				break;
			case 'pie':
			case 'pie-graph':
			case 'chart-pie':
				iconDataKey = 'chart-pie';
				break;
			case 'chat-bubble':
			case 'chat':
				iconDataKey = 'chat';
				break;
			case 'check-payment':
				iconDataKey = 'check-payment';
				break;
			case 'checks':
			case 'autorule-setup':
			case 'checkboxes':
				iconDataKey = 'checkboxes';
				break;
			case 'checkbox':
			case 'checkmark-box':
				iconDataKey = 'checkmark-box';
				break;
			case 'checkmark-circle-solid':
				iconDataKey = 'checkmark-circle-solid';
				break;
			case 'check-circle':
			case 'tick-solid':
			case 'checkmark-circle':
				iconDataKey = 'checkmark-circle';
				break;
			case 'check':
			case 'check-mark':
			case 'tick':
			case 'checkmark':
				iconDataKey = 'checkmark';
				break;
			case 'circle-minus-arrow':
				iconDataKey = 'circle-minus-arrow';
				break;
			case 'solid-circle':
			case 'circle-solid':
				iconDataKey = 'circle-solid';
				break;
			case 'circle':
				iconDataKey = 'circle';
				break;
			case 'check-clipboard':
			case 'clipboard-check':
			case 'clipboard-checkmark':
				iconDataKey = 'clipboard-checkmark';
				break;
			case 'clipboard-document':
				iconDataKey = 'clipboard-document';
				break;
			case 'clip-board':
			case 'clipboard':
				iconDataKey = 'clipboard';
				break;
			case 'history':
			case 'deduction-history':
			case 'clock-arrow-left':
				iconDataKey = 'clock-arrow-left';
				break;
			case 'check-clock':
			case 'timekeeper':
			case 'clock-checkmark':
				iconDataKey = 'clock-checkmark';
				break;
			case 'dollar-time':
			case 'rates':
			case 'clock-money':
				iconDataKey = 'clock-money';
				break;
			case 'diary':
			case 'clock':
				iconDataKey = 'clock';
				break;
			case 'remove-columns':
			case 'columns-x':
				iconDataKey = 'columns-x';
				break;
			case 'columns':
				iconDataKey = 'columns';
				break;
			case 'contacts':
			case 'profile-card':
			case 'membership':
			case 'contact-card':
				iconDataKey = 'contact-card';
				break;
			case 'download':
			case 'container-arrow-down':
				iconDataKey = 'container-arrow-down';
				break;
			case 'upload':
			case 'container-arrow-up':
				iconDataKey = 'container-arrow-up';
				break;
			case 'card':
			case 'credit-card':
				iconDataKey = 'credit-card';
				break;
			case 'stack':
			case 'cylinder-stack':
				iconDataKey = 'cylinder-stack';
				break;
			case 'doc-download':
			case 'download-doc':
			case 'document-arrow-down':
				iconDataKey = 'document-arrow-down';
				break;
			case 'export':
			case 'document-arrow':
				iconDataKey = 'document-arrow';
				break;
			case 'doc-check':
			case 'doc-check-solid':
			case 'document-check':
				iconDataKey = 'document-check';
				break;
			case 'document-clock':
				iconDataKey = 'document-clock';
				break;
			case 'csv':
			case 'document-csv':
				iconDataKey = 'document-csv';
				break;
			case 'doc-dollar':
			case 'invoices':
			case 'document-dollar-sign':
				iconDataKey = 'document-dollar-sign';
				break;
			case 'doc':
			case 'claim-notes':
			case 'doc-solid':
			case 'reporting':
			case 'document-lines':
				iconDataKey = 'document-lines';
				break;
			case 'doc-search':
			case 'search-doc':
			case 'document-magnify':
				iconDataKey = 'document-magnify';
				break;
			case 'doc-delete':
			case 'delete-doc':
			case 'document-minus':
				iconDataKey = 'document-minus';
				break;
			case 'doc-cash':
			case 'invoicing':
			case 'document-payment':
				iconDataKey = 'document-payment';
				break;
			case 'pdf':
			case 'document-pdf':
				iconDataKey = 'document-pdf';
				break;
			case 'doc-edit':
			case 'edit-doc':
			case 'document-pencil':
				iconDataKey = 'document-pencil';
				break;
			case 'doc-blank-add':
			case 'add-doc':
			case 'doc-add':
			case 'document-plus':
				iconDataKey = 'document-plus';
				break;
			case 'doc-question':
			case 'question-doc':
			case 'document-question':
				iconDataKey = 'document-question';
				break;
			case 'document-slash':
				iconDataKey = 'document-slash';
				break;
			case 'xls':
			case 'document-xls':
				iconDataKey = 'document-xls';
				break;
			case 'xml':
			case 'document-xml':
				iconDataKey = 'document-xml';
				break;
			case 'doc-blank':
			case 'single-doc':
			case 'document':
				iconDataKey = 'document';
				break;
			case 'docs':
			case 'docs-multiple':
			case 'multiple-docs':
			case 'docs-multiple-solid':
			case 'multiple-docs-solid':
			case 'documents':
				iconDataKey = 'documents';
				break;
			case 'door-arrow-in':
				iconDataKey = 'door-arrow-in';
				break;
			case 'door-arrow-out':
				iconDataKey = 'door-arrow-out';
				break;
			case 'download-drawer':
			case 'drawer-arrow-down':
				iconDataKey = 'drawer-arrow-down';
				break;
			case 'upload-drawer':
			case 'drawer-arrow-up':
				iconDataKey = 'drawer-arrow-up';
				break;
			case 'drawer':
				iconDataKey = 'drawer';
				break;
			case 'envelope-document':
				iconDataKey = 'envelope-document';
				break;
			case 'filled-envelope':
			case 'filled-mail':
			case 'mail-open':
			case 'envelope-open':
				iconDataKey = 'envelope-open';
				break;
			case 'mail':
			case 'envelope':
				iconDataKey = 'envelope';
				break;
			case 'exclamation-solid':
			case 'exclamation-circle-solid':
				iconDataKey = 'exclamation-circle-solid';
				break;
			case 'exclamation-circle':
				iconDataKey = 'exclamation-circle';
				break;
			case 'warning':
			case 'exclamation-triangle-solid':
				iconDataKey = 'exclamation-triangle-solid';
				break;
			case 'exclamation-triangle':
				iconDataKey = 'exclamation-triangle';
				break;
			case 'hide':
			case 'eye-line':
				iconDataKey = 'eye-line';
				break;
			case 'eyeball':
			case 'eye':
				iconDataKey = 'eye';
				break;
			case 'funnel-graph':
			case 'filter-stacked':
				iconDataKey = 'filter-stacked';
				break;
			case 'filter':
				iconDataKey = 'filter';
				break;
			case 'flag':
				iconDataKey = 'flag';
				break;
			case 'save':
			case 'floppy-disk':
				iconDataKey = 'floppy-disk';
				break;
			case 'lock-folder':
			case 'folder-lock':
				iconDataKey = 'folder-lock';
				break;
			case 'folder-minus':
				iconDataKey = 'folder-minus';
				break;
			case 'open-folder':
			case 'folder-open':
				iconDataKey = 'folder-open';
				break;
			case 'folder-plus':
				iconDataKey = 'folder-plus';
				break;
			case 'file-zip':
			case 'folder-zip':
				iconDataKey = 'folder-zip';
				break;
			case 'folder':
				iconDataKey = 'folder';
				break;
			case 'gauge':
				iconDataKey = 'gauge';
				break;
			case 'gavel':
				iconDataKey = 'gavel';
				break;
			case 'gear':
				iconDataKey = 'gear';
				break;
			case 'multiple-gears':
			case 'gears':
				iconDataKey = 'gears';
				break;
			case 'global-network':
			case 'globe-gears':
				iconDataKey = 'globe-gears';
				break;
			case 'globe':
				iconDataKey = 'globe';
				break;
			case 'grad-cap':
			case 'graduation-cap':
				iconDataKey = 'graduation-cap';
				break;
			case 'hand':
			case 'handout':
				iconDataKey = 'handout';
				break;
			case 'home':
				iconDataKey = 'home';
				break;
			case 'image':
				iconDataKey = 'image';
				break;
			case 'info-solid':
			case 'info-circle-solid':
				iconDataKey = 'info-circle-solid';
				break;
			case 'info':
			case 'info-circle':
				iconDataKey = 'info-circle';
				break;
			case 'key':
				iconDataKey = 'key';
				break;
			case 'screen-browser':
			case 'layout-browser':
				iconDataKey = 'layout-browser';
				break;
			case 'letters':
				iconDataKey = 'letters';
				break;
			case 'lightbulb':
				iconDataKey = 'lightbulb';
				break;
			case 'lines-arrow-down':
				iconDataKey = 'lines-arrow-down';
				break;
			case 'rows':
			case 'lines':
				iconDataKey = 'lines';
				break;
			case 'list-browser':
				iconDataKey = 'list-browser';
				break;
			case 'numbers':
			case 'numbered-list':
			case 'list-number':
				iconDataKey = 'list-number';
				break;
			case 'list-outline':
				iconDataKey = 'list-outline';
				break;
			case 'list':
				iconDataKey = 'list';
				break;
			case 'locator':
				iconDataKey = 'locator';
				break;
			case 'padlock':
			case 'lock':
				iconDataKey = 'lock';
				break;
			case 'magnify-minus':
				iconDataKey = 'magnify-minus';
				break;
			case 'magnify-plus':
				iconDataKey = 'magnify-plus';
				break;
			case 'search':
			case 'magnify':
				iconDataKey = 'magnify';
				break;
			case 'microphone-slash':
				iconDataKey = 'microphone-slash';
				break;
			case 'microphone':
				iconDataKey = 'microphone';
				break;
			case 'minus-solid':
			case 'error':
			case 'minus-circle-solid':
				iconDataKey = 'minus-circle-solid';
				break;
			case 'minus-circle':
				iconDataKey = 'minus-circle';
				break;
			case 'minus':
				iconDataKey = 'minus';
				break;
			case 'monitor':
				iconDataKey = 'monitor';
				break;
			case 'branch':
			case 'network-branch':
				iconDataKey = 'network-branch';
				break;
			case 'network':
				iconDataKey = 'network';
				break;
			case 'paper_clip':
			case 'paper-clip':
				iconDataKey = 'paper-clip';
				break;
			case 'cash-history':
			case 'budget-history':
			case 'rebate':
			case 'payment-left-arrow':
				iconDataKey = 'payment-left-arrow';
				break;
			case 'cash-quick':
			case 'quick-pay':
			case 'payment-quick':
				iconDataKey = 'payment-quick';
				break;
			case 'cash':
			case 'budget':
			case 'payments':
			case 'currency':
			case 'payment':
				iconDataKey = 'payment';
				break;
			case 'pencil':
				iconDataKey = 'pencil';
				break;
			case 'multiple-person':
			case 'users':
			case 'people':
				iconDataKey = 'people';
				break;
			case 'user-minus':
			case 'person-minus':
				iconDataKey = 'person-minus';
				break;
			case 'person-network':
				iconDataKey = 'person-network';
				break;
			case 'user-plus':
			case 'person-plus':
				iconDataKey = 'person-plus';
				break;
			case 'person-delete':
			case 'user-delete':
			case 'person-x':
				iconDataKey = 'person-x';
				break;
			case 'user':
			case 'person':
				iconDataKey = 'person';
				break;
			case 'mobile-phone':
			case 'phone-mobile':
				iconDataKey = 'phone-mobile';
				break;
			case 'smart-phone':
			case 'phone-touch-screen':
				iconDataKey = 'phone-touch-screen';
				break;
			case 'phone':
				iconDataKey = 'phone';
				break;
			case 'add-solid':
			case 'plus-circle-solid':
				iconDataKey = 'plus-circle-solid';
				break;
			case 'add':
			case 'add-circle':
			case 'plus-circle':
				iconDataKey = 'plus-circle';
				break;
			case 'plus':
				iconDataKey = 'plus';
				break;
			case 'power-button':
			case 'power':
				iconDataKey = 'power';
				break;
			case 'print':
			case 'printer':
				iconDataKey = 'printer';
				break;
			case 'puck':
				iconDataKey = 'puck';
				break;
			case 'question-solid':
			case 'question-circle-solid':
				iconDataKey = 'question-circle-solid';
				break;
			case 'question-circle':
				iconDataKey = 'question-circle';
				break;
			case 'ribbon':
			case 'rosette':
			case 'ribbon-award':
				iconDataKey = 'ribbon-award';
				break;
			case 'disposition':
			case 'law':
			case 'scales':
				iconDataKey = 'scales';
				break;
			case 'servers':
				iconDataKey = 'servers';
				break;
			case 'settings-solid':
			case 'settings':
				iconDataKey = 'settings';
				break;
			case 'shield-checkmark':
				iconDataKey = 'shield-checkmark';
				break;
			case 'dollar-shield':
			case 'money-shield':
			case 'shield-dollar':
				iconDataKey = 'shield-dollar';
				break;
			case 'shield':
				iconDataKey = 'shield';
				break;
			case 'cancel':
			case 'disabled':
			case 'slash-circle':
				iconDataKey = 'slash-circle';
				break;
			case 'snowflake':
				iconDataKey = 'snowflake';
				break;
			case 'grid-medium':
			case 'medium-grid':
			case 'squares-four':
				iconDataKey = 'squares-four';
				break;
			case 'grid':
			case 'large-grid':
			case 'squares-nine':
				iconDataKey = 'squares-nine';
				break;
			case 'layer':
			case 'squares-stacked':
				iconDataKey = 'squares-stacked';
				break;
			case 'star':
				iconDataKey = 'star';
				break;
			case 'pivot-table':
			case 'table-pivot':
				iconDataKey = 'table-pivot';
				break;
			case 'table':
				iconDataKey = 'table';
				break;
			case 'tools':
				iconDataKey = 'tools';
				break;
			case 'trash':
			case 'trash-can':
				iconDataKey = 'trash-can';
				break;
			case 'down-triangle':
			case 'triangle-down':
				iconDataKey = 'triangle-down';
				break;
			case 'left-triangle':
			case 'triangle-left':
				iconDataKey = 'triangle-left';
				break;
			case 'right-triangle':
			case 'triangle-right':
				iconDataKey = 'triangle-right';
				break;
			case 'up-triangle':
			case 'triangle-up':
				iconDataKey = 'triangle-up';
				break;
			case 'coverage':
			case 'umbrella':
				iconDataKey = 'umbrella';
				break;
			case 'lock-open':
			case 'padlock-open':
			case 'unlock':
				iconDataKey = 'unlock';
				break;
			case 'video-camera':
				iconDataKey = 'video-camera';
				break;
			case 'wifi':
				iconDataKey = 'wifi';
				break;
			case 'wrench':
				iconDataKey = 'wrench';
				break;
			case 'circle-close-solid':
			case 'x-circle-solid':
				iconDataKey = 'x-circle-solid';
				break;
			case 'circle-close':
			case 'x-circle':
				iconDataKey = 'x-circle';
				break;
			case 'close':
			case 'close-solid':
			case 'x':
				iconDataKey = 'x';
				break;
			default:
				iconDataKey = iconName;
				break;
			}

    if (this.iconsData[iconDataKey]) {
      return this.iconsData[iconDataKey];
    } if (mainOptions.customLegacyAliases) {
      for (const key in mainOptions.customLegacyAliases) {
        if (mainOptions.customLegacyAliases.hasOwnProperty(key) && mainOptions.customLegacyAliases[key].indexOf(iconDataKey) > -1) {
          // assuming, that customIconsData will contain icon with ${key} as a name.
          return this.iconsData[key] ? this.iconsData[key] : '';
        }
      }
    } else {
      console.log(`No icon with name ${iconDataKey}`);
      return '';
    }
  };
}
