import { getMaskingProperties, maskValue } from 'common/util/maskingUtil';
import $ from 'jquery';
import locale from '@glu/locale';
import number from 'numeral';
import alert from '@glu/alerts';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import BeneUpdatedWarning from 'common/dynamicPages/views/beneUpdatedWarning';
import configuration from 'system/configuration';
import Decimals from 'common/dynamicPages/api/decimals';
import entitlements from 'common/dynamicPages/api/entitlements';
import fxFieldValidation from 'common/uiWidgets/util/fxFieldValidation';
import userInfo from 'etc/userInfo';
import validatorPatterns from 'system/validatorPatterns';
import scroll from 'common/util/scroll';
import PaymentUtil from 'common/util/paymentUtil';
import { dark } from 'common/util/featureUtil';
import dateUtil from 'common/util/dateUtil';
import rtgsCommon from './rtgsCommon';

/**
 * Set the label for the RFP_BENE_STATE field to display correctly based on the action mode
 * and the payments BENE_COUNTRY value.
 * @param {Object} formView - Form containing the field label to be updated
 * @param {Model} model - model holding all of the payment attributes
 * @param {String} actionMode - current action (view, modify etc...)
 */
const handleRFPBeneStateProvinceLabel = (formView, model, actionMode) => {
    const isUnitedStatesBene = model.get('BENE_COUNTRY') === 'US';
    let $rfpBeneStateProvinceLabel = formView.$('label[for="RFP_BENE_STATE"]');

    // Need to locate the field label differently in view mode
    if (actionMode === 'view') {
        $rfpBeneStateProvinceLabel = formView.$('div[id="RFP_BENE_STATE"]').find('label');
    }
    /*
     * The field label needs to change based on the bene country.
     * US = State and NON-US = State/Province
     */
    $rfpBeneStateProvinceLabel.text(isUnitedStatesBene ? locale.get('RTP.REQUEST.REQOUT.*.ORIGINATOR_STATE.label') : locale.get('RTP.INST.CRTRAN.*.BENE_PROVINCE.label'));
};

/**
 * @name toggleLegalEntityError
 * @description will add or remove a page-level error based on the legal entity ID and
 * bene personal info being mutually exclusive.
 * @param {boolean} errorBeingAdded - whether to add or remove the error
 */
const toggleLegalEntityError = (errorBeingAdded) => {
    $('.alert-region').empty();
    if (errorBeingAdded) {
        $('.alert-region').append(alert.negative(
            locale.get('RTP.error.LEIPersonalInfoExclusive'),
            {
                canDismiss: false,
            },
        ).render().el);
        scroll.scrollToFirstError();
    }
};

/**
 * @name determineIfLegalEntityIdOrPersonalInfo
 * @description Will run every time any of the following fields change:
 * LEGAL_ENTITY_ID, BENE_BIRTH_CITY, BENE_BIRTH_COUNTRY, BENE_BIRTH_DATE
 * the intent is that within this form there can only be values for either the legal entity
 * OR the beneficiary personal info. Not both.
 * If the personal info exists but isn't displayed, do not perform validation.
 * @param {View} formView
 * @param {boolean} showPersonalInfo
 */
const determineIfLegalEntityIdOrPersonalInfo = (formView, showPersonalInfo) => {
    const { model } = formView;
    if (showPersonalInfo && model.get('LEGAL_ENTITY_ID')
        && (model.get('BENE_BIRTH_CITY') || model.get('BENE_BIRTH_DATE') || model.get('BENE_BIRTH_COUNTRY'))) {
        toggleLegalEntityError(true);
    } else {
        toggleLegalEntityError(false);
    }
};

export default function (form, initialState) {
    const showPersonalInfo = (applicationConfigParams.getValue('RTP', 'REQUIREPERSONALID') === '1');
    const remittanceGroup = 'remittanceGroup';
    const remittanceGroupFields = ['REMITTANCE_ADDRESS', 'REMITTANCE_METHOD'];
    const amountFieldGroup = 'amountFieldGroup';
    const amountFields = ['FULL_AMOUNT', 'DISCOUNT_AMOUNT'];
    const beneBirthGroup = 'beneBirthGroup';
    const beneBirthGroupFields = ['BENE_BIRTH_CITY', 'BENE_BIRTH_COUNTRY', 'BENE_BIRTH_DATE'];
    const addressGroup = 'addressGroup';
    const addressGroupFields = ['BENE_ADDRESS', 'BENE_COUNTRY', 'BENE_CITY', 'BENE_POSTALCODE'];
    let isState = false;
    const $summarySection = $('[data-widget-id="summarysection"]').closest('fieldset');
    let isFromRFP = false;

    /**
     * Configures the value date picker.  The type code and value date are the only
     * essential information, at least for now.  Future days do not vary with currency or
     * bank information.
     * @param {boolean} setEarliestDate - if true retrieve the earliest valid day and set it as
     * the default date. If false just refresh the calander to reflect the current value date.
     */
    const setValueDate = function (setEarliestDate) {
        const { formView } = form;
        const { model } = formView;

        const postData = {
            paymentType: model.jsonData.typeInfo.typeCode,
            debitBank: model.get('DEBIT_BANK_CODE'),
            debitCurrency: model.get('DEBIT_CURRENCY'),
            debitBankCountry: model.get('DEBIT_COUNTRY'),
            subType: model.jsonData.subtype === undefined ? '*' : model.jsonData.subtype,
            creditCurrency: model.get('CREDIT_CURRENCY'),
            creditBankCountry: model.get('BENE_BANK_COUNTRY'),
            beneBankId: model.get('BENE_BANK_ID'),
            beneBankType: model.get('BENE_BANK_TYPE'),
            valueDate: model.get('VALUE_DATE'),
            tranDate: model.get('TRAN_DATE'),
        };

        if (setEarliestDate) {
            dateUtil.setEffectiveDate(form, 'VALUE_DATE', postData);
        } else {
            dateUtil.refreshEffectiveDateCalendar(form, 'VALUE_DATE', postData);
        }
    };

    /**
     * Flags group of fields as required when necessary.
     * These address fields follow an "all or none" rule: if any one of them is present,
     * all must be present, otherwise they are all optional.
     * @param {string} groupName : name of the fieldGroup
     * @param {string[]} fields : an array of field names
     * @param {boolean} requiredByDefault
     */
    const toggleFieldsMandatory = function (groupName, fields, requiredByDefault) {
        const { formView } = form;
        const shouldRequire = requiredByDefault
                || fields.some(field => !!formView.model.get(field));

        if (formView.shouldRequire[groupName] !== shouldRequire) {
            // toggle
            util.each(fields, (fieldName) => {
                let $field = formView.$(`label[for="${fieldName}"]`);
                /*
                 * for some fields the label selector doesn't work
                 * for instance, BENE_ADDRESS and BENE_ADDRESS_2
                 * their labels have some random numbers appended
                 */
                if ($field.length === 0) {
                    $field = formView.$(`[name="${fieldName}"]`);
                }
                form.field(fieldName).shouldBeRequiredWhen(shouldRequire);
                $field.parent().toggleClass('required', shouldRequire);
            });
            formView.shouldRequire[groupName] = shouldRequire;
        }
    };

    /**
     * Flags the beneficiary address fields as required when necessary.
     * These address fields follow an "all or none" rule: if any one of them is present,
     * all must be present, otherwise they are all optional.
     */
    const toggleBeneAddressMandatory = function () {
        const beneAddressRequired = applicationConfigParams.getValue('RTP', 'REQUIREBENEADDRESS');
        const fields = addressGroupFields.slice();
        const isMandatory = !isFromRFP && (beneAddressRequired === '1' || form.formView.model.get('BENE_ADDRESS_2') !== '');

        if (isFromRFP) {
            fields.push('RFP_BENE_STATE');
        } else if (isState) {
            fields.push('BENE_STATE');
        } else {
            fields.push('BENE_PROVINCE');
        }
        toggleFieldsMandatory(addressGroup, fields, isMandatory);
        /*
         * As a consequence, the built in functionality that would open the
         * collapsed parent panel does not do so and we have to show it manually.
         */
        form.formView.$('[name="BENE_ADDRESS"]').closest('.panel-collapse').collapse('show');
    };

    /**
     * Flag the beneficiary personal information fields as mandatory when appropriate.
     * Like the address fields, these are treated as a group, if one is present,
     * all must be present, otherwise they are all optional.
     */
    const toggleBeneBirthMandatory = function () {
        toggleFieldsMandatory(beneBirthGroup, beneBirthGroupFields, false);
    };

    /**
     * if either Full_Amount or Discount_Amount is entered, make them both required
     */
    const toggleAmountsMandatory = function () {
        toggleFieldsMandatory(amountFieldGroup, amountFields, false);
    };

    /**
     * if either REMITTANCE_ADDRESS or REMITTANCE_METHOD is entered, make them both required
     * if REMITTANCE_METHOD is email, validate that REMITTANCE_ADDRESS is valid email address
     */
    const toggleRemittanceMandatory = function () {
        const { model } = form.formView;
        model.removeValidatorProp('REMITTANCE_ADDRESS', 'matches');
        model.removeValidatorProp('REMITTANCE_ADDRESS', 'overrideError');
        toggleFieldsMandatory(remittanceGroup, remittanceGroupFields, false);
        if (model.get('REMITTANCE_METHOD') === 'EMAL') {
            model.addValidatorProp('REMITTANCE_ADDRESS', 'matches', validatorPatterns.EMAIL_PATTERN);
            model.addValidatorProp('REMITTANCE_ADDRESS', 'description', locale.get('RTP.INST.CRTRAN.*.REMITTANCE_ADDRESS'));
            model.addValidatorProp('REMITTANCE_ADDRESS', 'overrideError', 'emailAddress');
        }
    };

    /*
     * Installs the listeners that enforce the mandatory status of the beneficiary
     * address fields.
     *
     * @returns {undefined}
     */
    const installBeneAddressListeners = function () {
        const { formView } = form;
        const { model } = formView;

        formView.$('[name="BENE_ADDRESS"], [name="BENE_CITY"], [name="BENE_POSTALCODE"]').on('blur change', () => {
            toggleBeneAddressMandatory();
        });

        /*
         * The UI drop-down doesn't generate blur events so we listen to changes
         * on the model.
         */
        model.on('change:BENE_COUNTRY change:BENE_STATE change:BENE_PROVINCE', () => {
            toggleBeneAddressMandatory();
        });

        // For TCH2.9, perform the following
        model.on('change:BENE_ADDRESS_2', () => {
            // when importing contact that contains second address line, make sure it's shown
            if (model.get('BENE_ADDRESS_2') !== '') {
                formView.$('#BENE_ADDRESS_LINK').hide();
                formView.$('[name="BENE_ADDRESS_2"]').parent().removeClass('hide');
            }
            toggleBeneAddressMandatory();
        });
    };

    /**
     * Installs the listeners that enforce the mandatory status of the beneficiary
     * personal information fields.
     *
     * returns {undefined}
     */
    const installBeneBirthInfoListeners = function () {
        /*
         * We listen for changes on the model because drop-downs don't appear
         * to generate blur events.
         */
        form.formView.model.on('change:BENE_BIRTH_COUNTRY', () => {
            toggleBeneBirthMandatory();
        });

        form.formView.$('[name="BENE_BIRTH_CITY"], [name="BENE_BIRTH_DATE"]').on('blur change', () => {
            toggleBeneBirthMandatory();
        });
    };

    /**
     * Sets up the summary section for non-insert action modes
     * Example: 4.00 USD to 1 Beneficiary on 29 Jan 2019
     */
    const setupSummarySection = function () {
        const { model } = form.formView;
        const $paymentSummaryContainer = $('[data-section="payment-summary"]');
        const $amountFields = $paymentSummaryContainer.find('[data-field="summary-amount"]');
        const $currencyFields = $paymentSummaryContainer.find('[data-field="summary-currency"]');
        const creditAmount = model.get('CREDIT_AMOUNT');
        const creditCurrency = model.get('CREDIT_CURRENCY');

        // Update the summary sections visibility
        $paymentSummaryContainer.removeClass('hidden');
        $paymentSummaryContainer.filter('.section-header').children().first().addClass('hidden');

        // Set the currency code
        $currencyFields.text(creditCurrency);

        // Format and set the amount
        const paymentTotalFormatted = number(creditAmount || 0)
            .format(Decimals.getCurrencyFormat(creditCurrency));
        $amountFields.text(paymentTotalFormatted);

        PaymentUtil.updateRTGSSummaryTotal(model, form.formView);
    };

    const getTemplateEntitlement = function () {
        if (configuration.isAdmin()) {
            $('[data-section="save-as-template"]').addClass('hidden');
            return;
        }

        const ctx = {
            serviceName: 'template/rtp',
        };

        const opt = {
            context: ctx,
        };
            // retrieve entitlements prior to rendering
        entitlements.getEntitlements(opt).then((result) => {
            // dont show save as template if not entitled
            const hasPermission = !!result.actions.INSERT;
            if (!hasPermission) {
                $('[data-section="save-as-template"]').addClass('hidden');
            }
        });
    };

    /**
     * Always Mask Bene account for RTP from RFPs
     */
    const maskBeneAccount = function () {
    // Bene Account should be masked
        const { formView } = form;
        const { model } = formView;
        const maskingProps = getMaskingProperties();
        const actionMode = formView.state;
        maskingProps.disableMask = false;
        maskingProps.forceMasking = true;
        if (actionMode === 'modify' || actionMode === 'repair') {
            form.field('BENE_ACCOUNT').$el.val(maskValue(model.get('BENE_ACCOUNT'), maskingProps));
        } else {
            form.field('BENE_ACCOUNT').$el.html(maskValue(model.get('BENE_ACCOUNT'), maskingProps));
        }
    };

    /**
     * Listen to model changes and update summary section accordingly
     * @param {Model} model
     */
    const updateSummarySectionOnModelChange = function (model) {
        model.on('change:VALUE_DATE change:CREDIT_AMOUNT change:CREDIT_CURRENCY', setupSummarySection);
    };

    const repairModeProtectedFields = [
        'ACCOUNTFILTER',
        'BENE_NAME',
        'BENE_ACCOUNT',
        'BENE_BANK_ID',
        'CUSTOMER_REFERENCE',
        'REMITTANCE_INFO',
        'BENE_ADDRESS',
        'BENE_COUNTRY',
        'BENE_CITY',
        'BENE_PROVINCE',
        'BENE_POSTALCODE',
        'BENE_BIRTH_COUNTRY',
        'BENE_BIRTH_CITY',
        'BENE_BIRTH_DATE',
    ];

    const fromRFPProtectFields = [
        'BENE_NAME',
        'LEGAL_ENTITY_ID',
        'BENE_ACCOUNT',
        'BENE_BANK_ID',
        'CUSTOMER_REFERENCE',
        'BENE_ADDRESS',
        'BENE_COUNTRY',
        'BENE_PROVINCE',
        'BENE_POSTALCODE',
        'BENE_ADDRESS_2',
        'BENE_STATE',
        'BENE_CITY',
        'BENE_BIRTH_COUNTRY',
        'BENE_BIRTH_CITY',
        'BENE_BIRTH_DATE',
        'RFP_BENE_STATE',
        'RFP_BENE_BLDG_NO',
    ];

    if (initialState) {
        const { formView } = form;
        const { model } = formView;
        const actionMode = formView.state;
        const { functionCode } = model.jsonData.typeInfo;
        const $beneProvince = formView.$('input[name="BENE_PROVINCE"]').closest('.field-container');
        const $beneState = formView.$('select[name="BENE_STATE"]').closest('.field-container');
        const $saveAsTemplate = formView.$('input[name="SAVEASTEMPLATE"]').closest('.field-container');
        const $templateCode = formView.$('input[name="TEMPLATE_CODE"]');
        const $creditAmount = formView.$('input[name="CREDIT_AMOUNT"]');
        const allowMaxTmplAmount = model.get('ALLOWMAXAMTPAYMENTFROMTMPL') || (model.fieldData.ALLOWMAXAMTPAYMENTFROMTMPL) ? model.fieldData.ALLOWMAXAMTPAYMENTFROMTMPL.value : 0;
        const entryMethod = model.get('ENTRYMETHOD');
        isFromRFP = model.get('RFP_MSG_ID');
        const $rfpLink = formView.$('button[id="RFPLINK"]').closest('div');
        const amountModificationNotAllowed = model.get('AMOUNT_MODIFICATION_ALLOWED') === '0';
        const $beneNameLookup = formView.$('button[name="BENE_NAME_LOOKUP"]');
        const $beneBankLookup = formView.$('button[name="BENE_BANK_ID_LOOKUP"]');

        // need to have product set in model if not set
        if (!model.get('PRODUCT')) {
            model.set('PRODUCT', 'RTP');
        }

        // hide conversation section in multi-add non-view mode & status is not bank confirmed
        if (actionMode !== 'view' && model.get('STATUS') === 'BC') {
            formView.$('.RTP_INST_RHS').hide();
        }

        if (actionMode === 'repair') {
            repairModeProtectedFields.forEach((field) => {
                form.field(field).shouldBeReadOnly(true);
            });
        }

        // Hide the Save as Template checkbox if the payment was created from a template or imported
        if ((functionCode === 'INST' && model.get('TEMPLATE_CODE')) || (entryMethod === '1' || entryMethod === '3') || isFromRFP) {
            $saveAsTemplate.hide();
        }

        if (actionMode === 'view' && model.get('ERRORS')) {
            form.formView.$('[data-text="ERRORS"]').text(locale.getDefault(model.get('ERRORS'), model.get('ERRORS')));
        }

        /*
         * NH-108554 When in repair status and the VALUE_DATE is past, the
         * TRAN_DATE will not match, so need to hide the tran-date-section
         */
        if (model.get('STATUS') === 'NR' && model.get('VALUE_DATE') !== model.get('TRAN_DATE')) {
            formView.$el.find('[data-section="tran-date-section"]').hide();
        }

        // form state flags to keep validators from being applied/removed unnecessarily
        formView.addressIsRequired = false;
        formView.birthDateIsRequired = false;
        formView.amountsIsRequired = false;
        formView.shouldRequire = {
            addressGroup: false,
            beneBirthGroup: false,
            amountFieldGroup: false,
            remittanceGroup: false,
        };

        /**
         * Set the input mask for the Date Of Birth field. Preserve back slashes.
         * onUnMask ensures that we do not copy the date format into the model as a value.
         */
        if (actionMode !== 'view') {
            formView.$('[name="BENE_BIRTH_DATE"]').inputmask({
                mask: userInfo.getDateFormat().replace(/[^-, /]/g, '9'),
                placeholder: userInfo.getDateFormat(),
                onUnMask: (maskedValue) => {
                    if (maskedValue !== userInfo.getDateFormat()) {
                        return maskedValue;
                    }
                    return '';
                },
            });
        }

        /*
         * TODO When meta data is update to include sections for bene,
         * hide the entire bene section
         */
        if (model.get('NoConfidentialAccess') === 'true') {
            form.field('BENE_BANK_ID').shouldBeHidden();
            form.field('BENE_ACCOUNT').shouldBeHidden();
            form.field('BENE_ADDRESS').$el.closest('.RTP_INST_PAYEE_ADDRESS').parent().hide();
            form.field('BENE_BIRTH_CITY').$el.closest('.RTP_INST_PAYEE_PERSONAL_INFO').parent().hide();
        }

        // On initial state, if bene state is populated, show that field, else show province
        if (isFromRFP) {
            /*
             * If the payment is from an RFP all fields are protected except Debit Account, Date,
             * Amount, and all Remittance information.
             */
            $beneNameLookup.hide();
            $beneBankLookup.hide();
            fromRFPProtectFields.forEach((field) => {
                form.field(field).shouldBeReadOnly(true);
            });

            form.field('BENE_STATE').shouldBeHidden();
            form.field('BENE_PROVINCE').shouldBeHidden();

            handleRFPBeneStateProvinceLabel(formView, model, actionMode);

            /*
             * Here will will hide the request for payment link if the user is not entitled to
             * view request for payments. Additionally we are hiding the link if this screen was
             * navigated to from another detail screen (ie: request for payment) to prevent an
             * end user from infinitely clicking back and forth between the two pages. Note that
             * the link is suppressed below for payments which do not originate from a request.
             */
            if ((dark.exists('RFP') && dark.isDark('RFP')) || model.context.isFromDetail || !model.get('LINKEDREQUESTENTITLED') || !(model.get('LINKEDREQUESTENTITLED') === 'true')) {
                $rfpLink.hide();
            }

            if (actionMode === 'view' || actionMode === 'modify') {
                form.field('REQUESTED_AMT').shouldBeHidden();
            }

            if (amountModificationNotAllowed) {
                form.field('FULL_AMOUNT').shouldBeReadOnly(true);
                form.field('DISCOUNT_AMOUNT').shouldBeReadOnly(true);
                form.field('REQUESTED_AMT').shouldBeReadOnly(true);
            }

            form.field('BENE_ACCOUNT').$el.on('blur change', () => { maskBeneAccount(); });
            formView.listenTo(formView.appBus, formView.model.cid ? `mapData:changed:BENE_ACCOUNT-${formView.model.cid}` : 'mapData:changed:BENE_ACCOUNT', maskBeneAccount);
            maskBeneAccount();
        } else {
            // Not applicable if the payment did not originate from an RFP
            $rfpLink.hide();
            form.field('REQUESTED_AMT').shouldBeHidden();
            form.field('RFP_BENE_STATE').shouldBeHidden();
            if (model.get('BENE_STATE')) {
                $beneProvince.hide();
                isState = true;
            } else {
                $beneState.hide();
                isState = false;
            }

            // Listen for a change to the bene country and toggle showing state/province
            model.on('change:BENE_COUNTRY', () => {
                // This is calling an RTGS specific function because the functionality is identical
                isState = rtgsCommon.toggleStateProvince(
                    model,
                    model.get('BENE_COUNTRY'),
                    $beneProvince,
                    $beneState,
                );
                const fieldName = isState ? 'BENE_STATE' : 'BENE_PROVINCE';
                const fieldName2 = isState ? 'BENE_PROVINCE' : 'BENE_STATE';
                const $field = formView.$(`label[for="${fieldName}"]`);
                form.field(fieldName).shouldBeRequired();
                $field.parent().toggleClass('required', true);
                form.field(fieldName2).shouldBeRequiredWhen(false);
            });
        }

        model.on('change:REMITTANCE_ADDRESS change:REMITTANCE_METHOD', () => {
            toggleRemittanceMandatory();
        });

        model.on('change:ACCOUNTFILTER change:BENE_BANK_ID', () => {
            if (model.get('ACCOUNTFILTER') && model.get('BENE_BANK_ID')) {
                const setEarliestDate = !model.get('VALUE_DATE');
                setValueDate(setEarliestDate);
            }
        });

        // Validate the VALUE_DATE field to verify that the date is still valid
        if ((actionMode === 'modify' || actionMode === 'repair' || (formView.state.toUpperCase() === 'INSERT' && model.context.createdFrom === '1')) && functionCode !== 'TMPL') {
            fxFieldValidation.doFieldValidation(this, model, 'VALUE_DATE', actionMode.toUpperCase()).then(() => {
                model.trigger('widget:update:value-date', model);
                setValueDate(false);
            });
        }

        if (actionMode.toUpperCase() === 'INSERT' && (functionCode === 'TMPL' || model.context.createdFrom === '1')) {
            // If this is not a create payment from template then hide the summary section
            if (!model.get('TEMPLATE_CODE') || model.context.createdFrom !== '1' || model.context.functionCode !== 'INST') {
                $summarySection.hide();
            }
        }

        if (actionMode === 'view' && !model.get('FULL_AMOUNT')) {
            formView.$('#FULL_AMOUNT').hide();
            formView.$('#DISCOUNT_AMOUNT').hide();
        }

        const amtBlock = form.formView.$('[data-validate="CREDIT_AMOUNT"]');
        PaymentUtil.shouldShowTemplateMaxAmt(
            allowMaxTmplAmount,
            formView,
            model,
            amtBlock,
            functionCode,
        );

        installBeneAddressListeners();
        installBeneBirthInfoListeners();

        model.on('change:DISCOUNT_AMOUNT change:FULL_AMOUNT', () => {
            toggleAmountsMandatory();
        });

        /*
         * if showPersonalInfo is false (0), then clear out data
         * when pulling in contact information
         */
        model.on('change:BENE_ACCOUNT', () => {
            if (!showPersonalInfo) {
                model.set({
                    BENE_BIRTH_CITY: '',
                    BENE_BIRTH_COUNTRY: '',
                    BENE_BIRTH_DATE: '',
                });
            }
        });

        // Display fields with correct mandatory status on initial render of page.
        toggleBeneAddressMandatory();
        toggleBeneBirthMandatory();
        toggleRemittanceMandatory();
        toggleAmountsMandatory();

        /*
         * Setup the summary section. Not needed for insert because the multi entry screen
         * does not use the standard MFA layout.
         */
        if ((actionMode !== 'insert' || functionCode === 'TMPL') && configuration.isClient()) {
            setupSummarySection();

            // Set listener to refresh summary section if values change
            updateSummarySectionOnModelChange(model);

            // listener to see that the user doesn't have both legal entity ID and personal info
            model.on('change:LEGAL_ENTITY_ID change:BENE_BIRTH_CITY change:BENE_BIRTH_COUNTRY change:BENE_BIRTH_DATE', () => determineIfLegalEntityIdOrPersonalInfo(formView, showPersonalInfo));
        }

        if (actionMode === 'insert' && model && model.get('FROMTEMPLATE') === '1') {
            setupSummarySection();
            // Set listener to refresh summary section if values change
            updateSummarySectionOnModelChange(model);
        }
        // Protect Template Code field for every action other than the initial insert
        if (functionCode === 'TMPL' && actionMode.toUpperCase() !== 'INSERT') {
            $templateCode.parent().addClass('read-only-text').removeClass('required');
        }

        // make credit amount field mandatory on modify if schedule is selected
        if (actionMode === 'modify' && functionCode === 'TMPL' && model.get('SCHEDULED')) {
            $creditAmount.prop('required', true);
            $creditAmount.closest('.field-container').toggleClass('required', true);
            model.addValidator(
                'CREDIT_AMOUNT',
                {
                    description: model.fieldData.CREDIT_AMOUNT.fieldLabel,
                    exists: true,
                },
            );
        }

        /*
         * Need to show the user a warning if the contact used in this payment has
         * been modified or deleted.
         * This flag is set on contact approval/deletion
         */
        const contactHasBeenUpdatedValue = model.get('CONTACTHASBEENUPDATED');
        const isApplicableActionMode = formView.state.toUpperCase() === 'MODIFY' || (formView.state.toUpperCase() === 'INSERT' && model.context.createdFrom === '1');
        if (isApplicableActionMode && (contactHasBeenUpdatedValue === 'YES' || contactHasBeenUpdatedValue === 'INVALID') && !model.get('beneWarningShown')) {
            model.set('beneWarningShown', true);
            const beneUpdatedWarning = new BeneUpdatedWarning({
                model,
                contactHasBeenUpdatedValue,
                formView,
            });
            dialog.custom(beneUpdatedWarning);
        }

        // make sure restrict flag checkbox is correct
        if (model.get('RESTRICTTEMPLATE_FLAG') === '1') {
            form.field('RESTRICTTEMPLATE_FLAG').setProperty('checked', true);
        } else {
            form.field('RESTRICTTEMPLATE_FLAG').setProperty('checked', false);
        }

        // hide all lookups, if copy as payment from template
        if (entryMethod === '1') {
            $('.lookup').addClass('hidden');
        }

        // protect lookup icon if necessary, for bene_bank_id lookup
        PaymentUtil.hideLookupIfProtected('BENE_BANK_ID_LOOKUP', 'BENE_BANK_IDLOOKUP', model, formView);

        // hide personal info section, if not configured to show
        if (!showPersonalInfo) {
            if (functionCode === 'TMPL') {
                $('[data-container="RTP_TMPL_PAYEE_PERSONAL_INFO"]').closest('.row').hide();
            } else {
                $('[data-container="RTP_INST_PAYEE_PERSONAL_INFO"]').closest('.row').hide();
            }
        }

        if (configuration.isClient()) {
            // hide save as template section if it is not entitled
            getTemplateEntitlement();
        }
    }
}

export {
    toggleLegalEntityError,
    determineIfLegalEntityIdOrPersonalInfo,
    handleRFPBeneStateProvinceLabel,
};
