import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import userInfo from 'etc/userInfo';
import serverConfigParams from 'system/webseries/models/configurationParameters';

import styles from './MaskedInput.styles';
import useMask from '../../hooks/useMask';

const propTypes = {
    /**
     * initial value of the input
     */
    initialValue: PropTypes.string,
    /**
     * updated value of the input from map data or other external source
     */
    updatedValue: PropTypes.string,
    /**
     * name of input, this prop is required
     */
    name: PropTypes.string.isRequired,
    /**
     * space separated list of class names to be assigned to the input's container
     */
    classList: PropTypes.string,
    /**
     * space separated list of class names to be assigned to the input
     */
    inputClassList: PropTypes.string,
    /**
     * space separated list of class names to be assigned to the input when it's read-only
     */
    readOnlyInputClassList: PropTypes.string,
    /**
     * label text for the input field
     */
    fieldLabel: PropTypes.string,
    /**
     * boolean flag to indicate if view binding is required (true) or not (false - default)
     */
    dataBind: PropTypes.bool,
    /**
     * placeholder for the input
     */
    placeholder: PropTypes.string,
    /**
     * flag to indicate if the input is required, defaults to false
     */
    isRequired: PropTypes.bool,
    /**
     * if isReadOnly is true, the component will render a <span> element containing the value
     */
    isReadOnly: PropTypes.bool,
    /**
     * if isProtected is true, an <input> element is rendered and set to readOnly
     */
    isProtected: PropTypes.bool,

    /**
     * flag to indicate to ignore the masking config and always disableMasking
     */
    disableMasking: PropTypes.bool,

    /**
     * this updateCount is only used when the value has changed from an external source,
     * i.e. map date or filter clearing as of now
     * This property is only used as a dependency for the update useEffect because
     * the updated value make be the same, empty string.  This occurs when clearing out the filter
     * (this is no longer used for clear filter but keeping this in for now for future use)
     */
    updatedCount: PropTypes.number,

    /**
     * list of values to be excluded from being masked
     */
    maskExcludeValues: PropTypes.arrayOf(PropTypes.string),

    // Internal classes hash - do not pass
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const defaultProps = {
    initialValue: '',
    updatedValue: undefined,
    classList: '',
    inputClassList: '',
    readOnlyInputClassList: '',
    fieldLabel: '',
    dataBind: false,
    placeholder: '',
    isRequired: false,
    isReadOnly: false,
    isProtected: false,
    disableMasking: false,
    updatedCount: 0,
    maskExcludeValues: [],
};

const MaskedInput = ({
    initialValue,
    updatedValue,
    name,
    classList,
    inputClassList,
    readOnlyInputClassList,
    fieldLabel,
    dataBind,
    placeholder,
    isRequired,
    isReadOnly,
    isProtected,
    disableMasking,
    updatedCount,
    maskExcludeValues,
    classes,
    ...props
}) => {
    const maskConfig = serverConfigParams.get('DisableClientAccountMasking');
    const disableMask = ((maskConfig !== undefined) && (maskConfig.toLowerCase() === 'true' || maskConfig === '1'))
        || userInfo.get('allowAccountUnmasking');
    const maskPattern = serverConfigParams.get('AccountMaskingFormat');
    const [value, setValue] = useState('');
    const [unmaskedValue, setUnmaskedValue] = useState(initialValue);
    // convert to maskExcludeValues to uppercase
    const [maskExcludeList] = useState(maskExcludeValues.map(excld => excld.toUpperCase()));

    const { maskIt } = useMask({ disableMask, maskPattern });

    const maskValue = inVal => ((inVal && maskExcludeList.includes(inVal.toUpperCase()))
        ? inVal : maskIt(inVal));

    const blurHandler = (e) => {
        const inVal = e.currentTarget.value;
        setUnmaskedValue(inVal);
        setValue(disableMasking ? inVal : maskValue(inVal));
    };

    const focusHandler = () => {
        setValue(unmaskedValue);
    };

    const changeHandler = (e) => {
        const newVal = e.currentTarget.value;
        setUnmaskedValue(newVal);
        setValue(newVal);
    };

    useEffect(() => {
        setValue(disableMasking ? unmaskedValue : maskValue(unmaskedValue));
    }, []); // eslint-disable-line

    useEffect(() => {
        // updatedValue gets set from a lookup mapdata
        if (updatedValue !== undefined && updatedValue !== unmaskedValue) {
            setUnmaskedValue(updatedValue);
            setValue(disableMasking ? updatedValue : maskValue(updatedValue));
        }
    }, [updatedValue, updatedCount]);  // eslint-disable-line

    useEffect(() => {
        if (disableMasking) {
            setValue(unmaskedValue);
        } else {
            setValue(maskValue(unmaskedValue));
        }
    }, [disableMasking]); // eslint-disable-line

    const divClass = (isRequired) ? `${classes.required} ${classList}` : classList;
    const dataBindProp = (dataBind) ? { 'data-bind': 'model' } : {};
    const protectedProp = (isProtected) ? { readOnly: true } : {};
    const inputClass = `${classes.maskedInput} ${inputClassList}`;
    const readOnlyInputClass = `${classes.readOnly} ${readOnlyInputClassList}`;
    return (
        <div className={divClass}>
            {fieldLabel
            && <label htmlFor={name}>{fieldLabel}</label>}
            {isReadOnly
            && <span data-qa="readonly-masked-input" className={readOnlyInputClass} name={name}>{value}</span>}
            {!isReadOnly
            && (
                <input
                    type="text"
                    className={inputClass}
                    value={value}
                    onBlur={blurHandler}
                    onFocus={focusHandler}
                    onChange={changeHandler}
                    name={name}
                    id={name}
                    {...dataBindProp}
                    {...protectedProp}
                    {...props}
                    placeholder={placeholder}
                    data-qa="masked-input"
                    autoComplete="off"
                />
            )}
            {dataBind
            && <span className="help-block" data-bind="model" data-validate={name} />}

        </div>
    );
};

MaskedInput.propTypes = propTypes;
MaskedInput.defaultProps = defaultProps;

export default withStyles(styles)(MaskedInput);
