const components = ['lookupGrid'];

const componentSupport = {
    setup() {
        document.addEventListener('dbiqe:getcomponent', ({ detail: { component, cb, options } }) => {
            if (component && components.includes(component)) {
                import(`./${component}`).then(helper => cb(helper.default.getComponent(options)));
            }
        });
    },
};

export default componentSupport;
