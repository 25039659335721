var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"workbench-container\" data-region=\"workbench\">\n    <div class=\"dashboard-layout ui-sortable\">\n        <div class=\"dashboard-inner\">\n            <div class=\"dashboard-region dashboard-region-large\" id=\"dashboard-region-210\">\n                <div class=\"widget\">\n                    <div class=\"widget-container\">\n                        <div class=\"row\">\n                            <div class=\"col-md-12\">\n                                <div class=\"btn-group pull-left widget-links\">\n                                    <button type=\"button\" class=\"btn btn-link payment-management\"><span class=\"icon-add-circle\"></span> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"smbPayments.payment.managment",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":136},"end":{"line":10,"column":178}}}))
    + "</button>\n                                </div>\n                            </div>\n                        </div><!-- end ROW -->\n                        <div class=\"widget-header ui-sortable-handle\">\n                            <h3>Transfer Payment</h3>\n                        </div>\n                        <div class=\"widget-body\">\n                            <div class=\"row\">\n                                <div class=\"col-md-12\" data-region=\"contentRegion\">\n\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});