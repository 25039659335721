var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"nav-tab active\" id=\"paymentTab\">\n                            <a class=\"nav-tab-link\" href=\"#\" role=\"tab\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"payments.smb_title",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":72},"end":{"line":14,"column":103}}}))
    + "</a>\n                        </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"nav-tab\" id=\"templateTab\">\n                            <a class=\"nav-tab-link\" href=\"#\" role=\"tab\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"PAY.TMPL.SELECT.*.ListViewTitle",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":72},"end":{"line":19,"column":116}}}))
    + "</a>\n                        </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":42}}}) : helper)))
    + "</h1>\n</div>\n<div class=\"page\">\n    <div class=\"section-body\">\n        <div class=\"section-container\" role=\"navigation\">\n            <div class=\"alert-message\">\n                <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n            </div>\n            <div class=\"PaymentTabs\">\n                <ul class=\"nav nav-tabs\" data-hook=\"getNavTabs\" role=\"tablist\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPaymentTab") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":16,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showTemplateTab") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":21,"column":27}}})) != null ? stack1 : "")
    + "                </ul>\n                <div class=\"tabPanels row\">\n                    <div data-region=\"gridRegion\"></div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});