var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"form-group\">\n                        <label for=\"MEMO\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"rdc.Memo",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":42},"end":{"line":16,"column":63}}}))
    + "</label>\n                        <input class=\"form-control\" data-bind=\"model\" name=\"MEMO\">\n                    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"glu-container\">\n    <div class=\"row\">\n        <div class=\"page-header-wrapper text-center\">\n            <h1 class=\"RDC-page-header landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rdc.Deposit.Check",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":55},"end":{"line":4,"column":85}}}))
    + "</h1>\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"col-12\">\n            <fieldset>\n                <div data-region=\"locationAccountRegion\"></div>\n\n                <div data-region=\"amountInputRegion\"></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showMemo") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":19,"column":23}}})) != null ? stack1 : "")
    + "            </fieldset>\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"col-12 text-center\">\n            <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rdc.Photograph.your.Check",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":15},"end":{"line":25,"column":53}}}))
    + "</p>\n        </div>\n        <div data-region=\"frontImageRegion\" class=\"col-6 Misnap-region text-center\"></div>\n        <div data-region=\"backImageRegion\" class=\"col-6 Misnap-region text-center\"></div>\n    </div>\n    <div class=\"row\">\n        <div class=\"col-12 center\">\n            <button class=\"btn btn-tertiary hide\" data-hook=\"getRetakeImagesButton\">\n                <img class=\"RDC-camera-svg\" src=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"imgBase") || (depth0 != null ? lookupProperty(depth0,"imgBase") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"imgBase","hash":{},"data":data,"loc":{"start":{"line":33,"column":49},"end":{"line":33,"column":60}}}) : helper)))
    + "/nh/camera.svg\">\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rdc.Retake.Photos",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":34,"column":16},"end":{"line":34,"column":46}}}))
    + "\n            </button>\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"col-12\">\n            <div data-region=\"analyzedAmountRegion\" class=\"RDC-analyzed-amount\"></div>\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"col-12 text-center\">\n            <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rdc.submitCheckDepositCustomTitle",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":45,"column":15},"end":{"line":45,"column":61}}}))
    + "</p>\n            <div>\n                <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rdc.submitCheckDepositCustomLabel1",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":47,"column":19},"end":{"line":47,"column":66}}}))
    + "</p>\n            </div>\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"col-12\">\n            <button class=\"btn btn-primary RDC-btn--primary\"\n                    type=\"button\"\n                    data-hook=\"getDepositButton\"\n                    disabled>\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rdc.Deposit.Check",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":57,"column":16},"end":{"line":57,"column":46}}}))
    + "\n            </button>\n        </div>\n    </div>\n</div>\n\n";
},"useData":true});