var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"StepsNamed-item is-active\"\n                        data-action=\"goStep\"\n                        aria-current=\"step\"\n                        data-step=\"0\">\n                        <a aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"step1Label") || (depth0 != null ? lookupProperty(depth0,"step1Label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"step1Label","hash":{},"data":data,"loc":{"start":{"line":16,"column":39},"end":{"line":16,"column":53}}}) : helper)))
    + " 1\" class=\"StepsNamed-link\"\n                           href=\"#\">1\n                        </a>\n                        <span class=\"StepsNamed-text\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"step1Label") || (depth0 != null ? lookupProperty(depth0,"step1Label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"step1Label","hash":{},"data":data,"loc":{"start":{"line":19,"column":54},"end":{"line":19,"column":68}}}) : helper)))
    + "</span>\n                    </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-primary submit\" data-hook=\"saveBtn\" data-action=\"save\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"addButtonLabel") || (depth0 != null ? lookupProperty(depth0,"addButtonLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"addButtonLabel","hash":{},"data":data,"loc":{"start":{"line":55,"column":108},"end":{"line":55,"column":126}}}) : helper)))
    + "</button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":56,"column":12},"end":{"line":64,"column":12}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isDeepLinked") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":16},"end":{"line":61,"column":27}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"buttons") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":20},"end":{"line":60,"column":29}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <button type=\"button\" class=\"btn "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":59,"column":57},"end":{"line":59,"column":110}}})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"action") || (depth0 != null ? lookupProperty(depth0,"action") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"action","hash":{},"data":data,"loc":{"start":{"line":59,"column":111},"end":{"line":59,"column":121}}}) : helper)))
    + "\" data-action=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"action") || (depth0 != null ? lookupProperty(depth0,"action") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"action","hash":{},"data":data,"loc":{"start":{"line":59,"column":136},"end":{"line":59,"column":146}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":59,"column":148},"end":{"line":59,"column":157}}}) : helper)))
    + "</button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "btn-primary";
},"11":function(container,depth0,helpers,partials,data) {
    return "btn-secondary";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"modify") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":12},"end":{"line":64,"column":12}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-primary submit\" data-hook=\"saveBtn\" data-action=\"save\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.modifyUser",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":63,"column":108},"end":{"line":63,"column":135}}}))
    + "</button>\n            ";
},"16":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"cancel\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.cancel.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":68,"column":84},"end":{"line":68,"column":122}}}))
    + "</button>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasSimulateEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":16},"end":{"line":73,"column":23}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-primary simulate\" data-action=\"simulate\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.simulateUser",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":72,"column":98},"end":{"line":72,"column":127}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"full-page\">\n    <div class=\"section section-container\">\n\n        <h1 data-hook=\"displayInfo\"></h1>\n\n        <div data-region=\"pendingChangesRegion\"></div>\n\n        <div class=\"form-step-container\">\n            <ol class=\"StepsNamed steps steps-named\"\n                data-hook=\"getStepsNamed\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isTabOneRequired") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":21,"column":23}}})) != null ? stack1 : "")
    + "                <li class=\"StepsNamed-item\"\n                    data-action=\"goStep\"\n                    data-step=\"1\">\n                    <a aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.set-permissions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":35},"end":{"line":25,"column":67}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"permissionStep") || (depth0 != null ? lookupProperty(depth0,"permissionStep") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"permissionStep","hash":{},"data":data,"loc":{"start":{"line":25,"column":68},"end":{"line":25,"column":86}}}) : helper)))
    + "\" class=\"StepsNamed-link\"\n                       href=\"#\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"permissionStep") || (depth0 != null ? lookupProperty(depth0,"permissionStep") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"permissionStep","hash":{},"data":data,"loc":{"start":{"line":26,"column":32},"end":{"line":26,"column":50}}}) : helper)))
    + "\n                    </a>\n                    <span class=\"StepsNamed-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.set-permissions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":50},"end":{"line":28,"column":82}}}))
    + "</span>\n                </li>\n                <li class=\"StepsNamed-item\"\n                    data-action=\"goStep\"\n                    data-step=\"2\">\n                    <a aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.assign-accounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":35},"end":{"line":33,"column":67}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"accountStep") || (depth0 != null ? lookupProperty(depth0,"accountStep") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"accountStep","hash":{},"data":data,"loc":{"start":{"line":33,"column":68},"end":{"line":33,"column":83}}}) : helper)))
    + "\" class=\"StepsNamed-link\"\n                       href=\"#\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"accountStep") || (depth0 != null ? lookupProperty(depth0,"accountStep") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"accountStep","hash":{},"data":data,"loc":{"start":{"line":34,"column":32},"end":{"line":34,"column":47}}}) : helper)))
    + "\n                    </a>\n                    <span class=\"StepsNamed-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.assign-accounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":50},"end":{"line":36,"column":82}}}))
    + "</span>\n                </li>\n                <li class=\"StepsNamed-item\"\n                    data-action=\"goStep\"\n                    data-step=\"3\">\n                    <a aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.apply-limits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":41,"column":35},"end":{"line":41,"column":64}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"limitStep") || (depth0 != null ? lookupProperty(depth0,"limitStep") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"limitStep","hash":{},"data":data,"loc":{"start":{"line":41,"column":65},"end":{"line":41,"column":78}}}) : helper)))
    + "\"class=\"StepsNamed-link\"\n                       href=\"#\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"limitStep") || (depth0 != null ? lookupProperty(depth0,"limitStep") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"limitStep","hash":{},"data":data,"loc":{"start":{"line":42,"column":32},"end":{"line":42,"column":45}}}) : helper)))
    + "\n                    </a>\n                    <span class=\"StepsNamed-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.apply-limits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":44,"column":50},"end":{"line":44,"column":79}}}))
    + "</span>\n                </li>\n            </ol>\n        </div>\n\n        <div data-hook=\"alertRegion\"></div>\n\n        <div data-hook=\"stepRegion\"></div>\n\n        <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"insert") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":54,"column":12},"end":{"line":64,"column":19}}})) != null ? stack1 : "")
    + "            <button type=\"button\" class=\"btn btn-secondary\" data-action=\"next\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.continue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":65,"column":79},"end":{"line":65,"column":104}}}))
    + "</button>\n            <button type=\"button\" class=\"btn btn-secondary\" data-action=\"prev\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.back",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":66,"column":79},"end":{"line":66,"column":100}}}))
    + "</button>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDeepLinked") : depth0),{"name":"unless","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":12},"end":{"line":69,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDeepLinked") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":12},"end":{"line":74,"column":19}}})) != null ? stack1 : "")
    + "         </div>\n    </div>\n</div>\n</div>\n";
},"useData":true});