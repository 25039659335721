import BaseGrid from 'ptx/system/baseGrid';
import locale from '@glu/locale';
import VirtualTrays from 'pcm/app/ptx-connect/collections/virtualTrays';
import VirtualTraySelection from './components/virtualTraySelection';
import InputCell from './components/input';

function VirtualTraysGrid(options) {
    this.virtualTrays = new VirtualTrays();
    this.model = options?.model;

    this.virtualTrays.fetch();

    this.grid = new BaseGrid({
        name: 'trays',
        skipDataLoading: true,
        disableDynamicColumnWidths: true,
        emptyViewText: locale.get('thereAreNoTrays'),
        model: this.model,
        collection: this.model.printerTrays,
        columns: [{
            className: 'printer-tray-name',
            field: 'printerTrayName',
            title: locale.get('trayName'),
            disableRemove: true,
        },
        {
            cellView: VirtualTraySelection,
            cellViewOptions: {
                collection: this.virtualTrays,
                printerTrays: this.model.printerTrays,
            },
            field: 'virtualTrayId',
            title: locale.get('virtualTrayId'),
            disableRemove: true,
        },
        {
            cellView: InputCell,
            cellViewOptions: {
                maxLength: 2,
            },
            field: 'postscriptId',
            title: locale.get('postscriptId'),
            width: 115,
        },
        {
            cellView: InputCell,
            cellViewOptions: {
                maxLength: 2,
            },
            field: 'pcl5Id',
            title: locale.get('pcl5Id'),
            width: 90,
        },
        {
            cellView: InputCell,
            cellViewOptions: {
                isNumeric: false,
            },
            field: 'description',
            title: locale.get('description'),
        },
        ],
    });

    return this.grid;
}

export default VirtualTraysGrid;
