import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import store from 'system/utilities/cache';
import ContextApi from 'common/dynamicPages/api/context';
import pageApi from 'common/dynamicPages/api/view';
import DataApi from 'common/dynamicPages/api/data';
import locale from 'system/webseries/api/locale';
import dialog from '@glu/dialog';
import AdminUtil from 'app/administration/common/util';
import gridApi from 'common/dynamicPages/api/grid';
import viewTmpl from './view.hbs';

export default Layout.extend({
    template: viewTmpl,

    regions: {
        pageRegion: '.pageRegion',
    },

    ui: {
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
    },

    initialize(options) {
        this.model = options.model || new Model();
        this.contextDef = ContextApi.menuContext.getContext('SEGMNT_MAINT');
        this.segmentName = store.get('segmentName');

        // get the model
        this.context = {
            functionCode: 'MAINT',
            productCode: 'SEGMENT',

            // '/segmentation/getModelInfo'
            serviceName: '/segmentation/',
        };
    },

    loadViewRequirements() {
        const self = this;

        const keyList = [
            'NAME',
        ];

        const quickEntryItemModel = DataApi.model.generateFromKeyList({
            context: this.context,
            keyList,
        });

        quickEntryItemModel.set({
            NAME: self.segmentName,
        });

        DataApi.model.generateModelFromModel(quickEntryItemModel)
            .then((metaModelFromPartialModel) => {
                metaModelFromPartialModel.fetch({
                    success() {
                        self.modelToEdit = metaModelFromPartialModel;
                        self.setHasLoadedRequiredData(true);
                        self.render();
                    },
                });
            });
    },

    onRender() {
        const self = this;
        if (self.hasLoadedRequiredData()) {
            const options = {
                context: this.contextDef,
                hideButtons: true,
                gridApi,
                state: 'view',
                model: this.modelToEdit,
                viewType: 'default',
            };

            this.ui.$navLinks.on('click', (e) => {
                e.preventDefault();
                const path = this.$(e.target).attr('href');
                this.navigateTo(path);
            });

            self.pageView = pageApi.page.get(options);
            self.pageRegion.show(self.pageView);
        } else {
            self.loadViewRequirements();
        }
    },

    templateHelpers() {
        return {
            hasModifyEntitlement() {
                return store.get('admin:segmentation:modify:entitlement');
            },

            hasApproveEntitlement() {
                return store.get('admin:segmentation:approve:entitlement');
            },

            hasDeleteEntitlement() {
                return store.get('admin:segmentation:delete:entitlement');
            },
        };
    },

    edit() {
        store.set('segmentModify', true);
        this.navigateTo('/SEGMENTATION/addProfile');
    },

    approve() {
        const self = this;

        this.pageView.model.set('id', this.segmentName);
        this.pageView.model.approve({
            success() {
                store.set('admin:segmentation:approve', `${store.get('segmentName')} ${locale.get('segment.approve.success')}`);
                self.back();
            },

            error(response) {
                AdminUtil.errorAlert(self.alertRegion, response);
            },
        });
    },

    delete() {
        const self = this;

        dialog.confirm(
            locale.get('segment.confirm.delete.warning'), locale.get('segment.confirm.delete.heading'),
            (ok) => {
                if (ok) {
                    self.pageView.model.set('id', self.segmentName);
                    self.pageView.model.delete({
                        success() {
                            store.set('admin:segmentation:delete', `${store.get('segmentName')} ${locale.get('segment.delete.success')}`);
                            self.back();
                        },

                        error(response) {
                            AdminUtil.errorAlert(self.alertRegion, response);
                        },
                    });
                }
            },
        );
    },

    back() {
        store.unset(
            'segmentName',
            {
                silent: true,
            },
        );
        store.unset(
            'segmentModify',
            {
                silent: true,
            },
        );
        this.navigateTo('/SEGMENTATION/viewSegments');
    },
});
