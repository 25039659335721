import ListView from 'common/dynamicPages/views/workflow/list';
import CardCollection from 'app/administration/collection/cards';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import store from 'system/utilities/cache';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import workspaceHelper from 'common/workspaces/api/helper';
import CardCollectionView from 'app/administration/common/card/list';
import template from 'app/administration/views/fxReporting/fx.hbs';

const FxReportingList = ListView.extend({
    template,

    ui: {
        $fxRegions: 'div[data-region="processedRegion"], div[data-region="unprocessedRegion"]',
        $toggle: '#fx-toggle',
        $processedTotal: '.processedTotal',
        $unprocessedTotal: '.unprocessedTotal',
    },

    initialize() {
        this.options = {
            menuCategory: 'REPORTING',
            serviceName: null,
            serviceFunc: null,
            businessType: null,
            enableSavedViews: true,
            enableDateTimeSupport: true,
            context: 'FX_TRANS_LIST_VIEW',
            returnRoute: 'REPORTING/viewFXTransactions',
            selector: 'none',
        };

        ListView.prototype.initialize.call(this, util.extend({}, this.options));
    },

    /**
     * This function sets the values in the fx transaction cards that display
     * above the list view grid.  It calculates the totals to display.
     */
    prepareCardCollection() {
        const data = {
            rowsPerPage: 0,
            startRow: 0,
            searchFields: this.gridView.wrapper.generateFiltersDataBlock(),
            viewId: this.gridView.wrapper.viewId,
        };

        this.cardCollection = new CardCollection(data);

        this.cardCollection.fetch({
            success: () => {
                const processedCollection = this.cardCollection.processedData();
                const unProcessedCollection = this.cardCollection.unprocessedData();
                let processedDataCount = 0;
                let unprocessedDataCount = 0;
                let processedView = null;
                let unprocessedView = null;

                processedView = new CardCollectionView({
                    collection: this.cardCollection.processedData(),
                });
                unprocessedView = new CardCollectionView({
                    collection: this.cardCollection.unprocessedData(),
                });

                processedDataCount = util.reduce(processedCollection.models, (accumulator, model) => accumulator + (parseInt(model.get('COUNT'), 10) || 0), 0);
                unprocessedDataCount = util.reduce(unProcessedCollection.models, (accumulator, model) => accumulator + (parseInt(model.get('COUNT'), 10) || 0), 0);
                this.ui.$processedTotal.text(processedDataCount);
                this.ui.$unprocessedTotal.text(unprocessedDataCount);
                this.processedRegion.show(processedView);
                this.unprocessedRegion.show(unprocessedView);
            },
        });
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.$('[data-toggle="popover"]').popover();

            this.listenTo(this.gridView.wrapper.rows, 'sync', this.prepareCardCollection);
            this.gridRegion.show(this.gridView);
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
            this.renderMessage(store.get(`${this.contextKey}-alertMessage`), store.get(`${this.contextKey}-confirms`));
            store.set(`${this.contextKey}-alertMessage`, null);
            store.set(`${this.contextKey}-confirms`, null);
        } else {
            this.loadViewRequirements();
        }
    },

    isLoan(model) {
        const type = model.get('TYPE');
        return type === 'LOANPAY' || type === 'LOANDRAW';
    },

    isPassThru(model) {
        const type = model.get('TYPE');
        return type === 'PASSTHRU';
    },

    refreshData() {
        ListView.prototype.refreshData.call(this); // call listView refreshData
        this.updateRefreshTimestamp();
    },

    showHideTotals() {
        if (this.ui.$toggle.text() === locale.get('fxReports.hide.totals')) {
            this.ui.$toggle.text(locale.get('fxReports.show.totals'));
        } else if (this.ui.$toggle.text() === locale.get('fxReports.show.totals')) {
            this.ui.$toggle.text(locale.get('fxReports.hide.totals'));
        }
        this.ui.$fxRegions.toggle();
    },

    gridRowSelect(options) {
        if (this.isLoan(options.model)) {
            store.set('loanModel', options.model);
            this.navigateTo('REPORTING/viewLoanPaymentDetails');
        } else if (this.isPassThru(options.model)) {
            store.set('passthru_view_payment_model', options.model);
            store.set('SURROGATETNUM', options.model.get('TNUM'));
            this.navigateTo('REPORTING/passThroughDetails');
        } else {
            this.contextKey = 'adminPayment_listView_payments';
            store.set(`${this.contextKey}-listRoute`, this.options.returnRoute);
            store.set(`${this.contextKey}-actionModel`, options.model);
            this.navigateTo('REPORTING/viewPaymentsDetails');
        }
        return Promise.resolve();
    },

    buildExportModel(format) {
        ListView.prototype.buildExportModel.call(this, format);
        this.exportModel.inquiryId = '20009';
    },

    export() {
        this.buildExportModel('CSV');
        this.doExport();
    },

    print() {
        this.buildExportModel('PDF');

        const printModal = new PrintViewModal({
            exportModel: this.exportModel,
            title: 'fxReports.section.header',
            exportURL: '/fxexport/listView',
        });
        dialog.custom(printModal);
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'FX_TRANS_LIST_VIEW',
    view: FxReportingList,
    options: {},
});

export default FxReportingList;
