import Layout from '@glu/core/src/layout';
import Collection from '@glu/core/src/collection';
import CollectionView from '@glu/core/src/collectionView';
import ViewHelper from 'common/dynamicPages/api/viewHelper';
import ContextApi from 'common/dynamicPages/api/context';
import validatorPatterns from 'system/validatorPatterns';
import util from '@glu/core/src/util';
import scroll from 'common/util/scroll';
import locale from '@glu/locale';
import alertMessage from 'common/api/alertMessage';
import store from 'system/utilities/cache';
import helpPageUtil from 'common/util/helpPage';
import workspaceHelper from 'common/workspaces/api/helper';
import Formatter from 'system/utilities/format';
import appConfigParams from 'system/webseries/models/applicationConfiguration';
import configParams from 'system/webseries/models/configurationParameters';
import constants from 'app/checkManagement/constants';

import IssueVoidSetModel from '../models/issueVoidSet';
import IssueVoidModel from '../models/issueVoid';
import ViewIssue from './viewIssue';
import ViewVoid from './viewVoid';
import multipleIssueVoidEntryTmpl from './multipleIssueVoidEntry.hbs';

const isDeeplink = () => window.parent !== window;

export default Layout.extend({
    template: multipleIssueVoidEntryTmpl,
    className: 'transfer-layout',

    ui: {
        addIssueAmtInput: '[name="ADDISSUEAMOUNT"]',
        addVoidAmtInput: '[name="ADDVOIDAMOUNT"]',
        currencyDisplay: '[data-hook="currencyDisplay"]',
        totalIssuesDisplay: '[data-hook="totalIssuesDisplay"]',
        totalVoidsDisplay: '[data-hook="totalVoidsDisplay"]',
        submitButton: '[data-hook="submit-button"]',
        headline: '.landing-header',
        $optFieldToggle: '[data-hook="getOptFieldSwitch"]',
        $addInfoIssue: '[data-hook="getAddInfoIssue"]',
        $addInfoVoid: '[data-hook="getAddInfoVoid"]',
        $getAddIssueAmount: '[data-hook="getAddIssueAmount"]',
        $getAddVoidAmount: '[data-hook="getAddVoidAmount"]',
    },

    events: {
        'click [data-hook="getAddItems"]': 'addItems',
        'click [data-hook="submit-button"]': 'saveItems',
        'click [data-hook="cancel-button"]': 'cancel',
        'click @ui.$optFieldToggle': 'toggleOptionalFields',
        'focus [data-hook="getAddIssueAmount"]': 'focusAddItem',
        'focus [data-hook="getAddVoidAmount"]': 'focusAddItem',
    },

    regions: {
        issueRegion: '.issue-region',
        voidRegion: '.void-region',
        recurRegion: '.recur-region',
        auditRegion: '[data-hook="auditRegion"]',
    },

    initialize(opts) {
        this.returnRoute = 'RISK/paymentFraudControl';
        this.type = opts.type;
        this.model = new IssueVoidSetModel();
        this.model.set('functionCode', 'INST');
    },

    onRender() {
        const issueOptions = {
            ISSUEVOIDFLAG: 'ISSUE',
        };

        const voidOptions = {
            ISSUEVOIDFLAG: 'VOID',
        };

        this.ui.headline.text(this.options.pageTitle);

        if (this.hasLoadedRequiredData()) {
            const newIssueModel = new IssueVoidModel({}, issueOptions);
            this.collectionIssues = new Collection([newIssueModel]);
            this.issueView = new CollectionView({
                collection: this.collectionIssues,
                itemView: ViewIssue,

                itemViewOptions: {
                    parentLayout: this,
                },
            });
            this.issuesFieldInfo = this.extractVisibleMandatoryFields(
                this.issuesMetaModel,
                newIssueModel,
            );
            this.updateIssuesVoidModel(
                newIssueModel,
                this.issuesMetaModel,
                this.issuesFieldInfo,
            );
            this.listenTo(
                this.collectionIssues,
                'change add remove',
                util.bind(this.handleChange, this),
            );
            const newVoidsModel = new IssueVoidModel({}, voidOptions);
            this.collectionVoids = new Collection([newVoidsModel]);
            this.voidView = new CollectionView({
                collection: this.collectionVoids,
                itemView: ViewVoid,

                itemViewOptions: {
                    parentLayout: this,
                },
            });

            this.voidsFieldInfo = this.extractVisibleMandatoryFields(
                this.voidsMetaModel,
                newVoidsModel,
            );
            this.updateIssuesVoidModel(
                newVoidsModel,
                this.voidsMetaModel,
                this.voidsFieldInfo,
            );
            this.listenTo(
                this.collectionVoids,
                'change add remove',
                util.bind(this.handleChange, this),
            );

            this.issueRegion.show(this.issueView);
            this.voidRegion.show(this.voidView);

            const formatOptions = util.extend(
                Formatter.getCurrencyMaskOptions(true, true),
                {
                    digits: 0,
                    placeholder: '',
                    allowMinus: false,
                    allowPlus: false,
                },
            );

            this.ui.addIssueAmtInput.inputmask(
                'number',
                formatOptions,
            );
            this.ui.addVoidAmtInput.inputmask(
                'number',
                formatOptions,
            );
            /**
             * This code will validate if "Show Optional Fields" should be checked
             * based of the app config data
             */
            if (appConfigParams.getValue('CMCIM', 'SHOWOPTIONALFIELDS') !== '0') {
                this.ui.$optFieldToggle.click();
            }

            this.updateFooter();
        } else {
            this.loadRequiredData();
        }
    },

    /**
     * @method loadRequiredData
     * - This method is for get the model info.
     */

    loadRequiredData() {
        const contextDefIssues = ContextApi.menuContext.getContext('CM_IV_LIST');
        const contextDefVoids = ContextApi.menuContext.getContext('CM_IV_LIST');

        contextDefIssues.subType = 'ISSUE';

        const issuePromise = ViewHelper.getModel({
            context: contextDefIssues,
            hideButtons: true,
        });

        contextDefVoids.subType = 'VOID';
        const voidPromise = ViewHelper.getModel({
            context: contextDefVoids,
            hideButtons: true,
        });

        const helpPagePromise = helpPageUtil.getHelpPagePromise({
            productCode: 'CM',
            functionCode: 'INST',
            typeCode: 'CIMINSTT',
            mode: 'INSERT',
        });

        Promise.all([
            issuePromise,
            voidPromise,
            helpPagePromise,
        ]).then(([issues, voids, helpPage]) => {
            this.issuesMetaModel = issues;
            this.updateSerialNumberValidator(this.issuesMetaModel);
            this.voidsMetaModel = voids;
            this.updateSerialNumberValidator(this.voidsMetaModel);
            store.set('helpPage', helpPage.helpPage);
            this.setHasLoadedRequiredData(true);
            this.render();
        });
    },
    /**
     * @name focusAddItem
     * @description handler for when the user focuses on add item input
     */
    focusAddItem(e) {
        const itemLimit = configParams.get('ROWLIMIT') || constants.DEFAULT_ITEM_PER_ROW;
        let checkType;
        if (e?.collectionType) {
            /**
             * When user removes or adds any row from the collection
             */
            checkType = e.collectionType;
        } else {
            /**
             * When the user focuses on the input box to make an entry
             */
            checkType = e?.currentTarget?.dataset?.hook === 'getAddIssueAmount' ? 'issue' : 'void';
        }
        const $elToUse = (checkType === 'issue') ? this.ui.$addInfoIssue : this.ui.$addInfoVoid;
        const checkCollection = (checkType === 'issue') ? this.collectionIssues : this.collectionVoids;

        if (itemLimit > 0) {
            this.updateAddInfoClasses(false, checkType);
            this.setElText($elToUse, `risk.${checkType}_addItem.info`, itemLimit, checkCollection?.length);
        }
    },
    /**
     * @name updateAddInfoClasses
     * @description updates the classes/styles for the add item info to reflect
     * either the error state (state = true)
     * or the non-error state (state = false)
     * @param {boolean} state
     * @param {string} checktype - issue or void
     */
    updateAddInfoClasses(state, checkType) {
        const $elToUse = (checkType === 'issue') ? this.ui.$addInfoIssue : this.ui.$addInfoVoid;
        this.setElTextStyling($elToUse, state);
    },

    updateSerialNumberValidator(metaModelParam) {
        const metaModel = metaModelParam;
        if (metaModel.validators.SERIALNUMBER && !metaModel.validators.SERIALNUMBER.exists) {
            metaModel.validators.SERIALNUMBER = {
                matches: validatorPatterns.NUMERIC_PATTERN,
                description: locale.get('CM.SerialNumber'),
            };
        }
    },

    /**
     * @method extractVisibleMandatoryFields
     * @param {Backbone.Model} metaModel - containing the model from DB.
     * @param {Backbone.Model} currentModel - containing the model which we use in UI.
     * - This method filters the mandatory and visible fields from model info.
     */
    extractVisibleMandatoryFields(metaModel, currentModel) {
        const issueFieldData = metaModel.fieldData;

        return util.chain(issueFieldData)
            .filter(field => // return true;
                util.has(currentModel.attributes, field.name))
            .map(field => ({
                name: field.name,
                mandatory: field.mandatory,
                visible: field.visible,
                editableOnCreate: field.editableOnCreate,
                editableOnModify: field.editableOnModify,
                maxLen: field.maxLen,
            }))
            .value();
    },

    /**
     * @method setElTextStyling
     * @param {Backbone.Model} $el - Dom element where user clicked on.
     * @param {Backbone.Model} state - flag to enable or disable the class.
     * - This method either makes the input box and error message red or
     * make it to original color.
     */
    setElTextStyling($el, state) {
        $el.toggleClass('help-block', state);
        // Marks the input field and the label as Red/orginal color depending on the state
        $el.closest('.row').toggleClass('has-error', state);
    },

    /**
     * @method setElText
     * @param {Backbone.Model} $el - Dom element where user focused or clicked on.
     * @param {Backbone.Model} localeKey
     * @param {Backbone.Model} limit - Max no. of rows that can be added.
     * @param {Backbone.Model} length - Current length of the collection.
     * - This method shows the information or error messages.
     */
    setElText($el, localeKey, limit, length) {
        $el.text(locale.get(localeKey, limit, length, limit - length));
    },

    /**
     * Adds Check items to the screen.
     * @param {Event} e
     */
    addItems(e) {
        /*
         * Get correct check type and collection based on the type of check item
         * chosen to be added
         */
        const checkType = this.$(e.currentTarget).data('checktype');
        const $elToUse = (checkType === 'issue') ? this.ui.$addInfoIssue : this.ui.$addInfoVoid;

        const checkCollection = (checkType === 'issue') ? this.collectionIssues : this.collectionVoids;
        const amountField = (checkType === 'issue') ? this.ui.addIssueAmtInput : this.ui.addVoidAmtInput;
        const itemLimit = configParams.get('ROWLIMIT') || constants.DEFAULT_ITEM_PER_ROW;

        // added Amount of items cannot exceed the item limit
        const addAmount = parseInt((amountField.val() || 1), 10);
        /**
         * If the addInput is greater than the limit, user should be
         * displayed anerror message else continue to add items to the collection
         */
        if ((addAmount + checkCollection.length) > itemLimit) {
            this.updateAddInfoClasses(true, checkType);
            this.setElText($elToUse, `risk.${checkType}_addItem.error`, itemLimit);
        } else {
            // create array of new items to be added
            const newItems = this.createNewCheckItems(checkCollection.last(), addAmount, checkType);
            checkCollection.add(newItems);

            // must retrigger optional fields toggle for new item views
            if (this.ui.$optFieldToggle.filter(`[data-checktype='${checkType}']`).is(':checked')) {
                checkCollection.trigger('toggle-optional-fields', true);
            }
        }
    },

    /**
     * @param {Model} defaultModel check item collection (either issue or void)
     * @param {number} amount - number of items to be added
     * @param {string} type - issue or void
     * @return {array} array of new check items to be added
     */
    createNewCheckItems(defaultModel, amount, type) {
        const defaultAttr = defaultModel.attributes;
        // Don't copy MEMO and PAYEE attrs into the new model
        const { MEMO, PAYEE, ...attrs } = defaultAttr;
        const newItems = new Array(amount).fill();

        return newItems.map((item, i) => {
            const attributes = {

                ...attrs,
                SERIALNUMBER: (type === 'issue' && attrs.SERIALNUMBER) ? (parseInt(attrs.SERIALNUMBER, 10) + (i + 1)) : null,
                AMOUNT: '',
            };

            return new IssueVoidModel(
                attributes,
                {
                    ISSUEVOIDFLAG: type.toUpperCase(),
                    FROMACCOUNT: defaultModel.fromAccount,
                    FIELDINFO: defaultModel.fieldInfo,
                },
            );
        });
    },

    /**
     * Updates mandatory, visible fields and validator info from metaModel to UI display model.
     * @param {Backbone.Model} issuesVoidModel - The model from DB
     * @param {Backbone.Model} metaModel
     * @param {Object} fieldInfo
     */
    updateIssuesVoidModel(issuesVoidModel, metaModel, fieldInfo) {
        const issuesVoidModelParam = issuesVoidModel;
        issuesVoidModelParam.fieldInfo = fieldInfo;
        issuesVoidModelParam.validators = metaModel.validators;
    },

    handleChange() {
        let newIssueModel;
        let newVoidsModel;
        if (this.collectionIssues.length < 1) {
            newIssueModel = new IssueVoidModel(
                {},
                {
                    ISSUEVOIDFLAG: 'ISSUE',
                    FIELDINFO: this.issuesFieldInfo,
                },
            );
            this.collectionIssues.add(newIssueModel);
            this.toggleOptionalFieldsByType('issue');
        } else {
            /**
             * When there is a change in the length of collectionIssue
             * update the info Text
             */
            this.collectionType = 'issue';
            this.focusAddItem(this);
        }
        if (this.collectionVoids.length < 1) {
            newVoidsModel = new IssueVoidModel(
                {},
                {
                    ISSUEVOIDFLAG: 'VOID',
                    FIELDINFO: this.voidsFieldInfo,
                },
            );
            this.collectionVoids.add(newVoidsModel);
            this.toggleOptionalFieldsByType('void');
        } else {
            /**
             * When there is a change in the length of collectionVoid
             * update the info Text
             */
            this.collectionType = 'void';
            this.focusAddItem(this);
        }
        this.updateFooter();
        this.toggleClearRemove();
    },

    updateFooter() {
        if (this.singleClearedModel(this.collectionIssues)) {
            this.ui.totalIssuesDisplay.text(0);
        } else {
            this.ui.totalIssuesDisplay.text(this.collectionIssues.length);
        }

        if (this.singleClearedModel(this.collectionVoids)) {
            this.ui.totalVoidsDisplay.text(0);
        } else {
            this.ui.totalVoidsDisplay.text(this.collectionVoids.length);
        }
    },

    getDuplicateIssues(collection) {
        const dupModels = [];

        collection.each((model) => {
            model.unset(
                'ERROR',
                {
                    silent: true,
                },
            );
            model.unset(
                'error',
                {
                    silent: true,
                },
            );
        });
        const collect = collection.filter((model1) => {
            const match = collection.find((model2) => {
                const attrMatch = util.isEqual(model2.attributes, model1.attributes);
                const cidMatch = model1.cid === model2.cid;
                if (attrMatch && !cidMatch) {
                    if (!dupModels.includes(model1.cid)) {
                        dupModels.push(model1.cid);
                    }
                    if (!dupModels.includes(model2.cid)) {
                        dupModels.push(model2.cid);
                    }
                }
                return attrMatch && !cidMatch;
            });

            return match === undefined ? false
                : util.isEqual(match.attributes, model1.attributes);
        });

        return util.each(collect, (model) => {
            if (dupModels.includes(model.cid)) {
                model.set(
                    'ERROR',
                    locale.get('CM.Error.DuplicateIssue'),
                    {
                        silent: true,
                    },
                );
            }
        });
    },

    toggleClearRemove() {
        this.collectionIssues.trigger('toggle-clear-remove', this.collectionIssues.length > 1);
        this.collectionVoids.trigger('toggle-clear-remove', this.collectionVoids.length > 1);
    },

    /**
     * @name toggleTheOptionalFields
     * @description triggers collection event to toggle optional fields
     * called from either toggleOptionsFields or toggleOptionalFieldsByType
     * @param {collection} collection
     * @param {string} selector
     */
    toggleTheOptionalFields(collection, selector) {
        collection.trigger('toggle-optional-fields', this.$(selector).is(':checked'));
        if (isDeeplink) {
            this.handleChange();
        }
    },

    /**
     * @name toggleOptionalFieldsByType
     * @description - toggles optional fields on or off based on switch position
     * called when collection changed and there is only one item
     * @param {string} checkType (issue or void)
     */
    toggleOptionalFieldsByType(checkType) {
        const checkCollection = (checkType === 'issue') ? this.collectionIssues : this.collectionVoids;
        const switchSelector = `input#opt${checkType === 'issue' ? 'Issue' : 'Void'}FieldSwitch`;
        this.toggleTheOptionalFields(checkCollection, switchSelector);
    },

    /**
     * Callback to show optional fields for a certain check type
     * Will check if the flag is toggled for either check issue or check void
     */
    toggleOptionalFields(e) {
        const checkType = this.$(e.currentTarget).data('checktype');
        const checkCollection = (checkType === 'issue') ? this.collectionIssues : this.collectionVoids;
        this.toggleTheOptionalFields(checkCollection, e.target);
    },

    handleAccountSelection(accountFilter, currency) {
        this.collectionIssues.trigger('account-selected', accountFilter, currency);
        this.accountFilter = accountFilter;
        this.setCurrency(currency);
    },

    setCurrency(currency) {
        this.currency = currency;
    },

    toggleButtonEnabled($button, enabled) {
        $button.prop('disabled', !enabled);
    },

    singleClearedModel(collection) {
        const model = collection.at(0);
        return collection.length === 1 && util.every(['ISSUEVOIDDATE', 'AMOUNT', 'ACCOUNT_NUMBER', 'PAYEE', 'MEMO', 'SERIALNUMBER'], attr => util.isEmpty(model.get(attr)));
    },

    saveItems() {
        const self = this;

        // Make sure we have something to save!
        if ((this.collectionIssues && this.collectionIssues.length)
            || (this.collectionVoids && this.collectionVoids.length)) {
            let valid = true;
            const singleClearedIssue = this.singleClearedModel(this.collectionIssues);
            const singleClearedVoid = this.singleClearedModel(this.collectionVoids);

            this.toggleButtonEnabled(this.ui.submitButton, false);

            if (this.getDuplicateIssues(this.collectionIssues).length > 0) {
                this.collectionIssues.trigger('toggle-errors', true);
                valid = false;
            } else {
                this.collectionIssues.trigger('toggle-errors', false);
            }

            if (valid) {
                /*
                 * Validate issues if it has atleast one valid model OR neither issues nor
                 * voids have valid models
                 */
                if (!singleClearedIssue || (singleClearedIssue && singleClearedVoid)) {
                    this.collectionIssues.each((rec) => {
                        valid = rec.isValid();
                    });
                }

                if (!singleClearedVoid) {
                    this.collectionVoids.each((rec) => {
                        valid = rec.isValid();
                    });
                }

                if (!this.model.isValid()) {
                    valid = false;
                }
            }

            if (valid) {
                const combined = new Collection();
                if (!singleClearedIssue) {
                    combined.add(this.collectionIssues.models);
                }
                if (!singleClearedVoid) {
                    combined.add(this.collectionVoids.models);
                }
                this.model.set('items', combined);
                this.model.save(
                    null,
                    {
                        type: this.type,

                        success(model, response) {
                            if (response.result === false) {
                                alertMessage.renderMessage(self, 'save', response, 0, true);
                                self.toggleButtonEnabled(self.ui.submitButton, true);
                            } else {
                                store.set('cm_listView_issueVoids-alertMessage', 'save');
                                store.set('cm_listView_issueVoids-confirms', response);

                                workspaceHelper.returnToCurrentWorkspace(self);
                            }
                        },

                        error(model, response) {
                            alertMessage.renderMessage(self, 'save', response.responseJSON, 0, true);
                            // NH-37584 - enable button if there's an error.
                            self.toggleButtonEnabled(self.ui.submitButton, true);
                        },
                    },
                );
            } else {
                scroll.scrollToFirstError();
                // If invalid, we need to enable the button, because something else came up.
                this.toggleButtonEnabled(self.ui.submitButton, true);
            }
        }
    },

    cancel() {
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    /**
     * @method onClose
     * @description - method that is invoked when the view is closed.
     * If we are not a batch child view, then unset the helpPage that is used for
     * the global help.
     *
     */
    onClose() {
        store.unset('helpPage'); // remove view helppage from cache
    },
});
