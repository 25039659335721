import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import numeral from 'numeral';
import locale from '@glu/locale';
import Decimals from 'common/dynamicPages/api/decimals';
import { getMaskingProperties, maskValue } from 'common/util/maskingUtil';
import confirmDataPoller from 'common/dynamicPages/views/workflow/confirmDataPoller';
import simpleEntryWidgetSuccessTmpl from './simpleEntryWidgetSuccess.hbs';

export default Layout.extend({
    template: simpleEntryWidgetSuccessTmpl,
    className: 'SubmitSuccess',

    ui: {
        $doneBtn: '[data-hook="getDoneBtn"]',
        $spinnerElement: '.spinnerElement',
        $updateIcon: '.updateIcon',
        $columnSize: '.columnSize',
        $pollingResultMessage: '#paymentProcessingMessage',
        $submissionMessage: '#submissionMessage',
    },

    events: {
        'click @ui.$doneBtn': 'hideSuccessView',
    },

    initialize(options) {
        this.options = util.extend({}, options);
        // get the masking properties once
        this.maskingProps = getMaskingProperties();
        if (options.response?.confirms) {
            if (confirmDataPoller.createPoller(this, options.response.confirms.confirmResults)) {
                this.listenTo(this, 'updateConfirmPollerMsg', this.updateConfirmPollerMsg);
            }
        }
    },

    onShow() {
        util.defer(() => this.$el.addClass('active'));
        // Since we have a css 0.3 transition, we need to add a delay of 300 millisecond
        util.delay(() => {
            this.ui.$doneBtn.focus();
        }, 300);
    },

    onBeforeClose() {
        this.$el.removeClass('active');
        this.appBus.trigger('quick:confirmationDone');
    },

    hideSuccessView() {
        this.close();
        this.appBus.trigger('rtp:handleConfirmationDone');
    },

    templateHelpers() {
        const amt = numeral(this.model.get('CREDIT_AMOUNT'))
            .format(Decimals.getCurrencyFormat(this.model.get('CREDIT_CURRENCY')));
        // Quick Single transfer will contain single confirmPollingKeys
        const getPollingkeys =
        this.options?.response?.confirms?.confirmResults?.[0]?.confirmPollingKeys || [];

        return {
            formattedAmount: amt,
            isTransfer: this.options.isTransfer,
            confirmPollingKeys: getPollingkeys,
            maskIt: value => maskValue(value, this.maskingProps),
        };
    },
    onClose() {
        confirmDataPoller.dispose();
    },
    updateConfirmPollerMsg(res) {
        this.ui.$spinnerElement.addClass('hidden');
        this.ui.$submissionMessage.addClass('hidden');
        this.ui.$columnSize.removeClass('col-lg-2').addClass('col-lg-3');
        this.ui.$pollingResultMessage.html(res.msg);
        if (res.msg.includes(locale.get('payment.confirm.rejected.msg')) === true) {
            this.ui.$updateIcon.removeClass('icon-check-circle').addClass('icon-warning');
        }
    },
});
