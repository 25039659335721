import ItemView from '@glu/core/src/itemView';
import planPropertiesViewTmpl from './planPropertiesView.hbs';

const PlanPropertiesView = ItemView.extend({
    className: 'plan-properties',
    template: planPropertiesViewTmpl,

    ui: {
        planSelector: '.plan-list',
    },

    events: {
        'change @ui.planSelector': 'handlePlanSelect',
    },

    initialize() {
        this.listenTo(this.model, 'sync', this.render);
        this.listenTo(this.collection, 'sync', this.render);
    },

    handlePlanSelect(data) {
        const target = data.target.value.split(':');
        this.collection.selectByIdAndVersion(target[0], target[1]);
    },

    templateHelpers() {
        return {
            plans: this.collection.toJSON().filter(m => m.cashFlowPlanId >= 0),
            id: this.cid,
        };
    },
});

export default PlanPropertiesView;
