/* istanbul ignore file */
import commonTheme from './commonTheme';

export default ({ palette, typography }) => ({
  dropdownChevron: {},
  itemHoverBackground: 'rgba(0, 0, 0, 0.2)',
  paginate: {
    fontSize: 'inherit'
  },
  selection: {
    borderRadius: 2,
    padding: [9, 30, 9, 10]
  },
  svgIconDisplay: 'initial',
  textTransform: 'uppercase',
  ...commonTheme(typography, palette)
});
