import f1 from 'system/utilities/f1';

export default (formParam, initialState) => {
    const form = formParam;
    let addendaFormat = form.field('ADDENDAFORMAT');
    let refCode = form.field('REFCODE');
    let totalInvoice = form.field('TOTALINVOICEAMOUNT');
    let termDisc = form.field('TERMSDISCOUNT');
    let adjAmount = form.field('AMOUNTOFADJ');
    let adjCode = form.field('ADJREASONCODE');
    let refNumber = form.field('REFNUMBER');
    let refQualifier = form.field('REFNUMBERQUALIFIER');
    let payDescription = form.field('PAYMENTDESCRIPTION');
    let addenDa = form.field('MESSAGE');
    let traceTypeCode = form.field('TRACETYPECODE');
    let referenceId = form.field('REFERENCEID');
    let orgCompCode = form.field('ORGCOMPCODE');
    let orgSuppleInfo = form.field('ORGCOMPSUPPLEINFO');
    let beneWidget;
    let beneForm;
    const { model } = form.formView;
    const { parentModel } = form.formView;
    const isHealthCare = parentModel.get('HEALTHCARE') === '1';

    /**
     * @description Clears the addenda related fields.  Needed when switching
     * between freeform and formatted
     */
    function clearAddendaFields() {
        model.set({
            REFCODE: '',
            TOTALINVOICEAMOUNT: '',
            AMOUNTOFADJ: '',
            ADJREASONCODE: '',
            REFNUMBER: '',
            REFNUMBERQUALIFIER: '',
            PAYMENTDESCRIPTION: '',
            TERMSDISCOUNT: '',
            MESSAGE: '',
            TRACETYPECODE: '',
            REFERENCEID: '',
            ORGCOMPCODE: '',
            ORGCOMPSUPPLEINFO: '',
        });
    }

    function updateHealthcareAddendaFields() {
        const required = (parentModel.get('ADDENDAFORMAT') === '3') || isHealthCare || (addendaFormat && addendaFormat.getValue() === '3');
        traceTypeCode.shouldBeVisibleWhen(required);
        referenceId.shouldBeVisibleWhen(required);
        orgCompCode.shouldBeVisibleWhen(required);
        orgSuppleInfo.shouldBeVisibleWhen(required);
        traceTypeCode.shouldBeRequiredWhen(required);
        traceTypeCode.$el.parent().toggleClass('required', required);
        traceTypeCode.shouldHaveMinLength(required ? 1 : 0);
        referenceId.shouldBeRequiredWhen(required);
        referenceId.$el.parent().toggleClass('required', required);
        orgCompCode.shouldBeRequiredWhen(required);
        orgCompCode.$el.parent().toggleClass('required', required);
        orgCompCode.shouldHaveMinLength(required ? 10 : 0);
    }

    /**
     * @description This function will show or hide addenda related fields.
     * Freeform and formatted addenda display
     * different form fields.  If the user has not chosen an addenda type, then
     * only the addenda type dropdown is
     * displayed.
     */
    function showHideAddendaFields() {
        const modelAddendaFormat = parentModel.get('ADDENDAFORMAT') || model.get('ADDENDAFORMAT');
        const modelMessage = parentModel.get('MESSAGE') || addenDa.getValue();
        if (modelAddendaFormat === '1' || (modelAddendaFormat === '' && modelMessage !== '')) {
            refCode.shouldBeVisible();
            addenDa.shouldBeVisible();
            totalInvoice.shouldBeHidden();
            termDisc.shouldBeHidden();
            adjAmount.shouldBeHidden();
            adjCode.shouldBeHidden();
            refNumber.shouldBeHidden();
            refQualifier.shouldBeHidden();
            payDescription.shouldBeHidden();
            updateHealthcareAddendaFields();
        } else if (modelAddendaFormat === '2') {
            refCode.shouldBeHidden();
            addenDa.shouldBeHidden();
            totalInvoice.shouldBeVisible();
            termDisc.shouldBeVisible();
            adjAmount.shouldBeVisible();
            adjCode.shouldBeVisible();
            refNumber.shouldBeVisible();
            refQualifier.shouldBeVisible();
            payDescription.shouldBeVisible();
            updateHealthcareAddendaFields();
        } else if (modelAddendaFormat === '3' || isHealthCare) {
            refCode.shouldBeHidden();
            addenDa.shouldBeHidden();
            totalInvoice.shouldBeHidden();
            termDisc.shouldBeHidden();
            adjAmount.shouldBeHidden();
            adjCode.shouldBeHidden();
            refNumber.shouldBeHidden();
            refQualifier.shouldBeHidden();
            payDescription.shouldBeHidden();
            updateHealthcareAddendaFields();
        } else {
            refCode.shouldBeHidden();
            addenDa.shouldBeHidden();
            totalInvoice.shouldBeHidden();
            termDisc.shouldBeHidden();
            adjAmount.shouldBeHidden();
            adjCode.shouldBeHidden();
            refNumber.shouldBeHidden();
            refQualifier.shouldBeHidden();
            payDescription.shouldBeHidden();
            updateHealthcareAddendaFields();
        }
    }

    if (form.formView.useBeneWidget) {
        /**
         * using the BENE widget, so the addenda fields are not in the parent/main view.
         * need to get the addenda fields from the bene widget
         * In order to use the F1 functions, such as shouldBeHidden and shouldBeVisible,
         * need to get an F1 form for the beneWidget view.
         */
        ({ beneWidget } = form.formView);
        beneForm = f1.getForm(beneWidget);

        addendaFormat = beneForm.field('ADDENDAFORMAT');
        totalInvoice = beneForm.field('TOTALINVOICEAMOUNT');
        termDisc = beneForm.field('TERMSDISCOUNT');
        adjAmount = beneForm.field('AMOUNTOFADJ');
        adjCode = beneForm.field('ADJREASONCODE');
        refCode = beneForm.field('REFCODE');
        refNumber = beneForm.field('REFNUMBER');
        refQualifier = beneForm.field('REFNUMBERQUALIFIER');
        payDescription = beneForm.field('PAYMENTDESCRIPTION');
        addenDa = beneForm.field('MESSAGE');
        traceTypeCode = beneForm.field('TRACETYPECODE');
        referenceId = beneForm.field('REFERENCEID');
        orgCompCode = beneForm.field('ORGCOMPCODE');
        orgSuppleInfo = beneForm.field('ORGCOMPSUPPLEINFO');
    }

    function formatOrigId(origCompId) {
        let id = origCompId;
        if (id.length === 9) {
            /*
             * we have to add space for legacy accounts, when copied from originator
             * to addenda
             */
            id += ' ';
        }
        return id;
    }

    function updateBeneAddenda() {
        if (parentModel.get('HEALTHCARE') !== '1') {
            return;
        }
        ({ beneWidget } = form.formView);
        if (parentModel.get('ORIGCOMPID')) {
            const id = formatOrigId(parentModel.get('ORIGCOMPID'));
            if (model.context.actionMode.toUpperCase() === 'INSERT') {
                model.set('ORGCOMPCODE', id);
            }
            // now lets update any other benes
            if (form.formView.parentView && form.formView.parentView.batchChildGridView) {
                const bulkRequest = {
                    rows: null,
                    action: 'bulkUpdate',
                    actionType: 'HC_ADDENDA',
                    strValue: id,
                };
                form.formView.parentView.batchChildGridView.makeBulkUpdateRequest(bulkRequest);
            }
        }
    }

    function updateAddendaFields() {
        clearAddendaFields();
        showHideAddendaFields();
        if (parentModel.get('HEALTHCARE') === '1') {
            model.set('ORGCOMPCODE', formatOrigId(parentModel.get('ORIGCOMPID')));
        }
    }

    if (initialState) {
        /*
         * set fields to ignore on a valid bene check.  These are auto-populated and
         * should not count
         */
        form.formView.beneAutoPopulatedFields = ['ORGCOMPCODE', 'ADDENDAFORMAT'];

        showHideAddendaFields();

        parentModel.on('change:ORIGCOMPID', () => {
            updateBeneAddenda();
        });

        parentModel.on('change:ADDENDAFORMAT', () => {
            updateAddendaFields();
        });

        model.on('change:ADDENDAFORMAT', () => {
            updateAddendaFields();
        });
    }
};
