import OverlayWindowAlignment from './overlayWindowAlignment';
import OverlayFrameAlignment from './overlayFrameAlignment';

export default {
    create(controller) {
        if (window.parent === window) {
            return new OverlayWindowAlignment();
        }
        return new OverlayFrameAlignment({
            frameController: controller,
        });
    },
};
