import axios from 'axios';
import locale from '@glu/locale';
import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import 'print-this';
import template from './consentModal.hbs';

const ConsentModal = Layout.extend({
    template,

    ui: {
        $agreeButton: '.agreeButton',
        $consentWrapper: '[data-hook="getConsentWrapper"]',
    },

    initialize() {
        this.dialogTitle = locale.get('_ADMIN.PPRLESS.Paperless.Statements.Agreement');
        this.dialogButtons = [
            {
                // should be disabled
                text: locale.get('_ADMIN.PPRLESS.Agree'),
                className: 'btn btn-primary agreeButton',
                callback: 'onAgree',
            },
            {
                text: locale.get('button.print'),
                className: 'btn btn-secondary',
                callback: 'print',
            },
            {
                text: locale.get('common.cancel'),
                className: 'btn btn-secondary',
                callback: 'cancel',
            },
        ];
    },

    /*
     * Using onShow because it is invoked after the view has been rendered and is
     * shown in the DOM. The modal button are also available at this time.
     */
    onShow() {
        this.toggleAgreeButton(true);
        this.getContent(serverConfigParams.get('PaperlessConsentAgreementUrl'))
            .then((content) => {
                this.ui.$consentWrapper.html(content);
                this.toggleAgreeButton(false);
            }).catch(() => {
                this.ui.$consentWrapper.html(locale.get('_ADMIN.PPRLESS.Consent.Form.Error'));
            });
    },

    /**
     * Use the configured method for getting the consent form
     * @param {string} url
     * @returns {Promise}
     */
    getContent(url) {
        if (!url) {
            return Promise.reject();
        }
        return axios.get(url)
            .then(resp => resp.data);
    },

    /**
     * Toggle the disabled prop from the agree button
     */
    toggleAgreeButton(disabled) {
        this.$el.parents('.modal').find('.agreeButton').prop('disabled', disabled);
    },

    /**
     * Invoke the accept callback and close the modal
     */
    onAgree() {
        this.options.onAgree();
        dialog.close();
    },

    /**
     * @name cancel
     * @description 'cancel' handler for dialog.
     */
    cancel() {
        this.options.onDecline();
        dialog.close();
    },

    print() {
        this.ui.$consentWrapper.printThis();
    },

    templateHelpers() {
        return {
            iframeSrc: this.paperlessConsentUrl,
        };
    },
});
export default ConsentModal;
