import Handlebars from 'handlebars';
import workflowPageHeaderTmpl from 'etc/partials/workflowPageHeader.hbs';
import detailAuditHistoryTmpl from 'etc/partials/detailAuditHistory.hbs';
import rapidAddAdditionalTmpl from 'etc/partials/rapidAddAdditional.hbs';
import rapidSummarySectionTmpl from 'etc/partials/rapidSummarySection.hbs';
import staticFieldPartialTmpl from 'etc/partials/staticFieldDiv.hbs';
import smbPaymentPartialTmpl from 'etc/partials/smbPaymentPartial.hbs';
import lockIconTmpl from 'etc/partials/lockIcon.hbs';
import timeZoneAddOnTmpl from 'etc/partials/timeZoneAddOn.hbs';
import loanDrawInterestRateTmpl from 'etc/partials/loanDrawInterestRate.hbs';

Handlebars.registerPartial('lockIcon', lockIconTmpl);
Handlebars.registerPartial('workflowPageHeader', workflowPageHeaderTmpl);
Handlebars.registerPartial('detailAuditHistory', detailAuditHistoryTmpl);
Handlebars.registerPartial('rapidAddAdditional', rapidAddAdditionalTmpl);
Handlebars.registerPartial('rapidSummarySection', rapidSummarySectionTmpl);
Handlebars.registerPartial('staticFieldPartial', staticFieldPartialTmpl);
Handlebars.registerPartial('timeZoneAddOn', timeZoneAddOnTmpl);
Handlebars.registerPartial('smbPaymentPartial', smbPaymentPartialTmpl);
Handlebars.registerPartial('loanDrawInterestRate', loanDrawInterestRateTmpl);
