import locale from '@glu/locale';
import FraudControlListView from 'app/administration/views/fraudControl/fraudControlListView';
import PosPayExtractLog from 'app/administration/views/fraudControl/posPayExtractLog';
import DecisionHistoryListView from 'app/checkManagement/views/listPositivePayDecisionHistory';
import EDecisionHistoryListView from 'app/checkManagement/views/listEPositivePayDecisionHistory';
import PositivePayDecisionList from 'app/administration/views/fraudControl/listPositivePayDecision';
import ReversePositivePayDecisionList from 'app/administration/views/fraudControl/listReversePositivePayDecision';
import EPositivePayDecisionList from 'app/administration/views/fraudControl/listEPositivePayDecision';
import store from 'system/utilities/cache';
import workspaceHelper from 'common/workspaces/api/helper';
import FraudParent from 'app/FraudChangeParent';
import { setTabButtonText, createTabsToggleButton } from 'common/util/a11y/tabs';
import fraudControlTmpl from './fraudControl.hbs';

const FraudControl = FraudParent.extend({
    template: fraudControlTmpl,
    defaultTab: 'individual',

    initialize(options) {
        if (options.defaultTab) {
            this.defaultTab = options.defaultTab;
        }
    },

    onRender() {
        if (store.get('cm_listView_payDecision-viewAll')) {
            this.defaultTab = store.get('cm_listView_comingToList');
        }

        createTabsToggleButton(this);
        this.openView(this.defaultTab);
        setTabButtonText(this, this.$(`a#${this.defaultTab}`).text() || locale.get('common.tabs.chooseTab'));
        this.isInitialRender = false;
    },

    updateView(e) {
        const href = this.$(e.currentTarget).attr('id');
        e.preventDefault();
        this.openView(href);
        this.changeTab(e.currentTarget);
    },

    openView(href) {
        switch (href) {
        case 'individual':
            this.changeLink('accs-by-type');
            this.gridView = new FraudControlListView();
            this.displayField(true, this.ui.$divLinks);
            break;
        case 'extract':
            this.gridView = new PosPayExtractLog();
            this.displayField(false, this.ui.$divLinks);
            break;
        case 'decision-history':
            this.gridView = new DecisionHistoryListView({
                serviceName: 'adminCM/fraud/listView/positivePayDecisionHistory',
                hideFields: true,
            });
            this.displayField(false, this.ui.$divLinks);
            break;
        case 'edecision-history':
            this.gridView = new EDecisionHistoryListView({
                serviceName: 'adminCM/fraud/listView/ePositivePayDecisionHistory',
                hideFields: true,
            });
            this.displayField(false, this.ui.$divLinks);
            break;
        case 'accs-by-type':
            this.changeLink(href);
            this.gridView = new FraudControlListView();
            break;
        case 'posPay-items':
            this.changeLink(href);
            this.gridView = new PositivePayDecisionList({
                serviceName: 'adminCM/fraud/listView/positivePayDecision',
                hideFields: true,
                returnRoute: 'REPORTING/viewPaymentFraudControl',
                viewAll: true,
            });
            break;
        case 'revPosPay-items':
            this.changeLink(href);
            this.gridView = new ReversePositivePayDecisionList({
                serviceName: 'adminCM/fraud/listView/reversePositivePayDecision',
                hideFields: true,
                returnRoute: 'REPORTING/viewPaymentFraudControl',
                viewAll: true,
            });
            break;
        case 'ePay-items':
            this.changeLink(href);
            this.gridView = new EPositivePayDecisionList({
                serviceName: 'adminCM/fraud/listView/ePositivePayDecision',
                hideFields: true,
                returnRoute: 'REPORTING/viewPaymentFraudControl',
                viewAll: true,
            });
            break;
        default:
            this.gridView = new FraudControlListView();
            this.displayField(true, this.ui.$divLinks);
        }

        this.pageRegion.show(this.gridView);
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'CM_FRAUD_CTRL',
    view: FraudControl,
    options: {},
});

export default FraudControl;
