import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import GridContext from '../Grid/GridContext';
import ColumnTitle from './ColumnTitle';
import ColumnMenu from './ColumnMenu';
import ColumnSort from './ColumnSort';
import ColumnFilterIndicator from './ColumnFilterIndicator';
import styles from './HeaderCellRenderer.styles';

const HeaderCellRenderer = ({
  column,
  columnApi,
  displayName,
  enableSorting,
  setSort,
  context,
  dataQa,
  classes
}) => {
  const [showMenuBtn, setShowMenuBtn] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showFilters, setShowFilters] = useState(column.isFilterActive());
  const gridContext = useContext(GridContext);
  const [sortOrder, setSortOrder] = useState((column.sort || column.userProvidedColDef.sort) || 'none');
  const closeMenuWithTimeout = useRef(null);

  const onSortChanged = (event) => {
    const orderSort = column.isSortAscending()
      ? 'desc'
      : column.isSortDescending()
        ? 'none'
        : 'asc';
    setSort(orderSort, event.shiftKey);
    setSortOrder(orderSort);
  };

  const onMouseEnter = () => {
    clearTimeout(closeMenuWithTimeout.current);
    if (column.userProvidedColDef.filterable !== false) {
      setShowMenuBtn(true);
      setShowMenu(true);
    }
  };

  const onMouseLeave = () => {
    closeMenuWithTimeout.current = setTimeout(() => {
      setShowMenuBtn(false);
    }, 400);
  };

  const onSortRequested = (event) => {
    if (column.userProvidedColDef.sortable) {
      onSortChanged(event);
    }
  };

  const onSortChangedEvent = () => {
    setSortOrder(column.sort || 'none');
  };

  useEffect(() => {
    const initSort = column.userProvidedColDef.sort;
    if (initSort) {
      setSort(initSort);
      setSortOrder(initSort);
    }

    const onFilterChanged = () => {
      setShowFilters(column.isFilterActive());
    };

    column.addEventListener('sortChanged', onSortChangedEvent);
    column.addEventListener('filterChanged', onFilterChanged);
    return () => {
      column.removeEventListener('sortChanged', onSortChangedEvent);
      column.removeEventListener('filterChanged', onFilterChanged);
    };
  }, []);

  return (
    <div
      data-qa={dataQa || column.colId}
      className={classes.root}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <button
        className={classes.sortWrapper}
        type="button"
        onClick={(event) => onSortRequested(event)}
      >
        <ColumnTitle
          displayName={displayName}
          column={column}
        />
        {showFilters && (
          <ColumnFilterIndicator />
        )}
        {enableSorting && (
          <ColumnSort order={sortOrder} />
        )}
      </button>

      {showMenu
        ? (
          <ColumnMenu
            dataQa={dataQa}
            columnApi={columnApi}
            column={column}
            context={context}
            filters={gridContext.filters}
            displayName={displayName}
            showMenuBtn={showMenuBtn}
          />
        )
        : null
      }
    </div>
  );
};

HeaderCellRenderer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  /** Ag grid context */
  context: PropTypes.objectOf(PropTypes.any).isRequired,
  column: PropTypes.shape({
    addEventListener: PropTypes.func,
    removeEventListener: PropTypes.func,
    isSortAscending: PropTypes.func.isRequired,
    isSortDescending: PropTypes.func.isRequired,
    userProvidedColDef: PropTypes.object.isRequired,
    colId: PropTypes.string.isRequired,
    isFilterActive: PropTypes.func.isRequired,
    sort: PropTypes.bool
  }).isRequired,
  displayName: PropTypes.string.isRequired,
  enableSorting: PropTypes.bool,
  setSort: PropTypes.func,
  columnApi: PropTypes.shape().isRequired,
  dataQa: PropTypes.string
};

HeaderCellRenderer.defaultProps = {
  enableSorting: true,
  setSort() { },
  dataQa: ''
};

export default withStyles(styles)(HeaderCellRenderer);
