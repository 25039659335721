import {
    Button, EXTRA_SMALL, PRIMARY, TERTIARY,
} from '@glu/buttons-react';
import {
    CheckmarkCircleNextIcon, ExclamationCircleNextIcon,
    InfoCircleNextIcon, XCircleNextIcon,
} from '@glu/icons-react';
import { appBus } from '@glu/core';
import { withStyles } from '@glu/theming';
import PropTypes from 'prop-types';
import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import ViewWrapper from 'react-wrappers/ViewWrapper';
import { isDeepLinked, getLastPositionData } from 'common/util/deeplinkUtil';
import useLocale from '../../hooks/useLocale';
import styles from './PopupAlert.styles';

const { getLocaleString } = useLocale();

const propTypes = {
    /** Title of alert (main heading)  */
    title: PropTypes.string,
    /** Alert text */
    message: PropTypes.string,
    /** Type of alert */
    type: PropTypes.oneOf(['default', 'success', 'positive', 'info', 'warning', 'danger', 'negative']),
    /** Type of icon */
    icon: PropTypes.oneOf(['', 'tick', 'success', 'warning', 'danger', 'negative', 'info']),
    /** Duration before alert is automatically closed */
    duration: PropTypes.number,
    /** JSS classes for styling */
    classes: PropTypes.objectOf(PropTypes.string),
    /** Should the primary button automatically receive focus */
    autoFocus: PropTypes.bool,
    /** Should the alert be animated on entry/exit */
    animate: PropTypes.bool,
    /** Can this dialog be dismissed */
    canDismiss: PropTypes.bool,
    /** Additional detaiils, can be Backbone view */
    details: PropTypes.func,
    /** Function that should be invoked on remove of alert */
    remove: PropTypes.func.isRequired,
    /** Animation type to use when showing  */
    // eslint-disable-next-line react/no-unused-prop-types
    animateIn: PropTypes.oneOf([
        'slideIn-fromTop',
        'slideIn-fromBottom',
        'slideIn-fromLeft',
        'slideIn-fromRight',
        'slideOut-fromTop',
        'slideOut-fromBottom',
        'slideOut-fromLeft',
        'slideOut-fromRight',
    ]),
    /** Animation type to use when hiding  */
    // eslint-disable-next-line react/no-unused-prop-types
    animateOut: PropTypes.oneOf([
        'slideIn-fromTop',
        'slideIn-fromBottom',
        'slideIn-fromLeft',
        'slideIn-fromRight',
        'slideOut-fromTop',
        'slideOut-fromBottom',
        'slideOut-fromLeft',
        'slideOut-fromRight',
    ]),
    /** Animation timing  */
    // eslint-disable-next-line react/no-unused-prop-types
    animateTiming: PropTypes.string,
};

const defaultProps = {
    animate: false,
    message: '',
    title: '',
    type: 'default',
    duration: 0,
    details: undefined,
    icon: '',
    canDismiss: true,
    autoFocus: true,
    animateIn: 'slideIn-fromBottom',
    animateOut: 'slideOut-fromBottom',
    animateTiming: '0.5s',
    classes: {},
};

const PopupAlert = ({
    autoFocus,
    animate,
    title,
    message,
    type,
    duration,
    details,
    icon,
    canDismiss,
    classes,
    remove,
}) => {
    const popupRef = useRef();
    const okButtonText = getLocaleString('button.ok') && getLocaleString('button.ok') !== '??button.ok??'
        ? getLocaleString('button.ok')
        : 'Okay';

    const [showDetails, setShowDetails] = useState(false);
    const [dismiss, setDismiss] = useState(false);
    const [shouldRender, setShouldRender] = useState(true);

    const dismissModal = useCallback(() => {
        setDismiss(true);
        if (!animate) {
            setShouldRender(false);
        }
    }, [animate]);

    const getInitialPosition = () => {
        const base = {
            height: '100vh',
            top: 0,
        };
        return (isDeepLinked() && getLastPositionData()) || base;
    };

    const [alertPosition, setAlertPosition] = useState(getInitialPosition());

    /**
     * Support positioning the popupAlert on mobile in an iframe
     * @param {object} data
     */
    const updatePositionFromIframe = data => setAlertPosition({
        height: data.usableHeight,
        top: data.contentTop,
    });

    useEffect(() => {
        if (isDeepLinked()) {
            appBus.on('iframePosition', updatePositionFromIframe);
            return () => appBus.off('iframePosition', updatePositionFromIframe);
        }
        return () => false;
    });

    const onAnimationEnd = () => {
        if (dismiss) {
            setShouldRender(false);
            remove();
        }
    };

    /**
     * Trap focus inside React reference element
     * @param {object} ref - reference to element inside which focus needs to be trapped
     * @param {function} escapeCallback - function to call when user press Escape key
     */
    const trapFocusInsideRef = (ref, escapeCallback) => {
        const focusableElements = ref.current.querySelectorAll(`a[href],
        area[href],
        input:not([disabled]),
        select:not([disabled]),
        textarea:not([disabled]),
        button:not([disabled]),
        iframe,
        object,
        embed,
        [tabindex="0"],
        [contenteditable]`);
        const firstTabElement = focusableElements[0];
        const lastTabElement = focusableElements[focusableElements.length - 1];

        ref.current.addEventListener('keydown', (e) => {
        // TAB
            if (e.keyCode === 9) {
                if (e.shiftKey) {
                    if (document.activeElement === firstTabElement) {
                        e.preventDefault();
                        lastTabElement.focus();
                    }
                } else if (document.activeElement === lastTabElement) {
                    e.preventDefault();
                    firstTabElement.focus();
                }
            }
            // ESC
            if (e.keyCode === 27) {
                escapeCallback();
            }
        });
    };

    const useMessageAsTitle = title === '' && message.length < 30;

    useEffect(() => {
        trapFocusInsideRef(popupRef, dismissModal);
    }, [dismissModal, showDetails]);

    useEffect(() => {
        if (duration) {
            setTimeout(() => {
                dismissModal();
                setShouldRender(false);
            }, duration);
        }
    }, [dismissModal, duration]);

    const iconHelper = useCallback((iconName) => {
        switch (iconName) {
        case 'tick':
        case 'success':
            return (<CheckmarkCircleNextIcon fill="#cccccc" className={classes.popupAlertIcon} />);
        case 'warning':
        case 'danger':
        case 'negative':
            return <ExclamationCircleNextIcon fill="#cccccc" className={classes.popupAlertIcon} />;
        case 'info':
            return <InfoCircleNextIcon fill="#cccccc" className={classes.popupAlertIcon} />;
        default:
            return null;
        }
    }, [classes]);

    const rootClassName = useMemo(() => {
        if (animate) {
            return `${classes.popupAlertOverlay} ${dismiss ? `${classes.popupAnimateOut}` : `${classes.popupAnimateIn}`}`;
        }
        return classes.popupAlertOverlay;
    }, [
        animate,
        classes.popupAlertOverlay,
        classes.popupAnimateIn,
        classes.popupAnimateOut,
        dismiss,
    ]);

    return shouldRender ? (
        <div
            ref={popupRef}
            className={rootClassName}
            role="alertdialog"
            aria-labelledby="popupAlertTitle"
            onAnimationEnd={onAnimationEnd}
            style={!isDeepLinked() ? {} : {
                top: `${alertPosition.top}px`,
                height: `${alertPosition.height}px`,
            }}
        >
            {canDismiss
                ? (
                    <Button
                        variant={TERTIARY}
                        className={classes.popupAlertCloseButton}
                        type="button"
                        onClick={dismissModal}
                        data-dismiss="alert"
                        aria-label="Close Dialog"
                    >
                        <XCircleNextIcon fill="#4c4c4c" />
                    </Button>
                )
                : null}
            <div className={classes.popupAlertContent}>
                <header>
                    { iconHelper(icon || type) }
                    <h1 id="popupAlertTitle">{useMessageAsTitle ? message.toUpperCase() : title.toUpperCase()}</h1>
                </header>
                {!useMessageAsTitle && <p className={classes.popupAlertMessage}>{message}</p>}
                {
                    details
                        ? (
                            <section className={classes.popupAlertDetails}>
                                {showDetails
                                    ? <ViewWrapper view={details} />
                                    : (
                                        <Button
                                            variant={TERTIARY}
                                            type="button"
                                            size={EXTRA_SMALL}
                                            onClick={() => setShowDetails(true)}
                                            text={`${getLocaleString('common.Show')} ${getLocaleString('details')}` || 'Show Detail'}
                                        />
                                    )}
                            </section>
                        )
                        : null
                }

                <Button
                    autoFocus={autoFocus}
                    variant={PRIMARY}
                    type="button"
                    onClick={dismissModal}
                    className={`${classes.popupAlertPrimaryButton}`}
                    text={okButtonText}
                />
            </div>
        </div>
    ) : null;
};

PopupAlert.propTypes = propTypes;
PopupAlert.defaultProps = defaultProps;

export const BarePopupAlert = PopupAlert;
export default withStyles(styles)(PopupAlert);
