import moment from 'moment';
import ItemView from '@glu/core/src/itemView';
import userInfo from 'etc/userInfo';
import dueDateTmpl from './dueDate.hbs';

export default ItemView.extend({
    className: 'due-date-cell',
    template: dueDateTmpl,

    templateHelpers() {
        const dueDate = moment(this.model.get('DUE_DATE'), userInfo.getDateFormat());
        const difference = dueDate.diff(new Date(), 'days');

        return {
            difference,
            isSingular: (Math.abs(difference) === 1),
            isNegative: difference < 0,
        };
    },
});
