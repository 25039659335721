import ItemView from '@glu/core/src/itemView';
import accountTmpl from 'app/reports/views/lockbox/modals/remitter/account.hbs';
import { maskValue } from 'common/util/maskingUtil';

export default ItemView.extend({
    template: accountTmpl,

    initialize(options) {
        this.parent = options.parent;
    },

    removeAccount() {
        this.model.action = 'destroy';
        if (this.model.has('ABA') && this.model.has('ACCOUNTNUMBER')) {
            this.parent.models.add(this.model);
        }
        this.trigger(this.model.action);
    },

    templateHelpers() {
        const self = this;
        return {
            isExistingAccount() {
                return self.model.get('ABA') && self.model.get('ACCOUNTNUMBER');
            },

            isAdvanced() {
                return self.parent.modalType === 'advanced';
            },

            maskIt: value => maskValue(value),
        };
    },
});
