var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"widget-links\">\n    <button type=\"button\" class=\"btn btn-tertiary export\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.export",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":65},"end":{"line":2,"column":91}}}))
    + "\" data-hook=\"export-button\"><span class=\"icon-export\"></span> "
    + alias2((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||alias3).call(alias1,"button.export",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":2,"column":153},"end":{"line":2,"column":188}}}))
    + "</button>\n    <button type=\"button\" class=\"btn btn-tertiary print\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":64},"end":{"line":3,"column":89}}}))
    + "\" data-hook=\"print-button\"><span class=\"icon-print\"></span> "
    + alias2((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||alias3).call(alias1,"button.print",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":3,"column":149},"end":{"line":3,"column":183}}}))
    + "</button>\n</div>\n<div data-region=\"failedRecordsGrid\" aria-live=\"polite\"></div>\n";
},"useData":true});