import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import fraudConstants from 'app/checkManagement/constants';
import store from 'system/utilities/cache';
import transform from 'common/util/transform';
import GridApi from 'common/dynamicPages/api/grid';
import PrintExportUtil from 'common/util/printExportUtil';
import TotalsView from 'app/administration/common/totalsHeader/totalsView';
import posPayExtractLogDetailTmpl from './posPayExtractLogDetail.hbs';

export default Layout.extend({
    template: posPayExtractLogDetailTmpl,
    inquiryId: fraudConstants.FILE_EXTRACTION_DETAIL_INQ_BMPP,

    initialize() {
        this.fileExtractModel = store.get('cm-posPay-fileExtract-model');
        if (this.fileExtractModel.get('POSITIVEPAYTYPECODE') === 'CMPP') {
            this.inquiryId = fraudConstants.FILE_EXTRACTION_DETAIL_INQ_CMPP;
        } else if (this.fileExtractModel.get('POSITIVEPAYTYPECODE') === 'EBMPP') {
            this.inquiryId = fraudConstants.FILE_EXTRACTION_DETAIL_INQ_EBMPP;
        }
    },

    /**
     * Creates an object literal to be used by the template
     * @return {object} the object for the template
     */
    templateHelpers() {
        const self = this;
        return {
            /**
             * Gets the section header
             * @return {string} the section header.
             */
            getSectionHeader() {
                return `${locale.get('CM.FileID')} ${self.fileExtractModel.get('ID')}`;
            },
        };
    },

    onRender() {
        const requestParameters = {
            inquiryId: this.inquiryId,
            productCode: 'CM',
            functionCode: 'BATCH',
            typeCode: '*',
            viewType: `CM-BATCH-*-${this.inquiryId}`,
        };

        const options = {
            context: {
                serviceName: '/tableMaint',
                actionMode: 'SELECT',
                functionCode: 'BATCH',
                productCode: 'CM',
                typeCode: `*-${this.inquiryId}`,
            },

            filter: true,
            selector: 'none',
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,
            enableSavedViews: true,

            requestParameters: {
                item: transform.hashToPairs(requestParameters),
            },

            additionalSearchFields: [{
                fieldName: 'ID',
                operator: '=',
                dataType: 'number',

                fieldValue: [
                    this.fileExtractModel.get('ID'),
                ],
            }, {
                fieldName: 'BANKCODE',
                operator: '=',
                dataType: 'text',

                fieldValue: [
                    this.fileExtractModel.get('BANKCODE'),
                ],
            }, {
                fieldName: 'POSITIVEPAYTYPECODE',
                operator: '=',
                dataType: 'text',

                fieldValue: [
                    this.fileExtractModel.get('POSITIVEPAYTYPECODE'),
                ],
            }],
        };

        this.gridView = GridApi.createServiceGridView(options);
        this.gridRegion.show(this.gridView);
        this.listenTo(this.gridView, 'gridapi:loaded', this.loadTotals);
        this.listenTo(this.gridView.getRows(), 'sync', this.refreshTotals);
    },

    /**
     * Creates and loads the totals view
     */
    loadTotals() {
        this.totalsView = new TotalsView({
            productCode: 'CM',
            functionCode: 'INST',
            typeCode: 'CIMINSTT',
            inquiryId: 20585,
            filters: this.gridView.wrapper.generateFiltersDataBlock(),
            viewId: this.gridView.wrapper.viewId,
        });
        this.totalsSection.show(this.totalsView);
        this.refreshTotals();
    },

    /**
     * Refreshes the totals view on this page.
     */
    refreshTotals() {
        if (this.totalsView) {
            this.totalsView.refresh(
                this.gridView.wrapper.generateFiltersDataBlock(),
                this.gridView.wrapper.viewId,
            );
        }
    },

    back() {
        this.navigateTo('REPORTING/viewPaymentFraudControl/extract');
    },

    print() {
        const options = {
            view: this,
            gridView: this.gridView,
            inquiryId: this.inquiryId,
            title: '',
            format: 'PDF',
        };
        PrintExportUtil.print(options);
    },

    export() {
        const options = {
            view: this,
            gridView: this.gridView,
            inquiryId: this.inquiryId,
            format: 'CSV',
        };
        PrintExportUtil.export(options);
    },
});
