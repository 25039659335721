var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"localeKeys") : depth0)) != null ? lookupProperty(stack1,"approvePretext") : stack1),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":11,"column":25}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":23},"end":{"line":10,"column":38}}}))
    + "</p>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"localeKeys") : depth0)) != null ? lookupProperty(stack1,"requestPretext") : stack1),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":52}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog glu-dialog\">\n    <form class=\"modal-content\" data-submit=\"save\">\n        <div class=\"modal-header\">\n            "
    + alias2(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"close","action":"close","text":"{{locale localeKeys.close}}","name":"close"},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":102}}}))
    + "\n            <h2 class=\"modal-title\" id=\"modal-title\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"localeKeys") : depth0)) != null ? lookupProperty(stack1,"confirmChangesTitle") : stack1),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":53},"end":{"line":5,"column":94}}}))
    + "</h2>\n        </div>\n        <div class=\"modal-body command-and-control-list-view\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isApprove") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":12,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isApprove") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":15,"column":23}}})) != null ? stack1 : "")
    + "            <div class=\"grid-region\" data-region=\"gridRegion\"></div>\n        </div>\n        <div class=\"modal-footer\">\n            <button type=\"submit\" class=\"btn btn-primary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"localeKeys") : depth0)) != null ? lookupProperty(stack1,"submit") : stack1),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":58},"end":{"line":19,"column":86}}}))
    + "</button>\n            <button type=\"button\" class=\"btn btn-default\" data-hook=\"cancel-button\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"localeKeys") : depth0)) != null ? lookupProperty(stack1,"cancel") : stack1),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":84},"end":{"line":20,"column":112}}}))
    + "</button>\n        </div>\n    </form>\n</div>\n";
},"useData":true});