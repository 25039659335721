import EntryView from 'common/dynamicPages/views/workflow/entry';
import store from 'system/utilities/cache';

export default EntryView.extend({
    initialize(opts) {
        store.set('current-workspace-route', opts?.lastFragment);
        const options = {
            menuCategory: 'SETUP',
            serviceName: 'tableMaintenance/clientSetting',
            serviceFunc: null,
            businessType: null,
            mode: opts.mode || 'view',
            context: 'MODIFY_CLIENT',
            lastFragment: opts.lastFragment,
        };

        EntryView.prototype.initialize.call(this, options);

        if (this.mode === 'modify') {
            this.getResetButtons();
        }
    },

    modify() {
        this.navigateTo('ADMINSTRATION/modifyClientSettings');
    },

    getResetButtons() {
        this.buttonList.push({
            action: 'RESET',
        });
    },

    reset() {
        this.loadRequiredData();
    },
});
