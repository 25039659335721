import React, {
    useContext, useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import useLocale from '../../hooks/useLocale';
import FormSelectWithOptions from '../FormSelectWithOptions/FormSelectWithOptions';
import RtpContext from '../RtpDrawerContainer/RtpContext';
import styles from './RtpAddToConversationMessage.styles';
import AddNewMessage from '../RtpAddNewMessage/RtpAddNewMessage';

const propTypes = {
    inputType: PropTypes.string.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSend: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    setMessage: PropTypes.func.isRequired,
    reasonType: PropTypes.string,
    setReasonType: PropTypes.func,
    reasonCode: PropTypes.string,
    setReasonCode: PropTypes.func,
    maxLength: PropTypes.number.isRequired,
    rows: PropTypes.number.isRequired,
    invalidErrorMessage: PropTypes.string,
    setInvalidErrorMessage: PropTypes.func,
    isSending: PropTypes.bool,
    errorMessage: PropTypes.string,
    // Internal classes hash - do not pass
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const defaultProps = {
    setReasonType: null,
    setReasonCode: null,
    reasonCode: '',
    reasonType: '',
    invalidErrorMessage: '',
    setInvalidErrorMessage: null,
    isSending: false,
    errorMessage: '',
};

const AddToConversationMessage = ({
    inputType, handleCancel, handleSend, message, setMessage,
    reasonType, setReasonType, reasonCode, setReasonCode,
    maxLength, rows, invalidErrorMessage,
    setInvalidErrorMessage, isSending, errorMessage,
    classes,
}) => {
    const rtpContext = useContext(RtpContext); // get state from RtpContext
    const incorrectRef = useRef();
    const missingRef = useRef();
    const { getLocaleString } = useLocale();
    const [warningMessage, setWarningMessage] = useState('');

    const getPlaceholderText = (mode) => {
        let placeholderText;

        if (mode === 'RFI_MODE') {
            placeholderText = getLocaleString('rtp.placeholderText.enterRequest');
        } else if (mode === 'PA_MODE') {
            placeholderText = getLocaleString('rtp.placeholderText.enterAcknowledgement');
        } else {
            placeholderText = getLocaleString('rtp.placeholderText.enterRequiredResponse');
        }

        return placeholderText;
    };

    useEffect(() => {
        // if response,scroll into view
        if (inputType === 'RESPONSE') {
            const elt = document.querySelector('.newMessage');
            if (elt && elt.scrollIntoView) {
                elt.scrollIntoView();
            }
        }
    }, [inputType]);

    useEffect(() => {
        if (!incorrectRef.current) {
            return;
        }
        const icount = rtpContext.incorrectCount;
        const mcount = rtpContext.missingCount;

        incorrectRef.current.disabled = icount === 10;
        missingRef.current.disabled = mcount === 10;
        if (icount === 10) {
            setReasonType('M');
            missingRef.current.checked = true;
        } else {
            incorrectRef.current.checked = true;
        }
    }, [rtpContext.incorrectCount, rtpContext.missingCount, setReasonType]);

    useEffect(() => {
        if (!reasonType) {
            return;
        }
        const list = (reasonType === 'M') ? rtpContext.missingReasons : rtpContext.incorrectReasons;
        const code = list[0].name;
        setReasonCode(code);
        setWarningMessage(code === 'NARR' ? getLocaleString('rtp.otherErrorMessage') : '');
        setInvalidErrorMessage('');
    }, [reasonType]); // eslint-disable-line

    const handleRequestChoice = (evt) => {
        setReasonType(evt.target.value);
    };

    const handleReqMsgReasonChange = (evt) => {
        const { value } = evt.target;
        setWarningMessage(value === 'NARR' ? getLocaleString('rtp.otherErrorMessage') : '');
        setReasonCode(value);
    };

    if (inputType === 'ACK') {
        return (
            <AddNewMessage
                title={getLocaleString('rtp.paymentAcknowledgement.heading')}
                placeHolderText={getPlaceholderText('PA_MODE')}
                rows={rows}
                maxLength={maxLength}
                message={message}
                setMessage={setMessage}
                isLoading={isSending}
                handleSend={handleSend}
                handleCancel={handleCancel}
                errorMessage={errorMessage}
            />
        );
    }
    if (inputType === 'REQUEST') {
        // TODO extract these into requestMessage component
        return (
            <AddNewMessage
                title={getLocaleString('rtp.requestForInfo.heading')}
                placeHolderText={getPlaceholderText('RFI_MODE')}
                rows={rows}
                maxLength={maxLength}
                message={message}
                setMessage={setMessage}
                isLoading={isSending}
                handleSend={handleSend}
                handleCancel={handleCancel}
                errorMessage={errorMessage}
                invalidErrorMessage={invalidErrorMessage}
                setInvalidErrorMessage={setInvalidErrorMessage}
                warningMessage={warningMessage}
            >
                <div className={classes.requestType}>
                    <div className={classes.requestChoices}>
                        <fieldset>
                            <legend>{getLocaleString('rtp.requestForInfo.type')}</legend>
                            <label>
                                <input
                                    type="radio"
                                    name="REQUESTFORINFO_REASON_FLAG"
                                    value="I"
                                    onClick={handleRequestChoice}
                                    ref={incorrectRef}
                                    data-qa="incorrect-reason"
                                />
                                {getLocaleString('rtp.requestForInfo.incorrect')}
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="REQUESTFORINFO_REASON_FLAG"
                                    value="M"
                                    onClick={handleRequestChoice}
                                    ref={missingRef}
                                    data-qa="missing-reason"
                                />
                                {getLocaleString('rtp.requestForInfo.missing')}
                            </label>
                        </fieldset>
                    </div>
                    <div className={classes.requestTypeReasons}>
                        <fieldset>
                            <legend>{getLocaleString('rtp.requestForInfo.reason')}</legend>
                            <FormSelectWithOptions
                                name="requestReason"
                                value={reasonCode}
                                valueProp="name"
                                labelProp="label"
                                onChange={handleReqMsgReasonChange}
                                list={reasonType === 'I' ? rtpContext.incorrectReasons : rtpContext.missingReasons}
                                accessibilityLabel={getLocaleString('rtp.requestForInfo.selectReason')}
                            />
                        </fieldset>
                    </div>
                </div>
            </AddNewMessage>
        );
    }
    if (inputType === 'RESPONSE') {
        return (
            <AddNewMessage
                title={getLocaleString('rtp.responseToRequestForInfo.heading')}
                placeHolderText={getPlaceholderText('RESPONSE_MODE')}
                rows={rows}
                maxLength={maxLength}
                message={message}
                setMessage={setMessage}
                isLoading={isSending}
                handleSend={handleSend}
                handleCancel={handleCancel}
                errorMessage={errorMessage}
                invalidErrorMessage={invalidErrorMessage}
                setInvalidErrorMessage={setInvalidErrorMessage}
            />
        );
    }
    return null;
};
AddToConversationMessage.propTypes = propTypes;
AddToConversationMessage.defaultProps = defaultProps;

export default withStyles(styles)(AddToConversationMessage);
