import Layout from '@glu/core/src/layout';
import TableMaintApi from 'system/webseries/api/tableMaintenance';
import util from '@glu/core/src/util';
import controlsTmpl from './controls.hbs';

export default Layout.extend({
    initialize() {
        this.listenTo(this.appBus, 'grid:row:action:action_select', this.handleView);
        this.listenTo(this.appBus, 'grid:row:action:action_modify', this.handleModify);
        this.listenTo(this.appBus, 'grid:row:action:action_delete', this.handleRemove);
    },

    template: controlsTmpl,

    /**
     * Refresh component references.
     *
     * @param opts
     */
    refresh(opts) {
        this.grid = opts.grid;
    },

    /**
     * Navigate to create page.
     *
     * It may be useful to create global variables for these paths.
     */
    handleAdd() {
        this.navigateTo('SETUP/alertsListView/create');
    },

    /**
     * Navigate to view page.  Work to be done in a future story.
     *
     * @param e
     */
    handleView() {

    },

    /**
     * Navigate to modify page.  Work to be done in a future story.
     *
     * @param e
     */
    handleModify() {

    },

    /**
     * Remove a single item from the table.
     *
     * @param e
     */
    handleRemove(e) {
        const self = this;

        // call delete service.  We don't yet know what the data should look like here.
        TableMaintApi.removeData().done(() => {
            // remove item from table
            self.grid.collection.remove(e);
        });
    },

    /**
     * Remove multiple items from the table in bulk.
     */
    handleBulkRemove() {
        // get a list of all selected records
        const { grid } = this;

        /*
         * call bulk delete service.  We don't yet know what the data should look like here
         * so just passing in selected rows for now.
         */
        TableMaintApi.bulkRemoveData(grid.getSelectedRows()).done((data) => {
            // remove items from table
            util.each(data.body, (id) => {
                grid.collection.remove(id);
            });
        });
    },
});
