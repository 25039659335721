var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"getPageTitle") || (depth0 != null ? lookupProperty(depth0,"getPageTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"getPageTitle","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":28}}}) : helper)))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"clientAccountName") || (depth0 != null ? lookupProperty(depth0,"clientAccountName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"clientAccountName","hash":{},"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":9,"column":35}}}) : helper))) != null ? stack1 : "")
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"accountNumber") || (depth0 != null ? lookupProperty(depth0,"accountNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountNumber","hash":{},"data":data,"loc":{"start":{"line":9,"column":38},"end":{"line":9,"column":55}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"currencyCode") || (depth0 != null ? lookupProperty(depth0,"currencyCode") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currencyCode","hash":{},"data":data,"loc":{"start":{"line":9,"column":58},"end":{"line":9,"column":74}}}) : helper)))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "            <div data-region=\"accountTransactionBalanceRegion\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" data-action=\"cancel\" href=\"#\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"balanceTrans.back.to.previous",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":144},"end":{"line":3,"column":186}}}))
    + "</span></a>\n    </div>\n    <h2 class=\"landing-header\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"getPageTitle") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":10,"column":15}}})) != null ? stack1 : "")
    + "    </h1>\n</div>\n<div class=\"page transactionDetails\">\n    <div class=\"section section-container\">\n        <div class=\"BalanceTransactionControls col-lg-6 col-md-6\">\n            <div data-region=\"viewAccountsDropdownRegion\"></div>\n            <div class=\"btn-wrapper\">\n                <div class=\"btn-group widget-info-links\">\n                    <span class=\"RefreshTransactionsBtn\" data-hook=\"getRefreshTransactionsControls\"></span>\n                </div>\n                "
    + ((stack1 = (lookupProperty(helpers,"gridUtilitySection")||(depth0 && lookupProperty(depth0,"gridUtilitySection"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"gridUtilityOptions") : depth0),{"name":"gridUtilitySection","hash":{},"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":21,"column":59}}})) != null ? stack1 : "")
    + "\n                </div>\n        </div>\n        <div data-region=\"closeToaRegion\" class=\"close-toa-region\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"displayAccountTransactionBalance") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":27,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        <div class=\"section-body\">\n            <div data-region=\"transactionsGrid\" aria-live=\"polite\"></div>\n        </div>\n        <div class=\"section-footer\">\n            <button type=\"button\" data-action=\"cancel\" class=\"btn btn-primary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":79},"end":{"line":33,"column":105}}}))
    + "</button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});