import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import { $ } from '@glu/core';
import { withStyles } from '@glu/theming';
import NoDataOrLoading from 'pcm/common/components/NoDataOrLoading';
import ErrorMsgPtx from 'pcm/common/components/ErrorMsgPtx';
import LoadingPage from 'components/LoadingPage/LoadingPage';
import { getSobSummaryReport } from './dataUtils';

import styles from './CheckPreview.styles';

class CheckPreview extends React.Component {
    state = {
        error: false,
        isLoading: true,
        pageNumber: 1,
    };

    componentDidMount() {
        const { batchId: ptxBatchId } = this.props;

        getSobSummaryReport(ptxBatchId).then((data) => {
            // PCM-4780 temporary response because ptx still down in responding this.
            if (data && data) {
                const blob = new Blob([data]);
                const url = window.URL.createObjectURL(blob);
                this.setState({
                    isLoading: false,
                    summaryData: data,
                    summaryDataUrl: url,
                }, () => {
                    $('[data-toggle="popover"]').popover();
                });
            }
        }, (err) => {
            const msg = (err instanceof Error) ? err.message : err.statusText;
            this.setState({
                error: msg,
                isLoading: false,
            });
        });
    }

    downloadPdf = () => {
        const binaryData = [];
        const filename = 'ickert-test.pdf';
        binaryData.push(this.state.summaryData);

        const blob = new Blob(binaryData, { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = url;
        a.download = filename;
        window.open(url);
        const isFirefox = typeof InstallTrigger !== 'undefined';
        if (isFirefox) {
            a.setAttribute('type', 'hidden');
            document.body.appendChild(a);
        }
        a.click();

        // for IE
        if (navigator && navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, filename);
        }
        window.URL.revokeObjectURL(url);

        if (isFirefox) {
            a.remove();
        }
    }

    render() {
        const {
            error, isLoading, summaryDataUrl, pageNumber,
        } = this.state;
        const { classes } = this.props;

        return (
            <Fragment>
                {isLoading ? (
                    <LoadingPage />
                ) : (
                    <Fragment>
                        <div className={classes.chekPreviwContainer}>
                            {error && <ErrorMsgPtx title={locale.get('ptx.error.jobsummary')} />}
                            {
                                summaryDataUrl ?
                                    <React.Fragment>
                                        <div className="document-buttons row no-padding">
                                            <div className="pull-left" />
                                            <div
                                                className="pull-right"
                                                data-trigger="hover"
                                                data-container="body"
                                                data-toggle="popover"
                                                data-placement="top"
                                                data-content={locale.get('ptx.check.download')}
                                            >
                                                <button onClick={this.downloadPdf} type="button" className="span-popover-trigger btn btn-tertiary js-btn-download"><span className="icon-download" /></button>
                                            </div>
                                        </div>
                                    </React.Fragment> : (
                                        <div className="text-center">
                                            <NoDataOrLoading
                                                isLoading={isLoading}
                                            />
                                        </div>
                                    )
                            }
                        </div>
                    </Fragment>
                )}

            </Fragment>


        );
    }
}

CheckPreview.propTypes = {
    batchId: PropTypes.string.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(CheckPreview);
