import Collection from '@glu/core/src/collection';
import services from 'services';
import Model from '@glu/core/src/model';
import constants from 'common/dynamicPages/api/constants';
import http from '@glu/core/src/http';
import transform from 'common/util/transform';
import util from '@glu/core/src/util';

const companiesCollection = Collection.extend({
    model: Model,
    filterVal: '',
    rowsPerPage: constants.COMBO_MIN_SIZE,
    startRow: 1,
    hasMorePages: false,
    fieldName: 'USERGROUPNAME',
    inquiryId: 29069,

    /**
     * Set the typeahead filter value to be used in the next read.
     * @param {number} page - page number
     * @param {string} term
     */
    setFilter(page, term) {
        let localTerm = term;
        localTerm = localTerm || '';
        if (localTerm !== this.filterVal || page === 1) {
            this.startRow = 1;
        }
        this.filterVal = localTerm;
        this.rowsPerPage = localTerm.length > 2 ? 50 : 10;
    },

    /**
     * Function to save or get data from server
     * @param {string} method - the current mode
     * @param {object} model - the current model
     * @param {object} options - object containing success and error callbacks
     */
    sync(method, model, options) {
        if (method === 'read') {
            const data = {
                depends: [],
                startRow: this.startRow,
                rowsPerPage: this.rowsPerPage,
                fieldName: this.fieldName,
                typeCode: 'ESTMENT',
                functionCode: 'STMENT',
                productCode: 'GIR',

                requestParameters: {
                    item: [{
                        name: 'INQUIRYID',
                        value: this.inquiryId,
                    }],
                },

                searchFields: [{
                    fieldName: this.fieldName,
                    fieldValue: [this.filterVal],
                    dataType: 'text',
                    operator: 'CONTAINS',
                }],

                searchOrOperator: false,
            };

            http.post(
                services.generateUrl(constants.URL_GETLOOKUPRESULTS_ACTION),
                data,
                (result) => {
                    this.hasMorePages = (this.startRow + result.rows.length) <= result.totalRows;
                    options.success(result);
                    this.startRow += this.rowsPerPage;
                }, (result) => {
                    options.error(result);
                },
            );
        }
    },

    /**
     * Default Function to modify data fetched from service
     * @param  {object} response data from service
     * @returns {object} "key - value pairs" where {"key => label" : "value => numeric value" }
     */
    parse(response) {
        const parsedResponse = response.rows.map(row => row.columns.map((column) => {
            const columnParam = column;
            columnParam.fieldValue = util.unescape(columnParam.fieldValue);
            return columnParam;
        }));
        return parsedResponse.map(row => transform.pairsToHash(row.columns, 'fieldName', 'fieldValue'));
    },
});

export default companiesCollection;
