import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DarkMode, withStyles } from '@glu/theming';
import locale from '@glu/locale';
import {
  ColumnsNextIcon,
  ContainerArrowDownNextIcon,
  FunnelNextIcon,
  PrinterNextIcon,
  ArrowsCirclesIcon
} from '@glu/icons-react';
import { SavedViews } from '@glu/data-components';
import { styles } from './ControlBar.styles';
import Drawer from './Drawer';
import exportGrid from '../../utils/export';
import { showFilters, showColumns, showDivider } from './ControlBars.util';

export function ControlBarComponent(props) {
  const {
    classes,
    data,
    domLayout,
    gridApi,
    gridId,
    hideColumns,
    hideExport,
    hideFilters,
    hidePrint,
    hideDrawerMenu,
    onChange,
    onSavedViewsLoad,
    showSavedViews,
    customPrint,
    syncData,
    lastSync
  } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const [tab, setTab] = useState('columns');
  const displayProps = {
    hideExport,
    hideFilters,
    hidePrint,
    hideColumns,
    hideDrawerMenu
  };
  const handleDrawerToggle = () => {
    setMenuOpen(current => !current);
  };

  const handlePrint = () => {
    if (!gridApi) {
      return;
    }
    gridApi.setDomLayout('print');
    setTimeout(() => {
      window.print();
      gridApi.setDomLayout(domLayout);
    }, 1000);
  };

  return (
    <>
      <div
        className={classes.controlBar}
      >
        <div className={classes.preControls}>
          {showSavedViews
          && (
            <SavedViews
              {...props}
              onLoad={onSavedViewsLoad}
              hasDefaultState
              id={gridId}
              data={data}
              name="savedViews"
              onChange={onChange}
            />
          )
          }
        </div>
        <div className={classes.controls}>
          {(syncData || lastSync) ? (
            <div className={classes.lastSyncWrapper} title={locale.get('lastSyncLabel')}>
              {syncData && (
                <ArrowsCirclesIcon
                  className={classes.lastSyncIcon}
                  onClick={syncData}
                  data-qa="arrow-circles-icon"
                />
              )}
              {lastSync && (
                <div className={classes.syncText} data-qa="last-sync-text">
                  {locale.get('header.lastSync', lastSync)}
                </div>
              )}
            </div>
          ) : null}
          { showFilters(displayProps) && (
            <div title={locale.get('filters')}>
              <FunnelNextIcon
                title={locale.get('filters')}
                className={classes.controlIcon}
                data-qa="filterToggle"
                onClick={() => {
                  handleDrawerToggle();
                  setTab('filters');
                }}
                actionable
              />
            </div>
          )}
          { showColumns(displayProps) && (
            <div title={locale.get('columns')}>
              <ColumnsNextIcon
                title={locale.get('columns')}
                className={classes.controlIcon}
                data-qa="columnsToggle"
                onClick={() => {
                  handleDrawerToggle();
                  setTab('columns');
                }}
                actionable
              />
            </div>
          )}
          { showDivider(displayProps) && (
            <div className={classes.divider} />
          )}
          <div className={classes.controlGroup}>
            {(!hidePrint && !customPrint) && (
              <div title={locale.get('print')}>
                <PrinterNextIcon
                  title={locale.get('print')}
                  className={classes.controlIcon}
                  data-qa="printButton"
                  onClick={handlePrint}
                  actionable
                />
              </div>
            )}
            {(!hidePrint && customPrint) && customPrint(gridApi)}
            {!hideExport
            && (
              <div title={locale.get('export')}>
                <ContainerArrowDownNextIcon
                  title={locale.get('export')}
                  data-qa="exportButton"
                  className={classes.controlIcon}
                  onClick={() => exportGrid(props)}
                  actionable
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <DarkMode>
        <Drawer
          menuOpen={menuOpen}
          onDrawerToggle={handleDrawerToggle}
          activeTab={tab}
          onTabClick={setTab}
          hideColumns={hideColumns}
          {...props}
        />
      </DarkMode>
    </>
  );
}

ControlBarComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  data: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        colId: PropTypes.string
      })
    ),
    filters: PropTypes.arrayOf(PropTypes.shape({})),
    grid: PropTypes.shape({}),
    sort: PropTypes.arrayOf(
      PropTypes.shape({
        colId: PropTypes.string,
        sort: PropTypes.oneOf(['asc', 'desc'])
      })
    ),
    pageSize: PropTypes.number,
    page: PropTypes.number,
    savedViews: PropTypes.shape({})
  }).isRequired,
  domLayout: PropTypes.oneOf(['autoHeight', 'normal', 'print']),
  exportActivePageOnly: PropTypes.bool,
  exportColumnConversion: PropTypes.func,
  // Created by Ag Grid
  gridApi: PropTypes.shape({
    setDomLayout: PropTypes.func
  }),
  gridId: PropTypes.string.isRequired,
  hideColumns: PropTypes.bool,
  hideExport: PropTypes.bool,
  hideFilters: PropTypes.bool,
  hidePrint: PropTypes.bool,
  /** Hide display of the drawer containing both filters and columns */
  hideDrawerMenu: PropTypes.bool,
  showSavedViews: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSavedViewsLoad: PropTypes.func,
  customPrint: PropTypes.func,
  syncData: PropTypes.func,
  lastSync: PropTypes.string
};

ControlBarComponent.defaultProps = {
  domLayout: 'autoHeight',
  exportActivePageOnly: false,
  exportColumnConversion: null,
  gridApi: null,
  hideColumns: false,
  hideExport: false,
  hideFilters: false,
  hidePrint: false,
  hideDrawerMenu: false,
  syncData: null,
  lastSync: '',
  onSavedViewsLoad() {
  },
  customPrint: null
};

export default withStyles(styles)(ControlBarComponent);
