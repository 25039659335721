import services from 'services';
import { postData } from 'common/util/services';

export default {
    generateData(tnum) {
        return {
            requestHeader: {
                queryOffset: 0,
                queryPagesize: 0,
                requestId: '',
            },

            IncludeMapData: 1,

            queryCriteria: {
                action: {
                    typeCode: 'PASSTHRU',
                    entryMethod: 0,
                    actionMode: 'SELECT',
                    productCode: 'USACH',
                    functionCode: 'BATCH',
                },

                customFilters: [{
                    filterName: 'Depends',
                    filterParam: ['SURROGATETNUM', tnum],
                }],

                fieldName: false,
                inquiryId: 17249,
                queryType: 'Query',
            },

            formatterType: 'Query',
        };
    },

    send(tnum) {
        const data = this.generateData(tnum);
        return postData(services.inquiryQueryResults, data)
            .then(response => response.queryResponse.QueryData.queryRows);
    },
};
