import EntryView from 'app/administration/common/dynamicPages/views/workflow/entry';
import DataApi from 'common/dynamicPages/api/data';
import gridApi from 'common/dynamicPages/api/grid';
import errorHandlers from 'system/error/handlers';
import $ from 'jquery';
import util from '@glu/core/src/util';
import Dialog from '@glu/dialog';
import Model from '@glu/core/src/model';
import PaymentsTransitionsView from 'app/administration/views/paymentManagement/paymentsTransitionsView';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import http from '@glu/core/src/http';
import services from 'services';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import alert from '@glu/alerts';
import transform from 'common/util/transform';
import PayUtil from 'common/util/paymentUtil';

export default EntryView.extend({
    initialize(opts) {
        const options = {
            menuCategory: 'REPORTING',
            serviceName: 'adminPayment/listView/payments',
            serviceFunc: null,
            businessType: null,
            context: 'PAY_LIST_VIEW',
            mode: 'view',
        };

        if (opts.isSubPayment) {
            options.subPaymentContextKey = 'adminPayment_File_getListViewDetails';
        }
        EntryView.prototype.initialize.call(this, options);
        const keyOverride = store.remove('detailsViewKeyOverride');
        if (keyOverride) {
            this.contextDef.keyOverride = keyOverride;
        }
    },

    events: {
        'click [data-action="transition"]': 'initiateTransition',
    },

    initiateTransition() {
        const self = this;
        const paymentsTransitionsView = new PaymentsTransitionsView({
            transitions: this.transitions,
            status: this.tempModel.get('STATUS_DESCRIPTION'),
            model: new Model(util.pick(this.storedModel.attributes, 'TNUM', 'PRODUCT', 'FUNCTION', 'TYPE')),
        });

        this.listenTo(paymentsTransitionsView, 'resolve', (data) => {
            self.actionSuccess(data);
        });

        Dialog.open(paymentsTransitionsView);
    },

    submitTransition(action) {
        const jsonData = this.getJSONData();

        http.post(services.generateUrl(`transitionsMaint/${action}`), jsonData, (data) => {
            this.actionSuccess(data);
        }, (data) => {
            this.actionError(data);
        });
    },

    actionSuccess(confirmResponse) {
        let { contextKey } = this;
        let alertMessage;

        if (this.contextDef.keyOverride) {
            contextKey = this.contextDef.keyOverride;
        }

        if (confirmResponse.resultType === 'WARNING') {
            alertMessage = {
                message: confirmResponse.confirms.confirmResults[0].messages[0],
                type: confirmResponse.resultType,
            };
            store.set('template_listView_corp-alertMessage', alertMessage);
        } else if (confirmResponse.resultType === 'DELEGATERESULT') {
            alertMessage = {
                message: confirmResponse.confirms.confirmResults[0].messages,
                type: confirmResponse.resultType,
            };
            store.set(`${this.contextKey}-alertMessage`, alertMessage);
        } else {
            store.set(`${contextKey}-alertMessage`, this.mode.toUpperCase());
            store.set(`${contextKey}-confirms`, confirmResponse);
        }

        // if we're in a stackview, render our notification instead of navigating away
        if (this.stack && this.stack.stack && this.cid === this.stack.stack[1].view.cid) {
            this.notify(this.mode.toUpperCase(), confirmResponse);
        } else {
            this.navigateTo(this.getListUrl());
        }
    },

    /**
     * @override
     * entry.js getListUrl uses this.contextKey, here we need to see if the keyOverride
     * is set and use that if it is
     */
    getListUrl() {
        const contextKey = this.contextDef.keyOverride || this.contextKey;
        const r = store.get(`${contextKey}-listRoute`);
        return r || this.contextModel.getListUrl();
    },

    notify(action, confirmResponse, errorCount) {
        /*
         * create the confirmation view for the alert
         * skip if action is null.  This occurs when first entering the payment workspace
         * because the onRender is called.
         */
        if ((action === null || action === undefined)
            && (confirmResponse === null || confirmResponse === undefined)) {
            // TODO: Refactor out empty block
        } else if (confirmResponse === null || confirmResponse === undefined) {
            if ((action.type === 'WARNING' || action.type === 'DELEGATERESULT') && action.message) {
                this.alertView = alert.warning(action.message);
                this.alertRegion.show(this.alertView);
            }
        } else {
            const confirm = typeof confirmResponse === 'object' ? confirmResponse.confirms : null;
            const successCount = confirm ? confirm.totalSuccess : 0;
            const failCount = confirm ? confirm.totalFail : errorCount;
            const totalCount = failCount + successCount;
            const success = successCount === totalCount;
            const alertFunc = success ? alert.success : alert.danger;
            const chainedMessages = typeof confirmResponse === 'object' ? confirmResponse.message : null;
            const message = typeof confirmResponse === 'object' && confirmResponse.message.length > 0 ? confirmResponse.message[chainedMessages.length - 1] : action;

            const confirms = new Confirms({
                confirms: confirmResponse ? confirmResponse.confirms : null,
            });

            /*
             * display notification message
             * this.alertView = alertFunc(message, {
             */
            this.stack.stack[0].view.alertView = alertFunc(
                message,
                {
                    details: confirmResponse
                        && confirmResponse.confirms.confirmResults
                        && confirmResponse.confirms.confirmResults[0].confirmData[0].item
                        ? confirms : null,
                    canDismiss: !!confirmResponse,
                    animate: true,
                },
            );

            if (message && this.stack && this.stack.stack.length) {
                // Show the notification on our first stackview
                this.stack.stack[0].view.alertRegion.show(this.stack.stack[0].view.alertView);
                // Return to our first stackview
                this.navigateTo(this.getListUrl());
            }
        }
    },

    reject() {
        this.submitTransition('reject');
    },

    approve() {
        this.submitTransition('approve');
    },

    getJSONData() {
        return {
            item: {
                item: transform.hashToPairs({
                    TNUM: this.storedModel.get('TNUM'),
                    PAYMENT_PRODUCT: this.storedModel.get('PRODUCT'),
                    PAYMENT_FUNCTION: this.storedModel.get('FUNCTION'),
                    PAYMENT_TYPE: this.storedModel.get('TYPE'),
                }),
            },
        };
    },

    loadRequiredData() {
        const self = this;

        if (this.mode === 'insert' || this.mode === 'import') {
            this.setHasLoadedRequiredData(true);
            this.render();
        } else {
            this.contextDef.serviceName = 'adminPayment/listView/payments';
            this.localeKey = 'adminPayment_';

            const contextKeyToUse = this.options.subPaymentContextKey || this.contextKey;
            DataApi.model.generateModelFromModel(store.get(`${contextKeyToUse}-actionModel`))
                .then((newModelParam) => {
                    const newModel = newModelParam;
                    newModel.set({
                        FUNCTIONCODE: newModel.get('FUNCTION'),
                        PRODUCTCODE: newModel.get('PRODUCT'),
                        TYPECODE: newModel.get('TYPE'),
                    });

                    newModel.key.FUNCTIONCODE = 'true';
                    newModel.key.PRODUCTCODE = 'true';
                    newModel.key.TYPECODE = 'true';
                    newModel.key.SUBTYPE = 'true';

                    newModel.convertModelAttributesToKeyJSON = util.bind(
                        self.convertModelAttributesToKeyJSON,
                        newModel,
                    );

                    newModel.fetch({
                        success() {
                            self.storedModel = newModel;
                            self.setEntryDescription(self.storedModel);
                            self.options.smbOverride = PayUtil.isReimburse(self.storedModel);
                            self.setHasLoadedRequiredData(true);
                            self.setupTransitionTrackingGrid();

                            /*
                             * Cancel button is added by default.
                             * Removing it since it is not needed
                             */
                            self.buttonList = [];
                            const actions = newModel.get('ACTIONLIST');
                            if (actions) {
                                if (actions.indexOf('INSERT') >= 0 && newModel.get('STATUS') !== 'TP' && newModel.get('TRANSITIONS') && newModel.get('TRANSITIONS').length > 0) {
                                    self.transitions = JSON.parse(newModel.get('TRANSITIONS').replace(/&quot;/g, '"'));
                                    self.buttonList.push({
                                        action: 'TRANSITION',
                                    });
                                }
                                if (newModel.get('STATUS') === 'TP') {
                                    if (actions.indexOf('APPROVE') >= 0) {
                                        self.buttonList.push({
                                            action: 'APPROVE',
                                        });
                                    }
                                    if (actions.indexOf('REJECT') >= 0) {
                                        self.buttonList.push({
                                            action: 'REJECT',
                                        });
                                    }
                                }
                            }
                            if (self.mode === 'view' && self.options.context === 'PAY_LIST_VIEW') {
                                self.storedModel.set('INDICATIVE_RATE', self.indicativeRate());
                            }
                            self.render();
                        },

                        error: util.bind(errorHandlers.loading, self),
                    });
                });
        }
    },

    // Indicative rate value for international wire transfer
    indicativeRate() {
        let ret = ' ';
        const creditCurr = this.storedModel.get('CREDIT_CURRENCY');
        const debitCurr = this.storedModel.get('DEBIT_CURRENCY');
        const exchangeRate = this.storedModel.get('EXCHANGE_RATE');
        let indicativeAmount = this.storedModel.get('INDICATIVEAMOUNT');
        let transAmount = this.storedModel.get('TRANSACTION_AMOUNT');

        if (indicativeAmount !== null) {
            indicativeAmount = indicativeAmount.trim().replace(/,/g, '');
        }
        if (transAmount !== null) {
            transAmount = transAmount.trim().replace(/,/g, '');
        }
        /*
         * getting the rate if credit and debit currencies are different
         * AND indicative and transaction amounts are set
         * AND exchange rate is not used
         */
        if (creditCurr !== debitCurr
            && !util.isEmpty(indicativeAmount)
            && !util.isEmpty(transAmount)
            && util.isEmpty(exchangeRate)
            && (indicativeAmount * 1 > 0)) {
            ret = `${((transAmount * 1) / (indicativeAmount * 1)).toFixed(6)}`;
        }
        return ret;
    },

    /*
     * We have to override this method to prevent the read request from having a nested
     * item array
     */
    convertModelAttributesToKeyJSON(model) {
        return this.convertModelAttributesToKeyWithOptContextJSON(model);
    },

    onRender() {
        EntryView.prototype.onRender.call(this);
        if (this.hasLoadedRequiredData() && this.transitionTrackingAuditGrid) {
            this.customRegion.show(this.transitionTrackingAuditGrid);
        }
    },

    gridLoadComplete() {
        const customRegionDiv = $('#customRegionDiv');
        if (this.transitionTrackingAuditGrid
                && this.transitionTrackingAuditGrid.wrapper.rows.totalCount === 0) {
            customRegionDiv.empty();
        } else {
            const id = util.uniqueId();
            customRegionDiv.addClass('section section-container');
            customRegionDiv.prepend(`<div class="panel panel-tertiary"><div class="panel-heading" role="tab" id="collapseHeading${id}"><h4 class="panel-title"><a role="button" data-toggle="collapse" href="#collapseArea${id}" aria-expanded="true" aria-controls="collapseArea${id}"><span class="indicator-icon"></span> ${locale.get('transition.auditgrid.title')} </a></h4></div><div id="collapseArea${id}" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="collapseHeading${id}"><div class="panel-body"></div></div></div>`);
            customRegionDiv.find('> div:last-child').appendTo(customRegionDiv.find('> .panel .panel-body'));
        }
    },

    setupTransitionTrackingGrid() {
        const data = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    action: {
                        typeCode: 'PAYMENTS',
                        productCode: 'ADMPAY',
                        functionCode: 'INST',
                        actionMode: 'SELECT',
                    },

                    searchType: '5',
                    inquiryId: 31000,
                },
            },
        };

        this.customFilters = [{
            filterName: 'Depends',
            filterParam: ['TNUM', this.storedModel.get('TNUM')],
        }];

        data.inquiryRequest.searchCriteria.customFilters = this.customFilters;

        const contextLocal = {
            actionMode: 'SELECT',
            displayOrder: 1,
            functionCode: 'INST',
            inquiryId: 31000,
            gridId: 0,
            nonDashboardDisplay: 0,
            productCode: 'ADMPAY',
            typeCode: 'PAYMENTS',
        };

        const options = {
            context: contextLocal,
            filter: false,
            selector: 'none',
            hideButtons: true,

            // default grid action buttons are hidden
            hideGridActionButtons: true,

            enableRowContextButtonCallbacks: true,
            inquirySearchCriteria: data.inquiryRequest.searchCriteria,
            lvc: this.lvc,
        };

        this.transitionTrackingAuditGrid = gridApi.createInquiryGridView(options);
        this.listenTo(this.transitionTrackingAuditGrid, 'gridapi:loaded', this.gridLoadComplete);
    },
});
