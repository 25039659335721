export default ({
    'PAYMENTS/addPayment': 'addPayment',
    'PAYMENTS/viewLoanPayment': 'viewPayment',
    'PAYMENTS/modifyLoanPayment': 'loadExistingPayment',
    'TEMPLATE/addTemplate': 'addTemplate',
    'TEMPLATE/viewLoanTemplate': 'viewTemplate',
    'TEMPLATE/modifyLoanTemplate': 'loadExistingTemplate',
    'TEMPLATE/restoreLoanTemplate': 'loadExistingTemplate',
    'BANK_ACC_INFO/transactionDetails': 'viewTransaction',
});
