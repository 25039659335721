import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { AccessibilityText } from '@glu/utilities-react';

const propTypes = {
    name: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    valueProp: PropTypes.string.isRequired,
    labelProp: PropTypes.string.isRequired,
    label: PropTypes.string,
    accessibilityLabel: PropTypes.string,
};

const defaultProps = {
    value: '',
    onChange: null,
    label: '',
    accessibilityLabel: '',
};

const FormSelectWithOptions = ({
    name, list, value, onChange, valueProp, labelProp, label, accessibilityLabel,
}) => (
    <>
        {accessibilityLabel
        && <AccessibilityText>{accessibilityLabel}</AccessibilityText>}
        {label
        && <label htmlFor={name}>{label}</label>}
        <select name={name} value={value} onChange={onChange} data-qa="select-with-options">
            { list.map(item => (
                <option key={item[valueProp]} value={item[valueProp]}>{item[labelProp]}</option>))}
        </select>
    </>

);

FormSelectWithOptions.propTypes = propTypes;
FormSelectWithOptions.defaultProps = defaultProps;

export default FormSelectWithOptions;
