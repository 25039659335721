var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"viewDisclosure\"> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"RTGS.INST.INTL.*.VIEWDISCLOSURE.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":85},"end":{"line":8,"column":135}}}))
    + "</button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<legend>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"allReports.Payment_Details",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":33},"end":{"line":10,"column":72}}}))
    + "</legend>";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"form-group\">\n                <label for=\"payee\" class=\"main-label\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"payeeText") || (depth0 != null ? lookupProperty(depth0,"payeeText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"payeeText","hash":{},"data":data,"loc":{"start":{"line":14,"column":54},"end":{"line":14,"column":67}}}) : helper)))
    + "</label>\n                <div> <span id=\"payee\" name=\"BENE_NAME\" data-text=\"BENE_NAME\" data-bind=\"model\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"BENE_NAME") || (depth0 != null ? lookupProperty(depth0,"BENE_NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BENE_NAME","hash":{},"data":data,"loc":{"start":{"line":15,"column":96},"end":{"line":15,"column":109}}}) : helper)))
    + "</span> </div>\n            </div>\n\n            <div class=\"form-group\">\n                <label for=\"payee-account\" class=\"main-label\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"accountText") || (depth0 != null ? lookupProperty(depth0,"accountText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountText","hash":{},"data":data,"loc":{"start":{"line":19,"column":62},"end":{"line":19,"column":77}}}) : helper)))
    + "</label>\n                <div><span id=\"payee-account\" name=\"BENE_ACCOUNT\">"
    + alias4((lookupProperty(helpers,"maskAccount")||(depth0 && lookupProperty(depth0,"maskAccount"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"BENE_ACCOUNT") : depth0),{"name":"maskAccount","hash":{},"data":data,"loc":{"start":{"line":20,"column":66},"end":{"line":20,"column":94}}}))
    + "</span></div>\n            </div>\n\n            <div class=\"form-group\">\n                <label for=\"payment-type\" class=\"main-label\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"deliveryMethodText") || (depth0 != null ? lookupProperty(depth0,"deliveryMethodText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"deliveryMethodText","hash":{},"data":data,"loc":{"start":{"line":24,"column":61},"end":{"line":24,"column":83}}}) : helper)))
    + "</label>\n                <div><span id=\"payment-type\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"typeDescription") || (depth0 != null ? lookupProperty(depth0,"typeDescription") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typeDescription","hash":{},"data":data,"loc":{"start":{"line":25,"column":45},"end":{"line":25,"column":64}}}) : helper)))
    + "</span></div>\n            </div>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"form-group\">\n                <label for=\"payee\" class=\"main-label\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"payeeText") || (depth0 != null ? lookupProperty(depth0,"payeeText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"payeeText","hash":{},"data":data,"loc":{"start":{"line":29,"column":54},"end":{"line":29,"column":67}}}) : helper)))
    + "</label>\n                <select id=\"payee\" class=\"form-control\" data-hook=\"payee-select\" data-bind=\"model\" name=\"BENE_NAME\">\n                    <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"payeeNotApproved") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.program(10, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":32,"column":20},"end":{"line":38,"column":27}}})) != null ? stack1 : "")
    + "                </select>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_NAME\"></span>\n            </div>\n\n            <div class=\"form-group\">\n                <label for=\"payee-account\" class=\"main-label\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"accountText") || (depth0 != null ? lookupProperty(depth0,"accountText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountText","hash":{},"data":data,"loc":{"start":{"line":44,"column":62},"end":{"line":44,"column":77}}}) : helper)))
    + "</label>\n                <select id=\"payee-account\" class=\"form-control\" data-hook=\"payee-account-select\" data-bind=\"model\" name=\"BENE_ACCOUNT\">\n                    <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"payeeNotApproved") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.program(15, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":47,"column":20},"end":{"line":53,"column":27}}})) != null ? stack1 : "")
    + "                </select>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_ACCOUNT\"></span>\n            </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPayment") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":12},"end":{"line":77,"column":19}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"CMB_BENE_NAME") || (depth0 != null ? lookupProperty(depth0,"CMB_BENE_NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CMB_BENE_NAME","hash":{},"data":data,"loc":{"start":{"line":33,"column":39},"end":{"line":33,"column":56}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"CMB_BENE_NAME") || (depth0 != null ? lookupProperty(depth0,"CMB_BENE_NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CMB_BENE_NAME","hash":{},"data":data,"loc":{"start":{"line":33,"column":58},"end":{"line":33,"column":75}}}) : helper)))
    + "</option>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"payeeList") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":24},"end":{"line":37,"column":33}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"BENE_NAME") || (depth0 != null ? lookupProperty(depth0,"BENE_NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BENE_NAME","hash":{},"data":data,"loc":{"start":{"line":36,"column":43},"end":{"line":36,"column":56}}}) : helper)))
    + "\" data-item=\"\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"BENE_NAME") || (depth0 != null ? lookupProperty(depth0,"BENE_NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BENE_NAME","hash":{},"data":data,"loc":{"start":{"line":36,"column":71},"end":{"line":36,"column":84}}}) : helper)))
    + "</option>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <option value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"BENE_ACCOUNT") || (depth0 != null ? lookupProperty(depth0,"BENE_ACCOUNT") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"BENE_ACCOUNT","hash":{},"data":data,"loc":{"start":{"line":48,"column":39},"end":{"line":48,"column":55}}}) : helper)))
    + "\">"
    + alias3((lookupProperty(helpers,"maskAccount")||(depth0 && lookupProperty(depth0,"maskAccount"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"BENE_ACCOUNT") : depth0),{"name":"maskAccount","hash":{},"data":data,"loc":{"start":{"line":48,"column":57},"end":{"line":48,"column":85}}}))
    + "</option>\n";
},"15":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"accountNumberList") : depth0),{"name":"each","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":24},"end":{"line":52,"column":33}}})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <option value=\""
    + alias1(container.lambda(depth0, depth0))
    + "\">"
    + alias1(((depths[1] && lookupProperty(depths[1],"maskAccount"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"../maskAccount","hash":{},"data":data,"loc":{"start":{"line":51,"column":53},"end":{"line":51,"column":76}}}))
    + "</option>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"form-group\">\n                    <label for=\"payment-type\" class=\"main-label\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"deliveryMethodText") || (depth0 != null ? lookupProperty(depth0,"deliveryMethodText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"deliveryMethodText","hash":{},"data":data,"loc":{"start":{"line":60,"column":65},"end":{"line":60,"column":87}}}) : helper)))
    + "</label>\n                    <select id=\"payment-type\" class=\"form-control\" data-hook=\"payment-type-select\" data-bind=\"model\" name=\"PAYMENTTYPE\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fromRTPWorkspace") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":136},"end":{"line":61,"column":175}}})) != null ? stack1 : "")
    + ">\n                        <option value=\"\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fromRTPWorkspace") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":41},"end":{"line":62,"column":100}}})) != null ? stack1 : "")
    + "</option>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"payeeNotApproved") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":63,"column":24},"end":{"line":73,"column":31}}})) != null ? stack1 : "")
    + "                    </select>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"PAYMENTTYPE\"></span>\n                </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"21":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"RTP.type.crtran",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":62,"column":65},"end":{"line":62,"column":93}}}));
},"23":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"PAYMENTTYPE") || (depth0 != null ? lookupProperty(depth0,"PAYMENTTYPE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"PAYMENTTYPE","hash":{},"data":data,"loc":{"start":{"line":64,"column":43},"end":{"line":64,"column":58}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"TYPE_DESCRIPTION") || (depth0 != null ? lookupProperty(depth0,"TYPE_DESCRIPTION") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TYPE_DESCRIPTION","hash":{},"data":data,"loc":{"start":{"line":64,"column":60},"end":{"line":64,"column":80}}}) : helper)))
    + "</option>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"fromRTPWorkspace") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":66,"column":28},"end":{"line":72,"column":35}}})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <option value=\"CRTRAN\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"RTP.type.crtran",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":67,"column":55},"end":{"line":67,"column":83}}}))
    + "</option>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"deliveryMethods") : depth0),{"name":"each","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":32},"end":{"line":71,"column":41}}})) != null ? stack1 : "");
},"29":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"paymentType") || (depth0 != null ? lookupProperty(depth0,"paymentType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"paymentType","hash":{},"data":data,"loc":{"start":{"line":70,"column":51},"end":{"line":70,"column":66}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"typeDescription") || (depth0 != null ? lookupProperty(depth0,"typeDescription") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typeDescription","hash":{},"data":data,"loc":{"start":{"line":70,"column":68},"end":{"line":70,"column":87}}}) : helper)))
    + "</option>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "             <div class=\"form-group\">\n                <label for=\"REMITTANCE_METHOD\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTP.INST.CRTRAN.*.REMITTANCE_METHOD",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":82,"column":47},"end":{"line":82,"column":95}}}))
    + "</label>\n                <select class=\"form-control\" data-hook=\"remittance-method\" data-bind=\"model\" id=\"REMITTANCE_METHOD\" name=\"REMITTANCE_METHOD\">\n                    <option value=\"\"></option>\n                    <option value=\"EMAL\" data-item=\"\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTP.REQUEST.REQOUT.*.REMITTANCE_METHOD.1",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":85,"column":54},"end":{"line":85,"column":107}}}))
    + "</option>\n                    <option value=\"URID\" data-item=\"\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTP.REQUEST.REQOUT.*.REMITTANCE_METHOD.2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":86,"column":54},"end":{"line":86,"column":107}}}))
    + "</option>\n                </select>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"REMITTANCE_METHOD\"></span>\n            </div>\n            <div class=\"form-group\">\n                <label for=\"REMITTANCE_ADDRESS\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTP.INST.CRTRAN.*.REMITTANCE_ADDRESS",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":91,"column":48},"end":{"line":91,"column":97}}}))
    + "</label>\n                <input class=\"form-control\" data-hook=\"remittance-address\" data-bind=\"model\" id=\"REMITTANCE_ADDRESS\" maxlength=\"2048\" name=\"REMITTANCE_ADDRESS\" type=\"text\" value=\"\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"REMITTANCE_ADDRESS\"></span>\n            </div>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div data-hook=\"payment-details\">\n                <div class=\"form-group\">\n                    <label for=\"OBI_TEXT_1\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.paymentDetailLine1",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":100,"column":44},"end":{"line":100,"column":86}}}))
    + "</label>\n                    <input class=\"form-control\" data-bind=\"model\" id=\"OBI_TEXT_1\" maxlength=\"35\" name=\"OBI_TEXT_1\" type=\"text\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"OBI_TEXT_1\"></span>\n                </div>\n                <div class=\"form-group\">\n                    <label for=\"OBI_TEXT_2\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.paymentDetailLine2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":105,"column":44},"end":{"line":105,"column":86}}}))
    + "</label>\n                    <input class=\"form-control\" data-bind=\"model\" id=\"OBI_TEXT_2\" maxlength=\"35\" name=\"OBI_TEXT_2\" type=\"text\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"OBI_TEXT_2\"></span>\n                </div>\n                <div class=\"form-group\">\n                    <label for=\"OBI_TEXT_3\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.paymentDetailLine3",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":110,"column":44},"end":{"line":110,"column":86}}}))
    + "</label>\n                    <input class=\"form-control\" data-bind=\"model\" id=\"OBI_TEXT_3\" maxlength=\"35\" name=\"OBI_TEXT_3\" type=\"text\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"OBI_TEXT_3\"></span>\n                </div>\n                <div class=\"form-group\">\n                    <label for=\"OBI_TEXT_4\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.paymentDetailLine4",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":115,"column":44},"end":{"line":115,"column":86}}}))
    + "</label>\n                    <input class=\"form-control\" data-bind=\"model\" id=\"OBI_TEXT_4\" maxlength=\"35\" name=\"OBI_TEXT_4\" type=\"text\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"OBI_TEXT_4\"></span>\n                </div>\n            </div>\n";
},"35":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"form-group additional-comments-group\">\n                <label for=\"REMITTANCE_INFO\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"SMBPAY.remittanceInfo",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":124,"column":64},"end":{"line":124,"column":98}}}))
    + "</label>\n                <textarea rows=\"2\" data-hook=\"remittance-text\" name=\"REMITTANCE_INFO\" data-bind=\"model\" id=\"REMITTANCE_INFO\" class=\"form-control\" maxlength=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"remittanceMaxSize") || (depth0 != null ? lookupProperty(depth0,"remittanceMaxSize") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"remittanceMaxSize","hash":{},"data":data,"loc":{"start":{"line":125,"column":157},"end":{"line":125,"column":178}}}) : helper)))
    + "\"></textarea>\n                <div class=\"char-counter pull-right\" id=\"char-counter\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.charactersRemaining",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":126,"column":71},"end":{"line":126,"column":114}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"remittanceMaxSize") || (depth0 != null ? lookupProperty(depth0,"remittanceMaxSize") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"remittanceMaxSize","hash":{},"data":data,"loc":{"start":{"line":126,"column":115},"end":{"line":126,"column":136}}}) : helper)))
    + "</div>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"REMITTANCE_INFO\"></span>\n            </div>\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <form class=\"payment-audit-history\">\n        <fieldset>\n            <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"allReports.Payment_Audit_History",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":140,"column":20},"end":{"line":140,"column":65}}}))
    + "</legend>\n            <div class=\"form-group\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"allReports.Entry_Method",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":142,"column":23},"end":{"line":142,"column":59}}}))
    + "</label>\n                <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"entryMethodName") || (depth0 != null ? lookupProperty(depth0,"entryMethodName") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"entryMethodName","hash":{},"data":data,"loc":{"start":{"line":143,"column":47},"end":{"line":143,"column":66}}}) : helper)))
    + "</p>\n            </div>\n            <div class=\"form-group\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"allReports.Status",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":146,"column":23},"end":{"line":146,"column":53}}}))
    + "</label>\n                <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"STATUS_DESCRIPTION") || (depth0 != null ? lookupProperty(depth0,"STATUS_DESCRIPTION") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"STATUS_DESCRIPTION","hash":{},"data":data,"loc":{"start":{"line":147,"column":47},"end":{"line":147,"column":69}}}) : helper)))
    + "</p>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"APPROVER_REJECTION_REASON") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":149,"column":8},"end":{"line":154,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"REJECTION_REASON") : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":155,"column":8},"end":{"line":160,"column":15}}})) != null ? stack1 : "")
    + "\n     <div class=\"panel panel-tertiary audit-history-wrapper\">\n        <div class=\"panel-heading\" role=\"tab\" id=\"audit-history-tab\">\n          <h3 class=\"panel-title\">\n            <a role=\"button\" data-toggle=\"collapse\" href=\"#audit-history-panel\" aria-expanded=\"true\" aria-controls=\"audit-history-panel\">\n                <span class=\"indicator-icon\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"allReports.Payment_History_Details",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":166,"column":53},"end":{"line":166,"column":100}}}))
    + "\n            </a>\n          </h3>\n        </div>\n        <div id=\"audit-history-panel\" class=\"panel-collapse collapse\" role=\"tabpanel\" aria-labelledby=\"audit-history-tab\" aria-expanded=\"false\">\n          <div class=\"panel-body\">\n            <table class=\"table audit-history-tbl\">\n                <thead>\n                    <tr>\n                        <th scope=\"col\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"allReports.Changed_On",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":175,"column":40},"end":{"line":175,"column":74}}}))
    + "</th>\n                        <th scope=\"col\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"allReports.Action",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":176,"column":40},"end":{"line":176,"column":70}}}))
    + "</th>\n                        <th scope=\"col\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"allReports.Company_ID",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":177,"column":40},"end":{"line":177,"column":74}}}))
    + "</th>\n                        <th scope=\"col\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"allReports.User_ID",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":178,"column":40},"end":{"line":178,"column":71}}}))
    + "</th>\n                        <th scope=\"col\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"allReports.User_Name",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":179,"column":40},"end":{"line":179,"column":73}}}))
    + "</th>\n                        <th scope=\"col\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"allReports.Change",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":180,"column":40},"end":{"line":180,"column":70}}}))
    + "</th>\n                    </tr>\n                </thead>\n                <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"auditModelData") : depth0),{"name":"each","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":184,"column":20},"end":{"line":198,"column":29}}})) != null ? stack1 : "")
    + "                </tbody>\n            </table>\n          </div>\n        </div>\n    </div>\n        </fieldset>\n    </form>\n\n";
},"38":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"form-group\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.approverejectreason",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":151,"column":23},"end":{"line":151,"column":63}}}))
    + "</label>\n                <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"APPROVER_REJECTION_REASON") || (depth0 != null ? lookupProperty(depth0,"APPROVER_REJECTION_REASON") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"APPROVER_REJECTION_REASON","hash":{},"data":data,"loc":{"start":{"line":152,"column":47},"end":{"line":152,"column":76}}}) : helper)))
    + "</p>\n            </div>\n";
},"40":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"form-group\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.*.rejection_reason",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":157,"column":23},"end":{"line":157,"column":59}}}))
    + "</label>\n                <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"REJECTION_REASON") || (depth0 != null ? lookupProperty(depth0,"REJECTION_REASON") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"REJECTION_REASON","hash":{},"data":data,"loc":{"start":{"line":158,"column":47},"end":{"line":158,"column":67}}}) : helper)))
    + "</p>\n            </div>\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <tr>\n                        <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"changedTs") : depth0), depth0))
    + "</td>\n                        <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"action") : depth0), depth0))
    + "</td>\n                        <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"companyId") : depth0), depth0))
    + "</td>\n                        <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"userId") : depth0), depth0))
    + "</td>\n                        <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"userName") : depth0), depth0))
    + "</td>\n\n                        <td class=\"change\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"change") : depth0),{"name":"each","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":193,"column":28},"end":{"line":195,"column":37}}})) != null ? stack1 : "")
    + "                        </td>\n                    </tr>\n";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":194,"column":41},"end":{"line":194,"column":73}}})) != null ? stack1 : "")
    + "\n";
},"44":function(container,depth0,helpers,partials,data) {
    return "<br>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form>\n    <fieldset>\n    <div class=\"alert-message\">\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n    </div>\n    <div data-hook=\"toggleSwitchSection\" data-region=\"toggleSwitchRegion\" class=\"mask-toggle\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"shouldShowCombinedDisclosure") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":3},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasAuditModel") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":10,"column":88}}})) != null ? stack1 : "")
    + "\n    <div data-hook=\"payee-section\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isExistingTransaction") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":78,"column":15}}})) != null ? stack1 : "")
    + "    <div data-hook=\"create-section\" data-region=\"createPaymentContent\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRTPPayment") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":8},"end":{"line":95,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPaymentDetails") : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":8},"end":{"line":120,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRTPPayment") : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":122,"column":8},"end":{"line":129,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    <div data-hook=\"confirm-section\" data-region=\"confirmPaymentContent\"></div>\n    </fieldset>\n</form>\n\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasAuditModel") : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":137,"column":0},"end":{"line":208,"column":7}}})) != null ? stack1 : "")
    + "\n<div data-hook=\"totals-region\"></div>\n\n<div data-hook=\"duplicate-payment-section\" data-region=\"duplicatePaymentContent\"></div>\n";
},"useData":true,"useDepths":true});