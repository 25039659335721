var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <form autocomplete=\"off\" data-submit=\"save\">\n\n        <div class=\"validation-group hide\" role=\"alert\">\n            <p class=\"help-block\" data-validation=\"generic\"></p>\n        </div>\n\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        \n        <div class=\"form-container security-passcode\">\n            <div>\n                <div class=\"field-container-lg\">\n                    "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"mfa.mobileOTP.challenge.description",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":12,"column":69}}}))
    + "\n                </div>\n            </div>\n        </div>\n        <div>\n                <div class=\"loader\"></div>\n        </div>\n    </form>\n";
},"useData":true});