import Marionette from 'backbone.marionette';
import $ from '../../$';
import log from '../../log';
import util from '../../util';
import viewBinding from './viewBinding';
import appBus from '../../appBus';

export default function (ClassToExtend) {
  const proto = ClassToExtend.prototype;

  return {

    appBus,

    navigateTo(route) {
      this.appBus.trigger('router:navigate', route, true);
    },

    refresh() {
      this.appBus.trigger('router:reload');
    },

    delegateEvents() {
      proto.delegateEvents.apply(this, arguments);
      this.addGluEvents();
      Marionette.bindEntityEvents(this, this.appBus, Marionette.getOption(this, 'appEvents'));
    },

    undelegateEvents() {
      proto.undelegateEvents.apply(this, arguments);
      this.removeGluEvents();
      Marionette.unbindEntityEvents(this, this.appBus, Marionette.getOption(this, 'appEvents'));
    },

    addGluEvents() {
      if (!this.noGluEvents) {
        this.listenTo(this, 'render', this.enableViewBinding);
        this.$el.on('click', '[data-action]', util.bind(this.onAction, this));
        this.$el.on('submit', 'form[data-submit]', util.bind(this.onSubmit, this));
      }
    },

    removeGluEvents() {
      if (!this.noGluEvents) {
        this.stopListening(this, 'render', this.enableViewBinding);
        this.$el.off('click', '[data-action]');
        this.$el.off('submit', 'form[data-submit]');
      }
    },

    onInteraction(e, dataAttr) {
      e.preventDefault();
      e.stopPropagation();

      const methodName = $(e.currentTarget).data()[dataAttr];
      const func = this[methodName];

      if (typeof func === 'function') {
        func.call(this, e);
      }
    },

    onSubmit(e) {
      return this.onInteraction(e, 'submit');
    },

    onAction(e) {
      return this.onInteraction(e, 'action');
    },

    render() {
      if (this.isClosed) {
        this.delegateEvents();
      }
      return proto.render.apply(this, arguments);
    },

    renderAndRestoreFocus() {
      const focusedChildId = this.captureFocusId();
      this.render();
      if (focusedChildId) {
        this.restoreFocus(focusedChildId);
      }
    },

    captureFocusId() {
      if (this.isClosed) {
        return null;
      }
      const $focused = this.$(document.activeElement);
      let ret = false;

      if ($focused.length > 0) {
        if ($focused.attr('id')) {
          ret = $focused.attr('id');
        } else {
          log.warn('Focus cannot be restored; element is missing ID attribute', $focused);
        }
      }
      return ret;
    },

    restoreFocus(id) {
      if (this.$(`#${id}`)) {
        this.$(`#${id}`).focus();
      }
    },

    enableViewBinding() {
      if (!this.model) {
        return;
      }
      viewBinding.bind(this);
    }
  };
}

