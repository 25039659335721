import Glu from '@glu/core/src/glu';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import constants from 'common/dynamicPages/api/constants';
import store from 'system/utilities/cache';
import dialog from '@glu/dialog';
import FiDetailModal from 'app/administration/views/fileImport/fiDetailModal';
import mobileUtil from 'mobile/util/mobileUtil';
import ListView from './importResults';
import template from './importStopResults.hbs';

const importResultsList = ListView.extend({
    template,

    events: {
        'click [data-hook="fileimport-button"]': 'fileimport',
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
        'click [data-hook="refresh-button"]': 'refreshData',
    },

    initialize(options) {
        this.parent = options.parent;
        const superOptions = {
            enableSaveViews: false,
            menuCategory: 'RISK',
            context: 'CM_IMPORTRESULTS',
            serviceName: 'cm/importStopsListView/importResultsStopPaymentImport',
            returnRoute: Glu.Backbone.history.getFragment(),
        };
        if (this.parent?.options?.gridOptions) {
            superOptions.gridOptions = this.parent.options.gridOptions;
        }
        store.set('cm_listView_importResultsStopPaymentImport-listRoute', Glu.Backbone.history.getFragment());
        store.set('cm_stopPaymentImportlistView-tab', 'imports');
        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    /**
     * Method to set print/export options for the grid
     * Overrides parent to assign proper context
     * @param {string} format - an optional format. Defaults to 'PDF'
     * @returns {Object}
     */
    getStopCancelOptions(format) {
        this.gridView.context.productCode = 'CM';
        this.gridView.context.functionCode = 'INST';
        this.gridView.context.typeCode = 'STCAIMPT';
        this.gridView.context.actionMode = 'SELECT';
        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            format,
        };
        return options;
    },

    templateHelpers() {
        const self = this;
        return {
            hasApproveEntitlement() {
                return self.hasEntitlement(constants.ACTION_APPROVE);
            },

            hasDeleteEntitlement() {
                return self.hasEntitlement(constants.ACTION_DELETE);
            },
        };
    },

    entitlementPromiseSuccess(result) {
        this.entitlements = result.actions;
        if (!mobileUtil.isMobileGridEnabled()) {
            this.listenTo(this.gridView.getRows(), 'sync', this.updateRefreshTimestamp);
            this.listenForGridErrors('listview.savesystemviewinvalid');
            this.setHasLoadedRequiredData(true);
            this.render();
        }
        return result.actions;
    },

    fileimport() {
        const importContext = {
            serviceName: '/checkfimport/import',
            actionMode: 'IMPORT',
            productCode: 'CM',
            typeCode: 'STCAIMPT',
        };
        const overrideContext = util.extend(this.contextDef, importContext);
        store.set(`${this.contextKey}-contextOverride`, overrideContext);
        this.navigateTo(this.contextModel.getImportUrl());
    },

    gridRowSelect(options) {
        const { model } = options;
        const status = model.get('STATUS');

        if (status.toUpperCase() === 'RJ') {
            store.set('fileImportId', model.get('IMPORTID'));
            store.set('jobId', model.get('EVENTID'));
            store.set('productCode', model.get('PRODUCT'));
            store.set('functionCode', model.get('FUNCTION'));
            store.set('typeCode', model.get('TYPE'));
            store.set('fileOriginalName', model.get('FILENAME'));
            store.set('importCode', 'CM');
            store.set('templateCode', model.get('TEMPLATE_CODE'));
            store.set('templateName', model.get('TEMPLATE_NAME'));
            const fiDetailModal = new FiDetailModal({});
            dialog.custom(fiDetailModal);
        } else {
            const jobId = options.model.get('EVENTID');
            this.parent.openView(
                'individual',
                [{
                    title: locale.get('CM.JobId'),
                    label: jobId,
                    field: 'JOBID',
                    type: 'multistring',
                    value: [jobId],
                }],
            );
        }
        return Promise.resolve();
    },
});

export default importResultsList;
