import EntryView from 'common/dynamicPages/views/workflow/entry';
import userInfo from 'etc/userInfo';
import store from 'system/utilities/cache';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import GridView from 'app/setup/views/fileImportMap/grid';
import domUtil from 'common/util/domUtil';
import entryTmpl from './entry.hbs';

export default EntryView.extend({
    template: entryTmpl,

    initialize(opts) {
        const options = {
            menuCategory: 'SETUP',
            serviceName: 'tableMaintenance/importMap',
            serviceFunc: null,
            businessType: null,
            context: 'ACH_DIMD_MAINT',
            mode: opts.mode,
        };

        EntryView.prototype.initialize.call(this, options);
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.disableButtons();
            this.listenTo(this.appBus, 'mdf:fileImportMap:hideGrid', this.hideGrid);
            this.listenTo(this.appBus, 'mdf:fileImportMap:showGrid', this.showGrid);
        }

        EntryView.prototype.onRender.call(this, null);
    },

    save() {
        const createImpMapRowCol = function (fieldname, value) {
            return {
                name: fieldname,
                value,
            };
        };

        const createImpMapRow = function (row, data, userGroup, userID) {
            const importMapRow = {};
            importMapRow.item = [];
            const activeStr = row.get('ACTIVE');
            const columnName = row.get('COLUMNNAME');
            const screenName = row.get('SCREENNAME');
            if ((screenName)) {
                importMapRow.item.push(createImpMapRowCol('ACTIVE', activeStr));
                importMapRow.item.push(createImpMapRowCol('ENTERED_BY', userID));
                importMapRow.item.push(createImpMapRowCol('COLUMNNAME', columnName));
                importMapRow.item.push(createImpMapRowCol('MATCH', row.get('MATCH')));
                importMapRow.item.push(createImpMapRowCol('SCREENNAME', screenName));
                importMapRow.item.push(createImpMapRowCol('USERGROUP', userGroup));
                importMapRow.item.push(createImpMapRowCol('STARTPOS', row.get('STARTPOS')));
                importMapRow.item.push(createImpMapRowCol('ENDPOS', row.get('ENDPOS')));
                importMapRow.item.push(createImpMapRowCol('DELIMITEDORDER', row.get('DELIMITEDORDER')));
                importMapRow.item.push(createImpMapRowCol('DEFAULTVALUE', row.get('DEFAULTVALUE')));
                importMapRow.item.push(createImpMapRowCol('RECNUM', row.get('RECNUM')));
                data.items.push(importMapRow);
            }
        };

        const collectGridData = function (gridView) {
            const userID = userInfo.get('id');
            const userGroup = userInfo.get('group');
            const data = {};
            data.items = [];
            data.name = 'PAYDIMChild';
            gridView.gridCollections.rows.each((row) => {
                createImpMapRow(row, data, userGroup, userID);
            });
            return data;
        };

        this.gridView.clearError();
        if (this.validateImportFieldMap()) {
            const data = collectGridData(this.gridView);
            const gridArray = [];
            gridArray.push(data);
            // .../common/dynamicPages/models/meta.js
            this.pageView.model.set({
                gridsOverride: gridArray,
            });
            // collect data and add it to pageView model
            this.pageView.save();
        } else {
            this.enableButtons();
        }
    },

    convertToInt(value) {
        if (value === undefined || Number.isNaN(Number(value))) {
            return -1;
        }
        return parseInt(value, 10);
    },

    validateImportFieldMap() {
        const { model } = this.pageView;
        const productCode = model.get('PRODUCTCODE');

        // defaults
        if (!model.get('SUBTYPE')) {
            if (model.get('IMPORTFILETYPE') === 'NACHA') {
                model.set('SUBTYPE', 'NACHA');
            }
            if (model.get('PRODUCTCODE') !== 'USACH') {
                model.set('SUBTYPE', '*');
            }
        }
        if (model.get('FUNCTIONCODE') === '') {
            if (model.get('PRODUCTCODE') === 'USACH') {
                model.set('FUNCTIONCODE', 'BATCH');
            }
            if (model.get('PRODUCTCODE') === 'CM' || model.get('PRODUCTCODE') === 'GIR') {
                model.set('FUNCTIONCODE', 'INST');
            }
        }

        if (model.get('FIELDDELIMITERVALUE') === 'Other') {
            model.set('OTHERFIELDDELIMITERVALUE', model.get('FIELDDELIMITER'));
        } else {
            model.set('OTHERFIELDDELIMITERVALUE', '');
        }

        if (model.get('RECORDDELIMITERVALUE') === 'Other') {
            model.set('OTHERRECORDDELIMITERVALUE', model.get('RECORDDELIMITER'));
        } else {
            model.set('OTHERRECORDDELIMITERVALUE', '');
        }

        if (!this.validateMandatoryFieldMap()) {
            return false;
        }
        if (productCode === 'RTGS') {
            return this.validateRealTimeGrossSettlementImportFields();
        }
        if (productCode === 'CM' || productCode === 'GIR') {
            return this.validateCheckManagementImportFields();
        }
        return this.validateImportFieldsACH();
    },

    validateMandatoryFieldMap() {
        const importFileType = this.pageView.model.get('IMPORTFILETYPE');
        let screenNameWarn = '';

        util.each(this.gridView.gridCollections.rows.models, (row) => {
            /*
             * We only need one mandatory field warning so
             * don't check if one has been found already
             */
            if (this.convertToInt(row.get('MANDATORY')) === 1 && screenNameWarn === '' && util.isEmpty(row.get('DEFAULTVALUE'))) {
                if (this.convertToInt(row.get('ACTIVE')) !== 1) {
                    screenNameWarn = row.get('SCREENNAME');
                } else if (importFileType === 'Delimited' && util.isEmpty(row.get('DELIMITEDORDER'))) {
                    screenNameWarn = row.get('SCREENNAME');
                } else if (importFileType === 'Fixed' && ((util.isEmpty(row.get('STARTPOS')) || util.isEmpty(row.get('ENDPOS'))))) {
                    screenNameWarn = row.get('SCREENNAME');
                }
            }
        });

        if (screenNameWarn === '') {
            return true;
        }
        this.gridView.showError(locale.get('depends.FailedMandatoryMapField', screenNameWarn));
        return false;
    },

    validateImportFieldsACH() {
        let isActive = false;
        let screenFieldNameWarn = '';
        const rows = this.gridView.gridCollections.rows.models;
        const { model } = this.pageView;
        const fileProcess = model.get('FILEPROCESS');
        const importFileType = model.get('IMPORTFILETYPE');
        let fieldToCheck1 = 'DELIMITEDORDER';
        let fieldToCheck2 = 'DEFAULTVALUE';
        const fieldToCheck3 = 'DEFAULTVALUE';
        let row = null;
        const self = this;

        this.gridView.gridCollections.rows.each((rowItem) => {
            if (self.convertToInt(rowItem.get('ACTIVE')) === 1) {
                isActive = true;
            }
        });

        if (!isActive) {
            this.gridView.showError(locale.get('ACH.MatchandUpdateError'));
            return false;
        }

        if (importFileType === 'Fixed' || importFileType === 'NACHA') {
            fieldToCheck1 = 'STARTPOS';
            fieldToCheck2 = 'ENDPOS';
        }

        let errorMessage;
        if (importFileType !== 'NACHA') {
            for (let i = 0; i < rows.length; i += 1) {
                row = rows[i];
                screenFieldNameWarn = row.get('SCREENNAME');
                if (this.convertToInt(row.get('ACTIVE')) !== 0) {
                    /*
                     * when Delimited type, if the delimited Order is empty and
                     * Default Value is empty, this is an error when Delimited type,
                     * if identified as Match, it must have a delimited order value,
                     * or its an error when Fixed type, if start position or end position
                     * is empty and it does not have a default value, this is an error
                     * when Fixed type, if start position or end position are empty and it is
                     * identified as Match, this is an error
                     */
                    if (importFileType === 'Delimited') {
                        if (util.isEmpty(row.get(fieldToCheck1))
                            && util.isEmpty(row.get(fieldToCheck2))) {
                            errorMessage = 'depends.FailedErrorActiveError';
                            break;
                        } else if (util.isEmpty(row.get(fieldToCheck1)) && this.convertToInt(row.get('MATCH')) === 1) {
                            errorMessage = 'ACH.MatchRequiresDelimiterError';
                            break;
                        }
                    } else if (importFileType === 'Fixed') {
                        if (util.isEmpty(row.get(fieldToCheck1))
                            || util.isEmpty(row.get(fieldToCheck2))) {
                            if (util.isEmpty(row.get(fieldToCheck3))) {
                                errorMessage = 'depends.FailedErrorActiveError';
                                break;
                            } else if (this.convertToInt(row.get('MATCH')) === 1) {
                                errorMessage = 'ACH.MatchRequiresPositionError';
                                break;
                            }
                        }
                    }
                }
            }
            if (errorMessage) {
                this.gridView.showError(locale.get(errorMessage, screenFieldNameWarn));
                return false;
            }
        }

        let retVal = false;
        if (fileProcess.toUpperCase() === 'APPEND ALL') {
            // no need to check for MATCH when Append All
            return true;
        }
        if (model.get('PAYMENTTYPECODE') === 'CRTRAN') {
            // For RTP no need to check for MATCH and Update
            return true;
        }

        /*
         * the below logic, does not make sense
         * In all other code here we break out of loop, when there is a problem, but
         * here, we break when things are good
         * This means that the rest of the list does not get checked.  I think this
         * logic is backwards and needs to be fixed.
         */
        for (let j = 0; j < rows.length; j += 1) {
            row = rows[j];
            if (this.convertToInt(row.get('ACTIVE')) !== 0) {
                if (fileProcess.toUpperCase() === 'REPLACE ALL') {
                    if ((!util.isEmpty(row.get(fieldToCheck1))
                        || !util.isEmpty(row.get(fieldToCheck2)))) {
                        retVal = true;
                        break;
                    }
                } else if (model.get('PAYMENTTYPECODE') === 'BDACHFP') {
                    if ((!util.isEmpty(row.get(fieldToCheck1))
                        || !util.isEmpty(row.get(fieldToCheck2)))) {
                        retVal = true;
                        break;
                    }
                } else if ((!util.isEmpty(row.get(fieldToCheck1)) || !util.isEmpty(row.get(fieldToCheck2))) && this.convertToInt(row.get('MATCH')) === 1) {
                    retVal = true;
                    break;
                }
            }
        }
        if (!retVal) {
            this.gridView.showError(locale.get('ACH.MatchandUpdateError'));
            return false;
        }

        return true;
    },

    validateRealTimeGrossSettlementImportFields() {
        let screenFieldNameWarn = '';
        const { model } = this.pageView;
        const importFileType = model.get('IMPORTFILETYPE');
        const rows = this.gridView.gridCollections.rows.models;

        for (let i = 0; i < rows.length; i += 1) {
            const row = rows[i];
            if (this.convertToInt(row.get('ACTIVE')) === 1) {
                if (importFileType === 'Delimited' && util.isEmpty(row.get('DELIMITEDORDER')) && util.isEmpty(row.get('DEFAULTVALUE'))) {
                    screenFieldNameWarn = row.get('SCREENNAME');
                    break;
                } else if (importFileType === 'Fixed' && ((util.isEmpty(row.get('STARTPOS')) || util.isEmpty(row.get('ENDPOS'))) && util.isEmpty(row.get('DEFAULTVALUE')))) {
                    screenFieldNameWarn = row.get('SCREENNAME');
                    break;
                }
            }
        }
        if (screenFieldNameWarn === '') {
            return true;
        }
        this.gridView.showError(locale.get('depends.FailedErrorActiveError', screenFieldNameWarn));
        return false;
    },

    validateCheckManagementImportFields() {
        // same validation of fields like RTGS
        return this.validateRealTimeGrossSettlementImportFields();
    },

    hideGrid() {
        this.disableButtons();
        this.gridContent.reset();
    },

    resolveImportFileTypeCode(importFileType) {
        let code = importFileType;
        if (code) {
            code = code.toUpperCase();
            switch (code) {
            case 'INSDIMD':
            case 'INSDIMF':
            case 'INSDIMN':
                break;
            case 'DELIMITED':
                code = 'INSDIMD';
                break;
            case 'FIXED':
                code = 'INSDIMF';
                break;
            case 'NACHA':
                code = 'INSDIMN';
                break;
            default:
                break;
            }
        }
        return code;
    },

    getInsertButtons() {
        this.buttonList.push({
            action: 'SAVE',
        });
        this.buttonList.push({
            action: 'RESET',
        });
    },

    getModifyButtons() {
        this.buttonList.push({
            action: 'SAVE',
        });
    },

    reset() {
        if (this.mode.toUpperCase() === 'INSERT') {
            this.disableButtons();
            this.pageView.model.set('IMPORTFILETYPE', '');
        }
        if (this.mode.toUpperCase() === 'MODIFY') {
            store.set(`${this.contextKey}-actionModel`, this.tempModel);
            this.navigateTo('SETUP/fileImportMap/modify');
        }
    },

    modify() {
        store.set(`${this.contextKey}-actionModel`, this.tempModel);
        this.navigateTo('SETUP/fileImportMap/modify');
    },

    disableButtons() {
        domUtil.setDisabled(this.ui.$saveButton, true);
    },

    showGrid() {
        this.enableButtons();
        const productCode = this.pageView.model.get('PRODUCTCODE');
        const paymentTypeCode = this.pageView.model.get('PAYMENTTYPECODE');
        let importFileType = this.pageView.model.get('IMPORTFILETYPE');
        const { mode } = this;
        importFileType = this.resolveImportFileTypeCode(importFileType);
        const options = {
            mapType: importFileType,
            mode: mode.toUpperCase(),
            paymentType: paymentTypeCode,
        };

        if (productCode === 'USACH') {
            options.paySubType = (this.pageView.model.get('IMPORTFILETYPE') === 'NACHA' ? 'NACHA' : this.pageView.model.get('SUBTYPE'));
            if (paymentTypeCode === 'BDACHCSP') {
                options.csAgency = this.pageView.model.get('CSAGENCY');
            } else if (paymentTypeCode === 'BDACHTP') {
                options.taxAuth = this.pageView.model.get('TAXAUTH');
            } else if (paymentTypeCode === 'BDACHCVP') {
                options.addendaFormat = this.pageView.model.get('ADDENDAFORMAT');
            }
        } else if (productCode === 'RTGS') {
            options.paySubType = '*';
            options.paymentFunction = this.pageView.model.get('FUNCTIONCODE');
        }

        if (mode && (mode.toUpperCase() === 'MODIFY' || mode.toUpperCase() === 'VIEW')) {
            options.id = this.pageView.model.get('ID');
        }

        // for BDACHCVP load grid data only if ADDENDAFORMAT is selected
        if (paymentTypeCode !== 'BDACHCVP' || options.addendaFormat) {
            this.gridView = new GridView(options);
            this.gridContent.show(this.gridView);
        }
    },
});
