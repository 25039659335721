import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import Model from '@glu/core/src/model';
import locale from '@glu/locale';
import { createTabsToggleButton, toggleTabs, setTabButtonText } from 'common/util/a11y/tabs';
import AccountsByPaymentGroup from './accountsByPaymentGroup';
import AccountsByPaymentType from './accountsByPaymentType';
import accountLimitsLayoutTmpl from './accountLimitsLayout.hbs';

export default Layout.extend({
    template: accountLimitsLayoutTmpl,
    itemViewContainer: '.list-container',
    className: 'approval-limits-region',

    ui: {
        $infoIcon: '.btn .icon-info',
        $navTabs: '[data-hook="getNavTabs"]',
        $navItems: '[data-hook="getNavTabs"] .NavTabs-item',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
    },

    regions: {
        accountLimitsRegion: '[data-hook="accountLimitsRegion"]',
    },

    initialize(options) {
        /*
         * Set the correct view to instantiate based on the group/type selection from
         * the permisions tab
         */
        this.AccountsGroupingView = options.permissionsGrouping === 'PAYMENT_GROUP' ? AccountsByPaymentGroup : AccountsByPaymentType;

        // Default to hide the action permissions in the limits listview.
        this.limitsModel = new Model({
            showPermissions: false,
            limitMap: {},
        });

        this.isCannotGrantBeyondOwnUser = this.options.isCannotGrantBeyondOwnUser;
    },

    onRender() {
        createTabsToggleButton(this);
        const byGroup = this.options.permissionsGrouping === 'PAYMENT_GROUP';
        this.modifyMode = this.options.mode === 'insert' || this.options.mode === 'modify';

        // HACK Rearrange the data entitlements for the group because of a bug in tab 3.
        util.each(
            this.options.mainModel.assignAccountsDataEntitlements,
            (dataEntitlementParam) => {
                const dataEntitlement = dataEntitlementParam;
                dataEntitlement.group = byGroup;

                if (byGroup) {
                    dataEntitlement.productCode = dataEntitlement.productCode
                        || dataEntitlement.typeCode;
                    dataEntitlement.typeCode = '';
                }
            },
        );

        if (this.ui.$navLinks.length > 0) {
            this.ui.$navItems.first().addClass('is-active');
            this.currentTabText = this.ui.$navItems.first().find('a').text();
            setTabButtonText(this, this.currentTabText);
            this.loadLimits(this.ui.$navLinks.data().value);
        }
    },

    loadLimits(groupId) {
        const group = this.collection.findWhere({
            id: groupId,
        });

        this.accountLimitsView = new this.AccountsGroupingView({
            model: group,
            limitsModel: this.limitsModel,
            mainModel: this.options.mainModel,
            modifyMode: this.modifyMode,
            mode: this.options.mode,
            isCannotGrantBeyondOwnUser: this.isCannotGrantBeyondOwnUser,
        });

        this.accountLimitsRegion.show(this.accountLimitsView);
    },

    showLimitsByPaymentGroup(e) {
        // Validate limits before going to a different limits tab.
        if (!this.validateLimits()) {
            // Stop the event if validation failure.
            e.preventDefault();
            return;
        }

        const $target = this.$(e.currentTarget);
        const selectedGroupId = e.target.dataset.value;

        this.ui.$navItems.removeClass('is-active');
        $target.parent().addClass('is-active');

        this.currentTabText = $target.text();
        toggleTabs(this);
        this.loadLimits(selectedGroupId);
    },

    /**
     * Validates the limits of a CGBO user against
     * the logged in user.
     * @return {boolean}
     * Returns true if limits are valid
     */
    validateLimits() {
        // No need to validate non CGBO users.
        if (!this.isCannotGrantBeyondOwnUser
            || util.isNullOrUndefined(this.accountLimitsView)) {
            return true;
        }

        return this.accountLimitsView.validateLimits();
    },

    beforeSave() {
        return this.accountLimitsView ? this.accountLimitsView.beforeSave() : Promise.resolve();
    },

    templateHelpers() {
        const self = this;
        return {
            label() {
                return locale.get(`administration.${this.get('label')}`);
            },

            groups() {
                return self.collection.filter(group => group.isEntitled());
            },

            modifyMode: this.modifyMode,
        };
    },
});
