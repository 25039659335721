import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';

import useLocale from '../../hooks/useLocale'; // path may change

import styles from '../RtpOnGoingConversation/RtpOnGoingConversation.styles';

const propTypes = {
    messageType: PropTypes.string.isRequired,
    rfiType: PropTypes.string,
    rfiReason: PropTypes.string,
    // Internal classes hash - do not pass
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const defaultProps = {
    rfiType: null,
    rfiReason: null,
};

const OnGoingHeader = ({
    messageType, rfiType, rfiReason, classes,
}) => {
    const { getLocaleString } = useLocale();

    let header = '';
    if (messageType === 'REQUEST') {
        header = getLocaleString('rtp.requestForInfo.heading');
    } else if (messageType === 'ACK') {
        header = getLocaleString('rtp.paymentAcknowledgement.heading');
    } else if (messageType === 'RESPONSE') {
        header = getLocaleString('rtp.responseToRequestForInfo.heading');
    }

    const reasonClass = `col-2 ${classes.rfiReason}`;
    const reasonDescClass = `col-4 ${classes.rfiReasonDesc}`;
    const rfiLabel = `rfiLabel ${classes.msgLabel}`;
    const ackLabel = `ackLabel ${classes.msgLabel}`;
    const respLabel = `responseLabel ${classes.msgLabel}`;

    return (
        <div className={classes.messageHeader}>
            {messageType === 'ACK'
                && (
                    <div className={classes.msgHeader}>
                        <div className="col-12">
                            <p className={ackLabel}>{header}</p>
                        </div>
                    </div>
                )}
            {messageType === 'RESPONSE'
                && (
                    <div className={classes.respMsgHeader}>
                        <div className="col-12">
                            <p className={respLabel}>{header}</p>
                        </div>
                    </div>
                )}
            {messageType === 'REQUEST'
                && (
                    <div className={classes.msgHeader}>
                        <div className="col-6">
                            <p className={rfiLabel}>{header}</p>
                            <p className={rfiLabel}>{getLocaleString('PAY.Type')}</p>
                            <span>{rfiType}</span>

                        </div>
                        <div className={reasonClass}>
                            <p className={classes.msgLabel}>{getLocaleString('PAY.Reason')}</p>
                        </div>
                        <div className={reasonDescClass}>
                            <span>{rfiReason}</span>
                        </div>
                    </div>
                )}
        </div>
    );
};

OnGoingHeader.propTypes = propTypes;
OnGoingHeader.defaultProps = defaultProps;

export default withStyles(styles)(OnGoingHeader);
