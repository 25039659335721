import { locale } from '@glu/core';

locale.set({
  clearSourceSelection: 'Clear selection',
  clearTargetSelection: 'Clear selection',
  moveSelectedItems: 'Move selected items',
  moveAllItems: 'Move all items',
  removeAllItems: 'Remove all items',
  removeSelectedItems: 'Remove selected items',
  sourceListHeader: 'Available items',
  targetListHeader: 'Selected items'
});
