import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import validatorPatterns from 'system/validatorPatterns';

const BillPay = Model.extend({
    defaults: {
        TYPE: 'BENEBKCH',
        PAYMENTPRODUCT: 'RTGS',
        PAYMENTTYPE: 'BPAY',
        CLEARINGSYSTEM: '*',
        TYPE_DESCRIPTION: 'RTGS.type.bpay',
        BENE_BANK_ID: null,
        BENE_ACCOUNT_CURRENCY: 'CAD',
        BENE_BANK_NAME: null,
        BENE_ACCOUNTNUMBER: null,
        BENE_BANK_ACCOUNT_NUMBER: null,
        TNUM: null,
    },

    initialize(data) {
        // Retrieve data if modify
        if (data && data.BPAY) {
            this.retrieveData(data);
        }

        this.validators = {
            BENE_BANK_ID: {
                exists: true,
                description: locale.get('bab.billerPlaceholder'),
            },
        };
    },

    updateAccountValidator(accountFormat) {
        const qMarkCount = (accountFormat.match(new RegExp('\\?', 'g')) || []).length;
        this.validators.BENE_ACCOUNTNUMBER = {
            matches: validatorPatterns.getBillPayAccountPattern(accountFormat),
            maxLength: accountFormat.length,

            // subtract ?s count as it is optional
            minLength: accountFormat.length - qMarkCount,

            exists: true,
            description: `${locale.get('bab.accountWithBiller')} (${accountFormat})`,
        };
    },

    isBillerEntered() {
        return !util.isEmpty(this.get('BENE_BANK_ID')) || !util.isEmpty(this.get('BENE_ACCOUNTNUMBER'));
    },

    retrieveData(data) {
        const beneBankName = data.BPAY.BENE_BANK_NAME;
        const beneBankId = data.BPAY.BENE_BANK_CODE_PRIMARY;
        const beneAccount = data.BPAY.BPAY_ACCOUNTNUMBER;
        const beneBankAcctNum = data.BPAY.BENE_BANK_ACCOUNT_NUMBER;
        const tnum = data.BPAY.TNUM;

        this.set({
            BENE_BANK_NAME: beneBankName || null,
            BENE_BANK_ID: beneBankId || null,
            BENE_ACCOUNTNUMBER: beneAccount || null,
            BENE_BANK_ACCOUNT_NUMBER: beneBankAcctNum || null,
            TNUM: tnum || null,
        });
        this.unset('BPAY');
    },

    sync() {
        return true;
    },
});

export default BillPay;
