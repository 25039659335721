import Glu from '@glu/core/src/glu';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import store from 'system/utilities/cache';
import constants from 'app/administration/views/paymentAutomationManager/constants';
import ListView from 'common/dynamicPages/views/workflow/list';
import listViewConfig from 'common/util/listViewConfig/listViewConfig';
import systemConfig from 'system/configuration';
import workspaceHelper from 'common/workspaces/api/helper';
import services from 'services';

const PayAutoFileManager = ListView.extend({
    className: 'PaymentAutomation-fileManagerSummary',

    initialize(options) {
        const superOptions = {
            menuCategory: 'REPORTING',
            serviceName: constants.SUMMARY_SERV,
            serviceFunc: null,
            businessType: null,
            context: constants.PAY_AUT_HIST,
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    /**
     * creates the listViewConfig and sets the member lvc to it
     * @param {object} [context]
     * @return {object} returns the listViewConfig
     */
    setListViewConfig(context) {
        let options;
        if (systemConfig.isAdmin()) {
            options = {
                productCode: 'ADMPAY',
                functionCode: 'AUTOMTN',
                typeCode: 'PAYAUTLC',
                subType: 'SUMMARY',
                actionMode: 'SELECT',
            };
        } else {
            options = {
                productCode: 'PA',
                functionCode: 'AUTOMTN',
                typeCode: 'PAYAUTLC',
                subType: 'SUMMARY',
                actionMode: 'SELECT',
            };
        }
        this.lvc = listViewConfig.getInstance(this, util.extend({}, context, options));
        return this.lvc;
    },

    gridRowSelect(options) {
        if (options && options.model) {
            options.model.set('returnRoute', Glu.Backbone.history.getFragment());
        }
        store.set(constants.PAY_AUT_HIST, options.model);
        this.navigateTo(constants.DETAIL_URL);
        return Promise.resolve();
    },

    fileDownload(data, textStatus, request) {
        const contentType = request.getResponseHeader('content-type');
        const contentDisposition = request.getResponseHeader('content-disposition');
        const elements = contentDisposition.split(';');
        const filename = elements.length > 1
            ? elements[1].replace(/filename=|"/g, '')
            : 'file.csv';
        const isFirefox = typeof InstallTrigger !== 'undefined';

        if (filename && textStatus === 'success') {
            const binaryData = [];
            binaryData.push(data);

            const blob = new Blob(binaryData, { type: contentType });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');

            a.href = url;
            a.download = filename;
            if (isFirefox) {
                a.setAttribute('type', 'hidden');
                document.body.appendChild(a);
            }
            a.click();

            // for IE
            if (navigator && navigator.msSaveBlob) {
                navigator.msSaveBlob(blob, filename);
            }
            window.URL.revokeObjectURL(url);

            if (isFirefox) {
                a.remove();
            }
        }
    },

    gridDownload(options) {
        return new Promise((resolve, reject) => {
            const { model } = options;
            const $loading = this.$('.loading-indicator-grid');
            const UNIQUEFILEID = model.get('UNIQUEFILEID');

            $loading.show();
            http.setRequestHeaders({ fileId: UNIQUEFILEID });

            const service = services.generateUrl(constants.DNLD_SERV);

            http.get(service, (data, textStatus, request) => {
                // success handler
                this.fileDownload(data, textStatus, request);
                $loading.hide();
                resolve({ result: data });
            }, (e) => {
                // error handler
                $loading.hide();
                reject(e);
            });
        });
    },
});
workspaceHelper.publishedWidgets.add({
    id: 'PAYMENT_AUTOMATION',
    view: PayAutoFileManager,
    options: {},
    useMobileCard: true,
});

export default PayAutoFileManager;
