var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"export-payment\">\n    <form autocomplete=\"off\" data-submit=\"save\">\n\n        <div class=\"validation-group hide\" role=\"alert\">\n            <p class=\"help-block center\" data-validation=\"generic\"></p>\n        </div>\n\n        <div class=\"form-container\">\n            <div>\n              <div class=\"field-container-lg\">\n                 <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"balanceTrans.print.data",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":24},"end":{"line":11,"column":60}}}))
    + "</label>\n                 <div class=\"form-group\">\n                      <label class=\"checkbox-inline\">\n                        <input type=\"checkbox\" id=\"includeDetails\" name=\"includeDetails\" data-bind=\"model\"/>\n                        "
    + alias2(((helper = (helper = lookupProperty(helpers,"getLabel") || (depth0 != null ? lookupProperty(depth0,"getLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"getLabel","hash":{},"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":15,"column":36}}}) : helper)))
    + "</label>\n                 </div>\n              </div>\n            </div>\n\n            </div>\n        </div>\n    </form>\n</div>\n";
},"useData":true});