import {
  useState, useContext, useCallback, useEffect
} from 'react';
import { EditContext } from '@glu/data-components';

export function defaultGetLabelText({ colDef }) {
  return colDef.headerName;
}

/**
 * The value returned by useEditAwareCellRenderer comprises a small API that allows you to interact
 * with the EditContext
 *
 * @typedef {Object} useEditAwareCellRendererApi
 */

/**
 * Hook that handles much of the work of connecting the value for a cell in ag grid to a form input
 * of some sort and communicating with the EditContext to allow user edits to be captured in one
 * spot with validation connected to each input. Using this hook you just need to pass it what it
 * needs and in simple cases just connect its change event handler with setEditedValue to pass
 * along changes from the user and a flag to indicate if you want validate performed or not.
 *
 * @param {Object} options - Options for this hook
 * @param {*} options.cellValue - The current value of the data for the cell in ag grid.
 * @param {Object} options.node - The row node from ag grid.
 * @param {Object} options.data - The data for the current row from ag grid.
 * @param {Object} options.colDef - The ag grid column definition for the column this cell
 *    belongs to.
 * @param {Object} options.api - The ag grid grid api.
 * @param {Element} options.eGridCell - Container element provided by ag grid to render cell
 *    contents into.
 * @param {function({ colDef, editedValue, rowData })} [options.getLabelText] - Function to get
 *    the label text that will be supplied by this hook for your form control. You can use this
 *    to customize the label text if the default of the headerName from the column definition
 *    does not meet your needs. It will be passed the current column definition, the current
 *    edited value of the data for this cell, and the data for the entire row.
 * @return {useEditAwareCellRendererApi}
 */
export default function useEditAwareCellRenderer({
  cellValue,
  node,
  data: rowData,
  colDef,
  api,
  eGridCell,
  getLabelText = defaultGetLabelText
}) {
  const { field } = colDef;

  const {
    getEditedData, getErrorsForRow, editDataForRow, addErrorCallbackForRow,
    removeErrorCallbackForRow
  } = useContext(EditContext);

  const rowId = node.id;
  const [rowErrors, setRowErrors] = useState(getErrorsForRow(rowId));

  const editedRowData = getEditedData()[rowId];
  const [editedValue, setEditedVal] = useState(editedRowData ? editedRowData[field] : cellValue);
  const labelText = getLabelText({ colDef, editedValue, rowData });

  const setEditedValue = useCallback((fieldValue, validate) => {
    setEditedVal(fieldValue);

    const validationResults = editDataForRow({
      rowId, rowData, field, fieldValue, validate
    });
    if (validationResults) {
      validationResults.then(errorHash => {
        setRowErrors(errorHash);
      });
    }
  }, [editDataForRow, rowId, rowData, field]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // when scrolling with its virtual rows enabled ag grid and rapidly changing the scroll
      // direction ag grid may add cells only to take them away as you change direction again
      // leading to a situation where the effect runs after a tick only to find no rendered react
      // component as ag grid has changed its mind
      /* istanbul ignore next */
      if (!eGridCell.firstChild) {
        return;
      }

      const { height } = eGridCell.firstChild.getBoundingClientRect();

      if (height > node.rowHeight || !rowErrors) {
        node.setRowHeight(height > 50 ? height : undefined);
        api.onRowHeightChanged();
      }
    });

    return () => {
      clearTimeout(timeout);
    };
  }, [api, node, rowErrors, eGridCell]);

  useEffect(() => {
    addErrorCallbackForRow(rowId, setRowErrors);

    return () => removeErrorCallbackForRow(rowId, setRowErrors);
  }, [addErrorCallbackForRow, removeErrorCallbackForRow, rowId, setRowErrors]);

  return {
    /**
     * The rowId of the row for the cell that your form input represents.
     *
     * @memberof useEditAwareCellRendererApi
     * @type {string|number}
     */
    rowId,
    /**
     * The field in the data that your form input is representing. Taken from the ag grid
     * column definition for the current column.
     *
     * @memberof useEditAwareCellRendererApi
     * @type {string}
     */
    field,
    /**
     * The text that you should use on the label for your form input.
     *
     * @memberof useEditAwareCellRendererApi
     * @type {string}
     */
    labelText,

    /**
     * The current value of the data that your form input is representing with the latest
     * edits applied.
     *
     * @memberof useEditAwareCellRendererApi
     * @type {*}
     */
    editedValue,


    /**
     * The current errors associated with the field represented by your form input in the current
     * row. Note that this can contain more than one error and if your form control can only show
     * one you will need to give it only the first entry of this array.
     *
     * @memberof useEditAwareCellRendererApi
     * @type {Array<string>}
     */
    errors: rowErrors && rowErrors[field],

    /**
     * Call this anytime you want to update the edited value from a form control. The value for
     * the field represented by your input for the current row will be updated to the fieldValue
     * passed in. Additionally validation will be performed if the validate argument is true.
     * Whether complete validation on the entire row or single field validation on just this field
     * is done is dependent on the whether the singleFieldValidation prop is set on the EditContext
     * or not.
     *
     * @memberof useEditAwareCellRendererApi
     * @function
     * @param {*} fieldValue - The new value to set for the data field in the current row
     *    represented by your input.
     * @param {boolean} [validate] - True if you would like to run validation
     */
    setEditedValue
  };
}
