import $ from 'jquery';

import 'jquery-mask-plugin/dist/jquery.mask';


// TODO use this file to create a trimmed-down version of jQuery

/**
 * returns true if an element is visible, with decent performance
 * @param [scope] scope of the render-window instance; default: window
 * @returns {boolean}
 */
$.fn.isScrolledIntoView = function isScrolledIntoView(scope) {
  const $elem = $(this);
  const offset = $elem.offset();
  const $scope = $(scope || window);
  const docViewTop = $scope.scrollTop();
  const docViewBottom = docViewTop + $scope.height();
  const elemTop = offset ? offset.top : 0;
  const elemBottom = elemTop + $elem.height();

  return (docViewBottom >= elemTop && docViewTop <= elemBottom);
};

// If no href is provided for page-headline's back button, then equate to browser back
$(document).on('click', '.page-headline .page-back a', (e) => {
  const href = $(e.currentTarget).attr('href');

  if (!href || href === '#') {
    e.preventDefault();
    return window.history.go(-1);
  }

  return undefined;
});


// prevent jQuery Input Mask from masking all future inputs matching 'input[type="text"]'
$.jMaskGlobals.watchInputs = false;

export default $;

