import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import services from 'services';
import transform from 'common/util/transform';
import userInfo from 'etc/userInfo';
import MasterTabs from '../../collection/user/masterTabs';

export default Model.extend({

    defaults() {
        return {
            masterTabs: new MasterTabs(),
            roleInfo: {},
        };
    },

    approve(options) {
        this.sync('approve', this, options);
    },

    delete(options) {
        this.sync('delete', this, options);
    },

    restore(options) {
        this.sync('restore', this, options);
    },

    sync(method, model, options) {
        let retVal;
        let data;
        let currentUserGroup;
        const validReqKeys = ['PARENTUSERGROUP', 'USERGROUP', 'ROLEID', 'ENTRYMETHOD', 'FUNCTIONCODE', 'UPDATECOUNT__', '_saveWithWarning'];

        switch (method) {
        case 'read':
            currentUserGroup = userInfo.get('group');

            if (options && options.userGroup) {
                currentUserGroup = options.userGroup;
            }

            if (options && options.roleId) {
                retVal = http.post(services.generateUrl('/roleMaintenance/role/read'), {
                    roleId: options.roleId,
                    userGroup: {
                        groupId: currentUserGroup,
                    },
                }, options.success, options.error);
            } else {
                retVal = http.post(services.generateUrl(`/roleMaintenance/role/getRoleEntitlementsMaintenanceModel/${currentUserGroup}`), {}, options.success, options.error);
            }

            break;

        case 'create':
        case 'update':
            data = model.toJSON();
            data.roleInfo = {
                item: transform.hashToPairs(data.roleInfo),
            };

            retVal = http.post(services.generateUrl(`/roleMaintenance/role/${options.isModify ? 'update' : 'add'}`), data, options.success, options.error);
            break;
        case 'approve':
        case 'delete':
        case 'restore':
            data = {
                item: util.chain(model.toJSON()).map((num, key) => {
                    if (validReqKeys.indexOf(key) > -1) {
                        return {
                            name: key,
                            value: num,
                        };
                    }
                    return undefined;
                }).without(undefined).value(),
            };
            retVal = http.post(services.generateUrl(`/roleMaintenance/role/${options.action}`), data, options.success, options.error);
            break;
        default:
            break;
        }

        return retVal;
    },

    parse(response) {
        // This was a save, simply return the object
        if (response.result) {
            return response;
        }

        if (response.masterTabs && response.roleInfo) {
            response.masterTabs = new MasterTabs(response.masterTabs);
            response.roleInfo = transform.pairsToHash(response.roleInfo.item);
        }

        return response;
    },
});
