import TopView from '@glu/scheduler/src/recurrence/weekly/top';
import { util } from '@glu/core';
import template from './top.hbs';

export default TopView.extend({

  initialize() {
    const options = util.defaults(this.options, {
      allowWeekend: true
    });

    // ToDo this was written with Monday as first day of week, which is variable
    // ToDo this was also previously not localized - we need to determine a module localization plan
    this.days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

    if (options.allowWeekend) {
      this.days.push('Saturday', 'Sunday');
    }

    this.disabled = false;

    if (!this.model.has('recurrence.nWeeks')) {
      this.model.set({
          'recurrence.nWeeks': 1,
          'nWeeks': 1,
      });
    }

    if ((this.model.isNew() && !this.model.has('recurrence.onN')) || util.isEmpty(this.model.get('recurrence.onN'))) {
      this.model.set('recurrence.onN', [1]);
      this.model.set('onN', [1]);
    } else {
      this.model.set('recurrence.onN', this.model.get('recurrence.onN').map((value) => +value));
      this.model.set('onN', this.model.get('recurrence.onN').map((value) => +value));
    }

    this.weeklySelectedDays = util.clone(this.model.get('recurrence.onN')) || [];
  },

  updateValues(e) {
    e.preventDefault();

    const $el = this.$(e.currentTarget);
    const value = $el.val();

    if ($el.is(':checked')) {
      this.weeklySelectedDays.push(+value);
    } else {
      this.weeklySelectedDays = util.without(this.weeklySelectedDays, +value);
    }

    // trigger the event so any errors will be removed
    this.model.set('onN', util.clone(this.weeklySelectedDays), {
      changeFromBinding: true
    });
  }

});
