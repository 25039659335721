import Layout from '@glu/core/src/layout';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import errorView from 'mobile/common/views/errorView.hbs';
import checkImageViewTemplate from './checkImageView.hbs';
import CheckImageDataModel from '../models/checkImageData';

export default Layout.extend({
    template: loadingTemplate,
    className: 'CheckImageView',

    initialize(options) {
        this.model = new CheckImageDataModel(options.model.toJSON());

        this.model.fetch()
            .then(this.receiveCheckImageData.bind(this))
            .catch(this.showErrorView.bind(this));
    },

    /**
     * Set the front and back image attributes of the model and then
     * change the template to the checkImageViewTemplate
     */
    receiveCheckImageData(response) {
        this.model.set({
            frontImage: response.CheckDepositImageData.frontImage.value,
            backImage: response.CheckDepositImageData.backImage.value,
        });
        this.setViewTemplate(checkImageViewTemplate);
    },

    /**
     * Set the template and render the view
     * @param {Object} template - template to render with this view
     */
    setViewTemplate(template) {
        this.template = template;
        this.render();
    },

    /**
     * Show alert with error message from the response
     * @param {Object} err - Error message object from the server
     */
    showErrorView(err) {
        this.model.set('message', err.responseJSON.message.join(' '));
        this.setViewTemplate(errorView);
    },
});
