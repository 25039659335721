import Layout from '@glu/core/src/layout';
import CategoryService from '../service/category';
import PlanCollection from '../collection/plan';
import PlanningLayout from './planningLayout';
import planningSurrogateLayoutTmpl from './planningSurrogateLayout.hbs';

const PlanningSurrogateLayout = Layout.extend({
    className: 'widget-plan-compare-view widget',
    template: planningSurrogateLayoutTmpl,

    regions: {
        pageRegion: '#region',
    },

    initialize() {
        this.plans = null;
        this.service = new CategoryService();

        this.getPlans().then(plans => this.initViews(plans));

        this.setNotificationData({
            title: 'cashFlowPlanning',
        });
    },

    getPlans() {
        return new Promise((resolve) => {
            const plans = new PlanCollection(
                {},
                {
                    categoryService: this.service,
                },
            );
            plans.once('sync', resolve);
        });
    },

    initViews(plans) {
        this.plans = plans;
        this.show();
    },

    show() {
        if (!this.plans) {
            return;
        }

        const view = new PlanningLayout({
            plans: this.plans,
            categoryservice: this.service,
        });

        this.pageRegion.show(view);
        this.notifyPageLoaded();
    },
});

export default PlanningSurrogateLayout;
