import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import cache from 'system/utilities/cache';
import constants from './listViewConfigConstants';
import lvcStorage from './listViewConfigStorage';

const validPropertyTypes = {
    FILTERFIELDS: 'array',
    SORTFIELDS: 'array',
    ROWSPERPAGE: 'number',
    STARTROW: 'number',
    CURRENTPAGE: 'number',
    VIEWID: 'string',
    COLUMNS: 'array',
    ADDITIONALSEARCH: 'array',
};

let allLVC = null;

const createLVCContainerKey = () => `DGB-GRID-${userInfo.get('group')}-${userInfo.get('id')}`;

/**
 * compose the key for the context object
 * @param {object} context - context should contain productCode, functionCode
 * and typeCode
 * @returns {string} key - usergroup-userid-productcode-functioncode-typecode[-actionMode]
 * actionMode is optional
 */
const createKeyFromContext = context => util.compact([
    userInfo.get('group'),
    userInfo.get('id'),
    context.productCode,
    context.functionCode,
    context.typeCode,
    context.subType !== '*' ? context.subType : undefined,
    context.actionMode,
    // TODO fix misspelling and reference to misspelling
    context.additionalLVCKey || context.additonalLVCKey,
]).join('-');

/**
 * compose the key for the listview
 * @param {object} listView
 * @returns {string} key - usergroup-userid-productcode-functioncode-typecode[-actionMode]
 * actionMode is optional
 */
const createKey = listView => createKeyFromContext(listView.contextDef);

/**
 * event handler for pageView change page or change rows per page events.
 * events are broadcast on the appBus so we need to make sure that the listener
 * is the current listview
 *
 * @param {object} pager view
 * @returns {boolean} false when not called for current listview
 */
function paginateHandler(pager) {
    // get context from pager
    const targetKey = (pager.wrapper && pager.wrapper.lvc) ? pager.wrapper.lvc.getKey() : '';
    const { lvc } = this;
    const key = lvc.getKey();

    // check for a match on key
    if (key !== targetKey) {
        return false;
    }
    // get the listViewConfig currentPage and pageSize properties
    const currentPage = lvc.get(constants.STARTROW);
    const pageSize = lvc.get(constants.ROWSPERPAGE);
    const newCurrentPage = pager.currentPage;
    const newPageSize = pager.pageSize;

    if (currentPage !== newCurrentPage) {
        lvc.set(constants.CURRENTPAGE, newCurrentPage);
        lvc.set(constants.STARTROW, (((newCurrentPage - 1) * newPageSize) + 1));
    }
    if (pageSize !== newPageSize) {
        lvc.set(constants.ROWSPERPAGE, newPageSize);
    }
    return true;
}

/**
 * attach event handlers for listview events like sort, filter, etc
 * when these events are triggered, the listviewConfig needs to be updated
 * @param {object} listView - listview view
 */
const setUpListeners = (listView) => {
    listView.listenTo(listView.appBus, 'grid:paginate', paginateHandler.bind(listView));
};

/**
 * initialize the listViewConfig for the key.  set a timestamp
 * @param key
 */
const initLVC = (key) => {
    allLVC[key] = {
        TIMESTAMP: Date.now(),
    };
};

function createLVC(listView, context) {
    // if listViewConfig already exists then return it
    if (listView && listView.lvc) {
        return listView.lvc;
    }

    /**
     * validates that the value is the correct type for the property (private method)
     * @param {string} prop - property to test
     * @param {number|string|array} value - value to validate for the incoming property
     */
    const isValueValidType = (prop, value) => {
        const validType = validPropertyTypes[prop];
        if (validType === 'array') {
            return (value instanceof Array);
        }
        // this will return false if the property is not a valid property
        return typeof value === validType;     //eslint-disable-line
    };

    /*
     * if context is valid, use that for the listViewConfig key
     * otherwise, if the listview has a configContext object, then use that for the key
     * otherwise, use the listView contextDef to create the key
     */
    const configContext = context || (listView ? listView.options.configContext : null);

    // create the key for this listView
    let key = null;
    if (configContext) {
        key = createKeyFromContext(configContext);
    } else if (listView) {
        key = createKey(listView);
    }

    let lvc = null;
    // if the listviewConfig doesn't exist yet, then create it
    if (!util.isNullOrUndefined(key)) {
        if (!allLVC[key]) {
            initLVC(key);
        } else if (typeof allLVC[key] === 'string') {
            allLVC[key] = JSON.parse(allLVC[key]);
        }
        lvc = allLVC[key];
    }

    // set up events for the listview
    if (listView) {
        setUpListeners(listView);
    }

    return {
        // for testing ONLY
        all() {
            return allLVC;
        },
        // end for testing only
        /**
         * get the key for the LVC
         * @returns {string} key of LVC (usergroup-userid-productcode-functioncode-typecode)
         */
        getKey() {
            return key;
        },

        /**
         * returns the listview config
         * @returns {object} listview config object
         */
        getConfig() {
            return lvc;
        },

        /**
         * returns the property value for the listViewConfig
         * @param property - property to retreive. valid properties are
         * FILTERFIELDS - 'FILTERFIELDS' - {array} array of filterfields
         * SORTFIELDS - 'SORTFIELDS' - {array} array of sortFields
         * ROWSPERPAGE: 'ROWSPERPAGE' - {number} number of rows per page
         * STARTROW: 'STARTROW' - {number} starting row to display in the listview
         * VIEWID: 'VIEWID' - {string} view id name
         * COLUMNS - 'COLUMNS' - array of column info containing the attributes of the
         *         column model
         * ADDITIONALSEARCH: 'ADDITIONALSEARCH' - array of server side filter fields
         * @return - the value of the specified property for the key-specified listViewConfig
         */
        get(property) {
            return lvc[property];
        },

        /**
         * updates the listViewConfig sets the input property to the input value
         * @param {string} property - property to set. valid properties are
         * FILTERFIELDS - 'FILTERFIELDS' - {array} array of filterfields
         * SORTFIELDS - 'SORTFIELDS' - {array} array of sortFields
         * ROWSPERPAGE: 'ROWSPERPAGE' - {number} number of rows per page
         * STARTROW: 'STARTROW' - {number} starting row to display in the listview
         * VIEWID: 'VIEWID' - {string} view id name
         * COLUMNS - 'COLUMNS' - array of column info containing the attributes of the
         *         column model
         * ADDITIONALSEARCH: 'ADDITIONALSEARCH' - array of server side filter fields
         * @param {*} value
         * @param {boolean} immediate - if true, then invoke the localstorage save immediately
         * @return - the (updated) listViewConfig
         */
        set(property, value, immediate) {
            if (!isValueValidType(property, value)) {
                return lvc;
            }

            lvc[constants.TIMESTAMP] = Date.now();
            lvc[property] = value;
            lvcStorage.setConfig(createLVCContainerKey(), this.getKey(), lvc, immediate);
            return lvc;
        },

        /**
         * Removes a property from the listViewConfig
         * @param {string} property - property to set. valid properties are
         * FILTERFIELDS - 'FILTERFIELDS' - {array} array of filterfields
         * SORTFIELDS - 'SORTFIELDS' - {array} array of sortFields
         * ROWSPERPAGE: 'ROWSPERPAGE' - {number} number of rows per page
         * STARTROW: 'STARTROW' - {number} starting row to display in the listview
         * VIEWID: 'VIEWID' - {string} view id name
         * COLUMNS - 'COLUMNS' - array of column info containing the attributes of the
         *         column model
         */
        remove(property) {
            delete lvc[property];
            lvcStorage.setConfig(createLVCContainerKey(), this.getKey(), lvc);
        },

        /**
         * replace listViewConfig
         * @param {object} newConfig - listView configuration object
         * It can be an empty object or it can contain one or more of the following
         * properties:
         * FILTERFIELDS - 'FILTERFIELDS' - {array} array of filterfields
         * SORTFIELDS - 'SORTFIELDS' - {array} array of sortFields
         * ROWSPERPAGE: 'ROWSPERPAGE' - {number} number of rows per page
         * STARTROW: 'STARTROW' - {number} starting row to display in the listview
         * VIEWID: 'VIEWID' - {string} view id name
         * COLUMNS - 'COLUMNS' - array of column info containing the attributes of the
         *         column model
         * @return - the (updated) listViewConfig
         *
         */
        replace(newConfig) {
            const config = newConfig;
            if (lvc === config) {
                return lvc;
            }
            config[constants.TIMESTAMP] = Date.now();
            lvc = config;
            lvcStorage.setConfig(createLVCContainerKey(), this.getKey(), lvc);

            return lvc;
        },

        /**
         * resets the page to the first page,
         * this is needed after a filter or sort
         */
        resetToFirstPage() {
            this.set(constants.CURRENTPAGE, 1);
            this.set(constants.STARTROW, 1);
            lvcStorage.setConfig(createLVCContainerKey(), this.getKey(), lvc);
        },

        /**
         * resets filters and moves to first page.
         * After a search (check inquiry, estatement or image search) we need to reset
         * to the first page and clear out the filters
         */
        searchRefresh() {
            this.resetToFirstPage();
            this.remove(constants.FILTERFIELDS);
            lvcStorage.setConfig(createLVCContainerKey(), this.getKey(), lvc);
        },

        /**
         * @param {string} itemKey - listViewConfig (gridState) key
         * @param {object} incLvc - listViewConfig
         * @param {boolean} immediate - flag to indicate if the store should be immediate or
         * delayed.
         */
        resetLvc(itemKey, incLvc, immediate) {
            const localLvc = incLvc;
            Object.keys(localLvc).forEach((item) => {
                if (item === constants.TIMESTAMP) {
                    return; // don't delete timestamp
                }
                delete localLvc[item];
            });
            lvcStorage.setConfig(createLVCContainerKey(), itemKey, localLvc, immediate);
        },

        /**
         * resets the listViewConfig to only contain timestamp
         */
        reset() {
            this.resetLvc(this.getKey(), lvc, false);
            this.set(constants.TIMESTAMP, Date.now());
        },

        /**
         * resets the listViewConfig of all the listViews in the local storage
         */
        resetAll() {
            const allLVCItems = lvcStorage.get(createLVCContainerKey());
            Object.keys(allLVCItems).forEach((itemKey) => {
                if (itemKey === 'lastUpdate') {
                    return;
                }
                const newLVC = JSON.parse(allLVCItems[itemKey]);
                this.resetLvc(itemKey, newLVC, true);
            });
        },

        /**
         * sets the columns in the listview configuration
         * this function is invoked when reordering or resizing columns
         * @param {array} columns - array of column models
         * @param {boolean} immediate - if true, then invoke the localstorage save immediately
         *  this should be only true for unit testing
         */
        setColumns(columns, immediate) {
            if (!columns || columns.length === 0) {
                return;
            }
            // store attributes for each column
            const currentColumns = columns.map(col => col.toJSON());
            // remove selector column.
            if (currentColumns[0].field === 'SELECTOR') {
                currentColumns.shift();
            }
            // store currentColumns in listViewConfig
            this.set(constants.COLUMNS, currentColumns);
            lvcStorage.setConfig(createLVCContainerKey(), this.getKey(), lvc, immediate);
        },
    };
}

export default {
    /**
     * getInstance returns the listView accessor functions
     * @param {object} listView
     * @param {object} [context]
     * @returns {{all, exists, getKey, getConfig, get, set, replace, resetToFirstPage}}
     */
    getInstance(listView, context) {
        const containerKey = createLVCContainerKey();
        /*
         * get out grid state for this usergroup/userid from storage
         * if storage is unavailable use the existing allLVC
         */
        allLVC = lvcStorage.get(containerKey, allLVC);

        // if the lvc container doesn't exist yet, then 'create it'
        if (!allLVC) {
            cache.set(containerKey, { lastUpdate: Date.now() });
            allLVC = cache.get(containerKey);
            lvcStorage.set(containerKey, allLVC, true);
        }

        // return an object, associated with this listviewConfig, with access methods
        return createLVC(listView, context);
    },
};
