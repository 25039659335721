import i18n from 'ptx/system/i18n';
import i18nPtxReact from 'ptxReact/system/i18n/en-us';

const localeCustomizations = {
    Cheque: 'Check',
    pageCannotBeFound: 'PCM: Sorry, the PT-X Connect page you\'re looking for cannot be found',
    youAreNotPermitted: 'PCM: Sorry, you are not permitted to view this PT-X Connect page',
    filter: 'Filter',
    'validator.exists': 'Value cannot be empty',
    'validator.isNumeric': 'Value should be numeric',
    'validator.minValue': 'Value must be in the correct range',
    chequeProfilePermissions: 'Check Document Profiles',
    documentProfilePermissions: 'eSend Document Profiles',
    printerPermissions: 'Printers',
    'DataComponents.ViewOf': 'View of',
    'dataComponents.viewof': 'View of',
    'ViewOf': 'View of',
    dataComponents: {
        actions: 'Actions',
        all: 'All',
        apply: 'Apply',
        back: 'Back',
        between: 'Between',
        betweenCriteria: 'Between {0} and {1}',
        cancel: 'Cancel',
        changed: 'Changed',
        clear: 'Clear',
        column: { none: 'No items found', search: 'Search' },
        columns: 'Columns',
        contains: 'Contains',
        default: 'Default',
        delete: 'Delete',
        deleteThisView: 'Delete this view?',
        display: 'Display',
        emptyHeader: 'No views available',
        enterMax: 'Enter Maximum',
        enterMin: 'Enter Minimum',
        equals: 'Equals',
        filters: 'Filters',
        findColumnByName: 'Find Column By Name',
        from: 'from',
        greaterThan: 'Greater than',
        hidden: 'Hidden',
        hideAll: 'Hide All',
        hideValues: 'Hide Values',
        isGreaterThan: 'Is Greater Than',
        isLessThan: 'Is Less Than',
        left: 'Left',
        lessThan: 'Less than',
        maxPrimary: 'Max of',
        next: 'Next',
        no: 'No',
        noResultsFound: 'No results found',
        numberFilterType: 'Number Filter Type',
        numberInput: 'Number Input',
        pin: 'Pin',
        remove: 'Remove',
        rename: 'Rename',
        right: 'Right',
        save: 'Save',
        saveAs: 'Save As',
        select: 'Select',
        selectAll: 'Select All',
        setDefault: 'Set Default',
        showValues: 'Show Values',
        to: 'to',
        unpin: 'Unpin',
        unpinAllColumns: 'Unpin all columns',
        viewName: 'View Name',
        viewOf: 'View {0} of {1}',
        xHidden: '{0} Hidden',
        xVisible: '{0} Visible',
        yes: 'Yes'
    },
    gridReact: {
        headerCell: {
            apply: 'Apply',
            clear: 'Clear',
            left: 'Left',
            menu: 'Menu',
            pin: 'Pin',
            pinColumn: 'Pin Column',
            right: 'Right',
            sort: 'Sort',
            unpin: 'Remove Pin'
        },
        loading: 'Loading',
        noRowsToShow: 'There is no content',
        pinColumn: 'Pin Column',
        close: 'close',
        columns: 'Columns',
        downloadCsv: 'Download as CSV',
        downloadExcel: 'Download as Excel',
        export: 'Export',
        filters: 'Filters',
        lastSync: 'As of {0}',
        lastSyncLabel: 'Last Sync',
        print: 'Print',
        search: {
            button: 'Apply Search Query',
            clear: 'Clear',
            label: 'Search',
            placeholder: 'Search'
        }
    },
    name: 'Name',
};

export default function () {
    return Object.assign({}, i18nPtxReact('', '', '', ''), i18n, localeCustomizations);
}

