var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "list-builder-spinner-disabled";
},"3":function(container,depth0,helpers,partials,data) {
    return "list-builder-spinner-active";
},"5":function(container,depth0,helpers,partials,data) {
    return "list-builder-loaded";
},"7":function(container,depth0,helpers,partials,data) {
    return "list-builder-loading";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <input type=\"text\" placeholder="
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.search",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":39},"end":{"line":10,"column":65}}}))
    + " size=\"20\" id=\"source-list-search-filter-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":10,"column":106},"end":{"line":10,"column":113}}}) : helper)))
    + "\" class=\"form-control source-list-search-filter show-search\">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"dataIsLoaded") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":12},"end":{"line":1,"column":103}}})) != null ? stack1 : "")
    + "\">\n    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"spinner") || (depth0 != null ? lookupProperty(depth0,"spinner") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"spinner","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":17}}}) : helper))) != null ? stack1 : "")
    + "\n</div>\n\n<section class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"dataIsLoaded") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":90}}})) != null ? stack1 : "")
    + " source-section\">\n    <div class=\"source-section-header common-header\">\n        <span class=\"source-section-header-text\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"sourceListHeader") || (depth0 != null ? lookupProperty(depth0,"sourceListHeader") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sourceListHeader","hash":{},"data":data,"loc":{"start":{"line":7,"column":49},"end":{"line":7,"column":69}}}) : helper)))
    + "</span>\n        <label class=\"sr-only\" for=\"source-list-search-filter-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":8,"column":62},"end":{"line":8,"column":69}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"filter") || (depth0 != null ? lookupProperty(depth0,"filter") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"filter","hash":{},"data":data,"loc":{"start":{"line":8,"column":71},"end":{"line":8,"column":81}}}) : helper)))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showSearch") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":11,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div data-region=\"sourceListRegion\"></div>\n\n    <div class=\"source-list-controls\">\n        "
    + alias4(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"btn btn-tertiary","textVisible":true,"text":(depth0 != null ? lookupProperty(depth0,"moveSelectedItems") : depth0),"name":"logout","action":"move-selected-items"},"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":17,"column":129}}}))
    + "\n        "
    + alias4(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"btn btn-tertiary","textVisible":true,"text":(depth0 != null ? lookupProperty(depth0,"moveAllItems") : depth0),"name":"logout","action":"move-all-items"},"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":18,"column":119}}}))
    + "\n        "
    + alias4(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"btn btn-tertiary","textVisible":true,"text":(depth0 != null ? lookupProperty(depth0,"clearSourceSelection") : depth0),"name":"remove-columns","action":"clear-source-selection"},"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":19,"column":143}}}))
    + "\n    </div>\n</section>\n\n<section class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"dataIsLoaded") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":23,"column":90}}})) != null ? stack1 : "")
    + " destination-section target-section\">\n    <h3 class=\"target-section-header target-section-header-text\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"targetListHeader") || (depth0 != null ? lookupProperty(depth0,"targetListHeader") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"targetListHeader","hash":{},"data":data,"loc":{"start":{"line":24,"column":65},"end":{"line":24,"column":85}}}) : helper)))
    + "</h3>\n\n    <div data-region=\"targetListRegion\"></div>\n\n    <div class=\"target-list-controls\">\n        <div class=\"source-list-controls\">\n            "
    + alias4(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"btn btn-tertiary","textVisible":true,"text":(depth0 != null ? lookupProperty(depth0,"removeSelectedItems") : depth0),"name":"close","action":"remove-selected-items"},"data":data,"loc":{"start":{"line":30,"column":12},"end":{"line":30,"column":136}}}))
    + "\n            "
    + alias4(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"btn btn-tertiary","textVisible":true,"text":(depth0 != null ? lookupProperty(depth0,"removeAllItems") : depth0),"name":"close","action":"remove-all-items"},"data":data,"loc":{"start":{"line":31,"column":12},"end":{"line":31,"column":126}}}))
    + "\n            "
    + alias4(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"btn btn-tertiary","textVisible":true,"text":(depth0 != null ? lookupProperty(depth0,"clearTargetSelection") : depth0),"name":"remove-columns","action":"clear-target-selection"},"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":32,"column":147}}}))
    + "\n        </div>\n    </div>\n</section>\n";
},"useData":true});