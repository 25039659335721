import ItemView from '@glu/core/src/itemView';
import model from '@glu/core/src/model';
import $ from 'jquery';
import util from '@glu/core/src/util';
import validation from 'validation';
import scroll from 'common/util/scroll';
import forgottenPassword from 'system/webseries/api/forgottenPassword';
import tmpl from './challenge.hbs';

const Model = model.extend({
    validators: {
        question1: {
            description: 'An answer',
            exists: true,
        },

        question2: {
            description: 'An answer',
            exists: true,
        },

        question3: {
            description: 'An answer',
            exists: true,
        },
    },
});

export default ItemView.extend({
    template: tmpl,
    binding: true,
    className: 'challenge-questions modal',

    initialize(options) {
        this.model = new Model();
        this.model.userId = options.user.name;
        this.model.userGroup = options.user.realm;
        this.questions = options.questions;
        this.notModal = options.notModal;
        this.model.clear();
        this.mapFields();
        this.listenTo(this.model, 'invalid', scroll.scrollToFirstError);

        this.checkIfNotModal();
    },

    checkIfNotModal() {
        // remove modal class from element if this.notModal is true
        if (this.notModal) {
            this.$el.removeClass('modal');
        }
    },

    mapFields() {
        // set new name for validators
        const map = {
            question1: `${this.questions[0].name}_${this.questions[0].columnId}`,
            question2: `${this.questions[1].name}_${this.questions[1].columnId}`,
            question3: `${this.questions[2].name}_${this.questions[2].columnId}`,
        };
        const validators = {};
        // populate validators obj with renamed keys, keep original values
        util.each(this.model.validators, (value, key) => {
            let localKey = key;
            localKey = map[localKey];
            validators[localKey] = value;
        });
        this.model.validators = validators;
    },

    cancel() {
        this.close();
        this.trigger('cancel');
    },

    save() {
        const myArray = [];
        const modelAttributes = this.model.attributes;

        // validate the form
        if (!this.model.isValid()) {
            this.model.trigger('invalid');
            return;
        }

        Object.keys(modelAttributes).forEach((attribute) => {
            const key = attribute.split('_');
            myArray.push({
                columnId: key[1],
                questionCd: key[0],
                answer: modelAttributes[attribute],
            });
        });

        const data = {
            userId: this.model.userId.toUpperCase(),
            userGroup: this.model.userGroup.toUpperCase(),

            data: {
                item: myArray,
            },
        };
        this.validateAnswers(data).then((res) => {
            this.parseValidateResponse(res);
        });
    },

    validateAnswers(data) {
        return forgottenPassword.validateAnswers(data);
    },

    parseValidateResponse(res) {
        if (!res.valid || res.lockedOut === true) {
            validation.show({
                generic: res.message,
            }, this);
            // return focus to validation msg
            $('.validation-group').focus();
        } else {
            this.trigger('save', {
                resetPwdToken: res.token,
            });
            if (!this.notModal) {
                this.close();
            }
        }
    },

    templateHelpers() {
        const self = this;

        return {
            getQuestion(number) {
                return self.questions[number].value;
            },

            getColName(number) {
                return `${self.questions[number].name}_${self.questions[number].columnId}`;
            },
            notModal: this.notModal,
        };
    },
});
