import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import GridApi from 'common/dynamicPages/api/grid';
import store from 'system/utilities/cache';
import constants from 'app/reports/constants';
import workspaceHelper from 'common/workspaces/api/helper';
import profileApi from 'app/reports/api/profile';
import ListView from 'common/dynamicPages/views/workflow/list';
import ContextApi from 'common/dynamicPages/api/context';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import template from './reportsListView.hbs';

const ReportsListView = Layout.extend({
    template,

    events: {
        'click [data-hook="refresh-button"]': 'refreshData',
    },

    initialize() {
        this.setNotificationData({
            title: 'ReportsManagement',
        });

        this.contextDef = ContextApi.menuContext.getContext('RPT_MGMT');
        this.gridView = GridApi.createServiceGridView({
            context: {
                serviceName: '/tableMaintenance/reports',
            },

            selector: 'none',
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,

            additionalSearchFields: [{
                dataType: 'text',
                fieldName: 'SHAREDFLAG',
                operator: '=',

                fieldValue: [
                    'TRUE',
                ],
            }],
            gridOptions: { caption: this?.contextDef?.name },
            lvc: this.lvc,
        });
        ListView.prototype.setListViewConfig.call(this);
    },

    setWidget(widget) {
        this.listenTo(widget.model, 'change:size', this.handleWidgetSizeChange);
    },

    handleWidgetSizeChange() {
        util.defer(() => {
            if (this.gridView && this.gridView.grid) {
                this.gridView.grid.tableView.setWidth();
            }
        });
    },

    onRender() {
        this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
        this.listenTo(this.gridView.getRows(), 'sync', ListView.prototype.updateRefreshTimestamp);
        ListView.prototype.setupGridAvailableListener.call(this);

        this.content.show(this.gridView);
        this.notifyPageLoaded();
    },

    gridRowAction(optionsParam) {
        return new Promise((resolve) => {
            const options = optionsParam;
            options.model.action = options.action.toUpperCase();
            if (options.action.toUpperCase() === 'VIEWRPT'
                || options.action.toUpperCase() === 'VIEW'
                || options.action.toUpperCase() === 'MODIFY') {
                store.set(constants.REPORTING_CONTEXT, options.model.context);
                store.set(constants.REPORTING_VIEWMODEL, options.model);
                this.navigateTo(constants.REPORT_VIEW_ROUTE);
                resolve();
            }
            if (options.action.toUpperCase() === 'DELETE') {
                const promiseMe = profileApi.deleteProfile(options.model.get('PROFILENUM'));
                promiseMe.then(() => {
                    this.gridView.refreshGridData();
                    resolve();
                });
            } else {
                resolve();
            }
        });
    },
});

let list = ReportsListView;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list);
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'REPORTS_MANAGEMENT',
    view: list,
    options: {},
});

const exportedList = list;

export default exportedList;
