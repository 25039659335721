import React, { useState, useEffect } from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import { appBus } from '@glu/core';
import { asView } from 'common/util/reactUtil';
import TransformLocale from './TransformLocale';

const propTypes = {
    localeKey: PropTypes.string.isRequired,
    params: arrayOf(PropTypes.shape({})),
    tagName: PropTypes.string,
    tagClass: PropTypes.string,
};

const defaultProps = {
    params: [],
    tagName: 'span',
    tagClass: '',
};

const TransformLocaleWrapper = ({
    localeKey, params, tagName, tagClass,
}) => {
    const [theKey] = useState(localeKey);
    const [theParams, setTheParams] = useState(params);

    useEffect(() => {
        const updateMessage = (newParams) => {
            setTheParams(newParams);
        };
        appBus.on(`update:localeMessage:${localeKey}`, updateMessage);

        return (() => {
            appBus.off(`update:localeMessage:${localeKey}`, updateMessage);
        });
    }, []);  // eslint-disable-line
    const Tag = tagName;
    return (
        <Tag className={tagClass}><TransformLocale localeKey={theKey} params={theParams} /></Tag>
    );
};

TransformLocaleWrapper.propTypes = propTypes;
TransformLocaleWrapper.defaultProps = defaultProps;

/**
 * Provide a utility function for leveraging this in a Backbone context
 * @param {Object} options - Options/props to be sent along to wrapped component
 */
export const createTransformLocaleView = options => asView(TransformLocaleWrapper, options);

export default TransformLocaleWrapper;
