import hash from 'common/util/promise-hash';
import Collection from '@glu/core/src/collection';
import transform from 'common/util/transform';
import Account from 'app/accounts/models/account';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import services from 'services';

const Accounts = Collection.extend({
    model: Account,
    url: services.generateUrl('/inquiry/getData'),

    initialize(options) {
        this.accountType = options && options.accountType;
        this.sections = options && options.sections;
    },

    parse(response) {
        return response.inquiryResponse.rows.map(row => transform.pairsToHashUnescape(row.columns, 'fieldName', 'fieldValue'));
    },
    sync(method, collection, options) {
        if (method !== 'read') {
            throw new Error(`This collection only knows how to read, but it was asked to ${method}.`);
        }

        const post = sectionId => http.post(util.result(collection, 'url'), {
            requestHeader: {},
            inquiryRequest: {
                searchCriteria: {
                    action: {
                        typeCode: 'GIRACCT',
                        entryMethod: 0,
                        actionMode: 'SELECT',
                        functionCode: 'INST',
                        productCode: 'GIR',
                    },
                    inquiryId: 22005,
                    searchType: '5',
                    searchFields: [],
                    customFilters: [{
                        filterName: 'Depends',
                        filterParam: ['tabId', 'ALLACCOUNTS'],
                    }, {
                        filterName: 'Depends',
                        filterParam: ['sectionId', sectionId],
                    }],
                },
            },
        });

        const promises = {};
        this.sections.forEach((sectionId) => {
            promises[sectionId] = post(sectionId);
        });

        return hash(promises).then((data) => {
            const rows = util.chain(data)
                .map((response, sectionId) => {
                    response.inquiryResponse.rows.forEach((row) => {
                        row.columns.push({
                            fieldName: 'sectionId',
                            fieldValue: sectionId,
                        });
                    });
                    return response.inquiryResponse.rows;
                })
                .flatten(1)
                .value();
            return {
                inquiryResponse: {
                    rows,
                },
            };
        }).then(options.success).catch(options.error);
    },

});

export default Accounts;
