import InquiryCollection from 'common/inquiry/inquiryCollection';

export default InquiryCollection.extend({
    initialize(models, options) {
        ({
            inquiryId: this.inquiryId,
            typeRestrictions: this.typeRestrictions,
        } = options);
    },

    requestBody() {
        return {
            requestHeader: {

            },

            inquiryRequest: {
                searchCriteria: {
                    action: {
                        typeCode: 'SEARCH',
                        entryMethod: 0,
                        actionMode: 'SEARCH',
                        functionCode: 'EXPORT',
                        productCode: 'GIR',
                    },

                    inquiryId: this.inquiryId ?? 22022,
                    searchType: '5',
                },
            },
        };
    },

    parse(res) {
        const types = InquiryCollection.prototype.parse.call(this, res);
        return (this.typeRestrictions)
            ? this.filterAllowedTypes(types, this.typeRestrictions) : types;
    },

    /**
     * @param {array} returnedTypes - array of models from type collection
     * @param {array} allowedTypes - array of export types for the user based on
     * their permissions
     */
    filterAllowedTypes(returnedTypes, allowedTypes) {
        return returnedTypes.filter(type => allowedTypes.indexOf(type.TYPECODE) !== -1);
    },
});
