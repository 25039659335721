var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"PanelApproval-colItem checkbox\">\n                    <label>\n                        <input type=\"checkbox\"\n                               value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"paymentType") || (depth0 != null ? lookupProperty(depth0,"paymentType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"paymentType","hash":{},"data":data,"loc":{"start":{"line":32,"column":38},"end":{"line":32,"column":53}}}) : helper)))
    + "\"\n                               data-hook=\"getPaymentCheckbox\"\n                               data-bind=\"model\"\n                               name=\"paymentMethods\"\n                               data-nullable/>\n                        "
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":37,"column":24},"end":{"line":37,"column":33}}}) : helper)))
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((depths[1] && lookupProperty(depths[1],"isFile"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"paymentType") : depth0),{"name":"../isFile","hash":{},"data":data,"loc":{"start":{"line":39,"column":30},"end":{"line":39,"column":53}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":24},"end":{"line":46,"column":31}}})) != null ? stack1 : "")
    + "                    </label>\n                </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"span-popover-trigger icon-info\"\n                                  data-content=\""
    + container.escapeExpression(container.lambda(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"fileCheckboxTipLocale")), depth0))
    + "\"\n                                  data-placement=\"auto\"\n                                  data-toggle=\"popover\"\n                                  data-trigger=\"hover focus\">\n                            </span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"checkbox\">\n                    <label>\n                        <input type=\"checkbox\"\n                               value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":61,"column":38},"end":{"line":61,"column":46}}}) : helper)))
    + "\"\n                               data-hook=\"getEntryMethodCheckbox\"\n                               data-bind=\"model\"\n                               name=\"entryMethods\"\n                               data-nullable/>\n                        "
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":66,"column":24},"end":{"line":66,"column":33}}}) : helper)))
    + "\n                    </label>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset class=\"PanelApproval-group\">\n    <div class=\"form-group\">\n        <p>\n            <span class=\"icon-exclamation-solid\"></span>\n            <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Some.Payment.Entry.Methods.Not.Eligible",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":18},"end":{"line":5,"column":87}}}))
    + "</span>\n        </p>\n\n        <h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Payment.Methods",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":57}}}))
    + "</h3>\n\n        <div class=\"checkbox\">\n            <label>\n                <input type=\"checkbox\"\n                       value=\"*\"\n                       name=\"allPaymentMethods\"\n                       data-hook=\"getAllPaymentMethodsCheckbox\"\n                       data-bind=\"model\"/>\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Appy.All.Payment.Methods",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":17,"column":70}}}))
    + "\n\n                <span class=\"span-popover-trigger icon-info\"\n                  data-content=\""
    + alias2((lookupProperty(helpers,"allFutureLocale")||(depth0 && lookupProperty(depth0,"allFutureLocale"))||alias3).call(alias1,"PS.panelApproval.Payment.Methods",{"name":"allFutureLocale","hash":{},"data":data,"loc":{"start":{"line":20,"column":32},"end":{"line":20,"column":86}}}))
    + "\"\n                  data-placement=\"auto\"\n                  data-toggle=\"popover\"\n                  data-trigger=\"hover focus\">\n                </span>\n            </label>\n        </div>\n        <div class=\"PanelApproval-col\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"paymentMethods") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":49,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</fieldset>\n<fieldset class=\"PanelApproval-group\">\n    <div class=\"form-group\">\n        <h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Entry.Methods",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":55,"column":12},"end":{"line":55,"column":55}}}))
    + "</h3>\n        <div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"entryMethods") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":12},"end":{"line":69,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</fieldset>\n<fieldset class=\"PanelApproval-group\">\n    <div class=\"form-group\">\n        <h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Accounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":75,"column":12},"end":{"line":75,"column":50}}}))
    + "</h3>\n        <div class=\"checkbox\">\n            <label>\n                <input type=\"checkbox\"\n                       value=\"*\"\n                       name=\"allAccounts\"\n                       data-hook=\"getAllAccountsCheckbox\"\n                       data-bind=\"model\"/>\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Assign.All.Accounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":83,"column":16},"end":{"line":83,"column":65}}}))
    + "\n                <span class=\"span-popover-trigger icon-info\"\n                  data-content=\""
    + alias2((lookupProperty(helpers,"allFutureLocale")||(depth0 && lookupProperty(depth0,"allFutureLocale"))||alias3).call(alias1,"PS.panelApproval.Accounts",{"name":"allFutureLocale","hash":{},"data":data,"loc":{"start":{"line":85,"column":32},"end":{"line":85,"column":79}}}))
    + "\"\n                  data-placement=\"auto\"\n                  data-toggle=\"popover\"\n                  data-trigger=\"hover focus\">\n                </span>\n            </label>\n        </div>\n    </div>\n    <div class=\"form-group\">\n        <div class=\"row\">\n            <div class=\"col-9\">\n                <div data-region=\"listBuilderRegion\"></div>\n            </div>\n        </div>\n    </div>\n</fieldset>\n";
},"useData":true,"useDepths":true});