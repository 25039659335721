import BaseLoanView from 'app/loans/views/baseLoanView';
import Collection from '@glu/core/src/collection';
import locale from '@glu/locale';
import $ from 'jquery';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import Formatter from 'system/utilities/format';
import store from 'system/utilities/cache';
import scroll from 'common/util/scroll';
import { moveToTopCheck } from 'common/util/deeplinkUtil';
import Loan from 'app/loans/models/modifyTemplate';
import tmpl from 'app/loans/views/template/modifyTemplate/modify.hbs';
import singleLookupHelper from 'common/dynamicPages/views/mdf/componentHelpers/singleLookup';
import loans from 'app/loans/api/common';
import workspaceHelper from 'common/workspaces/api/helper';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import validatorPatterns from 'system/validatorPatterns';
import gridApi from 'common/dynamicPages/api/grid';
import ScheduleWidget from 'app/loans/views/template/scheduleWidgetHelper';
import Dialog from '@glu/dialog';
import WarningDialog from 'common/dynamicPages/views/warningDialog';
import AuditHistorySetup from 'app/auditHistory/util/auditHistorySetup';
import PanelWorkflowAssignment from 'common/template/panelWorkflow/panelWorkflowAssignment';
import panelWorkflowAssignmentUtil from 'common/template/panelWorkflow/util';

export default BaseLoanView.extend({
    initialize(options) {
        this.model = new Loan();
        this.model.jsonData = options.jsonData;
        this.model.isCopy = options.isCopy;
        this.isTemplate = true;
        this.contextKey = 'template_listView_corp';
        this.preferences = options.preferences;
        this.fromAccount = options.debitAccountCollection.toJSON();
        this.toAccount = options.creditAccountCollection.toJSON();
        this.data = options.model;
        // set initial account values. update when a selection is made
        this.debitAccount = this.data.DEBIT_ACCOUNT_NUMBER;
        this.creditAccount = this.data.BENE_ACCOUNT;
        this.placeholder = null;
        this.model.set(this.data);
        if (!this.model.isCopy) {
            // set model id for update operation
            this.model.set('id', this.data.TNUM);
        }
        this.title = options.title || '';
        this.debit = options.debitAccountCollection || new Collection();
        this.credit = options.creditAccountCollection || new Collection();
        this.debitInquiryId = options.debitInquiryId;
        this.creditInquiryId = options.creditInquiryId;
        this.payMethod = options.payMethod;
        this.typeCode = options.typeCode;
        this.productCode = options.productCode;
        this.functionCode = options.functionCode;
        this.accountDetails = {
            debit: {},
            credit: {},
        };
        this.paymentTotal = 0;
        this.paymentOptions = loans.getAvailablePaymentOptions(options.preferences);
        this.savedOptions = this.getSavedOptions(this.data, this.data.AMOUNTTYPES_SELECTED);
        this.enabledOptions = new Collection(this.paymentOptions);
        this.savedCollection = new Collection(this.savedOptions);
        this.amountOfPaymentOptions = this.enabledOptions.length;
        this.model.set({
            _oldPaymentType: this.typeCode,
            paymentType: this.typeCode,
            PARENTUSERGROUP: userInfo.get('group'),

            // set the default value in the model to match the first option in the comboBox
            SELECTION_0: this.paymentOptions[0].key,
        });
        this.model.fieldData = {
            DEBIT_ACCOUNT_NUMBER: {
                dependsOn: ['CREDIT_CURRENCY', 'BENE_ACCOUNT'],
            },

            BENE_ACCOUNT: {
                dependsOn: ['DEBIT_CURRENCY', 'DEBIT_ACCOUNT_NUMBER'],
            },
        };
        if (this.typeCode === 'LOANPAY') {
            this.model.context = {
                serviceName: '/payment/loanPayment',
            };
        } else {
            this.model.context = {
                serviceName: '/payment/loanDraw',
            };
        }
        this.initializeOptions = {
            gridApi,
        };
        this.listenTo(this.model, 'modelAction:saveWithWarning', this.saveWithWarning);

        this.showAccountBalance = serverConfigParams.get('DisplayAccountBalanceOnLoanScreens') === 'true';
        this.showInterestRate = serverConfigParams.get('LookupLoanDrawInterestRate') === 'true';
        this.variableRateSubtypes = serverConfigParams.get('LookupLoanDrawInterestRateAcctSubTypes')?.split(',') || [];
    },

    template: tmpl,

    events: util.extend(
        {},
        BaseLoanView.prototype.events,
        {
            'click @ui.$viewHistory': 'viewAuditHistory',
            'click @ui.$getRestrictCheckbox': 'toggleRestrictOnModel',
        },
    ),

    ui: util.extend(
        {},
        BaseLoanView.prototype.ui,
        {
            // Hooks
            $templateCode: '[data-hook="template-code"]',

            $templateCodeCopy: '[data-hook="copy-template-code"]',
            $templateDescription: '[data-hook="template-description"]',
            $templateDescriptionCopy: '[data-hook="copy-template-description"]',
            $getRestrictCheckbox: '[data-hook="getRestrictCheckbox"]',
        },
    ),

    regions: {
        alertRegion: '#alertRegion',
        schedRegion: '[data-hook="getSchedPayRegion"]',
        addAnotherPaymentOptionLinkRegion: '.addAnotherPaymentOptionLinkRegion',
    },

    behaviors: {
        ValidationSupplement: {},
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            moveToTopCheck(this.model);
            // clear out helpPage from cache
            store.unset('helpPage');
            this.loadRequiredData(true);
        } else {
            const clearRecurring = this.showInterestRate &&
                this.variableRateSubtypes?.includes(this.model.get('ACCOUNT_SUBTYPE')) &&
                this.typeCode === 'LOANDRAW';

            /**
             * set up schedule section
             */
            const widgetOptions = {
                state: 'MODIFY',
                context: this.model.context,
                model: this.model,
                parentView: this,
                clearRecurring,
            };

            this.scheduleWidgetObj = new ScheduleWidget(widgetOptions);
            this.schedRegion.show(this.scheduleWidgetObj);

            this.renderFromAccount();
            this.renderToAccount();
            this.setDefaultTotal();
            this.setupCopyBehavior();
            this.addValidatorForSingleOption();
            loans.applyMask(this);
            singleLookupHelper.setupSingleLookups(this);

            const isScheduled = this.model.schedModel || this.model.get('SCHEDULED')
                || (this.parentModel && this.parentModel.get('recur'))
                || this.model.get('recur');
            const typeCode = this.model.get('paymentType');
            const isSingle = (typeCode === 'LOANDRAW' || this.amountOfPaymentOptions === 1);
            const amountField = isSingle ? 'CREDIT_AMOUNT' : 'AMOUNT_0';
            const container = isSingle ? '.field-container' : '.multifield-container-0';

            if (isScheduled) {
                this.ui.$amount.closest(container).addClass('required');
                this.model.addValidator(
                    amountField,
                    {
                        description: locale.get(`loans.${typeCode.toLowerCase()}.amount`),
                        exists: true,
                        minValue: 0.01,
                    },
                );
            } else {
                this.ui.$amount.closest(container).removeClass('required');
                this.model.removeValidator(amountField);
                this.ui.$amount.change();
            }

            /**
             * set inputmask to number to prevent user from entering illegal
             * characters or multiple decimal points
             */
            this.ui.$amount.inputmask('number');

            this.displaySummaryTotal(this.isTemplate);

            // setup for audit history
            AuditHistorySetup.setup(this, this.model);

            // Only show the Panel Workflow Assignment view when panel approval has been enabled
            if (panelWorkflowAssignmentUtil.panelApprovalEnabled) {
                this.panelWorkflowAssignmentRegion.show(new PanelWorkflowAssignment({
                    model: this.model,
                    collection: this.panelWorkflowCodes,
                }));
            }
        }
    },

    setDefaultTotal() {
        if (this.model.get('CREDIT_AMOUNT')) {
            const amount = Formatter.formatNumber(this.model.get('CREDIT_AMOUNT'));
            this.ui.$amountTotal.text(amount);
            this.ui.$summaryAmountTotal.text(amount);
            this.updateSummaryTotal();
        }
    },

    renderFromAccount() {
        this.ui.$debitAccount.comboBox({
            placeholder: locale.get('loans.account.select'),
            allowClear: true,
            matcher: this.maskedInputMatcher.bind(this, 'debit'),
        }).on('change', $.proxy(this.onDebitAccountChanged, this));

        const accountNumber = `-${this.data.DEBIT_ACCOUNT_NUMBER}`;
        const subAccountNumber = util.isEmpty(this.data.DEBIT_SUBACCOUNT_NUM) ? '' : `-${this.data.DEBIT_SUBACCOUNT_NUM}`;
        const debitBankCode = this.data.DEBIT_BANK_CODE;
        this.ui.$debitAccount.val(debitBankCode + accountNumber + subAccountNumber).change();
    },

    renderToAccount() {
        const accountNumber = `-${this.data.BENE_ACCOUNT}`;
        const beneBankCode = this.data.BENE_BANK_CODE;
        const subAccountNumber = util.isEmpty(this.data.BENE_SUBACCOUNT_NUM) ? '' : `-${this.data.BENE_SUBACCOUNT_NUM}`;
        this.ui.$creditAccount.comboBox({
            placeholder: locale.get('loans.account.select'),
            allowClear: true,
            matcher: this.maskedInputMatcher.bind(this, 'credit'),
        }).on('change', $.proxy(this.onLoanAccountChanged, this));

        this.ui.$creditAccount.val(beneBankCode + accountNumber + subAccountNumber).change();
    },

    submit() {
        this.listenTo(this.model, 'invalid', scroll.scrollToFirstError);

        this.beforeSubmit();
        if (!this.model.isValid()) {
            this.model.trigger('invalid');
            this.stopListening(this.model, 'invalid', scroll.scrollToFirstError);
            return;
        }
        this.toggleButtonsEnabled(false);
        const self = this;

        // merge debit and credit attributes into a single object
        const accountAttributes = $.extend(
            null,
            this.accountDetails.debit,
            this.accountDetails.credit,
        );
        // set the selected account numbers
        this.model.set({
            DEBIT_ACCOUNT_NUMBER: this.debitAccount,
            BENE_ACCOUNT: this.creditAccount,
        });

        this.model.set(accountAttributes);
        this.model.save(
            {},
            {
                success(model, confirmResponse) {
                    store.set(`${self.contextKey}-alertMessage`, 'MODIFY');
                    store.set(`${self.contextKey}-confirms`, confirmResponse);
                    workspaceHelper.returnToCurrentWorkspace(self);
                },

                error(response) {
                    self.toggleButtonsEnabled(true);
                    if (response.error.resultType === 'WARNING') {
                        Dialog.custom(new WarningDialog({
                            model: response,
                            methodName: 'SAVE',
                            confirms: response.error.confirms,
                        }));
                    } else {
                        const alertView = loans.getAlertView(response);

                        $(self.alertRegion.$el).hide();

                        $('html, body').animate({
                            scrollTop: 0,
                        }, 100);

                        self.alertRegion.show(alertView);
                        $(self.alertRegion.$el).fadeIn(200, () => alertView.$el.focus());
                    }
                },
            },
        );
    },

    saveWithWarning() {
        loans.saveWithWarning(this);
    },

    cancel() {
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    setPaymentAmountToType(value, index) {
        const type = $(`#selection_${index}`).val();
        this.model.set(type, value);
    },

    beforeSubmit() {
        const amount = this.model.get('CREDIT_AMOUNT');
        const isScheduled = this.model.schedModel || this.model.get('SCHEDULED')
            || (this.parentModel && this.parentModel.get('recur'))
            || this.model.get('recur');

        // the model adequately keeps track if there is only one payment option
        if (this.amountOfPaymentOptions !== 1) {
            /**
             * the model does not adequately keep track of all selected payment options during
             * form interactions. Instead, prior to submitting, they're set one by one below.
             * this function is called here to clear them prior to them being properly set.
             */
            this.clearPaymentDetailsOnModel();

            for (let x = 0; x < this.amountOfPaymentOptions; x += 1) {
                if (this.typeCode === 'LOANPAY' && this.amountOfPaymentOptions > 1) {
                    if (!$(`.multifield-container-${x}`).hasClass('hide')) {
                        const value = $(`#amount_${x}`).val();

                        // For templates only, remove validator on field w/blank value
                        if (value.replace(/\s/g, '').length === 0 && !isScheduled) {
                            this.model.removeValidator(`AMOUNT_${x}`);
                        }

                        const option = $(`#selection_${x}`).val();
                        this.model.set({
                            [option]: value,
                            [`AMOUNT_${x}`]: value,
                            [`SELECTION_${x}`]: option,
                        });
                    }
                }
            }
        }
        // For templates only, remove validator on field w/blank value
        if (this.typeCode === 'LOANDRAW' || this.amountOfPaymentOptions === 1) {
            this.model.removeValidator('AMOUNT_0');
        }
        if (amount.replace(/\s/g, '').length === 0 && !isScheduled) {
            this.model.removeValidator('CREDIT_AMOUNT');
        }
        if (this.model.get('DEBIT_ACCOUNT_NUMBER') === 'NONE') {
            this.model.unset('DEBIT_ACCOUNT_NUMBER');
        }
        if (this.model.get('BENE_ACCOUNT') === 'NONE') {
            this.model.unset('BENE_ACCOUNT');
        }
    },

    setupCopyBehavior() {
        if (this.model.isCopy) {
            $('.template-audit-section').hide();
            this.addCopyValidators();
        }
    },

    addCopyValidators() {
        this.model.addValidator(
            'TEMPLATE_CODE',
            {
                description: locale.get('loans.template.name'),
                exists: true,
                matches: validatorPatterns.TEMPLATECODE_PATTERN,
                overrideError: 'isTemplateCode',
            },
        );
        this.model.addValidator(
            'TEMPLATE_DESCRIPTION',
            {
                description: locale.get('loans.template.desc'),
                exists: true,
            },
        );
    },

    addValidatorForSingleOption() {
        const option = this.enabledOptions.at(0).get('key');
        if (this.enabledOptions.length === 1 && this.typeCode === 'LOANPAY') {
            this.model.validators[option].exists = true;
        } else if (this.enabledOptions.length === 1 && this.typeCode === 'LOANDRAW') {
            delete this.model.validators[option].exists;
        }
    },

    /**
     * - toggle the restrict flag on the model
     * @method toggleRestrictOnModel
     */
    toggleRestrictOnModel() {
        this.model.set('RESTRICTTEMPLATE_FLAG', this.model.get('RESTRICTTEMPLATE_FLAG') === '1' ? '0' : '1');
    },

    viewAuditHistory(e) {
        e.stopImmediatePropagation();
        loans.openDialog(this);
    },

    templateHelpers() {
        const self = this;
        return {
            debitAccounts: self.getArrayOfAccounts(self.fromAccount, self.model, 'debit'),
            creditAccounts: self.getArrayOfAccounts(self.toAccount, self.model, 'credit'),
            originLabel: loans.getOriginLabel(self.typeCode),
            destinationLabel: loans.getDestinationLabel(self.typeCode),

            // @TODO When there is only one option, display only the amount
            paymentOptions: self.enabledOptions.toJSON(),

            getType: self.title,

            getTitle() {
                self.title = self.model.isCopy ? `${locale.get('PAY.createtemplate')} - ` : `${locale.get('ACH.modify')} ${locale.get('PAY.Template')} - `;
                self.title += self.typeCode === 'LOANPAY' ? locale.get('common.loanPayment') : locale.get('common.loanDrawdown');
                return self.title;
            },

            isCopy: self.model.isCopy,
            isModify: !self.model.isCopy,
            isRestricted: self.model.get('RESTRICTTEMPLATE_FLAG') === '1',
            hasMultipleOptions: self.typeCode === 'LOANPAY',
            approved: loans.extractApproversFromModel(self.model.toJSON()),
            unapproved: (self.model.get('UNAPPROVED_BY') !== ''),
            userRejected: self.model.get('APPROVER_REJECTION_BY') !== '',
            singleOptionOnly: self.preferences.singleOnlyPaymentOptionSupported,
            hasBeenModified: this.model.get('MODIFIED_BY') !== '',
            restored: this.model.get('RESTORED_BY') !== '',
            getAmountText: locale.get(`loans.${self.typeCode.toLowerCase()}.amount`),
        };
    },
});
