var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " - "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"transactionType") || (depth0 != null ? lookupProperty(depth0,"transactionType") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"transactionType","hash":{},"data":data,"loc":{"start":{"line":1,"column":110},"end":{"line":1,"column":129}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.account",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":28}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"accountNumber") || (depth0 != null ? lookupProperty(depth0,"accountNumber") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"accountNumber","hash":{},"data":data,"loc":{"start":{"line":1,"column":29},"end":{"line":1,"column":46}}}) : helper)))
    + " "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.transactions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":1,"column":47},"end":{"line":1,"column":76}}}))
    + "        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"transactionType") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":84},"end":{"line":1,"column":136}}})) != null ? stack1 : "")
    + "</h3>\n<div class=\"alert-region\" data-region=\"alertRegion\"></div>\n<div class=\"refresh-transactions-control\"></div>\n<div data-region=\"transactionsGrid\" aria-live=\"polite\"></div>\n";
},"useData":true});