var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"Beneficiary-addForm "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"showEntry") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":40},"end":{"line":3,"column":79}}})) != null ? stack1 : "")
    + "section section-container\">\n            <div class=\"Beneficiary-requiredFields\">\n                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"beneficiaryBlock") || (depth0 != null ? lookupProperty(depth0,"beneficiaryBlock") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"beneficiaryBlock","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":38}}}) : helper))) != null ? stack1 : "")
    + "\n            </div>\n            <div class=\"Beneficiary-secondaryAccountFields\">\n                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"beneficiarySecondaryAccount") || (depth0 != null ? lookupProperty(depth0,"beneficiarySecondaryAccount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"beneficiarySecondaryAccount","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":49}}}) : helper))) != null ? stack1 : "")
    + "\n            </div>\n            <div class=\"Beneficiary-optionalFields\">\n                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"beneficiaryOptional") || (depth0 != null ? lookupProperty(depth0,"beneficiaryOptional") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"beneficiaryOptional","hash":{},"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":41}}}) : helper))) != null ? stack1 : "")
    + "\n            </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAddBeneButtonBlock") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":19,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"Beneficiary-showAdd "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideBeneAdd") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":40},"end":{"line":21,"column":72}}})) != null ? stack1 : "")
    + " \">\n            "
    + container.escapeExpression(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"btn btn-tertiary","textVisible":true,"locale":(depth0 != null ? lookupProperty(depth0,"getAddBeneficiaryLocale") : depth0),"name":"add","action":"openAddBeneficiary"},"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":22,"column":137}}}))
    + "\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "hidden ";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"Beneficiary-buttons button-container\">\n                    <button name=\"ADDBENE\" id=\"ADDBENE\" class=\"btn btn-secondary btn-sm\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.beneficiary.add",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":89},"end":{"line":16,"column":124}}}))
    + "</button>\n                    <button class=\"btn btn-tertiary btn-sm\" name=\"CLEARBENE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.beneficiary.clear",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":77},"end":{"line":17,"column":114}}}))
    + "</button>\n                </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"Beneficiary-grid\">\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"gridBlock") || (depth0 != null ? lookupProperty(depth0,"gridBlock") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"gridBlock","hash":{},"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":28,"column":27}}}) : helper))) != null ? stack1 : "")
    + "\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"section section-container Beneficiary\" data-hook=\"bene"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"viewId") || (depth0 != null ? lookupProperty(depth0,"viewId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"viewId","hash":{},"data":data,"loc":{"start":{"line":1,"column":66},"end":{"line":1,"column":76}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showBeneBlock") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":24,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasGrid") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":4},"end":{"line":30,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});