import ItemView from '@glu/core/src/itemView';
import Dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import modalTmpl from './modal.hbs';

export default ItemView.extend({
    template: modalTmpl,
    binding: true,
    tagName: 'td',
    className: 'sb-modal-cell',

    initialize(options) {
        this.model = options.model;
        this.column = options.column;
        this.cellHeader = this.column.get('title');
        this.cellValue = this.model.get(this.column.get('field'));
    },

    viewModal() {
        Dialog.alert(this.cellValue, this.cellHeader);
    },

    cellValueExists() {
        const modelValue = this.model.get(this.column.get('field'));
        if (modelValue === null || modelValue.length === 0) {
            return false;
        }
        return true;
    },

    templateHelpers() {
        return {
            cellHeader: this.cellHeader,
            cellHeaderExists: !util.isEmpty(this.cellHeader),
            cellValueExists: this.cellValueExists(),
        };
    },
});
