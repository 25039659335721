import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import alert from '@glu/alerts';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import dialog from '@glu/dialog';
import store from '@glu/store';
import httpSetup from 'system/httpSetup';

export default Layout.extend({
    errorStateButtons() {
        return [{
            text: locale.get('button.cancel'),
            className: 'btn btn-primary',
            callback: util.bind(this.cancel, this),
        }];
    },

    handleMFAError(appResCode, response) {
        let errorString;

        if (typeof response === 'string') {
            errorString = locale.get(response);
        } else if (response) {
            errorString = (response.responseJSON.respHeader.message[0])
                ? response.responseJSON.respHeader.message[0] : locale.get((appResCode) || 'mfa.error.message');
        } else {
            errorString = locale.get((appResCode) || 'mfa.error.message');
        }

        this.alertView = alert.danger(
            errorString,
            {
                canDismiss: true,
            },
        );

        this.alertRegion.show(this.alertView);
        this.trigger('dialog:buttons:change', this.errorStateButtons());
    },

    cancel() {
        this.resubmit('cancelled');
    },

    completion() {
        // PCM RELATED
        this.closeModal();
        this.resubmit('resubmit');
        // store challenge action on completion
        store.set('challengedAction', this.options.challengedAction);
    },

    returnToChallenge() {
        // display success notification
        dialog.close();
    },

    hold() {
        // Do something on hold ?
    },

    closeModal() {
        dialog.close();
    },

    resubmit(challengeHeader) {
        const requestData = this.options.request;
        const requestOptions = util.extend({}, requestData.options, {
            beforeSend(request) {
                // Get common headers
                const headers = httpSetup.getHeaders();
                headers['challenge-state'] = challengeHeader;
                if (util.isFunction(window.add_deviceprint)) {
                    headers.devicePrint = window.add_deviceprint();
                }
                util.each(util.keys(headers), (key) => {
                    request.setRequestHeader(key, headers[key]);
                });
            },
        });

        // resubmit the http request based on the method used
        if (this.options.requestMethod === 'get') {
            http.get(
                requestData.url,
                requestData.success,
                requestData.error,
                requestOptions,
            );
        } else {
            http.post(
                requestData.url,
                requestData.data,
                requestData.success,
                requestData.error,
                requestOptions,
            );
        }

        dialog.close();
    },
});
