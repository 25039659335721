import Grid from '@glu/grid';
import util from '@glu/core/src/util';
import listViewConfig from 'common/util/listViewConfig/listViewConfig';
import lvcConstants from 'common/util/listViewConfig/listViewConfigConstants';

/**
 * stateGrid extends from glu/grid
 * It represents a grid whose state (column order, column size and sort) is
 * maintained
 */
export default Grid.extend({
    /**
     * override initialize to set up listViewConfig for grid state
     * @param {object} optionsParam
     */
    initialize(optionsParam) {
        const options = optionsParam;
        /*
         * configContext must specify productCode, functionCode,
         * typeCode and optional actionMode
         */

        this.lvc = listViewConfig.getInstance(this, options.contextConfig);

        if (this.lvc.get(lvcConstants.COLUMNS)) {
            options.columns = this.lvc.get(lvcConstants.COLUMNS);
        }
        Grid.prototype.initialize.call(this, options);

        this.debouncedHandleChange = util.debounce(util.bind(this.handleChange, this), 5);
        this.debouncedHandleCollectionChange = util.debounce(
            util.bind(this.handleCollectionChange, this),
            5,
        );
    },

    /**
     * override addListeners to add listeners for column state changes
     */
    addListeners() {
        Grid.prototype.addListeners.call(this);

        // add listeners for column resize, column reorder and sort
        this.listenTo(
            this.columns,
            'change:width change:condition add remove reorder',
            this.debouncedHandleChange,
        );
        this.listenTo(this.collection, 'sort', this.debouncedHandleCollectionChange);
    },

    /**
     * handle column reorder, resize, add and remove events
     */
    handleChange() {
        this.lvc.setColumns(this.columns.models);
    },

    /**
     * handle column sort event
     */
    handleCollectionChange() {
        /*
         * store in listViewConfig
         * TODO - update when we support multi-column sort
         */
        const [sortColumn] = this.columns.models.filter(m => m.get('sorted'));
        this.lvc.set(
            lvcConstants.SORTFIELDS,
            [{
                fieldName: sortColumn.get('field'),
                sortOrder: sortColumn.get('sorted'),
            }],
        );
    },
});
