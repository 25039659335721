var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <button type=\"button\" class=\"btn btn-primary approve-payments\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"sbPayments.approve",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":91},"end":{"line":28,"column":122}}}))
    + "</button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <button type=\"button\" class=\"btn btn-primary unapprove-payments\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"sbPayments.unApprove",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":93},"end":{"line":31,"column":126}}}))
    + "</button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <button type=\"button\" class=\"btn btn-primary delete-payments\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"sbPayments.delete",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":34,"column":90},"end":{"line":34,"column":120}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div>\n    <div class=\"paymentsGridWrapper clearfix\">\n        <div class=\"row\">\n            <div class=\"col-md-12\">\n                <div class=\"page-alerts\">\n                    &nbsp;\n                </div>\n            </div><!-- end COL -->\n        </div><!-- end ROW -->\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        <div class=\"btn-group pull-right view-switcher\">\n            &nbsp;\n        </div>\n        <div id=\"container\" class=\"payment-grid-region\" data-region=\"paymentGrid\">\n            <div>\n                <div class=\"row\">\n                    <div class=\"col-md-12\">\n                        <div class=\"page-content\">\n                            <div id=\"paymentsGrid\" class=\"grid\"></div>\n                        </div>\n                    </div><!-- end COL -->\n                </div><!-- end ROW -->\n                <div class=\"row\">\n                    <div class=\"col-md-12 widget-action-btn-group\">\n                        <!-- @TODO: refactor btn-secondary to use a new name, while maintaining this style for overall glu grid update -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasApproveEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":24},"end":{"line":29,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasUnapproveEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":24},"end":{"line":32,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasDeleteEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":24},"end":{"line":35,"column":31}}})) != null ? stack1 : "")
    + "                    </div><!-- end COL -->\n                </div><!-- end ROW -->\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});