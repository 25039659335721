import Collection from '@glu/core/src/collection';
import services from 'services';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import http from '@glu/core/src/http';

export default Collection.extend({
    sync(method, model, options) {
        const userInfo = store.get('uce-load');
        const customFilters = [{
            filterName: 'Depends',
            filterParam: ['UserGroup', userInfo.userGroup],
        }];
        const data = {
            IncludeMapData: 1,
            queryCriteria: {
                action: {
                    typeCode: '_ROLE',
                    entryMethod: 0,
                    productCode: '_ADMIN',
                    actionMode: 'INSERT',
                    functionCode: 'ROLES',
                },
                customFilters,
                inquiryId: 40005,
            },
            requestHeader: {
                queryOffset: 0,
                queryPagesize: 0,
            },
        };

        http.post(services.inquiryQueryResults, data, (result) => {
            options.success(result.queryResponse.QueryData.queryRows);
        }, () => {
            options.error();
        });
    },

    parse(response) {
        const self = this;
        const returnArray = [];
        util.each(response, (rowItem) => {
            const obj = {
                id: rowItem.name,
                title: self.getValue(rowItem.mapDataList, 'Description'),
                roleType: self.getValue(rowItem.mapDataList, 'RoleType'),
                BANKMAINTAINED: self.getValue(rowItem.mapDataList, 'BANKMAINTAINED'),
            };
            returnArray.push(obj);
        });
        return returnArray;
    },

    getValue(mapDataList, fieldName) {
        const field = util.findWhere(mapDataList, {
            toField: fieldName,
        });
        return (field || {}).value;
    },
});
