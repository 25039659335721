import util from '@glu/core/src/util';
import locale from '@glu/locale';
import { appBus } from '@glu/core';
import Model from '@glu/core/src/model';
import services from 'system/servicesList';
import TemplateFromPaymentView from 'app/payments/views/templateFromPayment';
import PaymentFromTemplateView from 'app/payments/views/paymentFromTemplate';
import ContextModel from 'common/dynamicPages/models/context';
import { getErrorView } from 'common/util/deeplinkUtil';
import { processDeeplink } from 'common/dynamicPages/views/mdf/mdfUtil';
import store from 'system/utilities/cache';
import { removeViewIdFromRoute } from 'system/utilities/routeHelper';
import TransfersListView from './views/listTransfers';
import TransferTemplatesListView from './views/listTransferTemplates';
import RecurringTransfersListView from './views/listRecurringTransfers';
import ViewTransferTemplateDetail from './views/viewTemplate';
import ModifyTransferTemplateDetail from './views/modifyTemplate';
import RestoreTransferTemplateDetail from './views/restoreTemplate';
import ScheduleTransfer from './views/schedulePayment';
import TransferLayout from './views/transferLayout';

export default {
    listTransfers(viewId) {
        removeViewIdFromRoute(viewId);
        if (processDeeplink('PAYMENTS/listTransfers', this)) {
            this.showPage('', new TransfersListView({
                viewId,
            }));
        }
    },

    listTransferTemplates(viewId) {
        removeViewIdFromRoute(viewId);
        if (processDeeplink('PAYMENTS/listTransferTemplates', this)) {
            this.showPage('', new TransferTemplatesListView({
                viewId,
            }));
        }
    },

    listRecurringTransfers(viewId) {
        removeViewIdFromRoute(viewId);
        if (processDeeplink('PAYMENTS/listRecurringTransfers', this)) {
            this.showPage('', new RecurringTransfersListView({
                viewId,
            }));
        }
    },

    addTransfer(type) {
        this.showPage('', new TransferLayout({
            type,
            mode: 'add',
        }));
    },

    addTransferByTemplate(templateId) {
        this.showPage('', new TransferLayout({
            templateId,
        }));
    },

    addSingleTransferByTemplate(templateId, inRef) {
        (inRef || this).showPage('', new TransferLayout({
            templateId,
            type: 'SINGLE',
        }));
    },

    addSingleTemplateFromTemplate(templateId) {
        this.showPage('', new TransferLayout({
            templateId,
            type: 'SINGLE',
            templateMode: true,
            copyTemplateFromTemplate: true,
        }));
    },

    addTransferTemplate(type) {
        this.showPage('', new TransferLayout({
            type,
            templateMode: true,
            mode: 'add',
        }));
    },

    viewTransferSet(templateId) {
        const userGroup = store.remove('viewTransferTemplateUserGroup');
        this.showPage('', new TransferLayout({
            type: 'ONE2ONE',
            templateId,
            templateMode: true,
            readOnly: true,
            inquiryId: '19031',
            userGroup,
        }));
    },

    modifyTransferSet(templateId) {
        this.showPage('', new TransferLayout({
            type: 'ONE2ONE',
            templateId,
            templateMode: true,
            inquiryId: '19031',
            mode: 'modify',
        }));
    },

    restoreTransferSet(templateId) {
        this.showPage('', new TransferLayout({
            type: 'ONE2ONE',
            templateId,
            templateMode: true,
            inquiryId: '19031',
            mode: 'modify',
            overloadedMethodName: 'restore',
        }));
    },

    viewRecurring(templateId) {
        this.showPage('', new TransferLayout({
            type: 'RECUR',
            readOnly: true,
            recurId: templateId,
        }));
    },

    modifyRecurring(templateId) {
        this.showPage('', new TransferLayout({
            type: 'RECUR',
            recurId: templateId,
            mode: 'modify',
        }));
    },

    restoreRecurring(templateId) {
        this.showPage('', new TransferLayout({
            type: 'RECUR',
            recurId: templateId,
            mode: 'modify',
            overloadedMethodName: 'restore',
        }));
    },

    viewTransferTemplate() {
        this.showPage('', new ViewTransferTemplateDetail());
    },

    viewTransfer() {
        if (!store.has('viewTransferModel')) {
            appBus.trigger('router:navigate', 'PAYMENTS/manageTransfers', true);
        }

        this.showPage('', new TransferLayout({
            readOnly: true,
            type: 'ONE2ONE',
            transferModel: store.remove('viewTransferModel'),
        }));
    },

    modifyTransfer() {
        const options = {
            mode: 'modify',
        };

        if (!store.has('modifyTransferModel')) {
            appBus.trigger('router:navigate', 'PAYMENTS/manageTransfers', true);
        }

        const contextModel = new ContextModel({
            contextDef: options,
        });
        store.set(`${contextModel.getContextKey()}-listRoute`, 'PAYMENTS/manageTransfers');

        this.showPage('', new TransferLayout(util.extend(
            options,
            {
                transferModel: store.remove('modifyTransferModel'),
                type: 'ONE2ONE',
                mode: 'modify',
            },
        )));
    },

    modifySingleTransferTemplate(idRef) {
        const [recurId, isRecurring] = idRef.split('&');
        this.showPage('', new TransferLayout({
            type: 'SINGLE',
            recurId,
            isRecurring,
            mode: 'modify',
            templateMode: true,
        }));
    },

    restoreSingleTransferTemplate(templateId) {
        this.showPage('', new TransferLayout({
            type: 'SINGLE',
            recurId: templateId,
            mode: 'modify',
            templateMode: true,
            overloadedMethodName: 'restore',
        }));
    },

    viewSingleTransferTemplate(idRef) {
        const [recurId, isRecurring] = idRef.split('&');
        this.showPage('', new TransferLayout({
            type: 'SINGLE',
            readOnly: true,
            recurId,
            isRecurring,
            mode: 'view',
            templateMode: true,
        }));
    },

    modifyTransferTemplate() {
        this.showPage('', new ModifyTransferTemplateDetail());
    },

    restoreTransferTemplate() {
        this.showPage('', new RestoreTransferTemplateDetail());
    },

    repairTransfer() {
        const options = {
            mode: 'repair',
        };

        const contextModel = new ContextModel({
            contextDef: options,
        });

        if (!store.has('repairTransferModel')) {
            appBus.trigger('router:navigate', 'PAYMENTS/manageTransfers', true);
        }

        store.set(`${contextModel.getContextKey()}-listRoute`, 'PAYMENTS/manageTransfers');

        this.showPage('', new TransferLayout(util.extend(
            options,
            {
                transferModel: store.remove('repairTransferModel'),
                type: 'ONE2ONE',
                mode: 'repair',
            },
        )));
    },

    scheduleTransfer() {
        this.showPage('', new ScheduleTransfer());
    },

    addTransferTemplateFromTemplate() {
        const contextTemp = store.get('payment_transfer-contextOverride');
        const options = {
            menuCategory: 'PMTS',
            serviceName: contextTemp.serviceName,
            serviceFunc: null,
            businessType: null,
            context: 'TRANSFERS_LIST',
            templateServiceName: contextTemp.templateServiceName,
            tnum: contextTemp.tnum,
            functionCode: contextTemp.functionCode,
            subType: contextTemp.subType,
            updateCount: contextTemp.updateCount,
        };
        const contextModel = new ContextModel({
            contextDef: options,
        });
        store.set(`${contextModel.getContextKey()}-listRoute`, 'PAYMENTS/manageTransfers');
        this.showPage(locale.get('PAY.createtemplate'), new TemplateFromPaymentView(util.extend(
            options,
            {
                mode: 'insert',
            },
        )));
    },

    addTransferPaymentFromTemplate() {
        const contextTemp = store.get('payment_transfer-contextOverride');
        const options = {
            menuCategory: 'PMTS',
            serviceName: contextTemp.serviceName,
            serviceFunc: null,
            businessType: null,
            context: 'TRANSFERS_LIST',
            templateServiceName: contextTemp.templateServiceName,
            tnum: contextTemp.tnum,
            functionCode: contextTemp.functionCode,
            subType: contextTemp.subType,
            updateCount: contextTemp.updateCount,
        };
        const contextModel = new ContextModel({
            contextDef: options,
        });
        store.set(`${contextModel.getContextKey()}-listRoute`, 'PAYMENTS/manageTransfers');
        this.showPage(locale.get('payment.create'), new PaymentFromTemplateView(util.extend(
            options,
            {
                mode: 'insert',
            },
        )));
    },

    deepLinkViewTransferPayment() {
        if (!processDeeplink('PAYMENTS/deepLinkViewTransferPayment', this)) {
            return;
        }

        this.storedPreObj = store.remove(
            'paymentDetails',
            {
                context: {
                    productCode: 'RTGS',
                    functionCode: 'INST',
                },
            },
        );

        if (this.storedPreObj?.typeCode !== 'TRANSFER') {
            this.showPage('', getErrorView('noDataFoundForOperation'));
            return;
        }

        const model = new Model({
            TNUM: this.storedPreObj.tnum,
            UPDATECOUNT__: this.storedPreObj.updateCount,
            ENTRYMETHOD: this.storedPreObj.entryMethod,
            FUNCTION: this.storedPreObj.functionCode,
            TYPE: this.storedPreObj.typeCode,
        });

        const readServiceNameKey = `${this.storedPreObj.productCode}_${
            this.storedPreObj.functionCode}_${
            this.storedPreObj.typeCode}`;
        model.context = {
            serviceName: services[readServiceNameKey],
            actionData: {
                productCode: this.storedPreObj.productCode,
                functionCode: this.storedPreObj.functionCode,
                typeCode: this.storedPreObj.typeCode,
                subType: this.storedPreObj.subType ? this.storedPreObj.subType : '*',
                entryMethod: this.storedPreObj.entryMethod ? this.storedPreObj.entryMethod : '0',
            },
        };
        model.buttonsToHide = ['CANCEL'];
        store.set('viewTransferModel', model);
        this.viewTransfer();
    },

};
