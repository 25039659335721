import Model from '@glu/core/src/model';
import locale from '@glu/locale';
import userInfo from 'etc/userInfo';

export default Model.extend({
    defaults: {
        BENEBANKIDENTRYMETHOD: 'LOOKUP',
        BENE_ACCOUNT_TYPE: 'Other',
        PRODUCT: 'RTGS',
        TYPE: 'FEDWIRE',
        FUNCTION: 'INST',
        USERGROUP: userInfo.get('group'),
    },

    initialize() {
        this.setValidators();
        this.setSingleLookupParameters();
        this.setModelEventListners();
        this.setTypeAheadFieldData();
    },

    /**
     * @method setTypeAheadFieldData
     * The typeAhead widget, designed for the metadata system, expects certain
     * parts of data to be set on the model
     */
    setTypeAheadFieldData() {
        const arrayOfFields = [
            'BANKSORTCODE',
            'BANKNAME',
            'BANKADDRESS1',
            'BANKADDRESS2',
            'BANKCITY',
            'BANKSTATE',
        ];

        this.fieldData = {
            BENE_BANK_ID: {
                dependsOn: ['BENE_ACCOUNT_TYPE'],
                relatedProperty: '',

                typeAhead: arrayOfFields.map(field => ({
                    field,
                    type: 'STRING',
                })),
            },
        };
    },

    /**
     * @method setValidators
     * set default model validators
     */
    setValidators() {
        this.validators = {
            DEBIT_ACCOUNT_NUMBER: {
                description: locale.get('RTGS.originatorAccount'),
                exists: true,
            },

            BENE_BANK_ID: {
                description: locale.get('RTGS.INST.MULTIBK.*.BENE_BANK_CODE'),
                exists: true,
            },

            VALUE_DATE: {
                description: locale.get('RTGS.*.value_date'),
                exists: true,
            },

            BENE_ACCOUNT: {
                description: locale.get('RTGS.Account_Number'),
                exists: true,
            },

            CREDIT_AMOUNT: {
                description: locale.get('RTGS.screentext.Amount'),
                exists: true,
            },

            BENE_NAME: {
                description: locale.get('RTGS.*.Bene_Name'),
                exists: true,
            },
        };
    },

    /**
     * @method setSingleLookupParameters
     * set default keys for single lookup widget.
     */
    setSingleLookupParameters() {
        this.jsonData = {
            typeInfo: {
                typeCode: 'FEDWIRE',
                functionCode: 'INST',
                productCode: 'RTGS',
            },
        };
        this.context = '/payment/fedwire';
        this.fieldData = {
            BENE_BANK_ID: {
                dependsOn: 'BENE_ACCOUNT_TYPE',
            },
        };
    },

    /**
     * @method setModelEventListners
     * set model event listener(s)
     */
    setModelEventListners() {
        this.on('change:VALUE_DATE', () => {
            this.set('TRAN_DATE', this.get('VALUE_DATE'));
        });
    },
});
