var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "error";
},"3":function(container,depth0,helpers,partials,data) {
    return "<span class=\"error icon-exclamation-circle\"></span>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button class=\"image-indicator image-message "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showError") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":45},"end":{"line":1,"column":74}}})) != null ? stack1 : "")
    + "\" data-hook=\"image-button\" title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"iconDescription") || (depth0 != null ? lookupProperty(depth0,"iconDescription") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconDescription","hash":{},"data":data,"loc":{"start":{"line":1,"column":108},"end":{"line":1,"column":127}}}) : helper)))
    + "\">\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showError") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":79}}})) != null ? stack1 : "")
    + "\n    <span class=\"icon-"
    + alias4(((helper = (helper = lookupProperty(helpers,"iconName") || (depth0 != null ? lookupProperty(depth0,"iconName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconName","hash":{},"data":data,"loc":{"start":{"line":3,"column":22},"end":{"line":3,"column":34}}}) : helper)))
    + "\"></span>\n    <span class=\"sr-only\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"iconDescription") || (depth0 != null ? lookupProperty(depth0,"iconDescription") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconDescription","hash":{},"data":data,"loc":{"start":{"line":4,"column":26},"end":{"line":4,"column":45}}}) : helper)))
    + "</span>\n</button>\n\n";
},"useData":true});