import alert from '@glu/alerts';
import Dialog from '@glu/dialog';
import http from '@glu/core/src/http';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import $ from 'jquery';
import locale from '@glu/locale';
import services from 'services';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import gridApi from 'common/dynamicPages/api/grid';
import Loan from 'app/loans/views/payment/viewPayment/view';
import loans from 'app/loans/api/common';
import PaymentsTransitionsView from 'app/administration/views/paymentManagement/paymentsTransitionsView';
import store from 'system/utilities/cache';
import transform from 'common/util/transform';
import userInfo from 'etc/userInfo';
import moment from 'moment';

export default Loan.extend({
    initialize(options) {
        Loan.prototype.initialize.call(
            this,
            { ...options, model: loans.convertServerJSONToModelAttributes(options.model) },
        );

        // set attributes on the model for the handlebars template
        const dt = this.model.get('CMB_VALUE_DATE');
        if (dt) {
            this.model.set('VALUE_DATE', moment(new Date(dt)).format(userInfo.getDateFormat()));
        }

        this.model.set('DEBIT_ACCOUNT_TITLE', this.model.get('CMB_DEBIT_ACCOUNT_NAME'));
        this.model.set('BENE_ACCOUNT', this.model.get('CMB_BENE_ACCOUNT'));
        this.model.set('BENE_NAME', this.model.get('CMB_BENE_NAME'));
    },

    events: {
        'click [data-action="transition"]': 'initiateTransition',
    },

    cancel() {
        window.history.back();
    },

    initiateTransition() {
        const self = this;
        const paymentsTransitionsView = new PaymentsTransitionsView({
            transitions: this.transitions,
            status: this.model.get('STATUS_DESCRIPTION'),
            model: new Model(util.pick(this.model.attributes, 'TNUM', 'PRODUCT', 'FUNCTION', 'TYPE')),
        });

        this.listenTo(paymentsTransitionsView, 'resolve', (data) => {
            self.actionTransitioned(data);
        });

        Dialog.open(paymentsTransitionsView);
    },

    submitTransition(action) {
        const self = this;
        const jsonData = this.getJSONData();

        http.post(services.generateUrl(`transitionsMaint/${action}`), jsonData, (data) => {
            self.actionTransitioned(data);
        }, (data) => {
            self.actionTransitioned(data);
        });
    },

    getListUrl() {
        const r = store.get(`${this.contextKey}-listRoute`);
        return r || 'REPORTING/viewPayments';
    },

    actionTransitioned(confirmResponse) {
        const action = this.model.get('_MODE');

        /*
         * create the confirmation view for the alert
         * skip if action is null.  This occurs when first entering the payment workspace
         * because the onRender is called.
         */
        if ((action === null || action === undefined)
            && (confirmResponse === null || confirmResponse === undefined)) {
            return;
        }
        if (confirmResponse === null || confirmResponse === undefined) {
            if ((action.type === 'WARNING' || action.type === 'DELEGATERESULT') && action.message) {
                this.alertView = alert.warning(action.message);
            }
        } else {
            const confirm = typeof confirmResponse === 'object' ? confirmResponse.confirms : null;
            const successCount = confirm ? confirm.totalSuccess : 0;
            const failCount = confirm ? confirm.totalFail : 0;
            const totalCount = failCount + successCount;
            const success = successCount === totalCount;
            const alertFunc = success ? alert.success : alert.danger;
            const chainedMessages = typeof confirmResponse === 'object' ? confirmResponse.message : null;
            const message = typeof confirmResponse === 'object' && confirmResponse.message.length > 0 ? confirmResponse.message[chainedMessages.length - 1] : action;

            const confirms = new Confirms({
                confirms: confirmResponse ? confirmResponse.confirms : null,
            });

            // display notification message
            this.alertView = alertFunc(
                message,
                {
                    details: confirmResponse
                        && confirmResponse.confirms.confirmResults
                        && confirmResponse.confirms.confirmResults[0].confirmData[0].item
                        ? confirms : null,
                    canDismiss: !!confirmResponse,
                    animate: true,
                },
            );
        }
        /*
         * this will set the alertView so that it can be picked up in the listview
         * and displayed.
         */
        store.set('loanTransition:alertMessage', this.alertView);

        // Return to our first stackview
        this.navigateTo(this.getListUrl());
    },

    reject() {
        this.submitTransition('reject');
    },

    approve() {
        this.submitTransition('approve');
    },

    getJSONData() {
        return {
            item: {
                item: transform.hashToPairs({
                    TNUM: this.model.get('TNUM'),
                    PAYMENT_PRODUCT: this.model.get('PRODUCT'),
                    PAYMENT_FUNCTION: this.model.get('FUNCTION'),
                    PAYMENT_TYPE: this.model.get('TYPE'),
                }),
            },
        };
    },

    loadRequiredData() {
        this.setHasLoadedRequiredData(true);
        this.setupTransitionTrackingGrid();
        // Cancel button is added by default. Removing it since it is not needed
        this.buttonList = [];
        const actions = this.model.get('ACTIONLIST');
        if (actions.indexOf('INSERT') >= 0 && this.model.get('STATUS') !== 'TP' && this.model.get('TRANSITIONS') && this.model.get('TRANSITIONS').length > 0) {
            this.transitions = JSON.parse(this.model.get('TRANSITIONS').replace(/&quot;/g, '"'));
            this.buttonList.push({
                action: 'TRANSITION',
            });
        }
        if (this.model.get('STATUS') === 'TP') {
            if (actions.indexOf('APPROVE') >= 0) {
                this.buttonList.push({
                    action: 'APPROVE',
                });
            }
            if (actions.indexOf('REJECT') >= 0) {
                this.buttonList.push({
                    action: 'REJECT',
                });
            }
        }
        if (this.mode === 'view' && this.options.context === 'PAY_LIST_VIEW') {
            this.model.set('INDICATIVE_RATE', this.indicativeRate());
        }
        this.render();
    },

    // Indicative rate value for international wire transfer
    indicativeRate() {
        let ret = ' ';
        const creditCurr = this.model.get('CREDIT_CURRENCY');
        const debitCurr = this.model.get('DEBIT_CURRENCY');
        const exchangeRate = this.model.get('EXCHANGE_RATE');
        let indicativeAmount = this.model.get('INDICATIVEAMOUNT');
        let transAmount = this.model.get('TRANSACTION_AMOUNT');

        if (indicativeAmount !== null) {
            indicativeAmount = indicativeAmount.trim().replace(/,/g, '');
        }
        if (transAmount !== null) {
            transAmount = transAmount.trim().replace(/,/g, '');
        }
        /*
         * getting the rate if credit and debit currencies are different
         * AND indicative and transaction amounts are set
         * AND exchange rate is not used
         */
        if (creditCurr !== debitCurr
            && !util.isEmpty(indicativeAmount)
            && !util.isEmpty(transAmount)
            && util.isEmpty(exchangeRate)
            && (indicativeAmount * 1 > 0)) {
            ret = `${((transAmount * 1) / (indicativeAmount * 1)).toFixed(6)}`;
        }
        return ret;
    },

    /*
     * We have to override this method to prevent the read request from having a nested
     * item array
     */
    convertModelAttributesToKeyJSON(model) {
        return this.convertModelAttributesToKeyWithOptContextJSON(model);
    },

    onRender() {
        Loan.prototype.onRender.call(this);
        if (this.hasLoadedRequiredData() && this.transitionTrackingAuditGrid) {
            this.customRegion.show(this.transitionTrackingAuditGrid);
        } else if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
        }
    },

    gridLoadComplete() {
        const customRegionDiv = $('#customRegionDiv');
        if (this.transitionTrackingAuditGrid
            && this.transitionTrackingAuditGrid.wrapper.rows.totalCount === 0) {
            customRegionDiv.empty();
        } else {
            const id = util.uniqueId();
            customRegionDiv.addClass('section section-container');
            customRegionDiv.prepend(`<div class="panel panel-tertiary"><div class="panel-heading" role="tab" id="collapseHeading${id}"><h4 class="panel-title"><a role="button" data-toggle="collapse" href="#collapseArea${id}" aria-expanded="true" aria-controls="collapseArea${id}"><span class="indicator-icon"></span> ${locale.get('transition.auditgrid.title')} </a></h4></div><div id="collapseArea${id}" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="collapseHeading${id}"><div class="panel-body"></div></div></div>`);
            customRegionDiv.find('> div:last-child').appendTo(customRegionDiv.find('> .panel .panel-body'));
        }
    },

    setupTransitionTrackingGrid() {
        const data = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    action: {
                        typeCode: 'PAYMENTS',
                        productCode: 'ADMPAY',
                        functionCode: 'INST',
                        actionMode: 'SELECT',
                    },

                    searchType: '5',
                    inquiryId: 31000,
                },
            },
        };

        this.customFilters = [{
            filterName: 'Depends',
            filterParam: ['TNUM', this.model.get('TNUM')],
        }];

        data.inquiryRequest.searchCriteria.customFilters = this.customFilters;

        const contextLocal = {
            actionMode: 'SELECT',
            displayOrder: 1,
            functionCode: 'INST',
            inquiryId: 31000,
            gridId: 0,
            nonDashboardDisplay: 0,
            productCode: 'ADMPAY',
            typeCode: 'PAYMENTS',
        };

        const options = {
            context: contextLocal,
            filter: false,
            selector: 'none',
            hideButtons: true,

            // default grid action buttons are hidden
            hideGridActionButtons: true,

            enableRowContextButtonCallbacks: true,
            inquirySearchCriteria: data.inquiryRequest.searchCriteria,
        };

        this.transitionTrackingAuditGrid = gridApi.createInquiryGridView(options);
        this.listenTo(this.transitionTrackingAuditGrid, 'gridapi:loaded', this.gridLoadComplete);
    },
});
