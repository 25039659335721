import ItemView from '@glu/core/src/itemView';
import stepTmpl from './step.hbs';

export default ItemView.extend({
    template: stepTmpl,
    tagName: 'li',

    events: {
        click: 'handleStepClick',
    },

    /**
     * Dynamically set the classname based on the selected attribute of the model
     * @returns {string}
     */
    className() {
        const isActive = this.model.get('selected') ? 'is-active' : '';
        return `StepsNamed-item ${isActive}`.trim();
    },

    templateHelpers() {
        return {
            stepNum: this.options.index + 1,
        };
    },

    /**
     * Trigger the changeStep event
     */
    handleStepClick() {
        this.trigger('changeStep');
    },

});
