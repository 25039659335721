import $ from 'jquery';
import alert from '@glu/alerts';
import { appBus } from '@glu/core';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import util from '@glu/core/src/util';

import ActivateTokenModel from 'system/mfa/models/activateToken';
import BaseMFAView from 'system/mfa/views/baseMFA';
import Constants from 'system/mfa/constants';
import serverConfigParams from 'system/webseries/models/configurationParameters';

import hardTokenRegisterTemplate from './hardTokenRegister.hbs';
import hardTokenSelfRegister from './hardTokenSelfRegister.hbs';

export default BaseMFAView.extend({
    template: hardTokenRegisterTemplate,
    modalClass: 'modal-xlg dialog-warning mfa-challenge-modal',
    dialogTitle: ' ',

    ui: {
        $submit: '[data-hook="submit"]',
    },

    initialize() {
        this.model = new ActivateTokenModel();
        this.template = this.options.challengeType === Constants.ONESPAN_CHALLENGE_TYPE
            ? hardTokenSelfRegister : hardTokenRegisterTemplate;
        this.challengeType = this.options.challengeType;
        this.challengeAction = this.options.challengeAction;
        this.allowRegistrationAttempt = true;
        this.attempts = 0;
        this.listenTo(this.model, 'error', this.incrementRegistrationAttempts);
        this.createValidators();
    },

    createValidators() {
        if (this.isSelfRegistration(this.challengeType)) {
            this.model.addSelfRegistrationValidator();
        }
    },

    getModalPromises() {
        return Promise.all([
            !this.isSelfRegistration(this.challengeType)
                ? new Promise((resolve, reject) => {
                    this.model.fetchRegistrationCode({
                        success: resolve,
                        error: reject,
                    });
                }) : '',
            new Promise((resolve, reject) => {
                serverConfigParams.fetch({
                    success: resolve,
                    error: reject,
                    isPreLoginMfaFetch: true,
                });
            }),
        ]);
    },

    onRender() {
        this.trigger('dialog:title:change', locale.get('mfa.rsa.authorization.required'));
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
        }
    },

    /* display the warning icon in the header of the modal */
    getHeaderIcon() {
        return 'warning';
    },

    loadRequiredData() {
        this.getModalPromises().then(([modelDataResp, configParamResp]) => {
            if (!this.isSelfRegistration(this.challengeType)) {
                this.model.set(modelDataResp);
            }
            if (configParamResp) {
                const configParameters = configParamResp.toJSON();
                const showHelpLink = configParameters.mfaShowActivationHelpLink;
                const helpLink = configParameters.mfaActivationHelpLinkTarget;

                this.model.set(configParameters);
                // convert to boolean
                this.model.set('mfaShowActivationHelpLink', showHelpLink === 'true' && helpLink !== null);
                this.model.set('challengeType', this.challengeType);
                this.model.set('challengeAction', this.challengeAction);
            }
            this.setHasLoadedRequiredData(true);
            this.render();
        });
    },

    /**
     * @param {Number} challengeType - The specific type of MFA challenge being completed
     * @return {Boolean} - whether or not the current MFA challenge is a OneSpan
     * self-registration
     */
    isSelfRegistration(challengeType) {
        return challengeType === Constants.ONESPAN_CHALLENGE_TYPE;
    },

    incrementRegistrationAttempts() {
        if (this.errorCode === Constants.ERROR_MAX_RETRIES) {
            if (this.challengeAction.actionMode === 'LOGIN') {
                this.logout();
            } else {
                $('.btn-primary').prop('disabled', true);
                $('.btn-secondary').prop('disabled', true);
                setTimeout(() => this.logout(), 3000);
            }
            return;
        }
        // only count failures after code has been successfully retrieved.
        if (this.hasLoadedRequiredData()) {
            this.attempts += 1;
            if (this.model.get('MaxTokenRegistrationAttempts') && this.attempts >= +this.model.get('MaxTokenRegistrationAttempts')) {
                this.allowRegistrationAttempt = false;
            }
        }
    },

    buttons() {
        return [{
            text: locale.get('button.cancel'),
            className: 'btn btn-secondary',
            callback: util.bind(this.cancel, this),
        }];
    },

    handleMFAError(appResCode, response) {
        let errorString;
        if (response) {
            errorString = (response.responseJSON.respHeader.message[0])
                ? response.responseJSON.respHeader.message.join(' - ') : locale.get((appResCode) || 'mfa.error.message');
        } else {
            errorString = locale.get((appResCode) || 'mfa.error.message');
        }
        this.errorCode = response.responseJSON.respHeader.errorCode;
        if (this.challengeAction.actionMode !== 'LOGIN' && this.errorCode === Constants.ERROR_MAX_RETRIES) {
            errorString = locale.get('mfa.attempts.exceeded');
        }
        this.errorString = errorString;
        this.alertView = alert.danger(
            errorString,
            {
                canDismiss: true,
            },
        );
        this.alertRegion.show(this.alertView);
    },

    submitCode() {
        this.alertRegion.close();
        this.ui.$submit.prop('disabled', true);
        if (!this.isSelfRegistration(this.challengeType) && !this.allowRegistrationAttempt) {
            this.alertView = alert.danger(
                locale.get('mfa.token.attempts.exceeded'),
                {
                    canDismiss: true,
                },
            );
            this.alertRegion.show(this.alertView);
            return;
        }
        this.model.save(
            {},
            {
                success: (result) => {
                    if (result.get('respHeader').result === false) {
                        const resp = result.get('respHeader');
                        // handle error types
                        const message = resp.message.join(' ');

                        this.alertView = alert.danger(
                            message,
                            {
                                canDismiss: true,
                            },
                        );
                        this.alertRegion.show(this.alertView);
                        this.ui.$submit.prop('disabled', false);
                    } else {
                        this.trigger('activationSuccess');
                        dialog.close();
                    }
                },

                error: util.bind(this.handleMFAError, this),
            },
        );
    },

    logout() {
        this.alertView = alert.danger(
            this.errorString,
            {
                canDismiss: true,
            },
        );

        if (this.challengeAction.actionMode === 'LOGIN') {
            this.returnToChallenge();
            this.closeModal();
            appBus.trigger('mfa:challenge:showError', this.alertView);
        } else {
            this.trigger('activationFailure');
        }
    },
});
