import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import Collection from '@glu/core/src/collection';
import { createTabsToggleButton, toggleTabs, setTabButtonText } from 'common/util/a11y/tabs';
import GroupModel from 'app/administration/models/user2/group';
import appConfigParams from 'system/webseries/models/applicationConfiguration';

import OverallLimitsLayout from './overallLimits/overallLimitsLayout';
import AccountLimitsLayout from './accountLimits/accountLimitsLayout';
import limitTabsTmpl from './limitTabs.hbs';

export default Layout.extend({
    template: limitTabsTmpl,

    ui: {
        $navTabs: '[data-hook="getNavTabs"]',
        $navItems: '[data-hook="getNavTabs"] .NavTabs-item',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
    },

    events: {
        'click @ui.$navLinks': 'handleToggleButtonClick',
        'keypress @ui.$navLinks': 'handleKeyPress',
    },

    regions: {
        limitRegion: '[data-hook="limitRegion"]',
    },

    initialize(options) {
        this.mode = options.mode;
        this.sections = {};
        this.permissionsGrouping = null;
        this.isCannotGrantBeyondOwnUser = this.options.isCannotGrantBeyondOwnUser;
    },

    onRender() {
        createTabsToggleButton(this);

        this.currentTabText = locale.get('uce.overall.approval.limits');
        setTabButtonText(this, this.currentTabText);

        // Initialize the default current step everytime this Tab loads
        this.currentStep = 'OVERALL';

        this.userLimits = this.model.userModel;
        const infoModel = this.model.roleModel || this.model.userModel;
        const newPermissionsGrouping = infoModel.get('GROUPED_PERMISSIONS_BY');

        this.paymentsSection = this.getSection('PAYMENTS');

        // Don't render if no payments or templates are entitled at all to the company
        if (util.isNullOrUndefined(this.paymentsSection)) {
            return;
        }

        // Load Accounts only gets Payment Groups (for now!!!)
        const paymentGroups = this.collectGroups('PAYMENTS');

        // Check if approval Limits are enabled for Risk Management
        const riskGroupConfig = appConfigParams.getValue('CMCIM', 'UALPPD');

        // Load Groups (Overall) gets all Payments and Risk
        const allGroups = new Collection([
            ...paymentGroups,
            ...(riskGroupConfig === '1' ? this.collectGroups('RISK') : []),
        ]);


        /*
         * Don't bother to reload the overall and account limits views
         * if the permission groupings has not changed
         */
        if (newPermissionsGrouping !== this.permissionsGrouping) {
            this.permissionsGrouping = !util.isEmpty(newPermissionsGrouping) ? newPermissionsGrouping : 'PAYMENT_GROUP';
            this.loadGroups(allGroups);
            this.loadAccounts(paymentGroups, this.permissionsGrouping);
        }

        /*
         * Set current step to active.  This is where we left off or
         * first time here, so no need to reset any other buttons.
         */
        this.$(`a[data-step="${this.currentStep}"]`).addClass('is-active');
        this.toStep(this.currentStep);
    },

    loadGroups(groups) {
        this.sections.OVERALL = new OverallLimitsLayout({
            mode: this.mode,
            collection: groups,
            isCannotGrantBeyondOwnUser: this.isCannotGrantBeyondOwnUser,
        });
    },

    loadAccounts(groups, permissionsGrouping) {
        this.sections.ACCOUNTS = new AccountLimitsLayout({
            mode: this.mode,
            model: this.paymentsSection,
            mainModel: this.model,
            collection: new Collection(groups),
            permissionsGrouping,
            isCannotGrantBeyondOwnUser: this.isCannotGrantBeyondOwnUser,
        });
    },

    toStep(step) {
        this.currentStep = step;
        this.limitRegion.show(this.sections[this.currentStep]);
    },

    changeTab(step) {
        /*
         * Reset active by removing class from all buttons and resetting
         * to current step.
         */
        if (this.userLimits) {
            this.ui.$navItems.removeClass('is-active');
            this.ui.$navItems.find(`[data-step="${step}"]`).parent().addClass('is-active');

            this.currentTabText = this.ui.$navItems.find(`[data-step="${step}"]`).text();
        }
        toggleTabs(this);
    },

    beforeSave() {
        return !util.isNullOrUndefined(this.sections.ACCOUNTS)
            ? this.sections.ACCOUNTS.beforeSave() : Promise.resolve();
    },

    /**
     * Indicates if step 4 has been visited
     * @return {boolean}
     * Returns true if tab has been visited
     */
    isLimitTabNotRendered() {
        return util.isNullOrUndefined(this.sections) || util.isEmpty(this.sections);
    },

    /**
     * When save is pressed, validates the limits of
     * a CGBO user against the logged in user.
     * @return {boolean}
     * Returns true if limits are valid
     */
    validateLimitsOnSave() {
        if (this.isLimitTabNotRendered()) {
            return this.validateLimitsStep4NotRendered();
        }
        return this.validateAllVisbileLimits();
    },

    /**
     * Validate limits that step 4 not rendered
     * @return {boolean}
     * Returns true if limits are valid
     */
    validateLimitsStep4NotRendered() {
        /*
         * Iterate through groups if the tab was never rendered.
         * TODO need to also check account limits?
         */
        if (util.isNullOrUndefined(this.getSection('PAYMENTS'))) {
            return true;
        }
        return this.getSection('PAYMENTS').getSelectedGroups()
            .every(group => group.getEntitledTypes(group.id)
                .every(type => util.every(type.get('limits'), limit => limit.maxValue === null)));
    },

    /**
     * Validate limits that are currently displayed in step 4
     * @return {boolean}
     * Returns true if limits are valid
     */
    validateAllVisbileLimits() {
        return util.every(this.sections, section => section.validateLimits(true));
    },

    /**
     * If the visible overall limit tab is valid
     * need to check overall limits from model for the tabs that are not visible
     * @return {boolean}
     * Returns true if limits are valid
     */
    validateAllOverallLimits() {
        // Don't bother.  This was already done if empty.  Hard to choreograph.
        if (util.isNullOrUndefined(this.sections) || util.isEmpty(this.sections)) {
            return true;
        }

        return this.getSection('PAYMENTS').getSelectedGroups()
            .chain()
        // Don't check the visible tab since that was already done.
            .reject(group => group.id === this.sections.OVERALL.selectedGroupId)
            .every(group => group.getEntitledTypes(group.id)
                // If there is a validator we have already visited the tab and can ignore.
                .every(type => !util.isNullOrUndefined(type.validators)
                    || util.every(
                        type.get('limits'),
                        limit => util.isNullOrUndefined(limit.maxValue)
                            || limit.value <= limit.maxValue,
                    )))
            .value();
    },

    /**
     * Retrieves the section from model
     * returned from the server on read.
     * @param {string} id - Filers out the section based on ID
     * @return {Model}
     * Returns the model of the id section
     */
    getSection(id) {
        return this.model.get('sections').findWhere({
            id,
        });
    },

    getAllRiskGroups(riskSection) {
        const groups = riskSection.get('groups');
        /**
         * Combine all the types and aggregateModels to make it visble
         * under one Tab in 'Risk Management' of Approval Limits
         */
        const typeGroup = groups.map(group => group.get('types')?.models)
            .flat().filter(type => !util.isEmpty(type.get('limits')));

        const aggregateGrp = groups.map(group => group.get('aggregateModels')?.models).flat();

        /**
         * Groups contain nested objects.
         * To prevent modifying the originals, we parse out a new object
         */
        const newGroupData = JSON.parse(JSON.stringify(groups.at(0).toJSON()));
        const riskGroup = new GroupModel(newGroupData);

        riskGroup.get('aggregateModels').models = aggregateGrp;
        riskGroup.get('types').models = typeGroup;
        riskGroup.set({ id: riskSection.get('id'), label: riskSection.get('label') });
        return riskGroup;
    },
    /**
     * Collect all the groups from the section, (Risk and Payments) for approval Limit View
     * @param {string} sectionName - name of the section
     */
    collectGroups(sectionName) {
        const section = this.getSection(sectionName);
        if (section) {
            const match = {
                DEFAULT: sectionData => sectionData.get('groups')?.models ?? [],
                RISK: (sectionData) => {
                    const riskGroup = this.getAllRiskGroups(sectionData);
                    return [riskGroup];
                },
            };
            const matcher = match[sectionName] ?? match.DEFAULT;
            return matcher(section);
        }

        return [];
    },

    handleToggleButtonClick(e) {
        // Validate the page before leaving only for CGBO.
        if (this.isCannotGrantBeyondOwnUser
                && !this.sections[this.currentStep].validateLimits()) {
            e.preventDefault();
            return;
        }

        const elem = e.currentTarget;
        const step = elem.getAttribute('data-step');

        this.toStep(step);
        this.changeTab(step);
    },

    handleKeyPress(e) {
        // enter key
        if (e.which === 13) {
            this.handleToggleButtonClick(e);
        }
    },

    templateHelpers() {
        const self = this;

        return {
            paymentsEntitled() {
                return self.model.get('sections').some(section => section.id === 'PAYMENTS');
            },
            users: this.model.userModel,
        };
    },
});

