var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"help-block\" data-bind=\"model\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"errorString") || (depth0 != null ? lookupProperty(depth0,"errorString") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"errorString","hash":{},"data":data,"loc":{"start":{"line":11,"column":59},"end":{"line":11,"column":74}}}) : helper)))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog glu-dialog\">\n    <form class=\"modal-content\" data-submit=\"save\">\n        <div class=\"modal-header\">\n            "
    + alias2(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"close","action":"close","text":"{{locale localeKeys.close}}","name":"close"},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":102}}}))
    + "\n            <h2 class=\"modal-title\" id=\"modal-title\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"localeKeys") : depth0)) != null ? lookupProperty(stack1,"rejectRequest") : stack1),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":53},"end":{"line":5,"column":88}}}))
    + "</h2>\n        </div>\n        <div class=\"modal-body command-and-control-modal "
    + alias2(((helper = (helper = lookupProperty(helpers,"errorClass") || (depth0 != null ? lookupProperty(depth0,"errorClass") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"errorClass","hash":{},"data":data,"loc":{"start":{"line":7,"column":57},"end":{"line":7,"column":71}}}) : helper)))
    + "\">\n            <label for=\"REJECTION_REASON\">* "
    + alias2(lookupProperty(helpers,"locale").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"localeKeys") : depth0)) != null ? lookupProperty(stack1,"rejectionReason") : stack1),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":44},"end":{"line":8,"column":81}}}))
    + "</label>\n            <input type=\"text\" name=\"REJECTION_REASON\" maxlength=\"75\" />\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"errorString") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":12,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"modal-footer\">\n            <button type=\"submit\" class=\"btn btn-primary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"localeKeys") : depth0)) != null ? lookupProperty(stack1,"reject") : stack1),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":58},"end":{"line":15,"column":86}}}))
    + "</button>\n            <button type=\"button\" class=\"btn btn-default\" data-hook=\"cancel-button\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"localeKeys") : depth0)) != null ? lookupProperty(stack1,"cancel") : stack1),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":84},"end":{"line":16,"column":112}}}))
    + "</button>\n        </div>\n    </form>\n</div>\n";
},"useData":true});