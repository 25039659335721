import Collection from '@glu/core/src/collection';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';

export default Collection.extend({
    initialize(options) {
        this.inquiryId = (options && options.isAdmin) ? '10527' : '10530';
        this.typeCode = options.isAdmin ? 'CLUSR_TM' : '_USER';
        this.functionCode = options.isAdmin ? 'MAINT' : 'USERS';
        this.productCode = options.isAdmin ? 'CMAINT' : '_ADMIN';
    },
    sync(method, model, options) {
        const data = {
            IncludeMapData: 1,
            queryCriteria: {

                inquiryId: this.inquiryId,
                action: {
                    typeCode: this.typeCode,
                    entryMethod: 0,
                    actionMode: 'SELECT',
                    functionCode: this.functionCode,
                    productCode: this.productCode,
                },
                queryType: 'SearchQuery',
            },

            requestHeader: {
                queryPagesize: 0,
                queryOffset: 1,
                requestUser: {
                    data: {
                        item: [{
                            name: '',
                            value: '',
                        }],
                    },
                    userId: '',
                },
                requestId: '',
            },
        };

        http.post(services.inquiryQueryResults, data, (result) => {
            options.success(result.queryResponse.QueryData.queryRows);
        }, () => {
            options.error();
        });
    },

    parse(response) {
        const returnArray = [];
        util.each(response, (rowItem) => {
            const obj = {
                id: rowItem.name,
                label: rowItem.label,
                mapDataList: rowItem.mapDataList,
            };
            returnArray.push(obj);
        });
        return returnArray;
    },
});
