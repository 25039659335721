import ItemView from '@glu/core/src/itemView';
import paymentDecisionTopTmpl from './paymentDecisionTop.hbs';

export default ItemView.extend({
    template: paymentDecisionTopTmpl,
    binding: true,

    ui: {
        $buttons: '.pd-btn-wrap button',
        $newPaymentBtn: '#btnMakeNewPayment',
    },

    events: {
        'click @ui.$newPaymentBtn': 'handleMakeNewPaymentButtonClick',
        'click #btn-smb-payments': 'navigateToSmbPayments',
    },

    handleMakeNewPaymentButtonClick() {
        this.ui.$buttons.not(this.ui.$newPaymentBtn).addClass('inactive');
        this.ui.$newPaymentBtn.addClass('active');
    },

    navigateToSmbPayments() {
        this.navigateTo('SmbPayments/PaymentGrid');
    },
});
