import Model from '@glu/core/src/model';
import { postData } from 'common/util/services';
import services from 'services';
import locale from '@glu/locale';
import userInfo from 'etc/userInfo';
import transform from 'common/util/transform';

const FreeFormModel = Model.extend({

    defaults: {
        requestType: 'freeForm',
        subject: '',
        detail: null,
    },

    initialize() {
        this.validators = {
            requestType: {
                description: locale.get('serviceRequest.Request.Type'),
                exists: true,
            },
            subject: {
                description: locale.get('serviceRequest.Subject'),
                exists: true,
            },
            detail: {
                description: locale.get('serviceRequest.Detail'),
                exists: true,
            },
        };
    },

    sync(method, model, options) {
        if (method === 'create') {
            const endpoint = options.modify ? `${services.serviceRequest}/freeform/update`
                : `${services.serviceRequest}/freeform/add`;
            return postData(endpoint, this.getSaveData(model));
        }
        if (method === 'read') {
            return postData(
                `${services.serviceRequest}/freeform/read`,
                this.getFetchData(model, options),
            ).then(response => this.parse(response, options))
                .then(data => this.set(data));
        }

        return Promise.reject(new Error('method event not supported'));
    },

    parse(response, options = {}) {
        const data = transform.pairsToHash(response.item);
        if (options.modify) {
            return {
                subject: data.SUMMARY,
                detail: data.DESCRIPTION,
            };
        }
        return data;
    },

    getFetchData(model, options) {
        return {
            item: [
                {
                    name: 'TNUM',
                    value: options.tnum,
                },
            ],
        };
    },

    /**
     * Convert the model into server ready JSON
     * @param {Object} model
     * @returns {Object} serialized version of the object
     */
    getSaveData(model) {
        return {
            item: [
                {
                    name: 'TNUM',
                    value: model.get('tnum'),
                },
                {
                    name: 'SUMMARY',
                    value: model.get('subject'),
                },
                {
                    name: 'DESCRIPTION',
                    value: model.get('detail'),
                },
                {
                    name: 'USERGROUP',
                    value: userInfo.get('group'),
                },
            ],
        };
    },
});

export default FreeFormModel;
