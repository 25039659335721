import util from '@glu/core/src/util';
import RecipientsListView from 'app/setup/views/listAlertRecipients';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import mobileGridDefaultBulkActions from 'common/dynamicPages/api/mobileGridDefaultBulkActions';

const CustomRecipientsTabView = RecipientsListView.extend({

    initialize(options) {
        RecipientsListView.prototype.initialize.call(this, util.extend({}, options));
    },
    id: 'recipients',

    attributes: {
        role: 'tabpanel',
        tabindex: '0',
        'aria-labelledby': 'recipientsTab',
    },

    /**
     * @returns {object}
     */
    templateHelpers() {
        const obj = RecipientsListView.prototype.templateHelpers.call(this);

        return util.extend(obj, {
            hideSubHeader: true,
        });
    },
});

let list = CustomRecipientsTabView;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        insertActions: [
            {
                label: 'button.insertinto',
                entitlement: 'INSERT',
                handlerMethodName: 'insert',
            },
        ],
        bulkActions: mobileGridDefaultBulkActions,
    });
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
