import Layout from '@glu/core/src/layout';
import $ from 'jquery';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import confirmDataPoller from 'common/dynamicPages/views/workflow/confirmDataPoller';
import template from './confirmData.hbs';

export default Layout.extend({

    template,

    initialize(options) {
        const self = this;
        this.confirms = {
            confirmResults: [],
        };
        if (options.confirms) {
            this.confirms.confirmResults = options.confirms.confirmResults;
            if (confirmDataPoller.createPoller(self, this.confirms.confirmResults)) {
                self.listenTo(self, 'updateConfirmPollerMsg', self.updateConfirmPollerMsg);
            }
        }
    },

    templateHelpers() {
        const self = this;
        let messages;
        return {
            confirms() {
                if (self.confirms
                    && self.confirms.confirmResults.length > 0
                    && util.isArray(self.confirms.confirmResults[0].messages)
                    && self.confirms.confirmResults[0].messages.length > 0) {
                    ([{ messages }] = self.confirms.confirmResults);
                    self.confirms.confirmResults[0].hasMessageContent = messages[0] && util.has(messages[0], 'messageContent');
                }
                return self.confirms;
            },
            confirmResults() {
                return self.confirms.confirmResults;
            },
        };
    },
    onClose() {
        confirmDataPoller.dispose();
    },
    updateConfirmPollerMsg(res) {
        const pollId = `confirm.poll.${res.tnum}`;
        $(`div[data-pollId="${pollId}"]`).html(res.msg);
        if (res.msg.includes(locale.get('payment.confirm.rejected.msg')) === true) {
            if ($('div[role="alert"]')) {
                $('div[role="alert"]').removeClass('alert-positive').addClass('alert-negative');
            }
            if ($('span[class="icon-tick"]')) {
                $('span[class="icon-tick"]').removeClass('icon-tick').addClass('icon-warning');
            }
        }
    },

});
