import ItemView from '@glu/core/src/itemView';
import patterns from 'system/validatorPatterns';
import typeItemViewTmpl from './typeItemView.hbs';

export default ItemView.extend({
    template: typeItemViewTmpl,

    initialize() {
        this.model.validators = {
            name: {
                exists: true,
                description: 'Name',
                matches: patterns.ALPHANUMERICSPACE_PATTERN,
            },
        };
    },
});
