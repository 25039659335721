import React from 'react';
import PropTypes from 'prop-types';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import classnames from 'classnames';
import RenderFlag from './RenderFlag';
import useStyles from './CurrencyBadgeHeader.styles';

const CurrencyBadgeHeader = ({ currencyCode, count, isRow }) => {
    const classes = useStyles();
    const hideFlag = serverConfigParams.get('BTRDisplayFlagIconWithTotals')?.toLowerCase() === 'false';
    if (!currencyCode) return null;
    return (
        <div
            className={classnames(classes.icon, {
                [classes.row]: isRow,
                [classes.noFlagIcon]: hideFlag,
                [classes.noFlagRow]: hideFlag && isRow,
            })}
        >
            { !hideFlag &&
                <div className={`${isRow && classes.imgRow}`}>
                    <RenderFlag name={currencyCode} />
                </div>
            }
            <div>{currencyCode}</div>
            <div>({count})</div>
        </div>
    );
};

CurrencyBadgeHeader.defaultProps = {
    count: null,
    currencyCode: null,
    isRow: false,
};

CurrencyBadgeHeader.propTypes = {
    count: PropTypes.number,
    currencyCode: PropTypes.string,
    isRow: PropTypes.bool,
};

export default CurrencyBadgeHeader;
