import util from "@glu/core/src/util";
import { appBus, date } from '@glu/core';
import Formatter from 'system/utilities/format';
import DateView from './dateView';
import template from './startDate.hbs';

export default DateView.extend({
    template,
    propertyName: 'starts',

    modelEvents: {
        'change:starts': 'render',
        'set:dateValue': 'setDate',
    },

    events: {
        'blur [data-hook="getStartDatepicker"]': 'validateShownDateField',
        'change [data-hook="getStartDatepicker"]': 'validateShownDateField',
    },

    validateShownDateField() {
        appBus.trigger('startDate:change');
    },

    onRender() {
        DateView.prototype.onRender.apply(this);
        if (this.datepicker) {
          // make sure 'starts' is set to mindate if older date
          const currentStarts = this.model.get('starts') || Formatter.formatDate(new Date());
          const currentMin = this.model.get('minDate') || Formatter.formatDate(new Date());
            if (date(currentStarts) < date(currentMin)) {
                this.model.set('starts', currentMin);
            }
            this.datepicker.onDatePicked = (dateRangePicker) => {
                this.model.set('starts', date(dateRangePicker.startDate).format('YYYY-MM-DD'));
            };
        }
    },

    initialize(options) {
        options = options || {};
        this.options = options;

        this.serverFormat = options.startDate && options.startDate.dateFormats ? options.startDate.dateFormats.server : 'YYYY-MM-DD';
        this.uiFormat = options.startDate && options.startDate.dateFormats ? options.startDate.dateFormats.ui : 'DD MMM YYYY';
        this.minDate = options.model && options.model.has('minDate') ? date(options.model.get('minDate'), this.serverFormat) : date(options.model.get(this.propertyName), this.serverFormat);
    },
    templateHelpers() {
        return util.extend(
            DateView.prototype.templateHelpers.call(this),
            {
                startDateRequired: this.model.get('startDateRequired'),
            },
        );
    },
});
