import Model from '@glu/core/src/model';
import Layout from '@glu/core/src/layout';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import services from 'services';
import errorHandlers from 'system/error/handlers';
import store from 'system/utilities/cache';
import constants from 'app/reports/constants';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';

const ReportsListCollection = Model.extend({
    initialize(models, options) {
        this.queryId = options.id;
    },

    sync(method, collection, options) {
        if (method === 'read') {
            const url = services.generateUrl('/tableMaintenance/reports/getListView');

            http.post(
                url,
                {
                    startRow: 1,
                    rowsPerPage: 10,

                    searchFields: [{
                        dataType: 'text',
                        fieldName: 'ID',
                        operator: '=',
                        fieldValue: [this.queryId],
                    }],
                },
                (result) => {
                    if (result.rows && result.rows.length > 0) {
                        options.success(result);
                    } else {
                        options.error(result);
                    }
                },
                (result) => {
                    options.error(result);
                },
            );
        }
    },

    parse(data) {
        const retObj = {};

        this.context = {
            actionData: data.context.actionData,
            serviceName: data.context.serviceName,
        };

        if (data.rows && data.rows.length > 0) {
            util.each(data.rows[0].columns, (column) => {
                if (column.fieldName !== 'ACTIONLIST') {
                    retObj[column.fieldName] = column.fieldValue;
                }
            });
        }

        return retObj;
    },
});

export default Layout.extend({
    template: loadingPageTmpl,

    onRender() {
        const self = this;

        const reportsList = new ReportsListCollection({}, this.options.storedPreObj);
        reportsList.fetch({
            success(result) {
                store.set(constants.REPORTING_CONTEXT, result.context);
                store.set(constants.REPORTING_VIEWMODEL, result);
                self.navigateTo(constants.REPORT_VIEW_ROUTE);
            },

            error(result) {
                if (result.rows && result.rows.length > 0) {
                    util.bind(errorHandlers.loading, self);
                } else {
                    self.navigateTo(constants.REPORT_NOTENTITLED_ROUTE);
                }
            },
        });
    },
});
