var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"form-inline\">\n    <div class=\"search-input-wrapper date-picker-container\">\n        <div class=\"control-group\">\n            <input type=\"text\" class=\"date-picker-input input-date\" />\n            <div class=\"date-picker-wrapper\"></div>\n        </div>\n    </div>\n\n    <button type=\"submit\" name=\"submit\" class=\"btn btn-primary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.datepicker.apply",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":64},"end":{"line":9,"column":100}}}))
    + "</button>\n    <button type=\"button\" name=\"clear\" class=\"btn btn-tertiary btn-clear-filter\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"clear",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":81},"end":{"line":10,"column":99}}}))
    + "</button>\n</form>";
},"useData":true});