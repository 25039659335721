<{{primaryTagName}}
	{{#if action}} data-action="{{action}}"{{/if}}
	class="icon-wrapper {{#if className}}{{className}}{{/if}}"
	{{#if role}}role="{{role}}"{{/if}}
	{{#if ariaLabel}}aria-label="{{ariaLabel}}"{{/if}}
	{{#if title}}title="{{title}}"{{/if}}
	{{#if dataToggle}}data-toggle="{{dataToggle}}"{{/if}}
	{{#if dataPlacement}}data-placement="{{dataPlacement}}"{{/if}}>

{{#if iconHTML}}
	{{{iconHTML}}}
{{else}}
	<span class="icon-{{name}}" title="{{title}}"></span>
{{/if}}

{{#if srOnly}}
	<span {{#unless textVisible}}class="sr-only"{{/unless}}>{{srOnly}}</span>
{{/if}}

</{{primaryTagName}}>
