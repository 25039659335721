import store from 'pcm/common/store';
import React from 'react';
import locale from '@glu/locale';
import ContentLoading from 'pcm/common/components/ContentLoading';
import EntitlementProvider from 'pcm/common/entitlementContext/EntitlementProvider';
import { outboundScheduleEntitlements } from './OutboundScheduleConfigUtils';

const OutboundScheduleConfig = React.lazy(() => import('./OutboundScheduleConfigList'));
const AddOutboundScheduleConfig = React.lazy(() => import('./AddOutboundScheduleConfig'));
const ViewOutboundScheduleConfig = React.lazy(() => import('./ViewOutboundScheduleConfig'));
const EditOutboundScheduleConfig = React.lazy(() => import('./EditOutboundScheduleConfig'));

const getActionToTitleOptions = () => ({
    title: {
        view: locale.get('outboundScheduleConfig.viewOutboundScheduleConfigList'),
        add: locale.get('outboundScheduleConfig.addOutboundScheduleConfigList'),
        edit: locale.get('outboundScheduleConfig.editOutboundScheduleConfigList'),

    },
    backLink: '/ui-admin-portal/ADMINSTRATION/outboundScheduleConfig',
});

export default {

    outboundScheduleConfig() {
        this.showPage(
            locale.get('outboundScheduleConfig.list'),
            <React.Suspense fallback={<ContentLoading />}>
                <EntitlementProvider entitlements={outboundScheduleEntitlements}>
                    <OutboundScheduleConfig />
                </EntitlementProvider>
            </React.Suspense>,
            {
                headlineText: locale.get('outboundScheduleConfig.list'),
            },
        );
    },

    outboundScheduleConfigActions(action) {
        const components = {
            view: ViewOutboundScheduleConfig,
            add: AddOutboundScheduleConfig,
            edit: EditOutboundScheduleConfig,
        };

        store.action = action;
        store.alerts = {};

        const actionToTitleOptions = getActionToTitleOptions();
        const ComponentToShow = components[action];

        this.showPage(
            actionToTitleOptions.title[action],
            <React.Suspense fallback={<ContentLoading />}>
                <EntitlementProvider entitlements={outboundScheduleEntitlements}>
                    <ComponentToShow action={action} />
                </EntitlementProvider>
            </React.Suspense>,
            {
                headlineText: actionToTitleOptions.title[action],
                backLink: actionToTitleOptions.backLink,
            },
        );
    },
};
