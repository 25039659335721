import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import accountSelectItemTmpl from './accountSelectItem.hbs';

const AccountSelectItem = ItemView.extend({
    template: accountSelectItemTmpl,
    tagName: 'tr',
    className: '',

    ui: {
        $checkbox: 'input[type="checkbox"]',
        $thresholdInput: 'input[type="text"]',
    },

    events: {
        'click @ui.$checkbox': 'toggleCashOnHand',
    },

    modelEvents: {
        change: 'render',
        saveChangesFromEditMode: 'saveChanges',
    },

    toggleCashOnHand(e) {
        this.model.set('cashFlowEnabled', this.ui.$checkbox.prop('checked'));
        e.stopPropagation();
    },

    onBeforeRender() {
        const $focused = this.$(':focus');
        if ($focused.length > 0) {
            this.focusId = $focused.attr('id');
        }
    },

    onRender() {
        if (this.focusId) {
            this.$(`#${this.focusId}`).focus();
        }
        delete this.focusId;
    },

    templateHelpers() {
        return {
            currencyFormat: userInfo.getCurrencyFormat(),
            currencySymbolString: userInfo.get('currencySymbol'),
            dateFormat: userInfo.getDateFormat(),
            showAlert: !util.isNull(this.model.get('minimumBalanceDate')),
        };
    },

    saveChanges() {
        const threshold = +this.ui.$thresholdInput.val();
        if (!util.isNaN(threshold)) {
            this.model.set({
                minimumBalance: threshold,
            });
        }
    },
});

export default AccountSelectItem;
