import util from '@glu/core/src/util';
import EntryView from 'common/dynamicPages/views/workflow/entry';
import detailsTmpl from 'app/administration/views/alerts/details.hbs';
import ContextModel from 'common/dynamicPages/models/context';
import filterApi from 'common/dynamicPages/api/filters';
import gluAlert from '@glu/alerts';
import alertModel from 'app/administration/models/alerts';
import DataApi from 'common/dynamicPages/api/data';
import PageApi from 'common/dynamicPages/api/view';
import RecipientsView from 'app/administration/views/alerts/recipients';
import gridApi from 'common/dynamicPages/api/grid';
import errorHandlers from 'system/error/handlers';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import helpPageUtil from 'common/util/helpPage';
import workspaceHelper from 'common/workspaces/api/helper';
import CheckProfileListItem from 'pcm/app/checkManagement/checkApplications/CheckProfileListItem'; // PCM-5793
import ListBuilder from 'common/listBuilder/listBuilder'; // PCM-5793
import { Collection } from '@glu/core'; // PCM-5793
import { isDeepLinked, postMessagetoTX } from '../../../../common/util/deeplinkUtil';


const ALERTPRODUCTCODE = '*';
const ALERTFUNCTIONCODE = 'ALERT';
const ALERTTYPECODE = '*';
const ALERT_MODIFYURL = 'ADMINSTRATION/alerts/modifyAlert';

const NOFILTER_ALERT_TYPES = [
    'ACH_VLA',
    'APRVEWPA',
    'BALCHK',
    'FICVALRT',
    'FIPTALRT',
    'FISCALRT',
    'PRFNDCHK',
    'PTMPALRA',
    'REVSALRT',
    'RTGSALAR',
    'RTGSALRA',
    'RTGSBRAL',
];

const ACCOUNT_LOOKUP_POSITIVE_PAY = {
    PRODUCT: 'CM',
    FUNCTION: 'BATCH',
    TYPE: 'BMPPDEC',
    INQUIRYID: 20550,
    ALERT_TYPES: ['BMPPALRT', 'PPNOSUSP', 'BMCUTOFF', 'BMPAALRT'],
};

const ACCOUNT_LOOKUP_REVERSE_POSITIVE_PAY = {
    PRODUCT: 'CM',
    FUNCTION: 'BATCH',
    TYPE: 'CMPPDEC',
    INQUIRYID: 20560,
    ALERT_TYPES: ['CMPPALRT', 'CMPAALRT', 'CMCUTOFF', 'CMPPNOSU'],
};

const ACCOUNT_LOOKUP_CM_ISSUES_VOIDS = {
    PRODUCT: 'CM',
    FUNCTION: 'INST',
    TYPE: '*',
    INQUIRYID: 29568,
    ALERT_TYPES: ['CSTAALRT'],
};

const ACCOUNT_LOOKUP_RISK_MANAGEMENT = {
    PRODUCT: 'RISK',
    FUNCTION: 'BATCH',
    TYPE: 'EBMPPDEC',
    INQUIRYID: 28003,
    ALERT_TYPES: ['EPCUTOFF', 'EPPAALRT', 'EPPNOSUS', 'EPPSUS'],
};

const ACCOUNT_LOOKUP_REPORTING = {
    PRODUCT: 'GIR',
    FUNCTION: 'INST',
    TYPE: 'GIRACCT',
    INQUIRYID: 22300,
    ALERT_TYPES: ['GIRSUMBL', 'GIRCLAV', 'GIRCLLED', 'GIRTRNOT', 'WIREALRT', 'WACTALRT'],
};

const ACCOUNT_LOOKUP_REALTIME_REQUEST = {
    PRODUCT: 'RTP',
    FUNCTION: 'INST',
    TYPE: 'CRTRAN',
    INQUIRYID: 30011,
    ALERT_TYPES: ['REQIALRT'],
};

const ACCOUNT_LOOKUP_CONTROLLED_DISBURSEMENT = {
    PRODUCT: 'GIR',
    FUNCTION: 'CD',
    TYPE: 'CTRLDISB',
    INQUIRYID: 29566,
    ALERT_TYPES: ['CDDAALRT'],
};

const ACCOUNT_LOOKUP_LOCKBOX_AVAILABILITY = {
    PRODUCT: 'GIR',
    FUNCTION: 'LBXAVAIL',
    TYPE: 'LBX_AVL',
    INQUIRYID: 29565,
    ALERT_TYPES: ['LBXAALRT'],
};

const LOCKBOX_LOOKUP_REPORTING = {
    PRODUCT: 'GIR',
    FUNCTION: 'LOCKBOX',
    TYPE: 'LBX_RPT',
    INQUIRYID: 29567,
    ALERT_TYPES: ['LBXDALRT'],
};

const LEGACY_REPORT_LOOKUP = {
    PRODUCT: '_UTIL',
    FUNCTION: 'RPT',
    TYPE: 'LEGREPID',
    INQUIRYID: 17243,
    ALERT_TYPES: ['LEGRALRT'],
};

const ACCOUNT_LOOKUP_CONSTANTS = [
    ACCOUNT_LOOKUP_POSITIVE_PAY,
    ACCOUNT_LOOKUP_REVERSE_POSITIVE_PAY,
    ACCOUNT_LOOKUP_CM_ISSUES_VOIDS,
    ACCOUNT_LOOKUP_RISK_MANAGEMENT,
    ACCOUNT_LOOKUP_REPORTING,
    ACCOUNT_LOOKUP_REALTIME_REQUEST,
    ACCOUNT_LOOKUP_CONTROLLED_DISBURSEMENT,
    ACCOUNT_LOOKUP_LOCKBOX_AVAILABILITY,
    LOCKBOX_LOOKUP_REPORTING,
    LEGACY_REPORT_LOOKUP,
];

// PCM-5793
const buildSourceCollection = (applications, assignedApplications = []) => {
    const sourceApplications = applications.map((app) => {
        const { name, rowId, label } = app;
        return {
            id: rowId,
            name,
            description: label,
        };
    });
    const sourceAssignedApplication = assignedApplications;
    const sourceFinal = sourceApplications.filter(sApplication => (
        !sourceAssignedApplication.find(assigned => assigned.name === sApplication.name)
    ));
    return new Collection(sourceFinal);
};

export default EntryView.extend({
    template: detailsTmpl,

    ui: {
        $recipient: 'div[id$="_RECIPIENT"]',
    },
    /**
     * Reset the context before invoking delete
     */
    delete() {
        this.resetContext();
        EntryView.prototype.delete.call(this);
    },
    initialize(options) {
        const opts = {
            menuCategory: 'ALERT',
            serviceName: '/alerts/detailMaint',
            serviceFunc: null,
            businessType: null,
            context: 'ALERTLISTVW',
            mode: options.state || 'insert',
            contextDef: {
                serviceName: '/alerts/detailMaint',
            },
        };

        if (options.returnRoute) {
            store.set('alerts_listView-listRoute', options.returnRoute);
        }

        this.storedPreObj = store.remove('alertDetails', {
            context: {
                productCode: 'ALERT',
                functionCode: 'INST',
            },
        });

        EntryView.prototype.initialize.call(this, opts);
        this.noteForGroup = locale.get('_ADMIN.ALERT.*.*.RECIPIENT.noteForGroup');
        this.noteForRecipient = locale.get('_ADMIN.ALERT.*.*.RECIPIENT.noteForRecipient');
    },

    onRender() {
        if (isDeepLinked()) {
            this.$el.find('.page-header-wrapper').first().hide();
        }
        if (this.storedPreObj && this.storedPreObj.preFill) {
            store.set('alerts_listView-listRoute', 'SETUP/listAlerts');
            store.set(`${this.contextKey}-preFill`, this.storedPreObj.preFill);
        }
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
        } else {
            /*
             * HACK Because the recipients are stored in DB in the RECIPIENT field not
             * in the RECIPIENT_EMAIL or RECIPIENT_GROUP fields, populate the
             * respective field from the RECIPIENT attribute based on the GROUPFLAG
             */
            if (this.options.mode === 'view') {
                const attr = this.storedModel.get('GROUPFLAG') === '0'
                    ? 'RECIPIENT_EMAIL' : 'RECIPIENT_GROUP';
                this.storedModel.set(attr, this.storedModel.get('RECIPIENT'));
            }
            EntryView.prototype.renderPage.call(this);
            /**
             * check not only if the required data is loaded but check that we haven't already
             * setup events in insert mode, loadRequiredData (called from EntryView onRender)
             * will call render w/o any service calls and we don't want to set up duplicate
             * event listeners
             */
            if (!this.eventsLoaded) {
                this.listenTo(this.pageView, 'ui-loaded', this.setAlertSync);
                this.listenTo(this.appBus, 'alert:closeFilter', this.resetFilterRegion);
                this.listenTo(this.appBus, 'alert:alertTypeChanged', this.handleContinue);
                this.listenTo(this.pageView, 'typeahead:beforeChange:RECIPIENT_GROUP', this.beforeSelectRecipient);
                this.listenTo(this.pageView, 'show:recipient:region', this.showRecipientRegion);
                this.listenTo(this.pageView, 'empty:recipient:region', this.closeRecipientRegion);
                this.listenTo(this.pageView, 'typeahead:remove:RECIPIENT_EMAIL typeahead:remove:RECIPIENT_GROUP', this.recipientRemoved);
                this.eventsLoaded = true;

                if (this.options.mode === 'modify' || this.options.mode === 'view') {
                    this.listenTo(this.pageView, 'loaded', () => {
                        this.handleContinue();
                        this.showRecipientRegion(this.pageView.model);
                    });
                    if (isDeepLinked()) {
                        this.$el.find('.page-header-wrapper').first().hide();
                    }
                } else {
                    // hide save button
                    this.$('button[name="save"]').hide();
                }
            }
        }
    },

    /**
     * PCM-5793
     * @name updateApplications
     * @description Triggered when appliaction selection
     * changes on listbuilder and updataing the model
     *
     */
    updateApplications(selectedApplications, filterView) {
        const descArray = selectedApplications.map(app => app.name);
        filterView.model.set({
            DESCRIPTION: descArray,
        });
    },
    /**
     * PCM-5793
     * @name renderListBuilder
     * @description render new component list Builder replacing the application selection
     *
     */
    renderListBuilder(sourceCollection, targetCollection, filterView) {
        const gridItemView = CheckProfileListItem;
        const resource = 'CheckApplications';
        const localeResource = 'Applications';

        const pluralString = resource;
        const pluralStringLocale = localeResource;

        const listBuilder = new ListBuilder({
            id: 'uce-add-companies',
            className: 'list-builder dgb-list-builder',
            sourceCollection,
            targetCollection,
            labelView: gridItemView, // PCM-654
            showBusyIndicator: true,

            text: {
                moveSelectedItems: locale.get(`uce.moveSelected${pluralStringLocale}`),
                moveAllItems: locale.get(`uce.moveAll${pluralStringLocale}`),
                removeSelectedItems: locale.get(`uce.removeSelected${pluralStringLocale}`),
                removeAllItems: locale.get(`uce.removeAll${pluralStringLocale}`),
                filter: locale.get(`uce.filter${pluralStringLocale}`),
                sourceListHeader: `uce.nAvailable${pluralStringLocale}`,
                targetListHeader: `uce.nAssigned${pluralStringLocale}`,
                title: locale.get(`uce.add${pluralString}`),
                save: locale.get(`uce.add${pluralStringLocale}`),
                cancel: locale.get('button.cancel'),
            },
        });
        this.listenTo(listBuilder, 'targetListUpdated', (s) => {
            this.updateApplications(s, filterView);
        });
        this.listBuilderRegion.show(listBuilder);
    },

    /**
     * PCM-5793
     * @name populateFiltersApp
     * @description preparing data to populate and create listbuilder with the applications
     *
     */
    populateFiltersApp(mainModel, filterView) {
        // const inFilters = mainModel.alertFilters;
        let description = [];
        const modelDescription = filterView.model.get('DESCRIPTION');
        if (util.isArray(modelDescription)) {
            description = filterView.model.get('DESCRIPTION');
        } else if (util.isString(modelDescription)) {
            description = modelDescription ? modelDescription.split(',') : [];
        }

        const savedValues = description.map(item => ({ name: item }));
        const sourceCollection =
            buildSourceCollection(filterView.comboCollections.DESCRIPTION, savedValues);
        const targetCollection = buildSourceCollection(savedValues);
        if (this.options.mode !== 'view') {
            this.renderListBuilder(sourceCollection, targetCollection, filterView);
        }
    },

    /**
     * @name setAlertSync
     * @description update the pageView model to use the alertModel's sync and utility methods
     *
     */
    setAlertSync() {
        // user the alertModel sync methods
        this.pageView.model = util.extend({}, this.pageView.model, alertModel);
    },

    loadRequiredData() {
        const helpPagePromise = helpPageUtil.getHelpPagePromise({
            productCode: '*',
            functionCode: 'ALERT',
            typeCode: '*',
            mode: '*',
        });

        // clear out helpPage from cache
        store.unset('helpPage');

        helpPagePromise.then((result) => {
            if (result.helpPage) {
                store.set('helpPage', result.helpPage);
                /**
                 * need to save this so we can reset the helpPage in modify after
                 * the filter is
                 * retrieved
                 */
                this.helpPage = result.helpPage;
            }
            if (this.mode === 'insert' || this.mode === 'import') {
                this.setHasLoadedRequiredData(true);
                this.render();
            } else {
                DataApi.model.generateModelFromModel(store.get(`${this.contextKey}-actionModel`)).then((newModel) => {
                    let newModelParam = newModel;
                    //  apply sync to model for modify
                    newModelParam = util.extend({}, newModelParam, alertModel);

                    newModelParam.fetch({
                        success: () => {
                            this.storedModel = newModelParam;
                            this.setHasLoadedRequiredData(true);
                            this.render();
                        },
                        error: util.bind(errorHandlers.loading, this),
                    });
                });
            }
        });
    },

    /**
     * @name showRecipientRegion
     * @description displays the recipient region for the alert
     * this method is called initially in modify & view and also when a recipient
     * is added from
     * the dropdown
     * @param {Object} model in modify or view, this is the pageView's model
     */
    showRecipientRegion(model) {
        // if the recipient just chosen is a group, then close the select box
        if (model.get('GROUPFLAG') === '1') {
            this.pageView.$('.multi-type-ahead[name="RECIPIENT_GROUP"]').comboBox('close');
        }

        // create the recipientsView if it doesn't already exist
        if (!this.recipientsView) {
            this.recipientsView = new RecipientsView({
                context: this.contextDef,
                actionMode: this.contextDef.actionMode,
                formModel: model,
                alertId: this.pageView.model.get('ALERTID'),
            });
            // load the recipient region
            this.recipientRegion.show(this.recipientsView);
        } else {
            // locate the last recipient to be selected
            const recipients = model.get('RECIPIENT').split?.(',');
            const recipient = recipients[recipients.length - 1];
            // add the recipient to the view
            this.recipientsView.addRecipient({
                recipient,
                groupFlag: model.get('GROUPFLAG'),
                alertId: model.get('ALERTID'),
            });
        }
    },

    /**
     * Close the region and clear the view
     */
    closeRecipientRegion() {
        this.recipientRegion.close();
        this.recipientsView = undefined;
    },

    /**
     * @name recipientRemoved
     * @description this is the event handler that gets called when a recipient
     * is removed
     * @param {string} attrName - recipient control name
     * @param {string} removed - recipient being removed
     */
    recipientRemoved(attrName, removed) {
        const recipientWasGroup = this.pageView.model.get('GROUPFLAG') === '1';
        if (this.recipientsView) {
            // remove the recipient from the collection
            this.recipientsView.removeRecipient(removed, recipientWasGroup);
        }
    },

    /**
     * @name beforeSelectRecipient
     * @description the user can only select either 1 group or 1 or more individual
     * recipients
     *              prevent the user from choosing something that they are not
     * allowed to select
     * @param {object} evt - event object
     * @param {string} name - select2/combobox name
     * @returns {boolean}
     */
    beforeSelectRecipient(evt) {
        if (this.pageView?.model?.get?.('RECIPIENT')) {
            evt.preventDefault();
        }
    },

    /**
     * @name validateRecipients
     * @description validates that at least one recipient contact method has
     * been selected
     * @returns {boolean}
     */
    validateRecipients() {
        if (this.alertRegion && this.alertRegion.$el) {
            // clear the alert region
            this.alertRegion.$el.empty();
        }

        let valid = false;
        if (this.recipientRegion && this.recipientRegion.currentView) {
            this.recipientRegion.currentView.collection.each((recipient) => {
                // check each email
                const selectedEmail = util.filter(recipient.get('email'), email => email.selected === true);
                // check each phone
                const selectedPhone = util.filter(recipient.get('phone'), phone => phone.selected === true);
                // check each sms
                const selectedSMS = util.filter(recipient.get('sms'), sms => sms.selected === true);
                // check each fax
                const selectedFax = util.filter(recipient.get('fax'), fax => fax.selected === true);
                // eslint-disable-next-line
                valid = valid || !!((selectedEmail.length > 0)
                    || (selectedPhone.length > 0)
                    || (selectedSMS.length > 0)
                    || (selectedFax.length > 0));
            });
        }
        return valid;
    },

    /**
     * @name resetFilterRegion
     * @description resets the alert filter/criteria section. called when the
     * alerttype changes
     */
    resetFilterRegion() {
        this.filterRegion.close();
        this.$('button[name="save"]').hide();
    },

    /**
     * @name resetContext
     * @description resets the contextDef for the alert region
     */
    resetContext() {
        this.contextModel = new ContextModel({
            contextDef: {
                serviceName: 'alerts/listView',
            },
        });
        this.contextKey = this.contextModel.getContextKey();
    },

    /**
     * @name cancel
     * @description cancel button handler
     */
    cancel() {
        this.resetContext();
        this.disableButtons();
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    /**
     * @name modify
     * @description modify button handler
     */
    modify() {
        store.set(`${this.contextKey}-actionModel`, this.tempModel);
        this.navigateTo(ALERT_MODIFYURL);
    },

    /**
     * @name save
     * @description save button handler
     * saves the alert and it's recipients and filters
     */
    save() {
        let model;
        this.disableButtons();

        if (this.filterRegion
            && this.filterRegion.currentView
            && this.filterRegion.currentView.model) {
            ({ model } = this.filterRegion.currentView);
        } else {
            ({ model } = this.pageView);
        }

        if (!this.validateRecipients()) {
            const message = gluAlert.negative(locale.get('alerts.NoRecipientSelected'), {
                canDismiss: true,
            });

            if (isDeepLinked()) {
                postMessagetoTX(locale.get('alerts.NoRecipientSelected'), false);
            } else {
                this.alertRegion.show(message);
            }

            return;
        }

        /**
         * make sure all mandatory fields from two models will be validated and all error
         * messages would be displayed on the page, so that user experience would be
         * consistent
         * with other pages which only
         * have one model in it.
         */
        if (model.isValid() && this.pageView.model.isValid()) {
            const alertType = this.pageView.model.get('ALERTTYPE');

            this.pageView.model.set({
                filters: this.getFilters(alertType, model),
                PRODUCT: this.pageView.model.get('ALERTGROUP'),
                FUNCTION: ALERTFUNCTIONCODE,
                TYPE: alertType,
                recipients: this.recipientRegion.currentView.collection,
                // add fine grain recipient data to model
            });

            // reset service
            this.pageView.model.context.serviceName = '/alerts/detailMaint';
            this.pageView.save();
            this.resetContext();
        }
    },

    /**
     * Based on the alertType, fetch and return filters array from the api or
     * return an empty
     * array
     * @param {string} alertType
     * @param {Model} model
     * @returns {Array}
     */
    getFilters(alertType, model) {
        // No filters for these types
        if (util.contains(NOFILTER_ALERT_TYPES, alertType)) {
            return [];
        }
        return filterApi.gatherFilterData(model);
    },

    /**
     * Sets the response and alert messages in the store and returns to the current workspace
     * @param {string} confirmResponse - the confirmation response
     * Returns to the current workspace
     */
    actionSuccess(confirmResponse) {
        const { contextKey } = this;
        store.set(`${contextKey}-alertMessage`, this.mode.toUpperCase());
        store.set(`${contextKey}-confirms`, confirmResponse);
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    /**
     * @name getCacheName
     * @returns the cache/store name for the alert view
     * @returns {string}
     */
    getCacheName() {
        return `model-${this.filterContextDef.serviceName}-${ALERTPRODUCTCODE}-${ALERTFUNCTIONCODE}`;
    },

    /**
     * Will loop through and parse filter items in the page Model and set them
     * within the
     * filterView's model
     *  @param {object - model} mainModel
     *  @param {object - view} filterView
     */
    populateFilters(mainModel, filterView) {
        const inFilters = mainModel.alertFilters;
        let filtersViewData;
        // reset the helpPage after the mdf filter is retrieved
        store.set('helpPage', this.helpPage);
        if (util.isArray(inFilters)) {
            // loop through each filter and reduce into one filter data object to populate model
            filtersViewData = util.reduce(inFilters, (acc, filter) => {
                let accParam = acc;
                // combine needed filter props into a single obj for further use
                const filterPropsConfig = util.reduce(filter.item, (obj, propItem) => {
                    const objParam = obj;
                    objParam[propItem.name] = propItem.value;
                    return objParam;
                }, {});

                // get parsed values for current filter in filter view's model
                const filterData = this.parseFilterPropData(filterView, filterPropsConfig);

                // merge parsed filter data with accumulator of data;
                accParam = util.extend(accParam, filterData);
                return accParam;
            }, {});

            // set accumulated filtersViewData into View Model
            filterView.model.set(filtersViewData);
        }
    },

    /**
     * method to remove quotations, and format filter values (based on type of filter)
     * takes into account when there can be multiple values in one string that
     * needs parsing
     * @param {string} value - filter's value
     * @return {string|string[]} all parsed values
     */
    sanitizeFilterValues(value) {
        let newValue = value.substring(1);
        newValue = newValue.substring(0, newValue.length - 1);
        newValue = newValue.split('\',\'');
        return (newValue.length === 1) ? newValue[0] : newValue;
    },

    /**
     * Sets new values for current Filter View Model
     * @param {object - view} filterView -  Filter Sections Page View (will be
     * used as reference
     * for some operations)
     * @param {object} filterProps - object containing properties of a single filter
     * @return {object} resultData - formatted data for a single filter (to be
     * set into model)
     */
    parseFilterPropData(filterView, filterProps) {
        // use only needed filter properties ('FIELDNAME', 'OPERATOR', 'VALUE')
        const filterName = filterProps.FIELDNAME;

        const filterOperator = filterProps.OPERATOR;
        let filterValue = this.sanitizeFilterValues(filterProps.VALUE, filterOperator);
        const resultData = {};
        const fieldUi = filterView.model.fieldData[filterName].fieldUIType;

        // check state of filterView
        if (filterView.state === 'view'
            && (fieldUi === 'COMBOFILTER' || fieldUi === 'COMBOFILTERLT')
            && filterView.comboCollections[filterName]) {
            const options = filterView.comboCollections[filterName];
            const matchingOption = util.find(options, opt => opt.name === filterValue);
            // adjust filterValue if combo fiel type
            filterValue = matchingOption.label;
        }

        if (filterOperator === 'BETWEEN') {
            /*
             * special case where populating filter for between (range) inputs
             * include data for range values of filter
             */
            [
                resultData[filterName],
                resultData[`${filterName}2`],
            ] = filterValue;
        } else {
            resultData[filterName] = filterValue;
        }

        // include equality property for filter
        resultData[`${filterName}-equality`] = filterOperator;
        return resultData;
    },

    /**
     * @name handleContinue
     * @description displays the filter region when the alert type is set
     */
    handleContinue() {
        const { model } = this.pageView;

        // use TYPE unless it's empty, then user alerttype
        const alertType = model.get('TYPE') || model.get('ALERTTYPE');

        const self = this;
        const filterContextDef = {};

        filterContextDef.serviceName = '/advanceFilter';
        // need 'context' for getQueryResult filters
        filterContextDef.productCode = ALERTPRODUCTCODE;
        filterContextDef.functionCode = ALERTFUNCTIONCODE;
        filterContextDef.typeCode = ALERTTYPECODE;
        self.filterContextDef = filterContextDef;
        const requestData = {
            filterId: 0,
            alertType,
            typeInfo: {
                productCode: ALERTPRODUCTCODE,
                functionCode: ALERTFUNCTIONCODE,
                typeCode: ALERTTYPECODE,
            },
        };
        this.setAccountRequestData(alertType, requestData);

        store.unset(this.getCacheName());
        store.set(this.getCacheName(), this.model);

        if (!util.isEmpty(alertType) && (!util.contains(NOFILTER_ALERT_TYPES, alertType))) {
            const promiseMe = filterApi.getFilterData(requestData, this.getCacheName());
            promiseMe.then(() => {
                if (self.filterRegion) {
                    const mode = (self.mode === 'view') ? 'view' : 'insert';
                    const contextDef = self.filterContextDef;
                    if (alertType === 'BENEALRT' || alertType === 'ROLEALRT' || alertType === 'USERALRT') {
                        contextDef.typeCode = alertType;
                        contextDef.queryType = 'ActionAlertsMaint';
                    }
                    self.filterRegion.show(PageApi.page.get({
                        context: contextDef,
                        state: mode,
                        hideButtons: true,
                        gridApi,
                    }));
                    self.filterRegion.currentView.listenTo(self.filterRegion.currentView, 'loaded', util.bind(self.populateFilters, self, model, self.filterRegion.currentView));
                    // PCM-5793
                    if (alertType === 'RTRPALT') {
                        self.filterRegion.currentView.listenTo(self.filterRegion.currentView, 'loaded', util.bind(self.populateFiltersApp, self, model, self.filterRegion.currentView));
                        self.listenToOnce(self.filterRegion.currentView, 'item:rendered', () => {
                            if (mode !== 'view') {
                                self.$el.find('div[data-region="filterRegion"] fieldset.section.section-container').find('.field-container:eq(1)').addClass('hidden');
                            }
                        });
                    }
                }
            }).then(() => {
                self.$('button[name="save"]').removeClass('btn-secondary').addClass('btn-primary').show();
                // reset service name
                self.pageView.model.context.serviceName = '/alerts/detailMaint';
            });
        } else {
            self.$('button[name="save"]').removeClass('btn-secondary').addClass('btn-primary').show();
            // reset service name
            self.pageView.model.context.serviceName = '/alerts/detailMaint';

            /**
             * if it exists remove previous view from filter region because this alert type does
             * not have any filters
             */
            if (self.filterRegion.currentView) {
                self.filterRegion.currentView.close();
            }
        }
    },

    /**
     * setAccountRequestData sets the correct context in a request object from the
     * ACCOUNT_LOOKUP_CONSTANTS array based on the given typeCode.
     * @param {string} typeCode - type of alert the user is editing
     * @param {string} requestData - target request object to set the context
     * information for
     * combobox population
     */
    setAccountRequestData(typeCode, requestData) {
        const requestDataParam = requestData;
        /**
         * Set the Account field lookup context values so that only entitled accounts are
         * returned.
         *
         */
        const alertContext = util.find(
            ACCOUNT_LOOKUP_CONSTANTS,
            acctLookup => util.contains(acctLookup.ALERT_TYPES, typeCode),
        );

        if (alertContext) {
            requestDataParam.typeInfo = {
                productCode: alertContext.PRODUCT,
                functionCode: alertContext.FUNCTION,
                typeCode: alertContext.TYPE,
            };
        }
    },

    getInsertButtons() {
        this.buttonList.push({
            action: 'SAVE',
        });
    },

    getModifyButtons() {
        this.buttonList.push({
            action: 'SAVE',
        });
    },
});
