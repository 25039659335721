import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import validatorPatterns from 'system/validatorPatterns';
import $ from 'jquery';
import accountGroupHeaderViewTmpl from './accountGroupHeaderView.hbs';

const AccountGroupHeaderView = ItemView.extend({
    template: accountGroupHeaderViewTmpl,
    className: 'panel-heading',
    attributes: {
        role: 'tab',
    },

    ui: {
        $accountsCount: '[data-hook="getAccountsCount"]',
        $accountGroupDisplay: '[data-hook="getAccountGroupDisplay"]',
        $groupName: '[data-hook="getGroupName"]',
        $groupNameInput: '[data-hook="getGroupNameInput"]',
    },

    events: {
        'blur input': 'finishEdit',
        'keydown input': 'finishEditOnEnter',
    },

    initialize() {
        this.isEditMode = false;
        this.listenTo(this.model.get('accounts'), 'add remove', this.updateCount);
        this.listenTo(this.model, 'change:NAME', this.updateName);
        // set validator
        this.model.validators = {
            NAME: {
                description: 'Account Group Name',
                exists: true,
                matches: validatorPatterns.NOBADCHARS_PATTERN,
            },
        };
    },

    templateHelpers() {
        return {
            collapseId: `collapse-${this.model.cid}`,
            dynamicTitle: locale.get('gir.manageAccountGroups.deleteGroup', this.model.getName()),
        };
    },

    /**
     * @method getAccountsCount
     * @param {array} accounts
     * Produce localized text indicating the number of accounts in current Account Group
     */
    getAccountsCount(accounts) {
        return locale.get('gir.manageAccountGroups.numAccounts', accounts.length).replace('{0}', accounts.length);
    },

    updateCount() {
        const accountsCount = this.getAccountsCount(this.model.get('accounts'));
        this.ui.$accountsCount.text(accountsCount);
    },

    updateName() {
        const name = this.model.getName();
        this.ui.$groupName.text(name);
        this.ui.$groupNameInput.val(name);
    },

    /**
     * @method toggleEditMode
     * Toggles on and off the edit name input
     */
    toggleEditMode() {
        this.isEditMode = !this.isEditMode;
        this.ui.$groupNameInput.toggleClass('hidden', !this.isEditMode);
        this.ui.$accountGroupDisplay.toggleClass('hidden', this.isEditMode);

        if (this.isEditMode) {
            const $input = this.ui.$groupNameInput;
            // make sure the input is in the DOM before attempting to focus
            util.defer(() => {
                $input.focus();
                const inputElement = $input.get(0);
                inputElement.setSelectionRange(0, inputElement.value.length);
            });
        }
    },

    finishEdit() {
        const newName = this.ui.$groupNameInput.val().trim();
        if (newName && (newName !== this.model.getName())) {
            this.model.setName(newName);
        }

        if (this.model.isValid()) {
            this.toggleEditMode();
        }
    },

    finishEditOnEnter(event) {
        if (event.which === $.ui.keyCode.ENTER) {
            this.ui.$groupNameInput.blur();
        }
    },

    onRender() {
        this.updateCount();
        this.updateName();
        this.toggleEditMode();
    },

    delete() {
        this.model.markForDeletion();
    },

});

export default AccountGroupHeaderView;
