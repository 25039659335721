import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { DotDotDotNextIcon } from '@glu/icons-react';
import locale from '@glu/locale';

import Popover from '@glu/popover-react';
import DeleteConfirmation from './DeleteConfirmation';
import Rename from './Rename';
import ViewActions, { ACTIONS } from './ViewActions';

export default function ViewRow(props) {
  const {
    PopoverProps,
    classes,
    deleteName,
    handleCloseMainPopover,
    rename,
    select,
    setViewsVisibility,
    view,
    viewsVisibility
  } = props;
  const {
    defaultText,
    dropdownListItem,
    dropdownListItemName,
    menuToggle,
    rightContainer
  } = classes;
  const [renaming, toggleRenaming] = useState(false);
  const [confirmDelete, toggleDeleteConfirmation] = useState(false);
  const {
    allowedActions, id, isDefault, isStatic, name
  } = view.viewData;
  const showSetDefault = !isDefault && (
    allowedActions?.includes(ACTIONS.SET_DEFAULT) || !allowedActions
  );
  const showRename = !isStatic && (allowedActions?.includes(ACTIONS.RENAME) || !allowedActions);
  const showDelete = !isStatic && (allowedActions?.includes(ACTIONS.DELETE) || !allowedActions);

  return (
    <li className={dropdownListItem} key={name}>
      {!renaming && !confirmDelete && (
      <>
        <button
          className={dropdownListItemName}
          data-qa={`select-${id}`}
          onClick={() => {
            select();
            handleCloseMainPopover();
          }}
          type="button"
        >
          {name}
        </button>

        <div className={rightContainer}>
          {isDefault && (
          <span
            data-qa={`savedViews-default-${id}`}
            className={defaultText}
          >
            {locale.get('dataComponents.default')}
          </span>
          )}
          {(showSetDefault || showRename || showDelete) && (
            <Popover
              className={classes.popover}
              placement="bottom"
              trigger="click"
              noArrow
              enablePopoverClick
              content={/* istanbul ignore next */ ({ closePopover, visible }) => {
                if (viewsVisibility[id] !== visible) {
                  setViewsVisibility((views) => ({ ...views, [id]: visible }));
                }

                return (
                  <ViewActions
                    toggleDeleteConfirmation={toggleDeleteConfirmation}
                    toggleRenaming={toggleRenaming}
                    closePopover={closePopover}
                    showSetDefault={showSetDefault}
                    showRename={showRename}
                    showDelete={showDelete}
                    {...props}
                  />
                );
              }}
              {...PopoverProps}
            >
              <button
                data-qa={`menu-toggle-${id}`}
                className={menuToggle}
                type="button"
                aria-label={locale.get('dataComponents.actions')}
              >
                <DotDotDotNextIcon />
              </button>
            </Popover>
          )}
        </div>
      </>
      )}
      {confirmDelete && (
      <DeleteConfirmation
        {...props}
        view={view}
        classes={classes}
        deleteName={deleteName}
        setViewsVisibility={setViewsVisibility}
        toggleDeleteConfirmation={toggleDeleteConfirmation}
      />

      )}
      {renaming && (
      <Rename
        {...props}
        classes={classes}
        rename={rename}
        view={view}
        toggleRenaming={toggleRenaming}
      />
      )}
    </li>
  );
}

ViewRow.propTypes = {
  /** Options for @popperjs/core see docs for details */
  PopoverProps: PropTypes.shape({}),

  classes: PropTypes.objectOf(PropTypes.string),

  deleteName: PropTypes.func.isRequired,
  handleCloseMainPopover: PropTypes.func,
  rename: PropTypes.func,
  select: PropTypes.func,
  setViewsVisibility: PropTypes.func,
  view: PropTypes.shape({
    viewData: PropTypes.shape({
      allowedActions: PropTypes.arrayOf(PropTypes.string),
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isDefault: PropTypes.bool,
      isStatic: PropTypes.bool,
      name: PropTypes.string
    })
  }),
  viewsVisibility: PropTypes.shape({})
};

ViewRow.defaultProps = {
  PopoverProps: undefined,
  classes: {},

  handleCloseMainPopover() {},

  /* istanbul ignore next */
  rename() {},
  /* istanbul ignore next */
  select() {},
  setViewsVisibility() {},
  view: PropTypes.shape({
    id: 0,
    name: ''
  }),
  viewsVisibility: {}
};
