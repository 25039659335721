import { createUseStyles } from '@glu/theming';

const styles = ({ palette }) => ({
    header: {
        fontWeight: 'bold',
        color: palette.text.dark,
    },
});

export default createUseStyles(styles);
