var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"row\">\n    <div class=\"col-3\" data-hook=\"getPmtTypeDropdown\">\n        <div class=\"form-group\">\n            <label for=\"pmtType\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.pay",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":33},"end":{"line":5,"column":56}}}))
    + "</label>\n            <div data-region=\"pmtPayTypeRegion\"></div>\n        </div>\n    </div>\n    <div class=\"col-3\">\n        <div class=\"form-group\" data-hook=\"getPmtInvoiceContainer\">\n            <label for=\"pmtInvoices\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.invoice",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":37},"end":{"line":11,"column":64}}}))
    + "</label>\n            <div data-region=\"pmtInvoiceRegion\"></div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});