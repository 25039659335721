import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import services from 'services';

const PlanSummaryModel = Model.extend({
    defaults: {
        compositeKey: null,
        cashFlowPlanId: null,
        name: '',
        version: 0,
        createdBy: '',
        savedBy: '',
        createdOn: null,
        selected: false,
        groupPlan: false,
    },

    /*
     * fake out a composite key (created during parse) so that things like
     * sync() and destroy() work.
     */
    idAttribute: 'compositeKey',

    parse(response) {
        const m = response;
        m.compositeKey = `${m.cashFlowPlanId}:${m.version}`;
        return m;
    },

    sync(method) {
        let xhr;
        const self = this;
        switch (method) {
        case 'delete':
            xhr = http.post(
                services.generateUrl('/cashflowPlan/delete'),
                {
                    cashFlowPlanId: self.get('cashFlowPlanId'),
                    version: self.get('version'),
                    multiYearPlanId: self.get('multiYearPlanId'),
                },
                () => {
                    self.trigger('success', self);
                },
                () => {
                    self.trigger('error', self);
                },
            );
            this.trigger('request', xhr);
            break;
        default:
            break;
        }
        return xhr;
    },
});

export default PlanSummaryModel;
