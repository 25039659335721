var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"form-group\">\n    <button type=\"button\" id=\"alert-action-add\" class=\"btn btn-primary\" data-action=\"handleAdd\" aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.add",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":108},"end":{"line":2,"column":131}}}))
    + "\" data-disable-on-submit>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.add",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":156},"end":{"line":2,"column":179}}}))
    + "</button>\n    <button type=\"button\" id=\"alert-action-delete\" class=\"btn btn-primary\" data-action=\"handleBulkRemove\" aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.delete",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":118},"end":{"line":3,"column":144}}}))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.delete",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":146},"end":{"line":3,"column":172}}}))
    + "</button>\n</div>\n";
},"useData":true});