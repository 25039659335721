import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import dialog from '@glu/dialog';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import { isDeepLinked } from 'common/util/deeplinkUtil';
import { Alert } from '@glu/react-wrappers';
import store from 'system/utilities/cache';
import { navigateTo } from 'common/util/navigationUtils';
// import BackLinkHistory from 'pcm/common/components/BackLinkHistory';
import BackLink from 'pcm/common/components/BackLink';
import HeadlineContent from 'pcm/common/components/HeadlineContent';
import Tabs from 'pcm/common/components/Tabs';
import TabsContent from 'pcm/common/components/TabsContent';
import LoadingPage from 'components/LoadingPage/LoadingPage';
import PrintCheckModalView from '../../../pcm_mdf/app/payments/views/printChecks';
import { getAllPaymentData } from './dataUtils';
import TransactionDetails from './TransactionDetails';
import BatchInformation from './BatchInformation';
import CheckPreview from './CheckPreview';
import services from 'services';
import {
    transactionDetailsContract,
    batchInformationContract,
    checkPreviewContract,
} from './paymentContract';

const constants = {
    viewRoute: '/PAYMENTS/viewCheck', // have to be used with navigateTo()
    backLink: isDeepLinked() ? '/ui-portal/PAYMENTS/checkTransactionsWorkspace' : '/ui/PAYMENTS/managePayments', // have to be directly
    backLinkForCancelButton: isDeepLinked() ? '/ui-portal/PAYMENTS/checkTransactionsWorkspace' : '/ui/PAYMENTS/managePayments',
};

const defaultTabsComponents = {
    transactionDetails: TransactionDetails,
    batchInformation: BatchInformation,
    checkPreview: CheckPreview,
};

/**
 * Small helper of how to represent Page Headline.
 *
 * @param typeCode
 * @param checkNumber
 * @param useAlternative
 * @returns {string}
 */
const composeLandingHeader = (typeCode, checkNumber, useAlternative = false) => {
    let str = '';

    switch (typeCode.toLowerCase()) {
    case 'apchk':
        str = `Accounts Payable Check #${checkNumber}`; // TODO clm.type.APCHK
        break;

    case 'gpchk':
        str = `General Purpose Check #${checkNumber}`; // TODO clm.type.GPCHK
        break;

    case 'prchk':
        str = `Payroll Check #${checkNumber}`; // TODO clm.type.PRCHK
        break;
    default:
        str = locale.get('CM.Check');
    }

    // Alternative. TBD.
    if (useAlternative) {
        const str1 = locale.get(`batch.b${typeCode.toLowerCase()}_title-view`);
        const str2 = `#${checkNumber}`;
        str = `${str1} ${str2}`; // View Accounts Payable Check Payment #4332
    }

    return str;
};

class ViewCheckPayment extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            tabsData: this.allData || {
                transactionDetails: transactionDetailsContract,
                batchInformation: batchInformationContract,
                checkPreview: checkPreviewContract,
            },
            alertFromReprint: null,
            alertType: null,
            alerMessage: null,
            loadingAllData: true,
            checkStatus: null,
            newCheckStatus: null,
        };

        this.onChangeCheckStatus = this.onChangeCheckStatus.bind(this);
        this.onChangeLoadCheckStatusReason = this.onChangeLoadCheckStatusReason.bind(this);
        this.onUpdateCheckStatus = this.onUpdateCheckStatus.bind(this);
    }

    getTabsList = (tabData) => {
        const hidePreview = tabData && tabData.batchInformation.CHKPRINTSOURCE.value === 'O';
        return [
            {
                name: 'transactionDetails',
                label: locale.get('common.transactionDetails'),
                visible: true,
                action: this.tabClick,
            }, {
                name: 'batchInformation',
                label: locale.get('payment.batchsummary'),
                visible: true,
                action: this.tabClick,
            }, {
                name: 'checkPreview',
                label: 'Check Preview',
                visible: !hidePreview,
                action: this.tabClick,
            },
        ];
    };

    /**
     * @override Tab.propTypes.action( {function}, {string} )
     * @param activeTab
     */
    tabClick = (activeTab) => {
        const { batchTnum, tnum, typeCode } = this.props;

        this.allData = this.state.tabsData; // kinda "cache" workaround
        navigateTo(`${constants.viewRoute}/${batchTnum}/${tnum}/${typeCode}/${activeTab}/`);
    };


    alertRegion = {
        show: (alertView) => {
            this.setState({
                alertFromReprint: alertView,
                alertType: alertView.options.type,
                alerMessage: alertView.options.message,
            });
        },
    };
    componentDidMount() {
        const { batchTnum, tnum, typeCode } = this.props;
        getAllPaymentData(batchTnum, tnum, typeCode).then((tabsData) => {
            this.setState({
                tabsData,
                loadingAllData: false,
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.alertFromReprint && prevState.alertFromReprint !== this.state.alertFromReprint) {
            this.render();
        }
    }

    onChangeCheckStatus(newCheckStatus) {
        const that = this;
        this.setState({
            newCheckStatus,
        });
    }

    onChangeLoadCheckStatusReason(checkStatusReason) {
        const that = this;
        this.setState({
            checkStatusReason,
        });
    }

    render() {
        const {
            tabsComponents, activeTab, batchTnum, typeCode, ptxCheckId, currentAction,
        } = this.props;
        const {
            tabsData, alertType, alerMessage, alertFromReprint, loadingAllData,
        } = this.state;
        const tabProps = tabsData[activeTab];
        const tabsList = this.getTabsList(tabsData);
        const { value: checkNumber } = tabsData.transactionDetails.CHECKNUMBER;
        const { value: printflag } = tabsData.batchInformation.CHKPRINTSOURCE;
        const landingHeader = composeLandingHeader(typeCode, checkNumber);

        if (activeTab === 'checkPreview') {
            const checkTransactionInfo = store.get('checkTransactionInfo');
            // use case 1 - when click from grid, and then navigate to /checkPreview tab
            // use case 2, when page refreshed, so need to take from store
            tabProps.ptxCheckId = checkTransactionInfo
                ? (checkTransactionInfo.PTX_CHECK_ID || ptxCheckId) : '';
        }

        if (activeTab === 'batchInformation') {
            tabProps.batchTnum = batchTnum;
            tabProps.typeCode = typeCode;
        }

        return (
            <Fragment>

                {!isDeepLinked() && <div className="page-headline row">
                    <BackLink backLink={constants.backLink} />
                    {/* <BackLinkHistory /> */}
                    <HeadlineContent headlineText={landingHeader} />
                </div>}
                {loadingAllData ? (
                    <LoadingPage />
                ) : (
                    <Fragment>
                        <div className="view-pcm-payment">
                            <div className="section section-container">
                                <Tabs tabs={tabsList} activeTab={activeTab} />
                                {alertFromReprint &&
                                    <Alert
                                        type={alertType}
                                        message={alerMessage}
                                        duration={5000}
                                    />}
                                <TabsContent
                                    tabs={tabsComponents}
                                    activeTab={activeTab}
                                    onChangeCheckStatus={this.onChangeCheckStatus}
                                    onChangeLoadCheckStatusReason={this.onChangeLoadCheckStatusReason}
                                    currentAction={currentAction}
                                    {...tabProps}
                                />
                            </div>
                        </div>
                        { this.isCheckReadyToReprint() && printflag !== 'O' && currentAction !== 'UPDCHKST' ? (
                            <div className="row mt40">
                                <div className="actions col-3">
                                    <button type="submit" aria-busy={this.state.saving} className="btn btn-primary save" onClick={() => this.onReprint(this)}>
                                        {locale.get('clm.reprintChecksButton')}
                                    </button>
                                    <button type="button" className="btn btn-secondary cancel" onClick={() => this.onCancel()}>{locale.get('back')}</button>
                                </div>
                            </div>
                        ) : (
                            <div className="row mt40">
                                <div className="actions col-3">
                                    {currentAction == 'UPDCHKST' &&
                                    <button type="button" className="btn btn-primary save" onClick={() => this.onUpdateCheckStatus()}>{locale.get('button.updateCheckStatus')}</button>
                                    }
                                    <button type="button" className="btn btn-secondary cancel" onClick={() => this.onCancel()}>{locale.get('back')}</button>
                                </div>
                            </div>
                        )}
                    </Fragment>
                )}

            </Fragment>
        );
    }

    onCancel = () => {
        navigateTo(constants.backLinkForCancelButton);
    };

    isCheckReadyToReprint() {
        const {
            reprintEntitlements, checkStatus, status,
        } = this.props;

        return reprintEntitlements && checkStatus === 'OPEN' && status === 'RP';
    }

    onUpdateCheckStatus = () => {
        // call the edit action
        this.setState({
            alertFromReprint: null,
            alertType: null,
            alerMessage: null,
        });

        const {
            batchTnum, tnum, typeCode, entryMethod, updateCount,
        } = this.props;
        const updateURL = services.generateUrl('payment/CheckDetailSave');
        const data = {
            CHECKSTATUS: this.state.newCheckStatus,
            UPDATECHECKSTATUS_REASON: this.state.checkStatusReason,
            batchTnum,
            tnum,
            product: 'CLM',
            functioncode: 'CHKMGT',
            actionmode: 'UPDCHKST',
            typeCode,
            entryMethod,
            UpdateCount__: updateCount,
        };

        if (this.state.newCheckStatus === '' || this.state.newCheckStatus == null) {
            this.setState({
                alertFromReprint: 'error',
                alertType: 'negative',
                alerMessage: locale.get('validator.required', locale.get('checkManagement.newCheckStatus')),
            });
        } else if (this.state.checkStatusReason === '' || this.state.checkStatusReason == null) {
            this.setState({
                alertFromReprint: 'error',
                alertType: 'negative',
                alerMessage: locale.get('validator.required', locale.get('PAY.UpdateCheckStatusReason')),
            });
        } else {
            http.post(updateURL, this.formatUpdatedDataToPost(data)).then(() => {
                this.onCancel();
            }, ({ responseJSON }) => {
                console.log(responseJSON);
                // this.props.showMessage('error', responseJSON);
            });
        }
    };

    onReprint = () => {
        this.setState({
            alertFromReprint: null,
            alertType: null,
            alerMessage: null,
        });

        this.isReprintBatch = false;
        this.isReprintCheck = true;
        this.isModal = true;

        const self = this;
        const checkModel = new Map();
        Object.entries(self.state.tabsData.transactionDetails).forEach((entry) => {
            checkModel.set(entry[0], entry[1].value);
        });
        const checkBatchesData = Array.of({
            tnum: self.props.tnum.toString(),
            status: self.props.status,
            printStatus: '',
            PTXId: self.props.ptxCheckId,
            typeCode: self.state.tabsData.batchInformation.TYPE.value,
            updateCount: self.props.updateCount,
        });
        this.printView = new PrintCheckModalView({
            isModal: true,
            isReprintBatch: false,
            isReprintCheck: true,
            paymentListModel: self,
            isActionTriggeredFromPaymentsList: false,
            checkBatchesData,
            checkModel,
            entryMethod: 0,
        });

        dialog.open(self.printView);
    };

    formatUpdatedDataToPost = data => Object.keys(data).reduce((results, key) => {
        if (!results.item) {
                results.item = []; // eslint-disable-line
        }

        results.item.push({ name: key, value: data[key] });

        return results;
    }, {});
}

ViewCheckPayment.propTypes = {
    activeTab: PropTypes.string,
    tabsComponents: PropTypes.shape({}),
    batchTnum: PropTypes.number.isRequired,
    tnum: PropTypes.number.isRequired,
    typeCode: PropTypes.string.isRequired,
    ptxCheckId: PropTypes.string,
    checkStatus: PropTypes.string,
    status: PropTypes.string,
    updateCount: PropTypes.string,
    reprintEntitlements: PropTypes.bool,
    newCheckStatus: PropTypes.string,
    showMessage: PropTypes.func,
};

ViewCheckPayment.defaultProps = {
    activeTab: 'transactionDetails',
    tabsComponents: defaultTabsComponents,
    ptxCheckId: '',
    checkStatus: '',
    status: '',
    updateCount: '',
    reprintEntitlements: false,
    currentAction: 'SELECT',
    newCheckStatus: '',
    showMessage: null,

};

export default ViewCheckPayment;
