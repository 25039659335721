var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"section section-container\">\n    <div class=\"section-body\">\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n         <div class=\"default-heading\">\n            <h4 class=\"pull-left totals-title\">\n                <span>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"CM.TransactionTotals",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":22},"end":{"line":6,"column":55}}}))
    + "</span>\n            </h4>\n            <span class=\"pull-right\" data-region=\"totalsSection\" aria-live=\"polite\"></span>\n        </div>\n            <div class=\"btn-wrapper\">\n                 "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"gridUtilitySection") || (depth0 != null ? lookupProperty(depth0,"gridUtilitySection") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"gridUtilitySection","hash":{},"data":data,"loc":{"start":{"line":11,"column":17},"end":{"line":11,"column":41}}}) : helper))) != null ? stack1 : "")
    + "\n            </div>\n                <div data-region=\"gridRegion\" aria-live=\"polite\"></div>\n        </div>\n</div>\n";
},"useData":true});