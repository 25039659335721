import ItemView from '@glu/core/src/itemView';
import template from './checkboxCell.hbs';

export default ItemView.extend({
    template,

    ui: {
        $checkbox: '[data-hook="getCheckbox"]',
    },

    events: {
        'click [data-hook="getCheckbox"]': 'checkboxClicked',
    },

    templateHelpers() {
        const self = this;

        return {
            cid: this.cid,
            checked() {
                const val = self.dataValue();
                if (val === '0') {
                    return '';
                }
                if (val === '1') {
                    return 'checked';
                }
                return '';
            },

            columnClass() {
                return self.dataName();
            },
        };
    },

    checkboxClicked() {
        this.model.set(
            this.dataName(),
            this.ui.$checkbox.prop('checked') ? '1' : '0',
            {
                silent: true,
            },
        );
    },

    dataValue() {
        return this.model.get(this.dataName());
    },

    dataName() {
        return this.options.column.get('field');
    },
});
