import LookupCollection from 'common/dynamicPages/collections/lookup';

export default LookupCollection.extend({
    createSearchCriteria(queryTerm) {
        if (Number.isNaN(Number(queryTerm))) {
            // search against bank name
            return [{
                fieldName: 'Name',
                operator: 'CONTAINS',
                fieldValue: [this.queryTerm],
                dataType: 'text',
            }];
        }
        // Search against aba code
        return [{
            fieldName: 'Code',
            operator: 'CONTAINS',
            fieldValue: [this.queryTerm],
            dataType: 'text',
        }];
    },
});
