import http from '@glu/core/src/http';
import services from 'services';
import Model from '@glu/core/src/model';
import transform from 'common/util/transform';
import util from '@glu/core/src/util';

export default Model.extend({
    readRequestPayload: {
        queryCriteria: {
            action: {
                actionMode: 'INSERT',
                functionCode: 'INST',
                productCode: 'RTGS',
                typeCode: 'FEDWIRE',
            },

            allowDuplicates: false,
            entryClass: '',
            fieldName: 'ACCOUNTFILTER',
            subTypeCode: '*',
        },
    },

    serviceActionPayload: {
        REQUESTTYPE: 'fieldValidation',
        FIELDNAME: 'VALUE_DATE',
        VALUE_DATE: '',
        TRAN_DATE: '',
        POST_DATE: '',
        DEBIT_BANK_CODE: '',
        DEBIT_ACCOUNT_NUMBER: '',
        DEBIT_COUNTRY: '',
        DEBIT_CURRENCY: '',
        BENE_BANK_CODE: '',
        CREDIT_AMOUNT: '',
        DEBIT_AMOUNT: '',
        CREDIT_CURRENCY: '',
        EXCHANGE_RATE: '',
        EXCHANGE_RATE_CONTRACTID: '',
        BENE_BANK_ID: '',
        ONUS_INDICATOR: '',
        RELEASELEADTIME: '',
        ENTRYMETHOD: '',
        CREATEDFROM: '',
        ACTION: 'INSERT',
        PRODUCT: 'RTGS',
        FUNCTION: 'INST',
        TYPE: 'FEDWIRE',
        BENE_BANK_COUNTRY: 'US',
        ENTERED_AMOUNT_FLAG: 'C',
        BENE_BANK_TYPE: 'ABA',
        MARKETCONVENTION: 'D',
        FXRATETYPE: '0',
    },

    sync(method, model, options) {
        if (method === 'read') {
            return http.post(services.generateUrl('tableMaintenance/getQueryResults'), this.readRequestPayload, options.success, options.error);
        }
        if (method === 'create') {
            return http.post(services.generateUrl('payment/fedwire/multi/add'), this.convertModelAttributesToServerJSON(), options.success, options.error);
        }
        if (method === 'date') {
            return http.post(services.generateUrl('date/list'), this.getDateListPayload(model), options.success, options.error);
        }
        if (method === 'serviceAction') {
            return http.post(services.generateUrl('inquiry/processServiceAction'), this.getServiceNameValuePair(), options.success, options.error);
        }
        // TODO: Should we error for bad method?
        return undefined;
    },

    getServiceNameValuePair() {
        return {
            item: transform.hashToPairs(this.serviceActionPayload),
        };
    },

    /**
     * @method toFlexDataFormat
     * convert the queryRows into a select dropdown format
     */
    toDropdownFormat() {
        return this.get('queryResponse').QueryData.queryRows.map(row => ({
            value: row.name,
            label: row.label,
        }));
    },

    getDates(options) {
        return this.sync('date', options.model, options);
    },

    processServiceAction(options) {
        return this.sync('serviceAction', this, options);
    },

    /**
     * @param {Model} model
     * @returns {{
     *  debitBankCountry,
     *  creditBankCountry: string,
     *  beneBankType: string,
     *  tranDate,
     *  subType: string,
     *  debitBank,
     *  creditCurrency,
     *  valueDate,
     *  userSetValueDate: undefined,
     *  beneBankId,
     *  paymentType: string}}
     */
    getDateListPayload(model) {
        return {
            beneBankId: model.get('BENE_BANK_ID'),
            debitBank: model.get('DEBIT_BANK_CODE'),
            debitBankCountry: model.get('DEBIT_COUNTRY'),
            creditCurrency: model.get('CREDIT_CURRENCY'),
            tranDate: model.get('TRAN_DATE'),
            valueDate: model.get('VALUE_DATE'),
            userSetValueDate: undefined,
            beneBankType: 'ABA',
            creditBankCountry: 'US',
            paymentType: 'FEDWIRE',
            subType: '*',
        };
    },

    /**
     * @method serviceActionPayload
     * @returns object
     * @param {object} options
     */
    updateServicePayload(options) {
        this.serviceActionPayload = util.extend(this.serviceActionPayload, options);
    },

    /**
     * @method convertModelAttributesToServerJSON
     * @returns {{items: *}}
     */
    convertModelAttributesToServerJSON() {
        return {
            items: this.get('items'),
            saveWithWarning: this.get('saveWithWarning'),
        };
    },
});
