import EntryView from 'common/dynamicPages/views/workflow/entry';
import DataAPI from 'common/dynamicPages/api/data';
import PageApi from 'common/dynamicPages/api/view';
import gridApi from 'common/dynamicPages/api/grid';
import util from '@glu/core/src/util';

const PaymentManagementList = EntryView.extend({
    initialize(options, ...rest) {
        EntryView.prototype.initialize.apply(this, [options, ...rest]);
        this.contextDef.createdFrom = '1';
        // Despite the file name, this is used whenever we are making a payment.
        this.contextDef.copySource = options.functionCode === 'BATCH' ? 'payment' : 'template';
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.contextDef.serviceName = this.options.serviceName;
            this.contextDef.subType = this.options.subType;
            this.contextDef.createdFrom = '1';

            /*
             * RTP widget doesn't have a template list view, so display confirmation
             * at payment list view
             */
            if (this.contextDef.fromRTPWorkspace) {
                this.contextDef.keyOverride = 'payment_listView_corp';
            }

            this.pageView = PageApi.page.get({
                context: this.contextDef,
                model: this.paymentModel,
                hideButtons: true,
                gridApi,
                overrideChildFunctionCode: this.targetFunction,
            });
            this.listenTo(this.pageView, 'loaded', this.setPageViewListeners);
            this.pageContent.show(this.pageView);
            this.displaySummaryTotal();
        } else {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        const self = this;
        const keyListGlobal = ['TNUM', 'UPDATECOUNT__', 'FROMTEMPLATE', 'TARGETFUNCTION'];

        const model = DataAPI.model.generateFromKeyList({
            context: {
                serviceName: this.contextDef.templateServiceName,
            },

            keyList: keyListGlobal,
        });

        this.targetFunction = this.contextDef.functionCode;
        if (this.contextDef.functionCode === 'INST') {
            this.targetFunction = 'TMPL';
        } else if (this.contextDef.functionCode === 'BATCH') {
            this.targetFunction = 'BHTMPL';
        } else if (this.contextDef.functionCode === 'REQUEST') {
            this.targetFunction = 'REQTMPL';
        }
        model.set('TNUM', this.contextDef.tnum);
        model.set('UPDATECOUNT__', this.contextDef.updateCount);
        model.set('FROMTEMPLATE', '1');
        model.set('TARGETFUNCTION', this.targetFunction);
        model.set('FUNCTION', this.contextDef.functionCode);
        model.fetch({
            success(m) {
                self.model = m;
                const modelPromise = DataAPI.model.generate({
                    context: {
                        serviceName: self.contextDef.serviceName,
                        entrymethod: '0',
                        functionCode: self.targetFunction,
                        subType: self.contextDef.subType,
                        createdFrom: self.contextDef.createdFrom,
                        copySource: self.contextDef.copySource,
                    },
                }, false);

                let origCompName;
                let origCompID;
                let compIdName;
                modelPromise.then((paymentModel) => {
                    const paymentModelEdit = paymentModel;
                    util.each(self.model.attributes, (value, key) => {
                        if (!util.isEmpty(self.model.get(key))) {
                            paymentModel.set(key, self.model.get(key));
                            if (key === 'CLIENTCOMP_GROUPNUM') {
                                paymentModel.set('ID', self.model.get(key));
                            }
                        }
                    });

                    if (self.model.get('PRODUCT') === 'USACH') {
                        origCompName = self.model.get('ORIGCOMPNAME');
                        origCompID = self.model.get('ORIGCOMPID');
                        compIdName = `${origCompID} / ${origCompName}`;
                        paymentModel.set('COMPIDNAME', compIdName);
                    }

                    if (self.model.get('PRODUCT') === 'USACH') {
                        paymentModel.set('TEMPLATECODE', '');
                        paymentModel.set('TEMPLATEDESCRIPTION', '');
                    } else {
                        paymentModel.set('TEMPLATE_CODE', '');
                        paymentModel.set('TEMPLATE_DESCRIPTION', '');
                    }

                    self.paymentModel = paymentModel;

                    // handle any locked fields setup
                    const lockedFields = paymentModel.get('LOCKED_FIELDS').split(',');
                    lockedFields.forEach((fieldName) => {
                        if (paymentModel.has(fieldName)) {
                            paymentModelEdit.fieldData[fieldName].locked = true;
                        }
                    });

                    self.setHasLoadedRequiredData(true);
                    self.render();
                });
            },
        });
    },
});
export default PaymentManagementList;
