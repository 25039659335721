var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "required";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"MANDATORY") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":12},"end":{"line":1,"column":44}}})) != null ? stack1 : "")
    + "\">\n    <label>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"SCREENNAME") || (depth0 != null ? lookupProperty(depth0,"SCREENNAME") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"SCREENNAME","hash":{},"data":data,"loc":{"start":{"line":2,"column":11},"end":{"line":2,"column":25}}}) : helper)))
    + "</label>\n</div>\n";
},"useData":true});