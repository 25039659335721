import Layout from '@glu/core/src/layout';
import formatter from 'system/utilities/format';
import Threshold from './threshold';
import thresholdPanelTmpl from './thresholdPanel.hbs';

export default Layout.extend({
    template: thresholdPanelTmpl,

    ui: {
        $deleteButton: '[data-hook="getRemoveThresholdButton"]',
        $sequenceCount: '[data-hook="getSequenceCount"]',
    },

    events: {
        'click @ui.$deleteButton': 'removeThreshold',
    },

    initialize(options) {
        this.threshold = new Threshold(options);
        const sequences = options.model.get('approverSequences');

        this.listenTo(sequences, 'add remove', this.updateSequenceCount.bind(this));
    },

    onRender() {
        this.thresholdRegion.show(this.threshold);
    },

    templateHelpers() {
        return {
            index: this.options.index,
            getAmount: formatter.formatNumber(this.options.model.get('amount')),
            approvalSequences: this.getApproverSequencesLength(),
            currency: this.options.currency,
        };
    },

    getApproverSequencesLength() {
        const approverSequences = this.model.get('approverSequences');
        return approverSequences ? approverSequences.length : 0;
    },

    updateSequenceCount() {
        this.ui.$sequenceCount[0].innerHTML = this.getApproverSequencesLength();
    },

    removeThreshold() {
        this.trigger('remove:threshold');
    },
});
