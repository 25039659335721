import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import dialog from '@glu/dialog';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import printExportUtil from 'common/util/printExportUtil';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import systemConfig from 'system/configuration';
import store from 'system/utilities/cache';
import template from './viewTransmissionProfile.hbs';
import TransmissionProfile from '../transmissionProfileModel';

export default Layout.extend(util.extend(
    {},
    printExportUtil,
    {
        template,
        loadingTemplate,

        initialize() {
            const tempModel = store.get('export_transmissionProfile-actionModel');
            this.model = new TransmissionProfile();
            this.model.fetch(tempModel.get('ID')).then(() => {
                this.setHasLoadedRequiredData(true);
                this.render();
            });
        },

        ui: {
            $pwToggle: '[data-hook="getPwToggle"]',
            $password: '[data-hook="getPassword"]',
        },

        events: {
            'click @ui.$pwToggle': 'togglePassword',
        },

        templateHelpers() {
            return {
                headingText: locale.get('GIR.View.Transmission.Profile'),
                isAdmin: systemConfig.isAdmin(),
            };
        },

        /**
         * Mask/unmask the password field
         * @param {Object} e - jquery click event
         */
        togglePassword(e) {
            this.ui.$password.attr('type', e.currentTarget.checked ? 'text' : 'password');
        },

        /**
         * Navigate to the list view
         */
        cancel() {
            const returnRoute = store.get('ScheduledExportsWidget:returnRoute');
            this.navigateTo(returnRoute || this.options.returnRoute);
        },

        /**
         * Show a custom PrintVieModal with the options specific to
         * Scheduled exports
         */
        print() {
            const options = this.getPrintOptions(this.model);
            dialog.custom(new PrintViewModal(options));
        },

        /**
         * Get the print options for the this view, from the model
         * @param {Model} model - model attached to the view
         * @returns {Object} - print options
         */
        getPrintOptions(model) {
            return {
                exportModel: {
                    expData: 'transaction',
                    actionData: {
                        productCode: 'GIR',
                    },
                    detailReportId: 60005,
                    searchFields: [{
                        fieldName: 'ID',
                        operator: 'IN',
                        fieldValue: [
                            model.get('identifier'),
                        ],
                        dataType: 'string',
                    }],
                },
            };
        },
    },
));
