import EntryView from 'app/administration/common/dynamicPages/views/workflow/entry';
import DataApi from 'common/dynamicPages/api/data';
import errorHandlers from 'system/error/handlers';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';

export default EntryView.extend({
    initialize() {
        const options = {
            menuCategory: 'REPORTING',
            serviceName: '/adminCM/cm/stopCancels',
            serviceFunc: null,
            businessType: null,
            context: 'PAY_CM_IV_LIST',
            mode: 'view',
        };

        EntryView.prototype.initialize.call(this, options);
    },

    loadRequiredData() {
        const self = this;

        if (this.mode === 'insert' || this.mode === 'import') {
            this.setHasLoadedRequiredData(true);
            this.render();
        } else {
            this.contextDef.serviceName = '/adminCM/cm/stopCancels';
            if (this.contextDef.actionData.typeCode) {
                if (this.contextDef.actionData.typeCode === 'STOP') {
                    this.localeKey = 'cminst.placeStop_';
                } else {
                    this.localeKey = 'cminst.cancelStop_';
                }
            }
            DataApi.model.generateModelFromModel(store.get(`${this.contextKey}-actionModel`))
                .then((newModelParam) => {
                    const newModel = newModelParam;
                    newModel.set('PRODUCTCODE', newModel.get('PRODUCT'));
                    newModel.set('TYPECODE', newModel.get('TYPE'));
                    newModel.set('SUBTYPE', '*');

                    newModel.key.PRODUCTCODE = 'true';
                    newModel.key.TYPECODE = 'true';
                    newModel.key.SUBTYPE = 'true';
                    newModel.fetch({
                        success() {
                            self.storedModel = newModel;
                            self.setHasLoadedRequiredData(true);
                            self.render();
                        },

                        error: util.bind(errorHandlers.loading, self),
                    });
                });
        }
    },
});
