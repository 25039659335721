import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import CollectionComboBoxView from 'common/collectionComboBox/views/base';
import CommentView from '../modals/comment';
import datePicker from '../datePicker';
import addRowTmpl from './addRow.hbs';

export default Layout.extend({
    template: addRowTmpl,
    tagName: 'tr',
    className: 'add-payment',
    binding: true,
    editMode: false,

    initialize(options) {
        if (options.model.editRow) {
            this.editMode = true;
            this.model = options.model.clone();
            this.originalModel = options.model;
        }

        this.fromAccounts = options.editOptions.fromAccounts;
        this.toAccounts = options.editOptions.toAccounts;
        this.blockedDates = options.editOptions.blockedDates;
        this.maxDate = options.editOptions.maxDate;
        this.cutoffTimes = options.editOptions.cutoffTimes;
        this.earliestDay = options.editOptions.earliestDay;
    },

    onRender() {
        const self = this;
        this.fromAccountComboBoxView = new CollectionComboBoxView({
            collection: this.fromAccounts,
            canEdit: false,
            noSelectionText: locale.get('smb.account.select2'),
        });

        this.fromAccountComboBoxView.on('select', (value) => {
            self.resetValidationMessages('[data-validate="fromAccountId"]');
            self.setAccount(value, 'fromAccount');
        });

        this.toAccountComboBoxView = new CollectionComboBoxView({
            collection: this.toAccounts,
            canEdit: false,
            noSelectionText: locale.get('smb.account.select2'),
        });

        this.toAccountComboBoxView.on('select', (value) => {
            self.resetValidationMessages('[data-validate="toAccountId"]');
            self.setAccount(value, 'toAccount');
        });

        this.fromAccountRegion.show(this.fromAccountComboBoxView);
        this.toAccountRegion.show(this.toAccountComboBoxView);

        this.ui.$paymentDateWrap.html(datePicker.create(
            null,
            this.blockedDates,
            this.cutoffTimes,
            this.maxDate,
            this.earliestDay,
        ));

        if (this.editMode) {
            this.ui.$paymentDateWrap.find('.input-date').val(this.model.get('paymentDate'));
        } else {
            this.ui.$paymentDateWrap.find('.input-date').val(datePicker.defaultDate());
        }

        this.updateCommentButtonText();
    },

    ui: {
        $fromAccount: '[data-hook="from-account"]',
        $toAccount: '[data-hook="to-account"]',
        $commentLink: '[data-hook="commentButton"]',
        $paymentDateWrap: '.payment-grid-header-input.payment-date',
        $saveButton: '[data-action="save"]',
        $cancelButton: '[data-action="delete"]',
        $amount: 'input#amount',
    },

    templateHelpers() {
        return {
            notEditMode: !this.editMode,
            modelId: this.model.cid,
        };
    },

    // TODO don't hard code text
    onShow() {
        if (this.editMode) {
            this.setAccount(this.model);
            this.fromAccountComboBoxView.selectList().comboBox(
                'data',
                {
                    text: `${this.model.get('fromAccountId')}-${this.model.get('fromAccountName')}`,
                },
            );

            this.toAccountComboBoxView.selectList().comboBox(
                'data',
                {
                    text: `${this.model.get('toAccountId')}-${this.model.get('toAccountName')}`,
                },
            );

            this.ui.$saveButton.find('span').removeClass('icon-plus').addClass('icon-tick');
            this.ui.$cancelButton.attr({
                title: locale.get('smb.cancel'),
            });
        }
    },

    // add mode (add row) function; fired in baseAddGrid
    add(e) {
        const self = this;

        if (e) {
            e.preventDefault();
        }

        this.model.set('paymentDate', datePicker.setModelDate());

        const isValid = this.model.isValid();

        // store off a copy
        this.originalModel = this.model;
        if (isValid) {
            this.trigger('save', this.model, (result) => {
                if (result) {
                    self.clear();
                }
            });
        }
    },

    // add mode (add row) function; fired in baseAddGrid
    clear() {
        const self = this;
        this.trigger('clear', this.model, (result) => {
            if (result) {
                self.resetElements();
            }
        });
    },

    // edit mode function
    save(e) {
        if (e) {
            e.preventDefault();
        }

        this.model.set('paymentDate', datePicker.setModelDate());

        const isValid = this.model.isValid();

        if (isValid) {
            this.copyPropertiesDuringSave();
            this.model = this.originalModel;
            this.trigger('save');
            this.appBus.trigger('sbPayments:paymentListUpdated');
        }
    },

    // edit mode function
    cancel(e) {
        if (e) {
            e.preventDefault();
        }

        this.model = this.originalModel;
        this.trigger('save');
        this.appBus.trigger('sbPayments:paymentListUpdated');
    },

    resetElements() {
        this.fromAccountComboBoxView.clearSelection();
        this.toAccountComboBoxView.clearSelection();
        this.ui.$paymentDateWrap.find('.input-date').val(datePicker.defaultDate());
        this.resetValidationMessages(null);
        this.updateCommentButtonText();
    },

    resetValidationMessages(message) {
        if (!message) {
            util.each(this.$el.find('[class="help-block"],.date-error'), (el) => {
                this.$(el).empty();
            });
        } else {
            this.$el.find(message).empty();
        }
    },

    copyPropertiesDuringSave() {
        this.originalModel.set(this.model.attributes);
    },

    updateCommentButtonText() {
        this.ui.$commentLink.val(this.model.get('comment').isEmpty() ? locale.get('sbPayments.commentAdd') : locale.get('sbPayments.commentEdit'));
    },

    popComment() {
        const self = this;
        const commentModal = new CommentView({
            model: this.model.get('comment'),
        });

        commentModal.on('save', (model) => {
            self.model.set('comment', model);
            self.updateCommentButtonText();
        }, this);

        dialog.custom(commentModal);
    },

    setAccount(selectedVal, originAccount) {
        let selectedObj;
        const self = this;
        if (selectedVal) {
            selectedObj = selectedVal.attributes.text.split('-');
            if (originAccount === 'fromAccount') {
                this.model.set('fromAccountId', selectedObj[0]);
                this.model.set('fromAccountName', selectedObj[1]);
                util.each(selectedVal.attributes.mapDataList, (mapData) => {
                    if (mapData.toField === 'AccountFilter') {
                        self.model.set('ACCOUNTFILTER', mapData.value);
                    }
                    if (mapData.toField === 'Debit_Bank_Code') {
                        self.model.set('DEBIT_BANK_CODE', mapData.value);
                    }
                });
            } else if (originAccount === 'toAccount') {
                this.model.set('toAccountId', selectedObj[0]);
                this.model.set('toAccountName', selectedObj[1]);
                util.each(selectedVal.attributes.mapDataList, (mapData) => {
                    if (mapData.toField === 'Credit_Currency') {
                        self.model.set('CREDIT_CURRENCY', mapData.value);
                    }
                    if (mapData.toField === 'Bene_Name') {
                        self.model.set('BENE_NAME', mapData.value);
                    }
                    if (mapData.toField === 'Bene_Bank_Code') {
                        self.model.set('BENE_BANK_CODE', mapData.value);
                    }
                });
            }
        } else if (originAccount === 'fromAccount') {
            this.model.set('fromAccountId', '');
            this.model.set('fromAccountName', '');
        } else if (originAccount === 'toAccount') {
            this.model.set('toAccountId', '');
            this.model.set('toAccountName', '');
        }
    },
});
