import Layout from '@glu/core/src/layout';
import workspaceHelper from 'common/workspaces/api/helper';
import Collection from '@glu/core/src/collection';
import alert from '@glu/alerts';
import Dialog from '@glu/dialog';
import CollectionView from '@glu/core/src/collectionView';
import RapidWireItemView from 'app/payments/views/quickEntry/rapidWireItemView';
import transform from 'common/util/transform';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import WarningDialog from 'common/dynamicPages/views/warningDialog';
import store from 'system/utilities/cache';
import loans from 'app/loans/api/common';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import moment from 'moment';
import DuplicateDialog from 'common/dynamicPages/views/duplicateDialog';
import Formatter from 'system/utilities/format';
import configuration from 'system/configuration';
import constants from 'common/dynamicPages/api/constants';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import services from 'services';
import http from '@glu/core/src/http';
import Model from 'app/payments/models/rapidWireEntry';
import RapidWireCollectionModel from 'app/payments/models/rapidWireItem';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import unescapeData from 'common/util/unescapeData';
import rapidWireEntryTmpl from './rapidWireEntry.hbs';

const isClientDeeplink = configuration.isPortal() && configuration.isClient();

const RapidWireEntry = Layout.extend({
    template: rapidWireEntryTmpl,
    loadingTemplate: loadingPageTmpl,

    ui: {
        $submit: '[data-hook="getSubmit"]',
        $cancel: '[data-hook="getCancel"]',
        $add: '[data-hook="getAddEntryField"]',
        $count: '[data-hook="getAddEntryFieldCount"]',
        $summaryAmount: '[data-hook="getSummaryAmount"]',
        $summaryPayments: '[data-hook="getSummaryItems"]',
        $amount: '[name="CREDIT_AMOUNT"]',
        $alert: '.alert-region',
    },

    events: {
        'click @ui.$submit': 'save',
        'click @ui.$cancel': 'cancel',
        'click @ui.$add': 'addWirePaymentFromDOM',
    },

    initialize() {
        this.model = new Model();
        this.contextKey = 'payment_listView_corp';
        this.dispAcctBalance = serverConfigParams.get('DisplayAccountBalanceOnPaymentsScreens') === 'true';
        this.accountHelperText = [];
        this.listenTo(this.model, 'modelAction:saveWithWarning', this.saveWithWarning);
        this.listenTo(this.model, 'modelAction:cancelWarning', this.cancelWarning);
        store.unset(`${this.contextKey}-contextOverride`);
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.setQuickEntryCollectionView();
            this.renderQuickEntryCollectionView();
            this.hideRemoveBtnOnFirstChildView();
            if (isClientDeeplink && !store.has('current-workspace-route')) {
                this.ui.$cancel.hide();
            }
        } else {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        this.model.fetch({
            success: (collection, response) => {
                unescapeData.unescapeQueryResponseData(response);
                const accounts = response.queryResponse.QueryData.queryRows;
                this.origAccounts = [];
                util.each(
                    accounts,
                    newItem => this.origAccounts.push(transform.pairsToHash(newItem.mapDataList, 'toField', 'value')),
                );
                this.fetchBalancesForTheseAccounts(this.origAccounts, 'DEBIT_ACCOUNT_NUMBER')
                    .then((balances) => {
                        // setup helper text
                        this.mergeBalancesOnAccounts(this.model.get('queryResponse').QueryData.queryRows, balances);
                        this.setHasLoadedRequiredData(true);
                        this.render();
                    });
            },
        });
    },

    /**
     * @description retrieves the account balances for all the accounts
     * @param accounts
     * @param fieldName
     */
    fetchBalancesForTheseAccounts(accounts, fieldName) {
        // post to the service and return array of balances
        const serviceUrl = services.currencyBalances;

        /*
         * if we are not configured to show account balances then just return empty
         * entries for each combo entry so that nothing is shown.
         */
        if (!this.dispAcctBalance) {
            const emptyHelpText = accounts.map(() => '');
            return Promise.resolve(emptyHelpText);
        }

        /**
         * Each item in the request must contain:
         * productCode, functionCode, typeCode, bankCode, accountNumber, currency
         *
         */
        const balanceRequest = accounts.map(item => ({
            productCode: 'RTGS',
            functionCode: 'INST',
            typeCode: 'FEDWIRE',
            bankCode: item.DEBIT_BANK_CODE,
            accountNumber: item.ORIGINATOR_ID,
            currency: item.CREDIT_CURRENCY,
        }));
        const postData = {
            requests: balanceRequest,
        };

        return new Promise((resolve, reject) => {
            http.post(serviceUrl, postData, (data) => {
                if (data && data.responses) {
                    resolve(data.responses);
                } else {
                    resolve(accounts);
                }
            }, () => {
                reject(locale.get('common.combo.data.error', fieldName));
            });
        });
    },

    /**
     * @description merges the account balances into the label property for each account
     * @param accounts
     * @param balances
     */
    mergeBalancesOnAccounts(accounts, balances) {
        return accounts.map((itemParam, index) => {
            const item = itemParam;
            let label;

            if (balances && balances[index]) {
                if (balances[index].status === 'success') {
                    label = `: ${balances[index].balance} ${locale.get('common.available')}`;
                }
            }
            if (label) {
                item.label += label;
            }
            return item;
        });
    },

    /**
     * @method setQuickEntryCollectionView
     */
    setQuickEntryCollectionView() {
        const rapidWireCollection = new Collection(
            {},
            {
                model: RapidWireCollectionModel,
            },
        );
        this.rapidWireCollectionView = new CollectionView({
            collection: rapidWireCollection,
            itemView: RapidWireItemView,

            itemViewOptions: {
                originatorModel: this.model,
            },
        });
    },

    /**
     * @method renderQuickEntryCollectionView
     */
    renderQuickEntryCollectionView() {
        this.rapidWireCollectionView.on('itemview:updatePaymentTotal', () => this.updatePaymentTotal());
        this.rapidWireCollectionView.on('itemview:remove:entry', (childView) => {
            this.rapidWireCollectionView.collection.remove(childView.model);
            this.updatePaymentTotal();
            this.hideRemoveBtnOnFirstChildView();
        });
        this.rapidEntryRegion.show(this.rapidWireCollectionView);
    },

    /**
     * @method hideRemoveBtnOnFirstChildView
     */
    hideRemoveBtnOnFirstChildView() {
        const { children } = this.rapidWireCollectionView;
        const firstChildView = children.findByIndex(0);
        if (children.length === 1) {
            firstChildView.trigger('hideRemoveBtn');
        }
    },

    /**
     * @method hideRemoveBtnOnFirstChildView
     */
    showRemoveBtnOnFirstChildView() {
        const { children } = this.rapidWireCollectionView;
        const firstChildView = children.findByIndex(0);
        if (children.length > 1) {
            firstChildView.trigger('showRemoveBtn');
        }
    },

    /**
     * @method addWirePaymentFromDOM
     * a helper method to get the count from the dom which then calls addWirePayments
     * to add X models to collection
     */
    addWirePaymentFromDOM() {
        this.addWirePayments(this.ui.$count.val());
    },

    /**
     * @method addWirePayments
     * @param {number} count
     * Add model to collection based on counter field
     */
    addWirePayments(count) {
        const newRecords = new Array(+count).fill(0).map(() => new RapidWireCollectionModel());
        this.rapidWireCollectionView.collection.add(newRecords);
        this.showRemoveBtnOnFirstChildView();
    },

    /**
     *
     * Accepts the response from the Rest API service and joins each string in
     * the message array
     * Return the alert view.
     *
     * @param model
     * @returns {view}
     */
    getAlertView(model) {
        const { error } = model;
        const cnfm = error ? error.confirms : model.confirms;
        const message = error ? error.message[0] : model.message[0];
        const confirms = new Confirms({
            confirms: cnfm,
        });

        return alert.danger(
            message,
            {
                details: confirms,
                canDismiss: true,
                icon: 'warning',
            },
        );
    },

    /**
     * @method submitBtnShouldBeBusy
     * @param flag
     * A helper function to enable / disable the submit button
     */
    submitBtnShouldBeBusy(flag) {
        this.ui.$submit.attr('disabled', flag);
    },

    /**
     * @method updatePaymentTotal
     * @description check all amount elements in the dom, pass to the helper
     * method, then update the summary amount
     */
    updatePaymentTotal() {
        const total = loans.getTotal(this.$('[name="CREDIT_AMOUNT"]'));
        const applicableModels = this.rapidWireCollectionView.collection.models.filter(model => model.get('CREDIT_AMOUNT') !== undefined).length;
        this.ui.$summaryPayments.text(`(${applicableModels})`);
        this.ui.$summaryAmount.text(total.formatAs.number);
    },

    cancel() {
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    /**
     * @method showAlertMessage
     * @param response
     * @description render an alert message based on the response
     */
    showAlertMessage(response) {
        const alertView = this.getAlertView(response);

        this.ui.$alert.hide();

        this.$('html, body').animate({
            scrollTop: 0,
        }, 100);

        this.alertRegion.show(alertView);
        this.ui.$alert.fadeIn(200, () => alertView.$el.focus());
    },

    /**
     * Callback method handling ok on warning
     */
    saveWithWarning() {
        this.model.set({
            saveWithWarning: 'true',
        });
        this.save();
    },

    // Callback method handling cancel on warning
    cancelWarning() {
        const $button = this.$('[data-hook="submit-button"]');
        this.toggleButtonEnabled($button, true);
    },

    /**
     * appends success or failure messages to alerts view
     * @param {Object[]} rows - success/failure confirm results
     * @param {boolean} success - indicates success/failure
     * @param {number} count
     */
    addAlertView(rows, success, count) {
        if (count < 1) {
            return;
        }

        let message;
        let alertMethod;
        const confirmResponse = {
            confirms: {
                confirmResults: [],
            },
        };

        if (success) {
            message = `${count + (count > 1 ? ` ${locale.get('payment.payment.plural')}` : ` ${locale.get('payment.payment')}`)} ${locale.get('payment.processed')}`;
            alertMethod = alert.success;
        } else {
            message = `${count} ${count > 1 ? locale.get('payment.payment.plural') : locale.get('payment.payment')} ${locale.get('payment.failed')}`;
            alertMethod = alert.danger;
        }

        util.each(rows, (row) => {
            const items = [];

            /*
             * Skip displaying a result if it is an error that
             * comes back with no type or message. If MFA is configured and
             * there are duplicates present, successful payments will come back
             * from the server like this until the duplicate warning has been accepted.
             */
            if (!row.result && row.messages.length === 0 && !row.resultType) {
                return;
            }

            util.each(row.confirmData[0].item, (field) => {
                items.push({
                    name: field.name,
                    value: field.value,
                });
            });

            confirmResponse.confirms.confirmResults.push({
                confirmData: [{
                    item: items,
                }],

                messages: row.messages,
            });
        }, this);

        if (confirmResponse.confirms && confirmResponse.confirms.confirmResults.length === 0) {
            return;
        }

        const alertView = alertMethod(
            message,
            {
                details: new Confirms({
                    confirms: confirmResponse.confirms,
                }),

                canDismiss: !!confirmResponse,
                animate: true,
            },
        );

        this.ui.$alert.append(alertView.render().el);
    },

    isSuccessItem(confirmData, obj) {
        return `${obj.get('DEBIT_ACCOUNT_TITLE')} ${obj.get('DEBIT_ACCOUNT_NUMBER')}` === confirmData[locale.get('RTGS.From')]
            && `${obj.get('BENE_NAME')} ${obj.get('BENE_ACCOUNT')}` === confirmData[locale.get('RTGS.To')]
            && parseFloat(obj.get('CREDIT_AMOUNT')) === Formatter.unformatNumber(confirmData[locale.get('RTGS.Amount:')])
            && moment(obj.get('VALUE_DATE'), userInfo.getDateFormat()).isSame(confirmData[locale.get('RTGS.Value Date')]);
    },

    /**
     * @method clearSuccessfulPayments -  clears payments successfully submitted.
     * @param {object} rows - success payments results
     */
    clearSuccessfulPayments(rows) {
        util.each(rows, (row) => {
            const item = transform.pairsToHash(row.confirmData[0].item);
            const matchedModel = this.rapidWireCollectionView.collection
                .find(obj => this.isSuccessItem(item, obj));
            if (matchedModel) {
                this.rapidWireCollectionView.collection.remove(matchedModel);
            }
        }, this);
    },

    /**
     * @method updateCollectionFromFailedResults -  Update values in the collection
     * based on a failed server response
     * @param {object} response - response from the server containing confirmResults
     */
    updateCollectionFromFailedResults(response) {
        util.each(response.confirms.confirmResults, function (confirmResult) {
            this.rapidWireCollectionView.collection.each((obj) => {
                const confirmData = transform.pairsToHash(confirmResult.confirmData[0].item);
                const sameFrom = `${obj.get('DEBIT_ACCOUNT_TITLE')} ${obj.get('DEBIT_ACCOUNT_NUMBER')}` === `${confirmData.DEBIT_ACCOUNT_TITLE} ${confirmData.DEBIT_ACCOUNT_NUMBER}`;
                const sameTo = `${obj.get('BENE_NAME')} ${obj.get('BENE_ACCOUNT')}` === `${confirmData.BENE_NAME} ${confirmData.BENE_ACCOUNT}`;
                const sameAmount = parseFloat(obj.get('CREDIT_AMOUNT'), 10) === Formatter.unformatNumber(confirmData.CREDIT_AMOUNT);
                const sameDate = moment(obj.get('VALUE_DATE'), userInfo.getDateFormat()).isSame(confirmData.VALUE_DATE);

                /*
                 * Check if all the values from the server response match a record in the
                 * collection
                 */
                if (sameFrom && sameTo && sameAmount && sameDate) {
                    /*
                     * If we are dealing with a duplicate result then update the collection to
                     * indicate a possible duplicate. Needed for the duplicate warning modal.
                     */
                    if (confirmResult.resultType === 'DUPLICATEWARNING') {
                        obj.set('POSSIBLEDUPLICATE', true);
                    }
                }
            }, this);
        }, this);
    },

    /**
     * @method processResponse -  process success and/or error message by calling
     * addAlertView
     * @param {object} result - response confirm results
     * @param {int} numberOfDuplicates - number of responses that are duplicate warnings
     */
    processResponse(result, numberOfDuplicates) {
        const totalFail = result.totalFail - numberOfDuplicates;
        this.ui.$alert.empty();
        this.addAlertView(
            result.confirmResults.filter(confirmResult => confirmResult.result === false
                && !(confirmResult.resultType && confirmResult.resultType === 'DUPLICATEWARNING')),
            false,
            totalFail,
        );
        this.addAlertView(
            result.confirmResults.filter(confirmResult => confirmResult.result === true),
            true,
            result.totalSuccess,
        );
        this.clearSuccessfulPayments(result.confirmResults
            .filter(confirmResult => confirmResult.result === true));
    },

    save() {
        const rapidWireModels = this.rapidWireCollectionView.collection.models;
        let invalid = false;
        util.each(rapidWireModels, (model) => {
            if (!model.isValid()) {
                this.model.trigger('invalid');
                invalid = true;
            }
        });
        if (invalid) {
            return;
        }
        const models = this.rapidWireCollectionView.collection.toJSON();
        const items = models.map(model => ({
            item: transform.hashToPairs(model),
        }));
        const self = this;
        this.submitBtnShouldBeBusy(true);
        this.model.save({
            items,
        }, {
            success: (model, confirmResponse) => {
                if (isClientDeeplink && !store.has('current-workspace-route')) {
                    this.processResponse(confirmResponse.confirms, 0);
                    this.addWirePayments(1);
                    this.submitBtnShouldBeBusy(false);
                } else {
                    store.set(`${this.contextKey}-alertMessage`, 'INSERT');
                    store.set(`${this.contextKey}-confirms`, confirmResponse);
                    workspaceHelper.returnToCurrentWorkspace(this);
                }
            },

            error: (model, xhr) => {
                const response = xhr.responseJSON;
                if (response.resultType === 'WARNING') {
                    /*
                     * Clear out any successful submits and display an alert view for
                     * any non-warning results
                     */
                    this.processResponse(response.confirms, response.confirms.confirmResults.filter(confirmResults => confirmResults.resultType === 'DUPLICATEWARNING' || confirmResults.resultType === 'WARNING').length);
                    if (response.errorCode && response.errorCode
                            === constants.DUPLICATE_ERROR_CODE) {
                        /*
                         * Get all responses that are duplicates so they can be sent to the
                         * duplicate warning dialog
                         */
                        response.confirms.confirmResults = response.confirms.confirmResults.filter(confirmResults => confirmResults.resultType === 'DUPLICATEWARNING');

                        // Update the collection to mark duplicates based on the server response
                        this.updateCollectionFromFailedResults(response);

                        const duplicateDialog = new DuplicateDialog({
                            resp: response,
                            modelsArray: self.rapidWireCollectionView.collection,
                            methodName: 'INSERT',
                            isMultiAdd: true,
                        });
                        duplicateDialog.once('saveMultiAddDuplicates', this.save, this);
                        Dialog.custom(duplicateDialog);
                    } else {
                        Dialog.custom(new WarningDialog({
                            model: this.model,
                            methodName: 'SAVE',
                            confirms: response.confirms,
                        }));
                    }
                } else {
                    this.processResponse(response.confirms, 0);
                }
                this.submitBtnShouldBeBusy(false);
            },
        });
    },
});

// Registers this view as a widget.
workspaceHelper.publishedWidgets.add({
    id: 'RAPID_WIRE_ENTRY',
    view: RapidWireEntry,
    options: {},
});

export default RapidWireEntry;
