import util from '@glu/core/src/util';
import services from 'services';
import BaseRDCCollection from './baseRDCCollection';

export default BaseRDCCollection.extend({

    /**
     * Get the service URL for this collection
     * @returns {string}
     */
    getServiceURL() {
        return services.rdcLocationList;
    },

    /**
     * Remap all of the locations in the response and unescape the data
     * @param {object} jsonData
     * @returns {Array}
     */
    parse(jsonData) {
        return BaseRDCCollection.prototype.parse.call(this, jsonData)
            .map(this.unescapeLocation);
    },

    /**
     * Unescape all the attributes of the location
     * @param {object} location
     * @returns {object}
     */
    unescapeLocation(location) {
        return Object.keys(location).reduce((accum, key) => ({
            ...accum,
            [key]: util.unescape(location[key]),
        }), {});
    },

    /**
     * Get the data for this request
     * @returns {Object} data
     */
    getRequestData() {
        return {
            startRow: 1,
            dataOnly: 0,
        };
    },
});
