var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item is-active\"\n                    data-hook=\"getNotificationItem\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link btn btn-link\"\n                       data-step=\"notification\"\n                       tabindex=\"0\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"header.banner",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":36},"end":{"line":15,"column":62}}}))
    + "\n                    </a>\n                </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item\"\n                    data-hook=\"getTemplateItem\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link btn btn-link\"\n                       data-step=\"template\"\n                       tabindex=\"0\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"ACH.Template",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":36},"end":{"line":25,"column":61}}}))
    + "\n                    </a>\n                </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item\"\n                    data-hook=\"getArchiveItem\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link btn btn-link\"\n                       data-step=\"archive\"\n                       tabindex=\"0\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"RTGS.rpt.archived",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":35,"column":36},"end":{"line":35,"column":66}}}))
    + "\n                    </a>\n                </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"common.bannerNotifications",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":70}}}))
    + "</h1>\n</div>\n\n<div class=\"section section-container\" role=\"navigation\">\n    <div class=\"section-body\">\n        <ul id=\"banner-notif-tablist\" class=\"NavTabs nav nav-tabs\" role=\"tablist\"\n            data-hook=\"getNavTabs\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasNotificationEntitlements") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":18,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasTemplateEntitlements") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":28,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasArchiveEntitlements") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":12},"end":{"line":38,"column":19}}})) != null ? stack1 : "")
    + "        </ul>\n        <div class=\"gridRegion\" data-region=\"gridRegion\"></div>\n    </div>\n</div>\n";
},"useData":true});