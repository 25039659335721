import util from 'underscore';
import moment from 'moment';
import number from 'numeral';
import Format from '@glu/core/src/templateHelpers';

import userInfo from '../../../../etc/userInfo';
import serverConfigParams from '../../../../system/webseries/models/configurationParameters';
import Decimals from '../../api/decimals';

/*
 * TODO - React Templates
 * import { ReadOnlyAsString as readOnlyTmpl } from './componentTemplates/ReadOnly';
 */

// Handlebars Templates
import readOnlyTmpl from './componentTemplates/readOnly.hbs';
import readOnlyComboboxTmpl from './componentTemplates/readOnlyCombobox.hbs';
import uiWidgetTmpl from './componentTemplates/uiWidget.hbs';
import gridTmpl from './componentTemplates/gridHolder.hbs';

import {
    callTemplate,
    camelize,
} from './mdfUtil';

const readOnlyFieldHelpers = {
    // View Calendar Helper
    /**
     * {Object} view
     * {Object} jsonFieldParam
     * @returns {String}
     */
    CALENDAR(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        const format = userInfo.getDateFormat();
        jsonField.value = view.model.get(jsonField.name);
        jsonField.value = Format.date(jsonField.value, format);
        return !util.isEmpty(jsonField.value) ? readOnlyTmpl(jsonField) : '';
    },

    // View Calendar Helper
    /**
     * {Object} view
     * {Object} jsonFieldParam
     * @returns {String}
     */
    CALENDAR_ENABLEALL(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.value = view.model.get(jsonField.name);
        return !util.isEmpty(jsonField.value) ? readOnlyTmpl(jsonField) : '';
    },

    // View Checkbox Helper
    /**
     * {Object} view
     * {Object} jsonFieldParam
     * @returns {String}
     */
    CHECKBOX(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        if (!util.isEmpty(view.model.get(jsonField.name))) {
            jsonField.value = jsonField.fieldLabel;
            jsonField.fieldLabel = '';
        }

        return !util.isEmpty(jsonField.value) ? readOnlyTmpl(jsonField) : '';
    },

    // View Combobox Helper
    /**
     * {Object} view
     * {Object} jsonFieldParam
     * @returns {String}
     */
    COMBOBOX(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        const clist = view.comboCollections[jsonField.name];
        const cvalue = view.model.get(jsonField.name);

        if (!util.isEmpty(cvalue)) {
            for (let j = 0; j < clist.length; j += 1) {
                if (clist[j].name === cvalue) {
                    jsonField.value = clist[j].label;
                    break;
                }
            }
        } else {
            jsonField.blockClass += ' hidden ';
        }

        return readOnlyComboboxTmpl(jsonField);
    },

    // Default ComboSelect Helper
    /**
     * {Object} view
     * {Object} jsonFieldParam
     * @returns {String}
     */
    COMBOSELECT(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        const clist = jsonField.choices;
        const cvalue = view.model.get(jsonField.name);
        if (!util.isEmpty(cvalue)) {
            for (let j = 0; j < clist.length; j += 1) {
                if (clist[j].name === cvalue) {
                    jsonField.value = clist[j].value;
                    break;
                }
            }
        }
        return !util.isEmpty(jsonField.value) ? readOnlyTmpl(jsonField) : '';
    },

    /**
     * {Object} view
     * {Object} jsonFieldParam
     * @returns {String}
     */
    CHILDGRID(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        let tmpl = '';
        if (jsonField.protected && view.state !== 'create') {
            tmpl += readOnlyFieldHelpers.TEXTAREA(view, jsonField);
            jsonField.blockClass = 'hidden';
        }
        // use comboTmpl, need name, fieldLable and list (collection)
        return tmpl + callTemplate(gridTmpl, jsonField);
    },

    // View Radio Helper
    /**
     * {Object} view
     * {Object} jsonFieldParam
     * @returns {String}
     */
    RADIO(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        const rlist = jsonField.choices;
        const rvalue = view.model.get(jsonField.name);

        if (!util.isEmpty(rvalue)) {
            for (let k = 0; k < rlist.length; k += 1) {
                if (rlist[k].value === rvalue) {
                    jsonField.value = rlist[k].name;
                    break;
                }
            }
        }

        return !util.isEmpty(jsonField.value) ? readOnlyTmpl(jsonField) : '';
    },

    // View Textarea Helper
    /**
     * {Object} view
     * {Object} jsonFieldParam
     * @returns {String}
     */
    TEXTAREA(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.value = view.model.get(jsonField.name);
        return !util.isEmpty(jsonField.value) ? readOnlyTmpl(jsonField) : '';
    },

    /**
     * {Object} view
     * {Object} jsonFieldParam
     * @returns {String}
     */
    TYPEAHEAD_HELPERTEXT(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.value = util.unescape(view.model.get(jsonField.name));

        if (util.isEmpty(jsonField.value)) {
            jsonField.blockClass += ' hidden ';
        }

        /*
         * CSS adds a semicolon after the label. Remove it from the actual value of
         * the label if present.
         */
        if (util.contains(jsonField.fieldLabel, ':')) {
            jsonField.fieldLabel = jsonField.fieldLabel.replace(':', '');
        }

        return readOnlyTmpl(jsonField);
    },

    /**
     * {Object} view
     * {Object} jsonFieldParam
     * @returns {String}
     */
    TYPEAHEAD_PREFERRED(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.value = util.unescape(view.model.get(jsonField.name));

        if (util.isEmpty(jsonField.value)) {
            jsonField.blockClass += ' hidden ';
        }

        /*
         * CSS adds a semicolon after the label. Remove it from the actual value of
         * the label if present.
         */
        if (util.contains(jsonField.fieldLabel, ':')) {
            jsonField.fieldLabel = jsonField.fieldLabel.replace(':', '');
        }

        return readOnlyTmpl(jsonField);
    },

    // View Textbox Helper
    /**
     * {Object} view
     * {Object} jsonFieldParam
     * @returns {String}
     */
    TEXTBOX(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.value = util.unescape(view.model.get(jsonField.name));

        if ((jsonField.fieldType === 'AMOUNT' || jsonField.fieldType === 'PAMOUNT') && jsonField.relatedProperty) {
            jsonField.relatedValue = view.model.get(jsonField.relatedProperty);
            jsonField.value = number(jsonField.value).format(Decimals
                .getCurrencyFormat(view.model.get(jsonField.relatedProperty)));
        }

        if (util.isEmpty(jsonField.value)) {
            jsonField.blockClass += ' hidden ';
        }

        /*
         * CSS adds a semicolon after the label. Remove it from the actual value of
         * the label if present.
         */
        if (util.contains(jsonField.fieldLabel, ':')) {
            jsonField.fieldLabel = jsonField.fieldLabel.replace(':', '');
        }

        return readOnlyTmpl(jsonField);
    },

    /**
     * {Object} view
     * {Object} jsonFieldParam
     * @returns {String}
     */
    TEXTBOX_HELPERTEXT(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.value = util.unescape(view.model.get(jsonField.name));
        if (util.isEmpty(jsonField.value)) {
            jsonField.blockClass += ' hidden ';
        }
        return readOnlyTmpl(jsonField);
    },

    // View Textbox Helper
    /**
     * {Object} view
     * {Object} jsonFieldParam
     * @returns {String}
     */
    RCOUNT(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        if ((jsonField.fieldType === 'AMOUNT' || jsonField.fieldType === 'PAMOUNT') && jsonField.relatedProperty) {
            jsonField.relatedValue = view.model.get(jsonField.relatedProperty);
        }

        jsonField.value = view.model.get(jsonField.name);

        if (util.isEmpty(jsonField.value)) {
            jsonField.blockClass += ' hidden ';
        }

        /*
         * CSS adds a semicolon after the label. Remove it from the actual value of
         * the label if present.
         */
        if (util.contains(jsonField.fieldLabel, ':')) {
            jsonField.fieldLabel = jsonField.fieldLabel.replace(':', '');
        }

        return readOnlyTmpl(jsonField);
    },

    // View Time Field
    /**
     * {Object} view
     * {Object} jsonFieldParam
     * @returns {String}
     */
    TIMEFIELD(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        const timeZone = userInfo.get('timezone') || serverConfigParams.get('SystemTimezone');

        if (timeZone) {
            jsonField.relatedValue = moment(new Date()).tz(timeZone).format('z');
        }

        return !util.isEmpty(jsonField.value) ? readOnlyTmpl(jsonField) : '';
    },

    // Second account number widget
    /*
     * param {Object} jsonField
     * @returns {String}
     */
    SECONDACCOUNTNUM(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'SECONDACCOUNTNUMWIDGET',
            blockClass: 'col-md-2 col-mdlg-4',
            fieldName: jsonField.name,
            shouldBeProtected: jsonField.protected,
        });
    },

    // View password Helper
    /**
     * {Object} view
     * {Object} jsonFieldParam
     * @returns {String}
     */
    PASSWORD(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.value = view.model.get(jsonField.name);
        return !util.isEmpty(jsonField.value) ? readOnlyTmpl(jsonField) : '';
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    BASEWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'BASEWIDGET',
            fieldName: jsonField.name,
        });
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    VALUEDATEWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'VALUEDATEWIDGET',
            fieldName: jsonField.name,
            cssWidgetClass: 'inline-fields',
            blockClass: jsonField.blockClass,
        });
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    ADDRESSFIELDWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'ADDRESSFIELDWIDGET',
            fieldName: jsonField.name,
            cssWidgetClass: 'inline-fields',
        });
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    COMBOBOXWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'COMBOBOXWIDGET',
            fieldName: jsonField.name,
        });
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    DOUBLECOMBOBOXWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'DOUBLECOMBOBOXWIDGET',
            fieldName: jsonField.name,
        });
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    FLEXDROPDOWNWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'FLEXDROPDOWNWIDGET',
            fieldName: jsonField.name,
        });
    },

    DOCUMENTWIDGET(view, jsonField) {
        const viewParam = view;
        viewParam.useAltGrid = true;
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'DOCUMENTWIDGET',
            fieldName: jsonField.name,
            cssWidgetClass: 'inline-fields',
            blockClass: 'col-12',
        });
    },
};

export default readOnlyFieldHelpers;
