var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a class=\"btn-popover toolTip-btn-popover\" data-toggle=\"popover\" role=\"button\" aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"tooltip.shortened",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":99},"end":{"line":5,"column":129}}}))
    + "\" data-content=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":5,"column":145},"end":{"line":5,"column":154}}}) : helper)))
    + "\" data-placement=\"auto right\">\n            <span class=\"toolTipCell-icon icon-info\"></span>\n        </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"shortened-cell\">\n    <span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"formattedValue") || (depth0 != null ? lookupProperty(depth0,"formattedValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"formattedValue","hash":{},"data":data,"loc":{"start":{"line":2,"column":10},"end":{"line":2,"column":28}}}) : helper)))
    + "</span>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasToolTip") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":8,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});