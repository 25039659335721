import locale from '@glu/locale';
import util from '@glu/core/src/util';
import loadingEmptyTmpl from 'common/templates/loadingEmpty.hbs';
import SavedViewsList from 'common/dynamicPages/views/savedViews/savedViewsList';
import SavedViewModel from 'common/dynamicPages/models/savedView';
import CollectionComboBoxView from 'common/dynamicPages/views/savedViews/combobox';
import savedViewsListTmpl from './savedViewsList.hbs';

const SavedViewsListView = SavedViewsList.extend({
    template: savedViewsListTmpl,
    loadingTemplate: loadingEmptyTmpl,

    ui: {
        gridSavedList: '.gridSavedList',
        $makeDefaultButton: '.btn.makeDefault',
        $clearDLButton: '.btn.clearDLButton',
    },

    events: util.extend(
        {},
        SavedViewsList.prototype.events,
        {
            'click .btn.makeDefault': 'makeDefault',
            'click .btn.clearDLButton': 'clearDefault',
        },
    ),

    initialize(opts) {
        this.grid = opts.grid;
        this.wrapper = opts.wrapper;
        this.savedViews = opts.savedViews;
        this.canSave = opts.canSave;
        this.defaultText = opts.defaultText;
        this.currentSavedView = null;
        this.defaultSavedView = null;
        this.comboBoxView = null;
    },

    onRender() {
        const self = this;

        if (this.hasLoadedRequiredData()) {
            this.initializeComboBox1();
            this.showSavedViewFilters(this.currentSavedView);
        } else {
            this.savedViews.fetch({
                success() {
                    self.setHasLoadedRequiredData(true);
                    self.render();
                },
            });
        }
    },

    initializeComboBox1() {
        const self = this;
        const currentView = this.savedViews.find(view => view.get('viewId') === self.wrapper.viewId);

        if (util.isEmpty(this.wrapper.viewId)) {
            self.activateDefaultButton();
            self.activateClearDLButton();
        } else if (self.wrapper.viewId === '0') {
            self.currentSavedView = null;
            self.defaultSavedView = null;
            self.activateDefaultButton();
            self.activateClearDLButton();
        } else if (currentView) {
            self.currentSavedView = currentView;
            self.defaultSavedView = currentView;
            self.activateDefaultButton();
            self.deactivateClearDLButton();
        }
        this.comboBoxView = new CollectionComboBoxView({
            collection: this.savedViews,
            noSelectionText: util.isUndefined(this.defaultText) ? '' : this.defaultText,
            addButtonText: locale.get('common.save.view'),
            selected: this.currentSavedView,
            canAdd: util.isUndefined(this.canSave) ? true : this.canSave,
            canEdit: 'true',
        });

        this.listenTo(this.comboBoxView, 'create', this.callFilterSave);
        this.listenTo(this.comboBoxView, 'remove', this.itemDestroyed);
        this.listenTo(this.comboBoxView, 'cleared', this.selectionCleared);
        this.listenTo(this.comboBoxView, 'select', this.itemSelected);
        this.dropdownRegion.show(this.comboBoxView);
        if (this.comboBoxView.selected !== null) {
            this.trigger('comboShown', this.comboBoxView.selected.id);
        }
    },

    callFilterSave(data) {
        const self = this;
        const selectedModel = this.comboBoxView.selectedModel();

        if (selectedModel) {
            selectedModel.fetch({
                success() {
                    self.trigger('comboSave', data);
                },
            });
        } else {
            this.trigger('comboSave', data);
        }
    },

    itemSelected(model) {
        if (model) {
            this.ui.$makeDefaultButton.show();
            this.ui.$clearDLButton.show();
            if (this.defaultSavedView && model.get('viewId') === this.defaultSavedView.get('viewId')) {
                this.activateDefaultButton();
                this.deactivateClearDLButton();
            } else {
                this.deactivateDefaultButton();
                this.activateClearDLButton();
            }
        } else {
            this.ui.$makeDefaultButton.hide();
            this.ui.$clearDLButton.hide();
        }
        this.currentSavedView = model;
        this.showSavedViewFilters(this.currentSavedView);
        this.trigger('viewSelected', model);
    },

    activateDefaultButton() {
        this.ui.$makeDefaultButton.attr('style', 'display: none !important');
    },

    deactivateDefaultButton() {
        this.ui.$makeDefaultButton.attr('style', 'display: inline-block !important; vertical-align:top;');
    },

    activateClearDLButton() {
        this.ui.$clearDLButton.attr('style', 'display: none !important');
    },

    deactivateClearDLButton() {
        this.ui.$clearDLButton.attr('style', 'display: inline-block !important; vertical-align:top;');
    },

    processDefault(variable) {
        const self = this;
        const product = this.grid.collection.context.productCode
            || this.grid.collection.context.actionContext.productCode;
        const func = this.grid.collection.context.functionCode
            || this.grid.collection.context.actionContext.functionCode;
        const type = this.grid.collection.context.overrideTypeCode
            || this.grid.collection.context.typeCode
            || this.grid.collection.context.actionContext.typeCode;
        const options = {
            productCode: product,
            functionCode: func,
            typeCode: type,
            success() {},
            error() {},
        };

        if (this.currentSavedView) {
            if (variable === 'setDefault') {
                options.success = function () {
                    self.activateDefaultButton();
                    self.deactivateClearDLButton();
                    self.defaultSavedView = self.currentSavedView;
                };
            } else {
                options.viewID = '0';
                options.success = function () {
                    self.deactivateDefaultButton();
                    self.activateClearDLButton();
                    self.defaultSavedView = null;
                };
            }
            this.currentSavedView.default(options);
        }
    },

    makeDefault() {
        this.processDefault('setDefault');
    },

    clearDefault() {
        this.processDefault('clearDefault');
    },

    saveView(data, model) {
        const self = this;
        let viewModel;

        const findModel = this.savedViews.get(data.value);
        if (typeof (findModel) !== 'undefined') {
            viewModel = findModel;
            viewModel.set({
                listName: data.value,
                productCode: this.grid.collection.context.productCode
                    || this.grid.collection.context.actionContext.productCode,
                functionCode: this.grid.collection.context.functionCode
                    || this.grid.collection.context.actionContext.functionCode,
                typeCode: this.grid.collection.context.overrideTypeCode
                    || this.grid.collection.context.typeCode
                    || this.grid.collection.context.actionContext.typeCode,
                mode: '',
                shared: util.isUndefined(data.shared) ? false : data.shared,
            });
        } else {
            viewModel = new SavedViewModel();
            viewModel.set({
                listName: data.value,
                productCode: this.grid.collection.context.productCode
                    || this.grid.collection.context.actionContext.productCode,
                functionCode: this.grid.collection.context.functionCode
                    || this.grid.collection.context.actionContext.functionCode,
                typeCode: this.grid.collection.context.overrideTypeCode
                    || this.grid.collection.context.typeCode
                    || this.grid.collection.context.actionContext.typeCode,
                mode: '',
                update: 'false',
                shared: util.isUndefined(data.shared) ? false : data.shared,
            });
        }

        let filterArray = [];
        if (util.isUndefined(data.filters)) {
            if (this.wrapper.filters !== null) {
                this.wrapper.filters.each((fm) => {
                    let paramValue;
                    util.each(self.wrapper.columns.jsonData.filterFields, (field) => {
                        if (fm.get('field') === field.fieldName) {
                            if (field.codeField) {
                                paramValue = `${field.codeField}^${field.codeField}`;
                            } else {
                                paramValue = `${fm.get('field')}^${fm.get('field')}`;
                            }
                        }
                    });
                    /**
                     * HACK: wrapper FilterFields does not have the passed in filter field
                     * in the list, default to field passed in if not found in the list.
                     */
                    if (!paramValue) {
                        paramValue = `${fm.get('field')}^${fm.get('field')}`;
                    }
                    let value = fm.get('value');
                    const type = fm.get('type');

                    if (type === 'string' || type === 'enum' || type === 'typeahead') {
                        paramValue += '^1^';
                    } else if (type === 'amount' || type === 'number') {
                        paramValue += '^0^';
                    } else if (type === 'date') {
                        paramValue += '^4^';
                    }

                    if (type === 'string' || type === 'enum' || type === 'typeahead') {
                        paramValue += 'CONTAINS';
                    } else {
                        paramValue += (fm.get('equality')) ? fm.get('equality') : 'EQ';
                    }
                    if (util.isArray(value)) {
                        value = value.join('^');
                    }

                    paramValue += `^${value}`;

                    filterArray.push({
                        paramName: 'Filters',
                        paramValue,
                    });
                });
            }
            if (model) {
                filterArray = this.intersectFilterLists(
                    filterArray,
                    model.currentState.filters,
                );
            }
        } else {
            filterArray = data.filters;
        }

        // set filter data param strings to be sent to service
        viewModel.set('filters', filterArray || this.generateFilterParams(model));

        let ci = {};
        if (util.isUndefined(data.column)) {
            if (!util.isEmpty(this.wrapper.sortKey) && !util.isEmpty(this.wrapper.sortOrder)) {
                ci = {
                    sortColumn: this.wrapper.sortKey,
                    sortDir: this.wrapper.sortOrder,
                };
            } else if (model && model.currentState.columnInfo) {
                ci = {
                    sortColumn: model.currentState.columnInfo.sortColumn,
                    sortDir: model.currentState.columnInfo.sortDir,
                };
            }
            // no column available for this savelistview.
            viewModel.columnJSON = [];
        } else {
            ci = data.column;
            viewModel.columnJSON = [];
        }
        viewModel.set({
            columnInfo: ci,
        });
        viewModel.save(
            {},
            {
                success(successModel) {
                    // check if model already exists in collection
                    if (!self.savedViews.get(successModel.id)) {
                        self.savedViews.add(viewModel);
                    }

                    self.wrapper.viewId = successModel.get('viewId');
                    self.comboBoxView.ui.$selectList.comboBox('val', successModel.cid);
                    self.comboBoxView.ui.$selectList.comboBox('close');
                    self.currentSavedView = successModel;
                    if (self.defaultSavedView && successModel.get('viewId') === self.defaultSavedView.get('viewId')) {
                        self.activateDefaultButton();
                        self.deactivateClearDLButton();
                    } else {
                        self.deactivateDefaultButton();
                        self.activateClearDLButton();
                    }

                    self.trigger('viewSaveSuccess', successModel.get('viewId'));
                },

                error() {
                    self.comboBoxView.applyError();
                    self.comboBoxView.ui.$selectList.comboBox('val', self.savedViews.getModelByViewId(self.wrapper.viewId).cid);
                    self.comboBoxView.ui.$selectList.comboBox('open');
                },
            },
        );
    },
});

export default SavedViewsListView;
