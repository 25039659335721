import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import moment from 'moment';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';
import appConfigParams from 'system/webseries/models/applicationConfiguration';

export default {
    hasCurrencies(model) {
        return !!(model.get('DEBIT_CURRENCY') && model.get('CREDIT_CURRENCY'));
    },

    crossCurr(model) {
        return this.hasCurrencies(model) && model.get('DEBIT_CURRENCY') !== model.get('CREDIT_CURRENCY');
    },

    hasAmount(model) {
        return !!(model.get('CREDIT_AMOUNT') || model.get('DEBIT_AMOUNT'));
    },

    showExchangeRate(model) {
        return this.crossCurr(model) && this.hasAmount(model);
    },

    getIndicativeRate(model) {
        const url = services.generateUrl(constants.URL_GETINDICATIVERATE_ACTION);
        const data = {
            enteredAmountFlag: model.get('DBCR_FLAG') || 'C',
            creditCurrency: model.get('CREDIT_CURRENCY'),
            debitCurrency: model.get('DEBIT_CURRENCY'),
            creditAmount: model.get('CREDIT_AMOUNT'),
            debitAmount: model.get('DEBIT_AMOUNT'),
            valueDate: model.get('VALUE_DATE'),
            status: model.get('STATUS') || 'EN',
            typeCode: model.get('PAYMENTTYPE'),
            actionMode: 'INSERT',
        };

        return http.post(url, data).then((result) => {
            model.set({
                INDICATIVERATE: result.indicativeRate,
                INDICATIVEAMOUNT: result.indicativeAmount,
                EXCHANGE_RATE: result.indicativeRate,
                DEBIT_AMOUNT: result.indicativeAmount || ' ',
            });
            return result;
        });
    },

    doFieldValidation(model, fieldname) {
        const validationService = services.generateUrl(constants.URL_DO_FIELD_VALIDATION);

        const inputData = [{
            name: 'REQUESTTYPE',
            value: 'fieldValidation',
        }, {
            name: 'FIELDNAME',
            value: fieldname,
        }, {
            name: 'ACTION',
            value: model.id ? 'MODIFY' : 'INSERT',
        }, {
            name: 'PRODUCT',
            value: model.get('PAYMENTPRODUCTCODE'),
        }, {
            name: 'FUNCTION',
            value: model.get('PAYMENTFUNCTIONCODE'),
        }, {
            name: 'TYPE',
            value: model.get('PAYMENTTYPE'),
        }];

        // populate other values from the model
        util.each(['CREDIT_AMOUNT', 'VALUE_DATE', 'CREDIT_CURRENCY', 'DEBIT_CURRENCY'], (key) => {
            const value = model.get(key);
            if (!(util.isEmpty(value))) {
                inputData.push({
                    name: key,
                    value: util.unescape(value),
                });
            }
        });

        return http.post(
            validationService,
            {
                item: inputData,
            },
        ).then((result) => {
            // set the tran date and value date
            util.each(result.item, (nvp) => {
                if (nvp.name === 'VALUE_DATE' || nvp.name === 'TRAN_DATE') {
                    model.set(
                        nvp.name,
                        moment(nvp.value, 'YYYY-MM-DD').format(userInfo.getDateFormat()),
                        {
                            silent: true,
                        },
                    );
                }
                if (nvp.name === 'MARKETCONVENTION') {
                    model.set(
                        nvp.name,
                        nvp.value,
                        {
                            silent: true,
                        },
                    );
                }
            });
            return result;
        });
    },

    /**
     * Based on appconfig determine if the user is restricted to USD only or
     * if the accoubt us restricted to USD only. User take precedence over
     * the account.
     * @returns {boolean}
     */
    isUSDOnly() {
        if (appConfigParams.getValue('WIRES', 'ALLOWFXUSDUSER') === '1') {
            return userInfo.get('allowFXUSDUser') === '0';
        } else if (appConfigParams.getValue('INTL', 'ALLOWFXUSDSPLIT') === '1' && this.model.account) {
            return this.entryView.getAccountAttribute(this.model.account, 'ALLOWFX') === '0';
        }
        return false;
    },

};
