var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button type=\"button\" class=\"btn btn-tertiary refresh\" title=\""
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.refresh",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":1,"column":62},"end":{"line":1,"column":89}}}))
    + "\" data-hook=\"getRefreshPoller\">\n    <span class=\"icon icon-refresh\"></span>\n    <span class=\"refresh-time\" data-hook=\"getRefreshTimeStamp\"></span>\n</button>\n";
},"useData":true});