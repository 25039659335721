import util from '@glu/core/src/util';
import locale from '@glu/locale';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import serverConfig from 'system/webseries/models/configurationParameters';
import orderingPartyUtil from 'common/util/orderingPartyLookupUtil';
import PanelLayout from './validationPanel';
import addressViewTmpl from './addressView.hbs';

const AddressView = PanelLayout.extend({
    template: addressViewTmpl,
    tagName: 'fieldset',
    rtpSelected: false,
    intlSelected: false,
    rendered: false,

    getWhiteList() {
        return ['BENE_ADDRESS_1', 'BENE_ADDRESS_2', 'BENE_CITY', 'BENE_STATE', 'BENE_PROVINCE', 'BENE_POSTALCODE', 'BENE_COUNTRY'];
    },

    ui: {
        $beneState: 'select[name="BENE_STATE"]',
        $beneProvince: 'input[name="BENE_PROVINCE"]',
        $beneCountry: 'select[name="BENE_COUNTRY"]',
        $beneCity: 'input[name="BENE_CITY"]',
        $beneAddress1: 'input[name="BENE_ADDRESS_1"]',
        $beneAddress2: 'input[name="BENE_ADDRESS_2"]',
        $benePostalCode: 'input[name="BENE_POSTALCODE"]',
        completeIcon: '.complete-icon',
    },

    events: {
        'change @ui.$beneState': 'validateBeneState',
        'change @ui.$beneAddress1': 'applyMandatoryFieldRules',
        'change @ui.$beneAddress2': 'applyMandatoryFieldRules',
        'change @ui.$benePostalCode': 'applyMandatoryFieldRules',
        'change @ui.$beneCity': 'applyMandatoryFieldRules',
    },

    modelEvents: {
        'change:BENE_COUNTRY': 'validateBeneCountry',
    },

    onRender() {
        this.rendered = true;
        this.updateModelWithDefaultValues();
        this.applyMandatoryFieldRules();
    },

    /*
     * When default values are set by configuration, we need to make sure they are not only
     * shown on the resulting UI but also setup in the data layer to be submitted correctly.
     */
    updateModelWithDefaultValues() {
        const defaultKeysToCheck = [
            'BENE_ADDRESS_1',
            'BENE_ADDRESS_2',
            'BENE_CITY',
            'BENE_COUNTRY',
            'BENE_POSTALCODE',
        ];
        const setValues = defaultKeysToCheck.reduce((acc, cur) => {
            const accum = acc;
            const fieldTypeDataValue = this.fieldTypeData?.[cur]?.value;
            if (this.model.get(cur) !== '' && !this.model.get(cur) && fieldTypeDataValue) {
                accum[cur] = fieldTypeDataValue;
            }
            return accum;
        }, {});

        if (Object.keys(setValues).length) {
            // setting these default values silently to not cause additional renders
            this.model.set(setValues, { silent: true });
            this.hasDefaultValues = true;
        } else {
            this.hasDefaultValues = false;
        }
    },

    /**
     * Depending on a number of factors and configurations, the various fields within the
     * address form may or may not be mandatory. When any of these factors/configurations
     * change, we've got to rerun this function to determine the rules for these fields
     */
    applyMandatoryFieldRules() {
        // grab any applicable configuration rules
        const structuredAddressConfig = applicationConfigParams.getValue('PAYMENTS', 'STRUCTUREDADDRESSVALIDATION') === '1';
        const rtpRequireAddressConfig = applicationConfigParams.getValue('RTP', 'REQUIREBENEADDRESS') === '1';
        const intlRequireCityCountryConfig = applicationConfigParams.getValue('WIRES', 'REQUIRECITYCOUNTRYINTL') === '1';
        const orderingPartyAddressConfig = serverConfig.get('WIRE_REQUIREORDEROFADDRESSFIELDS') === 'true';
        const orderingPartyConfig = this.fieldTypeData
            && this.fieldTypeData.ALLOWBYORDEROF !== undefined
            && orderingPartyUtil.isEntitled(this.fieldTypeData.ALLOWBYORDEROF.value);

        // grab any applicable field values
        const address1Populated = this.model.get('BENE_ADDRESS_1');
        const address2Populated = this.model.get('BENE_ADDRESS_2');
        const cityPopulated = this.model.get('BENE_CITY');
        const countryPopulated = this.model.get('BENE_COUNTRY');
        const postalCodePopulated = this.model.get('BENE_POSTALCODE');
        const allowOrderingParty = this.model.get('ALLOWORDERINGPARTYLOOKUP');

        // setup defaults for whether or not certain fields are going to be required
        let address1Required = false;
        let cityRequired = false;
        let countryRequired = false;
        let postalCodeRequired = false;
        let provinceRequired = false;
        let stateRequired = false;

        // determine if enough fields are populated to qualify for specific rules
        const rtpAddressRuleActive = address1Populated
            || address2Populated
            || countryPopulated
            || cityPopulated
            || postalCodePopulated;

        /**
         * if (either of the address fields have been provided AND the structured address config
         * is active) OR (an INTL payment is selected AND the intlConfig is active)
         */
        if (((address1Populated || address2Populated) && structuredAddressConfig)
            || (this.intlSelected && intlRequireCityCountryConfig)) {
            cityRequired = true;
            countryRequired = true;
        }

        // determine if the rtp rule is in effect
        if (this.rtpSelected && (rtpRequireAddressConfig || rtpAddressRuleActive)) {
            cityRequired = true;
            countryRequired = true;
            address1Required = true;
            postalCodeRequired = true;
            stateRequired = this.isUS();
            provinceRequired = !stateRequired;
        }

        /*
         * If the contact can be used as an ordering party and the system configuration
         * requires the ordering party to have address information then make the
         * appropriate address fields mandatory
         */
        if (allowOrderingParty === '1'
            && orderingPartyConfig === true && orderingPartyAddressConfig === true) {
            address1Required = true;
            cityRequired = true;
            stateRequired = this.isUS();
            provinceRequired = !stateRequired;
            countryRequired = true;
        }

        this.toggleMandatoryField('BENE_ADDRESS_1', this.ui.$beneAddress1, address1Required, 'bab.address.1');
        this.toggleMandatoryField('BENE_CITY', this.ui.$beneCity, cityRequired, 'bab.city');
        this.toggleMandatoryField('BENE_COUNTRY', this.ui.$beneCountry, countryRequired, 'bab.country');
        this.toggleMandatoryField('BENE_POSTALCODE', this.ui.$benePostalCode, postalCodeRequired, 'bab.zip.postal.code');
        this.toggleMandatoryField('BENE_PROVINCE', this.ui.$beneProvince, provinceRequired, 'bab.province');
        this.toggleMandatoryField('BENE_STATE', this.ui.$beneState, stateRequired, 'bab.state');
    },

    /**
     * toggle the mandatory state of a specific field
     * @param {string} fieldName - the name of the field
     * @param {jQuery} uiReference - a reference to the matching DOM element
     * @param {bool} requiredFlag - a flag to indicate if this field is being made mandatory
     * @param {string} validatorLocale - the locale string lookup for this fields validator
     */
    toggleMandatoryField(fieldName, uiReference, requiredFlag, validatorLocale) {
        if (requiredFlag) {
            this.model.addValidator(
                fieldName,
                {
                    exists: true,
                    description: locale.get(validatorLocale),
                },
            );
        } else {
            this.model.removeValidator(fieldName);
        }
        uiReference.closest('.form-group').toggleClass('required', requiredFlag);
    },

    /**
     * Depending on the selected status of various payment types in one or more of this contacts
     * accounts, certain field will be mandatory/optional
     * @param {bool} rtpSelected - if the CRTRAN payment type has been selected
     * @param {bool} intlSelected - if the INTL payment type has been selected
     */
    updatePaymentTypesSelected(rtpSelected, intlSelected) {
        this.rtpSelected = rtpSelected;
        this.intlSelected = intlSelected;
        if (this.rendered) {
            this.applyMandatoryFieldRules();
        }
    },

    isNotStructValidated() {
        const config = applicationConfigParams.getValue('PAYMENTS', 'STRUCTUREDADDRESSVALIDATION');
        if (!config || config === '0') {
            return true;
        }
        return false;
    },

    validateBeneState() {
        this.model.validateField('BENE_STATE');
    },

    validateBeneCountry() {
        if (!this.model.get('BENE_COUNTRY') || this.model.get('BENE_COUNTRY') === 'US') {
            this.model.set(
                'BENE_PROVINCE',
                '',
                {
                    silent: true,
                },
            );
        } else {
            this.model.set(
                'BENE_STATE',
                '',
                {
                    silent: true,
                },
            );
        }
        this.render();
    },

    isUS() {
        return (!this.model.get('BENE_COUNTRY') || this.model.get('BENE_COUNTRY') === 'US');
    },

    templateHelpers() {
        const helpers = PanelLayout.prototype.templateHelpers.call(this);
        return util.extend(
            helpers,
            {
                states: this.options.states.toJSON(),
                countries: this.options.countries.toJSON(),
                isUS: this.isUS(),
                isNotStructValidated: this.isNotStructValidated(),
                fieldTypeData: this.fieldTypeData,
                doesNotHaveDefaultValues: !this.hasDefaultValues,
            },
        );
    },

    delegateEvents() {
        PanelLayout.prototype.delegateEvents.call(this);
        this.listenTo(this.options.countries, 'change reset remove', this.render);
        this.listenTo(this.options.states, 'change reset remove', this.render);
    },
});

export default AddressView;
