var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " required ";
},"3":function(container,depth0,helpers,partials,data) {
    return "hide";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isMandatory") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":12},"end":{"line":1,"column":48}}})) != null ? stack1 : "")
    + " widget-field-container numeric-range-widget form-group\">\n   <div class=\"range-widget-row\">\n     <div class=\"controls\">\n        <label data-hook=\"getLabelFrom\" for=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"getFromField") || (depth0 != null ? lookupProperty(depth0,"getFromField") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getFromField","hash":{},"data":data,"loc":{"start":{"line":4,"column":45},"end":{"line":4,"column":61}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":4,"column":62},"end":{"line":4,"column":69}}}) : helper)))
    + "\" >"
    + alias4(((helper = (helper = lookupProperty(helpers,"getFieldLabel") || (depth0 != null ? lookupProperty(depth0,"getFieldLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getFieldLabel","hash":{},"data":data,"loc":{"start":{"line":4,"column":72},"end":{"line":4,"column":89}}}) : helper)))
    + "</label>\n        <label class=\"sr-only\" for=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"getToField") || (depth0 != null ? lookupProperty(depth0,"getToField") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getToField","hash":{},"data":data,"loc":{"start":{"line":5,"column":36},"end":{"line":5,"column":50}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":5,"column":51},"end":{"line":5,"column":58}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"getToFieldLabel") || (depth0 != null ? lookupProperty(depth0,"getToFieldLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getToFieldLabel","hash":{},"data":data,"loc":{"start":{"line":5,"column":60},"end":{"line":5,"column":79}}}) : helper)))
    + "</label>\n     </div>\n     <button class=\"btn-tertiary btn "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disallowRange") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":37},"end":{"line":7,"column":69}}})) != null ? stack1 : "")
    + "\"  data-hook=\"getRangeToggle\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"getFromField") || (depth0 != null ? lookupProperty(depth0,"getFromField") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getFromField","hash":{},"data":data,"loc":{"start":{"line":7,"column":105},"end":{"line":7,"column":121}}}) : helper)))
    + "_TOGGLE\" value=\"true\" aria-pressed=\"false\" aria-describedby=\"toggle-direction-"
    + alias4(((helper = (helper = lookupProperty(helpers,"getFromField") || (depth0 != null ? lookupProperty(depth0,"getFromField") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getFromField","hash":{},"data":data,"loc":{"start":{"line":7,"column":199},"end":{"line":7,"column":215}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":7,"column":216},"end":{"line":7,"column":223}}}) : helper)))
    + "\" aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"getToggleAriaLabel") || (depth0 != null ? lookupProperty(depth0,"getToggleAriaLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getToggleAriaLabel","hash":{},"data":data,"loc":{"start":{"line":7,"column":237},"end":{"line":7,"column":259}}}) : helper)))
    + "\">\n             "
    + alias4(((helper = (helper = lookupProperty(helpers,"getRangeToggleLabel") || (depth0 != null ? lookupProperty(depth0,"getRangeToggleLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getRangeToggleLabel","hash":{},"data":data,"loc":{"start":{"line":8,"column":13},"end":{"line":8,"column":36}}}) : helper)))
    + "\n     </button>\n   </div>\n   <div class=\"range-widget-row\" >\n     <input class=\"form-control range\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"getFromField") || (depth0 != null ? lookupProperty(depth0,"getFromField") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getFromField","hash":{},"data":data,"loc":{"start":{"line":12,"column":43},"end":{"line":12,"column":59}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":12,"column":60},"end":{"line":12,"column":67}}}) : helper)))
    + "\" type=\"text\" data-hook=\"getFromField\" data-type=\"number\"  data-bind=\"model\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"getFromField") || (depth0 != null ? lookupProperty(depth0,"getFromField") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getFromField","hash":{},"data":data,"loc":{"start":{"line":12,"column":150},"end":{"line":12,"column":166}}}) : helper)))
    + "\" aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"getFromFieldAriaLabel") || (depth0 != null ? lookupProperty(depth0,"getFromFieldAriaLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getFromFieldAriaLabel","hash":{},"data":data,"loc":{"start":{"line":12,"column":180},"end":{"line":12,"column":205}}}) : helper)))
    + "\" placeholder=\"\">\n     <input class=\"form-control hide\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"getToField") || (depth0 != null ? lookupProperty(depth0,"getToField") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getToField","hash":{},"data":data,"loc":{"start":{"line":13,"column":42},"end":{"line":13,"column":56}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":13,"column":57},"end":{"line":13,"column":64}}}) : helper)))
    + "\" type=\"text\" data-hook=\"getToField\"  data-type=\"number\" data-bind=\"model\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"getToField") || (depth0 != null ? lookupProperty(depth0,"getToField") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getToField","hash":{},"data":data,"loc":{"start":{"line":13,"column":145},"end":{"line":13,"column":159}}}) : helper)))
    + "\" aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"getToFieldAriaLabel") || (depth0 != null ? lookupProperty(depth0,"getToFieldAriaLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getToFieldAriaLabel","hash":{},"data":data,"loc":{"start":{"line":13,"column":173},"end":{"line":13,"column":196}}}) : helper)))
    + "\" placeholder=\""
    + alias4(lookupProperty(helpers,"locale").call(alias1,"title.to",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":211},"end":{"line":13,"column":232}}}))
    + "\" >\n   </div>\n   <div class=\"range-widget-row validation-error\">\n     <span class=\"help-block\" data-bind=\"model\" data-validate=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"getFromField") || (depth0 != null ? lookupProperty(depth0,"getFromField") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getFromField","hash":{},"data":data,"loc":{"start":{"line":16,"column":63},"end":{"line":16,"column":79}}}) : helper)))
    + "\"></span>\n     <span class=\"help-block hide\" data-bind=\"model\" data-validate=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"getToField") || (depth0 != null ? lookupProperty(depth0,"getToField") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getToField","hash":{},"data":data,"loc":{"start":{"line":17,"column":68},"end":{"line":17,"column":82}}}) : helper)))
    + "\" data-hook=\"getToFieldValidationMessage\"></span>\n   </div>\n   <p id=\"toggle-direction-"
    + alias4(((helper = (helper = lookupProperty(helpers,"getFromField") || (depth0 != null ? lookupProperty(depth0,"getFromField") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getFromField","hash":{},"data":data,"loc":{"start":{"line":20,"column":27},"end":{"line":20,"column":43}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":20,"column":44},"end":{"line":20,"column":51}}}) : helper)))
    + "\" class=\"hide\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"title.rangetoggle.aria.describedby",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":66},"end":{"line":20,"column":113}}}))
    + "</p>\n</div>\n\n\n\n\n\n\n\n\n";
},"useData":true});