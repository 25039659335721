import util from '@glu/core/src/util';
import services from 'services';
import dialog from '@glu/dialog';
import ExportDataView from 'app/administration/views/fileLoad/exportData';
import store from 'system/utilities/cache';
import ListView from 'common/dynamicPages/views/workflow/list';
import template from './fileLoadAdminExport.hbs';

const BtrExportListView = ListView.extend({
    template,

    events: {
        'click [data-hook="refresh-button"]': 'refreshData',
        'click [data-hook="export-button"]': 'export',
    },

    initialize(options) {
        const superOptions = {
            menuCategory: 'SMAINT',
            serviceName: 'fileLoadExport/downloadExport',
            serviceFunc: null,
            businessType: null,
            context: 'getListView',
            selector: 'none',
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    gridRowSelect(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        store.set('fileLoadAdmin-actionModel', options.model);
        this.navigateTo('REPORTING/viewExportDetail');
        return Promise.resolve();
    },

    gridDownload(options) {
        if (this.$('#downloadForm').length === 0) {
            const f = `<form id="downloadForm" action="${services.generateUrl('/export/download ')}" method="get" target="_blank"><input id="exportId" type="hidden" name="exportId"></form>`;
            this.$el.append(f);
        }
        this.$('#downloadForm #exportId').val(options.model.get('EXPORTID'));
        this.$('#downloadForm').submit();
        return Promise.resolve();
    },

    export() {
        this.exportDataView = new ExportDataView();
        this.listenTo(this.exportDataView, 'doExport', function (alertView) {
            this.alertRegion.show(alertView);
            this.gridView.refreshGridData();
        });
        dialog.open(this.exportDataView);
    },
});

export default BtrExportListView;
