var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page page-2col\">\n    <div class=\"page-content beneficiaryPage\">\n        <div class=\"title-strip\">\n            <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"addressBook",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":40}}}))
    + "</h2>\n        </div>\n        <form class=\"beneficiaryForm\">\n            <fieldset>\n                <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"viewBeneficiary",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":24},"end":{"line":8,"column":52}}}))
    + "</legend>\n                <div class=\"col\">\n                    <div class=\"form-group\">\n                        <label for=\"name\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"name",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":42},"end":{"line":11,"column":59}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <input class=\"form-control\" type=\"text\" data-bind=\"model\" name=\"beneName\" disabled=\"disabled\" value=\"\" id=\"beneName\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"beneName\"></span>\n                        </div>\n                    </div>\n                    <div class=\"form-group\">\n                        <label for=\"reference\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"referenceId",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":47},"end":{"line":18,"column":71}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <input class=\"form-control\" type=\"text\" name=\"beneReference\" data-bind=\"model\" value=\"\" disabled=\"disabled\" id=\"beneReference\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"beneReference\"></span>\n                        </div>\n                    </div>\n                    <div class=\"form-group addressLines\">\n                        <label for=\"Address Lines\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"addressLine",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":51},"end":{"line":25,"column":75}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <input class=\"form-control\" type=\"text\" name=\"beneAddress1\" data-bind=\"model\" value=\"\" disabled=\"disabled\" id=\"beneAddress1\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"beneAddress1\"></span>\n                            <input class=\"form-control\" type=\"text\" name=\"beneAddress2\" data-bind=\"model\" value=\"\" disabled=\"disabled\" id=\"beneAddress2\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"beneAddress2\"></span>\n                            <input class=\"form-control\" type=\"text\" name=\"beneAddress3\" data-bind=\"model\" value=\"\" disabled=\"disabled\" id=\"beneAddress3\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"beneAddress3\"></span>\n                        </div>\n                    </div>\n                    <div class=\"form-group\">\n                        <label for=\"country\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"country",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":45},"end":{"line":36,"column":65}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <div data-region=\"countryRegion\"></div>\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"beneCountry\"></span>\n                        </div>\n                    </div>\n                    <div class=\"form-group\">\n                        <label for=\"province\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"BeneAddBook.Province",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":43,"column":46},"end":{"line":43,"column":79}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <input class=\"form-control\" type=\"text\" data-bind=\"model\" name=\"beneProvince\" value=\"\" disabled=\"disabled\" id=\"beneProvince\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"beneProvince\"></span>\n                        </div>\n                    </div>\n                    <div class=\"form-group\" id=\"state\">\n                        <label for=\"state\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"state",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":50,"column":43},"end":{"line":50,"column":61}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <div data-region=\"stateRegion\"></div>\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"beneState\"></span>\n                        </div>\n                    </div>\n                    <div class=\"form-group\">\n                        <label for=\"postal\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"postal",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":57,"column":44},"end":{"line":57,"column":63}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <input class=\"form-control\" type=\"text\" data-bind=\"model\" name=\"benePostalcode\" value=\"\" disabled=\"disabled\" id=\"benePostalcode\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"benePostalcode\"></span>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"col\">\n                    <div class=\"form-group\">\n                        <label for=\"phone\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"BeneAddBook.Phone",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":66,"column":43},"end":{"line":66,"column":73}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <input class=\"form-control\" type=\"text\" data-bind=\"model\" name=\"benePhonenumber\" value=\"\" disabled=\"disabled\" id=\"benePhonenumber\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"benePhonenumber\"></span>\n                        </div>\n                    </div>\n                    <div class=\"form-group\">\n                        <label for=\"fax\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"BeneAddBook.Fax",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":73,"column":41},"end":{"line":73,"column":69}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <input class=\"form-control\" type=\"text\" data-bind=\"model\" name=\"beneFaxnumber\" value=\"\" disabled=\"disabled\" id=\"beneFaxnumber\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"beneFaxnumber\"></span>\n                        </div>\n                    </div>\n                </div>\n            </fieldset>\n            <fieldset>\n                <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"paymentInfo",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":82,"column":24},"end":{"line":82,"column":48}}}))
    + "</legend>\n                <div class=\"col\">\n                    <div class=\"form-group\">\n                        <label for=\"paymentName\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"paymentName",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":85,"column":49},"end":{"line":85,"column":73}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <input class=\"form-control\" type=\"text\" data-bind=\"model\" name=\"paymentName\" value=\"\" disabled=\"disabled\" id=\"paymentName\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"paymentName\"></span>\n                        </div>\n                    </div>\n                    <div class=\"form-group\">\n                        <label for=\"paymentRid\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"referenceId",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":92,"column":48},"end":{"line":92,"column":72}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <input class=\"form-control\" type=\"text\" data-bind=\"model\" name=\"paymentRid\" value=\"\" disabled=\"disabled\" id=\"paymentRid\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"paymentRid\"></span>\n                        </div>\n                    </div>\n                    <div class=\"form-group\">\n                        <label for=\"accountType\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"accountType",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":99,"column":49},"end":{"line":99,"column":73}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <input class=\"form-control\" type=\"text\" data-bind=\"model\" name=\"accountType\" value=\"\" disabled=\"disabled\" id=\"accountType\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"accountType\"></span>\n                        </div>\n                    </div>\n                    <div class=\"form-group\">\n                        <label for=\"accountNumber\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"BeneAddBook.AccountNumber",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":106,"column":51},"end":{"line":106,"column":89}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <input class=\"form-control\" type=\"text\" data-bind=\"model\" name=\"accountNumber\" value=\"\" disabled=\"disabled\" id=\"accountNumber\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"accountNumber\"></span>\n                        </div>\n                    </div>\n                    <div class=\"form-group\" id=\"bank\">\n                        <label for=\"bank\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bank",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":113,"column":42},"end":{"line":113,"column":59}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <div data-region=\"bankRegion\"></div>\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"bank\"></span>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"col\">\n                    <div class=\"form-group\" id=\"currency\">\n                        <label for=\"currency\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"currency",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":122,"column":46},"end":{"line":122,"column":67}}}))
    + "</label>\n                        <div class=\"field-container\">\n                            <div data-region=\"currencyRegion\"></div>\n                        </div>\n                    </div>\n                </div>\n            </fieldset>\n            <div class=\"beneficiaryButtons\">\n                <button type=\"submit\" class=\"btn btn-primary\" data-action=\"modifySubmit\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":130,"column":89},"end":{"line":130,"column":113}}}))
    + "</button>\n                <button type=\"submit\" class=\"btn btn-primary\" data-action=\"modifyReset\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.reset",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":131,"column":88},"end":{"line":131,"column":113}}}))
    + "</button>\n                <button type=\"submit\" class=\"btn btn-primary\" data-action=\"modifyRemove\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.delete",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":132,"column":89},"end":{"line":132,"column":115}}}))
    + "</button>\n            </div>\n        </form>\n        <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"auditInfo",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":135,"column":16},"end":{"line":135,"column":38}}}))
    + "</legend>\n        <div class=\"beneficiaryAudit\" data-region=\"auditRegion\">\n            <div class=\"form-group auditInfo\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"createdBy",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":138,"column":23},"end":{"line":138,"column":45}}}))
    + ":</label>\n                <div class=\"field-container\">\n                    <div class=\"auditData\">\n                        <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"prompt.userGroup",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":141,"column":30},"end":{"line":141,"column":59}}}))
    + "</span>\n                        <input class=\"form-control\" type=\"text\" data-bind=\"model\" disabled=\"disabled\" name=\"enteredUsergroup\" value=\"\" id=\"enteredUsergroup\">\n                    </div>\n                    <div class=\"auditData\">\n                        <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"prompt.userID",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":145,"column":30},"end":{"line":145,"column":56}}}))
    + "</span>\n                        <input class=\"form-control\" type=\"text\" data-bind=\"model\" disabled=\"disabled\" name=\"enteredBy\" value=\"\" id=\"enteredBy\">\n                    </div>\n                    <div class=\"auditData\">\n                        <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"timestamp",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":149,"column":30},"end":{"line":149,"column":52}}}))
    + "</span>\n                        <input class=\"form-control\" type=\"text\" data-bind=\"model\" disabled=\"disabled\" name=\"enteredTimestamp\" value=\"\" id=\"enteredTimestamp\">\n                    </div>\n                </div>\n            </div>\n            <div class=\"form-group auditInfo\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"modifiedBy",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":155,"column":23},"end":{"line":155,"column":46}}}))
    + ":</label>\n                <div class=\"field-container\">\n                    <div class=\"auditData\">\n                        <input class=\"form-control\" type=\"text\" data-bind=\"model\" disabled=\"disabled\" name=\"modifiedUsergroup\" value=\"\" id=\"modifiedUsergroup\">\n                    </div>\n                    <div class=\"auditData\">\n                        <input class=\"form-control\" type=\"text\" data-bind=\"model\" disabled=\"disabled\" name=\"modifiedBy\" value=\"\" id=\"modifiedBy\">\n                    </div>\n                    <div class=\"auditData\">\n                        <input class=\"form-control\" type=\"text\" data-bind=\"model\" disabled=\"disabled\" name=\"modifiedTimestamp\" value=\"\" id=\"modifiedTimestamp\">\n                    </div>\n                </div>\n            </div>\n            <div class=\"form-group auditInfo\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"approvedBy",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":169,"column":23},"end":{"line":169,"column":46}}}))
    + ":</label>\n                <div class=\"field-container\">\n                    <div class=\"auditData\">\n                        <input class=\"form-control\" type=\"text\" data-bind=\"model\" disabled=\"disabled\" name=\"approvedUsergroup\" value=\"\" id=\"approvedUsergroup\">\n                    </div>\n                    <div class=\"auditData\">\n                        <input class=\"form-control\" type=\"text\" data-bind=\"model\" disabled=\"disabled\" name=\"approvedBy\" value=\"\" id=\"approvedBy\">\n                    </div>\n                    <div class=\"auditData\">\n                        <input class=\"form-control\" type=\"text\" data-bind=\"model\" disabled=\"disabled\" name=\"approvedTimestamp\" value=\"\" id=\"approvedTimestamp\">\n                    </div>\n                </div>\n            </div>\n            <div class=\"form-group auditInfo\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"lastActionTime",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":183,"column":23},"end":{"line":183,"column":50}}}))
    + ":</label>\n                <div class=\"field-container\">\n                    <div class=\"auditData\">\n                        <input class=\"form-control\" type=\"text\" data-bind=\"model\" disabled=\"disabled\" name=\"lastActionTime\" value=\"\" id=\"lastActionTime\">\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});