import locale from '@glu/locale';
import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import Collection from '@glu/core/src/collection';
import formatter from 'system/utilities/format';
import StateGrid from 'common/grid/stateGrid';
import thresholdAmountGridItemViewTmpl from './thresholdAmountGridItemView.hbs';

export default Layout.extend({
    template: thresholdAmountGridItemViewTmpl,

    onRender() {
        this.listRegion.show(new StateGrid(this.getGridConfig()));
    },

    templateHelpers() {
        return {
            getAmount: formatter.formatNumber(this.model.get('amount')),
            approvalSequences: this.getApproverSequencesLength(),
            currency: this.options.currency,
        };
    },

    getApproverSequencesLength() {
        const approverSequences = this.model.get('thresholdPanels');
        return approverSequences ? approverSequences.length : 0;
    },

    getGridConfig() {
        const configContext = this.options;
        const thresholdPanels = configContext.model.get('thresholdPanels');
        if (thresholdPanels?.length) {
            configContext.typeCode += thresholdPanels[0].sequence;
        }
        return {
            configContext,
            collection: new Collection(this.getApproverSequences(this.model)),
            columns: [
                {
                    label: locale.get('PS.panelApproval.Panel'),
                    field: 'order',
                },
                {
                    label: locale.get('PS.panelApproval.Approval.Sequence'),
                    field: 'description',
                },
                {
                    label: locale.get('PS.panelApproval.Approver.Type', '1'),
                    field: 'approver1',
                },
                {
                    label: locale.get('PS.panelApproval.Approver.Type', '2'),
                    field: 'approver2',
                },
                {
                    label: locale.get('PS.panelApproval.Approver.Type', '3'),
                    field: 'approver3',
                },
                {
                    label: locale.get('PS.panelApproval.Approver.Type', '4'),
                    field: 'approver4',
                },
                {
                    label: locale.get('PS.panelApproval.Approver.Type', '5'),
                    field: 'approver5',
                },
                {
                    label: locale.get('PS.panelApproval.Approver.Type', '6'),
                    field: 'approver6',
                },
            ],
        };
    },

    getApproverSequences(model) {
        const thresholdPanels = model.get('thresholdPanels');
        if (!thresholdPanels) {
            return {};
        }
        const data = thresholdPanels.map((approverSequence, index) => util.extend({
            order: index + 1,
            description: approverSequence.sequenceDescription,
        }, this.reduceApprovers(approverSequence.approvers)));
        return data;
    },

    /**
     * Field names for the grid are based off of 'approver + index', so map the proper name
     * value to the field
     * @param {Array} approvers - array of approvers
     */
    reduceApprovers(approvers) {
        return approvers.reduce((accum, approver, index) => util.extend(
            accum,
            {
                [`approver${index + 1}`]: approver.name,
            },
        ), {});
    },
});
