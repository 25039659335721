import ItemView from '@glu/core/src/itemView';
import constants from 'app/administration/constants';
import locale from '@glu/locale';
import permCheckViewTmpl from './permCheckView.hbs';

export default ItemView.extend({
    tagName: 'li',
    template: permCheckViewTmpl,

    templateHelpers() {
        const { model } = this;
        const { mode } = this.options;

        return {
            htmlId: `cb-${this.cid}`,
            readOnly: mode === constants.MODES.VIEW,
            displayFn: mode !== constants.MODES.VIEW || model.get('entitled'),
            label: locale.get(`type.${model.get('name') || model.get('typeCode')}`),
        };
    },
});
