var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"alert-region\"></div>\n\n<form class=\"form-container user-info\">\n\n    <div>\n        <h4>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.company.select",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":54}}}))
    + "</h4>\n    </div>\n\n    <div class=\"field-container-lg required\">\n        <input type=\"hidden\" id=\"USERGROUP\" class=\"form-control usergroup-field\" name=\"USERGROUP\">\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"USERGROUP\"></span>\n    </div>\n</form>\n";
},"useData":true});