var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <!-- *************** -->\n        <!-- Begin RFI/PA input -->\n        <!-- *************** -->\n        <div class=\"RFIPA-input\" data-hook=\"getRFIPAinput\">\n            <div class=\"row\">\n                <h3 class=\"centered-heading\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"RFI_MODE") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":15,"column":27}}})) != null ? stack1 : "")
    + "                </h3>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"RFI_MODE") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":56,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowResponse") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":12},"end":{"line":80,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"rtp.requestForInfo.heading",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":24},"end":{"line":10,"column":63}}}))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"PA_MODE") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":15,"column":20}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"rtp.paymentAcknowledgement.heading",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":24},"end":{"line":12,"column":71}}}))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"allowResponse") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":15,"column":20}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"rtp.responseToRequestForInfo.heading",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":24},"end":{"line":14,"column":73}}}))
    + "\n                    ";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"row\">\n                        <div class=\"col-5\">\n                            <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rtp.requestForInfo.type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":32},"end":{"line":21,"column":68}}}))
    + "</h4>\n                        </div>\n                        <div class=\"col-7\">\n                            <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rtp.requestForInfo.reason",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":32},"end":{"line":24,"column":70}}}))
    + "</h4>\n                        </div>\n                </div>\n                <div class=\"row\">\n                    <div class=\"col-5\">\n                        <label>\n                            <input\n                                type=\"radio\"\n                                name=\"REQUESTFORINFO_REASON_FLAG_"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":32,"column":65},"end":{"line":32,"column":72}}}) : helper)))
    + "\"\n                                value=\"I\"\n                                id=\"REQUESTFORINFO_REASON_FLAG_INCORRECT_"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":34,"column":73},"end":{"line":34,"column":80}}}) : helper)))
    + "\"\n                                data-hook=\"getRequestFlagRadioBtn\"\n                                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"reasonIncorrect") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":32},"end":{"line":36,"column":71}}})) != null ? stack1 : "")
    + "\n                                "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowIncorrect") : depth0),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":32},"end":{"line":37,"column":79}}})) != null ? stack1 : "")
    + ">\n                            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rtp.requestForInfo.incorrect",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":28},"end":{"line":38,"column":69}}}))
    + "\n                        </label>\n                        <label>\n                            <input\n                                type=\"radio\"\n                                name=\"REQUESTFORINFO_REASON_FLAG_"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":43,"column":65},"end":{"line":43,"column":72}}}) : helper)))
    + "\"\n                                value=\"M\"\n                                id=\"REQUESTFORINFO_REASON_FLAG_MISSING_"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":45,"column":71},"end":{"line":45,"column":78}}}) : helper)))
    + "\"\n                                data-hook=\"getRequestFlagRadioBtn\"\n                                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"reasonMissing") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":32},"end":{"line":47,"column":69}}})) != null ? stack1 : "")
    + "\n                                "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowMissing") : depth0),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":32},"end":{"line":48,"column":77}}})) != null ? stack1 : "")
    + ">\n                            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rtp.requestForInfo.missing",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":49,"column":28},"end":{"line":49,"column":67}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"col-7\">\n                        <div data-region=\"reasonDropdownRegion\"></div>\n                    </div>\n                </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return " checked ";
},"13":function(container,depth0,helpers,partials,data) {
    return " disabled ";
},"15":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"row\">\n                    <div class=\" col-12\">\n                        <textarea\n                        class=\"form-control textarea-field\"\n                        data-hook=\"getCountField\"\n                        rows=\"2\"\n                        data-bind=\"model\"\n                        id=\"ADDITIONAL_INFO_"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":65,"column":44},"end":{"line":65,"column":51}}}) : helper)))
    + "\"\n                        name=\"ADDITIONAL_INFO\"\n                        maxlength=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"maxMessageCharacters") || (depth0 != null ? lookupProperty(depth0,"maxMessageCharacters") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"maxMessageCharacters","hash":{},"data":data,"loc":{"start":{"line":67,"column":35},"end":{"line":67,"column":59}}}) : helper)))
    + "\"\n                        placeholder=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"placeholderText") || (depth0 != null ? lookupProperty(depth0,"placeholderText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"placeholderText","hash":{},"data":data,"loc":{"start":{"line":68,"column":37},"end":{"line":68,"column":56}}}) : helper)))
    + "\">\n                        </textarea>\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"ADDITIONAL_INFO\"></span>\n                    </div>\n                </div>\n                <div class=\"row\">\n                    <div class=\"col-8 character-count\"><span data-hook=\"getCountDisplay\"></span> "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"rtp.requestForInfo.charactersRemaining",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":74,"column":97},"end":{"line":74,"column":148}}}))
    + "</div>\n                    <div class=\"col-4 send-btns\">\n                        <button type=\"button\" class=\"btn btn-tertiary\" data-hook=\"getSendMessageBtn\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"rtp.conversationDrawer.send",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":76,"column":101},"end":{"line":76,"column":141}}}))
    + "</button>\n                        <button type=\"button\" class=\"btn btn-tertiary\" data-hook=\"getCancelBtn\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"rtp.conversationDrawer.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":77,"column":96},"end":{"line":77,"column":138}}}))
    + "</button>\n                    </div>\n                </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"RFIPA-btns active\" data-hook=\"getRFIPABtns\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"conversationStarted") : depth0),{"name":"unless","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":12},"end":{"line":91,"column":23}}})) != null ? stack1 : "")
    + "            <div class=\"row conversation-buttons\">\n                <div class=\"col-12\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowRFI") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":20},"end":{"line":96,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowPA") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":20},"end":{"line":99,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"row\">\n                    <div class=\"col-12 conversation-starter\">\n                            <h2>"
    + alias2(lookupProperty(helpers,"icon").call(alias1,"chat-bubble","Start a conversation",{"name":"icon","hash":{},"data":data,"loc":{"start":{"line":88,"column":32},"end":{"line":88,"column":77}}}))
    + " "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rtp.startAConversation",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":88,"column":78},"end":{"line":88,"column":113}}}))
    + "!</h2>\n                    </div>\n                </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <button class=\"big-btn\" type=\"button\" name=\"RFIBtn\" data-hook=\"getRFIBtn\" data-msgtype=\"rfi\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"rtp.requestInformationBtn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":95,"column":121},"end":{"line":95,"column":159}}}))
    + "</button>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <button class=\"big-btn\" type=\"button\" name=\"PABtn\" data-hook=\"getPABtn\" data-msgtype=\"pa\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"rtp.sendPaymentAcknowledgement",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":98,"column":118},"end":{"line":98,"column":161}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"INPUT_MODE") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":103,"column":11}}})) != null ? stack1 : "")
    + "    <!-- *************** -->\n    <!-- End RFI/PA input -->\n    <!-- *************** -->\n</form>\n";
},"useData":true});