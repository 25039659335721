export default {
    postAuth(userInfo) {
        if (userInfo.isLoggedIn()) {
            const tenant = userInfo.get('tenantId');
            const snippedId = userInfo.get('snippedId');
            const rapportServer = userInfo.get('rapportServer');
            const pinpointUrl = userInfo.get('pinpointUrl');
            const [s] = document.getElementsByTagName('div');

            if (tenant && tenant !== 'null' && rapportServer) {
                const sn = document.createElement('script');
                sn.setAttribute('async', true);
                sn.setAttribute('type', 'text/javascript');
                sn.setAttribute('src', `${document.location.protocol === 'https:' ? 'https:' : 'http:'}//${rapportServer}/${snippedId}/splash.js`);
                s.parentNode.insertBefore(sn, s);
            }
            if (tenant && tenant !== 'null' && pinpointUrl) {
                const sn1 = document.createElement('script');
                sn1.setAttribute('async', true);
                sn1.setAttribute('type', 'text/javascript');
                sn1.setAttribute('src', `${document.location.protocol === 'https:' ? 'https:' : 'http:'}//${pinpointUrl}/${snippedId}/int564.js`);
                s.parentNode.insertBefore(sn1, s);
            }
        }
    },
};
