import RsaAdaptiveAuthModel from 'system/mfa/models/rsaAdaptiveAuth';
import BaseMFAView from 'system/mfa/views/baseMFA';
import mfaUtil from 'system/mfa/util';
import userInfo from 'etc/userInfo';
import $ from 'jquery';
import loadingModalTmpl from 'common/templates/loadingModal.hbs';
import authenticationTmpl from './authentication.hbs';

export default BaseMFAView.extend({
    template: authenticationTmpl,
    loadingTemplate: loadingModalTmpl,

    ui: {
        $challengeBody: '#rsaChallengeBody',
    },

    events: {
        'click [data-action="close"]': 'logout',
    },

    initialize(options) {
        this.listenTo(this.appBus, 'extChallenge:rsa:challenge:complete', this.handleComplete);
        this.model = new RsaAdaptiveAuthModel({
            challengedAction: options.challengedAction,
            challengeType: options.challengeType,
        });
        this.clientRef = options.clientRef;

        userInfo.set('challengedRequired', true);
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.renderVendorMarkup();
        } else {
            this.loadRequiredData();
        }
    },

    logout() {
        mfaUtil.logout();
    },

    /**
     * @method renderVendorMarkup
     * insert the html from the rest service into the challengeBody as required by vendor
     */
    renderVendorMarkup() {
        // need to be sure returned markup has a wrapping div container
        const markup = `<div>${this.model.get('RSAMarkup') || ''}</div>`;
        const parsedMarkup = $.parseHTML(markup, document, true);
        this.ui.$challengeBody.empty();
        this.ui.$challengeBody.append(parsedMarkup);
    },

    /**
     * Passes the returned MFA token or, if no token is provided,
     * the original clientRef may be passed. This pattern exists
     * for customers who do not wish to pass success/failure state
     * through the UI. The resulting server action will determine
     * the state of the MFA Challenge.
     * @method handleComplete
     * @param {Object} [callBackToken]
     */
    handleComplete(callBackToken = {}) {
        const token = callBackToken.token
            ?? callBackToken.errorToken;
        this.model.set('callbackToken', token);
        userInfo.set('challengedRequired', false);
        this.model.save({}, {
            success: this.completion.bind(this),
            error: this.cancel,
        });
    },

    /**
     * @method loadRequiredData
     */
    loadRequiredData() {
        const rsaVendorMarkupPromise = new Promise((resolve, reject) => {
            this.model.fetch({
                success: resolve,
                error: reject,
            });
        });

        Promise.all([rsaVendorMarkupPromise])
            .then(() => {
                this.setHasLoadedRequiredData(true);
                this.render();
            });
    },
});
