var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n   <form autocomplete=\"off\" data-submit=\"save\">\n      <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n      <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.selfregister.intro",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":9},"end":{"line":4,"column":49}}}))
    + "</p>\n\n      <div class=\"glu-container security-passcode\">\n         <div class=\"row\">\n            <div class=\"col-6\">\n               <div class=\"form-group required\">\n                  <label for=\"TokenSerialNumber\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.selfregister.token",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":49},"end":{"line":10,"column":88}}}))
    + "</label>\n                  <input class=\"form-control\"\n                     data-bind=\"model\"\n                     data-hook=\"getToken\"\n                     id=\"TokenSerialNumber\"\n                     name=\"TokenSerialNumber\"\n                     type=\"text\">\n                  <span class=\"help-block\" data-bind=\"model\" data-validate=\"TokenSerialNumber\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.selfregister.token.back",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":95},"end":{"line":17,"column":139}}}))
    + "</span>\n               </div>\n            </div>\n         </div>\n         <div class=\"row\">\n            <div class=\"col-6\">\n               <div class=\"form-group required\">\n                  <label for=\"activationCode\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.selfregister.code",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":46},"end":{"line":24,"column":84}}}))
    + "</label>\n                  <input class=\"form-control\"\n                     data-bind=\"model\"\n                     data-hook=\"getCode\"\n                     id=\"activationCode\"\n                     name=\"activationCode\"\n                     type=\"text\">\n                  <span class=\"help-block\" data-bind=\"model\" data-validate=\"activationCode\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.selfregister.token.front",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":92},"end":{"line":31,"column":137}}}))
    + "</span>\n               </div>\n               <button\n                    type=\"button\"\n                    class=\"btn btn-primary btn-sm\"\n                    data-action=\"submitCode\"\n                    data-hook=\"submit\"\n                >"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.submit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":17},"end":{"line":38,"column":43}}}))
    + "</button>\n               <button type=\"button\" class=\"btn btn-secondary btn-sm\" data-action=\"returnToChallenge\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":39,"column":102},"end":{"line":39,"column":128}}}))
    + "</button>\n            </div>\n         </div>\n      </div>\n   </form>\n</div>\n";
},"useData":true});