import ItemView from '@glu/core/src/itemView';
import NotesModel from 'app/reports/models/transactionNote';
import emptyViewTmpl from 'app/reports/views/lockbox/modals/notes/emptyView.hbs';

export default ItemView.extend({
    template: emptyViewTmpl,

    initialize(options) {
        this.model = new NotesModel();
        this.parent = options.parent;
        this.showButtons = options.showButtons;
    },

    events: {
        'click .toggleForm': 'toggleForm',
    },

    ui: {
        $form: '.lockbox-note-form',
    },

    submit() {
        if (this.model.validate()) {
            this.model.trigger('invalid');
            return;
        }
        this.model.action = 'save';
        this.parent.models.push(this.model);
        this.trigger(this.model.action);
    },

    templateHelper() {
        const self = this;
        return {
            showButtons: self.showButtons,
        };
    },
});
