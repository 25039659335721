import $ from 'jquery';
import locale from '@glu/locale';

export default {
    setupRCountBoxes(view, selector, $alt) {
        let localSelector = selector;
        // Support an alternate container element
        const $el = $alt || view.$el;

        if (!localSelector) {
            localSelector = '.rcount';
        }

        $el.find(localSelector).each(function () {
            const maxLength = $(this).attr('maxlength');
            const charRemainingLabel = locale.get('common.charactersRemaining');
            $(this).keyup((e) => {
                const $target = $(e.target);
                const $targetTxtVal = $target.val();
                const txtReMainLen = maxLength - $targetTxtVal.length;
                $target.parent().find('.textline-field').text(`${charRemainingLabel} ${txtReMainLen}`);
            });

            const currentValue = maxLength - $(this).val().length;
            $(this).parent().find('.textline-field').text(`${charRemainingLabel} ${currentValue}`);
        });
    },
};
