import EntryView from 'common/dynamicPages/views/workflow/entry';
import store from 'system/utilities/cache';
import util from '@glu/core/src/util';
import restoreTemplateUtil from './restoreTemplateUtil';

export default EntryView.extend(util.extend(
    {},
    restoreTemplateUtil,
    {
        initialize() {
            const context = store.get('smb_restore_context') || {};
            const options = {
                menuCategory: 'PMTS',
                serviceName: context.serviceName || 'templates/corp',
                serviceFunc: null,
                businessType: null,
                context: context.context || 'TEMP_LIST_VIEW',
                mode: 'restore',
                smbOverride: context.smbOverride,
                reimburse: context.reimburse,
            };
            EntryView.prototype.initialize.call(this, options);
        },
    },
));
