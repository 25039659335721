import Layout from '@glu/core/src/layout';
import { createTabsToggleButton, toggleTabs, setTabButtonText } from 'common/util/a11y/tabs';
import ContentView from './viewContentTab';
import GroupView from './viewGroupTab';
import navigationTmpl from './navigation.hbs';

const Navigation = Layout.extend({
    className: 'page admin-elearning',
    template: navigationTmpl,

    regions: {
        pageRegion: '.pageRegion',
        alertRegion: '.alert-region',
    },

    ui: {
        $navTabs: '[data-hook="getNavTabs"]',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
    },
    isInitialRender: true,

    events: {
        'click @ui.$navLinks': 'updateView',
    },

    initialize(options) {
        Layout.prototype.initialize.call(this, options);
        this.lastRoute = options.lastRoute;
    },

    onRender() {
        createTabsToggleButton(this);
        if (this.lastRoute !== undefined && this.lastRoute.length > 0 && this.lastRoute.indexOf('Group') > 0) {
            this.openView('#group');
        } else {
            this.showFirstEntitledView();
        }
        this.isInitialRender = false;
    },

    handleSuccess(action, message) {
        this.openView(this.previousState);
        this.renderMessage(action, message);
    },

    handleError(action, message) {
        this.renderMessage(action, message);
        const regionLoc = this.$('.alert-region').offset();
        window.scrollTo(regionLoc.left, regionLoc.top);
    },

    updateView(e) {
        const href = this.$(e.currentTarget).attr('href');
        e.preventDefault();
        this.openView(href);
        this.changeTab(href);
    },

    openView(href) {
        if (this.alertRegion) {
            this.alertRegion.close();
        }

        if (this.elearningView !== undefined && this.elearningView !== null) {
            this.stopListening(this.elearningView.remoteGridView, 'rowAction', this.gridRowAction);
        }

        switch (href) {
        case '#group':
            this.elearningView = new GroupView();
            break;
        case '#content':
            this.elearningView = new ContentView();
            break;
        default:
            this.elearningView = new ContentView();
        }
        this.pageRegion.show(this.elearningView);
        this.previousState = href;
        this.currentTabText = this.$(`a[href$=${href}]`).text();
        if (this.isInitialRender) {
            setTabButtonText(this, this.currentTabText);
        }
    },

    changeTab(e) {
        this.$(`a[href$=${e}]`).parent('li').addClass('is-active').siblings()
            .removeClass('is-active');
        this.currentTabText = this.$(`a[href$=${e}]`).text();
        toggleTabs(this);
    },

    showFirstEntitledView() {
        this.openView('#content');
    },
});

export default Navigation;
