import Collection from '@glu/core/src/collection';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';

export default Collection.extend({
    sync(method, model, options) {
        const data = this.getQueryResults();

        http.post(services.inquiryQueryResults, data, (result) => {
            options.success(result.queryResponse.QueryData.queryRows);
        }, () => {
            options.error();
        });
    },

    /**
     * Builds inquiry data.
     */
    getQueryResults() {
        const data = {
            IncludeMapData: 0,

            queryCriteria: {
                inquiryId: 23000,

                action: {
                    typeCode: 'TRANSFER',
                    productCode: 'RTGS',
                    functionCode: 'TMPL',
                },

                queryType: 'PayTmplSignQuery',
            },
        };

        return data;
    },

    parse(response) {
        return util.map(response, rowItem => ({
            CODE: rowItem.name,
            DESCRIPTION: rowItem.label,
        }));
    },
});
