import InquiryCollection from 'common/inquiry/inquiryCollection';

export default InquiryCollection.extend({
    initialize(searchFields, inquiryId) {
        this.inquiryId = inquiryId;
        this.searchFields = searchFields;
    },

    requestBody() {
        return {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    action: {
                        typeCode: '*',
                        entryMethod: 0,
                        actionMode: 'INQ',
                        functionCode: 'INQ',
                        productCode: 'PAY',
                    },

                    inquiryId: this.inquiryId,
                    searchFields: this.searchFields,
                    searchType: '5',
                },
            },
        };
    },
});
