import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import Popover from '@glu/popover-react';
import { InfoCircleAshIcon, XCircleAshIcon } from '@glu/icons-react';

const styles = ({ typography, palette }) => {
    const svgStyles = {
        '& svg': {
            fill: ({ dark }) => (dark ? palette.text.light : '#0070b9'),
        },
    };
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: ({ dark }) => (dark ? '#0072b6' : '#cbe4ff'),
            borderRadius: 15,
            padding: [5, 5, 5, 10],
            marginBottom: 10,
        },
        label: {
            ...typography.text,
            margin: 0,
            textTransform: 'uppercase',
            marginRight: 8,
            fontSize: 14,
            color: ({ dark }) => (dark ? palette.text.light : palette.text.dark),
        },
        infoIcon: {
            position: 'relative',
            top: 2,
            ...svgStyles,
        },
        deleteButton: {
            margin: 0,
            border: 0,
            padding: 0,
            background: 'none',
            ...svgStyles,
        },
    };
};

const Pill = ({
    name, label, tooltipText, canDelete, classes,
}) => {
    const actionContent = useMemo(() => {
        if (canDelete) {
            return (
                <button type="button" className={classes.deleteButton}>
                    <XCircleAshIcon />
                </button>
            );
        }
        if (!tooltipText) {
            return <span />;
        }
        return (
            <Popover content={tooltipText} openOnHover placement="auto">
                <span className={classes.infoIcon}>
                    <InfoCircleAshIcon />
                </span>
            </Popover>
        );
    }, [canDelete, classes.deleteButton, classes.infoIcon, tooltipText]);

    const onDragStart = (e) => {
        const ev = e;
        ev.dataTransfer.setData('text/plain', `{{${name}}}`);
    };

    return (
        <div draggable onDragStart={onDragStart} data-name={name} className={classes.root}>
            <p className={classes.label}>
                {label}
            </p>
            {actionContent}
        </div>
    );
};

Pill.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    tooltipText: PropTypes.string,
    canDelete: PropTypes.bool,
    // eslint-disable-next-line react/no-unused-prop-types
    dark: PropTypes.bool,
};

Pill.defaultProps = {
    tooltipText: undefined,
    canDelete: false,
    dark: false,
};

export default withStyles(styles)(Pill);
