import React from 'react';
import PropTypes from 'prop-types';

const TabsContent = (props) => {
    const { tabs } = props;
    // TODO improve this components, and pass ONLY needed components, instead all tabs.
    const TabContentComponent = tabs[props.activeTab];

    return (
        <div className="membership-subtab-content">
            <TabContentComponent {...props} />
        </div>
    );
};

TabsContent.propTypes = {
    activeTab: PropTypes.string.isRequired,
    // tabs: PropTypes.objectOf(PropTypes.func).isRequired
    tabs: PropTypes.shape({}).isRequired,
};

export default TabsContent;
