import $ from 'jquery';
import util from '@glu/core/src/util';
import Layout from '@glu/core/src/layout';
import Formatter from 'system/utilities/format';
import userInfo from 'etc/userInfo';
import 'jui/draggable';
import droppedTileItemTmpl from './droppedTileItem.hbs';

export default Layout.extend({
    initialize() {
        $(this.el).draggable({
            // clicking an icon won't initiate dragging
            cancel: 'a.ui-icon',

            // when not dropped, the item will revert back to its initial position
            revert: 'invalid',

            /*
             * containment: 'document',
             * helper: 'clone',
             */
            cursor: 'move',
        });

        $(this.el).data('backbone-view', this);
    },

    template: droppedTileItemTmpl,
    tagName: 'li',

    regions: {
        currentAmountWarning: '.icon-warning',
    },

    ui: {
        $viewBillLink: '.view-bill',
        $dueDateContainer: '.due-date-container',
    },

    events: {
        'click @ui.$viewBillLink': 'viewBill',
    },

    getFormattedAmount(value) {
        /*
         * use the localized formatter in the util object which allows
         * localization formatting by user and other preferences
         */
        return Formatter.formatCurrency(value);
    },

    templateHelpers() {
        return {
            beneficiary: util.findWhere(
                this.options.model.get('columns'),
                {
                    fieldName: 'VENDORNAME',
                },
            ).fieldValue,

            paymentAmount: this.getFormattedAmount(util.findWhere(
                this.options.model.get('columns'),
                {
                    fieldName: 'TOTALAMOUNT',
                },
            ).fieldValue),

            accountNumber: util.findWhere(
                this.options.model.get('columns'),
                {
                    fieldName: 'VENDORID',
                },
            ).fieldValue,

            preferredFormat: userInfo.getCurrencyFormat(),
        };
    },

    renderDatePickerContent() {
        // TODO use nhDatePicker or Glu replacement
    },

    onRender() {
        this.renderDatePickerContent();
    },

    onShow() {
        $('.input-date ').click(() => {
            // self.hideAlertIcon();
        });
    },
});
