import TableFooterLayout from 'no-override!@glu/grid/src/footer/tableFooterLayout';

export default TableFooterLayout.extend({
    initialize() {
        TableFooterLayout.prototype.initialize.call(this);
        this.listenTo(this.grid, 'grid:contentRendered', () => {
            /*
             * NH-179424 When scrolling modals, need to update the
             * footer position of the grid inside, so that the footer
             * does not remain stuck at the bottom of the modal.
             */
            const $modalDialog = this.getModal('.modal-dialog');
            if ($modalDialog.length) {
                $modalDialog.find('.modal-body').on('scroll', () => this.updateFooterPosition());
            }
            this.updateFooterPosition();
        });
    },

    /**
     * Find the closest modal, based on the modalSelector, to the tbody
     * @param {string} modalSelector
     * @returns
     */
    getModal(modalSelector) {
        const $tableBody = this.grid.$el.find('tbody');
        return $tableBody.closest(modalSelector);
    },

    /**
     * call the parent function
     * update sticky footer position in modals with scrolling content
     * @method updateFooterPosition
     */
    updateFooterPosition() {
        TableFooterLayout.prototype.updateFooterPosition.call(this);

        const $modalDialog = this.getModal('.modal-dialog');
        const $reactModalDialog = this.getModal('[class^="card-"');
        const $stickyFooter = $modalDialog.length ? $modalDialog.find('.sticky-footer')
            : $reactModalDialog.find('.sticky-footer');
        const isInModal = $modalDialog.length || $reactModalDialog.length;

        if (!this.isClosed && this.hasStickyFooter && isInModal) {
            const modalFooterHeight = $modalDialog.length ?
                $modalDialog.find('.modal-footer').innerHeight()
                : $reactModalDialog.find('.footer').innerHeight();

            let bottom;
            if ($modalDialog.length) {
                const modalBottomOffset = window.innerHeight - ($modalDialog[0].offsetTop
                + $modalDialog[0].offsetHeight);
                bottom = modalFooterHeight + modalBottomOffset;
            } else {
                // in a react modal, just account for controls area
                bottom = 85;
            }
            $stickyFooter.css({
                bottom,
            });
        }
    },
});
