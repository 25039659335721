{{#if hasBreadcrumb}}
    <ul class="item-breadcrumb">
        <li><a href="#">All</a></li>
        {{#each breadcrumb}}
            <li data-parent-id="{{id}}"><a href="#">{{label}}</a></li>
        {{/each}}
    </ul>
{{/if}}

{{#if tableView}}
    <div class="spinner"></div>
    <table class="table-view-dropdown">
        {{#if columnTitles}}
        <thead>
            <tr class="title-row">
            {{#if multiSelect}}
                <td></td>
            {{/if}}
            {{#each columnTitles}}
                <th scope="col">{{#if this}}{{this}}{{/if}}</th>
            {{/each}}
            </tr>
        </thead>
        {{/if}}
        <tbody class="item-list"></tbody>
    </table>
{{else}}
	{{#if hasChildList}}
		<div class="item-list"></div>
	{{else}}
		<div>
			<ul class="item-list"></ul>
		</div>
	{{/if}}
{{/if}}

<div class="button-holder">
{{#if enableMultiButton}}
    <button type="button" class="btn btn-secondary done">{{locale "done"}}</button>
{{/if}}
{{#if clearBtn}}
    <button type="button" class="btn btn-secondary clear">{{locale "clear"}}</button>
{{/if}}
{{#if selectAllBtn}}
    <button type="button" class="btn btn-primary select-all">{{locale "selectAll"}}</button>
{{/if}}
</div>
