import ItemView from '@glu/core/src/itemView';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import CommentView from '../modals/comment';
import datePicker from '../datePicker';
import addRowTmpl from './addRow.hbs';

export default ItemView.extend({
    template: addRowTmpl,
    tagName: 'tr',
    className: 'add-payment',
    binding: true,
    editMode: false,

    initialize(options) {
        if (options.model.editRow) {
            this.editMode = true;
            this.model = options.model.clone();
            this.originalModel = options.model;
        }

        this.fromLoans = options.editOptions.fromLoans;
        this.toAccounts = options.editOptions.toAccounts;
        this.blockedDates = options.editOptions.blockedDates;
        this.maxDate = options.editOptions.maxDate;
        this.cutoffTimes = options.editOptions.cutoffTimes;
    },

    onRender() {
        this.ui.$fromLoan.comboBox({
            placeholder: locale.get('smb.loan.select'),
            allowClear: true,
        });

        this.ui.$toAccount.comboBox({
            placeholder: locale.get('smb.account.select2'),
            allowClear: true,
        });

        this.ui.$paymentDateWrap.html(datePicker.create(
            null,
            this.blockedDates,
            this.cutoffTimes,
            this.maxDate,
        ));

        if (this.editMode) {
            this.ui.$fromLoan.comboBox('val', this.model.get('fromLoanId'));
            this.ui.$toAccount.comboBox('val', this.model.get('toAccountId'));
            this.ui.$paymentDateWrap.find('.input-date').val(this.model.get('paymentDate'));
        } else {
            this.ui.$paymentDateWrap.find('.input-date').val(datePicker.defaultDate());
        }

        const self = this;
        this.ui.$fromLoan.on('change', (e) => {
            self.setBalances(e.val, self.fromLoans, 'fromLoanBalance');
        });

        this.ui.$toAccount.on('change', (e) => {
            self.setBalances(e.val, self.toAccounts, 'toAccBalance');
        });

        this.updateCommentButtonText();
    },

    ui: {
        $fromLoan: '[data-hook="from-loan"]',
        $toAccount: '[data-hook="to-account"]',
        $commentLink: '[data-hook="commentButton"]',
        $paymentDateWrap: '.payment-grid-header-input.payment-date',
        $fromBalLabel: '[data-hook="fromBalLabel"]',
        $toBalLabel: '[data-hook="toBalLabel"]',
        $fromLnBalance: '[data-hook="fromLnBalance"]',
        $toAccBalance: '[data-hook="toAccBalance"]',
        $saveButton: '[data-action="save"]',
        $cancelButton: '[data-action="delete"]',
        $amount: 'input#amount',
    },

    templateHelpers() {
        return {
            fromLoans: this.fromLoans,
            toAccounts: this.toAccounts,
            notEditMode: !this.editMode,
            modelId: this.model.cid,
        };
    },

    onShow() {
        if (this.editMode) {
            this.setBalances(this.model.get('fromLoanId'), this.fromLoans, 'fromLoanBalance');
            this.setBalances(this.model.get('toAccountId'), this.toAccounts, 'toAccBalance');
            this.ui.$saveButton.find('span').removeClass('icon-plus').addClass('icon-tick');
            this.ui.$cancelButton.attr({
                title: 'Cancel',
            });
        }
    },

    // add mode (add row) function; fired in baseAddGrid
    add(e) {
        const self = this;

        if (e) {
            e.preventDefault();
        }

        this.model.set('paymentDate', datePicker.setModelDate());

        const isValid = this.model.isValid();

        // store off a copy
        this.originalModel = this.model;
        if (isValid) {
            this.trigger('save', this.model, (result) => {
                if (result) {
                    self.clear();
                }
            });
        }
    },

    // add mode (add row) function; fired in baseAddGrid
    clear() {
        const self = this;
        this.trigger('clear', this.model, (result) => {
            if (result) {
                self.resetElements();
            }
        });
    },

    // edit mode function
    save(e) {
        if (e) {
            e.preventDefault();
        }

        this.model.set('paymentDate', datePicker.setModelDate());

        const isValid = this.model.isValid();

        if (isValid) {
            this.copyPropertiesDuringSave();
            this.model = this.originalModel;
            this.trigger('save');
            this.appBus.trigger('sbPayments:paymentListUpdated');
        }
    },

    // edit mode function
    cancel(e) {
        if (e) {
            e.preventDefault();
        }

        this.model = this.originalModel;
        this.trigger('save');
        this.appBus.trigger('sbPayments:paymentListUpdated');
    },

    resetElements() {
        this.ui.$fromBalLabel.empty();
        this.ui.$toBalLabel.empty();
        this.ui.$fromLoan.comboBox({
            placeholder: locale.get('smb.loan.select'),
            allowClear: true,
        });

        this.ui.$toAccount.comboBox({
            placeholder: locale.get('smb.account.select2'),
            allowClear: true,
        });
        this.ui.$paymentDateWrap.find('.input-date').val(datePicker.defaultDate());
        this.resetValidationMessages(null);
        this.updateCommentButtonText();
    },

    resetValidationMessages(message) {
        if (!message) {
            util.each(this.$el.find('[class="help-block"],.date-error'), (el) => {
                this.$(el).empty();
            });
        } else {
            this.$el.find(message).empty();
        }
    },

    copyPropertiesDuringSave() {
        this.originalModel.set(this.model.attributes);
    },

    updateCommentButtonText() {
        this.ui.$commentLink.val(this.model.get('comment').isEmpty() ? locale.get('sbPayments.commentAdd') : locale.get('sbPayments.commentEdit'));
    },

    popComment() {
        const self = this;
        const commentModal = new CommentView({
            model: this.model.get('comment'),
        });

        commentModal.on('save', (model) => {
            self.model.set('comment', model);
            self.updateCommentButtonText();
        }, this);

        dialog.custom(commentModal);
    },

    // get an attribute of the account with a particular id in an account list
    getAccountAttr(acctList, id, attr) {
        return util.findWhere(
            acctList,
            {
                id,
            },
        )[attr];
    },

    setBalances(accountId, accounts, balanceLabel) {
        let balance = '';
        if (accountId) {
            const selectedBalance = this.getAccountAttr(accounts, accountId, 'balance');
            balance = util.isEmpty(selectedBalance) ? locale.get('sbPayments.notAvailable') : selectedBalance;
        }
        let $balanceLabel;
        let $accBalance;
        if (balanceLabel === 'fromLoanBalance') {
            $balanceLabel = this.ui.$fromBalLabel;
            $accBalance = this.ui.$fromLnBalance;
        } else {
            $balanceLabel = this.ui.$toBalLabel;
            $accBalance = this.ui.$toAccBalance;
        }
        if (!util.isEmpty(balance)) {
            $balanceLabel.text(`${locale.get('sbPayments.balance')}:`);
            $accBalance.text(this.model.displayBalance(balance));
        } else {
            $balanceLabel.text('');
            $accBalance.text('');
        }
    },
});
