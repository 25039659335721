import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import Collection from '@glu/core/src/collection';
import DashboardLayout from 'dashboard/js/DashboardLayout';
import WidgetInjector from 'dashboard/js/widget/WidgetInjector';
import SurrogateWidget from 'dashboard/js/widget/surrogate/SurrogateWidget';
import ListView from 'common/dynamicPages/views/workflow/list';
import fakeWorkspaceTmpl from './fakeWorkspace.hbs';

const FakeWorkspace = Layout.extend({
    template: fakeWorkspaceTmpl,

    initialize(optionsParam) {
        const options = optionsParam;
        if (!options.title) {
            options.title = '';
        }
        this.widgetLib = [];
        this.dashboard = new DashboardLayout();
        this.injector = new WidgetInjector({
            collection: new Collection(this.widgetLib),
        });
    },

    onRender() {
        const self = this;
        const swidget = new SurrogateWidget({
            model: new Model({
                title: self.options.title,
                did: 0,
                wid: 21,
                size: 'large',
            }),
        });

        if (self.options.listView) {
            swidget.setView(self.options.listView);
        } else {
            swidget.setView(new ListView(self.options));
        }
        self.dashboard.inject(swidget);
        self.header.show(self.injector);
        self.workbench.show(self.dashboard);
    },

    templateHelpers() {
        const self = this;
        return {
            title() {
                return self.options.title;
            },
        };
    },

    back() {
        this.navigateTo(this.options.returnRoute);
    },
});

export default FakeWorkspace;
