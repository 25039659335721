/**
 * route configuration for transfers
 */
export default ({
    'PAYMENTS/listTransfers(/)(:viewId)': 'listTransfers',
    'PAYMENTS/listTransferTemplates(/)(:viewId)': 'listTransferTemplates',
    'PAYMENTS/listRecurringTransfers(/)(:viewId)': 'listRecurringTransfers',
    'PAYMENTS/addTransfer/(:type)': 'addTransfer',
    'PAYMENTS/addTransferTemplate/(:type)': 'addTransferTemplate',
    'PAYMENTS/addTransferByTemplate/(:templateId)': 'addTransferByTemplate',
    'PAYMENTS/addSingleTransferByTemplate/(:templateId)': 'addSingleTransferByTemplate',
    'PAYMENTS/viewTransferSet/(:templateId)': 'viewTransferSet',
    'PAYMENTS/scheduleTransfer': 'scheduleTransfer',
    'PAYMENTS/modifyTransferSet/(:templateId)': 'modifyTransferSet',
    'PAYMENTS/restoreTransferSet/(:templateId)': 'restoreTransferSet',
    'PAYMENTS/viewTransfer': 'viewTransfer',
    'PAYMENTS/modifyRecurring/(:templateId)': 'modifyRecurring',
    'PAYMENTS/restoreRecurring/(:templateId)': 'restoreRecurring',
    'PAYMENTS/viewRecurring/(:templateId)': 'viewRecurring',
    'PAYMENTS/modifyTransfer': 'modifyTransfer',
    'PAYMENTS/repairTransfer': 'repairTransfer',
    'TEMPLATES/viewTransferTemplate': 'viewTransferTemplate',
    'TEMPLATES/modifyTransferTemplate': 'modifyTransferTemplate',
    'TEMPLATES/restoreTransferTemplate': 'restoreTransferTemplate',
    'TEMPLATES/viewSingleTransferTemplate/(:templateId)': 'viewSingleTransferTemplate',
    'TEMPLATES/modifySingleTransferTemplate/(:templateId)': 'modifySingleTransferTemplate',
    'TEMPLATES/restoreSingleTransferTemplate/(:templateId)': 'restoreSingleTransferTemplate',
    'TEMPLATES/addTransferTemplateFromTemplate': 'addTransferTemplateFromTemplate',
    'TEMPLATES/addSingleTemplateFromTemplate/(:templateId)': 'addSingleTemplateFromTemplate',
    'PAYMENTS/addTransferPaymentFromTemplate': 'addTransferPaymentFromTemplate',
    'PAYMENTS/deepLinkViewTransferPayment': 'deepLinkViewTransferPayment',
});
