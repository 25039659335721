import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import services from 'services';
import userInfo from 'etc/userInfo';
import MasterTabs from '../../collection/user/masterTabs';

export default Model.extend({

    defaults() {
        return {
            masterTabs: new MasterTabs(),
            userInfo: {},
        };
    },

    sync(method, model, options) {
        const requestData = {};
        let currentUserGroup;
        switch (method) {
        case 'read':
            currentUserGroup = userInfo.get('group');

            if (options && options.userGroup) {
                requestData.userGroup = options.userGroup;
                currentUserGroup = options.userGroup;
            }

            if (options && options.userId) {
                requestData.updateCount = '0';
                requestData.userId = options.userId;
                http.post(services.generateUrl('/users/userCentric/read'), requestData, options.success, options.error);
            } else {
                http.post(services.generateUrl(`/users/userCentric/getUserEntitlementsMaintenanceModel/${currentUserGroup}`), requestData, options.success, options.error);
            }

            break;

        case 'create':
        case 'update':
            http.post(services.userCentric + (options.isModify ? 'update' : 'add'), model.toJSON(), options.success, options.error);
            break;
        case 'delete':
            break;
        default:
            break;
        }
    },

    parse(response) {
        response.masterTabs = new MasterTabs(response.masterTabs);
        return response;
    },
});
