var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = (lookupProperty(helpers,"gridUtilitySection")||(depth0 && lookupProperty(depth0,"gridUtilitySection"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"gridUtilityOptions") : depth0),{"name":"gridUtilitySection","hash":{},"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":59}}})) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"gridUtilitySection") || (depth0 != null ? lookupProperty(depth0,"gridUtilitySection") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"gridUtilitySection","hash":{},"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":40}}}) : helper))) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"tknmgmt.title",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":57}}}))
    + "</h1>\n</div>\n<div class=\"section section-container token-management\">\n    <div class=\"section-header\">\n        <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"tknmgmt.header",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":39}}}))
    + "</h2>\n    </div>\n    <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n    <div class=\"section-body\">\n        <div class=\"btn-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasAssignEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":15,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"grid-region\" data-region=\"gridRegion\"></div>\n    </div>\n</div>\n";
},"useData":true});