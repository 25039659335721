import CompositeView from '@glu/core/src/compositeView';
import Collection from '@glu/core/src/collection';
import DataCard from 'common/dataCard/views/dataCard';
import template from './dataCardCompositeView.hbs';

export default CompositeView.extend({
    tagName: 'li',
    className: 'col-md-3 payment-totals__grouping',
    template,
    itemView: DataCard,
    itemViewContainer: 'ul',
    initialize(options) {
        this.collection = new Collection(options.model.get('totalTiles'));
    },
});
