import Decimals from 'common/dynamicPages/api/decimals';
import number from 'numeral';
import Formatter from 'system/utilities/format';
import $ from 'jquery';
import locale from '@glu/locale';
import dateUtil from 'common/util/dateUtil';

export default function (form, initialState) {
    /**
     * Sets up the summary section for non-insert action modes
     * Example: 4.00 USD to 1 Beneficiary on 29 Jan 2019
     */
    const setupSummarySection = function (model) {
        const $paymentSummaryContainer = $('[data-section="payment-summary"]');
        const $amountFields = $paymentSummaryContainer.find('[data-field="summary-amount"]');
        const $currencyFields = $paymentSummaryContainer.find('[data-field="summary-currency"]');
        const creditAmount = model.get('TOTAL_INTERBANK_SETTLE_AMT');
        const creditCurrency = model.get('TOTAL_INTERBANK_SETTLE_CCY');
        const beneficiaryName = model.get('CREDITOR_NAME');
        const valueDate = model.get('INTERBANK_SETTLE_DATE');

        // Set the transaction descripition field on the screen which just field on the screen
        $('[name="TRANSACTION_DESCRIPTION"]').text(locale.get('RTP.INST.DBTRAN.*.TRANSACTION_DESCRIPTION.text'));

        // Update the summary sections visibility
        $paymentSummaryContainer.removeClass('hidden');
        $paymentSummaryContainer.filter('.section-header').children().first().addClass('hidden');

        // Set the currency code
        $currencyFields.text(creditCurrency);

        // Format and set the amount
        const paymentTotalFormatted = number(creditAmount || 0)
            .format(Decimals.getCurrencyFormat(creditCurrency));
        $amountFields.text(paymentTotalFormatted);

        const formattedDate = Formatter.formatDateFromUserFormat(
            valueDate,
            dateUtil.PAYMENT_SUMMARY_DATE_FORMAT,
        );
        // update the summary total line at the bottom in the summary section
        const params = [{
            value: paymentTotalFormatted,
            className: 'summary-large-text',
        }, {
            value: creditCurrency,
        }, {
            value: '',
        }, {
            value: beneficiaryName,
        }, {
            value: formattedDate,
        }];
        form.formView.appBus.trigger(
            `update:localeMessage:${form.formView.summaryLocaleKey}`,
            params,
        );
    };

    if (initialState) {
        const { model } = form.formView;
        const actionMode = form.formView.state;

        setupSummarySection(model);

        // Hide optional amount fields if they are empty instead of displaying 0.00
        if (actionMode === 'view' && !model.get('FULL_AMOUNT')) {
            form.formView.$('#FULL_AMOUNT').hide();
            form.formView.$('#DISCOUNT_AMOUNT').hide();
        }
    }
}
