import CollectionView from '@glu/core/src/collectionView';
import GroupListView from './paymentType';

export default CollectionView.extend({
    tagName: 'div',
    itemView: GroupListView,

    initialize(options) {
        this.mode = options.mode;
        this.paymentTypeGroup = options.paymentTypeGroup;
        this.userGroup = options.userGroup;
        this.isRole = options.isRole;
        this.hasPanelApproval = options.hasPanelApproval;
        this.allSelected = options.allSelected;
    },

    itemViewOptions() {
        return {
            paymentTypeGroup: this.paymentTypeGroup,
            mode: this.mode,
            userGroup: this.userGroup,
            isRole: this.isRole,
            hasPanelApproval: this.hasPanelApproval,
            allSelected: this.allSelected,
        };
    },
});
