import EntryView from 'app/administration/common/dynamicPages/views/workflow/entry';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import DataApi from 'common/dynamicPages/api/data';
import Constants from 'app/administration/constants';
import PageApi from 'app/administration/common/dynamicPages/api/view';
import gridApi from 'common/dynamicPages/api/grid';
import BABConstants from 'app/bab/constants';
import PassThroughDetailView from 'app/administration/views/paymentManagement/passThroughDetails';
import AddressBookAPI from 'app/administration/views/fileImportHistory/bab/addressBook';
import ContactView from 'app/administration/views/fileImportHistory/bab/viewContact';
import transform from 'common/util/transform';
import Model from '@glu/core/src/model';
import { appBus } from '@glu/core';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import importViewTmpl from './importView.hbs';

export default EntryView.extend({
    template: importViewTmpl,

    initialize(options) {
        this.opts = {
            menuCategory: 'REPORTING',
            serviceName: this.getServiceName(),
            serviceFunc: null,
            businessType: null,
            context: this.getContext(),
            mode: 'view',
        };
        EntryView.prototype.initialize.call(this, this.opts);
        this.dialogTitle = this.getTitle();
        this.model = options.model;

        this.dialogButtons = [{
            text: locale.get('administration.close.btn'),
            className: 'btn btn-primary',
            callback: 'cancel',
        }];
    },

    getContext() {
        return (this.model && this.model.get('PRODUCT') === 'CM') ? 'CM_IV_LIST' : 'PAY_LIST_VIEW';
    },

    getServiceName() {
        let serviceName = 'adminPayment/listView/payments';
        const type = this.model.get('TYPE');
        const stopImportServiceName = 'adminCM/cm/stopCancels';
        const cminstServiceName = 'adminCM/cminst/issueVoids';
        if (this.model.get('PRODUCT') === 'CM') {
            serviceName = (type === 'STOP' || type === 'CANCEL') ? stopImportServiceName : cminstServiceName;
        }
        return serviceName;
    },

    onRender() {
        this.loadPage();
    },

    loadPage() {
        const self = this;
        if (this.hasLoadedRequiredData()) {
            if (this.model.get('TYPE') === 'BENEADBK') {
                self.model.set('id', self.model.get('TNUM'));
                store.set(BABConstants.STORE_BAB_CONTEXT, self.model.context);
                store.set(BABConstants.STORE_BAB_VIEWMODEL, self.model);
                this.beneViewContact();
            } else if (this.model.get('TYPE') === 'PASSTHRU' || this.model.get('TYPE') === 'BEFTPASS') {
                self.model.buttons = [];
                store.set('SURROGATETNUM', self.model.get('TNUM'));
                store.set('passthru_view_payment_model', self.storedModel);
                this.pageView = new PassThroughDetailView({
                    isModal: true,
                });
                this.pageContent.show(this.pageView);
            } else {
                this.contextDef.serviceName = this.getServiceName();
                this.pageView = PageApi.page.get({
                    context: self.model.context,
                    model: self.storedModel,
                    state: 'view',
                    hideButtons: true,
                    gridApi,
                });
                this.pageContent.show(this.pageView);
            }
        } else {
            this.loadRequiredData();
        }
    },

    beneViewContact() {
        const model = store.get(BABConstants.STORE_BAB_VIEWMODEL);
        const addrAPI = new AddressBookAPI();
        const newModel = new Model(util.pick(model.attributes, 'TNUM', 'PRODUCT', 'FUNCTION', 'TYPE'));
        const self = this;

        addrAPI.read(newModel).then((result) => {
            const data = transform.pairsToHash(result.item);

            self.pageView = new ContactView({
                mode: 'view',
                data,
                state: 'modal',
            });
            self.pageContent.show(self.pageView);
        }, (result) => {
            dialog.alert(result);
            appBus.trigger('router:navigate', 'SETUP/viewBABContact', true);
        });
    },

    loadRequiredData() {
        const self = this;
        const { model } = this;
        const actData = {
            productCode: model.get('PRODUCT'),
            functionCode: model.get('FUNCTION'),
            typeCode: model.get('TYPE'),
            subType: model.get('SUBTYPE'),
            entryMethod: model.get('ENTRYMETHOD'),
            entryClass: model.get('ENTRYCLASS'),
        };
        const actContext = {
            actionMode: 'SELECT',
            productCode: model.get('PRODUCT'),
            functionCode: model.get('FUNCTION'),
            typeCode: model.get('TYPE'),
            subType: model.get('SUBTYPE'),
            entryMethod: model.get('ENTRYMETHOD'),
            entryClass: model.get('ENTRYCLASS'),
        };
        const keyListGlobal = ['TNUM'];
        const contextData = {
            actionContext: actContext,
            actionData: actData,
            actionMode: 'view',
            keyList: keyListGlobal,
            serviceName: this.getServiceName(),
            subType: model.get('SUBTYPE'),
        };

        model.context = contextData;

        if (model.get('TYPE') !== 'BENEADBK') {
            DataApi.model.generateModelFromModel(model).then((newModelParam) => {
                const newModel = newModelParam;
                newModel.set('PRODUCTCODE', newModel.get('PRODUCT'));
                newModel.set('FUNCTIONCODE', newModel.get('FUNCTION'));
                newModel.set('TYPECODE', newModel.get('TYPE'));
                newModel.set('SUBTYPE', newModel.get('SUBTYPE'));

                newModel.key.PRODUCTCODE = 'true';
                newModel.key.FUNCTIONCODE = 'true';
                newModel.key.TYPECODE = 'true';
                newModel.key.SUBTYPE = 'true';

                newModel.convertModelAttributesToKeyJSON = util.bind(
                    self.convertModelAttributesToKeyJSON,
                    newModel,
                );

                // Overide Visible setting if transfer time is disabled
                if (applicationConfigParams.getValue('TRANSFER', 'ENABLETRANSFERTIME') !== '1' && model.get('TYPE') === 'TRANSFER') {
                    newModel.fieldData.TRANSFER_TIME.visible = false;
                }

                newModel.fetch({
                    success() {
                        self.storedModel = newModel;
                        self.setHasLoadedRequiredData(true);
                        self.render();
                    },
                });
            });
        } else {
            self.setHasLoadedRequiredData(true);
            self.render();
        }
    },

    modalClass: 'modal-xlg',

    getTitle() {
        const { model } = this;
        const typeDesc = model.get('TYPEDESC');
        const type = model.get('TYPE');
        const beneficiary = model.get('BENEFICIARY');
        let str1 = beneficiary;

        if (type === 'BENEADBK') {
            return beneficiary;
        }
        if (util.contains(['CIMINSTT', 'STCAIMPT', 'STOP', 'CANCEL'], type)) {
            return locale.get(typeDesc);
        }
        if (beneficiary === 'MULTI') {
            str1 = locale.get('fih.MULTI');
        }
        return typeDesc + Constants.SPACE + locale.get('fih.TO') + Constants.SPACE + str1;
    },

    /*
     * We have to override this method to prevent the read request from having a nested
     * item array
     */
    convertModelAttributesToKeyJSON(model) {
        return this.convertModelAttributesToKeyWithOptContextJSON(model);
    },

    cancel() {
        dialog.close();
    },
});
