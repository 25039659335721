import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import { SavedViews } from '@glu/data-components';

import styles from './MobileGridSavedViews.styles';

function defaultConvertor(view) {
  const { columns: allColumnData = [], filters = {}, sort = {} } = view;
  const columns = allColumnData.map(c => ({
    field: c.field || c.fieldId,
    hide: c.hide,
    primary: c.primary
  }));
  return { columns, filters, sort };
}

const MobileGridSavedViews = ({ classes, ...props }) => (
  <div className={classes.root}>
    <SavedViews comparisonConvertor={defaultConvertor} {...props} />
  </div>
);

MobileGridSavedViews.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired
};

export default withStyles(styles)(MobileGridSavedViews);
