import { createUseStyles } from '@glu/theming';

const styles = {
    section: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: [-15, 0, 0, 200],
        '@media (max-width: 768px)': {
            marginLeft: 20,
            marginTop: 10,
        },
        '@media (min-width: 768px)': {
            '&.with-toa-dropdown': {
                margin: [-15, 0, 0, 400],
            },
        },
    },
    wrapper: {
        display: 'flex',
        paddingBottom: 15,
        overflowX: 'auto',
        /** START: Keeping scrollbar visible */
        '&::-webkit-scrollbar': {
            width: '1em',
        },
        '&::-webkit-scrollbar:vertical': {
            display: 'none',
        },
        '&::-webkit-scrollbar:horizontal': {
            height: 5,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: 'rgba(0, 0, 0, .5)',
        },
        /** END: Keeping scrollbar visible */
    },
    container: {
        display: 'flex',
    },
};

export default createUseStyles(styles);
