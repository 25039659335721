import locale from '@glu/locale';
import util from '@glu/core/src/util';
import $ from 'jquery';
import loadingModalTmpl from 'common/templates/loadingModal.hbs';
import branding from 'etc/branding';
import BaseMFAView from 'system/mfa/views/baseMFA';
import Constants from 'system/mfa/constants';
import ChallengeSettingsModel from 'system/mfa/models/challengeSettings';

export default BaseMFAView.extend({
    template: loadingModalTmpl,
    modalClass: 'modal-xlg dialog-warning mfa-challenge-modal',
    dialogTitle: ' ',

    onRender() {
        this.trigger('dialog:title:change', locale.get('mfa.rsa.authorization.required'));
        if (this.options.request && this.options.request.data
            && this.options.request.data.userId && this.options.request.data.userGroup) {
            this.fetchChallengeSettings({
                userRequestingOnBehalfOf: {
                    userGroup: this.options.request.data.userGroup,
                    userId: this.options.request.data.userId,
                },
            });
        } else {
            this.fetchChallengeSettings();
        }
    },

    fetchChallengeSettings(additionalRequestData) {
        branding.apply(() => {}, true);

        const modelAttrs = {
            challengedAction: this.options.challengedAction,
        };

        if (additionalRequestData) {
            util.extend(modelAttrs, additionalRequestData);
        }

        const challengeSettings = new ChallengeSettingsModel(modelAttrs);

        challengeSettings.fetch({
            success: () => {
                const ChallengeView = challengeSettings.get('viewClass');
                this.options.challengeSettings = challengeSettings;
                if (challengeSettings.get('challengeType') === Constants.EXT_CHALLENGE_TYPE
                    || challengeSettings.get('challengeType') === Constants.RSA_AA_CHALLENGE_TYPE) {
                    $('body').find('.modal-dialog').addClass('externalMfa');
                }
                this.options = util.extend(this.options, {
                    challengeType: challengeSettings.get('challengeType'),
                });
                const newView = new ChallengeView(this.options);
                this.trigger('dialog:view:change', newView);
            },

            error: util.bind(this.handleMFAError, this),
        });
    },

    /* display the warning icon in the header of the modal */
    getHeaderIcon() {
        return 'warning';
    },
});
