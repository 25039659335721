import util from '@glu/core/src/util';
import ListView from 'common/dynamicPages/views/workflow/list';
import constants from 'common/dynamicPages/api/constants';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import store from 'system/utilities/cache';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import workspaceHelper from 'common/workspaces/api/helper';
import template from './listView.hbs';

const REPORTING = 'REPORTING';

const StopCancelsList = ListView.extend({
    template,

    events: {
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
        'click [data-hook="refresh-button"]': 'refreshData',
    },

    initialize(options) {
        const superOptions = {
            menuCategory: REPORTING,
            serviceName: null,
            serviceFunc: null,
            businessType: null,
            enableSavedViews: true,
            context: 'PAY_CM_IV_LIST',
            returnRoute: `${REPORTING}/viewStopCancels`,
            viewId: options.viewId,
            selector: 'none',
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    templateHelpers() {
        const self = this;

        self.localeKey = 'menuitem.cm.stopList';

        return {
            getString(type) {
                if (type === 'title') {
                    return locale.get('menuitem.cm.stopList');
                }
                if (type === 'section-header-list') {
                    return locale.get('listview.cm.stopList');
                }
                return locale.get(self.localeKey + type);
            },

            getButtonString(type) {
                const key = `${self.localeKey}.button_${type}`;
                let retStr = locale.get(key);
                if (retStr === (`??${key}??`)) {
                    retStr = locale.get(`${self.localeKey.split('.')[0]}.button_${type}`);
                }
                return retStr;
            },

            context() {
                return self.contextKey;
            },

            hasInsertEntitlement() {
                return self.hasEntitlement(constants.ACTION_INSERT);
            },

            hasApproveEntitlement() {
                return self.hasEntitlement(constants.ACTION_APPROVE);
            },

            hasUnapproveEntitlement() {
                return self.hasEntitlement(constants.ACTION_UNAPPROVE);
            },

            hasRejectEntitlement() {
                return self.hasEntitlement(constants.ACTION_REJECT);
            },

            hasDeleteEntitlement() {
                return self.hasEntitlement(constants.ACTION_DELETE);
            },

            hasBulkActionEntitlements() {
                // return true if the user is entitled to perform bulk actions on grid rows
                return self.hasEntitlement(constants.ACTION_APPROVE)
                    || self.hasEntitlement(constants.ACTION_UNAPPROVE)
                    || self.hasEntitlement(constants.ACTION_REJECT)
                    || self.hasEntitlement(constants.ACTION_DELETE);
            },
        };
    },

    export() {
        const options = {
            format: 'CSV',
            gridView: this.gridView,
        };

        this.buildExportModel(options);
        this.doExport(options);
    },

    print() {
        const options = {
            format: 'PDF',
            gridView: this.gridView,
        };

        this.buildExportModel(options);
        const printModal = new PrintViewModal({
            exportModel: this.exportModel,
            title: 'menuitem.cm.stopList',
        });
        dialog.custom(printModal);
    },

    buildExportModel(format) {
        ListView.prototype.buildExportModel.call(this, format);
        this.exportModel.inquiryId = '20577';
    },

    gridRowSelect(options) {
        if (options.action === 'select' && options.model.get('TYPE') === 'STOP' && options.model.get('SERIALNUM_TO') && options.model.get('SERIALNUM_TO') !== '' && ['Bank Confirmed', 'Rejected', 'Partial Success'].indexOf(options.model.get('STATUS_DESCRIPTION')) > -1) {
            store.set(`${this.contextKey}-actionModel`, options.model);
            this.navigateTo(`${REPORTING}/stopCancelCheckView`);
        } else {
            store.set(`${this.contextKey}-actionModel`, options.model);
            this.navigateTo(`${REPORTING}/viewStopCancelsDetails`);
        }
        return Promise.resolve();
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'PAY_CM_IV_LIST',
    view: StopCancelsList,
    options: {},
});

export default StopCancelsList;
