import util from '@glu/core/src/util';
import dialog from '@glu/dialog';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import ListView from 'common/dynamicPages/views/workflow/list';
import detailListTmpl from './detailList.hbs';

const DetailList = ListView.extend({
    template: detailListTmpl,

    events: {
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
        'click [data-hook="getBackButton"]': 'cancel',
    },

    initialize(options) {
        this.returnRoute = options.returnRoute;
        this.reportSubTypeCode = options.detailOptions.reportSubTypeCode;
        this.mode = 'select';

        const superOptions = {
            menuCategory: this.menuCategory,
            serviceName: 'adminReporting/listView',
            serviceFunc: null,
            businessType: null,
            hideGridActionButtons: true,
            context: 'ADMIN_REPORT',
            enableSavedViews: true,
        };

        ListView.prototype.initialize.call(
            this,
            util.extend({}, superOptions, options.detailOptions),
        );
    },

    print() {
        const printModal = new PrintViewModal({
            exportModel: this.buildExportModel('PDF'),
            title: `${this.localeKey}title`,
            exportURL: '/adminReporting/detailView/export',
        });
        printModal.exportModel.expData = this.reportSubTypeCode;
        dialog.custom(printModal);
    },

    export(options) {
        const format = options.format || 'CSV';
        const optionsParam = {
            ...options,
            gridView: this.gridView,
            format,
            exportURL: '/adminReporting/detailView/export',
            exportModel: this.buildExportModel(format),
        };
        optionsParam.exportModel.expData = this.reportSubTypeCode;
        this.doExport(optionsParam);
    },

    cancel() {
        this.navigateTo(this.returnRoute);
    },

});

export default DetailList;
