import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import okCancelWarningTmpl from './okCancelWarning.hbs';

const OkCancelWarning = ItemView.extend({
    template: okCancelWarningTmpl,

    attributes: {
        role: 'dialog',
        tabindex: '-1',
        'aria-hidden': 'false',
        class: 'modal',
    },

    ui: {
        // this is needed in certain situations when the dialog doesn't provide a callback
        cancel: '[data-dismiss="modal"]',
    },

    events: {
        'click @ui.cancel': 'cancel',
    },

    /**
     * @name initialize
     * @description sets up the save and cancel handlers if passed in as options;
     * otherwise, the default handlers are used
     * @param options
     */
    initialize(options) {
        this.message = options.message;
        this.title = options.title;
        this.save = options.save || this.save;
        this.cancel = options.cancel || this.cancel;
        this.okLabel = options.okLabel || locale.get('button.ok');
    },

    /**
     * @name save
     * @description 'ok' handler for warning dialog.  triggers 'warning:ok'
     */
    save() {
        this.trigger('warning:ok');
        this.close();
    },

    /**
     * @name cancel
     * @description 'cancel' handler for warning dialog. triggers 'warning:cancel'
     */
    cancel() {
        this.trigger('warning:cancel');
        this.close();
    },

    templateHelpers() {
        return {
            title: this.title,
            message: this.message,
            okLabel: this.okLabel,
        };
    },
});

export default OkCancelWarning;
