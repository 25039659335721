var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div>\n                <span class=\"info-label\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"field1Label") || (depth0 != null ? lookupProperty(depth0,"field1Label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"field1Label","hash":{},"data":data,"loc":{"start":{"line":5,"column":41},"end":{"line":5,"column":56}}}) : helper)))
    + "</span>\n                <span class=\"info-value\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"field1Value") || (depth0 != null ? lookupProperty(depth0,"field1Value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"field1Value","hash":{},"data":data,"loc":{"start":{"line":6,"column":41},"end":{"line":6,"column":56}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"currency") || (depth0 != null ? lookupProperty(depth0,"currency") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currency","hash":{},"data":data,"loc":{"start":{"line":6,"column":57},"end":{"line":6,"column":69}}}) : helper)))
    + "</span>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div>\n                <span class=\"info-label\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"field2Label") || (depth0 != null ? lookupProperty(depth0,"field2Label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"field2Label","hash":{},"data":data,"loc":{"start":{"line":11,"column":41},"end":{"line":11,"column":56}}}) : helper)))
    + "</span>\n                <span class=\"info-value\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"field2Value") || (depth0 != null ? lookupProperty(depth0,"field2Value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"field2Value","hash":{},"data":data,"loc":{"start":{"line":12,"column":41},"end":{"line":12,"column":56}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"currency") || (depth0 != null ? lookupProperty(depth0,"currency") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currency","hash":{},"data":data,"loc":{"start":{"line":12,"column":57},"end":{"line":12,"column":69}}}) : helper)))
    + "</span>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"info\">\n    <div class=\"info--section\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"field1Label") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"field2Label") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"info--section\">\n        <div>\n            <span class=\"info-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.TotalCredits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":37},"end":{"line":18,"column":66}}}))
    + " ("
    + alias2(((helper = (helper = lookupProperty(helpers,"totalCreditsCount") || (depth0 != null ? lookupProperty(depth0,"totalCreditsCount") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"totalCreditsCount","hash":{},"data":data,"loc":{"start":{"line":18,"column":68},"end":{"line":18,"column":89}}}) : helper)))
    + ")</span>\n            <span class=\"info-value\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"totalCredits") || (depth0 != null ? lookupProperty(depth0,"totalCredits") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"totalCredits","hash":{},"data":data,"loc":{"start":{"line":19,"column":37},"end":{"line":19,"column":53}}}) : helper)))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"currency") || (depth0 != null ? lookupProperty(depth0,"currency") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"currency","hash":{},"data":data,"loc":{"start":{"line":19,"column":54},"end":{"line":19,"column":66}}}) : helper)))
    + "</span>\n        </div>\n        <div>\n            <span class=\"info-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.TotalDebits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":37},"end":{"line":22,"column":65}}}))
    + " ("
    + alias2(((helper = (helper = lookupProperty(helpers,"totalDebitsCount") || (depth0 != null ? lookupProperty(depth0,"totalDebitsCount") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"totalDebitsCount","hash":{},"data":data,"loc":{"start":{"line":22,"column":67},"end":{"line":22,"column":87}}}) : helper)))
    + ")</span>\n            <span class=\"info-value\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"totalDebits") || (depth0 != null ? lookupProperty(depth0,"totalDebits") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"totalDebits","hash":{},"data":data,"loc":{"start":{"line":23,"column":37},"end":{"line":23,"column":52}}}) : helper)))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"currency") || (depth0 != null ? lookupProperty(depth0,"currency") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"currency","hash":{},"data":data,"loc":{"start":{"line":23,"column":53},"end":{"line":23,"column":65}}}) : helper)))
    + "</span>\n        </div>\n    </div>\n</div>\n";
},"useData":true});