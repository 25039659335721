var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n        <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"stats.adminReportHeading",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":35},"end":{"line":2,"column":72}}}))
    + "</h1>\n</div>\n<div class=\"page\">\n        <div class=\"section-container\">\n                <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n                <div class=\"AdminReportFilters row\">\n                        <div class=\"col-3 col-md-3 required\">\n                                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"stats.reportTypeDropdows",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":39},"end":{"line":9,"column":76}}}))
    + "</label>\n                                <div class=\"Dropdown Dropdown--fullWidth\"\n                                        data-region=\"reportTypeDropdownRegion\"></div>\n                                <span class=\"help-block\" data-bind=\"model\" data-validate=\"reportType\">\n                                </span>\n                        </div>\n                        <div class=\"col-3 col-md-3 required\">\n                                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"stats.reportSubTypeDropdows",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":39},"end":{"line":16,"column":79}}}))
    + "</label>\n                                <div class=\"Dropdown\" data-region=\"reportSubTypeDropdownRegion\">\n                                </div>\n                                <span class=\"help-block\" data-bind=\"model\" data-validate=\"reportSubType\">\n                                </span>\n                        </div>\n                </div>\n\n                <div class=\"AdminFeatureFilters\" data-region=\"filterRegion\" aria-live=\"polite\"></div>\n\n                <div class=\"section-body\">\n                        <button type=\"button\" data-hook=\"getReportSearch\"\n                                class=\"btn btn-primary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.search",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":56},"end":{"line":28,"column":82}}}))
    + "</button>\n                </div>\n\n                <div data-region=\"summaryListRegion\" aria-live=\"polite\"></div>\n        </div>\n</div>\n";
},"useData":true});