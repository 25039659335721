import CheckGrid from './checkGrid';
import CONSTANTS from './constants';
import gridDataAccess from './gridDataActions';

export default CheckGrid.extend({
    // Identifies if there is only one type in a group
    singleType: true,

    /**
     * Return the payment Initiator Group data entitlement attribute
     * @return {string}
     */
    getEntityName() {
        return CONSTANTS.ENTITY_NAME.PAYMENY_INITIATOR_GROUP_IDS;
    },

    /**
     * Return the payment Initiator Group type used for localized resources
     * @return {string}
     */
    getEntityType() {
        return CONSTANTS.ENTITY_TYPE.PAYMENY_INITIATOR_GROUP_IDS;
    },

    /**
     * Return the payment Initiator Group data inquiry id for the service call
     * @return {string}
     */
    getEntityID() {
        return CONSTANTS.REQUEST_ENTITY.PAYMENY_INITIATOR_GROUP_IDS;
    },

    /**
     * Return the payment Initiator Group data inquiry id for current/future for the service call
     * @return {string}
     */
    getCFEntityID() {
        return CONSTANTS.REQUEST_ENTITY.PAYMENY_INITIATOR_GROUP_IDS_CF;
    },

    /**
     * Return the available payment Initiator Group types to populate the grid
     * @return {Array}
     */
    getRecords() {
        return gridDataAccess.paymentInitiatorGroupRecords(this.model);
    },
});
