import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import { maskValue } from 'common/util/maskingUtil';
import smbConstants from 'app/smbPayments/constants';
import paymentAlertDetailsTmpl from './paymentAlertDetails.hbs';

const getAccountAttribute = function (account, attr) {
    return util.findWhere(
        account.get('mapDataList'),
        {
            toField: attr,
        },
    ).value;
};

const paymentLabels = function (paymentType) {
    const baseLabels = {
        comments: locale.get('smbPayments.comments'),
        paymentMethod: locale.get('SMBPAY.PaymentMethod'),
    };
    let labels;
    if (smbConstants.isACHCollection(paymentType)) {
        labels = {
            id: locale.get('RTGS.ID'),
            name: locale.get('SMBPAY.PayerName'),
            contactAccount: locale.get('SMBPAY.PayerAccount'),
            userAccount: locale.get('SMBPAY.ToAccount'),
            transactionDate: locale.get('SMBPAY.CollectionDate'),
            total: locale.get('sbPayments.collectionTotal'),
            isPayment: false,
        };
    } else {
        labels = {
            id: locale.get('RTGS.ID'),
            name: locale.get('SMBPAY.PayeeName'),
            contactAccount: locale.get('smbPayments.payee.account'),
            userAccount: locale.get('smbPayments.from.account'),
            transactionDate: locale.get('SMBPAY.PaymentDate'),
            total: locale.get('smbPayments.payment.total'),
            isPayment: true,
        };
    }
    return util.extend({}, baseLabels, labels);
};

export default Layout.extend({
    template: paymentAlertDetailsTmpl,

    initialize(opts) {
        this.isError = opts.isError;

        if (opts && opts.model) {
            this.model = this.setModelAttributes(opts.model);
        } else {
            this.model = new Model();
        }
        // add an attribute to be TYPE_DESCRIPTION without the 'additional fees apply' message
        const type = this.model.get('TYPE');
        if (type === smbConstants.FEDWIRE) {
            this.model.set('TYPE_DESCRIPTION_UPDATE', locale.get('SMBPAY.ExpFedWire.NoFee'));
        } else if (type === smbConstants.INTL) {
            this.model.set('TYPE_DESCRIPTION_UPDATE', locale.get('SMBPAY.ExpIntlWire.NoFee'));
        } else {
            this.model.set('TYPE_DESCRIPTION_UPDATE', this.model.get('TYPE_DESCRIPTION'));
        }
    },

    templateHelpers() {
        return util.extend(
            {},
            paymentLabels(this.model.get('PAYMENTTYPE')),
            {
                isError: this.isError,
            },
        );
    },

    setModelAttributes(model) {
        let accountNum;
        const { beneAccount } = model;
        const { account } = model;
        const paymentType = beneAccount.get('PAYMENTTYPE');

        /*
         * HACK NH-150963 SMB does not use the confirmResponse from the server, so
         * all the masking done here needs to happen before updating the model. When
         * SMB can use the server response, masking can come directly from the server.
         */
        if (smbConstants.isWire(paymentType) || smbConstants.isRTP(paymentType)) {
            accountNum = maskValue(getAccountAttribute(account, 'DEBIT_ACCOUNT_NUMBER'));
        } else {
            const offsetAccountNum = getAccountAttribute(account, 'OFFSETACCOUNTNUM');
            accountNum = (offsetAccountNum ? `${maskValue(offsetAccountNum)} / ` : '') + getAccountAttribute(account, 'ORIGCOMPNAME');
        }
        model.set('DEBIT_ACCOUNT_NUMBER', accountNum);
        model.set('BENE_ACCOUNT_DESCRIPTION', maskValue(beneAccount.get('BENE_ACCOUNT')));
        if (!model.get('CREDIT_CURRENCY')) {
            model.set('CREDIT_CURRENCY', model.get('DEBIT_CURRENCY'));
        }
        return model;
    },
});
