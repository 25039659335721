// import { gridRowAction } from 'pcm/common/serviceGridUtils'; //eslint-disable-line
import { navigateTo } from 'common/util/navigationUtils'; //eslint-disable-line
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import Glu from '@glu/core/src/glu';
import util from '@glu/core/src/util';
import Collection from '@glu/core/src/collection';
import errorHandlers from 'system/error/handlers';
import alert from '@glu/alerts';
import http from '@glu/core/src/http';
import store from 'system/utilities/cache';
import gridApi from 'common/dynamicPages/api/grid';
import constants from 'common/dynamicPages/api/constants';
import entitlements from 'common/dynamicPages/api/entitlements';
import workspaceHelper from 'common/workspaces/api/helper';
import helpers from 'components/AsyncReporting/helpers';
import ExportDetail from 'app/payments/views/exportDetail';
import ExportPaymentView from 'app/payments/views/exportPayments';
import services from 'services';
import ListView from 'common/dynamicPages/views/workflow/list';
import transform from 'common/util/transform';
import TotalsModel from 'common/dataCard/models/paymentSummary';
import TotalsView from 'common/dataCard/views/dataCardCollectionView';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import systemConfig from 'system/configuration';
import scroll from 'common/util/scroll';
import { moveToTopCheck } from 'common/util/deeplinkUtil';
import ReasonDialog from './reasonDialog';
import template from './checkTransactionList.hbs';

const localConstants = {
    addRoute: '/PAYMENTS/addCheck',
    viewRoute: '/PAYMENTS/viewCheck',
    editRoute: '/PAYMENTS/editCheck',
};

const CheckTransactionsListView = ListView.extend({
    template,

    regions: {
        totalsSection: '[data-hook="payment-totals-region"]',
    },

    ui: {
        $toggleTotals: '.toggleTotals',
        $totalsRegion: '[data-hook="payment-totals-region"]',
    },

    events: util.extend(
        {},
        ListView.prototype.events,
        {
            'click [data-hook="export-button"]': 'export',
            'click [data-hook="print-button"]': 'showPrintOptionsModal',
            'click [data-hook="refresh-button"]': 'refreshData',
            'click [data-hook="toggle-totals-button"]': 'toggleTotals',
        },
    ),

    initialize(options) {
        const requestParameters = {
            inquiryId: '50117',
            productCode: 'CLM',
            functionCode: 'INST',
            typeCode: '*',
        };

        const superOptions = {
            serviceName: 'tableMaint',
            serviceFunc: null,
            businessType: null,
            enableSavedViews: true,
            requestParameters: {
                item: transform.hashToPairs(requestParameters),
            },

            // key of LVC (usergroup-userid-productcode-functioncode-typecode)
            // 'contextDef' used to create unique grid ID = key of LVC
            // But for Check Transactions grid using contextDef doesn't work.
            // Fortunately, we can use 'configContext' instead.

            // contextDef: {
            configContext: {
                actionMode: 'SELECT',
                productCode: 'CLM',
                functionCode: '',
                typeCode: '*',
            },
        };

        this.returnUrl = Glu.Backbone.history.getFragment();
        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
        this.shouldShowTotals = serverConfigParams.get('ShowCLMTransactionstListViewTotals');
    },

    getPrintOptions() {
        return {
            inquiryId: '21010',
            hasSummarySelection: false,
        };
    },
    export() {
        const exportModel = {};
        this.prepareExportScreenInfo(exportModel);
        const rows = this.gridView.grid.getSelectedRowModels();
        exportModel.enableSel = (rows.length > 0);

        // if nothing is selected or all selected ones are not nacha or everything in
        // the grid are nacha
        exportModel.enableAll = (rows.length === 0) ||
            !exportModel.showDataFormatForSel ||
            exportModel.showDataFormatForAll;
        exportModel.selected = rows;
        this.exportPaymentsView = new ExportPaymentView(exportModel);
        this.listenTo(this.exportPaymentsView, 'exportPayments', this.exportPay);
        dialog.open(this.exportPaymentsView);
    },

    prepareExportScreenInfo(exportModelParam) {
        const exportModel = exportModelParam;
        let allNachaRowsFound = false;
        let allSelectedNachaRowsFound = false;
        const allRowModels = this.gridView.wrapper.rows.models;
        const selectedRowModels = this.gridView.grid.getSelectedRowModels();

        util.each(selectedRowModels, (model) => {
            if (model.get('SUBTYPE') !== 'NACHA') {
                allSelectedNachaRowsFound = false;
            }
        });
        exportModel.showDataFormatForSel = allSelectedNachaRowsFound;

        util.each(allRowModels, (model) => {
            if (model.get('SUBTYPE') !== 'NACHA') {
                allNachaRowsFound = false;
            }
        });
        exportModel.showDataFormatForAll = allNachaRowsFound;

        exportModel.isTemplate = false;
        exportModel.functioncode = ' Check Transactions';
    },

    exportPay(model) {
        this.buildExportModel(model);
        this.doExport();
    },

    buildExportModel(model) {
        this.exportModel = {};
        this.exportModel.outputFormat = model.get('fileformat');
        // this.exportModel.outputFormat = 'PDF';
        this.exportModel.pageType = 'LETTER';
        this.exportModel.numberOfRows = 0;
        this.exportModel.expData = model.get('expdata');
        this.exportModel.viewId = this.gridView.wrapper.viewId;
        this.exportModel.actionData = {};
        this.exportModel.actionData.productCode = 'CLM';
        this.exportModel.actionData.functionCode = 'INST';
        this.exportModel.actionData.typeCode = '*';

        // Check for Sort by Column
        if (this.gridView.wrapper.sortKey !== null) {
            const sortField = {};
            sortField.fieldName = this.gridView.wrapper.sortKey;
            sortField.sortOrder = this.gridView.wrapper.sortOrder;
            this.exportModel.sortFields = [];
            this.exportModel.sortFields.push(sortField);
        }
        const searchFields = [];
        let selectedTnums = [];
        // Check for Filtering
        const { filters } = this.gridView.wrapper;
        if (model.get('selection') && model.get('selection') !== 'all') {
            const rows = this.gridView.grid.getSelectedRows();
            if (rows.length > 0) {
                selectedTnums = rows.map((r) => {
                    const paymodel = this.gridView.wrapper.rows.get(r);
                    return paymodel.get('TNUM');
                });
            }
            let searchFieldName = 'TNUM';
            if (this.exportModel.expData === 'transaction') {
                searchFieldName = 'BATCH_TNUM';
            }
            searchFields[searchFields.length] = {
                fieldName: searchFieldName,
                operator: 'IN',
                fieldValue: selectedTnums,
                dataType: 'number',
            };
        }

        if (filters && filters.length > 0 && filters.models && filters.models.length > 0) {
            for (let i = 0; i < filters.models.length; i += 1) {
                const filterType = filters.models[i].get('type');
                const filterValues = filters.models[i].get('value');
                const filterName = filters.models[i].get('field');

                switch (filterType) {
                case 'string':
                case 'typeahead':
                    searchFields[searchFields.length] = {
                        fieldName: filterName,
                        operator: 'CONTAINS',
                        fieldValue: [filterValues],
                        dataType: 'text',
                    };
                    break;
                case 'date':
                case 'amount':
                case 'number':
                    searchFields[searchFields.length] = {
                        fieldName: filterName,
                        operator: filters.models[i].get('equality') || 'EQ',
                        fieldValue: util.isArray(filterValues) ? filterValues : [filterValues],
                        dataType: filterType,
                    }; break;
                case 'enum':
                    searchFields[searchFields.length] = {
                        fieldName: filterName,
                        operator: 'EQ',
                        fieldValue: [filterValues],
                        dataType: 'text',
                    };
                    break;
                default:
                    break;
                }
            }
        }

        if (searchFields.length > 0) {
            this.exportModel.searchFields = searchFields;
        }

        if (this.exportModel.expData === 'transaction') {
            // columns are read from db meta data
            this.exportModel.inquiryId = '21010';
        } else {
            // Get Columns and Order
            // TODO refactor
            const columns = [];
            this.gridView.wrapper.columns.each((m) => {
                if (m.get('condition') !== false) {
                    columns.push(m.attributes.field);
                }
            });
            this.exportModel.columns = columns;
        }
    },

    doExport() {
        const url = services
            .generateUrl(services.runAsyncListViewReport);
        if (this.exportModel.outputFormat === 'NACHA') {
            const options = {
                success: () => {
                    this.alertView = alert.success(
                        locale.get('payment.Export'),
                        {
                            details: new ExportDetail(),
                        },
                    );

                    this.alertRegion.show(this.alertView);
                },

                error: () => {
                    this.alertView = alert.negative(
                        locale.get('payment.export.error.message'),
                        {
                            title: locale.get('payment.export.error.title'),
                        },
                    );

                    this.alertRegion.show(this.alertView);
                },
            };

            const jsonData = this.exportModel;

            http.post(url, jsonData, (result) => {
                options.success(result);
            }, (result) => {
                // when notifications are in place this service will be include some response
                options.success(result);
            });
        } else {
            // if the CSV export file format is selected, then this export
            // will be available immediately as an automatic browser file download

            if (!this.isAdmin) {
                helpers.handleExportAsyncReport({
                    service: this.exportListViewPath || services.runAsyncListViewReport,
                    postData: this.exportModel,
                }, dialog);
                return;
            }

            let mode = 'get';
            if (this.exportListViewPath !== '/export/listView') {
                mode = 'post';
            }

            if (this.$('#listViewExportForm').length === 0) {
                const f = `<form id="listViewExportForm" action="${url}" method="${mode}" target="_self"><input id="listViewRequest" type="hidden" name="listViewRequest" ></form>`;
                this.$el.append(f);
            }

            this.$('#listViewExportForm #listViewRequest').val(JSON.stringify(this.exportModel));
            this.$('#listViewExportForm').submit();
        }
    },

    updateFromRender() {
        if (this.shouldShowTotals && !systemConfig.isAdmin()) {
            this.updateSubTotals();
        }
        // update refresh timestamp
        this.updateRefreshTimestamp();
    },

    updateSubTotals() {
        this.totalsModel.set('filters', this.gridView.wrapper.generateFiltersDataBlock());
        this.totalsModel.set('viewId', this.gridView.wrapper.viewId);
        this.totalsModel.fetch();
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.gridRegion.show(this.gridView);
            this.listenTo(this.gridView, 'rowAction', this.gridRowActionBefore);
            this.initializeTotals();
        } else {
            this.loadViewRequirements();
        }
    },

    gridRowActionBefore(options) {
        if (options && options.action === 'image') {
            const { model } = options;
            const batchTnum = model.get('BATCHTNUM');
            const tnum = model.get('TNUM');
            const typeCode = model.get('TYPE').toLowerCase();

            store.set('checkTransactionInfo', options.model.toJSON());
            // PCM
            store.set('entitlementsInfo', this.entitlements);
            navigateTo(`${localConstants
                .viewRoute}/${batchTnum}/${tnum}/${typeCode}/checkPreview/`);
        } else {
            this.gridRowAction(options);
        }
    },

    initializeTotals() {
        if (this.totalsModel && this.totalsView) {
            this.totalsModel.set(
                'filters',
                this.gridView.wrapper.generateFiltersDataBlock(),
            );
            this.totalsSection.show(this.totalsView);
            this.listenTo(this.totalsModel, 'change', function () {
                this.totalsCollection.reset(this.totalsModel.get('paymentTotalsByPaymentGroupList'));
                this.totalsView.render();
            });
            return;
        }
        if (!this.totalsModel) {
            this.totalsModel = new TotalsModel({
                filters: this.gridView.wrapper.generateFiltersDataBlock(),
            }, {
                service: services.generateUrl('payment/getCheckPaymentsSummary'),
                order: ['ACH', 'LOANS', 'WIRES', 'BILLPAY', 'RTP'],
            });
            // TODO: 30315 - Once the API is updated to handle in Admin,
            // the check for systemConfig.isAdmin() can be removed.
            if (this.shouldShowTotals && !systemConfig.isAdmin()) {
                this.totalsCollection = new Collection();
                this.totalsView = new TotalsView({
                    collection: this.totalsCollection,
                });
                this.totalsSection.show(this.totalsView);
            }
            this.listenTo(this.totalsModel, 'change', function () {
                this.totalsCollection.reset(this.totalsModel.get('paymentTotalsByPaymentGroupList'));
            });
        }
    },

    toggleTotals() {
        const isVisible = this.ui.$totalsRegion.height() > 0;
        if (isVisible) {
            this.ui.$totalsRegion.css({
                height: '0',
                visibility: 'hidden',
            });
            this.ui.$toggleTotals.text(locale.get('common.show.totals'));
        } else {
            this.ui.$totalsRegion.css({
                height: 'auto',
                visibility: 'visible',
            });
            this.ui.$toggleTotals.text(locale.get('common.hide.totals'));
        }
    },

    entitlementPromiseSuccess(result) {
        this.entitlements = result.actions;
        const self = this;
        const options = this.viewOptionRequirements();
        options.selector = this.entitlements[constants.ACTION_REPRINTCHECK] ?
            'rowSelector' : 'none';
        self.gridView = gridApi.createServiceGridView(options);
        this.setHasLoadedRequiredData(true);
        this.setupGridAvailableListener();
        this.listenTo(this.gridView.getRows(), 'sync', this.updateFromRender);
        this.render();
        this.updateFromRender();
        this.listenForGridErrors();
    },

    loadViewRequirements() {
        const entitlementOptions = {
            context: {
                serviceName: '/payment/listView/corp',
            },
            productCode: 'CLM',
            functionCode: 'BATCH',
            entryMethod: 0,
        };
        const entitlementPromise = entitlements.getEntitlements(entitlementOptions);
        entitlementPromise.then(
            result => this.entitlementPromiseSuccess(result),
            util.bind(errorHandlers.loading, this),
        );
    },

    gridRowSelect(rowData) {
        const { model } = rowData;
        const batchTnum = model.get('BATCHTNUM');
        const tnum = model.get('TNUM');
        const typeCode = model.get('TYPE').toLowerCase();

        store.set('checkTransactionInfo', rowData.model.toJSON());
        // PCM
        store.set('entitlementsInfo', this.entitlements);
        // TODO - we may need to save also in sessionStorage,
        // so that after page/tab refresh this data not lost
        store.set('currentAction', 'SELECT');
        navigateTo(`${localConstants.viewRoute}/${batchTnum}/${tnum}/${typeCode}/transactionDetails/`);
        // TODO something not OK with route pattern (/) - works OK only when / at the end.
    },

    gridRowModify(rowData) {
        const { model } = rowData;
        model.set('ACTIONCODE', 'UPDCHKST');
        const batchTnum = model.get('BATCHTNUM');
        const tnum = model.get('TNUM');
        const typeCode = model.get('TYPE').toLowerCase();

        store.set('checkTransactionInfo', rowData.model.toJSON());
        // PCM
        store.set('entitlementsInfo', this.entitlements);
        // TODO - we may need to save also in sessionStorage,
        // so that after page/tab refresh this data not lost
        // Action
        store.set('currentAction', 'UPDCHKST');
        navigateTo(`${localConstants.viewRoute}/${batchTnum}/${tnum}/${typeCode}/transactionDetails/`);
        // TODO something not OK with route pattern (/) - works OK only when / at the end.
    },

    gridRowVoidCheck(options) {
        const reasonDialog = new ReasonDialog({
            model: options.model,
            pageTitle: locale.get('clm.voidreasondialog.title') || '',
            actionType: options.action,
            refreshData: () => this.success(),
        });
        dialog.open(reasonDialog);
    },

    success() {
        this.refreshData();
        if (store.has('payment_listView_corp-confirms')) {
            const confirmResponse = store.get('payment_listView_corp-confirms');
            const confirm =
                typeof confirmResponse === 'object'
                    ? confirmResponse.confirms
                    : null;
            const successCount =
                confirm !== null ? confirm.totalSuccess : confirmResponse;
            const failCount = confirm !== null ? confirm.totalFail : 0;
            const totalCount = failCount + successCount;
            const success = successCount === totalCount;
            const alertFunc = success ? alert.success : alert.danger;
            const message =
                typeof confirmResponse === 'object' &&
                confirmResponse.message.length > 0
                    ? confirmResponse.message
                    : confirmResponse;
            this.alertRegion.show(alertFunc(message));
        }
        store.unset('payment_listView_corp-confirms');
        scroll.scrollToFirstError();
        if (window.parent !== window) {
            moveToTopCheck(this.model);
        }
    },

    gridRowStopCheck(options) {
        const reasonDialog = new ReasonDialog({
            model: options.model,
            pageTitle: locale.get('clm.stopreasondialog.title') || '',
            actionType: options.action,
            refreshData: () => this.success(),
        });
        dialog.open(reasonDialog);
    },

    gridRowReprintCheckFromTransactionList(options) {
        const reprintKeyValuesPairArray = [];
        const { model } = options;
        const typeCode = `B${model.get('TYPE')}`;
        const ptxId = model.get('PTX_CHECK_ID');
        const keyValuePairs = this.generateKeyValuePairs(
            model,
            typeCode,
            ptxId,
        );
        const { entryMethod } = keyValuePairs;
        const isReprintBatch = false;
        const isReprintCheck = true;
        reprintKeyValuesPairArray.push(keyValuePairs);
        this.performCheckBatchPrintOrReprintAction(
            reprintKeyValuesPairArray,
            isReprintBatch,
            isReprintCheck,
            entryMethod,
            model,
        );
    },

    reprintCheckFromTransactionList() {
        if (this.entitlements[constants.ACTION_REPRINTCHECK]) {
            const selectedChecks = this.gridView.grid.getSelectedRows();
            const releasedToPrinterStatus = 'RP';
            const reprintKeyValuesArray = [];
            let illegibleChecksCount = 0;
            const reprintBatchAction = false;
            const reprintCheckAction = true;
            let selectedCheckModel;
            if (selectedChecks.length > 0) {
                const firstSelectedCheck = this.gridView.wrapper.rows.get(selectedChecks[0]);
                // we have only one entryMethod within collection
                const { entryMethod } = firstSelectedCheck.context.actionContext;
                for (let index = 0; index < selectedChecks.length; index += 1) {
                    selectedCheckModel =
                        this.gridView.wrapper.rows.get(selectedChecks[index]);
                    const selectedCheckStatus = selectedCheckModel.get('STATUS');
                    if (selectedCheckStatus === releasedToPrinterStatus) {
                        const typeCode = `B${selectedCheckModel.get('TYPE')}`;
                        const ptxId = selectedCheckModel.get('PTX_CHECK_ID');
                        const keyValuePair =
                            this.generateKeyValuePairs(
                                selectedCheckModel,
                                typeCode,
                                ptxId,
                            );
                        reprintKeyValuesArray.push(keyValuePair);
                    } else {
                        illegibleChecksCount += 1;
                    }
                }

                const singleCheckSelected = reprintKeyValuesArray.length === 1;

                if (illegibleChecksCount > 0) {
                    const message = locale.get('clm.reprintcheck.eligible.error');
                    const translatedAction = locale.get('clm.reprintChecks');
                    dialog.confirm(
                        `${message}`,
                        `${locale.get('title.confirm')} ${translatedAction}`,
                        (ok) => {
                            if (ok && illegibleChecksCount < selectedChecks.length) {
                                if (singleCheckSelected) {
                                    this.performCheckBatchPrintOrReprintAction(
                                        reprintKeyValuesArray,
                                        reprintBatchAction,
                                        reprintCheckAction,
                                        entryMethod,
                                        selectedCheckModel,
                                    );
                                } else {
                                    this.performCheckBatchPrintOrReprintAction(
                                        reprintKeyValuesArray,
                                        reprintBatchAction,
                                        reprintCheckAction,
                                        entryMethod,
                                    );
                                }
                            }
                        },
                    );
                } else if (singleCheckSelected) {
                    this.performCheckBatchPrintOrReprintAction(
                        reprintKeyValuesArray,
                        reprintBatchAction,
                        reprintCheckAction,
                        entryMethod,
                        selectedCheckModel,
                    );
                } else {
                    this.performCheckBatchPrintOrReprintAction(
                        reprintKeyValuesArray,
                        reprintBatchAction,
                        reprintCheckAction,
                        entryMethod,
                    );
                }
            }
        }
    },

    templateHelpers() {
        const self = this;
        const obj = ListView.prototype.templateHelpers.call(self);
        return Object.assign(obj, {
            getSectionTitle() {
                return locale.get('listview.checktrans.lockbox');
            },
            getButtonString(type) {
                return locale.get(type);
            },
            hasReprintCheckEntitlement:
                () => self.hasEntitlement(constants.ACTION_REPRINTCHECK),
            showTotals() {
                const shouldShow = self.shouldShowTotals === 'true';
                return (shouldShow && !systemConfig.isAdmin());
            },
        });
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'CHECK_TRANSACTIONS',
    view: CheckTransactionsListView,
    options: {},
});

export default CheckTransactionsListView;
