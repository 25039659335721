import Layout from '@glu/core/src/layout';
import { createTabsToggleButton, toggleTabs, setTabButtonText } from 'common/util/a11y/tabs';
import store from 'system/utilities/cache';
import workspaceHelper from 'common/workspaces/api/helper';
import constants from 'app/payments/constants';
import CheckInquiry from 'app/payments/views/checkInquiry/checkInquiry';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import util from '@glu/core/src/util';
import systemConfig from 'system/configuration';
import checkInquiryTabsTmpl from './checkInquiryTabs.hbs';

const CheckInquiryTabs = Layout.extend({
    template: checkInquiryTabsTmpl,

    ui: {
        $navTabs: '[data-hook="getNavTabs"]',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
        $navItems: '[data-hook="getNavTabs"] .NavTabs-item',
    },

    isInitialRender: true,

    initialize(options) {
        this.allowHistory = applicationConfigParams.getValue('INQ', 'CHECKINQHISTORY') === '1';
        this.initialTab = store.get('chkinq:initialTab');
        const checkInquiryIds = ['CHECK_INQUIRY_VIEW', 'CHECK_INQUIRY_TABS', 'CHECK_INQUIRY_VIEW_NEW'];
        if (checkInquiryIds.includes(options?.id)) {
            store.set('current-workspace-route', 'PAYMENTS/checkInquiry');
        }
    },

    onRender() {
        createTabsToggleButton(this);
        const initialTab = this.getInitialTab();
        initialTab.call(this);
        this.isInitialRender = false;
    },

    /*
     *  @method: switchActiveTab
     *  @param: {jquery Event/jquery object} e
     *  Removes old active tab and sets current
     */
    switchActiveTab(e) {
        const $tab = e.currentTarget ? this.$(e.currentTarget) : e;
        this.ui.$navItems.removeClass('is-active');
        $tab.parent().addClass('is-active');

        this.currentTabText = $tab.text();
        if (this.isInitialRender) {
            setTabButtonText(this, this.currentTabText);
        } else {
            toggleTabs(this);
        }
    },

    /*
     *  @method: getActiveTab
     *  @returns {jquery object}
     *  Returns the jquery object of the current tab
     */
    getActiveTab() {
        let $tab;
        if (!util.isNullOrUndefined(this.initialTab)) {
            $tab = this.$(`a[data-action="${this.initialTab}"]`);
        } else {
            $tab = this.ui.$navLinks.first();
        }
        return $tab;
    },

    /*
     *  @method: getInitialTab
     *  @returns {function}
     *  Returns the function of the initial tab to be executed by the caller
     */
    getInitialTab() {
        if (!util.isNullOrUndefined(this.initialTab)) {
            return this[this.initialTab];
        }
        return this.showRealTimeInquiry;
    },

    /*
     *  @method: showRealTimeInquiry
     *  Renders the Real-time Inquiry tab
     */
    showRealTimeInquiry() {
        store.set('chkinq:initialTab', 'showRealTimeInquiry');
        const $tab = this.$('[data-action="showRealTimeInquiry"]');
        this.switchActiveTab($tab);
        this.checkInquiryRegion.show(new CheckInquiry({
            tabId: constants.CHECK_INQUIRY.TABS.REAL_TIME,
            widget: this.widget,
        }));
    },

    /*
     *  @method: showHistoricalInquiry
     *  Renders the Real-time Inquiry tab
     */
    showHistoricalInquiry() {
        store.set('chkinq:initialTab', 'showHistoricalInquiry');
        const $tab = this.$('[data-action="showHistoricalInquiry"]');
        this.switchActiveTab($tab);
        this.checkInquiryRegion.show(new CheckInquiry({
            tabId: constants.CHECK_INQUIRY.TABS.HISTORY,
            loanExportPrintURL:
                    systemConfig.isAdmin()
                        ? constants.LOAN_ACCTGRP_EXPORT_PRINT_URL
                        : constants.ASYNC_LOAN_ACCTGRP_EXPORT_PRINT_URL,
        }));
    },

    templateHelpers() {
        return {
            pageTitle: this.options.pageTitle,
            showHistoryTab: this.allowHistory,
        };
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'CHECK_INQUIRY',
    view: CheckInquiryTabs,
    options: {},
});
workspaceHelper.publishedWidgets.add({
    id: 'CHECK_INQUIRY_NEW',
    view: CheckInquiryTabs,
    options: {},
});
export default CheckInquiryTabs;
