import $ from 'jquery';
import util from '@glu/core/src/util';
import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import alert from '@glu/alerts';
import Model from '@glu/core/src/model';
import store from 'system/utilities/cache';
import errorHandlers from 'system/error/handlers';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import ContextApi from 'common/dynamicPages/api/context';
import ContextModel from 'common/dynamicPages/models/context';
import PaymentTypeCollection from 'app/payments/collections/paymentTypes';
import PaymentSubTypeCollection from 'app/payments/collections/paymentSubTypes';
import PaymentTemplateCollection from 'app/payments/collections/paymentTemplates';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import constants from 'common/dynamicPages/api/constants'; // PCM
import loadingModalTmpl from 'common/templates/loadingModal.hbs';
import addPaymentTmpl from './addPayment.hbs';

const AddPaymentModel = Model.extend({
    validators: {
        type: {
            description: 'Payment Type',
            exists: true,
        },
    },
});

export default Layout.extend({
    template: addPaymentTmpl,
    loadingTemplate: loadingModalTmpl,
    modalClass: 'modal-lg add-payment-modal',

    ui: {
        $paymentTypeSelect: '[data-hook="payment-select"]',
        $paymentSubTypeSelect: '[data-hook="payment-sub-select"]',
        $templateSelect: '[data-hook="template-select"]',
        $paymentSubTypeContainer: '#paymentSubType',
        $paymentGroup: '.payment-group',
        $templateGroup: '.template-group',
        $transferTypeContainer: '#transferTypeContainer',
        $transferType: '#transferType',
        $selection: '[data-hook="getPaymentTemplateRadio"]',
    },

    onClose() {
        this.$('select, input[type="hidden"]').comboBox('destroy');
    },

    events: {
        'click #payment-type': 'showSelection',
        'click #payment-template': 'showSelection',
    },

    initialize() {
        this.dialogTitle = locale.get('payment.add');

        this.dialogButtons = [{
            text: locale.get('payment.continue'),
            className: 'btn btn-primary',
            callback: 'continue',
        }, {
            text: locale.get('payment.cancel'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }];

        this.model = new AddPaymentModel();
        this.model.removeValidator('tnum');

        this.isModal = this.options.isModal;

        this.storedPreObj = null;

        if (this.options.isModal === false) {
            this.contextDef = ContextApi.menuContext.getContext('PAY_LIST_VIEW');
            this.contextModel = new ContextModel({
                menuCategory: 'PMTS',
                serviceName: 'payments/corp',
                serviceFunc: null,
                businessType: null,
                context: 'PAY_LIST_VIEW',
                contextDef: this.contextDef,
            });
            this.contextKey = this.contextModel.getContextKey();
            this.localeKey = this.contextModel.getLocaleKey();
            store.set(`${this.contextKey}-listRoute`, 'PAYMENTS/addNewPayment');

            // check for deep linking payment
            this.storedPreObj = store.remove(
                'paymentDetails',
                {
                    context: {
                        productCode: 'PAY',
                        functionCode: 'INST',
                    },
                },
            );
        }
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            if (this.options.isModal === false) {
                this.renderMessage(store.get(`${this.contextKey}-alertMessage`), store.get(`${this.contextKey}-confirms`));
                store.set(`${this.contextKey}-alertMessage`, null);
                store.set(`${this.contextKey}-confirms`, null);
            }

            this.ui.$paymentSubTypeContainer.hide();
            this.model.set('selection', 'newPayment');
            this.ui.$paymentGroup.show();
            this.ui.$templateGroup.hide();
            this.renderPaymentType();
            this.renderPaymentTemplate();
            this.ui.$transferTypeContainer.hide();
        } else {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        const self = this;
        const paymentTypePromise = this.paymentTypePromise();
        const paymentTemplatePromise = this.paymentTemplatePromise();

        Promise.all([paymentTypePromise, paymentTemplatePromise]).then((results) => {
            [self.paymentTypes, self.paymentTemplates] = results;
            self.paymentSubTypes = new PaymentSubTypeCollection();

            // check for deep linked payment type
            if (!util.isNull(self.storedPreObj)) {
                self.handleStoredPreObj();
            } else {
                const tCount = self.paymentTemplates.length;
                if (tCount >= 249) {
                    self.handleNextBatch();
                } else {
                    self.setHasLoadedRequiredData(true);
                    self.render();
                }
            }
        }, util.bind(errorHandlers.loadingModal, self));
    },

    handleNextBatch() {
        this.startRow = this.paymentTemplates.length + 1;
        const paymentTemplatePromise = this.paymentTemplatePromise({
            queryOffSet: this.startRow,
        });
        this.loadNextBatch(paymentTemplatePromise);
    },

    loadNextBatch(paymentTemplatePromise) {
        const self = this;
        Promise.all([paymentTemplatePromise]).then((results) => {
            const [collection] = results;
            const tCount = collection.length;

            self.paymentTemplates.add(collection.models);
            if (tCount >= 249) {
                self.handleNextBatch();
            } else {
                self.setHasLoadedRequiredData(true);
                self.render();
            }
        }, util.bind(errorHandlers.loadingModal, self));
    },

    templateHelpers() {
        const self = this;

        return {
            getString(type) {
                return locale.get(type);
            },

            paymentTypes() {
                return self.paymentTypes.toJSON();
            },

            paymentSubTypes() {
                return self.paymentSubTypes.toJSON();
            },

            paymentTemplates() {
                return self.paymentTemplates.toJSON();
            },

            notModal: !this.options.isModal,
            title: this.dialogTitle,
        };
    },

    handleStoredPreObj() {
        const self = this;

        const foundModel = this.paymentTypes.find(model => model.get('typeCode') === self.storedPreObj.typeCode);

        if (foundModel) {
            this.model.set('selection', 'newPayment');
            this.model.set('type', foundModel.id);

            const context = foundModel.get('context');
            context.functionCode = 'INST';

            if (context.serviceName.indexOf('/batch/') === 0) {
                context.subType = this.storedPreObj.subType;
            }

            store.set(`${this.contextKey}-contextOverride`, context);
            if (this.storedPreObj.preFill) {
                store.set(`${this.contextKey}-preFill`, this.storedPreObj.preFill);
            }
            this.navigateTo(this.contextModel.getInsertUrl());
        } else {
            // if payment type can't be found, then the user is not entitled for that.
            this.navigateTo('PAYMENTS/notEntitled');
        }
    },

    renderMessage(action, confirmResponse, errorCount) {
        // create the confirmation view for the alert
        // skip if action is null.  This occurs when first entering the payment workspace
        // because the onRender is called.
        if ((action === null || action === undefined)
            && (confirmResponse === null || confirmResponse === undefined)) {
            // TODO: Refactor this empty block away
        } else if (confirmResponse === null || confirmResponse === undefined) {
            if (action.type === 'WARNING' && action.message) {
                this.alertView = alert.warning(action.message);
                this.alertRegion.show(this.alertView);
            }
        } else {
            const confirm = typeof confirmResponse === 'object' ? confirmResponse.confirms : null;
            const successCount = confirm !== null ? confirm.totalSuccess : confirmResponse;
            const failCount = confirm !== null ? confirm.totalFail : errorCount;
            const totalCount = failCount + successCount;
            const success = successCount === totalCount;
            const alertFunc = success ? alert.success : alert.danger;
            const chainedMessages = typeof confirmResponse === 'object' ? confirmResponse.message : null;
            const message = typeof confirmResponse === 'object' && confirmResponse.message.length > 0 ? confirmResponse.message[chainedMessages.length - 1] : action;

            // display notification message
            this.alertView = alertFunc(
                message,
                {
                    details: confirmResponse
                    && confirmResponse.confirms.confirmResults[0].confirmData[0].item
                        ? new Confirms({
                            confirms: confirm,
                        }) : null,

                    canDismiss: !!confirmResponse,
                    animate: true,
                },
            );

            if (message) {
                this.alertRegion.show(this.alertView);
            }
        }
    },

    paymentTypePromise() {
        return new Promise((resolve, reject) => {
            const paymentTypes = new PaymentTypeCollection({
                functionCode: 'INST',
                inquiryId: 20011,
            }, {
                base: 'payments',
            });
            paymentTypes.fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    paymentTemplatePromise(options) {
        let localOptions = options;
        localOptions = localOptions || {};
        return new Promise((resolve, reject) => {
            const paymentTemplates = new PaymentTemplateCollection(localOptions);
            paymentTemplates.fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    /**
     * @description checks if either the payment types list or the list of templates
     * has only one value,
     *              if so, the combo will be set to that value.
     * @param isPayment
     */
    selectIfOnlyOne(isPayment) {
        const ifOne = (serverConfigParams.get('SelectIfOnlyOne') && serverConfigParams.get('SelectIfOnlyOne').toUpperCase() === 'TRUE');
        let payment = (this.ui.$selection.val() === 'newPayment');
        let $select;

        if (isPayment !== undefined) {
            payment = isPayment;
        }
        const collection = (payment) ? this.paymentTypes.models : this.paymentTemplates.models;
        if (ifOne && (collection && collection.length === 1)) {
            $select = payment ? this.ui.$paymentTypeSelect : this.ui.$templateSelect;
            $select.comboBox('val', $select.find('option').eq(1).attr('value'), true);
        }
    },

    showSelection(e) {
        const radioButton = e.currentTarget;
        if (radioButton.value === 'newPayment') {
            this.ui.$templateGroup.hide();
            this.ui.$paymentGroup.show();
            this.model.removeValidator('tnum');
            this.model.addValidator(
                'type',
                {
                    description: locale.get('payment.type'),
                    exists: true,
                },
            );
        }
        if (radioButton.value === 'template') {
            this.ui.$paymentGroup.hide();
            this.ui.$templateGroup.show();

            this.model.removeValidator('subType');
            this.model.removeValidator('transferType');
            this.model.removeValidator('type');

            this.model.addValidator(
                'tnum',
                {
                    description: locale.get('payment.template'),
                    exists: true,
                },
            );
        }
        this.selectIfOnlyOne((radioButton.value === 'newPayment'));
    },

    renderPaymentType() {
        const self = this;

        this.ui.$paymentTypeSelect.comboBox({
            matcher(term, text) {
                // first check and see if there are exact matches
                let exactMatch = false;
                self.paymentTypes.each((paymentType) => {
                    if (paymentType.get('label').toUpperCase() === term.toUpperCase()) {
                        exactMatch = true;
                    }
                });
                if (exactMatch && term.toUpperCase() === text.toUpperCase()) {
                    return true;
                }
                if (!exactMatch && (text.toUpperCase().indexOf(term.toUpperCase()) > -1)) {
                    return true;
                }
                return false;
            },
        });
        this.ui.$paymentTypeSelect.on('change', (e) => {
            if (e.val) {
                const paymentModel = self.paymentTypes.get(e.val);
                const context = paymentModel.get('context');
                if (context.serviceName.indexOf('/batch/') === 0) {
                    self.addSubType(paymentModel);
                } else {
                    self.removeSubType();
                }

                const isTransfer = context.serviceName.indexOf('/transfer') !== -1;
                if (isTransfer) {
                    self.addTransferType();
                } else {
                    self.removeTransferType();
                }

                self.updateProductCode(paymentModel);
            }
        });
        this.selectIfOnlyOne();
    },

    updateProductCode(paymentModel) {
        this.model.set('productCode', paymentModel.get('productCode'));
    },

    renderPaymentTemplate() {
        this.ui.$templateSelect.comboBox();
        this.selectIfOnlyOne();
    },

    addSubType(paymentModel) {
        // PCM
        if (paymentModel.get('productCode') === constants.CHECK_PRODUCT_CODE) {
            this.ui.$paymentSubTypeSelect.comboBox('val', '*', true);
            this.ui.$paymentSubTypeContainer.hide();
            this.model.removeValidator('subType'); // PCM-1112 fix
            return;
        }
        // PCM
        const self = this;

        this.ui.$paymentSubTypeSelect.html('<option></option>');
        this.ui.$paymentSubTypeSelect.comboBox({
            placeholder: locale.get('payment.loading'),
        });

        this.model.validators.subType = {
            description: locale.get('payment.sub.type'),
            exists: true,
        };

        const success = () => {
            let html = '<option></option>';

            self.paymentSubTypes.each((model) => {
                const id = util.escape(model.get('id'));
                const label = util.escape(model.get('label'));
                html += `<option id="${id}" value="${id}">${label}</option>`;
            });

            self.ui.$paymentSubTypeSelect.html(html);
            self.ui.$paymentSubTypeSelect.comboBox({
                placeholder: locale.get('payment.subtype.select'),
            });

            // if there is only one option in addition to the blank one, select it by default
            if (self.ui.$paymentSubTypeSelect.find('option').length === 2) {
                self.ui.$paymentSubTypeSelect.comboBox('val', self.ui.$paymentSubTypeSelect.find('option').eq(1).attr('value'), true);
                self.ui.$paymentSubTypeContainer.hide();
            } else {
                // show the subtype dropdown only when more than one option is available,
                // excluding the empty option
                self.ui.$paymentSubTypeContainer.show();
            }
        };

        if (this.paymentSubTypes.type === paymentModel.get('typeCode')) {
            success();
        } else {
            this.paymentSubTypes.type = paymentModel.get('typeCode');
            this.paymentSubTypes.fetch({
                success,
            });
        }
    },

    removeSubType() {
        this.ui.$paymentSubTypeContainer.hide();
        this.ui.$paymentSubTypeSelect.html('<option></option>');
        this.model.validators.subType = null;
    },

    addTransferType() {
        this.ui.$transferType.comboBox({
            placeholder: locale.get('payment.selectTransferType'),
        });
        this.ui.$transferTypeContainer.show();

        this.model.validators.transferType = {
            description: locale.get('payment.TransferType'),
            exists: true,
        };
    },

    removeTransferType() {
        this.ui.$transferTypeContainer.hide();
        this.model.validators.transferType = null;
    },

    getTypeCode() { // PCM RELATED
        const typeCode = this.model.get('type') ? this.model.get('type').split('~')[0] : '*';
        return typeCode;
    },

    continue() {
        if (this.model.isValid()) {
            let context;
            if (this.model.get('selection') !== 'template') {
                context = this.paymentTypes.get(this.model.get('type')).get('context');
                if (context.serviceName.indexOf('/batch/') === 0) {
                    const typeCode = this.getTypeCode(); // PCM
                    context.serviceNameTypeCode = this.model.get('productCode') === constants.CHECK_PRODUCT_CODE ? typeCode : this.paymentSubTypes.type;
                    context.subType = this.model.get('productCode') !== constants.CHECK_PRODUCT_CODE ? this.paymentSubTypes.get(this.model.get('subType')).get('id') : '*';
                }

                const isTransfer = context.serviceName.indexOf('/transfer') !== -1;
                if (isTransfer) {
                    context.transferType = $('option:selected', this.ui.$transferType).val();
                }

                context.enableSaveDraft = true;
                if (this.model.get('type') === 'MULTIFEDWIRE~*') {
                    context.isRapidWireEntry = true;
                } else if (this.model.get('type') === 'CRTRAN~*') {
                    context.isRtpMultiAdd = true;
                }
                this.model.set('context', context);
            } else if (this.model.get('selection') === 'template') {
                context = this.paymentTemplates.get(this.model.get('tnum')).get('context');
                context.enableSaveDraft = true;
                this.model.set('context', context);
            }
            if (this.options.isModal === true) {
                dialog.close();
                this.trigger('selected', this.model);
            } else {
                let overrideContext;

                if (this.model.get('selection') === 'newPayment') {
                    if (this.model.get('type').indexOf('LOANPAY') > -1 || this.model.get('type').indexOf('LOANDRAW') > -1) {
                        store.set('basic_payment_data', this.model);
                        this.navigateTo('PAYMENTS/addPayment');
                    } else {
                        overrideContext = util.extend(this.contextDef, this.model.get('context'));
                        overrideContext.enableSaveDraft = true;

                        const isTransferShadow = overrideContext.serviceName.indexOf('/transfer') !== -1;
                        const isManyToOne = overrideContext.transferType === 'many-to-one';
                        const isOneToMany = overrideContext.transferType === 'one-to-many';

                        if (isTransferShadow && isManyToOne) {
                            overrideContext.subType = 'MANY2ONE';
                            store.set(`${this.contextKey}-contextOverride`, overrideContext);
                            this.navigateTo('PAYMENTS/addTransferManyToOne');
                        } else if (isTransferShadow && isOneToMany) {
                            overrideContext.subType = 'ONE2MANY';
                            store.set(`${this.contextKey}-contextOverride`, overrideContext);
                            this.navigateTo('PAYMENTS/addTransferOneToMany');
                        } else {
                            store.set(`${this.contextKey}-contextOverride`, context);
                            this.navigateTo(this.contextModel.getInsertUrl());
                        }
                    }
                }
                if (this.model.get('selection') === 'template') {
                    if (this.model.get('context').type.indexOf('LOANPAY') > -1 || this.model.get('context').type.indexOf('LOANDRAW') > -1) {
                        store.set('basic_payment_data', this.model);
                        this.navigateTo('PAYMENTS/addPayment');
                    } else {
                        overrideContext = util.extend(
                            this.contextDef,
                            {
                                serviceName: this.model.get('context').serviceName,
                                templateServiceName: this.model.get('context').templateServiceName,
                                tnum: this.model.get('context').tnum,
                                functionCode: this.model.get('context').functionCode,
                                subType: this.model.get('context').subType,
                                updateCount: this.model.get('context').updateCount,
                            },
                        );
                        overrideContext.enableSaveDraft = true;
                        store.set(`${this.contextKey}-contextOverride`, overrideContext);
                        this.navigateTo('PAYMENTS/addPaymentFromTemplate');
                    }
                }
            }
        }
    },

    cancel() {
        dialog.close();
    },
});
