import GluModel from '@glu/core/src/model';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import Formatter from 'system/utilities/format';
import sbPaymentsApi from 'app/smb/api';
import CommentModel from './largeComment';
import RecipientModel from './map/beneficiary';

let Model = GluModel;

export default function () {
    Model = Model.extend({
        defaults: {
            /*
             * calling this accountNumber instead of toAccount is temporary until references to
             * recipients gets
             * sorted out.  accountNumber is preferable at the moment because it makes
             * copying attributes to
             * and from a recipient easier, since recipient's account is named accountNumber.
             */
            BENE_ACCOUNTNUMBER: '',

            BENE_BANK_ID: '',
            BENE_NAME: '',
            fromAccount: '',
            recipientId: '',
            paymentDate: '',
            comment: new CommentModel(),
            recipient: new RecipientModel(),

            displayAmount() {
                const amount = this.amount ? this.amount : 0;
                return Formatter.formatCurrency(amount);
            },
        },

        initialize() {
            this.validators = {
                BENE_NAME: {
                    description: locale.get('sbPayments.recipient'),
                    exists: true,
                },

                amount: {
                    description: locale.get('sbPayments.amount'),
                    matches: sbPaymentsApi.AMOUNT_PATTERN,
                },
            };
        },

        reset(...args) {
            Model.prototype.clear.apply(this, args);
            this.set(util.clone(this.defaults));
        },
    });
    return new Model();
}
