import services from 'system/servicesList';
import systemConfig from 'system/configuration';
import configParams from 'system/webseries/models/configurationParameters';
import constants from 'app/balanceAndTransaction/constants';
import { getFlexDropdownFormat } from 'common/util/inquiryQueryResults';
import useRequest from '../../hooks/useRequest';

const { post } = useRequest();
const REPORT_TYPES_ENDPOINT = 'export/scheduledExport/getEntitledReportTypes';
const SCHEDULED_EXPORTS_GET_QUERY_RESULTS = 'export/scheduledExport/getQueryResults';
const GET_LOOKUP_RESULTS = 'tableMaintenance/getLookupResults';

export default {
    /**
     * Get BAI data base on search criteria
     * @param {Array} codes - Array of codes to use in the search
     * @returns {Promise}
     */
    getBAIData(codes) {
        return this.getBAIFilterData({
            inquiryId: 22310,
            fieldName: 'BAI_CODELOOKUP',
            searchFieldName: 'BAI_CODE',
            searchFieldValue: codes,
        });
    },
    /**
     * Get Swift data base on search criteria
     * @param {Array} codes - Array of codes to use in the search
     * @returns {Promise}
     */
    getSwiftData(codes) {
        return this.getBAIFilterData({
            inquiryId: 22312,
            fieldName: 'SWIFT_TYPE_CODELOOKUP',
            searchFieldName: 'SWIFT_CODE',
            searchFieldValue: codes,
        });
    },

    /**
     * Get LookupResults for BAI filters based on the data
     * @param {Object} options
     * @returns {Promise}
     */
    getBAIFilterData(options) {
        let data = {
            rowsPerPage: 50,
            startRow: 1,
            fieldName: options.fieldName,
            typeCode: 'BAIEXP',
            productCode: 'GIR',
            functionCode: 'MAINT',
            entryClass: null,
            depends: [],
            requestParameters: {
                item: [{
                    name: 'PRODUCTCODE',
                    value: 'GIR',
                }, {
                    name: 'FUNCTIONCODE',
                    value: 'MAINT',
                }, {
                    name: 'TYPECODE',
                    value: 'BAIEXP',
                }, {
                    name: 'INQUIRYID',
                    value: options.inquiryId,
                }],
            },
            searchFields: [{
                fieldName: options.searchFieldName,
                operator: 'IN',
                fieldValue: options.searchFieldValue,
                dataType: 'text',
            }],
        };
        const { userGroup, userId } = options;
        if (systemConfig.isAdmin() && userGroup && userId) {
            data = {
                ...data,
                depends: [
                    {
                        name: 'USERGROUP',
                        value: userGroup,
                    },
                    {
                        name: 'USERID',
                        value: userId,
                    },
                ],
            };
        }
        return post(GET_LOOKUP_RESULTS, data);
    },

    /**
     * Get full data for account based on accountFilter data
     * @param {Object} options
     * @returns {Promise}
     */
    getAccountFilterData(options) {
        const { accountFilters, userGroup, userId } = options;
        return this.getBAIFilterData({
            inquiryId: 22017,
            fieldName: 'ACCOUNTFILTERLOOKUP',
            searchFieldName: 'ACCOUNTFILTER',
            searchFieldValue: accountFilters,
            userGroup,
            userId,
        });
    },

    /**
     * Get report types for the current user
     * @param {Object} data - containing userGroup and userId info
     * @returns {Promise}
     */
    getReportTypes({ userGroup, userId }) {
        return post(REPORT_TYPES_ENDPOINT, {
            userGroup: Array.isArray(userGroup) ? userGroup[0] : userGroup,
            userId: Array.isArray(userId) ? userId[0] : userId,
        });
    },

    /**
     * Get a list of bank reports
     * @returns {Promise}
     */
    getBankReports(userGroup, userId) {
        let data = {
            inquiryId: 72303,
            action: {
                functionCode: 'MAINT',
                typeCode: 'SCHEXP',
                productCode: 'GIR',
            },
        };
        if (systemConfig.isAdmin()) {
            data = {
                ...data,
                customFilters: [
                    {
                        filterName: 'Depends',
                        filterParam: [
                            'USERGROUP',
                            userGroup,
                        ],
                    },
                    {
                        filterName: 'Depends',
                        filterParam: [
                            'USER',
                            userId,
                        ],
                    },
                ],
            };
            return this.getInquiryData(data, SCHEDULED_EXPORTS_GET_QUERY_RESULTS);
        }
        return this.getInquiryData(data);
    },

    /**
     * Get all export type codes for balance and transaction
     * @returns {Promise}
     */
    getFileFormats(userGroup, userId) {
        const customFilters = systemConfig.isAdmin() ? [
            {
                filterName: 'Depends',
                filterParam: [
                    'USERGROUP',
                    userGroup,
                ],
            },
            {
                filterName: 'Depends',
                filterParam: [
                    'USER',
                    userId,
                ],
            },
        ] : [];
        const data = {
            inquiryRequest: {
                searchCriteria: {
                    customFilters,
                    inquiryId: 22022,
                    searchType: '5',
                    action: {
                        functionCode: 'EXPORT',
                        typeCode: 'SEARCH',
                        actionMode: 'SEARCH',
                        productCode: 'GIR',
                        entryMethod: 0,
                    },
                },
            },
        };
        return post(services.inquiryData, data);
    },

    /**
     * Get a list of saved bank report configruations
     * @returns {Promise}
     */
    getSavedBankReportConfigs(reportId, userGroup, userId) {
        const data = {
            customFilters: [
                {
                    filterName: 'Depends',
                    filterParam: [
                        'USERGROUP',
                        userGroup,
                    ],
                },
                {
                    filterName: 'Depends',
                    filterParam: [
                        'USER',
                        userId,
                    ],
                },
                {
                    filterName: 'Depends',
                    filterParam: [
                        'ID',
                        reportId,
                    ],
                },
            ],
            inquiryId: 72304,
            action: {
                functionCode: 'MAINT',
                typeCode: 'SCHEXP',
                productCode: 'GIR',
            },
        };
        if (systemConfig.isAdmin()) {
            return this.getInquiryData(data, SCHEDULED_EXPORTS_GET_QUERY_RESULTS);
        }
        return this.getInquiryData(data);
    },

    /**
     * User inquiry quest results to fetch data for transmission profiles
     * @returns {Promise}
     */
    getTransmissionProfiles(userGroup) {
        let data = {
            inquiryId: 17320,
            action: {
                functionCode: 'MAINT',
                typeCode: 'TRMPRFL',
                productCode: 'GIR',
            },
        };
        if (userGroup) {
            data = {
                ...data,
                customFilters: [{
                    filterName: 'Depends',
                    filterParam: [
                        'USERGROUP',
                        Array.isArray(userGroup) ? userGroup[0] : userGroup,
                    ],
                }],
            };
        }
        return this.getInquiryData(data)
            .then(getFlexDropdownFormat);
    },

    /**
     * Use the inquiry query results endpoint to fetch data
     * based on the post body
     * @param {Object} options - options object to customize
     * the post data
     * @param {string} [url]
     * @returns {Promise}
     */
    getInquiryData(options, url = services.inquiryQueryResults) {
        const data = {
            IncludeMapData: 1,
            queryCriteria: {
                customFilters: options.customFilters || [],
                inquiryId: options.inquiryId,
                allowDuplicates: null,
                action: {
                    functionCode: '*',
                    typeCode: '*',
                    actionMode: 'SELECT',
                    productCode: '_ADMIN',
                    entryMethod: '0',
                    ...options.action,
                },
            },
            requestHeader: {
                queryPagesize: 200,
            },
        };
        return post(url, data);
    },

    getAccountGroupData(userGroup, userId, accountGroupValue) {
        const data = {
            rowsPerPage: 50,
            startRow: 1,
            fieldName: 'ACCOUNTGROUPLOOKUP',
            typeCode: 'SCHEXP',
            productCode: 'GIR',
            functionCode: 'MAINT',
            entryClass: null,
            depends: [
                {
                    name: 'USERGROUP',
                    value: userGroup,
                },
                {
                    name: 'USERID',
                    value: userId,
                },
            ],
            requestParameters: {
                item: [{
                    name: 'PRODUCTCODE',
                    value: 'GIR',
                },
                {
                    name: 'FUNCTIONCODE',
                    value: 'MAINT',
                },
                {
                    name: 'TYPECODE',
                    value: 'SCHEXP',
                },
                {
                    name: 'INQUIRYID',
                    value: 22014,
                },
                ],
            },
            searchFields: [{
                fieldName: 'ID',
                operator: 'IN',
                fieldValue: accountGroupValue,
                dataType: 'text',
            }],
        };
        return post(services.tableMaintLookup, data);
    },

    /**
     * Fetch email recipients for report types
     * @param {string} url - request url
     * @param {object} data - data to be used in the request
     * @returns {Promise}
     */
    getEmailRecipients(url, data) {
        return post(services.generateUrl(url), data)
            .then(this.formatEmailRecipients);
    },

    /**
     * Format each item in the email recipients response
     * @param {Object} EmailRecipients - response
     */
    formatEmailRecipients({ emailEntitlements = [] }) {
        return emailEntitlements;
    },

    /**
     * Determine whether or not to show the email recipients
     * @returns {boolean}
     */
    shouldShowEmailRecipients() {
        return configParams.get('ScheduledExportEmailDistribution') === 'true';
    },

    /**
     * Get all the entitled users for scheduled exports
     * @param {string} searchValue - value to search for in user name
     * @param {string} userGroup
     */
    getAllUsers(searchValue, userGroup) {
        const data = {
            userGroup,
            searchValue,
        };
        return post(services.generateUrl('export/scheduledExport/getUsers'), data);
    },
    /**
     * Get a single user based on id and usergroup
     * @param {string} userGroup
     * @param {string} userId
     */
    getUser(userGroup, userId) {
        const data = {
            userGroup,
            userId,
        };
        return post(services.generateUrl('export/scheduledExport/getUser'), data);
    },

    /**
     * Get the first id from the first index in the array of groups
     * @param {Array} selectedGroups - array of selected groups
     * @returns {string} - id
     */
    getUserGroupId(selectedGroups) {
        if (!selectedGroups.length) {
            return '';
        }
        const [selected] = selectedGroups;
        return selected.id;
    },

    /**
     * Return a boolean based on output and exportTypecode
     * @param {string} output
     * @param {string} exportTypeCode
     * @returns {boolean}
     */
    showContinuationAccountSummaryRecord(output, exportTypeCode) {
        const validOutput = ['GIRBALANDTRANS', 'GIRTRANS'];
        const validExportType = [
            constants.EXPORT_TYPE.CSV,
            constants.EXPORT_TYPE.CSVEXP,
            constants.EXPORT_TYPE.TSV,
            constants.EXPORT_TYPE.TSVEXP,
        ];
        return configParams.get('SeparateTransDetailsMultiColumns') === 'true'
            && validOutput.includes(output) && validExportType.includes(exportTypeCode);
    },
    /**
     * Post data to isValidName endpoint to validate if the schedule name
     * is unique
     * @param {Object} data - data to submit in post body
     * @returns {Promise}
     */
    validateScheduleName(data) {
        return post(services.generateUrl('export/scheduledExport/validate'), data);
    },
};
