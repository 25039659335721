var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"submitted-accordion\">\n                        <div role=\"tabpanel\" id=\"rtpSubId\">\n                            <div class=\"row accordion-titlebar\">\n                               <a class=\"col-9 accordion-link collapsed\" role=\"button\" data-toggle=\"collapse\" href=\"#SubmittedCollapseArea\" aria-expanded=\"false\" aria-controls=\"SubmittedCollapseArea\">\n                                <span class=\"icon-plus\"></span> <span class=\"icon-minus\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rtp.paymentsSubmitted",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":97},"end":{"line":17,"column":131}}}))
    + "\n                                </a>\n                                <div class=\"col-3 banner\">\n                                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"insertEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":36},"end":{"line":20,"column":208}}})) != null ? stack1 : "")
    + "\n                                    <span aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rtp.number_unread_messages",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":54},"end":{"line":21,"column":93}}}))
    + "\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rtp.number_unread_messages",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":102},"end":{"line":21,"column":141}}}))
    + "\">\n                                        <span class=\"payment-count RealTimePayments-badge\" data-hook=\"getSubmittedCount\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"paymentsSubmittedCount") || (depth0 != null ? lookupProperty(depth0,"paymentsSubmittedCount") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"paymentsSubmittedCount","hash":{},"data":data,"loc":{"start":{"line":22,"column":121},"end":{"line":22,"column":147}}}) : helper)))
    + "</span>\n                                        <span class=\"icon-chat-bubble\" data-hook=\"getSubmittedChatBubble\"></span>\n                                    </span>\n                                </div>\n                            </div>\n                        </div>\n                        <div id=\"SubmittedCollapseArea\" class=\"panel-collapse collapse\" role=\"region\" data-accordionid=\"paymentsSubmitted\" aria-labelledby=\"rtpSubId\">\n                            <div>\n                                <div data-region=\"rtpSubmittedPaymentsRegion\"></div>\n                            </div>\n                        </div>\n                    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button class=\"new-payment-btn btn btn-secondary\" type=\"button\" name=\"button\" data-hook=\"insertBtn\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"rtp.insert_payment",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":161},"end":{"line":20,"column":192}}}))
    + "</button>";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"received-accordion\">\n                        <div role=\"tabpanel\" id=\"rtpReceivedId\">\n                            <div class=\"row accordion-titlebar\">\n                                <a class=\"col-11 accordion-link collapsed\" role=\"button\" data-toggle=\"collapse\" href=\"#ReceivedCollapseArea\" aria-expanded=\"false\" aria-controls=\"ReceivedCollapseArea\">\n                                <span class=\"icon-plus\"></span><span class=\"icon-minus\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rtp.paymentsReceived",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":43,"column":96},"end":{"line":43,"column":129}}}))
    + "\n                                </a>\n                                <div class=\"col-1 banner\" aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rtp.number_unread_messages",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":45,"column":70},"end":{"line":45,"column":109}}}))
    + "\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rtp.number_unread_messages",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":45,"column":118},"end":{"line":45,"column":157}}}))
    + "\">\n                                    <span class=\"payment-count RealTimePayments-badge\" data-hook=\"getReceivedCount\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"paymentsReceivedCount") || (depth0 != null ? lookupProperty(depth0,"paymentsReceivedCount") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"paymentsReceivedCount","hash":{},"data":data,"loc":{"start":{"line":46,"column":116},"end":{"line":46,"column":141}}}) : helper)))
    + "</span>\n                                    <span class=\"icon-chat-bubble\"  data-hook=\"getReceivedChatBubble\"></span>\n                                </div>\n                            </div>\n                        </div>\n                        <div id=\"ReceivedCollapseArea\" class=\"panel-collapse collapse\" role=\"region\" data-accordionid=\"paymentsReceived\" aria-labelledby=\"rtpReceivedId\">\n                            <div>\n                                <div data-region=\"rtpReceivedPaymentsRegion\"></div>\n                            </div>\n                        </div>\n                    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"rtp.pageHeading",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":59}}}))
    + "</h1>\n</div>\n<div class=\"page\">\n    <div class=\"section-body\">\n        <div class=\"section-container RealTimePayments\">\n            <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n            <div class=\"PaymentAccordions\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"submittedEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":34,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"receivedEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":16},"end":{"line":57,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});