var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"REPORTID") || (depth0 != null ? lookupProperty(depth0,"REPORTID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"REPORTID","hash":{},"data":data,"loc":{"start":{"line":5,"column":43},"end":{"line":5,"column":55}}}) : helper)))
    + " "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.Selected",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":56},"end":{"line":5,"column":81}}}))
    + "</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"form-control-static\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"PAY.All",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":43},"end":{"line":7,"column":63}}}))
    + "</p>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"form-control-static\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"REPORTDESC") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + " "
    + alias1(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"PAY.Selected",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":65},"end":{"line":13,"column":90}}}))
    + "</p>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"form-control-static\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"LEGACYREPORTDATE") || (depth0 != null ? lookupProperty(depth0,"LEGACYREPORTDATE") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"LEGACYREPORTDATE","hash":{},"data":data,"loc":{"start":{"line":21,"column":43},"end":{"line":21,"column":63}}}) : helper)))
    + "</p>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"form-control-static\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"relativeDateSet.Today",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":43},"end":{"line":23,"column":77}}}))
    + "</p>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"form-control-static\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"REPORTORDOWNLOAD") : depth0),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":43},"end":{"line":29,"column":70}}}))
    + "</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"col-3\">\n        <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"legacy.system.report.id",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":15},"end":{"line":3,"column":51}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"REPORTID") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"col-3\">\n        <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"legacy.system.report.desc",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":15},"end":{"line":11,"column":53}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"REPORTDESC") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":16,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"col-3\">\n        <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.Date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":15},"end":{"line":19,"column":36}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"LEGACYREPORTDATE") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"col-3\">\n        <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"legacy.system.file.type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":15},"end":{"line":27,"column":51}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"REPORTORDOWNLOAD") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":32,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});