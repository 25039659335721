import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, ashTheme } from '@glu/theming';
import locale from '@glu/locale';
import { legacyTheme as btiqeLegacyTheme } from '@dbiqe/common';

import Layout from 'system/gluOverride/layout';


const MobileGrid = React.lazy(() => import('components/MobileGrid/MobileGrid'));
const TabbedWorkspace = React.lazy(() => import('components/TabbedWorkspace/TabbedWorkspace'));

const loadingText = locale.get('loading');

export const asView = (Component, options = {}) => Layout.extend({
    template: () => '',

    onRender() {
        ReactDOM.render(
            (
                <ThemeProvider theme={btiqeLegacyTheme} baseTheme={ashTheme}>
                    <Component {...options} {...this.options} />
                </ThemeProvider>
            ), this.$el[0],
        );
    },

    onBeforeClose() {
        ReactDOM.unmountComponentAtNode(this.$el[0]);
    },
});

export const getGridComponent = opts => (
    <ThemeProvider theme={btiqeLegacyTheme} baseTheme={ashTheme}>
        <Suspense fallback={(
            <div>
                {loadingText}
                ...
            </div>
        )}
        >
            <MobileGrid {...opts} />
        </Suspense>
    </ThemeProvider>
);

export const getWorkspaceComponent = opts => (
    <ThemeProvider theme={btiqeLegacyTheme} baseTheme={ashTheme}>
        <Suspense fallback={(
            <div>
                {loadingText}
                ...
            </div>
        )}
        >
            <TabbedWorkspace {...opts} />
        </Suspense>
    </ThemeProvider>
);

export default asView;
