var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<input aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":2,"column":46},"end":{"line":2,"column":54}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"accountNumber") || (depth0 != null ? lookupProperty(depth0,"accountNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountNumber","hash":{},"data":data,"loc":{"start":{"line":2,"column":55},"end":{"line":2,"column":72}}}) : helper)))
    + "\" id=\"checkbox-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":87},"end":{"line":2,"column":93}}}) : helper)))
    + "\" type=\"checkbox\" name=\"account\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"accountFilter") || (depth0 != null ? lookupProperty(depth0,"accountFilter") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountFilter","hash":{},"data":data,"loc":{"start":{"line":2,"column":133},"end":{"line":2,"column":150}}}) : helper)))
    + "\"";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isChecked") || (depth0 != null ? lookupProperty(depth0,"isChecked") : depth0)) != null ? helper : alias2),(options={"name":"isChecked","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":151},"end":{"line":2,"column":187}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isChecked")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">";
},"2":function(container,depth0,helpers,partials,data) {
    return " checked";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"group-count col-5\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.group",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":63},"end":{"line":11,"column":85}}}))
    + "\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"groupCount") || (depth0 != null ? lookupProperty(depth0,"groupCount") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"groupCount","hash":{},"data":data,"loc":{"start":{"line":11,"column":87},"end":{"line":11,"column":101}}}) : helper)))
    + " "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.groups",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":102},"end":{"line":11,"column":125}}}))
    + "</span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression((lookupProperty(helpers,"customizedIcon")||(depth0 && lookupProperty(depth0,"customizedIcon"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"customizedIcon","hash":{"className":"btn btn-tertiary","action":"delete","text":(depth0 != null ? lookupProperty(depth0,"dynamicTitle") : depth0),"name":"close"},"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":15,"column":119}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasCheckbox") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":195}}})) != null ? stack1 : "")
    + "\n\n        <div class=\"list-builder-item-content row\">\n                <label class=\"col-7\" for=\"checkbox-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":5,"column":51},"end":{"line":5,"column":57}}}) : helper)))
    + "\">\n                        <span class=\"client-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":6,"column":50},"end":{"line":6,"column":58}}}) : helper)))
    + "</span>\n                        <span class=\"account-number\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"gir.manageAccountGroups.acccountNumberShort",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":53},"end":{"line":7,"column":109}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"accountNumber") || (depth0 != null ? lookupProperty(depth0,"accountNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountNumber","hash":{},"data":data,"loc":{"start":{"line":7,"column":110},"end":{"line":7,"column":127}}}) : helper)))
    + "</span>\n                </label>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showGroupCount") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":12,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasDeleteButton") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":16,"column":23}}})) != null ? stack1 : "")
    + "        </div>\n</div>\n";
},"useData":true});