import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import template from './nachaHold.hbs';

export default ItemView.extend({
    template,

    events: {
        'click @ui.$checkbox': 'holdClicked',
    },

    ui: {
        $checkbox: '[data-hook="getNachaHoldInput"]',
    },

    templateHelpers() {
        const self = this;

        return {
            id: this.model.cid,
            isCheckedText: self.model.get('HOLDFLAG') === '1' ? locale.get('common.yes') : locale.get('common.no'),
            isChecked: self.model.get('HOLDFLAG') === '1' ? 'checked' : '',
        };
    },

    holdClicked() {
        const self = this;
        this.model.set({
            HOLDFLAG: this.ui.$checkbox.prop('checked') ? '1' : '0',
        }, {
            silent: true,
        });

        this.model.set('ENTRYMETHOD', this.model.context.PARENTENTRYMETHOD);

        this.model.save({}, {
            success() {
                /*
                 * status and status description must be cleared out when
                 * you edit a bene in the grid
                 */
                self.model.set({
                    STATUS: '',
                    STATUS_DESCRIPTION: '',
                });
                self.appBus.trigger('nachaChild:hold:update:success');
            },
            error(response) {
                self.appBus.trigger('nachaChild:hold:update:error', response);
            },
            silent: true,
        });
    },
});
