import actionsLocale from '../components/Actions/defaultStrings_en_US';
import controlBarLocale from '../components/ControlBar/defaultStrings_en_US';
import gridLocale from '../components/Grid/defaultStrings_en_US';
import subRowControlLocale from '../components/SubRowControl/defaultStrings_en_US';

export default {
  ...actionsLocale,
  ...controlBarLocale,
  ...gridLocale,
  ...subRowControlLocale
};
