import 'guillotine';
import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import Grid from '@glu/grid';
import Collection from '@glu/core/src/collection';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import alert from '@glu/alerts';
import transform from 'common/util/transform';
import services from 'services';
import Formatter from 'system/utilities/format';
import locale from '@glu/locale';
import $ from 'jquery';
import browser from '@glu/core/src/browser';
import csv from 'common/csv';
import IEUtil from 'common/util/IEUtil';
import ActionCell from 'common/dynamicPages/views/gridActionCell';
import dynamicTypeSearch from 'system/gridOverride/views/dynamicTypeSearch';
import Constants from 'app/balanceAndTransaction/constants';
import CheckReviewAmount from 'common/dynamicPages/views/gridCells/checkReviewAmount';
import { moveToTopCheck } from 'common/util/deeplinkUtil';
import checkReviewTpl from './checkReview.hbs';
import checkPrintTpl from './checkPrint.hbs';

const split = function (a) {
    const len = a.length;
    const out = [];
    let i = 0;
    let n = 3;
    while (i < len) {
        const size = Math.ceil((len - i) / n);
        n -= 1;
        out.push(a.slice(i, i += size));
    }
    return out;
};
const CustomGrid = Grid.extend();
CustomGrid.prototype.filterViewClass = dynamicTypeSearch;

const CheckImageView = Layout.extend({
    template: checkReviewTpl,
    className: 'check-review',

    events: {
        'click @ui.$rotateBtn': 'handleRotate',
        'click @ui.$invertBtn': 'handleInvert',
        'click .zoom-in': 'handleZoomIn',
        'click .zoom-out': 'handleZoomOut',
        'click .save': 'handleSave',
        'click .print': 'handlePrint',
        'click [data-hook="getExportBtn"]': 'handleExportAll',
    },

    ui: {
        $checkThumbnails: '.check-thumbnail',
        $checkContainer: '.check-container',
        $checkFront: '.check-front',
        $checkBack: '.check-back',
        $invertBtn: '.invert',
        $rotateBtn: '.rotate',
        $flipBtn: '.flip',
        $exportAll: '[data-hook="getExportBtn"]',
        $tooltips: '[data-toggle="tooltip"]',
    },

    initialize(options) {
        this.populateImagesArray();
        this.dialogTitle = locale.get('common.check.detail');

        this.dialogButtons = [{
            text: locale.get('common.cancel'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }];

        this.imageIndex = 0;
        this.source = options.source;
        this.showThumbnails = options.showThumbnails || true;
        this.hideImageList = options.hideImageList || util.isEmpty(this.model.get('depositSlipItems'));
        this.hideExportBtn = options.hideExportBtn || util.isEmpty(this.model.get('depositSlipItems'));
        if (this.model.has('selectedImage') && this.model.get('selectedImage') < this.model.get('images').length) {
            this.imageIndex = this.model.get('selectedImage');
        }
        this.imageResponse = options.result;
    },

    onRender() {
        moveToTopCheck(this.model);
        this.setImage(this.imageIndex);
        this.buildGrid();
        this.setExportAllData();
        this.renderMessage();
        if (this.showThumbnails && this.imageIndex) {
            this.ui.$checkThumbnails.find('img').removeClass('selectedImage').eq(this.imageIndex).addClass('selectedImage');
        }

        if (this.options && this.options.checkIndex) {
            $('.check-viewer-header .prev-page').prop('disabled', this.options.checkIndex === 0);
            $('.check-viewer-header .next-page').prop('disabled', this.options.checkIndex === this.options.numberOfChecks - 1);
        }

        this.ui.$tooltips.tooltip({
            container: this.ui.$checkContainer,
            trigger: 'manual',
        });

        this.ui.$tooltips.parent().on('mouseenter', function () {
            $(this).children().tooltip('show');
        });
        this.ui.$tooltips.parent().on('mouseleave', function () {
            $(this).children().tooltip('hide');
        });
    },

    /**
     * Fill images array if it's not already populated
     */
    populateImagesArray() {
        if (!util.isEmpty(this.model.get('images'))) {
            return;
        }
        const imageArray = [
            {
                IMAGE: this.model.get('image_data'),
                IMAGETYPE: this.model.get('image_type'),
            },
            {
                IMAGE: this.model.get('image_data_reverse'),
                IMAGETYPE: this.model.get('image_type_reverse'),
            },
        ];

        this.model.set('images', imageArray);
    },

    setImage(imageIndex) {
        let frontSrc;
        let backSrc;

        if (this.hasValidData()) {
            if (imageIndex === 0) {
                if (!util.isEmpty(this.model.get('image_data'))) {
                    frontSrc = this.buildImageSrc(this.model.get('image_data'), this.model.get('image_type'));
                    backSrc = this.buildImageSrc(this.model.get('image_data_reverse'), this.model.get('image_type_reverse'));
                } else {
                    const [imgModel] = this.model.get('images');
                    frontSrc = this.buildImageSrc(imgModel.IMAGE, imgModel.IMAGETYPE);
                }
            } else if (imageIndex <= this.model.get('images').length) {
                const imageModel = this.model.get('images')[imageIndex];

                if (!imageModel.frontSrc && !imageModel.backSrc) {
                    frontSrc = this.buildImageSrc(imageModel.IMAGE, imageModel.IMAGETYPE);
                } else {
                    frontSrc = this.buildImageSrc(imageModel.frontSrc, imageModel.frontType);
                    backSrc = this.buildImageSrc(imageModel.backSrc, imageModel.backType);
                }
            }

            if (frontSrc) {
                const frontImage = $('<img id="check-front-image">');
                frontImage.attr('src', frontSrc);
                frontImage.attr('alt', locale.get('rdc.Front'));
                frontImage.attr('tabindex', 0);

                frontImage.load(function () {
                    frontImage.guillotine({
                        width: this.width,
                        height: this.height,
                    });
                });

                this.ui.$checkFront.html(frontImage);

                let backImage = null;

                if (backSrc) {
                    backImage = $('<img id="check-back-image">');
                    backImage.attr('src', backSrc);
                    backImage.attr('alt', locale.get('rdc.Image.Drawer.Back'));
                    backImage.attr('tabindex', 0);

                    backImage.load(function () {
                        backImage.guillotine({
                            width: this.width,
                            height: this.height,
                        });
                    });

                    this.ui.$checkBack.html(backImage);
                }

                this.frontImage = frontImage;
                this.backImage = backImage;
            }
        }
    },

    buildGrid() {
        if (!util.isEmpty(this.model.get('depositSlipItems')) && this.model.get('isModal') !== true) {
            const imageCollection = new Collection(this.model.get('depositSlipItems'));

            imageCollection.each((modelParam) => {
                const model = modelParam;
                model.buttons = [];
                if (model.get('IS_IMAGE_AVAILABLE') === 'true') {
                    model.buttons.push({
                        value: 'action_view',
                        label: locale.get('common.view'),
                        action: 'view',
                    });
                }
                model.set('DISPLAY_STRING', model.get('AMOUNT'));
                const unformattedNumber = Formatter.unformatNumber(model.get('AMOUNT'));
                model.set('AMOUNT', unformattedNumber);
            });

            const columns = [{
                cellView: ActionCell,
                display: 'dropdown',
                label: locale.get('common.action.plural'),
                sortable: false,
                filterable: false,
                disableRemove: true,
                condition: true,
            }];

            const headers = util.omit(this.model.get('headerLabels'), 'ACCOUNT_TITLE', 'POST_DATE');

            Object.keys(headers || {}).forEach((x) => {
                let type = 'string';
                if (x === 'CUST_REF') {
                    type = 'number';
                }
                if (x === 'AMOUNT') {
                    type = 'amount';
                }
                columns.push({
                    field: x,
                    type,
                    title: headers[x],
                    label: headers[x],
                    cellView: x === 'AMOUNT' ? CheckReviewAmount : undefined,
                });
            });

            const grid = new CustomGrid({
                collection: imageCollection,
                filterable: true,
                pageable: true,
                columns,
            });

            this.columns = columns;

            if (this.imageListGrid && !this.hideImageList) {
                this.imageListGrid.show(grid);
            }

            this.gridView = grid;
            this.imageCollection = imageCollection;
            this.listenTo(this.appBus, ('grid:row:action:action_view'), this.gridViewAction);
        }
    },

    selectImage(e) {
        this.ui.$checkThumbnails.find('img').removeClass('selectedImage');
        this.setImage(this.$el.find(e.currentTarget).index());
        this.$el.find(e.currentTarget).find('img').addClass('selectedImage');
    },

    setExportAllData() {
        if (!this.hideExportBtn) {
            if (!browser.msie) {
                const data = this.getDataToExport();
                let uri;
                if (data.length > 0) {
                    uri = `data:text/csv;base64,${csv.encode(data, this.columns)}`;
                }
                this.ui.$exportAll.attr('href', uri);
                this.ui.$exportAll.attr('download', 'download.csv');
            }
        }
    },

    getDataToExport() {
        const headerData = this.model.get('headerData');
        const depositItem = {
            Date: headerData.POST_DATE,
            Amount: headerData.AMOUNT,
            'Image Description': locale.get('imageSearch.imageType.deposit'),
            'Account Number': headerData.ACCOUNT_NUMBER_DISP,
            ABA: headerData.BANK_ID,
        };

        const recurseLabelsAndValues = (arr, objParam) => {
            const obj = objParam;
            if (Object.prototype.hasOwnProperty.call(arr, 'label')) {
                obj[arr.label] = arr.value;
                return arr;
            }
            for (let i = 0; i < arr.length; i += 1) {
                recurseLabelsAndValues(arr[i], obj);
            }
            return obj;
        };

        const checkItems = this.model.get('depositSlipItems').map((item, index) => {
            const allItems = this.getCheckImagesByIndex(index);
            const csvItem = split(allItems);

            return recurseLabelsAndValues(csvItem, {});
        });

        return [depositItem, ...checkItems];
    },

    getCheckImagesByIndex(index) {
        const checkImgs = this.model.get('depositSlipItems');
        const headerLabels = this.model.get('headerLabels');

        return Object.keys(headerLabels || {})
            .filter(x => util.has(checkImgs[index], x))
            .map(x => ({
                label: headerLabels[x],
                value: checkImgs[index][x],
            }));
    },

    getHeaderObjectByIndex(index) {
        let localIndex = index;
        let headerLabels = this.model.get('headerLabels');
        let headerDataObject = this.getHeaderDataObject();
        const topHeaderLabels = this.model.get('topHeaderLabels');

        if (!util.isNullOrUndefined(topHeaderLabels) && !util.isEmpty(topHeaderLabels)) {
            headerLabels = topHeaderLabels;
        }

        if (util.isArray(this.model.get('headerData'))) {
            if (util.isUndefined(localIndex)) {
                localIndex = this.imageIndex;
            }

            if (localIndex > 0) {
                headerDataObject = new Model(this.model.get('headerData')[localIndex]);
            }
        }

        if (headerDataObject.has('AMOUNT')) {
            const amount = headerDataObject.get('AMOUNT');
            headerDataObject.set('AMOUNT', Formatter.formatNumber(amount));
        }

        return this.getHeaderObject(headerLabels, headerDataObject);
    },

    /**
     * @method getHeaderDataObject
     * @returns {{headerDataObject: Model}}
     * moved this line as separate method so that
     * it can be overriden by child implemetations.
     */
    getHeaderDataObject() {
        return this.model.get('selectedImage') ? new Model(this.model.get('images')[this.model.get('selectedImage')]) : new Model(this.model.get('headerData'));
    },

    getHeaderObject(headerLabels, dataModel) {
        const allItems = Object.keys(headerLabels || {})
            .filter(x => dataModel.has(x) && dataModel.get(x))
            .map(x => ({
                label: headerLabels[x],
                value: dataModel.get(x),
            }));

        return split(allItems);
    },

    /**
     * @param {object} options
     * @param {Model} options.model
     * @param {number} options.index
     * @param {boolean} options.result
     */
    viewChildCheckModal(options) {
        const dialogModel = options.model;
        dialogModel.set({
            selectedImage: options.index,
            isModal: true,
        });

        const checkViewDialog = new CheckImageView({
            model: dialogModel,
            result: options.result,
        });

        dialog.open(checkViewDialog);
        /*
         * Unset the items above on close.
         * Nested modals (dialog) use the same model.
         * The added keys cause problems when reopening the first modal.
         */
        checkViewDialog.once('close', () => {
            dialogModel.unset('selectedImage');
            dialogModel.unset('isModal');
            dialogModel.unset('imgRetrievalErrorMessage');
            dialogModel.unset('respMessages');
        });
    },

    gridViewAction(model) {
        const index = this.imageCollection.models.findIndex(m => m.cid === model.cid);
        const subModel = util.omit(this.model.get('depositSlipItems')[index], 'ACCOUNT_NUMBER_DISP');
        // to fetch or not to fetch

        if (subModel.frontSrc) {
            this.viewChildCheckModal({
                model,
                index,
                result: true,
            });
        } else {
            const item = transform.hashToPairs(subModel);
            let url = Constants.CHECK_PAID_IMAGE_SERVICE;

            if (this.source && this.source === 'IMAGESEARCH') {
                url = Constants.CHECK_PAID_IMAGE_SERVICE_SEARCH;
            } else if (this.source && this.source === 'CD') {
                url = Constants.CD_IMAGE_SERVICE;
            }

            item.push({
                name: 'ACCOUNTFILTER',
                value: this.model.get('ACCOUNTFILTER'),
            }, {
                name: 'IMAGETYPE',
                value: 'CHECK',
            }, {
                name: 'DEPOSIT_SLIP_IMG_SEARCHKEY',
                value: this.model.get('IMAGEID') || this.model.get('id'),
            }, {
                name: '_productCode',
                value: this.model.get('PRODUCT'),
            }, {
                name: '_functionCode',
                value: this.model.get('FUNCTION'),
            });

            if (this.model.has('imgRetrievalErrorMessage')) {
                this.model.set('imgRetrievalErrorMessage', '');
            }

            http.post(
                services.generateUrl(url),
                {
                    item,
                },
                (response) => {
                    const msgs = response.messages;
                    const headerData = transform.pairsToHash(response.headerData.item);
                    const headerLabels = transform.pairsToHash(response.headerLabels.item);
                    const topHeaderLabels = transform.pairsToHash(response.topHeaderLabels.item);
                    const images = [];
                    const depositSlipItems = [];

                    util.each(response.images, (image) => {
                        images.push(transform.pairsToHash(image.item));
                    });

                    util.each(response.depositSlipItems, (depositItem) => {
                        depositSlipItems.push(transform.pairsToHash(depositItem.item));
                    });

                    if (headerData.AMOUNT) {
                        headerData.AMOUNT = Formatter.formatNumber(headerData.AMOUNT);
                    }
                    model.set({
                        images,
                        headerData,
                        topHeaderLabels,
                        headerLabels,
                        depositSlipItems,
                        image_data: !util.isEmpty(response.frontImage) ? response.frontImage.value : '',
                        image_type: !util.isEmpty(response.frontImage) ? response.frontImage.contentType : '',
                        image_data_reverse: !util.isEmpty(response.backImage) ? response.backImage.value : '',
                        image_type_reverse: !util.isEmpty(response.backImage) ? response.backImage.contentType : '',
                    });

                    if (!response.result && util.isArray(msgs) && msgs.length > 0) {
                        this.model.set({
                            imgRetrievalErrorMessage: msgs[0],
                            respMessages: msgs,
                        });
                    }

                    this.viewChildCheckModal({
                        model,
                        index,
                        result: response.result,
                    });
                },
            );
        }
    },

    handleZoomOut() {
        this.frontImage.guillotine('zoomOut');
    },

    handleZoomIn() {
        this.frontImage.guillotine('zoomIn');
    },

    handleInvert() {
        this.ui.$checkContainer.toggleClass('check-invert');
    },

    handleRotate() {
        this.frontImage.guillotine('rotateLeft');
        this.frontImage.guillotine('fit');
    },

    handleSave() {
        let image = null;

        if (this.ui.$checkFront.is(':visible')) {
            image = this.$el.find('#check-front-image');
        } else {
            image = this.$el.find('#check-back-image');
        }

        const imageSrc = image.prop('src');
        if (!imageSrc || imageSrc.indexOf('base64') === -1) {
            return;
        }

        const pieces = imageSrc.split(';');
        let type;
        let link;
        if (pieces.length) {
            // set image type if it's undefined
            if (pieces[0].indexOf('undefined') > -1) {
                pieces[0] = `data:${this.model.get('image_type')}`;
            }
            type = pieces[0].split('/');
            if (type.length >= 2) {
                // IE 10+
                if (navigator.msSaveBlob) {
                    navigator.msSaveBlob(IEUtil.getBlob(imageSrc.split(',')[1]), `image.${type[1]}`);
                } else {
                    /*
                     * Need the dom element here since jQuery doesn't call
                     * the native click event for links.
                     */
                    link = document.getElementById(`save-image-link_${this.cid}`);
                    link.setAttribute('href', imageSrc);
                    link.setAttribute('download', `image.${type[1]}`);
                    link.click();
                }
            }
        }
    },

    getImageJsonData() {
        const data = {};
        const frontImage = this.$el.find('#check-front-image');
        const backImage = this.$el.find('#check-back-image');
        let printImages = [];
        const imageArr = util.filter(this.model.get('images'), image => !util.isEmpty(image.IMAGE));
        let content;

        data.headerItems = this.getHeaderObjectByIndex();
        data.frontSrc = frontImage.prop('src');
        data.frontAlt = frontImage.prop('alt');

        if (backImage.length > 0) {
            data.backSrc = backImage.prop('src');
            data.backAlt = backImage.prop('alt');
        }

        if (this.gridView) {
            content = this.gridView.$('.content').clone();

            // remove the view column, hidden toggle buttons, sr-only stuff
            content.find('th:first-child, tr td:first-child, button.grid-column-menu-trigger, .sr-only, .column-header-icon').remove();

            // convert button to label for headers
            content.find('button.txt').replaceWith(function () {
                return $(this).html();
            });

            data.grid = content.html();
        }

        printImages = imageArr.map(image => ({
            frontAlt: data.frontAlt,
            grid: data.grid,
            headerItems: data.headerItems,
            frontSrc: `data:${image.IMAGETYPE};base64,${image.IMAGE}`,
        }));

        return {
            images: printImages,
        };
    },

    handlePrint() {
        this.print(this.getImageJsonData());
    },

    handleExportAll(e) {
        // IE 10+
        if (navigator.msSaveBlob) {
            e.preventDefault();

            const iedata = csv.convert(this.getDataToExport(), this.columns);
            csv.exportIE(iedata, 'download.csv');
        }
    },

    getPrintHTML() {
        return checkPrintTpl(this.getImageJsonData());
    },

    /**
     * @param {object} jsonData
     */
    print(jsonData) {
        // use custom Print handler if passed
        if (this.options.onPrint) {
            this.options.onPrint();
            return;
        }

        const element = window.open();
        element.document.write(checkPrintTpl(jsonData));
        element.document.close();
        element.focus();
        element.print();
        element.close();
    },

    cancel() {
        dialog.close();
        this.model.unset('isModal');
    },

    hasValidData() {
        return (util.isEmpty(this.model.get('image_data')) === false || this.model.get('images').length > 0);
    },

    /**
     * Check all sources of image data to see if there is none
     * @return {Boolean} Whether or not image data was found
     */
    hasNoImageData() {
        return (util.isEmpty(this.model.get('images')) || util.isEmpty(this.model.get('images')[0].IMAGE)) && util.isEmpty(this.model.get('image_data')) && util.isEmpty(this.model.get('FRONTIMAGE')) && util.isEmpty(this.model.get('frontImage'));
    },

    hasSpecificImageData() {
        if (this.imageIndex === 0 && util.isEmpty(this.model.get('image_data')) === false) {
            return true;
        }
        let check = this.model.get('images')[this.imageIndex];
        check = check ? (check.frontSrc || check.IMAGE) : {};

        if (!util.isEmpty(check) && (this.imageIndex > 0
            || (this.imageIndex === 0 && this.showThumbnails))) {
            return true;
        }
        return false;
    },

    hasMultipleImages() {
        return (this.model.get('images').length > 1);
    },

    buildImageSrc(data, type) {
        return `data:${type};base64,${data}`;
    },

    renderMessage() {
        if (this.model.get('respMessages') && this.model.get('respMessages').length) {
            // NH-68604 set the alert function to success on successful image retrieval
            const alertFunc = this.imageResponse ? alert.success : alert.warning;

            const messages = this.model.get('respMessages').join(' ');

            this.alertView = alertFunc(messages);
            this.alertRegion.show(this.alertView);
        }
    },

    templateHelpers() {
        const self = this;

        return {
            cid: this.cid,
            hasNoImages: this.hasNoImageData(),
            hasSpecificImage: this.hasSpecificImageData(),
            hasMultipleImages: this.hasMultipleImages(),
            hasValidData: this.hasValidData(),
            bulkCheckView: this.options.bulkCheckView,
            showThumbnails: this.showThumbnails,
            hideExportBtn: this.hideExportBtn,

            invertible() {
                const headerData = self.model.get('headerData');
                return headerData && headerData.INVERT_COLOR ? headerData.INVERT_COLOR.toUpperCase() === 'TRUE' : true;
            },

            headerItems() {
                return self.getHeaderObjectByIndex();
            },

            hasHeaderItems() {
                return (self.getHeaderObjectByIndex().length > 0);
            },

            getImgRetrievalErrorMessage() {
                return self.model.get('imgRetrievalErrorMessage');
            },

            thumbnails() {
                const thumbs = [];
                const imageArr = self.model.get('images');

                if (imageArr.length > 0) {
                    for (let x = 0; x < imageArr.length; x += 1) {
                        if (imageArr[x].IMAGE) {
                            thumbs.push({
                                IMAGE: imageArr[x].IMAGE,
                                LABEL: imageArr[x].LABEL,
                                TYPE: imageArr[x].IMAGETYPE,
                                ID: imageArr[x].IMAGE_ID || x + 1,
                            });
                        }
                    }
                }
                return thumbs;
            },
        };
    },
});

export default CheckImageView;
