export const TYPE = {
    SET_LOCALE_OPTIONS: 'SET_LOCALE_OPTIONS',
    SET_FOOTER_DATA: 'SET_FOOTER_DATA',
    SET_MODE: 'SET_MODE',
    SET_MARKET_SEGMENT_OPTIONS: 'SET_MARKET_SEGMENT_OPTIONS',
    SET_ENTITLEMENTS: 'SET_ENTITLEMENTS',
};
export default (state, action) => {
    switch (action.type) {
    case TYPE.SET_LOCALE_OPTIONS:
        return {
            ...state,
            localeOptions: action.payload,
        };
    case TYPE.SET_FOOTER_DATA:
        return {
            ...state,
            footerData: {
                ...state.footerData,
                ...action.payload,
            },
        };
    case TYPE.SET_MODE:
        return {
            ...state,
            mode: action.payload,
        };
    case TYPE.SET_MARKET_SEGMENT_OPTIONS:
        return {
            ...state,
            marketSegmentOptions: action.payload,
        };
    case TYPE.SET_ENTITLEMENTS:
        return {
            ...state,
            entitlements: action.payload,
        };
    default:
        throw new Error('Alert Reducer Type does not exist');
    }
};
