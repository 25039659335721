import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import errorHandlers from 'system/error/handlers';
import DataApi from 'common/dynamicPages/api/data';
import EntryView from 'common/dynamicPages/views/workflow/entry';
import constants from 'app/administration/constants';
import recipientGroupAssignmentModelOverride from 'app/administration/models/recipientGroupAssignment';
import { isDeepLinked } from '../../../../common/util/deeplinkUtil';

export default EntryView.extend({
    initialize(options) {
        EntryView.prototype.initialize.call(this, options);
    },

    onRender() {
        EntryView.prototype.onRender.call(this);
        if (this.mode === 'insert' && this.hasLoadedRequiredData() && !this.eventsLoaded) {
            this.eventsLoaded = true;
            this.listenTo(this.pageView, 'ui-loaded', this.syncModel);
        }
        if (isDeepLinked()) {
            this.$el.find('.page-header-wrapper').first().hide();
        }
    },

    loadRequiredData() {
        const self = this;

        if (this.mode === 'insert') {
            this.setHasLoadedRequiredData(true);
            this.render();
        } else {
            DataApi.model.generateModelFromModel(store.get(`${this.contextKey}-actionModel`)).then((newModel) => {
                let newModelParam = newModel;
                //  apply sync to model for modify
                newModelParam = util.extend(
                    {},
                    newModelParam,
                    recipientGroupAssignmentModelOverride,
                );

                newModelParam.fetch({
                    success() {
                        self.storedModel = newModelParam;
                        self.setHasLoadedRequiredData(true);
                        self.render();
                    },
                    error: util.bind(errorHandlers.loading, self),
                });
            });
        }
    },

    /**
     * @method syncModel
     * @description extends the pageView model with the sync methods in
     * recipientGroupAssignmentModelOverride
     */
    syncModel() {
        if (this.pageView.model) {
            this.pageView.model = util.extend(
                {},
                this.pageView.model,
                recipientGroupAssignmentModelOverride,
            );
        }
    },

    /**
     * @method getTheModel
     * @description override base class to return the model mixed with the
     * recipientGroupAssignmentModelOverride (sync functions)
     * @return {object} model
     *
     */
    getTheModel() {
        return util.extend({}, store.get(`${this.contextModel.getContextKey()}-actionModel`), recipientGroupAssignmentModelOverride);
    },

    /**
     * @method getModifyUrl
     * @description override base class to return recipient group modify url
     * @returns {*} URL - route to navigate to for modify
     */
    getModifyUrl() {
        return constants.RECIPIENTGROUPASSIGNMENTMODIFY_URL;
    },

});
