import util from '@glu/core/src/util';
import ReadOnlyRowView from './readOnlyRowView';

const CategorySumView = ReadOnlyRowView.extend({
    tagName: 'div',
    className: 'category-total',

    modelEvents: {
        'change:sum': 'render',
    },

    initialize(options) {
        this.viewModel = options.viewModel;
        this.listenTo(
            this.viewModel,
            {
                'change:displayFactor': this.render,
            },
            this,
        );
    },

    template: util.template('Total: <span><%= formattedSum %></span>'),

    templateHelpers() {
        return {
            formattedSum: this.getFormattedNumber(this.model.get('sum'), true),
        };
    },
});

export default CategorySumView;
