import Layout from '@glu/core/src/layout';
import messageViewTmpl from './messageView.hbs';

export default Layout.extend({
    template: messageViewTmpl,

    initialize(options) {
        this.messages = options.messages;
    },

    templateHelpers() {
        const self = this;
        return {
            messages() {
                return self.messages;
            },
        };
    },
});
