import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Layout from '@glu/layout-react';
import { withStyles } from '@glu/theming';
import ContentEditor from './ContentEditor';
import ContentEditorContext from './ContentEditorContext';
import DraggableArea from './DraggableArea';
import { getPillsDisplayArray, getPillMapper } from './util';

const styles = {
    dragAreaWrap: {
        paddingLeft: 22,
    },
};

const ContentEditorContainer = ({
    alertParameters,
    classes,
    content,
    contentType,
    hasPills,
    isShown,
    maxCharacterCount,
    onChange,
    ...props
}) => {
    const { setIsShown } = useContext(ContentEditorContext);
    useEffect(() => {
        setIsShown(isShown);
    }, [isShown, setIsShown]);

    const editorCols = hasPills ? 9 : 12;

    return (
        <Layout container>
            <Layout col={editorCols}>
                <ContentEditor
                    maxCharacterCount={maxCharacterCount}
                    content={content}
                    contentType={contentType}
                    onChange={onChange}
                    pillMap={hasPills ? getPillMapper(alertParameters) : {}}
                    {...props}
                />
            </Layout>
            {hasPills
            && (
                <Layout col={3} className={classes.dragAreaWrap}>
                    <DraggableArea pillContainers={getPillsDisplayArray(alertParameters)} />
                </Layout>
            )}
        </Layout>
    );
};

ContentEditorContainer.propTypes = {
    alertParameters: PropTypes.shape({
        EMAIL: {
            pillGroups: PropTypes.shape({
                FIELDS: PropTypes.shape({
                    label: PropTypes.string,
                    pills: PropTypes.array,
                }),
                BLOCKS: PropTypes.shape({
                    label: PropTypes.string,
                    pills: PropTypes.array,
                }),
            }),
            pills: PropTypes.shape({}),
        },
        TEXT: {
            pillGroups: PropTypes.shape({
                FIELDS: PropTypes.shape({
                    label: PropTypes.string,
                    pills: PropTypes.array,
                }),
                BLOCKS: PropTypes.shape({
                    label: PropTypes.string,
                    pills: PropTypes.array,
                }),
            }),
            pills: PropTypes.shape({}),
        },
    }),
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    /** Value for content editor text area */
    content: PropTypes.string.isRequired,
    /** Type of content: html or text */
    contentType: PropTypes.oneOf(['html', 'text']),
    /** Whether this editor should have parameter pills */
    hasPills: PropTypes.bool,
    isShown: PropTypes.bool,
    maxCharacterCount: PropTypes.number,
    onChange: PropTypes.func,
    hidePreview: PropTypes.bool,
};

ContentEditorContainer.defaultProps = {
    alertParameters: {},
    contentType: 'html',
    hasPills: true,
    isShown: true,
    maxCharacterCount: undefined,
    onChange: () => {},
    hidePreview: false,
};

export default withStyles(styles)(ContentEditorContainer);
