import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import validatorPatterns from 'system/validatorPatterns';
import services from 'services';
import userInfo from 'etc/userInfo';
import Constants from 'app/administration/constants';
import httpError from 'common/util/httpErrorResult';

export default Model.extend({
    defaults() {
        return {
            // by default this is set to -1 for new records
            uid: -1,
            channel: '',
            contact: '',
            emailAddress: '',
            passcode: '',
            verified: false,
            editable: false,
            default: false,
            // for loaded records, delete them on 'update' if this is true.
            removeRecord: false,
        };
    },

    parse(res) {
        /*
         * give objects from the server an ID so save & destroy make the correct
         * server calls
         */
        res.id = this.cid;
        return res;
    },

    initialize() {
        this.validators = {
            contact: {
                description: locale.get('administration.phonenumber'),
            },
            emailAddress: {
                description: locale.get('administration.emailAddress'),
                matches: validatorPatterns.EMAIL_PATTERN,
            },
        };

        if (this.get('channel') === Constants.PASSCODE_TEXT_CHANNEL) {
            this.validators.passcode = {
                description: locale.get('mfa.otp_singleUseCode'),
                exists: true,
            };
        }
    },

    sendActivateCode(options) {
        this.sync('sendCode', this, options);
    },

    validateActivateCode(options) {
        this.sync('validateCode', this, options);
    },

    sync(method, model, options) {
        let data = this.convertModelAttributesToServerJSON();

        if (method === 'update') {
            http.post(services.generateUrl('/tableMaintenance/OTPDestination/add'), data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        } else if (method === 'delete') {
            http.post(services.generateUrl('/tableMaintenance/OTPDestination/delete'), data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        } else if (method === 'sendCode') {
            data = {
                phoneNumber: this.get('contact'),
            };
            http.post(services.generateUrl('/mfaService/initiateActivatePhone'), data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        } else if (method === 'validateCode') {
            data = {
                phoneNumber: this.get('contact'),
                passCode: this.get('passcode'),
            };
            http.post(services.generateUrl('/mfaService/validateActivatePhone'), data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }
    },

    convertModelAttributesToServerJSON() {
        const jsonData = [];
        jsonData.push({
            name: 'USERGROUP',
            value: userInfo.get('group'),
        });
        jsonData.push({
            name: 'USERID',
            value: userInfo.get('id'),
        });
        jsonData.push({
            name: 'CHANNEL',
            value: this.get('channel'),
        });
        if (this.get('channel') === 'email') {
            jsonData.push({
                name: 'ADDRESS',
                value: this.get('emailAddress'),
            });
        } else {
            jsonData.push({
                name: 'ADDRESS',
                value: this.get('contact'),
            });
        }

        return {
            item: jsonData,
        };
    },
});
