import Layout from '@glu/core/src/layout';
import ItemView from '@glu/core/src/itemView';
import CollectionView from '@glu/core/src/collectionView';
import dialog from '@glu/dialog';
import GridApi from 'common/dynamicPages/api/grid';
import EnvelopeModel from 'app/reports/models/envelope';
import store from 'system/utilities/cache';
import NotesCollection from 'app/reports/collections/transactionNotes';
import notesTemplate from 'app/reports/views/lockbox/lockboxReportEnvelopeNotes.hbs';
import ModifyRemitterView from 'app/reports/views/lockbox/modals/remitter/modifyRemitter';
import ManageNotesView from 'app/reports/views/lockbox/modals/notes/manageNotes';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import template from 'app/reports/views/lockbox/lockboxReportEnvelope.hbs';
import loadingTemplate from 'common/templates/loadingPage.hbs';

const LockboxReportEnvelope = Layout.extend({
    template,
    loadingTemplate,

    ui: {
        $noteCount: '[data-hook="noteCount"]',
    },

    initialize(options) {
        this.envelopeDetails = store.get('envelopeDetails');
        this.returnRoute = options.returnRoute;
        this.model = new EnvelopeModel({
            envelopeDetails: this.envelopeDetails,
        });
        this.collection = new NotesCollection({
            eid: this.envelopeDetails.eid,
        });
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.loadEnvelopeDetailsGrid();
            this.loadTransactionNotes();
        } else {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        const self = this;
        this.model.fetch({
            success() {
                self.setHasLoadedRequiredData(true);
                self.render();
            },
        });
    },

    /**
     * @method loadEnvelopeDetailsGrid
     * - Defines the grid options, renders the grid
     */
    loadEnvelopeDetailsGrid() {
        const reportOptions = {
            context: {
                serviceName: `/lockbox/${this.envelopeDetails.endpoint}/getEnvelopeDetailsListView`,
                productCode: 'GIR',
                functionCode: 'LOCKBOX',
                typeCode: this.envelopeDetails.typeCode,
            },

            suppressServicesUrlSuffix: true,
            enableSavedViews: true,
            enableColumnRenameControls: true,
            hideGridActionButtons: true,
            selector: 'none',

            additionalSearchFields: [{
                dataType: 'number',
                fieldName: 'ENVELOPE_ID',
                operator: '=',
                fieldValue: [this.envelopeDetails.eid],
            }],

            parseRow() {
                // for each row
                for (let x = 0; x < this.jsonData.rows.length; x += 1) {
                    const row = this.jsonData.rows[x];

                    // and each column in a row
                    for (let y = 0; y < row.columns.length; y += 1) {
                        const column = row.columns[y];
                        let { fieldValue } = column;

                        /*
                         * check for a flag, set it onto the object, and remove it from the
                         * field value
                         */
                        if (fieldValue.indexOf('^Y') > -1) {
                            column.hasCheckImage = true;
                            fieldValue = fieldValue.replace(/\^Y/gi, '');
                        } else {
                            fieldValue = fieldValue.replace(/\^N/gi, '');
                        }
                        column.fieldValue = fieldValue;
                    }
                }
            },
        };

        this.gridView = GridApi.createServiceGridView(reportOptions);
        this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
        if (mobileUtil.isMobileGridEnabled()) {
            let MobileList = LockboxReportEnvelope;
            const mobileList = configureMobileInterface(MobileList, {
                useGridViewFromOptions: true,
            });
            MobileList = MobileList.extend(mobileList);
            this.gridRegion.show(new MobileList({
                ...this.options,
                ...reportOptions,
                gridView: this.gridView,
            }));
        } else {
            this.gridRegion.show(this.gridView);
        }
    },

    /**
     * @method loadTransactionNotes
     * - Fetch the latest note data, then render the transaction notes
     */
    loadTransactionNotes() {
        const self = this;

        this.collection.fetch({
            success() {
                self.renderTransactionNotes();
            },
        });
    },

    /**
     * @method renderTransactionNotes
     */
    renderTransactionNotes() {
        this.notesCollectionView = new CollectionView({
            collection: this.collection,

            itemView: ItemView.extend({
                template: notesTemplate,
            }),
        });
        this.notesRegion.show(this.notesCollectionView);
        this.ui.$noteCount.text(`(${this.collection.length})`);
    },

    /**
     * @method gridRowAction
     * @param {object} options - The data about the selected grid action
     */
    gridRowAction(options) {
        const action = options.action.toUpperCase();

        if (action === 'IMAGE') {
            this.showImage(options);
        }
        if (action !== 'MANAGERMT') {
            return undefined;
        }
        this.modifyRemitter(options);
        return Promise.resolve();
    },

    /**
     * @method showImage
     * @param {object} options - The data about the selected grid action.
     */
    showImage(options) {
        const req = {
            ENVELOPE_ID: options.model ? options.model.get('ENVELOPE_ID') : this.envelopeDetails.eid,
            VIEW_ENVELOPE_IMAGE: true,
        };
        store.set('lbxImageRequest', req);
        this.navigateTo('REPORTING/lockbox/reports/checkImage');
    },

    modifyRemitter(optionsParam) {
        const options = optionsParam;
        options.account = {
            accountNumber: options.model.get('CHKACCT'),
            aba: options.model.get('CHKROUT'),
        };
        const remitterName = options.model.get('REMITTER');
        if (remitterName.replace(/\s/g, '').length > 0) {
            options.remitterName = remitterName;
        }
        options.allowExistingRemitters = true;
        options.modalType = 'basic';
        const remitterModal = new ModifyRemitterView(options);

        remitterModal.on('remitter:close', (alertView) => {
            if (alertView) {
                this.alertRegion.show(alertView);
                this.loadEnvelopeDetailsGrid();
            }
            dialog.close();
        });

        dialog.open(remitterModal);
    },

    /**
     * @method cancel
     */
    cancel() {
        this.navigateTo(this.returnRoute);
    },

    /**
     * @method manageNotes
     * - On click of the Add Notes button, open the manageNotes modal
     */
    manageNotes() {
        const self = this;

        const manageNotes = new ManageNotesView({
            batch: this.model.get('BATCH_ID'),
            lockbox: this.envelopeDetails.filter,
            ENVELOPE_ID: this.model.get('ENVELOPE_ID'),
        });

        manageNotes.on('grid:refresh', (alert) => {
            if (alert) {
                self.alertRegion.show(alert);
                self.loadTransactionNotes();
            }
            dialog.close();
        });
        dialog.open(manageNotes);
    },
});

export default LockboxReportEnvelope;
