var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel panel-primary\">\n    <div class=\"panel-heading\" role=\"tab\" id=\"panel-heading-2\">\n        <h3 class=\"panel-title\">\n            <a role=\"button\" data-toggle=\"collapse\" href=\"#combined-account-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":4,"column":76},"end":{"line":4,"column":82}}}) : helper)))
    + "\" aria-expanded=\"collapsed\" class=\"\"\n                aria-controls=\"combined-account-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":5,"column":48},"end":{"line":5,"column":54}}}) : helper)))
    + "\">\n                <span class=\"indicator-icon\"></span> <span class=\"panel-title-inner\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":6,"column":85},"end":{"line":6,"column":94}}}) : helper)))
    + "</span>\n            </a>\n        </h3>\n    </div>\n</div>\n";
},"useData":true});