var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.transactionDetails",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":69}}}))
    + "</h1>\n    <div class=\"page-header\" data-region=\"header\"></div>\n</div>\n<section class=\"corp-loans-page\">\n    <div class=\"dashboard-layout bank-account-summary\">\n        <div class=\"dashboard-inner\">\n            <div class=\"dashboard-region dashboard-region-large\">\n                <div class=\"widget\">\n                    <div class=\"widget-container\">\n                        <div class=\"widget-header\">\n                            <h3 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.bankLLC",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":55},"end":{"line":12,"column":82}}}))
    + "</h3>\n                        </div>\n                        <div class=\"widget-body\">\n                            <div class=\"page-content\" data-region=\"tableContent\"></div>\n                            <div class=\"row\">\n                                <div class=\"col-md-12 widget-action-btn-group\">\n                                        <button type=\"button\" data-action=\"cancel\" class=\"btn btn-primary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":107},"end":{"line":18,"column":133}}}))
    + "</button>\n                                </div>\n                            </div>\n                        </div>\n                        <div class=\"widget-footer\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</section>\n";
},"useData":true});