import ItemView from '@glu/core/src/itemView';
import reasonRowViewTmpl from './reasonRowView.hbs';

export default ItemView.extend({
    tagName: 'tr',
    template: reasonRowViewTmpl,

    templateHelpers() {
        return {
            colspan: this.options.colspan,
        };
    },
});
