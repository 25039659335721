var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <select class=\"form-control\" id=\"type-confidential-setting-"
    + alias2(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":3,"column":67},"end":{"line":3,"column":82}}}) : helper)))
    + "\" name=\"CONFIDENTIALSETTING\" data-bind=\"model\">\n            <option value=\"0\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"type.confidentialImportSetting.never",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":30},"end":{"line":4,"column":79}}}))
    + "</option>\n            <option value=\"1\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"type.confidentialImportSetting.always",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":30},"end":{"line":5,"column":80}}}))
    + "</option>\n            <option value=\"2\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"type.confidentialImportSetting.prompt",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":30},"end":{"line":6,"column":80}}}))
    + "</option>\n        </select>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "       <div class=\"field-container\" id=\"type-confidential-setting-"
    + alias3(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":9,"column":66},"end":{"line":9,"column":81}}}) : helper)))
    + "\">\n           "
    + alias3((lookupProperty(helpers,"resolveConfidentialSetting")||(depth0 && lookupProperty(depth0,"resolveConfidentialSetting"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"CONFIDENTIALSETTING") : depth0),{"name":"resolveConfidentialSetting","hash":{},"data":data,"loc":{"start":{"line":10,"column":11},"end":{"line":10,"column":61}}}))
    + "\n       </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <label for=\"type-confidential-setting-"
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":1,"column":42},"end":{"line":1,"column":57}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":1,"column":59},"end":{"line":1,"column":74}}}) : helper)))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"canModify") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":12,"column":11}}})) != null ? stack1 : "");
},"useData":true});