import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import services from 'services';
import http from '@glu/core/src/http';
import transform from 'common/util/transform';

export default Collection.extend({
    initialize(models, options) {
        this.billId = options.billId;
    },

    sync(method, collection, options) {
        let xhr;

        if (method === 'read') {
            // get options.bill_id for data
            const url = services.generateUrl('/inquiry/getQueryResults');

            const request = {
                requestHeader: {
                    queryOffset: 0,
                    queryPagesize: 0,
                    requestId: '',
                },

                IncludeMapData: 1,

                queryCriteria: {
                    action: {
                        typeCode: 'BILL',
                        entryMethod: 0,
                        actionMode: 'SELECT',
                        productCode: 'BOS',
                        functionCode: 'MAINT',
                    },

                    fieldName: false,
                    inquiryId: '19810',

                    customFilters: [{
                        filterName: 'Depends',
                        filterParam: ['ID', this.billId],
                    }],

                    allowDuplicates: true,
                    queryType: 'Query',
                },

                formatterType: 'Query',
            };

            xhr = http.post(url, request, options.success, options.error);
        }

        this.trigger('request', collection, xhr, options);
        return xhr;
    },

    getTotals() {
        return {
            PENDING_PAID_AMOUNT: util.first(this.pluck('PENDING_PAID_AMOUNT')) || 0,
            PAID_AMOUNT: util.first(this.pluck('PAID_AMOUNT')) || 0,
            AMOUNT_DUE: util.first(this.pluck('AMOUNT_DUE')) || 0,
        };
    },

    parse(res) {
        let parsed = [];
        if (res.queryResponse && res.queryResponse.QueryData
            && res.queryResponse.QueryData.queryRows) {
            parsed = util.map(res.queryResponse.QueryData.queryRows, obj => transform.pairsToHash(obj.mapDataList, 'toField', 'value'), this);
        }

        return parsed;
    },
});
