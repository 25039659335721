import Model from '@glu/core/src/model';
import services from 'services';
import transform from 'common/util/transform';
import Formatter from 'system/utilities/format';
import constants from 'app/administration/constants';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';

export default Model.extend({

    sync(method, model, options) {
        const url = services.generateUrl('/inquiry/getData');
        const postData = this.createServiceRequestParams(this.get('userProfile'));

        if (this.get('userProfile').status === 'MD') {
            http.post(url, postData, (result) => {
                options.success(result.inquiryResponse);
            }, (result) => {
                options.error(result);
            });
        }
    },

    /**
     * @method createServiceRequestParams
     * @param {object} userProfile
     * Uses the User Profile information to generate the request data needed to fetch user's
     * pending changes information
     */
    createServiceRequestParams(userProfile) {
        return {
            requestHeader: {
                requestId: '1',
                requestUser: {
                    data: {
                        item: [{
                            name: 'usergroup',
                            value: userProfile.userGroup,
                        },
                        {
                            name: 'password',
                            value: userProfile.password,
                        },
                        ],
                    },
                    userId: userProfile.userID,
                },
            },
            inquiryRequest: {
                startRow: 1,
                searchCriteria: {
                    searchType: '5',
                    inquiryId: 17272,
                    searchFields: [{
                        fieldValue: [userProfile.userGroup],
                        fieldName: 'USERGROUP',
                        dataType: 'TEXT',
                        operator: 'EQ',
                    },
                    {
                        fieldValue: [userProfile.userID],
                        fieldName: 'USERID',
                        dataType: 'TEXT',
                        operator: 'EQ',
                    },
                    ],
                },
            },
        };
    },

    /**
     * @method formatLogTime
     * @param {string} logtime -- full timestamp
     * @return {string} formatted Timestamp
     * Takes in a sql timestamp value and returns a formatted timestamp 'MM/DD/YYYY HH:MM AM/PM'
     */
    formatLogTime(logtime) {
        return `${Formatter.formatDate(logtime)} ${Formatter.formatTime(logtime)}`;
    },

    parse(response) {
        /**
         * Take the rowHeader response with localized display names and create a
         * hashmap to
         * reference from
         */
        const headerDisplayRef = transform.pairsToHash(response.rowHeader, 'fieldName', 'displayName');

        const columns = util.map(response.rows, (row) => {
            // turn column array to a hashmap
            const column = transform.pairsToHash(row.columns, 'fieldName', 'fieldValue');

            const logtime = column[constants.TIMESTAMP_KEY];
            const formatedTimeStamp = this.formatLogTime(logtime);

            // format timestamp
            column[constants.TIMESTAMP_KEY] = formatedTimeStamp;
            // format escaped info content
            column[constants.INFO_KEY] = column[constants.INFO_KEY].replace(/(?:\r\n|\r|\n)/g, '<br/>');

            return column;
        });

        return {
            headers: headerDisplayRef,
            columns,
        };
    },

});
