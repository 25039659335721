var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "switch-disabled";
},"3":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"5":function(container,depth0,helpers,partials,data) {
    return "checked";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"switch-widget-region "
    + alias4(((helper = (helper = lookupProperty(helpers,"switchClass") || (depth0 != null ? lookupProperty(depth0,"switchClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"switchClass","hash":{},"data":data,"loc":{"start":{"line":1,"column":33},"end":{"line":1,"column":48}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":49},"end":{"line":1,"column":89}}})) != null ? stack1 : "")
    + "\">\n    <label for=\"switch-widget-"
    + alias4(((helper = (helper = lookupProperty(helpers,"switchId") || (depth0 != null ? lookupProperty(depth0,"switchId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"switchId","hash":{},"data":data,"loc":{"start":{"line":2,"column":30},"end":{"line":2,"column":42}}}) : helper)))
    + "\">\n        <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"switchLabel") || (depth0 != null ? lookupProperty(depth0,"switchLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"switchLabel","hash":{},"data":data,"loc":{"start":{"line":3,"column":14},"end":{"line":3,"column":29}}}) : helper)))
    + "</span>\n        <input\n            type=\"checkbox\"\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":45}}})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"switchActivated") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":49}}})) != null ? stack1 : "")
    + "\n            id=\"switch-widget-"
    + alias4(((helper = (helper = lookupProperty(helpers,"switchId") || (depth0 != null ? lookupProperty(depth0,"switchId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"switchId","hash":{},"data":data,"loc":{"start":{"line":8,"column":30},"end":{"line":8,"column":42}}}) : helper)))
    + "\"\n            name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"switchName") || (depth0 != null ? lookupProperty(depth0,"switchName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"switchName","hash":{},"data":data,"loc":{"start":{"line":9,"column":18},"end":{"line":9,"column":32}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"switchId") || (depth0 != null ? lookupProperty(depth0,"switchId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"switchId","hash":{},"data":data,"loc":{"start":{"line":9,"column":33},"end":{"line":9,"column":45}}}) : helper)))
    + "\"\n        />\n    </label>\n</div>\n";
},"useData":true});