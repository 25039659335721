import CompositeView from '@glu/core/src/compositeView';
import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import CTXAddendaChild from 'common/dynamicPages/views/ctxAddenda/ctxAddendaChild';
import Wrapper from 'common/dynamicPages/api/gridWrapper';
import template from './ctxAddendaLayout.hbs';
import compositeTemplate from './ctxAddendaComposite.hbs';

export default Layout.extend({
    template,
    compositeTemplate,

    events: {
        'click #ADDADDENDA': 'saveNewGrandchild',
    },

    initialize(options) {
        const optionsParam = options;
        optionsParam.isComponent = true;
        optionsParam.enableRowContextButtonCallbacks = true;
        optionsParam.paginate = false;
        optionsParam.filter = false;
        optionsParam.sortable = false;
        optionsParam.rowsPerPage = 100;
        optionsParam.disableAuxControl = true;
        optionsParam.disableDeleteConfirm = true;
        if (optionsParam.gridViewOptions) {
            Object.entries(optionsParam.gridViewOptions).forEach(([key, value]) => {
                optionsParam[key] = value;
            });
        }
        optionsParam.gridId = this.cid;
        this.options = optionsParam;
        this.meta = optionsParam.meta;
        this.parentState = optionsParam.parentState;
        optionsParam.batchSeqNum = options.parentModel.get('BATCHSEQNUM');
        this.wrapper = new Wrapper(optionsParam);
        this.wrapper.rows.comparator = (model) => {
            const parseRECNUM = -parseInt((model.get('RECNUM')), 10);
            return parseRECNUM;
        };

        this.compositeView = new CompositeView({
            template: this.compositeTemplate,
            itemViewOptions: this.itemViewOptions(),
            itemView: CTXAddendaChild,
            collection: this.wrapper.rows,
            itemViewContainer: '#accordion',
        });
    },

    itemViewOptions() {
        return {
            meta: this.meta,
            parentState: this.parentState,
            parentModelAttrs: this.options.parentModel.attributes,
        };
    },

    /**
     * ensure the required promises are fulfilled before rendering
     */
    loadRequiredData() {
        this.wrapperGridPromise = this.wrapper.initializeGridData({
            viewId: this.cid,
        });

        this.wrapperGridPromise.then(() => {
            this.setHasLoadedRequiredData(true);
            this.render();
        });
    },

    onRender() {
        if (this.parentState !== 'view') {
            const freshAddenda = new Model();
            freshAddenda.isFresh = true;
            const options = this.itemViewOptions();
            options.model = freshAddenda;
        }

        if (this.hasLoadedRequiredData()) {
            this.collectionRegion.show(this.compositeView);
        } else {
            this.loadRequiredData();
        }
    },

    handleCollectionRefresh() {
        let sequenceNumber = this.wrapper.rows.length;
        this.wrapper.rows.each((model) => {
            const modelParam = model;
            modelParam.set('ui_sequenceNumber', sequenceNumber);
            sequenceNumber -= 1;
            modelParam.originalAttributes = util.clone(modelParam.attributes);
        });
        this.wrapper.rows.trigger('reset');
    },

    refreshCollectionData() {
        this.wrapper.refreshGridData(false).then(util.bind(this.handleCollectionRefresh, this));
    },

    setNextSeqNumber(nextSeqNum) {
        const depends = this.wrapper.getComponentDepends();
        const [firstDepends] = depends;
        let item;
        if (depends && depends.length > 0) {
            item = firstDepends;
            item.value = nextSeqNum;
        }
    },

    saveNewGrandchild() {
        this.trigger('save:dirty:grandchild');
    },

    getCollectionLength() {
        return this.compositeView.collection.length;
    },

});
