import Collection from '@glu/core/src/collection';
import { postData } from 'common/util/services';
import services from 'services';
import transform from 'common/util/transform';

const NoteCollection = Collection.extend({
    sync(method, collection, options) {
        if (method === 'read') {
            return postData(
                services.serviceRequestNotesList,
                this.getFetchData(collection, options),
            ).then(response => this.parse(response))
                .then(data => this.set(data));
        }
        return Promise.reject(new Error(`${method} event not supported`));
    },

    parse(response) {
        if (response.rows.length) {
            return response.rows.map(row => transform.pairsToHash(row.columns, 'fieldName', 'fieldValue'));
        }

        return [];
    },

    getFetchData(collection, options) {
        return {
            searchFields: [
                {
                    dataType: 'text',
                    operator: '=',
                    fieldName: 'SERVICEREQUESTTNUM',
                    fieldValue: [
                        options.tnum,
                    ],
                },
            ],
            sortFields: [
                {
                    fieldName: 'ENTERED_TIMESTAMP',
                    sortOrder: 'DESC',
                },
            ],
            startRow: 1,
            rowsPerPage: 10,
            dataOnly: 0,
        };
    },
});

export default NoteCollection;
