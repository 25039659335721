var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":18,"column":39},"end":{"line":18,"column":45}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":18,"column":47},"end":{"line":18,"column":55}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.backToPreviousPage",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":144},"end":{"line":3,"column":191}}}))
    + "</span></a>\n    </div>\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.editContent",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":71}}}))
    + "</h1>\n</div>\n\n<fieldset class=\"section section-container\">\n    <div class=\"row\">\n        <div class=\"form-column col-md-6\">\n            <div class=\"required field-container-lg field-container\">\n                <label for=\"marketSegment\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.selectMarketSegment",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":43},"end":{"line":12,"column":91}}}))
    + "</label>\n                <select class=\"form-control\"\n                    data-bind=\"model\"\n                    data-hook=\"getMarketSegment\"\n                    name=\"marketSegment\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"segments") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":19,"column":29}}})) != null ? stack1 : "")
    + "                </select>\n                <br>\n                <button type=\"button\" data-action=\"load\" class=\"btn btn-primary\" name=\"load\"><span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.loadWidgetView",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":111},"end":{"line":22,"column":154}}}))
    + "</span></button>\n                <button class=\"btn btn-primary\"\n                    data-action=\"save\"\n                    data-hook=\"getWidgetSaveButton\"\n                    disabled=\"true\"\n                    name=\"save\"\n                    type=\"button\">\n                    <span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.saveWidgetEdits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":38},"end":{"line":29,"column":82}}}))
    + "</span>\n                </button>\n            </div>\n        </div>\n    </div>\n    <hr>\n    <div class=\"row\">\n        <div class=\"form-column col-md-12\" data-region=\"widgetDisplayRegion\">\n        </div>\n    </div>\n</fieldset>\n";
},"useData":true});