/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useState, useEffect, useRef
} from 'react';
import { createPortal } from 'react-dom';
import { useClickOutside, AccessibilityText } from '@glu/utilities-react';
import PropTypes from 'prop-types';
import useStyles from './Popover.styles';

const Popover = (props) => {
  const {
    activeClass,
    children,
    childrenRef,
    content,
    dataQa,
    gridWrapperRef,
    leftOffset,
    minWidth,
    open,
    setOpen,
    topOffset
  } = props;
  const classes = useStyles(props);
  const [left, setLeft] = useState(0);
  const [right, setRight] = useState('auto');
  const [top, setTop] = useState(0);
  const contentRef = useRef();
  const buttonRef = useRef();
  const childRef = childrenRef || buttonRef;
  const handleEndFocus = () => {
    setOpen(false);
    buttonRef.current?.focus();
  };

  useEffect(() => {
    if (!childRef?.current) return;
    const {
      height: childHeight,
      left: childLeft,
      top: childTop
    } = childRef?.current?.getBoundingClientRect();

    const {
      left: gridLeft,
      right: gridRight,
      top: gridTop
    } = gridWrapperRef.current.getBoundingClientRect();

    const topPos = childTop - gridTop + childHeight + topOffset;
    if (childLeft + minWidth > gridRight) {
      setLeft('auto');
      setRight(0);
      setTop(topPos);
    } else {
      setLeft(childLeft - gridLeft + leftOffset);
      setRight('auto');
      setTop(topPos);
    }
  });

  useClickOutside(contentRef, /* istanbul ignore next */() => { setOpen(false); });

  useEffect(() => {
    let timeout;
    if (open) {
      timeout = setTimeout(() => {
        contentRef.current?.querySelectorAll('button, a, input, textarea')[0].focus();
      });
    }

    return () => clearTimeout(timeout);
  }, [open]);

  return (
    <>
      <button
        type="button"
        ref={buttonRef}
        data-qa={`menuOpenBtn-${dataQa}`}
        className={`${classes.children} ${open ? activeClass : ''}`}
        onClick={() => { setOpen((prev) => !prev); }}
      >
        {children}
      </button>
      {open && createPortal(
        <div
          data-qa="popover-content"
          ref={contentRef}
          style={{ left, right, top }}
          className={classes.content}
        >
          {content}
          <button
            type="button"
            className={classes.closeOnFocusButton}
            onFocus={handleEndFocus}
          >
            <AccessibilityText>Close Popup Content</AccessibilityText>
          </button>
        </div>,
        gridWrapperRef.current
      )}
    </>
  );
};

Popover.propTypes = {
  activeClass: PropTypes.string,
  children: PropTypes.node.isRequired,
  childrenRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.shape({}) })
  ]),
  content: PropTypes.shape({}).isRequired,
  dataQa: PropTypes.string,
  gridWrapperRef: PropTypes.shape({
    current: PropTypes.shape({
      getBoundingClientRect: PropTypes.func.isRequired,
      querySelector: PropTypes.func.isRequired
    })
  }).isRequired,
  leftOffset: PropTypes.number,
  minWidth: PropTypes.number,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  topOffset: PropTypes.number
};
Popover.defaultProps = {
  activeClass: undefined,
  childrenRef: undefined,
  dataQa: undefined,
  leftOffset: 0,
  minWidth: undefined,
  topOffset: 0
};
export default Popover;
