var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button type=\"button\" class=\"btn btn-secondary viewchecks\" data-hook=\"getViewChecks\" disabled>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasViewChecksEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":20,"column":23}}})) != null ? stack1 : "")
    + "            </button>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"viewchecks",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":17,"column":52}}}))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"viewAll",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":19,"column":49}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"section section-container\">\n    <div class=\"section-body\">\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        <div class=\"alert-region\" data-region=\"checkInquiryAlertRegion\"></div>\n        <div class=\"row\">\n            <div class=\"col-10\"></div>\n            <div class=\"btn-wrapper\">\n               "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"gridUtilitySection") || (depth0 != null ? lookupProperty(depth0,"gridUtilitySection") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"gridUtilitySection","hash":{},"data":data,"loc":{"start":{"line":8,"column":15},"end":{"line":8,"column":39}}}) : helper))) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n        <div class=\"grid-region\" data-region=\"gridRegion\"></div>\n    </div>\n    <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showViewButton") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":22,"column":15}}})) != null ? stack1 : "")
    + "        <button type=\"button\" class=\"btn btn-secondary\" data-hook=\"getPlaceStops\">"
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||alias2).call(alias1,"placeStops",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":23,"column":82},"end":{"line":23,"column":114}}}))
    + "</button>\n    </div>\n</div>\n";
},"useData":true});