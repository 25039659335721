import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import locale from '@glu/locale';
import ContextApi from 'common/dynamicPages/api/context';
import pageApi from 'common/dynamicPages/api/view';
import DataApi from 'common/dynamicPages/api/data';
import gridApi from 'common/dynamicPages/api/grid';
import scroll from 'common/util/scroll';
import AdminUtil from 'app/administration/common/util';
import dialog from '@glu/dialog';
import addTmpl from './add.hbs';

export default Layout.extend({
    template: addTmpl,

    regions: {
        pageRegion: '.pageRegion',
        alertRegion: '.alert-region',
    },

    ui: {
        $navTabs: '[data-hook="getNavTabs"]',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
    },

    initialize(options) {
        this.model = options.model || new Model();
        this.listenTo(this.model, 'invalid', scroll.scrollToFirstError);
        this.contextDef = ContextApi.menuContext.getContext('SEGMNT_MAINT');
        this.segmentName = store.get('segmentName');
        this.editMode = store.get('segmentModify');

        // get the model
        this.context = {
            functionCode: 'MAINT',
            productCode: 'SEGMENT',
            serviceName: '/segmentation',
        };
    },

    loadViewRequirements() {
        const self = this;

        const keyList = [
            'NAME',
        ];

        const quickEntryItemModel = DataApi.model.generateFromKeyList({
            context: this.context,
            keyList,
        });

        quickEntryItemModel.set({
            NAME: self.segmentName,
        });

        DataApi.model.generateModelFromModel(quickEntryItemModel)
            .then((metaModelFromPartialModel) => {
                metaModelFromPartialModel.fetch({
                    success() {
                        self.modelToEdit = metaModelFromPartialModel;

                        // save off the current state so we know if something changes.
                        self.originalAttributes = util.clone(self.modelToEdit.attributes);
                        self.setHasLoadedRequiredData(true);
                        self.render();
                    },
                });
            });
    },

    onRender() {
        const self = this;
        if (self.hasLoadedRequiredData()) {
            if (self.editMode) {
                self.displayLeavePageWarning();
            }

            const options = {
                context: this.contextDef,
                hideButtons: true,
                gridApi,
                state: 'modify',
                model: this.modelToEdit,
                viewType: 'default',
            };

            self.pageView = pageApi.page.get(options);
            self.pageRegion.show(self.pageView);
        } else {
            self.loadViewRequirements();
        }
    },

    templateHelpers() {
        const self = this;
        return {
            isEditMode() {
                return self.editMode;
            },
        };
    },

    displayLeavePageWarning() {
        const self = this;

        self.ui.$navLinks.on('click', (e) => {
            e.preventDefault();

            if (this.$(e.target).parent('li').hasClass('is-active')) {
                return;
            }

            const path = self.$(e.target).attr('href');

            const hasChanged = !util.isEqual(
                self.originalAttributes,
                self.pageView.model.attributes,
            );

            if (window.innerWidth < 768) {
                this.ui.$navTabs.toggleClass('is-open');
            }

            if (!util.isEmpty(path) && path !== '#' && hasChanged) {
                dialog.confirm(
                    locale.get('segment.confirm.leavepage.warning'),
                    locale.get('segment.confirm.edit.heading'),
                    (ok) => {
                        if (ok) {
                            self.navigateTo(path);
                        }
                    },
                );
            } else {
                // no changes so just go there.
                self.navigateTo(path);
            }
        });
    },

    save() {
        const self = this;

        if (this.editMode) {
            dialog.confirm(
                locale.get('segment.confirm.edit.warning'), locale.get('segment.confirm.edit.heading'),
                (ok) => {
                    if (ok) {
                        self.handleSave();
                    }
                },
            );
        } else {
            self.handleSave();
        }
    },

    handleSave() {
        const self = this;

        self.pageView.model.set('NAME', self.segmentName);
        self.pageView.model.set('id', self.segmentName);

        self.pageView.model.save(
            {},
            {
                success: () => {
                    if (this.isEditMode) {
                        store.set('admin:segmentation:add', locale.get('administration.segmentation.update.success', self.segmentName));
                        this.cancel();
                    } else {
                        // send user to Payment Limits tab
                        this.navigateTo('/SEGMENTATION/addPaymentLimits');
                    }
                },

                error(response) {
                    AdminUtil.errorAlert(self.alertRegion, response);
                },
            },
        );
    },

    back() {
        store.set('segmentModify', true);
        this.navigateTo('/SEGMENTATION/addServices');
    },

    cancel() {
        store.unset(
            'segmentName',
            {
                silent: true,
            },
        );
        store.unset(
            'segmentModify',
            {
                silent: true,
            },
        );
        this.navigateTo('/SEGMENTATION/viewSegments');
    },
});
