import '../../themeDefaults';
import { createUseStyles } from '@glu/theming';

const styles = ({ grid = { actions: {} }, typography }) => ({
  menuItem: {
    '&:hover': {
      background: grid.actions.buttonHoverBackground
    },
    background: 'none',
    border: 'none',
    color: grid.actions.buttonColor,
    cursor: 'pointer',
    display: 'block',
    font: 'inherit',
    fontSize: 16,
    lineHeight: '46px',
    padding: [0, 14],
    textAlign: 'left',
    textDecoration: `none solid ${grid.actions.buttonTextDecorationColor}`,
    width: '100%'
  },
  root: {
    background: grid.whiteBackgroundColor,
    fontFamily: typography.fontFamily,
    padding: 0
  }
});

export default createUseStyles(styles);
