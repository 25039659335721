const SERVER_VALUE = {
    TRUE: '1',
    FALSE: '0',
};

const getBooleanValue = serverValue => serverValue === SERVER_VALUE.TRUE;
const getServerValue = value => (value ? SERVER_VALUE.TRUE : SERVER_VALUE.FALSE);

export {
    SERVER_VALUE,
    getBooleanValue,
    getServerValue,
};
