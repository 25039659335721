import transferTemplateView from 'app/payments/views/viewTemplate';
import store from 'system/utilities/cache';
import actionFunction from 'app/transfers/api/transfersActionFunction';

export default transferTemplateView.extend({
    initialize() {
        this.options = {
            menuCategory: 'PMTS',
            serviceName: 'template/transfer',
            serviceFunc: null,
            businessType: null,
            context: 'TRANSFERS_TEMPLATE_LIST',
            mode: 'view',
        };

        transferTemplateView.prototype.init.call(this);
    },

    repair() {
        store.set('payment_transfer-actionModel', this.tempModel);
        this.navigateTo('TEMPLATES/repairTemplate');
    },

    copytmpl() {
        actionFunction.makePayment(this.storedModel, 'payment_transfer');
        this.navigateTo('TEMPLATES/addTransferTemplateFromTemplate');
    },

    makeinst() {
        actionFunction.makeTemplate(this.storedModel, 'payment_transfer');
        this.navigateTo('PAYMENTS/addTransferPaymentFromTemplate');
    },

    modify() {
        store.set('payment_transfer-actionModel', this.tempModel);
        this.navigateTo('TEMPLATES/modifyTransferTemplate');
    },
});
