import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import transform from 'common/util/transform';
import services from 'services';
import http from '@glu/core/src/http';

export default Collection.extend({
    initialize(options) {
        this.additionalSearchFields = options.additionalSearchFields;
    },

    parse(results) {
        return util.map(results.inquiryResponse.rows, row => transform.pairsToHash(row.columns, 'fieldName', 'fieldValue'));
    },

    sync(method, model, options) {
        const url = services.generateUrl('/inquiry/getData');

        const postData = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    searchFields: this.additionalSearchFields,
                    searchType: '5',
                    inquiryId: 22420,
                },
            },
        };

        http.post(url, postData, (result) => {
            options.success(result);
        }, (result) => {
            options.error(result);
        });
    },
});
