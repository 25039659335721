import $ from 'jquery';
import Handlebars from 'handlebars';
import GluIcons from '@glu/icons';
import './fileUpload'; // mainly inject Blueimp JQuery File Upload, but with small change for Glu. Not yet sure if we really need that.
import fileUploadTemplate from './fileUploadTemplate.hbs';

export default {
  // Suggestion to give ability LOB use custom template
  useTemplate(target, customTemplate) {
    if (!target) {
      /* eslint: no-console: "off" */
      console.log('Provide target element where to attach fileUpload container');
      return;
    }

    const template = customTemplate || fileUploadTemplate;

    $(target).append(Handlebars.compile(template)); // TODO fix
  },

  setServerUrl(customUrl) {
    if (customUrl) {
      this.serverUrl = customUrl;
    }
  },

  setIconsMode(customIconsType) {
    this.isSvgBasedIcons = customIconsType || 'svg';
    this.gluIconsInstance = new GluIcons({
      iconsType: this.isSvgBasedIcons
    });
    this.gluIconsInstance.registerIconHelper();
  },

  executeExtended() {
    let iconRefresh;
    let iconRemove; // if iconsType will be 'font', then "undefined" value of these variable is OK for further code.
    if (this.isSvgBasedIcons) {
      iconRefresh = Handlebars.compile('{{icon name="refresh"}}');
      iconRemove = Handlebars.compile('{{icon name="close"}}');
    }
    this.fileUploadExtended(this.isSvgBasedIcons, iconRefresh, iconRemove);
  },

  /**
   * Main content of this function taken from old codebase for glu core (folder example) and modified to be public code of Glu File Upload
   * But the design can be changed in future. Because in fact not many LOB used this layout.
   * Some LOB has similar code in their codebase.
   * Oct-2018: More work will be done during redesign GLU-1452
   * @param svgBasedIcons
   * @param iconRefresh
   * @param iconRemove
   */
  fileUploadExtended(svgBasedIcons, iconRefresh, iconRemove) {
    /* eslint no-use-before-define: "off" */
    /* eslint consistent-return: "off" */
    // Change this to the location of your server-side upload handler:
    const url = this.serverUrl || 'server/php/';

    // element references
    // var uploadData = '[data-hook="upload-data"]';
    // var upload = '.file-upload';
    const uploadInput = '[data-hook="file-upload-data"]';
    const uploadStatus = '.file-upload .status';
    const uploadStatusMsg = '.file-upload .message';
    const uploadStatusIcon = '.file-upload .status-icon';
    const uploadCancelBtn = '.file-upload [data-action="cancel"]';
    const uploadDropzone = '.file-upload .dropzone';
    const uploadGif = '.file-upload .upload-gif';
    const uploadFileInput = '.file-upload input[type=file]';
    const manualSelect = '[data-hook="manual-select"]';

    function initializeFileUpload() {
      initialDragEvents();
      initialClickEvents();

      // Handle legacy IE (no drag/drop support)
      if ($('html').hasClass('lt-ie10')) {
        $(uploadDropzone).addClass('no-dnd');
      }

      const options = {
        type: 'POST',
        dataType: 'text', // caters for empty response body
        dropZone: uploadDropzone,
        pasteZone: uploadDropzone,
        autoUpload: true,
        paramName: 'file',
        sequentialUploads: true,
        maxNumberOfFiles: 1,

        add(e, data) {
          // Update the URL as required
          data.url = url;

          // Set an error state is we don't have a file
          if (!data.files || !data.files.length) {
            return uploadStatusError();
          }

          uploadStatusProcessing();
          uploadStatusMessage(`Uploading file, ${data.files[0].name}`);

          // Aborts download when "Cancel" is clicked
          const jqXHR = data.submit();

          $(uploadCancelBtn).click((ev) => {
            ev.preventDefault();
            jqXHR.abort();
          });
        },

        done(e, data) {
          // Set an error state is we don't have a result or any file
          if (!data.files || !data.files.length) {
            uploadStatusMessage('File upload failed');
            return this.uploadStatusError();
          }

          const file = data.files[0];

          try {
            const { progressUrl } = JSON.parse(data.result); // TODO from which LOB such logic, that progressUrl will be sent?
            /* eslint no-console : "off" */
            console.log(progressUrl); // TODO
          } catch (ex) {
            uploadStatusMessage('File upload failed');
            return uploadStatusError();
          }

          uploadStatusValidating();
          uploadStatusMessage(`Validating file, ${file.name}`);
        },

        fail(e, data) {
          // If the server sent a Bad Request response, show the message it replied with.
          if (data.jqXHR.status === 400) {
            const { error } = JSON.parse(data.jqXHR.responseText);
            const errorMessage = (error && error.fileMask) ? error.fileMask : 'An un-expected error occurred';
            uploadStatusMessage(`File upload failed: ${errorMessage}`);
            uploadStatusError();
            return;
          }

          const messagePrefix = (data.errorThrown === 'abort') ?
            'File upload cancelled, ' :
            'File upload failed, ';
          try {
            uploadStatusMessage(messagePrefix + data.files[0].name);
          } catch (ex) {
            uploadStatusMessage('File upload failed');
          }

          uploadStatusError();
        }
      };

      $(uploadInput).uploader(options);

      // it's better for user to disable the default browser action for file drops
      $(document).bind('drop dragenter dragover', (e) => {
        e.preventDefault();
        e.stopPropagation();
      });
    }

    function initialDragEvents() {
      $(document).bind('dragenter dragover', (e) => {
        e.preventDefault();
        e.stopPropagation();

        const dropZone = $(uploadDropzone);
        const timeout = window.dropZoneTimeout;

        if (!timeout) {
          dropZone.addClass('in');
        } else {
          clearTimeout(timeout);
        }

        let found = false;
        let node = e.target;

        do {
          if (node === dropZone[0]) {
            found = true;
            break;
          }
          node = node.parentNode;
        } while (node !== null);

        dropZone[found ? 'addClass' : 'removeClass']('hover');

        window.dropZoneTimeout = setTimeout(() => {
          window.dropZoneTimeout = null;
          dropZone.removeClass('in hover');
        }, 100);
      });
    }

    function initialClickEvents() {
      $(uploadFileInput).bind('click', (e) => {
        stopPropagation(e);
      });

      $(uploadDropzone).bind('click', (e) => {
        openFileDialog(e);
      });

      // a few click events to demonstrate functionality without a backend.
      $('#show-processing').bind('click', () => {
        uploadStatusProcessing();
        uploadStatusMessage('Uploading file, test.txt');
      });

      $('#show-validating').bind('click', () => {
        uploadStatusProcessing();
        uploadStatusValidating();
        uploadStatusMessage('Validating file...');
      });

      $('#show-fail').bind('click', () => {
        uploadStatusError();
      });

      $('#show-done').bind('click', () => {
        uploadFinished();
      });
    }

    function stopPropagation(e) {
      // Prevent an infinite event handler loop (click events for file upload)
      e.stopPropagation();
    }

    function openFileDialog(e) {
      e.preventDefault();
      $(uploadFileInput).click();
    }

    function uploadStatusMessage(message) {
      $(uploadStatusMsg).text(message);
    }

    function uploadStatusProcessing() {
      $(manualSelect).addClass('hide');

      $(uploadStatusIcon)
        .addClass('glyphicon-refresh')
        .removeClass('glyphicon-remove')
        .removeClass('glyphicon-ok');

      if (svgBasedIcons) {
        $(uploadStatusIcon)
          .removeClass('glyphicon')
          .removeClass('glyphicon-refresh');

        $(uploadStatusIcon).html(iconRefresh);

        $(uploadStatusIcon).css({
          fontSize: 'initial'
        });
      }

      $(uploadStatus)
        .removeClass('success')
        .removeClass('error')
        .removeClass('hide');

      $(uploadDropzone).addClass('hide');
      $(uploadCancelBtn).removeClass('hide');

      // Handle legacy IE (no CSS animations)
      if ($('html').hasClass('lt-ie10')) {
        $(uploadGif).removeClass('hide');
        $(uploadStatusIcon).addClass('hide');
      }
    }

    function uploadStatusValidating() {
      $(uploadCancelBtn).addClass('hide');
    }

    function uploadStatusError() {
      uploadStatusMessage('File upload failed');

      $(manualSelect).removeClass('hide');

      $(uploadGif).addClass('hide');

      $(uploadStatusIcon)
        .removeClass('hide')
        .removeClass('glyphicon-refresh')
        .addClass('glyphicon-remove')
        .removeClass('glyphicon-ok');

      if (svgBasedIcons) {
        $(uploadStatusIcon)
          .removeClass('glyphicon')
          .removeClass('glyphicon-remove');

        $(uploadStatusIcon).html(iconRemove);
        $(uploadStatusIcon).css({
          fontSize: 'initial'
        });
      }

      $(uploadStatus)
        .removeClass('success')
        .addClass('error')
        .removeClass('hide');

      $(uploadDropzone).removeClass('hide');
      $(uploadCancelBtn).addClass('hide');
    }

    // function uploadInProgress() {
    // TODO (research old code, why this function was suggested/added to implement in future)
    // }

    function uploadFinished() {
      $(uploadStatus).addClass('hide');
      $(manualSelect).removeClass('hide');
      $(uploadDropzone).removeClass('hide');
      $(uploadCancelBtn).removeClass('hide');
    }

    // setup the file upload component
    initializeFileUpload();
  }
};
