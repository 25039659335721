import PositivePayDecisionList from 'app/checkManagement/views/listPositivePayDecision';
import dialog from '@glu/dialog';
import constants from 'common/dynamicPages/api/constants';
import store from 'system/utilities/cache';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import template from './listDecision.hbs';

export default PositivePayDecisionList.extend({
    template,

    /** @override */
    getInitializeOptions(options) {
        const initOptions = {
            serviceName: 'adminCM/fraud/listView/positivePayDecision',
            serviceFunc: null,
            businessType: null,
            returnRoute: 'REPORTING/viewPaymentFraudControl',
            enableSavedViews: true,
            selector: 'none',
            viewAll: options.viewAll,
        };

        let rowModel;

        if (options.viewAll) {
            store.set('cm_listView_comingToList', 'posPay-items');
        } else {
            rowModel = this.getRowModel(options);
            initOptions.additionalSearchFields = [{
                fieldName: 'PPACCOUNTBATCHID',
                operator: 'EQ',
                fieldValue: [rowModel.get('BATCHTNUM')],
                dataType: 'text',
            }];
        }

        return initOptions;
    },

    /** @override */

    back() {
        this.navigateTo(this.options.returnRoute);
    },

    getInquiryId() {
        return constants.INQUIRY_ID_29500;
    },

    /** @override */
    getRowModel(options) {
        if (options && options.model) {
            return options.model;
        }
        return store.get('adminCM_fraud_listView_paymentFraudControl-actionModel');
    },

    /** @override */
    gridRowSelect(options) {
        const { model } = options;
        model.set('ACCOUNT_NUMBER', this.ACCOUNT_NUMBER);
        model.set('ACCOUNT_TITLE', this.ACCOUNT_TITLE);
        store.set(`${this.contextKey}-actionModel`, model);
        this.navigateTo('REPORTING/viewPositivePayDecision');
        return Promise.resolve();
    },

    export() {
        const options = {
            format: 'CSV',
            gridView: this.gridView,
        };

        this.buildExportModel(options);
        this.doExport(options);
    },

    print() {
        const options = {
            format: 'PDF',
            gridView: this.gridView,
        };

        this.buildExportModel(options);
        const printModal = new PrintViewModal({
            exportModel: this.exportModel,
            title: `${this.localeKey}title`,
        });
        dialog.custom(printModal);
    },

    buildExportModel(format) {
        PositivePayDecisionList.prototype.buildExportModel.call(this, format);
        this.exportModel.inquiryId = this.getInquiryId();
        this.exportModel.contextData = {
            AccountNumber: this.ACCOUNT_NUMBER,
            AccountName: this.ACCOUNT_TITLE,
        };
    },
});
