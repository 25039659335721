import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import services from 'services';
import errHandler from 'system/errHandler';
import http from '@glu/core/src/http';
import CollectionView from '@glu/core/src/collectionView';
import alert from '@glu/alerts';
import systemConfig from 'system/configuration';
import ApproverCollection from 'app/administration/collection/panelApproval/approvers';
import TypeItemView from './typeItemView';
import typeViewTmpl from './typeView.hbs';

export default Layout.extend({
    template: typeViewTmpl,
    className: 'PanelApproval-types',

    events: {
        'click @ui.$saveButton': 'save',
        'click button[data-hook="getResetButton"]': 'reset',
        'click button[data-hook="getCancelButton"]': 'cancel',
    },

    ui: {
        $saveButton: '[data-hook="getSaveButton"]',
    },

    initialize(opts) {
        this.groupId = `${opts.groupId}`;
        this.contextKey = opts.contextKey;

        this.collection = new ApproverCollection(
            [],
            {
                userGroup: this.groupId,
            },
        );

        this.collectionView = new CollectionView({
            itemView: TypeItemView,
            collection: this.collection,
        });
    },

    loadRequiredData() {
        Promise.all([
            this.getEntitlements(this.groupId),
            this.collection.fetch(),
        ]).then(([entitlements]) => {
            this.resetCollectionData = this.collection.toJSON();
            this.entitlements = entitlements;
            this.setHasLoadedRequiredData(true);
            this.render();
        }, errHandler);
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
        } else {
            this.collectionRegion.show(this.collectionView);
        }
    },

    getEntitlements(userGroup) {
        return http.post(
            services.generateUrl('panelmaint/getEntitledActions'),
            {
                typeCode: 'APPTN_TM',
                action: 'MODIFY',
                productCode: systemConfig.isAdmin() ? 'CMAINT' : '_ADMIN',
                functionCode: 'MAINT',

                data: [{
                    name: 'USERGROUP',
                    value: userGroup,
                }],

                entryMethod: 0,
            },
        ).then(data => util.values(data.actionModes));
    },

    save() {
        let valid = true;

        this.collection.each((rec) => {
            if (!rec.isValid()) {
                valid = false;
            }
        });

        if (valid) {
            this.collection.save({
                success: this.handleSaveSuccess.bind(this),
                error: this.handleSaveError.bind(this),
            });
        }
    },

    handleSaveSuccess(response) {
        this.ui.$saveButton.attr('aria-busy', 'true');
        store.set(`${this.contextKey}-confirms`, response);
        this.cancel();
    },

    /**
     * Get the error message from the response and show the alert
     * @param {Object} response - error response
     */
    handleSaveError(response) {
        this.ui.$saveButton.attr('aria-busy', 'false');
        const { message } = response.responseJSON;
        // index 0 is technical garbage not for user, so get 1 index
        const alertMessage = message[1] ? message[1] : message[0];
        this.alertRegion.show(alert.negative(alertMessage));
    },

    cancel() {
        this.navigateTo(this.options.returnRoute);
    },

    /**
     * Reset the collection with data from original fetch operation then
     * rerender the collectionView
     */
    reset() {
        this.collection.reset(this.resetCollectionData);
        this.collectionView.render();
    },

    templateHelpers() {
        return {
            groupId: this.groupId,
            isAdmin: systemConfig.isAdmin(),
        };
    },
});
