import util from '@glu/core/src/util';
import locale from '@glu/locale';
import ItemView from '@glu/core/src/itemView';
import accountTransactionBalanceDetailsTmpl from './accountTransactionBalanceDetails.hbs';

const AccountTransactionBalanceDetails = ItemView.extend({
    template: accountTransactionBalanceDetailsTmpl,

    initialize(options) {
        this.model = options.model;
        this.poller = options.poller;

        this.listenTo(this.model, 'sync change', this.render);

        this.listenTo(this.poller, 'responseReceived', this.model.sync);

        // trigger initial service call
        this.model.fetch();
    },

    templateHelpers() {
        const blackListedKeys = [
            locale.get('PAY.Currency'),
            'TOTALSCOLUMN1',
            'TOTALSCOLUMN2',
            'TOTALSCOLUMN1LABEL',
            'TOTALSCOLUMN2LABEL',
        ];
        return {
            columns: util.omit(this.model.get('columns'), blackListedKeys),
        };
    },
});

export default AccountTransactionBalanceDetails;
