import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import transform from 'common/util/transform';
import constants from 'common/dynamicPages/api/constants';
import services from 'services';
import Formatter from 'system/utilities/format';
import TransferModel from './transfer';

export default Model.extend({
    initialize(obj, opts) {
        if (opts.templateMode) {
            this.validators = {
                setName: {
                    exists: true,
                    description: locale.get('PAY.TemplateCode'),
                },

                setDescription: {
                    exists: true,
                    description: locale.get('PAY.TemplateDescription'),
                },
            };
        }
    },

    handleRead(model, opts) {
        const data = {
            typeCode: 'TRANSFER',
            productCode: 'RTGS',
            functionCode: 'TMPLSET',

            depends: [{
                name: 'SETNAME',
                value: opts.setName,
            },
            {
                name: 'USERGROUP',
                value: opts.userGroup,
            },
            ],

            requestParameters: {
                item: [{
                    name: 'INQUIRYID',
                    value: opts.inquiryId ? opts.inquiryId : '19029',
                }],
            },

            startRow: 0,
            rowsPerPage: 0,
        };

        return http.post(
            services.generateUrl(constants.URL_GETLOOKUPRESULTS_ACTION),
            data,
            (result) => {
                opts.success(result.rows);
            }, () => {
                opts.error();
            },
        );
    },

    processAction(method, model, options) {
        let data;
        const url = (options.templateMode ? 'template/listView/corp/' : 'payment/transfer/') + method;

        if (options.templateMode) {
            data = {
                items: [{
                    item: transform.hashToPairs({
                        FUNCTIONCODE: model.get('setEntryMode') === 'SINGLE' ? 'TMPL' : 'TMPLSET',
                        TYPECODE: 'TRANSFER',
                        PRODUCTCODE: 'RTGS',
                        TNUM: model.get('TNUM'),
                        ENTRYMETHOD: model.get('ENTRYMETHOD'),
                        UPDATECOUNT__: model.get('UPDATECOUNT__'),
                    }),
                }],
            };
        } else {
            data = {
                item: transform.hashToPairs({
                    FUNCTIONCODE: model.get('FUNCTION'),
                    TNUM: model.get('TNUM'),
                    ENTRYMETHOD: model.get('ENTRYMETHOD'),
                    UPDATECOUNT__: model.get('UPDATECOUNT__'),
                    _saveWithWarning: model.get('_saveWithWarning'),
                    duplicateAccepted: model.get('duplicateAccepted'),
                }),
            };
            if (method === 'reject') {
                data.item.push(options.rejectReasonItem);
            }
        }

        http.post(services.generateUrl(url), data, (result) => {
            options.success(model, result, options);
        }, (result) => {
            options.error({
                errorCode: result.status,
                errorMessage: result.statusText,
                message: result.responseText,
            });
        });
    },

    handleSave(model, opts) {
        let mode;
        let singleModel;
        const self = this;
        const functionCode = model.get('functionCode');

        if (opts.isRepair) {
            mode = 'REPAIR';
        } else if (opts.isModify) {
            mode = 'MODIFY';
        } else {
            mode = 'ADD';
        }

        const url = services.generateUrl(`payment/transfer/multi/${mode}`);

        switch (opts.type) {
        case 'ONE2ONE':

            model.get('items').each((transferModel) => {
                transferModel.set({
                    ISRESTORE: opts.isRestore,
                    PANELPROFILECODE: model.get('PANELPROFILECODE'),
                });
            });
            break;
        case 'SINGLE':
            model.get('items').each((transferModel) => {
                transferModel.set({
                    TEMPLATE_CODE: model.get('setName'),
                    TEMPLATE_DESCRIPTION: model.get('setDescription'),
                    PANELPROFILECODE: model.get('PANELPROFILECODE'),
                });
                /*
                 * if we are creating transfer from transferTemplate, get rid of schedModel
                 * in attributes
                 * it is already parsed in model and not needed
                 */
                if (functionCode === 'INST' && model.get('copyFromTemplate')) {
                    transferModel.unset('schedModel');
                    self.copyFromTemplate = model.get('copyFromTemplate');
                    model.unset('copyFromTemplate');
                }
            });
            if (opts.isModify && model.get('functionCode') === 'TMPL') {
                return this.saveSingleTransferTemplate(model, opts);
            }
            break;
        case 'ONE2MANY':

            singleModel = model.get('singleItem');
            model.get('items').each((transferModelParam) => {
                const transferModel = transferModelParam;
                transferModel.set({
                    TRAN_DATE: singleModel.get('TRAN_DATE'),
                    VALUE_DATE: singleModel.get('VALUE_DATE'),
                    DEBIT_ACCOUNT_NUMBER: singleModel.get('DEBIT_ACCOUNT_NUMBER'),
                    DEBIT_BANK_CODE: singleModel.get('DEBIT_BANK_CODE'),
                    ACCOUNTFILTER: singleModel.get('ACCOUNTFILTER'),
                    ISRESTORE: opts.isRestore,
                    PANELPROFILECODE: model.get('PANELPROFILECODE'),
                });
                transferModel.fromAccount = singleModel.fromAccount;
            });

            model.unset('singleItem');

            break;
        case 'MANY2ONE':

            singleModel = model.get('singleItem');

            model.get('items').each((transferModelParam) => {
                const transferModel = transferModelParam;
                transferModel.set({
                    TRAN_DATE: singleModel.get('TRAN_DATE'),
                    VALUE_DATE: singleModel.get('VALUE_DATE'),
                    BENE_BANK_CODE: singleModel.get('BENE_BANK_CODE'),
                    BENE_ACCOUNT: singleModel.get('BENE_ACCOUNT'),
                    BENE_BANK_COUNTRY: singleModel.get('BENE_BANK_COUNTRY'),
                    CREDIT_CURRENCY: singleModel.get('CREDIT_CURRENCY'),
                    ISRESTORE: opts.isRestore,
                    PANELPROFILECODE: model.get('PANELPROFILECODE'),
                });
                transferModel.toAccount = singleModel.toAccount;
            });

            model.unset('singleItem');

            break;
        default:
        }
        /*
         * NH-91140 Backend does not expect panelProfileDetails && PANELPROFILECODE for any type
         * so convert the model to JSON and remove the keys. Can't unset the attributes of
         * the model as these values are required again if a warning dialog appears
         */
        const data = model.toJSON();
        delete data.panelProfileDetails;
        delete data.PANELPROFILECODE;

        return http.post(url, data, (result) => {
            opts.success(result);
        }, (result) => {
            opts.error({
                errorCode: result.status,
                errorMessage: result.statusText,
                message: result.responseText,
            });
        });
    },

    saveSingleTransferTemplate(model, opts) {
        /*
         * saving recurring transfer
         * PaymentService requires a list of name/value pairs so we have to create
         * that from our model
         */
        const url = services.generateUrl(`template/transfer/recurring/${(opts.isModify) ? 'update' : 'add'}`);

        const [templateModel] = model.get('items').models;
        let nameValuePairs = [];

        util.each(templateModel.attributes, (value, key) => {
            if (util.isString(value)) {
                nameValuePairs.push({
                    name: key,
                    value,
                });
            }
        });

        if (templateModel.fromAccount) {
            nameValuePairs = nameValuePairs.concat(transform.hashToPairs(templateModel.fromAccount.get('mapDataList')));
        }

        if (templateModel.toAccount) {
            nameValuePairs = nameValuePairs.concat(transform.hashToPairs(templateModel.toAccount.get('mapDataList')));
        }

        nameValuePairs.push({
            name: 'isRestore',
            value: opts.isRestore,
        });

        return http.post(
            url,
            {
                item: nameValuePairs,
            },
            (result) => {
                opts.success(result);
            },
            (result) => {
                opts.error({
                    errorCode: result.status,
                    errorMessage: result.statusText,
                    message: result.responseText,
                });
            },
        );
    },

    reject(options) {
        this.sync('reject', this, options);
    },

    sync(method, model, optionsParam) {
        const options = optionsParam;
        if (method === 'create') {
            return this.handleSave(model, options);
        }
        if (method === 'read') {
            return this.handleRead(model, options);
        }
        if (method === 'reject') {
            options.rejectReasonItem = {
                name: 'APPROVER_REJECTION_REASON',
                value: model.get('APPROVER_REJECTION_REASON'),
            };
            return this.processAction(method, model, options);
        }
        return this.processAction(method, model, options);
    },

    parse(response) {
        // This was a save, simply return the object
        if (response.result) {
            return response;
        }

        let subType = '';
        let singleTransfer;

        // Otherwise we must properly parse the read
        const templates = util.map(response, (rowItem) => {
            if (util.isEmpty(rowItem)) {
                return undefined;
            }

            const rec = transform.pairsToHashUnescape(rowItem.columns, 'fieldName', 'fieldValue');

            rec.DEBIT_AMOUNT = Formatter.formatCurrency(rec.DEBIT_AMOUNT);
            rec.CREDIT_AMOUNT = Formatter.formatCurrency(rec.CREDIT_AMOUNT);

            if (!subType) {
                subType = rec.ENTRYMODE;

                if (subType !== 'ONE2ONE') {
                    singleTransfer = new TransferModel(rec);
                }
            }

            return new TransferModel(rec);
        });

        let obj = {};
        if (templates[0]) {
            obj = {
                setName: templates[0].get('SETNAME'),
                setDescription: templates[0].get('DESCRIPTION'),
                updateCount: templates[0].get('PARENTUPDATECOUNT__'),
                setEntryMode: subType,
                commonReference: templates[0].get('CUSTOMER_REFERENCE'),
                templates,
                MAX_SIGNATURES_OVERRIDE: templates[0].get('MAX_SIGNATURES_OVERRIDE'),
                TEMPLATE_MAX_AMOUNT: templates[0].get('TEMPLATE_MAX_AMOUNT'),
                TEMPLATE_MAX_CURRENCY: templates[0].get('TEMPLATE_MAX_CURRENCY'),
            };
        }

        if (singleTransfer) {
            obj.singleTransfer = singleTransfer;
        }

        return obj;
    },

    toJSON(...args) {
        const json = Model.prototype.toJSON.call(this, args);
        const { functionCode } = json;

        /*
         * todo: serverside needs to make this a boolean...
         * if this is INST and is copied from template, then we dont want to save as
         * template cause template already exists
         */
        if (!(this.copyFromTemplate && functionCode === 'INST') && json.saveAsTemplate) {
            json.saveAsTemplate = '1';
        }

        if (json.items) {
            json.items = json.items.toJSON();
        }

        return json;
    },
});
