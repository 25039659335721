import { Collection } from '@glu/core';
import urls from 'ptx/system/urls';


export default Collection.extend({
    url() {
        return urls.virtualTrays;
    },

    getAvailableData(selectedIds) {
        return this.toJSON().filter(model => !selectedIds.includes(model.id));
    },
});
