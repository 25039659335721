import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import ImageSearchResultsList from 'app/reports/views/imageSearch/listImageSearchResults';
import locale from '@glu/locale';
import alert from '@glu/alerts';
import $ from 'jquery';
import filterApi from 'common/dynamicPages/api/filters';
import workspaceHelper from 'common/workspaces/api/helper';
import ImageTypeCollection from 'app/reports/collections/imageTypes';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import viewHelper from 'common/dynamicPages/api/viewHelper';
import moment from 'moment';
import validationPatterns from 'system/validatorPatterns';
import mobileUtil from 'mobile/util/mobileUtil';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import imageSearchTmpl from 'app/reports/views/imageSearch/imageSearch.hbs';

const ImageSearch = Layout.extend({
    template: imageSearchTmpl,

    ui: {
        $searchButton: 'div.section-body button[name="search"]',
        $imageType: 'select[name="imageType"].form-select',
    },

    events: {
        'click @ui.$searchButton': 'search',
        'change @ui.$imageType': 'typeChange',
    },

    initialize() {
        this.model = new Model();

        this.imageTypeCollection = new ImageTypeCollection();
        this.options = {
            menuCategory: 'REPORTING',
            contextDef: {},
            mode: 'insert',
        };

        this.setNotificationData({
            title: '/REPORTING/showImageSearch',
        });
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadViewRequirements();
        } else {
            this.notifyPageLoaded();
            this.selectIfOnlyOne();
        }
    },

    loadViewRequirements() {
        const promises = [];
        const options = {
            context: {
                productCode: '_UTIL',
                functionCode: 'IMGSRCH',
                typeCode: 'SEARCH',
            },
        };

        promises.push(viewHelper.getDateRangePromise(options));

        this.hasDepositItemEntitlements = false;

        promises.push(new Promise((resolve, reject) => {
            this.imageTypeCollection.fetch({
                success: resolve,
                error: reject,
            });
        }));

        Promise.all(promises).then((results) => {
            /*
             * Setting the startDate, endDate and maxDaysPerSearch from the results of
             * the dateRangePromise which is the first one in the promises list.
             */
            util.each(['startDate', 'endDate', 'maxDaysPerSearch'], (elem) => {
                if (results[0].get(elem)) {
                    this[elem] = results[0].get(elem);
                }
            });
            this.setHasLoadedRequiredData(true);
            this.render();
        });
    },

    typeChange() {
        this.model.set('imageType', $('#imageType').val());
        this.imageSearchResultsRegion.close();
        this.filterRegion.reset();
        this.ui.$searchButton.hide();
        this.handleContinue();
    },

    search() {
        const filterModel = this.filterRegion.currentView.model;
        const accountFilter = filterModel.get('ACCOUNTFILTER');
        const dateRange = filterModel.get('DATE');
        const serialNumber = filterModel.get('SERIALNUMBER');
        const wholeNumber = new RegExp(validationPatterns.NUMERIC_PATTERN);
        let errorMessage;
        const self = this;
        this.updateDateRange();
        const additionalSearchFields = filterApi.gatherFilterData(filterModel);
        const options = {
            requestParameters: {
                item: [{
                    name: 'initialRequest',
                    value: 'true',
                }, {
                    name: 'imageType',
                    value: this.model.get('imageType'),
                }],
            },

            additionalSearchFields,
            accountFilter,
            imageType: this.model.get('imageType'),
            context: 'IMAGE_SEARCH',
        };

        if (!accountFilter || !dateRange) {
            if (!accountFilter) {
                errorMessage = 'imageSearch.account.required';
            } else if (!dateRange) {
                errorMessage = 'imageSearch.date.required';
            }
            this.alertMessage(errorMessage);
            return;
        }

        const dp = this.filterRegion.currentView.$('.input-date').data('daterangepicker');
        // validate startDate & endDate exist
        if (dp.startDate && dp.endDate) {
            if (dp.isInvalidDay(dp.startDate) || dp.isInvalidDay(dp.endDate)) {
                this.alertView = alert.danger(
                    locale.get('system.invalidDateRange'),
                    {
                        canDismiss: false,
                    },
                );
                this.imageSearchAlertRegion.show(this.alertView);
                return;
            }
        }

        // validate the day range for search not exceeding allowed number of days
        if (dp.startDate && dp.endDate) {
            const startDate = moment(dp.startDate);
            const endDate = moment(dp.endDate);

            if (startDate < endDate.subtract(this.maxDaysPerSearch, 'day')) {
                this.alertView = alert.danger(
                    locale.get('PS.Search.DateRange.Exceeded').replace('{0}', this.maxDaysPerSearch),
                    {
                        canDismiss: false,
                    },
                );
                this.imageSearchAlertRegion.show(this.alertView);
                return;
            }
        }

        if (!util.isEmpty(serialNumber) && !wholeNumber.test(serialNumber)) {
            this.alertMessage('imageSearch.ivalid.serialnumber');
            return;
        }

        this.imageSearchAlertRegion.close();
        this.ui.$searchButton.attr('aria-busy', true);
        const imageSearchResultsList = new ImageSearchResultsList(options);
        this.listenTo(imageSearchResultsList, 'imageSearchList:child:isReady', () => {
            self.ui.$searchButton.attr('aria-busy', false);
        });
        this.imageSearchResultsRegion.show(imageSearchResultsList);
    },

    alertMessage(errorMessage) {
        this.alertView = alert.danger(
            locale.get(errorMessage),
            {
                canDismiss: false,
            },
        );
        this.imageSearchAlertRegion.show(this.alertView);
    },

    getCacheName() {
        return `model-${this.filterContextDef.serviceName}-_UTIL-IMGSRCH`;
    },

    templateHelpers() {
        return {
            imageTypes: this.imageTypeCollection ? this.imageTypeCollection.toJSON() : [],

            getString(key) {
                return locale.get(key);
            },

            isModal() {
                return true;
            },
            isMobileScreen: mobileUtil.isMobileScreen(),
        };
    },

    handleContinue() {
        const filterContextDef = this.options.contextDef;
        const imageType = this.model.get('imageType');

        if (!imageType) {
            this.alertView = alert.danger(
                locale.get('imageSearch.imageType.required'),
                {
                    canDismiss: false,
                },
            );
            this.imageSearchAlertRegion.show(this.alertView);
            return;
        }

        this.imageSearchAlertRegion.close();
        filterContextDef.serviceName = '/advanceFilter';
        this.filterContextDef = filterContextDef;

        const self = this;

        const requestData = {
            filterId: this.getFilterID(imageType),

            typeInfo: {
                productCode: '_UTIL',
                functionCode: 'IMGSRCH',
                typeCode: 'SEARCH',
            },
        };

        this.filterContextDef.productCode = '_UTIL';
        this.filterContextDef.functionCode = 'IMGSRCH';
        this.filterContextDef.typeCode = 'SEARCH';

        this.datePickerOptions = {
            allowWeekends: true,
            alwaysShowCalendars: true,
        };

        this.staticDependentFields = {
            ACCOUNTFILTER: [{
                filterName: 'Depends',
                filterParam: ['TYPE', imageType.toUpperCase()],
            }],
        };

        store.unset(this.getCacheName());
        store.set(this.getCacheName(), this.model);

        filterApi.getFilters(requestData, self).then(() => {
            self.ui.$searchButton.show();
            self.listenToOnce(self.filterRegion.currentView, 'item:rendered', () => {
                self.defaultDateRange();
            });
        });
    },

    defaultDateRange() {
        const self = this;
        const dates = this.filterRegion.currentView.$('.input-date');
        const filterModel = this.filterRegion.currentView
            ? this.filterRegion.currentView.model : null;
        let dp;

        util.each(dates, (date) => {
            const key = self.$(date).attr('name');

            dp = self.$(date).data('daterangepicker');
            dp.setStartDate(self.startDate);
            dp.setEndDate(self.endDate);
            if (dp.startDate < dp.minDate) {
                dp.minDate = dp.startDate;
            }
            // eslint-disable-next-line
            filterModel._datePickerValues[key] = [dp.startDate, dp.endDate];
        });
    },

    getFilterID(imageType) {
        switch (imageType.toUpperCase()) {
        case 'CHECK':
            return 9029;
        case 'RDEPITEM':
            return 9031;
        case 'DEPLIST':
            return 9030;
        case 'DEPTKT':
            return 9030;
        default:
            return 9030;
        }
    },

    updateDateRange() {
        const self = this;
        const dates = this.filterRegion.currentView.$('.input-date');
        const filterModel = this.filterRegion.currentView
            ? this.filterRegion.currentView.model : null;
        let dp;

        util.each(dates, (date) => {
            const key = self.$(date).attr('name');

            dp = self.$(date).data('daterangepicker');
            // eslint-disable-next-line
            filterModel._datePickerValues[key] = [dp.startDate, dp.endDate];
        });
    },

    /**
     * @name selectIfOnlyOne
     * @description  selects the image type if there is only one choice available
     */
    selectIfOnlyOne() {
        const ifOne = (serverConfigParams.get('SelectIfOnlyOne') && serverConfigParams.get('SelectIfOnlyOne').toUpperCase() === 'TRUE');
        const collection = this.imageTypeCollection;
        if (ifOne && (collection && collection.length === 1)) {
            this.model.set('imageType', this.ui.$imageType.find('option').eq(1).attr('value'));
        }
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'IMAGE_SEARCH',
    view: ImageSearch,
    options: {},
});

export default ImageSearch;
