import { ItemView } from 'backbone.marionette';
import util from '@glu/core/src/util';
import { appBus } from '@glu/core';
import $ from 'jquery';

const CONTAINER_MIN_HEIGHT = 650;
const MANAGE_TEMPLATE_GROUPS_MIN_HEIGHT = 790;

export default ItemView.extend({
    lastHeight: 0,
    lastWidth: 0,

    // field to indicate whether to pause publishing current DOM change
    resizePaused: false,

    initialize(options) {
        this.frameController = options.frameController;
        this.domObserver = options.domObserver;
        this.domChangeHandle = util.debounce(util.bind(this.handleDomChange, this), 10);
    },

    delegateEvents() {
        ItemView.prototype.delegateEvents.call(this);
        this.listenTo(this.domObserver, 'change', this.domChangeHandle);
        // Add specific handler for ListBuilder Modal Changes
        this.listenTo(appBus, 'listBuilderModalChange', this.domChangeHandle);
        this.listenTo(appBus, 'pauseResizePublish', this.pausePublishDomChange);
        this.listenTo(appBus, 'resumeResizePublish', this.resumePublishDomChange);
        this.listenTo(appBus, 'forcePublishNewResizeMinHeight', this.domChangeHandle);
    },

    /**
     * Pause on sending resize messages
     */
    pausePublishDomChange() {
        this.resizePaused = true;
    },

    /**
     * Resume sending resize messages
     */
    resumePublishDomChange() {
        this.resizePaused = false;
    },

    /**
     * Height check specific to manage columns panel which is absolutely positioned.
     * @returns {number}
     */
    getGearHeight() {
        const $g = this.$('.grid-manage-columns-menu:visible');
        if ($g.length !== 0) {
            // Add offset from top & height
            return $g.offset().top + $g.height() + 60;
        }
        return 0;
    },

    /**
     * Find the height of open modals which are absolutely positioned
     * @returns {number}
     */
    getModalHeight() {
        const modalHeights = $('.modal-dialog').toArray()
            .map(el => el.getBoundingClientRect().bottom);
        return Math.max(0, ...modalHeights);
    },

    /**
     * Find the height of open datepicker elements which are absolutely positioned
     * @returns {number}
     */
    getDatepickerHeight() {
        const datePickerEdges = $('.daterangepicker').toArray()
            .map(el => el.getBoundingClientRect().bottom);
        return Math.max(0, ...datePickerEdges);
    },
    /**
     * Height check specific to manage Grid Pop-up which is absolutely positioned.
     * @returns {number}
     */
    getPopUpHeight() {
        const $g = this.$('.grid-action-popup-wrapper');
        if ($g.length !== 0) {
            // Add offset from top & height
            return $g.offset().top + $g.height();
        }
        return 0;
    },

    /**
     * Accumulates the "special" heights:
     * - Open Modal
     * - Open Grid Column selector "Gear"
     * @returns {number}
     */
    getOtherHeight() {
        return Math.max(
            this.getGearHeight(),
            this.getModalHeight(),
            this.getDatepickerHeight(),
            this.getPopUpHeight(),
        );
    },

    /**
     * @param {number} height
     * @param {number} width
     * @returns {boolean}
     */
    isSameSize(height, width) {
        return height === this.lastHeight && width === this.lastWidth;
    },

    handleDomChange(minHeight) {
        const w = this.$el.width();
        let h = this.$el.height();

        if (this.resizePaused) {
            return;
        }

        // handleDomChange receives the DOM element as first argument when used normally
        if (typeof minHeight === 'number' && h < minHeight) {
            h = minHeight;
        }

        const sameSize = this.isSameSize(h, w);
        // otherHeight can accumulate special cases as we find them.
        const otherHeight = this.getOtherHeight();

        if (sameSize && otherHeight === 0) {
            return;
        }

        h = Math.max(h, otherHeight, CONTAINER_MIN_HEIGHT);

        if (minHeight?.firstChild?.baseURI.endsWith('manageTemplateGroups') === true) {
            // Manage Template Groups dialog needs bigger height
            h = Math.max(h, MANAGE_TEMPLATE_GROUPS_MIN_HEIGHT);
        }
        this.frameController.publish(
            'resize',
            {
                height: h,
                width: w,
            },
        );
        this.frameController.publish('getPositionData');
        this.lastHeight = h;
        this.lastWidth = w;
    },

    render() {
        return this;
    },
});
