// TODO refactor to make this the base class if possible.
import util from '@glu/core/src/util';
import EntitlementView from './payments/assignedPermissions/entitlementView';
import entitlementViewTmpl from './entitlementView.hbs';

export default EntitlementView.extend({
    template: entitlementViewTmpl,

    initialize(opts, ...rest) {
        EntitlementView.prototype.initialize.call(this, [opts, ...rest]);
        this.label = this.model.label;
        this.mode = opts.mode;
    },

    templateHelpers() {
        return util.extend(
            EntitlementView.prototype.templateHelpers.call(this),
            {
                label: this.label,
            },
        );
    },
});
