import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Switch from '@glu/switch-react';
import { ThemeProvider, legacyTheme, withStyles } from '@glu/theming';
import { legacyTheme as btiqeLegacyTheme } from '@dbiqe/common';
import styles from './SwitchCell.styles';
import useModelListener from '../../../hooks/useModelListener';
import { getBooleanValue } from './constants';

const propTypes = {
    model: PropTypes.shape({
        get: PropTypes.func.isRequired,
        set: PropTypes.func.isRequired,
        on: PropTypes.func.isRequired,
        off: PropTypes.func.isRequired,
    }).isRequired,
    column: PropTypes.shape({
        get: PropTypes.func.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
    /*
     * This can be a function so that the value can be based off
     * of entitlements which haven't resolve when the grid is instantiated
     */
    readOnly: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    negativeLabel: PropTypes.string,
    positiveLabel: PropTypes.string,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onToggleSwitch: PropTypes.func,
};
const defaultProps = {
    readOnly: false,
    negativeLabel: '',
    positiveLabel: '',
    onToggleSwitch: undefined,
};

const SwitchCell = ({
    model,
    column,
    onChange,
    value,
    readOnly,
    positiveLabel,
    negativeLabel,
    classes,
    onToggleSwitch,
    ...props
}) => {
    const name = column.get('fieldName');
    const [toggleValue, setToggleValue] = useState(getBooleanValue(value));

    const isReadOnly = typeof readOnly === 'function' ? readOnly() : readOnly;

    const onToggle = (fieldName, newValue) => {
        setToggleValue(newValue);
        onChange(fieldName, newValue, model);
        if (onToggleSwitch) {
            onToggleSwitch();
        }
    };
    useModelListener(model, 'switch:toggled', setToggleValue);
    return (
        <ThemeProvider theme={btiqeLegacyTheme} baseTheme={legacyTheme}>
            {isReadOnly
                ? <span>{toggleValue ? positiveLabel : negativeLabel}</span>
                : (
                    <Switch
                        className={classes.switch}
                        labelText=""
                        name={name}
                        value={toggleValue}
                        onChange={onToggle}
                        positiveLabel={positiveLabel}
                        negativeLabel={negativeLabel}
                        {...props}
                    />
                )}
        </ThemeProvider>
    );
};

SwitchCell.propTypes = propTypes;
SwitchCell.defaultProps = defaultProps;

export default withStyles(styles)(SwitchCell);
