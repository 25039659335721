export const styles = {
  filterWrapper: {
    '& > div[class*="dropdown"]': {
      width: '100%'
    },
    '& input[title*="Number Input"]': {
      width: '100%'
    },
    '& ul': {
      '& > li:first-child': {
        marginTop: 0
      },
      margin: [7, -10, -6, -5],
      maxHeight: 175,
      overflow: 'auto',
      padding: [0, 5]
    },
    marginBottom: 20
  }
};

export default styles;
