var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"blockClass") || (depth0 != null ? lookupProperty(depth0,"blockClass") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"blockClass","hash":{},"data":data,"loc":{"start":{"line":2,"column":12},"end":{"line":2,"column":26}}}) : helper)))
    + " required\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"blockClass") || (depth0 != null ? lookupProperty(depth0,"blockClass") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"blockClass","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":26}}}) : helper)))
    + "\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " readonly tabIndex=\"-1\" ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"mandatory") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":5,"column":7}}})) != null ? stack1 : "")
    + "\n    <!-- Label -->\n    <label for=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldName") || (depth0 != null ? lookupProperty(depth0,"fieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldName","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":29}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldLabel") || (depth0 != null ? lookupProperty(depth0,"fieldLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldLabel","hash":{},"data":data,"loc":{"start":{"line":8,"column":31},"end":{"line":8,"column":45}}}) : helper)))
    + "</label>\n\n    <div class=\"form-column\">\n        <!-- Field 1 -->\n        <div class=\"search-input-wrapper\">\n            <label for=\"field-value\" class=\"sr-only\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.search.value",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":53},"end":{"line":13,"column":85}}}))
    + "</label>\n            <input class=\"form-control input-date\" type=\"text\" data-bind=\"model\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldName") || (depth0 != null ? lookupProperty(depth0,"fieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldName","hash":{},"data":data,"loc":{"start":{"line":14,"column":87},"end":{"line":14,"column":100}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"protected") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":102},"end":{"line":14,"column":150}}})) != null ? stack1 : "")
    + " value=\"\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldName") || (depth0 != null ? lookupProperty(depth0,"fieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldName","hash":{},"data":data,"loc":{"start":{"line":14,"column":164},"end":{"line":14,"column":177}}}) : helper)))
    + "\">\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldName") || (depth0 != null ? lookupProperty(depth0,"fieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldName","hash":{},"data":data,"loc":{"start":{"line":15,"column":70},"end":{"line":15,"column":83}}}) : helper)))
    + "\"></span>\n        </div>   \n    </div>\n\n</div>\n";
},"useData":true});