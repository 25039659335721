import locale from '@glu/locale';
import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import constants from 'app/administration/constants';
import Collection from '@glu/core/src/collection';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import AbsTypes from './assignedPermissions/absTypes';
import RestrictedTemplates from '../payments/restrictedTemplates';
import absTmpl from './abs.hbs';

export default Layout.extend({
    template: absTmpl,
    loadingTemplate: loadingPageTmpl,

    ui: {
        $selectAll: '.select-all-perms',
    },

    events: {
        'click @ui.$selectAll': 'selectAllPerms',
    },

    initialize(opts) {
        this.mode = opts.mode;
        this.roleInfo = this.model.roleInfo;
        const complexTypes = this.model.get('complexTypes');
        this.permissions = complexTypes.length ? this.model.get('complexTypes').at(0).get('typesInGroup') : new Collection();
        this.entity = opts.entity;

        if (opts.mode === constants.MODES.VIEW) {
            this.permissions.reset(this.permissions.filter(perm => perm.isEntitled()));
        }

        this.permissions.comparator = function (model) {
            return locale.get(`type.${model.get('typeGroupLabel')}`);
        };
        this.permissions.sort();

        this.userGroup = this.model.userModel && this.model.userModel.get('USERGROUP');
        if (!this.userGroup) {
            this.userGroup = this.model.userGroupModel && this.model.userGroupModel.get('parentUserGroup');
        }

        this.setHasLoadedRequiredData(true);
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.renderPermissions();
            this.renderRestrictedTemplates();
        }
    },

    /**
     * Renders the permissions region
     */
    renderPermissions() {
        this.permissionsView = new AbsTypes({
            model: this.model,
            mode: this.mode,
            isRole: true,
            userGroup: this.userGroup,
        });
        this.permissionsRegion.show(this.permissionsView);
    },

    /**
     * Renders the restricted templates region
     */
    renderRestrictedTemplates() {
        if (this.allowsRestrictedTemplates()) {
            this.restrictedTemplatesView = new RestrictedTemplates({
                mode: this.mode,
                model: this.model.get('simpleDataEntitlements').at(0),
                userGroup: this.userGroup,
                entity: this.entity,
            });

            this.restrictedTemplatesRegion.show(this.restrictedTemplatesView);
        }
    },

    /**
     * Renders the restricted templates region
     * @return {boolean} are restricted templates allowed
     */
    allowsRestrictedTemplates() {
        const simpleDataEnt = this.model.get('simpleDataEntitlements');
        const allowsRestrictedTemplates = !util.isUndefined(simpleDataEnt)
            && simpleDataEnt.length > 0;
        return (this.mode !== constants.MODES.VIEW && allowsRestrictedTemplates)
            || (allowsRestrictedTemplates
                && !util.isUndefined(simpleDataEnt.at(0).get('dataEntitlements'))
                && (simpleDataEnt.at(0).get('dataEntitlements').length > 0
                    || simpleDataEnt.at(0).get('assignAll') === true));
    },

    /**
     * Selects all permissions for that can be entitled
     */
    selectAllPerms(evt) {
        this.permissionsView.selectAllReqInPerms(evt);
        this.permissionsView.selectAllReqOutPerms(evt);
        this.permissionsView.selectAllReqOutTmplPerms(evt);
        this.permissionsView.selectAllMsgsPerms(evt);
    },

    onClose() {
        this.permissionsView.close();
        if (this.gridView) {
            this.gridView.close();
        }
    },

    templateHelpers() {
        const complexTypes = this.model.get('complexTypes');
        const types = complexTypes.length > 0 ? complexTypes.at(0).get('typesInGroup') : null;
        const hasRFPOutbound = types && types.findWhere({ typeCode: 'REQOUT' });
        const hasRFPInbound = types && types.findWhere({ typeCode: 'REQIN' });

        return {
            cid: this.model.cid,
            readOnly: this.mode === constants.MODES.VIEW,
            hasRFP: hasRFPOutbound || hasRFPInbound,
        };
    },

});
