import React, {
  useEffect, useMemo, useState, useCallback, useContext
} from 'react';
import { withStyles, withTheme } from '@glu/theming';
import classnames from 'classnames';
import { AgGridReact } from 'ag-grid-react';
import isEqual from 'lodash/isEqual';
import locale from '@glu/locale';
import Actions from '../Actions/Actions';
import SubRowControl from '../SubRowControl/SubRowControl';
import withGridId from '../withGridId/withGridId';
import { combineColumnData } from '../../utils/columns';
import { propTypes, defaultProps } from './Grid.props';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import styles from './Grid.styles';
import '../../themeDefaults';
import GridContext, { withGridContext } from './GridContext';
import { defaultSubRowControlColumnDef } from './subRowControlColumn';
import HeaderCellRenderer from '../ColumnMenu/HeaderCellRenderer';

export const sizes = Object.freeze({
  small: 25,
  medium: 35
});

const mapRowHeight = (rHeight) => (
  {
    height: sizes[rHeight],
    className: rHeight
  }
);

const updatePaging = (gridApi, setVisibleItems, prevRowCount) => {
  setVisibleItems(gridApi.paginationGetRowCount());

  const currRowCount = gridApi.getDisplayedRowCount();
  // show label if no rows
  if (prevRowCount !== currRowCount && currRowCount === 0) {
    gridApi.showNoRowsOverlay();
  }
  // hide 'no rows' label
  if (prevRowCount !== currRowCount && prevRowCount === 0) {
    gridApi.hideOverlay();
  }
};

const Grid = ({
  alternateRowShade,
  onChange,
  onGridReady,
  captureApis,
  columnDefs,
  convertFilters,
  defaultState,
  data,
  frameworkComponents: sourceFrameworkComponents,
  records,
  setVisibleItems,
  setSelections,
  SubRow,
  columnHasSubRowContent,
  sizeColumnsToFit,
  classes,
  gridRowHeight,
  columnHeaderFilters,
  PopoverProps,
  ...props
}) => {
  const [capturedColumnApi, setColumnApi] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const [gridReady, setGridReady] = useState(false);

  useEffect(
    () => () => {
      if (gridApi) {
        gridApi.destroy();
      }
    },
    [gridApi]
  );

  useEffect(() => {
    const resize = () => {
      if (gridApi && sizeColumnsToFit && gridReady) {
        gridApi.sizeColumnsToFit();
      }
    };
    if (gridApi && sizeColumnsToFit && gridReady) {
      gridApi.sizeColumnsToFit();
      window.addEventListener('resize', resize);
    }
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [gridApi, sizeColumnsToFit, gridReady]);


  // filter effect
  useEffect(() => {
    const converted = convertFilters(data.filters);

    if (gridApi && !isEqual(converted, gridApi.getFilterModel())) {
      const prevRowCount = gridApi.getDisplayedRowCount();

      gridApi.setFilterModel(converted);
      gridApi.onFilterChanged();

      updatePaging(gridApi, setVisibleItems, prevRowCount);
    }
  }, [convertFilters, data.filters, gridApi, setVisibleItems, records]);

  // record changing effect
  useEffect(() => {
    if (gridApi) {
      updatePaging(gridApi, setVisibleItems);
    }
  }, [gridApi, setVisibleItems, records]);

  useEffect(() => {
    if (!capturedColumnApi) return;

    const addSubRowCol = (columnHasSubRowContent || SubRow) && !columnDefs.find(
      col => col.colId === defaultSubRowControlColumnDef.colId
    );

    const allColsAreHidden = data.columns && data.columns.every(col => col.hide);
    const columns = addSubRowCol && !allColsAreHidden
      ? [
        ...(addSubRowCol
          ? [{
            ...defaultSubRowControlColumnDef,
            cellClass: classes.subRowControl,
            cellRendererParams: { SubRow },
            hide: false
          }]
          : []
        ),
        ...data.columns
      ] : data.columns; // eslint-disable-line prefer-destructuring

    if (columns && columns.length && !isEqual(columns, capturedColumnApi.getColumnState())) {
      capturedColumnApi.setColumnState(columns);
    }
  }, [SubRow, classes.subRowControl,
    columnHasSubRowContent, data.columns, capturedColumnApi, columnDefs]);

  useEffect(() => {
    if (!gridApi) return;

    const sort = data.sort; // eslint-disable-line prefer-destructuring
    const gridSort = gridApi.getSortModel();

    if (!isEqual(sort, gridSort)) {
      gridApi.setSortModel(sort);
    }
  }, [data.sort, gridApi]);

  useEffect(() => {
    if (gridApi && data.pageSize !== gridApi.paginationGetPageSize()) {
      gridApi.paginationSetPageSize(data.pageSize);
    }
  }, [data.pageSize, gridApi]);

  useEffect(() => {
    if (gridApi && data.page !== gridApi.paginationGetCurrentPage()) {
      if (data.page) {
        gridApi.paginationGoToPage(data.page);
      } else {
        gridApi.paginationGoToFirstPage();
      }
    }
  }, [data.page, gridApi]);

  const getRowClass = useCallback((params) => {
    const shadeClass = alternateRowShade
      && ((params.node.rowIndex + 1) % 2 === 0)
      && classes.shadedRow;
    const heightClass = classes[mapRowHeight(gridRowHeight).className];
    return classnames(classes.row, heightClass, shadeClass);
  }, [classes, gridRowHeight, alternateRowShade]);

  const handleGridChange = ({ api, columnApi, type }) => {
    // Ignore most of the bootstrapping
    if (!gridReady) {
      return;
    }
    if (/sort/.test(type)) {
      const sort = api.getSortModel();
      if (isEqual(sort, data.sort)) {
        return;
      }
      onChange({
        name: 'sort',
        value: sort,
        errors: {}
      });
      return;
    }
    if (/pagination/.test(type) && !props.serverSidePagination) {
      const page = api.paginationGetCurrentPage();
      const pageSize = api.paginationGetPageSize();
      if (isEqual(page, data.page) && isEqual(pageSize, data.pageSize)) {
        return;
      }
      onChange({
        name: 'page',
        value: page,
        errors: {}
      });

      onChange({
        name: 'pageSize',
        value: pageSize,
        errors: {}
      });
    }

    if (/column/.test(type)) {
      const columns = columnApi.getColumnState();
      const combined = combineColumnData(columnDefs, columns);
      /*
       * This is protecting against bootstrap changes from
       * ag grid. No real way to test.
       */
      /* istanbul ignore next */
      if (isEqual(combined, data.columns)) {
        return;
      }
      onChange({
        name: 'columns',
        value: combineColumnData(columnDefs, columns),
        errors: {}
      });
    }
  };

  const updateSelections = ({ api }) => {
    setSelections(api.getSelectedRows());
  };

  const handleGridReady = params => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    setGridReady(true);
    onGridReady(params);
  };

  const frameworkComponents = {
    actions: useMemo(() => withGridId(props.gridId)(Actions), [props.gridId]),
    subRowControl: useMemo(() => withGridId(props.gridId)(SubRowControl), [props.gridId]),
    ...sourceFrameworkComponents
  };

  if (columnHeaderFilters) {
    frameworkComponents.agColumnHeader = withGridId(props.gridId)(HeaderCellRenderer);
  }

  const memoizedColDefs = useMemo(() => {
    const addSubRowCol = (columnHasSubRowContent || SubRow) && !columnDefs.find(
      col => col.colId === defaultSubRowControlColumnDef.colId
    );

    return [
      ...(addSubRowCol
        ? [{
          ...defaultSubRowControlColumnDef,
          cellClass: classes.subRowControl,
          cellRendererParams: { SubRow }
        }]
        : []
      ),
      ...columnDefs.map(({ type, ...rest }) => rest)
    ];
  }, [SubRow, classes.subRowControl, columnDefs, columnHasSubRowContent]);

  const gridContext = useContext(GridContext);
  useEffect(() => {
    gridContext.setFilters(data.filters);
  }, [data.filters]);

  return (
    <div className={classnames(classes.root, 'ag-theme-material')}>
      <AgGridReact
        context={{
          records,
          onChange,
          data
        }}
        rowHeight={mapRowHeight(gridRowHeight).height}
        reactNext
        columnDefs={memoizedColDefs}
        domLayout="normal"
        onColumnMoved={handleGridChange}
        onFirstDataRendered={handleGridReady}
        onPaginationChanged={handleGridChange}
        onSortChanged={handleGridChange}
        pagination
        target="screen"
        rowData={records}
        headerHeight={40}
        getRowClass={getRowClass}
        frameworkComponents={frameworkComponents}
        onSelectionChanged={updateSelections}
        suppressPaginationPanel
        suppressDragLeaveHidesColumns
        suppressPropertyNamesCheck
        localeText={{ noRowsToShow: locale.get('noRowsToShow') }}
        suppressRowClickSelection
        {...props}
        defaultColDef={{
          cellClass: params => (params.colDef.actions ? 'action' : ''),
          ...props.defaultColDef,
          cellRendererParams: {
            ...(props.defaultColDef && props.defaultColDef.cellRendererParams
              ? props.defaultColDef.cellRendererParams
              : {}),
            PopoverProps,
            gridRowHeight
          }
        }}
        // this will break pagination if it is passed in as ag grid doesn't seem to respond
        // to it beyond the initial value
        paginationPageSize={undefined}
      />
    </div>
  );
};

Grid.propTypes = propTypes;
Grid.defaultProps = defaultProps;

export const BareGrid = Grid;

const GridWithContext = withGridContext(Grid);

export default withTheme(withStyles(styles)(GridWithContext));
