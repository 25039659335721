import dateUtil from 'common/util/dateUtil';
import moment from 'moment';
import userInfo from 'etc/userInfo';

/**
 * Perform a server call to get the dates that are available to the user based on the current
 * request values and then refresh the calander to reflect them.
 * @param {Object} rfpForm - form containing the date picker to be refreshed
 * @param {Model} rfpModel - model holding all of the request attributes
 */
const refreshDatePicker = (rfpForm, rfpModel) => {
    const postData = {
        paymentType: rfpModel.get('TYPE'),
        debitBank: rfpModel.get('DEBIT_BANK_CODE'),
        subType: '*',
        creditCurrency: rfpModel.get('CREDIT_CURRENCY'),
        beneBankId: rfpModel.get('ORIGINATOR_ID'),
        beneBankType: rfpModel.get('ORIGINATOR_ID_TYPE'),
        valueDate: rfpModel.get('VALUE_DATE'),
        tranDate: rfpModel.get('TRAN_DATE'),
    };
    dateUtil.refreshEffectiveDateCalendar(rfpForm, 'VALUE_DATE', postData);
};

export default function (form, initialState) {
    const { model } = form.formView;
    const pageViewModel = form.formView.pageView.model;
    /*
     * Depending on the type of MetaDrivenFooter some fields may need to be prepopulated with values
     * not explicity provided by the pageView or a separate "read" call
     */
    const applyDefaults = () => {
        if (form.formView.context.actionContext.subType === 'PAY') {
            model.jsonData.typeInfo.functionCode = 'INST';
            model.jsonData.typeInfo.typeCode = 'CRTRAN';
            model.set({
                FULL_AMOUNT: pageViewModel.get('INSTRUCTED_AMT'),
                VALUE_DATE: moment(new Date()).format(userInfo.getDateFormat()),
            });

            /*
             * Merge the values in the selected account into the RFP model.
             * The initialization of the ACCOUNTFILTER combobox does not trigger the
             * model update that occurs when a user selects an account so we must
             * do it during the form setup.
             */
            const acct = form.formView.comboCollections.ACCOUNTFILTER.find(account => account.name === pageViewModel.get('ACCOUNTFILTER'));
            if (acct) {
                acct.mapDataList.forEach((mapData) => {
                    const toField = mapData.toField.toUpperCase();
                    model.set(toField, mapData.value);
                });
            } else {
                /*
                 * if the model contains a value for ACCOUNTFILTER that is not included in the list
                 * of valid results, clear the value on the model
                 */
                model.set('ACCOUNTFILTER', '');
            }

            // return the radio button date picker to its default state
            form.formView.$('[data-type="TODAY"]').prop('checked', true).change();
        }
    };

    /**
     * In the REJECT footer form, the COMMENT field should toggle depending on the selected
     * REJECT_REASON
     */
    const checkForHideComment = () => {
        const rejectReason = model.get('REJECT_REASON');
        if (rejectReason === 'NARR' || rejectReason === '1100') {
            form.formView.$('[name="COMMENT"]').closest('.field-container').show();
            model.addValidator('COMMENT', {
                exists: true,
                description: model.fieldData.COMMENT.fieldLabel,
            });
        } else {
            form.formView.$('[name="COMMENT"]').closest('.field-container').hide();
            model.set('COMMENT', '');
            model.removeValidator('COMMENT');
        }
    };

    /**
     * When the radio buttons on the date picker are toggled, perform read-only toggle logic and
     * set some default values
     */
    const updateDatePickerButtons = (e) => {
        const dateSelection = e.target.dataset.type;
        const datePickerInput = form.formView.$(e.target.closest('.date-picker')).children('.input-date');
        // by default set the new date to Today
        let newDate = moment(new Date()).format(userInfo.getDateFormat());
        let lockDatePickerInput = true;

        if (dateSelection === 'DUEON') {
            /*
             * Set the new date to the DUE ON date from the main page's model.
             * We can take it for granted that REQUESTED_EXECUTION_DATE will be
             * formatted with the user's date and time format. We just need the
             * date portion.
             */
            newDate = moment(pageViewModel.get('REQUESTED_EXECUTION_DATE'), userInfo.getDateFormat())
                .format(userInfo.getDateFormat());
        } else if (dateSelection === 'OTHER') {
            lockDatePickerInput = false;
            refreshDatePicker(form, model);
        }

        model.set('VALUE_DATE', newDate);

        datePickerInput.toggleClass('read-only', lockDatePickerInput);
        datePickerInput.prop('disabled', lockDatePickerInput);
    };

    if (initialState) {
        // listener setup here BEFORE defaults are applied so it will trigger once on page load
        if (form.formView.context.actionContext.subType === 'PAY') {
            form.formView.$('[name="optionalRadio"]').change(updateDatePickerButtons);
        }

        applyDefaults();

        if (form.formView.context.actionContext.subType === 'REJECTFM') {
            model.on('change:REJECT_REASON', checkForHideComment);

            // hide the comment field unless it's needed
            form.formView.$('[name="COMMENT"]').closest('.field-container').hide();
        }
    }
}

export { refreshDatePicker };
