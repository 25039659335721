import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';

const styles = {
  hidden: {
    border: 0,
    clip: 'rect(0, 0, 0, 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: 1
  },

  root: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
  }
};

const HideableLabel = ({
  children, className, classes, hidden, htmlFor
}) => (
  <label
    className={`${classes.root} ${hidden ? classes.hidden : ''} ${className}`}
    htmlFor={htmlFor}
  >
    <>{children}</>
  </label>
);

HideableLabel.propTypes = {

  /** contents of the label */
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.element]).isRequired,

  /** CSS ClassName to use in addition to built in classes */
  className: PropTypes.string,

  /** Classes for JSS styling */
  classes: PropTypes.objectOf(PropTypes.string).isRequired,

  /** Indicates if the label should be hidden and display for screenreaders only */
  hidden: PropTypes.bool,

  /** Html id to use for the htmlFor attribute of the label */
  htmlFor: PropTypes.string.isRequired
};

HideableLabel.defaultProps = {
  className: '',
  hidden: false
};

export default withStyles(styles)(HideableLabel);
