import util from '@glu/core/src/util';
import services from 'system/servicesList';
import { postData } from 'common/util/services';
import transform from 'common/util/transform';

export const formatForDropdown = response => response.queryResponse.QueryData.queryRows
    .map((row) => {
        const field = transform.pairsToHash(
            row.mapDataList,
            'toField',
            'value',
        );
        return {
            id: field.LOCATIONNAME,
            name: util.unescape(field.LOCATIONDISPLAYNAME),
        };
    });

export const getClientLocations = (model) => {
    const data = {
        IncludeMapData: 1,
        queryCriteria: {
            subTypeCode: '*',
            allowDuplicates: false,
            inquiryId: 42346,
            entryClass: '',
            customFilters: [
                {
                    filterName: 'Depends',
                    filterParam: [
                        'USERGROUP',
                        model.get('USERGROUP'),
                    ],
                },
            ],
            action: {
                productCode: 'RDC',
                functionCode: 'RDC',
                actionMode: 'SELECT',
                entryMethod: 0,
                typeCode: 'MOBILE',
            },
        },
    };
    return postData(services.inquiryQueryResults, data)
        .then(formatForDropdown);
};
