import Model from '@glu/core/src/model';
import Collection from '@glu/core/src/collection';

/**
 * NotificationClient is a relatively simple container.
 * It is a collection for convenience, using a model to store config
 *  and another to track the currently subscribed notification types.
 * There is only one instance of the service, somewhat like the appBus.
 * Sockets, pollers, or any code use standard .listenTo() and .trigger()
 *  directly on the NotificationClient itself.
 *
 * It supports "Automatic Reference Counting" to automatically unsubscribe
 * from events when no more views are listening for them.
 */
const NotificationClient = Collection.extend({
    initialize() {
        this.add(this.getDefaultModels());
    },

    getDefaultModels() {
        return [
            new Model({
                id: 'config',
            }),
            new Model({
                id: 'subscriptions',
            }),
        ];
    },

    /**
     * Simple method to return the appropriate model.
     * @return {*}
     */
    getConfig() {
        return this.get('config');
    },

    /**
     * Simple method to return the appropriate model.
     * @return {*}
     */
    getSubs() {
        return this.get('subscriptions');
    },

    getARC() {
        // Get the arc array.
        const config = this.getConfig();
        return config.get('arc') || {};
    },

    setARC(arc) {
        const config = this.getConfig();
        config.set({
            arc,
        });
    },

    /**
     * "Automatic" Reference Counting for view subscriptions
     * Add view to arc object
     * @param {string} key
     * @param {Object} view
     */
    addToARC(key, view) {
        const arc = this.getARC();
        const group = arc[key] || [];

        /*
         * Add to it and put it back into the object.
         * TODO: could be done immutably
         */
        group.push(view);
        arc[key] = group;
        this.setARC(arc);
    },

    /**
     * "Automatic" Reference Counting for view subscriptions
     * Remove views and unsubscribe if no longer needed.
     * @param {string} key
     * @param {Object} view
     */
    removeFromARC(key, view) {
        const arc = this.getARC();

        // Remove the view.
        const group = (arc[key] || []).filter(v => v !== view);
        arc[key] = group;
        this.setARC(arc);

        // Check for no more references
        if (group.length === 0) {
            this.unsubscribe(key);
        }
    },

    /**
     * "Automatic" Reference Counting for view subscriptions
     * Patch onClose to ensure we don't have stray references.
     * @param {string} key
     * @param {Object} view
     */
    addARCRemoveToView(key, view) {
        const viewParam = view;
        const oldClose = view.onClose;
        viewParam.onClose = (...args) => {
            this.removeFromARC(key, view);
            // Call previous onRemove function
            if (typeof oldClose === 'function') {
                oldClose.apply(view, args);
            }
        };
    },

    clearFromARC(key) {
        const arc = this.getARC();
        // Just wipe the ARC entry.
        arc[key] = [];
        this.setARC(arc);
    },

    /**
     * Assumes `true`, but designed so a specific type can use complex values.
     * Unlike `.listenTo`, this is to indicate to the request component -
     *  a socket or poller - that there is sometime added.
     * @param {String} key - the event to which you are subscribing
     * @param {Boolean|*} state - Plain value, in case you need state. Defaults to true.
     * @param {Object} [view] - A view passed in will enable ARC
     */
    subscribeTo(key, state = true, view) {
        const subs = this.getSubs();
        const data = {
            [key]: state,
        };

        if (view !== undefined) {
            this.addToARC(key, view);
            this.addARCRemoveToView(key, view);
        } else {
            /*
             * Add a timestamp to ARC to prevent accidental unsubscribe.
             * TODO: Is this necessary/useful?
             */
            this.addToARC(key, Date.now());
        }

        subs.set(data);
        this.trigger('service:subscribe', key);
    },

    /**
     * Removes a subscription, regardless of ARC.
     * @param {String} key - The event to unsubscribe
     */
    unsubscribe(key) {
        const subs = this.get('subscriptions');
        subs.unset(key);
        this.clearFromARC(key);
        this.trigger('service:unsubscribe', key);
    },
});

export default new NotificationClient();
