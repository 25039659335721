import Collection from '@glu/core/src/collection';
import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import transform from 'common/util/transform';
import typeaheadhelper from 'common/dynamicPages/views/mdf/componentHelpers/typeahead';
import LookupInquiryService from '../service/bankCodeTypeLookup';
import intermediaryBankFormPreferredResultsTmpl from './intermediaryBankFormPreferredResults.hbs';

const IntermediaryBankFormPreferredResults = Layout.extend({
    template: intermediaryBankFormPreferredResultsTmpl,
    className: 'intermediary-bank-form row',

    ui() {
        return {
            $bankCodeId: 'input[name="INTERMEDIARY_ID"]',
            $bankCodeIdType: 'select[name="INTERMEDIARY_IDTYPE"]',
            $bankEntryMethod: 'input[name="INTERBENEBANKIDENTRYMETHOD"]',
            $intermediaryAccountNumber: 'input[name="INTER_BANK_ACCOUNT_NUMBER"]',
        };
    },

    regions: {
        intermediaryBankForm: '.intermediary-bank-form',
    },

    initialize(options) {
        this.options = options;
        this.model = options.model;
        this.mode = options.mode;
        this.isEntryRow = options.isEntryRow;
        this.isMDF = false;

        /*
         * set model info for intermediary bank inputs to be used when initializing their
         * comboboxes
         */
        this.model.fieldData = [];
        this.model.fieldData.INTERMEDIARY_ID = {
            name: 'INTERMEDIARY_ID',
            fieldUIType: 'TYPEAHEAD_PREFERRED',
            dependsOn: ['BENE_ACCOUNT_CURRENCY', 'BENE_BANK_ID', 'INTERMEDIARY_IDTYPE'],
            typeAhead: [{ field: 'BANKSORTCODE', type: 'STRING' }, { field: 'BANKNAME', type: 'STRING' }],
        };

        this.model.jsonData = {
            typeInfo: {
                productCode: '_ADMIN',
                typeCode: '*',
                functionCode: 'MAINT',
            },
        };

        this.context = {
            actionMode: 'SELECT',
        };

        this.options.typeAheadFieldsOverride = [{
            name: 'INTERMEDIARY_ID',
            overrideUrl: '/beneAddressBook/getLookupResults',
        }];

        this.influencedAttributes = [
            { toField: 'INTERMEDIARY_ADDRESS_1' },
            { toField: 'INTERMEDIARY_ADDRESS_2' },
            { toField: 'INTERMEDIARY_ADDRESS_3' },
            { toField: 'INTERMEDIARY_COUNTRY' },
            { toField: 'INTERMEDIARY_ID' },
            { toField: 'INTERMEDIARY_NAME' },
        ];

        // Set default entry method values if none exist
        if (!this.model.get('INTERBENEBANKIDENTRYMETHOD') && !this.model.get('INTERBANKIDENTRYMETHOD')) {
            this.model.set('INTERBENEBANKIDENTRYMETHOD', 'LOOKUP');
            this.model.set('INTERBANKIDENTRYMETHOD', 'LOOKUP');
        }

        // Set the radio button correctly if we recieved a value from the server
        if (this.model.get('INTERBANKIDENTRYMETHOD')) {
            this.model.set('INTERBENEBANKIDENTRYMETHOD', this.model.get('INTERBANKIDENTRYMETHOD'));
        }

        this.bankIdCollection = new Collection();
        this.bankIdTypeCollection = new Collection();
        this.codeTypeService = new LookupInquiryService();

        this.codeTypeService.setActionMode('SELECT');
        this.codeTypeService.setFunctionCode('MAINT');
        this.codeTypeService.setProductCode('_ADMIN');
        this.codeTypeService.setInquiryId(17088);
        this.codeTypeService.setTypeCode('*');
        this.codeTypeService.send();

        this.fieldTypeData = options.fieldTypeData;

        this.model.on('change:INTERMEDIARY_ID', this.updateAddressText.bind(this));
    },

    delegateEvents() {
        Layout.prototype.delegateEvents.call(this);
        this.listenTo(this.codeTypeService, 'success', this.handleBankIdTypeServiceSuccess);
    },

    parseIdTypeResponse(response) {
        return util.collect(response, (obj) => {
            const tmp = transform.pairsToHash(obj.mapDataList, 'toField', 'value');
            tmp.text = tmp.Intermediary_IdType;
            tmp.id = tmp.Intermediary_IdType;
            return tmp;
        });
    },

    handleBankIdTypeServiceSuccess(response) {
        this.bankIdTypeResponse = response;
        let bankIdCodes = this.parseIdTypeResponse(this.bankIdTypeResponse);
        bankIdCodes = util.map(bankIdCodes, obj => ({
            name: obj.id,
            label: obj.text,
        }));

        this.bankIdTypeCollection.reset(bankIdCodes);
        this.render();
    },

    parseResponse(response) {
        return util.collect(response, (obj) => {
            const tmp = transform.pairsToHash(obj.mapDataList, 'toField', 'value');
            tmp.text = `${tmp.Intermediary_Id} - ${tmp.Intermediary_Name}`;
            tmp.id = tmp.Intermediary_Id;
            return tmp;
        });
    },

    onRender() {
        const comboboxContainer = this.ui.$bankCodeId.siblings('.type-ahead');
        if (!comboboxContainer.get(0)) {
            if (this.firstRender) {
                this.firstRender = false;
            }
            typeaheadhelper.setupTypeaheadBoxes(this, undefined, undefined, ['INTERMEDIARY_ID']);
        }
        this.updateAddressText();
    },

    clearIntermediary() {
        this.manuallyCleared = true;
        this.model.set('INTERMEDIARY_IDTYPE', '');
    },

    updateAddressText() {
        if (this.model.get('INTERMEDIARY_NAME') || this.model.get('INTERMEDIARY_ADDRESS_1') || this.model.get('INTERMEDIARY_ADDRESS_2') || this.model.get('INTERMEDIARY_ADDRESS_3') || this.model.get('INTERMEDIARY_STATE') || this.model.get('INTERMEDIARY_COUNTRY')) {
            this.$el.find('.intermediary_address_container').show();
        } else {
            this.$el.find('.intermediary_address_container').hide();
            return;
        }

        const interName = this.model.get('INTERMEDIARY_NAME') ? this.model.get('INTERMEDIARY_NAME') : '';
        const interAdd1 = this.model.get('INTERMEDIARY_ADDRESS_1') ? this.model.get('INTERMEDIARY_ADDRESS_1') : '';
        const interAdd2 = this.model.get('INTERMEDIARY_ADDRESS_2') ? this.model.get('INTERMEDIARY_ADDRESS_2') : '';
        const interAdd3 = this.model.get('INTERMEDIARY_ADDRESS_3') ? this.model.get('INTERMEDIARY_ADDRESS_3') : '';
        const interState = this.model.get('INTERMEDIARY_STATE') ? this.model.get('INTERMEDIARY_STATE') : '';
        const interCountry = this.model.get('INTERMEDIARY_COUNTRY') ? this.model.get('INTERMEDIARY_COUNTRY') : '';

        this.$el.find('.intermediary_name').text(interName);
        this.$el.find('.intermediary_address_1').text(interAdd1);
        this.$el.find('.intermediary_address_2').text(interAdd2);
        this.$el.find('.intermediary_address_3').text(interAdd3);
        this.$el.find('.intermediary_state').text(interState);
        this.$el.find('.intermediary_country').text(interCountry);
    },

    templateHelpers() {
        return {
            mandatory: this.model.get('INTERMEDIARY_FREE_FORM_ACCOUNT_REQUIRED') === 'true',
            fieldLabel: locale.get('bab.bank.routing.code'),
            fieldName: 'INTERMEDIARY_ID',
            blockClass: 'field-container',
            cssClass: 'form-control',
            bankIdTypes: this.bankIdTypeCollection ? this.bankIdTypeCollection.toJSON() : {},
            cid: this.cid,
            modelCid: this.model.cid,
            fieldTypeData: this.fieldTypeData,
        };
    },
});

export default IntermediaryBankFormPreferredResults;
