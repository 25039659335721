var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <th scope=\"col\"><span class=\"sr-only\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"smbBillMgmt.delete",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":70},"end":{"line":11,"column":101}}}))
    + "</span></th>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button class=\"btn btn-tertiary\" data-hook=\"add-line-item\"><span class=\"icon-add-circle\"></span> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"smbBillMgmt.add.another",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":113},"end":{"line":20,"column":149}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n        <table class=\"table\">\n                <thead>\n                        <tr>\n                                <th scope=\"col\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.code.item.number",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":48},"end":{"line":5,"column":89}}}))
    + "</th>\n                                <th scope=\"col\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.product.name.desc",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":48},"end":{"line":6,"column":90}}}))
    + "</th>\n                                <th scope=\"col\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.quality",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":48},"end":{"line":7,"column":80}}}))
    + "</th>\n                                <th scope=\"col\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.unit.price",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":48},"end":{"line":8,"column":83}}}))
    + "</th>\n                                <th scope=\"col\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":48},"end":{"line":9,"column":79}}}))
    + "</th>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":32},"end":{"line":12,"column":43}}})) != null ? stack1 : "")
    + "\n                        </tr>\n                </thead>\n                <tbody></tbody>\n        </table>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":21,"column":19}}})) != null ? stack1 : "")
    + "\n";
},"useData":true});