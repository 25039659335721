import services from 'services';
import BaseRDCCollection from './baseRDCCollection';

export default BaseRDCCollection.extend({

    /**
     * Get the service URL for this collection
     * @returns {string}
     */
    getServiceURL() {
        return services.rdcDepositHistory;
    },

    /**
     * @param {Object} options
     * @param {string} options.locationName
     * @param {string} options.bankCode
     * @param {string} options.accountNumber
     * @returns {Object} data
     */
    getRequestData(options) {
        return {
            locationName: options.locationName || '',
            bankCode: options.bankCode || '',
            accountNumber: options.accountNumber || '',
        };
    },

    /**
     * @param {Object} jsonData
     * @returns {Array}
     */
    parse(jsonData) {
        this.rdcSessionData = jsonData.rdcSessionData;
        return jsonData.checkDepositHistoryDetails;
    },
});
