import { appBus, date } from '@glu/core';
import locale from '@glu/locale';
import $ from 'jquery';
import moment from 'moment';
import DateView from './dateView';
import template from './endDate.hbs';

export default DateView.extend({
    template,
    propertyName: 'ends',

    modelEvents: {
        'change:ends': 'render',
        'set:dateValue': 'setDate',
        'set:endDateValue': 'setDate'
    },

    ui: {
        $endByField: '[data-hook="getEndByDatepicker"]',
    },

    events: {
        'blur [data-hook="getEndByDatepicker"]': 'validateShownDateField',
        'change [data-hook="getEndByDatepicker"]': 'validateShownDateField',
    },

    validateShownDateField(e, dateRangePicker) {
        let daterangepickerInstance;
        if (e) {
            daterangepickerInstance = $(e.currentTarget).data('daterangepicker');
        } else {
            daterangepickerInstance = dateRangePicker;
        }

        if (!daterangepickerInstance) return;
        const { minDate, maxDate, startDate } = daterangepickerInstance;
        let invalidDate = false;
        const flatMinDate = moment(minDate.format('YYYY-MM-DD'));

        if (flatMinDate && startDate.isBefore(flatMinDate)) {
            invalidDate = true;
        }
        if (maxDate && startDate.isAfter(maxDate)) {
            invalidDate = true;
        }
        this.handleInlineError(invalidDate);
        const endDate = e.endDate || e.target && e.target.value
        if (endDate !== undefined) {
            this.validateDate(endDate);
        }
    },

    handleInlineError(shouldShowError) {
        this.ui.$endByField.closest('.form-group-child').toggleClass('has-error', shouldShowError);
        this.ui.$endByField
            .closest('.datepicker')
            .find('.help-block')
            .text(shouldShowError ? locale.get('common.datepicker.invalidDateRange') : '');
    },

    initialize(options = {}) {
        options.autoUpdateInput = options.autoUpdateInput !== undefined ? options.autoUpdateInput : false;
        DateView.prototype.initialize(options);
        this.serverFormat = options.endDate && options.endDate.dateFormats ? options.endDate.dateFormats.server : 'YYYY-MM-DD';
        this.uiFormat = options.endDate && options.endDate.dateFormats ? options.endDate.dateFormats.ui : 'DD MMM YYYY';
        this.minDate = options.minDate ? date(options.minDate, this.serverFormat) : date().add(1, 'days');
    },

    onRender() {
        DateView.prototype.onRender.apply(this);

        /**
         * If ends is not defined or if it's an integer then return
         */
        const endDate = this.model.get('ends')
        const ends = Number(endDate);
        if (Number.isInteger(ends)) {
            return;
        }

        this.setEndsListener();
        this.validateDate(endDate);
    },

    /**
     * Helper method designed for ACH auth rules to validate end date
     * @param {String} endDate
     */

    validateDate(endDate) {
        const startDate = this.model.get('starts');
        const isValidDate = moment(endDate).isValid();
        if (!isValidDate || startDate && moment(startDate).add(1, 'days').isAfter(moment(endDate))) {
            appBus.trigger('endDate:invalid');
        } else {
            appBus.trigger('endDate:valid');
        }
    },

    setEndsListener() {
        if (this.datepicker) {
            this.datepicker.onDatePicked = (dateRangePicker) => {
                this.model.set('ends', moment(dateRangePicker.startDate).format("YYYY-MM-DD"));
            };
        }
    },
});