import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import moment from 'moment';
import locale from '@glu/locale';
import groupPlanStatsViewTmpl from './groupPlanStatsView.hbs';

const GroupPlanStatsView = ItemView.extend({
    className: 'group-plan-stats',
    template: groupPlanStatsViewTmpl,

    initialize() {
        this.listenTo(this.model, 'sync', this.render);
    },

    templateHelpers() {
        let subPlanCount = 0;
        let submittedCount = 0;
        let notSubmittedCount = 0;
        let lateCount = 0;
        const planYear = `FY 20${this.model.get('fyStart').substring(7)}`;
        const createdOn = this.convertServerDateToLocalDate(this.model.get('createdOn'));
        const expectedDate = this.convertFYDateToLocalDate(this.model.get('expectedDate'));
        const planNotes = '';
        const lines = this.model.get('groupPlanLines');

        if (!util.isUndefined(lines)) {
            lines.each((line) => {
                const lineStatus = line.get('status');
                if (lineStatus === locale.get('cashflow.groupplan.status.submitted')) {
                    submittedCount += 1;
                } else if (lineStatus === locale.get('cashflow.groupplan.status.notsubmitted')) {
                    notSubmittedCount += 1;
                } else if (lineStatus === locale.get('cashflow.groupplan.status.late')) {
                    lateCount += 1;
                }
            });
            subPlanCount = lines.length;
        }

        const planProps = [{
            lineStatus: locale.get('cashflow.groupplan.status.submitted'),
            lineValue: submittedCount,
            planProp: locale.get('cashflow.plan.year'),
            propValue: planYear,
        }, {
            lineStatus: locale.get('cashflow.groupplan.status.notsubmitted'),
            lineValue: notSubmittedCount,
            planProp: locale.get('cashflow.createdon'),
            propValue: createdOn,
        }, {
            lineStatus: locale.get('cashflow.groupplan.status.late'),
            lineValue: lateCount,
            planProp: locale.get('cashflow.plan.dueby'),
            propValue: expectedDate,
        }];

        return {
            id: this.cid,
            subPlanCount,
            planNoteLabel: locale.get('cashflow.plan.notes'),
            plansLabel: locale.get('cashflow.plans'),
            planNotes,
            planProps,
        };
    },

    convertServerDateToLocalDate(dateString) {
        if (!dateString) {
            return '-';
        }
        return moment(dateString, 'YYYY-MM-DD').format(userInfo.getDateFormat());
    },

    convertFYDateToLocalDate(dateString) {
        if (!dateString) {
            return '-';
        }
        return moment(dateString, 'DD-MMM-YY').format(userInfo.getDateFormat());
    },
});

export default GroupPlanStatsView;
