var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button type=\"button\"\n                class=\"btn btn-tertiary "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"class") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":40},"end":{"line":6,"column":79}}})) != null ? stack1 : "")
    + "\"\n                data-hook=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"dataHook") : depth0), depth0))
    + "\"\n                data-action=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"dataAction") : depth0), depth0))
    + "\"\n                aria-label=\""
    + alias3(lookupProperty(helpers,"locale").call(alias1,(depth0 != null ? lookupProperty(depth0,"ariaLabel") : depth0),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":28},"end":{"line":9,"column":53}}}))
    + "\"\n                title=\""
    + alias3(lookupProperty(helpers,"locale").call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":23},"end":{"line":10,"column":44}}}))
    + "\">\n            <span class=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"icon") : depth0), depth0))
    + "\"></span>\n        </button>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"class") : depth0), depth0));
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button type=\"button\"\n                class=\"btn btn-tertiary refresh\"\n                data-hook=\"refresh-button\">\n            <span class=\"icon-refresh\"></span>\n            <span class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.refresh",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":34},"end":{"line":20,"column":61}}}))
    + "</span>\n              <span class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"listview.lastupdated",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":36},"end":{"line":21,"column":69}}}))
    + "</span>\n            <span class=\"refresh-time\"\n                  data-hook=\"refresh-time\"></span>\n        </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"btn-group widget-info-links listViewWidgetInfoLinks\">\n    <div data-region=\"toggleSwitchOuterRegion\" class=\"mask-toggle\"></div>\n    <div >\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"actionIcons") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":13,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasRefresh") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":25,"column":11}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});