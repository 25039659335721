import { createUseStyles } from '@glu/theming';

const styles = () => ({
    formField: {
        'margin-bottom': '20px',
    },
    p: {
        'word-break': 'break-word',
    },
});

export default createUseStyles(styles);
