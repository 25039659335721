import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import constants from 'app/loans/constants';
import invoiceAmountsWidgetTmpl from './invoiceAmountsWidget.hbs';

export default Layout.extend({
    template: invoiceAmountsWidgetTmpl,

    ui: {
        $invoiceFields: '[data-hook="getInvoiceAmounts"]',
        $amounts: '[data-hook="getInvoiceAmount"]',
    },

    initialize() {
        this.invoiceAmountFields = [];
    },

    /**
     * @method handleSelect
     * @param {Model} model
     * @description Accepts the flex object from the invoice dropdown, sets the
     * attributes of the selection on the model, triggers an event.
     * Setting the attributes of the selected collection model onto the current
     * view model forever overwrites the identical model in the collection.
     * Instead of using set, replace the current view model entirely.
     */
    handleSelect(model) {
        this.model = model;
        this.hideShowFieldVisibilityByValue();
    },

    /**
     * @method fieldsShouldBeHidden
     * @param shouldHide
     * @description A helper function to control visibility of all invoice related fields
     */
    fieldsShouldBeHidden(shouldHide) {
        if (shouldHide) {
            this.ui.$invoiceFields.hide();
        } else {
            this.ui.$invoiceFields.show();
        }
    },

    /**
     * @method hideShowFieldVisibilityByValue
     * @description For all possible invoice fields, check the model for a value.
     * Control the visibility based on the value
     */
    hideShowFieldVisibilityByValue() {
        util.chain(constants.INVOICE_FIELDS).keys()
            .each(this.processFieldVisibility.bind(this));
    },

    /**
     * @method processFieldVisibility
     * @param field
     * @description For a given field, check the model for a value
     * with the hasAmountFieldMethod. show/hide based on boolean result
     */
    processFieldVisibility(field) {
        if (this.hasAmountField(field)) {
            this.$(`[data-hook="${constants.INVOICE_FIELDS[field].name}"]`).show();
        } else {
            this.$(`[data-hook="${constants.INVOICE_FIELDS[field].name}"]`).hide();
        }
    },

    /**
     * @method hasAmountField
     * @param field
     * @returns {*|boolean}
     * @description a helper method to check if the model has a field, and if the
     * field has a value. Currently the service
     * sends an int value, however it is unclear if the service in production
     * will always return an int or instead send
     * a string value. So use both isNumber and isEmpty.
     */
    hasAmountField(field) {
        const value = this.model.get(field);
        const hasAmountResult = this.model.has(field)
            && (!util.isEmpty(value) || util.isNumber(value));
        if (hasAmountResult) {
            this.invoiceAmountFields.push(constants.INVOICE_FIELDS[field]);
        }
        return hasAmountResult;
    },

    templateHelpers() {
        return {
            invoiceAmountFields: util.values(constants.INVOICE_FIELDS),
        };
    },
});
