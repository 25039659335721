import CollectionView from '@glu/core/src/collectionView';
import http from '@glu/core/src/http';
import Layout from '@glu/core/src/layout';
import services from 'services';
import store from 'system/utilities/cache';
import CriteriaModel from 'app/challengeManager/models/criteria';
import SystemActionsView from 'app/challengeManager/views/systemActions';
import criteriaTmpl from 'app/challengeManager/views/criteria.hbs';

const CriteriaView = Layout.extend({
    template: criteriaTmpl,

    ui: {
        $name: 'input[name="criteriaName"]',
        $precheck: 'input[name="precheckEnabled"]',
        $precheckpmt: 'input[name="precheckPaymentIncluded"]',
        $general: 'input[name="General"]',
        $challengeType: 'select[name="challengeType"]',
        $loginAction: 'input[name="_CORE_USERS__USER_LOGIN_FREEFORM"]',
    },

    events: {
        'blur @ui.$name': 'loadInitialActions',
    },

    initialize() {
        this.systemActionsCollection = this.options.systemActionsCollection;
        this.isExistingChallenge = this.options.action === 'select' || this.options.action === 'modify';
        if (this.isExistingChallenge) {
            this.loadChallengeData();
        } else {
            this.model = new CriteriaModel();
        }
        this.loadChallengeType();
    },

    loadChallengeData() {
        this.actionModel = store.get('mfa:actionModel');
        const frequency = this.actionModel.get('FREQUENCY');
        const name = this.actionModel.get('CRITERIA_NAME');

        if (frequency.toUpperCase().indexOf('ALWAYS') > -1) {
            this.model = new CriteriaModel({
                frequency: 'ALWAYS',
                criteriaName: name,
            });
        } else if (frequency.toUpperCase().indexOf('ACTION') > -1) {
            this.model = new CriteriaModel({
                frequency: 'ONCEPERACTIONPERSESSION',
                criteriaName: name,
            });
        } else {
            this.model = new CriteriaModel({
                frequency: 'ONCEPERSESSION',
                criteriaName: name,
            });
        }
    },

    loadChallengeType() {
        const self = this;
        let challengeTypeOption = 'ONETIMEPASSCODE';
        let precheckEnabledFlag = false;
        let precheckPaymentIncludedFlag = false;

        const postData = this.getPostData();
        const url = services.generateUrl('/inquiry/getQueryResults');

        http.post(url, postData, (result) => {
            self.challengeMethods = result.queryResponse.QueryData.queryRows;
            if (self.options.action === 'select' || self.options.action === 'modify') {
                if (self.options.systemActionsCollection
                    && self.options.systemActionsCollection.models) {
                    const first = self.options.systemActionsCollection.models[0].attributes;
                    challengeTypeOption = (first.challengeType || challengeTypeOption);
                    precheckEnabledFlag = (first.precheckEnabled === 'ENABLED');
                    precheckPaymentIncludedFlag = (first.precheckPaymentIncluded === 'ENABLED');
                    if (first.finalApproval === 1) {
                        self.model.set('finalApproval', '1');
                    }
                }
                self.model.set({
                    challengeType: challengeTypeOption,
                    precheckEnabled: precheckEnabledFlag,
                    precheckPaymentIncluded: precheckPaymentIncludedFlag,
                });
            }
            for (let i = 0; i < self.challengeMethods.length; i += 1) {
                if (self.challengeMethods[i].name === challengeTypeOption) {
                    self.challengeMethods[i].selected = 'selected';
                }
            }
            self.render();

            self.ui.$precheck.prop('checked', precheckEnabledFlag);
            self.ui.$precheckpmt.prop('checked', precheckPaymentIncludedFlag);
        }, () => {
            // TODO reject(status.status + ' ' + status.statusText);
        });
    },

    onRender() {
        const self = this;
        const challengeType = this.ui.$challengeType.val();

        this.systemActionsList = new CollectionView({
            el: this.$('.mfa-system-actions')[0],
            collection: this.systemActionsCollection,
            itemView: SystemActionsView,

            itemViewOptions: {
                action: this.options.action,
                sysCollection: this.systemActionsCollection,
            },
        });
        this.ui.$challengeType.comboBox();

        if (challengeType) {
            this.model.set('challengeType', challengeType);
        }

        if (this.challengeMethods && this.challengeMethods.length < 2) {
            this.ui.$challengeType.prop('disabled', true);
        }

        self.$('.precheck-control').show();
        self.$('.precheckpmt-control').show();

        this.systemActionsList.render();
        this.loadInitialActions();
        this.listenTo(this.appBus, 'systemActions:updateParentModel', this.setSystemActions);
    },

    loadInitialActions() {
        this.systemActionsList.children.each(function (systemActionView) {
            this.setSystemActions(systemActionView.model.toJSON());
        }, this);
    },

    setSystemActions(sysActions) {
        this.model.convertActionsToServerFormat(sysActions);
    },

    templateHelpers() {
        const self = this;
        return {
            isDisabled: this.options.action === 'select',
            noEdit: this.options.action === 'modify',

            challengeMethods() {
                return self.challengeMethods;
            },
        };
    },

    getPostData() {
        return {
            requestHeader: {
                queryOffset: 0,
                queryPagesize: 0,
            },

            queryCriteria: {
                action: {
                    typeCode: '*',
                    entryMethod: 0,
                    productCode: '_UTIL',
                    functionCode: '*',
                    actionMode: 'SELECT',
                },

                inquiryId: 17268,
            },
        };
    },
});

export default CriteriaView;
