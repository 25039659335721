var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<thead>\n        <tr>\n                <th class=\"column-account\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"columnHeader1") || (depth0 != null ? lookupProperty(depth0,"columnHeader1") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"columnHeader1","hash":{},"data":data,"loc":{"start":{"line":3,"column":43},"end":{"line":3,"column":62}}}) : helper)))
    + " &nbsp;</th>\n                <th class=\"column-comment\"></th>\n                <th class=\"column-amount\">"
    + alias3((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias2).call(alias1,"payment.Amount",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":5,"column":42},"end":{"line":5,"column":73}}}))
    + "</th>\n                <th class=\"column-currency\"></th>\n                <th class=\"column-actions\"></th>\n        </tr>\n</thead>\n\n<tbody></tbody>\n";
},"useData":true});