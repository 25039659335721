import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import Collection from '@glu/core/src/collection';
import WorkspaceCollection from 'common/workspaces/collections/workspaces';
import WidgetCollection from 'common/workspaces/collections/widgets';
import configParam from 'system/webseries/models/configurationParameters';

const WorkspaceHelper = Model.extend({
    initialize() {
        this.list = new WorkspaceCollection();
        this.publishedWidgets = new WidgetCollection();
        this.hasSetLockedWidgets = false;
    },

    getWorkspaceTitle(workspaceId) {
        const workspaceModel = this.list.get(workspaceId);
        if (workspaceModel) {
            return workspaceModel.get('description');
        }
        return '';
    },

    /**
     * @method returnToCurrentWorkspaceOnResponse
     *
     * @param {object} [view] - relevant view
     *
     * Helper method to return user to the original screen where they initially came from
     * User will not be positioned vertically in the browser at the last saved
     * scroll Y position
     *
     */
    returnToCurrentWorkspaceOnResponse(view) {
        this.returnToCurrentWorkspace(view, true);
    },

    /**
     * @method returnToCurrentWorkspace
     *
     * @param {object} view  -    relevant view
     * @param {boolean} [noScrollY] - indicates whether to skip using the scrollY value
     * to position the user on the returning workspace at the initial position
     * from which the user came from
     *
     * Helper method to return user to the original screen where the user initially came from
     *
     */
    returnToCurrentWorkspace(view, noScrollY) {
        let route = store.get('current-workspace-route');
        /*
         * User may have navigated from view detail page to another detail page,
         * or modify page. When this is the case, we return to the initial page
         */
        const originalContextRoute = this.getOriginalContextRoute(view);
        if (originalContextRoute) {
            route = originalContextRoute;
            view.navigateTo(originalContextRoute);
            return;
        }

        // Always use lastFragement when it is present
        if (view.lastFragment) {
            view.navigateTo(view.lastFragment);
            return;
        }

        route = this.getRouteForPerformedActionFromLinkedPaged(view, route);

        if (!route) {
            if (typeof view.getListUrl === 'function') {
                route = view.getListUrl();
            } else if (typeof view.getReturnRoute === 'function') {
                route = view.getReturnRoute();
            }
        }

        if (!route) {
            window.history.back();
            return;
        }

        store.unset('current-workspace-route');

        if (!noScrollY && store.get('current-workspace-scrollY')) {
            store.set('use-current-workspace-scrollY', 'Y');
            // save current workspace route
            store.set('last-workspace-route', route);
        }
        view.navigateTo(route);
    },

    /**
     * The original-workspace-route may contain a return route that had been lost when the
     * user navigated between detail pages using on-screen links.
     * @param {Object} view - Relevant view
     * @return {String} - return route or empty string if none is found in the cache
     */
    getOriginalContextRoute(view) {
        let originalContextRoute;
        const model = view.storedModel || view.model;

        if (model) {
            const storeName = `${model.get('id')}-original-workspace-route`;
            originalContextRoute = store.get(storeName);
            store.unset(storeName);
        }
        return originalContextRoute || '';
    },

    /**
     * Get the correct route for when the user has just performed an action from a detail
     * screen that they navigated to from another detail screen, and not a list view.
     * @param {View} view - Relevant view
     * @param {String} currentRoute - The current returnRoute pulled from the cache
     * @return {String} - Either the newly found route or the one that was passed in
     */
    getRouteForPerformedActionFromLinkedPaged(view, currentRoute) {
        let newRoute;
        const isFromDetailScreen = view.tempModel
            ? view.tempModel.isFromDetailScreen : view.isFromDetailScreen;
        const returnLinkAfterAction = view.tempModel
            ? view.tempModel.returnLinkAfterAction : view.returnLinkAfterAction;

        // If we have confirmData then we can assume an action was just performed.
        const confirmData = store.get(`${view.contextKey}-confirms`);

        if (isFromDetailScreen && confirmData) {
            newRoute = returnLinkAfterAction;
        }
        return newRoute || currentRoute;
    },

    getWidgetList(workspaceId) {
        const workspaceModel = this.list.get(workspaceId);
        if (workspaceModel) {
            /*
             * Widgets from the workspaceModel do not contain the same props
             * setup in the original publishedWidgets.add call in the widgets themselves.
             * This will add any options from the publishedWidget to the workspaceModel copy
             */
            const widgetsFromWorkspaceModel = workspaceModel.get('widgets');
            /**
             * NH-164267
             * The widgets attribute from workspace model should provide an object with
             * a jsonData array which contains the widget attributes for each widget
             * in the workspace. But, in some cases it was observed that this
             * jsonData array was nested inside another jsonData object. In the latter case,
             * it looked like the widget attribute from workspace model was itself a
             * widget model that nested a callection of widgets. This behavior is unexpected.
             * This condition was added to fix the jsonData for the latter case.
             */
            if (widgetsFromWorkspaceModel?.jsonData?.jsonData) {
                widgetsFromWorkspaceModel.jsonData = widgetsFromWorkspaceModel.jsonData.jsonData;
            }
            const currentPublishedWidgets = this.publishedWidgets.models;
            widgetsFromWorkspaceModel.models.forEach((widgetModel) => {
                /**
                 * NH-164267
                 * The widgetModel passed here is expected to have the widget
                 * attributes. But in some cases it was observed that the attributes were
                 * nested inside another attributes object. The following code extracts the
                 * attributes from this nested structure, clears the widget model
                 * and sets back the extracted attributes.
                 */
                const nestedAttributes = widgetModel.get('attributes');
                if (nestedAttributes) {
                    const cachedAttributes = { ...nestedAttributes };
                    widgetModel.clear();
                    widgetModel.set(cachedAttributes);
                }
                // need to add any attributes from publishedWidget version to the matching model
                const matchingPublishedWidget = currentPublishedWidgets.find(widget => widget.get('id') === widgetModel.get('widgetName'));
                if (matchingPublishedWidget) {
                    const matchingWidgetProps = matchingPublishedWidget.toJSON();
                    widgetModel.set(matchingWidgetProps);
                }
            });

            return workspaceModel.get('widgets');
        }
        return new WidgetCollection();
    },

    getExternalWidgetList(workspaceId) {
        const workspaceModel = this.list.get(workspaceId);
        if (workspaceModel) {
            return workspaceModel.get('externalWidgets');
        }
        return new WidgetCollection();
    },

    getAllExternal() {
        return util.flatten(this.list.pluck('externalWidgets'));
    },

    getGlobalWidgetList() {
        const col = new Collection();
        col.comparator = 'title';

        const lockedWidgets = this.lockedWidgets || [];
        this.list.forEach((model) => {
            const colId = model.get('id');
            const widgetList = model.get('widgets');
            const externalWidgetList = model.get('externalWidgets');
            const joinedWidgetList = widgetList.models.concat(externalWidgetList.models);

            /*
             * NH-110369. If a widget is locked to a workspace, then said widget should not be
             * availabe in the widget injector dropdown.
             */
            if (!this.hasSetLockedWidgets && configParam.get('ListUnlockedWidgetsOnly') === 'true') {
                joinedWidgetList.forEach((widget) => {
                    const widgetName = widget.get('widgetName');
                    if (widget.get('locked') && !lockedWidgets.includes(widgetName)) {
                        lockedWidgets.push(widgetName);
                    }
                });
            }

            joinedWidgetList.forEach((joinedWidget) => {
                /**
                 * NH-164267
                 * The joinedWidget model passed here is expected to have the widget
                 * attributes. But in some cases it was observed that the attributes were
                 * nested inside another attributes object. The following code extracts the
                 * attributes from this nested structure, clears the widget model
                 * and sets back the extracted attributes.
                 */
                const nestedAttributes = joinedWidget.get('attributes');
                if (nestedAttributes) {
                    const cachedAttributes = { ...nestedAttributes };
                    joinedWidget.clear();
                    joinedWidget.set(cachedAttributes);
                }
                const widgetId = joinedWidget.get('widgetName');
                const widgetDescription = joinedWidget.get('widgetDescription');
                col.add({
                    id: widgetId,
                    title: widgetDescription,
                    collectionId: colId,
                    wid: widgetId,
                    type: widgetId,
                    widget: joinedWidget,
                });
            });
        });

        this.setLockedWidgetList(lockedWidgets);

        if (lockedWidgets.length) {
            col.models = col.models.filter(widget => !lockedWidgets.includes(widget.get('id')));
        }

        return col;
    },

    // Cash the locked widgets to executing duplicate logic
    setLockedWidgetList(lockedWidgets) {
        this.lockedWidgets = lockedWidgets;
        this.hasSetLockedWidgets = true;
    },
});

export default new WorkspaceHelper();
