import util from '@glu/core/src/util';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import { appBus } from '@glu/core';
import { ICONS_DATA } from '@glu/icons';
import $ from 'jquery';
import userInfo from 'etc/userInfo';
import mobileUtil from 'mobile/util/mobileUtil';
import ChangePassword from 'system/password/views/change';
import systemConfig from 'system/configuration';
import navigationUtil from 'system/navigation/util';
import SuperMenuView from 'system/gluOverride/menu/menuView';
import dynamicLinkConfig from 'system/dynamiclinks/configuration';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import menuTemplate from './menu.hbs';


// TODO - NH-167065 - remove when glu icons contains this new icon and update the name accordingly.
ICONS_DATA.autobooks = '<svg viewBox="0 0 16 16"><path d="M12.5 4.9V4.5c0-.3-.1-.6-.4-.8L8.9.3C8.6.1 8.3 0 8 0H1C.7 0 .5.2.5.5v15C.5 15.8.7 16 1 16h11c.3 0 .5-.2.5-.5v-1.4C15 13 16.2 10 15.1 7.5C14.6 6.4 13.7 5.4 12.5 4.9zM11.5 15h-10V1H8l.2.1 3.3 3.3c0 0 .1.1.1.2v.1c-.3-.1-.7-.1-1-.1C9.8 4.5 9 4.7 8.4 5H3C2.7 5 2.5 5.2 2.5 5.5S2.7 6 3 6h3.9C6.6 6.3 6.4 6.6 6.2 7H3C2.7 7 2.5 7.2 2.5 7.5S2.7 8 3 8h2.8C5.7 8.3 5.6 8.7 5.6 9H3C2.7 9 2.5 9.2 2.5 9.5S2.7 10 3 10h2.6c.3 2.6 2.4 4.5 5 4.5c.3 0 .7 0 1-.1L11.5 15zM10.5 13.5c-2.2 0-4-1.8-4-4s1.8-4 4-4s4 1.8 4 4S12.7 13.5 10.5 13.5zM11.4 10.7c0-.2-.1-.4-.2-.5c-.1-.1-.3-.2-.6-.3c-.3-.1-.5-.2-.7-.3C9.7 9.5 9.6 9.3 9.4 9.2C9.3 9.1 9.2 8.9 9.1 8.7S9 8.3 9 8.1C9 7.7 9.1 7.3 9.4 7s.6-.4 1.1-.5V5.7h.6v.8c.5.1.8.3 1.1.6c.3.3.4.7.4 1.2h-1.1c0-.3-.1-.5-.2-.7c-.1-.2-.3-.2-.5-.2c-.2 0-.4.1-.5.2s-.2.3-.2.5c0 .2.1.3.2.5c.1.1.3.2.6.4c.3.1.6.2.7.3c.2.1.4.2.5.4s.2.3.3.5c.1.2.1.4.1.6c0 .4-.1.8-.4 1.1c-.3.3-.6.4-1.1.5V13h-.6v-.8c-.5-.1-.9-.2-1.2-.6c-.3-.3-.4-.7-.4-1.3h1.1c0 .3.1.5.2.7c.1.2.4.2.6.2c.2 0 .4-.1.5-.2C11.3 11.1 11.4 10.9 11.4 10.7z"/></svg>';

export default SuperMenuView.extend({
    context: {},
    dynamicLinks: [],
    showPasswordChange: false,

    /*
     * TODO: What is the reason for overriding the default menu template?
     *  Is this something we can promote back into glu?
     */
    template: menuTemplate,

    ui: {
        ...SuperMenuView.prototype.ui,
        $aria: '[data-hook="nav-aria-live"]',
        $menuToggle: '[data-qa="glu-menu-toggle-btn"]',
    },

    events: util.extend(
        {},
        SuperMenuView.prototype.events,
        {
            /*
             * Add the 'menuItemClicked' event to the events defined
             * on the base MenuView class (called SuperMenuView)
             */
            'click [class^="item-"]': 'menuItemClicked',

            'click [class^="item-mobile-"]': 'mobileMenuItemClicked',
            'click [data-hook="getNativeVoiceButton"]': 'activateVoiceInteraction',
        },
    ),

    initialize() {
        this.isinitialRender = true;
        /*
         * If the model was loaded from a direct url, it is not necessary
         * to wait for the model to change; it is there.  Determine by checking the
         * attributes property is in the model.
         */
        if ('attributes' in this.model) {
            this.setContext.apply(this, [this.model.get('modules')]);
            // setup dynamic link routes
            dynamicLinkConfig.setupDynamicLinks(this.model);
            // create array of dynamic link data
            if (this.model.get('modules')) {
                this.dynamicLinks = this.createDynamicLinksArray(this.model.get('modules')[0].components);
            }

            if (this.userMenuContainsPasswordChange(this.model)) {
                this.showPasswordChange = true;
            }
        }

        this.model.on('change', function (firstArg) {
            this.setContext.apply(this, [firstArg.attributes.modules]);
            this.render(this);
        }, this);

        $(window).resize(this.handleResize);
        this.listenTo(appBus, 'menu:toggle', () => {
            if (this.ui.$navbar.hasClass('open')) {
                this.menuToggle();
            }
        });
        this.listenTo(appBus, 'router:navigate', this.pbNavToggle.bind(this, false));
    },

    /**
     * - check if the user menu model contains a change password object
     * @method userMenuContainsPasswordChange
     * @param {Object} menuModel - a Backbone model containing all menu objects
     * @returns the password change object, or false
     */
    userMenuContainsPasswordChange(menuModel) {
        const modules = menuModel.get('modules');
        let userMenu = [];
        let containsPasswordChange = false;

        if (!modules || modules.length === 0) {
            return containsPasswordChange;
        }

        userMenu = util.find(modules[0].components, component => component.key === 'USERMENU' || component.key === 'SMBUSERMENU');

        if (!userMenu) {
            return containsPasswordChange;
        }

        containsPasswordChange = util.find(userMenu.actions, (action) => {
            if (action.displayOrder > -1) {
                return (action.menuCode === '_ADMIN_PWDCH_TM');
            }
            return false;
        });

        return containsPasswordChange;
    },


    /**
     * this event is to give the flyout menu a chance to stop the href using
     * e.stopPropagation();
     * the flyout menu listens for this trigger restricts to SMB, non-mobile users
     * so we don't inadvertently trigger clicks for mobile users
     * @method menuItemClicked
     * @param {event} e - the click event of the element that was clicked
     */
    menuItemClicked(e) {
        if (this.access.isTouch()) {
            return;
        }

        if ($('body.smb-usergroup').length) {
            this.appBus.trigger('menuHandleClick', e);
        }
    },

    /**
     * - triggered when a mobile user clicks a nav item
     * @method mobileMenuItemClicked
     * @param {event} e - the click event of the element that was clicked
     * @returns false
     */
    mobileMenuItemClicked(e) {
        const target = $(e.currentTarget);
        const menuItemTitle = $.trim(target.text());

        this.appBus.trigger(
            'menuItem:tapped',
            {
                menuItemTitle,
            },
        );

        return false;
    },

    addAriaContainer() {
        const $aria = $('<span class="sr-only" role="alert" data-hook="nav-aria-live" aria-live="polite" aria-atomic="true" aria-relevant="additions"></span>');
        this.$el.append($aria);
        this.$aria = $aria;
        return $aria;
    },

    getAriaContainer() {
        if (this.ui.$aria.length !== 0 && typeof this.ui.$aria !== 'string') {
            return this.ui.$aria;
        }
        return this.$aria || this.addAriaContainer();
    },

    notifyShowPage(title) {
        const $aria = this.getAriaContainer();

        let message = '';
        if (title) {
            message = locale.get('accessibility.pageChangeWithTitle', title);
            if (message.startsWith('??')) {
                message = `Page Navigation to ${title}.`;
            }
        } else {
            message = locale.getDefault('accessibility.pageChange', 'Page Navigation');
        }

        // Clear the text immediately.
        $aria.text('');
        this.appBus.once('router:navigate', () => {
            $aria.text(message);
            $('#main').focus();
            // Focus but also scrollTop...we're changing pages
            $('html, body').animate({
                scrollTop: 0,
            }, 100);

            return true;
        });
    },

    /**
     * To trap the focus in the sidebar for mobile view
     */
    setUpFocusTrap() {
        const focusableInNav = $('#top .mobile-trap-focus');
        const $firstFocusableElement = focusableInNav[0];
        const $lastFocusableElement = focusableInNav.last();
        /**
         * Move the focus to menu Button when Escape is clicked
         */
        focusableInNav.on('keydown', (e) => {
            if (e.key === 'Escape') {
                this.ui.$menuToggle.focus();
                this.menuToggle();
            }
        });
        /**
         * Dont allow the focus to move out of the sidebar and
         * return the focus to the first element/first nav
         */
        $lastFocusableElement.on('keydown', (e) => {
            if (e.key === 'Tab' && !e.shiftKey) {
                e.preventDefault();

                setTimeout(() => {
                    $firstFocusableElement.focus();
                }, 0);
            }
        });
    },

    /**
     * @param {boolean} [toggleState]
     */
    pbNavToggle(toggleState) {
        if (this.isinitialRender) {
            this.setUpFocusTrap();
            this.isinitialRender = false;
        }
        const $body = $('body');
        $body.toggleClass('glu-menu-open', toggleState);
        if (typeof this.ui.$navbar === 'object') {
            this.ui.$navbar.find('button').attr('aria-expanded', `${this.ui.$navbar.hasClass('open')}`);
        }

        const focusableInNav = $('#top .mobile-trap-focus');
        const $firstFocusableElement = focusableInNav[0];
        if (!$body.hasClass('glu-menu-open')) {
            // make sure the flyout closes when the menu does
            $body.removeClass('glu-flyout-menu-open');
            if (typeof this.ui.$hasDD?.removeClass === 'function') {
                this.ui.$hasDD.removeClass('dd-open');
            }
            if (typeof this.ui.$dropdowns?.attr === 'function') {
                this.ui.$dropdowns.attr('aria-hidden', 'true');
                this.ui.$parents.addClass('hideMenuItems');
            }
            if (typeof this.ui.$all?.removeClass === 'function') {
                this.ui.$all.removeClass('menu-focus menu-hover');
            }
        } else {
            this.ui.$parents.removeClass('hideMenuItems');
            /**
             * Focus the first Nav when we click on the Menu Button
             */
            $firstFocusableElement.focus();
        }
    },

    appEvents: {
        pbNavToggle: 'pbNavToggle',
        notifyShowPage: 'notifyShowPage',
    },

    setContext(menus) {
        util.each(menus, function (menu) {
            util.each(menu.components, function (component) {
                util.each(component.actions, function (action) {
                    const key = action.menuCode;
                    if (key) {
                        const data = {};
                        Object.keys(action || {}).forEach((field) => {
                            if (field === 'menuCode') {
                                return;
                            }
                            data[field === 'key' ? 'serviceName' : field] = action[field];
                        });
                        this.context[key] = data;
                    }
                }, this);
            }, this);
        }, this);
    },

    changePassword() {
        dialog.open(new ChangePassword());
    },

    clientHelp() {
        navigationUtil.clientHelp();
    },

    /**
     * - create an array of only dynamic links to expose to the template
     * @method createDynamicLinksArray
     * @param {Array} components - Should contain the usermenu component
     * @returns [Array] dynamicLinksArray
     */
    createDynamicLinksArray(components) {
        let dynamicLinksArray = [];

        const userMenu = util.find(components, c => c.menuCategory === 'usermenu' || c.menuCategory === 'smbusermenu');

        if (userMenu) {
            dynamicLinksArray = util.filter(userMenu.actions, m => m.productCode === 'DYNLINK');

            dynamicLinksArray = dynamicLinksArray.map((link) => {
                const linkObj = link;
                linkObj.menuCategoryUC = linkObj.menuCatergory.toUpperCase();
                return linkObj;
            });
        }

        return dynamicLinksArray;
    },

    templateHelpers() {
        return {
            username: userInfo.get('name'),
            hasTopNavigation: userInfo.hasTopNavigation(),
            hasLeftNavigation: userInfo.hasLeftNavigation(),
            appRoot: systemConfig.appRoot,
            dynamicLinks: this.dynamicLinks,
            hasChangePassword: this.showPasswordChange,
            showHelp: serverConfigParams.get('hideHelp') === 'false',

            fetchMenuIcon(menuCode) {
                switch (menuCode) {
                case 'WORKSPACE':
                    return 'home';
                case 'SMB_PAY_LIST':
                    return 'budget';
                case 'SMB_TRANSFER':
                    return 'cases';
                case 'SMB_CONTACT':
                    return 'address-book';
                case 'SMB_EMPLOYEE':
                    return 'multiple-person';
                case 'CM_FRAUD_CTRL':
                    return 'shield-dollar';
                case 'SMB_ADMIN':
                    return 'wrench';
                case 'BOS_CASHFLOW':
                    return 'reporting';
                case 'BOS_BILLMGMT':
                    return 'invoicing';
                case 'RDC_BASIC':
                    return 'smart-phone';
                case 'RDCHISTORY_BASIC':
                    return 'check-payment';
                case 'AUTOBOOKS':
                    return 'autobooks';
                case 'internalLinkReplace/CLIENT_BBD':
                    return 'caret-left';
                default:
                }
                return undefined;
            },

            sanitizeKey(key) {
                /*
                 * replace all forward slash characters with an underscore character so we
                 * can correction
                 * build css classes and id's from urls with multiple slash characters.
                 * ie. when passing params
                 */
                if (key) {
                    // replace all forward and back slash with underscore
                    return key.replace(/[/\\\\]/g, '_');
                }
                return undefined;
            },

            displayOrderIsVisible() {
                return util.isUndefined(this.displayOrder) || (this.displayOrder > -1);
            },

            addGroupName() {
                return this.skipGroupNameInUrl === null || this.skipGroupNameInUrl === 0;
            },

            isNativeApp: mobileUtil.isNativeApp(),

            /**
             * Handles menu items that return null from service but require icon anyway
             * @param {string} key - Key from handlebars template
             * @param {Object} options - options object passed by Handlebars
             * @returns {boolean} - invocation of Handlebars helper as "if"
             */
            hasGenericIcon(key, options) {
                const genericIconKeys = ['internalLinkReplace/CLIENT_BBD'];
                return (genericIconKeys.indexOf(key) !== -1)
                    ? options.fn(this) : options.inverse(this);
            },
        };
    },

    /**
     * Trigger an event on the application bus that the native app
     * is listening to, for launching voice interactions
     */
    activateVoiceInteraction() {
        this.appBus.trigger('activate:voice:interaction');
    },
});
