export default {

    getBlob(imageSrc) {
        // atob to base64_decode
        const imageData = window.atob(imageSrc);

        // Use typed arrays to convert the binary data to a Blob
        const arraybuffer = new ArrayBuffer(imageData.length);

        const view = new Uint8Array(arraybuffer);
        let blob;
        for (let i = 0; i < imageData.length; i += 1) {
            // eslint-disable-next-line
            view[i] = imageData.charCodeAt(i) & 0xff;
        }
        try {
            blob = new window.Blob([arraybuffer], {
                type: 'application/octet-stream',
            });
        } catch (e) {
            const FnToUse = window.WebKitBlobBuilder || window.MozBlobBuilder;
            const bb = new FnToUse();
            bb.append(arraybuffer);
            blob = bb.getBlob('application/octet-stream');
        }

        return blob;
    },

};
