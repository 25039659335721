var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <button class=\"btn btn-tertiary NativeVoiceIcon-button hide\"\n                data-hook=\"getNativeVoiceButton\">\n            <svg class=\"NativeVoiceIcon-svg\" viewBox=\"0 0 10.3 16\">\n                <path d=\"M5.1 10.8c-1.5 0-2.7-1.2-2.7-2.7V2.7C2.5 1.2 3.7 0 5.1 0c1.5 0 2.7 1.2 2.7 2.7v5.4c0 1.4-1.2 2.6-2.7 2.7zm5.2-2.3h-1c-.2 2.3-2.3 4-4.6 3.7C2.8 12 1.2 10.5 1 8.5H0C.2 11 2.2 13 4.6 13.2V15H3.1v1h4.2v-1H5.6v-1.8c2.5-.2 4.5-2.2 4.7-4.7z\">\n                </path>\n            </svg>\n        </button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li role=\"none\">\n                        <a class=\"item-changePassword\" href=\"#\" data-action=\"changePassword\" role=\"menuitem\" aria-controls=\"main\" tabindex=\"-1\">\n                            "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"function.ChangePwd",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":28},"end":{"line":29,"column":59}}}))
    + "\n                        </a>\n                    </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li role=\"none\">\n                        <a aria-controls=\"main\"\n                            class=\"item-"
    + alias3((((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"sanitizeKey"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"key") : depth0),{"name":"@root/sanitizeKey","hash":{},"data":data,"loc":{"start":{"line":36,"column":40},"end":{"line":36,"column":65}}}))
    + "\"\n                            href=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"appRoot") || (depth0 != null ? lookupProperty(depth0,"appRoot") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"appRoot","hash":{},"data":data,"loc":{"start":{"line":37,"column":34},"end":{"line":37,"column":45}}}) : helper)))
    + "/"
    + alias3(((helper = (helper = lookupProperty(helpers,"menuCategoryUC") || (depth0 != null ? lookupProperty(depth0,"menuCategoryUC") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"menuCategoryUC","hash":{},"data":data,"loc":{"start":{"line":37,"column":46},"end":{"line":37,"column":64}}}) : helper)))
    + "/"
    + alias3(((helper = (helper = lookupProperty(helpers,"requestMappings") || (depth0 != null ? lookupProperty(depth0,"requestMappings") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"requestMappings","hash":{},"data":data,"loc":{"start":{"line":37,"column":65},"end":{"line":37,"column":84}}}) : helper)))
    + "\"\n                            role=\"menuitem\"\n                            tabindex=\"-1\">\n                            "
    + alias3(((helper = (helper = lookupProperty(helpers,"menuDescription") || (depth0 != null ? lookupProperty(depth0,"menuDescription") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"menuDescription","hash":{},"data":data,"loc":{"start":{"line":40,"column":28},"end":{"line":40,"column":47}}}) : helper)))
    + "\n                        </a>\n                    </li>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"modules") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":12},"end":{"line":101,"column":21}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"components") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":16},"end":{"line":100,"column":25}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"displayOrderIsVisible")),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":20},"end":{"line":99,"column":27}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"parent hideMenuItems\" role=\"none\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"actions") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(23, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":62,"column":28},"end":{"line":97,"column":35}}})) != null ? stack1 : "")
    + "                        </li>\n";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <a id=\"gmp-"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (depth0 != null ? lookupProperty(depth0,"key") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":65,"column":43},"end":{"line":65,"column":50}}}) : helper)))
    + "\" class=\"item-"
    + alias4((((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"sanitizeKey"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"key") : depth0),{"name":"@root/sanitizeKey","hash":{},"data":data,"loc":{"start":{"line":65,"column":64},"end":{"line":65,"column":89}}}))
    + " has-dd mobile-trap-focus\" role=\"menuitem\" aria-haspopup=\"true\" href onclick=\"return false;\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"menuIcon") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":66,"column":32},"end":{"line":70,"column":39}}})) != null ? stack1 : "")
    + "                                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":71,"column":36},"end":{"line":71,"column":44}}}) : helper)))
    + "\n                                    <span class=\"caret\" role=\"presentation\"></span>\n                                </a>\n                                <ul class=\"dropdown\" role=\"menu\" aria-hidden=\"true\" aria-labelledby=\"gmp-"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (depth0 != null ? lookupProperty(depth0,"key") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":74,"column":105},"end":{"line":74,"column":112}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"actions") : depth0),{"name":"each","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":36},"end":{"line":87,"column":45}}})) != null ? stack1 : "")
    + "                                </ul>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    "
    + container.escapeExpression(lookupProperty(helpers,"icon").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{"iconsType":"svg","name":(depth0 != null ? lookupProperty(depth0,"menuIcon") : depth0)},"data":data,"loc":{"start":{"line":67,"column":36},"end":{"line":67,"column":74}}}))
    + "\n";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    "
    + container.escapeExpression(lookupProperty(helpers,"icon").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{"iconsType":"svg","name":(depth0 != null ? lookupProperty(depth0,"menuCategory") : depth0)},"data":data,"loc":{"start":{"line":69,"column":36},"end":{"line":69,"column":78}}}))
    + "\n";
},"16":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"displayOrderIsVisible")),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":40},"end":{"line":86,"column":47}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <li role=\"none\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"key") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.program(21, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":78,"column":48},"end":{"line":84,"column":55}}})) != null ? stack1 : "")
    + "                                            </li>\n";
},"18":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                    <a class=\"item-"
    + alias3((((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"sanitizeKey"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"key") : depth0),{"name":"@root/sanitizeKey","hash":{},"data":data,"loc":{"start":{"line":79,"column":67},"end":{"line":79,"column":92}}}))
    + "\" href=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"appRoot") || (depth0 != null ? lookupProperty(depth0,"appRoot") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"appRoot","hash":{},"data":data,"loc":{"start":{"line":79,"column":100},"end":{"line":79,"column":111}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"addGroupName")),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":111},"end":{"line":79,"column":155}}})) != null ? stack1 : "")
    + "/"
    + alias3(((helper = (helper = lookupProperty(helpers,"key") || (depth0 != null ? lookupProperty(depth0,"key") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":79,"column":156},"end":{"line":79,"column":163}}}) : helper)))
    + "\" role=\"menuitem\" aria-controls=\"main\">\n                                                        "
    + alias3(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":80,"column":56},"end":{"line":80,"column":64}}}) : helper)))
    + "\n                                                    </a>\n";
},"19":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "/"
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"key") : depths[1]), depth0));
},"21":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                    <span class=\"item-separator\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":83,"column":81},"end":{"line":83,"column":89}}}) : helper)))
    + "</span>\n";
},"23":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <a id=\"gmp-"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (depth0 != null ? lookupProperty(depth0,"key") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":90,"column":43},"end":{"line":90,"column":50}}}) : helper)))
    + "\" class=\"item-"
    + alias4((((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"sanitizeKey"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"key") : depth0),{"name":"@root/sanitizeKey","hash":{},"data":data,"loc":{"start":{"line":90,"column":64},"end":{"line":90,"column":89}}}))
    + "\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"appRoot") || (depth0 != null ? lookupProperty(depth0,"appRoot") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"appRoot","hash":{},"data":data,"loc":{"start":{"line":90,"column":97},"end":{"line":90,"column":108}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (depth0 != null ? lookupProperty(depth0,"key") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":90,"column":109},"end":{"line":90,"column":116}}}) : helper)))
    + "\" role=\"menuitem\" aria-controls=\"main\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(container.data(data, 2) && lookupProperty(container.data(data, 2),"first")),{"name":"if","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":155},"end":{"line":90,"column":194}}})) != null ? stack1 : "")
    + ">\n                                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"menuIcon") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":36},"end":{"line":91,"column":97}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"hasGenericIcon"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"key") : depth0),{"name":"@root/hasGenericIcon","hash":{},"fn":container.program(28, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":92,"column":36},"end":{"line":94,"column":61}}})) != null ? stack1 : "")
    + "                                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":95,"column":36},"end":{"line":95,"column":44}}}) : helper)))
    + "\n                                </a>\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "tabindex=\"0\"";
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"icon").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{"iconsType":"svg","name":(depth0 != null ? lookupProperty(depth0,"menuIcon") : depth0)},"data":data,"loc":{"start":{"line":91,"column":52},"end":{"line":91,"column":90}}}));
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <span class=\"icon-"
    + container.escapeExpression((((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"fetchMenuIcon"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"key") : depth0),{"name":"@root/fetchMenuIcon","hash":{},"data":data,"loc":{"start":{"line":93,"column":58},"end":{"line":93,"column":85}}}))
    + "\" role=\"presentation\"></span>\n";
},"30":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"modules") : depth0),{"name":"each","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":12},"end":{"line":127,"column":21}}})) != null ? stack1 : "")
    + "\n";
},"31":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"components") : depth0),{"name":"each","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":106,"column":16},"end":{"line":126,"column":25}}})) != null ? stack1 : "");
},"32":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"displayOrderIsVisible")),{"name":"if","hash":{},"fn":container.program(33, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":107,"column":20},"end":{"line":125,"column":27}}})) != null ? stack1 : "");
},"33":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"parent hideMenuItems\" role=\"none\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"actions") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0, blockParams, depths),"inverse":container.program(38, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":109,"column":28},"end":{"line":123,"column":35}}})) != null ? stack1 : "")
    + "                        </li>\n";
},"34":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"actions") : depth0),{"name":"each","hash":{},"fn":container.program(35, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":110,"column":32},"end":{"line":117,"column":41}}})) != null ? stack1 : "");
},"35":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"displayOrderIsVisible")),{"name":"if","hash":{},"fn":container.program(36, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":111,"column":32},"end":{"line":116,"column":39}}})) != null ? stack1 : "");
},"36":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <a id=\"gmp-"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (depth0 != null ? lookupProperty(depth0,"key") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":112,"column":47},"end":{"line":112,"column":54}}}) : helper)))
    + "\" class=\"item-"
    + alias4((((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"sanitizeKey"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"key") : depth0),{"name":"@root/sanitizeKey","hash":{},"data":data,"loc":{"start":{"line":112,"column":68},"end":{"line":112,"column":93}}}))
    + " mobile-trap-focus\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"appRoot") || (depth0 != null ? lookupProperty(depth0,"appRoot") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"appRoot","hash":{},"data":data,"loc":{"start":{"line":112,"column":119},"end":{"line":112,"column":130}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (depth0 != null ? lookupProperty(depth0,"key") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":112,"column":131},"end":{"line":112,"column":138}}}) : helper)))
    + "\" role=\"menuitem\" aria-controls=\"main\">\n                                        "
    + alias4(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"iconsType":"svg","name":(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"fetchMenuIcon"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"menuCode") : depth0),{"name":"@root/fetchMenuIcon","hash":{},"data":data,"loc":{"start":{"line":113,"column":52},"end":{"line":113,"column":82}}})},"data":data,"loc":{"start":{"line":113,"column":40},"end":{"line":113,"column":100}}}))
    + "\n                                        "
    + alias4(container.lambda((depths[1] != null ? lookupProperty(depths[1],"name") : depths[1]), depth0))
    + "\n                                    </a>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <a id=\"gmp-"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (depth0 != null ? lookupProperty(depth0,"key") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":119,"column":43},"end":{"line":119,"column":50}}}) : helper)))
    + "\" class=\"item-"
    + alias4((((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"sanitizeKey"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"key") : depth0),{"name":"@root/sanitizeKey","hash":{},"data":data,"loc":{"start":{"line":119,"column":64},"end":{"line":119,"column":89}}}))
    + "\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"appRoot") || (depth0 != null ? lookupProperty(depth0,"appRoot") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"appRoot","hash":{},"data":data,"loc":{"start":{"line":119,"column":97},"end":{"line":119,"column":108}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (depth0 != null ? lookupProperty(depth0,"key") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":119,"column":109},"end":{"line":119,"column":116}}}) : helper)))
    + "\" role=\"menuitem\" aria-controls=\"main\">\n                                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"menuIcon") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":120,"column":36},"end":{"line":120,"column":97}}})) != null ? stack1 : "")
    + "\n                                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":121,"column":36},"end":{"line":121,"column":44}}}) : helper)))
    + "\n                                </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<nav class=\"glu-menu\" aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"menu.menu",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":1,"column":34},"end":{"line":1,"column":56}}}))
    + "\">\n    <span class=\"sr-only\" role=\"alert\" data-hook=\"nav-aria-live\" aria-live=\"polite\" aria-atomic=\"true\" aria-relevant=\"additions\"></span>\n    <div class=\"toggle\" data-action=\"menuToggle\" data-qa=\"glu-menu-toggle-wrap\">\n        <button type=\"button\" aria-expanded=\"false\" class=\"btn btn-tertiary\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"menu.toggle",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":84},"end":{"line":4,"column":108}}}))
    + "\" data-qa=\"glu-menu-toggle-btn\">\n            <span class=\"icon-rows\">&nbsp;</span>\n        </button>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNativeApp") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":17,"column":11}}})) != null ? stack1 : "")
    + "    <ul class=\"top\" id=\"top\" role=\"menubar\" aria-controls=\"main\">\n        <li class=\"parent mobile-user hideMenuItems\" role=\"none\">\n            <span id=\"gmp-USER\" class=\"item-USER has-dd mobile-trap-focus\" role=\"menuitem\" aria-haspopup=\"true\" tabindex=\"0\">\n            <span class=\"icon-person\" role=\"presentation\"></span>\n                "
    + alias2(((helper = (helper = lookupProperty(helpers,"username") || (depth0 != null ? lookupProperty(depth0,"username") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"username","hash":{},"data":data,"loc":{"start":{"line":22,"column":16},"end":{"line":22,"column":28}}}) : helper)))
    + "\n                <span class=\"caret\" role=\"presentation\"></span>\n            </span>\n            <ul class=\"dropdown\" role=\"menu\" aria-hidden=\"true\" aria-labelledby=\"gmp-USER\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasChangePassword") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":16},"end":{"line":32,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"dynamicLinks") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":16},"end":{"line":43,"column":25}}})) != null ? stack1 : "")
    + "                <li role=\"none\">\n                    <a class=\"item-helpSystem item-help\"  href=\"#\" data-action=\"clientHelp\" role=\"menuitem\" aria-controls=\"main\" tabindex=\"-1\">\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"header.help",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":46,"column":24},"end":{"line":46,"column":48}}}))
    + "\n                    </a>\n                </li>\n                <li role=\"none\">\n                    <a class=\"item-logoffItem\" href=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"appRoot") || (depth0 != null ? lookupProperty(depth0,"appRoot") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"appRoot","hash":{},"data":data,"loc":{"start":{"line":50,"column":53},"end":{"line":50,"column":64}}}) : helper)))
    + "/logout\" role=\"menuitem\" aria-controls=\"main\" tabindex=\"-1\">\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"header.logoff",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":51,"column":24},"end":{"line":51,"column":50}}}))
    + "\n                    </a>\n                </li>\n            </ul>\n        </li>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasTopNavigation") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":8},"end":{"line":102,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasLeftNavigation") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":103,"column":8},"end":{"line":129,"column":15}}})) != null ? stack1 : "")
    + "    </ul>\n</nav>\n";
},"useData":true,"useDepths":true});