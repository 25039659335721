import React from 'react';
import locale from '@glu/locale';
import ContentLoading from 'pcm/common/components/ContentLoading';
import EntitlementProvider from 'pcm/common/entitlementContext/EntitlementProvider';
import store from 'pcm/common/store';
import { outsourcePrintEntitlements } from './outsourcePrintProfileUtils';


const OutsourcePrintProfile = React.lazy(() => import('./OutsourcePrintProfileList'));
const AddOutsourcePrintProfile = React.lazy(() => import('./AddOutsourcePrintProfile'));
const ViewOutsourcePrintProfile = React.lazy(() => import('./ViewOutsourcePrintProfile'));
const EditOutsourcePrintProfile = React.lazy(() => import('./EditOutsourcePrintProfile'));


const getActionToTitleOptions = () => ({
    title: {
        view: locale.get('outsource.printprofile.view'),
        add: locale.get('outsource.addOutsourcePrintProfile'),
        edit: locale.get('outsource.editOutsourcePrintProfile'),

    },
    backLink: '/ui-admin-portal/ADMINSTRATION/outsourcepp',
});

export default {

    outsourcePrintProfileConfig() {
        this.showPage(
            locale.get('outsource.printprofile.list'),
            <React.Suspense fallback={<ContentLoading />}>
                <EntitlementProvider entitlements={outsourcePrintEntitlements}>
                    <OutsourcePrintProfile />
                </EntitlementProvider>
            </React.Suspense>,
            {
                headlineText: locale.get('outsource.printprofile.list'),
            },
        );
    },

    outsourcePrintProfileActions(action) {
        const components = {
            view: ViewOutsourcePrintProfile,
            add: AddOutsourcePrintProfile,
            edit: EditOutsourcePrintProfile,
        };

        store.action = action;
        store.alerts = {};

        const actionToTitleOptions = getActionToTitleOptions();
        const ComponentToShow = components[action];

        this.showPage(
            actionToTitleOptions.title[action],
            <React.Suspense fallback={<ContentLoading />}>
                <EntitlementProvider entitlements={outsourcePrintEntitlements}>
                    <ComponentToShow action={action} />
                </EntitlementProvider>
            </React.Suspense>,
            {
                headlineText: actionToTitleOptions.title[action],
                backLink: actionToTitleOptions.backLink,
            },
        );
    },


};
