export default ({ breakpoints }) => ({
  root: {
    bottom: 15,
    position: 'fixed',
    right: 15,
    [`@media only screen and (max-width: ${breakpoints.values.sm}px)`]: {
      left: 15,
      right: 15
    }
  }
});
