import Collection from '@glu/core/src/collection';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import PassCodeModel from 'app/administration/models/securityPasscode';
import Constants from 'app/administration/constants';
import services from 'services';

const PassCodeList = Collection.extend({

    model: PassCodeModel,
    initialize() {
        this.data = {
            startRow: 0,
            rowsPerPage: 0,
        };
    },

    save(options) {
        this.sync('update', this, options);
    },

    sync(method, collection, options) {
        let url;
        if (method === 'read') {
            url = services.generateUrl('/userSelfService/OTPDestinations/getListView');
            http.post(url, this.data, (result) => {
                options.success(result);
            }, (result) => {
                options.error(result);
            });
        } else if (method === 'update') {
            const jsonData = [];

            util.each(this.models, (item) => {
                let address = item.get('contact');
                if (item.get('channel') === 'email') {
                    address = item.get('emailAddress');
                }

                jsonData.push({
                    address,
                    default: item.get('default'),
                    channel: item.get('channel'),
                });
            });

            const saveData = {
                destinations: jsonData,
            };
            url = services.generateUrl('/userSelfService/OTPDestinations/saveOPTDestinations');

            http.post(url, saveData, (result) => {
                options.success(result);
            }, (result) => {
                options.error(result);
            });
        }
    },

    parse(response) {
        return util.map(response.rows, (row) => {
            // set correct fields in the model
            if (Constants.PASSCODE_EMAIL_CHANNEL === row.columns[2].fieldValue) {
                return {
                    // set to anything other than -1 for existing records
                    uid: 0,
                    channel: row.columns[2].fieldValue,
                    emailAddress: row.columns[3].fieldValue,
                    verified: true,
                    default: row.columns[4].fieldValue === '1',
                };
            }
            return {
                // set to anything other than -1 for existing records
                uid: 0,
                channel: row.columns[2].fieldValue,
                contact: row.columns[3].fieldValue,
                verified: true,
                default: row.columns[4].fieldValue === '1',
            };
        });
    },

    /*
     * Remove the empty contacts, contacts marked for delete and unverified contacts from
     * the collection for saving
     */
    cleanData() {
        const filtered = this.filter(passCodeModel => passCodeModel.get('removeRecord') === false && (passCodeModel.get('contact') !== '' || passCodeModel.get('emailAddress') !== ''));
        return new PassCodeList(filtered);
    },

    byChannel(channel) {
        const filtered = this.filter(passCodeModel => passCodeModel.get('channel') === channel);
        return new PassCodeList(filtered);
    },

});

export default PassCodeList;
