import React from 'react';
import PropTypes from 'prop-types';
import actionsHelper from './actions_helper';

export default function Menu({
  classes,
  closePopover,
  gridApi,
  gridId,
  rowIndex,
  visible,
  restActions,
  ...props
}) {
  if (!visible) {
    return null;
  }
  const renderActions = (action) => {
    const Action = actionsHelper.getComponent(action);
    const actionProps = actionsHelper.getProps(action, props, closePopover);
    return (
      <Action
        {...(action.component && props)}
        className={classes.dropDownButton}
        key={action.label}

        data-qa={`${gridId}-${action.label}-${rowIndex}`}
        {...actionProps}
      >
        {action.label}
      </Action>
    );
  };
  return (
    <div className={classes.dropDown}>
      {restActions.map(renderActions)}
    </div>
  );
}
Menu.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  /** Provided by @glu/popover-react */
  closePopover: PropTypes.func,
  /** Ag grid grid api */
  gridApi: PropTypes.shape({
    addGlobalListener: PropTypes.func,
    removeGlobalListener: PropTypes.func
  }).isRequired,
  /** String to determine qa attributes */
  gridId: PropTypes.string.isRequired,
  /** Number generated by Ag Grid */
  rowIndex: PropTypes.number.isRequired,
  /** List of available for menu actions after condition check */
  restActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string,
      handler: PropTypes.func,
      condition: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
    })
  ).isRequired,
  /** Provided by @glu/popover-reat */
  visible: PropTypes.bool
};

Menu.defaultProps = {
  closePopover() {},
  visible: false
};
