var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button class=\"btn btn-tertiary\" type=\"button\" data-hook=\"getAddPanelWorkflow\">\n                        <span class=\"icon icon-add-circle\"></span>\n                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"PS.panelApproval.Add.Approval.Workflow",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":24},"end":{"line":12,"column":75}}}))
    + "\n                    </button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\"\n                    class=\"btn btn-secondary approve\"\n                    data-action=\"approve\">\n                    "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"action.approve",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":20},"end":{"line":28,"column":47}}}))
    + "\n                </button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\"\n                    class=\"btn btn-secondary delete\"\n                    data-action=\"delete\">\n                    "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"action.delete",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":35,"column":20},"end":{"line":35,"column":46}}}))
    + "\n                </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Approval.Management",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":80}}}))
    + "</h1>\n</div>\n<div class=\"section section-container\">\n    <div class=\"section-body\">\n        <div data-region=\"alertRegion\"></div>\n        <div class=\"btn-wrapper\">\n            <div class=\"btn-group widget-links\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasInsertEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":14,"column":23}}})) != null ? stack1 : "")
    + "                <button class=\"btn btn-tertiary\" type=\"button\" data-hook=\"getManageApprovers\">\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Manage.Approvers",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":16,"column":66}}}))
    + "\n                </button>\n            </div>\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"gridUtilitySection") || (depth0 != null ? lookupProperty(depth0,"gridUtilitySection") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"gridUtilitySection","hash":{},"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":19,"column":36}}}) : helper))) != null ? stack1 : "")
    + "\n        </div>\n        <div class=\"filter-region\" data-region=\"filterRegion\"></div>\n        <div class=\"grid-region\" data-region=\"gridRegion\"></div>\n        <div id=\"complete\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasApproveEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":30,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasDeleteEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":12},"end":{"line":37,"column":19}}})) != null ? stack1 : "")
    + "\n        </div>\n    </div>\n</div>\n";
},"useData":true});