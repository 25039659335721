import React, {
    useEffect, useState, useReducer, Fragment,
} from 'react';
import PropTypes from 'prop-types';
import { appBus } from '@glu/core';
import { Loading } from '@glu/indicators-react';

import constants from 'common/dynamicPages/api/constants';
import systemConfig from 'system/configuration';

import useRequest from '../../hooks/useRequest';
import RtpUtils from '../../app/payments/views/realTimePayments/utils/conversationUtils';

import StartConversation from '../RtpStartConversation/RtpStartConversation';
import ConversationHeader from '../RtpConversationHeader/RtpConversationHeader';
import OnGoingConversation from '../RtpOnGoingConversation/RtpOnGoingConversation';
import AddToConversation from '../RtpAddToConversation/RtpAddToConversation';
import conversationReducer from './conversationReducer';
import RtpContext from './RtpContext';

const isAdmin = systemConfig.isAdmin();

const propTypes = {
    data: PropTypes.shape({
        INSTRUCTION_ID: PropTypes.string,
        TNUM: PropTypes.string,
        PAYMENT_ID: PropTypes.string,
    }).isRequired,
    showDetailPage: PropTypes.func.isRequired,
    showDetailLink: PropTypes.bool,
    conversationStarted: PropTypes.bool,
    currentTab: PropTypes.string,
};

const defaultProps = {
    currentTab: null,
    showDetailLink: true,
    conversationStarted: false,
};

const DrawerContainer = ({
    data,
    showDetailPage,
    showDetailLink,
    conversationStarted,
    currentTab,
}) => {
    const { post } = useRequest();
    const initialState = {
        conversationItems: [],
        incorrectReasons: [],
        missingReasons: [],
        incorrectCount: 0,
        missingCount: 0,
        responses: [],
    };

    const [state, dispatch] = useReducer(conversationReducer, initialState);
    const [isConversationStarted, setIsConversationStarted] = useState(conversationStarted);
    const [currentList, setCurrentList] = useState(currentTab);
    const [isLoading, setIsLoading] = useState(true);
    const supportPairedMessages = currentTab === 'submitted';
    const [pairedMessages, setPairedMessages] = useState(false);

    /**
     *  since the state is set on the first render and it is not reset, need to manually update
     *  this is similar to getDerivedStateFromPropss
     */
    if (conversationStarted !== isConversationStarted) {
        setIsConversationStarted(conversationStarted);
    }
    if (currentTab !== currentList) {
        setCurrentList(currentTab);
    }

    const [showConversationStarted, setShowConversationStarted] = useState(!isConversationStarted);

    const getRFIReasons = () => {
        const url = '/tableMaintenance/getQueryResults';
        const incorrect = RtpUtils.createReasonRequestObj('I');
        const missing = RtpUtils.createReasonRequestObj('M');

        // hit service and return data for dropdown
        const incorrectReasonsPromise = post(url, incorrect);
        const missingReasonsPromise = post(url, missing);

        return [incorrectReasonsPromise, missingReasonsPromise];
    };

    const makeConversationPromises = (value) => {
        const path = (currentList === 'submitted')
            ? constants.URL_RTP_SUBMITTED_CONVERSATION
            : constants.URL_RTP_RECEIVED_CONVERSATION;
        const url = `${path}/read`;
        const payload = {
            item: [
                {
                    name: 'PAYMENT_ID',
                    value,
                },
            ],
        };
        return post(url, payload);
    };

    const getPlaceholderMessages = (items) => {
        const placeholderMessages = items.filter(i => i.ITEM_TYPE === 'PLACEHOLDER')
            .map(m => ({
                message: '', internalMessage: '', seqno: m.SEQUENCE_NUM, paymentId: m.PAYMENT_ID, noresponse: false,
            }));

        if (currentList === 'submitted') {
            items.forEach((item) => {
                if (item.NOT_DELIVERED) {
                    const noResp = item.ADDITIONAL_INFO === 'No Response';
                    const m = {
                        message: item.ADDITIONAL_INFO,
                        internalMessage: item.ADDITIONAL_INFO,
                        seqno: item.SEQUENCE_NUM,
                        paymentId: item.PAYMENT_ID,
                        noresponse: noResp,
                        CONVITEM_ID: item.CONVITEM_ID,
                    };
                    placeholderMessages.push(m);
                }
            });
        }

        setPairedMessages(placeholderMessages.length > 0);
        return placeholderMessages;
    };
    useEffect(() => {
        let ignore = false;
        const [incorrectReasonsPromise, missingReasonsPromise] = getRFIReasons();

        if (!isConversationStarted) {
            setIsLoading(true);
            Promise.all([incorrectReasonsPromise, missingReasonsPromise])
                .then((results) => {
                    if (!ignore) {
                        const localIncorrectReasons = results[0].queryResponse.QueryData.queryRows;
                        const localMissingReasons = results[1].queryResponse.QueryData.queryRows;
                        dispatch({
                            type: 'firstConversation',
                            incorrectReasons: localIncorrectReasons,
                            missingReasons: localMissingReasons,
                        });
                        setIsLoading(false);
                    }
                })
                .catch(() => {
                    setIsLoading(false);
                });
        } else {
            const id = data.INSTRUCTION_ID || data.TNUM || data.PAYMENT_ID;
            const conversationPromise = makeConversationPromises(id);
            dispatch({
                type: 'resetConversation',
            });
            setIsLoading(true);
            Promise.all([incorrectReasonsPromise, missingReasonsPromise, conversationPromise])
                .then((results) => {
                    if (!ignore) {
                        const localIncorrectReasons = results[0].queryResponse.QueryData.queryRows;
                        const localMissingReasons = results[1].queryResponse.QueryData.queryRows;
                        const conversationItems = RtpUtils.createConversationItems(
                            results[2].grids[0].items, localIncorrectReasons,
                            localMissingReasons,
                        );
                        const responses = getPlaceholderMessages(conversationItems);
                        dispatch({
                            type: 'all',
                            incorrectReasons: localIncorrectReasons,
                            missingReasons: localMissingReasons,
                            conversationItems,
                            responses,
                        });
                        setIsLoading(false);
                    }
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }

        return () => {
            ignore = true;
        };
    }, [currentTab, data.INSTRUCTION_ID, data.TNUM, data.PAYMENT_ID, isConversationStarted]);  // eslint-disable-line

    useEffect(() => {
        let ignore = false;
        const requestUpdateConversation = () => {
            const id = data.INSTRUCTION_ID || data.TNUM || data.PAYMENT_ID;
            const newConvPromise = makeConversationPromises(id);
            dispatch({
                type: 'resetConversation',
            });

            newConvPromise.then((results) => {
                if (!ignore) {
                    const conversationItems = RtpUtils.createConversationItems(
                        results.grids[0].items,
                        state.incorrectReasons,
                        state.missingReasons,
                    );
                    const responses = getPlaceholderMessages(conversationItems);
                    dispatch({
                        type: 'newConversation',
                        conversationItems,
                        responses,
                    });
                    setIsConversationStarted(true);
                    setShowConversationStarted(false);
                    appBus.trigger('rtp:conversationUpdated', currentTab);
                }
            });
        };
        appBus.on('rtp:newMessageSuccess', requestUpdateConversation);

        return (() => {
            appBus.off('rtp:newMessageSuccess', requestUpdateConversation);
            ignore = true;
        });
        /**
         * the dependency list must include these state array
         *  because the requestUpdateConversation handler depends on these values
         */
    }, [state.incorrectReasons, state.missingReasons]);  // eslint-disable-line

    const updateResponse = ({
        message, seqno, paymentId, noresponse, internalMessage,
    }) => {
        const response = {
            message, seqno, paymentId, noresponse, internalMessage,
        };
        dispatch({
            type: 'updateResponse',
            response,
        });
    };

    const resetResponses = () => {
        dispatch({
            type: 'resetResponses',
        });
    };

    return (
        <RtpContext.Provider value={state}>
            {currentTab
                && (
                    <ConversationHeader
                        data={data}
                        showDetailPage={showDetailPage}
                        currentTab={currentTab}
                        showDetailLink={showDetailLink}
                        conversationStarted={conversationStarted}
                    />
                )}
            <Loading isLoading={isLoading}>
                <>
                    {currentTab && !showConversationStarted
                    && (
                        <OnGoingConversation
                            currentTab={currentTab}
                            id={data.INSTRUCTION_ID || data.TNUM || data.PAYMENT_ID}
                            updateResponse={updateResponse}
                            displayPaired={pairedMessages}
                            resetResponses={resetResponses}
                            isAdmin={isAdmin}
                        />
                    )}
                    {currentTab && showConversationStarted
                    && <StartConversation />}
                    {currentTab && !isAdmin && !supportPairedMessages
                    && (
                        <AddToConversation
                            data={data}
                            currentTab={currentTab}
                            isConversationStarted={isConversationStarted}
                            setShowConversationStarted={setShowConversationStarted}
                        />
                    )}
                </>
            </Loading>
        </RtpContext.Provider>
    );
};

DrawerContainer.propTypes = propTypes;
DrawerContainer.defaultProps = defaultProps;

export default DrawerContainer;
