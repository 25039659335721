import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import locale from '@glu/locale';
import { AccessibilityText } from '@glu/utilities-react';
import { withStyles } from '@glu/theming';
import { Dropdown } from '@glu/dropdown-react';
import defaultNumberFilterTypes from './Options';
import { styles } from './NumberFilter.styles';
import '../../themeDefaults';

function NumberFilter({
  className,
  classes,
  createFilterValue,
  dark,
  fieldId,
  filterId,
  htmlId,
  numberFilterTypes,
  onChange,
  parseFilterValue: parse,
  step,
  value
}) {
  const [optionType, setOption] = useState((parse(value) && parse(value).type) || '');
  const [number, setNumber] = useState((parse(value) && parse(value).filter) || '');
  const [start, setStart] = useState((parse(value) && parse(value).start) || '');
  const [end, setEnd] = useState((parse(value) && parse(value).end) || '');

  const clearInputs = () => {
    setNumber('');
    setStart('');
    setEnd('');
  };

  const handleSelectChange = (name, selectValue) => {
    const { id: type } = selectValue[0];
    setOption(type);
    clearInputs();
    onChange(
      fieldId,
      createFilterValue(filterId, fieldId, {
        numberType: type,
        value: type === 'between' ? { end, start } : number
      })
    );
  };

  const handleInputChange = (event) => {
    event.preventDefault();
    setNumber(event.target.value);
    onChange(
      fieldId,
      createFilterValue(filterId, fieldId, {
        numberType: optionType,
        value: optionType === 'between' ? { end, start } : event.target.value
      })
    );
  };

  const handleStartInputChange = (e) => {
    e.preventDefault();
    setStart(e.target.value);
    onChange(
      fieldId,
      createFilterValue(filterId, fieldId, {
        numberType: optionType,
        value: optionType === 'between' ? { end, start: e.target.value } : number
      })
    );
  };

  const handleEndInputChange = (e) => {
    e.preventDefault();
    setEnd(e.target.value);
    onChange(
      fieldId,
      createFilterValue(filterId, fieldId, {
        numberType: optionType,
        value: optionType === 'between' ? { end: e.target.value, start } : number
      })
    );
  };

  const filterTypes = numberFilterTypes || defaultNumberFilterTypes(locale);
  const getOptionFromId = (id) => (
    filterTypes.filter(({ value: optVal }) => optVal === id)
      .map(({ text, value: optVal }) => ({ id: optVal, name: text }))
  );

  useEffect(() => {
    if (value?.filters?.length === 0) {
      clearInputs();
    } else if (value?.filters && (start === '' || end === '' || number === '')) {
      const { filter, type } = value.filters[0].filterData;
      setOption(type);
      if (type === 'between') {
        setEnd(filter.end);
        setStart(filter.start);
      } else {
        setNumber(filter);
      }
    }
  }, [value]);

  return (
    <div className={classNames(className, classes.wrapper)}>
      <Dropdown
        aria-label={locale.get('dataComponents.numberFilterType')}
        className={classes.dropdown}
        dark={dark}
        firstOption={null}
        htmlId={`${htmlId}-select`}
        filterEnabled={false}
        onChange={handleSelectChange}
        preSelectedIds={[optionType]}
        value={optionType ? getOptionFromId(optionType) : null}
        options={filterTypes.map(({ text, value: optVal }) => ({ id: optVal, name: text }))}
        disableInputAPI
      />
      {optionType === 'between'
        ? (
          <div className={classes.rangeWrapper}>
            <div className={classes.rangeInputWrapper}>
              <label htmlFor={`${fieldId}-start`}>
                <AccessibilityText>{locale.get('dataComponents.from')}</AccessibilityText>
              </label>
              <input
                className={classes.input}
                id={`${htmlId}-start`}
                title={locale.get('dataComponents.from')}
                type="number"
                name={`${fieldId}-start`}
                value={start || ''}
                onChange={handleStartInputChange}
                placeholder={locale.get('dataComponents.enterMin')}
              />
            </div>
            <div className={classes.rangeInputWrapper}>
              <label htmlFor={`${fieldId}-end`}>
                <AccessibilityText>{locale.get('dataComponents.to')}</AccessibilityText>
              </label>
              <input
                className={classes.input}
                id={`${htmlId}-end`}
                title={locale.get('dataComponents.to')}
                type="number"
                name={`${fieldId}-end`}
                value={end || ''}
                onChange={handleEndInputChange}
                placeholder={locale.get('dataComponents.enterMax')}
              />
            </div>
          </div>
        )
        : (
          <input
            className={classes.input}
            id={htmlId}
            title={locale.get('dataComponents.numberInput')}
            type="number"
            step={step}
            name={fieldId}
            value={number || ''}
            disabled={!optionType ? 'disabled' : ''}
            onChange={handleInputChange}
          />
        )}
    </div>
  );
}

NumberFilter.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,

  /**
   * Function used to build object to pass to `onChange` function.
   * This is where you would build an object for consumption by
   * another component such at `Data-Lens` or `Ag-Grid`
   * The `createFilterValue` function will receive three props `filterId`,
   * `fieldId`, and `value`. `value` is an object of `{numberType: selectValue, value: inputValue}`
   */
  createFilterValue: PropTypes.func.isRequired,

  dark: PropTypes.bool,

  /**
   * Field the filter will be applied against. This will match the column field, usually.
   */
  fieldId: PropTypes.string.isRequired,

  filterId: PropTypes.string,

  htmlId: PropTypes.string.isRequired,

  numberFilterTypes: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.string
  })),
  /**
   * Callback function that consumes the value created the `createFilterValue` function.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * Function to process the raw value object. The value will be used to populate the input field.
   */
  parseFilterValue: PropTypes.func.isRequired,

  /**
   * Step attribute for input.
   */
  step: PropTypes.string,

  value: PropTypes.shape({
    end: PropTypes.number,
    filters: PropTypes.shape([]),
    start: PropTypes.number
  })
};

NumberFilter.defaultProps = {
  className: '',
  dark: true,
  filterId: 'Number Filter',
  numberFilterTypes: undefined,
  step: '0.1',
  value: undefined
};

export const NumberFilterBase = NumberFilter;
export default withStyles(styles)(NumberFilter);
