import { NestedModel, util } from '@glu/core';
import Scheduler from '@glu/scheduler';
import SimpleEndDate from '@glu/scheduler/src/date/simpleEndDate';
import Recurrence from '@glu/scheduler/src/recurrence/recurrence';
import moment from 'moment';
import StartDate from './date/startDate';
import DueExpires from './dueExpires/dueOnExpiresOn';
import EndByOptions from './ends/endBy';
import template from './scheduler.hbs';


/**
 * Patch the existing scheduler initialize to use a less strict NestedModel check.
 */
Scheduler.prototype.initialize = function initialize(options) {
    this.options = util.defaults(options, {
        model: new NestedModel(),
        readOnly: false,
        startValue: null,
        endValue: null,
        startDate: false,
        simpleEndDate: false,
        minutes: false,
        hourly: false,
        daily: true,
        weekly: false,
        monthly: false,
        yearly: false,
        twoColumns: false,
        showModifyScheduleCheckbox: false,
    });

    this.model = options.model;

    if (options.startValue) {
        this.model.set('starts', options.startValue);
    }
    if (options.endValue) {
        this.model.set('ends', options.endValue);
    }
    // recurrence readOnly comes off of model attributes
    this.model.set('readOnly', options.readOnly);

    if (this.options.showModifyScheduleCheckbox) {
        this.model.set('resetPattern', '0');
    }

    // delayedTrigger is "duck-typing" test for NestedModel
    // This resolves instanceof issues for overrides
    if (!(this.model instanceof NestedModel || this.model.delayedTrigger !== undefined)) {
        throw new Error('Model type should be of Nested Model, use glu/nestedModel!');
    }
    this.isACHAuthRules = this.options.isACHAuthRules;
};

Scheduler.prototype.template = template;

Scheduler.prototype.events = {
    'click [data-hook="getModifyScheduleCheckbox"]': 'handleModifyScheduleSelection',
};

Scheduler.prototype.regions = {
    startDate: '[data-hook="schedulerStartDateRegion"]',
    endDate: '[data-hook="schedulerEndDateRegion"]',
    recurrence: '[data-hook="schedulerRecurrenceRegion"]',
    dueDate: '[data-hook="schedulerDueDateRegion"]',
    endBy: '[data-hook="schedulerEndByRegion"]',
};

Scheduler.prototype.onRender = function onRender() {
    this.recurrence.show(new Recurrence(this.options));

    if (this.options.startDate) {
        this.startDate.show(new StartDate({
            readOnly: this.options.readOnly,
            model: this.model,
            startDate: this.options.startDate,
        }));
    } else {
        this.startDate.close();
    }

    if (this.options.simpleEndDate) {
        this.endDate.show(new SimpleEndDate({
            readOnly: this.options.readOnly,
            model: this.model,
            endDate: this.options.simpleEndDate,
        }));
    } else {
        this.endDate.close();
    }

    if (this.options.ends) {
        this.endBy.show(new EndByOptions({
            readOnly: this.options.readOnly,
            model: this.model,
            endDate: this.options.ends,
            autoUpdateInput: this.options.autoUpdateInput,
            // In ACH auth rules, min date of end date must be the day after tomorrow
            minDate: this.isACHAuthRules && moment(this.model.get('minDate')).add(1, 'days'),
        }));
    } else {
        this.endBy.close();
    }

    if (this.options.dueDate) {
        this.dueDate.show(new DueExpires({
            model: this.model,
        }));
    }
    
    if (this.options.readOnly) {
        this.disableDailyCheckboxes(true);
    } else {
        this.disableDailyCheckboxes(false);
    }
    
    if (this.options.showModifyScheduleCheckbox) {
        this.$('[data-toggle="popover"]').popover();
    }
};

Scheduler.prototype.handleModifyScheduleSelection = function (e) {
    const { currentTarget } = e;
    if (currentTarget.checked) {
        // make sure fields are editable and we have stored a reference
        // to the existing data
        this.options.readOnly = false;
        // recurrence readOnly comes off of model attributes
        this.model.set('readOnly', false);
        this.model.set({
            readOnly: false,
            resetPattern: '1',
        });
        this.modifyScheduleCheckboxChecked = true;
        this.modelRef = this.model.toJSON();
        this.appBus.trigger('scheduler:modifyschedulecheckbox', { readOnly: false });
    } else {
        // mark fields as readonly and restore stored value references
        this.options.readOnly = true;
        this.modifyScheduleCheckboxChecked = false;
        // restore our model to the previous state
        const newModelSet = {
            ...this.modelRef,
            readOnly: true,
            resetPattern: '0',
        };
        this.model.set(newModelSet);
        this.modelRef = undefined;
        this.appBus.trigger('scheduler:modifyschedulecheckbox', { readOnly: true });
    }
    this.render();
};

Scheduler.prototype.disableDailyCheckboxes = function (isDisabled = true) {
    this.$el.find('input[type="checkbox"]:not([id="RESETPATTERN"])').prop('disabled', isDisabled);
};

Scheduler.prototype.templateHelpers = function templateHelpers() {
    return {
        isACHAuthRules: this.isACHAuthRules,
        showModifyScheduleCheckbox: this.options.showModifyScheduleCheckbox,
        isModifyScheduleCheckboxChecked: this.modifyScheduleCheckboxChecked,
    };
};

export default Scheduler;
