import Model from '@glu/core/src/model';
import services from 'services';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';

export default Model.extend({
    initialize(options) {
        this.data = this.getRequest(
            options.inquiryId,
            options.typeCode,
            options.account,
            options.filterParam,
            options.currency,
            options.beneAccount,
            options.subAccountNum,
        );
        this.id = this.account;
        this.options = options;
    },

    getRequest(inquiryId, typeCode, account, filterParam, currency, beneAccount) {
        let localBeneAccount = beneAccount;
        localBeneAccount = localBeneAccount || 'NONE';
        return {
            IncludeMapData: 1,

            queryCriteria: {
                inquiryId,

                customFilters: [{
                    filterName: 'Depends',
                    filterParam: [`${filterParam}_Currency`, currency],
                }, {
                    filterName: 'Depends',
                    filterParam: ['Bene_Account', localBeneAccount],
                }, {
                    filterName: 'Depends',
                    filterParam: ['Debit_Account_Number', localBeneAccount],
                }],

                action: {
                    typeCode,
                    entryMethod: 0,
                    productCode: 'RTGS',
                    actionMode: 'INSERT',
                    functionCode: 'INST',
                },

                queryType: 'Query',
                queryField: 'ACCOUNTFILTER',
                queryValue: account,
            },

            requestHeader: {
                requestId: 0,
                queryPagesize: 0,
                queryOffset: 0,
            },
        };
    },

    sync(method, modelParam, options) {
        const model = modelParam;
        if (method === 'read') {
            http.post(services.inquiryQueryResults, this.data, (response) => {
                options.success(response);
            }, (response) => {
                model.error = JSON.parse(response.responseText);
                options.error(model, {});
            });
        }
    },

    parse(response) {
        if (!util.isUndefined(this.options.subAccountNum) && this.options.subAccountNum !== '') {
            this.options.returnField = (this.options.typeCode) === 'LOANPAY' ? 'BENE_SUBACCOUNT_NUM' : 'DEBIT_SUBACCOUNT_NUM';
            // Match subAccountNum or fall back to first row.
            const returnVal =
                response.queryResponse.QueryData.queryRows.find(row =>
                    row.mapDataList.find(dataRow =>
                        dataRow.toField === this.options.returnField)?.value ===
                    this.options.subAccountNum);
            return returnVal ?? response.queryResponse.QueryData.queryRows[0];
        }
        return response.queryResponse.QueryData.queryRows[0];
    },
});
