import BaseLoanView from 'app/loans/views/baseLoanView';
import locale from '@glu/locale';
import $ from 'jquery';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import Template from 'app/loans/models/template';
import loans from 'app/loans/api/common';
import singleLookupHelper from 'common/dynamicPages/views/mdf/componentHelpers/singleLookup';
import workspaceHelper from 'common/workspaces/api/helper';
import validatorPatterns from 'system/validatorPatterns';
import ScheduleWidget from 'app/loans/views/template/scheduleWidgetHelper';
import PanelWorkflowAssignment from 'common/template/panelWorkflow/panelWorkflowAssignment';
import panelWorkflowAssignmentUtil from 'common/template/panelWorkflow/util';
import scroll from 'common/util/scroll';
import tmpl from './add.hbs';

export default BaseLoanView.extend({
    initialize(optionsParam) {
        const options = optionsParam;
        options.model = new Template();
        this.isTemplate = true;
        BaseLoanView.prototype.initialize.apply(this, [options]);
        this.model.jsonData = options.jsonData;
        this.restrictFlag = options.templateFlag;
        this.model.set({
            Template_Code: options.templateCode,
            Template_Description: options.templateDescription,
            RestrictTemplate_Flag: this.restrictFlag,
        });
        this.contextKey = 'template_listView_corp';
        this.setSelectionForComboBox();
        this.addFieldData();
        this.addContext();
    },

    template: tmpl,

    ui: util.extend(
        {},
        BaseLoanView.prototype.ui,
        {
            $inputGroupAddon: '.input-group-addon',
            $templateCode: '#template-code',
            $templateDescription: '#template-description',
        },
    ),

    regions: {
        alertRegion: '#alertRegion',
        schedRegion: '[data-hook="getSchedPayRegion"]',
        addAnotherPaymentOptionLinkRegion: '.addAnotherPaymentOptionLinkRegion',
    },

    behaviors: {
        ValidationSupplement: {},
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            // clear out helpPage from cache
            store.unset('helpPage');
            this.loadRequiredData(true);
        } else {
            this.ui.$debitAccount.comboBox({
                placeholder: locale.get('loans.account.select'),
                allowClear: true,
                matcher: this.maskedInputMatcher.bind(this, 'debit'),
            }).on('change', $.proxy(this.onDebitAccountChanged, this));

            this.ui.$creditAccount.comboBox({
                placeholder: locale.get('loans.account.select'),
                allowClear: true,
                matcher: this.maskedInputMatcher.bind(this, 'credit'),
            }).on('change', $.proxy(this.onLoanAccountChanged, this));

            this.ui.$inputGroupAddon.hide();
            loans.applyMask(this);
            singleLookupHelper.setupSingleLookups(this);
            this.ui.$templateCode.find('input[name="Template_Code"]')[0].pattern = validatorPatterns.TEMPLATECODE_PATTERN;

            /**
             * set inputmask to number to prevent user from entering illegal
             * characters or multiple decimal points
             */
            this.ui.$amount.inputmask('number');

            /**
             * set up schedule section
             */

            const widgetOptions = {
                state: 'INSERT',
                context: this.model.context,
                model: this.model,
                parentView: this,
            };

            this.scheduleWidgetObj = new ScheduleWidget(widgetOptions);
            this.schedRegion.show(this.scheduleWidgetObj);

            // Only show the Panel Workflow Assignment view when panel approval has been enabled
            if (panelWorkflowAssignmentUtil.panelApprovalEnabled) {
                this.panelWorkflowAssignmentRegion.show(new PanelWorkflowAssignment({
                    model: this.model,
                    collection: this.panelWorkflowCodes,
                }));
            }
            this.displaySummaryTotal(this.isTemplate);
        }
    },

    beforeSubmit() {
        const flag = !this.model.get('RestrictTemplate_Flag') ? 0 : +(this.model.get('RestrictTemplate_Flag'));
        const amount = this.model.get('CREDIT_AMOUNT');
        const enabledOption = this.enabledOptions.at(0);

        const isScheduled = this.model.schedModel || this.model.get('SCHEDULED')
                || (this.parentModel && this.parentModel.get('recur'))
                || this.model.get('recur');

        this.model.set('RestrictTemplate_Flag', flag);

        // the model adequately keeps track if there is only one payment option
        if (this.amountOfPaymentOptions !== 1) {
            /**
             * the model does not adequately keep track of all selected payment options during
             * form interactions. Instead, prior to submitting, they're set one by one below.
             * this function is called here to clear them prior to them being properly set.
             */
            this.clearPaymentDetailsOnModel();

            for (let x = 0; x < this.amountOfPaymentOptions; x += 1) {
                if (!$(`.multifield-container-${x}`).hasClass('hide')) {
                    if (this.typeCode === 'LOANPAY') {
                        let value = (this.amountOfPaymentOptions === 1) ? $('#credit-amount').val() : $(`#amount_${x}`).val();
                        if (util.isNullOrUndefined(value)
                                && this.amountOfPaymentOptions === 1
                                && this.options.preferences.singleOnlyPaymentOptionSupported) {
                            value = amount;
                        }
                        // For templates only, remove validator on field w/blank value
                        if (value.replace(/\s/g, '').length === 0 && !isScheduled) {
                            this.model.removeValidator(`AMOUNT_${x}`);
                        }

                        const option = $(`#selection_${x}`).val();
                        this.model.set({
                            [option]: value,
                            [`AMOUNT_${x}`]: value,
                            [`SELECTION_${x}`]: option,
                        });
                    }
                }
            }
        }
        if ((this.typeCode === 'LOANDRAW' || this.amountOfPaymentOptions === 1)) {
            this.model.removeValidator('AMOUNT_0');
        }
        if (amount.replace(/\s/g, '').length === 0 && !isScheduled) {
            this.model.removeValidator('CREDIT_AMOUNT');
        }

        if (this.typeCode === 'LOANPAY') {
            if (enabledOption.get('onlyOption')) {
                this.model.addValidatorProp(enabledOption.get('key'), 'exists', true, null);
            } else {
                this.model.removeValidatorProp(enabledOption.get('key'), 'exists');
            }
        } else {
            if (this.model.validators.CREDIT_AMOUNT) {
                this.model.addValidatorProp('CREDIT_AMOUNT', 'exists', true, null);
            }
            this.model.removeValidatorProp(enabledOption.get('key'), 'exists');
        }

        if (this.model.get('DEBIT_ACCOUNT_NUMBER') === 'NONE') {
            this.model.unset('DEBIT_ACCOUNT_NUMBER');
        }
        if (this.model.get('BENE_ACCOUNT') === 'NONE') {
            this.model.unset('BENE_ACCOUNT');
        }
    },

    submit() {
        this.beforeSubmit();

        if (!this.model.isValid()) {
            this.model.trigger('invalid');
            this.stopListening(this.model, 'invalid', scroll.scrollToFirstError);

            return;
        }

        this.toggleButtonsEnabled(false);

        const self = this;

        // merge debit and credit attributes into a single object
        const accountAttributes = $.extend(
            null,
            this.accountDetails.debit,
            this.accountDetails.credit,
        );

        // needs to be set after the accountAttributes
        this.model.set({
            DEBIT_ACCOUNT_NUMBER: (this.accountDetails.debit.DEBIT_ACCOUNT_NUMBER || '').trim(),
            DEBIT_SUBACCOUNT_NUM: (this.accountDetails.debit.DEBIT_SUBACCOUNT_NUM || '').trim(),
            BENE_ACCOUNT: (this.accountDetails.credit.BENE_ACCOUNT || '').trim(),
            BENE_SUBACCOUNT_NUM: (this.accountDetails.credit.BENE_SUBACCOUNT_NUM || '').trim(),
        });

        this.model.set(accountAttributes);

        this.model.save(
            {},
            {
                success(model, confirmResponse) {
                    store.set(`${self.contextKey}-alertMessage`, 'INSERT');
                    store.set(`${self.contextKey}-confirms`, confirmResponse);
                    workspaceHelper.returnToCurrentWorkspace(self);
                },

                error(model) {
                    self.toggleButtonsEnabled(true);

                    const alertView = loans.getAlertView(model);

                    $(self.alertRegion.$el).hide();

                    $('html, body').animate({
                        scrollTop: 0,
                    }, 100);

                    self.alertRegion.show(alertView);
                    $(self.alertRegion.$el).fadeIn(200, () => alertView.$el.focus());
                },
            },
        );
    },

    cancel() {
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    templateHelpers() {
        const self = this;
        return {
            debitAccounts: self.getArrayOfAccounts(self.debit.toJSON(), self.model, 'debit'),
            creditAccounts: self.getArrayOfAccounts(self.credit.toJSON(), self.model, 'credit'),

            originLabel() {
                return loans.getOriginLabel(self.typeCode);
            },

            destinationLabel() {
                return loans.getDestinationLabel(self.typeCode);
            },

            paymentOptions() {
                return self.enabledOptions.toJSON();
            },

            getType() {
                return self.title;
            },

            hasMultipleOptions() {
                return (self.typeCode === 'LOANPAY');
            },

            singleOptionOnly() {
                return self.preferences.singleOnlyPaymentOptionSupported;
            },

            getAmountText: locale.get(`loans.${self.typeCode.toLowerCase()}.amount`),
        };
    },
});
