import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import template from './freeFormView.hbs';
import constants from '../constants';
import CharsRemainingInput from './charactersRemainingInput';

const FreeFormView = Layout.extend({
    template,

    onRender() {
        this.subjectInputRegion.show(new CharsRemainingInput({
            model: this.options.model,
            label: locale.get('serviceRequest.Subject'),
            inputType: constants.type.textInput,
            name: 'subject',
            maxLength: 35,
        }));
        this.detailInputRegion.show(new CharsRemainingInput({
            model: this.options.model,
            label: locale.get('serviceRequest.Detail'),
            inputType: constants.type.textArea,
            name: 'detail',
            maxLength: 300,
        }));
    },
});

export default FreeFormView;
