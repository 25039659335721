import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@glu/dropdown-react';
import { useValidation } from '@glu/validation-react';
import {
    Button, PRIMARY, SECONDARY, SMALL,
} from '@glu/buttons-react';
import { asView } from 'common/util/reactUtil';
import { appBus } from '@glu/core';
import { withStyles } from '@glu/theming';
import TextArea from 'components/RtpCharCountTextArea/RtpCharCountTextArea';
import Loader from 'components/Loader/Loader';
import LoadingPage from 'components/LoadingPage/LoadingPage';
import useLocale from 'hooks/useLocale';
import useRequest from 'hooks/useRequest';
import transformUtil from 'common/util/transform';
import { SERVICE_ERROR_CODE } from 'common/dynamicPages/api/constants';
import Constants from './constants';

import styles from './CancelRequestPopup.styles';

const { hashToPairs } = transformUtil;

const propTypes = {
    submitText: PropTypes.string.isRequired,
    cancelText: PropTypes.string.isRequired,
    model: PropTypes.shape({
        get: PropTypes.func,
        set: PropTypes.func,
    }).isRequired,
    // Internal classes hash - do not pass
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const payload = {
    queryCriteria: {
        action: {
            productCode: Constants.productCode,
            functionCode: Constants.functionCode,
            typeCode: Constants.typeCode,
            actionMode: 'SELECT',
        },
        subTypeCode: Constants.subTypeCode,
        fieldName: Constants.cancelReasonCode,
        entryClass: '',
        allowDuplicates: false,
    },
};

const CancelRequestPopup = ({
    submitText, cancelText, model, classes,
}) => {
    const { getLocaleString } = useLocale();
    const { post } = useRequest();

    const [comment, setComment] = useState('');
    const [reason, setReason] = useState('');
    const [options, setOptions] = useState([]);
    const [queryErrorMsg, setQueryErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [Provider, value, { validate }] = useValidation();

    useEffect(() => {
        post(Constants.queryService, payload).then((response) => {
            const reasons = response.queryResponse.QueryData.queryRows.map(item => ({
                id: item.name,
                name: item.label,
            }));
            setOptions(reasons);
            setIsLoading(false);
        })
            .catch((e) => {
                let errMsg = e.statusText;
                if (e.responseJSON && e.responseJSON.queryResponse
                    && e.responseJSON.queryResponse.respHeader) {
                    errMsg = e.responseJSON.queryResponse.respHeader.message.join('');
                }
                setQueryErrorMsg(errMsg);
                setIsLoading(false);
            });
    }, []); // eslint-disable-line

    const onChange = (name, [selected]) => {
        setReason(selected?.id);
    };

    const submitCancelRequest = () => {
        // get the payload
        model.set('ADDITIONAL_INFORMATION', comment);
        model.set('CANCEL_REASON_CODE', reason);

        const formData = {
            ADDITIONAL_INFORMATION: model.get('ADDITIONAL_INFORMATION'),
            CANCEL_REASON_CODE: model.get('CANCEL_REASON_CODE'),
            ENTRYMETHOD: '0',
            FUNCTION: Constants.functionCode,
            PRODUCT: Constants.productCode,
            TYPE: Constants.typeCode,
            TNUM: model.get('TNUM'),
            UPDATECOUNT__: model.get('UPDATECOUNT__'),
        };

        const postData = {
            item: hashToPairs(formData),
        };

        setIsLoading(true);
        post(Constants.saveService, postData).then((response) => {
            setIsLoading(false);
            appBus.trigger('gridActionPopup:submit', response);
        })
            .catch(() => {
                setIsLoading(false);
                const errorInfo = {
                    result: false,
                    confirms: {
                        confirmResults: [{
                            messages: [getLocaleString(Constants.serverError)],
                        }],
                    },
                    errorCode: SERVICE_ERROR_CODE,
                };
                appBus.trigger('gridActionPopup:submit', errorInfo);
            });
    };

    const handleClose = () => {
        appBus.trigger('gridActionPopup:close');
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        validate().then(({ isValid }) => {
            if (isValid) {
                submitCancelRequest();
            }
        });
    };

    return (
        <Loader isLoading={isLoading} LoadingComponent={LoadingPage}>
            <Provider value={value}>
                <form>
                    <div>
                        <Dropdown
                            htmlId="CancelReason"
                            name="CancelReason"
                            labelText={getLocaleString('gridAction.label.cancelReason')}
                            options={options}
                            className={classes.cancelReasons}
                            filterEnabled={false}
                            onChange={onChange}
                            validators={{
                                name: getLocaleString('gridAction.label.cancelReason'),
                                required: true,
                            }}
                        />
                        {queryErrorMsg
                        && <span className={classes.helpBlock}>{queryErrorMsg}</span>}
                    </div>
                    <div>
                        <TextArea
                            name="Comment"
                            rows={4}
                            maxLength={105}
                            message={comment || ''}
                            setMessage={setComment}
                            textAreaClass={classes.charCountTextArea}
                            labelText={getLocaleString('gridAction.label.comment')}
                        />
                    </div>
                    <div>
                        <Button type="button" variant={PRIMARY} size={SMALL} onClick={handleSubmit} text={submitText} className={classes.cancelRequestButton} />
                        <Button type="button" variant={SECONDARY} size={SMALL} onClick={handleClose} text={cancelText} className={classes.cancelRequestButton} />
                    </div>
                </form>
            </Provider>
        </Loader>
    );
};

CancelRequestPopup.propTypes = propTypes;

const StyledCancelRequestPopup = withStyles(styles)(CancelRequestPopup);

/**
 * Provide a utility function for leveraging this in a Backbone context
 * @param {Object} options - Options/props to be sent along to wrapped component
 */
export const createCancelRequestPopupView = options => asView(StyledCancelRequestPopup, options);

export default StyledCancelRequestPopup;
