import util from '@glu/core/src/util';
import locale from '@glu/locale';
import infoTooltipHelper from 'common/dynamicPages/views/mdf/componentHelpers/infoTooltip';
import ListView from 'common/dynamicPages/views/workflow/list';
import workspaceHelper from 'common/workspaces/api/helper';
import store from 'system/utilities/cache';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import constants from './constants';
import template from './achAuthRulesList.hbs';

const achAuthRulesList = ListView.extend({
    className: 'achAuthRulesList',

    template,

    initialize(options) {
        // Dynamic import to resolve circular references in MDF
        this.gridImportPromise = import('common/dynamicPages/api/grid').then(({ default: gridApi }) => {
            this.gridApi = gridApi;
        });
        this.requestParameters = {
            item: [{
                name: 'initialRequest',
                value: 'true',
            }],
        };

        const superOptions = {
            menuCategory: constants.FRAUDMENUCATEGORY,
            menuContext: constants.ACHAUTHRULES_CONTEXT_SERVICENAME,
            serviceName: constants.ACHAUTHRULES_SERVICENAME,
            context: constants.ACHAUTHRULES_CONTEXT,
            returnRoute: constants.ACHAUTHRULES_RETURNROUTE,
            viewId: options.viewId,
            configContext: {
                productCode: constants.ACHAUTHRULES_PRODUCTCODE,
                functionCode: constants.ACHAUTHRULES_FUNCTIONCODE,
                typeCode: constants.ACHAUTHRULES_TYPECODE,
                subType: '*',
                actionMode: 'SELECT',
            },
            requestParameters: this.requestParameters,
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    getButtons(parentHelpers) {
        return Object.entries({
            approve: 'hasApproveEntitlement',
            reject: 'hasRejectEntitlement',
            delete: 'hasDeleteEntitlement',
        }).filter(([, value]) => parentHelpers[value])
            .map(([key]) => ({
                class: key,
                localeString: parentHelpers.getButtonString(key),
            }));
    },

    onRender() {
        infoTooltipHelper.setupInfoTooltips(this);
        ListView.prototype.onRender.call(this);
        if (this.gridView) {
            this.listenTo(this.gridView, 'gridapi:loaded', this.clearGridRequestParams);
            this.listenTo(this.gridView, 'gridapi:loaded:failed', this.clearGridRequestParams);

            const gridViewCid = this.gridView.cid;
            this.listenTo(this.appBus, (`gridapi:showMessages${gridViewCid}`), this.clearGridRequestParams);
            this.listenTo(this.appBus, (`gridapi:showWarningMessage${gridViewCid}`), this.clearGridRequestParams);
            this.listenTo(this.appBus, (`gridapi:showErrorMessages${gridViewCid}`), this.clearGridRequestParams);

            this.startGridRespAlertListeners(this.gridView.cid);
        }
    },

    /**
     * @description refreshes the grid data in response to the refresh icon
     * pass in true to send rowsperpage
     */
    refreshData() {
        this.setGridRequestParams(this.requestParameters);
        ListView.prototype.refreshData.call(this);
    },

    /**
     * override
     * @name populateCustomKeys
     * @description populates the product, function and typecode for the request
     * @param {object} item
     */
    populateCustomKeys(item) {
        util.each(item.item, (currentItemParam) => {
            const currentItem = currentItemParam;
            if (currentItem.name === 'PRODUCTCODE') {
                currentItem.value = 'RISK';
            }
            if (currentItem.name === 'FUNCTIONCODE') {
                currentItem.value = 'INST';
            }
            if (currentItem.name === 'TYPECODE') {
                currentItem.value = 'EBMPPRUL';
            }
        });
        return item;
    },

    insert() {
        this.navigateTo(constants.ACHAUTHRULES_INSERT_ROUTE);
    },

    /**
     * handles select action from the ACH Rules
     * @param {Object} optionsParam - Holds options for the rule being selected
     */
    gridRowSelect(optionsParam) {
        const options = optionsParam;
        options.model.context = this.contextDef;
        store.set('listView-contextKey', this.contextKey);
        store.set(`${this.contextKey}-actionModel`, options.model);

        this.navigateTo(constants.ACHAUTHRULES_VIEW_ROUTE);
        return Promise.resolve();
    },

    /**
     * handles modify action from the ACH Rules
     * @param {Object} optionsParam - Holds options for the rule being selected
     */
    gridRowModify(optionsParam) {
        const options = optionsParam;
        options.model.context = this.contextDef;
        store.set('listView-contextKey', this.contextKey);
        store.set(`${this.contextKey}-actionModel`, options.model);

        this.navigateTo(constants.ACHAUTHRULES_MODIFY_ROUTE);
        return Promise.resolve();
    },

    templateHelpers() {
        const parentHelpers = ListView.prototype.templateHelpers.call(this);
        return {
            ...parentHelpers,
            entitlements: this.getButtons(parentHelpers),
            insertButtonLabel: locale.get('RISK.addNewRule'),
        };
    },
});

let list = achAuthRulesList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        insertActions: [
            {
                label: 'RISK.addNewRule',
                entitlement: 'INSERT',
                handlerMethodName: 'insert',
            },
        ],
        bulkActions: [
            {
                label: 'button.approve',
                entitlement: 'APPROVE',
            },
            {
                label: 'button.unapprove',
                entitlement: 'REJECT',
            },
            {
                label: 'button.delete',
                entitlement: 'DELETE',
            },
        ],
    });
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'POSPAY_RULES_LIST',
    view: list,
    options: {
        title: locale.get('RISK.achAuthRulesTitle'),
    },
});

const exportedList = list;

export default exportedList;
