import Layout from '@glu/core/src/layout';
import Grid from '@glu/grid';
import locale from '@glu/locale';
import constants from 'app/administration/constants';
import dialog from '@glu/dialog';
import ListBuilder from 'common/listBuilder/listBuilder';
import DataEntitlements from 'app/administration/collection/user/dataEntitlements';
import infoTooltipHelper from 'common/dynamicPages/views/mdf/componentHelpers/infoTooltip';
import ListBuilderAssetView from 'app/administration/views/userMaintenance/listBuilderAsset';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import LimitSetCollectionView from './limitSetCollectionView';
import riskGroupViewTmpl from './riskGroupView.hbs';

export default Layout.extend({
    template: riskGroupViewTmpl,
    className: 'risk-group assigned-accounts',
    loadingTemplate: loadingPageTmpl,

    ui: {
        editItemsButton: '.edit-items-button',
        assignedAccountsHeader: '.panel-heading h4',
        accountContainer: '.accounts-div',
        $popovers: '[data-toggle="popover"]',
        $selectAll: '.select-all-perms',
        $assignAllChk: '[data-hook="assign-all-checkbox"]',
        $gridSection: '[data-hook="grid-section"]',
        $assignAllMsg: '.assign-all-msg',
    },

    events: {
        'click @ui.editItemsButton': 'openEditItemsDialog',
        'click @ui.assignedAccountsHeader': 'togglePanel',
        'click @ui.$selectAll': 'selectAllPerms',
        'click @ui.$assignAllChk': 'changeAssignAll',
    },

    initialize(opts) {
        this.permissions = this.model.get('entitlementsByType');
        this.entity = opts.entity;

        if (opts.mode === constants.MODES.VIEW) {
            this.permissions.reset(this.permissions.filter(perm => perm.isEntitled()));
        }

        this.accountsConfig = this.model.get('simpleDataEntitlements');

        if (this.model.availableAccounts) {
            this.gridColumns = this.model.availableAccounts.columns;
            this.setHasLoadedRequiredData(true);
        }

        this.selectedAccounts = this.accountsConfig.get('dataEntitlements');
        this.model.availableAccounts = this.model.availableAccounts || new DataEntitlements(
            [],
            {
                inquiryId: this.accountsConfig.get('inquiryId'),
                keyCol: this.accountsConfig.get('keyCol'),
                configuredDataType: this.accountsConfig.get('configuredDataType'),
                userGroup: opts.userGroup,
            },
        );

        this.listenTo(this.permissions, 'change', this.showHideAccounts);
    },

    onRender() {
        infoTooltipHelper.setupInfoTooltips(this);
        if (this.hasLoadedRequiredData()) {
            this.permissionsView = new LimitSetCollectionView({
                collection: this.permissions,
                mode: this.options.mode,
            });

            this.permissionsRegion.show(this.permissionsView);

            if (this.options.mode !== constants.MODES.VIEW
                || this.selectedAccounts.length > 0) {
                this.showHideAccounts();

                this.gridView = new Grid({
                    columns: this.gridColumns,
                    collection: this.selectedAccounts,
                    emptyViewText: locale.get('uce.emptyGrid.bankAccounts'),
                });

                this.gridRegion.show(this.gridView);

                const checked = this.accountsConfig.get('assignAll');

                this.ui.$assignAllChk.prop('checked', checked);
                this.ui.$gridSection.toggle(!checked);
                this.ui.$assignAllMsg.toggle(checked);
            }
        } else {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        const self = this;

        this.model.availableAccounts.fetch({
            success(accounts) {
                const toRemove = [];

                accounts.each((rec) => {
                    const selectedRec = self.selectedAccounts.matchRecord(rec, accounts.keyCol);

                    if (selectedRec) {
                        toRemove.push(rec);
                        selectedRec.mergeData(rec.attributes);
                    }
                });

                // Remove the selected accounts that are not entitled anymore
                const accountsNotEntitled = self.selectedAccounts
                    .accountsNotEntitled(self.model.availableAccounts);
                self.selectedAccounts.remove(accountsNotEntitled);

                self.model.availableAccounts.remove(toRemove);
                self.gridColumns = accounts.columns;

                self.setHasLoadedRequiredData(true);
                self.render();
            },
        });
    },

    selectAllPerms(evt) {
        this.permissions.selectAll(evt.target.checked);
        this.permissionsView.render();
        this.showHideAccounts();
    },

    togglePanel() {
        this.gridView.render();
    },

    showHideAccounts() {
        if (this.model.isEntitled()) {
            this.ui.accountContainer.removeClass('hidden');
        } else {
            this.ui.accountContainer.addClass('hidden');
        }
    },

    getButtonLabel() {
        return locale.get(this.selectedAccounts.length > 0 ? 'uce.lookupText.RISK' : 'uce.addAccounts');
    },

    openEditItemsDialog() {
        const self = this;

        dialog.open(new ListBuilder({
            id: `uce-${this.model.id}`,
            className: 'list-builder dgb-list-builder',
            sourceCollection: this.model.availableAccounts,
            targetCollection: this.selectedAccounts,
            labelView: ListBuilderAssetView,

            localeKeys: {
                moveSelectedItems: 'uce.moveSelectedAccounts',
                moveAllItems: 'uce.moveAllAccounts',
                removeSelectedItems: 'uce.removeSelectedAccounts',
                removeAllItems: 'uce.removeAllAccounts',
                filter: 'uce.filterAccounts',
                sourceListHeader: 'uce.nAvailableAccounts',
                targetListHeader: 'uce.nAssignedAccounts',
                title: 'uce.addBankAccounts',
                save: 'uce.addAccounts',
                cancel: 'button.cancel',
            },

            saveHandler() {
                self.ui.editItemsButton.find('.replace-text').text(` ${self.getButtonLabel()}`);
            },
        }));
    },

    changeAssignAll(e) {
        const { checked } = e.target;

        this.accountsConfig.set('assignAll', checked);

        this.ui.$gridSection.toggle(!checked);
        this.ui.$assignAllMsg.toggle(checked);
    },

    templateHelpers() {
        const self = this;
        return {
            cid: this.model.cid,
            readOnly: self.options.mode === constants.MODES.VIEW,
            label: locale.get(`uce.riskGroup.${this.model.get('typeGroupId')}`),
            buttonLabel: self.getButtonLabel(),

            assignAllCheckbox() {
                return locale.get(`role.assignAllCheckbox.${self.model.get('typeGroupId')}`);
            },

            assignAllTooltip() {
                return locale.get(`${self.entity}.assignAllTooltip.${self.model.get('typeGroupId')}`);
            },

            assignAllMsg() {
                return locale.get(`role.assignAllMsg.${self.model.get('typeGroupId')}`);
            },
        };
    },
});
