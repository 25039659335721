import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import adminConstants from 'app/administration/constants';
import pendingChangesCellViewTmpl from './pendingChangesCellView.hbs';

export default ItemView.extend({
    template: pendingChangesCellViewTmpl,

    hasLink() {
        return this.model.get('USERPERMISSIONSETTINGS') === adminConstants.UCE_BASED && !util.isEmpty(this.model.get('PENDINGCHANGES'));
    },

    linkFunc() {
        this.appBus.trigger('user:viewPendingChanges', {
            model: this.model,
        });
    },

    serializeData() {
        return this;
    },
});
