var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"loading-indicator-grid hide\" data-hook=\"spinner\"><span></span></div>\n<div id=\"users-grid\" class=\"grid-region checkGrid\" data-grid-entity=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"entityId") || (depth0 != null ? lookupProperty(depth0,"entityId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"entityId","hash":{},"data":data,"loc":{"start":{"line":2,"column":69},"end":{"line":2,"column":81}}}) : helper)))
    + "\"></div>\n";
},"useData":true});