import Collection from '@glu/core/src/collection';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import services from 'services';
import transform from 'common/util/transform';

export default Collection.extend({
    url: services.generateUrl('/inquiry/getData'),

    parse(response) {
        return response.inquiryResponse.rows.map(row => transform.pairsToHash(row.columns, 'fieldName', 'fieldValue'));
    },

    sync(method, collection, options) {
        if (method !== 'read') {
            throw new Error(`This collection only knows how to read, but it was asked to ${method}.`);
        }
        return http.post(util.result(collection, 'url'), util.result(this, 'requestBody')).then(options.success, options.error);
    },

    // Override requestBody or the three properties below.
    requestBody() {
        return {
            requestHeader: util.result(this.requestHeader),

            inquiryRequest: {
                searchCriteria: {
                    action: util.result(this, 'action'),
                    inquiryId: util.result(this, 'inquiryId'),
                },
            },
        };
    },

    requestHeader: {},
    action: {},
    inquiryId: 0,
});
