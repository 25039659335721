import { useEffect } from 'react';
import PropTypes from 'prop-types';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import dialog from '@glu/dialog';
/*
 * TODO When the printViewModal is rewritten in React evaluate if this component
 * will still be needed. As it is right now, all of functionality for fetching
 * a report is directly part of the printViewModal view. This wrapper of sorts
 * never actually renders markup. It does show a backbone modal based on the show
 * prop and removes the modal listener when this component is unmounted.
 */
const PrintModal = ({
    options,
    show,
    onHide,
}) => {
    useEffect(() => {
        let printModal;
        if (show) {
            dialog.custom(new PrintViewModal(options));
            printModal = dialog.activeModal;
            printModal.once('close', onHide);
        }
        return () => {
            if (printModal) {
                printModal.off('close');
            }
        };
    }, [onHide, options, show]);
    return null;
};

PrintModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    options: PropTypes.shape({
        exportModel: PropTypes.shape({
            expData: PropTypes.string.isRequired,
            viewId: PropTypes.number,
            actionData: PropTypes.shape({
                productCode: PropTypes.string,
            }),
            detailReportId: PropTypes.number,
        }).isRequired,
    }).isRequired,
};

export default PrintModal;
