export default function (form) {
    const formState = form.formView.state;
    const code = form.field('CODE');
    const { model } = form.formView;

    model.set('CODE', model.get('CODE').toUpperCase());

    if (formState === 'modify') {
        code.shouldBeReadOnly(true);
    }
}
