var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"live.maintenance",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":60}}}))
    + "</h1>\n</div>\n\n<div class=\"page\">\n  <div class=\"section-container live-maintenance\">\n\n    <div class=\"row maintenance-controls\">\n\n        <div class=\"start-maintenance row active\" data-hook=\"getStartMaintenanceSection\">\n          <button type=\"button\" class=\"btn btn-primary start-btn col-2\" data-hook=\"startMaintenance\">\n            <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"live.maintenance.start",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":18},"end":{"line":13,"column":53}}}))
    + "</span>\n          </button>\n          <div class=\"col-10\">\n              <span class=\"icon-warning\"></span>\n              <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"live.maintenance.off",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":17,"column":53}}}))
    + "</span>\n          </div>\n        </div>\n\n        <div class=\"stop-maintenance col-12\" data-hook=\"getStopMaintenanceSection\">\n          <button type=\"button\" class=\"btn btn-primary stop-btn\" data-hook=\"stopMaintenance\">\n            <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"live.maintenance.end",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":18},"end":{"line":24,"column":51}}}))
    + "</span>\n          </button>\n          <span class=\"icon-warning\"></span>\n          <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"live.maintenance.active",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":16},"end":{"line":27,"column":52}}}))
    + "</span>\n        </div>\n    </div>\n\n    <div class=\"row\">\n      <div class=\"col-12 alert-region\" data-region=\"alert-region\">\n      </div>\n    </div>\n\n    <form class=\"form-inline language-selection\">\n      <fieldset>\n        <div class=\"row\">\n          <h3 class=\"col-12\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"live.maintenance.language.selection",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":41,"column":29},"end":{"line":41,"column":77}}}))
    + "</h3>\n        </div>\n\n        <div data-region=\"messages-region\" class=\"messages-region\"></div>\n\n        <div class=\"modify-controls-off active\" data-hook=\"getModifyControlsOff\">\n          <div class=\"row\">\n            <button type=\"button\" class=\"btn btn-secondary modify-language-btn\" data-hook=\"getStartModifyMode\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.modify",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":51,"column":111},"end":{"line":51,"column":137}}}))
    + "</button>\n          </div>\n        </div>\n\n        <div class=\"modify-controls-on row\" data-hook=\"getModifyControlsOn\">\n          <div class=\"col-12\">\n            <button type=\"button\" class=\"btn btn-tertiary add-language-btn\" data-hook=\"addLanguage\">\n              <span class=\"icon-add-circle\"></span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"live.maintenance.another.language",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":59,"column":51},"end":{"line":59,"column":97}}}))
    + "\n            </button>\n          </div>\n\n          <div class=\"row messaging-modify col-12\">\n                <button type=\"button\" data-hook=\"saveModels\" class=\"btn btn-secondary update-language-btn\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"update",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":65,"column":107},"end":{"line":65,"column":126}}}))
    + "</button>\n                <button type=\"button\" data-hook=\"endModifyMode\" class=\"btn btn-tertiary cancel-language-btn\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":66,"column":109},"end":{"line":66,"column":128}}}))
    + "</button>\n          </div>\n        </div>\n\n      </fieldset>\n    </form>\n  </div>\n</div>\n";
},"useData":true});