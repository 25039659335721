import cookie from 'system/cookie';
import userInfo from 'etc/userInfo';

export const appendScript = (
    path,
    {
        async = false,
        onLoad = () => {},
    } = {},
) => {
    const script = document.createElement('script');
    script.addEventListener('load', onLoad);
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', path);
    script.setAttribute('async', async);
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script);
};

const getSession = cookieName => (cookieName && cookie.get(cookieName))
    || userInfo.get('token');

export default getSession;
