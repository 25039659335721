import Collection from '@glu/core/src/collection';
import LiveMaintenanceUserMessageModel from 'system/liveMaintenance/models/liveMaintenanceUserMessageModel';
import http from '@glu/core/src/http';
import services from 'services';
import { appBus } from '@glu/core';
import transform from 'common/util/transform';
import util from '@glu/core/src/util';

export default Collection.extend({
    model: LiveMaintenanceUserMessageModel,
    url: services.generateUrl('/liveMaintenance/getMaintenanceMessages'),

    /**
     * Retrieve all languages/messages from server
     */
    sync(method) {
        this.handleCollectionSync(method);
    },

    handleCollectionSync(method) {
        if (method === 'read') {
            this.retrieveApiData('get');
        }
    },

    /**
     * General function to hit apis and retrieve data
     * @param  {string} method API method
     * @return {promise} Data returned from API
     */
    retrieveApiData(method) {
        const self = this;

        http[method](this.url).then((response) => {
            self.handleResponse(response);
        }, () => {
            self.handleError();
        });
    },

    handleResponse(response) {
        // after success loop through each message in result
        const modelArray = util.map(response.lookupData.lookupRows, messageArray => transform.pairsToHash(messageArray.columns, 'fieldName', 'fieldValue'));

        this.addToCollection(modelArray);
    },

    addToCollection(modelArray) {
        // add model array to collection
        this.add(modelArray);

        this.setFirstActive();
    },

    setFirstActive() {
        this.first().set('active', true);
    },

    handleError() {
        appBus.trigger('liveMaintenance.getDataError');
    },
});
