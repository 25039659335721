import Model from '@glu/core/src/model';
import Formatter from 'system/utilities/format';
import moment from 'moment';
import userInfo from 'etc/userInfo';
import services from 'services';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import sbPaymentsApi from 'app/smb/api';
import http from '@glu/core/src/http';
import nhUtil from 'system/utilities/accessors';
import Constants from 'app/smbPayments/constants';

const typeMap = {
    FEDWIRE: {
        deliveryMethod: '(Wire Transfer)',
        routeBase: '/payment/fedwire',
        commentMaxLength: 140,
        commentPattern: sbPaymentsApi.SWIFT_PATTERN,
    },

    BDACHCVP: {
        deliveryMethod: '(Corporate/Vendor Payment)',
        routeBase: '/batch/CorporateVendorPayments',
        commentMaxLength: 50,
        // eslint-disable-next-line
        commentPattern: sbPaymentsApi._NOBADCHARS_PATTERN,
    },

    BDACHCP: {
        deliveryMethod: '(Consumer Payment)',
        routeBase: '/batch/ConsumerPayments',
        commentMaxLength: 50,
        // eslint-disable-next-line
        commentPattern: sbPaymentsApi._NOBADCHARS_PATTERN,
    },

    INTL: {
        deliveryMethod: '(International Wire Transfer)',
        routeBase: '/payment/Wire-International',
        commentMaxLength: 140,
        commentPattern: sbPaymentsApi.SWIFT_PATTERN,
    },

    BDACHCEC: {
        deliveryMethod: '(Corporate Collections)',
        routeBase: '/batch/CorporateCollections',
        commentMaxLength: 50,
        // eslint-disable-next-line
        commentPattern: sbPaymentsApi._NOBADCHARS_PATTERN,
    },

    BDACHCRC: {
        deliveryMethod: '(Consumer Collections)',
        routeBase: '/batch/ConsumerCollections',
        commentMaxLength: 50,
        // eslint-disable-next-line
        commentPattern: sbPaymentsApi._NOBADCHARS_PATTERN,
    },

    CRTRAN: {
        deliveryMethod: '(Real Time Payment)',
        routeBase: '/payment/crtran',
        commentMaxLength: 140,
        commentPattern: sbPaymentsApi.SWIFT_PATTERN,
    },
};

export default Model.extend({
    defaults: {
        BENE_NAME: '',
        BENE_ACCOUNT: '',
        DEBIT_ACCOUNT_NUMBER: '',
        CREDIT_AMOUNT: '',
        VALUE_DATE: '',
        SPECIAL_INSTRUCTIONS: '',
    },

    initialize() {
        this.validators = {
            BENE_NAME: {
                description: locale.get('sbPayments.payee'),
                exists: true,
            },

            BENE_ACCOUNT: {
                description: locale.get('sbPayments.accountNumber'),
                exists: true,
            },

            PAYMENTTYPE: {
                description: locale.get('SMBPAY.PaymentMethod'),
                exists: true,
            },

            ACCOUNTFILTER: {
                description: locale.get('common.fromAccount'),
                exists: true,
            },

            CREDIT_AMOUNT: {
                description: locale.get('sbPayments.amount'),
                exists: true,
                matches: sbPaymentsApi.AMOUNT_PATTERN,
            },

            VALUE_DATE: {
                description: locale.get('sbPayments.effectiveDate'),
                exists: true,
                matchesDatePattern: userInfo.getDateFormat(),
            },

            OBI_TEXT_1: {
                description: locale.get('sbPayments.paymentDetailLine1'),
                maxLength: 35,
                matches: sbPaymentsApi.SWIFT_PATTERN,
                overrideError: 'isSwift',
            },

            OBI_TEXT_2: {
                description: locale.get('sbPayments.paymentDetailLine2'),
                maxLength: 35,
                matches: sbPaymentsApi.SWIFT_PATTERN,
                overrideError: 'isSwift',
            },

            OBI_TEXT_3: {
                description: locale.get('sbPayments.paymentDetailLine3'),
                maxLength: 35,
                matches: sbPaymentsApi.SWIFT_PATTERN,
                overrideError: 'isSwift',
            },

            OBI_TEXT_4: {
                description: locale.get('sbPayments.paymentDetailLine4'),
                maxLength: 35,
                matches: sbPaymentsApi.SWIFT_PATTERN,
                overrideError: 'isSwift',
            },

            SPECIAL_INSTRUCTIONS: {
                description: locale.get('sbPayments.comment'),
                maxLength: 140,
                matches: sbPaymentsApi.SWIFT_PATTERN,
            },
        };
    },

    // Note: model is almost certainly this
    sync(method, model, options) {
        const self = this;
        const serviceName = typeMap[this.get('TYPE')].routeBase;

        if (method === 'read') {
            const readData = this.convertModelAttributesToKeyJSON();
            const route = services.generateUrl(`${serviceName}/read`);
            http.post(route, readData, (response) => {
                options.success(self.convertServerJSONToModelAttributes(response));
            }, () => {});
        }
    },

    /*
     * convert any attributes from the server that need converting.  Do the reverse in
     * printEntry()
     */
    parseEntry(name, value) {
        let returnVal = value;
        if (name === 'VALUE_DATE' || name === 'TRAN_DATE' || name === 'EFFECTIVEDATE') {
            returnVal = Formatter.formatDate(value);
        }
        if (name === 'CONINTLWIRELASTCANCELTIME') {
            if (userInfo.get('timezone') && moment.tz) {
                // Push us to the users' timezone
                moment.tz.setDefault(userInfo.get('timezone'));
            }
            returnVal = `${moment(value).format('MM/DD/YYYY hh:mm:ss A')} ${moment(value).zoneAbbr()}`;
        }
        return returnVal;
    },

    // called by billsGrid/droppableView
    convertInquiryModelAttributesToServerJSON(attribute) {
        const jsonData = [];
        let x = 0;
        // TODO: Use .map()?
        Object.keys(attribute || {}).forEach((data) => {
            jsonData[x] = {
                name: attribute[data].fieldName,
                value: attribute[data].fieldValue,
            };
            x += 1;
        });

        return {
            item: jsonData,
        };
    },

    convertModelAttributesToKeyJSON() {
        const jsonData = [];
        const self = this;
        const keys = ['TNUM', 'UPDATECOUNT__'];
        const paymentType = self.get('TYPE');
        let jsonKey;

        util.each(keys, (key) => {
            jsonData.push({
                name: key,
                value: self.get(key),
            });
        });

        if (Constants.isACH(paymentType)) {
            jsonKey = {
                item: {
                    item: jsonData,
                },
            };
        } else {
            jsonKey = {
                item: jsonData,
            };
        }
        return jsonKey;
    },

    convertServerJSONToModelAttributes(serverJson) {
        const data = {};
        const self = this;
        util.each(serverJson.item, (entry) => {
            if (entry.name === 'TNUM') {
                data.id = entry.value;
            }
            data[entry.name] = self.parseEntry(entry.name, entry.value);
        });

        if (Constants.isACHPayment(data.TYPE)) {
            data.BENE_NAME = data.CMB_BENE_NAME;
            data.CREDIT_AMOUNT = data.CMB_CREDIT_AMOUNT;
            data.SPECIAL_INSTRUCTIONS = data.BATCHCOMMENT;
        } else if (Constants.isACHCollection(data.TYPE)) {
            data.BENE_NAME = data.CMB_BENE_NAME;
            data.CREDIT_AMOUNT = data.CMB_DEBIT_AMOUNT;
            data.SPECIAL_INSTRUCTIONS = data.BATCHCOMMENT;
        }
        return data;
    },

    formattedAmount() {
        return Formatter.formatCurrency(this.get('CREDIT_AMOUNT'));
    },

    formattedDebitAmount() {
        return Formatter.formatCurrency(this.get('DEBIT_AMOUNT'));
    },

    formattedIndicativeAmount() {
        return Formatter.formatCurrency(this.get('INDICATIVEAMOUNT'));
    },

    isExistingPayment() {
        return this.get('TNUM');
    },

    getCommentMaxLength() {
        let maxLength = 140;
        if (this.beneAccount) {
            maxLength = typeMap[this.beneAccount.get('PAYMENTTYPE')].commentMaxLength;
        }
        return maxLength;
    },

    /*
     * Convert bill to payment takes in the vendor bill model, the vendor model and the
     * debit account model
     */
    convertBillToPayment(billModel, vendorModel, debitAccount) {
        if (vendorModel.attributes) {
            // TODO: Convert to one set.
            this.set('BENE_NAME', nhUtil.getFieldValue(billModel, 'VENDORNAME'));
            this.set('BENE_ACCOUNT', vendorModel.get('BENE_ACCOUNTNUMBER'));
            this.set('BENE_BANK_ID', vendorModel.get('BENE_BANK_ID'));
            this.set('BENE_BANK_NAME', vendorModel.get('BENE_BANK_NAME'));
            this.set('BENE_BANK_STATE', vendorModel.get('BENE_BANK_STATE'));
            this.set('BENE_COUNTRY', vendorModel.get('BENE_COUNTRY'));
            this.set('DEBIT_ACCOUNT_NUMBER', debitAccount.get('id'));
            this.set('DEBIT_ACCOUNT_BANK_NAME', nhUtil.getToFieldValue(debitAccount, 'DEBIT_BANKNAME'));
            this.set('ORIGINATOR_NAME', nhUtil.getToFieldValue(debitAccount, 'ORIGINATOR_NAME'));
            this.set('CREDIT_CURRENCY', nhUtil.getToFieldValue(debitAccount, 'CREDIT_CURRENCY'));
            this.set('DEBIT_BANK_CODE', nhUtil.getToFieldValue(debitAccount, 'DEBIT_BANK_CODE'));
            this.set('CREDIT_AMOUNT', nhUtil.getFieldValue(billModel, 'TOTALAMOUNT'));
            this.set('ACCOUNTFILTER', nhUtil.getToFieldValue(debitAccount, 'ACCOUNTFILTER'));
            // What the hell is this?!
            this.set('DETAILS_OF_CHARGES', 'SHA');
            // TRANSACTIONDATE
            this.set('TRAN_DATE', Formatter.formatDate(nhUtil.getFieldValue(billModel, 'DUEDATE'), 'YYYY-MM-DD'));
            // TRANSACTIONDATE
            this.set('VALUE_DATE', Formatter.formatDate(nhUtil.getFieldValue(billModel, 'DUEDATE'), 'YYYY-MM-DD'));
            this.set('paymentDate', Formatter.formatDate(nhUtil.getFieldValue(billModel, 'DUEDATE'), 'YYYY-MM-DD'));
        }
        return this;
    },
});
