import http from '@glu/core/src/http';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import services from 'services';
import BaseController from 'system/baseController';
import InquiryModel from './inquiry';

const ConfigurationParameters = InquiryModel.extend({
    /**
     * This is a model for storing values from the ConfigParams table.
     * This table is used to store global system parameters.  Any changes require a server
     * restart to take effect.
     *
     * @constructs
     */
    initialize() {
        this.searchType = 5;
        this.inquiryId = 9029;
        this.searchOrOperator = false;
    },

    inquiryUrl() {
        return '/configurationParameter/getData';
    },

    initialAppDataInquiryUrl() {
        return '/configurationParameter/getInitialAppData';
    },

    initialAppDataMfaInquiryUrl() {
        return '/configurationParameter/getInitialAppDataMfa';
    },

    sync(method, model, options) {
        let url;
        if (method === 'read') {
            if (options.isPreLoginFetch) {
                /**
                 * append random number, this request no longer to be cached, its response
                 * cache-control header will also indicate no-cache, no-store, must-revalidate
                 */
                url = `${services.generateUrl(this.initialAppDataInquiryUrl())}?${Math.random()}`;
            } else if (options.isPreLoginMfaFetch) {
                url = services.generateUrl(this.initialAppDataMfaInquiryUrl());
            } else {
                url = services.generateUrl(this.inquiryUrl());
            }

            return http.get(url, (result) => {
                options.success(result);
            }, (result) => {
                // 401 response may contain forceLogoff header, if present handle it
                if (result.getResponseHeader('forceLogoff') === 'true') {
                    const modelObj = new Model();
                    modelObj.set(this.parse(result.responseJSON));
                    /**
                     * in some customers environments, DELETE calls are not allowed. When that
                     * is the case, DGB is configured to use the GET based logout service call
                     * rather than the HTTP DELETE based security/logout service
                     */
                    new BaseController().doLogout(false, modelObj.get('useLogoutGet'));
                    return;
                }
                options.error(result);
            });
        }
        // TODO: Maybe return rejected promise for bad method?
        return undefined;
    },

    parse(data) {
        const self = this;
        const retModel = new Model();
        util.each(data.inquiryResponse.rows, (row) => {
            const key = self.parseValue(row.columns, 'parameter');
            const value = self.parseValue(row.columns, 'value');
            retModel.set(key, value);
        });
        return retModel.toJSON();
    },
});

export default new ConfigurationParameters();
