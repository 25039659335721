import CollectionView from '@glu/core/src/collectionView';
import nhUtil from 'system/utilities/accessors';
import DroppedTileItem from './droppedTileItem';

/**
 * Represents a list of view types on the tile grid
 *
 * @class TileGridView
 */
const DroppedTileCollectionView = CollectionView.extend({
    tagName: 'ul',
    className: 'dropped-tiles',
    itemView: DroppedTileItem,

    initialize(options) {
        this.options = options;
    },

    total() {
        let retVal = 0;
        this.options.collection.each((model) => {
            retVal += Number(nhUtil.getFieldValue(model, 'TOTALAMOUNT'));
        });
        return retVal;
    },
});

export default DroppedTileCollectionView;
