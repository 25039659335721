export default ({
    // see view factory for relevant documentation
    'RISK/issueVoids/multipleEntry': 'riskMultipleEntry',

    /*
     * Make sure the route containing the optional viewId param
     * is below '/multipleEntry' so that there is not an errant match
     * that will pass 'multipleEntry' as the viewId.
     */
    'RISK/issueVoids(/)(:viewId)': 'riskIssueVoids',
    'RISK/paymentFraudControl': 'riskPaymentFraudControlWorkspace',
    'RISK/achAuthRulesControl': 'riskAchAuthRulesWorkspace',
    'RISK/paymentFraudControlView': 'riskPaymentFraudControl',
    'RISK/positivePayDecision': 'riskPositivePayDecision',
    'RISK/reversePositivePayDecision': 'riskReversePositivePayDecision',
    'RISK/ePositivePayDecision': 'riskEPositivePayDecision',
    'RISK/viewPositivePayDecision': 'riskViewPositivePayDecision',
    'RISK/viewReversePositivePayDecision': 'riskViewReversePositivePayDecision',
    'RISK/viewEPositivePayDecision': 'riskViewEPositivePayDecision',
    'RISK/payAndAdjustPositivePayDecisionItems': 'riskPayAndAdjustPositivePayDecisionItems',
    'RISK/viewPositivePayDecisionItems': 'riskViewPositivePayDecisionItems',
    'RISK/viewReversePositivePayDecisionItems': 'riskViewReversePositivePayDecisionItems',
    'RISK/viewEPositivePayDecisionItems': 'riskViewEPositivePayDecisionItems',
    'RISK/decisionHistory': 'riskDecisionHistory',
    'RISK/importResults': 'importResults',
    'CM/stopCancelCheckView': 'stopCancelCheckView',
    'CLM/instructionInquiryCLM': 'underConstruction',
    'CLM/checkDetListView': 'underConstruction',
    'CLM/reprintListView': 'underConstruction',
    'CLM/securePrintListView': 'underConstruction',
    'CLM/reconCheckListView': 'underConstruction',
    'CM/multiAdd/(:type)': 'mdfMultiAdd',
    'CM/multiPreFillAdd/(:type)': 'mdfMultiPrefill',
    'RISK/paymentFraudControl(/)(:viewId)': 'riskPaymentFraudControlWorkspace',
    'RISK/achAuthRules': 'riskAchAuthRulesList',
    'RISK/achAuthRulesAdd': 'authRulesAdd',
    'RISK/achAuthRulesView': 'authRulesView',
    'RISK/achAuthRulesModify': 'authRulesModify',
    'RISK/achClientView': 'achClientViewWorkspace',
    'RISK/achClientView(/)(:viewId)': 'achClientViewWorkspace',
    'CMAINT/achClientView': 'clientAchControlTotal',
});
