import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import { createTabsToggleButton, toggleTabs, setTabButtonText } from 'common/util/a11y/tabs';
import tabWrapperTmpl from './tabWrapper.hbs';

export default Layout.extend({
    template: tabWrapperTmpl,
    defaultTab: 'individualItems',

    ui: {
        $navTabs: '[data-hook="getNavTabs"]',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
    },

    events: {
        'click @ui.$navLinks': 'handleClick',
        'keypress @ui.$navLinks': 'handleKeyPress',
    },

    regions: {
        individualItemsRegion: '.individualItemsRegion',
        fileExtractRegion: '.fileExtractRegion',
    },
    isInitialRender: true,

    initialize(options) {
        this.tabViews = {
            individualItems: options.individualItems,
            fileExtract: options.fileExtract,
        };

        this.defaultTab = options.defaultTab || this.defaultTab;
    },

    onRender() {
        createTabsToggleButton(this);
        this.switchTab(this.defaultTab);
        this.isInitialRender = false;
    },

    /**
     * Switch tabs on mouse click
     * @param {object} e - the mouse event
     */
    handleClick(e) {
        const el = e.currentTarget;
        const tabId = el.getAttribute('id');
        this.switchTab(tabId);
    },

    /**
     * Switch tabs on keyboard navigation
     * @param {object} e - the keypress event
     */
    handleKeyPress(e) {
        // enter key
        if (e.which === 13) {
            const el = e.currentTarget;
            const tabId = el.getAttribute('id');
            this.switchTab(tabId);
        }
    },

    /**
     * Switch tabs
     * @param {string} tabId - the tab id
     */
    switchTab(tabId) {
        if (this.currentTab) {
            this.tabViews[this.currentTab].setHasLoadedRequiredData(false);
        }
        this.currentTab = tabId;
        this.currentTabText = this.ui.$navLinks.filter(`[id=${tabId}]`).text();
        this.ui.$navLinks.parent().removeClass('is-active');
        this.ui.$navLinks.filter(`[id=${tabId}]`).parent().addClass('is-active');

        if (tabId === 'fileExtract' && !this.fileExtractRegion.currentView) {
            this.fileExtractRegion.show(this.tabViews.fileExtract);
        } else if (!this.individualItemsRegion.currentView) {
            this.individualItemsRegion.show(this.tabViews.individualItems);
        }

        if (this.isInitialRender) {
            setTabButtonText(this, this.currentTabText);
        } else {
            toggleTabs(this);
        }

        this.$el.find('.individualItemsRegion').toggleClass('hide', tabId !== 'individualItems');
        this.$el.find('.fileExtractRegion').toggleClass('hide', tabId !== 'fileExtract');
    },

    /**
     * Creates an object literal to be used by the template
     * @return {object} the object for the template
     */
    templateHelpers() {
        return {
            /**
             * Gets the title of the page
             * @return {string} the page title.
             */
            getPageTitle() {
                return locale.get('cm.listView_title');
            },
        };
    },
});
