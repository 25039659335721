import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import Model from '@glu/core/src/model';
import SSOCollection from 'app/utilities/collections/ssoCollection';
import template from './itemSelectionView.hbs';

const TargetModel = Model.extend({
    validators: {
        target: {
            description: 'Item',
            exists: true,
        },
    },
});

export default Layout.extend({
    template,
    modalClass: 'modal-lg add-payment-modal',

    ui: {
        $selectItem: '[data-hook="item-select"]',
    },

    events: {},

    dialogButtons: [{
        text: 'Continue',
        className: 'btn btn-primary',
        callback: 'continue',
    }, {
        text: 'Cancel',
        className: 'btn btn-tertiary',
        callback: 'cancel',
    }],

    initialize(options) {
        this.model = new TargetModel();
        this.model.validators.target.description = options.loginResponse.dialogItemDescription;
        this.loginResponse = options.loginResponse;
        this.dialogTitle = this.loginResponse.dialogMessage;
        this.ssoCollection = new SSOCollection(null, this.loginResponse.dialogItems);
    },

    onRender() {
        this.ui.$selectItem.comboBox({
            placeholder: `Select ${this.loginResponse.dialogItemDescription}`,
            minimumResultsForSearch: -1,
        });
    },

    continue() {
        if (this.model.isValid()) {
            const itemModel = this.ssoCollection.get(this.model.get('target'));
            this.trigger('itemSelected', itemModel.get('theItem'));
            dialog.close();
        }
    },

    cancel() {
        dialog.close();
    },

    templateHelpers() {
        const self = this;
        return {
            items() {
                return self.ssoCollection.toJSON();
            },
        };
    },
});
