import locale from '@glu/locale';
import constants from 'app/administration/constants';
import Layout from '@glu/core/src/layout';
import CollectionView from '@glu/core/src/collectionView';
import Entitlements from 'app/administration/collection/user2/entitlements';
import EntitlementCollectionView from './entitlementCollectionView';
import ContactTypeView from './contactTypeView';
import groupViewTmpl from './groupView.hbs';
import utils from './permissionUtils';
import { updateSelectAllState, onChildrenChange } from '../helpers';

export default Layout.extend({
    template: groupViewTmpl,
    className: 'admin-group',

    ui: {
        $selectAll: '.select-all-perms',
    },

    events: {
        'click @ui.$selectAll': 'selectAllPerms',
    },

    initialize(opts) {
        this.mode = opts.mode;
        this.isBene = this.model.get('id') === 'BeneficiaryAddressBook';

        this.entitlements = new Entitlements(this.model.get('types').map((type) => {
            const entitlement = type.get('entitlements').at(0);
            entitlement.label = locale.get(type.get('label'));
            return entitlement;
        }));

        this.entitlements.comparator = function (model) {
            return locale.get(`type.${model.get('typeGroupLabel')}`);
        };
        this.entitlements.sort();

        if (this.mode === constants.MODES.VIEW) {
            // "CHECKED === true" means user has entitlement
            this.options.mainModel.contactTypes
                .reset(this.options.mainModel.contactTypes.models.filter(type => type.get('CHECKED')));
        }
        this.boundUpdateSelectAllState = this.updateSelectAllState.bind(this, null);
    },

    shouldShowPermissions(type) {
        if (type !== '_USER' && type !== '_ROLE') {
            return true;
        }
        if (utils.isBankMaintained(this.options)) {
            if (this.options.bankMaintained === '1') { // checkbox is checked
                return true;
            }
            return false;
        }
        return true;
    },

    onRender() {
        this.entitlementsCollectionView = new EntitlementCollectionView({
            collection: this.entitlements,
            mode: this.mode,
        });

        if (this.shouldShowPermissions(this.entitlements.models[0].get('typeCode'))) {
            this.permissionsRegion.show(this.entitlementsCollectionView);
        }

        if (this.isBene) {
            this.contactTypesRegion.show(new CollectionView({
                className: 'checkbox',
                collection: this.options.mainModel.contactTypes,
                itemView: ContactTypeView,

                itemViewOptions: {
                    mode: this.mode,
                    onChange: () => this.updateSelectAllState(this.contactTypesRegion?.$el.find('.checkbox-inline input[type="checkbox"]')),
                },
            }));
        }
        this.updateSelectAllState(null);
        this.onChildrenChange();
    },

    onChildrenChange() {
        onChildrenChange(this, this.getCheckboxList());
    },

    updateSelectAllState(checkboxList) {
        updateSelectAllState(this, checkboxList || this.getCheckboxList());
    },

    getCheckboxList() {
        return this.permissionsRegion?.$el.find('.checkbox-inline input[type="checkbox"]');
    },

    /*
     * Selects/deselects all admin types in a group
     * @param  {Event} evt
     */
    selectAllPerms(evtParam) {
        const evt = evtParam;
        const { contactTypes } = this.options.mainModel;
        const { checked } = evt.target;

        this.entitlements.selectAll(checked);
        // have to rerender until two way binding works for nested models
        this.entitlementsCollectionView.render();

        // Select all the contact types when in bene address book group
        if (this.isBene && contactTypes) {
            contactTypes.each((model) => {
                model.trigger('selectAll', checked);
            });
        }
        this.onChildrenChange();
    },

    templateHelpers() {
        const sectionIds = this.model.get('types').models.map(m => m.id);
        let showSection = true;
        /*
         * user & role maintenance only have one section
         */
        if (sectionIds.length === 1 && !this.shouldShowPermissions(sectionIds[0])) {
            showSection = false;
        }
        return {
            cid: this.model.cid,
            readOnly: this.mode === constants.MODES.VIEW,
            isBene: this.isBene,
            label: locale.get(`adminGroup.${this.model.get('id')}`),
            showSection,
        };
    },
});
