import locale from '@glu/locale';
import Layout from '@glu/core/src/layout';
import alert from '@glu/alerts';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import securityChallengeQuestions from 'system/login/services/securityChallengeQuestions';
import AuthenticatedChallenge from './authenticatedChallenge';
import template from './inactiveChallenge.hbs';

export default Layout.extend({
    template,
    loadingTemplate,

    initialize(options) {
        this.questionsPromise = this.getChallengeQuestions(options.userGroup, options.userId);
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            if (this.enrollmentStatus) {
                this.challengeQuestionsRegion.show(this.challenge);
            } else {
                this.alertRegion.show(alert.warning(locale.get('logon.forgotten.system.error')));
            }
        } else {
            this.questionsPromise.then((response) => {
                this.enrollmentStatus = response.enrollmentStatus;
                this.challenge = this.createChallengeQuestionsView(
                    response.data.items,
                    this.options.userGroup,
                    this.options.userId,
                );
                this.setHasLoadedRequiredData(true);
                this.render();
            }).catch(() => {
                this.setHasLoadedRequiredData(true);
                this.render();
            });
        }
    },

    createChallengeQuestionsView(questions, userGroup, userId) {
        const challengeQuestion = new AuthenticatedChallenge({
            questions,
            title: locale.get('common.security.title'),
            notModal: true,
            user: {
                realm: userGroup,
                name: userId,
            },
        });
        challengeQuestion.on('save', this.challengeSaveSuccess.bind(this));
        challengeQuestion.on('cancel', this.goToLoginScreen.bind(this));

        return challengeQuestion;
    },

    getChallengeQuestions(userGroup, userId) {
        return securityChallengeQuestions.getChallengeQuestions({
            userId,
            userGroup,
        });
    },

    challengeSaveSuccess() {
        this.trigger('challenge:success');
    },

    goToLoginScreen() {
        this.navigateTo('logout');
    },
});
