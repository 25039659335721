import React from 'react';
import locale from '@glu/locale';
import ContentLoading from 'pcm/common/components/ContentLoading';
import store from 'pcm/common/store';
import EntitlementProvider from 'pcm/common/entitlementContext/EntitlementProvider';
import { workflowEntitlements } from './workflowConfigUtils';

const WorkflowConfig = React.lazy(() => import('./WorkflowConfigList'));
const ViewWorkflowConfig = React.lazy(() => import('./ViewWorkflowConfig'));
const AddWorkflowConfig = React.lazy(() => import('./AddWorkflowConfig'));
const EditWorkflowConfig = React.lazy(() => import('./EditWorkflowConfig'));

const getActionToTitleOptions = () => ({
    title: {
        view: locale.get('workflow.payment.view'),
        add: locale.get('workflow.addNewWorkflowConfig'),
        edit: locale.get('workflow.editWorkflowConfig'),

    },
    backLink: '/ui-admin-portal/ADMINSTRATION/iopaymentconfig',
});

export default {

    pcmWorkflowConfig() {
        this.showPage(
            locale.get('workflow.config.list'),
            <React.Suspense fallback={<ContentLoading />}>
                <EntitlementProvider entitlements={workflowEntitlements}>
                    <WorkflowConfig />
                </EntitlementProvider>
            </React.Suspense>,
            {
                headlineText: locale.get('workflow.config.list'),
            },
        );
    },

    pcmWorkflowConfigActions(action) {
        const components = {
            view: ViewWorkflowConfig,
            add: AddWorkflowConfig,
            edit: EditWorkflowConfig,
        };

        store.action = action;
        store.alerts = {};

        const actionToTitleOptions = getActionToTitleOptions();
        const ComponentToShow = components[action];

        this.showPage(
            actionToTitleOptions.title[action],
            <React.Suspense fallback={<ContentLoading />}>
                <EntitlementProvider entitlements={workflowEntitlements}>
                    <ComponentToShow action={action} />
                </EntitlementProvider>
            </React.Suspense>,
            {
                headlineText: actionToTitleOptions.title[action],
                backLink: actionToTitleOptions.backLink,
            },
        );
    },
};
