import serverConfigParams from 'system/webseries/models/configurationParameters';

const walkMe = {
    serverConfigParamsReady() {
        // Config param is based on the environment it is running on (prod or test)
        const isWalkMeEnabled = serverConfigParams.get('walkMe.enabled') === 'true';
        if (isWalkMeEnabled) {
            const isWalkMeProd = serverConfigParams.get('walkMe.production') === 'true';
            let walkMeSrc = serverConfigParams.get('walkMe.srcTest');
            if (isWalkMeProd) {
                walkMeSrc = serverConfigParams.get('walkMe.srcProd');
            }
            const walkme = document.createElement('script');
            walkme.async = true;
            walkme.src = walkMeSrc;
            document.body.appendChild(walkme);
            // eslint-disable-next-line
            window._walkmeConfig = { smartLoad: true };
        }
    },
};

export default walkMe;
