import Layout from '@glu/core/src/layout';
import Dialog from '@glu/dialog';
import locale from '@glu/locale';
import loadingModalTmpl from 'common/templates/loadingModal.hbs';
import BillerImageModel from '../models/billerImage';
import billerImageTmpl from './billerImage.hbs';

const BillerImage = Layout.extend({
    template: billerImageTmpl,
    loadingTemplate: loadingModalTmpl,
    modalClass: 'modal-lg',

    initialize(options) {
        this.model = new BillerImageModel({
            MerchantId: options.billerModel.get('MerchantId'),
            FileName_en: options.billerModel.get('FileName_en'),
            FileName_fr: options.billerModel.get('FileName_fr'),
        });

        this.dialogButtons = [{
            text: locale.get('button.close'),
            className: 'btn btn-primary',
            callback: 'cancel',
        }];

        this.dialogTitle = locale.get('RTGS.bpay.imageModalTitle');
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        const self = this;

        this.model.fetch({
            success() {
                self.setHasLoadedRequiredData(true);
                self.render();
            },
        });
    },

    cancel() {
        Dialog.close();
    },
});

export default BillerImage;
