import constants from 'common/dynamicPages/api/constants';
import cmConstants from 'app/checkManagement/constants';
import dialog from '@glu/dialog';
import store from 'system/utilities/cache';
import ReturnReasonDialog from 'app/checkManagement/views/returnReasonDialog';
import workspaceHelper from 'common/workspaces/api/helper';
import ListDecision from './listDecision';

const ReversePositivePayDecisionList = ListDecision.extend({
    id: 'pmtFraudCtrlTodaysDecisions',

    attributes: {
        role: 'tabpanel',
        tabindex: '0',
        'aria-labelledby': 'pmtFraudCtrlTodaysDecisionsTab',
    },

    initialize(options) {
        // set options for listView
        this.mobileListViewOptions = {
            bulkActions: [
                {
                    label: 'cm.button_pay',
                    entitlement: 'PAY',
                },
                {
                    label: 'cm.button_return',
                    entitlement: 'RETURN',
                },
                {
                    label: 'cm.button_approve',
                    entitlement: 'APPROVE',
                },
                {
                    label: 'cm.button_unapprove',
                    entitlement: 'UNAPPROVE',
                },
                {
                    label: 'cm.button_viewitems',
                    handlerMethodName: 'viewChecks',
                    handleEntitlementMethodName: 'shouldEnableViewChecks',
                    entitlement: 'SELECT',
                },
            ],
        };
        this.mobileGridView = ReversePositivePayDecisionList;
        this.renderMobileGridUsingTemplate = options.renderMobileGridUsingTemplate !== undefined
            ? options.renderMobileGridUsingTemplate
            : true;

        // call the parent
        ListDecision.prototype.initialize.call(this, options);
    },

    getProductCode() {
        return cmConstants.CM;
    },

    getFunctionCode() {
        return cmConstants.BATCH;
    },

    getTypeCode() {
        return cmConstants.REVERSE_POS_PAY_TYPE;
    },

    getMenuContext() {
        return 'cm/listView/reversePositivePayDecision/multi';
    },

    getInquiryId() {
        return constants.INQUIRY_ID_20540;
    },

    /** @override */
    getInitializeOptions(options) {
        const initOptions = {
            serviceName: 'cm/listView/reversePositivePayDecision',
            serviceFunc: null,
            businessType: null,
            returnRoute: options.returnRoute,
            enableSavedViews: true,
            viewAll: options.viewAll,
        };

        let rowModel;

        if (options.viewAll) {
            store.set('cm_listView_comingToList', 'revPosPay-items');
        } else {
            rowModel = this.getRowModel(options);
            initOptions.additionalSearchFields = [{
                fieldName: 'PPACCOUNTBATCHID',
                operator: 'EQ',
                fieldValue: [rowModel.get('BATCHTNUM')],
                dataType: 'text',
            }];
        }

        return initOptions;
    },

    back() {
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    /** @override */
    getRowModel() {
        return store.get('cm_listView_paymentFraudControl-actionModel');
    },

    /** @override */
    gridRowSelect(options) {
        const { model } = options;
        model.set({
            ACCOUNT_NUMBER: this.ACCOUNT_NUMBER,
            ACCOUNT_TITLE: this.ACCOUNT_TITLE,
            _mode: 'SELECT',
        });
        store.set(`${this.contextKey}-actionModel`, model);
        this.navigateTo('RISK/viewReversePositivePayDecision');
        return Promise.resolve();
    },

    /** @override */
    gridRowCustomAction(options) {
        const action = options.action.toUpperCase();
        const { model } = options;
        const selectedRow = [];
        model.fieldData = this.getFieldDataToSendToServer();
        selectedRow.push(model);

        if (action === 'PAY') {
            model.set({
                CMDECISION: 'P',
                CLIENTRETURNREASONCODE: '',
                CLIENTRETURNREASONDESC: '',
                PAYISSUE_FLAG: 'N',
            });
            this.processMultiActionsAsOne(constants.ACTION_PAY, selectedRow);
        } else if (action === 'RETURN') {
            const returnReasonDialog = new ReturnReasonDialog({
                model,
                list: this,
                multiSelect: false,
                selectedRow,
                type: cmConstants.REVERSE_POS_PAY_TYPE,
                serviceUrl: constants.URL_RETURN_REASON_CMPPDEC,
            });
            dialog.custom(returnReasonDialog);
        }
        return Promise.resolve();
    },

    /** @override */
    populateCustomKeys(item) {
        item.item.push({
            name: 'CUSTOMKEY',
            value: 'PPCMPPPAIDITEMID',
        });
        return item;
    },

    /** @override */
    getCustomItem(model, methodName) {
        let item;
        if (methodName === constants.ACTION_RETURN) {
            item = this.convertModelAttributesToJSONForCM(
                model,
                {
                    CMDECISION: 'R',
                    CLIENTRETURNREASONCODE: '',
                    PPCMPPPAIDITEMID: '',
                    CLIENTRETURNREASONDESC: '',
                    UPDATECOUNT__: '',
                    PAYISSUE_FLAG: 'N',
                    CUSTOMEKEY: 'PPCMPPPAIDITEMID',
                },
            );
        }

        if (methodName === constants.ACTION_PAY) {
            item = this.convertModelAttributesToJSONForCM(
                model,
                {
                    CMDECISION: 'P',
                    CLIENTRETURNREASONCODE: '',
                    PPCMPPPAIDITEMID: '',
                    CLIENTRETURNREASONDESC: '',
                    UPDATECOUNT__: '',
                    PAYISSUE_FLAG: 'N',
                    CUSTOMEKEY: 'PPCMPPPAIDITEMID',
                },
            );
        }
        return item;
    },

    /** @override */
    getFieldDataToSendToServer() {
        return {
            PPCMPPPAIDITEMID: {
                name: 'PPCMPPPAIDITEMID',
                virtualField: false,
            },
            CMDECISION: {
                name: 'CMDECISION',
                virtualField: false,
            },
            CLIENTRETURNREASONCODE: {
                name: 'CLIENTRETURNREASONCODE',
                virtualField: false,
            },
            CLIENTRETURNREASONDESC: {
                name: 'CLIENTRETURNREASONDESC',
                virtualField: false,
            },
            PAYISSUE_FLAG: {
                name: 'PAYISSUE_FLAG',
                virtualField: false,
            },
            // eslint-disable-next-line
            UPDATECOUNT__: {
                name: 'UPDATECOUNT__',
                virtualField: false,
            },
        };
    },

    /** @override */
    updateSelectedModels(methodName, args) {
        if (methodName === 'PAY') {
            this.updateModelOfSelectedRows({
                CMDECISION: 'P',
                CLIENTRETURNREASONCODE: '',
                CLIENTRETURNREASONDESC: '',
                PAYISSUE_FLAG: 'N',
            });
        }
        if (methodName === 'RETURN') {
            this.updateModelOfSelectedRows({
                CMDECISION: 'R',
                CLIENTRETURNREASONCODE: args.CLIENTRETURNREASONCODE,
                CLIENTRETURNREASONDESC: args.CLIENTRETURNREASONDESC,
                PAYISSUE_FLAG: 'N',
            });
        }
    },

    multiPay() {
        this.handleBulkAction(constants.ACTION_PAY);
    },

    multiReturn() {
        if (!this.isRowSelected()) return;
        const returnReasonDialog = new ReturnReasonDialog({
            multiSelect: true,
            type: cmConstants.REVERSE_POS_PAY_TYPE,
            serviceUrl: constants.URL_RETURN_REASON_CMPPDEC,
        });
        returnReasonDialog.once('list:multi:action:action_return', this.handleMultiReturn, this);
        dialog.custom(returnReasonDialog);
    },

    getReturnPath() {
        this.navigateTo('RISK/viewReversePositivePayDecisionItems');
    },

    handleMultiReturn(returnReason) {
        this.handleBulkAction(constants.ACTION_RETURN, returnReason);
    },
});

export default ReversePositivePayDecisionList;
