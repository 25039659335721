import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import validatorPatterns from 'system/validatorPatterns';
import services from 'services';

export default Model.extend({

    defaults() {
        return {
            EMAILADDRESS: '',
            PHONENUMBER: '',
            MOBILEPHONENUMBER: '',
            FAXNUMBER: '',
            USERGROUP: '',
            USERID: '',
        };
    },

    initialize(userInfo, context) {
        this.set({
            USERGROUP: userInfo.get('group'),
            USERID: userInfo.get('id'),
        });

        this.context = context;

        this.validators = {
            EMAILADDRESS: {
                exists: true,
                description: locale.get('alerts.emails'),
                isEmailAddress: true,
                matches: validatorPatterns.EMAIL_PATTERN,
            },
            PHONENUMBER: {
                description: locale.get('alerts.phone'),
                matches: validatorPatterns.PHONE_NUMERIC_PATTERN,
            },
            MOBILEPHONENUMBER: {
                description: locale.get('administration.mobile.phone'),
                matches: validatorPatterns.PHONE_NUMERIC_PATTERN,
            },
            FAXNUMBER: {
                description: locale.get('alerts.fax'),
                matches: validatorPatterns.PHONE_NUMERIC_PATTERN,
            },
        };
    },

    /**
     * Save updates to the server
     * @param  {string} method - The sync method
     * @param  {Model} model - this Model
     * @param  {object} options
     * @return {Promise} - A jQuery Promise
     */
    sync(method, model, options) {
        if (method === 'update') {
            return http.post(services.mySettingsUpdate, this.getSaveData(model), (result) => {
                options.success(result);
            }, (result) => {
                options.error(result);
            });
        }
        return undefined;
    },

    /**
     * Generate the data object for update (save)
     * @param  {Model} model - this Model
     * @return {object} - The data object to save
     */
    getSaveData(model) {
        return {
            emailAddress: model.get('EMAILADDRESS'),
            phoneNumber: model.get('PHONENUMBER'),
            mobilePhoneNumber: model.get('MOBILEPHONENUMBER'),
            faxNumber: model.get('FAXNUMBER'),
            requestHeader: {
                requestUser: {
                    data: {
                        item: [{
                            name: 'USERGROUP',
                            value: model.get('USERGROUP'),
                        }],
                    },
                    userId: model.get('USERID'),
                },
            },
        };
    },
});
