import contextApi from 'common/dynamicPages/api/context';
import gridApi from 'common/dynamicPages/api/grid';
import PageApi from 'common/dynamicPages/api/view';
import DataApi from 'common/dynamicPages/api/data';
import ListView from 'app/smbPayments/views/paymentGrid';
import entitlementsApi from 'common/dynamicPages/api/entitlements';
import errHandler from 'system/errHandler';
import constants from './constants';

export default {
    /*
     * a view factory is used so that the same views could be used in widget, modal or
     * normal views
     */

    /*
     * builds a grid based purely off the meta driven system Orion has built
     * buttons and use are purely meta driven
     */
    gridMetaView() {
        const options = {
            context: contextApi.menuContext.getContext(constants.GRID_CONTEXT),
        };

        const gridPromise = gridApi.createServiceGridView(options);

        return new Promise((resolve) => {
            gridPromise.then((result) => {
                resolve(result);
            }).then(null, errHandler);
        });
    },

    /*
     * builds a grid based off the meta driven but uses options to tell the grid to behave in
     * a custom manner
     */
    gridCustomView() {
        const options = {
            context: contextApi.menuContext.getContext(constants.GRID_CONTEXT),
        };

        return entitlementsApi.getEntitlements(options).then(entitlements => new ListView({
            entitlements,
            viewFactory: this,
        })).then(null, errHandler);
    },

    paymentViewAdd() {
        const context = window.Bottomline.store[constants.STORE_PAYMENTS_CONTEXT];

        const options = {
            context,
        };

        return Promise.resolve(PageApi.page.get(options));
    },

    paymentViewView() {
        const context = window.Bottomline.store[constants.STORE_PAYMENTS_CONTEXT];
        const model = window.Bottomline.store[constants.STORE_PAYMENTS_VIEWMODEL];

        return new Promise((resolve, reject) => {
            DataApi.model.generateModelFromModel(model).then((newModel) => {
                newModel.fetch({
                    success() {
                        const options = {
                            context,
                            model: newModel,
                            state: 'view',
                        };

                        resolve(PageApi.page.get(options));
                    },
                });
            }, reject);
        });
    },

    paymentViewModify() {
        const context = window.Bottomline.store[constants.STORE_PAYMENTS_CONTEXT];
        const model = window.Bottomline.store[constants.STORE_PAYMENTS_VIEWMODEL];

        return new Promise((resolve, reject) => {
            DataApi.model.generateModelFromModel(model).then((newModel) => {
                newModel.fetch({
                    silent: true,

                    success() {
                        const options = {
                            context,
                            model: newModel,
                            state: 'modify',
                        };

                        resolve(PageApi.page.get(options));
                    },
                });
            }, reject);
        });
    },

    getView(viewName) {
        if (viewName === constants.GRID_META_VIEW) {
            return this.gridMetaView();
        }
        if (viewName === constants.GRID_CUSTOM_VIEW) {
            return this.gridCustomView();
        }
        if (viewName === constants.ADD_PAYMENT_VIEW) {
            return this.addPaymentView();
        }
        if (viewName === constants.PAYMENT_VIEW_ADD) {
            return this.paymentViewAdd();
        }
        if (viewName === constants.PAYMENT_VIEW_VIEW) {
            return this.paymentViewView();
        }
        if (viewName === constants.PAYMENT_VIEW_MODIFY) {
            return this.paymentViewModify();
        }
        return undefined;
    },
};
