import constants from 'app/balanceAndTransaction/constants';
import systemConfig from 'system/configuration';
import TransactionListView from './depositTransactionListView';

export default TransactionListView.extend({
    accountType: 'DEPOSIT',

    /**
     * provides a part of The URL to hit for Current Day Transactions
     * @method getServiceName - extend
     * @return {String} A part of the URL to hit for Current Day Transactions
     */

    getServiceName() {
        return `${constants.DEPOSIT_ACCTS_SERVICE_PREFIX + constants.CURRENT_DAY_PREFIX}getTransactions`;
    },

    /**
     * provides the URL to hit for print/export on Current Day Transactions
     * @method getExportPrintURL - extend
     * @return {String} the URL to hit for print/export on Current Day Transactions
     */

    getExportPrintURL() {
        return systemConfig.isAdmin()
            ? constants.CURRENT_DAY_TRANS_EXPORT_PRINT_URL
            : constants.ASYNC_CURRENT_DAY_TRANS_EXPORT_PRINT_URL;
    },

    /**
     * provides the typeCode for Current Day Transactions
     * @method getTypeCode - extend
     * @return {String} provides the typeCode for Current Day Transactions
     */

    getTypeCode() {
        return constants.BTR_TYPECODES.CURRENT.TRANSACTIONS;
    },

    /**
     * provides ability for displayAccountTransactionBalance to be shown or hidden on
     * Transaction drilldown
     * should be shown for Current Day Transaction drilldown
     * @method templateHelpers
     * @return {object} provides ability for displayAccountTransactionBalance to
     * be shown or hidden on Transaction drilldown
     */

    templateHelpers() {
        return {
            displayAccountTransactionBalance: true,

            gridUtilityOptions: {
                hasRefresh: false,
            },

            hideBackHeader: () => this.hideBackHeader,
        };
    },
});
