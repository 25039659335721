import locale from '@glu/locale';
import Model from '@glu/core/src/model';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import PrintExportBase from './printExportBase';
import exportOptionsTmpl from './exportOptions.hbs';

export default PrintExportBase.extend({
    template: exportOptionsTmpl,

    initialize(options) {
        this.model = new Model({
            selection: options.selectedRows
                ? this.SELECTION_MODE_SELECT : this.SELECTION_MODE_ALL,
            // default to summaryInquiryId when present
            inquiryId: options.summaryInquiryId || options.inquiryId,
            format: 'CSV',
            expdata: 'summary',
        });

        this.dialogTitle = locale.get('common.exportOptions.title');

        this.dialogButtons = [{
            text: locale.get('button.export'),
            className: 'btn btn-primary',
            callback: 'export',
        }, {
            text: locale.get('common.cancel'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }];
    },

    /**
     * Close this dialog and trigger the export event and pass in this model
     */
    export() {
        dialog.close();
        const options = util.extend(this.options, this.model.toJSON());
        this.trigger('export', options);
    },
});
