import CheckGrid from './checkGrid';
import CONSTANTS from './constants';
import gridDataAccess from './gridDataActions';

export default CheckGrid.extend({
    // Identifies if there is only one type in a group
    singleType: true,

    /**
     * Return the location data entitlement attribute
     * @return {string}
     */
    getEntityName() {
        return CONSTANTS.ENTITY_NAME.LOCATION;
    },

    /**
     * Return the location data inquiry id for the service call
     * @return {string}
     */
    getEntityID() {
        return CONSTANTS.REQUEST_ENTITY.LOCATION;
    },

    /**
     * Return the location data inquiry id for current/future for the service call
     * @return {string}
     */
    getCFEntityID() {
        return CONSTANTS.REQUEST_ENTITY.LOCATION_CF;
    },

    /**
     * Return the location type used for localized resources
     * @return {string}
     */
    getEntityType() {
        return CONSTANTS.ENTITY_TYPE.LOCATION;
    },

    /**
     * Return the available location types to populate the grid
     * @return {Array}
     */
    getRecords() {
        return gridDataAccess.locationRecords(this.model);
    },
});
