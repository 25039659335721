import Collection from '@glu/core/src/collection';
import services from 'services';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';

export default Collection.extend({
    initialize(options) {
        this.mode = options.mode;
        this.isEntry = options.mode === 'INSERT';
        this.isModify = options.mode === 'MODIFY';
        this.isView = options.mode === 'SELECT';
        this.isCopy = options.mode === 'COPY';
        this.baseServicePath = options && options.serviceName ? options.serviceName : 'bannerNotification';
        this.existingFeatures = options.existingFeatures;
        this.allFeaturesSelected = options.allFeaturesSelected;
    },
    sync(method, collection, options) {
        const url = services.generateUrl(`${this.baseServicePath}/feature/getFeatures`);

        http.post(url, {}, (result) => {
            options.success(result);
        }, (result) => {
            options.error(result);
        });
    },

    parse(result) {
        return this.initProcessTopTierBranches(result.branches);
    },

    /**
     * Accepts the top level branches from the REST service. Each item in the
     * array represent a
     * tab in the UI
     * @param tabs
     * @returns [Array]
     */
    initProcessTopTierBranches(tabs) {
        const self = this;
        // With each product feature tab (e.g. Payments)
        return util.map(tabs, (tab) => {
            const tabParam = tab;
            self.initProcessSecondTierBranches(tabParam);
            // If every group within a tab shouldBeHidden, hide the tab.
            tabParam.shouldBeHidden = util.every(
                tabParam.branches,
                branch => branch.shouldBeHidden,
            );
            return util.extend(tabParam, {
                label: tabParam.desc,
            });
        });
    },

    /**
     * Accepts the second level tab object. Each item of the tab.branches array
     * represents a
     * group of features categorized by the tab
     * @param tab
     */
    initProcessSecondTierBranches(tab) {
        const self = this;
        // With each product feature group within a tab (e.g. Templates)
        util.map(tab.branches, (group) => {
            const groupParam = group;
            groupParam.groupId = tab.id;
            groupParam.count = 0;
            self.initProcessThirdTierBranches(groupParam);
        });
    },

    /**
     * Accepts the third level group object. Each item in group.branches array
     * represents
     * either a group of features or another grouping of features.
     * (e.g. Payments (tab) -> Alerts (group) -> Approver Rejected Payments (feature)
     *                      -> Template (group) -> Wires (child group) -> Drawdown (feature)
     * @param group
     */
    initProcessThirdTierBranches(group) {
        const self = this;
        const groupParam = group;

        groupParam.selectedCount = 0;
        groupParam.unSelectedCount = 0;
        groupParam.hasAtleastOneSubGroup = false;
        // With each feature within a group (e.g. Wires)
        util.forEach(groupParam.branches, (feature) => {
            const featureParam = feature;
            featureParam.groupId = groupParam.id;
            if (featureParam.branches.length > 0) {
                featureParam.hasChildItems = true;
                groupParam.hasAtleastOneSubGroup = true;
                self.initProcessFourthTierBranches(featureParam, groupParam);
            } else {
                featureParam.hasChildItems = false;
                if (!self.isEntry && self.isExistingFeature(featureParam.id)) {
                    if (self.isView) {
                        featureParam.shouldBeReadOnly = true;
                    }
                    featureParam.isSelected = true;
                    featureParam.count += 1;
                    groupParam.selectedCount += 1;
                } else if (self.isView) {
                    featureParam.shouldBeHidden = true;
                    groupParam.unSelectedCount += 1;
                }
            }
        });

        if (groupParam.hasAtleastOneSubGroup) {
            groupParam.allChildGroupsSelected = util.every(
                groupParam.branches,
                branch => branch.allChildItemsSelected,
            );
            groupParam.shouldBeHidden = util.every(
                groupParam.branches,
                branch => branch.shouldBeHidden,
            );
        } else if (groupParam.selectedCount === groupParam.branches.length) {
            groupParam.allFeaturesSelected = true;
        } else if (groupParam.unSelectedCount === groupParam.branches.length) {
            groupParam.shouldBeHidden = true;
        }
        return undefined;
    },

    /**
     * Accepts the third level feature and the group parent object. Each item of the
     * feature.branches array represents a child feature
     * @param featureParam
     * @param group
     */
    initProcessFourthTierBranches(featureParam, group) {
        const self = this;
        const feature = featureParam;
        const groupParam = group;
        feature.selectedChildCount = 0;
        feature.unSelectedChildCount = 0;
        feature.totalChildCount = feature.branches.length;
        // With each child feature (e.g. Drawdown)
        util.forEach(feature.branches, (childfeature) => {
            const childFeatureParam = childfeature;
            if (!self.isEntry && self.isExistingFeature(childFeatureParam.id)) {
                childFeatureParam.isSelected = true;
                feature.selectedChildCount += 1;
                groupParam.selectedCount += 1;
                if (self.isView) {
                    childFeatureParam.shouldBeReadOnly = true;
                    childFeatureParam.shouldBeHidden = false;
                }
            } else if (self.isView) {
                childFeatureParam.shouldBeHidden = true;
                feature.unSelectedChildCount += 1;
            }
        });

        if (!self.isEntry) {
            feature.allChildItemsSelected = feature.selectedChildCount === feature.totalChildCount;
        }
        if (self.isView) {
            feature.shouldBeHidden = feature.unSelectedChildCount === feature.totalChildCount;
        }
    },

    /**
     * @method isExistingFeature
     * @param id (e.g. USACH~BATCH~ACH~BDACHCCD)
     * @returns {boolean}
     * checks the id against an array of existing features
     */
    isExistingFeature(id) {
        let isExistingFeature = false;
        if (this.allFeaturesSelected) {
            isExistingFeature = this.allFeaturesSelected;
        } else if (this.existingFeatures && this.existingFeatures.length > 0) {
            isExistingFeature = this.existingFeatures.indexOf(id) > -1;
        } else {
            isExistingFeature = false;
        }
        return isExistingFeature;
    },
});
