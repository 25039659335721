import util from '@glu/core/src/util';
import constants from 'common/dynamicPages/api/constants';
import FooterForm from 'common/dynamicPages/views/mdf/metaDrivenFooterForm';
import PageView from 'common/dynamicPages/views/mdf/metaDrivenForm';
import viewHelper from 'common/dynamicPages/api/viewHelper';

const compareFieldUIOrder = function (a, b) {
    if (a.fieldUIOrder < b.fieldUIOrder) {
        return -1;
    }
    if (a.fieldUIOrder > b.fieldUIOrder) {
        return 1;
    }
    return 0;
};

export default {
    page: {
        /**
         * Assigns classes to individual fields on the form
         * @param allVisibleFields {Array} - all visible fields within the form
         * @param fieldColumnSpanInUse {Boolean} - indicates whether or not to use a columnar
         * layout for the page
         */
        assignClassesToFields(allVisibleFields, fieldColumnSpanInUse) {
            // assign classes to all fields that will be onscreen
            allVisibleFields.forEach((fieldParam) => {
                const field = fieldParam;
                field.infoClass = (field.info && !field.mandatory) ? 'textline-field' : undefined;
                field.cssClass = this.getVisibleFieldCSSClass(field);
                /*
                 * there are rare situations where we don't want to apply a size to a field
                 * ex: in a vertically aligned group the group itself gets the size
                 */
                if (!field.shouldNotBeSized) {
                    field.blockClass = this.getVisibleFieldBlockClass(
                        field,
                        fieldColumnSpanInUse,
                    );
                }
            });

            return allVisibleFields;
        },

        /**
         * Conditionally return a blockClass property for a visible field in the fieldlist based
         * on its field properties
         * @param {object} field - the field to add classes to
         * @param fieldColumnSpanInUse {Boolean} - indicates whether or not a columnar layout
         * is in use for the form
         * @return {string} blockClass
         */
        getVisibleFieldBlockClass(field, fieldColumnSpanInUse) {
            const size = Number(field.size);

            // will check if columns in use for field
            if (fieldColumnSpanInUse && (field.columnSpan && field.columnSpan !== '0')) {
                // HACK to add midsize view break (768 - 1024)
                if (field.columnSpan === 2) {
                    return `col-md-${field.columnSpan} col-mdlg-${field.columnSpan + 2}`;
                }
                return `col-md-${field.columnSpan}`;
            }
            if (field.fieldUIType && field.fieldUIType.indexOf('FILTER')) {
                return 'field-container-lg';
                // check by size if not a filter type
            }
            if (size < constants.SMALLTEXT) {
                return 'field-container-xs';
            }
            if (size < constants.MEDIUMTEXT) {
                return 'field-container-sm';
            }
            if (size < constants.LARGETEXT) {
                return 'field-container-md';
            }

            return 'field-container-lg';
        },

        /**
         * Conditionally return the cssClass property for a visible field in the fieldlist based
         * on its field properties
         * @param {object} field - the field to add classes to
         * @return {string} cssClass - the classes to apply to this field
         */
        getVisibleFieldCSSClass(field) {
            let cssClass = 'form-control';

            switch (field.fieldUIType) {
            case 'COMBOBOX':
            case 'COMBOSELECT':
            case 'COMBOFILTER':
            case 'COMBOFILTERLT':
            case 'MULTICOMBOFILTER':
            case 'COMBOBOXWIDGET':
                cssClass += ' form-select';
                break;
            case 'TEXTLINE':
                cssClass = 'textline-field';
                break;
            case 'TEXTAREA':
                cssClass += ' textarea-field';
                break;
            default:
                break;
            }

            if (field.fieldType === 'AMOUNT' && field.relatedProperty) {
                cssClass += ' amount-field';
            }

            return cssClass;
        },

        /**
         * Adds a specific field to a fields array within a group object
         * @param targetGroup {Object} - the desired group to recieve the field
         * @param field {Object} - the field to be added to a group
         */
        addToGroup(targetGroup = { fields: [] }, field) {
            // add the field to the target group
            targetGroup.fields.push(field);
        },

        /**
         * Uses the page's JSON data to construct rows/containers of groups and properly
         * establish any nested groups relationships
         * @param model {Model} - page model that contains field/page data
         * @param fields {array} - fields to be added onscreen
         */
        createViewRowGroups(model, fields) {
            // exit early if there are no field containers
            if (util.isEmpty(model.jsonData.fieldContainerList)) {
                return undefined;
            }

            const containers = model.jsonData.fieldContainerList;
            let fieldColumnSpanInUse = false;

            /*
             * create a map object that contains groups with fields arrays
             * this contains all groups and sub-groups from "containers"
             */
            const allGroups = util.chain(containers)
                .map(container => container.groups)
                .flatten()
                .value()
                .reduce((acc, containerObject) => {
                    acc[containerObject.fieldGroupName] = containerObject;
                    acc[containerObject.fieldGroupName].fields = [];
                    return acc;
                }, {});

            // sort the array of all fields into their correct order before putting in groups
            fields.sort(compareFieldUIOrder);

            // assign individual fields to groups
            fields.forEach((field) => {
                if (field.fieldGroup) {
                    this.addToGroup(allGroups[field.fieldGroup], field);
                }
            });

            // should be false unless presented with a valid columnSpan from a field
            fieldColumnSpanInUse = fields.some(field => (field.columnSpan && field.columns !== '0'));

            // assign classes for each container/subgroup
            containers.forEach((containerParam) => {
                const container = containerParam;
                // account for improper numColumns (undefined, 0, etc)
                container.numColumns = container.numColumns || 1;

                // Column suffix is the Bootstrap "colspan".
                const columnSuffix = parseInt(
                    constants.ROW_GROUPS_MAX_COLUMNS / container.numColumns,
                    10,
                );
                container.classes = 'section';
                container.labelClasses = '';

                viewHelper.properlyNestSubgroups(container);
                viewHelper.columnCountPerRow(container);
                viewHelper.assignColumnClasses(container, columnSuffix, fieldColumnSpanInUse);
            });

            return containers;
        },

        get(options) {
            return options.footerForm ? new FooterForm(options) : new PageView(options);
        },
    },
};
