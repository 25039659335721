var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<!-- REVIEW: inline style seems to be the only way to set the width of a combobox -->\n<select name=\"group\" style=\"width: 200px\" placeholder=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.selectGroup",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":55},"end":{"line":3,"column":83}}}))
    + "\"></select>\n\n"
    + alias2((lookupProperty(helpers,"customizedIcon")||(depth0 && lookupProperty(depth0,"customizedIcon"))||container.hooks.helperMissing).call(alias1,{"name":"customizedIcon","hash":{"textVisible":true,"className":"btn btn-tertiary","action":"move-selected-accounts","locale":"gir.manageAccountGroups.moveSelectedAccounts","name":"logout"},"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":5,"column":166}}}))
    + "\n";
},"useData":true});