import axios from 'axios';
import CompositeView from '@glu/core/src/compositeView';
import locale from '@glu/locale';
import services from 'services';
import NotificationsCollection, { notificationHelper } from 'app/notifications/collection/notification';
import NotificationModel from 'app/notifications/models/notification';
import workspaceHelper from 'common/workspaces/api/helper';
import NotificationType from './notificationItem';
import notificationsViewTmpl from './notificationsView.hbs';

const notification = CompositeView.extend({
    template: notificationsViewTmpl,
    tagName: 'div',
    itemView: NotificationType,
    itemViewContainer: '.notifications-container',

    ui: {
        $loadingOverlay: '.notification-loading-overlay',
    },

    initialize() {
        this.setNotificationData({
            title: 'Notifications',
        });
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
        }
        this.notifyPageLoaded();
        const { title, type } = this?._notificationData; // eslint-disable-line no-underscore-dangle
        if (title === 'Notifications' && type === 'page:close') {
            this.ui.$loadingOverlay.hide();
        }
    },

    /**
     * @name createModelFromResponse
     * @description creates a NotificationModel from the input
     * @param {object} data
     * @param {number} counter
     * @returns NotificationModel
     */
    createModelFromResponse(data, counter) {
        let generatedUrl = notificationHelper.determineRoute(data);
        if (generatedUrl && data.viewId) {
            generatedUrl += data.viewId;
        }

        const obj = {
            notificationType: data.notificationType,
            count: data.count,
            subject: data.subject,
            message: data.text,
            evenRow: !(counter % 2),
            rowNum: counter,
            url: generatedUrl,
            hideCount: data.hideCount,
        };
        if (obj.url) {
            obj.urlText = locale.get('PS.Notifications.View');
        }

        if (obj.message) {
            obj.warning = true;
        }

        return new NotificationModel(obj);
    },

    /**
     * @name getNotificationTypesList
     * @description removes GLOBALBANNER & GROUPBANNER from the list
     * @param {array of notification types} data
     * @returns
     */
    getNotificationTypesList(data) {
        return data.filter(n => n.notificationType !== 'GLOBALBANNER'
            && n.notificationType !== 'GROUPBANNER');
    },

    /**
     * @name addNotification
     * @description add a notification to the collection
     * @param {object} notifications
     * @param {number} index
     * @param {number} groupBannerIndex - to loop over multiple group banners
     */
    addNotification(notifications, index, groupBannerIndex) {
        const modelIndex = groupBannerIndex ?? index;
        const notificationIndex = groupBannerIndex ?? 0;
        const model =
        this.createModelFromResponse(
            notifications[notificationIndex],
            modelIndex,
        );
        this.collection.add(model);
        this.addItemView(model, NotificationType, modelIndex);
    },

    onError(err) {
        this.ui.$loadingOverlay.addClass('hidden');
        window.console.log(`error getting notification type ${err}`);
    },

    dataLoaded() {
        this.setHasLoadedRequiredData(true);
        this.render();
    },

    loadRequiredData() {
        // get the list of entitled notifications
        this.collection = new NotificationsCollection();
        const url = services.generateUrl('notifications/getEntitledNotificationTypes');
        const notificationTypeUrl = services.generateUrl('notifications/getNotificationsByType');
        let firstLoaded = false;

        axios.get(url).then((resp) => {
            const notificationList = this.getNotificationTypesList(resp.data.notificationTypes);
            // first get any group banner notifications
            this.groupBannerNotification = resp.data.notificationTypes.find(x => x.notificationType === 'GROUPBANNER');
            return [notificationList, axios.post(notificationTypeUrl, {
                newOnly: false,
                notificationTypes: [
                    this.groupBannerNotification?.notificationType,
                ],
            })];
        }).then(([notificationList, notificationTypePromise]) => {
            notificationTypePromise.then((result) => {
                const notificationsArray = result.data.notifications;
                if (notificationsArray.length) {
                    // Display all client banners rather than just the first one
                    if (this.groupBannerNotification?.notificationType === 'GROUPBANNER') {
                        notificationsArray.forEach((n, groupBannerIndex) => {
                            this.addNotification(notificationsArray, 0, groupBannerIndex);
                        });
                    } else {
                        this.addNotification(notificationsArray, 0);
                    }
                    firstLoaded = true;
                    this.dataLoaded();
                }
                const lastCount = notificationList.length;
                if (lastCount === 0) {
                    this.dataLoaded();
                    this.ui.$loadingOverlay.addClass('hidden');
                    return Promise.resolve();
                }
                return Promise.all(notificationList.map((n, index) =>
                    axios.post(notificationTypeUrl, {
                        newOnly: false,
                        notificationTypes: [
                            n.notificationType,
                        ],
                    }).then((typeResult) => {
                        if (!typeResult.data.notifications.length) {
                            return;
                        }
                        this.addNotification(typeResult.data.notifications, index + 1);
                        if (!firstLoaded) {
                            firstLoaded = true;
                            this.dataLoaded();
                        }
                        if (index === lastCount - 1) {
                            this.ui.$loadingOverlay.addClass('hidden');
                        }
                    }).catch((err) => {
                        this.onError(err);
                    })));
            }).catch((err) => {
                this.onError(err);
            });
        }).catch((err) => {
            this.onError(err);
        });
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'NOTIFICATIONS',
    view: notification,
    options: {},
});

export default notification;
