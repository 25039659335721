import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from '@glu/tabs-react';
import PageHeader from 'components/PageHeader/PageHeader';
import ViewWrapper from 'react-wrappers/ViewWrapper';
import useLocale from 'hooks/useLocale';
import Alerts from 'app/alertContentManagement/alerts';
import Footers from 'app/alertContentManagement/footers';
import { Loading } from '@glu/indicators-react';
import store from 'system/utilities/cache';
import { hasAlertAccess } from 'components/AlertContentManagement/services';

const TAB = {
    ALERTS: 'ALERTS',
    FOOTERS: 'FOOTERS',
};

const ListWidget = () => {
    const { getLocaleString } = useLocale();
    const [tab, setTab] = useState(store.get('alertContentMangementListTab') || TAB.ALERTS);
    const [isLoading, setIsLoading] = useState(true);
    const [currentListView, setCurrentListView] = useState(new Alerts());
    const [alertsEntitled, setAlertsEntitled] = useState(false);
    const [footersEntitled, setFootersEntitled] = useState(false);
    const changeTab = (name, value) => {
        setTab(value);
        store.set('alertContentMangementListTab', value);
    };
    useEffect(() => {
        Promise.all([
            hasAlertAccess('alerts'),
            hasAlertAccess('footers'),
        ]).then(([hasAlertsResult, hasFootersResult]) => {
            const hasAlerts = hasAlertsResult.entitlements.reduce((acc, cur) => {
                if (cur.entitled) {
                    return true;
                }
                return acc;
            }, false);
            const hasFooters = hasFootersResult.entitlements.reduce((acc, cur) => {
                if (cur.entitled) {
                    return true;
                }
                return acc;
            }, false);
            setIsLoading(false);
            if (!hasAlerts && hasFooters) {
                setTab(TAB.FOOTERS);
            }
            setAlertsEntitled(hasAlerts);
            setFootersEntitled(hasFooters);
        });
    }, []);
    useEffect(() => {
        const ListView = tab === TAB.ALERTS ? Alerts : Footers;
        /*
         * Instantiate a new list view, should the tab change.
         * This is required so that all grid listeners and
         * regions are rendered properly each time the tab
         * is changed. Store the view in state, so that it
         * is not recreated with each render.
         */
        setCurrentListView(new ListView());
    }, [tab]);
    return (
        <>
            <PageHeader
                headingText={getLocaleString('CMAINT.Alert.Content.Management')}
            />
            <Loading isLoading={isLoading}>
                <div className="section section-container">
                    <Tabs value={tab}>
                        {alertsEntitled
                            && (
                                <Tab value={TAB.ALERTS} onChange={changeTab}>
                                    {getLocaleString('CMAINT.Alerts')}
                                </Tab>
                            )}
                        {footersEntitled
                            && (
                                <Tab value={TAB.FOOTERS} onChange={changeTab}>
                                    {getLocaleString('CMAINT.Footers')}
                                </Tab>
                            )}
                    </Tabs>
                    <ViewWrapper view={currentListView} />
                </div>
            </Loading>
        </>
    );
};

export default ListWidget;
