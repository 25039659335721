import alertMessage from 'common/api/alertMessage';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import util from '@glu/core/src/util';
import alert from '@glu/alerts';
import locale from '@glu/locale';
import constants from 'app/administration/constants';
import scroll from 'common/util/scroll';
import store from 'system/utilities/cache';
import Layout from '@glu/core/src/layout';
import systemConfig from 'system/configuration';
import AdminUtil from 'app/administration/common/util';
import PermissionTabs from './permissions/permissionTabs';
import AccountTabs from './accounts/accountTabs';
import LimitTabs from './limits/limitTabs';
import template from './baseStepLayout.hbs';

export default Layout.extend({
    template,
    loadingTemplate,
    className: 'uce',
    events: {},

    ui: {
        $stepsNamed: '[data-hook="getStepsNamed"]',
        $stepsNamedItems: '[data-hook="getStepsNamed"] .StepsNamed-item',
        displayInfo: '[data-hook="displayInfo"]',
        $previousBtn: '[data-action="prev"]',
        $nextBtn: '[data-action="next"]',
        $save: '[data-hook="saveBtn"]',
    },

    regions: {
        stepRegion: '[data-hook="stepRegion"]',
        alertRegion: '[data-hook="alertRegion"]',
    },

    STEP_MAP: {
        PERM: 1,
        ACCT: 2,
        LIMT: 3,
    },

    /**
     * @abstract
     */
    initialize() {

    },

    /**
     * @name init
     * @description common initialization method
     * @param {object} opts - initialization options
     * @param {object} model - user/role model
     */
    init(opts, model) {
        this.mode = opts.mode;
        this.userGroup = opts.userGroup;
        this.isAdmin = systemConfig.isAdmin();
        this.isDeepLink = opts.deeplinked || false;
        this.isCannotGrantBeyondOwnUser = opts.isCannotGrantBeyondOwnUser;
        this.showGenericLimitsError = false;
        this.returnURL = opts.returnRoute;
        if (this.returnURL) {
            store.set('current-workspace-route', this.returnURL);
        }
        if (model) {
            this.listenTo(model, 'invalid', scroll.scrollToFirstError);
        }
    },

    onRender() {
        this.toggleLoading(true);
        if (this.hasLoadedRequiredData()) {
            if (this.profileModel) {
                this.initSteps();
                this.changeStep(this.currentStep);
                this.additionalRender();
            }
        } else {
            this.load();
        }
    },

    /**
     * @abstract
     */
    additionalRender() {

    },

    renderMessage(alertResponse) {
        const confirmResponse = alertResponse.response;
        if (typeof confirmResponse === 'object' && confirmResponse.message.length > 0) {
            /*
             * handles a response on this detail page which is normally displayed on list view.
             * This occurs when deep linking directly to the modify page.
             */
            alertMessage.renderMessage(this, alertResponse.action, confirmResponse);
        } else {
            // handles a normal detail response
            this.alertRegion.show(alert.success(alertResponse));
        }
    },

    /**
     * Sets the header at the top of the page which displays the user id and user
     * name combination
     * @param {string} id- the user/role id
     * @param {string} name - the user/role name
     */
    displayHeader(id, name) {
        this.ui.displayInfo.text(`${id} | ${name}`);
    },

    /**
     * @abstract
     */
    initStepOne() {

    },

    initStepTwo() {
        return new PermissionTabs({
            copyFromUser: this.copyUserId,
            model: this.model,
            mode: this.mode,
            isUce: true, // defaults to uce
        });
    },

    initStepThree() {
        return new AccountTabs({
            model: this.model,
            mode: this.mode,
        });
    },

    initSteps() {
        this.steps = [
            this.initStepOne(),
            this.initStepTwo(),
            this.initStepThree(),
            new LimitTabs({
                model: this.model,
                mode: this.mode,
                isCannotGrantBeyondOwnUser: this.isCannotGrantBeyondOwnUser,
            }),
        ];
    },

    /**
     * @abstract
     */
    load() {
    },

    /**
     * @param {boolean} [loading]
     * Will stop or re-enable form actions if user data is currenlty being loaded
     */
    toggleLoading(loading) {
        let localLoading = loading;
        localLoading = (localLoading !== undefined) ? localLoading : true;
        // TODO: replace with common loading indicator approach instead
        this.ui.$save.attr('disabled', localLoading);
        this.ui.$nextBtn.attr('aria-busy', localLoading);
        this.ui.$stepsNamedItems.toggleClass('disabled', localLoading);
    },
    prev() {
        // Never show step 0 (users) for deep link.
        const stepNumber = this.currentStep;
        if (stepNumber > (!this.isProfileTabRequired ? 1 : 0)) {
            this.changeStep(stepNumber - 1);
        }
    },

    next() {
        const stepNumber = this.currentStep;
        if (stepNumber < this.steps.length - 1) {
            this.changeStep(stepNumber + 1);
        }
    },

    /**
     * @abstract
     * save the user/role
     */
    save() {
    },

    beforeSave() {
        const promises = [];
        util.each(this.steps, (step) => {
            if (util.isFunction(step.beforeSave)) {
                promises.push(step.beforeSave());
            }
        });

        return Promise.all(promises);
    },

    /**
     * @abstract
     */
    cancel() {
    },

    /**
     * @abstract
     */
    modify() {
    },

    /**
     * Next five functions stolen from
     * src/app/administration/views/userMaintenance/userLayout.js
     */
    // TODO fix to work for both users/roles
    delete() {
        this.doAction(constants.ACTIONS.DELETE);
    },

    disable() {
        this.doAction(constants.ACTIONS.DISABLE);
    },

    approve() {
        this.doAction(constants.ACTIONS.APPROVE);
    },

    restore() {
        this.doAction(constants.ACTIONS.RESTORE);
    },

    doAction(action, responseStore = 'addUserCentricSuccessMessage') {
        this.profileModel[action]({
            success: (model, response) => {
                this.doActionResponse(action, response, responseStore);
            },

            error: (model, response) => {
                this.doActionResponse(
                    action,
                    model.message ? JSON.parse(model.message) : response,
                    responseStore,
                );
            },
        });
    },

    doActionResponse(action, response, responseStore) {
        const alertResponse = {
            action,
            response,
        };
        if (this.isDeepLink) {
            this.renderMessage(alertResponse);
        } else {
            store.set(responseStore, alertResponse);
            this.navigateTo(this.options.returnRoute);
        }
    },

    goStep(e) {
        if (this.hasLoadedRequiredData()) {
            this.changeStep(this.$(e.currentTarget).data('step'));
        }
    },

    changeStep(stepNumber) {
        if (this.steps && this.hasLoadedRequiredData()) {
            if (stepNumber === this.firstStep) {
                this.ui.$previousBtn.hide();
            } else {
                this.ui.$previousBtn.show();
            }

            this.stepRegion.show(this.steps[stepNumber]);

            this.ui.$stepsNamed.find('.is-active').removeClass('is-active').removeAttr('aria-current');

            // For deep links we need to adjust the number to set active.
            // adding aria-current attribute to the step when step changes
            const stepIndex = stepNumber < this.firstStep ?
                stepNumber : stepNumber - this.firstStep;
            this.ui.$stepsNamedItems.eq(stepIndex).addClass('is-active').attr('aria-current', 'step');

            if (stepNumber === this.STEP_MAP.LIMT) {
                this.ui.$nextBtn.hide();
            } else {
                this.ui.$nextBtn.show();
            }

            this.alertRegion.close();

            this.currentStep = stepNumber;
        }
    },

    /**
     * When save is pressed, validates the limits of
     * a CGBO user against the logged in user.
     * @return {boolean}
     * Returns true if limits are valid
     */
    limitsValid() {
        // Don't validate tab 4 if copied from user and tab 4 never visited.
        if (this.options.copyFromUser
                && this.steps[this.STEP_MAP.LIMT].isLimitTabNotRendered()) {
            return true;
        }
        let isValid = this.steps[this.STEP_MAP.LIMT].validateLimitsOnSave();
        if (isValid) {
            isValid = this.steps[this.STEP_MAP.LIMT].validateAllOverallLimits();
            if (!isValid) {
                this.showGenericLimitsError = true;
            }
        }

        return isValid;
    },

    templateHelpers() {
        return {
            isTabOneRequired: true,
            readOnly: this.mode === constants.MODES.VIEW,
            modify: this.mode === constants.MODES.MODIFY,
            isDeepLinked: this.isDeepLink,
            buttons: this.buttons,
            insert: this.mode === constants.MODES.INSERT,
            isBankMaintained: AdminUtil.isBankMaintainedRolesEnabled() && this.bankMaintained,
            permissionStep: 2,
            accountStep: 3,
            limitStep: 4,
            step1Label: locale.get('uce.define-user'),
        };
    },
});
