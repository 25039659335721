import Collection from '@glu/core/src/collection';
import services from 'services';
import http from '@glu/core/src/http';
import $ from 'jquery';
import transform from 'common/util/transform';
import Model from '@glu/core/src/model';

const RulesCollection = Collection.extend({
    model: Model,

    sync(method, modelParam, options) {
        const model = modelParam;
        if (method === 'read') {
            http.post(services.generateUrl('/passwordRules/getListView'), {}, (result) => {
                options.success(result.rows[0]);
            }, (response) => {
                model.error = JSON.parse(response.responseText);
                options.error(model, {});
            });
        }
    },

    parse(response) {
        this.passwordRules = transform.pairsToHash(response.columns, 'fieldName', 'fieldValue');

        const array = [];

        delete this.passwordRules.ID;
        delete this.passwordRules.MODIFIEDBY;
        delete this.passwordRules.MODIFIEDBYTIMESTAMP;
        delete this.passwordRules.ENTEREDBY;
        delete this.passwordRules.ENTEREDBYTIMESTAMP;
        delete this.passwordRules.USERGROUP;

        Object.keys(this.passwordRules || {}).forEach((rule) => {
            if (this.passwordRules[rule] === '0') {
                this.convertStringToBoolean(rule);
            }
            if (rule === 'SPECIALCHARS') {
                this.fixUnicodeCharacters(rule);
            }
            array.push({
                name: rule,
                value: this.passwordRules[rule],
            });
        });
        this.passwordRules.RULES = array;

        return this.passwordRules;
    },

    convertStringToBoolean(key) {
        this.passwordRules[key] = false;
    },

    fixUnicodeCharacters(key) {
        const str = this.passwordRules[key];
        this.passwordRules[key] = $('<div/>').html(str).text();
    },
});

export default RulesCollection;
