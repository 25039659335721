var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog glu-dialog\">\n    <form class=\"modal-content\" data-submit=\"save\">\n        <div class=\"modal-header\">\n                        "
    + alias2(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"close","action":"close","locale":"cashflow.plan.saveas.close","name":"close"},"data":data,"loc":{"start":{"line":4,"column":24},"end":{"line":4,"column":115}}}))
    + "\n            <h2 class=\"modal-title\" id=\"modal-title\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.planning.saveas",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":53},"end":{"line":5,"column":90}}}))
    + "</h2>\n        </div>\n        <div class=\"modal-body\">\n            <div class=\"form-group\">\n                <label for=\"name\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.plan.name",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":34},"end":{"line":9,"column":65}}}))
    + "</label>\n                <input id=\"name\" class=\"form-control\" data-hook=\"plan-name\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":10,"column":83},"end":{"line":10,"column":91}}}) : helper)))
    + "\">\n                <span class=\"help-block\" data-hook=\"name-hint\"></span>\n            </div>\n        </div>\n        <div class=\"modal-footer\">\n            <button class=\"btn btn-primary\" data-hook=\"save-as-new\" data-loader aria-busy=\"false\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.saveas.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":98},"end":{"line":15,"column":130}}}))
    + "</button>\n            <button class=\"btn btn-primary\" data-hook=\"save-as-snapshot\" data-loader aria-busy=\"false\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.save.snapshot.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":103},"end":{"line":16,"column":142}}}))
    + "</button>\n            <button class=\"btn btn-tertiary\" data-dismiss=\"modal\" data-loader aria-busy=\"false\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.cancel.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":96},"end":{"line":17,"column":128}}}))
    + "</button>\n        </div>\n    </form>\n</div>\n";
},"useData":true});