import NestedModel from '@glu/core/src/nestedModel';
import http from '@glu/core/src/http';
import services from 'services';
import ComplexTypeGroups from '../../collection/user/complexTypeGroups';
import SimpleTypeGroups from '../../collection/user/simpleTypeGroups';
import SimpleDataEntitlements from '../../collection/user/simpleDataEntitlements';

export default NestedModel.extend({

    initialize(obj) {
        this.set(this.parse(obj));
    },

    defaults() {
        return {
            complexTypes: new ComplexTypeGroups(),
            simpleTypes: new SimpleTypeGroups(),
            masterTabCode: '',
            masterTabLabel: '',
            simpleDataEntitlements: new SimpleDataEntitlements(),
        };
    },

    parse(response) {
        const { simpleDataEntitlements } = response;
        response.complexTypes = new ComplexTypeGroups(response.complexTypes);
        response.simpleTypes = new SimpleTypeGroups(response.simpleTypes);
        response.simpleDataEntitlements = new SimpleDataEntitlements(simpleDataEntitlements);
        return response;
    },

    aggregateToTypes(success) {
        http.post(
            services.generateUrl(`/users/userCentric/getPermissionsByType/${this.userGroupModel.get('id')}`),
            this.toJSON(),
            (response) => {
                this.set(this.parse(response));
                success();
            },
        );
    },

    getSelectedComplexTypes() {
        return new ComplexTypeGroups(this.get('complexTypes').filter(group => group.hasEntitledType()));
    },

    hasSelectedComplexType() {
        return this.get('complexTypes').some(group => group.hasEntitledType());
    },

    hasSimpleEntitlement() {
        return this.get('simpleTypes').some(group => group.isSimpleEntitled());
    },

    hasSimpleActionEntitlement() {
        return this.get('simpleTypes').some(group => group.isEntitled());
    },
});
