import $ from 'jquery';
import constants from 'common/dynamicPages/api/constants';
import http from '@glu/core/src/http';
import Layout from '@glu/core/src/layout';
import services from 'services';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import PaymentUtil from 'common/util/paymentUtil';
import orderingPartyUtil from 'common/util/orderingPartyLookupUtil';
import { triggerMaskedInputChanged } from 'common/dynamicPages/views/mdf/mdfUtil';
import { getMaskingUITypes } from 'common/util/maskingUtil';
import template from './beneUpdatedWarning.hbs';

export default Layout.extend({
    template,

    initialize(options) {
        const hasOrderingPartyConfig = orderingPartyUtil.isConfigured();

        this.model = options.model;
        this.contactUpdated = options.contactHasBeenUpdatedValue === 'YES'
            || options.contactHasBeenUpdatedValue === 'INVALID';
        this.contactIsInvalid = options.contactHasBeenUpdatedValue === 'INVALID';
        this.orderingPartyUpdated = hasOrderingPartyConfig
            && (options.orderingPartyUpdatedValue === 'YES'
            || options.orderingPartyUpdatedValue === 'INVALID');
        this.orderingPartyIsInvalid = hasOrderingPartyConfig
            && options.orderingPartyUpdatedValue === 'INVALID';
        this.inavlidBeneficiaries = options.inavlidBeneficiaries;
        this.formView = options.formView;
        this.isRTGS = this.model.get('PRODUCT') === 'RTGS';
        this.isRTP = this.model.get('PRODUCT') === 'RTP' && this.model.get('TYPE') !== 'REQOUT';
        this.isRFP = this.model.get('PRODUCT') === 'RTP' && this.model.get('TYPE') === 'REQOUT';
    },

    onRender() {

    },

    attributes: {
        role: 'dialog',
        tabindex: '-1',
        'aria-hidden': 'false',
        class: 'modal',
        'data-backdrop': 'static',
    },

    save() {
        this.close();

        if (this.isRTGS) {
            if (this.contactIsInvalid) {
                this.removeBeneInfo();
            } else if (this.contactUpdated) {
                this.updateBeneInfo();
            }

            if (this.orderingPartyIsInvalid) {
                PaymentUtil.clearOrderingParty(this.model);
            } else if (this.orderingPartyUpdated) {
                this.updateOrderPartyInfo();
            }
        } else if (this.isRTP || this.isRFP) {
            if (this.contactIsInvalid) {
                this.removeBeneInfo();
            } else {
                this.updateBeneInfo();
            }
        } else {
            this.makeBulkRequestACH('CONTACTUPDATE');
        }
    },

    cancel() {
        this.close();

        if (this.isRTGS) {
            if (this.orderingPartyUpdated) {
                PaymentUtil.clearOrderingPartyLink(this.model);
            }
            if (this.contactUpdated) {
                this.removeContactLink();
            }
        } else if (this.isRTP || this.isRFP) {
            this.removeContactLink();
        } else {
            this.makeBulkRequestACH('REMOVECONTACTLINK');
        }
    },

    /*
     * Make a bulk update request that either updates each affected beneficiary
     * with the latest values
     * from the contact, or breaks the link between beneficiary and contact.
     * @param {String} action - CONTACTUPDATE: update the bene's,
     * REMOVECONTACTLINK: remove link between bene and contact
     */
    makeBulkRequestACH(action) {
        const self = this;
        const bulkUpdateUrl = services.generateUrl(this.model.context.serviceName)
             + constants.URL_BULK_UPDATE;
        const data = {
            gridName: this.options.model.jsonData.childGridName,
            entryClass: this.model.get('ENTRYCLASS'),
            requests: [{ action: 'bulkUpdate', actionType: action, value: 1 }],
        };

        http.post(bulkUpdateUrl, data).then(() => {
            self.trigger('refreshGrid');
        });
    },

    /*
     * The user has chosen to not accept the changes that have been made to the
     * contact used in this payment.
     * Because of this the link between the contact and payment is broken.
     */
    removeContactLink() {
        this.model.set({
            BENEBOOK_ID: '',
            BENEBOOKCHILD_ID: '',
            BENEBOOKUPDATECOUNT__: '',
            CONTACTHASBEENUPDATED: 'NO',
        });
    },

    /*
     * The user accepted the warning stating that the contact is no longer valid.
     * This means the link between the contact and payment is broken and information
     * is removed.
     */
    removeBeneInfo() {
        let configurationObject = {
            BENEBOOK_ID: '',
            BENEBOOKCHILD_ID: '',
            BENEBOOKUPDATECOUNT__: '',
            CONTACTHASBEENUPDATED: 'NO',
            BENE_NAME: '',
            BENE_ADDRESS_1: '',
            BENE_ADDRESS_2: '',
            BENE_COUNTRY: '',
            BENE_CITY: '',
            BENE_PROVINCE: '',
            BENE_STATE: '',
            BENE_POSTALCODE: '',
            BENE_BANK_ID: '',
            BENE_ACCOUNT: '',
            BENE_BANK_NAME: '',
            BENE_BANK_CITY: '',
            BENE_BANK_STATE: '',
            BENE_BANK_TYPE: '',
            BENEBANKIDENTRYMETHOD: '',
            BENE_BANK_ACCOUNT_NUMBER: '',
            BENE_BANK_ADDRESS_1_VIRTUALFIELD: '',
            BENE_BANK_ADDRESS_2_VIRTUALFIELD: '',
            BENE_BANK_CITY_VIRTUALFIELD: '',
            BENE_BANK_ACCOUNT_NUMBER_VIRTUALFIELD: '',
            BENE_BANK_COUNTRY: '',
            BENE_ACCOUNT_TYPE: '',
            TRANSACTION_CURRENCY: '',
            CMB_BENE_ACCOUNT: '',
            CMB_BENE_BANK_ID: '',
            CMB_BENE_NAME: '',
            CMB_CREDIT_CURRENCY: '',
            CMB_BENE_BANK_NAME: '',
            CMB_TRANSACTION_CURRENCY: '',
            CMB_BENE_ID: '',
            BENE_BIRTH_DATE: '',
            BENE_BIRTH_CITY: '',
            BENE_BIRTH_COUNTRY: '',
            LEGAL_ENTITY_ID: '',
            BENE_NOTIFICATION_EMAIL: '',
            BENE_NOTIFICATION_EMAIL2: '',
            BENE_NOTIFICATION_EMAIL3: '',
            BENE_NOTIFICATION_EMAIL4: '',
            BENE_NOTIFICATION_EMAIL5: '',
        };

        this.model.set('BENE_BANK_ID', 'Other');

        /*
        * HACK: This blanket clearing of bene information
        * is causing issues for Bill Pay payments as it
        * is clearing some fields which are not part of
        * the bene, but are in fact detail which is part
        * of the originator debit information (NH-182376).
        * The "IsNotFedwire" section below has existed since
        * 2020 and was likely put in place to stop a similar
        * issue with Fedwire.
        *
        * I've piggybacked onto this code, but at some point
        * this section should be revisited to ensure proper
        * fields are being cleared (or not cleared) for each
        * payment type.
        */
        const modelTypeIsNotFedwire = this.model.get('TYPE') !== 'FEDWIRE';
        const modelTypeIsNotBillPay = this.model.get('TYPE') !== 'BPAY';

        if (modelTypeIsNotFedwire && modelTypeIsNotBillPay) {
            configurationObject = {
                ...configurationObject,
                DEBIT_BANK_CODE: 'BLANK',
                DEBIT_BANKNAME: '',
                ORIGINATOR_CITY: '',
                ORIGINATOR_COUNTRY: '',
                ORIGINATOR_NAME: '',
                ORIGINATOR_POSTALCODE: '',
                ORIGINATOR_PROVINCE: '',
                ORIGINATOR_STATE: '',
                ORIGINATOR_ADDRESS_1: '',
                ORIGINATOR_ADDRESS_2: '',
                DEBTOR_LEI_ID: '',
                DEBTOR_BIRTH_DATE: '',
                DEBTOR_CITY_OF_BIRTH: '',
                DEBTOR_COUNTRY_OF_BIRTH: '',
                DEBIT_ACCOUNT_NUMBER: (this.isRTP && this.model.get('FUNCTION') === 'INST') ?
                    this.model.get('DEBIT_ACCOUNT_NUMBER') : '',
            };
        }

        this.model.set(configurationObject);

        /*
         * DEBIT_BANK_CODE is a type ahead field type, simply setting it to empty won't work
         * Setting it to BLANK and then to empty does the trick
         */
        if (modelTypeIsNotFedwire && modelTypeIsNotBillPay) {
            this.model.set('DEBIT_BANK_CODE', '');
            this.formView.trigger('lookupHelperText:update', ('DEBIT_BANK_CODE'));
        }
        this.formView.trigger('lookupHelperText:clear', ('BENE_BANK_ID'));
        $('#BENE_ACCOUNT').val('');

        // if a datepicker exists, trigger an update to the datepicker's cutoff helper text
        if (this.formView.$el.find('.ui-datepicker-trigger')) {
            PaymentUtil.updateCutoff(
                this.model.get('PRODUCT'),
                this.model.get('FUNCTION'),
                this.model.get('TYPE'),
                '*',
                this.model.context.actionMode,
                this.model,
                this.formView.$el.find('.ui-datepicker-trigger'),
            );
        }

        /*
         * in rare instances, a field is defined by meta-data and does not have a corresponding
         * model value. This causes view binding to fail and these fields must be manually
         * updated
         */
        this.formView.$el.find('[data-text="BENE_ADDRESS"]').text('');
    },

    /*
     * The user has accepted any changes that have been made to the contact used in this
     * payment. Pull in the contact data as if it was re-selected.
     */
    updateBeneInfo() {
        const url = services.generateUrl(constants.URL_GETLOOKUPRESULTS_ACTION);
        const data = this.getData();

        http.post(url, data).then((result) => {
            const maskedUITypes = getMaskingUITypes();
            const modelSetList = {
                CONTACTHASBEENUPDATED: 'NO',
            };
            const { lookupHelperText, fieldData } = this.model;
            const potentialHelperTextFields = lookupHelperText
                ? Object.keys(lookupHelperText) : [];
            const helperTextFields = [];
            let updateMaskedInputField = false;

            if (!result.rows || !result.rows[0]) {
                this.removeBeneInfo();
            } else {
                const { mapDataList } = result.rows[0];
                mapDataList.forEach((mapData) => {
                    const toField = mapData.toField.toUpperCase();
                    modelSetList[toField] = mapData.value;

                    /*
                     * When updating the beneficiary details, if any of the mapped fields are for
                     * an on screen field with account masking, and that field's value is updated,
                     * then we will need to refresh the displayed masked input widgets to assure
                     * that the values shown match the values on the model.
                     */
                    if (toField in fieldData) {
                        const { fieldUIType } = fieldData[toField];
                        if (!updateMaskedInputField
                            && maskedUITypes.includes(fieldUIType)
                            && this.model.get(toField) !== mapData.value) {
                            updateMaskedInputField = true;
                        }
                    }

                    if (potentialHelperTextFields.includes(toField)) {
                        helperTextFields.push(toField);
                    }
                });
            }

            this.model.set(modelSetList);

            if (updateMaskedInputField) {
                triggerMaskedInputChanged(this.model);
            }

            helperTextFields.forEach((lookupField) => {
                this.formView.trigger('lookupHelperText:update', lookupField);
            });
        });
    },

    /*
     * The user has accepted any changes that have been made to
     * the order party contact used in this payment.
     * Pull in the order party contact data as if it was re-selected.
     */
    updateOrderPartyInfo() {
        const url = services.generateUrl(constants.URL_GETLOOKUPRESULTS_ACTION);
        const data = this.getData('ORDERPARTY');

        http.post(url, data).then((result) => {
            const modelSetList = {
                ORDERINGPARTYCONTACTUPDATED: 'NO',
            };
            if (!result.rows || !result.rows[0]) {
                PaymentUtil.clearOrderingParty(this.model);
            } else {
                const { mapDataList } = result.rows[0];
                mapDataList.forEach((mapData) => {
                    const toField = mapData.toField.toUpperCase();
                    modelSetList[toField] = mapData.value;
                });
            }

            this.model.set(modelSetList);
            triggerMaskedInputChanged(this.model);
        });
    },

    getData(LookupType) {
        const beneChildId = this.model.get('BENEBOOKCHILD_ID') || '1';

        if (LookupType === 'ORDERPARTY') {
            return {
                rowsPerPage: 1,
                startRow: 1,
                fieldName: 'ORDEROF_NAME',
                typeCode: this.model.get('TYPE'),
                productCode: this.model.get('PRODUCT'),
                functionCode: 'INST',
                entryClass: '*',
                depends: [],

                searchFields: [{
                    fieldValue: [this.model.get('ORDERINGPARTY_BOOK_ID')],
                    fieldName: 'TNUM',
                    dataType: 'TEXT',
                    operator: 'EQ',
                }],
            };
        }
        return {
            rowsPerPage: 1,
            startRow: 1,
            fieldName: this.isRFP ? 'ORIGINATOR_NAME' : 'BENE_NAME',
            typeCode: this.model.get('TYPE'),
            productCode: this.model.get('PRODUCT'),
            functionCode: this.isRFP ? 'REQUEST' : 'INST',
            entryClass: '*',
            depends: (this.isRFP && !util.isEmpty(this.model.get('PAYER_TYPE')) && this.model.get('TYPE') === 'REQOUT') ?
                [{ name: 'PAYER_TYPE', value: this.model.get('PAYER_TYPE') }] : [],

            searchFields: [{
                fieldValue: [this.model.get('BENEBOOK_ID')],
                fieldName: 'PARENTTNUM',
                dataType: 'TEXT',
                operator: 'EQ',
            }, {
                fieldValue: [beneChildId],
                fieldName: 'CHILD_TNUM',
                dataType: 'TEXT',
                operator: 'EQ',
            }],
        };
    },

    getModalTitle() {
        if (this.isRFP) {
            return 'PAY.rfpBeneUpdateTitle';
        }
        if (this.isRTGS) {
            return this.orderingPartyUpdated
                ? 'PAY.rtgsContactUpdateTitle' : 'PAY.rtgsBeneUpdateTitle';
        }
        return 'PAY.achBeneUpdateTitle';
    },

    templateHelpers() {
        return {
            isRTGS: this.isRTGS,
            isRTP: this.isRTP,
            isRFP: this.isRFP,
            contactUpdated: this.contactUpdated,
            contactIsInvalid: this.contactIsInvalid,
            orderingPartyUpdated: this.orderingPartyUpdated,
            orderingPartyIsInvalid: this.orderingPartyIsInvalid,
            inavlidBeneficiaries: this.inavlidBeneficiaries,
            modalTitle: locale.get(this.getModalTitle()),
        };
    },
});
