import util from '@glu/core/src/util';

const globalSettingsObj = {
    updateGlobalSettingInput(model, $input, setting) {
        const globalSettings = model.get('globalSettings');
        if (globalSettings[setting]) {
            globalSettings[setting].value = true;
        }
        if ($input) {
            $input.prop('checked', true);
            $input.prop('disabled', true);
            $input.addClass('read-only-field');
        }
    },

    getSettingBooleanValue(model, setting) {
        const userCompany = model.userGroupModel;
        const field = util.findWhere(
            userCompany.get('mapDataList'),
            {
                toField: setting,
            },
        );
        return field && field.value && field.value === '1';
    },

    populateBeneAddressBookSettings(model, $paymentCheckbox, $templateCheckbox) {
        const reqForPayments = this.getSettingBooleanValue(model, 'CompanyRequireBeneFromAdressBookForPayments');
        const reqForTemplates = this.getSettingBooleanValue(model, 'CompanyRequireBeneFromAdressBookForTemplates');

        if (reqForPayments) {
            this.updateGlobalSettingInput(model, $paymentCheckbox, 'BENEPAY');
        }

        if (reqForTemplates) {
            this.updateGlobalSettingInput(model, $templateCheckbox, 'BENETEMP');
        }
    },
};

export default globalSettingsObj;
