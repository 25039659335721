var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"\n                                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPositive") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":32},"end":{"line":6,"column":69}}})) != null ? stack1 : "")
    + "\n                                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNegative") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":32},"end":{"line":7,"column":68}}})) != null ? stack1 : "")
    + "\n                        \">\n                                "
    + container.escapeExpression(lookupProperty(helpers,"money").call(alias1,(depth0 != null ? lookupProperty(depth0,"amount") : depth0),(depth0 != null ? lookupProperty(depth0,"preferredCurrencyFormat") : depth0),{"name":"money","hash":{},"data":data,"loc":{"start":{"line":9,"column":32},"end":{"line":9,"column":72}}}))
    + "\n\n                                <span class=\"\n                                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPositive") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":40},"end":{"line":12,"column":80}}})) != null ? stack1 : "")
    + "\n                                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNegative") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":40},"end":{"line":13,"column":82}}})) != null ? stack1 : "")
    + "\n                                \"></span>\n                        </span>\n\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "text-success";
},"4":function(container,depth0,helpers,partials,data) {
    return "text-danger";
},"6":function(container,depth0,helpers,partials,data) {
    return " icon-up-arrow ";
},"8":function(container,depth0,helpers,partials,data) {
    return " icon-down-arrow ";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <i>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"cashflow.no.transactions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":27},"end":{"line":19,"column":64}}}))
    + "</i>\n\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"line-indicator\" title=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"lineName") || (depth0 != null ? lookupProperty(depth0,"lineName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"lineName","hash":{},"data":data,"loc":{"start":{"line":29,"column":60},"end":{"line":29,"column":72}}}) : helper)))
    + "\">&#9679;</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"view-mode\">\n        <div class=\"name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":2,"column":26},"end":{"line":2,"column":34}}}) : helper)))
    + "</div>\n        <div class=\"transaction-summary\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasTransactions") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":21,"column":23}}})) != null ? stack1 : "")
    + "\n        </div>\n\n        "
    + alias4(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"btn btn-link show-transactions","action":"","locale":"cashflow.scenario.view","name":"eye"},"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":25,"column":113}}}))
    + "\n\n        <div class=\"line-indicators\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"lines") : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":16},"end":{"line":30,"column":25}}})) != null ? stack1 : "")
    + "        </div>\n\n</div>\n\n<div class=\"edit-mode hidden\">\n                <label class=\"sr-only\" for=\"rename-scenario-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":36,"column":60},"end":{"line":36,"column":66}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"cashflow.scenario.rename",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":68},"end":{"line":36,"column":105}}}))
    + " \""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":36,"column":107},"end":{"line":36,"column":115}}}) : helper)))
    + "\"</label>\n                <input type=\"text\" id=\"rename-scenario-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":37,"column":55},"end":{"line":37,"column":61}}}) : helper)))
    + "\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":37,"column":70},"end":{"line":37,"column":78}}}) : helper)))
    + "\" class=\"rename-scenario\">\n\n                "
    + alias4(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"btn btn-link remove","action":"","locale":"cashflow.scenario.delete","name":"close"},"data":data,"loc":{"start":{"line":39,"column":16},"end":{"line":39,"column":114}}}))
    + "\n\n\n</div>\n";
},"useData":true});