// PCM CHANGES ON HOLE FILE - BE AWARE ON DGB MERGE
import store from 'system/utilities/cache';
import util from '@glu/core/src/util';
import dialog from '@glu/dialog';
import ListView from 'common/dynamicPages/views/workflow/list';
import workspaceHelper from 'common/workspaces/api/helper';
import userInfo from 'etc/userInfo';
// import constants from 'app/administration/constants';
import lvcConstants from 'common/util/listViewConfig/listViewConfigConstants';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import AddRoleDialog from './addRoleDialog';
import template from './list.hbs';

const RolesList = ListView.extend({
    template,

    regions: {
        alertRegion: '.alert-region',
    },

    initialize(options) {
        this.isSMB = userInfo.get('marketSegment') === 'SMB';
        this.isAdmin = (window.Bottomline.appRoot === 'ui-admin' || window.Bottomline.appRoot === 'ui-admin-portal');

        this.addUserGroupFilter(options);
        const superOptions = {
            menuCategory: 'ADMIN',
            serviceName: '/roleMaintenance/role',
            serviceFunc: null,
            businessType: null,
            context: this.isAdmin ? 'ADMIN_ROLE' : 'USER_ENTITLEMENT',
            returnRoute: options.returnRoute || 'ADMINISTRATION/viewRoles',
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));

        /* when coming from a user group admin page or the role menu item,
           clear out grid state, not on drill down and not for client */
        if (this.isAdmin && (this.usergroup || options.fromMenu)) {
            this.lvc.reset();
        }

        /* if there are additional search fields for server side filters,
           then store in listView config (grid state)
         */
        if (options.additionalSearchFields) {
            this.lvc.set(lvcConstants.ADDITIONALSEARCH, options.additionalSearchFields);
        }
    },

    /*
     * TODO refactor so that this returns a new options object
     *  rather than update the input options
     */
    addUserGroupFilter(optionsParam) {
        const options = optionsParam;
        let viewIdIndex;
        if (options.returnRoute && options.returnRoute.indexOf('ADMINSTRATION/viewRoles/') !== -1) {
            viewIdIndex = options.returnRoute.lastIndexOf('^viewId=');
            if (viewIdIndex !== -1) {
                this.usergroup = options.returnRoute.substring(options.returnRoute.lastIndexOf('/') + 1, viewIdIndex);
                options.viewId = options.returnRoute.substring(viewIdIndex + 8);
            } else {
                this.usergroup = options.returnRoute.substring(options.returnRoute.lastIndexOf('/') + 1);
            }

            options.additionalSearchFields = [{
                fieldName: 'USERGROUP',
                operator: 'EQ',
                fieldValue: [this.usergroup],
                dataType: 'text',
            }];
        }
    },

    onRender(...args) {
        let alertResponse;
        ListView.prototype.onRender.call(this, args);

        if (this.hasLoadedRequiredData() && store.has('addRoleSuccessMessage')) {
            this.gridView.refreshGridData();
            alertResponse = store.get('addRoleSuccessMessage');
            this.renderMessage(alertResponse.action, alertResponse.response);
            store.unset('addRoleSuccessMessage');
        }
    },

    insert() {
        const userGroup = this.usergroup ? this.usergroup : userInfo.get('group');
        dialog.open(new AddRoleDialog({
            isAdmin: this.isAdmin,
            userGroup,
        }));
    },

    gridRowCustomAction(opts) {
        if (opts.action === 'clone') {
            dialog.open(new AddRoleDialog({
                isAdmin: this.isAdmin,
                userGroup: opts.model.get('USERGROUP'),
                cloneId: opts.model.get('ROLEID'),
            }));
        }
        return Promise.resolve();
    },

    gridRowSelect(opts) {
        store.set(
            'rolebased-select',
            {
                model: opts.model.toJSON(),
                buttons: opts.model.buttons,
            },
        );
        this.navigateTo(`ADMINISTRATION/viewRole/${opts.model.get('ROLEID')}/${opts.model.get('USERGROUP')}`);
        return Promise.resolve();
    },

    gridRowModify(opts) {
        this.navigateTo(`ADMINISTRATION/modifyRole/${opts.model.get('ROLEID')}/${opts.model.get('USERGROUP')}`);
        return Promise.resolve();
    },
});

let list = RolesList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list);
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'ROLE_MAINT',
    view: list,
    options: {},
    useMobileCard: true,
});

const exportedList = list;

export default exportedList;
