import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import Formatter from 'system/utilities/format';

export default Model.extend({

    defaults: {
        validations: {
            BATCH: true,
            DAILY: true,
            TRANSACTION: true,
            GROUPDAILY: true,
        },
    },

    /**
     * Update corresponding key in model when focus leaves input field
     * @param  {Array} inputs - array of values from inputs in view
     */
    updateModel(inputs) {
        const isGroupDaily = util.some(inputs, input => input.restrictionlabel === 'Group Daily');

        const limitType = isGroupDaily ? 'groupLimit' : 'limits';

        const updatedLimits = util.reduce(this.get(limitType), (accumulator, limit, index) => [
            ...accumulator, {
                ...limit,
                value: inputs[index].value,
            }], []);

        this.set(limitType, updatedLimits);

        if (this.get('productCode') === '*' || isGroupDaily) {
            this.validateAll();
        } else {
            // run and set validations for model
            this.set('validations', this.validate());
        }

        // TODO: the set function above is not triggering a change event for some reason
        this.trigger('change');
    },

    /**
     * Determine if limit values in model are valid
     * @return {object} Validations for all limit types
     */
    validate() {
        const validations = {};

        const limits = this.get('limits');
        const overallCompanyLimits = this.collection.at(0).get('limits');

        /**
         * this will return the correct groupLimit whether this is a groupLimit field or other
         * field
         */
        const groupDailyLimit = util.chain(this.collection.toJSON())
            .map((model) => {
                if (model.typeCode === this.get('typeCode') && model.groupLimit !== undefined) {
                    return model.groupLimit[0];
                }
                return undefined;
            })
            .compact()
            .value();

        const groupDailyLimitValue = this.retrieveValue(groupDailyLimit, 'Group Daily');
        const batchLimitValue = this.retrieveValue(limits, 'Batch');
        const dailyLimitValue = this.retrieveValue(limits, 'Daily');
        const transactionLimitValue = this.retrieveValue(limits, 'Transaction');
        const overallCompanyBatchLimitValue = this.retrieveValue(overallCompanyLimits, 'Batch');
        const overallCompanyDailyLimitValue = this.retrieveValue(overallCompanyLimits, 'Daily');
        const overallCompanyTransactionLimitValue = this.retrieveValue(overallCompanyLimits, 'Transaction');

        if (this.get('productCode') === 'USACH') {
            validations.BATCH = this.compareValues(
                batchLimitValue,
                [dailyLimitValue, overallCompanyBatchLimitValue],
            );
            validations.DAILY = this.compareValues(
                dailyLimitValue,
                [overallCompanyDailyLimitValue],
            );
            validations.TRANSACTION = this.compareValues(
                transactionLimitValue,
                [batchLimitValue, dailyLimitValue, overallCompanyTransactionLimitValue],
            );
        } else if (this.get('productCode') === 'RTGS') {
            // if there is a groupDailyLimit then compare it to the overallCompanyDailyLimit
            validations.GROUPDAILY = groupDailyLimitValue
                ? this.compareValues(
                    groupDailyLimitValue,
                    [overallCompanyDailyLimitValue],
                ) : true;
            /**
             * if there is a groupDailyLimit then compare the dailyLimit it overall and group
             * daily and if not then just to overall
             */
            validations.DAILY = this.compareValues(
                dailyLimitValue,
                groupDailyLimitValue ? [
                    overallCompanyDailyLimitValue,
                    groupDailyLimitValue,
                ] : [overallCompanyDailyLimitValue],
            );
            validations.TRANSACTION = this.compareValues(
                transactionLimitValue,
                [groupDailyLimitValue, dailyLimitValue, overallCompanyTransactionLimitValue],
            );
        } else if (this.get('productCode') === '*') {
            validations.BATCH = this.compareValues(
                batchLimitValue,
                [overallCompanyDailyLimitValue],
            );
            validations.DAILY = this.compareValues(
                dailyLimitValue,
                [overallCompanyDailyLimitValue],
            );
            validations.TRANSACTION = this.compareValues(
                transactionLimitValue,
                [overallCompanyBatchLimitValue, overallCompanyDailyLimitValue],
            );
        } else {
            validations.BATCH = this.compareValues(
                batchLimitValue,
                [overallCompanyBatchLimitValue],
            );
            validations.DAILY = this.compareValues(
                dailyLimitValue,
                [overallCompanyDailyLimitValue],
            );
            validations.TRANSACTION = this.compareValues(
                transactionLimitValue,
                [overallCompanyTransactionLimitValue],
            );
        }

        return validations;
    },

    /**
     * get the value of a limit if it exists
     * @param  {object} limitObj object with limit parameters and values
     * @param  {string} label label representing the field this limit is associated with
     * @return {number} value of the field if it has one and 0 if it doesn't
     */
    retrieveValue(limitObj, label) {
        // return 0 if limit does not exist
        if (util.isEmpty(limitObj)) {
            return 0;
        }

        const limitValue = util.findWhere(limitObj, { restrictionLabel: label });
        if (limitValue) {
            return util.isEmpty(limitValue) ? 0 : limitValue.value;
        }
        return 0;
    },

    /**
     * Run through every model in collection and validate
     */
    validateAll() {
        util.each(this.collection.models, (model) => {
            // if this is an overall company limit model we don't want to validate
            model.set('validations', model.validate());
        });
    },

    /**
     * Simple comparison of one number against another
     * @param {string} value
     * @param {string[]} valuesToCompare
     * @return {boolean} Valid state boolean
     */
    compareValues(value, valuesToCompare) {
        const validations = util.map(valuesToCompare, (valueToCompare) => {
            // if valueToCompare is 0 then return true
            if (Formatter.stringToNumber(valueToCompare) === 0) {
                return true;
            }

            return Formatter.stringToNumber(value) <= Formatter.stringToNumber(valueToCompare);
        });

        return util.every(validations, validation => validation === true);
    },

});
