/**
 * List view for Command and Control (Feature Deployment Center)
 */
import util from '@glu/core/src/util';
import dialog from '@glu/dialog';
import Model from '@glu/core/src/model';
import Collection from '@glu/core/src/collection';
import ItemView from '@glu/core/src/itemView';
import alert from '@glu/alerts';
import { TERTIARY } from '@glu/buttons-react';
import ListView from 'common/dynamicPages/views/workflow/list';
import ToggleView from 'common/uiWidgets/switchWidget/switchWidget';
import ChipView from 'common/uiWidgets/chipWidget/chipWidget';
import { createButtonCellView } from 'components/CustomCells';
import { getData } from 'common/util/services';
import servicesUtil from 'system/servicesList';
import store from 'system/utilities/cache';
import userInfo from 'etc/userInfo';
import template from './list.hbs';
import ApprovalModal from './approvalModal';
import RejectModal from './rejectModal';
import DetailsView from './details';
import constants from './constants';
import localeKeys from './localeKeys';

const CommandAndControlList = ListView.extend({
    template,

    regions: {
        alertRegion: '.alert-region',
    },

    events: {
        'click [data-hook="tab-button"]': 'handleTabClick',
    },

    initialize(options) {
        const superOptions = {
            menuCategory: 'ADMIN',
            serviceName: '/featureControl',
            serviceFunc: null,
            businessType: null,
            context: 'FEATUREID',
            enableSavedViews: false,
        };

        this.model = new Model({
            initialDataLoaded: false,
            viewId: constants.viewId.ALL_FEATURES,
            selectedTab: 'allFeatures',
        });

        this.listenTo(this.model, 'change', () => {
            this.render();
        });

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    onRender(view) {
        const newView = view;
        newView.options = {
            ...newView.options,
            cellViews: {
                STAGED: ToggleView,
                ENABLED: ChipView,
                NAME: createButtonCellView({
                    variant: TERTIARY,
                    onClick: (e, { model }) => {
                        const modelCopy = model;
                        if (this.alertRegion) {
                            this.alertRegion.close();
                        }
                        modelCopy.context = {
                            actionContext: {
                                actionMode: 'SELECT',
                                entryMethod: 0,
                                functionCode: 'MAINT',
                                productCode: 'FEATURES',
                                subType: '*',
                                typeCode: 'COMMDCHD',
                            },
                            serviceName: '/featureDetail',
                        };
                        store.set('featureDetail-actionModel', modelCopy);
                        dialog.open(new DetailsView({
                            viewType: 'modal',
                            featureName: model.get('NAME'),
                        }));
                    },
                }),
            },
            viewId: this.model.get('viewId'),
        };

        if (this.hasLoadedRequiredData()) {
            this.gridRegion.show(this.gridView);
            this.gridView.wrapper.entitlements = this.entitlements;
            if (this.model.get('showAlert')) {
                this.showAlert(this.model.get('modalResponse'));
            }
        } else {
            this.loadViewRequirements();
        }
    },

    loadViewRequirements() {
        const originalPromise = ListView.prototype.loadViewRequirements.call(this);
        const summaryPromise = this.getSummary();

        this.gridImportPromise.then(() => {
            Promise.all([originalPromise, summaryPromise, this.gridView.loadPromise]).then(([,
                summaryResponse,
            ]) => {
                const requestor = this.gridView.grid.gridCollection.reduce((acc, cur) => {
                    if (cur.get('MODIFIED_BY') && cur.get('STATUS') === constants.status.MODIFIED) {
                        return cur.get('MODIFIED_BY');
                    }
                    return acc;
                }, undefined);

                this.setupGridListeners();

                this.model.set({
                    initialDataLoaded: true,
                    featuresSummary: summaryResponse,
                    requestor,
                });
            }).catch(() => {
                this.model.set({
                    hasLoadError: true,
                });
            });
        });
    },

    setupGridListeners() {
        // listen for grid loaded to make sure buttons are in right state
        this.listenTo(this.gridView, 'gridapi:loaded', () => {
            if (this.gridView.grid) {
                this.render();
            }
        });

        // listen for toggle changes
        this.listenTo(
            this.gridView.grid.collection,
            'change:STAGED',
            this.handleToggleChanges,
        );

        this.listenTo(this.gridView, 'item:before:render', (view) => {
            const viewObject = view;
            viewObject.rowClassName = (model) => {
                if (model.get('STATUS') === constants.status.MODIFIED) {
                    return 'switch-widget-disabled';
                }
                return '';
            };
        });
    },

    handleToggleChanges() {
        const hasToggledSwitches = this.gridView.grid
            ? !!this.gridView.grid.collection.find(model => (
                model.get('STAGED') !== model.get('ENABLED')
            )) : false;
        this.model.set({
            hasToggledSwitches,
        });
    },

    getSummary() {
        const summaryUrl = servicesUtil.generateUrl('featureControl/getSummary');
        return getData(summaryUrl);
    },

    getRequestedChanges() {
        const changedCollection = new Collection();
        this.gridView.grid.gridCollection.forEach((featureModel) => {
            const staged = featureModel.get('STAGED');
            let enabled = featureModel.get('ENABLED');
            // if status is "approved" treat enabled as the opposite
            if (featureModel.get('STATUS') === constants.status.PENDING_DEPLOYMENT) {
                enabled = featureModel.get('ENABLED') === '0' ? '1' : '0';
            }
            if (enabled !== staged) {
                changedCollection.add(featureModel);
            }
        });
        return changedCollection;
    },

    requestChanges() {
        const changedCollection = this.getRequestedChanges();
        dialog.custom(new ApprovalModal({
            changedCollection,
            refreshData: this.refreshData.bind(this),
        }));
    },

    approve() {
        const changedCollection = this.getRequestedChanges();
        dialog.custom(new ApprovalModal({
            changedCollection,
            isApprove: true,
            refreshData: this.refreshData.bind(this),
        }));
    },

    reject() {
        // need to show reject modal
        dialog.custom(new RejectModal({
            refreshData: this.refreshData.bind(this),
        }));
    },

    /**
     * Reset requested changes to original state
     */
    reset() {
        this.gridView.refreshGridData();
        this.model.set({
            hasToggledSwitches: false,
        });
    },

    /**
     * Method to provide to approval/rejection modals an ability to trigger a refresh of data
     *
     * need to provide data from caller that gives us requestor id when necessary
     */
    refreshData(name, data = {}) {
        const { response, ...props } = data;
        if (name === 'cancel') {
            this.reset();
            return;
        }

        if (name === 'approve' || name === 'reject') {
            this.switchToTab('allFeatures');
        }

        const summaryRequest = this.getSummary();
        const refreshGridRequest = this.gridView.refreshGridData(undefined, {
            viewId: this.model.get('viewId'),
        });

        Promise.all([summaryRequest, refreshGridRequest]).then(([summaryResponse]) => {
            this.model.set({
                ...props,
                featuresSummary: summaryResponse,
                showAlert: !!response,
                modalResponse: response,
            });
        });
    },

    showAlert(response) {
        let message;
        if (this.model.get('selectedTab') !== 'allFeatures') {
            return;
        }
        if (response.result) {
            const messageItems = response.confirms.confirmResults[0].confirmData[0].item;
            const messages = messageItems.map(item => (
                `\n${item.name} ${item.value}`
            )).join('');
            const messageLead = (response.message && Array.isArray(response.message))
                ? response.message.join('') : '';
            this.alertRegion.show(alert.positive(messageLead, {
                details: new (ItemView.extend({
                    template() {
                        return messages;
                    },
                }))(),
            }));
        } else {
            const messages = response.confirms && response.confirms.confirmResults
                && response.confirms.confirmResults[0].messages;
            message = messages.join('\n');
            this.alertRegion.show(alert.negative(message));
        }
    },

    switchToTab(tabName) {
        const viewId = tabName === 'pendingApprovals'
            ? constants.viewId.PENDING_APPROVALS : constants.viewId.ALL_FEATURES;

        this.model.set({
            viewId,
            selectedTab: tabName,
        });
    },

    /**
     * Handle tab clicks
     *
     * @param {Object} e - Event object
     */
    handleTabClick(e) {
        const selectedTab = e.currentTarget.getAttribute('data-tab');
        const liContainer = e.currentTarget.closest('li');
        const isCurrentlyActive = liContainer.classList.contains('active');

        if (isCurrentlyActive) {
            return;
        }

        this.switchToTab(selectedTab);

        this.gridView.refreshGridData(undefined, {
            viewId: this.model.get('viewId'),
        });
    },

    userIsRequestor() {
        return userInfo.get('id') === this.model.get('requestor');
    },

    hasModelInStatus(status) {
        return this.gridView.grid
            ? !!this.gridView.grid.collection.find(model => (
                model.get('STATUS') === status
            )) : false;
    },

    hasModelInModified() {
        return this.hasModelInStatus(constants.status.MODIFIED);
    },

    hasModelInPendingDeployment() {
        return this.hasModelInStatus(constants.status.PENDING_DEPLOYMENT);
    },

    hasPendingChanges() {
        return this.hasModelInModified() || this.hasModelInPendingDeployment();
    },

    isTabSelected(tabName) {
        return this.model.get('selectedTab') === tabName;
    },

    templateHelpers() {
        const originalHelpers = ListView.prototype.templateHelpers.call(this);
        const featuresSummary = this.model.get('featuresSummary');
        return {
            ...originalHelpers,
            getActiveClass: (tabName) => {
                if (tabName === this.model.get('selectedTab')) {
                    return 'active';
                }
                return '';
            },
            allFeaturesCount: featuresSummary ? featuresSummary.totalFeatureCount : 0,
            pendingApprovalCount: featuresSummary
                ? featuresSummary.pendingApprovalCount : 0,
            currentUsersPendingChanges: userInfo.get('id') === this.model.get('requestor'),
            requestButtonsDisabled: () => {
                /*
                 * The request buttons should be disabled when:
                 *  - There are pending changes
                 *  - OR the user has not toggled any switches
                 */
                const hasPendingChanges = this.hasPendingChanges();
                const hasToggledSwitches = this.model.get('hasToggledSwitches');
                return hasPendingChanges || !hasToggledSwitches;
            },
            approveButtonsDisabled: () => {
                /*
                 * The approve buttons should be disabled when:
                 *   - The model is in "Approved - Pending Deployment" state
                 *   - The model is in Modified state and the current user is the requestor
                 */
                const userIsRequestor = this.userIsRequestor();
                const modelInModified = this.gridView.grid
                    ? !!this.gridView.grid.collection.find(model => (
                        model.get('STATUS') === constants.status.MODIFIED
                    )) : false;
                const modelInPendingDeployment = this.gridView.grid
                    ? !!this.gridView.grid.collection.find(model => (
                        model.get('STATUS') === constants.status.PENDING_DEPLOYMENT
                    )) : false;
                return !!((userIsRequestor && modelInModified) || modelInPendingDeployment);
            },
            showRequestButtons: () => {
                /*
                 * Request buttons should show when:
                 *  - A user is on allFeatures tab
                 *  - AND there are no pending approvals
                 */
                const isAllFeaturesTab = this.isTabSelected('allFeatures');
                const hasPendingChanges = this.hasPendingChanges();
                return !hasPendingChanges && isAllFeaturesTab;
            },
            showApproveButtons: () => {
                /*
                 * Approve buttons should show when:
                 *  - A user is on pendingApprovals tab
                 *  - AND there are pending approvals
                 *  - AND the user is not the requestor
                 */
                const hasPendingChanges = featuresSummary
                    ? !!featuresSummary.pendingApprovalCount : false;
                const isPendingApprovalsTab = this.isTabSelected('pendingApprovals');
                const userIsRequestor = this.userIsRequestor();
                return hasPendingChanges && isPendingApprovalsTab && !userIsRequestor;
            },
            /*
             * Text for "All Features" before change request shown when:
             *   - This is the allFeatures tab
             *   - There are no MODIFIED models
             *   - There are no PENDING_DEPLOYMENT models
             */
            showAllFeaturesBeforeRequestPretext: () => {
                const isAllFeaturesTab = this.isTabSelected('allFeatures');
                const modelInPendingOrModified = this.hasPendingChanges();
                return !modelInPendingOrModified && isAllFeaturesTab;
            },
            /*
             * Text for "All Features" after change request made shown when:
             *   - This is the allFeatures tab
             *   - The user is the requestor
             *   - There are MODIFIED models
             */
            showAllFeaturesAfterRequestPretext: () => {
                const isAllFeaturesTab = this.isTabSelected('allFeatures');
                const userIsRequestor = this.userIsRequestor();
                const modelInModified = this.hasModelInModified();
                return modelInModified && isAllFeaturesTab && userIsRequestor;
            },
            /*
             * Text for "Pending Approvals" for requestor after change request shown when:
             *   - This is the pendingApprovals tab
             *   - The user is the requestor
             *   - There are MODIFIED models
             */
            showPendingApprovalsForRequestorPretext: () => {
                const isPendingApprovalsTab = this.isTabSelected('pendingApprovals');
                const userIsRequestor = this.userIsRequestor();
                const modelInModified = this.hasModelInModified();
                return modelInModified && isPendingApprovalsTab && userIsRequestor;
            },
            /*
             * Text for Approver on BOTH tabs after change request shown when:
             *   - The user is not the requestor
             *   - There are MODIFIED models
             *   - OR
             *   - There are PENDING_DEPLOYMENT models
             */
            showPendingApprovalsForApproverPretext: () => {
                const userIsRequestor = this.userIsRequestor();
                const modelInModified = this.hasModelInModified();
                const modelInPending = this.hasModelInPendingDeployment();
                return (modelInModified && !userIsRequestor) || modelInPending;
            },

            /* Hide the default icons (print/export) above the grid */
            gridUtilityOptions: {
                includeDefaultIcons: false,
            },

            gridErrorClass: this.model.get('hasLoadError') ? 'has-error' : '',

            localeKeys,
        };
    },

});

export default CommandAndControlList;
