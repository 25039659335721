import Layout from '@glu/core/src/layout';
import userInfo from 'etc/userInfo';
import $ from 'jquery';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
// temp fix until validators can be extended
import SystemModel from '@glu/core/src/model';
import cashFlowEntryLayoutTmpl from './cashFlowEntryLayout.hbs';
import cashFlowEntryCategoryTmpl from './cashFlowEntryCategory.hbs';

const CashFlowEntryLayout = Layout.extend({
    template: cashFlowEntryLayoutTmpl,

    ui: {
        // containers of controls for future adjustments
        $futureAdjustmentSettings: '.cashflow-future-adjustment-settings',

        $futureAdjustmentAmounts: '.cashflow-future-adjustment-amounts',

        // datepicker container
        $cashFlowDatePicker: '#cashflow-datepicker',

        $cashFlowRecurringUntil: '#cashflow-recurring-until',

        // form fields
        $description: '#cashflow-description',

        $accountSelect: '#cashflow-account',
        $cashFlowType: '#cashflow-type',
        $cashFlowAmount: '#cashflow-amount',
        $cashFlowCategory: '#cashflow-category',
        $adjustmentTypeSelect: '#cashflow-adjustment-type',
        $adjustmentAmountField: '#cashflow-adjustment-amount',
        $adjustmentPercentField: '#cashflow-adjustment-percent',
        $frequencyTypeSelect: '#cashflow-frequency-type',
        $cashflowRecurringType: '#cashflow-recurring-type',
        $cashflowRecurringUntil: '#cashflow-recurring-until',
        $cashflowIncreaseType: '#cashflow-amount-type',

        // buttons
        $btnCashflowSave: '.btn-cashflow-save',
    },

    events: {
        'change @ui.$cashFlowType': 'cashFlowTypeChanged',
        'change @ui.$adjustmentTypeSelect': 'adjustmentTypeChanged',
        'change @ui.$adjustmentAmountField': 'adjustmentAmountChanged',
        'change @ui.$adjustmentPercentField': 'adjustmentPercentChanged',
        'change @ui.$cashflowRecurringType': 'recurringTypeChanged',
    },

    initialize(options) {
        this.model.getValidationResultsByFieldName = SystemModel.prototype
            .getValidationResultsByFieldName;
        this.model.getValidationMessage = SystemModel.prototype.getValidationMessage;

        this.listenTo(
            this.model,
            {
                updateFields: this.save,
                'change:UPDATEFUTUREITEMS': this.updateFutureChanged,
            },
            this,
        );

        this.model.validators = util.extend(
            this.model.validators || {},
            {
                ACCOUNTID: {
                    description: locale.get('cashflow.account'),
                    exists: true,
                },

                DESCRIPTION: {
                    description: locale.get('cashflow.description'),
                    exists: true,
                },

                CASHFLOWCATEGORYID: {
                    description: locale.get('cashflow.category'),
                    exists: true,
                },

                EXPECTEDDATE: {
                    description: locale.get('cashflow.entry.date'),
                    exists: true,

                    date() {
                        return userInfo.getDateFormat();
                    },
                },
            },
        );

        this.action = options.action;
        this.editMode = false;
        this.isCategoryChangeOnly = this.model.get('CASHFLOWTYPE') === 'DEBIT' || this.model.get('CASHFLOWTYPE') === 'CREDIT';
        this.partOfActivity = !util.isNull(this.model.get('CASHFLOWSCENARIO')) && this.model.get('CASHFLOWSCENARIO') !== '' && this.action !== 'create';
        this.accountList = options.accountList;
        this.inflowCategories = options.inflowCategories;
        this.outflowCategories = options.outflowCategories;

        this.transactions = options.transactions;
        this.gridTransactions = options.gridTransactions;

        if (!util.isEmpty(this.model.attributes)) {
            this.editMode = true;
        }

        this.isPredicted = !util.isUndefined(this.model) && this.model.get('CASHFLOWTYPE') === 'PREDICTED';

        // by default, always show the recurring UI?
        this.showRecurring = !util.isUndefined(this.model) && util.isUndefined(this.model.get('RECURRING'));
        this.isRecurring = !util.isUndefined(this.model) && this.model.get('RECURRING') === 'RECURRING';

        if (this.isRecurring) {
            this.model.set('INCREASEBY', 0);
            this.model.validators = util.extend(
                this.model.validators || {},
                {
                    INCREASEBY: {
                        description: 'Increase future amounts',
                        isNumeric: true,
                    },
                },
            );
        }

        this.scenarios = options.scenarios;
    },

    templateHelpers() {
        const types = [{
            type: 'INFLOW',
            label: locale.get('cashflow.inflow'),
            action: 'inflow',
            categoryOnly: false,
        }, {
            type: 'OUTFLOW',
            label: locale.get('cashflow.outflow'),
            action: 'outflow',
            categoryOnly: false,
        }];

        if (this.isCategoryChangeOnly) {
            types.push({
                type: 'CREDIT',
                label: locale.get('cashflow.credit'),
                action: 'credit',
                categoryOnly: true,
            });
            types.push({
                type: 'DEBIT',
                label: locale.get('cashflow.debit'),
                action: 'debit',
                categoryOnly: true,
            });
            types.push({
                type: 'BILL',
                label: locale.get('cashflow.bill'),
                action: 'bill',
                categoryOnly: true,
            });
            types.push({
                type: 'INVOICE',
                label: locale.get('cashflow.invoice'),
                action: 'invoice',
                categoryOnly: true,
            });
        }

        return {
            showRecurring: this.showRecurring === true,
            isRecurring: this.isRecurring === true,
            isPredicted: this.isPredicted === true,
            isCategoryChangeOnly: this.isCategoryChangeOnly,
            partOfActivity: this.partOfActivity,
            accounts: this.accountList.toJSON(),
            types,
            currencySymbolString: userInfo.getCurrencySymbol(),
        };
    },

    onRender() {
        const type = this.model.get('CASHFLOWTYPE');
        if (type) {
            this.ui.$cashFlowType.val(type.toLowerCase());
        }

        this.cashFlowTypeChanged();
        this.ui.$cashFlowDatePicker.html(this.cashFlowDatePicker);
        this.ui.$cashFlowRecurringUntil.html(this.cashFlowRecurringUntil);
        this.updateAccountValue();
        if (this.isPredicted) {
            this.ui.$futureAdjustmentSettings.toggleClass('hidden', true);
        }

        if (this.isCategoryChangeOnly) {
            this.$('input, select').not('#cashflow-category').prop('disabled', true);
            this.$('.ui-datepicker-trigger').off();
        }

        /*
         * TODO make sure this is set to the current date, correctly sets the date
         * on the model
         */
        this.ui.$cashFlowDatePicker.nhDatePicker({
            locale: {
                format: userInfo.get('dateFormat'),
            },
        });

        this.ui.$cashFlowRecurringUntil.nhDatePicker({
            locale: {
                format: userInfo.get('dateFormat'),
            },
        });
    },

    updateAccountValue() {
        let selected = this.model.get('ACCOUNTID');

        if (this.accountList.length > 0) {
            // make sure the selected account is actually in the list
            const accountInList = this.accountList.where({
                accountId: +selected,
            });

            if (accountInList.length === 0) {
                // if it isn't, select the first one available
                selected = this.accountList.at(0).get('accountId');
            }
        }

        this.ui.$accountSelect.val(selected).trigger('change');
    },

    isOutflowType(cashFlowType) {
        return util.contains(['OUTFLOW', 'DEBIT', 'BILL'], cashFlowType);
    },

    getCurrentValue() {
        let value = 0;
        if (this.isOutflowType(this.model.get('CASHFLOWTYPE'))) {
            value = this.model.get('OUTFLOW');
        } else {
            value = this.model.get('INFLOW');
        }
        return value;
    },

    cashFlowTypeChanged() {
        /*
         * make sure the INFLOW & OUTFLOW fields are up-to-date
         * a value the user enters or that is loaded from the model should be in the
         * current field
         * the other one should be an empty string
         */

        const val = this.ui.$cashFlowType.val();

        const field = val.toUpperCase();
        const type = this.isOutflowType(field) ? 'OUTFLOW' : 'INFLOW';
        const otherField = this.isOutflowType(field) ? 'INFLOW' : 'OUTFLOW';
        const data = {};

        data[field] = this.getCurrentValue();
        data[otherField] = '';

        this.$('[data-cashflow-type]').hide().filter(`[data-cashflow-type="${val}"]`).show();

        this.model.validators[field] = {
            description: locale.get('cashflow.amount'),
            exists: true,
            isNumeric: true,
        };
        this.model.validators[otherField] = {};

        this.model.set(data);
        this.model.set('CASHFLOWTYPE', field);
        this.setCategories(type.toLowerCase());
    },

    save() {
        const attributes = {};

        if (this.ui.$cashflowRecurringType.val() && this.ui.$cashflowRecurringType.val() !== 'never') {
            util.extend(
                attributes,
                {
                    FREQUENCY: this.ui.$cashflowRecurringType.val(),
                    RECURRENCEENDDATE: this.ui.$cashflowRecurringUntil.val(),
                    RECURRING: 1,
                },
            );
        }

        if (this.editMode) {
            /*
             * update inflow/outflow attrs in the event we are editing a credit/debit.
             * NH-24742
             */
            if (this.isOutflowType(this.model.get('CASHFLOWTYPE'))) {
                this.model.set('OUTFLOW', this.model.get(this.model.get('CASHFLOWTYPE')));
            } else {
                this.model.set('INFLOW', this.model.get(this.model.get('CASHFLOWTYPE')));
            }

            /*
             * make sure we update future entries for recurring transactions
             * if so desired.
             */
            if (this.model.get('RECURRING') === 'RECURRING') {
                util.extend(
                    attributes,
                    {
                        // translate back to a database value.
                        RECURRING: 1,

                        increaseType: this.ui.$cashflowIncreaseType.val(),
                    },
                );
            }

            if (this.model.has('predictionId')) {
                util.extend(
                    attributes,
                    {
                        adjustmentType: this.ui.$adjustmentTypeSelect.val(),
                        adjustmentAmount: this.ui.$adjustmentAmountField.val(),
                        frequencyType: this.ui.$frequencyTypeSelect.val(),
                    },
                );
            }
        }

        this.model.set(attributes);
    },

    getButtons() {
        let buttons = [{
            text: locale.get('cashflow.save.base.btn'),
            className: 'btn btn-primary btn-cashflow-save',
        }, {
            text: locale.get('cashflow.save.activity.btn'),
            className: 'btn btn-secondary btn-cashflow-save-to-a-scenario',
        }];
        if (this.action === 'update') {
            if (this.isCategoryChangeOnly) {
                buttons = [{
                    text: locale.get('cashflow.save.btn'),
                    className: 'btn btn-primary btn-cashflow-save',
                }];
                // If item is not in activity, save or create item on activity
            } else if (this.model.get('CASHFLOWSCENARIOID') === '' || this.model.get('CASHFLOWSCENARIOID') === '0') {
                buttons = [{
                    text: locale.get('cashflow.save.btn'),
                    className: 'btn btn-primary btn-cashflow-save',
                }, {
                    text: locale.get('cashflow.save.activity.btn'),
                    className: 'btn btn-secondary btn-cashflow-save-to-a-scenario',
                }];
                // If item is in activity, save or move to a new activity
            } else if (this.model.get('CASHFLOWSCENARIOID') !== null && this.model.get('CASHFLOWSCENARIOID') > 0) {
                buttons = [{
                    text: 'Save',
                    className: 'btn btn-primary btn-cashflow-save',
                }, {
                    text: locale.get('cashflow.move.activity.btn'),
                    className: 'btn btn-secondary btn-cashflow-save-to-a-scenario',
                }];
            }
        }

        return buttons;
    },

    updateCategoryList(categoryCollection) {
        this.ui.$cashFlowCategory.html(cashFlowEntryCategoryTmpl({
            categories: categoryCollection.toJSON(),
        }));

        let valueToSelect = this.model.get('CASHFLOWCATEGORYID');
        if (categoryCollection.where({
            cashFlowCategoryId: +valueToSelect,
        }).length === 0) {
            // if no category matches, pick the first one
            valueToSelect = categoryCollection.at(0).get('cashFlowCategoryId');
        }

        // update the field and make sure binding picks up on it
        this.ui.$cashFlowCategory.val(valueToSelect).trigger('change');
    },

    setCategories(type) {
        if (type === 'inflow') {
            this.updateCategoryList(this.inflowCategories);
        } else {
            this.updateCategoryList(this.outflowCategories);
        }
    },

    updateFutureChanged() {
        this.ui.$futureAdjustmentSettings.toggleClass('hidden', this.model.get('UPDATEFUTUREITEMS'));
    },

    recurringTypeChanged() {
        const val = this.ui.$cashflowRecurringType.val();

        if (val !== 'never') {
            if (util.isUndefined(this.model.validators.RECURRENCEENDDATE)) {
                this.model.validators = util.extend(
                    this.model.validators,
                    {
                        RECURRENCEENDDATE: {
                            description: locale.get('cashflow.recurring.until.date'),
                            exists: true,

                            date() {
                                return userInfo.getDateFormat();
                            },
                        },
                    },
                );
            }

            $('#cashflow-recurring-until').removeAttr('disabled');
        } else {
            delete this.model.validators.RECURRENCEENDDATE;
            $('#cashflow-recurring-until').prop('disabled', true);
        }
    },

    adjustmentTypeChanged() {
        let hide = true;
        if (this.ui.$adjustmentTypeSelect.val() === 'steadyIncrease'
            || this.ui.$adjustmentTypeSelect.val() === 'steadyDecrease') {
            hide = false;
        }

        this.ui.$futureAdjustmentAmounts.toggleClass('hidden', hide);
    },

    adjustmentAmountChanged() {
        const value = this.ui.$adjustmentAmountField.val();
        if (value === undefined || Number.isNaN(parseFloat(value))) {
            return;
        }
        const currentAmount = this.ui.$cashFlowAmount.val();
        if (currentAmount === undefined || Number.isNaN(parseFloat(currentAmount))) {
            return;
        }
        const percent = Math.round((value * 100) / parseFloat(currentAmount));
        this.ui.$adjustmentPercentField.val(percent);
    },

    adjustmentPercentChanged() {
        const value = this.ui.$adjustmentPercentField.val();
        if (value === undefined || Number.isNaN(parseFloat(value))) {
            return;
        }
        const currentAmount = this.ui.$cashFlowAmount.val();
        if (currentAmount === undefined || Number.isNaN(parseFloat(currentAmount))) {
            return;
        }
        const amount = Math.round((parseFloat(value) * parseFloat(currentAmount)) / 100);
        this.ui.$adjustmentAmountField.val(amount);
    },
});

export default CashFlowEntryLayout;
