var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.backToPreviousPage",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":144},"end":{"line":3,"column":191}}}))
    + "</span></a>\n    </div>\n    <h1 class=\"landing-header\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"stateDescription") || (depth0 != null ? lookupProperty(depth0,"stateDescription") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"stateDescription","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":51}}}) : helper)))
    + " "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.E-Learning",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":52},"end":{"line":5,"column":80}}}))
    + "</h1>\n</div>\n\n<div class=\"section section-container\" role=\"navigation\">\n    <div class=\"section-body\">\n        <ul id=\"elearn-tablist\" class=\"NavTabs nav nav-tabs\"\n            data-hook=\"getNavTabs\"\n            role=\"tablist\">\n            <li class=\"NavTabs-item is-active content-tab\"\n                role=\"tab\">\n                <a aria-controls=\"content\"\n                    class=\"NavTabs-link\"\n                    href=\"#content\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.elearning.content",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":36},"end":{"line":17,"column":70}}}))
    + "\n                </a>\n            </li>\n            <li class=\"NavTabs-item is-active group-tab\"\n                role=\"tab\">\n                <a aria-controls=\"group\"\n                    class=\"NavTabs-link\"\n                    href=\"#group\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.elearning.group",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":34},"end":{"line":24,"column":66}}}))
    + "\n                </a>\n            </li>\n        </ul>\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        <div class=\"pageRegion remove-section-styles\"></div>\n    </div>\n</div>\n";
},"useData":true});