import Layout from '@glu/core/src/layout';
import $ from 'jquery';
import store from 'system/utilities/cache';
import locale from '@glu/locale';
import GridApi from 'common/dynamicPages/api/grid';
import PrintExportUtil from 'common/util/printExportUtil';
import ListView from 'common/dynamicPages/views/workflow/list';
import failedRecordsListViewTmpl from './failedRecordsListView.hbs';

export default Layout.extend({
    template: failedRecordsListViewTmpl,

    events: {
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
    },

    onRender() {
        this.loadViewRequirements();
    },

    gridLoadComplete() {
        $('#failedCount').html(this.gridView.wrapper.rows.totalCount);
        store.set('failedRecordsGridLoaded', true);
        if (this.gridView.wrapper.rows.totalCount === 0) {
            $('#failedRecordsPanel').hide();
        }
        this.options.parent.trigger('bothGridsLoaded');
    },

    templateHelpers() {
        return {
            getButtonString(code) {
                return locale.get(code);
            },
        };
    },

    loadViewRequirements() {
        const data = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    action: {
                        typeCode: 'IMPORT',
                        productCode: 'ADMPAY',
                        functionCode: 'HISTORY',
                        actionMode: 'SELECT',
                    },

                    callingAction: {
                        typeCode: 'IMPORT',
                        entryMethod: 0,
                        productCode: 'ADMPAY',
                        functionCode: 'HISTORY',
                        actionMode: 'SELECT',
                    },

                    searchType: '5',
                    inquiryId: 17260,
                },
            },
        };

        this.customFilters = [{
            filterName: 'Depends',
            filterParam: ['JOBID', store.get('jobId')],
        }];

        data.inquiryRequest.searchCriteria.customFilters = this.customFilters;

        const contextLocal = {
            actionMode: 'SELECT',
            displayOrder: 1,
            functionCode: 'HISTORY',
            inquiryId: 17260,
            gridId: 0,
            nonDashboardDisplay: 0,
            productCode: 'ADMPAY',
            requestParams: '?!_init=true&_productCode=ADMPAY&_functionCode=HISTORY&_typeCode=IMPORT&_mode=SELECT&_gridId=17260',
            // set to IMPORT-FAILED to keep the id unique from other listviews
            typeCode: 'IMPORT-FAILED',
        };

        ListView.prototype.setListViewConfig.call(this, contextLocal);

        const options = {
            context: contextLocal,
            filter: false,
            selector: 'none',
            hideButtons: true,

            // default grid action buttons are hidden
            hideGridActionButtons: true,

            enableRowContextButtonCallbacks: true,
            inquirySearchCriteria: data.inquiryRequest.searchCriteria,
            lvc: this.lvc,
        };

        this.gridView = GridApi.createInquiryGridView(options);
        this.listenTo(this.gridView, 'gridapi:loaded', this.gridLoadComplete);
        ListView.prototype.setupGridAvailableListener.call(this);

        if (this.failedRecordsGrid) {
            this.failedRecordsGrid.show(this.gridView);
        }
    },

    print() {
        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            inquiryId: '17260',
            title: 'fih.FailedRecordsGrid',
            format: 'PDF',
        };

        PrintExportUtil.print(options);
    },

    export() {
        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            inquiryId: '17260',
            format: 'CSV',
        };

        PrintExportUtil.export(options);
    },
});
