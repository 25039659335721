import React, {
    createContext,
    useReducer,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import FooterContextReducer, { TYPE } from './FooterContextReducer';
import { MODE } from '../constants';

const FooterContext = createContext({});

export const FooterContextProvider = ({
    children,
    providerState,
}) => {
    const [state, dispatch] = useReducer(FooterContextReducer, {
        mode: MODE.MODIFY,
        localeOptions: [],
        footerData: {
            FOOTERCONTENT: '',
            LOCALE: '',
        },
        entitlements: [],
    });
    const setLocaleOptions = useCallback((options) => {
        dispatch({
            type: TYPE.SET_LOCALE_OPTIONS,
            payload: options,
        });
    }, []);
    const setFooterData = useCallback((data) => {
        dispatch({
            type: TYPE.SET_FOOTER_DATA,
            payload: data,
        });
    }, []);
    const setMarketSegmentOptions = useCallback((data) => {
        dispatch({
            type: TYPE.SET_MARKET_SEGMENT_OPTIONS,
            payload: data,
        });
    }, []);

    const setEntitlements = useCallback((data) => {
        dispatch({
            type: TYPE.SET_ENTITLEMENTS,
            payload: data,
        });
    }, []);

    return (
        <FooterContext.Provider value={{
            ...state,
            ...providerState,
            setFooterData,
            setLocaleOptions,
            setMarketSegmentOptions,
            setEntitlements,
        }}
        >
            {children}
        </FooterContext.Provider>
    );
};

FooterContextProvider.propTypes = {
    providerState: PropTypes.shape({}),
    children: PropTypes.node.isRequired,
};
FooterContextProvider.defaultProps = {
    providerState: {},
};
export default FooterContext;
