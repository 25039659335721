import showdown from 'showdown';

export default {
    /**
     * Convert markdown to HTML
     * @param  {string} markdown - content we want to convert to HTML
     * @param  {boolean} targetLink if the links should use target blank.
     * @return {string} converted HTML string
     */
    toHTML(markdown, targetLink) {
        showdown.extension('targetlink', () => [{
            type: 'html',
            regex: /(<a [^>]+?)(>.*<\/a>)/g,
            replace: '$1 target="_blank"$2',
        }]);

        const markdownConverter = new showdown.Converter(targetLink ? { extensions: ['targetlink'] } : {});
        markdownConverter.setFlavor('github');

        return markdownConverter.makeHtml(markdown);
    },

    /**
     * Make markdown safe
     * @param  {string} markdown Markdown text
     * @return {string} markdown with harmful characters removed
     */
    encodeMarkdown(markdown) {
        const div = document.createElement('div');
        // https://kellegous.com/j/2013/02/27/innertext-vs-textcontent/
        div.textContent = markdown;
        return div.innerHTML.replace(/<br>/g, '\n');
    },
};
