import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import partialAccountSummaryTmpl from './partialAccountSummary.hbs';

const PartialAccountSummary = ItemView.extend({
    tagName: 'ul',
    className: 'account-summary',

    modelEvents: {
        change: 'render',
    },

    templateHelpers() {
        return {
            end: locale.getDefault(`gir.${this.model.get('END_LABEL')}_LEDGER`, 'Default Closing Ledger'),
            start: locale.get('gir.OPENING_LEDGER'),
            useGroupKeys: this.model.has('START_LEDGER_FORGROUP'),
        };
    },

    template: partialAccountSummaryTmpl,
});

export default PartialAccountSummary;
