import util from '@glu/core/src/util';
import workspaceHelper from 'common/workspaces/api/helper';
import Entitlements from 'common/dynamicPages/api/entitlements';
import services from 'services';
import RTPPoller from 'common/notificationClient/realTimePayments/rtpPoller';
import client from 'common/notificationClient/client';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import constants from 'app/payments/constants';
import ContextApi from 'common/dynamicPages/api/context';
import store from 'system/utilities/cache';
import tmpl from './rtpMainView.hbs';
import AccordionLayout from './accordionLayoutBase';
import PaymentGrid from './rtpGridView';

const RTP_NOTIFICATION_INTERVAL = serverConfigParams.get('RTPNotificationIntervalMS') || 30000;
const SUBMITTED = 0;
const RECEIVED = 1;

const RealTimePayments = AccordionLayout.extend({
    template: tmpl,

    ui: util.extend({}, AccordionLayout.prototype.ui, {
        $sendPaymentBtn: '[data-hook="getSendPaymentBtn"]',
        $paymentsSubmittedCount: '[data-hook="getSubmittedCount"]',
        $paymentsReceivedCount: '[data-hook="getReceivedCount"]',
        $insertBtn: '[data-hook="insertBtn"]',
    }),

    /**
     * this data will be replaced with RTP payments submitted and received
     * parameters after they are created
     */
    gridData: {
        paymentsSubmittedOpts: {
            menuCategory: 'PMTS',
            serviceName: 'payment/rtp',
            context: 'RTP_SUB_VIEW',
            enableSavedViews: true,
        },
        paymentsReceivedOpts: {
            menuCategory: 'PMTS',
            serviceName: 'payment/rtp-rec',
            context: 'RTP_REC_VIEW',
            enableSavedViews: true,
            inquiryId: constants.RTP.INQUIRYID.RECEIVED,
        },
    },

    events: {
        'click @ui.$tabLinks': 'handleClick',
        'click @ui.$sendPaymentBtn': 'sendPayment',
        'click @ui.$insertBtn': 'insert',
    },

    initialize() {
        AccordionLayout.prototype.initialize.call(this, { storeName: 'payment_rtp-accordionStates' });
        this.notificationKeys = [];
        this.paymentsReceivedCount = 0;
        this.paymentsSubmittedCount = 0;
        this.contextDef = ContextApi.menuContext.getContext('RTP_SUB_VIEW');
        this.listenTo(this.appBus, 'grid:refreshed', this.updateNotifications);
    },

    onRender/* istanbul ignore next */() {
        if (this.hasLoadedRequiredData()) {
            this.setupToggle();
            this.setupPoller();
            this.openSetAccordions();
        } else {
            this.loadRequiredData();
        }
    },

    /**
     * @description Event handler for accordions
     * @param {object} e - the mouse event
     */
    handleOpen/* istanbul ignore next */(e) {
        const accordionId = this.$(e.currentTarget).data('accordionid');
        this.showGrid(this.setGridData(accordionId));
        this.storeAccordionStates(accordionId, true);
    },

    /**
     * [setGridData description]
     * @param  {string} accordionId which accordion is opening
     */
    setGridData(accordionId) {
        let opts = {};

        // get active tab id
        if (accordionId === 'paymentsSubmitted' && !this.rtpSubmittedPaymentsRegion.currentView) {
            opts = {
                paymentsOpts: this.gridData.paymentsSubmittedOpts,
                gridView: this.views[0],
                currentRegion: this.rtpSubmittedPaymentsRegion,
            };
        } else if (accordionId === 'paymentsReceived' && !this.rtpReceivedPaymentsRegion.currentView) {
            opts = {
                paymentsOpts: this.gridData.paymentsReceivedOpts,
                gridView: this.views[1],
                currentRegion: this.rtpReceivedPaymentsRegion,
            };
        }
        return opts;
    },

    showGrid(opts) {
        if (util.isEmpty(opts)) {
            return;
        }

        const { gridView, currentRegion } = opts;

        currentRegion.show(gridView);
    },

    createViews() {
        const submittedGridView = this.createGrid(this.gridData.paymentsSubmittedOpts);
        const receivedGridView = this.createGrid(this.gridData.paymentsReceivedOpts);
        const views = [submittedGridView, receivedGridView];

        return views;
    },

    getViewEntitlements(gridView) {
        return Entitlements.getEntitlements(gridView.viewOptionRequirements());
    },

    /**
     * Retrieve entitlements data and create views
     */
    loadRequiredData/* istanbul ignore next */() {
        // create views and check entitlements
        this.views = this.createViews();

        // get Entitlements
        const entitlements = this.views.map(view => this.getViewEntitlements(view));

        /*
         * delete unused views
         * views.forEach(view => view.close());
         */

        Promise.all(entitlements)
            .then((results) => {
                this.submittedEntitlements = results[0].actions;
                this.subEntitlement = results[0].actions.SELECT;
                this.recEntitlement = results[1].actions.SELECT;
                this.setHasLoadedRequiredData(true);
                this.render();
            });
    },

    createGrid(gridData) {
        return new PaymentGrid(gridData);
    },

    /**
     * handles insert action from the RTP Workspace
     */
    insert() {
        this.storeAccordionStates('paymentsSubmitted', true);
        if (this.options.returnRoute) {
            store.set('current-workspace-route', this.options.returnRoute);
        }
        this.appBus.trigger('rtp:insertPayment');
    },

    /**
     * @name setupPoller
     * @description sets up the rtp pollers (received and submitted) based on the entitlements
     */
    setupPoller() {
        // based on the entitlements, set up notificationKeys
        if (this.subEntitlement) {
            this.notificationKeys.push('RTPCONVERSATION');
        }
        if (this.recEntitlement) {
            this.notificationKeys.push('RTPRECEIVEDCONVERSATION');
        }

        // create an poller for RTP to retrieve the unread message counts and message_states
        this.poller = new RTPPoller({
            interval: RTP_NOTIFICATION_INTERVAL,
            serviceUrl: services.generateUrl('/notifications/getNotificationData'),
            notificationKeys: this.notificationKeys,
            leading: true,
        });
        // subscribe to the rtp notifications
        this.notificationKeys.forEach((k) => {
            client.subscribeTo(k, true, this);
        });

        // set up the listener for the poller updates
        client.listenTo(client, 'notification:rtp', data => this.notificationUpdate(data));
    },

    /**
     * @name notificationUpdate
     * @description rtp notification (poller) handler.  The response will contain the
     * unread message count and a list of unread messages
     * @param {Object} response
     */
    notificationUpdate(response) {
        // need to check the which service and update accordingly
        const notificationMessages = response.notificationMessages || [];
        const validMessages = notificationMessages.filter(n => n.notificationTypeValid);
        if (validMessages && validMessages.length) {
            // length should be either 1 or 2
            validMessages.forEach((m) => {
                if (m.notificationType === 'RTPCONVERSATION') {
                    this.paymentsSubmittedCount = m.data.count;
                    if (this.currentGrid === SUBMITTED) {
                        this.updateMessageStates(m.data.rows);
                    }
                } else if (m.notificationType === 'RTPRECEIVEDCONVERSATION') {
                    this.paymentsReceivedCount = m.data.count;
                    if (this.currentGrid === RECEIVED) {
                        this.updateMessageStates(m.data.rows);
                    }
                }
            });
            this.updateUnreadMessageCounts();
        }
    },

    /**
     * @name updateUnreadMessageCounts
     * @description updates the message counts in the tabs for the
     * submitted and received payments tabs
     */
    updateUnreadMessageCounts() {
        if (Number.isInteger(this.paymentsSubmittedCount)) {
            this.$('[data-hook="getSubmittedCount"]').text(this.paymentsSubmittedCount);
        }
        if (Number.isInteger(this.paymentsReceivedCount)) {
            this.$('[data-hook="getReceivedCount"]').text(this.paymentsReceivedCount);
        }
        this.$('[data-hook="getSubmittedChatBubble"]').toggleClass('unreadBig', this.paymentsSubmittedCount > 0);
        this.$('[data-hook="getReceivedChatBubble"]').toggleClass('unreadBig', this.paymentsReceivedCount > 0);
    },

    /**
     * @name updateMessageStates
     * @description updates the current rtp grid with data received from
     * the notification service based on INSTRUCTION_ID
     * the data will include the message state and last message timestamp
     * @param {object} data
     */
    updateMessageStates(data) {
        if (data && data.length) {
            if (this.currentGridView.gridView && this.currentGridView.gridView.grid
                && this.currentGridView.gridView.grid.gridCollection) {
                // get the current grid models
                const { models } = this.currentGridView.gridView.grid.gridCollection;
                /*
                 * loop thru each notification response &
                 * update the corresponding payment grid row
                 */
                data.forEach((item) => {
                    this.updateRTPGrid(item, models);
                });
            }
        }
    },

    /**
     * @name updateRTPGrid
     * @description updates the current RTP grid with the response
     * from the notification service
     * @param {object} updateInfo - notification response
     * @param models
     */
    updateRTPGrid(updateInfo, models) {
        const id = updateInfo.rowId; // this is the payment's identified
        const info = updateInfo.columns;
        const row = models.find(m => m.get('INSTRUCTION_ID') === id) || models.find(m => m.get('TNUM') === id);
        if (row) {
            this.updateRTPRow(row, info);
        }
    },

    /**
     * @name updateRTPRow
     * @description updates the grid row with the notification data
     * @param {object} row
     * @param updateInfo
     */
    updateRTPRow(row, updateInfo) {
        updateInfo.forEach((fld) => {
            row.set(fld.fieldName, fld.fieldValue);
        });
    },

    /**
     * @method onClose
     * @description - method that is invoked when the view is closed.
     * shut down the poller
     *
     */
    onClose() {
        if (this.poller) {
            this.poller.removeListeners();
        }
    },

    /**
     * @method updateNotifications
     * @description when the grid is refreshed, restart the poller
     */
    updateNotifications() {
        if (this.poller) {
            this.poller.disable();
            this.poller.enable(true);
        }
    },

    templateHelpers() {
        return {
            paymentsSubmittedCount: this.paymentsSubmittedCount,
            paymentsReceivedCount: this.paymentsReceivedCount,
            submittedEntitlement: this.subEntitlement,
            receivedEntitlement: this.recEntitlement,
            insertEntitlement: this.submittedEntitlements && this.submittedEntitlements.INSERT,
        };
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'RTP',
    view: RealTimePayments,
    options: {},
});

export default RealTimePayments;
