import Model from '@glu/core/src/model';
import services from 'services';
import http from '@glu/core/src/http';

export default Model.extend({
    initialize(options) {
        this.targetId = options.targetId;
    },

    sync(method, model, options) {
        const data = {
            requestHeader: {},

            inquiryRequest: {
                startRow: 1,
                rowsPerPage: 1,

                searchCriteria: {
                    searchFields: [{
                        operator: '=',
                        dataType: 'number',
                        fieldName: 'SEQNO',
                        fieldValue: [this.targetId],
                    }],

                    inquiryId: '29053',
                    searchType: '5',
                    operationalData: false,
                },
            },
        };

        http.post(services.inquiryData, data, (response) => {
            options.success(response);
        }, () => {
            options.error();
        });
    },

    parse(response) {
        let columns = [];
        const obj = {};

        if (response.inquiryResponse.rows.length > 0) {
            ([{ columns }] = response.inquiryResponse.rows);
        }

        for (let i = 0; i < columns.length; i += 1) {
            obj[columns[i].fieldName] = columns[i].fieldValue;
        }

        return obj;
    },
});
