import React from 'react';
import PropTypes from 'prop-types';
import { XCircleNextIcon } from '@glu/icons-react';
import { withStyles } from '@glu/theming';
import { AccessibilityText } from '@glu/utilities-react';
import locale from '@glu/locale';
import { styles } from './FilterChip.styles';

export function FilterChipComponent({
  classes, filterConditions, hidden, htmlId, onFilterRemove, onFilterSelect
}) {
  const {
    buttonContainer, chipCount, chipName, chipWrapper, filterToggle, removeButton, toggleButton
  } = classes;
  const { nameDisplay } = filterConditions[0];
  return (
    <div className={chipWrapper}>
      <div className={chipName} data-qa={`${htmlId}-${nameDisplay}`}>
        {/* becomes unreadable with eslint */}
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        {nameDisplay} <span className={chipCount}>({filterConditions.length})</span>
      </div>
      {!hidden && (
        <div className={classes.filterConditions}>
          {filterConditions.map((filterCondition) => (
            <div className={filterToggle} key={filterCondition.id} data-qa={`${htmlId}-${filterCondition.criteriaDisplay}`}>
              <div className={buttonContainer}>
                <button
                  className={toggleButton}
                  type="button"
                  onClick={() => onFilterSelect(filterCondition)}
                >
                  {filterCondition.criteriaDisplay}
                </button>
                <button
                  className={removeButton}
                  type="button"
                  onClick={() => onFilterRemove(filterCondition)}
                >
                  <AccessibilityText>{locale.get('dataComponents.remove')}</AccessibilityText>
                  <XCircleNextIcon noIconWrapper focusable={false} />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

const commonFilterChipProps = {
  /** JSS classes */
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  filterConditions: PropTypes.arrayOf(PropTypes.shape({
    criteriaDisplay: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    nameDisplay: PropTypes.string.isRequired
  })).isRequired,
  /** Are the chips all hidden so we shouldn't display anything but the name/count */
  hidden: PropTypes.bool.isRequired,
  /** Html id to use on the container element */
  htmlId: PropTypes.string.isRequired
};

FilterChipComponent.propTypes = {
  ...commonFilterChipProps,

  /** Callback to call with the filter when the user removes one (X clicked) */
  onFilterRemove: PropTypes.func.isRequired,

  /** Callback to call with the filter when the user selects one (label clicked) */
  onFilterSelect: PropTypes.func.isRequired
};

const StyledFilterChipComponent = withStyles(styles)(FilterChipComponent);

StyledFilterChipComponent.propTypes = FilterChipComponent.propTypes;

/**
 * Simplified FilterChip with single click handler for both buttons.
 * Provides backward compatibility with @glu/data-components <= 1.0.0
 */
export const SimpleFilterChip = ({ onChange, ...props }) => (
  <StyledFilterChipComponent {...props} onFilterRemove={onChange} onFilterSelect={onChange} />
);

SimpleFilterChip.propTypes = {
  ...commonFilterChipProps,
  /** Callback to call with the filter data when the user clicks any of chip buttons */
  onChange: PropTypes.func.isRequired
};

export default StyledFilterChipComponent;
