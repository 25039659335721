var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"fxReports.page.title",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":64}}}))
    + "</h1>\n</div>\n\n<div class=\"section section-container\">\n    <div class=\"section-header\">\n        <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"fxReports.section.header",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":49}}}))
    + "</h2>\n    </div>\n    <div class=\"section-body\">\n        <div class=\"fx-reporting-controls\">\n            <div class=\"btn-wrapper\">\n                <div class=\"btn-group widget-info-links\">\n                    <button type=\"button\" class=\"btn btn-tertiary refresh\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.refresh",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":82},"end":{"line":13,"column":109}}}))
    + "\" data-hook=\"refresh-button\"><span class=\"icon-refresh\"></span><span class=\"refresh-time\" data-hook=\"refresh-time\"></span></button>\n                </div>\n            </div>\n            <button id=\"fx-toggle\" type=\"button\" class=\"btn btn-tertiary\" data-action=\"showHideTotals\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"fxReports.hide.totals",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":103},"end":{"line":16,"column":137}}}))
    + "</button>\n        </div>\n        <div class=\"fx-payment-totals\">\n            <div>\n                <div class=\"fx-payment-title\">\n                    <div> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"fxReports.processed.totals",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":26},"end":{"line":21,"column":65}}}))
    + "</div>\n                    <div>\n                      <span class=\"processedTotal\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"allReports.Payments",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":59},"end":{"line":23,"column":91}}}))
    + "\n                        <span class=\"icon-info\"  data-toggle=\"popover\" data-trigger=\"hover\" data-placement=\"bottom\" data-title=\"All Accounts\" data-content=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"fxReports.totals.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":156},"end":{"line":24,"column":190}}}))
    + "\"></span>\n                    </div>\n                </div>\n                <div data-region=\"processedRegion\"></div>\n            </div>\n            <div>\n                <div class=\"fx-payment-title\">\n                    <div> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"fxReports.unprocessed.totals",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":26},"end":{"line":31,"column":67}}}))
    + "</div>\n                    <div>\n                       <span class=\"unprocessedTotal\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"allReports.Payments",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":62},"end":{"line":33,"column":94}}}))
    + "\n                        <span class=\"icon-info\"  data-toggle=\"popover\" data-trigger=\"hover\" data-placement=\"bottom\" data-title=\"All Accounts\" data-content=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"fxReports.totals.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":34,"column":156},"end":{"line":34,"column":190}}}))
    + "\"></span>\n                    </div>\n                </div>\n                <div data-region=\"unprocessedRegion\"></div>\n            </div>\n        </div>\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        <div class=\"btn-wrapper\">\n            <div class=\"btn-group widget-info-links\">\n                <button type=\"button\" class=\"btn btn-tertiary export\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.export",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":43,"column":77},"end":{"line":43,"column":103}}}))
    + "\" data-hook=\"export-button\"><span class=\"icon-export\"></span></button>\n                <button type=\"button\" class=\"btn btn-tertiary print\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":44,"column":76},"end":{"line":44,"column":101}}}))
    + "\" data-hook=\"print-button\"><span class=\"icon-print\"></span></button>\n            </div>\n        </div>\n        <div data-region=\"gridRegion\"></div>\n    </div>\n</div>\n";
},"useData":true});