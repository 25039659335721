import '../themeDefaults';

export default ({
  mobilegrid: {
    actionControl,
    focusColor
  },
  buttons
}) => ({
  actions: {
    paddingBottom: '1rem',
    '& button': {
      background: 'none',
      border: 0,
      borderLeft: `1px solid ${actionControl.borderColor}`,
      fontSize: actionControl.fontSize,
      textAlign: actionControl.textAlign,
      fontWeight: actionControl.fontWeight,
      fontFamily: actionControl.fontFamily,
      cursor: 'pointer',
      color: '#0070B9',
      padding: '0.5rem 1rem',
      '&:focus': {
        outlineColor: focusColor
      },
      '&:disabled': {
        color: buttons.tertiary.disabledColor,
        textDecoration: 'none',
        cursor: 'not-allowed'
      }
    },
    '& button:first-child': {
      borderLeft: 0,
      paddingLeft: actionControl.firstActionLeftPadding
    },
    '& button:after': {
      borderBottom: '1px solid transparent',
      content: '""',
      display: 'block',
      transition: actionControl.transition,
      width: '100%'
    },
    '& button:hover:after': {
      transition: actionControl.transition,
      borderColor: actionControl.borderColorHover
    },
    '& button:disabled:hover:after': {
      transition: actionControl.transition,
      borderColor: 'transparent'
    }
  },
  popover: {
    background: actionControl.popover.background,
    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0
    }
  }
});
