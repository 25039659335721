import serverConfigParams from 'system/webseries/models/configurationParameters';
import $ from 'jquery';
import dialog from '@glu/dialog';
import http from '@glu/core/src/http';
import services from 'services';
import locale from '@glu/locale';
import { appBus, util } from '@glu/core';
import Controller from 'system/baseController';

const LogoutTimer = Controller.extend({
    initialize() {
        this.idleWarning = serverConfigParams.get('idleWarning') ? serverConfigParams.get('idleWarning') : 2;
        this.userTimeout = serverConfigParams.get('userTimeout') ? serverConfigParams.get('userTimeout') : -1;
        this.shouldRun = true;
        if (this.userTimeout - this.idleWarning < 0) {
            this.shouldRun = false;
        }
        this.updateSession = util.throttle(() => {
            http.get(`${services.userInfo}?${Math.random()}`);
        }, 60000);
    },

    clearTimeouts() {
        clearTimeout(this.logoutTimer);
        clearTimeout(this.sessionIdleTimer);
    },

    lastChanceTimer() {
        const self = this;
        this.clearTimeouts();
        this.logoutTimer = setTimeout(() => {
            self.logout();
        }, this.idleWarning * 60000);
    },

    sessionWatcherKeepAlive(evt) {
        const self = this;
        if (evt.data && evt.data.type === 'keepSessionAlive') {
            http.get(
                `${services.userInfo}?${Math.random()}`,
                () => {
                    self.sessionWatcher();
                    window.parent.postMessage(evt.data, '*');
                },
            );
        }
    },

    sessionWatcher() {
        if (!this.shouldRun) {
            return;
        }
        this.updateSession();
        this.clearTimeouts();
        const self = this;

        this.sessionIdleTimer = setTimeout(() => {
            self.lastChanceTimer();
            self.confirm();
            // value is in minutes.. multiply by 60000 to get milliseconds
        }, (this.userTimeout - this.idleWarning) * 60000);
    },

    confirm() {
        const self = this;
        dialog.confirm(locale.get('common.autologout.message'), locale.get('common.autologout.title'), (ok) => {
            if (ok) {
                /*
                 *  NH-111271 changed to keepAlive so that the keepAlive ping
                 *  to portal can be sent.
                 */
                http.get(
                    `${services.keepAlive}?${Math.random()}`,
                    () => {
                        self.sessionWatcher();
                    },
                    () => {
                        self.logout();
                    },
                );
                return;
            }
            self.logout();
        });
    },

    watch() {
        $(document).on('keyup.sessionWatcher, click.sessionWatcher, change.sessionWatcher', util.bind(this.sessionWatcher, this));
        this.listenTo(appBus, 'router:navigate', this.sessionWatcher);
        /*
         *  listener for the keepSessionAlive event, to refresh the session and reset the timer
         * on the message that is displayed if the user has been inactive
         *  This code was added to enable portals to request a refresh of the DGB session
         *  This listener handles the event name sent by the MainFrameController
         * which is instantiated when DGB screens are displayed in iFrames
         */
        this.listenTo(appBus, 'keepSessionAlive', this.sessionWatcherKeepAlive);
        this.sessionWatcher();
    },

    onClose() {
        $(document).off('.sessionWatcher');
        this.clearTimeouts();
    },
});

export default {
    /*
     * NH-10784
     * end return block.
     */
    autoLogoutTimer() {
        const userTimeout = serverConfigParams.get('userTimeout') ? serverConfigParams.get('userTimeout') : -1;
        if (userTimeout > 0) {
            const x = new LogoutTimer();
            x.watch();
        }
    },
};
