import Model from '@glu/core/src/model';
import serviceList from 'services';
import locale from '@glu/locale';
import validatorPatterns from 'system/validatorPatterns';
import transform from 'common/util/transform';
import { postData } from 'common/util/services';

export default Model.extend({

    /**
     * initializes model
     *
     * @param {Context} context - context for model
     */
    initialize(context) {
        this.idAttribute = 'ANALYTICSSERVERID';
        this.context = context;
        this.validators = {
            SERVERURL: {
                exists: true,
                description: locale.get('ANALYTIC.SERVERURL.label'),
                matches: validatorPatterns.NOBADCHARS_PATTERN,
            },
            USERNAME: {
                exists: true,
                description: locale.get('ANALYTIC.USERNAME.label'),
                matches: validatorPatterns.NOBADCHARS_PATTERN,
            },
            PASSWORD: {
                exists: true,
                description: locale.get('ANALYTIC.PASSWORD.label'),
                matches: validatorPatterns.NOBADCHARS_PATTERN,
            },
            DATACOLLECTIONENABLED: {
                exists: true,
                description: locale.get('ANALYTIC.DATACOLLECTIONENABLED.label'),
            },
        };
    },

    /**
     * Save updates to the server
     *
     * @param  {string} method - The sync method
     * @param  {Model} model - this Model
     * @return {Promise} - A jQuery Promise
     */
    sync(method, model) {
        switch (method) {
        case 'update':
            return postData(
                `${serviceList.analyticsServerSettings}/update`,
                this.getSaveData(model),
            );
        case 'read':
            return postData(
                `${serviceList.analyticsServerSettings}/read`,
                this.getReadPayload(),
            ).then((response) => {
                model.set(this.parse(response));
            });
        default:
            return undefined;
        }
    },

    /**
     * Convert the response object to attribute hash
     *
     * @param {Object} response - response
     * @returns {Object} newly transform object which can be use to set the model
     */
    parse(response) {
        return transform.pairsToHash(response.item, 'name', 'value');
    },

    /**
     * Get the payload data for the read request
     * @returns {Object}
     */
    getReadPayload() {
        return {
            item: [
                {
                    name: 'ANALYTICSSERVERID',
                    value: 1,
                },
            ],
        };
    },

    /**
     * Generate the data object for update (save)
     *
     * @param  {Model} model - this Model
     * @return {object} - The data object to save
     */
    getSaveData(model) {
        return {
            item: transform.hashToPairs(model.toJSON(), 'name', 'value'),
        };
    },
});
