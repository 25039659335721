import CollectionView from '@glu/core/src/collectionView';
import Approver from './approver';

export default CollectionView.extend({
    itemView: Approver,
    className: 'PanelApproval-list-wrapper',

    itemViewOptions(model, index) {
        return {
            index,
            approverTypes: this.options.approverTypes,
        };
    },
});
