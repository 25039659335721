import ItemView from '@glu/core/src/itemView';
import template from './buttonView.hbs';

export default ItemView.extend({
    tagName: 'button',
    template,

    events: {
        click: 'onClick',
    },

    className: 'btn',

    initialize() {
        this.listenTo(this.model, 'change', this.refresh);
    },

    onClick() {
        // override in a subclass
    },

    refresh() {
        /*
         * override in a subclass
         * the function will be called every time the model changes
         * you can do things like disable/enable button or add/remove classes, cases
         * where re-rendering the button would be overkill
         */
    },

    label() {
        return this.options.label || '';
    },

    icon() {
        return this.options.icon;
    },

    iconClass() {
        return `icon-${this.icon()}`;
    },

    hasFontIcon() {
        return !!this.icon() && !this.svg();
    },

    svg() {
        const icons = {
            refresh: '<svg class="icon" version="1.1" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="16" height="16" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve"><path role="presentation" d="M16,9l-0.1,0.4c-0.7,3.7-3.9,6.1-7.7,6.1c-2.1,0-4.1-0.8-5.6-2.3l-1.1,0.7l-0.4-5l4.7,2.2L4.6,12c1,0.9,2.3,1.4,3.7,1.4    c2.6,0,4.9-1.7,5.4-4.2V9H16z M2.7,6.6c0.5-2.5,2.8-4.3,5.4-4.3c1.4,0,2.6,0.5,3.7,1.4l-1.3,0.9l4.7,1.9l-0.4-4.9l-1.1,0.8C12.2,0.8,10.3,0,8.1,0c-3.8,0-7,2.7-7.7,6.4L0.3,7h2.3L2.7,6.6z"/></svg>',
        };

        return icons[this.icon()] || '';
    },

    templateHelpers() {
        return {
            label: this.label(),
            iconClass: this.iconClass(),
            svg: this.svg(),
            hasFontIcon: this.hasFontIcon(),
        };
    },
});
