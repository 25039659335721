import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/code';
import 'tinymce/plugins/image';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/emoticons';

import ItemView from '@glu/core/src/itemView';
import Model from '@glu/core/src/model';
import Collection from '@glu/core/src/collection';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import $ from 'jquery';
import Handlebars from 'handlebars';
import services from 'services';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import widgetEditTmpl from './widgetEdit.hbs';

const DynamicWidgetContentModel = Model.extend({
    idAttribute: 'widgetId',

    sync(method, collection, options) {
        if (method === 'read') {
            const readurl = services.generateUrl('/cxp/externalWidget/getWidgetContent');

            http.post(readurl, this.toJSON(), (result) => {
                options.success(result);
            }, (result) => {
                options.error(result);
            });
        }
        if (method === 'update') {
            const saveurl = services.generateUrl('/cxp/externalWidget/saveWidgetContent');

            http.post(saveurl, this.toJSON(), (result) => {
                options.success(result);
            }, (result) => {
                options.error(result);
            });
        }
    },
});

const WidgetContentModel = Model.extend({
    sync(method, collection, options) {
        if (method === 'read') {
            const url = services.generateWidgetUrl(`${this.get('widgetId')}/index.html`);

            http.get(url, (response) => {
                options.success(response);
            }, (result) => {
                options.error(result);
            });
        }
    },

    parse(response) {
        return {
            content: response,
        };
    },
});

const WidgetFileListCollection = Collection.extend({
    initialize(models, options) {
        this.widgetId = options.widgetId;
    },

    sync(method, collection, options) {
        if (method === 'read') {
            const listUrl = services.generateUrl('/cxp/externalWidget/listWidgetContent');

            http.post(
                listUrl,
                {
                    widgetId: this.widgetId,
                },
                (result) => {
                    options.success(result);
                },
                (result) => {
                    options.error(result);
                },
            );
        }
    },

    parse(data) {
        const self = this;
        const retArray = [];
        const imageSearch = `/widgets/${this.widgetId}/images/`;

        util.each(data.nodeList, (nodeAddress) => {
            if (nodeAddress.indexOf(imageSearch) > -1) {
                const img = nodeAddress.slice(imageSearch.length);
                retArray.push({
                    id: img,
                    address: services.generateWidgetUrl(`${self.widgetId}/images/${img}`),
                });
            }
        });
        return retArray;
    },
});

export default ItemView.extend({
    template: widgetEditTmpl,
    loadingTemplate: loadingPageTmpl,

    initialize(options) {
        this.widgetId = options.widgetId;
        this.segment = options.segment;

        this.dynamicWidgetContent = new DynamicWidgetContentModel({
            marketSegment: this.segment,
            widgetId: this.widgetId,
        });

        this.widgetContent = new WidgetContentModel({
            widgetId: this.widgetId,
        });

        this.widgetFileList = new WidgetFileListCollection(
            {},
            {
                widgetId: this.widgetId,
            },
        );

        this.tinyMCEEditors = [];
    },

    onRender() {
        const self = this;

        if (this.hasLoadedRequiredData()) {
            const resources = util.extend(
                {},
                {
                    resourceUrl: services.generateWidgetUrl(`${this.widgetId}/`),
                    proxyUrl: services.generateServletUrl(`${this.widgetId}/`),
                },
                this.dynamicWidgetContent.get('htmlKeyValues'),
            );

            // figure out list of edits and put default stuff in place if needed
            let html = Handlebars.compile(this.widgetContent.get('content'))(resources);
            html = Handlebars.compile(html)(resources);

            // FIXME: Injecting Double Templated server-side markup has no obvious mitigation.
            this.$el.html(html);

            this.$('[data-edit]').each((index, element) => {
                const $el = $(element);

                // Fix apparently empty areas
                if ($el.html() === '') {
                    $el.text('Empty Data');
                }

                const editorId = $el.attr('data-edit');

                window.tinymce.EditorManager.init({
                    selector: `[data-edit="${editorId}"]`,
                    menubar: false,
                    plugins: 'image code',
                    toolbar: 'bold italic underline | alignleft aligncenter alignright | styleselect fontselect fontsizeselect | hr link insertdatetime emoticons image code | undo cancel',
                    relative_urls: false,
                    image_list: self.getTinyMCEImageList(),
                    inline: true,
                    skin_url: `${window.Bottomline.assetRoot}/skins/ui/oxide`,
                });

                self.tinyMCEEditors[index] = editorId;
            });

            this.trigger('readyForEdits', this);
        } else {
            this.loadRequiredData();
        }
    },

    getTinyMCEImageList() {
        const retArray = [];
        this.widgetFileList.each((model) => {
            retArray.push({
                title: model.get('id'),
                value: model.get('address'),
            });
        });
        return retArray;
    },

    loadRequiredData() {
        const self = this;

        const dynamicWidgetContentPromise = new Promise((resolve, reject) => {
            self.dynamicWidgetContent.fetch({
                success: resolve,
                error: reject,
            });
        });

        const widgetContentPromise = new Promise((resolve, reject) => {
            self.widgetContent.fetch({
                success: resolve,
                error: reject,
            });
        });

        const widgetFileListPromise = new Promise((resolve, reject) => {
            self.widgetFileList.fetch({
                success: resolve,
                error: reject,
            });
        });

        Promise.all([
            dynamicWidgetContentPromise,
            widgetContentPromise,
            widgetFileListPromise,
        ]).then(() => {
            self.setHasLoadedRequiredData(true);
            self.render();
        });
    },

    saveWidgetContent() {
        const htmlKeyValues = {};

        for (let i = 0; i < this.tinyMCEEditors.length; i += 1) {
            htmlKeyValues[this.tinyMCEEditors[i]] = window.tinymce
                .EditorManager.editors[i].getContent();
        }

        this.dynamicWidgetContent.set({
            htmlKeyValues,
        });

        this.dynamicWidgetContent.save();
    },
});
