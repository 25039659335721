import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    TERTIARY,
} from '@glu/buttons-react';
import { withStyles } from '@glu/theming';
import { asView } from '../../../common/util/reactUtil';

const propTypes = {
    /** JSS classes used for styling */
    classes: PropTypes.objectOf(PropTypes.string),
    /** Click handler for button click */
    onClick: PropTypes.func.isRequired,
    /** Backbone Model passed to custom cell */
    model: PropTypes.shape({
        get: PropTypes.func.isRequired,
        attributes: PropTypes.shape({
            NAME: PropTypes.string.isRequired,
        }),
    }).isRequired,
    /** Button variant (PRIMARY, SECONDARY, TERTIARY from @glu/buttons-react) */
    variant: PropTypes.string,
};

const defaultProps = {
    classes: {},
    variant: TERTIARY,
};

const styles = {
    root: {
        fontSize: 12,
        padding: props => ((props.variant === TERTIARY) ? 0 : 5),
    },
};

const ButtonCell = (props) => {
    const {
        classes, model, onClick, variant,
    } = props;
    const [featureName] = useState(model.get('NAME'));

    const handleClick = (e) => {
        onClick(e, {
            model,
        });
    };

    return (
        <Button
            onClick={handleClick}
            variant={variant}
            className={classes.root}
        >
            {featureName}
        </Button>
    );
};

ButtonCell.propTypes = propTypes;
ButtonCell.defaultProps = defaultProps;

const StyledButton = withStyles(styles)(ButtonCell);

/**
 * Provide a utility function for leveraging this in a Backbone context
 * @param {Object} options - Options/props to be sent along to wrapped component
 */
export const createButtonCellView = options => asView(StyledButton, options);

export const BareButton = ButtonCell;
export default StyledButton;
