import gluStore from '@glu/store';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import { setHeaders } from '@dbiqe/common';
import configuration from 'system/configuration';

// Store a header
const headers = {};

export default {
    /**
     * Return a fresh object of the headers
     * @return {{}}
     */
    getHeaders() {
        return util.extend({}, headers);
    },

    addHeader(key, value) {
        headers[key] = value;
    },

    isMobile() {
        return !!window.mobileInfo;
    },

    checkMobile() {
        // This is set from the native app as soon as possible
        if (!this.isMobile()) {
            return false;
        }
        this.addHeader('X-NativeApp', 'true');
        this.addHeader('X-MobileInfo', JSON.stringify(window.mobileInfo));
        return true;
    },

    devSetup() {
        if (configuration.isDev() && gluStore.get('token')) {
            this.addHeader('__token__', gluStore.get('token'));
        }
    },

    config() {
        this.checkMobile();
        this.devSetup();
        setHeaders();
        if (!util.isEmpty(headers)) {
            http.setRequestHeaders(headers);
        }
    },
};
