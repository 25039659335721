export default ({ palette }) => ({
    charCountTextArea: {
        backgroundColor: '#434e6e',
        border: 'none',
        color: palette.text.light,
        height: 'auto',
        marginBottom: '4px',
        width: '100%',
        paddingLeft: '15px',
        paddingRight: '10px',
        paddingBottom: '0px',
        '&::placeholder': {
            color: palette.grey.G500,
        },
    },

    remainingChars: {
        fontSize: '12px',
        fontStyle: 'italic',
    },

    helpBlock: {
        color: '#ff9494',
        fontSize: '12px',
    },

    error: {
        border: '1px solid #A8150D',
    },
});
