import React from 'react';
import locale from '@glu/locale';
import store from 'pcm/common/store';
import ContentLoading from 'pcm/common/components/ContentLoading';

const CustomFieldsEdit = React.lazy(() => import('./CustomFieldsEdit'));
const CustomFieldsList = React.lazy(() => import('./CustomFieldsList'));
const CustomFieldsAdd = React.lazy(() => import('./CustomFieldsAdd'));
const CustomFieldsView = React.lazy(() => import('./CustomFieldsView'));

const getActionToTitleOptions = () => ({
    title: {
        add: locale.get('customFields.newCustomField'),
        edit: locale.get('customFields.modifyCustomField'),
        view: locale.get('customFields.viewCustomField'),
        list: locale.get('customFields.customFieldsList'),
    },
    backLink: '/ui/ADMINSTRATION/custom-fields',
});

export default {
    pcmCustomFields() {
        const actionToTitleOptions = getActionToTitleOptions();

        this.showPage(
            actionToTitleOptions.title.list,
            <React.Suspense fallback={<ContentLoading />}>
                <div className="section section-container custom-fields">
                    <CustomFieldsList />
                </div>
            </React.Suspense>,
            {
                headlineText: actionToTitleOptions.title.list,
            },
        );
    },
    pcmCustomFieldsActions(action) {
        const components = {
            add: CustomFieldsAdd,
            edit: CustomFieldsEdit,
            view: CustomFieldsView,
            list: CustomFieldsList,
        };

        store.action = action;
        store.alerts = {};

        const actionToTitleOptions = getActionToTitleOptions();

        const ComponentToShow = components[action];

        this.showPage(
            actionToTitleOptions.title[action],
            <React.Suspense fallback={<ContentLoading />}>
                <div className="custom-fields">
                    <ComponentToShow action={action} />
                </div>
            </React.Suspense>,
            {
                headlineText: actionToTitleOptions.title[action],
                backLink: actionToTitleOptions.backLink,
            },
        );
    },
};
