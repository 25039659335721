import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import services from 'services';
import http from '@glu/core/src/http';
import transform from 'common/util/transform';
import PaymentAccountModel from '../models/paymentAccount';

export default Collection.extend({
    model: PaymentAccountModel,

    sync(method, collection, options) {
        let xhr;

        if (method === 'read') {
            const url = services.generateUrl('/inquiry/getQueryResults');

            const request = {
                requestHeader: {
                    queryOffset: 0,
                    queryPagesize: 0,
                    requestId: '',
                },

                IncludeMapData: 1,

                queryCriteria: {
                    action: {
                        typeCode: 'BILL',
                        entryMethod: 0,
                        actionMode: 'SELECT',
                        productCode: 'BOS',
                        functionCode: 'MAINT',
                    },

                    fieldName: false,
                    inquiryId: '19813',
                    allowDuplicates: true,
                    queryType: 'Query',
                },

                formatterType: 'Query',
            };

            xhr = http.post(url, request, options.success, options.error);
        }

        this.trigger('request', collection, xhr, options);
        return xhr;
    },

    parse(res) {
        let parsed = [];
        if (res.queryResponse && res.queryResponse.QueryData
            && res.queryResponse.QueryData.queryRows) {
            parsed = util.map(res.queryResponse.QueryData.queryRows, obj => transform.pairsToHash(obj.mapDataList, 'toField', 'value'), this);
        }

        return parsed;
    },

    getComboBoxArray() {
        return this.map(model => ({
            id: model.get('DISPLAYFIELD'),
            text: model.get('DISPLAYFIELD'),
        }));
    },
});
