import services from 'services';
import { postData } from 'common/util/services';
import userInfo from 'etc/userInfo';
import Formatter from 'system/utilities/format';
import util from '@glu/core/src/util';
import constants from 'common/dynamicPages/api/constants';
import transform from 'common/util/transform';
import QuickTransferWidgetService from 'app/payments/services/quickTransferWidget';
import interfaceUtil from './util';

const accountAttributes = {
    ACCOUNT_NUMBER_UNMASKED: 'accountNumber',
    BANKCODE: 'bankCode',
    CLIENTACCOUNTNAME: 'accountName',
    CURRENCYCODE: 'currencyCode',
    ACCOUNTFILTER: 'accountFilter',
};

export default {
    // productCode~functionCode~typeCode
    entitlementKey: 'RTGS~INST~TRANSFER',
    requestKeys: constants.QUICK_TRANSFER_REQUEST_KEYS,
    /**
     * Transfer funds from one account to another on the specified
     * date with the specified amount
     * @param {Object} data
     * @param {string} data.ACCOUNTFILTER - Debit (from) bank code and account number
     * concatenated, ie: BONY-14432343
     * @param {string} data.BENE_ACCOUNTENTITLEMENT - Credit (to) bank code and
     * account number
     * concatenated, ie: BONY-14432343
     * @param {string} data.CREDIT_AMOUNT
     * @param {string} data.TRAN_DATE
     * @returns {Promise}
     */
    transfer(data = {}) {
        return interfaceUtil.dataHasProps(this.requestKeys.requiredDataProps, data)
            .then(this.buildTransferDataObj.bind(this))
            .then(interfaceUtil.dataHasProps.bind(this, this.requestKeys.fullRequestPropsSet))
            .then(this.postTransfer.bind(this))
            .then(response => JSON.stringify(response))
            .catch(error => Promise.reject(JSON.stringify(error)));
    },

    /**
     * Submit data to the rest endpoint to create a new transfer
     * @param {*} data
     * @returns {Promise}
     */
    postTransfer(data) {
        return postData(
            services.generateUrl('payment/transfer/multi/ADD'),
            {
                functionCode: 'INST',
                setEntryMode: 'SINGLE',
                items: [
                    {
                        item: transform.hashToPairs(data, 'name', 'value'),
                    },
                ],
                saveWithWarning: false,
            },
        );
    },

    /**
     * Fetch to and from accounts and combine into one list
     * @returns {Promise}
     */
    getAccounts() {
        const defaultData = {
            subType: 'SINGLE',
            typeCode: 'TRANSFER',
            productCode: 'RTGS',
            functionCode: 'INST',
        };

        const getToAccounts = interfaceUtil.getAccounts(util.extend({}, defaultData, {
            fieldName: 'BENE_ACCOUNT',
        }));
        const getFromAccounts = interfaceUtil.getAccounts(util.extend({}, defaultData, {
            fieldName: 'DEBIT_ACCOUNT_NUMBER',
        }));
        return Promise.all([getToAccounts, getFromAccounts])
            .then(response => ({
                accounts: this.combineAccounts(response),
                entitlement: this.entitlementKey,
            }));
    },

    /**
     * Using extractData, convert pairs to hash with a remap of the keysand then extend
     * the object with any additional data
     * @param {Object} [additionalData]
     * @param {Object} row - default row object from DGB response
     */
    flattenRow(additionalData = {}, row) {
        const data = interfaceUtil.extractData(row.columns, accountAttributes);
        return util.extend(data, additionalData);
    },

    /**
     * Evaluate to and from response and combine the list into a single acocunt list
     * with additional properties to identify as a valid to/from account
     * @param {Array} response
     * @returns {Array} array of combined to/from accounts
     */
    combineAccounts(response) {
        const [toAccounts, fromAccounts] = response;
        let allAccounts = toAccounts.rows.map(this.flattenRow.bind(this, {
            toAccount: true,
            fromAccount: false,
        }));
        allAccounts = fromAccounts.rows.reduce((accum, row) => {
            let combinedAccounts = accum;
            // Flatten this account and add the additional data
            const fromAccount = this.flattenRow({
                toAccount: false,
                fromAccount: true,
            }, row);
            // Search the accumulator (toAccounts) to see if we have a matching account num
            const existingAccount = util.find(
                accum,
                toRow => toRow.accountNumber === fromAccount.accountNumber
                    && toRow.bankCode === fromAccount.bankCode,
            );

            // When no match is found, add the fromAccount to the overall list
            if (existingAccount === undefined) {
                combinedAccounts = [...accum, fromAccount];
            } else {
                // Have a match, indicate that it is also a fromAccount
                existingAccount.fromAccount = true;
            }
            return combinedAccounts;
        }, allAccounts);

        return allAccounts;
    },

    /**
     * build the appropriate transfer request object
     * @param {Object} data
     * @returns {Promise} complete data object to be sent to service
     */
    buildTransferDataObj(data) {
        return new Promise((resolve, reject) => {
            let postedData = util.pick(data, this.requestKeys.requiredDataProps);
            const date = Formatter.formatDate(postedData.TRAN_DATE, userInfo.getDateFormat());
            postedData = util.extend(postedData, {
                TRAN_DATE: date,
                VALUE_DATE: date,
                USERGROUP: userInfo.get('group'),
            });

            Promise.all([
                this.getAccountInfo('from', data.ACCOUNTFILTER),
                this.getAccountInfo('to', data.BENE_ACCOUNTENTITLEMENT),
            ]).then((results) => {
                resolve(util.extend(
                    {},
                    // from account supplementary info
                    results[0],
                    // to account supplementary info
                    results[1],
                    // posted data
                    postedData,
                    // default request values
                    this.requestKeys.defaults,
                ));
            }).catch(err => reject(err));
        });
    },

    /**
     * get account information to populate transfer request object
     * @param {string} type
     * @param {string} accountRef
     */
    getAccountInfo(type = '', accountRef = '') {
        let listToUse;
        let fieldToUse;
        let methodToUse;

        if (type === 'from') {
            listToUse = 'debitAcctList';
            fieldToUse = 'ACCOUNTFILTER';
            methodToUse = 'getFromAccounts';
        } else if (type === 'to') {
            listToUse = 'beneAcctList';
            fieldToUse = 'BENE_ACCOUNTENTITLEMENT';
            methodToUse = 'getToAccounts';
        }

        return new Promise((resolve, reject) => {
            if (!type) {
                reject(new Error('A valid type is required'));
            }

            QuickTransferWidgetService[listToUse].forEach((acct, idx) => {
                let dataList = acct.get('mapDataList');
                let acctNum;
                // If no data list, collections missing accounts, get this account info.
                if (!dataList) {
                    QuickTransferWidgetService[methodToUse]({
                        filter: {
                            term: accountRef.split('-')[1],
                        },
                    }).then((response) => {
                        const theModel = response.collection.findWhere({
                            ACCOUNTFILTER: accountRef,
                        });
                        dataList = theModel.get('mapDataList');
                        if (dataList) {
                            resolve(util.pick(dataList, this.requestKeys[type]));
                        } else {
                            reject(new Error(`Unable to populate necessary ${type} account information.`));
                        }
                    }).catch((err) => {
                        reject(new Error(`Error requesting necessary supplementary account information: ${err.message}`));
                    });
                } else {
                    // make sure we get the right one account in collection
                    acctNum = dataList[fieldToUse];
                    if (acctNum === accountRef) {
                        resolve(util.pick(dataList, this.requestKeys[type]));
                    } else if (this[listToUse].length === idx - 1) {
                        reject(new Error(`Unable to populate necessary ${type} account information.`));
                    }
                }
            }, this);
        });
    },
};
