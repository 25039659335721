import locale from '@glu/locale';
import LoanPaymentLayout from 'app/smbPayments/views/accounts/balances/transfer/loanPaymentLayout';
import services from 'services';
import requestServices from 'common/util/services';
import constants from 'app/smbPayments/constants';
import serverConfigParams from 'system/webseries/models/configurationParameters';

export default LoanPaymentLayout.extend({
    ui: {
        ...LoanPaymentLayout.prototype.ui,
        $interestRateContainer: '[data-hook="getInterestRateContainer"]',
    },

    initialize(options) {
        LoanPaymentLayout.prototype.initialize.call(this, options);
        this.showInterestRate = serverConfigParams.get('LookupLoanDrawInterestRate') === 'true';
        this.variableRateSubtypes = serverConfigParams.get('LookupLoanDrawInterestRateAcctSubTypes')?.split(',') || [];
    },

    getHeader() {
        return locale.get('smbPayments.loan.draw.header');
    },

    onRender() {
        LoanPaymentLayout.prototype.onRender.call(this);
        // Only in view mode and when interest rates are configured to be shown/exist
        if (this.options?.action === 'select' && this.showInterestRate && this.model.get('INTEREST_RATE')) {
            this.ui.$interestRateContainer.removeClass('hide');
        }
    },

    templateHelpers(...args) {
        return {
            ...LoanPaymentLayout.prototype.templateHelpers.apply(this, args),
            debitAccountLabel: locale.get('smbPayments.loan.account'),
            creditAccountLabel: locale.get('SMBPAY.TransferTo'),
            hasMultipleOptions: false,
            displayInterestRate: this.showInterestRate,
        };
    },

    getTypeCode() {
        return 'LOANDRAW';
    },

    getServiceName() {
        return '/payment/loanDraw';
    },

    updatePaymentTotal() {
        // not needed for loan draw
    },

    beforeSubmit() {
        for (let x = 0; x < this.amountOfPaymentOptions; x += 1) {
            this.model.removeValidator(`AMOUNT_${x}`);
        }
        const debitModelValue = this.model.get('DEBIT_ACCOUNT_NUMBER');
        // update our new transaction amount
        this.model.set('DEBIT_AMOUNT', this.model.get('CREDIT_AMOUNT'));
        this.model.set('DEBIT_ACCOUNT_NUMBER', debitModelValue?.split('-')?.[1] || debitModelValue);
    },

    getDebitInquiryId() {
        return 19535;
    },

    getCreditInquiryId() {
        return 19536;
    },

    /**
     * Return the query field used to get the label for
     * the credit accounts
     * @returns {string}
     */
    getCreditQueryField() {
        return constants.ACCOUNTNAME_NUM_BANK;
    },

    /**
     * Based on the parameters, return the promise used to fetch the accounts
     * @param {Object} data
     * @param {string} fieldName
     * @param {boolean} isAccountArray
     * @returns {Promise}
     */
    getAccountBalancePromise(postData, fieldName, isAccountArray) {
        if (fieldName === 'DEBIT_ACCOUNT_NUMBER') {
            return this.getLoanAccountBalancePromise(
                isAccountArray ? services.loanBalancesReporting : services.loanReporting,
                postData,
            );
        }
        return requestServices.postData(
            isAccountArray ? services.currencyBalances : services.balanceAndTransaction,
            postData,
        );
    },

    /**
     * Return the query field used to get the label for
     * the debit accounts
     * @returns {string}
     */
    getDebitQueryField() {
        return constants.ACCOUNTNAME_DISPLAY;
    },
});
