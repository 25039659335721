import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import dateRangeUtil from 'app/balanceAndTransaction/api/dateRangeUtil';
import moment from 'moment';
import userInfo from 'etc/userInfo';
import Formatter from 'system/utilities/format';
import dialog from '@glu/dialog';

// The TransactionList jasper report expects the date in this format.
const FILTER_DATE_FORMAT = 'MM/DD/YYYY';

const PrintAccountUtil = {
    print(options) {
        const printModal = new PrintViewModal({
            exportModel: this.buildModel(options),
            exportURL: options.accountURL,
            title: 'export.printSummaryReport',
            viewId: 'printBTRModal',
            formMethod: 'get',
        });
        dialog.custom(printModal);
    },

    buildModel(options) {
        const btrPrintModel = {};
        btrPrintModel.outputFormat = 'PDF';
        btrPrintModel.pageType = 'LETTER';

        /*
         * accountSearchFields carries account section filters adjusted for single
         * account transactions.
         */
        if (options.filterModel.getSearchFields) {
            btrPrintModel.accountSearchFields = options.filterModel.getSearchFields();
        }
        if (options.inquiryId) {
            btrPrintModel.inquiryId = options.inquiryId;
        }
        btrPrintModel.filterValues = this.getFilterValues(options);
        if (options.searchFields) {
            btrPrintModel.searchFields = options.searchFields;
        }
        if (options.sortFields) {
            btrPrintModel.sortFields = options.sortFields;
        }
        if (options.sortFieldsDebit) {
            btrPrintModel.sortFieldsDebit = options.sortFieldsDebit;
        }
        if (options.includeSummaryInfo !== undefined) {
            btrPrintModel.includeSummaryInfo = options.includeSummaryInfo;
        }
        if (options.separated !== undefined) {
            btrPrintModel.separated = options.separated;
        }
        return btrPrintModel;
    },

    getFilterValues(options) {
        let startDate = options.filterModel.get('START_DATE');
        let endDate = options.filterModel.get('END_DATE');
        if (endDate.length < 1) {
            startDate = dateRangeUtil.convertCodesToDates(startDate);
            endDate = moment(new Date()).format(userInfo.getDateFormat());
        }
        const filterValues = {
            startDate: Formatter.formatDateFromUserFormat(startDate, FILTER_DATE_FORMAT),
            endDate: Formatter.formatDateFromUserFormat(endDate, FILTER_DATE_FORMAT),
            transactionsView: options.filterModel.get('accountTransactionsView'),
        };
        return filterValues;
    },
};

export default PrintAccountUtil;
