import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    children: PropTypes.node.isRequired,
    LoadingComponent: PropTypes.elementType.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

const Loader = ({
    children,
    LoadingComponent,
    isLoading,
}) => {
    if (isLoading) {
        return (
            <LoadingComponent />
        );
    }

    return (
        <div>
            { children }
        </div>
    );
};

Loader.propTypes = propTypes;

export default Loader;
