import Layout from '@glu/core/src/layout';
import ConfigListView from 'app/administration/views/configurations/configListView';
import store from 'system/utilities/cache';
import userInfo from 'etc/userInfo';
import { createTabsToggleButton, toggleTabs, setTabButtonText } from 'common/util/a11y/tabs';
import configurationsTmpl from './configurations.hbs';

export default Layout.extend({
    template: configurationsTmpl,
    defaultTab: 'BTR',

    regions: {
        pageRegion: '.pageRegion',
    },

    ui: {
        $navTabs: '[data-hook="getNavTabs"]',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
    },
    isInitialRender: true,

    events: {
        'click @ui.$navLinks': 'updateView',
    },

    initialize(options) {
        if (options.defaultTab) {
            this.defaultTab = options.defaultTab;
        }
        this.tabKey = `${userInfo.get('group')}-${userInfo.get('id')}-configurationTab`;
    },

    onRender() {
        const tab2open = store.get(this.tabKey) || this.defaultTab;
        createTabsToggleButton(this);
        this.openView(tab2open);
        this.isInitialRender = false;
    },

    updateView(e) {
        const href = this.$(e.currentTarget).attr('id');
        e.preventDefault();
        this.openView(href);
    },

    openView(href) {
        const options = {
            id: 'APPCONF_MAINT',
            selectedTab: href,

            enableSavedViews: false,

            additionalSearchFields: [{
                fieldName: 'TAB',
                operator: '=',
                dataType: 'string',
                fieldValue: [href],
            }],
        };

        this.changeTab(href);
        this.gridView = new ConfigListView(options);
        this.pageRegion.show(this.gridView);
    },

    changeTab(e) {
        this.$(`a[id$=${e}]`).parent('li').addClass('is-active').siblings()
            .removeClass('is-active');
        this.currentTabText = this.$(`a[id$=${e}]`).text();
        store.set(this.tabKey, e);
        if (this.isInitialRender) {
            setTabButtonText(this, this.currentTabText);
        } else {
            toggleTabs(this);
        }
    },

});
