import Model from '@glu/core/src/model';
import Collection from '@glu/core/src/collection';
import locale from '@glu/locale';
import util from '@glu/core/src/util';

const AccountModel = Model.extend({
    defaults: {
        PRIMARY_ACCOUNT: false,
    },

    initialize(data) {
        // TODO: lots of .set() calls in here.
        if (!data || data.BENE_BANK_ID || data.BENE_ACCOUNTNUMBER || data.TNUM) {
            return;
        }
        const vals = util.values(data);
        let tmpPrimary;
        let primary;
        let secondary;
        let paymentTypes;
        if (vals.length > 1) {
            tmpPrimary = this.findPrimary;
            if (tmpPrimary) {
                primary = util.find(vals, this.findPrimary);
            } else {
                [primary] = vals;
            }
            secondary = util.find(vals, this.findSecondary);
            paymentTypes = primary.PAYMENTTYPE_COLLECTION
                .concat(secondary.PAYMENTTYPE_COLLECTION);
        } else {
            [primary] = vals;
            paymentTypes = primary.PAYMENTTYPE_COLLECTION;
            secondary = false;
        }
        const isFreeForm = primary.BENEBANKIDENTRYMETHOD === 'FREEFORM';
        const isAcctNumMandatory = primary.ACCT_NUM_MANDATORY === 'true';
        const isCorrespondentIDMandatory = primary.CORRESPONDENT_ID_MANDATORY === 'true';

        this.set(primary);

        this.set({
            PAYMENTTYPE_COLLECTION: new Collection(paymentTypes),
            PRIMARY_ACCOUNT: !!(primary.PRIMARY_ACCOUNT && primary.PRIMARY_ACCOUNT.toUpperCase() === 'Y'),
        });

        if (this.get('PAYMENTPRODUCT').search(/ach/i) > -1) {
            this.set('ACH_ONLY', true);
        } else if (this.get('PAYMENTPRODUCT').search(/rtgs/i) > -1) {
            this.set('WIRE_ONLY', true);
        }

        if (secondary) {
            this.set({
                SECONDARY_CODE: true,
                BENE_BANK_ID_SECONDARY: secondary.BENE_BANK_ID,
                BENE_BANK_CODE_SECONDARY: secondary.BENE_BANK_ID,
                BENE_BANK_NAME_SECONDARY: secondary.BENE_BANK_NAME,
                BANK_ACCOUNT_TEXT_SECONDARY: `${secondary.BENE_BANK_ID} - ${secondary.BENE_BANK_NAME}`,
                BENE_BANK_IDTYPE_SECONDARY: secondary.BENE_BANK_IDTYPE,
                PAYMENTTYPE: primary.PAYMENTTYPE.concat(secondary.PAYMENTTYPE),
            });
        }
        this.updateValidators(isFreeForm, isAcctNumMandatory, isCorrespondentIDMandatory);
    },

    updateValidators(
        isFreeForm,
        isAcctNumMandatory,
        isCorrespondentIDMandatory,
        isIntermediaryFreeform,
        isIntermediaryAcctNumMandatory,
        bankCodeFieldName,
        preExistingValidators = {},
    ) {
        let localIsIntermediaryFreeform = isIntermediaryFreeform;
        // initialize validators here so we can use locale strings

        let wirePaymentTypeSelected = false;

        if (localIsIntermediaryFreeform === null || localIsIntermediaryFreeform === undefined) {
            localIsIntermediaryFreeform = this.get('INTERBANKIDENTRYMETHOD') === 'FREEFORM';
        }

        /*
         * Check if a wire payment type is selected before enforcing that intermediary fields
         * are required.
         */
        if (this.attributes && this.attributes.PAYMENTTYPE && (this.attributes.PAYMENTTYPE.indexOf('INTL') > -1 || this.attributes.PAYMENTTYPE.indexOf('FEDWIRE') > -1)) {
            wirePaymentTypeSelected = true;
        }

        this.validators = {
            BENE_ACCOUNTNUMBER: {
                description: locale.get('bab.account.number'),
                exists: true,
            },

            BENE_ACCOUNT_CURRENCY: {
                description: locale.get('bab.currency'),
                exists: true,
            },

            PAYMENTTYPE: {
                description: locale.get('bab.payment.type'),
                exists: true,
            },
        };

        const secondaryAccountValidations = ['SECOND_ACCOUNT_ALLOCATION', 'SECOND_ALLOCATION_TYPE', 'SECOND_BENE_ACCOUNTNUMBER', 'SECOND_BENE_ACCOUNTTYPE', 'SECOND_BENE_BANK_ID'];

        // Carry over secondary account validators that wereset in secondaryAccountForm.js
        secondaryAccountValidations.forEach((validatorName) => {
            if (preExistingValidators[validatorName]) {
                this.validators[validatorName] = preExistingValidators[validatorName];
            }
        });

        if (this.get('ACH_SELECTED')) {
            this.validators.BENE_ACCOUNTTYPE = {
                description: locale.get('bab.account.type'),
                exists: true,
            };
        }
        if (isAcctNumMandatory && isFreeForm) {
            this.validators.BENE_BANK_ACCOUNT_NUMBER = {
                description: locale.get('RTGS.*.bene_bank_account_number'),
                exists: true,
            };
        }
        if (isIntermediaryAcctNumMandatory && isFreeForm) {
            this.validators.INTERMEDIARY_ID = {
                description: locale.get('RTGS.*.intermediary_id'),
                exists: true,
            };
        }
        if (isCorrespondentIDMandatory && wirePaymentTypeSelected && isFreeForm) {
            this.validators.INTERMEDIARY_ID_SORTCODETYPE = {
                description: locale.get('RTGS.*.intermediary_type'),
                exists: true,
            };
            this.validators.INTERMEDIARY_ID_SORTCODETYPE = {
                description: locale.get('RTGS.*.intermediary_id'),
                exists: true,
            };
        }
        if (localIsIntermediaryFreeform) {
            this.validators.INTERMEDIARY2_NAME = {
                description: locale.get('bab.bank.name'),
                exists: true,
            };
            this.validators.INTERMEDIARY2_ADDRESS_1 = {
                description: locale.get('bab.address.1'),
                exists: true,
            };
        }
        if (localIsIntermediaryFreeform
            && isIntermediaryAcctNumMandatory
            && wirePaymentTypeSelected) {
            this.validators.INTER_BANK_ACCOUNT_NUMBER = {
                description: locale.get('RTGS.*.bene_bank_account_number'),
                exists: true,
            };
        }
        if (isFreeForm) {
            this.validators.BENE_BANK_NAME = {
                description: locale.get('bab.bank.name'),
                exists: true,
            };
            this.validators.BENE_BANK_ADDRESS_1 = {
                description: locale.get('bab.address.1'),
                exists: true,
            };
            this.validators.BENE_BANK_COUNTRY = {
                description: locale.get('bab.country'),
                exists: true,
            };
        } else if (this.get('BENE_ACCOUNTTYPE') !== 'IBAN') {
            if (!util.isEmpty(this.get('BENE_BANK_CODE'))) {
                this.set('BENE_BANK_CODE_SORTCODETYPE', this.get('BENE_BANK_CODE'));
            } else if (!util.isEmpty(this.get('BENE_BANK_CODE_SORTCODETYPE'))) {
                this.set('BENE_BANK_CODE', this.get('BENE_BANK_CODE_SORTCODETYPE'));
            }
            this.validators[bankCodeFieldName || 'BENE_BANK_CODE_SORTCODETYPE'] = {
                description: locale.get('bab.bank.routing.code'),
                exists: true,
            };
        }
    },

    findPrimary(obj) {
        if (obj) {
            return obj.BENE_BANK_CODE_PRIMARY === '';
        }
        return false;
    },

    findSecondary(obj) {
        return obj.BENE_BANK_CODE_PRIMARY !== '';
    },

    isPrimary() {
        return this.get('PRIMARY_ACCOUNT') === true;
    },

    wasPrimary() {
        return this.previous('PRIMARY_ACCOUNT') === true;
    },

    sync() {
        return true;
    },
});

export default AccountModel;
