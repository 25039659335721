import { Collection, http, util } from '@glu/core';
import FlexDropdownSelectionCollection from '../collections/flexDropdownSelectionCollection';
import FlexDropdownModel from './flexDropdownModel';

export default FlexDropdownModel.extend({
  url() {
    const params = {};
    let paramString = '?';

    if (this.filterText) {
      params.filter = this.filterText;
    }

    params.slice1 = this.slice1 || 0;
    params.slice2 = this.slice2 || this.defaultGet;

    util.each(params, (val, key) => {
      paramString += `${key}=${encodeURIComponent(val)}&`;
    });

    paramString = paramString.substr(0, paramString.length - 1);

    return this.dynamicUrl + paramString;
  },
  parse(data) {
    if (data && data.length) {
      this.data = data;

      if (this.customParse) {
        data = this.customParse(data);
      }

      // items holds all items currently in JS memory
      data = {
        items: new Collection(data)
      };

      // selection holds all items currently selected
      data.selection = new FlexDropdownSelectionCollection(this.getSelected(), {
        defaultSelectMessage: this.defaultSelectMessage
      });

      data.breadcrumb = [];

      // Selected items are marked as selected in the items collection
      // When the items collection changes we updated the selection collection
      data.items.on('change reset', function reset() {
        data.selection.reset(this.getSelected());
      }, this);
    } else {
      this.data = [{
        name: '--',
        id: null,
        selected: true,
        noData: true
      }];

      data = {
        items: new Collection([]),
        selection: new FlexDropdownSelectionCollection(this.data, {}),
        breadcrumb: []
      };
    }

    this.minLength = false;

    return data;
  },
  /**
   * Clear hidden data
   */
  resetFilter() {
    this.filterText = '';
    return null;
  },
  /**
   * Sets all items to unselected
   */
  clearSelected() {
    this.data = [];
    this.get('selection').reset();
    return null;
  },
  /**
   * Filters all data using string entered.
   *
   * @param {string} filterText - string to use to filter the data
   */
  filterCollection(filterText) {
    const that = this;

    that.get('items').reset();
    that.filterText = filterText;

    clearTimeout(this.filterTimer);
    this.filterTimer = setTimeout(function updateActive() {
      that.getActive(0, this.defaultGet, null, true);
    }, 1500);
  },
  /**
   * Returns the active items for the dropdown
   *
   * @param {number} slice1 - first item of data in array to retrieve
   * @param {number} slice2 - last item of data in array to retrieve
   * @param {boolean} addTo - if true, add to to the current collection. If false, reset current collection
   * @param {boolean} filter - true if the request is prompted by a change in the filter
   *
   * */
  getActive(slice1, slice2, addTo, filter) {
    if (filter && this.loadData !== undefined && this.loadData.abort !== undefined) {
      this.loadData.abort();
      this.loadingDropdown = false;
    }

    if (!slice1 || slice2 !== 'scroll') {
      // Initial state of new scroll and reset
      this.lastPageReached = false;
    }

    if (!this.loadingDropdown && !this.lastPageReached) {
      this.loadingDropdown = true;
      this.slice1 = slice1 || 0;
      this.slice2 = slice2 || this.defaultGet;

      if (slice2 === 'scroll') {
        this.slice2 = slice1 + this.defaultGet;
      }

      // eslint-disable-next-line consistent-return
      this.loadData = http.get(this.url(), (response) => {
        if (!response) {
          this.loadingDropdown = false;
          const items = this.get('items');
          // Reset the list of items only if it's empty so that it's reset for filter
          // but not when scrolling and there are no more items to show.
          if (items !== undefined && items.length === 0) {
            items.reset();
          }
          return []; // ToDo this return value violates lint but this code is confusing so leaving for now
        }

        let exists;
        let i;
        const selectedIds = [];

        if (this.customParse) {
          this.data = this.customParse(this.data);
        }

        const setSelected = function (selectedId) {
          if (`${response[i].id}` === `${selectedId}`) {
            response[i].selected = true;
          }
        };
        const checkExists = function (itemExists) {
          if (`${response[i].id}` === `${itemExists.id}`) {
            exists = true;
          }
        };

        util.each(this.get('selection').toJSON(), (selectionItem) => {
          if (selectionItem.id !== null) {
            selectedIds.push(selectionItem.id);
          }
        });

        for (i = 0; i < response.length; i++) {
          exists = false;

          util.each(selectedIds, setSelected);

          util.each(this.data, checkExists);

          if (!exists) {
            this.data.push(response[i]);
          }
        }

        if (!addTo) {
          this.get('items').reset(response);
        } else if (response.length) {
          this.get('items').add(response);
        }

        if (response.length < (this.slice2 - this.slice1)) {
          // Stop making request when end of list reached
          this.lastPageReached = true;
        }

        this.loadingDropdown = false;
      }, (error) => {
        // Throw error and remove loader, reset collection
        this.loadingDropdown = false;
        this.get('items')[addTo ? 'add' : 'reset']([]);
        try {
          error = JSON.parse(error.responseText);
        } catch (e) {
          error = error.responseText;
        }
        throw error;
      });
    }
  },

  /**
   * Toggles an item as selected by id
   *
   * @param {number} id - id of the item to toggle
   */
  toggleSelected(id) {
    for (const item in this.data) {
      if (this.data.hasOwnProperty(item) && `${this.data[item].id}` === `${id}`) {
        this.data[item].selected = !this.data[item].selected;
        return this.data[item].selected;
      }
    }

    return null;
  },

  /**
   * Selects one item and sets the rest to unselected
   *
   * @param {number} id - id of the item to select
   */
  selectOne(id) {
    util.each(this.data, (item) => {
      item.selected = (`${item.id}` === `${id}`);
    });
  },

  /**
   * Returns a JSON array of all selected items
   */
  getSelected() {
    const selected = [];
    const isSelected = function (item) {
      if (item.selected) {
        selected.push(item);
      }
    };

    util.each(this.data, (item) => {
      isSelected(item);
    });

    return selected;
  },
  defaultGet: 100,
  dynamic: true
});
