export default {

    cleanUp: null,

    /**
     * sets the cleanUp function which runs when there is
     * no more localStorage space.
     * @param {function} cleanFunc
     */
    setCleanUp(cleanFunc) {
        this.cleanUp = cleanFunc;
    },

    /**
     * retrieves an item from browser localStorage by the specified key
     * @param {string} key
     * @returns {object} parse object or undefined if an error occurred
     */
    get(key) {
        try {
            return JSON.parse(localStorage.getItem(key));
        } catch (e) {
            // TODO: Should this return be unique, to distinguish this state?
            return undefined;
        }
    },

    /**
     * stores the object 'value' in browser localStorage with the specified key
     * @param {string} key - unique key for object in localStorage
     * @param {object} value - object to store
     * @returns {boolean} true is store was successful; otherwise, false
     */
    set(key, value) {
        try {
            localStorage.setItem(key, JSON.stringify(value));
            return true;
        } catch (e) {
            if (e.code === 22) {
                // Out of storage space.
                if (this.cleanUp) {
                    this.cleanUp();
                }
                // TODO log this somewhere
            }
            // other error or localStorage is unavailable
            return false;
        }
    },

    /**
     * removes the object specified by 'key' from localStorage
     * @param {string} key to identify the object
     * @returns {boolean} true if object is successfully removed; otherwise, false
     */
    remove(key) {
        try {
            localStorage.removeItem(key);
            return true;
        } catch (e) {
            return false;
        }
    },
};
