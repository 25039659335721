import Layout from '@glu/core/src/layout';
import Formatter from 'system/utilities/format';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import Collection from '@glu/core/src/collection';
import CollectFromBusinessPaymentsCollection from 'app/smb/collections/business';
import ActionCell from 'common/dynamicPages/views/gridActionCell';
import SimpleCellView from 'app/smb/views/cells/simple';
import ModalCellView from 'app/smb/views/cells/complexModal';
import SmbPaymentOptions from 'app/smb/models/newPaymentOptions';
import RecipientCellView from 'app/smb/views/recipient/recipient';
import CommonAddRow from 'app/smb/common/addRow';
import Model from '@glu/core/src/model';
import Navigation from 'app/smb/navigation';
import CollectFromBusinessPaymentGridView from '../baseAddGrid';
import baseTmpl from './base.hbs';

const paymentTypeId = 'SMBFUNDSBUSINESS';

// This model is used for validation
const getBaseModel = function () {
    const ThisModel = Model.extend({
        defaults: {
            fromAccount: '',
            totalPayments: '',
            balance: '',
        },

        validators: {
            fromAccount: {
                description: locale.get('sbPayments.fromAccount'),
                exists: true,
            },

            totalPayments: {
                description: locale.get('sbPayments.totalPayments'),
                minValue: 1,
            },
        },

        displayAmount(balance) {
            if (balance !== locale.get('sbPayments.notAvailable')) {
                return Formatter.formatCurrency(balance);
            }
            return balance;
        },
    });
    return new ThisModel();
};

export default Layout.extend({
    template: baseTmpl,
    draftSaved: false,
    collectFromBusinessPaymentsCollection: new CollectFromBusinessPaymentsCollection(),
    smbPaymentOptions: new SmbPaymentOptions(),

    ui: {
        $fromAccount: '[data-hook="from-account"]',
        $paymentsList: '[data-hook="payments-list"]',
        $totalLabel: '[data-hook="total-text"]',
        $submitButton: '[data-hook="submit-payments"]',
        $saveButton: '[data-hook="save-draft"]',
        $fromAccSelected: '[data-hook="fromAccSelected"]',
        $selectedRecipient: '[data-hook="recipient-selected"]',
    },

    appEvents: {
        'sbPayments:paymentListUpdated': 'updateTotal',
        'sbPayments:recipientListUpdated': 'updateRecipient',
        'sbPayments:recipientAddRowUpdated': 'updateAddRowRecipient',
        'sbPayments:paymentCollectionUpdated': 'updatePaymentCollection',
    },

    events: {
        'select2-selecting #payments-list': 'selectChange',
        'select2-selecting #fromAccount': 'setFromAccount',
    },

    initialize(options) {
        this.listenTo(this.collectFromBusinessPaymentsCollection, 'all', this.updateTotal);
        this.recipientList = options.recipientList;
        this.fromAccountList = options.fromAccountList;
        this.paymentTypeCollection = options.paymentTypeCollection;
        this.countryCollection = options.countryList;
        this.accountTypeCollection = options.accountTypeList;
        this.dates = options.dates;
        this.cutoffTimes = options.cutoffTimes;
        this.model = getBaseModel();
    },

    onRender() {
        this.ui.$paymentsList.comboBox();
        this.ui.$fromAccount.comboBox({
            placeholder: locale.get('sbPayments.selectAccount'),
            allowClear: true,
        });
        this.ui.$fromAccount.on('change', (e) => {
            this.setFromAccount(e);
        });
        this.ui.$paymentsList.comboBox('val', paymentTypeId);
        this.ui.$totalLabel.text(`${locale.get('sbPayments.totalPaymentsLabel')} ${Formatter.formatCurrency(this.collectFromBusinessPaymentsCollection.total())}`);
    },

    getColumnsCollection() {
        return new Collection([{
            title: locale.get('sbPayments.recipient'),
            field: 'BENE_NAME',
            type: RecipientCellView,
        }, {
            title: locale.get('sbPayments.effectiveDate'),
            field: 'paymentDisplayDate',
            type: SimpleCellView,
        }, {
            title: locale.get('sbPayments.comment'),
            field: 'comment',
            sort: 'string',
            type: ModalCellView,
            editCell: 'text',
        }, {
            title: locale.get('sbPayments.amount'),
            field: 'displayAmount',
            sort: 'string',
            type: SimpleCellView,
            editCell: 'text',
        }, {
            type: ActionCell,
            width: 100,
            resizing: false,
            className: 'text-center btn-cell',
            display: 'iconList',

            buttons: [{
                value: 'edit',
                label: locale.get('smb.edit'),
                icon: 'pencil',
            }, {
                value: 'delete',
                label: locale.get('smb.delete'),
                icon: 'cancel',
            }],

            editButtons: [{
                value: 'cancel',
                label: locale.get('smb.cancel'),
            }],
        }]);
    },

    onShow() {
        const collectFromBusinessPaymentGrid = new CollectFromBusinessPaymentGridView({
            AddEditRowView: CommonAddRow,
            columns: this.getColumnsCollection(),
            collection: this.collectFromBusinessPaymentsCollection,

            editOptions: {
                recipients: this.recipientList,
                country: this.countryCollection.toJSON(),
                accountType: this.accountTypeCollection.toJSON(),
                blockedDates: this.dates.blockedDates,
                maxDate: this.dates.maxDate,
                cutoffTimes: this.cutoffTimes,
            },
        });

        this.content.show(collectFromBusinessPaymentGrid);
    },

    templateHelpers() {
        const paymentTypes = util.sortBy(this.paymentTypeCollection.models[0].get('smbPayMenu'), 'displayOrder');

        return {
            paymentTypes,
            fromAccounts: this.fromAccountList.toJSON(),
        };
    },

    selectChange(e) {
        /*
         * paytype can be taken from one of the collections.
         * As it is not permitting in few screens, defined value
         * 'paymentTypeId' is taken to compare the menucategory.
         */
        const self = this;
        if (paymentTypeId !== e.val) {
            dialog.confirm(locale.get('smb.change.paymenttype.warning'), 'Warning', (ok) => {
                if (ok) {
                    Navigation.navigate(e, self);
                }
                self.ui.$paymentsList.comboBox('val', paymentTypeId);
            });
        }
    },

    setFromAccount(e) {
        this.fromAccount = e.val;
        if (util.isEmpty(e.val)) {
            this.ui.$fromAccSelected.empty();
            return;
        }

        // TODO: We should be able to do this without .toJSON()
        const account = util.find(
            this.fromAccountList.toJSON(),
            fromAcc => e.val === fromAcc.id,
        );

        if (account !== undefined) {
            this.model.set('balance', account.balance === ''
                ? locale.get('sbPayments.notAvailable') : account.balance);
        }

        // TODO: This test seems odd. Should we be tested for .has()?
        if (this.model.get('balance') === 'undefined') {
            this.ui.$fromAccSelected.empty();
        } else {
            // FIXME: HTML injection is bad, m'kay? Why isn't this part of a template?
            const html = `${locale.get('sbPayments.balance')}: <b>${this.model.displayAmount(this.model.get('balance'))}</b>`;
            this.ui.$fromAccSelected.html(html);
        }
    },

    updateTotal() {
        const total = this.collectFromBusinessPaymentsCollection.total();
        const totalPayments = this.collectFromBusinessPaymentsCollection.models.length;
        this.$el.find('[data-hook="total-text"]').text(`${totalPayments} ${locale.get('sbPayments.totalPaymentsLabel')} ${Formatter.formatCurrency(total)}`);
    },

    updatePaymentCollection(options) {
        util.each(this.collectFromBusinessPaymentsCollection.models, (paymentModel) => {
            const recipient = paymentModel.get('recipient');

            if (options.model.get('BENE_ACCOUNTNUMBER') === recipient.get('BENE_ACCOUNTNUMBER')
                || options.originalModel.get('BENE_ACCOUNTNUMBER') === recipient.get('BENE_ACCOUNTNUMBER')) {
                recipient.set(options.model.attributes);
                // make parent model happy
                paymentModel.set('BENE_NAME', options.model.get('BENE_NAME'));
            }
        });
    },

    updateRecipient(model) {
        util.each(this.recipientList.models, (recipientModel) => {
            if (model.cid === recipientModel.cid) {
                recipientModel.set(model.attributes);
            }
        });
    },

    /**
     * TODO: HACK: XXX: This needs to be redesigned.
     * FIXME: Injecting arbitrary HTML is BAD. And we're doing it from an appEvent?!
     * @param {string}html
     */
    updateAddRowRecipient(html) {
        this.bindUIElements();
        this.ui.$selectedRecipient[0].innerHTML = html;
    },

    submitPayments() {
        const self = this;
        const totalPayments = this.collectFromBusinessPaymentsCollection.models.length;

        this.model.set({
            fromAccount: this.ui.$fromAccount.comboBox('val'),
            totalPayments,
        });

        if (!this.model.isValid()) {
            return;
        }

        if (totalPayments > 0) {
            util.each(this.collectFromBusinessPaymentsCollection.models, (model) => {
                model.set('fromAccount', self.model.get('fromAccount'));
            });
        }

        const alertStr = `${totalPayments} ${totalPayments === 1 ? locale.get('sbPayments.submitMessage.payment') : locale.get('sbPayments.submitMessage.payments')}`;

        if (totalPayments > 0) {
            dialog.alert(alertStr, () => {
                dialog.alert(locale.get('sbPayments.serverSubmit'));
            });
            // Send data to server
            this.collectFromBusinessPaymentsCollection.reset();
        } else {
            dialog.alert(alertStr, () => {
                // Return to Dashboard
                self.navigateTo('');
            });
        }
    },

    saveDraft() {
        dialog.alert(locale.get('smb.draft.saved'));
        this.draftSaved = true;
    },

    showServiceNotAvailableDialog() {
        dialog.alert(locale.get('smb.service.unavailable.error'));
    },

    cancelPayments() {
        this.navigateTo('PAYMENTS/enterPayment');
    },
});
