import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import Popover from '@glu/popover-react';
import locale from '@glu/locale';

import styles from './ActionsGroup.styles';

const ActionsGroup = ({
  rowData,
  actionDefs,
  classes,
  minNumberOfPrimaryRowActions,
  minNumberOfSecondaryRowActions
}) => {
  let primaryActions = [];
  let secondaryActions = [...rowData.actions];

  primaryActions = secondaryActions.slice(0, minNumberOfPrimaryRowActions);
  secondaryActions = secondaryActions.slice(minNumberOfPrimaryRowActions);

  if (minNumberOfSecondaryRowActions > secondaryActions.length) {
    primaryActions.push(...secondaryActions);
    secondaryActions = [];
  }

  const handleActionClick = useCallback((action, closePopover) => e => {
    if (closePopover) {
      closePopover();
    }
    actionDefs[action].onClick({ event: e, rowData });
  }, [actionDefs, rowData]);

  if (!Object.keys(actionDefs).length) {
    return null;
  }

  return (
    <div className={classes.actions}>
      {primaryActions.map(action => (
        <button
          key={`actionsgroup-${action}`}
          type="button"
          onClick={handleActionClick(action)}
          {...actionDefs[action].additionalAttributes?.({ rowData })}
        >
          {actionDefs[action].label}
        </button>
      ))}
      {secondaryActions?.length ? (
        <>
          <Popover
            enablePopoverClick
            placement="top"
            content={({ closePopover }) => (
              <div className={classes.popover} data-qa="action-popover">
                <ul>
                  {secondaryActions.map((action) => (
                    <li key={`actionsgroup-secondary-${action}`}>
                      <button
                        type="button"
                        onClick={handleActionClick(action, closePopover)}
                        {...actionDefs[action].additionalAttributes?.({ rowData })}
                      >
                        {actionDefs[action].label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          >
            <button type="button">
              {locale.get('more')}
              &hellip;
            </button>
          </Popover>
        </>
      ) : null}
    </div>
  );
};

ActionsGroup.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  rowData: PropTypes.shape({
    actions: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired,
  actionDefs: PropTypes.shape({}).isRequired,
  minNumberOfPrimaryRowActions: PropTypes.number,
  minNumberOfSecondaryRowActions: PropTypes.number
};

ActionsGroup.defaultProps = {
  minNumberOfPrimaryRowActions: 1,
  minNumberOfSecondaryRowActions: 1
};

export default withStyles(styles)(ActionsGroup);
