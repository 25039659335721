import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import gridActionPopupManager from 'app/utilities/gridActionPopup/gridActionPopupManager';
import mobileUtil from 'mobile/util/mobileUtil';
import tpl from './gridActionCell.hbs';
import ActionMenu from './actionMenu';

export default ItemView.extend({
    template: tpl,
    tagName: 'td',
    className: 'grid-row-buttons',
    disableInitialViewBinding: true,

    ui: {
        $buttonGroup: '.btn-group',
    },

    events: {
        'click [data-action]': 'handleClick',
    },

    // edit and delete events are special events that need to be bubbled up the stack.
    appEvents: {
        edit: 'handleEdit',
        delete: 'handleDelete',
        'actionMenu:hide': 'closeActionMenu',
    },

    initialize(options) {
        ItemView.prototype.initialize.call(this, options);
        this.column = options.column;
    },

    /**
     * The actionMenu extends layerView which will cause it to render as a child
     * of the body element and therefore can be positioned anywhere on the page without
     * being constrained by the overflow settings of the grid.
     */
    initializeActionMenu() {
        this.actionMenu = new ActionMenu({
            parentCellView: this,
            buttons: this.getButtons(),
            model: this.model,
        });
        this.actionMenu.render();
    },

    // tear down the action cell and it's children
    onClose() {
        this.closeActionMenu();
    },

    /**
     * Bubble the edit event up the stack.
     */
    handleEdit(e) {
        this.trigger('edit', this, e);
    },

    /**
     * Bubble the delete event up the stack.
     */
    handleDelete(e) {
        this.trigger('delete', this, e);
    },

    closeActionMenu() {
        if (this.actionMenu) {
            this.actionMenu.close();
            delete this.actionMenu;
        }
    },

    handleClick(e) {
        const val = e.currentTarget.getAttribute('data-action');
        // set empty defaults, not required for mobile grid
        let buttonOffset = {
            top: 0,
            left: 0,
        };
        if (!mobileUtil.isMobileScreen()) {
            buttonOffset = {
                top: this.ui.$buttonGroup.offset().top,
                left: this.ui.$buttonGroup.offset().left,
            };
        }
        // hide all menus
        this.appBus.trigger('actionMenu:hide');

        /*
         * 'edit' and 'delete' appear to be keywords.  we need to bubble these up the stack.
         * we have to leave these options here in case the first option in the menu is one
         * of the special actions.
         */
        switch (val) {
        case 'edit':
            this.handleEdit(e);
            break;
        case 'delete':
            this.handleDelete(e);
            break;
        case 'toggleMenu':
            if (this.moreThanOne()) {
                if (this.actionMenu) {
                    this.closeActionMenu();
                } else {
                    this.initializeActionMenu();
                    this.actionMenu.showMenu(this.$(e.currentTarget));
                }
            }
            break;
        case `action_authrize_${this.model.parentViewId}`:
        case `action_rejectfm_${this.model.parentViewId}`:
        case `action_canclreq_${this.model.parentViewId}`:
            gridActionPopupManager.makePopup({
                action: val.split('_')[1],
                model: this.model,
                buttonOffset,
            });
            break;
        case `action_extendreq_${this.model.parentViewId}`:
            gridActionPopupManager.makePopup({
                action: val.split('_')[1],
                model: this.model,
                buttonOffset,
            });
            break;
        default:
            // trigger an event on the app bus so components can listen outside of the grid
            this.appBus.trigger(`grid:row:action:${val}`, this.model);
        }
    },

    /**
     * Use the actions defined in the model if provided.  Otherwise fall back to the
     * actions defined in the column metadata.  This allows for data-level security.
     *
     * @returns {*}
     */
    getButtons() {
        return this.model.buttons || this.column.get('buttons') || [];
    },

    findPrimary() {
        const buttons = this.getButtons();
        let primary = util(buttons).find(model => model.primary);

        if (!primary) {
            if (buttons) {
                [primary] = buttons;
            }
        }

        return primary;
    },

    hasPrimary() {
        const buttons = this.getButtons();
        const primary = util(buttons).find(model => model.primary);

        if (!primary) {
            return false;
        }

        return true;
    },

    moreThanOne() {
        return (this.getButtons().length > 1);
    },

    templateHelpers() {
        return {
            hasPrimary: this.hasPrimary(),
            primary: this.findPrimary(),
            column: this.column.toJSON(),
            buttons: this.getButtons(),
            moreThanOne: this.moreThanOne(),
            columnId: this.column.cid,
        };
    },
});
