import ItemView from '@glu/core/src/itemView';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import template from './imageIndicatorCell.hbs';

export default ItemView.extend({
    disableInitialViewBinding: true,

    events: {
        'click [data-hook="image-button"]': 'showImage',
    },

    showImage() {
        // maybe pass options from to showImage.

        this.model.typeCode = this.options.column.get('typeCode');
        this.model.fieldName = this.options.column.get('fieldName');
        this.appBus.trigger(`grid:row:action:action_imageDetail_${this.model.parentViewId}`, this.model, 'image');
        dialog.close();
    },

    getTemplate() {
        if (this.hasImage()) {
            return template;
        }
        return function () {
            return '';
        };
    },

    hasImage() {
        return this.options.value === 'Y';
    },

    templateHelpers() {
        return {
            iconName: this.options.column ? this.options.column.get('iconName') : 'image-indicator',
            iconDescription: locale.get('common.image.view'),
        };
    },
});
