import locale from '@glu/locale';
import Dialog from '@glu/dialog';
import Model from '@glu/core/src/model';
import ItemView from '@glu/core/src/itemView';
import store from 'system/utilities/cache';
import loadingModalTmpl from 'common/templates/loadingModal.hbs';
import TemplateCollection from '../collections/templates';
import addTransferDialogTmpl from './addTransferDialog.hbs';

export default ItemView.extend({
    template: addTransferDialogTmpl,
    loadingTemplate: loadingModalTmpl,
    modalClass: 'modal-lg',

    ui: {
        typeCombo: '[data-hook="typeCombo"]',
        templateCombo: '[data-hook="templateCombo"]',
        transferTypeHelpText: '[data-validate="transferType"]',
        transferTemplateHelpText: '[data-validate="transferTemplate"]',
        transferTypeContainer: '.transfer-type-container',
    },

    initialize() {
        this.model = new Model({
            typeOrTemplate: this.options.showTransferTypeDropdown ? 'type' : 'template',
        });

        this.dialogTitle = locale.getDefault('PAY.add.transfer', 'Add Transfer');

        this.dialogButtons = [{
            text: locale.get('button.continue'),
            className: 'btn btn-primary',
            callback: 'addTransfer',
        }, {
            text: locale.get('button.cancel'),
            className: 'btn btn-tertiary',
            callback: 'cancel',
        }];

        this.templateCollection = new TemplateCollection({
            isTransfer: true,
        });

        this.listenTo(this.model, 'change:typeOrTemplate', (model, val) => {
            this.ui.typeCombo.comboBox(val === 'template' ? 'disable' : 'enable');
            this.ui.templateCombo.comboBox(val === 'type' ? 'disable' : 'enable');

            if (val === 'type') {
                this.ui.templateCombo.comboBox('data', null);
            } else {
                this.ui.typeCombo.comboBox('data', null);
            }

            this.clearError(this.ui.transferTypeHelpText, this.ui.typeCombo);
            this.clearError(this.ui.transferTemplateHelpText, this.ui.templateCombo);
        });

        this.listenTo(this.model, 'change:transferType', () => {
            this.clearError(this.ui.transferTypeHelpText, this.ui.typeCombo);
        });

        this.listenTo(this.model, 'change:transferTemplate', () => {
            this.clearError(this.ui.transferTemplateHelpText, this.ui.templateCombo);
        });
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
        } else if (this.options.showTransferTypeDropdown) {
            this.ui.transferTypeContainer.show();
            this.ui.typeCombo.comboBox();
            this.ui.templateCombo.comboBox().comboBox('disable');
        } else {
            this.ui.transferTypeContainer.hide();
            this.ui.templateCombo.comboBox().comboBox('enable');
        }
    },

    loadRequiredData() {
        const self = this;

        if (this.options.templateMode) {
            self.setHasLoadedRequiredData(true);
            self.render();
        } else {
            this.templateCollection.fetch({
                success() {
                    self.setHasLoadedRequiredData(true);
                    self.render();
                },
            });
        }
    },

    /**
     * Remove error message and error class from the passed in helperText region
     * and comboBox.
     */
    clearError(helperText, comboBox) {
        helperText.empty();
        comboBox.parent().removeClass('has-error');
    },

    addTransfer() {
        /**
         * If the user has selected to create a new template and has not chosen a
         * type, throw
         * an inline error on the transferType dropdown.
         * Else if the user has selected to create a transfer from template and has
         * not chosen
         * a template, throw an inline error on the transferTemplate dropdown.
         *
         */
        if (this.model.get('typeOrTemplate') === 'type' && !this.model.get('transferType')) {
            this.ui.transferTypeHelpText.text(locale.get('validator.transfertype.required'));
            this.ui.typeCombo.parent().addClass('has-error');
            return;
        }

        if (this.model.get('typeOrTemplate') === 'template' && !this.model.get('transferTemplate')) {
            this.ui.transferTemplateHelpText.text(locale.get('validator.templatetype.required'));
            this.ui.templateCombo.parent().addClass('has-error');
            return;
        }

        if (this.model.get('typeOrTemplate') === 'type') {
            this.navigateTo(`PAYMENTS/addTransfer${this.options.templateMode ? 'Template/' : '/'}${this.model.get('transferType')}`);

            Dialog.close();
        } else if (this.model.get('transferTemplate')) {
            const setName = this.model.get('transferTemplate');
            const rec = this.templateCollection.get(setName);
            const templateEntryMethod = rec.get('TEMPLATEENTRYMETHOD');
            store.set('templateEntryMethod', templateEntryMethod);

            if (rec.get('TRANSFERSET') === '1') {
                this.navigateTo(`PAYMENTS/addTransferByTemplate/${setName}`);
            } else if (rec.get('TRANSFERSET') === '0') {
                this.navigateTo(`PAYMENTS/addSingleTransferByTemplate/${rec.get('TNUM')}`);
            } else {
                const context = {
                    serviceName: '/payment/transfer',
                    functionCode: 'TMPL',
                    subType: '',
                    templateServiceName: '/payment/transfer',
                    enableSaveDraft: true,
                    tnum: rec.get('TNUM'),
                    updateCount: rec.get('UPDATECOUNT'),
                };

                store.set('payment_listView_corp-contextOverride', context);

                this.navigateTo('PAYMENTS/addPaymentFromTemplate');
            }

            Dialog.close();
        }
    },

    cancel() {
        Dialog.close();
    },

    onClose() {
        this.ui.typeCombo.comboBox('destroy');
        this.ui.templateCombo.comboBox('destroy');
    },

    templateHelpers() {
        return {
            cid: this.model.cid,
            templates: this.templateCollection.toJSON(),
            hideTemplates: this.options.templateMode,
        };
    },
});
