import Layout from '@glu/core/src/layout';
import FlexDropdown from '@glu/flex-dropdown';
import util from '@glu/core/src/util';
import panelWorkflowAssignmentTmpl from './panelWorkflowAssignment.hbs';

export default Layout.extend({
    template: panelWorkflowAssignmentTmpl,

    initialize(options) {
        const panelProfileCode = this.model.get('PANELPROFILECODE');
        this.panelWorkflowAssignment = new FlexDropdown({
            data: options.collection.toJSON(),
            disableMultiButton: true,
            showTooltip: false,
            preSelectedIds: this.isValidProfileCode(panelProfileCode) ? [panelProfileCode] : [],
            clearBtn: true,
        });

        this.listenTo(this.panelWorkflowAssignment, 'selectionChanged:id', this.handleWorkflowCodeChange.bind(this));
    },

    onRender() {
        this.getPanelProfileCode.show(this.panelWorkflowAssignment);
    },

    templateHelpers() {
        return {
            hasPanelProfileCode: this.isValidProfileCode(),
        };
    },

    /**
     * Check the profileCode and determine if it a valid value
     * @param {string} [profileCode]
     * @returns {boolean}
     */
    isValidProfileCode(profileCode) {
        const panelProfileCode = profileCode || this.model.get('PANELPROFILECODE');
        return !util.isNullOrUndefined(panelProfileCode) && panelProfileCode !== '';
    },

    /**
     * Set the model with the first item selected
     * @param {Array} selected - selected ids
     */
    handleWorkflowCodeChange(selected = []) {
        if (selected.length) {
            this.model.set('PANELPROFILECODE', selected[0]);
        } else {
            this.model.set('PANELPROFILECODE', '');
        }
    },
});
