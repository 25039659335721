var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-tertiary\" data-hook=\"insert-button\">\n                    <span class=\"icon-add-circle\"></span> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"SSOOUT.MAINT.CFSMBACT.*.addBtn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":58},"end":{"line":11,"column":101}}}))
    + "\n                </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"section section-container\">\n    <div class=\"section-body\">\n        <div class=\"btn-wrapper\">\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"gridUtilitySection") || (depth0 != null ? lookupProperty(depth0,"gridUtilitySection") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"gridUtilitySection","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":36}}}) : helper))) != null ? stack1 : "")
    + "\n        </div>\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        <div class=\"btn-wrapper\">\n            <div class=\"btn-group widget-links\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEntitledToAddBank") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":13,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n        <div class=\"filter-region\" data-region=\"filterRegion\"></div>\n        <div class=\"grid-region\" data-region=\"gridRegion\"></div>\n\n    </div>\n</div>\n";
},"useData":true});