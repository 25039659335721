import nestedModel from '@glu/core/src/nestedModel';
import util from '@glu/core/src/util';

let Model = nestedModel;

export default function () {
    Model = Model.extend({
        defaults: {
            comment: '',
        },

        validators: {
            // none
        },

        isEmpty() {
            let flag = true;
            const self = this;
            util.each(this.defaults, (value, field) => {
                if (!util.isEmpty(self.get(field))) {
                    flag = false;
                }
            });
            return flag;
        },
    });
    return new Model();
}
