var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row\">\n                <div class=\"col-6\">\n                    <h4>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"RTP.type.reqin",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":24},"end":{"line":8,"column":51}}}))
    + "</h4>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":17,"column":31}}})) != null ? stack1 : "")
    + "\n                    <div data-region=\"reqInPermRegion\"></div>\n                </div>\n            </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"global-select-all-perms\">\n                            <div class=\"checkbox\">\n                                <input type=\"checkbox\" id=\"selectAllReqin-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":13,"column":74},"end":{"line":13,"column":81}}}) : helper)))
    + "\" class=\"select-all-reqin\">\n                                <label for=\"selectAllReqin-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":14,"column":59},"end":{"line":14,"column":66}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"uce.assignAll",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":68},"end":{"line":14,"column":94}}}))
    + "</label>\n                            </div>\n                        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"col-6\">\n                            <h4>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"RTP.type.reqout",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":32},"end":{"line":28,"column":60}}}))
    + "</h4>\n    \n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":28},"end":{"line":35,"column":39}}})) != null ? stack1 : "")
    + "    \n                            <div data-region=\"reqOutPermRegion\"></div>\n                        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"checkbox\">\n                                    <input type=\"checkbox\" id=\"selectAllReqout-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":32,"column":79},"end":{"line":32,"column":86}}}) : helper)))
    + "\" class=\"select-all-reqout\">\n                                    <label for=\"selectAllReqout-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":33,"column":64},"end":{"line":33,"column":71}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"uce.assignAll",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":73},"end":{"line":33,"column":99}}}))
    + "</label>\n                                </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"col-6\">\n                            <h4>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"administration.template.request.permissions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":43,"column":32},"end":{"line":43,"column":88}}}))
    + "</h4>\n    \n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":28},"end":{"line":52,"column":39}}})) != null ? stack1 : "")
    + "    \n                            <div data-region=\"reqOutTemplPermRegion\"></div>\n                        </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"global-select-all-perms\">\n                                    <div class=\"checkbox\">\n                                        <input type=\"checkbox\" id=\"selectAllReqoutTmpl-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":48,"column":87},"end":{"line":48,"column":94}}}) : helper)))
    + "\" class=\"select-all-reqouttmpl\">\n                                        <label for=\"selectAllReqoutTmpl-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":49,"column":72},"end":{"line":49,"column":79}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"administration.assign.all.permissions.template",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":49,"column":81},"end":{"line":49,"column":140}}}))
    + "</label>\n                                    </div>\n                                </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"row\">\n                        <div data-region=\"requestAccountsRegion\" class=\"col-12\"></div>\n                    </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"abs-group\">\n                <div class=\"row\">\n                    <div class=\"col-6\">\n                        <h4>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"uce.reportGroup.RTP",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":72,"column":28},"end":{"line":72,"column":60}}}))
    + "</h4>\n    \n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":24},"end":{"line":79,"column":35}}})) != null ? stack1 : "")
    + "    \n                        <div data-region=\"msgsPermRegion\"></div>\n                    </div>\n                </div>\n                <div class=\"row\">\n                    <div data-region=\"msgsAccountsRegion\" class=\"col-12\"></div>\n                </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"checkbox\">\n                                <input type=\"checkbox\" id=\"selectAllMsgs-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":76,"column":73},"end":{"line":76,"column":80}}}) : helper)))
    + "\" class=\"select-all-msgs\">\n                                <label for=\"selectAllMsgs-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":77,"column":58},"end":{"line":77,"column":65}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"uce.assignAll",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":77,"column":67},"end":{"line":77,"column":93}}}))
    + "</label>\n                            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel panel-primary\">\n\n        <div class=\"panel-body\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasReqInPermissions") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":22,"column":19}}})) != null ? stack1 : "")
    + "\n            <div class=\"abs-group\">\n                <div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasReqOutPermissions") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":20},"end":{"line":39,"column":27}}})) != null ? stack1 : "")
    + "    \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasReqOutTmplPermissions") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":20},"end":{"line":56,"column":27}}})) != null ? stack1 : "")
    + "    \n                </div>\n    \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasReqAccounts") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":16},"end":{"line":64,"column":23}}})) != null ? stack1 : "")
    + "               </div>\n           </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasMsgsPermissions") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":12},"end":{"line":87,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n          </div>\n\n</div>";
},"useData":true});