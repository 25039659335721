import util from '@glu/core/src/util';

export default {

    /**
     * @method disableColumnSorting
     * Disable sorting on columns that don't support sorting.
     * @param {Array} colNames - string array of field names
     * @param {Object} targetGridView - gridView
     */
    disableColumnSorting(colNames, targetGridView) {
        if (targetGridView && targetGridView.wrapper && targetGridView.wrapper.columns) {
            util.each(colNames, (element) => {
                const column = this.gridView.wrapper.columns.where({ fieldName: element });
                if (util.isArray(column) && column.length > 0) {
                    column[0].set('sortable', false);
                    this.$(`[data-qa=${element}] > a`).addClass('disabled');
                }
            });
        }
    },
};
