import '../../themeDefaults';

const buttonReset = {
  background: 'none',
  border: 'none',
  color: 'inherit',
  cursor: 'pointer',
  font: 'inherit',
  padding: 0
};

export default ({
  grid = { actions: {} }, typography
}) => ({
  actionButton: {
    font: 'inherit',
    fontSize: 14,
    lineHeight: '14px'
  },

  button: {
    '& svg': {
      fill: grid.actions.caretDownFill,
      height: '18px',
      width: '18px'
    },
    background: 'transparent',
    margin: [0, 5],
    marginTop: ({ gridRowHeight }) => (gridRowHeight === 'small' ? 0 : gridRowHeight === 'medium' ? 4 : 2),
    ...buttonReset,
    display: 'flex'
  },

  buttonWrapper: {
    '&:hover $dot': {
      '& svg': {
        fill: grid.actions.dotHoverFill
      },
      backgroundColor: grid.actions.dotHoverBackground
    },
    alignItems: 'center',
    display: 'flex',
    height: 25
  },

  dot: {
    '& svg': {
      fill: grid.actions.dotFill,
      height: 25,
      width: 25
    },
    borderRadius: '50%',
    display: 'inherit',
    margin: [-12, -5, -5, -5],
    padding: 5
  },

  dotActive: {
    '& svg': {
      fill: grid.actions.dotHoverFill
    },
    backgroundColor: grid.actions.dotHoverBackground
  },

  dropDown: {
    '& $dropDownButton': {
      ...buttonReset,
      ...grid.actions.typography,
      '&:hover': {
        background: grid.actions.buttonHoverBackground
      },
      color: grid.actions.buttonColor,
      fontSize: grid.actions.dropdownFontSize,
      padding: [0, 14, 0],
      textAlign: 'left',
      textDecoration: `none solid ${grid.actions.buttonTextDecorationColor}`
    },
    background: grid.whiteBackgroundColor,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: typography.fontFamily,
    padding: [10, 0],

    top: -5,
    // do not revert, it should be white!
    zIndex: 100
  },
  dropDownButton: {},

  popover: {
    padding: 0
  },

  wrapper: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: typography.fontFamily,
    height: ({ gridRowHeight }) => (gridRowHeight === 'small' ? 25 : gridRowHeight === 'medium' ? 32 : 48)
  }
});
