import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import alert from '@glu/alerts';
import dialog from '@glu/dialog';
import ListView from 'common/dynamicPages/views/workflow/list';
import services from 'services';
import store from 'system/utilities/cache';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import PrintExportUtil from 'common/util/printExportUtil';
import workspaceHelper from 'common/workspaces/api/helper';
import listViewTmpl from './listView.hbs';

const HistoryList = ListView.extend({
    template: listViewTmpl,

    events: {
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
        'click [data-hook="refresh-button"]': 'refreshData',
    },

    initialize(options) {
        const superOptions = {
            menuCategory: 'REPORTING',
            serviceName: '/adminFileImportHistory/listView/importHistory',
            serviceFunc: null,
            businessType: null,
            enableSavedViews: true,
            context: 'FIMPORT_LISTVIEW',
            returnRoute: 'REPORTING/viewFileImportHistory',
            viewId: options.viewId,
            selector: 'none',
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    export() {
        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            inquiryId: this.inquiryId,
            format: 'CSV',
        };

        PrintExportUtil.export(options);
    },

    print() {
        this.buildExportModel('PDF');
        const printModal = new PrintViewModal({
            exportModel: this.exportModel,
            title: `${this.localeKey}title`,
        });
        dialog.custom(printModal);
    },

    buildExportModel(format) {
        ListView.prototype.buildExportModel.call(this, format);
        this.exportModel.inquiryId = '17236';
    },

    gridRowSelect(options) {
        if (options.action.toUpperCase() === 'SELECT') {
            store.set('fileImportId', options.model.get('IMPORTID'));
            store.set('jobId', options.model.get('EVENTID'));
            store.set('productCode', options.model.get('PRODUCTCODE'));
            store.set('functionCode', options.model.get('FUNCTIONCODE'));
            store.set('typeCode', options.model.get('TYPECODE'));
            store.set('fileOriginalName', options.model.get('ORIGINAL_FILE'));
            store.set('importCode', options.model.get('IMPORT_CODE'));
            this.navigateTo('REPORTING/viewFileImportHistoryDetails');
        }
        return Promise.resolve();
    },

    gridDownload(options) {
        return new Promise((resolve, reject) => {
            const { model } = options;
            const $loading = this.$('.loading-indicator-grid');
            const EVENTID = model.get('EVENTID');

            $loading.show();
            http.setRequestHeaders({ eventId: EVENTID });

            const service = services.generateUrl('/fileImportHistory/downloadBlobId');

            http.get(service, (data, textStatus, request) => {
                // success handler
                if (!request.getResponseHeader('content-disposition')) {
                    $loading.hide();
                    if (this.alertRegion) {
                        this.alertRegion.close();
                    }
                    this.alertRegion.show(alert.danger(data, {
                        canDismiss: true,
                        animate: true,
                    }));
                } else {
                    PrintExportUtil.fileDownload(data, textStatus, request);
                    $loading.hide();
                    resolve({ result: data });
                }
            }, (e) => {
                // error handler
                $loading.hide();
                reject(e);
            });
        });
    },

});

workspaceHelper.publishedWidgets.add({
    id: 'FIMPORT_LISTVIEW',
    view: HistoryList,
    options: {},
});

export default HistoryList;
