import Layout from '@glu/core/src/layout';
import CharsRemainingInput from 'app/serviceRequest/views/charactersRemainingInput';
import constants from 'app/serviceRequest/constants';
import util from '@glu/core/src/util';
import rtpRemittanceDialogTmpl from './rtpRemittanceDialog.hbs';

export default Layout.extend({
    template: rtpRemittanceDialogTmpl,

    attributes: {
        role: 'dialog',
        'aria-hidden': 'false',
        class: 'rtp-remittance-dialog',
    },

    ui: {
        $doneButton: '[data-hook="getDoneButton"]',
    },

    events: {
        'click @ui.$doneButton': 'addRemittance',
    },

    onRender() {
        this.remittanceEntryRegion.show(new CharsRemainingInput({
            model: this.model,
            label: '',
            inputType: constants.type.textArea,
            name: 'REMITTANCE_INFO',
            maxLength: 140,
            customClass: 'rtp-remittanceEntry',
            rows: 5,
        }));
        util.defer(() => {
            this.$('[data-hook="getInput"]').focus();
        });
    },

    /**
     * add the remittance is not blank and close
     */
    addRemittance() {
        this.options.parentView.trigger('rtp:remittance:entered');
        this.remove(); // close
    },

});
