import Layout from '@glu/core/src/layout';
import { BriefcaseNextIcon } from '@glu/icons-react';
import $ from 'jquery';
import store from 'system/utilities/cache';
import mobileUtil from 'mobile/util/mobileUtil';
import workspaceHelper from 'common/workspaces/api/helper';
import AccountSummary from 'app/balanceAndTransaction/views/accountSummary';
import AccountGroupSummary from 'app/balanceAndTransaction/views/accountGroupSummary';
import PreviousDaySummary from 'app/balanceAndTransaction/views/previousDaySummary';
import CurrentDaySummary from 'app/balanceAndTransaction/views/currentDaySummary';
import CashPositionSummary from 'app/balanceAndTransaction/views/cashPositionSummary';
import AccountReportingModel from 'app/balanceAndTransaction/models/accountReporting';
import EntitledGIRTypesModel from 'app/balanceAndTransaction/models/entitledGIRTypes';
import ReportingView from 'app/balanceAndTransaction/views/reporting';
import PreviousDayReportingView from 'app/balanceAndTransaction/views/previousDayReporting';
import StackView from 'common/stack/views/stackView';
import setupAccessibleTabs, { createTabsToggleButton, toggleTabs, setTabButtonText } from 'common/util/a11y/tabs';
import TransactionSearch from 'app/balanceAndTransaction/views/tranSearch/previousCurrentDaySearch';
import NewTransactionSearch from 'app/balanceAndTransaction/views/tranSearch/previousDaySearch';
import constants from 'app/balanceAndTransaction/constants';
import util from '@glu/core/src/util';
import applicationConfiguration from 'system/webseries/models/applicationConfiguration';
import systemConfig from 'system/configuration';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import template from './balanceRptContainer.hbs';

const BalanceAndTransactionReporting = Layout.extend({
    template,
    isInitialRender: true,
    ui: {
        $navTabs: '[data-hook="getNavTabs"]',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
        $navItems: '[data-hook="getNavTabs"] .NavTabs-item',
        $transactionTab: '[data-hook="getTransactionBtn"]',
    },

    events: {
        'click @ui.$navLinks': 'switchActiveTab',
    },

    initialize(options) {
        this.options = options;
        this.model = new AccountReportingModel();
        this.entitledGIRTypesModel = new EntitledGIRTypesModel();
        this.initialTab = store.get('btr:initialTab');
        store.set('btr:fromInitialContainer', true);
    },

    onRender() {
        if (!this.loadedRequirements) {
            this.loadViewRequirements();
            return;
        }
        createTabsToggleButton(this);
        const tabViewFunction = this.getInitialTabView();
        tabViewFunction.call(this, this.isInitialRender);
        // btr:initialTab is stored when Tab changes for persistence
        this.currentTab = store.get('btr:initialTab');
        this.switchActiveTab(this.$el.find(`[data-action="${this.currentTab}"]`), true);
        if (this.isInitialRender) {
            this.isInitialRender = false;
            if (!mobileUtil.isMobileScreen()) {
                this.listenToOnce(this.activeView, 'afterRender', () => {
                    setupAccessibleTabs(this, this.activateTab);
                });
                return;
            }
        }

        if (mobileUtil.isMobileScreen) {
            this.ui.$navItems.removeAttr('role');
            this.setupMobileSizedKeyEvents();
        }
    },
    /**
     * Mobile-sized views have different keyboard behavior
     * because the tabs are arranged as a dropdown
     */
    setupMobileSizedKeyEvents() {
        const self = this;
        this.ui.$navLinks.on('keydown', (e) => {
            switch (e.key) {
            case 'ArrowUp':
                if ($(e.currentTarget).parent().prev().length) {
                    $(e.currentTarget).attr({ tabindex: -1 }).parent().prev()
                        .children()
                        .attr({ tabindex: 0 })
                        .focus();
                } else {
                    $(e.currentTarget).attr({ tabindex: -1 });
                    $(self.ui.$navItems[self.ui.$navItems.length - 1])
                        .children().attr({ tabindex: 0 }).focus();
                }
                e.stopPropagation();
                e.preventDefault();
                break;
            case 'ArrowDown':
                if ($(e.currentTarget).parent().next().length) {
                    $(e.currentTarget).attr({ tabindex: -1 }).parent().next()
                        .children()
                        .attr({ tabindex: 0 })
                        .focus();
                } else {
                    $(e.currentTarget).attr({ tabindex: -1 });
                    // $(self.ui.$navItems[0]).attr({ tabindex: 0 });
                    $(self.ui.$navItems[0]).children().attr({ tabindex: 0 }).focus();
                }
                e.stopPropagation();
                e.preventDefault();
                break;
            case ' ':
            case 'Space':
            case 'Enter':
                e.preventDefault();
                this.switchActiveTab(e);
                // Only do this when we CLOSE the menu, not on OPEN
                self[e.currentTarget.dataset.action].call(self);
                break;
            case 'Escape':
                toggleTabs(this);
                break;
            case 'Tab':
            default:
                break;
            }
        });
    },
    /*
     *  @method: setWidget
     *  @param: {View} Widget
     *  Method called by higher level SurrogateWidget to instantiate widget needs
     *  Needed because usually views will directly instantiate widget listeners for view
     *  But this page has dynamic views (activated tabs) that will need the widget
     * listeners for grids
     */
    setWidget(widget) {
        // store the widget to pass into child views
        this.widget = widget;
    },

    onClose() {
        // unset fetched btr entitlements used by child tab views when leaving the btr page
        store.unset('btr:entitlements');
        /**
         * HACK - NH-117328 6/12/19 When adding a widget, the workspace will re-render the
         * widgets, calling onClose, however loadedRequirements is still set, so we don't
         * re-fetch the entitlements. Resetting loaddedRequirements here resolves the issue.
         *
         * Should be able to be removed once NH-52954 is resolved.
         */
        this.loadedRequirements = false;
    },

    loadViewRequirements() {
        const self = this;
        const reportingEntitlementPromise = new Promise((resolve, reject) => {
            self.model.fetch({
                success: resolve,
                error: reject,
            });
        });

        const entitledGIRTypesPromise = new Promise((resolve, reject) => {
            self.entitledGIRTypesModel.fetch({
                success: resolve,
                error: reject,
            });
        });

        Promise.all([reportingEntitlementPromise, entitledGIRTypesPromise])
            .then(util.bind(function () {
                // store user's entitlements to be used by child tab views
                store.set('btr:entitlements', this.entitledGIRTypesModel);
                this.loadedRequirements = true;
                this.render();
            }, this))
            .catch(() => {
                // Throwing for uncaught promise error
            });
    },

    /*
     *  @method: switchActiveTab
     *  @param: {jquery Event/jquery object} e
     *  Removes old active tab and sets current
     */
    /**
     *  @method: switchActiveTab
     *  @param: {jquery Event/jquery object} e
     *  Removes old active tab and sets current
     */
    switchActiveTab(e, initial = false) {
        const $link = e.currentTarget ? this.$(e.currentTarget) : e;
        if (mobileUtil.isMobileScreen()) {
            this.currentTabText = ($link.text() || '').trim();
            if (initial) {
                setTabButtonText(this, this.currentTabText);
            } else {
                toggleTabs(this);
            }
            const isOpen = this.ui.$navTabs.hasClass('is-open');
            // If the same tab, exit
            if (isOpen && $link.attr('aria-selected') === 'true') {
                return;
            }
        }
        // Remove aria-selected from all tabs
        this.ui.$navLinks.attr('aria-selected', 'false');
        // Set aria-selected on our tab
        $link.attr('aria-selected', 'true');
        // Unset store's btrTabId and TOA location
        store.unset('btrTabId');
        store.unset('btr:toaLocation');
        this.ui.$navItems.removeClass('is-active');
        $link.parent().addClass('is-active');
    },

    /*
     *  @method: getActiveTab
     *  @returns {jquery object}
     *  Returns the jquery object of the current tab
     */
    getActiveTab() {
        let $tab;
        if (!util.isNullOrUndefined(this.initialTab)) {
            $tab = this.$(`a[data-action="${this.initialTab}"]`);
        } else {
            $tab = this.ui.$navLinks.first();
        }
        return $tab;
    },

    activateTab(e) {
        const currElementId = e.target.id;
        switch (currElementId) {
        case 'showAllAccountsLink':
            this.showAllAccounts();
            break;
        case 'showCashPositionGirtrnLink':
            this.showCashPosition_GIRTRN_I();
            break;
        case 'showAccountGroupsLink':
            this.showAccountGroups();
            break;
        case 'showCustomReportLink':
            this.showCustomReport();
            break;
        case 'showTransactionSearchLink':
            this.showTransactionSearch();
            break;
        case 'showPreviousDayLink':
            this.showPreviousDay();
            break;
        case 'showCurrentDayLink':
            this.showCurrentDay();
            break;
        case 'showCashPositionGirbalLink':
            this.showCashPosition();
            break;
        case 'showAccountGroupsPriorDayLink':
            this.showAccountGroupsPriorDay();
            break;
        case 'showNewCustomReportLink':
            this.showNewCustomReport();
            break;
        case 'showNewTransactionSearchLink':
            this.showNewTransactionSearch();
            break;
        default:
            break;
        }
        this.switchActiveTab(e);
    },

    /*
     *  @method: showAllAccounts
     *  Renders the account summary tab for all accounts
     */
    showAllAccounts() {
        store.set('btr:initialTab', 'showAllAccounts');
        this.activeView = new AccountSummary({
            tabId: constants.ACCOUNT_SUMMARY.TABS.ALL,
            widget: this.widget,
        });
        this.btrContentRegion.show(this.activeView);
    },

    /*
     *  @method: showAccountGroups
     *  Renders the account groups tab for all accounts
     */
    showAccountGroups() {
        const isAdmin = systemConfig.isAdmin();
        // Use same id as ALLACCOUNTS
        store.set('btr:initialTab', 'showAccountGroups');
        this.activeView = new AccountGroupSummary({
            /*
             * TODO Determine why account groups are required to use 'ALLACCOUNTS' for
             * the tabId. Changing this to something more appropriate like ACCOUNT_GROUPS
             * breaks many of the service calls downstream
             */
            tabId: constants.ACCOUNT_SUMMARY.TABS.ALL,
            ariaLabelledBy: 'showAccountGroupsLink',
            depositDetailPageUrl: 'BANK_ACC_INFO/depositAccountsTransactions',
            creditCardDetailPageUrl: constants.TRANSACTION_CREDIT_CARD_DETAIL,
            loanDetailPageUrl: 'BANK_ACC_INFO/loanAccountsTransactions',
            depositExportPrintURL:
                isAdmin ? constants.DEPOSIT_ACCTGRP_EXPORT_PRINT_URL
                    : constants.ASYNC_DEPOSIT_ACCTGRP_EXPORT_PRINT_URL,
            creditCardExportPrintURL: isAdmin
                ? constants.CREDIT_CARD_ACCTGRP_EXPORT_PRINT_URL
                : constants.ASYNC_CREDIT_CARD_ACCTGRP_EXPORT_PRINT_URL,
            loanExportPrintURL: isAdmin ? constants.LOAN_ACCTGRP_EXPORT_PRINT_URL
                : constants.ASYNC_LOAN_ACCTGRP_EXPORT_PRINT_URL,
            tieredLoanDetailPageUrl: 'BANK_ACC_INFO/tieredLoanAccountDetail',
        });
        this.btrContentRegion.show(this.activeView);
    },

    /*
     *  @method: showAccountGroupsPriorDay
     *  Renders the account groups tab for prior day accounts
     */
    showAccountGroupsPriorDay() {
        const isAdmin = systemConfig.isAdmin();
        // Use same id as ALLACCOUNTS
        store.set('btr:initialTab', 'showAccountGroupsPriorDay');
        this.activeView = new AccountGroupSummary({
            tabId: constants.ACCOUNT_SUMMARY.TABS.PRIOR,
            ariaLabelledBy: 'showAccountGroupsPriorDayLink',
            depositDetailPageUrl: constants.TRANSACTION_PREVDAY_DEPOSIT_DETAIL,
            creditCardDetailPageUrl: constants.TRANSACTION_PREVDAY_CREDIT_CARD_DETAIL,
            loanDetailPageUrl: 'BANK_ACC_INFO/loanPreviousDayAccountsTransactions',
            depositExportPrintURL: isAdmin
                ? constants.PREVIOUS_DAY_ACCTGRP_EXPORT_PRINT_URL
                : constants.ASYNC_PREVIOUS_DAY_ACCTGRP_EXPORT_PRINT_URL,
            creditCardExportPrintURL: isAdmin
                ? constants.PREVIOUS_DAY_CREDIT_CARD_ACCTGRP_EXPORT_PRINT_URL
                : constants.ASYNC_PREVIOUS_DAY_CREDIT_CARD_ACCTGRP_EXPORT_PRINT_URL,
            loanExportPrintURL: isAdmin
                ? constants.LOAN_PREV_DAY_ACCTGRP_EXPORT_PRINT_URL
                : constants.ASYNC_LOAN_PREV_DAY_ACCTGRP_EXPORT_PRINT_URL,
            tieredLoanDetailPageUrl: 'BANK_ACC_INFO/tieredLoanPreviousDayAccountDetail',
            toaExportPrintURL: constants.ASYNC_PREV_TOA_ACCTGRP_EXPORT_PRINT_URL,
        });
        this.btrContentRegion.show(this.activeView);
    },

    /*
     *  @method: showPreviousDay
     *  Renders the account summary tab for prior day accounts
     */
    showPreviousDay() {
        store.set('btr:initialTab', 'showPreviousDay');
        this.activeView = new PreviousDaySummary({
            tabId: constants.ACCOUNT_SUMMARY.TABS.PRIOR,
            widget: this.widget,
        });
        this.btrContentRegion.show(this.activeView);
    },

    /*
     *  @method: showCurrentDay
     *  Renders the account summary tab for current day accounts
     */
    showCurrentDay() {
        store.set('btr:initialTab', 'showCurrentDay');
        this.activeView = new CurrentDaySummary({
            tabId: constants.ACCOUNT_SUMMARY.TABS.CURRENT,
            widget: this.widget,
        });
        this.btrContentRegion.show(this.activeView);
    },

    /*
     *  @method: showCashPosition_GIRTRN_I
     *  Renders the cash position tab for Combined BTR Reporting (GIRTRN_I)
     */
    showCashPosition_GIRTRN_I() {
        store.set('btr:initialTab', 'showCashPosition_GIRTRN_I');
        this.activeView = new CashPositionSummary({
            tabId: constants.ACCOUNT_SUMMARY.TABS.CASH_POSITION_GIRTRN_I,
            urlTab: constants.CASH_POSITION_GIRTRN_I_TAB,
            widget: this.widget,
            ariaLabelledBy: 'showCashPositionGirtrnTab',
        });
        this.btrContentRegion.show(this.activeView);
    },

    /*
     *  @method: showCashPosition
     *  Renders the cash position tab for Separated BTR Reporting (GIRBAL_I)
     */
    showCashPosition() {
        store.set('btr:initialTab', 'showCashPosition');
        this.activeView = new CashPositionSummary({
            tabId: constants.ACCOUNT_SUMMARY.TABS.CASH_POSITION,
            urlTab: constants.CASH_POSITION_TAB,
            widget: this.widget,
            ariaLabelledBy: 'showCashPositionGirbalTab',
        });
        this.btrContentRegion.show(this.activeView);
    },

    /*
     *  @method: showCustomReport
     *  Renders the custom report tab for all accounts
     */
    showCustomReport() {
        const options = {
            workspaceStack: this.options.stack,
            tabId: constants.ACCOUNT_SUMMARY.TABS.CUSTOM_REPORT,
            rowsPerPage: constants.CUSTOM_REPORT_MAX_ACCOUNTS,
        };
        store.set('btr:initialTab', 'showCustomReport');
        this.activeView = new ReportingView(options);
        this.btrContentRegion.show(new StackView({ initItem: this.activeView }));
    },

    /*
     *  @method: showNewCustomReport
     *  Renders the custom report tab for prev/curr day entitlements
     */
    showNewCustomReport() {
        const options = {
            workspaceStack: this.options.stack,
            tabId: constants.ACCOUNT_SUMMARY.TABS.CUSTOM_REPORT_PRIOR,
            rowsPerPage: constants.CUSTOM_REPORT_MAX_ACCOUNTS,
        };
        /**
         * NH-175054 | config param CustRepoCurrDayDataForSeparatedPerm
         * Update to the UI to call Combined Tab Logic for the Separated model.
         * If Config Param = True - behavior should be that user is allowed to view intra-day
         * and real time balances and transaction data for the entitled account.
         * If Config Param = False (DEFAULT) - behavior should be that user will not see
         * the intra day and real time balance and transaction data for the entitled accounts.
         */
        const CustRepoCurrDayDataForSeparatedPerm =
            serverConfigParams.get('CustRepoCurrDayDataForSeparatedPerm')?.toLowerCase() === 'true';
        const View = CustRepoCurrDayDataForSeparatedPerm ? ReportingView : PreviousDayReportingView;
        store.set('btr:initialTab', CustRepoCurrDayDataForSeparatedPerm ? 'showCustomReport' : 'showNewCustomReport');
        this.activeView =
            new View(options);
        this.btrContentRegion.show(new StackView({ initItem: this.activeView }));
    },

    /*
     *  @method: showTransactionSearch
     *  Renders the transaction search tab all accounts entitlements
     */
    showTransactionSearch() {
        store.set('btr:initialTab', 'showTransactionSearch');
        this.activeView = new TransactionSearch(util.extend(
            this.options,
            {
                workspaceStack: this.options.stack,
            },
        ));
        this.btrContentRegion.show(new StackView({ initItem: this.activeView }));
    },

    /*
     *  @method: showNewTransactionSearch
     *  Renders the transaction search tab for prev/curr day entitlements
     */
    showNewTransactionSearch() {
        store.set('btr:initialTab', 'showNewTransactionSearch');
        this.activeView = new NewTransactionSearch(util.extend(
            this.options,
            {
                workspaceStack: this.options.stack,
            },
        ));
        this.btrContentRegion.show(new StackView({ initItem: this.activeView }));
    },

    /*
     *  @method: getInitialTabView
     *  @returns {function}
     *  Returns the function of the initial tab to be executed by the caller
     */
    getInitialTabView() {
        if (!util.isNullOrUndefined(this.initialTab)) {
            return this[this.initialTab];
        }
        if (this.hasCurrentOrPreviousTransactions()) {
            return this.showAllAccounts;
        }
        if (this.hasPreviousDaySummary()) {
            return this.showPreviousDay;
        }
        return this.showCurrentDay;
    },

    /*
     * @method: hasCurrentOrPreviousTransactions
     * @returns {Boolean}
     * Returns true when all accounts entitlements are selected
     */
    hasCurrentOrPreviousTransactions() {
        return this.entitledGIRTypesModel.isEntitled('GIRTRN_I') || this.entitledGIRTypesModel.isEntitled('GIRTRN_P');
    },

    /*
     * @method: hasPreviousDaySummary
     * @returns {Boolean}
     * Returns true when previous day entitlements is selected
     */
    hasPreviousDaySummary() {
        return this.entitledGIRTypesModel.isEntitled('GIRBAL_P');
    },

    /*
     * @method: hasCurrentDaySummary
     * @returns {Boolean}
     * Returns true when current day entitlements is selected
     */
    hasCurrentDaySummary() {
        return this.entitledGIRTypesModel.isEntitled('GIRBAL_I');
    },

    /*
     * @method: hasCashPositionSummary_GIRTRN_I
     * @returns {Boolean}
     * Returns true when the configuration is enabled
     * and the user has Current Day Transaction (GIRTRN_I) is enabled.
     */
    hasCashPositionSummary_GIRTRN_I() {
        return this.isEnabledShowCashRpt() && this.entitledGIRTypesModel.isEntitled('GIRTRN_I');
    },

    /*
     * @method: hasCashPositionSummary_GIRBAL_I
     * @returns {Boolean}
     * Returns true when the configuration is enabled
     * and the user has Current Day Balance (GIRBAL_I) entitled.
     */
    hasCashPositionSummary_GIRBAL_I() {
        return this.isEnabledShowCashRpt() && this.entitledGIRTypesModel.isEntitled('GIRBAL_I');
    },

    /*
     * @method: isShowCashRpt
     * @returns {Boolean}
     * Returns true when the CURRDAYCASHRPT configuration is enabled.
     */
    isEnabledShowCashRpt() {
        return applicationConfiguration.getValue('DEPOSITRPT', 'CURRDAYCASHRPT') === '1';
    },

    templateHelpers() {
        return {
            pageTitle: this.options.pageTitle,

            /*
             * Custom Reporting will not be allowed if
             * user is permitted to only RealTime Accounts
             */
            hasNonRealTimeAccounts: this.model.get('nonRealTimeAccountsCount') > 0,

            hasCurrentOrPreviousTransactions: this.hasCurrentOrPreviousTransactions(),
            hasPreviousDaySummary: this.hasPreviousDaySummary(),
            hasCurrentDaySummary: this.hasCurrentDaySummary(),
            hasCashPositionSummary_GIRTRN_I: this.hasCashPositionSummary_GIRTRN_I(),
            hasCashPositionSummary_GIRBAL_I: this.hasCashPositionSummary_GIRBAL_I(),
            hasNewPreviousDayTransactions: this.entitledGIRTypesModel.isEntitled('GIRBAL_P') && this.entitledGIRTypesModel.isEntitled('GIRTRA_P'),
        };
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'ACCTLIST',
    view: BalanceAndTransactionReporting,
    options: {},
    useMobileCard: true,
    mobileCardProps: {
        icon: BriefcaseNextIcon,
    },
});

export default BalanceAndTransactionReporting;
