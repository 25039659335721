import alert from '@glu/alerts';
import Collection from '@glu/core/src/collection';
import dialog from '@glu/dialog';
import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import dateUtil from 'common/util/dateUtil';
import locale from '@glu/locale';
import { log } from '@glu/core';

import $ from 'jquery';
import moment from 'moment';

import amountDueTmpl from 'app/loans/views/payment/amountDue.hbs';
import BalanceModel from 'app/loans/models/balance';
import balanceTmpl from 'app/loans/views/balance.hbs';
import Constants from 'app/loans/constants';
import Details from 'app/loans/models/details';
import domUtil from 'common/util/domUtil';
import dueDateTmpl from 'app/loans/views/dueDate.hbs';
import errorHandlers from 'system/error/handlers';
import Formatter from 'system/utilities/format';
import fxFieldValidation from 'common/uiWidgets/util/fxFieldValidation';
import gridApi from 'common/dynamicPages/api/grid';
import helpPageUtil from 'common/util/helpPage';
import interestRateUtil from 'common/util/interestRateUtil';
import InvoiceAmountsWidget from 'app/loans/views/invoiceAmountsWidget';
import EntryView from 'common/dynamicPages/views/workflow/entry';
import InvoiceWidget from 'app/loans/views/invoiceWidget';
import loans from 'app/loans/api/common';
import panelWorkflowAssignmentUtil from 'common/template/panelWorkflow/util';
import PanelWorkflowCodesCollection from 'common/template/panelWorkflow/panelWorkflowCodes';
import PaymentOptionsCollection from 'app/loans/collections/paymentOptions';
import paymentOptionTmpl from 'app/loans/views/paymentOption.hbs';
import PaymentTypeCollection from 'app/loans/collections/paymentType';
import PaymentUtil from 'common/util/paymentUtil';
import scroll from 'common/util/scroll';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import showAccountBalanceUtil from 'common/util/showAccountBalanceUtil';
import store from 'system/utilities/cache';
import transform from 'common/util/transform';
import userInfo from 'etc/userInfo';
import validatorPatterns from 'system/validatorPatterns';
import { createTransformLocaleView } from 'components/TransformLocale/TransformLocaleWrapper';
import helpers from './helpers';

/**
 * @param {JQuery Object} - The onscreen element to hide
 * @param {Model} - The model to remove the interest rate from
 * Remove interest rate values from the screen and model
 */
const clearInterestRateInformation = ($element, model) => {
    $element.addClass('hide');
    model.set('INTEREST_RATE', '');
};

export { clearInterestRateInformation };

export default Layout.extend({
    initialize(options) {
        this.accountDetails = {
            debit: {},
            credit: {},
        };
        this.contextKey = 'payment_listView_corp';
        this.showAccountBalance = serverConfigParams.get('DisplayAccountBalanceOnLoanScreens') === 'true';
        this.showInterestRate = serverConfigParams.get('LookupLoanDrawInterestRate') === 'true';
        this.variableRateSubtypes = serverConfigParams.get('LookupLoanDrawInterestRateAcctSubTypes')?.split(',') || [];
        this.credit = options.creditAccountCollection || new Collection();
        this.debit = options.debitAccountCollection || new Collection();
        this.paymentOptions = loans.getAvailablePaymentOptions(options.preferences);

        this.paymentTotal = 0;
        // all loans are entitled to at least one type of payment option
        this.currentAmountOfPaymentTypes = 1;
        this.cutOffTimes = [];
        this.processingDays = [];
        this.daysForward = [37];
        this.blockedDates = [];

        this.creditInquiryId = options.creditInquiryId;
        this.debitInquiryId = options.debitInquiryId;
        this.functionCode = options.functionCode;
        this.model = options.model;
        this.model.jsonData = options.jsonData;
        this.payMethod = options.payMethod;
        this.preferences = options.preferences;
        this.productCode = options.productCode;
        this.title = options.title || '';
        this.typeCode = options.typeCode;

        this.datePickerParameters = loans.getDatePickerParameters(this.typeCode);
        this.enabledOptions = new Collection(this.paymentOptions);
        this.amountOfPaymentOptions = this.enabledOptions.length;
        this.savedOptions = this.getSavedOptions(this.model, this.model.AMOUNTTYPES_SELECTED);
        this.savedCollection = new Collection(this.savedOptions);

        this.setSelectionForComboBox();
        this.addFieldData();
        this.addContext();

        this.initializeOptions = {
            gridApi,
        };

        this.listenTo(this.model, 'retrieved', this.handleBusinessDays);
        this.listenTo(this.model, 'invalid', scroll.scrollToFirstError);
        this.listenTo(this.model, 'modelAction:saveWithWarning', this.saveWithWarning);
        this.listenTo(this.model, 'dateChanged', this.updateCutoff);
    },

    events: {
        'change @ui.$amount': 'updateSummaryAmountTotal',
        'click #remove-option': 'removeOption',
        'change @ui.$dateField': 'updateCutoff',
    },

    ui: {
        // Inputs
        $accountName: '[name="accountName"]',
        $appliesTo: '[name="apply-to"]',
        $bankName: '[name="bankName"]',
        $clientAccountName: '[name="clientAccountName"]',
        $dateField: '[name="VALUE_DATE"]',
        $datePicker: 'input[name="VALUE_DATE"]',
        $saveAsTemplate: '[name="SAVEASTEMPLATE"]',

        // Actions
        $cancelButton: '[data-action="cancel"]',
        $saveButton: '[data-action="submit"]',
        $saveDraftButton: '[data-action="saveDraft"]',

        // Hooks
        $amount: '[data-hook="getAmount"]',
        $amountDue: '[data-hook="getAmountDue"]',
        $amountTotal: '[data-hook="getAmountTotal"]',
        $beneName: '[data-hook="getBeneName"]',
        $currency: '[data-hook="getCurrency"]',
        $date: '[data-hook="getDatePicker"]',
        $debitAccount: '[data-hook="getDebitAccountNumber"]',
        $invoices: '[data-hook="getInvoices"]',
        $noInvoice: '[data-hook="getNoInvoice"]',
        $recurringInfo: '[data-hook="getSchedPayRegion"]',
        $summaryAmountTotal: '[data-hook="getSummaryAmountTotal"]',
        $summaryDate: '[data-hook="getSummaryDate"]',
        $summaryOn: '[data-hook="getSummaryOn"]',
        $summaryTo: '[data-hook="getSummaryTo"]',
        $valueDate: '[data-hook="getValueDate"]',
        $valueDueDate: '[data-hook="getDueDate"]',

        // Shared General Selectors
        $additionalBalances: '.amounts-due',
        $creditAccount: '#BENE_ACCOUNT',
        $creditBalance: '.to-account .field-info',
        $debitBalance: '.from-account .field-info',
        $dueDate: '.payment-date .field-info',
        $interestRateContainer: '.interest-rate-container',
        $loader: '.loans-loading-overlay',
        $loanPaymentDate: '.loan-payment-date',
        $paymentOptions: '.payment-options',
        $templateCodeForm: '.template-code-form',
        $templateDescrForm: '.template-descr-form',
        $templateRestrictForm: '.restrict-check-form',
        $toggleForm: '#template-save',
        $toggleRestrict: '#RESTRICTTEMPLATE_FLAG',
        $viewHistory: 'button[name="VIEWHISTORY"]',
    },

    regions: {
        alertRegion: '#alertRegion',
        pmtInvoicesRegion: '[data-region="pmtInvoicesRegion"]',
        pmtInvoiceAmountsRegion: '[data-region="pmtInvoiceAmountsRegion"]',
        addAnotherPaymentOptionLinkRegion: '.addAnotherPaymentOptionLinkRegion',
    },

    behaviors: {
        ValidationSupplement: {},
    },

    loadRequiredData(panelWorkflowAssignment = false) {
        const helpPagePromise = helpPageUtil.getHelpPagePromise({
            productCode: 'RTGS',
            functionCode: this.functionCode,
            typeCode: this.typeCode,
            mode: this.mode === 'insert' ? 'INSERT' : '*',
        });
        let promises = [helpPagePromise];

        if (panelWorkflowAssignment) {
            const panelApprovalCheck = panelWorkflowAssignmentUtil
                .getPanelApprovalConfig(this.mode);
            this.panelWorkflowCodes = new PanelWorkflowCodesCollection();
            promises = [...promises, panelApprovalCheck, this.panelWorkflowCodes.fetch()];
        }

        Promise.all(promises).then((results) => {
            store.set('helpPage', results[0].helpPage);
            this.setHasLoadedRequiredData(true);
            this.render();
        }, util.bind(errorHandlers.loadingModal, this));
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            // clear out helpPage from cache
            store.unset('helpPage');
            this.loadRequiredData();
        } else {
            this.ui.$amount.inputmask('number');
        }
    },

    /*
     * Toggle's the "disabled" status of the footer button
     * @param {Boolean} enabled - The desired enabled status of the buttons
     */
    toggleButtonsEnabled(enabled) {
        domUtil.setDisabled([
            this.ui.$saveButton,
            this.ui.$saveDraftButton,
            this.ui.$cancelButton,
        ], !enabled);
    },

    disableValidators: EntryView.prototype.disableValidators,
    reenableValidators: EntryView.prototype.reenableValidators,
    enableButtons() { this.toggleButtonsEnabled(true); },

    /**
     * Adds field data to model
     */
    addFieldData() {
        this.model.fieldData = {
            DEBIT_ACCOUNT_NUMBER: {
                dependsOn: ['CREDIT_CURRENCY', 'BENE_ACCOUNT'],
            },

            BENE_ACCOUNT: {
                dependsOn: ['DEBIT_CURRENCY', 'DEBIT_ACCOUNT_NUMBER'],
            },
        };
    },

    /**
     * Toggle the visible status of the loader icon
     * @param {Boolean} shown - The desired shown status of the loader icon
     */
    toggleLoaderVisible(shown) {
        this.ui.$loader.toggleClass('hidden', !shown);
    },

    /**
     * @method Performs validations on the value date when changed on blur
     * or calendar for loans
     */
    valueDateFieldValidation() {
        const hidecutoff = serverConfigParams.get('hideCutoff');
        const showCutoff = !hidecutoff || !hidecutoff.includes(this.typeCode);
        const { typeInfo } = this.model.jsonData;
        const debitBankCode = this.model.get('DEBIT_BANK_CODE');
        const ccDebitBankCode = this.model.get('Debit_Bank_Code');
        const debitCountry = this.model.get('DEBIT_COUNTRY');
        const ccDebitCountry = this.model.get('Debit_Country');

        this.model.set({
            PRODUCT: typeInfo.productCode,
            FUNCTION: typeInfo.functionCode,
            TYPE: typeInfo.typeCode,
        });

        if (typeInfo.functionCode !== 'BHTMPL' && typeInfo.functionCode !== 'TMPL') {
            if (!debitBankCode && ccDebitBankCode) {
                this.model.set('DEBIT_BANK_CODE', ccDebitBankCode);
            }
            if (!debitCountry && ccDebitCountry) {
                this.model.set('DEBIT_COUNTRY', ccDebitCountry);
            }
            fxFieldValidation.doFieldValidation(this, this.model, 'VALUE_DATE', 'INSERT').then(() => {
                if (showCutoff) {
                    if (this.functionCode !== 'BHTMPL' && this.functionCode !== 'TMPL') {
                        PaymentUtil.showCutoff(this.model.get('CUTOFF_INFO'), $('.ui-datepicker-trigger'), this.typeCode);
                    }
                }
            });
        }
    },

    updateCutoff(e) {
        if (e && e.target) {
            this.model.set('VALUE_DATE', e.target.value);
        }
        this.valueDateFieldValidation();
        this.updateSummaryTotal();
    },

    /**
     * Adds context data to model
     */
    addContext() {
        if (this.typeCode === 'LOANPAY') {
            this.model.context = {
                serviceName: Constants.LOAN_PAYMENT_SERVICE_NAME,
            };
        } else {
            this.model.context = {
                serviceName: Constants.LOAN_DRAW_SERVICE_NAME,
            };
        }
    },

    /**
     * Sets initial selection for combo box
     */
    setSelectionForComboBox() {
        const selection = this.paymentOptions[0] ? this.paymentOptions[0].key : '';
        this.model.set({
            _oldPaymentType: this.typeCode,
            paymentType: this.typeCode,
            PARENTUSERGROUP: userInfo.get('group'),
            SELECTION_0: selection, // default to the first option in the comboBox
        });
    },

    /**
     * When the user elects to add another payment amount within a loan payment, we show that
     * amount input, as well as the associated amount type dropdown, and protect the input
     * if needed
     */
    addOption() {
        // don't allow the user to add more than the maximum amount of payments
        if (this.currentAmountOfPaymentTypes < this.amountOfPaymentOptions) {
            const newPaymentTypeIndex = this.currentAmountOfPaymentTypes;
            const $specificOptionContainer = this.$el.find(`.multifield-container-${newPaymentTypeIndex}`);
            const $specificOptionTypeDropdown = $specificOptionContainer.find(`#selection_${newPaymentTypeIndex}`);

            // show the amount input and amount type dropdown for this paymentTypeIndex
            $specificOptionContainer.removeClass('hide');

            // add a validator to the newly visible amount input
            this.model.addValidator(`AMOUNT_${newPaymentTypeIndex}`, this.createValidator('PAMOUNT_PATTERN'));

            // set the default value for this amount type dropdown
            this.model.set(`SELECTION_${newPaymentTypeIndex}`, $specificOptionTypeDropdown.select2('data').id);

            // trigger a change on the amounttype dropdown to protect the amount input if needed
            this.onAmountTypeChanged($specificOptionTypeDropdown, true);

            // increment the amount of visible and active amount types
            this.currentAmountOfPaymentTypes += 1;
        }
    },

    /**
     * When a user elects to remove a specific payment amount within a loan payment, we need
     * to remove that amount and type from the model. On screen we want to hide the last
     * displayed inputs as well so that add/remove continues to function as expected. This
     * requires cascading amounts and types to the "removed" element and hiding the last.
     *
     * ex: Given the amount fields [1, 2, 3, hidden], removing the second amount should
     * result in [1, 3, hidden, hidden] not [1, hidden, 3, hidden].
     *
     * @param {Event/jQuery Selector} e - the click event on the "remove" button, or the element
     *      itself
     * @param {Boolean} isFunctionCall - if this function is being called programmatically from
     *      another function rather than from an actual on screen user interaction.
     */
    removeOption(e, isFunctionCall = false) {
        // if this function is being programmatically called, use the provided DOM element
        const optionCancelButton = isFunctionCall ? $(e).find('#remove-option') : e.currentTarget;

        const currIndex = $(optionCancelButton).attr('data-hook');

        if (this.currentAmountOfPaymentTypes === 1) {
            /*
             * If the removed amount is the only on screen amount field simply remove
             * the amount and reset the type to default.
             */
            this.model.set(`AMOUNT_${currIndex}`, '');

            const currAmountContainer = this.ui.$amount.closest(`.multifield-container-${currIndex}`);
            const currTypeDropdown = currAmountContainer.find(`#selection_${currIndex}`);
            currTypeDropdown.val(loans
                .getAvailablePaymentOptions(this.preferences[0])[0].key).change();
        } else {
            // More than one amount is visible on screen.
            let modelSetData = {};

            // Starting with the currently selected amount field and moving up ...
            for (let i = Number(currIndex); i < this.currentAmountOfPaymentTypes; i += 1) {
                const j = i + 1;
                const currAmountContainer = this.ui.$amount.closest(`.multifield-container-${i}`);
                const currTypeDropdown = currAmountContainer.find(`#selection_${i}`);
                const nextAmountContainer = this.ui.$amount.closest(`.multifield-container-${j}`);
                // const nextTypeDropdown = nextAmountContainer.find(`#selection_${j}`);

                if (Number(currIndex) === i) {
                    /*
                     * ... we need to remove the actual model value for the amount being
                     * removed. This is not the same as the on screen amount and amount
                     * type fields. ex: STANDARD_AMOUNT or PRINCIPAL_AMOUNT
                     */
                    modelSetData = {
                        ...modelSetData,
                        [currTypeDropdown.select2('data').id]: '',
                    };
                }

                if (!nextAmountContainer
                        || nextAmountContainer.length === 0
                        || nextAmountContainer.hasClass('hide')) {
                    /*
                     * ... if the next amount does not exist, does not contain fields, or is
                     * hidden, then this is the last amount so hide it and remove the validator
                     */
                    currAmountContainer.addClass('hide');
                    this.model.removeValidator(`AMOUNT_${i}`);
                    // Reset the dropdown to the default value
                    currTypeDropdown.val(loans
                        .getAvailablePaymentOptions(this.preferences[0])[0].key).change();

                    // Clear the values from the on screen amount and amount type fields.
                    modelSetData = {
                        ...modelSetData,
                        [`AMOUNT_${i}`]: '',
                        [`SELECTION_${i}`]: '',
                    };
                } else {
                    /*
                     * ... if the next amount field is visible, cascade the values down from
                     * that amount and amount type to the current. Then process the next.
                     */
                    modelSetData = {
                        ...modelSetData,
                        [`AMOUNT_${i}`]: this.model.get([`AMOUNT_${j}`]) || '',
                        [`SELECTION_${i}`]: this.model.get([`SELECTION_${j}`]) || '',
                    };
                }
            }

            this.model.set(modelSetData);
            // decrement the amount of visible and active amount types
            this.currentAmountOfPaymentTypes -= 1;
        }

        this.updateSummaryAmountTotal();

        /*
         * if this form is a template, check to see if we need to show/hide the
         * recurring info (ex: all protected amounts have been removed)
         */
        if (this.isTemplate) {
            this.showAndHideRecurringInfo();
        }
    },

    /**
     * @method Set up validation parameters for amount fields in loan views
     * @param {String} formatName - used to retrieve a format to compare the value against
     * @param {boolean} isRequired - the amount field is mandatory
     * @return {Object} Validation parameters
     */
    createValidator(formatName, isRequired = false) {
        let validators = {
            description: locale.get(`loans.${this.typeCode.toLowerCase()}.amount`),
            maxLength: 16,
            matches: validatorPatterns[formatName],
        };

        // Set minValue validator if its a payment
        if (this.contextKey === 'payment_listView_corp' || isRequired) {
            validators = ({
                ...validators,
                exists: true,
                minValue: 0.01,
            });
        }
        return validators;
    },

    /**
     * gets an array of accounts with certain elements calculated/added like display values
     * @param {Array} arrayOfModels - an array of models to be evaluated
     * @param {Model} acctModel - the backbone model for this specific account
     * @param {String} debitOrCredit - used to determine some model attribute names
     * @return {Array} - The finished array of accounts with newly desired values added
     */
    getArrayOfAccounts(arrayOfModels, acctModel, debitOrCredit) {
        // go through all models and evaluate each individually
        return arrayOfModels.map((currentModel) => {
            const account = acctModel.parse(currentModel.mapDataList);
            let accountNumber = currentModel.label;

            let subAccountNumber;

            /*
             * some displayed elements will depend on whether this model is associated with
             * a credit or debit account
             */
            if (debitOrCredit === 'debit') {
                accountNumber = account.DEBIT_ACCOUNT_NUMBER;
                subAccountNumber = util.isEmpty(account.DEBIT_SUBACCOUNT_NUM)
                    ? '' : account.DEBIT_SUBACCOUNT_NUM;
            } else if (debitOrCredit === 'credit') {
                accountNumber = account.BENE_ACCOUNT;
                subAccountNumber = util.isEmpty(account.BENE_SUBACCOUNT_NUM)
                    ? '' : account.BENE_SUBACCOUNT_NUM;
            }

            // add this model to the array to be returned
            return {
                value: `${accountNumber}${subAccountNumber}`,
                displayValue: currentModel.label,
                ACCOUNT_NUM: accountNumber,
                SUBACCOUNT_NUM: subAccountNumber,
                ACCOUNT_FILTER: currentModel.name,
            };
        });
    },

    /**
     * Click event listener used in loan views, Toggles the restrict template flag on the model
     * as well as the visual indicator on the target element
     * @param {Event} e - the click event
     */
    toggleRestrict(e) {
        const currentTarget = $(e.currentTarget);
        if (currentTarget.hasClass('restricted')) {
            currentTarget.removeClass('hide');
            this.model.set('RESTRICTTEMPLATE_FLAG', '1');
        } else {
            currentTarget.addClass('restricted');
            this.model.set('RESTRICTTEMPLATE_FLAG', '0');
        }
    },

    /**
     * update the onscreen payment total, as well as the value on the model
     * @param {String} totalSource - indicates where we're pulling the "total" from
     */
    updateSummaryAmountTotal(totalSource = 'uiAmount') {
        let total = '';
        if (totalSource === 'invoiceWidget') {
            total = loans.getTotal(this.invoiceAmountsWidget.ui.$amounts).formatAs.number;
        } else if (totalSource === 'uiAmount' || typeof totalSource === 'object') {
            // 'object' accounts for when this function is run as an event listener
            total = loans.getTotal(this.ui.$amount).formatAs.number;
        }

        /*
         * The model value should be set to an empty string when zero so that the mandatory
         * field validation triggers when appropriate.
         */
        this.model.set('CREDIT_AMOUNT', (total === '0.00') ? '' : total);
        this.ui.$summaryAmountTotal.text(total);
        if (this.model.get('CREDIT_CURRENCY') && this.model.get('CREDIT_CURRENCY') !== 'NONE') {
            this.ui.$currency.text(this.model.get('CREDIT_CURRENCY'));
        }
        this.updateSummaryTotal();
    },

    getPaymentMessage() {
        PaymentUtil.getPaymentMessage(this.model.jsonData, this);
    },

    /**
     * @method onClose
     * @description - method that is invoked when the view is closed.
     * If we are not a batch child view, then unset the helpPage that is used for
     * the global help.
     *
     */
    onClose() {
        store.unset('helpPage'); // remove view helppage from cache
    },

    /**
     * When switching between loan accounts, if the new loan account has a protected
     * default amount, a warning needs to be shown to the user
     * @param {Object} params - specific details about the account selected and the change
     * @param {Model} accountBalancesModel - the returned balances model for this account
     */
    warnIfLoanAccountHasProtectedDefault(params, accountBalancesModel) {
        const defaultOption = loans.getAvailablePaymentOptions(this.preferences[0])[0];
        const dialogWarning = this.isTemplate ? 'RTGS.loanpay.dueAmountProtected' : 'RTGS.LoanIneditableAmountWarning';
        /*
         * by excluding situations where the previous loan account selection was null, we avoid
         * showing this modal for the first selected loan account, and when the loan account is
         * selected on page-load when modifying a template or payment
         */
        if (defaultOption.amountLocked && params.loanChangeEvent
                && params.loanChangeEvent.removed
                && !params.manuallyTriggered) {
            dialog.confirm(locale.get(dialogWarning), (confirm) => {
                if (confirm) {
                    // the user has elected to go through with selecting this loan account
                    this.showBalances(accountBalancesModel, params);
                    this.displayPaymentOptions();
                    return;
                }
                // the user has elected to cancel the selection of this loan account

                // restore any view variables to their previous state
                this.balance = this.oldBalance;

                // revert the selected loan to its previous value
                $(params.loanChangeEvent.currentTarget)
                    .val(params.loanChangeEvent.removed.id);
                this.model.set('BENE_ACCOUNT', params.loanChangeEvent.removed.id, { silent: true });

                /*
                 * manually trigger a scoped change on the loan dropdown to update the
                 * selected amount type on the DOM and manually set the BENE_ACCOUNT as well.
                 * this circumvents viewbinding and stops us from running unnecessary listeners
                 * in onLoanAccountChanged
                 */
                $(params.loanChangeEvent.currentTarget).trigger('change.select2');
            });
        } else {
            this.showBalances(accountBalancesModel, params);
            this.displayPaymentOptions();
        }
    },

    /**
     * Kicks off a call to retrieve payment option types for an account
     * @param {Object} params - specific details about the account selected and the change
     * @param {Boolean} loanAccountChanged - *optional* inidicates that the user has
     * selected a new loan account
     * @param {Model} accountBalancesModel - *optional* the returned balances model for
     * this account
     */
    fetchPaymentOptions(params, loanAccountChanged = false, accountBalancesModel) {
        const paymentOptions = new PaymentOptionsCollection({
            account: params.account,
        });
        paymentOptions.fetch({
            success: () => {
                this.toggleLoaderVisible(false);
                this.preferences = paymentOptions.toJSON();

                // remove any existing payment options since we're populating with new ones
                this.removeExistingPaymentOptions();

                /*
                 * only check for protected amounts, show warning modals, update the model, and
                 * show balances if the user has changed loan accounts
                 */
                if (loanAccountChanged) {
                    /*
                     * show warning modal to verify that the user wants to switch to a loan
                     * account that features a protected default amount
                     */
                    this.warnIfLoanAccountHasProtectedDefault(
                        params,
                        accountBalancesModel,
                    );
                    helpers.showAddAnotherPaymentOptionLinkRegion(this);
                } else {
                    this.displayPaymentOptions();
                }
            },
        });
    },

    /**
     * Checks to see if there are any protected amount inputs, and if there are, then it hides
     * and clears the recurring info for templates. Otherwise it shows the recurring info.
     */
    showAndHideRecurringInfo(variableInterestLoan) {
        // if this isn't a template, don't bother showing/hiding recurring info
        if (!this.isTemplate) {
            return;
        }

        if (this.allAmountsAreUnprotected() && !variableInterestLoan) {
            // show the recurring checkbox
            this.ui.$recurringInfo.show();
        } else {
            // clear out individual fields by manually deselecting the recurring checkbox
            this.scheduleWidgetObj.ui.recurCheck.prop('checked', false).change();

            // hide the recurring checkbox
            this.ui.$recurringInfo.hide();
        }
    },

    /**
     * Determines if there are any visible and active payment options that currently have
     * protected amount inputs. Used to determine whether to disable recurring payments on
     * templates.
     * @return {Boolean} - if all of the amount types are unprotected
     */
    allAmountsAreUnprotected() {
        // get all visible amount inputs, and check for the presence of disabled ones
        return this.$el.find('[data-hook="getAmount"]:visible').toArray().every(el => el.disabled === false);
    },

    /**
     * Shows/hides a warning based on the input's protected status
     */
    showAndHideProtectedWarning(amountInput) {
        const amountInputContainer = amountInput.parents('.form-group');
        const amountInputWarning = amountInputContainer.children('.help-block');

        if (amountInput.prop('disabled')) {
            amountInputContainer.addClass('has-warning');
            amountInputWarning.html(locale.get('RTGS.loanpay.dueAmountProtected'));
        } else {
            amountInputContainer.removeClass('has-warning');
            amountInputWarning.html('');
        }
    },

    /**
     * Using an amount type, set a default value for the now protected amount input
     * @param {String} amountType - The amount type that is set to be protected
     */
    retrieveProtectedValue(amountType) {
        let amountTypeNameMap = amountType;
        if (amountType === 'STANDARD_AMOUNT') {
            amountTypeNameMap = 'PAYMENT_AMOUNT';
        }

        return this.balance.get(`${amountTypeNameMap.split('_')[0].toLowerCase()}DueAmount`);
    },

    /**
     * When an amount type changes, if it's intended to be a protected type, protect the input
     * and populate it with the specified amount
     * @param {Event} e - The change event on the amount type dropdown
     * @param {Boolean} manualChange - indicates that this is a manual update to the element
     * rather than a result of a change event firing
     */
    onAmountTypeChanged(e, manualChange = false) {
        let changedElement;

        if (manualChange) {
            /*
             * When creating a loan draw payment, we may run into a situation where there is no
             * amount type dropdown when we manually update this field. If so, exit here
             */
            if (!e.get(0)) {
                return;
            }
            changedElement = e.get(0);
        } else {
            changedElement = e.currentTarget;
        }

        // This payment option has been hidden, don't bother protecting anything, exit here
        if (changedElement.value === '') {
            return;
        }

        const associatedAmountInput = $(changedElement).parents('.payment-option').find('[data-hook="getAmount"]');
        const dialogWarning = this.isTemplate ? 'RTGS.loanpay.dueAmountProtected' : 'RTGS.LoanIneditableAmountWarning';

        // this payment option should disable the amount input
        if (this.paymentOptions.find(option => option.key === changedElement.value)?.amountLocked) {
            // don't show warning modal for manual changes
            if (!manualChange && e.removed) {
                // show warning modal to verify that the user wants to protect this amount field
                dialog.confirm(locale.get(dialogWarning), (confirm) => {
                    if (confirm) {
                        associatedAmountInput.prop('disabled', true);
                        /*
                         * if this form is a template, check to see if we need to show/hide a
                         * warning on the now protected/unprotected amount input, and if we need
                         * to show/hide the recurrance info
                         */
                        if (this.isTemplate) {
                            associatedAmountInput.val('').change();
                            this.showAndHideProtectedWarning(associatedAmountInput);
                            this.showAndHideRecurringInfo();
                        } else {
                            associatedAmountInput.val(this.retrieveProtectedValue(changedElement
                                .value)).change();
                        }
                        return;
                    }
                    // revert the selected amountType to its previous value
                    $(changedElement).val(e.removed.id);
                    /*
                     * manually trigger a change on the amountType dropdown to update the
                     * selected amount type on the DOM (necessary for select2 dropdowns)
                     */
                    $(changedElement).change();
                });
            } else {
                associatedAmountInput.prop('disabled', true);
                associatedAmountInput.val((this.isTemplate ? ''
                    : this.retrieveProtectedValue(changedElement.value))).change();
            }
        } else if (associatedAmountInput.prop('disabled')) {
            associatedAmountInput.prop('disabled', false);
            associatedAmountInput.val('').change();
        }

        /*
         * if this form is a template, check to see if we need to show/hide a warning on the now
         * protected/unprotected amount input, and if we need to show/hide the recurrance info
         */
        if (this.isTemplate) {
            this.showAndHideProtectedWarning(associatedAmountInput);
            this.showAndHideRecurringInfo();
        }
    },

    /**
     * kicks off a call to retrieve balances for a selected account
     * @param {Object} options - specific details about the account selected and the change
     * event that prompted the account change
     */
    retrieveBalances(options) {
        const params = {
            ...options,
            prefix: locale.get('common.availableBalance'),
            isOriginAccount: (options.$element === this.ui.$debitBalance),
        };

        const balanceConfigurationObject = new BalanceModel({
            accountNumber: params.accountNumber,
            accountType: params.accountType,
            accountSubType: params.accountSubType,
            typeCode: params.typeCode,
            bankCode: params.bankCode,
            currency: params.currency,
            origin: params.isOriginAccount,
            subAccountNumber: params.subAccountNumber,
            showAccountBalance: this.showAccountBalance,
        });

        /*
         * if the user is retrieving the amounts for a loan account, there's a chance that
         * they can revert their selection in the future. So keep the track of the old
         * balance object just in case
         */
        if (!params.debitAccount) {
            this.oldBalance = this.balance;
            this.balance = balanceConfigurationObject;
        }

        balanceConfigurationObject.fetch({
            success: (accountBalancesModel) => {
                if (!params.account) {
                    this.toggleLoaderVisible(false);
                    return;
                }

                // show balances for the selected account
                this.showBalances(accountBalancesModel, params);

                // if this is a debit account, loan draw, or view workflow, exit here
                if (params.debitAccount || this.typeCode === 'LOANDRAW' || this.isViewWorkflow) {
                    this.toggleLoaderVisible(false);
                    return;
                }

                /*
                 * if this is a loan account, and payment options are relevant
                 * retrieve the valid payment options for this account
                 */
                if (this.selectedPaymentType === Constants.TYPE.ANY) {
                    this.fetchPaymentOptions(params, true, accountBalancesModel);
                }

                // if the payment type is NONE or INVOICE_ONLY then stop here
                if (this.selectedPaymentType === Constants.TYPE.NONE
                    || this.selectedPaymentType === Constants.TYPE.INVOICE_ONLY) {
                    this.toggleLoaderVisible(false);
                }
            },
        });
    },

    /**
     * updates various dependant variables and displays amounts once they are retrieved from
     * the 'retrieveBalances' call
     * @param {Model} accountBalancesModel - the returned balances model for this account
     * @param {Object} params - specific details about the account selected
     */
    showBalances(accountBalancesModel, params) {
        const account = {
            label: (this.typeCode === 'LOANPAY' && !params.isOriginAccount) ? locale.get('loans.outstanding.balance') : params.prefix,
            balance: accountBalancesModel.get('balance'),
            amountDue: accountBalancesModel.get('paymentDueAmount'),
            dueDate: accountBalancesModel.get('nextPaymentDueDate'),
            currency: accountBalancesModel.get('currency'),
            dueDateLabel: '',
        };

        const $balanceDomElement = params.$element;

        /**
         * If this is a 'view' workflow, all we want to do is show an account balance beneath a
         * selected account (if applicable). Don't worry about everything else.
         */
        if (this.isViewWorkflow && showAccountBalanceUtil.shouldShowAccountBalance(this.showAccountBalance, this.isTemplate, this.isViewWorkflow, this.model.get('STATUS'))) {
            /*
             * TODO: Keep from directly changing the html here by using balanceTmpl as a
             * template for a small view that is created/rendered as needed.
             */
            $balanceDomElement.get(0).innerHTML = balanceTmpl(account);
            return;
        }

        let { accountNumber } = params;
        let { subAccountNumber } = params;

        if (!params.manuallyTriggered) {
            accountNumber = params.selectedAcct.attr('data-accountnumber') ? params.selectedAcct.attr('data-accountnumber').trim() : '';
            subAccountNumber = params.selectedAcct.attr('data-subaccountnumber').trim();
        }
        if (params.debitAccount) {
            this.debitAccount = accountNumber;
            this.debitSubaccount = subAccountNumber;
        } else {
            this.creditAccount = accountNumber;
            this.creditSubaccount = subAccountNumber;

            this.model.set(params.loanAccountDataToSetOnModel);

            this.ui.$beneName.text(params.account.BENE_NAME);
            this.updateSummaryTotal();
            if (this.ui.$summaryTo.hasClass('hide')) {
                this.ui.$summaryTo.removeClass('hide');
            }

            if (this.datePickerParameters) {
                this.datePickerParameters.creditCurrency = params.account.CREDIT_CURRENCY;
                if (!this.isTemplate) {
                    this.model.getBusinessDays(this.datePickerParameters);
                }
            }

            if (!params.manuallyTriggered) {
                this.accountDetails.credit = params.account;
            }

            if (this.ui.$inputGroupAddon) {
                this.ui.$inputGroupAddon.show();
            }
        }

        let amountDue = '';
        let balance = '';
        let dueDate = '';

        if (!params.debitAccount) {
            if (this.isTemplate) {
                if (account.dueDate) {
                    dueDate = `${locale.get('loans.due.date')}${account.dueDate}`;
                }
                if (this.showAccountBalance) {
                    this.ui.$dueDate.text(dueDate);
                }
            }
            if (account.amountDue) {
                amountDue = `${locale.get('loans.amount.due', Formatter.formatCurrency(account.amountDue))} ${account.currency}`;
            }
            if (this.showAccountBalance && !this.isTemplate) {
                this.ui.$amountDue.text(amountDue);
            }
        } else {
            this.ui.$currency.html(account.currency);
        }

        if (accountBalancesModel.get('balance')) {
            balance = balanceTmpl(account);
        }

        if (!this.isTemplate) {
            if (account.dueDate && this.showAccountBalance) {
                if (this.typeCode === 'LOANPAY') {
                    account.dueDate = locale.get('loans.payment.due.date', moment(account.dueDate).format(userInfo.getDateFormat()));
                } else {
                    account.dueDate = moment(account.dueDate).format(userInfo.getDateFormat());
                    account.dueDateLabel = locale.get('loans.due.date');
                    dueDate = dueDateTmpl(account);
                }
            }
        }

        if (showAccountBalanceUtil.shouldShowAccountBalance(this.showAccountBalance, this.isTemplate, this.isViewWorkflow, this.model.get('STATUS'))) {
            /*
             * TODO: Keep from directly changing the html here by using balanceTmpl as a
             * template for a small view that is created/rendered as needed.
             */
            $balanceDomElement.get(0).innerHTML = balance;
        }

        if (!this.isTemplate) {
            if (this.typeCode === 'LOANPAY') {
                if (this.selectedPaymentType !== Constants.TYPE.INVOICE_ONLY) {
                    $(this.ui.$valueDueDate).html(account.dueDate);

                    if (!params.isOriginAccount) {
                        const dueAmounts = [
                            'past',
                            'principal',
                            'interest',
                            'escrow',
                            'other',
                        ].reduce((acc, key) => {
                            const amount = accountBalancesModel.get(`${key}DueAmount`);
                            if (amount) {
                                acc.push({
                                    label: locale.get(`loans.${key}.due`),
                                    balance: amount,
                                    currency: accountBalancesModel.get('currency'),
                                });
                            }
                            return acc;
                        }, []);

                        this.ui.$additionalBalances.html(amountDueTmpl(dueAmounts));
                        this.ui.$additionalBalances.removeClass('hide');
                    }
                }
            } else {
                $(this.ui.$dueDate).html(dueDate);
            }
        }
    },

    /**
     * Retrieve the details for a selected debit account
     * @param {jQuery Selector} selectedAcct - the selected loan account element
     * @param {Boolean} manuallyTriggered - optional paramater to indicate specific scenarios
     */
    getDebitAccountDetails(selectedAcct, manuallyTriggered = false) {
        if (!manuallyTriggered && (!selectedAcct || !selectedAcct.attr('data-accountfilter'))) {
            return;
        }

        const success = (debitAccountDetailsModel) => {
            const mapDataList = debitAccountDetailsModel.get('mapDataList');

            if (!mapDataList) {
                return;
            }

            mapDataList.map(mapDataParam => ({
                ...mapDataParam,
                value: util.unescape(mapDataParam.value),
            }));

            const returnedAccountModel = this.model.parse(mapDataList);

            const params = {
                accountNumber: returnedAccountModel.DEBIT_ACCOUNT_NUMBER,
                typeCode: this.typeCode,
                bankCode: returnedAccountModel.DEBIT_BANK_CODE,
                currency: returnedAccountModel.DEBIT_CURRENCY,
                subAccountNumber: returnedAccountModel.DEBIT_SUBACCOUNT_NUM,
                accountType: returnedAccountModel.ACCOUNT_TYPE,
                accountSubType: returnedAccountModel.ACCOUNT_SUBTYPE,
                $element: this.ui.$debitBalance,
                debitAccount: true,
                account: returnedAccountModel,
                manuallyTriggered,
                selectedAcct,
            };

            // set debit account data on the model
            this.model.set(transform.pairsToHash(mapDataList, 'toField', 'value'));

            if (!this.isTemplate) {
                // set params for the api request
                this.datePickerParameters.debitBank = returnedAccountModel.DEBIT_BANK_CODE;
                this.datePickerParameters.debitCurrency = returnedAccountModel.DEBIT_CURRENCY;
                this.datePickerParameters.debitBankCountry = returnedAccountModel.DEBIT_COUNTRY;
                this.model.getBusinessDays(this.datePickerParameters);
            }

            if (this.scheduleWidgetObj) {
                if (this.showInterestRate &&
                this.variableRateSubtypes?.includes(returnedAccountModel.ACCOUNT_SUBTYPE) &&
                this.isTemplate && params.debitAccount && this.typeCode === 'LOANDRAW') {
                    this.showAndHideRecurringInfo(true);
                } else {
                    this.showAndHideRecurringInfo(false);
                }
            }

            if (!manuallyTriggered) {
                this.accountDetails.debit = returnedAccountModel;
            }

            this.retrieveBalances(params);
            this.retrieveInterestRate(returnedAccountModel);
        };

        let accountParams;

        if (!manuallyTriggered) {
            const account = selectedAcct.attr('data-accountfilter').trim();
            const accountModel = this.debit.findWhere({
                name: account,
            });
            if (accountModel) {
                success(accountModel);
                return;
            }
            accountParams = {
                currency: 'NONE',
                account,
            };
        }

        this.details = this.formatAccountDetailsRequestData(accountParams, 'Debit', manuallyTriggered);

        // return early if there is no valid account to retrieve details for
        if (!this.details) {
            return;
        }

        this.details.fetch({ success });
    },

    /**
     * Assuming the proper configurations are met, attempts to retrieve interest rate information
     * for an account
     * @param {Object} returnedAccountModel - the loan account model
     * @return {Boolean/Promise} - either false if invalid account, or the interest rate promise
     * if the account is valid
     */
    retrieveInterestRate(returnedAccountModel) {
        // We do not show interest rates for templates so there is no work to be done here
        if (this.isTemplate) {
            return false;
        }

        /*
         * When show interest rate config is inactive, OR when the account subtype is not a
         * variable rate account type, clear the interest rate if previously obtained and exit.
         */
        if (!this.showInterestRate ||
            !this.variableRateSubtypes?.includes(returnedAccountModel.ACCOUNT_SUBTYPE)) {
            clearInterestRateInformation(this.ui.$interestRateContainer, this.model);
            return false;
        }

        // Config is active and account subtype is variable rate, call interest rate service
        return interestRateUtil.createPromiseToRetrieveInterestRate({
            productCode: this.productCode,
            functionCode: this.functionCode,
            typeCode: this.typeCode,
            bankCode: returnedAccountModel.DEBIT_BANK_CODE,
            accountNumber: returnedAccountModel.DEBIT_ACCOUNT_NUMBER,
            currency: returnedAccountModel.DEBIT_CURRENCY,
            subAccountNum: returnedAccountModel.DEBIT_SUBACCOUNT_NUM,
            accountFilter: returnedAccountModel.ACCOUNTFILTER,
        }).then(
            (response) => {
                if (response.status === 'success' && !!response.interestRate) {
                    this.ui.$interestRateContainer.removeClass('hide');
                    this.model.set('INTEREST_RATE', response.interestRate);
                } else {
                    clearInterestRateInformation(this.ui.$interestRateContainer, this.model);
                }
            },
            (err) => {
                clearInterestRateInformation(this.ui.$interestRateContainer, this.model);
                log.error(err);
            },
        );
    },

    /**
     * format the request for account details
     * @param {Object} accountParams - Object contains account,currency of selected account item
     * @param {String} typeOfAccount - Debit or Credit
     * @param {Boolean} manuallyTriggered - optional paramater to indicate specific scenarios
     * @return {Boolean/Object} - Exit early OR the formatted request Data
     */
    formatAccountDetailsRequestData(accountParams, typeOfAccount, manuallyTriggered = false) {
        let currency;
        let account;
        const lowercaseTypeOfAccount = typeOfAccount.toLowerCase();

        /*
         * Since getLoanAccountDetails was triggered manually, some of the steps and references
         * are different
         */
        if (manuallyTriggered) {
            currency = this.model.get(`${typeOfAccount.toUpperCase()}_CURRENCY`);
            account = typeOfAccount === 'Debit' ? this.model.get('ACCOUNTFILTER') : this.model.get('BENE_ACCOUNTENTITLEMENT');
        } else {
            if (!accountParams || !accountParams.currency || !accountParams.account) {
                return false;
            }
            ({ currency, account } = accountParams);
        }

        const formattedRequest = {
            inquiryId: this[`${lowercaseTypeOfAccount}InquiryId`],
            typeCode: this.typeCode,
            account,
            filterParam: typeOfAccount === 'Debit' ? 'Credit' : 'Debit',
            currency,
        };

        if (this.typeCode === 'LOANPAY' && typeOfAccount === 'Credit') {
            formattedRequest.subAccountNum = this.model.get('BENE_SUBACCOUNT_NUM');
        } else if (this.typeCode === 'LOANDRAW' && typeOfAccount === 'Debit') {
            formattedRequest.subAccountNum = this.model.get('DEBIT_SUBACCOUNT_NUM');
        }
        if (typeOfAccount === 'Credit') {
            formattedRequest.beneAccount = this.model.get('DEBIT_ACCOUNT_NUMBER');
        }
        return new Details(formattedRequest);
    },

    /**
     * Retrieve the details for a selected loan account
     * @param {Event} loanChangeEvent - the change event for this dropdown
     * @param {jQuery Selector} selectedAcct - the selected loan account element
     * @param {Boolean} manuallyTriggered - optional paramater to indicate specific scenarios
     */
    getLoanAccountDetails(loanChangeEvent, selectedAcct, manuallyTriggered = false) {
        if (!manuallyTriggered && (!selectedAcct || !selectedAcct.attr('data-accountfilter'))) {
            return;
        }

        const success = (loanAccountDetailsModel) => {
            let mapDataList = loanAccountDetailsModel.get('mapDataList');

            mapDataList = util.map(mapDataList, (mapDataParam) => {
                const mapData = mapDataParam;
                mapData.value = util.unescape(mapData.value);
                return mapData;
            });

            // return early if there's no data to set on the model
            if (!mapDataList) {
                return;
            }

            const returnedAccountModel = this.model.parse(mapDataList);

            const params = {
                accountNumber: returnedAccountModel.BENE_ACCOUNT,
                typeCode: this.typeCode,
                bankCode: returnedAccountModel.BENE_BANK_CODE,
                currency: returnedAccountModel.CREDIT_CURRENCY,
                subAccountNumber: returnedAccountModel.BENE_SUBACCOUNT_NUM,
                $element: this.ui.$creditBalance,
                accountType: returnedAccountModel.BENE_ACCOUNT_TYPE,
                accountSubType: returnedAccountModel.BENEACCOUNT_SUBTYPE,
                account: returnedAccountModel,
                loanAccountDataToSetOnModel: transform.pairsToHash(mapDataList, 'toField', 'value'),
                invoiceNum: this.typeCode === 'LOANPAY' ? this.model.get('INVOICENUM') : '',
                manuallyTriggered,
                loanChangeEvent,
                selectedAcct,
            };

            this.toggleLoaderVisible(true);
            // if this is a loan payment (not a template) determine relevant payment type
            if (this.typeCode === 'LOANPAY' && !this.isTemplate) {
                this.retrievePaymentType(params);
            } else {
                this.retrieveBalances(params);
            }
        };

        let accountParams;

        if (!manuallyTriggered) {
            const account = selectedAcct.attr('data-accountfilter').trim();
            const accountModel = this.credit.findWhere({
                name: account,
            });
            if (accountModel) {
                success(accountModel);
                return;
            }
            accountParams = {
                currency: 'NONE',
                account,
            };
        }

        this.details = this.formatAccountDetailsRequestData(accountParams, 'Credit', manuallyTriggered);

        // return early if there is no valid account to retrieve details for
        if (!this.details) {
            return;
        }

        this.details.fetch({ success });
    },

    /**
     * @method fetchPaymentType
     * @param {Object} params
     * @description When a Loan payment loan account is selected, retrieve the
     * payment types for the account
     */
    retrievePaymentType(params) {
        this.paymentTypeCollection = new PaymentTypeCollection({
            ...params,
            ...params.account,
        });
        this.ui.$paymentOptions.addClass('hide');
        this.ui.$noInvoice.addClass('hide');
        // re-enable footer buttons
        this.toggleButtonsEnabled(true);
        this.paymentTypeCollection.fetch({
            success: () => {
                this.processPaymentType(params);
                this.retrieveBalances(params);
            },
            error: () => {},
        });
    },

    /**
     * Change listener for the loan account dropdown
     * @param {Event} e - The change event
     */
    onLoanAccountChanged(e) {
        const selectedAcct = $(e.target).find('option:selected');

        // exit early if the selected loan account is faulty
        if (selectedAcct.length === 0 || !selectedAcct.attr('data-accountnumber')) {
            return;
        }

        this.selectedPaymentType = Constants.TYPE.ANY;
        this.ui.$creditBalance.removeClass('hide');
        this.toggleLoaderVisible(true);

        // retrieve loan account details and set them on the model
        this.getLoanAccountDetails(e, selectedAcct);
    },

    /**
     * Change listener for the loan account dropdown
     * @param {Event} e - The change event
     */
    onDebitAccountChanged(e) {
        const selectedAcct = $(e.target).find('option:selected');

        if (selectedAcct.length === 0 || !selectedAcct.attr('data-accountnumber')) {
            // templates don't have interest rate functionality
            if (!this.isTemplate) {
                /*
                 * if there is no account selected, hide any interest rates shown
                 * (should probably be doing this for account balances also!)
                 */
                clearInterestRateInformation(this.ui.$interestRateContainer, this.model);
            }
            return;
        }

        this.toggleLoaderVisible(true);

        this.getDebitAccountDetails(selectedAcct);
    },

    /**
     * Removes all existing payment options, used when switching between loan accounts
     */
    removeExistingPaymentOptions() {
        const populatedPaymentOptions = this.ui.$paymentOptions.find('.payment-option').toArray().slice(0, this.currentAmountOfPaymentTypes);
        populatedPaymentOptions.reverse().forEach((paymentOption) => {
            this.removeOption(paymentOption, true);
        });
    },

    /**
     * Populates and displays the potential payment types. for each potential amount added in
     * the loan payment. *note* ALL potential amount inputs and payment type dropdowns are
     * added to the screen. They're not dynamically added.
     */
    displayPaymentOptions() {
        this.paymentOptions = loans.getAvailablePaymentOptions(this.preferences[0]);
        this.enabledOptions = new Collection(this.paymentOptions);
        this.amountOfPaymentOptions = this.enabledOptions.length;

        const isScheduled = (this.model.schedModel && this.model.schedModel.get('onN'))
                || this.model.get('SCHEDULED')
                || (this.parentModel && this.parentModel.get('recur'))
                || this.model.get('recur');

        if (this.paymentOptions[0]) {
            this.model.set({
                SELECTION_0: this.paymentOptions[0].key,
            });
        }
        this.ui.$paymentOptions.removeClass('hide');

        const enabledOptions = this.enabledOptions.toJSON();

        // duplicated to avoid tweaking the original
        const enabledOptionsCollection = this.enabledOptions;

        let options;

        if (this.model.get('INVOICENUM')) {
            this.handlePaymentTypeChange(this.selectedPaymentType);
            return;
        }

        for (let x = 0; x < this.amountOfPaymentOptions; x += 1) {
            /*
             * unbind any currently existing change listeners so we don't apply multiple when
             * we switch between loan accounts
             */
            this.$el.find(`#selection_${x}`).unbind('change', $.proxy(this.onAmountTypeChanged, this));

            /*
             * Only relevant when modifying or creating from template,
             * If there are populated payment options present, show them to the user to ensure
             * they can modify or remove them as needed
             */
            if (x < this.savedCollection.length) {
                const savedModel = this.savedCollection.at(x);
                const key = savedModel.get('key');
                const amount = savedModel.get('optionAmount');

                // get the model from enabledCollection that matches the current saved option
                const model = enabledOptionsCollection.findWhere({
                    key,
                });

                // set the currentIndex for when adding / removing options
                this.currentAmountOfPaymentTypes = this.savedCollection.length;

                // remove that model from the collection
                enabledOptionsCollection.remove(model);

                // re-add it to the top
                enabledOptionsCollection.unshift(model);

                // render the re-ordered collection of options
                options = paymentOptionTmpl(enabledOptionsCollection.toJSON());

                // display the data. remove the old selected option on change
                this.$el.find(`#selection_${x}`).html(options).comboBox()
                    .on('change', $.proxy(this.onAmountTypeChanged, this));

                // set the amount to its' proper option for submission
                this.setPaymentAmountToType(amount, x);

                // add a validator for the default amount field
                this.model.addValidator(
                    `AMOUNT_${x}`,
                    this.createValidator('PAMOUNT_PATTERN', (x === 0 && isScheduled)),
                );

                /*
                 * When creating a payment from a template with saved options no longer enabled
                 * by the admin unset set the default values. The single enabled option is
                 * already set. This is an extreme edge case.
                 */
                if (this.paymentOptions.length === 1) {
                    const keysToUnset = ['STANDARD_AMOUNT', 'PRINCIPAL_AMOUNT', 'INTEREST_AMOUNT', 'ESCROW_AMOUNT', 'OTHER_AMOUNT', 'CREDIT_AMOUNT'];
                    keysToUnset.forEach((keyToUnset) => {
                        if (keyToUnset !== this.paymentOptions[0].key) {
                            this.model.unset(keyToUnset);
                        }
                    });
                } else {
                    // set the amount to its' bound field
                    this.model.set(`AMOUNT_${x}`, amount);
                    // set the selection to its' bound field
                    this.model.set(`SELECTION_${x}`, key);
                }

                // unhide this payment option
                if (this.$el.find(`.multifield-container-${x}`).hasClass('hide')) {
                    this.$el.find(`.multifield-container-${x}`).removeClass('hide');
                }

                /*
                 * manually trigger a change on this saved payment option to protect the
                 * amount input if needed
                 */
                this.onAmountTypeChanged(this.$el.find(`#selection_${x}`), true);
            } else {
                /*
                 * there are no saved payment options. The user is not modifying an existing
                 * payment or template;
                 * for EVERY available potential amount type, populate the amount type dropdown
                 * that's already on the screen with amount type options
                 */
                this.$el.find(`#selection_${x}`).html(paymentOptionTmpl(enabledOptions))
                    .comboBox().on('change', $.proxy(this.onAmountTypeChanged, this));
            }
        }

        if (!this.savedCollection.length) {
            /*
             * There is no saved list of options, just the default one. So manually trigger
             * a change on this single option to protect the amount input if needed
             */
            this.onAmountTypeChanged(this.$el.find(`#selection_${0}`), true);
        }

        // add a validator for the default amount field
        this.model.addValidator('AMOUNT_0', this.createValidator('PAMOUNT_PATTERN', isScheduled));

        /**
         * On Loan Draw, the AMOUNT_0 field is not populated by the Loan Draw field, so apply
         * this validator to the CREDIT_AMOUNT field as well
         */
        if (this.typeCode === 'LOANDRAW') {
            this.model.addValidator('CREDIT_AMOUNT', this.createValidator('PAMOUNT_PATTERN'), isScheduled);
        }
        // hide add button if only one enabled by admin
        if (this.paymentOptions.length === 1) {
            this.$el.find('#addOption').addClass('hide');
        }
        loans.removeOptionIcon(this, this.preferences[0].singleOnlyPaymentOptionSupported);
        // formats the displayed amount to be paid
        loans.getTotal(this.ui.$amount);

        this.updateSummaryAmountTotal();

        /*
         * When modifying payments/templates, we use these variables to know which payment
         * options to display on page load. Once these options are displayed we need to clear
         * these variables so that future loan account switching doesn't repopulate the
         * payment options onscreen with their data
         */
        this.model.set('AMOUNTTYPES_SELECTED', '');
        this.savedOptions = '';
        this.savedCollection = '';
    },

    /**
     * @method renderInvoices
     * @description Render the invoices widget
     */
    renderInvoices() {
        const invoiceNumber = this.model.get('INVOICENUM');
        const collection = this.paymentTypeCollection;
        const firstModel = collection.at(0);

        // exit early if there are no models within the collection
        if (collection.length === 0) {
            return;
        }

        /*
         * if the loan payment already DOES have a prior invoice number use it to get the
         * corresponding model by ID from the collection. If it DOES NOT have a number, use the
         * first model from the collection. If it DOES have a number, but that model is not
         * found because the user switched to a different loan account, use the first model.
         */
        this.invoiceWidget = new InvoiceWidget({
            collection,
            model: !util.isEmpty(invoiceNumber)
                ? (collection.get(invoiceNumber) || firstModel) : firstModel,
            parentModel: this.model,
            loanAccountType: this.paymentTypeCollection.accountType,
            selectedPaymentType: this.selectedPaymentType,
        });
        this.invoiceAmountsWidget = new InvoiceAmountsWidget({
            model: this.model,
        });
        if (this.selectedPaymentType === Constants.TYPE.INVOICE_ONLY) {
            this.invoiceWidget.setPreSelectedInvoice(collection.at(0));
        }
        this.listenTo(this.invoiceWidget, 'invoice:selection:changed', this.setSelectedInvoice);
        this.listenTo(this.invoiceWidget, 'invoice:paymentType:changed', this.handlePaymentTypeChange);
        this.pmtInvoicesRegion.show(this.invoiceWidget);
        this.pmtInvoiceAmountsRegion.show(this.invoiceAmountsWidget);
    },

    /**
     * @method setSelectedInvoice
     * @param attributes
     * @description Accepts model attributes from the invoice widget, sets them
     * directly onto the model
     */
    setSelectedInvoice(attributes) {
        this.model.set(attributes);
        this.invoiceAmountsWidget.handleSelect(this.model);
        this.ui.$additionalBalances.addClass('hide');
        this.ui.$creditBalance.addClass('hide');

        this.ui.$summaryAmountTotal.text(Formatter.formatNumber(this.model.get('CREDIT_AMOUNT')));
        this.ui.$currency.text(this.model.get('CREDIT_CURRENCY'));
        const dueDate = this.model.get('dueDate');
        if (dueDate && this.typeCode === 'LOANPAY'
            && (this.selectedPaymentType === Constants.TYPE.INVOICE_ONLY)) {
            const dueDateLabel = locale.get('loans.payment.due.date', moment(dueDate).format(userInfo.getDateFormat()));
            $(this.ui.$valueDueDate).html(dueDateLabel);
        }
        this.updateSummaryTotal();
    },

    /**
     * @method processPaymentType
     * @description Based on the type returned from the REST service, determine which form
     * elements to show/hide. These will be either invoices, payment types, neither, or both
     */
    processPaymentType() {
        this.selectedPaymentType = this.paymentTypeCollection.accountType;

        if (!this.selectedPaymentType) {
            this.toggleLoaderVisible(false);
            return;
        }

        // currently 4 possible types here: INVOICE_ONLY, PAY_ANY, NONE, BOTH
        if (this.selectedPaymentType === Constants.TYPE.NONE) {
            // if NONE, hide payment options and invoices, and disable submit button
            this.ui.$date.hide();
            this.ui.$noInvoice.removeClass('hide');
            this.pmtInvoicesRegion.close();
            this.pmtInvoiceAmountsRegion.close();
            this.updateSummaryAmountTotal();
            this.model.unset('INVOICENUM');
            this.toggleButtonsEnabled(false);
        } else {
            this.ui.$date.show();

            if (this.selectedPaymentType === Constants.TYPE.ANY) {
                // if PAY_ANY, hide invoice dropdown and show relevant payment options
                this.pmtInvoicesRegion.close();
                this.pmtInvoiceAmountsRegion.close();
                this.model.unset('INVOICENUM');
                return;
            }
            if (this.selectedPaymentType === Constants.TYPE.INVOICE_ONLY) {
                // if INVOICE_ONLY, show invoices and hide payment inputs and payment dropdowns
                this.ui.$invoices.show();
                this.renderInvoices();
                this.setSelectedInvoice(this.invoiceWidget.model.toJSON());
                this.ui.$paymentOptions.addClass('hide');
                this.updateSummaryAmountTotal('invoiceWidget');
                return;
            }
            if (this.selectedPaymentType === Constants.TYPE.BOTH) {
                // if BOTH, render invoice data
                this.ui.$invoices.show();
                this.renderInvoices();

                // if the user currently has an invoice selected (modifying an existing payment)
                if (this.model.get('INVOICENUM')) {
                    this.selectedPaymentType = Constants.TYPE.INVOICE_ONLY;
                    // hide the payment options and select the invoice
                    this.setSelectedInvoice(this.invoiceWidget.model.toJSON());
                    this.ui.$paymentOptions.addClass('hide');
                    this.updateSummaryAmountTotal('invoiceWidget');
                } else {
                    this.selectedPaymentType = Constants.TYPE.ANY;
                    // hide the rendered invoices, default to payment options
                    this.invoiceAmountsWidget.fieldsShouldBeHidden(true);
                    this.invoiceWidget.fieldsShouldBeHidden(true);
                }
            }
        }
    },

    /**
     * @method handlePaymentTypeChange
     * @param {string} type
     * When the payment type for a loan account is "both" the user manually selects between
     * invoice and "other" payment options. This function runs when a user changes that
     * manual selection
     */
    handlePaymentTypeChange(type) {
        const isInvoice = (type !== Constants.TYPE.ANY);
        // hide or show the payment options depending on the users selection
        this.ui.$paymentOptions.toggleClass('hide', isInvoice);
        this.selectedPaymentType = type;

        // if the user has selected invoice, select a preselected invoice, and show the fields
        if (isInvoice) {
            this.invoiceWidget.setPreSelectedInvoice(this.paymentTypeCollection.at(0));
            this.invoiceAmountsWidget.fieldsShouldBeHidden(false);
            this.invoiceWidget.fieldsShouldBeHidden(false);
        } else {
            /*
             * if a user has selected "other" payment options. Hide the invoiceWidget and show
             * the other payment options
             */
            this.invoiceAmountsWidget.fieldsShouldBeHidden(true);
            this.invoiceWidget.fieldsShouldBeHidden(true);
            this.clearPaymentDetailsOnModel();
            this.clearAmountFields();
            this.updateSummaryAmountTotal();
            if (this.typeCode === 'LOANPAY') {
                const dueDate = this.balance.get('nextPaymentDueDate');
                const dueDateLabel = dueDate ? locale.get('loans.payment.due.date', moment(dueDate).format(userInfo.getDateFormat())) : '';
                $(this.ui.$valueDueDate).html(dueDateLabel);
                this.ui.$additionalBalances.removeClass('hide');
                this.ui.$creditBalance.removeClass('hide');
            }
        }
    },

    /**
     * @method displayNoInvoiceError
     * Displays no invoice error message when invoice is
     * not available for the account
     */
    displayNoInvoiceError() {
        this.alertView = alert.danger(
            locale.get('loans.invoice.not.available'),
            {
                canDismiss: true,
                icon: 'warning',
            },
        );
        this.$('#alertRegion').hide();
        $('html, body').animate({
            scrollTop: 0,
        }, 100);
        this.alertRegion.show(this.alertView);
        this.$('#alertRegion').fadeIn(200, () => this.alertView.$el.focus());
    },

    /**
     * Creates an array of active payment types to display on the loan form
     * @param {Object} modelAttributes - The attributes for the current loan form model
     * @param {Array} selectedTypes - The specifically selected payment amount types for this
     * loan
     * @returns {Array} - all actively selected payment types
     */
    getSavedOptions(modelAttributes, selectedTypes) {
        if (!selectedTypes) {
            return [];
        }

        return selectedTypes.split(',').map((paymentAmountType) => {
            const [typeName] = paymentAmountType.split('_');
            return {
                key: paymentAmountType,
                value: locale.get(`RTGS.LOANS.OPTION.${typeName}`),
                optionAmount: modelAttributes[paymentAmountType],
            };
        });
    },

    /**
     * Clear all payment amounts on the model
     */
    clearPaymentDetailsOnModel() {
        const bulkSetObject = {};
        this.paymentOptions.forEach((option, index) => {
            bulkSetObject[option.key] = '';
            bulkSetObject[`SELECTION_${index}`] = '';
        });

        this.model.set(bulkSetObject);
    },

    /**
     * @method clearAmountFields
     * Clears all amount fields
     */
    clearAmountFields() {
        this.model.unset('AMOUNT_0');
        this.model.unset('AMOUNT_1');
        this.model.unset('AMOUNT_2');
        this.model.unset('AMOUNT_3');
        this.model.unset('AMOUNT_4');
    },

    getFormattedDate() {
        let dt = '';
        if (this.model.get('VALUE_DATE')) {
            dt = Formatter.formatDateFromUserFormat(
                this.model.get('VALUE_DATE'),
                dateUtil.PAYMENT_SUMMARY_DATE_FORMAT,
            );
        }
        return dt;
    },

    /**
     * @name getSummaryTotalParams
     * @description  returns the parameters for the summary total line
     * app resource
     */
    getSummaryTotalParams() {
        const amount = this.model.get('CREDIT_AMOUNT') || '0.00';
        const dt = this.getFormattedDate();
        let currency = this.model.get('DEBIT_CURRENCY') || '';
        let beneName = this.model.get('Bene_Name') || this.model.get('BENE_NAME') || '';
        if (beneName === 'NONE') {
            beneName = '';
        }
        if (currency === 'NONE') {
            currency = '';
        }
        const params = [{ value: Formatter.formatCurrency(amount), className: 'summary-large-text' },
            { value: currency }, { value: beneName }, { value: dt }];
        return params;
    },

    /**
     * @name updateSummaryTotal
     * @description  updates the summary total transformLocale string
     */
    updateSummaryTotal() {
        const params = this.getSummaryTotalParams();
        this.appBus.trigger(`update:localeMessage:${this.summaryLocaleKey}`, params);
    },

    /**
     * @name displaySummaryTotal
     * @description initializes the summary total line transformLocale string
     * we are using the transformLocale string to tokenize the app resource.
     */
    displaySummaryTotal(isTemplate = false) {
        const localeKey = (isTemplate) ? 'common.template.summary.total.loan' : 'common.summary.total.loan';
        this.summaryLocaleKey = localeKey;
        const params = this.getSummaryTotalParams();
        const SummaryTotalView = createTransformLocaleView({
            localeKey,
            params,
            tagName: 'p',
            tagClass: 'summaryTotal',
        });
        if (this.summaryTotalRegion) {
            this.summaryTotalRegion.show(new SummaryTotalView());
        }
    },

    /**
     * Allow the full account number to be returned when typed in
     * @param {string} type - Either 'credit' or 'debit'
     * @param {Object} params - search params from select2
     * @param {string} data - option data from select2
     */
    maskedInputMatcher(type, params, data) {
        // if no params, no search, display the item
        if (!params) {
            return data;
        }
        let theData = [];
        if (type === 'credit') {
            theData = this.credit;
        } else {
            theData = this.debit;
        }
        const thisItem = theData.find(item => item.get('label') === data);
        const matchesParams = [
            thisItem.get('label'),
            thisItem.get('name'),
        ].filter(string => string.includes(params));
        return matchesParams.length ? data : null;
    },
});
