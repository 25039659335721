import Model from '@glu/core/src/model';
import services from 'services';
import http from '@glu/core/src/http';
import SubPlanCollection from '../collection/subPlanTable';

const GroupPlan = Model.extend({
    defaults: {
        name: '',
        cashFlowPlanId: 0,
        version: 0,
        asNewVersion: false,
        groupPlan: true,
    },

    initialize() {
        // initialize the line items here
        this.set({
            groupPlanLines: new SubPlanCollection(),
        });
    },

    idAttribute: 'cashFlowPlanId',

    isChanged() {
        const isChanged = false;
        // Check if the line items have changed here
        return isChanged;
    },

    parse(response) {
        const parsed = {
            cashFlowPlanId: response.cashFlowPlanId,
            version: response.version,
            name: response.name,
            fyStart: response.fyStart,
            fyEnd: response.fyEnd,
            multiYearPlanId: response.multiYearPlanId,
            createdOn: response.createdOn,
            expectedDate: response.expectedDate,
        };

        // Add year in for displaying the group plan name in the combobox
        if (parsed.fyStart !== undefined) {
            parsed.year = parsed.fyStart.substring(7);
        }

        this.get('groupPlanLines').reset(response.cashFlowGroupPlanLines);

        return parsed;
    },

    sync(method, model, optionsParam) {
        const options = optionsParam;
        // results won't make sense if categories haven't loaded yet
        let xhr;

        options.method = method;

        switch (method) {
        case 'read':
            options.read = true;
            xhr = http.post(
                services.generateUrl('/cashflowPlan/getGroupPlan'), this.getReadJSON(),
                (response) => {
                    options.success(response);
                },
                (response) => {
                    options.error(response);
                },
            );
            break;

        case 'create':
        case 'update':
            xhr = http.post(
                services.generateUrl('/cashflowPlan/saveGroupPlan'), this.toServerJSON(),
                (response) => {
                    options.success(response);
                },
                (response) => {
                    options.error(response);
                },
            );
            break;
        default:
        }
        this.trigger('request', this, xhr, options);
        return xhr;
    },

    getReadJSON() {
        return {
            cashFlowPlanId: this.get('cashFlowPlanId'),
            version: this.get('version'),
            name: this.get('name'),
            fyStart: this.get('fyStart'),
            fyEnd: this.get('fyEnd'),
            multiYearPlanId: this.get('multiYearPlanId'),
        };
    },

    toServerJSON() {
        // encode the line items here

        let lines = this.get('groupPlanLines').get('cashFlowGroupPlanLines');
        if (!lines) {
            lines = [];
        }

        return {
            cashFlowPlanId: this.get('cashFlowPlanId'),
            version: this.get('version'),
            fyStart: this.get('fyStart'),
            fyEnd: this.get('fyEnd'),
            multiYearPlanId: this.get('multiYearPlanId'),
            name: this.get('name'),
            cashFlowPlanName: this.get('name'),
            asNewVersion: this.get('asNewVersion'),
            groupPlan: this.get('groupPlan'),
            cashFlowGroupPlanLines: lines,
        };
    },
});

export default GroupPlan;
