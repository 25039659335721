import ItemView from '@glu/core/src/itemView';
import template from './formattedCell.hbs';

export default ItemView.extend({
    template,

    className: 'text-right',

    templateHelpers() {
        return {
            formattedValue: this.getCheckReviewAmountValue(),
        };
    },

    /**
     * function to display formatted amount value in the review data table
     * @returns {String} formatted Amount data
     */
    getCheckReviewAmountValue() {
        return this.model.get('DISPLAY_STRING');
    },
});
