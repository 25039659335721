import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import alert from '@glu/alerts';
import locale from '@glu/locale';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import workspaceHelper from 'common/workspaces/api/helper';
import cookie from 'system/cookie';
import CheckFreeFieldCollection from '../collection/checkFreeCollection';
import CheckFreeModel from '../model/checkFreeModel';
import checkFreeSSOViewTmpl from './checkFreeSSOView.hbs';

const CheckFreeSSOView = Layout.extend({
    template: checkFreeSSOViewTmpl,
    className: 'check-free',

    events: {
        'click [data-hook="getSubmitBtn"]': 'handleSubmit',
        'click [data-hook="getCancelBtn"]': 'handleCancel',
        'click [data-hook="getDeleteBtn"]': 'handleDelete',
        'click [data-hook="getRegisterBtn"]': 'handleRegistry',
    },

    regions: {
        alertRegion: '.alert-region',
    },

    initialize(options) {
        this.collection = new CheckFreeFieldCollection({ vendorCode: options.vendorCode });
        this.model = new CheckFreeModel();
        this.listenTo(this.collection, 'reset', this.handleReset);
        this.collection.fetch();
    },

    /**
     * Event handler for collection reset event.  Synchronizes the collection
     * ss field with the models ss property.
     * @return {void}
     */
    handleReset() {
        this.model.set('ss', this.collection.getFieldValue('ss'));
    },

    isInModal() {
        return this.$el.parents('.modal-body').length;
    },

    /**
     * Event handler for the user clicking the submit button.
     * Synchronizes data between model and collection and saves the collection.
     */
    handleSubmit() {
        if (!this.model.isValid()) {
            return;
        }
        this.collection.setFieldValue('ss', this.model.get('ss'));
        this.collection.setFieldValue('pw', this.model.get('pw'));
        this.collection.getField('pw').set('ENCRYPTED', 1);
        this.collection.save()
            .then(util.bind(this.displaySuccess, this), util.bind(this.displayError, this));
    },

    handleDelete() {
        this.collection.destroy();
    },

    handleCancel() {
        this.trigger('cancel', this);
    },

    handleRegistry() {
        if (this.isInModal()) {
            this.trigger('register', this);
            return;
        }
        this.navigateTo('PAYMENTS/checkFree/register');
    },

    /**
     * Displays servers response within the view.
     * @param  {Object} response - Response object from server
     * @return {void}
     */
    displaySuccess(response) {
        const message = alert.success(response.message.length > 0 ? response.message[0] : locale.get('payment.success'));
        this.alertRegion.show(message);
        this.trigger('success', this);
    },

    /**
     * Displays error from server within the view.
     * @param  {Object} response - Response object from server.
     * @return {void}
     */
    displayError(response) {
        const message = alert.negative(response.message.length > 0 ? response.message[0] : locale.get('payment.error'));
        this.alertRegion.show(message);
    },

    /**
     * Returns structure of extra information for the template
     * @return {Object} Extra information for the template.
     */
    templateHelpers() {
        return {
            checkFreeRegistrationUrl: util.unescape(serverConfigParams.get('CheckFreeRegistrationURL')),
            notSimulation: util.isNullOrUndefined(cookie.get('SIMULATIONSESSIONTOKEN')),
        };
    },
});
// Registers this view as a widget.
workspaceHelper.publishedWidgets.add({
    id: 'CHECKFREE_SSO',
    view: CheckFreeSSOView,
    options: {},
});

export default CheckFreeSSOView;
