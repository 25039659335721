import locale from '@glu/locale';
import GridActionPopup from './gridActionPopup';
import gridActionPopupMap from './gridActionPopupMap';

export default {
    makePopup(data) {
        // any common grid action popup configuration items
        const gridActionPopupConfigurationObject = {
            action: data.action,
            buttonOffset: data.buttonOffset,
            model: data.model,
        };

        // any common fields view configuration items
        const fieldsViewConfigurationObject = {
            closeText: locale.get('button.close'),
            cancelText: locale.get('button.cancel'),
            model: data.model,
            submitText: locale.get('button.submit'),
        };

        const popupData = gridActionPopupMap[data.action];
        const {
            View, successLabel, title,
        } = popupData;
        this.gridActionPopup = new GridActionPopup({
            ...gridActionPopupConfigurationObject,
            fieldsView: new View({
                ...fieldsViewConfigurationObject,
                successLabel: locale.get(successLabel),
                title: locale.get(title),
                ...popupData.options,
            }),
            isReact: popupData.isReact,
        });
        // if the requested grid action popup doesn't have an existing configuration, do nothing
        if (!this.gridActionPopup) {
            return;
        }

        // render the popup
        this.gridActionPopup.render();
    },
};
