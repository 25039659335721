import Controller from '@glu/core/src/controller';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import services from 'services';
import scroll from 'common/util/scroll';
import DataConverter from './dataConverter';

const AddressBook = Controller.extend({
    initialize() {
        this.converter = new DataConverter({
            // paymentTypeCollection: options.paymentTypeCollection
        });
        this.handleCreateSuccess = util.bind(this.handleCreateSuccess, this);
        this.handleCreateFailure = util.bind(this.handleCreateFailure, this);

        this.reduce = util.bind(this.reduce, this);
    },

    create(data, contactOnly) {
        const requestData = this.converter.createRequest(data, contactOnly);
        return http.post(`${services.beneAddressBookAction}add`, requestData, this.handleCreateSuccess, this.handleCreateFailure);
    },

    read(id) {
        this.id = id;

        const requestData = {
            item: {
                item: [{
                    name: 'TNUM',
                    value: id,
                }],
            },
        };
        return Promise.all([
            http.post(services.beneAddressBook, requestData),
            http.post(services.beneAddressGetChildren, requestData),
        ])
            .then(results => util.extend(results[0], results[1]));
    },

    update(data, contactOnly) {
        const requestData = this.converter.createRequest(data, contactOnly);
        return http.post(`${services.beneAddressBookAction}update`, requestData, this.handleCreateSuccess, this.handleCreateFailure);
    },

    delete(id) {
        this.id = id;
    },

    handleCreateFailure(xhr) {
        this.trigger('create:fail', xhr);
        scroll.scrollToFirstError();
    },

    handleCreateSuccess(response) {
        if (response && response.result) {
            this.trigger('create:success', response);
        } else {
            this.trigger('create:error', response);
        }
        scroll.scrollToFirstError();
    },

    unescapeData(dataObjParam) {
        const dataObj = dataObjParam;
        Object.keys(dataObj || {}).forEach((prop) => {
            dataObj[prop] = util.unescape(dataObj[prop]);
        });
        return dataObj;
    },

    // reduces data set to what the UI expects in terms of payment types and data structure.
    reduce(result) {
        const returnRes = this.converter.reduce(result);
        returnRes.data = this.unescapeData(returnRes.data);
        return returnRes;
    },
});

export default AddressBook;
