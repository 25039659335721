/**
 * Represents a list of view types on the list layout
 *
 * @class ItemListView
 */
import CollectionView from '@glu/core/src/collectionView';
import LimitSet from './limitSet';

export default CollectionView.extend({
    tagName: 'div',
    className: 'limit-set-list',
    itemView: LimitSet,

    initialize(opts) {
        this.itemViewOptions = {
            mode: opts.mode,
        };
    },
});
