import util from '@glu/core/src/util';
import RFPEntryView from 'app/payments/views/requestForPayments/rfpEntry';
import restoreTemplateUtil from '../restoreTemplateUtil';

export default RFPEntryView.extend(util.extend(
    {},
    restoreTemplateUtil,
    {
        initialize() {
            const options = {
                menuCategory: 'PMTS',
                serviceName: '/requestTemplate/outgoingPaymentRequestTemplates',
                serviceFunc: null,
                businessType: null,
                context: 'RFP_TEMP_LIST_VIEW',
                mode: 'restore',
            };
            RFPEntryView.prototype.initialize.call(this, options);
        },
    },
));
