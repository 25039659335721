import NotificationEntryView from 'app/administration/views/notificationManagement/notificationEntry';
import NotificationModel from 'app/administration/models/notification';
import store from 'system/utilities/cache';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import constants from 'app/administration/constants';

export default NotificationEntryView.extend({
    initialize() {
        this.returnTab = 'template';
        this.serviceName = 'bannerNotificationTemplate';
        this.gridModel = store.get('bannerNotifications:read');
        if (this.gridModel) {
            this.gridModel.isCopyFromMessage = this.options.mode === constants.COPYTMPL;
        }
        this.model = new NotificationModel({
            serviceName: this.serviceName,
            gridModel: this.gridModel,
            mode: this.options.mode,
            notificationType: 'template',
        });
        if (this.options.mode === constants.INSERT) {
            this.model.set('POPULATIONTYPE', 'C');
        }
        this.filters = [];
    },

    templateHelpers() {
        const { mode } = this.options;
        return util.extend(
            NotificationEntryView.prototype.templateHelpers.call(this),
            {
                ModifyEntitled: this.isModEntitled,
                DeleteEntitled: this.isDelEntitled,
                isTemplateCodeReadonly: mode === constants.SELECT || mode === constants.MODIFY,
                isTemplateWorkflow: true,
                auditTitle: locale.get('common.templateHistory'),
                isShared: this.model.get('SHARED') === '1',
            },
        );
    },
});
