var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"contentId") || (depth0 != null ? lookupProperty(depth0,"contentId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contentId","hash":{},"data":data,"loc":{"start":{"line":8,"column":39},"end":{"line":8,"column":52}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":8,"column":54},"end":{"line":8,"column":62}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <div class=\"row\">\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"field-container-lg field-container\">\n                <label for=\"video-select\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"CXP.elearning.video",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":42},"end":{"line":5,"column":75}}}))
    + "</label>\n                <select class=\"form-control video-select\" name=\"video-select\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"videos") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":9,"column":29}}})) != null ? stack1 : "")
    + "                </select>\n            </div>\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"form-column col-md-12 horizontal\">\n            <video autoplay id=\"video-player\" controls>\n                <source id=\"video-source\">\n            </video>\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"form-column col-md-12 horizontal\">\n            <p class=\"form-control-static\" name=\"transcript-text\"></p>\n        </div>\n    </div>\n</div>\n\n\n";
},"useData":true});