import LayoutView from '@glu/core/src/layout';
import store from 'system/utilities/cache';
import services from 'services';
import exportBTRDataDetailTmpl from './exportBTRDataDetail.hbs';

export default LayoutView.extend({
    template: exportBTRDataDetailTmpl,

    ui: {
        $downloadButton: '[data-hook="getDownloadButton"]',
        $cancelButton: '[data-hook="getCancelButton"]',
    },

    initialize() {
        let messageText = '';
        if (store.has('baiExport_downloadExport-actionModel')) {
            this.model = store.get('baiExport_downloadExport-actionModel');
            messageText = this.model.get('MESSAGE_DETAIL');
        } else if (store.has('fileLoadAdmin-actionModel')) {
            this.model = store.get('fileLoadAdmin-actionModel');
            messageText = this.model.get('MESSAGE');
        }
        this.content = {
            exportType: this.model.get('TYPEDESC'),
            status: this.model.get('STATUS'),
            fileName: this.model.get('FILENAME'),
            submissionTime: this.model.get('SUBMISSIONTIME'),
            completionTime: this.model.get('COMPLETIONTIME'),
            fileSize: this.model.get('EXPORTFILESIZE'),
            message: messageText,
        };
    },

    onRender() {
        const enabledStatus = ['AP', 'RL'];
        if (!enabledStatus.includes(this.model.get('STATUS')) && this.ui.$downloadButton.length > 0 && this.ui.$downloadButton.prop) {
            this.ui.$downloadButton.prop('disabled', true);
        }
    },

    cancel() {
        this.disableButtons();
        window.history.back();
    },

    dload() {
        if (this.$('#downloadForm').length === 0) {
            const f = `<form id="downloadForm" action="${services.generateUrl('/export/download ')}" method="get" target="_blank"><input id="exportId" type="hidden" name="exportId"></form>`;
            this.$el.append(f);
        }
        this.$('#downloadForm #exportId').val(this.model.get('EXPORTID'));
        this.$('#downloadForm').submit();
        window.history.back();
    },

    disableButtons() {
        if (this.ui.$downloadButton && this.ui.$downloadButton.prop) {
            this.ui.$downloadButton.prop('disabled', true);
        }
        if (this.ui.$cancelButton && this.ui.$cancelButton.prop) {
            this.ui.$cancelButton.prop('disabled', true);
        }
    },

    templateHelpers() {
        return {
            content: this.content,
            showDownloadButton: (this.model.get('EXPORTFILESIZE') > 0),
        };
    },
});
