import React, {
    useEffect, useLayoutEffect, useState, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { appBus } from '@glu/core';
import { withStyles } from '@glu/theming';
import { Drawer } from '@glu/drawer-react';
import { useTrapFocus } from '@glu/utilities-react';
import locale from '@glu/locale';
import useDrawerToggle from '../../hooks/useDrawerToggle';
import DrawerContainer from '../RtpDrawerContainer/RtpDrawerContainer';
import styles from './RtpDrawer.styles';

const propTypes = {
    children: PropTypes.node,
    // Internal classes hash - do not pass
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    defaultOpen: PropTypes.bool,
    /*
     * Drawer Content (Currently expecting bb view)
     * don't copy props to state... this should not be a prop, you can use with children
     */
    view: PropTypes.shape({ cid: PropTypes.string }),
    // don't copy props to state... hasTab is more of an implementation of RTPConversations
    hasTab: PropTypes.bool,
    // don't copy props to state...allowDetail is more of an implementation of RTPConversasions
    allowDetail: PropTypes.bool,
};

const defaultProps = {
    children: null,
    defaultOpen: false,
    view: null,
    hasTab: false,
    allowDetail: true,
};

const DrawerBase = ({
    view,
    defaultOpen,
    children,
    classes,
    hasTab,
    allowDetail,
}) => {
    const {
        isOpen,
        classesString,
        handleKeyUp,
        containerRef,
        open,
        close,
        toggleOpen,
    } = useDrawerToggle(defaultOpen, classes);

    const [drawerView, setDrawerView] = useState(view);
    const [showTab, setShowTab] = useState(hasTab);
    const [showTabOnClose, setShowTabOnClose] = useState(false);
    const [currentTab, setCurrentTab] = useState(null);
    const [showDetailLink, setShowDetailLink] = useState(allowDetail);
    const [conversationStarted, setConversationStarted] = useState(null);
    const [msgIcon, setMsgIcon] = useState(null);
    const keepAlive = useRef(false);
    const divRef = useRef();
    const onKeyDown = useTrapFocus({ active: open, ref: divRef });

    const handleClose = useCallback(() => {
        if (!keepAlive.current) {
            setDrawerView(null);
        }
        close();
        msgIcon?.focus();
    }, [close, msgIcon]);

    useEffect(() => {
        if (!isOpen) {
            appBus.trigger('dgb:drawer:wasClosed');
            if (showTabOnClose) {
                setShowTab(true);
            }
            if (!keepAlive.current) {
                setDrawerView(null);
            }
        } else if (isOpen) {
            appBus.trigger('dgb:drawer:wasOpened');
        }
    }, [isOpen, showTabOnClose]);

    useLayoutEffect(() => {
        /**
         *
         * @param {Object} obj - { view, viewOptions }
         */
        function updateDrawer(obj) {
            setMsgIcon(obj.viewOptions.msgIconElement);
            const viewOptions = obj.viewOptions || {};
            const shouldBeOpen = (obj.shouldBeOpen !== undefined)
                ? obj.shouldBeOpen : true;

            if (viewOptions.keepAlive !== undefined) {
                keepAlive.current = viewOptions.keepAlive;
            }
            if (viewOptions.currentTab !== undefined) {
                setCurrentTab(viewOptions.currentTab);
            }
            if (obj.showTab !== undefined) {
                setShowTab(obj.showTab);
            }
            if (obj.showTabOnClose !== undefined) {
                setShowTabOnClose(obj.showTabOnClose);
            }
            if (viewOptions.allowDetail !== undefined) {
                setShowDetailLink(viewOptions.allowDetail);
            }
            if (viewOptions.conversationStarted !== undefined) {
                setConversationStarted(obj.viewOptions.conversationStarted);
            }
            if (obj.view) {
                const View = obj.view;
                setDrawerView(new View(viewOptions));
            }
            if (shouldBeOpen) {
                open();
            } else {
                handleClose();
            }
        }

        appBus.on('dgb:drawer:update', updateDrawer);
        appBus.on('dgb:drawer:open', open);
        /*
         * this does not remove the view only toggles closed, if we want to remove
         * we want to just create a function to setDrawerView to null and then open()
         */
        appBus.on('dgb:drawer:close', handleClose);

        return () => {
            appBus.off('dgb:drawer:update', updateDrawer);
            appBus.off('dgb:drawer:open', open);
            appBus.off('dgb:drawer:close', handleClose);
        };
    }, [open, close, handleClose, setDrawerView, showTabOnClose]);

    const viewPayment = () => {
        const options = {
            action: 'select',
            model: drawerView.model,
            fromReact: true,
        };
        drawerView.appBus.trigger('rtp:viewPayment', options);
    };
    const closeDrawer = (event) => {
        if (event.key === 'Escape') {
            msgIcon?.focus();
        }
    };

    return (
        /* eslint-disable jsx-a11y/no-static-element-interactions */
        <section
            ref={containerRef}
            onKeyDown={closeDrawer}
        >
            {showTab
                && (
                    <button
                        onClick={toggleOpen}
                        onKeyUp={handleKeyUp}
                        className={`icon-chat-bubble notStarted ${classesString}`}
                        data-hook="getDrawerTabButton"
                    />
                )}
            {drawerView
                && (
                    /* eslint-disable jsx-a11y/no-static-element-interactions */
                    <div ref={divRef} onKeyDown={onKeyDown} >
                        <Drawer open={isOpen} className={classes.dgbdrawer} anchorTo="right" size={550} tabIndex="-1">
                            <button
                                aria-hidden="false"
                                className={`${classes.close} close`}
                                data-hook="getCloseBtn"
                                onClick={handleClose}
                                type="button"
                                aria-label={locale.get('button.close')}
                            >
                                <span className="icon-close" />
                            </button>

                            {children}

                            {drawerView.model && currentTab
                    && (
                        <DrawerContainer
                            data={drawerView.model.toJSON()}
                            showDetailPage={viewPayment}
                            currentTab={currentTab}
                            showDetailLink={showDetailLink}
                            conversationStarted={conversationStarted}
                        />
                    )}
                        </Drawer>
                    </div>
                )}
        </section>
    );
};

DrawerBase.propTypes = propTypes;
DrawerBase.defaultProps = defaultProps;

export default withStyles(styles)(DrawerBase);
