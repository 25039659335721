import ItemView from '@glu/core/src/itemView';
import $ from 'jquery';
import util from '@glu/core/src/util';
import Formatter from 'system/utilities/format';
import columnSumEditorTmpl from './columnSumEditor.hbs';

const MASK_OPTIONS = Formatter.getCurrencyMaskOptions();
// do not show pennies
MASK_OPTIONS.digits = 0;

const ColumnSumEditor = ItemView.extend({
    className: 'column-sum-edit-bubble',
    template: columnSumEditorTmpl,

    events: {
        keyup: 'handleKeypress',
        'click .btn[data-distribution]': 'handleButtonClick',
        'blur @ui.$sumInput': 'ensureNotBlank',
    },

    ui: {
        $sumInput: 'input[type="text"]',
    },

    initialize(options) {
        this.$triggerEl = options.$triggerEl;
        this.attr = options.attr;
    },

    behaviors: {
        NumberInputAcceleration: {},
    },

    delegateEvents(...args) {
        ItemView.prototype.delegateEvents.apply(this, args);
        $(document).on(`focusin.${this.cid} mouseup.${this.cid}`, util.bind(this.handleDocumentEvent, this));
    },

    undelegateEvents(...args) {
        $(document).off(`.${this.cid}`);
        ItemView.prototype.undelegateEvents.apply(this, args);
    },

    onRender() {
        this.$el.addClass(this.className);
        this.$(':focusable').eq(0).focus();
        this.ui.$sumInput.inputmask('decimal', MASK_OPTIONS);
    },

    onClose() {
        if (this.$triggerEl && this.$triggerEl.focus) {
            this.$triggerEl.focus();
        }
    },

    handleDocumentEvent(e) {
        if (this.$(e.target).length === 0) {
            // focus/click has left the view
            this.close();
        }
    },

    handleKeypress(e) {
        if (e.keyCode === $.ui.keyCode.ESCAPE) {
            this.close();
        }
    },

    ensureNotBlank(e) {
        const $target = this.$(e.currentTarget);
        if ($target.val() === '') {
            $target.val(0);
        }
    },

    templateHelpers() {
        return {
            id: this.cid,
            sum: this.getCurrentSum(),
        };
    },

    handleButtonClick(e) {
        const type = this.$(e.currentTarget).data('distribution');
        const sum = +this.ui.$sumInput.val();

        if (type === 'even') {
            this.distributeEvenly(sum);
        } else if (type === 'proportional') {
            this.distributeProportionally(sum);
        }

        this.close();
    },

    getCurrentSum() {
        return this.collection.reduce(function (memo, model) {
            return (memo + model.get(this.attr));
        }, 0, this);
    },

    distributeEvenly(sum) {
        const piece = sum / this.collection.length;
        this.collection.invoke('set', this.attr, piece);
    },

    distributeProportionally(sum) {
        const oldTotal = this.getCurrentSum();
        if (oldTotal === 0) {
            this.distributeEvenly(sum);
        } else {
            this.collection.each(function (model) {
                const proportion = model.get(this.attr) / oldTotal;
                model.set(this.attr, Math.round(proportion * sum));
            }, this);
        }
    },
});

export default ColumnSumEditor;
