import Layout from '@glu/core/src/layout';
import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import workflowServices from 'app/administration/views/panelApproval/workflow/workflowServices';
import Threshold from './threshold/threshold';
import ThresholdModel from '../models/thresholdModel';
import ThresholdPanelsViews from './threshold/thresholdPanels';
import ApproverSequenceModel from '../models/approverSequence';
import ApproverModel from '../models/approver';
import thresholdStepTmpl from './thresholdStep.hbs';

export default Layout.extend({
    template: thresholdStepTmpl,

    ui: {
        $addThresholdButton: '[data-hook="getAddThresholdButton"]',
    },

    events: {
        'click @ui.$addThresholdButton': 'addThreshold',
    },

    initialize(options) {
        this.primaryThresholdModel = new ThresholdModel();
        this.thresholds = options.model.get('thresholds');
        // Initially, do not show primary threshold when modifying
        this.showPrimaryThreshold = !options.modify;
    },

    loadRequiredData() {
        const currency = workflowServices.getCurrencyRequest(this.model.get('userGroup'));
        const sequenceTypes = workflowServices.getSequenceRequest();
        const approverTypes = workflowServices
            .getApproverTypesRequest(this.model.get('userGroup'));

        return Promise.all([currency, sequenceTypes, approverTypes])
            .then(this.setData.bind(this));
    },

    setData(response) {
        const [currency, sequenceTypes, approverTypes] = response;
        this.model.set('currency', currency);
        this.sequenceTypes = sequenceTypes;
        this.approverTypes = approverTypes;

        // Instantiate outside render so that only one event listener is created
        this.thresholdPanelsView = new ThresholdPanelsViews({
            collection: this.thresholds,
            currency,
            sequenceTypes,
            approverTypes,
        });
        this.listenTo(this.thresholdPanelsView, 'last:thresholdPanel:removed', this.togglePrimaryThreshold.bind(this));

        this.setHasLoadedRequiredData(true);
        this.render();
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            // Only render the view when flagged
            if (this.showPrimaryThreshold) {
                this.renderPrimaryThesholdView();
            }
            this.thresholdPanelsRegion.show(this.thresholdPanelsView);
        } else {
            this.loadRequiredData();
        }
    },

    renderPrimaryThesholdView() {
        this.primaryThresholdView = new Threshold({
            model: this.primaryThresholdModel,
            currency: this.model.get('currency'),
            sequenceTypes: this.sequenceTypes,
            approverTypes: this.approverTypes,
        });
        this.primaryThresholdRegion.show(this.primaryThresholdView);
    },

    addThreshold() {
        // When not showing primary threshold, show it and return
        if (!this.showPrimaryThreshold) {
            this.showPrimaryThreshold = true;
            this.renderPrimaryThesholdView();
            return;
        }

        if (this.primaryThresholdModel.isValid()) {
            const newThreshold = this.cloneThresholdModel(this.primaryThresholdModel);
            this.thresholds.add(newThreshold);
            /*
             * NH-128370 When the collection was empty because all the models were removed,
             * need to force the collection view to render after adding any new models
             */
            this.thresholdPanelsView.render();
            this.primaryThresholdModel.set(this.primaryThresholdModel.defaults());
            this.renderPrimaryThesholdView();
        }
    },

    cloneThresholdModel(thresholdModel) {
        const approverSequences = thresholdModel.get('approverSequences');
        return new ThresholdModel({
            amount: this.primaryThresholdModel.get('amount'),
            approverSequences: this.cloneApproverSequences(approverSequences),
        });
    },

    cloneApproverSequences(approverSequences) {
        const remapped = approverSequences.map(this.cloneApproverModel);
        return new Collection(remapped, { model: ApproverSequenceModel });
    },

    cloneApproverModel(approverSequence) {
        const approvers = approverSequence.get('approvers').toJSON();
        return new ApproverSequenceModel({
            sequence: approverSequence.get('sequence'),
            approvers: new Collection(approvers, { model: ApproverModel }),
        });
    },

    getThresholdData() {
        // When showing primaryThreshold combine it with threshold models
        if (this.showPrimaryThreshold) {
            return [this.primaryThresholdModel, ...this.thresholds.models];
        }

        return this.thresholds.models;
    },

    isValidStep() {
        const validThresholds = this.thresholds.map(threshold => threshold.isValid());
        const allValid = !util.contains(validThresholds, false);

        // Only validate primaryThreshold when it is visible
        if (this.showPrimaryThreshold) {
            const primaryValid = this.primaryThresholdModel.isValid();
            return primaryValid && allValid;
        }

        return allValid;
    },

    /**
     * Set flag showPrimaryThreshold to true and re-render
     */
    togglePrimaryThreshold() {
        this.showPrimaryThreshold = true;
        this.render();
    },
});
