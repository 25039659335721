import moment from 'moment';
import $ from 'jquery';
import RowView from './readOnlyRowView';
import PlanningConfiguration from './configuration';
import rowSumViewTmpl from './rowSumView.hbs';
import ColumnSumEditorView from './columnSumEditor';

const RowSumView = RowView.extend({
    template: rowSumViewTmpl,

    initialize(options) {
        RowView.prototype.initialize.call(this, options);
        this.flowType = options.flowType;
    },

    events: {
        'click button': 'handleClick',
    },

    handleClick(e) {
        // 1-12, sum
        const attr = +this.$(e.currentTarget).data('attr');
        if (!this.model.has(attr)) {
            return;
        }

        const m = PlanningConfiguration.get('fiscalYearStartMonth') + (attr - 1);
        const month = moment(new Date()).month(m).format('MMMM');

        const $td = this.$(e.currentTarget).closest('td');

        // need to make sure the el is already in the DOM when the view renders
        const $el = $('<div></div>').appendTo($td);
        (new ColumnSumEditorView({
            month,
            attr,
            el: $el,
            $triggerEl: this.$(e.currentTarget),
            collection: this.collection,
        })).render();
    },
});

export default RowSumView;
