import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import services from 'services';
import cache from 'system/utilities/cache';
import mdfData from 'common/dynamicPages/api/data';
import config from 'system/configuration';
import transform from 'common/util/transform';
import userInfo from 'etc/userInfo';

const baseUrl = `${config.isAdmin() ? '/adminPayment/listView' : ''}/payment/fedwire`;

export default {
    saveDocument(model, sequence) {
        if (!model.isValid()) {
            /*
             * TODO - should this return a locale that we can display?
             * We shouldn't be able to get here?
             */
            return Promise.reject(new Error('Invalid'));
        }

        const data = model.toJSON();
        const amountFields = [
            'DOC_PAIDAMOUNT',
            'DOC_ORIGINALAMOUNT',
            'DOC_DISCOUNTAMOUNT',
            'DOC_ADJUSTMENTAMOUNT',
        ];
        const decimalSeparator = userInfo.get('decimalSeparator');
        const thousandsSeparator = userInfo.get('thousandsSeparator');

        // Server requires US currency format
        amountFields.forEach((fieldName) => {
            const field = data[fieldName];
            if (!util.isEmpty(field)) {
                data[fieldName] = field.split(thousandsSeparator).join('').split(decimalSeparator).join('.');
            }
        });

        data.USERGROUP = userInfo.get('group');

        /*
         * If this is a new form, there is no sequence, so add one.
         * Not added to the model so a failed request leaves the model intact.
         */
        if (util.isEmpty(data.WORKSEQNUMBER)) {
            data.WORKSEQNUMBER = sequence;
        }

        const sendData = {
            grids: [{
                name: 'RTGS_REMIT_DOCUMENT_GRID',

                items: [{
                    item: transform.hashToPairs(data),
                }],
            }],
        };

        // TODO - should this url be in the services
        return http.post(services.generateUrl(`${baseUrl}/detail/saveChildren`), sendData);
    },

    deleteDocument(data) {
        const deleteService = services.generateUrl(`${baseUrl}/detail/deleteChildren`);

        return http.post(deleteService, data);
    },

    deleteAllDocuments() {
        const deleteAll = services.generateUrl(`${baseUrl}/details/bulkChildrenUpdate`);

        return http.post(
            deleteAll,
            {
                gridName: 'RTGS_REMIT_DOCUMENT_GRID',

                requests: [{
                    value: 0,
                    action: 'DELETE',
                    actionType: 'ACTIONALL',
                }],
            },
        );
    },

    getChildObject(context) {
        return cache.get(mdfData.model.getCacheName({ context }, true));
    },
};
