var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-region=\"alert\"></div>\n\n<div class=\"form-container inline-fields create-template-fields\">\n        <div>\n                <div class=\"field-container-sm required\">\n                        <label for=\"group-name-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":6,"column":47},"end":{"line":6,"column":53}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"payment.templategroups.groupName",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":55},"end":{"line":6,"column":100}}}))
    + "</label>\n                        <input name=\"name\" id=\"group-name-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":7,"column":58},"end":{"line":7,"column":64}}}) : helper)))
    + "\" type=\"text\" data-bind=\"model\" class=\"form-control\" maxlength=\"35\">\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"name\"></span>\n                </div>\n                <div class=\"field-container-sm required\">\n                        <label for=\"group-description-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":11,"column":54},"end":{"line":11,"column":60}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"payment.templategroups.groupDescription",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":62},"end":{"line":11,"column":114}}}))
    + "</label>\n                        <input name=\"description\" id=\"group-description-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":12,"column":72},"end":{"line":12,"column":78}}}) : helper)))
    + "\" type=\"text\" data-bind=\"model\" class=\"form-control\" maxlength=\"128\">\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"description\"></span>\n                </div>\n        </div>\n</div>\n\n<div data-region=\"list-builder\">\n        <div class=\"loader\"></div>\n</div>\n";
},"useData":true});