import locale from '@glu/locale';
import BaseRDCModel from './baseRDCModel';

export default BaseRDCModel.extend({

    defaults: {
        LOCATIONNAME: null,
        ACCOUNTNUM: null,
    },

    /**
     * Add validation for LOCATIONNAME when using location workflow
     * @param {boolean} useLocationWorkflow
     */
    addLocationValidation(useLocationWorkflow) {
        if (useLocationWorkflow) {
            this.validators = {
                LOCATIONNAME: {
                    description: locale.get('rdc.user.location'),
                    exists: true,
                },
            };
        } else {
            this.validators = {
                ACCOUNTNUM: {
                    description: locale.get('loans.account'),
                    exists: true,
                },
            };
        }
    },
});
