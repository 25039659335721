import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import ListView from 'common/dynamicPages/views/workflow/list';
import mobileUtil from 'mobile/util/mobileUtil';
import util from 'underscore';

let list = ListView.extend({
    initialize(options) {
        const superOptions = {
            menuCategory: 'BANK_ACC_INFO',
            serviceName: 'tableMaintenance/renameAccount',
            serviceFunc: null,
            businessType: null,
            context: 'CLIENT_ACCT_NAME',
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },
});

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list);
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
