import { createUseStyles } from '@glu/theming';

const styles = {
    fieldWrapper: {
        '& > div > label': {
            width: '100%',
            '& p:first-child': {
                marginBottom: '3px',
            },
        },
        '& input': {
            'font-weight': 400,
        },
    },
};

export default createUseStyles(styles);
