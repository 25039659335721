var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<"
    + alias4(((helper = (helper = lookupProperty(helpers,"hx") || (depth0 != null ? lookupProperty(depth0,"hx") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hx","hash":{},"data":data,"loc":{"start":{"line":1,"column":1},"end":{"line":1,"column":7}}}) : helper)))
    + " class=\"account-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"userGroup") || (depth0 != null ? lookupProperty(depth0,"userGroup") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userGroup","hash":{},"data":data,"loc":{"start":{"line":1,"column":29},"end":{"line":1,"column":42}}}) : helper)))
    + "</"
    + alias4(((helper = (helper = lookupProperty(helpers,"hx") || (depth0 != null ? lookupProperty(depth0,"hx") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hx","hash":{},"data":data,"loc":{"start":{"line":1,"column":44},"end":{"line":1,"column":50}}}) : helper)))
    + ">\n<small class=\"account-number\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"userName") || (depth0 != null ? lookupProperty(depth0,"userName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userName","hash":{},"data":data,"loc":{"start":{"line":2,"column":30},"end":{"line":2,"column":42}}}) : helper)))
    + "</small>\n<small class=\"currency-code\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"userId") || (depth0 != null ? lookupProperty(depth0,"userId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userId","hash":{},"data":data,"loc":{"start":{"line":3,"column":29},"end":{"line":3,"column":39}}}) : helper)))
    + "</small>\n<small class=\"bank-code\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"userGroupName") || (depth0 != null ? lookupProperty(depth0,"userGroupName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userGroupName","hash":{},"data":data,"loc":{"start":{"line":4,"column":25},"end":{"line":4,"column":42}}}) : helper)))
    + "</small>\n\n";
},"useData":true});