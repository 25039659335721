// This file was marked as DEPRECATED by @JMahoney in 2015.
// And, according to @Max it was most probably due to mobile issues with storage in mobile browsers.
// In 2018 code moved to dedicated repo/module.
// glu-store is used by glu-grid as alternative (not backend) place to save info about grid state.
// Sep-2018 converted to ES6
function GluStorage(storageName) {
  storageName = storageName || 'sessionStorage';

  // storage emulation
  const defaultStore = {
    data: {},

    setItem(key, value) {
      const { data } = this;

      data[key] = value;

      return data[key];
    },

    getItem(key) {
      return this.data[key];
    },

    removeItem(key) {
      const { data } = this;
      const value = data[key];

      if (!data.hasOwnProperty(key)) {
        return null;
      }

      delete data[key];

      return value;
    }
  };

  this.store = window[storageName] || defaultStore;

  return {
    store: this.store,

    isBrowserStorage() {
      return (this.store instanceof window.Storage);
    },

    getStorage(storeName) {
      return new GluStorage(storeName);
    },

    set(key, value) {
      const { store } = this;
      if (!store) {
        return null;
      }

      return (typeof store.setItem === 'function') ? store.setItem(key, JSON.stringify(value)) : null;
    },

    get(key) {
      const { store } = this;
      if (!store) {
        return null;
      }

      const value = (typeof store.getItem === 'function') ? store.getItem(key) : '';

      return value ? JSON.parse(value) : null;
    },

    unset(key) {
      const { store } = this;
      if (!store) {
        return null;
      }

      return (typeof store.removeItem === 'function') ? store.removeItem(key) : null;
    }
  };
}

export default new GluStorage();
