var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "checked";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<td>\n        <div class=\"plan-item\">\n        <input type=\"radio\" name=\"plan\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cashFlowPlanId") || (depth0 != null ? lookupProperty(depth0,"cashFlowPlanId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cashFlowPlanId","hash":{},"data":data,"loc":{"start":{"line":4,"column":47},"end":{"line":4,"column":65}}}) : helper)))
    + ":"
    + alias4(((helper = (helper = lookupProperty(helpers,"version") || (depth0 != null ? lookupProperty(depth0,"version") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"version","hash":{},"data":data,"loc":{"start":{"line":4,"column":66},"end":{"line":4,"column":77}}}) : helper)))
    + "\" id=\"include-plan-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cashFlowPlanId") || (depth0 != null ? lookupProperty(depth0,"cashFlowPlanId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cashFlowPlanId","hash":{},"data":data,"loc":{"start":{"line":4,"column":96},"end":{"line":4,"column":114}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"version") || (depth0 != null ? lookupProperty(depth0,"version") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"version","hash":{},"data":data,"loc":{"start":{"line":4,"column":115},"end":{"line":4,"column":126}}}) : helper)))
    + "\"  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":129},"end":{"line":4,"column":159}}})) != null ? stack1 : "")
    + ">\n        <label for=\"include-plan-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cashFlowPlanId") || (depth0 != null ? lookupProperty(depth0,"cashFlowPlanId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cashFlowPlanId","hash":{},"data":data,"loc":{"start":{"line":5,"column":33},"end":{"line":5,"column":51}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"version") || (depth0 != null ? lookupProperty(depth0,"version") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"version","hash":{},"data":data,"loc":{"start":{"line":5,"column":52},"end":{"line":5,"column":63}}}) : helper)))
    + "\">\n                "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":24}}}) : helper)))
    + " v"
    + alias4(((helper = (helper = lookupProperty(helpers,"version") || (depth0 != null ? lookupProperty(depth0,"version") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"version","hash":{},"data":data,"loc":{"start":{"line":6,"column":26},"end":{"line":6,"column":37}}}) : helper)))
    + "\n        </label>\n\n        "
    + alias4(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"btn btn-link remove","action":"","locale":"cashflow.plan.delete","name":"close"},"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":9,"column":102}}}))
    + "\n\n</div>\n\n</td>\n";
},"useData":true});