var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>"
    + alias2(lookupProperty(helpers,"date").call(alias1,(depth0 != null ? lookupProperty(depth0,"PAYDATE") : depth0),{"name":"date","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":20}}}))
    + "</td>\n<td>"
    + alias2(lookupProperty(helpers,"date").call(alias1,(depth0 != null ? lookupProperty(depth0,"ISSUEDATE") : depth0),{"name":"date","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":22}}}))
    + "</td>\n<td>"
    + alias2(((helper = (helper = lookupProperty(helpers,"ENTERED_BY") || (depth0 != null ? lookupProperty(depth0,"ENTERED_BY") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"ENTERED_BY","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":18}}}) : helper)))
    + "</td>\n<td>"
    + alias2(((helper = (helper = lookupProperty(helpers,"PAYMETHOD") || (depth0 != null ? lookupProperty(depth0,"PAYMETHOD") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"PAYMETHOD","hash":{},"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":17}}}) : helper)))
    + "</td>\n<td>"
    + alias2(((helper = (helper = lookupProperty(helpers,"STATUS") || (depth0 != null ? lookupProperty(depth0,"STATUS") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"STATUS","hash":{},"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":14}}}) : helper)))
    + "</td>\n<td>"
    + alias2(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"AMOUNT") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":21}}}))
    + "</td>\n";
},"useData":true});