import Collection from '@glu/core/src/collection';
import services from 'services';
import util from '@glu/core/src/util';
import transform from 'common/util/transform';
import http from '@glu/core/src/http';

const Billers = Collection.extend({
    initialize() {
        this.data = {
            rowsPerPage: 50,
            startRow: 1,
            fieldName: 'BILLERLOOKUP',

            searchFields: [{
                fieldName: 'MERCHANTID',
                operator: 'CONTAINS',

                fieldValue: [
                    '',
                ],

                dataType: 'text',
            }, {
                fieldName: 'MERCHANTNAME',
                operator: 'CONTAINS',

                fieldValue: [
                    '',
                ],

                dataType: 'text',
            }],

            searchOrOperator: true,
        };
    },

    sync(method, collection, options) {
        http.post(services.beneAdressLookup, this.data, (result) => {
            options.success(result.rows);
        }, () => {
            options.error();
        });
    },

    setFilter(filterText) {
        util.each(this.data.searchFields, (searchFieldParam) => {
            const searchField = searchFieldParam;
            searchField.fieldValue = [filterText];
        });
    },

    parse(response) {
        const returnArray = [];

        util.each(response, (rowItem) => {
            const obj = transform.pairsToHash(rowItem.columns, 'fieldName', 'fieldValue');

            const data = {
                id: obj.MerchantId,
                text: `${obj.MerchantName} - ${obj.MerchantId}`,
            };

            util.forEach(rowItem.mapDataList, (mapData) => {
                data[mapData.toField] = mapData.value;
            });
            returnArray.push(data);
        });

        return returnArray;
    },
});

export default Billers;
