var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"global-select-all-perms\">\n            <label class=\"checkbox-inline\" for=\"selectAllPermissions-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":7,"column":69},"end":{"line":7,"column":76}}}) : helper)))
    + "\">\n                <input type=\"checkbox\" id=\"selectAllPermissions-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":8,"column":64},"end":{"line":8,"column":71}}}) : helper)))
    + "\" data-hook=\"selectAllGroupPermissions\">\n                "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"button.selectall",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":45}}}))
    + "\n            </label>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset class=\"group-container\">\n    <legend>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"RTP.type.reqin",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":39}}}))
    + "</legend>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":12,"column":15}}})) != null ? stack1 : "")
    + "\n    <div data-region=\"permissionsRegion\"></div>\n</fieldset>\n";
},"useData":true});