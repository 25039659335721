import util from '@glu/core/src/util';
import locale from '@glu/locale';
import constants from 'common/dynamicPages/api/constants';
import ListView from 'common/dynamicPages/views/workflow/list';
import importResultsTmpl from './importResults.hbs';

const ImportResultsList = ListView.extend({
    template: importResultsTmpl,

    events: {
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
    },

    initialize(options) {
        const superOptions = {
            enableSaveViews: false,
            menuCategory: 'REPORTING',
            context: 'CM_IMPORTRESULTS',
            serviceName: 'adminCM/fraud/listView/importResults',
        };
        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    templateHelpers() {
        const self = this;
        return {
            getString(type) {
                return locale.get(self.localeKey + type);
            },

            getButtonString(type) {
                const key = `${self.localeKey}.button_${type}`;
                let retStr = locale.get(key);
                if (retStr === (`??${key}??`)) {
                    retStr = locale.get(`${self.localeKey.split('.')[0]}.button_${type}`);
                }
                return retStr;
            },

            hasApproveEntitlement() {
                return self.hasEntitlement(constants.ACTION_APPROVE);
            },

            hasDeleteEntitlement() {
                return self.hasEntitlement(constants.ACTION_DELETE);
            },
        };
    },

    cancel() {
        this.navigateTo('REPORTING/viewPaymentFraudControl');
    },
});

export default ImportResultsList;
