import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import constants from 'app/administration/constants';
import Entitlements from 'app/administration/collection/user2/entitlements';
import EntitlementCollectionView from './entitlementCollectionView';
import riskGroupViewTmpl from './riskGroupView.hbs';
import { updateSelectAllState, onChildrenChange } from '../helpers';

export default Layout.extend({
    template: riskGroupViewTmpl,
    className: 'risk-group',

    ui: {
        $selectAll: '.select-all-perms',
    },

    events: {
        'click @ui.$selectAll': 'selectAllPerms',
    },

    initialize(opts) {
        /*
         * Risk only has 1 entitlement per type, so we will map what we need from
         * the type to
         * each entitlement and simply pass a collection of entitlements
         */
        this.entitlements = new Entitlements(this.model.get('types').map((type) => {
            const entitlement = type.get('entitlements').at(0);
            entitlement.label = locale.get(type.get('label'));
            return entitlement;
        }));

        if (opts.mode === constants.MODES.VIEW) {
            this.entitlements.reset(this.entitlements.filter(type => type.isEntitled()));
        }
        this.boundUpdateSelectAllState = this.updateSelectAllState.bind(this);
    },

    onRender() {
        this.entitlementsCollectionView = new EntitlementCollectionView({
            groupsData: this.model,
            collection: this.entitlements,
            mode: this.options.mode,
        });

        this.permissionsRegion.show(this.entitlementsCollectionView);

        if (this.shouldSetColumnWidth()) {
            this.$el.addClass('col-6');
        }
        this.updateSelectAllState();
        this.onChildrenChange();
    },

    updateSelectAllState() {
        updateSelectAllState(this, this.getCheckboxList());
    },

    onChildrenChange() {
        onChildrenChange(this, this.getCheckboxList());
    },

    getCheckboxList() {
        return this.permissionsRegion?.$el.find('input[type="checkbox"]');
    },

    /**
     * Will add class col-6 to the risk-group div
     * @returns {boolean}
     */
    shouldSetColumnWidth() {
        return this.model.collection.length <= 3;
    },

    selectAllPerms(evt) {
        this.entitlements.selectAll(evt.target.checked);
        this.selectAllTypes(evt);
        this.entitlementsCollectionView.render();
        this.onChildrenChange();
    },

    /**
     * Manually set the entitled flag when we select All(group wise)
     * @param {jQuery Obj} evt
     */
    selectAllTypes(evt) {
        const { checked } = evt.currentTarget;
        this.model.get('types').each((rec) => {
            rec.set('entitled', checked);
        });
    },

    templateHelpers() {
        const self = this;
        return {
            cid: this.model.cid,
            readOnly: self.options.mode === constants.MODES.VIEW,
            label: locale.get(`uce.riskGroup.${this.model.get('id')}`),
        };
    },
});
