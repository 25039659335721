import MySettingsView from 'app/administration/views/alerts/mySettingsView';
import util from '@glu/core/src/util';

const MySettingsTabView = MySettingsView.extend({

    id: 'mySettings',

    attributes: {
        role: 'tabpanel',
        tabindex: '0',
        'aria-labelledby': 'mySettingsTab',
    },

    /**
     * @param {object} opts
     */
    initialize(opts) {
        MySettingsView.prototype.initialize.call(this, opts);
    },

    /**
     * @return {Object} template helpers
     */
    templateHelpers() {
        const obj = MySettingsView.prototype.templateHelpers.call(this);

        /*
         * Do not show the headers on the view when it is displayed under the Alert
         * Center tab.
         */
        return util.extend(obj, {
            hideSubHeader: true,
            hideHeader: true,
        });
    },
});

export default MySettingsTabView;
